import React, { Component } from 'react';

import { Calendar } from 'components/Calendar/Calendar';
import { ModalCustom } from 'components/ModalCustom/ModalCustom';

import { views, eventTypes, CALENDAR_NOTE } from 'shared/helpers/constants/calendarConstants';
import { isWarehouseUser } from 'shared/helpers/matchers/checkUserType';
import { formatDate } from 'shared/helpers/parsers/formaters';

import dateAdapter from 'utils/date/dateAdapter';

import { CalendarNotes } from './CalendarNotes';
import { ModalContent } from './ModalContent';

class ShipmentsCalendarLayout extends Component {
  state = {
    modalOpened: false,
    notesModalOpened: false,
    selectedEvent: {},
  };

  static getDerivedStateFromProps(props, state) {
    const { selectedEvent } = state;
    const {
      shipmentsCalendar: { shipments },
    } = props;
    if (selectedEvent.type === eventTypes.calendarNotes) {
      const updatedEvent =
        shipments &&
        shipments.calendarNotes.find(({ timestamp }) => timestamp === selectedEvent.timestamp);

      if (updatedEvent && updatedEvent.count < selectedEvent.count) {
        return { selectedEvent: { type: eventTypes.calendarNotes, ...updatedEvent } };
      } else if (!updatedEvent) {
        return { notesModalOpened: false };
      }
    }

    return null;
  }

  componentDidMount() {
    const { getShipmentsCalendar, markedDay } = this.props;

    getShipmentsCalendar(markedDay || +dateAdapter());
  }

  componentWillUnmount() {
    this.props.clearShipmentsCalendar();
  }

  onDateChange = (date, displayType) => {
    const { clearShipmentsCalendar, getShipmentsCalendar } = this.props;

    clearShipmentsCalendar();

    if (displayType === views.month) {
      const fetchDate = date.setDate(15);
      return getShipmentsCalendar(+fetchDate);
    }

    return getShipmentsCalendar(+date);
  };

  eventSelected = event => {
    this.setState({
      ...(event.type === eventTypes.calendarNotes
        ? { notesModalOpened: true }
        : { modalOpened: true }),
      selectedEvent: event,
    });
  };

  modalClose = () => this.setState({ modalOpened: false, notesModalOpened: false });

  render() {
    const {
      auth,
      history,
      match,
      markedDay,
      shipmentsCalendar: { shipments },
    } = this.props;
    const { modalOpened, selectedEvent, notesModalOpened } = this.state;

    return (
      <>
        <Calendar
          data={shipments}
          onDateChange={this.onDateChange}
          onSelectEvent={this.eventSelected}
          markedDay={markedDay}
          auth={auth}
        />
        <ModalCustom
          isOpen={modalOpened}
          maxWidth={720}
          containerPadding={0}
          modalClose={this.modalClose}
        >
          <ModalContent
            event={selectedEvent}
            history={history}
            match={match}
            markedDay={markedDay}
            allowActions={!isWarehouseUser(auth.user.userType)}
          />
        </ModalCustom>
        <ModalCustom
          isOpen={notesModalOpened}
          maxWidth={960}
          containerPadding={0}
          modalClose={this.modalClose}
          title={`${formatDate(selectedEvent.timestamp)}: ${CALENDAR_NOTE.NOTES}`}
        >
          <CalendarNotes notes={selectedEvent} auth={auth} />
        </ModalCustom>
      </>
    );
  }
}

export { ShipmentsCalendarLayout };
