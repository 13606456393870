import PropTypes from 'prop-types';
import React from 'react';

import { StyledLoadingSpinner } from 'assets/styles/common/StyledLoadingSpinner';

function Spinner(props) {
  const { loading } = props;

  return loading ? <StyledLoadingSpinner /> : null;
}

Spinner.propTypes = {
  loading: PropTypes.bool,
};

Spinner.defaultProps = {
  loading: false,
};

export { Spinner };
