import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { downloadAttachment } from 'actions/Attachments/downloadAttachment';

import { LinkButton } from 'shared/components/Buttons/LinkButton/LinkButton';
import { Icon } from 'shared/components/Icon/Icon';
import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';
import { notificationActionTypes } from 'shared/helpers/constants/notifications/notificationActionTypes';
import { paymentStatuses } from 'shared/helpers/constants/payments/paymentStatuses';
import { paymentTypes } from 'shared/helpers/constants/payments/paymentTypes';
import { cssVar } from 'shared/helpers/styling/styling';

import { Section } from '../Section/Section';
import { StyledActionsPortal, StyledSection } from './StyledNotification';

const addExtraNote = (noteType, data) => {
  switch (noteType) {
    case notificationActionTypes.paymentRequest:
      const { relatedLotHasUnpaidPayments, relatedLot, paymentType, status } = data || {};
      return relatedLotHasUnpaidPayments &&
        paymentType === paymentTypes.final &&
        status === paymentStatuses.submitted
        ? LOTS.UNPAID_PAYMENTS_EXIST(relatedLot.grgLotNumber)
        : '';
    default:
      return '';
  }
};

const NotificationBase = ({
  notification: {
    messageSubject,
    messageText,
    externalLink,
    externalLinkLabel,
    attachments,
    actionType,
  },
  className,
  actions,
  onCancel,
  isResolving,
  payment,
  ...props
}) => {
  const downloadFile = ({ path }) => props.downloadAttachment(path);
  const message = (
    <span
      dangerouslySetInnerHTML={{
        __html: messageText + addExtraNote(actionType, payment),
      }}
    />
  );

  return (
    <div className={className}>
      <Section label={messageSubject}>
        <Icon icon="icon-cancel" onClick={onCancel} />
      </Section>
      <StyledSection label={message} description>
        <StyledActionsPortal id="notificationActionsPortal" />
        {actions && actions(isResolving)}
        {externalLink && <LinkButton label={externalLinkLabel} url={externalLink} />}
        {attachments.map(attachment => (
          <LinkButton
            download
            label={attachment.originalName}
            onClick={() => downloadFile(attachment)}
          />
        ))}
      </StyledSection>
    </div>
  );
};

const mapDispatchToProps = {
  downloadAttachment,
};

const mapStateToProps = ({ paymentDetails: { payment } }) => ({
  payment,
});

const Notification = connect(mapStateToProps, mapDispatchToProps)(NotificationBase);

const NotificationStyled = styled(Notification)`
  background-color: ${cssVar('sanJuan')};
  margin-bottom: 35px;
`;

NotificationStyled.propTypes = {
  notification: PropTypes.shape({
    actionType: PropTypes.string,
    attachments: PropTypes.arrayOf(PropTypes.string).isRequired,
    messageSubject: PropTypes.string,
    messageText: PropTypes.string.isRequired,
  }),
  actions: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]).isRequired,
  onCancel: PropTypes.func.isRequired,
  isResolving: PropTypes.bool.isRequired,
  className: PropTypes.string.isRequired,
  payment: PropTypes.shape({
    relatedLotHasUnpaidPayments: PropTypes.bool.isRequired,
    status: PropTypes.string.isRequired,
    relatedLot: PropTypes.shape({
      grgLotNumber: PropTypes.string.isRequired,
    }),
    requestedFinalPaymentDate: PropTypes.string,
    paymentAmount: PropTypes.number,
    paymentNumber: PropTypes.number,
    fundsType: PropTypes.string,
    paymentType: PropTypes.string,
  }),
};

export { NotificationStyled as Notification };
