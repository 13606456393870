import DatePicker from 'react-datepicker';
import styled from 'styled-components';

export const DatePickerStyled = styled(DatePicker)`
  height: 50px;
  width: 100%;
  padding: 0 25px;
  font-size: 0.875rem;
  color: var(--alabaster);
  background-color: var(--shuttleGray);
  border-color: var(--shuttleGray);
  border-radius: 5px;
  border: none;
  transition: 100ms ease-in;

  :hover {
    background-color: var(--nevada);
  }

  :focus {
    outline: none;
  }

  :disabled {
    background-color: var(--limedSpruceDarker);
  }

  ::placeholder {
    color: var(--alto);
    opacity: 0.56;
    font-size: 0.75rem;
  }
`;
