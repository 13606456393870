import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const checkCustomLotNumberActionType = 'CHECK_CUSTOM_LOT_NUMBER';
const checkCustomLotNumberAction = () => ({
  type: checkCustomLotNumberActionType,
});

const checkCustomLotNumberSuccessActionType = `${checkCustomLotNumberActionType}_SUCCESS`;
const checkCustomLotNumberSuccessAction = result => ({
  type: checkCustomLotNumberSuccessActionType,
  payload: {
    result,
  },
});

const checkCustomLotNumberFailureActionType = `${checkCustomLotNumberActionType}_FAILURE`;
const checkCustomLotNumberFailureAction = () => ({
  type: checkCustomLotNumberFailureActionType,
});

const checkCustomLotNumber = customLotNumber => dispatch => {
  dispatch(checkCustomLotNumberAction());

  return axios
    .post(`${API_HOST_AUTH}/lots/validate/custom-lot-number`, {
      customLotNumber,
    })
    .then(res => {
      dispatch(checkCustomLotNumberSuccessAction(res));

      return res.data;
    })
    .catch(err => {
      dispatch(checkCustomLotNumberFailureAction());

      return Promise.reject(err.data);
    });
};

export {
  checkCustomLotNumberActionType,
  checkCustomLotNumberSuccessActionType,
  checkCustomLotNumberFailureActionType,
  checkCustomLotNumber,
};
