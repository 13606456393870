import React, { Fragment, useContext, useMemo, useEffect, useCallback } from 'react';
import { Field } from 'redux-form';

import { FieldInput } from 'shared/components/Fields/FieldInput/FieldInput';
import { FieldSelect } from 'shared/components/Fields/FieldSelect/FieldSelect';
import { Section } from 'shared/components/Form';
import { wizardContext } from 'shared/components/Wizard/Wizard';
import { COMPANY } from 'shared/helpers/constants/companies/companyConstants';
import { USER } from 'shared/helpers/constants/users/userConstants';

function Locations(props) {
  const { fields } = props;
  const wizard = useContext(wizardContext);
  const data = wizard && wizard.data;
  const locations = data && data.locations;
  const users = data && data.users;

  useEffect(() => {
    users.forEach(({ email }) => fields.push({ email }));

    return () => fields.removeAll();
  }, [users]);

  const locationsOptions = useMemo(
    () =>
      locations.map(({ name, lotSuffix }) => ({
        label: name,
        value: lotSuffix,
      })),
    [locations],
  );

  const locationsTemplate = useMemo(
    () => fields.map(field => `${field}.email ${field}.lotSuffix`),
    [fields],
  );

  const format = useCallback(
    value => {
      const { firstName, lastName } = users.find(({ email }) => email === value);

      return `${firstName} ${lastName}`;
    },
    [users],
  );

  return (
    <Section header={COMPANY.USERS_LOCATIONS} template={locationsTemplate} unwrap>
      {fields.map((location, index) => (
        <Fragment key={index}>
          <Field
            name={`${location}.email`}
            component={FieldInput}
            label={USER.USER}
            format={format}
            disabled
          />
          <Field
            name={`${location}.lotSuffix`}
            component={FieldSelect}
            label={USER.LOCATIONS}
            field="required"
            options={locationsOptions}
            multi
          />
        </Fragment>
      ))}
    </Section>
  );
}

export { Locations };
