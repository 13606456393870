import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { Field } from 'redux-form';

import {
  getCompanyLocations as getCompanyLocationsAction,
  clearCompanyLocations as clearCompanyLocationsAction,
} from 'actions/Locations/getCompanyLocations';

import { FieldSelect } from 'shared/components/Fields';
import { USER } from 'shared/helpers/constants/users/userConstants';
import { parseLocationsSelectData } from 'shared/helpers/dataParsers/location/parseLocationsSelectData';

function SelectLocationBase({
  locations,
  relatedCompany,
  match: {
    params: { locationId },
  },
  change,
  getCompanyLocations,
  clearCompanyLocations,
}) {
  useEffect(() => {
    if (relatedCompany) {
      getCompanyLocations(relatedCompany);
    }

    return () => clearCompanyLocations();
  }, [getCompanyLocations, relatedCompany, clearCompanyLocations]);

  useEffect(() => {
    if (!locationId) return;

    const forLocation = locations.find(({ _id }) => _id === locationId);

    change(
      'location',
      forLocation && [
        {
          label: forLocation.name,
          value: forLocation._id,
        },
      ],
    );
  }, [locationId, locations]);

  const parsedLocations = useMemo(
    () => locations && parseLocationsSelectData(locations),
    [locations],
  );

  return (
    !!relatedCompany && (
      <Field
        name="location"
        label={USER.LOCATION}
        field="required"
        options={parsedLocations}
        component={FieldSelect}
        multi
        wrap
      />
    )
  );
}

const mapStateToProps = ({ companyLocations }) => ({
  locations: companyLocations && companyLocations.data,
});

const mapDispatchToProps = {
  getCompanyLocations: getCompanyLocationsAction,
  clearCompanyLocations: clearCompanyLocationsAction,
};

const SelectLocation = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(SelectLocationBase);

export { SelectLocation };
