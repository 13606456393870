import {
  downloadAttachmentActionType,
  downloadAttachmentFailureActionType,
  downloadAttachmentSuccessActionType,
} from 'actions/Attachments/downloadAttachment';

const INITIAL_STATE = {
  isPending: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case downloadAttachmentActionType:
      return {
        isPending: true,
      };
    case downloadAttachmentFailureActionType:
    case downloadAttachmentSuccessActionType:
      return {
        isPending: false,
      };
    default:
      return state;
  }
};
