import { statusesForGlobal } from 'shared/helpers/constants/lots/statusesForGlobal';
import { isInternalCompany } from 'shared/helpers/matchers/checkCompanyType';
import { isOwnerUser } from 'shared/helpers/matchers/checkUserType';

const deleteSettlementsAccess = (lotStatusForGlobal, userType, companyType) => {
  const { ship_summary_accepted, invoice_generated, assay_results_received } = statusesForGlobal;
  return (
    [
      ...(isInternalCompany(companyType) ? [assay_results_received] : []),
      ship_summary_accepted,
      invoice_generated,
    ].includes(lotStatusForGlobal) && isOwnerUser(userType)
  );
};

export { deleteSettlementsAccess };
