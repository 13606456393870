export const setPlaceholder = (packages, assignedLot, placeholderType) => {
  const packageCountIncoming = Math.max(0, ...packages.map(({ packageNumber }) => packageNumber));

  return Array.from({ length: packageCountIncoming })
    .map((gap, index) => {
      const currentPackageNumber = packageCountIncoming - index;
      const existingPackage = packages.find(
        ({ packageNumber }) => packageNumber === currentPackageNumber,
      );

      return (
        existingPackage || {
          packageNumber: currentPackageNumber,
          status: placeholderType,
          assignedLot,
          placeholder: true,
        }
      );
    })
    .sort((a, b) => a.packageNumber - b.packageNumber)
    .filter(pkg => pkg.status !== 'deleted');
};
