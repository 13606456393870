import { connect } from 'react-redux';

import { generateShipmentDocuments } from 'actions/Shipments/generateShipmentDocuments';
import { showSnackbar } from 'actions/shared/snackbar';

import { snackbarOptions } from 'shared/helpers/matchers/snackbar/snackbarOptions';

function GenerateShipmentDocumentsBase(props) {
  const resolveAction = result =>
    props.showSnackbar(snackbarOptions(result.messageType), result.message);

  const onClick = () => {
    const {
      shipment: { _id },
    } = props;

    props.generateShipmentDocuments(_id).then(resolveAction).catch(resolveAction);
  };

  return props.children({ onClick });
}

const mapDispatchToProps = {
  generateShipmentDocuments,
  showSnackbar,
};

const ConnectedGenerateShipmentDocuments = connect(
  null,
  mapDispatchToProps,
)(GenerateShipmentDocumentsBase);

export {
  ConnectedGenerateShipmentDocuments as GenerateShipmentDocuments,
  GenerateShipmentDocumentsBase,
};
