const outgoingShipmentStatuses = {
  open: 'open',
  sent: 'sent',
};

const incomingShipmentStatuses = {
  ...outgoingShipmentStatuses,
  received: 'received',
};

export { outgoingShipmentStatuses, incomingShipmentStatuses };
