import {
  poolAccountBalanceStatementType,
  poolAccountBalanceStatementSuccessType,
  poolAccountBalanceStatementFailureType,
} from 'actions/PoolAccounts/poolAccountBalanceStatement';
import {
  getPoolAccountDetailsFailureType,
  getPoolAccountDetailsSuccessType,
  getPoolAccountDetailsType,
  clearPoolAccountDetailsType,
} from 'actions/PoolAccounts/poolAccountDetails';

const INITIAL_STATE = {
  isPending: false,
  poolAccount: null,
};

const poolAccountDetailsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getPoolAccountDetailsType:
      return {
        isPending: true,
        poolAccount: null,
      };
    case getPoolAccountDetailsSuccessType:
      return {
        isPending: false,
        poolAccount: action.payload.poolAccount,
      };
    case getPoolAccountDetailsFailureType:
      return {
        isPending: false,
        poolAccount: null,
      };
    case poolAccountBalanceStatementType:
      return {
        ...state,
        isPending: true,
      };
    case poolAccountBalanceStatementSuccessType:
    case poolAccountBalanceStatementFailureType:
      return {
        ...state,
        isPending: false,
      };
    case clearPoolAccountDetailsType:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};

export { poolAccountDetailsReducer };
