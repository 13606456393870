import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const deleteBankHolidaysActionType = 'DELETE_BANK_HOLIDAYS';
const deleteBankHolidaysAction = () => ({ type: deleteBankHolidaysActionType });

const deleteBankHolidaysSuccessActionType = `${deleteBankHolidaysActionType}_SUCCESS`;
const deleteBankHolidaysSuccessAction = () => ({ type: deleteBankHolidaysSuccessActionType });

const deleteBankHolidaysFailureActionType = `${deleteBankHolidaysActionType}_FAILURE`;
const deleteBankHolidaysFailureAction = () => ({ type: deleteBankHolidaysFailureActionType });

const deleteBankHolidays = id => async dispatch => {
  dispatch(deleteBankHolidaysAction());

  try {
    await axios.delete(`${API_HOST_AUTH}/bank-holidays/${id}`);
    dispatch(deleteBankHolidaysSuccessAction());
  } catch (error) {
    dispatch(deleteBankHolidaysFailureAction());

    return Promise.reject(error.data);
  }
};

export {
  deleteBankHolidays,
  deleteBankHolidaysActionType,
  deleteBankHolidaysSuccessActionType,
  deleteBankHolidaysFailureActionType,
};
