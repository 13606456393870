import { connect } from 'react-redux';

import { getCompany, clearCompanyDetails } from 'actions/Companies/companyDetails';
import { createLocation as createLocationAction } from 'actions/Locations/createLocation';
import { getCompaniesContact } from 'actions/Locations/getCompaniesContact';
import { openModal, closeModal } from 'actions/shared/modal';
import { hideSnackbar, showSnackbar } from 'actions/shared/snackbar';

import { CreateLocation } from './CreateLocation';

const mapStateToProps = ({ auth, modal, createLocation, companyDetails }) => ({
  auth,
  modal,
  createLocation,
  companyDetails,
});

const mapDispatchToProps = {
  showSnackbar,
  hideSnackbar,
  createLocation: createLocationAction,
  getCompany,
  clearCompanyDetails,
  getCompaniesContact,
  openModal,
  closeModal,
};

const ConnectedCreateLocation = connect(mapStateToProps, mapDispatchToProps)(CreateLocation);

export { ConnectedCreateLocation };
