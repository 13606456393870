const parseLotUpdateLocation = ({ locationStored, ...lot }) => {
  const data = {
    locationStored: locationStored.map(location => ({
      building: lot.building,
      section: location.section,
      ...(location.lane && { lane: location.lane }),
    })),
  };

  return data;
};

export { parseLotUpdateLocation };
