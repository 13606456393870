import React from 'react';

import { Button } from 'shared/components/Buttons/Button';
import { Header } from 'shared/components/Details/DetailsContainer/DetailsContainer';
import { renderDetailsContainer } from 'shared/components/Details/helpers/renderDetailsContainer';
import { Icon } from 'shared/components/Icon/Icon';
import { OverviewListWrapper } from 'shared/components/OverviewListWrapper/OverviewListWrapper';
import { SETTLEMENT_FOR_LOT_WIZARD } from 'shared/helpers/constants/poolAccounts/settlementForLotWizard';
import { cssVar } from 'shared/helpers/styling/styling';

import {
  settlementDetailsContainerConfig,
  deliveriesDetailsContainerConfig,
  deliveriesDetailsFieldsConfig,
  settlementDetailsFieldsConfig,
} from 'pages/PoolAccounts/components/RequestSettlement/SettlementForLotWizard/components/SettlementWizardSummary/config/fieldsConfig';

const { DELETE_ITEM, RELATED_LOT, SUMMARY_BLANKET_HEDGES_HEADER } = SETTLEMENT_FOR_LOT_WIZARD;

const SummaryItem = ({
  relatedLot: { grgLotNumber, label, value: relatedLotId },
  settlement,
  ouncesRemoved,
  deliveries,
  onDelete,
  userType,
}) => {
  const listActionButton = (
    <Button
      id="delete"
      onClick={() => onDelete(relatedLotId)}
      outlineColor={cssVar('solidRed')}
      padding="0 10px 0 23px"
    >
      {DELETE_ITEM}
      <Icon icon="icon-cancel" />
    </Button>
  );

  return (
    <OverviewListWrapper
      label={`${RELATED_LOT}: ${label || grgLotNumber}`}
      list={{
        items: {
          total: 1,
        },
      }}
      button={listActionButton}
      customList
    >
      <div>
        {renderDetailsContainer(
          settlementDetailsContainerConfig,
          settlementDetailsFieldsConfig,
          settlement,
          userType,
        )}
        {deliveries.length && (
          <Header bgColor={cssVar('outerSpaceBitLighter')}>{SUMMARY_BLANKET_HEDGES_HEADER}</Header>
        )}
        {deliveries.map(delivery =>
          renderDetailsContainer(
            deliveriesDetailsContainerConfig,
            deliveriesDetailsFieldsConfig,
            delivery,
          ),
        )}
      </div>
    </OverviewListWrapper>
  );
};

export { SummaryItem };
