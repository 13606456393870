import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import styled from 'styled-components';

import { TinyFieldInput } from 'shared/components/Fields';
import { scrollbar } from 'shared/helpers/styling/styling';

const TinyFieldTextareaLayout = forwardRef((props, ref) => (
  <TinyFieldInput
    {...props}
    ref={ref}
    height={50}
    prefixSize={20}
    suffixSize={20}
    maxLength={255}
    padding={10}
    as="textarea"
  />
));

const TinyFieldTextarea = styled(TinyFieldTextareaLayout)`
  ${scrollbar};
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
`;

TinyFieldTextarea.propTypes = {
  className: PropTypes.string,
  focused: PropTypes.bool,
  input: PropTypes.shape({
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onDragStart: PropTypes.func,
    onDrop: PropTypes.func,
    onFocus: PropTypes.func,
    value: PropTypes.string,
  }),
  meta: PropTypes.object,
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
};

export { TinyFieldTextarea };
