const packageType = {
  box: 'box',
  drum: 'drum',
  super_sack: 'super_sack',
  sample: 'sample',
  other: 'other',
};
const packageTypeLabels = {
  box: 'box',
  drum: 'drum',
  super_sack: 'super_sack',
  sample: 'lab cats',
  other: 'other',
};

const availablePackageType = {
  all: Object.values(packageType),
  allWithLabels: Object.values(packageType).map(pkg => ({
    value: pkg,
    label: packageTypeLabels[pkg],
  })),
  processing: [packageType.box, packageType.drum, packageType.super_sack],
};

const packageStatuses = {
  closed: 'closed',
  open: 'open',
  received: 'received',
  deleted: 'deleted',
  inactive: 'inactive',
};

export { availablePackageType, packageStatuses, packageType };
