import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { ContentLoader } from 'shared/components/ContentLoader/ContentLoader';
import { renderDetailsContainer } from 'shared/components/Details/helpers/renderDetailsContainer';
import { NotesPanel } from 'shared/components/Notes/NotesPanel/NotesPanel';
import { NOTE } from 'shared/helpers/constants/noteConstants';
import { dangerOptions } from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { isAssayOrGradingCompany } from 'shared/helpers/matchers/checkCompanyType';
import { isWarehouseUser } from 'shared/helpers/matchers/checkUserType';

import { extractErrorMessage } from 'utils/extractErrorMessage';

import { renderDiligenceDetails } from 'pages/Companies/components/CompanyDetails/DiligenceDetails/DiligenceDetails';
import { diligenceConfig } from 'pages/Companies/components/CompanyDetails/config/diligenceConfig';

import { CompanyDetailsLists } from './CompanyDetailsLists/CompanyDetailsLists';
import { MainCompanyDetails } from './MainDetails/MainDetails';
import {
  activityContainerConfig,
  activityFieldsConfig,
  companyDetailsContainerConfig,
  companyDetailsFieldsConfig,
  companyDiligenceContainerConfig,
  companyDiligenceFieldsConfig,
  primaryContactContainerConfig,
  primaryContactFieldsConfig,
} from './config/fieldsConfig';

class CompanyDetails extends Component {
  componentDidMount() {
    this.props.getCompany(this.props.match.params.id).catch(error => {
      this.props.showSnackbar(dangerOptions, extractErrorMessage(error));
    });
  }

  componentWillUnmount() {
    this.props.clearCompanyDetails();
    this.props.clearAgreementsList();
    this.props.clearTermsList();
    this.props.clearLocationsList();
    this.props.clearUsersList();
    this.props.clearNotesList();
  }

  diligenceSectionIsAvailable = access => {
    const { company } = this.props.companyDetails;
    return access && isAssayOrGradingCompany(company.companyType);
  };

  render() {
    const { company, isPending } = this.props.companyDetails;
    const {
      auth,
      companyDetailsGetAgreements,
      companyDetailsGetTerms,
      companyDetailsGetUsers,
      inactivateCompany: { isPending: isInactivating },
      reactivateCompany: { isPending: isReactivating },
    } = this.props;

    return (
      <ContentLoader loaded={company && !isPending && !isInactivating && !isReactivating}>
        {company && company.companyType && (
          <>
            <MainCompanyDetails
              company={company}
              auth={auth}
              companyDetailsGetUsers={companyDetailsGetUsers}
              companyDetailsGetAgreements={companyDetailsGetAgreements}
              companyDetailsGetTerms={companyDetailsGetTerms}
            />
            {renderDetailsContainer(
              companyDetailsContainerConfig,
              companyDetailsFieldsConfig,
              company,
              auth.user.userType,
            )}
            {this.diligenceSectionIsAvailable(
              auth.access.companies.details.dueDiligence.details.view,
            ) &&
              isAssayOrGradingCompany(company.companyType) &&
              renderDetailsContainer(
                companyDiligenceContainerConfig,
                companyDiligenceFieldsConfig,
                company,
                this.props.downloadAttachment,
                this.props.notesList,
              )}
            {this.diligenceSectionIsAvailable(
              auth.access.companies.details.dueDiligence.details.view,
            ) &&
              isAssayOrGradingCompany(company.companyType) &&
              renderDiligenceDetails(
                diligenceConfig(company, this.props.match, this.props.downloadAttachment),
              )}
            {company.primaryContact.map((primaryContact, index) =>
              renderDetailsContainer(
                primaryContactContainerConfig,
                primaryContactFieldsConfig,
                primaryContact,
                index,
                company.primaryContact.length,
              ),
            )}
            {renderDetailsContainer(activityContainerConfig, activityFieldsConfig, company)}
            {!isWarehouseUser(auth.user.userType) && (
              <NotesPanel match={this.props.match} relatedType={NOTE.NOTES_TYPE.COMPANIES} />
            )}
            <CompanyDetailsLists {...this.props} />
          </>
        )}
      </ContentLoader>
    );
  }
}

CompanyDetails.propTypes = {
  auth: PropTypes.shape({
    attempts: PropTypes.number,
    user: PropTypes.object,
    access: PropTypes.object,
    isPending: PropTypes.bool,
  }),
  clearAgreementsList: PropTypes.func,
  clearCompanyDetails: PropTypes.func,
  clearLocationsList: PropTypes.func,
  clearNotesList: PropTypes.func,
  clearTermsList: PropTypes.func,
  clearUsersList: PropTypes.func,
  closeModal: PropTypes.func,
  companyDetails: PropTypes.shape({
    isPending: PropTypes.bool,
    company: PropTypes.shape({
      assignedShipmentNumber: PropTypes.number,
      balanceRemaining: PropTypes.number,
      companyName: PropTypes.string,
      companyType: PropTypes.string,
      createdAt: PropTypes.number,
      createdBy: PropTypes.object,
      grgDefaultShippingLocation: PropTypes.shape({
        name: PropTypes.string,
      }),
      hasLotsOrHedges: PropTypes.bool,
      lotPrefix: PropTypes.string,
      materialCountRequired: PropTypes.bool,
      onlyInternalHedgingAndSettlement: PropTypes.bool,
      primaryContact: PropTypes.array,
      primaryLocation: PropTypes.shape({
        city: PropTypes.string,
        country: PropTypes.string,
        name: PropTypes.string,
        state: PropTypes.object,
        street: PropTypes.string,
        zipCode: PropTypes.string,
      }),
      purchasingAppURL: PropTypes.string,
      referralMethod: PropTypes.string,
      status: PropTypes.string,
      updatedAt: PropTypes.number,
      updatedBy: PropTypes.object,
    }),
  }),
  companyDetailsGetAgreements: PropTypes.func,
  companyDetailsGetLocations: PropTypes.func,
  companyDetailsGetTerms: PropTypes.func,
  companyDetailsGetUsers: PropTypes.func,
  companyDetailsLocationsList: PropTypes.object,
  companyDetailsTermsList: PropTypes.object,
  companyDetailsUsersList: PropTypes.object,
  createNote: PropTypes.func,
  createNoteStorage: PropTypes.shape({
    isPending: PropTypes.bool,
  }),
  downloadAttachment: PropTypes.func,
  downloadAttachmentStorage: PropTypes.shape({
    isPending: PropTypes.bool,
  }),
  getCompany: PropTypes.func,
  getNotes: PropTypes.func,
  inactivateCompany: PropTypes.shape({
    isPending: PropTypes.bool,
  }),
  reactivateCompany: PropTypes.shape({
    isPending: PropTypes.bool,
  }),
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
  modal: PropTypes.object,
  notesList: PropTypes.object,
  openModal: PropTypes.func,
  showSnackbar: PropTypes.func,
};

export { CompanyDetails };
