import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { getFutureHedges } from 'actions/Hedges/getFutureHedges';
import { marketAvailability } from 'actions/Hedges/marketAvailability';
import { createMarketHolidays } from 'actions/Hedges/marketHolidaysCreate';
import { openModal } from 'actions/shared/modal';
import { showSnackbar } from 'actions/shared/snackbar';

import { MarketHolidaysCreate } from './MarketHolidaysCreate';

const mapStateToProps = ({ modal, futureHedges }) => ({
  modal,
  futureHedges,
});

const mapDispatchToProps = {
  createMarketHolidays,
  openModal,
  showSnackbar,
  marketAvailability,
  getFutureHedges,
};

const ConnectedMarketHolidaysCreate = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MarketHolidaysCreate),
);

export { ConnectedMarketHolidaysCreate };
