const lotsBreadcrumbsRoutes = {
  '/lots': 'Lots overview',
  '/lots/list': 'Lots',
  '/lots/create': 'Create lot',
  '/lots/incoming': 'Incoming lot overview',
  '/lots/(incoming|processing|mixing)/:lotId': 'Lot details',
  '/lots/processing/complete': null,
  '/lots/processing/accept': null,
  '/lots/mixing/complete': null,
  '/lots/mixing/accept': null,
  '/lots/processing/complete/:id': 'Processing complete',
  '/lots/mixing/complete/:id': 'Mixing complete',
  '/lots/processing/accept/:id': 'Accept for processing',
  '/lots/mixing/accept/:id': 'Accept for mixing',
  '/lots/(processing|mixing)/(complete|accept)/:id/packages': null,
  '/lots/(processing|mixing)/(complete|accept)/:id/packages/:pkgId': 'Package details',
  '/lots/outgoing': 'Outgoing lot overview',
  '/lots/processing': 'Processing lot overview',
  '/lots/mixing': 'Mixing lot overview',
  '/lots/outgoing/lots': null,
  '/lots/outgoing/request-inspection': 'Request inspection',
  '/lots/outgoing/schedule-inspection': 'Schedule inspection',
  '/lots/outgoing/lots/:id': 'Lot details',
  '/lots/outgoing/shipments': null,
  '/lots/outgoing/shipments/:id': 'Shipment details',
  '/lots/list/:id': 'Lot details',
  '/lots/list/:id/commodity': 'Remove commodity',
  '/lots/list/:id/assay': null,
  '/lots/list/:id/assay/:assayId': 'Assay details',
  '/lots/list/:id/enter-assay-results': 'Enter assay manually',
  '/lots/list/:id/update': url => (window.location.pathname === url ? 'Update lot' : null),
  '/lots/list/:id/update/location': 'Update lot location',
  '/lots/list/:id/update/shipment-info': 'Update shipment information',
  '/lots/list/:lotId/packages': null,
  '/lots/list/:lotId/packages/:id/update': null,
  '/lots/list/:lotId/packages/create': null,
  '/lots/list/:lotId/packages/create/(customer|global|processing)': 'Create package',
  '/lots/list/:lotId/packages/:id/update(/(customer|global))?$': url =>
    window.location.pathname === url ? 'Update package' : null,
  '/lots/list/:lotId/packages/:id/update/weight': 'Update weight',
  '/lots/list/:lotId/packages/:id': 'Package details',
  '/lots/list/:lotId/notes': 'Notes',
  '/lots/list/:lotId/shipments': null,
  '/lots/list/:lotId/shipments/:id': 'Shipment details',
  '/lots/list/:lotId/payments': null,
  '/lots/list/:lotId/payments/:id': 'Payment details',
  '/lots/list/:lotId/generate/final-invoice': 'Generate final invoice',
  '/lots/list/:lotId/:manage': null,
  '/lots/list/:lotId/:manage/:fileType': null,
  '/lots/:id': 'Lot details',
  '/lots/:id/create-note': 'Create note',
  '/lots/create-processing-note': null,
  '/lots/create-processing-note/:id': 'Create processing note',
  '/lots/:id/update-processing-note': null,
  '/lots/:id/update-processing-note/:noteId': 'Edit processing note',
};

export { lotsBreadcrumbsRoutes };
