const message = {
  INVALID_EMAIL: 'Email must have a valid format.',
  INVALID_EMAIL_EMPTY: 'Email cannot be empty.',
  INVALID_PASSWORD: 'Password must have a valid format.',
  INVALID_PASSWORD_EMPTY: 'Password cannot be empty.',
  INVALID_USER_TYPE_EMPTY: 'User type cannot be empty.',
  INVALID_ACCESS_LEVEL_EMPTY: 'Access level cannot be empty.',
  INVALID_FIRST_NAME: 'First name must have a valid format.',
  INVALID_FIRST_NAME_EMPTY: 'First name cannot be empty.',
  INVALID_LAST_NAME: 'Last name must have a valid format.',
  INVALID_LAST_NAME_EMPTY: 'Last name cannot be empty.',
  INVALID_TITLE: 'Title must have a valid format.',
  INVALID_OFFICE_PHONE: 'Office phone must have a valid format.',
  INVALID_MOBILE_PHONE_EMPTY: 'Mobile phone cannot be empty.',
  INVALID_PHONE_EXTENSION: 'Phone extension must have a valid format.',
  INVALID_MOBILE_PHONE: 'Mobile phone must have a valid format.',
  INVALID_RELATED_COMPANY_EMPTY: 'Related company cannot be empty.',
  INVALID_LOCATION_REQUIRED_EMPTY: 'Location cannot be empty.',
  INVALID_DATABASE_ACCESS_EMPTY: 'Database access cannot be empty.',
  INVALID_PURCHASING_APP_ACCESS_EMPTY: 'Purchasing app access cannot be empty.',
  AT_LEAST_ONE_LOCATION: 'User must have at least one location.',
  UNIQUE_EMAIL: 'Email must be unique.',
};

export { message };
