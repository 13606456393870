const saveTemporaryPackageDataActionType = 'SAVE_TEMPORARY_PACKAGE_DATA';
const saveTemporaryPackageDataAction = () => ({
  type: saveTemporaryPackageDataActionType,
});

const saveTemporaryPackageDataSuccessActionType = `${saveTemporaryPackageDataActionType}_SUCCESS`;
const saveTemporaryPackageDataSuccessAction = temporaryData => ({
  type: saveTemporaryPackageDataSuccessActionType,
  payload: temporaryData,
});

const saveTemporaryPackageData = temporaryData => dispatch => {
  dispatch(saveTemporaryPackageDataAction());
  dispatch(saveTemporaryPackageDataSuccessAction(temporaryData));
};

export {
  saveTemporaryPackageDataActionType,
  saveTemporaryPackageDataAction,
  saveTemporaryPackageDataSuccessActionType,
  saveTemporaryPackageDataSuccessAction,
  saveTemporaryPackageData,
};
