import { dateFormat } from 'shared/helpers/parsers/date';

import dateAdapter from 'utils/date/dateAdapter';

import { PAYMENTS } from './paymentsConstants';
import { parsePayoutRule } from './payoutRulesTypes';

const itemsTemplate = [
  {
    label: PAYMENTS.PAYOUT_RULES.COMPANY_NAME,
    render: ({ companyName }) => companyName,
  },
  {
    label: PAYMENTS.PAYOUT_RULES.PAYOUT_RULE,
    render: ({ payoutRule }) => parsePayoutRule(payoutRule),
  },
  {
    label: PAYMENTS.PAYOUT_RULES.DATE_ADDED,
    render: ({ createdAt }) => dateAdapter(createdAt).format(dateFormat),
  },
  {
    label: PAYMENTS.PAYOUT_RULES.ADDED_BY,
    render: ({ createdBy: { firstName, lastName } }) => `${firstName} ${lastName}`,
  },
];

const itemsTemplateMobile = [
  {
    label: PAYMENTS.PAYOUT_RULES.COMPANY_NAME,
    render: ({ companyName }) => companyName,
  },
  {
    label: PAYMENTS.PAYOUT_RULES.PAYOUT_RULE,
    render: ({ payoutRule }) => parsePayoutRule(payoutRule),
  },
];

const itemsDetailsTemplate = [
  {
    label: PAYMENTS.PAYOUT_RULES.DATE_ADDED,
    render: ({ createdAt }) => dateAdapter(createdAt).format(dateFormat),
  },
  {
    label: PAYMENTS.PAYOUT_RULES.ADDED_BY,
    render: ({ createdBy: { firstName, lastName } }) => `${firstName} ${lastName}`,
  },
];

export { itemsTemplate, itemsTemplateMobile, itemsDetailsTemplate };
