import { eventTypes } from 'shared/helpers/constants/lots/eventTypes';
import { formatDate, formatToDollarWithPrefix, formatToUS } from 'shared/helpers/parsers/formaters';

export const detailsFormatter = (type, { key, value }) => {
  let parsedValue = value;

  if (/ounces|oz$/i.test(key)) {
    if (type === eventTypes.hedgeRequested) {
      parsedValue = `${formatToUS(value, 3)} oz`;
    } else {
      parsedValue = `${formatToUS(value)} oz`;
    }
  }

  if (/weight/i.test(key)) {
    parsedValue = `${formatToUS(value)} lbs`;
  }

  if (/price|amount/i.test(key)) {
    parsedValue = formatToDollarWithPrefix(value);
  }

  if (/date|at$/i.test(key)) {
    parsedValue = formatDate(value);
  }

  if (type === eventTypes.hedgePlaced && value === null) {
    parsedValue = '-';
  }

  if (
    [eventTypes.stillwaterInitialPayment, eventTypes.stillwaterFinalPayment].includes(type) &&
    value === ''
  ) {
    parsedValue = '-';
  }

  return {
    key: key.replace(/oz$/i, ''),
    value: parsedValue,
  };
};
