import axios from 'axios';

import { showSnackbar } from 'actions/shared/snackbar';

import { REPORTS } from 'shared/helpers/constants/reports/reportsConstants';
import { successOptions } from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { downloadFile } from 'shared/helpers/dataParsers/files/downloadFile';
import { parseClientLotParams } from 'shared/helpers/dataParsers/reports/parseClientLotReportData';

const API_HOST_AUTH = process.env.REACT_APP_API;

export const generateClientLotReportActionType = `GENERATE_CLIENT_LOT_REPORT`;
const generateClientLotReportAction = () => ({ type: generateClientLotReportActionType });

export const generateClientLotReportSuccessActionType = `${generateClientLotReportActionType}_SUCCESS`;
const generateClientLotReportSuccessAction = () => ({
  type: generateClientLotReportSuccessActionType,
});

export const generateClientLotReportFailureActionType = `${generateClientLotReportActionType}_FAILURE`;
const generateClientLotReportFailureAction = () => ({
  type: generateClientLotReportFailureActionType,
});

export const generateClientLotReport = params => async dispatch => {
  dispatch(generateClientLotReportAction());

  const URL = `${API_HOST_AUTH}/reports/client-lot-report`;

  return axios
    .get(URL, { params: parseClientLotParams(params), responseType: 'arraybuffer' })
    .then(data => {
      downloadFile(data);
      dispatch(generateClientLotReportSuccessAction());
      dispatch(showSnackbar(successOptions, REPORTS.GENERATE_CLIENT_LOT_REPORT_SUCCESS));

      return data;
    })
    .catch(err => {
      dispatch(generateClientLotReportFailureAction());

      return Promise.reject(err.data);
    });
};
