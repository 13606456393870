import {
  getHedgesOverviewActionType,
  getHedgesOverviewSuccessActionType,
  getHedgesOverviewTableSuccessActionType,
  getHedgesOverviewFailureActionType,
  clearHedgesOverviewListActionType,
} from 'actions/Hedges/hedgesOverviewList';

const INITIAL_STATE = {
  isPending: false,
  items: null,
  existingHedges: { items: {} },
};

const HedgesOverviewListReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getHedgesOverviewActionType:
      return {
        ...state,
        isPending: true,
      };
    case getHedgesOverviewSuccessActionType:
      return {
        ...state,
        isPending: false,
        items: action.payload.data,
      };
    case getHedgesOverviewTableSuccessActionType:
      return {
        ...state,
        existingHedges: {
          ...state.existingHedges,
          [action.payload.hedgeName]: { items: action.payload.data },
        },
      };
    case getHedgesOverviewFailureActionType:
      return {
        ...state,
        isPending: false,
      };
    case clearHedgesOverviewListActionType:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};

export { HedgesOverviewListReducer };
