import {
  hedgeReassignActionType,
  hedgeReassignSuccessActionType,
  hedgeReassignFailureActionType,
} from 'actions/Hedges/hedgesReassign';

const INITIAL_STATE = {
  isPending: false,
};

const hedgeReassignReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case hedgeReassignActionType:
      return {
        isPending: true,
      };

    case hedgeReassignSuccessActionType:
    case hedgeReassignFailureActionType:
      return {
        isPending: false,
      };

    default:
      return state;
  }
};

export { hedgeReassignReducer };
