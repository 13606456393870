import {
  approveTermsActionType,
  approveTermsFailureActionType,
  approveTermsSuccessActionType,
} from 'actions/Terms/approveTerms';
import {
  submitForApprovalActionType,
  submitForApprovalFailureActionType,
  submitForApprovalSuccessActionType,
} from 'actions/Terms/submitForApproval';
import {
  getTermsDetailsActionType,
  getTermsDetailsFailureActionType,
  getTermsDetailsSuccessActionType,
  clearTermsDetailsActionType,
} from 'actions/Terms/termsDetails';
import { uploadTermsDocumentSuccessActionType } from 'actions/Terms/uploadTermsDocument';

const INITIAL_STATE = {
  isPending: false,
  errorMessage: null,
  terms: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getTermsDetailsActionType:
      return {
        terms: null,
        isPending: true,
      };
    case getTermsDetailsSuccessActionType:
      return {
        ...state,
        isPending: false,
        terms: action.payload.terms,
      };
    case getTermsDetailsFailureActionType:
      return {
        ...state,
        isPending: false,
        errorMessage: action.payload.errorMessage,
      };
    case clearTermsDetailsActionType:
      return {
        ...state,
        terms: null,
        isPending: false,
        errorMessage: null,
      };
    case submitForApprovalActionType:
      return {
        ...state,
        isPending: true,
      };
    case submitForApprovalFailureActionType: {
      return {
        ...state,
        isPending: false,
      };
    }
    case submitForApprovalSuccessActionType:
      return {
        ...state,
        isPending: false,
        terms: state.terms
          ? {
              ...state.terms,
              submittedBy: action.payload.terms.submittedBy,
              submittedAt: action.payload.terms.submittedAt,
              customerDecisionBy: action.payload.terms.customerDecisionBy,
              status: action.payload.terms.status,
            }
          : null,
      };
    case approveTermsActionType:
      return {
        ...state,
        isPending: true,
      };
    case approveTermsFailureActionType: {
      return {
        ...state,
        isPending: false,
      };
    }
    case approveTermsSuccessActionType:
      return {
        ...state,
        isPending: false,
        terms: state.terms
          ? {
              ...state.terms,
              status: action.payload.terms.status,
              globalDecisionBy: action.payload.terms.globalDecisionBy,
              globalDecisionAt: action.payload.terms.globalDecisionAt,
              updatedBy: action.payload.terms.updatedBy,
              updatedAt: action.payload.terms.updatedAt,
              termsDocument: action.payload.terms.termsDocument,
              docuSignDocument: action.payload.terms.docuSignDocument,
            }
          : null,
      };
    case uploadTermsDocumentSuccessActionType:
      return {
        ...state,
        terms: state.terms
          ? {
              ...state.terms,
              status: action.payload.terms.status,
              updatedBy: action.payload.terms.updatedBy,
              updatedAt: action.payload.terms.updatedAt,
              termsDocument: action.payload.terms.termsDocument,
              docuSignDocument: action.payload.terms.docuSignDocument,
            }
          : null,
      };
    default:
      return state;
  }
};
