const NOTE = {
  NOTE: 'Note',
  NOTES: 'notes',
  CREATE_A_NOTE: 'Add a note',
  NOTE_CREATED: 'Note has been created.',
  NOTE_CREATED_ERROR: 'Note has not been created.',
  NOTES_TYPE: {
    USERS: 'users',
    COMPANIES: 'companies',
    DILIGENCE: 'diligence',
    LOCATION_NOTE: 'locationNote',
    QUESTIONNAIRE_NOTE: 'questionnaireNote',
    LOCATIONS: 'locations',
    LOTS: 'lots',
    HEDGES: 'hedges',
    PROCESSING: 'processing',
    SETTLEMENTS: 'settlements',
  },
  DETAILS: {
    NOTES: 'Notes',
    ATTACHMENTS: 'Attachments',
    MORE_ATTACHMENT: 'more attachment',
    MORE_ATTACHMENTS: 'more attachments',
  },
  ADD_PROCESSING_NOTE: 'Add processing note',
  EDIT_PROCESSING_NOTE: 'Edit processing note',
  PROCESSING_NOTES_LIST: 'Processing notes',
  SETTLEMENT_NOTES_LIST: 'Settlement notes',
  CONTINUE: 'Continue',
  NOTE_READED:
    'Please click "continue" to acknowledge that you have read these notes, or "cancel" to return to the lot details page',
  NOTE_SUBTITLE: 'Please review the following lot notes and/or manual changes:',
  NOTE_CONTINUE_ERROR: 'This action is not permitted',
  ERRORS: {
    DELETE_NOTE_ERROR: 'Note has not been deleted.',
  },
};

export { NOTE };
