import axios from 'axios';
import { saveAs } from 'file-saver';

const API_HOST_AUTH = process.env.REACT_APP_API;

const generateShipmentReportActionType = 'GENERATE_SHIPMENT_REPORT';
const generateShipmentReportAction = () => ({
  type: generateShipmentReportActionType,
});

const generateShipmentReportSuccessActionType = `${generateShipmentReportActionType}_SUCCESS`;
const generateShipmentReportSuccessAction = () => ({
  type: generateShipmentReportSuccessActionType,
});

const generateShipmentReportFailureActionType = `${generateShipmentReportActionType}_FAILURE`;
const generateShipmentReportFailureAction = () => ({
  type: generateShipmentReportFailureActionType,
});

const generateShipmentReport = id => dispatch => {
  dispatch(generateShipmentReportAction());

  return axios
    .post(`${API_HOST_AUTH}/shipments/${id}/generate-report`, null, {
      responseType: 'arraybuffer',
    })
    .then(res => {
      dispatch(generateShipmentReportSuccessAction());
      saveAs(new Blob([res.data], { type: res.headers['content-type'] }), 'shipmentReport.txt');

      return res.data;
    })
    .catch(() => {
      dispatch(generateShipmentReportFailureAction());

      return Promise.reject();
    });
};

export {
  generateShipmentReport,
  generateShipmentReportActionType,
  generateShipmentReportSuccessActionType,
  generateShipmentReportFailureActionType,
};
