import * as React from 'react';
import { useSelector } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { formValueSelector } from 'redux-form';

import { PAYMENTS } from 'shared/helpers/constants/payments/paymentsConstants';

import {
  StyledExceptionTypeInformation,
  StyledInformation,
} from './StyledExceptionTypeInformation';

const selector = formValueSelector('PaymentExceptionCompaniesForm');

export const ExceptionTypeInformation = () => {
  const exceptionTypes = useSelector(state => selector(state, 'exceptionTypes')) || [];

  return (
    exceptionTypes.length > 0 && (
      <StyledExceptionTypeInformation>
        <TransitionGroup component="ul" className="RequirementMatcher-list">
          {exceptionTypes.map(({ value }) => (
            <CSSTransition
              key={value}
              classNames="RequirementMatcher-list-requirement"
              timeout={500}
              unmountOnExit
            >
              <StyledInformation>
                {PAYMENTS.PAYMENT_EXCEPTION_COMPANIES.INFORMATION[value.toUpperCase()]}
              </StyledInformation>
            </CSSTransition>
          ))}
        </TransitionGroup>
      </StyledExceptionTypeInformation>
    )
  );
};
