import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getPackagesOverviewActionType = 'GET_PACKAGES_OVERVIEW_LIST';
function getPackagesOverviewAction() {
  return {
    type: getPackagesOverviewActionType,
  };
}

const getPackagesOverviewSuccessActionType = `${getPackagesOverviewActionType}_SUCCESS`;
function getPackagesOverviewSuccessAction(packages, sectionType) {
  return {
    type: getPackagesOverviewSuccessActionType,
    payload: {
      packages,
      sectionType,
    },
  };
}

const getPackagesOverviewFailureActionType = `${getPackagesOverviewActionType}_FAILURE`;
function getPackagesOverviewFailureAction(errorMessage) {
  return {
    type: getPackagesOverviewFailureActionType,
    payload: {
      errorMessage,
    },
  };
}

const clearPackagesOverviewActionType = 'CLEAR_PACKAGES_OVERVIEW_LIST';
function clearPackagesOverviewAction(sectionType) {
  return {
    type: clearPackagesOverviewActionType,
    payload: {
      sectionType,
    },
  };
}

const setPackageAsClosedActionType = 'SET_PACKAGE_AS_CLOSED_ACTION_TYPE';
function setPackageAsClosedAction(sectionType) {
  return {
    type: setPackageAsClosedActionType,
    payload: {
      sectionType,
    },
  };
}

const clearAllPackagesOverviewType = 'CLEAR_ALL_PACKAGES_OVERVIEW';
const clearAllPackagesOverview = () => ({
  type: clearAllPackagesOverviewType,
});

const getPackagesOverviewFactory = url => (id, limit, sectionType) => dispatch => {
  dispatch(getPackagesOverviewAction());

  return axios
    .get(url)
    .then(res => dispatch(getPackagesOverviewSuccessAction(res.data, sectionType)))
    .catch(err => dispatch(getPackagesOverviewFailureAction(err)));
};

const getPackagesOverviewForLots = (id, limit, sectionType) =>
  getPackagesOverviewFactory(`${API_HOST_AUTH}/packages/lots/${id}/${limit}/${sectionType}`)(
    id,
    limit,
    sectionType,
  );

const getPackagesOverviewForShipments = (id, limit, sectionType) =>
  getPackagesOverviewFactory(`${API_HOST_AUTH}/packages/shipments/${id}/${limit}`)(
    id,
    limit,
    sectionType,
  );

const getPackagesUnsentForShipments = (companyId, limit, sectionType) =>
  getPackagesOverviewFactory(`${API_HOST_AUTH}/packages/unsent/${companyId}/${limit}`)(
    companyId,
    limit,
    sectionType,
  );

const getPackagesUnsentWithShipments = (companyId, shipmentId, limit, sectionType) =>
  getPackagesOverviewFactory(
    `${API_HOST_AUTH}/packages/unsent/${companyId}/${shipmentId}/${limit}`,
  )(companyId, limit, sectionType);

function clearPackagesOverview(sectionType) {
  return dispatch => {
    dispatch(clearPackagesOverviewAction(sectionType));
  };
}

export {
  getPackagesOverviewActionType,
  getPackagesOverviewSuccessActionType,
  getPackagesOverviewFailureActionType,
  clearPackagesOverviewActionType,
  setPackageAsClosedActionType,
  clearAllPackagesOverviewType,
  setPackageAsClosedAction,
  getPackagesOverviewForLots,
  getPackagesOverviewForShipments,
  getPackagesUnsentForShipments,
  getPackagesUnsentWithShipments,
  clearPackagesOverview,
  clearAllPackagesOverview,
};
