import React, { useState } from 'react';

import { ListCollapse } from 'shared/components/ListCollapse/ListCollapse';
import {
  OverviewListSection,
  OverviewListSectionWrapper,
} from 'shared/components/OverviewList/OverviewListSection/OverviewListSection';

function OverviewDashboardSection({ children, total, title }) {
  const [displaySection, setDisplaySection] = useState(true);

  const sectionToggle = () =>
    total.length && setDisplaySection(prevDisplaySection => !prevDisplaySection);

  return (
    <OverviewListSectionWrapper isOpen={displaySection} total={total?.length}>
      <OverviewListSection isOpen={displaySection} total={total?.length}>
        <div>
          <ListCollapse collapse={displaySection} toggleList={sectionToggle} label={title} />
        </div>
      </OverviewListSection>
      <div>{children}</div>
    </OverviewListSectionWrapper>
  );
}

export { OverviewDashboardSection };
