import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { clearPoolAccountsAction } from 'actions/PoolAccounts/poolAccountsList';
import { openModal } from 'actions/shared/modal';

import { UIAuthorization } from 'components/Authorization/UIAuthorization';

import { globalRelatedAppName } from 'shared/helpers/constants/companies/globalRelatedAppName';
import { logoutModal } from 'shared/helpers/constants/modalConstants';
import { SHARED } from 'shared/helpers/constants/sharedConstants';

import { history } from 'utils/history';
import { resolveVersion, versions } from 'utils/versioning';

import {
  DropdownStyled,
  DropdownToggleStyled,
  DropdownMenuStyled,
} from './components/DropdownStyled';

const relatedApp = process.env.REACT_APP_RELATED_UNIT_URL;
const iconColorClass = resolveVersion({
  [versions.global]: '',
  [versions.west]: 'icon-gray',
  [versions.alpha]: '',
});

export const Dropdown = () => {
  const [isDropdownOpened, setIsDropdownOpened] = useState(false);
  const { user, access } = useSelector(state => state.auth);
  const dispatch = useDispatch();

  const logout = () => {
    dispatch(
      openModal(logoutModal, () => {
        redirectTo('/logout');
        dispatch(clearPoolAccountsAction());
      }),
    );
  };

  const redirectTo = url => {
    history.push(url);
    toggleDropdown();
  };

  const toggleDropdown = () => {
    setIsDropdownOpened(prev => !prev);
  };

  return (
    <div>
      <DropdownStyled isOpen={isDropdownOpened} toggle={toggleDropdown} id="Dropdown-user">
        <DropdownToggleStyled>
          <div className="userInfo">
            <div className="userType">{user.userType}</div>
            <div>
              {user.firstName} {user.lastName}
            </div>
          </div>
          <div className="dropdown-icon">
            <i
              className={`icon menu-arrow ${
                isDropdownOpened ? 'animate' : ''
              } icon-tiny-arrow-down ${iconColorClass}`}
            />
          </div>
        </DropdownToggleStyled>
        <DropdownMenuStyled id="Dropdown-menu-myProfile">
          <div onClick={() => redirectTo('/my-profile')}>
            <i className={`icon icon-user ${iconColorClass}`} />
            <span>{SHARED.MY_PROFILE}</span>
          </div>
          <UIAuthorization access={access.systemSettings.view}>
            <div onClick={() => redirectTo('/settings')}>
              <i className={`icon icon-status ${iconColorClass}`} />
              <span>{SHARED.SYSTEM_SETTINGS}</span>
            </div>
          </UIAuthorization>
          {relatedApp && (
            <UIAuthorization access={access.relatedPortal.view}>
              <a href={relatedApp} target="_blank" rel="noopener noreferrer">
                <i className={`icon icon-grg ${iconColorClass}`} />
                <span>{globalRelatedAppName}</span>
              </a>
            </UIAuthorization>
          )}
          <div id="Dropdown-menu-logout" onClick={logout}>
            <i className={`icon icon-logout ${iconColorClass}`} />
            <span>{SHARED.LOGOUT}</span>
          </div>
        </DropdownMenuStyled>
      </DropdownStyled>
    </div>
  );
};
