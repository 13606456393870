import styled from 'styled-components';

import { cssVar, pxToRem } from 'shared/helpers/styling/styling';

export const StyledExceptionTypeInformation = styled.div`
  border-top: ${pxToRem(1)} solid ${cssVar('riverBedDarker')};
  background-color: ${cssVar('limedSpruceForm')};
  padding: ${pxToRem(15)};
`;

export const StyledInformation = styled.li`
  color: ${cssVar('solidYellow')};
  font-size: ${pxToRem(14)};
  list-style: none;
`;
