import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const manageGradingReceiptType = 'MANAGE_GRADING_RECEIPT';
const manageGradingReceiptAction = () => ({
  type: manageGradingReceiptType,
});

const manageGradingReceiptSuccessType = `${manageGradingReceiptType}_SUCCESS`;
const manageGradingReceiptSuccessAction = lot => ({
  type: manageGradingReceiptSuccessType,
  payload: {
    ...lot,
  },
});

const manageGradingReceiptFailureType = `${manageGradingReceiptType}_FAILURE`;
const manageGradingReceiptFailureAction = () => ({
  type: manageGradingReceiptFailureType,
});

const manageGradingReceipt = (lotId, approve) => dispatch => {
  dispatch(manageGradingReceiptAction());

  return axios
    .put(`${API_HOST_AUTH}/lots/${lotId}/manage-grading-receipt`, { approve })
    .then(res => {
      dispatch(manageGradingReceiptSuccessAction(res.data.lot));

      return res.data;
    })
    .catch(err => {
      dispatch(manageGradingReceiptFailureAction());

      return Promise.reject(err.data);
    });
};

export {
  manageGradingReceiptType,
  manageGradingReceiptSuccessType,
  manageGradingReceiptFailureType,
  manageGradingReceipt,
};
