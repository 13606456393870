import { Route, Switch } from 'react-router-dom';

import { Authorization } from 'components/Authorization/Authorization';
import { NotFound } from 'components/ErrorPage/ErrorPage';

import { ConnectedCreateCompaniesNote } from 'shared/components/Notes/CreateNotes/ConnectedCreateNote';

import { ConnectedLocationDetails } from 'pages/Locations/components/LocationDetails/ConnectedLocationDetails';
import { ConnectedLocationsList } from 'pages/Locations/components/LocationsList/ConnectedLocationsList';
import { ConnectedCreateLocation } from 'pages/Locations/components/LocationsManagement/CreateLocation/ConnectedCreateLocation';
import { ConnectedUpdateLocation } from 'pages/Locations/components/LocationsManagement/UpdateLocation/ConnectedUpdateLocation';
import { ConnectedUpdateLocationDefaultPPMs } from 'pages/Locations/components/LocationsManagement/UpdateLocationDefaultPPMs/ConnectedUpdateLocationDefaultPPMs';
import ConnectedTermsDetails from 'pages/Terms/components/TermsDetails/ConnectedTermsDetails';
import ConnectedTermsList from 'pages/Terms/components/TermsList/ConnectedTermsList';
import {
  CreateTermCeramic,
  CreateTermFoil,
  CreateTermO2Sensor,
  CreateTermDiesel,
} from 'pages/Terms/components/TermsManagement/components/CreateTerm/CreateTermContainers';
import ConnectedUpdateTerm from 'pages/Terms/components/TermsManagement/components/UpdateTerm/ConnectedUpdateTerm';
import { UserDetails } from 'pages/Users/components/UserDetails/UserDetails';
import { CreateUser } from 'pages/Users/components/UsersManagement/components/CreateUser/CreateUser';

import { ConnectedAgreementsDetails } from '../Agreements/components/AgreementsDetails/ConnectedAgreementsDetails';
import { ConnectedAgreementsList } from '../Agreements/components/AgreementsList/ConnectedAgreementsList';
import { ConnectedCreateAgreements } from '../Agreements/components/Management/CreateAgreement/ConnectedCreateAgreement';
import { ChangeUsersPassword } from '../UserProfile/components/ChangeUsersPassword/ChangeUsersPassword';
import ConnectedUpdateUser from '../Users/components/UsersManagement/components/UpdateUser/ConnectedUpdateUser';
import { ConnectedCompaniesList } from './components/CompaniesList/ConnectedCompaniesList';
import { CreateCompanyWizard } from './components/CompaniesManagement/CreateCompanyWizard/CreateCompanyWizard';
import { ConnectedUpdateCompany } from './components/CompaniesManagement/components/UpdateCompany/ConnectedUpdateCompany';
import { UpdateOutstandingBalance } from './components/CompaniesManagement/components/UpdateOutstandingBalance/UpdateOutstandingBalance';
import { ConnectedCompanyDetails } from './components/CompanyDetails/ConnectedCompanyDetails';

function Companies(props) {
  const { companies, users, specialPermissions } = props.auth.access;

  const AuthCompaniesList = Authorization(ConnectedCompaniesList, companies.list);
  const AuthCreateCompanyWizard = Authorization(CreateCompanyWizard, companies.create);
  const AuthConnectedUpdateCompany = Authorization(ConnectedUpdateCompany, companies.update);
  const AuthConnectedCompanyDetails = Authorization(
    ConnectedCompanyDetails,
    companies.details.details,
  );
  const AuthConnectedLocationsList = Authorization(
    ConnectedLocationsList,
    companies.details.locations.list,
  );
  const AuthConnectedCreateLocation = Authorization(
    ConnectedCreateLocation,
    companies.details.locations.create,
  );
  const AuthConnectedUpdateLocation = Authorization(
    ConnectedUpdateLocation,
    companies.details.locations.update,
  );
  const AuthLocationDetails = Authorization(
    ConnectedLocationDetails,
    companies.details.locations.details,
  );
  const AuthConnectedTermsList = Authorization(ConnectedTermsList, companies.details.terms.list);
  const AuthCreateTermCeramic = Authorization(CreateTermCeramic, companies.details.terms.create);
  const AuthCreateTermFoil = Authorization(CreateTermFoil, companies.details.terms.create);
  const AuthCreateTermO2Sensor = Authorization(CreateTermO2Sensor, companies.details.terms.create);
  const AuthCreateTermDiesel = Authorization(CreateTermDiesel, companies.details.terms.create);
  const AuthConnectedTermsDetails = Authorization(
    ConnectedTermsDetails,
    companies.details.terms.details,
  );
  const AuthConnectedUpdateTerms = Authorization(
    ConnectedUpdateTerm,
    companies.details.terms.create,
  );
  const AuthConnectedCreateAgreements = Authorization(
    ConnectedCreateAgreements,
    companies.details.agreements.create,
  );
  const AuthConnectedAgreementsList = Authorization(
    ConnectedAgreementsList,
    companies.details.agreements.list,
  );
  const AuthConnectedAgreementsDetails = Authorization(
    ConnectedAgreementsDetails,
    companies.details.agreements.details,
  );
  const AuthConnectedUpdateOutstandingBalance = Authorization(
    UpdateOutstandingBalance,
    specialPermissions.payments.updateCompanyOutstandingBalance,
  );
  const AuthCreateUser = Authorization(CreateUser, users.create);
  const AuthUserDetails = Authorization(UserDetails, users.details.details);
  const AuthUpdateUser = Authorization(ConnectedUpdateUser, users.update);
  const AuthChangeUsersPassword = Authorization(ChangeUsersPassword, users.changePassword);
  const AuthCreateCompaniesNote = Authorization(
    ConnectedCreateCompaniesNote,
    companies.details.notesPanel,
  );
  const AuthConnectedUpdateLocationDefaultPPMs = Authorization(
    ConnectedUpdateLocationDefaultPPMs,
    companies.details.locations.update,
  );

  return (
    <Switch>
      <Route exact path="/companies" component={AuthCompaniesList} />
      <Route exact path="/companies/:id/locations" component={AuthConnectedLocationsList} />
      <Route exact path="/companies/:companyId/locations/:id" component={AuthLocationDetails} />
      <Route
        exact
        path="/companies/:companyId/locations/:id/update"
        component={AuthConnectedUpdateLocation}
      />
      <Route
        exact
        path="/companies/:companyId/locations/:id/update-default-ppms"
        component={AuthConnectedUpdateLocationDefaultPPMs}
      />
      <Route
        exact
        path="/companies/:companyId/location/:locationId/users/create"
        component={AuthCreateUser}
      />
      <Route path="/companies/:id/location/create" component={AuthConnectedCreateLocation} />
      <Route path="/companies/:id/term/create_ceramic" component={AuthCreateTermCeramic} />
      <Route path="/companies/:id/term/create_foil" component={AuthCreateTermFoil} />
      <Route path="/companies/:id/term/create_o2sensor" component={AuthCreateTermO2Sensor} />
      <Route path="/companies/:id/term/create_diesel" component={AuthCreateTermDiesel} />
      <Route exact path="/companies/:companyId/terms" component={AuthConnectedTermsList} />
      <Route path="/companies/:companyId/terms/:id/update" component={AuthConnectedUpdateTerms} />
      <Route path="/companies/:companyId/terms/:id" component={AuthConnectedTermsDetails} />
      <Route path="/companies/:id/agreement/create" component={AuthConnectedCreateAgreements} />
      <Route
        exact
        path="/companies/:companyId/agreements"
        component={AuthConnectedAgreementsList}
      />
      <Route
        exact
        path="/companies/:companyId/agreements/:id"
        component={AuthConnectedAgreementsDetails}
      />
      <Route
        exact
        path="/companies/:id/update/outstanding-balance"
        component={AuthConnectedUpdateOutstandingBalance}
      />
      <Route path="/companies/:id/update" component={AuthConnectedUpdateCompany} />
      <Route path="/companies/:companyId/users/create" component={AuthCreateUser} />
      <Route path="/companies/:companyId/users/:userId/update" component={AuthUpdateUser} />
      <Route
        path="/companies/:companyId/users/:userId/change-password"
        component={AuthChangeUsersPassword}
      />
      <Route path="/companies/:companyId/users/:id" component={AuthUserDetails} />
      <Route path="/companies/create" component={AuthCreateCompanyWizard} />
      <Route path="/companies/:id/create-note" component={AuthCreateCompaniesNote} />
      <Route path="/companies/:id" component={AuthConnectedCompanyDetails} />
      <Route component={NotFound} />
    </Switch>
  );
}

export { Companies };
