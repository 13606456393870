import React from 'react';

import { LinkButton } from 'shared/components/Buttons';
import { DetailsActivityField } from 'shared/components/Details/DetailsActivityField';
import { DetailsField } from 'shared/components/Details/DetailsField';
import { DetailsHumanizedField } from 'shared/components/Details/DetailsHumanizedField';
import { PACKAGES } from 'shared/helpers/constants/packages/packageConstants';
import { isInternalCompany } from 'shared/helpers/matchers/checkCompanyType';
import { isLimited } from 'shared/helpers/matchers/checkUserAccessLevel';
import { isGlobalUserType } from 'shared/helpers/matchers/isGlobalUserType';
import { formatToUS } from 'shared/helpers/parsers/formaters';
import { capitalize } from 'shared/helpers/parsers/text';

import { history } from 'utils/history';

import { packageNumberLabel } from '../OverviewList/helpers';

const generatePackageLabel = (pkg, auth) =>
  isGlobalUserType(auth.user.userType)
    ? `${capitalize(pkg.packageClass)} ${PACKAGES.PACKAGE_COLON_LOWER_CASE}`
    : PACKAGES.PACKAGE_COLON;

const packageDetailsFieldsConfig = (pkg, auth) => [
  isGlobalUserType(auth.user.userType) &&
    !isLimited(auth.user.accessLevel) && {
      label: PACKAGES.ASSIGNED_SHIPMENT,
      value: pkg.assignedShipment?._id,
      icon: 'icon-home',
      id: 'assignedShipment',
      customContent: pkg.assignedShipment?.shipmentDirection && pkg.assignedShipment?._id && (
        <LinkButton
          label={PACKAGES.SHIPMENT}
          onClick={() =>
            history.push(
              `/shipments/${pkg.assignedShipment?.shipmentDirection}/${pkg.assignedShipment?._id}`,
            )
          }
        />
      ),
      FieldType: DetailsField,
    },
  {
    label: PACKAGES.PACKAGE_NUMBER,
    value: `${generatePackageLabel(pkg, auth)} ${packageNumberLabel(pkg)}`,
    icon: 'icon-material',
    id: 'packageNumber',
    FieldType: DetailsHumanizedField,
  },
  {
    label: PACKAGES.PACKAGE_TYPE,
    value: pkg.packageType,
    icon: 'icon-package-type',
    id: 'packageType',
    FieldType: DetailsHumanizedField,
  },
  {
    label: PACKAGES.MATERIAL_TYPE_DECLARED,
    value: pkg.materialTypeDeclared,
    icon: 'icon-material',
    id: 'materialTypeDeclared',
    FieldType: DetailsHumanizedField,
  },
  isGlobalUserType(auth.user.userType) && {
    label: PACKAGES.MATERIAL_CLASS,
    value: pkg.materialClass,
    icon: 'icon-class',
    id: 'materialClass',
    FieldType: DetailsHumanizedField,
  },
  {
    label: PACKAGES.UNIT_COUNT,
    value: formatToUS(pkg.unitCount, 0),
    icon: 'icon-cart',
    suffix: PACKAGES.UNITS,
    id: 'unitCount',
    FieldType: DetailsField,
  },
  {
    label: PACKAGES.CUSTOMER_INSTRUCTIONS,
    value: pkg.customerInstructions,
    icon: 'icon-referral',
    id: 'customerInstructions',
    FieldType: DetailsField,
  },
];

const activityFieldsConfig = pkg => [
  {
    label: PACKAGES.CREATED_BY,
    user: pkg.createdBy,
    date: pkg.createdAt,
    id: 'createdBy',
    FieldType: DetailsActivityField,
  },
  {
    label: PACKAGES.UPDATED_BY,
    user: pkg.updatedBy,
    date: pkg.updatedAt,
    id: 'updatedBy',
    FieldType: DetailsActivityField,
  },
  {
    label: PACKAGES.RECEIVED_BY,
    user: pkg.receivedBy,
    date: pkg.receivedAt,
    id: 'receivedBy',
    FieldType: DetailsActivityField,
  },
  {
    label: PACKAGES.RELEASED_BY,
    user: pkg.releasedBy,
    date: pkg.releasedAt,
    id: 'releasedBy',
    FieldType: DetailsActivityField,
  },
];

const weightsFieldsConfig = (pkg, auth) => [
  ...(pkg.weightGrossDeclared
    ? [
        {
          label: PACKAGES.GROSS_DECLARED,
          value: formatToUS(pkg.weightGrossDeclared),
          icon: 'icon-volume',
          id: 'weightGrossDeclared',
          suffix: PACKAGES.LBS,
          FieldType: DetailsField,
        },
        {
          label: PACKAGES.TARE_DECLARED,
          value: formatToUS(pkg.weightTareDeclared),
          icon: 'icon-volume',
          id: 'weightTareDeclared',
          suffix: PACKAGES.LBS,
          FieldType: DetailsField,
        },
        {
          label: PACKAGES.NET_DECLARED,
          value: formatToUS(pkg.weightNetDeclared),
          icon: 'icon-volume',
          id: 'weightNetDeclared',
          suffix: PACKAGES.LBS,
          FieldType: DetailsField,
        },
      ]
    : []),
  ...(isGlobalUserType(auth.user.userType) ||
  isInternalCompany(auth.user.relatedCompany.companyType)
    ? [
        {
          label: PACKAGES.GROSS_ACTUAL,
          value: formatToUS(pkg.weightGrossActual),
          icon: 'icon-volume',
          id: 'weightGrossActual',
          suffix: PACKAGES.LBS,
          FieldType: DetailsField,
        },
        {
          label: PACKAGES.TARE_ACTUAL,
          value: formatToUS(pkg.weightTareActual),
          icon: 'icon-volume',
          id: 'weightTareActual',
          suffix: PACKAGES.LBS,
          FieldType: DetailsField,
        },
        {
          label: PACKAGES.NET_ACTUAL,
          value: formatToUS(pkg.weightNetActual),
          icon: 'icon-volume',
          id: 'weightNetActual',
          suffix: PACKAGES.LBS,
          FieldType: DetailsField,
        },
      ]
    : []),
];

const packageDetailsContainerConfig = (pkg, auth) => ({
  header: PACKAGES.GENERAL_DETAILS,
  template:
    isGlobalUserType(auth.user.userType) && !isLimited(auth.user.accessLevel)
      ? [
          'packageNumber packageNumber assignedShipment assignedShipment',
          'packageType materialTypeDeclared materialClass unitCount',
          'customerInstructions customerInstructions customerInstructions customerInstructions',
        ]
      : isGlobalUserType(auth.user.userType) && isLimited(auth.user.accessLevel)
      ? [
          'packageNumber packageNumber packageType packageType',
          'materialTypeDeclared materialTypeDeclared materialClass unitCount',
          'customerInstructions customerInstructions customerInstructions customerInstructions',
        ]
      : [
          'packageNumber packageType materialTypeDeclared unitCount',
          'customerInstructions customerInstructions customerInstructions customerInstructions',
        ],
});

const activityContainerConfig = () => ({
  header: PACKAGES.ACTIVITY,
  template: [
    'createdBy createdBy updatedBy updatedBy',
    'receivedBy receivedBy releasedBy releasedBy',
  ],
});

const weightsTemplate = (pkg, auth) => {
  if (
    isGlobalUserType(auth.user.userType) ||
    isInternalCompany(auth.user.relatedCompany.companyType)
  ) {
    if (pkg.weightGrossDeclared) {
      return [
        'weightGrossDeclared weightGrossDeclared weightTareDeclared weightTareDeclared',
        'weightNetDeclared weightNetDeclared weightNetDeclared weightNetDeclared',
        'weightGrossActual weightGrossActual weightTareActual weightTareActual',
        'weightNetActual weightNetActual weightNetActual weightNetActual',
      ];
    }
    return [
      'weightGrossActual weightGrossActual weightTareActual weightTareActual',
      'weightNetActual weightNetActual weightNetActual weightNetActual',
    ];
  }
  return [
    'weightGrossDeclared weightGrossDeclared weightTareDeclared weightTareDeclared',
    'weightNetDeclared weightNetDeclared weightNetDeclared weightNetDeclared',
  ];
};

const weightsContainerConfig = (pkg, auth) => ({
  header: PACKAGES.WEIGHTS,
  template: weightsTemplate(pkg, auth),
});

export {
  packageDetailsFieldsConfig,
  activityFieldsConfig,
  weightsFieldsConfig,
  packageDetailsContainerConfig,
  activityContainerConfig,
  weightsContainerConfig,
};
