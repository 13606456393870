import {
  getSpecialPermissionsActionType,
  getSpecialPermissionsSuccessActionType,
  getSpecialPermissionsFailureActionType,
} from 'actions/SpecialPermissions/getSpecialPermissions';

const INITIAL_STATE = {
  isPending: false,
  value: null,
};

export const SpecialPermissions = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getSpecialPermissionsActionType:
      return {
        ...state,
        isPending: true,
      };
    case getSpecialPermissionsSuccessActionType:
      return {
        ...state,
        isPending: false,
        value: action.payload,
      };
    case getSpecialPermissionsFailureActionType:
      return {
        ...state,
        isPending: false,
      };
    default:
      return state;
  }
};
