import React, { useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm, FormSection, formValueSelector } from 'redux-form';

import { FieldButtonSelect } from 'shared/components/Fields/FieldButtonSelect/FieldButtonSelect';
import { FieldCapitalized } from 'shared/components/Fields/FieldCapitalized/FieldCapitalized';
import { FieldInput } from 'shared/components/Fields/FieldInput/FieldInput';
import { FieldRadioButton } from 'shared/components/Fields/FieldRadioButton/FieldRadioButton';
import { FieldSelect } from 'shared/components/Fields/FieldSelect/FieldSelect';
import { Form, Section } from 'shared/components/Form';
import { formName } from 'shared/components/Wizard/Wizard';
import { materialCountRequiredAvailable } from 'shared/helpers/accesses/companies/materialCountRequiredAvailable';
import { onlyInternalHedgingAndSettlementAvailable } from 'shared/helpers/accesses/companies/onlyInternalHedgingAndSettlementAvailable';
import { samplesAllowedAvailable } from 'shared/helpers/accesses/companies/samplesAllowedAvailable';
import { companyTypesForUserRole } from 'shared/helpers/accesses/config/companyTypesForUserRole';
import { COMPANY } from 'shared/helpers/constants/companies/companyConstants';
import { isAssayCompany, isGradingCompany } from 'shared/helpers/matchers/checkCompanyType';
import { normalize } from 'shared/helpers/parsers/boolean';
import { mapArray } from 'shared/helpers/parsers/select';
import { asyncValidate } from 'shared/helpers/validations/company/asyncValidate';
import { validate } from 'shared/helpers/validations/company/companyValidations';
import { multiValidator, multiAsyncValidator } from 'shared/helpers/validations/multiValidator';
import { useAsyncValidatorGuard } from 'shared/hooks/useAsyncValidatorGuard';

function AddCompanyBase(props) {
  const {
    CustomActions,
    handleSubmit,
    companyType,
    userValues,
    invalid,
    asyncValidating,
    isAsyncValidationPending,
    change,
    resetSection,
  } = props;

  const companyTypes = useMemo(() => {
    let types = companyTypesForUserRole[props.user.userType];

    if (!props.access.companies.gradingEnabled) {
      types = types.filter(type => type !== 'grading');
    }

    return mapArray(types);
  }, [props.user.userType]);

  const isAssayOrGrading =
    companyType && (isAssayCompany(companyType.value) || isGradingCompany(companyType.value));

  const onCompanyTypeChange = useCallback(() => {
    change(
      'users',
      userValues.map(user => ({
        ...user,
        userType: null,
        accessLevel: null,
      })),
    );
    resetSection('company.materialCountRequired');
    change('company.samplesAllowed', false);
    change('company.onlyInternalHedgingAndSettlement', false);

    userValues.forEach((user, i) => {
      resetSection(`users[${i}].databaseAccess`);
      resetSection(`users[${i}].purchasingAppAccess`);
    });
  }, [change, resetSection, userValues]);

  const [validated, onChange] = useAsyncValidatorGuard(isAsyncValidationPending);

  return (
    <Form
      header={COMPANY.COMPANY}
      onSubmit={handleSubmit}
      CustomActions={CustomActions}
      submitDisabled={invalid || !!asyncValidating || !validated}
    >
      <FormSection name="company">
        <Section template={['companyName .']}>
          <Field
            name="companyName"
            component={FieldInput}
            label={COMPANY.COMPANY_NAME}
            field="required"
          />
        </Section>
        <Section
          header={COMPANY.COMPANY_INFO}
          template={[
            'companyType companyType',
            'lotPrefix referralMethod',
            'volume purchasingAppURL',
            `samplesAllowed ${
              companyType && !isGradingCompany(companyType.value)
                ? 'onlyInternalHedgingAndSettlement'
                : '.'
            }`,
            'materialCountRequired materialCountRequired',
          ]}
        >
          <Field
            name="companyType"
            component={FieldButtonSelect}
            label={COMPANY.COMPANY_TYPE}
            options={companyTypes}
            field="required"
            onChange={onCompanyTypeChange}
          />
          <FieldCapitalized
            name="lotPrefix"
            prefix={isAssayOrGrading ? COMPANY.LOT_PREFIX_PREFIX : ''}
            disabled={!companyType}
            prefixSize={34}
            component={FieldInput}
            label={COMPANY.LOT_PREFIX}
            field="unique required"
            onChange={onChange}
          />
          <Field name="referralMethod" component={FieldInput} label={COMPANY.REFERRAL_METHOD} />
          <Field name="volume" component={FieldInput} label={COMPANY.VOLUME} />
          <Field
            name="purchasingAppURL"
            component={FieldInput}
            label={COMPANY.PURCHASING_APP_URL}
          />
          {materialCountRequiredAvailable(companyType && companyType.value) && (
            <Field
              name="materialCountRequired"
              component={FieldRadioButton}
              label={COMPANY.MATERIAL_COUNT_REQUIRED}
              icon="icon-count"
              field="required"
              normalize={normalize}
            />
          )}
          {companyType && samplesAllowedAvailable(companyType.value) && (
            <Field
              name="samplesAllowed"
              component={FieldRadioButton}
              label={COMPANY.INDIVIDUAL_ASSAYS_ALLOWED}
              icon="icon-count"
              field="required"
              normalize={normalize}
            />
          )}
          {onlyInternalHedgingAndSettlementAvailable(companyType?.value) && (
            <Field
              name="onlyInternalHedgingAndSettlement"
              component={FieldRadioButton}
              label={COMPANY.ONLY_INTERNAL_HEDGING_SETTLEMENT}
              icon="icon-count"
              field="required"
              normalize={normalize}
            />
          )}
        </Section>
        <Section header={COMPANY.SHIPPING_LOCATION} template={['grgDefaultShippingLocation .']}>
          <Field
            name="grgDefaultShippingLocation"
            component={FieldSelect}
            disabled
            label={COMPANY.GRG_DEFAULT_SHIPPING_LOCATION}
          />
        </Section>
      </FormSection>
    </Form>
  );
}

const selector = formValueSelector(formName);

const mapStateToProps = state => ({
  user: state.auth.user,
  access: state.auth.access,
  companyType: selector(state, 'company.companyType'),
  userValues: selector(state, 'users'),
  isAsyncValidationPending: state.checkCompany && state.checkCompany.isPending,
});

const AddCompany = compose(
  reduxForm({
    validate: multiValidator('company', validate),
    asyncValidate: multiAsyncValidator('company', asyncValidate),
    asyncChangeFields: ['company.companyType'],
    asyncBlurFields: ['company.lotPrefix'],
  }),
  connect(mapStateToProps),
)(AddCompanyBase);

export { AddCompany, AddCompanyBase };
