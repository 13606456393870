import React from 'react';

import { StyledLoadingSpinner } from 'assets/styles/common/StyledLoadingSpinner';

import { Icon } from '../Icon/Icon';
import { StyledListCollapse } from './StyledListCollapse/StyledListCollapse';

export const ListCollapse = ({
  collapse,
  toggleList,
  label,
  className,
  icon,
  disabled,
  clickable,
  processing,
  pending,
  logs,
}) => {
  const listToggle = () => {
    clickable && toggleList(collapse);
  };

  return (
    <div className={className || ''} data-testid={'listCollapseWrapper'}>
      <StyledListCollapse
        onClick={listToggle}
        isOpen={collapse || !clickable}
        disabled={disabled}
        processing={processing}
        logs={logs}
      >
        {icon && <Icon icon={icon} width="50px" height="50px" />}
        {label && <h3>{label}</h3>}
        {pending && <StyledLoadingSpinner />}
      </StyledListCollapse>
    </div>
  );
};

ListCollapse.defaultProps = {
  disabled: false,
  clickable: true,
  pending: false,
};
