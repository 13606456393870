const statusForCustomerFilterActionType = 'STATUS_FOR_CUSTOMER_ACTION';
function statusForCustomerFilterAction(statusForCustomer) {
  return {
    type: statusForCustomerFilterActionType,
    payload: {
      statusForCustomer,
    },
  };
}

const statusForGlobalFilterActionType = 'STATUS_FOR_GLOBAL_ACTION';
function statusForGlobalFilterAction(statusForGlobal) {
  return {
    type: statusForGlobalFilterActionType,
    payload: {
      statusForGlobal,
    },
  };
}

const materialTypeFilterActionType = 'MATERIAL_TYPE_ACTION';
function materialTypeFilterAction(materialType) {
  return {
    type: materialTypeFilterActionType,
    payload: {
      materialType,
    },
  };
}

const sentFromCompanyLocationFilterActionType = 'LOTS_SENT_FROM_COMPANY_LOCATION_ACTION';
function sentFromCompanyLocationFilterAction(sentFromCompanyLocation) {
  return {
    type: sentFromCompanyLocationFilterActionType,
    payload: {
      sentFromCompanyLocation,
    },
  };
}

const hedgedStatusFilterActionType = 'HEDGED_STATUS_ACTION';
function hedgedStatusFilterAction(hedgedStatus) {
  return {
    type: hedgedStatusFilterActionType,
    payload: {
      hedgedStatus,
    },
  };
}

const receivedAtStartFilterActionType = 'RECEIVED_ON_START_ACTION';
function receivedAtStartFilterAction(receivedAtStart) {
  return {
    type: receivedAtStartFilterActionType,
    payload: {
      receivedAtStart,
    },
  };
}

const receivedAtEndFilterActionType = 'RECEIVED_ON_END_ACTION';
function receivedAtEndFilterAction(receivedAtEnd) {
  return {
    type: receivedAtEndFilterActionType,
    payload: {
      receivedAtEnd,
    },
  };
}

const lotsFiltersClearActionType = 'LOTS_FILTERS_CLEAR_ACTION';
function lotsFiltersClearAction() {
  return {
    type: lotsFiltersClearActionType,
  };
}

function statusForCustomerFilter(statusForCustomer) {
  return dispatch => {
    dispatch(statusForCustomerFilterAction(statusForCustomer));
  };
}

function statusForGlobalFilter(statusForGlobal) {
  return dispatch => {
    dispatch(statusForGlobalFilterAction(statusForGlobal));
  };
}

function materialTypeFilter(materialType) {
  return dispatch => {
    dispatch(materialTypeFilterAction(materialType));
  };
}

function sentFromCompanyLocationFilter(sentFromCompanyLocation) {
  return dispatch => {
    dispatch(sentFromCompanyLocationFilterAction(sentFromCompanyLocation));
  };
}

function hedgedStatusFilter(hedgedStatus) {
  return dispatch => {
    dispatch(hedgedStatusFilterAction(hedgedStatus));
  };
}

function receivedAtStartFilter(receivedAtStart) {
  return dispatch => {
    dispatch(receivedAtStartFilterAction(receivedAtStart));
  };
}

function receivedAtEndFilter(receivedAtEnd) {
  return dispatch => {
    dispatch(receivedAtEndFilterAction(receivedAtEnd));
  };
}

function lotsFiltersClear() {
  return dispatch => {
    dispatch(lotsFiltersClearAction());
  };
}

export {
  statusForCustomerFilterActionType,
  statusForCustomerFilterAction,
  statusForGlobalFilterActionType,
  statusForGlobalFilterAction,
  materialTypeFilterActionType,
  materialTypeFilterAction,
  sentFromCompanyLocationFilterActionType,
  sentFromCompanyLocationFilterAction,
  hedgedStatusFilterActionType,
  hedgedStatusFilterAction,
  receivedAtStartFilterActionType,
  receivedAtStartFilterAction,
  receivedAtEndFilterActionType,
  receivedAtEndFilterAction,
  lotsFiltersClearActionType,
  lotsFiltersClearAction,
  statusForCustomerFilter,
  statusForGlobalFilter,
  materialTypeFilter,
  sentFromCompanyLocationFilter,
  hedgedStatusFilter,
  receivedAtStartFilter,
  receivedAtEndFilter,
  lotsFiltersClear,
};
