import {
  getSummaryAvailableLotsActionType,
  getSummaryAvailableLotsSuccessActionType,
  getSummaryAvailableLotsFailureActionType,
  clearSummaryAvailableLotsActionType,
  getSummaryAvailableLotsForCompanySuccessActionType,
} from 'actions/PoolAccounts/getSummaryAvailableLots';

const INITIAL_STATE = {
  isPending: false,
  lots: null,
  errorMessage: null,
};

const getSummaryAvailableLotsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getSummaryAvailableLotsActionType:
      return {
        ...state,
        isPending: true,
        errorMessage: null,
      };
    case getSummaryAvailableLotsSuccessActionType:
      return {
        isPending: false,
        lots: action.payload.lots,
        errorMessage: null,
      };
    case getSummaryAvailableLotsForCompanySuccessActionType:
      return {
        isPending: false,
        lots: { ...state.lots, ...action.payload },
        errorMessage: null,
      };
    case getSummaryAvailableLotsFailureActionType:
      return {
        isPending: false,
        lots: null,
        errorMessage: action.payload.errorMessage,
      };
    case clearSummaryAvailableLotsActionType:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};

export { getSummaryAvailableLotsReducer };
