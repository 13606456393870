import { checkCustomLotNumber } from 'actions/Lots/checkCustomLotNumber';

import { message } from 'shared/helpers/constants/lots/createLotConstants';

const asyncValidate = async (
  { defaultPackagesValues: { customLotNumber } },
  dispatch,
  { assignedLot },
) => {
  return (
    customLotNumber &&
    assignedLot.customLotNumber !== customLotNumber &&
    dispatch(checkCustomLotNumber(customLotNumber)).then(async res =>
      res
        ? Promise.reject({
            defaultPackagesValues: { customLotNumber: message.INVALID_CUSTOM_LOT_NUMBER_UNIQUE },
          })
        : null,
    )
  );
};

export { asyncValidate };
