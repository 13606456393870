import {
  getShipmentsOverviewActionType,
  getShipmentsOverviewSuccessActionType,
  getShipmentsOverviewFailureActionType,
  clearShipmentsOverviewActionType,
} from 'actions/Shipments/lotShipmentsOverviewList';

const INITIAL_STATE = {
  isPending: false,
  shipments: [],
  pages: null,
  total: null,
};

const ShipmentsOverviewReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getShipmentsOverviewActionType:
      return {
        ...state,
        isPending: true,
      };
    case getShipmentsOverviewSuccessActionType:
      return {
        ...state,
        ...action.payload,
        isPending: false,
      };
    case getShipmentsOverviewFailureActionType:
      return {
        ...state,
        isPending: false,
      };
    case clearShipmentsOverviewActionType:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};

export { ShipmentsOverviewReducer };
