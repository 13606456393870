import {
  deleteShipmentActionType,
  deleteShipmentSuccessActionType,
  deleteShipmentFailureActionType,
} from 'actions/Shipments/deleteShipment';

const INITIAL_STATE = {
  isPending: false,
};

const DeleteShipmentReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case deleteShipmentActionType:
      return {
        isPending: true,
      };
    case deleteShipmentSuccessActionType:
    case deleteShipmentFailureActionType:
      return {
        isPending: false,
      };
    default:
      return state;
  }
};

export { DeleteShipmentReducer };
