const FINANCE_CHARGES = {
  FINANCE_CHARGE_EMPTY: 'Finance charge cannot be empty.',
  FINANCE_CHARGE_INVALID: 'Finance charge must have a valid format.',
  FINANCE_CHARGE_NEGATIVE: 'Finance charge cannot be less than 0.',
  FINANCE_CHARGE_RANGE: 'Finance charge cannot be greater than the payment amount.',
  FINANCE_RATE_EMPTY: 'Finance rate cannot be empty.',
  FINANCE_RATE_RANGE: 'Finance rate should be number between 0 and 100.',
  FINANCE_RATE_INVALID: 'Finance rate must have a valid format.',
  RELATED_LOT_EMPTY: 'Related lot is required.',
};

export { FINANCE_CHARGES };
