import { versions } from './config';

const name = 'Version resolver';

const createVersionResolver = versionEnvKey => {
  const version = process.env[versionEnvKey];
  const versionsArray = Object.values(versions);
  const versionsArrayStrings = versionsArray.map(s => s.toString());
  const versionSymbol = Symbol.for(version && version.toLowerCase());

  if (!versionsArray.includes(versionSymbol)) {
    throw new Error(
      `${name}: expected version to be one of: [${versionsArrayStrings.join(', ')}],` +
        ` instead got: ${versionSymbol.toString()}`,
    );
  }

  return versionedObject => {
    const isObject = typeof versionedObject === 'object';

    if (!isObject) {
      throw new Error(
        `${name}: expected versionedObject to be an object,` +
          ` instead got: ${typeof versionEnvKey}`,
      );
    }

    const versionedObjectKeys = Object.getOwnPropertySymbols(versionedObject);
    const versionedObjectKeysStrings = versionedObjectKeys.map(s => s.toString());

    if (versionsArray.some(key => !versionedObjectKeys.includes(key))) {
      throw new Error(
        `${name}: expected object with keys: [${versionsArrayStrings.join(', ')}],` +
          ` instead got: [${versionedObjectKeysStrings.join(', ')}]`,
      );
    }

    return versionedObject[versionSymbol];
  };
};

export { createVersionResolver };
