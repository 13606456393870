const COMMODITIES = {
  COMMODITIES: 'Commodities',
  FROM_LOT: 'From lot',
  TO_LOT: 'To lot',
  REMOVE_COMMODITY: 'Remove commodity from lot',
  MATERIAL_TYPE: 'Material type',
  MATERIAL_UNITS: 'Material units',
  MATERIAL_WEIGHT: 'Material weight',
  CREATED_BY: 'Created by',
  CREATED_ON: 'Created on',
  WEIGHTS_UNITS: 'Weights / Units',
};

export { COMMODITIES };
