const companyDetailsSections = {
  agreement: {
    name: 'agreements',
    title: 'Agreements',
  },
  location: {
    name: 'locations',
    title: 'Locations',
  },
  term: {
    name: 'terms',
    title: 'Terms',
  },
  user: {
    name: 'users',
    title: 'Users',
  },
};

export { companyDetailsSections };
