import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getNotesActionType = 'GET_NOTES';
function getNotesAction() {
  return {
    type: getNotesActionType,
  };
}

const getNotesSuccessActionType = 'GET_NOTES_SUCCESS';
function getNotesSuccessAction(notes, pages, total) {
  return {
    type: getNotesSuccessActionType,
    payload: {
      notes,
      pages,
      total,
    },
  };
}

const getNotesFailureActionType = 'GET_NOTES_FAILURE';
function getNotesFailureAction(errorMessage) {
  return {
    type: getNotesFailureActionType,
    payload: {
      errorMessage,
    },
  };
}

const clearNotesListActionType = 'CLEAR_NOTES_LIST_ACTION';
function clearNotesListAction() {
  return {
    type: clearNotesListActionType,
  };
}

function getNotes({ relatedType, subType, relatedId, listLimit = 3, listPage = 1 }) {
  return dispatch => {
    dispatch(getNotesAction());

    return axios
      .get(`${API_HOST_AUTH}/notes/${relatedType}/${relatedId}`, {
        params: {
          subType,
          limit: listLimit,
          page: listPage,
        },
      })
      .then(res => {
        dispatch(getNotesSuccessAction(res.data.docs, res.data.pages, res.data.total));
        return res.data;
      })
      .catch(() => {
        dispatch(getNotesFailureAction(''));
      });
  };
}

function clearNotesList() {
  return dispatch => {
    dispatch(clearNotesListAction());
  };
}

export {
  getNotesActionType,
  getNotesSuccessActionType,
  getNotesFailureActionType,
  clearNotesListActionType,
  getNotes,
  clearNotesList,
};
