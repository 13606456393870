import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  agreementDocumentClearAction,
  agreementDocumentAddedAction,
  uploadAgreementDocument,
} from 'actions/Agreements/uploadAgreementDocument';
import { showSnackbar } from 'actions/shared/snackbar';

import { AgreementUploadSigned } from './AgreementUploadSigned';

const mapStateToProps = state => ({
  agreementsUploadDocument: state.agreementsUploadDocument,
  agreementsDetails: state.agreementsDetails,
});

const mapActionsToProps = dispatch =>
  bindActionCreators(
    {
      uploadAgreementDocumentAction: uploadAgreementDocument,
      agreementDocumentAddedAction,
      agreementDocumentClearAction,
      showSnackbar,
    },
    dispatch,
  );

const connectedComponent = connect(mapStateToProps, mapActionsToProps)(AgreementUploadSigned);
export { connectedComponent as ConnectedAgreementUploadSigned };
