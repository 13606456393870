import axios from 'axios';

import { PACKAGES } from 'shared/helpers/constants/packages/packageConstants';
import { messageTypes } from 'shared/helpers/constants/snackbar/snackbarMessageTypes';
import { parsePackageData } from 'shared/helpers/dataParsers/packages/parsePackageData';

const API_HOST_AUTH = process.env.REACT_APP_API;

const createMultiplePackagesActionType = 'CREATE_MULTIPLE_PACKAGES';
const createMultiplePackagesAction = () => ({
  type: createMultiplePackagesActionType,
});

const createMultiplePackagesSuccessActionType = `${createMultiplePackagesActionType}_SUCCESS`;
const createMultiplePackagesSuccessAction = () => ({
  type: createMultiplePackagesSuccessActionType,
});

const createMultiplePackagesFailureActionType = `${createMultiplePackagesActionType}_FAILURE`;
const createMultiplePackagesFailureAction = () => ({
  type: createMultiplePackagesFailureActionType,
});

const createMultiplePackagesFactory = url => data => dispatch => {
  dispatch(createMultiplePackagesAction());

  return axios
    .post(`${API_HOST_AUTH}${url}`, {
      packages: data.packages.map(pkg => parsePackageData(pkg, data.samplePackages)),
    })
    .then(res => {
      dispatch(createMultiplePackagesSuccessAction());

      return {
        message: res.data.message,
        messageType: messageTypes.success,
        packages: res.data.id,
      };
    })
    .catch(err => {
      dispatch(createMultiplePackagesFailureAction());

      return Promise.reject({
        message: `${PACKAGES.CREATE_PACKAGES_ERROR} ${err.data.message}`,
        messageType: messageTypes.error,
      });
    });
};

const createMultiplePackages = createMultiplePackagesFactory('/packages/multiple');
const createMultiplePackagesForCustomer = createMultiplePackagesFactory(
  '/packages/incoming/customer/multiple',
);

const createMultiplePackagesForPackingList = createMultiplePackagesFactory(
  '/packages/incoming/customer/multiple/material-count-required',
);

const createMultiplePackagesForGlobal = createMultiplePackagesFactory(
  '/packages/incoming/global/multiple',
);
const createMultipleProcessingPackages = (data, packageType) =>
  createMultiplePackagesFactory(`/packages/processing/${packageType}`)(data);

export {
  createMultiplePackagesActionType,
  createMultiplePackagesAction,
  createMultiplePackagesSuccessActionType,
  createMultiplePackagesSuccessAction,
  createMultiplePackagesFailureActionType,
  createMultiplePackagesFailureAction,
  createMultiplePackages,
  createMultiplePackagesForCustomer,
  createMultiplePackagesForGlobal,
  createMultipleProcessingPackages,
  createMultiplePackagesForPackingList,
};
