import { COMPANY } from 'shared/helpers/constants/companies/companyConstants';
import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';
import { PAYMENTS } from 'shared/helpers/constants/payments/paymentsConstants';
import { SETTLEMENTS } from 'shared/helpers/constants/settlements/settlementsConstants';
import { SHARED } from 'shared/helpers/constants/sharedConstants';
import { setDesignation } from 'shared/helpers/dataParsers/lot/setDesignation';
import { dateFormat } from 'shared/helpers/parsers/date';
import { formatDate } from 'shared/helpers/parsers/formaters';
import { capitalizeGlobal, humanize } from 'shared/helpers/parsers/text';

import { history } from 'utils/history';

export const itemsDetailsTemplate = [
  {
    label: LOTS.STATUS_FOR_GLOBAL,
    render: ({ statusForGlobal }) => capitalizeGlobal(humanize(statusForGlobal)),
  },
  {
    label: PAYMENTS.REQUESTED_FINAL_PAYMENT_DATE,
    render: ({ suggestedFinalPaymentDate }) => formatDate(suggestedFinalPaymentDate, dateFormat),
  },
  {
    label: SETTLEMENTS.SETTLEMENT_DATE_SWC,
    render: ({ settlements }) =>
      settlements.length ? (
        <>
          {settlements.map(({ updatedAt }, i) => (
            <div key={i}>{formatDate(updatedAt, dateFormat) || SHARED.AWAITING_STATUS}</div>
          ))}
        </>
      ) : null,
  },
  {
    label: SETTLEMENTS.SETTLEMENT_REQUEST_DATE_GRG,
    render: ({ settlements }) =>
      settlements.length ? (
        <>
          {settlements.map(({ createdAt }, i) => (
            <div key={i}>{formatDate(createdAt, dateFormat)}</div>
          ))}
        </>
      ) : null,
  },
];

export const itemsTemplateMobile = [
  {
    label: COMPANY.COMPANY_NAME,
    render: ({ sentFromCompany }) => sentFromCompany && sentFromCompany[0].companyName,
  },
  {
    label: LOTS.GRG_LOT_NUMBER,
    render: ({ grgLotNumber, customLotNumber, designation }) => {
      const lotName = setDesignation(grgLotNumber, designation);

      return (!customLotNumber && lotName) || [lotName, customLotNumber];
    },
    highlight: true,
    includeRegExp: true,
  },
];

export const itemsTemplate = [...itemsTemplateMobile, ...itemsDetailsTemplate];

export const actionsTemplate = handleToggleModal => [
  {
    icon: 'add',
    action: 'Add note',
    onClick:
      ({ _id }) =>
      () =>
        handleToggleModal({ _id }),
  },
  {
    icon: 'arrow-right',
    action: 'Details',
    onClick:
      ({ _id }) =>
      () =>
        _id && history.push(`/lots/list/${_id}`),
  },
];
