import axios from 'axios';

import { COMPANY } from 'shared/helpers/constants/companies/companyConstants';

const API_HOST_AUTH = process.env.REACT_APP_API;

const reactivateCompanyActionType = 'REACTIVATE_COMPANY';
const reactivateCompanyAction = () => ({
  type: reactivateCompanyActionType,
});

const reactivateCompanySuccessActionType = `${reactivateCompanyActionType}_SUCCESS`;
const reactivateCompanySuccessAction = ({ company }) => ({
  type: reactivateCompanySuccessActionType,
  payload: { company },
});

const reactivateCompanyFailureActionType = `${reactivateCompanyActionType}_FAILURE`;
const reactivateCompanyFailureAction = () => ({
  type: reactivateCompanyFailureActionType,
});

const reactivateCompany = id => dispatch => {
  dispatch(reactivateCompanyAction());

  return axios
    .patch(`${API_HOST_AUTH}/companies/reactivate/${id}`)
    .then(response => {
      dispatch(reactivateCompanySuccessAction(response.data));

      return { message: response.data.message };
    })
    .catch(() => {
      dispatch(reactivateCompanyFailureAction());

      return Promise.reject({ message: COMPANY.REACTIVATE_COMPANY_ERROR });
    });
};

export {
  reactivateCompanyActionType,
  reactivateCompanyAction,
  reactivateCompanySuccessActionType,
  reactivateCompanySuccessAction,
  reactivateCompanyFailureActionType,
  reactivateCompanyFailureAction,
  reactivateCompany,
};
