import React from 'react';
import styled from 'styled-components';

import { cssVar } from 'shared/helpers/styling/styling';

import { DropdownMenu } from '../styles/SelectDropdown.styles';

const MenuOption = styled.div`
  background-color: ${cssVar('shuttleGray')};
  border-radius: 5px;
`;

function MenuDropdown({ id, renderDropdownItems }) {
  return (
    <DropdownMenu id={`${id}-menu`}>
      <MenuOption>{renderDropdownItems}</MenuOption>
    </DropdownMenu>
  );
}

export { MenuDropdown };
