import React from 'react';

import { PackageForm } from '../PackageForm/components/PackageForm/PackageForm';

const ManagePackage = props => (
  <PackageForm
    onSubmit={props.onSubmit}
    forSpecifiedLot
    config={props.config}
    editMode={props.editMode}
    updatePackageWeight={props.updatePackageWeight}
    initialValues={props.initialValues}
  />
);

export { ManagePackage };
