import { isMobile } from 'shared/helpers/styling/styling';

const linksConfig = {
  email: {
    linkPrefix: 'mailto',
  },
  sms: {
    linkPrefix: 'sms',
    conditionalDisplay: () => isMobile(),
  },
  call: {
    linkPrefix: 'tel',
    conditionalDisplay: () => isMobile(),
  },
};

const SpecialLink = ({ href, type, children }) =>
  (linksConfig[type]?.conditionalDisplay ? linksConfig[type]?.conditionalDisplay() : true) && (
    <a href={`${linksConfig[type].linkPrefix}:${href}`}>{children}</a>
  );

export { SpecialLink };
