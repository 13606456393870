import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getSignedTermsActionType = 'GET_SIGNED_TERMS';
function getSignedTermsAction({ advanceRate }) {
  return {
    type: getSignedTermsActionType,
    payload: { advanceRate },
  };
}

const getSignedTermsPendingActionType = `${getSignedTermsActionType}_PENDING`;
function getSignedTermsPendingAction() {
  return { type: getSignedTermsPendingActionType };
}

function getSignedTerms(companyId) {
  return dispatch => {
    dispatch(getSignedTermsPendingAction());

    return axios({
      method: 'get',
      url: `${API_HOST_AUTH}/terms/signedTerms/${companyId}`,
    })
      .then(res => dispatch(getSignedTermsAction(res.data || { advanceRate: null })))
      .catch(() => dispatch(getSignedTermsAction({ advanceRate: null })));
  };
}

export { getSignedTermsPendingActionType, getSignedTermsActionType, getSignedTerms };
