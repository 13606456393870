import { removeCommasAndParse } from 'shared/helpers/parsers/text';

const parseAdvancePaymentData = payment => ({
  relatedLot: payment.relatedLot.value,
  fundsType: payment.fundsType.value,
  paymentAmount: removeCommasAndParse(payment.paymentAmount),
  additionalInfo: payment.additionalInfo,
});

export { parseAdvancePaymentData };
