import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getLotsForProcessingOverviewType = 'GET_LOTS_FOR_PROCESSING_OVERVIEW';
const getLotsForProcessingOverviewAction = () => ({
  type: getLotsForProcessingOverviewType,
});

const getLotsForProcessingOverviewSuccessType = `${getLotsForProcessingOverviewType}_SUCCESS`;
const getLotsForProcessingOverviewSuccessAction = lots => ({
  type: getLotsForProcessingOverviewSuccessType,
  payload: {
    lots,
  },
});

const getLotsForProcessingOverviewFailureType = `${getLotsForProcessingOverviewType}_FAILURE`;
const getLotsForProcessingOverviewFailureAction = () => ({
  type: getLotsForProcessingOverviewFailureType,
});

const changeLotsInProcessingOverviewType = 'CHANGE_LOTS_FOR_PROCESSING_OVERVIEW';
const changeLotsInProcessingOverviewAction = difference => ({
  type: changeLotsInProcessingOverviewType,
  payload: {
    difference,
  },
});

const changeLotsInProcessingOverviewSuccessType = `${changeLotsInProcessingOverviewType}_SUCCESS`;
const changeLotsInProcessingOverviewSuccessAction = () => ({
  type: changeLotsInProcessingOverviewSuccessType,
});

const changeLotsInProcessingOverviewFailureType = `${changeLotsInProcessingOverviewSuccessType}_FAILURE`;
const changeLotsInProcessingOverviewFailureAction = () => ({
  type: changeLotsInProcessingOverviewFailureType,
});

const clearProcessingOverviewActionType = 'CLEAR_PROCESSING_OVERVIEW';
const clearProcessingOverviewAction = () => ({
  type: clearProcessingOverviewActionType,
});

const getLotsForProcessingOverview = () => dispatch => {
  dispatch(getLotsForProcessingOverviewAction());

  return axios
    .get(`${API_HOST_AUTH}/lots/processing-overview`)
    .then(lots => dispatch(getLotsForProcessingOverviewSuccessAction(lots.data)))
    .catch(err => {
      dispatch(getLotsForProcessingOverviewFailureAction());

      return Promise.reject(err);
    });
};

const changeLotsInProcessingOverview = (difference, destination) => dispatch => {
  dispatch(changeLotsInProcessingOverviewAction(difference));

  return axios
    .put(`${API_HOST_AUTH}/lots/processing-overview`, {
      [destination]: difference[destination].map(lot => lot._id),
    })
    .then(() => dispatch(changeLotsInProcessingOverviewSuccessAction()))
    .catch(err => {
      dispatch(changeLotsInProcessingOverviewFailureAction());

      return Promise.reject(err);
    });
};

export {
  getLotsForProcessingOverviewType,
  getLotsForProcessingOverviewSuccessType,
  getLotsForProcessingOverviewFailureType,
  getLotsForProcessingOverview,
  changeLotsInProcessingOverviewType,
  changeLotsInProcessingOverviewSuccessType,
  changeLotsInProcessingOverviewFailureType,
  changeLotsInProcessingOverview,
  clearProcessingOverviewActionType,
  clearProcessingOverviewAction,
};
