import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const requestUpdateMarketIndicatorsType = 'REQUEST_UPDATE_MARKET_INDICATORS';
const requestUpdateMarketIndicatorsAction = () => ({
  type: requestUpdateMarketIndicatorsType,
});

const requestUpdateMarketIndicatorsSuccessType = `${requestUpdateMarketIndicatorsType}_SUCCESS`;
const requestUpdateMarketIndicatorsSuccessAction = () => ({
  type: requestUpdateMarketIndicatorsSuccessType,
});

const requestUpdateMarketIndicatorsFailureType = `${requestUpdateMarketIndicatorsType}_FAILURE`;
const requestUpdateMarketIndicatorsFailureAction = () => ({
  type: requestUpdateMarketIndicatorsFailureType,
});

const requestUpdateMarketIndicators = data => async dispatch => {
  dispatch(requestUpdateMarketIndicatorsAction());

  try {
    const response = await axios.post(`${API_HOST_AUTH}/market-indicators/request-update`, data);
    dispatch(requestUpdateMarketIndicatorsSuccessAction());

    return response.data;
  } catch (error) {
    dispatch(requestUpdateMarketIndicatorsFailureAction());

    return Promise.reject(error.data);
  }
};

export {
  requestUpdateMarketIndicatorsType,
  requestUpdateMarketIndicatorsSuccessType,
  requestUpdateMarketIndicatorsFailureType,
  requestUpdateMarketIndicators,
};
