import { connect } from 'react-redux';

import { markAllResolvedAsRead } from 'actions/Notifications/markAllResolvedAsRead';
import { openModal } from 'actions/shared/modal';
import { showSnackbar } from 'actions/shared/snackbar';

import { markAllReadAsResolvedModal } from 'shared/helpers/constants/modalConstants';
import {
  dangerOptions,
  successOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

function MarkAllResolvedAsReadLayout(props) {
  const markAllResolvedAsReadAction = () => {
    props.openModal(markAllReadAsResolvedModal, () => {
      props
        .markAllResolvedAsRead()
        .then(res => {
          props.fetchList();
          props.showSnackbar(successOptions, res.message);
        })
        .catch(err => props.showSnackbar(dangerOptions, err.message));
    });
  };

  return props.children({ markAllResolvedAsRead: markAllResolvedAsReadAction });
}

const mapDispatchToProps = {
  openModal,
  showSnackbar,
  markAllResolvedAsRead,
};

const MarkAllResolvedAsRead = connect(null, mapDispatchToProps)(MarkAllResolvedAsReadLayout);

export { MarkAllResolvedAsRead, MarkAllResolvedAsReadLayout };
