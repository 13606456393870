import {
  agreementsFiltersClearActionType,
  agreementsStatusFilterActionType,
} from 'actions/Agreements/agreementsFilters';

const INITIAL_STATE = {
  status: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case agreementsStatusFilterActionType:
      return { ...state, status: action.payload.status };
    case agreementsFiltersClearActionType:
      return { status: [] };
    default:
      return state;
  }
};
