import React, { Component } from 'react';

import { GroupButtons } from 'shared/components/Buttons';
import { GroupButton } from 'shared/components/Buttons/GroupButton/components/GroupButton';
import { USER } from 'shared/helpers/constants/users/userConstants';
import {
  itemsDetailsTemplate,
  itemsDetailsTemplateCustomer,
  itemsTemplate,
  itemsTemplateCustomer,
  itemsTemplateMobile,
  itemsTemplateMobileCustomer,
} from 'shared/helpers/constants/users/usersListTableConfig';
import { isCustomerUser, isOwnerUser, isSupportUser } from 'shared/helpers/matchers/checkUserType';

import { StyledUsersList } from './StyledUserList';
import { UsersFilters } from './components/UsersFilters/UsersFilters';

class UsersList extends Component {
  componentWillUnmount() {
    this.props.clearUsersList();
  }

  onFiltersChanged = searchFilter => filters => {
    const {
      getUsers,
      usersList: { limit },
      accessLevelFilter,
      userStatusFilter,
      userTypeFilter,
    } = this.props;
    getUsers(limit, 1, searchFilter, filters);
    userStatusFilter(filters.status);
    userTypeFilter(filters.userType);
    accessLevelFilter(filters.accessLevel);
  };

  get tableActions() {
    const {
      auth: {
        access: { users },
      },
      history,
    } = this.props;

    const actions = [];

    if (users.details.details) {
      actions.push({
        icon: 'arrow-right',
        action: 'Details',
        onClick: thisUser => () => {
          history.push(`/users/${thisUser._id}`);
        },
      });
    }

    return actions;
  }

  filtersClear = searchFilter => {
    const {
      getUsers,
      userFiltersClear,
      usersList: { limit },
    } = this.props;

    getUsers(limit, 1, searchFilter, { status: [], userType: '', accessLevel: [] });
    userFiltersClear();
  };

  fetchList = (page = 1, limit = 50, query = '') => {
    const { getUsers, userFilters, auth } = this.props;
    const parsedFilters = isCustomerUser(auth.user.userType)
      ? { ...userFilters, status: [] }
      : userFilters;
    getUsers(limit, page, query, parsedFilters);
  };

  renderFilters = searchFilter => {
    const {
      auth,
      usersList: { page, limit },
      userFilters,
    } = this.props;

    return (
      <UsersFilters
        filters={userFilters}
        pagination={{ page, limit }}
        onFiltersChanged={this.onFiltersChanged(searchFilter)}
        auth={auth}
      />
    );
  };

  renderActionButtons = () => {
    const {
      auth: {
        user: { userType },
        access: { users },
      },
      history,
    } = this.props;

    const actions = [
      {
        access: users.create,
        onClick: () => history.push('/users/create'),
        label: USER.CREATE_A_USER,
        icon: 'create-user',
      },
      {
        access: isOwnerUser(userType) || isSupportUser(userType),
        onClick: this.props.generateUsersReport,
        label: USER.GENERATE_REPORT,
        icon: 'add',
      },
    ];

    return (
      <GroupButtons>
        {actions.map(action => (
          <GroupButton key={action.label} {...action} />
        ))}
      </GroupButtons>
    );
  };

  render() {
    const {
      usersList: { pages, page, limit, items, isPending },
      userFilters,
      auth: {
        user: { userType },
      },
    } = this.props;

    const filtersSelected =
      userFilters.status.length || userFilters.accessLevel.length || userFilters.userType.length;

    return (
      <StyledUsersList
        title={USER.USERS}
        itemsTemplate={isCustomerUser(userType) ? itemsTemplateCustomer : itemsTemplate}
        itemsDetailsTemplate={
          isCustomerUser(userType) ? itemsDetailsTemplateCustomer : itemsDetailsTemplate
        }
        itemsTemplateMobile={
          isCustomerUser(userType) ? itemsTemplateMobileCustomer : itemsTemplateMobile
        }
        actionsTemplate={this.tableActions}
        items={items.docs || []}
        fetchList={this.fetchList}
        filterComponent={this.renderFilters}
        filterAccess={!isCustomerUser(userType)}
        pagination={{ limit, page, pages }}
        enableSearch
        filtersClear={this.filtersClear}
        filtersSelected={filtersSelected}
        actionsContext={this}
        actionButtons={this.renderActionButtons}
        isPending={isPending}
        filters={userFilters}
      />
    );
  }
}

export { UsersList };
