import { message } from 'shared/helpers/constants/agreements/uploadSignedDocument';
import { FILES_CONSTANTS } from 'shared/helpers/constants/filesConstants';

function validate(values, props) {
  const { files } = props.agreementsUploadDocument;
  const errors = {};

  if (!files) {
    errors.signedDocument = message.INVALID_FILE;
  }

  if (files && files.length !== 1) {
    errors.signedDocument = message.INVALID_FILE_NUMBER;
  }

  if (files && files.length > 0 && files[0].size > FILES_CONSTANTS.MAX_FILE_SIZE) {
    errors.signedDocument = message.INVALID_FILE_SIZE;
  }

  if (files && files.length > 0 && !/\.pdf$/i.test(files[0].name)) {
    errors.signedDocument = message.INVALID_FILE_TYPE;
  }

  return errors;
}

export { validate };
