import {
  requestPasswordResetActionType,
  requestPasswordResetFailureActionType,
  requestPasswordResetSuccessActionType,
} from 'actions/Users/requestPasswordReset';

const INITIAL_STATE = {
  isPending: false,
};

const requestPasswordResetReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case requestPasswordResetActionType:
      return {
        isPending: true,
      };
    case requestPasswordResetSuccessActionType:
    case requestPasswordResetFailureActionType:
      return {
        isPending: false,
      };
    default:
      return state;
  }
};

export { requestPasswordResetReducer };
