import PropTypes from 'prop-types';
import styled from 'styled-components';

import { cssVar, media } from 'shared/helpers/styling/styling';

const Disclaimer = styled.h6`
  margin: 26px 0 26px 37px;
  color: ${({ color }) => cssVar(color)};

  ${media.mobile`
    margin-left: 0;
  `}
`;

Disclaimer.propTypes = {
  color: PropTypes.string,
};

Disclaimer.defaultProps = {
  color: 'alabaster',
};

export { Disclaimer };
