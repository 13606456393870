const companiesBreadcrumbsRoutes = {
  '/companies': 'Companies',
  '/companies/create': 'Create company',
  '/companies/:id': 'Company details',
  '/companies/:id/update': 'Update company',
  '/companies/:id/location': null,
  '/companies/:id/location/create': 'Create location',
  '/companies/:id/locations': 'Locations',
  '/companies/:id/create-note': 'Create note',
  '/companies/:companyId/locations/:id': 'Location details',
  '/companies/:companyId/locations/:id/update': 'Update location',
  '/companies/:id/agreement': null,
  '/companies/:id/agreement/create': 'Create agreement',
  '/companies/:id/agreements': 'Agreements',
  '/companies/:companyId/agreements/:id': 'Agreement details',
  '/companies/:companyId/term': null,
  '/companies/:companyId/term/:termType': 'Create terms',
  '/companies/:companyId/terms': 'Terms',
  '/companies/:companyId/terms/:id': 'Terms details',
  '/companies/:companyId/terms/:id/update': 'Update terms',
  '/companies/:id/users': null,
  '/companies/:id/users/create': 'Create user',
  '/companies/:companyId/users': null,
  '/companies/:companyId/users/:id': 'User details',
  '/companies/:companyId/users/:id/update': 'Update user',
  '/companies/:companyId/users/:userId/change-password': 'Change password',
};

export { companiesBreadcrumbsRoutes };
