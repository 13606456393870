import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const changeDeliveryDateType = 'CHANGE_DELIVERY_DATE';
const changeDeliveryDateAction = () => ({
  type: changeDeliveryDateType,
});

const changeDeliveryDateSuccessType = `${changeDeliveryDateType}_SUCCESS`;
const changeDeliveryDateSuccessAction = () => ({
  type: changeDeliveryDateSuccessType,
});

const changeDeliveryDateFailureType = `${changeDeliveryDateType}_FAILURE`;
const changeDeliveryDateFailureAction = () => ({
  type: changeDeliveryDateFailureType,
});

const changeDeliveryDate = (lotId, data) => async dispatch => {
  try {
    dispatch(changeDeliveryDateAction());
    const response = await axios.patch(`${API_HOST_AUTH}/lots/${lotId}/received-date`, data);
    dispatch(changeDeliveryDateSuccessAction());

    return response.data;
  } catch (error) {
    dispatch(changeDeliveryDateFailureAction());

    return Promise.reject(error.data);
  }
};

export {
  changeDeliveryDateType,
  changeDeliveryDateSuccessType,
  changeDeliveryDateFailureType,
  changeDeliveryDate,
};
