import React, { Component } from 'react';

import { UIAuthorization } from 'components/Authorization/UIAuthorization';

import { ActionsContainer } from 'shared/components/ActionsContainer';
import { RouterButton, GroupButtons } from 'shared/components/Buttons';
import { GroupActionButton } from 'shared/components/Buttons/GroupButton/components/GroupActionButton';
import { GroupButton } from 'shared/components/Buttons/GroupButton/components/GroupButton';
import { FullPageList } from 'shared/components/FullPageList/FullPageList';
import { Preloader } from 'shared/components/Preloader/Preloader';
import { FILES_CONSTANTS } from 'shared/helpers/constants/filesConstants';
import { message } from 'shared/helpers/constants/hedges/createMarketHolidaysConstants';
import { HEDGES } from 'shared/helpers/constants/hedges/hedgesConstants';
import {
  itemsTemplate,
  itemsTemplateCustomer,
  itemsDetailsTemplate,
  itemsDetailsTemplateCustomer,
  itemsTemplateMobile,
  actionsTemplateCustomer,
} from 'shared/helpers/constants/hedges/hedgesListTableConfig';
import { POOL_ACCOUNTS } from 'shared/helpers/constants/poolAccounts/poolAccountsConstants';
import {
  successOptions,
  dangerOptions,
  infoOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { isCustomerUser } from 'shared/helpers/matchers/checkUserType';
import { isGlobalUserType } from 'shared/helpers/matchers/isGlobalUserType';
import { isMobile } from 'shared/helpers/styling/styling';

import { RequestMarketIndicators } from 'pages/Settings/components/RequestMarketIndicators/RequestMarketIndicators';

import { MarketHoursRouterButton } from '../HedgesOverview/components/MarketHoursRouterButtton/MarketHoursRouterButton';
import { HedgesFilters } from './components/HedgesFilters/HedgesFilters';

class HedgesList extends Component {
  state = {
    requestIndicatorsModal: false,
  };

  componentDidMount() {
    const {
      auth: {
        user: { userType },
      },
      match: {
        params: { id, fileName },
      },
      downloadAttachment,
      history,
      getHedgeTotalValue,
    } = this.props;

    isGlobalUserType(userType) && getHedgeTotalValue();

    if (id && fileName) {
      downloadAttachment(`hedges/files/${id}/${fileName}`).then(() => history.push('/hedges'));
    }
  }

  componentWillUnmount() {
    const {
      auth: {
        user: { userType },
      },
      clearHedgesAction,
      clearHedgeTotalValue,
    } = this.props;

    isGlobalUserType(userType) && clearHedgeTotalValue();
    clearHedgesAction();
  }

  onFiltersChanged = searchFilter => filters => {
    const {
      getHedges,
      hedgesList: {
        pagination: { limit },
      },
    } = this.props;
    getHedges({ ...filters, query: searchFilter }, { page: 1, limit });
  };

  get tableActions() {
    const {
      auth: {
        access: { hedges },
      },
      history,
    } = this.props;

    const actions = [];

    if (hedges.details) {
      actions.push({
        icon: 'arrow-right',
        action: 'Details',
        onClick: hedge => () => {
          history.push(`/hedges/${hedge._id}`);
        },
      });
    }

    return actions;
  }

  deleteHedge = hedge => {
    const {
      hedgesList: { filters, pagination },
      getHedges,
      deleteHedge,
      showSnackbar,
    } = this.props;

    deleteHedge(hedge)
      .then(res => {
        getHedges(filters, pagination);
        showSnackbar(successOptions, res.message);
      })
      .catch(() => showSnackbar(dangerOptions, message.HEDGES_DELETE_ERROR));
  };

  filtersClear = searchFilter => {
    const {
      getHedges,
      hedgesList: { pagination },
    } = this.props;

    getHedges({ status: [], query: searchFilter }, { limit: pagination.limit, page: 1 });
  };

  fetchList = (page = 1, limit = 50, query) => {
    const {
      getHedges,
      hedgesList: { filters },
    } = this.props;
    getHedges({ ...filters, query }, { page, limit });
  };

  toggleRequestIndicatorsModal = () => {
    const { marketAvailabilityDetails, showSnackbar } = this.props;

    if (this.state.requestIndicatorsModal) {
      return this.setState({
        requestIndicatorsModal: false,
      });
    }

    const { marketHoliday, inMarketHours, marketHours, marketDays, timezone } =
      marketAvailabilityDetails;

    if (marketHoliday) {
      return showSnackbar(infoOptions, HEDGES.REQUEST_INDICATORS_MARKET_HOLIDAY);
    }
    if (!inMarketHours) {
      return showSnackbar(
        infoOptions,
        HEDGES.REQUEST_INDICATORS_MARKET_HOURS(marketHours, timezone, marketDays),
      );
    }

    this.setState({
      requestIndicatorsModal: true,
    });
  };

  renderFilters = searchFilter => {
    const {
      hedgesList: { filters, pagination },
      auth,
    } = this.props;

    return (
      <HedgesFilters
        filters={filters}
        pagination={pagination}
        onFiltersChanged={this.onFiltersChanged(searchFilter)}
        auth={auth}
      />
    );
  };

  renderRouterButton = ({ access, ...route }) => (
    <UIAuthorization access={access} key={route.path}>
      <RouterButton id={route.path} {...route} />
    </UIAuthorization>
  );

  settleLot = () => this.props.history.push('/pool-accounts/settlement-for-lot');

  renderActionButtons = () => {
    const {
      auth: {
        user: {
          userType,
          relatedCompany: { materialCountRequired },
        },
        access: { hedges, poolAccounts, systemSettings },
      },
    } = this.props;

    const routerActions = [
      {
        access: hedges.enterPlaced,
        path: '/hedges/enter-placed',
        label: HEDGES.ADD_EXISTING_HEDGE,
      },
      {
        access: hedges.defaultPPMs,
        path: '/hedges/default-ppms',
        label: HEDGES.DEFAULT_PPMS.BUTTON_TITLE,
      },
      {
        access: hedges.assignHedgeOunces,
        path: '/hedges/assign-ounces',
        label: HEDGES.ASSIGN_HEDGE_OUNCES,
      },
      {
        access: poolAccounts.enterSettledOunces,
        path: '/hedges/enter-settled-ounces',
        label: HEDGES.ENTER_SETTLED_OUNCES,
      },
      {
        access: hedges.marketHolidays.list && isMobile(),
        path: '/hedges/market-holidays',
        label: HEDGES.MARKET_HOLIDAYS.TITLE,
      },
      {
        access: hedges.trustedCompanies.list && isMobile(),
        path: '/hedges/trusted-companies',
        label: HEDGES.TRUSTED_COMPANIES.TITLE,
      },
      {
        access: hedges.recipients.list && isMobile(),
        path: '/hedges/recipients',
        label: HEDGES.RECIPIENTS.TITLE,
      },
      {
        access: hedges.hedgeLots && isMobile(),
        path: '/hedges/hedge-ounces',
        label: HEDGES.HEDGE_LOTS.TITLE,
      },
    ];

    return (
      <ActionsContainer>
        <GroupButtons>
          <MarketHoursRouterButton
            access={hedges.create}
            path="/hedges/create"
            label={HEDGES.CREATE}
            module="Hedges"
            icon="hedge"
            userType={userType}
            materialCountRequired={materialCountRequired}
          />
          <GroupButton
            access={poolAccounts.requestSettlement.forLot && isCustomerUser(userType)}
            onClick={this.settleLot}
            label={POOL_ACCOUNTS.SETTLE_LOTS}
            icon="close-lot"
          />
          <GroupButton
            access={systemSettings.marketIndicators.request}
            onClick={this.toggleRequestIndicatorsModal}
            label={HEDGES.REQUEST_INDICATORS}
            icon="edit"
          />
          {isGlobalUserType(userType) && (
            <GroupActionButton>{routerActions.map(this.renderRouterButton)}</GroupActionButton>
          )}
        </GroupButtons>
      </ActionsContainer>
    );
  };

  render() {
    const {
      auth: {
        user: { userType },
      },
      hedgesList: { pagination, hedges, filters, isPending },
      hedgeTotalValue: { hedgeTotal },
      downloadAttachment,
      downloadAttachmentStorage,
    } = this.props;

    const filtersSelected =
      filters.status.length ||
      filters.deliveryDateStart ||
      filters.deliveryDateEnd ||
      filters.placedAtStart ||
      filters.placedAtEnd;
    const filterAccess = true;

    return (
      <>
        <FullPageList
          title={HEDGES.HEDGES}
          subheader={isGlobalUserType(userType) && HEDGES.TOTAL_VALUE_OF_UNFILLED(hedgeTotal)}
          itemsTemplate={isCustomerUser(userType) ? itemsTemplateCustomer : itemsTemplate}
          itemsDetailsTemplate={
            isCustomerUser(userType) ? itemsDetailsTemplateCustomer : itemsDetailsTemplate
          }
          itemsTemplateMobile={itemsTemplateMobile}
          actionsTemplate={
            isCustomerUser(userType)
              ? actionsTemplateCustomer(downloadAttachment)
              : this.tableActions
          }
          items={hedges.docs || []}
          fetchList={this.fetchList}
          filterComponent={this.renderFilters}
          filterAccess={filterAccess}
          pagination={{ ...pagination, pages: hedges.pages }}
          enableSearch
          filtersClear={this.filtersClear}
          filtersSelected={filtersSelected}
          actionsContext={this}
          actionButtons={this.renderActionButtons}
          isPending={isPending}
          filters={filters}
        />
        <Preloader
          loading={downloadAttachmentStorage.isPending}
          label={FILES_CONSTANTS.DOWNLOADING_FILES}
        />
        <RequestMarketIndicators
          isOpen={this.state.requestIndicatorsModal}
          toggle={this.toggleRequestIndicatorsModal}
        />
      </>
    );
  }
}

export { HedgesList };
