import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getRecipientsActionType = 'GET_RECIPIENTS';
function getRecipientsAction() {
  return { type: getRecipientsActionType };
}

const getRecipientsSuccessActionType = `${getRecipientsActionType}_SUCCESS`;
function getRecipientsSuccessAction(recipients) {
  return {
    type: getRecipientsSuccessActionType,
    payload: { recipients },
  };
}

const getRecipientsFailureActionType = `${getRecipientsActionType}_FAILURE`;
function getRecipientsFailureAction(errorMessage) {
  return {
    type: getRecipientsFailureActionType,
    payload: { errorMessage },
  };
}

const clearRecipientsActionType = `${getRecipientsActionType}_CLEAR`;
function clearRecipients() {
  return { type: clearRecipientsActionType };
}

function getRecipients() {
  return dispatch => {
    dispatch(getRecipientsAction());

    return axios({
      method: 'get',
      url: `${API_HOST_AUTH}/hedges-recipients`,
    })
      .then(({ data }) => dispatch(getRecipientsSuccessAction(data)))
      .catch(({ message }) => {
        dispatch(getRecipientsFailureAction(message));
      });
  };
}

export {
  getRecipientsActionType,
  getRecipientsSuccessActionType,
  getRecipientsFailureActionType,
  clearRecipientsActionType,
  getRecipients,
  clearRecipients,
};
