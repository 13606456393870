import { Component } from 'react';

import { FormContainer } from 'shared/components/Form/FormContainer/FormContainer';
import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';
import { isBuyerUser } from 'shared/helpers/matchers/checkUserType';
import { isGlobalUserType } from 'shared/helpers/matchers/isGlobalUserType';

import { goBackOrTo } from 'utils/history';

import { BuyerLotForm } from '../LotForm/BuyerLotForm/BuyerLotForm';
import { CustomerCreateLot } from './CustomerCreateLot/CustomerCreateLot';
import { GlobalCreateLot } from './GlobalCreateLot/GlobalCreateLot';

class CreateLot extends Component {
  componentDidMount() {
    const { relatedCompany, userType } = this.props.auth.user;

    if (!isGlobalUserType(userType)) {
      this.props
        .getCompanyLocations(relatedCompany._id)
        .then(data => data.length === 0 && goBackOrTo('/'));
    }
  }

  componentWillUnmount() {
    this.props.lotsClearAllFiles();
  }

  renderFormBasedOnUserType() {
    const { userType } = this.props.auth.user;

    if (isBuyerUser(userType)) {
      return <BuyerLotForm companyLocations={this.props.companyLocations} auth={this.props.auth} />;
    }

    if (isGlobalUserType(userType)) {
      return <GlobalCreateLot {...this.props} />;
    }

    return <CustomerCreateLot {...this.props} />;
  }

  render() {
    const { isPending } = this.props.lotCreate;

    return (
      <FormContainer loaded={!isPending} header={LOTS.CREATE_A_LOT}>
        {this.renderFormBasedOnUserType()}
      </FormContainer>
    );
  }
}

export { CreateLot };
