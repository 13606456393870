import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getSuggestedDeliveryDateActionType = 'GET_SUGGESTED_DELIVERY_DATE';
const getSuggestedDeliveryDateAction = () => ({
  type: getSuggestedDeliveryDateActionType,
});

const getSuggestedDeliveryDateSuccessActionType = `${getSuggestedDeliveryDateActionType}_SUCCESS`;
const getSuggestedDeliveryDateSuccessAction = suggestedDeliveryDate => ({
  type: getSuggestedDeliveryDateSuccessActionType,
  payload: {
    suggestedDeliveryDate,
  },
});

const getSuggestedDeliveryDateFailureActionType = `${getSuggestedDeliveryDateActionType}_FAILURE`;
const getSuggestedDeliveryDateFailureAction = () => ({
  type: getSuggestedDeliveryDateFailureActionType,
});

const clearSuggestedDeliveryDateActionType = 'CLEAR_SUGGESTED_DELIVERY_DATE';
const clearSuggestedDeliveryDate = () => ({
  type: clearSuggestedDeliveryDateActionType,
});

const getSuggestedDeliveryDate = (relatedCompany, lotId) => dispatch => {
  dispatch(getSuggestedDeliveryDateAction());

  return axios
    .get(`${API_HOST_AUTH}/hedges/suggested-delivery-date/${relatedCompany}/${lotId}`)
    .then(({ data }) => {
      dispatch(getSuggestedDeliveryDateSuccessAction(data));
      return data;
    })
    .catch(() => dispatch(getSuggestedDeliveryDateFailureAction()));
};

export {
  getSuggestedDeliveryDateActionType,
  getSuggestedDeliveryDateAction,
  getSuggestedDeliveryDateSuccessActionType,
  getSuggestedDeliveryDateSuccessAction,
  getSuggestedDeliveryDateFailureActionType,
  getSuggestedDeliveryDateFailureAction,
  getSuggestedDeliveryDate,
  clearSuggestedDeliveryDateActionType,
  clearSuggestedDeliveryDate,
};
