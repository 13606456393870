import {
  updateNotificationPreferencesActionType,
  updateNotificationPreferencesFailureActionType,
  updateNotificationPreferencesSuccessActionType,
} from 'actions/Users/notificationPreferences';

const INITIAL_STATE = {
  isPending: false,
};

const updateNotificationPreferencesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case updateNotificationPreferencesActionType:
      return {
        isPending: true,
      };
    case updateNotificationPreferencesSuccessActionType:
    case updateNotificationPreferencesFailureActionType:
      return {
        isPending: false,
      };
    default:
      return state;
  }
};

export { updateNotificationPreferencesReducer };
