import {
  getAccountsReceivableActionType,
  getAccountsReceivableFailureActionType,
  getAccountsReceivableSuccessActionType,
  clearAccountsReceivableActionType,
} from 'actions/Payments/getAccountsReceivable';

const INITIAL_STATE = {
  isPending: false,
  accountsReceivable: null,
};

const AccountsReceivableReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getAccountsReceivableActionType:
      return {
        ...state,
        isPending: true,
      };
    case getAccountsReceivableSuccessActionType:
      return {
        isPending: false,
        accountsReceivable: action.payload.accountsReceivable,
      };
    case getAccountsReceivableFailureActionType:
    case clearAccountsReceivableActionType:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};

export { AccountsReceivableReducer };
