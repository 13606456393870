import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { downloadAttachment } from 'actions/Attachments/downloadAttachment';
import { getHedgeTotalValue, clearHedgeTotalValue } from 'actions/Hedges/getHedgeTotalValue';
import { deleteHedge } from 'actions/Hedges/hedgesDelete';
import { getHedges, clearHedgesAction } from 'actions/Hedges/hedgesList';
import { openModal } from 'actions/shared/modal';
import { showSnackbar } from 'actions/shared/snackbar';

import { HedgesList } from './HedgesList';

const mapStateToProps = state => ({
  auth: state.auth,
  hedgesList: state.hedgesList,
  downloadAttachmentStorage: state.downloadAttachmentStorage,
  hedgeTotalValue: state.hedgeTotalValue,
  marketAvailabilityDetails: state.marketAvailabilityDetails,
});

const mapDispatchToProps = {
  getHedges,
  clearHedgesAction,
  openModal,
  showSnackbar,
  deleteHedge,
  downloadAttachment,
  getHedgeTotalValue,
  clearHedgeTotalValue,
};

const ConnectedHedgesList = withRouter(connect(mapStateToProps, mapDispatchToProps)(HedgesList));

export { ConnectedHedgesList };
