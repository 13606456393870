import PropTypes from 'prop-types';
import * as React from 'react';

import {
  PERMISSIONS,
  PERMISSIONS_LOGS_MAPPER,
} from 'shared/helpers/constants/systemSettings/permissions/constants';

import { LogsActionList } from '../LogsActionList/LogsActionList';
import { LogsItem } from '../LogsItem/LogsItem';
import * as S from '../SpecialPermissionsLogsStyles';

const LogsAction = ({ type, actionType, before, after, editedObject }) => (
  <S.LogsActionWrapper>
    <S.LogsActionHeader>
      {PERMISSIONS_LOGS_MAPPER[type][actionType](editedObject)}
    </S.LogsActionHeader>
    {!!Object.keys(before).length && !!Object.keys(after).length && (
      <S.LogsActionContent>
        <LogsItem title={PERMISSIONS.ACTION_BEFORE}>
          <LogsActionList data={before} />
        </LogsItem>
        <LogsItem withoutBorderLeft title={PERMISSIONS.ACTION_AFTER}>
          <LogsActionList data={after} />
        </LogsItem>
      </S.LogsActionContent>
    )}
  </S.LogsActionWrapper>
);

LogsAction.propTypes = {
  type: PropTypes.string,
  actionType: PropTypes.string,
  before: PropTypes.object,
  after: PropTypes.object,
  editedObject: PropTypes.object,
};

export { LogsAction };
