import React, { Component } from 'react';
import { FieldArray } from 'redux-form';

import { Form } from 'shared/components/Form/Form/Form';
import { cancelModal } from 'shared/helpers/constants/modalConstants';
import { POOL_ACCOUNTS } from 'shared/helpers/constants/poolAccounts/poolAccountsConstants';

import { goBackOrTo } from 'utils/history';

import { RequestSettlementFormList } from '../RequestSettlementsFormList/RequestSettlementFormList';

class RequestSettlementsForm extends Component {
  componentWillUnmount() {
    this.props.destroy();
    this.props.clearMaximumSettlementOunces();
  }

  modalOpen = () =>
    this.props.openModal(cancelModal, () => {
      goBackOrTo('/payments');
    });

  renderSettlements = ({ fields }) => (
    <RequestSettlementFormList
      {...this.props}
      fields={fields}
      invalid={this.props.invalid}
      location={this.props.location}
    />
  );

  render() {
    const { handleSubmit, invalid, submitting } = this.props;

    return (
      <Form
        header={POOL_ACCOUNTS.SETTLEMENT}
        onSubmit={handleSubmit}
        onCancel={this.modalOpen}
        submitDisabled={invalid || submitting}
      >
        <FieldArray name="settlements" component={this.renderSettlements} />
      </Form>
    );
  }
}

export { RequestSettlementsForm };
