import {
  addGradingReceiptType,
  clearGradingReceiptType,
  uploadGradingReceiptType,
  uploadGradingReceiptSuccessType,
  uploadGradingReceiptFailureType,
} from 'actions/Lots/uploadGradingReceipt';

const INITIAL_STATE = {
  gradingReceipt: null,
  isPending: false,
};

const uploadGradingReceiptReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case uploadGradingReceiptType:
      return {
        ...state,
        isPending: true,
      };
    case uploadGradingReceiptSuccessType:
    case uploadGradingReceiptFailureType:
      return {
        ...state,
        isPending: false,
      };
    case addGradingReceiptType:
      return {
        ...state,
        gradingReceipt: action.payload.gradingReceipt,
      };
    case clearGradingReceiptType:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};

export { uploadGradingReceiptReducer };
