import PropTypes from 'prop-types';

import { DraggableItemBasic } from './DraggableItemBasic';
import { DraggableItemExpanded } from './DraggableItemExpanded';

const DraggableItem = props =>
  props.expanded ? DraggableItemExpanded(props) : DraggableItemBasic(props);

DraggableItem.defaultProps = {
  highlightedColor: 'shipCove',
  actionsTemplate: [],
  expandedActionsTemplate: [],
};

DraggableItem.propTypes = {
  item: PropTypes.object,
  itemsTemplate: PropTypes.arrayOf(
    PropTypes.shape({
      render: PropTypes.func,
    }),
  ),
  actionsTemplate: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
    }),
  ).isRequired,
  expandedActionsTemplate: PropTypes.arrayOf(
    PropTypes.shape({
      render: PropTypes.func,
      access: PropTypes.func,
    }),
  ).isRequired,
  highlightedColor: PropTypes.string,
};

export { DraggableItem };
