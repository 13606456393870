import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, FieldArray, getFormSyncErrors } from 'redux-form';

import {
  getBlanketHedgeCount,
  clearBlanketHedgeCount,
} from 'actions/Companies/getBlanketHedgeCount';
import {
  getHedgesSuggestedValues,
  getHedgesSuggestedValuesAction,
} from 'actions/Hedges/hedgesSuggestedValues';
import { marketAvailability } from 'actions/Hedges/marketAvailability';
import { getMarketHolidays } from 'actions/Hedges/marketHolidaysList';
import { getPackagesOverviewForLots } from 'actions/Packages/packagesOverviewList';
import { saveSharedData, clearSharedData } from 'actions/shared/saveSharedData';
import { showSnackbar } from 'actions/shared/snackbar';

import { ContentLoader } from 'shared/components/ContentLoader/ContentLoader';
import { Form } from 'shared/components/Form/Form/Form';
import { HEDGES } from 'shared/helpers/constants/hedges/hedgesConstants';
import { initialValues } from 'shared/helpers/constants/hedges/initialValues';
import { cancelModal } from 'shared/helpers/constants/modalConstants';
import { isCustomerUser } from 'shared/helpers/matchers/checkUserType';
import { validate } from 'shared/helpers/validations/hedges/hedgesValidation';

import dateAdapter from 'utils/date/dateAdapter';

import { HedgesFormList } from '../HedgesFormList/HedgesFormList';

class HedgesForm extends Component {
  componentDidMount() {
    const {
      history: { location },
      change,
      resetSection,
      auth: {
        user: { userType },
      },
    } = this.props;

    if (location && location.state && location.state.hedge) {
      change(`${location.state.hedge}.relatedLot`, {
        label: location.state.name,
        value: location.state.id,
        receivedAt: location.state.receivedAt,
        statusForCustomer: location.state.statusForCustomer,
        statusForGlobal: location.state.statusForGlobal,
        grgLotNumber: location.state.name,
      });
      resetSection(`${location.state.hedge}.deliveryDate`);
      resetSection(`${location.state.hedge}.deliveryDateGRG`);
    }

    if (isCustomerUser(userType)) {
      this.props.getBlanketHedgeCount();
    }
    this.props.marketAvailability(true);
    this.props.getMarketHolidays(this.props.marketHolidaysList.filters);
  }

  componentWillUnmount() {
    this.props.clearBlanketHedgeCount();
  }

  onCancel = () => {
    const { history, location, openModal, destroy, enterPlacedHedges, clearSharedData } =
      this.props;

    openModal(cancelModal, () => {
      destroy();

      if (!enterPlacedHedges && location.state && location.state.relatedLot) {
        return history.push('/hedges/hedge-ounces', {
          shipmentId: location.state.relatedLot.shipmentId,
        });
      }
      clearSharedData('defaultMaterialType');
      history.push('/hedges');
    });
  };

  renderHedges = ({ fields }) => <HedgesFormList {...this.props} fields={fields} />;

  render() {
    const {
      invalid,
      submitting,
      hedgesSuggestedValues,
      handleSubmit,
      enterPlacedHedges,
      marketHolidaysList,
    } = this.props;

    return (
      <ContentLoader loaded={!marketHolidaysList.isPending}>
        <Form
          header={HEDGES.HEDGES}
          onSubmit={handleSubmit}
          onCancel={this.onCancel}
          submitDisabled={
            invalid || submitting || (!enterPlacedHedges && hedgesSuggestedValues.isPending)
          }
        >
          <FieldArray name="hedges" component={this.renderHedges} />
        </Form>
      </ContentLoader>
    );
  }
}

const syncError = getFormSyncErrors(HEDGES.HEDGES_FORM);

const mapStateToProps = ({
  marketHolidaysList,
  getBlanketHedgeCount,
  marketAvailabilityDetails,
  ...restState
}) => ({
  blanketHedgeCount: getBlanketHedgeCount && getBlanketHedgeCount.blanketHedgeCount,
  marketHolidaysList,
  marketAvailabilityDetails,
  formErrors: syncError(restState),
  sharedData: restState.sharedData,
});

const mapDispatchToProps = {
  getBlanketHedgeCount,
  clearBlanketHedgeCount,
  getMarketHolidays,
  marketAvailability,
  getPackagesOverviewForLots,
  getHedgesSuggestedValues,
  getHedgesSuggestedValuesAction,
  showSnackbar,
  saveSharedData,
  clearSharedData,
};

const ConnectedHedgesForm = compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: HEDGES.HEDGES_FORM,
    destroyOnUnmount: false,
    validate,
    initialValues: { hedges: [{ ...initialValues, formId: dateAdapter().valueOf() }] },
  }),
)(HedgesForm);

export { ConnectedHedgesForm, HedgesForm as HedgesFormTest };
