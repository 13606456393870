import React, { Component } from 'react';
import Select from 'react-select';
import styled from 'styled-components';

import { styles } from 'shared/components/Fields/FieldSelect/FieldSelect';

const LimitContainer = styled.div`
  width: 115px;
`;

class Limit extends Component {
  state = {
    value: this.props.limits.find(({ value }) => value === this.props.selectedLimit),
  };

  onLimitChange = limit => {
    this.props.setLimit(limit.value);
    this.setState({
      value: limit,
    });
  };

  render() {
    return (
      <LimitContainer>
        <p>VIEW</p>
        <Select
          styles={styles}
          isSearchable={false}
          options={this.props.limits}
          value={this.state.value}
          onChange={this.onLimitChange}
        />
      </LimitContainer>
    );
  }
}

export { Limit };
