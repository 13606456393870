import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import { useSelector } from 'react-redux';

import { Button } from 'shared/components/Buttons/Button';
import { FieldInput } from 'shared/components/Formik/Fields';
import { Link } from 'shared/components/Link/Link';
import { ACCESS } from 'shared/helpers/constants/access/accessConstants';

import { FormWrapper } from 'pages/ResetPassword/components/FormWrapper/FormWrapper';

import { initialValues, validationSchema } from './LoginFormYup';
import { StyledLoginFormButtons, StyledLoginFormReCaptcha } from './StyledLoginForm';

const CAPTCHA_SITE_KEY = process.env.REACT_APP_CAPTCHA_SITE_KEY;
const CAPTCHA_LIMIT = 4;

export const LoginForm = ({
  verifyRecaptchaCallback,
  onExpiredRecaptcha,
  onSubmit,
  filledCaptcha,
}) => {
  const attempts = useSelector(state => state.auth.attempts);

  const recaptchaRef = useRef(null);
  const { values, touched, isValid, errors, handleSubmit, handleBlur, handleChange } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });
  const resetRecaptcha = () => {
    recaptchaRef.current.reset();
  };

  const setRecaptchaCallback = response => {
    verifyRecaptchaCallback(response);
  };

  const renderRecaptchaSubmitButton = () => {
    return (
      <Button
        id="submitButton"
        disabled={!filledCaptcha || !isValid}
        onClick={resetRecaptcha}
        padding="0 32px"
        type="submit"
      >
        {ACCESS.SIGN_IN}
      </Button>
    );
  };

  const renderSubmitButton = () => {
    return (
      <Button id="submitButton" disabled={!isValid} type="submit" padding="0 32px">
        {ACCESS.SIGN_IN}
      </Button>
    );
  };

  const renderRecaptcha = () => {
    return (
      <div>
        CAPTCHA
        <StyledLoginFormReCaptcha
          ref={recaptchaRef}
          sitekey={CAPTCHA_SITE_KEY}
          onChange={setRecaptchaCallback}
          onExpired={onExpiredRecaptcha}
          theme="dark"
        />
      </div>
    );
  };

  return (
    <FormWrapper>
      <form onSubmit={handleSubmit} noValidate>
        <FieldInput
          name="email"
          type="text"
          prefixSize={15}
          label="Email"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          labelWidth={100}
          error={touched.email && errors.email}
        />
        <FieldInput
          name="password"
          type="password"
          label="Password"
          prefixSize={15}
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
          labelWidth={100}
          error={touched.password && errors.password}
        />
        {attempts >= CAPTCHA_LIMIT && renderRecaptcha()}
        <StyledLoginFormButtons>
          <Link path="/password-reset" color="alto" fontSize={14}>
            {ACCESS.FORGOT_PASSWORD}
          </Link>
          {attempts >= CAPTCHA_LIMIT
            ? renderRecaptchaSubmitButton(isValid)
            : renderSubmitButton(isValid)}
        </StyledLoginFormButtons>
      </form>
    </FormWrapper>
  );
};

LoginForm.propTypes = {
  filledCaptcha: PropTypes.bool,
  verifyRecaptchaCallback: PropTypes.func,
  onExpiredRecaptcha: PropTypes.func,
  onSubmit: PropTypes.func,
};
