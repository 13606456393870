import {
  getAssayResultsDetailsActionType,
  getAssayResultsDetailsSuccessActionType,
  getAssayResultsDetailsFailureActionType,
  clearAssayResultsDetailsActionType,
} from 'actions/Lots/assayResultsDetails';

const INITIAL_STATE = {
  isPending: false,
  assayResults: null,
};

const assayResultsDetailsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getAssayResultsDetailsActionType:
      return {
        payment: null,
        isPending: true,
      };
    case getAssayResultsDetailsSuccessActionType:
      return {
        ...state,
        isPending: false,
        assayResults: action.payload.assayResults,
      };
    case getAssayResultsDetailsFailureActionType:
      return {
        ...state,
        isPending: false,
      };
    case clearAssayResultsDetailsActionType:
      return {
        isPending: false,
        assayResults: null,
      };
    default:
      return state;
  }
};

export { assayResultsDetailsReducer };
