import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

import { IconButton } from 'shared/components/Buttons/IconButton/IconButton';
import { media } from 'shared/helpers/styling/styling';

const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: 50%;
  transform: translateY(-40%);
  right: ${({ previewIcoInside }) => (previewIcoInside ? '13px' : '-50px')};
  background-color: transparent;
  border: none;
  max-width: 40px;

  ${({ sectionDocuments }) =>
    sectionDocuments &&
    css`
      position: relative;
      top: 0;
      transform: translateY(0);
      right: 0;
      margin-top: 8px;
    `}

  ${({ revertPosition }) =>
    revertPosition &&
    css`
      left: 25%;
      ${media.mobile`
        left: -50px;
      `}
    `}
`;

const PreviewButton = ({
  handleClick,
  previewIcoInside,
  sectionDocuments,
  revertPosition,
  dataTestId,
}) => {
  PreviewButton.defaultProps = {
    previewIcoInside: false,
    revertPosition: false,
  };

  return (
    <StyledIconButton
      revertPosition={revertPosition}
      previewIcoInside={previewIcoInside}
      sectionDocuments={sectionDocuments}
      icon="icon-preview"
      onClick={handleClick}
      iconMargin="1px 0px 1px 5px"
      data-testid={dataTestId || "previewButton"}
    />
  );
};

PreviewButton.propTypes = {
  handleClick: PropTypes.func,
  previewIcoInside: PropTypes.bool,
  sectionDocuments: PropTypes.bool,
  revertPosition: PropTypes.bool,
  dataTestId: PropTypes.string,
};

export { PreviewButton };
