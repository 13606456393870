import { message } from 'shared/helpers/constants/terms/createTermConstants';
import { dateToTimestamp } from 'shared/helpers/parsers/date';
import { replaceEmpty } from 'shared/helpers/parsers/text';
import { commaValidator } from 'shared/helpers/validations/commaValidator.js';
import {
  INTEGER_REGEX,
  FLOAT_REGEX,
  PRICE_REGEX,
} from 'shared/helpers/validations/regularExpressions';

import dateAdapter from 'utils/date/dateAdapter';

function validate(values) {
  const errors = {};
  const today = dateAdapter().startOf('d').valueOf();

  if (replaceEmpty(values.treatmentChargePerPound, '').length === 0) {
    errors.treatmentChargePerPound = message.INVALID_TREATMENT_CHARGE_PER_POUND_EMPTY;
  } else if (
    !PRICE_REGEX.test(values.treatmentChargePerPound) ||
    (values.treatmentChargePerPound && values.treatmentChargePerPound.length > 12)
  ) {
    errors.treatmentChargePerPound = message.INVALID_TREATMENT_CHARGE_PER_POUND;
  } else if (parseFloat(values.treatmentChargePerPound) < 0) {
    errors.treatmentChargePerPound = message.INVALID_TREATMENT_CHARGE_PER_POUND_RANGE;
  }

  if (!values.startDate) {
    errors.startDate = message.INVALID_START_DATE_EMPTY;
  } else if (dateToTimestamp(values.startDate) < today) {
    errors.startDate = message.INVALID_START_DATE_PAST;
  } else if (
    values.endDate &&
    dateToTimestamp(values.startDate) > dateToTimestamp(values.endDate)
  ) {
    errors.startDate = message.INVALID_START_DATE_VALUE;
  } else if (
    values.endDate &&
    dateToTimestamp(values.startDate) === dateToTimestamp(values.endDate)
  ) {
    errors.startDate = message.INVALID_START_DATE_VALUE_SAME;
  }

  if (!values.endDate) {
    errors.endDate = message.INVALID_END_DATE_EMPTY;
  } else if (dateToTimestamp(values.endDate) < today) {
    errors.endDate = message.INVALID_END_DATE_PAST;
  } else if (
    values.startDate &&
    dateToTimestamp(values.startDate) > dateToTimestamp(values.endDate)
  ) {
    errors.endDate = message.INVALID_END_DATE_VALUE;
  } else if (
    values.startDate &&
    dateToTimestamp(values.startDate) === dateToTimestamp(values.endDate)
  ) {
    errors.endDate = message.INVALID_END_DATE_VALUE_SAME;
  }

  if (replaceEmpty(values.smallLotCharge, '').length === 0) {
    errors.smallLotCharge = message.INVALID_SMALL_LOT_CHARGE_EMPTY;
  } else if (
    !PRICE_REGEX.test(values.smallLotCharge) ||
    (values.smallLotCharge && values.smallLotCharge.length > 12)
  ) {
    errors.smallLotCharge = message.INVALID_SMALL_LOT_CHARGE;
  } else if (parseFloat(values.smallLotCharge) < 0) {
    errors.smallLotCharge = message.INVALID_SMALL_LOT_CHARGE_RANGE;
  }

  if (replaceEmpty(values.largeLotCharge, '').length === 0) {
    errors.largeLotCharge = message.INVALID_LARGE_LOT_CHARGE_EMPTY;
  } else if (
    !PRICE_REGEX.test(values.largeLotCharge) ||
    (values.largeLotCharge && values.largeLotCharge.length > 12)
  ) {
    errors.largeLotCharge = message.INVALID_LARGE_LOT_CHARGE;
  } else if (parseFloat(values.largeLotCharge) < 0) {
    errors.largeLotCharge = message.INVALID_LARGE_LOT_CHARGE_RANGE;
  }

  if (replaceEmpty(values.advanceRate, '').length === 0) {
    errors.advanceRate = message.INVALID_ADVANCE_RATE_EMPTY;
  } else if (!FLOAT_REGEX.test(values.advanceRate)) {
    errors.advanceRate = message.INVALID_ADVANCE_RATE;
  } else if (parseFloat(values.advanceRate) < 0 || parseFloat(values.advanceRate) > 100) {
    errors.advanceRate = message.INVALID_ADVANCE_RATE_RANGE;
  }

  if (replaceEmpty(values.platinumReturnRate, '').length === 0) {
    errors.platinumReturnRate = message.INVALID_PLATINUM_RETURN_RATE_EMPTY;
  } else if (!FLOAT_REGEX.test(values.platinumReturnRate)) {
    errors.platinumReturnRate = message.INVALID_PLATINUM_RETURN_RATE;
  } else if (
    parseFloat(values.platinumReturnRate) < 0 ||
    parseFloat(values.platinumReturnRate) > 100
  ) {
    errors.platinumReturnRate = message.INVALID_PLATINUM_RETURN_RATE_RANGE;
  }

  if (replaceEmpty(values.platinumRefiningCharge, '').length === 0) {
    errors.platinumRefiningCharge = message.INVALID_PLATINUM_REFINING_CHARGE_EMPTY;
  } else if (
    !PRICE_REGEX.test(values.platinumRefiningCharge) ||
    replaceEmpty(values.platinumRefiningCharge, '').length > 12
  ) {
    errors.platinumRefiningCharge = message.INVALID_PLATINUM_REFINING_CHARGE;
  } else if (parseFloat(values.platinumRefiningCharge) > 0) {
    errors.platinumRefiningCharge = message.INVALID_PLATINUM_REFINING_CHARGE_RANGE;
  }

  if (replaceEmpty(values.palladiumReturnRate, '').length === 0) {
    errors.palladiumReturnRate = message.INVALID_PALLADIUM_RETURN_RATE_EMPTY;
  } else if (!FLOAT_REGEX.test(values.palladiumReturnRate)) {
    errors.palladiumReturnRate = message.INVALID_PALLADIUM_RETURN_RATE;
  } else if (
    parseFloat(values.palladiumReturnRate) < 0 ||
    parseFloat(values.palladiumReturnRate) > 100
  ) {
    errors.palladiumReturnRate = message.INVALID_PALLADIUM_RETURN_RATE_RANGE;
  }

  if (replaceEmpty(values.palladiumRefiningCharge, '').length === 0) {
    errors.palladiumRefiningCharge = message.INVALID_PALLADIUM_REFINING_CHARGE_EMPTY;
  } else if (
    !PRICE_REGEX.test(values.palladiumRefiningCharge) ||
    replaceEmpty(values.palladiumRefiningCharge, '').length > 12
  ) {
    errors.palladiumRefiningCharge = message.INVALID_PALLADIUM_REFINING_CHARGE;
  } else if (parseFloat(values.palladiumRefiningCharge) > 0) {
    errors.palladiumRefiningCharge = message.INVALID_PALLADIUM_REFINING_CHARGE_RANGE;
  }

  if (replaceEmpty(values.rhodiumReturnRate, '').length === 0) {
    errors.rhodiumReturnRate = message.INVALID_RHODIUM_RETURN_RATE_EMPTY;
  } else if (!FLOAT_REGEX.test(values.rhodiumReturnRate)) {
    errors.rhodiumReturnRate = message.INVALID_RHODIUM_RETURN_RATE;
  } else if (
    parseFloat(values.rhodiumReturnRate) < 0 ||
    parseFloat(values.rhodiumReturnRate) > 100
  ) {
    errors.rhodiumReturnRate = message.INVALID_RHODIUM_RETURN_RATE_RANGE;
  }

  if (replaceEmpty(values.rhodiumRefiningCharge, '').length === 0) {
    errors.rhodiumRefiningCharge = message.INVALID_RHODIUM_REFINING_CHARGE_EMPTY;
  } else if (
    !PRICE_REGEX.test(values.rhodiumRefiningCharge) ||
    replaceEmpty(values.rhodiumRefiningCharge, '').length > 12
  ) {
    errors.rhodiumRefiningCharge = message.INVALID_RHODIUM_REFINING_CHARGE;
  } else if (parseFloat(values.rhodiumRefiningCharge) > 0) {
    errors.rhodiumRefiningCharge = message.INVALID_RHODIUM_REFINING_CHARGE_RANGE;
  }

  if (replaceEmpty(values.metalsReturnTermInDays, '').length === 0) {
    errors.metalsReturnTermInDays = message.INVALID_METALS_RETURN_TERMIN_DAYS_EMPTY;
  } else if (!INTEGER_REGEX.test(values.metalsReturnTermInDays)) {
    errors.metalsReturnTermInDays = message.INVALID_METALS_RETURN_TERMIN_DAYS;
  } else if (
    parseFloat(values.metalsReturnTermInDays) < 0 ||
    parseFloat(values.metalsReturnTermInDays) > 150
  ) {
    errors.metalsReturnTermInDays = message.INVALID_METALS_RETURN_TERMIN_DAYS_RANGE;
  }

  if (replaceEmpty(values.metalsReturnFinanceRate, '').length === 0) {
    errors.metalsReturnFinanceRate = message.INVALID_METALS_RETURN_FINANCE_RATE_EMPTY;
  } else if (!FLOAT_REGEX.test(values.metalsReturnFinanceRate)) {
    errors.metalsReturnFinanceRate = message.INVALID_METALS_RETURN_FINANCE_RATE;
  } else if (
    parseFloat(values.metalsReturnFinanceRate) < 0 ||
    parseFloat(values.metalsReturnFinanceRate) > 100
  ) {
    errors.metalsReturnFinanceRate = message.INVALID_METALS_RETURN_FINANCE_RATE_RANGE;
  }

  if (values.digitalSignatureRequired === undefined || values.digitalSignatureRequired === '') {
    errors.digitalSignatureRequired = message.DIGITAL_SIGNATURE_REQUIRED_EMPTY;
  }

  commaValidator(
    {
      treatmentChargePerPound: values.treatmentChargePerPound,
      smallLotCharge: values.smallLotCharge,
      largeLotCharge: values.largeLotCharge,
      platinumRefiningCharge: values.platinumRefiningCharge,
      palladiumRefiningCharge: values.palladiumRefiningCharge,
      rhodiumRefiningCharge: values.rhodiumRefiningCharge,
      platinumReturnRate: values.platinumReturnRate,
      palladiumReturnRate: values.palladiumReturnRate,
      rhodiumReturnRate: values.rhodiumReturnRate,
      advanceRate: values.advanceRate,
      metalsReturnFinanceRate: values.metalsReturnFinanceRate,
    },
    errors,
  );

  return errors;
}

export { validate };
