import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';

import { getLotByBarcode as getLotByBarcodeAction } from 'actions/Lots/getLotByBarcode';
import { clearLotDetails as clearLotDetailsAction } from 'actions/Lots/lotDetails';
import { markLotAsReceived as markLotAsReceivedAction } from 'actions/Lots/markLotAsReceived';
import {
  getGlobalCreatedPackage as getGlobalCreatedPackageAction,
  clearPackageDetailsAction,
} from 'actions/Packages/packageDetails';

import { BarcodeScanner } from 'shared/components/BarcodeScanner/BarcodeScanner';
import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';

import { DuplicateSummary } from './DuplicateSummary';

const DuplicateLotLabelBase = props => {
  const {
    open,
    lotDetails,
    packageDetails,
    clearLotDetails,
    clearPackageDetails,
    getLotByBarcode,
    getGlobalCreatedPackage,
    onSelfClose,
    printLotLabel,
  } = props;

  const message = {
    success: LOTS.PACKAGE_SUCCESSFULLY_SCAN,
    error: LOTS.PRINT_DUPLICATE_ERROR,
    idle: LOTS.PRINT_DUPLICATE_SCAN,
    manualIdle: LOTS.PRINT_DUPLICATE_ENTER,
  };

  useEffect(
    () => () => {
      clearLotDetails();
      clearPackageDetails();
    },
    [clearLotDetails, clearPackageDetails],
  );

  const barcodeEntered = useCallback(
    async scannedBarcode => {
      try {
        (await getLotByBarcode(scannedBarcode)) || (await getGlobalCreatedPackage(scannedBarcode));
      } catch (e) {
        return Promise.reject(LOTS.PRINT_DUPLICATE_ERROR);
      }
    },
    [getLotByBarcode, getGlobalCreatedPackage],
  );

  const onDone = useCallback(() => {
    onSelfClose();
    clearLotDetails();
    clearPackageDetails();
  }, [onSelfClose, clearLotDetails, clearPackageDetails]);

  const idleStates = [
    {
      icon: 'icon-box',
      name: 'barcode',
      barcodeFormat: /(^[a-f0-9]{8}$)|(^[a-f0-9]{24}$)|(^[A-Z0-9()\- ]+$)/,
      onChange: barcodeEntered,
    },
    {
      name: 'Summary',
      render: () => (
        <DuplicateSummary
          onDone={onDone}
          lot={lotDetails.lot}
          pkg={packageDetails.package}
          isPending={printLotLabel.isPending}
        />
      ),
    },
  ];

  return (
    <BarcodeScanner
      open={open}
      onSelfClose={onDone}
      message={message}
      idleStates={idleStates}
      isPending={printLotLabel.isPending}
    />
  );
};

const mapStateToProps = state => ({
  lotDetails: state.lotDetails,
  packageDetails: state.packageDetails,
  printLotLabel: state.printLotLabel,
});

const mapDispatchToProps = {
  getLotByBarcode: getLotByBarcodeAction,
  getGlobalCreatedPackage: getGlobalCreatedPackageAction,
  markLotAsReceived: markLotAsReceivedAction,
  clearLotDetails: clearLotDetailsAction,
  clearPackageDetails: clearPackageDetailsAction,
};

const DuplicateLotLabel = connect(mapStateToProps, mapDispatchToProps)(DuplicateLotLabelBase);

export { DuplicateLotLabel, DuplicateLotLabelBase };
