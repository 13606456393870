import store from 'store';

import { showSnackbar } from 'actions/shared/snackbar';

import { MESSAGES } from 'shared/helpers/constants/messagesConstants';
import { infoOptions } from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

import packageInfo from '../../../../package.json';

function compareVersions(appVersion, currentVersion) {
  return appVersion !== currentVersion;
}

function validateVersion(headers) {
  const currentVersion = headers.version || headers.Version;
  const snackBarDelay = 500;
  const reloadDelay = 4000;

  if (
    packageInfo.version &&
    currentVersion &&
    compareVersions(packageInfo.version, currentVersion)
  ) {
    setTimeout(() => {
      store.dispatch(showSnackbar(infoOptions, MESSAGES.NEW_VERSION));
      setTimeout(() => window.location.reload(), snackBarDelay + reloadDelay);
    }, snackBarDelay);
  }
}

export { validateVersion };
