import { resolveVersion, versions } from 'utils/versioning';

const processingTypes = {
  postMill: 'postMill',
  postShears: 'postShears',
  postMix: 'postMix',
};

const processViaOptions = {
  get mills() {
    return resolveVersion({
      [versions.alpha]: {},
      [versions.global]: {
        M1: 'M1',
        M2: 'M2',
      },
      [versions.alpha]: {
        M1: 'M1',
        M2: 'M2',
      },
      [versions.west]: {},
    });
  },
  get shears() {
    return resolveVersion({
      [versions.alpha]: {
        Shears: 'Shears',
      },
      [versions.global]: {
        Shears: 'Shears',
      },
      [versions.alpha]: {
        Shears: 'Shears',
      },
      [versions.west]: {
        Shears: 'Shears',
      },
    });
  },
  get mixers() {
    return resolveVersion({
      [versions.alpha]: {},
      [versions.global]: {
        M1: 'M1',
        M2: 'M2',
      },
      [versions.alpha]: {
        M1: 'M1',
        M2: 'M2',
      },
      [versions.west]: {},
    });
  },
  get processing() {
    return Object.values({
      ...this.mills,
      ...this.shears,
    });
  },
};

export { processingTypes, processViaOptions };
