import { userHierarchy } from 'shared/helpers/accesses/config/createAccessLevels';
import { statusesEnum } from 'shared/helpers/constants/filters/allStatuses';
import { isCustomerUser } from 'shared/helpers/matchers/checkUserType';

function parseUserData(user) {
  const accessLevel =
    user?.accessLevel?.value ?? userHierarchy.accessLevel[user.userType.value][0].value;

  return {
    userType: user.userType.value,
    firstName: user.firstName,
    lastName: user.lastName,
    title: user.title,
    email: user.email,
    officePhone: user.officePhone,
    phoneExtension: user.phoneExtension,
    mobilePhone: user.mobilePhone,
    relatedCompany: user.relatedCompany.value,
    accessLevel: accessLevel,
    password: user.password,
    location: user.location.map(({ value }) => value),
    ...(isCustomerUser(user.userType.value) && {
      databaseAccess: user.databaseAccess,
      purchasingAppAccess: user.purchasingAppAccess,
    }),
  };
}

const selectOptionsParser = (usersList, sortAlphabetically = false) => {
  if (Object.prototype.toString.call(usersList) !== '[object Array]')
    throw new Error('Parameter must be an array.');
  const parsedUsersList = usersList.map(({ firstName, lastName, status, _id }) => ({
    label: `${firstName} ${lastName}`,
    value: _id,
    ...(status && { isUserActive: status === statusesEnum.active }),
  }));

  if (sortAlphabetically) {
    return parsedUsersList.sort((a, b) => a.label.localeCompare(b.label));
  } else {
    return parsedUsersList;
  }
};

export { parseUserData, selectOptionsParser };
