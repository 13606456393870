import { safeReplace } from 'shared/helpers/parsers/text';

const parseEnterPoolAccountOuncesData = data => ({
  relatedCompany: data.relatedCompany.value,
  relatedLot: data.relatedLot.value,
  platinumOz: +safeReplace(data.platinumOz, ',', '.'),
  palladiumOz: +safeReplace(data.palladiumOz, ',', '.'),
  rhodiumOz: +safeReplace(data.rhodiumOz, ',', '.'),
});

export { parseEnterPoolAccountOuncesData };
