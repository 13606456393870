import { message } from 'shared/helpers/constants/hedges/createTrustedCompaniesConstants';

function validate(values) {
  const errors = {};

  if (!values.relatedCompany) {
    errors.relatedCompany = message.INVALID_RELATED_COMPANY_EMPTY;
  }

  if (!values.exceptionTypes?.length) {
    errors.exceptionTypes = message.EXCEPTION_TYPE_EMPTY;
  }

  return errors;
}

export { validate };
