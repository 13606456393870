import React, { Component } from 'react';

import { FormContainer } from 'shared/components/Form';
import { message } from 'shared/helpers/constants/hedges/createRecipientsConstants';
import { HEDGES } from 'shared/helpers/constants/hedges/hedgesConstants';
import {
  successOptions,
  dangerOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

import { RecipientsForm } from '../RecipientsForm/RecipientsForm';

class RecipientsCreate extends Component {
  submit = values => {
    const { showSnackbar, createRecipient, history } = this.props;
    return createRecipient(values)
      .then(() => {
        showSnackbar(successOptions, message.RECIPIENTS_CREATE_SUCCESS);
        history.push('/hedges/recipients');
      })
      .catch(({ data }) => showSnackbar(dangerOptions, data.message));
  };

  render() {
    return (
      <FormContainer header={HEDGES.RECIPIENTS.CHANGE}>
        <RecipientsForm {...this.props} onSubmit={this.submit} />
      </FormContainer>
    );
  }
}

export { RecipientsCreate };
