import { isExternalUser } from 'shared/helpers/matchers/checkUserType';

const { REACT_APP_DEFAULT_STALE_TIME, REACT_APP_EXTERNAL_STALE_TIME } = process.env;

const oneHourInMs = 3600000;
const defaultStaleTime = oneHourInMs * REACT_APP_DEFAULT_STALE_TIME;
const externalStaleTime = oneHourInMs * REACT_APP_EXTERNAL_STALE_TIME;

const getStaleTime = user =>
  isExternalUser(user && user.userType) ? externalStaleTime : defaultStaleTime;

export { getStaleTime };
