import { PAYMENTS } from 'shared/helpers/constants/payments/paymentsConstants';
import { formatDate, formatToDollarWithPrefix } from 'shared/helpers/parsers/formaters';
import { replaceEmpty, humanize } from 'shared/helpers/parsers/text';

const itemsTemplate = [
  {
    label: PAYMENTS.PAID_ON,
    render: ({ paidAt }) => replaceEmpty(formatDate(paidAt)),
  },
  {
    label: PAYMENTS.RELATED_COMPANY,
    render: ({ relatedCompany }) => relatedCompany.companyName,
    highlight: true,
  },
  {
    label: PAYMENTS.RELATED_LOT,
    render: ({ relatedLot }) => replaceEmpty(relatedLot?.grgLotNumber),
    highlight: true,
  },
  {
    label: PAYMENTS.PAYMENT_NUMBER,
    render: ({ paymentNumber }) => replaceEmpty(paymentNumber),
  },
  {
    label: PAYMENTS.PAYMENT_AMOUNT,
    render: ({ paymentAmount }) => formatToDollarWithPrefix(paymentAmount),
  },
  {
    label: PAYMENTS.FUNDS_TYPE,
    render: ({ fundsType }) => humanize(fundsType),
  },
  {
    label: PAYMENTS.PAYMENT_TYPE,
    render: ({ paymentType }) => humanize(paymentType),
  },
];

const itemsTemplateMobile = [
  {
    label: PAYMENTS.PAID_ON,
    render: ({ paidAt }) => replaceEmpty(formatDate(paidAt)),
  },
  {
    label: PAYMENTS.RELATED_COMPANY,
    render: ({ relatedCompany }) => relatedCompany.companyName,
    highlight: true,
  },
  {
    label: PAYMENTS.RELATED_LOT,
    render: ({ relatedLot }) =>
      replaceEmpty(relatedLot?.customLotNumber || relatedLot?.grgLotNumber),
    highlight: true,
  },
];

const itemsDetailsTemplate = [
  {
    label: PAYMENTS.PAYMENT_NUMBER,
    render: ({ paymentNumber }) => replaceEmpty(paymentNumber),
  },
  {
    label: PAYMENTS.PAYMENT_AMOUNT,
    render: ({ paymentAmount }) => formatToDollarWithPrefix(paymentAmount),
  },
  {
    label: PAYMENTS.FUNDS_TYPE,
    render: ({ fundsType }) => humanize(fundsType),
  },
  {
    label: PAYMENTS.PAYMENT_TYPE,
    render: ({ paymentType }) => humanize(paymentType),
  },
];

export { itemsTemplate, itemsTemplateMobile, itemsDetailsTemplate };
