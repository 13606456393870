import React from 'react';
import { connect } from 'react-redux';

import { uploadCountSheet, addCountSheet, clearCountSheet } from 'actions/Lots/uploadCountSheet';
import { showSnackbar } from 'actions/shared/snackbar';

import { ModalCustom } from 'components/ModalCustom/ModalCustom';

import { Preloader } from 'shared/components/Preloader/Preloader';
import { FILES_CONSTANTS } from 'shared/helpers/constants/filesConstants';
import {
  successOptions,
  dangerOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

import { UploadCountSheetContent } from './components/UploadCountSheetContent';

function UploadCountSheetLayout(props) {
  const uploadFile = () => {
    props
      .uploadCountSheet(props.lot?._id, props.uploadCountSheetFile)
      .then(res => {
        props.showSnackbar(successOptions, res.message);
        props.onSuccess();
        props.toggle();
      })
      .catch(err => {
        props.showSnackbar(dangerOptions, err.message);
        props.toggle();
      });
  };

  return (
    <ModalCustom isOpen={props.open} modalClose={props.toggle}>
      <UploadCountSheetContent
        onSubmit={uploadFile}
        addCountSheet={props.addCountSheet}
        clearCountSheet={props.clearCountSheet}
        uploadCountSheetFile={props.uploadCountSheetFile}
        grgLotNumber={props.lot?.grgLotNumber}
      />
      <Preloader
        loading={props.uploadCountSheetFile.isPending}
        label={FILES_CONSTANTS.UPLOADING_FILES}
      />
    </ModalCustom>
  );
}

const mapStateToProps = ({ uploadCountSheetFile }) => ({
  uploadCountSheetFile,
});

const mapDispatchToProps = {
  showSnackbar,
  uploadCountSheet,
  addCountSheet,
  clearCountSheet,
};

const UploadCountSheet = connect(mapStateToProps, mapDispatchToProps)(UploadCountSheetLayout);

export { UploadCountSheet, UploadCountSheetLayout };
