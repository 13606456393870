const REQUEST_PAYMENT = {
  RELATED_COMPANY_EMPTY: 'Related company cannot be empty.',
  RELATED_LOT_EMPTY: 'Related lot cannot be empty.',
  PAYMENT_TYPE_EMPTY: 'Payment type cannot be empty.',
  FUNDS_TYPE_EMPTY: 'Funds type cannot be empty.',
  ADDITIONAL_INFO_INVALID: 'Additional information must have a valid format.',
  PAYMENT_AMOUNT_INVALID: 'Payment amount must have a valid format.',
  PAYMENT_AMOUNT_EMPTY: 'Payment amount cannot be empty.',
  PAYMENT_AMOUNT_NEGATIVE: 'Payment amount cannot be less or equal to 0.',
  PAYMENT_AMOUNT_TOO_HIGH: 'You cannot request more than available amount.',
  REQUESTED_FINAL_PAYMENT_DATE_EMPTY: 'Requested final payment date cannot be empty.',
  REQUESTED_FINAL_PAYMENT_DATE_TOO_LOW: 'Requested final payment date cannot be in the past.',
};

export { REQUEST_PAYMENT };
