import { setDesignation } from 'shared/helpers/dataParsers/lot/setDesignation';

import { history } from 'utils/history';

const overviewItemTemplate = [
  {
    render: ({ grgLotNumber, customLotNumber, designation }) =>
      customLotNumber || setDesignation(grgLotNumber, designation),
  },
];

const processingActionsTemplate = [
  {
    icon: 'icon-eye',
    onClick: lot => () => history.push(`/lots/processing/${lot._id}`),
  },
];

const mixingActionsTemplate = [
  {
    icon: 'icon-eye',
    onClick: lot => () => history.push(`/lots/mixing/${lot._id}`),
  },
];

export { overviewItemTemplate, processingActionsTemplate, mixingActionsTemplate };
