/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useEffect } from 'react';

function useInterval(callback, interval, immediate = false) {
  const currentCallback = useRef(callback);

  useEffect(() => {
    if (immediate) {
      callback();
    }
  }, []);

  useEffect(() => {
    currentCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const intervalId = setInterval(() => currentCallback.current(), interval);

    return () => {
      clearInterval(intervalId);
    };
  }, [interval, immediate]);
}

export { useInterval };
