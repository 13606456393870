import styled from 'styled-components';

import { media } from 'shared/helpers/styling/styling';

export const StyledMainInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  ${media.phone`
    flex-direction: column;
    align-items: flex-start;
    padding: 22px 22px 0;
    margin-top: 0;
    margin-bottom: 0;
  `}
`;

export const StyledMainInfoTitle = styled.h3`
  font-size: 30px;
  color: var(--whiteGRG);
  font-weight: 500;
`;

export const StyledMainInfoSubtitle = styled.h3`
  display: inline-block;
  color: var(--whiteGRG);
  font-size: 25px;
  font-weight: 500;
  opacity: 0.5;
  padding-right: 10px;
`;

export const StyledMainInfoButtons = styled.div`
  display: flex;
  ${media.phone`
    flex-direction: column;
    width: 100%;
  `}

  & > a {
    margin-left: 10px;
    ${media.phone`
      button {
        margin-left: 0;
        margin-top: 5px;
      }
  `}
  }
`;
