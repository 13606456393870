import styled from 'styled-components';

import { media } from 'shared/helpers/styling/styling';

const slices = 6;

export const StyledPreloaderWrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.75);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2000;
`;

export const StyledPreloaderComponent = styled.div`
  position: absolute;
  height: fit-content;
  margin: auto;
  border: none;
  outline: none;
  border-radius: 0;
  padding: 20px;
  max-width: 540px;
  max-height: 400px;
  top: 0;
  left: 0;
  z-index: 100;
  right: 0;
  bottom: 0;
  ${media.phone`max-width: 290px;`}
`;

export const StyledPreloaderContent = styled.div`
  padding: 0 60px;
  min-height: 300px;
  position: relative;
  text-align: center;
  ${media.phone`
padding: 20px 10px 0 10px;
text-align: center;
`}
`;

export const StyledPreloaderTextarea = styled.div`
  padding: 95px 0 35px 0;
`;

export const StyledPreloaderTitle = styled.h2`
  color: var(--whiteGRG);
  font-size: 3.2em;
`;

export const StyledPreloaderDesc = styled.p`
  color: var(--whiteGRG);
`;

export const StyledPreloaderSpinner = styled.div`
  ${[...new Array(slices)]
    .map(
      slice => `
      @keyframes preload-show-${slice + 1} {
        from {
          transform: rotateZ(${60 * (slice + 1)}deg) rotateY(-90deg) rotateX(0deg);
          border-left-color: var(--conifer);
        }
      }

      @keyframes preload-hide-${slice + 1} {
        to {
          transform: rotateZ(${60 * (slice + 1)}deg) rotateY(-90deg) rotateX(0deg);
          border-left-color: var(--conifer);
        }
      }

      @keyframes preload-cycle-${slice + 1} {
        $startIndex: ${(slice + 1) * 5};
        $reverseIndex: (${80 - (slice + 1) * 5});

        ${(slice + 1) * 5}% {
          transform: rotateZ(${60 * (slice + 1)}deg) rotateY(90deg) rotateX(0deg);
          border-left-color: var(--conifer);
        }

        ${(slice + 1) * 5 + 5}%,
        ${80 - (slice + 1) * 5}% {
          transform: rotateZ(${60 * (slice + 1)}deg) rotateY(0) rotateX(0deg);
          border-left-color: var(--atlantis);
        }

        ${80 - (slice + 1) * 5 + 5}%,
        100% {
          transform: rotateZ(${60 * (slice + 1)}deg) rotateY(90deg) rotateX(0deg);
          border-left-color: var(--conifer);
        }
      }
  `,
    )
    .join(' ')}

  position: absolute;
  top: 25px;
  left: 50%;
  font-size: 20px;
  display: block;
  width: 3.75em;
  height: 4.25em;
  margin-left: -1.875em;
  margin-top: -2.125em;
  transform-origin: center center;
  transform: rotateY(180deg) rotateZ(-60deg);

  .slice {
    border-top: 1.125em solid transparent;
    border-right: none;
    border-bottom: 1em solid transparent;
    border-left: 1.875em solid var(--atlantis);
    position: absolute;
    top: 0;
    left: 50%;
    transform-origin: left bottom;
    border-radius: 3px 3px 0 0;
  }

  animation: 2s preload-flip steps(2) infinite both;

  ${[...new Array(slices)]
    .map(
      (_, slice) => ` 
        .slice:nth-of-type(${slice + 1}n) {
          transform: rotateZ(${60 * (slice + 1)}deg) rotateY(90deg) rotateX(0);
          animation: 2s preload-cycle-${slice + 1} linear infinite both;
        }
      `,
    )
    .join(' ')}
`;
