import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getTermsActionType = 'GET_TERMS';
function getTermsAction() {
  return {
    type: getTermsActionType,
  };
}

const getTermsSuccessActionType = 'GET_TERMS_SUCCESS';
function getTermsSuccessAction(terms, pages, total) {
  return {
    type: getTermsSuccessActionType,
    payload: {
      terms,
      pages,
      total,
    },
  };
}

const getTermsFailureActionType = 'GET_TERMS_FAILURE';
function getTermsFailureAction(errorMessage) {
  return {
    type: getTermsFailureActionType,
    payload: {
      errorMessage,
    },
  };
}

const clearTermsListActionType = 'CLEAR_TERMS_LIST_ACTION';
function clearTermsList() {
  return {
    type: clearTermsListActionType,
  };
}

function companyDetailsGetTerms(companyId, limit, page) {
  return dispatch => {
    dispatch(getTermsAction());

    return axios
      .get(`${API_HOST_AUTH}/terms/companies/${companyId}/${page}/${limit}`)
      .then(res => {
        dispatch(getTermsSuccessAction(res.data.docs, res.data.pages, res.data.total));
      })
      .catch(() => {
        dispatch(getTermsFailureAction(''));
      });
  };
}

export {
  clearTermsListActionType,
  getTermsActionType,
  getTermsFailureActionType,
  getTermsSuccessActionType,
  companyDetailsGetTerms,
  clearTermsList,
};
