import styled from 'styled-components';

import { media } from 'shared/helpers/styling/styling';

export const StyledCalendar = styled.div`
  border: 1px solid var(--riverBedCalendar);
  background-color: var(--limedSpruceCalendar);

  .market-holiday {
    background: repeating-linear-gradient(
      -45deg,
      transparent,
      transparent 8px,
      var(--riverBedCalendarBorder) 10px,
      var(--riverBedCalendarBorder) 24px
    );
  }

  .rbc-month-view {
    border: 1px solid var(--riverBedCalendar);

    .rbc-month-header {
      height: 63px;

      .rbc-header {
        font-size: 14px;
        border: none;
        color: var(--alabaster);
        font-weight: normal;
        text-align: left;
        align-items: flex-end;
        display: flex;

        span {
          margin: 0 0 17px 20px;
        }
      }
      ${media.phone`
    height: 40px;

    .rbc-header {
      justify-content: center;
      align-items: center;

      span {
        margin: 0;
      }
    }
    `}
    }

    .rbc-today {
      background-color: var(--riverBedCalendar);
    }

    .rbc-off-range-bg {
      background-color: var(--outerSpaceTabs);
    }

    .rbc-month-row {
      flex-basis: initial;
      min-height: 250px;
      font-size: 14px;
      border-top: 2px solid rgba(255, 255, 255, 0.25);

      .noteEvent {
        top: 200px;
      }

      ${media.phone`
    min-height: 240px;

    .noteEvent {
      top: 180px;
    }
    `}

      .rbc-day-bg {
        border-left: 1px solid rgba(255, 255, 255, 0.07);
      }

      .rbc-date-cell {
        display: flex;
        align-items: flex-start;
        color: rgba(255, 255, 255, 0.5);
        border-bottom: 2px solid rgba(255, 255, 255, 0.15);

        a {
          margin: 19px 0 17px 22px;
          pointer-events: none;
        }

        &.rbc-now {
          font-weight: normal;
          border-bottom: 2px solid $silver;

          a {
            color: var(--alabaster);
          }
        }

        ${media.phone`
      height: 44px;
      padding-right: 0;
      align-items: center;
      justify-content: center;

      a {
        margin: 0;
      }
      `}
      }
    }

    .rbc-row-segment {
      padding-top: 6px;
      display: flex;
      justify-content: center;
    }

    .rbc-event {
      all: initial;
      font-family: 'Open Sans', sans-serif;
      margin: 1px 0;
    }
  }

  .rbc-time-view {
    border: 1px solid var(--riverBedCalendar);

    .rbc-time-gutter,
    .rbc-time-header-gutter,
    .rbc-time-content {
      display: none;
    }

    .rbc-today {
      background-color: var(--riverBedCalendar);
    }

    .rbc-time-header-cell {
      height: 64px;

      ${media.phone`
    height: 41px;
    `}
    }

    .rbc-time-header-content {
      border: none;
    }

    .rbc-allday-cell {
      height: 200px;

      ${media.phone`
    height: 220px;
    `}

      .rbc-row-segment {
        padding-top: 8px;
        display: flex;
        justify-content: center;
      }

      .rbc-event {
        all: initial;
        font-family: 'Open Sans', sans-serif;
        margin: 1px 0;
      }

      .rbc-day-bg {
        border-color: rgba(255, 255, 255, 0.07);
        border-top: 2px solid rgba(255, 255, 255, 0.15);

        &.rbc-today {
          border-top: 2px solid $silver;
        }
      }
    }

    .rbc-row {
      .rbc-header {
        font-size: 14px;
        border: none;
        font-weight: normal;
        text-align: left;
        align-items: flex-end;
        justify-content: center;
        display: flex;
        padding-bottom: 10px;
        border-left: 1px solid rgba(255, 255, 255, 0.07);

        &.rbc-today {
          span {
            opacity: 1;
          }
        }

        a {
          pointer-events: none;
        }

        &:first-of-type {
          border-left: none;
        }
      }
    }
  }

  .marked-day {
    border: 1px solid var(--gullGrayLighter) !important;
  }

  .rbc-header.marked-day {
    border-bottom: none !important;
  }

  .rbc-day-bg.marked-day {
    border-top: 2px solid rgba(255, 255, 255, 0.15) !important;
  }
`;
