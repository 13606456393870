import { connect } from 'react-redux';

import { downloadAttachment } from 'actions/Attachments/downloadAttachment';
import { clearPaymentDetails, getPayment } from 'actions/Payments/paymentDetails';
import { saveSharedData } from 'actions/shared/saveSharedData';
import { showSnackbar } from 'actions/shared/snackbar';

import { PaymentDetails } from './PaymentDetails';

const mapStateToProps = ({ auth, deletePayment, paymentDetails, downloadAttachmentStorage }) => ({
  auth,
  deletePayment,
  paymentDetails,
  downloadAttachmentStorage,
});

const mapDispatchToProps = {
  getPayment,
  clearPaymentDetails,
  showSnackbar,
  saveSharedData,
  downloadAttachment,
};

const ConnectedPaymentDetails = connect(mapStateToProps, mapDispatchToProps)(PaymentDetails);

export { ConnectedPaymentDetails };
