import React, { Component } from 'react';

import { FormContainer } from 'shared/components/Form';
import { POOL_ACCOUNTS } from 'shared/helpers/constants/poolAccounts/poolAccountsConstants';
import {
  successOptions,
  dangerOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { parseSummaryAvailableSelectData } from 'shared/helpers/dataParsers/poolAccounts/parseSummaryAvailableSelectData';

import { goBackOrTo } from 'utils/history';

import { ConnectedRequestSettlementForm } from '../RequestSettlementForm/ConnectedRequestSettlementForm';

class SettlementMockUp extends Component {
  normalizeValue = this.props.match.params.id && { relatedLot: this.props.match.params.id };

  componentDidMount() {
    this.props.getSummaryAvailableLots('lots-to-settle');
  }

  componentWillUnmount() {
    this.props.clearSummaryAvailableLots();
  }

  submit = values =>
    this.props
      .settlementMockUpRequest(values)
      .then(res => {
        this.props.showSnackbar(successOptions, res.message);
        goBackOrTo('/lots');
      })
      .catch(res => this.props.showSnackbar(dangerOptions, res.message));

  render() {
    const {
      summaryAvailableLots: { lots, isPending },
      auth: {
        user: { relatedCompany },
      },
    } = this.props;

    return (
      <FormContainer
        header={POOL_ACCOUNTS.SETTLEMENT_MOCK_UP}
        subHeader={relatedCompany.companyName}
        loaded={!isPending}
      >
        {() => (
          <ConnectedRequestSettlementForm
            summaryAvailableLots={parseSummaryAvailableSelectData(lots)}
            onSubmit={this.submit}
            initialValues={this.normalizeValue}
            allowNegativeValues
          />
        )}
      </FormContainer>
    );
  }
}

export { SettlementMockUp };
