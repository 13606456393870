import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getShipmentDetailsActionType = 'GET_SHIPMENT_DETAILS';
const getShipmentDetailsAction = () => ({
  type: getShipmentDetailsActionType,
});

const getShipmentDetailsSuccessActionType = `${getShipmentDetailsActionType}_SUCCESS`;
const getShipmentDetailsSuccessAction = shipment => ({
  type: getShipmentDetailsSuccessActionType,
  payload: {
    shipment,
  },
});

const getShipmentDetailsFailureActionType = `${getShipmentDetailsActionType}_FAILURE`;
const getShipmentDetailsFailureAction = errorMessage => ({
  type: getShipmentDetailsFailureActionType,
  payload: {
    errorMessage,
  },
});

const clearShipmentDetailsActionType = 'CLEAR_SHIPMENT_DETAILS_ACTION';
const clearShipmentDetailsAction = () => ({
  type: clearShipmentDetailsActionType,
});

const getShipment = shipmentId => dispatch => {
  dispatch(getShipmentDetailsAction());

  return axios
    .get(`${API_HOST_AUTH}/shipments/${shipmentId}`)
    .then(res => {
      dispatch(getShipmentDetailsSuccessAction(res.data));
      return res.data;
    })
    .catch(err => {
      dispatch(getShipmentDetailsFailureAction(err));

      return Promise.reject(err.data);
    });
};

export {
  getShipmentDetailsActionType,
  getShipmentDetailsAction,
  getShipmentDetailsSuccessActionType,
  getShipmentDetailsSuccessAction,
  getShipmentDetailsFailureActionType,
  getShipmentDetailsFailureAction,
  clearShipmentDetailsActionType,
  clearShipmentDetailsAction,
  getShipment,
};
