import styled from 'styled-components';

import { RouterButton } from 'shared/components/Buttons';
import { media } from 'shared/helpers/styling/styling';

export const StyledReadyToShipSection = styled.div`
  .DraggableSection {
    & > div:first-child {
      grid-row-start: unset;
    }

    &-list {
      height: 100%;
    }
  }
`;

export const StyledInfo = styled.div`
  color: var(--geyser);
  text-align: right;
  font-size: 1.4rem;
  margin-top: 3rem;
  margin-bottom: -3rem;
  display: flex;
  justify-content: space-between;

  ${media.phone`
        flex-direction: column;
        padding-right: 15px;`}
`;

export const StyledRouterButton = styled(RouterButton)`
  width: auto;
`;
