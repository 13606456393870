import { evaluate } from 'utils/evaluate';

const safeParseFloat = value =>
  typeof value === 'string' ? Number.parseFloat(value.replace(/,/, '.')) : value;

const tryParseNumber = (value, fallback) =>
  !Number.isNaN(parseFloat(value)) ? +value.toFixed(2) : fallback;

const roundUp = (value, precision = 2) => evaluate(value).toFixed(precision);

export { safeParseFloat, tryParseNumber, roundUp };
