import { materialTypeForAssay } from 'shared/helpers/constants/packages/materialType';

const createHedgeDefaultMaterialType = ({ packages }) => {
  const packageTotal = packages.length;

  const materialTypesCount = packages.reduce(
    (acc, { materialTypeDeclared }) => {
      switch (materialTypeDeclared) {
        case materialTypeForAssay.DPF_decanned:
          acc.DPF_decanned++;
          break;
        case materialTypeForAssay.DPF_canned:
          acc.DPF_canned++;
          break;
        case materialTypeForAssay.sensors:
          acc.sensors++;
          break;
        case materialTypeForAssay.foil_decanned:
          acc.foil_decanned++;
          break;
        case materialTypeForAssay.foil_canned:
          acc.foil_canned++;
          break;
        default:
          break;
      }
      return acc;
    },

    { DPF_decanned: 0, DPF_canned: 0, sensors: 0, foil_decanned: 0, foil_canned: 0 },
  );

  const materialTypesPercentages = [
    {
      type: materialTypeForAssay.DPF_decanned,
      value: materialTypesCount.DPF_decanned / packageTotal,
    },
    {
      type: materialTypeForAssay.DPF_canned,
      value: materialTypesCount.DPF_canned / packageTotal,
    },
    { type: materialTypeForAssay.sensors, value: materialTypesCount.sensors / packageTotal },
    {
      type: materialTypeForAssay.foil_decanned,
      value: materialTypesCount.foil_decanned / packageTotal,
    },
    {
      type: materialTypeForAssay.foil_canned,
      value: materialTypesCount.foil_canned / packageTotal,
    },
  ];

  return (
    materialTypesPercentages.find(material => material.value >= 0.9 && material.type)?.type || false
  );
};

export { createHedgeDefaultMaterialType };
