import { REQUEST_PAYMENT } from 'shared/helpers/constants/payments/requestPaymentConstants';
import { isHedgeNotRequired } from 'shared/helpers/matchers/companies/checkExceptionType';
import { replaceEmpty, removeCommasAndParse } from 'shared/helpers/parsers/text';
import { commaValidator } from 'shared/helpers/validations/commaValidator';
import { PRICE_REGEX } from 'shared/helpers/validations/regularExpressions';

import dateAdapter from 'utils/date/dateAdapter';
import { MomentAdapter } from 'utils/date/momentAdapter';

function validate(values, props) {
  const paymentAmount = props.paymentAmount && props.paymentAmount.data;
  const exceptionTypes = paymentAmount?.paymentExceptionCompany?.exceptionTypes || [];
  const errors = {};

  if (!values.relatedLot) {
    errors.relatedLot = REQUEST_PAYMENT.RELATED_LOT_EMPTY;
  }

  if (!values.fundsType) {
    errors.fundsType = REQUEST_PAYMENT.FUNDS_TYPE_EMPTY;
  }

  if (values.additionalInfo && values.additionalInfo.length > 250) {
    errors.additionalInfo = REQUEST_PAYMENT.ADDITIONAL_INFO_INVALID;
  }

  if (replaceEmpty(values.paymentAmount, '').length === 0) {
    errors.paymentAmount = REQUEST_PAYMENT.PAYMENT_AMOUNT_INVALID;
  } else if (!PRICE_REGEX.test(values.paymentAmount)) {
    errors.paymentAmount = REQUEST_PAYMENT.PAYMENT_AMOUNT_INVALID;
  } else if (values.paymentAmount && values.paymentAmount.toString().length > 12) {
    errors.paymentAmount = REQUEST_PAYMENT.PAYMENT_AMOUNT_INVALID;
  } else if (removeCommasAndParse(values.paymentAmount) <= 0) {
    errors.paymentAmount = REQUEST_PAYMENT.PAYMENT_AMOUNT_NEGATIVE;
  } else if (
    removeCommasAndParse(values.paymentAmount) >
      removeCommasAndParse(paymentAmount && paymentAmount.availableAmount) &&
    !isHedgeNotRequired(exceptionTypes)
  ) {
    errors.paymentAmount = REQUEST_PAYMENT.PAYMENT_AMOUNT_TOO_HIGH;
  }

  if (!values.requestedFinalPaymentDate) {
    errors.requestedFinalPaymentDate = REQUEST_PAYMENT.REQUESTED_FINAL_PAYMENT_DATE_EMPTY;
  } else if (
    dateAdapter(values.requestedFinalPaymentDate).isBefore(
      new MomentAdapter().subtract(1, 'd').startOf('d'),
    )
  ) {
    errors.requestedFinalPaymentDate = REQUEST_PAYMENT.REQUESTED_FINAL_PAYMENT_DATE_TOO_LOW;
  }

  commaValidator({ paymentAmount: values.paymentAmount }, errors);

  return errors;
}

export { validate };
