import styled from 'styled-components';

import { cssVar, pxToRem } from 'shared/helpers/styling/styling';

const PreferencesWrapper = styled.section`
  color: ${cssVar('alabaster')};
  font-size: ${pxToRem(14)};
  background: ${cssVar('riverBed')};
  border: 1px solid ${cssVar('riverBed')};
  margin-top: 4rem;

  & > form {
    display: grid;
    gap: 1px;
    grid-gap: 1px;
  }
`;

export { PreferencesWrapper };
