import {
  getDashboardActionType,
  getNotificationsOnDashboardSuccessActionType,
  getNotificationsOnDashboardWithTypeSuccessActionType,
  getNotificationsOnDashboardFailureActionType,
  clearDashboardActionType,
} from 'actions/Dashboard/dashboardNotificationsLists';

const INITIAL_STATE = {
  isPending: false,
  errorMessage: null,
  input: {
    items: [],
    isPending: false,
  },
  dashboardNotifications: [],
  limit: 50,
  page: 1,
  pages: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getDashboardActionType:
      return {
        ...state,
        isPending: true,
        limit: action.payload.limit,
        page: action.payload.page,
        input: {
          items: state.input.items,
          isPending: true,
        },
      };
    case getNotificationsOnDashboardFailureActionType:
      return {
        ...state,
        isPending: false,
        errorMessage: action.payload.errorMessage,
        dashboardNotifications: [],
        pages: null,
        page: null,
      };
    case getNotificationsOnDashboardSuccessActionType:
      return {
        ...state,
        isPending: false,
        dashboardNotifications: action.payload.notifications.reduce((acc, section) => {
          const [[key, value], [total, count]] = Object.entries(section);

          return {
            ...acc,
            [key]: value[0],
            [total]: count[0][0],
          };
        }, {}),
      };
    case getNotificationsOnDashboardWithTypeSuccessActionType:
      return {
        ...state,
        isPending: false,
        dashboardNotifications: {
          ...state.dashboardNotifications,
          [action.payload.type]: action.payload.notifications,
        },
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case clearDashboardActionType:
      return {
        isPending: false,
        errorMessage: null,
        input: [],
        dashboardNotifications: [],
        limit: 50,
        page: 1,
        pages: null,
      };
    default:
      return state;
  }
};
