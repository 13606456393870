import styled from 'styled-components';

import { DraggableSection } from 'shared/components/DraggableSection/DraggableSection';

export const StyledMixingOverviewWrapper = styled.div`
  .MixingOverview {
    &__header {
      display: flex;
      justify-content: space-between;

      &__title {
        display: flex;
      }
    }
  }
`;

export const StyledMixingOverviewDraggableSection = styled(DraggableSection)`
  grid-template-rows: auto 1fr;

  & > div:first-of-type {
    grid-row: 2 / 5;

    & > div {
      height: 100%;
    }
  }
`;
