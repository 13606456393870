import PropTypes from 'prop-types';
import React from 'react';

import { functionify } from 'shared/helpers/functions';

import { PreloaderInline } from '../PreloaderInline/PreloaderInline';

const ContentLoader = ({ className, loaded, children }) =>
  loaded ? (
    functionify(children)()
  ) : (
    <div className={className}>
      <PreloaderInline loading />
    </div>
  );

ContentLoader.propTypes = {
  loaded: PropTypes.bool,
};

ContentLoader.defaultProps = {
  loaded: false,
};

export { ContentLoader };
