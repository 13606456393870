const { companyTypesEnum } = require('shared/helpers/constants/filters/companyTypes');
const { statusesEnum } = require('shared/helpers/constants/filters/allStatuses');

const createPackagesAccess = user => {
  const {
    relatedCompany: { materialCountRequired, status, companyType },
  } = user;
  return !(
    companyType === companyTypesEnum.assay &&
    status === statusesEnum.active &&
    materialCountRequired
  );
};

export { createPackagesAccess };
