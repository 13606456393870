import React from 'react';

import { StyledNoResults } from './StyledNoResults';

function NoResults({ label }) {
  return (
    <StyledNoResults>{`No ${label.charAt(0).toLowerCase()}${label.substr(
      1,
    )} found`}</StyledNoResults>
  );
}

export { NoResults };
