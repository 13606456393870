import PropTypes from 'prop-types';
import { useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  clearCommoditiesOverviewList,
  getCommoditiesOverviewList,
} from 'actions/Commodities/commoditiesOverviewList';
import { deleteCommodity } from 'actions/Commodities/deleteCommodity';
import { getLot } from 'actions/Lots/lotDetails';
import { openModal } from 'actions/shared/modal';
import { showSnackbar } from 'actions/shared/snackbar';

import { ListCollapse } from 'shared/components/ListCollapse/ListCollapse';
import { OverviewList } from 'shared/components/OverviewList/OverviewList';
import {
  OverviewListSection,
  OverviewListSectionWrapper,
} from 'shared/components/OverviewList/OverviewListSection/OverviewListSection';
import { COMMODITIES } from 'shared/helpers/constants/commodities/commoditiesConstants';
import {
  actionsTemplateCommodities,
  itemsDetailsTemplate,
  itemsTemplateMobile,
  itemTemplate,
} from 'shared/helpers/constants/commodities/overviewTableConfig';
import { deleteCommodityModal } from 'shared/helpers/constants/modalConstants';
import { isTablet } from 'shared/helpers/constants/resolutionsConstants';
import {
  dangerOptions,
  successOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { useWindowWidth } from 'shared/hooks/useWindowWidth';

export const CommoditiesOverview = ({ auth }) => {
  const [isContentDisplayed, setIsContentDisplayed] = useState(true);

  const commoditiesOverviewList = useSelector(state => state.commoditiesOverviewList);
  const totalCommoditiesOverviewListItems = commoditiesOverviewList.items?.total;
  const dispatch = useDispatch();

  const { id } = useParams();

  const windowWidth = useWindowWidth();
  const isMobile = windowWidth < isTablet;

  const handleGetCommoditiesOverviewList = (_, limit) => {
    dispatch(getCommoditiesOverviewList(id, limit));
  };

  const handleClearCommoditiesOverviewList = () => {
    dispatch(clearCommoditiesOverviewList());
  };

  const toggleContent = () => {
    totalCommoditiesOverviewListItems && setIsContentDisplayed(prev => !prev);
  };

  const handleDeleteCommodity = ({ _id }) => {
    dispatch(
      openModal(deleteCommodityModal, () => {
        dispatch(deleteCommodity(_id)).then(
          res => {
            batch(() => {
              dispatch(showSnackbar(successOptions, res.message));
              dispatch(getLot(id));
              dispatch(getCommoditiesOverviewList(_id, commoditiesOverviewList.items.limit));
            });
          },
          error => {
            dispatch(showSnackbar(dangerOptions, error.message));
          },
        );
      }),
    );
  };

  return (
    <OverviewListSectionWrapper
      isOpen={isContentDisplayed}
      total={totalCommoditiesOverviewListItems}
    >
      <OverviewListSection isOpen={isContentDisplayed} total={totalCommoditiesOverviewListItems}>
        <div>
          <ListCollapse
            collapse={isContentDisplayed}
            toggleList={toggleContent}
            label={COMMODITIES.COMMODITIES}
          />
        </div>
      </OverviewListSection>
      <OverviewList
        getList={handleGetCommoditiesOverviewList}
        clearData={handleClearCommoditiesOverviewList}
        input={commoditiesOverviewList}
        itemsTemplate={itemTemplate}
        actionsTemplate={actionsTemplateCommodities(auth, handleDeleteCommodity)}
        itemsTemplateMobile={itemsTemplateMobile}
        itemDetailsTemplate={itemsDetailsTemplate}
        isMobile={isMobile}
        label={COMMODITIES.COMMODITIES}
      />
    </OverviewListSectionWrapper>
  );
};

CommoditiesOverview.propTypes = {
  auth: PropTypes.object,
};
