import React from 'react';

import { LinkButton } from 'shared/components/Buttons';
import { DetailsActivityField } from 'shared/components/Details/DetailsActivityField';
import { DetailsDateField } from 'shared/components/Details/DetailsDateField';
import { DetailsDownloadField } from 'shared/components/Details/DetailsDownloadFieldRedesign';
import { DetailsField } from 'shared/components/Details/DetailsField';
import { DetailsHumanizedField } from 'shared/components/Details/DetailsHumanizedField';
import { DetailsRadioField } from 'shared/components/Details/DetailsRadioFieldRedesign';
import { ProgressBar } from 'shared/components/ProgressBar';
import { COMPANY } from 'shared/helpers/constants/companies/companyConstants';
import { parsePayoutRule } from 'shared/helpers/constants/payments/payoutRulesTypes';
import { isGradingCompany } from 'shared/helpers/matchers/checkCompanyType';
import { isOwnerOrSupport } from 'shared/helpers/matchers/checkUserType';
import { formatToDollarWithPrefix } from 'shared/helpers/parsers/formaters';
import { cssVar } from 'shared/helpers/styling/styling';

import { MomentAdapter } from 'utils/date/momentAdapter';
import { isWest } from 'utils/versioning/Guards';

const companyDetailsFieldsConfig = (values, userType) => [
  {
    label: COMPANY.COMPANY_NAME,
    value: values.companyName,
    icon: 'icon-home',
    id: 'companyName',
    FieldType: DetailsField,
  },
  {
    label: COMPANY.LOT_PREFIX,
    value: values.lotPrefix,
    icon: 'icon-box',
    id: 'lotPrefix',
    FieldType: DetailsField,
  },
  {
    label: COMPANY.COMPANY_TYPE,
    icon: 'icon-type',
    value: values.companyType,
    id: 'companyType',
    FieldType: DetailsHumanizedField,
  },
  {
    label: COMPANY.COMPANY_NUMBER,
    value: values.companyNumber,
    icon: 'icon-home',
    id: 'companyNumber',
    FieldType: DetailsField,
  },
  {
    label: COMPANY.VOLUME,
    value: values.volume,
    icon: 'icon-volume',
    id: 'volume',
    FieldType: DetailsField,
  },
  {
    label: COMPANY.INDIVIDUAL_ASSAYS_ALLOWED,
    value: values.samplesAllowed,
    icon: 'icon-document',
    id: 'samplesAllowed',
    FieldType: DetailsRadioField,
  },
  {
    label: COMPANY.REFERRAL_METHOD,
    value: values.referralMethod,
    icon: 'icon-referral',
    id: 'referralMethod',
    FieldType: DetailsField,
  },
  {
    label: COMPANY.MATERIAL_COUNT_REQUIRED,
    value: values.materialCountRequired,
    icon: 'icon-document',
    id: 'materialCountRequired',
    FieldType: DetailsRadioField,
  },
  {
    label: COMPANY.ONLY_INTERNAL_HEDGING_AND_SETTLEMENT,
    value: values.onlyInternalHedgingAndSettlement,
    icon: 'icon-document',
    id: 'onlyInternalHedgingAndSettlement',
    FieldType: DetailsRadioField,
  },
  {
    label: COMPANY.GRG_DEFAULT_SHIPPING_LOCATION,
    value: values.grgDefaultShippingLocation.name,
    icon: 'icon-location',
    id: 'grgDefaultShippingLocation',
    FieldType: DetailsField,
  },
  {
    label: COMPANY.PURCHASING_APP_URL,
    value: values.purchasingAppURL,
    icon: 'icon-link',
    id: 'purchasingAppURL',
    customContent: values.purchasingAppURL && (
      <a href={values.purchasingAppURL} target="_blank" rel="noopener noreferrer">
        <LinkButton label={values.purchasingAppURL} />
      </a>
    ),
    FieldType: DetailsField,
  },
  {
    label: COMPANY.PAYOUT_RULE,
    value: (() => {
      return values.payoutRule && parsePayoutRule(values.payoutRule.rule);
    })(),
    icon: 'icon-payout-rules',
    id: 'payoutRule',
    FieldType: DetailsField,
  },
  isOwnerOrSupport(userType) && {
    label: COMPANY.BALANCE_REMAINING,
    value: values.balanceRemaining === 0 ? null : formatToDollarWithPrefix(values.balanceRemaining),
    icon: 'icon-dollar',
    id: 'balanceRemaining',
    FieldType: DetailsField,
    valueColor: values.balanceRemaining ? cssVar('solidRed') : undefined,
  },
];

const companyDiligenceFieldsConfig = ({ files, companyType }, downloadAttachment) => {
  const { photoId, documentForAML, businessLicense, materialSourcingAgreement } = files;
  return [
    {
      label: COMPANY.PHOTO_ID,
      value: photoId,
      id: 'photoId',
      onClick: index => downloadAttachment(photoId[index].relatedFile.path),
      FieldType: DetailsDownloadField,
      highlightLast: true,
    },
    {
      label: COMPANY.EXPIRES,
      value: photoId[photoId.length - 1]?.fileExpiresAt,
      id: 'photoIdExpiresAt',
      FieldType: DetailsDateField,
    },
    {
      label: COMPANY.UPLOADED_BY,
      user: photoId[photoId.length - 1]?.createdBy,
      date: photoId[photoId.length - 1]?.createdAt,
      id: 'photoIdCreatedBy',
      FieldType: DetailsActivityField,
    },
    {
      label: COMPANY.AML_CHECK,
      value: documentForAML,
      id: 'documentForAML',
      onClick: index => downloadAttachment(documentForAML[index].relatedFile.path),
      FieldType: DetailsDownloadField,
      highlightLast: true,
    },
    {
      label: COMPANY.EXPIRES,
      value: documentForAML[documentForAML.length - 1]?.fileExpiresAt,
      id: 'documentForAMLExpiresAt',
      FieldType: DetailsDateField,
    },
    {
      label: COMPANY.UPLOADED_BY,
      user: documentForAML[documentForAML.length - 1]?.createdBy,
      date: documentForAML[documentForAML.length - 1]?.createdAt,
      id: 'documentForAMLCreatedBy',
      FieldType: DetailsActivityField,
    },
    {
      label: COMPANY.BUSINESS_LICENSE,
      value: businessLicense,
      id: 'businessLicense',
      onClick: index => downloadAttachment(businessLicense[index].relatedFile.path),
      FieldType: DetailsDownloadField,
      highlightLast: true,
    },
    {
      label: COMPANY.EXPIRES,
      value: businessLicense[businessLicense.length - 1]?.fileExpiresAt,
      id: 'businessLicenseExpiresAt',
      FieldType: DetailsDateField,
    },
    {
      label: COMPANY.UPLOADED_BY,
      user: businessLicense[businessLicense.length - 1]?.createdBy,
      date: businessLicense[businessLicense.length - 1]?.createdAt,
      id: 'businessLicenseCreatedBy',
      FieldType: DetailsActivityField,
    },
    {
      label:
        isWest && isGradingCompany(companyType)
          ? COMPANY.MATERIAL_AGREEMENT_WEST
          : COMPANY.MATERIAL_AGREEMENT,
      value: materialSourcingAgreement,
      id: 'materialSourcingAgreement',
      onClick: index => downloadAttachment(materialSourcingAgreement[index].relatedFile.path),
      FieldType: DetailsDownloadField,
      highlightLast: true,
    },

    {
      label: COMPANY.UPLOADED_BY,
      user: materialSourcingAgreement[materialSourcingAgreement.length - 1]?.createdBy,
      date: materialSourcingAgreement[materialSourcingAgreement.length - 1]?.createdAt,
      id: 'materialSourcingAgreementCreatedBy',
      FieldType: DetailsActivityField,
    },
  ];
};

const companyDiligenceContainerConfig = (company, _, { notes }) => {
  const {
    photoId,
    documentForAML,
    businessLicense,
    materialSourcingAgreement,
    dueDiligenceQuestionnaire,
    locationVerification,
  } = company.files;
  const { diligence, questionnaireNote, locationNote } = notes;
  const progressCalculationsBasedOn = [
    {
      value: photoId,
      expiresAt: photoId[photoId.length - 1]?.fileExpiresAt,
    },
    {
      value: documentForAML,
      expiresAt: documentForAML[documentForAML.length - 1]?.fileExpiresAt,
    },
    {
      value: businessLicense,
      expiresAt: businessLicense[businessLicense.length - 1]?.fileExpiresAt,
    },
    {
      value: materialSourcingAgreement,
    },
    {
      value: diligence || [],
    },
    {
      value: questionnaireNote.length > 0 ? questionnaireNote : dueDiligenceQuestionnaire,
    },
    {
      value: locationNote.length > 0 ? locationNote : locationVerification,
    },
  ];

  const progressResult = progressCalculationsBasedOn.reduce(
    (acc, { value = [], expiresAt }) => {
      const momentAdapter = new MomentAdapter();

      if (
        (value.length && expiresAt >= momentAdapter.startOf('d').getTime()) ||
        (value.length && !expiresAt)
      )
        acc.correct++;
      acc.total++;
      acc.percentage = (acc.correct / acc.total) * 100;

      return acc;
    },
    { correct: 0, total: 0, percentage: 0 },
  );

  return {
    header: COMPANY.DUE_DILIGENCE,
    subHeader: 'All fields must be submitted for this client to be marked as verified',
    config: {
      noMargin: true,
    },
    secondHeader: (
      <ProgressBar
        now={progressResult.percentage}
        label={`${progressResult.correct} out of ${progressResult.total}`}
      />
    ),
    template: [
      'photoId photoId photoIdExpiresAt photoIdCreatedBy',
      'documentForAML documentForAML documentForAMLExpiresAt documentForAMLCreatedBy',
      'businessLicense businessLicense businessLicenseExpiresAt businessLicenseCreatedBy',
      'materialSourcingAgreement materialSourcingAgreement materialSourcingAgreementCreatedBy materialSourcingAgreementCreatedBy',
      'locationVerification locationVerification locationVerificationCreatedBy locationVerificationCreatedBy',
      'dueDiligenceQuestionnaire dueDiligenceQuestionnaire dueDiligenceQuestionnaireCreatedBy dueDiligenceQuestionnaireCreatedBy',
    ],
  };
};

const primaryContactFieldsConfig = primaryContact => [
  {
    label: COMPANY.CONTACT_PERSON,
    user: primaryContact,
    id: 'companyPerson',
    FieldType: DetailsActivityField,
  },
  {
    label: COMPANY.EMAIL,
    value: primaryContact.email,
    icon: 'icon-email',
    id: 'email',
    FieldType: DetailsField,
  },
];

const activityFieldsConfig = values => [
  {
    label: COMPANY.CREATED_BY,
    user: values.createdBy,
    date: values.createdAt,
    id: 'createdBy',
    FieldType: DetailsActivityField,
  },
  {
    label: COMPANY.UPDATED_BY,
    user: values.updatedBy,
    date: values.updatedAt,
    id: 'updatedBy',
    FieldType: DetailsActivityField,
  },
  {
    label: COMPANY.PAYOUT_RULE_ADDED,
    user: values.payoutRule && values.payoutRule.createdBy,
    date: values.payoutRule && values.payoutRule.createdAt,
    id: 'payoutRuleAddedBy',
    FieldType: DetailsActivityField,
  },
];

const companyDetailsContainerConfig = (company, userType) => ({
  header: COMPANY.GENERAL_DETAILS,
  template: [
    'companyName lotPrefix materialCountRequired onlyInternalHedgingAndSettlement',
    'companyType companyNumber samplesAllowed volume',
    'grgDefaultShippingLocation referralMethod purchasingAppURL purchasingAppURL',
    `payoutRule payoutRule ${
      isOwnerOrSupport(userType) ? 'balanceRemaining balanceRemaining' : 'payoutRule payoutRule'
    }`,
  ],
});

const primaryContactContainerConfig = (primaryContact, index, length) => ({
  header: length === 1 ? COMPANY.PRIMARY_CONTACT : `${COMPANY.PRIMARY_CONTACT} ${index + 1}`,
  template: ['companyPerson companyPerson email email'],
});

const activityContainerConfig = () => ({
  header: COMPANY.ACTIVITY,
  template: [
    'createdBy createdBy updatedBy updatedBy',
    'payoutRuleAddedBy payoutRuleAddedBy payoutRuleAddedBy payoutRuleAddedBy',
  ],
});

export {
  companyDetailsFieldsConfig,
  primaryContactFieldsConfig,
  activityFieldsConfig,
  companyDetailsContainerConfig,
  companyDiligenceContainerConfig,
  companyDiligenceFieldsConfig,
  primaryContactContainerConfig,
  activityContainerConfig,
};
