import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Field, formValueSelector } from 'redux-form';

import { IconButton } from 'shared/components/Buttons/IconButton/IconButton';
import { FieldCapitalized } from 'shared/components/Fields/FieldCapitalized/FieldCapitalized';
import { FieldInput } from 'shared/components/Fields/FieldInput/FieldInput';
import { FieldRadioButton } from 'shared/components/Fields/FieldRadioButton/FieldRadioButton';
import { Section } from 'shared/components/Form';
import { formName } from 'shared/components/Wizard/Wizard';
import { LOCATION } from 'shared/helpers/constants/locations/locationConstants';
import { normalize } from 'shared/helpers/parsers/boolean';
import { cssVar } from 'shared/helpers/styling/styling';

import SelectCountry from 'pages/Locations/components/LocationsManagement/LocationForm/components/SelectRegion/SelectCountry';

const specialCountries = ['US', 'CA'];

function AddLocation(props) {
  const { index, location, onRemove, change } = props;
  const selector = formValueSelector(formName);
  const locationValues = useSelector(state => selector(state, 'locations')[index]);

  const countryValue = locationValues.country && locationValues.country.value;
  const zipCodeRequired = useMemo(() => specialCountries.includes(countryValue), [countryValue]);

  const actions = onRemove && (
    <IconButton onClick={onRemove} icon="icon-cancel" color={cssVar('whiteGRG')} />
  );

  return (
    <>
      <Section
        header={index ? LOCATION.LOCATION : LOCATION.PRIMARY_LOCATION}
        template={[`${location}.name .`]}
        actions={actions}
      >
        <Field
          name={`${location}.name`}
          component={FieldInput}
          label={LOCATION.NAME}
          field="required"
        />
      </Section>
      <SelectCountry formName={location} change={change} locationValues={locationValues} preFill />
      <Section
        header={LOCATION.ADDRESS}
        template={[
          `${location}.city .`,
          `${location}.street ${location}.suite`,
          `${location}.zipCode ${location}.poBox`,
        ]}
      >
        <Field
          name={`${location}.city`}
          component={FieldInput}
          label={LOCATION.CITY}
          field="required"
        />
        <Field
          name={`${location}.street`}
          component={FieldInput}
          label={LOCATION.STREET}
          field="required"
        />
        <Field name={`${location}.suite`} component={FieldInput} label={LOCATION.SUITE} />
        <Field
          name={`${location}.zipCode`}
          component={FieldInput}
          label={LOCATION.ZIP_CODE}
          field={zipCodeRequired && 'required'}
        />
        <Field name={`${location}.poBox`} component={FieldInput} label={LOCATION.PO_BOX} />
      </Section>
      <Section
        header={LOCATION.ATTRIBUTES}
        template={[`${location}.lotSuffix ${location}.shipmentEligible`]}
      >
        <FieldCapitalized
          name={`${location}.lotSuffix`}
          component={FieldInput}
          label={LOCATION.LOT_SUFFIX}
          field="unique required"
        />
        <Field
          name={`${location}.shipmentEligible`}
          component={FieldRadioButton}
          label={LOCATION.SHIPMENT_ELIGIBLE}
          field="required"
          normalize={normalize}
        />
      </Section>
    </>
  );
}

export { AddLocation };
