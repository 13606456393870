import { statusesForGlobal } from 'shared/helpers/constants/lots/statusesForGlobal';

const fillPackagesForMixing = (
  {
    access: {
      lots: { acceptForMixing },
    },
  },
  lot,
  packagesList,
) => {
  const postMillPackages = packagesList && packagesList['post-mill'];
  const packages = postMillPackages && !postMillPackages.docs.length;
  const missingWeight =
    postMillPackages &&
    postMillPackages.docs.some(p => p.weightGrossActual === null || p.weightTareActual === null);

  return (
    acceptForMixing &&
    lot.statusForGlobal === statusesForGlobal.ready_for_mixing &&
    lot.canBeMixed &&
    !packages &&
    missingWeight
  );
};

export { fillPackagesForMixing };
