import React, { useCallback, useRef, useLayoutEffect } from 'react';
import { withRouter } from 'react-router';
import styled, { css } from 'styled-components';

import { pxToRem } from 'shared/helpers/styling/styling';
import { useWindowWidth } from 'shared/hooks/useWindowWidth';

const Container = styled.div`
  grid-area: label;
  justify-self: end;
  display: grid;
  min-width: 86px;
  align-items: center;
  grid-template-columns: minmax(74px, auto) 12px;
  grid-template-rows: minmax(32px, auto);
  grid-template-areas: 'text arrow';

  ${({ clickable }) =>
    clickable &&
    css`
      :hover {
        opacity: 0.9;
        cursor: pointer;
      }
    `};
`;

const Text = styled.div`
  display: flex;
  min-height: 32px;
  justify-content: center;
  align-items: center;
  grid-area: text;
  padding: 0 12px;
  color: white;
  background-color: ${({ color }) => color};
  font-size: ${pxToRem(14)};
  border-radius: 8px 0 0 8px;
`;

const Arrow = styled.div`
  grid-area: arrow;
  box-sizing: border-box;
  border-style: solid;
  border-color: transparent transparent transparent ${({ color }) => color};
  border-width: 16px 0px 16px 12px;
`;

function LabelBase({ children, type, color = 'gray', resourceId, pathResolver, history }) {
  const onClick = useCallback(() => {
    if (!resourceId) return;

    const path = pathResolver(type, resourceId);
    if (path) history.push(path);
  }, [history, pathResolver, type, resourceId]);

  const textRef = useRef(null);
  const arrowRef = useRef(null);

  useWindowWidth();
  useLayoutEffect(() => {
    const arrowBorder = `${textRef.current.clientHeight / 2}px`;
    arrowRef.current.style.borderTopWidth = arrowBorder;
    arrowRef.current.style.borderBottomWidth = arrowBorder;
  });

  return (
    <Container clickable={!!resourceId} onClick={onClick}>
      <Text color={color} ref={textRef}>
        {children}
      </Text>
      <Arrow color={color} ref={arrowRef} />
    </Container>
  );
}

const Label = withRouter(LabelBase);

export { Label };
