import React, { Component } from 'react';

import { UIAuthorization } from 'components/Authorization/UIAuthorization';

import { DatepickerDropdown } from 'shared/components/DatepickerDropdown/DatepickerDropdown';
import { FiltersDropdown } from 'shared/components/FiltersDropdown/FiltersDropdown';
import { LocationsDropdown } from 'shared/components/LocationsDropdown/LocationsDropdown';
import { selfDeliveryRequestStatuses } from 'shared/helpers/constants/selfDeliveryRequests/selfDeliveryRequestStatuses';
import { SELF_DELIVERY_REQUESTS } from 'shared/helpers/constants/selfDeliveryRequests/selfDeliveryRequestsConstants';
import { isGradingUser } from 'shared/helpers/matchers/checkUserType';

import dateAdapter from 'utils/date/dateAdapter';
import { MomentAdapter } from 'utils/date/momentAdapter';

import { StyledSelfDeliveryRequestFilters, StyledItem } from './StyledSelfDeliveryRequestFilters';

class SelfDeliveryRequestsFilters extends Component {
  filterFunction = name => value => {
    const mappedValue =
      name === 'relatedCompany'
        ? { [name]: value._id ? value : '' }
        : { [name]: value instanceof MomentAdapter ? value.valueOf() : value };

    this.props.onFiltersChanged({
      ...this.props.filters,
      ...mappedValue,
    });
  };

  renderDateFilter = (name, label, minDate, maxDate, selected) => (
    <StyledItem>
      <DatepickerDropdown
        label={label}
        selected={selected && dateAdapter(selected)}
        filterFunction={this.filterFunction(name)}
        minDate={dateAdapter(minDate || 0)}
        maxDate={dateAdapter(maxDate)}
      />
    </StyledItem>
  );

  render() {
    const {
      filters: { deliveryDate, relatedCompany, status },
      companiesForFilters,
      relatedCompanyFilter,
      auth: {
        user: { userType },
      },
    } = this.props;

    return (
      <StyledSelfDeliveryRequestFilters>
        <UIAuthorization access={!isGradingUser(userType)}>
          <StyledItem>
            <FiltersDropdown
              label={SELF_DELIVERY_REQUESTS.STATUS}
              options={Object.values(selfDeliveryRequestStatuses.all)}
              selected={status || ''}
              filterFunction={this.filterFunction('status')}
            />
          </StyledItem>
          <StyledItem>
            <LocationsDropdown
              label={SELF_DELIVERY_REQUESTS.RELATED_COMPANY}
              options={companiesForFilters.data}
              selected={relatedCompany || ''}
              filterFunction={relatedCompanyFilter}
              filterOptionsSelected={this.filterFunction('relatedCompany')}
              isSearchable
            />
          </StyledItem>
        </UIAuthorization>
        {this.renderDateFilter(
          'deliveryDateStart',
          SELF_DELIVERY_REQUESTS.DELIVERY_DATE_FROM,
          0,
          deliveryDate.to,
          deliveryDate.from,
        )}
        {this.renderDateFilter(
          'deliveryDateEnd',
          SELF_DELIVERY_REQUESTS.DELIVERY_DATE_TO,
          deliveryDate.from,
          Infinity,
          deliveryDate.to,
        )}
      </StyledSelfDeliveryRequestFilters>
    );
  }
}

export { SelfDeliveryRequestsFilters };
