import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { isTablet } from 'shared/helpers/constants/resolutionsConstants';
import { cssVar, pxToRem } from 'shared/helpers/styling/styling';

import { getBrowserWidth } from 'utils/responsive';

import { withFieldWrapper } from '../FieldWrapper/FieldWrapper';

const getStateColor =
  (errorColor, noValueColor, valueColor) =>
  ({ value, meta }) => {
    if (meta && meta.touched && meta.error) return errorColor;

    return value ? valueColor : noValueColor;
  };

const InputWrapper = styled.span`
  position: relative;
  display: flex;
  align-items: center;
  color: ${cssVar('alabaster')};
  font-size: ${({ fontSize }) => pxToRem(fontSize)};

  ::before {
    position: absolute;
    display: flex;
    justify-content: center;
    visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
    width: ${({ prefixSize }) => prefixSize}px;
    padding: 0 2px;
    text-align: start;
    overflow: hidden;
    content: '${({ prefix }) => prefix}';
  }

  ::after {
    position: absolute;
    display: flex;
    justify-content: center;
    visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
    right: 0;
    width: ${({ suffixSize }) => suffixSize}px;
    padding: 0 2px;
    text-align: end;
    overflow: hidden;
    content: '${({ suffix }) => suffix}';
  }
`;

InputWrapper.propTypes = {
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  fontSize: PropTypes.number,
};

InputWrapper.defaultProps = {
  prefix: '',
  suffix: '',
  fontSize: 14,
};

const Input = styled.input`
  height: ${({ height }) => height}px;
  flex-grow: 1;
  padding: ${({ prefixSize, suffixSize, suffix, padding }) =>
    `${padding}px ${suffix ? suffixSize : prefixSize}px ${padding}px ${prefixSize}px`};
  color: ${({ color }) => color || cssVar('alabaster')};
  resize: none;
  border: 2px solid
    ${getStateColor(cssVar('copperRust'), cssVar('paleSky'), cssVar('regentGrayLighter'))};
  border-radius: 5px;
  background-color: ${getStateColor(
    cssVar('limedSpruceLighter'),
    cssVar('limedSpruce'),
    cssVar('limedSpruceLighter'),
  )};
  text-indent: 3px;
  transition: 100ms ease-in-out;
  text-transform: ${({ isUpperCase }) => (isUpperCase ? 'uppercase' : 'none')};

  :hover {
    background-color: ${cssVar('limedSpruceLighter')};
    border-color: ${getStateColor(
      cssVar('copperRust'),
      cssVar('regentGrayLighter'),
      cssVar('regentGrayLighter'),
    )};
  }

  :focus {
    background-color: ${cssVar('outerSpaceLighter')};
    border-color: ${getStateColor(
      cssVar('copperRust'),
      cssVar('gullGrayLighter'),
      cssVar('gullGrayLighter'),
    )};
  }

  :hover,
  :focus {
    outline: none;
  }

  :disabled {
    border-color: ${cssVar('limedSpruceLightest')};
    background-color: ${cssVar('limedSpruceForm')};
    opacity: 1;
  }

  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const setFieldType = propsType => {
  if (propsType === 'number') return getBrowserWidth() < isTablet ? propsType : 'text';
  return propsType;
};

const FieldInput = withFieldWrapper(props => (
  <InputWrapper
    prefix={props.prefix}
    suffix={props.suffix}
    prefixSize={props.prefixSize}
    suffixSize={props.suffixSize}
    show={props.meta && (props.meta.active || props.input.value)}
  >
    <Input
      {...props}
      {...props.input}
      {...(props.disabled && { onDrop: e => e.preventDefault() })}
      id={props.input.name}
      type={setFieldType(props.type)}
      autoComplete="off"
      isUpperCase={props.isUpperCase}
    />
  </InputWrapper>
));

FieldInput.propTypes = {
  height: PropTypes.number,
  prefixSize: PropTypes.number,
  suffixSize: PropTypes.number,
  padding: PropTypes.number,
  pretendMobile: PropTypes.bool,
  color: PropTypes.string,
  isUpperCase: PropTypes.bool,
};

FieldInput.defaultProps = {
  height: 50,
  prefixSize: 26,
  suffixSize: 60,
  padding: 0,
  pretendMobile: false,
  isUpperCase: false,
};

export { FieldInput, Input, InputWrapper };
