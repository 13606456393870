import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const updateShipmentInfoType = 'UPDATE_SHIPMENT_INFO';
const updateShipmentInfoAction = () => ({
  type: updateShipmentInfoType,
});

const updateShipmentInfoSuccessType = `${updateShipmentInfoType}_SUCCESS`;
const updateShipmentInfoSuccessAction = () => ({
  type: updateShipmentInfoSuccessType,
});

const updateShipmentInfoFailureType = `${updateShipmentInfoType}_FAILURE`;
const updateShipmentInfoFailureAction = () => ({
  type: updateShipmentInfoFailureType,
});

const updateShipmentInfo = (lotId, data) => dispatch => {
  dispatch(updateShipmentInfoAction());

  return axios
    .put(`${API_HOST_AUTH}/lots/update-shipment-info/${lotId}`, data)
    .then(res => {
      dispatch(updateShipmentInfoSuccessAction(res.data.lot));

      return res.data;
    })
    .catch(err => {
      dispatch(updateShipmentInfoFailureAction());

      return Promise.reject(err.data);
    });
};

export {
  updateShipmentInfoType,
  updateShipmentInfoSuccessType,
  updateShipmentInfoFailureType,
  updateShipmentInfo,
};
