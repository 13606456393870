import {
  createMultiplePackagesActionType,
  createMultiplePackagesSuccessActionType,
  createMultiplePackagesFailureActionType,
} from 'actions/Packages/createMultiplePackages';

const INITIAL_STATE = {
  isPending: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case createMultiplePackagesActionType:
      return {
        isPending: true,
      };
    case createMultiplePackagesFailureActionType:
    case createMultiplePackagesSuccessActionType:
      return {
        isPending: false,
      };
    default:
      return state;
  }
};
