import styled from 'styled-components';

import { cssVar, pxToRem } from 'shared/helpers/styling/styling';

const getStateColor =
  (errorColor, noValueColor, valueColor) =>
  ({ value, error }) => {
    if (error) return errorColor;

    return value ? valueColor : noValueColor;
  };

export const InputWrapper = styled.span`
  position: relative;
  display: flex;
  align-items: center;
  color: ${cssVar('alabaster')};
  font-size: ${({ fontSize }) => pxToRem(fontSize)};

  ::before {
    position: absolute;
    display: flex;
    justify-content: center;
    visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
    width: ${({ prefixSize }) => prefixSize}px;
    padding: 0 2px;
    text-align: start;
    overflow: hidden;
    content: '${({ prefix }) => prefix}';
  }

  ::after {
    position: absolute;
    display: flex;
    justify-content: center;
    visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
    right: 0;
    width: ${({ suffixSize }) => suffixSize}px;
    padding: 0 2px;
    text-align: end;
    overflow: hidden;
    content: '${({ suffix }) => suffix}';
  }
`;

export const Input = styled.input`
  height: ${({ height }) => height}px;
  flex-grow: 1;
  padding: ${({ prefixSize, suffixSize, suffix, padding }) =>
    `${padding}px ${suffix ? suffixSize : prefixSize}px ${padding}px ${prefixSize}px`};
  color: ${({ color }) => color || cssVar('alabaster')};
  resize: none;
  border: 2px solid
    ${getStateColor(cssVar('copperRust'), cssVar('paleSky'), cssVar('regentGrayLighter'))};
  border-radius: 5px;
  background-color: ${getStateColor(
    cssVar('limedSpruceLighter'),
    cssVar('limedSpruce'),
    cssVar('limedSpruceLighter'),
  )};
  text-indent: 3px;
  transition: 100ms ease-in-out;
  text-transform: ${({ isUpperCase }) => (isUpperCase ? 'uppercase' : 'none')};

  :hover {
    background-color: ${cssVar('limedSpruceLighter')};
    border-color: ${getStateColor(
      cssVar('copperRust'),
      cssVar('regentGrayLighter'),
      cssVar('regentGrayLighter'),
    )};
  }

  :focus {
    background-color: ${cssVar('outerSpaceLighter')};
    border-color: ${getStateColor(
      cssVar('copperRust'),
      cssVar('gullGrayLighter'),
      cssVar('gullGrayLighter'),
    )};
  }

  :hover,
  :focus {
    outline: none;
  }

  :disabled {
    border-color: ${cssVar('limedSpruceLightest')};
    background-color: ${cssVar('outerSpaceBrighter')};
    opacity: 1;
  }

  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
