import { NOTE } from 'shared/helpers/constants/noteConstants';

export const isNoteRemovable = (access, { type }) => {
  switch (type) {
    case NOTE.NOTES_TYPE.DILIGENCE:
    case NOTE.NOTES_TYPE.LOCATION_NOTE:
    case NOTE.NOTES_TYPE.QUESTIONNAIRE_NOTE:
      return access.companies.details.dueDiligence.notesPanel.remove;
    case NOTE.NOTES_TYPE.SETTLEMENTS:
      return access.reports.openFinals.notesPanel.remove;
    default:
      return false;
  }
};
