import PDFPreview from './PDFPreview';
import AuthReducer from './auth';
import ModalReducer from './modal';
import RedirectReducer from './redirect';
import SharedData from './saveSharedData';
import SessionSnackbarReducer from './sessionSnackbar';
import SnackbarReducer from './snackbar';

const sharedRootReducer = {
  auth: AuthReducer,
  modal: ModalReducer,
  snackbar: SnackbarReducer,
  redirect: RedirectReducer,
  PDFPreview: PDFPreview,
  sharedData: SharedData,
  sessionSnackbar: SessionSnackbarReducer,
};

export { sharedRootReducer };
