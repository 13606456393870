import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getHedgesOverviewActionType = 'GET_HEDGES_OVERVIEW_LIST';
const getHedgesOverviewAction = () => ({
  type: getHedgesOverviewActionType,
});

const getHedgesOverviewSuccessActionType = `${getHedgesOverviewActionType}_SUCCESS`;
const getHedgesOverviewSuccessAction = data => ({
  type: getHedgesOverviewSuccessActionType,
  payload: {
    data,
  },
});
const getHedgesOverviewTableSuccessActionType = `${getHedgesOverviewActionType}_TABLE_SUCCESS`;
const getHedgesOverviewTableSuccessAction = (data, hedgeName) => ({
  type: getHedgesOverviewTableSuccessActionType,
  payload: {
    data,
    hedgeName,
  },
});

const getHedgesOverviewFailureActionType = `${getHedgesOverviewActionType}_FAILURE`;
const getHedgesOverviewFailureAction = () => ({
  type: getHedgesOverviewFailureActionType,
});

const clearHedgesOverviewListActionType = 'CLEAR_HEDGES_OVERVIEW_LIST';
const clearHedgesOverviewList = () => ({
  type: clearHedgesOverviewListActionType,
});

const getHedgesOverviewList = (lotId, limit, hedgeName) => dispatch => {
  dispatch(getHedgesOverviewAction());

  return axios
    .get(`${API_HOST_AUTH}/hedges/overview-list/${lotId}/${limit}`)
    .then(({ data }) => {
      dispatch(getHedgesOverviewSuccessAction(data));
      dispatch(getHedgesOverviewTableSuccessAction(data, hedgeName));
      return data;
    })
    .catch(() => dispatch(getHedgesOverviewFailureAction()));
};

export {
  getHedgesOverviewActionType,
  getHedgesOverviewSuccessActionType,
  getHedgesOverviewTableSuccessActionType,
  getHedgesOverviewFailureActionType,
  clearHedgesOverviewListActionType,
  getHedgesOverviewList,
  clearHedgesOverviewList,
};
