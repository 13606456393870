import { connect } from 'react-redux';

import { marketAvailability } from 'actions/Hedges/marketAvailability';
import { deleteMarketHolidays } from 'actions/Hedges/marketHolidaysDelete';
import { getMarketHolidays, clearMarketHolidays } from 'actions/Hedges/marketHolidaysList';
import { openModal } from 'actions/shared/modal';
import { showSnackbar } from 'actions/shared/snackbar';

import { MarketHolidays } from './MarketHolidays';

const mapStateToProps = ({ marketHolidaysList, auth }) => ({
  marketHolidaysList,
  auth,
});

const dispatchMapToProps = {
  getMarketHolidays,
  clearMarketHolidays,
  deleteMarketHolidays,
  showSnackbar,
  openModal,
  marketAvailability,
};

const ConnectedMarketHolidays = connect(mapStateToProps, dispatchMapToProps)(MarketHolidays);

export { ConnectedMarketHolidays };
