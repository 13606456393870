import React, { useEffect, useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';

import { getPriceFeed, clearPriceFeed } from 'actions/PriceFeeds/getPriceFeed';
import { updatePriceFeed } from 'actions/PriceFeeds/updatePriceFeed';
import { showSnackbar } from 'actions/shared/snackbar';

import { FormContainer } from 'shared/components/Form';
import {
  dangerOptions,
  successOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { PRICE_FEEDS } from 'shared/helpers/constants/systemSettings/priceFeeds/constants';
import { humanize, capitalize, removeCommasAndParse } from 'shared/helpers/parsers/text';

import { UpdatePriceFeedForm } from './components/UpdatePriceFeedForm/UpdatePriceFeedForm';

function UpdatePriceFeedBase(props) {
  const {
    history,
    match: {
      params: { id },
    },
    priceFeed: { priceFeed, isPending },
  } = props;
  useEffect(() => {
    props.getPriceFeed(id).catch(({ message }) => props.showSnackbar(dangerOptions, message));

    return () => clearPriceFeed();
  }, [id]);

  const initialValues = useMemo(
    () =>
      priceFeed && {
        source: {
          label: humanize(priceFeed.source),
          value: priceFeed.source,
        },
        adjustment: priceFeed.adjustment,
        manualPrice: priceFeed.manualPrice,
      },
    [priceFeed],
  );

  const onSubmit = useCallback(
    ({ source, adjustment, manualPrice }) => {
      const data = {
        source: source.value,
        ...(manualPrice && { manualPrice: removeCommasAndParse(manualPrice) }),
        ...(adjustment && { adjustment: removeCommasAndParse(adjustment) }),
      };

      props
        .updatePriceFeed(id, data)
        .then(res => {
          props.showSnackbar(successOptions, res.message);
          history.push('/settings');
        })
        .catch(res => props.showSnackbar(dangerOptions, res.message));
    },
    [id, props.updatePriceFeed],
  );

  const metal = priceFeed && priceFeed.metal;

  return (
    <FormContainer
      loaded={!isPending}
      header={PRICE_FEEDS.UPDATE_PRICE_FEED}
      subHeader={capitalize(metal)}
    >
      <UpdatePriceFeedForm initialValues={initialValues} onSubmit={onSubmit} />
    </FormContainer>
  );
}

const mapStateToProps = state => ({
  priceFeed: state.getPriceFeed,
});

const mapDispatchToProps = {
  showSnackbar,
  updatePriceFeed,
  getPriceFeed,
  clearPriceFeed,
};

const UpdatePriceFeed = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
)(UpdatePriceFeedBase);

export { UpdatePriceFeedBase, UpdatePriceFeed };
