import { CREATE_PACKAGE } from 'shared/helpers/constants/packages/createPackageConstants';
import { safeParseFloat } from 'shared/helpers/parsers/number';
import { replaceEmpty } from 'shared/helpers/parsers/text';
import { commaValidator } from 'shared/helpers/validations/commaValidator';
import { FLOAT_REGEX } from 'shared/helpers/validations/regularExpressions';

function validate(values) {
  const errors = {};

  if (!values.updateWeightGrossActual) {
    errors.updateWeightGrossActual = CREATE_PACKAGE.INVALID_WEIGHT_GROSS_ACTUAL_EMPTY;
  }
  if (values.updateWeightGrossActual <= 0) {
    errors.updateWeightGrossActual = CREATE_PACKAGE.INVALID_WEIGHT_GROSS_ACTUAL_LESS;
  }
  if (
    (values.updateWeightGrossActual && !FLOAT_REGEX.test(values.updateWeightGrossActual)) ||
    replaceEmpty(values.updateWeightGrossActual, '').length > 10
  ) {
    errors.updateWeightGrossActual = CREATE_PACKAGE.INVALID_WEIGHT_GROSS_ACTUAL;
  }

  if (!values.updateWeightTareActual) {
    errors.updateWeightTareActual = CREATE_PACKAGE.INVALID_WEIGHT_TARE_ACTUAL_EMPTY;
  }
  if (values.updateWeightTareActual < 0) {
    errors.updateWeightTareActual = CREATE_PACKAGE.INVALID_WEIGHT_TARE_ACTUAL_LESS;
  }
  if (
    (values.updateWeightTareActual && !FLOAT_REGEX.test(values.updateWeightTareActual)) ||
    replaceEmpty(values.updateWeightTareActual, '').length > 10
  ) {
    errors.updateWeightTareActual = CREATE_PACKAGE.INVALID_WEIGHT_TARE_ACTUAL;
  }
  if (
    (!values.updateWeightGrossActual && values.updateWeightTareActual) ||
    safeParseFloat(values.updateWeightGrossActual) <= safeParseFloat(values.updateWeightTareActual)
  ) {
    errors.updateWeightTareActual = CREATE_PACKAGE.INVALID_WEIGHT_TARE_ACTUAL_GREATER;
  }

  commaValidator(
    {
      updateWeightTareActual: values.updateWeightTareActual,
      updateWeightGrossActual: values.updateWeightGrossActual,
    },
    errors,
  );

  return errors;
}

export { validate };
