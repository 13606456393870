import {
  changePasswordUsingTokenActionType,
  changePasswordUsingTokenFailureActionType,
  changePasswordUsingTokenSuccessActionType,
} from 'actions/Users/changePasswordUsingToken';

const INITIAL_STATE = {
  isPending: false,
};

const changePasswordUsingTokenReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case changePasswordUsingTokenActionType:
      return {
        isPending: true,
      };
    case changePasswordUsingTokenSuccessActionType:
    case changePasswordUsingTokenFailureActionType:
      return {
        isPending: false,
      };
    default:
      return state;
  }
};

export { changePasswordUsingTokenReducer };
