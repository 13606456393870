import { dateFormat } from 'shared/helpers/parsers/date';
import { formatToDollarWithPrefix, formatToUS } from 'shared/helpers/parsers/formaters';

import dateAdapter from 'utils/date/dateAdapter';

import { POOL_ACCOUNTS } from './poolAccountsConstants';

const headerGroups = [
  {
    label: null,
    gridArea: 'company',
  },
  {
    label: null,
    gridArea: 'weights',
  },
  {
    label: POOL_ACCOUNTS.CURRENT_MARKET_VALUE,
    gridArea: 'values',
  },
  {
    label: null,
    gridArea: 'dates',
  },
  {
    label: null,
    gridArea: 'actions',
  },
];

headerGroups.gridTemplate =
  'company weights weights weights values values values values dates dates actions';

const customerHeaderGroups = [
  {
    label: null,
    gridArea: 'weights',
  },
  {
    label: POOL_ACCOUNTS.CURRENT_MARKET_VALUE,
    gridArea: 'values',
  },
];

customerHeaderGroups.gridTemplate = 'weights weights weights values values values values';

const itemsTemplate = [
  {
    label: POOL_ACCOUNTS.COMPANY,
    render: poolAccount =>
      poolAccount.relatedCompany ? poolAccount.relatedCompany.companyName : '-',
  },
  {
    label: POOL_ACCOUNTS.PLATINUM_OZ,
    render: ({ platinumOz }) => formatToUS(platinumOz, 3),
  },
  {
    label: POOL_ACCOUNTS.PALLADIUM_OZ,
    render: ({ palladiumOz }) => formatToUS(palladiumOz, 3),
  },
  {
    label: POOL_ACCOUNTS.RHODIUM_OZ,
    render: ({ rhodiumOz }) => formatToUS(rhodiumOz, 3),
  },
  {
    label: POOL_ACCOUNTS.PT,
    render: ({ platinumValue }) => formatToDollarWithPrefix(platinumValue),
    className: 'left-border',
  },
  {
    label: POOL_ACCOUNTS.PD,
    render: ({ palladiumValue }) => formatToDollarWithPrefix(palladiumValue),
  },
  {
    label: POOL_ACCOUNTS.RH,
    render: ({ rhodiumValue }) => formatToDollarWithPrefix(rhodiumValue),
  },
  {
    label: POOL_ACCOUNTS.GRAND_TOTAL,
    render: ({ grandTotal }) => formatToDollarWithPrefix(grandTotal),
    className: 'right-border',
  },
  {
    label: POOL_ACCOUNTS.UPDATED_BY,
    render: ({ updatedBy }) => `${updatedBy.firstName} ${updatedBy.lastName}`,
  },
  {
    label: POOL_ACCOUNTS.UPDATED_ON,
    render: ({ updatedAt }) => dateAdapter(updatedAt).format(dateFormat),
    className: 'reset-width',
  },
];

const customerItemsTemplate = [
  {
    label: POOL_ACCOUNTS.PLATINUM_OZ,
    render: ({ platinumOz }) => formatToUS(platinumOz, 3),
  },
  {
    label: POOL_ACCOUNTS.PALLADIUM_OZ,
    render: ({ palladiumOz }) => formatToUS(palladiumOz, 3),
  },
  {
    label: POOL_ACCOUNTS.RHODIUM_OZ,
    render: ({ rhodiumOz }) => formatToUS(rhodiumOz, 3),
  },
  {
    label: POOL_ACCOUNTS.PT,
    render: ({ platinumValue }) => formatToDollarWithPrefix(platinumValue),
    className: 'left-border',
  },
  {
    label: POOL_ACCOUNTS.PD,
    render: ({ palladiumValue }) => formatToDollarWithPrefix(palladiumValue),
  },
  {
    label: POOL_ACCOUNTS.RH,
    render: ({ rhodiumValue }) => formatToDollarWithPrefix(rhodiumValue),
  },
  {
    label: POOL_ACCOUNTS.GRAND_TOTAL,
    render: ({ grandTotal }) => formatToDollarWithPrefix(grandTotal),
    className: 'PoolAccountsList-grandTotal',
  },
];

const itemsTemplateMobile = [
  {
    label: POOL_ACCOUNTS.COMPANY,
    render: ({ relatedCompany }) => relatedCompany.companyName,
  },
  {
    label: POOL_ACCOUNTS.GRAND_TOTAL,
    render: ({ grandTotal }) => formatToDollarWithPrefix(grandTotal),
  },
];

const customerItemsTemplateMobile = [
  {
    label: POOL_ACCOUNTS.GRAND_TOTAL,
    render: ({ grandTotal }) => formatToDollarWithPrefix(grandTotal),
  },
];

const itemsDetailsTemplate = [
  {
    label: POOL_ACCOUNTS.PLATINUM_OZ,
    render: ({ platinumOz }) => formatToUS(platinumOz, 3),
  },
  {
    label: POOL_ACCOUNTS.PALLADIUM_OZ,
    render: ({ palladiumOz }) => formatToUS(palladiumOz, 3),
  },
  {
    label: POOL_ACCOUNTS.RHODIUM_OZ,
    render: ({ rhodiumOz }) => formatToUS(rhodiumOz, 3),
  },
  {
    label: POOL_ACCOUNTS.PT,
    render: ({ platinumValue }) => formatToDollarWithPrefix(platinumValue),
    className: 'left-border',
  },
  {
    label: POOL_ACCOUNTS.PD,
    render: ({ palladiumValue }) => formatToDollarWithPrefix(palladiumValue),
  },
  {
    label: POOL_ACCOUNTS.RH,
    render: ({ rhodiumValue }) => formatToDollarWithPrefix(rhodiumValue),
  },
  {
    label: POOL_ACCOUNTS.UPDATED_BY,
    render: ({ updatedBy }) => `${updatedBy.firstName} ${updatedBy.lastName}`,
  },
  {
    label: POOL_ACCOUNTS.UPDATED_ON,
    render: ({ updatedAt }) => dateAdapter(updatedAt).format(dateFormat),
    className: 'reset-width',
  },
];

export {
  headerGroups,
  customerHeaderGroups,
  itemsTemplate,
  itemsTemplateMobile,
  itemsDetailsTemplate,
  customerItemsTemplate,
  customerItemsTemplateMobile,
};
