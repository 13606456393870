import { samplePackageEmptyFieldsAccess } from 'shared/helpers/accesses/packages/samplePackageAccess';
import { CREATE_PACKAGE } from 'shared/helpers/constants/packages/createPackageConstants';
import { CAPITAL_ALPHANUM_REGEX } from 'shared/helpers/validations/regularExpressions';

function validateSamplePackage(pkg, props) {
  const error = {};

  if (pkg.primaryId) {
    if (!CAPITAL_ALPHANUM_REGEX.test(pkg.primaryId)) {
      error.primaryId = CREATE_PACKAGE.INVALID_PRIMARY_ID_FORMAT;
    } else if (pkg.primaryId.length > 30) {
      error.primaryId = CREATE_PACKAGE.INVALID_PRIMARY_ID_LENGTH;
    }
  }
  if (!samplePackageEmptyFieldsAccess(props.userType)) {
    if (!pkg.primaryId) {
      error.primaryId = CREATE_PACKAGE.INVALID_PRIMARY_ID_EMPTY;
    }
  }

  if (pkg.secondaryId) {
    if (!CAPITAL_ALPHANUM_REGEX.test(pkg.secondaryId)) {
      error.secondaryId = CREATE_PACKAGE.INVALID_SECONDARY_ID_FORMAT;
    } else if (pkg.secondaryId.length > 30) {
      error.secondaryId = CREATE_PACKAGE.INVALID_SECONDARY_ID_LENGTH;
    }
  }

  return error;
}

function validate({ samplePackages, packages }, props) {
  const errors = {};

  if (!samplePackages?.contactPerson) {
    errors.contactPerson = CREATE_PACKAGE.INVALID_CONTACT_PERSON_EMPTY;
  }

  if (!samplePackages?.externalUser) {
    errors.externalUser = CREATE_PACKAGE.INVALID_USERNAME_EMPTY;
  } else if (samplePackages.externalUser.length > 100) {
    errors.externalUser = CREATE_PACKAGE.INVALID_CONTACT_PERSON_LENGTH;
  }

  if (!samplePackages?.portalUser?.value) {
    errors.portalUser = CREATE_PACKAGE.INVALID_USERNAME_EMPTY;
  }

  return {
    samplePackages: errors,
    packages: (packages || []).map(pkg => {
      return {
        samplePackages: (pkg?.samplePackages || []).map((pkg, index) =>
          validateSamplePackage(pkg, props, index),
        ),
      };
    }),
  };
}

export { validate };
