import UserDetailsReducer from './Details/userDetails';
import { changeOwnPasswordReducer } from './changeOwnPassword';
import { changePasswordUsingTokenReducer } from './changePasswordUsingToken';
import { changeUsersPasswordReducer } from './changeUsersPassword';
import CheckEmailReducer from './checkEmail';
import createUserReducer from './createUser';
import { deleteUserReducer } from './deleteUser';
import { inactivateUserReducer } from './inactivateUser';
import { inviteUserReducer } from './inviteUser';
import { updateNotificationPreferencesReducer } from './notificationPreferences';
import { requestPasswordResetReducer } from './requestPasswordReset';
import updateUserReducer from './updateUser';
import UserFiltersReducer from './userFilters';
import UsersListReducer from './usersList';

const usersRootReducer = {
  createUser: createUserReducer,
  updateUser: updateUserReducer,
  usersList: UsersListReducer,
  userDetails: UserDetailsReducer,
  userFilters: UserFiltersReducer,
  checkEmail: CheckEmailReducer,
  deleteUser: deleteUserReducer,
  inactivateUser: inactivateUserReducer,
  changePasswordUsingToken: changePasswordUsingTokenReducer,
  inviteUser: inviteUserReducer,
  requestPasswordReset: requestPasswordResetReducer,
  changeOwnPassword: changeOwnPasswordReducer,
  changeUsersPassword: changeUsersPasswordReducer,
  updatingNotificationPreferences: updateNotificationPreferencesReducer,
};

export { usersRootReducer };
