import { statusesForGlobal } from 'shared/helpers/constants/lots/statusesForGlobal';
import {
  markProcessingCompleteModal,
  markMixingCompleteModal,
} from 'shared/helpers/constants/modalConstants';
import {
  successOptions,
  dangerOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

function MarkProcessingComplete(props) {
  const isProcessingModal =
    props.lot.statusForGlobal === statusesForGlobal.accepted_for_processing
      ? markProcessingCompleteModal
      : markMixingCompleteModal;

  const markProcessingCompleteAction = () => {
    props.openModal(isProcessingModal, () => {
      props
        .markProcessingComplete(props.lot._id)
        .then(res => {
          props.getLotsForProcessingOverview();
          props.showSnackbar(successOptions, res.message);
          props.successCallback && props.successCallback();
        })
        .catch(err => {
          props.getLot(props.lot._id);
          props.showSnackbar(dangerOptions, err.message);
        });
    });
  };

  return props.children({ markProcessingComplete: markProcessingCompleteAction });
}

export { MarkProcessingComplete };
