import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { getHedgeDetails } from 'actions/Hedges/hedgesDetails';
import { filterLotsForReassign } from 'actions/Hedges/hedgesManagement';
import { hedgeReassign } from 'actions/Hedges/hedgesReassign';
import { openModal } from 'actions/shared/modal';
import { showSnackbar } from 'actions/shared/snackbar';

import { ModalCustom } from 'components/ModalCustom/ModalCustom';

import { message } from 'shared/helpers/constants/hedges/validateHedgesConstants';
import {
  successOptions,
  dangerOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

import { HedgeReassignForm } from './HedgeReassignForm';

function HedgeReassignBase(props) {
  const reassign = ({ relatedLot }) =>
    props
      .hedgeReassign(props.hedge, relatedLot.value, props.superAdminMode)
      .then(res => {
        props.showSnackbar(successOptions, res.message);
        props.toggle();
        return props.getHedgeDetails(props.hedge._id);
      })
      .catch(() => {
        props.showSnackbar(dangerOptions, message.HEDGES_UPDATE_ERROR);
        props.toggle();
      });

  return (
    <ModalCustom isOpen={props.open} modalClose={props.toggle}>
      <HedgeReassignForm
        onSubmit={reassign}
        hedge={props.hedge}
        filterLotsForReassign={props.filterLotsForReassign}
        superAdminMode={props.superAdminMode}
      />
    </ModalCustom>
  );
}

const mapDispatchToProps = {
  openModal,
  showSnackbar,
  hedgeReassign,
  getHedgeDetails,
  filterLotsForReassign,
};

HedgeReassignBase.propTypes = {
  superAdminMode: PropTypes.bool,
  open: PropTypes.bool,
  filterLotsForReassign: PropTypes.func,
  getHedgeDetails: PropTypes.func,
  hedge: PropTypes.object,
  hedgeReassign: PropTypes.func,
  openModal: PropTypes.func,
  showSnackbar: PropTypes.func,
  toggle: PropTypes.func,
};

const HedgeReassign = connect(null, mapDispatchToProps)(HedgeReassignBase);

export { HedgeReassign, HedgeReassignBase };
