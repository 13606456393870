import {
  releaseLotActionType,
  releaseLotSuccessActionType,
  releaseLotFailureActionType,
} from 'actions/Lots/releaseLot';

const INITIAL_STATE = {
  isPending: false,
};

const releaseLotReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case releaseLotActionType:
      return {
        isPending: true,
      };
    case releaseLotSuccessActionType:
    case releaseLotFailureActionType:
      return {
        isPending: false,
      };
    default:
      return state;
  }
};

export { releaseLotReducer };
