import { connect } from 'react-redux';

import { poolAccountBalanceStatement } from 'actions/PoolAccounts/poolAccountBalanceStatement';
import { showSnackbar } from 'actions/shared/snackbar';

import {
  successOptions,
  dangerOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

const PoolAccountBalanceStatementLayout = props => {
  const generateDocumentAction = () => {
    props
      .poolAccountBalanceStatement(props.companyId)
      .then(res => props.showSnackbar(successOptions, res.message))
      .catch(err => props.showSnackbar(dangerOptions, err.message));
  };

  return props.children({ generatePoolAccountBalanceStatement: generateDocumentAction });
};

const mapDispatchToProps = {
  showSnackbar,
  poolAccountBalanceStatement,
};

const PoolAccountBalanceStatement = connect(
  null,
  mapDispatchToProps,
)(PoolAccountBalanceStatementLayout);

export { PoolAccountBalanceStatement, PoolAccountBalanceStatementLayout };
