import React, { Component } from 'react';

import { FormContainer } from 'shared/components/Form/FormContainer/FormContainer';
import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';
import { normalizeLotLocationObject } from 'shared/helpers/constants/lots/normalizeLotLocation';
import { successOptions, infoOptions } from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

import { history, goBackOrTo } from 'utils/history';

import { LotLocationForm } from 'pages/Lots/components/LotsManagement/LotForm/LotLocationForm';

class UpdateLotLocation extends Component {
  componentDidMount() {
    this.props.getLot(this.props.match.params.id).catch(() => history.push('/lots'));
  }

  submit = values =>
    this.props
      .updateLotLocation({ ...values, id: this.props.lotDetails.lot._id })
      .then(res => {
        this.props.showSnackbar(successOptions, res.message);
        goBackOrTo(`/lots/list/${res.lot._id}`);
      })
      .catch(res => this.props.showSnackbar(infoOptions, res.message));

  render() {
    const { isPending } = this.props.lotLocationUpdate;
    const { lot } = this.props.lotDetails;

    return (
      <FormContainer
        header={LOTS.UPDATE_LOT_LOCATION}
        subHeader={lot && (lot.customLotNumber || lot.grgLotNumber)}
        loaded={!isPending && lot}
      >
        {() => (
          <LotLocationForm
            {...this.props}
            editMode
            updateLotLocation
            initialValues={normalizeLotLocationObject(lot)}
            onSubmit={this.submit}
          />
        )}
      </FormContainer>
    );
  }
}

export { UpdateLotLocation };
