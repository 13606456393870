import { hideSnackbarActionType, showSnackbarActionType } from 'actions/shared/snackbar';

const INITIAL_STATE = {
  snackbarOpened: false,
  snackbarOptions: null,
  snackbarMessage: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case showSnackbarActionType:
      return {
        ...state,
        snackbarOpened: true,
        snackbarOptions: action.payload.options,
        snackbarMessage: action.payload.message,
      };
    case hideSnackbarActionType:
      return {
        ...state,
        snackbarOpened: false,
        snackbarOptions: null,
        snackbarMessage: null,
      };
    default:
      return state;
  }
};
