import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { downloadAttachment } from 'actions/Attachments/downloadAttachment';

import { DetailsTabs } from 'shared/components/Details/DetailsTabs/DetailsTabs';
import { renderDetailsContainer } from 'shared/components/Details/helpers/renderDetailsContainer';

import { history } from 'utils/history';

import {
  lotDetailsInternalCompanyContainerConfig,
  lotDetailsInternalCompanyFieldsConfig,
} from '../config/fieldsConfig';
import { tabsInternalCustomerConfig } from '../config/tabsInternalCustomerConfig';

export const InternalCustomerView = ({ lot, auth }) => {
  const dispatch = useDispatch();

  const params = useParams();
  const handleDownloadAttachment = path => {
    dispatch(downloadAttachment(path));
  };

  return (
    <>
      {renderDetailsContainer(
        lotDetailsInternalCompanyContainerConfig,
        lotDetailsInternalCompanyFieldsConfig,
        lot,
        downloadAttachment,
        auth,
      )}
      <DetailsTabs
        tabsConfig={tabsInternalCustomerConfig(
          lot,
          auth,
          history,
          params,
          handleDownloadAttachment,
        )}
      />
    </>
  );
};

InternalCustomerView.propTypes = {
  auth: PropTypes.object,
  lot: PropTypes.object,
};
