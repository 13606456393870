import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import styled from 'styled-components';

import { Disclaimer } from 'shared/components/Disclaimer/Disclaimer';
import { FieldHint, FieldSearch } from 'shared/components/Fields';
import { FieldButtonSelect } from 'shared/components/Fields/FieldButtonSelect/FieldButtonSelect';
import { FieldDate } from 'shared/components/Fields/FieldDate/FieldDate';
import { FieldInput } from 'shared/components/Fields/FieldInput/FieldInput';
import { FieldSelect } from 'shared/components/Fields/FieldSelect/FieldSelect';
import { Form, Section } from 'shared/components/Form';
import { companyTypesEnum } from 'shared/helpers/constants/filters/companyTypes';
import { cancelModal } from 'shared/helpers/constants/modalConstants';
import { fundsTypes } from 'shared/helpers/constants/payments/fundsTypes';
import { paymentTypes as availablePaymentTypes } from 'shared/helpers/constants/payments/paymentTypes';
import { PAYMENTS } from 'shared/helpers/constants/payments/paymentsConstants';
import { dangerOptions } from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { parseLotsSelectData } from 'shared/helpers/dataParsers/lot/parseLotsSelectData';
import {
  getFinalPaymentDateMinDate,
  getBankHolidaysDates,
} from 'shared/helpers/dataParsers/payments/parseBankHolidaysData';
import { isAssayCompany, isGradingCompany } from 'shared/helpers/matchers/checkCompanyType';
import { isHedgeNotRequired } from 'shared/helpers/matchers/companies/checkExceptionType';
import { formatToDollar } from 'shared/helpers/parsers/formaters';
import { mapArray } from 'shared/helpers/parsers/select';
import { insertCommas, capitalize } from 'shared/helpers/parsers/text';
import { cssVar } from 'shared/helpers/styling/styling';
import { validate } from 'shared/helpers/validations/payments/enterPaymentValidation';

import { history } from 'utils/history';

const StyledFieldButtonSelect = styled(FieldButtonSelect)`
  width: calc(100% + 75px);
`;

class EnterPaymentFormLayout extends Component {
  state = {
    finalPaymentRequested: false,
    hasRequestedFinalPaymentDate: false,
  };

  componentWillUnmount() {
    if (!this.isEditingMode()) {
      this.props.clearAvailablePaymentTypes();
      this.props.clearPaymentAmount();
      this.props.clearAssignedLotsAction();
      this.props.clearSharedData('payments');
    }

    this.props.clearBankHolidays();
  }

  componentDidMount() {
    if (this.props.sharedData && !this.props.config?.editing) {
      this.companyOnChange(this.props.sharedData?.relatedCompany);
      this.props.change('relatedCompany', this.props.sharedData?.relatedCompany);
      this.lotOnChange(this.props.sharedData?.relatedLot);
      this.props.change('relatedLot', this.props.sharedData?.relatedLot);
      this.props.change('paymentType', this.props.sharedData?.paymentType);
    }

    if (this.props.config?.editing) {
      this.props.blur('requestedFinalPaymentDate');
    }
  }

  modalOpen = () => {
    this.props.openModal(cancelModal, () => history.push('/payments'));
  };

  isEditingMode = () => {
    return this.props.config?.editing;
  };

  listCompanies = input =>
    !input
      ? Promise.resolve({ options: [] })
      : this.props.filterEnterPaymentCompanies(input).then(({ options }) => ({
          options: options?.map(company => ({
            ...company,
            label: isGradingCompany(company.companyType)
              ? `${company.label} (grading)`
              : company.label,
          })),
        }));

  companyOnChange = company => {
    const { resetSection, change, clearAvailablePaymentTypes, getLotsForEnterPayment } = this.props;

    resetSection('fundsType');
    resetSection('relatedLot');
    resetSection('paymentType');
    resetSection('paymentAmount');
    clearAvailablePaymentTypes();
    company && getLotsForEnterPayment(company.value);

    change('balanceRemaining', company ? company.balanceRemaining : null);
  };

  lotOnChange = lot => {
    !this.props.sharedData?.paymentType && this.props.resetSection('paymentType');
    this.props.resetSection('paymentAmount');
    this.props.clearAvailablePaymentTypes();

    this.props.getBankHolidays({ year: '' });

    if (lot) {
      this.props.getPaymentAmount(lot.value).then(paymentAmount => {
        if (!this.props.relatedLot) {
          return;
        }
        if (paymentAmount.finalPaymentRequested) {
          this.setState({ finalPaymentRequested: true });
          this.props.showSnackbar(dangerOptions, PAYMENTS.FINAL_PAYMENT_ALREADY_REQUESTED);
        } else {
          this.setState({ finalPaymentRequested: false });
        }

        this.props.getAvailablePaymentTypes(lot.value).then(paymentTypes => {
          if (paymentTypes.length === 1) {
            const paymentType = { value: paymentTypes[0], label: capitalize(paymentTypes[0]) };
            this.props.change('paymentType', paymentType);
            this.paymentTypeOnChange(paymentType);
          }
        });
      });
    }
  };

  paymentTypeOnChange = paymentType => {
    const value = paymentType && paymentType.value;

    this.props.resetSection('paymentAmount');
    this.props.resetSection('requestedFinalPaymentDate');
    this.fillAvailableAmount(value);
    this.setRequestedFinalPaymentDate(null);
  };

  setRequestedFinalPaymentDate = value => {
    if (value !== null) {
      return this.setState({ hasRequestedFinalPaymentDate: true });
    }
    return this.setState({ hasRequestedFinalPaymentDate: false });
  };

  paymentAmountDisclaimer = () => {
    const {
      paymentType,
      paymentAmount: { data: paymentAmount },
      relatedCompany,
    } = this.props;
    return (
      paymentType &&
      paymentType.value === availablePaymentTypes.final &&
      paymentAmount &&
      paymentAmount.totalFinalPayment <= 0 &&
      relatedCompany &&
      isAssayCompany(relatedCompany.companyType)
    );
  };

  fillAvailableAmount = paymentType => {
    const {
      relatedCompany,
      paymentAmount: { data: paymentAmount },
      relatedLot,
    } = this.props;
    const companyType = relatedCompany && relatedCompany.companyType;

    if (paymentType === availablePaymentTypes.advance) {
      this.props.touch('paymentAmount');

      paymentAmount &&
        this.props.change('paymentAmount', insertCommas(paymentAmount.availableAmount.toString()));
    }

    if (this.disablePaymentAmountField({ paymentType, companyType, relatedLot })) {
      this.props.touch('paymentAmount');

      if (paymentAmount?.totalFinalPayment && paymentType) {
        this.props.change(
          'paymentAmount',
          insertCommas(paymentAmount.totalFinalPayment.toString()),
        );
      }
    }

    if (
      paymentType === availablePaymentTypes.final &&
      paymentAmount &&
      paymentAmount.finalPaymentRequested
    ) {
      this.setState({ finalPaymentRequested: true });
      this.props.showSnackbar(dangerOptions, PAYMENTS.FINAL_PAYMENT_ALREADY_REQUESTED);
    } else {
      this.setState({ finalPaymentRequested: false });
    }
  };

  disablePaymentAmountField = ({ paymentType, companyType, relatedLot }) =>
    !paymentType ||
    !relatedLot ||
    (paymentType === availablePaymentTypes.final && companyType === companyTypesEnum.assay);

  displayMaximumPaymentAmount = () => {
    const {
      paymentAmount: { data: paymentAmount },
      paymentType: formPaymentType,
    } = this.props;
    const paymentType = formPaymentType && formPaymentType.value;
    const availableForPaymentTypes = [availablePaymentTypes.advance];
    const exceptionTypes = paymentAmount?.paymentExceptionCompany?.exceptionTypes || [];

    return (
      !isHedgeNotRequired(exceptionTypes) &&
      paymentAmount &&
      paymentType &&
      availableForPaymentTypes.includes(paymentType)
    );
  };

  maximumPaymentAmountValue = () => {
    const {
      paymentAmount: { data: paymentAmount },
      paymentType: formPaymentType,
    } = this.props;

    const paymentType = formPaymentType && formPaymentType.value;

    return paymentType === availablePaymentTypes.final
      ? paymentAmount.remainingAmount
      : paymentAmount.availableAmount;
  };

  isRequestedFinalPaymentDateRequired = () => {
    const { paymentType: formPaymentType } = this.props;

    return [availablePaymentTypes.final, availablePaymentTypes.grading].includes(
      formPaymentType?.value,
    );
  };

  render() {
    const {
      handleSubmit,
      invalid,
      pristine,
      assignedLots,
      paymentType: formPaymentType,
      relatedCompany,
      availablePaymentTypes: paymentTypes,
      bankHolidaysList: { bankHolidays },
      relatedLot,
    } = this.props;
    const paymentType = formPaymentType?.value;
    const companyType = relatedCompany?.companyType;
    const balanceRemaining = relatedCompany?.balanceRemaining;

    return (
      <Form
        header={PAYMENTS.PAYMENT}
        onSubmit={handleSubmit}
        onCancel={this.modalOpen}
        submitDisabled={
          (this.isRequestedFinalPaymentDateRequired(formPaymentType?.value) &&
            !this.state.hasRequestedFinalPaymentDate &&
            !this.isEditingMode()) ||
          invalid ||
          pristine ||
          (this.state.finalPaymentRequested && paymentType === availablePaymentTypes.final)
        }
      >
        <Section
          template={[
            'relatedCompany relatedLot',
            'paymentType balanceRemaining',
            `fundsType ${
              this.isRequestedFinalPaymentDateRequired() ? 'requestedFinalPaymentDate' : 'fundsType'
            }`,
            'paymentAmount additionalInfo',
            'paymentAmountDisclaimer paymentAmountDisclaimer',
            'paymentAmountDisabledDisclaimer paymentAmountDisabledDisclaimer',
          ]}
        >
          {!this.isEditingMode() && (
            <Field
              name="relatedCompany"
              component={FieldSearch}
              label={PAYMENTS.RELATED_COMPANY}
              field="required"
              getOptions={this.listCompanies}
              onChange={this.companyOnChange}
              disabled={this.isEditingMode()}
            />
          )}
          {!this.isEditingMode() && (
            <Field
              name="relatedLot"
              component={FieldSelect}
              options={parseLotsSelectData(assignedLots.data)}
              disabled={this.isEditingMode() || !companyType}
              label={PAYMENTS.RELATED_LOT}
              field="required"
              onChange={this.lotOnChange}
            />
          )}
          {!this.isEditingMode() && (
            <Field
              name="paymentType"
              component={FieldSelect}
              options={mapArray(paymentTypes.data)}
              disabled={this.isEditingMode() || !paymentTypes.data.length}
              label={PAYMENTS.PAYMENT_TYPE}
              field="required"
              onChange={this.paymentTypeOnChange}
            />
          )}
          {balanceRemaining < 0 && (
            <Field
              name="balanceRemaining"
              data-testid="balanceRemaining"
              component={FieldInput}
              label={PAYMENTS.BALANCE_REMAINING}
              color={cssVar('solidRed')}
              prefix="$"
              format={formatToDollar}
              disabled
            />
          )}
          <Field
            name="fundsType"
            component={StyledFieldButtonSelect}
            options={mapArray(Object.values(fundsTypes))}
            disabled={!companyType && !this.isEditingMode()}
            label={PAYMENTS.FUNDS_TYPE}
            field="required"
          />
          {this.isRequestedFinalPaymentDateRequired() && (
            <Field
              name="requestedFinalPaymentDate"
              data-testid="requestedFinalPaymentDate"
              component={FieldDate}
              minDate={getFinalPaymentDateMinDate()}
              excludeDates={getBankHolidaysDates(bankHolidays)}
              label={PAYMENTS.REQUESTED_FINAL_PAYMENT_DATE}
              field="required"
              onChange={value => this.setRequestedFinalPaymentDate(value)}
              onlyWeekdays
            />
          )}
          <Field
            name="paymentAmount"
            data-testid="paymentAmount"
            component={FieldHint}
            disabled={
              this.disablePaymentAmountField({ paymentType, companyType, relatedLot }) &&
              !this.isEditingMode()
            }
            label={PAYMENTS.PAYMENT_AMOUNT}
            field="required"
            type="text"
            prefix="$"
            hint={PAYMENTS.MAXIMUM}
            suggestion={
              this.displayMaximumPaymentAmount() &&
              insertCommas(this.maximumPaymentAmountValue().toString())
            }
          />
          <Field
            name="additionalInfo"
            component={FieldInput}
            label={PAYMENTS.ADDITIONAL_INFORMATION}
          />
          {this.paymentAmountDisclaimer() && (
            <Disclaimer name="paymentAmountDisabledDisclaimer" color="solidRed">
              {PAYMENTS.PAYMENT_AMOUNT_DISABLED}
            </Disclaimer>
          )}
        </Section>
      </Form>
    );
  }
}

const selector = formValueSelector('EnterPaymentForm');

const mapStateToProps = state => ({
  paymentType: selector(state, 'paymentType'),
  relatedCompany: selector(state, 'relatedCompany'),
  paymentAmountValue: selector(state, 'paymentAmount'),
  relatedLot: selector(state, 'relatedLot'),
  sharedData: state.sharedData.payments,
  bankHolidaysList: state.bankHolidaysList,
});

const EnterPaymentForm = compose(
  connect(mapStateToProps),
  reduxForm({
    form: 'EnterPaymentForm',
    validate,
  }),
)(EnterPaymentFormLayout);

export { EnterPaymentForm, EnterPaymentFormLayout };
