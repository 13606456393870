import PropTypes from 'prop-types';
import React from 'react';

import { isTablet } from 'shared/helpers/constants/resolutionsConstants';

import { getBrowserWidth } from 'utils/responsive';

import { withFieldWrapper } from '../FieldWrapper/FieldWrapper';
import { InputWrapper, Input } from './StyledFieldInput';

const setFieldType = propsType => {
  if (propsType === 'number') return getBrowserWidth() < isTablet ? propsType : 'text';
  return propsType;
};

const FieldInput = withFieldWrapper(props => (
  <InputWrapper
    prefix={props.prefix}
    suffix={props.suffix}
    prefixSize={props.prefixSize}
    suffixSize={props.suffixSize}
    show={props.meta && (props.meta.active || props.input.value)}
  >
    <Input
      {...props}
      data-testid={props.name}
      type={setFieldType(props.type)}
      autoComplete="off"
      isUpperCase={props.isUpperCase}
      onChange={props.onChange}
    />
  </InputWrapper>
));

InputWrapper.propTypes = {
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  fontSize: PropTypes.number,
};

InputWrapper.defaultProps = {
  prefix: '',
  suffix: '',
  fontSize: 14,
};

FieldInput.propTypes = {
  height: PropTypes.number,
  prefixSize: PropTypes.number,
  suffixSize: PropTypes.number,
  padding: PropTypes.number,
  pretendMobile: PropTypes.bool,
  color: PropTypes.string,
  isUpperCase: PropTypes.bool,
};

FieldInput.defaultProps = {
  height: 50,
  prefixSize: 26,
  suffixSize: 60,
  padding: 0,
  pretendMobile: false,
  isUpperCase: false,
};

export { FieldInput, InputWrapper };
