import styled from 'styled-components';

import { cssVar } from 'shared/helpers/styling/styling';

const InlineError = styled.div.attrs(({ error }) => ({
  children: error,
}))`
  display: flex;
  justify-content: center;
  color: ${cssVar('roman')};
  padding-bottom: 20px;
`;

export { InlineError };
