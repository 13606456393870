import {
  getCommoditiesOverviewActionType,
  getCommoditiesOverviewSuccessActionType,
  getCommoditiesOverviewFailureActionType,
  clearCommoditiesOverviewListActionType,
} from 'actions/Commodities/commoditiesOverviewList';

const INITIAL_STATE = {
  isPending: false,
  items: null,
};

const commoditiesOverviewListReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getCommoditiesOverviewActionType:
      return {
        ...state,
        isPending: true,
      };
    case getCommoditiesOverviewSuccessActionType:
      return {
        isPending: false,
        items: action.payload.data,
      };
    case getCommoditiesOverviewFailureActionType:
      return {
        ...state,
        isPending: false,
      };
    case clearCommoditiesOverviewListActionType:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};

export { commoditiesOverviewListReducer };
