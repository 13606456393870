import React from 'react';

import { StatusIndicator } from 'shared/components/StatusIndicator/StatusIndicator';
import { humanize } from 'shared/helpers/parsers/text';

import { PackagesSelectActions } from 'pages/Packages/components/OverviewList/components/PackagesSelectActions/PackagesSelectActions';
import {
  packageContents,
  packageNumberLabel,
} from 'pages/Packages/components/OverviewList/helpers';

import { PACKAGES } from '../packages/packageConstants';

const itemsTemplate = [
  {
    label: PACKAGES.SELECT,
    render: pkg => <PackagesSelectActions pkg={pkg} />,
  },
  {
    label: PACKAGES.STATUS,
    render: ({ status }) => <StatusIndicator status={status} />,
  },
  {
    label: PACKAGES.GRG_LOT_NUMBER,
    render: ({ assignedLot: { customLotNumber, grgLotNumber } }) => grgLotNumber,
  },
  {
    label: PACKAGES.PACKAGE_NUMBER,
    render: pkg => `${PACKAGES.PACKAGE_COLON} ${packageNumberLabel(pkg)}`,
  },
  {
    label: PACKAGES.PACKAGE_TYPE,
    render: ({ packageType }) => humanize(packageType),
  },
  {
    label: PACKAGES.MATERIAL_TYPE_DECLARED,
    render: ({ materialTypeDeclared }) => humanize(materialTypeDeclared),
  },
  {
    label: PACKAGES.CONTENTS,
    render: pkg => packageContents(pkg),
  },
];

const itemsTemplateMobile = [
  {
    label: PACKAGES.SELECT,
    render: pkg => <PackagesSelectActions pkg={pkg} />,
  },
  {
    label: PACKAGES.STATUS,
    render: ({ status }) => <StatusIndicator status={status} />,
  },
  {
    label: PACKAGES.GRG_LOT_NUMBER,
    render: ({ grgLotNumber, assignedLot }) => grgLotNumber || assignedLot?.grgLotNumber,
  },
];

itemsTemplate.onClickAction = function onClick(pkg) {
  const packages = this.props.shipmentPackagesListData.packages;
  if (packages.includes(pkg._id)) {
    const newPackages = packages.filter(id => id !== pkg._id);
    this.props.shipmentPackagesList(newPackages);
  } else {
    this.props.shipmentPackagesList([...packages, pkg._id]);
  }
};

const [, , , ...itemsDetailsTemplate] = itemsTemplate;

const actionsTemplate = [];

export { itemsTemplate, itemsDetailsTemplate, itemsTemplateMobile, actionsTemplate };
