import { isWest } from 'utils/versioning/Guards';

import { hedgeStatusOverlay } from '../constants/hedges/hedgeStatus';
import { dateWithShortTimeFormat } from '../parsers/date';
import { formatDate } from '../parsers/formaters';

export const isTooltipVisible = (requestedHedgeDate, currentStatus) =>
  requestedHedgeDate &&
  currentStatus === hedgeStatusOverlay.scheduled &&
  `${formatDate(requestedHedgeDate, dateWithShortTimeFormat)} ${isWest ? 'PT' : 'ET'}`;
