const updateWarehouseDataActionType = 'UPDATE_WAREHOUSE_DATA';
const updateWarehouseDataAction = newVirtualLotData => ({
  type: updateWarehouseDataActionType,
  payload: newVirtualLotData,
});

const updateWarehouseData = newVirtualLotData => dispatch => {
  dispatch(updateWarehouseDataAction(newVirtualLotData));
};

export { updateWarehouseData, updateWarehouseDataActionType, updateWarehouseDataAction };
