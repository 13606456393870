import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { shipmentPackagesList } from 'actions/Shipments/shipmentPackagesList';

import { Checkbox } from 'shared/components/Fields/FieldCheckbox/FieldCheckbox';
import { SHIPMENTS } from 'shared/helpers/constants/shipments/shipmentsConstants';
import { parsePackageLabels } from 'shared/helpers/dataParsers/packages/parsePackageLabels';
import { cssVar, media, pxToRem } from 'shared/helpers/styling/styling';

const SelectAllPackagesLabelsStyled = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${media.mobile`
    flex-direction: column;
    align-items: flex-start;
  `}
`;

const NoteStyled = styled.span`
  display: block;
  color: ${cssVar('solidYellow')};
  font-size: ${pxToRem(14)};

  ${media.mobile`
    margin-top: ${pxToRem(20)};
  `}
`;

const SelectAllPackagesLabels = ({ chosenPackages, packages, changedShipmentDestination }) => {
  const dispatch = useDispatch();

  const checkSelectedPackages = () => chosenPackages?.length === packages?.length;

  const removeAllPackages = () => dispatch(shipmentPackagesList([]));

  const addAllPackages = () => dispatch(shipmentPackagesList(packages.map(parsePackageLabels)));

  const onCheckboxChange = ({ currentTarget }) =>
    currentTarget.checked ? addAllPackages() : removeAllPackages();

  return (
    <SelectAllPackagesLabelsStyled>
      <Checkbox
        id="selectAllPackagesLabels"
        checked={checkSelectedPackages()}
        onChange={onCheckboxChange}
        label={SHIPMENTS.SELECT_ALL_PACKAGES}
      />
      {changedShipmentDestination && (
        <NoteStyled>{SHIPMENTS.REPRINT_ALL_PACKAGES_LABELS}</NoteStyled>
      )}
    </SelectAllPackagesLabelsStyled>
  );
};

SelectAllPackagesLabels.propTypes = {
  chosenPackages: PropTypes.array,
  packages: PropTypes.array,
  changedShipmentDestination: PropTypes.bool,
};

export { SelectAllPackagesLabels };
