import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import { markAsRead } from 'actions/Notifications/markAsRead';
import { markAsResolved } from 'actions/Notifications/markAsResolved';
import {
  getNotification,
  clearNotificationDetails,
} from 'actions/Notifications/notificationDetails';
import { showSnackbar } from 'actions/shared/snackbar';

import { NotificationDialogBase } from './NotificationDialogBase';

const mapStateToProps = ({
  auth,
  hedgesDetails,
  lotDetails,
  markAsResolved: markAsResolvedState,
  notificationDetails,
  paymentDetails,
  selfDeliveryRequestDetails,
  termsDetails,
}) => ({
  auth,
  hedgesDetails,
  lotDetails,
  markAsResolvedState,
  notificationDetails,
  paymentDetails,
  selfDeliveryRequestDetails,
  termsDetails,
});

const mapDispatchToProps = {
  getNotification,
  clearNotificationDetails,
  markAsResolved,
  markAsRead,
  showSnackbar,
};

const NotificationDialog = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(NotificationDialogBase);

export { NotificationDialog };
