import {
  checkSubmittedPoolPaymentsActionType,
  checkSubmittedPoolPaymentsSuccessActionType,
  checkSubmittedPoolPaymentsFailureActionType,
  clearSubmittedPoolPaymentsActionType,
} from 'actions/PoolAccounts/checkSubmittedPoolPayments';

const INITIAL_STATE = {
  isPending: false,
  wasPoolPaymentsSubmitted: null,
};

const checkSubmittedPoolPaymentsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case checkSubmittedPoolPaymentsActionType:
      return {
        isPending: true,
      };
    case checkSubmittedPoolPaymentsSuccessActionType:
      return {
        isPending: false,
        wasPoolPaymentsSubmitted: action.payload.wasPoolPaymentsSubmitted,
      };
    case checkSubmittedPoolPaymentsFailureActionType:
      return {
        isPending: false,
        wasPoolPaymentsSubmitted: null,
      };
    case clearSubmittedPoolPaymentsActionType:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};

export { checkSubmittedPoolPaymentsReducer };
