import { PAYMENTS } from 'shared/helpers/constants/payments/paymentsConstants';
import { isBeforeOrDuringMarketHours } from 'shared/helpers/matchers/marketDays/isBeforeOrDuringMarketHours';

import { getFinalPaymentDateMinDate } from './parseBankHolidaysData';

const calculateMinimalFinalPaymentDelay = (checkMarketHours = true) =>
  getFinalPaymentDateMinDate(
    checkMarketHours &&
      (isBeforeOrDuringMarketHours()
        ? PAYMENTS.FINAL_PAYMENT_DELAY_AFTER_MARKET_HOURS
        : PAYMENTS.FINAL_PAYMENT_DELAY_BEFORE_OR_DURING_MARKET_HOURS),
  );

export { calculateMinimalFinalPaymentDelay };
