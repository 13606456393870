import {
  updateSpecialPermissionsActionType,
  updateSpecialPermissionsSuccessActionType,
  updateSpecialPermissionsFailureActionType,
} from 'actions/SpecialPermissions/updateSpecialPermissions';

const INITIAL_STATE = {
  isPending: false,
};

export const UpdateSpecialPermissions = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case updateSpecialPermissionsActionType:
      return {
        isPending: true,
      };
    case updateSpecialPermissionsSuccessActionType:
      return {
        isPending: false,
      };
    case updateSpecialPermissionsFailureActionType:
      return {
        isPending: false,
      };
    default:
      return state;
  }
};
