const message = {
  INVALID_RELATED_COMPANY_EMPTY: 'Related company is required.',
  INVALID_PAYOUT_RULE_EMPTY: 'Payout rule is required.',
  PAYOUT_RULE_CREATE_SUCCESS: 'Payout rule has been successfully added.',
  PAYOUT_RULE_CREATE_ERROR: 'There was an error during creating payout rule for company.',
  PAYOUT_RULE_DELETE_SUCCESS: 'Payout rule has been successfully deleted.',
  PAYOUT_RULE_DELETE_ERROR: 'There was an error during delete.',
};

export { message };
