import { message } from 'shared/helpers/constants/users/createUserConstants';
import { EMAIL_REGEX } from 'shared/helpers/validations/regularExpressions';

function validate({ email }) {
  let emailErrors = null;

  if (!email || !email.trim()) {
    emailErrors = message.INVALID_EMAIL_EMPTY;
  } else if (!EMAIL_REGEX.test(email)) {
    emailErrors = message.INVALID_EMAIL;
  } else if (email.length > 100) {
    emailErrors = message.INVALID_EMAIL;
  }

  return {
    email: emailErrors,
  };
}

export { validate };
