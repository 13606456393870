import React from 'react';
import { Field } from 'redux-form';

import { FieldButtonSelect } from 'shared/components/Fields/FieldButtonSelect/FieldButtonSelect';
import { USER } from 'shared/helpers/constants/users/userConstants';
import { generateName } from 'shared/helpers/forms/generateName';

function AccessLevel({ options, formName }) {
  return (
    <Field
      name={generateName(formName, 'accessLevel')}
      component={FieldButtonSelect}
      label={USER.ACCESS_LEVEL}
      options={options}
      field="required"
    />
  );
}

export { AccessLevel };
