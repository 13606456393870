import React, { useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import styled from 'styled-components';

import { downloadAttachment } from 'actions/Attachments/downloadAttachment';
import { getForwardRecipients, clearForwardRecipients } from 'actions/Files/getForwardRecipients';
import { showSnackbar } from 'actions/shared/snackbar';

import { SubmitButton } from 'shared/components/Buttons/SubmitButton/SubmitButton';
import { DetailsDownloadField } from 'shared/components/Details/DetailsDownloadFieldRedesign/DetailsDownloadField';
import { FieldSelect } from 'shared/components/Fields/FieldSelect/FieldSelect';
import { FieldTextarea } from 'shared/components/Fields/FieldTextarea/FieldTextarea';
import { Spinner } from 'shared/components/Spinner/Spinner';
import { FILES_CONSTANTS } from 'shared/helpers/constants/filesConstants';
import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';
import { dangerOptions } from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { cssVar } from 'shared/helpers/styling/styling';
import { validate } from 'shared/helpers/validations/files/forwardDocumentValidation';

const Container = styled.div`
  margin-bottom: 20px;
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const form = 'ForwardDocumentForm';
const selector = formValueSelector(form);

function ForwardDocumentContentBase({
  handleSubmit,
  invalid,
  fileName,
  downloadable,
  file: { _id: fileId, path },
}) {
  const dispatch = useDispatch();
  const { recipients } = useSelector(state => state.forwardRecipients);
  const { isPending } = useSelector(state => state.forwardDocument);
  const portalUsers = useSelector(state => selector(state, 'portalUsers'));
  const externalEmails = useSelector(state => selector(state, 'externalEmails'));

  useEffect(() => {
    dispatch(getForwardRecipients(fileId)).catch(error =>
      dispatch(showSnackbar(dangerOptions, error.message)),
    );

    return () => dispatch(clearForwardRecipients());
  }, [dispatch, fileId]);

  const internalUsers = useMemo(
    () =>
      recipients
        ? recipients.map(({ _id, firstName, lastName }) => ({
            label: `${firstName} ${lastName}`,
            value: _id,
          }))
        : [],
    [recipients],
  );

  const isFilled = useMemo(
    () => (portalUsers && !!portalUsers.length) || (externalEmails && externalEmails.length),
    [portalUsers, externalEmails],
  );

  const formatEmails = useCallback(emails => (emails || '').replace(/;(?!\n)(.)/g, ';\n$1'), []);
  const download = useCallback(() => dispatch(downloadAttachment(path)), [dispatch, path]);

  return (
    <Container>
      <h1>{FILES_CONSTANTS.FORWARD_DOCUMENT}</h1>
      {downloadable && (
        <DetailsDownloadField
          value={path}
          fileId={fileId}
          label={LOTS.FINAL_INVOICE_MOCKUP}
          backgroundColor={cssVar('outerSpace')}
          onClick={download}
          id="draftFile"
        />
      )}
      <form onSubmit={handleSubmit}>
        <Field
          name="portalUsers"
          component={FieldSelect}
          label={FILES_CONSTANTS.PORTAL_USERS}
          options={internalUsers}
          maxMenuHeight={235}
          multi
          wrap
        />
        <Field
          name="externalEmails"
          component={FieldTextarea}
          label={FILES_CONSTANTS.EXTERNAL_EMAILS}
          format={formatEmails}
        />
        <Actions>
          <Spinner loading={isPending} />
          <SubmitButton disabled={invalid || !isFilled || isPending} icon="icon-share">
            {FILES_CONSTANTS.FORWARD}
          </SubmitButton>
        </Actions>
      </form>
    </Container>
  );
}

const ForwardDocumentContent = reduxForm({
  form,
  validate,
})(ForwardDocumentContentBase);

export { ForwardDocumentContentBase, ForwardDocumentContent };
