import axios from 'axios';

import { clearRecipients } from './recipientsList';

const API_HOST_AUTH = process.env.REACT_APP_API;

const deleteRecipientActionType = 'DELETE_RECIPIENT';
function deleteRecipientAction() {
  return { type: deleteRecipientActionType };
}

const deleteRecipientSuccessActionType = `${deleteRecipientActionType}_SUCCESS`;
function deleteRecipientSuccessAction() {
  return { type: deleteRecipientSuccessActionType };
}

const deleteRecipientFailureActionType = `${deleteRecipientActionType}_FAILURE`;
function deleteRecipientFailureAction() {
  return { type: deleteRecipientFailureActionType };
}

function deleteRecipient(recipient) {
  return dispatch => {
    dispatch(deleteRecipientAction());
    dispatch(clearRecipients());

    return axios
      .delete(`${API_HOST_AUTH}/hedges-recipients/${recipient._id}`)
      .then(() => dispatch(deleteRecipientSuccessAction()))
      .catch(error => {
        dispatch(deleteRecipientFailureAction());

        return Promise.reject(error);
      });
  };
}

export {
  deleteRecipient,
  deleteRecipientActionType,
  deleteRecipientSuccessActionType,
  deleteRecipientFailureActionType,
};
