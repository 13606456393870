import React from 'react';

import { PackagesSelectActions } from 'pages/Packages/components/OverviewList/components/PackagesSelectActions/PackagesSelectActions';
import {
  packageContents,
  packageNumberLabel,
} from 'pages/Packages/components/OverviewList/helpers';

import { PACKAGES } from './packageConstants';

const itemsTemplate = [
  {
    label: PACKAGES.SELECT,
    render: (pkg, index, templateProps) => (
      <PackagesSelectActions
        pkg={{
          ...pkg,
          ...(templateProps?.isPackingList && {
            _id: pkg.newPackageNumber?.toString() || pkg.packageNumber?.toString(),
          }),
        }}
      />
    ),
  },
  {
    label: PACKAGES.PKG_NUMBER,
    render: (pkg, _, templateProps) =>
      templateProps?.isPackingList
        ? pkg?.newPackageNumber || pkg?.packageNumber
        : packageNumberLabel(pkg),
  },
  {
    label: PACKAGES.CONTENTS,
    render: pkg => packageContents(pkg),
  },
];

itemsTemplate.onClickAction = function onClick(pkg) {
  this.checkElement((pkg?.newPackageNumber || pkg.packageNumber).toString());
};

const actionsTemplate = [];

export { actionsTemplate, itemsTemplate };
