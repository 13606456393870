const message = {
  EMAIL_EMPTY: 'Email is required.',
  EMAIL_FORMAT: 'Email has invalid format.',
  EMAIL_UNIQUE: 'There is already recipient with given email.',

  RECIPIENTS_CREATE_SUCCESS: 'Recipient has been added successfully.',
  RECIPIENTS_DELETE_SUCCESS: 'Recipient has been deleted successfully.',
  RECIPIENTS_DELETE_ERROR: 'There was an error during removal of recipient.',
};

export { message };
