const setInitialLocationFiltersActionType = 'LOCATION_FILTERS_SET_INITIAL';
function setInitialLocationFiltersAction() {
  return {
    type: setInitialLocationFiltersActionType,
  };
}

const locationStatusFilterActionType = 'LOCATION_STATUS_ACTION';
function locationStatusFilterAction(locationStatus) {
  return {
    type: locationStatusFilterActionType,
    payload: {
      locationStatus,
    },
  };
}

const locationFiltersClearActionType = 'LOCATION_FILTERS_CLEAR_ACTION';
function locationFiltersClearAction() {
  return {
    type: locationFiltersClearActionType,
  };
}

function setInitialLocationFilters() {
  return dispatch => {
    dispatch(setInitialLocationFiltersAction());
  };
}

function locationStatusFilter(locationStatus) {
  return dispatch => {
    dispatch(locationStatusFilterAction(locationStatus));
  };
}

function locationFiltersClear() {
  return dispatch => {
    dispatch(locationFiltersClearAction());
  };
}

export {
  locationFiltersClearActionType,
  locationStatusFilterActionType,
  setInitialLocationFiltersActionType,
  locationStatusFilter,
  locationFiltersClear,
  setInitialLocationFilters,
};
