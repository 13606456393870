import PropTypes from 'prop-types';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field } from 'redux-form';

import {
  companyAddFile,
  companyRemoveExistingFile,
  companyRemoveFile,
} from 'actions/Companies/companyAddFile';

import { FieldDate } from 'shared/components/Fields';
import { FieldUpload } from 'shared/components/Fields/FiledUpload/FieldUpload';
import { FieldUploadList } from 'shared/components/Fields/FiledUpload/FieldUploadList';
import { Section } from 'shared/components/Form';
import { COMPANY } from 'shared/helpers/constants/companies/companyConstants';
import { DILIGENCE_VALID_FILE_EXTENSIONS } from 'shared/helpers/constants/companies/diligenceValidFileExtensions';
import { FILES_CONSTANTS } from 'shared/helpers/constants/filesConstants';
import { isGradingCompany } from 'shared/helpers/matchers/checkCompanyType';

import { isWest } from 'utils/versioning/Guards';

const DiligenceSection = ({ companyFiles: { files, existingFiles }, companyDetails, ...props }) => {
  const dispatch = useDispatch();
  const { details } = useSelector(({ auth }) => auth.access.companies.details.dueDiligence);

  const handleInputFileChange = e => {
    dispatch(props.change(`${e.target.name}ExpiresAt`, ''));
    return dispatch(companyAddFile(e.target));
  };

  const handleRemoveFileByIndex = (fileType, fileIndex) => {
    return dispatch(companyRemoveFile(fileType, fileIndex));
  };

  const handleRemoveFileById = (fileType, fileId) => {
    return dispatch(companyRemoveExistingFile({ fileType, fileId }));
  };

  const renderFile = fileType => {
    const uploadedFiles = [...files[fileType], ...existingFiles[fileType]].map(
      (file, fileIndex) => ({
        file,
        ...(details.remove && {
          onDelete: () =>
            file._id
              ? handleRemoveFileById(fileType, file._id)
              : handleRemoveFileByIndex(fileType, fileIndex),
        }),
      }),
    );

    return <FieldUploadList files={uploadedFiles.reverse()} allowDeleteExistingFiles />;
  };

  const validExtensions = [
    FILES_CONSTANTS.VALID_EXTENSIONS.pdf,
    FILES_CONSTANTS.VALID_EXTENSIONS.jpg,
    FILES_CONSTANTS.VALID_EXTENSIONS.jpeg,
    FILES_CONSTANTS.VALID_EXTENSIONS.png,
  ].join(', ');

  return (
    <Section
      header={COMPANY.DUE_DILIGENCE}
      template={[
        'photoId photoIdExpiresAt',
        'documentForAML documentForAMLExpiresAt',
        'businessLicense businessLicenseExpiresAt',
        'materialSourcingAgreement .',
        'locationVerification .',
        'dueDiligenceQuestionnaire .',
      ]}
    >
      <div name="photoId">
        <Field
          name="photoId"
          component={FieldUpload}
          label={COMPANY.PHOTO_ID}
          validExtensions={validExtensions}
          onChange={handleInputFileChange}
        />
        {renderFile('photoId')}
      </div>
      {(files.photoId?.length > 0 || existingFiles.photoId?.length) && (
        <Field
          name="photoIdExpiresAt"
          component={FieldDate}
          label={COMPANY.EXPIRES}
          field="required"
          disabled={!files.photoId?.length}
        />
      )}
      <div name="documentForAML">
        <Field
          name="documentForAML"
          component={FieldUpload}
          label={COMPANY.AML_CHECK}
          validExtensions={validExtensions}
          onChange={handleInputFileChange}
        />
        {renderFile('documentForAML')}
      </div>
      {(files.documentForAML?.length > 0 || existingFiles.documentForAML?.length > 0) && (
        <Field
          name="documentForAMLExpiresAt"
          component={FieldDate}
          label={COMPANY.EXPIRES}
          field="required"
          disabled={!files.documentForAML?.length}
        />
      )}
      <div name="businessLicense">
        <Field
          name="businessLicense"
          component={FieldUpload}
          label={COMPANY.BUSINESS_LICENSE}
          validExtensions={validExtensions}
          onChange={handleInputFileChange}
        />
        {renderFile('businessLicense')}
      </div>
      {(files.businessLicense?.length > 0 || existingFiles.businessLicense?.length > 0) && (
        <Field
          name="businessLicenseExpiresAt"
          component={FieldDate}
          label={COMPANY.EXPIRES}
          field="required"
          disabled={!files.businessLicense?.length}
        />
      )}
      <div name="materialSourcingAgreement">
        <Field
          name="materialSourcingAgreement"
          component={FieldUpload}
          label={
            isWest && isGradingCompany(companyDetails.companyType)
              ? COMPANY.MATERIAL_AGREEMENT_WEST
              : COMPANY.MATERIAL_AGREEMENT
          }
          validExtensions={validExtensions}
          onChange={handleInputFileChange}
        />
        {renderFile('materialSourcingAgreement')}
      </div>
      <div name="locationVerification">
        <Field
          name="locationVerification"
          component={FieldUpload}
          label={COMPANY.LOCATION_VERIFICATION}
          validExtensions={DILIGENCE_VALID_FILE_EXTENSIONS}
          onChange={handleInputFileChange}
        />
        {renderFile('locationVerification')}
      </div>
      <div name="dueDiligenceQuestionnaire">
        <Field
          name="dueDiligenceQuestionnaire"
          component={FieldUpload}
          label={COMPANY.DUE_DILIGENCE_QUESTIONNAIRE}
          validExtensions={DILIGENCE_VALID_FILE_EXTENSIONS}
          onChange={handleInputFileChange}
        />
        {renderFile('dueDiligenceQuestionnaire')}
      </div>
    </Section>
  );
};

DiligenceSection.propTypes = {
  files: PropTypes.shape({
    photoId: PropTypes.array,
    documentForAML: PropTypes.array,
    businessLicense: PropTypes.array,
    materialSourcingAgreement: PropTypes.array,
  }).isRequired,
};

DiligenceSection.defaultProps = {
  files: {},
};

export { DiligenceSection };
