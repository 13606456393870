const {
  getGeneralInfoType,
  getGeneralInfoSuccessActionType,
  getGeneralInfoFailureActionType,
} = require('actions/GeneralInfo/generalInfoList');

const INITIAL_STATE = {
  isPending: false,
};

const getGeneralInfoReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getGeneralInfoType:
      return {
        isPending: true,
      };
    case getGeneralInfoSuccessActionType:
      return { isPending: false, generalInfo: action.payload.generalInfo };
    case getGeneralInfoFailureActionType:
      return { isPending: false };
    default:
      return state;
  }
};

export { getGeneralInfoReducer };
