import styled, { keyframes } from 'styled-components';

import { theme } from '../common/theme';

const spin = keyframes`
  0% {transform: rotate(0deg);}
  100% {transform: rotate(360deg);}
`;

export const StyledLoadingSpinner = styled.span`
  display: inline-block;
  width: 40px;
  height: 40px;
  padding: 7px;

  h2 > & {
    width: 30px;
    height: 30px;
    padding: 0 7px;
  }

  &:after {
    content: ' ';
    display: inline-block;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 2px solid var(--atlantis);
    border-color: var(--atlantis) transparent var(--atlantis) transparent;
    animation: ${spin} 0.8s linear infinite;
  }
`;

StyledLoadingSpinner.defaultProps = {
  theme,
};
