import { setDesignation } from 'shared/helpers/dataParsers/lot/setDesignation';
import { isInternalCompany } from 'shared/helpers/matchers/checkCompanyType';
import { formatDate, formatToUS } from 'shared/helpers/parsers/formaters';
import { capitalizeGlobal, humanize, replaceEmpty } from 'shared/helpers/parsers/text';

import { LOTS } from './lotsConstants';

const itemsTemplateGlobal = isBuyer => [
  {
    label: LOTS.GRG_LOT_NUMBER,
    render: ({ grgLotNumber, customLotNumber, designation }) => {
      const lotName = setDesignation(grgLotNumber, designation);

      return (!customLotNumber && lotName) || [lotName, customLotNumber];
    },
    highlight: true,
    includeRegExp: true,
  },
  ...(!isBuyer
    ? [
        {
          label: LOTS.SHORTENED_STILLWATER_SHIPMENT_ID,
          render: ({ stillwaterShipmentId }) => replaceEmpty(stillwaterShipmentId),
          highlight: true,
        },
      ]
    : []),
  {
    label: LOTS.STATUS_FOR_GLOBAL,
    render: ({ statusForGlobal }) => capitalizeGlobal(humanize(statusForGlobal)),
  },
  {
    label: LOTS.MATERIAL_TYPE,
    render: ({ materialType }) => replaceEmpty(humanize(materialType)),
  },
  {
    label: LOTS.SHORTENED_SENT_FROM_COMPANY,
    render: ({ sentFromCompany }) => sentFromCompany.companyName,
    highlight: true,
  },
  {
    label: LOTS.SHORTENED_SENT_FROM_COMPANY_LOCATION,
    render: ({ sentFromCompanyLocation }) => sentFromCompanyLocation.name,
    highlight: true,
  },
  {
    label: `${LOTS.SHORTENED_WEIGHT_GROSS_DECLARED} (${LOTS.LBS})`,
    render: ({ weightGrossActual }) => replaceEmpty(formatToUS(weightGrossActual)),
  },
  {
    label: LOTS.HEDGED_STATUS,
    render: ({ hedgedStatus }) => humanize(hedgedStatus),
  },
  {
    label: LOTS.RECEIVED_ON,
    render: ({ receivedAt, packagesNotReceivedSameDay }) =>
      `${replaceEmpty(formatDate(receivedAt))} ${packagesNotReceivedSameDay ? '*' : ''}`,
  },
];

const itemsTemplateCustomer = companyType => {
  return [
    {
      label: LOTS.STATUS,
      ...(isInternalCompany(companyType)
        ? { render: ({ statusForGlobal }) => capitalizeGlobal(humanize(statusForGlobal)) }
        : { render: ({ statusForCustomer }) => capitalizeGlobal(humanize(statusForCustomer)) }),
    },
    {
      label: LOTS.GRG_LOT_NUMBER,
      render: ({ grgLotNumber, customLotNumber }) =>
        (!customLotNumber && grgLotNumber) || [grgLotNumber, customLotNumber],
      highlight: true,
      includeRegExp: true,
    },
    {
      label: LOTS.SHORTENED_SENT_FROM_COMPANY_LOCATION,
      render: ({ sentFromCompanyLocation }) => sentFromCompanyLocation.name,
    },
    {
      label: LOTS.PROCESS_UPON_DELIVERY,
      render: ({ processUponDelivery }) => {
        if (processUponDelivery) {
          return 'Yes';
        }
        return 'No';
      },
    },
    {
      label: `${LOTS.SHORTENED_WEIGHT_GROSS_DECLARED} (${LOTS.LBS})`,
      render: ({ weightGrossDeclared }) => replaceEmpty(formatToUS(weightGrossDeclared)),
    },
    {
      label: LOTS.HEDGED_STATUS,
      render: ({ hedgedStatus }) => humanize(hedgedStatus),
    },
    {
      label: LOTS.RECEIVED_ON,
      render: ({ receivedAt }) => replaceEmpty(formatDate(receivedAt)),
    },
  ];
};

const itemsTemplateGrading = [
  {
    label: LOTS.STATUS_FOR_CUSTOMER,
    render: ({ statusForCustomer }) => humanize(statusForCustomer),
  },
  {
    label: LOTS.GRG_LOT_NUMBER,
    render: ({ grgLotNumber, customLotNumber }) =>
      (!customLotNumber && grgLotNumber) || [grgLotNumber, customLotNumber],
    highlight: true,
    includeRegExp: true,
  },
  {
    label: LOTS.SHORTENED_SENT_FROM_COMPANY_LOCATION,
    render: ({ sentFromCompanyLocation }) => sentFromCompanyLocation.name,
  },
  {
    label: `${LOTS.SHORTENED_WEIGHT_GROSS_DECLARED} (${LOTS.LBS})`,
    render: ({ weightGrossDeclared }) => replaceEmpty(formatToUS(weightGrossDeclared)),
  },
  {
    label: LOTS.RECEIVED_ON,
    render: ({ receivedAt }) => replaceEmpty(formatDate(receivedAt)),
  },
];

const itemsTemplateMobileGlobal = [
  {
    label: LOTS.GRG_LOT_NUMBER,
    render: ({ grgLotNumber, customLotNumber, designation }) => {
      const lotName = setDesignation(grgLotNumber, designation);

      return (!customLotNumber && lotName) || [lotName, customLotNumber];
    },
    highlight: true,
    includeRegExp: true,
  },
  {
    label: LOTS.STATUS_FOR_GLOBAL,
    render: ({ statusForGlobal }) => humanize(statusForGlobal),
  },
  {
    label: LOTS.SHORTENED_SENT_FROM_COMPANY_LOCATION,
    render: ({ sentFromCompanyLocation }) => sentFromCompanyLocation.name,
  },
];

const itemsTemplateMobileCustomer = [
  {
    label: LOTS.STATUS_FOR_CUSTOMER,
    render: ({ statusForCustomer }) => capitalizeGlobal(humanize(statusForCustomer)),
  },
  {
    label: LOTS.GRG_LOT_NUMBER,
    render: ({ grgLotNumber, customLotNumber }) =>
      (!customLotNumber && grgLotNumber) || [grgLotNumber, customLotNumber],
    highlight: true,
    includeRegExp: true,
  },
  {
    label: LOTS.SHORTENED_SENT_FROM_COMPANY_LOCATION,
    render: ({ sentFromCompanyLocation }) => sentFromCompanyLocation.name,
  },
];

const itemsDetailsTemplateGlobal = isBuyer => [
  ...(!isBuyer
    ? [
        {
          label: LOTS.SHORTENED_STILLWATER_SHIPMENT_ID,
          render: ({ stillwaterShipmentId }) => replaceEmpty(stillwaterShipmentId),
        },
      ]
    : []),
  {
    label: LOTS.MATERIAL_TYPE,
    render: ({ materialType }) => replaceEmpty(humanize(materialType)),
  },
  {
    label: LOTS.SHORTENED_SENT_FROM_COMPANY,
    render: ({ sentFromCompany }) => sentFromCompany.companyName,
    highlight: true,
  },
  {
    label: `${LOTS.SHORTENED_WEIGHT_GROSS_DECLARED} (${LOTS.LBS})`,
    render: ({ weightGrossActual }) => replaceEmpty(formatToUS(weightGrossActual)),
  },
  {
    label: LOTS.HEDGED_STATUS,
    render: ({ hedgedStatus }) => humanize(hedgedStatus),
  },
  {
    label: LOTS.RECEIVED_ON,
    render: ({ receivedAt }) => replaceEmpty(formatDate(receivedAt)),
  },
];

const [, , , ...itemsDetailsTemplateCustomer] = itemsTemplateCustomer();
const [, , , ...itemsDetailsTemplateGrading] = itemsTemplateGrading;

export {
  itemsTemplateGlobal,
  itemsTemplateCustomer,
  itemsTemplateGrading,
  itemsDetailsTemplateGlobal,
  itemsDetailsTemplateCustomer,
  itemsDetailsTemplateGrading,
  itemsTemplateMobileGlobal,
  itemsTemplateMobileCustomer,
};
