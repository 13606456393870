import CompanyDetailsAgreementsListReducer from './Details/agreementsList';
import CompanyDetailsLocationsListReducer from './Details/locationsList';
import CompanyDetailsTermsListReducer from './Details/termsList';
import CompanyDetailsUsersListReducer from './Details/usersList';
import CheckLotPrefixReducer from './checkLotPrefix';
import CompaniesListReducer from './companiesList';
import CompanyDetailsReducer from './companyDetails';
import { companyFilesReducer } from './companyFiles';
import CompanyFiltersReducer from './companyFilters';
import CreateCompanyReducer from './createCompany';
import FilterCompaniesReducer from './filterCompanies';
import { generateReportReducer } from './generateReport';
import { getBlanketHedgeCountReducer } from './getBlanketHedgeCount';
import { GetCompaniesForFiltersReducer } from './getCompaniesForFilters';
import { InactivateCompanyReducer } from './inactivateCompany';
import PrivilegedCompaniesListReducer from './privilegedCompaniesList';
import { reactivateCompanyReducer } from './reactivateCompany';
import UpdateCompanyReducer from './updateCompany';

const companiesRootReducer = {
  checkCompany: CheckLotPrefixReducer,
  companiesList: CompaniesListReducer,
  companyDetails: CompanyDetailsReducer,
  companyDetailsAgreementsList: CompanyDetailsAgreementsListReducer,
  companyDetailsLocationsList: CompanyDetailsLocationsListReducer,
  companyDetailsTermsList: CompanyDetailsTermsListReducer,
  companyDetailsUsersList: CompanyDetailsUsersListReducer,
  companyFilters: CompanyFiltersReducer,
  createCompany: CreateCompanyReducer,
  filteredCompanies: FilterCompaniesReducer,
  companiesForFilters: GetCompaniesForFiltersReducer,
  updateCompany: UpdateCompanyReducer,
  privilegedCompaniesList: PrivilegedCompaniesListReducer,
  inactivateCompany: InactivateCompanyReducer,
  getBlanketHedgeCount: getBlanketHedgeCountReducer,
  generateReport: generateReportReducer,
  reactivateCompany: reactivateCompanyReducer,
  companyFiles: companyFilesReducer,
};

export { companiesRootReducer };
