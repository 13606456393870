import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import Select, { components } from 'react-select';
import styled from 'styled-components';

import { isTablet } from 'shared/helpers/constants/resolutionsConstants';
import { humanize } from 'shared/helpers/parsers/text';
import { cssVar, themed, isTouchScreenDevice, scrollbar } from 'shared/helpers/styling/styling';
import { useWindowWidth } from 'shared/hooks/useWindowWidth';

import { searchByAdditionalValue } from 'utils/searchByAdditionalValue';

import { withFieldWrapper } from '../FieldWrapper/FieldWrapper';
import { FIELD_SELECT } from './constants';

const styles = {
  container: provided => ({
    ...provided,
    color: cssVar('alabaster'),
  }),
  menu: provided => ({
    ...provided,
    color: cssVar('alabaster'),
    backgroundColor: cssVar('shuttleGray'),
    boxShadow: '0 11px 24px 0 rgba(0,0,0,0.29)',
    borderRadius: 5,
  }),
  control: (provided, { isDisabled }) => ({
    ...provided,
    backgroundColor: cssVar(isDisabled ? 'limedSpruceDarker' : 'shuttleGray'),
    boxShadow: 'none',
    padding: '0 10px 0 22px',
    minHeight: 50,
    borderRadius: 5,
    border: 'none',
    ':hover': {
      cursor: 'pointer',
      backgroundColor: cssVar('nevada'),
    },
  }),
  option: (provided, state) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    minHeight: 46,
    paddingLeft: 24,
    fontSize: '0.875rem',
    color: cssVar('alto'),
    wordBreak: 'break-word',
    backgroundColor: cssVar(state.isSelected || state.isFocused ? 'nevada' : 'shuttleGray'),
    'span:first-of-type': {
      maxWidth: '100%',
    },
    ':not(:last-child)': {
      borderBottom: '1px solid rgba(151, 151, 151, 0.2)',
    },
    ':hover, :active': {
      color: cssVar('whiteGRG'),
      cursor: 'pointer',
      backgroundColor: cssVar('nevada'),
    },
  }),
  placeholder: provided => ({
    ...provided,
    color: cssVar('alto'),
    fontSize: '0.75rem',
    opacity: 0.56,
    position: 'absolute',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    padding: 0,
    maxWidth: state.selectProps.isMobile
      ? '50vw'
      : state.selectProps.customFieldWidth
      ? state.selectProps.customFieldWidth
      : '75%',
    display: 'flex',
    flexWrap: state.selectProps?.wrap ? 'wrap' : 'nowrap',
  }),
  singleValue: provided => ({
    ...provided,
    color: cssVar('alabaster'),
    fontSize: '0.875rem',
  }),
  input: provided => ({
    ...provided,
    color: cssVar('alabaster'),
    fontSize: '0.875rem',
    maxWidth: '190px',
    paddingTop: '3px',
    paddingBottom: '3px',
    'input:not(:focus)': {
      width: 0,
      height: 0,
    },
  }),
  multiValue: (provided, { data, selectProps }) => ({
    ...provided,
    minHeight: 32,
    display: 'flex',
    alignItems: 'center',
    backgroundColor:
      selectProps.highlightAvailableFields && data.isUserActive
        ? cssVar('seaGreenGRG')
        : cssVar('slateGrayGRG'),
    maxWidth: 'calc(100% - 10px)',
    borderRadius: 6,
    '+ div:last-child': {
      lineHeight: 0,
      padding: 0,
      margin: 0,
    },
    '+ div:last-child input:not(:focus)': {
      width: 0,
      height: 0,
    },
  }),
  multiValueLabel: (provided, { selectProps }) => ({
    ...provided,
    padding: '8px 6px',
    paddingLeft: 12,
    color: cssVar('alabaster'),
    fontSize: '0.875rem',
    fontHeight: 16,
    paddingRight: selectProps.allowEmptyArray ? 0 : selectProps.valueLength > 1 ? 0 : 12,
  }),
  multiValueRemove: (provided, { selectProps }) => ({
    ...provided,
    color: cssVar('alabaster'),
    minHeight: 20,
    minWidth: 20,
    maxHeight: 20,
    maxWidth: 20,
    marginRight: 8,
    padding: 0,
    display: selectProps.allowEmptyArray ? 'flex' : selectProps.valueLength > 1 ? 'flex' : 'none',
    ':hover': {
      color: cssVar('alabaster'),
      backgroundColor: cssVar('nevada'),
    },
    svg: {
      height: '100%',
      width: '100%',
    },
  }),
  indicatorsContainer: provided => ({
    ...provided,
    svg: {
      color: cssVar('whiteGRG'),
      opacity: 0.7,
      ':hover': {
        opacity: 1,
      },
    },
  }),
  clearIndicator: provided => ({
    ...provided,
    padding: '5px 0',
  }),
  dropdownIndicator: (provided, { isDisabled }) => ({
    ...provided,
    padding: '5px 2px',
    visibility: isDisabled ? 'hidden' : 'visible',
    height: 'auto',
    width: 'auto',
    svg: {
      height: 30,
      width: 30,
    },
  }),
  indicatorSeparator: provided => ({
    ...provided,
    display: 'none',
  }),
};

const normalizeValue = value => {
  if (!value) return null;

  return typeof value === 'string' && value ? { label: humanize(value), value } : value;
};

const MenuList = styled(components.MenuList)`
  ${scrollbar}
`;

const FieldSelect = withFieldWrapper(
  ({
    input,
    label,
    field,
    disabled,
    allowEmptyArray,
    clearable,
    multi,
    meta,
    blurOnChange,
    searchBy,
    ...rest
  }) => {
    const windowWidth = useWindowWidth();
    const [initialized, setInitialized] = useState(false);
    const [inputValues, setInputValues] = useState();
    const selectRef = useRef();

    const onChange = useCallback(
      (value, { action, removedValues }) => {
        if (action === 'clear' && !allowEmptyArray) {
          value = [removedValues[0]];
        }
        input.onChange(value);
        isTouchScreenDevice() && input.onBlur(value);
        !allowEmptyArray && setInputValues([value]);
      },
      [input],
    );

    const onBlur = useCallback(() => !isTouchScreenDevice() && input.onBlur(input.value), [input]);

    const customFilterOption = useCallback(
      (option, rawInput) =>
        option.label.toLowerCase().includes(rawInput.toLowerCase()) ||
        option.data?.grgLotNumber?.toLowerCase().includes(rawInput.toLowerCase()) ||
        (searchBy && searchBy.some(item => searchByAdditionalValue(item, option.data, rawInput))),

      [],
    );

    const noOptionsMessage = useCallback(() => FIELD_SELECT.NO_OPTION_MESSAGE, []);

    const { options } = rest;
    const { onChange: inputOnChange, value } = input;
    const clearableAvailable = allowEmptyArray
      ? clearable === undefined || clearable
      : value.length > 1;

    useEffect(() => {
      if (options && options.length === 1 && !multi && !value && !initialized && !disabled) {
        inputOnChange(options[0]);
        setInitialized(true);
      }
      setInputValues(input.value);
    }, [options, inputOnChange, multi, value, initialized, disabled]);

    useEffect(() => {
      if (disabled) {
        selectRef.current.setState({ menuIsOpen: false });
      }
    }, [disabled]);

    return (
      <Select
        {...rest}
        {...input}
        id={input.name}
        ref={selectRef}
        onChange={onChange}
        onBlur={onBlur}
        components={{
          MenuList,
        }}
        value={normalizeValue(inputValues)}
        hasError={meta.touched && meta.error}
        styles={styles}
        isDisabled={disabled}
        isSearchable={!isTouchScreenDevice()}
        filterOption={customFilterOption}
        isClearable={clearableAvailable}
        isMulti={multi}
        noOptionsMessage={noOptionsMessage}
        isMobile={windowWidth < isTablet}
        allowEmptyArray={allowEmptyArray}
        valueLength={value.length}
      />
    );
  },
);

const FieldSelectStyled = styled(FieldSelect).attrs(themed(({ disabled }) => ({ disabled })))``;

FieldSelect.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onDragStart: PropTypes.func,
    onDrop: PropTypes.func,
    onFocus: PropTypes.func,
    value: PropTypes.oneOfType([
      PropTypes.objectOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.string,
        }),
      ),
      PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.string,
        }),
      ),
    ]),
  }),
  disabled: PropTypes.bool,
  allowEmptyArray: PropTypes.bool,
  clearable: PropTypes.bool,
  multi: PropTypes.bool,
  meta: PropTypes.object,
};

FieldSelect.defaultProps = {
  allowEmptyArray: true,
  searchBy: false,
  customFieldWidth: false,
};

export { styles, MenuList, FieldSelectStyled as FieldSelect };
