import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import { deleteVirtualLot } from 'actions/Lots/deleteVirtualLot';
import { getWarehouseData } from 'actions/Lots/getWarehouseDashboardData';
import { holdLot } from 'actions/Lots/holdLot';
import { holdVirtualLot } from 'actions/Lots/holdVirtualLot';
import { releaseLot } from 'actions/Lots/releaseLot';
import { openModal } from 'actions/shared/modal';
import { showSnackbar } from 'actions/shared/snackbar';

import { Button } from 'shared/components/Buttons/Button';
import { GroupActionButton } from 'shared/components/Buttons/GroupButton/components/GroupActionButton';
import { Icon } from 'shared/components/Icon/Icon';
import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';
import { deleteLotModal } from 'shared/helpers/constants/modalConstants';
import {
  successOptions,
  dangerOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import {
  COLORS,
  COUNTING_REQUIRED_SECTIONS,
  SECTIONS,
} from 'shared/helpers/constants/warehouseDashboard/labels';
import { setDesignation } from 'shared/helpers/dataParsers/lot/setDesignation';
import { isSupportUser } from 'shared/helpers/matchers/checkUserType';
import { cssVar, pxToRem, media } from 'shared/helpers/styling/styling';

import { MomentAdapter } from 'utils/date/momentAdapter';
import { history } from 'utils/history';

import { LotColor } from './components/LotColor/LotColor';
import { ProcessingNote } from './components/ProcessingNote/ProcessingNote';

const Title = styled.h2`
  margin: ${({ margin }) => margin || 0};
  font-size: ${pxToRem(13)};
  width: 100%;
  ${media.smallDesktop`
    font-size: ${pxToRem(12)};
  `}
`;

const Subtitle = styled.h3`
  margin: ${({ margin }) => margin || '4px 0 0'};
  color: ${cssVar('whiteGRG')};
  font-size: ${pxToRem(12)};
  opacity: ${({ opacity }) => opacity || '50%'};
`;

const LotWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: ${pxToRem(12)};
  color: ${cssVar('whiteGRG')};
  background: ${({ bg }) =>
    bg && bg !== COLORS.GRAY.name.toUpperCase() ? COLORS[bg].color : cssVar('fiord')};
  padding: 15px;
  width: 100%;
  border-radius: 8px;
  margin: 0 0 15px;
  position: relative;

  ${media.smallDesktop`
    padding: 10px;
  `}
`;

const VirtualLotWrapper = styled(LotWrapper)`
  background: ${cssVar('outerSpaceTabs')};
  border: 3px solid
    ${({ bg }) =>
      bg && bg !== COLORS.GRAY.name.toUpperCase() ? COLORS[bg].color : cssVar('outerSpaceTabs')};
  cursor: ${({ isDragDisabled }) => (isDragDisabled ? 'not-allowed' : 'grab')};
  ${({ isDragging }) =>
    isDragging &&
    css`
      margin: 0;
      position: static;
      opacity: 0.95;
      border: 3px solid ${cssVar('rockBlue')};
    `}
`;

const DelayLabel = styled.div`
  position: absolute;
  top: -10px;
  transform: translate(-50%, 0);
  left: 50%;
  width: auto;
  height: 20px;
  min-width: 65px;
  border-radius: 8px;
  padding: 2px 10px;
  background: ${cssVar('nevada')};
  font-size: ${pxToRem(10)};
  color: ${cssVar('whiteGRG')};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
`;
const WeightDiscrepancyLabel = styled(DelayLabel)`
  min-width: 90px;
`;

const Options = styled.div`
  width: calc(100% + 30px);
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 48px;
  padding: 10px 15px;
  margin: -16px 0 15px -15px;
  border-bottom: 1px solid ${cssVar('riverBedDarker')};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background: ${({ bg }) =>
    bg !== COLORS.GRAY.name.toUpperCase() ? COLORS[bg]?.color : cssVar('outerSpaceTabs')};
  ${media.smallDesktop`
    padding: 10px;
    margin: -12px 0 15px -10px;
    width: calc(100% + 20px);
  `}

  ${({ standardLot }) =>
    standardLot &&
    css`
      justify-content: flex-end;
      border-bottom: none;
      padding: 0px 17px;
      margin-bottom: 0;
      min-height: 40px;
    `}
`;
const Section = styled.div`
  width: calc(100% + 30px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 48px;
  background: ${({ color }) => (color ? 'rgba(0,0,0,0.1)' : 'rgba(255,255,255,0.05)')};
  padding: 10px 15px;
  margin-left: -15px;

  ${media.smallDesktop`
    padding: 10px;
    margin-left: -10px;
    width: calc(100% + 20px);
  `}
`;

const NoteOptions = styled(Options)`
  background-color: ${({ bgColor }) => bgColor || 'transparent'};
  margin: 0 0 -15px -15px;
  border-radius: 0 0 8px 8px;
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ noBorder }) =>
    !noBorder &&
    css`
      border-top: 1px solid ${cssVar('riverBedDarker')};
    `};
  ${media.smallDesktop`
    padding: 0 6px;
    margin: 0 0 -15px -10px;
  `}
`;

const Lot = ({
  _id,
  delayed,
  grgLotNumber,
  designation,
  currentColor,
  customLotNumber,
  receivedAt,
  dragProvided,
  dragSnapshot,
  processingNotes,
  createProcessingNote,
  weightDiscrepancyLbs,
  subsection,
  columnName,
}) => {
  const dispatch = useDispatch();
  const { userType } = useSelector(({ auth: { user } }) => user);
  const { isPending: holdLotPending } = useSelector(({ holdLot }) => holdLot);
  const { isPending: releaseLotPending } = useSelector(({ releaseLot }) => releaseLot);
  const { isPending: warehousePending } = useSelector(
    ({ warehouseDashboardData }) => warehouseDashboardData,
  );

  const handleHoldLot = lotId => {
    dispatch(holdLot(lotId)).finally(() => dispatch(getWarehouseData()));
  };

  const handleReleaseLot = lotId => {
    dispatch(releaseLot(lotId)).finally(() => dispatch(getWarehouseData()));
  };

  return (
    <LotWrapper
      data-testid={`lot_${_id}`}
      bg={currentColor && currentColor.toUpperCase()}
      ref={dragProvided.innerRef}
      {...dragProvided.draggableProps}
      {...dragProvided.dragHandleProps}
      {...dragSnapshot}
    >
      {!isSupportUser(userType)
        ? columnName !== SECTIONS.INCOMING.name && (
            <Options standardLot>
              <GroupActionButton smallGroup standardLot>
                {subsection === COUNTING_REQUIRED_SECTIONS.HOLD.id ? (
                  <Button
                    disabled={warehousePending || releaseLotPending}
                    onClick={() => handleReleaseLot(_id)}
                  >
                    {LOTS.RELEASE}
                  </Button>
                ) : (
                  <Button
                    disabled={warehousePending || holdLotPending}
                    onClick={() => handleHoldLot(_id)}
                  >
                    {LOTS.HOLD}
                  </Button>
                )}
              </GroupActionButton>
            </Options>
          )
        : null}

      {delayed && <DelayLabel>{LOTS.DELAYED}</DelayLabel>}
      {weightDiscrepancyLbs && <WeightDiscrepancyLabel>{LOTS.MISSING_LBS}</WeightDiscrepancyLabel>}
      <Subtitle opacity="36%">{LOTS.GRG_LOT}</Subtitle>
      <Title margin="5px 0 10px">{setDesignation(grgLotNumber, designation) || 'N/A'}</Title>
      <Subtitle opacity="36%">{LOTS.CLIENT_LOT}</Subtitle>
      <Title margin="5px 0 10px">{customLotNumber || 'N/A'}</Title>
      {receivedAt && (
        <Section color={currentColor}>
          <Subtitle opacity="36%">{LOTS.RECEIVED_PACKAGES}</Subtitle>
          <Title margin="5px 0 10px">{MomentAdapter.formatLocal(receivedAt, 'MM-DD-YYYY')}</Title>
        </Section>
      )}

      <NoteOptions bgColor={cssVar('nevada')} noBorder>
        <ProcessingNote notesCount={processingNotes.length} onClick={createProcessingNote(_id)} />
        <LotColor lotId={_id} currentColor={currentColor} />
      </NoteOptions>
    </LotWrapper>
  );
};

const VirtualLot = ({
  _id,
  name,
  weight,
  index,
  date,
  currentColor,
  isDragDisabled,
  isDragging,
  dragProvided,
  dragSnapshot,
  processingNotes,
  createProcessingNote,
  subsection,
}) => {
  const dispatch = useDispatch();
  const { userType } = useSelector(({ auth: { user } }) => user);

  const handleHoldVirtualLot = lotId => {
    dispatch(holdVirtualLot(lotId)).finally(() => dispatch(getWarehouseData()));
  };

  const deleteVirtualLotFn = id =>
    dispatch(
      openModal(deleteLotModal, () => {
        dispatch(deleteVirtualLot(id))
          .then(result => {
            dispatch(getWarehouseData());
            dispatch(showSnackbar(successOptions, result.message));
          })
          .catch(err => dispatch(showSnackbar(dangerOptions, err.message)));
      }),
    );

  return (
    <VirtualLotWrapper
      data-testid={`lot_${_id}_${index}`}
      ref={dragProvided.innerRef}
      isDragDisabled={isDragDisabled}
      isDragging={isDragging}
      bg={currentColor && currentColor.toUpperCase()}
      {...dragProvided.draggableProps}
      {...dragProvided.dragHandleProps}
      {...dragSnapshot}
    >
      <Options bg={currentColor && currentColor.toUpperCase()}>
        <Icon icon="icon-drag" width={28} height={28} />
        <GroupActionButton smallGroup={true}>
          {!isSupportUser(userType)
            ? subsection !== COUNTING_REQUIRED_SECTIONS.HOLD.id && (
                <Button padding={'0 15px'} onClick={() => handleHoldVirtualLot(_id)}>
                  {LOTS.HOLD}
                </Button>
              )
            : null}
          <Button
            padding={'0 15px'}
            onClick={() =>
              history.push({
                pathname: `/lots/update-virtual-lot/${_id}`,
              })
            }
          >
            {LOTS.UPDATE_LOT}
          </Button>
          <Button color="danger" padding={'0 15px'} onClick={() => deleteVirtualLotFn(_id)}>
            {LOTS.DELETE_LOT}
          </Button>
        </GroupActionButton>
      </Options>
      <Subtitle opacity="36%">{LOTS.NAME}</Subtitle>
      <Title margin="5px 0 10px">{name}</Title>
      <Subtitle opacity="36%">{LOTS.DATE}</Subtitle>
      <Title margin="5px 0 10px">
        {(date && MomentAdapter.formatLocal(date, 'MM-DD-YYYY')) || 'N/A'}
      </Title>
      {weight && (
        <>
          <Subtitle opacity="36%">{LOTS.WEIGHT}</Subtitle>
          <Title margin="5px 0 10px">{weight}</Title>
        </>
      )}
      <NoteOptions>
        <ProcessingNote
          notesCount={processingNotes.length}
          onClick={createProcessingNote(_id, true)}
        />
        <LotColor lotId={_id} currentColor={currentColor} isVirtual={true} />
      </NoteOptions>
    </VirtualLotWrapper>
  );
};

Lot.propType = {
  _id: PropTypes.string,
  delayed: PropTypes.bool,
  subsection: PropTypes.string,
  section: PropTypes.string,
  grgLotNumber: PropTypes.string,
  customLotNumber: PropTypes.string,
  receivedAt: PropTypes.number,
  processingNotes: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      createdAt: PropTypes.number,
      createdBy: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
      }),
      note: PropTypes.string,
    }),
  ),
  createProcessingNote: PropTypes.func,
};

Lot.defaultProps = {
  processingNotes: [],
};

VirtualLot.propType = {
  _id: PropTypes.string,
  name: PropTypes.string,
  date: PropTypes.number,
  subsection: PropTypes.string,
  createProcessingNote: PropTypes.func,
};

VirtualLot.defaultProps = {
  processingNotes: [],
};

export { Lot, VirtualLot, Section };
