import * as React from 'react';
import { useSelector } from 'react-redux';

import { isCustomerUser } from 'shared/helpers/matchers/checkUserType';

import { CustomerList } from './CustomerList';
import { GlobalList } from './GlobalList';

const ReportsList = () => {
  const auth = useSelector(({ auth }) => auth);
  const isCustomer = isCustomerUser(auth.user.userType);

  return <>{isCustomer ? <CustomerList /> : <GlobalList />}</>;
};
export { ReportsList };
