import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { cssVar, pxToRem } from 'shared/helpers/styling/styling';

const calculateActionColumn = ({ equalColumns, processing, logs }) => {
  if (equalColumns) {
    return 0;
  }

  if (processing) {
    return '110px';
  }

  if (logs) {
    return '150px';
  }

  return '100px';
};

const OverviewTableRow = styled.div`
  display: grid;
  @media print {
    break-inside: avoid;
  }
  max-height: ${({ limitMaximumHeight }) => (limitMaximumHeight ? pxToRem(89) : 'none')};
  ${({ gridTemplate }) => css`
    grid-template-areas: '${gridTemplate}';
  `};

  ${({ mobile, itemsNumber, processing, logs, mobileDetails, equalColumns }) =>
    mobile
      ? css`
          grid-template-columns: repeat(${itemsNumber || 1}, 1fr) ${mobileDetails ? 0 : 50}px;
        `
      : css`
          grid-template-columns: repeat(${itemsNumber || 1}, 1fr) ${calculateActionColumn({
              equalColumns,
              processing,
              logs,
            })};
          padding-left: ${({ head, equalColumns }) => (equalColumns && !head ? 0 : '15px')};
        `};
  align-items: ${({ detailsList }) => (detailsList ? 'flex-start' : 'center')};
  justify-content: center;
  background-color: ${({ head, isOpen, customColor }) => {
    if (customColor) {
      return cssVar(customColor);
    }
    return head ? cssVar('shark') : cssVar(isOpen ? 'cuttySarkTable' : 'fiord');
  }};

  font-size: 0.8rem;
  font-weight: 600;
  min-height: ${({ head }) => (head ? pxToRem(55) : pxToRem(89))};
  color: ${cssVar('gullGray')};

  ${({ head, mobile, isOpen, detailsList, customColor }) =>
    !head &&
    css`
      ${(!mobile || (mobile && !isOpen)) &&
      css`
        border-bottom: 2px solid ${cssVar('nevada')};

        @media print {
          border-bottom: none;
        }
      `}

      ${mobile &&
      isOpen &&
      css`
        border-bottom: 2px solid transparent;
      `}

      ${!mobile &&
      !detailsList &&
      !customColor &&
      css`
        &:hover {
          background-color: ${cssVar('cuttySarkTable')};
        }
      `}
    `}
  ${({ isOpen, header }) =>
    !isOpen &&
    !header &&
    css`
      display: none;
    `};

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      opacity: 0.5;
    `};

  ${({ isOpen, mobileDetails }) =>
    isOpen &&
    mobileDetails &&
    css`
      border-bottom-color: ${cssVar('outerSpaceBrighter')};
    `};

  ${({ isClickable }) =>
    isClickable &&
    css`
      cursor: pointer;
    `};
  ${({ additionalClass, isOpen, overviewList }) =>
    additionalClass &&
    !isOpen &&
    additionalClass.length &&
    !overviewList &&
    `background-color: ${cssVar('nevadaDarker')};`}

  ${({ item, itemFilter, isOpen, mobile }) =>
    itemFilter && ((!isOpen && mobile) || !mobile) && itemFilter(item)};

  ${({ item }) =>
    item?.edited &&
    css`
      border: 1px solid ${cssVar('atlantis')};
    `}

  ${({ stripedItems }) =>
    stripedItems &&
    css`
      &:nth-child(even) {
        background: ${cssVar('sanJuan')};
      }
    `};

  @media print {
    :first-child {
      font-weight: bold;
      border: 1px solid black;
    }

    min-height: 0;
    padding: 5px;
    border-left: 1px solid #00000044;
    border-right: 1px solid #00000044;
    border-bottom: 1px solid #00000044;
  }
`;

OverviewTableRow.propTypes = {
  isOpen: PropTypes.bool,
  head: PropTypes.bool,
  additionalClass: PropTypes.string,
  itemsNumber: PropTypes.number,
  mobile: PropTypes.bool,
  mobileDetails: PropTypes.bool,
  fullPageList: PropTypes.bool,
  gridTemplate: PropTypes.string,
  isClickable: PropTypes.number,
  detailsList: PropTypes.bool,
};

OverviewTableRow.defaultProps = {
  isOpen: false,
  mobileDetails: false,
};

export { OverviewTableRow };
