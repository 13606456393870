import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const changeOwnPasswordActionType = 'CHANGE_OWN_PASSWORD';
const changeOwnPasswordAction = () => ({
  type: changeOwnPasswordActionType,
});

const changeOwnPasswordSuccessActionType = `${changeOwnPasswordActionType}_SUCCESS`;
const changeOwnPasswordSuccessAction = () => ({
  type: changeOwnPasswordSuccessActionType,
});

const changeOwnPasswordFailureActionType = `${changeOwnPasswordActionType}_FAILURE`;
const changeOwnPasswordFailureAction = () => ({
  type: changeOwnPasswordFailureActionType,
});

const changeOwnPassword = (oldPassword, newPassword) => dispatch => {
  dispatch(changeOwnPasswordAction());

  return axios
    .post(`${API_HOST_AUTH}/users/change-password`, {
      oldPassword,
      newPassword,
    })
    .then(res => {
      dispatch(changeOwnPasswordSuccessAction());

      return res.data;
    })
    .catch(error => {
      dispatch(changeOwnPasswordFailureAction());

      return Promise.reject(error.data);
    });
};

export {
  changeOwnPasswordActionType,
  changeOwnPasswordSuccessActionType,
  changeOwnPasswordFailureActionType,
  changeOwnPassword,
};
