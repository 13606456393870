import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { getUser as getUserAction } from 'actions/Users/Details/userDetails';
import { changeUsersPassword as changeUsersPasswordAction } from 'actions/Users/changeUsersPassword';
import { showSnackbar as showSnackbarAction } from 'actions/shared/snackbar';

import { CHANGE_PASSWORD } from 'shared/helpers/constants/changePasswordConstants';
import {
  dangerOptions,
  successOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { USER } from 'shared/helpers/constants/users/userConstants';

import { goBackOrTo } from 'utils/history';

import { ResetPasswordForm } from 'pages/ResetPassword/components/ResetPasswordFormPortal/ResetPasswordForm';

const ChangeUsersPasswordBase = props => {
  const {
    getUser,
    match: { params },
  } = props;

  useEffect(() => {
    getUser(params.userId);
  }, [getUser, params.userId]);

  const navigateBack = () => {
    const { userId, companyId } = props.match.params;

    goBackOrTo(companyId ? `/companies/${companyId}/users/${userId}` : `/users/${userId}`);
  };

  const submit = ({ newPassword }) => {
    const { userId } = props.match.params;

    return props
      .changeUsersPassword(userId, newPassword)
      .then(res => {
        props.showSnackbar(successOptions, `${USER.PASSWORD_CHANGED} ${res.email}`);
        navigateBack();
      })
      .catch(error => {
        props.showSnackbar(dangerOptions, `${USER.PASSWORD_NOT_CHANGED} ${error.message}`);
      });
  };

  return (
    <ResetPasswordForm
      header={CHANGE_PASSWORD.CHANGE_PASSWORD}
      subheader={props.userEmail}
      onSubmit={submit}
      onCancel={navigateBack}
    />
  );
};

const mapStateToProps = state => ({
  userEmail: state.userDetails.user && state.userDetails.user.email,
});

const mapDispatchToProps = {
  changeUsersPassword: changeUsersPasswordAction,
  showSnackbar: showSnackbarAction,
  getUser: getUserAction,
};

const ChangeUsersPassword = connect(mapStateToProps, mapDispatchToProps)(ChangeUsersPasswordBase);

export { ChangeUsersPassword, ChangeUsersPasswordBase };
