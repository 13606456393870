import {
  forwardDocumentType,
  forwardDocumentSuccessType,
  forwardDocumentFailureType,
} from 'actions/Files/forwardDocument';

const INITIAL_STATE = {
  isPending: false,
};

const forwardDocumentReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case forwardDocumentType:
      return {
        isPending: true,
      };
    case forwardDocumentSuccessType:
      return {
        isPending: false,
      };
    case forwardDocumentFailureType:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};

export { forwardDocumentReducer };
