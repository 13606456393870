import { LinkButton } from 'shared/components/Buttons';
import { DetailsDateField } from 'shared/components/Details/DetailsDateField';
import { DetailsField } from 'shared/components/Details/DetailsField';
import { DetailsHumanizedField } from 'shared/components/Details/DetailsHumanizedField';
import { DetailsRadioField } from 'shared/components/Details/DetailsRadioFieldRedesign';
import { termsAbbrDetailsString } from 'shared/components/TermsAbbrDetails/TermsAbbrDetails';
import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';
import { isOwnerOrSupport } from 'shared/helpers/matchers/checkUserType';
import { checkSentFromCompany } from 'shared/helpers/matchers/lots/checkSentFromCompany';

import { history } from 'utils/history';

const lotDetailsFieldsConfig = (lot, auth) => [
  {
    ...(isOwnerOrSupport(auth.user.userType) && {
      customContent: lot.sentFromCompany && (
        <LinkButton
          label={lot.sentFromCompany.companyName}
          onClick={() => history.push(`/companies/${lot.sentFromCompany._id}`)}
        />
      ),
    }),
    label: LOTS.SHORTENED_SENT_FROM_COMPANY,
    value: lot.sentFromCompany.companyName,
    icon: 'icon-home',
    id: 'sentFromCompany',
    FieldType: DetailsField,
  },
  {
    label: LOTS.SHORTENED_SENT_FROM_COMPANY_LOCATION,
    value: lot.sentFromCompanyLocation.name,
    icon: 'icon-home',
    id: 'sentFromCompanyLocation',
    FieldType: DetailsField,
  },
  {
    label: LOTS.STATUS_FOR_GLOBAL,
    value: lot.statusForGlobal,
    icon: 'icon-type',
    id: 'statusForGlobal',
    FieldType: DetailsHumanizedField,
  },
  {
    label: LOTS.STATUS_FOR_CUSTOMER,
    value: lot.statusForCustomer,
    icon: 'icon-type',
    id: 'statusForCustomer',
    FieldType: DetailsHumanizedField,
  },
  checkSentFromCompany(lot.sentFromCompany) && {
    label: LOTS.PROCESS_UPON_DELIVERY,
    value: lot.processUponDelivery,
    icon: 'icon-process',
    id: 'processUponDelivery',
    FieldType: DetailsRadioField,
  },
  {
    label: LOTS.MATERIAL_TYPE,
    value: lot.materialType,
    icon: 'icon-cart',
    id: 'materialType',
    FieldType: DetailsHumanizedField,
  },
  isOwnerOrSupport(auth.user.userType) && {
    label: LOTS.TERMS_APPLIED,
    value: lot.termsApplied && termsAbbrDetailsString(lot),
    icon: 'icon-document',
    id: 'termsApplied',
    FieldType: DetailsField,
  },
  {
    label: LOTS.HEDGED_STATUS,
    value: lot.hedgedStatus,
    icon: 'icon-type',
    id: 'hedgedStatus',
    FieldType: DetailsHumanizedField,
  },
  {
    label: LOTS.HEDGED_LOT_DELIVERY_DEADLINE,
    value: lot.hedgedLotDeliveryDeadline,
    id: 'hedgedLotDeliveryDeadline',
    FieldType: DetailsDateField,
  },
  {
    label: LOTS.CUSTOMER_INSTRUCTIONS,
    value: lot.customerInstructions,
    icon: 'icon-document',
    id: 'customerInstructions',
    FieldType: DetailsField,
  },
];

const lotDetailsContainerConfig = (lot, auth) => ({
  header: LOTS.GENERAL_DETAILS,
  template: [
    'sentFromCompany sentFromCompany sentFromCompanyLocation sentFromCompanyLocation',
    'statusForGlobal statusForCustomer hedgedStatus hedgedLotDeliveryDeadline',
    checkSentFromCompany(lot.sentFromCompany)
      ? 'processUponDelivery materialType customerInstructions customerInstructions'
      : 'materialType materialType customerInstructions customerInstructions',
    isOwnerOrSupport(auth.user.userType)
      ? 'termsApplied termsApplied termsApplied termsApplied'
      : '. . . . ',
  ],
});

export { lotDetailsFieldsConfig, lotDetailsContainerConfig };
