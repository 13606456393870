const GENERAL_INFO = {
  GENERAL_INFO: 'General info',
  FACILITY_ADDRESS: 'Facility address',
  TOLL_FREE: 'Toll free',
  PHONE: 'Phone',
  HOURS_OF_OPERATION: 'Hours of operation',
  HEDGING_HOURS: 'Hedging hours',
  SHIPPING_AND_RECEIVING_HOURS: 'Shipping and receiving hours',
  ACCOUNTING_DEPARTMENT: 'Accounting department',
  CONTACT_YOUR_REPRESENTATIVE: 'Contact your representative',
  MOBILE: 'Mobile',
  OFFICE: 'Office',
  EMAIL: 'Email',
};

export { GENERAL_INFO };
