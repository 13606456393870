import {
  getAgreementsDetailsActionType,
  getAgreementsDetailsFailureActionType,
  getAgreementsDetailsSuccessActionType,
  clearAgreementsDetailsActionType,
} from 'actions/Agreements/agreementsDetails';
import {
  createAgreementDocumentActionType,
  createAgreementDocumentSuccessActionType,
  createAgreementDocumentFailureActionType,
} from 'actions/Agreements/createDocument';
import { uploadAgreementDocumentSuccessActionType } from 'actions/Agreements/uploadAgreementDocument';

const INITIAL_STATE = {
  isPending: false,
  errorMessage: null,
  agreement: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getAgreementsDetailsActionType:
      return {
        agreement: null,
        isPending: true,
      };
    case getAgreementsDetailsSuccessActionType:
      return {
        ...state,
        isPending: false,
        agreement: action.payload.agreement,
      };
    case getAgreementsDetailsFailureActionType:
      return {
        ...state,
        isPending: false,
        errorMessage: action.payload.errorMessage,
      };
    case clearAgreementsDetailsActionType:
      return {
        ...state,
        agreement: null,
        isPending: false,
        errorMessage: null,
      };
    case createAgreementDocumentActionType:
      return {
        ...state,
        isPending: true,
      };
    case createAgreementDocumentSuccessActionType:
      return {
        ...state,
        agreement: state.agreement
          ? {
              ...state.agreement,
              status: action.payload.agreement.status,
              updatedBy: action.payload.agreement.updatedBy,
              updatedAt: action.payload.agreement.updatedAt,
              gradingDocument: action.payload.agreement.gradingDocument,
              docuSignDocument: action.payload.agreement.docuSignDocument,
            }
          : null,
        isPending: false,
      };
    case createAgreementDocumentFailureActionType:
      return {
        ...state,
        isPending: false,
      };
    case uploadAgreementDocumentSuccessActionType:
      return {
        ...state,
        agreement: state.agreement
          ? {
              ...state.agreement,
              status: action.payload.agreement.status,
              updatedBy: action.payload.agreement.updatedBy,
              updatedAt: action.payload.agreement.updatedAt,
              gradingDocument: action.payload.agreement.gradingDocument,
              docuSignDocument: action.payload.agreement.docuSignDocument,
            }
          : null,
      };
    default:
      return state;
  }
};
