import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { enterAssayResults } from 'actions/Lots/enterAssayResults';
import { overwriteApproveShipmentSummary } from 'actions/Lots/manageGlobalFiles';
import { openModal } from 'actions/shared/modal';
import { showSnackbar } from 'actions/shared/snackbar';

import { FormContainer } from 'shared/components/Form';
import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';
import {
  dangerOptions,
  successOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

import { EnterAssayResultsForm } from './EnterAssayResultsForm/EnterAssayResultsForm';

const EnterAssayResultsLayout = props => {
  const {
    match: { params },
    history,
    enterAssayResultsDetails,
  } = props;

  const submit = async values => {
    try {
      const { message } = await props.enterAssayResults(values, params.id);

      props.showSnackbar(successOptions, message);
    } catch (err) {
      props.showSnackbar(dangerOptions, err.message);
    } finally {
      history.push(`/lots/list/${params.id}`);
    }
  };

  return (
    <FormContainer header={LOTS.ENTER_ASSAY_MANUALLY} loaded={!enterAssayResultsDetails.isPending}>
      {() => <EnterAssayResultsForm {...props} onSubmit={submit} />}
    </FormContainer>
  );
};

const mapStateToProps = ({ enterAssayResultsDetails }) => ({
  enterAssayResultsDetails,
});

const mapDispatchToProps = {
  enterAssayResults,
  overwriteApproveShipmentSummary,
  showSnackbar,
  openModal,
};

EnterAssayResultsLayout.propTypes = {
  openModal: PropTypes.func,
  overwriteApproveShipmentSummary: PropTypes.func,
  enterAssayResults: PropTypes.func,
  showSnackbar: PropTypes.func,
  match: PropTypes.shape({
    isExact: PropTypes.bool,
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
    path: PropTypes.string,
    url: PropTypes.string,
  }),
  history: PropTypes.object,
  enterAssayResultsDetails: PropTypes.object,

  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

const EnterAssayResults = connect(mapStateToProps, mapDispatchToProps)(EnterAssayResultsLayout);

export { EnterAssayResultsLayout, EnterAssayResults };
