import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { FormContainer } from 'shared/components/Form';
import { POOL_ACCOUNTS } from 'shared/helpers/constants/poolAccounts/poolAccountsConstants';
import {
  marketHolidayModal,
  marketHoursModal,
} from 'shared/helpers/constants/poolAccounts/poolAccountsModalConstants';
import {
  successOptions,
  dangerOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { parseSummaryAvailableSelectData } from 'shared/helpers/dataParsers/poolAccounts/parseSummaryAvailableSelectData';

import dateAdapter from 'utils/date/dateAdapter';

import { ConnectedRequestMultipleSettlementsForm } from '../RequestSettlementForm/ConnectedRequestMultipleSettlementsForm';

const SettlementForLot = props => {
  const { isPending } = useSelector(({ requestSettlement }) => requestSettlement);

  useEffect(() => {
    props.getSummaryAvailableLots('lots-to-settle');
    return () => props.clearSummaryAvailableLots();
  }, []);

  const submit = values => props.marketAvailability().then(res => informationModal(res)(values));

  const informationModal =
    ({
      marketHoliday,
      marketTimeFrom,
      marketTimeTo,
      marketHours,
      marketDays,
      timezone,
      isBusinessDay,
    }) =>
    values => {
      const isAvailable = dateAdapter().isBetween(marketTimeFrom, marketTimeTo) && isBusinessDay;

      const modalSettings = marketHoursModal({ marketHours, marketDays, timezone });
      const isSettlementForLot = props.history.location.pathname.endsWith('settlement-for-lot');

      if (marketHoliday) {
        return props.openModal(marketHolidayModal(isSettlementForLot), () => sentRequest(values));
      }
      if (!isAvailable) {
        return props.openModal(modalSettings, () => sentRequest(values));
      }
      return sentRequest(values);
    };

  const sentRequest = values =>
    props
      .settlementForLotRequest(values)
      .then(res => {
        props.showSnackbar(successOptions, res.message);

        if (props.location.state && props.location.state.requestPayment) {
          return props.history.push('/payments');
        }

        props.history.push('/lots');
      })
      .catch(res => props.showSnackbar(dangerOptions, res.message));

  return (
    <FormContainer
      header={POOL_ACCOUNTS.SETTLEMENT_FOR_LOTS}
      subHeader={props.auth.user.relatedCompany.companyName}
      loaded={!props.summaryAvailableLots.isPending && !isPending}
    >
      <ConnectedRequestMultipleSettlementsForm
        summaryAvailableLots={parseSummaryAvailableSelectData(props.summaryAvailableLots.lots)}
        onSubmit={submit}
        allowNegativeValues
        customerRequestSettlement
      />
    </FormContainer>
  );
};

export { SettlementForLot };
