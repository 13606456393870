import PropTypes from 'prop-types';
import React, { useState, useEffect, forwardRef } from 'react';

import { ListCollapse } from 'shared/components/ListCollapse/ListCollapse';
import { OverviewList } from 'shared/components/OverviewList/OverviewList';
import {
  OverviewListSection,
  OverviewListSectionWrapper,
} from 'shared/components/OverviewList/OverviewListSection/OverviewListSection';
import { isTablet } from 'shared/helpers/constants/resolutionsConstants';
import { useWindowWidth } from 'shared/hooks/useWindowWidth';

const OverviewListWrapper = forwardRef((props, ref) => {
  const { customList, children, list, access, contentHide } = props;
  const total = list.items?.total;
  const [displayContent, setContentDisplay] = useState(false);

  useEffect(() => {
    if (!contentHide && total > 0) {
      setContentDisplay(true);
    }
  }, [total]);

  const windowWidth = useWindowWidth();

  return (
    <OverviewListSectionWrapper
      isOpen={displayContent}
      disableTransform={props.disableTransform}
      total={total}
    >
      <OverviewListSection isOpen={displayContent} total={total}>
        <div ref={ref}>
          <ListCollapse
            collapse={displayContent}
            toggleList={() => setContentDisplay(!displayContent)}
            clickable={!!total}
            label={props.label}
            pending={props.pending}
          />
          {props.button && <div>{props.button}</div>}
        </div>
      </OverviewListSection>
      {customList
        ? !!total && children
        : list && (
            <div>
              <OverviewList
                getList={props.getList}
                clearData={props.clearList}
                input={list}
                itemsTemplate={props.itemsTemplate}
                itemsTemplateMobile={props.itemsTemplateMobile}
                itemDetailsTemplate={props.itemDetailsTemplate}
                templateProps={props.templateProps}
                actionsTemplate={props.actionsTemplate}
                actionsContext={props.actionsContext}
                isMobile={windowWidth < isTablet}
                label={props.label}
                displayAllDetails={props.displayAllDetails}
                dashboard={props.dashboard}
                logs={props.logs}
              />
              {access && children}
            </div>
          )}
    </OverviewListSectionWrapper>
  );
});

OverviewListWrapper.propTypes = {
  actionsTemplate: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        action: PropTypes.string,
        icon: PropTypes.string,
        onClick: PropTypes.func,
      }),
    ]),
  ),
  getList: PropTypes.func,
  clearData: PropTypes.func,
  list: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  customList: PropTypes.bool,
  button: PropTypes.node,
  itemsTemplate: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        label: PropTypes.string,
        render: PropTypes.func,
      }),
    ]),
  ),
  itemsTemplateMobile: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        label: PropTypes.string,
        render: PropTypes.func,
      }),
    ]),
  ),
  itemDetailsTemplate: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        label: PropTypes.string,
        render: PropTypes.func,
      }),
    ]),
  ),
  templateProps: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        label: PropTypes.string,
        render: PropTypes.func,
      }),
    ]),
  ),
  label: PropTypes.string.isRequired,
  displayAllDetails: PropTypes.bool,
  dashboard: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  access: PropTypes.bool,
  disableTransform: PropTypes.bool,
  contentHide: PropTypes.bool,
  logs: PropTypes.bool,
};

OverviewListWrapper.defaultProps = {
  getList: () => {},
  actionsTemplate: [],
  access: false,
  disableTransform: false,
  contentHide: false,
  logs: false,
};

export { OverviewListWrapper };
