import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const deletePayoutRuleActionType = 'DELETE_PAYOUT_RULE';
const deletePayoutRuleAction = () => ({
  type: deletePayoutRuleActionType,
});

const deletePayoutRuleSuccessActionType = `${deletePayoutRuleActionType}_SUCCESS`;
const deletePayoutRuleSuccessAction = () => ({
  type: deletePayoutRuleSuccessActionType,
});

const deletePayoutRuleFailureActionType = `${deletePayoutRuleActionType}_FAILURE`;
const deletePayoutRuleFailureAction = () => ({
  type: deletePayoutRuleFailureActionType,
});

const deletePayoutRule = company => dispatch => {
  dispatch(deletePayoutRuleAction());

  return axios
    .delete(`${API_HOST_AUTH}/payout-rules/${company._id}`)
    .then(() => dispatch(deletePayoutRuleSuccessAction()))
    .catch(error => {
      dispatch(deletePayoutRuleFailureAction());

      return Promise.reject(error);
    });
};

export {
  deletePayoutRuleActionType,
  deletePayoutRuleSuccessActionType,
  deletePayoutRuleFailureActionType,
  deletePayoutRule,
};
