import { useFormik } from 'formik';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getCompanies } from 'actions/Companies/companiesList';
import { getCompanyLocations } from 'actions/Locations/getCompanyLocations';
import { generateClientLotReport } from 'actions/Reports/generateClientLotReport';
import { openModal } from 'actions/shared/modal';
import { showSnackbar } from 'actions/shared/snackbar';

import { Section } from 'shared/components/Form';
import { FormContainer } from 'shared/components/Form/FormContainer/FormContainer';
import { FieldDate, FieldSelect } from 'shared/components/Formik/Fields';
import { FieldRadioButton } from 'shared/components/Formik/Fields/FieldRadioButton/FieldRadioButton';
import { Form } from 'shared/components/Formik/Form';
import { companyStatuses } from 'shared/helpers/constants/companies/companiesFilters';
import { cancelModal } from 'shared/helpers/constants/modalConstants';
import { REPORTS } from 'shared/helpers/constants/reports/reportsConstants';
import { dangerOptions } from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

import { goBackOrTo } from 'utils/history';

import { ClientLotReportFormSchema } from './ClientLotReportFormYup';

export function ClientLotReportForm() {
  const dispatch = useDispatch();
  const history = useHistory();

  const onSubmit = async submitValues => {
    try {
      await dispatch(generateClientLotReport(submitValues));
    } catch (err) {
      dispatch(showSnackbar(dangerOptions, REPORTS.CREATE_CLIENT_LOT_REPORT_FAILURE));
    } finally {
      goBackOrTo('/reports/client-lot-report');
    }
  };

  const {
    values,
    isValid,
    handleBlur,
    getFieldMeta,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    touched,
    dirty,
  } = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    initialValues: {
      includeAllCompanies: true,
      companies: [],
      includeAllLocations: true,
      companyLocations: [],
      dateFrom: undefined,
      dateTo: undefined,
    },
    validationSchema: ClientLotReportFormSchema,
    onSubmit,
  });

  const getErrors = name => Object.keys(touched).includes(name) && getFieldMeta(name).error;

  const { companies } = useSelector(({ companiesList }) => companiesList);
  const companyLocations = useSelector(({ companyLocations }) => companyLocations);

  useEffect(() => {
    try {
      dispatch(getCompanies(undefined, undefined, undefined, { status: [companyStatuses.active] }));
    } catch (err) {
      goBackOrTo('/reports/client-lot-report');
      dispatch(showSnackbar(dangerOptions, err.message));
    }
  }, []);

  useEffect(() => {
    if (values.companies.length !== 1) return;
    try {
      dispatch(getCompanyLocations(values.companies[0].value));
    } catch (err) {
      goBackOrTo('/reports/client-lot-report');
      dispatch(showSnackbar(dangerOptions, err.message));
    }
  }, [values.companies]);

  const modalOpen = () => {
    dispatch(openModal(cancelModal, () => history.push('/reports/client-lot-report')));
  };

  const parseCompaniesOptions = useMemo(() => {
    return companies?.map(company => ({
      value: company._id,
      label: company.companyName,
    }));
  }, [companies]);

  const parseCompanyLocationsOptions = useMemo(() => {
    return values.companies.length === 1
      ? companyLocations?.data?.map(location => ({
          value: location._id,
          label: location.name,
        }))
      : [];
  }, [companyLocations, values.companies]);

  const handleChangeSelectAllClients = async value => {
    await setFieldValue('includeAllCompanies', value);
    if (value) {
      await setFieldValue('includeAllLocations', true);
      await setFieldValue('companies', []);
      await setFieldValue('companyLocations', []);
    }
  };

  const handleChangeSelectAllLocations = async value => {
    await setFieldValue('includeAllLocations', value);
    if (value) {
      setFieldValue('companyLocations', []);
    }
  };

  const handleSelectChange = async (option, data, fieldName) => {
    if (data?.action === 'clear') {
      await setFieldTouched(fieldName);
    }

    await setFieldValue(fieldName, option);
  };

  const handleCompaniesChange = async (option, data) => {
    await handleSelectChange(option, data, 'companies');
    await setFieldValue('includeAllLocations', true);
    await setFieldValue('companyLocations', []);
  };

  return (
    <FormContainer loaded={true} header={REPORTS.GENERATE_CLIENT_LOT_REPORT}>
      <Form
        onSubmit={handleSubmit}
        onCancel={modalOpen}
        submitDisabled={!isValid || !dirty}
        header={REPORTS.GENERATE_CLIENT_LOT_REPORT}
      >
        <Section
          template={[
            'includeAllCompanies companies',
            'includeAllLocations companyLocations',
            'dateFrom dateTo',
          ]}
        >
          <FieldRadioButton
            name="includeAllCompanies"
            label={REPORTS.INCLUDE_ALL_COMPANIES}
            field="required"
            value={values.includeAllCompanies}
            onChange={handleChangeSelectAllClients}
            setFieldValue={setFieldValue}
            error={getErrors('includeAllCompanies')}
          />
          <FieldSelect
            name="companies"
            options={parseCompaniesOptions}
            disabled={!companies || values.includeAllCompanies}
            searchable={true}
            clearable
            label={REPORTS.COMPANIES}
            field="required"
            multi
            wrap
            value={values.companies}
            onChange={handleCompaniesChange}
            setFieldValue={setFieldValue}
            error={getErrors('companies')}
          />
          <FieldRadioButton
            name="includeAllLocations"
            label={REPORTS.INCLUDE_ALL_LOCATIONS}
            field="required"
            value={values.includeAllLocations}
            disabled={values.companies.length !== 1 || values.includeAllCompanies}
            onChange={handleChangeSelectAllLocations}
            setFieldValue={setFieldValue}
            error={getErrors('includeAllLocations')}
          />
          <FieldSelect
            name="companyLocations"
            options={parseCompanyLocationsOptions}
            searchable={true}
            clearable
            label={REPORTS.COMPANY_LOCATIONS}
            field="required"
            multi
            error={getFieldMeta('companyLocations').error}
            wrap
            value={values.companyLocations}
            disabled={
              values.companies.length !== 1 ||
              values.includeAllCompanies ||
              values.includeAllLocations
            }
            onChange={(value, data) => {
              handleSelectChange(value, data, 'companyLocations');
            }}
            setFieldValue={setFieldValue}
          />
          <FieldDate
            name="dateFrom"
            label={REPORTS.DATE_FROM}
            field="required"
            value={values.dateFrom}
            onBlur={handleBlur}
            error={getErrors('dateFrom')}
            onChange={setFieldValue}
            maxDate={new Date()}
            required
          />
          <FieldDate
            name="dateTo"
            label={REPORTS.DATE_TO}
            field="required"
            value={values.dateTo}
            onBlur={handleBlur}
            error={getErrors('dateTo')}
            onChange={setFieldValue}
            minDate={values.dateFrom}
            maxDate={new Date()}
          />
        </Section>
      </Form>
    </FormContainer>
  );
}
