import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { cssVar, media, sanitizeRow, pxToRem } from 'shared/helpers/styling/styling';

export const Subheader = styled.p`
  display: block;
  color: ${cssVar('solidYellow')};
  font-size: ${pxToRem(12)};
  margin: 0;
`;

export const SectionCell = styled.div.attrs(({ area }) => ({
  style: {
    gridArea: sanitizeRow(area),
  },
}))`
  display: flex;
  justify-content: center;
  flex-direction: column;
  ${({ noPadding, children }) =>
    !noPadding &&
    children &&
    css`
      padding: 5px 90px 5px 10px;
    `};
  ${({ smallPadding, children }) =>
    smallPadding &&
    children &&
    css`
      padding: 5px 50px 5px 10px;
    `};
  background-color: ${cssVar('limedSpruceForm')};
  max-width: 100%;

  ${({ rowSection }) =>
    rowSection &&
    css`
      padding: 0;
    `};

  ${({ children, noPadding }) =>
    children &&
    !noPadding &&
    css`
      ${media.mobile`
        padding: ${({ withoutGridGap }) => (withoutGridGap ? '5px 0px' : '5px 90px')};
      `}

      ${media.phone`
        padding: 5px 37px;
      `}
    `}

  ${({ noPaddingField }) =>
    Number.isFinite(noPaddingField) &&
    css`
      &:nth-child(${noPaddingField}) {
        padding: 0;
      }
    `}
`;

SectionCell.propTypes = {
  area: PropTypes.string.isRequired,
};
