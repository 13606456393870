import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const updateMarketIndicatorsType = 'UPDATE_MARKET_INDICATORS';
const updateMarketIndicatorsAction = () => ({
  type: updateMarketIndicatorsType,
});

const updateMarketIndicatorsSuccessType = `${updateMarketIndicatorsType}_SUCCESS`;
const updateMarketIndicatorsSuccessAction = () => ({
  type: updateMarketIndicatorsSuccessType,
});

const updateMarketIndicatorsFailureType = `${updateMarketIndicatorsType}_FAILURE`;
const updateMarketIndicatorsFailureAction = () => ({
  type: updateMarketIndicatorsFailureType,
});

const updateMarketIndicators = marketIndicators => async dispatch => {
  dispatch(updateMarketIndicatorsAction());

  try {
    const response = await axios.put(`${API_HOST_AUTH}/market-indicators`, marketIndicators);
    dispatch(updateMarketIndicatorsSuccessAction());

    return response.data;
  } catch (error) {
    dispatch(updateMarketIndicatorsFailureAction());

    return Promise.reject(error.data);
  }
};

export {
  updateMarketIndicatorsType,
  updateMarketIndicatorsSuccessType,
  updateMarketIndicatorsFailureType,
  updateMarketIndicators,
};
