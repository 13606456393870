import React, { Fragment } from 'react';

import { AddButton } from 'shared/components/Buttons/AddButton/AddButton';
import { Separator } from 'shared/components/Form';
import { SETTLEMENT_FOR_LOT_WIZARD } from 'shared/helpers/constants/poolAccounts/settlementForLotWizard';
import { getDeliveriesValues } from 'shared/helpers/dataParsers/deliveries/getDeliveriesValues';
import { parseRemainingOunces } from 'shared/helpers/dataParsers/deliveries/parseRemainingOunces';
import { safeParseFloat, roundUp } from 'shared/helpers/parsers/number';

import { ApplyBlanketHedges } from '../ApplyBlanketHedges/ApplyBlanketHedges';

const { APPLY_BLANKET_HEDGES } = SETTLEMENT_FOR_LOT_WIZARD;

const initialDeliveryValues = {
  relatedHedge: null,
  platinumOz: null,
  palladiumOz: null,
  rhodiumOz: null,
  availablePalladiumOz: null,
  availablePlatinumOz: null,
  availableRhodiumOz: null,
};

const ApplyBlanketHedgesList = ({
  fields,
  change,
  blur,
  requestSettlementWizardData,
  formValues,
  hedges,
  availableOunces,
  invalid,
}) => {
  const getAvailableHedges = ({ selectedLot, hedges = [] }) =>
    (selectedLot &&
      hedges.filter(({ relatedCompany }) => relatedCompany === selectedLot?.sentFromCompany._id)) ||
    [];

  const checkHedgesAvailability = () => {
    const availableHedges = getAvailableHedges({
      selectedLot: formValues?.applyBlanketHedges.relatedLot,
      hedges,
    });

    return !availableHedges?.length || availableHedges?.length <= fields.length;
  };

  const addSection = () => fields.push(initialDeliveryValues);

  const removeDelivery = index => fields.remove(index);

  const calculatePreviousRemainingOunces = index => {
    const currentField = fields.get(index);
    const currentRelatedHedge = currentField.relatedHedge && currentField.relatedHedge.value;

    return parseRemainingOunces(
      getDeliveriesValues(requestSettlementWizardData, currentRelatedHedge),
      currentRelatedHedge,
    );
  };

  const calculateLotRemainingOunces = index => {
    const initialRemainingOunces = {
      platinumOz: 0,
      palladiumOz: 0,
      rhodiumOz: 0,
    };

    const remainingOunces = fields
      .getAll()
      .slice(0, index)
      .reduce(
        (remaining, field) => ({
          platinumOz: remaining.platinumOz + +safeParseFloat(field.platinumOz),
          palladiumOz: remaining.palladiumOz + +safeParseFloat(field.palladiumOz),
          rhodiumOz: remaining.rhodiumOz + +safeParseFloat(field.rhodiumOz),
        }),
        { ...initialRemainingOunces },
      );

    return {
      platinumOz: +roundUp(remainingOunces.platinumOz, 3),
      palladiumOz: +roundUp(remainingOunces.palladiumOz, 3),
      rhodiumOz: +roundUp(remainingOunces.rhodiumOz, 3),
    };
  };

  return (
    <>
      {fields.map((delivery, index) => (
        <Fragment key={index}>
          <ApplyBlanketHedges
            delivery={delivery}
            values={fields.get(index)}
            previousRemainingOunces={calculatePreviousRemainingOunces(index)}
            previousLotsRemainingOunces={calculateLotRemainingOunces(index)}
            onRemove={() => removeDelivery(index)}
            change={change}
            blur={blur}
            index={index}
            formValues={formValues}
            hedges={hedges}
            availableOunces={availableOunces}
          />
          <Separator />
        </Fragment>
      ))}
      {fields.length > 0 && <Separator />}
      {fields.length < 10 && (
        <AddButton
          data-testid="applyBlanketHedgeButton"
          disabled={invalid || checkHedgesAvailability()}
          onClick={addSection}
        >
          {APPLY_BLANKET_HEDGES}
        </AddButton>
      )}
    </>
  );
};

export { ApplyBlanketHedgesList };
