import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getSelfDeliveryRequestsOverviewActionType = 'GET_SELF_DELIVERY_REQUESTS';
const getSelfDeliveryRequestsOverviewAction = () => ({
  type: getSelfDeliveryRequestsOverviewActionType,
});

const getSelfDeliveryRequestsOverviewSuccessActionType = `${getSelfDeliveryRequestsOverviewActionType}_SUCCESS`;
const getSelfDeliveryRequestsOverviewSuccessAction = data => ({
  type: getSelfDeliveryRequestsOverviewSuccessActionType,
  payload: {
    data,
  },
});

const getSelfDeliveryRequestsOverviewFailureActionType = `${getSelfDeliveryRequestsOverviewActionType}_FAILURE`;
const getSelfDeliveryRequestsOverviewFailureAction = () => ({
  type: getSelfDeliveryRequestsOverviewFailureActionType,
});

const clearSelfDeliveryRequestsOverviewActionType = 'CLEAR_SELF_DELIVERY_REQUESTS_OVERVIEW';
const clearSelfDeliveryRequestsOverview = () => ({
  type: clearSelfDeliveryRequestsOverviewActionType,
});

const getSelfDeliveryRequestsOverviewList = limit => dispatch => {
  dispatch(getSelfDeliveryRequestsOverviewAction());

  return axios
    .get(`${API_HOST_AUTH}/self-delivery-requests/overview/${limit}`)
    .then(({ data }) => {
      dispatch(getSelfDeliveryRequestsOverviewSuccessAction(data));
    })
    .catch(() => dispatch(getSelfDeliveryRequestsOverviewFailureAction()));
};

export {
  getSelfDeliveryRequestsOverviewActionType,
  getSelfDeliveryRequestsOverviewSuccessActionType,
  getSelfDeliveryRequestsOverviewFailureActionType,
  clearSelfDeliveryRequestsOverviewActionType,
  getSelfDeliveryRequestsOverviewList,
  clearSelfDeliveryRequestsOverview,
};
