import { dateToTimestamp } from 'shared/helpers/parsers/date';
import { removeCommasAndParse } from 'shared/helpers/parsers/text';

const parseFinalPaymentData = payment => ({
  relatedLot: payment.relatedLot.value,
  fundsType: payment.fundsType.value,
  paymentAmount: removeCommasAndParse(payment.paymentAmount),
  requestedFinalPaymentDate: dateToTimestamp(payment.requestedFinalPaymentDate),
  additionalInfo: payment.additionalInfo,
});

export { parseFinalPaymentData };
