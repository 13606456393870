import React, { Component } from 'react';
import { css } from 'styled-components';

import { ListCollapse } from 'shared/components/ListCollapse/ListCollapse';
import {
  OverviewListSectionWrapper,
  OverviewListSection,
} from 'shared/components/OverviewList/OverviewListSection/OverviewListSection';
import { overviewPackageClasses } from 'shared/helpers/constants/packages/packageClasses';
import { cssVar } from 'shared/helpers/styling/styling';

import { OverviewPackagesList } from 'pages/Packages/components/OverviewList/OverviewPackagesList';
import { relatedModules } from 'pages/Packages/components/OverviewList/config/relatedModules';

import { StyledPackagesOverviewWrapper } from './StyledPackagesOverview';

class PackagesOverview extends Component {
  state = {
    displayContent: true,
    sectionType: overviewPackageClasses.all,
  };

  toggleContent = () => {
    const {
      packagesOverviewList: { packagesList },
    } = this.props;

    packagesList.items &&
      packagesList.items.total &&
      this.setState(prevState => ({ displayContent: !prevState.displayContent }));
  };

  itemFilter = item => {
    const { packagesOverviewList, shipmentDetails } = this.props;

    const filteredLots = shipmentDetails &&
      packagesOverviewList.packagesList.all && [
        ...new Set(packagesOverviewList.packagesList.all.docs.map(p => p.grgLotNumber)),
      ];

    return (
      (filteredLots.indexOf(item.grgLotNumber) + 1) % 2 === 0 &&
      css`
        background-color: ${cssVar('nevadaDarker')};
      `
    );
  };

  render() {
    const { packagesList } = this.props.packagesOverviewList;
    const total = packagesList.items?.total;
    return (
      <StyledPackagesOverviewWrapper>
        <OverviewListSectionWrapper isOpen={this.state.displayContent} total={total}>
          <OverviewListSection isOpen={this.state.displayContent} total={total}>
            <div>
              <ListCollapse
                collapse={this.state.displayContent}
                toggleList={this.toggleContent}
                label={this.props.label}
              />
            </div>
          </OverviewListSection>
          <div>
            <OverviewPackagesList
              itemFilter={this.itemFilter}
              relatedModule={relatedModules.shipments}
              {...this.props}
              sectionType={this.state.sectionType}
              packagesList={packagesList[this.state.sectionType]}
            />
          </div>
        </OverviewListSectionWrapper>
      </StyledPackagesOverviewWrapper>
    );
  }
}

export { PackagesOverview };
