export const companyAddFileActionType = 'COMPANY_ADD_FILE_ACTION_TYPE';
export const companyAddFileAction = file => {
  return {
    type: companyAddFileActionType,
    payload: { file },
  };
};

export const companyAddExistingFilesActionType = 'COMPANY_ADD_EXISTING_FILES_ACTION_TYPE';
export const companyAddExistingFilesAction = files => {
  return {
    type: companyAddExistingFilesActionType,
    payload: files,
  };
};

export const companyRemoveFileActionType = 'COMPANY_REMOVE_FILE_ACTION_TYPE';
export const companyRemoveFileAction = payload => {
  return {
    type: companyRemoveFileActionType,
    payload,
  };
};

export const companyRemoveExistingFileActionType = 'COMPANY_REMOVE_EXISTING_FILE_ACTION_TYPE';
export const companyRemoveExistingFileAction = payload => {
  return {
    type: companyRemoveExistingFileActionType,
    payload,
  };
};

export const companyClearAllFilesActionType = 'COMPANY_CLEAR_ALL_FILES';
export const companyClearAllFilesAction = () => {
  return {
    type: companyClearAllFilesActionType,
  };
};

export const companyAddFile = payload => {
  return dispatch => {
    dispatch(companyAddFileAction({ type: payload.name, file: [].slice.call(payload.files) }));
  };
};

export const companyRemoveFile = (fileType, fileIndex) => {
  return dispatch => {
    dispatch(companyRemoveFileAction({ fileType, fileIndex }));
  };
};

export const companyAddExistingFiles = payload => {
  return dispatch => {
    dispatch(companyAddExistingFilesAction(payload));
  };
};

export const companyRemoveExistingFile = payload => {
  return dispatch => {
    dispatch(companyRemoveExistingFileAction(payload));
  };
};

export const companyClearAllFiles = () => {
  return dispatch => {
    dispatch(companyClearAllFilesAction());
  };
};
