import { connect } from 'react-redux';

import {
  agreementsStatusFilter,
  agreementsFiltersClear,
} from 'actions/Agreements/agreementsFilters';
import { getAgreementsList, clearAgreementsList } from 'actions/Agreements/agreementsList';
import { downloadApiAttachment } from 'actions/Attachments/downloadAttachment';

import { AgreementsList } from './AgreementsList';

const mapStateToProps = ({
  auth,
  agreementsList,
  agreementsFilters,
  downloadAttachmentStorage,
}) => ({
  auth,
  agreementsList,
  agreementsFilters,
  downloadAttachmentStorage,
});

const mapDispatchToProps = {
  getAgreementsList,
  clearAgreementsList,
  agreementsStatusFilter,
  agreementsFiltersClear,
  downloadApiAttachment,
};

export const ConnectedAgreementsList = connect(mapStateToProps, mapDispatchToProps)(AgreementsList);
