import styled from 'styled-components';

export const StyledHedgesChangeTypeForm = styled.form`
  margin-top: 2.5rem;

  .form-group .Select {
    &.is-open,
    &-option {
      background: var(--outerSpaceFormBg);
    }
  }
`;
export const StyledHedgesChangeTypeActions = styled.div`
  margin-top: 130px;
  display: flex;
  justify-content: flex-end;
`;
