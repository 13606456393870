import {
  clearUsersListActionType,
  getUsersActionType,
  getUsersFailureActionType,
  getUsersSuccessActionType,
} from 'actions/Companies/Details/usersList';
import { inactivateCompanySuccessActionType } from 'actions/Companies/inactivateCompany';
import { reactivateCompanySuccessActionType } from 'actions/Companies/reactivateCompany';

const INITIAL_STATE = {
  isPending: false,
  errorMessage: null,
  users: [],
  limit: 3,
  page: 1,
  pages: null,
  total: null,
  items: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getUsersActionType:
      return {
        ...state,
        isPending: true,
      };
    case getUsersSuccessActionType:
      return {
        ...state,
        isPending: false,
        users: action.payload.users,
        pages: action.payload.pages,
        total: action.payload.total,
        items: {
          docs: action.payload.users,
        },
      };
    case getUsersFailureActionType:
      return {
        ...state,
        isPending: false,
        errorMessage: action.payload.errorMessage,
      };
    case inactivateCompanySuccessActionType:
    case reactivateCompanySuccessActionType:
      return {
        ...state,
        users: state.users.map(user => ({
          ...user,
          status:
            user.relatedCompany._id === action.payload.company._id
              ? action.payload.company.status
              : user.status,
        })),
      };
    case clearUsersListActionType:
      return {
        isPending: false,
        errorMessage: null,
        users: [],
        limit: 3,
        page: 1,
        pages: null,
        total: null,
      };
    default:
      return state;
  }
};
