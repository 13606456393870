function parseUserUpdateData(user) {
  return {
    firstName: user.firstName,
    lastName: user.lastName,
    title: user.title ? user.title : null,
    email: user.email,
    officePhone: user.officePhone ? user.officePhone : null,
    phoneExtension: user.phoneExtension ? user.phoneExtension : null,
    mobilePhone: user.mobilePhone,
    databaseAccess: user.databaseAccess,
    purchasingAppAccess: user.purchasingAppAccess,
    accessLevel: user.accessLevel.value || user.accessLevel,
    location: user.location.map(el => el.value),
  };
}

export { parseUserUpdateData };
