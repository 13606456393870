import { Dropdown, DropdownToggle } from 'reactstrap';
import styled from 'styled-components';
import { DropdownMenuItem } from '../ListFilters/styles/SelectDropdown.styles';
import { cssVar, pxToRem } from 'shared/helpers/styling/styling';

const StyledFiltersDropdown = styled(Dropdown)`
  height: 50px;
  width: 100%;
  font-size: 0.875rem;
  border-radius: 5px;
  transition: 100ms ease-in;

  .dropdown-menu.show {
    margin-top: 10px;
  }
`;

const StyledFiltersDropdownToggle = styled(DropdownToggle)`
  &.btn{
    height: 50px;
    width: 100%;
    padding: 0 10px 0 22px;
    border-radius: 5px;
    background-color: ${cssVar('shuttleGray')};
    border-color: ${cssVar('shuttleGray')};
    color: ${cssVar('alabaster')};

    .icon-tiny-arrow-down {
      margin-top: 5px;
    }

    .icon-cross {
      margin-top: 15px;
      opacity: .7;

      :hover {
        opacity: 1;
      }
    }

    :hover {
      background-color: ${cssVar('nevada')};
    }

    :focus {
      outline: none;
      box-shadow: none;
    }

    :disabled {
      background-color: ${cssVar('limedSpruceDarker')};
      cursor: not-allowed;
    }

    ::placeholder {
      color: ${cssVar('alto')};
      opacity: .56;
      font-size: .75rem;
    }
`;

const StyledFiltersDropdownItem = styled(DropdownMenuItem)`
  box-shadow: 0 11px 24px 0 rgba(0, 0, 0, 0.29);
`;

const StyledFiltersDropdownInput = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${({ isSelected }) => (isSelected ? cssVar('alabaster') : cssVar('dustGray'))};
  font-size: ${({ isSelected }) => (isSelected ? pxToRem(14) : pxToRem(12))};
  margin-right: 35px;
`;

export {
  StyledFiltersDropdown,
  StyledFiltersDropdownToggle,
  StyledFiltersDropdownItem,
  StyledFiltersDropdownInput,
};
