import { ASSAY } from 'shared/helpers/constants/lots/assayConstants';

import { setCorrectLabel } from 'utils/setCorrectLabel';

const containedMetalsFieldsConfig = assayOption => [
  {
    name: 'platinumContainedOzF',
    type: 'number',
    label: setCorrectLabel(
      assayOption,
      ASSAY.PLATINUM_CONTAINED_OZ_F,
      ASSAY.PLATINUM_CONTAINED_OZ_A,
    ),
    suffix: ASSAY.TROY_OZ,
  },
  {
    name: 'palladiumContainedOzF',
    type: 'number',
    label: setCorrectLabel(
      assayOption,
      ASSAY.PALLADIUM_CONTAINED_OZ_F,
      ASSAY.PALLADIUM_CONTAINED_OZ_A,
    ),
    suffix: ASSAY.TROY_OZ,
  },
  {
    name: 'rhodiumContainedOzF',
    type: 'number',
    label: setCorrectLabel(assayOption, ASSAY.RHODIUM_CONTAINED_OZ_F, ASSAY.RHODIUM_CONTAINED_OZ_A),
    suffix: ASSAY.TROY_OZ,
  },
  {
    name: 'platinumContainedOzH',
    type: 'number',
    label: setCorrectLabel(
      assayOption,
      ASSAY.PLATINUM_CONTAINED_OZ_H,
      ASSAY.PLATINUM_CONTAINED_OZ_B,
    ),
    suffix: ASSAY.TROY_OZ,
  },
  {
    name: 'palladiumContainedOzH',
    type: 'number',
    label: setCorrectLabel(
      assayOption,
      ASSAY.PALLADIUM_CONTAINED_OZ_H,
      ASSAY.PALLADIUM_CONTAINED_OZ_B,
    ),
    suffix: ASSAY.TROY_OZ,
  },
  {
    name: 'rhodiumContainedOzH',
    type: 'number',
    label: setCorrectLabel(assayOption, ASSAY.RHODIUM_CONTAINED_OZ_H, ASSAY.RHODIUM_CONTAINED_OZ_B),
    suffix: ASSAY.TROY_OZ,
  },
];

const containedAssaysFieldsConfig = assayOption => [
  {
    name: 'platinumAssayF',
    type: 'number',
    label: setCorrectLabel(assayOption, ASSAY.PLATINUM_ASSAY_F, ASSAY.PLATINUM_ASSAY_A),
    suffix: ASSAY.PPM,
  },
  {
    name: 'palladiumAssayF',
    type: 'number',
    label: setCorrectLabel(assayOption, ASSAY.PALLADIUM_ASSAY_F, ASSAY.PALLADIUM_ASSAY_A),
    suffix: ASSAY.PPM,
  },
  {
    name: 'rhodiumAssayF',
    type: 'number',
    label: setCorrectLabel(assayOption, ASSAY.RHODIUM_ASSAY_F, ASSAY.RHODIUM_ASSAY_A),
    suffix: ASSAY.PPM,
  },
  {
    name: 'platinumAssayH',
    type: 'number',
    label: setCorrectLabel(assayOption, ASSAY.PLATINUM_ASSAY_H, ASSAY.PLATINUM_ASSAY_B),
    suffix: ASSAY.PPM,
  },
  {
    name: 'palladiumAssayH',
    type: 'number',
    label: setCorrectLabel(assayOption, ASSAY.PALLADIUM_ASSAY_H, ASSAY.PALLADIUM_ASSAY_B),
    suffix: ASSAY.PPM,
  },
  {
    name: 'rhodiumAssayH',
    type: 'number',
    label: setCorrectLabel(assayOption, ASSAY.RHODIUM_ASSAY_H, ASSAY.RHODIUM_ASSAY_B),
    suffix: ASSAY.PPM,
  },
];

const returnableOuncesFieldsConfig = assayOption => [
  {
    name: 'platinumReturnableOzF',
    type: 'number',
    label: setCorrectLabel(
      assayOption,
      ASSAY.PLATINUM_RETURNABLE_OZ_F,
      ASSAY.PLATINUM_RETURNABLE_OZ_A,
    ),
    suffix: ASSAY.TROY_OZ,
  },
  {
    name: 'palladiumReturnableOzF',
    type: 'number',
    label: setCorrectLabel(
      assayOption,
      ASSAY.PALLADIUM_RETURNABLE_OZ_F,
      ASSAY.PALLADIUM_RETURNABLE_OZ_A,
    ),
    suffix: ASSAY.TROY_OZ,
  },
  {
    name: 'rhodiumReturnableOzF',
    type: 'number',
    label: setCorrectLabel(
      assayOption,
      ASSAY.RHODIUM_RETURNABLE_OZ_F,
      ASSAY.RHODIUM_RETURNABLE_OZ_A,
    ),
    suffix: ASSAY.TROY_OZ,
  },
  {
    name: 'platinumReturnableOzH',
    type: 'number',
    label: setCorrectLabel(
      assayOption,
      ASSAY.PLATINUM_RETURNABLE_OZ_H,
      ASSAY.PLATINUM_RETURNABLE_OZ_B,
    ),
    suffix: ASSAY.TROY_OZ,
  },
  {
    name: 'palladiumReturnableOzH',
    type: 'number',
    label: setCorrectLabel(
      assayOption,
      ASSAY.PALLADIUM_RETURNABLE_OZ_H,
      ASSAY.PALLADIUM_RETURNABLE_OZ_B,
    ),
    suffix: ASSAY.TROY_OZ,
  },
  {
    name: 'rhodiumReturnableOzH',
    type: 'number',
    label: setCorrectLabel(
      assayOption,
      ASSAY.RHODIUM_RETURNABLE_OZ_H,
      ASSAY.RHODIUM_RETURNABLE_OZ_B,
    ),
    suffix: ASSAY.TROY_OZ,
  },
];

export { containedAssaysFieldsConfig, containedMetalsFieldsConfig, returnableOuncesFieldsConfig };
