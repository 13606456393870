import styled from 'styled-components';

import { media } from 'shared/helpers/styling/styling';

export const StyledFilters = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${media.largeTablet`
    justify-content: center;
  `}

  ${media.tablet`
    justify-content: center;
  `}

  ${media.phone`
    flex-direction: column;
    align-items: center;
  `}
`;

export const StyledFiltersItem = styled.div`
  width: 460px;

  ${media.phone`
    width: 250px;
  `}
`;
