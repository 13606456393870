import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getCommoditiesOverviewActionType = 'GET_COMMODITIES_OVERVIEW_LIST';
const getCommoditiesOverviewAction = () => ({
  type: getCommoditiesOverviewActionType,
});

const getCommoditiesOverviewSuccessActionType = `${getCommoditiesOverviewActionType}_SUCCESS`;
const getCommoditiesOverviewSuccessAction = data => ({
  type: getCommoditiesOverviewSuccessActionType,
  payload: {
    data,
  },
});

const getCommoditiesOverviewFailureActionType = `${getCommoditiesOverviewActionType}_FAILURE`;
const getCommoditiesOverviewFailureAction = () => ({
  type: getCommoditiesOverviewFailureActionType,
});

const clearCommoditiesOverviewListActionType = 'CLEAR_COMMODITIES_OVERVIEW_LIST';
const clearCommoditiesOverviewList = () => ({
  type: clearCommoditiesOverviewListActionType,
});

const getCommoditiesOverviewList = (lotId, limit) => dispatch => {
  dispatch(getCommoditiesOverviewAction());

  return axios
    .get(`${API_HOST_AUTH}/commodity/list/${lotId}/${limit}`)
    .then(({ data }) => {
      dispatch(getCommoditiesOverviewSuccessAction(data));
    })
    .catch(() => dispatch(getCommoditiesOverviewFailureAction()));
};

export {
  getCommoditiesOverviewActionType,
  getCommoditiesOverviewSuccessActionType,
  getCommoditiesOverviewFailureActionType,
  clearCommoditiesOverviewListActionType,
  getCommoditiesOverviewList,
  clearCommoditiesOverviewList,
};
