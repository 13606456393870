import { compose } from 'redux';

import { packageStatuses } from 'shared/helpers/constants/packages/packageType';
import { parsePkgToCompare } from 'shared/helpers/dataParsers/packages/parsePkgToCompare';

const isPkgEdited = (pkg1, pkg2) => Object.keys(pkg1).every(k => pkg2[k] === pkg1[k]);

const findPackageById = (packages, id) => packages.find(pkg => id === pkg._id);

const checkIfPackageExist = (packages, id) =>
  compose(parsePkgToCompare, findPackageById)(packages, id);

const getExistingPackages = (packages, allowEditedProp, packagesToCompare) =>
  packages
    .filter(pkg => pkg.status !== packageStatuses.deleted)
    .map(pkg => ({
      ...pkg,
      ...(allowEditedProp &&
        !pkg.edited && {
          edited:
            !pkg._id ||
            !isPkgEdited(parsePkgToCompare(pkg), checkIfPackageExist(packagesToCompare, pkg._id)),
        }),
    }));

export { isPkgEdited, findPackageById, getExistingPackages };
