import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const checkEmailActionType = 'CHECK_EMAIL';
const checkEmailAction = () => ({
  type: checkEmailActionType,
});

const checkEmailSuccessActionType = `${checkEmailActionType}_SUCCESS`;
const checkEmailSuccessAction = result => ({
  type: checkEmailSuccessActionType,
  payload: {
    result,
  },
});

const checkEmailFailureActionType = `${checkEmailActionType}_FAILURE`;
const checkEmailFailureAction = () => ({
  type: checkEmailFailureActionType,
});

const checkEmail = (email, match) => dispatch => {
  dispatch(checkEmailAction());

  return axios
    .post(`${API_HOST_AUTH}/users/validate/email`, {
      email,
      ...(match && { userId: match.params.userId }),
    })
    .then(res => {
      dispatch(checkEmailSuccessAction(res));

      return res.data;
    })
    .catch(err => {
      dispatch(checkEmailFailureAction());

      return Promise.reject(err.data);
    });
};

export {
  checkEmailActionType,
  checkEmailSuccessActionType,
  checkEmailFailureActionType,
  checkEmail,
};
