import { DetailsDateField } from 'shared/components/Details/DetailsDateField';
import { DetailsField } from 'shared/components/Details/DetailsField';
import { DetailsHumanizedField } from 'shared/components/Details/DetailsHumanizedField';
import { DetailsRadioField } from 'shared/components/Details/DetailsRadioFieldRedesign';
import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';

const lotDetailsFieldsConfig = lot => [
  {
    label: LOTS.SHORTENED_SENT_FROM_COMPANY_LOCATION,
    value: lot.sentFromCompanyLocation.name,
    icon: 'icon-home',
    id: 'sentFromCompanyLocation',
    FieldType: DetailsField,
  },
  {
    label: LOTS.STATUS_FOR_GLOBAL,
    value: lot.statusForGlobal,
    icon: 'icon-type',
    id: 'statusForGlobal',
    FieldType: DetailsHumanizedField,
  },
  {
    label: LOTS.PROCESS_UPON_DELIVERY,
    value: lot.processUponDelivery,
    icon: 'icon-process',
    id: 'processUponDelivery',
    FieldType: DetailsRadioField,
  },
  {
    label: LOTS.CUSTOMER_INSTRUCTIONS,
    value: lot.customerInstructions,
    icon: 'icon-document',
    id: 'customerInstructions',
    FieldType: DetailsField,
  },
  {
    label: LOTS.HEDGED_STATUS,
    value: lot.hedgedStatus,
    icon: 'icon-type',
    id: 'hedgedStatus',
    FieldType: DetailsHumanizedField,
  },
  {
    label: LOTS.HEDGED_LOT_DELIVERY_DEADLINE,
    value: lot.hedgedLotDeliveryDeadline,
    id: 'hedgedLotDeliveryDeadline',
    FieldType: DetailsDateField,
  },
];

const lotDetailsContainerConfig = () => ({
  header: LOTS.GENERAL_DETAILS,
  template: [
    'sentFromCompanyLocation sentFromCompanyLocation statusForGlobal statusForGlobal',
    'processUponDelivery hedgedStatus hedgedLotDeliveryDeadline customerInstructions',
  ],
});

export { lotDetailsFieldsConfig, lotDetailsContainerConfig };
