import {
  acceptForGradingType,
  acceptForGradingSuccessType,
  acceptForGradingFailureType,
} from 'actions/Lots/acceptForGrading';

const INITIAL_STATE = {
  isPending: false,
};

const acceptForGradingReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case acceptForGradingType:
      return {
        isPending: true,
      };
    case acceptForGradingSuccessType:
    case acceptForGradingFailureType:
      return {
        isPending: false,
      };
    default:
      return state;
  }
};

export { acceptForGradingReducer };
