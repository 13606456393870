const parseMultipleFiles = (files, parseFn) =>
  Object.entries(files).reduce(
    (acc, [name, value]) => ({
      ...acc,
      [name]: value.map(parseFn).filter(Boolean),
    }),
    {},
  );

const parseExistingFile = file => ({
  _id: file._id,
  name: file.originalName || file.name,
  type: file.mimeType || file.type,
  size: file.size,
  keyName: `${file.originalName || file.name}-${new Date().valueOf()}`,
});

const parseMultipleFilesInput = target => ({
  name: target.name,
  files: [...target.files].map(file => ({
    existingFile: parseExistingFile(file),
    fileToUpload: file,
  })),
});

const parseLotsFilesToSend = lotsFiles => ({
  files: parseMultipleFiles(lotsFiles.multipleFiles, file => file.fileToUpload),
  existingLotFiles: parseMultipleFiles(lotsFiles.multipleFiles, file => file.existingFile._id),
});

const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

export {
  parseMultipleFiles,
  parseExistingFile,
  toBase64,
  parseMultipleFilesInput,
  parseLotsFilesToSend,
};
