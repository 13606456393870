const { dateToTimestamp } = require('shared/helpers/parsers/date');

export const parseClientLotParams = params => ({
  includeAllCompanies: params.includeAllCompanies,
  companies: !params.includeAllCompanies ? params.companies.map(({ value }) => value) : undefined,
  includeAllLocations: params.includeAllLocations,
  companyLocations: !params.includeAllLocations
    ? params.companyLocations.map(({ value }) => value)
    : undefined,
  dateFrom: dateToTimestamp(params.dateFrom),
  dateTo: dateToTimestamp(params.dateTo),
});
