import {
  getAssignedLotsActionType,
  getAssignedLotsFailureActionType,
  getAssignedLotsSuccessActionType,
  clearAssignedLotsActionType,
} from 'actions/Lots/getAssignedLots';

const INITIAL_STATE = {
  isPending: false,
  data: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getAssignedLotsActionType:
      return {
        ...state,
        isPending: true,
      };
    case getAssignedLotsFailureActionType:
      return {
        data: [],
        isPending: false,
      };
    case getAssignedLotsSuccessActionType:
      return {
        data: action.payload.response,
        isPending: false,
      };
    case clearAssignedLotsActionType:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
