import styled from 'styled-components';

import { cssVar, media } from 'shared/helpers/styling/styling';

const PreferencesHeader = styled.div`
  display: grid;
  grid-template: auto / ${({ gridTemplate }) => gridTemplate};
  gap: 1px;
  grid-gap: 1px;

  h2 {
    background: ${cssVar('outerSpaceBrighter')};
    grid-area: 1 / 1 / 1 / -1;
    margin: 0;
    padding: 32px 47px;
    ${media.mobile`
    padding: 10px;
    text-align: center;
    font-size 1.7rem;`}
  }

  div {
    background: ${cssVar('limedSpruce')};
    font-weight: bold;
    text-align: center;
    padding: 32px 47px;
    ${media.mobile`padding: 10px;`}

    &:first-child {
      text-align: left;
    }
  }
`;

PreferencesHeader.defaultProps = {
  gridTemplate: '2fr 1fr 1fr 1fr',
};

export { PreferencesHeader };
