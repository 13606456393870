import { REQUEST_PAYMENT } from 'shared/helpers/constants/payments/requestPaymentConstants';
import { message } from 'shared/helpers/constants/poolAccounts/createPoolAccountsConstants';
import {
  getAssignedLotOunces,
  formatMaximumLotOunces,
} from 'shared/helpers/dataParsers/deliveries/getAssignedLotOunces';
import { parseSettlementMaximumOunces } from 'shared/helpers/dataParsers/settlements/parseSettlementMaximumOunces';
import { isGlobalUserType } from 'shared/helpers/matchers/isGlobalUserType';
import { safeParseFloat } from 'shared/helpers/parsers/number';
import { replaceEmpty } from 'shared/helpers/parsers/text';
import { commaValidator } from 'shared/helpers/validations/commaValidator';
import { FLOAT_REGEX_GEN } from 'shared/helpers/validations/regularExpressions';

import dateAdapter from 'utils/date/dateAdapter';
import { MomentAdapter } from 'utils/date/momentAdapter';

function validateSettlement(values, props) {
  const { formValues } = props;

  const error = {};

  const checkMaterial = () => {
    if (props.formValues?.applyBlanketHedges) {
      const {
        formValues: {
          applyBlanketHedges: { relatedLot },
        },
        maximumSettlementOunces,
      } = props;
      const settlementOunces = maximumSettlementOunces?.settlementOunces?.[relatedLot?.value];
      const available = parseSettlementMaximumOunces(settlementOunces);

      return (
        available &&
        formatMaximumLotOunces(
          available,
          getAssignedLotOunces([...(formValues?.applyBlanketHedges?.deliveries || [])]),
        )
      );
    }

    if (isGlobalUserType(props.auth.user.userType) && props.relatedCompany) {
      return {
        platinumOz: props.relatedCompany.platinumOz,
        palladiumOz: props.relatedCompany.palladiumOz,
        rhodiumOz: props.relatedCompany.rhodiumOz,
      };
    }

    if (
      (props.location.pathname.includes('settlement-mock-up') ||
        props.location.pathname.includes('settlement-for-lot')) &&
      props.maximumSettlementOunces.settlementOunces
    ) {
      const { settlementOunces } = props.maximumSettlementOunces;

      return (
        settlementOunces[values.relatedLot && values.relatedLot.value] && {
          platinumOz: settlementOunces[values.relatedLot.value].maximumPlatinumOz,
          palladiumOz: settlementOunces[values.relatedLot.value].maximumPalladiumOz,
          rhodiumOz: settlementOunces[values.relatedLot.value].maximumRhodiumOz,
        }
      );
    }

    if (
      props.location.pathname.includes('settlement-of-metal-pool') &&
      props.poolAccountsList.poolAccounts.docs
    ) {
      return {
        platinumOz: props.poolAccountsList.poolAccounts.docs[0].platinumOz,
        palladiumOz: props.poolAccountsList.poolAccounts.docs[0].palladiumOz,
        rhodiumOz: props.poolAccountsList.poolAccounts.docs[0].rhodiumOz,
      };
    }
  };

  if (!values.relatedCompany) {
    error.relatedCompany = message.RELATED_COMPANY_EMPTY;
  }

  if (!values.relatedLot) {
    error.relatedLot = message.RELATED_LOT_EMPTY;
  }

  if (replaceEmpty(values.platinumOz, '').length === 0) {
    error.platinumOz = message.PLATINUM_OZ_EMPTY;
  } else if (values.platinumOz.length > 10) {
    error.platinumOz = message.PLATINUM_OZ_FORMAT;
  } else if (!FLOAT_REGEX_GEN(3).test(values.platinumOz)) {
    error.platinumOz = message.PLATINUM_OZ_FORMAT;
  } else if (!props.allowNegativeValues && values.platinumOz < 0) {
    error.platinumOz = message.PLATINUM_OZ_NEGATIVE;
  } else if (
    !props.allowNegativeValues &&
    checkMaterial() &&
    safeParseFloat(values.platinumOz) > checkMaterial().platinumOz
  ) {
    error.platinumOz = message.PALLADIUM_OZ_LESS(checkMaterial().platinumOz);
  } else if (
    props.allowNegativeValues &&
    checkMaterial() &&
    checkMaterial().platinumOz < 0 &&
    safeParseFloat(values.platinumOz) !== safeParseFloat(checkMaterial().platinumOz)
  ) {
    error.platinumOz = message.PLATINUM_OZ_EQUAL(checkMaterial().platinumOz);
  } else if (
    props.allowNegativeValues &&
    checkMaterial() &&
    checkMaterial().platinumOz >= 0 &&
    safeParseFloat(values.platinumOz) > checkMaterial().platinumOz
  ) {
    error.platinumOz = message.PLATINUM_OZ_LESS(checkMaterial().platinumOz);
  } else if (
    props.allowNegativeValues &&
    checkMaterial() &&
    checkMaterial().platinumOz >= 0 &&
    values.platinumOz < 0
  ) {
    error.platinumOz = message.PLATINUM_OZ_NEGATIVE;
  }

  if (replaceEmpty(values.palladiumOz, '').length === 0) {
    error.palladiumOz = message.PALLADIUM_OZ_EMPTY;
  } else if (values.palladiumOz.length > 10) {
    error.palladiumOz = message.PALLADIUM_OZ_FORMAT;
  } else if (!FLOAT_REGEX_GEN(3).test(values.palladiumOz)) {
    error.palladiumOz = message.PALLADIUM_OZ_FORMAT;
  } else if (!props.allowNegativeValues && values.palladiumOz < 0) {
    error.palladiumOz = message.PALLADIUM_OZ_NEGATIVE;
  } else if (
    !props.allowNegativeValues &&
    checkMaterial() &&
    safeParseFloat(values.palladiumOz) > checkMaterial().palladiumOz
  ) {
    error.palladiumOz = message.PALLADIUM_OZ_LESS(checkMaterial().palladiumOz);
  } else if (
    props.allowNegativeValues &&
    checkMaterial() &&
    checkMaterial().palladiumOz < 0 &&
    safeParseFloat(values.palladiumOz) !== safeParseFloat(checkMaterial().palladiumOz)
  ) {
    error.palladiumOz = message.PALLADIUM_OZ_EQUAL(checkMaterial().palladiumOz);
  } else if (
    props.allowNegativeValues &&
    checkMaterial() &&
    checkMaterial().palladiumOz >= 0 &&
    safeParseFloat(values.palladiumOz) > checkMaterial().palladiumOz
  ) {
    error.palladiumOz = message.PALLADIUM_OZ_LESS(checkMaterial().palladiumOz);
  } else if (
    props.allowNegativeValues &&
    checkMaterial() &&
    checkMaterial().palladiumOz >= 0 &&
    values.palladiumOz < 0
  ) {
    error.palladiumOz = message.PALLADIUM_OZ_NEGATIVE;
  }

  if (replaceEmpty(values.rhodiumOz, '').length === 0) {
    error.rhodiumOz = message.RHODIUM_OZ_EMPTY;
  } else if (values.rhodiumOz.length > 10) {
    error.rhodiumOz = message.RHODIUM_OZ_FORMAT;
  } else if (!FLOAT_REGEX_GEN(3).test(values.rhodiumOz)) {
    error.rhodiumOz = message.RHODIUM_OZ_FORMAT;
  } else if (!props.allowNegativeValues && values.rhodiumOz < 0) {
    error.rhodiumOz = message.RHODIUM_OZ_NEGATIVE;
  } else if (
    !props.allowNegativeValues &&
    checkMaterial() &&
    safeParseFloat(values.rhodiumOz) > checkMaterial().rhodiumOz
  ) {
    error.rhodiumOz = message.RHODIUM_OZ_LESS(checkMaterial().rhodiumOz);
  } else if (
    props.allowNegativeValues &&
    checkMaterial() &&
    checkMaterial().rhodiumOz < 0 &&
    safeParseFloat(values.rhodiumOz) !== safeParseFloat(checkMaterial().rhodiumOz)
  ) {
    error.rhodiumOz = message.RHODIUM_OZ_EQUAL(checkMaterial().rhodiumOz);
  } else if (
    props.allowNegativeValues &&
    checkMaterial() &&
    checkMaterial().rhodiumOz >= 0 &&
    safeParseFloat(values.rhodiumOz) > checkMaterial().rhodiumOz
  ) {
    error.rhodiumOz = message.RHODIUM_OZ_LESS(checkMaterial().rhodiumOz);
  } else if (
    props.allowNegativeValues &&
    checkMaterial() &&
    checkMaterial().rhodiumOz >= 0 &&
    values.rhodiumOz < 0
  ) {
    error.rhodiumOz = message.RHODIUM_OZ_NEGATIVE;
  }

  if (
    !formValues?.applyBlanketHedges?.deliveries?.length &&
    +values.platinumOz === 0 &&
    +values.palladiumOz === 0 &&
    +values.rhodiumOz === 0
  ) {
    error.platinumOz = message.ALL_OUNCES_ZERO;
    error.palladiumOz = message.ALL_OUNCES_ZERO;
    error.rhodiumOz = message.ALL_OUNCES_ZERO;
  }

  if (values.requestPayment) {
    if (!values.fundsType) {
      error.fundsType = REQUEST_PAYMENT.FUNDS_TYPE_EMPTY;
    }

    if (!values.requestedFinalPaymentDate) {
      error.requestedFinalPaymentDate = REQUEST_PAYMENT.REQUESTED_FINAL_PAYMENT_DATE_EMPTY;
    } else if (
      dateAdapter(values.requestedFinalPaymentDate).isBefore(
        new MomentAdapter().subtract(1, 'd').startOf('d'),
      )
    ) {
      error.requestedFinalPaymentDate = REQUEST_PAYMENT.REQUESTED_FINAL_PAYMENT_DATE_TOO_LOW;
    }

    if (values.additionalInfo && values.additionalInfo.length > 250) {
      error.additionalInfo = REQUEST_PAYMENT.ADDITIONAL_INFO_INVALID;
    }
  }

  commaValidator(
    { platinumOz: values.platinumOz, palladiumOz: values.palladiumOz, rhodiumOz: values.rhodiumOz },
    error,
  );

  return error;
}

function validate(values, props) {
  const { settlements } = values;
  if (!settlements || !settlements.length) {
    return validateSettlement(values, props);
  }
  return {
    settlements: settlements.map(settlement => validateSettlement(settlement, props)),
  };
}

export { validate };
