import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { getIncomingShipmentsCompanies } from 'actions/Companies/getCompaniesForFilters';
import { getCompanyLocations } from 'actions/Locations/getCompanyLocations';
import {
  statusFilterAction,
  sentFromCompanyFilterAction,
  sentFromCompanyLocationFilterAction,
  estPickupDateToFilterAction,
  estPickupDateFromFilterAction,
  estDeliveryDateToFilterAction,
  estDeliveryDateFromFilterAction,
  shipmentsFiltersClearAction,
} from 'actions/Shipments/shipmentsFilters';
import { getShipmentsList, clearShipmentsListAction } from 'actions/Shipments/shipmentsList';

import { IncomingShipmentsList } from './IncomingShipmentsList';

const mapStateToProps = state => ({
  auth: state.auth,
  router: state.router,
  shipmentsFilters: state.shipmentsFilters,
  shipmentsList: state.shipmentsList,
  companyLocations: state.companyLocations,
  companyIncomingShipment: state.companiesForFilters,
});

const mapDispatchToProps = {
  getShipmentsList,
  getCompanyLocations,
  getIncomingShipmentsCompanies,
  clearShipmentsListAction,
  statusFilterAction,
  sentFromCompanyFilterAction,
  sentFromCompanyLocationFilterAction,
  estPickupDateToFilterAction,
  estPickupDateFromFilterAction,
  estDeliveryDateToFilterAction,
  estDeliveryDateFromFilterAction,
  shipmentsFiltersClearAction,
};

const ConnectedIncomingShipmentsList = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(IncomingShipmentsList),
);

export { ConnectedIncomingShipmentsList };
