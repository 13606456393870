import { statusesForGlobal } from 'shared/helpers/constants/lots/statusesForGlobal';
import { packageClasses } from 'shared/helpers/constants/packages/packageClasses';

export const adminUpdatePackageWeightAccess = ({ auth, statusForGlobal, packageClass }) => {
  const { updatePackageWeight } = auth.access.specialPermissions.processing;

  return (
    updatePackageWeight &&
    [
      statusesForGlobal.not_received,
      statusesForGlobal.partly_received,
      statusesForGlobal.received,
      statusesForGlobal.accepted_for_grading,
      statusesForGlobal.grading_completed,
      statusesForGlobal.ready_for_processing,
      statusesForGlobal.accepted_for_processing,
      statusesForGlobal.ready_for_mixing,
      statusesForGlobal.accepted_for_mixing,
      statusesForGlobal.ready_to_ship,
      ...(packageClass === packageClasses.post_mill
        ? [statusesForGlobal.shipped_from_global, statusesForGlobal.assay_results_received]
        : []),
    ].includes(statusForGlobal)
  );
};
