import styled, { css } from 'styled-components';

import { cssVar, pxToRem, media } from 'shared/helpers/styling/styling';

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  padding: 18px 18px 0;
  overflow: hidden;
  background-color: ${cssVar('limedSpruceCalendar')};

  ${media.smallDesktop`
    padding: 10px 10px 0;
  `}
`;

export const DropZone = styled.div`
  width: 100%;

  &:not(:empty) {
    margin-top: 15px;

    ${media.smallDesktop`
      margin-top: 10px;
    `}
  }
`;

export const DroppableWrapper = styled.div`
  width: calc(100% + 36px);

  ${({ isLabeled }) =>
    !isLabeled &&
    css`
      min-height: 20px;
    `}

  ${({ isDraggingOver }) =>
    isDraggingOver &&
    css`
      height: 100%;
      background-image: repeating-linear-gradient(
        -45deg,
        ${cssVar('limedSpruceDarker')},
        ${cssVar('limedSpruceDarker')} 15px,
        ${cssVar('outerSpaceTabs')} 15px,
        ${cssVar('outerSpaceTabs')} 28px
      );
    `}
`;

export const Header = styled.div`
  min-height: 56px;
  width: calc(100% + 36px);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  margin: -18px 0 0;
  padding: 5px 18px;
  background-color: ${cssVar('columnHeader')};
  color: ${cssVar('alabaster')};
  border-bottom: 1px solid ${cssVar('riverBedDarker')};

  ${media.smallDesktop`
    width: calc(100% + 20px);
    margin: -10px 0 0;
    padding: 5px 10px;
  `}
`;

export const Label = styled.div`
  font-size: ${pxToRem(14)};
  min-height: 56px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  margin: -1px 0;
  padding: 5px 18px;
  background-color: ${cssVar('columnLabel')};
  color: ${cssVar('alabaster')};
  border-bottom: 1px solid ${cssVar('riverBedDarker')};
  border-top: 1px solid ${cssVar('riverBedDarker')};

  ${({ isDropDisabled }) =>
    isDropDisabled &&
    css`
      opacity: 0.2;
    `}
`;

export const Title = styled.h2`
  margin: ${({ margin }) => margin || 0};
  font-size: ${pxToRem(14)};
  width: 100%;
`;

export const Subtitle = styled.h3`
  margin: ${({ margin }) => margin || '4px 0 0'};
  color: ${cssVar('whiteGRG')};
  font-size: ${pxToRem(12)};
  opacity: ${({ opacity }) => opacity || '50%'};
`;

export const Group = styled.div`
  width: 100%;
  padding: 0 18px;
`;

export const SelfDeliveryWrapper = styled.div`
  width: calc(100% + 36px);
  background: ${cssVar('gradingPos')};
  position: relative;
  margin: 0px 0 0px -18px;
  padding: 0 20px;
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid ${cssVar('riverBedDarker')};

  ${media.smallDesktop`
    width: calc(100% + 20px);
    margin: -10px 0 10px -10px;
    padding: 0 10px;
  `}
`;

export const SelfDelivery = styled.div`
  display: inline-flex;
  align-items: center;
  height: 38px;
  margin-right: 15px;
  color: ${cssVar('whiteGRG')};
  font-size: ${pxToRem(14)};
`;
