import { packageType } from 'shared/helpers/constants/packages/packageType';

const calculateAssignedCats = (packagesList = []) =>
  packagesList.map(pkg => ({
    ...pkg,
    ...(pkg.packageType.value === packageType.sample && {
      assignedCats: [...Array(Number(pkg.unitCount)).keys()],
    }),
  }));

export { calculateAssignedCats };
