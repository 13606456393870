import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const markAsResolvedActionType = 'MARK_AS_RESOLVED';
function markAsResolvedAction() {
  return {
    type: markAsResolvedActionType,
  };
}

const markAsResolvedSuccessActionType = 'MARK_AS_RESOLVED_SUCCESS';
function markAsResolvedSuccessAction(notification) {
  return {
    type: markAsResolvedSuccessActionType,
    payload: {
      notification,
    },
  };
}

const markAsResolvedFailureActionType = 'MARK_AS_RESOLVED_FAILURE';
function markAsResolvedFailureAction(errorMessage) {
  return {
    type: markAsResolvedFailureActionType,
    payload: {
      errorMessage,
    },
  };
}

function markAsResolved(notificationId) {
  return dispatch => {
    dispatch(markAsResolvedAction());

    return axios
      .put(`${API_HOST_AUTH}/notifications/${notificationId}/mark-as-resolved`)
      .then(res => {
        dispatch(markAsResolvedSuccessAction(res.data.notification));
        return res.data.message;
      })
      .catch(error => {
        dispatch(markAsResolvedFailureAction(error));
        return Promise.reject(error.data);
      });
  };
}

export {
  markAsResolvedActionType,
  markAsResolvedSuccessActionType,
  markAsResolvedFailureActionType,
  markAsResolved,
};
