const normalizeFormValues = values =>
  Object.entries(values).reduce(
    (acc, [name, value]) => ({
      ...acc,
      [name]: value && value.value ? value.value : value,
    }),
    {},
  );

export { normalizeFormValues };
