import { checkLotPrefix } from 'actions/Companies/checkLotPrefix';

import { COMPANY } from 'shared/helpers/constants/companies/companyConstants';
import { message } from 'shared/helpers/constants/companies/createCompanyConstants';
import { isAssayCompany, isGradingCompany } from 'shared/helpers/matchers/checkCompanyType';

const asyncValidate = (values, dispatch, props) => {
  const companyId = props.companyDetails ? props.companyDetails.company._id : undefined;
  const isAssayOrGrading =
    isAssayCompany(values.companyType.value) || isGradingCompany(values.companyType.value);
  const lotPrefix = isAssayOrGrading
    ? `${COMPANY.LOT_PREFIX_PREFIX}${values.lotPrefix}`
    : values.lotPrefix;

  return dispatch(checkLotPrefix(companyId, lotPrefix)).then(async res =>
    res ? Promise.reject({ lotPrefix: message.INVALID_LOT_PREFIX_UNIQUE }) : null,
  );
};

export { asyncValidate };
