import PropTypes from 'prop-types';
import * as React from 'react';

import { StyledProgressBar, StyledLabel } from './StyledProgressBar';

const ProgressBar = ({ now, label, width, barColor, progressBgColor }) => (
  <StyledProgressBar width={width} barColor={barColor} progressBgColor={progressBgColor}>
    <div className="progress">
      <div
        className="progress-bar"
        role="progressbar"
        style={{ width: `${now}%` }}
        aria-valuenow={now}
        aria-valuemin="0"
        aria-valuemax="100"
        data-testid="progress-bar"
      >
        {label && <StyledLabel>{label}</StyledLabel>}
      </div>
    </div>
  </StyledProgressBar>
);

ProgressBar.propTypes = {
  now: PropTypes.number,
  label: PropTypes.string,
  width: PropTypes.number,
  barColor: PropTypes.string,
  progressBgColor: PropTypes.string,
};

ProgressBar.defaultProps = {
  now: 0,
  label: '',
  width: 50,
  barColor: 'apple',
  progressBgColor: 'limedSpruceDetails',
};

export { ProgressBar };
