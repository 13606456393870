import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const deleteSelfDeliveryRequestType = 'DELETE_SELF_DELIVERY_REQUEST';
const deleteSelfDeliveryRequestAction = () => ({
  type: deleteSelfDeliveryRequestType,
});

const deleteSelfDeliveryRequestSuccessType = `${deleteSelfDeliveryRequestType}_SUCCESS`;
const deleteSelfDeliveryRequestSuccessAction = () => ({
  type: deleteSelfDeliveryRequestSuccessType,
});

const deleteSelfDeliveryRequestFailureType = `${deleteSelfDeliveryRequestType}_FAILURE`;
const deleteSelfDeliveryRequestFailureAction = () => ({
  type: deleteSelfDeliveryRequestFailureType,
});

const deleteSelfDeliveryRequest = sdRequest => dispatch => {
  dispatch(deleteSelfDeliveryRequestAction());

  return axios
    .delete(`${API_HOST_AUTH}/self-delivery-requests/${sdRequest}`)
    .then(res => {
      dispatch(deleteSelfDeliveryRequestSuccessAction());

      return res.data;
    })
    .catch(err => {
      dispatch(deleteSelfDeliveryRequestFailureAction());

      return Promise.reject(err.data);
    });
};

export {
  deleteSelfDeliveryRequestType,
  deleteSelfDeliveryRequestSuccessType,
  deleteSelfDeliveryRequestFailureType,
  deleteSelfDeliveryRequest,
};
