import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const addCountSheetType = 'ADD_COUNT_SHEET';
const addCountSheet = countSheet => ({
  type: addCountSheetType,
  payload: {
    countSheet,
  },
});

const clearCountSheetType = 'CLEAR_COUNT_SHEET';
const clearCountSheet = () => ({
  type: clearCountSheetType,
});

const uploadCountSheetType = 'UPLOAD_COUNT_SHEET';
const uploadCountSheetAction = () => ({
  type: uploadCountSheetType,
});

const uploadCountSheetSuccessType = `${uploadCountSheetType}_SUCCESS`;
const uploadCountSheetSuccessAction = () => ({
  type: uploadCountSheetSuccessType,
});

const uploadCountSheetFailureType = `${uploadCountSheetType}_FAILURE`;
const uploadCountSheetFailureAction = () => ({
  type: uploadCountSheetFailureType,
});

const uploadCountSheet =
  (lotId, { countSheet }) =>
  dispatch => {
    const data = new FormData();

    data.append('countSheet', countSheet);
    dispatch(uploadCountSheetAction());

    return axios
      .patch(`${API_HOST_AUTH}/lots/upload-count-sheet/${lotId}`, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then(res => {
        dispatch(uploadCountSheetSuccessAction());

        return res.data;
      })
      .catch(err => {
        dispatch(uploadCountSheetFailureAction());

        return Promise.reject(err.data);
      });
  };

export {
  addCountSheetType,
  clearCountSheetType,
  uploadCountSheetType,
  uploadCountSheetSuccessType,
  uploadCountSheetFailureType,
  addCountSheet,
  clearCountSheet,
  uploadCountSheet,
};
