import {
  getCfuActionSuccessType,
  getCfuActionType,
  getCfuFailureActionType,
} from '../../actions/Cfu/getCfu';

const INITIAL_STATE = {
  isPending: true,
  data: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getCfuActionType:
      return {
        isPending: true,
      };
    case getCfuActionSuccessType:
      return {
        isPending: false,
        data: action.payload,
      };
    case getCfuFailureActionType:
      return {
        isPending: false,
        data: null,
      };
    default:
      return state;
  }
};
