import { PROFIT_LOSS } from 'shared/helpers/constants/lots/profitLossConstants';
import { safeParseFloat } from 'shared/helpers/parsers/number';
import { replaceEmpty } from 'shared/helpers/parsers/text';
import { commaValidator } from 'shared/helpers/validations/commaValidator';
import { PRICE_REGEX } from 'shared/helpers/validations/regularExpressions';

const { ERRORS } = PROFIT_LOSS;

const VALUE_LIMIT = 12;

function validate({ adjustments }) {
  const errors = {};

  errors.adjustments = (adjustments || []).map(adjustment => {
    const adjustmentsErrors = {};

    if (replaceEmpty(adjustment.lotAdjustmentValue, '').length === 0) {
      adjustmentsErrors.lotAdjustmentValue = ERRORS.VALUE_EMPTY;
    } else if (
      !PRICE_REGEX.test(adjustment.lotAdjustmentValue) ||
      (adjustment.lotAdjustmentValue && adjustment.lotAdjustmentValue.length > VALUE_LIMIT)
    ) {
      adjustmentsErrors.lotAdjustmentValue = ERRORS.VALUE_INVALID;
    } else if (safeParseFloat(adjustment.lotAdjustmentValue) < 0) {
      adjustmentsErrors.lotAdjustmentValue = ERRORS.COMMODITY_VALUE_NEGATIVE;
    }

    if (replaceEmpty(adjustment.lotAdjustmentValue, '').length === 0) {
      adjustmentsErrors.lotAdjustmentValue = ERRORS.VALUE_EMPTY;
    } else if (
      !PRICE_REGEX.test(adjustment.lotAdjustmentValue) ||
      (adjustment.lotAdjustmentValue && adjustment.lotAdjustmentValue.length > VALUE_LIMIT)
    ) {
      adjustmentsErrors.lotAdjustmentValue = ERRORS.VALUE_INVALID;
    } else if (safeParseFloat(adjustment.lotAdjustmentValue) < 0) {
      adjustmentsErrors.lotAdjustmentValue = ERRORS.COMMODITY_VALUE_NEGATIVE;
    }

    if (!adjustment.lotAdjustmentDescription || adjustment.lotAdjustmentDescription.length > 50) {
      adjustmentsErrors.lotAdjustmentDescription = ERRORS.LOT_DESCRIPTION_INVALID;
    }

    if (!adjustment.lotAdjustmentAction) {
      adjustmentsErrors.lotAdjustmentAction = ERRORS.ACTION_EMPTY;
    }

    commaValidator(
      {
        lotAdjustmentValue: adjustment.lotAdjustmentValue,
      },
      errors,
    );

    return adjustmentsErrors;
  });

  return errors;
}

export { validate };
