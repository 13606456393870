import PropTypes from 'prop-types';
import * as React from 'react';
import ReactTooltip from 'react-tooltip';

import { Icon } from 'shared/components/Icon/Icon';
import { cssVar } from 'shared/helpers/styling/styling';

export const FieldDescription = ({ description, name }) => {
  return (
    <span data-tip="" data-for={name}>
      <Icon
        icon="icon-alert"
        width={35}
        height={35}
        margin="4px 0 0"
        color={cssVar('solidYellow')}
      />
      <ReactTooltip id={name} effect="solid" backgroundColor={cssVar('outerSpaceFormBg')}>
        {description}
      </ReactTooltip>
    </span>
  );
};

FieldDescription.propTypes = {
  description: PropTypes.string,
  name: PropTypes.string,
};
