import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const markAllResolvedAsReadType = 'MARK_ALL_RESOLVED_AS_READ';
const markAllResolvedAsReadAction = () => ({
  type: markAllResolvedAsReadType,
});

const markAllResolvedAsReadSuccessType = `${markAllResolvedAsReadType}_SUCCESS`;
const markAllResolvedAsReadSuccessAction = () => ({
  type: markAllResolvedAsReadSuccessType,
});

const markAllResolvedAsReadFailureType = `${markAllResolvedAsReadType}_FAILURE`;
const markAllResolvedAsReadFailureAction = () => ({
  type: markAllResolvedAsReadFailureType,
});

const markAllResolvedAsRead = () => dispatch => {
  dispatch(markAllResolvedAsReadAction());

  return axios
    .patch(`${API_HOST_AUTH}/notifications/mark-resolved-as-read`)
    .then(res => {
      dispatch(markAllResolvedAsReadSuccessAction());

      return res.data;
    })
    .catch(err => {
      dispatch(markAllResolvedAsReadFailureAction());

      return Promise.reject(err.data);
    });
};

export {
  markAllResolvedAsReadType,
  markAllResolvedAsReadSuccessType,
  markAllResolvedAsReadFailureType,
  markAllResolvedAsRead,
};
