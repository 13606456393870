import { updateDashboardPreferencesSuccessActionType } from 'actions/Users/dashboardPreferences';
import {
  loginActionType,
  loginFailureActionType,
  loginSuccessActionType,
  logoutActionType,
} from 'actions/shared/auth';

const INITIAL_STATE = {
  attempts: 0,
  user: null,
  access: null,
  isPending: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case logoutActionType:
      return {
        ...state,
        user: null,
        access: null,
      };
    case loginActionType:
      return {
        ...state,
        isPending: true,
      };
    case loginSuccessActionType:
      return {
        attempts: 0,
        user: action.payload.data.details,
        access: action.payload.data.access,
        isPending: false,
      };
    case loginFailureActionType:
      return {
        attempts: action.payload.attempts,
        user: null,
        access: null,
        isPending: false,
      };
    case updateDashboardPreferencesSuccessActionType:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};
