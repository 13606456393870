import axios from 'axios';

import { parseCalendarNoteData } from 'shared/helpers/dataParsers/shipments';

const API_HOST_AUTH = process.env.REACT_APP_API;

const createCalendarNoteActionType = 'CREATE_CALENDAR_NOTE';
const createCalendarNoteAction = () => ({
  type: createCalendarNoteActionType,
});

const createCalendarNoteSuccessActionType = `${createCalendarNoteActionType}_SUCCESS`;
const createCalendarNoteSuccessAction = data => ({
  type: createCalendarNoteSuccessActionType,
  payload: { data },
});

const createCalendarNoteFailureActionType = `${createCalendarNoteActionType}_FAILURE`;
const createCalendarNoteFailureAction = data => ({
  type: createCalendarNoteFailureActionType,
  payload: { data },
});

const clearCreatedCalendarNoteActionType = 'CLEAR_CREATED_CALENDAR_NOTE';
const clearCreatedCalendarNote = () => ({
  type: clearCreatedCalendarNoteActionType,
});

const createCalendarNote = calendarNoteData => async dispatch => {
  dispatch(createCalendarNoteAction());

  try {
    const { data } = await axios.post(
      `${API_HOST_AUTH}/calendar-notes`,
      parseCalendarNoteData(calendarNoteData),
    );
    dispatch(createCalendarNoteSuccessAction(data));

    return data;
  } catch (error) {
    dispatch(createCalendarNoteFailureAction());

    return Promise.reject(error.data);
  }
};

export {
  createCalendarNoteAction,
  createCalendarNoteActionType,
  createCalendarNoteSuccessAction,
  createCalendarNoteSuccessActionType,
  createCalendarNoteFailureAction,
  createCalendarNoteFailureActionType,
  clearCreatedCalendarNote,
  clearCreatedCalendarNoteActionType,
  createCalendarNote,
};
