import React from 'react';
import { Field, reduxForm } from 'redux-form';

import { SubmitButton } from 'shared/components/Buttons/SubmitButton/SubmitButton';
import { FieldDate } from 'shared/components/Fields';
import { PAYMENTS } from 'shared/helpers/constants/payments/paymentsConstants';

import dateAdapter from 'utils/date/dateAdapter';

import { StyledActions, StyledForm } from './StyledPaymentMarkAsPaidContent';

const validate = values => {
  const errors = {};

  if (!values.paidAt) {
    errors.paidAt = PAYMENTS.PAID_ON_MESSAGES.PAID_ON_EMPTY;
  } else if (dateAdapter(values.paidAt).isAfter(dateAdapter())) {
    errors.paidAt = PAYMENTS.PAID_ON_MESSAGES.PAID_ON_IN_FUTURE;
  }

  return errors;
};

const PaymentMarkAsPaidContentLayout = ({ handleSubmit, invalid, pristine }) => {
  return (
    <div className="PaymentMarkAsPaidContent">
      <h3>{PAYMENTS.PAID_ON_MESSAGES.PAID_ON_LABEL}</h3>
      <StyledForm noValidate onSubmit={handleSubmit}>
        <Field
          name="paidAt"
          component={FieldDate}
          label={PAYMENTS.PAID_ON}
          type="text"
          popperPlacement={'bottom-start'}
          icon="icon-month"
          field="required"
          maxDate={dateAdapter()}
        />
        <StyledActions>
          <SubmitButton
            id="markAsPaid"
            label={PAYMENTS.MARK_AS_PAID}
            disabled={invalid || pristine}
          />
        </StyledActions>
      </StyledForm>
    </div>
  );
};

const PaymentMarkAsPaidContent = reduxForm({
  form: 'PaymentMarkAsPaidForm',
  validate,
})(PaymentMarkAsPaidContentLayout);

export { PaymentMarkAsPaidContent, PaymentMarkAsPaidContentLayout };
