import React from 'react';
import { Field, reduxForm } from 'redux-form';

import { SubmitButton } from 'shared/components/Buttons/SubmitButton/SubmitButton';
import { FieldSelect } from 'shared/components/Fields';
import { hedgeTypes } from 'shared/helpers/constants/hedges/hedgeType';
import { HEDGES } from 'shared/helpers/constants/hedges/hedgesConstants';
import { message } from 'shared/helpers/constants/hedges/validateHedgesConstants';
import { SHARED } from 'shared/helpers/constants/sharedConstants';
import { mapArray } from 'shared/helpers/parsers/select';

import {
  StyledHedgesChangeTypeActions,
  StyledHedgesChangeTypeForm,
} from './StyledHedgesChangeType';

const validate = values => (!values.hedgeType ? { hedgeType: message.HEDGE_TYPE_EMPTY } : {});

const HedgesChangeTypeFormBase = ({ handleSubmit, invalid }) => {
  const availableValues = mapArray([hedgeTypes.custom, hedgeTypes.internal, hedgeTypes.standard]);

  return (
    <div className="HedgesChangeTypeForm">
      <h3>{HEDGES.CHANGE_TYPE}</h3>
      <StyledHedgesChangeTypeForm
        noValidate
        onSubmit={handleSubmit}
        className="HedgesChangeTypeForm-form"
      >
        <Field
          name="hedgeType"
          component={FieldSelect}
          options={availableValues}
          searchable={false}
          label={HEDGES.HEDGE_TYPE}
          icon="icon-box"
          field="required"
        />
        <StyledHedgesChangeTypeActions className="HedgesChangeTypeForm-form-actions">
          <SubmitButton id="markAsPaid" label={SHARED.SUBMIT} disabled={invalid} />
        </StyledHedgesChangeTypeActions>
      </StyledHedgesChangeTypeForm>
    </div>
  );
};

const HedgesChangeTypeForm = reduxForm({
  form: 'HedgesChangeTypeForm',
  validate,
})(HedgesChangeTypeFormBase);

export { HedgesChangeTypeForm, HedgesChangeTypeFormBase };
