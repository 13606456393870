import React from 'react';

import { Event } from './components/Event/Event';

function Timeline({ events, colorResolver, pathResolver, detailsFormatter }) {
  return (
    <>
      {events.map((event, index) => (
        <Event
          key={index}
          event={event}
          last={events.length - 1 === index}
          colorResolver={colorResolver}
          pathResolver={pathResolver}
          detailsFormatter={detailsFormatter}
        />
      ))}
    </>
  );
}

export { Timeline };
