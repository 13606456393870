import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { clearDeliveriesList, getDeliveriesList } from 'actions/Deliveries/deliveriesList';
import { showSnackbar } from 'actions/shared/snackbar';

import { ListCollapse } from 'shared/components/ListCollapse/ListCollapse';
import { OverviewList } from 'shared/components/OverviewList/OverviewList';
import {
  OverviewListSectionWrapper,
  OverviewListSection,
} from 'shared/components/OverviewList/OverviewListSection/OverviewListSection';
import { DELIVERIES } from 'shared/helpers/constants/deliveries/deliveriesConstants';
import {
  itemTemplate,
  itemsTemplateMobile,
  actionsTemplate,
} from 'shared/helpers/constants/deliveries/deliveriesTableConfig';
import { isTablet } from 'shared/helpers/constants/resolutionsConstants';

import { getBrowserWidth } from 'utils/responsive';

class DeliveriesListBase extends Component {
  state = {
    displayContent: true,
    windowWidth: getBrowserWidth(),
  };

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  getCommoditiesOverviewList = (page, limit) =>
    this.props.getDeliveriesList(this.props.match.params.id, limit);

  toggleContent = () => {
    const { deliveriesList } = this.props;

    deliveriesList.items &&
      deliveriesList.items.total &&
      this.setState(prevState => ({ displayContent: !prevState.displayContent }));
  };

  handleWindowSizeChange = () => {
    this.setState({ windowWidth: getBrowserWidth() });
  };

  render() {
    const { windowWidth, displayContent } = this.state;
    const { deliveriesList } = this.props;
    const total = deliveriesList.items?.total;

    return (
      <OverviewListSectionWrapper isOpen={displayContent} total={total}>
        <OverviewListSection isOpen={displayContent} total={total}>
          <div>
            <ListCollapse
              collapse={displayContent}
              toggleList={this.toggleContent}
              label={DELIVERIES.DELIVERIES}
            />
          </div>
        </OverviewListSection>
        <div>
          <OverviewList
            getList={this.getCommoditiesOverviewList}
            clearData={this.props.clearDeliveriesList}
            input={deliveriesList}
            itemsTemplate={itemTemplate}
            itemsTemplateMobile={itemsTemplateMobile}
            actionsTemplate={actionsTemplate}
            isMobile={windowWidth < isTablet}
            label={DELIVERIES.DELIVERIES}
          />
        </div>
      </OverviewListSectionWrapper>
    );
  }
}

const mapStateToProps = state => ({
  deliveriesList: state.deliveriesList,
});

const mapDispatchToProps = {
  showSnackbar,
  clearDeliveriesList,
  getDeliveriesList,
};

const DeliveriesList = withRouter(connect(mapStateToProps, mapDispatchToProps)(DeliveriesListBase));

export { DeliveriesList, DeliveriesListBase };
