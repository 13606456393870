import {
  createTermsActionType,
  createTermsFailureActionType,
  createTermsSuccessActionType,
} from 'actions/Terms/createTerms';

const INITIAL_STATE = {
  isPending: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case createTermsActionType:
      return {
        isPending: true,
      };
    case createTermsFailureActionType:
      return {
        isPending: false,
      };
    case createTermsSuccessActionType:
      return {
        isPending: false,
      };
    default:
      return state;
  }
};
