import { formatToDollarWithPrefix } from 'shared/helpers/parsers/formaters';

import { resolveVersion, versions } from 'utils/versioning';

const HEDGES = {
  GENERAL_DETAILS: 'General details',
  HEDGES: 'Hedges',
  ALL_HEDGES: 'All hedges',
  OVERVIEW: 'Overview',
  HEDGES_LIST: 'Hedges list',
  HEDGE: 'Hedge',
  HEDGES_FORM: 'HedgesForm',
  STATUS: 'Status',
  CREATE: 'Request hedge(s)',
  ADD_EXISTING_HEDGE: 'Add an existing hedge',
  EXISTING_HEDGES: 'Existing hedges',
  EXISTING_BLANKET_HEDGES: 'Existing unfilled blanket hedges',
  ADD: 'Add new hedge',
  REMOVE: 'Remove hedge',
  HEDGE_TYPE: 'Hedge type',
  HEDGE_NAME: 'Hedge name',
  HEDGE_TOTAL: 'Hedge total',
  TROY_OUNCES_HEDGED: 'Troy ounces hedged',
  TROY_OUNCES_REMAINING: 'Troy ounces remaining',
  OUNCES: 'Ounces',
  EST_UNITS_WEIGHT: 'Est. units/weight',
  HEDGE_PRICES: 'Hedge prices',
  HEDGE_PRICES_CUSTOMER: 'Hedge prices (cust.)',
  MATERIAL_TYPE: 'Material type',
  MATERIAL_UNITS: 'Canned units',
  MATERIAL_WEIGHT: 'Net weight',
  PLATINUM_OZ: 'Platinum',
  PALLADIUM_OZ: 'Palladium',
  RHODIUM_OZ: 'Rhodium',
  DELIVERY_DATE: 'Delivery date',
  DELIVERY_DATE_FROM: 'Delivery from date',
  DELIVERY_DATE_TO: 'Delivery to date',
  DELIVERY_DATE_CUSTOMER: 'Delivery date (cust.)',
  DELIVERY_DATE_GRG: `Delivery date ${resolveVersion({
    [versions.alpha]: 'ALP',
    [versions.global]: 'GRG',
    [versions.west]: 'GRG',
  })}`,
  RELATED_COMPANY: 'Related company',
  RELATED_LOT: 'Related lot',
  PLACED_ON_FROM: 'Placed on from date',
  PLACED_ON_TO: 'Placed on to date',
  PLACED_ON: 'Placed on',
  CUSTOMER_INSTRUCTIONS: 'Customer instructions',
  VIEW_HEDGE_ORDER: 'View hedge order',
  FOIL_CANNED_INFO: 'Please enter either the number of canned units or the net weight.',
  MARKET_HOURS_WARNING: ({ marketHours, marketDays, timezone }, module) =>
    `${module} can only be submitted during market hours: ${marketHours} ${timezone}, ${marketDays}`,
  MATERIAL_COUNT_MARKET_HOURS_WARNING: ({ marketHours, marketDays, timezone }) =>
    `A hedge cannot be placed at this time. Regular hedging hours are ${marketHours} ${timezone}, ${marketDays}. However, you may schedule a hedge for a future date and time by completing and submitting the hedge form.`,
  MATERIAL_COUNT_MARKET_HOLIDAYS_WARNING:
    'Today is a market holiday, therefore hedges cannot be processed. However, you may schedule a hedge for a future date and time by completing and submitting the hedge form.',
  MARKET_HOLIDAYS_WARNING: 'Today is a market holiday - hedging is unavailable or restricted.',
  HEDGE_ORDER_DOCUMENT: 'Hedge order document',
  ENTER_PRICES: 'Enter prices',
  ENTER_HEDGE_PRICES: (hedgeName = '-') => `Enter prices for hedge ${hedgeName}`,
  ENTER_CUSTOM_HEDGE_PRICES: (hedgeName = '-') => `Enter custom prices for hedge ${hedgeName}`,
  ENTER_CUSTOM_PRICES: 'Enter custom prices',
  ACTIONS: 'Actions',
  ACTIVITY: 'Activity',
  CREATED_BY: 'Created by',
  UPDATED_BY: 'Updated by',
  GLOBAL_DECISION_BY: 'Global decision by',
  DELETE_HEDGE: 'Delete hedge',
  CHANGE_TYPE: 'Change type',
  REASSIGN_HEDGE: 'Reassign hedge',
  ADMIN_TRANSFER_HEDGE: 'Transfer hedge (admin only)',
  CREATE_DOCUMENT: 'Create document',
  DOWNLOAD: 'download',
  APPROVE: 'Approve',
  DENY: 'Deny',
  OZ: 'oz',
  UNITS: 'units',
  LBS: 'lbs',
  PT: 'Pt',
  PD: 'Pd',
  RH: 'Rh',
  DATE_PLACED: 'Date placed',
  BLANKET_HEDGE: 'BH',
  MARKET_HOLIDAYS: {
    TITLE: 'Market holidays',
    DATE: 'Date',
    MONTH: 'Month',
    DAY: 'Day',
    YEAR: 'Year',
    CREATE: 'Add market holiday',
    DELETE: 'Delete market holiday',
    SELECT_YEAR: 'Select year',
    HOLIDAY_NAME: 'Holiday name',
    DATE_ADDED: 'Date added',
    ADDED_BY: 'Added by',
    MIN_YEAR: 2018,
  },
  TRUSTED_COMPANIES: {
    TITLE: 'Trusted companies',
    CREATE: 'Add trusted company',
    RELATED_COMPANY: 'Related company',
    STATUS: 'Status',
    COMPANY_NAME: 'Company name',
    COMPANY_TYPE: 'Company type',
    LOT_PREFIX: 'Lot prefix',
    DELETE: 'Delete market holiday',
    DATE_ADDED: 'Date added',
    ADDED_BY: 'Added by',
  },
  RECIPIENTS: {
    ADD: 'Add recipient',
    CHANGE: 'Add a hedge recipient',
    TITLE: 'Hedge recipients',
    EMAIL: 'Email',
    DATE_ADDED: 'Date added',
    ADDED_BY: 'Added by',
  },
  DEFAULT_PPMS: {
    TITLE: 'Default PPM values for calculating estimated hedge ounces',
    BUTTON_TITLE: 'Edit default PPMs',
    MONO_CANNED_DECANNED: 'Mono canned / Mono decanned',
    FORM_TITLE: 'Default PPMs',
    FOIL: 'Foil canned',
    SENSORS: 'Sensors',
    PLATINUM_ASSAY: 'Platinum assay',
    PALLADIUM_ASSAY: 'Palladium assay',
    RHODIUM_ASSAY: 'Rhodium assay',
    PPM: 'ppm',
  },
  HEDGE_LOTS: {
    TITLE: 'Hedge lots',
    SHIPMENT: index => `Shipment ${index + 1}`,
    LOT_NUMBER: 'Lot number',
    NET_LBS: 'Net lbs',
    MATERIAL: 'Material',
    EST_OZ: 'Est. oz',
    HEDGED_OZ: 'Hedged oz',
    HEDGES: 'Hedges',
    NO_OUTGOING_SHIPMENTS_FOUND: 'No outgoing shipments were found',
    ASSIGN_HEDGE: 'Assign hedge',
    REQUEST_HEDGE: 'Request hedge',
  },
  PLATINUM_PRICE_ACTUAL: 'Actual platinum price',
  PALLADIUM_PRICE_ACTUAL: 'Actual palladium price',
  RHODIUM_PRICE_ACTUAL: 'Actual rhodium price',
  PLATINUM_PRICE_CUST: 'Customer platinum price',
  PALLADIUM_PRICE_CUST: 'Customer palladium price',
  RHODIUM_PRICE_CUST: 'Customer rhodium price',
  ASSIGN_HEDGE_OUNCES: 'Assign hedge ounces',
  HEDGE_OUNCES: 'Hedge ounces',
  UPDATE_HEDGE_OUNCES: 'Update ounces',
  REQUEST_INDICATORS: 'Request indicators',
  TOTAL_VALUE_OF_UNFILLED: value =>
    `Total value of all unfilled hedges: ${formatToDollarWithPrefix(value) || ''}`,
  REQUEST_INDICATORS_MARKET_HOLIDAY:
    'Today is a market holiday and pricing indicators are not available.',
  REQUEST_INDICATORS_MARKET_HOURS: (marketHours, timezone, marketDays) =>
    `Indicators can only be requested during market hours: ${marketHours} ${timezone}, ${marketDays}.`,
  ENTER_SETTLED_OUNCES: 'Enter settlements',
  REQUEST_HEDGE_DATE: 'Requested hedge date',
  HEDGE_DATE_AND_TIME: 'Hedge date and time',
  HEDGE_DATE: 'Hedge date',
  HEDGE_TIME: 'Hedge time',
  REQUEST_NOW: 'Request now',
  SCHEDULE: 'Schedule',
  ADMIN_CHANGE_PRICES: 'Change prices (admin only)',
  ADMIN_CHANGE_CUSTOM_PRICES: 'Change custom prices (admin only)',
  CHANGE_HEDGE_PRICES: (hedgeName = '-') => `Change prices for hedge ${hedgeName}`,
  CHANGE_CUSTOM_HEDGE_PRICES: (hedgeName = '-') => `Change custom prices for hedge ${hedgeName}`,
  ADMIN_CHANGE_OUNCES: 'Change ounces (admin only)',
  MINIMUM: 'Minimum',
  CANCEL_HEDGE_ADMIN: 'Cancel hedge (admin only)',
  CANCEL_HEDGE: 'Cancel hedge',
  RELATED_HEDGE: 'Related hedge',
  TRANSFER_BLANKET_HEDGE_OUNCES: 'Transfer blanket hedge ounces',
  ADMIN_TRANSFER_BLANKET_HEDGE_OUNCES: 'Transfer ounces (admin only)',
};

export { HEDGES };
