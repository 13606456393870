import PropTypes from 'prop-types';
import { useRef, useEffect } from 'react';
import { connect } from 'react-redux';

import { hideSnackbar as hideSnackbarAction } from 'actions/shared/snackbar';

import { SnackbarContent } from '../SnackbarContent/SnackbarContent';

function SnackbarBase({ snackbar, hideSnackbar }) {
  const { snackbarOptions, snackbarMessage, snackbarOpened } = snackbar;
  const { icon, ...options } = snackbarOptions || {};
  const timerId = useRef(null);

  const getDismissTime = message => (message ? message.length * 150 : 0);

  useEffect(() => {
    timerId.current = setTimeout(hideSnackbar, getDismissTime(snackbarMessage));

    return () => clearTimeout(timerId.current);
  }, [hideSnackbar, snackbarMessage]);

  return (
    !!snackbarOpened && (
      <SnackbarContent
        options={options}
        icon={icon}
        snackbarMessage={snackbarMessage}
        onCancel={hideSnackbar}
      />
    )
  );
}

const mapStateToProps = ({ snackbar }) => ({ snackbar });

const mapDispatchToProps = {
  hideSnackbar: hideSnackbarAction,
};

const Snackbar = connect(mapStateToProps, mapDispatchToProps)(SnackbarBase);

Snackbar.propTypes = {
  hideSnackbar: PropTypes.func,
  snackbar: PropTypes.object,
};

export { Snackbar };
