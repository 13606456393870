import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import {
  termsDocumentAddedAction,
  termsDocumentClearAction,
  uploadTermsDocument,
} from 'actions/Terms/uploadTermsDocument';
import { showSnackbar } from 'actions/shared/snackbar';

import { ModalCustom } from 'components/ModalCustom/ModalCustom';

import { snackbarOptions } from 'shared/helpers/matchers/snackbar/snackbarOptions';

import { ConnectedTermsUploadSigned } from 'pages/Terms/components/TermsManagement/components/TermsUploadSigned/ConnectedTermsUploadSigned';

const Header = styled.h3`
  text-align: center;
`;

const TermsReuploadSignedLayout = ({
  open,
  toggle,
  title,
  termsDetails,
  termsUploadDocument,
  ...props
}) => {
  const resolveAction = result => {
    const messageType = snackbarOptions(result.messageType);
    props.showSnackbar(messageType, result.message);
  };

  const uploadFile = () => {
    if (termsUploadDocument.files) {
      props
        .uploadTermsDocument(termsDetails.terms._id, termsUploadDocument.files)
        .then(resolveAction)
        .then(toggle);
    }
  };

  return (
    <ModalCustom isOpen={open} modalClose={toggle}>
      <Header>{title}</Header>
      <ConnectedTermsUploadSigned closeModal={toggle} onSubmit={uploadFile} />
    </ModalCustom>
  );
};

const mapStateToProps = state => ({
  termsDetails: state.termsDetails,
  termsUploadDocument: state.termsUploadDocument,
});

const mapActionsToProps = {
  uploadTermsDocument,
  termsDocumentAddedAction,
  termsDocumentClearAction,
  showSnackbar,
};

const TermsReuploadSigned = connect(mapStateToProps, mapActionsToProps)(TermsReuploadSignedLayout);

export { TermsReuploadSignedLayout, TermsReuploadSigned };
