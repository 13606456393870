import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { deleteSelfDeliveryRequest as deleteAction } from 'actions/SelfDeliveryRequests/deleteSelfDeliveryRequest';
import { openModal } from 'actions/shared/modal';
import { showSnackbar } from 'actions/shared/snackbar';

import { deleteSelfDeliveryRequestModal } from 'shared/helpers/constants/modalConstants';
import {
  successOptions,
  dangerOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

function DeleteSelfDeliveryRequestLayout(props) {
  const deleteSelfDeliveryRequest = () => {
    props.openModal(deleteSelfDeliveryRequestModal, () => {
      props
        .deleteAction(props.id)
        .then(res => {
          props.showSnackbar(successOptions, res.message);
          props.history.push('/shipments/self-delivery-requests');
        })
        .catch(err => props.showSnackbar(dangerOptions, err.message));
    });
  };

  return props.children({ deleteSelfDeliveryRequest });
}

const mapDispatchToProps = {
  openModal,
  showSnackbar,
  deleteAction,
};

const DeleteSelfDeliveryRequest = withRouter(
  connect(null, mapDispatchToProps)(DeleteSelfDeliveryRequestLayout),
);

export { DeleteSelfDeliveryRequest, DeleteSelfDeliveryRequestLayout };
