import { dateFormat } from 'shared/helpers/parsers/date';

import dateAdapter from 'utils/date/dateAdapter';

import { HEDGES } from './hedgesConstants';

const itemsTemplate = [
  {
    label: HEDGES.RECIPIENTS.EMAIL,
    render: ({ email }) => email,
    className: 'recipientEmail',
  },
  {
    label: HEDGES.RECIPIENTS.DATE_ADDED,
    render: ({ createdAt }) => dateAdapter(createdAt).format(dateFormat),
    className: 'createdAt',
  },
  {
    label: HEDGES.RECIPIENTS.ADDED_BY,
    render: ({ createdBy: { firstName, lastName } }) => `${firstName} ${lastName}`,
    className: 'createdBy',
  },
];

const itemsTemplateMobile = [
  {
    label: HEDGES.RECIPIENTS.EMAIL,
    render: ({ email }) => email,
  },
];

const [, ...itemsDetailsTemplate] = itemsTemplate;

export { itemsTemplate, itemsTemplateMobile, itemsDetailsTemplate };
