import React from 'react';

import { StatusIndicator } from 'shared/components/StatusIndicator/StatusIndicator';
import { humanize, replaceEmpty } from 'shared/helpers/parsers/text';

import { resolveVersion, versions } from 'utils/versioning';

import { USER } from './userConstants';

const displayAccessLevel = resolveVersion({
  [versions.alpha]: true,
  [versions.global]: true,
  [versions.west]: true,
});

const MaterialCountRequired = () => (
  <span className="TableItem-withOpacity">{USER.MATERIAL_COUNT_REQUIRED}</span>
);

const itemsTemplate = [
  {
    label: USER.STATUS,
    render: ({ status }) => <StatusIndicator status={status} />,
  },
  {
    label: displayAccessLevel ? `${USER.USER_TYPE}/${USER.ACCESS_LEVEL}` : USER.USER_TYPE,
    render: ({ userType, accessLevel }) => (
      <>
        {humanize(userType)}
        {' user'}
        {displayAccessLevel && (
          <>
            <br />
            <span className="TableItem-access">-{humanize(accessLevel)}</span>
          </>
        )}
      </>
    ),
  },
  {
    label: USER.PERSON,
    render: ({ firstName, lastName }) => `${firstName} ${lastName}`,
    highlight: true,
  },
  {
    label: USER.TITLE,
    render: ({ title }) => replaceEmpty(title),
  },
  {
    label: USER.RELATED_COMPANY,
    render: ({ relatedCompany }) => [
      relatedCompany.companyName,
      relatedCompany.materialCountRequired && <MaterialCountRequired />,
    ],
    highlight: true,
  },
  {
    label: USER.EMAIL,
    render: ({ email }) => email,
    highlight: true,
  },
];

const itemsTemplateCustomer = [
  {
    label: USER.STATUS,
    render: ({ status }) => <StatusIndicator status={status} />,
  },
  displayAccessLevel && {
    label: USER.ACCESS_LEVEL,
    render: ({ accessLevel }) => humanize(accessLevel),
  },
  {
    label: USER.PERSON,
    render: ({ firstName, lastName }) => `${firstName} ${lastName}`,
    highlight: true,
  },
  {
    label: USER.TITLE,
    render: ({ title }) => replaceEmpty(title),
  },
  {
    label: USER.LOCATION,
    render: ({ firstLocation }) => firstLocation && firstLocation.name,
    highlight: true,
  },
  {
    label: USER.DATABASE_ACCESS,
    render: ({ databaseAccess }) => {
      if (databaseAccess) {
        return 'Yes';
      }
      return 'No';
    },
  },
  {
    label: USER.PURCHASING_APP_ACCESS,
    render: ({ purchasingAppAccess }) => {
      if (purchasingAppAccess) {
        return 'Yes';
      }
      return 'No';
    },
    highlight: true,
  },
].filter(Boolean);

const itemsTemplateMobile = [
  {
    label: USER.STATUS,
    render: ({ status }) => <StatusIndicator status={status} />,
  },
  {
    label: USER.PERSON,
    render: ({ firstName, lastName }) => `${firstName} ${lastName}`,
    highlight: true,
  },
  {
    label: USER.RELATED_COMPANY,
    render: ({ relatedCompany }) => [
      relatedCompany.companyName,
      relatedCompany.materialCountRequired && <MaterialCountRequired />,
    ],
    highlight: true,
  },
];

const itemsTemplateMobileCustomer = [
  {
    label: USER.STATUS,
    render: ({ status }) => <StatusIndicator status={status} />,
  },
  {
    label: USER.PERSON,
    render: ({ firstName, lastName }) => `${firstName} ${lastName}`,
    highlight: true,
  },
];

const itemsDetailsTemplate = [
  {
    label: displayAccessLevel ? `${USER.USER_TYPE}/${USER.ACCESS_LEVEL}` : USER.USER_TYPE,
    render: ({ userType, accessLevel }) => (
      <>
        {humanize(userType)}
        {' user'}
        {displayAccessLevel && (
          <>
            <br />
            <span className="TableItem-access">-{humanize(accessLevel)}</span>
          </>
        )}
      </>
    ),
  },
  {
    label: USER.TITLE,
    render: ({ title }) => replaceEmpty(title),
  },
  {
    label: USER.EMAIL,
    render: ({ email }) => email,
    highlight: true,
  },
];

const itemsDetailsTemplateCustomer = [
  displayAccessLevel && {
    label: USER.ACCESS_LEVEL,
    render: ({ accessLevel }) => humanize(accessLevel),
  },
  {
    label: USER.TITLE,
    render: ({ title }) => replaceEmpty(title),
  },
  {
    label: USER.LOCATION,
    render: ({ firstLocation }) => firstLocation && firstLocation.name,
    highlight: true,
  },
  {
    label: USER.DATABASE_ACCESS,
    render: ({ databaseAccess }) => {
      if (databaseAccess) {
        return 'Yes';
      }
      return 'No';
    },
  },
  {
    label: USER.PURCHASING_APP_ACCESS,
    render: ({ purchasingAppAccess }) => {
      if (purchasingAppAccess) {
        return 'Yes';
      }
      return 'No';
    },
    highlight: true,
  },
].filter(Boolean);

export {
  itemsTemplateMobile,
  itemsTemplate,
  itemsTemplateMobileCustomer,
  itemsTemplateCustomer,
  itemsDetailsTemplate,
  itemsDetailsTemplateCustomer,
};
