import styled from 'styled-components';

import { FieldSelect } from 'shared/components/Fields/FieldSelect/FieldSelect';
import { cssVar, media, pxToRem } from 'shared/helpers/styling/styling';

export const StyledPermissionsRow = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 0.5fr;
  background-color: ${cssVar('fiord')};
  padding: ${pxToRem(0)} ${pxToRem(18)};
  color: ${cssVar('alabaster')};
  border-bottom: 2px solid ${cssVar('nevada')};
  opacity: ${({ isDisabled }) => (isDisabled ? `0.5` : '1')};

  &:hover {
    background-color: ${cssVar('cuttySarkTable')};
  }

  ${media.mobile`
    grid-template-columns: 1fr;
  `}
`;

export const StyledLabel = styled.h5`
  margin: 0;
  font-size: 12px;
  font-weight: 500;

  ${media.mobile`
    margin-top: ${pxToRem(26)};
  `}
`;

export const StyledFieldSelect = styled(FieldSelect)`
  display: block;
`;
