import { connect } from 'react-redux';

import {
  clearDashboardAction,
  getNotificationsOnDashboard,
  getNotificationsOnDashboardWithType,
} from 'actions/Dashboard/dashboardNotificationsLists';
import { markAsRead } from 'actions/Notifications/markAsRead';
import { getNotification } from 'actions/Notifications/notificationDetails';

import { NotificationsList } from './NotificationsList';

const mapStateToProps = ({
  auth,
  dashboardNotificationsList,
  notificationsCount,
  notificationDetails,
}) => ({
  auth,
  dashboardNotificationsList,
  notificationsCount,
  notificationDetails,
});
const mapDispatchToProps = {
  getNotificationsOnDashboard,
  getNotificationsOnDashboardWithType,
  clearDashboardAction,
  getNotification,
  markAsRead,
};
const ConnectedNotificationsList = connect(mapStateToProps, mapDispatchToProps)(NotificationsList);

export { ConnectedNotificationsList };
