import { createCalendarNoteReducer } from './createCalendarNote';
import { CreateShipmentReducer } from './createShipment';
import { DeleteCalendarNoteReducer } from './deleteCalendarNote';
import { DeleteShipmentReducer } from './deleteShipment';
import { IncomingShipmentsOverviewReducer } from './incomingShipmentsOverview';
import { ShipmentsOverviewReducer } from './lotShipmentsOverviewList';
import { MarkAsSentReducer } from './markAsSent';
import { OutgoingShipmentsOverviewReducer } from './outgoingShipmentsOverview';
import { ShipmentDetailsReducer } from './shipmentDetails';
import { ShipmentPackagesListReducer } from './shipmentPackagesList';
import { ShipmentsCalendarReducer } from './shipmentsCalendar';
import { ShipmentsFiltersReducer } from './shipmentsFilters';
import { ShipmentsListReducer } from './shipmentsList';
import { UpdateShipmentReducer } from './updateShipment';

const shipmentsRootReducer = {
  createShipment: CreateShipmentReducer,
  createShipmentData: CreateShipmentReducer,
  deleteShipment: DeleteShipmentReducer,
  markAsSent: MarkAsSentReducer,
  shipmentDetails: ShipmentDetailsReducer,
  shipmentPackagesListData: ShipmentPackagesListReducer,
  shipmentsCalendar: ShipmentsCalendarReducer,
  shipmentsFilters: ShipmentsFiltersReducer,
  shipmentsList: ShipmentsListReducer,
  shipmentsOverviewList: ShipmentsOverviewReducer,
  updateShipment: UpdateShipmentReducer,
  incomingShipmentsOverview: IncomingShipmentsOverviewReducer,
  outgoingShipmentsOverview: OutgoingShipmentsOverviewReducer,
  createdCalendarNote: createCalendarNoteReducer,
  deleteCalendarNote: DeleteCalendarNoteReducer,
};

export { shipmentsRootReducer };
