import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { getCompaniesBySearchStr } from 'actions/Companies/filterCompanies';
import { getCompanyLocations } from 'actions/Locations/getCompanyLocations';
import {
  clearPackagesOverview,
  getPackagesUnsentWithShipments,
} from 'actions/Packages/packagesOverviewList';
import { clearShipmentDetailsAction, getShipment } from 'actions/Shipments/shipmentDetails';
import {
  clearShipmentPackagesList,
  shipmentPackagesList,
} from 'actions/Shipments/shipmentPackagesList';
import { updateIncomingShipment } from 'actions/Shipments/updateShipment';
import { openModal, closeModal } from 'actions/shared/modal';
import { showSnackbar } from 'actions/shared/snackbar';

import { UpdateIncomingShipment } from './UpdateIncomingShipment';

const mapStateToProps = state => ({
  auth: state.auth,
  companyLocations: state.companyLocations,
  modal: state.modal,
  packagesOverviewList: state.packagesOverviewList,
  shipmentDetails: state.shipmentDetails,
  shipmentPackagesListData: state.shipmentPackagesListData,
});

const mapDispatchToProps = {
  clearPackagesOverview,
  clearShipmentDetailsAction,
  clearShipmentPackagesList,
  closeModal,
  getCompaniesBySearchStr,
  getCompanyLocations,
  getPackagesUnsentWithShipments,
  getShipment,
  openModal,
  shipmentPackagesList,
  showSnackbar,
  updateIncomingShipment,
};

const ConnectedUpdateIncomingShipment = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UpdateIncomingShipment),
);

export { ConnectedUpdateIncomingShipment };
