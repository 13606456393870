import { Field, FormSection } from 'redux-form';
import styled from 'styled-components';

import { media } from 'shared/helpers/styling/styling';

export const StyledSearchForm = styled(FormSection)`
  display: flex;
  @media print {
    display: none;
  }
`;
export const StyledSearchFormInput = styled(Field)`
  ${media.phone`
width: 50%;
max-width: 50%;
`}

  ${media.mobile`
width: auto;
max-width: 100%;
`}
`;
