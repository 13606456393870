import React, { Component } from 'react';

import { FormContainer } from 'shared/components/Form';
import { shipmentDirections } from 'shared/helpers/constants/shipments/shipmentDirections';
import { SHIPMENTS } from 'shared/helpers/constants/shipments/shipmentsConstants';
import { snackbarOptions } from 'shared/helpers/matchers/snackbar/snackbarOptions';

import { goBackOrTo } from 'utils/history';

import { OutgoingShipmentForm } from '../OutgoingShipmentForm/OutgoingShipmentForm';

class UpdateOutgoingShipment extends Component {
  componentDidMount() {
    this.props
      .getShipment(this.props.match.params.id)
      .catch(() => this.props.history.push('/shipments'));
  }

  componentWillUnmount() {
    this.props.clearShipmentDetailsAction();
  }

  submit = values => {
    const updateObj = {
      ...values,
      id: this.props.shipmentDetails.shipment._id,
    };

    return this.props.updateOutgoingShipment(updateObj).then(this.resolveAction);
  };

  pathToRedirect = shipmentId => {
    const { location } = this.props;

    return location.pathname.includes(shipmentDirections.outgoing)
      ? `/shipments/outgoing/${shipmentId}`
      : `/shipments/${shipmentId}`;
  };

  resolveAction = result => {
    this.props.showSnackbar(snackbarOptions(result.messageType), result.message);
    goBackOrTo(this.pathToRedirect(result.id));
  };

  render() {
    const { shipment, isPending } = this.props.shipmentDetails;
    const initialValues = {
      relatedCompany: {
        label: this.props.auth.user.relatedCompany.companyName,
      },
    };

    return (
      <FormContainer header={SHIPMENTS.UPDATE_OUTGOING_SHIPMENT} loaded={shipment && !isPending}>
        <OutgoingShipmentForm
          {...this.props}
          onSubmit={this.submit}
          initialValues={initialValues}
          editMode
        />
      </FormContainer>
    );
  }
}

export { UpdateOutgoingShipment };
