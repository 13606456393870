import styled from 'styled-components';

import { media } from 'shared/helpers/styling/styling';

export const ModalContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 40px 16px 20px;
`;

export const Header = styled.h4`
  font-size: 1rem;
  font-weight: bold;
`;

export const Paragraph = styled.p`
  font-size: 0.875rem;
  color: var(--gullGray);
  font-weight: 400;
  text-align: center;
  padding-top: 0.5rem;
`;

export const ModalButtons = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 10px;
`;

export const StyledLotsIncomingTitle = styled.h2`
  margin: 1rem 0 1rem 0;

  ${media.phone`
        padding: 0 15px;
      `}
`;
export const StyledLotsIncoming = styled.div`
  &-lotNumber {
    min-width: 150px;

    ${media.phone`
  min-width: unset;
`}
  }

  .DraggableSection {
    & > div:first-child {
      grid-row-start: unset;
    }

    &-header {
      grid-row: 1 / 2;
    }

    &-list {
      height: 100%;
    }
  }
`;
