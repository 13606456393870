const INITIAL_STATE = {
  actions: [],
};

const maxSize = 50;
const actionsLogReducer = (state = INITIAL_STATE, action) => ({
  actions: [action.type, ...state.actions].slice(0, maxSize),
});

export { actionsLogReducer };
