import { hedgeStatus } from 'shared/helpers/constants/hedges/hedgeStatus';
import { hedgeTypes } from 'shared/helpers/constants/hedges/hedgeType';

const changeHedgeTypeAccess = (
  {
    access: {
      hedges: { changeType },
    },
  },
  hedge,
) => changeType && hedge.status === hedgeStatus.open && hedge.hedgeType !== hedgeTypes.blanket;

export { changeHedgeTypeAccess };
