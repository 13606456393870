import { statusesEnum } from 'shared/helpers/constants/filters/allStatuses';
import { statusesForGlobal } from 'shared/helpers/constants/lots/statusesForGlobal';
import { packageClasses } from 'shared/helpers/constants/packages/packageClasses';
import { packageStatuses } from 'shared/helpers/constants/packages/packageType';
import { isInternalCompany } from 'shared/helpers/matchers/checkCompanyType';
import { isGlobalRefiningGroup } from 'shared/helpers/matchers/isGlobalRefiningGroup';

const updatePackageAccess = (auth, pkg) => {
  const { companyType } = auth.user.relatedCompany;
  let internalUserCanEditPackage = true;

  if (pkg.packageClass !== 'incoming' && isInternalCompany(companyType)) {
    internalUserCanEditPackage = false;
  }

  return (
    auth.access.packages.update &&
    auth.user.status === statusesEnum.active &&
    pkg.status === packageStatuses.open &&
    internalUserCanEditPackage
  );
};

const checkUpdateWeightLotStatus = lotStatus =>
  [
    statusesForGlobal.accepted_for_processing,
    statusesForGlobal.ready_for_mixing,
    statusesForGlobal.accepted_for_mixing,
    statusesForGlobal.ready_to_ship,
    statusesForGlobal.shipped_from_global,
    statusesForGlobal.assay_results_received,
  ].includes(lotStatus);

const updateGlobalCreatedIncomingPackageAccess = (
  {
    user,
    access: {
      packages: { updateIncoming },
    },
  },
  pkg,
) => {
  const access = isGlobalRefiningGroup(pkg.companyName)
    ? updateIncoming.forGlobal
    : updateIncoming.forCustomer;

  return access && user.status === statusesEnum.active && pkg.status === packageStatuses.open;
};

const checkPackageStatus = (pkg, updateWeightClosePostMill) => {
  if (pkg.packageClass === packageClasses.incoming) {
    switch (pkg.status) {
      case packageStatuses.open:
      case packageStatuses.received:
        return true;
      case packageStatuses.inactive:
        return [
          statusesForGlobal.not_received,
          statusesForGlobal.partly_received,
          statusesForGlobal.received,
          statusesForGlobal.accepted_for_grading,
          statusesForGlobal.grading_completed,
          statusesForGlobal.ready_for_processing,
          statusesForGlobal.accepted_for_processing,
          statusesForGlobal.ready_for_mixing,
          statusesForGlobal.accepted_for_mixing,
          statusesForGlobal.ready_to_ship,
          statusesForGlobal.shipped_from_global,
          statusesForGlobal.assay_results_received,
        ].includes(pkg.assignedLot.statusForGlobal);

      default:
        return false;
    }
  }

  if (
    pkg.packageClass === packageClasses.post_mill &&
    pkg.status === packageStatuses.closed &&
    checkUpdateWeightLotStatus(pkg.assignedLot.statusForGlobal)
  ) {
    return updateWeightClosePostMill;
  }

  return pkg.status === packageStatuses.open;
};

const updatePackageWeightAccess = (
  {
    user,
    access: {
      packages: { updateWeight, updateWeightClosePostMill },
    },
  },
  pkg,
) =>
  updateWeight &&
  user.status === statusesEnum.active &&
  checkPackageStatus(pkg, updateWeightClosePostMill);

const deletePackageAccess = (auth, pkg) =>
  auth.access.packages.delete &&
  auth.user.status === statusesEnum.active &&
  (pkg.status === packageStatuses.open || pkg.packageClass === packageClasses.incoming);

export {
  updatePackageAccess,
  deletePackageAccess,
  updateGlobalCreatedIncomingPackageAccess,
  updatePackageWeightAccess,
  checkUpdateWeightLotStatus,
  checkPackageStatus,
};
