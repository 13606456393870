import React from 'react';

import { eventTypes } from 'shared/helpers/constants/lots/eventTypes';
import { humanizeCamelCase, humanize, replaceNameElements } from 'shared/helpers/parsers/text';

import { Container, Row } from './StyledDetails';

const printObjectData = (type, data, formatter) => {
  return Object.entries(data)
    .map(([key, value]) => {
      const { key: parsedKey, value: parsedValue } = formatter(type, { key, value });

      return {
        key,
        value,
        parsedKey,
        parsedValue,
      };
    })
    .filter(({ parsedValue }) => parsedValue !== null && parsedValue !== undefined);
};
const typesWithOriginalKeys = [eventTypes.received];

const parseTitle = (type, parsedKey) =>
  `${
    typesWithOriginalKeys.includes(type)
      ? parsedKey
      : replaceNameElements(humanizeCamelCase(parsedKey))
  }: `;

const Details = ({ type, data, formatter }) => {
  return (
    <Container>
      {printObjectData(type, data, formatter).map(({ key, value, parsedKey, parsedValue }) => (
        <Row key={`${key}-${value}`}>
          <span> {parseTitle(type, parsedKey)}</span>
          {parsedValue instanceof Object ? (
            printObjectData(type, parsedValue, formatter).map(
              ({ key, value, parsedKey, parsedValue }) => (
                <Row nestedRow key={`${key}-${value}`}>
                  <span>{`${replaceNameElements(humanizeCamelCase(parsedKey))}: `}</span>
                  <span>{humanize(String(parsedValue))}</span>
                </Row>
              ),
            )
          ) : (
            <span>{humanize(String(parsedValue))}</span>
          )}
        </Row>
      ))}
    </Container>
  );
};

export { Details };
