import axios from 'axios';
import { saveAs } from 'file-saver';

import { showSnackbar } from 'actions/shared/snackbar';

import { PACKAGES } from 'shared/helpers/constants/packages/packageConstants';
import { messageTypes } from 'shared/helpers/constants/snackbar/snackbarMessageTypes';
import {
  dangerOptions,
  successOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

const API_HOST_AUTH = process.env.REACT_APP_API;

const printLabRequestFormActionType = 'PRINT_LAB_REQUEST_FORM';
const printLabRequestFormsAction = () => ({
  type: printLabRequestFormActionType,
});

const printLabRequestFormSuccessActionType = `${printLabRequestFormActionType}_SUCCESS`;
const printLabRequestFormSuccess = () => ({
  type: printLabRequestFormSuccessActionType,
});

const printLabRequestFormFailureActionType = `${printLabRequestFormActionType}_FAILURE`;
const printLabRequestFormFailure = () => ({
  type: printLabRequestFormFailureActionType,
});

const printLabRequestForms =
  (relatedType, relatedId, packages = [], printLabPolicy) =>
  dispatch => {
    dispatch(printLabRequestFormsAction());

    return axios
      .get(
        `${API_HOST_AUTH}/packages/print/lab-request-form/${relatedType}/${relatedId}/${packages.join()}`,
        {
          responseType: 'arraybuffer',
          params: { printLabPolicy },
        },
      )
      .then(res => {
        dispatch(printLabRequestFormSuccess());
        saveAs(
          new Blob([res.data], { type: res.headers['content-type'] }),
          'LaboratoryRequestForms.pdf',
        );
        dispatch(showSnackbar(successOptions, PACKAGES.PRINT_LAB_REQUEST_FORMS_SUCCESS));

        return {
          message: PACKAGES.PRINT_LAB_REQUEST_FORMS_SUCCESS,
          messageType: messageTypes.success,
        };
      })
      .catch(() => {
        dispatch(printLabRequestFormFailure());
        dispatch(showSnackbar(dangerOptions, PACKAGES.PRINT_LAB_REQUEST_FORMS_ERROR));

        return Promise.reject({
          message: PACKAGES.PRINT_LAB_REQUEST_FORMS_ERROR,
          messageType: messageTypes.error,
        });
      });
  };

const printSingleLabRequestForm = packageId => dispatch => {
  dispatch(printLabRequestFormsAction());

  return axios
    .get(`${API_HOST_AUTH}/packages/print/lab-request-form/${encodeURI(packageId)}`, {
      responseType: 'arraybuffer',
    })
    .then(res => {
      dispatch(printLabRequestFormSuccess());
      saveAs(
        new Blob([res.data], { type: res.headers['content-type'] }),
        'LaboratoryRequestForm.pdf',
      );
      dispatch(showSnackbar(successOptions, PACKAGES.PRINT_LAB_REQUEST_FORMS_SUCCESS));

      return {
        message: PACKAGES.PRINT_LAB_REQUEST_FORMS_SUCCESS,
        messageType: messageTypes.success,
      };
    })
    .catch(() => {
      dispatch(printLabRequestFormFailure());
      dispatch(showSnackbar(dangerOptions, PACKAGES.PRINT_LAB_REQUEST_FORMS_ERROR));

      return Promise.reject({
        message: PACKAGES.PRINT_LAB_REQUEST_FORMS_ERROR,
        messageType: messageTypes.error,
      });
    });
};

export {
  printSingleLabRequestForm,
  printLabRequestForms,
  printLabRequestFormsAction,
  printLabRequestFormSuccess,
  printLabRequestFormFailure,
  printLabRequestFormActionType,
  printLabRequestFormSuccessActionType,
  printLabRequestFormFailureActionType,
};
