import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { cssVar, pixelify } from 'shared/helpers/styling/styling';

const resolveColor = icon => {
  switch (icon) {
    case 'icon-bin':
      return cssVar('carnation');
    default:
      return cssVar('whiteGRG');
  }
};

const Icon = styled.i.attrs(({ icon }) => ({
  className: `icon ${icon}`,
}))`
  display: inline-block;
  mask-size: ${({ size }) => size} !important;
  ${({ margin }) => css`
    margin: ${margin};
  `};
  ${({ padding }) =>
    padding &&
    css`
      padding: ${padding};
    `};
  transition: 100ms ease-in;
  opacity: ${({ opacity }) => opacity};
  && {
    width: ${({ width }) => pixelify(width)};
    height: ${({ height }) => pixelify(height)};
    background-color: ${({ color, icon }) => color || resolveColor(icon)};
  }
`;

Icon.propTypes = {
  color: PropTypes.string,
  opacity: PropTypes.number,
  margin: PropTypes.string,
  padding: PropTypes.string,
  icon: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  size: PropTypes.oneOf(['auto', 'cover', 'contain']),
};

Icon.defaultProps = {
  width: 35,
  height: 35,
  size: 'auto',
};

export { Icon };
