import { useState, useEffect, useCallback } from 'react';

const useAsyncValidatorGuard = isPending => {
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    setValidated(!isPending);
  }, [isPending]);

  const onChange = useCallback(() => {
    setValidated(false);
  }, []);

  return [validated, onChange];
};

export { useAsyncValidatorGuard };
