import { SHARED } from '../sharedConstants';

const TERMS = {
  GENERAL_DETAILS: 'General details',
  ACTIVITY: 'Activity',
  TERM: 'Term',
  TERMS: 'Terms',
  ALL_TERMS: 'All terms',
  CREATE_TERM: 'Create terms',
  CREATE_CERAMIC_TERM: `Create ceramic${SHARED.NON_BREAKING_SPACE}terms`,
  CREATE_FOIL_TERM: `Create foil${SHARED.NON_BREAKING_SPACE}terms`,
  CREATE_O2SENSOR_TERM: `Create O2${SHARED.NON_BREAKING_SPACE}sensor${SHARED.NON_BREAKING_SPACE}terms`,
  CREATE_DIESEL_TERMS: `Create diesel${SHARED.NON_BREAKING_SPACE}terms`,
  EDIT_CERAMIC_TERMS: `Edit ceramic${SHARED.NON_BREAKING_SPACE}terms`,
  EDIT_FOIL_TERMS: `Edit foil${SHARED.NON_BREAKING_SPACE}terms`,
  EDIT_O2SENSOR_TERMS: `Edit O2${SHARED.NON_BREAKING_SPACE}sensor${SHARED.NON_BREAKING_SPACE}terms`,
  EDIT_DIESEL_TERMS: `Edit diesel${SHARED.NON_BREAKING_SPACE}terms`,
  EDIT_TERMS: 'Edit terms',
  CHOOSE_TYPE: 'Choose type:',
  CERAMIC: 'Ceramic',
  FOIL: 'Foil',
  O2: 'O2 sensor',
  DIESEL: 'Diesel',
  STATUS: 'Status',
  MATERIAL_TYPE: 'Material type',
  START_DATE: 'Start date',
  END_DATE: 'End date',
  DIGITAL_SIGNATURE_REQUIRED: 'Digital signature required',
  ACTUAL_EXPIRATION_DATE: 'Actual expiration date',
  DOCUMENT_LINK: 'Document link',
  TERMS_DOCUMENT: 'Terms document',
  DETAILS: 'Details',
  CREATED_BY: 'Created by',
  UPDATED_BY: 'Updated by',
  RELATED_COMPANY: 'Related company',
  ACTIONS: 'Actions',
  TREATMENT_CHARGE_CERAMIC: 'Treatment charge per pound decanned',
  TREATMENT_CHARGE_FOIL: 'Treatment charge per pound canned',
  TREATMENT_CHARGE_O2SENSOR: 'Treatment charge per pound',
  SMALL_LOT_CHARGE: 'Small lot charge',
  LARGE_LOT_CHARGE: 'Large lot charge',
  PLATINUM: 'Platinum',
  PLATINUM_RETURN_RATE: 'Platinum return rate',
  PLATINUM_REFINING_CHARGE: 'Platinum refining charge per troy ounce',
  PALLADIUM: 'Palladium',
  PALLADIUM_RETURN_RATE: 'Palladium return rate',
  PALLADIUM_REFINING_CHARGE: 'Palladium refining charge per troy ounce',
  RHODIUM: 'Rhodium',
  RHODIUM_RETURN_RATE: 'Rhodium return rate',
  RHODIUM_REFINING_CHARGE: 'Rhodium refining charge per troy ounce',
  ADVANCE_RATE: 'Advance rate',
  METALS_RETURN_TERMIN_DAYS: 'Metals return term in days',
  METALS_RETURN_FINANCE_RATE: 'Metals return finance rate',
  SUBMITTED_BY: 'Submitted by',
  GLOBAL_DECISION_BY: 'Global decision by',
  CUSTOMER_DECISION_BY: 'Customer decision by',
  GLOBAL_SIGNED_ON: 'Global signed on',
  PALLADIUM_RETURN_TERM_IN_DAYS: 'Palladium return term in days',
  PALLADIUM_RETURN_FINANCE_RATE: 'Palladium return finance rate',
  RHODIUM_RETURN_TERM_IN_DAYS: 'Rhodium return term in days',
  RHODIUM_RETURN_FINANCE_RATE: 'Rhodium return finance rate',
  PLATINUM_RETURN_TERM_IN_DAYS: 'Platinum return term in days',
  PLATINUM_RETURN_FINANCE_RATE: 'Platinum return finance rate',
  REUPLOAD_SIGNED: 'Reupload signed document',
  SUBMIT: 'Submit for approval',
  SUBMIT_ERROR: 'There was error during submiting terms',
  SUBMIT_ERROR_INVALID_DATA: 'Duration of the terms is invalid. Please edit draft.',
  SUBMIT_ERROR_CONTACT:
    'The related company has no primary contact. Contact support to fix appropriate settings.',
  SUBMIT_ERROR_ADVANCE_RATE: 'Please edit draft to review current advance rate.',
  UPLOAD: 'Upload',
  UPLOAD_SIGNED: 'Upload signed document',
  UPLOAD_SIGNED_PROGRESS: 'Uploading signed document.',
  DOCUMENT_DOWNLOAD: 'download',
  APPROVE: 'Approve',
  DENY: 'Deny',
  RETURN_RATES: 'Return rates',
  DATES: 'Dates',
  DIGITAL_SIGNATURE: 'Digital signature',
  SUBMIT_ERROR_STATUS: 'Terms have to be in draft status to submit for approval.',
};

export { TERMS };
