import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Button } from 'shared/components/Buttons';
import { MANUAL_DOWNLOAD } from 'shared/helpers/constants/manualDownload/manualDownloadConstants';

import { FormWrapper } from 'pages/ResetPassword/components/FormWrapper/FormWrapper';

import { StyledLink, StyledWrapper } from './StyledManualDownload';

function ManualDownload() {
  const { search } = useLocation();
  const { goBack, push } = useHistory();
  const params = new URLSearchParams(search.substring(search.indexOf('?')));
  const link = params.get('link');

  useEffect(() => {
    if (!link) {
      push('/');
    }
  }, []);

  return (
    <FormWrapper>
      <StyledWrapper>
        <StyledLink href={link}>
          <Button>{MANUAL_DOWNLOAD.MANUALLY_DOWNLOAD_FILE}</Button>
        </StyledLink>
        <Button onClick={() => goBack()}>{MANUAL_DOWNLOAD.GO_BACK}</Button>
      </StyledWrapper>
    </FormWrapper>
  );
}

export { ManualDownload };
