import { packageClasses } from 'shared/helpers/constants/packages/packageClasses';

const packageNumberLabel = pkg => {
  switch (pkg.packageClass) {
    case packageClasses.post_mill:
      return `${pkg.packageNumber} of ${pkg.packageCountWarehouse}`;
    case packageClasses.post_mix:
    case packageClasses.post_shears:
      return `${pkg.packageNumber} of ${pkg.packageCountOutgoing}`;
    default:
      return `${pkg.packageNumber} of ${pkg.packageCountIncoming}`;
  }
};

export { packageNumberLabel };
