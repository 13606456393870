import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const marketAvailabilityActionType = 'MARKET_AVAILABILITY';

const marketAvailabilityAction = marketHolidays => ({
  type: marketAvailabilityActionType,
});

const marketAvailabilitySuccessActionType = `${marketAvailabilityActionType}_SUCCESS`;
const marketAvailabilitySuccessAction = marketHolidays => ({
  type: marketAvailabilitySuccessActionType,
  payload: marketHolidays,
});

const marketAvailabilityFailureActionType = `${marketAvailabilityActionType}_FAILURE`;
const marketAvailabilityFailureAction = () => ({
  type: marketAvailabilityFailureActionType,
});

const marketAvailability = allowCached => (dispatch, getState) => {
  const { marketAvailabilityDetails } = getState();

  if (!allowCached || marketAvailabilityDetails.validUntil < Date.now()) {
    dispatch(marketAvailabilityAction());
    return axios
      .get(`${API_HOST_AUTH}/market-holidays/check-today`)
      .then(({ data }) => {
        dispatch(marketAvailabilitySuccessAction(data));
        return data;
      })
      .catch(() => dispatch(marketAvailabilityFailureAction()));
  }

  return marketAvailabilityDetails;
};

export {
  marketAvailability,
  marketAvailabilityActionType,
  marketAvailabilitySuccessActionType,
  marketAvailabilityFailureActionType,
};
