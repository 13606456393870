import { message } from 'shared/helpers/constants/companies/createCompanyConstants';
import { FILES_CONSTANTS } from 'shared/helpers/constants/filesConstants';
import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';
import {
  URL_REGEX,
  CAPITAL_ALPHANUM_REGEX,
  VALIDATE_UTF_8,
} from 'shared/helpers/validations/regularExpressions';

import { validateAttachment } from '../attachment/validateAttachment';
import { validate as notesValidate } from '../notes/notesValidations';

function validate(values, props) {
  const files = props.companyFiles?.files;
  const errors = {};
  const companyType = values.companyType && values.companyType.value;
  const lotPrefixMaxLength = 6;

  if (!values.companyName || !values.companyName.trim()) {
    errors.companyName = message.INVALID_COMPANY_NAME_EMPTY;
  } else if (values.companyName.length > 100) {
    errors.companyName = message.INVALID_COMPANY_NAME;
  }

  if (!values.lotPrefix || !values.lotPrefix.trim()) {
    errors.lotPrefix = message.INVALID_LOT_PREFIX_EMPTY;
  } else if (values.lotPrefix.length > lotPrefixMaxLength) {
    errors.lotPrefix = message.INVALID_LOT_PREFIX;
  } else if (!CAPITAL_ALPHANUM_REGEX.test(values.lotPrefix)) {
    errors.lotPrefix = message.INVALID_LOT_PREFIX;
  }

  if (!values.volume) {
    errors.volume = '';
  } else if (values.volume.length > 100) {
    errors.volume = message.INVALID_VOLUME;
  }

  if (!values.referralMethod) {
    errors.referralMethod = '';
  } else if (values.referralMethod.length > 100) {
    errors.referralMethod = message.INVALID_REFERRAL_METHOD;
  }

  if (values.materialCountRequired === undefined || values.materialCountRequired === '') {
    errors.materialCountRequired = message.INVALID_MATERIAL_COUNT_REQUIRED_EMPTY;
  }

  if (values.samplesAllowed === undefined || values.samplesAllowed === '') {
    errors.samplesAllowed = message.INVALID_SAMPLES_ALLOWED_EMPTY;
  }

  if (values.onlyInternalHedgingAndSettlement === undefined) {
    errors.onlyInternalHedgingAndSettlement =
      message.INVALID_ONLY_INTERNAL_HEDGING_SETTLEMENT_EMPTY;
  }

  if (!companyType) {
    errors.companyType = message.INVALID_COMPANY_TYPE;
  }

  if (!values.purchasingAppURL) {
    errors.purchasingAppURL = '';
  } else if (!URL_REGEX.test(values.purchasingAppURL)) {
    errors.purchasingAppURL = message.INVALID_PURCHASING_APP_URL_REGEX;
  } else if (values.purchasingAppURL.length > 100) {
    errors.purchasingAppURL = message.INVALID_PURCHASING_APP_URL;
  }

  if (files?.photoId?.length > 0 && !values.photoIdExpiresAt) {
    errors.photoIdExpiresAt = message.INVALID_PHOTO_ID_EXPIRES;
  }

  if (files?.documentForAML?.length > 0 && !values.documentForAMLExpiresAt) {
    errors.documentForAMLExpiresAt = message.INVALID_AML_EXPIRES;
  }

  if (files?.businessLicense?.length > 0 && !values.businessLicenseExpiresAt) {
    errors.businessLicenseExpiresAt = message.INVALID_BUSINESS_LICENSE_EXPIRES;
  }

  if (files) {
    if (files?.photoId.length) {
      const { VALID_EXTENSIONS } = FILES_CONSTANTS;

      if (
        !validateAttachment(files?.photoId[0], [
          VALID_EXTENSIONS.jpg,
          VALID_EXTENSIONS.jpeg,
          VALID_EXTENSIONS.pdf,
          VALID_EXTENSIONS.png,
        ])
      ) {
        errors.photoId = LOTS.ERRORS.INVALID_FILE_FORMAT;
      }

      if (!VALIDATE_UTF_8().test(files?.photoId[0].name)) {
        errors.photoId = LOTS.ERRORS.INVALID_FILE_NAME;
      }

      if (files?.photoId[0].size > FILES_CONSTANTS.MAX_FILE_SIZE) {
        errors.photoId = LOTS.ERRORS.INVALID_SIZE;
      }
    }

    if (files?.documentForAML.length) {
      const { VALID_EXTENSIONS } = FILES_CONSTANTS;

      if (
        !validateAttachment(files?.documentForAML[0], [
          VALID_EXTENSIONS.jpg,
          VALID_EXTENSIONS.jpeg,
          VALID_EXTENSIONS.pdf,
          VALID_EXTENSIONS.png,
        ])
      ) {
        errors.documentForAML = LOTS.ERRORS.INVALID_FILE_FORMAT;
      }

      if (!VALIDATE_UTF_8().test(files?.documentForAML[0].name)) {
        errors.documentForAML = LOTS.ERRORS.INVALID_FILE_NAME;
      }

      if (files?.documentForAML[0].size > FILES_CONSTANTS.MAX_FILE_SIZE) {
        errors.documentForAML = LOTS.ERRORS.INVALID_SIZE;
      }
    }

    if (files?.businessLicense.length) {
      const { VALID_EXTENSIONS } = FILES_CONSTANTS;

      if (
        !validateAttachment(files?.businessLicense[0], [
          VALID_EXTENSIONS.jpg,
          VALID_EXTENSIONS.jpeg,
          VALID_EXTENSIONS.pdf,
          VALID_EXTENSIONS.png,
        ])
      ) {
        errors.businessLicense = LOTS.ERRORS.INVALID_FILE_FORMAT;
      }

      if (!VALIDATE_UTF_8().test(files?.businessLicense[0].name)) {
        errors.businessLicense = LOTS.ERRORS.INVALID_FILE_NAME;
      }

      if (files?.businessLicense[0].size > FILES_CONSTANTS.MAX_FILE_SIZE) {
        errors.businessLicense = LOTS.ERRORS.INVALID_SIZE;
      }
    }

    if (files?.materialSourcingAgreement.length) {
      const { VALID_EXTENSIONS } = FILES_CONSTANTS;

      if (
        !validateAttachment(files?.materialSourcingAgreement[0], [
          VALID_EXTENSIONS.jpg,
          VALID_EXTENSIONS.jpeg,
          VALID_EXTENSIONS.pdf,
          VALID_EXTENSIONS.png,
        ])
      ) {
        errors.materialSourcingAgreement = LOTS.ERRORS.INVALID_FILE_FORMAT;
      }

      if (!VALIDATE_UTF_8().test(files?.materialSourcingAgreement[0].name)) {
        errors.materialSourcingAgreement = LOTS.ERRORS.INVALID_FILE_NAME;
      }

      if (files?.materialSourcingAgreement[0].size > FILES_CONSTANTS.MAX_FILE_SIZE) {
        errors.materialSourcingAgreement = LOTS.ERRORS.INVALID_SIZE;
      }
    }
  }
  return {
    ...errors,
    ...notesValidate(values),
  };
}

export { validate };
