import {
  generateProfitLossActionType,
  generateProfitLossFailureActionType,
  generateProfitLossSuccessActionType,
} from 'actions/Lots/generateProfitLoss';

const INITIAL_STATE = {
  isPending: false,
};

const generateProfitLossReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case generateProfitLossActionType:
      return {
        isPending: true,
      };
    case generateProfitLossSuccessActionType:
    case generateProfitLossFailureActionType:
      return {
        isPending: false,
      };
    default:
      return state;
  }
};

export { generateProfitLossReducer };
