import { Container } from 'reactstrap';

import { ConnectedBreadcrumbs } from 'components/Breadcrumbs/ConnectedBreadcrumbs';
import { Navbar } from 'components/Navbar/ConnectedNavbar';

import {
  getNavbarElements,
  hedgesNavbarElements,
  lotsNavbarElements,
  lotsNavbarElementsBuyer,
  paymentsNavbarElements,
  reportsNavbarElements,
  shipmentsNavbarElements,
} from 'shared/helpers/constants/navbarElements';
import { isBuyerUser, isCustomerUser } from 'shared/helpers/matchers/checkUserType';

import { history } from 'utils/history';
import { resolveVersion, versions } from 'utils/versioning';

const lotsRoutes = lotsNavbarElements.map(el => el.path);
const shipmentsRoutes = shipmentsNavbarElements.map(el => el.path);
const hedgesRoutes = hedgesNavbarElements.map(el => el.path);
const paymentsRoutes = paymentsNavbarElements.map(el => el.path);
const reportsRoutes = reportsNavbarElements.map(el => el.path);
const disabledBreadcrumbsRoutes = [
  '/[a-z\\d]+/calendar-notes',
  '/dashboard',
  '/my-profile',
  '/companies',
  '/users',
  '/lots',
  '/shipments',
  '/terms/[a-f\\d]+',
  '/locations/[a-f\\d]+',
  '/agreements/[a-f\\d]+',
  '/notifications',
  '/hedges',
  '/payments',
  '/pool-accounts',
  '/settings',
  '/reports',
  '/general-info',
];

const Navigation = ({ auth, isMobile }) => {
  const { pathname } = history.location;
  const additionalNavbarRoutes = (() => {
    if (pathname.includes('reports')) {
      return reportsNavbarElements;
    }
    if (pathname.includes('lots')) {
      if (isBuyerUser(auth.user.userType)) {
        return lotsNavbarElementsBuyer;
      }
      return lotsNavbarElements;
    }
    if (pathname.includes('shipments')) {
      return shipmentsNavbarElements;
    }
    if (pathname.includes('hedges')) {
      return hedgesNavbarElements;
    }
    if (pathname.includes('payments')) {
      return paymentsNavbarElements;
    }
  })();
  const isAdditionalNavbarPath =
    [
      ...lotsRoutes,
      ...shipmentsRoutes,
      ...hedgesRoutes,
      ...paymentsRoutes,
      ...reportsRoutes,
    ].includes(pathname) &&
    !isMobile &&
    !(
      isCustomerUser(auth.user.userType) &&
      (shipmentsRoutes.includes(pathname) ||
        hedgesRoutes.includes(pathname) ||
        paymentsRoutes.includes(pathname))
    );

  return (
    <div>
      {!isMobile && (
        <Navbar
          backgroundColor={resolveVersion({
            [versions.alpha]: 'fiordLighter',
            [versions.global]: 'fiordLighter',
            [versions.west]: 'lightGrayGRG',
          })}
          navbarElements={getNavbarElements(auth.user.userType)}
        />
      )}
      {isAdditionalNavbarPath ? (
        <Navbar
          navbarElements={additionalNavbarRoutes}
          backgroundColor={resolveVersion({
            [versions.alpha]: 'limedSpruceNav',
            [versions.global]: 'limedSpruceNav',
            [versions.west]: 'argent',
          })}
          subNavbar
        />
      ) : (
        !disabledBreadcrumbsRoutes.some(r => new RegExp(`^${r}$`).test(pathname)) && (
          <Container>
            <ConnectedBreadcrumbs />
          </Container>
        )
      )}
    </div>
  );
};

export { Navigation };
