import React from 'react';

import { UIAuthorization } from 'components/Authorization/UIAuthorization';

import { RouterButton } from 'shared/components/Buttons';
import { Button } from 'shared/components/Buttons/Button';
import { ExtendableButton } from 'shared/components/Buttons/ExtendableButton/ExtendableButton';
import { DetailsHeader } from 'shared/components/Details/DetailsHeader';
import { LocationChangeStatus } from 'shared/components/LocationChangeStatus/LocationChangeStatus';
import { editDefaultPPMsAccess } from 'shared/helpers/accesses/locations/editDefaultPPMsAccess';
import { LOCATION } from 'shared/helpers/constants/locations/locationConstants';

function MainLocationDetails({ location, access, user }) {
  return (
    <DetailsHeader header={location.name} status={location.status}>
      <ExtendableButton label={LOCATION.ACTIONS} closeOnClick>
        <UIAuthorization access={access.companies.details.locations.update}>
          <RouterButton
            id="openUpdateLocation"
            path={`/companies/${location.relatedCompany._id}/locations/${location._id}/update`}
            label={LOCATION.EDIT_LOCATION}
          />
        </UIAuthorization>
        <UIAuthorization access={editDefaultPPMsAccess(user, location)}>
          <RouterButton
            id="openUpdateLocation"
            path={`/companies/${location.relatedCompany._id}/locations/${location._id}/update-default-ppms`}
            label={LOCATION.EDIT_DEFAULT_PPMS}
          />
        </UIAuthorization>
        <UIAuthorization access={access.companies.details.locations.changeStatus}>
          <LocationChangeStatus location={location}>
            {({ changeStatus }) => (
              <Button
                outline
                id="changeLocationStatus"
                color={location.status === 'active' && 'danger'}
                onClick={changeStatus}
              >
                {location.status === 'active' ? 'Inactivate' : 'Reactivate'}
              </Button>
            )}
          </LocationChangeStatus>
        </UIAuthorization>
      </ExtendableButton>
    </DetailsHeader>
  );
}

export { MainLocationDetails };
