import styled, { css } from 'styled-components';

import { cssVar, scrollbar } from 'shared/helpers/styling/styling';

export const Header = styled.div`
  height: 60px;
  padding-left: 37px;
  display: flex;
  align-items: center;
  color: ${cssVar('alto')};
  background-color: ${cssVar('shark')};
  font-size: 0.75rem;
`;

export const StyledForm = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${({ wide }) => (wide ? '95vw' : '1100px')};

  min-height: calc(100vh - 150px);
  margin: auto;

  ${scrollbar} ::-webkit-scrollbar {
    &-track {
      border-right: 0;
      border-top: 17px solid transparent;
      border-bottom: 17px solid transparent;
    }

    &-thumb {
      border-right: 15px solid transparent;
      border-left: 9px solid transparent;
      border-top: 13px solid transparent;
      border-bottom: 13px solid transparent;
    }
  }

  ${({ wide }) =>
    wide &&
    css`
      overflow-x: auto;

      .form-content {
        background-color: ${cssVar('limedSpruceForm')};

        :before {
          content: '';
          position: absolute;
          background-color: ${cssVar('shark')};
          height: 60px;
          width: 95%;
          transform: translateY(-60px);
          z-index: -1;
        }
      }
    `}
`;
