import { createDeliveriesReducer } from './createDeliveries';
import { deliveriesListReducer } from './deliveriesList';
import { getAvailableHedgesReducer } from './getAvailableHedges';
import { getAvailableLotsReducer } from './getAvailableLots';
import { getDeliveriesToTransferReducer } from './getDeliveriesToTransfer';
import { getLotsToTransferReducer } from './getLotsToTransfer';
import { transferBlanketHedgeOuncesReducer } from './transferBlanketHedgeOunces';

const deliveriesRootReducer = {
  availableHedges: getAvailableHedgesReducer,
  availableLots: getAvailableLotsReducer,
  createDeliveries: createDeliveriesReducer,
  deliveriesList: deliveriesListReducer,
  deliveriesToTransfer: getDeliveriesToTransferReducer,
  lotsToTransfer: getLotsToTransferReducer,
  transferBlanketHedgeOunces: transferBlanketHedgeOuncesReducer,
};

export { deliveriesRootReducer };
