const FILTERS = {
  FILTERS: 'Filters',
  COLLAPSE_FILTERS: 'collapse filters',
  CLEAR_FILTERS: 'Clear all filters',
  LABELS: {
    STATUS: 'Status',
    ACCESS_LEVEL: 'Access level',
    USER_TYPE: 'User type',
    COMPANY_TYPE: 'Company type',
    MATERIAL_TYPE: 'Material type',
    STATUS_FOR_CUSTOMER: 'Status for customer',
    STATUS_FOR_GLOBAL: 'Status for Global',
    HEDGED_STATUS: 'Hedged status',
    SENT_FROM_COMPANY: 'From company',
    SENT_FROM_COMPANY_LOCATION: 'From location',
    RECEIVED_ON_FROM: 'Received from date',
    RECEIVED_ON_TO: 'Received to date',
  },
  PLACEHOLDERS: {
    SELECT_STATUS: '-- select status --',
    SELECT_COMPANY: 'Select company',
    SELECT_LOCATION: 'Select location',
    SELECT_TYPE: '-- select type --',
    SELECT_DATE: '-- select date --',
    SELECT_ACCESS_LEVEL: '-- select access level --',
    SELECT_USER_TYPE: '-- select user type --',
    SELECT_COMPANY_TYPE: '-- select company type --',
    SELECT_MATERIAL_TYPE: '--select material type --',
  },
};

export { FILTERS };
