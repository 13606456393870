const eventTypes = {
  created: 'created',
  shippedToGlobal: 'shippedToGlobal',
  shippedToStillwater: 'shippedToStillwater',
  received: 'received',
  graded: 'graded',
  markedForProcessing: 'markedForProcessing',
  processed: 'processed',
  mixed: 'mixed',
  xmlSummaryReceived: 'xmlSummaryReceived',
  invalidXmlSummaryReceived: 'invalidXmlSummaryReceived',
  assayEnteredManually: 'assayEnteredManually',
  noteEntered: 'noteEntered',
  inspectionRequested: 'inspectionRequested',
  inspectionScheduledFor: 'inspectionScheduledFor',
  commodityRemovedTo: 'commodityRemovedTo',
  commodityAddedFrom: 'commodityAddedFrom',
  gradingReceiptUploaded: 'gradingReceiptUploaded',
  gradingReceiptAccepted: 'gradingReceiptAccepted',
  countSheetUploaded: 'countSheetUploaded',
  stillwaterInitialPayment: 'stillwaterInitialPaymentEntered',
  stillwaterFinalPayment: 'stillwaterFinalPaymentEntered',
  stillwaterShipmentSummaryUploaded: 'stillwaterShipmentSummaryUploaded',
  stillwaterFinalInvoiceUploaded: 'stillwaterFinalInvoiceUploaded',
  globalShipmentSummaryGenerated: 'globalShipmentSummaryGenerated',
  globalShipmentSummaryApproved: 'globalShipmentSummaryApproved',
  globalFinalInvoiceMockup: 'globalFinalInvoiceMockup',
  globalFinalInvoiceGenerated: 'globalFinalInvoiceGenerated',
  globalFinalInvoiceApproved: 'globalFinalInvoiceApproved',
  globalFinalInvoiceUploaded: 'globalFinalInvoiceUploaded',
  profitLossGenerated: 'profitLossGenerated',
  profitLossApproved: 'profitLossApproved',
  hedgeRequested: 'hedgeRequested',
  hedgeDecision: 'hedgeDecision',
  hedgePlaced: 'hedgePlaced',
  hedgeOuncesAssignedFrom: 'hedgeOuncesAssignedFrom',
  paymentRequested: 'paymentRequested',
  paymentDecision: 'paymentDecision',
  paymentPaidOut: 'paymentPaidOut',
  poolOuncesAdded: 'poolOuncesAdded',
  poolOuncesSettled: 'poolOuncesSettled',
  packageUpdated: 'packageUpdated',
  setFinalizedLot: 'setFinalizedLot',
  cancelHedge: 'cancelHedge',
  changeHedgeOunces: 'changeHedgeOunces',
  changeHedgePrices: 'changeHedgePrices',
  transferHedgeToDifferentLot: 'transferHedgeToDifferentLot',
  reassignPayment: 'reassignPayment',
  changeLotStatus: 'changeLotStatus',
  deleteIncomingPackage: 'deleteIncomingPackage',
  gradingReceiptCFU: "gradingReceiptCFU",
  poolSettlementDocumentCFU: "poolSettlementDocumentCFU",
  billOfLadingCFU: "billOfLadingCFU",
  countSheetCFU: "countSheetCFU",
  grgFinalInvoiceCFU: "grgFinalInvoiceCFU",
};
export { eventTypes };
