import axios from 'axios';

import { parseGenerateProfitLossData } from 'shared/helpers/dataParsers/lot/parseGenerateProfitLossData';

const API_HOST_AUTH = process.env.REACT_APP_API;

const generateProfitLossActionType = 'GENERATE_PROFIT_LOSS';
const generateProfitLossAction = () => ({
  type: generateProfitLossActionType,
});

const generateProfitLossSuccessActionType = `${generateProfitLossActionType}_SUCCESS`;
const generateProfitLossSuccessAction = () => ({
  type: generateProfitLossSuccessActionType,
});

const generateProfitLossFailureActionType = `${generateProfitLossActionType}_FAILURE`;
const generateProfitLossFailureAction = () => ({
  type: generateProfitLossFailureActionType,
});

const generateProfitLoss = profitLossData => dispatch => {
  dispatch(generateProfitLossAction());

  return axios
    .post(
      `${API_HOST_AUTH}/lots/generate/profit-and-loss`,
      parseGenerateProfitLossData(profitLossData),
    )
    .then(res => {
      dispatch(generateProfitLossSuccessAction());

      return res.data;
    })
    .catch(err => {
      dispatch(generateProfitLossFailureAction());

      return Promise.reject(err.data);
    });
};

export {
  generateProfitLossActionType,
  generateProfitLossSuccessActionType,
  generateProfitLossFailureActionType,
  generateProfitLoss,
};
