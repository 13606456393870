import { hedgeStatus } from 'shared/helpers/constants/hedges/hedgeStatus';

const allowedStatuses = [hedgeStatus.approved];

const cancelHedgeAccess = ({ access }, { status, hasAssociatedDeliveries }) => {
  const { cancelHedge } = access.specialPermissions.hedges;

  return cancelHedge && allowedStatuses.includes(status) && !hasAssociatedDeliveries;
};

export { cancelHedgeAccess };
