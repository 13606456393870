import axios from 'axios';

import { message } from 'shared/helpers/constants/hedges/createMarketHolidaysConstants';
import { dateToTimestamp } from 'shared/helpers/parsers/date';

const API_HOST_AUTH = process.env.REACT_APP_API;

const asyncValidate = async values => {
  const date = dateToTimestamp(values.date);

  return axios.get(`${API_HOST_AUTH}/market-holidays/date-exists/${date}`).then(({ data }) => {
    if (data.dateExists) {
      const dateError = { date: message.INVALID_DATE_DUPLICATE };
      throw dateError;
    }
  });
};

export { asyncValidate };
