import React from 'react';

import { theme } from 'assets/styles/common/theme';

import { RouterButton } from 'shared/components/Buttons';
import { Button } from 'shared/components/Buttons/Button';
import { SHARED } from 'shared/helpers/constants/sharedConstants';

import {
  StyledErrorPage,
  StyledErrorPageButton,
  StyledErrorPageColumn,
  StyledErrorPageIcons,
  StyledErrorPageInfo,
  StyledErrorPageRow,
  StyledErrorPageText,
  StyledErrorPageTextBottom,
  StyledErrorPageTextTop,
} from './StyledErrorPage';
import { GhostIcon } from './components/GhostIcon';

function ErrorPage(props) {
  return (
    <StyledErrorPage theme={theme}>
      <StyledErrorPageRow>
        <StyledErrorPageColumn md="6" sm="12" xs="12">
          <StyledErrorPageText>
            <StyledErrorPageTextTop>3rr0R</StyledErrorPageTextTop>
            <StyledErrorPageTextBottom>{props.status}</StyledErrorPageTextBottom>
          </StyledErrorPageText>
        </StyledErrorPageColumn>
        <StyledErrorPageIcons md="6" sm="12" xs="12">
          <GhostIcon color="pink" size="small" />
          <GhostIcon color="green" size="medium" />
          <GhostIcon color="grey" size="large" />
        </StyledErrorPageIcons>
      </StyledErrorPageRow>
      <StyledErrorPageInfo>{props.message}</StyledErrorPageInfo>
      <StyledErrorPageButton>
        {props.navigate ? (
          <RouterButton id="takeMeHome" path="/" label="Take me Home" onClick={props.onClick} />
        ) : (
          <Button id="takeMeHome" onClick={props.onClick}>
            Take me Home
          </Button>
        )}
      </StyledErrorPageButton>
    </StyledErrorPage>
  );
}

ErrorPage.defaultProps = {
  status: 404,
  message: SHARED.NOT_FOUND_MESSAGE,
  onClick: () => {},
};

const NotFound = () => <ErrorPage navigate />;

const Unauthorized = () => <ErrorPage status={401} navigate />;

const Forbidden = () => <ErrorPage status={403} navigate />;

const ErrorCaught = props => <ErrorPage status="&nbsp;" message={SHARED.ERROR} {...props} />;

export { NotFound, Unauthorized, ErrorCaught, Forbidden };
