import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { forwardDocument } from 'actions/Files/forwardDocument';
import { showSnackbar } from 'actions/shared/snackbar';

import { ModalCustom } from 'components/ModalCustom/ModalCustom';

import {
  successOptions,
  dangerOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

import { ForwardDocumentContent } from './components/ForwardDocumentContent/ForwardDocumentContent';

function ForwardDocument({ isOpen, toggle, file, fileName, downloadable }) {
  const dispatch = useDispatch();

  const onSubmit = useCallback(
    async ({ portalUsers, externalEmails }) => {
      try {
        const parsedUsers = portalUsers && portalUsers.map(({ value }) => value);
        const parsedEmails =
          externalEmails &&
          externalEmails
            .split(';')
            .map(email => email.trim())
            .filter(Boolean);

        const { message } = await dispatch(
          forwardDocument(file._id, {
            internal: parsedUsers || [],
            external: parsedEmails || [],
          }),
        );

        toggle();
        dispatch(showSnackbar(successOptions, message));
      } catch ({ message }) {
        dispatch(showSnackbar(dangerOptions, message));
      }
    },
    [dispatch, toggle, file],
  );

  return (
    <ModalCustom isOpen={isOpen} modalClose={toggle}>
      <ForwardDocumentContent
        onSubmit={onSubmit}
        file={file}
        fileName={fileName}
        downloadable={downloadable}
      />
    </ModalCustom>
  );
}

export { ForwardDocument };
