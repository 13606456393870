import * as React from 'react';

import { Highlight } from 'shared/components/Highlight/Highlight';

export const renderHighlight = (render, item, searchFilter, includeRegExp, dueDiligence) => {
  const renderedItem = render(item);
  return [].concat(renderedItem).map((txt, index) => {
    const isString = typeof txt === 'string';
    return isString ? (
      <Highlight
        key={index}
        searchFilter={searchFilter}
        textStr={txt}
        includeRegExp={includeRegExp}
        dueDiligence={dueDiligence && item.diligence}
      />
    ) : (
      txt
    );
  });
};
