const MARKET_INDICATORS_LOGS = {
  EVENT: 'Event',
  TYPE: 'Type',
  DATE_TIME: 'Date/time',
  USER: 'User',
  PRICES: 'Prices',
  LOG: 'Log',
  PT: 'Pt',
  PD: 'Pd',
  RH: 'Rh',
};

const eventTypes = {
  request: 'request',
  update: 'update',
};

export { MARKET_INDICATORS_LOGS, eventTypes };
