import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const enterPricesActionType = 'ENTER_PRICES';
const enterPricesAction = () => ({
  type: enterPricesActionType,
});

const enterPricesSuccessActionType = `${enterPricesActionType}_SUCCESS`;
const enterPricesSuccessAction = () => ({
  type: enterPricesSuccessActionType,
});

const enterPricesFailureActionType = `${enterPricesActionType}_FAILURE`;
const enterPricesFailureAction = () => ({
  type: enterPricesFailureActionType,
});

const enterPrices = (hedgeId, prices) => dispatch => {
  dispatch(enterPricesAction());

  return axios
    .patch(`${API_HOST_AUTH}/hedges/${hedgeId}/prices`, prices)
    .then(({ data }) => {
      dispatch(enterPricesSuccessAction());

      return data;
    })
    .catch(error => {
      dispatch(enterPricesFailureAction());

      return Promise.reject(error.data);
    });
};

export {
  enterPricesActionType,
  enterPricesSuccessActionType,
  enterPricesFailureActionType,
  enterPrices,
};
