import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getLotsForMixingOverview } from 'actions/Lots/mixingOverview';
import { getLotsForProcessingOverview } from 'actions/Lots/processingOverview';
import { releasePackage } from 'actions/Packages/releasePackage';
import { showSnackbar } from 'actions/shared/snackbar';

import { BarcodeScanner } from 'shared/components/BarcodeScanner/BarcodeScanner';
import { ScanningSummary } from 'shared/components/BarcodeScanner/components/ScanningSummary';
import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';
import { processViaOptions } from 'shared/helpers/constants/lots/processingConstants';
import { statusesForGlobal } from 'shared/helpers/constants/lots/statusesForGlobal';
import { successOptions } from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

class ReleaseBarcodeScannerBase extends Component {
  state = {
    summary: null,
    stepIndex: 0,
  };

  packageIdAndMachineEntered = async (packageId, machineId) => {
    try {
      const { summary } = await this.props.releasePackage(this.props.lot._id, packageId, machineId);

      this.setState({ summary });

      if (summary.released === summary.total) {
        this.onFinish();
        this.props.showSnackbar(successOptions, LOTS.ALL_PACKAGES_RELEASED);
      }
    } catch (error) {
      return Promise.reject((error.err && error.err.message) || error.message);
    }
  };

  onFinish = () => {
    const { lot, onSelfClose } = this.props;

    this.setState({ summary: null, stepIndex: 0 });

    lot.statusForGlobal === statusesForGlobal.accepted_for_processing
      ? this.props.getLotsForProcessingOverview()
      : this.props.getLotsForMixingOverview();
    onSelfClose();
  };

  render() {
    const { open, lot } = this.props;
    const { stepIndex, summary } = this.state;
    const scanningProgress = { current: stepIndex + 1, steps: 2 };
    const steps = [
      {
        name: 'machine id',
        icon: 'icon-cart',
        barcodeFormat: new RegExp(
          `^${(lot && (lot.mixVia || lot.processVia)) || '[a-z0-9]+'}$`,
          'i',
        ),
        barcodeLength: processViaOptions.processing,
        persistent: true,
      },
      {
        name: 'package id',
        icon: summary
          ? () => (
              <ScanningSummary
                done={summary.released}
                total={summary.total}
                doneLabel={LOTS.RELEASED_PACKAGES}
                totalLabel={LOTS.NOT_RELEASED_PACKAGES}
              />
            )
          : 'icon-box',
        barcodeLength: [8, 24],
        onChange: this.packageIdEntered,
      },
    ];
    const messageConfig = {
      success: LOTS.PACKAGE_SUCCESSFULLY_RELEASED,
      error: LOTS.INVALID_FACTORY,
      idle: LOTS.SCAN_FACTORY,
      manualIdle: LOTS.ENTER_FACTORY,
    };

    return (
      <BarcodeScanner
        title={
          lot ? `${LOTS.LOT}: ${lot.customLotNumber || lot.grgLotNumber}` : LOTS.RELEASE_PACKAGE
        }
        progress={stepIndex < 2 ? scanningProgress : null}
        states={Object.keys(steps).length}
        open={open}
        onSelfClose={this.onFinish}
        message={messageConfig}
        idleStates={steps}
        stateResetTime={3000}
        idleStateChanged={index => this.setState({ stepIndex: index })}
        onSuccessfulScan={this.packageIdAndMachineEntered}
      />
    );
  }
}

const mapDispatchToProps = {
  showSnackbar,
  releasePackage,
  getLotsForProcessingOverview,
  getLotsForMixingOverview,
};

const ReleaseBarcodeScanner = connect(null, mapDispatchToProps)(ReleaseBarcodeScannerBase);

export { ReleaseBarcodeScanner, ReleaseBarcodeScannerBase };
