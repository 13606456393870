const statusesForGrading = {
  open: 'open',
  received: 'received',
  receipt_ready: 'receipt_ready',
  receipt_accepted: 'receipt_accepted',
  finalized: 'finalized',
};

const availableStatusesForGrading = {
  all: Object.values(statusesForGrading),
};

export { statusesForGrading, availableStatusesForGrading };
