import PropTypes from 'prop-types';

import { FiltersDropdown } from 'shared/components/FiltersDropdown/FiltersDropdown';
import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';
import { lotsAwaitingResults } from 'shared/helpers/constants/lots/statusesForGlobal';
import { capitalizeGlobal } from 'shared/helpers/parsers/text';

import { MomentAdapter } from 'utils/date/momentAdapter';

import {
  StyledLotsFilters,
  StyledLotsFiltersItem,
} from 'pages/Lots/components/LotsList/components/LotsFilters/StyledLotFilters';

const LotsAwaitingResultsFilters = ({ filters, onFiltersChanged }) => {
  const { statusForGlobal } = filters;
  const capitalizedStatusForGlobal = statusForGlobal[0] && [capitalizeGlobal(statusForGlobal[0])];

  const filterFunction = name => value =>
    onFiltersChanged({
      ...filters,
      [name]: value instanceof MomentAdapter ? value.valueOf() : value,
    });

  return (
    <StyledLotsFilters>
      <StyledLotsFiltersItem>
        <FiltersDropdown
          label={LOTS.STATUS_FOR_GLOBAL}
          options={Object.values(lotsAwaitingResults)}
          selected={capitalizedStatusForGlobal || []}
          filterFunction={filterFunction('statusForGlobal')}
        />
      </StyledLotsFiltersItem>
    </StyledLotsFilters>
  );
};

LotsAwaitingResultsFilters.propTypes = {
  filters: PropTypes.shape({
    statusForGlobal: PropTypes.arrayOf(PropTypes.string),
  }),
  onFiltersChanged: PropTypes.func,
};

export { LotsAwaitingResultsFilters };
