import {
  getRegionListActionType,
  getRegionListFailureActionType,
  getRegionListSuccessActionType,
} from 'actions/Locations/getRegionList';

const INITIAL_STATE = {
  isPending: false,
  data: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getRegionListActionType:
      return {
        ...state,
        isPending: true,
      };
    case getRegionListFailureActionType:
      return {
        data: null,
        isPending: false,
      };
    case getRegionListSuccessActionType:
      return {
        data: action.payload.response,
        isPending: false,
      };
    default:
      return state;
  }
};
