import { Form } from 'redux-form';
import { media } from 'shared/helpers/styling/styling';
import styled from 'styled-components';

export const StyledForm = styled(Form)`
  margin-top: 25px;

  ${media.phone`
  height: 600px;
`}

  .form-control-label {
    z-index: -1;
  }
`;

export const StyledActions = styled.div`
  margin-top: 290px;
  display: flex;
  justify-content: flex-end;
`;
