import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getLotAvailableOuncesActionType = 'GET_LOT_AVAILABLE_OUNCES';
const getLotAvailableOuncesAction = () => ({
  type: getLotAvailableOuncesActionType,
});

const getLotAvailableOuncesSuccessActionType = `${getLotAvailableOuncesActionType}_SUCCESS`;
const getLotAvailableSuccessAction = (available, lotId) => ({
  type: getLotAvailableOuncesSuccessActionType,
  payload: { available, lotId },
});

const getLotAvailableOuncesFailureActionType = `${getLotAvailableOuncesActionType}_FAILURE`;
const getLotAvailableOuncesFailureAction = errorMessage => ({
  type: getLotAvailableOuncesFailureActionType,
  payload: {
    errorMessage,
  },
});

const clearAvailableOuncesActionType = 'CLEAR_LOT_AVAILABLE_OUNCES';
const clearAvailableOunces = () => ({
  type: clearAvailableOuncesActionType,
});

const clearLotAvailableOuncesActionType = 'CLEAR_LOT_AVAILABLE_OUNCES_FOR_LOT';
const clearLotAvailableOunces = lotId => ({
  type: clearLotAvailableOuncesActionType,
  payload: { lotId },
});

const getLotAvailableOunces = lotId => dispatch => {
  dispatch(getLotAvailableOuncesAction());

  return axios
    .get(`${API_HOST_AUTH}/pool-accounts/available-ounces/${lotId}`)
    .then(res => {
      dispatch(getLotAvailableSuccessAction(res.data, lotId));

      return res.data;
    })
    .catch(err => Promise.reject(dispatch(getLotAvailableOuncesFailureAction(err))));
};

export {
  getLotAvailableOuncesActionType,
  getLotAvailableOuncesAction,
  getLotAvailableOuncesSuccessActionType,
  getLotAvailableSuccessAction,
  getLotAvailableOuncesFailureActionType,
  getLotAvailableOuncesFailureAction,
  getLotAvailableOunces,
  clearAvailableOuncesActionType,
  clearAvailableOunces,
  clearLotAvailableOuncesActionType,
  clearLotAvailableOunces,
};
