import { resolveFilterDate, resolveFilterDateEnd } from 'shared/helpers/parsers/dateFilterParser';

function parseLotsFilters(lotsFilters, limit, page, searchFilter, businessDaysOut) {
  const filters = {
    ...lotsFilters,
    sentFromCompanyLocation: lotsFilters.sentFromCompanyLocation._id,
    receivedAtStart: resolveFilterDate(lotsFilters.receivedAtStart),
    receivedAtEnd: resolveFilterDateEnd(lotsFilters.receivedAtEnd),
  };

  return {
    ...filters,
    searchFilter,
    limit,
    page,
    businessDaysOut,
  };
}

export { parseLotsFilters };
