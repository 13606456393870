import { statusesForGrading } from 'shared/helpers/constants/lots/statusesForGrading';

const allowedStatuses = [statusesForGrading.receipt_ready];

const manageGradingReceiptAccess = (
  {
    access: {
      lots: { manageGradingReceipt },
    },
  },
  lot,
) =>
  !lot.gradingReceiptRejectedBy &&
  manageGradingReceipt &&
  allowedStatuses.includes(lot.statusForCustomer);
export { manageGradingReceiptAccess };
