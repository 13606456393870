import { isZero } from 'mathjs';

import { SHARED } from 'shared/helpers/constants/sharedConstants';
import { contactData } from 'shared/helpers/constants/versioning/contactData';
import { fieldPrefix } from '../versioning/fieldPrefixes';
import { formatToDollarWithPrefix } from 'shared/helpers/parsers/formaters';

const PAYMENTS = {
  PAYMENT: 'Payment',
  ALL_PAYMENTS: 'All payments',
  POOL_PAYMENT: 'Pool payment',
  PAYMENTS: 'Payments',
  GENERAL_DETAILS: 'General details',
  ACTIVITY: 'Activity',
  REQUEST_POOL_PAYMENT: 'Request a pool payment',
  REQUEST_PAYMENT: 'Request a payment',
  REQUEST_FINAL_PAYMENT: `Request a${SHARED.NON_BREAKING_SPACE}final payment`,
  REQUEST_ADVANCE_PAYMENT: `Request an${SHARED.NON_BREAKING_SPACE}advance payment`,
  CHOOSE_TYPE: 'Choose payment type:',
  RELATED_LOT: 'Related lot',
  RELATED_COMPANY: 'Related company',
  EXCEPTION_COMPANIES: 'Exception companies',
  EXCEPTION_COMPANY: 'Exception company',
  FUNDS_TYPE: 'Funds type',
  PAYMENT_AMOUNT: 'Payment amount',
  PAYMENT_NUMBER: 'Payment number',
  PAYMENT_NUMBER_COLON: 'Payment number:',
  PAYMENT_TYPE: 'Payment type',
  BALANCE_REMAINING: 'Company balance',
  ADDITIONAL_INFORMATION: 'Additional information',
  PAID: 'Paid',
  PAID_ON: 'Paid on',
  PAID_ON_FROM: 'Paid on from date',
  PAID_ON_TO: 'Paid on to date',
  CREATED_BY: 'Created by',
  UPDATED_BY: 'Updated by',
  GLOBAL_DECISION_BY: 'Global decision by',
  ACTIONS: 'Actions',
  DELETE_PAYMENT: 'Delete payment',
  EDIT_PAYMENT: 'Edit payment',
  APPROVE_PAYMENT: 'Approve payment',
  DECLINE_PAYMENT: 'Decline payment',
  SHOW_EXISTING_PAYMENTS: 'Cancel and display all lot payments',
  MARK_AS_PAID: 'Mark as paid',
  REASSIGN_PAYMENT: 'Re-assign payment',
  ADMIN_REASSIGN_PAYMENT: 'Re-assign payment (admin only)',
  STATUS: 'Status',
  MAXIMUM: 'Maximum',
  SUGGESTED: 'Suggested',
  SETTLEMENT: 'Settlement',
  PLATINUM: 'Platinum',
  PALLADIUM: 'Palladium',
  RHODIUM: 'Rhodium',
  OUNCES: 'Ounces',
  PLATINUM_OUNCES: 'Platinum ounces',
  PALLADIUM_OUNCES: 'Palladium ounces',
  RHODIUM_OUNCES: 'Rhodium ounces',
  PRICE_PER_OUNCE: 'Price per ounce',
  PLATINUM_PRICE_PER_OUNCE: 'Platinum price per ounce',
  PALLADIUM_PRICE_PER_OUNCE: 'Palladium price per ounce',
  RHODIUM_PRICE_PER_OUNCE: 'Rhodium price per ounce',
  SETTLEMENT_DOCUMENT: 'Settlement document',
  REQUESTED_BY: 'Requested by',
  PAYMENT_TYPES: {
    FINAL: 'final',
    ADVANCE: 'advance',
  },
  PAYMENT_EXCEPTION_COMPANIES: {
    TITLE: 'Payment exception companies',
    CREATE: 'Add payment exception company',
    RELATED_COMPANY: 'Related company',
    STATUS: 'Status',
    COMPANY_NAME: 'Company name',
    COMPANY_TYPE: 'Company type',
    EXCEPTION_TYPE: 'Exception type',
    LOT_PREFIX: 'Lot prefix',
    DELETE: 'Delete payment exception company',
    DATE_ADDED: 'Date added',
    ADDED_BY: 'Added by',
    INFORMATION: {
      HEDGE_REQUIRED: 'Pre-delivery payments allowed (based on terms)',
      HEDGE_NOT_REQUIRED: 'Payments allowed above terms and w/out hedge (post-delivery)',
    },
  },
  PAYOUT_RULES: {
    TITLE: 'Payout rules',
    CREATE: 'Add payout rule',
    RELATED_COMPANY: 'Related company',
    COMPANY_NAME: 'Company name',
    PAYOUT_RULE: 'Payout rule',
    DELETE: 'Delete payout rule',
    DATE_ADDED: 'Added on',
    ADDED_BY: 'Added by',
  },
  CREATE_SETTLEMENT_SUCCESS: 'Pool account settlement has been generated successfully.',
  CREATE_SETTLEMENT_FAILURE: 'Pool account settlement has not been generated.',
  ERROR: {
    REQUEST_FINAL_PAYMENT_ERROR: 'Final payment has not been requested.',
    REQUEST_ADVANCE_PAYMENT_ERROR: 'Advance payment request has not been submitted for approval.',
    PAYMENT_REQUEST_DELETE_ERROR: 'Payment request has not been deleted.',
    PAYMENT_DELETE_ERROR: 'Payment has not been deleted.',
    CHANGE_STATUS_ERROR: 'Payment has not been approved or declined.',
    MARK_AS_PAID_ERROR: 'Payment has not been marked as paid.',
    ENTER_PAYMENT_ERROR: 'Payment has not been submitted for approval.',
    POOL_PAYMENT_AMOUNT: `The entered ounces and prices do not match the specified payment amount.
                          Please check that the values you entered are correct.`,
  },
  PAID_ON_MESSAGES: {
    PAID_ON_LABEL: 'Please specify the date when the payment was made.',
    PAID_ON_EMPTY: 'Paid on cannot be empty.',
    PAID_ON_IN_FUTURE: 'Paid on cannot be in the future.',
  },
  REASSIGN_MESSAGES: {
    NEW_LOT_EMPTY: 'New lot cannot be empty.',
  },
  FINAL_PAYMENT_DELAY_AFTER_MARKET_HOURS: 1,
  FINAL_PAYMENT_DELAY_BEFORE_OR_DURING_MARKET_HOURS: 2,
  PAYMENT_AMOUNT_OUT_OF_RANGE: 'Payment amount out of range.',
  CALCULATED_PAYMENT_AMOUNT: value =>
    `Payment amount: ${formatToDollarWithPrefix(isZero(value) ? 0 : value)}`,
  SETTLEMENT_MARKET_HOLIDAY:
    'Today is a market holiday and settlement requests cannot be processed.',
  SETTLEMENT_MARKET_HOURS: (marketHours, timezone, marketDays) =>
    `Settlements can only be submitted during market hours: ${marketHours} ${timezone}, ${marketDays}.`,
  SELECT: 'Select',
  GRG_LOT_NUMBER: `${fieldPrefix} lot number`,
  STILLWATER_SHIPMENT_ID: 'SWC ID',
  MATERIAL_TYPE: 'Material type',
  HEDGED_STATUS: 'Hedged status',
  RECEIVED_ON: 'Received on',
  SETTLE_LOTS: 'Settle lots',
  PAYMENT_AMOUNT_DISABLED:
    "A final payment cannot be entered for this lot, as the final invoice amount is negative. Please set the lot status to 'Finalized' manually.",
  NO_LOT_TO_SETTLE_FOUND: 'No lots to settle were found.',
  POOL_PAYMENT_ALREADY_REQUESTED:
    'A pool payment has already been requested. Please wait until the existing request is processed before submitting another payment request.',
  FINAL_PAYMENT_ALREADY_REQUESTED: 'A final payment has already been requested for this lot.',
  SETTLEMENT_ALREADY_REQUESTED: 'A settlement has already been requested for this lot.',
  REQUESTED_FINAL_PAYMENT_DATE: 'Requested final payment date',
  REMAINING_AMOUNT_IS_TOO_LOW: `The value of the lot is less than that of the already-paid-out funds.
                                Please contact ${contactData.company} representative at ${contactData.phoneNumber} for assistance.`,
  TOTAL_ACCOUNTS_RECEIVABLE: value =>
    `Total value of all outstanding accounts receivable: ${formatToDollarWithPrefix(value) || ''}`,
  OUTSTANDING_BALANCE_APPLIED_HEADER:
    'Enter the outstanding amount you would like to apply to this payment (this amount will be subtracted from the payment amount)',
  OUTSTANDING_BALANCE_APPLIED: 'Outstanding balance applied',
  POOL_PAYMENT_REQUEST_EXISTS:
    'A pool payment request already exists for this company. Please process the existing request before submitting another pool payment.',
  FINANCE_CHARGES: 'Finance charges',
  FINANCE_CHARGE: 'Finance charge',
  ADD_NEW_FINANCE_CHARGE: 'Add new finance charge',
  FINANCE_RATE: 'Finance rate',
  BANK_HOLIDAYS: {
    TITLE: 'Bank holidays',
    DATE: 'Date',
    MONTH: 'Month',
    DAY: 'Day',
    YEAR: 'Year',
    CREATE: 'Add bank holiday',
    DELETE: 'Delete bank holiday',
    SELECT_YEAR: 'Select year',
    HOLIDAY_NAME: 'Holiday name',
    DATE_ADDED: 'Date added',
    ADDED_BY: 'Added by',
    TODAY_IS_A_BANK_HOLIDAY: 'Today is a bank holiday - payments are unavailable or restricted.',
    YEARS_RANGE: 5,
  },
  POOL_ACCOUNT_SETTLEMENT_AND_BALANCE_DOCUMENT: 'Pool account settlement and balance',
  UPDATE_DRAFT_FINAL_PAYMENT: (grgLotNumber = '') =>
    `Customer final payment for lot ${grgLotNumber}`,
  UPDATE_DRAFT_POOL_PAYMENT: (companyName = '') => `Pool payment for ${companyName}`,
};

export { PAYMENTS };
