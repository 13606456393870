import { ENTER_POOL_PAYMENT } from 'shared/helpers/constants/payments/enterPoolPaymentConstants';
import { calculatePoolPaymentAmount } from 'shared/helpers/dataParsers/payments/calculatePoolPaymentAmount';
import { safeParseFloat } from 'shared/helpers/parsers/number';
import { replaceEmpty } from 'shared/helpers/parsers/text';
import { commaValidator } from 'shared/helpers/validations/commaValidator';
import { FLOAT_REGEX_GEN, PRICE_REGEX } from 'shared/helpers/validations/regularExpressions';

function validate(values, index, props) {
  const { platinumOz, palladiumOz, rhodiumOz, balanceRemaining } =
    props.values.payment.relatedCompany;
  const {
    settlement,
    financeCharges: { charges },
  } = props.values;
  const errors = {};

  if (platinumOz !== 0) {
    if (replaceEmpty(values.ouncesPt, '').length === 0 && platinumOz !== 0) {
      errors.ouncesPt = ENTER_POOL_PAYMENT.PLATINUM_OUNCES_EMPTY;
    } else if (values.ouncesPt && values.ouncesPt.toString().length > 10) {
      errors.ouncesPt = ENTER_POOL_PAYMENT.PLATINUM_OUNCES_INVALID;
    } else if (!FLOAT_REGEX_GEN(3).test(values.ouncesPt)) {
      errors.ouncesPt = ENTER_POOL_PAYMENT.PLATINUM_OUNCES_INVALID;
    } else if (values.ouncesPt < 0) {
      errors.ouncesPt = ENTER_POOL_PAYMENT.PLATINUM_OUNCES_NEGATIVE;
    } else if (safeParseFloat(values.ouncesPt) > platinumOz) {
      errors.ouncesPt = ENTER_POOL_PAYMENT.PLATINUM_OUNCES_RANGE(platinumOz);
    }
  }

  if (palladiumOz !== 0) {
    if (replaceEmpty(values.ouncesPd, '').length === 0 && palladiumOz !== 0) {
      errors.ouncesPd = ENTER_POOL_PAYMENT.PALLADIUM_OUNCES_EMPTY;
    } else if (values.ouncesPd && values.ouncesPd.toString().length > 10) {
      errors.ouncesPd = ENTER_POOL_PAYMENT.PALLADIUM_OUNCES_INVALID;
    } else if (!FLOAT_REGEX_GEN(3).test(values.ouncesPd)) {
      errors.ouncesPd = ENTER_POOL_PAYMENT.PALLADIUM_OUNCES_INVALID;
    } else if (values.ouncesPd < 0) {
      errors.ouncesPd = ENTER_POOL_PAYMENT.PALLADIUM_OUNCES_NEGATIVE;
    } else if (safeParseFloat(values.ouncesPd) > palladiumOz) {
      errors.ouncesPd = ENTER_POOL_PAYMENT.PALLADIUM_OUNCES_RANGE(palladiumOz);
    }
  }

  if (rhodiumOz !== 0) {
    if (replaceEmpty(values.ouncesRh, '').length === 0) {
      errors.ouncesRh = ENTER_POOL_PAYMENT.RHODIUM_OUNCES_EMPTY;
    } else if (values.ouncesRh && values.ouncesRh.toString().length > 10) {
      errors.ouncesRh = ENTER_POOL_PAYMENT.RHODIUM_OUNCES_INVALID;
    } else if (!FLOAT_REGEX_GEN(3).test(values.ouncesRh)) {
      errors.ouncesRh = ENTER_POOL_PAYMENT.RHODIUM_OUNCES_INVALID;
    } else if (values.ouncesRh < 0) {
      errors.ouncesRh = ENTER_POOL_PAYMENT.RHODIUM_OUNCES_NEGATIVE;
    } else if (safeParseFloat(values.ouncesRh) > rhodiumOz) {
      errors.ouncesRh = ENTER_POOL_PAYMENT.RHODIUM_OUNCES_RANGE(rhodiumOz);
    }
  }

  if ((platinumOz !== 0 || values.ouncesPt) && safeParseFloat(values.ouncesPt) !== 0) {
    if (replaceEmpty(values.pricePerOuncePt, '').length === 0) {
      errors.pricePerOuncePt = ENTER_POOL_PAYMENT.PLATINUM_PRICE_PER_OUNCE_EMPTY;
    } else if (!PRICE_REGEX.test(values.pricePerOuncePt)) {
      errors.pricePerOuncePt = ENTER_POOL_PAYMENT.PLATINUM_PRICE_PER_OUNCE_INVALID;
    } else if (safeParseFloat(values.pricePerOuncePt) <= 0) {
      errors.pricePerOuncePt = ENTER_POOL_PAYMENT.PLATINUM_PRICE_PER_OUNCE_NEGATIVE;
    } else if (values.pricePerOuncePt && values.pricePerOuncePt.length > 12) {
      errors.pricePerOuncePt = ENTER_POOL_PAYMENT.PLATINUM_PRICE_PER_OUNCE_INVALID;
    }
  }

  if ((palladiumOz !== 0 || values.ouncesPd) && safeParseFloat(values.ouncesPd) !== 0) {
    if (replaceEmpty(values.pricePerOuncePd, '').length === 0) {
      errors.pricePerOuncePd = ENTER_POOL_PAYMENT.PALLADIUM_PRICE_PER_OUNCE_EMPTY;
    } else if (!PRICE_REGEX.test(values.pricePerOuncePd)) {
      errors.pricePerOuncePd = ENTER_POOL_PAYMENT.PALLADIUM_PRICE_PER_OUNCE_INVALID;
    } else if (safeParseFloat(values.pricePerOuncePd) <= 0) {
      errors.pricePerOuncePd = ENTER_POOL_PAYMENT.PALLADIUM_PRICE_PER_OUNCE_NEGATIVE;
    } else if (values.pricePerOuncePd && values.pricePerOuncePd.length > 12) {
      errors.pricePerOuncePd = ENTER_POOL_PAYMENT.PALLADIUM_PRICE_PER_OUNCE_INVALID;
    }
  }

  if ((rhodiumOz !== 0 || values.ouncesRh) && safeParseFloat(values.ouncesRh) !== 0) {
    if (replaceEmpty(values.pricePerOunceRh, '').length === 0) {
      errors.pricePerOunceRh = ENTER_POOL_PAYMENT.RHODIUM_PRICE_PER_OUNCE_EMPTY;
    } else if (!PRICE_REGEX.test(values.pricePerOunceRh)) {
      errors.pricePerOunceRh = ENTER_POOL_PAYMENT.RHODIUM_PRICE_PER_OUNCE_INVALID;
    } else if (safeParseFloat(values.pricePerOunceRh) <= 0) {
      errors.pricePerOunceRh = ENTER_POOL_PAYMENT.RHODIUM_PRICE_PER_OUNCE_NEGATIVE;
    } else if (values.pricePerOunceRh && values.pricePerOunceRh.length > 12) {
      errors.pricePerOunceRh = ENTER_POOL_PAYMENT.RHODIUM_PRICE_PER_OUNCE_INVALID;
    }
  }

  if (!replaceEmpty(values.outstandingBalanceApplied, '').length) {
    errors.outstandingBalanceApplied = ENTER_POOL_PAYMENT.OUTSTANDING_BALANCE_APPLIED_EMPTY;
  } else if (
    values.outstandingBalanceApplied &&
    !PRICE_REGEX.test(values.outstandingBalanceApplied)
  ) {
    errors.outstandingBalanceApplied = ENTER_POOL_PAYMENT.OUTSTANDING_BALANCE_APPLIED_INVALID;
  } else if (values.outstandingBalanceApplied && values.outstandingBalanceApplied.length > 15) {
    errors.outstandingBalanceApplied = ENTER_POOL_PAYMENT.OUTSTANDING_BALANCE_APPLIED_INVALID;
  } else if (safeParseFloat(values.outstandingBalanceApplied) < 0) {
    errors.outstandingBalanceApplied = ENTER_POOL_PAYMENT.OUTSTANDING_BALANCE_APPLIED_NEGATIVE;
  } else if (safeParseFloat(values.outstandingBalanceApplied) > Math.abs(balanceRemaining)) {
    errors.outstandingBalanceApplied = ENTER_POOL_PAYMENT.OUTSTANDING_BALANCE_APPLIED_TOO_HIGH;
  } else if (calculatePoolPaymentAmount(settlement, charges) < 0) {
    errors.outstandingBalanceApplied = ENTER_POOL_PAYMENT.OUTSTANDING_BALANCE_APPLIED_RANGE;
  }

  commaValidator(
    {
      ouncesPt: values.ouncesPt,
      ouncesPd: values.ouncesPd,
      ouncesRh: values.ouncesRh,
      pricePerOuncePt: values.pricePerOuncePt,
      pricePerOuncePd: values.pricePerOuncePd,
      pricePerOunceRh: values.pricePerOunceRh,
      outstandingBalanceApplied: values.outstandingBalanceApplied,
    },
    errors,
  );

  return errors;
}

export { validate };
