const CREATE_PACKAGE = {
  INVALID_CUSTOMER_INSTRUCTIONS: 'Customer instructions must have a valid format.',
  INVALID_CUSTOMER_INSTRUCTIONS_EMPTY: 'Customer instructions cannot be empty.',
  INVALID_CUSTOMER_INSTRUCTIONS_LENGTH:
    'Customer instructions cannot be longer than 100 characters.',
  INVALID_ASSIGNED_LOT_EMPTY: 'Assigned lot cannot be empty.',
  INVALID_PACKAGE_TYPE_EMPTY: 'Package type cannot be empty.',
  INVALID_MATERIAL_CLASS: 'Material class cannot be empty.',
  INVALID_MATERIAL_TYPE_DECLARED_EMPTY: 'Material type declared cannot be empty.',
  INVALID_UNIT_COUNT_EMPTY: 'Unit count cannot be empty.',
  INVALID_UNIT_COUNT_LESS: 'Unit count cannot be less then 1.',
  INVALID_UNIT_COUNT: 'Unit count must have a valid format.',
  INVALID_WEIGHT_GROSS_DECLARED_EMPTY: 'Gross declared cannot be empty.',
  INVALID_WEIGHT_GROSS_DECLARED_LESS: 'Gross declared cannot be less than or equal to 0.',
  INVALID_WEIGHT_GROSS_DECLARED: 'Gross declared must have a valid format.',
  INVALID_WEIGHT_TARE_DECLARED_EMPTY: 'Tare declared cannot be empty.',
  INVALID_WEIGHT_TARE_DECLARED_LESS: 'Tare declared cannot be less than 0.',
  INVALID_WEIGHT_TARE_DECLARED: 'Tare declared must have a valid format.',
  INVALID_WEIGHT_TARE_DECLARED_GREATER:
    'Tare declared cannot be greater than or equal to gross declared.',
  INVALID_WEIGHT_GROSS_ACTUAL_EMPTY: 'Gross actual cannot be empty.',
  INVALID_WEIGHT_GROSS_ACTUAL_LESS: 'Gross actual cannot be less than or equal to 0.',
  INVALID_WEIGHT_GROSS_ACTUAL: 'Gross actual must have a valid format.',
  INVALID_WEIGHT_GROS_ACTUAL_GREATER: 'Gross actual cannot be less than or equal to tare actual.',
  INVALID_WEIGHT_TARE_ACTUAL_EMPTY: 'Tare actual cannot be empty.',
  INVALID_WEIGHT_TARE_ACTUAL_LESS: 'Tare actual cannot be less than 0.',
  INVALID_WEIGHT_TARE_ACTUAL: 'Tare actual must have a valid format.',
  INVALID_WEIGHT_TARE_ACTUAL_GREATER:
    'Tare actual cannot be greater than or equal to gross actual.',
  INVALID_DENOMINATED_IN_EMPTY: 'Denominated in cannot be empty.',
  INVALID_NUMBER_OF_PACKAGES_EMPTY: 'Number of packages cannot be empty.',
  INVALID_NUMBER_OF_PACKAGES_ZERO: 'Number of packages cannot be zero.',
  INVALID_NUMBER_OF_PACKAGES: 'Number of packages must have a valid format.',
  INVALID_NUMBER_OF_PACKAGES_GREATER: 'Number of packages must be not greater than 99.',
  INVALID_LABEL_ID_FORMAT: 'Label ID must have a valid format.',
  INVALID_PLANT_FORMAT: 'Plant # must have a valid format.',
  INVALID_CUSTOM_LOT_NUMBER_FORMAT: 'Custom lot number must have a valid format.',
  INVALID_CUSTOM_LOT_NUMBER_UNIQUE: 'Custom lot number must be unique.',
  INVALID_CUSTOMER_INSTRUCTIONS_REQUIRED_OWNER: 'Please specify the package contents',
  INVALID_CUSTOMER_INSTRUCTIONS_REQUIRED_CUSTOMER:
    'Since the material type chosen is "Other", please specify the contents of the package in the Customer Instructions field.',
  INVALID_PRIMARY_ID_EMPTY: 'Converter identifier cannot be empty.',
  INVALID_PRIMARY_ID_LENGTH: 'Converter identifier cannot be longer than 30 characters.',
  INVALID_SECONDARY_ID_LENGTH: 'Secondary identifier cannot be longer than 30 characters.',
  INVALID_CONTACT_PERSON_LENGTH: 'Contact name cannot be longer than 100 characters.',
  INVALID_PRIMARY_ID_FORMAT: 'Converter identifier must have a valid format.',
  INVALID_SECONDARY_ID_FORMAT: 'Secondary identifier must have a valid format.',
  INVALID_CONTACT_PERSON_EMPTY: 'Contact name cannot be empty.',
  INVALID_USERNAME_EMPTY: 'First and last name cannot be empty.',
};

export { CREATE_PACKAGE };
