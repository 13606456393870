const materialTypes = {
  mono_decanned: 'mono_decanned',
  dpf_decanned: 'DPF_decanned',
  sweeps: 'sweeps',
  foil_decanned: 'foil_decanned',
  beads_decanned: 'beads_decanned',
  sensors: 'sensors',
  high_grade_dust: 'high_grade_dust',
};

const availableMaterialTypes = {
  all: Object.values(materialTypes),
};

export { availableMaterialTypes, materialTypes };
