import ReCAPTCHA from 'react-google-recaptcha';
import styled from 'styled-components';

import { media } from 'shared/helpers/styling/styling';

export const StyledLoginFormComponent = styled.div`
  text-align: center;
  height: 100%;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;

  &:before {
    content: ' ';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
`;

export const StyledLoginFormWrapper = styled.div`
  display: inline-block;
  vertical-align: middle;
  width: 540px;
  padding: 10px 15px;

  ${media.phone`
    font-size: 0.7em;
    width: 100%;
    padding: 20px 15px;
  `}

  ${media.tablet`
    width: 540px;
  `}
`;

export const StyledLoginFormContent = styled.div`
  background: var(--outerSpace);
  padding: 50px 100px 100px 100px;

  ${media.phone`
      padding: 40px 40px 80px;
  `}
`;

export const StyledLoginFormLogo = styled.div`
  width: 100%;
  text-align: center;

  img {
    height: 48px;
  }

  ${media.phone`
    img {
  height: 27px;
}
`}
`;

export const StyledLoginFormForm = styled.div`
  margin-top: 42px;
  ${media.phone`
    margin-top: 30px;
  `}
`;

export const StyledLoginFormReCaptcha = styled(ReCAPTCHA)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  ${media.phone`
    margin: 12px 0 0 -42px;
    transform: scale(0.85);
    justify-content: center;

    div {
      width: 250px;
    }
`}
`;

export const StyledLoginFormButtons = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
