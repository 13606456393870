const getLatestMarketIndicatorsType = 'GET_LATEST__MARKET_INDICATORS';

const getLatestMarketIndicatorsAction = () => ({
  type: getLatestMarketIndicatorsType,
});

const getLatestMarketIndicatorsSuccessType = `${getLatestMarketIndicatorsType}_SUCCESS`;
const getLatestMarketIndicatorsSuccessAction = latestMarketIndicators => ({
  type: getLatestMarketIndicatorsSuccessType,
  payload: {
    latestMarketIndicators,
  },
});

const getLatestMarketIndicatorsFailureType = `${getLatestMarketIndicatorsType}_FAILURE`;
const getLatestMarketIndicatorsFailureAction = () => ({
  type: getLatestMarketIndicatorsFailureType,
});

const clearLatestMarketIndicatorsActionType = 'CLEAR_LATEST_MARKET_INDICATORS';
const clearLatestMarketIndicatorsAction = () => ({
  type: clearLatestMarketIndicatorsActionType,
});

const getLatestMarketIndicators =
  ({
    settlementUpdatedAt,
    hedgingUpdatedAt,
    indicators: {
      hedging: { hedgingPlatinumPrice, hedgingPalladiumPrice, hedgingRhodiumPrice },
      settlement: { settlementPlatinumPrice, settlementPalladiumPrice, settlementRhodiumPrice },
    },
  }) =>
  dispatch => {
    dispatch(getLatestMarketIndicatorsAction());
    try {
      const currentTime = new Date().getTime();
      const oneHourBefore = currentTime - 3600000;
      const isSettlementValid = settlementUpdatedAt && settlementUpdatedAt >= oneHourBefore;
      const updateOlderThanOneHour =
        oneHourBefore > settlementUpdatedAt && oneHourBefore > hedgingUpdatedAt;
      const validMarketIndicators = {};

      if (settlementUpdatedAt === null && hedgingUpdatedAt === null) {
        throw new Error('Market indicators are empty');
      }

      if (isSettlementValid || settlementUpdatedAt >= hedgingUpdatedAt) {
        validMarketIndicators.palladium = settlementPalladiumPrice;
        validMarketIndicators.platinum = settlementPlatinumPrice;
        validMarketIndicators.rhodium = settlementRhodiumPrice;
      } else {
        validMarketIndicators.palladium = hedgingPalladiumPrice;
        validMarketIndicators.platinum = hedgingPlatinumPrice;
        validMarketIndicators.rhodium = hedgingRhodiumPrice;
      }

      validMarketIndicators.updateOlderThanOneHour = updateOlderThanOneHour;

      dispatch(getLatestMarketIndicatorsSuccessAction(validMarketIndicators));

      return validMarketIndicators;
    } catch (error) {
      dispatch(getLatestMarketIndicatorsFailureAction());
    }
  };

export {
  getLatestMarketIndicatorsType,
  getLatestMarketIndicatorsSuccessType,
  getLatestMarketIndicatorsFailureType,
  clearLatestMarketIndicatorsActionType,
  getLatestMarketIndicators,
  clearLatestMarketIndicatorsAction as clearLatestMarketIndicators,
};
