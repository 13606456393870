import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import {
  deletePackage,
  deleteIncomingPackage,
  deleteProcessingPackage,
} from 'actions/Packages/deletePackage';
import { openModal } from 'actions/shared/modal';
import { showSnackbar } from 'actions/shared/snackbar';

import { deletePackageModal } from 'shared/helpers/constants/modalConstants';
import { packageStatuses } from 'shared/helpers/constants/packages/packageType';
import { isCustomerUser, isOwnerUser, isSupportUser } from 'shared/helpers/matchers/checkUserType';
import { isGlobalCreatedPackage } from 'shared/helpers/matchers/packages/isGlobalCreatedPackage';
import { snackbarOptions } from 'shared/helpers/matchers/snackbar/snackbarOptions';

function DeletePackage(props) {
  const {
    relatedCompany: { materialCountRequired },
    userType,
  } = props.auth.user;

  const deletePackageAction = () => {
    props.openModal(deletePackageModal(materialCountRequired, isCustomerUser(userType)), () => {
      const packageId = props.pkg._id || props.pkg.id;
      const isProcessingPackage = isGlobalCreatedPackage(props.pkg)
        ? props.deleteProcessingPackage
        : props.deleteIncomingPackage;
      const deleteAction =
        isOwnerUser(props.auth.user.userType) || isSupportUser(props.auth.user.userType)
          ? isProcessingPackage
          : props.deletePackage;

      deleteAction(packageId).then(res => {
        props.showSnackbar(snackbarOptions(res.messageType), res.message);
        const { onDelete } = props;
        onDelete && onDelete();
      });
    });
  };

  return <React.Fragment>{props.children({ deletePackage: deletePackageAction })}</React.Fragment>;
}

const mapStateToProps = ({ auth }) => ({ auth });

const mapDispatchToProps = {
  openModal,
  showSnackbar,
  deletePackage,
  deleteIncomingPackage,
  deleteProcessingPackage,
};

const ConnectedDeletePackage = connect(mapStateToProps, mapDispatchToProps)(DeletePackage);

DeletePackage.propTypes = {
  auth: PropTypes.shape({
    acess: PropTypes.object,
    attempts: PropTypes.number,
    isPending: PropTypes.bool,
    user: PropTypes.object,
  }),
  children: PropTypes.func,
  deleteIncomingPackage: PropTypes.func,
  deletePackage: PropTypes.func,
  deleteProcessingPackage: PropTypes.func,
  onDelete: PropTypes.func,
  openModal: PropTypes.func,
  pkg: PropTypes.shape({
    assignedLot: PropTypes.object,
    assignedShipment: PropTypes.object,
    companyName: PropTypes.string,
    customerInstructions: PropTypes.string,
    id: PropTypes.string,
    labelId: PropTypes.string,
    materialTypeDeclared: PropTypes.string,
    openIncomingPkgCount: PropTypes.number,
    packageClass: PropTypes.string,
    packageCountIncoming: PropTypes.number,
    packageNumber: PropTypes.number,
    packageType: PropTypes.string,
    plant: PropTypes.string,
    status: PropTypes.oneOf([
      packageStatuses.closed,
      packageStatuses.deleted,
      packageStatuses.open,
      packageStatuses.received,
    ]),
    unitCount: PropTypes.oneOf([PropTypes.number]),
    weightGrossDeclared: PropTypes.number,
    weightNetDeclared: PropTypes.number,
    weightTareDeclared: PropTypes.number,
  }),
  showSnackbar: PropTypes.func,
};

export { ConnectedDeletePackage as DeletePackage, DeletePackage as DeletePackageModal };
