import { requestHedgeDateAccess } from 'shared/helpers/accesses/hedges';
import { ENTER_PRICES } from 'shared/helpers/constants/hedges/enterPricesConstants';
import { hedgeTypes } from 'shared/helpers/constants/hedges/hedgeType';
import {
  allowedMaterialTypesForBuyer,
  allowedMaterialTypesForCustomer,
  materialTypes,
} from 'shared/helpers/constants/hedges/materialType';
import { message } from 'shared/helpers/constants/hedges/validateHedgesConstants';
import {
  denominationTable,
  denominatedIn,
} from 'shared/helpers/constants/materials/denominationTable';
import { isBuyerUser, isCustomerUser } from 'shared/helpers/matchers/checkUserType';
import { safeParseFloat } from 'shared/helpers/parsers/number';
import { replaceEmpty } from 'shared/helpers/parsers/text';
import { commaValidator } from 'shared/helpers/validations/commaValidator';
import {
  FLOAT_REGEX_GEN,
  INTEGER_REGEX,
  PRICE_REGEX,
} from 'shared/helpers/validations/regularExpressions';

function validateHedge(hedge, props) {
  const error = {};
  const materialTypeValue = hedge.materialType && hedge.materialType.value;
  const hedgeTypeValue = hedge.hedgeType && hedge.hedgeType.value;

  const {
    auth: {
      user: {
        userType,
        relatedCompany: { trustedCompany },
      },
    },
    enterPlacedHedges,
    marketAvailabilityDetails: { marketHoliday, inMarketHours },
  } = props;
  const isCustomer = isCustomerUser(userType);
  const isBuyer = isBuyerUser(userType);

  if (!hedge.relatedCompany) {
    error.relatedCompany = message.RELATED_COMPANY_EMPTY;
  } else if (
    (marketHoliday || !inMarketHours) &&
    !requestHedgeDateAccess({ userType, relatedCompany: hedge.relatedCompany })
  ) {
    error.relatedCompany = message.INVALID_COMPANY_FOR_FUTURE_HEDGE;
  }

  if (!hedgeTypeValue) {
    error.hedgeType = message.HEDGE_TYPE_EMPTY;
  }

  if (!materialTypeValue && !enterPlacedHedges) {
    error.materialType = message.MATERIAL_TYPE_EMPTY;
  } else if (
    isCustomer &&
    !Object.values(allowedMaterialTypesForCustomer).includes(materialTypeValue)
  ) {
    error.materialType = message.MATERIAL_TYPE_CUSTOMER;
  } else if (isBuyer && !Object.values(allowedMaterialTypesForBuyer).includes(materialTypeValue)) {
    error.materialType = message.MATERIAL_TYPE_BUYER;
  }

  if (
    materialTypeValue === materialTypes.foil_canned &&
    hedge.materialWeight &&
    hedge.materialUnits
  ) {
    error.materialUnits = message.MATERIAL_BOTH_FILLED;
  } else if (!hedge.materialWeight && !hedge.materialUnits) {
    error.materialUnits = message.MATERIAL_UNITS_EMPTY;
    error.materialWeight = message.MATERIAL_WEIGHT_EMPTY;
  }

  if (!hedge.materialWeight && denominationTable[materialTypeValue] === denominatedIn.weight) {
    error.materialWeight = message.MATERIAL_WEIGHT_EMPTY;
  }

  if (!hedge.materialUnits && denominationTable[materialTypeValue] === denominatedIn.units) {
    error.materialUnits = message.MATERIAL_UNITS_EMPTY;
  }

  if (!hedge.relatedLot && hedgeTypeValue !== hedgeTypes.blanked) {
    error.relatedLot = message.RELATED_LOT_EMPTY;
  }

  if (!!hedge.materialUnits && !INTEGER_REGEX.test(hedge.materialUnits)) {
    error.materialUnits = message.MATERIAL_UNITS_FORMAT;
  } else if (!!hedge.materialUnits && +hedge.materialUnits < 1) {
    error.materialUnits = message.MATERIAL_UNITS_LT_1;
  } else if (!!hedge.materialUnits && hedge.materialUnits.length > 10) {
    error.materialUnits = message.MATERIAL_UNITS_FORMAT;
  }

  if (!!hedge.materialWeight && !FLOAT_REGEX_GEN(2).test(hedge.materialWeight)) {
    error.materialWeight = message.MATERIAL_WEIGHT_FORMAT;
  } else if (!!hedge.materialWeight && hedge.materialWeight.replace(',', '.') < 0) {
    error.materialWeight = message.MATERIAL_WEIGHT_ZERO;
  } else if (!!hedge.materialWeight && hedge.materialWeight.length > 10) {
    error.materialWeight = message.MATERIAL_WEIGHT_FORMAT;
  }

  const platinumOz = replaceEmpty(hedge.platinumOz, '');
  if (replaceEmpty(platinumOz, '').length === 0) {
    error.platinumOz = message.PLATINUM_OZ_EMPTY;
  } else if (platinumOz.length > 10) {
    error.platinumOz = message.PLATINUM_OZ_FORMAT;
  } else if (+platinumOz.replace(',', '.') < 1 && +platinumOz.replace(',', '.') !== 0) {
    error.platinumOz = message.PLATINUM_OZ_ZERO;
  } else if (isCustomer && !trustedCompany && !FLOAT_REGEX_GEN(1).test(platinumOz)) {
    error.platinumOz = message.PLATINUM_OZ_FORMAT;
  } else if (!FLOAT_REGEX_GEN(3).test(platinumOz)) {
    error.platinumOz = message.PLATINUM_OZ_FORMAT;
  } else if (
    isCustomer &&
    Number.isFinite(+hedge.suggestedPlatinumOz) &&
    !trustedCompany &&
    safeParseFloat(platinumOz) > hedge.suggestedPlatinumOz
  ) {
    error.platinumOz = message.PLATINUM_OZ_LESS(hedge.suggestedPlatinumOz);
  } else if (isCustomer && Number.isFinite(+hedge.suggestedPlatinumOz) && trustedCompany) {
    if (
      Number.isFinite(+hedge.maximumPlatinumOz) &&
      safeParseFloat(platinumOz) > hedge.maximumPlatinumOz
    ) {
      error.platinumOz = message.HEDGE_OUNCES_OVER_LOT_MAXIMUM;
    } else if (safeParseFloat(platinumOz) > 2 * hedge.suggestedPlatinumOz) {
      error.platinumOz = message.HEDGE_OUNCES_OVER_MAXIMUM;
    }
  } else if (
    isBuyer &&
    Number.isFinite(+hedge.suggestedPlatinumOz) &&
    safeParseFloat(platinumOz) !== +hedge.suggestedPlatinumOz &&
    safeParseFloat(platinumOz) > 0
  ) {
    error.platinumOz = message.PLATINUM_OZ_EQUAL_SUGGESTED;
  } else if (
    Number.isFinite(+hedge.maximumPlatinumOz) &&
    safeParseFloat(platinumOz) > hedge.maximumPlatinumOz
  ) {
    error.platinumOz = message.HEDGE_OUNCES_OVER_LOT_MAXIMUM;
  }

  const palladiumOz = replaceEmpty(hedge.palladiumOz, '');
  if (replaceEmpty(palladiumOz, '').length === 0) {
    error.palladiumOz = message.PALLADIUM_OZ_EMPTY;
  } else if (palladiumOz.length > 10) {
    error.palladiumOz = message.PALLADIUM_OZ_FORMAT;
  } else if (+palladiumOz.replace(',', '.') < 1 && +palladiumOz.replace(',', '.') !== 0) {
    error.palladiumOz = message.PALLADIUM_OZ_ZERO;
  } else if (isCustomer && !trustedCompany && !FLOAT_REGEX_GEN(1).test(palladiumOz)) {
    error.palladiumOz = message.PALLADIUM_OZ_FORMAT;
  } else if (!FLOAT_REGEX_GEN(3).test(palladiumOz)) {
    error.palladiumOz = message.PALLADIUM_OZ_FORMAT;
  } else if (
    isCustomer &&
    Number.isFinite(+hedge.suggestedPalladiumOz) &&
    !trustedCompany &&
    safeParseFloat(palladiumOz) > hedge.suggestedPalladiumOz
  ) {
    error.palladiumOz = message.PALLADIUM_OZ_LESS(hedge.suggestedPalladiumOz);
  } else if (isCustomer && Number.isFinite(+hedge.suggestedPalladiumOz) && trustedCompany) {
    if (
      Number.isFinite(+hedge.maximumPalladiumOz) &&
      safeParseFloat(palladiumOz) > hedge.maximumPalladiumOz
    ) {
      error.palladiumOz = message.HEDGE_OUNCES_OVER_LOT_MAXIMUM;
    } else if (safeParseFloat(palladiumOz) > 2 * hedge.suggestedPalladiumOz) {
      error.palladiumOz = message.HEDGE_OUNCES_OVER_MAXIMUM;
    }
  } else if (
    isBuyer &&
    Number.isFinite(+hedge.suggestedPalladiumOz) &&
    safeParseFloat(palladiumOz) !== +hedge.suggestedPalladiumOz &&
    safeParseFloat(palladiumOz) > 0
  ) {
    error.palladiumOz = message.PALLADIUM_OZ_EQUAL_SUGGESTED;
  } else if (
    Number.isFinite(+hedge.maximumPalladiumOz) &&
    safeParseFloat(palladiumOz) > hedge.maximumPalladiumOz
  ) {
    error.palladiumOz = message.HEDGE_OUNCES_OVER_LOT_MAXIMUM;
  }

  const rhodiumOz = replaceEmpty(hedge.rhodiumOz, '');
  if (replaceEmpty(rhodiumOz, '').length === 0) {
    error.rhodiumOz = message.RHODIUM_OZ_EMPTY;
  } else if (rhodiumOz.length > 10) {
    error.rhodiumOz = message.RHODIUM_OZ_FORMAT;
  } else if (+rhodiumOz.replace(',', '.') < 1 && +rhodiumOz.replace(',', '.') !== 0) {
    error.rhodiumOz = message.RHODIUM_OZ_ZERO;
  } else if (isCustomer && !trustedCompany && !FLOAT_REGEX_GEN(1).test(rhodiumOz)) {
    error.rhodiumOz = message.RHODIUM_OZ_FORMAT;
  } else if (!FLOAT_REGEX_GEN(3).test(rhodiumOz)) {
    error.rhodiumOz = message.RHODIUM_OZ_FORMAT;
  } else if (
    isCustomer &&
    Number.isFinite(+hedge.suggestedRhodiumOz) &&
    !trustedCompany &&
    safeParseFloat(rhodiumOz) > hedge.suggestedRhodiumOz
  ) {
    error.rhodiumOz = message.RHODIUM_OZ_LESS(hedge.suggestedRhodiumOz);
  } else if (isCustomer && Number.isFinite(+hedge.suggestedRhodiumOz) && trustedCompany) {
    if (
      Number.isFinite(+hedge.maximumRhodiumOz) &&
      safeParseFloat(rhodiumOz) > hedge.maximumRhodiumOz
    ) {
      error.rhodiumOz = message.HEDGE_OUNCES_OVER_LOT_MAXIMUM;
    } else if (safeParseFloat(rhodiumOz) > 2 * hedge.suggestedRhodiumOz) {
      error.rhodiumOz = message.HEDGE_OUNCES_OVER_MAXIMUM;
    }
  } else if (
    isBuyer &&
    Number.isFinite(+hedge.suggestedRhodiumOz) &&
    safeParseFloat(rhodiumOz) !== +hedge.suggestedRhodiumOz &&
    safeParseFloat(rhodiumOz) > 0
  ) {
    error.rhodiumOz = message.RHODIUM_OZ_EQUAL_SUGGESTED;
  } else if (
    Number.isFinite(+hedge.maximumRhodiumOz) &&
    safeParseFloat(rhodiumOz) > hedge.maximumRhodiumOz
  ) {
    error.rhodiumOz = message.HEDGE_OUNCES_OVER_LOT_MAXIMUM;
  }

  if (
    platinumOz &&
    palladiumOz &&
    rhodiumOz &&
    +platinumOz === 0 &&
    +palladiumOz === 0 &&
    +rhodiumOz === 0
  ) {
    error.platinumOz = message.ALL_OUNCES_ZERO;
    error.palladiumOz = message.ALL_OUNCES_ZERO;
    error.rhodiumOz = message.ALL_OUNCES_ZERO;
  }

  if (!hedge.deliveryDate) {
    error.deliveryDate = message.DELIVERY_DATE_EMPTY;
  }

  if (!hedge.requestedHedgeDate) {
    error.requestedHedgeDate = message.REQUEST_HEDGE_DATE_EMPTY;
  }

  if (!hedge.requestedHedgeTime) {
    error.requestedHedgeTime = message.REQUEST_HEDGE_TIME_EMPTY;
  }

  if (
    !hedge.deliveryDateGRG &&
    (!hedge.relatedLot || hedge.relatedLot.statusForGlobal !== 'shipped_from_global')
  ) {
    error.deliveryDateGRG = message.DELIVERY_DATE_GRG_EMPTY;
  }

  if (hedge.customerInstructions && hedge.customerInstructions.length > 250) {
    error.customerInstructions = message.CUSTOMER_INSTRUCTIONS_FORMAT;
  }

  if (enterPlacedHedges) {
    if (!hedge.placedAt) {
      error.placedAt = message.PLACED_ON_EMPTY;
    }

    if (+platinumOz !== 0 || platinumOz === '') {
      if (!replaceEmpty(hedge.platinumPriceActual, '').length) {
        error.platinumPriceActual = ENTER_PRICES.PLATINUM_PRICE_ACTUAL_EMPTY;
      } else if (
        !PRICE_REGEX.test(hedge.platinumPriceActual) ||
        (hedge.platinumPriceActual && hedge.platinumPriceActual.length > 10)
      ) {
        error.platinumPriceActual = ENTER_PRICES.PLATINUM_PRICE_ACTUAL_INVALID;
      } else if (safeParseFloat(hedge.platinumPriceActual) < 0) {
        error.platinumPriceActual = ENTER_PRICES.PLATINUM_PRICE_ACTUAL_NEGATIVE;
      }

      if (!replaceEmpty(hedge.platinumPriceCust, '').length) {
        error.platinumPriceCust = ENTER_PRICES.PLATINUM_PRICE_CUST_EMPTY;
      } else if (
        !PRICE_REGEX.test(hedge.platinumPriceCust) ||
        (hedge.platinumPriceCust && hedge.platinumPriceCust.length > 10)
      ) {
        error.platinumPriceCust = ENTER_PRICES.PLATINUM_PRICE_CUST_INVALID;
      } else if (safeParseFloat(hedge.rhodiumPriceActual) < 0) {
        error.platinumPriceCust = ENTER_PRICES.PLATINUM_PRICE_CUST_NEGATIVE;
      }
    }

    if (+palladiumOz !== 0 || palladiumOz === '') {
      if (!replaceEmpty(hedge.palladiumPriceActual, '').length) {
        error.palladiumPriceActual = ENTER_PRICES.PALLADIUM_PRICE_ACTUAL_EMPTY;
      } else if (
        !PRICE_REGEX.test(hedge.palladiumPriceActual) ||
        (hedge.palladiumPriceActual && hedge.palladiumPriceActual.length > 10)
      ) {
        error.palladiumPriceActual = ENTER_PRICES.PALLADIUM_PRICE_ACTUAL_INVALID;
      } else if (safeParseFloat(hedge.palladiumPriceActual) < 0) {
        error.palladiumPriceActual = ENTER_PRICES.PALLADIUM_PRICE_ACTUAL_NEGATIVE;
      }

      if (!replaceEmpty(hedge.palladiumPriceCust, '').length) {
        error.palladiumPriceCust = ENTER_PRICES.PALLADIUM_PRICE_CUST_EMPTY;
      } else if (
        !PRICE_REGEX.test(hedge.palladiumPriceCust) ||
        (hedge.palladiumPriceCust && hedge.palladiumPriceCust.length > 10)
      ) {
        error.palladiumPriceCust = ENTER_PRICES.PALLADIUM_PRICE_CUST_INVALID;
      } else if (safeParseFloat(hedge.palladiumPriceCust) < 0) {
        error.palladiumPriceCust = ENTER_PRICES.PALLADIUM_PRICE_CUST_NEGATIVE;
      }
    }

    if (+rhodiumOz !== 0 || rhodiumOz === '') {
      if (!replaceEmpty(hedge.rhodiumPriceActual, '').length) {
        error.rhodiumPriceActual = ENTER_PRICES.RHODIUM_PRICE_ACTUAL_EMPTY;
      } else if (
        !PRICE_REGEX.test(hedge.rhodiumPriceActual) ||
        (hedge.rhodiumPriceActual && hedge.rhodiumPriceActual.length > 10)
      ) {
        error.rhodiumPriceActual = ENTER_PRICES.RHODIUM_PRICE_ACTUAL_INVALID;
      } else if (safeParseFloat(hedge.rhodiumPriceActual) < 0) {
        error.rhodiumPriceActual = ENTER_PRICES.RHODIUM_PRICE_ACTUAL_NEGATIVE;
      }

      if (!replaceEmpty(hedge.rhodiumPriceCust, '').length) {
        error.rhodiumPriceCust = ENTER_PRICES.RHODIUM_PRICE_CUST_EMPTY;
      } else if (
        !PRICE_REGEX.test(hedge.rhodiumPriceCust) ||
        (hedge.rhodiumPriceCust && hedge.rhodiumPriceCust.length > 10)
      ) {
        error.rhodiumPriceCust = ENTER_PRICES.RHODIUM_PRICE_CUST_INVALID;
      } else if (safeParseFloat(hedge.rhodiumPriceCust) < 0) {
        error.rhodiumPriceCust = ENTER_PRICES.RHODIUM_PRICE_CUST_NEGATIVE;
      }
    }
  }

  commaValidator(
    {
      materialUnits: hedge.materialUnits,
      materialWeight: hedge.materialWeight,
      platinumOz: hedge.platinumOz,
      palladiumOz: hedge.palladiumOz,
      rhodiumOz: hedge.rhodiumOz,
      platinumPriceActual: hedge.platinumPriceActual,
      palladiumPriceActual: hedge.palladiumPriceActual,
      rhodiumPriceActual: hedge.rhodiumPriceActual,
      platinumPriceCust: hedge.platinumPriceCust,
      palladiumPriceCust: hedge.palladiumPriceCust,
      rhodiumPriceCust: hedge.rhodiumPriceCust,
    },
    error,
  );

  return error;
}

function validate(values, props) {
  const deliveryDateErrors = (values.hedges || []).map(hedge => {
    const lot = hedge.relatedLot && hedge.relatedLot.value;
    const hedgesWithSameLot = values.hedges.filter(
      ({ relatedLot, formId }) => relatedLot && relatedLot.value === lot && formId !== hedge.formId,
    );

    const hasDifferentDate = hedgesWithSameLot.some(
      otherHedge => otherHedge.deliveryDate?.valueOf() !== hedge.deliveryDate?.valueOf(),
    );

    const hasDifferentDateGRG = hedgesWithSameLot.some(
      otherHedge => otherHedge.deliveryDateGRG?.valueOf() !== hedge.deliveryDateGRG?.valueOf(),
    );

    return {
      ...(hasDifferentDate && { deliveryDate: message.DIFFERENT_DELIVERY_DATES }),
      ...(hasDifferentDateGRG && { deliveryDateGRG: message.DIFFERENT_DELIVERY_DATES }),
    };
  });

  return {
    hedges: (values.hedges || []).map((hedge, index) => ({
      ...validateHedge(hedge, props),
      ...deliveryDateErrors[index],
    })),
  };
}

export { validate };
