import axios from 'axios';

import { messageTypes } from 'shared/helpers/constants/snackbar/snackbarMessageTypes';
import { parseErrorData } from 'shared/helpers/dataParsers/terms/parseTermsError';

const API_HOST_AUTH = process.env.REACT_APP_API;

const submitForApprovalActionType = 'SUBMIT_TERMS';
function submitForApprovalAction() {
  return {
    type: submitForApprovalActionType,
  };
}

const submitForApprovalSuccessActionType = 'SUBMIT_TERMS_SUCCESS';
function submitForApprovalSuccessAction(terms) {
  return {
    type: submitForApprovalSuccessActionType,
    payload: { terms },
  };
}

const submitForApprovalFailureActionType = 'SUBMIT_TERMS_FAILURE';
function submitForApprovalFailureAction() {
  return {
    type: submitForApprovalFailureActionType,
  };
}

function submitForApproval(term) {
  return dispatch => {
    dispatch(submitForApprovalAction());
    return axios({
      method: 'post',
      url: `${API_HOST_AUTH}/terms/${term._id}/submitForApproval`,
    })
      .then(res => {
        dispatch(submitForApprovalSuccessAction(res.data.terms));
        return {
          message: res.data.message,
          messageType: messageTypes.success,
        };
      })
      .catch(error => {
        dispatch(submitForApprovalFailureAction());

        return {
          message: parseErrorData(error.data),
          messageType: messageTypes.error,
        };
      });
  };
}

export {
  submitForApprovalActionType,
  submitForApprovalSuccessActionType,
  submitForApprovalFailureActionType,
  submitForApproval,
};
