import { css } from 'styled-components';

import { isTablet, isPhone } from 'shared/helpers/constants/resolutionsConstants';

import { flatten } from 'utils/flatten';
import { getBrowserWidth } from 'utils/responsive';

const getColor = (value, map) => map[value] || value;

const cssVar = name => `var(--${name})`;

const pixelify = value => (typeof value === 'number' ? `${value}px` : value);

const pxToRem = fontSize => `${fontSize / 16}rem`;

const themed =
  (mapper = () => ({})) =>
  props => ({
    ...props,
    ...props.theme,
    ...mapper(props),
  });

const isMobile = () => getBrowserWidth() < isTablet;

const isSmartphone = () => getBrowserWidth() < isPhone;

const isTouchScreenDevice = () => 'ontouchstart' in document.documentElement;

const placeholder = 'placeholder';

const fillGaps = template =>
  template.map((row, index) => row.replace(/\./g, (_, pos) => `${placeholder}${index}${pos}`));

const sanitizeRow = row => row && row.replace(/]\.|\[/g, '');

const sanitizeTemplate = template => template.map(sanitizeRow);

const formatTemplate = template => template.reduce((acc, row) => `${acc} "${row}"`, '');

const splitTemplate = templates =>
  flatten(
    templates.map(template => {
      const parts = template.split(' ');
      const breakpoint = parts.length / 2;
      const first = parts.slice(0, breakpoint).join(' ');
      const last = parts.slice(breakpoint).join(' ');

      return first === last ? [first] : [first, last];
    }),
  ).filter(template => !!template);

const sizes = {
  smallDesktop: '(max-width: 1240px)',
  mobile: '(max-width: 991px)',
  smallMobile: '(max-width: 768px)',
  phone: '(max-width: 576px)',
  smallTablet: '(min-width: 576px) and (max-width: 768px)',
  landscapePhone: '(min-width: 576px) and (max-width: 992) and (orientation: landscape)',
  tablet: '(min-width: 576px) and (max-width: 991px)',
  largeTablet: '(min-width: 769px) and (max-width: 991px)',
  desktop: '(min-width: 992px)',
};

const media = Object.keys(sizes).reduce(
  (acc, label) => ({
    ...acc,
    [label]: (...args) => css`
      @media ${sizes[label]} {
        ${css(...args)}
      }
    `,
  }),
  {},
);

const scrollbar = css`
  ::-webkit-scrollbar {
    width: 23px;
    background-color: transparent;

    &-track {
      width: 17px;
      background-color: ${cssVar('outerSpaceBrighter')};
      background-clip: padding-box;
      border-right: 6px solid transparent;
      border-top: 4px solid transparent;
      border-bottom: 4px solid transparent;
    }

    &-thumb {
      width: 9px;
      border-right: 10px solid transparent;
      border-left: 4px solid transparent;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      background-clip: padding-box;
      background-color: ${cssVar('regentGrayLight')};
    }
  }
`;

export {
  getColor,
  cssVar,
  pixelify,
  pxToRem,
  themed,
  isMobile,
  isSmartphone,
  isTouchScreenDevice,
  fillGaps,
  sanitizeRow,
  sanitizeTemplate,
  formatTemplate,
  splitTemplate,
  media,
  scrollbar,
};
