import { statusesForGlobal } from 'shared/helpers/constants/lots/statusesForGlobal';
import { packageStatuses } from 'shared/helpers/constants/packages/packageType';
import { isWarehouseUser } from 'shared/helpers/matchers/checkUserType';

const viewAcceptLotForProcessingPageAccess = (
  { access, user },
  lot,
  packagesList,
  allowMissingPackages = false,
) => {
  const {
    lots: { acceptForProcessing },
  } = access;
  const countSheet = lot?.sentFromCompany?.materialCountRequired ? !!lot.countSheet : true;
  const availableForProcessing =
    lot?.acceptedForProcessingBy !== null && lot?.acceptedForProcessingAt !== null;
  const incomingPackages = packagesList?.incoming;
  const areIncomingPackagesAvailable = incomingPackages?.docs?.length !== 0;
  const missingWeight =
    lot.updateWeightsRequired &&
    incomingPackages?.docs.some(p => {
      return p.weightGrossActual === null || p.weightTareActual === null;
    });
  const isBillOfLadingUploaded = lot.billOfLading?.length > 0;
  const isWarehouse = isWarehouseUser(user.userType);

  return (
    acceptForProcessing &&
    (!isWarehouse || isBillOfLadingUploaded) &&
    lot.statusForGlobal === statusesForGlobal.ready_for_processing &&
    lot.processUponDelivery &&
    (countSheet || availableForProcessing) &&
    lot.canBeProcessed &&
    (allowMissingPackages || areIncomingPackagesAvailable) &&
    (allowMissingPackages || !missingWeight)
  );
};

const acceptLotForProcessingAccess = (auth, lot, packagesList, allowMissingPackages) => {
  return (
    viewAcceptLotForProcessingPageAccess(auth, lot, packagesList, allowMissingPackages) &&
    !packagesList?.incoming?.docs?.every(p => p.status === packageStatuses.inactive)
  );
};

export { acceptLotForProcessingAccess, viewAcceptLotForProcessingPageAccess };
