import {
  enterPoolAccountOuncesActionType,
  enterPoolAccountOuncesSuccessActionType,
  enterPoolAccountOuncesFailureActionType,
} from 'actions/PoolAccounts/enterPoolAccountOunces';

const INITIAL_STATE = {
  isPending: false,
};

const enterPoolAccountOuncesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case enterPoolAccountOuncesActionType:
      return {
        isPending: true,
      };
    case enterPoolAccountOuncesSuccessActionType:
    case enterPoolAccountOuncesFailureActionType:
      return {
        isPending: false,
      };
    default:
      return state;
  }
};

export { enterPoolAccountOuncesReducer };
