import React from 'react';
import styled from 'styled-components';

import { Button } from 'shared/components/Buttons/Button';
import { pixelify, media, cssVar } from 'shared/helpers/styling/styling';

const OverviewHeaderLayout = ({ className, header, subheader, actions, children }) => (
  <div className={className}>
    <Headers>
      <h2>{header || children}</h2>
      {subheader && <h4>{subheader}</h4>}
    </Headers>
    <Actions>{actions}</Actions>
  </div>
);

const OverviewHeader = styled(OverviewHeaderLayout)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${({ marginBottom }) => `15px 0 ${pixelify(marginBottom)} 0`};

  @media print {
    margin: 15px 0;
  }

  ${media.phone`
    flex-direction: column;
    width: 100%;
    padding: 22px 22px 0 22px;
    margin-bottom: 30px;

    ${Button} {
      width: 100%
    }
  `}
`;

const Headers = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  ${media.phone`
    align-self: flex-start;
    `}

  h4 {
    color: ${cssVar('whiteGRG')};

    ${media.phone`
    font-size: 15px;
    margin-bottom: 1rem;
    `}
  }
`;

const Actions = styled.div`
  display: flex;
  align-items: center;

  ${media.phone`
    margin-top: 20px;
    ${Button} {
      max-width: 100%;
    }
  `}

  @media print {
    display: none;
  }
`;

OverviewHeader.defaultProps = {
  marginBottom: 50,
};

export { OverviewHeader };
