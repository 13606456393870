import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getCfuActionType = 'CFU_GET';
function getCfuAction() {
  return { type: getCfuActionType };
}

const getCfuActionSuccessType = 'CFU_GET_SUCCESS';
function getCfuActionSuccessAction(data) {
  return {
    type: getCfuActionSuccessType,
    payload: data,
  };
}

const getCfuFailureActionType = 'CFU_GET_FAILURE';
function getCfuFailureAction() {
  return {
    type: getCfuFailureActionType,
  };
}

function getCfuDocuments(id, type) {
  return dispatch => {
    dispatch(getCfuAction());
    return axios({
      method: 'GET',
      url: `${API_HOST_AUTH}/cfu/${id}`,
      params: {
        type,
      },
    })
      .then(item => {
        dispatch(getCfuActionSuccessAction(item.data));
        return item.data;
      })
      .catch(() => {
        dispatch(getCfuFailureAction());
        return Promise.reject();
      });
  };
}

export {
  getCfuActionType,
  getCfuAction,
  getCfuActionSuccessType,
  getCfuActionSuccessAction,
  getCfuFailureActionType,
  getCfuFailureAction,
  getCfuDocuments,
};
