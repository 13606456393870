import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled from 'styled-components';

export const StyledRequirementMatcher = styled.div`
  padding: 5px;
`;

export const StyledRequirementMatcherList = styled(TransitionGroup)`
  padding-left: 20px;
  margin-bottom: 0;
`;

export const StyledRequirementMatcherListRequirement = styled(CSSTransition)`
  color: var(--solidRed);
  padding: 3px 0;
  text-align: left;

  .enter {
    opacity: 0.01;
    transform: scale(0.9) translateY(30%);
  }

  .enter-active {
    opacity: 1;
    transform: scale(1) translateY(0%);
    transition: all 300ms ease-out;
  }

  .exit {
    opacity: 1;
    transform: scale(1) translateY(0%);
  }

  .exit-active {
    opacity: 0.01;
    transform: scale(0.9) translateY(30%);
    transition: all 300ms ease-out;
  }
`;
