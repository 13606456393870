import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const deleteCommodityType = 'DELETE_COMMODITY';
const deleteCommodityAction = () => ({
  type: deleteCommodityType,
});

const deleteCommoditySuccessType = `${deleteCommodityType}_SUCCESS`;
const deleteCommoditySuccessAction = () => ({
  type: deleteCommoditySuccessType,
});

const deleteCommodityFailureType = `${deleteCommodityType}_FAILURE`;
const deleteCommodityFailureAction = () => ({
  type: deleteCommodityFailureType,
});

const deleteCommodity = id => dispatch => {
  dispatch(deleteCommodityAction());

  return axios
    .delete(`${API_HOST_AUTH}/commodity/${id}`)
    .then(res => {
      dispatch(deleteCommoditySuccessAction());

      return res.data;
    })
    .catch(err => {
      dispatch(deleteCommodityFailureAction());

      return Promise.reject(err.data);
    });
};

export {
  deleteCommodityType,
  deleteCommoditySuccessType,
  deleteCommodityFailureType,
  deleteCommodity,
};
