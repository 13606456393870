import { SHARED } from 'shared/helpers/constants/sharedConstants';

import { companyInformation } from '../versioning/companyInformation';
import { fieldPrefix } from '../versioning/fieldPrefixes';

const LOTS = {
  BUSINESS_DAYS_OUT: 'Business days out',
  NEW: 'New lot',
  SECTION: 'Section',
  SUBSECTION: 'Subsection',
  DATE: 'Date',
  NAME: 'Name',
  VIRTUAL_LOT: 'Virtual Lot',
  CREATE_VIRTUAL_LOT: 'Create a virtual lot',
  CREATE_VIRTUAL_LOT_PROGRESS: 'Creating a virtual lot in progress',
  UPDATE_VIRTUAL_LOT: 'Update virtual lot',
  UPDATE_LOT: 'Update lot',
  UPDATE_VIRTUAL_LOT_WEIGHT: 'Update virtual lot weight',
  DELAYED: 'Delayed',
  MISSING_LBS: 'Missing lbs',
  INCOMING: 'Incoming',
  GRADING: 'Grading',
  COUNTING_REQUIRED: "Counting Req'd",
  HOLD: 'Hold',
  RELEASE: 'Release',
  COUNT: 'Count',
  CURRENTLY_PROCESSING: 'Currently Processing',
  COMPLETE: 'Complete',
  DASHBOARD: 'Dashboard',
  LOTS: 'Lots',
  LOT: 'Lot',
  GENERAL_DETAILS: 'General details',
  ALL_LOTS: 'All lots',
  OVERVIEW: 'Overview',
  INCOMING_LOTS: 'Incoming lots',
  OUTGOING_LOTS: 'Outgoing lots',
  OVERVIEW_INCOMING: 'Incoming lot overview',
  OVERVIEW_OUTGOING: 'Outgoing lot overview',
  CREATE_A_LOT: 'Create a lot',
  DELETE_LOT: 'Delete lot',
  EDIT_LOT: 'Edit lot',
  ENTER_ASSAY_MANUALLY: 'Enter assay manually',
  UPDATE_LOCATION: 'Update location',
  UPDATE_LOT_LOCATION: 'Update lot location',
  SHIPMENT_INFO: 'Shipment information',
  CREATE_PACKAGE: 'Create a package',
  CREATE_PACKAGES: 'Create packages',
  CREATE_INCOMING_PACKAGE: 'Create an incoming package',
  CREATE_INCOMING_PACKAGES: 'Create incoming packages',
  INACTIVATE_PACKAGES_DESCRIPTION:
    'Do you want to inactivate all received sensor packages in this lot?',
  REQUEST_INSPECTION: `Request an${SHARED.NON_BREAKING_SPACE}inspection`,
  SCHEDULE_INSPECTION: `Schedule an${SHARED.NON_BREAKING_SPACE}inspection`,
  PROCESS_UPON_DELIVERY: 'Process upon delivery',
  CHANGE_PROCESS_UPON_DELIVERY: 'Process upon delivery',
  DO_NOT_PROCESS_UPON_DELIVERY: 'Do not process',
  FUNDS_TYPE: 'Funds type',
  SELECT_FUNDS_TYPE: '-- select funds type --',
  SENT_FROM_COMPANY_LOCATION: 'Sent from company location',
  SENT_FROM_COMPANY: 'Sent from company',
  SHORTENED_SENT_FROM_COMPANY: 'From company',
  SHORTENED_SENT_FROM_COMPANY_LOCATION: 'From location',
  CUSTOMER_INSTRUCTIONS: 'Customer instructions',
  CONVERTER_COUNTS: 'Converter Counts',
  MONO: {
    label: 'Mono',
    description: 'Number of regular ceramic catalyst alone',
  },
  FOIL: {
    label: 'Foil',
    description: 'Number of foil units alone',
  },
  EMPTY: {
    label: 'Empty',
    description: 'Number of empty converters alone',
  },
  AFT: {
    label: 'AFT',
    description: 'Number of aftermarket units alone',
  },
  GRG_QTY_TOTAL: {
    label: `${fieldPrefix} QTY Total`,
    description: 'Number of Global declared units total in QTY section of PL reconciliation',
  },
  BEAD: {
    label: 'Bead',
    description: 'Number of bead converters alone',
  },
  STATUS_FOR_CUSTOMER: 'Status for customer',
  STATUS: 'Status',
  STATUS_FOR_GLOBAL: `Status for ${companyInformation.representativeName}`,
  SHORTENED_STATUS_FOR_CUSTOMER: 'Customer status',
  SHORTENED_STATUS_FOR_GLOBAL: `${companyInformation.representativeName} status`,
  WEIGHT: 'Weight',
  INVOICING: 'Invoicing',
  PROCESSING: 'Processing',
  ASSAY: 'Assay',
  ASSAY_RESULTS: 'Assay results',
  ASSAY_RESULTS_HEAVIES: 'Assay results - Heavies',
  ASSAY_RESULTS_A: 'Assay results - A',
  ASSAY_RESULTS_FINES: 'Assay results - Fines',
  ASSAY_RESULTS_B: 'Assay results - B',
  ASSAY_RESULTS_ENTERED_MANUALLY: 'Assay results entered manually',
  UPLOADED_MANUALLY: 'UPLOADED MANUALLY',
  ACTIVITY: 'Activity',
  DOCUMENTS: 'Documents',
  INSPECTION: 'Inspection',
  GRADING_RECEIPT: 'Grading receipt',
  WEIGHT_GROSS_DECLARED: 'Gross declared',
  SHORTENED_WEIGHT_GROSS_DECLARED: 'Gross',
  SHORTENED_WEIGHT_GROSS_ACTUAL: 'Gross',
  STILLWATER_SHIPMENT_ID: 'Stillwater shipment ID',
  SHORTENED_STILLWATER_SHIPMENT_ID: 'SWC ID',
  INSPECTION_REQUESTED: 'Inspection requested',
  INSPECTION_SCHEDULED: 'Inspection scheduled',
  INSPECTION_REQUESTED_BY: 'Inspection requested by',
  INSPECTION_SCHEDULED_BY: 'Inspection scheduled by',
  INSPECTION_SCHEDULED_FOR: 'Inspection scheduled for',
  WEIGHT_TARE_DECLARED: 'Tare declared',
  WEIGHT_NET_DECLARED: 'Net declared',
  WEIGHT_GROSS_ACTUAL: 'Gross actual',
  WEIGHT_TARE_ACTUAL: 'Tare actual',
  WEIGHT_NET_ACTUAL: 'Net actual',
  WEIGHT_GROSS_RECEIVED: 'Gross received',
  WEIGHT_TARE_RECEIVED: 'Tare received',
  WEIGHT_NET_WET_RECEIVED: 'Net wet received',
  WEIGHT_SCRAP_RECEIVED: 'Scrap received',
  WEIGHT_ADJUSTED_NET_WET_RECEIVED: 'Adjusted net wet received',
  PERCENT_MOISTURE: 'Percent moisture',
  WEIGHT_NET_DRY_RECEIVED: 'Net dry received',

  WEIGHT_GROSS_RECEIVED_HEAVIES: 'Gross received - Heavies',
  WEIGHT_TARE_RECEIVED_HEAVIES: 'Tare received - Heavies',
  WEIGHT_NET_WET_RECEIVED_HEAVIES: 'Net wet received - Heavies',
  WEIGHT_SCRAP_RECEIVED_HEAVIES: 'Scrap received - Heavies',
  WEIGHT_ADJUSTED_NET_WET_RECEIVED_HEAVIES: 'Adjusted net wet received - Heavies',
  PERCENT_MOISTURE_HEAVIES: 'Percent moisture - Heavies',
  WEIGHT_NET_DRY_RECEIVED_HEAVIES: 'Net dry received - Heavies',
  WEIGHT_GROSS_RECEIVED_A: 'Gross received - A',
  WEIGHT_TARE_RECEIVED_A: 'Tare received - A',
  WEIGHT_NET_WET_RECEIVED_A: 'Net wet received - A',
  WEIGHT_SCRAP_RECEIVED_A: 'Scrap received - A',
  WEIGHT_ADJUSTED_NET_WET_RECEIVED_A: 'Adjusted net wet received - A',
  PERCENT_MOISTURE_A: 'Percent moisture - A',
  WEIGHT_NET_DRY_RECEIVED_A: 'Net dry received - A',

  WEIGHT_GROSS_RECEIVED_FINES: 'Gross received - Fines',
  WEIGHT_TARE_RECEIVED_FINES: 'Tare received - Fines',
  WEIGHT_NET_WET_RECEIVED_FINES: 'Net wet received - Fines',
  WEIGHT_SCRAP_RECEIVED_FINES: 'Scrap received - Fines',
  WEIGHT_ADJUSTED_NET_WET_RECEIVED_FINES: 'Adjusted net wet received - Fines',
  PERCENT_MOISTURE_FINES: 'Percent moisture - Fines',
  WEIGHT_NET_DRY_RECEIVED_FINES: 'Net dry received - Fines',
  WEIGHT_GROSS_RECEIVED_B: 'Gross received - B',
  WEIGHT_TARE_RECEIVED_B: 'Tare received - B',
  WEIGHT_NET_WET_RECEIVED_B: 'Net wet received - B',
  WEIGHT_SCRAP_RECEIVED_B: 'Scrap received - B',
  WEIGHT_ADJUSTED_NET_WET_RECEIVED_B: 'Adjusted net wet received - B',
  PERCENT_MOISTURE_B: 'Percent moisture - B',
  WEIGHT_NET_DRY_RECEIVED_B: 'Net dry received - B',

  PLATINUM_CONTAINED_OZ: 'Platinum contained oz',
  PALLADIUM_CONTAINED_OZ: 'Palladium contained oz',
  RHODIUM_CONTAINED_OZ: 'Rhodium contained oz',

  PLATINUM_CONTAINED_OZ_HEAVIES: 'Platinum contained oz - Heavies',
  PALLADIUM_CONTAINED_OZ_HEAVIES: 'Palladium contained oz - Heavies',
  RHODIUM_CONTAINED_OZ_HEAVIES: 'Rhodium contained oz - Heavies',
  PLATINUM_CONTAINED_OZ_A: 'Platinum contained oz - A',
  PALLADIUM_CONTAINED_OZ_A: 'Palladium contained oz - A',
  RHODIUM_CONTAINED_OZ_A: 'Rhodium contained oz - A',

  PLATINUM_CONTAINED_OZ_FINES: 'Platinum contained oz - Fines',
  PALLADIUM_CONTAINED_OZ_FINES: 'Palladium contained oz - Fines',
  RHODIUM_CONTAINED_OZ_FINES: 'Rhodium contained oz - Fines',
  PLATINUM_CONTAINED_OZ_B: 'Platinum contained oz - B',
  PALLADIUM_CONTAINED_OZ_B: 'Palladium contained oz - B',
  RHODIUM_CONTAINED_OZ_B: 'Rhodium contained oz - B',

  PLATINUM_RETURNABLE_OZ: 'Platinum returnable oz',
  PALLADIUM_RETURNABLE_OZ: 'Palladium returnable oz',
  RHODIUM_RETURNABLE_OZ: 'Rhodium returnable oz',

  PLATINUM_RETURNABLE_OZ_HEAVIES: 'Platinum returnable oz - Heavies',
  PALLADIUM_RETURNABLE_OZ_HEAVIES: 'Palladium returnable oz - Heavies',
  RHODIUM_RETURNABLE_OZ_HEAVIES: 'Rhodium returnable oz - Heavies',
  PLATINUM_RETURNABLE_OZ_A: 'Platinum returnable oz - A',
  PALLADIUM_RETURNABLE_OZ_A: 'Palladium returnable oz - A',
  RHODIUM_RETURNABLE_OZ_A: 'Rhodium returnable oz - A',

  PLATINUM_RETURNABLE_OZ_FINES: 'Platinum returnable oz - Fines',
  PALLADIUM_RETURNABLE_OZ_FINES: 'Palladium returnable oz - Fines',
  RHODIUM_RETURNABLE_OZ_FINES: 'Rhodium returnable oz - Fines',
  PLATINUM_RETURNABLE_OZ_B: 'Platinum returnable oz - B',
  PALLADIUM_RETURNABLE_OZ_B: 'Palladium returnable oz - B',
  RHODIUM_RETURNABLE_OZ_B: 'Rhodium returnable oz - B',

  GLOBAL_MOISTURE_TEST: `${companyInformation.representativeName} moisture test`,

  PLATINUM_ASSAY: 'Platinum assay',
  PALLADIUM_ASSAY: 'Palladium assay',
  RHODIUM_ASSAY: 'Rhodium assay',

  PLATINUM_ASSAY_HEAVIES: 'Platinum assay - Heavies',
  PALLADIUM_ASSAY_HEAVIES: 'Palladium assay - Heavies',
  RHODIUM_ASSAY_HEAVIES: 'Rhodium assay - Heavies',
  PLATINUM_ASSAY_A: 'Platinum assay - A',
  PALLADIUM_ASSAY_A: 'Palladium assay - A',
  RHODIUM_ASSAY_A: 'Rhodium assay - A',

  PLATINUM_ASSAY_FINES: 'Platinum assay - Fines',
  PALLADIUM_ASSAY_FINES: 'Palladium assay - Fines',
  RHODIUM_ASSAY_FINES: 'Rhodium assay - Fines',
  PLATINUM_ASSAY_B: 'Platinum assay - B',
  PALLADIUM_ASSAY_B: 'Palladium assay - B',
  RHODIUM_ASSAY_B: 'Rhodium assay - B',

  HEDGED_STATUS: 'Hedged status',
  HEDGED_LOT_DELIVERY_DEADLINE: 'Hedged lot delivery deadline',
  SHORTENED_HEDGED_LOT_DELIVERY_DEADLINE: 'Delivery deadline',
  COUNT_SHEET: 'Count sheet',
  GRG_SHIPMENT_SUMMARY: `${fieldPrefix} shipment summary`,
  GRG_FINAL_INVOICE: `${fieldPrefix} final invoice`,
  GRG_LOT: `${fieldPrefix} Lot`,
  CLIENT_LOT: 'Client lot',
  STILLWATER_SHIPMENT_SUMMARY: 'Stillwater shipment summary',
  STILLWATER_FINAL_INVOICE: 'Stillwater final invoice',
  STILLWATER_INITIAL_PAYMENT: 'Initial payment',
  STILLWATER_INITIAL_PAYMENT_CONFIRM: 'Confirm Initial payment',
  STILLWATER_INITIAL_PAYMENT_DATE: 'Initial payment date',
  STILLWATER_FINAL_PAYMENT: 'Final payment',
  STILLWATER_FINAL_PAYMENT_CONFIRM: 'Final payment Confirm',
  STILLWATER_FINAL_PAYMENT_DATE: 'Final payment date',
  GRG_PROFIT_AND_LOSS: `${fieldPrefix} Profit and Loss`,
  PROFIT_AND_LOSS: 'Profit and Loss',
  PROFIT_AND_LOSS_SHORT: 'P&L',
  GRG_LOT_NUMBER: `${fieldPrefix} lot number`,
  SHORTENED_GRG_LOT_NUMBER: 'Lot number',
  RECEIVED_BY: 'Received by',
  RECEIVED_ON: 'Received on',
  GRADED_BY: 'Graded by',
  MARKED_FOR_PROCESSING_BY: 'Marked for processing by',
  PROCESSED_BY: 'Processed by',
  MIXED_BY: 'Mixed by',
  SHIPPED_FROM_GLOBAL_BY: `Shipped from ${companyInformation.representativeName} by`,
  INVOICE_GENERATED_BY: 'Invoice generated by',
  INVOICE_APPROVED_BY: 'Invoice approved by',
  CREATED_BY: 'Created by',
  UPDATED_BY: 'Updated by',
  ACTIONS: 'Actions',
  LBS: 'lbs',
  PPM: 'ppm',
  TROY_OZ: 'troy oz',
  MATERIAL_TYPE: 'Material type',
  TRUCKING: 'Trucking',
  DEDUCTIONS: 'Deductions',
  DEDUCTION: 'Deduction',
  CREDITS: 'Credits',
  CREDIT: 'Credit',
  VALUE: 'Value',
  DESCRIPTION: 'Description',
  PROCESS_VIA: 'Process via',
  MIX_VIA: 'Mix via',
  LOCATION_STORED: 'Location stored',
  LOCATION: 'Location',
  BUILDING: 'Building',
  LANE: 'Lane',
  TERMS_APPLIED: 'Terms applied',
  PACKAGE_COUNT_INCOMING: 'Package count incoming',
  PACKAGE_COUNT_WAREHOUSE: 'Package count warehouse',
  PACKAGE_COUNT_OUTGOING: 'Package count outgoing',
  UPLOAD_COUNT_SHEET: 'Upload count sheet',
  UPLOAD_GRADING_RECEIPT: 'Upload grading receipt',
  UPLOAD_STILLWATER_SHIPMENT_SUMMARY: 'Upload SWC shipment summary',
  UPLOAD_STILLWATER_FINAL_INVOICE: 'Upload SWC final invoice',
  RETRY_IMPORT: 'Retry import',
  MOVE_TO_PROCESSING: 'Move to processing',
  ERRORS: {
    PROCESS_UPON_DELIVERY_ERROR: 'Process upon delivery has not been changed.',
    VIRTUAL_LOT_MOVE_TO_HOLD_ERROR: 'Virtual lot has not been moved to the hold section.',
    MOVE_TO_HOLD_ERROR: 'Lot has not been moved to the hold section.',
    RELEASE_ERROR: 'Lot has not been released.',
    UPDATE_LOT_ERROR: 'Lot has not been updated.',
    CREATE_LOT_ERROR: 'Lot has not been created.',
    DELETE_LOT_ERROR: 'Lot has not been deleted.',
    IMPORT_LOT_ERROR: 'Lot has not been imported.',
    INVALID_FILE: 'File is required.',
    INVALID_FILE_FORMAT: 'File has invalid format.',
    INVALID_FILE_NAME: 'File has invalid name.',
    INVALID_SIZE: 'Maximum file size is 20MB.',
  },
  AVAILABLE: 'Available',
  SELECTED: 'Selected',
  NO_MORE_LOTS: 'No more lots to select',
  DROP_HERE: 'Drop lots here',
  SELECTED_WEIGHT: 'Selected lots weight',
  AVAILABLE_LOTS_WEIGHT: 'Available lots weight',
  SCAN_RECEIVED_PACKAGES: 'Scan received packages',
  ACCEPT_FOR_GRADING: 'Accept for grading',
  GRADING_FINISHED: 'Grading finished',
  PROCESSING_OVERVIEW: 'Processing overview',
  MIXING_OVERVIEW: 'Mixing overview',
  ACCEPT_FOR_PROCESSING: 'Accept for processing',
  ACCEPT_FOR_MIXING: 'Accept for mixing',
  SCAN_PACKAGE: 'Scan package',
  RELEASE_PACKAGE: 'Release package',
  FILL_PACKAGES_WEIGHT: 'Fill packages weight',
  PACKAGE_SUCCESSFULLY_SCAN: 'Package successfully scanned',
  PACKAGE_SUCCESSFULLY_RELEASED: 'Package successfully released',
  PACKAGE_SCANNING_ERROR: 'Invalid package barcode',
  SCAN_PACKAGE_BARCODE: 'Please scan the package barcodes for this lot',
  SCAN_LAB_CAT_PACKAGE_BARCODE: 'Please scan the lab cat package barcodes',
  ENTER_LAB_CAT_PACKAGE_BARCODE: 'Enter lab cat package barcode',
  ENTER_PACKAGE_BARCODE: 'Enter package barcode',
  PROCESSING_COMPLETE: 'Processing complete',
  MIXING_COMPLETE: 'Mixing complete',
  REMOVE_COMMODITY: 'Remove commodity',
  UPDATE_SHIPMENT_INFORMATION: 'Update shipment information',
  EDIT_SHIPMENT_INFO: 'Edit shipment info',
  GENERATE_PROFIT_LOSS: 'Generate Profit & Loss',
  GENERATE_SHIPMENT_SUMMARY: 'Generate shipment summary',
  GENERATE_FINAL_INVOICE: 'Generate final invoice',
  GENERATE_FINAL_INVOICE_MOCKUP: 'Generate final invoice mockup',
  UPLOAD_CUSTOM_INVOICE_WARNING: `Are you sure you want to upload this file?
                                  This will replace the existing ${companyInformation.representativeName} final invoice for this lot.`,
  UPLOAD_COUNT_SHEET_WARNING: grgLotNumber =>
    `Are you sure you want to upload count sheet for lot ${grgLotNumber}?`,
  UPLOAD_GRADING_RECEIPT_WARNING: grgLotNumber =>
    `Are you sure you want to upload grading receipt for lot ${grgLotNumber}?`,
  UPLOAD_CUSTOM_INVOICE: 'Upload custom invoice',
  ADD: 'Add',
  ADD_CUSTOM_INVOICE: 'Add custom invoice',
  ADD_COUNT_SHEET: 'Add count sheet',
  ADD_GRADING_RECEIPT: 'Add grading receipt',
  SET_TO_FINALIZED: 'Set to finalized',
  ADMIN_SET_TO_FINALIZED: 'Set to finalized (admin only)',
  UPLOAD_CUSTOM_INVOICE_PROGRESS: 'Upload in progress.',
  APPROVE_RECEIPT: 'Approve receipt',
  REJECT_RECEIPT: 'Reject receipt',
  APPROVE: 'Approve',
  DENY: 'Deny',
  UPLOAD: 'Upload',
  FILES: 'Files',
  SWC_FILES: 'SWC Files',
  SCAN_FACTORY: name => `Please scan the ${name}`,
  ENTER_FACTORY: name => `Please enter the ${name}`,
  INVALID_FACTORY: name => `Invalid ${name}`,
  MANAGE_FINAL_INVOICE_HEADER: grgLotNumber =>
    `Approval required for ${grgLotNumber} final invoice.`,
  MANAGE_FINAL_INVOICE_INFO:
    grgLotNumber => `A Global final invoice has been generated for lot ${grgLotNumber}.
                                              Please review it and select Approve or Decline.`,
  MANAGE_SHIPMENT_SUMMARY_HEADER: grgLotNumber =>
    `Approval required for ${grgLotNumber} shipment summary.`,
  MANAGE_SHIPMENT_SUMMARY_INFO:
    grgLotNumber => `Shipment summary has been generated for lot ${grgLotNumber}.
                                                 Please review it and select Approve or Decline.`,
  MANAGE_PROFIT_AND_LOSS_NOTE: 'Note: this lot has not been finalized',
  MANAGE_PROFIT_AND_LOSS_HEADER: grgLotNumber =>
    `Approval required for ${grgLotNumber} Profit and Loss.`,
  MANAGE_PROFIT_AND_LOSS_INFO: (grgLotNumber, isNote) =>
    `A Profit and Loss document has been generated for lot ${grgLotNumber}. Please review it and select Approve or Deny. ${
      isNote ? '\nNote: this lot has not been finalized' : ''
    }`,
  PACKAGE_N_RELEASED: packageNumber => `Package ${packageNumber} released`,
  RELEASED_PACKAGES: 'Released',
  NOT_RELEASED_PACKAGES: 'Not released',
  RECEIVED_PACKAGES: 'Received',
  NOT_RECEIVED_PACKAGES: 'Not received',
  INACTIVATED_PACKAGES: 'Inactivated',
  NOT_INACTIVATED_PACKAGES: 'Not inactivated',
  SCANNED_BARCODE_IS_NOT_POST_MILL: 'The scanned barcode is not a post-mill package barcode.\n',
  ALL_PACKAGES_RELEASED: 'All packages have been released.',
  ALL_PACKAGES_RECEIVED: 'All packages have been received.',
  LOCATION_STORED_ADD: 'Add place',
  LOCATION_STORED_PLACE: 'Place',
  PRINT_LOT_LABEL: 'Print lot label',
  PRINT_LOT_LABEL_ZEBRA: 'Print lot label (zebra)',
  PRINT_LOT_LABEL_EBS: 'Print lot label (EBS)',
  PRINT_LOT_LABEL_SUCCESS: 'Lot label has been generated and is being downloaded.',
  PRINT_LOT_LABEL_ERROR: 'Lot label has not been generated correctly.',
  SETTLE_LOTS: 'Settle lots',
  SETTLE: 'Settle',
  PRINT_DUPLICATE: 'Print duplicate',
  PRINT_DUPLICATE_SCAN: 'Please scan the package or lot barcode',
  PRINT_DUPLICATE_ENTER: 'Enter code manually',
  PRINT_DUPLICATE_ERROR:
    'The entered barcode does not correspond to an existing lot number or package id',
  PRINT_DUPLICATE_SUCCESS: 'Lot found',
  COMPARE_LABELS: 'Compare labels',
  BARCODE_SUCCESSFULLY_SCAN: 'Barcode successfully scanned',
  BARCODE_SCANNING_ERROR: 'Invalid barcode',
  BARCODE_SCAN: 'Please scan the barcode',
  BARCODE_ENTER: 'Please enter the barcode',
  STILLWATER_SHIPMENT_ID_MISSING:
    'Lot material type or SWC ID missing. Please click "Edit shipment info" to add the missing information.',
  INTERNAL_COMPANY_STILLWATER_SHIPMENT_ID_MISSING:
    'This lot is missing a SWC shipment ID. Please click "Edit shipment info" to add the missing information.',
  APPLIED_TERMS_MISSING:
    'To generate the final invoice or final invoice mockup for this lot, it must have applied terms.',
  FINAL_INVOICE_MOCKUP: 'Final invoice mockup',
  CHANGE_RECEIVED_DATE: 'Change received date',
  VIEW_TIMELINE: 'View timeline',
  CUSTOM_LOT_NUMBER: 'Custom lot number',
  SETTLED_OUNCES_PRICES_MISSING:
    'An invoice cannot be generated until the prices for all settled ounces have been entered',
  CUSTOM_HEDGES_PRICES_MISSING:
    'Invoice cannot be generated until prices for all custom hedges have been entered.',
  HEDGE_ORDER_DOCUMENT: 'Hedge order document',
  UPLOAD_BILL_OF_LADING_WARNING: grgLotNumber =>
    `Do you want to upload Bill of Lading for lot ${grgLotNumber}?`,
  ADD_BILL_OF_LADING: 'Add Bill of Lading',
  UPLOAD_BILL_OF_LADING: 'Upload Bill of Lading',
  BILL_OF_LADING: 'Bill of Lading',
  MISSING_BILL_OF_LADING: 'Missing Bill of Lading',
  UPLOAD_POOL_SETTLEMENT_DOCUMENT: 'Upload pool settlement document',
  POOL_SETTLEMENT_DOCUMENT: 'Pool settlement document',
  LAB_REQUEST_FORM: 'Lab request form',
  GENERATE_SHIPMENT_SUMMARY_MODAL_STANDARD: 'Standard',
  GENERATE_SHIPMENT_SUMMARY_MODAL_SCRAPS: 'Scraps',
  GENERATE_SHIPMENT_SUMMARY_MODAL_MANUAL: 'Manual entry',
  MATERIAL_COUNT_WARNING: companyName =>
    `Material count is required for ${companyName}. To create new packages for the lot an incoming shipment must be sent from a customer.
    Please ask the customer to prepare a shipment.`,
  CREATE_PROCESSING_NOTE: 'Create processing note',
  NOTE: 'Note',
  LOT_ALREADY_MARKED_AS_PROCESSED: 'Lot has already been marked as processed.',
  LOT_STATUSES_WARNING: {
    INVOICE_GENERATED_AND_ACCEPTED: (destination = 'hedge') =>
      `This lot has an existing final invoice. You will need to re-generate the invoice in order for this ${destination} to be displayed on the final document.`,
  },
  BILL_OF_LADING_SHORT: 'BOL',
  BILL_OF_LADING_LIMIT: 5,
  GLOBAL_SHIPMENT_SUMMARY_DENIED: 'Global shipment summary has been denied.',
  REMOVE_SAMPLES_LIST_TITLE: 'Received - remove samples',
  ACTIVE_SAMPLE_PKG_INFO:
    'The lot cannot be accepted for processing until all sample packages have been inactivated.',
  PROCESSING_LOT_NOT_ACCEPT: 'The lot must have at least one package which can be processed.',
  PROCESSING_LOT_WITHOUT_POST_MILL_PACKAGE: 'The lot must have at least one post-mill package.',
  PROCESSING_LOT_WITHOUT_POST_SHEARS_PACKAGE: 'The lot must have at least one post-shears package.',
  PROCESSING_LOT_WITHOUT_POST_MIX_PACKAGE: 'The lot must have at least one post-mix package.',
  PROCESSING_LOT_WITHOUT_WEIGHTS:
    'The lot cannot be accepted for processing until all package weights are recorded.',
  UNPAID_PAYMENTS_EXIST: grgLotNumber =>
    `<div><b>Please note that lot ${grgLotNumber} has existing payments which have not been marked as paid. Are you sure you would like to approve this final payment without first updating those payments?</b></div>`,
  INACTIVATE_LAB_PACKAGES: 'Inactivate lab cat packages',
};

export { LOTS };
