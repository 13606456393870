import React, { Component } from 'react';

import { GroupButtons } from 'shared/components/Buttons';
import { GroupButton } from 'shared/components/Buttons/GroupButton/components/GroupButton';
import { FullPageList } from 'shared/components/FullPageList/FullPageList';
import { message } from 'shared/helpers/constants/hedges/createTrustedCompaniesConstants';
import { HEDGES } from 'shared/helpers/constants/hedges/hedgesConstants';
import {
  itemsTemplate,
  itemsTemplateMobile,
  itemsDetailsTemplate,
} from 'shared/helpers/constants/hedges/trustedCompaniesTableConfig';
import { deleteTrustedCompaniesModal } from 'shared/helpers/constants/modalConstants';
import {
  successOptions,
  dangerOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

class TrustedCompanies extends Component {
  componentWillUnmount() {
    this.props.clearPrivilegedCompanies();
  }

  deleteTrustedCompany(trustedCompany) {
    const { getTrustedCompanies, deleteTrustedCompany, showSnackbar } = this.props;

    deleteTrustedCompany(trustedCompany)
      .then(() => {
        getTrustedCompanies();
        showSnackbar(successOptions, message.TRUSTED_COMPANIES_DELETE_SUCCESS);
      })
      .catch(() => showSnackbar(dangerOptions, message.TRUSTED_COMPANIES_DELETE_ERROR));
  }

  renderActionButtons = () => (
    <GroupButtons>
      <GroupButton
        className="TrustedCompanies-create"
        access={this.props.auth.access.hedges.trustedCompanies.manage}
        onClick={() => this.props.history.push('/hedges/trusted-companies/create')}
        label={HEDGES.TRUSTED_COMPANIES.CREATE}
        icon="add-trusted-company"
      />
    </GroupButtons>
  );

  render() {
    const { privilegedCompaniesList, auth, openModal } = this.props;
    const manageAuth = auth.access.hedges.trustedCompanies.manage;
    const actions = manageAuth
      ? [
          {
            icon: 'bin',
            action: 'Delete',
            onClick: trustedCompany => () =>
              openModal(deleteTrustedCompaniesModal, () =>
                this.deleteTrustedCompany(trustedCompany),
              ),
          },
        ]
      : [];

    return (
      <FullPageList
        title={HEDGES.TRUSTED_COMPANIES.TITLE}
        actionButtons={this.renderActionButtons}
        itemsTemplate={itemsTemplate}
        itemsTemplateMobile={itemsTemplateMobile}
        itemsDetailsTemplate={itemsDetailsTemplate}
        actionsTemplate={actions}
        items={privilegedCompaniesList.privilegedCompanies.trusted}
        fetchList={this.props.getTrustedCompanies}
        isPending={privilegedCompaniesList.isPending}
      />
    );
  }
}

export { TrustedCompanies };
