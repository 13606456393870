const denominationTable = {
  aftermarkets: 'units',
  beads_canned: 'units',
  beads_decanned: 'weight',
  DPF_canned: 'units',
  DPF_decanned: 'weight',
  foil_canned: 'units_weight',
  foil_decanned: 'weight',
  high_grade_dust: 'weight',
  industrial_foil: 'weight',
  industrial_mono: 'weight',
  mono_canned: 'units',
  mono_decanned: 'weight',
  other: 'units_weight',
  sensors: 'weight',
  sweeps: 'weight',
};

const denominatedIn = {
  units: 'units',
  weight: 'weight',
  unitsWeight: 'units_weight',
};

export { denominationTable, denominatedIn };
