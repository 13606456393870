import { deleteLotSuccessActionType } from 'actions/Lots/deleteLot';
import {
  clearLotsListActionType,
  getLotsListActionType,
  getLotsListSuccessActionType,
  getLotsListFailureActionType,
} from 'actions/Lots/lotsList';

const INITIAL_STATE = {
  isPending: false,
  errorMessage: null,
  lots: [],
  limit: 50,
  page: 1,
  pages: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getLotsListActionType:
      return {
        ...state,
        isPending: true,
      };
    case getLotsListSuccessActionType:
      return {
        ...state,
        isPending: false,
        lots: action.payload.lots,
        page: action.payload.page,
        limit: action.payload.limit,
        pages: action.payload.pages,
      };
    case getLotsListFailureActionType:
      return {
        ...state,
        isPending: false,
        errorMessage: action.payload.errorMessage,
        lots: [],
        pages: null,
      };
    case deleteLotSuccessActionType:
      return {
        ...state,
        isPending: false,
        lots: state.lots.filter(lot => lot._id !== action.payload.lot.id),
      };
    case clearLotsListActionType:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
