import axios from 'axios';

import { outgoingSections } from 'shared/helpers/constants/lots/outgoingSections';

const { REACT_APP_API } = process.env;

const outgoingLotsOverviewActionType = 'OUTGOING_LOTS';
const outgoingLotsOverviewAction = section => ({
  type: outgoingLotsOverviewActionType,
  payload: { section },
});

const outgoingLotsOverviewSuccessActionType = `${outgoingLotsOverviewActionType}_SUCCESS`;
const outgoingLotsOverviewSuccessAction = (section, data) => ({
  type: outgoingLotsOverviewSuccessActionType,
  payload: { section, data },
});

const outgoingLotsOverviewFailureActionType = `${outgoingLotsOverviewActionType}_FAILURE`;
const outgoingLotsOverviewFailureAction = section => ({
  type: outgoingLotsOverviewFailureActionType,
  payload: { section },
});

const outgoingLotsOverviewSelectedActionType = `${outgoingLotsOverviewActionType}_SELECTED`;
const outgoingLotsOverviewSelectedAction = (section, selected) => ({
  type: outgoingLotsOverviewSelectedActionType,
  payload: { section, selected },
});

const outgoingLotsOverviewClearActionType = `${outgoingLotsOverviewActionType}_CLEAR`;
const outgoingLotsOverviewClearAction = () => ({
  type: outgoingLotsOverviewClearActionType,
});

const getOutgoingLotsOverview =
  (section, page = 1, limit = 0) =>
  dispatch => {
    dispatch(outgoingLotsOverviewAction(section));

    const endpoint = section.name === outgoingSections.unsentShipments.name ? 'shipments' : 'lots';

    return axios({
      method: 'post',
      url: `${REACT_APP_API}/${endpoint}/outgoing-overview`,
      data: JSON.stringify({
        ...section.query,
        pagination: { page, limit },
      }),
    })
      .then(res => {
        dispatch(outgoingLotsOverviewSuccessAction(section, res.data));
      })
      .catch(() => {
        dispatch(outgoingLotsOverviewFailureAction(section));
      });
  };

export {
  getOutgoingLotsOverview,
  outgoingLotsOverviewSelectedAction,
  outgoingLotsOverviewClearAction,
  outgoingLotsOverviewActionType,
  outgoingLotsOverviewSuccessActionType,
  outgoingLotsOverviewFailureActionType,
  outgoingLotsOverviewSelectedActionType,
  outgoingLotsOverviewClearActionType,
};
