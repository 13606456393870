import styled from 'styled-components';

import { cssVar, pxToRem } from 'shared/helpers/styling/styling';

export const StyledContent = styled.div`
  position: relative;
  background-color: ${cssVar('limedSpruceDetails')};
  color: ${cssVar('solidYellow')};
  border-radius: ${pxToRem(5)};
  padding: ${pxToRem(10)};
  font-size: ${pxToRem(14)};
  margin-bottom: ${pxToRem(20)};

  &:after {
    position: absolute;
    content: '';
    height: ${pxToRem(20)};
    width: ${pxToRem(20)};
    top: calc(100% - (${pxToRem(10)}));
    right: ${pxToRem(50)};
    transform: rotateZ(45deg);
    background-color: ${cssVar('limedSpruceDetails')};
  }
`;
