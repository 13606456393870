import axios from 'axios';
import FormData from 'form-data';

import { messageTypes } from 'shared/helpers/constants/snackbar/snackbarMessageTypes';

const API_HOST_AUTH = process.env.REACT_APP_API;

const uploadAgreementDocumentSuccessActionType = 'UPLOAD_AGREEMENT_DOCUMENT_SUCCESS';
function uploadAgreementDocumentSuccessAction(agreement) {
  return {
    type: uploadAgreementDocumentSuccessActionType,
    payload: { agreement },
  };
}

const uploadAgreementDocumentFailureActionType = 'UPLOAD_AGREEMENT_DOCUMENT_FAILED';
function uploadAgreementDocumentFailureAction() {
  return { type: uploadAgreementDocumentFailureActionType };
}

const agreementDocumentAddedActionType = 'UPLOAD_AGREEMENT_DOCUMENT_ADDED';
function agreementDocumentAddedAction(files) {
  return {
    type: agreementDocumentAddedActionType,
    payload: { files },
  };
}

const agreementDocumentClearActionType = 'UPLOAD_AGREEMENT_DOCUMENT_CLER';
function agreementDocumentClearAction() {
  return { type: agreementDocumentClearActionType };
}

const uploadAgreementDocumentActionType = 'UPLOAD_AGREEMENT_DOCUMENT';
function uploadAgreementDocumentAction() {
  return { type: uploadAgreementDocumentActionType };
}

function uploadAgreementDocument(id, files) {
  return dispatch => {
    dispatch(uploadAgreementDocumentAction());

    const data = new FormData();
    data.append('agreement', id);
    files.forEach(val => data.append('document', val));

    return axios({
      method: 'post',
      url: `${API_HOST_AUTH}/agreements/${id}/uploadDocument`,
      headers: { 'Content-Type': 'multipart/form-data' },
      data,
    })
      .then(res => {
        const { agreement, message } = res.data;
        dispatch(uploadAgreementDocumentSuccessAction(agreement));
        return {
          agreement,
          message,
          messageType: messageTypes.success,
        };
      })
      .catch(error => {
        const { message } = error.data;
        dispatch(uploadAgreementDocumentFailureAction());
        return {
          message,
          messageType: messageTypes.error,
        };
      });
  };
}

export {
  agreementDocumentAddedAction,
  agreementDocumentClearAction,
  uploadAgreementDocument,
  uploadAgreementDocumentSuccessActionType,
  uploadAgreementDocumentFailureActionType,
  agreementDocumentAddedActionType,
  uploadAgreementDocumentActionType,
  agreementDocumentClearActionType,
};
