import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, FieldArray, reduxForm } from 'redux-form';

import { FieldInput } from 'shared/components/Fields';
import { FieldCapitalized } from 'shared/components/Fields/FieldCapitalized/FieldCapitalized';
import { FieldSearch } from 'shared/components/Fields/FieldSearch/FieldSearch';
import { FieldSelect } from 'shared/components/Fields/FieldSelect/FieldSelect';
import { Form, Section } from 'shared/components/Form';
import { lotLocationSuffix } from 'shared/helpers/constants/lots/lotLocation';
import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';
import { cancelModal } from 'shared/helpers/constants/modalConstants';
import { validate } from 'shared/helpers/validations/lot/updateLotLocationValidations';

import { goBackOrTo } from 'utils/history';
import { isWest } from 'utils/versioning/Guards';

import { LotLocationSectionsList } from './LotLocationSectionsList';

class LotLocationFormBase extends Component {
  state = {
    sentFromCompany: {},
  };

  componentDidMount() {
    const { change } = this.props;

    if (isWest) {
      change('building', 'A');
    }
  }

  listSentFromCompanies = input => {
    if (!input) {
      return Promise.resolve({
        options: [],
      });
    }
    return this.props.getCompaniesBySearchStr(input).then(companies => ({
      options: companies.options,
    }));
  };

  modalOpen = () => {
    this.props.openModal(cancelModal, () => {
      goBackOrTo('/lots');
    });
  };

  render() {
    const { handleSubmit, updateLotLocation } = this.props;
    const { sentFromCompany } = this.state;

    return (
      <Form
        header={LOTS.LOCATION}
        onSubmit={handleSubmit}
        onCancel={this.modalOpen}
        submitDisabled={this.props.invalid}
      >
        <Section template={['sentFromCompany sentFromCompanyLocation', 'building .']}>
          <Field
            name="sentFromCompany"
            component={FieldSearch}
            label={LOTS.SENT_FROM_COMPANY}
            field="required"
            getOptions={this.listSentFromCompanies}
            disabled={updateLotLocation}
          />
          <Field
            name="sentFromCompanyLocation"
            component={FieldSelect}
            options={sentFromCompany.relatedLocations}
            disabled={updateLotLocation}
            label={LOTS.SENT_FROM_COMPANY_LOCATION}
            field="required"
          />
          <FieldCapitalized
            name="building"
            component={FieldInput}
            label={LOTS.BUILDING}
            field="required"
            suffix={isWest ? '' : lotLocationSuffix.building}
            disabled={isWest}
          />
        </Section>
        <FieldArray name="locationStored" component={LotLocationSectionsList} />
      </Form>
    );
  }
}

const mapStateToProps = ({ form: { LotForm } }) => ({
  LotForm,
});

const LotForm = reduxForm({
  form: 'LotForm',
  validate,
})(LotLocationFormBase);

const LotLocationForm = connect(mapStateToProps)(LotForm);

export { LotLocationForm };
