import React from 'react';

import { createTermConfig } from 'shared/helpers/constants/terms/createTermConfig';

import { ConnectedTermChargedForm } from '../TermChargedForm/TermChargedForm';
import { ConnectedTermForm } from '../TermForm/TermForm';
import ConnectedCreateTerm from './ConnectedCreateTerm';

const CreateTermCeramic = containerProps => {
  const connectedProps = { ...containerProps, ...createTermConfig.ceramic };

  return (
    <ConnectedCreateTerm {...connectedProps}>
      {props => <ConnectedTermForm {...props} />}
    </ConnectedCreateTerm>
  );
};

const CreateTermDiesel = containerProps => {
  const connectedProps = { ...containerProps, ...createTermConfig.diesel };

  return (
    <ConnectedCreateTerm {...connectedProps}>
      {props => <ConnectedTermForm {...props} />}
    </ConnectedCreateTerm>
  );
};

const CreateTermFoil = containerProps => {
  const connectedProps = { ...containerProps, ...createTermConfig.foil };

  return (
    <ConnectedCreateTerm {...connectedProps}>
      {props => <ConnectedTermChargedForm {...props} />}
    </ConnectedCreateTerm>
  );
};

const CreateTermO2Sensor = containerProps => {
  const connectedProps = { ...containerProps, ...createTermConfig.o2sensor };

  return (
    <ConnectedCreateTerm {...connectedProps}>
      {props => <ConnectedTermChargedForm {...props} />}
    </ConnectedCreateTerm>
  );
};

export { CreateTermCeramic, CreateTermFoil, CreateTermO2Sensor, CreateTermDiesel };
