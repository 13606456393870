import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

import { media } from 'shared/helpers/styling/styling';

import { FieldUploadListItem } from './components/FieldUploadListItem/FieldUploadListItem';

const FieldUploadList = ({
  files,
  className,
  previewIcoInside,
  revertPosition,
  allowDeleteExistingFiles,
}) => (
  <div className={className}>
    {files.map((file, fileToUpload, index) => (
      <FieldUploadListItem
        {...fileToUpload}
        {...file}
        onDelete={(!!file.fileToUpload || allowDeleteExistingFiles) && file.onDelete}
        meta={file.meta}
        key={file.file.keyName || `${file.file.name}.${index}`}
        withoutLabel
        previewIcoInside={previewIcoInside}
        revertPosition={revertPosition}
      />
    ))}
  </div>
);

const FieldUploadListStyled = styled(FieldUploadList)`
  margin-top: 5px;

  & > * {
    margin-top: 5px;
  }
  ${({ revertPosition }) =>
    revertPosition &&
    css`
      ${media.mobile`
        margin-left: 50px;
      `}
    `}
`;

FieldUploadListStyled.propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.shape({
      file: PropTypes.object,
      onDelete: PropTypes.func,
    }),
  ),
  className: PropTypes.string,
  previewIcoInside: PropTypes.bool,
  revertPosition: PropTypes.bool,
  allowDeleteExistingFiles: PropTypes.bool,
};

FieldUploadListStyled.defaultProps = {
  allowDeleteExistingFiles: false,
};

export { FieldUploadListStyled as FieldUploadList };
