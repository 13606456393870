import { processViaOptions } from 'shared/helpers/constants/lots/processingConstants';
import { statusesForGlobal } from 'shared/helpers/constants/lots/statusesForGlobal';

const markProcessingCompleteAccess = (
  {
    access: {
      lots: { markProcessingComplete },
    },
  },
  lot,
  allowMissingPackages = false,
) => {
  const acceptedForProcessing = lot.statusForGlobal === statusesForGlobal.accepted_for_processing;
  const acceptedForMixing = lot.statusForGlobal === statusesForGlobal.accepted_for_mixing;
  const processViaMill = Object.keys(processViaOptions.mills).includes(lot.processVia);
  const processViaShears = Object.keys(processViaOptions.shears).includes(lot.processVia);
  const isPostMill = acceptedForProcessing && processViaMill && lot.packageCountWarehouse >= 1;
  const isPostShears = acceptedForProcessing && processViaShears && lot.packageCountOutgoing >= 1;
  const isPostMix = acceptedForMixing && lot.packageCountOutgoing >= 1;

  return (
    markProcessingComplete &&
    (allowMissingPackages || isPostMill || isPostShears || isPostMix) &&
    (acceptedForProcessing || acceptedForMixing)
  );
};

export { markProcessingCompleteAccess };
