import { statusesForCustomer } from 'shared/helpers/constants/lots/statusesForCustomer';
import { statusesForGlobal } from 'shared/helpers/constants/lots/statusesForGlobal';
import { isGlobalRefiningGroup } from 'shared/helpers/matchers/isGlobalRefiningGroup';

const checkStatusForGlobal = ({ sentFromCompany, statusForGlobal }) =>
  isGlobalRefiningGroup(sentFromCompany.companyName)
    ? statusForGlobal === statusesForGlobal.received
    : statusForGlobal === statusesForGlobal.not_received;

const deleteLotAccess = (access, lot) =>
  access &&
  (lot.statusForCustomer === statusesForCustomer.open || !lot.statusForCustomer) &&
  checkStatusForGlobal(lot) &&
  !lot.hedgesCount &&
  !lot.packagesCount &&
  !lot.shipmentsCount &&
  !lot.paymentsCount &&
  !lot.deliveriesCount &&
  !(lot.commodities && lot.commodities.length);

export { deleteLotAccess };
