import React, { useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import styled from 'styled-components';

import { SubmitButton } from 'shared/components/Buttons';
import { FieldUpload } from 'shared/components/Fields/FiledUpload/FieldUpload';
import { FieldUploadList } from 'shared/components/Fields/FiledUpload/FieldUploadList';
import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';
import { validate } from 'shared/helpers/validations/lot/uploadCountSheetValidation';

const Header = styled.h3`
  text-align: center;
`;

const SubmitContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 35px;
`;

const UploadCountSheetContentLayout = props => {
  const { clearCountSheet } = props;

  useEffect(
    () => () => {
      clearCountSheet();
    },
    [clearCountSheet],
  );

  const countSheet = props.uploadCountSheetFile.countSheet;
  const onFileInputChange = e => props.addCountSheet(e.target.files[0]);

  return (
    <div>
      <Header>{LOTS.UPLOAD_COUNT_SHEET_WARNING(props.grgLotNumber)}</Header>
      <form onSubmit={props.handleSubmit} noValidate>
        <Field
          name="countSheet"
          type="file"
          validExtensions=".pdf"
          component={FieldUpload}
          label={LOTS.ADD_COUNT_SHEET}
          onChange={onFileInputChange}
        >
          <span>{LOTS.ADD_COUNT_SHEET}</span>
        </Field>
        {countSheet && <FieldUploadList previewIcoInside files={[{ file: countSheet }]} />}
        <SubmitContainer>
          <SubmitButton
            id="uploadCountSheet"
            label={LOTS.UPLOAD}
            disabled={props.invalid || props.pristine}
          />
        </SubmitContainer>
      </form>
    </div>
  );
};

const UploadCountSheetContent = reduxForm({
  form: 'UploadCountSheetContentForm',
  validate,
})(UploadCountSheetContentLayout);

export { UploadCountSheetContent, UploadCountSheetContentLayout };
