import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { FormContainer } from 'shared/components/Form';
import { Preloader } from 'shared/components/Preloader/Preloader';
import { POOL_ACCOUNTS } from 'shared/helpers/constants/poolAccounts/poolAccountsConstants';
import {
  marketHolidayModal,
  marketHoursModal,
} from 'shared/helpers/constants/poolAccounts/poolAccountsModalConstants';
import {
  successOptions,
  dangerOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { isCustomerUser } from 'shared/helpers/matchers/checkUserType';

import dateAdapter from 'utils/date/dateAdapter';

import { ConnectedRequestSettlementForm } from '../RequestSettlementForm/ConnectedRequestSettlementForm';

class SettlementOfMetalPool extends Component {
  submit = values =>
    this.props.marketAvailability().then(res => this.informationModal(res)(values));

  informationModal =
    ({
      marketHoliday,
      marketTimeFrom,
      marketTimeTo,
      marketHours,
      marketDays,
      timezone,
      isBusinessDay,
    }) =>
    values => {
      const isAvailable = dateAdapter().isBetween(marketTimeFrom, marketTimeTo) && isBusinessDay;

      const modalSettings = marketHoursModal({ marketHours, marketDays, timezone });

      if (marketHoliday) {
        return this.props.openModal(marketHolidayModal(), () => this.sentRequest(values));
      }
      if (!isAvailable) {
        return this.props.openModal(modalSettings, () => this.sentRequest(values));
      }
      return this.sentRequest(values);
    };

  sentRequest = values =>
    this.props
      .settlementOfMetalPoolRequest(values)
      .then(res => {
        this.props.showSnackbar(successOptions, res.message);
        this.props.history.push('/pool-accounts');
      })
      .catch(res => this.props.showSnackbar(dangerOptions, res.message));

  isPoolAccount = poolAccountsList =>
    poolAccountsList.poolAccounts.docs && poolAccountsList.poolAccounts.docs.length;

  listSentFromCompanies = input =>
    !input
      ? Promise.resolve({ options: [] })
      : this.props.getCompaniesForSettleOunces(input).then(companies => ({
          options: companies.options,
        }));

  render() {
    const {
      requestSettlement: { isPending },
      marketAvailabilityDetails,
      poolAccountsList,
      auth: {
        user: { userType, relatedCompany },
      },
    } = this.props;

    return (
      <>
        <FormContainer
          header={POOL_ACCOUNTS.SETTLEMENT_OF_METAL_POOL}
          subHeader={isCustomerUser(userType) && relatedCompany.companyName}
          loaded={this.isPoolAccount(poolAccountsList)}
        >
          {() => (
            <ConnectedRequestSettlementForm
              onSubmit={this.submit}
              poolAccountsList={poolAccountsList}
              listSentFromCompanies={this.listSentFromCompanies}
              metalPool
            />
          )}
        </FormContainer>
        <Preloader loading={isPending || marketAvailabilityDetails.isPending} />
      </>
    );
  }
}

SettlementOfMetalPool.propTypes = {
  requestSettlement: PropTypes.shape({
    isPending: PropTypes.bool,
  }),
  marketAvailabilityDetails: PropTypes.shape({
    inMarketHours: PropTypes.bool,
    isBusinessDay: PropTypes.bool,
    isPending: PropTypes.bool,
    marketDays: PropTypes.string,
    marketDaysNumbers: PropTypes.array,
    marketHoliday: PropTypes.bool,
    marketHours: PropTypes.string,
    marketTimeFrom: PropTypes.number,
    marketTimeTo: PropTypes.number,
    timezone: PropTypes.string,
    validUntil: PropTypes.number,
  }),
  poolAccountsList: PropTypes.shape({
    isPending: PropTypes.bool,
    pagination: PropTypes.object,
    poolAccounts: PropTypes.shape({
      requestSent: PropTypes.bool,
    }),
  }),
  settlementOfMetalPoolRequest: PropTypes.func,
  getCompaniesForSettleOunces: PropTypes.func,
  marketAvailability: PropTypes.func,
  showSnackbar: PropTypes.func,
  closeModal: PropTypes.func,
  openModal: PropTypes.func,
};

export { SettlementOfMetalPool };
