import {
  changePackagesStateActionType,
  changePackagesStateSuccessActionType,
  changePackagesStateFailureActionType,
} from 'actions/Payments/changePackagesState';

const INITIAL_STATE = {
  isPending: false,
};

const ChangePackagesStateReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case changePackagesStateActionType:
      return {
        isPending: true,
      };
    case changePackagesStateSuccessActionType:
    case changePackagesStateFailureActionType:
      return {
        isPending: false,
      };
    default:
      return state;
  }
};

export { ChangePackagesStateReducer };
