const fetchPDFPreviewActionType = 'FETCH_PDF_PREVIEW';
const fetchPDFPreviewFailureActionType = 'FETCH_PDF_PREVIEW_FAILURE';
const showPDFPreviewActionType = 'SHOW_PDF_PREVIEW';
const hidePDFPreviewActionType = 'HIDE_PDF_PREVIEW';

const fetchPDFPreviewAction = () => ({
  type: fetchPDFPreviewActionType,
});

const fetchPDFPreviewFailureAction = () => ({
  type: fetchPDFPreviewFailureActionType,
});

const showPDFPreviewAction = filePath => {
  return {
    type: showPDFPreviewActionType,
    filePath,
  };
};

const hidePDFPreviewAction = () => {
  return {
    type: hidePDFPreviewActionType,
  };
};

const showPDFPreview = filePath => dispatch => {
  dispatch(fetchPDFPreviewAction());
  dispatch(showPDFPreviewAction(filePath));
};

const hidePDFPreview = () => dispatch => dispatch(hidePDFPreviewAction());

export {
  showPDFPreviewActionType,
  hidePDFPreviewActionType,
  fetchPDFPreviewActionType,
  fetchPDFPreviewFailureActionType,
  showPDFPreview,
  hidePDFPreview,
  fetchPDFPreviewAction,
  fetchPDFPreviewFailureAction,
};
