import {
  deleteUserActionType,
  deleteUserSuccessActionType,
  deleteUserFailureActionType,
} from 'actions/Users/deleteUser';

const INITIAL_STATE = {
  isPending: false,
};

const deleteUserReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case deleteUserActionType:
      return {
        isPending: true,
      };
    case deleteUserSuccessActionType:
    case deleteUserFailureActionType:
      return {
        isPending: false,
      };
    default:
      return state;
  }
};

export { deleteUserReducer };
