import CreateTermsReducer from './createTerms';
import SignedTermsReducer from './getSignedTerms';
import TermFiltersReducer from './termFilters';
import TermsDetailsReducer from './termsDetails';
import TermsListReducer from './termsList';
import UpdateTermsReducer from './updateTerms';
import TermsUploadDocumentReducer from './uploadTermsDocument';

const termsRootReducer = {
  termsList: TermsListReducer,
  termFilters: TermFiltersReducer,
  createTerms: CreateTermsReducer,
  updateTerms: UpdateTermsReducer,
  termsDetails: TermsDetailsReducer,
  termsUploadDocument: TermsUploadDocumentReducer,
  signedTerms: SignedTermsReducer,
};

export { termsRootReducer };
