const requestSettlementForLotAccess = (auth, lot, { settlementOunces }) =>
  auth.access.poolAccounts.requestSettlement.forLot &&
  lot.sentFromCompany._id === auth.user.relatedCompany._id &&
  lot.canBeSettled &&
  settlementOunces &&
  settlementOunces[lot._id] &&
  (settlementOunces[lot._id].maximumPlatinumOz !== 0 ||
    settlementOunces[lot._id].maximumPalladiumOz !== 0 ||
    settlementOunces[lot._id].maximumRhodiumOz !== 0);

export { requestSettlementForLotAccess };
