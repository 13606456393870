import {
  getUserActionType,
  getUserSuccessActionType,
  getUserFailureActionType,
  clearUserDetailsActionType,
} from 'actions/Users/Details/userDetails';
import { inactivateUserSuccessActionType } from 'actions/Users/inactivateUser';

const INITIAL_STATE = {
  isPending: false,
  errorMessage: null,
  user: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getUserActionType:
      return {
        ...state,
        updatedBy: null,
        isPending: true,
        user: null,
      };
    case getUserSuccessActionType:
    case inactivateUserSuccessActionType:
      return {
        ...state,
        isPending: false,
        user: action.payload.user,
      };
    case getUserFailureActionType:
      return {
        ...state,
        isPending: false,
        errorMessage: action.payload.errorMessage,
      };
    case clearUserDetailsActionType:
      return {
        isPending: false,
        errorMessage: null,
        user: null,
      };
    default:
      return state;
  }
};
