const SETTLEMENT_FOR_LOT_WIZARD = {
  RELATED_LOT: 'Related lot',
  DELETE_ITEM: 'Delete',
  APPLY_BLANKET_HEDGES: 'Apply blanket hedge(s)',
  SUMMARY_HEADER: 'Settlement for lots review',
  ADD_LOT: 'Add lot',
  PLATINUM_OUNCES_REMOVED: 'Platinum ounces removed',
  PALLADIUM_OUNCES_REMOVED: 'Palladium ounces removed',
  RHODIUM_OUNCES_REMOVED: 'Rhodium ounces removed',
  OUNCES_MOVED_TO_POOL_ACCOUNT_HEADER: 'Ounces moved to the pool account',
  OUNCES_MOVED_TO_POOL_ACCOUNT: 'Ounces moved',
  TROY_OUNCES_SETTLED: 'Troy ounces settled',
  HEDGE_OUNCES: 'Hedge ounces',
  SUMMARY_BLANKET_HEDGES_HEADER: 'Applied blanket hedges',
  REQUEST_PAYMENT: 'Request a payment',
  SETTLEMENT: 'Settlement',
  REQUEST_SETTLEMENT: 'Request another settlement',
};

export { SETTLEMENT_FOR_LOT_WIZARD };
