import React from 'react';
import { connect } from 'react-redux';

import { deleteShipment as deleteShipmentAction } from 'actions/Shipments/deleteShipment';
import { openModal } from 'actions/shared/modal';
import { showSnackbar } from 'actions/shared/snackbar';

import { deleteShipmentModal } from 'shared/helpers/constants/modalConstants';
import { snackbarOptions } from 'shared/helpers/matchers/snackbar/snackbarOptions';

function DeleteShipment(props) {
  const deleteShipment = () => {
    props.openModal(deleteShipmentModal, () => {
      props.routeChange && props.routeChange();
      props.deleteShipment(props.shipment._id || props.shipment.id).then(res => {
        props.showSnackbar(snackbarOptions(res.messageType), res.message);
        const { onDelete } = props;
        onDelete && onDelete();
      });
    });
  };

  return <React.Fragment>{props.children({ deleteShipment })}</React.Fragment>;
}

const mapDispatchToProps = {
  openModal,
  showSnackbar,
  deleteShipment: deleteShipmentAction,
};

const ConnectedDeleteShipment = connect(null, mapDispatchToProps)(DeleteShipment);

export { ConnectedDeleteShipment as DeleteShipment, DeleteShipment as DeleteShipmentModal };
