import axios from 'axios';

import { getLot } from 'actions/Lots/lotDetails';

import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';
import { messageTypes } from 'shared/helpers/constants/snackbar/snackbarMessageTypes';

const API_HOST_AUTH = process.env.REACT_APP_API;

const retryImportActionType = 'RETRY_IMPORT_LOT';
function retryImportAction() {
  return {
    type: retryImportActionType,
  };
}

const retryImportSuccessActionType = `${retryImportActionType}_SUCCESS`;
function retryImportSuccessAction({ lot }) {
  return {
    type: retryImportSuccessActionType,
    payload: { lot },
  };
}

const retryImportFailureActionType = `${retryImportActionType}_DELETE_LOT_FAILURE`;
function retryImportFailureAction() {
  return {
    type: retryImportFailureActionType,
  };
}

function retryImport(id) {
  return dispatch => {
    dispatch(retryImportAction());

    return axios
      .post(`${API_HOST_AUTH}/lots/${id}/process-xml-retry`)
      .then(response => {
        dispatch(retryImportSuccessAction(response.data));
        return {
          message: response.data.message,
          messageType: messageTypes.success,
        };
      })
      .catch(error => {
        dispatch(retryImportFailureAction());
        return {
          message: `${LOTS.ERRORS.IMPORT_LOT_ERROR} ${error.data.message}`,
          messageType: messageTypes.error,
        };
      })
      .finally(() => dispatch(getLot(id)));
  };
}

export {
  retryImportActionType,
  retryImportAction,
  retryImportSuccessActionType,
  retryImportSuccessAction,
  retryImportFailureActionType,
  retryImportFailureAction,
  retryImport,
};
