import {
  markProcessingCompleteType,
  markProcessingCompleteSuccessType,
  markProcessingCompleteFailureType,
} from 'actions/Lots/markProcessingComplete';

const INITIAL_STATE = {
  isPending: false,
};

const markProcessingCompleteReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case markProcessingCompleteType:
      return {
        isPending: true,
      };
    case markProcessingCompleteSuccessType:
    case markProcessingCompleteFailureType:
      return {
        isPending: false,
      };
    default:
      return state;
  }
};

export { markProcessingCompleteReducer };
