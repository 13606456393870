import styled from 'styled-components';

import { cssVar, pxToRem } from 'shared/helpers/styling/styling';

export const StyledNoteHeader = styled.h4`
  background: ${cssVar('limedSpruceDarker')};
  margin: 0;
  padding: 20px 15px;
  font-size: ${pxToRem(19)};
  color: ${cssVar('whiteGRG')};
`;
