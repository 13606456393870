import React, { Component } from 'react';

import { Button } from 'shared/components/Buttons/Button';
import { Icon } from 'shared/components/Icon/Icon';
import { ListCollapse } from 'shared/components/ListCollapse/ListCollapse';
import {
  OverviewListSection,
  OverviewListSectionWrapper,
} from 'shared/components/OverviewList/OverviewListSection/OverviewListSection';
import { SHARED } from 'shared/helpers/constants/sharedConstants';
import { cssVar } from 'shared/helpers/styling/styling';

class OverviewCompanySection extends Component {
  state = { displaySection: true };

  sectionToggle = () => {
    const {
      children: {
        props: {
          input: { total },
        },
      },
    } = this.props;
    total !== 0 && this.setState(prevState => ({ displaySection: !prevState.displaySection }));
  };

  render() {
    const {
      history,
      title,
      name,
      button,
      children,
      showAll,
      companyDetails: { company },
    } = this.props;

    const listCollapseLabel = Number.isFinite(children.props.input.total)
      ? `${title} (${children.props.input.total})`
      : title;

    return (
      <OverviewListSectionWrapper
        isOpen={this.state.displaySection}
        total={children.props.input.total}
      >
        <OverviewListSection isOpen={this.state.displaySection} total={children.props.input.total}>
          <div>
            <div>
              <ListCollapse
                collapse={this.state.displaySection}
                toggleList={this.sectionToggle}
                label={listCollapseLabel}
              />
            </div>
            <div>
              {showAll && children.props.input.total > 10 && (
                <Button
                  id={`showAll${title}`}
                  onClick={() => history.push(`/companies/${company._id}/${name}`)}
                  borderRadius={18}
                  outlineColor={cssVar('pelorous')}
                  margin={false}
                  bgColor={cssVar('outerSpaceTabs')}
                >
                  {SHARED.DETAILS}
                  <Icon icon="icon-tiny-arrow-right" color={cssVar('alabaster')} />
                </Button>
              )}
              {button}
            </div>
          </div>
        </OverviewListSection>
        <div>{children}</div>
      </OverviewListSectionWrapper>
    );
  }
}

export { OverviewCompanySection };
