import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { getGlobalCreatedPackage } from 'actions/Packages/packageDetails';
import { saveTemporaryPackageData } from 'actions/Packages/saveTemporaryPackageData';
import {
  updateIncomingPackageForCustomer,
  updateIncomingPackageForCustomerMaterialCount,
} from 'actions/Packages/updatePackage';
import { showSnackbar } from 'actions/shared/snackbar';

import { ContentLoader } from 'shared/components/ContentLoader/ContentLoader';
import { FormContainer } from 'shared/components/Form';
import { ModalEditMorePackages } from 'shared/components/ModalEditMorePackages/ModalEditMorePackages';
import { normalizePackageObject } from 'shared/helpers/constants/packages/normalizePackage';
import { PACKAGES } from 'shared/helpers/constants/packages/packageConstants';
import {
  dangerOptions,
  successOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { parsePkgToCompare } from 'shared/helpers/dataParsers/packages/parsePkgToCompare';
import { isWarehouseUser } from 'shared/helpers/matchers/checkUserType';
import { isPkgEdited } from 'shared/helpers/matchers/packages/getExistingPackages';

import { goBackOrTo } from 'utils/history';
import { urlExtractor } from 'utils/urlExtractor';

import { ManagePackage } from 'pages/Packages/components/PackagesManagement/components/ManagePackage/ManagePackage';
import { configNames } from 'pages/Packages/components/PackagesManagement/components/PackageForm/components/PackageForm/fieldsConfig';

class UpdateIncomingPackageForCustomerBase extends Component {
  state = {
    isModalOpen: false,
    valuesToSend: null,
  };

  componentDidMount() {
    const {
      match: {
        params: { id },
      },
      packageDetails: { package: pkg },
      saveTemporaryPackageData,
    } = this.props;

    saveTemporaryPackageData({ editPackageMode: true });

    this.props
      .getGlobalCreatedPackage(id)
      .catch(() => goBackOrTo(`/lots/list/${pkg.assignedLot._id}`));
  }

  handleToggleModal = () => {
    this.setState(prevState => ({
      isModalOpen: !prevState.isModalOpen,
    }));
  };

  handleClickEditMorePackages = () => {
    const { shipmentId, id: packageId } = this.props.match.params;
    const { saveTemporaryPackageData } = this.props;
    const {
      packageDetails: { package: pkg },
    } = this.props;
    const { valuesToSend } = this.state;

    const isEdited = !isPkgEdited(parsePkgToCompare(pkg), parsePkgToCompare(valuesToSend));

    this.props.history.push(
      `/shipments/${shipmentId || pkg.assignedShipment._id}/multipackages/${packageId}/update`,
    );

    saveTemporaryPackageData({
      editedPackage: {
        ...valuesToSend,
        edited: isEdited,
      },
      editMultipackagesMode: true,
      editPackageMode: false,
    });
  };

  handleClickUpdateOnePackage = () => {
    const { valuesToSend } = this.state;
    const { id } = this.props.packageDetails.package.assignedLot;

    this.handleSendDataToAPI({ ...valuesToSend, assignedLot: { _id: id } });
  };

  handleSendDataToAPI = values => {
    const {
      weightGrossActual,
      weightTareActual,
      packageClass,
      weightGrossDeclared,
      weightTareDeclared,
      ...valuesToSend
    } = values;

    const {
      auth: {
        user: { userType },
      },
      packageDetails: {
        package: { assignedLot },
      },
      lotDetails: {
        lot: { sentFromCompany },
      },
      updateIncomingPackageForCustomer,
      updateIncomingPackageForCustomerMaterialCount,
    } = this.props;

    const pkgAction = sentFromCompany.materialCountRequired
      ? updateIncomingPackageForCustomerMaterialCount
      : updateIncomingPackageForCustomer;

    return pkgAction({
      ...(!isWarehouseUser(userType) && {
        weightGrossDeclared,
        weightTareDeclared,
      }),
      ...valuesToSend,
      assignedLot,
      materialCountRequired: sentFromCompany.materialCountRequired,
    })
      .then(res => {
        this.props.history.push(urlExtractor(this.props.location.pathname, -2));
        this.props.showSnackbar(successOptions, res.message);
      })
      .catch(res => this.props.showSnackbar(dangerOptions, res.message));
  };

  submit = values => {
    const { weightGrossActual, weightTareActual, packageClass, ...valuesToSend } = values;
    const { multiple: updateMultipleIncoming } = this.props.auth.access.packages.updateIncoming;

    const {
      packageDetails: {
        package: { openIncomingPkgCount },
      },
      lotDetails: {
        lot: { sentFromCompany },
      },
    } = this.props;

    if (
      openIncomingPkgCount > 1 &&
      sentFromCompany.materialCountRequired &&
      updateMultipleIncoming
    ) {
      this.setState({
        valuesToSend: {
          ...valuesToSend,
          plant: valuesToSend?.plant?.toUpperCase(),
          labelId: valuesToSend?.labelId?.toUpperCase(),
          materialCountRequired: sentFromCompany.materialCountRequired,
        },
      });

      return this.handleToggleModal();
    }

    return this.handleSendDataToAPI({ ...valuesToSend });
  };

  render() {
    const companyName = this.props.lotDetails.lot?.sentFromCompany.companyName;

    const {
      packageDetails: { isPending, package: pkg },
      updatePackage: { isPending: updatePackageIsPending },
    } = this.props;

    return (
      <ContentLoader loaded={!updatePackageIsPending && !isPending}>
        <FormContainer
          companyName={companyName}
          formLoaded={pkg && !isPending}
          header={PACKAGES.UPDATE_AN_INCOMING_PACKAGE}
          subHeader={companyName}
        >
          <ManagePackage
            companyName={companyName}
            config={
              pkg?.assignedLot.sentFromCompany.materialCountRequired
                ? configNames.ownerEditPackage
                : configNames.grgForCustomer
            }
            onSubmit={this.submit}
            editMode
            initialValues={pkg && normalizePackageObject(pkg)}
          />
          <ModalEditMorePackages
            handleToggleModal={this.handleToggleModal}
            handleClickEditMorePackages={this.handleClickEditMorePackages}
            handleClickUpdateOnePackage={this.handleClickUpdateOnePackage}
            isModalOpen={this.state.isModalOpen}
            isPending={updatePackageIsPending}
          />
        </FormContainer>
      </ContentLoader>
    );
  }
}

const mapStateToProps = state => ({
  lotDetails: state.lotDetails,
  packageDetails: state.packageDetails,
  auth: state.auth,
  updatePackage: state.updatePackage,
});

const mapDispatchToProps = {
  getGlobalCreatedPackage,
  showSnackbar,
  updateIncomingPackageForCustomer,
  updateIncomingPackageForCustomerMaterialCount,
  saveTemporaryPackageData,
};

UpdateIncomingPackageForCustomerBase.propTypes = {
  auth: PropTypes.shape({
    attempts: PropTypes.number,
    user: PropTypes.object,
    access: PropTypes.object,
    isPending: PropTypes.bool,
  }),
  getGlobalCreatedPackage: PropTypes.func,
  history: PropTypes.object,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
    state: PropTypes.object,
    key: PropTypes.string,
  }),
  lotDetails: PropTypes.object,
  match: PropTypes.shape({
    path: PropTypes.string,
    url: PropTypes.string,
    isExact: PropTypes.bool,
    params: PropTypes.object,
  }),
  packageDetails: PropTypes.shape({
    isPending: PropTypes.bool,
    errorMessage: PropTypes.oneOf([PropTypes.object, null]),
    package: PropTypes.object,
    recentlyCreated: PropTypes.bool,
  }),
  showSnackbar: PropTypes.func,
  updateIncomingPackageForCustomer: PropTypes.func,
  updateIncomingPackageForCustomerMaterialCount: PropTypes.func,
  updatePackage: PropTypes.object,
  saveTemporaryPackageData: PropTypes.func,
};

const UpdateIncomingPackageForCustomer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UpdateIncomingPackageForCustomerBase),
);

export { UpdateIncomingPackageForCustomer, UpdateIncomingPackageForCustomerBase };
