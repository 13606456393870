import {
  statusFilterActionType,
  deliveryDateFromFilterActionType,
  deliveryDateToFilterActionType,
  relatedCompanyFilterActionType,
  selfDeliveryRequestsFiltersClearActionType,
} from 'actions/SelfDeliveryRequests/selfDeliveryRequestsFilters';

const INITIAL_STATE = {
  status: [],
  relatedCompany: '',
  deliveryDate: {
    from: null,
    to: null,
  },
};

const SelfDeliveryRequestsFiltersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case statusFilterActionType:
      return {
        ...state,
        status: action.payload.status,
      };
    case relatedCompanyFilterActionType:
      return {
        ...state,
        relatedCompany: action.payload.relatedCompany,
      };
    case deliveryDateFromFilterActionType:
      return {
        ...state,
        deliveryDate: {
          ...state.deliveryDate,
          from: action.payload.deliveryDateFrom,
        },
      };
    case deliveryDateToFilterActionType:
      return {
        ...state,
        deliveryDate: {
          ...state.deliveryDate,
          to: action.payload.deliveryDateTo,
        },
      };
    case selfDeliveryRequestsFiltersClearActionType:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};

export { SelfDeliveryRequestsFiltersReducer };
