const JOURNAL = {
  JOURNAL: 'Journal',
  CREATED_AT: 'Created on',
  TYPE: 'Type',
  OUNCES: 'Ounces',
  METAL: 'Metal',
  PRICE_PER_OUNCE: 'Price per ounce',
  RELATED_LOT: 'Related lot',
  RELATED_COMPANY: 'Related company',
  CREATED_BY: 'Created by',
  CREATED_AT_FROM: 'Created on from',
  CREATED_AT_TO: 'Created on to',
};

export { JOURNAL };
