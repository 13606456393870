import React, { Component } from 'react';

import { OverviewList } from 'shared/components/OverviewList/OverviewList';
import {
  userItemTableTemplate,
  userItemMobileTemplate,
  userDetailsTableTemplate,
  userActionsTemplate,
} from 'shared/helpers/constants/companies/companyDetailsTableConfig';
import { isTablet } from 'shared/helpers/constants/resolutionsConstants';
import { USER } from 'shared/helpers/constants/users/userConstants';

import { getBrowserWidth } from 'utils/responsive';

class UsersList extends Component {
  state = {
    windowWidth: getBrowserWidth(),
  };

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
    this.props.clearLocationDetailsUsersList();
  }

  handleWindowSizeChange = () => {
    this.setState({
      windowWidth: getBrowserWidth(),
    });
  };

  render() {
    const { windowWidth } = this.state;
    const isMobile = windowWidth < isTablet;
    const {
      locationDetailsUsersList,
      locationDetails: { location },
      getLocationDetailsUsers,
    } = this.props;

    return (
      <OverviewList
        input={locationDetailsUsersList}
        getList={(page, limit) => getLocationDetailsUsers(location._id, limit, page)}
        itemsTemplate={userItemTableTemplate}
        itemsTemplateMobile={userItemMobileTemplate}
        itemsDetailsTemplate={userDetailsTableTemplate}
        actionsTemplate={userActionsTemplate}
        isMobile={isMobile}
        label={USER.USERS}
        actionsContext={this}
      />
    );
  }
}

export { UsersList };
