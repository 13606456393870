import PropTypes from 'prop-types';
import { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router';

import {
  clearRelatedSamplePackages,
  getRelatedSamplePackages,
} from 'actions/Packages/getRelatedSamplePackages';
import {
  clearShipmentPackagesList,
  shipmentPackagesList,
} from 'actions/Shipments/shipmentPackagesList';

import { ModalCustom } from 'components/ModalCustom/ModalCustom';

import { OverviewList } from 'shared/components/OverviewList/OverviewList';
import { PreloaderInline } from 'shared/components/PreloaderInline/PreloaderInline';
import {
  itemsTemplate,
  actionsTemplate,
} from 'shared/helpers/constants/packages/printPackagesLabelsTableConfig';

import { PrintLabRequestFormsActions } from './PrintLabRequestFormsActions';

const PrintLabRequestForms = ({ open, modalClose }) => {
  const location = useLocation();
  const relatedType = location.pathname.includes('shipments') ? 'shipments' : 'lots';
  const { id: relatedId } = useParams();
  const packagesList = useSelector(state => state.relatedSamplePackages);
  const chosenPackages = useSelector(state => state.shipmentPackagesListData.packages);
  const dispatch = useDispatch();

  useEffect(
    () => () => {
      dispatch(clearRelatedSamplePackages());
      dispatch(clearShipmentPackagesList());
    },
    [dispatch],
  );

  const getPackages = useCallback(
    () => dispatch(getRelatedSamplePackages(relatedType, relatedId)),
    [dispatch, relatedType, relatedId],
  );

  const addPackageToList = packageId => {
    dispatch(shipmentPackagesList([...chosenPackages, packageId]));
  };

  const removePackageFromList = packageId => {
    const packages = chosenPackages.filter(id => id !== packageId);
    dispatch(shipmentPackagesList(packages));
  };

  const checkElement = id => {
    chosenPackages.includes(id) ? removePackageFromList(id) : addPackageToList(id);
  };

  return (
    <ModalCustom isOpen={open} modalClose={modalClose}>
      <PrintLabRequestFormsActions packagesList={packagesList} />
      <PreloaderInline loading={packagesList?.isPending && !packagesList?.packages?.length} />
      <OverviewList
        input={{
          items: { docs: packagesList?.packages },
          isPending: packagesList?.isPending,
        }}
        getList={getPackages}
        itemsTemplate={itemsTemplate}
        actionsTemplate={actionsTemplate}
        templateProps={{
          listSize: packagesList?.length,
          isPackingList: true,
        }}
        actionsContext={{ checkElement }}
      />
    </ModalCustom>
  );
};

PrintLabRequestForms.propTypes = {
  open: PropTypes.bool,
  modalClose: PropTypes.func,
};

export { PrintLabRequestForms };
