import { DetailsActivityField } from 'shared/components/Details/DetailsActivityField';
import { DetailsDateField } from 'shared/components/Details/DetailsDateField';
import { DetailsField } from 'shared/components/Details/DetailsField';
import { DetailsHumanizedField } from 'shared/components/Details/DetailsHumanizedField';
import { SELF_DELIVERY_REQUESTS } from 'shared/helpers/constants/selfDeliveryRequests/selfDeliveryRequestsConstants';
import { dateWithShortTimeFormat } from 'shared/helpers/parsers/date';
import { formatToDollar, formatToUS } from 'shared/helpers/parsers/formaters';

const selfDeliveryRequestDetailsFieldsConfig = sdRequest => [
  {
    label: SELF_DELIVERY_REQUESTS.FUNDS_TYPE,
    value: sdRequest.fundsType,
    icon: 'icon-dollar',
    id: 'fundsType',
    FieldType: DetailsHumanizedField,
  },
  {
    label: SELF_DELIVERY_REQUESTS.ESTIMATED_FUNDS,
    value: formatToDollar(sdRequest.estimatedFunds),
    icon: 'icon-dollar',
    id: 'estimatedFunds',
    prefix: '$',
    FieldType: DetailsField,
  },
  {
    label: SELF_DELIVERY_REQUESTS.UNIT_COUNT,
    value: formatToUS(sdRequest.unitCount, 0),
    icon: 'icon-cart',
    id: 'unitCount',
    suffix: 'units',
    FieldType: DetailsField,
  },
  {
    label: SELF_DELIVERY_REQUESTS.DELIVERY_DATE,
    value: sdRequest.deliveryDate,
    id: 'deliveryDate',
    dateFormat: dateWithShortTimeFormat,
    FieldType: DetailsDateField,
  },
  {
    label: SELF_DELIVERY_REQUESTS.CUSTOMER_INSTRUCTIONS,
    value: sdRequest.customerInstructions,
    icon: 'icon-document',
    id: 'customerInstructions',
    FieldType: DetailsField,
  },
];

const activityFieldsConfig = sdRequest => [
  {
    label: SELF_DELIVERY_REQUESTS.CREATED_BY,
    user: sdRequest.createdBy,
    date: sdRequest.createdAt,
    id: 'createdBy',
    FieldType: DetailsActivityField,
  },
  {
    label: SELF_DELIVERY_REQUESTS.GLOBAL_DECISION_BY,
    user: sdRequest.globalDecisionBy,
    date: sdRequest.globalDecisionAt,
    id: 'globalDecisionBy',
    FieldType: DetailsActivityField,
  },
];

const selfDeliveryRequestDetailsContainerConfig = () => ({
  header: SELF_DELIVERY_REQUESTS.GENERAL_DETAILS,
  template: [
    'fundsType estimatedFunds unitCount deliveryDate',
    'customerInstructions customerInstructions customerInstructions customerInstructions',
  ],
});

const activityContainerConfig = () => ({
  header: SELF_DELIVERY_REQUESTS.ACTIVITY,
  template: ['createdBy createdBy globalDecisionBy globalDecisionBy'],
});

export {
  selfDeliveryRequestDetailsFieldsConfig,
  activityFieldsConfig,
  selfDeliveryRequestDetailsContainerConfig,
  activityContainerConfig,
};
