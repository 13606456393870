import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import 'utils/interceptors/request';
import 'utils/interceptors/response';

import App from './app';
import { GlobalStyle } from './assets/styles/common/GlobalStyle';
import './assets/styles/index.scss';
import * as serviceWorker from './serviceWorker';
import store from './store';

const rootElement = document.getElementById('app');
const root = ReactDOM.createRoot(rootElement);

root.render(
  <Provider store={store}>
    <GlobalStyle />
    <App />
  </Provider>,
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
