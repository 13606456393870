import {
  hideSessionSnackbarActionType,
  showSessionSnackbarActionType,
} from 'actions/shared/sessionSnackbar';

const INITIAL_STATE = {
  snackbarOpened: false,
  remainingTime: 0,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case showSessionSnackbarActionType:
      return {
        ...state,
        snackbarOpened: true,
        remainingTime: action.payload.remainingTime,
      };
    case hideSessionSnackbarActionType:
      return {
        ...state,
        snackbarOpened: false,
        remainingTime: 0,
      };
    default:
      return state;
  }
};
