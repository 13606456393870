import { CHANGE_RECEIVED_DATE } from 'shared/helpers/constants/lots/changeReceoviedDateConstants';

import dateAdapter from 'utils/date/dateAdapter';

const validate = ({ receivedAt }) => {
  let receivedAtError = null;

  if (!receivedAt) {
    receivedAtError = CHANGE_RECEIVED_DATE.RECEIVED_AT_EMPTY;
  }

  if (dateAdapter(receivedAt).isBetween(dateAdapter().startOf('d'), dateAdapter().endOf('d'))) {
    receivedAtError = CHANGE_RECEIVED_DATE.RECEIVED_AT_TODAY;
  }

  return {
    receivedAt: receivedAtError,
  };
};

export { validate };
