import { PACKAGES } from 'shared/helpers/constants/packages/packageConstants';
import { packageType } from 'shared/helpers/constants/packages/packageType';
import { isWarehouseUser } from 'shared/helpers/matchers/checkUserType';

export const parseSamplePackages = ({ samplePackages = [], assignedCats = [] }) => ({
  samplePackages: {
    contactPerson:
      samplePackages.contactPerson.value === PACKAGES.EXTERNAL_USER_VALUE
        ? samplePackages.externalUser
        : samplePackages.portalUser.label,
    laboratoryPolicy: samplePackages.laboratoryPolicy,
    assignedCats,
  },
});

export const parseAssignedCats = userType => pkg => {
  if (!pkg) return null;

  const { unitCount, samplePackages, ...parsedPkg } = pkg;
  const isSample = parsedPkg.packageType?.value === packageType.sample;

  return {
    ...parsedPkg,
    ...(!(isWarehouseUser(userType) && !isSample) && {
      unitCount,
    }),
    ...(isSample && {
      assignedCats: [...Array(Number(unitCount)).keys()].map(
        (_, i) => samplePackages?.[i] || { primaryId: null, secondaryId: null },
      ),
    }),
  };
};
