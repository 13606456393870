import socketIOClient from 'socket.io-client';

class Socket {
  static _socket = null;

  static get instance() {
    if (!Socket._socket) Socket.openConnection();

    Socket._socket.on('connect_error', error => {
      Socket._socket.io.opts.transports = ['polling', 'websocket'];
    });

    return Socket._socket;
  }

  static openConnection = () => {
    Socket._socket = socketIOClient(process.env.REACT_APP_WEBSOCKET_ENDPOINT, {
      transports: ['websocket'],
      upgrade: true,
    });
  };

  static closeConnection = () => {
    Socket._socket && Socket._socket.close();
    Socket._socket = null;
  };
}

export { Socket };
