const showSessionSnackbarActionType = 'SHOW_SESSION_SNACKBAR';
function showSessionSnackbarAction({ remainingTime }) {
  return {
    type: showSessionSnackbarActionType,
    payload: {
      remainingTime,
    },
  };
}

const hideSessionSnackbarActionType = 'HIDE_SESSION_SNACKBAR';
function hideSessionSnackbarAction() {
  return {
    type: hideSessionSnackbarActionType,
  };
}

function showSessionSnackbar({ remainingTime }) {
  return dispatch => {
    dispatch(showSessionSnackbarAction({ remainingTime }));
  };
}

function hideSessionSnackbar() {
  return dispatch => {
    dispatch(hideSessionSnackbarAction());
  };
}

export {
  showSessionSnackbarActionType,
  hideSessionSnackbarActionType,
  showSessionSnackbar,
  hideSessionSnackbar,
};
