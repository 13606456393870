import axios from 'axios';

import { showSnackbar } from 'actions/shared/snackbar';

import {
  dangerOptions,
  successOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

const API_HOST_AUTH = process.env.REACT_APP_API;

const reassignPaymentActionType = 'REASSIGN_PAYMENT';
const reassignPaymentAction = () => ({
  type: reassignPaymentActionType,
});

const reassignPaymentSuccessActionType = `${reassignPaymentActionType}_SUCCESS`;
const reassignPaymentSuccessAction = payment => ({
  type: reassignPaymentSuccessActionType,
  payload: { payment },
});

const reassignPaymentFailureActionType = `${reassignPaymentActionType}_FAILURE`;
const reassignPaymentFailureAction = () => ({
  type: reassignPaymentFailureActionType,
});

const reassignPayment = (paymentId, lotId) => dispatch => {
  dispatch(reassignPaymentAction());

  return axios
    .patch(`${API_HOST_AUTH}/payments/${paymentId}/reassign/${lotId}`)
    .then(({ data }) => {
      dispatch(reassignPaymentSuccessAction(data.payment));
      dispatch(showSnackbar(successOptions, data.message));

      return {
        message: data.message,
      };
    })
    .catch(({ data }) => {
      dispatch(reassignPaymentFailureAction());
      dispatch(showSnackbar(dangerOptions, data.message));

      return Promise.reject(data);
    });
};

export {
  reassignPayment,
  reassignPaymentActionType,
  reassignPaymentSuccessActionType,
  reassignPaymentFailureActionType,
};
