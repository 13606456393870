import { DetailsDateField } from 'shared/components/Details/DetailsDateField';
import { DetailsDownloadField } from 'shared/components/Details/DetailsDownloadFieldRedesign';
import { DetailsField } from 'shared/components/Details/DetailsField';
import { DetailsHumanizedField } from 'shared/components/Details/DetailsHumanizedField';
import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';

const lotDetailsFieldsConfig = (lot, downloadAttachment) => [
  {
    label: LOTS.SHORTENED_SENT_FROM_COMPANY_LOCATION,
    value: lot.sentFromCompanyLocation.name,
    icon: 'icon-home',
    id: 'sentFromCompanyLocation',
    FieldType: DetailsField,
  },
  {
    label: LOTS.STATUS_FOR_CUSTOMER,
    value: lot.statusForCustomer,
    icon: 'icon-type',
    id: 'statusForCustomer',
    FieldType: DetailsHumanizedField,
  },
  {
    label: LOTS.GRADING_RECEIPT,
    value: lot.gradingReceipt,
    id: 'gradingReceipt',
    onClick: () => downloadAttachment(lot.gradingReceipt.path),
    FieldType: DetailsDownloadField,
  },
  {
    label: LOTS.RECEIVED_ON,
    value: lot.receivedAt,
    id: 'receivedAt',
    FieldType: DetailsDateField,
  },
  {
    label: LOTS.CUSTOMER_INSTRUCTIONS,
    value: lot.customerInstructions,
    icon: 'icon-document',
    id: 'customerInstructions',
    FieldType: DetailsField,
  },
];

const weightFieldsConfig = lot => [
  {
    label: LOTS.WEIGHT_GROSS_DECLARED,
    value: lot.weightGrossDeclared,
    icon: 'icon-volume',
    id: 'weightGrossDeclared',
    suffix: LOTS.LBS,
    FieldType: DetailsField,
  },
  {
    label: LOTS.WEIGHT_TARE_DECLARED,
    value: lot.weightTareDeclared,
    icon: 'icon-volume',
    id: 'weightTareDeclared',
    suffix: LOTS.LBS,
    FieldType: DetailsField,
  },
  {
    label: LOTS.WEIGHT_NET_DECLARED,
    value: lot.weightNetDeclared,
    icon: 'icon-volume',
    id: 'weightNetDeclared',
    suffix: LOTS.LBS,
    FieldType: DetailsField,
  },
];

const lotDetailsContainerConfig = () => ({
  header: LOTS.GENERAL_DETAILS,
  template: [
    'sentFromCompanyLocation sentFromCompanyLocation statusForCustomer statusForCustomer',
    'gradingReceipt receivedAt customerInstructions customerInstructions',
  ],
});

const weightContainerConfig = () => ({
  header: LOTS.WEIGHT,
  template: [
    'weightGrossDeclared weightGrossDeclared weightTareDeclared weightTareDeclared',
    'weightNetDeclared weightNetDeclared weightNetDeclared weightNetDeclared',
  ],
});

export {
  lotDetailsFieldsConfig,
  weightFieldsConfig,
  lotDetailsContainerConfig,
  weightContainerConfig,
};
