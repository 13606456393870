import styled, { css } from 'styled-components';

import { Button } from 'shared/components/Buttons/Button';
import { media } from 'shared/helpers/styling/styling';

const FieldWithButton = styled.div`
  display: flex;
  align-items: center;
  width: 475px;

  ${Button} {
    margin: 0 10px;
  }

  ${media.mobile`
    width: auto;

    ${Button} {
      margin-top: 45px;
    }
  `}

  ${({ noPadding }) =>
    !noPadding &&
    css`
      ${media.mobile`
        padding: 0 90px;
      `}

      ${media.phone`
        padding: 0 37px;
      `}
    `}
`;

export { FieldWithButton };
