import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getLotsForSettlementActionType = 'GET_LOTS_FOR_SETTLEMENT';
const getLotsForSettlementAction = () => ({
  type: getLotsForSettlementActionType,
});

const getLotsForSettlementSuccessActionType = `${getLotsForSettlementActionType}_SUCCESS`;
const getLotsForSettlementSuccessAction = lots => ({
  type: getLotsForSettlementSuccessActionType,
  payload: {
    lots,
  },
});

const getLotsForSettlementFailureActionType = `${getLotsForSettlementActionType}_FAILURE`;
const getLotsForSettlementFailureAction = () => ({
  type: getLotsForSettlementFailureActionType,
});

const clearPaymentAmountActionType = 'CLEAR_PAYMENT_AMOUNT';
const clearPaymentAmount = () => ({
  type: clearPaymentAmountActionType,
});

const getLotsForSettlement = () => async dispatch => {
  dispatch(getLotsForSettlementAction());

  try {
    const res = await axios.get(`${API_HOST_AUTH}/payments/settle-lot`);

    return dispatch(getLotsForSettlementSuccessAction(res.data));
  } catch (error) {
    return Promise.reject(dispatch(getLotsForSettlementFailureAction()));
  }
};

export {
  getLotsForSettlementActionType,
  getLotsForSettlementAction,
  getLotsForSettlementSuccessActionType,
  getLotsForSettlementSuccessAction,
  getLotsForSettlementFailureActionType,
  getLotsForSettlementFailureAction,
  clearPaymentAmountActionType,
  clearPaymentAmount,
  getLotsForSettlement,
};
