import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const checkStatusPackageActionType = 'CHECK_STATUS_PACKAGE';
function checkStatusPackageAction() {
  return {
    type: checkStatusPackageActionType,
  };
}

const checkStatusPackageSuccessActionType = `${checkStatusPackageActionType}_SUCCESS`;
function checkStatusPackageSuccessAction() {
  return { type: checkStatusPackageSuccessActionType };
}

const checkStatusPackageFailureActionType = `${checkStatusPackageActionType}_FAILURE`;
function checkStatusPackageFailureAction() {
  return {
    type: checkStatusPackageFailureActionType,
  };
}

const checkStatusPackageFactory = data => dispatch => {
  dispatch(checkStatusPackageAction());

  return axios
    .post(`${API_HOST_AUTH}/lots/check-package-status`, data)
    .then(response => {
      dispatch(checkStatusPackageSuccessAction(response.data));
      return {
        isLastPackage: response.data.isLastPackage,
      };
    })
    .catch(error => {
      dispatch(checkStatusPackageFailureAction());
      return Promise.reject(error.data);
    });
};

export {
  checkStatusPackageActionType,
  checkStatusPackageAction,
  checkStatusPackageSuccessActionType,
  checkStatusPackageSuccessAction,
  checkStatusPackageFailureActionType,
  checkStatusPackageFailureAction,
  checkStatusPackageFactory,
};
