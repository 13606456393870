const statuses = {
  open: 'open',
  sent: 'sent',
  received: 'received',
};

const shipmentStatuses = {
  ...statuses,
  all: Object.values(statuses),
};

export { shipmentStatuses };
