import { isGradingUser } from 'shared/helpers/matchers/checkUserType';
import { resolveFilterDate, resolveFilterDateEnd } from 'shared/helpers/parsers/dateFilterParser';

const parseSelfDeliveryRequestsFilters = (limit, page, filters, user, order) => ({
  status: filters.status,
  relatedCompany: isGradingUser(user.userType)
    ? user.relatedCompany._id
    : filters.relatedCompany && (filters.relatedCompany._id || ''),
  deliveryDateFrom: resolveFilterDate(filters.deliveryDate.from),
  deliveryDateTo: resolveFilterDateEnd(filters.deliveryDate.to),
  limit,
  page,
  deliveryDateOrder: order?.deliveryDate,
  unitCountOrder: order?.unitCount,
});

export { parseSelfDeliveryRequestsFilters };
