import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import { downloadAttachment } from 'actions/Attachments/downloadAttachment';
import { getLot } from 'actions/Lots/lotDetails';
import {
  approveFinalInvoice,
  approveProfitAndLoss,
  approveShipmentSummary,
  denyFinalInvoice,
  denyProfitAndLoss,
  denyShipmentSummary,
  overwriteApproveShipmentSummary,
  overwriteDenyShipmentSummary,
} from 'actions/Lots/manageGlobalFiles';
import {
  clearPackagesOverview,
  getPackagesOverviewForLots,
} from 'actions/Packages/packagesOverviewList';

import { UIAuthorization } from 'components/Authorization/UIAuthorization';

import { DetailsTabs } from 'shared/components/Details/DetailsTabs';
import { renderDetailsContainer } from 'shared/components/Details/helpers/renderDetailsContainer';
import { ForwardDocument } from 'shared/components/Forward/ForwardDocument/ForwardDocument';
import { NotesPanel } from 'shared/components/Notes/NotesPanel/NotesPanel';
import {
  actionsTemplateGlobal,
  itemsDetailsTemplateGlobal,
  itemsTemplateGlobal,
  itemsTemplateMobile,
} from 'shared/helpers/constants/hedges/overviewTableConfig';
import { globalFileTypes } from 'shared/helpers/constants/lots/globalFileTypes';
import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';
import { statusesForGlobal } from 'shared/helpers/constants/lots/statusesForGlobal';
import { NOTE } from 'shared/helpers/constants/noteConstants';
import { SHARED } from 'shared/helpers/constants/sharedConstants';
import { stripHtmlTags } from 'shared/helpers/parsers/stripHtmlTags';

import { history } from 'utils/history';

import { CommoditiesOverview } from 'pages/Lots/components/LotDetails/components/components/CommoditiesOverview/CommoditiesOverview';
import { PaymentsOverview } from 'pages/Lots/components/LotDetails/components/components/PaymentsOverview/PaymentsOverview';
import { LotTimeline } from 'pages/Lots/components/LotTimeline/LotTimeline';

import { MainLotDetails } from '../MainDetails/MainDetails';
import {
  GlobalPackagesOverview,
  HedgesOverview,
  SettlementsOverview,
  ShipmentsOverview,
} from '../components';
import { ManageGlobalFiles } from './components/ManageGlobalFiles';
import { lotDetailsContainerConfig, lotDetailsFieldsConfig, tabsConfig } from './config';

export const LotDetailsGlobal = ({ auth, lot, hedgesOverviewList, packagesOverviewList }) => {
  const notification = useSelector(state => state.notificationDetails.notification);
  const settlements = useSelector(state => state.lotSettlements.settlements);
  const { shipments, isPending } = useSelector(state => state.shipmentsOverviewList);
  const manageGlobalFiles = useSelector(state => state.manageGlobalFiles);
  const notes = useSelector(state => state.notesList.notes);
  const dispatch = useDispatch();

  const params = useParams();
  const { pathname: path } = useLocation();

  const handleDownloadAttachment = path => {
    dispatch(downloadAttachment(path));
  };

  useEffect(() => {
    if (history.location.pathname.includes('profit-and-loss') && params.fileName) {
      const [name] = params.fileName.replace('alpha', 'grg').split('-');

      const file = lot[name].find(({ path }) => path.includes(params.fileName));
      downloadAttachment(file.path);
    }
  }, [params.fileName]);

  const redirectToDetails = id => history.push(`/lots/list/${id}`);

  const handleApproveProfitAndLoss = async id => {
    const res = await dispatch(approveProfitAndLoss(id));
    await dispatch(getLot(lot._id));

    return res;
  };

  const toggleForwardFinalInvoiceMockup = useCallback(() => redirectToDetails(lot._id), [lot._id]);

  const handleApproveShipmentSummaryOverwrite = useCallback(
    async lotId => {
      try {
        const result = await dispatch(overwriteApproveShipmentSummary(lotId));
        await dispatch(getLot(lotId));
        return result;
      } catch (err) {
        redirectToDetails(lot._id);
        return Promise.reject(err.data);
      }
    },
    [lot._id],
  );

  const handleDenyShipmentSummaryOverwrite = useCallback(
    async lotId => {
      const result = await dispatch(overwriteDenyShipmentSummary(lotId));
      await redirectToDetails(lot._id);
      return result;
    },
    [lot._id],
  );

  const getPackagesOverviewForLotsAction = (id, limit, section) =>
    dispatch(getPackagesOverviewForLots(id, limit, section));

  const clearPackagesOverviewAction = useCallback(
    sectionType => dispatch(clearPackagesOverview(sectionType)),
    [],
  );

  const isNote = lot.statusForGlobal !== statusesForGlobal.finalized;
  return (
    <>
      <MainLotDetails
        auth={auth}
        lot={lot}
        packagesList={packagesOverviewList.packagesList}
        globalDetails
        settlements={settlements}
        shipments={shipments}
        shipmentsIsPending={isPending}
        lotNotesCount={notes.lots.length}
        hedges={hedgesOverviewList.items}
      />
      {renderDetailsContainer(lotDetailsContainerConfig, lotDetailsFieldsConfig, lot, auth)}
      <DetailsTabs
        tabsConfig={tabsConfig(lot, auth, history, params, handleDownloadAttachment, shipments)}
      />
      <UIAuthorization access={auth.access.lots.details.viewNotes}>
        <NotesPanel
          match={{ params }}
          relatedType={NOTE.NOTES_TYPE.LOTS}
          {...(auth.access.lots.warehouseDashboard && {
            subType: NOTE.NOTES_TYPE.PROCESSING,
          })}
          createAuth={auth.access.lots.details.addNotes}
        />
      </UIAuthorization>
      <UIAuthorization access={auth.access.shipments.list}>
        <ShipmentsOverview />
      </UIAuthorization>
      <GlobalPackagesOverview
        lotId={lot._id}
        getPackagesOverviewForLots={getPackagesOverviewForLotsAction}
        clearPackagesOverview={clearPackagesOverviewAction}
        packagesOverviewList={packagesOverviewList}
        auth={auth}
        match={{
          params,
        }}
      />
      <UIAuthorization access={auth.access.lots.details.hedgesList}>
        <HedgesOverview
          itemsTemplate={itemsTemplateGlobal}
          actionsTemplate={actionsTemplateGlobal}
          itemsTemplateMobile={itemsTemplateMobile}
          itemDetailsTemplate={itemsDetailsTemplateGlobal}
          hedgesOverviewList={hedgesOverviewList}
        />
      </UIAuthorization>
      <UIAuthorization access={auth.access.lots.details.paymentsList}>
        <PaymentsOverview />
      </UIAuthorization>
      <CommoditiesOverview auth={auth} />
      <UIAuthorization access={auth.access.settlements.list}>
        <SettlementsOverview />
      </UIAuthorization>
      <UIAuthorization access={params.fileType === globalFileTypes.finalInvoice}>
        <ManageGlobalFiles
          manageGlobalFiles={manageGlobalFiles}
          lot={lot}
          fileLabel={LOTS.GRG_FINAL_INVOICE}
          approveFileAction={approveFinalInvoice}
          denyFileAction={denyFinalInvoice}
          draftFile={lot.grgFinalInvoiceDraft}
          modalHeader={LOTS.MANAGE_FINAL_INVOICE_HEADER(lot.grgLotNumber)}
          modalInfo={
            stripHtmlTags(notification?.messageText) ||
            LOTS.MANAGE_FINAL_INVOICE_INFO(lot.grgLotNumber)
          }
        />
      </UIAuthorization>
      <UIAuthorization access={params.fileType === globalFileTypes.shipmentSummary}>
        <ManageGlobalFiles
          manageGlobalFiles={manageGlobalFiles}
          lot={lot}
          fileLabel={LOTS.GRG_SHIPMENT_SUMMARY}
          approveFileAction={approveShipmentSummary}
          denyFileAction={denyShipmentSummary}
          draftFile={lot.grgShipmentSummaryDraft}
          modalHeader={LOTS.MANAGE_SHIPMENT_SUMMARY_HEADER(lot.grgLotNumber)}
          modalInfo={LOTS.MANAGE_SHIPMENT_SUMMARY_INFO(lot.grgLotNumber)}
        />
      </UIAuthorization>
      <UIAuthorization access={params.fileType === globalFileTypes.shipmentSummaryOverwrite}>
        <ManageGlobalFiles
          manageGlobalFiles={manageGlobalFiles}
          lot={lot}
          fileLabel={LOTS.GRG_SHIPMENT_SUMMARY}
          approveFileAction={handleApproveShipmentSummaryOverwrite}
          denyFileAction={handleDenyShipmentSummaryOverwrite}
          draftFile={lot.grgShipmentSummaryDraft}
          modalHeader={notification?.messageSubject}
          modalInfo={stripHtmlTags(notification?.messageText)}
          cancelLabel={SHARED.NO}
          approveLabel={SHARED.YES}
        />
      </UIAuthorization>
      <UIAuthorization
        access={
          lot.grgProfitAndLossDraftData?.file && params.fileType === globalFileTypes.profitAndLoss
        }
      >
        <ManageGlobalFiles
          manageGlobalFiles={manageGlobalFiles}
          lot={lot}
          fileLabel={LOTS.PROFIT_AND_LOSS}
          approveFileAction={handleApproveProfitAndLoss}
          denyFileAction={denyProfitAndLoss}
          draftFile={lot.grgProfitAndLossDraftData?.file}
          modalHeader={LOTS.MANAGE_PROFIT_AND_LOSS_HEADER(lot.grgLotNumber)}
          modalInfo={LOTS.MANAGE_PROFIT_AND_LOSS_INFO(lot.grgLotNumber, isNote)}
        />
      </UIAuthorization>
      <UIAuthorization access={params.fileType === globalFileTypes.finalInvoiceMockup}>
        <ForwardDocument
          isOpen
          downloadable
          fileName={LOTS.FINAL_INVOICE_MOCKUP}
          toggle={toggleForwardFinalInvoiceMockup}
          file={lot.grgFinalInvoiceMockup}
        />
      </UIAuthorization>
      <UIAuthorization access={path.endsWith('timeline')}>
        <LotTimeline />
      </UIAuthorization>
    </>
  );
};

LotDetailsGlobal.propTypes = {
  auth: PropTypes.object,
  packagesOverviewList: PropTypes.object,
  lot: PropTypes.object,
  hedgesOverviewList: PropTypes.object,
};
