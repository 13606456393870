import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { cssVar, pxToRem, media } from 'shared/helpers/styling/styling';

import { Icon } from '../Icon/Icon';

const BreadcrumbItem = ({ className, content }) => (
  <span className={className}>
    <Icon icon="icon-little-arrow" />
    {content}
  </span>
);

const BreadcrumbItemStyled = styled(BreadcrumbItem)`
  font-size: ${pxToRem(14)};
  color: ${cssVar('ghostWhiteGRG')};
  font-weight: 500;

  a {
    color: ${cssVar('ghostWhiteGRG')};
    width: max-content;
    font-weight: 300;

    &:hover {
      color: ${cssVar('atlantis')};
    }
  }

  ${Icon} {
    width: 15px;
    height: 10px;
  }

  &:nth-child(1) {
    ${Icon} {
      display: none;
    }
  }
`;

BreadcrumbItemStyled.propTypes = {
  content: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

const BreadcrumbWrapper = ({ className, content, reference }) => (
  <div className={className} ref={reference}>
    {content}
  </div>
);

const BreadcrumbWrapperStyled = styled(BreadcrumbWrapper)`
  border-bottom: 1px solid rgba(255, 255, 255, 0.17);
  padding: 20px 0;
  overflow-x: auto;
  white-space: nowrap;

  ${media.phone`
    padding-left: 15px;
    margin-bottom: 25px;
  `}
`;

BreadcrumbWrapperStyled.propTypes = {
  content: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  reference: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
};

export { BreadcrumbItemStyled as BreadcrumbItem, BreadcrumbWrapperStyled as BreadcrumbWrapper };
