import axios from 'axios';

import dateAdapter from 'utils/date/dateAdapter';

const API_HOST_AUTH = process.env.REACT_APP_API;

const deleteCalendarNoteActionType = 'DELETE_CALENDAR_NOTE';
const deleteCalendarNoteAction = () => ({
  type: deleteCalendarNoteActionType,
});

const deleteCalendarNoteSuccessActionType = `${deleteCalendarNoteActionType}_SUCCESS`;
const deleteCalendarNoteSuccessAction = () => ({
  type: deleteCalendarNoteSuccessActionType,
});

const deleteCalendarNoteFailureActionType = `${deleteCalendarNoteActionType}_FAILURE`;
const deleteCalendarNoteFailureAction = () => ({
  type: deleteCalendarNoteFailureActionType,
});

const deleteCalendarNote = id => async dispatch => {
  dispatch(deleteCalendarNoteAction());

  try {
    const { data } = await axios.delete(`${API_HOST_AUTH}/calendar-notes/${id}`);
    dispatch(deleteCalendarNoteSuccessAction());

    return data;
  } catch (error) {
    dispatch(deleteCalendarNoteFailureAction());

    return Promise.reject(error.data);
  }
};

const deleteOneCalendarNote = (id, date) => async dispatch => {
  dispatch(deleteCalendarNoteAction());

  const removeDate = dateAdapter(date).valueOf();

  try {
    const { data } = await axios.request({
      method: 'delete',
      url: `${API_HOST_AUTH}/calendar-notes`,
      data: { id, removeDate },
    });
    dispatch(deleteCalendarNoteSuccessAction());

    return data;
  } catch (error) {
    dispatch(deleteCalendarNoteFailureAction());

    return Promise.reject(error.data);
  }
};

export {
  deleteCalendarNote,
  deleteOneCalendarNote,
  deleteCalendarNoteAction,
  deleteCalendarNoteActionType,
  deleteCalendarNoteFailureAction,
  deleteCalendarNoteFailureActionType,
  deleteCalendarNoteSuccessAction,
  deleteCalendarNoteSuccessActionType,
};
