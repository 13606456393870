import { isOwnerUser } from 'shared/helpers/matchers/checkUserType';
import { isGlobalRefiningGroup } from 'shared/helpers/matchers/isGlobalRefiningGroup';

const editDefaultPPMsAccess = (user, location) => {
  const isGlobalLocation = isGlobalRefiningGroup(location?.relatedCompany?.companyName);
  const isOwner = isOwnerUser(user?.userType);
  return isGlobalLocation && isOwner;
};

export { editDefaultPPMsAccess };
