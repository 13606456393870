import axios from 'axios';

import { showSnackbar } from 'actions/shared/snackbar';

import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';
import {
  dangerOptions,
  successOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

const API_HOST_AUTH = process.env.REACT_APP_API;

const holdVirtualLotActionType = 'HOLD_VIRTUAL_LOT';
const holdVirtualLotAction = () => ({
  type: holdVirtualLotActionType,
});

const holdVirtualLotSuccessActionType = `${holdVirtualLotActionType}_SUCCESS`;
const holdVirtualLotSuccessAction = () => ({
  type: holdVirtualLotSuccessActionType,
});

const holdVirtualLotFailureActionType = `${holdVirtualLotActionType}_FAILURE`;
const holdVirtualLotFailureAction = () => ({
  type: holdVirtualLotFailureActionType,
});

const holdVirtualLot = lotId => dispatch => {
  dispatch(holdVirtualLotAction());

  return axios
    .patch(`${API_HOST_AUTH}/virtual-lots/${lotId}/hold`)
    .then(res => {
      dispatch(holdVirtualLotSuccessAction());
      dispatch(showSnackbar(successOptions, res.data.message));
    })
    .catch(() => {
      dispatch(holdVirtualLotFailureAction());
      dispatch(showSnackbar(dangerOptions, LOTS.ERRORS.VIRTUAL_LOT_MOVE_TO_HOLD_ERROR));
    });
};

export {
  holdVirtualLot,
  holdVirtualLotActionType,
  holdVirtualLotSuccessActionType,
  holdVirtualLotFailureActionType,
};
