const CREATE_DELIVERY = {
  RELATED_HEDGE_EMPTY: 'Related hedge is required.',
  RELATED_LOT_EMPTY: 'Related lot is required.',
  PLATINUM_OZ_EMPTY: 'Platinum oz. cannot be empty.',
  PLATINUM_OZ_INVALID: 'Platinum oz. must have a valid format.',
  PLATINUM_OZ_NEGATIVE: 'Platinum oz. cannot be less than 0.',
  PLATINUM_OZ_MAXIMUM: 'Platinum oz. is too high.',
  PALLADIUM_OZ_EMPTY: 'Palladium oz. cannot be empty.',
  PALLADIUM_OZ_INVALID: 'Palladium oz. must have a valid format.',
  PALLADIUM_OZ_NEGATIVE: 'Palladium oz. cannot be less than 0.',
  PALLADIUM_OZ_MAXIMUM: 'Palladium oz. is too high.',
  RHODIUM_OZ_EMPTY: 'Rhodium oz. cannot be empty.',
  RHODIUM_OZ_INVALID: 'Rhodium oz. must have a valid format.',
  RHODIUM_OZ_NEGATIVE: 'Rhodium oz. cannot be less than 0.',
  RHODIUM_OZ_MAXIMUM: 'Rhodium oz. is too high.',
  DELIVERY_EMPTY: 'At least one weight should have value greater than zero.',
};

export { CREATE_DELIVERY };
