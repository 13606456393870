const parsePkgToCompare = obj => ({
  customerInstructions: obj.customerInstructions || null,
  labelId: obj.labelId || null,
  packageType: obj.packageType.value || obj.packageType,
  plant: obj.plant || null,
  weightGrossDeclared: +obj.weightGrossDeclared,
  weightTareDeclared: +obj.weightTareDeclared,
});

export { parsePkgToCompare };
