import {
  getLotSettlementsType,
  getLotSettlementsFailureType,
  getLotSettlementsSuccessType,
  clearLotSettlementsType,
} from 'actions/Settlements/getLotSettlements';

const INITIAL_STATE = {
  isPending: false,
  settlements: null,
  limit: 0,
};

const GetLotSettlementsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getLotSettlementsType:
      return {
        ...state,
        isPending: true,
        limit: action.payload.limit,
      };
    case getLotSettlementsSuccessType:
      return {
        ...state,
        isPending: false,
        settlements: action.payload.settlements,
      };
    case clearLotSettlementsType:
    case getLotSettlementsFailureType:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};

export { GetLotSettlementsReducer };
