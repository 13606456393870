import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import {
  createTrustedCompany,
  filterTrustedCompaniesToAdd,
} from 'actions/Companies/privilegedCompaniesCreate';
import { openModal } from 'actions/shared/modal';
import { showSnackbar } from 'actions/shared/snackbar';

import { TrustedCompaniesCreate } from './TrustedCompaniesCreate';

const mapStateToProps = ({ modal }) => ({
  modal,
});

const mapDispatchToProps = {
  createTrustedCompany,
  filterTrustedCompaniesToAdd,
  openModal,
  showSnackbar,
};

const ConnectedTrustedCompaniesCreate = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TrustedCompaniesCreate),
);

export { ConnectedTrustedCompaniesCreate };
