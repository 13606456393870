import { shipmentDirections } from '../shipments/shipmentDirections';
import { shipmentStatuses } from '../shipments/shipmentStatuses';
import { statusesForGlobal } from './statusesForGlobal';

const outgoingSections = {
  readyToShip: {
    name: 'readyToShip',
    title: 'Ready to ship',
    query: {
      filters: {
        statusForGlobal: [statusesForGlobal.ready_to_ship],
        outgoingShipment: false,
      },
      sort: { grgLotNumber: 1 },
    },
  },
  shippedFromGlobal: {
    name: 'shippedFromGlobal',
    title: 'Shipped from Alpha',
    query: {
      filters: {
        statusForGlobal: [statusesForGlobal.shipped_from_global],
      },
      sort: { grgLotNumber: 1 },
    },
  },
  unsentShipments: {
    name: 'unsentShipments',
    title: 'Unsent shipments',
    query: {
      shipmentDirection: shipmentDirections.outgoing,
      filters: {
        status: [shipmentStatuses.open],
      },
    },
  },
};

export { outgoingSections };
