import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { reduxForm, Field } from 'redux-form';

import { openModal } from 'actions/shared/modal';

import { FieldDate } from 'shared/components/Fields/FieldDate/FieldDate';
import { Form, Section } from 'shared/components/Form';
import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';
import { cancelModal } from 'shared/helpers/constants/modalConstants';
import { validate } from 'shared/helpers/validations/lot/changeReceivedDateValidation';

import dateAdapter from 'utils/date/dateAdapter';

function ChangeReceivedDateFormBase({
  match: {
    params: { id },
  },
  createdAt,
  history,
  handleSubmit,
  invalid,
  initialValues: { receivedAt },
  submitting,
  touch,
}) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (receivedAt) {
      touch('receivedAt');
    }
  }, []);

  const navigateBack = () => history.push(`/lots/list/${id}`);

  const openModalOnCancel = () => {
    dispatch(openModal(cancelModal, navigateBack));
  };

  const maxDate = dateAdapter().subtract(1, 'd').endOf('d').valueOf();

  return (
    <Form
      onSubmit={handleSubmit}
      onCancel={openModalOnCancel}
      header={LOTS.RECEIVED_ON}
      submitDisabled={invalid || submitting}
    >
      <Section template={['receivedAt .']}>
        <Field
          name="receivedAt"
          label={LOTS.RECEIVED_ON}
          component={FieldDate}
          field="required"
          maxDate={maxDate}
        />
      </Section>
    </Form>
  );
}

const ChangeReceivedDateForm = compose(
  withRouter,
  reduxForm({
    form: 'updateShipmentInfo',
    validate,
  }),
)(ChangeReceivedDateFormBase);

export { ChangeReceivedDateForm, ChangeReceivedDateFormBase };
