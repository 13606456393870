import * as yup from 'yup';

import { message } from 'shared/helpers/constants/users/createUserConstants';
import { EMAIL_REGEX } from 'shared/helpers/validations/regularExpressions';

export const validationSchema = yup.object().shape({
  email: yup
    .string()
    .required(message.INVALID_EMAIL_EMPTY)
    .max(100, message.INVALID_EMAIL)
    .matches(EMAIL_REGEX, message.INVALID_EMAIL),
  password: yup
    .string()
    .required(message.INVALID_PASSWORD_EMPTY)
    .max(100, message.INVALID_PASSWORD),
});

export const initialValues = {
  email: '',
  password: '',
};
