import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getPriceFeedType = 'GET_PRICE_FEED';
const getPriceFeedAction = () => ({
  type: getPriceFeedType,
});

const getPriceFeedSuccessType = `${getPriceFeedType}_SUCCESS`;
const getPriceFeedSuccessAction = priceFeed => ({
  type: getPriceFeedSuccessType,
  payload: {
    priceFeed,
  },
});

const getPriceFeedFailureType = `${getPriceFeedType}_FAILURE`;
const getPriceFeedFailureAction = () => ({
  type: getPriceFeedFailureType,
});

const clearPriceFeedType = 'CLEAR_PRICE_FEED';
const clearPriceFeedsTypeAction = () => ({
  type: clearPriceFeedType,
});

const getPriceFeed = priceFeedId => dispatch => {
  dispatch(getPriceFeedAction());

  return axios
    .get(`${API_HOST_AUTH}/price-feeds/${priceFeedId}`)
    .then(res => {
      dispatch(getPriceFeedSuccessAction(res.data));

      return res.data;
    })
    .catch(err => {
      dispatch(getPriceFeedFailureAction());

      return Promise.reject(err.data);
    });
};

export {
  getPriceFeedType,
  getPriceFeedSuccessType,
  getPriceFeedFailureType,
  clearPriceFeedType,
  getPriceFeed,
  clearPriceFeedsTypeAction as clearPriceFeed,
};
