import { parseFromTimeZone, formatToTimeZone, convertToTimeZone } from 'date-fns-timezone';
import { DateTime } from 'luxon';

import { dateFormat as defaultDateFormat } from 'shared/helpers/parsers/date';

import { companyTimezone as zone } from './companyTimezone';

class MomentAdapter {
  constructor(date = new Date()) {
    if (date instanceof MomentAdapter) {
      this.dateTime = date.dateTime;
    } else if (date instanceof DateTime) {
      this.dateTime = date;
    } else if (date instanceof Date) {
      this.dateTime = DateTime.fromJSDate(date, { zone });
    } else if (Number.isInteger(date)) {
      this.dateTime = DateTime.fromMillis(date, { zone });
    } else if (typeof date === 'string') {
      this.dateTime = DateTime.fromJSDate(
        parseFromTimeZone(date, defaultDateFormat, { timeZone: zone }),
      );
    } else {
      this.dateTime = DateTime.fromJSDate(new Date(date === Infinity ? date : 0));
    }
  }

  startOf = unit => {
    switch (unit) {
      case 'h':
        return new MomentAdapter(this.dateTime.startOf('hour'));
      case 'd':
        return new MomentAdapter(this.dateTime.startOf('day'));
      case 'y':
        return new MomentAdapter(this.dateTime.startOf('year'));
      default:
        return new MomentAdapter(this.dateTime.startOf(unit));
    }
  };

  endOf = unit => {
    switch (unit) {
      case 'h':
        return new MomentAdapter(this.dateTime.endOf('hour'));
      case 'd':
        return new MomentAdapter(this.dateTime.endOf('day'));
      case 'y':
        return new MomentAdapter(this.dateTime.endOf('year'));
      default:
        return new MomentAdapter(this.dateTime.endOf(unit));
    }
  };

  add = (value, unit) => {
    let date = this.dateTime;
    switch (unit) {
      case 'm':
        return new MomentAdapter(this.dateTime.plus({ minutes: value }));
      case 'h':
        return new MomentAdapter(this.dateTime.plus({ hours: value }));
      case 'd':
        return new MomentAdapter(this.dateTime.plus({ days: value }));
      case 'y':
        return new MomentAdapter(this.dateTime.plus({ years: value }));
      default:
        date = this.dateTime;
    }
    return new MomentAdapter(date);
  };

  subtract = (value, unit) => new MomentAdapter(this.add(-value, unit));

  isAfter = date => new MomentAdapter(date).valueOf() < this.valueOf();

  isBefore = date => new MomentAdapter(date).valueOf() > this.valueOf();

  isBetween = (date1, date2) => this.isAfter(date1) && this.isBefore(date2);

  format = (dateFormat = defaultDateFormat) =>
    formatToTimeZone(this.toDate(), dateFormat, { timeZone: zone });

  convertToTimestampWithTimezone = date =>
    convertToTimeZone(new MomentAdapter(date), {
      timeZone: zone,
    }).getTime();

  toDate = () => this.dateTime.toJSDate();

  valueOf = () => this.dateTime.valueOf();

  getTime = () => this.dateTime.valueOf();

  isValid = () => this.dateTime.isValid && this.dateTime.ts !== Infinity;

  localize = () => new Date(this.dateTime.year, this.dateTime.month - 1, this.dateTime.day);

  localizeTime = () =>
    new Date(
      this.dateTime.year,
      this.dateTime.month - 1,
      this.dateTime.day,
      this.dateTime.hour,
      this.dateTime.minute,
    );

  static normalize = date =>
    DateTime.fromObject(
      {
        day: date.getDate(),
        month: date.getMonth() + 1,
        year: date.getFullYear(),
      },
      { zone },
    );

  static normalizeWithTime = date =>
    DateTime.fromObject(
      {
        minute: date.getMinutes(),
        hour: date.getHours(),
        day: date.getDate(),
        month: date.getMonth() + 1,
        year: date.getFullYear(),
      },
      { zone },
    );

  static weekday = date => DateTime.fromMillis(date, zone).weekday;

  static formatLocal = (date, format) =>
    formatToTimeZone(date, format, { timeZone: DateTime.local().zoneName });
}

const ProxiedMomentAdapter = new Proxy(MomentAdapter, {
  construct(Target, args) {
    return new Proxy(new Target(...args), {
      get(obj, prop) {
        return obj[prop] || obj.dateTime[prop];
      },
    });
  },
});

export { ProxiedMomentAdapter as MomentAdapter };
