import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { getCompanyLocations } from 'actions/Locations/getCompanyLocations';
import { clearAllPackagesOverview } from 'actions/Packages/packagesOverviewList';

import { Lots } from './Lots';

function mapStateToProps({ auth, router, companyLocations, packagesOverviewList }) {
  return {
    auth,
    router,
    companyLocations,
    packagesOverviewList,
  };
}

const mapDispatchToProps = {
  getCompanyLocations,
  clearAllPackagesOverview,
};

const ConnectedLots = withRouter(connect(mapStateToProps, mapDispatchToProps)(Lots));

export { ConnectedLots };
