import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  display: grid;
  width: 100%;
  height: 100px;
  grid-template: minmax(23px, auto) minmax(50px, auto) / minmax(50px, auto) minmax(50px, auto);
  text-align: ${({ hide }) => (hide ? 'left' : 'center')};
  padding: 0 5px 24px 5px;
  grid-template-areas:
    'error error'
    'input input';
`;

Container.propTypes = {
  hide: PropTypes.bool,
};

Container.defaultProps = {
  hide: false,
};

export { Container };
