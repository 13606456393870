import React, { useEffect, useCallback } from 'react';
import { useDispatch, connect } from 'react-redux';
import { useLocation, withRouter } from 'react-router';
import { useHistory } from 'react-router-dom';
import { compose } from 'redux';
import { Field, FieldArray, FormSection, reduxForm, getFormValues, reset } from 'redux-form';

import { getAvailableHedges } from 'actions/Deliveries/getAvailableHedges';
import { getLotAvailableOunces } from 'actions/Deliveries/getLotAvailableOunces';

import { FieldSelect } from 'shared/components/Fields/FieldSelect/FieldSelect';
import { Form, Section, Separator } from 'shared/components/Form';
import { formName } from 'shared/components/Wizard/Wizard';
import { POOL_ACCOUNTS } from 'shared/helpers/constants/poolAccounts/poolAccountsConstants';
import { SETTLEMENT_FOR_LOT_WIZARD } from 'shared/helpers/constants/poolAccounts/settlementForLotWizard';
import { parseSummaryAvailableSelectData } from 'shared/helpers/dataParsers/poolAccounts/parseSummaryAvailableSelectData';
import { multiValidator } from 'shared/helpers/validations/multiValidator';
import { validate } from 'shared/helpers/validations/poolAccounts/applyBlanketHedgesValidation';

import { ApplyBlanketHedgesList } from './ApplyBlanketHedgesList/ApplyBlanketHedgesList';

const { RELATED_LOT, HEDGE_OUNCES } = SETTLEMENT_FOR_LOT_WIZARD;

const ApplyBlanketHedgesFormLayout = ({
  invalid,
  change,
  blur,
  handleSubmit,
  formValues,
  hedges,
  availableOunces,
  match,
  summaryAvailableLots,
  requestSettlementWizardData,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const removeSelectedLots = selectedLots => availableLots =>
    availableLots.filter(lot => selectedLots.indexOf(lot.value) < 0);

  const filteredLots = compose(
    removeSelectedLots(Object.keys(requestSettlementWizardData || {})),
    parseSummaryAvailableSelectData,
  )(summaryAvailableLots.lots);

  useEffect(() => {
    try {
      Promise.all([dispatch(getAvailableHedges('ascending'))]);
    } catch (e) {
      history.push('/lots');
    }
  }, []);

  useEffect(() => {
    if (filteredLots && filteredLots.length && match.params.id) {
      const lotToSelect = filteredLots.find(lot => lot.value === match.params.id);

      if (lotToSelect) {
        dispatch(getLotAvailableOunces(lotToSelect.value));
        change('applyBlanketHedges.relatedLot', lotToSelect);
      }
    }
  }, [summaryAvailableLots]);

  const onLotChange = useCallback(
    currentValue => {
      const selectedLot = formValues?.applyBlanketHedges.relatedLot?.value;

      if (selectedLot !== currentValue?.value) {
        dispatch(reset(formName));
      }

      if (currentValue) {
        dispatch(getLotAvailableOunces(currentValue.value));
      }
    },
    [dispatch, formValues],
  );

  return (
    <Form header={HEDGE_OUNCES} onSubmit={handleSubmit} submitDisabled={invalid}>
      <FormSection name="applyBlanketHedges">
        <Section header={RELATED_LOT} template={['relatedLot .']}>
          <Field
            name="relatedLot"
            component={FieldSelect}
            options={filteredLots}
            label={POOL_ACCOUNTS.RELATED_LOT}
            onChange={onLotChange}
            field="required"
            searchBy={[['sentFromCompany', 'companyName']]}
          />
        </Section>
        <Separator />
        <FieldArray
          name="deliveries"
          component={ApplyBlanketHedgesList}
          location={location}
          change={change}
          blur={blur}
          formValues={formValues}
          requestSettlementWizardData={requestSettlementWizardData}
          hedges={hedges}
          availableOunces={availableOunces}
          invalid={invalid}
        />
      </FormSection>
    </Form>
  );
};

const mapStateToProps = state => ({
  formValues: getFormValues('wizard')(state),
  hedges: state.availableHedges.hedges,
  availableOunces: state.lotAvailableOunces,
  summaryAvailableLots: state.summaryAvailableLots,
  requestSettlementWizardData: state.requestSettlementWizard.data,
});

const ApplyBlanketHedgesForm = compose(
  withRouter,
  connect(mapStateToProps),
  reduxForm({
    validate: multiValidator('applyBlanketHedges', validate),
  }),
)(ApplyBlanketHedgesFormLayout);

export { ApplyBlanketHedgesForm, ApplyBlanketHedgesFormLayout };
