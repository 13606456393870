import { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  clearReportsTotalPaymentsAction,
  getReportsTotalPayments,
} from 'actions/Payments/reportsTotalPaymentsList';

import { GroupButtons } from 'shared/components/Buttons';
import { GroupButton } from 'shared/components/Buttons/GroupButton/components/GroupButton';
import { FullPageList } from 'shared/components/FullPageList/FullPageList';
import { REPORTS } from 'shared/helpers/constants/reports/reportsConstants';
import {
  itemsTemplate,
  itemsTemplateMobile,
  itemsDetailsTemplate,
} from 'shared/helpers/constants/reports/totalPaymentsTableConfig';
import { isMobile } from 'shared/helpers/styling/styling';

import dateAdapter from 'utils/date/dateAdapter';

import { TotalPaymentsFilters } from './components/TotalPaymentsFilters/TotalPaymentsFilters';

const TotalPayments = () => {
  const dispatch = useDispatch();
  const auth = useSelector(({ auth }) => auth);
  const history = useHistory();

  const { isPending, payments, filters, pagination } = useSelector(
    ({ reportsTotalPaymentsList }) => reportsTotalPaymentsList,
  );

  useEffect(() => () => dispatch(clearReportsTotalPaymentsAction()), []);

  const fetchList = useCallback(
    (page = 1, limit = 50, query) =>
      dispatch(
        getReportsTotalPayments(
          {
            ...filters,
            query,
          },
          { page, limit },
        ),
      ),
    [dispatch, filters],
  );

  const onFiltersChanged = searchFilter => filters =>
    dispatch(
      getReportsTotalPayments(
        { ...filters, query: searchFilter },
        { page: 1, limit: pagination.limit },
      ),
    );

  const renderFilters = searchFilter => (
    <TotalPaymentsFilters
      filters={filters}
      pagination={pagination}
      onFiltersChanged={onFiltersChanged(searchFilter)}
      auth={auth}
    />
  );

  const renderActionButtons = () => {
    return (
      <div className="TotalPayments-actionButtons">
        <GroupButtons>
          <GroupButton
            id="lotsAwaitingResults"
            access={auth.access.reports && isMobile()}
            onClick={() => history.push('/reports/lots-awaiting-results')}
            label={REPORTS.LOTS_AWAITING_RESULTS}
            icon="waiting"
          />
          <GroupButton
            id="analysisReports"
            access={auth.access.reports && isMobile()}
            onClick={() => history.push('/reports/analysis')}
            label={REPORTS.ANALYSIS_REPORTS}
            icon="calendar-note"
          />
        </GroupButtons>
      </div>
    );
  };

  const filtersClear = searchFilter =>
    dispatch(
      getReportsTotalPayments(
        {
          query: searchFilter,
          status: [],
          paymentType: [],
          fundsType: [],
          isPaid: true,
        },
        { limit: pagination.limit, page: 1 },
      ),
    );

  const getActionsTemplate = useCallback(
    () => [
      ...(auth.access.payments.details
        ? [
            {
              icon: 'arrow-right',
              action: 'Details',
              onClick: payment => () => {
                history.push(`/payments/${payment._id}`);
              },
            },
          ]
        : []),
    ],
    [auth],
  );

  const filtersSelected = filters =>
    filters.paymentType.length ||
    filters.fundsType.length ||
    filters.paidAtStart ||
    filters.paidAtEnd;

  const isToday = date =>
    !!date && dateAdapter(date).startOf('d').valueOf() === dateAdapter().startOf('d').valueOf();

  const showListedTitle = ({ paymentType, fundsType, paidAtStart, paidAtEnd }) =>
    paymentType.length || fundsType.length || !isToday(paidAtStart) || !isToday(paidAtEnd);

  return (
    <FullPageList
      title={REPORTS.TOTAL_PAYMENTS}
      subheader={REPORTS.TOTAL_PAYMENTS_TITLE({
        filtersSelected: showListedTitle(filters),
        value: payments.totalPaymentAmount,
      })}
      itemsTemplate={itemsTemplate}
      itemsDetailsTemplate={itemsDetailsTemplate}
      itemsTemplateMobile={itemsTemplateMobile}
      actionsTemplate={getActionsTemplate()}
      items={payments.docs || []}
      fetchList={fetchList}
      filterComponent={renderFilters}
      pagination={{
        ...pagination,
        pages: payments.pages,
      }}
      enableSearch
      filtersClear={filtersClear}
      filtersSelected={filtersSelected(filters)}
      filterAccess
      actionButtons={renderActionButtons}
      isPending={isPending}
      filters={filters}
      excludedFilters={['isPaid']}
    />
  );
};

export { TotalPayments };
