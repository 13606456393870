import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { cancelHedge } from 'actions/Hedges/SpecialPermissions/cancelHedge';
import { hedgesDecision } from 'actions/Hedges/hedgesDecision';
import { deleteHedge } from 'actions/Hedges/hedgesDelete';
import { getHedgeDetails } from 'actions/Hedges/hedgesDetails';
import { openModal } from 'actions/shared/modal';
import { showSnackbar } from 'actions/shared/snackbar';

import { UIAuthorization } from 'components/Authorization/UIAuthorization';
import { NotificationActionsPortal } from 'components/NotificationActionsPortal/NotificationActionsPortal';

import { Button } from 'shared/components/Buttons/Button';
import { ExtendableButton } from 'shared/components/Buttons/ExtendableButton/ExtendableButton';
import { RouterButton } from 'shared/components/Buttons/index';
import { DetailsHeader } from 'shared/components/Details/DetailsHeader';
import {
  enterPricesAccess,
  enterCustomPricesAccess,
  changeHedgeTypeAccess,
  deleteHedgeAccess,
  reassignHedgeAccess,
} from 'shared/helpers/accesses/hedges';
import { cancelHedgeAccess } from 'shared/helpers/accesses/hedges/specialPermissions/cancelHedgeAccess';
import { transferAccess } from 'shared/helpers/accesses/hedges/specialPermissions/transferAccess';
import { transferOuncesAccess } from 'shared/helpers/accesses/hedges/specialPermissions/transferOuncesAccess';
import { updateOuncesAccess } from 'shared/helpers/accesses/hedges/specialPermissions/updateOuncesAccess';
import {
  updatePricesAccess,
  updateCustomPricesAccess,
} from 'shared/helpers/accesses/hedges/specialPermissions/updatePricesAccess';
import { hedgeStatus } from 'shared/helpers/constants/hedges/hedgeStatus';
import { HEDGES } from 'shared/helpers/constants/hedges/hedgesConstants';
import { message } from 'shared/helpers/constants/hedges/validateHedgesConstants';
import { deleteHedgeModal, cancelHedgeModal } from 'shared/helpers/constants/modalConstants';
import { notificationActionTypes } from 'shared/helpers/constants/notifications/notificationActionTypes';
import {
  successOptions,
  dangerOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { parseOpenStatus } from 'shared/helpers/dataParsers/hedges/parseOpenStatus';
import { isTooltipVisible } from 'shared/helpers/matchers/isTooltipVisible';

import { HedgesChangeType } from 'pages/Hedges/components/HedgesManagement/components/HedgeChangeType/HedgesChangeType';
import { HedgeReassign } from 'pages/Hedges/components/HedgesManagement/components/HedgeReassign/HedgeReassign';

class HedgesMainDetails extends Component {
  state = {
    displayReassignModal: false,
    displayChangeTypeModal: false,
    superAdminMode: false,
  };

  deleteHedgeCallback = () => {
    const {
      hedgesDetails: { hedge },
      history,
    } = this.props;

    this.props
      .deleteHedge(hedge)
      .then(res => {
        this.props.showSnackbar(successOptions, res.message);
        history.push('/hedges');
      })
      .catch(() => showSnackbar(dangerOptions, message.HEDGES_DELETE_ERROR));
  };

  hedgeDecision = decision => () => {
    const {
      hedgesDetails: { hedge },
    } = this.props;

    this.props
      .hedgesDecision(hedge, decision)
      .then(res => {
        this.props.showSnackbar(successOptions, res.message);
        this.props.getHedgeDetails(hedge._id);
      })
      .catch(() => showSnackbar(dangerOptions, message.HEDGES_UPDATE_ERROR));
  };

  toggleChangeTypeModal = () =>
    this.setState(({ displayChangeTypeModal }) => ({
      displayChangeTypeModal: !displayChangeTypeModal,
    }));

  toggleReassignModal = (superAdminMode = false) => {
    this.setState(({ displayReassignModal }) => ({ displayReassignModal: !displayReassignModal }));
    this.setState({ superAdminMode });
  };

  deleteHedge = () => this.props.openModal(deleteHedgeModal, this.deleteHedgeCallback);

  cancelHedgeCallback = () => {
    const {
      hedgesDetails: { hedge },
      history,
    } = this.props;

    this.props
      .cancelHedge(hedge._id)
      .then(({ message }) => {
        this.props.showSnackbar(successOptions, message);
        history.push('/hedges');
      })
      .catch(({ message }) => this.props.showSnackbar(dangerOptions, message));
  };

  cancelHedge = () => this.props.openModal(cancelHedgeModal, this.cancelHedgeCallback);

  render() {
    const { superAdminMode, displayReassignModal, displayChangeTypeModal } = this.state;
    const {
      auth,
      hedgesDetails: { hedge },
    } = this.props;
    const currentStatus = parseOpenStatus({
      status: hedge.status,
      requestedHedgeDate: hedge.requestedHedgeDate,
    });

    return (
      <DetailsHeader
        header={`${HEDGES.HEDGE} ${hedge.hedgeName}`}
        status={currentStatus}
        tooltipContent={isTooltipVisible(hedge?.requestedHedgeDate, currentStatus)}
        id={hedge._id}
      >
        <ExtendableButton label={HEDGES.ACTIONS} closeOnClick>
          <UIAuthorization access={enterPricesAccess(auth, hedge)}>
            <RouterButton
              id="enterPrices"
              path={`/hedges/${hedge._id}/prices`}
              label={HEDGES.ENTER_PRICES}
            />
          </UIAuthorization>
          <UIAuthorization access={changeHedgeTypeAccess(auth, hedge)}>
            <Button id="changeType" onClick={this.toggleChangeTypeModal}>
              {HEDGES.CHANGE_TYPE}
            </Button>
          </UIAuthorization>
          <UIAuthorization access={reassignHedgeAccess(auth, hedge)}>
            <Button id="reassign" onClick={() => this.toggleReassignModal()}>
              {HEDGES.REASSIGN_HEDGE}
            </Button>
          </UIAuthorization>
          <UIAuthorization access={cancelHedgeAccess(auth, hedge)}>
            <Button id="cancelHedge" color="danger" onClick={this.cancelHedge}>
              {HEDGES.CANCEL_HEDGE_ADMIN}
            </Button>
          </UIAuthorization>
          <UIAuthorization access={transferAccess(auth, hedge)}>
            <Button
              id="reassignSuperAdminMode"
              color="danger"
              onClick={() => this.toggleReassignModal(true)}
            >
              {HEDGES.ADMIN_TRANSFER_HEDGE}
            </Button>
          </UIAuthorization>
          <UIAuthorization access={enterCustomPricesAccess(auth, hedge)}>
            <RouterButton
              id="enterCustomPrices"
              path={`/hedges/${hedge._id}/custom-prices`}
              label={HEDGES.ENTER_CUSTOM_PRICES}
            />
          </UIAuthorization>
          <UIAuthorization access={updatePricesAccess(auth, hedge)}>
            <RouterButton
              id="changePrices"
              color="danger"
              path={`/hedges/${hedge._id}/prices/update`}
              label={HEDGES.ADMIN_CHANGE_PRICES}
            />
          </UIAuthorization>
          <UIAuthorization access={updateCustomPricesAccess(auth, hedge)}>
            <RouterButton
              id="changeCustomPrices"
              color="danger"
              path={`/hedges/${hedge._id}/custom-prices/update`}
              label={HEDGES.ADMIN_CHANGE_CUSTOM_PRICES}
            />
          </UIAuthorization>
          <UIAuthorization access={updateOuncesAccess(auth, hedge)}>
            <RouterButton
              id="changeOunces"
              color="danger"
              path={`/hedges/${hedge._id}/ounces/update`}
              label={HEDGES.ADMIN_CHANGE_OUNCES}
            />
          </UIAuthorization>
          <UIAuthorization access={auth.access.hedges.update && hedge.status === hedgeStatus.open}>
            <NotificationActionsPortal actions={notificationActionTypes.hedgeRequest}>
              <Button id="approveHedge" onClick={this.hedgeDecision(hedgeStatus.approved)}>
                {HEDGES.APPROVE}
              </Button>
            </NotificationActionsPortal>
          </UIAuthorization>
          <UIAuthorization access={auth.access.hedges.update && hedge.status === hedgeStatus.open}>
            <NotificationActionsPortal actions={notificationActionTypes.hedgeRequest}>
              <Button
                id="denyHedge"
                color="danger"
                outlineColor="danger"
                onClick={this.hedgeDecision(hedgeStatus.cancelled)}
              >
                {HEDGES.DENY}
              </Button>
            </NotificationActionsPortal>
          </UIAuthorization>
          <UIAuthorization access={transferOuncesAccess(auth, hedge)}>
            <RouterButton
              id="transferOunces"
              color="danger"
              path={`/hedges/transfer-ounces/${hedge._id}`}
              label={HEDGES.ADMIN_TRANSFER_BLANKET_HEDGE_OUNCES}
            />
          </UIAuthorization>
          <UIAuthorization access={deleteHedgeAccess(auth, hedge)}>
            <Button id="deleteHedge" color="danger" onClick={this.deleteHedge}>
              {HEDGES.DELETE_HEDGE}
            </Button>
          </UIAuthorization>
        </ExtendableButton>
        <HedgesChangeType
          toggle={this.toggleChangeTypeModal}
          open={displayChangeTypeModal}
          hedge={hedge}
        />
        <HedgeReassign
          toggle={() => this.toggleReassignModal()}
          superAdminMode={superAdminMode}
          open={displayReassignModal}
          hedge={hedge}
        />
      </DetailsHeader>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    hedgesDetails: state.hedgesDetails,
  };
}

const mapDispatchToProps = {
  openModal,
  showSnackbar,
  deleteHedge,
  hedgesDecision,
  getHedgeDetails,
  cancelHedge,
};

const ConnectedHedgesDetails = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HedgesMainDetails),
);

export { ConnectedHedgesDetails as HedgesMainDetails, HedgesMainDetails as HedgesMainDetailsTests };
