import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const bankHolidayAvailabilityActionType = 'BANK_HOLIDAY_AVAILABILITY';

const bankHolidayAvailabilityAction = () => ({
  type: bankHolidayAvailabilityActionType,
});

const bankHolidayAvailabilitySuccessActionType = `${bankHolidayAvailabilityActionType}_SUCCESS`;
const bankHolidayAvailabilitySuccessAction = bankHolidays => ({
  type: bankHolidayAvailabilitySuccessActionType,
  payload: bankHolidays,
});

const bankHolidayAvailabilityFailureActionType = `${bankHolidayAvailabilityActionType}_FAILURE`;
const bankHolidayAvailabilityFailureAction = () => ({
  type: bankHolidayAvailabilityFailureActionType,
});

const bankHolidayAvailability = () => dispatch => {
  dispatch(bankHolidayAvailabilityAction());

  return axios
    .get(`${API_HOST_AUTH}/bank-holidays/check-today`)
    .then(({ data }) => {
      dispatch(bankHolidayAvailabilitySuccessAction(data));
      return data;
    })
    .catch(() => dispatch(bankHolidayAvailabilityFailureAction()));
};

export {
  bankHolidayAvailability,
  bankHolidayAvailabilityActionType,
  bankHolidayAvailabilitySuccessActionType,
  bankHolidayAvailabilityFailureActionType,
};
