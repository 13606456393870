import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';

import { cssVar, media } from 'shared/helpers/styling/styling';

import { Icon } from '../Icon/Icon';

const CollapsableDetailsFieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  grid-area: ${({ id }) => id};
`;

const CollapsableDetailsFieldsHead = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  background-color: ${({ isOpen }) => cssVar(isOpen ? 'shuttleGrayAssay' : 'riverBedAssay')};
  color: ${cssVar('alto')};
  font-weight: bold;
  font-size: 12px;
  cursor: pointer;

  @media print {
    padding: 0 15px;
  }

  ${Icon} {
    margin: 0 30px 0 45px;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${cssVar('limedSpruceDetails')};
      cursor: auto;
      pointer-events: none;
      color: rgba(207, 207, 207, 0.38);

      ${Icon} {
        background-color: rgba(207, 207, 207, 0.38);
      }
    `};
`;

const CollapsableDetailsFieldsContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  transition: all 0.3s ease;
  transform-origin: top;
  transform: scaleY(${({ isOpen }) => (isOpen ? 1 : 0)});
  max-height: ${({ isOpen }) => (isOpen ? '3000px' : 0)};
  @media print {
    transform: none;
    max-height: none;
  }
  background-color: ${cssVar('limedSpruceDetails')};
  width: 100%;
  & > * {
    width: ${({ cellWidth }) => `${cellWidth}%`};
    border-right: 1px solid rgba(255, 255, 255, 0.05);
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);

    ${media.mobile`width: 50%;`}
    ${media.phone`width: 100%;`}

    @media print {
      padding-bottom: 15px;
      width: 50%;
    }
  }
`;

const CollapsableDetailsFields = ({
  id,
  label,
  insideFields,
  cellWidth,
  disabled,
  children,
  showChildren,
  isOpenAtStart,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    isOpenAtStart && setIsOpen(true);
    disabled && setIsOpen(false);
  }, [disabled, isOpenAtStart]);

  const toggleDetails = () => setIsOpen(prevIsOpen => !prevIsOpen);
  return (
    <CollapsableDetailsFieldsWrapper id={id}>
      <CollapsableDetailsFieldsHead onClick={toggleDetails} isOpen={isOpen} disabled={disabled}>
        <Icon icon="icon-plus" width={16} height={isOpen || disabled ? 2 : 16} />
        {label}
      </CollapsableDetailsFieldsHead>
      <CollapsableDetailsFieldsContent isOpen={isOpen} cellWidth={cellWidth}>
        {showChildren && children}
        {insideFields &&
          insideFields.map(({ Component, ...rest }, index) => <Component key={index} {...rest} />)}
      </CollapsableDetailsFieldsContent>
    </CollapsableDetailsFieldsWrapper>
  );
};

CollapsableDetailsFields.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  insideFields: PropTypes.array,
  cellWidth: PropTypes.number,
  disabled: PropTypes.bool,
  children: PropTypes.node,
};

CollapsableDetailsFields.defaultProps = {
  cellWidth: 25,
  disabled: false,
  showChildren: false,
  isOpenAtStart: false,
};

export { CollapsableDetailsFields };
