import React from 'react';
import { reduxForm } from 'redux-form';

import { FieldInput } from 'shared/components/Fields';
import { Form, Section } from 'shared/components/Form';
import { MultipleFieldsSection } from 'shared/components/Form/MultipleFieldsSection/MultipleFieldsSection';
import { HEDGES } from 'shared/helpers/constants/hedges/hedgesConstants';
import { cancelModal } from 'shared/helpers/constants/modalConstants';
import { validate } from 'shared/helpers/validations/defaultPPMs/defaultPPMsValidation';

import { goBackOrTo } from 'utils/history';

const DefaultPPMsFormBase = ({ goBackURL = '/hedges', fieldsRequired = true, ...props }) => {
  const modalOpen = () => {
    props.openModal(cancelModal, () => {
      goBackOrTo(goBackURL);
    });
  };

  return (
    <Form
      header={HEDGES.DEFAULT_PPMS.FORM_TITLE}
      onSubmit={props.handleSubmit}
      onCancel={modalOpen}
      submitDisabled={props.invalid}
    >
      <Section
        header={HEDGES.DEFAULT_PPMS.MONO_CANNED_DECANNED}
        template={['monoPPMs monoPPMs']}
        noPadding
      >
        <MultipleFieldsSection
          name="monoPPMs"
          fields={[
            {
              component: FieldInput,
              name: 'monoPlatinumAssay',
              type: 'number',
              label: HEDGES.DEFAULT_PPMS.PLATINUM_ASSAY,
              suffix: HEDGES.DEFAULT_PPMS.PPM,
              field: fieldsRequired && 'required',
              onChange: () => props.touch('monoPalladiumAssay', 'monoRhodiumAssay'),
            },
            {
              component: FieldInput,
              name: 'monoPalladiumAssay',
              type: 'number',
              label: HEDGES.DEFAULT_PPMS.PALLADIUM_ASSAY,
              suffix: HEDGES.DEFAULT_PPMS.PPM,
              field: fieldsRequired && 'required',
              onChange: () => props.touch('monoPlatinumAssay', 'monoRhodiumAssay'),
            },
            {
              component: FieldInput,
              name: 'monoRhodiumAssay',
              type: 'number',
              label: HEDGES.DEFAULT_PPMS.RHODIUM_ASSAY,
              suffix: HEDGES.DEFAULT_PPMS.PPM,
              field: fieldsRequired && 'required',
              onChange: () => props.touch('monoPlatinumAssay', 'monoPalladiumAssay'),
            },
          ]}
        />
      </Section>
      <Section header={HEDGES.DEFAULT_PPMS.FOIL} template={['foilPPMs foilPPMs']} noPadding>
        <MultipleFieldsSection
          name="foilPPMs"
          fields={[
            {
              name: 'foilCannedPlatinumAssay',
              component: FieldInput,
              type: 'number',
              label: HEDGES.DEFAULT_PPMS.PLATINUM_ASSAY,
              suffix: HEDGES.DEFAULT_PPMS.PPM,
              field: fieldsRequired && 'required',
              onChange: () => props.touch('foilCannedPalladiumAssay', 'foilCannedRhodiumAssay'),
            },
            {
              name: 'foilCannedPalladiumAssay',
              component: FieldInput,
              type: 'number',
              label: HEDGES.DEFAULT_PPMS.PALLADIUM_ASSAY,
              suffix: HEDGES.DEFAULT_PPMS.PPM,
              field: fieldsRequired && 'required',
              onChange: () => props.touch('foilCannedPlatinumAssay', 'foilCannedRhodiumAssay'),
            },
            {
              name: 'foilCannedRhodiumAssay',
              component: FieldInput,
              type: 'number',
              label: HEDGES.DEFAULT_PPMS.RHODIUM_ASSAY,
              suffix: HEDGES.DEFAULT_PPMS.PPM,
              field: fieldsRequired && 'required',
              onChange: () => props.touch('foilCannedPlatinumAssay', 'foilCannedPalladiumAssay'),
            },
          ]}
        />
      </Section>
      <Section
        header={HEDGES.DEFAULT_PPMS.SENSORS}
        template={['sensorsPPMs sensorsPPMs']}
        noPadding
      >
        <MultipleFieldsSection
          name="sensorsPPMs"
          fields={[
            {
              name: 'sensorsPlatinumAssay',
              component: FieldInput,
              type: 'number',
              label: HEDGES.DEFAULT_PPMS.PLATINUM_ASSAY,
              suffix: HEDGES.DEFAULT_PPMS.PPM,
              field: fieldsRequired && 'required',
              onChange: () => props.touch('sensorsPalladiumAssay', 'sensorsRhodiumAssay'),
            },
            {
              name: 'sensorsPalladiumAssay',
              component: FieldInput,
              type: 'number',
              label: HEDGES.DEFAULT_PPMS.PALLADIUM_ASSAY,
              suffix: HEDGES.DEFAULT_PPMS.PPM,
              field: fieldsRequired && 'required',
              onChange: () => props.touch('sensorsPlatinumAssay', 'sensorsRhodiumAssay'),
            },
            {
              name: 'sensorsRhodiumAssay',
              component: FieldInput,
              type: 'number',
              label: HEDGES.DEFAULT_PPMS.RHODIUM_ASSAY,
              suffix: HEDGES.DEFAULT_PPMS.PPM,
              field: fieldsRequired && 'required',
              onChange: () => props.touch('sensorsPlatinumAssay', 'sensorsPalladiumAssay'),
            },
          ]}
        />
      </Section>
    </Form>
  );
};

const DefaultPPMsForm = reduxForm({
  form: 'DefaultPPMsForm',
  validate,
})(DefaultPPMsFormBase);

export { DefaultPPMsForm, DefaultPPMsFormBase };
