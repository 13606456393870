import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';

import { clearFilterLotsForReassign } from 'actions/Hedges/hedgesManagement';

import { SubmitButton } from 'shared/components/Buttons/SubmitButton/SubmitButton';
import { FieldSearch } from 'shared/components/Fields';
import { Spinner } from 'shared/components/Spinner/Spinner';
import { HEDGES } from 'shared/helpers/constants/hedges/hedgesConstants';
import { message } from 'shared/helpers/constants/hedges/validateHedgesConstants';
import { statusesForGlobal } from 'shared/helpers/constants/lots/statusesForGlobal';
import { PLACEHOLDERS } from 'shared/helpers/constants/placeholders';
import { SHARED } from 'shared/helpers/constants/sharedConstants';

import { StyledActions, StyledForm } from './StyledHedgeReassignForm';
import { WarningMessage } from './components/WarningMessage';

const formName = 'HedgeReassignForm';
const selector = formValueSelector(formName);
const lotStatusesForWarning = [
  statusesForGlobal.invoice_generated,
  statusesForGlobal.invoice_accepted,
  statusesForGlobal.finalized,
];

const validate = values => (!values.relatedLot ? { relatedLot: message.RELATED_LOT_EMPTY } : {});

const HedgeReassignFormBase = ({
  handleSubmit,
  filterLotsForReassign,
  invalid,
  hedge,
  submitting,
  superAdminMode,
}) => {
  const dispatch = useDispatch();
  const [defaultOptions, setDefaultOptions] = useState([]);

  const [selectedLotId, filteredLots] = useSelector(state => [
    selector(state, 'relatedLot'),
    state.hedgesManagement.filteredLots,
  ]);

  useEffect(() => {
    filterLotsForReassign({ hedge, superAdminMode }).then(({ options }) => {
      setDefaultOptions(options);
    });
  }, [hedge._id, superAdminMode]);

  const selectedLotStatus = filteredLots?.find(
    ({ _id, statusForGlobal }) =>
      _id === selectedLotId?.value && lotStatusesForWarning.includes(statusForGlobal),
  )?.statusForGlobal;

  const handleGetOptions = filter => filterLotsForReassign({ filter, hedge, superAdminMode });

  useEffect(() => {
    return () => dispatch(clearFilterLotsForReassign());
  }, []);

  return (
    <>
      <header>
        <h3>{`${HEDGES.REASSIGN_HEDGE} ${hedge.hedgeName}`}</h3>
      </header>
      <StyledForm noValidate onSubmit={handleSubmit}>
        <Field
          name="relatedLot"
          component={FieldSearch}
          label={HEDGES.RELATED_LOT}
          field="required"
          placeholder={PLACEHOLDERS.SEARCH}
          getOptions={handleGetOptions}
          defaultOptions={defaultOptions}
          cacheOptions
          maxMenuHeight={235}
        />
        <StyledActions displayWarning={!!selectedLotStatus && superAdminMode}>
          {selectedLotStatus && (
            <WarningMessage superAdminMode={superAdminMode} selectedLotStatus={selectedLotStatus} />
          )}
          <Spinner loading={submitting} />
          <SubmitButton id="markAsPaid" label={SHARED.SUBMIT} disabled={invalid || submitting} />
        </StyledActions>
      </StyledForm>
    </>
  );
};

HedgeReassignFormBase.propTypes = {
  handleSubmit: PropTypes.func,
  filterLotsForReassign: PropTypes.func,
  invalid: PropTypes.bool,
  hedge: PropTypes.object,
  submitting: PropTypes.bool,
  superAdminMode: PropTypes.bool,
};

const HedgeReassignForm = reduxForm({
  form: formName,
  validate,
})(HedgeReassignFormBase);

export { HedgeReassignForm, HedgeReassignFormBase };
