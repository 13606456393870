const UPDATE_MARKET_INDICATORS = {
  MARKET_INDICATORS: 'Market indicators',
  UPDATE_MARKET_INDICATORS: 'Update market indicators',
  TYPE: 'Type',
  HEDGING: 'Hedging',
  SETTLEMENT: 'Settlement',
  PLATINUM: 'Platinum',
  PALLADIUM: 'Palladium',
  RHODIUM: 'Rhodium',
  HEDGING_PLATINUM_PRICE_EMPTY: 'Hedging platinum price cannot be empty.',
  HEDGING_PLATINUM_PRICE_NOT_POSITIVE: 'Hedging platinum price must be greater that 0.',
  HEDGING_PLATINUM_PRICE_FORMAT: 'Hedging platinum price has invalid format.',
  HEDGING_PLATINUM_PRICE_DO_NOT_MATCH: 'Hedging platinum prices do not match.',
  HEDGING_PALLADIUM_PRICE_EMPTY: 'Hedging palladium price cannot be empty.',
  HEDGING_PALLADIUM_PRICE_NOT_POSITIVE: 'Hedging palladium price must be greater that 0.',
  HEDGING_PALLADIUM_PRICE_FORMAT: 'Hedging palladium price has invalid format.',
  HEDGING_PALLADIUM_PRICE_DO_NOT_MATCH: 'Hedging palladium prices do not match.',
  HEDGING_RHODIUM_PRICE_EMPTY: 'Hedging rhodium price cannot be empty.',
  HEDGING_RHODIUM_PRICE_NOT_POSITIVE: 'Hedging rhodium price must be greater that 0.',
  HEDGING_RHODIUM_PRICE_FORMAT: 'Hedging rhodium price has invalid format.',
  HEDGING_RHODIUM_PRICE_DO_NOT_MATCH: 'Hedging rhodium prices do not match.',
  SETTLEMENT_PLATINUM_PRICE_EMPTY: 'Settlement platinum price cannot be empty.',
  SETTLEMENT_PLATINUM_PRICE_NOT_POSITIVE: 'Settlement platinum price must be greater that 0.',
  SETTLEMENT_PLATINUM_PRICE_FORMAT: 'Settlement platinum price has invalid format.',
  SETTLEMENT_PLATINUM_PRICE_DO_NOT_MATCH: 'Settlement platinum prices do not match.',
  SETTLEMENT_PALLADIUM_PRICE_EMPTY: 'Settlement palladium price cannot be empty.',
  SETTLEMENT_PALLADIUM_PRICE_NOT_POSITIVE: 'Settlement palladium price must be greater that 0.',
  SETTLEMENT_PALLADIUM_PRICE_FORMAT: 'Settlement palladium price has invalid format.',
  SETTLEMENT_PALLADIUM_PRICE_DO_NOT_MATCH: 'Settlement palladium prices do not match.',
  SETTLEMENT_RHODIUM_PRICE_EMPTY: 'Settlement rhodium price cannot be empty.',
  SETTLEMENT_RHODIUM_PRICE_NOT_POSITIVE: 'Settlement rhodium price must be greater that 0.',
  SETTLEMENT_RHODIUM_PRICE_FORMAT: 'Settlement rhodium price has invalid format.',
  SETTLEMENT_RHODIUM_PRICE_DO_NOT_MATCH: 'Settlement rhodium prices do not match.',
};

export { UPDATE_MARKET_INDICATORS };
