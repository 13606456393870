import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const requestMarketIndicatorsType = 'REQUEST_MARKET_INDICATORS';
const requestMarketIndicatorsAction = () => ({
  type: requestMarketIndicatorsType,
});

const requestMarketIndicatorsSuccessType = `${requestMarketIndicatorsType}_SUCCESS`;
const requestMarketIndicatorsSuccessAction = () => ({
  type: requestMarketIndicatorsSuccessType,
});

const requestMarketIndicatorsFailureType = `${requestMarketIndicatorsType}_FAILURE`;
const requestMarketIndicatorsFailureAction = () => ({
  type: requestMarketIndicatorsFailureType,
});

const requestMarketIndicators = type => async dispatch => {
  dispatch(requestMarketIndicatorsAction());

  try {
    const res = await axios.post(`${API_HOST_AUTH}/market-indicators/request`, {
      type,
    });
    dispatch(requestMarketIndicatorsSuccessAction());

    return res.data;
  } catch (error) {
    dispatch(requestMarketIndicatorsFailureAction());

    return Promise.reject(error.data);
  }
};

export {
  requestMarketIndicatorsType,
  requestMarketIndicatorsSuccessType,
  requestMarketIndicatorsFailureType,
  requestMarketIndicators,
};
