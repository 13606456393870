import { hedgeStatus } from 'shared/helpers/constants/hedges/hedgeStatus';
import { hedgeTypes } from 'shared/helpers/constants/hedges/hedgeType';

const reassignHedgeAccess = (
  {
    access: {
      hedges: { update },
    },
  },
  hedge,
) => update && hedge.status === hedgeStatus.placed && hedge.hedgeType !== hedgeTypes.blanket;

export { reassignHedgeAccess };
