import DatePicker from 'react-datepicker';
import styled from 'styled-components';

import { cssVar } from 'shared/helpers/styling/styling';

export const DatePickerStyled = styled(DatePicker)`
  height: 50px;
  width: 100%;
  padding: 0 25px;
  font-size: 0.875rem;
  color: ${cssVar('alabaster')};
  background-color: ${cssVar('shuttleGray')};
  border-color: ${cssVar('shuttleGray')};
  border-radius: 5px;
  border: none;
  transition: 100ms ease-in;

  :hover {
    background-color: ${cssVar('nevada')};
  }

  :focus {
    outline: none;
  }

  :disabled {
    background-color: ${cssVar('limedSpruceDarker')};
  }

  ::placeholder {
    color: ${cssVar('alto')};
    opacity: 0.56;
    font-size: 0.75rem;
  }
`;
