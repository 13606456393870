import {
  updateVirtualLotActionType,
  updateVirtualLotSuccessActionType,
  updateVirtualLotFailureActionType,
  updateVirtualLotWeightActionType,
  clearUpdateVirtualLotActionType,
} from 'actions/Lots/updateVirtualLot';

const INITIAL_STATE = {
  isPending: false,
  updateVirtualLotData: null,
};

const updateVirtualLotReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case updateVirtualLotActionType:
      return {
        ...state,
        isPending: true,
      };
    case updateVirtualLotSuccessActionType:
    case updateVirtualLotFailureActionType:
      return {
        isPending: false,
        updateVirtualLotData: null,
      };
    case updateVirtualLotWeightActionType:
      return {
        ...state,
        updateVirtualLotData: action.payload,
      };
    case clearUpdateVirtualLotActionType: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
};

export { updateVirtualLotReducer };
