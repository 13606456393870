import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const requestPasswordResetActionType = 'REQUEST_PASSWORD_RESET';
const requestPasswordResetAction = () => ({
  type: requestPasswordResetActionType,
});

const requestPasswordResetSuccessActionType = `${requestPasswordResetActionType}_SUCCESS`;
const requestPasswordResetSuccessAction = () => ({
  type: requestPasswordResetSuccessActionType,
});

const requestPasswordResetFailureActionType = `${requestPasswordResetActionType}_FAILURE`;
const requestPasswordResetFailureAction = () => ({
  type: requestPasswordResetFailureActionType,
});

const requestPasswordReset = email => dispatch => {
  dispatch(requestPasswordResetAction());

  return axios
    .post(`${API_HOST_AUTH}/users/password-reset`, { email })
    .then(() => dispatch(requestPasswordResetSuccessAction()))
    .catch(() => dispatch(requestPasswordResetFailureAction()));
};

export {
  requestPasswordResetActionType,
  requestPasswordResetSuccessActionType,
  requestPasswordResetFailureActionType,
  requestPasswordReset,
};
