import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getCompaniesForFiltersActionType = 'GET_COMPANIES_FOR_FILTERS';
const getCompaniesForFiltersAction = () => ({
  type: getCompaniesForFiltersActionType,
});

const getCompaniesForFiltersSuccessActionType = `${getCompaniesForFiltersActionType}_SUCCESS`;
const getCompaniesForFiltersSuccessAction = response => ({
  type: getCompaniesForFiltersSuccessActionType,
  payload: {
    response,
  },
});

const getCompaniesForFiltersFailureActionType = `${getCompaniesForFiltersActionType}_FAILURE`;
const getCompaniesForFiltersFailureAction = errorMessage => ({
  type: getCompaniesForFiltersFailureActionType,
  payload: {
    errorMessage,
  },
});

const getCompaniesForFiltersFactory = url => dispatch => {
  dispatch(getCompaniesForFiltersAction());

  return axios
    .get(`${API_HOST_AUTH}/companies/related/${url}`)
    .then(res => dispatch(getCompaniesForFiltersSuccessAction(res.data)))
    .catch(err => dispatch(getCompaniesForFiltersFailureAction(err)));
};

const getIncomingShipmentsCompanies = () => getCompaniesForFiltersFactory('incoming-shipment');

const getSelfDeliveryRequestsCompanies = () =>
  getCompaniesForFiltersFactory('self-delivery-requests');

const getPoolAccountJournalCompanies = () => getCompaniesForFiltersFactory('pool-account-journal');

export {
  getCompaniesForFiltersActionType,
  getCompaniesForFiltersSuccessActionType,
  getCompaniesForFiltersFailureActionType,
  getIncomingShipmentsCompanies,
  getSelfDeliveryRequestsCompanies,
  getPoolAccountJournalCompanies,
};
