import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  changeLotsInProcessingOverview,
  getLotsForProcessingOverview,
  clearProcessingOverviewAction,
} from 'actions/Lots/processingOverview';
import { clearAllPackagesOverview } from 'actions/Packages/packagesOverviewList';

import { Button } from 'shared/components/Buttons/Button';
import { DraggableSection } from 'shared/components/DraggableSection/DraggableSection';
import { Icon } from 'shared/components/Icon/Icon';
import { OverviewHeader } from 'shared/components/OverviewHeader/OverviewHeader';
import { ScrollToTop } from 'shared/components/ScrollToTop/ScrollToTop';
import {
  viewAcceptLotForProcessingPageAccess,
  markProcessingCompleteAccess,
} from 'shared/helpers/accesses/lots';
import { releaseIncomingPackagesAccess } from 'shared/helpers/accesses/lots/releaseIncomingPackagesAccess';
import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';
import {
  overviewItemTemplate,
  processingActionsTemplate,
} from 'shared/helpers/constants/lots/overviewTableConfig';
import { statusesForGlobal } from 'shared/helpers/constants/lots/statusesForGlobal';
import { packageClasses } from 'shared/helpers/constants/packages/packageClasses';
import { packageStatuses } from 'shared/helpers/constants/packages/packageType';
import { isTablet } from 'shared/helpers/constants/resolutionsConstants';
import { SHARED } from 'shared/helpers/constants/sharedConstants';
import { generateProcessingSectionHeader } from 'shared/helpers/dataParsers/lot/lotOverviewData';
import { capitalize } from 'shared/helpers/parsers/text';
import { cssVar } from 'shared/helpers/styling/styling';

import { goBackOrTo, history } from 'utils/history';
import { getBrowserWidth } from 'utils/responsive';

import { ReleaseBarcodeScanner } from '../ReleaseBarcodeScanner/ReleaseBarcodeScanner';
import { StyledLoadingSpinner } from 'assets/styles/common/StyledLoadingSpinner';

const isLotProcessed = lot => lot.statusForGlobal === statusesForGlobal.accepted_for_processing;

class ProcessingOverviewBase extends Component {
  isDragging = false;

  state = {
    showReleaseBarcodeScanner: false,
    isDropSectionDisabled: false,
    windowWidth: getBrowserWidth(),
  };

  componentDidMount() {
    this.props.clearProcessingOverviewAction();

    this.getLotsForProcessing();
    this.intervalId = setInterval(() => {
      const {
        processingOverview: { isPending },
      } = this.props;

      if (!(isPending || this.isDragging)) {
        this.getLotsForProcessing();
      }
    }, 5000);
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
    this.props.clearAllPackagesOverview();
    this.props.clearProcessingOverviewAction();
  }

  onSelectedChange = (difference, destination) => {
    this.isDragging = false;

    this.props
      .changeLotsInProcessingOverview(difference, destination)
      .then(this.props.getLotsForProcessingOverview)
      .catch(this.getLotsForProcessing);
  };

  onDragStart = ({ draggableId, source: { droppableId } }) => {
    const {
      auth,
      processingOverview: { lots },
    } = this.props;

    const lot = lots[droppableId].find(lot => lot._id === draggableId);

    const markProcessingComplete =
      markProcessingCompleteAccess(auth, lot, true) &&
      lot.packages.filter(p => p.packageClass === packageClasses.incoming && !p.releasedAt)
        .length === 0;

    releaseIncomingPackagesAccess(auth, lot) || markProcessingComplete
      ? this.setState({ isDropSectionDisabled: true })
      : this.setState({ isDropSectionDisabled: false });

    this.isDragging = true;
  };

  get sections() {
    const {
      processingOverview: { lots },
    } = this.props;
    const { windowWidth } = this.state;

    return Object.entries(lots || {}).reduce(
      (acc, [name, data]) => ({
        ...acc,
        [name]: {
          data,
          id: name,
          placeholder: SHARED.DROPPABLE_PLACEHOLDER(capitalize(name)),
          header: generateProcessingSectionHeader(name, data.length),
          sort: this.getSortFunction(name),
          expandable: name !== 'available' && windowWidth < isTablet,
          icon: name !== 'available' ? 'icon-cart' : null,
        },
      }),
      {},
    );
  }

  getLotsForProcessing = () => {
    const { lots, isPending } = this.props;

    return (
      (isPending || !lots) &&
      this.props.getLotsForProcessingOverview().catch(() => goBackOrTo('/lots'))
    );
  };

  getSortFunction(name) {
    switch (name) {
      case 'available':
        return (a, b) => 2 * (a.receivedAt > b.receivedAt) - 1;
      case 'Shears':
        return a => {
          if (a.packages.map(pkg => Number.isFinite(pkg.releasedAt)).includes(false)) return 1;
          return 0;
        };
      default:
        return (lotA, lotB) => -(isLotProcessed(lotA) && !isLotProcessed(lotB));
    }
  }

  toggleReleaseBarcodeScanner = releasedLot => () =>
    this.setState(({ showReleaseBarcodeScanner }) => ({
      showReleaseBarcodeScanner: !showReleaseBarcodeScanner,
      releasedLot,
    }));

  handleWindowSizeChange = () => this.setState({ windowWidth: getBrowserWidth() });

  getDraggableProps = (item, sectionId, index) => ({
    highlighted: isLotProcessed(item),
    icon: isLotProcessed(item) ? 'icon-process' : 'icon-drag',
    dragDisabled: this.isDragDisabled(item),
    expanded: sectionId !== 'available' && (index === 0 || sectionId === 'Shears'),
  });

  isDragDisabled = lot => {
    const {
      processingOverview: { isPending },
      auth,
    } = this.props;

    return auth.access.lots.processingOverview.modifyAccepted
      ? isPending ||
          (markProcessingCompleteAccess(auth, lot, true) &&
            lot.packages.some(
              p =>
                p.packageClass === packageClasses.post_mill ||
                p.packageClass === packageClasses.post_shears,
            ))
      : !auth.access.lots.processingOverview.modify || isPending || isLotProcessed(lot);
  };

  goToProcessingCompleteView = lot => () => history.push(`/lots/processing/complete/${lot._id}`);

  goToAccepptForProcessingView = lot => () => history.push(`/lots/processing/accept/${lot._id}`);

  render() {
    const {
      auth,
      processingOverview: { isPending },
    } = this.props;
    const { windowWidth, showReleaseBarcodeScanner, releasedLot, isDropSectionDisabled } =
      this.state;
    const expandedProcessingActionsTemplate = [
      {
        access: lot =>
          viewAcceptLotForProcessingPageAccess(
            auth,
            { ...lot, canBeProcessed: true },
            { incoming: { docs: lot.packages.filter(p => p.packageClass === 'incoming') } },
            true,
          ),
        render: (lot, key) => (
          <Button onClick={this.goToAccepptForProcessingView(lot)} key={key}>
            {LOTS.ACCEPT_FOR_PROCESSING}
            <Icon icon="icon-process" width={20} height={20} size="contain" />
          </Button>
        ),
      },
      {
        access: lot => releaseIncomingPackagesAccess(auth, lot),
        render: (lot, key) => (
          <Button
            key={key}
            outlineColor={cssVar('roti')}
            onClick={this.toggleReleaseBarcodeScanner(lot)}
          >
            {LOTS.RELEASE_PACKAGE}
            <Icon icon="icon-tick" width={20} height={20} size="contain" />
          </Button>
        ),
      },
      {
        access: lot =>
          markProcessingCompleteAccess(auth, lot, true) &&
          lot.packages.filter(
            p =>
              p.packageClass === packageClasses.incoming &&
              !p.releasedAt &&
              p.status !== packageStatuses.inactive,
          ).length === 0,
        render: (lot, key) => (
          <Button
            onClick={this.goToProcessingCompleteView(lot)}
            outlineColor={cssVar('shipCove')}
            key={key}
          >
            {LOTS.PROCESSING_COMPLETE}
            <Icon icon="icon-tick" width={20} height={20} size="contain" />
          </Button>
        ),
      },
    ];

    return (
      <ScrollToTop>
        <OverviewHeader>
          {LOTS.PROCESSING_OVERVIEW}
          {isPending && <StyledLoadingSpinner /> }
        </OverviewHeader>
        <DraggableSection
          sections={this.sections}
          itemTemplate={overviewItemTemplate}
          expandedActionsTemplate={expandedProcessingActionsTemplate}
          actionsTemplate={processingActionsTemplate}
          getDraggableProps={this.getDraggableProps}
          disabled={this.isDragDisabled}
          onSelectedChange={this.onSelectedChange}
          onDragStart={this.onDragStart}
          windowWidth={windowWidth}
          isDropSectionDisabled={isDropSectionDisabled}
          outlined
        />
        <ReleaseBarcodeScanner
          open={showReleaseBarcodeScanner}
          onSelfClose={this.toggleReleaseBarcodeScanner()}
          lot={releasedLot}
        />
      </ScrollToTop>
    );
  }
}

ProcessingOverviewBase.propTypes = {
  auth: PropTypes.shape({
    access: PropTypes.object,
    attempts: PropTypes.number,
    isPending: PropTypes.bool,
    user: PropTypes.object,
  }),
  changeLotsInProcessingOverview: PropTypes.func,
  clearAllPackagesOverview: PropTypes.func,
  clearProcessingOverviewAction: PropTypes.func,
  getLotsForProcessingOverview: PropTypes.func,
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
  processingOverview: PropTypes.shape({
    isPending: PropTypes.bool,
    lots: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.shape({
        M1: PropTypes.array,
        M2: PropTypes.array,
        available: PropTypes.array,
        Shears: PropTypes.array,
      }),
    ]),
  }),
};

const mapStateToProps = state => ({
  processingOverview: state.processingOverview,
  auth: state.auth,
});

const mapDispatchToProps = {
  getLotsForProcessingOverview,
  changeLotsInProcessingOverview,
  clearAllPackagesOverview,
  clearProcessingOverviewAction,
};

const ProcessingOverview = connect(mapStateToProps, mapDispatchToProps)(ProcessingOverviewBase);

export { ProcessingOverviewBase, ProcessingOverview };
