import { formatDate, formatToUS } from 'shared/helpers/parsers/formaters';
import { humanize, replaceEmpty } from 'shared/helpers/parsers/text';

import { history } from 'utils/history';

import { LOTS } from './lotsConstants';

const materialDeliveryRequiredTableTemplate = [
  {
    label: LOTS.SHORTENED_GRG_LOT_NUMBER,
    render: ({ customLotNumber, grgLotNumber }) => customLotNumber || grgLotNumber,
  },
  {
    label: LOTS.PROCESS_UPON_DELIVERY,
    render: ({ processUponDelivery }) => (processUponDelivery ? 'Yes' : 'No'),
  },
  {
    label: LOTS.SHORTENED_SENT_FROM_COMPANY_LOCATION,
    render: ({ sentFromCompanyLocation }) => humanize(sentFromCompanyLocation.name),
  },
  {
    label: `${LOTS.SHORTENED_WEIGHT_GROSS_DECLARED} (${LOTS.LBS})`,
    render: ({ weightGrossDeclared }) => replaceEmpty(formatToUS(weightGrossDeclared)),
  },
  {
    label: LOTS.HEDGED_STATUS,
    render: ({ hedgedStatus }) => humanize(hedgedStatus),
  },
  {
    label: LOTS.RECEIVED_ON,
    render: ({ receivedAt }) => replaceEmpty(formatDate(receivedAt)),
  },
  {
    label: LOTS.SHORTENED_HEDGED_LOT_DELIVERY_DEADLINE,
    render: ({ hedgedLotDeliveryDeadline }) => formatDate(hedgedLotDeliveryDeadline),
  },
];

const materialDeliveryRequiredMobileTemplate = [
  {
    label: LOTS.SHORTENED_GRG_LOT_NUMBER,
    render: ({ customLotNumber, grgLotNumber }) => customLotNumber || grgLotNumber,
  },
  {
    label: LOTS.SHORTENED_SENT_FROM_COMPANY_LOCATION,
    render: ({ sentFromCompanyLocation }) => humanize(sentFromCompanyLocation.name),
  },
  {
    label: LOTS.RECEIVED_ON,
    render: ({ receivedAt }) => replaceEmpty(formatDate(receivedAt)),
  },
];

const materialDeliveryRequiredActionsTemplate = [
  {
    icon: 'arrow-right',
    action: 'Details',
    onClick:
      ({ _id }) =>
      () =>
        history.push(`${history.location.pathname}/list/${_id}`),
  },
];

const customerLotItemTableTemplate = [
  {
    label: LOTS.SHORTENED_GRG_LOT_NUMBER,
    render: ({ customLotNumber, grgLotNumber }) => customLotNumber || grgLotNumber,
  },
  {
    label: LOTS.PROCESS_UPON_DELIVERY,
    render: ({ processUponDelivery }) => (processUponDelivery ? 'Yes' : 'No'),
  },
  {
    label: LOTS.SHORTENED_SENT_FROM_COMPANY_LOCATION,
    render: ({ sentFromCompanyLocation }) => humanize(sentFromCompanyLocation.name),
  },
  {
    label: `${LOTS.SHORTENED_WEIGHT_GROSS_DECLARED} (${LOTS.LBS})`,
    render: ({ weightGrossDeclared }) => replaceEmpty(formatToUS(weightGrossDeclared)),
  },
  {
    label: LOTS.HEDGED_STATUS,
    render: ({ hedgedStatus }) => humanize(hedgedStatus),
  },
  {
    label: LOTS.RECEIVED_ON,
    render: ({ receivedAt }) => replaceEmpty(formatDate(receivedAt)),
  },
];

const customerLotItemDetailsTableTemplate = [
  {
    label: LOTS.PROCESS_UPON_DELIVERY,
    render: ({ processUponDelivery }) => (processUponDelivery ? 'Yes' : 'No'),
  },
  {
    label: `${LOTS.SHORTENED_WEIGHT_GROSS_DECLARED} (${LOTS.LBS})`,
    render: ({ weightGrossDeclared }) => replaceEmpty(formatToUS(weightGrossDeclared)),
  },
  {
    label: LOTS.HEDGED_STATUS,
    render: ({ hedgedStatus }) => humanize(hedgedStatus),
  },
];

const materialDeliveryRequiredDetailsTableTemplate = [
  ...customerLotItemDetailsTableTemplate,
  {
    label: LOTS.SHORTENED_HEDGED_LOT_DELIVERY_DEADLINE,
    render: ({ hedgedLotDeliveryDeadline }) => formatDate(hedgedLotDeliveryDeadline),
  },
];

const gradingLotItemTableTemplate = [
  {
    label: LOTS.SHORTENED_GRG_LOT_NUMBER,
    render: ({ customLotNumber, grgLotNumber }) => customLotNumber || grgLotNumber,
  },
  {
    label: LOTS.SHORTENED_SENT_FROM_COMPANY_LOCATION,
    render: ({ sentFromCompanyLocation }) => humanize(sentFromCompanyLocation.name),
  },
  {
    label: `${LOTS.SHORTENED_WEIGHT_GROSS_DECLARED} (${LOTS.LBS})`,
    render: ({ weightGrossDeclared }) => replaceEmpty(formatToUS(weightGrossDeclared)),
  },
  {
    label: LOTS.RECEIVED_ON,
    render: ({ receivedAt }) => replaceEmpty(formatDate(receivedAt)),
  },
];

const lotItemMobileTemplate = [
  {
    label: LOTS.SHORTENED_GRG_LOT_NUMBER,
    render: ({ customLotNumber, grgLotNumber }) => customLotNumber || grgLotNumber,
  },
  {
    label: LOTS.SHORTENED_SENT_FROM_COMPANY_LOCATION,
    render: ({ sentFromCompanyLocation }) => humanize(sentFromCompanyLocation.name),
  },
  {
    label: LOTS.RECEIVED_ON,
    render: ({ receivedAt }) => replaceEmpty(formatDate(receivedAt)),
  },
];

const lotItemActionsTemplate = [
  {
    icon: 'arrow-right',
    action: 'Details',
    onClick:
      ({ _id }) =>
      () =>
        history.push(`${history.location.pathname}/list/${_id}`),
  },
];

export {
  materialDeliveryRequiredActionsTemplate,
  materialDeliveryRequiredMobileTemplate,
  materialDeliveryRequiredTableTemplate,
  materialDeliveryRequiredDetailsTableTemplate,
  lotItemActionsTemplate,
  lotItemMobileTemplate,
  customerLotItemTableTemplate,
  customerLotItemDetailsTableTemplate,
  gradingLotItemTableTemplate,
};
