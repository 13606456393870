import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getRegionListActionType = 'GET_REGION_LIST';
function getRegionListAction() {
  return {
    type: getRegionListActionType,
  };
}

const getRegionListSuccessActionType = 'GET_REGION_LIST_SUCCESS';
function getRegionListSuccessAction(response) {
  return {
    type: getRegionListSuccessActionType,
    payload: {
      response,
    },
  };
}

const getRegionListFailureActionType = 'GET_REGION_LIST_FAILURE';
function getRegionListFailureAction() {
  return {
    type: getRegionListFailureActionType,
  };
}

function getRegionList() {
  return dispatch => {
    dispatch(getRegionListAction());

    return axios({
      method: 'get',
      url: `${API_HOST_AUTH}/locations/region/list`,
    })
      .then(response => {
        dispatch(getRegionListSuccessAction(response.data));
      })
      .catch(() => {
        dispatch(getRegionListFailureAction());
      });
  };
}

export {
  getRegionListActionType,
  getRegionListSuccessActionType,
  getRegionListFailureActionType,
  getRegionList,
};
