import styled, { css } from 'styled-components';

import { PackageSection } from 'shared/components/Form/PackgeSection/PackageSection';
import { cssVar, media } from 'shared/helpers/styling/styling';

import { FormRowCell } from 'pages/Packages/components/PackagesManagement/components/MultiPackagesCreate/components/IncomingPackagesFormList/components/IncomingPackageListTemplate/components/FormRowCell';
import { MultiPackagesFormHeader } from 'pages/Packages/components/PackagesManagement/components/MultiPackagesCreate/components/IncomingPackagesFormList/components/MultiPackagesFormHeader/MultiPackagesFormHeader';

export const StyledPackageSection = styled(PackageSection)`
  ${media.smallDesktop`
        width: 100%;
    `}
`;

export const StyledMultiPackagesFormHeader = styled(MultiPackagesFormHeader)`
  ${media.smallDesktop`
        width: 100%;
    `}
`;

export const StyledFormRowCell = styled(FormRowCell)`
  & > div {
    height: 45px;
  }
`;

export const ProgressCounterDictionary = {
  filled: 'green',
  empty: 'grey',
  touched: 'orange',
};

export const ProgressCounter = styled.span`
  user-select: none;
  border-radius: 5px;
  padding: 2px 15px;
  font-size: 0.9rem;
  margin-left: 20px;
  color: ${cssVar('lightGrayBitLighter')};
  ${({ status }) =>
    status &&
    css`
      background: ${ProgressCounterDictionary[status]};
    `};
`;
