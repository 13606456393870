import React, { useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';

import { changeDeliveryDate } from 'actions/Lots/changeDeliveryDate';
import { getLot } from 'actions/Lots/lotDetails';
import { showSnackbar } from 'actions/shared/snackbar';

import { FormContainer } from 'shared/components/Form';
import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';
import {
  dangerOptions,
  successOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { dateToTimestamp } from 'shared/helpers/parsers/date';

import { ChangeReceivedDateForm } from './components/ChangeReceivedDateForm/ChangeReceivedDateForm';

function ChangeReceivedDateBase({
  match: {
    params: { id },
  },
  history,
}) {
  const dispatch = useDispatch();
  const goBack = useCallback(() => history.push(`/lots/list/${id}`), [history, id]);

  useEffect(() => {
    dispatch(getLot(id)).catch(goBack);
  }, [dispatch, id, goBack]);

  const onSubmit = useCallback(
    async ({ receivedAt }) => {
      try {
        const { message } = await dispatch(
          changeDeliveryDate(id, {
            receivedAt: dateToTimestamp(receivedAt),
          }),
        );
        dispatch(showSnackbar(successOptions, message));
        goBack();
      } catch ({ message }) {
        dispatch(showSnackbar(dangerOptions, message));
      }
    },
    [dispatch, goBack, id],
  );

  const { lot, isPending } = useSelector(state => state.lotDetails);

  const initialValues = useMemo(
    () => ({
      receivedAt: lot && lot.receivedAt,
    }),
    [lot],
  );

  return (
    <FormContainer loaded={!isPending && lot} header={LOTS.CHANGE_RECEIVED_DATE}>
      <ChangeReceivedDateForm
        initialValues={initialValues}
        createdAt={lot && lot.createdAt}
        onSubmit={onSubmit}
      />
    </FormContainer>
  );
}

const ChangeReceivedDate = withRouter(ChangeReceivedDateBase);

export { ChangeReceivedDate, ChangeReceivedDateBase };
