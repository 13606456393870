import { isOwnerOrSupport } from 'shared/helpers/matchers/checkUserType';
import { formatToDollarWithPrefix, formatToUS } from 'shared/helpers/parsers/formaters';

const POOL_ACCOUNTS = {
  DETAILS: 'Details',
  POOL_ACCOUNTS: 'Pool accounts',
  POOL_ACCOUNT_BALANCE: 'Pool account balance',
  ACTIONS: 'Actions',
  OVERVIEW: 'Overview',
  RELATED_COMPANY: 'Related company',
  RELATED_LOT: 'Related lot',
  COMPANY: 'Company',
  PLATINUM: 'Platinum',
  PALLADIUM: 'Palladium',
  RHODIUM: 'Rhodium',
  PLATINUM_OZ: 'Platinum (oz.)',
  PALLADIUM_OZ: 'Palladium (oz.)',
  RHODIUM_OZ: 'Rhodium (oz.)',
  PLATINUM_OZ_TO_SETTLE: 'Platinum (oz.) to settle',
  PALLADIUM_OZ_TO_SETTLE: 'Palladium (oz.) to settle',
  RHODIUM_OZ_TO_SETTLE: 'Rhodium (oz.) to settle',
  PT: 'Pt',
  PD: 'Pd',
  RH: 'Rh',
  PT_OUNCES: 'Pt ounces',
  PD_OUNCES: 'Pd ounces',
  RH_OUNCES: 'Rh ounces',
  GRAND_TOTAL: 'Grand total',
  UPDATED_BY: 'Updated by',
  UPDATED_ON: 'Updated on',
  DISCLAIMER:
    'Disclaimer: the monetary values indicated above are based on indicator pricing, and may not reflect actual market pricing.',
  DO_NOT_EXIST: 'Pool account does not exist.',
  CURRENT_MARKET_VALUE: 'Current market value',
  SETTLEMENT: 'Settlement',
  SETTLEMENT_FOR_LOT: 'Settlement for lot',
  SETTLEMENT_FOR_LOTS: 'Settlement for lots',
  SETTLEMENT_MOCK_UP: 'Settlement mock-up',
  SETTLEMENT_OF_METAL_POOL: 'Settlement of metal pool',
  SETTLE_LOTS: 'Settle lots',
  SETTLE_OUNCES: 'Settle ounces',
  OUNCES: 'Ounces',
  VIEW_JOURNAL: 'View journal',
  CREATE_STATEMENT: 'Create statement',
  COMMODITY: 'Commodity',
  LOT: 'Lot',
  TROY_OUNCES: 'Troy ounces',
  TOTAL: 'Total',
  OZ: 'oz.',
  GENERATE_BALANCE_STATEMENT_SUCCESS:
    'Pool account balance statement has been generated successfully.',
  GENERATE_BALANCE_STATEMENT_FAILURE: 'Pool account balance statement has not been generated.',
  ERROR: {
    DELETE_ERROR: 'Pool account has not been deleted.',
    ENTER_OUNCES: 'Ounces have not been entered.',
  },
  ENTER_OUNCES: 'Enter ounces',
  GRAND_TOTAL_VALUE: value =>
    `Total value of all outstanding accounts: ${formatToDollarWithPrefix(value) || ''}`,
  TOTAL_OUNCES: ({ outstandingPt, outstandingPd, outstandingRh }) =>
    `Total outstanding ounces (Pt/Pd/Rh): 
    ${formatToUS(outstandingPt, 3) || '-'} / 
    ${formatToUS(outstandingPd, 3) || '-'} /  
    ${formatToUS(outstandingRh, 3) || '-'}`,
  REQUEST_PAYMENT: 'Request payment',
  REQUEST_PAYMENT_INFO: (companyName, userType) =>
    `If ${
      companyName && isOwnerOrSupport(userType) ? `${companyName} does` : 'you do'
    } not wish to take payment at this time, please unselect the checked field.`,
  ADD: 'Add settlement',
  REQUEST_PAYMENT_TITLE: companyName =>
    `Request payment${companyName ? ` for ${companyName}` : ''}`,
};

export { POOL_ACCOUNTS };
