import React, { Component } from 'react';

import { FiltersDropdown } from 'shared/components/FiltersDropdown/FiltersDropdown';
import { availableTermStatuses } from 'shared/helpers/constants/filters/termStatuses';
import { termTypes } from 'shared/helpers/constants/filters/termTypes';
import { TERMS } from 'shared/helpers/constants/terms/termsConstants';

import { StyledTermFilters, StyledTermFiltersItem } from './StyledTermFilters';

class TermFilters extends Component {
  filterFunction = name => value => {
    this.props.onFiltersChanged({
      ...this.props.filters,
      [name]: value,
    });
  };

  getStatuses = userType =>
    availableTermStatuses[userType] ? availableTermStatuses[userType] : availableTermStatuses.all;

  render() {
    const {
      filters: { materialType, status },
      auth,
    } = this.props;

    return (
      <StyledTermFilters>
        <StyledTermFiltersItem>
          <FiltersDropdown
            label={TERMS.STATUS}
            options={this.getStatuses(auth.user.userType)}
            selected={status || []}
            filterFunction={this.filterFunction('status')}
          />
        </StyledTermFiltersItem>
        <StyledTermFiltersItem>
          <FiltersDropdown
            label={TERMS.MATERIAL_TYPE}
            options={Object.values(termTypes)}
            selected={materialType || []}
            filterFunction={this.filterFunction('materialType')}
          />
        </StyledTermFiltersItem>
      </StyledTermFilters>
    );
  }
}

export { TermFilters };
