import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import styled from 'styled-components';

import {
  getHedgeOunces,
  clearHedgeOunces,
  getHedgeOuncesSuccessAction,
} from 'actions/Hedges/getHedgeOunces';
import { marketAvailability } from 'actions/Hedges/marketAvailability';
import { showSnackbar } from 'actions/shared/snackbar';

import { Socket } from 'components/SocketContext/socketContext';

import { ContentLoader } from 'shared/components/ContentLoader/ContentLoader';
import { DetailsTabs } from 'shared/components/Details/DetailsTabs';
import { OverviewHeader } from 'shared/components/OverviewHeader';
import { HEDGES } from 'shared/helpers/constants/hedges/hedgesConstants';

import { HedgeOuncesList } from './components/HedgeOuncesList';

const NoResults = styled.div`
  padding: 30px 0;
  text-align: center;
`;

const HedgeOuncesLayout = ({
  hedgeOunces: { data, isPending },
  location,
  getHedgeOuncesAction,
  clearHedgeOuncesAction,
  marketAvailability,
  marketAvailabilityDetails,
  getHedgeOuncesSuccessAction,
  showSnackbar,
}) => {
  useEffect(() => {
    getHedgeOuncesAction();
    marketAvailability();

    Socket.instance.on('update_hedge_ounces', hedgeOunces =>
      getHedgeOuncesSuccessAction(hedgeOunces),
    );

    return () => clearHedgeOuncesAction();
  }, [
    getHedgeOuncesAction,
    clearHedgeOuncesAction,
    marketAvailability,
    getHedgeOuncesSuccessAction,
  ]);

  const tabsConfig = hedgeOunces =>
    hedgeOunces.map(({ _id }, index) => ({
      tabName: HEDGES.HEDGE_LOTS.SHIPMENT(index),
      total: data.length,
      shipmentId: _id,
      tabContent: {
        template: [`shipment_${index} shipment_${index} shipment_${index} shipment_${index}`],
        fieldsConfig: [
          {
            ...data[index],
            marketAvailabilityDetails,
            showSnackbar,
            id: `shipment_${index}`,
            Component: HedgeOuncesList,
          },
        ],
      },
    }));

  const selectShipment = () =>
    location.state &&
    location.state.shipmentId &&
    data.findIndex(d => d._id === location.state.shipmentId);

  return (
    <ContentLoader loaded={data && !isPending}>
      {data && (
        <>
          <OverviewHeader header={HEDGES.HEDGE_LOTS.TITLE} />
          {data.length ? (
            <DetailsTabs
              tabsConfig={tabsConfig(data)}
              listView
              itemsScroll
              selectedIndex={selectShipment()}
            />
          ) : (
            <NoResults>
              <h3>{HEDGES.HEDGE_LOTS.NO_OUTGOING_SHIPMENTS_FOUND}</h3>
            </NoResults>
          )}
        </>
      )}
    </ContentLoader>
  );
};

const mapStateToProps = ({ hedgeOunces, marketAvailabilityDetails }) => ({
  hedgeOunces,
  marketAvailabilityDetails,
});

const mapDispatchToProps = {
  getHedgeOuncesAction: getHedgeOunces,
  getHedgeOuncesSuccessAction,
  clearHedgeOuncesAction: clearHedgeOunces,
  marketAvailability,
  showSnackbar,
};

const HedgeOunces = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
)(HedgeOuncesLayout);

export { HedgeOunces, HedgeOuncesLayout };
