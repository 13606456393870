import { isFactory } from 'shared/helpers/matchers/matcherFactory';

const configNames = {
  customer: 'customer',
  warehouseForCustomer: 'warehouseForCustomer',
  grgForCustomer: 'grgForCustomer',
  grgForGlobal: 'grgForGlobal',
  postMill: 'postMill',
  postShears: 'postShears',
  postMix: 'postMix',
  incoming: 'incoming',
  packingList: 'packingList',
  placeholder: 'placeholder',
  blocked: 'blocked',
  editMultiplePackages: 'editMultiplePackages',
  editPackage: 'editPackage',
  samplePackages: 'samplePackages',
  ownerEditPackage: 'ownerEditPackage',
};

const availableFields = {
  assignedLot: 'assignedLot',
  packageType: 'packageType',
  materialTypeDeclared: 'materialTypeDeclared',
  denominatedIn: 'denominatedIn',
  processVia: 'processVia',
  mixVia: 'mixVia',
  materialClass: 'materialClass',
  unitCount: 'unitCount',
  weightGrossDeclared: 'weightGrossDeclared',
  weightTareDeclared: 'weightTareDeclared',
  weightGrossActual: 'weightGrossActual',
  weightTareActual: 'weightTareActual',
  customerInstructions: 'customerInstructions',
  number: 'number',
  status: 'status',
  labelId: 'labelId',
  plant: 'plant',
  removePackage: 'removePackage',
  primaryId: 'primaryId',
  secondaryId: 'secondaryId',
};

const configs = {
  [configNames.customer]: [
    availableFields.assignedLot,
    availableFields.packageType,
    availableFields.materialTypeDeclared,
    availableFields.unitCount,
    availableFields.weightGrossDeclared,
    availableFields.weightTareDeclared,
    availableFields.customerInstructions,
    availableFields.number,
  ],
  [configNames.warehouseForCustomer]: [
    availableFields.assignedLot,
    availableFields.packageType,
    availableFields.materialTypeDeclared,
    availableFields.customerInstructions,
    availableFields.weightGrossActual,
    availableFields.weightTareActual,
    availableFields.number,
    availableFields.unitCount,
  ],
  [configNames.grgForCustomer]: [
    availableFields.assignedLot,
    availableFields.packageType,
    availableFields.materialTypeDeclared,
    availableFields.unitCount,
    availableFields.weightGrossDeclared,
    availableFields.weightTareDeclared,
    availableFields.customerInstructions,
    availableFields.number,
  ],
  [configNames.grgForGlobal]: [
    availableFields.assignedLot,
    availableFields.packageType,
    availableFields.materialTypeDeclared,
    availableFields.unitCount,
    availableFields.weightGrossActual,
    availableFields.weightTareActual,
    availableFields.customerInstructions,
    availableFields.number,
  ],
  _processingBase: [
    availableFields.assignedLot,
    availableFields.processVia,
    availableFields.packageType,
    availableFields.materialTypeDeclared,
    availableFields.weightGrossActual,
    availableFields.weightTareActual,
    availableFields.customerInstructions,
    availableFields.number,
  ],
  _packingList: [
    availableFields.removePackage,
    availableFields.weightTareDeclared,
    availableFields.weightGrossDeclared,
    availableFields.materialTypeDeclared,
    availableFields.unitCount,
    availableFields.packageType,
    availableFields.customerInstructions,
    availableFields.plant,
    availableFields.labelId,
    availableFields.number,
  ],
  _samplePackages: [availableFields.number, availableFields.primaryId, availableFields.secondaryId],
  _placeholder: [availableFields.number, availableFields.status],

  get postMill() {
    return [...this._processingBase, availableFields.materialClass];
  },
  get postShears() {
    return this._processingBase;
  },
  get postMix() {
    return [...this._processingBase, availableFields.materialClass, availableFields.mixVia];
  },
  get packingList() {
    return [...this._packingList];
  },
  get samplePackages() {
    return [...this._samplePackages];
  },
  get editPackage() {
    return [...this[configNames.customer], availableFields.plant, availableFields.labelId];
  },
  get ownerEditPackage() {
    return [
      ...this[configNames.grgForCustomer],
      availableFields.plant,
      availableFields.labelId,
      availableFields.packageType,
    ];
  },
  get editMultiplePackages() {
    return [...this.packingList];
  },
  get placeholder() {
    return [...this._placeholder];
  },
  get incoming() {
    return [
      ...this[configNames.grgForCustomer],
      availableFields.weightGrossActual,
      availableFields.weightTareActual,
      availableFields.removePackage,
    ];
  },
};

const isGlobalCreatedPackage = isFactory(
  configNames.grgForCustomer,
  configNames.grgForGlobal,
  configNames.warehouseForCustomer,
);

const isProcessingPackage = isFactory(
  configNames.postMill,
  configNames.postShears,
  configNames.postMix,
);

const configsProxy = new Proxy(configs, {
  get(obj, prop) {
    return Object.prototype.hasOwnProperty.call(obj, prop) ? Reflect.get(obj, prop) : [];
  },
});

export { configNames, configsProxy as configs, isGlobalCreatedPackage, isProcessingPackage };
