import { statusesForCustomer } from 'shared/helpers/constants/lots/statusesForCustomer';
import { statusesForGlobal } from 'shared/helpers/constants/lots/statusesForGlobal';
import { isGradingCompany } from 'shared/helpers/matchers/checkCompanyType';
import { isOwnerUser, isSupportUser, isWarehouseUser } from 'shared/helpers/matchers/checkUserType';
import { isGlobalRefiningGroup } from 'shared/helpers/matchers/isGlobalRefiningGroup';

const changeProcessUponDeliveryAccess = (auth, lot) => {
  const access = auth.access.lots.changeProcessUponDelivery;
  const { userType } = auth.user;
  const allowedStatusesForGlobal = [
    statusesForGlobal.not_received,
    statusesForGlobal.partly_received,
    statusesForGlobal.received,
  ];

  if (isOwnerUser(userType) || isSupportUser(userType) || isWarehouseUser(userType)) {
    if (
      allowedStatusesForGlobal.includes(lot.statusForGlobal) &&
      !isGlobalRefiningGroup(lot.sentFromCompany.companyName) &&
      !isGradingCompany(lot.sentFromCompany.companyType)
    ) {
      return access;
    }
    return false;
  }

  if (lot.statusForCustomer === statusesForCustomer.open) {
    return access;
  }
  if (lot.statusForCustomer === statusesForCustomer.received) {
    return access && !lot.processUponDelivery;
  }
};

export { changeProcessUponDeliveryAccess };
