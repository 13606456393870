const { assayOptions } = require('shared/helpers/constants/lots/assayOptions');

export const setCorrectLabel = (option, HFLabel, ABLabel) => {
  switch (option) {
    case assayOptions.AB_assay:
      return ABLabel;
    default:
      return HFLabel;
  }
};
