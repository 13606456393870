import {
  createNoteActionType,
  createNoteFailureActionType,
  createNoteSuccessActionType,
} from 'actions/Notes/createNote';

const INITIAL_STATE = {
  isPending: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case createNoteActionType:
      return {
        isPending: true,
      };
    case createNoteSuccessActionType:
    case createNoteFailureActionType:
      return {
        isPending: false,
      };
    default:
      return state;
  }
};
