import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getMarketHolidaysActionType = 'GET_MARKET_HOLIDAYS';
function getMarketHolidaysAction(filters) {
  return {
    type: getMarketHolidaysActionType,
    payload: { filters },
  };
}

const getMarketHolidaysSuccessActionType = `${getMarketHolidaysActionType}_SUCCESS`;
function getMarketHolidaysSuccessAction(data) {
  return {
    type: getMarketHolidaysSuccessActionType,
    payload: { data },
  };
}

const getMarketHolidaysFailureActionType = `${getMarketHolidaysActionType}_FAILURE`;
function getMarketHolidaysFailureAction(errorMessage) {
  return {
    type: getMarketHolidaysFailureActionType,
    payload: { errorMessage },
  };
}

const clearMarketHolidaysActionType = 'CLEAR_MARKET_HOLIDAYS_ACTION';
function clearMarketHolidays() {
  return { type: clearMarketHolidaysActionType };
}

const restoreInitialStateActionType = 'RESTORE_INITIAL_STATE_FOR_MARKET_HOLIDAYS_ACTION';
function restoreInitialState() {
  return { type: restoreInitialStateActionType };
}

function getMarketHolidays(filters) {
  return dispatch => {
    dispatch(getMarketHolidaysAction(filters));

    return axios({
      method: 'get',
      url: `${API_HOST_AUTH}/market-holidays/${filters.year}`,
    })
      .then(res => {
        dispatch(getMarketHolidaysSuccessAction(res.data));
      })
      .catch(({ message }) => {
        dispatch(getMarketHolidaysFailureAction(message));
      });
  };
}

export {
  getMarketHolidaysActionType,
  getMarketHolidaysSuccessActionType,
  getMarketHolidaysFailureActionType,
  clearMarketHolidaysActionType,
  restoreInitialStateActionType,
  getMarketHolidays,
  clearMarketHolidays,
  restoreInitialState,
};
