import React, { Component } from 'react';

import { FullPageList } from 'shared/components/FullPageList/FullPageList';
import { Preloader } from 'shared/components/Preloader/Preloader';
import { FILES_CONSTANTS } from 'shared/helpers/constants/filesConstants';
import { TERMS } from 'shared/helpers/constants/terms/termsConstants';
import {
  itemsTemplate,
  itemsDetailsTemplate,
  itemsTemplateMobile,
  actionsTemplate,
  actionsTemplateCustomer,
} from 'shared/helpers/constants/terms/termsListTableConfig';
import { isCustomerUser } from 'shared/helpers/matchers/checkUserType';

import { checkStartDateTerm } from 'utils/checkStartDateTerm';

import { TermFilters } from './components/TermFilters/TermFilters';

class TermsList extends Component {
  componentDidMount() {
    const { id } = this.props.match.params;
    const companyId = this.props.auth.user.relatedCompany._id;

    if (id) {
      this.props
        .downloadApiAttachment(`terms/${id}/document`)
        .then(() => this.props.history.push(`/terms/${companyId}`));
    }
  }

  componentWillUnmount() {
    this.props.clearTermsList();
  }

  onFiltersChanged = async filters => {
    const {
      termsList: { limit },
      termStatusFilter,
      materialTypeFilter,
    } = this.props;

    await termStatusFilter(filters.status);
    await materialTypeFilter(filters.materialType);

    await this.fetchList(1, limit);
  };

  filtersClear = () => {
    const {
      getTermsList,
      termFiltersClear,
      termsList: { limit, page },
      match: {
        params: { companyId },
      },
    } = this.props;

    const clearedFilters = {
      status: [],
      materialType: [],
    };

    getTermsList(companyId, limit, page, clearedFilters);
    termFiltersClear();
  };

  renderFilters = () => {
    const {
      termsList: { limit, page, pages },
      termFilters,
      auth,
    } = this.props;

    return (
      <TermFilters
        filters={termFilters}
        pagination={{ page, limit, pages }}
        onFiltersChanged={this.onFiltersChanged}
        auth={auth}
      />
    );
  };

  fetchList = (page = 1, limit = 50) => {
    const {
      getTermsList,
      termFilters,
      match: {
        params: { companyId },
      },
    } = this.props;

    getTermsList(companyId, limit, page, termFilters);
  };

  updateTerms = terms => {
    return terms.map(term => {
      const updateStatus = checkStartDateTerm(term.status, term.startDate);
      return { ...term, status: updateStatus };
    });
  };

  render() {
    const {
      auth: {
        user: { userType },
      },
      termsList: { terms, limit, page, pages, company, isPending },
      termFilters,
      downloadApiAttachment,
      downloadAttachmentStorage,
    } = this.props;
    const { materialType, status } = termFilters;
    const filtersSelected = materialType.length || status.length;

    return (
      <>
        <FullPageList
          title={TERMS.TERMS}
          itemsTemplate={itemsTemplate}
          itemsDetailsTemplate={itemsDetailsTemplate}
          itemsTemplateMobile={itemsTemplateMobile}
          actionsTemplate={
            isCustomerUser(userType)
              ? actionsTemplateCustomer(downloadApiAttachment)
              : actionsTemplate(this.props.history, company)
          }
          items={this.updateTerms(terms) || []}
          fetchList={this.fetchList}
          filterComponent={this.renderFilters}
          filterAccess
          pagination={{ limit, page, pages }}
          filtersClear={this.filtersClear}
          filtersSelected={filtersSelected}
          actionsContext={this}
          isPending={isPending}
          filters={termFilters}
        />
        <Preloader
          loading={downloadAttachmentStorage.isPending}
          label={FILES_CONSTANTS.DOWNLOADING_FILES}
        />
      </>
    );
  }
}

export { TermsList };
