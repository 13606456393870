import styled from 'styled-components';

import { cssVar, pxToRem } from 'shared/helpers/styling/styling';

export const StyledProgressBar = styled.div`
  width: ${({ width }) => width}%;

  .progress {
    background-color: ${({ progressBgColor }) => cssVar(progressBgColor)};

    .progress-bar {
      background-color: ${({ barColor }) => cssVar(barColor)};
    }
  }
`;

export const StyledLabel = styled.span`
  font-size: ${pxToRem(10)};
  color: ${cssVar('white')};
`;
