import { PASSWORD_VALIDATION } from 'shared/helpers/constants/users/passwordValidationConstants';
import { USER_PASSWORD } from 'shared/helpers/validations/regularExpressions';

function validate({ oldPassword, newPassword, newPasswordRepeat }) {
  let oldPasswordError = null;
  let newPasswordError = null;
  let newPasswordRepeatError = null;

  if (!oldPassword || !oldPassword.trim()) {
    oldPasswordError = PASSWORD_VALIDATION.CANNON_BE_EMPTY;
  }

  if (!newPassword || !newPassword.trim()) {
    newPasswordError = PASSWORD_VALIDATION.CANNON_BE_EMPTY;
  } else if (!USER_PASSWORD.test(newPassword)) {
    newPasswordError = PASSWORD_VALIDATION.IS_INVALID;
  }

  if (newPassword !== newPasswordRepeat) {
    newPasswordRepeatError = PASSWORD_VALIDATION.DO_NOT_MATCH;
  }

  return {
    oldPassword: oldPasswordError,
    newPassword: newPasswordError,
    newPasswordRepeat: newPasswordRepeatError,
  };
}

export { validate };
