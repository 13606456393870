import {
  getTimelineActionType,
  getTimelineFailureActionType,
  getTimelineSuccessActionType,
  clearTimelineActionType,
} from 'actions/Lots/getTimeline';

const INITIAL_STATE = {
  isPending: false,
  events: null,
};

const getTimelineReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getTimelineActionType:
      return {
        ...state,
        isPending: true,
      };
    case getTimelineSuccessActionType:
      return {
        events: action.payload.data,
        isPending: false,
      };
    case getTimelineFailureActionType:
      return {
        ...state,
        isPending: false,
      };
    case clearTimelineActionType:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};

export { getTimelineReducer };
