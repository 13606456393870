import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getOutgoingLotsOverview,
  outgoingLotsOverviewSelectedAction,
} from 'actions/Lots/outgoingLotsOverview';

import { ContentLoader } from 'shared/components/ContentLoader/ContentLoader';
import { DraggableSection } from 'shared/components/DraggableSection/DraggableSection';
import { Icon } from 'shared/components/Icon/Icon';
import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';
import { outgoingSections } from 'shared/helpers/constants/lots/outgoingSections';
import {
  outgoingLotItemTemplate,
  outgoingLotsActionsTemplate,
} from 'shared/helpers/constants/lots/outgoingTableConfig';
import { SHIPMENTS } from 'shared/helpers/constants/shipments/shipmentsConstants';
import { formatToUS } from 'shared/helpers/parsers/formaters';
import { replaceEmpty } from 'shared/helpers/parsers/text';

import { OverviewLotsSection } from 'pages/Lots/components/OverviewLotsSection/OverviewLotsSection';

import {
  StyledInfo,
  StyledReadyToShipSection,
  StyledRouterButton,
} from './StyledReadyToShipSection';

export const ReadyToShipSection = ({ total }) => {
  const outgoingLotsOverview = useSelector(state => state.outgoingLotsOverview);
  const dispatch = useDispatch();

  const sumLotsArray = lots => lots.reduce((acc, lot) => acc + (lot.weightGrossActual || 0), 0);

  const getSelectedWeight = () => {
    const { selected } = outgoingLotsOverview.readyToShip;
    const weight = sumLotsArray(selected);

    return Number(weight).toFixed(2);
  };

  const setSelected = ({ selected }) => {
    if (selected) {
      dispatch(outgoingLotsOverviewSelectedAction(outgoingSections.readyToShip, selected));
    }
  };

  const orderFn = (lot1, lot2) => 2 * (lot1.grgLotNumber > lot2.grgLotNumber) - 1;

  const getItemsWeight = () => {
    const { docs } = outgoingLotsOverview.readyToShip.items;
    const weight = sumLotsArray(docs) - getSelectedWeight();

    return Number(weight).toFixed(2);
  };

  const renderButtons = () => (
    <StyledRouterButton
      type="button"
      path="/shipments/create-outgoing"
      label={SHIPMENTS.CREATE_OUTGOING_SHIPMENT}
      icon={<Icon icon="icon-plus" />}
    />
  );

  const getDraggableProps = () => ({ icon: 'icon-drag' });

  useEffect(() => {
    const { selected } = outgoingLotsOverview.readyToShip;
    dispatch(getOutgoingLotsOverview(outgoingSections.readyToShip));
    setSelected({ selected });
  }, []);

  if (!outgoingLotsOverview.readyToShip.items) {
    return <ContentLoader />;
  }

  const list = outgoingLotsOverview.readyToShip;
  const data = list.items.docs;

  const sections = {
    items: {
      placeholder: LOTS.NO_MORE_LOTS,
      sort: orderFn,
      data: data.filter(p => !list.selected.find(s => s._id === p._id)),
    },
    selected: {
      placeholder: LOTS.DROP_HERE,
      sort: orderFn,
      data: data.filter(p => list.selected.find(s => s._id === p._id)),
    },
  };

  return (
    <>
      <StyledInfo>
        <div>
          {`${LOTS.AVAILABLE_LOTS_WEIGHT}: ${replaceEmpty(formatToUS(+getItemsWeight()))}lbs`}
        </div>
        <div>{`${LOTS.SELECTED_WEIGHT}: ${replaceEmpty(formatToUS(+getSelectedWeight()))}lbs`}</div>
      </StyledInfo>
      <OverviewLotsSection
        id={outgoingSections.readyToShip.name}
        title={outgoingSections.readyToShip.title}
        total={total}
        disableTransform
        buttons={renderButtons}
      >
        <StyledReadyToShipSection>
          <DraggableSection
            sections={sections}
            itemTemplate={outgoingLotItemTemplate}
            actionsTemplate={outgoingLotsActionsTemplate}
            onSelectedChange={setSelected}
            getDraggableProps={getDraggableProps}
          />
        </StyledReadyToShipSection>
      </OverviewLotsSection>
    </>
  );
};

ReadyToShipSection.propTypes = {
  total: PropTypes.number,
};
