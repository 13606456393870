import axios from 'axios';
import Cookies from 'js-cookie';
import { reset } from 'redux-form';

import { Socket } from 'components/SocketContext/socketContext';

import { dangerOptions } from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

import { showSnackbar, hideSnackbar } from './snackbar';

const API_HOST_AUTH = process.env.REACT_APP_API;

const logoutActionType = 'LOGOUT_USER';
function logoutAction() {
  return {
    type: logoutActionType,
  };
}

const loginActionType = 'LOGIN_USER';
function loginAction() {
  return {
    type: loginActionType,
  };
}

const loginSuccessActionType = 'LOGIN_USER_SUCCESS';
function loginSuccessAction(data) {
  return {
    type: loginSuccessActionType,
    payload: {
      data,
    },
  };
}

const loginFailureActionType = 'LOGIN_USER_FAILURE';
function loginFailureAction(attempts) {
  return {
    type: loginFailureActionType,
    payload: {
      attempts,
    },
  };
}

function login(user, gRecaptchaResponse) {
  return dispatch => {
    dispatch(loginAction());

    return axios({
      method: 'post',
      url: `${API_HOST_AUTH}/auth`,
      data: {
        email: user.email,
        password: user.password,
        gRecaptchaResponse,
      },
    })
      .then(response => {
        Cookies.set('grgUserSession', response.data.payload.details.id);
        dispatch(loginSuccessAction(response.data.payload));
        dispatch(hideSnackbar());

        return response;
      })
      .catch(error => {
        dispatch(loginFailureAction(error.data.attempts));
        dispatch(showSnackbar(dangerOptions, error.data.message));
        dispatch(reset('LoginForm'));

        return Promise.reject(error);
      });
  };
}

function logout() {
  return dispatch => {
    dispatch(logoutAction());

    return axios.post(`${API_HOST_AUTH}/logout`).then(Socket.closeConnection);
  };
}

export {
  logoutActionType,
  loginActionType,
  loginSuccessActionType,
  loginFailureActionType,
  login,
  logout,
};
