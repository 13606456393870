import React, { Fragment, useContext, useMemo, useEffect, useCallback } from 'react';
import { Field } from 'redux-form';

import { FieldInput } from 'shared/components/Fields/FieldInput/FieldInput';
import { FieldSelect } from 'shared/components/Fields/FieldSelect/FieldSelect';
import { Section } from 'shared/components/Form';
import { wizardContext } from 'shared/components/Wizard/Wizard';
import { COMPANY } from 'shared/helpers/constants/companies/companyConstants';

function PrimaryContacts(props) {
  const { fields } = props;
  const wizard = useContext(wizardContext);
  const data = wizard && wizard.data;
  const locations = (data && data.locations) || [];
  const users = (data && data.users) || [];

  useEffect(() => {
    locations.forEach(({ lotSuffix }) => fields.push({ lotSuffix }));

    return () => fields.removeAll();
  }, [locations]);

  const primaryContactsTemplate = useMemo(
    () => fields.map(field => `${field}.lotSuffix ${field}.email`),
    [fields],
  );

  const options = useMemo(
    () =>
      users.map(({ firstName, lastName, email }) => ({
        label: `${firstName} ${lastName}`,
        value: email,
      })),
    [users],
  );

  const format = useCallback(
    value => locations.find(({ lotSuffix }) => lotSuffix === value).name,
    [locations],
  );

  return (
    <Section header={COMPANY.PRIMARY_CONTACTS} template={primaryContactsTemplate} unwrap>
      {fields.map((primaryContact, index) => (
        <Fragment key={index}>
          <Field
            name={`${primaryContact}.lotSuffix`}
            component={FieldInput}
            label={index ? COMPANY.LOCATION : COMPANY.PRIMARY_LOCATION}
            format={format}
            disabled
          />
          <Field
            name={`${primaryContact}.email`}
            component={FieldSelect}
            label={COMPANY.USER}
            field={locations[index].shipmentEligible && 'required'}
            options={options}
            multi
          />
        </Fragment>
      ))}
    </Section>
  );
}

export { PrimaryContacts };
