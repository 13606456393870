import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const enterCustomPricesActionType = 'ENTER_CUSTOM_PRICES';
const enterCustomPricesAction = () => ({
  type: enterCustomPricesActionType,
});

const enterCustomPricesSuccessActionType = `${enterCustomPricesActionType}_SUCCESS`;
const enterCustomPricesSuccessAction = () => ({
  type: enterCustomPricesSuccessActionType,
});

const enterCustomPricesFailureActionType = `${enterCustomPricesActionType}_FAILURE`;
const enterCustomPricesFailureAction = () => ({
  type: enterCustomPricesFailureActionType,
});

const enterCustomPrices = (hedgeId, prices) => dispatch => {
  dispatch(enterCustomPricesAction());

  return axios
    .patch(`${API_HOST_AUTH}/hedges/${hedgeId}/custom-prices`, prices)
    .then(({ data }) => {
      dispatch(enterCustomPricesSuccessAction());

      return data;
    })
    .catch(error => {
      dispatch(enterCustomPricesFailureAction());

      return Promise.reject(error.data);
    });
};

export {
  enterCustomPricesActionType,
  enterCustomPricesSuccessActionType,
  enterCustomPricesFailureActionType,
  enterCustomPrices,
};
