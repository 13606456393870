import React from 'react';

import { DropdownToggleIcon } from '../styles/SelectDropdown.styles';

function DropdownIcon({ dropdownOpen }) {
  return (
    <DropdownToggleIcon
      className={`icon filter-arrow ${dropdownOpen && 'animate'} icon-tiny-arrow-down`}
    />
  );
}

export { DropdownIcon };
