import React, { memo, forwardRef } from 'react';
import styled, { css } from 'styled-components';

import { Input, InputWrapper } from 'shared/components/Fields/FieldInput/FieldInput';
import { withTinyFieldWrapper } from 'shared/components/Fields/TinyFieldWrapper/TinyFieldWrapper';
import { isTablet } from 'shared/helpers/constants/resolutionsConstants';
import { cssVar, pxToRem, pixelify } from 'shared/helpers/styling/styling';

import { getBrowserWidth } from 'utils/responsive';

const TinyInput = memo(styled(Input).attrs({
  prefixSize: 10,
})`
  padding: ${({ padding }) => padding};
  width: 100%;
  min-width: 0;
  font-size: ${pxToRem(14)};
  height: ${({ height }) => pixelify(height)};

  &:disabled {
    cursor: not-allowed;

    ${({ hide }) =>
      hide &&
      css`
      width: 80px;
      cursor: auto;
      background-color: ${cssVar('limedSpruceForm')};
    }
  `}
`);

const setFieldType = propsType => {
  if (propsType === 'number') return getBrowserWidth() < isTablet ? propsType : 'text';
  return propsType;
};

const TinyFieldInput = withTinyFieldWrapper(
  forwardRef((props, ref) => (
    <InputWrapper
      prefix={props.prefix}
      suffix={props.suffix}
      prefixSize={props.prefixSize}
      suffixSize={props.suffixSize}
      show={props.meta && (props.meta.active || props.input.value)}
      fontSize={16}
    >
      <TinyInput
        {...props}
        {...props.input}
        {...(props.disabled && { onDrop: e => e.preventDefault() })}
        type={setFieldType(props.type)}
        autoComplete="off"
        ref={ref}
        padding={props.padding}
      />
    </InputWrapper>
  )),
);

TinyInput.defaultProps = {
  padding: '11px 10px',
};

export { TinyFieldInput };
