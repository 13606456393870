import React from 'react';

import { layout } from 'shared/helpers/constants/versioning/layout';

import {
  StyledFormWrapperContainer,
  StyledFormWrapperContainerChildren,
  StyledFormWrapperContainerContent,
  StyledFormWrapperContainerLogo,
  StyledFormWrapperContainerWrapper,
} from './StyledFormWrapper';

const FormWrapper = props => (
  <StyledFormWrapperContainer>
    <StyledFormWrapperContainerWrapper>
      <StyledFormWrapperContainerContent>
        <StyledFormWrapperContainerLogo>
          <img src={layout.logo} alt="Global Refining Group" />
        </StyledFormWrapperContainerLogo>
        <StyledFormWrapperContainerChildren>{props.children}</StyledFormWrapperContainerChildren>
      </StyledFormWrapperContainerContent>
    </StyledFormWrapperContainerWrapper>
  </StyledFormWrapperContainer>
);

export { FormWrapper };
