import {
  markGradingCompleteType,
  markGradingCompleteSuccessType,
  markGradingCompleteFailureType,
} from 'actions/Lots/markGradingComplete';

const INITIAL_STATE = {
  isPending: false,
};

const markGradingCompleteReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case markGradingCompleteType:
      return {
        isPending: true,
      };
    case markGradingCompleteSuccessType:
    case markGradingCompleteFailureType:
      return {
        isPending: false,
      };
    default:
      return state;
  }
};

export { markGradingCompleteReducer };
