import { hedgeStatus } from 'shared/helpers/constants/hedges/hedgeStatus';
import { hedgeTypes } from 'shared/helpers/constants/hedges/hedgeType';

const enterCustomPricesAccess = (
  {
    access: {
      hedges: { enterCustomPrices },
    },
  },
  hedge,
) =>
  enterCustomPrices &&
  hedge.hedgeType === hedgeTypes.custom &&
  (hedge.status === hedgeStatus.placed ||
    hedge.status === hedgeStatus.reassigned ||
    (hedge.status === hedgeStatus.filled &&
      (!hedge.platinumPriceCust || !hedge.palladiumPriceCust || !hedge.rhodiumPriceCust)));

export { enterCustomPricesAccess };
