import PropTypes from 'prop-types';
import * as React from 'react';
import styled from 'styled-components';

import { ModalCustom } from 'components/ModalCustom/ModalCustom';

import { Button } from 'shared/components/Buttons';
import { Icon } from 'shared/components/Icon/Icon';
import { defaultModal } from 'shared/helpers/constants/modalConstants';
import { isCustomerUser } from 'shared/helpers/matchers/checkUserType';
import { cssVar } from 'shared/helpers/styling/styling';

import { firstStepModal, secondStepModal } from './config/modalMessageConfig';

const ModalContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px 16px 20px;
`;

const Header = styled.h4`
  font-size: 1rem;
  font-weight: bold;
`;

const Paragraph = styled.p`
  font-size: 0.875rem;
  color: var(--gullGray);
  font-weight: 400;
  text-align: center;
  padding-top: 0.5rem;
`;

const ModalButtons = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 10px;
`;

const MarkShipmentAsSentModal = ({
  sendShipmentModalOpen,
  handleClickModalClose,
  showSecondMessage,
  handleClickConfirm,
  handleSubmitWizard,
  handleClickEditShipment,
  userType,
}) => {
  return (
    <ModalCustom isOpen={sendShipmentModalOpen} modalClose={handleClickModalClose}>
      <ModalContent>
        <Icon
          icon="warning icon-exclamation"
          color={cssVar(
            showSecondMessage ? secondStepModal.topIconColor : firstStepModal.topIconColor,
          )}
          height={65}
          width={65}
        />
        <Header>{firstStepModal.header(isCustomerUser(userType))}</Header>
        <Paragraph>
          {showSecondMessage
            ? secondStepModal.paragraph
            : firstStepModal.paragraph(isCustomerUser(userType))}
        </Paragraph>
        <ModalButtons>
          <Button
            outlineColor={cssVar(
              showSecondMessage
                ? secondStepModal.customActionColor
                : firstStepModal.cancelButtonColor,
            )}
            onClick={showSecondMessage ? handleClickEditShipment : handleClickModalClose}
          >
            {showSecondMessage ? secondStepModal.customActionButton : firstStepModal.cancelButton}
            <Icon
              icon={
                showSecondMessage
                  ? secondStepModal.customActionIcon
                  : firstStepModal.cancelButtonIcon
              }
              width={15}
              height={15}
              size="contain"
            />
          </Button>
          <Button onClick={showSecondMessage ? handleSubmitWizard : handleClickConfirm}>
            {showSecondMessage ? secondStepModal.acceptButton : firstStepModal.acceptButton}
            <Icon icon={defaultModal.acceptIcon} width={20} height={20} size="contain" />
          </Button>
        </ModalButtons>
      </ModalContent>
    </ModalCustom>
  );
};

MarkShipmentAsSentModal.propTypes = {
  handleClickEditShipment: PropTypes.func,
  handleClickModalClose: PropTypes.func,
  handleClickConfirm: PropTypes.func,
  handleSubmitWizard: PropTypes.func,
  sendShipmentModalOpen: PropTypes.bool,
  showSecondMessage: PropTypes.bool,
  userType: PropTypes.string,
};

export { MarkShipmentAsSentModal };
