import styled from 'styled-components';

import { GroupButton } from 'shared/components/Buttons/GroupButton/components/GroupButton';
import { media } from 'shared/helpers/styling/styling';

export const StyledGroupButton = styled(GroupButton)`
  margin: 0 0.3rem;

  ${media.phone`
  margin: 5px 0;
  `}
`;

export const StyledLotsOutgoing = styled.div`
  .item-grgLotNumber {
    min-width: 150px;
  }
`;

export const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0 1rem 0;

  ${media.mobile`
  flex-direction: column;
  `}

  ${media.phone`
  padding: 0 15px;
  `}
`;

export const StyledActions = styled.div`
  display: flex;
  justify-content: flex-end;

  ${media.phone`
  flex-direction: column;
  `}
`;
