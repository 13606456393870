import styled from 'styled-components';

export const TermsUploadSignedStyledForm = styled.form`
  &-button.btn {
    width: 100%;
    min-width: none;
  }

  .FieldUpload-error {
    height: 0;
  }
`;
