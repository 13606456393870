import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import {
  getAssignedLotsForOutgoingShipment,
  clearAssignedLotsAction,
} from 'actions/Lots/getAssignedLots';
import { outgoingLotsOverviewClearAction } from 'actions/Lots/outgoingLotsOverview';
import { createOutgoingShipment } from 'actions/Shipments/createShipment';
import { openModal, closeModal } from 'actions/shared/modal';
import { showSnackbar } from 'actions/shared/snackbar';

import { CreateOutgoingShipment } from './CreateOutgoingShipment';

function mapStateToProps({ auth, router, modal, assignedLots, outgoingLotsOverview }) {
  return {
    auth,
    router,
    modal,
    assignedLots,
    outgoingLotsOverview,
  };
}

const mapDispatchToProps = {
  createOutgoingShipment,
  getAssignedLotsForOutgoingShipment,
  openModal,
  closeModal,
  showSnackbar,
  outgoingLotsOverviewClearAction,
  clearAssignedLotsAction,
};

const ConnectedCreateOutgoingShipment = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreateOutgoingShipment),
);

export { ConnectedCreateOutgoingShipment };
