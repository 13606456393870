import axios from 'axios';

import { parsePaymentsFilters } from 'shared/helpers/dataParsers/payments/parsePaymentsFilters';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getPaymentsActionType = 'GET_PAYMENTS_LIST';
const getPaymentsAction = (filters, pagination) => ({
  type: getPaymentsActionType,
  payload: { filters, pagination },
});

const getPaymentsSuccessActionType = `${getPaymentsActionType}_SUCCESS`;
const getPaymentsSuccessAction = payments => ({
  type: getPaymentsSuccessActionType,
  payload: { payments },
});

const getPaymentsFailureActionType = `${getPaymentsActionType}_FAILURE`;
const getPaymentsFailureAction = () => ({
  type: getPaymentsFailureActionType,
});

const clearPaymentsActionType = `${getPaymentsActionType}_CLEAR`;
const clearPaymentsAction = () => ({
  type: clearPaymentsActionType,
});

const getPayments = (filters, pagination) => dispatch => {
  const parsedFilters = parsePaymentsFilters(filters);

  dispatch(getPaymentsAction(parsedFilters, pagination));
  return axios
    .get(`${API_HOST_AUTH}/payments`, {
      params: {
        ...parsedFilters,
        ...pagination,
      },
    })
    .then(({ data }) => {
      dispatch(getPaymentsSuccessAction(data));
      return data;
    })
    .catch(error => {
      dispatch(getPaymentsFailureAction());
      return Promise.reject(error.data);
    });
};

export {
  clearPaymentsAction,
  clearPaymentsActionType,
  getPaymentsActionType,
  getPaymentsSuccessActionType,
  getPaymentsFailureActionType,
  getPayments,
};
