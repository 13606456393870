import styled, { css } from 'styled-components';

import { cssVar, pxToRem } from 'shared/helpers/styling/styling';

import { isWest } from 'utils/versioning/Guards';

const timeZone = isWest ? 'PT' : 'ET';

export const StyledDatePickerWrapper = styled.div`
  ${({ showTimeZoneInInput }) =>
    showTimeZoneInInput &&
    css`
      .react-datepicker__input-container {
        position: relative;

        &::after {
          position: absolute;
          content: '${timeZone}';
          color: ${cssVar('alabaster')};
          left: ${({ timeLength }) => (timeLength === 10 ? 89 : 81)}px;
          top: 15px;
          font-size: ${pxToRem(14)};
        }
      }
    `}

  .react-datepicker {
    background: var(--riverBed);

    &__time-box {
      background-color: var(--outerSpaceBrighter);
    }

    &__time-list-item {
      background-color: var(--shuttleGray);
      &--disabled {
        background-color: var(--limedSpruceDarker);
      }
    }

    &__triangle {
      display: none;
    }

    &__navigation {
      margin: 10px 5px 0;

      &--next--with-time {
        right: 105px;
      }

      &:active,
      &:focus {
        outline: none;
      }
    }

    &__time-container {
      width: 265px;
      box-shadow: 14px 11px 24px -9px rgba(0, 0, 0, 0.29);

      .react-datepicker__header--time {
        display: none;
        align-items: flex-end;
        justify-content: center;
        border-bottom: 1px solid rgba(${cssVar('dustGray')}, 0.53);
        border-top-left-radius: 0;

        .react-datepicker-time__header {
          font-weight: 300;
        }
      }

      .react-datepicker__time {
        width: 100%;
        background-color: ${cssVar('shuttleGray')};
        border-bottom-right-radius: 5px;

        &-box {
          width: 100%;
          margin: 0 0;

          ul.react-datepicker__time-list {
            height: fit-content;
            max-height: 265px;
          }
        }

        &-list {
          overflow: auto !important;
          color: ${cssVar('alto')};

          &-item {
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 44px;
            width: 100%;

            &:not(:last-child) {
              border-bottom: 1px solid rgba(${cssVar('dustGray')}, 0.2);
            }
          }

          &-item--disabled {
            display: none;
          }
        }
      }
    }
  }

  ${({ showTimeZoneInDropDown }) =>
    showTimeZoneInDropDown &&
    css`
      .react-datepicker__time-list-item {
        &::after {
          content: '${timeZone}';
          padding-left: 5px;
        }
      }
    `}
`;
