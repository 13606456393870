import { deleteSettlement } from 'actions/Settlements/deleteSettlement';
import { getLotSettlements } from 'actions/Settlements/getLotSettlements';
import { openModal } from 'actions/shared/modal';
import { showSnackbar } from 'actions/shared/snackbar';

import { deleteSettlementsAccess } from 'shared/helpers/accesses/settlements/deleteSettlementsAccess';
import { enterPricesForSettledOuncesAccess } from 'shared/helpers/accesses/settlements/enterPricesForSettledOuncesAccess';
import {
  troyOuncesTemplate,
  hedgePricesTemplate,
} from 'shared/helpers/constants/hedges/hedgesListTableConfig';
import { removeSettlementRequestModal } from 'shared/helpers/constants/modalConstants';
import { SETTLEMENTS } from 'shared/helpers/constants/settlements/settlementsConstants';
import {
  successOptions,
  dangerOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { isInternalCompany } from 'shared/helpers/matchers/checkCompanyType';
import { isOwnerOrSupport, isWarehouseUser } from 'shared/helpers/matchers/checkUserType';
import { isSettlementZero } from 'shared/helpers/matchers/settlements/isSettlementZero';
import { dateWithShortTimeFormat, dateFormat } from 'shared/helpers/parsers/date';
import { formatDate } from 'shared/helpers/parsers/formaters';

import { history } from 'utils/history';

export const templates = {
  troyOunces: {
    label: SETTLEMENTS.TROY_OUNCES,
    render: ({ ouncesPt, ouncesPd, ouncesRh }) =>
      troyOuncesTemplate.render({
        platinumOz: ouncesPt,
        palladiumOz: ouncesPd,
        rhodiumOz: ouncesRh,
      }),
  },
  prices: {
    label: SETTLEMENTS.PRICES,
    render: ({ pricePerOuncePt, pricePerOuncePd, pricePerOunceRh }) =>
      hedgePricesTemplate.render({
        platinumPriceCust: pricePerOuncePt,
        palladiumPriceCust: pricePerOuncePd,
        rhodiumPriceCust: pricePerOunceRh,
      }),
  },
  requestedBy: {
    label: SETTLEMENTS.REQUESTED_BY,
    render: ({ requestedBy: { firstName, lastName } }) => `${firstName} ${lastName}`,
  },
  requestedOn: {
    label: SETTLEMENTS.REQUESTED_ON,
    render: ({ createdAt }) => formatDate(createdAt, dateWithShortTimeFormat),
  },
  customerRequestedOn: {
    label: SETTLEMENTS.REQUESTED_ON,
    render: ({ createdAt }) => formatDate(createdAt, dateFormat),
  },
  createdBy: {
    label: SETTLEMENTS.CREATED_BY,
    render: ({ createdBy: { firstName, lastName } }) => `${firstName} ${lastName}`,
  },
  updatedAt: {
    label: SETTLEMENTS.UPDATED_ON,
    render: ({ updatedAt }) => (updatedAt ? formatDate(updatedAt, dateWithShortTimeFormat) : '-'),
  },
  updatedBy: {
    label: SETTLEMENTS.UPDATED_BY,
    render: ({ updatedBy }) => (updatedBy ? `${updatedBy.firstName} ${updatedBy.lastName}` : '-'),
  },
};

export const itemsTemplate = (userType, companyType) => [
  templates.troyOunces,
  templates.prices,
  templates.requestedBy,
  ...(isOwnerOrSupport(userType) || (isInternalCompany(companyType) && !isWarehouseUser(userType))
    ? [templates.requestedOn, templates.createdBy, templates.updatedAt, templates.updatedBy]
    : isInternalCompany(companyType)
    ? [templates.requestedOn, templates.createdBy, templates.updatedBy]
    : [templates.customerRequestedOn]),
];

export const itemsTemplateMobile = [templates.troyOunces, templates.prices];

export const itemDetailsTemplate = (userType, companyType) => [
  templates.requestedBy,
  templates.requestedOn,
  ...(isOwnerOrSupport(userType) || (isInternalCompany(companyType) && !isWarehouseUser(userType))
    ? [templates.createdBy, templates.updatedAt, templates.updatedBy]
    : []),
];

export const actionsItemTemplate = (
  { lotId, limit, relatedCompanyType },
  { settlements },
  userType,
  dispatch,
) => [
  {
    icon: 'dollar',
    action: SETTLEMENTS.ENTER_PRICES,
    onClick:
      ({ _id }) =>
      () =>
        history.push(`${lotId}/settlements/${_id}/enter-prices`),
    access: ({ relatedLot: { statusForGlobal }, ouncesPd, ouncesPt, ouncesRh }) =>
      !isSettlementZero({ ouncesPd, ouncesPt, ouncesRh }) &&
      enterPricesForSettledOuncesAccess(statusForGlobal, settlements, relatedCompanyType),
  },
  {
    icon: 'bin',
    action: 'Delete',
    access: ({ relatedLot: { statusForGlobal } }) =>
      deleteSettlementsAccess(statusForGlobal, userType, relatedCompanyType),
    onClick:
      ({ _id }) =>
      () =>
        dispatch(
          openModal(removeSettlementRequestModal, async () => {
            try {
              const res = await dispatch(deleteSettlement(_id));
              await dispatch(showSnackbar(successOptions, res.message));
              dispatch(getLotSettlements(lotId, limit));
            } catch (err) {
              dispatch(showSnackbar(dangerOptions, err.message));
            }
          }),
        ),
  },
];
