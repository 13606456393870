import {
  createDeliveriesActionType,
  createDeliveriesSuccessActionType,
  createDeliveriesFailureActionType,
} from 'actions/Deliveries/createDeliveries';

const INITIAL_STATE = {
  isPending: false,
};

const createDeliveriesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case createDeliveriesActionType:
      return {
        isPending: true,
      };
    case createDeliveriesSuccessActionType:
    case createDeliveriesFailureActionType:
      return {
        isPending: false,
      };
    default:
      return state;
  }
};

export { createDeliveriesReducer };
