import { connect } from 'react-redux';

import { acceptForProcessing } from 'actions/Lots/acceptForProcessing';
import { getLot } from 'actions/Lots/lotDetails';
import { openModal } from 'actions/shared/modal';
import { showSnackbar } from 'actions/shared/snackbar';

import { acceptForProcessingModal } from 'shared/helpers/constants/modalConstants';
import {
  successOptions,
  dangerOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

function AcceptForProcessingBase(props) {
  const acceptForProcessingAction = () => {
    props.openModal(acceptForProcessingModal, () => {
      props
        .acceptForProcessing(props.lot._id)
        .then(res => {
          props.showSnackbar(successOptions, res.message);
          props.successCallback && props.successCallback();
        })
        .catch(err => {
          props.getLot(props.lot._id);
          props.showSnackbar(dangerOptions, err.message);
        });
    });
  };

  return props.children({ acceptForProcessing: acceptForProcessingAction });
}

const mapDispatchToProps = {
  openModal,
  showSnackbar,
  acceptForProcessing,
  getLot,
};

const AcceptForProcessing = connect(null, mapDispatchToProps)(AcceptForProcessingBase);

export { AcceptForProcessing, AcceptForProcessingBase };
