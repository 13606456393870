import React from 'react';

import { StatusIndicator } from 'shared/components/StatusIndicator/StatusIndicator';
import { dateFormat } from 'shared/helpers/parsers/date';
import { capitalize } from 'shared/helpers/parsers/text';

import dateAdapter from 'utils/date/dateAdapter';

import { PAYMENTS } from './paymentsConstants';

const itemsTemplate = [
  {
    label: PAYMENTS.PAYMENT_EXCEPTION_COMPANIES.STATUS,
    render: ({ status }) => <StatusIndicator status={status} />,
  },
  {
    label: PAYMENTS.PAYMENT_EXCEPTION_COMPANIES.COMPANY_NAME,
    render: ({ companyName }) => companyName,
  },
  {
    label: PAYMENTS.PAYMENT_EXCEPTION_COMPANIES.COMPANY_TYPE,
    render: ({ companyType }) => capitalize(companyType),
  },
  {
    label: PAYMENTS.PAYMENT_EXCEPTION_COMPANIES.LOT_PREFIX,
    render: ({ lotPrefix }) => lotPrefix,
  },
  {
    label: PAYMENTS.PAYMENT_EXCEPTION_COMPANIES.DATE_ADDED,
    render: ({ createdAt }) => dateAdapter(createdAt).format(dateFormat),
  },
  {
    label: PAYMENTS.PAYMENT_EXCEPTION_COMPANIES.ADDED_BY,
    render: ({ createdBy: { firstName, lastName } }) => `${firstName} ${lastName}`,
  },
];

const itemsTemplateMobile = [
  {
    label: PAYMENTS.PAYMENT_EXCEPTION_COMPANIES.STATUS,
    render: ({ status }) => <StatusIndicator status={status} />,
  },
  {
    label: PAYMENTS.PAYMENT_EXCEPTION_COMPANIES.COMPANY_NAME,
    render: ({ companyName }) => companyName,
    className: 'PaymentExceptionCompanies-companyName',
  },
];

const [, , ...itemsDetailsTemplate] = itemsTemplate;

export { itemsTemplate, itemsTemplateMobile, itemsDetailsTemplate };
