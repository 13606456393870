import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';

import { saveTemporaryData } from 'actions/Shipments/saveTemporaryData';

import { snackbarOptions } from 'shared/helpers/matchers/snackbar/snackbarOptions';

import CustomerLotForm from 'pages/Lots/components/LotsManagement/LotForm/CustomerLotForm';

function CustomerCreateLot(props) {
  const dispatch = useDispatch();

  const submit = values =>
    props.createLot(values, null, false).then(res => {
      if (props.location.state?.fromRequestHedgeView) {
        props.history.push('/hedges/create', {
          name: res.grgLotNumber,
          id: res.id,
          statusForGlobal: res.statusForGlobal,
          statusForCustomer: res.statusForCustomer,
          hedge: props.location.state.relatedHedge,
        });
      } else if (props.location.state?.fromCreatePackagesView) {
        dispatch(saveTemporaryData({ createdLot: res }));
        props.history.push('/shipments/packages/create', {
          ...props.location.state,
          assignLot: true,
          name: res.grgLotNumber,
          id: res.id,
        });
      } else if (props.location.state?.fromPackingListView) {
        dispatch(saveTemporaryData({ createdLot: res }));
        props.history.push('/shipments/create-incoming-wizard', {
          ...props.location.state,
          assignLot: true,
        });
      } else if (res.id) {
        props.history.push(`/lots/list/${res.id}`);
      }

      props.showSnackbar(snackbarOptions(res.messageType), res.message);
    });

  return (
    <CustomerLotForm
      {...props}
      onSubmit={submit}
      sentFromCompany={props.auth.user.relatedCompany}
    />
  );
}

CustomerCreateLot.propTypes = {
  auth: PropTypes.shape({
    access: PropTypes.object,
    attempts: PropTypes.number,
    isPending: PropTypes.bool,
    user: PropTypes.object,
  }),
  closeModal: PropTypes.func,
  companyLocations: PropTypes.shape({
    data: PropTypes.array,
    isPending: PropTypes.bool,
  }),
  createLot: PropTypes.func,
  getCompaniesBySearchStr: PropTypes.func,
  getCompanyLocations: PropTypes.func,
  history: PropTypes.object,
  location: PropTypes.object,
  lotCreate: PropTypes.shape({
    isPending: PropTypes.bool,
  }),
  lotsAddFile: PropTypes.func,
  lotsClearAllFiles: PropTypes.func,
  lotsFiles: PropTypes.object,
  lotsRemoveFile: PropTypes.func,
  match: PropTypes.object,
  modal: PropTypes.shape({
    modalOpened: PropTypes.bool,
    modalOptions: PropTypes.object,
    modalCallback: PropTypes.oneOf([null, PropTypes.func]),
  }),
  openModal: PropTypes.func,
  showSnackbar: PropTypes.func,
};

export { CustomerCreateLot };
