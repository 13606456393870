import PropTypes from 'prop-types';
import * as React from 'react';
import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { clearDeliveriesList, getDeliveriesList } from 'actions/Deliveries/deliveriesList';
import { updateHedgeOunces } from 'actions/Hedges/SpecialPermissions/updateHedgeOunces';
import { getHedgeDetails, clearHedgeDetailsAction } from 'actions/Hedges/hedgesDetails';

import { FormContainer } from 'shared/components/Form';
import { Preloader } from 'shared/components/Preloader/Preloader';
import { HEDGES } from 'shared/helpers/constants/hedges/hedgesConstants';
import { safeParseFloat } from 'shared/helpers/parsers/number';

import { UpdateHedgeOuncesForm } from './components/UpdateHedgeOuncesForm';

const UpdateHedgeOunces = ({ match, history }) => {
  const dispatch = useDispatch();
  const hedgesDetails = useSelector(({ hedgesDetails }) => hedgesDetails);
  const updateOunces = useSelector(({ updateOunces }) => updateOunces);
  const deliveriesList = useSelector(({ deliveriesList }) => deliveriesList);

  useEffect(() => {
    dispatch(getHedgeDetails(match.params.id)).catch(() => history.push(`/hedges`));
    dispatch(getDeliveriesList(match.params.id, 0)).catch(() => history.push(`/hedges`));

    return () => {
      dispatch(clearHedgeDetailsAction());
      dispatch(clearDeliveriesList());
    };
  }, [dispatch, history, match]);

  const onSubmit = useCallback(
    ({ palladiumOz, platinumOz, rhodiumOz }) => {
      const parsedValues = {
        platinumOz: safeParseFloat(platinumOz),
        palladiumOz: safeParseFloat(palladiumOz),
        rhodiumOz: safeParseFloat(rhodiumOz),
      };

      return dispatch(updateHedgeOunces(match.params.id, parsedValues)).finally(() =>
        history.push(`/hedges/${match.params.id}`),
      );
    },
    [match.params.id, hedgesDetails],
  );

  const calculateHedgedOunce = (deliveries, field) =>
    deliveries.reduce((acc, h) => acc + h[field], 0);

  const parseDeliveriesOunces = deliveries => {
    if (!deliveries) return;

    return {
      platinumOz: calculateHedgedOunce(deliveries, 'platinumOz'),
      palladiumOz: calculateHedgedOunce(deliveries, 'palladiumOz'),
      rhodiumOz: calculateHedgedOunce(deliveries, 'rhodiumOz'),
    };
  };

  return (
    <>
      <FormContainer
        header={HEDGES.UPDATE_HEDGE_OUNCES}
        loaded={hedgesDetails.hedge && !hedgesDetails.isPending && !deliveriesList.isPending}
      >
        <UpdateHedgeOuncesForm
          onSubmit={onSubmit}
          deliveries={parseDeliveriesOunces(deliveriesList.items?.docs)}
          showHint={hedgesDetails.hedge?.hasAssociatedDeliveries}
        />
      </FormContainer>
      <Preloader loading={updateOunces.isPending} />
    </>
  );
};

UpdateHedgeOunces.propTypes = {
  history: PropTypes.object,
  location: PropTypes.shape({
    hash: PropTypes.string,
    key: PropTypes.string,
    pathname: PropTypes.string,
    search: PropTypes.string,
    state: PropTypes.object,
  }),
  match: PropTypes.shape({
    isExact: PropTypes.bool,
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
    path: PropTypes.string,
    url: PropTypes.string,
  }),
};

export { UpdateHedgeOunces };
