import {
  getFinalInvoiceMaximumOuncesActionType,
  getFinalInvoiceMaximumOuncesSuccessActionType,
  getFinalInvoiceMaximumOuncesFailureActionType,
  clearFinalInvoiceMaximumOuncesActionType,
} from 'actions/Lots/getFinalInvoiceMaximumOunces';

const INITIAL_STATE = {
  isPending: false,
  data: null,
};

const finalInvoiceMaximumOuncesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getFinalInvoiceMaximumOuncesActionType:
      return {
        ...state,
        isPending: true,
      };
    case getFinalInvoiceMaximumOuncesSuccessActionType:
      return {
        data: action.payload.data,
        isPending: false,
      };
    case getFinalInvoiceMaximumOuncesFailureActionType:
      return {
        ...state,
        isPending: false,
      };
    case clearFinalInvoiceMaximumOuncesActionType:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};

export { finalInvoiceMaximumOuncesReducer };
