import React from 'react';

import { StatusIndicator } from 'shared/components/StatusIndicator/StatusIndicator';
import { SELF_DELIVERY_REQUESTS } from 'shared/helpers/constants/selfDeliveryRequests/selfDeliveryRequestsConstants';
import { setDesignation } from 'shared/helpers/dataParsers/lot/setDesignation';
import { dateWithShortTimeFormat } from 'shared/helpers/parsers/date';
import { formatDate } from 'shared/helpers/parsers/formaters';
import { humanize, replaceEmpty } from 'shared/helpers/parsers/text';

import { history } from 'utils/history';

import { SHIPMENTS } from './shipmentsConstants';

const assignedLotsTemplate = {
  label: SHIPMENTS.ASSIGNED_LOTS,
  render: ({ populatedLots }) =>
    populatedLots && populatedLots.length ? (
      <>
        {populatedLots.map(({ grgLotNumber, designation }) => (
          <span key={grgLotNumber}>
            {setDesignation(grgLotNumber, designation)}
            <br />
          </span>
        ))}
      </>
    ) : (
      SHIPMENTS.EMPTY_FIELD
    ),
};

const overviewListsConfig = {
  incomingShipmentsItemsTemplate: [
    {
      label: SHIPMENTS.STATUS,
      render: ({ status }) => <StatusIndicator status={status} />,
    },
    {
      label: SHIPMENTS.SENT_FROM_COMPANY,
      render: ({ sentFromCompany }) => sentFromCompany?.companyName,
    },
    {
      label: SHIPMENTS.EST_DELIVERY_DATE,
      render: ({ estDeliveryDate }) => formatDate(estDeliveryDate),
    },
    assignedLotsTemplate,
    {
      label: SHIPMENTS.PACKAGES,
      render: ({ assignedPackagesCount }) => replaceEmpty(assignedPackagesCount),
    },
  ],
  incomingShipmentsItemsDetailsTemplate: [
    assignedLotsTemplate,
    {
      label: SHIPMENTS.PACKAGES,
      render: ({ assignedPackagesCount }) => replaceEmpty(assignedPackagesCount),
    },
  ],
  outgoingShipmentsItemsTemplate: [
    {
      label: SHIPMENTS.STATUS,
      render: ({ status }) => <StatusIndicator status={status} />,
    },
    {
      label: SHIPMENTS.SHIP_DATE,
      render: ({ estPickupDate }) => formatDate(estPickupDate),
    },
    {
      label: SHIPMENTS.EST_DELIVERY_DATE,
      render: ({ estDeliveryDate }) => formatDate(estDeliveryDate),
    },
    assignedLotsTemplate,
    {
      label: SHIPMENTS.PACKAGES,
      render: ({ assignedPackagesCount }) => replaceEmpty(assignedPackagesCount),
    },
  ],
  outgoingShipmentsItemsDetailsTemplate: [
    assignedLotsTemplate,
    {
      label: SHIPMENTS.PACKAGES,
      render: ({ assignedPackagesCount }) => replaceEmpty(assignedPackagesCount),
    },
  ],
  customerShipmentsItemsTemplate: [
    {
      label: SHIPMENTS.STATUS,
      render: ({ status }) => <StatusIndicator status={status} />,
    },
    {
      label: SHIPMENTS.SENT_FROM_COMPANY_LOCATION,
      render: ({ sentFromCompanyLocation }) => sentFromCompanyLocation.name,
    },
    {
      label: SHIPMENTS.EST_PICKUP_DATE,
      render: ({ estPickupDate }) => formatDate(estPickupDate),
    },
    {
      label: SHIPMENTS.EST_DELIVERY_DATE,
      render: ({ estDeliveryDate }) => formatDate(estDeliveryDate),
    },
    assignedLotsTemplate,
    {
      label: SHIPMENTS.PACKAGES,
      render: ({ assignedPackagesCount }) => replaceEmpty(assignedPackagesCount),
    },
  ],
  customerShipmentsItemsDetailsTemplate: [
    {
      label: SHIPMENTS.EST_DELIVERY_DATE,
      render: ({ estDeliveryDate }) => formatDate(estDeliveryDate),
    },
    assignedLotsTemplate,
    {
      label: SHIPMENTS.PACKAGES,
      render: ({ assignedPackagesCount }) => replaceEmpty(assignedPackagesCount),
    },
  ],
  incomingShipmentsTemplateMobile: [
    {
      label: SHIPMENTS.STATUS,
      render: ({ status }) => <StatusIndicator status={status} />,
    },
    {
      label: SHIPMENTS.SENT_FROM_COMPANY,
      render: ({ sentFromCompany }) => sentFromCompany.companyName,
    },
    {
      label: SHIPMENTS.EST_DELIVERY_DATE,
      render: ({ estDeliveryDate }) => formatDate(estDeliveryDate),
    },
  ],
  outgoingShipmentsTemplateMobile: [
    {
      label: SHIPMENTS.STATUS,
      render: ({ status }) => <StatusIndicator status={status} />,
    },
    {
      label: SHIPMENTS.SHIP_DATE,
      render: ({ estPickupDate }) => formatDate(estPickupDate),
    },
    {
      label: SHIPMENTS.EST_DELIVERY_DATE,
      render: ({ estDeliveryDate }) => formatDate(estDeliveryDate),
    },
  ],
  customerShipmentsTemplateMobile: [
    {
      label: SHIPMENTS.STATUS,
      render: ({ status }) => <StatusIndicator status={status} />,
    },
    {
      label: SHIPMENTS.SENT_FROM_COMPANY_LOCATION,
      render: ({ sentFromCompanyLocation }) => sentFromCompanyLocation.name,
    },
    {
      label: SHIPMENTS.EST_PICKUP_DATE,
      render: ({ estPickupDate }) => formatDate(estPickupDate),
    },
  ],
  shipmentActionsTemplate: type => [
    {
      icon: 'arrow-right',
      action: 'Details',
      onClick: shipment => () => history.push(`shipments/${type}/${shipment._id}`),
    },
  ],
  customerShipmentActionsTemplate: [
    {
      icon: 'arrow-right',
      action: 'Details',
      onClick: shipment => () => history.push(`shipments/${shipment._id}`),
    },
  ],
  sdRequestsItemsTemplate: [
    {
      label: SELF_DELIVERY_REQUESTS.STATUS,
      render: ({ status }) => <StatusIndicator status={status} />,
    },
    {
      label: SELF_DELIVERY_REQUESTS.RELATED_COMPANY,
      render: ({ relatedCompany }) => relatedCompany.companyName,
    },
    {
      label: SELF_DELIVERY_REQUESTS.DELIVERY_DATE,
      render: ({ deliveryDate }) => formatDate(deliveryDate, dateWithShortTimeFormat),
    },
    {
      label: SELF_DELIVERY_REQUESTS.UNIT_COUNT_UNITS,
      render: ({ unitCount }) => unitCount,
    },
    {
      label: SELF_DELIVERY_REQUESTS.FUNDS_TYPE,
      render: ({ fundsType }) => humanize(fundsType),
    },
  ],
  sdRequestsItemsDetailsTemplate: [
    {
      label: SELF_DELIVERY_REQUESTS.RELATED_COMPANY,
      render: ({ relatedCompany }) => relatedCompany.companyName,
    },
    {
      label: SELF_DELIVERY_REQUESTS.UNIT_COUNT_UNITS,
      render: ({ unitCount }) => unitCount,
    },
    {
      label: SELF_DELIVERY_REQUESTS.FUNDS_TYPE,
      render: ({ fundsType }) => humanize(fundsType),
    },
  ],
  customerSdRequestsItemsTemplate: [
    {
      label: SELF_DELIVERY_REQUESTS.STATUS,
      render: ({ status }) => <StatusIndicator status={status} />,
    },
    {
      label: SELF_DELIVERY_REQUESTS.DELIVERY_DATE,
      render: ({ deliveryDate }) => formatDate(deliveryDate, dateWithShortTimeFormat),
    },
    {
      label: SELF_DELIVERY_REQUESTS.UNIT_COUNT_UNITS,
      render: ({ unitCount }) => unitCount,
    },
    {
      label: SELF_DELIVERY_REQUESTS.FUNDS_TYPE,
      render: ({ fundsType }) => humanize(fundsType),
    },
  ],
  customerSdRequestsItemsDetailsTemplate: [
    {
      label: SELF_DELIVERY_REQUESTS.UNIT_COUNT_UNITS,
      render: ({ unitCount }) => unitCount,
    },
    {
      label: SELF_DELIVERY_REQUESTS.FUNDS_TYPE,
      render: ({ fundsType }) => humanize(fundsType),
    },
  ],
  sdRequestsTemplateMobile: [
    {
      label: SELF_DELIVERY_REQUESTS.STATUS,
      render: ({ status }) => <StatusIndicator status={status} />,
    },
    {
      label: SELF_DELIVERY_REQUESTS.DELIVERY_DATE,
      render: ({ deliveryDate }) => formatDate(deliveryDate, dateWithShortTimeFormat),
    },
  ],
  customerSdRequestsTemplateMobile: [
    {
      label: SELF_DELIVERY_REQUESTS.STATUS,
      render: ({ status }) => <StatusIndicator status={status} />,
    },
    {
      label: SELF_DELIVERY_REQUESTS.DELIVERY_DATE,
      render: ({ deliveryDate }) => formatDate(deliveryDate, dateWithShortTimeFormat),
    },
  ],
  sdRequestsActionsTemplate: auth => {
    const actionsTemplate = [
      {
        icon: 'arrow-right',
        action: 'Details',
        onClick: sdRequest => () =>
          history.push(`shipments/self-delivery-requests/${sdRequest._id}`),
      },
    ];

    actionsTemplate.access = function access() {
      return auth.access.selfDeliveryRequests.create;
    };

    return actionsTemplate;
  },
};

export { overviewListsConfig };
