import { connect } from 'react-redux';

import {
  printLotLabel,
  printLotLabelSuccess,
  printLotLabelFailure,
} from 'actions/Lots/printLotLabel';
import { showSnackbar } from 'actions/shared/snackbar';

import { PrintLabel } from 'shared/components/PrintLabel/PrintLabel';

const mapDispatchToProps = {
  printLabels: printLotLabel,
  printLabelsSuccess: printLotLabelSuccess,
  printLabelsFailure: printLotLabelFailure,
  showSnackbar,
};

const ConnectedPrintLotLabel = connect(null, mapDispatchToProps)(PrintLabel);

export { ConnectedPrintLotLabel as PrintLotLabel };
