import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { cssVar, media } from 'shared/helpers/styling/styling';

import { Button } from '../Buttons/Button';
import { Icon } from '../Icon/Icon';
import { ListCollapse } from '../ListCollapse/ListCollapse';

const CollapsableContainerWrapper = styled.div`
  box-sizing: border-box;
  border: 1px solid ${cssVar('riverBed')};
  background-color: ${cssVar('outerSpaceTabs')};

  ${media.desktop`
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  `}

  ${({ isOpen }) =>
    !isOpen &&
    css`
      border-bottom: 1px solid ${cssVar('riverBed')};
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      border: 2px dashed ${cssVar('riverBed')};
      background-color: transparent;
      & > div > div > div:first-of-type {
        opacity: 0.5;
      }
    `}
`;

const CollapsableContainerHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 105px;
  flex-shrink: 0;

  > ${Button} {
    margin-right: ${({ processing }) => (!processing ? '40px' : '10px')};
  }
`;

const CollapsableContainerHandle = styled(ListCollapse)`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  ${({ processing }) =>
    !processing &&
    css`
      padding: 2rem 1rem;
    `}
  ${media.phone`justify-content: center;`}
  ${media.mobile`${Icon} { display: none; }`}
`;

const CollapsableContentWrapper = styled.div`
  background-color: ${cssVar('limedSpruce')};
  box-shadow: 0 0 0 1px ${cssVar('riverBed')};
  color: ${cssVar('alabaster')};
  overflow: hidden;

  ${media.desktop`
    height: 100%;
  `}
`;

const CollapsableContainer = ({
  header,
  children,
  expandable,
  disabled,
  icon,
  actions,
  processing,
}) => {
  const [displaySection, setDisplaySection] = useState(true);
  const sectionToggle = () => expandable && setDisplaySection(!displaySection);

  return (
    <CollapsableContainerWrapper isOpen={displaySection} disabled={disabled}>
      {header && (
        <CollapsableContainerHead processing={processing}>
          <CollapsableContainerHandle
            disabled={!expandable}
            processing={processing}
            collapse={displaySection}
            toggleList={sectionToggle}
            label={header}
            icon={icon}
          />
          {actions && actions()}
        </CollapsableContainerHead>
      )}
      {displaySection && !disabled && (
        <CollapsableContentWrapper>{children}</CollapsableContentWrapper>
      )}
    </CollapsableContainerWrapper>
  );
};

CollapsableContainer.propTypes = {
  header: PropTypes.string,
  expandable: PropTypes.bool,
  actions: PropTypes.func,
  icon: PropTypes.string,
};

export {
  CollapsableContainer,
  CollapsableContainerHandle as CollapsableContainerHeader,
  CollapsableContentWrapper,
  CollapsableContainerWrapper,
};
