import {
  updateHedgeOuncesActionType,
  updateHedgeOuncesFailureActionType,
  updateHedgeOuncesSuccessActionType,
} from 'actions/Hedges/SpecialPermissions/updateHedgeOunces';

const INITIAL_STATE = {
  isPending: false,
};

const updateOuncesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case updateHedgeOuncesActionType:
      return {
        isPending: true,
      };

    case updateHedgeOuncesSuccessActionType:
    case updateHedgeOuncesFailureActionType:
      return {
        isPending: false,
      };

    default:
      return state;
  }
};

export { updateOuncesReducer };
