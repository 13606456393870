import { useSelector } from 'react-redux';
import { Container, Navbar as BSTNavbar, NavbarBrand } from 'reactstrap';

import { Dropdown } from 'components/Dropdown/Dropdown';

import { isTablet } from 'shared/helpers/constants/resolutionsConstants';
import { layout } from 'shared/helpers/constants/versioning/layout';
import { useWindowWidth } from 'shared/hooks/useWindowWidth';

import { history } from 'utils/history';
import { resolveVersion, versions } from 'utils/versioning';

import { NotificationsCounter } from '../NotificationsCounter/ConnectedNotificationsCounter';
import { Sidebar } from '../Sidebar/ConnectedSidebar';
import {
  StyledHeader,
  StyledHeaderContent,
  StyledHeaderLogo,
  StyledHeaderWrapper,
} from './StyledHeader';
import { Navigation } from './components/Navigation';

export const Header = () => {
  const windowWidth = useWindowWidth();

  const auth = useSelector(state => state.auth);
  const notification = useSelector(state => state.notificationDetails.notification);

  const redirectTo = () => {
    history.push('/dashboard');
  };

  const isMobile = windowWidth < isTablet;

  return (
    <StyledHeader className={`${notification ? 'hidden' : ''}`}>
      <StyledHeaderWrapper
        className={`Header-wrapper ${resolveVersion({
          [versions.global]: '',
          [versions.alpha]: '',
          [versions.west]: 'Header-wrapper--west',
        })}`}
      >
        <Container>
          <StyledHeaderContent>
            <BSTNavbar color="faded" light>
              <div>{isMobile && <Sidebar />}</div>
              <div>
                <NavbarBrand onClick={redirectTo}>
                  <StyledHeaderLogo
                    src={isMobile ? layout.logoMobile : layout.logo}
                    className="Header-wrapper-content-logo"
                    alt="Global Refining Group"
                  />
                </NavbarBrand>
              </div>
              <NotificationsCounter />
              {!isMobile && <Dropdown />}
            </BSTNavbar>
          </StyledHeaderContent>
        </Container>
      </StyledHeaderWrapper>
      <Navigation isMobile={isMobile} auth={auth} />
    </StyledHeader>
  );
};
