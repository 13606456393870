import { statusesForGlobal } from 'shared/helpers/constants/lots/statusesForGlobal';
import { isCustomerUser, isGradingUser } from 'shared/helpers/matchers/checkUserType';
import { isGlobalRefiningGroup } from 'shared/helpers/matchers/isGlobalRefiningGroup';

import dateAdapter from 'utils/date/dateAdapter';

const { REACT_APP_BILL_OF_LADING_START_TIME } = process.env;

const isBillOfLadingDateValid = createdAt =>
  dateAdapter(createdAt).isAfter(+REACT_APP_BILL_OF_LADING_START_TIME);

const missingBillofLadingWarningAccess = (
  { statusForGlobal: lotStatusForGlobal, billOfLading, sentFromCompany, createdAt },
  { userType },
) =>
  isBillOfLadingDateValid(createdAt) &&
  !isCustomerUser(userType) &&
  !isGradingUser(userType) &&
  !isGlobalRefiningGroup(sentFromCompany.companyName) &&
  lotStatusForGlobal !== statusesForGlobal.not_received &&
  lotStatusForGlobal !== statusesForGlobal.partly_received &&
  !billOfLading?.length;

export { missingBillofLadingWarningAccess };
