import { companyTypesEnum } from 'shared/helpers/constants/filters/companyTypes';
import { hedgeStatus } from 'shared/helpers/constants/hedges/hedgeStatus';
import { hedgeTypes } from 'shared/helpers/constants/hedges/hedgeType';
import { statusesForGlobal } from 'shared/helpers/constants/lots/statusesForGlobal';
import { isAssayCompany } from 'shared/helpers/matchers/checkCompanyType';
import { isSettlementZero } from 'shared/helpers/matchers/settlements/isSettlementZero';

import { isGlobal } from 'utils/versioning/Guards';

const finalInvoiceStatuses = [
  statusesForGlobal.ship_summary_accepted,
  statusesForGlobal.invoice_accepted,
  statusesForGlobal.invoice_generated,
];

const allowedFinalInvoiceMockupStatuses = [
  statusesForGlobal.ship_summary_accepted,
  statusesForGlobal.ship_summary_generated,
];

const allowedShipmentSummaryStatuses = [
  statusesForGlobal.assay_results_received,
  statusesForGlobal.ship_summary_accepted,
  statusesForGlobal.ship_summary_generated,
];

const generateDocumentAccess =
  allowedStatuses =>
  (
    {
      access: {
        lots: { generateGlobalDocuments },
      },
    },
    lot,
  ) =>
    generateGlobalDocuments &&
    allowedStatuses.includes(lot.statusForGlobal) &&
    isAssayCompany(lot.sentFromCompany.companyType);

const settlementContainsNullPrices = ({ pricePerOuncePt, pricePerOuncePd, pricePerOunceRh }) =>
  pricePerOuncePt === null || pricePerOuncePd === null || pricePerOunceRh === null;

const settlementsAccess = settlements =>
  settlements?.docs &&
  (!settlements.docs.length ||
    !settlements.docs.some(
      settlement => !isSettlementZero(settlement) && settlementContainsNullPrices(settlement),
    ));

const generateFinalInvoiceMockupAccess = generateDocumentAccess(allowedFinalInvoiceMockupStatuses);

const finalInvoiceAccess = generateDocumentAccess(finalInvoiceStatuses);
const generateShipmentSummaryAccess = (auth, lot) =>
  lot.termsApplied && generateDocumentAccess(allowedShipmentSummaryStatuses)(auth, lot);

const generateProfitLossAccess = (
  {
    access: {
      lots: { profitLossManagement },
      specialPermissions: {
        payments: { generateOrApproveProfitLossDocs },
      },
    },
  },
  lot,
) => {
  const { companyType } = lot.sentFromCompany;
  const accessToProfitLoss = isGlobal ? generateOrApproveProfitLossDocs : profitLossManagement;
  const acceptedCompanyTypes = [companyTypesEnum.assay, companyTypesEnum.grading].includes(
    companyType,
  );
  const acceptedStatues = [
    statusesForGlobal.finalized,
    statusesForGlobal.invoice_accepted,
    statusesForGlobal.invoice_generated,
  ].includes(lot.statusForGlobal);

  return acceptedStatues && accessToProfitLoss && acceptedCompanyTypes;
};

const customHedgesContainsNullPrices = ({
  platinumPriceCust,
  palladiumPriceCust,
  rhodiumPriceCust,
}) => platinumPriceCust === null || palladiumPriceCust === null || rhodiumPriceCust === null;

const customHedgeStatusAccess = ({ status }) =>
  [hedgeStatus.placed, hedgeStatus.filled, hedgeStatus.reassigned].includes(status);

const customHedgesPricesAccess = hedges =>
  hedges?.docs &&
  (!hedges.docs.length ||
    !hedges.docs
      .filter(({ hedgeType }) => hedgeType === hedgeTypes.custom)
      .some(hedge => customHedgeStatusAccess(hedge) && customHedgesContainsNullPrices(hedge)));

export {
  generateDocumentAccess,
  generateFinalInvoiceMockupAccess,
  finalInvoiceAccess,
  generateShipmentSummaryAccess,
  generateProfitLossAccess,
  settlementsAccess,
  customHedgesPricesAccess,
};
