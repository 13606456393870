import {
  getShipmentsListFailureActionType,
  getShipmentsListSuccessActionType,
  clearShipmentsListActionType,
  getShipmentsListActionType,
} from 'actions/Shipments/shipmentsList';

const INITIAL_STATE = {
  isPending: false,
  shipments: [],
  limit: 50,
  page: 1,
  pages: null,
};

const ShipmentsListReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getShipmentsListActionType:
      return {
        ...state,
        isPending: true,
      };
    case getShipmentsListSuccessActionType:
      return {
        ...state,
        isPending: false,
        shipments: action.payload.shipments,
        pages: action.payload.pages,
        limit: action.payload.limit,
        page: action.payload.page,
      };
    case getShipmentsListFailureActionType:
      return {
        ...state,
        isPending: false,
        shipments: [],
        pages: null,
      };
    case clearShipmentsListActionType:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};

export { ShipmentsListReducer };
