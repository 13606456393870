const addSettlementWizardItemActionType = 'ADD_SETTLEMENT_WIZARD_ITEM';
const addSettlementWizardItemAction = payload => ({
  type: addSettlementWizardItemActionType,
  payload,
});

const deleteSettlementWizardItemActionType = 'DELETE_SETTLEMENT_WIZARD_ITEM';
const deleteSettlementWizardItemAction = lotId => ({
  type: deleteSettlementWizardItemActionType,
  lotId,
});

const clearSettlementWizardActionType = 'CLEAR_SETTLEMENT_WIZARD';
const clearSettlementWizardAction = () => ({
  type: clearSettlementWizardActionType,
});

export {
  addSettlementWizardItemActionType,
  addSettlementWizardItemAction,
  clearSettlementWizardAction,
  deleteSettlementWizardItemAction,
  deleteSettlementWizardItemActionType,
  clearSettlementWizardActionType,
};
