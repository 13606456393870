import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import {
  getSelfDeliveryRequest,
  clearSelfDeliveryRequestDetails,
} from 'actions/SelfDeliveryRequests/selfDeliveryRequestDetails';
import { clearShipmentsCalendar, getShipmentsCalendar } from 'actions/Shipments/shipmentsCalendar';
import { showSnackbar } from 'actions/shared/snackbar';

import { SelfDeliveryRequestDetails } from './SelfDeliveryRequestDetails';

const mapStateToProps = state => ({
  auth: state.auth,
  selfDeliveryRequestDetails: state.selfDeliveryRequestDetails,
  shipmentsCalendar: state.shipmentsCalendar,
});

const mapDispatchToProps = {
  getSelfDeliveryRequest,
  clearSelfDeliveryRequestDetails,
  clearShipmentsCalendar,
  getShipmentsCalendar,
  showSnackbar,
};

const ConnectedSelfDeliveryRequestDetails = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(props => <SelfDeliveryRequestDetails key={props.match.params.id} {...props} />),
);

export { ConnectedSelfDeliveryRequestDetails };
