import { checkLotSuffix } from 'actions/Locations/checkLotSuffix';

import { messages } from 'shared/helpers/constants/locations/createLocationConstants';

const asyncValidate = (values, dispatch, props) =>
  dispatch(checkLotSuffix(values.lotSuffix, props.companyId)).then(async res =>
    res ? Promise.reject({ lotSuffix: messages.INVALID_LOT_SUFFIX_UNIQUE }) : null,
  );

export { asyncValidate };
