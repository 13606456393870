import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getNotificationsCountActionType = 'GET_NOTIFICATIONS_COUNT';
function getNotificationsCountAction() {
  return {
    type: getNotificationsCountActionType,
  };
}

const setNotificationsCountActionType = 'SET_NOTIFICATIONS_COUNT';
function setNotificationsCountAction(notificationsCount) {
  return {
    type: setNotificationsCountActionType,
    payload: {
      notificationsCount,
    },
  };
}

const getNotificationsCountSuccessActionType = 'GET_NOTIFICATIONS_COUNT_SUCCESS';
function getNotificationsCountSuccessAction(notificationsCount) {
  return {
    type: getNotificationsCountSuccessActionType,
    payload: {
      notificationsCount,
    },
  };
}

const getNotificationsCountFailureActionType = 'GET_NOTIFICATIONS_COUNT_FAILURE';
function getNotificationsCountFailureAction() {
  return {
    type: getNotificationsCountFailureActionType,
  };
}

const clearNotificationsCountActionType = 'CLEAR_NOTIFICATIONS_COUNT';
function clearNotificationsCountAction() {
  return {
    type: clearNotificationsCountActionType,
  };
}

function getNotificationsCount() {
  return dispatch => {
    dispatch(getNotificationsCountAction());

    return axios
      .get(`${API_HOST_AUTH}/notifications/unread`)
      .then(res => dispatch(getNotificationsCountSuccessAction(res.data.count)))
      .catch(() => dispatch(getNotificationsCountFailureAction()));
  };
}

function setNotificationsCount(notificationCount) {
  return dispatch => dispatch(setNotificationsCountAction(notificationCount));
}

function clearNotificationsCount() {
  return dispatch => dispatch(clearNotificationsCountAction());
}

export {
  getNotificationsCountActionType,
  getNotificationsCountSuccessActionType,
  getNotificationsCountFailureActionType,
  setNotificationsCountActionType,
  clearNotificationsCountActionType,
  setNotificationsCount,
  getNotificationsCount,
  clearNotificationsCount,
};
