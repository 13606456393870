import dateAdapter from 'utils/date/dateAdapter';

const resolveFilterDate = timestamp => {
  switch (timestamp) {
    case undefined:
      return undefined;
    default:
      return timestamp
        ? dateAdapter(+timestamp)
            .startOf('day')
            .valueOf()
        : 0;
  }
};

const resolveFilterDateEnd = timestamp => {
  switch (timestamp) {
    case undefined:
      return undefined;
    case Infinity:
      return Infinity;
    default:
      return timestamp
        ? dateAdapter(+timestamp)
            .endOf('day')
            .valueOf()
        : 0;
  }
};

export { resolveFilterDate, resolveFilterDateEnd };
