import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { cssVar, pxToRem, media } from 'shared/helpers/styling/styling';

const HeaderContainer = styled.div`
  background-color: ${cssVar('outerSpaceTabs')};
  border: 1px solid ${cssVar('riverBedDarker')};
  justify-content: space-between;
  min-height: 100px;
  display: flex;
  align-items: center;
  padding: 4px 21px;

  span {
    font-size: ${pxToRem(25)};
    font-weight: 500;
    color: ${cssVar('whiteGRG')};
  }

  ${media.phone`
    padding: 8px;
  `}
`;

const ModalListContainer = ({ header, actions, children }) => (
  <>
    <HeaderContainer>
      <span>{header}</span>
      {actions}
    </HeaderContainer>
    {children}
  </>
);

ModalListContainer.propTypes = {
  header: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  actions: PropTypes.node,
};

export { ModalListContainer };
