import { isDayBankHoliday } from 'shared/helpers/matchers/bankHolidays/isBankHoliday';
import { isWeekend } from 'shared/helpers/matchers/isWeekend';
import { dateToTimestamp } from 'shared/helpers/parsers/date';

import dateAdapter from 'utils/date/dateAdapter';

const parseBankHolidaysData = bankHoliday => ({
  ...bankHoliday,
  date: dateToTimestamp(bankHoliday.date),
});

const getBankHolidaysDates = bankHolidays =>
  bankHolidays.map(({ date }) => dateAdapter(date).localizeTime().getTime());

const isDayWeekendOrBankHoliday = testDay => isDayBankHoliday(testDay) || isWeekend(testDay);

const getFinalPaymentDateMinDate = addWorkingDays => {
  const today = dateAdapter().startOf('d');
  let remainingDays = addWorkingDays;

  if (addWorkingDays) {
    let addDays = 0;
    while (remainingDays > 0 || isDayWeekendOrBankHoliday(today.add(addDays, 'd'))) {
      const testDay = today.add(addDays, 'd');
      addDays++;
      if (!isDayWeekendOrBankHoliday(testDay)) {
        remainingDays--;
      }
    }
    return today.add(addDays, 'd').localizeTime();
  }
  return today.localizeTime();
};

export {
  parseBankHolidaysData,
  getBankHolidaysDates,
  isDayWeekendOrBankHoliday,
  getFinalPaymentDateMinDate,
};
