import PropTypes from 'prop-types';

import { Section } from 'shared/components/Form/Section/Section';
import { NoResults } from 'shared/components/NoResults/NoResults';
import { OverviewList } from 'shared/components/OverviewList/OverviewList';
import { Preloader } from 'shared/components/Preloader/Preloader';
import { DELIVERIES } from 'shared/helpers/constants/deliveries/deliveriesConstants';
import {
  itemsTemplate,
  itemsTemplateMobile,
  itemsDetailsTemplate,
} from 'shared/helpers/constants/deliveries/transferDeliveriesTableConfig';
import { statusesForGlobal } from 'shared/helpers/constants/lots/statusesForGlobal';
import { isTablet } from 'shared/helpers/constants/resolutionsConstants';
import { useWindowWidth } from 'shared/hooks/useWindowWidth';

const TransferDeliveriesFormContent = ({ deliveries = [], isPending }) => {
  const windowWidth = useWindowWidth();

  const restrictedLotStatuses = [
    statusesForGlobal.finalized,
    statusesForGlobal.invoice_accepted,
    statusesForGlobal.invoice_generated,
  ];

  const parseDeliveries = deliveries =>
    deliveries.map(delivery => ({
      ...delivery,
      isDisabled: restrictedLotStatuses.includes(delivery.relatedLot.statusForGlobal),
    }));

  return (
    <>
      <Section template={['transferDeliveries transferDeliveries']} noPadding topBorder>
        <OverviewList
          input={{
            items: { docs: parseDeliveries(deliveries) },
            isPending,
          }}
          dashboard
          itemsTemplate={itemsTemplate}
          itemsTemplateMobile={itemsTemplateMobile}
          itemDetailsTemplate={itemsDetailsTemplate}
          actionsTemplate={[]}
          isMobile={windowWidth < isTablet}
          label={DELIVERIES.DELIVERIES}
          name="transferDeliveries"
          customColor="limedSpruceForm"
        />
        {deliveries.length === 0 && (
          <NoResults label={DELIVERIES.DELIVERIES} name="transferDeliveries" />
        )}
      </Section>
      <Preloader loading={isPending} />
    </>
  );
};

TransferDeliveriesFormContent.propTypes = {
  deliveries: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      createdAt: PropTypes.number,
      createdBy: PropTypes.object,
      palladiumOz: PropTypes.number,
      platinumOz: PropTypes.number,
      rhodiumOz: PropTypes.number,
      relatedHedge: PropTypes.object,
      relatedLot: PropTypes.object,
    }),
  ).isRequired,
  isPending: PropTypes.bool,
};

export { TransferDeliveriesFormContent };
