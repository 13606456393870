import styled from 'styled-components';

import { Button } from 'shared/components/Buttons/Button';
import { media } from 'shared/helpers/styling/styling';

import { Section } from '../Section/Section';

export const StyledActionsPortal = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  grid-gap: 10px;
  align-content: start;

  #showExistingPayments {
    grid-column-start: span 2;
  }

  ${Button} {
    color: white;
  }

  &:not(:empty) ~ ${Button} {
    display: none;
  }
`;

export const StyledSection = styled(Section)`
  .container {
    div {
      ${media.tablet`
        flex-direction: column;
    `}
    }
  }
`;
