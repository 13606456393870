import {
  lotInspectionActionType,
  lotInspectionSuccessActionType,
  lotInspectionFailureActionType,
} from 'actions/Lots/lotInspections';

const INITIAL_STATE = {
  isPending: false,
};

const LotInspectionsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case lotInspectionActionType:
      return {
        isPending: true,
      };
    case lotInspectionSuccessActionType:
    case lotInspectionFailureActionType:
      return {
        isPending: false,
      };
    default:
      return state;
  }
};

export { LotInspectionsReducer };
