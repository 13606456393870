import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import { FieldDate } from 'shared/components/Fields/FieldDate/FieldDate';
import { FieldInput } from 'shared/components/Fields/FieldInput/FieldInput';
import { FieldRadioButton } from 'shared/components/Fields/FieldRadioButton/FieldRadioButton';
import { Form, Section } from 'shared/components/Form';
import { termTypes } from 'shared/helpers/constants/filters/termTypes';
import { cancelModal } from 'shared/helpers/constants/modalConstants';
import { TERMS } from 'shared/helpers/constants/terms/termsConstants';
import { normalize } from 'shared/helpers/parsers/boolean';
import { validate } from 'shared/helpers/validations/term/termValidations';

import dateAdapter from 'utils/date/dateAdapter';
import { goBackOrTo } from 'utils/history';

class CreateTermForm extends Component {
  componentDidMount() {
    const { defaultValues, termsDetails } = this.props;
    this.populateValues(termsDetails.terms || defaultValues);
  }

  onCancel = () => {
    this.props.openModal(cancelModal, () => {
      goBackOrTo(`/companies/${this.props.companyId}`);
    });
  };

  setField = (key, value) => {
    this.props.change(key, value);
  };

  getTreatmentChargeLabel = () => {
    const { termsDetails, type } = this.props;
    const { terms } = termsDetails;

    if (type === termTypes.foil || (terms && terms.materialType === termTypes.foil)) {
      return TERMS.TREATMENT_CHARGE_FOIL;
    }
    return TERMS.TREATMENT_CHARGE_O2SENSOR;
  };

  populateValues = term => {
    const { signedTerms, auth } = this.props;

    const digitalSignatureEnabled = auth?.access?.companies?.details?.terms?.digitalSignature;

    this.setField('treatmentChargePerPound', term.treatmentChargePerPound);
    this.setField('advanceRate', signedTerms.value || term.advanceRate);
    this.setField('smallLotCharge', term.smallLotCharge);
    this.setField('largeLotCharge', term.largeLotCharge);
    this.setField('startDate', dateAdapter(term.startDate));
    this.setField('endDate', dateAdapter(term.endDate));
    this.setField('platinumReturnRate', term.platinumReturnRate);
    this.setField('platinumRefiningCharge', term.platinumRefiningCharge);
    this.setField('palladiumReturnRate', term.palladiumReturnRate);
    this.setField('palladiumRefiningCharge', term.palladiumRefiningCharge);
    this.setField('metalsReturnTermInDays', term.metalsReturnTermInDays);
    this.setField('metalsReturnFinanceRate', term.metalsReturnFinanceRate);
    this.setField('digitalSignatureRequired', digitalSignatureEnabled && term.digitalSignatureRequired);

    if (term.materialType === termTypes.foil || this.props.type === termTypes.foil) {
      this.setField('rhodiumReturnRate', term.rhodiumReturnRate);
      this.setField('rhodiumRefiningCharge', term.rhodiumRefiningCharge);
    }

    if (term.startDate) {
      this.props.touch('startDate');
    }

    if (term.endDate) {
      this.props.touch('endDate');
    }
  };

  render() {
    const { handleSubmit, resources, signedTerms } = this.props;

    return (
      <Form
        header={TERMS.TERM}
        onSubmit={handleSubmit}
        onCancel={this.onCancel}
        submitDisabled={this.props.invalid}
      >
        <Section
          template={['treatmentChargePerPound advanceRate', 'smallLotCharge largeLotCharge']}
        >
          <Field
            name="treatmentChargePerPound"
            component={FieldInput}
            type="text"
            label={this.getTreatmentChargeLabel()}
            field="required"
            prefix="$"
          />
          <Field
            name="advanceRate"
            component={FieldInput}
            type="text"
            label={TERMS.ADVANCE_RATE}
            field="required"
            suffix="%"
            disabled={signedTerms.value !== null}
          />
          <Field
            name="smallLotCharge"
            component={FieldInput}
            type="text"
            label={TERMS.SMALL_LOT_CHARGE}
            field="required"
            prefix="$"
          />
          <Field
            name="largeLotCharge"
            component={FieldInput}
            type="text"
            label={TERMS.LARGE_LOT_CHARGE}
            field="required"
            prefix="$"
          />
        </Section>
        <Section header={TERMS.DATES} template={['startDate endDate']}>
          <Field
            name="startDate"
            component={FieldDate}
            label={TERMS.START_DATE}
            field="required"
            minDate={dateAdapter()}
          />
          <Field
            name="endDate"
            component={FieldDate}
            label={TERMS.END_DATE}
            field="required"
            minDate={dateAdapter().add(1, 'd')}
          />
        </Section>
        {resources.map(({ name, title }) => (
          <Section key={name} header={title} template={[`${name}ReturnRate ${name}RefiningCharge`]}>
            <Field
              name={`${name}ReturnRate`}
              type="text"
              component={FieldInput}
              label={TERMS[`${name.toUpperCase()}_RETURN_RATE`]}
              icon="icon-cart"
              field="required"
              suffix="%"
            />
            <Field
              name={`${name}RefiningCharge`}
              type="text"
              component={FieldInput}
              label={TERMS[`${name.toUpperCase()}_REFINING_CHARGE`]}
              icon="icon-cart"
              field="required"
              prefix="$"
            />
          </Section>
        ))}
        <Section
          header={TERMS.RETURN_RATES}
          template={['metalsReturnTermInDays metalsReturnFinanceRate']}
        >
          <Field
            name="metalsReturnTermInDays"
            component={FieldInput}
            type="number"
            label={TERMS.METALS_RETURN_TERMIN_DAYS}
            field="required"
          />
          <Field
            name="metalsReturnFinanceRate"
            component={FieldInput}
            type="text"
            label={TERMS.METALS_RETURN_FINANCE_RATE}
            field="required"
            suffix="%"
          />
        </Section>
        <Section header={TERMS.DIGITAL_SIGNATURE} template={['digitalSignatureRequired .']}>
          <Field
            name="digitalSignatureRequired"
            component={FieldRadioButton}
            label={TERMS.DIGITAL_SIGNATURE_REQUIRED}
            field="required"
            normalize={normalize}
            disabled={!this.props.auth?.access?.companies?.details?.terms?.digitalSignature}
          />
        </Section>
      </Form>
    );
  }
}

const mapStateToProps = state => ({
  companyId: state.companyDetails.company && state.companyDetails.company._id,
});

const ConnectedTermChargedForm = reduxForm({
  form: 'TermForm',
  initialValues: {
    digitalSignatureRequired: false,
  },
  validate,
})(connect(mapStateToProps)(CreateTermForm));

export { ConnectedTermChargedForm };
