import axios from 'axios';

import { showSnackbar } from 'actions/shared/snackbar';

import {
  dangerOptions,
  successOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

import { history } from 'utils/history';

const API_HOST_AUTH = process.env.REACT_APP_API;

const updateVirtualLotActionType = 'UPDATE_VIRTUAL_LOT';
const updateVirtualLotAction = () => ({
  type: updateVirtualLotActionType,
});

const updateVirtualLotWeightActionType = `${updateVirtualLotActionType}_WEIGHT`;
const updateVirtualLotWeightAction = newVirtualLotData => ({
  type: updateVirtualLotWeightActionType,
  payload: newVirtualLotData,
});

const updateVirtualLotSuccessActionType = `${updateVirtualLotActionType}_SUCCESS`;
const updateVirtualLotSuccessAction = () => ({
  type: updateVirtualLotSuccessActionType,
});

const updateVirtualLotFailureActionType = `${updateVirtualLotActionType}_FAILURE`;
const updateVirtualLotFailureAction = () => ({
  type: updateVirtualLotFailureActionType,
});

const clearUpdateVirtualLotActionType = `CLEAR_${updateVirtualLotActionType}`;
const clearUpdateVirtualLotAction = () => ({
  type: clearUpdateVirtualLotActionType,
});

const updateVirtualLot = lot => dispatch => {
  dispatch(updateVirtualLotAction());

  return axios({
    method: 'patch',
    url: `${API_HOST_AUTH}/virtual-lots`,
    headers: { 'Content-Type': 'application/json' },
    data: lot,
  })
    .then(({ data }) => {
      dispatch(updateVirtualLotSuccessAction());

      dispatch(showSnackbar(successOptions, data.message));
      history.push('/lots');
    })
    .catch(({ data }) => {
      dispatch(updateVirtualLotFailureAction());

      dispatch(showSnackbar(dangerOptions, data.message));
    });
};

const updateVirtualLotWeight = newVirtualLotData => dispatch => {
  dispatch(updateVirtualLotWeightAction(newVirtualLotData));
};

export {
  updateVirtualLotActionType,
  updateVirtualLotAction,
  updateVirtualLotWeightAction,
  updateVirtualLotWeightActionType,
  updateVirtualLotSuccessActionType,
  updateVirtualLotSuccessAction,
  updateVirtualLotFailureActionType,
  updateVirtualLotFailureAction,
  clearUpdateVirtualLotActionType,
  clearUpdateVirtualLotAction,
  updateVirtualLotWeight,
  updateVirtualLot,
};
