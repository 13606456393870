import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { clearLotDetails, getLot } from 'actions/Lots/lotDetails';
import { lotsClearAllFiles } from 'actions/Lots/lotsAddFiles';
import { updateLot } from 'actions/Lots/updateLot';
import { updateLotLocation } from 'actions/Lots/updateLotLocation';
import { openModal, closeModal } from 'actions/shared/modal';
import { showSnackbar } from 'actions/shared/snackbar';

import { UpdateLotLocation } from './UpdateLotLocation';

const mapStateToProps = state => ({
  auth: state.auth,
  modal: state.modal,
  lotsFiles: state.lotsFiles,
  lotDetails: state.lotDetails,
  lotLocationUpdate: state.updateLotLocation,
});

const mapDispatchToProps = {
  updateLot,
  updateLotLocation,
  lotsClearAllFiles,
  getLot,
  showSnackbar,
  clearLotDetails,
  openModal,
  closeModal,
};

const ConnectedUpdateLotLocation = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UpdateLotLocation),
);

export { ConnectedUpdateLotLocation };
