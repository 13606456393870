import {
  updateShipmentActionType,
  updateShipmentSuccessActionType,
  updateShipmentFailureActionType,
} from 'actions/Shipments/updateShipment';

const INITIAL_STATE = {
  isPending: false,
};

const UpdateShipmentReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case updateShipmentActionType:
      return {
        isPending: true,
      };
    case updateShipmentFailureActionType:
    case updateShipmentSuccessActionType:
      return {
        isPending: false,
      };
    default:
      return state;
  }
};

export { UpdateShipmentReducer };
