import React, { Fragment, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { openModal } from 'actions/shared/modal';

import { AddButton } from 'shared/components/Buttons/AddButton/AddButton';
import { Separator } from 'shared/components/Form';
import { removeUserModal } from 'shared/helpers/constants/modalConstants';
import { USER } from 'shared/helpers/constants/users/userConstants';

import { AddUser } from './AddUser';

function UsersList(props) {
  const { fields, invalid, change } = props;
  const dispatch = useDispatch();

  const addField = useCallback(() => fields.push({}), [fields]);

  const removeField = useCallback(
    index => {
      dispatch(openModal(removeUserModal, () => fields.remove(index)));
    },
    [fields, dispatch],
  );

  return (
    <>
      {fields.map((user, index) => (
        <Fragment key={index}>
          <AddUser
            index={index}
            user={user}
            change={change}
            onRemove={!!index && (() => removeField(index))}
          />
          <Separator />
        </Fragment>
      ))}
      <AddButton onClick={addField} disabled={invalid}>
        {USER.ADD_NEW_USER}
      </AddButton>
    </>
  );
}

export { UsersList };
