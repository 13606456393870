import React, { Fragment } from 'react';

import { AddButton } from 'shared/components/Buttons/AddButton/AddButton';
import { Separator } from 'shared/components/Form';
import { ENTER_SETTLED_OUNCES } from 'shared/helpers/constants/settlements/settlementsConstants';

import { EnterSettledOunceForm } from '../EnterSettledOunceForm/EnterSettledOunceForm';

const EnterSettledOuncesFormList = ({ fields, invalid, ...props }) => {
  const addSettledOunce = () => fields.push({ formId: Date.now() });

  const removeSettledOunce = index => fields.remove(index);

  return (
    <>
      {fields.map((settledOunce, index) => (
        <Fragment key={settledOunce}>
          <EnterSettledOunceForm
            {...props}
            settledOunce={settledOunce}
            index={index}
            header={`${ENTER_SETTLED_OUNCES.SETTLEMENT} ${index + 1}`}
            onRemove={fields.length > 1 && (() => removeSettledOunce(index))}
          />
          <Separator />
        </Fragment>
      ))}
      {fields.length < 10 && (
        <AddButton onClick={addSettledOunce} disabled={invalid}>
          {ENTER_SETTLED_OUNCES.ADD}
        </AddButton>
      )}
    </>
  );
};

export { EnterSettledOuncesFormList };
