import PropTypes from 'prop-types';
import { memo } from 'react';
import styled from 'styled-components';

import { StepIndicator } from 'shared/components/StepIndicator/StepIndicator';
import { media } from 'shared/helpers/styling/styling';

const StepIndicatorStyled = styled(StepIndicator)`
  margin: 0 auto 0 150px;

  ${media.mobile`
    margin: 30px 0;
  `}
`;

const WizardIndicator = memo(({ current, steps, actionButtons }) => (
  <>
    <StepIndicatorStyled current={current} steps={steps} />
    {actionButtons?.()}
  </>
));

WizardIndicator.propTypes = {
  current: PropTypes.number.isRequired,
  steps: PropTypes.number.isRequired,
  actionButtons: PropTypes.func,
};

export { WizardIndicator };
