import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { parseAttachment } from 'actions/Attachments/parseAttachment';
import { clearLotDetails, getLot } from 'actions/Lots/lotDetails';
import {
  lotsAddFile,
  lotsRemoveFile,
  lotsRemoveMultipleFiles,
  lotsClearAllFiles,
  lotsAddMultipleDocuments,
  lotsParseUploadedMultipleFiles,
} from 'actions/Lots/lotsAddFiles';
import { updateLot } from 'actions/Lots/updateLot';
import { openModal, closeModal } from 'actions/shared/modal';
import { showSnackbar } from 'actions/shared/snackbar';

import { UpdateLot } from './UpdateLot';

const mapStateToProps = state => ({
  auth: state.auth,
  router: state.router,
  modal: state.modal,
  lotsFiles: state.lotsFiles,
  lotDetails: state.lotDetails,
  lotUpdate: state.updateLot,
  parseAttachmentStorage: state.parseAttachmentStorage,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateLot,
      lotsAddFile,
      lotsAddMultipleDocuments,
      lotsRemoveFile,
      lotsRemoveMultipleFiles,
      lotsClearAllFiles,
      getLot,
      showSnackbar,
      clearLotDetails,
      openModal,
      closeModal,
      lotsParseUploadedMultipleFiles,
      parseAttachment,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateLot);
