import PropTypes from 'prop-types';
import React from 'react';

import { replaceEmpty } from 'shared/helpers/parsers/text';

import dateAdapter from 'utils/date/dateAdapter';

function formatRatingRate(term) {
  const platinum = replaceEmpty(term.platinumReturnRate);
  const palladium = replaceEmpty(term.palladiumReturnRate);
  const rhodium = replaceEmpty(term.rhodiumReturnRate);

  return term.materialType === 'o2sensor'
    ? `${platinum}%-${palladium}%`
    : `${platinum}%-${palladium}%-${rhodium}%`;
}

function formatTermInDays(term) {
  const platinum = replaceEmpty(term.platinumReturnTermInDays);
  const palladium = replaceEmpty(term.palladiumReturnTermInDays);
  const rhodium = replaceEmpty(term.rhodiumReturnTermInDays);

  return term.materialType === 'o2sensor'
    ? `${platinum}d/${palladium}d`
    : `${platinum}d/${palladium}d/${rhodium}d`;
}

function formatFinanceRate(term) {
  const platinum = replaceEmpty(term.platinumReturnFinanceRate);
  const palladium = replaceEmpty(term.palladiumReturnFinanceRate);
  const rhodium = replaceEmpty(term.rhodiumReturnFinanceRate);

  return term.materialType === 'o2sensor'
    ? `${platinum}%-${palladium}%`
    : `${platinum}%-${palladium}%-${rhodium}%`;
}

const calculateTermsDetailsDate = lot => {
  const valueToAdd = lot.sentFromCompany.materialCountRequired
    ? 30
    : lot.termsApplied.metalsReturnTermInDays;
  return dateAdapter(lot.receivedAt).add(valueToAdd, 'd').format('MM/DD/YYYY');
};

function termsAbbrDetailsString(lot) {
  const { termsApplied } = lot;

  const treatmentChargePerPound = replaceEmpty(termsApplied.treatmentChargePerPound);
  const advanceRate = replaceEmpty(termsApplied.advanceRate);

  return `${formatRatingRate(termsApplied)}, TC $ ${treatmentChargePerPound}/lb,
          ${formatTermInDays(termsApplied)} @ ${formatFinanceRate(
    termsApplied,
  )}, ADV ${advanceRate}%, Value Date: ${calculateTermsDetailsDate(lot)}`;
}

function TermsAbbrDetails({ term }) {
  const treatmentChargePerPound = replaceEmpty(term.treatmentChargePerPound);
  const advanceRate = replaceEmpty(term.advanceRate);

  return (
    <div>
      <div>{formatRatingRate(term)}</div>
      <div>{`TC $ ${treatmentChargePerPound}/lb ${formatTermInDays(term)}`}</div>
      <div>{`@ ${formatFinanceRate(term)} ADV ${advanceRate}%`}</div>
    </div>
  );
}

TermsAbbrDetails.propTypes = {
  term: PropTypes.shape({
    advanceRate: PropTypes.number.isRequired,
    treatmentChargePerPound: PropTypes.number.isRequired,
    palladiumReturnFinanceRate: PropTypes.number.isRequired,
    palladiumReturnRate: PropTypes.number.isRequired,
    palladiumReturnTermInDays: PropTypes.number.isRequired,
    platinumReturnFinanceRate: PropTypes.number.isRequired,
    platinumReturnRate: PropTypes.number.isRequired,
    platinumReturnTermInDays: PropTypes.number.isRequired,
    rhodiumReturnFinanceRate: PropTypes.number,
    rhodiumReturnRate: PropTypes.number,
    rhodiumReturnTermInDays: PropTypes.number,
  }).isRequired,
};

export {
  TermsAbbrDetails,
  termsAbbrDetailsString,
  calculateTermsDetailsDate,
  formatRatingRate,
  formatTermInDays,
  formatFinanceRate,
};
