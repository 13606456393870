import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getSettlementDetailsType = 'GET_SETTLEMENT_DETAILS';
const getSettlementDetailsAction = () => ({
  type: getSettlementDetailsType,
});

const getSettlementDetailsSuccessType = `${getSettlementDetailsType}_SUCCESS`;
const getSettlementDetailsSuccessAction = settlement => ({
  type: getSettlementDetailsSuccessType,
  payload: { settlement },
});

const getSettlementDetailsFailureType = `${getSettlementDetailsType}_FAILURE`;
const getSettlementDetailsFailureAction = () => ({
  type: getSettlementDetailsFailureType,
});

const clearSettlementDetailsType = 'CLEAR_SETTLEMENT_DETAILS';
const clearSettlementDetails = () => ({
  type: clearSettlementDetailsType,
});

const getSettlementDetails = settlementId => dispatch => {
  dispatch(getSettlementDetailsAction());

  return axios
    .get(`${API_HOST_AUTH}/settlements/${settlementId}`)
    .then(res => {
      dispatch(getSettlementDetailsSuccessAction(res.data));

      return res.data;
    })
    .catch(err => {
      dispatch(getSettlementDetailsFailureAction());

      return Promise.reject(err.data);
    });
};

export {
  getSettlementDetailsType,
  getSettlementDetailsSuccessType,
  getSettlementDetailsFailureType,
  clearSettlementDetailsType,
  getSettlementDetails,
  clearSettlementDetails,
};
