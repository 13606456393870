import PropTypes from 'prop-types';
import * as React from 'react';

import { LogsActionItemWrapper, LogsActionItem } from '../SpecialPermissionsLogsStyles';

const LogsItem = ({ withoutBorderLeft, title, children }) => (
  <LogsActionItemWrapper withoutBorderLeft={withoutBorderLeft}>
    {title && <LogsActionItem>{title}</LogsActionItem>}
    {children}
  </LogsActionItemWrapper>
);

LogsItem.propTypes = {
  withoutBorderLeft: PropTypes.bool,
  title: PropTypes.string,
};

export { LogsItem };
