import {
  updateNoteActionType,
  updateNoteFailureActionType,
  updateNoteSuccessActionType,
} from 'actions/Notes/updateNote';

const INITIAL_STATE = {
  isPending: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case updateNoteActionType:
      return {
        isPending: true,
      };
    case updateNoteSuccessActionType:
    case updateNoteFailureActionType:
      return {
        isPending: false,
      };
    default:
      return state;
  }
};
