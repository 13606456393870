const stillwaterFilesAccess = lot => 'stillwaterShipmentSummary' in lot;

const globalFilesAccess = lot => 'grgShipmentSummary' in lot;

const hedgeOrderDocumentsAccess = lot => 'hedgeOrderDocuments' in lot;

const poolSettlementDocumentAccess = lot => 'poolSettlementDocument' in lot;

export {
  stillwaterFilesAccess,
  globalFilesAccess,
  hedgeOrderDocumentsAccess,
  poolSettlementDocumentAccess,
};
