import PropTypes from 'prop-types';
import React from 'react';

import { DetailsField } from '../DetailsField';
import { DETAILS_CONSTANTS } from '../constants/detailsConstants';

const parseFieldValue = value =>
  value !== null && (value ? DETAILS_CONSTANTS.SET_TO_TRUE : DETAILS_CONSTANTS.SET_TO_FALSE);

const DetailsRadioField = ({ value, ...props }) => (
  <DetailsField {...props} value={parseFieldValue(value)} />
);

DetailsRadioField.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
};

export { DetailsRadioField };
