import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { GroupButtons } from 'shared/components/Buttons';
import { GroupButton } from 'shared/components/Buttons/GroupButton/components/GroupButton';
import { OverviewHeader } from 'shared/components/OverviewHeader';
import { REPORTS } from 'shared/helpers/constants/reports/reportsConstants';

import { StyledSectionActionButtons } from './StyledAcrionButtons';

const ClientLotReport = () => {
  const history = useHistory();
  const sectionActionButtons = (
    <StyledSectionActionButtons>
      <GroupButtons>
        <GroupButton
          access={true}
          onClick={() => history.push('/reports/client-lot-report/create')}
          label={REPORTS.GENERATE}
          icon="add"
        />
      </GroupButtons>
    </StyledSectionActionButtons>
  );

  return (
    <OverviewHeader header={REPORTS.GENERATE_CLIENT_LOT_REPORT} actions={sectionActionButtons} />
  );
};
export { ClientLotReport };
