import React from 'react';

import { StatusIndicator } from 'shared/components/StatusIndicator/StatusIndicator';
import { TermsAbbrDetails } from 'shared/components/TermsAbbrDetails/TermsAbbrDetails';
import { formatDate } from 'shared/helpers/parsers/formaters';
import { replaceEmpty, humanize } from 'shared/helpers/parsers/text';

import { checkStartDateTerm } from 'utils/checkStartDateTerm';
import { history } from 'utils/history';
import { resolveVersion, versions } from 'utils/versioning';

import { AGREEMENTS } from '../agreements/agreementsConstants';
import { LOCATION } from '../locations/locationConstants';
import { TERMS } from '../terms/termsConstants';
import { USER } from '../users/userConstants';

const displayAccessLevel = resolveVersion({
  [versions.alpha]: true,
  [versions.global]: true,
  [versions.west]: true,
});

const userItemTableTemplate = [
  {
    label: USER.STATUS,
    render: ({ status }) => <StatusIndicator status={status} />,
  },
  {
    label: USER.USER_TYPE,
    render: ({ userType }) => humanize(userType),
  },
  displayAccessLevel && {
    label: USER.ACCESS_LEVEL,
    render: ({ accessLevel }) => humanize(accessLevel),
  },
  {
    label: USER.FIRST_NAME,
    render: ({ firstName }) => firstName,
  },
  {
    label: USER.LAST_NAME,
    render: ({ lastName }) => lastName,
  },
  {
    label: USER.TITLE,
    render: ({ title }) => replaceEmpty(title),
  },
].filter(Boolean);

const userItemMobileTemplate = [
  {
    label: USER.STATUS,
    render: ({ status }) => <StatusIndicator status={status} />,
  },
  {
    label: USER.PERSON,
    render: ({ firstName, lastName }) => `${firstName} ${lastName}`,
  },
  {
    label: USER.USER_TYPE,
    render: ({ userType }) => humanize(userType),
  },
];

const userDetailsTableTemplate = [
  displayAccessLevel && {
    label: USER.ACCESS_LEVEL,
    render: ({ accessLevel }) => humanize(accessLevel),
  },
  {
    label: USER.TITLE,
    render: ({ title }) => replaceEmpty(title),
  },
].filter(Boolean);

const userActionsTemplate = [
  {
    icon: 'arrow-right',
    action: 'Details',
    onClick:
      ({ _id, relatedCompany }) =>
      () =>
        history.push(`/companies/${relatedCompany._id}/users/${_id}`),
  },
];

const agreementItemTableTemplate = [
  {
    label: AGREEMENTS.STATUS,
    render: ({ status }) => <StatusIndicator status={status} />,
  },
  {
    label: AGREEMENTS.START_DATE,
    render: ({ startDate }) => replaceEmpty(formatDate(startDate)),
  },
  {
    label: AGREEMENTS.ACTUAL_EXPIRATION_DATE,
    render: ({ actualExpirationDate }) => replaceEmpty(formatDate(actualExpirationDate)),
  },
];

const agreementItemMobileTemplate = [
  {
    label: AGREEMENTS.STATUS,
    render: ({ status }) => <StatusIndicator status={status} />,
  },
  {
    label: AGREEMENTS.START_DATE,
    render: ({ startDate }) => replaceEmpty(formatDate(startDate)),
  },
];

const [, , ...agreementDetailsTableTemplate] = agreementItemTableTemplate;

const agreementActionsTemplate = [
  {
    icon: 'arrow-right',
    action: 'Details',
    onClick:
      ({ _id }) =>
      () =>
        history.push(`${history.location.pathname}/agreements/${_id}`),
  },
];

const termItemTableTemplate = [
  {
    label: TERMS.STATUS,
    render: ({ status, startDate }) => (
      <StatusIndicator status={checkStartDateTerm(status, startDate)} />
    ),
  },
  {
    label: TERMS.MATERIAL_TYPE,
    render: ({ materialType }) => humanize(materialType),
  },
  {
    label: TERMS.START_DATE,
    render: ({ startDate }) => replaceEmpty(formatDate(startDate)),
  },
  {
    label: TERMS.END_DATE,
    render: ({ endDate }) => replaceEmpty(formatDate(endDate)),
  },
  {
    label: TERMS.DETAILS,
    render: term => <TermsAbbrDetails term={term} />,
  },
];

const termItemMobileTemplate = [
  {
    label: TERMS.STATUS,
    render: ({ status, startDate }) => (
      <StatusIndicator status={checkStartDateTerm(status, startDate)} />
    ),
  },
  {
    label: TERMS.MATERIAL_TYPE,
    render: ({ materialType }) => humanize(materialType),
  },
];

const [, , ...termDetailsTableTemplate] = termItemTableTemplate;

const termActionsTemplate = [
  {
    icon: 'arrow-right',
    action: 'Details',
    onClick:
      ({ _id }) =>
      () =>
        history.push(`${history.location.pathname}/terms/${_id}`),
  },
];

const locationItemTableTemplate = [
  {
    label: LOCATION.STATUS,
    render: ({ status }) => <StatusIndicator status={status} />,
  },
  {
    label: LOCATION.NAME,
    render: ({ name }) => name,
  },
  {
    label: LOCATION.COUNTRY,
    render: ({ country }) => country,
  },
  {
    label: LOCATION.STATE,
    render: ({ state }) => replaceEmpty(state),
  },
  {
    label: LOCATION.CITY,
    render: ({ city }) => replaceEmpty(city),
  },
  {
    label: LOCATION.LOT_SUFFIX,
    render: ({ lotSuffix }) => lotSuffix,
  },
  {
    label: LOCATION.SHIPMENT_ELIGIBLE,
    render: ({ shipmentEligible }) => {
      if (shipmentEligible) return 'Yes';
      return 'No';
    },
  },
  {
    label: LOCATION.PRIMARY_LOCATION,
    render: ({ primaryLocation }) => {
      if (primaryLocation) return 'Yes';
      return 'No';
    },
  },
];

const locationItemMobileTemplate = [
  {
    label: LOCATION.STATUS,
    render: ({ status }) => <StatusIndicator status={status} />,
  },
  {
    label: LOCATION.CITY,
    render: ({ city }) => city,
  },
  {
    label: LOCATION.LOT_SUFFIX,
    render: ({ lotSuffix }) => lotSuffix,
  },
];

const locationDetailsTableTemplate = [
  {
    label: LOCATION.NAME,
    render: ({ name }) => name,
  },
  {
    label: LOCATION.COUNTRY,
    render: ({ country }) => country,
  },
  {
    label: LOCATION.STATE,
    render: ({ state }) => replaceEmpty(state),
  },
  {
    label: LOCATION.SHIPMENT_ELIGIBLE,
    render: ({ shipmentEligible }) => {
      if (shipmentEligible) return 'Yes';
      return 'No';
    },
  },
  {
    label: LOCATION.PRIMARY_LOCATION,
    render: ({ primaryLocation }) => {
      if (primaryLocation) return 'Yes';
      return 'No';
    },
  },
];

const locationActionsTemplate = () => [
  {
    icon: 'arrow-right',
    action: 'Details',
    onClick:
      ({ _id }) =>
      () =>
        history.push(`${history.location.pathname}/locations/${_id}`),
  },
];

export {
  agreementActionsTemplate,
  agreementItemMobileTemplate,
  agreementDetailsTableTemplate,
  agreementItemTableTemplate,
  locationActionsTemplate,
  locationItemMobileTemplate,
  locationDetailsTableTemplate,
  locationItemTableTemplate,
  termActionsTemplate,
  termItemMobileTemplate,
  termDetailsTableTemplate,
  termItemTableTemplate,
  userItemTableTemplate,
  userItemMobileTemplate,
  userDetailsTableTemplate,
  userActionsTemplate,
};
