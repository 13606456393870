import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const hedgesManagement = 'HEDGES_MANAGEMENT';

const filterCompaniesSuccessActionType = `${hedgesManagement}_COMPANY_FILTER_SUCCESS`;
const filterCompaniesSuccessAction = () => ({
  type: filterCompaniesSuccessActionType,
});

const filterCompaniesFailedActionType = `${hedgesManagement}_COMPANY_FILTER_FAILED`;
const filterCompaniesFailedAction = () => ({
  type: filterCompaniesFailedActionType,
});

const filterLotsForReassignSuccessActionType = `${hedgesManagement}_LOTS_FILTER_SUCCESS`;
const filterLotsForReassignSuccessAction = filteredLots => ({
  type: filterLotsForReassignSuccessActionType,
  payload: { filteredLots },
});

const clearFilterLotsForReassignSuccessActionType = `${hedgesManagement}_CLEAR_LOTS_FILTER`;
const clearFilterLotsForReassign = () => ({
  type: clearFilterLotsForReassignSuccessActionType,
});

const filterLotsForReassignFailedActionType = `${hedgesManagement}_LOTS_FILTER_FAILED`;
const filterLotsForReassignFailedAction = () => ({
  type: filterLotsForReassignFailedActionType,
});

const getLotsForHedgesActionType = `${hedgesManagement}_GET_LOTS`;
const getLotsForHedgesAction = () => ({
  type: getLotsForHedgesActionType,
});

const getLotsForHedgesSuccessActionType = `${hedgesManagement}_GET_LOTS_SUCCESS`;
const getLotsForHedgesSuccessAction = (relatedCompany, data) => ({
  type: getLotsForHedgesSuccessActionType,
  payload: { relatedCompany, data },
});

const getLotsForHedgesFailureActionType = `${hedgesManagement}_GET_LOTS_FAILED`;
const getLotsForHedgesFailureAction = relatedCompany => ({
  type: getLotsForHedgesFailureActionType,
  payload: { relatedCompany },
});

const filterCompanies = filter => dispatch =>
  filter
    ? axios
        .get(`${API_HOST_AUTH}/hedges/filter-companies/${encodeURIComponent(filter)}`)
        .then(({ data }) =>
          data.map(
            ({
              _id,
              companyName,
              lotPrefix,
              companyType,
              blanketHedgeCount,
              onlyInternalHedgingAndSettlement,
              materialCountRequired,
            }) => ({
              value: _id,
              label: companyName,
              lotPrefix,
              companyType,
              blanketHedgeCount,
              onlyInternalHedgingAndSettlement,
              materialCountRequired,
            }),
          ),
        )
        .then(companies => {
          dispatch(filterCompaniesSuccessAction());
          return { options: companies };
        })
        .catch(() => dispatch(filterCompaniesFailedAction()))
    : Promise.resolve();

const filterLotsForReassign =
  ({ filter = '', hedge: { _id }, superAdminMode = false }) =>
  dispatch =>
    axios
      .get(`${API_HOST_AUTH}/hedges/${_id}/filter-reassign-lots/${encodeURIComponent(filter)}`, {
        params: {
          superAdminMode,
        },
      })
      .then(({ data }) => {
        dispatch(filterLotsForReassignSuccessAction(data));

        return {
          options: data.map(({ _id, grgLotNumber }) => ({
            value: _id,
            label: grgLotNumber,
          })),
        };
      })
      .catch(() => dispatch(filterLotsForReassignFailedAction()));

const getLotsForHedgesFactory = url => relatedCompany => dispatch => {
  if (!relatedCompany) return;

  dispatch(getLotsForHedgesAction());

  return axios
    .get(`${API_HOST_AUTH}/hedges/${url}/${relatedCompany}`)
    .then(({ data }) => dispatch(getLotsForHedgesSuccessAction(relatedCompany, data)))
    .catch(() => dispatch(getLotsForHedgesFailureAction(relatedCompany)));
};

const getLotsForHedges = getLotsForHedgesFactory('get-lots');
const getLotsToEnterPlacedHedges = getLotsForHedgesFactory('enter-placed/get-lots');

export {
  filterCompanies,
  filterLotsForReassign,
  clearFilterLotsForReassign,
  clearFilterLotsForReassignSuccessActionType,
  getLotsForHedgesActionType,
  getLotsForHedgesSuccessActionType,
  getLotsForHedgesFailureActionType,
  filterLotsForReassignSuccessActionType,
  getLotsForHedges,
  getLotsToEnterPlacedHedges,
};
