const notificationsTypesOwner = {
  hedges: {
    name: 'Hedges',
    value: 'hedges',
  },
  holds: {
    name: 'Holds',
    value: 'holds',
  },
  payments: {
    name: 'Payments',
    value: 'payments',
  },
  processing: {
    name: 'Processing',
    value: 'processing',
  },
  terms: {
    name: 'Terms',
    value: 'terms',
  },
  gradingAgreements: {
    name: 'Grading agreements',
    value: 'gradingAgreements',
  },
  poolAccounts: {
    name: 'Pool accounts',
    value: 'poolAccounts',
  },
  administration: {
    name: 'Administration',
    value: 'administration',
  },
  shipments: {
    name: 'Shipments',
    value: 'shipments',
  },
  userManagement: {
    name: 'User management',
    value: 'userManagement',
  },
};

const notificationsTypesSupport = {
  hedges: {
    name: 'Hedges',
    value: 'hedges',
  },
  holds: {
    name: 'Holds',
    value: 'holds',
  },
  payments: {
    name: 'Payments',
    value: 'payments',
  },
  processing: {
    name: 'Processing',
    value: 'processing',
  },
  terms: {
    name: 'Terms',
    value: 'terms',
  },
  gradingAgreements: {
    name: 'Grading agreements',
    value: 'gradingAgreements',
  },
  poolAccounts: {
    name: 'Pool accounts',
    value: 'poolAccounts',
  },
  administration: {
    name: 'Administration',
    value: 'administration',
  },
  shipments: {
    name: 'Shipments',
    value: 'shipments',
  },
};

const notificationsTypesWarehouse = {
  holds: {
    name: 'Holds',
    value: 'holds',
  },
  processing: {
    name: 'Processing',
    value: 'processing',
  },
  shipments: {
    name: 'Shipments',
    value: 'shipments',
  },
};

const notificationsTypesCustomer = {
  hedges: {
    name: 'Hedges',
    value: 'hedges',
  },
  payments: {
    name: 'Payments',
    value: 'payments',
  },
  terms: {
    name: 'Terms',
    value: 'terms',
  },
  poolAccounts: {
    name: 'Pool accounts',
    value: 'poolAccounts',
  },
  shipments: {
    name: 'Shipments',
    value: 'shipments',
  },
  confirmation: {
    name: 'Confirmation',
    value: 'confirmation',
  },
};

const notificationsTypesGrading = {
  payments: {
    name: 'Payments',
    value: 'payments',
  },
  gradingAgreements: {
    name: 'Grading agreements',
    value: 'gradingAgreements',
  },
  shipments: {
    name: 'Shipments',
    value: 'shipments',
  },
};

export {
  notificationsTypesOwner,
  notificationsTypesSupport,
  notificationsTypesWarehouse,
  notificationsTypesCustomer,
  notificationsTypesGrading,
};
