import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const compareBarcodesType = 'COMPARE_BARCODES';
const compareBarcodesAction = () => ({
  type: compareBarcodesType,
});

const compareBarcodesSuccessType = `${compareBarcodesType}_SUCCESS`;
const compateBarcodesSuccessAction = () => ({
  type: compareBarcodesSuccessType,
});

const compareBarcodesFailureType = `${compareBarcodesType}_FAILURE`;
const compareBarcodesFailureAction = () => ({
  type: compareBarcodesFailureType,
});

const compareBarcodes = (firstBarcode, secondBarcode) => dispatch => {
  dispatch(compareBarcodesAction());

  return axios
    .post(`${API_HOST_AUTH}/lots/compare-barcodes`, {
      firstBarcode,
      secondBarcode,
    })
    .then(res => {
      dispatch(compateBarcodesSuccessAction());

      return res.data;
    })
    .catch(err => {
      dispatch(compareBarcodesFailureAction());

      return Promise.reject(err.data);
    });
};

export {
  compareBarcodesType,
  compareBarcodesSuccessType,
  compareBarcodesFailureType,
  compareBarcodes,
};
