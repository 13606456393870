import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { Authorization } from 'components/Authorization/Authorization';
import { NotFound } from 'components/ErrorPage/ErrorPage';

import { termsAccess } from 'shared/helpers/accesses/terms/termsAccess';

import ConnectedTermsList from './components/TermsList/ConnectedTermsList';

function Terms(props) {
  const { user, access } = props.auth;

  const AuthTermsList = Authorization(ConnectedTermsList, termsAccess(user, access));

  return (
    <Switch>
      <Route exact path="/terms/:companyId" component={AuthTermsList} />
      <Route exact path="/terms/:companyId/download/:id" component={AuthTermsList} />
      <Route component={NotFound} />
    </Switch>
  );
}

export { Terms };
