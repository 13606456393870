/* eslint-disable react/no-multi-comp */
import PropTypes from 'prop-types';
import React, { memo, useEffect, forwardRef } from 'react';
import styled from 'styled-components';

import { ErrorMessage } from 'shared/components/Fields/FieldWrapper/components/ErrorMessage';
import { Container } from 'shared/components/Fields/TinyFieldWrapper/components/Container/Container';
import { themed } from 'shared/helpers/styling/styling';

const Content = styled.div`
  grid-area: input;
  height: fit-content;
`;

const ErrorMsg = styled(ErrorMessage)`
  position: absolute;
  bottom: 5px;
  left: 0;

  ::before {
    left: 38%;
    bottom: -6px;
  }
`;

const TinyFieldWrapper = memo(({ className, id, disabled, labelWidth, meta, children, hide }) => {
  const { error, touched, dirty } = meta || {};

  return (
    <Container className={className} hide={hide}>
      <Content id={id}>{children}</Content>
      <ErrorMsg show={(touched || dirty) && !disabled} labelWidth={labelWidth} withoutMargin>
        {error}
      </ErrorMsg>
    </Container>
  );
});

const TinyFieldWrapperStyled = styled(TinyFieldWrapper).attrs(themed())``;

TinyFieldWrapperStyled.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  littleField: PropTypes.bool,
};

TinyFieldWrapperStyled.defaultProps = {
  required: false,
  littleField: false,
};

const withTinyFieldWrapper = (C, wrapperProps) =>
  forwardRef(({ as: asComponent, focused, ...props }, ref) => {
    useEffect(() => {
      if (ref && ref.current && focused) ref.current.focus();
    }, [focused, ref]);

    return (
      <TinyFieldWrapperStyled {...props} {...wrapperProps}>
        {asComponent ? <C {...props} as={asComponent} ref={ref} /> : <C {...props} ref={ref} />}
      </TinyFieldWrapperStyled>
    );
  });

export { TinyFieldWrapperStyled as TinyFieldWrapper, withTinyFieldWrapper };
