import styled from 'styled-components';

import { cssVar } from 'shared/helpers/styling/styling';

export const StyledHeader = styled.h3`
  margin: 0;
  font-size: ${cssVar('bs-body-font-size')};
`;

export const StyledTop = styled.div`
  padding: 37px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledBottom = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 40px 100px;
  border-top: 1px solid ${cssVar('dustGray')};
`;
