import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Icon } from 'shared/components/Icon/Icon';
import { cssVar } from 'shared/helpers/styling/styling';

import { Button } from '../Button/Button';

const AddButton = ({ className, children, ...props }) => (
  <div className={className}>
    <div>
      <Button {...props} outlineColor={cssVar('atlantis')}>
        {children}
        <Icon icon="icon-plus" />
      </Button>
    </div>
  </div>
);

const StyledAddButton = styled(AddButton)`
  background: ${({ bgColor, borderColor }) =>
    `repeating-linear-gradient(45deg, ${bgColor}, ${bgColor} 8px, ${borderColor} 8px, ${borderColor} 16px)`};
  overflow: auto;

  & > div {
    display: flex;
    justify-content: center;
    padding: 30px 0;
    height: 100%;
    background-color: ${({ bgColor }) => bgColor};
    margin: 1px;
  }
`;

StyledAddButton.propTypes = {
  bgColor: PropTypes.string,
  borderColor: PropTypes.string,
};

StyledAddButton.defaultProps = {
  bgColor: cssVar('outerSpaceBrighter'),
  borderColor: cssVar('riverBedButton'),
};

export { StyledAddButton as AddButton };
