import axios from 'axios';

import { showSnackbar } from 'actions/shared/snackbar';

import {
  successOptions,
  dangerOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { parseAgreementData } from 'shared/helpers/dataParsers/agreements/parseAgreementData';

import { history } from 'utils/history';

const API_HOST_AUTH = process.env.REACT_APP_API;

const createAgreementActionType = 'CREATE_AGREEMENT';
function createAgreementAction() {
  return {
    type: createAgreementActionType,
  };
}

const createAgreementSuccessActionType = 'CREATE_AGREEMENT_SUCCESS';
function createAgreementSuccessAction() {
  return {
    type: createAgreementSuccessActionType,
  };
}

const createAgreementFailureActionType = 'CREATE_AGREEMENT_FAILURE';
function createAgreementFailureAction() {
  return {
    type: createAgreementFailureActionType,
  };
}

function createAgreement(agreement, relatedCompany) {
  return dispatch => {
    dispatch(createAgreementAction());

    return axios({
      method: 'post',
      url: `${API_HOST_AUTH}/agreements`,
      data: JSON.stringify({
        ...parseAgreementData(agreement),
        relatedCompany,
      }),
    })
      .then(res => {
        dispatch(createAgreementSuccessAction());
        dispatch(showSnackbar(successOptions, res.data.message));
        history.push(`/companies/${relatedCompany}/agreements/${res.data.id}`);
      })
      .catch(error => {
        dispatch(createAgreementFailureAction());
        dispatch(
          showSnackbar(dangerOptions, `Agreement has not been created. ${error.data.message}`),
        );
      });
  };
}

export {
  createAgreementActionType,
  createAgreementSuccessActionType,
  createAgreementFailureActionType,
  createAgreement,
};
