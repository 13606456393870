import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { downloadAttachment } from 'actions/Attachments/downloadAttachment';
import { getLot } from 'actions/Lots/lotDetails';
import { getNotes } from 'actions/Notes/notesList';
import { showSnackbar } from 'actions/shared/snackbar';

import { CancelButton } from 'shared/components/Buttons';
import { Button } from 'shared/components/Buttons/Button';
import { NotesItem } from 'shared/components/Notes/NotesPanel/NotesItem/NotesItem';
import { OverviewHeader } from 'shared/components/OverviewHeader';
import { Preloader } from 'shared/components/Preloader/Preloader';
import { NOTE } from 'shared/helpers/constants/noteConstants';
import { dangerOptions } from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

import { GenerateShipmentSummary } from '../GenerateShipmentSummary/GenerateShipmentSummary';
import { BtnGroup, Paragraph } from './StyledLotNotes';

const LotNotes = ({
  history,
  location: { state },
  match: {
    params: { id },
  },
}) => {
  const [displayGenerateShipmentSummaryModal, setDisplayGenerateShipmentSummaryModal] =
    useState(false);
  const dispatch = useDispatch();
  const { notes, isPending: isNotesListPending } = useSelector(({ notesList }) => notesList);
  const { lot, isPending } = useSelector(({ lotDetails }) => lotDetails);
  const { isPending: downloadIsPending } = useSelector(
    ({ downloadAttachmentStorage }) => downloadAttachmentStorage,
  );

  const toggleGenerateShipmentSummaryModal = () =>
    setDisplayGenerateShipmentSummaryModal(!displayGenerateShipmentSummaryModal);
  const isPrevShipmentSummaryGenerate = state?.prevLink === 'shipment';
  const goBack = () => {
    history.replace();
    return history.push(`/lots/list/${id}`);
  };

  useEffect(() => {
    dispatch(
      getNotes({
        relatedType: NOTE.NOTES_TYPE.LOTS,
        relatedId: id,
        listLimit: 0,
        listPage: 1,
      }),
    );

    isPrevShipmentSummaryGenerate && dispatch(getLot(id)).catch(() => goBack());
  }, [id]);

  const onCancel = () => goBack();
  const onContinue = () => {
    switch (state?.prevLink) {
      case 'shipment':
        return toggleGenerateShipmentSummaryModal();
      case 'invoice':
        return history.push(`/lots/list/${id}/generate/final-invoice`);
      default:
        goBack();
        return showSnackbar(dangerOptions, NOTE.NOTE_CONTINUE_ERROR);
    }
  };

  return (
    <>
      <Preloader loading={isPending || downloadIsPending || isNotesListPending} />
      <OverviewHeader header={NOTE.DETAILS.NOTES} subheader={NOTE.NOTE_SUBTITLE} />
      {!isNotesListPending &&
        notes[NOTE.NOTES_TYPE.LOTS]?.map(note => (
          <NotesItem
            onClick={path => dispatch(downloadAttachment(path))}
            note={note}
            key={note._id}
            lotId={id}
          />
        ))}
      {state?.prevLink && (
        <>
          <Paragraph>{NOTE.NOTE_READED}</Paragraph>
          <BtnGroup>
            <Button onClick={onContinue} type="submit">
              {NOTE.CONTINUE}
            </Button>
            <CancelButton onClick={onCancel} />
          </BtnGroup>
        </>
      )}
      {isPrevShipmentSummaryGenerate && !isPending && (
        <GenerateShipmentSummary
          lot={lot}
          open={displayGenerateShipmentSummaryModal}
          modalClose={toggleGenerateShipmentSummaryModal}
        />
      )}
    </>
  );
};

export { LotNotes };
