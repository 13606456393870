import { isBuyerUser } from 'shared/helpers/matchers/checkUserType';
import { isGlobalUserType } from 'shared/helpers/matchers/isGlobalUserType';

import { CustomerLotsFilters } from './CustomerLotsFilters/CustomerLotsFilters';
import { GlobalLotsFilters } from './GlobalLotsFilters/GlobalLotsFilters';

export const LotsFilters = props => {
  const { userType } = props.auth.user;
  return isGlobalUserType(userType) || isBuyerUser(userType) ? (
    <GlobalLotsFilters {...props} />
  ) : (
    <CustomerLotsFilters {...props} />
  );
};
