import {
  updateColorType,
  updateColorSuccessType,
  updateColorFailureType,
} from 'actions/Lots/updateColor';

const INITIAL_STATE = {
  isPending: false,
};

const updateColorReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case updateColorType:
      return {
        isPending: true,
      };
    case updateColorSuccessType:
    case updateColorFailureType:
      return {
        isPending: false,
      };
    default:
      return state;
  }
};

export { updateColorReducer };
