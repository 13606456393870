import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { cssVar } from 'shared/helpers/styling/styling';

const OverviewListTableCell = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: ${({ header }) => (header ? 'center' : 'flex-start')};
  color: ${({ head }) => (head ? cssVar('alto') : cssVar('alabaster'))};
  margin: ${({ margin }) => margin};
  font-weight: 500;
  font-size: 12px;
  text-align: left;
  word-break: break-word;

  @media print {
    margin: 0;
    padding: 0;
  }

  ${({ bgColor }) =>
    bgColor &&
    css`
      background-color: ${bgColor};
      margin: 0;
      padding: ${({ margin }) => margin};
      height: 100%;
    `};

  ${({ gridArea }) => css`
    grid-area: ${gridArea};
  `};

  .TableItem-access {
    text-indent: 50px;
  }

  .TableItem-withOpacity {
    opacity: 0.5;
  }
`;

OverviewListTableCell.propTypes = {
  head: PropTypes.bool,
  header: PropTypes.bool,
  gridArea: PropTypes.string,
  margin: PropTypes.string,
  bgColor: PropTypes.string,
};

OverviewListTableCell.defaultProps = {
  head: false,
  header: false,
  margin: '10px',
};

export { OverviewListTableCell };
