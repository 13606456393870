import { safeParseFloat, roundUp } from 'shared/helpers/parsers/number';

const getAssignedLotOunces = lotOunces =>
  lotOunces.reduce(
    (acc, curr) => ({
      ...acc,
      palladiumOz: safeParseFloat(acc.palladiumOz || 0) + safeParseFloat(curr.palladiumOz || 0),
      platinumOz: safeParseFloat(acc.platinumOz || 0) + safeParseFloat(curr.platinumOz || 0),
      rhodiumOz: safeParseFloat(acc.rhodiumOz || 0) + safeParseFloat(curr.rhodiumOz || 0),
    }),
    {},
  );

const formatMaximumLotOunces = (available, { platinumOz, palladiumOz, rhodiumOz }) => ({
  platinumOz: +roundUp(`${available?.platinumOz || 0} - ${platinumOz > 0 ? platinumOz : 0}`, 3),
  palladiumOz: +roundUp(`${available?.palladiumOz || 0} - ${palladiumOz > 0 ? palladiumOz : 0}`, 3),
  rhodiumOz: +roundUp(`${available?.rhodiumOz || 0} - ${rhodiumOz > 0 ? rhodiumOz : 0}`, 3),
});

export { getAssignedLotOunces, formatMaximumLotOunces };
