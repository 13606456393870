import React from 'react';
import { FieldArray, FormSection, reduxForm } from 'redux-form';

import { Form } from 'shared/components/Form';
import { PROFIT_LOSS } from 'shared/helpers/constants/lots/profitLossConstants';
import { additionalCommoditiesValidation } from 'shared/helpers/validations/lot/generateProfitLossValidation';
import { multiValidator } from 'shared/helpers/validations/multiValidator';

import { AdditionalCommodities } from './AdditionalCommodities/AdditionalCommodities';

const AdditionalCommoditiesFormLayout = ({ handleSubmit, invalid }) => (
  <Form
    header={PROFIT_LOSS.ADDITIONAL_COMMODITIES}
    onSubmit={handleSubmit}
    submitDisabled={invalid}
  >
    <FormSection name="additionalCommodities">
      <FieldArray name="commodities" component={AdditionalCommodities} />
    </FormSection>
  </Form>
);

const AdditionalCommoditiesForm = reduxForm({
  validate: multiValidator('additionalCommodities', additionalCommoditiesValidation),
})(AdditionalCommoditiesFormLayout);

export { AdditionalCommoditiesForm, AdditionalCommoditiesFormLayout };
