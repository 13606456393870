import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import { lotsFiltersClear } from 'actions/Lots/lotsFilters';

import { Authorization } from 'components/Authorization/Authorization';
import { NotFound } from 'components/ErrorPage/ErrorPage';

import { reportsAccess } from 'shared/helpers/accesses/reportsAccess/reportsAccess';

import { ClientLotReport } from './components/ClientLotReport/ClientLotReport';
import { ClientLotReportForm } from './components/ClientLotReport/components/ClientLotReportForm/ClientLotReportForm';
import { LotsAwaitingResults } from './components/LotsAwaitingResults/LotsAwaitingResults';
import { OpenFinals } from './components/OpenFinals/OpenFinals';
import { ReportsList } from './components/ReportsList/ReportsList';
import { TotalPayments } from './components/TotalPayments/TotalPayments';

const Reports = ({ auth: { user, access } }) => {
  const dispatch = useDispatch();
  useEffect(() => () => dispatch(lotsFiltersClear()), []);

  const AuthReports = Authorization(ReportsList, reportsAccess(user, access));
  const AuthOpenFinals = Authorization(OpenFinals, access.reports.openFinals.view);
  const AuthTotalPayments = Authorization(TotalPayments, access.payments.analysis.overview);
  const AuthLotsAwaitingResults = Authorization(
    LotsAwaitingResults,
    access.reports.lotsAwaitingResults,
  );
  const AuthClientLotReport = Authorization(ClientLotReport, access.reports.clientLotReports);
  const AuthClientLotReportForm = Authorization(
    ClientLotReportForm,
    access.reports.clientLotReports,
  );

  return (
    <Switch>
      <Route exact path="/reports/lots-awaiting-results" component={AuthLotsAwaitingResults} />
      <Route exact path="/reports/client-lot-report" component={AuthClientLotReport} />
      <Route exact path="/reports/client-lot-report/create" component={AuthClientLotReportForm} />
      <Route exact path="/reports/analysis" component={AuthReports} />
      <Route exact path="/reports/finals" component={AuthOpenFinals} />
      <Route exact path="/reports" component={AuthTotalPayments} />
      <Route component={NotFound} />
    </Switch>
  );
};

export { Reports };
