import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getLotsToTransferActionType = 'GET_LOTS_TO_TRANSFER';
const getLotsToTransferAction = () => ({
  type: getLotsToTransferActionType,
});

const getLotsToTransferSuccessActionType = `${getLotsToTransferActionType}_SUCCESS`;
const getLotsToTransferSuccessAction = data => ({
  type: getLotsToTransferSuccessActionType,
  payload: data,
});

const getLotsToTransferFailureActionType = `${getLotsToTransferActionType}_FAILURE`;
const getLotsToTransferFailureAction = () => ({
  type: getLotsToTransferFailureActionType,
});

const getLotsToTransfer = relatedLotId => dispatch => {
  dispatch(getLotsToTransferAction());

  return axios
    .get(`${API_HOST_AUTH}/deliveries/transfer-ounces/lots`, { params: { relatedLotId } })
    .then(response => {
      dispatch(getLotsToTransferSuccessAction(response.data));
      return response.data;
    })
    .catch(error => {
      dispatch(getLotsToTransferFailureAction());
      return Promise.reject(error.data);
    });
};

export {
  getLotsToTransferActionType,
  getLotsToTransferAction,
  getLotsToTransferSuccessActionType,
  getLotsToTransferSuccessAction,
  getLotsToTransferFailureActionType,
  getLotsToTransferFailureAction,
  getLotsToTransfer,
};
