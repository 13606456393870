import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { clearLotSettlements, getLotSettlements } from 'actions/Settlements/getLotSettlements';

import { OverviewListWrapper } from 'shared/components/OverviewListWrapper/OverviewListWrapper';
import { deleteSettlementsAccess } from 'shared/helpers/accesses/settlements/deleteSettlementsAccess';
import { enterPricesForSettledOuncesAccess } from 'shared/helpers/accesses/settlements/enterPricesForSettledOuncesAccess';
import { SETTLEMENTS } from 'shared/helpers/constants/settlements/settlementsConstants';

import {
  actionsItemTemplate,
  itemDetailsTemplate,
  itemsTemplate,
  itemsTemplateMobile,
} from 'pages/Lots/components/LotDetails/components/components/SettlementsOverview/SettlementsOverviewTableConfig';

export const SettlementsOverview = () => {
  const { settlements, limit } = useSelector(state => state.lotSettlements);
  const lotStatusForGlobal = useSelector(state => state.lotDetails.lot.statusForGlobal);
  const relatedCompanyType = useSelector(
    state => state.lotDetails.lot.sentFromCompany?.companyType,
  );
  const {
    user: {
      userType,
      relatedCompany: { companyType },
    },
    access,
  } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(getLotSettlements(id, limit));

    return () => clearLotSettlements();
  }, [dispatch, id]);

  const normalizedSettlements = useMemo(
    () => ({
      items: {
        docs: settlements && settlements.docs,
        total: settlements && settlements.total,
      },
    }),
    [settlements],
  );

  const actions = actionsItemTemplate(
    { lotId: id, limit, lotStatusForGlobal, relatedCompanyType },
    access,
    userType,
    dispatch,
  );

  actions.access = () =>
    enterPricesForSettledOuncesAccess(lotStatusForGlobal, access.settlements, relatedCompanyType) ||
    deleteSettlementsAccess(lotStatusForGlobal, userType, relatedCompanyType);

  return (
    <OverviewListWrapper
      list={normalizedSettlements}
      getList={getLotSettlements}
      itemsTemplate={itemsTemplate(userType, companyType)}
      itemsTemplateMobile={itemsTemplateMobile}
      itemDetailsTemplate={itemDetailsTemplate(userType, companyType)}
      actionsTemplate={actions}
      label={SETTLEMENTS.SETTLEMENTS}
    />
  );
};
