import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

import { cssVar, media } from 'shared/helpers/styling/styling';

const DraggableItemsListWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1;
  padding: 26px 13px 13px;
  gap: 13px;
  grid-gap: 13px;
  align-items: center;
  justify-content: stretch;
  border-radius: 8px;
  color: ${cssVar('regentGrayLight')};
  font-size: 0.9rem;

  ${media.desktop`
    display: flex;
    align-items: stretch;
    flex-direction: column;
    height: 100%;
  `}

  ${({ empty }) =>
    empty &&
    css`
      background-color: cssVar('outerSpaceTabs');
      border: 1px dashed ${cssVar('baliHai')};
      text-align: center;
      margin: 26px 12px 12px;
      padding: 0;
      width: calc(100% - 26px);
      min-height: 114px;
      height: calc(100% - 38px)!important;
    `}

  ${({ isDraggingOver }) =>
    isDraggingOver &&
    css`
      background-image: repeating-linear-gradient(
        -45deg,
        ${cssVar('limedSpruceDarker')},
        ${cssVar('limedSpruceDarker')} 15px,
        ${cssVar('outerSpaceTabs')} 15px,
        ${cssVar('outerSpaceTabs')} 28px
      );
    `}
`;

const DraggableItemsList = ({ providedRef, children, placeholder, ...rest }) => (
  <DraggableItemsListWrapper ref={providedRef} {...rest}>
    {children}
    {rest.empty && !rest.isDraggingOver && placeholder}
  </DraggableItemsListWrapper>
);

DraggableItemsList.defaultProps = {
  empty: PropTypes.bool,
  isDraggingOver: PropTypes.bool,
  placeholder: PropTypes.string.isRequired,
};

export { DraggableItemsList };
