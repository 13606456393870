import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const acceptForProcessingType = 'ACCEPT_FOR_PROCESSING';
const acceptForProcessingAction = () => ({
  type: acceptForProcessingType,
});

const acceptForProcessingSuccessType = `${acceptForProcessingType}_SUCCESS`;
const acceptForProcessingSuccessAction = lot => ({
  type: acceptForProcessingSuccessType,
  payload: {
    lot,
  },
});

const acceptForProcessingFailureType = `${acceptForProcessingType}_FAILURE`;
const acceptForProcessingFailureAction = () => ({
  type: acceptForProcessingFailureType,
});

const acceptForProcessing = lotId => dispatch => {
  dispatch(acceptForProcessingAction());

  return axios
    .put(`${API_HOST_AUTH}/lots/accept-for-processing/${lotId}`)
    .then(res => {
      dispatch(acceptForProcessingSuccessAction(res.data.lot));

      return res.data;
    })
    .catch(err => {
      dispatch(acceptForProcessingFailureAction());

      return Promise.reject(err.data);
    });
};

export {
  acceptForProcessingType,
  acceptForProcessingSuccessType,
  acceptForProcessingFailureType,
  acceptForProcessing,
};
