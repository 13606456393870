import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const markLotAsReceivedType = 'MARK_LOT_AS_RECEIVED';
const markLotAsReceivedAction = () => ({
  type: markLotAsReceivedType,
});

const markLotAsReceivedSuccessType = `${markLotAsReceivedType}_SUCCESS`;
const markLotAsReceivedSuccessAction = () => ({
  type: markLotAsReceivedSuccessType,
});

const markLotAsReceivedFailureType = `${markLotAsReceivedType}_FAILURE`;
const markLotAsReceivedFailureAction = () => ({
  type: markLotAsReceivedFailureType,
});

const markLotAsReceived = (lotId, packageId) => dispatch => {
  dispatch(markLotAsReceivedAction());

  return axios
    .put(`${API_HOST_AUTH}/lots/mark-as-received`, { lotId, packageId })
    .then(res => {
      dispatch(markLotAsReceivedSuccessAction());

      return res.data;
    })
    .catch(err => {
      dispatch(markLotAsReceivedFailureAction());

      return Promise.reject(err.data);
    });
};

export {
  markLotAsReceivedType,
  markLotAsReceivedSuccessType,
  markLotAsReceivedFailureType,
  markLotAsReceived,
};
