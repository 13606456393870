import { media } from 'shared/helpers/styling/styling';
import styled from 'styled-components';

export const StyledSelfDeliveryRequestFilters = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${media.largeTablet`
  justify-content: center;
  `}

  ${media.tablet`
  justify-content: center;
  `}
  
  ${media.phone`
    flex-direction: column;
    align-items: center;
  `}
`;

export const StyledItem = styled.div`
  width: 540px;
  ${media.phone`
      width: 250px;`}
`;
