import { createMarketHolidaysSuccessActionType } from 'actions/Hedges/marketHolidaysCreate';
import {
  getMarketHolidaysActionType,
  getMarketHolidaysSuccessActionType,
  getMarketHolidaysFailureActionType,
  clearMarketHolidaysActionType,
  restoreInitialStateActionType,
} from 'actions/Hedges/marketHolidaysList';

const INITIAL_STATE = {
  isPending: false,
  marketHolidays: [],
  filters: { year: new Date().getFullYear() },
};

const marketHolidaysListReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getMarketHolidaysActionType:
      return {
        ...state,
        filters: action.payload.filters,
        isPending: true,
      };
    case getMarketHolidaysSuccessActionType:
      return {
        ...state,
        isPending: false,
        marketHolidays: action.payload.data,
      };
    case getMarketHolidaysFailureActionType:
      return {
        ...state,
        isPending: false,
      };
    case createMarketHolidaysSuccessActionType:
      return {
        ...state,
        filters: {
          year: new Date(action.payload.marketHoliday.date).getFullYear(),
        },
      };
    case clearMarketHolidaysActionType:
      return {
        ...INITIAL_STATE,
        filters: state.filters,
      };
    case restoreInitialStateActionType:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};

export { marketHolidaysListReducer };
