import axios from 'axios';

import { parseLotUpdateLocation } from 'shared/helpers/dataParsers/lot/parseLotUpdateLocation';

const API_HOST_AUTH = process.env.REACT_APP_API;

const updateLotLocationType = 'UPDATE_LOT_LOCATION';
const updateLotLocationAction = () => ({
  type: updateLotLocationType,
});

const updateLotLocationSuccessType = `${updateLotLocationType}_SUCCESS`;
const updateLotLocationSuccessAction = () => ({
  type: updateLotLocationSuccessType,
});

const updateLotLocationFailureType = `${updateLotLocationType}_FAILURE`;
const updateLotLocationFailureAction = () => ({
  type: updateLotLocationFailureType,
});

const updateLotLocation = data => dispatch => {
  dispatch(updateLotLocationAction());

  return axios
    .patch(
      `${API_HOST_AUTH}/lots/location/${data.id}`,
      JSON.stringify(parseLotUpdateLocation(data)),
    )
    .then(res => {
      dispatch(updateLotLocationSuccessAction());

      return res.data;
    })
    .catch(err => {
      dispatch(updateLotLocationFailureAction());

      return Promise.reject(err.data);
    });
};

export {
  updateLotLocationType,
  updateLotLocationSuccessType,
  updateLotLocationFailureType,
  updateLotLocation,
};
