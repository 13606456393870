import axios from 'axios';

import { messageTypes } from 'shared/helpers/constants/snackbar/snackbarMessageTypes';

const API_HOST_AUTH = process.env.REACT_APP_API;

const updateNotificationPreferencesActionType = 'UPDATE_NOTIFICATION_PREFERENCES';
function updateNotificationPreferencesAction() {
  return {
    type: updateNotificationPreferencesActionType,
  };
}

const updateNotificationPreferencesSuccessActionType = 'UPDATE_NOTIFICATION_PREFERENCES_SUCCESS';
function updateNotificationPreferencesSuccessAction() {
  return {
    type: updateNotificationPreferencesSuccessActionType,
  };
}

const updateNotificationPreferencesFailureActionType = 'UPDATE_NOTIFICATION_PREFERENCES_FAILURE';
function updateNotificationPreferencesFailureAction() {
  return {
    type: updateNotificationPreferencesFailureActionType,
  };
}

function updateNotificationPreferences(preferences, id) {
  return dispatch => {
    dispatch(updateNotificationPreferencesAction());

    return axios({
      method: 'put',
      url: `${API_HOST_AUTH}/users/${id}/notification-preferences`,
      data: JSON.stringify(preferences),
    })
      .then(response => {
        dispatch(updateNotificationPreferencesSuccessAction());
        return {
          message: response.data.message,
          messageType: messageTypes.success,
        };
      })
      .catch(error => {
        dispatch(updateNotificationPreferencesFailureAction());
        return {
          message: error.data.message,
          messageType: messageTypes.error,
        };
      });
  };
}

export {
  updateNotificationPreferences,
  updateNotificationPreferencesAction,
  updateNotificationPreferencesActionType,
  updateNotificationPreferencesFailureAction,
  updateNotificationPreferencesFailureActionType,
  updateNotificationPreferencesSuccessAction,
  updateNotificationPreferencesSuccessActionType,
};
