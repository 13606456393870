import axios from 'axios';

import { SHIPMENTS } from 'shared/helpers/constants/shipments/shipmentsConstants';
import { messageTypes } from 'shared/helpers/constants/snackbar/snackbarMessageTypes';
import {
  parseShipmentData,
  parseOutgoingShipmentData,
  parseIncomingShipmentData,
  parsePackingListResponseData,
  parseShipmentUpdatePackingListData,
} from 'shared/helpers/dataParsers/shipments';

const API_HOST_AUTH = process.env.REACT_APP_API;

const updateShipmentActionType = 'UPDATE_SHIPMENT';
const updateShipmentAction = () => ({
  type: updateShipmentActionType,
});

const updateShipmentSuccessActionType = `${updateShipmentActionType}_SUCCESS`;
const updateShipmentSuccessAction = () => ({
  type: updateShipmentSuccessActionType,
});

const updateShipmentFailureActionType = `${updateShipmentActionType}_FAILURE`;
const updateShipmentFailureAction = () => ({
  type: updateShipmentFailureActionType,
});

const updateShipmentFactory = (url, parseResponseData) => parsedShipmentData => dispatch => {
  dispatch(updateShipmentAction());

  return axios
    .put(url, JSON.stringify(parsedShipmentData))
    .then(res => {
      dispatch(updateShipmentSuccessAction());

      return {
        id: res.data.id,
        message: res.data.message,
        messageType: messageTypes.success,
        shipmentPackages: res.data.shipmentPackages,
        packagesToPrint: res.data.packagesToPrint,
        ...((parseResponseData && parseResponseData(res.data)) || {}),
      };
    })
    .catch(error => {
      dispatch(updateShipmentFailureAction());

      return {
        message: `${SHIPMENTS.ERROR.UPDATE_SHIPMENT_ERROR} ${error.data.message}`,
        messageType: messageTypes.error,
      };
    });
};

const updateShipment = shipment =>
  updateShipmentFactory(`${API_HOST_AUTH}/shipments/${shipment.id}`)(parseShipmentData(shipment));
const updateOutgoingShipment = shipment =>
  updateShipmentFactory(`${API_HOST_AUTH}/shipments/outgoing/${shipment.id}`)(
    parseOutgoingShipmentData(shipment),
  );
const updateIncomingShipment = shipment =>
  updateShipmentFactory(`${API_HOST_AUTH}/shipments/incoming/${shipment.id}`)(
    parseIncomingShipmentData(shipment),
  );

const updateShipmentPackingList = (shipment, userType, chosenPackages, shipmentId) => {
  return updateShipmentFactory(
    `${API_HOST_AUTH}/shipments/incoming-packing-list/${shipmentId}`,
    parsePackingListResponseData,
  )(parseShipmentUpdatePackingListData(shipment, userType, chosenPackages));
};

export {
  updateShipmentActionType,
  updateShipmentAction,
  updateShipmentSuccessActionType,
  updateShipmentSuccessAction,
  updateShipmentFailureActionType,
  updateShipmentFailureAction,
  updateOutgoingShipment,
  updateIncomingShipment,
  updateShipment,
  updateShipmentPackingList,
};
