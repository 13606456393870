import PropTypes from 'prop-types';
import { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router';

import { clearRelatedSamplePackages } from 'actions/Packages/getRelatedSamplePackages';
import { printLabPolicy } from 'actions/Packages/printLabPolicy';
import { printLabRequestForms } from 'actions/Packages/printLabRequestForms';
import { clearShipmentPackagesList } from 'actions/Shipments/shipmentPackagesList';

import { Button } from 'shared/components/Buttons/Button';
import { PACKAGES } from 'shared/helpers/constants/packages/packageConstants';

import {
  LabPolicy,
  LabPolicyInfo,
  StyledCheckbox,
  StyledPrintLabRequestFormsActions,
} from './StyledPrintLabRequestFormActions';

const PrintLabRequestFormsActions = ({ packagesList }) => {
  const location = useLocation();
  const relatedType = location.pathname.includes('shipments') ? 'shipments' : 'lots';
  const { id: relatedId } = useParams();
  const [printPolicy, setPrintPolicy] = useState(false);
  const selectedPackageNumbers = useSelector(state => state.shipmentPackagesListData.packages);
  const selectedPackages = packagesList.packages.filter(p =>
    selectedPackageNumbers.includes((p.newPackageNumber || p.packageNumber).toString()),
  );
  const dispatch = useDispatch();

  useEffect(
    () => () => {
      dispatch(clearRelatedSamplePackages());
      dispatch(clearShipmentPackagesList());
    },
    [dispatch],
  );
  const printSelectedLabels = useCallback(() => {
    if (!selectedPackages.length && printPolicy) {
      dispatch(printLabPolicy());
      return;
    }
    dispatch(
      printLabRequestForms(
        relatedType,
        relatedId,
        selectedPackages.map(p => p._id),
        printPolicy,
      ),
    );
  }, [dispatch, relatedType, relatedId, selectedPackages, printPolicy]);

  const printAllLabels = useCallback(() => {
    dispatch(
      printLabRequestForms(
        relatedType,
        relatedId,
        packagesList.packages.map(p => p._id),
        printPolicy,
      ),
    );
  }, [dispatch, relatedType, relatedId, packagesList, printPolicy]);

  const changeLaboratoryPolicy = () => {
    setPrintPolicy(!printPolicy);
  };

  return (
    <StyledPrintLabRequestFormsActions>
      <Button
        outline
        id="printSelectedLabRequestForms"
        onClick={printSelectedLabels}
        disabled={!selectedPackageNumbers.length && !printPolicy}
      >
        {PACKAGES.PRINT_SELECTED_LAB_REQUEST_FORMS}
      </Button>
      <Button outline id="printAllLabRequestForms" onClick={printAllLabels}>
        {PACKAGES.PRINT_ALL_LAB_REQUEST_FORMS}
      </Button>
      <LabPolicy>
        <StyledCheckbox
          field="required"
          name="laboratoryPolicy"
          dataTestId="laboratoryPolicy"
          checked={printPolicy}
          onClick={changeLaboratoryPolicy}
          onChange={changeLaboratoryPolicy}
        />
        <LabPolicyInfo>{PACKAGES.PRINT_LAB_POLICY}</LabPolicyInfo>
      </LabPolicy>
    </StyledPrintLabRequestFormsActions>
  );
};

PrintLabRequestFormsActions.propTypes = {
  packagesList: PropTypes.arrayOf(
    PropTypes.shape({
      packages: PropTypes.shape({
        _id: PropTypes.string,
        packageNumber: PropTypes.number,
        newPackageNumber: PropTypes.number,
      }),
    }),
  ),
};

export { PrintLabRequestFormsActions };
