import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getLocationDetailsUsersActionType = 'GET_USERS';
function getLocationDetailsUsersAction() {
  return {
    type: getLocationDetailsUsersActionType,
  };
}

const getLocationDetailsUsersSuccessActionType = 'GET_USERS_SUCCESS';
function getLocationDetailsUsersSuccessAction(users, pages, total) {
  return {
    type: getLocationDetailsUsersSuccessActionType,
    payload: {
      users,
      pages,
      total,
    },
  };
}

const getLocationDetailsUsersFailureActionType = 'GET_USERS_FAILURE';
function getLocationDetailsUsersFailureAction(errorMessage) {
  return {
    type: getLocationDetailsUsersFailureActionType,
    payload: {
      errorMessage,
    },
  };
}

const clearLocationDetailsUsersListActionType = 'CLEAR_USERS_LIST_ACTION';
function clearLocationDetailsUsersListAction() {
  return {
    type: clearLocationDetailsUsersListActionType,
  };
}

function getLocationDetailsUsers(locationId, limit, page) {
  return dispatch => {
    dispatch(getLocationDetailsUsersAction());

    return axios
      .get(`${API_HOST_AUTH}/users/locations/${locationId}/${limit}/${page}`)
      .then(res => {
        dispatch(
          getLocationDetailsUsersSuccessAction(res.data.docs, res.data.pages, res.data.total),
        );
      })
      .catch(() => {
        dispatch(getLocationDetailsUsersFailureAction(''));
      });
  };
}

function clearLocationDetailsUsersList() {
  return dispatch => {
    dispatch(clearLocationDetailsUsersListAction());
  };
}

export {
  getLocationDetailsUsersActionType,
  getLocationDetailsUsersSuccessActionType,
  getLocationDetailsUsersFailureActionType,
  clearLocationDetailsUsersListActionType,
  getLocationDetailsUsers,
  clearLocationDetailsUsersList,
};
