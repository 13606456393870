import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Button } from 'shared/components/Buttons/Button';
import { cssVar } from 'shared/helpers/styling/styling';

import { DraggableItemBasic } from './DraggableItemBasic';

const DraggableItemExpandedWrapper = styled.div`
  display: grid;
  gap: 10px;
  grid-gap: 10px;
  background: ${cssVar('outerSpaceTabs')};
  border: 1px dashed ${cssVar('baliHai')};
  padding: 10px;
  margin-left: -13px;
  margin-right: -13px;

  &:focus {
    outline-color: ${cssVar('atlantis')};
  }

  ${Button} {
    width: 100%;
    font-size: 0.8rem;
  }
`;

const DraggableItemExpanded = ({
  dragRef,
  expandedActionsTemplate,
  highlighted,
  highlightedColor,
  item,
  icon,
  isDragging,
  dragDisabled,
  itemsTemplate,
  actionsTemplate,
  outlined,
  ...rest
}) => (
  <DraggableItemExpandedWrapper {...rest} ref={dragRef}>
    <DraggableItemBasic
      highlighted={highlighted}
      highlightedColor={highlightedColor}
      item={item}
      icon={icon}
      isDragging={isDragging}
      dragDisabled={dragDisabled}
      itemsTemplate={itemsTemplate}
      actionsTemplate={actionsTemplate}
      outlined={outlined}
    />
    {expandedActionsTemplate
      .filter(({ access }) => !access || access(item))
      .map((action, index) => action.render(item, index))}
  </DraggableItemExpandedWrapper>
);

DraggableItemExpanded.propTypes = {
  id: PropTypes.string,
  dragRef: PropTypes.func,
  icon: PropTypes.string,
  dragDisabled: PropTypes.bool,
  outlined: PropTypes.bool,
  expanded: PropTypes.bool,
  isDraggingOver: PropTypes.bool,
  item: PropTypes.object,
  style: PropTypes.object,
  itemsTemplate: PropTypes.arrayOf(
    PropTypes.shape({
      render: PropTypes.func,
    }),
  ),
  highlighted: PropTypes.bool,
  actionsTemplate: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
    }),
  ).isRequired,
  isDragging: PropTypes.bool,
  expandedActionsTemplate: PropTypes.arrayOf(
    PropTypes.shape({
      render: PropTypes.func,
      access: PropTypes.func,
    }),
  ).isRequired,
  highlightedColor: PropTypes.string,
};

DraggableItemExpanded.defaultProps = {
  highlightedColor: 'shipCove',
};

export { DraggableItemExpanded };
