import {
  bankHolidayAvailabilityActionType,
  bankHolidayAvailabilitySuccessActionType,
  bankHolidayAvailabilityFailureActionType,
} from 'actions/Payments/bankHolidayAvailability';

const INITIAL_STATE = {
  isPending: false,
  isBankHoliday: false,
};

const BankHolidayAvailabilityReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case bankHolidayAvailabilityActionType:
      return {
        ...state,
        isPending: true,
      };
    case bankHolidayAvailabilitySuccessActionType:
      return {
        isPending: false,
        ...action.payload,
      };
    case bankHolidayAvailabilityFailureActionType:
      return {
        ...state,
        isPending: false,
      };
    default:
      return state;
  }
};

export { BankHolidayAvailabilityReducer };
