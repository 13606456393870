import axios from 'axios';

import { showSnackbar } from 'actions/shared/snackbar';

import {
  dangerOptions,
  successOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

const API_HOST_AUTH = process.env.REACT_APP_API;

const updateHedgeOuncesActionType = 'UPDATE_HEDGE_OUNCES';
const updateHedgeOuncesAction = () => ({
  type: updateHedgeOuncesActionType,
});

const updateHedgeOuncesSuccessActionType = `${updateHedgeOuncesActionType}_SUCCESS`;
const updateHedgeOuncesSuccessAction = () => ({
  type: updateHedgeOuncesSuccessActionType,
});

const updateHedgeOuncesFailureActionType = `${updateHedgeOuncesActionType}_FAILURE`;
const updateHedgeOuncesFailureAction = () => ({
  type: updateHedgeOuncesFailureActionType,
});

const updateHedgeOunces = (hedgeId, ounces) => dispatch => {
  dispatch(updateHedgeOuncesAction());

  return axios
    .patch(`${API_HOST_AUTH}/hedges/${hedgeId}/ounces`, ounces)
    .then(({ data: { message } }) => {
      dispatch(updateHedgeOuncesSuccessAction());
      dispatch(showSnackbar(successOptions, message));
    })
    .catch(({ data }) => {
      dispatch(updateHedgeOuncesFailureAction());
      dispatch(showSnackbar(dangerOptions, `Hedge ounces have not been updated. ${data.message}`));

      return Promise.reject(data);
    });
};

export {
  updateHedgeOuncesActionType,
  updateHedgeOuncesSuccessActionType,
  updateHedgeOuncesFailureActionType,
  updateHedgeOunces,
};
