import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getCompanyHedgesActionType = 'GET_COMPANY_HEDGES';
const getCompanyHedgesAction = () => ({
  type: getCompanyHedgesActionType,
});

const getCompanyHedgesSuccessActionType = `${getCompanyHedgesActionType}_SUCCESS`;
const getCompanyHedgesSuccessAction = data => ({
  type: getCompanyHedgesSuccessActionType,
  payload: {
    data,
  },
});
const getCompanyHedgesTableSuccessActionType = `${getCompanyHedgesActionType}_TABLE_SUCCESS`;
const getCompanyHedgesTableSuccessAction = (data, hedgeName) => ({
  type: getCompanyHedgesTableSuccessActionType,
  payload: {
    data,
    hedgeName,
  },
});

const getCompanyHedgesFailureActionType = `${getCompanyHedgesActionType}_FAILURE`;
const getCompanyHedgesFailureAction = () => ({
  type: getCompanyHedgesFailureActionType,
});

const getCompanyHedges = (companyId, hedgeType, hedgeName) => dispatch => {
  dispatch(getCompanyHedgesAction());

  return axios
    .get(`${API_HOST_AUTH}/hedges/${companyId}/${hedgeType}`)
    .then(({ data }) => {
      dispatch(getCompanyHedgesSuccessAction(data));
      dispatch(getCompanyHedgesTableSuccessAction(data, hedgeName));
      return data;
    })
    .catch(() => dispatch(getCompanyHedgesFailureAction()));
};

export {
  getCompanyHedgesActionType,
  getCompanyHedgesSuccessActionType,
  getCompanyHedgesFailureActionType,
  getCompanyHedgesTableSuccessActionType,
  getCompanyHedges,
};
