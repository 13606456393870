import axios from 'axios';

import { showSnackbar } from 'actions/shared/snackbar';

import {
  successOptions,
  dangerOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

const API_HOST_AUTH = process.env.REACT_APP_API;

export const updateDraftPaymentActionType = 'UPDATE_DRAFT_PAYMENT';
const updateDraftPaymentAction = () => ({
  type: updateDraftPaymentActionType,
});

export const updateDraftPaymentSuccessActionType = `${updateDraftPaymentActionType}_SUCCESS`;
const updateDraftPaymentSuccessAction = () => ({
  type: updateDraftPaymentSuccessActionType,
});

export const updateDraftPaymentFailureActionType = `${updateDraftPaymentActionType}_FAILURE`;
const updateDraftPaymentFailureAction = () => ({
  type: updateDraftPaymentFailureActionType,
});

export const updateDraftPayment = path => values => async dispatch => {
  dispatch(updateDraftPaymentAction());

  return axios
    .patch(`${API_HOST_AUTH}/${path}`, values)
    .then(({ data }) => {
      dispatch(updateDraftPaymentSuccessAction());
      dispatch(showSnackbar(successOptions, data.message));

      return {
        message: data.message,
      };
    })
    .catch(({ data }) => {
      dispatch(updateDraftPaymentFailureAction());
      dispatch(showSnackbar(dangerOptions, data.message));

      return Promise.reject(data);
    });
};

export const updateDraftFinalPayment = (paymentId, values) =>
  updateDraftPayment(`payments/${paymentId}/update-draft-final`)(values);
export const updateDraftPoolPayment = (paymentId, values) =>
  updateDraftPayment(`payments/pool/${paymentId}/update-draft`)(values);
