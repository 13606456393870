import {
  marketAvailabilityActionType,
  marketAvailabilitySuccessActionType,
  marketAvailabilityFailureActionType,
} from 'actions/Hedges/marketAvailability';

import dateAdapter from 'utils/date/dateAdapter';

const INITIAL_STATE = {
  isPending: false,
  validUntil: null,
  marketHoliday: false,
  marketTimeFrom: 0,
  marketTimeTo: 0,
  marketHours: '',
  marketDays: '',
};

const marketAvailabilityDetailsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case marketAvailabilityActionType:
      return {
        ...state,
        isPending: true,
      };
    case marketAvailabilitySuccessActionType:
      return {
        ...action.payload,
        isPending: false,
        validUntil:
          dateAdapter().minute > 30
            ? dateAdapter().endOf('h').valueOf()
            : dateAdapter().startOf('h').add(30, 'm').valueOf(),
      };
    case marketAvailabilityFailureActionType:
      return {
        ...state,
        isPending: false,
      };
    default:
      return state;
  }
};

export { marketAvailabilityDetailsReducer };
