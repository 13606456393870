import { connect } from 'react-redux';

import { acceptForMixing } from 'actions/Lots/acceptForMixing';
import { getLot } from 'actions/Lots/lotDetails';
import { getLotsForMixingOverview } from 'actions/Lots/mixingOverview';
import { openModal } from 'actions/shared/modal';
import { showSnackbar } from 'actions/shared/snackbar';

import { acceptForMixingModal } from 'shared/helpers/constants/modalConstants';
import {
  successOptions,
  dangerOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

function AcceptForMixingBase(props) {
  const acceptForMixingAction = () => {
    props.openModal(acceptForMixingModal, () => {
      props
        .acceptForMixing(props.lot._id)
        .then(res => {
          props.getLotsForMixingOverview();
          props.showSnackbar(successOptions, res.message);
          props.successCallback && props.successCallback();
        })
        .catch(err => {
          props.getLot(props.lot._id);
          props.showSnackbar(dangerOptions, err.message);
        });
    });
  };

  return props.children({ acceptForMixing: acceptForMixingAction });
}

const mapDispatchToProps = {
  openModal,
  showSnackbar,
  acceptForMixing,
  getLot,
  getLotsForMixingOverview,
};

const AcceptForMixing = connect(null, mapDispatchToProps)(AcceptForMixingBase);

export { AcceptForMixing, AcceptForMixingBase };
