import PropTypes from 'prop-types';
import styled from 'styled-components';

import { DELIVERIES } from 'shared/helpers/constants/deliveries/deliveriesConstants';
import { dateFormat } from 'shared/helpers/parsers/date';
import { formatToUS, formatDate } from 'shared/helpers/parsers/formaters';

const Paragraph = styled.p`
  font-size: 0.875rem;
  font-weight: 400;
  font-weight: ${({ lead }) => (lead ? '600' : '400')};
  margin-bottom: 0.6rem;
`;

const DeliveryDetails = ({
  delivery: {
    relatedLot: { grgLotNumber },
    createdBy,
    createdAt,
    relatedHedge,
    palladiumOz,
    platinumOz,
    rhodiumOz,
  },
}) => {
  const { hedgeName } = relatedHedge;

  const renderTroyOunces = ({ platinumOz, palladiumOz, rhodiumOz }) => `
    ${DELIVERIES.PT} ${formatToUS(+platinumOz, 3)} -
    ${DELIVERIES.PD} ${formatToUS(+palladiumOz, 3)} -
    ${DELIVERIES.RH} ${formatToUS(+rhodiumOz, 3)} 
  `;

  return (
    <div>
      <Paragraph lead>
        {DELIVERIES.SELECTED_HEDGE}: {hedgeName}
      </Paragraph>
      <Paragraph>
        {DELIVERIES.RELATED_LOT}: {grgLotNumber}
      </Paragraph>
      <Paragraph>
        {renderTroyOunces({
          palladiumOz,
          platinumOz,
          rhodiumOz,
        })}
      </Paragraph>
      <Paragraph>
        {DELIVERIES.CREATED_BY}: {createdBy.firstName} {createdBy.lastName}
      </Paragraph>
      <Paragraph>
        {DELIVERIES.CREATED_ON}: {formatDate(createdAt, dateFormat)}
      </Paragraph>
    </div>
  );
};

DeliveryDetails.propTypes = {
  delivery: PropTypes.shape({
    _id: PropTypes.string,
    createdAt: PropTypes.number,
    createdBy: PropTypes.object,
    palladiumOz: PropTypes.number,
    platinumOz: PropTypes.number,
    rhodiumOz: PropTypes.number,
    relatedHedge: PropTypes.object,
    relatedLot: PropTypes.object,
  }),
};

export { DeliveryDetails };
