import PropTypes from 'prop-types';
import { useState } from 'react';

import { ListCollapse } from 'shared/components/ListCollapse/ListCollapse';
import { OverviewListSection } from 'shared/components/OverviewList/OverviewListSection/OverviewListSection';

import { StyledOverviewLotsSectionWrapper } from './StyledOverviewLotsSection';

export const OverviewLotsSection = ({ id, title, total, disableTransform, children, buttons }) => {
  const [isSectionDisplayed, setIsSectionDisplayed] = useState(true);

  const sectionToggle = () => {
    total && setIsSectionDisplayed(prev => !prev);
  };

  return (
    <StyledOverviewLotsSectionWrapper
      id={id}
      isOpen={isSectionDisplayed}
      disableTransform={disableTransform}
      total={total}
    >
      <OverviewListSection isOpen={isSectionDisplayed} total={total}>
        <div>
          <ListCollapse
            collapse={isSectionDisplayed}
            toggleList={sectionToggle}
            label={Number.isFinite(total) ? `${title} (${total})` : title}
          />
          {!!buttons && buttons()}
        </div>
      </OverviewListSection>
      <div>{children}</div>
    </StyledOverviewLotsSectionWrapper>
  );
};

OverviewLotsSection.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  total: PropTypes.number,
  disableTransform: PropTypes.bool,
  buttons: PropTypes.element,
  children: PropTypes.element,
};
