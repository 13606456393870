import styled from 'styled-components';

import { media } from 'shared/helpers/styling/styling';

export const StyledCompaniesFilters = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${media.mobile`justify-content: center;`}
`;

export const StyledCompaniesFiltersItem = styled.div`
  width: 45%;
  ${media.mobile`width: 70%;`}
`;
