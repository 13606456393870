import axios from 'axios';

import { showSnackbar } from 'actions/shared/snackbar';

import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';
import {
  dangerOptions,
  successOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

const API_HOST_AUTH = process.env.REACT_APP_API;

const releaseLotActionType = 'RELEASE_LOT';
const releaseLotAction = () => ({
  type: releaseLotActionType,
});

const releaseLotSuccessActionType = `${releaseLotActionType}_SUCCESS`;
const releaseLotSuccessAction = () => ({
  type: releaseLotSuccessActionType,
});

const releaseLotFailureActionType = `${releaseLotActionType}_FAILURE`;
const releaseLotFailureAction = () => ({
  type: releaseLotFailureActionType,
});

const releaseLot = lotId => dispatch => {
  dispatch(releaseLotAction());

  return axios
    .patch(`${API_HOST_AUTH}/lots/${lotId}/release`)
    .then(res => {
      dispatch(releaseLotSuccessAction());
      dispatch(showSnackbar(successOptions, res.data.message));
    })
    .catch(() => {
      dispatch(releaseLotFailureAction());
      dispatch(showSnackbar(dangerOptions, LOTS.ERRORS.RELEASE_ERROR));
    });
};

export {
  releaseLot,
  releaseLotActionType,
  releaseLotSuccessActionType,
  releaseLotFailureActionType,
};
