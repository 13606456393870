import { connect } from 'react-redux';

import { getCompaniesContact } from 'actions/Locations/getCompaniesContact';
import { getRegionList } from 'actions/Locations/getRegionList';
import { getLocation, clearLocationDetails } from 'actions/Locations/locationDetails';
import { updateLocation as updateLocationAction } from 'actions/Locations/updateLocation';
import { openModal, closeModal } from 'actions/shared/modal';
import { hideSnackbar, showSnackbar } from 'actions/shared/snackbar';

import { UpdateLocation } from './UpdateLocation';

const mapStateToProps = ({ auth, modal, updateLocation, locationDetails }) => ({
  auth,
  modal,
  updateLocation,
  locationDetails,
});

const mapDispatchToProps = {
  showSnackbar,
  hideSnackbar,
  updateLocation: updateLocationAction,
  getLocation,
  clearLocationDetails,
  getRegionList,
  getCompaniesContact,
  openModal,
  closeModal,
};

const ConnectedUpdateLocation = connect(mapStateToProps, mapDispatchToProps)(UpdateLocation);

export { ConnectedUpdateLocation };
