import axios from 'axios';

import { PACKAGES } from 'shared/helpers/constants/packages/packageConstants';
import { messageTypes } from 'shared/helpers/constants/snackbar/snackbarMessageTypes';
import { parsePackageData } from 'shared/helpers/dataParsers/packages/parsePackageData';

const API_HOST_AUTH = process.env.REACT_APP_API;

const updatePackageActionType = 'UPDATE_PACKAGE';

function updatePackageAction() {
  return {
    type: updatePackageActionType,
  };
}

const updatePackageSuccessActionType = `${updatePackageActionType}_SUCCESS`;

function updatePackageSuccessAction() {
  return {
    type: updatePackageSuccessActionType,
  };
}

const updatePackageFailureActionType = `${updatePackageActionType}_FAILURE`;

function updatePackageFailureAction() {
  return {
    type: updatePackageFailureActionType,
  };
}

const updatePackageFactory =
  (url, multi = false) =>
  pkg =>
  dispatch => {
    dispatch(updatePackageAction());

    return axios
      .put(
        `${API_HOST_AUTH}/${url}/${pkg.id}`,
        multi ? { packages: [parsePackageData(pkg)] } : parsePackageData(pkg),
      )
      .then(res => {
        dispatch(updatePackageSuccessAction());
        return {
          id: res.data.id,
          message: res.data.message,
          messageType: messageTypes.success,
        };
      })
      .catch(error => {
        dispatch(updatePackageFailureAction());
        return Promise.reject({
          message: `${PACKAGES.UPDATE_PACKAGE_ERROR} ${error.data.message}`,
          messageType: messageTypes.error,
        });
      });
  };

const updatePackage = updatePackageFactory('packages');
const updatePackageMaterialCount = updatePackageFactory('packages/material-count-required', true);
const updateIncomingPackageForCustomer = updatePackageFactory('packages/incoming/customer');
const updateIncomingPackageForCustomerMaterialCount = updatePackageFactory(
  'packages/incoming/customer/material-count-required',
  true,
);
const updateIncomingPackageForGlobal = updatePackageFactory('packages/incoming/global');
const updatePackageWeight = updatePackageFactory('packages/weight');
const updatePackageWeighMaterialCount = updatePackageFactory(
  'packages/weight/material-count-required',
  true,
);

export {
  updatePackageActionType,
  updatePackageAction,
  updatePackageSuccessActionType,
  updatePackageSuccessAction,
  updatePackageFailureActionType,
  updatePackageFailureAction,
  updatePackage,
  updatePackageMaterialCount,
  updateIncomingPackageForCustomer,
  updateIncomingPackageForCustomerMaterialCount,
  updateIncomingPackageForGlobal,
  updatePackageWeight,
  updatePackageWeighMaterialCount,
};
