import React, { Component } from 'react';

import { FiltersDropdown } from 'shared/components/FiltersDropdown/FiltersDropdown';
import { AGREEMENTS } from 'shared/helpers/constants/agreements/agreementsConstants';
import { availableAgreementsStatuses } from 'shared/helpers/constants/filters/agreementsStatuses';

import { StyledAgreementFilters, StyledAgreementFiltersItem } from './StyledAgreementsFilters';

class AgreementsFilters extends Component {
  filterFunction = name => value => {
    this.props.onFiltersChanged({
      ...this.props.filters,
      [name]: value,
    });
  };

  getStatuses = userType =>
    availableAgreementsStatuses[userType]
      ? availableAgreementsStatuses[userType]
      : availableAgreementsStatuses.all;

  render() {
    const {
      filters: { status },
      auth,
    } = this.props;

    return (
      <StyledAgreementFilters>
        <StyledAgreementFiltersItem>
          <FiltersDropdown
            label={AGREEMENTS.STATUS}
            options={this.getStatuses(auth.user.userType)}
            selected={status || []}
            filterFunction={this.filterFunction('status')}
          />
        </StyledAgreementFiltersItem>
      </StyledAgreementFilters>
    );
  }
}

export { AgreementsFilters };
