import React, { Component } from 'react';

import { OverviewList } from 'shared/components/OverviewList/OverviewList';
import { PreloaderInline } from 'shared/components/PreloaderInline/PreloaderInline';
import { DASHBOARD } from 'shared/helpers/constants/dashboardConstants';
import { NOTIFICATION } from 'shared/helpers/constants/notifications/notificationConstants';
import {
  customerItemsTemplate,
  itemsTemplate,
  itemsTemplateMobile,
  customerItemsTemplateMobile,
  itemsDetailsTemplate,
} from 'shared/helpers/constants/notifications/notificationsListTableConfig';
import {
  notificationsTypesOwner,
  notificationsTypesSupport,
  notificationsTypesWarehouse,
  notificationsTypesCustomer,
  notificationsTypesGrading,
} from 'shared/helpers/constants/notifications/notificationsTypes';
import { isTablet } from 'shared/helpers/constants/resolutionsConstants';
import {
  isCustomerOrGrading,
  isCustomerUser,
  isGradingUser,
  isOwnerUser,
  isSupportUser,
  isWarehouseUser,
} from 'shared/helpers/matchers/checkUserType';

import { history } from 'utils/history';
import { getBrowserWidth } from 'utils/responsive';

import { NotificationsListHeader } from './NotificationsList.styles';
import { OverviewDashboardSection } from './OverviewDashboardSection/OverviewDashboardSection';

class NotificationsList extends Component {
  state = {
    windowWidth: getBrowserWidth(),
  };

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);

    const { getNotificationsOnDashboard } = this.props;

    getNotificationsOnDashboard(3);
  }

  componentWillUnmount() {
    this.props.clearDashboardAction();
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({
      windowWidth: getBrowserWidth(),
    });
  };

  goToNotification = notification => {
    window.scrollTo(0, 0);
    !notification.alreadyRead && this.props.markAsRead(notification._id);
    return (
      history.location.pathname.includes('dashboard') &&
      notification.path &&
      history.push(notification.path)
    );
  };

  get tableActions() {
    return [
      {
        icon: 'arrow-right',
        action: 'Notification',
        onClick:
          ({ _id }) =>
          () =>
            !this.props.notificationDetails.isPending &&
            this.props
              .getNotification(_id, 'dashboard')
              .then(notification => this.goToNotification(notification)),
      },
    ];
  }

  renderList = type => {
    const {
      auth: { user },
      dashboardNotificationsList: { dashboardNotifications, isPending },
      getNotificationsOnDashboardWithType,
    } = this.props;
    let total = 0;
    let itemsNumber = 0;

    if (dashboardNotifications[`${type.value}_count`]) {
      total = dashboardNotifications[`${type.value}_count`].total;
      itemsNumber = dashboardNotifications[type.value].length;
    }

    const isMobile = this.state.windowWidth < isTablet;
    return (
      <OverviewList
        input={{
          items: { docs: dashboardNotifications[type.value], pages: total / itemsNumber },
          isPending,
        }}
        getList={(page, limit) => getNotificationsOnDashboardWithType(limit, page, type.value)}
        listLimit={3}
        itemsTemplate={isCustomerOrGrading(user.userType) ? customerItemsTemplate : itemsTemplate}
        itemsTemplateMobile={
          isCustomerOrGrading(user.userType) ? customerItemsTemplateMobile : itemsTemplateMobile
        }
        itemDetailsTemplate={itemsDetailsTemplate}
        actionsTemplate={this.tableActions}
        isMobile={isMobile}
        label={NOTIFICATION.NOTIFICATIONS}
        actionsContext={this}
        dashboard
      />
    );
  };

  render() {
    const {
      auth: {
        user: { userType },
      },
      dashboardNotificationsList: { dashboardNotifications },
      notificationsCount: { notificationsCount },
    } = this.props;

    const notificationsTypes =
      (isOwnerUser(userType) && notificationsTypesOwner) ||
      (isSupportUser(userType) && notificationsTypesSupport) ||
      (isWarehouseUser(userType) && notificationsTypesWarehouse) ||
      (isCustomerUser(userType) && notificationsTypesCustomer) ||
      (isGradingUser(userType) && notificationsTypesGrading);

    return (
      <>
        <PreloaderInline loading={dashboardNotifications && dashboardNotifications.length === 0} />
        {!notificationsCount && (
          <NotificationsListHeader>
            {DASHBOARD.YOU_DO_NOT_HAVE_NOTIFICATIONS}
          </NotificationsListHeader>
        )}
        {!!dashboardNotifications &&
          Object.values(notificationsTypes).map(
            type =>
              !!dashboardNotifications[type.value] &&
              !!dashboardNotifications[type.value].length && (
                <OverviewDashboardSection
                  key={type.value}
                  title={type.name}
                  total={dashboardNotifications[type.value]}
                >
                  {this.renderList(type)}
                </OverviewDashboardSection>
              ),
          )}
      </>
    );
  }
}

export { NotificationsList };
