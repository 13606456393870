import PropTypes from 'prop-types';
import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';

import { getBankHolidays } from 'actions/Payments/bankHolidaysList';
import { getPaymentAmount } from 'actions/Payments/getPaymentAmount';
import { showSnackbar, hideSnackbar } from 'actions/shared/snackbar';

import { FieldInput, FieldDate } from 'shared/components/Fields';
import { FieldButtonSelect } from 'shared/components/Fields/FieldButtonSelect/FieldButtonSelect';
import { FieldSelect } from 'shared/components/Fields/FieldSelect/FieldSelect';
import { Form, Section } from 'shared/components/Form';
import { statusesForGlobal } from 'shared/helpers/constants/lots/statusesForGlobal';
import { availableFundsTypes } from 'shared/helpers/constants/payments/fundsTypes';
import { PAYMENTS } from 'shared/helpers/constants/payments/paymentsConstants';
import { dangerOptions } from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { parseAllLotsSelectData } from 'shared/helpers/dataParsers/lot/parseLotsSelectData';
import { calculateMinimalFinalPaymentDelay } from 'shared/helpers/dataParsers/payments/calculateMinimalFinalPaymentDelay';
import { getBankHolidaysDates } from 'shared/helpers/dataParsers/payments/parseBankHolidaysData';
import { isInternalCompany } from 'shared/helpers/matchers/checkCompanyType';
import { mapArray } from 'shared/helpers/parsers/select';
import { insertCommas } from 'shared/helpers/parsers/text';
import { validate } from 'shared/helpers/validations/payments/requestPaymentValidation';

const selector = formValueSelector('RequestPaymentForm');

const RequestFinalPaymentFormLayout = ({
  handleSubmit,
  invalid,
  change,
  onCancel,
  data,
  resetSection,
  history,
}) => {
  const { companyType } = useSelector(({ auth }) => auth.user.relatedCompany);
  const relatedLot = useSelector(state => selector(state, 'relatedLot'));
  const { filters, bankHolidays } = useSelector(state => state.bankHolidaysList);
  const dispatch = useDispatch();
  const [finalPaymentRequested, setFinalPaymentRequested] = useState(false);
  const [selectedLot, setSelectedLot] = useState(null);

  const getPaymentAmountForLot = useCallback(
    lot => {
      const lotValue = lot && lot.value;
      setSelectedLot(lotValue);
      if (!lotValue) resetSection('requestedFinalPaymentDate');

      resetSection('paymentAmount');
      dispatch(hideSnackbar());
      setFinalPaymentRequested(true);

      if (
        lot &&
        (lot.statusForGlobal === statusesForGlobal.invoice_accepted ||
          lot.statusForGlobal === statusesForGlobal.ship_summary_accepted)
      ) {
        lotValue &&
          dispatch(getPaymentAmount(lotValue))
            .then(paymentAmount => {
              if (isInternalCompany(companyType)) change('paymentAmount', 0);

              if (
                lot.statusForGlobal === statusesForGlobal.invoice_accepted &&
                !isInternalCompany(companyType)
              ) {
                change('paymentAmount', insertCommas(String(paymentAmount.totalFinalPayment)));
                if (paymentAmount.totalFinalPayment <= 0) {
                  dispatch(showSnackbar(dangerOptions, PAYMENTS.REMAINING_AMOUNT_IS_TOO_LOW));
                }
              }

              if (paymentAmount.finalPaymentRequested) {
                setFinalPaymentRequested(true);
                dispatch(showSnackbar(dangerOptions, PAYMENTS.FINAL_PAYMENT_ALREADY_REQUESTED));
              } else {
                setFinalPaymentRequested(false);
              }
            })
            .catch(() => history.push('/payments'));
      } else {
        setFinalPaymentRequested(false);
      }
    },
    [resetSection, dispatch, change, hideSnackbar, showSnackbar, history],
  );

  useEffect(() => {
    dispatch(getBankHolidays(filters));

    if (data.length === 1) {
      const [singleLot] = parseAllLotsSelectData(data);
      change('relatedLot', singleLot);

      if (singleLot._id !== (relatedLot && relatedLot.value)) {
        getPaymentAmountForLot(singleLot);
      }
    }
  }, [data, change, dispatch]);

  return (
    <Form
      header={PAYMENTS.PAYMENT}
      onSubmit={handleSubmit}
      onCancel={onCancel}
      submitDisabled={invalid || finalPaymentRequested}
    >
      <Section
        template={[
          `relatedLot ${isInternalCompany(companyType) ? '.' : 'paymentAmount'}`,
          'requestedFinalPaymentDate fundsType',
          'additionalInfo .',
        ]}
      >
        <Field
          name="relatedLot"
          component={FieldSelect}
          options={parseAllLotsSelectData(data)}
          disabled={data.length === 1}
          label={PAYMENTS.RELATED_LOT}
          field="required"
          onChange={getPaymentAmountForLot}
        />
        <Field
          name="fundsType"
          component={FieldButtonSelect}
          options={mapArray(availableFundsTypes.assayInternal)}
          label={PAYMENTS.FUNDS_TYPE}
          field="required"
        />
        {relatedLot &&
          relatedLot.statusForGlobal === statusesForGlobal.invoice_accepted &&
          !isInternalCompany(companyType) && (
            <Field
              name="paymentAmount"
              component={FieldInput}
              label={PAYMENTS.PAYMENT_AMOUNT}
              prefix="$"
              disabled
            />
          )}
        <Field
          name="requestedFinalPaymentDate"
          component={FieldDate}
          label={PAYMENTS.REQUESTED_FINAL_PAYMENT_DATE}
          minDate={calculateMinimalFinalPaymentDelay()}
          field="required"
          disabled={!selectedLot}
          excludeDates={getBankHolidaysDates(bankHolidays)}
          onlyWeekdays
        />
        <Field
          name="additionalInfo"
          component={FieldInput}
          label={PAYMENTS.ADDITIONAL_INFORMATION}
          field="optional"
        />
      </Section>
    </Form>
  );
};

const RequestFinalPaymentForm = reduxForm({
  form: 'RequestPaymentForm',
  validate,
})(RequestFinalPaymentFormLayout);

RequestFinalPaymentFormLayout.propTypes = {
  change: PropTypes.func,
  data: PropTypes.array,
  handleSubmit: PropTypes.func,
  history: PropTypes.object,
  invalid: PropTypes.bool,
  onCancel: PropTypes.func,
  resetSection: PropTypes.func,
};

export { RequestFinalPaymentForm, RequestFinalPaymentFormLayout };
