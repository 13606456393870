import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getLotPaymentsType = 'GET_LOT_PAYMENTS';
const getLotPaymentsAction = () => ({
  type: getLotPaymentsType,
});

const getLotPaymentsSuccessType = `${getLotPaymentsType}_SUCCESS`;
const getLotPaymentsSuccessAction = payments => ({
  type: getLotPaymentsSuccessType,
  payload: {
    payments,
  },
});

const getLotPaymentsFailureType = `${getLotPaymentsType}_FAILURE`;
const getLotPaymentsFailureAction = () => ({
  type: getLotPaymentsFailureType,
});

const clearLotPaymentsType = 'CLEAR_LOT_PAYMENTS';
const clearLotPayments = () => ({
  type: clearLotPaymentsType,
});

const getLotPayments = (lotId, limit) => dispatch => {
  dispatch(getLotPaymentsAction());

  return axios
    .get(`${API_HOST_AUTH}/payments/list/${lotId}/${limit}`)
    .then(res => {
      dispatch(getLotPaymentsSuccessAction(res.data));

      return res.data;
    })
    .catch(err => {
      dispatch(getLotPaymentsFailureAction());

      return Promise.reject(err.data);
    });
};

export {
  getLotPaymentsType,
  getLotPaymentsSuccessType,
  getLotPaymentsFailureType,
  clearLotPaymentsType,
  getLotPayments,
  clearLotPayments,
};
