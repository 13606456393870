import dateAdapter from 'utils/date/dateAdapter';

const today = () => dateAdapter().startOf('d').valueOf();

const createAgreementConfig = {
  defaultValues: {
    get startDate() {
      return today();
    },
  },
};

export { createAgreementConfig };
