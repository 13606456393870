const saveUrlActionType = 'SAVE_URL';
function saveUrlAction(url, userId, notification) {
  return {
    type: saveUrlActionType,
    payload: {
      url,
      userId,
      notification,
    },
  };
}

const clearSavedUrlActionType = 'CLEAR_SAVED_URL';
function clearSavedUrlAction() {
  return {
    type: clearSavedUrlActionType,
  };
}

export { saveUrlActionType, clearSavedUrlActionType, saveUrlAction, clearSavedUrlAction };
