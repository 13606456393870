const AGREEMENTS = {
  GENERAL_DETAILS: 'General details',
  ACTIVITY: 'Activity',
  AGREEMENTS: 'Agreements',
  AGREEMENT: 'Agreement',
  ALL_AGREEMENTS: 'All agreements',
  CREATE_AN_AGREEMENT: 'Create an agreement',
  EDIT_AGREEMENT: 'Edit agreement',
  STATUS: 'Status',
  START_DATE: 'Start date',
  END_DATE: 'End date',
  DIGITAL_SIGNATURE_REQUIRED: 'Digital signature required',
  ACTUAL_EXPIRATION_DATE: 'Actual expiration date',
  DOCUMENT_LINK: 'Document link',
  GRADING_DOCUMENT: 'Grading document',
  DETAILS: 'Details',
  CREATED_BY: 'Created by',
  UPDATED_BY: 'Updated by',
  RELATED_COMPANY: 'Related company',
  ACTIONS: 'Actions',
  SUBMITTED_BY: 'Submitted by',
  GLOBAL_DECISION_BY: 'Global decision by',
  GRADING_DECISION_ON: 'Grading decision on',
  GRADING_DECISION_BY: 'Grading decision by',
  GLOBAL_SIGNED_ON: 'Global signed on',
  CREATE_DOCUMENT: 'Create a document',
  CREATE_DOCUMENT_SUCCESS: 'Document was successfully created for agreement',
  CREATE_DOCUMENT_FAILURE: 'There was problem with creating document for agreement',
  DOCUMENT_DOWNLOAD: 'download',
  UPLOAD: 'Upload',
  UPLOAD_SIGNED: 'Upload signed document',
  REUPLOAD_SIGNED: 'Reupload signed document',
  UPLOAD_SIGNED_PROGRESS: 'Upload in progress',
};

export { AGREEMENTS };
