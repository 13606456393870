import PropTypes from 'prop-types';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, getFormValues, reduxForm } from 'redux-form';

import { getLotsToReassignPayment } from 'actions/Lots/getLotsToReassignPayment';
import { reassignPayment } from 'actions/Payments/reassignPayment';

import { SubmitButton } from 'shared/components/Buttons';
import { FieldSearch } from 'shared/components/Fields';
import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';
import { statusesForGlobal } from 'shared/helpers/constants/lots/statusesForGlobal';
import { PAYMENTS } from 'shared/helpers/constants/payments/paymentsConstants';
import { PLACEHOLDERS } from 'shared/helpers/constants/placeholders';
import { SHARED } from 'shared/helpers/constants/sharedConstants';
import { validate } from 'shared/helpers/validations/payments/reassignPaymentValidation';

import {
  StyledActions,
  StyledForm,
} from 'pages/Hedges/components/HedgesManagement/components/HedgeReassign/StyledHedgeReassignForm';
import { WarningMessage } from 'pages/Hedges/components/HedgesManagement/components/HedgeReassign/components/WarningMessage';

const formName = 'PaymentReassignForm';
const showWarningForStatuses = [
  statusesForGlobal.invoice_generated,
  statusesForGlobal.invoice_accepted,
];

const PaymentReassignFormBase = ({ paymentId, handleSubmit, pristine, invalid }) => {
  const dispatch = useDispatch();
  const [defaultOptions, setDefaultOptions] = useState([]);
  const { newLot } = Object(useSelector(state => getFormValues(formName)(state)));

  useEffect(() => {
    dispatch(getLotsToReassignPayment(paymentId)).then(data => {
      return setDefaultOptions(data?.options);
    });
  }, [paymentId]);

  const handleShowWarning = () => {
    if (showWarningForStatuses.includes(newLot?.statusForGlobal)) {
      return newLot?.statusForGlobal;
    }

    return false;
  };

  const handleOnSubmit = () => dispatch(reassignPayment(paymentId, newLot?.value));
  const handleGetOptions = input => dispatch(getLotsToReassignPayment(paymentId, input));
  return (
    <>
      <header>
        <h3>{PAYMENTS.REASSIGN_PAYMENT}</h3>
      </header>
      <StyledForm noValidate onSubmit={handleSubmit(handleOnSubmit)}>
        <Field
          name="newLot"
          component={FieldSearch}
          label={LOTS.NEW}
          field="required"
          defaultOptions={defaultOptions}
          cacheOptions
          placeholder={PLACEHOLDERS.SEARCH}
          getOptions={handleGetOptions}
          maxMenuHeight={235}
        />
        <StyledActions displayWarning={handleShowWarning()}>
          <WarningMessage selectedLotStatus={handleShowWarning()} destination="payment" />
          <SubmitButton id="reassignPayment" label={SHARED.SUBMIT} disabled={pristine || invalid} />
        </StyledActions>
      </StyledForm>
    </>
  );
};

PaymentReassignFormBase.propTypes = {
  invalid: PropTypes.bool,
  pristine: PropTypes.bool,
  paymentId: PropTypes.string,
  handleSubmit: PropTypes.func,
};

const PaymentReassignForm = reduxForm({
  form: formName,
  validate,
})(PaymentReassignFormBase);

export { PaymentReassignForm };
