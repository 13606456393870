import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import { createSettlementDocument } from 'actions/Payments/createSettlementDocument';
import { showSnackbar } from 'actions/shared/snackbar';

import {
  successOptions,
  dangerOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

const CreateSettlementDocumentLayout = props => {
  const createSettlementDocumentAction = () => {
    props
      .createSettlementDocument(props.match.params.id)
      .then(res => props.showSnackbar(successOptions, res.message))
      .catch(err => props.showSnackbar(dangerOptions, err.message));
  };

  return props.children({ createSettlementDocument: createSettlementDocumentAction });
};

const mapDispatchToProps = {
  showSnackbar,
  createSettlementDocument,
};

const CreateSettlementDocument = compose(
  withRouter,
  connect(null, mapDispatchToProps),
)(CreateSettlementDocumentLayout);

export { CreateSettlementDocument, CreateSettlementDocumentLayout };
