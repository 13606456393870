import React from 'react';
import { connect } from 'react-redux';

import { hedgesChangeType } from 'actions/Hedges/hedgesChangeType';
import { openModal } from 'actions/shared/modal';
import { showSnackbar } from 'actions/shared/snackbar';

import { ModalCustom } from 'components/ModalCustom/ModalCustom';

import { message } from 'shared/helpers/constants/hedges/validateHedgesConstants';
import {
  successOptions,
  dangerOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { humanize } from 'shared/helpers/parsers/text';

import { HedgesChangeTypeForm } from './HedgesChangeTypeForm';

function HedgesChangeTypeBase(props) {
  const changeType = ({ hedgeType }) => {
    props
      .hedgesChangeType(props.hedge, hedgeType && hedgeType.value)
      .then(res => {
        props.showSnackbar(successOptions, res.message);
        props.toggle();
      })
      .catch(() => {
        props.showSnackbar(dangerOptions, message.HEDGES_UPDATE_ERROR);
        props.toggle();
      });
  };

  return (
    <ModalCustom isOpen={props.open} modalClose={props.toggle}>
      <HedgesChangeTypeForm
        onSubmit={changeType}
        initialValues={{
          hedgeType: {
            value: props.hedge.hedgeType,
            label: humanize(props.hedge.hedgeType),
          },
        }}
      />
    </ModalCustom>
  );
}

const mapDispatchToProps = {
  openModal,
  showSnackbar,
  hedgesChangeType,
};

const HedgesChangeType = connect(null, mapDispatchToProps)(HedgesChangeTypeBase);

export { HedgesChangeType, HedgesChangeTypeBase };
