import React, { forwardRef } from 'react';
import styled from 'styled-components';

import { Button } from 'shared/components/Buttons/Button';
import { Icon } from 'shared/components/Icon/Icon';

const IconButton = forwardRef(({ icon, color, className, iconMargin, ...props }, ref) => (
  <Button
    {...props}
    ref={ref}
    noOutline
    noBoxShadow
    fitToContent
    margin={false}
    className={className}
  >
    <Icon icon={icon} color={color} margin={iconMargin} />
  </Button>
));

const IconButtonStyled = styled(IconButton)`
  border: none;
  border-radius: 50%;

  &:hover {
    opacity: 0.5;
  }

  &:disabled {
    border: none;
  }
`;

export { IconButtonStyled as IconButton };
