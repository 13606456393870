import { Children, useContext, memo } from 'react';
import styled, { css } from 'styled-components';

import { Required } from 'shared/components/Fields/FieldWrapper/components/Label';
import { filterContext } from 'shared/components/FieldsFilter/FieldsFilter';
import { cssVar, media } from 'shared/helpers/styling/styling';

import { configNames } from 'pages/Packages/components/PackagesManagement/components/PackageForm/components/PackageForm/fieldsConfig';

const Header = ({ actions, className, children }) => (
  <div className={className}>
    {children}
    {actions}
  </div>
);

const HeaderStyled = styled(Header)`
  grid-area: header;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 35px;
  padding: 7px 0 7px 5px;
  color: ${cssVar('alto')};
  background-color: ${cssVar('outerSpaceBitLighter')};
  border-bottom: 1px solid ${cssVar('outerSpaceBitLighter')};
  font-size: 0.75rem;
  ${({ headerPadding }) =>
    css`
      padding: ${headerPadding};
    `};

  & > p {
    margin-bottom: 0;
  }
`;

const Content = styled.div`
  grid-column: ${({ index }) => `
    ${index + 1} / span 1;
  `};
`;

const Cell = styled.div`
  ${({ highlight }) =>
    highlight &&
    css`
      padding: 20px 0;
      background-color: ${cssVar('riverBedPkgs')};
    `}
`;

const PackageSectionLayout = memo(({ className, onClick, children, header }) => {
  const filterFunc = useContext(filterContext);
  const dataName = header ? 'header-row' : 'package-row';
  return (
    <div className={className} onClick={onClick} data-testid={dataName} data-cy={dataName}>
      {Children.toArray(children)
        .filter(child => !!child && filterFunc(child))
        .map((child, i) => (
          <Content key={i} index={i}>
            {child.props && child.props.label && (
              <HeaderStyled>
                <p>
                  {child.props.label}
                  <Required required={child.props.required} />
                </p>
              </HeaderStyled>
            )}
            {!header && (
              <Cell style={{ gridArea: 'content' }} highlight={child.props.highlight}>
                {child}
              </Cell>
            )}
          </Content>
        ))}
    </div>
  );
});

const PackageSection = styled(PackageSectionLayout)`
  display: grid;
  grid-template-columns: ${({ config }) => {
    switch (config) {
      case configNames.postMix:
        return '90px minmax(200px, 300px) minmax(150px, 300px) minmax(180px, 220px) 180px minmax(180px, 260px);';
      case configNames.blocked:
        return '90px 150px 1fr';
      case configNames.postMill:
        return '90px minmax(200px, 300px) minmax(150px, 300px) minmax(180px, 220px) 180px minmax(180px, 260px);';
      case configNames.postShears:
        return '90px minmax(200px, 300px) minmax(150px, 300px) minmax(180px, 220px) minmax(180px, 260px);';
      case configNames.packingList:
      case configNames.editMultiplePackages:
        return '80px minmax(140px, 200px) minmax(140px, 200px) minmax(140px, 250px) minmax(160px, 260px) minmax(160px, 260px) minmax(110px, 150px) minmax(150px, 260px) minmax(150px, 260px) 70px;';
      case configNames.placeholder:
        return '80px 1fr';
      case configNames.samplePackages:
        return '110px 1fr';
      default:
        return '90px minmax(200px, 300px) minmax(150px, 300px) minmax(180px, 260px) minmax(180px, 260px);';
    }
  }};

  grid-auto-columns: minmax(120px, 1fr);
  background-color: ${cssVar('limedSpruceForm')};
  min-height: 100px;
  width: 100%;
  color: ${cssVar('alabaster')};
  margin-bottom: 1px;

  ${media.smallDesktop`
    width: fit-content
  `}

  ${({ header }) =>
    header &&
    css`
      max-height: 35px;
      min-height: 35px;
      height: 35px;
    `}

  ${({ edited }) =>
    edited &&
    css`
      border: 1px solid ${cssVar('atlantis')};
    `};

  ${({ hasError }) =>
    hasError &&
    css`
      border: 3px dashed ${cssVar('appleBlossom')};

      &:first-child {
        border-top-width: 4px;
      }
    `};

  & > div {
    display: grid;
    align-content: center;
    border-bottom: 1px solid ${cssVar('riverBedDarker')};
    background-color: ${cssVar('limedSpruceForm')};

    ${({ focused }) =>
      focused &&
      css`
        background-color: ${cssVar('riverBedPkgs')};
      `};

    ${({ isBlocked }) =>
      isBlocked &&
      css`
        background-color: transparent;
        border-bottom: none;
      `};

    &:not(:last-child) {
      border-right: 1px solid ${cssVar('riverBedDarker')};
    }

    grid-template-areas:
      'header header'
      'content content';
  }

  ${({ focused, edited }) =>
    !focused &&
    !edited &&
    css`
      :hover {
        cursor: pointer;

        & > * {
          background-color: ${cssVar('limedSpruceCalendar')};
        }
      }
    `}
  ${({ isBlocked }) =>
    isBlocked &&
    css`
      background-image: repeating-linear-gradient(
        -45deg,
        ${cssVar('limedSpruceDarker')},
        ${cssVar('limedSpruceDarker')} 15px,
        ${cssVar('outerSpaceTabs')} 15px,
        ${cssVar('outerSpaceTabs')} 28px
      );
    `}
`;

export { PackageSection };
