import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const acceptForMixingType = 'ACCEPT_FOR_MIXING';
const acceptForMixingAction = () => ({
  type: acceptForMixingType,
});

const acceptForMixingSuccessType = `${acceptForMixingType}_SUCCESS`;
const acceptForMixingSuccessAction = lot => ({
  type: acceptForMixingSuccessType,
  payload: {
    lot,
  },
});

const acceptForMixingFailureType = `${acceptForMixingType}_FAILURE`;
const acceptForMixingFailureAction = () => ({
  type: acceptForMixingFailureType,
});

const acceptForMixing = lotId => dispatch => {
  dispatch(acceptForMixingAction());

  return axios
    .put(`${API_HOST_AUTH}/lots/accept-for-mixing/${lotId}`)
    .then(res => {
      dispatch(acceptForMixingSuccessAction(res.data.lot));

      return res.data;
    })
    .catch(err => {
      dispatch(acceptForMixingFailureAction());

      return Promise.reject(err.data);
    });
};

export {
  acceptForMixingType,
  acceptForMixingSuccessType,
  acceptForMixingFailureType,
  acceptForMixing,
};
