import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getMarketIndicatorsType = 'GET_MARKET_INDICATORS';
const getMarketIndicatorsAction = () => ({
  type: getMarketIndicatorsType,
});

const getMarketIndicatorsSuccessType = `${getMarketIndicatorsType}_SUCCESS`;
const getMarketIndicatorsSuccessAction = marketIndicators => ({
  type: getMarketIndicatorsSuccessType,
  payload: {
    marketIndicators,
  },
});

const getMarketIndicatorsFailureType = `${getMarketIndicatorsType}_FAILURE`;
const getMarketIndicatorsFailureAction = () => ({
  type: getMarketIndicatorsFailureType,
});

const clearMarketIndicatorsActionType = 'CLEAR_MARKET_INDICATORS';
const clearMarketIndicatorsAction = () => ({
  type: clearMarketIndicatorsActionType,
});

const getMarketIndicators = () => async dispatch => {
  dispatch(getMarketIndicatorsAction());

  try {
    const res = await axios.get(`${API_HOST_AUTH}/market-indicators`);
    dispatch(getMarketIndicatorsSuccessAction(res.data));

    return res.data;
  } catch (error) {
    dispatch(getMarketIndicatorsFailureAction());

    return Promise.reject(error.data);
  }
};

export {
  getMarketIndicatorsType,
  getMarketIndicatorsSuccessType,
  getMarketIndicatorsFailureType,
  clearMarketIndicatorsActionType,
  getMarketIndicators,
  clearMarketIndicatorsAction as clearMarketIndicators,
};
