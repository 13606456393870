import { isInternalCompany } from 'shared/helpers/matchers/checkCompanyType';

const settlementMockUpAccess = (auth, lot, { settlementOunces }) =>
  auth.access.poolAccounts.requestSettlement.mockUp &&
  !isInternalCompany(lot.sentFromCompany.companyType) &&
  lot.canBeSettled &&
  settlementOunces &&
  settlementOunces[lot._id] &&
  (settlementOunces[lot._id].maximumPlatinumOz !== 0 ||
    settlementOunces[lot._id].maximumPalladiumOz !== 0 ||
    settlementOunces[lot._id].maximumRhodiumOz !== 0);

export { settlementMockUpAccess };
