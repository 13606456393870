import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Button } from 'shared/components/Buttons';
import { Icon } from 'shared/components/Icon/Icon';
import { PACKAGES } from 'shared/helpers/constants/packages/packageConstants';
import { cssVar } from 'shared/helpers/styling/styling';

const StyledAddPackageAction = styled.div`
  background-color: ${cssVar('outerSpaceBitLighter')};
  border-bottom: 1px solid ${cssVar('outerSpaceBitLighter')};
  font-size: 0.75rem;
  padding: 20px;
  display: flex;
  justify-content: flex-end;
`;

const AddPackageAction = ({ onClick, disabled }) => (
  <StyledAddPackageAction>
    <Button alignToLeft onClick={onClick} disabled={disabled}>
      {PACKAGES.ADD_PACKAGES}
      <Icon icon="icon-plus" />
    </Button>
  </StyledAddPackageAction>
);

AddPackageAction.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export { AddPackageAction };
