import PropTypes from 'prop-types';

import { ModalContent } from 'components/Modal/components/ModalContent/ModalContent';

import { CancelButton, SubmitButton } from 'shared/components/Buttons';
import { Preloader } from 'shared/components/Preloader/Preloader';
import { inactivatePackageModal } from 'shared/helpers/constants/modalConstants';

import {
  StyledInactivateConfirmation,
  StyledButtonsContainer,
} from './StyledInactivateConfirmation';

const InactivateConfirmation = ({ isPending, onSubmit, onCancel }) => {
  const { message, messageText, acceptButton, cancelButton } = inactivatePackageModal;
  return (
    <StyledInactivateConfirmation>
      <Preloader loading={isPending} />
      <ModalContent
        modal={{
          modalOptions: {
            message,
            messageText,
          },
        }}
      />
      <StyledButtonsContainer>
        <CancelButton onClick={onCancel} label={cancelButton} />
        <SubmitButton onClick={onSubmit} icon={'icon-tick'} label={acceptButton} />
      </StyledButtonsContainer>
    </StyledInactivateConfirmation>
  );
};

InactivateConfirmation.propTypes = {
  isPending: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

export { InactivateConfirmation };
