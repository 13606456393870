import styled from 'styled-components';

import { OverviewListSectionWrapper } from 'shared/components/OverviewList/OverviewListSection/OverviewListSection';

export const StyledOverviewLotsSectionWrapper = styled(OverviewListSectionWrapper)`
  &-mainPanel {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;

    &-buttons {
      display: flex;
    }

    h3 {
      font-size: 1.5em;
      color: var(--gullGray);
    }
  }

  &-list {
    margin-bottom: 1rem;
  }
`;
