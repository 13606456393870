import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import { Authorization } from 'components/Authorization/Authorization';

import { ConnectedCreateDueDiligenceNote } from 'shared/components/Notes/CreateNotes/ConnectedCreateNote';

const Diligence = props => {
  const { companies } = props.auth.access;

  const AuthCreateDueDiligenceNote = Authorization(
    ConnectedCreateDueDiligenceNote,
    companies.details.dueDiligence.notesPanel.create,
  );

  return (
    <Switch>
      <Route path="/diligence/:id/create-note" component={AuthCreateDueDiligenceNote} />
    </Switch>
  );
};

export { Diligence };
