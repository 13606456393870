import { statusesForGlobal } from 'shared/helpers/constants/lots/statusesForGlobal';

const updateLotLocationAccess = (auth, lot) => {
  const {
    access: {
      lots: { updateLocationStored },
    },
  } = auth;

  const allowedStatuses = [
    statusesForGlobal.partly_received,
    statusesForGlobal.received,
    statusesForGlobal.accepted_for_grading,
    statusesForGlobal.grading_completed,
    statusesForGlobal.ready_for_processing,
    statusesForGlobal.accepted_for_processing,
    statusesForGlobal.ready_for_mixing,
    statusesForGlobal.accepted_for_mixing,
    statusesForGlobal.ready_to_ship,
  ];

  return updateLocationStored && allowedStatuses.includes(lot.statusForGlobal);
};

const changeReceivedDateAccess = (
  {
    access: {
      lots: { changeReceivedDate },
    },
  },
  lot,
) =>
  changeReceivedDate &&
  lot.receivedAt &&
  ![statusesForGlobal.invoice_accepted, statusesForGlobal.finalized].includes(lot.statusForGlobal);

export { updateLotLocationAccess, changeReceivedDateAccess };
