export function reorder(list, source, destination) {
  const result = [...list];
  const [removed] = result.splice(source.index, 1);
  result.splice(destination.index, 0, removed);

  return result;
}

export function move(sourceList, destinationList, sourceItem, destinationItem) {
  const sourceClone = [...sourceList];
  const destClone = [...destinationList];
  const [removed] = sourceClone.splice(sourceItem.index, 1);
  destClone.splice(destinationItem.index, 0, removed);

  return {
    [destinationItem.droppableId]: destClone,
    [sourceItem.droppableId]: sourceClone,
  };
}
