import * as React from 'react';
import { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { deleteBankHolidays } from 'actions/Payments/bankHolidaysDelete';
import { getBankHolidays, clearBankHolidays } from 'actions/Payments/bankHolidaysList';
import { openModal } from 'actions/shared/modal';
import { showSnackbar } from 'actions/shared/snackbar';

import { GroupButtons } from 'shared/components/Buttons';
import { GroupButton } from 'shared/components/Buttons/GroupButton/components/GroupButton';
import { FullPageList } from 'shared/components/FullPageList/FullPageList';
import { deleteBankHolidaysModal } from 'shared/helpers/constants/modalConstants';
import {
  itemsTemplate,
  itemsTemplateMobile,
  itemsDetailsTemplate,
} from 'shared/helpers/constants/payments/bankHolidaysTableConfig';
import { message } from 'shared/helpers/constants/payments/createBankHolidaysConstants';
import { PAYMENTS } from 'shared/helpers/constants/payments/paymentsConstants';
import {
  successOptions,
  dangerOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

import { history } from 'utils/history';

import { MarketHolidaysFilters } from 'pages/Hedges/components/MarketHolidays/components/MarketHolidaysFilters/MarketHolidaysFilters';

const BankHolidays = () => {
  const dispatch = useDispatch();
  const bankHolidaysList = useSelector(({ bankHolidaysList }) => bankHolidaysList);
  const auth = useSelector(({ auth }) => auth);

  useEffect(() => () => dispatch(clearBankHolidays()), []);

  const renderActionButtons = () => (
    <GroupButtons>
      <GroupButton
        data-testid="bankHolidaysActionButton"
        access={auth.access.payments.bankHolidays.manage}
        onClick={() => history.push('/payments/bank-holidays/create')}
        label={PAYMENTS.BANK_HOLIDAYS.CREATE}
        icon="add-market-holiday"
      />
    </GroupButtons>
  );

  const actions = auth.access.payments.bankHolidays.manage
    ? [
        {
          icon: 'bin',
          action: 'Delete',
          onClick: banktHoliday => () =>
            dispatch(openModal(deleteBankHolidaysModal, () => deleteBankHoliday(banktHoliday))),
        },
      ]
    : [];

  const fetchList = useCallback(
    () => dispatch(getBankHolidays(bankHolidaysList.filters)),
    [bankHolidaysList.filters],
  );

  const deleteBankHoliday = async ({ _id }) => {
    try {
      await dispatch(deleteBankHolidays(_id));
      fetchList();
      dispatch(showSnackbar(successOptions, message.BANK_HOLIDAY_DELETE_SUCCESS));
    } catch (err) {
      dispatch(showSnackbar(dangerOptions, message.BANK_HOLIDAY_DELETE_ERROR));
    }
  };

  const onFiltersChanged = filters =>
    dispatch(
      getBankHolidays({
        ...getBankHolidays.filters,
        ...filters,
      }),
    );

  const renderFilters = () => (
    <MarketHolidaysFilters filters={bankHolidaysList.filters} onFiltersChanged={onFiltersChanged} />
  );

  return (
    <FullPageList
      title={`${PAYMENTS.BANK_HOLIDAYS.TITLE} ${bankHolidaysList.filters.year}`}
      actionButtons={renderActionButtons}
      itemsTemplate={itemsTemplate}
      itemsTemplateMobile={itemsTemplateMobile}
      itemsDetailsTemplate={itemsDetailsTemplate}
      actionsTemplate={actions}
      items={bankHolidaysList.bankHolidays}
      fetchList={fetchList}
      filterComponent={renderFilters}
      filterAccess
      isPending={bankHolidaysList.isPending}
      filters={bankHolidaysList.filters}
    />
  );
};

export { BankHolidays };
