import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { getPoolAccountJournalCompanies } from 'actions/Companies/getCompaniesForFilters';
import {
  clearPoolAccountJournal,
  getPoolAccountJournal,
} from 'actions/PoolAccounts/poolAccountJournal';
import { showSnackbar } from 'actions/shared/snackbar';

import { FullPageList } from 'shared/components/FullPageList/FullPageList';
import { JOURNAL } from 'shared/helpers/constants/poolAccounts/journalConstants';
import {
  itemsTemplate,
  itemsTemplateMobile,
  itemsDetailsTemplate,
} from 'shared/helpers/constants/poolAccounts/journalTableConfig';
import { dangerOptions } from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

import { JournalFilters } from './components/JournalFilters';

const ViewJournalLayout = ({ poolAccountJournal, ...props }) => {
  useEffect(() => {
    props.getPoolAccountJournalCompanies();

    return () => props.clearPoolAccountJournal();
  }, []);

  const listLimits = [
    { value: 100, label: '100' },
    { value: 50, label: '50' },
    { value: 25, label: '25' },
    { value: 10, label: '10' },
  ];

  const fetchList = (page = 1, limit = 50, query) => {
    const { filters } = poolAccountJournal;

    props
      .getPoolAccountJournal({ ...filters, query }, { page, limit })
      .catch(res => props.showSnackbar(dangerOptions, res.message));
  };

  const filtersClear = searchFilter => {
    const { limit } = poolAccountJournal.pagination;

    props
      .getPoolAccountJournal({ query: searchFilter }, { limit, page: 1 })
      .catch(res => props.showSnackbar(dangerOptions, res.message));
  };

  const onFiltersChanged = searchFilter => filters => {
    props.getPoolAccountJournal(
      { ...filters, query: searchFilter },
      { ...poolAccountJournal.pagination, page: 1 },
    );
  };

  const renderFilters = searchFilter => (
    <JournalFilters
      companies={props.companiesForFilters}
      filters={poolAccountJournal.filters}
      pagination={poolAccountJournal.pagination}
      onFiltersChanged={onFiltersChanged(searchFilter)}
    />
  );

  const filtersSelected = () => {
    const { type, metal, relatedCompany, createdAtFrom, createdAtTo } = poolAccountJournal.filters;

    return type || metal || relatedCompany || createdAtFrom || createdAtTo;
  };

  return (
    <FullPageList
      title={JOURNAL.JOURNAL}
      itemsTemplate={itemsTemplate}
      itemsDetailsTemplate={itemsDetailsTemplate}
      itemsTemplateMobile={itemsTemplateMobile}
      actionsTemplate={[]}
      items={poolAccountJournal.journal.docs || []}
      fetchList={fetchList}
      pagination={{ ...poolAccountJournal.pagination, pages: poolAccountJournal.journal.pages }}
      enableSearch
      filterComponent={renderFilters}
      filterAccess
      filtersClear={filtersClear}
      filtersSelected={filtersSelected()}
      isPending={poolAccountJournal.isPending}
      listLimits={listLimits}
      filters={poolAccountJournal.filters}
    />
  );
};

const mapStateToProps = ({ auth, poolAccountJournal, companiesForFilters }) => ({
  auth,
  poolAccountJournal,
  companiesForFilters,
});

const mapDispatchToProps = {
  getPoolAccountJournal,
  clearPoolAccountJournal,
  showSnackbar,
  getPoolAccountJournalCompanies,
};

const ViewJournal = connect(mapStateToProps, mapDispatchToProps)(ViewJournalLayout);

export { ViewJournal, ViewJournalLayout };
