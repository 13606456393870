import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';

import { AccessPermission } from 'components/Authorization/AccessPermission';

import { FormContainer } from 'shared/components/Form';
import { editDefaultPPMsAccess } from 'shared/helpers/accesses/locations/editDefaultPPMsAccess';
import { LOCATION } from 'shared/helpers/constants/locations/locationConstants';
import { dangerOptions } from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { successOptions } from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { safeParseFloat } from 'shared/helpers/parsers/number';

import { goBackOrTo } from 'utils/history';

import { DefaultPPMsForm } from 'pages/Hedges/components/DefaultPPMs/components/DefaultPPMsForm';

function UpdateLocationDefaultPPMs({
  auth,
  getLocation,
  locationDetails,
  clearLocationDetails,
  updateDefaultPPMs,
  match: { params },
  ...props
}) {
  const goBackURL = `/companies/${params.companyId}/locations/${params.id}`;

  useEffect(() => {
    getLocation(params.id);

    return () => {
      clearLocationDetails();
    };
  }, []);

  const initialValues = useMemo(
    () =>
      locationDetails?.location?.defaultPPMs &&
      Object.entries(locationDetails.location.defaultPPMs)?.reduce(
        (acc, [name, value]) => ({
          ...acc,
          [name]: value || value === 0 ? value.toString() : '',
        }),
        {},
      ),
    [locationDetails?.location?.defaultPPMs],
  );

  const resolveAction = result => {
    props.showSnackbar(successOptions, result.data.message);
    goBackOrTo(goBackURL);
  };

  const parseValues = vals =>
    Object.entries(vals).reduce(
      (acc, [name, value]) => ({
        ...acc,
        [name]: safeParseFloat(value),
      }),
      {},
    );

  const submit = values => {
    const data = parseValues(values);
    updateDefaultPPMs(params.id, data)
      .then(resolveAction)
      .catch(err => props.showSnackbar(dangerOptions, err.message));
  };

  return (
    <FormContainer header={LOCATION.DEFAULT_PPMS.TITLE} loaded={!locationDetails.isPending}>
      <AccessPermission access={editDefaultPPMsAccess(auth.user, locationDetails.location)}>
        <DefaultPPMsForm
          {...props}
          initialValues={initialValues}
          onSubmit={submit}
          fieldsRequired={false}
          goBackURL={goBackURL}
        />
      </AccessPermission>
    </FormContainer>
  );
}

UpdateLocationDefaultPPMs.propTypes = {
  auth: PropTypes.shape({
    access: PropTypes.object,
    attempts: PropTypes.number,
    isPending: PropTypes.bool,
    user: PropTypes.object,
  }),
  clearLocationDetails: PropTypes.func,
  closeModal: PropTypes.func,
  getLocation: PropTypes.func,
  hideSnackbar: PropTypes.func,
  history: PropTypes.object,
  location: PropTypes.object,
  locationDetails: PropTypes.shape({
    location: PropTypes.shape({
      city: PropTypes.string,
      country: PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      }),
      createdAt: PropTypes.number,
      createdBy: PropTypes.shape({
        _id: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
      }),
      hasLotsOrHedges: PropTypes.bool,
      lotSuffix: PropTypes.string,
      name: PropTypes.string,
      poBox: PropTypes.string,
      primaryContact: PropTypes.array,
      primaryLocation: PropTypes.bool,
      relatedCompany: PropTypes.shape({
        _id: PropTypes.string,
        companyType: PropTypes.string,
        companyName: PropTypes.string,
      }),
      shipmentEligible: PropTypes.bool,
      state: PropTypes.string,
      status: PropTypes.string,
      street: PropTypes.string,
      suite: PropTypes.string,
      updatedAt: PropTypes.number,
      updatedBy: PropTypes.shape({
        _id: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
      }),
      zipCode: PropTypes.string,
      _id: PropTypes.string,
    }),
    isPending: PropTypes.bool,
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      companyId: PropTypes.string,
      id: PropTypes.string,
    }),
  }),
  modal: PropTypes.object,
  openModal: PropTypes.func,
  showSnackbar: PropTypes.func,
  updateDefaultPPMs: PropTypes.func,
};

export { UpdateLocationDefaultPPMs };
