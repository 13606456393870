import {
  saveSharedDataActionType,
  saveSharedDataSuccessActionType,
  clearSharedDataSuccessActionType,
} from 'actions/shared/saveSharedData';

const INITIAL_STATE = {
  isPending: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case saveSharedDataActionType:
      return {
        ...state,
        isPending: true,
      };
    case saveSharedDataSuccessActionType:
      return {
        ...state,
        isPending: false,
        ...action?.payload,
      };
    case clearSharedDataSuccessActionType:
      delete state[action?.payload];
      return state;
    default:
      return state;
  }
};
