import {
  updatePriceFeedType,
  updatePriceFeedSuccessType,
  updatePriceFeedFailureType,
} from 'actions/PriceFeeds/updatePriceFeed';

const INITIAL_STATE = {
  isPending: false,
};

const updatePriceFeedReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case updatePriceFeedType:
      return {
        isPending: true,
      };
    case updatePriceFeedSuccessType:
      return {
        isPending: false,
      };
    case updatePriceFeedFailureType:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};

export { updatePriceFeedReducer };
