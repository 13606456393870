import styled from 'styled-components';

import { cssVar, pxToRem, media } from 'shared/helpers/styling/styling';

export const Info = styled.p`
  display: block;
  color: ${cssVar('solidYellow')};
  font-size: ${pxToRem(12)};
  margin: 0;
  padding: ${pxToRem(10)} ${pxToRem(30)};

  ${media.mobile`
    padding: ${pxToRem(10)} 0;
  `}
`;
