import {
  clearTermsListActionType,
  getTermsActionType,
  getTermsFailureActionType,
  getTermsSuccessActionType,
} from 'actions/Companies/Details/termsList';

const INITIAL_STATE = {
  isPending: false,
  errorMessage: null,
  terms: [],
  limit: 3,
  page: 1,
  pages: null,
  total: null,
  items: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getTermsActionType:
      return {
        ...state,
        isPending: true,
      };
    case getTermsSuccessActionType:
      return {
        ...state,
        isPending: false,
        terms: action.payload.terms,
        pages: action.payload.pages,
        total: action.payload.total,
        items: {
          docs: action.payload.terms,
        },
      };
    case getTermsFailureActionType:
      return {
        ...state,
        isPending: false,
        errorMessage: action.payload.errorMessage,
      };
    case clearTermsListActionType:
      return {
        isPending: false,
        errorMessage: null,
        terms: [],
        limit: 3,
        page: 1,
        pages: null,
        total: null,
        items: [],
      };
    default:
      return state;
  }
};
