import { isCustomerUser, isOwnerOrSupport } from 'shared/helpers/matchers/checkUserType';

import { configNames } from 'pages/Packages/components/PackagesManagement/components/PackageForm/components/PackageForm/fieldsConfig';

export const goToSummaryButtonAccess = (
  userType,
  editMode,
  step,
  config,
  changedShipmentDestination,
  isValidForm,
) => {
  if (isValidForm && editMode && config === configNames.packingList) {
    if (isCustomerUser(userType) && step === 0) return true;
    if (isOwnerOrSupport(userType) && (step === 0 || step === 1) && !changedShipmentDestination)
      return true;
    return false;
  }

  return false;
};
