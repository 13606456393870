export const notificationActionTypes = {
  gradingReceiptAvailable: 'gradingReceiptAvailable',
  paymentRequest: 'paymentRequest',
  advancePaymentRequest: 'advancePaymentRequest',
  shipmentSummary: 'shipmentSummary',
  submitTerms: 'submitTerms',
  hedgeRequest: 'hedgeRequest',
  selfDeliveryRequestApproval: 'selfDeliveryRequestApproval',
  finalInvoice: 'finalInvoice',
  showExistingPayments: 'showExistingPayments',
};
