import { message } from 'shared/helpers/constants/users/createUserConstants';

const validateEmail = (values, current, props) => {
  const formValues = props.values.users;
  const emails = formValues
    .map(({ email }, index) => (current === index ? null : email))
    .filter(email => !!email);

  if (emails.includes(values.email)) {
    return {
      email: message.UNIQUE_EMAIL,
    };
  }

  return {};
};

export { validateEmail };
