import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const cancelHedgeActionType = 'CANCEL_HEDGE';
function cancelHedgeAction() {
  return { type: cancelHedgeActionType };
}

const cancelHedgeSuccessActionType = `${cancelHedgeActionType}_SUCCESS`;
function cancelHedgeSuccessAction() {
  return { type: cancelHedgeSuccessActionType };
}

const cancelHedgeFailureActionType = `${cancelHedgeActionType}_FAILURE`;
function cancelHedgeFailureAction() {
  return { type: cancelHedgeFailureActionType };
}

function cancelHedge(hedgeId) {
  return dispatch => {
    dispatch(cancelHedgeAction());

    return axios({
      method: 'patch',
      url: `${API_HOST_AUTH}/hedges/cancel`,
      headers: { 'Content-Type': 'application/json' },
      data: {
        hedgeId,
      },
    })
      .then(({ data }) => {
        dispatch(cancelHedgeSuccessAction());
        return data;
      })
      .catch(error => {
        dispatch(cancelHedgeFailureAction());

        return Promise.reject(error.data);
      });
  };
}

export {
  cancelHedge,
  cancelHedgeActionType,
  cancelHedgeSuccessActionType,
  cancelHedgeFailureActionType,
};
