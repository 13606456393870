import {
  getAvailableLotsActionType,
  getAvailableLotsSuccessActionType,
  getAvailableLotsFailureActionType,
} from 'actions/Deliveries/getAvailableLots';

const INITIAL_STATE = {
  isPending: false,
  lots: null,
};

const getAvailableLotsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getAvailableLotsActionType:
      return {
        isPending: true,
      };
    case getAvailableLotsSuccessActionType:
      return {
        isPending: false,
        lots: action.payload.lots,
      };
    case getAvailableLotsFailureActionType:
      return {
        isPending: false,
        lots: null,
      };
    default:
      return state;
  }
};

export { getAvailableLotsReducer };
