import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { companyAddExistingFiles, companyClearAllFiles } from 'actions/Companies/companyAddFile';
import { getCompany, clearCompanyDetails } from 'actions/Companies/companyDetails';
import { updateCompany as updateCompanyAction } from 'actions/Companies/updateCompany';
import { closeModal, openModal } from 'actions/shared/modal';

import { UpdateCompany } from './UpdateCompany';

function ConnectedUpdateCompany(props) {
  return <UpdateCompany {...props} />;
}

function mapStateToProps({
  auth,
  router,
  modal,
  createAttachmentStorage,
  companyDetails,
  companyFiles,
  updateCompany,
}) {
  return {
    auth,
    router,
    modal,
    createAttachmentStorage,
    companyDetails,
    updateCompany,
    companyFiles,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      openModal,
      closeModal,
      getCompany,
      clearCompanyDetails,
      companyClearAllFiles,
      updateCompany: updateCompanyAction,
      companyAddExistingFiles,
    },
    dispatch,
  );
}

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(ConnectedUpdateCompany);

export { connectedComponent as ConnectedUpdateCompany };
