import PropTypes from 'prop-types';
import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Button } from 'shared/components/Buttons/Button';
import { IconButton } from 'shared/components/Buttons/IconButton/IconButton';
import { ForwardDocument } from 'shared/components/Forward/ForwardDocument/ForwardDocument';
import { Icon } from 'shared/components/Icon/Icon';
import { cssVar, pxToRem, media } from 'shared/helpers/styling/styling';

const Container = styled.div`
  display: flex;
  align-items: center;
  margin: ${({ margin }) => margin};

  ${IconButton} {
    margin-left: 10px;
  }
`;

const ButtonStyled = styled(Button)`
  font-weight: 300;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 242px;
  text-align: left;
  position: relative;

  ${media.mobile`
    font-size: ${pxToRem(12)}
    max-width: 198px;
    padding-right: 23px;
  `}

  ${Icon} {
    position: absolute;
    right: 7px;
  }

  a {
    line-height: 16px;
  }
`;

const LinkButtonBase = ({
  className,
  label,
  download,
  forwardable,
  forwardAllowed,
  onClick,
  url,
  margin,
  ...rest
}) => {
  const [open, setOpen] = useState(false);
  const toggle = useCallback(() => setOpen(isOpen => !isOpen));

  const icon = (
    <Icon
      icon={download ? 'icon-arrow-down' : 'icon-arrow-right'}
      width={13}
      height={13}
      size="contain"
    />
  );

  return (
    <Container margin={margin}>
      <ButtonStyled
        className={className}
        bgColor={cssVar('riverBedButton')}
        bgColorHover={cssVar('shuttleGray')}
        outlineColor={cssVar('riverBedButton')}
        outlineColorHover={cssVar('shuttleGray')}
        borderRadius={8}
        onClick={onClick}
        fontSize={pxToRem(14)}
        color={cssVar('atlantis')}
        padding="7px 27px 7px 9px"
        height="30px"
        noBoxShadow
        {...rest}
      >
        {url ? (
          <a target="_blank" href={url} rel="noopener noreferrer">
            {label}
            {icon}
          </a>
        ) : (
          <>
            {label}
            {icon}
          </>
        )}
      </ButtonStyled>
      {forwardable && forwardAllowed && (
        <>
          <IconButton icon="icon-share" onClick={toggle} iconMargin="1px 2px 1px 0" />
          <ForwardDocument isOpen={open} toggle={toggle} file={download} />
        </>
      )}
    </Container>
  );
};

const mapStateToProps = ({ auth: { access } }) => ({
  forwardAllowed: access && access.files && access.files.forward,
});

const LinkButton = connect(mapStateToProps)(LinkButtonBase);

LinkButton.propTypes = {
  label: PropTypes.string.isRequired,
  download: PropTypes.any,
  forwardable: PropTypes.bool,
  onClick: PropTypes.func,
  margin: PropTypes.string,
};

LinkButton.defaultProps = {
  forwardable: false,
};

export { LinkButton };
