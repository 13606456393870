import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const parseAttachmentActionType = 'PARSE_ATTACHMENT';
function parseAttachmentAction() {
  return {
    type: parseAttachmentActionType,
  };
}

const parseAttachmentSuccessActionType = `${parseAttachmentActionType}_SUCCESS`;
function parseAttachmentSuccessAction() {
  return {
    type: parseAttachmentSuccessActionType,
  };
}

const parseAttachmentFailureActionType = `${parseAttachmentActionType}_FAILURE`;
function parseAttachmentFailureAction() {
  return {
    type: parseAttachmentFailureActionType,
  };
}

function parseFile(res, originalName) {
  return new File([res.data], originalName, { type: res.headers['content-type'] });
}

function parseAttachment({ path, originalName }) {
  return dispatch => {
    dispatch(parseAttachmentAction());

    return axios({
      method: 'post',
      url: `${API_HOST_AUTH}/files/single`,
      data: JSON.stringify({ path }),
      responseType: 'arraybuffer',
    })
      .then(res => {
        dispatch(parseAttachmentSuccessAction());
        return parseFile(res, originalName);
      })
      .catch(() => {
        dispatch(parseAttachmentFailureAction());
        return Promise.reject();
      });
  };
}

export {
  parseAttachmentAction,
  parseAttachmentActionType,
  parseAttachmentFailureAction,
  parseAttachmentFailureActionType,
  parseAttachmentSuccessAction,
  parseAttachmentSuccessActionType,
  parseAttachment,
};
