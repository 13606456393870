export const parseSpecialPermission = specialPermissions => {
  const parsedSpecialPermissions = {};
  Object.keys(specialPermissions).forEach(sectionName => {
    const permissionNames = Object.keys(specialPermissions[sectionName]);
    permissionNames.forEach(permissionName => {
      const permission = specialPermissions[sectionName][permissionName];
      parsedSpecialPermissions[sectionName] = {
        ...parsedSpecialPermissions[sectionName],
        ...(Array.isArray(permission) &&
          permission.length > 0 && {
            [permissionName]: permission.map(({ label, value }) => ({
              label,
              value,
            })),
          }),
      };
    });
  });

  return parsedSpecialPermissions;
};
