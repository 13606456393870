import React from 'react';
import styled from 'styled-components';

import { Details } from '../Details/Details';
import { Dot } from '../Dot/Dot';
import { Info } from '../Info/Info';
import { Label } from '../Label/Label';
import { Line } from '../Line/Line';

const Container = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 3fr 8px 18px 30px 4fr;
  grid-template-rows: minmax(36px, auto) minmax(15px, auto);
  grid-template-areas: 'label . dot . info' '. . line . details';
`;

function Event({
  event: { _id, type, name, date, user, data },
  last = false,
  colorResolver,
  pathResolver,
  detailsFormatter,
}) {
  const color = colorResolver(type);

  return (
    <Container>
      <Label color={color} type={type} resourceId={_id} pathResolver={pathResolver}>
        {name}
      </Label>
      <Dot color={color} />
      <Info date={date} user={user} />
      {!last && <Line />}
      {data && <Details type={type} data={data} formatter={detailsFormatter} />}
    </Container>
  );
}

export { Event };
