import React from 'react';

import { Button } from 'shared/components/Buttons/Button';
import { Icon } from 'shared/components/Icon/Icon';
import { SHARED } from 'shared/helpers/constants/sharedConstants';
import { cssVar } from 'shared/helpers/styling/styling';

const CancelButton = ({ children, label, ...props }) => (
  <Button {...props} outlineColor={cssVar('roman')} padding="0 10px 0 23px">
    {children || label || SHARED.CANCEL_BUTTON}
    <Icon icon="icon-cancel" width={35} height={35} />
  </Button>
);

export { CancelButton };
