import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getWarehouseDataActionType = 'GET_WAREHOUSE_DATA';
const getWarehouseDataAction = () => ({
  type: getWarehouseDataActionType,
});

const getWarehouseDataSuccessActionType = `${getWarehouseDataActionType}_SUCCESS`;
const getWarehouseDataSuccessAction = data => ({
  type: getWarehouseDataSuccessActionType,
  payload: {
    data,
  },
});
const getWarehouseDataFailureActionType = `${getWarehouseDataActionType}_FAILURE`;
const getWarehouseDataFailureAction = () => ({
  type: getWarehouseDataFailureActionType,
});

const getWarehouseData = () => dispatch => {
  dispatch(getWarehouseDataAction());

  return axios
    .get(`${API_HOST_AUTH}/lots/warehouse`)
    .then(res => {
      dispatch(getWarehouseDataSuccessAction(res.data));
    })
    .catch(() => {
      dispatch(getWarehouseDataFailureAction());
    });
};

export {
  getWarehouseDataActionType,
  getWarehouseDataAction,
  getWarehouseDataSuccessActionType,
  getWarehouseDataSuccessAction,
  getWarehouseDataFailureActionType,
  getWarehouseDataFailureAction,
  getWarehouseData,
};
