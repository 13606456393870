import { connect } from 'react-redux';

import { inactivateCompany as inactivateCompanyAction } from 'actions/Companies/inactivateCompany';
import { openModal } from 'actions/shared/modal';
import { showSnackbar } from 'actions/shared/snackbar';

import { inactivateCompanyModal } from 'shared/helpers/constants/modalConstants';
import {
  successOptions,
  dangerOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

function InactivateCompany(props) {
  const inactivateCompany = () => {
    props.openModal(inactivateCompanyModal, () => {
      props
        .inactivateCompany(props.company._id)
        .then(({ message }) => {
          props.showSnackbar(successOptions, message);
          props.onInactivate && props.onInactivate();
        })
        .catch(({ message }) => props.showSnackbar(dangerOptions, message));
    });
  };

  return props.children({ inactivateCompany });
}

const mapDispatchToProps = {
  openModal,
  showSnackbar,
  inactivateCompany: inactivateCompanyAction,
};

const ConnectedInactivateCompany = connect(null, mapDispatchToProps)(InactivateCompany);

export {
  ConnectedInactivateCompany as InactivateCompany,
  InactivateCompany as InactivateCompanyModal,
};
