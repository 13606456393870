import React, { Component } from 'react';
import { connect } from 'react-redux';

import { marketAvailability } from 'actions/Hedges/marketAvailability';

import { UIAuthorization } from 'components/Authorization/UIAuthorization';

import {
  isCustomerUser,
  isGradingUser,
  isOwnerUser,
  isSupportUser,
} from 'shared/helpers/matchers/checkUserType';

import { ShipmentsCalendar } from 'pages/Shipments/components/ShipmentsCalendar/ConnectedShipmentsCalendar';

import { MainInfo } from './components/MainInfo/MainInfo';
import { ConnectedNotificationsList } from './components/NotificationsList/ConnectedNotificationsList';
import { ConnectedSelfDeliveryRequestsList } from './components/SelfDeliveryRequestsList/ConnectedSelfDeliveryRequestsList';

class DashboardBase extends Component {
  componentDidMount() {
    const {
      auth: {
        user: { userType },
      },
    } = this.props;

    if (isOwnerUser(userType) || isSupportUser(userType) || isCustomerUser(userType)) {
      this.props.marketAvailability(true);
    }
  }

  render() {
    const {
      auth: { user, access },
      marketAvailabilityDetails,
    } = this.props;
    const shipmentsCalendarAccess =
      user.dashboardPreferences &&
      access.users.dashboardPreferences &&
      user.dashboardPreferences.calendar;

    return (
      <>
        <MainInfo user={user} marketHoliday={marketAvailabilityDetails.marketHoliday} />
        <ConnectedNotificationsList />
        <UIAuthorization access={isGradingUser(user.userType)}>
          <ConnectedSelfDeliveryRequestsList />
        </UIAuthorization>
        <UIAuthorization access={shipmentsCalendarAccess}>
          <ShipmentsCalendar />
        </UIAuthorization>
      </>
    );
  }
}

const mapStateToProps = ({ auth, marketAvailabilityDetails }) => ({
  auth,
  marketAvailabilityDetails,
});

const mapDispatchToProps = {
  marketAvailability,
};

const ConnectedDashboard = connect(mapStateToProps, mapDispatchToProps)(DashboardBase);

export { ConnectedDashboard as Dashboard, DashboardBase };
