import { isGlobalRefiningGroup } from 'shared/helpers/matchers/isGlobalRefiningGroup';

const parseSettlementForLotData = values => {
  const { companyName } = values.applyBlanketHedges.relatedLot.sentFromCompany;
  const { requestPayment } = values.settlement;

  return {
    ...values,
    settlement: {
      ...values.settlement,
      requestPayment: isGlobalRefiningGroup(companyName) ? false : requestPayment,
    },
  };
};

export { parseSettlementForLotData };
