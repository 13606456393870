import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { downloadAttachment } from 'actions/Attachments/downloadAttachment';
import { getCfuDocuments } from 'actions/Cfu/getCfu';
import { inspectCfu } from 'actions/Cfu/inspectCfu';
import { markAsResolved } from 'actions/Notifications/markAsResolved';
import { clearNotificationDetails } from 'actions/Notifications/notificationDetails';
import { showSnackbar } from 'actions/shared/snackbar';

import { ModalCustom } from 'components/ModalCustom/ModalCustom';

import { Button, CancelButton } from 'shared/components/Buttons';
import { ContentLoader } from 'shared/components/ContentLoader/ContentLoader';
import { DetailsDownloadField } from 'shared/components/Details/DetailsDownloadFieldRedesign';
import { Icon } from 'shared/components/Icon/Icon';
import { PreloaderInline } from 'shared/components/PreloaderInline/PreloaderInline';
import { CLIENT_FACING_UPLOADS_CONSTANTS } from 'shared/helpers/constants/clientFacingUploadsConstants';
import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';
import { infoOptions } from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { stripHtmlTags } from 'shared/helpers/parsers/stripHtmlTags';
import { cssVar } from 'shared/helpers/styling/styling';

import { getLot } from '../../../../actions/Lots/lotDetails';
import { StyledWrapper, StyledActions } from './InspectCfu.style';

const InspectCfu = ({ isInspect }) => {
  const [showModal, setShowModal] = useState(true);
  const dispatch = useDispatch();
  const notification = useSelector(state => state.notificationDetails.notification);
  const { lot, isPending } = useSelector(state => state.lotDetails);
  const { data, isPending: loading } = useSelector(state => state.cfu);
  const inspectCfuResult = useSelector(state => state.inspectCfu);
  const history = useHistory();
  const isDataReady = !isPending && !loading && notification && data?.cfuFiles?.length;

  const handleClose = () => {
    setShowModal(false);
    history.push(`/lots/list/${lot._id}`);
  };

  const handleApprove = () => {
    dispatch(
      inspectCfu(
        data.cfuFiles.map(item => item._id),
        lot._id,
        true,
      ),
    )
      .then(() => {
        dispatch(getLot(lot._id));
        dispatch(clearNotificationDetails());
        dispatch(markAsResolved(notification._id));
      })
      .finally(() => {
        handleClose();
      });
  };

  const handleDecline = () => {
    dispatch(
      inspectCfu(
        data.cfuFiles.map(item => item._id),
        lot._id,
        false,
      ),
    )
      .then(() => {
        dispatch(clearNotificationDetails());
        dispatch(markAsResolved(notification._id));
      })
      .finally(() => {
        handleClose();
      });
  };

  useEffect(() => {
    dispatch(
      getCfuDocuments(
        lot._id,
        isInspect
          ? CLIENT_FACING_UPLOADS_CONSTANTS.PENDING
          : CLIENT_FACING_UPLOADS_CONSTANTS.REJECTED,
      ),
    ).then(result => {
      if (!result?.cfuFiles?.length) {
        dispatch(
          showSnackbar(
            infoOptions,
            CLIENT_FACING_UPLOADS_CONSTANTS.DOCUMENTS_ALREADY_INSPECTED({
              isMultiple: Boolean(result.cfuFiles?.length),
            }),
          ),
        );
        handleClose();
      }
    });
  }, []);

  return (
    notification && (
      <ContentLoader loaded={isDataReady}>
        <ModalCustom isOpen={showModal} modalClose={handleClose}>
          <StyledWrapper>
            <h4>{notification.messageSubject}</h4>
            <p>{stripHtmlTags(notification.messageText)}</p>
            {inspectCfuResult.isPending ? (
              <PreloaderInline loading />
            ) : (
              <>
                <div style={{ textAlign: 'start' }}>
                  {data?.fileTypes?.map(fileType => {
                    const files = data?.cfuFiles.filter(file => file.fileType === fileType);
                    return (
                      <DetailsDownloadField
                        label={
                          {
                            billOfLading: LOTS.BILL_OF_LADING,
                            poolSettlementDocument: LOTS.POOL_SETTLEMENT_DOCUMENT,
                            gradingReceipt: LOTS.GRADING_RECEIPT,
                            countSheet: LOTS.COUNT_SHEET,
                            grgFinalInvoice: LOTS.GRG_FINAL_INVOICE,
                          }[fileType]
                        }
                        backgroundColor={cssVar('outerSpace')}
                        onClick={index => {
                          dispatch(downloadAttachment(files[index].fileDetails.path));
                        }}
                        value={files.map(file => file.fileDetails)}
                      />
                    );
                  })}
                </div>
              </>
            )}

            {isInspect && (
              <StyledActions>
                <CancelButton
                  label="Decline"
                  style={{ padding: '0 20px' }}
                  onClick={handleDecline}
                  disabled={inspectCfuResult.isPending}
                ></CancelButton>
                <Button
                  disabled={inspectCfuResult.isPending}
                  style={{ padding: '0 20px' }}
                  onClick={handleApprove}
                >
                  Approve <Icon icon="icon-tick" />
                </Button>
              </StyledActions>
            )}
          </StyledWrapper>
        </ModalCustom>
      </ContentLoader>
    )
  );
};

export { InspectCfu };
