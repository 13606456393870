import {
  agreementDocumentAddedActionType,
  agreementDocumentClearActionType,
  uploadAgreementDocumentActionType,
  uploadAgreementDocumentFailureActionType,
  uploadAgreementDocumentSuccessActionType,
} from 'actions/Agreements/uploadAgreementDocument';

const INITIAL_STATE = {
  isPending: false,
  files: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case agreementDocumentAddedActionType:
      return {
        isPending: false,
        files: action.payload.files,
      };
    case agreementDocumentClearActionType:
      return {
        isPending: false,
        files: null,
      };
    case uploadAgreementDocumentActionType:
      return {
        isPending: true,
      };
    case uploadAgreementDocumentFailureActionType:
      return {
        isPending: false,
      };
    case uploadAgreementDocumentSuccessActionType:
      return {
        isPending: false,
      };
    default:
      return state;
  }
};
