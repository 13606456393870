import React, { Component } from 'react';

import { GroupButtons } from 'shared/components/Buttons';
import { GroupButton } from 'shared/components/Buttons/GroupButton/components/GroupButton';
import { FullPageList } from 'shared/components/FullPageList/FullPageList';
import { deletePaymentExceptionCompaniesModal } from 'shared/helpers/constants/modalConstants';
import { message } from 'shared/helpers/constants/payments/createPaymentExceptionCompaniesConstants';
import { PAYMENTS } from 'shared/helpers/constants/payments/paymentsConstants';
import {
  itemsTemplate,
  itemsTemplateMobile,
  itemsDetailsTemplate,
} from 'shared/helpers/constants/payments/paymentsExceptionCompaniesTableConfig';
import {
  successOptions,
  dangerOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

class PaymentExceptionCompanies extends Component {
  componentDidMount() {
    this.props.getPaymentExceptionCompanies();
  }

  componentWillUnmount() {
    this.props.clearPrivilegedCompanies();
  }

  deletePaymentExceptionCompany(paymentExceptionCompany) {
    const { getPaymentExceptionCompanies, deletePaymentExceptionCompany, showSnackbar } =
      this.props;

    deletePaymentExceptionCompany(paymentExceptionCompany)
      .then(() => {
        getPaymentExceptionCompanies();
        showSnackbar(successOptions, message.PAYMENT_EXCEPTION_COMPANIES_DELETE_SUCCESS);
      })
      .catch(() => showSnackbar(dangerOptions, message.PAYMENT_EXCEPTION_COMPANIES_DELETE_ERROR));
  }

  renderActionButtons = () => (
    <GroupButtons>
      <GroupButton
        className="PaymentExceptionCompanies-create"
        access={this.props.auth.access.payments.paymentExceptionCompanies.manage}
        onClick={() => this.props.history.push('/payments/payment-exception-companies/create')}
        label={PAYMENTS.PAYMENT_EXCEPTION_COMPANIES.CREATE}
        icon="add-exception-company"
      />
    </GroupButtons>
  );

  render() {
    const { privilegedCompaniesList, auth, openModal } = this.props;
    const manageAuth = auth.access.payments.paymentExceptionCompanies.manage;
    const actions = manageAuth
      ? [
          {
            icon: 'bin',
            onClick: paymentExceptionCompany => () =>
              openModal(deletePaymentExceptionCompaniesModal, () =>
                this.deletePaymentExceptionCompany(paymentExceptionCompany),
              ),
          },
        ]
      : [];

    return (
      <>
        <FullPageList
          title={PAYMENTS.PAYMENT_EXCEPTION_COMPANIES.TITLE}
          tableHeader={PAYMENTS.PAYMENT_EXCEPTION_COMPANIES.INFORMATION.HEDGE_REQUIRED}
          actionButtons={this.renderActionButtons}
          itemsTemplate={itemsTemplate}
          itemsTemplateMobile={itemsTemplateMobile}
          itemsDetailsTemplate={itemsDetailsTemplate}
          actionsTemplate={actions}
          items={privilegedCompaniesList.privilegedCompanies.exceptions.hedge_required}
          isPending={privilegedCompaniesList.isPending}
          disableInitialFetch
        />
        <FullPageList
          tableHeader={PAYMENTS.PAYMENT_EXCEPTION_COMPANIES.INFORMATION.HEDGE_NOT_REQUIRED}
          itemsTemplate={itemsTemplate}
          itemsTemplateMobile={itemsTemplateMobile}
          itemsDetailsTemplate={itemsDetailsTemplate}
          actionsTemplate={actions}
          items={privilegedCompaniesList.privilegedCompanies.exceptions.hedge_not_required}
          isPending={privilegedCompaniesList.isPending}
          disableInitialFetch
        />
      </>
    );
  }
}

export { PaymentExceptionCompanies };
