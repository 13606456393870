import React, { Component } from 'react';

import { FullPageList } from 'shared/components/FullPageList/FullPageList';
import { LOCATION } from 'shared/helpers/constants/locations/locationConstants';
import {
  itemsTemplate,
  itemsTemplateMobile,
  itemsDetailsTemplate,
} from 'shared/helpers/constants/locations/locationsListTableConfig';

import { LocationsFilters } from './components/LocationsFilters/LocationsFilters';

class LocationsList extends Component {
  componentWillUnmount() {
    this.props.clearLocationsList();
  }

  onFiltersChanged = searchFilter => filters => {
    const {
      getLocationList,
      locationsList: { limit },
      locationStatusFilter,
      match,
    } = this.props;
    const companyId = match.params.id;

    getLocationList(companyId, limit, 1, searchFilter, filters);
    locationStatusFilter(filters.status);
  };

  get tableActions() {
    const {
      auth: {
        access: { locations, companies },
      },
      history,
    } = this.props;

    const actions = [];

    if (locations.ownLocations.details) {
      actions.push({
        icon: 'arrow-right',
        action: 'Details',
        onClick: thisLocation => () => {
          history.push(`${history.location.pathname}/location/${thisLocation._id}`);
        },
      });
    }

    if (companies.details.locations.details.details) {
      actions.push({
        icon: 'arrow-right',
        action: 'Details',
        onClick: thisLocation => () => {
          history.push(`${history.location.pathname}/${thisLocation._id}`);
        },
      });
    }

    return actions;
  }

  filtersClear = searchFilter => {
    const {
      getLocationList,
      locationFiltersClear,
      match,
      locationsList: { limit },
    } = this.props;

    getLocationList(match.params.id, limit, 1, searchFilter, { status: [] });
    locationFiltersClear();
  };

  fetchList = (page = 1, limit = 50, query) => {
    const { getLocationList, locationFilters, match } = this.props;
    const companyId = match.params.id;

    getLocationList(companyId, limit, page, query, locationFilters);
  };

  renderFilters = searchFilter => {
    const {
      locationsList: { page, limit },
      locationFilters,
    } = this.props;

    return (
      <LocationsFilters
        filters={locationFilters}
        pagination={{ page, limit }}
        onFiltersChanged={this.onFiltersChanged(searchFilter)}
      />
    );
  };

  render() {
    const {
      locationsList: { locations, limit, page, pages, isPending },
      locationFilters,
    } = this.props;

    const filtersSelected = locationFilters.status.length;

    return (
      <FullPageList
        title={LOCATION.LOCATIONS}
        itemsTemplate={itemsTemplate}
        itemsDetailsTemplate={itemsDetailsTemplate}
        itemsTemplateMobile={itemsTemplateMobile}
        actionsTemplate={this.tableActions}
        items={locations || []}
        fetchList={this.fetchList}
        filterComponent={this.renderFilters}
        filterAccess
        pagination={{ limit, page, pages }}
        enableSearch
        filtersClear={this.filtersClear}
        filtersSelected={filtersSelected}
        isPending={isPending}
        filters={locationFilters}
      />
    );
  }
}

export { LocationsList };
