import React, { useState, useEffect, useCallback, useRef } from 'react';
import { batch } from 'react-redux';
import styled from 'styled-components';

import { UIAuthorization } from 'components/Authorization/UIAuthorization';

import { GroupButtons } from 'shared/components/Buttons';
import { GroupButton } from 'shared/components/Buttons/GroupButton/components/GroupButton';
import { RouterButton } from 'shared/components/Buttons/RouterButton/RouterButton';
import { Icon } from 'shared/components/Icon/Icon';
import { OverviewHeader } from 'shared/components/OverviewHeader/OverviewHeader';
import { OverviewListWrapper } from 'shared/components/OverviewListWrapper/OverviewListWrapper';
import { SearchComponent } from 'shared/components/Search/Search';
import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';
import { globalSections, globalSectionsEnum } from 'shared/helpers/constants/lots/sections';
import { isTablet } from 'shared/helpers/constants/resolutionsConstants';
import { SHARED } from 'shared/helpers/constants/sharedConstants';
import { humanizeCamelCase } from 'shared/helpers/parsers/text';
import { cssVar } from 'shared/helpers/styling/styling';
import { useWindowWidth } from 'shared/hooks/useWindowWidth';

import { debounce } from 'utils/debounce';
import { flatten } from 'utils/flatten';

import { MarketHoursRouterButton } from 'pages/Hedges/components/HedgesOverview/components/MarketHoursRouterButtton/MarketHoursRouterButton';
import { CompareBarcodes } from 'pages/Lots/components/CompareBarcodes/CompareBarcodes';
import { DuplicateLotLabel } from 'pages/Lots/components/DuplicateLotLabel/DuplicateLotLabel';

import { GlobalLotsSection } from './LotsSection/GlobalLotsSection/GlobalLotsSection';
import { WarehouseDashboard } from './LotsSection/WarehouseDashboard/WarehouseDashboard';

const itemsTemplate = [
  {
    label: humanizeCamelCase(globalSectionsEnum.incomingLots),
    render: ({ incomingLots }) => incomingLots,
  },
  {
    label: humanizeCamelCase(globalSectionsEnum.processingOverview),
    render: ({ processingOverview }) => processingOverview,
  },
  {
    label: humanizeCamelCase(globalSectionsEnum.mixingOverview),
    render: ({ mixingOverview }) => mixingOverview,
  },
  {
    label: humanizeCamelCase(globalSectionsEnum.outgoingLots),
    render: ({ outgoingLots }) => outgoingLots,
  },
];

const actionsTemplate = [];

const StyledSectionActionButtons = styled.div`
  display: flex;
  align-items: center;
`;

function GlobalLotsSections({
  auth: { access },
  lotsSections,
  marketAvailabilityDetails,
  showSnackbar,
  countLots,
  marketAvailability,
  clearLotsCount,
  ...props
}) {
  const sectionsMounted = useRef(false);

  const [showDuplicateLotLabelModal, setShowDuplicateLotLabelModal] = useState(false);
  const toggleDuplicateModal = useCallback(
    () => setShowDuplicateLotLabelModal(!showDuplicateLotLabelModal),
    [setShowDuplicateLotLabelModal, showDuplicateLotLabelModal],
  );

  const [showCompareBarcodesModal, setShowCompareBarcodesModal] = useState(false);
  const toggleCompareBarcodesModal = useCallback(
    () => setShowCompareBarcodesModal(!showCompareBarcodesModal),
    [showCompareBarcodesModal],
  );

  useEffect(() => {
    sectionsMounted.current = true;

    return () => {
      sectionsMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const {
      payments: { settleLot },
    } = access;
    batch(() => {
      countLots();
      if (settleLot) marketAvailability();
    });

    return () => {
      clearLotsCount();
    };
  }, [access, countLots, marketAvailability, clearLotsCount]);

  const searchLots = debounce(value => {
    const { history } = props;

    sectionsMounted.current &&
      value.overview.searchInput &&
      history.push('lots/list', { searchLots: value.overview.searchInput });
  }, 600);

  const listActionButtons = (
    <>
      <UIAuthorization access={access.lots.list}>
        <SearchComponent handleChange={searchLots} nonPersistent label="overview" size="long" />
      </UIAuthorization>
      <UIAuthorization access={access.lots.list}>
        <RouterButton path="/lots/list" outlineColor={cssVar('pelorous')}>
          {SHARED.DETAILS}
          <Icon icon="icon-tiny-arrow-right" />
        </RouterButton>
      </UIAuthorization>
    </>
  );

  const createLot = () => props.history.push('/lots/create');

  const sectionActionButtons = (
    <StyledSectionActionButtons>
      <GroupButtons>
        <GroupButton
          access={access.lots.duplicateLabel}
          onClick={toggleDuplicateModal}
          label={LOTS.PRINT_DUPLICATE}
          icon="printer"
        />
        <GroupButton
          access={access.lots.compareBarcodes}
          onClick={toggleCompareBarcodesModal}
          label={LOTS.COMPARE_LABELS}
          icon="compare"
        />
        <MarketHoursRouterButton
          access={access.payments.settleLot}
          path="/pool-accounts/settlement-for-lot"
          label={LOTS.SETTLE_LOTS}
          module="Settlements"
          icon="close-lot"
        />
        <GroupButton
          access={access.lots.create}
          onClick={createLot}
          label={LOTS.CREATE_A_LOT}
          icon="new-lot"
        />
      </GroupButtons>
    </StyledSectionActionButtons>
  );

  const windowWidth = useWindowWidth();
  const isMobile = windowWidth < isTablet;
  const renderSections = () =>
    globalSections(access.companies.gradingEnabled).map(section => (
      <GlobalLotsSection key={section.route} section={section} contentHide={isMobile} />
    ));

  const count = Object.entries(lotsSections.lotsCount).reduce(
    (acc, [name, section]) => ({
      ...acc,
      [name]: flatten(Object.values(section)).length,
    }),
    { _id: 0 },
  );

  const total = Object.values(count).reduce((acc, length) => acc + length, 0);

  const list = {
    items: {
      docs: [count],
      total: total || 1,
    },
  };

  return (
    <>
      <OverviewHeader header={LOTS.LOTS} actions={sectionActionButtons} />
      <OverviewListWrapper
        label={LOTS.ALL_LOTS}
        list={list}
        itemsTemplate={itemsTemplate}
        actionsTemplate={actionsTemplate}
        button={listActionButtons}
        contentHide={isMobile}
      />
      <UIAuthorization access={access.lots.warehouseDashboard}>
        <WarehouseDashboard access={access.lots.warehouseDashboard} contentHide={isMobile} />
      </UIAuthorization>
      <DuplicateLotLabel open={showDuplicateLotLabelModal} onSelfClose={toggleDuplicateModal} />
      <CompareBarcodes open={showCompareBarcodesModal} onSelfClose={toggleCompareBarcodesModal} />
      {renderSections()}
    </>
  );
}

export { GlobalLotsSections };
