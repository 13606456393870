import React, { Component } from 'react';

import { UIAuthorization } from 'components/Authorization/UIAuthorization';

import { ActionsContainer } from 'shared/components/ActionsContainer';
import { GroupButtons, RouterButton } from 'shared/components/Buttons';
import { GroupActionButton } from 'shared/components/Buttons/GroupButton/components/GroupActionButton';
import { GroupButton } from 'shared/components/Buttons/GroupButton/components/GroupButton';
import { settleOuncesAccess } from 'shared/helpers/accesses/poolAccounts/settleOuncesAccess';
import { POOL_ACCOUNTS } from 'shared/helpers/constants/poolAccounts/poolAccountsConstants';
import {
  customerHeaderGroups,
  customerItemsTemplate,
  customerItemsTemplateMobile,
  headerGroups,
  itemsDetailsTemplate,
  itemsTemplate,
  itemsTemplateMobile,
} from 'shared/helpers/constants/poolAccounts/poolAccountsListTableConfig';
import { isCustomerUser } from 'shared/helpers/matchers/checkUserType';
import { isGlobalUserType } from 'shared/helpers/matchers/isGlobalUserType';

import { StyledPoolAccountsDisclaimer, StyledPoolAccountsList } from './StyledPoolAccountsList';

class PoolAccountsList extends Component {
  componentDidMount() {
    const {
      getGrandTotalValue,
      auth: {
        user: { userType },
      },
    } = this.props;

    isGlobalUserType(userType) && getGrandTotalValue();
  }

  componentWillUnmount() {
    const {
      auth: {
        user: { userType },
      },
      clearPoolAccountsAction,
      clearGrandTotalValue,
    } = this.props;

    !isCustomerUser(userType) && clearPoolAccountsAction();
    isGlobalUserType(userType) && clearGrandTotalValue();
  }

  get tableActions() {
    const {
      auth: {
        access: { poolAccounts },
        user: { userType, relatedCompany },
      },
      history,
    } = this.props;

    return [
      poolAccounts.details && {
        icon: 'arrow-right',
        action: 'Details',
        onClick: poolAccount => () => {
          history.push(
            `/pool-accounts/${
              isGlobalUserType(userType) ? poolAccount.relatedCompany._id : relatedCompany._id
            }`,
          );
        },
      },
    ].filter(Boolean);
  }

  fetchList = (page = 1, limit = 50, query) => {
    const {
      getPoolAccounts,
      poolAccountsList: { filters },
    } = this.props;
    getPoolAccounts({ ...filters, query }, { page, limit });
  };

  actionButtons = () => {
    const {
      auth: { user, access },
      poolAccountsList: { poolAccounts },
      history,
    } = this.props;

    return (
      <ActionsContainer>
        <GroupButtons>
          <GroupButton
            id="journal"
            access={access.poolAccounts.viewJournal}
            onClick={() => history.push('/pool-accounts/journal')}
            label={POOL_ACCOUNTS.VIEW_JOURNAL}
            icon="view-journal"
          />
          <GroupButton
            id="settleOunces"
            access={
              isCustomerUser(user.userType) &&
              settleOuncesAccess(user, access.poolAccounts, poolAccounts.docs)
            }
            onClick={() => history.push('/pool-accounts/settlement-of-metal-pool')}
            label={POOL_ACCOUNTS.SETTLE_OUNCES}
            icon="volume"
          />
          <GroupActionButton
            access={!isCustomerUser(user.userType) || access.poolAccounts.enterOunces}
          >
            <UIAuthorization
              access={
                isGlobalUserType(user.userType) &&
                settleOuncesAccess(user, access.poolAccounts, poolAccounts.docs)
              }
            >
              <RouterButton
                id="requestSettlementMetalPool"
                path="pool-accounts/settlement-of-metal-pool"
                label={POOL_ACCOUNTS.SETTLE_OUNCES}
              />
            </UIAuthorization>
            <UIAuthorization access={access.poolAccounts.enterOunces}>
              <RouterButton
                id="enterOunces"
                path="/pool-accounts/enter-ounces"
                label={POOL_ACCOUNTS.ENTER_OUNCES}
              />
            </UIAuthorization>
          </GroupActionButton>
        </GroupButtons>
      </ActionsContainer>
    );
  };

  render() {
    const {
      auth: {
        user: { userType },
      },
      poolAccountsList,
      poolAccountsList: { pagination, poolAccounts },
      grandTotalValue: { grandTotal, totalOverall },
    } = this.props;

    const subheader = isGlobalUserType(userType) && (
      <>
        <div>{POOL_ACCOUNTS.GRAND_TOTAL_VALUE(grandTotal)}</div>
        <div>
          {POOL_ACCOUNTS.TOTAL_OUNCES({
            outstandingPt: parseFloat(totalOverall?.platinumOz),
            outstandingPd: parseFloat(totalOverall?.palladiumOz),
            outstandingRh: parseFloat(totalOverall?.rhodiumOz),
          })}
        </div>
      </>
    );

    return (
      <div
        className={`PoolAccountsList ${
          isCustomerUser(this.props.auth.user.userType) ? 'PoolAccountsList--customer' : ''
        }`}
      >
        <StyledPoolAccountsList
          title={POOL_ACCOUNTS.POOL_ACCOUNTS}
          actionButtons={this.actionButtons}
          subheader={subheader}
          headerGroups={
            isCustomerUser(this.props.auth.user.userType) ? customerHeaderGroups : headerGroups
          }
          itemsTemplate={
            isCustomerUser(this.props.auth.user.userType) ? customerItemsTemplate : itemsTemplate
          }
          itemsDetailsTemplate={
            isCustomerUser(this.props.auth.user.userType)
              ? customerItemsTemplate
              : itemsDetailsTemplate
          }
          itemsTemplateMobile={
            isCustomerUser(this.props.auth.user.userType)
              ? customerItemsTemplateMobile
              : itemsTemplateMobile
          }
          actionsTemplate={this.tableActions}
          items={poolAccounts.docs || []}
          isPending={poolAccountsList.isPending}
          fetchList={this.fetchList}
          pagination={
            isCustomerUser(this.props.auth.user.userType)
              ? null
              : { ...pagination, pages: poolAccounts.pages }
          }
        />

        {isCustomerUser(this.props.auth.user.userType) && (
          <StyledPoolAccountsDisclaimer>{POOL_ACCOUNTS.DISCLAIMER}</StyledPoolAccountsDisclaimer>
        )}
      </div>
    );
  }
}

export { PoolAccountsList };
