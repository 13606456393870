import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { downloadAttachment } from 'actions/Attachments/downloadAttachment';
import { clearNotesList, getNotes } from 'actions/Notes/notesList';

import { UIAuthorization } from 'components/Authorization/UIAuthorization';

import { RouterButton } from 'shared/components/Buttons';
import { Icon } from 'shared/components/Icon/Icon';
import { ListCollapse } from 'shared/components/ListCollapse/ListCollapse';
import {
  OverviewListSectionWrapper,
  OverviewListSection,
} from 'shared/components/OverviewList/OverviewListSection/OverviewListSection';
import { Preloader } from 'shared/components/Preloader/Preloader';
import { FILES_CONSTANTS } from 'shared/helpers/constants/filesConstants';
import { NOTE } from 'shared/helpers/constants/noteConstants';

import { NotesList } from './NotesList/NotesList';
import { StyledNotesPanelWrapper } from './StyledNotesPanel';

const NotesPanel = ({
  relatedType,
  relatedState,
  subType,
  match,
  label = `${NOTE.DETAILS.NOTES} / ${NOTE.DETAILS.ATTACHMENTS}`,
  createAuth = true,
}) => {
  const [displayNotes, setDisplayNotes] = useState(true);
  const dispatch = useDispatch();
  const notesList = useSelector(state => state.notesList);
  const downloadAttachmentStorage = useSelector(state => state.downloadAttachmentStorage);

  const renderLabel = () => {
    return Number.isFinite(notesList.total[relatedState?.type ?? relatedType]) &&
      notesList.total[relatedState?.type ?? relatedType] !== 0
      ? `${label} (${notesList.total[relatedState?.type ?? relatedType]})`
      : label;
  };

  const notesToggle = () => {
    notesList.total !== 0 && setDisplayNotes(prevState => !prevState);
  };

  return (
    <StyledNotesPanelWrapper>
      <OverviewListSectionWrapper
        isOpen={displayNotes}
        total={notesList.total[relatedState?.type ?? relatedType]}
      >
        <Preloader
          loading={downloadAttachmentStorage.isPending}
          label={FILES_CONSTANTS.DOWNLOADING_FILES}
        />
        <OverviewListSection
          isOpen={displayNotes}
          total={notesList.total[relatedState?.type ?? relatedType]}
        >
          <div className="NotesPanel-mainPanel-buttons">
            <ListCollapse
              collapse={displayNotes}
              toggleList={notesToggle}
              label={renderLabel()}
              clickable={notesList.total[relatedState?.type ?? relatedType] > 0}
            />
            <UIAuthorization access={createAuth}>
              <div>
                <RouterButton
                  path={`/${relatedType}/${match.params.id}/create-note`}
                  padding="0 10px 0 23px"
                  icon={<Icon icon="icon-plus" />}
                  label={NOTE.CREATE_A_NOTE}
                  {...(relatedState && { locationState: relatedState })}
                />
              </div>
            </UIAuthorization>
          </div>
        </OverviewListSection>
        <NotesList
          match={match}
          getNotes={dispatch(getNotes)}
          notesList={notesList}
          clearNotesList={dispatch(clearNotesList)}
          downloadAttachment={dispatch(downloadAttachment)}
          relatedType={relatedState?.type ?? relatedType}
          subType={subType}
        />
      </OverviewListSectionWrapper>
    </StyledNotesPanelWrapper>
  );
};

NotesPanel.defaultProps = {
  createAuth: true,
  label: `${NOTE.DETAILS.NOTES} / ${NOTE.DETAILS.ATTACHMENTS}`,
};

export { NotesPanel };
