import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { changePackagesState } from 'actions/Payments/changePackagesState';

import { ModalCustom } from 'components/ModalCustom/ModalCustom';

import { Button } from 'shared/components/Buttons';
import { Icon } from 'shared/components/Icon/Icon';
import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';
import { PACKAGES } from 'shared/helpers/constants/packages/packageConstants';
import { SHARED } from 'shared/helpers/constants/sharedConstants';

import { StyledHeader, StyledTop, StyledBottom } from './StyledInactiveSensorPackages';

export const InactiveSensorPackages = ({ incomingSensorPackages = [], onFinish, ...props }) => {
  const dispatch = useDispatch();

  const handleSetSensorsPackagesInactive = () => {
    dispatch(changePackagesState('inactivate', incomingSensorPackages)).then(() => onFinish());
  };

  return (
    <ModalCustom isOpen={props.isOpen} modalClose={props.toggle}>
      <StyledTop>
        <StyledHeader>{LOTS.INACTIVATE_PACKAGES_DESCRIPTION}</StyledHeader>
        <Icon icon="icon-box" height={100} width={100} margin="20px" size="contain" />
      </StyledTop>
      <StyledBottom>
        <Button onClick={handleSetSensorsPackagesInactive}>{PACKAGES.INACTIVATE_PACKAGES}</Button>
        <Button id="skipInactivationPackages" outlineColor="danger" onClick={onFinish}>
          {SHARED.NO}
        </Button>
      </StyledBottom>
    </ModalCustom>
  );
};

InactiveSensorPackages.propTypes = {
  incomingSensorPackages: PropTypes.array,
  onFinish: PropTypes.func,
};
