const fundsTypes = {
  cash: 'cash',
  check: 'check',
  wire: 'wire',
};

const availableFundsTypes = {
  grading: Object.values(fundsTypes),
  assayInternal: [fundsTypes.check, fundsTypes.wire],
};

export { fundsTypes, availableFundsTypes };
