import { dateToTimestamp } from 'shared/helpers/parsers/date';

function parseMarketHolidaysData(marketHoliday) {
  return {
    ...marketHoliday,
    date: dateToTimestamp(marketHoliday.date),
  };
}

export { parseMarketHolidaysData };
