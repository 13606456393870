import { statusesForGlobal } from 'shared/helpers/constants/lots/statusesForGlobal';

const allowedStatuses = [
  statusesForGlobal.ready_to_ship,
  statusesForGlobal.shipped_from_global,
  statusesForGlobal.assay_results_received,
  statusesForGlobal.ship_summary_generated,
  statusesForGlobal.ship_summary_accepted,
];

const enterAssayResultsAccess = (
  {
    access: {
      lots: { enterAssayResults },
    },
  },
  lot,
) => enterAssayResults && allowedStatuses.includes(lot.statusForGlobal);

export { enterAssayResultsAccess };
