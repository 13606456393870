import styled from 'styled-components';

import { cssVar } from 'shared/helpers/styling/styling';

import { OverviewPackagesList } from 'pages/Packages/components/OverviewList/OverviewPackagesList';
import { relatedModules } from 'pages/Packages/components/OverviewList/config/relatedModules';

const GlobalPackagesOverviewItem = ({
  className,
  id,
  getPackagesOverviewForLots,
  sectionType,
  packagesList,
  ...props
}) => (
  <div className={className} id={id}>
    <OverviewPackagesList
      {...props}
      relatedModule={relatedModules.lots}
      getPackagesOverview={getPackagesOverviewForLots}
      sectionType={sectionType}
      packagesList={packagesList}
    />
  </div>
);

const StyledGlobalPackagesOverviewItem = styled(GlobalPackagesOverviewItem)`
  grid-area: ${({ id }) => id};
  background-color: ${cssVar('shark')};
`;

export { StyledGlobalPackagesOverviewItem };
