export const colorVariables = {
  // Global Steel - Primary portal color
  apple: '#58972f',
  argent: '#c1c1c1',
  argentBitLighter: '#cacaca',
  barleyCorn: '#7d7d7d',
  grayGRG: '#8a8a8a',
  shark: '#232323',
  ebonyClay: '#2d2d2d',
  ebonyClaySnackbar: '#2b2b2b',
  outerSpace: '#252525',
  outerSpaceBitLighter: '#3a3a3a',
  outerSpaceBrighter: '#151515',
  outerSpaceFormBg: '#181818',
  outerSpaceLighter: '#3c3c3c',
  outerSpaceListAppend: '#2f2f2f',
  outerSpaceTabs: '#191919',
  juneBud: '#bede6c',
  limedSpruceDarker: '#3c3c3c',
  limedSpruce: '#414141',
  limedSpruceList: '#484848',
  limedSpruceLighter: '#454545',
  limedSpruceLightest: '#474747',
  limedSpruceForm: '#303030',
  limedSpruceDetails: '#363636',
  limedSpruceCalendar: '#212121',
  limedSpruceNav: '#252525',
  calendarLegend: '#181818',
  sanJuan: '#4d4d4d',
  shuttleGray: '#454545',
  shuttleGrayCalendar: '#636363',
  riverBed: '#252525',
  riverBedAssay: '#444444',
  riverBedButton: '#545454',
  riverBedCalendar: '#3d3d3d',
  riverBedCalendarBorder: '#4f4f4f',
  riverBedDarker: '#515151',
  riverBedLegend: '#505050',
  riverBedLighter: '#4c4c4c',
  riverBedPkgs: '#4e4e4e',
  fiordLighter: '#343434',
  fiord: '#313131',
  fiordDarker: '#555555',
  trout: '#4d575e',
  shuttleGrayAssay: '#555555',
  cuttySark: '#6a6a6a',
  cuttySarkTable: '#4a4a4a',
  lynch: '#767676',
  lynchLegend: '#5e7b8d',
  nevadaDarker: '#606060',
  nevada: '#595959',
  paleSkyLighter: '#747474',
  paleSky: '#767676',
  slateGrayDarker: '#7f7f7f',
  slateGrayGRG: '#7f7f7f',
  regentGray: '#8a8a8a',
  regentGrayLight: '#9b9b9b',
  rollingStone: '#828282',
  regentGrayBrighter: '#8d8d8d',
  regentGrayLighter: '#949494',
  gullGray: '#9f9f9f',
  heather: '#b4b4b4',
  gullGrayLighter: '#b0b0b0',
  dustGray: '#979797',
  edward: '#a7a7a7',
  towerGray: '#bbbbbb',
  lightGrayGRG: '#d2d2d2',
  lightGrayBitLighter: '#dadada',
  silverSand: '#c9c9c9',
  geyser: '#d6d6d6',
  alto: '#cfcfcf',
  porcelain: '#eceff1',
  alabaster: '#f9f9f9',
  ghostWhiteGRG: '#f1f1f2',
  whiteGRG: '#ffffff',
  tangerine: '#f08700',
  fountainBlue: '#6aafc4',
  pelorous: '#33aebc',
  shipCove: '#6a85c4',
  kashmirBlue: '#5069a3',
  blueMarguerite: '#736ac4',
  amethyst: '#9c6ac4',
  silver: '#c2c2c2',
  silverDarker: '#c8c8c8',
  doveGray: '#6f6f6f',
  highland: '#738864',
  dingley: '#5d7b49',
  dingleyScanner: '#778948',
  forestGreenGRG: '#1a8b22',
  lochinvar: '#2f978d',
  astral: '#2f7297',
  sycamore: '#938c40',
  matrix: '#b25252',
  oldRose: '#c27d7d',
  scorpion: '#5c5c5c',
  tundora: '#474747',
  horizon: '#5f7daf',
  blueBayoux: '#49616f',
  smaltBlue: '#4f8392',
  seaGreenGRG: '#2f9761',
  boulder: '#787878',
  boulderLegend: '#7d7d7d',
  oxley: '#6d9b83',
  butterflyBash: '#595496',
  gumbo: '#75a0a1',
  cuttySarkStatus: '#44696b',
  gurkha: '#9e977e',
  greenHouse: '#185d10',
  blueLagon: '#048582',
  shipCove2: '#6b86c2',
  baliHai: '#859ead',
  rockBlue: '#9abbcf',
  roti: '#c3a642',
  graniteGray: '#676767',
  darkGrayGRG: '#a9a9a9',
  blackGRG: '#000',
  columnHeader: '#2f3e48',
  columnLabel: '#33414a',
  lotSection: '#495c6a',
  processingNote: '#5c6870',
  processingNoteIcon: '#91999f',
  gradingPos: '#303e46',
  dragLotBg: '#2a3135',
  lotColorHoverBg: '#454f55',

  // Global Green - Accent color
  atlantis: '#ee5b30',
  conifer: '#ee5b30',
  sushi: '#ee5b30',
  wasabi: '#dc562d',
  oliveDrab: '#c14c29',

  // Accept
  acceptColor: '#96bc33',

  // Global Violet - Secondary color
  wisteria: '#ba68c8',
  lightWisteria: '#ce93d8',

  // Red - Validation/Errors/Status/Delete
  copperRust: '#9b4446',
  wellRead: '#bc3333',
  solidRed: '#f44336',
  red: '#ef5350',
  carnation: '#f56a6a',
  japonica: '#d76976',
  roman: '#d65555',
  lightRed: '#ffcdd2',
  appleBlossom: '#ae4a4c',

  // Brown
  beaver: '#926f5e',

  // Green - Confirmation/Success/Status
  solidGreen: '#4caf50',
  green: '#81c784',
  lightGreen: '#c8e6c9',

  // Yellow
  solidYellow: '#ffc107',
  lightYellow: '#ffecb3',
  alpine: '#bc9833',
  yellow: '#ffe082',

  //
  headerMenuFocus: '#4e555b',
  headerMenuActive: '#545b62',
};
