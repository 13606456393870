import React from 'react';
import styled, { css } from 'styled-components';

import { Icon } from 'shared/components/Icon/Icon';
import { isGlobalRefiningGroup } from 'shared/helpers/matchers/isGlobalRefiningGroup';
import { cssVar } from 'shared/helpers/styling/styling';

const iconSize = 20;

const DraggableItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${cssVar('alabaster')};
  background-color: ${({ highlighted, highlightedColor }) =>
    cssVar(highlighted ? highlightedColor : 'blueBayoux')};
  border-radius: 8px;
  font-size: 0.9rem;
  width: 100%;
  min-height: 50px;
  box-sizing: border-box;
  padding: 14px;
  user-select: none;
  ${({ outlined }) =>
    outlined &&
    css`
      border: 2px solid ${cssVar('roman')};
    `}
  ${({ isDragging }) =>
    isDragging &&
    css`
      border: 2px solid ${cssVar('rockBlue')};
    `}
  cursor: ${({ dragDisabled }) => (dragDisabled ? 'not-allowed' : 'grab')};

  &:focus {
    outline: none;
    box-shadow: 0 0 0 1px ${cssVar('atlantis')};
  }
`;

const ContentWrapper = styled.div`
  flex: 1;
`;

const IconWrapper = styled.div`
  display: flex;
  padding: 0 10px;
  align-content: center;

  ${Icon} {
    ${({ cursor }) =>
      css`
        cursor: ${cursor};
      `}
    ${({ highlighted }) =>
      !highlighted &&
      css`
        opacity: 0.4;
      `}
  }

  &:last-child {
    border-left: 1px solid rgba(255, 255, 255, 0.34);
    padding-right: 0px;
  }
`;

const DraggableItemBasic = ({
  item,
  itemsTemplate,
  actionsTemplate,
  dragRef,
  outlined,
  ...rest
}) => (
  <DraggableItemWrapper
    {...rest}
    ref={dragRef}
    outlined={
      !isGlobalRefiningGroup(item.sentFromCompany?.companyName) &&
      !item.billOfLading?.length &&
      outlined
    }
  >
    <ContentWrapper>
      {itemsTemplate.map((field, index) => (
        <div key={index}>{field.render(item)}</div>
      ))}
    </ContentWrapper>
    <IconWrapper highlighted={rest.highlighted}>
      <Icon icon={rest.icon} width={iconSize} height={iconSize} size="contain" />
    </IconWrapper>
    {actionsTemplate.map(({ icon, onClick }, index) => (
      <IconWrapper key={index} cursor="pointer">
        <Icon
          icon={icon}
          onClick={onClick(item)}
          width={iconSize}
          height={iconSize}
          size="contain"
        />
      </IconWrapper>
    ))}
  </DraggableItemWrapper>
);

DraggableItemBasic.defaultProps = {
  highlightedColor: 'shipCove',
};

export { DraggableItemBasic };
