import React from 'react';
import { Field, reduxForm } from 'redux-form';

import { Button } from 'shared/components/Buttons/Button';
import { Checkbox } from 'shared/components/Fields/FieldCheckbox/FieldCheckbox';
import { Icon } from 'shared/components/Icon/Icon';
import { SHARED } from 'shared/helpers/constants/sharedConstants';
import {
  successOptions,
  dangerOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { USER } from 'shared/helpers/constants/users/userConstants';
import { normalizeCheckbox } from 'shared/helpers/parsers/boolean';
import { camelToSentence } from 'shared/helpers/parsers/text';

import {
  PreferencesWrapper,
  PreferencesHeader,
  PreferencesFooter,
  PreferencesSection,
} from '../components';

function DashboardPreferencesLayout(props) {
  const submit = values =>
    props
      .updateDashboardPreferences(props.user._id, values)
      .then(res => {
        props.showSnackbar(successOptions, res.message);
        props.getUser(props.user._id);
      })
      .catch(err => {
        props.showSnackbar(dangerOptions, err.message);
      });

  const renderPreferencesField = () => {
    const fieldsNames = Object.keys(props.user.dashboardPreferences);

    return fieldsNames.map(name => (
      <PreferencesSection gridTemplate="1fr 1fr" key={name}>
        <div>{camelToSentence(name)}</div>
        <Field
          name={name}
          component={Checkbox}
          type="checkbox"
          id={name}
          normalize={normalizeCheckbox}
        />
      </PreferencesSection>
    ));
  };

  return (
    <PreferencesWrapper>
      <form onSubmit={props.handleSubmit(submit)} noValidate>
        <PreferencesHeader gridTemplate="1fr 1fr">
          <h2>{USER.DASHBOARD_PREFERENCES}</h2>
          <div>{SHARED.WIDGETS}</div>
          <div>{SHARED.DISPLAY}</div>
        </PreferencesHeader>
        {renderPreferencesField()}
        <PreferencesFooter>
          <Button type="submit" outline disabled={!props.dirty}>
            {SHARED.SAVE_BUTTON}
            <Icon icon="icon-tick" />
          </Button>
        </PreferencesFooter>
      </form>
    </PreferencesWrapper>
  );
}

const connectedForm = reduxForm({
  form: 'DashboardPreferences',
  enableReinitialize: true,
})(DashboardPreferencesLayout);

export { connectedForm as DashboardPreferences, DashboardPreferencesLayout };
