import axios from 'axios';

import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';
import { messageTypes } from 'shared/helpers/constants/snackbar/snackbarMessageTypes';

const API_HOST_AUTH = process.env.REACT_APP_API;

const changeProcessUponDeliveryActionType = 'CHANGE_PROCESS_UPON_DELIVERY';
function changeProcessUponDeliveryAction() {
  return {
    type: changeProcessUponDeliveryActionType,
  };
}

const changeProcessUponDeliverySuccessActionType = 'CHANGE_PROCESS_UPON_DELIVERY_SUCCESS';
function changeProcessUponDeliverySuccessAction(lot) {
  return {
    type: changeProcessUponDeliverySuccessActionType,
    payload: {
      lot,
    },
  };
}

const changeProcessUponDeliveryFailureActionType = 'CHANGE_PROCESS_UPON_DELIVERY_FAILURE';
function changeProcessUponDeliveryFailureAction() {
  return {
    type: changeProcessUponDeliveryFailureActionType,
  };
}

function changeProcessUponDeliveryField(id, processUponDelivery) {
  return dispatch => {
    dispatch(changeProcessUponDeliveryAction());

    return axios({
      method: 'put',
      url: `${API_HOST_AUTH}/lots/processUponDelivery/${id}`,
      data: JSON.stringify({ processUponDelivery }),
    })
      .then(response => {
        dispatch(changeProcessUponDeliverySuccessAction(response.data.lot));
        return {
          message: response.data.message,
          messageType: messageTypes.success,
        };
      })
      .catch(error => {
        dispatch(changeProcessUponDeliveryFailureAction());
        return {
          message: `${LOTS.ERRORS.PROCESS_UPON_DELIVERY_ERROR} ${error.data.message}`,
          messageType: messageTypes.error,
        };
      });
  };
}

export {
  changeProcessUponDeliveryActionType,
  changeProcessUponDeliveryAction,
  changeProcessUponDeliverySuccessActionType,
  changeProcessUponDeliverySuccessAction,
  changeProcessUponDeliveryFailureActionType,
  changeProcessUponDeliveryFailureAction,
  changeProcessUponDeliveryField,
};
