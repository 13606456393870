import axios from 'axios';

import { parsePrivilegedCompany } from 'shared/helpers/dataParsers/company/parsePrivilegedCompany';

const API_HOST_AUTH = process.env.REACT_APP_API;

const createPrivilegedCompanyActionType = 'CREATE_PRIVILEGED_COMPANY';
const createPrivilegedCompanyAction = () => ({
  type: createPrivilegedCompanyActionType,
});

const createPrivilegedCompanySuccessActionType = `${createPrivilegedCompanyActionType}_SUCCESS`;
const createPrivilegedCompanySuccessAction = () => ({
  type: createPrivilegedCompanySuccessActionType,
});

const createPrivilegedCompanyFailureActionType = `${createPrivilegedCompanyActionType}_FAILURE`;
const createPrivilegedCompanyFailureAction = () => ({
  type: createPrivilegedCompanyFailureActionType,
});

const filterCompaniesToAddActionType = `${createPrivilegedCompanyActionType}_FILTER`;
const filterCompaniesToAddAction = () => ({
  type: filterCompaniesToAddActionType,
});

const createPrivilegedCompany = (values, url) => dispatch => {
  dispatch(createPrivilegedCompanyAction());

  return axios
    .post(`${API_HOST_AUTH}/${url}`, parsePrivilegedCompany(values))
    .then(() => dispatch(createPrivilegedCompanySuccessAction()))
    .catch(error => {
      dispatch(createPrivilegedCompanyFailureAction());

      return Promise.reject(error);
    });
};

const filterCompaniesToAdd = (filter, url) => dispatch =>
  axios
    .post(`${API_HOST_AUTH}/${url}/filter-to-add`, { filter })
    .then(({ data }) =>
      data.map(({ _id, companyName, companyType, balanceRemaining, exceptionTypes }) => ({
        value: _id,
        label: companyName,
        companyType,
        balanceRemaining,
        exceptionTypes,
      })),
    )
    .then(companies => {
      dispatch(filterCompaniesToAddAction());
      return { options: companies };
    });

const createTrustedCompany = relatedCompany =>
  createPrivilegedCompany(relatedCompany, 'trusted-companies');

const createPaymentExceptionCompany = values =>
  createPrivilegedCompany(values, 'payment-exception-companies');

const filterTrustedCompaniesToAdd = filter => filterCompaniesToAdd(filter, 'trusted-companies');

const filterPaymentExceptionCompaniesToAdd = filter =>
  filterCompaniesToAdd(filter, 'payment-exception-companies');

const filterEnterPaymentCompanies = filter => filterCompaniesToAdd(filter, 'payments/enter');

export {
  createPrivilegedCompanyActionType,
  createPrivilegedCompanySuccessActionType,
  createPrivilegedCompanyFailureActionType,
  filterCompaniesToAddActionType,
  filterCompaniesToAdd,
  createPrivilegedCompany,
  createTrustedCompany,
  createPaymentExceptionCompany,
  filterTrustedCompaniesToAdd,
  filterPaymentExceptionCompaniesToAdd,
  filterEnterPaymentCompanies,
};
