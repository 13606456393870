import React from 'react';

import { IsEmpty } from 'shared/components/IsEmpty/IsEmpty';
import { StatusIndicator } from 'shared/components/StatusIndicator/StatusIndicator';
import { formatDate, formatToUS } from 'shared/helpers/parsers/formaters';

import { SHIPMENTS } from './shipmentsConstants';

const itemsTemplate = [
  {
    label: SHIPMENTS.STATUS,
    render: ({ status }) => <StatusIndicator status={status} />,
  },
  {
    label: SHIPMENTS.SENT_FROM_COMPANY_LOCATION,
    render: ({ sentFromCompanyLocation }) => sentFromCompanyLocation.name,
  },
  {
    label: SHIPMENTS.EST_PICKUP_DATE,
    render: ({ estPickupDate }) => formatDate(estPickupDate),
  },
  {
    label: SHIPMENTS.EST_DELIVERY_DATE,
    render: ({ estDeliveryDate }) => formatDate(estDeliveryDate),
  },
  {
    label: SHIPMENTS.LOAD_NUMBER,
    render: ({ loadNumber }) => loadNumber || SHIPMENTS.EMPTY_FIELD,
  },
  {
    label: SHIPMENTS.ASSIGNED_LOTS,
    render: ({ assignedLot }) => assignedLot.map(lot => <div key={lot}>{lot}</div>),
  },
  {
    label: SHIPMENTS.GROSS_DECLARED,
    render: ({ weightGrossDeclared }) => <IsEmpty value={formatToUS(weightGrossDeclared)} />,
  },
];

const getItemsTemplateBuyer = itemsTemplate.filter(item => item.label !== SHIPMENTS.LOAD_NUMBER)

const itemsTemplateMobile = [
  {
    label: SHIPMENTS.STATUS,
    render: ({ status }) => <StatusIndicator status={status} />,
  },
  {
    label: SHIPMENTS.SENT_FROM_COMPANY_LOCATION,
    render: ({ sentFromCompanyLocation }) => sentFromCompanyLocation.name,
  },
  {
    label: SHIPMENTS.EST_PICKUP_DATE,
    render: ({ estPickupDate }) => formatDate(estPickupDate),
  },
];

const [, , , ...itemsDetailsTemplate] = itemsTemplate;

export { itemsTemplate, itemsTemplateMobile, itemsDetailsTemplate, getItemsTemplateBuyer };
