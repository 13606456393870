const moveToProcessingAccess = (
  {
    access: {
      lots: { moveToProcessing },
    },
  },
  { acceptedForProcessingBy, acceptedForProcessingAt },
) => moveToProcessing && !acceptedForProcessingBy && !acceptedForProcessingAt;

export { moveToProcessingAccess };
