import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { changeLocationStatus } from 'actions/Locations/changeLocationStatus';
import { openModal } from 'actions/shared/modal';

import {
  inactivateLocationModal,
  reactivateLocationModal,
} from 'shared/helpers/constants/modalConstants';

function LocationChangeStatus(props) {
  const changeStatus = () => {
    if (props.location.status === 'active') {
      props.openModal(inactivateLocationModal, () => {
        props.changeLocationStatus(props.location._id, 'inactive');
      });
    } else {
      props.openModal(reactivateLocationModal, () => {
        props.changeLocationStatus(props.location._id, 'active');
      });
    }
  };

  return <React.Fragment>{props.children({ changeStatus })}</React.Fragment>;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      openModal,
      changeLocationStatus,
    },
    dispatch,
  );
}

const ConnectedLocationChangeStatus = connect(null, mapDispatchToProps)(LocationChangeStatus);

export { ConnectedLocationChangeStatus as LocationChangeStatus };
