import axios from 'axios';

import { PAYMENTS } from 'shared/helpers/constants/payments/paymentsConstants';
import { messageTypes } from 'shared/helpers/constants/snackbar/snackbarMessageTypes';
import { parseAdvancePaymentData } from 'shared/helpers/dataParsers/payments/parseAdvancePaymentData';
import { parseFinalPaymentData } from 'shared/helpers/dataParsers/payments/parseFinalPaymentData';

const API_HOST_AUTH = process.env.REACT_APP_API;

const requestPaymentActionType = 'REQUEST_PAYMENT';
const requestPaymentAction = () => ({
  type: requestPaymentActionType,
});

const requestPaymentSuccessActionType = `${requestPaymentActionType}_SUCCESS`;
const requestPaymentSuccessAction = () => ({
  type: requestPaymentSuccessActionType,
});

const requestPaymentFailureActionType = `${requestPaymentActionType}_FAILURE`;
const requestPaymentFailureAction = () => ({
  type: requestPaymentFailureActionType,
});

const requestPayment = (paymentType, errorMessage, dataParser) => data => dispatch => {
  dispatch(requestPaymentAction());

  return axios
    .post(`${API_HOST_AUTH}/payments/request/${paymentType}`, dataParser(data))
    .then(res => {
      dispatch(requestPaymentSuccessAction());

      return {
        id: res.data.id,
        message: res.data.message,
        messageType: messageTypes.success,
      };
    })
    .catch(error => {
      dispatch(requestPaymentFailureAction());

      return Promise.reject({
        message: `${errorMessage} ${error.data.message}`,
        messageType: messageTypes.error,
      });
    });
};

const requestFinalPayment = requestPayment(
  'final',
  PAYMENTS.ERROR.REQUEST_FINAL_PAYMENT_ERROR,
  parseFinalPaymentData,
);

const requestAdvancePayment = requestPayment(
  'advance',
  PAYMENTS.ERROR.REQUEST_ADVANCE_PAYMENT_ERROR,
  parseAdvancePaymentData,
);

export {
  requestPaymentActionType,
  requestPaymentAction,
  requestPaymentSuccessActionType,
  requestPaymentSuccessAction,
  requestPaymentFailureActionType,
  requestPaymentFailureAction,
  requestPayment,
  requestFinalPayment,
  requestAdvancePayment,
};
