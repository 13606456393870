import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

import { Icon } from 'shared/components/Icon/Icon';
import { SHARED } from 'shared/helpers/constants/sharedConstants';

import { Button } from '../Button';

const ButtonStyled = styled(Button)`
  width: 100%;
  justify-content: space-between;

  ${({ disabled }) =>
    disabled &&
    css`
      border: 2px dashed rgba(255, 255, 255, 0.32);
      background-color: transparent;

      :disabled {
        opacity: 1;
        cursor: default;
      }

      :hover {
        background-color: transparent;
        border-color: rgba(255, 255, 255, 0.32);
      }
    `}

  :hover {
    opacity: 0.9;
  }
`;

function StatusButton(props) {
  const { color, empty, plural, icon, onClick, className } = props;
  const data = Array.prototype.concat(props.data);
  const dataLength = data.length;

  const generateLabel = () => {
    const [first] = data;

    switch (dataLength) {
      case 0:
        return empty;
      case 1:
        return first.replace('-', SHARED.NON_BREAKING_HYPHEN);
      default:
        return `${dataLength} ${plural}`;
    }
  };

  return (
    <ButtonStyled
      className={className}
      bgColor={color}
      bgColorHover={color}
      outlineColor={color}
      outlineColorHover={color}
      borderRadius={8}
      disabled={!dataLength}
      onClick={onClick}
      statusButton
    >
      {generateLabel()}
      {!!icon && !!dataLength && <Icon icon={icon} padding="0 12px" />}
    </ButtonStyled>
  );
}

StatusButton.propTypes = {
  color: PropTypes.string.isRequired,
  icon: PropTypes.string,
  plural: PropTypes.string.isRequired,
  empty: PropTypes.string.isRequired,
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
  onClick: PropTypes.func,
};

export { StatusButton };
