import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

import { cssVar, pxToRem } from 'shared/helpers/styling/styling';

const FormRowCellLayout = ({ className, children, onClick, name }) => (
  <div className={className} onClick={e => onClick && onClick(e, name)}>
    <div>{children}</div>
  </div>
);

const FormRowCell = styled(FormRowCellLayout)`
  padding: 5px 5px;

  & > div {
    display: flex;
    align-items: center;
    height: 50px;
    padding: 5px 11px;
    font-size: ${pxToRem(14)};
    ${({ noBorder }) =>
      !noBorder &&
      css`
        background-color: ${cssVar('limedSpruceForm')};
        border: 2px solid ${cssVar('regentGrayLighter')};
      `};
    border-radius: 5px;
    word-break: break-word;
  }
`;

FormRowCell.propTypes = {
  noBorder: PropTypes.bool,
};

FormRowCell.defaultProps = {
  noBorder: false,
};

export { FormRowCell };
