import PropTypes from 'prop-types';
import React, { useEffect, useCallback } from 'react';
import { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Field } from 'redux-form';

import {
  getLotAvailableOunces,
  clearLotAvailableOunces,
} from 'actions/Deliveries/getLotAvailableOunces';

import { IconButton } from 'shared/components/Buttons/IconButton/IconButton';
import { FieldSelect, FieldHint, FieldInput } from 'shared/components/Fields';
import { Section } from 'shared/components/Form';
import { MultipleFieldsSection } from 'shared/components/Form/MultipleFieldsSection/MultipleFieldsSection';
import { DELIVERIES } from 'shared/helpers/constants/deliveries/deliveriesConstants';
import { isTablet } from 'shared/helpers/constants/resolutionsConstants';
import { SHARED } from 'shared/helpers/constants/sharedConstants';
import { parseLotsData } from 'shared/helpers/dataParsers/lot/parseLotsSelectData';
import { dateFormat } from 'shared/helpers/parsers/date';
import { roundUp } from 'shared/helpers/parsers/number';
import { replaceEmpty } from 'shared/helpers/parsers/text';
import { cssVar } from 'shared/helpers/styling/styling';
import { useWindowWidth } from 'shared/hooks/useWindowWidth';

import dateAdapter from 'utils/date/dateAdapter';

const DeliveryForm = props => {
  const {
    values,
    previousRemainingOunces,
    previousRemainingLotOunces,
    onRemove,
    change,
    blur,
    delivery,
    location: { state },
    formValues,
  } = props;

  const dispatch = useDispatch();
  const hedges = useSelector(({ availableHedges: { hedges } }) => hedges);
  const lots = useSelector(({ availableLots: { lots } }) => lots);
  const windowWidth = useWindowWidth();
  const currentLot = formValues && formValues.relatedLot;
  const availableOunces = useSelector(({ lotAvailableOunces }) => lotAvailableOunces);
  const available = useMemo(
    () => availableOunces.available && availableOunces.available[currentLot && currentLot.value],
  );

  useEffect(() => {
    state && state.relatedLot && change(`${delivery}.relatedLot`, state.relatedLot);
  }, [state, change, delivery]);

  const setLeftOuncesValue = () => {
    change(
      `${delivery}.availablePlatinumOz`,
      available && available.platinumOz === null
        ? '-'
        : replaceEmpty(
            available &&
              (+roundUp(available?.platinumOz - previousRemainingLotOunces['platinumOz'], 3) || 0),
          ),
    );
    change(
      `${delivery}.availablePalladiumOz`,
      available && available.palladiumOz === null
        ? '-'
        : replaceEmpty(
            available &&
              (+roundUp(available.palladiumOz - previousRemainingLotOunces['palladiumOz'], 3) || 0),
          ),
    );
    change(
      `${delivery}.availableRhodiumOz`,
      available && available.rhodiumOz === null
        ? '-'
        : replaceEmpty(
            available &&
              (+roundUp(available.rhodiumOz - previousRemainingLotOunces['rhodiumOz'], 3) || 0),
          ),
    );
  };

  useEffect(() => {
    setLeftOuncesValue();
  }, [setLeftOuncesValue]);

  useEffect(() => {
    if (state?.relatedLot?.value) {
      dispatch(getLotAvailableOunces(state.relatedLot.value));
    }
  }, []);

  const parseHedgesOptions = () =>
    hedges.map(hedge => ({
      value: hedge._id,
      label: `${hedge.hedgeName} ${DELIVERIES.PLACED_ON}: ${dateAdapter(hedge.placedAt).format(
        dateFormat,
      )}`,
    }));

  const formatMaximum = (currentValue, previousValue) => roundUp(currentValue - previousValue, 3);

  const selectedHedge = values.relatedHedge;
  const selectedHedgeId = selectedHedge && selectedHedge.value;
  const relatedHedge = hedges.find(hedge => hedge._id === selectedHedgeId) || {};

  const actions = onRemove && (
    <IconButton onClick={onRemove} icon="icon-cancel" color={cssVar('whiteGRG')} />
  );
  const onLotChange = useCallback(
    (currentValue, newValue, prevValue) => {
      if (currentValue) {
        dispatch(getLotAvailableOunces(currentValue.value));
      } else if (prevValue && prevValue.value) {
        dispatch(clearLotAvailableOunces(prevValue.value));
      }
    },
    [dispatch, selectedHedgeId],
  );

  const lotNumber = currentLot && currentLot.label;

  const ouncesAvailableField = (label, name) => ({
    component: FieldInput,
    name,
    label,
    field: 'required',
    suffix: DELIVERIES.OZ,
    disabled: true,
  });

  const enterOuncesField = (label, name, suggestion) => {
    if (selectedHedgeId && suggestion <= 0) {
      change(name, 0);
    }

    return {
      label,
      name,
      component: FieldHint,
      type: 'number',
      field: 'required',
      suffix: DELIVERIES.OZ,
      hint: SHARED.MAXIMUM,
      suggestion,
      disabled: !selectedHedgeId || suggestion <= 0,
    };
  };

  const lotAvailable = [
    ouncesAvailableField(DELIVERIES.PLATINUM_LEFT, `${delivery}.availablePlatinumOz`),
    ouncesAvailableField(DELIVERIES.PALLADIUM_LEFT, `${delivery}.availablePalladiumOz`),
    ouncesAvailableField(DELIVERIES.RHODIUM_LEFT, `${delivery}.availableRhodiumOz`),
  ];

  const formatSuggestion = ounces => {
    setLeftOuncesValue();
    const availableOunces = available?.[ounces];
    const formatted = formatMaximum(relatedHedge[ounces], previousRemainingOunces[ounces]);
    const formattedLot = formatMaximum(availableOunces, previousRemainingLotOunces[ounces]);

    if (formattedLot <= 0 && availableOunces !== null) {
      return null;
    }

    if (!availableOunces && availableOunces !== 0) {
      return isNaN(formatted) ? null : formatted;
    }

    if (+formattedLot <= +formatted) {
      return isNaN(formattedLot) ? null : formattedLot;
    }

    return isNaN(formatted) ? null : formatted;
  };

  const ouncesFields = [
    enterOuncesField(
      DELIVERIES.PLATINUM,
      `${delivery}.platinumOz`,
      selectedHedgeId && formatSuggestion('platinumOz'),
    ),
    enterOuncesField(
      DELIVERIES.PALLADIUM,
      `${delivery}.palladiumOz`,
      selectedHedgeId && formatSuggestion('palladiumOz'),
    ),
    enterOuncesField(
      DELIVERIES.RHODIUM,
      `${delivery}.rhodiumOz`,
      selectedHedgeId && formatSuggestion('rhodiumOz'),
    ),
  ];

  const handleOnChange = () => {
    blur(`${delivery}.relatedHedge`, null);
    change(`${delivery}.relatedLot`, null);
  };

  const fieldsMobile = lotAvailable.reduce(
    (prev, current, i) => [...prev, ...[current, ouncesFields[i]]],
    [],
  );

  return (
    <>
      <Section
        template={[`${delivery}.relatedHedge ${delivery}.relatedLot`]}
        actions={actions}
        header={DELIVERIES.ASSIGNMENT}
      >
        <Field
          name={`${delivery}.relatedHedge`}
          component={FieldSelect}
          options={parseHedgesOptions()}
          onChange={handleOnChange}
          label={DELIVERIES.RELATED_HEDGE}
          field="required"
        />
        <Field
          name={`${delivery}.relatedLot`}
          component={FieldSelect}
          options={parseLotsData(lots)}
          onChange={onLotChange}
          label={DELIVERIES.RELATED_LOT}
          field="required"
          disabled={!selectedHedgeId || !!(state && state.relatedLot)}
        />
      </Section>
      {windowWidth >= isTablet ? (
        <Section
          header={DELIVERIES.LOT(lotNumber)}
          template={['lotAvailable lotAvailable', 'ouncesFields ouncesFields']}
          noPadding
        >
          <MultipleFieldsSection name="lotAvailable" fields={lotAvailable} />
          <MultipleFieldsSection name="ouncesFields" fields={ouncesFields} />
        </Section>
      ) : (
        <Section
          header={DELIVERIES.LOT(lotNumber)}
          template={['fieldsMobile fieldsMobile']}
          noPadding
        >
          <MultipleFieldsSection name="fieldsMobile" fields={fieldsMobile} />
        </Section>
      )}
    </>
  );
};

DeliveryForm.propTypes = {
  values: PropTypes.object,
  previousRemainingOunces: PropTypes.object,
  previousRemainingLotOunces: PropTypes.object,
  onRemove: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  change: PropTypes.func,
  blur: PropTypes.func,
  delivery: PropTypes.string,
  location: PropTypes.shape({
    state: PropTypes.object,
  }),
  formValues: PropTypes.object,
};

export { DeliveryForm };
