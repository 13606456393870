import { connect } from 'react-redux';

import {
  getDefaultPPMs,
  updateDefaultPPMs,
  clearDefaultPPMsAction,
} from 'actions/Hedges/defaultPPMs';
import { openModal } from 'actions/shared/modal';
import { showSnackbar } from 'actions/shared/snackbar';

import { DefaultPPMs } from 'pages/Hedges/components/DefaultPPMs/DefaultPPMs';

const mapStateToProps = ({ auth, defaultPPMs }) => ({
  auth,
  defaultPPMs,
});

const mapDispatchToProps = {
  getDefaultPPMs,
  updateDefaultPPMs,
  clearDefaultPPMsAction,
  showSnackbar,
  openModal,
};

const ConnectedDefaultPPMs = connect(mapStateToProps, mapDispatchToProps)(DefaultPPMs);

export { ConnectedDefaultPPMs };
