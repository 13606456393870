import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { getLot } from 'actions/Lots/lotDetails';
import { updateShipmentInfo } from 'actions/Lots/updateShipmentInfo';
import { showSnackbar } from 'actions/shared/snackbar';

import { FormContainer } from 'shared/components/Form';
import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';
import { materialTypeOutbound } from 'shared/helpers/constants/packages/materialType';
import {
  dangerOptions,
  successOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { setDesignation } from 'shared/helpers/dataParsers/lot/setDesignation';
import { humanize } from 'shared/helpers/parsers/text';

import { goBackOrTo } from 'utils/history';

import { UpdateShipmentInfoForm } from './components/UpdateShipmentInfoForm/UpdateShipmentInfoForm';

class UpdateShipmentInfoBase extends Component {
  componentDidMount() {
    const lotId = this.props.match.params.id;

    this.props.getLot(lotId).catch(() => goBackOrTo(`/lots/list/${lotId}`));
  }

  onSubmit = ({ stillwaterShipmentId, materialType: { value: materialType } }) => {
    const lotId = this.props.match.params.id;

    return this.props
      .updateShipmentInfo(lotId, { stillwaterShipmentId, materialType })
      .then(res => {
        this.props.showSnackbar(successOptions, res.message);
        this.navigateBack();
      })
      .catch(res => this.props.showSnackbar(dangerOptions, res.message));
  };

  navigateBack() {
    this.props.history.push(`/lots/list/${this.props.match.params.id}`);
  }

  render() {
    const { lot, isPending } = this.props.lotDetails;
    const initialValues = {
      stillwaterShipmentId: lot && lot.stillwaterShipmentId,
      materialType:
        lot && lot.materialType
          ? {
              label: humanize(lot.materialType),
              value: materialTypeOutbound[lot.materialType],
            }
          : null,
    };

    const header = `${LOTS.UPDATE_SHIPMENT_INFORMATION} ${
      lot ? lot.customLotNumber || setDesignation(lot.grgLotNumber, lot.designation) : ''
    }`;

    return (
      <FormContainer loaded={!isPending && !!lot} header={header}>
        {() => <UpdateShipmentInfoForm initialValues={initialValues} onSubmit={this.onSubmit} />}
      </FormContainer>
    );
  }
}

const mapStateToProps = ({ lotDetails }) => ({
  lotDetails,
});

const mapDispatchToProps = {
  getLot,
  updateShipmentInfo,
  showSnackbar,
};

const UpdateShipmentInfo = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UpdateShipmentInfoBase),
);

export { UpdateShipmentInfo, UpdateShipmentInfoBase };
