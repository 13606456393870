const CREATE_SHIPMENT = {
  INVALID_SENT_FROM_COMPANY_LOCATION_EMPTY: 'Sent from company location cannot be empty.',
  INVALID_ASSIGNED_LOT_EMPTY: 'Assigned lot cannot be empty.',
  INVALID_SHIP_DATE_EMPTY: 'Ship date cannot be empty.',
  INVALID_SHIP_DATE_VALUE: 'Ship date is later than Est. delivery date.',
  INVALID_SHIP_DATE_VALUE_SAME: 'Ship date must be earlier than Est. delivery date.',
  INVALID_EST_PICKUP_DATE_EMPTY: 'Est. pickup date cannot be empty.',
  INVALID_EST_PICKUP_DATE_PAST: 'Est. pickup date cannot be in the past.',
  INVALID_EST_PICKUP_DATE_VALUE: 'Est. pickup date is later than Est. delivery date.',
  INVALID_EST_PICKUP_DATE_VALUE_SAME: 'Est. pickup date must be earlier than Est. delivery date.',
  INVALID_EST_DELIVERY_DATE_EMPTY: 'Est. delivery date cannot be empty.',
  INVALID_EST_DELIVERY_DATE_PAST: 'Est. delivery date cannot be in the past.',
  INVALID_EST_DELIVERY_DATE_VALUE: 'Est. delivery date is earlier than Est. pickup date.',
  INVALID_EST_DELIVERY_DATE_VALUE_SAME: 'Est. delivery date must be later than Est. pickup date.',
  INVALID_SHIPMENT_COMPANY: 'Shipment company must have a valid format.',
  INVALID_SHIPMENT_COMPANY_LENGTH: 'Shipment company cannot be longer than 50 characters.',
  INVALID_SHIPMENT_SEAL: 'Shipment seal must have a valid format.',
  INVALID_SHIPMENT_SEAL_LENGTH: 'Shipment seal cannot be longer than 50 characters.',
  INVALID_LOAD_NUMBER_LENGTH: 'Load number cannot be longer than 50 characters.',
  INVALID_SENSOR: 'Sensor must have a valid format.',
  INVALID_SENSOR_LENGTH: 'Sensor cannot be longer than 50 characters.',
  INVALID_TRAILER: 'Trailer must have a valid format.',
  INVALID_TRAILER_LENGTH: 'Trailer cannot be longer than 50 characters.',
  INVALID_TRUCKING_COMPANY: 'Trucking company must have a valid format.',
  INVALID_TRUCKING_COMPANY_LENGTH: 'Trucking company cannot be longer than 50 characters.',
  INVALID_BILL_OF_LADING: 'BOL must have a valid format.',
  INVALID_BILL_OF_LADING_LENGTH: 'BOL cannot be longer than 50 characters.',
  INVALID_PURCHASE_ORDER: 'PO # must have a valid format.',
  INVALID_PURCHASE_ORDER_LENGTH: 'PO # cannot be longer than 50 characters.',
  INVALID_CONTACT_NUMBER: 'Contact number must have a valid format.',
  INVALID_CONTACT_NUMBER_EMPTY: 'Contact number cannot be empty.',
  INVALID_CONTACT_NAME_EMPTY: 'Contact name cannot be empty.',
};

export { CREATE_SHIPMENT };
