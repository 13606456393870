import { connect } from 'react-redux';

import { getCompany, clearCompanyDetails } from 'actions/Companies/companyDetails';
import { getCompaniesBySearchStrUserType } from 'actions/Companies/filterCompanies';
import { getCompanyLocations } from 'actions/Locations/getCompanyLocations';
import { getUser, clearUserDetails } from 'actions/Users/Details/userDetails';
import { updateUser } from 'actions/Users/updateUser';
import { openModal, closeModal } from 'actions/shared/modal';

import { UpdateUser } from './UpdateUser';

function mapStateToProps({
  auth,
  router,
  modal,
  filteredCompanies,
  createAttachmentStorage,
  companyLocations,
  companyDetails,
  createUser,
  userDetails,
}) {
  return {
    auth,
    router,
    modal,
    createUser,
    filteredCompanies,
    createAttachmentStorage,
    companyLocations,
    companyDetails,
    userDetails,
  };
}

const mapDispatchToProps = {
  updateUser,
  getUser,
  clearUserDetails,
  getCompany,
  getCompaniesBySearchStrUserType,
  getCompanyLocations,
  clearCompanyDetails,
  openModal,
  closeModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateUser);
