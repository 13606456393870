import React, { Component } from 'react';

import { ContentLoader } from 'shared/components/ContentLoader/ContentLoader';
import { renderDetailsContainer } from 'shared/components/Details/helpers/renderDetailsContainer';
import { Preloader } from 'shared/components/Preloader/Preloader';
import { FILES_CONSTANTS } from 'shared/helpers/constants/filesConstants';
import { dangerOptions } from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

import { extractErrorMessage } from 'utils/extractErrorMessage';

import { AgreementsMainDetails } from './components/AgreementsMainDetails';
import {
  agreementDetailsContainerConfig,
  activityContainerConfig,
  activityFieldsConfig,
  agreementDetailsFieldsConfig,
} from './config/fieldsConfig';

class AgreementsDetails extends Component {
  componentDidMount() {
    this.props.getAgreement(this.props.match.params.id).catch(error => {
      const {
        match: {
          params: { companyId },
        },
      } = this.props;

      this.props.showSnackbar(dangerOptions, extractErrorMessage(error));
      return this.props.history.push(`/companies/${companyId}`);
    });
  }

  componentWillUnmount() {
    this.props.clearAgreementsDetails();
  }

  render() {
    const {
      auth,
      agreementsDetails,
      agreementsUploadDocument,
      downloadApiAttachment,
      downloadAttachmentStorage,
    } = this.props;
    const { agreement, isPending } = agreementsDetails;

    return (
      <ContentLoader loaded={agreement && !isPending}>
        {agreement && (
          <>
            <AgreementsMainDetails
              agreement={agreement}
              agreementsUploadDocument={agreementsUploadDocument}
              access={auth.access}
              auth={auth}
            />
            {renderDetailsContainer(
              agreementDetailsContainerConfig,
              agreementDetailsFieldsConfig,
              agreement,
              downloadApiAttachment,
            )}
            {renderDetailsContainer(activityContainerConfig, activityFieldsConfig, agreement)}
          </>
        )}
        <Preloader
          loading={downloadAttachmentStorage.isPending}
          label={FILES_CONSTANTS.DOWNLOADING_FILES}
        />
      </ContentLoader>
    );
  }
}

export { AgreementsDetails };
