import { connect } from 'react-redux';

import { inactivateUser as inactivateUserAction } from 'actions/Users/inactivateUser';
import { openModal } from 'actions/shared/modal';
import { showSnackbar } from 'actions/shared/snackbar';

import { inactivateUserModal } from 'shared/helpers/constants/modalConstants';
import { snackbarOptions } from 'shared/helpers/matchers/snackbar/snackbarOptions';

function InactivateUserLayout(props) {
  const inactivateUser = () => {
    props.openModal(inactivateUserModal, () => {
      props
        .inactivateUser(props.user._id)
        .then(({ message, messageType }) =>
          props.showSnackbar(snackbarOptions(messageType), message),
        );
    });
  };

  return props.children({ inactivateUser });
}

const mapDispatchToProps = {
  openModal,
  showSnackbar,
  inactivateUser: inactivateUserAction,
};

const InactivateUser = connect(null, mapDispatchToProps)(InactivateUserLayout);

export { InactivateUser, InactivateUserLayout };
