import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const acceptForGradingType = 'ACCEPT_FOR_GRADING';
const acceptForGradingAction = () => ({
  type: acceptForGradingType,
});

const acceptForGradingSuccessType = `${acceptForGradingType}_SUCCESS`;
const acceptForGradingSuccessAction = lot => ({
  type: acceptForGradingSuccessType,
  payload: {
    lot,
  },
});

const acceptForGradingFailureType = `${acceptForGradingType}_FAILURE`;
const acceptForGradingFailureAction = () => ({
  type: acceptForGradingFailureType,
});

const acceptForGrading = lotId => dispatch => {
  dispatch(acceptForGradingAction());

  return axios
    .put(`${API_HOST_AUTH}/lots/accept-for-grading/${lotId}`)
    .then(res => {
      dispatch(acceptForGradingSuccessAction(res.data.lot));

      return res.data;
    })
    .catch(err => {
      dispatch(acceptForGradingFailureAction());

      return Promise.reject(err.data);
    });
};

export {
  acceptForGradingType,
  acceptForGradingSuccessType,
  acceptForGradingFailureType,
  acceptForGrading,
};
