import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getAssayResultsDetails, clearAssayResultsDetails } from 'actions/Lots/assayResultsDetails';
import { showSnackbar } from 'actions/shared/snackbar';

import { ContentLoader } from 'shared/components/ContentLoader/ContentLoader';
import { DetailsHeader } from 'shared/components/Details/DetailsHeader';
import { renderDetailsContainer } from 'shared/components/Details/helpers/renderDetailsContainer';
import { dangerOptions } from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

import { extractErrorMessage } from 'utils/extractErrorMessage';

import {
  weightsContainerConfig,
  weightsFieldsConfig,
  containedAssaysContainerConfig,
  containedAssaysFieldsConfig,
  containedMetalsContainerConfig,
  containedMetalsFieldsConfig,
  returnableOuncesContainerConfig,
  returnableOuncesFieldsConfig,
  activityFieldsConfig,
  activityContainerConfig,
} from './config/fieldsConfig';

class AssayResultsDetailsBase extends Component {
  componentDidMount() {
    const { match, history } = this.props;

    this.props.getAssayResultsDetails(match.params.assayId).catch(error => {
      this.props.showSnackbar(dangerOptions, extractErrorMessage(error));
      history.push(`/lots/list/${match.params.id}`);
    });
  }

  componentWillUnmount() {
    this.props.clearAssayResultsDetails();
  }

  render() {
    const { assayResults, isPending } = this.props.assayResultsDetails;

    return (
      <ContentLoader loaded={assayResults && !isPending}>
        {assayResults && (
          <>
            <DetailsHeader header={assayResults.relatedLot.grgLotNumber} />
            {renderDetailsContainer(weightsContainerConfig, weightsFieldsConfig, assayResults)}
            {renderDetailsContainer(
              containedAssaysContainerConfig,
              containedAssaysFieldsConfig,
              assayResults,
            )}
            {renderDetailsContainer(
              containedMetalsContainerConfig,
              containedMetalsFieldsConfig,
              assayResults,
            )}
            {renderDetailsContainer(
              returnableOuncesContainerConfig,
              returnableOuncesFieldsConfig,
              assayResults,
            )}
            {renderDetailsContainer(activityContainerConfig, activityFieldsConfig, assayResults)}
          </>
        )}
      </ContentLoader>
    );
  }
}

const mapStateToProps = ({ auth, assayResultsDetails }) => ({
  auth,
  assayResultsDetails,
});

const mapDispatchToProps = {
  getAssayResultsDetails,
  clearAssayResultsDetails,
  showSnackbar,
};

const AssayResultsDetails = connect(mapStateToProps, mapDispatchToProps)(AssayResultsDetailsBase);

export { AssayResultsDetailsBase, AssayResultsDetails };
