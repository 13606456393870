import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { FiltersDropdown } from 'shared/components/FiltersDropdown/FiltersDropdown';
import { HEDGES } from 'shared/helpers/constants/hedges/hedgesConstants';

import dateAdapter from 'utils/date/dateAdapter';

import {
  StyledMarketHolidayFilters,
  StyledMarketHolidayFiltersYear,
} from './StyledMarketHolidaysFilters';

class MarketHolidaysFilters extends Component {
  yearSelected = value => {
    const { filters, onFiltersChanged } = this.props;
    const selectedYear = filters.year;
    onFiltersChanged({ year: +value || selectedYear });
  };

  render() {
    const { filters } = this.props;
    const startYear = HEDGES.MARKET_HOLIDAYS.MIN_YEAR;
    const endYear = dateAdapter().year + 1;

    const years = [...Array(endYear - startYear + 1).keys()].map(y => (endYear - y).toString());

    const selected = [filters.year.toString()];

    return (
      <StyledMarketHolidayFilters>
        <StyledMarketHolidayFiltersYear>
          <FiltersDropdown
            label={HEDGES.MARKET_HOLIDAYS.SELECT_YEAR}
            options={years}
            selected={selected}
            filterOptionsSelected={this.yearSelected}
            notClearable
          />
        </StyledMarketHolidayFiltersYear>
      </StyledMarketHolidayFilters>
    );
  }
}

MarketHolidaysFilters.propTypes = {
  onFiltersChanged: PropTypes.func,
  filters: PropTypes.object,
};

export { MarketHolidaysFilters };
