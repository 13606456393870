import { FILES_CONSTANTS } from 'shared/helpers/constants/filesConstants';
import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';
import { VALIDATE_UTF_8, PDF_REGEX } from 'shared/helpers/validations/regularExpressions';

const validate = (values, { uploadGradingReceiptFile: { gradingReceipt } }) => {
  const errors = {};

  if (gradingReceipt) {
    if (gradingReceipt.size > FILES_CONSTANTS.MAX_FILE_SIZE) {
      errors.gradingReceipt = LOTS.ERRORS.INVALID_SIZE;
    }

    if (!PDF_REGEX.test(gradingReceipt.name)) {
      errors.gradingReceipt = LOTS.ERRORS.INVALID_FILE_FORMAT;
    }

    if (!VALIDATE_UTF_8().test(gradingReceipt.name)) {
      errors.gradingReceipt = LOTS.ERRORS.INVALID_FILE_NAME;
    }
  }

  return errors;
};

export { validate };
