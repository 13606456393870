import { statusesEnum } from '../constants/filters/allStatuses';
import { isAssist } from '../matchers/checkAccessLevel';
import { isOwnerUser } from '../matchers/checkUserType';
import { createUserTypes } from './config/createUserTypes';

const userTypeAccessHandler = (userTypeToManage, userType, accessLevel) =>
  createUserTypes[userType][accessLevel] &&
  createUserTypes[userType][accessLevel].includes(userTypeToManage);

const inactivateUserAuth = (auth, user) => {
  const { access, user: loggedUser } = auth;

  return (
    access.users.inactivate &&
    !(isAssist(loggedUser.accessLevel) && isOwnerUser(user.userType)) &&
    user.status !== statusesEnum.inactive &&
    !(user._id === loggedUser.id)
  );
};

export { userTypeAccessHandler, inactivateUserAuth };
