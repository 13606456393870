const statusFilterActionType = 'STATUS_FILTER_ACTION';
const statusFilter = status => ({
  type: statusFilterActionType,
  payload: {
    status,
  },
});

const relatedCompanyFilterActionType = 'RELATED_COMPANY_FILTER_ACTION';
const relatedCompanyFilter = relatedCompany => ({
  type: relatedCompanyFilterActionType,
  payload: {
    relatedCompany,
  },
});

const deliveryDateFromFilterActionType = 'DELIVERY_DATE_FROM_FILTER_ACTION';
const deliveryDateFromFilter = deliveryDateFrom => ({
  type: deliveryDateFromFilterActionType,
  payload: {
    deliveryDateFrom,
  },
});

const deliveryDateToFilterActionType = 'DELIVERY_DATE_TO_FILTER_ACTION';
const deliveryDateToFilter = deliveryDateTo => ({
  type: deliveryDateToFilterActionType,
  payload: {
    deliveryDateTo,
  },
});

const selfDeliveryRequestsFiltersClearActionType = 'SELF_DELIVERY_REQUESTS_FILTERS_CLEAR_ACTION';
const selfDeliveryRequestsFiltersClear = () => ({
  type: selfDeliveryRequestsFiltersClearActionType,
});

export {
  statusFilterActionType,
  relatedCompanyFilterActionType,
  deliveryDateFromFilterActionType,
  deliveryDateToFilterActionType,
  selfDeliveryRequestsFiltersClearActionType,
  statusFilter,
  relatedCompanyFilter,
  deliveryDateFromFilter,
  deliveryDateToFilter,
  selfDeliveryRequestsFiltersClear,
};
