import { hedgeStatus } from 'shared/helpers/constants/hedges/hedgeStatus';
import { hedgeTypes } from 'shared/helpers/constants/hedges/hedgeType';
import { statusesForGlobal } from 'shared/helpers/constants/lots/statusesForGlobal';

const availableHedgeStatuses = [
  hedgeStatus.placed,
  hedgeStatus.partly_filled,
  hedgeStatus.filled,
  hedgeStatus.reassigned,
];

const availableHedgeType = [hedgeTypes.custom, hedgeTypes.standard, hedgeTypes.internal];

export const updateOuncesAccess = (auth, { status, hedgeType, relatedLot }) => {
  const { changeHedgeOunces } = auth.access.specialPermissions.hedges;

  return (
    changeHedgeOunces &&
    availableHedgeStatuses.includes(status) &&
    availableHedgeType.includes(hedgeType) &&
    relatedLot?.statusForGlobal !== statusesForGlobal.finalized
  );
};
