import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Field } from 'redux-form';

import { IconButton } from 'shared/components/Buttons/IconButton/IconButton';
import { FieldInput, FieldSelect, FieldHint } from 'shared/components/Fields';
import { Section } from 'shared/components/Form';
import { MultipleFieldsSection } from 'shared/components/Form/MultipleFieldsSection/MultipleFieldsSection';
import { DELIVERIES } from 'shared/helpers/constants/deliveries/deliveriesConstants';
import { isTablet } from 'shared/helpers/constants/resolutionsConstants';
import { SHARED } from 'shared/helpers/constants/sharedConstants';
import { dateFormat } from 'shared/helpers/parsers/date';
import { safeParseFloat, roundUp } from 'shared/helpers/parsers/number';
import { replaceEmpty } from 'shared/helpers/parsers/text';
import { cssVar } from 'shared/helpers/styling/styling';
import { useWindowWidth } from 'shared/hooks/useWindowWidth';

import dateAdapter from 'utils/date/dateAdapter';

import { BlanketHedgeHistory } from '../BlanketHedgeHistory/BlanketHedgeHistory';

const ApplyBlanketHedges = ({
  values,
  previousRemainingOunces,
  onRemove,
  change,
  blur,
  hedges,
  delivery,
  previousLotsRemainingOunces,
  formValues,
  availableOunces,
}) => {
  const windowWidth = useWindowWidth();
  const {
    applyBlanketHedges: { relatedLot, deliveries },
  } = formValues;

  const getAvailableHedges = (deliveries, hedges) =>
    (hedges || []).filter(
      hedge =>
        deliveries.map(delivery => delivery?.relatedHedge?.value).indexOf(hedge._id) < 0 &&
        hedge.relatedCompany === relatedLot.sentFromCompany._id,
    );

  const availableHedges = getAvailableHedges(deliveries || [], hedges);
  const available = availableOunces?.available?.[relatedLot?.value];

  const actions = onRemove && (
    <IconButton onClick={onRemove} icon="icon-cancel" color={cssVar('whiteGRG')} />
  );

  const parseHedgesOptions = () =>
    availableHedges.map(hedge => ({
      value: hedge._id,
      label: `${hedge.hedgeName} ${DELIVERIES.PLACED_ON}: ${dateAdapter(hedge.placedAt).format(
        dateFormat,
      )}`,
    }));

  const formatMaximum = (currentValue, previousValue) => +roundUp(currentValue - previousValue, 3);

  const selectedHedge = values.relatedHedge;
  const selectedHedgeId = selectedHedge && selectedHedge.value;
  const relatedHedge = hedges?.find(hedge => hedge._id === selectedHedgeId) || {};

  const ouncesAvailableField = (label, name) => ({
    component: FieldInput,
    name,
    label,
    field: 'required',
    suffix: DELIVERIES.OZ,
    disabled: true,
    'data-testid': label,
  });

  const enterOuncesField = (label, name, suggestion) => {
    if (selectedHedgeId && suggestion <= 0) {
      change(`applyBlanketHedges.${name}`, 0);
    }

    return {
      label,
      name,
      component: FieldHint,
      type: 'number',
      field: 'required',
      suffix: DELIVERIES.OZ,
      hint: SHARED.MAXIMUM,
      suggestion,
      disabled: !selectedHedgeId || suggestion <= 0,
    };
  };

  const lotAvailable = [
    ouncesAvailableField(DELIVERIES.PLATINUM_LEFT, `${delivery}.availablePlatinumOz`),
    ouncesAvailableField(DELIVERIES.PALLADIUM_LEFT, `${delivery}.availablePalladiumOz`),
    ouncesAvailableField(DELIVERIES.RHODIUM_LEFT, `${delivery}.availableRhodiumOz`),
  ];

  const getRemainingLotOunces = ounces =>
    +roundUp(
      `${safeParseFloat(available?.[ounces] || 0)} -
      ${safeParseFloat(previousLotsRemainingOunces[ounces] || 0)} `,
      3,
    );

  useEffect(() => {
    change(
      `applyBlanketHedges.${delivery}.availablePlatinumOz`,
      replaceEmpty(getRemainingLotOunces('platinumOz')),
    );
    change(
      `applyBlanketHedges.${delivery}.availablePalladiumOz`,
      replaceEmpty(getRemainingLotOunces('palladiumOz')),
    );
    change(
      `applyBlanketHedges.${delivery}.availableRhodiumOz`,
      replaceEmpty(getRemainingLotOunces('rhodiumOz')),
    );
  }, [available, delivery, formValues]);

  const formatSuggestion = ounces => {
    const availableOunces = available?.[ounces];

    const remainingLotOunces = getRemainingLotOunces(ounces);
    const formatted = formatMaximum(relatedHedge[ounces], previousRemainingOunces[ounces]);

    if (remainingLotOunces <= formatted) {
      return remainingLotOunces;
    }

    if ((!availableOunces && availableOunces !== 0) || availableOunces >= formatted) {
      return formatted;
    }

    return availableOunces;
  };

  const ouncesFields = [
    enterOuncesField(
      DELIVERIES.PLATINUM,
      `${delivery}.platinumOz`,
      selectedHedgeId && formatSuggestion('platinumOz'),
    ),
    enterOuncesField(
      DELIVERIES.PALLADIUM,
      `${delivery}.palladiumOz`,
      selectedHedgeId && formatSuggestion('palladiumOz'),
    ),
    enterOuncesField(
      DELIVERIES.RHODIUM,
      `${delivery}.rhodiumOz`,
      selectedHedgeId && formatSuggestion('rhodiumOz'),
    ),
  ];

  const fieldsMobile = lotAvailable.reduce(
    (prev, current, i) => [...prev, ...[current, ouncesFields[i]]],
    [],
  );

  return (
    <>
      <Section
        template={[`${delivery}.relatedHedge .`]}
        actions={actions}
        header={DELIVERIES.ASSIGNMENT}
      >
        <Field
          name={`${delivery}.relatedHedge`}
          component={FieldSelect}
          options={parseHedgesOptions()}
          onChange={() => {
            blur(`${delivery}.relatedHedge`, null);
          }}
          label={DELIVERIES.RELATED_HEDGE}
          field="required"
        />
      </Section>
      <BlanketHedgeHistory relatedHedge={relatedHedge} />
      {windowWidth >= isTablet ? (
        <Section
          header={DELIVERIES.LOT(relatedLot?.label)}
          template={['lotAvailable lotAvailable', 'ouncesFields ouncesFields']}
          noPadding
        >
          <MultipleFieldsSection name="lotAvailable" fields={lotAvailable} />
          <MultipleFieldsSection name="ouncesFields" fields={ouncesFields} />
        </Section>
      ) : (
        <Section
          header={DELIVERIES.LOT(relatedLot?.label)}
          template={['fieldsMobile fieldsMobile']}
          noPadding
        >
          <MultipleFieldsSection name="fieldsMobile" fields={fieldsMobile} />
        </Section>
      )}
    </>
  );
};

ApplyBlanketHedges.propTypes = {
  availableOunces: PropTypes.shape({
    available: PropTypes.object,
    errorMessage: PropTypes.object,
    isPending: PropTypes.bool,
  }),
  blur: PropTypes.func,
  change: PropTypes.func,
  delivery: PropTypes.string,
  formValues: PropTypes.object,
  hedges: PropTypes.arrayOf(
    PropTypes.shape({
      hedgeName: PropTypes.string,
      platiniumOz: PropTypes.number,
      palladiumOz: PropTypes.number,
      rhodiumOz: PropTypes.number,
      placedAt: PropTypes.number,
      relatedCompany: PropTypes.string,
      _id: PropTypes.string,
    }),
  ),
  onRemove: PropTypes.func,
  previousLotsRemainingOunces: PropTypes.shape({
    platiniumOz: PropTypes.number,
    palladiumOz: PropTypes.number,
    rhodiumOz: PropTypes.number,
  }),
  previousRemainingOunces: PropTypes.shape({
    platiniumOz: PropTypes.number,
    palladiumOz: PropTypes.number,
    rhodiumOz: PropTypes.number,
  }),
  values: PropTypes.shape({
    availablePalladiumOz: PropTypes.string,
    availablePlatinumOz: PropTypes.string,
    availableRhodiumOz: PropTypes.string,
    palladiumOz: PropTypes.number,
    platinumOz: PropTypes.number,
    rhodiumOz: PropTypes.number,
    relatedHedge: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  }),
};

export { ApplyBlanketHedges };
