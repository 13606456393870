import Axios from "axios";
import { saveAs } from 'file-saver';

const API_HOST_AUTH = process.env.REACT_APP_API;

const printLotLabelActionType = 'PRINT_LOT_LABEL';
const printLotLabelAction = () => ({
  type: printLotLabelActionType,
});

const printLotLabelSuccessActionType = `${printLotLabelActionType}_SUCCESS`;
const printLotLabelSuccess = () => ({
  type: printLotLabelSuccessActionType,
});

const printLotLabelFailureActionType = `${printLotLabelActionType}_FAILURE`;
const printLotLabelFailure = () => ({
  type: printLotLabelFailureActionType,
});

const printLotLabel = path => {
  return dispatch => {
    dispatch(printLotLabelAction());
    return Axios({
      method: 'get',
      url: `${API_HOST_AUTH}${path}`,
      responseType: 'arraybuffer',
    })
      .then(res => {
        saveAs(new Blob([res.data], { type: res.headers['content-type'] }), 'label.pdf');

        dispatch(printLotLabelSuccess());
      })
      .catch(() => {
        dispatch(printLotLabelFailure());
      });
  };
}

export {
  printLotLabel,
  printLotLabelSuccess,
  printLotLabelFailure,
  printLotLabelActionType,
  printLotLabelSuccessActionType,
  printLotLabelFailureActionType,
};
