import Cookie from 'js-cookie';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { outgoingLotsOverviewClearAction } from 'actions/Lots/outgoingLotsOverview';
import { logout } from 'actions/shared/auth';

import { LogoutButton } from 'pages/Logout/components/LogoutButtons/LogoutButton';

import { StyledLogoutComponent, StyledLogoutWrapper } from './StyledLogout';
import { LogoutContent } from './components/LogoutContent/LogoutContent';

export const Logout = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    Cookie.remove('grgUserSession');
    dispatch(outgoingLotsOverviewClearAction());
    dispatch(logout());
  }, []);

  return (
    <StyledLogoutWrapper>
      <StyledLogoutComponent>
        <LogoutContent />
        <LogoutButton />
      </StyledLogoutComponent>
    </StyledLogoutWrapper>
  );
};
