import React from 'react';

import { PERMISSIONS } from 'shared/helpers/constants/systemSettings/permissions/constants';
import { parseLogInfo } from 'shared/helpers/dataParsers/specialPermissionsLogs/parseLogInfo';

import { LogsAction } from '../LogsAction/LogsAction';
import { LogsDetails } from '../LogsDetails/LogsDetails';

const itemsTemplate = [
  {
    label: PERMISSIONS.ACTION,
    render: logData => (
      <LogsAction
        type={logData.type}
        actionType={logData.actionType}
        before={logData.before}
        after={logData.after}
        editedObject={logData.editedObject}
      />
    ),
  },
  {
    label: PERMISSIONS.DETAILS,
    render: ({ updatedAt, updatedBy }) => (
      <LogsDetails logInfo={parseLogInfo({ updatedAt, updatedBy })} />
    ),
  },
];

const itemsDetailsTemplate = [
  {
    label: PERMISSIONS.DETAILS,
    render: ({ updatedAt, updatedBy }) => (
      <LogsDetails logInfo={parseLogInfo({ updatedAt, updatedBy })} />
    ),
  },
];

const itemsTemplateMobile = [
  {
    label: PERMISSIONS.ACTION,
    render: logData => (
      <LogsAction
        type={logData.type}
        actionType={logData.actionType}
        before={logData.before}
        after={logData.after}
        editedObject={logData.editedObject}
      />
    ),
  },
];

export { itemsTemplate, itemsDetailsTemplate, itemsTemplateMobile };
