import PropTypes from 'prop-types';
import React from 'react';

import { Icon } from 'shared/components/Icon/Icon';

import { Button } from '../Button';

const SubmitButton = ({ label, children, icon, ...props }) => (
  <Button {...props} type="submit" padding="0 10px 0 23px">
    {label || children}
    <Icon icon={icon || 'icon-arrow-right'} />
  </Button>
);

SubmitButton.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.string,
};

export { SubmitButton };
