import styled from 'styled-components';

export const StyledTokenAuthNotValid = styled.div.attrs(({ className }) => ({
  className,
}))`
  border: 1px solid var(--solidRed);
  color: var(--solidRed);
  padding: 15px;
  border-radius: 5px;
  font-size: 1.2em;
`;
