import {
  printPackageLabelsActionType,
  printPackageLabelsSuccessActionType,
  printPackageLabelsFailureActionType,
} from 'actions/Packages/printPackageLabels';
import {
  generateShipmentDocumentsActionType,
  generateShipmentDocumentsSuccessActionType,
  generateShipmentDocumentsFailureActionType,
} from 'actions/Shipments/generateShipmentDocuments';
import {
  generateShipmentReportActionType,
  generateShipmentReportFailureActionType,
  generateShipmentReportSuccessActionType,
} from 'actions/Shipments/generateShipmentReport';
import {
  getShipmentDetailsActionType,
  getShipmentDetailsSuccessActionType,
  getShipmentDetailsFailureActionType,
  clearShipmentDetailsActionType,
} from 'actions/Shipments/shipmentDetails';

import { packageStatuses } from 'shared/helpers/constants/packages/packageType';
import { setPlaceholder } from 'shared/helpers/dataParsers/packages/setPlaceholder';
import { isAssayCompany } from 'shared/helpers/matchers/checkCompanyType';

const INITIAL_STATE = {
  isPending: false,
  errorMessage: null,
  shipment: null,
};

const ShipmentDetailsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getShipmentDetailsActionType:
      return {
        shipment: null,
        isPending: true,
      };
    case getShipmentDetailsSuccessActionType:
      const { shipment } = action.payload;

      return {
        ...state,
        isPending: false,
        shipment: {
          ...shipment,
          ...(isAssayCompany(shipment.sentFromCompany.companyType) && {
            packingListPackages: setPlaceholder(
              shipment.packages,
              shipment.assignedLot,
              packageStatuses.deleted,
            ).sort((pkg1, pkg2) => pkg1.packageNumber - pkg2.packageNumber),
          }),
        },
      };
    case getShipmentDetailsFailureActionType:
      return {
        ...state,
        isPending: false,
        errorMessage: action.payload.errorMessage,
      };
    case clearShipmentDetailsActionType:
      return {
        isPending: false,
        errorMessage: null,
        shipment: null,
      };
    case generateShipmentDocumentsSuccessActionType:
      return {
        ...state,
        isPending: false,
        ...(state.shipment && {
          shipment: {
            ...state.shipment,
            shipmentBillOfLading: action.payload.shipment.shipmentBillOfLading,
            shipmentPackingList: action.payload.shipment.shipmentPackingList,
          },
        }),
      };
    case generateShipmentDocumentsActionType:
    case generateShipmentReportActionType:
    case printPackageLabelsActionType:
      return {
        ...state,
        isPending: true,
      };
    case generateShipmentDocumentsFailureActionType:
    case generateShipmentReportSuccessActionType:
    case generateShipmentReportFailureActionType:
    case printPackageLabelsSuccessActionType:
    case printPackageLabelsFailureActionType:
      return {
        ...state,
        isPending: false,
      };
    default:
      return state;
  }
};

export { ShipmentDetailsReducer };
