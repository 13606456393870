import styled, { css } from 'styled-components';

import { Icon } from 'shared/components/Icon/Icon';
import { SearchComponent } from 'shared/components/Search/Search';
import { cssVar, pxToRem, media } from 'shared/helpers/styling/styling';

export const StyledFullPageListFilters = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ searchEnabled }) => (searchEnabled ? 'space-between' : 'flex-end')};
  flex-wrap: wrap;
  margin-top: ${pxToRem(10)};
  padding: ${pxToRem(10)} 0;
  background-color: ${cssVar('ebonyClay')};

  ${media.phone`
  margin-top: 0px;
   flex-direction: row;
   align-items: flex-end;

   ${SearchComponent} {
    margin: 5px 10px 0 0;
   }

   ${Icon} {
      margin-left: .5rem;
      margin-right: .5rem;
   }
  `}

  ${({ searchEnabled, filtersEnabled }) =>
    !searchEnabled &&
    !filtersEnabled &&
    css`
      display: none;
    `}

  @media print {
    display: none;
  }
`;
