import PropTypes from 'prop-types';

import { renderDetailsContainer } from 'shared/components/Details/helpers/renderDetailsContainer';
import {
  itemsDetailsTemplateBase,
  itemsTemplateBuyer,
  itemsTemplateMobile,
} from 'shared/helpers/constants/hedges/overviewTableConfig';

import { HedgesOverview } from 'pages/Lots/components/LotDetails/components/components';
import { BuyerPackagesOverview } from 'pages/Lots/components/LotDetails/components/components/PackagesOverview/BuyerPackagesOverview/BuyerPackagesOverview';

import { MainLotDetails } from '../MainDetails/MainDetails';
import { lotDetailsContainerConfig, lotDetailsFieldsConfig } from './config/fieldsConfig';

export const LotDetailsBuyer = ({ auth, lot, packagesOverviewList, hedgesOverviewList }) => {
  return (
    <>
      <MainLotDetails lot={lot} auth={auth} packagesList={packagesOverviewList.packagesList} />
      {renderDetailsContainer(lotDetailsContainerConfig, lotDetailsFieldsConfig, lot)}
      <BuyerPackagesOverview packagesOverviewList={packagesOverviewList} auth={auth} />
      <HedgesOverview
        hedgesOverviewList={hedgesOverviewList}
        itemsTemplate={itemsTemplateBuyer}
        actionsTemplate={[]}
        itemsTemplateMobile={itemsTemplateMobile}
        itemDetailsTemplate={itemsDetailsTemplateBase}
      />
    </>
  );
};

LotDetailsBuyer.propTypes = {
  auth: PropTypes.object,
  packagesOverviewList: PropTypes.object,
  lot: PropTypes.object,
  hedgesOverviewList: PropTypes.object,
};
