import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { clearShipmentsCalendar, getShipmentsCalendar } from 'actions/Shipments/shipmentsCalendar';

import { ShipmentsCalendarLayout } from './ShipmentsCalendar';

const mapStateToProps = ({ auth, shipmentsCalendar }) => ({
  auth,
  shipmentsCalendar,
});

const mapDispatchToProps = {
  clearShipmentsCalendar,
  getShipmentsCalendar,
};

const ShipmentsCalendar = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ShipmentsCalendarLayout),
);

export { ShipmentsCalendar };
