import { messageTypes } from 'shared/helpers/constants/snackbar/snackbarMessageTypes';
import {
  dangerOptions,
  successOptions,
  infoOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

function snackbarOptions(messageType) {
  if (messageType === messageTypes.success) {
    return successOptions;
  }
  if (messageType === messageTypes.error) {
    return dangerOptions;
  }
  if (messageType === messageTypes.info) {
    return infoOptions;
  }
  return dangerOptions;
}

export { snackbarOptions };
