import axios from 'axios';

import { history } from 'utils/history';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getUserActionType = 'GET_USER';
function getUserAction() {
  return {
    type: getUserActionType,
  };
}

const getUserSuccessActionType = 'GET_USER_SUCCESS';
function getUserSuccessAction(user) {
  return {
    type: getUserSuccessActionType,
    payload: {
      user,
    },
  };
}

const getUserFailureActionType = 'GET_USER_FAILURE';
function getUserFailureAction(errorMessage) {
  return {
    type: getUserFailureActionType,
    payload: {
      errorMessage,
    },
  };
}

const clearUserDetailsActionType = 'CLEAR_USER_DETAILS';
function clearUserDetails() {
  return {
    type: clearUserDetailsActionType,
  };
}

function getUser(id) {
  return dispatch => {
    dispatch(getUserAction());

    return axios
      .get(`${API_HOST_AUTH}/users/${id}`)
      .then(res => {
        dispatch(getUserSuccessAction(res.data));
        return res.data;
      })
      .catch(error => {
        history.push('/users');
        dispatch(getUserFailureAction(error));

        return Promise.reject(error.data);
      });
  };
}

export {
  getUserActionType,
  getUserSuccessActionType,
  getUserFailureActionType,
  clearUserDetailsActionType,
  getUser,
  clearUserDetails,
};
