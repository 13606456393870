import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';

import { getPriceFeeds as getPriceFeedsAction } from 'actions/PriceFeeds/getPriceFeeds';

import { OverviewListWrapper } from 'shared/components/OverviewListWrapper/OverviewListWrapper';
import { SHARED } from 'shared/helpers/constants/sharedConstants';
import { PRICE_FEEDS } from 'shared/helpers/constants/systemSettings/priceFeeds/constants';
import { formatToDollarWithPrefix } from 'shared/helpers/parsers/formaters';
import { humanize, replaceEmpty } from 'shared/helpers/parsers/text';

import dateAdapter from 'utils/date/dateAdapter';
import { history } from 'utils/history';

const templates = {
  metal: {
    label: PRICE_FEEDS.METAL,
    render: ({ metal }) => humanize(metal),
  },
  source: {
    label: PRICE_FEEDS.SOURCE,
    render: ({ source }) => humanize(source),
  },
  adjustment: {
    label: PRICE_FEEDS.ADJUSTMENT,
    render: ({ adjustment }) => replaceEmpty(formatToDollarWithPrefix(adjustment), SHARED.NA),
  },
  manualPrice: {
    label: PRICE_FEEDS.MANUAL_PRICE,
    render: ({ manualPrice }) => replaceEmpty(formatToDollarWithPrefix(manualPrice), SHARED.NA),
  },
  currentPrice: {
    label: PRICE_FEEDS.SYSTEM_PRICE,
    render: ({ currentPrice }) => formatToDollarWithPrefix(currentPrice),
  },
  updatedBy: {
    label: PRICE_FEEDS.UPDATED_BY,
    render: ({ updatedBy }) =>
      replaceEmpty(updatedBy && `${updatedBy.firstName} ${updatedBy.lastName}`),
  },
  updatedAt: {
    label: PRICE_FEEDS.UPDATED_AT,
    render: ({ updatedAt }) => replaceEmpty(updatedAt && dateAdapter(updatedAt).format()),
  },
};

const itemsTemplate = [
  templates.metal,
  templates.source,
  templates.adjustment,
  templates.manualPrice,
  templates.currentPrice,
  templates.updatedBy,
  templates.updatedAt,
];

const itemsTemplateMobile = [templates.metal, templates.currentPrice];

const itemDetailsTemplate = [
  templates.source,
  templates.adjustment,
  templates.manualPrice,
  templates.updatedAt,
  templates.updatedBy,
];

const actionsItemTemplate = [
  {
    icon: 'edit',
    action: SHARED.EDIT,
    onClick:
      ({ _id }) =>
      () =>
        _id && history.push(`settings/price-feeds/${_id}/update`),
  },
];

actionsItemTemplate.access = function access() {
  return this.access.systemSettings.priceFeeds.update;
};

function PriceFeedsBase({ auth, priceFeeds, getPriceFeeds }) {
  useEffect(() => {
    getPriceFeeds(3, 1);
  }, [getPriceFeeds]);

  const normalizedPriceFeeds = useMemo(
    () => ({
      items: {
        docs: priceFeeds,
        total: (priceFeeds || []).length,
      },
    }),
    [priceFeeds],
  );

  return (
    <OverviewListWrapper
      list={normalizedPriceFeeds}
      getList={getPriceFeeds}
      itemsTemplate={itemsTemplate}
      itemsTemplateMobile={itemsTemplateMobile}
      itemDetailsTemplate={itemDetailsTemplate}
      actionsTemplate={actionsItemTemplate}
      actionsContext={auth}
      label={PRICE_FEEDS.PRICE_FEEDS}
      dashboard
    />
  );
}

const mapStateToProps = ({ auth, getPriceFeeds }) => ({
  auth,
  priceFeeds: getPriceFeeds.priceFeeds,
});

const mapDispatchToProps = {
  getPriceFeeds: getPriceFeedsAction,
};

const PriceFeeds = connect(mapStateToProps, mapDispatchToProps)(PriceFeedsBase);

export { PriceFeedsBase, PriceFeeds };
