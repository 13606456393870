const UPDATE_PRICE_FEED = {
  SOURCE_EMPTY: 'Source cannot be empty.',
  ADJUSTMENT_EMPTY: 'Adjustment cannot be empty.',
  ADJUSTMENT_FORMAT: 'Adjustment has invalid format.',
  MANUAL_PRICE_EMPTY: 'Manual price cannot be empty.',
  MANUAL_PRICE_NEGATIVE: 'Manual price cannot be less than 0.',
  MANUAL_PRICE_FORMAT: 'Manual price has invalid format.',
};

export { UPDATE_PRICE_FEED };
