import axios from 'axios';

import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';
import { messageTypes } from 'shared/helpers/constants/snackbar/snackbarMessageTypes';
import { parseLotData } from 'shared/helpers/dataParsers/lot/parseLotData';

const API_HOST_AUTH = process.env.REACT_APP_API;

const createLotActionType = 'CREATE_LOT';
function createLotAction() {
  return {
    type: createLotActionType,
  };
}

const createLotSuccessActionType = 'CREATE_LOT_SUCCESS';
function createLotSuccessAction() {
  return {
    type: createLotSuccessActionType,
  };
}

const createLotFailureActionType = 'CREATE_LOT_FAILURE';
function createLotFailureAction() {
  return {
    type: createLotFailureActionType,
  };
}

function createLot(lot, files, isGrgUser) {
  return dispatch => {
    dispatch(createLotAction());

    return axios({
      method: 'post',
      url: `${API_HOST_AUTH}/lots`,
      headers: { 'Content-Type': 'multipart/form-data' },
      data: parseLotData(lot, files, isGrgUser),
    })
      .then(response => {
        dispatch(createLotSuccessAction());

        return {
          id: response.data.id,
          grgLotNumber: response.data.lot.grgLotNumber,
          customLotNumber: response.data.lot.customLotNumber,
          statusForGlobal: response.data.lot.statusForGlobal,
          statusForCustomer: response.data.lot.statusForCustomer,
          message: response.data.message,
          messageType: messageTypes.success,
        };
      })
      .catch(error => {
        dispatch(createLotFailureAction());
        return {
          message: `${LOTS.ERRORS.CREATE_LOT_ERROR} ${error.data.message}`,
          messageType: messageTypes.error,
        };
      });
  };
}

export {
  createLotActionType,
  createLotAction,
  createLotSuccessActionType,
  createLotSuccessAction,
  createLotFailureActionType,
  createLotFailureAction,
  createLot,
};
