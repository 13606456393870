import React, { useCallback } from 'react';
import { connect } from 'react-redux';

import { requestMarketIndicators as requestMarketIndicatorsAction } from 'actions/MarketIndicators/requestMarketIndicators';
import { showSnackbar as showSnackbarAction } from 'actions/shared/snackbar';

import { ModalCustom } from 'components/ModalCustom/ModalCustom';

import {
  successOptions,
  dangerOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

import { RequestMarketIndicatorsForm } from './components/RequestMarketIndicatorsForm/RequestMarketIndicatorsForm';

function RequestMarketIndicatorsBase({ isOpen, toggle, requestMarketIndicators, showSnackbar }) {
  const onSubmit = useCallback(
    ({ type }) =>
      requestMarketIndicators(type && type.map(({ value }) => value))
        .then(({ message }) => {
          showSnackbar(successOptions, message);
          toggle();
        })
        .catch(({ message }) => showSnackbar(dangerOptions, message)),
    [requestMarketIndicators, showSnackbar, toggle],
  );

  return (
    <ModalCustom isOpen={isOpen} modalClose={toggle}>
      <RequestMarketIndicatorsForm onSubmit={onSubmit} />
    </ModalCustom>
  );
}

const mapDispatchToProps = {
  requestMarketIndicators: requestMarketIndicatorsAction,
  showSnackbar: showSnackbarAction,
};

const RequestMarketIndicators = connect(null, mapDispatchToProps)(RequestMarketIndicatorsBase);

export { RequestMarketIndicatorsBase, RequestMarketIndicators };
