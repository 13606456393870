function normalize(value) {
  if (typeof value === 'string') {
    if (value === 'true') {
      return true;
    }

    if (value === 'false') {
      return false;
    }
  }

  return false;
}

function normalizeCheckbox(value) {
  return !!value;
}

export { normalize, normalizeCheckbox };
