import {
  markAsResolvedActionType,
  markAsResolvedSuccessActionType,
  markAsResolvedFailureActionType,
} from 'actions/Notifications/markAsResolved';

const INITIAL_STATE = {
  isPending: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case markAsResolvedActionType:
      return {
        isPending: true,
      };
    case markAsResolvedFailureActionType:
    case markAsResolvedSuccessActionType:
      return {
        isPending: false,
      };
    default:
      return state;
  }
};
