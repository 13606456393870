import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { createNote } from 'actions/Notes/createNote';
import { openModal } from 'actions/shared/modal';
import { showSnackbar } from 'actions/shared/snackbar';

import { NOTE } from 'shared/helpers/constants/noteConstants';

import { CreateNote } from './CreateNote';

const mapStateToProps = state => ({
  createNoteStorage: state.createNoteStorage,
});

const mapDispatchToProps = {
  showSnackbar,
  createNote,
  openModal,
};

const ConnectedCreateNote = withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateNote));

const ConnectedCreateCompaniesNote = () => (
  <ConnectedCreateNote relatedType={NOTE.NOTES_TYPE.COMPANIES} />
);

const ConnectedCreateDueDiligenceNote = () => (
  <ConnectedCreateNote relatedType={NOTE.NOTES_TYPE.DILIGENCE} />
);

const ConnectedCreateUsersNote = () => <ConnectedCreateNote relatedType={NOTE.NOTES_TYPE.USERS} />;

const ConnectedCreateLotsNote = () => <ConnectedCreateNote relatedType={NOTE.NOTES_TYPE.LOTS} />;

const ConnectedCreateHedgesNote = () => (
  <ConnectedCreateNote relatedType={NOTE.NOTES_TYPE.HEDGES} />
);

export {
  ConnectedCreateCompaniesNote,
  ConnectedCreateUsersNote,
  ConnectedCreateLotsNote,
  ConnectedCreateHedgesNote,
  ConnectedCreateDueDiligenceNote,
};
