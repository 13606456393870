import { fieldPrefix } from "../versioning/fieldPrefixes";
import { materialTypeForAssay } from 'shared/helpers/constants/packages/materialType';

const setMsgMaterialType = defaultMatetrialType => {
  switch (defaultMatetrialType) {
    case materialTypeForAssay.DPF_canned:
    case materialTypeForAssay.DPF_decanned:
      return PACKAGES.DPF;
    case materialTypeForAssay.sensors:
      return PACKAGES.SENSOR;
    case materialTypeForAssay.foil_canned:
    case materialTypeForAssay.foil_decanned:
      return PACKAGES.FOIL;
    default:
      return;
  }
};

const PACKAGES = {
  LABEL_ID: 'Label ID',
  PLANT: 'Plant #',
  GENERAL_DETAILS: 'General details',
  PACKAGES: 'Packages',
  PACKAGE: 'Package',
  PACKAGE_COLON: 'Package:',
  PACKAGE_COLON_LOWER_CASE: 'package:',
  FROM_COMPANY: 'From',
  PACKAGE_TYPE: 'Package type',
  MATERIAL_TYPE_DECLARED: 'Material type declared',
  DECLARED_MATERIAL_TYPE: 'Declared material type',
  MATERIAL_CLASS: 'Material class',
  UNIT_COUNT: 'Unit count',
  DECLARED_GROSS_WEIGHT: 'Declared gross weight',
  WEIGHT_GROSS_DECLARED: 'Weight gross declared',
  DECLARED_TARE_WEIGHT: 'Declared tare weight',
  WEIGHT_TARE_DECLARED: 'Weight tare declared',
  WEIGHT_NET_DECLARED: 'Weight net declared',
  CREATE_A_PACKAGE: 'Create a package',
  CREATE_AN_INCOMING_PACKAGE: 'Create an incoming package',
  CREATE_INCOMING_PACKAGES: 'Create incoming packages',
  CREATE_POST_MILL_PACKAGES: 'Create post-mill packages',
  CREATE_POST_MIX_PACKAGES: 'Create post-mix packages',
  CREATE_POST_SHEARS_PACKAGES: 'Create post-shears packages',
  CREATE_PACKING_LIST_PACKAGES: 'Create packing list',
  UPDATE_AN_INCOMING_PACKAGE: 'Update an incoming package',
  INACTIVATE_PACKAGES: 'Inactivate packages',
  ASSIGNED_LOT: 'Assigned lot',
  PROCESS_VIA: 'Process via',
  MIX_VIA: 'Mix via',
  DENOMINATED_IN: 'Denominated in',
  WEIGHT_GROSS_ACTUAL: 'Weight gross actual',
  WEIGHT_TARE_ACTUAL: 'Weight tare actual',
  WEIGHT_NET_ACTUAL: 'Weight net actual',
  GROSS_DECLARED: 'Gross declared',
  TARE_DECLARED: 'Tare declared',
  NET_DECLARED: 'Net declared',
  GROSS_ACTUAL: 'Gross actual',
  TARE_ACTUAL: 'Tare actual',
  NET_ACTUAL: 'Net actual',
  CUSTOMER_INSTRUCTIONS_MESSAGE:
    'Since the material type chosen is "Other", please specify the contents of the package in the Customer Instructions field.',
  CUSTOMER_INSTRUCTIONS: 'Customer instructions',
  PKG_NUMBER: 'Pkg number',
  PACKAGE_NUMBER: 'Package number',
  GRG_LOT_NUMBER: `${fieldPrefix} lot number`,
  SHORTENED_GRG_LOT_NUMBER: 'Lot number',
  SELECTED_COLON: 'Selected: ',
  SELECT: 'Select',
  STATUS: 'Status',
  ACTIONS: 'Actions',
  CONTENTS: 'Contents',
  CONTENTS_DECLARED: 'Contents declared',
  RELEASED: 'Released',
  GROSS_LBS: 'Gross (lbs)',
  LBS: 'lbs',
  UNITS: 'units',
  UPDATE_PACKAGE: 'Update package',
  CREATE_PACKAGE_ERROR: 'Package has not been created.',
  CREATE_PACKAGES_ERROR: 'Packages have not been created.',
  UPDATE_PACKAGE_ERROR: 'Package has not been updated.',
  PRINT_PACKAGE_LABELS_SUCCESS: 'Package labels have been generated and are being downloaded.',
  PRINT_PACKAGE_LABELS_ERROR: 'Package labels have not been generated correctly.',
  PRINT_PACKAGE_LABEL_SUCCESS: 'Package label has been generated and is being downloaded.',
  PRINT_PACKAGE_LABEL_ERROR: 'Package label has not been generated correctly.',
  PRINT_LAB_REQUEST_FORMS_SUCCESS:
    'Laboratory request forms have been generated and are being downloaded.',
  PRINT_LAB_REQUEST_FORMS_ERROR: 'Laboratory request forms have not been generated correctly.',
  PRINT_ALL_LAB_REQUEST_FORMS: 'Print all forms',
  PRINT_SELECTED_LAB_REQUEST_FORMS: 'Print selected forms',
  PRINT_LAB_REQUEST_FORMS: 'Print lab request form(s)',
  PRINT_LAB_POLICY: 'Print indiv. assay lab policy',
  CREATE_ANOTHER_PACKAGE: 'Create another package',
  PRINT_LABEL: 'Print label',
  PRINT_LABEL_ZEBRA: 'Print label (zebra)',
  PRINT_LABEL_EBS: 'Print label (EBS)',
  PRINT_CUSTOMER_INSTRUCTIONS: 'Print customer instructions',
  PRINT_PACKAGE_LABELS: 'Print package labels',
  SELECT_PACKAGES: 'Select packages',
  PRINT_ALL_LABELS: 'Print all labels',
  PRINT_SELECTED_LABELS: 'Print selected labels',
  EDIT_PACKAGE: 'Edit package',
  DELETE_PACKAGE: 'Delete package',
  RECEIVED_BY: 'Received by',
  RELEASED_BY: 'Released by',
  CREATED_BY: 'Created by',
  UPDATE_WEIGHT: 'Update weight',
  INACTIVATE: 'Inactivate',
  REACTIVATE: 'Reactivate',
  ADMIN_UPDATE_WEIGHT: 'Update any package weight (admin only)',
  UPDATE_WEIGHTS: 'Update weights required',
  UPDATED_BY: 'Updated by',
  ASSIGNED_SHIPMENT: 'Assigned shipment',
  SHIPMENT: 'Shipment',
  PACKAGE_LABEL: 'Package label',
  WEIGHTS: 'Weights',
  ACTIVITY: 'Activity',
  SUCCESSFULLY_SCANNED: 'Package successfully scanned',
  SCANNING_ERROR: 'Invalid package barcode',
  SCAN_PACKAGE: 'Please scan package barcode',
  ENTER_PACKAGE_BARCODE: 'Enter package barcode',
  WEIGHTS_UNITS: 'Weights / Units',
  H_F: 'H / F',
  PACKAGES_QUANTITY: 'Number of packages',
  LOT_FOR_PACKAGES: 'Lot for packages',
  createTypedPackage(type) {
    return `Create a ${type} package`;
  },
  updateWeightTypedPackage(type) {
    return `Update a ${type} package weight`;
  },
  RELEASE_NEXT_PACKAGE: 'Release next package',
  RELEASE_PACKAGES_DONE: 'Done',
  LOTS_FOR_PACKAGES: {
    SAME_LOT: 'same_lot',
    MULTIPLE_LOTS: 'multiple_lots',
  },
  DEFAULT_VALUES: 'Default values',
  CREATE_PACKAGES: 'Create packages',
  NUMBER: 'Number',
  CUSTOM_LOT_NUMBER: 'Custom lot number',
  ADD_PACKAGES: 'Add package',
  REMOVE_PACKAGE: 'Remove',
  DPF: 'DPF',
  SENSOR: 'sensor',
  FOIL: 'foil',
  PRIMARY_ID: 'Converter identifier',
  SECONDARY_ID: 'Secondary identifier (if applicable)',
  CONTACT_NAME: 'Contact name',
  CONTACT_TYPE: 'Type',
  CONTACT_USER_NAME: 'First and last name',
  LABORATORY_POLICY: 'Laboratory Policy',
  PORTAL_USER_LABEL: 'Portal user',
  EXTERNAL_USER_LABEL: 'External user',
  PORTAL_USER_VALUE: 'portal',
  EXTERNAL_USER_VALUE: 'external',
  LABORATORY_INFO: companyName =>
    `I have read the individual assay <a href="/laboratoryPolicy.pdf" target="_blank" rel="noreferrer">laboratory policy</a> and acknowledge my responsibilities as they pertain to shipping individual assay converters and subsequent procedural guidelines followed by ${companyName}.`,
  DOUBLE_CHECK_MATERIAL_TYPE: materialType => {
    const finalMaterialType = setMsgMaterialType(materialType);

    return `This is a ${finalMaterialType} lot. Please double-check that you have chosen the correct material type before continuing.`;
  },
};

export { PACKAGES };
