import { termStatuses } from 'shared/helpers/constants/filters/termStatuses';

const checkStartDateTerm = (status, startDate) => {
  const dateNow = new Date().getTime();

  if (status === termStatuses.signed && dateNow < startDate) {
    return 'signed_upcoming';
  }

  return status;
};

export { checkStartDateTerm };
