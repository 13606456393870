const FILES_CONSTANTS = {
  ATTACHMENT: 'Attachment',
  ATTACHMENTS: 'Attachments',
  UPLOAD: 'Upload attachment',
  ERROR_SIZE: 'You cannot attach files with invalid format, name or larger than 20MB.',
  ERROR_LENGTH: limit => `You can only attach a maximum of ${limit} files.`,
  VALID_EXTENSIONS: {
    doc: '.doc',
    docx: '.docx',
    gif: '.gif',
    jpeg: '.jpeg',
    jpg: '.jpg',
    png: '.png',
    pdf: '.pdf',
    xls: '.xls',
    xlsx: '.xlsx',
    msg: '.msg',
    rtf: '.rtf',
  },
  UPLOADING_FILES: '...your files are being uploaded!',
  ADDING_NOTES: '...adding note in progress!',
  DOWNLOADING_FILES: '...your file is being downloaded!',
  ATTACHMENT_UPLOAD_ERROR: 'The attachment could not be uploaded.',
  ATTACHMENT_DOWNLOAD_ERROR: 'Attachment has not been downloaded.',
  ATTACHMENT_NOT_EXISTS: 'Attachment has been removed.',
  MAX_FILE_SIZE: 20971520,
  VALID_TYPES: [
    'image/jpeg',
    'image/png',
    'image/gif',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel',
    'application/pdf',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/msword',
    'application/vnd.ms-outlook',
    'application/octet-stream',
  ],
  FORWARD_DOCUMENT: 'Forward document',
  PORTAL_USERS: 'Portal user(s)',
  EXTERNAL_EMAILS: 'External email(s) (separated by ";")',
  FORWARD: 'Forward',
  INVALID_EMAILS: 'Invalid email(s)',
  LIMIT: 10,
};

export { FILES_CONSTANTS };
