import axios from 'axios';

import { parseEnterPaymentData } from 'shared/helpers/dataParsers/payments/parseEnterPaymentData';
import { parseEnterPoolPaymentData } from 'shared/helpers/dataParsers/payments/parseEnterPoolPaymentData';

const API_HOST_AUTH = process.env.REACT_APP_API;

const enterPaymentActionType = 'ENTER_PAYMENT';
const enterPaymentAction = () => ({
  type: enterPaymentActionType,
});

const enterPaymentSuccessActionType = `${enterPaymentActionType}_SUCCESS`;
const enterPaymentSuccessAction = () => ({
  type: enterPaymentSuccessActionType,
});

const enterPaymentFailureActionType = `${enterPaymentActionType}_FAILURE`;
const enterPaymentFailureAction = () => ({
  type: enterPaymentFailureActionType,
});

const enterPaymentBase = (url, dataParser) => data => dispatch => {
  dispatch(enterPaymentAction());

  return axios
    .post(`${API_HOST_AUTH}${url}`, dataParser(data))
    .then(res => {
      dispatch(enterPaymentSuccessAction());

      return {
        id: res.data.id,
        message: res.data.message,
      };
    })
    .catch(error => {
      dispatch(enterPaymentFailureAction());

      return Promise.reject({
        message: error.data.message,
      });
    });
};

const enterPayment = enterPaymentBase('/payments', parseEnterPaymentData);
const enterPoolPayment = enterPaymentBase('/payments/pool', parseEnterPoolPaymentData);

export {
  enterPaymentActionType,
  enterPaymentAction,
  enterPaymentSuccessActionType,
  enterPaymentSuccessAction,
  enterPaymentFailureActionType,
  enterPaymentFailureAction,
  enterPaymentBase,
  enterPayment,
  enterPoolPayment,
};
