import React, { Component } from 'react';

import { UIAuthorization } from 'components/Authorization/UIAuthorization';

import { RouterButton } from 'shared/components/Buttons';
import { ExtendableButton } from 'shared/components/Buttons/ExtendableButton/ExtendableButton';
import { Icon } from 'shared/components/Icon/Icon';
import { OverviewList } from 'shared/components/OverviewList/OverviewList';
import { companyDetailsListAccess } from 'shared/helpers/accesses/companyDetailsListAccess';
import { AGREEMENTS } from 'shared/helpers/constants/agreements/agreementsConstants';
import { companyStatuses } from 'shared/helpers/constants/companies/companiesFilters';
import { companyDetailsSections } from 'shared/helpers/constants/companies/companyDetailsSections';
import {
  agreementActionsTemplate,
  agreementItemMobileTemplate,
  agreementDetailsTableTemplate,
  agreementItemTableTemplate,
  locationActionsTemplate,
  locationItemMobileTemplate,
  locationDetailsTableTemplate,
  locationItemTableTemplate,
  termActionsTemplate,
  termItemMobileTemplate,
  termDetailsTableTemplate,
  termItemTableTemplate,
  userActionsTemplate,
  userItemMobileTemplate,
  userDetailsTableTemplate,
  userItemTableTemplate,
} from 'shared/helpers/constants/companies/companyDetailsTableConfig';
import { LOCATION } from 'shared/helpers/constants/locations/locationConstants';
import { isTablet } from 'shared/helpers/constants/resolutionsConstants';
import { TERMS } from 'shared/helpers/constants/terms/termsConstants';
import { USER } from 'shared/helpers/constants/users/userConstants';
import {
  isAssayCompany,
  isExternalCompany,
  isGradingCompany,
  isInternalCompany,
} from 'shared/helpers/matchers/checkCompanyType';

import { getBrowserWidth } from 'utils/responsive';

import { OverviewCompanySection } from '../OverviewCompanySection/OverviewCompanySection';

class CompanyDetailsLists extends Component {
  state = {
    windowWidth: getBrowserWidth(),
  };

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({
      windowWidth: getBrowserWidth(),
    });
  };

  showSection = (companyType, title) => {
    if (
      (isInternalCompany(companyType) || isExternalCompany(companyType)) &&
      (title === companyDetailsSections.term.title ||
        title === companyDetailsSections.agreement.title)
    ) {
      return false;
    }
    return !(
      (isGradingCompany(companyType) && title === companyDetailsSections.term.title) ||
      (isAssayCompany(companyType) && title === companyDetailsSections.agreement.title)
    );
  };

  checkAccess = (access, title) => {
    if (title === USER.USERS) {
      return companyDetailsListAccess(
        access,
        this.props.companyDetails.company.companyType,
        this.props.auth.user.userType,
      );
    }
    return access;
  };

  renderOverviewList(
    input,
    getList,
    label = null,
    itemsTemplate,
    itemsTemplateMobile,
    detailsTableTemplate,
    actionsItemTemplate,
  ) {
    const { windowWidth } = this.state;
    const { auth } = this.props;
    return (
      <OverviewList
        getList={getList}
        input={input}
        itemsTemplate={itemsTemplate}
        itemsTemplateMobile={itemsTemplateMobile}
        itemDetailsTemplate={detailsTableTemplate}
        actionsTemplate={actionsItemTemplate}
        isMobile={windowWidth < isTablet}
        auth={auth}
        label={label}
        actionsContext={this}
      />
    );
  }

  render() {
    const { company } = this.props.companyDetails;
    const {
      auth,
      companyDetailsLocationsList,
      companyDetailsGetLocations,
      companyDetailsAgreementsList,
      companyDetailsGetAgreements,
      companyDetailsUsersList,
      companyDetailsGetUsers,
      companyDetailsTermsList,
      companyDetailsGetTerms,
    } = this.props;

    const renderCollapseButton = (companyId, access) =>
      access && (
        <ExtendableButton label={TERMS.CREATE_TERM}>
          <RouterButton
            id="openCreateLots"
            path={`/companies/${companyId}/term/create_ceramic`}
            label={TERMS.CERAMIC}
          />
          <RouterButton
            id="openCreateLots"
            path={`/companies/${companyId}/term/create_foil`}
            label={TERMS.FOIL}
          />
          <RouterButton
            id="openCreateLots"
            path={`/companies/${companyId}/term/create_o2sensor`}
            label={TERMS.O2}
          />
          <RouterButton
            id="openCreateLots"
            path={`/companies/${companyId}/term/create_diesel`}
            label={TERMS.DIESEL}
          />
        </ExtendableButton>
      );

    const renderRouterButton = (title, label, path, access) =>
      access && (
        <RouterButton
          id={`create${title}`}
          path={`/companies/${this.props.match.params.id}/${path}`}
          padding="0 10px 0 23px"
          icon={<Icon icon="icon-plus" />}
          label={label}
        />
      );

    const sectionTemplates = [
      {
        ...companyDetailsSections.location,
        render: this.renderOverviewList(
          companyDetailsLocationsList,
          (page, limit) => companyDetailsGetLocations(company._id, page, limit),
          companyDetailsSections.location.title,
          locationItemTableTemplate,
          locationItemMobileTemplate,
          locationDetailsTableTemplate,
          locationActionsTemplate(auth),
        ),
        access: auth.access.companies.details.locations.list,
        showAll: true,
        button: renderRouterButton(
          companyDetailsSections.location.title,
          LOCATION.CREATE_A_LOCATION,
          'location/create',
          auth.access.companies.details.locations.create,
        ),
      },
      {
        ...companyDetailsSections.agreement,
        render: this.renderOverviewList(
          companyDetailsAgreementsList,
          (page, limit) => companyDetailsGetAgreements(company._id, page, limit),
          companyDetailsSections.agreement.title,
          agreementItemTableTemplate,
          agreementItemMobileTemplate,
          agreementDetailsTableTemplate,
          agreementActionsTemplate,
        ),
        label: AGREEMENTS.CREATE_AN_AGREEMENT,
        path: 'agreement/create',
        access: auth.access.companies.details.agreements.list,
        showAll: true,
        button: renderRouterButton(
          companyDetailsSections.agreement.title,
          AGREEMENTS.CREATE_AN_AGREEMENT,
          'agreement/create',
          auth.access.companies.details.agreements.create &&
            !!company.primaryContact.length &&
            !!company.primaryLocation,
        ),
      },
      {
        ...companyDetailsSections.term,
        render: this.renderOverviewList(
          companyDetailsTermsList,
          (page, limit) => companyDetailsGetTerms(company._id, page, limit),
          companyDetailsSections.term.title,
          termItemTableTemplate,
          termItemMobileTemplate,
          termDetailsTableTemplate,
          termActionsTemplate,
        ),
        label: TERMS.CREATE_TERM,
        path: 'terms',
        access: auth.access.companies.details.terms.list,
        showAll: true,
        button: renderCollapseButton(
          company._id,
          isAssayCompany(company.companyType) &&
            !!company.primaryContact.length &&
            !!company.primaryLocation &&
            company.status !== companyStatuses.inactive,
        ),
      },
      {
        ...companyDetailsSections.user,
        render: this.renderOverviewList(
          companyDetailsUsersList,
          (page, limit) => companyDetailsGetUsers(company._id, page, limit),
          companyDetailsSections.user.title,
          userItemTableTemplate,
          userItemMobileTemplate,
          userDetailsTableTemplate,
          userActionsTemplate,
        ),
        access: auth.access.companies.details.usersList,
        showAll: false,
        button: renderRouterButton(
          companyDetailsSections.location.title,
          USER.CREATE_A_USER,
          'users/create',
          auth.access.companies.details.usersList,
        ),
      },
    ];

    return (
      <div className="DetailsListsComponent">
        {sectionTemplates.map(
          ({ name, title, render, access, button, showAll }) =>
            this.showSection(company.companyType, title) && (
              <UIAuthorization access={this.checkAccess(access, title)} key={name}>
                <OverviewCompanySection
                  {...this.props}
                  title={title}
                  name={name}
                  button={button}
                  showAll={showAll}
                >
                  {render}
                </OverviewCompanySection>
              </UIAuthorization>
            ),
        )}
      </div>
    );
  }
}

export { CompanyDetailsLists };
