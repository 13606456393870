import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getDefaultPPMsActionType = 'GET_DEFAULT_PPMS';
function getDefaultPPMsAction() {
  return {
    type: getDefaultPPMsActionType,
  };
}

const getDefaultPPMsSuccessActionType = `${getDefaultPPMsActionType}_SUCCESS`;
function getDefaultPPMsSuccessAction(data) {
  return {
    type: getDefaultPPMsSuccessActionType,
    payload: { data: data.defaultPPMs },
  };
}
const getDefaultPPMsFailureActionType = `${getDefaultPPMsActionType}_FAILURE`;
function getDefaultPPMsFailureAction() {
  return {
    type: getDefaultPPMsFailureActionType,
  };
}

const clearDefaultPPMsActionType = 'CLEAR_DEFAULT_PPMS';
function clearDefaultPPMsAction() {
  return {
    type: clearDefaultPPMsActionType,
  };
}

const getDefaultPPMs = () => dispatch => {
  dispatch(getDefaultPPMsAction());

  return axios
    .get(`${API_HOST_AUTH}/hedges/default-ppms`)
    .then(res => {
      dispatch(getDefaultPPMsSuccessAction(res.data));
    })
    .catch(err => {
      dispatch(getDefaultPPMsFailureAction());

      return Promise.reject(err.data);
    });
};

const updateDefaultPPMs = newDefaultPPMs => dispatch => {
  const { _id, __v, ...dataToSend } = newDefaultPPMs;
  dispatch(getDefaultPPMsAction());

  return axios
    .put(`${API_HOST_AUTH}/hedges/default-ppms`, dataToSend)
    .then(res => {
      dispatch(getDefaultPPMsSuccessAction(res.data));

      return res.data;
    })
    .catch(err => {
      dispatch(getDefaultPPMsFailureAction());

      return Promise.reject(err.data);
    });
};

export {
  getDefaultPPMsActionType,
  getDefaultPPMsSuccessActionType,
  getDefaultPPMsFailureActionType,
  clearDefaultPPMsActionType,
  getDefaultPPMs,
  updateDefaultPPMs,
  clearDefaultPPMsAction,
};
