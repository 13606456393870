import {
  createSelfDeliveryRequestActionType,
  createSelfDeliveryRequestSuccessActionType,
  createSelfDeliveryRequestFailureActionType,
} from 'actions/SelfDeliveryRequests/createSelfDeliveryRequest';

const INITIAL_STATE = {
  isPending: false,
};

const CreateSelfDeliveryRequestReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case createSelfDeliveryRequestActionType:
      return {
        isPending: true,
      };
    case createSelfDeliveryRequestSuccessActionType:
    case createSelfDeliveryRequestFailureActionType:
      return {
        isPending: false,
      };
    default:
      return state;
  }
};

export { CreateSelfDeliveryRequestReducer };
