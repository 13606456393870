import { connect } from 'react-redux';

import { manageGradingReceipt } from 'actions/Lots/manageGradingReceipt';
import { openModal } from 'actions/shared/modal';
import { showSnackbar } from 'actions/shared/snackbar';

import { manageGradingReceiptModal } from 'shared/helpers/constants/modalConstants';
import {
  dangerOptions,
  successOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

function ManageGradingReceiptLayout(props) {
  const manageGradingReceiptAction = () => {
    props.openModal(manageGradingReceiptModal(props.approve), () => {
      props
        .manageGradingReceipt(props.lot._id, props.approve)
        .then(({ message }) => props.showSnackbar(successOptions, message))
        .catch(({ message }) => props.showSnackbar(dangerOptions, message));
    });
  };

  return props.children({ manageGradingReceipt: manageGradingReceiptAction });
}

const mapDispatchToProps = {
  showSnackbar,
  openModal,
  manageGradingReceipt,
};

const ManageGradingReceipt = connect(null, mapDispatchToProps)(ManageGradingReceiptLayout);

export { ManageGradingReceipt, ManageGradingReceiptLayout };
