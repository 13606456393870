import React, { Component } from 'react';

import { ListAppend } from '../ListAppend/ListAppend';
import { OverviewTable } from './OverviewTable/OverviewTable';
import { OverviewTableMobile } from './OverviewTableMobile/OverviewTableMobile';

class OverviewList extends Component {
  state = {
    listLimit: this.props.listLimit || 3,
    listPage: 1,
  };

  componentDidMount() {
    if (!this.props.dashboard) {
      this.props.getList(this.state.listPage, this.state.listLimit);
    }
  }

  componentWillUnmount() {
    const { clearData } = this.props;

    clearData && clearData();
  }

  listAppend = () => {
    this.setState(
      prevState => ({
        listLimit: prevState.listLimit + (this.props.listLimit || 3),
      }),
      () => this.props.getList(this.state.listPage, this.state.listLimit),
    );
  };

  renderList() {
    const {
      isMobile,
      input,
      itemsTemplate,
      itemsTemplateMobile,
      itemDetailsTemplate,
      templateProps,
      actionsTemplate,
      actionsContext,
      processing,
      itemFilter,
      displayAllDetails,
      logs,
      limitMaximumHeight,
      customColor,
    } = this.props;

    const actionsTemplateWithAccess =
      !actionsTemplate.access || actionsTemplate.access.call(actionsContext) ? actionsTemplate : [];

    return isMobile && !!itemsTemplateMobile ? (
      <OverviewTableMobile
        itemsTemplate={itemsTemplate}
        actionsTemplate={actionsTemplateWithAccess}
        actionsContext={actionsContext}
        itemsTemplateMobile={itemsTemplateMobile}
        itemDetailsTemplate={itemDetailsTemplate}
        templateProps={templateProps}
        items={input.items.docs}
        itemFilter={itemFilter}
        displayAllDetails={displayAllDetails}
        logs={logs}
        customColor={customColor}
      />
    ) : (
      <OverviewTable
        itemsTemplate={itemsTemplate}
        templateProps={templateProps}
        actionsTemplate={actionsTemplateWithAccess}
        actionsContext={actionsContext}
        items={input.items.docs}
        processing={processing}
        itemFilter={itemFilter}
        logs={logs}
        limitMaximumHeight={limitMaximumHeight}
        customColor={customColor}
      />
    );
  }

  render() {
    const { input } = this.props;
    const itemsExists = !!(input.items && input.items.docs && input.items.docs.length);
    const showMore =
      input.items && (input.pages > this.state.listPage || input.items.pages > this.state.listPage);
    return (
      <React.Fragment>
        {itemsExists && (
          <React.Fragment>
            {this.renderList()}
            {showMore && <ListAppend onClick={this.listAppend} loading={input.isPending} />}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export { OverviewList };
