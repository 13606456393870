import PropTypes from 'prop-types';
import React from 'react';
import { FieldArray } from 'redux-form';

import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';

import { LotsGroupFields } from '../LotsGroupFields/LotsGroupFields';

function LotsGroupForm({ namePrefix, title, buttonLabel, sectionTitle, commonComponent }) {
  return (
    <FieldArray
      title={title}
      name={namePrefix}
      sectionTitle={sectionTitle}
      buttonLabel={buttonLabel}
      label={LOTS.DESCRIPTION}
      component={LotsGroupFields}
      commonComponent={commonComponent}
    />
  );
}

LotsGroupForm.propTypes = {
  buttonLabel: PropTypes.string,
  namePrefix: PropTypes.string,
  title: PropTypes.string,
  sectionTitle: PropTypes.string,
  commonComponent: PropTypes.element,
};

export { LotsGroupForm };
