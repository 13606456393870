import {
  addBillOfLadingType,
  clearBillOfLadingType,
  uploadBillOfLadingType,
  uploadBillOfLadingSuccessType,
  uploadBillOfLadingFailureType,
} from 'actions/Lots/uploadBillOfLading';

const INITIAL_STATE = {
  billOfLading: null,
  isPending: false,
};

const uploadBillOfLadingReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case uploadBillOfLadingType:
      return {
        ...state,
        isPending: true,
      };
    case uploadBillOfLadingSuccessType:
    case uploadBillOfLadingFailureType:
      return {
        ...state,
        isPending: false,
      };
    case addBillOfLadingType:
      return {
        ...state,
        billOfLading: action.payload.billOfLading,
      };
    case clearBillOfLadingType:
      return {
        ...INITIAL_STATE,
      };

    default:
      return state;
  }
};

export { uploadBillOfLadingReducer };
