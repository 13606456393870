import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { createCalendarNote } from 'actions/Shipments/createCalendarNote';
import { getShipmentsCalendar } from 'actions/Shipments/shipmentsCalendar';
import { showSnackbar } from 'actions/shared/snackbar';

import { ModalCustom } from 'components/ModalCustom/ModalCustom';

import { CALENDAR_NOTE } from 'shared/helpers/constants/calendarConstants';
import {
  dangerOptions,
  successOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

import dateAdapter from 'utils/date/dateAdapter';
import { relativeNavigator } from 'utils/relativeNavigator';

import { CalendarNoteForm } from './components/CalendarNoteForm';

const CreateCalendarNote = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const navigation = [
    { from: /^\/shipments\/calendar-notes/, to: '/shipments' },
    { from: /^\/dashboard\/calendar-notes/, to: '/dashboard' },
  ];

  const navigator = relativeNavigator(navigation)();

  const modalClose = useCallback(() => {
    navigator(pathname);
  }, [navigator]);

  const createNote = useCallback(
    values => {
      dispatch(createCalendarNote(values))
        .then(({ message }) => {
          dispatch(getShipmentsCalendar(Date.now()));
          dispatch(showSnackbar(successOptions, message));
          modalClose();
        })
        .catch(error => dispatch(showSnackbar(dangerOptions, error && error.message)));
    },
    [modalClose],
  );

  const defaultValues = {
    get startDate() {
      return dateAdapter().startOf('d').valueOf();
    },
  };

  return (
    <ModalCustom title={CALENDAR_NOTE.TITLE} modalClose={modalClose} maxWidth={1000} isOpen>
      <CalendarNoteForm
        onSubmit={createNote}
        closeModal={modalClose}
        initialValues={defaultValues}
      />
    </ModalCustom>
  );
};

export { CreateCalendarNote };
