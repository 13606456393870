import React from 'react';

import { StatusIndicator } from 'shared/components/StatusIndicator/StatusIndicator';
import { dateWithShortTimeFormat } from 'shared/helpers/parsers/date';
import { formatDate, formatToDollarWithPrefix, formatToUS } from 'shared/helpers/parsers/formaters';
import { humanize } from 'shared/helpers/parsers/text';

import { SELF_DELIVERY_REQUESTS } from './selfDeliveryRequestsConstants';

const itemsTemplate = [
  {
    label: SELF_DELIVERY_REQUESTS.STATUS,
    render: ({ status }) => <StatusIndicator status={status} />,
  },
  {
    label: SELF_DELIVERY_REQUESTS.RELATED_COMPANY,
    render: ({ relatedCompany }) => relatedCompany.companyName,
  },
  {
    label: SELF_DELIVERY_REQUESTS.DELIVERY_DATE,
    render: ({ deliveryDate }) => formatDate(deliveryDate, dateWithShortTimeFormat),
  },
  {
    label: SELF_DELIVERY_REQUESTS.UNIT_COUNT_UNITS,
    render: ({ unitCount }) => formatToUS(unitCount, 0),
  },
  {
    label: SELF_DELIVERY_REQUESTS.FUNDS_TYPE,
    render: ({ fundsType }) => humanize(fundsType),
  },
  {
    label: SELF_DELIVERY_REQUESTS.ESTIMATED_FUNDS,
    render: ({ estimatedFunds }) => formatToDollarWithPrefix(estimatedFunds),
  },
];

const itemsTemplateGrading = [
  {
    label: SELF_DELIVERY_REQUESTS.STATUS,
    render: ({ status }) => <StatusIndicator status={status} />,
  },
  {
    label: SELF_DELIVERY_REQUESTS.DELIVERY_DATE,
    render: ({ deliveryDate }) => formatDate(deliveryDate, dateWithShortTimeFormat),
  },
  {
    label: SELF_DELIVERY_REQUESTS.UNIT_COUNT_UNITS,
    render: ({ unitCount }) => formatToUS(unitCount, 0),
  },
  {
    label: SELF_DELIVERY_REQUESTS.FUNDS_TYPE,
    render: ({ fundsType }) => humanize(fundsType),
  },
  {
    label: SELF_DELIVERY_REQUESTS.ESTIMATED_FUNDS,
    render: ({ estimatedFunds }) => formatToDollarWithPrefix(estimatedFunds),
  },
];

const itemsTemplateMobile = [
  {
    label: SELF_DELIVERY_REQUESTS.STATUS,
    render: ({ status }) => <StatusIndicator status={status} />,
  },
  {
    label: SELF_DELIVERY_REQUESTS.DELIVERY_DATE,
    render: ({ deliveryDate }) => formatDate(deliveryDate, dateWithShortTimeFormat),
  },
];

const itemsDetailsTemplate = [
  {
    label: SELF_DELIVERY_REQUESTS.RELATED_COMPANY,
    render: ({ relatedCompany }) => relatedCompany.companyName,
  },
  {
    label: SELF_DELIVERY_REQUESTS.UNIT_COUNT_UNITS,
    render: ({ unitCount }) => formatToUS(unitCount, 0),
  },
  {
    label: SELF_DELIVERY_REQUESTS.FUNDS_TYPE,
    render: ({ fundsType }) => humanize(fundsType),
  },
  {
    label: SELF_DELIVERY_REQUESTS.ESTIMATED_FUNDS,
    render: ({ estimatedFunds }) => formatToDollarWithPrefix(estimatedFunds),
  },
];

const [, ...itemsDetailsTemplateGrading] = itemsTemplateGrading;

export {
  itemsTemplate,
  itemsTemplateGrading,
  itemsDetailsTemplate,
  itemsDetailsTemplateGrading,
  itemsTemplateMobile,
};
