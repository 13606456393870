import { safeReplace, removeCommasAndParse } from 'shared/helpers/parsers/text';

const parseEnterPoolPaymentData = ({ payment, settlement, financeCharges }) =>
  Object.entries({
    relatedCompany: payment.relatedCompany.value,
    fundsType: payment.fundsType.value,
    additionalInfo: payment.additionalInfo,
    ouncesPt: +safeReplace(settlement.ouncesPt, ',', '.'),
    ouncesPd: +safeReplace(settlement.ouncesPd, ',', '.'),
    ouncesRh: +safeReplace(settlement.ouncesRh, ',', '.'),
    outstandingBalanceApplied: removeCommasAndParse(settlement.outstandingBalanceApplied),
    pricePerOuncePt: removeCommasAndParse(settlement.pricePerOuncePt),
    pricePerOuncePd: removeCommasAndParse(settlement.pricePerOuncePd),
    pricePerOunceRh: removeCommasAndParse(settlement.pricePerOunceRh),
    charges:
      financeCharges.charges?.map(charge => ({
        relatedLot: charge.relatedLot?.value,
        financeCharge: removeCommasAndParse(charge.financeCharge),
        ...(charge.financeRate && { financeRate: removeCommasAndParse(charge.financeRate) }),
      })) || [],
  }).reduce((acc, [key, value]) => (value || value === 0 ? { ...acc, [key]: value } : acc), {});

export { parseEnterPoolPaymentData };
