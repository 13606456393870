import {
  clearLocationDetailsUsersListActionType,
  getLocationDetailsUsersActionType,
  getLocationDetailsUsersFailureActionType,
  getLocationDetailsUsersSuccessActionType,
} from 'actions/Locations/Details/usersList';

const INITIAL_STATE = {
  isPending: false,
  errorMessage: null,
  users: [],
  limit: 3,
  page: 1,
  pages: null,
  total: null,
  items: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getLocationDetailsUsersActionType:
      return {
        ...state,
        isPending: true,
      };
    case getLocationDetailsUsersSuccessActionType:
      return {
        ...state,
        isPending: false,
        users: action.payload.users,
        pages: action.payload.pages,
        total: action.payload.total,
        items: {
          docs: action.payload.users,
        },
      };
    case getLocationDetailsUsersFailureActionType:
      return {
        ...state,
        isPending: false,
        errorMessage: action.payload.errorMessage,
      };
    case clearLocationDetailsUsersListActionType:
      return {
        isPending: false,
        errorMessage: null,
        users: [],
        limit: 3,
        page: 1,
        pages: null,
        total: null,
        items: [],
      };
    default:
      return state;
  }
};
