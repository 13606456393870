import {
  getPrintablePackagesActionType,
  getPrintablePackagesSuccessActionType,
  getPrintablePackagesFailureActionType,
  clearPrintablePackagesActionType,
} from 'actions/Packages/printablePackagesList';

const INITIAL_STATE = {
  isPending: false,
  packages: [],
  items: [],
  pages: null,
};

const PrintablePackagesListReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getPrintablePackagesActionType:
      return {
        ...state,
        isPending: true,
      };
    case getPrintablePackagesSuccessActionType:
      return {
        ...action.payload,
        items: { docs: action.payload.packages },
        isPending: false,
      };
    case getPrintablePackagesFailureActionType:
      return {
        ...state,
        isPending: false,
      };
    case clearPrintablePackagesActionType:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};

export { PrintablePackagesListReducer };
