import PropTypes from 'prop-types';

import { DatepickerDropdown } from 'shared/components/DatepickerDropdown/DatepickerDropdown';
import { FiltersDropdown } from 'shared/components/FiltersDropdown/FiltersDropdown';
import { fundsTypes } from 'shared/helpers/constants/payments/fundsTypes';
import { paymentTypes } from 'shared/helpers/constants/payments/paymentTypes';
import { PAYMENTS } from 'shared/helpers/constants/payments/paymentsConstants';

import dateAdapter from 'utils/date/dateAdapter';
import { MomentAdapter } from 'utils/date/momentAdapter';

import { StyledFilters, StyledFiltersItem } from './StyledTotalPaymentsFilters';

const TotalPaymentsFilters = ({ filters, onFiltersChanged }) => {
  const { paymentType, fundsType, paidAtStart, paidAtEnd } = filters;

  const filterFunction = name => value =>
    onFiltersChanged({
      ...filters,
      [name]: value instanceof MomentAdapter ? value.valueOf() : value,
    });

  const renderDateFilter = (name, label, minDate, maxDate) => (
    <DatepickerDropdown
      label={label}
      selected={filters[name] && dateAdapter(filters[name])}
      filterFunction={filterFunction(name)}
      minDate={dateAdapter(minDate || 0)}
      maxDate={dateAdapter(maxDate)}
      fixedHeight
    />
  );

  return (
    <StyledFilters>
      <StyledFiltersItem>
        <FiltersDropdown
          label={PAYMENTS.PAYMENT_TYPE}
          options={Object.values(paymentTypes)}
          selected={paymentType || ''}
          filterFunction={filterFunction('paymentType')}
          multiSelect
        />
      </StyledFiltersItem>
      <StyledFiltersItem>
        <FiltersDropdown
          label={PAYMENTS.FUNDS_TYPE}
          options={Object.values(fundsTypes)}
          selected={fundsType || ''}
          filterFunction={filterFunction('fundsType')}
        />
      </StyledFiltersItem>
      <StyledFiltersItem>
        {renderDateFilter('paidAtStart', PAYMENTS.PAID_ON_FROM, 0, paidAtEnd)}
      </StyledFiltersItem>
      <StyledFiltersItem>
        {renderDateFilter('paidAtEnd', PAYMENTS.PAID_ON_TO, paidAtStart)}
      </StyledFiltersItem>
    </StyledFilters>
  );
};

TotalPaymentsFilters.propTypes = {
  filters: PropTypes.shape({
    fundsType: PropTypes.arrayOf(PropTypes.string),
    isPaid: PropTypes.bool,
    paidAtEnd: PropTypes.number,
    paidAtStart: PropTypes.number,
    paymentType: PropTypes.arrayOf(PropTypes.string),
    query: PropTypes.string,
    status: PropTypes.arrayOf(PropTypes.string),
  }),
  onFiltersChanged: PropTypes.func,
};

export { TotalPaymentsFilters };
