import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { getCompaniesForSettleOunces } from 'actions/Companies/filterCompanies';
import { marketAvailability } from 'actions/Hedges/marketAvailability';
import { settlementOfMetalPoolRequest } from 'actions/PoolAccounts/requestSettlement';
import { closeModal, openModal } from 'actions/shared/modal';
import { showSnackbar } from 'actions/shared/snackbar';

import { SettlementOfMetalPool } from './SettlementOfMetalPool';

const mapStateToProps = ({
  auth,
  modal,
  poolAccountsList,
  requestSettlement,
  marketAvailabilityDetails,
}) => ({
  auth,
  modal,
  poolAccountsList,
  requestSettlement,
  marketAvailabilityDetails,
});

const mapDispatchToProps = {
  closeModal,
  marketAvailability,
  openModal,
  settlementOfMetalPoolRequest,
  showSnackbar,
  getCompaniesForSettleOunces,
};

const ConnectedSettlementOfMetalPool = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SettlementOfMetalPool),
);

export { ConnectedSettlementOfMetalPool };
