import { connect } from 'react-redux';

import { locationStatusFilter, locationFiltersClear } from 'actions/Locations/locationFilters';
import { getLocationList, clearLocationsList } from 'actions/Locations/locationsList';

import { LocationsList } from './LocationsList';

const mapStateToProps = ({ auth, locationsList, locationFilters }) => ({
  auth,
  locationsList,
  locationFilters,
});

const mapDispatchToProps = {
  getLocationList,
  clearLocationsList,
  locationStatusFilter,
  locationFiltersClear,
};

export const ConnectedLocationsList = connect(mapStateToProps, mapDispatchToProps)(LocationsList);
