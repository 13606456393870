import React, { useState } from 'react';

import { UIAuthorization } from 'components/Authorization/UIAuthorization';

import { Button } from 'shared/components/Buttons/Button';
import { ExtendableButton } from 'shared/components/Buttons/ExtendableButton/ExtendableButton';
import { DetailsHeader } from 'shared/components/Details/DetailsHeader';
import { AGREEMENTS } from 'shared/helpers/constants/agreements/agreementsConstants';
import { agreementsStatuses } from 'shared/helpers/constants/filters/agreementsStatuses';
import { isStandard } from 'shared/helpers/matchers/checkAccessLevel';
import { isOwnerUser } from 'shared/helpers/matchers/checkUserType';

import { AgreementsReuploadSigned } from 'pages/Agreements/components/AgreementsDetails/components/AgreementsReuploadSigned/AgreementsReuploadSigned';

import { AgreementCreateDocument } from './AgreementsCreateDocument';

function AgreementsMainDetails({ auth, agreement, access }) {
  const [displayReuploadSignedModal, setDisplayReuploadSignedModal] = useState(false);

  const canCreateDocument =
    access.agreements.createAgreementDocument && agreement.status === agreementsStatuses.draft;

  const canUploadSigned =
    access.agreements.uploadSignedAgreement &&
    agreement.status === agreementsStatuses.download &&
    agreement.digitalSignatureRequired === false;

  const canUploadAnotherDocument =
    isOwnerUser(auth.user.userType) &&
    isStandard(auth.user.accessLevel) &&
    agreement.status === agreementsStatuses.signed;

  const toggleAgreementsReuploadSigned = () =>
    setDisplayReuploadSignedModal(!displayReuploadSignedModal);

  return (
    <DetailsHeader header={agreement.relatedCompany.companyName} status={agreement.status}>
      <ExtendableButton label={AGREEMENTS.ACTIONS}>
        <UIAuthorization access={canCreateDocument}>
          <AgreementCreateDocument agreement={agreement}>
            {({ onClick }) => (
              <Button outline id="createAgreementDocument" onClick={onClick}>
                {AGREEMENTS.CREATE_DOCUMENT}
              </Button>
            )}
          </AgreementCreateDocument>
        </UIAuthorization>
        <UIAuthorization access={canUploadAnotherDocument || canUploadSigned}>
          <Button outline onClick={toggleAgreementsReuploadSigned}>
            {agreement.status === agreementsStatuses.signed
              ? AGREEMENTS.REUPLOAD_SIGNED
              : AGREEMENTS.UPLOAD_SIGNED}
          </Button>
        </UIAuthorization>
      </ExtendableButton>
      <AgreementsReuploadSigned
        toggle={toggleAgreementsReuploadSigned}
        open={displayReuploadSignedModal}
        title={
          agreement.status === agreementsStatuses.signed
            ? AGREEMENTS.REUPLOAD_SIGNED
            : AGREEMENTS.UPLOAD_SIGNED
        }
      />
    </DetailsHeader>
  );
}

export { AgreementsMainDetails };
