import React from 'react';
import { useSelector } from 'react-redux';

import { FiltersDropdown } from 'shared/components/FiltersDropdown/FiltersDropdown';
import {
  companyDueDiligenceStatus,
  companyStatuses,
  companyTypesTable,
} from 'shared/helpers/constants/companies/companiesFilters';
import { COMPANY } from 'shared/helpers/constants/companies/companyConstants';

import { FilterCountry } from './FilterCountry/FilterCountry';
import { StyledCompaniesFilters, StyledCompaniesFiltersItem } from './StyledCompaniesFilters';

const CompaniesFilters = ({ filters, regionList, onFiltersChanged }) => {
  const { status, companyTypes, country, state, dueDiligence } = filters;
  const access = useSelector(state => state.auth.access);

  const filterFunction = name => value => {
    onFiltersChanged({
      ...filters,
      ...(name === 'country' && { state: null }),
      [name]: value,
    });
  };

  return (
    <StyledCompaniesFilters>
      <StyledCompaniesFiltersItem>
        <FiltersDropdown
          label={COMPANY.STATUS}
          options={Object.values(companyStatuses)}
          selected={status || ''}
          filterFunction={filterFunction('status')}
        />
      </StyledCompaniesFiltersItem>
      <StyledCompaniesFiltersItem>
        <FiltersDropdown
          label={COMPANY.COMPANY_TYPE}
          options={Object.values(companyTypesTable(access.companies.gradingEnabled))}
          selected={companyTypes || ''}
          filterFunction={filterFunction('companyTypes')}
        />
      </StyledCompaniesFiltersItem>
      <StyledCompaniesFiltersItem>
        <FilterCountry
          regionList={regionList}
          filters={{ country, state }}
          filterFunction={filterFunction}
        />
      </StyledCompaniesFiltersItem>
      <StyledCompaniesFiltersItem>
        <FiltersDropdown
          label={COMPANY.DUE_DILIGENCE}
          options={Object.values(companyDueDiligenceStatus)}
          selected={dueDiligence || ''}
          filterFunction={filterFunction('dueDiligence')}
          multiSelect
        />
      </StyledCompaniesFiltersItem>
    </StyledCompaniesFilters>
  );
};
export { CompaniesFilters };
