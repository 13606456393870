import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';

import { getPDFDocument } from 'actions/Files/getPDFDocument';

import { LinkButton } from 'shared/components/Buttons/LinkButton/LinkButton';
import { PreviewButton } from 'shared/components/Buttons/PreviewButton';
import { PDF_REGEX, FILE_EXTENSION } from 'shared/helpers/validations/regularExpressions';

import { DetailsField } from '../DetailsField';
import { DETAILS_CONSTANTS } from '../constants/detailsConstants';

const isFileEmpty = value => value && DETAILS_CONSTANTS.DOWNLOAD;

const FilesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
`;

const FileActions = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin: -8px 10px 0 0 !important;

  ${({ highlightLast }) =>
    highlightLast &&
    css`
      opacity: 0.2;

      &:last-child {
        opacity: 1;
      }
    `}
`;

const DetailsDownloadField = ({
  value,
  generateName,
  previewIsAvailable,
  manualCreationWarning,
  fileId,
  ...props
}) => {
  const dispatch = useDispatch();
  const handleShow = id => dispatch(getPDFDocument(id));

  if (Array.isArray(value)) {
    const filesList = (
      <FilesContainer>
        {value.map((file, index) => {
          const fileOriginalName = file?.originalName || file?.relatedFile?.originalName;

          return (
            <FileActions highlightLast={props.highlightLast}>
              <LinkButton
                label={generateName ? generateName(index, value) : isFileEmpty(file)}
                download={file}
                onClick={() => props.onClick(index)}
                forwardable={props.forwardable}
                margin="0 8px 0 0"
                key={index}
              />
              {PDF_REGEX.test(fileOriginalName) || !FILE_EXTENSION.test(fileOriginalName) ? (
                <PreviewButton
                  sectionDocuments
                  handleClick={() => handleShow(file.relatedFile?._id || file._id)}
                />
              ) : null}
            </FileActions>
          );
        })}
      </FilesContainer>
    );

    const displayFiles = value && value.length;

    return (
      <DetailsField
        {...props}
        download={value}
        customContent={displayFiles ? filesList : DETAILS_CONSTANTS.EMPTY_FIELD}
        customContentDisabled={!displayFiles}
      />
    );
  }

  return (
    <DetailsField
      {...props}
      value={isFileEmpty(value)}
      download={value}
      fileId={fileId}
      previewIsAvailable={previewIsAvailable}
      manualCreationWarning={manualCreationWarning}
    />
  );
};

DetailsDownloadField.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  previewIsAvailable: PropTypes.bool,
  generateName: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string,
        path: PropTypes.string,
        uploadAt: PropTypes.number,
        hedgeName: PropTypes.string,
      }),
    ),
    PropTypes.shape({
      _id: PropTypes.string,
      path: PropTypes.string,
      uploadAt: PropTypes.number,
      hedgeName: PropTypes.string,
    }),
  ]),
  icon: PropTypes.string,
  highlightLast: PropTypes.bool,
  manualCreationWarning: PropTypes.bool,
};

DetailsDownloadField.defaultProps = {
  icon: 'icon-download',
  previewIsAvailable: true,
  highlightLast: false,
  manualCreationWarning: false,
};

export { DetailsDownloadField };
