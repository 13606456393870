import React from 'react';
import { useSelector } from 'react-redux';
import { FieldArray, FormSection, reduxForm, formValueSelector } from 'redux-form';

import { Form } from 'shared/components/Form';
import { PAYMENTS } from 'shared/helpers/constants/payments/paymentsConstants';
import { calculatePoolPaymentAmount } from 'shared/helpers/dataParsers/payments/calculatePoolPaymentAmount';
import { multiValidator } from 'shared/helpers/validations/multiValidator';
import { validate } from 'shared/helpers/validations/payments/financeChargesValidation';

import { PaymentAmountContainer, paymentAmountOutOfRange } from '../SettlementForm/SettlementForm';
import { FinanceCharges } from './FinanceCharges/FinanceCharges';

const FinanceChargesFormLayout = ({ handleSubmit, invalid, form, submitting }) => {
  const relatedCompany = useSelector(state =>
    formValueSelector(form)(state, 'payment.relatedCompany'),
  );
  const settlement = useSelector(state => formValueSelector(form)(state, 'settlement'));
  const charges = useSelector(state => formValueSelector(form)(state, 'financeCharges.charges'));

  return (
    <Form
      header={PAYMENTS.FINANCE_CHARGES}
      onSubmit={handleSubmit}
      submitDisabled={
        submitting || invalid || (relatedCompany.financeChargesRequired && !charges?.length)
      }
    >
      <FormSection name="financeCharges">
        <FieldArray
          name="charges"
          isRequired={relatedCompany.financeChargesRequired}
          relatedLots={relatedCompany.relatedLots}
          component={FinanceCharges}
          charges={charges}
          form={form}
        />
      </FormSection>
      <PaymentAmountContainer>
        {paymentAmountOutOfRange(settlement, charges)
          ? PAYMENTS.PAYMENT_AMOUNT_OUT_OF_RANGE
          : PAYMENTS.CALCULATED_PAYMENT_AMOUNT(calculatePoolPaymentAmount(settlement, charges))}
      </PaymentAmountContainer>
    </Form>
  );
};

const FinanceChargesForm = reduxForm({
  validate: multiValidator('financeCharges', validate),
})(FinanceChargesFormLayout);

export { FinanceChargesForm, FinanceChargesFormLayout };
