import React, { Fragment, useCallback } from 'react';
import { connect } from 'react-redux';

import { openModal as OpenModalAction } from 'actions/shared/modal';

import { AddButton } from 'shared/components/Buttons/AddButton/AddButton';
import { Separator } from 'shared/components/Form';
import { LOCATION } from 'shared/helpers/constants/locations/locationConstants';
import { removeLocationModal } from 'shared/helpers/constants/modalConstants';

import { AddLocation } from './AddLocation';

function LocationsListBase(props) {
  const { fields, invalid, change, openModal } = props;

  const addField = useCallback(() => fields.push({}), [fields]);

  const removeField = useCallback(
    index => {
      openModal(removeLocationModal, () => fields.remove(index));
    },
    [fields, openModal],
  );

  return (
    <>
      {fields.map((location, index) => (
        <Fragment key={index}>
          <AddLocation
            index={index}
            location={location}
            change={change}
            onRemove={!!index && (() => removeField(index))}
          />
          <Separator />
        </Fragment>
      ))}
      <AddButton onClick={addField} disabled={invalid}>
        {LOCATION.ADD_NEW_LOCATION}
      </AddButton>
    </>
  );
}

const mapDispatchToProps = {
  openModal: OpenModalAction,
};

const LocationsList = connect(null, mapDispatchToProps)(LocationsListBase);

export { LocationsList };
