import styled, { css } from 'styled-components';

import { StyledListCollapse } from 'shared/components/ListCollapse/StyledListCollapse/StyledListCollapse';
import { cssVar } from 'shared/helpers/styling/styling';

export const SamplePackagesTitle = styled.h4`
  font-size: 1.2rem;
  margin: 0;
`;

export const SamplePackagesListCollapse = styled(StyledListCollapse)`
  padding: 20px 45px;
  background-color: ${cssVar('outerSpaceBitLighter')};
  border-bottom: 1px solid ${cssVar('riverBedDarker')};
  font-size: 1.5rem;
  width: 100%;
  :hover {
    background-color: ${cssVar('limedSpruceDarker')};
  }
  ${({ isOpen }) =>
    isOpen
      ? css`
          &:after {
            transform: translateX(-0.7rem);
            width: 1.5rem;
            height: 1px;
          }
          &:before {
            width: 1px;
            height: 1.5rem;
            margin-right: 5.5rem;
          }
        `
      : css`
          &:before {
            transform: translateX(-0.7rem);
            width: 1.5rem;
            height: 1px;
            margin-right: 4.12rem;
            padding-right: 1.5rem;
          }
          &:after {
            width: 1px;
            height: 1.5rem;
          }
        `}
`;
