import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Icon } from 'shared/components/Icon/Icon';
import { cssVar } from 'shared/helpers/styling/styling';

const ProcessingNoteWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const ProcessingNoteCounter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  font-weight: bold;
  text-align: center;
  position: absolute;
  right: -25%;
  top: ${({ notesCount }) => (notesCount ? '-20%' : '60%')};
  color: ${cssVar('riverBedLegend')};
  font-size: 10px;
  background-color: ${cssVar('whiteGRG')};
`;

const ProcessingNote = ({ notesCount, icon, onClick }) => (
  <ProcessingNoteWrapper onClick={onClick}>
    <Icon
      icon={icon}
      width={24}
      height={24}
      size="contain"
      color={cssVar('whiteGRG')}
      data-testid="processingNoteIcon"
    />
    <ProcessingNoteCounter notesCount={notesCount}>
      {notesCount || (
        <Icon
          icon="icon-plus"
          color={cssVar('riverBedLegend')}
          size="contain"
          width={9}
          height={9}
          data-testid="processingNotePlusIcon"
        />
      )}
    </ProcessingNoteCounter>
  </ProcessingNoteWrapper>
);

ProcessingNote.propTypes = {
  icon: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  notesCount: PropTypes.number.isRequired,
};

ProcessingNote.defaultProps = {
  icon: 'icon-document',
};

export { ProcessingNote };
