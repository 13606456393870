import {
  getSuggestedDeliveryDateAction,
  getSuggestedDeliveryDateSuccessActionType,
  getSuggestedDeliveryDateFailureActionType,
  clearSuggestedDeliveryDateActionType,
} from 'actions/Hedges/suggestedDeliveryDate';

const INITIAL_STATE = {
  isPending: false,
  deliveryDate: null,
};

const suggestedDeliveryDateReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getSuggestedDeliveryDateAction:
      return {
        isPending: true,
        ...state,
      };
    case getSuggestedDeliveryDateSuccessActionType:
      return {
        isPending: false,
        deliveryDate: action.payload.suggestedDeliveryDate,
      };
    case getSuggestedDeliveryDateFailureActionType:
      return {
        isPending: false,
        deliveryDate: null,
      };
    case clearSuggestedDeliveryDateActionType:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};

export { suggestedDeliveryDateReducer };
