import React from 'react';

import { isGlobalUserType } from 'shared/helpers/matchers/isGlobalUserType';

import { CustomerLotsSections } from './components/CustomerLotsSections';
import { GlobalLotsSections } from './components/GlobalLotsSections';

function LotsSections(props) {
  return (
    <React.Fragment>
      {isGlobalUserType(props.auth.user.userType) ? (
        <GlobalLotsSections {...props} />
      ) : (
        <CustomerLotsSections {...props} />
      )}
    </React.Fragment>
  );
}

export { LotsSections };
