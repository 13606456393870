import {
  createLotActionType,
  createLotSuccessActionType,
  createLotFailureActionType,
} from 'actions/Lots/createLot';

const INITIAL_STATE = {
  isPending: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case createLotActionType:
      return {
        isPending: true,
      };
    case createLotFailureActionType:
      return {
        isPending: false,
      };
    case createLotSuccessActionType:
      return {
        isPending: false,
      };
    default:
      return state;
  }
};
