import { create, all } from 'mathjs';

const math = create(all, { number: 'BigNumber' });

const evaluate = expression => {
  if (typeof expression === 'string') {
    return math.evaluate(expression.replace(/\r?\n/g, ' ').replace(/null|undefined/g, '0'));
  }

  if (typeof expression === 'number') {
    return math.evaluate(expression);
  }

  return expression;
};

export { evaluate };
