import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { getGrandTotalValue, clearGrandTotalValue } from 'actions/PoolAccounts/getGrandTotalValue';
import { getPoolAccounts, clearPoolAccountsAction } from 'actions/PoolAccounts/poolAccountsList';
import { openModal } from 'actions/shared/modal';
import { showSnackbar } from 'actions/shared/snackbar';

import { PoolAccountsList } from './PoolAccountsList';

const mapStateToProps = state => ({
  auth: state.auth,
  poolAccountsList: state.poolAccountsList,
  grandTotalValue: state.grandTotalValue,
});

const mapDispatchToProps = {
  getPoolAccounts,
  clearPoolAccountsAction,
  openModal,
  showSnackbar,
  getGrandTotalValue,
  clearGrandTotalValue,
};

const ConnectedPoolAccountsList = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PoolAccountsList),
);

export { ConnectedPoolAccountsList };
