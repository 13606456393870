import { DetailsActivityField } from 'shared/components/Details/DetailsActivityField';
import { DetailsField } from 'shared/components/Details/DetailsField';
import { DetailsRadioField } from 'shared/components/Details/DetailsRadioFieldRedesign';
import { LOCATION } from 'shared/helpers/constants/locations/locationConstants';
import { USER } from 'shared/helpers/constants/users/userConstants';

const locationDetailsFieldsConfig = location => [
  {
    label: LOCATION.RELATED_COMPANY,
    value: location.relatedCompany.companyName,
    icon: 'icon-home',
    id: 'relatedCompany',
    FieldType: DetailsField,
  },
  {
    label: LOCATION.SUITE,
    icon: 'icon-suite',
    value: location.suite,
    id: 'suite',
    FieldType: DetailsField,
  },
  {
    label: LOCATION.LOT_SUFFIX,
    value: location.lotSuffix,
    icon: 'icon-document',
    id: 'lotSuffix',
    FieldType: DetailsField,
  },
  {
    label: LOCATION.PO_BOX,
    value: location.poBox,
    icon: 'icon-po-box',
    id: 'poBox',
    FieldType: DetailsField,
  },
  {
    label: LOCATION.COUNTRY,
    value: location.country.label,
    icon: 'icon-location',
    id: 'country',
    FieldType: DetailsField,
  },
  {
    label: LOCATION.STATE,
    value: location.state,
    icon: 'icon-state',
    id: 'state',
    FieldType: DetailsField,
  },
  {
    label: LOCATION.CITY,
    value: location.city,
    icon: 'icon-city',
    id: 'city',
    FieldType: DetailsField,
  },
  {
    label: LOCATION.STREET,
    value: location.street,
    icon: 'icon-street',
    id: 'street',
    FieldType: DetailsField,
  },
  {
    label: LOCATION.ZIP_CODE,
    value: location.zipCode,
    icon: 'icon-zip',
    id: 'zipCode',
    FieldType: DetailsField,
  },
  {
    label: LOCATION.SHIPMENT_ELIGIBLE,
    value: location.shipmentEligible,
    icon: 'icon-document',
    id: 'shipmentEligible',
    FieldType: DetailsRadioField,
  },
  {
    label: LOCATION.PRIMARY_LOCATION,
    value: location.primaryLocation,
    icon: 'icon-document',
    id: 'primaryLocation',
    FieldType: DetailsRadioField,
  },
];

const primaryContactFieldsConfig = primaryContact => [
  {
    label: LOCATION.CONTACT_PERSON,
    user: primaryContact,
    id: 'companyPerson',
    FieldType: DetailsActivityField,
  },
  {
    label: USER.EMAIL,
    value: primaryContact.email,
    icon: 'icon-email',
    id: 'email',
    FieldType: DetailsField,
  },
  {
    label: USER.OFFICE_PHONE,
    value: primaryContact.officePhone,
    icon: 'icon-phone',
    id: 'officePhone',
    FieldType: DetailsField,
  },
  {
    label: USER.PHONE_EXTENSION,
    value: primaryContact.phoneExtension,
    icon: 'icon-phone',
    id: 'phoneExtension',
    FieldType: DetailsField,
  },
  {
    label: USER.MOBILE_PHONE,
    value: primaryContact.mobilePhone,
    icon: 'icon-phone',
    id: 'mobilePhone',
    FieldType: DetailsField,
  },
];

const activityFieldsConfig = location => [
  {
    label: LOCATION.CREATED_BY,
    user: location.createdBy,
    date: location.createdAt,
    id: 'createdBy',
    FieldType: DetailsActivityField,
  },
  {
    label: LOCATION.UPDATED_BY,
    user: location.updatedBy,
    date: location.updatedAt,
    id: 'updatedBy',
    FieldType: DetailsActivityField,
  },
];

const locationDetailsContainerConfig = () => ({
  header: LOCATION.GENERAL_DETAILS,
  template: [
    'relatedCompany relatedCompany lotSuffix country',
    'state city street zipCode',
    'suite poBox shipmentEligible primaryLocation',
  ],
});

const primaryContactContainerConfig = (primaryContact, index, length) => ({
  header: length === 1 ? LOCATION.PRIMARY_CONTACT : `${LOCATION.PRIMARY_CONTACT} ${index + 1}`,
  template: [
    'companyPerson companyPerson email email',
    'officePhone officePhone phoneExtension mobilePhone',
  ],
});

const activityContainerConfig = () => ({
  header: LOCATION.ACTIVITY,
  template: ['createdBy createdBy updatedBy updatedBy'],
});

export {
  locationDetailsFieldsConfig,
  primaryContactFieldsConfig,
  activityFieldsConfig,
  locationDetailsContainerConfig,
  primaryContactContainerConfig,
  activityContainerConfig,
};
