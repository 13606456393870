import PropTypes from 'prop-types';
import React, { useState, useCallback } from 'react';
import { Field } from 'redux-form';

import { Section } from 'shared/components/Form';
import { capitalizeGlobal } from 'shared/helpers/parsers/text';

import { SecretFieldInput } from '..';

function FieldConfirmation(props) {
  const {
    name,
    label,
    change,
    errorMessage,
    topBorder,
    disabled,
    valueExtractor,
    disableConfirmation,
    withoutGridGap = false,
    keepOriginalLabel = false,
  } = props;
  const [needsConfirmation, setNeedsConfirmation] = useState(false);
  const { hint, hintClickable, ...baseProps } = props;

  const actionName = 'Confirm';
  const repeatName = `${name}${actionName}`;
  const repeatLabel = `${actionName} ${
    keepOriginalLabel ? label : capitalizeGlobal(label.toLowerCase())
  }`;

  const validate = useCallback(
    (repeatValue, values) => {
      const { [name]: mainValue } = values;
      const value = valueExtractor ? valueExtractor(values) : mainValue;

      return value && repeatValue !== value ? errorMessage : null;
    },
    [valueExtractor, errorMessage, name],
  );

  const onChange = useCallback(
    ({ target: { value } }) => {
      setNeedsConfirmation(!!value.length);
      change(repeatName, null);
    },
    [change, repeatName],
  );

  return (
    <Section
      template={[`${name} ${repeatName}`]}
      topBorder={topBorder}
      withoutGridGap={withoutGridGap}
    >
      <Field
        {...baseProps}
        name={name}
        label={label}
        component={SecretFieldInput}
        onChange={onChange}
        disabled={disabled}
        data-testid={name}
        hint={hint}
      />
      <Field
        {...baseProps}
        name={repeatName}
        label={repeatLabel}
        component={SecretFieldInput}
        validate={validate}
        field={needsConfirmation ? 'required' : props.field}
        alwaysVisible
        disabled={disabled || disableConfirmation}
        data-testid={repeatName}
        description={false}
      />
    </Section>
  );
}

FieldConfirmation.propTypes = {
  name: PropTypes.string.isRequired,
  change: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired,
  disableConfirmation: PropTypes.bool,
  hintClickable: PropTypes.bool,
  hint: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.any,
  }),
};

export { FieldConfirmation };
