import React, { Component } from 'react';

import { GroupButtons } from 'shared/components/Buttons';
import { GroupButton } from 'shared/components/Buttons/GroupButton/components/GroupButton';
import { FullPageList } from 'shared/components/FullPageList/FullPageList';
import { message } from 'shared/helpers/constants/hedges/createMarketHolidaysConstants';
import { HEDGES } from 'shared/helpers/constants/hedges/hedgesConstants';
import {
  itemsTemplate,
  itemsTemplateMobile,
  itemsDetailsTemplate,
} from 'shared/helpers/constants/hedges/marketHolidaysTableConfig';
import { deleteMarketHolidaysModal } from 'shared/helpers/constants/modalConstants';
import {
  successOptions,
  dangerOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

import { MarketHolidaysFilters } from './components/MarketHolidaysFilters/MarketHolidaysFilters';

class MarketHolidays extends Component {
  componentWillUnmount() {
    this.props.clearMarketHolidays();
  }

  onFiltersChanged = filters => {
    const { getMarketHolidays, marketHolidaysList } = this.props;
    getMarketHolidays({
      ...marketHolidaysList.filters,
      ...filters,
    });
  };

  fetchList = () => {
    const { marketHolidaysList, getMarketHolidays } = this.props;
    getMarketHolidays(marketHolidaysList.filters);
  };

  deleteMarketHoliday(marketHoliday) {
    const {
      marketHolidaysList,
      getMarketHolidays,
      deleteMarketHolidays,
      showSnackbar,
      marketAvailability,
    } = this.props;

    deleteMarketHolidays(marketHoliday)
      .then(() => {
        getMarketHolidays(marketHolidaysList.filters);
        showSnackbar(successOptions, message.MARKET_HOLIDAY_DELETE_SUCCESS);
        marketAvailability();
      })
      .catch(() => showSnackbar(dangerOptions, message.MARKET_HOLIDAY_DELETE_ERROR));
  }

  renderFilters = () => (
    <MarketHolidaysFilters
      filters={this.props.marketHolidaysList.filters}
      onFiltersChanged={this.onFiltersChanged}
    />
  );

  renderActionButtons = () => (
    <GroupButtons>
      <GroupButton
        access={this.props.auth.access.hedges.marketHolidays.manage}
        onClick={() => this.props.history.push('/hedges/market-holidays/create')}
        label={HEDGES.MARKET_HOLIDAYS.CREATE}
        icon="add-market-holiday"
      />
    </GroupButtons>
  );

  render() {
    const { marketHolidaysList, auth, openModal } = this.props;
    const actions = auth.access.hedges.marketHolidays.manage
      ? [
          {
            icon: 'bin',
            action: 'Delete',
            onClick: marketHoliday => () =>
              openModal(deleteMarketHolidaysModal, () => this.deleteMarketHoliday(marketHoliday)),
          },
        ]
      : [];

    return (
      <FullPageList
        title={`${HEDGES.MARKET_HOLIDAYS.TITLE}  ${marketHolidaysList.filters.year}`}
        actionButtons={this.renderActionButtons}
        itemsTemplate={itemsTemplate}
        itemsTemplateMobile={itemsTemplateMobile}
        itemsDetailsTemplate={itemsDetailsTemplate}
        actionsContext={this}
        actionsTemplate={actions}
        items={marketHolidaysList.marketHolidays}
        fetchList={this.fetchList}
        filterComponent={this.renderFilters}
        filterAccess
        isPending={marketHolidaysList.isPending}
        filters={marketHolidaysList.filters}
      />
    );
  }
}

export { MarketHolidays };
