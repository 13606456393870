import axios from 'axios';

import { PAYMENTS } from 'shared/helpers/constants/payments/paymentsConstants';
import { messageTypes } from 'shared/helpers/constants/snackbar/snackbarMessageTypes';

const API_HOST_AUTH = process.env.REACT_APP_API;

const paymentStatusChangeActionType = 'PAYMENT_STATUS_CHANGE';
const paymentStatusChangeAction = () => ({
  type: paymentStatusChangeActionType,
});

const paymentStatusChangeSuccessActionType = `${paymentStatusChangeActionType}_SUCCESS`;
const paymentStatusChangeSuccessAction = payment => ({
  type: paymentStatusChangeSuccessActionType,
  payload: { payment },
});

const paymentStatusChangeFailureActionType = `${paymentStatusChangeActionType}_FAILURE`;
const paymentStatusChangeFailureAction = () => ({
  type: paymentStatusChangeFailureActionType,
});

const paymentStatusChange = (id, status) => dispatch => {
  dispatch(paymentStatusChangeAction());

  return axios
    .put(`${API_HOST_AUTH}/payments/${id}`, JSON.stringify({ status }))
    .then(res => {
      dispatch(paymentStatusChangeSuccessAction(res.data.payment));

      return {
        message: res.data.message,
        messageType: messageTypes.success,
      };
    })
    .catch(error => {
      dispatch(paymentStatusChangeFailureAction());

      return Promise.reject({
        message: `${PAYMENTS.ERROR.CHANGE_STATUS_ERROR} ${error.data.message}`,
        messageType: messageTypes.error,
      });
    });
};

export {
  paymentStatusChange,
  paymentStatusChangeActionType,
  paymentStatusChangeSuccessActionType,
  paymentStatusChangeFailureActionType,
};
