import { resolveFilterDate, resolveFilterDateEnd } from 'shared/helpers/parsers/dateFilterParser';

function parseHedgesFilters(filters) {
  return {
    ...filters,
    deliveryDateStart: resolveFilterDate(filters.deliveryDateStart),
    deliveryDateEnd: resolveFilterDateEnd(filters.deliveryDateEnd),
    placedAtStart: resolveFilterDate(filters.placedAtStart),
    placedAtEnd: resolveFilterDateEnd(filters.placedAtEnd),
  };
}

export { parseHedgesFilters };
