import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Field, reduxForm } from 'redux-form';
import styled from 'styled-components';

import { FieldInput } from 'shared/components/Fields/index';
import { Form, Section } from 'shared/components/Form';
import { Preloader } from 'shared/components/Preloader/Preloader';
import { PACKAGES } from 'shared/helpers/constants/packages/packageConstants';
import { cssVar, media } from 'shared/helpers/styling/styling';
import { validate } from 'shared/helpers/validations/package/updatePackageWeightFormValidations';

import { CustomActions } from './CustomActions';

const StyledSection = styled(Section)`
  grid-template-columns: 1fr;
  padding: 30px 100px 40px;
  background-color: ${cssVar('outerSpaceTabs')};

  ${media.phone`
    padding: 15px 15px;
  `}
`;

const StyledForm = styled(Form)`
  min-height: auto;
  margin: 0;
`;

const StyledFieldInput = styled(FieldInput)`
  background-color: ${cssVar('outerSpaceTabs')};
`;

const UpdatePackageWeightFormBase = ({ onSubmit, isPending, invalid, submitting, lastStep }) => {
  const FormCustomActions = useCallback(
    () => (
      <CustomActions onSubmit={onSubmit} disabled={invalid || submitting} lastStep={lastStep} />
    ),
    [onSubmit, invalid, submitting],
  );

  return (
    <StyledForm
      header={PACKAGES.UPDATE_WEIGHT}
      onSubmit={onSubmit}
      CustomActions={FormCustomActions}
    >
      <StyledSection noPadding template={['updateWeightGrossActual', 'updateWeightTareActual']}>
        <Field
          pretendMobile
          name="updateWeightGrossActual"
          component={StyledFieldInput}
          type="text"
          label={PACKAGES.WEIGHT_GROSS_ACTUAL}
          field="required"
          suffix="lbs"
          data-testid="updateWeightGrossActual"
        />
        <Field
          pretendMobile
          name="updateWeightTareActual"
          component={StyledFieldInput}
          type="text"
          label={PACKAGES.WEIGHT_TARE_ACTUAL}
          field="required"
          suffix="lbs"
          data-testid="updateWeightTareActual"
        />
      </StyledSection>
      <Preloader loading={isPending} />
    </StyledForm>
  );
};

UpdatePackageWeightFormBase.propTypes = {
  onSubmit: PropTypes.func,
  isPending: PropTypes.bool,
  invalid: PropTypes.bool,
  submitting: PropTypes.bool,
  lastStep: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
};

const UpdatePackageWeightForm = reduxForm({
  form: 'UpdatePackageWeightForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
})(UpdatePackageWeightFormBase);

export { UpdatePackageWeightForm };
