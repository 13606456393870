import styled, { css } from 'styled-components';

const OverviewActionsCell = styled.div`
  ${({ gridArea }) => css`
    grid-area: ${gridArea};
  `};

  ${({ detailsList }) =>
    detailsList &&
    css`
      margin-top: 8px;
    `};

  display: flex;
  justify-content: flex-start;

  & > * + * {
    margin-left: 5px;
  }
`;

export { OverviewActionsCell };
