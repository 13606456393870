const CALENDAR = {
  // Long dashes - not to mistake with regular dash
  EMPTY: '— —',
  WEEKLY: 'Weekly',
  MONTHLY: 'Monthly',
  VIEW: 'View:',
  SHIPMENTS_CALENDAR: 'Shipments calendar',
  INCOMING_SHIPMENTS: 'Incoming shipments',
  OUTGOING_SHIPMENTS: 'Outgoing shipments',
  SELF_DELIVERY_REQUESTS: 'Self-delivery requests',
  ASSIGNED_LOTS: 'Assigned lots',
  FROM_COMPANY: 'From company',
  ACTIONS: 'Actions',
  SHOW_TODAY: 'Show today',
};

const CALENDAR_NOTE = {
  START_DATE: 'Start date',
  END_DATE: 'End date',
  NOTE: 'Note',
  NOTES: 'Notes',
  TITLE: 'Add calendar note',
  VALIDATION: {
    START_DATE_EMPTY: 'Start date cannot be empty.',
    START_DATE_TOO_HIGH: 'Start date cannot be after the end date.',
    END_DATE_EMPTY: 'End date cannot be empty.',
    END_DATE_TOO_HIGH: 'End date cannot be before the start date.',
    NOTE_CONTENTS_EMPTY: 'Note cannot be empty.',
    NOTE_CONTENTS_TOO_LONG: 'The note is too long.',
  },
  REMOVE_ONE: 'Remove from this date',
  REMOVE_ALL: 'Remove from all calendar dates',
  DURATION: 'Duration',
};

const views = {
  month: 'month',
  week: 'week',
};

const colors = {
  incomingShipments: 'shipCove',
  outgoingShipments: 'amethyst',
  selfDeliveryRequests: 'fountainBlue',
};

const eventTypes = {
  incomingShipments: 'incomingShipments',
  outgoingShipments: 'outgoingShipments',
  selfDeliveryRequests: 'selfDeliveryRequests',
  calendarNotes: 'calendarNotes',
};

export { CALENDAR, CALENDAR_NOTE, views, colors, eventTypes };
