import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { FormContainer } from 'shared/components/Form';
import { allowedStatusesForGlobal } from 'shared/helpers/constants/lots/statusesForGlobal';
import { normalizePackageObject } from 'shared/helpers/constants/packages/normalizePackage';
import { packageClasses } from 'shared/helpers/constants/packages/packageClasses';
import { PACKAGES } from 'shared/helpers/constants/packages/packageConstants';
import {
  dangerOptions,
  successOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { isGlobalRefiningGroup } from 'shared/helpers/matchers/isGlobalRefiningGroup';

import { goBackOrTo } from 'utils/history';
import { urlExtractor } from 'utils/urlExtractor';

import { ManagePackage } from 'pages/Packages/components/PackagesManagement/components/ManagePackage/ManagePackage';
import { configNames } from 'pages/Packages/components/PackagesManagement/components/PackageForm/components/PackageForm/fieldsConfig';

class UpdatePackageWeight extends Component {
  componentDidMount() {
    const {
      match: {
        params: { id },
      },
      packageDetails: { package: pkg },
    } = this.props;

    this.props
      .getGlobalCreatedPackage(id)
      .catch(() => goBackOrTo(`/lots/list/${pkg.assignedLot._id}`));
  }

  componentWillUnmount() {
    this.props.clearLotDetailsAction();
  }

  submit = values => {
    const { superAdminMode } = this.props;
    const { assignedLot, ...valuesToSend } = values;

    return this.props
      .updatePackageWeight({ ...valuesToSend, superAdminMode })
      .then(res => {
        goBackOrTo(urlExtractor(this.props.location.pathname, -2));
        this.props.showSnackbar(successOptions, res.message);
      })
      .catch(res => this.props.showSnackbar(dangerOptions, res.message));
  };

  setGlobalOrCustomerConfig = lot =>
    lot &&
    isGlobalRefiningGroup(lot.sentFromCompany && lot.sentFromCompany.companyName) &&
    allowedStatusesForGlobal.includes(lot.statusForGlobal)
      ? configNames.grgForGlobal
      : configNames.incoming;

  returnMatchingConfig = (pkg, lot) => {
    switch (pkg.packageClass) {
      case packageClasses.post_shears:
        return configNames.postShears;
      case packageClasses.post_mix:
        return configNames.postMix;
      case packageClasses.post_mill:
        return configNames.postMill;
      default:
        return this.setGlobalOrCustomerConfig(lot);
    }
  };

  render() {
    const companyName =
      this.props.lotDetails.lot && this.props.lotDetails.lot.sentFromCompany.companyName;

    const {
      lotDetails: { lot },
      packageDetails: { isPending, package: pkg },
    } = this.props;

    return (
      <FormContainer header={PACKAGES.UPDATE_WEIGHT} loaded={pkg && !isPending}>
        {() => (
          <ManagePackage
            companyName={companyName}
            config={this.returnMatchingConfig(pkg, lot)}
            header={PACKAGES.updateWeightTypedPackage(pkg.packageClass)}
            initialValues={normalizePackageObject(pkg)}
            onSubmit={this.submit}
            editMode
            updatePackageWeight
          />
        )}
      </FormContainer>
    );
  }
}

UpdatePackageWeight.propTypes = {
  clearLotDetailsAction: PropTypes.func,
  getGlobalCreatedPackage: PropTypes.func,
  history: PropTypes.object,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
    state: PropTypes.object,
    key: PropTypes.string,
  }),
  lotDetails: PropTypes.shape({
    isPending: PropTypes.bool,
    errorMessage: PropTypes.object,
    lot: PropTypes.object,
  }),
  match: PropTypes.shape({
    path: PropTypes.string,
    url: PropTypes.string,
    isExact: PropTypes.bool,
    params: PropTypes.object,
  }),
  packageDetails: PropTypes.shape({
    isPending: PropTypes.bool,
    errorMessage: PropTypes.oneOf([PropTypes.object, null]),
    package: PropTypes.object,
    recentlyCreated: PropTypes.bool,
  }),
  showSnackbar: PropTypes.func,
  superAdminMode: PropTypes.bool,
  updatePackageWeight: PropTypes.func,
};

export { UpdatePackageWeight };
