import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

function hashLinkScroll() {
  const { hash } = window.location;

  if (!hash) return;

  setTimeout(() => {
    const element = document.querySelector(hash);
    if (element) element.scrollIntoView();
  }, 0);
}

let didNavigationOccurred = false;

history.listen(() => {
  hashLinkScroll();
  didNavigationOccurred = true;
});

const goBackOrTo = (url = '/') =>
  didNavigationOccurred ? (history.goBack(), true) : (history.push(url), false);

export { history, goBackOrTo };
