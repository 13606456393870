import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getLocationActionType = 'GET_LOCATION';
function getLocationAction() {
  return {
    type: getLocationActionType,
  };
}

const getLocationSuccessActionType = 'GET_LOCATION_SUCCESS';
function getLocationSuccessAction(location) {
  return {
    type: getLocationSuccessActionType,
    payload: {
      location,
    },
  };
}

const getLocationFailureActionType = 'GET_LOCATION_FAILURE';
function getLocationFailureAction(errorMessage) {
  return {
    type: getLocationFailureActionType,
    payload: {
      errorMessage,
    },
  };
}

const clearLocationDetailsActionType = 'CLEAR_LOCATION_DETAILS_ACTION';
function clearLocationDetailsAction() {
  return {
    type: clearLocationDetailsActionType,
  };
}

function getLocation(locationId) {
  return dispatch => {
    dispatch(getLocationAction());

    return axios
      .get(`${API_HOST_AUTH}/locations/${locationId}`)
      .then(location => {
        dispatch(getLocationSuccessAction(location.data));
        return location.data;
      })
      .catch(err => {
        dispatch(getLocationFailureAction(err));

        return Promise.reject(err.data);
      });
  };
}

function clearLocationDetails() {
  return dispatch => {
    dispatch(clearLocationDetailsAction());
  };
}

export {
  getLocationActionType,
  getLocationSuccessActionType,
  getLocationFailureActionType,
  clearLocationDetailsActionType,
  getLocation,
  clearLocationDetails,
};
