import dateAdapter from 'utils/date/dateAdapter';

const normalizeShipment = (shipment, additionalPackages = []) => ({
  estDeliveryDate: dateAdapter(shipment.estDeliveryDate).toDate(),
  estPickupDate: dateAdapter(shipment.estPickupDate).toDate(),
  packages: [...shipment.packages, ...additionalPackages],
  sentFromCompanyLocation: {
    value: shipment.sentFromCompanyLocation._id,
    label: shipment.sentFromCompanyLocation.name,
  },
  shipmentCompany: shipment.shipmentCompany,
  shipmentSeal: shipment.shipmentSeal,
});

const normalizeIncomingShipment = (shipment, additionalPackages = []) => ({
  ...normalizeShipment(shipment, additionalPackages),
  sentFromCompany: {
    value: shipment.sentFromCompany._id,
    label: shipment.sentFromCompany.companyName,
    companyType: shipment.sentFromCompany.companyType,
  },
});

export { normalizeShipment, normalizeIncomingShipment };
