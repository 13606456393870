import { css } from 'styled-components';

export const PrintStyles = css`
  @media print {
    * {
      color: black !important;
      background: none !important;
      box-shadow: none !important;
    }

    .container {
      margin: 0;
      max-width: 100%;
    }

    i.icon {
      display: none;
    }

    body {
      zoom: 60%;
    }
  }

  @page {
    margin: 24mm 16mm;
  }
`;
