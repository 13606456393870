import { packageType } from 'shared/helpers/constants/packages/packageType';
import { packageDateFormat } from 'shared/helpers/parsers/date';
import { formatDate } from 'shared/helpers/parsers/formaters';
import { humanize } from 'shared/helpers/parsers/text';

const objToQueryString = obj =>
  Object.entries(obj)
    .map(
      ([key, value]) =>
        `${key}=${
          value
            ? encodeURIComponent(value)
            : typeof value === 'boolean'
            ? encodeURIComponent(value)
            : ''
        }`,
    )
    .join('&');

const labelsPrintUrl = `${process.env.REACT_APP_HOST}/labels-print`;
const labelsPrintUrlEbs = `${process.env.REACT_APP_HOST}/ebs-labels-print`;

const getLabelObject = pkg => ({
  lot: pkg.assignedLot.grgLotNumber,
  grgLotNumber: pkg.assignedLot.grgLotNumber,
  customLotNumber: pkg.assignedLot.customLotNumber,
  materialCountRequired: pkg.assignedLot.sentFromCompany.materialCountRequired,
  designation: pkg.assignedLot.designation,
  labelId: pkg.labelId,
  plant: pkg.plant,
  weightGrossDeclared: pkg.weightGrossDeclared,
  location: pkg.assignedLot.sentFromCompanyLocation?.name,
  units: pkg.unitCount,
  pkg: pkg.shortenedId,
  company: pkg.companyName,
  time: formatDate(Date.now(), packageDateFormat),
  material: humanize(pkg.materialTypeDeclared),
  pkgNum: pkg.packageNumber,
  pkgCount: '',
  mark: ['heavies', 'fines'].includes(pkg.materialClass) ? pkg.materialClass.toUpperCase()[0] : '',
  lotNote: pkg.assignedLot.customerInstructions,
  pkgNote: pkg.customerInstructions,
  labCats: pkg.packageType === packageType.sample ? 'LAB CATS' : '',
  type: pkg.packageType === packageType.super_sack ? 'label_notes' : 'label',
});

const printLabelUrl = pkg => `${labelsPrintUrl}?${objToQueryString(getLabelObject(pkg))}`;

const printLabelsUrl = pkgs => {
  let counter = -1;
  const urlData =
    pkgs &&
    pkgs
      .filter(p => p && (p._id || p.id))
      .reduce(
        (prev, pkg) => ({
          ...prev,
          ...Object.entries(getLabelObject(pkg)).reduce(
            (counter++,
            (indexed, [key, value]) => ({
              ...indexed,
              [`${key}${counter}`]: value,
            })),
            {},
          ),
          ...(pkg.assignedLot.sentFromCompany.materialCountRequired &&
            (pkg.customerInstructions || pkg.assignedLot.customerInstructions) &&
            pkg.packageType !== 'super_sack' &&
            Object.entries(getCustomerInstructionsObject(pkg)).reduce(
              (counter++,
              (indexed, [key, value]) => ({
                ...indexed,
                [`${key}${counter}`]: value,
              })),
              {},
            )),
        }),
        {},
      );

  return `${labelsPrintUrl}?${objToQueryString(urlData)}`;
};

const printLabelEbsUrl = pkg => {
  const lot = pkg.assignedLot?.grgLotNumber;
  const tare = pkg.weightTareDeclared ?? pkg.weightTareActual ?? '';
  const gross = pkg.weightGrossDeclared ?? pkg.weightGrossActual ?? '';
  return `${labelsPrintUrlEbs}?lot=${lot}&tare=${tare}&gross=${gross}`;
};

const printLotLabelUrl = lot => {
  const urlData = {
    lot: lot.grgLotNumber,
    type: 'lot',
  };

  return `${labelsPrintUrl}?${objToQueryString(urlData)}`;
};

const printLotLabelEbsUrl = lot => {
  const lotNumber = lot?.grgLotNumber;
  const tare = lot.weightTareDeclared ?? lot.weightTareActual ?? '';
  const gross = lot.weightGrossDeclared ?? lot.weightGrossActual ?? '';

  return `${labelsPrintUrlEbs}?${objToQueryString({ lot: lotNumber, tare, gross })}`;
};

const getCustomerInstructionsObject = pkg => ({
  lot: pkg.assignedLot.grgLotNumber,
  company: pkg.companyName,
  material: humanize(pkg.materialTypeDeclared),
  labelId: pkg.labelId,
  plant: pkg.plant,
  weightGrossDeclared: pkg.weightGrossDeclared,
  units: pkg.unitCount,
  materialCountRequired: pkg.assignedLot.sentFromCompany.materialCountRequired,
  lotNote: pkg.assignedLot.customerInstructions,
  pkgNote: pkg.customerInstructions,
  time: formatDate(Date.now(), packageDateFormat),
  location: pkg.assignedLot.sentFromCompanyLocation?.name,
  type: 'note',
});

const printCustomerInstructionsUrl = pkg =>
  `${labelsPrintUrl}?${objToQueryString(getCustomerInstructionsObject(pkg))}`;

export {
  printLabelUrl,
  printLabelsUrl,
  printLotLabelUrl,
  printCustomerInstructionsUrl,
  printLabelEbsUrl,
  printLotLabelEbsUrl,
};
