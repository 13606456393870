import { connect } from 'react-redux';

import { getAgreement, clearAgreementsDetails } from 'actions/Agreements/agreementsDetails';
import { downloadApiAttachment } from 'actions/Attachments/downloadAttachment';
import { showSnackbar } from 'actions/shared/snackbar';

import { AgreementsDetails } from './AgreementsDetails';

const mapStateToProps = ({
  auth,
  router,
  agreementsDetails,
  agreementsUploadDocument,
  downloadAttachmentStorage,
}) => ({
  auth,
  router,
  agreementsDetails,
  agreementsUploadDocument,
  downloadAttachmentStorage,
});

const mapDispatchToProps = {
  getAgreement,
  clearAgreementsDetails,
  downloadApiAttachment,
  showSnackbar,
};
const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(AgreementsDetails);

export { connectedComponent as ConnectedAgreementsDetails };
