import axios from 'axios';

import { PAYMENTS } from 'shared/helpers/constants/payments/paymentsConstants';
import { messageTypes } from 'shared/helpers/constants/snackbar/snackbarMessageTypes';
import { isOwnerUser } from 'shared/helpers/matchers/checkUserType';

const API_HOST_AUTH = process.env.REACT_APP_API;

const deletePaymentActionType = 'DELETE_PAYMENT';
const deletePaymentAction = () => ({
  type: deletePaymentActionType,
});

const deletePaymentSuccessActionType = `${deletePaymentActionType}_SUCCESS`;
const deletePaymentSuccessAction = () => ({
  type: deletePaymentSuccessActionType,
});

const deletePaymentFailureActionType = `${deletePaymentActionType}_FAILURE`;
const deletePaymentFailureAction = () => ({
  type: deletePaymentFailureActionType,
});

const deletePayment = (id, userType) => dispatch => {
  dispatch(deletePaymentAction());

  return axios
    .delete(`${API_HOST_AUTH}/payments/${id}`)
    .then(res => {
      dispatch(deletePaymentSuccessAction());

      return {
        message: res.data.message,
        messageType: messageTypes.success,
      };
    })
    .catch(error => {
      dispatch(deletePaymentFailureAction());

      return Promise.reject({
        message: `${
          isOwnerUser(userType)
            ? PAYMENTS.ERROR.PAYMENT_DELETE_ERROR
            : PAYMENTS.ERROR.PAYMENT_REQUEST_DELETE_ERROR
        } ${error.data.message}`,
        messageType: messageTypes.error,
      });
    });
};

export {
  deletePayment,
  deletePaymentActionType,
  deletePaymentSuccessActionType,
  deletePaymentFailureActionType,
};
