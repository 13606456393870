import React from 'react';
import { FormSection, reduxForm } from 'redux-form';

import { Button } from 'shared/components/Buttons/Button';
import { Icon } from 'shared/components/Icon/Icon';
import { cancelModal } from 'shared/helpers/constants/modalConstants';
import { SHARED } from 'shared/helpers/constants/sharedConstants';
import { USER } from 'shared/helpers/constants/users/userConstants';
import { snackbarOptions } from 'shared/helpers/matchers/snackbar/snackbarOptions';
import { camelToSentence, toKebabCase } from 'shared/helpers/parsers/text';
import { cssVar } from 'shared/helpers/styling/styling';

import { configNames } from 'pages/Packages/components/PackagesManagement/components/PackageForm/components/PackageForm/fieldsConfig';

import {
  PreferencesField,
  PreferencesWrapper,
  PreferencesHeader,
  PreferencesFooter,
} from '../components';

function NotificationPreferences(props) {
  const submit = values =>
    props.updateNotificationPreferences(values, props.user._id).then(res => {
      props.showSnackbar(snackbarOptions(res.messageType), res.message);
      props.getUser(props.user._id);
    });

  const reset = () => props.openModal(cancelModal, props.reset);

  const parseFieldLabel = name => {
    const kebabCaseLabels = [configNames.postMix];

    const parsedLabel = camelToSentence(name);
    return kebabCaseLabels.includes(name) ? toKebabCase(parsedLabel) : parsedLabel;
  };

  const renderPreferencesField = () => {
    const fieldsNames = Object.keys(props.user.notificationPreferences);

    return fieldsNames.map(name => (
      <FormSection name={name} key={`section-${name}`}>
        <PreferencesField label={parseFieldLabel(name)} groupName={name} key={name} />
      </FormSection>
    ));
  };

  return (
    <PreferencesWrapper>
      <form onSubmit={props.handleSubmit(submit)} noValidate>
        <PreferencesHeader>
          <h2>{USER.NOTIFICATION_PREFERENCES}</h2>
          <div>{SHARED.MODULES}</div>
          <div>{SHARED.EMAIL}</div>
          <div>{SHARED.SMS}</div>
          <div>{SHARED.PORTAL}</div>
        </PreferencesHeader>
        {renderPreferencesField()}
        <PreferencesFooter>
          <Button
            id="resetPreferences"
            onClick={reset}
            outlineColor={cssVar('roman')}
            disabled={!props.dirty}
          >
            {SHARED.RESET}
            <Icon icon="icon-cancel" />
          </Button>
          <Button type="submit" outline disabled={!props.dirty}>
            {SHARED.SAVE_BUTTON}
            <Icon icon="icon-tick" />
          </Button>
        </PreferencesFooter>
      </form>
    </PreferencesWrapper>
  );
}

const connectedForm = reduxForm({
  form: 'NotificationPreferences',
  enableReinitialize: true,
})(NotificationPreferences);

export {
  connectedForm as NotificationPreferences,
  NotificationPreferences as NotificationPreferencesTest,
};
