import styled from 'styled-components';

import { media } from 'shared/helpers/styling/styling';

export const StyledNotesPanelWrapper = styled.div`
  .NotesPanel {
    margin-top: 20px;

    &-list {
      margin-top: 0.8rem;
    }

    &-mainPanel {
      display: flex;
      justify-content: space-between;

      &-buttons {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }

      h3 {
        color: var(--gullGray);
      }
    }

    ${media.phone`
  &-list {
    margin-top: 15px;

    &-routerButton {
      display: flex;
      justify-content: flex-end;
    }
  }

  &-mainPanel {
    h3 {
      font-size: 1.25em;
    }
  }
  `}

    ${media.tablet`
  &-list {
    margin-top: 15px;

    &-routerButton {
      display: flex;
      justify-content: flex-end;
    }
  }

  &-mainPanel {
    h3 {
      font-size: 1.25em;
    }
  }
  `}
  }
`;
