import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { Section } from 'shared/components/Form';
import { FieldTextarea, FieldAttachment } from 'shared/components/Formik/Fields';
import { Form } from 'shared/components/Formik/Form';
import { FILES_CONSTANTS } from 'shared/helpers/constants/filesConstants';
import { cancelModal } from 'shared/helpers/constants/modalConstants';
import { NOTE } from 'shared/helpers/constants/noteConstants';
import { checkTrim } from 'shared/helpers/parsers/text';

import { goBackOrTo } from 'utils/history';

import { CreateNoteFormYup } from './CreateNoteFormYup';

const NoteForm = ({
  noteValue,
  checkNotes,
  openModal,
  relatedType,
  onSubmit,
  createNoteStorage,
  blockSubmit,
  ...props
}) => {
  useEffect(() => {
    checkNotes(noteValue);
  }, []);

  const modalOpen = () =>
    openModal(cancelModal, () => {
      goBackOrTo(`/${relatedType}`);
    });

  const {
    values,
    isValid,
    isSubmitting,
    touched,
    dirty,
    handleBlur,
    getFieldMeta,
    handleSubmit,
    handleChange,
    setFieldTouched,
  } = useFormik({
    initialValues: { note: '' },
    validationSchema: CreateNoteFormYup,
    onSubmit: values => onSubmit(values),
  });

  const getErrors = name => Object.keys(touched).includes(name) && getFieldMeta(name).error;

  return (
    <Form
      onSubmit={handleSubmit}
      onCancel={modalOpen}
      submitDisabled={!isValid || isSubmitting || !dirty || blockSubmit}
      header={NOTE.NOTE}
      loaded={!createNoteStorage.isPending}
    >
      <Section template={['note note', 'attachment .']}>
        <FieldTextarea
          name="note"
          label={NOTE.NOTE}
          field="required"
          value={values.note}
          onChange={handleChange}
          onBlur={handleBlur}
          error={getErrors('note')}
          onInput={() => setFieldTouched('note')}
        />
        {values.note && checkTrim(values.note) && (
          <FieldAttachment
            {...props}
            id="file"
            name="attachment"
            label={FILES_CONSTANTS.ATTACHMENTS}
            multiple
          />
        )}
      </Section>
    </Form>
  );
};

NoteForm.propTypes = {
  checkNotes: PropTypes.func,
  openModal: PropTypes.func,
  relatedType: PropTypes.string,
  onSubmit: PropTypes.func,
  createNoteStorage: PropTypes.shape({
    isPending: PropTypes.bool,
  }),
  addFile: PropTypes.func,
  attachment: PropTypes.shape({
    files: PropTypes.array,
    invalidFiles: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  }),
  blockSubmit: PropTypes.bool,
  createNote: PropTypes.func,
  filesError: PropTypes.func,
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
  removeFile: PropTypes.func,
  showSnackbar: PropTypes.func,
  submitLabel: PropTypes.string,
};

export { NoteForm as ConnectedNoteForm };
