import axios from 'axios';

import { messageTypes } from 'shared/helpers/constants/snackbar/snackbarMessageTypes';

const API_HOST_AUTH = process.env.REACT_APP_API;

const approveTermsActionType = 'APPROVE_TERMS';
function approveTermsAction() {
  return {
    type: approveTermsActionType,
  };
}

const approveTermsSuccessActionType = 'APPROVE_TERMS_SUCCESS';
function approveTermsSuccessAction(terms) {
  return {
    type: approveTermsSuccessActionType,
    payload: { terms },
  };
}

const approveTermsFailureActionType = 'APPROVE_TERMS_FAILURE';
function approveTermsFailureAction() {
  return {
    type: approveTermsFailureActionType,
  };
}

function approveTerms(term, approve) {
  return dispatch => {
    dispatch(approveTermsAction());

    return axios({
      method: 'post',
      url: `${API_HOST_AUTH}/terms/${term._id}/approveTerms`,
      data: JSON.stringify({ approve }),
    })
      .then(res => {
        dispatch(approveTermsSuccessAction(res.data.terms));
        return {
          message: res.data.message,
          messageType: messageTypes.success,
        };
      })
      .catch(error => {
        dispatch(approveTermsFailureAction());
        return {
          message: error.data.message,
          messageType: messageTypes.error,
        };
      });
  };
}

export {
  approveTerms,
  approveTermsActionType,
  approveTermsSuccessActionType,
  approveTermsFailureActionType,
};
