import axios from 'axios';

import { SELF_DELIVERY_REQUESTS } from 'shared/helpers/constants/selfDeliveryRequests/selfDeliveryRequestsConstants';
import { messageTypes } from 'shared/helpers/constants/snackbar/snackbarMessageTypes';

const API_HOST_AUTH = process.env.REACT_APP_API;

const selfDeliveryRequestStatusChangeActionType = 'SELF_DELIVERY_REQUEST_STATUS_CHANGE';
const selfDeliveryRequestStatusChangeAction = () => ({
  type: selfDeliveryRequestStatusChangeActionType,
});

const selfDeliveryRequestStatusChangeSuccessActionType = `${selfDeliveryRequestStatusChangeActionType}_SUCCESS`;
const selfDeliveryRequestStatusChangeSuccessAction = selfDeliveryRequest => ({
  type: selfDeliveryRequestStatusChangeSuccessActionType,
  payload: { selfDeliveryRequest },
});

const selfDeliveryRequestStatusChangeFailureActionType = `${selfDeliveryRequestStatusChangeActionType}_FAILURE`;
const selfDeliveryRequestStatusChangeFailureAction = () => ({
  type: selfDeliveryRequestStatusChangeFailureActionType,
});

const selfDeliveryRequestStatusChange = (id, status) => dispatch => {
  dispatch(selfDeliveryRequestStatusChangeAction());

  return axios
    .put(`${API_HOST_AUTH}/self-delivery-requests/${id}`, JSON.stringify({ status }))
    .then(res => {
      dispatch(selfDeliveryRequestStatusChangeSuccessAction(res.data.selfDeliveryRequest));

      return {
        message: res.data.message,
        messageType: messageTypes.success,
      };
    })
    .catch(error => {
      dispatch(selfDeliveryRequestStatusChangeFailureAction());

      return Promise.reject({
        message: `${SELF_DELIVERY_REQUESTS.ERROR.CHANGE_STATUS_ERROR} ${error.data.message}`,
        messageType: messageTypes.error,
      });
    });
};

export {
  selfDeliveryRequestStatusChange,
  selfDeliveryRequestStatusChangeActionType,
  selfDeliveryRequestStatusChangeSuccessActionType,
  selfDeliveryRequestStatusChangeFailureActionType,
};
