import axios from 'axios';

import { parsePoolAccountJournalFilters } from 'shared/helpers/dataParsers/poolAccounts/parsePoolAccountJournalFilters';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getPoolAccountJournalActionType = 'GET_POOL_ACCOUNT_JOURNAL';
const getPoolAccountJournalAction = (filters, pagination) => ({
  type: getPoolAccountJournalActionType,
  payload: { filters, pagination },
});

const getPoolAccountJournalSuccessActionType = `${getPoolAccountJournalActionType}_SUCCESS`;
const getPoolAccountJournalSuccessAction = journalEntries => ({
  type: getPoolAccountJournalSuccessActionType,
  payload: { journalEntries },
});

const getPoolAccountJournalFailureActionType = `${getPoolAccountJournalActionType}_FAILURE`;
const getPoolAccountJournalFailureAction = () => ({
  type: getPoolAccountJournalFailureActionType,
});

const clearPoolAccountJournalActionType = 'CLEAR_POOL_ACCOUNT_JOURNAL';
const clearPoolAccountJournal = () => ({
  type: clearPoolAccountJournalActionType,
});

const getPoolAccountJournal = (filters, pagination) => dispatch => {
  const parsedFilters = parsePoolAccountJournalFilters(filters);

  dispatch(getPoolAccountJournalAction(parsedFilters, pagination));

  return axios
    .post(`${API_HOST_AUTH}/journal-entries/list`, {
      filters: {
        ...parsedFilters,
        relatedCompany: filters.relatedCompany && filters.relatedCompany._id,
      },
      pagination,
    })
    .then(({ data }) => {
      dispatch(getPoolAccountJournalSuccessAction(data));
      return data;
    })
    .catch(error => {
      dispatch(getPoolAccountJournalFailureAction());
      return Promise.reject(error.data);
    });
};

export {
  clearPoolAccountJournal,
  clearPoolAccountJournalActionType,
  getPoolAccountJournalActionType,
  getPoolAccountJournalSuccessActionType,
  getPoolAccountJournalFailureActionType,
  getPoolAccountJournal,
};
