import {
  releasePackageType,
  releasePackageSuccessType,
  releasePackageFailureType,
} from 'actions/Packages/releasePackage';

const INITIAL_STATE = {
  isPending: false,
};

const releasePackageReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case releasePackageType:
      return {
        isPending: true,
      };
    case releasePackageSuccessType:
    case releasePackageFailureType:
      return {
        isPending: false,
      };
    default:
      return state;
  }
};

export { releasePackageReducer };
