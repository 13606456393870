import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';

import { FieldHint } from 'shared/components/Fields';
import { FieldButtonSelect } from 'shared/components/Fields/FieldButtonSelect/FieldButtonSelect';
import { FieldInput } from 'shared/components/Fields/FieldInput/FieldInput';
import { FieldSelect } from 'shared/components/Fields/FieldSelect/FieldSelect';
import { Form, Section } from 'shared/components/Form';
import { cancelModal } from 'shared/helpers/constants/modalConstants';
import { availableFundsTypes } from 'shared/helpers/constants/payments/fundsTypes';
import { PAYMENTS } from 'shared/helpers/constants/payments/paymentsConstants';
import { parseLotsSelectData } from 'shared/helpers/dataParsers/lot/parseLotsSelectData';
import { isHedgeNotRequired } from 'shared/helpers/matchers/companies/checkExceptionType';
import { mapArray } from 'shared/helpers/parsers/select';
import { insertCommas } from 'shared/helpers/parsers/text';
import { validate } from 'shared/helpers/validations/payments/requestPaymentValidation';

import { history } from 'utils/history';

class RequestAdvancePaymentFormLayout extends Component {
  state = {
    relatedLots: [],
    paymentAmount: null,
  };

  componentDidMount() {
    this.props.getLotsForAdvancePayment();
  }

  componentWillUnmount() {
    this.props.clearPaymentAmount();
    this.props.clearAssignedLotsAction();
  }

  getPaymentAmount = lot => {
    const lotValue = lot && lot.value;
    this.props.resetSection('paymentAmount');
    lotValue &&
      this.props.getPaymentAmount(lotValue).then(data => {
        this.setState({ paymentAmount: data });
        this.props.touch('paymentAmount');
        this.props.change('paymentAmount', data && insertCommas(String(data.availableAmount)));
      });
  };

  get isPaymentExceptionCompany() {
    return this.state.paymentAmount && !!this.state.paymentAmount.paymentExceptionCompany;
  }

  modalOpen = () => {
    this.props.openModal(cancelModal, () => history.push('/payments'));
  };

  render() {
    const { handleSubmit, invalid, pristine, relatedLot, assignedLots } = this.props;
    const { paymentAmount } = this.state;
    const exceptionTypes = paymentAmount?.paymentExceptionCompany?.exceptionTypes || [];

    return (
      <Form
        header={PAYMENTS.PAYMENT}
        onSubmit={handleSubmit}
        onCancel={this.modalOpen}
        submitDisabled={invalid || pristine}
      >
        <Section template={['relatedLot fundsType', 'paymentAmount additionalInfo']}>
          <Field
            name="relatedLot"
            component={FieldSelect}
            options={parseLotsSelectData(assignedLots?.data)}
            label={PAYMENTS.RELATED_LOT}
            field="required"
            onChange={this.getPaymentAmount}
          />
          <Field
            name="fundsType"
            component={FieldButtonSelect}
            options={mapArray(availableFundsTypes.assayInternal)}
            label={PAYMENTS.FUNDS_TYPE}
            field="required"
          />
          <Field
            name="paymentAmount"
            component={FieldHint}
            disabled={!paymentAmount}
            label={PAYMENTS.PAYMENT_AMOUNT}
            field="required"
            type="text"
            prefix="$"
            hint={this.isPaymentExceptionCompany ? PAYMENTS.SUGGESTED : PAYMENTS.MAXIMUM}
            suggestion={
              paymentAmount &&
              relatedLot &&
              !isHedgeNotRequired(exceptionTypes) &&
              insertCommas(paymentAmount.availableAmount.toString())
            }
          />
          <Field
            name="additionalInfo"
            component={FieldInput}
            label={PAYMENTS.ADDITIONAL_INFORMATION}
          />
        </Section>
      </Form>
    );
  }
}

const selector = formValueSelector('RequestPaymentForm');

const RequestAdvancePaymentForm = compose(
  reduxForm({
    form: 'RequestPaymentForm',
    validate,
  }),
  connect(state => ({ relatedLot: selector(state, 'relatedLot') })),
)(RequestAdvancePaymentFormLayout);

export { RequestAdvancePaymentForm, RequestAdvancePaymentFormLayout };
