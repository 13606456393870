import styled from 'styled-components';

import { pxToRem } from 'shared/helpers/styling/styling';

const StyledHintContainer = styled.div`
  grid-area: hint;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  top: ${pxToRem(-14)};
`;

export { StyledHintContainer };
