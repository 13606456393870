import PropTypes from 'prop-types';
import * as React from 'react';
import styled, { css } from 'styled-components';

import { HEDGES } from 'shared/helpers/constants/hedges/hedgesConstants';
import { HISTORY_HEDGES } from 'shared/helpers/constants/hedges/historyHedgesConstants';
import { dateFormat } from 'shared/helpers/parsers/date';
import { roundUp, safeParseFloat } from 'shared/helpers/parsers/number';
import { cssVar, pxToRem, media } from 'shared/helpers/styling/styling';

import dateAdapter from 'utils/date/dateAdapter';

const StyledHistoryHeaderContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 0.3fr 1fr;
  color: ${cssVar('alabaster')};
  font-size: ${pxToRem(12)};
  border-right: none;

  ${media.phone`
    grid-template-columns: 1fr;
  `}
`;

const StyledHedgeNumber = styled.div`
  grid-row-start: 1;
  grid-row-end: 3;
  padding: ${pxToRem(10)};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${cssVar('outerSpaceBitLighter')};
  font-weight: bold;
`;

const StyledItem = styled.div`
  text-align: center;
  padding: ${pxToRem(5)};
  border-left: 1px solid rgba(255, 255, 255, 0.05);
  font-weight: ${({ weightBold }) => weightBold && 'bold'};

  &:first-of-type {
    border-left: none;
  }

  ${media.phone`
    ${({ mobileBorderBottom }) =>
      mobileBorderBottom &&
      css`
        border-bottom: 1px solid rgba(255, 255, 255, 0.05);
      `};
  `}
`;

const StyledRow = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  border-bottom: ${({ borderBottom }) => borderBottom && '1px solid rgba(255,255,255,0.05)'};
  font-weight: ${({ weightBold }) => weightBold && 'bold'};

  ${StyledItem} {
    &:first-of-type {
      border-left: 1px solid rgba(255, 255, 255, 0.05);
    }
  }
`;

const setInitialHedgeOunces = (relatedHedge, deliveriesList) => {
  const hedgeOunces = {
    platinumOz: +safeParseFloat(relatedHedge.platinumOz) || 0,
    palladiumOz: +safeParseFloat(relatedHedge.palladiumOz) || 0,
    rhodiumOz: +safeParseFloat(relatedHedge.rhodiumOz) || 0,
  };

  const initialHedgeOunces = deliveriesList.reduce(
    (acc, delivery) => ({
      palladiumOz: acc.palladiumOz + +safeParseFloat(delivery.palladiumOz),
      platinumOz: acc.platinumOz + +safeParseFloat(delivery.platinumOz),
      rhodiumOz: acc.rhodiumOz + +safeParseFloat(delivery.rhodiumOz),
    }),
    { ...hedgeOunces },
  );

  return {
    initialPalladium: +roundUp(initialHedgeOunces.palladiumOz, 3),
    initialPlatinum: +roundUp(initialHedgeOunces.platinumOz, 3),
    initialRhodium: +roundUp(initialHedgeOunces.rhodiumOz, 3),
  };
};

const HistoryHeader = ({ relatedHedge, deliveriesList }) => {
  const { hedgeName, placedAt } = relatedHedge;

  return (
    <>
      <StyledHistoryHeaderContainer>
        <StyledHedgeNumber>{hedgeName}</StyledHedgeNumber>
        <StyledRow borderBottom weightBold>
          <StyledItem>{HEDGES.PLACED_ON}</StyledItem>
          <StyledItem>{HISTORY_HEDGES.PT_HEDGE}</StyledItem>
          <StyledItem>{HISTORY_HEDGES.PD_HEDGE}</StyledItem>
          <StyledItem>{HISTORY_HEDGES.RH_HEDGE}</StyledItem>
        </StyledRow>
        <StyledRow>
          <StyledItem>{dateAdapter(placedAt).format(dateFormat)}</StyledItem>
          <StyledItem>
            {setInitialHedgeOunces(relatedHedge, deliveriesList).initialPlatinum?.toFixed(3)}
          </StyledItem>
          <StyledItem>
            {setInitialHedgeOunces(relatedHedge, deliveriesList).initialPalladium?.toFixed(3)}
          </StyledItem>
          <StyledItem>
            {setInitialHedgeOunces(relatedHedge, deliveriesList).initialRhodium?.toFixed(3)}
          </StyledItem>
        </StyledRow>
      </StyledHistoryHeaderContainer>
      <StyledHistoryHeaderContainer>
        <StyledItem mobileBorderBottom weightBold>
          {HISTORY_HEDGES.LOT_APPLIED_TO}
        </StyledItem>
        <StyledRow weightBold>
          <StyledItem>{HISTORY_HEDGES.HEDGE_APPLIED_ON}</StyledItem>
          <StyledItem>{HEDGES.PT}</StyledItem>
          <StyledItem>{HEDGES.PD}</StyledItem>
          <StyledItem>{HEDGES.RH}</StyledItem>
        </StyledRow>
      </StyledHistoryHeaderContainer>
    </>
  );
};

HistoryHeader.defaultProps = {
  deliveriesList: [],
};

HistoryHeader.propTypes = {
  deliveriesList: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      createdAt: PropTypes.number,
      createdBy: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
      }),
      relatedLot: PropTypes.shape({
        _id: PropTypes.string,
        grgLotNumber: PropTypes.string,
      }),
      palladiumOz: PropTypes.number,
      platinumOz: PropTypes.number,
      rhodium: PropTypes.number,
    }),
  ),
  relatedHedge: PropTypes.shape({
    hedgeName: PropTypes.string,
    palladiumOz: PropTypes.number,
    placedAt: PropTypes.number,
    platiniumOz: PropTypes.number,
    relatedCompany: PropTypes.string,
    rhodiumOz: PropTypes.number,
    _id: PropTypes.string,
  }),
};

export { HistoryHeader, setInitialHedgeOunces };
