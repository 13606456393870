import { REQUEST_MARKET_INDICATORS } from 'shared/helpers/constants/systemSettings/marketIndicators/requestMarketIndicators';

function validate({ type }) {
  if (!type || !type.length) {
    return {
      type: REQUEST_MARKET_INDICATORS.TYPE_NOT_SELECTED,
    };
  }
}

export { validate };
