import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getDeliveriesListActionType = 'GET_DELIVERIES_LIST';
const getDeliveriesListAction = () => ({
  type: getDeliveriesListActionType,
});

const getDeliveriesListSuccessActionType = `${getDeliveriesListActionType}_SUCCESS`;
const getDeliveriesListSuccessAction = data => ({
  type: getDeliveriesListSuccessActionType,
  payload: {
    data,
  },
});

const getDeliveriesListFailureActionType = `${getDeliveriesListActionType}_FAILURE`;
const getDeliveriesListFailureAction = () => ({
  type: getDeliveriesListFailureActionType,
});

const clearDeliveriesListActionType = 'CLEAR_DELIVERIES_LIST';
const clearDeliveriesList = () => ({
  type: clearDeliveriesListActionType,
});

const getDeliveriesList = (hedgeId, limit) => dispatch => {
  dispatch(getDeliveriesListAction());

  return axios
    .get(`${API_HOST_AUTH}/deliveries/list/${hedgeId}/${limit}`)
    .then(({ data }) => dispatch(getDeliveriesListSuccessAction(data)))
    .catch(err => {
      dispatch(getDeliveriesListFailureAction());
      return Promise.reject(err.data);
    });
};

export {
  getDeliveriesListActionType,
  getDeliveriesListSuccessActionType,
  getDeliveriesListFailureActionType,
  clearDeliveriesListActionType,
  clearDeliveriesList,
  getDeliveriesList,
};
