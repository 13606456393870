import {
  getCompanyActionType,
  getCompanyFailureActionType,
  getCompanySuccessActionType,
  clearCompanyDetailsActionType,
} from 'actions/Companies/companyDetails';
import { inactivateCompanySuccessActionType } from 'actions/Companies/inactivateCompany';
import { reactivateCompanySuccessActionType } from 'actions/Companies/reactivateCompany';

const INITIAL_STATE = {
  isPending: false,
  errorMessage: null,
  company: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getCompanyActionType:
      return {
        ...state,
        isPending: true,
      };
    case getCompanySuccessActionType:
    case inactivateCompanySuccessActionType:
    case reactivateCompanySuccessActionType:
      return {
        ...state,
        isPending: false,
        company: action.payload.company,
      };
    case getCompanyFailureActionType:
      return {
        ...state,
        isPending: false,
        errorMessage: action.payload.errorMessage,
      };
    case clearCompanyDetailsActionType:
      return {
        ...state,
        isPending: false,
        errorMessage: null,
        company: null,
      };
    default:
      return state;
  }
};
