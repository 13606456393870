import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router';

import { Button } from '../Button/Button';
import { cssVar } from 'shared/helpers/styling/styling';

function RouterButtonBase({
  path,
  label,
  icon,
  onClick,
  children,
  history,
  replace = false,
  locationState = {},
  ...props
}) {
  const navigate = () => {
    (replace ? history.replace : history.push)(path, locationState);
    onClick();
  };

  return (
    <Button {...props} onClick={navigate} data-testid={props.id} outlineColor={props.outlineColor || cssVar('atlantis')}>
      {label || children}
      {icon}
    </Button>
  );
}

RouterButtonBase.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string,
  path: PropTypes.string.isRequired,
  locationState: PropTypes.object,
};

RouterButtonBase.defaultProps = {
  onClick: () => {},
};

const RouterButton = withRouter(RouterButtonBase);

export { RouterButton };
