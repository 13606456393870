import CreateMultiplePackagesReducer from './createMultiplePackages';
import CreatePackageReducer from './createPackage';
import { deletePackageReducer } from './deletePackage';
import { PackageDetailsReducer } from './packageDetails';
import { PackagesOverviewReducer } from './packagesOverviewList';
import { PrintablePackagesListReducer } from './printablePackagesList';
import { RelatedSamplePackagesReducer } from './relatedSamplePackages';
import { releasePackageReducer } from './releasePackage';
import { updateMultiplePackagesReducer } from './updateMultiplePackages';
import { updatePackageReducer } from './updatePackage';

const packagesRootReducer = {
  createPackage: CreatePackageReducer,
  createMultiplePackages: CreateMultiplePackagesReducer,
  packageDetails: PackageDetailsReducer,
  updatePackage: updatePackageReducer,
  updateMultiplePackages: updateMultiplePackagesReducer,
  deletePackage: deletePackageReducer,
  packagesOverviewList: PackagesOverviewReducer,
  printablePackagesList: PrintablePackagesListReducer,
  releasePackage: releasePackageReducer,
  relatedSamplePackages: RelatedSamplePackagesReducer,
};

export { packagesRootReducer };
