import axios from 'axios';

import { parseHedge, parsePlacedHedge } from 'shared/helpers/dataParsers/hedges';

const API_HOST_AUTH = process.env.REACT_APP_API;

const createHedgesActionType = 'CREATE_HEDGES';
const createHedgesAction = () => ({
  type: createHedgesActionType,
});

const createHedgesSuccessActionType = `${createHedgesActionType}_SUCCESS`;
const createHedgesSuccessAction = () => ({
  type: createHedgesSuccessActionType,
});

const createHedgesFailureActionType = `${createHedgesActionType}_FAILURE`;
const createHedgesFailureAction = () => ({
  type: createHedgesFailureActionType,
});

const createHedgesFactory = (url, mappedHedges) => dispatch => {
  dispatch(createHedgesAction());

  return axios
    .post(`${API_HOST_AUTH}/${url}`, mappedHedges)
    .then(({ data }) => {
      dispatch(createHedgesSuccessAction());
      return data;
    })
    .catch(error => {
      dispatch(createHedgesFailureAction());
      return Promise.reject(error.data);
    });
};

const createHedgesRequests = (hedges, userType, materialCountRequired) =>
  createHedgesFactory(
    'hedges',
    hedges.map(hedge => parseHedge(hedge, userType, materialCountRequired)),
  );

const enterPlacedHedges = hedges =>
  createHedgesFactory('hedges/enter-placed', hedges.map(parsePlacedHedge));

export {
  createHedgesActionType,
  createHedgesSuccessActionType,
  createHedgesFailureActionType,
  createHedgesRequests,
  enterPlacedHedges,
};
