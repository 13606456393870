import { isCustomerUser } from 'shared/helpers/matchers/checkUserType';
import { isStatusActive } from 'shared/helpers/matchers/isStatusActive';

const reportsAccess = (user, access) =>
  isStatusActive(user.status) &&
  (isCustomerUser(user.userType)
    ? user.relatedCompany.materialCountRequired && access.reports.list
    : access.reports.list);

export { reportsAccess };
