import PropTypes from 'prop-types';
import * as React from 'react';
import { compose } from 'redux';
import styled, { css } from 'styled-components';

import { HISTORY_HEDGES } from 'shared/helpers/constants/hedges/historyHedgesConstants';
import { dateFormat } from 'shared/helpers/parsers/date';
import { roundUp } from 'shared/helpers/parsers/number';
import { cssVar, pxToRem, media, formatTemplate } from 'shared/helpers/styling/styling';

import dateAdapter from 'utils/date/dateAdapter';

const StyledHistoryItem = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 0.3fr 1fr;
  color: ${cssVar('alabaster')};
  font-size: ${pxToRem(12)};
  border-right: none;

  ${media.phone`
    grid-template-columns: 1fr;
  `}
`;

const StyledCell = styled.div`
  padding: ${pxToRem(5)};
  border-left: ${({ withoutBorderLeft }) =>
    !withoutBorderLeft && '1px solid rgba(255, 255, 255, 0.05)'};
  text-align: center;
  grid-area: ${({ name }) => name};

  ${({ borderRight }) =>
    borderRight &&
    css`
      border-right: 1px solid rgba(255, 255, 255, 0.05);
      margin-right: -1px;
    `};

  ${media.phone`
    ${({ mobileBorderBottom }) =>
      mobileBorderBottom &&
      css`
        border-bottom: 1px solid rgba(255, 255, 255, 0.05);
      `};
  `}
`;

const StyledRow = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-areas: ${({ template }) => compose(formatTemplate)(template)};
  border-bottom: ${({ borderBottom }) => borderBottom && '1px solid rgba(255, 255, 255, 0.05)'};
`;

const StyledFooter = styled(StyledHistoryItem)`
  background-color: ${cssVar('apple')};
`;

const getAvailableOunces = relatedHedge => {
  const availablePalladium = relatedHedge.palladiumOz || 0;
  const availableRhodium = relatedHedge.rhodiumOz || 0;
  const availablePlatinum = relatedHedge.platinumOz || 0;

  return {
    availablePalladium: +roundUp(availablePalladium, 3),
    availablePlatinum: +roundUp(availablePlatinum, 3),
    availableRhodium: +roundUp(availableRhodium, 3),
  };
};

const HistoryItems = ({ relatedHedge, deliveriesList }) => {
  return (
    <>
      {deliveriesList.length ? (
        deliveriesList.reverse().map(delivery => (
          <StyledHistoryItem key={delivery._id}>
            <StyledCell mobileBorderBottom withoutBorderLeft>
              {delivery.relatedLot.grgLotNumber}
              {delivery.relatedLot.customLotNumber && ` (${delivery.relatedLot.customLotNumber})`}
            </StyledCell>
            <StyledRow template={['appliedOn platinumOz palladiumOz rhodiumOz']}>
              <StyledCell name="appliedOn">
                {dateAdapter(delivery.createdAt).format(dateFormat)}
              </StyledCell>
              <StyledCell name="platinumOz">{delivery.platinumOz?.toFixed(3)}</StyledCell>
              <StyledCell name="palladiumOz">{delivery.palladiumOz?.toFixed(3)}</StyledCell>
              <StyledCell name="rhodiumOz">{delivery.rhodiumOz?.toFixed(3)}</StyledCell>
            </StyledRow>
          </StyledHistoryItem>
        ))
      ) : (
        <StyledHistoryItem>
          <StyledCell withoutBorderLeft>-</StyledCell>
          <StyledRow template={['appliedOn platinumOz palladiumOz rhodiumOz']}>
            <StyledCell name="appliedOn">-</StyledCell>
            <StyledCell name="platinumOz">-</StyledCell>
            <StyledCell name="palladiumOz">-</StyledCell>
            <StyledCell name="rhodiumOz">-</StyledCell>
          </StyledRow>
        </StyledHistoryItem>
      )}
      <StyledFooter>
        <StyledCell mobileBorderBottom borderRight withoutBorderLeft>
          {HISTORY_HEDGES.OUNCES_AVAILABLE}
        </StyledCell>
        <StyledRow template={['. availablePt availablePd availableRh']}>
          <StyledCell name="availablePt">
            {getAvailableOunces(relatedHedge, deliveriesList).availablePlatinum?.toFixed(3)}
          </StyledCell>
          <StyledCell name="availablePd">
            {getAvailableOunces(relatedHedge, deliveriesList).availablePalladium?.toFixed(3)}
          </StyledCell>
          <StyledCell name="availableRh">
            {getAvailableOunces(relatedHedge, deliveriesList).availableRhodium?.toFixed(3)}
          </StyledCell>
        </StyledRow>
      </StyledFooter>
    </>
  );
};

HistoryItems.defaultProps = {
  deliveriesList: [],
};

HistoryItems.propTypes = {
  deliveriesList: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      createdAt: PropTypes.number,
      createdBy: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
      }),
      relatedLot: PropTypes.shape({
        _id: PropTypes.string,
        grgLotNumber: PropTypes.string,
      }),
      palladiumOz: PropTypes.number,
      platinumOz: PropTypes.number,
      rhodium: PropTypes.number,
    }),
  ),
  relatedHedge: PropTypes.shape({
    hedgeName: PropTypes.string,
    palladiumOz: PropTypes.number,
    placedAt: PropTypes.number,
    platiniumOz: PropTypes.number,
    relatedCompany: PropTypes.string,
    rhodiumOz: PropTypes.number,
    _id: PropTypes.string,
  }),
};

export { HistoryItems };
