import { humanizeCamelCase } from 'shared/helpers/parsers/text';

const payoutRulesTypes = {
  net30: 'net30',
};

const parsePayoutRule = rule =>
  rule === payoutRulesTypes.net30 ? rule.toUpperCase() : humanizeCamelCase(rule);

export { payoutRulesTypes, parsePayoutRule };
