import {
  getSettlementDetailsType,
  getSettlementDetailsSuccessType,
  getSettlementDetailsFailureType,
  clearSettlementDetailsType,
} from 'actions/Settlements/settlementDetails';

const INITIAL_STATE = {
  isPending: false,
  settlement: null,
};

const SettlementDetailsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getSettlementDetailsType:
      return {
        ...state,
        isPending: true,
      };
    case getSettlementDetailsSuccessType:
      return {
        isPending: false,
        settlement: action.payload.settlement,
      };
    case clearSettlementDetailsType:
    case getSettlementDetailsFailureType:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};

export { SettlementDetailsReducer };
