import {
  getBankHolidaysActionType,
  getBankHolidaysSuccessActionType,
  getBankHolidaysFailureActionType,
  clearBankHolidaysActionType,
} from 'actions/Payments/bankHolidaysList';

const INITIAL_STATE = {
  isPending: false,
  bankHolidays: [],
  filters: { year: new Date().getFullYear() },
};

const bankHolidaysListReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getBankHolidaysActionType:
      return {
        ...state,
        filters: action.payload.filters,
        isPending: true,
      };
    case getBankHolidaysSuccessActionType:
      return {
        ...state,
        isPending: false,
        bankHolidays: action.payload.data,
      };
    case getBankHolidaysFailureActionType:
      return {
        ...state,
        isPending: false,
      };
    case clearBankHolidaysActionType:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};

export { bankHolidaysListReducer };
