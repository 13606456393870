import React from 'react';

import { isArray } from 'utils/array';

const renderArray = result => (
  <div className="TableItem--breakable">
    {result.map((el, i) => (
      <span key={`${el}.${i}`}>
        {el}
        &nbsp;
      </span>
    ))}
  </div>
);

const renderItem = (render, item) => {
  const result = render(item);

  return isArray(result) ? renderArray(result) : result;
};

export { renderArray, renderItem };
