import { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { lotsFiltersClear, statusForGlobalFilter } from 'actions/Lots/lotsFilters';
import { getLotsList } from 'actions/Lots/lotsList';

import { FullPageList } from 'shared/components/FullPageList/FullPageList';
import { lotsAwaitingResults } from 'shared/helpers/constants/lots/statusesForGlobal';
import {
  itemsTemplate,
  itemsTemplateMobile,
  itemsDetailsTemplate,
} from 'shared/helpers/constants/reports/lotsAwaitingResultsConfig';
import { REPORTS } from 'shared/helpers/constants/reports/reportsConstants';

import { LotsAwaitingResultsFilters } from './components/LotsAwaitingResultsFilters';

const LotsAwaitingResults = () => {
  const dispatch = useDispatch();
  const auth = useSelector(({ auth }) => auth);
  const history = useHistory();

  const lotsFilters = useSelector(({ lotsFilters }) => lotsFilters);

  const searchFilter = useSelector(
    state => state.form?.SearchForm?.values?.lotsAwaitingResults?.searchInput,
  );

  const { lots, page, pages, limit, isPending } = useSelector(({ lotsList }) => lotsList);

  useEffect(() => {
    fetchList(1, 50, searchFilter);
  }, [lotsFilters]);

  const fetchList = useCallback(
    (page = 1, limit = 50, query, businessDaysOut = true) => {
      dispatch(
        getLotsList(
          limit,
          page,
          query,
          lotsFilters.statusForGlobal.length
            ? { ...lotsFilters }
            : { ...lotsFilters, statusForGlobal: lotsAwaitingResults },
          businessDaysOut,
        ),
      );
    },
    [dispatch, lotsFilters],
  );

  const filtersClear = () => dispatch(lotsFiltersClear());

  const onFiltersChanged = searchFilter => filters => {
    dispatch(statusForGlobalFilter(filters.statusForGlobal));
  };

  const filtersSelected = filters => filters.statusForGlobal.length;

  const getActionsTemplate = useCallback(
    () => [
      ...(auth.access.lots.details.details
        ? [
            {
              icon: 'arrow-right',
              action: 'Details',
              onClick: lot => () => {
                history.push(`/lots/list/${lot._id}`);
              },
            },
          ]
        : []),
    ],
    [auth],
  );

  const renderFilters = searchFilter => (
    <LotsAwaitingResultsFilters
      filters={lotsFilters}
      pagination={{ page, limit }}
      onFiltersChanged={onFiltersChanged(searchFilter)}
      auth={auth}
    />
  );

  return (
    <FullPageList
      title={REPORTS.LOTS_AWAITING_RESULTS}
      itemsTemplate={itemsTemplate}
      itemsDetailsTemplate={itemsDetailsTemplate}
      itemsTemplateMobile={itemsTemplateMobile}
      actionsTemplate={getActionsTemplate()}
      items={lots || []}
      fetchList={fetchList}
      filterComponent={renderFilters}
      pagination={{ page, limit, pages }}
      enableSearch
      filtersClear={filtersClear}
      filtersSelected={filtersSelected(lotsFilters)}
      filterAccess
      isPending={isPending}
      filters={lotsFilters}
      disableInitialFetch
    />
  );
};

export { LotsAwaitingResults };
