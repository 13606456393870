const globalFileTypes = {
  shipmentSummary: 'shipment-summary',
  finalInvoiceMockup: 'final-invoice-mockup',
  finalInvoice: 'final-invoice',
  profitAndLossDraft: 'profit-and-loss-draft',
  profitAndLoss: 'profit-and-loss',
  shipmentSummaryOverwrite: 'shipment-summary-overwrite',
};

export { globalFileTypes };
