import { connect } from 'react-redux';

import { reactivateCompany } from 'actions/Companies/reactivateCompany';
import { openModal } from 'actions/shared/modal';
import { showSnackbar } from 'actions/shared/snackbar';

import { reactivateCompanyModal } from 'shared/helpers/constants/modalConstants';
import {
  successOptions,
  dangerOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

function ReactivateCompanyBase(props) {
  const onClick = () => {
    props.openModal(reactivateCompanyModal, () => {
      props
        .reactivateCompany(props.company._id)
        .then(({ message }) => {
          props.showSnackbar(successOptions, message);
          props.onReactivate && props.onReactivate();
        })
        .catch(({ message }) => props.showSnackbar(dangerOptions, message));
    });
  };

  return props.children({ onClick });
}

const mapDispatchToProps = {
  openModal,
  showSnackbar,
  reactivateCompany,
};

const ReactivateCompany = connect(null, mapDispatchToProps)(ReactivateCompanyBase);

export { ReactivateCompanyBase, ReactivateCompany };
