import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { Field, FormSection, reduxForm, getFormValues } from 'redux-form';

import { getBankHolidays } from 'actions/Payments/bankHolidaysList';
import {
  getMaximumSettlementOunces,
  clearMaximumSettlementOunces,
} from 'actions/PoolAccounts/getMaximumSettlementOunces';

import { FieldHint, FieldDate, FieldInput } from 'shared/components/Fields';
import { FieldButtonSelect } from 'shared/components/Fields/FieldButtonSelect/FieldButtonSelect';
import { FieldCheckbox } from 'shared/components/Fields/FieldCheckbox/FieldCheckbox';
import { Form, Section } from 'shared/components/Form';
import { MultipleFieldsSection } from 'shared/components/Form/MultipleFieldsSection/MultipleFieldsSection';
import { availableFundsTypes } from 'shared/helpers/constants/payments/fundsTypes';
import { PAYMENTS } from 'shared/helpers/constants/payments/paymentsConstants';
import { POOL_ACCOUNTS } from 'shared/helpers/constants/poolAccounts/poolAccountsConstants';
import { SHARED } from 'shared/helpers/constants/sharedConstants';
import {
  getAssignedLotOunces,
  formatMaximumLotOunces,
} from 'shared/helpers/dataParsers/deliveries/getAssignedLotOunces';
import { calculateMinimalFinalPaymentDelay } from 'shared/helpers/dataParsers/payments/calculateMinimalFinalPaymentDelay';
import { getBankHolidaysDates } from 'shared/helpers/dataParsers/payments/parseBankHolidaysData';
import { parseSettlementMaximumOunces } from 'shared/helpers/dataParsers/settlements/parseSettlementMaximumOunces';
import { isCustomerUser, isOwnerOrSupport } from 'shared/helpers/matchers/checkUserType';
import { isGlobalRefiningGroup } from 'shared/helpers/matchers/isGlobalRefiningGroup';
import { mapArray } from 'shared/helpers/parsers/select';
import { validate } from 'shared/helpers/validations/poolAccounts/requestSettlementValidation';

import { Info } from './StyledRequestSettlementForm';

const RequestSettlementFormLayout = ({
  handleSubmit,
  invalid,
  change,
  formValues,
  auth: {
    user: { userType },
  },
  maximumSettlementOunces,
  bankHolidaysList: { filters, bankHolidays },
}) => {
  const {
    settlement: {
      platinumOz: platinumOzToSettle,
      palladiumOz: palladiumOzToSettle,
      rhodiumOz: rhodiumOzToSettle,
      requestPayment,
    },
    applyBlanketHedges: { relatedLot, deliveries },
  } = formValues;
  const dispatch = useDispatch();
  const settlementOunces = maximumSettlementOunces?.settlementOunces?.[relatedLot?.value];
  const available = parseSettlementMaximumOunces(settlementOunces);
  const { isPending } = maximumSettlementOunces;
  const suggestions = formatMaximumLotOunces(available, getAssignedLotOunces([...deliveries]));

  useEffect(() => {
    relatedLot && dispatch(getMaximumSettlementOunces(relatedLot.value));
    dispatch(getBankHolidays(filters));
    return () => dispatch(clearMaximumSettlementOunces());
  }, [dispatch]);

  useEffect(() => {
    available &&
      getDefaultValues(formatMaximumLotOunces(available, getAssignedLotOunces([...deliveries])));
  }, [maximumSettlementOunces]);

  const wasFinalPaymentRequested = () => {
    const doesFinalPaymentExist = settlementOunces?.wasFinalPaymentRequested;

    if (doesFinalPaymentExist && formValues.settlement.requestPayment)
      change('settlement.requestPayment', false);

    return doesFinalPaymentExist;
  };

  const getDefaultValues = ({ palladiumOz, platinumOz, rhodiumOz }, maximumOunces) => {
    change(
      `settlement.platinumOz`,
      (platinumOzToSettle > platinumOz && platinumOz) ||
        (!maximumOunces && platinumOz && platinumOzToSettle) ||
        platinumOz,
    );
    change(
      `settlement.palladiumOz`,
      (palladiumOzToSettle > palladiumOz && palladiumOz) ||
        (!maximumOunces && palladiumOz && palladiumOzToSettle) ||
        palladiumOz,
    );
    change(
      `settlement.rhodiumOz`,
      (rhodiumOzToSettle > rhodiumOz && rhodiumOz) ||
        (!maximumOunces && rhodiumOz && rhodiumOzToSettle) ||
        rhodiumOz,
    );
  };

  const changeRequestPayment = () => {
    getDefaultValues(
      formatMaximumLotOunces(available, getAssignedLotOunces([...deliveries])),
      true,
    );
    change('settlement.requestPayment', !requestPayment);
  };

  const disableOuncesField = ounces => wasFinalPaymentRequested() || requestPayment || ounces <= 0;

  const ouncesFields = [
    {
      component: FieldHint,
      name: `platinumOz`,
      field: 'required',
      suggestion: suggestions.platinumOz,
      disabled: disableOuncesField(suggestions.platinumOz),
      label: POOL_ACCOUNTS.PLATINUM_OZ_TO_SETTLE,
      hint: SHARED.MAXIMUM,
    },
    {
      component: FieldHint,
      name: `palladiumOz`,
      field: 'required',
      suggestion: suggestions.palladiumOz,
      disabled: disableOuncesField(suggestions.palladiumOz),
      label: POOL_ACCOUNTS.PALLADIUM_OZ_TO_SETTLE,
      hint: SHARED.MAXIMUM,
    },
    {
      component: FieldHint,
      name: `rhodiumOz`,
      field: 'required',
      suggestion: suggestions.rhodiumOz,
      disabled: disableOuncesField(suggestions.rhodiumOz),
      label: POOL_ACCOUNTS.RHODIUM_OZ_TO_SETTLE,
      hint: SHARED.MAXIMUM,
    },
  ];

  const getRequestPaymentTitle = () => {
    const companyName = isOwnerOrSupport(userType) && relatedLot.sentFromCompany.companyName;
    return POOL_ACCOUNTS.REQUEST_PAYMENT_TITLE(companyName);
  };

  const isGlobalCompany = () => {
    const isGlobal = isGlobalRefiningGroup(relatedLot.sentFromCompany.companyName);
    if (isGlobal) {
      change('settlement.requestPayment', false);
    }
    return isGlobal;
  };

  return (
    <Form
      header={POOL_ACCOUNTS.SETTLEMENT}
      onSubmit={handleSubmit}
      submitDisabled={isPending || invalid}
    >
      <FormSection name="settlement">
        <Section header={POOL_ACCOUNTS.OUNCES} template={['ouncesFields ouncesFields']} noPadding>
          <MultipleFieldsSection name="ouncesFields" fields={ouncesFields} />
        </Section>

        {!isPending && !wasFinalPaymentRequested() && !isGlobalCompany() && (
          <>
            <Section template={['requestPayment requestPayment']}>
              {
                <div name="requestPayment">
                  <Field
                    component={FieldCheckbox}
                    label={POOL_ACCOUNTS.REQUEST_PAYMENT}
                    checked={requestPayment}
                    onClick={changeRequestPayment}
                  />
                  {requestPayment && (
                    <Info>
                      {POOL_ACCOUNTS.REQUEST_PAYMENT_INFO(
                        relatedLot.sentFromCompany.companyName,
                        userType,
                      )}
                    </Info>
                  )}
                </div>
              }
            </Section>

            {requestPayment && (
              <Section
                header={getRequestPaymentTitle()}
                template={['fundsType requestedFinalPaymentDate', 'additionalInfo .']}
              >
                <Field
                  name="fundsType"
                  component={FieldButtonSelect}
                  options={mapArray(availableFundsTypes.assayInternal)}
                  label={PAYMENTS.FUNDS_TYPE}
                  field="required"
                />
                <Field
                  name="requestedFinalPaymentDate"
                  component={FieldDate}
                  label={PAYMENTS.REQUESTED_FINAL_PAYMENT_DATE}
                  minDate={calculateMinimalFinalPaymentDelay(isCustomerUser(userType))}
                  field="required"
                  excludeDates={getBankHolidaysDates(bankHolidays)}
                  onlyWeekdays
                />
                <Field
                  name="additionalInfo"
                  component={FieldInput}
                  label={PAYMENTS.ADDITIONAL_INFORMATION}
                  field="optional"
                />
              </Section>
            )}
          </>
        )}
      </FormSection>
    </Form>
  );
};

const mapStateToProps = state => ({
  availableOunces: state.lotAvailableOunces,
  formValues: getFormValues('wizard')(state),
  auth: state.auth,
  maximumSettlementOunces: state.maximumSettlementOunces,
  allowNegativeValues: true,
  bankHolidaysList: state.bankHolidaysList,
});

const RequestSettlementForm = compose(
  withRouter,
  connect(mapStateToProps),
  reduxForm({
    validate: (values, props) => ({
      settlement: validate({ ...values.settlement }, props),
    }),
  }),
)(RequestSettlementFormLayout);

RequestSettlementFormLayout.propTypes = {
  auth: PropTypes.object,
  change: PropTypes.func,
  formValues: PropTypes.shape({
    applyBlanketHedges: PropTypes.object,
    ouncesRemoved: PropTypes.object,
    settlement: PropTypes.object,
  }),
  handleSubmit: PropTypes.func,
  invalid: PropTypes.bool,
  bankHolidaysList: PropTypes.shape({
    filters: PropTypes.shape({
      year: PropTypes.number,
    }),
    bankHolidays: PropTypes.array,
  }),
  maximumSettlementOunces: PropTypes.shape({
    isPending: PropTypes.bool,
    settlementOunces: PropTypes.object,
  }),
};

export { RequestSettlementForm, RequestSettlementFormLayout };
