import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getLotsToReassignPaymentActionType = 'GET_LOTS_TO_REASSIGN_PAYMENT';
const getLotsToReassignPaymentAction = () => ({
  type: getLotsToReassignPaymentActionType,
});

const getLotsToReassignPaymentSuccessActionType = `${getLotsToReassignPaymentActionType}_SUCCESS`;
const getLotsToReassignPaymentSuccessAction = () => ({
  type: getLotsToReassignPaymentSuccessActionType,
});

const getLotsToReassignPaymentFailureActionType = `${getLotsToReassignPaymentActionType}_FAILURE`;
const getLotsToReassignPaymentFailureAction = () => ({
  type: getLotsToReassignPaymentFailureActionType,
});

const getLotsToReassignPayment =
  (paymentId, filter = '') =>
  async dispatch => {
    dispatch(getLotsToReassignPaymentAction());

    return axios
      .get(`${API_HOST_AUTH}/lots/payments/${paymentId}/reassign/${encodeURIComponent(filter)}`)
      .then(({ data }) => {
        dispatch(getLotsToReassignPaymentSuccessAction());

        return {
          options: data.map(({ _id, grgLotNumber, statusForGlobal }) => ({
            value: _id,
            label: grgLotNumber,
            statusForGlobal,
          })),
        };
      })
      .catch(({ data }) => {
        dispatch(getLotsToReassignPaymentFailureAction());

        return Promise.reject(data);
      });
  };

export {
  getLotsToReassignPayment,
  getLotsToReassignPaymentActionType,
  getLotsToReassignPaymentSuccessActionType,
  getLotsToReassignPaymentFailureActionType,
};
