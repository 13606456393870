import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';

import { markAsSent as markAsSentAction } from 'actions/Shipments/markAsSent';
import { openModal } from 'actions/shared/modal';
import { saveSharedData as saveSharedDataAction } from 'actions/shared/saveSharedData';
import { showSnackbar } from 'actions/shared/snackbar';

import {
  shipmentSealReminderModal,
  requestAdvancedPaymentModal,
} from 'shared/helpers/constants/modalConstants';
import { paymentTypes } from 'shared/helpers/constants/payments/paymentTypes';
import { SHIPMENTS } from 'shared/helpers/constants/shipments/shipmentsConstants';
import {
  successOptions,
  dangerOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { isInternalCompany } from 'shared/helpers/matchers/checkCompanyType';
import { isFullHedging, isLimitedHedging } from 'shared/helpers/matchers/checkUserAccessLevel';
import { isCustomerUser, isOwnerOrSupport } from 'shared/helpers/matchers/checkUserType';
import { isStatusActive } from 'shared/helpers/matchers/isStatusActive';
import { isIncomingShipment } from 'shared/helpers/matchers/shipments/checkShipmentDirection';

function MarkAsSent({
  markAsSent,
  saveSharedData,
  shipment,
  showSnackbar,
  history,
  user,
  isPaymentExceptionCompany,
  config,
  openModal,
  children,
}) {
  const markAsSentHandler = () =>
    markAsSent(shipment._id || shipment.id)
      .then(res => showSnackbar(successOptions, res.message))
      .catch(({ message }) => showSnackbar(dangerOptions, message));

  const requestAdvancedPayment = () =>
    openModal(requestAdvancedPaymentModal(), () => history.push('/payments/request/advance'));

  const requestAdvancePaymentAsGlobal = () => {
    const { companyName } = shipment.sentFromCompany;
    return openModal(requestAdvancedPaymentModal(companyName), () =>
      history.push('/payments/enter'),
    );
  };

  const handleCustomAction = () => history.push(config.redirectPath(shipment._id));
  const handleAcceptAction = async () => {
    const { userType, accessLevel, status, relatedCompany } = user;

    await markAsSentHandler();

    if (
      isCustomerUser(userType) &&
      !isInternalCompany(relatedCompany.companyType) &&
      !isInternalCompany(shipment.sentFromCompany.companyType) &&
      isPaymentExceptionCompany &&
      isStatusActive(status) &&
      (isFullHedging(accessLevel) || isLimitedHedging(accessLevel))
    ) {
      await requestAdvancedPayment();
    }

    if (
      isOwnerOrSupport(userType) &&
      isPaymentExceptionCompany &&
      !isInternalCompany(shipment.sentFromCompany.companyType) &&
      isIncomingShipment(shipment.shipmentDirection)
    ) {
      saveSharedData({
        payments: {
          relatedCompany: {
            label: shipment?.sentFromCompany.companyName,
            value: shipment?.sentFromCompany._id,
            companyType: shipment?.sentFromCompany.companyType,
            balanceRemaining: shipment?.sentFromCompany.balanceRemaining,
          },
          paymentType: {
            value: paymentTypes.advance,
            label: 'Advance',
          },
        },
      });

      await requestAdvancePaymentAsGlobal();
    }
  };

  const markAsSentShipment = async () => {
    let confirmationResult = await openModal(config.markAsSentShipmentModal);

    if (!confirmationResult) return;

    if (!shipment.packages.length) {
      return showSnackbar(dangerOptions, SHIPMENTS.ERROR.MUST_HAVE_PACKAGE);
    }

    if (!shipment.shipmentSeal) {
      await openModal(shipmentSealReminderModal, handleAcceptAction, null, handleCustomAction);
    } else {
      await handleAcceptAction();
    }
  };

  return children({ markAsSentShipment, label: config?.label });
}

const mapStateToProps = ({ shipmentDetails: { shipment }, auth: { user } }) => ({
  isPaymentExceptionCompany: shipment && !!shipment.sentFromCompany.paymentExceptionCompany,
  user,
});

MarkAsSent.propTypes = {
  children: PropTypes.func,
  markAsSent: PropTypes.func,
  saveSharedData: PropTypes.func,
  shipment: PropTypes.object,
  showSnackbar: PropTypes.func,
  history: PropTypes.object,
  user: PropTypes.object,
  isPaymentExceptionCompany: PropTypes.bool,
  config: PropTypes.shape({
    materialCountRequired: PropTypes.shape({
      markAsSentShipmentModal: PropTypes.object,
      label: PropTypes.string,
      redirectPath: PropTypes.func,
    }),
    default: PropTypes.shape({
      markAsSentShipmentModal: PropTypes.object,
      label: PropTypes.string,
      redirectPath: PropTypes.func,
    }),
  }),
  openModal: PropTypes.func,
};

const mapDispatchToProps = {
  openModal,
  showSnackbar,
  markAsSent: markAsSentAction,
  saveSharedData: saveSharedDataAction,
};

const ConnectedMarkAsSent = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
)(MarkAsSent);

export { ConnectedMarkAsSent as MarkAsSent, MarkAsSent as MarkAsSentShipmentModal };
