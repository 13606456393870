import React, { useEffect, useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { useSelector, useDispatch } from 'react-redux';

import { getWarehouseData } from 'actions/Lots/getWarehouseDashboardData';
import { updateVirtualLot, updateVirtualLotWeight } from 'actions/Lots/updateVirtualLot';
import { updateWarehouseData } from 'actions/Lots/updateWarehouseDashboardData';
import { showSnackbar } from 'actions/shared/snackbar';

import { ColumnGrid } from 'shared/components/ColumnGrid/ColumnGrid';
import { dangerOptions } from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { SECTIONS } from 'shared/helpers/constants/warehouseDashboard/labels';

import { history } from 'utils/history';

import { warehouseDashboardData } from 'pages/Lots/components/LotsSections/config/warehouseDashboardData';

import { DashboardColumn } from './DashboardColumn';

const WarehouseDashboardContainer = props => {
  const dispatch = useDispatch();
  const { warehouseData } = useSelector(({ warehouseDashboardData }) => warehouseDashboardData);
  const [isDragging, setIsDragging] = useState(false);

  const parsedWarehouseData = warehouseData && warehouseDashboardData(warehouseData);

  useEffect(() => {
    dispatch(getWarehouseData());
  }, [dispatch]);

  const onDragEnd = result => {
    setIsDragging(false);

    if (!result.destination) {
      return;
    }

    const source = result.source;
    const destination = result.destination;
    const newSection = destination.droppableId.split('-')[0];
    const oldSection = source.droppableId.split('-')[0];
    const newSubsection = destination.droppableId.split('-')[1];
    const lotId = result.draggableId.split('_')[0];

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }
    const updatedVirtualLotData = {
      _id: lotId,
      section: newSection,
      subsection: newSubsection || null,
    };

    if (newSection.toLowerCase() === SECTIONS.COMPLETE.name.toLowerCase()) {
      updatedVirtualLotData.oldSection = oldSection;
      dispatch(updateVirtualLotWeight(updatedVirtualLotData));
      history.push({
        pathname: `/lots/update-virtual-lot/${lotId}`,
      });
    } else {
      dispatch(updateWarehouseData({ updatedVirtualLotData, oldSection }));
      dispatch(updateVirtualLot(updatedVirtualLotData))
        .then(() => {
          dispatch(getWarehouseData());
        })
        .catch(err => {
          dispatch(getWarehouseData());
          return dispatch(showSnackbar(dangerOptions, err.message));
        });
    }
  };

  const onDragStart = () => setIsDragging(true);

  return (
    <>
      {warehouseData && (
        <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
          <ColumnGrid size={parsedWarehouseData.length}>
            {parsedWarehouseData.map(col => (
              <DashboardColumn data={col} key={col.name} isDragging={isDragging} {...props} />
            ))}
          </ColumnGrid>
        </DragDropContext>
      )}
    </>
  );
};

export { WarehouseDashboardContainer };
