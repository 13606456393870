import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, FieldArray, SubmissionError } from 'redux-form';

import { Form } from 'shared/components/Form';
import { USER } from 'shared/helpers/constants/users/userConstants';
import { multiAsyncValidator, multiValidator } from 'shared/helpers/validations/multiValidator';
import { asyncValidate } from 'shared/helpers/validations/user/asyncValidate';
import { validate } from 'shared/helpers/validations/user/userValidations';
import { validateEmail } from 'shared/helpers/validations/user/validateEmail';

import { UsersList } from './components/UsersList';

function AddUsersBase(props) {
  const { CustomActions, invalid, submitting, change, isCheckEmailPending, handleSubmit } = props;

  const onSubmit = useCallback(
    values => handleSubmit(values).catch(err => new SubmissionError(err)),
    [handleSubmit],
  );

  return (
    <Form
      header={USER.USERS}
      onSubmit={onSubmit}
      CustomActions={CustomActions}
      submitDisabled={invalid || submitting || isCheckEmailPending}
    >
      <FieldArray name="users" component={UsersList} invalid={invalid} change={change} />
    </Form>
  );
}

const AddUsers = compose(
  connect(state => {
    const isCheckEmailPending = state.checkEmail.isPending;
    return {
      isCheckEmailPending,
    };
  }),
  reduxForm({
    validate: multiValidator('users', validate, validateEmail),
    asyncValidate: multiAsyncValidator('users', asyncValidate),
    asyncBlurFields: ['users[].email'],
    initialValues: {
      users: [{}],
    },
  }),
)(AddUsersBase);

export { AddUsers };
