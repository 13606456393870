import axios from 'axios';

import { SHIPMENTS } from 'shared/helpers/constants/shipments/shipmentsConstants';
import { messageTypes } from 'shared/helpers/constants/snackbar/snackbarMessageTypes';

const API_HOST_AUTH = process.env.REACT_APP_API;

const generateShipmentDocumentsActionType = 'GENERATE_SHIPMENT_DOCUMENTS';
const generateShipmentDocumentsAction = () => ({
  type: generateShipmentDocumentsActionType,
});

const generateShipmentDocumentsSuccessActionType = `${generateShipmentDocumentsActionType}_SUCCESS`;
const generateShipmentDocumentsSuccessAction = shipment => ({
  type: generateShipmentDocumentsSuccessActionType,
  payload: { shipment },
});

const generateShipmentDocumentsFailureActionType = `${generateShipmentDocumentsActionType}_FAILURE`;
const generateShipmentDocumentsFailureAction = () => ({
  type: generateShipmentDocumentsFailureActionType,
});

const generateShipmentDocuments = id => dispatch => {
  dispatch(generateShipmentDocumentsAction());

  return axios
    .post(`${API_HOST_AUTH}/shipments/${id}/generate-documents`)
    .then(res => {
      dispatch(generateShipmentDocumentsSuccessAction(res.data.shipment));

      return {
        message: res.data.message,
        messageType: messageTypes.success,
      };
    })
    .catch(error => {
      dispatch(generateShipmentDocumentsFailureAction());

      return Promise.reject({
        message: `${SHIPMENTS.ERROR.GENERATE_SHIPMENT_DOCUMENTS_ERROR} ${error.data.message}`,
        messageType: messageTypes.error,
      });
    });
};

export {
  generateShipmentDocuments,
  generateShipmentDocumentsActionType,
  generateShipmentDocumentsSuccessActionType,
  generateShipmentDocumentsFailureActionType,
};
