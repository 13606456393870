import styled from 'styled-components';

const StyledWrapper = styled('div')`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 42px;
`;
const StyledLink = styled('a')`
  display: flex;
  flex-direction: column;
`;

export { StyledWrapper, StyledLink };
