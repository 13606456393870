import axios from 'axios';

import { sessionExpiredHandler } from './authentication/sessionExpiredHandler';
import { validateVersion } from './authentication/validateVersion';

const responseInterceptor = axios.interceptors.response.use(response => {
  validateVersion(response.headers);

  return response;
}, sessionExpiredHandler);

export { responseInterceptor };
