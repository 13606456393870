import { combineReducers } from 'redux';
import { reducer as FormReducer } from 'redux-form';

import { logoutActionType } from 'actions/shared/auth';

import { actionsLogReducer } from './ActionsLog/actionsLog';
import { agreementsRootReducer } from './Agreements';
import { attachmentRootReducer } from './Attachments';
import { cfuRootReducer } from './Cfu';
import { commoditiesReducer } from './Commodities';
import { companiesRootReducer } from './Companies';
import { dashboardRootReducer } from './Dashboard';
import { deliveriesRootReducer } from './Deliveries';
import { filesRootReducer } from './Files';
import { generalInfoRootReducer } from './GeneralInfo';
import { hedgesRootReducer } from './Hedges';
import { locationsRootReducer } from './Locations';
import { lotsRootReducer } from './Lots';
import { marketIndicatorsRootReducer } from './MarketIndicators';
import { noteRootReducer } from './Notes';
import { notificationsRootReducer } from './Notifications';
import { packagesRootReducer } from './Packages';
import { paymentsRootReducer } from './Payments';
import { poolAccountsRootReducer } from './PoolAccounts';
import { priceFeedsRootReducer } from './PriceFeeds';
import { reportsRootReducer } from './Reports';
import { selfDeliveryRequestsRootReducer } from './SelfDeliveryRequests';
import { settlementsRootReducer } from './Settlements';
import { shipmentsRootReducer } from './Shipments';
import { specialPermissionsRootReducer } from './SpecialPermissions';
import { specialPermissionsLogsRootReducer } from './SpecialPermissionsLogs';
import { termsRootReducer } from './Terms';
import { tokenValidationReducer } from './TokenValidation/tokenValidation';
import { usersRootReducer } from './Users';
import { sharedRootReducer } from './shared/index';

const appReducer = combineReducers({
  ...sharedRootReducer,
  ...attachmentRootReducer,
  ...usersRootReducer,
  ...companiesRootReducer,
  ...locationsRootReducer,
  ...noteRootReducer,
  ...termsRootReducer,
  ...agreementsRootReducer,
  ...lotsRootReducer,
  ...packagesRootReducer,
  ...paymentsRootReducer,
  ...selfDeliveryRequestsRootReducer,
  ...shipmentsRootReducer,
  ...notificationsRootReducer,
  ...hedgesRootReducer,
  ...commoditiesReducer,
  ...poolAccountsRootReducer,
  ...deliveriesRootReducer,
  ...dashboardRootReducer,
  ...filesRootReducer,
  ...priceFeedsRootReducer,
  ...marketIndicatorsRootReducer,
  ...settlementsRootReducer,
  ...specialPermissionsRootReducer,
  ...specialPermissionsLogsRootReducer,
  ...reportsRootReducer,
  ...generalInfoRootReducer,
  ...cfuRootReducer,
  tokenValidation: tokenValidationReducer,
  form: FormReducer,
  actionsLog: actionsLogReducer,
});

const rootReducer = (state, action) =>
  appReducer(action.type === logoutActionType ? undefined : state, action);

export default rootReducer;
