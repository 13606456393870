import { CREATE_SELF_DELIVERY_REQUEST } from 'shared/helpers/constants/selfDeliveryRequests/createSelfDeliveeryRequestConstants';
import { isOwnerUser, isSupportUser } from 'shared/helpers/matchers/checkUserType';
import { dateToTimestamp } from 'shared/helpers/parsers/date';
import { replaceEmpty } from 'shared/helpers/parsers/text';
import { commaValidator } from 'shared/helpers/validations/commaValidator';
import { INTEGER_REGEX, PRICE_REGEX } from 'shared/helpers/validations/regularExpressions';

import dateAdapter from 'utils/date/dateAdapter';

function validate(
  values,
  {
    auth: {
      user: { userType },
    },
  },
) {
  const errors = {};
  const minDate =
    isOwnerUser(userType) || isSupportUser(userType) ? 0 : dateAdapter().startOf('d').valueOf();

  if (!values.deliveryDate) {
    errors.deliveryDate = CREATE_SELF_DELIVERY_REQUEST.INVALID_DELIVERY_DATE_EMPTY;
  } else if (dateToTimestamp(values.deliveryDate) < minDate) {
    errors.deliveryDate = CREATE_SELF_DELIVERY_REQUEST.INVALID_DELIVERY_DATE_PAST;
  }

  if (values.excludedHoursCount > 17) {
    errors.deliveryDateTime = CREATE_SELF_DELIVERY_REQUEST.APPROX_DELIVERY_TIME_BUSY;
  } else if (!values.deliveryDateTime) {
    errors.deliveryDateTime = CREATE_SELF_DELIVERY_REQUEST.APPROX_DELIVERY_TIME_EMPTY;
  }

  if (values.customerInstructions && values.customerInstructions.length > 100) {
    errors.customerInstructions = CREATE_SELF_DELIVERY_REQUEST.INVALID_CUSTOMER_INSTRUCTIONS;
  }

  if (replaceEmpty(values.unitCount, '').length === 0) {
    errors.unitCount = CREATE_SELF_DELIVERY_REQUEST.INVALID_UNIT_COUNT_EMPTY;
  } else if (values.unitCount <= 0) {
    errors.unitCount = CREATE_SELF_DELIVERY_REQUEST.INVALID_UNIT_COUNT_NEGATIVE;
  } else if (!INTEGER_REGEX.test(values.unitCount)) {
    errors.unitCount = CREATE_SELF_DELIVERY_REQUEST.INVALID_UNIT_COUNT;
  } else if (replaceEmpty(values.unitCount, '').length > 10) {
    errors.unitCount = CREATE_SELF_DELIVERY_REQUEST.INVALID_UNIT_COUNT;
  }

  if (!values.relatedCompany) {
    errors.relatedCompany = CREATE_SELF_DELIVERY_REQUEST.INVALID_RELATED_COMPANY_EMPTY;
  }

  if (!values.fundsType) {
    errors.fundsType = CREATE_SELF_DELIVERY_REQUEST.INVALID_FUNDS_TYPE_EMPTY;
  }

  if (replaceEmpty(values.estimatedFunds, '').length === 0) {
    errors.estimatedFunds = CREATE_SELF_DELIVERY_REQUEST.INVALID_ESTIMATED_FUNDS_EMPTY;
  } else if (!PRICE_REGEX.test(values.estimatedFunds)) {
    errors.estimatedFunds = CREATE_SELF_DELIVERY_REQUEST.INVALID_ESTIMATED_FUNDS;
  } else if (parseFloat(values.estimatedFunds) <= 0) {
    errors.estimatedFunds = CREATE_SELF_DELIVERY_REQUEST.INVALID_ESTIMATED_FUNDS_NEGATIVE;
  } else if (values.estimatedFunds && values.estimatedFunds.length > 12) {
    errors.estimatedFunds = CREATE_SELF_DELIVERY_REQUEST.INVALID_ESTIMATED_FUNDS;
  }

  commaValidator({ estimatedFunds: values.estimatedFunds }, errors);

  return errors;
}

export { validate };
