import React, { useState } from 'react';

import { UIAuthorization } from 'components/Authorization/UIAuthorization';
import { NotificationActionsPortal } from 'components/NotificationActionsPortal/NotificationActionsPortal';

import { RouterButton } from 'shared/components/Buttons';
import { Button } from 'shared/components/Buttons/Button';
import { ExtendableButton } from 'shared/components/Buttons/ExtendableButton/ExtendableButton';
import { DetailsHeader } from 'shared/components/Details/DetailsHeader';
import { termStatuses } from 'shared/helpers/constants/filters/termStatuses';
import { notificationActionTypes } from 'shared/helpers/constants/notifications/notificationActionTypes';
import { TERMS } from 'shared/helpers/constants/terms/termsConstants';
import { isStandard } from 'shared/helpers/matchers/checkAccessLevel';
import { isOwnerUser } from 'shared/helpers/matchers/checkUserType';
import { capitalize } from 'shared/helpers/parsers/text';

import { checkStartDateTerm } from 'utils/checkStartDateTerm';

import { TermsApprove } from 'pages/Terms/components/TermsManagement/components/TermsApprove/TermsApprove';
import { TermsReuploadSigned } from 'pages/Terms/components/TermsManagement/components/TermsReuploadSigned/TermsReuploadSigned';
import { TermsSubmit } from 'pages/Terms/components/TermsManagement/components/TermsSubmit/TermsSubmit';

function TermsMainDetails({ terms, auth, companyId }) {
  const [displayReuploadSignedModal, setDisplayReuploadSignedModal] = useState(false);

  const canSubmitTerms =
    auth.access.companies.details.terms.submit &&
    terms.status === termStatuses.draft &&
    terms.relatedCompany.primaryLocation &&
    terms.relatedCompany.primaryContact.length;

  const canApproveTerms =
    auth.access.companies.details.terms.approve && terms.status === termStatuses.awaiting;

  const canUpdateTerms =
    auth.access.companies.details.terms.update && terms.status === termStatuses.draft;

  const canUploadDocument =
    auth.access.terms.uploadSignedDocument && terms.status === termStatuses.download;

  const canUploadAnotherDocument =
    isOwnerUser(auth.user.userType) &&
    isStandard(auth.user.accessLevel) &&
    terms.status === termStatuses.signed;

  const actionButton = (label, onClick, color) => (
    <Button outline color={color} outlineColor={color} onClick={onClick}>
      {label}
    </Button>
  );

  const toggleTermsReuploadSigned = () =>
    setDisplayReuploadSignedModal(!displayReuploadSignedModal);

  return (
    <DetailsHeader
      header={terms.materialType === 'o2sensor' ? 'O2 sensor' : capitalize(terms.materialType)}
      status={checkStartDateTerm(terms.status, terms.startDate)}
    >
      <ExtendableButton label={TERMS.ACTIONS}>
        <UIAuthorization access={canSubmitTerms}>
          <TermsSubmit terms={terms}>
            {({ onClick }) => actionButton(TERMS.SUBMIT, onClick)}
          </TermsSubmit>
        </UIAuthorization>

        <UIAuthorization access={canUploadAnotherDocument || canUploadDocument}>
          {actionButton(
            terms.status === termStatuses.signed ? TERMS.REUPLOAD_SIGNED : TERMS.UPLOAD_SIGNED,
            toggleTermsReuploadSigned,
          )}
        </UIAuthorization>

        <UIAuthorization access={canApproveTerms}>
          <TermsApprove terms={terms} approve>
            {({ onClick }) => (
              <NotificationActionsPortal actions={notificationActionTypes.submitTerms}>
                {actionButton(TERMS.APPROVE, onClick)}
              </NotificationActionsPortal>
            )}
          </TermsApprove>
        </UIAuthorization>
        <UIAuthorization access={canApproveTerms}>
          <TermsApprove terms={terms}>
            {({ onClick }) => (
              <NotificationActionsPortal actions={notificationActionTypes.submitTerms}>
                {actionButton(TERMS.DENY, onClick, 'danger')}
              </NotificationActionsPortal>
            )}
          </TermsApprove>
        </UIAuthorization>
        <UIAuthorization access={canUpdateTerms}>
          <RouterButton
            id="openUpdateTerms"
            path={`/companies/${companyId}/terms/${terms._id}/update`}
            label={TERMS.EDIT_TERMS}
          />
        </UIAuthorization>
      </ExtendableButton>
      <TermsReuploadSigned
        toggle={toggleTermsReuploadSigned}
        open={displayReuploadSignedModal}
        title={terms.status === termStatuses.signed ? TERMS.REUPLOAD_SIGNED : TERMS.UPLOAD_SIGNED}
      />
    </DetailsHeader>
  );
}

export { TermsMainDetails };
