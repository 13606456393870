import {
  updateCompanyActionType,
  updateCompanySuccessActionType,
  updateCompanyFailureActionType,
} from 'actions/Companies/updateCompany';

const INITIAL_STATE = {
  isPending: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case updateCompanyActionType:
      return {
        isPending: true,
      };
    case updateCompanySuccessActionType:
      return {
        isPending: false,
      };
    case updateCompanyFailureActionType:
      return {
        isPending: false,
      };
    default:
      return state;
  }
};
