import { statusesForGlobal } from 'shared/helpers/constants/lots/statusesForGlobal';

const updateShipmentInfoAccess = (
  {
    access: {
      lots: { updateShipmentInfo },
    },
  },
  { statusForGlobal },
) =>
  updateShipmentInfo &&
  [
    statusesForGlobal.ready_to_ship,
    statusesForGlobal.shipped_from_global,
    statusesForGlobal.assay_results_received,
  ].includes(statusForGlobal);

export { updateShipmentInfoAccess };
