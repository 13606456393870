import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getSpecialPermissionsActionType = 'GET_SPECIAL_PERMISSIONS';
const getSpecialPermissionsAction = () => ({
  type: getSpecialPermissionsActionType,
});

const getSpecialPermissionsSuccessActionType = `${getSpecialPermissionsActionType}_SUCCESS`;
const getSpecialPermissionsSuccessAction = specialPermissions => ({
  type: getSpecialPermissionsSuccessActionType,
  payload: specialPermissions,
});

const getSpecialPermissionsFailureActionType = `${getSpecialPermissionsActionType}_FAILURE`;
const getSpecialPermissionsFailureAction = () => ({
  type: getSpecialPermissionsFailureActionType,
});

const getSpecialPermissions = () => dispatch => {
  dispatch(getSpecialPermissionsAction());

  return axios
    .get(`${API_HOST_AUTH}/special-permissions`)
    .then(({ data }) => {
      dispatch(getSpecialPermissionsSuccessAction(data));

      return {
        specialPermissions: data,
      };
    })
    .catch(err => {
      dispatch(getSpecialPermissionsFailureAction());

      return Promise.reject({
        message: err.data?.message,
      });
    });
};

export {
  getSpecialPermissions,
  getSpecialPermissionsSuccessAction,
  getSpecialPermissionsActionType,
  getSpecialPermissionsSuccessActionType,
  getSpecialPermissionsFailureActionType,
};
