const shipmentsBreadcrumbsRoutes = {
  '/shipments': 'Shipments',
  '/shipments/create': 'Create shipment',
  '/shipments/create-incoming': 'Create an incoming shipment',
  '/shipments/create-outgoing': 'Create an outgoing shipment',
  '/shipments/list': 'Shipments list',
  '/shipments/incoming': 'Incoming shipments',
  '/shipments/incoming/calendar': 'Calendar',
  '/shipments/incoming/calendar/shipment': null,
  '/shipments/incoming/calendar/lot': null,
  '/shipments/incoming/calendar/shipment/:id': 'Shipment details',
  '/shipments/incoming/calendar/shipment/:id/update': 'Update shipment',
  '/shipments/incoming/calendar/lot/:id': 'Lot details',
  '/shipments/incoming/calendar/lot/:id/update': 'Update lot',
  '/shipments/outgoing': 'Outgoing shipments',
  '/shipments/outgoing/calendar': 'Calendar',
  '/shipments/outgoing/calendar/shipment': null,
  '/shipments/outgoing/calendar/lot': null,
  '/shipments/outgoing/calendar/shipment/:id': 'Shipment details',
  '/shipments/outgoing/calendar/shipment/:id/update': 'Update shipment',
  '/shipments/outgoing/calendar/lot/:id': 'Lot details',
  '/shipments/outgoing/calendar/lot/:id/update': 'Update lot',
  '/shipments/list/:id': 'Shipment details',
  '/shipments/incoming/:id': 'Shipment details',
  '/shipments/incoming/:id/update': 'Update shipment',
  '/shipments/outgoing/:id': 'Shipment details',
  '/shipments/outgoing/:id/ship-and-print': 'Ship and print',
  '/shipments/outgoing/:id/update': 'Update shipment',
  '/shipments/:id': 'Shipment details',
  '/shipments/:id/update': 'Update shipment',
  '/shipments/packages': null,
  '/shipments/packages/create': 'Create package',
  '/shipments/packages/create/customer': null,
  '/shipments/packages/:id': 'Package details',
  '/shipments/packages/:id/update': 'Update package',
  '/shipments/self-delivery-requests': 'Self-delivery requests',
  '/shipments/self-delivery-requests/calendar': 'Calendar',
  '/shipments/self-delivery-requests/calendar/self-delivery-request': null,
  '/shipments/self-delivery-requests/calendar/self-delivery-request/:id':
    'Self-delivery request details',
  '/shipments/self-delivery-requests/create': 'Create self-delivery request',
  '/shipments/self-delivery-requests/:id': 'Self-delivery request details',
  '/shipments/:shipmentId/packages': null,
  '/shipments/:shipmentId/packages/:id': 'Package details',
  '/shipments/:shipmentId/packages/:id/update(/(customer|global))?$': url =>
    window.location.pathname === url ? 'Update package' : null,
  '/shipments/:shipmentId/packages/:id/update': null,
  '/shipments/:shipmentId/packages/:id/update/weight': 'Update weight',
  '/shipments/edit-incoming-wizard/:id': null,
};

export { shipmentsBreadcrumbsRoutes };
