import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import styled from 'styled-components';

import { lotsAddMultipleDocuments, lotsRemoveMultipleFiles } from 'actions/Lots/lotsAddFiles';

import { FieldUpload } from 'shared/components/Fields/FiledUpload/FieldUpload';
import { FieldUploadMultipleFilesList } from 'shared/components/Fields/FiledUpload/FieldUploadMultipleFilesList';
import { Form } from 'shared/components/Form';
import { Preloader } from 'shared/components/Preloader/Preloader';
import { FILES_CONSTANTS } from 'shared/helpers/constants/filesConstants';
import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';
import { parseMultipleFilesInput } from 'shared/helpers/dataParsers/files/parseMultipleFiles';
import { validateMultipleDocumentsLength } from 'shared/helpers/validations/lot/validateMultipleDocuments';

import { CustomActions } from './CustomActions';

const StyledFieldContent = styled.div`
  padding: 50px 40px 53px 0;
`;

const StyledForm = styled(Form)`
  min-height: auto;
  margin: 0;
`;

const UploadBillOfLadingScannerLayout = ({
  isPending,
  onSubmit,
  lastStep,
  grgLotNumber,
  invalid,
  submitting,
  lotsFiles,
}) => {
  const dispatch = useDispatch();
  const { billOfLading } = useSelector(({ lotsFiles }) => lotsFiles.multipleFiles);
  const [billOfLadingError, setbillOfLadingError] = useState(false);
  const hasNewBOLFiles = !!billOfLading.filter(({ existingFile }) => !existingFile._id).length;
  const { VALID_EXTENSIONS } = FILES_CONSTANTS;

  const FormCustomActions = useCallback(
    () => (
      <CustomActions
        onSubmit={onSubmit}
        disabled={!hasNewBOLFiles || billOfLadingError}
        lastStep={lastStep}
      />
    ),
    [onSubmit, invalid, submitting, billOfLadingError],
  );

  const onDelete =
    ({ type, fileIndex }) =>
    () =>
      dispatch(lotsRemoveMultipleFiles({ type, fileIndex }));

  const onMultipleFilesInputChange = async ({ target }) => {
    const payload = parseMultipleFilesInput(target);

    dispatch(lotsAddMultipleDocuments(payload));
  };

  const checkMultipleFilesError = ({ hasError }) => {
    setbillOfLadingError(hasError);
  };

  return (
    <StyledForm
      header={LOTS.UPLOAD_BILL_OF_LADING_WARNING(grgLotNumber)}
      onSubmit={onSubmit}
      CustomActions={FormCustomActions}
      noValidate
    >
      <StyledFieldContent>
        <Field
          multiple
          name="billOfLading"
          component={FieldUpload}
          label={LOTS.UPLOAD_BILL_OF_LADING}
          validExtensions={[
            VALID_EXTENSIONS.jpg,
            VALID_EXTENSIONS.jpeg,
            VALID_EXTENSIONS.pdf,
            VALID_EXTENSIONS.png,
          ].join(', ')}
          onChange={onMultipleFilesInputChange}
        />

        <FieldUploadMultipleFilesList
          files={lotsFiles}
          type="billOfLading"
          validExtensions={[
            VALID_EXTENSIONS.jpg,
            VALID_EXTENSIONS.jpeg,
            VALID_EXTENSIONS.pdf,
            VALID_EXTENSIONS.png,
          ]}
          onDelete={onDelete}
          limit={LOTS.BILL_OF_LADING_LIMIT}
          checkMultipleFilesError={checkMultipleFilesError}
          validationError={validateMultipleDocumentsLength(
            lotsFiles.multipleFiles.billOfLading,
            LOTS.BILL_OF_LADING_LIMIT,
          )}
          revertPosition
        />
      </StyledFieldContent>
      <Preloader loading={isPending} />
    </StyledForm>
  );
};

UploadBillOfLadingScannerLayout.propTypes = {
  isPending: PropTypes.bool,
  onSubmit: PropTypes.func,
  lastStep: PropTypes.bool,
  grgLotNumber: PropTypes.string,
  invalid: PropTypes.bool,
  submitting: PropTypes.bool,
  lotsFiles: PropTypes.object,
  blockSubmit: PropTypes.bool,
};

const UploadBillOfLadingScanner = reduxForm({
  form: 'UpdatePackageWeightForm',
})(UploadBillOfLadingScannerLayout);

export { UploadBillOfLadingScanner, UploadBillOfLadingScannerLayout };
