import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getAccountsReceivableActionType = 'GET_ACCOUNTS_RECEIVABLE';
const getAccountsReceivableAction = () => ({
  type: getAccountsReceivableActionType,
});

const getAccountsReceivableSuccessActionType = `${getAccountsReceivableActionType}_SUCCESS`;
const getAccountsReceivableSuccessAction = accountsReceivable => ({
  type: getAccountsReceivableSuccessActionType,
  payload: {
    accountsReceivable,
  },
});

const getAccountsReceivableFailureActionType = `${getAccountsReceivableActionType}_FAILURE`;
const getAccountsReceivableFailureAction = () => ({
  type: getAccountsReceivableFailureActionType,
});

const clearAccountsReceivableActionType = 'CLEAR_ACCOUNTS_RECEIVABLE';
const clearAccountsReceivable = () => ({
  type: clearAccountsReceivableActionType,
});

const getAccountsReceivable = () => async dispatch => {
  try {
    dispatch(getAccountsReceivableAction());

    const {
      data: { accountsReceivable },
    } = await axios.get(`${API_HOST_AUTH}/payments/accounts-receivable`);

    return dispatch(getAccountsReceivableSuccessAction(accountsReceivable));
  } catch (error) {
    return Promise.reject(dispatch(getAccountsReceivableFailureAction(error)));
  }
};

export {
  getAccountsReceivableActionType,
  getAccountsReceivableAction,
  getAccountsReceivableSuccessActionType,
  getAccountsReceivableSuccessAction,
  getAccountsReceivableFailureActionType,
  getAccountsReceivableFailureAction,
  getAccountsReceivable,
  clearAccountsReceivableActionType,
  clearAccountsReceivable,
};
