import React from 'react';
import { Field } from 'redux-form';
import styled from 'styled-components';

import { Checkbox, CheckboxWrapper } from 'shared/components/Fields/FieldCheckbox/FieldCheckbox';
import { normalizeCheckbox } from 'shared/helpers/parsers/boolean';
import { cssVar, media } from 'shared/helpers/styling/styling';

const PreferencesSection = styled.div`
  display: grid;
  grid-template: auto / ${({ gridTemplate }) => gridTemplate};
  gap: 1px;
  grid-gap: 1px;

  & > div {
    background: ${cssVar('outerSpaceBrighter')};
    justify-content: space-around;
    align-items: center;
    text-align: center;
    padding: 32px 47px;
    ${media.mobile`padding: 10px;`}

    &:first-child {
      display: flex;
      align-items: center;
    }
  }

  ${CheckboxWrapper} {
    overflow: hidden;
    input:checked ~ label {
      box-shadow: 0 0 0 320px ${cssVar('limedSpruceDarker')};
    }
  }
`;

PreferencesSection.defaultProps = {
  gridTemplate: '2fr 1fr 1fr 1fr',
};

function PreferencesField(props) {
  return (
    <PreferencesSection>
      <div>{props.label}</div>
      <Field
        name="email"
        component={Checkbox}
        type="checkbox"
        id={`${props.groupName}-email`}
        normalize={normalizeCheckbox}
        lazyError
      />
      <Field
        name="sms"
        component={Checkbox}
        type="checkbox"
        id={`${props.groupName}-sms`}
        normalize={normalizeCheckbox}
      />
      <Field
        name="portal"
        component={Checkbox}
        type="checkbox"
        id={`${props.groupName}-portal`}
        normalize={normalizeCheckbox}
      />
    </PreferencesSection>
  );
}

export { PreferencesField, PreferencesSection };
