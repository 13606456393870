import {
  termFiltersClearActionType,
  materialTypeFilterActionType,
  termStatusFilterActionType,
} from 'actions/Terms/termFilters';

const INITIAL_STATE = {
  status: [],
  materialType: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case materialTypeFilterActionType:
      return { ...state, materialType: action.payload.materialType };
    case termStatusFilterActionType:
      return { ...state, status: action.payload.termStatus };
    case termFiltersClearActionType:
      return { status: [], materialType: [] };
    default:
      return state;
  }
};
