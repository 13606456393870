import { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { marketAvailability } from 'actions/Hedges/marketAvailability';

import { withFieldWrapper } from 'shared/components/Fields/FieldWrapper/FieldWrapper';
import { datePickerFormat } from 'shared/helpers/parsers/date';

import dateAdapter, { validateDate } from 'utils/date/dateAdapter';
import { MomentAdapter } from 'utils/date/momentAdapter';

import { Input } from './Input';
import { DatePickerStyled } from './StyledFieldDate';

const FieldDateStyles = styled.div`
  .react-datepicker {
    &__triangle {
      display: none;
    }
    &__calendar {
      border: none;

      background-color: transparent;

      & > div:nth-child(4):not(:last-child) {
        border-bottom-right-radius: 0;

        .react-datepicker__month {
          height: 265px;
        }
      }
    }
    &__navigation {
      margin: 10px 5px 0;

      &:active,
      &:focus {
        outline: none;
      }
    }

    &__month-container {
      background-color: var(--shuttleGray);
      border-radius: 5px;
      box-shadow: 0 11px 24px 0 rgba(0, 0, 0, 0.29);

      .react-datepicker__header {
        border-bottom: 1px solid rgba(var(--dustGray), 0.53);
        font-weight: 300;

        .react-datepicker__current-month {
          margin: 5px 0 10px;
          font-weight: 300;
        }

        .react-datepicker__day-names {
          margin-bottom: 5px;
        }

        .react-datepicker__year-dropdown,
        .react-datepicker__month-dropdown {
          border: 1px solid rgba(var(--dustGray), 0.3);
        }

        .react-datepicker__year-option:hover,
        .react-datepicker__month-option:hover {
          background-color: var(--shuttleGray);
        }
      }

      .react-datepicker__month {
        --height: 44px;
        margin: 0;

        .react-datepicker__week {
          display: flex;
          align-items: center;
          height: var(--height);

          &:not(:last-child) {
            border-bottom: 1px solid rgba(var(--dustGray), 0.2);
          }

          .react-datepicker__day {
            height: 100%;
            flex-grow: 1;
            margin: 0;
            line-height: var(--height);

            &:hover {
              background-color: var(--nevada);
              border-radius: 0;
            }

            &--disabled {
              color: var(--dustGray);
            }

            &--today {
              border-radius: 0;
              background-color: transparent;
            }

            &--selected {
              border-radius: 0;
              background-color: var(--slateGrayGRG);
            }

            &--keyboard-selected {
              background-color: transparent;
              outline: 1px solid var(--slateGrayGRG);
            }
          }
        }
      }
    }
  }
`;

class FieldDateBase extends Component {
  static defaultProps = {
    excludeDates: [],
    onlyWeekdays: false,
  };

  componentDidMount() {
    const { validUntil, isPending } = this.props.marketAvailabilityDetails;

    if (!isPending && validUntil < Date.now()) {
      this.props.marketAvailability(true);
    }
  }

  get value() {
    const { value } = this.unwrapDates(this.props.input);
    const date = value instanceof Date ? value : new Date(value);

    return Number.isNaN(date.getTime()) ? null : date;
  }

  stripTime = value =>
    value instanceof Date
      ? new Date(value.getFullYear(), value.getMonth(), value.getDate())
      : value;

  onBlur = () => {
    const { input } = this.props;

    input.onBlur && input.onBlur(this.stripTime(input.value));
  };

  onChange = (value, event) => {
    const { input, onDateChange } = this.props;
    const normalizedValue = value && MomentAdapter.normalize(value).toJSDate();

    if (event && event.keyCode === 13) return event.preventDefault();

    onDateChange && onDateChange(normalizedValue);
    input.onChange && input.onChange(this.stripTime(value));
  };

  unwrapDates(values) {
    return Object.entries(values)
      .map(([key, value]) => [key, value instanceof MomentAdapter ? value.localize() : value])
      .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
  }

  localizeDate(date) {
    return date instanceof Date ? date : dateAdapter(date).localize();
  }

  isWeekday = date => {
    const { marketDaysNumbers } = this.props.marketAvailabilityDetails;

    const day = new Date(date).getDay();
    return marketDaysNumbers.includes(day);
  };

  render() {
    const { input, clearable, filterRestrictedDays, ...parsedProps } = this.unwrapDates(this.props);
    const { minDate, maxDate, excludeDates, onlyWeekdays } = this.props;
    const minMax = {
      minDate: validateDate(minDate) ? this.localizeDate(minDate) : null,
      maxDate: validateDate(maxDate) ? this.localizeDate(maxDate) : null,
    };
    const { value, ...handlers } = input;
    const { disabled, disableFlip } = parsedProps;

    return (
      <FieldDateStyles>
        <DatePickerStyled
          {...parsedProps}
          {...minMax}
          {...handlers}
          onBlur={this.onBlur}
          onChange={this.onChange}
          customInput={<Input hideIcon={disabled} />}
          isClearable={(clearable === undefined || clearable) && !disabled}
          calendarClassName="FieldDate__calendar"
          selected={this.value}
          dateFormat={datePickerFormat}
          autoComplete="off"
          excludeDates={excludeDates}
          showMonthDropdown
          showYearDropdown
          filterDate={filterRestrictedDays || (onlyWeekdays && this.isWeekday)}
          popperModifiers={{
            flip: {
              enabled: !disableFlip,
            },
          }}
        />
      </FieldDateStyles>
    );
  }
}

const FieldDate = withFieldWrapper(FieldDateBase);

const mapStateToProps = ({ marketAvailabilityDetails }) => ({ marketAvailabilityDetails });

const mapDispatchToProps = {
  marketAvailability,
};

const connected = connect(mapStateToProps, mapDispatchToProps)(FieldDate);

export { connected as FieldDate, DatePickerStyled };
