import axios from 'axios';

import { showSnackbar } from 'actions/shared/snackbar';

import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';
import {
  dangerOptions,
  successOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

const API_HOST_AUTH = process.env.REACT_APP_API;

const holdLotActionType = 'HOLD_LOT';
const holdLotAction = () => ({
  type: holdLotActionType,
});

const holdLotSuccessActionType = `${holdLotActionType}_SUCCESS`;
const holdLotSuccessAction = () => ({
  type: holdLotSuccessActionType,
});

const holdLotFailureActionType = `${holdLotActionType}_FAILURE`;
const holdLotFailureAction = () => ({
  type: holdLotFailureActionType,
});

const holdLot = lotId => dispatch => {
  dispatch(holdLotAction());

  return axios
    .patch(`${API_HOST_AUTH}/lots/${lotId}/hold`)
    .then(res => {
      dispatch(holdLotSuccessAction());
      dispatch(showSnackbar(successOptions, res.data.message));
    })
    .catch(() => {
      dispatch(holdLotFailureAction());
      dispatch(showSnackbar(dangerOptions, LOTS.ERRORS.MOVE_TO_HOLD_ERROR));
    });
};

export { holdLot, holdLotActionType, holdLotSuccessActionType, holdLotFailureActionType };
