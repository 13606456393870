import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getBlanketHedgeCountActionType = 'GET_BLANKET_HEDGE_COUNT';
const getBlanketHedgeCountAction = () => ({
  type: getBlanketHedgeCountActionType,
});

const getBlanketHedgeCountSuccessActionType = `${getBlanketHedgeCountActionType}_SUCCESS`;
const getBlanketHedgeCountSuccessAction = blanketHedgeCount => ({
  type: getBlanketHedgeCountSuccessActionType,
  payload: {
    blanketHedgeCount,
  },
});

const getBlanketHedgeCountFailureActionType = `${getBlanketHedgeCountActionType}_FAILURE`;
const getBlanketHedgeCountFailureAction = () => ({
  type: getBlanketHedgeCountFailureActionType,
});

const clearBlanketHedgeCountActionType = 'CLEAR_BLANKET_HEDGE_COUNT';
const clearBlanketHedgeCount = () => ({
  type: clearBlanketHedgeCountActionType,
});

const getBlanketHedgeCount = () => async dispatch => {
  dispatch(getBlanketHedgeCountAction());

  try {
    const { data } = await axios.get(`${API_HOST_AUTH}/companies/blanket-hedge-count`);
    dispatch(getBlanketHedgeCountSuccessAction(data.blanketHedgeCount));
  } catch (error) {
    dispatch(getBlanketHedgeCountFailureAction());

    return Promise.reject(error.data);
  }
};

export {
  getBlanketHedgeCountActionType,
  getBlanketHedgeCountAction,
  getBlanketHedgeCountSuccessActionType,
  getBlanketHedgeCountSuccessAction,
  getBlanketHedgeCountFailureActionType,
  getBlanketHedgeCountFailureAction,
  clearBlanketHedgeCountActionType,
  clearBlanketHedgeCount,
  getBlanketHedgeCount,
};
