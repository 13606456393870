const systemSettingsBreadcrumbsRoutes = {
  '/settings': 'System settings',
  '/settings/price-feeds': 'Price feeds',
  '/settings/price-feeds/:id': null,
  '/settings/price-feeds/:id/update': 'Update price feed',
  '/settings/market-indicators': null,
  '/settings/market-indicators/logs': 'Log',
  '/settings/special-permissions': null,
  '/settings/special-permissions/logs': 'Special permissions log',
};

export { systemSettingsBreadcrumbsRoutes };
