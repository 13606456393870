import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { downloadAttachment } from 'actions/Attachments/downloadAttachment';
import { getLot } from 'actions/Lots/lotDetails';
import { approveProfitAndLoss, denyProfitAndLoss } from 'actions/Lots/manageGlobalFiles';
import {
  clearPackagesOverview,
  getPackagesOverviewForLots,
} from 'actions/Packages/packagesOverviewList';

import { UIAuthorization } from 'components/Authorization/UIAuthorization';

import {
  actionsTemplateCustomer,
  itemsDetailsTemplateBase,
  itemsTemplateCustomer,
  itemsTemplateInternalCustomer,
  itemsTemplateMobile,
} from 'shared/helpers/constants/hedges/overviewTableConfig';
import { globalFileTypes } from 'shared/helpers/constants/lots/globalFileTypes';
import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';
import { isInternalCompany } from 'shared/helpers/matchers/checkCompanyType';

import { PaymentsOverview } from 'pages/Lots/components/LotDetails/components/components/PaymentsOverview/PaymentsOverview';

import { ManageGlobalFiles } from '../LotDetailsGlobal/components/ManageGlobalFiles';
import { MainLotDetails } from '../MainDetails/MainDetails';
import {
  CommoditiesOverview,
  CustomerPackagesOverview,
  GlobalPackagesOverview,
  HedgesOverview,
  SettlementsOverview,
  ShipmentsOverview,
} from '../components';
import { CustomerView, InternalCustomerView } from './components';

export const LotDetailsCustomer = ({ auth, lot, hedgesOverviewList, packagesOverviewList }) => {
  const companyType = auth.user.relatedCompany.companyType;

  const dispatch = useDispatch();
  const params = useParams();

  const handleApproveProfitAndLoss = async id => {
    const res = await dispatch(approveProfitAndLoss(id));
    await dispatch(getLot(lot._id));
    return res;
  };

  const handleDownloadAttachment = path => {
    dispatch(downloadAttachment(path));
  };

  const getPackagesOverviewForLotsAction = (id, limit, section) =>
    dispatch(getPackagesOverviewForLots(id, limit, section));

  const clearPackagesOverviewAction = useCallback(
    sectionType => dispatch(clearPackagesOverview(sectionType)),
    [],
  );

  return (
    <>
      <MainLotDetails lot={lot} auth={auth} packagesList={packagesOverviewList.packagesList} />
      {isInternalCompany(companyType) ? (
        <InternalCustomerView lot={lot} auth={auth} />
      ) : (
        <CustomerView lot={lot} auth={auth} />
      )}
      <ShipmentsOverview />
      {isInternalCompany(companyType) ? (
        <GlobalPackagesOverview
          lotId={params.lotId}
          getPackagesOverviewForLots={getPackagesOverviewForLotsAction}
          clearPackagesOverview={clearPackagesOverviewAction}
          packagesOverviewList={packagesOverviewList}
          auth={auth}
          match={{
            params,
          }}
        />
      ) : (
        <CustomerPackagesOverview packagesOverviewList={packagesOverviewList} auth={auth} />
      )}
      <UIAuthorization access={auth.access.lots.details.hedgesList}>
        <HedgesOverview
          hedgesOverviewList={hedgesOverviewList}
          itemsTemplate={
            isInternalCompany(companyType) ? itemsTemplateInternalCustomer : itemsTemplateCustomer
          }
          actionsTemplate={actionsTemplateCustomer(handleDownloadAttachment, hedgesOverviewList)}
          itemsTemplateMobile={itemsTemplateMobile}
          itemDetailsTemplate={itemsDetailsTemplateBase}
        />
      </UIAuthorization>
      <UIAuthorization
        access={
          lot.grgProfitAndLossDraftData?.file && params.fileType === globalFileTypes.profitAndLoss
        }
      >
        <ManageGlobalFiles
          fileLabel={LOTS.PROFIT_AND_LOSS}
          approveFileAction={handleApproveProfitAndLoss}
          denyFileAction={denyProfitAndLoss}
          draftFile={lot.grgProfitAndLossDraftData?.file}
          modalHeader={LOTS.MANAGE_PROFIT_AND_LOSS_HEADER(lot.grgLotNumber)}
          modalInfo={LOTS.MANAGE_PROFIT_AND_LOSS_INFO(lot.grgLotNumber)}
        />
      </UIAuthorization>
      <UIAuthorization access={auth.access.lots.details.paymentsList}>
        <PaymentsOverview />
      </UIAuthorization>
      {isInternalCompany(companyType) && <CommoditiesOverview auth={auth} />}
      <UIAuthorization access={auth.access.settlements.list}>
        <SettlementsOverview />
      </UIAuthorization>
    </>
  );
};

LotDetailsCustomer.propTypes = {
  auth: PropTypes.object,
  packagesOverviewList: PropTypes.object,
  lot: PropTypes.object,
  hedgesOverviewList: PropTypes.object,
};
