import React, { useRef } from 'react';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';

import { TooltipButton } from 'shared/components/Buttons/TooltipButton/TooltipButton';
import { HEDGES } from 'shared/helpers/constants/hedges/hedgesConstants';
import { statusesForGlobal } from 'shared/helpers/constants/lots/statusesForGlobal';
import { infoOptions } from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { dateFormat } from 'shared/helpers/parsers/date';
import { formatToUS } from 'shared/helpers/parsers/formaters';
import { humanize, replaceEmpty } from 'shared/helpers/parsers/text';
import { cssVar, pxToRem, isMobile } from 'shared/helpers/styling/styling';
import { useClickAway } from 'shared/hooks/useClickAway';

import dateAdapter from 'utils/date/dateAdapter';
import { history } from 'utils/history';

const Ounces = styled.div`
  div {
    padding-bottom: 8px;
  }
`;

const HedgeItemLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  min-height: 28px;
  padding: 5px 10px;
  border-radius: 9px;
  font-size: ${pxToRem(12)};
  color: ${cssVar('alabaster')};
  margin-bottom: 10px;
  background-color: ${cssVar('slateGrayGRG')};
  cursor: pointer;
  font-weight: 500;

  :hover {
    background-color: ${cssVar('baliHai')};
  }
`;

const Tooltip = styled(ReactTooltip)`
  background-color: ${cssVar('baliHai')} !important;
  color: ${cssVar('alabaster')} !important;
  border-radius: 10px !important;
  opacity: 1 !important;
  padding: 0 !important;
  font-size: ${pxToRem(14)};

  &.place-top {
    &:after {
      border-top-color: ${cssVar('baliHai')} !important;
      border-top-style: solid !important;
      border-top-width: 6px !important;
    }
  }
`;

const TooltipContainer = styled.div`
  height: 140px;
  width: 268px;
`;

const TooltipHeader = styled.div`
  background-color: ${cssVar('slateGrayGRG')};
  height: 50px;
  font-weight: 600;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom: 2px solid ${cssVar('towerGray')};
  display: flex;
  align-items: center;

  span {
    margin-left: 5px;
  }
`;

const TooltipTable = styled.table`
  border-style: hidden;
  width: 100%;
  height: 90px;

  td {
    padding-left: 5px;
    border: 2px solid ${cssVar('towerGray')};
  }
`;

const HedgeItem = ({ hedge }) => {
  const ref = useRef(null);
  let tooltipRef = null;

  useClickAway(ref, () => {
    return tooltipRef?.hideTooltip();
  });

  return (
    <div ref={ref}>
      <HedgeItemLayout data-tip data-for={hedge._id} data-event="click">
        {`${hedge.placedAt ? dateAdapter(hedge.placedAt).format('MM/DD') : '-'} - ${formatToUS(
          hedge.platinumOz,
          3,
        )}
        - ${formatToUS(hedge.palladiumOz, 3)} - ${formatToUS(hedge.rhodiumOz, 3)}`}
      </HedgeItemLayout>
      <Tooltip
        ref={ref => (tooltipRef = ref)}
        effect="solid"
        offset={isMobile() ? { left: 60 } : {}}
        id={hedge._id}
      >
        <TooltipContainer>
          <TooltipHeader>
            <span>{hedge.hedgeName}</span>
          </TooltipHeader>
          <TooltipTable>
            <tbody>
              <tr>
                <td colSpan={3}>
                  {`${HEDGES.DATE_PLACED}: ${
                    hedge.placedAt ? dateAdapter(hedge.placedAt).format(dateFormat) : '-'
                  }`}
                </td>
              </tr>
              <tr>
                <td>{`${HEDGES.PT}: ${formatToUS(hedge.platinumOz, 3)}`}</td>
                <td>{`${HEDGES.PD}: ${formatToUS(hedge.palladiumOz, 3)}`}</td>
                <td>{`${HEDGES.RH}: ${formatToUS(hedge.rhodiumOz, 3)}`}</td>
              </tr>
            </tbody>
          </TooltipTable>
        </TooltipContainer>
      </Tooltip>
    </div>
  );
};

const calculateHedgedOunce = (hedges, deliveries, field) =>
  hedges.reduce((acc, h) => acc + h[field], 0) + deliveries.reduce((acc, d) => acc + d[field], 0);

const getPlacedAt = hedgeOrDelivery =>
  hedgeOrDelivery.relatedHedge
    ? hedgeOrDelivery.relatedHedge.placedAt
    : hedgeOrDelivery.placedAt || Infinity;

const mergeHedgesDeliveries = (hedges, deliveries) =>
  [...hedges, ...deliveries.map(d => ({ ...d.relatedHedge, ...d }))].sort(
    (hedge1, hedge2) => getPlacedAt(hedge2) - getPlacedAt(hedge1),
  );

const itemsTemplate = [
  {
    label: HEDGES.HEDGE_LOTS.LOT_NUMBER,
    render: ({ grgLotNumber }) => grgLotNumber,
  },
  {
    label: HEDGES.HEDGE_LOTS.NET_LBS,
    render: ({ weightNetActual }) =>
      replaceEmpty(weightNetActual && `${formatToUS(weightNetActual)} lbs`),
  },
  {
    label: HEDGES.HEDGE_LOTS.MATERIAL,
    render: ({ materialType }) => replaceEmpty(humanize(materialType)),
  },
  {
    label: HEDGES.HEDGE_LOTS.EST_OZ,
    render: ({ suggestedValues }) => (
      <Ounces>
        <div>
          {`${HEDGES.PT}: ${replaceEmpty(
            suggestedValues && formatToUS(suggestedValues.platinumOz, 3),
          )}`}
        </div>
        <div>
          {`${HEDGES.PD}: ${replaceEmpty(
            suggestedValues && formatToUS(suggestedValues.palladiumOz, 3),
          )}`}
        </div>
        <div>
          {`${HEDGES.RH}: ${replaceEmpty(
            suggestedValues && formatToUS(suggestedValues.rhodiumOz, 3),
          )}`}
        </div>
      </Ounces>
    ),
  },
  {
    label: HEDGES.HEDGE_LOTS.HEDGED_OZ,
    render: ({ hedges, deliveries }) => (
      <Ounces>
        <div>
          {`${HEDGES.PT}: ${formatToUS(calculateHedgedOunce(hedges, deliveries, 'platinumOz'), 3)}`}
        </div>
        <div>
          {`${HEDGES.PD}: ${formatToUS(
            calculateHedgedOunce(hedges, deliveries, 'palladiumOz'),
            3,
          )}`}
        </div>
        <div>
          {`${HEDGES.RH}: ${formatToUS(calculateHedgedOunce(hedges, deliveries, 'rhodiumOz'), 3)}`}
        </div>
      </Ounces>
    ),
  },
  {
    label: HEDGES.HEDGE_LOTS.HEDGES,
    render: ({ hedges, deliveries }) =>
      hedges.length || deliveries.length ? (
        <>
          {mergeHedgesDeliveries(hedges, deliveries).map(hedge => (
            <HedgeItem hedge={hedge} key={hedge._id} />
          ))}
        </>
      ) : (
        '-'
      ),
  },
];

const itemsTemplateMobile = [
  {
    label: HEDGES.HEDGE_LOTS.LOT_NUMBER,
    render: ({ grgLotNumber }) => grgLotNumber,
  },
  {
    label: HEDGES.HEDGE_LOTS.NET_LBS,
    render: ({ weightNetActual }) => replaceEmpty(weightNetActual && `${weightNetActual} lbs`),
  },
];

const [, , ...tableDetailsTemplate] = itemsTemplate;

const allowedLotStatuses = [
  statusesForGlobal.received,
  statusesForGlobal.accepted_for_grading,
  statusesForGlobal.grading_completed,
  statusesForGlobal.ready_for_processing,
  statusesForGlobal.accepted_for_processing,
  statusesForGlobal.ready_for_mixing,
  statusesForGlobal.accepted_for_mixing,
  statusesForGlobal.ready_to_ship,
  statusesForGlobal.shipped_from_global,
  statusesForGlobal.assay_results_received,
  statusesForGlobal.ship_summary_generated,
  statusesForGlobal.ship_summary_accepted,
];

const actionsTemplate = (shipmentId, marketAvailabilityDetails, showSnackbar) => [
  {
    icon: 'new',
    action: HEDGES.HEDGE_LOTS.ASSIGN_HEDGE,
    tooltip: {
      label: HEDGES.HEDGE_LOTS.ASSIGN_HEDGE,
      effect: 'solid',
    },
    Component: TooltipButton,
    access: ({ statusForGlobal }) => allowedLotStatuses.includes(statusForGlobal),
    onClick:
      ({ _id, grgLotNumber }) =>
      () =>
        history.push('/hedges/assign-ounces', {
          relatedLot: {
            value: _id,
            label: grgLotNumber,
            shipmentId,
          },
        }),
  },
  {
    icon: 'add',
    action: HEDGES.HEDGE_LOTS.REQUEST_HEDGE,
    tooltip: {
      label: HEDGES.HEDGE_LOTS.REQUEST_HEDGE,
      effect: 'solid',
    },
    Component: TooltipButton,
    access: ({ statusForGlobal }) => allowedLotStatuses.includes(statusForGlobal),
    onClick:
      ({ sentFromCompany, ...props }) =>
      () => {
        const { marketHoliday, inMarketHours, isBusinessDay, marketDays, marketHours, timezone } =
          marketAvailabilityDetails;
        const marketInfo = { marketDays, marketHours, timezone };

        if (marketHoliday || !inMarketHours || !isBusinessDay) {
          const warningInfo = marketHoliday
            ? HEDGES.MATERIAL_COUNT_MARKET_HOLIDAYS_WARNING
            : HEDGES.MATERIAL_COUNT_MARKET_HOURS_WARNING(marketInfo);

          showSnackbar(infoOptions, warningInfo);
        }

        return history.push('/hedges/create', {
          relatedLot: {
            value: props._id,
            label: props.grgLotNumber,
            receivedAt: props.receivedAt,
            statusForGlobal: props.statusForGlobal,
            statusForCustomer: props.statusForCustomer,
            relatedCompany: sentFromCompany,
            shipmentId,
          },
        });
      },
  },
];

export { itemsTemplateMobile, actionsTemplate, itemsTemplate, tableDetailsTemplate };
