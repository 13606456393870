const hedgesBreadcrumbsRoutes = {
  '/hedges': 'Hedges',
  '/hedges/:id': 'Hedge details',
  '/hedges/:id/create-note': 'Create note',
  '/hedges/list': 'List',
  '/hedges/create': 'Request hedge(s)',
  '/hedges/default-ppms': 'Default PPMs',
  '/hedges/market-holidays': 'Market holidays',
  '/hedges/market-holidays/create': 'Add market holiday',
  '/hedges/trusted-companies': 'Trusted companies',
  '/hedges/trusted-companies/create': 'Add trusted company',
  '/hedges/recipients': 'Recipients',
  '/hedges/recipients/create': 'Add recipient',
  '/hedges/assign-ounces': 'Assign ounces',
  '/hedges/hedge-ounces': 'Hedge ounces',
  '/hedges/:id/prices': 'Enter prices',
  '/hedges/:id/custom-prices': 'Enter customer prices',
  '/hedges/download': null,
  '/hedges/download/:id': null,
  '/hedges/download/:id/:fileName': null,
};

export { hedgesBreadcrumbsRoutes };
