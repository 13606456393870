import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { Authorization } from 'components/Authorization/Authorization';
import { NotFound } from 'components/ErrorPage/ErrorPage';

import { ConnectedAgreementsDetails } from './components/AgreementsDetails/ConnectedAgreementsDetails';
import { ConnectedAgreementsList } from './components/AgreementsList/ConnectedAgreementsList';

function Agreements(props) {
  const { agreements } = props.auth.access;

  const AuthTermsList = Authorization(ConnectedAgreementsList, agreements.list);
  const AuthAgreementsDetails = Authorization(ConnectedAgreementsDetails, agreements.details);

  return (
    <Switch>
      <Route exact path="/agreements/:companyId" component={AuthTermsList} />
      <Route exact path="/agreements/:companyId/agreement/:id" component={AuthAgreementsDetails} />
      <Route exact path="/agreements/:companyId/download/:id" component={AuthTermsList} />
      <Route component={NotFound} />
    </Switch>
  );
}

export { Agreements };
