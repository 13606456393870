import { connect } from 'react-redux';

import { deletePayment as deletePaymentAction } from 'actions/Payments/deletePayment';
import { openModal } from 'actions/shared/modal';
import { showSnackbar } from 'actions/shared/snackbar';

import {
  deletePaymentModal,
  deletePaymentRequestModal,
} from 'shared/helpers/constants/modalConstants';
import { isOwnerUser } from 'shared/helpers/matchers/checkUserType';
import { snackbarOptions } from 'shared/helpers/matchers/snackbar/snackbarOptions';

import { history } from 'utils/history';

function DeletePaymentLayout(props) {
  const deletePayment = () => {
    props.openModal(
      isOwnerUser(props.userType) ? deletePaymentModal : deletePaymentRequestModal,
      () => {
        props.deletePaymentAction(props.payment._id, props.userType).then(res => {
          history.push('/payments');
          props.showSnackbar(snackbarOptions(res.messageType), res.message);
        });
      },
    );
  };

  return props.children({ deletePayment });
}

const mapDispatchToProps = {
  openModal,
  showSnackbar,
  deletePaymentAction,
};

const DeletePayment = connect(null, mapDispatchToProps)(DeletePaymentLayout);

export { DeletePayment, DeletePaymentLayout };
