import { companyTypesEnum } from 'shared/helpers/constants/filters/companyTypes';
import { dateToTimestamp } from 'shared/helpers/parsers/date';

function parseCompanyUpdateData(company, companyFiles) {
  const data = new FormData();
  const { files, existingFiles } = companyFiles;

  let parsedExistingCompanyFile = {};

  if (files) {
    Object.keys(files).forEach(key => {
      if (files[key] !== null) {
        return files[key].forEach(file => {
          return data.append(key, file);
        });
      }
    });
  }

  if (existingFiles) {
    for (const key in existingFiles) {
      Object.assign(parsedExistingCompanyFile, {
        [key]: existingFiles[key].map(file => ({
          fileExpiresAt: file.fileExpiresAt,
          _id: file._id,
          relatedFile: file.relatedFile._id,
          createdBy: file.createdBy,
          createdAt: file.createdAt,
        })),
      });
    }
  }

  const parsedCompany = {
    companyName: company.companyName,
    volume: company.volume,
    materialCountRequired: company.materialCountRequired,
    samplesAllowed: company.samplesAllowed,
    purchasingAppURL: company.purchasingAppURL,
    lotPrefix: company.lotPrefix,
    ...(company?.companyType?.value !== companyTypesEnum.grading && {
      onlyInternalHedgingAndSettlement: company.onlyInternalHedgingAndSettlement,
    }),
    photoIdExpiresAt: dateToTimestamp(company.photoIdExpiresAt),
    documentForAMLExpiresAt: dateToTimestamp(company.documentForAMLExpiresAt),
    businessLicenseExpiresAt: dateToTimestamp(company.businessLicenseExpiresAt),
    existingFiles: JSON.stringify(parsedExistingCompanyFile),
  };

  Object.keys(parsedCompany).forEach(field => {
    if (Array.isArray(parsedCompany[field])) {
      return data.append(field, JSON.stringify(parsedCompany[field]));
    } else if (parsedCompany[field] !== undefined && parsedCompany[field] !== null) {
      return data.append(field, parsedCompany[field]);
    }
  });

  return data;
}

export { parseCompanyUpdateData };
