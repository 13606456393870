import { connect } from 'react-redux';

import { generateUsersReport } from 'actions/Users/generateUsersReport';
import {
  accessLevelFilter,
  userFiltersClear,
  userStatusFilter,
  userTypeFilter,
} from 'actions/Users/userFilters';
import { clearUsersList, getUsers } from 'actions/Users/usersList';

import { UsersList } from './UsersList';

const mapStateToProps = ({ auth, router, usersList, userFilters }) => ({
  auth,
  router,
  usersList,
  userFilters,
});

const mapDispatchToProps = {
  getUsers,
  clearUsersList,
  userFiltersClear,
  userTypeFilter,
  accessLevelFilter,
  userStatusFilter,
  generateUsersReport,
};

export const ConnectedUsersList = connect(mapStateToProps, mapDispatchToProps)(UsersList);
