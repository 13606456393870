import React, { useCallback } from 'react';
import { Field } from 'redux-form';

import { AddButton } from 'shared/components/Buttons/AddButton/AddButton';
import { IconButton } from 'shared/components/Buttons/IconButton/IconButton';
import { FieldInput, FieldSelect } from 'shared/components/Fields';
import { Section, Separator } from 'shared/components/Form';
import {
  PROFIT_LOSS,
  lotAdjustmentActionTypes,
} from 'shared/helpers/constants/lots/profitLossConstants';
import { mapArray } from 'shared/helpers/parsers/select';
import { cssVar } from 'shared/helpers/styling/styling';

const mapSelectData = data => mapArray(Object.values(data));

const deleteAction = (fields, index) => (
  <IconButton onClick={() => fields.remove(index)} icon="icon-cancel" color={cssVar('whiteGRG')} />
);

const LotAdjustments = ({ fields }) => {
  const addSection = useCallback(() => fields.push({}), [fields]);

  return (
    <>
      {fields.map((field, index) => (
        <Section
          header={`${PROFIT_LOSS.LOT_ADJUSTMENT} ${index + 1}`}
          template={[
            `${field}.lotAdjustmentAction ${field}.lotAdjustmentDescription`,
            `${field}.lotAdjustmentValue .`,
          ]}
          key={field}
          actions={deleteAction(fields, index)}
        >
          <Field
            name={`${field}.lotAdjustmentAction`}
            component={FieldSelect}
            options={mapSelectData(lotAdjustmentActionTypes)}
            label={PROFIT_LOSS.ACTION}
            field="required"
          />
          <Field
            name={`${field}.lotAdjustmentDescription`}
            component={FieldInput}
            field="required"
            label={PROFIT_LOSS.DESCRIPTION}
          />
          <Field
            name={`${field}.lotAdjustmentValue`}
            component={FieldInput}
            label={PROFIT_LOSS.VALUE}
            field="required"
            type="text"
            prefix="$"
          />
        </Section>
      ))}
      {fields.length > 0 && <Separator />}
      <AddButton onClick={addSection}>{PROFIT_LOSS.ADD_LOT_ADJUSTMENT}</AddButton>
    </>
  );
};

export { LotAdjustments };
