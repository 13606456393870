import axios from 'axios';

import { globalFileTypes } from 'shared/helpers/constants/lots/globalFileTypes';

const API_HOST_AUTH = process.env.REACT_APP_API;

const manageGlobalFilesType = 'MANAGE_GLOBAL_FILE';
const manageGlobalFilesAction = () => ({
  type: manageGlobalFilesType,
});

const manageGlobalFilesSuccessType = `${manageGlobalFilesType}_SUCCESS`;
const manageGlobalFilesSuccessAction = lotDetails => ({
  type: manageGlobalFilesSuccessType,
  payload: {
    ...lotDetails,
  },
});

const manageGlobalFilesFailureType = `${manageGlobalFilesType}_FAILURE`;
const manageGlobalFilesFailureAction = () => ({
  type: manageGlobalFilesFailureType,
});

const manageGlobalFilesFactory = (action, fileType) => lotId => dispatch => {
  dispatch(manageGlobalFilesAction());

  return axios
    .patch(`${API_HOST_AUTH}/lots/${fileType}/${lotId}/${action}`)
    .then(res => {
      dispatch(manageGlobalFilesSuccessAction(res.data.lot));

      return res.data;
    })
    .catch(err => {
      dispatch(manageGlobalFilesFailureAction());

      return Promise.reject(err.data);
    });
};

const approveFinalInvoice = manageGlobalFilesFactory('approve', globalFileTypes.finalInvoice);
const denyFinalInvoice = manageGlobalFilesFactory('deny', globalFileTypes.finalInvoice);
const approveShipmentSummary = manageGlobalFilesFactory('approve', globalFileTypes.shipmentSummary);
const denyShipmentSummary = manageGlobalFilesFactory('deny', globalFileTypes.shipmentSummary);
const approveProfitAndLoss = manageGlobalFilesFactory('approve', globalFileTypes.profitAndLoss);
const denyProfitAndLoss = manageGlobalFilesFactory('deny', globalFileTypes.profitAndLoss);
const overwriteApproveShipmentSummary = manageGlobalFilesFactory(
  'approve',
  globalFileTypes.shipmentSummaryOverwrite,
);
const overwriteDenyShipmentSummary = manageGlobalFilesFactory(
  'deny',
  globalFileTypes.shipmentSummaryOverwrite,
);

export {
  manageGlobalFilesType,
  manageGlobalFilesSuccessType,
  manageGlobalFilesFailureType,
  manageGlobalFilesFactory,
  approveFinalInvoice,
  denyFinalInvoice,
  approveShipmentSummary,
  denyShipmentSummary,
  approveProfitAndLoss,
  denyProfitAndLoss,
  overwriteApproveShipmentSummary,
  overwriteDenyShipmentSummary,
};
