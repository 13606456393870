import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { getPDFDocument } from 'actions/Files/getPDFDocument';
import { showPDFPreview } from 'actions/shared/PDFPreview';

import { Button } from 'shared/components/Buttons/Button';
import { PreviewButton } from 'shared/components/Buttons/PreviewButton';
import { withFieldWrapper } from 'shared/components/Fields/FieldWrapper/FieldWrapper';
import { Icon } from 'shared/components/Icon/Icon';
import { toBase64 } from 'shared/helpers/dataParsers/files/parseMultipleFiles';
import { cssVar } from 'shared/helpers/styling/styling';
import { PDF_REGEX, FILE_EXTENSION } from 'shared/helpers/validations/regularExpressions';

const FieldUploadListItem = ({
  file,
  fileToUpload,
  onDelete,
  className,
  previewIcoInside,
  revertPosition,
}) => {
  const dispatch = useDispatch();

  const handlePreview = async () => {
    if (file instanceof File) {
      const base64 = await toBase64(file);
      dispatch(showPDFPreview(base64));
    } else if (fileToUpload) {
      const base64 = await toBase64(fileToUpload);
      dispatch(showPDFPreview(base64));
    } else {
      dispatch(getPDFDocument(file.relatedFile?._id || file._id));
    }
  };

  return (
    <>
      <div className={className}>
        <StyledSpan>{file.name}</StyledSpan>
        {onDelete && (
          <Button
            onClick={onDelete}
            fitToContent
            borderRadius={0}
            outlineColor="transparent"
            bgColorHover={cssVar('nevadaDarker')}
          >
            <Icon icon="icon-bin" />
          </Button>
        )}
      </div>
      {PDF_REGEX.test(file.name) || !FILE_EXTENSION.test(file.name) ? (
        <PreviewButton
          revertPosition={revertPosition}
          previewIcoInside={previewIcoInside}
          handleClick={handlePreview}
        />
      ) : null}
    </>
  );
};

const StyledSpan = styled.div`
  width: 180px;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const FieldUploadListItemStyled = withFieldWrapper(styled(FieldUploadListItem)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  word-break: break-all;
  min-height: 50px;
  font-size: 0.875rem;
  padding-left: 18px;
  color: ${cssVar('alabaster')};
  background-color: ${cssVar('shuttleGray')};
  border-radius: 5px;
  position: relative;

  ${Button} {
    height: auto;
    padding: 0 10px;
    border-radius: 0 5px 5px 0;
    border-left: 1px solid ${cssVar('nevadaDarker')};
    box-shadow: none;
  }

  ${Icon} {
    margin: 0;
  }
`);

FieldUploadListItem.propTypes = {
  file: PropTypes.object,
  fileToUpload: PropTypes.object,
  onDelete: PropTypes.func,
  className: PropTypes.string,
  previewIcoInside: PropTypes.bool,
  revertPosition: PropTypes.bool,
};

export { FieldUploadListItemStyled as FieldUploadListItem };
