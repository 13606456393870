import { CREATE_DELIVERY } from 'shared/helpers/constants/deliveries/createDeliveryConstansts';
import { safeParseFloat, roundUp } from 'shared/helpers/parsers/number';
import { replaceEmpty } from 'shared/helpers/parsers/text';
import { commaValidator } from 'shared/helpers/validations/commaValidator';
import { THREE_DECIMAL_REGEX } from 'shared/helpers/validations/regularExpressions';

import { evaluate } from 'utils/evaluate';

const validateDelivery = (delivery, index, remainingOunces) => {
  const errors = {};

  const {
    relatedHedge,
    relatedLot,
    platinumOz,
    palladiumOz,
    rhodiumOz,
    availablePlatinumOz,
    availablePalladiumOz,
    availableRhodiumOz,
  } = delivery;

  if (!relatedHedge || !replaceEmpty(relatedHedge.value, '').length) {
    errors.relatedHedge = CREATE_DELIVERY.RELATED_HEDGE_EMPTY;
  }

  if (!relatedLot || !replaceEmpty(relatedLot.value, '').length) {
    errors.relatedLot = CREATE_DELIVERY.RELATED_LOT_EMPTY;
  }

  if (
    [+platinumOz, +palladiumOz, +rhodiumOz].every(
      metal => replaceEmpty(metal, '').length > 0 && +evaluate(metal) === 0,
    )
  ) {
    errors.platinumOz = CREATE_DELIVERY.DELIVERY_EMPTY;
    errors.palladiumOz = CREATE_DELIVERY.DELIVERY_EMPTY;
    errors.rhodiumOz = CREATE_DELIVERY.DELIVERY_EMPTY;
  }

  if (!replaceEmpty(platinumOz, '').length) {
    errors.platinumOz = CREATE_DELIVERY.PLATINUM_OZ_EMPTY;
  }

  if (!replaceEmpty(platinumOz, '').length) {
    errors.platinumOz = CREATE_DELIVERY.PLATINUM_OZ_EMPTY;
  } else if (!THREE_DECIMAL_REGEX.test(platinumOz) || (platinumOz && platinumOz.length > 10)) {
    errors.platinumOz = CREATE_DELIVERY.PLATINUM_OZ_INVALID;
  } else if (safeParseFloat(platinumOz) < 0) {
    errors.platinumOz = CREATE_DELIVERY.PLATINUM_OZ_NEGATIVE;
  } else if (
    (remainingOunces &&
      remainingOunces.platinumIndex !== null &&
      index >= remainingOunces.platinumIndex) ||
    safeParseFloat(platinumOz) > safeParseFloat(availablePlatinumOz)
  ) {
    errors.platinumOz = CREATE_DELIVERY.PLATINUM_OZ_MAXIMUM;
  }

  if (!replaceEmpty(palladiumOz, '').length) {
    errors.palladiumOz = CREATE_DELIVERY.PALLADIUM_OZ_EMPTY;
  } else if (!THREE_DECIMAL_REGEX.test(palladiumOz) || (palladiumOz && palladiumOz.length > 10)) {
    errors.palladiumOz = CREATE_DELIVERY.PALLADIUM_OZ_INVALID;
  } else if (safeParseFloat(palladiumOz) < 0) {
    errors.palladiumOz = CREATE_DELIVERY.PALLADIUM_OZ_NEGATIVE;
  } else if (
    (remainingOunces &&
      remainingOunces.palladiumIndex !== null &&
      index >= remainingOunces.palladiumIndex) ||
    safeParseFloat(palladiumOz) > safeParseFloat(availablePalladiumOz)
  ) {
    errors.palladiumOz = CREATE_DELIVERY.PALLADIUM_OZ_MAXIMUM;
  }

  if (!replaceEmpty(rhodiumOz, '').length) {
    errors.rhodiumOz = CREATE_DELIVERY.RHODIUM_OZ_EMPTY;
  } else if (!THREE_DECIMAL_REGEX.test(rhodiumOz) || (rhodiumOz && rhodiumOz.length > 10)) {
    errors.rhodiumOz = CREATE_DELIVERY.RHODIUM_OZ_INVALID;
  } else if (safeParseFloat(rhodiumOz) < 0) {
    errors.rhodiumOz = CREATE_DELIVERY.RHODIUM_OZ_NEGATIVE;
  } else if (
    (remainingOunces &&
      remainingOunces.rhodiumIndex !== null &&
      index >= remainingOunces.rhodiumIndex) ||
    safeParseFloat(rhodiumOz) > safeParseFloat(availableRhodiumOz)
  ) {
    errors.rhodiumOz = CREATE_DELIVERY.RHODIUM_OZ_MAXIMUM;
  }

  commaValidator({ platinumOz, palladiumOz, rhodiumOz }, errors);

  return errors;
};

const validate = (values, { hedges }) => {
  const deliveries = values.deliveries || [];
  const relatedHedgesIds = deliveries.map(
    delivery => delivery.relatedHedge && delivery.relatedHedge.value,
  );

  const remainingOunces = (hedges || []).reduce(
    (map, hedge) =>
      relatedHedgesIds.includes(hedge._id)
        ? map.set(hedge._id, {
            platinumOz: hedge.platinumOz,
            palladiumOz: hedge.palladiumOz,
            rhodiumOz: hedge.rhodiumOz,
          })
        : map,
    new Map(),
  );

  deliveries.forEach((delivery, index) => {
    const hedgeId = delivery.relatedHedge && delivery.relatedHedge.value;

    if (!hedgeId) return;

    const weights = remainingOunces.get(hedgeId);

    const remaining = {
      platinumOz: +roundUp(weights.platinumOz - safeParseFloat(delivery.platinumOz || 0), 3),
      palladiumOz: +roundUp(weights.palladiumOz - safeParseFloat(delivery.palladiumOz || 0), 3),
      rhodiumOz: +roundUp(weights.rhodiumOz - safeParseFloat(delivery.rhodiumOz || 0), 3),
    };

    const newIndexes = {
      platinumIndex: remaining.platinumOz < 0 ? index : null,
      palladiumIndex: remaining.palladiumOz < 0 ? index : null,
      rhodiumIndex: remaining.rhodiumOz < 0 ? index : null,
    };

    remainingOunces.set(hedgeId, {
      platinumIndex:
        weights.platinumIndex !== null && weights.platinumIndex !== undefined
          ? weights.platinumIndex
          : newIndexes.platinumIndex,
      palladiumIndex:
        weights.palladiumIndex !== null && weights.palladiumIndex !== undefined
          ? weights.palladiumIndex
          : newIndexes.palladiumIndex,
      rhodiumIndex:
        weights.rhodiumIndex !== null && weights.rhodiumIndex !== undefined
          ? weights.rhodiumIndex
          : newIndexes.rhodiumIndex,
      ...remaining,
    });
  });

  return {
    deliveries: deliveries.map((delivery, index) =>
      validateDelivery(
        delivery,
        index,
        delivery.relatedHedge && remainingOunces.get(delivery.relatedHedge.value),
      ),
    ),
  };
};

export { validate };
