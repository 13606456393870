import React from 'react';

import { GroupButtons } from 'shared/components/Buttons';
import { Button } from 'shared/components/Buttons/Button';
import { GroupButton } from 'shared/components/Buttons/GroupButton/components/GroupButton';
import { Icon } from 'shared/components/Icon/Icon';
import { OverviewHeader } from 'shared/components/OverviewHeader';
import { OverviewListWrapper } from 'shared/components/OverviewListWrapper/OverviewListWrapper';
import { createShipmentsAccessButtonHandler } from 'shared/helpers/accesses/shipments/createShipmentsAccessButtonHandler';
import { PACKAGES } from 'shared/helpers/constants/packages/packageConstants';
import { SHARED } from 'shared/helpers/constants/sharedConstants';
import { overviewListsConfig } from 'shared/helpers/constants/shipments/overviewListsConfig';
import { shipmentDirections } from 'shared/helpers/constants/shipments/shipmentDirections';
import { SHIPMENTS } from 'shared/helpers/constants/shipments/shipmentsConstants';
import { isCustomerUser, isGradingUser } from 'shared/helpers/matchers/checkUserType';
import { cssVar } from 'shared/helpers/styling/styling';
import { useWindowWidth } from 'shared/hooks/useWindowWidth';

import { ShipmentsList } from 'pages/Shipments/components/ShipmentsList/ShipmentsList';

const renderActionButtons = (auth, companyLocations, history) => (
  <GroupButtons>
    <GroupButton
      id="newCustomerShipment"
      access={createShipmentsAccessButtonHandler(
        auth.access.shipments.create,
        companyLocations.data,
        auth.user.relatedCompany.status,
      )}
      onClick={() => history.push('/shipments/create')}
      label={SHIPMENTS.CREATE_A_SHIPMENT}
      icon="create-shipment"
    />
    <GroupButton
      id="newCustomerPackage"
      access={auth.access.packages.create}
      onClick={() => history.push('/shipments/packages/create')}
      label={PACKAGES.CREATE_PACKAGES}
      icon="create-package"
    />
    <GroupButton
      id="createSelfDeliveryRequest"
      access={auth.access.selfDeliveryRequests.create}
      onClick={() => history.push('/shipments/self-delivery-requests/create')}
      label={SHIPMENTS.CREATE_SELF_DELIVERY_REQUEST}
      icon="self-delivery"
    />
  </GroupButtons>
);

const listsTemplate = props => [
  {
    clearList: props.clearIncomingShipmentsOverview,
    getList: (page, limit) => props.getIncomingShipmentsOverview(limit),
    label: SHIPMENTS.SHIPMENTS,
    buttonRoute: '/shipments/list',
    itemsTemplate: overviewListsConfig.customerShipmentsItemsTemplate,
    itemsTemplateMobile: overviewListsConfig.customerShipmentsTemplateMobile,
    itemDetailsTemplate: overviewListsConfig.customerShipmentsItemsDetailsTemplate,
    actionsTemplate: overviewListsConfig.customerShipmentActionsTemplate,
    list: props.incomingShipments,
  },
  {
    clearList: props.clearSelfDeliveryRequestsOverview,
    getList: (page, limit) => props.getSelfDeliveryRequestsOverviewList(limit),
    label: SHIPMENTS.SELF_DELIVERY_REQUESTS,
    buttonRoute: '/shipments/self-delivery-requests',
    itemsTemplate: overviewListsConfig.customerSdRequestsItemsTemplate,
    itemsTemplateMobile: overviewListsConfig.customerSdRequestsTemplateMobile,
    itemDetailsTemplate: overviewListsConfig.customerSdRequestsItemsDetailsTemplate,
    actionsTemplate: overviewListsConfig.sdRequestsActionsTemplate(props.auth),
    list: props.selfDeliveryRequests,
  },
];

const CustomerShipmentsOverviewLayout = props => {
  const windowWidth = useWindowWidth();

  return (
    <>
      {isCustomerUser(props.auth.user.userType) && (
        <ShipmentsList
          getShipmentsList={props.getShipmentsList}
          shipmentDirection={shipmentDirections.incoming}
          {...props}
        />
      )}
      {isGradingUser(props.auth.user.userType) && (
        <>
          <OverviewHeader
            header={SHIPMENTS.SHIPMENTS}
            actions={renderActionButtons(props.auth, props.companyLocations, props.history)}
          />
          {listsTemplate(props).map(el => (
            <OverviewListWrapper
              {...props}
              {...el}
              key={el.label}
              windowWidth={windowWidth}
              button={
                <Button
                  id="listsButton"
                  onClick={() => props.history.push(el.buttonRoute)}
                  borderRadius={18}
                  outlineColor={cssVar('pelorous')}
                  margin={false}
                  bgColor={cssVar('outerSpaceTabs')}
                  padding="0px 36px"
                >
                  {SHARED.DETAILS}
                  <Icon
                    icon="icon-tiny-arrow-right"
                    color={cssVar('alabaster')}
                    margin="0 0 0 16px"
                  />
                </Button>
              }
            />
          ))}
        </>
      )}
    </>
  );
};

export { CustomerShipmentsOverviewLayout };
