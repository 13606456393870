import React, { Component } from 'react';
import styled from 'styled-components';

import { isRestrictedCustomer } from 'shared/helpers/matchers/checkCustomerAccessLevel';
import { isCustomerUser } from 'shared/helpers/matchers/checkUserType';
import { media } from 'shared/helpers/styling/styling';

import { NavbarElement } from './components/NavbarElement';

class NavbarLayout extends Component {
  componentDidMount() {
    const {
      poolAccountsList: { requestSent },
      subNavbar,
    } = this.props;

    if (
      isCustomerUser(this.props.auth.user.userType) &&
      !isRestrictedCustomer(this.props.auth.user.accessLevel) &&
      !requestSent &&
      !subNavbar
    ) {
      this.fetchList();
    }
  }

  fetchList = (page = 1, limit = 50, query) => {
    const {
      getPoolAccounts,
      poolAccountsList: { filters },
    } = this.props;

    getPoolAccounts({ ...filters, query }, { page, limit });
  };

  render() {
    const {
      closeOnClick,
      auth,
      poolAccountsList: { poolAccounts },
      className,
      navbarElements,
      subNavbar,
    } = this.props;

    return (
      <div className={className}>
        {navbarElements.map(item => (
          <NavbarElement
            {...item}
            key={item.id}
            subNavbar={subNavbar}
            auth={{ ...auth, poolAccounts }}
            closeOnClick={closeOnClick}
          />
        ))}
      </div>
    );
  }
}

const Navbar = styled(NavbarLayout)`
  display: flex;

  ${media.mobile`
    flex-direction: column;
  `}
`;

export { Navbar };
