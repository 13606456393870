const REQUEST_MARKET_INDICATORS = {
  TYPE_NOT_SELECTED: 'At least one type must be selected.',
};

const type = {
  hedging: 'hedging',
  settlement: 'settlement',
};

const buyerIndicatorTypes = {
  hedging: 'hedging',
};

export { type, buyerIndicatorTypes, REQUEST_MARKET_INDICATORS };
