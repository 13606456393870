import {
  paymentStatuses,
  deletableStatuses,
  customerDeletableStatuses,
} from 'shared/helpers/constants/payments/paymentStatuses';
import { paymentTypes } from 'shared/helpers/constants/payments/paymentTypes';
import { isInternalCompany } from 'shared/helpers/matchers/checkCompanyType';
import { isGlobalUserType } from 'shared/helpers/matchers/isGlobalUserType';

const paymentsAccess = (access, user) => access.payments.overview;

const deletePaymentAccess = (auth, payment) =>
  auth.access.payments.delete &&
  (isGlobalUserType(auth.user.userType)
    ? deletableStatuses.includes(payment.status)
    : customerDeletableStatuses.includes(payment.status));

const markPaymentAsPaidAccess = (auth, payment) =>
  auth.access.payments.markAsPaid &&
  (payment.paymentType === paymentTypes.pool
    ? payment.status === paymentStatuses.approved && payment.hasPoolAccount
    : payment.status === paymentStatuses.approved);

const changePaymentStatusAccess = (auth, payment) =>
  auth.access.payments.changeStatus && payment.status === paymentStatuses.submitted;

const createSettlementDocumentAccess = (auth, payment) =>
  auth.access.payments.createSettlement &&
  payment.paymentType === paymentTypes.pool &&
  payment.status === paymentStatuses.paid &&
  !payment.poolAccountSettlementAndBalanceDocument &&
  payment.settlement;

const showExistingPaymentsAccess = ({ status, relatedLotHasUnpaidPayments, paymentType }) =>
  status === paymentStatuses.submitted &&
  relatedLotHasUnpaidPayments &&
  paymentType === paymentTypes.final;

const reassignPaymentAccess = ({ access }, { status, paymentType }) => {
  const { reassignPayment } = access.specialPermissions.payments;
  const availableForPaymentTypes = [paymentTypes.advance, paymentTypes.grading];
  const availableForPaymentStatuses = [
    paymentStatuses.draft,
    paymentStatuses.submitted,
    paymentStatuses.approved,
    paymentStatuses.paid,
  ];

  return (
    reassignPayment &&
    availableForPaymentTypes.includes(paymentType) &&
    availableForPaymentStatuses.includes(status)
  );
};

const updateDraftFinalPaymentAccess = ({ access }, payment) => {
  const { update } = access.payments;
  const { relatedCompany, status, paymentType } = payment || {};

  const isFinalPayment = paymentType === paymentTypes.final;
  const isDraftPayment = status === paymentStatuses.draft;

  return (
    update && isInternalCompany(relatedCompany?.companyType) && isFinalPayment && isDraftPayment
  );
};

const updateDraftPoolPaymentAccess = ({ access }, payment) => {
  const { update } = access.payments;
  const { status, paymentType } = payment || {};

  const isPoolPayment = paymentType === paymentTypes.pool;
  const isDraftPayment = status === paymentStatuses.draft;

  return update && isDraftPayment && isPoolPayment;
};

const updateDraftPaymentAccess = (access, payment) => {
  return (
    updateDraftPoolPaymentAccess(access, payment) || updateDraftFinalPaymentAccess(access, payment)
  );
};

export {
  paymentsAccess,
  deletePaymentAccess,
  markPaymentAsPaidAccess,
  changePaymentStatusAccess,
  showExistingPaymentsAccess,
  createSettlementDocumentAccess,
  reassignPaymentAccess,
  updateDraftFinalPaymentAccess,
  updateDraftPoolPaymentAccess,
  updateDraftPaymentAccess,
};
