import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Transition } from 'react-transition-group';
import styled, { css } from 'styled-components';

import { deleteCalendarNote, deleteOneCalendarNote } from 'actions/Shipments/deleteCalendarNote';
import { getShipmentsCalendar } from 'actions/Shipments/shipmentsCalendar';
import { showSnackbar } from 'actions/shared/snackbar';

import { Button } from 'shared/components/Buttons';
import { IconButton } from 'shared/components/Buttons/IconButton/IconButton';
import { CALENDAR_NOTE } from 'shared/helpers/constants/calendarConstants';
import {
  dangerOptions,
  successOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { formatDate } from 'shared/helpers/parsers/formaters';
import { cssVar, pxToRem, media, isMobile } from 'shared/helpers/styling/styling';

const ListItem = styled.div`
  display: grid;
  grid-template-columns: 20% 40% 25% 15%;
  grid-template-rows: 3fr 1fr;
  font-size: ${pxToRem(12)};
  color: ${cssVar('ghostWhiteGRG')};
  background-color: ${cssVar('outerSpaceTabs')};
  min-height: 110px;
  max-height: ${isMobile ? 200 : 150}px;
  border-bottom: 2px solid rgba(255, 255, 255, 0.15);
  padding: 25px 0;
  overflow: hidden;

  ${IconButton} {
    max-height: 45px;
    max-width: 45px;
  }

  span:first-child {
    grid-area: 1 / 1 / 2 / 2;
    justify-self: center;
  }

  span:nth-child(2) {
    grid-area: 1 / 2 / 2 / 3;
    word-break: break-all;
  }

  span:nth-child(3) {
    grid-area: 1 / 3 / 2 / 4;
  }

  .delete {
    grid-area: 1 / 4 / 2 / 5;
    ${media.tablet`
      top: 10px;
      left: 20px;
    `}

    ${media.desktop`
      top: 10px;
      left: 20px;
    `}
  }
`;

const CalendarNotesActions = ({ note, timestamp }) => {
  const [showActions, setShowActions] = useState(false);
  const dispatch = useDispatch();
  const { isPending } = useSelector(({ shipmentsCalendar }) => shipmentsCalendar);
  const { isPending: deleteNoteIsPending } = useSelector(
    ({ deleteCalendarNote }) => deleteCalendarNote,
  );

  const deleteNote = useCallback(() => {
    dispatch(deleteCalendarNote(note._id))
      .then(data => {
        dispatch(getShipmentsCalendar(timestamp));
        dispatch(showSnackbar(successOptions, data.message));
      })
      .catch(error => {
        dispatch(showSnackbar(dangerOptions, error.message));
      });
  }, []);

  const deleteOne = useCallback(() => {
    dispatch(deleteOneCalendarNote(note._id, timestamp))
      .then(data => {
        dispatch(getShipmentsCalendar(timestamp));
        dispatch(showSnackbar(successOptions, data.message));
      })
      .catch(error => {
        dispatch(showSnackbar(dangerOptions, error.message));
      });
  }, []);

  return (
    <>
      <IconButton className="delete" icon="icon-bin" onClick={() => setShowActions(!showActions)} />
      <Transition in={showActions} timeout={400}>
        {state => (
          <Actions state={state}>
            <Button
              outlineColor={cssVar('carnation')}
              onClick={deleteNote}
              disabled={isPending || deleteNoteIsPending}
            >
              {CALENDAR_NOTE.REMOVE_ALL}
            </Button>
            <Button
              outlineColor={cssVar('carnation')}
              onClick={deleteOne}
              disabled={isPending || deleteNoteIsPending}
            >
              {CALENDAR_NOTE.REMOVE_ONE}
            </Button>
          </Actions>
        )}
      </Transition>
    </>
  );
};

const Actions = styled.div`
  grid-area: 2 / 1 / 3 / 5;
  align-self: center;
  justify-self: center;
  display: flex;
  transition: max-height 0.4s ease-out, opacity 0.4s ease-out;

  ${Button}:first-child {
    margin-right: 15px;
  }

  ${({ state }) => {
    switch (state) {
      case 'entered':
      case 'exiting':
        return css`
          max-height: ${isMobile ? 120 : 60}px;
          opacity: 1;
        `;
      case 'entering':
      case 'exited':
      default:
        return css`
          max-height: 0;
          opacity: 0;
          visibility: hidden;
        `;
    }
  }};

  ${media.mobile`
    flex-direction: column;

    ${Button}:first-child {
      margin: 15px 0;
    }
  `}
`;

const NoteDuration = ({ note: { startDate, endDate } }) =>
  startDate === endDate
    ? formatDate(startDate)
    : `${formatDate(startDate)} - ${formatDate(endDate)}`;

const CalendarNotes = ({ notes, auth: { access } }) => (
  <>
    {notes.data.map(item => (
      <ListItem key={item._id}>
        <span>{`${item.createdBy.firstName} ${item.createdBy.lastName}`}</span>
        <span>{item.noteContents}</span>
        <span>
          {`${CALENDAR_NOTE.DURATION}: `}
          <NoteDuration note={item} />
        </span>
        {access.shipments.calendarNote.delete && (
          <CalendarNotesActions note={item} timestamp={notes.timestamp} />
        )}
      </ListItem>
    ))}
  </>
);

export { CalendarNotes };
