import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getShipmentsOverviewActionType = 'GET_SHIPMENTS_OVERVIEW_LIST';
function getShipmentsOverviewAction() {
  return {
    type: getShipmentsOverviewActionType,
  };
}

const getShipmentsOverviewSuccessActionType = `${getShipmentsOverviewActionType}_SUCCESS`;
function getShipmentsOverviewSuccessAction(shipments, pages, total) {
  return {
    type: getShipmentsOverviewSuccessActionType,
    payload: {
      shipments,
      pages,
      total,
    },
  };
}

const getShipmentsOverviewFailureActionType = `${getShipmentsOverviewActionType}_FAILURE`;
function getShipmentsOverviewFailureAction() {
  return {
    type: getShipmentsOverviewFailureActionType,
  };
}

const clearShipmentsOverviewActionType = 'CLEAR_SHIPMENTS_OVERVIEW_LIST';
function clearShipmentsOverviewAction() {
  return {
    type: clearShipmentsOverviewActionType,
  };
}

function getShipmentsOverview(lotId, limit) {
  return dispatch => {
    dispatch(getShipmentsOverviewAction());

    return axios
      .get(`${API_HOST_AUTH}/shipments/${lotId}/${limit}`)
      .then(res => {
        dispatch(getShipmentsOverviewSuccessAction(res.data.docs, res.data.pages, res.data.total));

        return res.data.docs;
      })
      .catch(() => dispatch(getShipmentsOverviewFailureAction()));
  };
}

function clearShipmentsOverview() {
  return dispatch => {
    dispatch(clearShipmentsOverviewAction());
  };
}

export {
  getShipmentsOverviewActionType,
  getShipmentsOverviewSuccessActionType,
  getShipmentsOverviewFailureActionType,
  clearShipmentsOverviewActionType,
  getShipmentsOverview,
  clearShipmentsOverview,
};
