import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { destroy as destroyAction } from 'redux-form';

import { createCompany as createCompanyAction } from 'actions/Companies/createCompany';
import { openModal as openModalAction } from 'actions/shared/modal';
import { showSnackbar as showSnackbarAction } from 'actions/shared/snackbar';

import { Wizard, formName } from 'shared/components/Wizard/Wizard';
import { COMPANY } from 'shared/helpers/constants/companies/companyConstants';
import { globalRefiningGroupName } from 'shared/helpers/constants/companies/globalRefiningGroup';
import { cancelModal } from 'shared/helpers/constants/modalConstants';
import {
  dangerOptions,
  successOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

import { AddAssociations } from './components/AddAssociations/AddAssociations';
import { AddCompany } from './components/AddCompany/AddCompany';
import { AddLocations } from './components/AddLocations/AddLocations';
import { AddUsers } from './components/AddUsers/AddUsers';
import { userHierarchy } from 'shared/helpers/accesses/config/createAccessLevels';

const steps = {
  company: AddCompany,
  locations: AddLocations,
  users: AddUsers,
  associations: AddAssociations,
};

const initialValues = {
  company: {
    grgDefaultShippingLocation: globalRefiningGroupName,
  },
  locations: [
    {
      shipmentEligible: true,
    },
  ],
  users: [{}],
};

function CreateCompanyWizardBase({ openModal, showSnackbar, createCompany, destroy, history }) {
  const onSubmit = useCallback(
    values => {
      const { grgDefaultShippingLocation, ...company } = values.company;
      const data = {
        ...values,
        company: {
          ...company,
          companyType: company.companyType.value,
        },
        locations: values.locations.map((location, index) => ({
          ...location,
          primaryLocation: !index,
          country: location.country.value,
          state:
            location.state instanceof Object
              ? location.state
              : {
                  label: location.state,
                  value: location.state,
                },
        })),
        users: values.users.map(user => ({
          ...user,
          userType: user.userType.value,
          accessLevel:
            user?.accessLevel?.value ?? userHierarchy.accessLevel[user.userType.value][0].value,
        })),
        associations: {
          primaryContacts: values.associations.primaryContacts.map(primaryContact => ({
            ...primaryContact,
            email: primaryContact.email && primaryContact.email.map(({ value }) => value),
          })),
          locations: values.associations.locations.map(location => ({
            ...location,
            lotSuffix: location.lotSuffix.map(({ value }) => value),
          })),
        },
      };

      return createCompany(data)
        .then(({ message, id }) => {
          showSnackbar(successOptions, message);
          history.push(`/companies/${id}`);
        })
        .catch(({ message }) => showSnackbar(dangerOptions, message));
    },
    [showSnackbar, createCompany, history],
  );

  const onCancel = useCallback(() => {
    openModal(cancelModal, () => {
      history.push('/companies');
    });
  }, [openModal, history]);

  useEffect(() => () => destroy(formName), [destroy]);

  return (
    <Wizard
      steps={steps}
      header={COMPANY.CREATING_COMPANY}
      onSubmit={onSubmit}
      initialValues={initialValues}
      onCancel={onCancel}
    />
  );
}

const mapDispatchToProps = {
  createCompany: createCompanyAction,
  showSnackbar: showSnackbarAction,
  openModal: openModalAction,
  destroy: destroyAction,
};

const CreateCompanyWizard = compose(
  withRouter,
  connect(null, mapDispatchToProps),
)(CreateCompanyWizardBase);

export { CreateCompanyWizard };
