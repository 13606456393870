const usersBreadcrumbsRoutes = {
  '/users': 'Users',
  '/users/create': 'Create user',
  '/users/:id': 'User details',
  '/users/:id/update': 'Update user',
  '/users/:id/change-password': 'Change password',
  '/users/:id/create-note': 'Create note',
};

export { usersBreadcrumbsRoutes };
