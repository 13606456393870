import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getRelatedSamplePackagesActionType = 'GET_RELATED_SAMPLE_PACKAGES';
const getRelatedSamplePackagesAction = () => ({
  type: getRelatedSamplePackagesActionType,
});

const getRelatedSamplePackagesSuccessActionType = `${getRelatedSamplePackagesActionType}_SUCCESS`;
const getRelatedSamplePackagesSuccessAction = packages => ({
  type: getRelatedSamplePackagesSuccessActionType,
  payload: {
    packages,
  },
});

const getRelatedSamplePackagesFailureActionType = `${getRelatedSamplePackagesActionType}_FAILURE`;
const getRelatedSamplePackagesFailureAction = () => ({
  type: getRelatedSamplePackagesFailureActionType,
});

const clearRelatedSamplePackagesActionType = 'CLEAR_RELATED_SAMPLE_PACKAGES';
const clearRelatedSamplePackages = () => ({
  type: clearRelatedSamplePackagesActionType,
});

const getRelatedSamplePackages = (relatedType, relatedId) => dispatch => {
  dispatch(getRelatedSamplePackagesAction());

  return axios
    .get(`${API_HOST_AUTH}/packages/samples/${relatedType}/${relatedId}`)
    .then(res => {
      dispatch(getRelatedSamplePackagesSuccessAction(res.data.packages));
      return res.data.packages;
    })
    .catch(() => dispatch(getRelatedSamplePackagesFailureAction()));
};

export {
  getRelatedSamplePackagesActionType,
  getRelatedSamplePackagesSuccessActionType,
  getRelatedSamplePackagesFailureActionType,
  clearRelatedSamplePackagesActionType,
  getRelatedSamplePackages,
  clearRelatedSamplePackages,
};
