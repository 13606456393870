import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const addLotToSettlementActionType = 'ADD_LOT_TO_SETTLEMENT';
const addLotToSettlementAction = id => ({
  type: addLotToSettlementActionType,
  payload: {
    id,
  },
});

const removeLotFromSettlementActionType = 'REMOVE_LOT_FROM_SETTLEMENT';
const removeLotFromSettlementAction = id => ({
  type: removeLotFromSettlementActionType,
  payload: {
    id,
  },
});

const removeAllLotsForSettlementActionType = 'REMOVE_ALL_LOTS_FOR_SETTLEMENT';
const removeAllLotsForSettlementAction = () => ({
  type: removeAllLotsForSettlementActionType,
});

const requestLotsSettlementActionType = 'REQUEST_LOTS_SETTLEMENT';
const requestLotsSettlementAction = () => ({
  type: requestLotsSettlementActionType,
});

const requestLotsSettlementSuccessActionType = `${requestLotsSettlementActionType}_SUCCESS`;
const requestLotsSettlementSuccessAction = () => ({
  type: requestLotsSettlementSuccessActionType,
});

const requestLotsSettlementFailureActionType = `${requestLotsSettlementActionType}_FAILURE`;
const requestLotsSettlementFailureAction = () => ({
  type: requestLotsSettlementFailureActionType,
});

const requestLotsSettlement = () => async (dispatch, getState) => {
  dispatch(requestLotsSettlementAction());

  try {
    const relatedLot = getState().requestLotsSettlement.lots;
    const res = await axios.post(`${API_HOST_AUTH}/payments/settle-lot`, {
      relatedLot,
    });

    dispatch(requestLotsSettlementSuccessAction());
    return res.data;
  } catch (error) {
    dispatch(requestLotsSettlementFailureAction());
    return Promise.reject(error.data);
  }
};

export {
  addLotToSettlementAction,
  addLotToSettlementActionType,
  removeLotFromSettlementAction,
  removeLotFromSettlementActionType,
  removeAllLotsForSettlementAction,
  removeAllLotsForSettlementActionType,
  requestLotsSettlementActionType,
  requestLotsSettlementAction,
  requestLotsSettlementSuccessActionType,
  requestLotsSettlementSuccessAction,
  requestLotsSettlementFailureActionType,
  requestLotsSettlementFailureAction,
  requestLotsSettlement,
};
