import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const createRecipientActionType = 'CREATE_RECIPIENT';
function createRecipientAction() {
  return {
    type: createRecipientActionType,
  };
}

const createRecipientSuccessActionType = `${createRecipientActionType}_SUCCESS`;
function createRecipientSuccessAction() {
  return { type: createRecipientSuccessActionType };
}

const createRecipientFailureActionType = `${createRecipientActionType}_FAILURE`;
function createRecipientFailureAction() {
  return {
    type: createRecipientFailureActionType,
  };
}

function createRecipient(recipient) {
  return dispatch => {
    dispatch(createRecipientAction());

    return axios({
      method: 'post',
      url: `${API_HOST_AUTH}/hedges-recipients`,
      data: JSON.stringify(recipient),
    })
      .then(() => dispatch(createRecipientSuccessAction()))
      .catch(error => {
        dispatch(createRecipientFailureAction());

        return Promise.reject(error);
      });
  };
}

export {
  createRecipient,
  createRecipientActionType,
  createRecipientSuccessActionType,
  createRecipientFailureActionType,
};
