import { createNoteSuccessActionType } from 'actions/Notes/createNote';
import {
  deleteNoteActionType,
  deleteNoteFailureActionType,
  deleteNoteSuccessActionType,
} from 'actions/Notes/deleteNote';
import {
  getNotesActionType,
  getNotesSuccessActionType,
  getNotesFailureActionType,
  clearNotesListActionType,
} from 'actions/Notes/notesList';

const INITIAL_STATE = {
  isPending: false,
  errorMessage: null,
  notes: {
    users: [],
    diligence: [],
    locationNote: [],
    questionnaireNote: [],
    processing: [],
    companies: [],
    agreements: [],
    lots: [],
    terms: [],
    shipments: [],
    hedges: [],
    notes: [],
  },
  pages: null,
  total: {
    users: 0,
    diligence: 0,
    locationNote: 0,
    questionnaireNote: 0,
    processing: 0,
    companies: 0,
    agreements: 0,
    lots: 0,
    terms: 0,
    shipments: 0,
    hedges: 0,
    notes: 0,
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case deleteNoteActionType:
    case getNotesActionType:
      return {
        ...state,
        isPending: true,
      };
    case getNotesSuccessActionType:
      return {
        ...state,
        isPending: false,
        notes: { ...state.notes, ...action.payload.notes },
        pages: action.payload.pages,
        total: {
          ...state.total,
          ...action.payload.total,
        },
      };
    case deleteNoteSuccessActionType:
      const { noteType, noteId } = action.payload;
      return {
        ...state,
        isPending: false,
        notes: {
          ...state.notes,
          [noteType]: state.notes[noteType].filter(note => note._id !== noteId),
        },
        total: {
          ...state.total,
          [noteType]: state.total[noteType] - 1,
        },
      };
    case deleteNoteFailureActionType:
    case getNotesFailureActionType:
      return {
        ...state,
        isPending: false,
        errorMessage: action.payload?.errorMessage,
      };
    case clearNotesListActionType:
      return {
        isPending: false,
        errorMessage: null,
        notes: INITIAL_STATE.notes,
        pages: null,
        total: INITIAL_STATE.total,
      };
    case createNoteSuccessActionType:
      return {
        ...state,
        notes: INITIAL_STATE.notes,
      };
    default:
      return state;
  }
};
