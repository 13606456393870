import axios from 'axios';

import { messageTypes } from 'shared/helpers/constants/snackbar/snackbarMessageTypes';
import { USER } from 'shared/helpers/constants/users/userConstants';

const API_HOST_AUTH = process.env.REACT_APP_API;

const inactivateUserActionType = 'INACTIVATE_USER';
const inactivateUserAction = () => ({
  type: inactivateUserActionType,
});

const inactivateUserSuccessActionType = `${inactivateUserActionType}_SUCCESS`;
const inactivateUserSuccessAction = ({ user }) => ({
  type: inactivateUserSuccessActionType,
  payload: {
    user,
  },
});

const inactivateUserFailureActionType = `${inactivateUserActionType}_FAILURE`;
const inactivateUserFailureAction = () => ({
  type: inactivateUserFailureActionType,
});

const inactivateUser = id => dispatch => {
  dispatch(inactivateUserAction());

  return axios
    .patch(`${API_HOST_AUTH}/users/inactivate/${id}`)
    .then(response => {
      dispatch(inactivateUserSuccessAction(response.data));

      return {
        message: response.data.message,
        messageType: messageTypes.success,
      };
    })
    .catch(error => {
      dispatch(inactivateUserFailureAction());

      return {
        message: `${USER.INACTIVATE_USER_ERROR} ${error.data.message}`,
        messageType: messageTypes.error,
      };
    });
};

export {
  inactivateUserActionType,
  inactivateUserAction,
  inactivateUserSuccessActionType,
  inactivateUserSuccessAction,
  inactivateUserFailureActionType,
  inactivateUserFailureAction,
  inactivateUser,
};
