import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';

import { FieldDate } from 'shared/components/Fields/FieldDate/FieldDate';
import { FieldSelect } from 'shared/components/Fields/FieldSelect/FieldSelect';
import { Form, Section } from 'shared/components/Form';
import { INSPECTIONS } from 'shared/helpers/constants/inspections/inspectionConstants';
import { inspectionTypes } from 'shared/helpers/constants/inspections/inspectionTypes';
import { cancelModal } from 'shared/helpers/constants/modalConstants';
import { parseLotsSelectData } from 'shared/helpers/dataParsers/lot/parseLotsSelectData';
import { validate } from 'shared/helpers/validations/lot/lotInspectionsValidations';

import dateAdapter from 'utils/date/dateAdapter';
import { goBackOrTo } from 'utils/history';

class LotInspectionsFormBase extends Component {
  componentDidMount() {
    const { getLotsForInspection, inspectionType } = this.props;

    getLotsForInspection(inspectionType);
  }

  componentWillUnmount() {
    this.props.clearAssignedLotsAction();
  }

  modalOpen = () => {
    this.props.openModal(cancelModal, () => {
      goBackOrTo('/lots/outgoing');
    });
  };

  render() {
    const { handleSubmit, inspectionType, assignedLots, invalid, submitting } = this.props;

    return (
      <Form
        header={INSPECTIONS.RELATED_LOT}
        onSubmit={handleSubmit}
        onCancel={this.modalOpen}
        submitDisabled={invalid || submitting}
      >
        <Section template={['relatedLot inspectionScheduledFor']}>
          <Field
            name="relatedLot"
            component={FieldSelect}
            options={parseLotsSelectData(assignedLots.data)}
            label={INSPECTIONS.RELATED_LOT}
            field="required"
          />
          {inspectionType === inspectionTypes.schedule && (
            <Field
              name="inspectionScheduledFor"
              component={FieldDate}
              label={INSPECTIONS.INSPECTION_SCHEDULED_FOR}
              field="required"
              minDate={dateAdapter()}
            />
          )}
        </Section>
      </Form>
    );
  }
}

const LotInspectionsForm = reduxForm({
  form: 'LotInspectionsForm',
  validate,
})(LotInspectionsFormBase);

export { LotInspectionsForm, LotInspectionsFormBase };
