import React from 'react';

import { UIAuthorization } from 'components/Authorization/UIAuthorization';

import { RouterButton, Button } from 'shared/components/Buttons';
import { ExtendableButton } from 'shared/components/Buttons/ExtendableButton/ExtendableButton';
import { DetailsHeader } from 'shared/components/Details/DetailsHeader';
import {
  userTypeAccessHandler,
  inactivateUserAuth,
} from 'shared/helpers/accesses/userTypeAccessHandler';
import { SHARED } from 'shared/helpers/constants/sharedConstants';
import { USER } from 'shared/helpers/constants/users/userConstants';

import { DeleteUser } from 'pages/Users/components/DeleteUser/DeleteUser';
import { InactivateUser } from 'pages/Users/components/InactivateUser/InactivateUser';
import { InviteUser } from 'pages/Users/components/InviteUserModal/InviteUser';

function UserMainDetails({ user, auth, companyId, history }) {
  const backToUsersList = () => history.push('/users');

  return (
    <DetailsHeader
      header={`${user.firstName} ${user.lastName}`}
      status={user.status}
      {...(!user.hasPassword && {
        tooltipContent: USER.USER_WITHOUT_PASSWORD,
        tooltipOptions: {
          place: 'top',
          scrollHide: false,
        },
        tooltipNeverHide: true,
        tooltipInitShow: true,
      })}
    >
      <ExtendableButton label={SHARED.EXTENDABLE_ACTIONS} closeOnClick>
        <UIAuthorization access={user.isInvitable}>
          <InviteUser userId={user._id}>
            {({ inviteUser }) => (
              <Button outline id="inviteUser" onClick={inviteUser}>
                {USER.INVITE_USER}
              </Button>
            )}
          </InviteUser>
        </UIAuthorization>
        <RouterButton
          id="changePassword"
          type="button"
          path={
            companyId
              ? `/companies/${companyId}/users/${user._id}/change-password`
              : `/users/${user._id}/change-password`
          }
          label={USER.CHANGE_PASSWORD}
        />
        <UIAuthorization
          access={userTypeAccessHandler(user.userType, auth.user.userType, auth.user.accessLevel)}
        >
          <RouterButton
            id="editUser"
            type="button"
            path={
              companyId
                ? `/companies/${companyId}/users/${user._id}/update`
                : `/users/${user._id}/update`
            }
            label={USER.EDIT_A_USER}
          />
        </UIAuthorization>
        <UIAuthorization access={inactivateUserAuth(auth, user)}>
          <InactivateUser user={user}>
            {({ inactivateUser }) => (
              <Button outline id="inactivateUser" onClick={inactivateUser}>
                {USER.INACTIVATE_USER}
              </Button>
            )}
          </InactivateUser>
        </UIAuthorization>
        <UIAuthorization access={auth.access.users.delete && user.isRemovable}>
          <DeleteUser user={user} onDelete={backToUsersList}>
            {({ deleteUser }) => (
              <Button outline id="deleteUser" color="danger" onClick={deleteUser}>
                {USER.DELETE_USER}
              </Button>
            )}
          </DeleteUser>
        </UIAuthorization>
      </ExtendableButton>
    </DetailsHeader>
  );
}

export { UserMainDetails };
