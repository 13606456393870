import { isFactory } from './matcherFactory';

const isExternalCompany = isFactory('external');
const isInternalCompany = isFactory('internal');
const isGradingCompany = isFactory('grading');
const isAssayCompany = isFactory('assay');
const isAssayOrGradingCompany = isFactory('assay', 'grading');

export {
  isExternalCompany,
  isInternalCompany,
  isGradingCompany,
  isAssayCompany,
  isAssayOrGradingCompany,
};
