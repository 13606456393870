import {
  compareBarcodesType,
  compareBarcodesSuccessType,
  compareBarcodesFailureType,
} from 'actions/Lots/compareBarcodes';

const INITIAL_STATE = {
  isPending: false,
};

const compareBarcodesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case compareBarcodesType:
      return {
        isPending: true,
      };
    case compareBarcodesSuccessType:
    case compareBarcodesFailureType:
      return {
        isPending: false,
      };
    default:
      return state;
  }
};

export { compareBarcodesReducer };
