import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Container } from 'shared/components/Fields/FieldWrapper/components/Container';
import { ErrorMessage } from 'shared/components/Fields/FieldWrapper/components/ErrorMessage';
import { Hint } from 'shared/components/Fields/FieldWrapper/components/Hint';
import { Label } from 'shared/components/Fields/FieldWrapper/components/Label';
import { themed } from 'shared/helpers/styling/styling';

const Content = styled.div`
  grid-area: input;
`;

const fieldTypes = {
  required: 'required',
  unique: 'unique',
};

const parseOptions = fieldOptions =>
  Object.entries(fieldTypes).reduce(
    (options, [key, value]) => ({
      ...options,
      [key]: fieldOptions.includes(value),
    }),
    {},
  );

const checkHintValue = value => value !== undefined && value !== null && value !== false;

const FieldWrapper = ({
  className,
  label,
  id,
  field,
  disabled,
  labelWidth,
  hint = null,
  hintClickable = false,
  input,
  slim,
  pretendMobile,
  lazyError = false,
  children,
  forceHint,
  withoutLabel = false,
  error,
}) => {
  const parsedField = typeof field === 'string' ? field : '';
  const fieldOptions = parsedField.split(' ');
  const parsedOptions = parseOptions(fieldOptions);

  return (
    <Container
      className={className}
      labelWidth={labelWidth}
      slim={slim}
      pretendMobile={pretendMobile}
      withoutLabel={withoutLabel}
    >
      {!withoutLabel && (
        <Label id={id} {...parsedOptions} disabled={disabled} pretendMobile={pretendMobile}>
          {label}
        </Label>
      )}
      <Content>{children}</Content>
      <ErrorMessage
        {...parsedOptions}
        show={error}
        label={label}
        labelWidth={labelWidth}
        pretendMobile={pretendMobile}
      >
        {error}
      </ErrorMessage>
      {((!disabled && hint && checkHintValue(hint.value)) || forceHint) && (
        <Hint input={input} hint={hint} isClickable={!disabled && hintClickable} />
      )}
    </Container>
  );
};

const FieldWrapperStyled = styled(FieldWrapper).attrs(themed(({ disabled }) => ({ disabled })))``;

FieldWrapperStyled.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  hint: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.any,
  }),
  hintClickable: PropTypes.bool,
  pretendMobile: PropTypes.bool,
  lazyError: PropTypes.bool,
  withoutLabel: PropTypes.bool,
  label: PropTypes.string,
  className: PropTypes.string,
};

const withFieldWrapper =
  (C, wrapperProps) =>
  ({ as: asComponent, ...props }) =>
    (
      <FieldWrapperStyled {...props} {...wrapperProps}>
        {asComponent ? <C {...props} as={asComponent} /> : <C {...props} />}
      </FieldWrapperStyled>
    );
export { FieldWrapperStyled as FieldWrapper, withFieldWrapper };
