/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import SmoothCollapse from 'react-smooth-collapse';

import {
  StyledRequirementMatcher,
  StyledRequirementMatcherList,
  StyledRequirementMatcherListRequirement,
} from './StyledRequirementMatcher';

class RequirementMatcher extends Component {
  static propTypes = {
    value: PropTypes.any,
    hideMet: PropTypes.bool,
    show: PropTypes.bool.isRequired,
    requirements: PropTypes.arrayOf(
      PropTypes.exact({
        name: PropTypes.string.isRequired,
        test: PropTypes.oneOfType([PropTypes.func, PropTypes.instanceOf(RegExp)]).isRequired,
      }).isRequired,
    ).isRequired,
  };

  static defaultProps = {
    value: '',
  };

  get testedRequirements() {
    return this.props.show
      ? this.props.requirements
          .map(req => ({ ...req, isMet: this.testForValidity(req.test) }))
          .filter(req => !(this.props.hideMet && req.isMet))
      : [];
  }

  testForValidity(test) {
    const { value } = this.props;

    return test instanceof RegExp ? test.test(value) : test(value);
  }

  render() {
    return (
      <StyledRequirementMatcher>
        <SmoothCollapse expanded={this.props.show}>
          <StyledRequirementMatcherList component="ul">
            {this.testedRequirements.map(({ name, isMet }) => (
              <StyledRequirementMatcherListRequirement key={name} timeout={300} unmountOnExit>
                <li>{name}</li>
              </StyledRequirementMatcherListRequirement>
            ))}
          </StyledRequirementMatcherList>
        </SmoothCollapse>
      </StyledRequirementMatcher>
    );
  }
}

export { RequirementMatcher };
