export const saveSharedDataActionType = 'SAVE_SHARED_DATA';
export const saveSharedDataAction = () => ({
  type: saveSharedDataActionType,
});

export const saveSharedDataSuccessActionType = `${saveSharedDataActionType}_SUCCESS`;
export const saveSharedDataSuccessAction = sharedData => ({
  type: saveSharedDataSuccessActionType,
  payload: sharedData,
});

export const clearSharedDataActionType = 'CLEAR_SHARED_DATA';
export const clearSharedDataAction = () => ({
  type: clearSharedDataActionType,
});

export const clearSharedDataSuccessActionType = `${clearSharedDataActionType}_SUCCESS`;
export const clearSharedDataSuccessAction = clearSharedData => ({
  type: clearSharedDataSuccessActionType,
  payload: clearSharedData,
});

export const saveSharedData = sharedData => dispatch => {
  dispatch(saveSharedDataAction());
  dispatch(saveSharedDataSuccessAction(sharedData));
};

export const clearSharedData = clearSharedData => dispatch => {
  dispatch(clearSharedDataAction());
  dispatch(clearSharedDataSuccessAction(clearSharedData));
};
