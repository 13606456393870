import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, formValueSelector, isDirty, reduxForm } from 'redux-form';

import { FieldInput } from 'shared/components/Fields';
import { FormContainer, Form, Section } from 'shared/components/Form';
import { CHANGE_PASSWORD } from 'shared/helpers/constants/changePasswordConstants';
import { validate } from 'shared/helpers/validations/user/passwordValidation';

import { PasswordRequirements } from '../PasswordRequirements/PasswordRequirements';

const ResetPasswordFormLayout = ({
  onCancel,
  handleSubmit,
  onSubmit,
  header,
  subheader,
  invalid,
  newPassword,
  dirty,
  own,
}) => (
  <FormContainer header={header} subHeader={subheader}>
    <Form
      onCancel={onCancel}
      onSubmit={handleSubmit(onSubmit)}
      header={CHANGE_PASSWORD.CHANGE_PASSWORD}
      submitDisabled={invalid}
    >
      {own && (
        <Section template={['oldPassword .']}>
          <Field
            name="oldPassword"
            type="password"
            component={FieldInput}
            label={CHANGE_PASSWORD.OLD_PASSWORD}
            field="required"
            labelWidth={130}
          />
        </Section>
      )}
      <Section
        template={['newPassword newPasswordRepeat', 'passwordRequirements passwordRequirements']}
        header={own && CHANGE_PASSWORD.NEW_PASSWORD}
      >
        <Field
          name="newPassword"
          type="password"
          field="required"
          component={FieldInput}
          label={CHANGE_PASSWORD.NEW_PASSWORD}
          labelWidth={130}
        />
        <Field
          name="newPasswordRepeat"
          type="password"
          field="required"
          component={FieldInput}
          label={CHANGE_PASSWORD.REPEAT_NEW_PASSWORD}
          labelWidth={130}
        />
        {dirty && validate({ newPassword }).newPassword && newPassword && (
          <PasswordRequirements
            marginBottom={0}
            name="passwordRequirements"
            password={newPassword}
            dirty={dirty && !!newPassword}
          />
        )}
      </Section>
    </Form>
  </FormContainer>
);

const selector = formValueSelector('ResetPasswordForm');

const mapStateToProps = state => ({
  userEmail: state.tokenValidation.info && state.tokenValidation.info.email,
  newPassword: selector(state, 'newPassword'),
  dirty: isDirty('ResetPasswordForm')(state),
});

const ResetPasswordForm = compose(
  reduxForm({
    form: 'ResetPasswordForm',
    validate,
  }),
  connect(mapStateToProps),
)(ResetPasswordFormLayout);

export { ResetPasswordForm, ResetPasswordFormLayout };
