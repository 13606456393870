import React, { Component } from 'react';

import { UIAuthorization } from 'components/Authorization/UIAuthorization';

import { DatepickerDropdown } from 'shared/components/DatepickerDropdown/DatepickerDropdown';
import { FiltersDropdown } from 'shared/components/FiltersDropdown/FiltersDropdown';
import { hedgeStatus, hedgeStatusForCustomer } from 'shared/helpers/constants/hedges/hedgeStatus';
import { HEDGES } from 'shared/helpers/constants/hedges/hedgesConstants';
import { isOwnerOrSupportOrBuyer } from 'shared/helpers/matchers/checkUserType';

import dateAdapter from 'utils/date/dateAdapter';
import { MomentAdapter } from 'utils/date/momentAdapter';

import { StyledHedgesFilters, StyledHedgesFiltersItem } from './StyledHedgesFilters';

class HedgesFilters extends Component {
  filterFunction = name => value => {
    this.props.onFiltersChanged({
      ...this.props.filters,
      [name]: value instanceof MomentAdapter ? value.valueOf() : value,
    });
  };

  renderDateFilter = (name, label, minDate, maxDate) => {
    const { filters } = this.props;

    return (
      <StyledHedgesFiltersItem>
        <DatepickerDropdown
          label={label}
          selected={filters[name] && dateAdapter(filters[name])}
          filterFunction={this.filterFunction(name)}
          minDate={dateAdapter(minDate || 0)}
          maxDate={dateAdapter(maxDate)}
        />
      </StyledHedgesFiltersItem>
    );
  };

  render() {
    const {
      filters: { status, deliveryDateStart, deliveryDateEnd, placedAtStart, placedAtEnd },
      auth: {
        user: { userType },
      },
    } = this.props;

    const statuses = Object.values(
      isOwnerOrSupportOrBuyer(userType) ? hedgeStatus : hedgeStatusForCustomer,
    );

    return (
      <StyledHedgesFilters>
        <StyledHedgesFiltersItem>
          <FiltersDropdown
            label={HEDGES.STATUS}
            options={statuses}
            selected={status || ''}
            filterFunction={this.filterFunction('status')}
          />
        </StyledHedgesFiltersItem>
        {this.renderDateFilter(
          'deliveryDateStart',
          HEDGES.DELIVERY_DATE_FROM,
          0,
          deliveryDateEnd || Infinity,
        )}
        {this.renderDateFilter(
          'deliveryDateEnd',
          HEDGES.DELIVERY_DATE_TO,
          deliveryDateStart,
          Infinity,
        )}
        <UIAuthorization access={isOwnerOrSupportOrBuyer(userType)}>
          {this.renderDateFilter('placedAtStart', HEDGES.PLACED_ON_FROM, 0, placedAtEnd)}
          {this.renderDateFilter('placedAtEnd', HEDGES.PLACED_ON_TO, placedAtStart)}
        </UIAuthorization>
      </StyledHedgesFilters>
    );
  }
}

export { HedgesFilters };
