import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { Authorization } from 'components/Authorization/Authorization';
import { NotFound } from 'components/ErrorPage/ErrorPage';

import { EnterOunces } from './components/EnterOunces';
import { PoolAccountDetails } from './components/PoolAccountDetails';
import { ConnectedPoolAccountsList } from './components/PoolAccountsList/ConnectedPoolAccountsList';
import { ConnectedSettlementForLot } from './components/RequestSettlement/SettlementForLot/ConnectedSettlementForLot';
import { SettlementForLotWizard } from './components/RequestSettlement/SettlementForLotWizard/SettlementForLotWizard';
import { ConnectedSettlementMockUp } from './components/RequestSettlement/SettlementMockUp/ConnectedSettlementMockUp';
import { ConnectedSettlementOfMetalPool } from './components/RequestSettlement/SettlementOfMetalPool/ConnectedSettlementOfMetalPool';
import { ViewJournal } from './components/ViewJournal';

function PoolAccounts({
  auth: {
    access: { poolAccounts },
  },
}) {
  const AuthPoolAccounts = Authorization(ConnectedPoolAccountsList, poolAccounts.overview);

  const AuthRequestSettlementForLotFinalPaymentOverview = Authorization(
    ConnectedSettlementForLot,
    poolAccounts.requestSettlement.forLot,
  );

  const AuthRequestSettlementForLotWizardOverview = Authorization(
    SettlementForLotWizard,
    poolAccounts.requestSettlement.forLot,
  );

  const AuthRequestSettlementMockUpOverview = Authorization(
    ConnectedSettlementMockUp,
    poolAccounts.requestSettlement.mockUp,
  );
  const AuthRequestSettlementOfMetalPoolOverview = Authorization(
    ConnectedSettlementOfMetalPool,
    poolAccounts.requestSettlement.metalPool,
  );
  const AuthPoolAccountsEnterOunces = Authorization(EnterOunces, poolAccounts.enterOunces);
  const AuthPoolAccountsJournal = Authorization(ViewJournal, poolAccounts.viewJournal);
  const AuthPoolAccountDetails = Authorization(PoolAccountDetails, poolAccounts.details);

  return (
    <Switch>
      <Route exact path="/pool-accounts" component={AuthPoolAccounts} />
      <Route
        exact
        path="/pool-accounts/settlement-for-lot-final-payment"
        component={AuthRequestSettlementForLotFinalPaymentOverview}
      />
      <Route
        exact
        path="/pool-accounts/settlement-for-lot"
        component={AuthRequestSettlementForLotWizardOverview}
      />
      <Route
        exact
        path="/pool-accounts/settlement-for-lot/:id"
        component={AuthRequestSettlementForLotWizardOverview}
      />
      <Route
        exact
        path="/pool-accounts/settlement-mock-up"
        component={AuthRequestSettlementMockUpOverview}
      />
      <Route
        exact
        path="/pool-accounts/settlement-mock-up/:id"
        component={AuthRequestSettlementMockUpOverview}
      />
      <Route
        exact
        path="/pool-accounts/settlement-of-metal-pool"
        component={AuthRequestSettlementOfMetalPoolOverview}
      />
      <Route exact path="/pool-accounts/enter-ounces" component={AuthPoolAccountsEnterOunces} />
      <Route exact path="/pool-accounts/journal" component={AuthPoolAccountsJournal} />
      <Route exact path="/pool-accounts/:companyId" component={AuthPoolAccountDetails} />
      <Route component={NotFound} />
    </Switch>
  );
}

export { PoolAccounts };
