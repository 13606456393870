import { dateToTimestamp } from 'shared/helpers/parsers/date';
import { removeCommasAndParse } from 'shared/helpers/parsers/text';

const parseUploadFinalInvoiceData = (
  {
    grgFinalInvoice,
    invoiceGeneratedAt,
    finalPlatinumPriceConfirm,
    finalPalladiumPriceConfirm,
    finalRhodiumPriceConfirm,
    ...formValues
  },
  grgFinalInvoiceFile,
  lotId,
  removingOuncesDisabled,
  balanceRemaining,
) => {
  const data = new FormData();

  data.append('grgFinalInvoice', grgFinalInvoiceFile);
  data.append('invoiceGeneratedAt', dateToTimestamp(invoiceGeneratedAt));
  data.append('lotId', lotId);

  delete formValues.balanceRemaining;
  if (balanceRemaining === 0 || formValues.totalFinalPayment <= 0) {
    delete formValues.outstandingBalanceApplied;
  }
  if (removingOuncesDisabled) {
    delete formValues.platinumOuncesRemoved;
    delete formValues.palladiumOuncesRemoved;
    delete formValues.rhodiumOuncesRemoved;
  }

  const finalInvoiceValues = {
    ...formValues,
    finalPlatinumPrice: formValues.finalPlatinumPrice || 0,
    finalPalladiumPrice: formValues.finalPalladiumPrice || 0,
    finalRhodiumPrice: formValues.finalRhodiumPrice || 0,
  };

  Object.entries(finalInvoiceValues).forEach(([field, value]) =>
    data.append(field, removeCommasAndParse(value)),
  );

  return data;
};

export { parseUploadFinalInvoiceData };
