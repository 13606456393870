export const getPackagesToPrint = (originalPackageArray, packagesToPrint) => {
  if (!(Array.isArray(originalPackageArray) && Array.isArray(packagesToPrint)))
    throw new Error('Arguments must be of type Array');

  return originalPackageArray
    .filter(({ packageNumber }) =>
      packagesToPrint.find(
        selectedPackageNumber => Number(selectedPackageNumber) === Number(packageNumber),
      ),
    )
    .map(pkg => pkg._id);
};
