import {
  clearLocationsListActionType,
  getLocationsActionType,
  getLocationsFailureActionType,
  getLocationsSuccessActionType,
} from 'actions/Companies/Details/locationsList';
import { changeLocationStatusSuccessActionType } from 'actions/Locations/changeLocationStatus';

const INITIAL_STATE = {
  isPending: false,
  errorMessage: null,
  locations: [],
  limit: 3,
  page: 1,
  pages: null,
  total: null,
  items: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getLocationsActionType:
      return {
        ...state,
        isPending: true,
      };
    case getLocationsSuccessActionType:
      return {
        ...state,
        isPending: false,
        locations: action.payload.locations,
        pages: action.payload.pages,
        total: action.payload.total,
        items: {
          docs: action.payload.locations,
        },
      };
    case getLocationsFailureActionType:
      return {
        ...state,
        isPending: false,
        errorMessage: action.payload.errorMessage,
      };
    case clearLocationsListActionType:
      return {
        isPending: false,
        errorMessage: null,
        locations: [],
        limit: 3,
        page: 1,
        pages: null,
        total: null,
        items: [],
      };
    case changeLocationStatusSuccessActionType:
      return {
        ...state,
        locations: state.locations.map(location =>
          location._id === action.payload.location._id ? action.payload.location : location,
        ),
      };
    default:
      return state;
  }
};
