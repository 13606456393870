import PropTypes from 'prop-types';
import React, { useCallback, useRef } from 'react';
import styled from 'styled-components';

import { withFieldWrapper } from 'shared/components/Fields/FieldWrapper/FieldWrapper';
import { FIELD_UPLOAD } from 'shared/components/Fields/FiledUpload/constants';
import { Icon } from 'shared/components/Icon/Icon';
import { cssVar } from 'shared/helpers/styling/styling';

const FieldUpload = ({ validExtensions, name, className, ...props }) => {
  const ref = useRef(null);

  const onWrapperClick = useCallback(
    e => {
      ref.current.click();
      e.stopPropagation();
    },
    [ref],
  );

  const onLabelClick = useCallback(e => {
    e.preventDefault();
  }, []);

  return (
    <div className={className} onClick={onWrapperClick}>
      <input
        {...props}
        ref={ref}
        type="file"
        accept={validExtensions}
        data-testid="uploadFile"
        value=""
      />
      <label htmlFor={name} onClick={onLabelClick}>
        {FIELD_UPLOAD.UPLOAD}
      </label>
      <Icon icon="icon-upload" />
    </div>
  );
};

FieldUpload.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  meta: PropTypes.object,
  multiple: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  validExtensions: PropTypes.string,
};

const FieldUploadStyled = withFieldWrapper(styled(FieldUpload)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px 0 32px;
  height: 50px;
  font-size: 0.875rem;
  color: ${cssVar('rollingStone')};
  background-color: transparent;
  border: 2px dashed ${cssVar('dustGray')};
  border-radius: 5px;
  cursor: pointer;

  :hover {
    background-color: ${cssVar('limedSpruceDarker')};
  }

  input {
    display: none;
  }

  label {
    margin-bottom: 0;
    cursor: pointer;
  }
`);

export { FieldUploadStyled as FieldUpload };
