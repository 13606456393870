import styled, { css } from 'styled-components';

import { isNumberOrString } from 'shared/helpers/parsers/formaters';
import { media, pxToRem, cssVar } from 'shared/helpers/styling/styling';

export const IconWrapper = styled.div`
  ${media.mobile`
    display: none;
  `}
  display:flex;
`;

export const DetailsContent = styled.div`
  margin-left: 20px;
  ${({ value, emptyOpacity, customContent, customContentDisabled }) =>
    !isNumberOrString(value) &&
    (!customContent || customContentDisabled) &&
    css`
      opacity: ${emptyOpacity};
    `};

  div {
    color: ${({ labelColor }) => labelColor};
    font-size: ${({ oneRow }) => (oneRow ? pxToRem(14) : pxToRem(12))};
  }

  div + div,
  div + div div {
    font-size: ${pxToRem(14)};
    margin-top: 8px;
    color: ${({ valueColor }) => valueColor};

    @media print {
      font-size: ${pxToRem(12)};
      margin-top: 0;
      font-weight: normal;
    }
  }

  @media print {
    font-weight: bold;
    margin-left: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
  }
`;

export const FileActions = styled.div`
  display: flex;
  align-items: center;
  margin-top: -8px !important;
`;

export const StyledWarningMessage = styled.span`
  margin: 8px 0 0 8px;
  font-size: ${pxToRem(11)};
  font-weight: 600;
  color: ${cssVar('solidRed')};

  ${media.tablet`
  font-size: ${pxToRem(10)};
`}

  ${media.phone`
  font-size: ${pxToRem(8)};
`}
`;
