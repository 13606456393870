import {
  markAsSentActionType,
  markAsSentSuccessActionType,
  markAsSentFailureActionType,
} from 'actions/Shipments/markAsSent';

const INITIAL_STATE = {
  isPending: false,
};

const MarkAsSentReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case markAsSentActionType:
      return {
        isPending: true,
      };
    case markAsSentSuccessActionType:
    case markAsSentFailureActionType:
      return {
        isPending: false,
      };
    default:
      return state;
  }
};

export { MarkAsSentReducer };
