import { message } from 'shared/helpers/constants/notes/createNoteConstants';

function validate(values) {
  const errors = {};

  if (!values.note) {
    errors.note = '';
  } else if (!values.note.trim()) {
    errors.note = message.INVALID_NOTE;
  } else if (values.note.length > 255) {
    errors.note = message.INVALID_NOTE_LENGTH();
  }

  return errors;
}

export { validate };
