import React, { useEffect, useMemo } from 'react';

import { FormContainer } from 'shared/components/Form';
import { HEDGES } from 'shared/helpers/constants/hedges/hedgesConstants';
import { dangerOptions } from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { snackbarOptions } from 'shared/helpers/matchers/snackbar/snackbarOptions';
import { safeParseFloat } from 'shared/helpers/parsers/number';

import { goBackOrTo } from 'utils/history';

import { DefaultPPMsForm } from 'pages/Hedges/components/DefaultPPMs/components/DefaultPPMsForm';

const DefaultPPMs = props => {
  useEffect(() => {
    props.getDefaultPPMs();

    return () => {
      props.clearDefaultPPMsAction();
    };
  }, []);

  const initialValues = useMemo(
    () =>
      Object.entries(props.defaultPPMs.values).reduce(
        (acc, [name, value]) => ({
          ...acc,
          [name]: value && value.toString(),
        }),
        {},
      ),
    [props.defaultPPMs.values],
  );

  const resolveAction = result => {
    props.showSnackbar(snackbarOptions(result.message.messageType), result.message.message);
    goBackOrTo('/hedges');
  };

  const parseValues = vals =>
    Object.entries(vals).reduce(
      (acc, [name, value]) => ({
        ...acc,
        [name]: safeParseFloat(value),
      }),
      {},
    );

  const submit = values => {
    const data = parseValues(values);
    props
      .updateDefaultPPMs(data)
      .then(resolveAction)
      .catch(err => props.showSnackbar(dangerOptions, err.message));
  };

  return (
    <FormContainer header={HEDGES.DEFAULT_PPMS.TITLE} loaded={!props.defaultPPMs.isPending}>
      <DefaultPPMsForm {...props} initialValues={initialValues} onSubmit={submit} />
    </FormContainer>
  );
};

export { DefaultPPMs };
