import PropTypes from 'prop-types';
import * as React from 'react';
import { memo } from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getDeliveriesList } from 'actions/Deliveries/deliveriesList';
import { showSnackbar } from 'actions/shared/snackbar';

import { CollapsableDetailsFields } from 'shared/components/CollapsableDetailsFields/CollapsableDetailsFields';
import { HISTORY_HEDGES } from 'shared/helpers/constants/hedges/historyHedgesConstants';
import { dangerOptions } from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

import { HistoryHeader } from './HistoryHeader/HistoryHeader';
import { HistoryItems } from './HistoryItems/HistoryItems';

const BlanketHedgeHistory = memo(({ relatedHedge }) => {
  const dispatch = useDispatch();
  const [deliveriesList, setDeliveriesList] = useState({});
  const disabledDetails = Object.values(relatedHedge).length;
  const isPending = useSelector(({ deliveriesList }) => deliveriesList.isPending);

  useEffect(() => {
    if (relatedHedge._id) {
      dispatch(getDeliveriesList(relatedHedge._id, 0))
        .then(({ payload }) => {
          const { _id } = relatedHedge;
          setDeliveriesList(prevState => ({
            ...prevState,
            [_id]: payload?.data?.docs,
          }));
        })
        .catch(err => dispatch(showSnackbar(dangerOptions, err.message)));
    }
  }, [dispatch, relatedHedge._id]);

  return (
    <CollapsableDetailsFields
      disabled={!disabledDetails}
      label={HISTORY_HEDGES.HISTORY_OF_BLANKET}
      cellWidth={100}
      showChildren
      isOpenAtStart={isPending}
    >
      <HistoryHeader
        relatedHedge={relatedHedge}
        deliveriesList={deliveriesList[relatedHedge._id]}
      />
      <HistoryItems relatedHedge={relatedHedge} deliveriesList={deliveriesList[relatedHedge._id]} />
    </CollapsableDetailsFields>
  );
});

BlanketHedgeHistory.propTypes = {
  relatedHedge: PropTypes.shape({
    hedgeName: PropTypes.string,
    palladiumOz: PropTypes.number,
    placedAt: PropTypes.number,
    platiniumOz: PropTypes.number,
    relatedCompany: PropTypes.string,
    rhodiumOz: PropTypes.number,
    _id: PropTypes.string,
  }),
};

export { BlanketHedgeHistory };
