import styled from 'styled-components';

import { cssVar } from 'shared/helpers/styling/styling';

const StyledGeneralInfoWrapper = styled('div')`
  display: flex;
  gap: 4px;
  flex-direction: column;
`;

const StyledLink = styled('a')`
  color: ${cssVar('geyser')};
  font-size: 1.1rem;
  font-weight: 300;
  margin: 0 0 0 16px;
`;

export { StyledGeneralInfoWrapper, StyledLink };
