function parseState(state) {
  const parsedState =
    state instanceof Object
      ? state
      : {
          value: state,
          label: state,
        };

  return parsedState.value && parsedState.label ? parsedState : undefined;
}

function parseLocationData(location, relatedCompany) {
  return {
    relatedCompany: relatedCompany.value,
    name: location.name,
    street: location.street,
    suite: location.suite,
    poBox: location.poBox,
    city: location.city,
    state: parseState(location.state),
    zipCode: location.zipCode,
    country: location.country.value,
    lotSuffix: location.lotSuffix.toUpperCase(),
    shipmentEligible: location.shipmentEligible,
    primaryLocation: location.primaryLocation,
    primaryContact: location.primaryContact
      ? location.primaryContact.map(({ value }) => value)
      : undefined,
  };
}

export { parseLocationData };
