import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import styled from 'styled-components';

import { cssVar } from 'shared/helpers/styling/styling';

import { resolveVersion, versions } from 'utils/versioning';

const DropdownStyled = styled(Dropdown)`
  min-width: 180px;
  height: 90px;

  button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${({ isOpen }) =>
      resolveVersion({
        [versions.alpha]: isOpen ? cssVar('shuttleGray') : cssVar('outerSpace'),
        [versions.global]: isOpen ? cssVar('shuttleGray') : cssVar('outerSpace'),
        [versions.west]: cssVar('lightGrayGRG'),
      })};
    border: none;
    border-left: 1px solid rgba(150, 150, 150, 0.2);
    border-right: 1px solid rgba(150, 150, 150, 0.2);
    border-bottom: ${({ isOpen }) => (isOpen ? '1px solid rgba(150, 150, 150, .7)' : 'none')};
    height: 100%;
    width: 100%;
    padding: 0 30px;
    line-height: 0;
    border-radius: 0;

    &:focus {
      box-shadow: none;
      background: ${resolveVersion({
        [versions.alpha]: cssVar('shuttleGray'),
        [versions.global]: cssVar('shuttleGray'),
        [versions.west]: cssVar('argentBitLighter'),
      })};
      border-color: ${resolveVersion({
        [versions.alpha]: cssVar('headerMenuFocus'),
        [versions.global]: cssVar('headerMenuFocus'),
        [versions.west]: cssVar('argent'),
      })};
      border-bottom: ${({ isOpen }) => (isOpen ? '1px solid rgba(150, 150, 150, .7)' : 'none')};
    }

    &:hover:not(:focus) {
      background-color: ${resolveVersion({
        [versions.alpha]: cssVar('outerSpaceBitLighter'),
        [versions.global]: cssVar('outerSpaceBitLighter'),
        [versions.west]: cssVar('argentBitLighter'),
      })};
      border-color: ${resolveVersion({
        [versions.alpha]: cssVar('headerMenuFocus'),
        [versions.global]: cssVar('headerMenuFocus'),
        [versions.west]: cssVar('argent'),
      })};
    }

    &:hover:focus {
      background-color: ${({ isOpen }) =>
        resolveVersion({
          [versions.alpha]: isOpen ? cssVar('shuttleGray') : cssVar('outerSpaceBitLighter'),
          [versions.global]: isOpen ? cssVar('shuttleGray') : cssVar('outerSpaceBitLighter'),
          [versions.west]: cssVar('argentBitLighter'),
        })};
      border-bottom: ${({ isOpen }) => (isOpen ? '1px solid rgba(150, 150, 150, .7)' : 'none')};
      border-color: ${resolveVersion({
        [versions.alpha]: cssVar('headerMenuFocus'),
        [versions.global]: cssVar('headerMenuFocus'),
        [versions.west]: cssVar('argent'),
      })};
    }

    div {
      height: 16px;
      text-align: left;
      color: ${resolveVersion({
        [versions.alpha]: cssVar('whiteGRG'),
        [versions.global]: cssVar('whiteGRG'),
        [versions.west]: cssVar('blackGRG'),
      })};

      .userType {
        color: ${resolveVersion({
          [versions.alpha]: cssVar('porcelain'),
          [versions.global]: cssVar('porcelain'),
          [versions.west]: cssVar('graniteGray'),
        })};
        opacity: 0.4;
      }

      &.dropdown-icon {
        transform: translateY(-8px);
      }
    }
  }
`;

const DropdownToggleStyled = styled(DropdownToggle)`
  &.btn-secondary:not([disabled]):not(.disabled):active {
    background: ${resolveVersion({
      [versions.alpha]: cssVar('headerMenuActive'),
      [versions.global]: cssVar('headerMenuActive'),
      [versions.west]: cssVar('argent'),
    })};
    border-color: ${resolveVersion({
      [versions.alpha]: cssVar('headerMenuFocus'),
      [versions.global]: cssVar('headerMenuFocus'),
      [versions.west]: cssVar('argent'),
    })};
  }
`;

const DropdownMenuStyled = styled(DropdownMenu)`
  &.dropdown-menu {
    width: 100%;
    background-color: ${resolveVersion({
      [versions.alpha]: cssVar('shuttleGray'),
      [versions.global]: cssVar('shuttleGray'),
      [versions.west]: cssVar('argentBitLighter'),
    })};
    border-radius: 0 0 8px 8px;
    margin: 0;
    padding: 0;
    border: none;
  }

  a,
  div {
    height: 69px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    color: ${resolveVersion({
      [versions.alpha]: cssVar('whiteGRG'),
      [versions.global]: cssVar('whiteGRG'),
      [versions.west]: cssVar('graniteGray'),
    })};
    font-size: 14px;

    &:hover {
      background-color: ${resolveVersion({
        [versions.alpha]: cssVar('nevada'),
        [versions.global]: cssVar('nevada'),
        [versions.west]: cssVar('argent'),
      })};
      color: ${resolveVersion({
        [versions.alpha]: cssVar('whiteGRG'),
        [versions.global]: cssVar('whiteGRG'),
        [versions.west]: cssVar('graniteGray'),
      })};

      &:last-child {
        border-radius: 0 0 8px 8px;
      }
    }

    &:not(:last-child) {
      border-bottom: 1px solid rgba(150, 150, 150, 0.7);
    }

    .icon {
      margin: 0 25px;
    }
  }
`;

export { DropdownStyled, DropdownToggleStyled, DropdownMenuStyled };
