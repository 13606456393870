const checkAccessToPath = url =>
  url.includes('settlement-for-lot') ||
  url.includes('settlement-mock-up') ||
  url.includes('settlement-of-metal-pool');

const poolAccountsBreadcrumbsRoutes = {
  '/pool-accounts': () => (checkAccessToPath(window.location.pathname) ? null : 'Pool accounts'),
  '/pool-accounts/settlement-for-lot': 'Settlement for lot',
  '/pool-accounts/settlement-for-lot/:id': null,
  '/pool-accounts/settlement-mock-up': 'Settlement mock-up',
  '/pool-accounts/settlement-mock-up/:id': null,
  '/pool-accounts/settlement-of-metal-pool': 'Settlement of metal pool',
  '/pool-accounts/journal': 'Journal',
  '/pool-accounts/:companyId': 'Pool account details',
};

export { poolAccountsBreadcrumbsRoutes };
