import { statusesEnum } from '../constants/filters/allStatuses';

const companyInactivateAccess = (auth, company) => {
  const validStatus = [statusesEnum.active, statusesEnum.no_terms].includes(company.status);
  const assignedUser = auth.user.relatedCompany._id === company._id;

  return (
    auth.access.companies.inactivate &&
    validStatus &&
    !company.assignedShipmentNumber &&
    !assignedUser
  );
};

const companyReactivateAccess = (auth, company) => {
  const validStatus = company.status === statusesEnum.inactive;

  return auth.access.companies.inactivate && validStatus && !company.assignedShipmentNumber;
};

export { companyInactivateAccess, companyReactivateAccess };
