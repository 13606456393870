import { useDispatch, useSelector } from 'react-redux';

import { getOutgoingLotsOverview } from 'actions/Lots/outgoingLotsOverview';

import { GroupButtons } from 'shared/components/Buttons';
import { OverviewList } from 'shared/components/OverviewList/OverviewList';
import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';
import { outgoingSections } from 'shared/helpers/constants/lots/outgoingSections';
import {
  lotActionsTemplate,
  lotItemMobileTemplate,
  lotItemTemplate,
  shipmentActionsTemplate,
  shipmentItemMobileTemplate,
  shipmentItemTemplate,
} from 'shared/helpers/constants/lots/outgoingTableConfig';
import { isTablet } from 'shared/helpers/constants/resolutionsConstants';
import { isOwnerUser } from 'shared/helpers/matchers/checkUserType';
import { useWindowWidth } from 'shared/hooks/useWindowWidth';

import { history } from 'utils/history';

import { OverviewLotsSection } from '../OverviewLotsSection/OverviewLotsSection';
import {
  StyledActions,
  StyledGroupButton,
  StyledHeader,
  StyledLotsOutgoing,
} from './StyledLotsOutgoing';
import { ReadyToShipSection } from './components/ReadyToShipSection';

export const LotsOutgoing = () => {
  const outgoingLotsOverview = useSelector(state => state.outgoingLotsOverview);
  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();

  const windowWidth = useWindowWidth();

  const getTotalShipments = resource => resource.items?.total;

  const renderOverviewList = (
    input,
    getList,
    itemsTemplate = lotItemTemplate,
    itemsTemplateMobile = lotItemMobileTemplate,
    actionsItemTemplate = lotActionsTemplate,
  ) => {
    return (
      <OverviewList
        getList={getList}
        input={input}
        itemsTemplate={itemsTemplate}
        itemsTemplateMobile={itemsTemplateMobile}
        actionsTemplate={actionsItemTemplate}
        isMobile={windowWidth < isTablet}
        auth={auth}
        label={LOTS.LOTS}
        listLimit={5}
      />
    );
  };

  const sectionTemplates = [
    {
      ...outgoingSections.shippedFromGlobal,
      total: getTotalShipments(outgoingLotsOverview.shippedFromGlobal),
      render: renderOverviewList(outgoingLotsOverview.shippedFromGlobal, (page, limit) =>
        dispatch(getOutgoingLotsOverview(outgoingSections.shippedFromGlobal, page, limit)),
      ),
    },
    {
      ...outgoingSections.unsentShipments,
      total: getTotalShipments(outgoingLotsOverview.unsentShipments),
      render: renderOverviewList(
        outgoingLotsOverview.unsentShipments,
        (page, limit) =>
          dispatch(getOutgoingLotsOverview(outgoingSections.unsentShipments, page, limit)),
        shipmentItemTemplate,
        shipmentItemMobileTemplate,
        shipmentActionsTemplate,
      ),
    },
  ];

  const readyToShipTotal = getTotalShipments(outgoingLotsOverview.readyToShip);

  return (
    <StyledLotsOutgoing>
      <StyledHeader>
        <h2 className="LotsOutgoing-title">{LOTS.OVERVIEW_OUTGOING}</h2>
        <StyledActions>
          <GroupButtons>
            <StyledGroupButton
              id="requestInspection"
              access={isOwnerUser(auth.user.userType)}
              onClick={() => history.push('/lots/outgoing/request-inspection')}
              label={LOTS.REQUEST_INSPECTION}
              icon="request-inspection"
            />
            <StyledGroupButton
              id="scheduleInspection"
              access={isOwnerUser(auth.user.userType)}
              onClick={() => history.push('/lots/outgoing/schedule-inspection')}
              label={LOTS.SCHEDULE_INSPECTION}
              icon="schedule-inspection"
            />
          </GroupButtons>
        </StyledActions>
      </StyledHeader>
      {auth.access.shipments.createOutgoing ||
      auth.access.specialPermissions.shipments.createOutgoingShipment ? (
        <ReadyToShipSection total={readyToShipTotal} />
      ) : (
        renderOverviewList(outgoingLotsOverview.readyToShip, (page, limit) =>
          dispatch(getOutgoingLotsOverview(outgoingSections.readyToShip, page, limit)),
        )
      )}
      {sectionTemplates.map(({ name, title, render, total }) => (
        <OverviewLotsSection
          key={name}
          id={name}
          title={title}
          total={total}
          disableTransform={outgoingSections.readyToShip.name === name}
        >
          {render}
        </OverviewLotsSection>
      ))}
    </StyledLotsOutgoing>
  );
};
