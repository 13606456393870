import dateAdapter from 'utils/date/dateAdapter';

const deleteSelfDeliveryRequestAccess = (auth, selfDeliveryRequest) => {
  const minDeliveryDate = dateAdapter().subtract(30, 'd').startOf('day').valueOf();

  return (
    auth.access.selfDeliveryRequests.details.delete &&
    selfDeliveryRequest.deliveryDate >= minDeliveryDate
  );
};

export { deleteSelfDeliveryRequestAccess };
