import { connect } from 'react-redux';

import { downloadApiAttachment } from 'actions/Attachments/downloadAttachment';
import { termStatusFilter, materialTypeFilter, termFiltersClear } from 'actions/Terms/termFilters';
import { getTermsList, clearTermsList } from 'actions/Terms/termList';

import { TermsList } from './TermsList';

const mapStateToProps = ({ auth, termsList, termFilters, downloadAttachmentStorage }) => ({
  auth,
  termsList,
  termFilters,
  downloadAttachmentStorage,
});

const mapDispatchToProps = {
  getTermsList,
  clearTermsList,
  termStatusFilter,
  materialTypeFilter,
  termFiltersClear,
  downloadApiAttachment,
};

export default connect(mapStateToProps, mapDispatchToProps)(TermsList);
