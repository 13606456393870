import axios from 'axios';

import { parseMarketHolidaysData } from 'shared/helpers/dataParsers/marketHolidays/parseMarketHolidayData';

const API_HOST_AUTH = process.env.REACT_APP_API;

const createMarketHolidaysActionType = 'CREATE_MARKET_HOLIDAYS';
function createMarketHolidaysAction() {
  return {
    type: createMarketHolidaysActionType,
  };
}

const createMarketHolidaysSuccessActionType = `${createMarketHolidaysActionType}_SUCCESS`;
function createMarketHolidaysSuccessAction(marketHoliday) {
  return {
    type: createMarketHolidaysSuccessActionType,
    payload: { marketHoliday },
  };
}

const createMarketHolidaysFailureActionType = `${createMarketHolidaysActionType}_FAILURE`;
function createMarketHolidaysFailureAction() {
  return {
    type: createMarketHolidaysFailureActionType,
  };
}

function createMarketHolidays(marketHoliday) {
  return dispatch => {
    dispatch(createMarketHolidaysAction());

    return axios({
      method: 'post',
      url: `${API_HOST_AUTH}/market-holidays`,
      data: JSON.stringify(parseMarketHolidaysData(marketHoliday)),
    })
      .then(({ data }) => dispatch(createMarketHolidaysSuccessAction(data.marketHoliday)))
      .catch(error => {
        dispatch(createMarketHolidaysFailureAction());

        return Promise.reject(error);
      });
  };
}

export {
  createMarketHolidays,
  createMarketHolidaysActionType,
  createMarketHolidaysSuccessActionType,
  createMarketHolidaysFailureActionType,
};
