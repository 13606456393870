import React, { Component } from 'react';

import { UIAuthorization } from 'components/Authorization/UIAuthorization';

import { ContentLoader } from 'shared/components/ContentLoader/ContentLoader';
import { renderDetailsContainer } from 'shared/components/Details/helpers/renderDetailsContainer';
import { createUserFromLocationAccess } from 'shared/helpers/accesses/users/createUserFromLocationAccess';
import { dangerOptions } from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { isGlobalUserType } from 'shared/helpers/matchers/isGlobalUserType';

import { extractErrorMessage } from 'utils/extractErrorMessage';
import { history } from 'utils/history';

import { LocationUsers } from './LocationUsers/LocationUsers';
import { MainLocationDetails } from './MainDetails/MainDetails';
import {
  activityContainerConfig,
  activityFieldsConfig,
  locationDetailsContainerConfig,
  locationDetailsFieldsConfig,
  primaryContactContainerConfig,
  primaryContactFieldsConfig,
} from './config/fieldsConfig';

class LocationDetails extends Component {
  componentDidMount() {
    this.handleDetailsGet();
  }

  handleDetailsGet = async () => {
    const {
      getLocation,
      auth: { user, access },
      match: { params },
      showSnackbar,
    } = this.props;

    try {
      access.locations.ownLocations.details
        ? await getLocation(params.locationId)
        : await getLocation(params.id);
    } catch (error) {
      showSnackbar(dangerOptions, extractErrorMessage(error));
      return history.push(
        isGlobalUserType(user.userType)
          ? `/companies/${params.companyId}`
          : `/locations/${user.relatedCompany._id}`,
      );
    }
  };

  render() {
    const { location, isPending } = this.props.locationDetails;
    const {
      auth: { access, user },
    } = this.props;

    const userListAccess = access.companies.details.locations.details.usersList;
    let createUserAccess = false;
    if (location && location.relatedCompany) {
      createUserAccess = createUserFromLocationAccess(
        userListAccess,
        user.userType,
        location.relatedCompany.companyType,
        location.relatedCompany.companyName,
      );
    }

    return (
      <ContentLoader loaded={location && !isPending}>
        {location && (
          <>
            <MainLocationDetails location={location} access={access} user={user} />
            {renderDetailsContainer(
              locationDetailsContainerConfig,
              locationDetailsFieldsConfig,
              location,
            )}
            {location.primaryContact.map((primaryContact, index) =>
              renderDetailsContainer(
                primaryContactContainerConfig,
                primaryContactFieldsConfig,
                primaryContact,
                index,
                location.primaryContact.length,
              ),
            )}
            {renderDetailsContainer(activityContainerConfig, activityFieldsConfig, location)}
            <UIAuthorization access={createUserAccess}>
              <LocationUsers {...this.props} />
            </UIAuthorization>
          </>
        )}
      </ContentLoader>
    );
  }
}

export { LocationDetails };
