import { FILES_CONSTANTS } from 'shared/helpers/constants/filesConstants';
import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';
import { validateAttachment } from 'shared/helpers/validations/attachment/validateAttachment';
import { VALIDATE_UTF_8 } from 'shared/helpers/validations/regularExpressions';

const validateMultipleDocumentsLength = (files, limit = 10) =>
  files.length <= limit ? null : FILES_CONSTANTS.ERROR_LENGTH(limit);

const validateMultipleDocuments = (file, validExtensions) => {
  let errorMessage = null;

  if (!validateAttachment(file, validExtensions)) {
    errorMessage = LOTS.ERRORS.INVALID_FILE_FORMAT;
  }

  if (!VALIDATE_UTF_8().test(file.name)) {
    errorMessage = LOTS.ERRORS.INVALID_FILE_NAME;
  }

  return errorMessage;
};

export { validateMultipleDocuments, validateMultipleDocumentsLength };
