import { CREATE_PACKAGE } from 'shared/helpers/constants/packages/createPackageConstants';
import { materialTypeForAssay } from 'shared/helpers/constants/packages/materialType';
import { PACKAGES } from 'shared/helpers/constants/packages/packageConstants';
import { isOwnerUser } from 'shared/helpers/matchers/checkUserType';
import { replaceEmpty } from 'shared/helpers/parsers/text';
import { INTEGER_WITHOUT_LEADING_ZEROES } from 'shared/helpers/validations/regularExpressions';
import { CUSTOM_LOT_NUMBER_REGEX } from 'shared/helpers/validations/regularExpressions';

const validate = ({ defaultPackagesValues }, { auth }) => {
  let packagesQuantityError = null;
  let assignedLotError = null;
  let customerInstructionsError = null;
  let customLotNumberError = null;

  if (defaultPackagesValues) {
    if (
      defaultPackagesValues.oneOrMultiLots &&
      defaultPackagesValues.oneOrMultiLots.value === PACKAGES.LOTS_FOR_PACKAGES.SAME_LOT &&
      !defaultPackagesValues.assignedLot
    ) {
      assignedLotError = CREATE_PACKAGE.INVALID_ASSIGNED_LOT_EMPTY;
    }

    if (!replaceEmpty(defaultPackagesValues.packagesQuantity, '').length) {
      packagesQuantityError = CREATE_PACKAGE.INVALID_NUMBER_OF_PACKAGES_EMPTY;
    } else if (
      defaultPackagesValues.packagesQuantity &&
      !INTEGER_WITHOUT_LEADING_ZEROES.test(defaultPackagesValues.packagesQuantity)
    ) {
      packagesQuantityError = CREATE_PACKAGE.INVALID_NUMBER_OF_PACKAGES;
    } else if (Number(defaultPackagesValues.packagesQuantity) === 0) {
      packagesQuantityError = CREATE_PACKAGE.INVALID_NUMBER_OF_PACKAGES_ZERO;
    } else if (Number(defaultPackagesValues.packagesQuantity) > 99) {
      packagesQuantityError = CREATE_PACKAGE.INVALID_NUMBER_OF_PACKAGES_GREATER;
    }

    if (
      defaultPackagesValues.materialTypeDeclared?.value === materialTypeForAssay.other &&
      (!defaultPackagesValues.customerInstructions ||
        !defaultPackagesValues.customerInstructions.trim())
    ) {
      customerInstructionsError = isOwnerUser(auth.user.userType)
        ? CREATE_PACKAGE.INVALID_CUSTOMER_INSTRUCTIONS_REQUIRED_OWNER
        : CREATE_PACKAGE.INVALID_CUSTOMER_INSTRUCTIONS_REQUIRED_CUSTOMER;
    } else if (
      defaultPackagesValues.customerInstructions &&
      defaultPackagesValues.customerInstructions.length > 100
    ) {
      customerInstructionsError = CREATE_PACKAGE.INVALID_CUSTOMER_INSTRUCTIONS_LENGTH;
    }

    if (
      defaultPackagesValues.customLotNumber &&
      (!CUSTOM_LOT_NUMBER_REGEX.test(defaultPackagesValues.customLotNumber) ||
        defaultPackagesValues.customLotNumber.length > 15)
    ) {
      customLotNumberError = CREATE_PACKAGE.INVALID_CUSTOM_LOT_NUMBER_FORMAT;
    }
  }
  return {
    defaultPackagesValues: {
      packagesQuantity: packagesQuantityError,
      assignedLot: assignedLotError,
      customerInstructions: customerInstructionsError,
      customLotNumber: customLotNumberError,
    },
  };
};

export { validate };
