import { ENTER_ASSAY } from 'shared/helpers/constants/lots/enterAssayConstants';
import { safeParseFloat } from 'shared/helpers/parsers/number';
import { replaceEmpty } from 'shared/helpers/parsers/text';
import { commaValidator } from 'shared/helpers/validations/commaValidator';
import {
  FLOAT_REGEX,
  ONE_DECIMAL_REGEX,
  THREE_DECIMAL_REGEX,
} from 'shared/helpers/validations/regularExpressions';

import { setCorrectLabel } from 'utils/setCorrectLabel';

function validate(values, props) {
  const { formValues } = props;
  const errors = {};

  if (!values.assayOption) {
    errors.assayOption = ENTER_ASSAY.ASSAY_OPTION_EMPTY;
  }

  if (!values.materialType) {
    errors.materialType = ENTER_ASSAY.MATERIAL_TYPE_EMPTY;
  }

  if (!values.materialTypeH) {
    errors.materialTypeH = ENTER_ASSAY.MATERIAL_TYPE_EMPTY_H;
  }

  if (!replaceEmpty(values.weightGrossReceived, '').length) {
    errors.weightGrossReceived = ENTER_ASSAY.WEIGHT_GROSS_RECEIVED_EMPTY;
  } else if (!FLOAT_REGEX.test(values.weightGrossReceived)) {
    errors.weightGrossReceived = ENTER_ASSAY.WEIGHT_GROSS_RECEIVED_INVALID;
  } else if (values.weightGrossReceived && values.weightGrossReceived.length > 10) {
    errors.weightGrossReceived = ENTER_ASSAY.WEIGHT_GROSS_RECEIVED_INVALID;
  } else if (safeParseFloat(values.weightGrossReceived) <= 0) {
    errors.weightGrossReceived = ENTER_ASSAY.WEIGHT_GROSS_RECEIVED_NEGATIVE;
  }

  if (!replaceEmpty(values.weightTareReceived, '').length) {
    errors.weightTareReceived = ENTER_ASSAY.WEIGHT_TARE_RECEIVED_EMPTY;
  } else if (!FLOAT_REGEX.test(values.weightTareReceived)) {
    errors.weightTareReceived = ENTER_ASSAY.WEIGHT_TARE_RECEIVED_INVALID;
  } else if (values.weightTareReceived && values.weightTareReceived.length > 10) {
    errors.weightTareReceived = ENTER_ASSAY.WEIGHT_TARE_RECEIVED_INVALID;
  } else if (safeParseFloat(values.weightTareReceived) < 0) {
    errors.weightTareReceived = ENTER_ASSAY.WEIGHT_TARE_RECEIVED_NEGATIVE;
  } else if (
    safeParseFloat(values.weightTareReceived) >= safeParseFloat(formValues.weightGrossReceived)
  ) {
    errors.weightTareReceived = ENTER_ASSAY.WEIGHT_TARE_RECEIVED_GTE_GROSS;
  }

  if (!replaceEmpty(values.weightNetWetReceived, '').length) {
    errors.weightNetWetReceived = ENTER_ASSAY.WEIGHT_NET_WET_RECEIVED_EMPTY;
  } else if (!FLOAT_REGEX.test(values.weightNetWetReceived)) {
    errors.weightNetWetReceived = ENTER_ASSAY.WEIGHT_NET_WET_RECEIVED_INVALID;
  } else if (values.weightNetWetReceived && values.weightNetWetReceived.length > 10) {
    errors.weightNetWetReceived = ENTER_ASSAY.WEIGHT_NET_WET_RECEIVED_INVALID;
  } else if (safeParseFloat(values.weightNetWetReceived) <= 0) {
    errors.weightNetWetReceived = ENTER_ASSAY.WEIGHT_NET_WET_RECEIVED_NEGATIVE;
  } else if (
    safeParseFloat(values.weightNetWetReceived) > safeParseFloat(formValues.weightGrossReceived)
  ) {
    errors.weightNetWetReceived = ENTER_ASSAY.WEIGHT_NET_WET_RECEIVED_GTE_GROSS;
  }

  if (!replaceEmpty(values.weightAdjustedNetWetReceived, '').length) {
    errors.weightAdjustedNetWetReceived = ENTER_ASSAY.WEIGHT_ADJUSTED_NET_WET_RECEIVED_EMPTY;
  } else if (!FLOAT_REGEX.test(values.weightAdjustedNetWetReceived)) {
    errors.weightAdjustedNetWetReceived = ENTER_ASSAY.WEIGHT_ADJUSTED_NET_WET_RECEIVED_INVALID;
  } else if (
    values.weightAdjustedNetWetReceived &&
    values.weightAdjustedNetWetReceived.length > 10
  ) {
    errors.weightAdjustedNetWetReceived = ENTER_ASSAY.WEIGHT_ADJUSTED_NET_WET_RECEIVED_INVALID;
  } else if (safeParseFloat(values.weightAdjustedNetWetReceived) <= 0) {
    errors.weightAdjustedNetWetReceived = ENTER_ASSAY.WEIGHT_ADJUSTED_NET_WET_RECEIVED_NEGATIVE;
  } else if (
    safeParseFloat(values.weightAdjustedNetWetReceived) >
    safeParseFloat(formValues.weightNetWetReceived)
  ) {
    errors.weightAdjustedNetWetReceived = ENTER_ASSAY.WEIGHT_ADJUSTED_NET_WET_RECEIVED_GTE_NET_WET;
  }

  if (!replaceEmpty(values.weightScrapReceived, '').length) {
    errors.weightScrapReceived = ENTER_ASSAY.WEIGHT_SCRAP_RECEIVED_EMPTY;
  } else if (!FLOAT_REGEX.test(values.weightScrapReceived)) {
    errors.weightScrapReceived = ENTER_ASSAY.WEIGHT_SCRAP_RECEIVED_INVALID;
  } else if (values.weightScrapReceived && values.weightScrapReceived.length > 10) {
    errors.weightScrapReceived = ENTER_ASSAY.WEIGHT_SCRAP_RECEIVED_INVALID;
  } else if (safeParseFloat(values.weightScrapReceived) < 0) {
    errors.weightScrapReceived = ENTER_ASSAY.WEIGHT_SCRAP_RECEIVED_NEGATIVE;
  } else if (
    safeParseFloat(values.weightScrapReceived) >= safeParseFloat(formValues.weightNetWetReceived)
  ) {
    errors.weightScrapReceived = ENTER_ASSAY.WEIGHT_SCRAP_RECEIVED_GTE_NET_WET;
  }

  if (!replaceEmpty(values.weightNetDryReceived, '').length) {
    errors.weightNetDryReceived = ENTER_ASSAY.WEIGHT_NET_DRY_RECEIVED_EMPTY;
  } else if (!FLOAT_REGEX.test(values.weightNetDryReceived)) {
    errors.weightNetDryReceived = ENTER_ASSAY.WEIGHT_NET_DRY_RECEIVED_INVALID;
  } else if (values.weightScrapReceived && values.weightNetDryReceived.length > 10) {
    errors.weightNetDryReceived = ENTER_ASSAY.WEIGHT_NET_DRY_RECEIVED_INVALID;
  } else if (safeParseFloat(values.weightNetDryReceived) <= 0) {
    errors.weightNetDryReceived = ENTER_ASSAY.WEIGHT_NET_DRY_RECEIVED_NEGATIVE;
  } else if (
    safeParseFloat(values.weightNetDryReceived) > safeParseFloat(formValues.weightNetWetReceived)
  ) {
    errors.weightNetDryReceived = ENTER_ASSAY.WEIGHT_NET_DRY_RECEIVED_GT_NET_WET;
  }

  if (!replaceEmpty(values.percentMoisture, '').length) {
    errors.percentMoisture = ENTER_ASSAY.PERCENT_MOISTURE_EMPTY;
  } else if (
    safeParseFloat(values.percentMoisture) < 0 ||
    safeParseFloat(values.percentMoisture) > 10
  ) {
    errors.percentMoisture = ENTER_ASSAY.PERCENT_MOISTURE_RANGE;
  } else if (
    !THREE_DECIMAL_REGEX.test(values.percentMoisture) ||
    values.percentMoisture.length > 6
  ) {
    errors.percentMoisture = ENTER_ASSAY.PERCENT_MOISTURE_INVALID;
  }

  if (!replaceEmpty(values.platinumAssay, '').length) {
    errors.platinumAssay = ENTER_ASSAY.PLATINUM_ASSAY_EMPTY;
  } else if (!ONE_DECIMAL_REGEX.test(values.platinumAssay)) {
    errors.platinumAssay = ENTER_ASSAY.PLATINUM_ASSAY_INVALID;
  } else if (values.platinumAssay && values.platinumAssay.length > 10) {
    errors.platinumAssay = ENTER_ASSAY.PLATINUM_ASSAY_INVALID;
  } else if (safeParseFloat(values.platinumAssay) < 0) {
    errors.platinumAssay = ENTER_ASSAY.PLATINUM_ASSAY_NEGATIVE;
  }

  if (!replaceEmpty(values.palladiumAssay, '').length) {
    errors.palladiumAssay = ENTER_ASSAY.PALLADIUM_ASSAY_EMPTY;
  } else if (!ONE_DECIMAL_REGEX.test(values.palladiumAssay)) {
    errors.palladiumAssay = ENTER_ASSAY.PALLADIUM_ASSAY_INVALID;
  } else if (values.palladiumAssay && values.palladiumAssay.length > 10) {
    errors.palladiumAssay = ENTER_ASSAY.PALLADIUM_ASSAY_INVALID;
  } else if (safeParseFloat(values.palladiumAssay) < 0) {
    errors.palladiumAssay = ENTER_ASSAY.PALLADIUM_ASSAY_INVALID;
  }

  if (!replaceEmpty(values.rhodiumAssay, '').length) {
    errors.rhodiumAssay = ENTER_ASSAY.RHODIUM_ASSAY_EMPTY;
  } else if (!ONE_DECIMAL_REGEX.test(values.rhodiumAssay)) {
    errors.rhodiumAssay = ENTER_ASSAY.RHODIUM_ASSAY_INVALID;
  } else if (values.rhodiumAssay && values.rhodiumAssay.length > 10) {
    errors.rhodiumAssay = ENTER_ASSAY.RHODIUM_ASSAY_INVALID;
  } else if (safeParseFloat(values.rhodiumAssay) < 0) {
    errors.rhodiumAssay = ENTER_ASSAY.RHODIUM_ASSAY_NEGATIVE;
  }

  if (!replaceEmpty(values.carbonPercentage, '').length) {
    errors.carbonPercentage = ENTER_ASSAY.CARBON_PERCENTAGE_EMPTY;
  } else if (
    safeParseFloat(values.carbonPercentage) < 0 ||
    safeParseFloat(values.carbonPercentage) > 10
  ) {
    errors.carbonPercentage = ENTER_ASSAY.CARBON_PERCENTAGE_RANGE;
  } else if (
    !THREE_DECIMAL_REGEX.test(values.carbonPercentage) ||
    values.carbonPercentage.length > 6
  ) {
    errors.carbonPercentage = ENTER_ASSAY.CARBON_PERCENTAGE_INVALID;
  }

  if (!replaceEmpty(values.platinumContainedOz, '').length) {
    errors.platinumContainedOz = ENTER_ASSAY.PLATINUM_CONTAINED_OZ_EMPTY;
  } else if (values.platinumContainedOz && values.platinumContainedOz.length > 10) {
    errors.platinumContainedOz = ENTER_ASSAY.PLATINUM_CONTAINED_OZ_INVALID;
  } else if (safeParseFloat(values.platinumContainedOz) < 0) {
    errors.platinumContainedOz = ENTER_ASSAY.PLATINUM_CONTAINED_OZ_NEGATIVE;
  } else if (!THREE_DECIMAL_REGEX.test(values.platinumContainedOz)) {
    errors.platinumContainedOz = ENTER_ASSAY.PLATINUM_CONTAINED_OZ_INVALID;
  }

  if (!replaceEmpty(values.palladiumContainedOz, '').length) {
    errors.palladiumContainedOz = ENTER_ASSAY.PALLADIUM_CONTAINED_OZ_EMPTY;
  } else if (!THREE_DECIMAL_REGEX.test(values.palladiumContainedOz)) {
    errors.palladiumContainedOz = ENTER_ASSAY.PALLADIUM_CONTAINED_OZ_INVALID;
  } else if (values.palladiumContainedOz && values.palladiumContainedOz.length > 10) {
    errors.palladiumContainedOz = ENTER_ASSAY.PALLADIUM_CONTAINED_OZ_INVALID;
  } else if (safeParseFloat(values.palladiumContainedOz) < 0) {
    errors.palladiumContainedOz = ENTER_ASSAY.PALLADIUM_CONTAINED_OZ_INVALID;
  }

  if (!replaceEmpty(values.rhodiumContainedOz, '').length) {
    errors.rhodiumContainedOz = ENTER_ASSAY.RHODIUM_CONTAINED_OZ_EMPTY;
  } else if (!THREE_DECIMAL_REGEX.test(values.rhodiumContainedOz)) {
    errors.rhodiumContainedOz = ENTER_ASSAY.RHODIUM_CONTAINED_OZ_INVALID;
  } else if (values.rhodiumContainedOz && values.rhodiumContainedOz.length > 10) {
    errors.rhodiumContainedOz = ENTER_ASSAY.RHODIUM_CONTAINED_OZ_INVALID;
  } else if (safeParseFloat(values.rhodiumContainedOz) < 0) {
    errors.rhodiumContainedOz = ENTER_ASSAY.RHODIUM_CONTAINED_OZ_NEGATIVE;
  }

  if (!replaceEmpty(values.platinumReturnableOz, '').length) {
    errors.platinumReturnableOz = ENTER_ASSAY.PLATINUM_RETURNABLE_OZ_EMPTY;
  } else if (!THREE_DECIMAL_REGEX.test(values.platinumReturnableOz)) {
    errors.platinumReturnableOz = ENTER_ASSAY.PLATINUM_RETURNABLE_OZ_INVALID;
  } else if (values.platinumReturnableOz && values.platinumReturnableOz.length > 10) {
    errors.platinumReturnableOz = ENTER_ASSAY.PLATINUM_RETURNABLE_OZ_INVALID;
  } else if (safeParseFloat(values.platinumReturnableOz) < 0) {
    errors.platinumReturnableOz = ENTER_ASSAY.PLATINUM_RETURNABLE_OZ_NEGATIVE;
  }

  if (!replaceEmpty(values.palladiumReturnableOz, '').length) {
    errors.palladiumReturnableOz = ENTER_ASSAY.PALLADIUM_RETURNABLE_OZ_EMPTY;
  } else if (!THREE_DECIMAL_REGEX.test(values.palladiumReturnableOz)) {
    errors.palladiumReturnableOz = ENTER_ASSAY.PALLADIUM_RETURNABLE_OZ_INVALID;
  } else if (values.palladiumReturnableOz && values.palladiumReturnableOz.length > 10) {
    errors.palladiumReturnableOz = ENTER_ASSAY.PALLADIUM_RETURNABLE_OZ_INVALID;
  } else if (safeParseFloat(values.palladiumReturnableOz) < 0) {
    errors.palladiumReturnableOz = ENTER_ASSAY.PALLADIUM_RETURNABLE_OZ_INVALID;
  }

  if (!replaceEmpty(values.rhodiumReturnableOz, '').length) {
    errors.rhodiumReturnableOz = ENTER_ASSAY.RHODIUM_RETURNABLE_OZ_EMPTY;
  } else if (!THREE_DECIMAL_REGEX.test(values.rhodiumReturnableOz)) {
    errors.rhodiumReturnableOz = ENTER_ASSAY.RHODIUM_RETURNABLE_OZ_INVALID;
  } else if (values.rhodiumReturnableOz && values.rhodiumReturnableOz.length > 10) {
    errors.rhodiumReturnableOz = ENTER_ASSAY.RHODIUM_RETURNABLE_OZ_INVALID;
  } else if (safeParseFloat(values.rhodiumReturnableOz) < 0) {
    errors.rhodiumReturnableOz = ENTER_ASSAY.RHODIUM_RETURNABLE_OZ_NEGATIVE;
  }

  if (!replaceEmpty(values.weightGrossReceivedH, '').length) {
    errors.weightGrossReceivedH = setCorrectLabel(
      formValues?.assayOption?.value,
      ENTER_ASSAY.WEIGHT_GROSS_RECEIVED_EMPTY_H,
      ENTER_ASSAY.WEIGHT_GROSS_RECEIVED_EMPTY_B,
    );
  } else if (!FLOAT_REGEX.test(values.weightGrossReceivedH)) {
    errors.weightGrossReceivedH = setCorrectLabel(
      formValues?.assayOption?.value,
      ENTER_ASSAY.WEIGHT_GROSS_RECEIVED_INVALID_H,
      ENTER_ASSAY.WEIGHT_GROSS_RECEIVED_INVALID_B,
    );
  } else if (values.weightGrossReceivedH && values.weightGrossReceivedH.length > 10) {
    errors.weightGrossReceivedH = setCorrectLabel(
      formValues?.assayOption?.value,
      ENTER_ASSAY.WEIGHT_GROSS_RECEIVED_INVALID_H,
      ENTER_ASSAY.WEIGHT_GROSS_RECEIVED_INVALID_B,
    );
  } else if (safeParseFloat(values.weightGrossReceivedH) <= 0) {
    errors.weightGrossReceivedH = setCorrectLabel(
      formValues?.assayOption?.value,
      ENTER_ASSAY.WEIGHT_GROSS_RECEIVED_NEGATIVE_H,
      ENTER_ASSAY.WEIGHT_GROSS_RECEIVED_NEGATIVE_B,
    );
  }

  if (!replaceEmpty(values.weightTareReceivedH, '').length) {
    errors.weightTareReceivedH = setCorrectLabel(
      formValues?.assayOption?.value,
      ENTER_ASSAY.WEIGHT_TARE_RECEIVED_EMPTY_H,
      ENTER_ASSAY.WEIGHT_TARE_RECEIVED_EMPTY_B,
    );
  } else if (!FLOAT_REGEX.test(values.weightTareReceivedH)) {
    errors.weightTareReceivedH = setCorrectLabel(
      formValues?.assayOption?.value,
      ENTER_ASSAY.WEIGHT_TARE_RECEIVED_INVALID_H,
      ENTER_ASSAY.WEIGHT_TARE_RECEIVED_INVALID_B,
    );
  } else if (values.weightTareReceivedH && values.weightTareReceivedH.length > 10) {
    errors.weightTareReceivedH = setCorrectLabel(
      formValues?.assayOption?.value,
      ENTER_ASSAY.WEIGHT_TARE_RECEIVED_INVALID_H,
      ENTER_ASSAY.WEIGHT_TARE_RECEIVED_INVALID_B,
    );
  } else if (safeParseFloat(values.weightTareReceivedH) < 0) {
    errors.weightTareReceivedH = setCorrectLabel(
      formValues?.assayOption?.value,
      ENTER_ASSAY.WEIGHT_TARE_RECEIVED_NEGATIVE_H,
      ENTER_ASSAY.WEIGHT_TARE_RECEIVED_NEGATIVE_B,
    );
  } else if (
    safeParseFloat(values.weightTareReceivedH) >= safeParseFloat(formValues.weightGrossReceivedH)
  ) {
    errors.weightTareReceivedH = setCorrectLabel(
      formValues?.assayOption?.value,
      ENTER_ASSAY.WEIGHT_TARE_RECEIVED_GTE_GROSS_H,
      ENTER_ASSAY.WEIGHT_TARE_RECEIVED_GTE_GROSS_B,
    );
  }

  if (!replaceEmpty(values.weightNetWetReceivedH, '').length) {
    errors.weightNetWetReceivedH = setCorrectLabel(
      formValues?.assayOption?.value,
      ENTER_ASSAY.WEIGHT_NET_WET_RECEIVED_EMPTY_H,
      ENTER_ASSAY.WEIGHT_NET_WET_RECEIVED_EMPTY_B,
    );
  } else if (!FLOAT_REGEX.test(values.weightNetWetReceivedH)) {
    errors.weightNetWetReceivedH = setCorrectLabel(
      formValues?.assayOption?.value,
      ENTER_ASSAY.WEIGHT_NET_WET_RECEIVED_INVALID_H,
      ENTER_ASSAY.WEIGHT_NET_WET_RECEIVED_INVALID_B,
    );
  } else if (values.weightNetWetReceivedH && values.weightNetWetReceivedH.length > 10) {
    errors.weightNetWetReceivedH = setCorrectLabel(
      formValues?.assayOption?.value,
      ENTER_ASSAY.WEIGHT_NET_WET_RECEIVED_INVALID_H,
      ENTER_ASSAY.WEIGHT_NET_WET_RECEIVED_INVALID_B,
    );
  } else if (safeParseFloat(values.weightNetWetReceivedH) <= 0) {
    errors.weightNetWetReceivedH = setCorrectLabel(
      formValues?.assayOption?.value,
      ENTER_ASSAY.WEIGHT_NET_WET_RECEIVED_NEGATIVE_H,
      ENTER_ASSAY.WEIGHT_NET_WET_RECEIVED_NEGATIVE_B,
    );
  } else if (
    safeParseFloat(values.weightNetWetReceivedH) > safeParseFloat(formValues.weightGrossReceivedH)
  ) {
    errors.weightNetWetReceivedH = setCorrectLabel(
      formValues?.assayOption?.value,
      ENTER_ASSAY.WEIGHT_NET_WET_RECEIVED_GTE_GROSS_H,
      ENTER_ASSAY.WEIGHT_NET_WET_RECEIVED_GTE_GROSS_B,
    );
  }

  if (!replaceEmpty(values.weightScrapReceivedH, '').length) {
    errors.weightScrapReceivedH = setCorrectLabel(
      formValues?.assayOption?.value,
      ENTER_ASSAY.WEIGHT_SCRAP_RECEIVED_EMPTY_H,
      ENTER_ASSAY.WEIGHT_SCRAP_RECEIVED_EMPTY_B,
    );
  } else if (!FLOAT_REGEX.test(values.weightScrapReceivedH)) {
    errors.weightScrapReceivedH = setCorrectLabel(
      formValues?.assayOption?.value,
      ENTER_ASSAY.WEIGHT_SCRAP_RECEIVED_INVALID_H,
      ENTER_ASSAY.WEIGHT_SCRAP_RECEIVED_INVALID_B,
    );
  } else if (values.weightScrapReceivedH && values.weightScrapReceivedH.length > 10) {
    errors.weightScrapReceivedH = setCorrectLabel(
      formValues?.assayOption?.value,
      ENTER_ASSAY.WEIGHT_SCRAP_RECEIVED_INVALID_H,
      ENTER_ASSAY.WEIGHT_SCRAP_RECEIVED_INVALID_B,
    );
  } else if (safeParseFloat(values.weightScrapReceivedH) < 0) {
    errors.weightScrapReceivedH = setCorrectLabel(
      formValues?.assayOption?.value,
      ENTER_ASSAY.WEIGHT_SCRAP_RECEIVED_NEGATIVE_H,
      ENTER_ASSAY.WEIGHT_SCRAP_RECEIVED_NEGATIVE_B,
    );
  } else if (
    safeParseFloat(values.weightScrapReceivedH) >= safeParseFloat(formValues.weightNetWetReceivedH)
  ) {
    errors.weightScrapReceivedH = setCorrectLabel(
      formValues?.assayOption?.value,
      ENTER_ASSAY.WEIGHT_SCRAP_RECEIVED_GTE_NET_WET_H,
      ENTER_ASSAY.WEIGHT_SCRAP_RECEIVED_GTE_NET_WET_B,
    );
  }

  if (!replaceEmpty(values.weightAdjustedNetWetReceivedH, '').length) {
    errors.weightAdjustedNetWetReceivedH = setCorrectLabel(
      formValues?.assayOption?.value,
      ENTER_ASSAY.WEIGHT_ADJUSTED_NET_WET_RECEIVED_EMPTY_H,
      ENTER_ASSAY.WEIGHT_ADJUSTED_NET_WET_RECEIVED_EMPTY_B,
    );
  } else if (!FLOAT_REGEX.test(values.weightAdjustedNetWetReceivedH)) {
    errors.weightAdjustedNetWetReceivedH = setCorrectLabel(
      formValues?.assayOption?.value,
      ENTER_ASSAY.WEIGHT_ADJUSTED_NET_WET_RECEIVED_INVALID_H,
      ENTER_ASSAY.WEIGHT_ADJUSTED_NET_WET_RECEIVED_INVALID_B,
    );
  } else if (
    values.weightAdjustedNetWetReceivedH &&
    values.weightAdjustedNetWetReceivedH.length > 10
  ) {
    errors.weightAdjustedNetWetReceivedH = setCorrectLabel(
      formValues?.assayOption?.value,
      ENTER_ASSAY.WEIGHT_ADJUSTED_NET_WET_RECEIVED_INVALID_H,
      ENTER_ASSAY.WEIGHT_ADJUSTED_NET_WET_RECEIVED_INVALID_B,
    );
  } else if (safeParseFloat(values.weightAdjustedNetWetReceivedH) <= 0) {
    errors.weightAdjustedNetWetReceivedH = setCorrectLabel(
      formValues?.assayOption?.value,
      ENTER_ASSAY.WEIGHT_ADJUSTED_NET_WET_RECEIVED_NEGATIVE_H,
      ENTER_ASSAY.WEIGHT_ADJUSTED_NET_WET_RECEIVED_NEGATIVE_B,
    );
  } else if (
    safeParseFloat(values.weightAdjustedNetWetReceivedH) >
    safeParseFloat(formValues.weightNetWetReceivedH)
  ) {
    errors.weightAdjustedNetWetReceivedH = setCorrectLabel(
      formValues?.assayOption?.value,
      ENTER_ASSAY.WEIGHT_ADJUSTED_NET_WET_RECEIVED_GTE_NET_WET_H,
      ENTER_ASSAY.WEIGHT_ADJUSTED_NET_WET_RECEIVED_GTE_NET_WET_B,
    );
  }

  if (!replaceEmpty(values.weightNetDryReceivedH, '').length) {
    errors.weightNetDryReceivedH = setCorrectLabel(
      formValues?.assayOption?.value,
      ENTER_ASSAY.WEIGHT_NET_DRY_RECEIVED_EMPTY_H,
      ENTER_ASSAY.WEIGHT_NET_DRY_RECEIVED_EMPTY_B,
    );
  } else if (!FLOAT_REGEX.test(values.weightNetDryReceivedH)) {
    errors.weightNetDryReceivedH = setCorrectLabel(
      formValues?.assayOption?.value,
      ENTER_ASSAY.WEIGHT_NET_DRY_RECEIVED_INVALID_H,
      ENTER_ASSAY.WEIGHT_NET_DRY_RECEIVED_INVALID_B,
    );
  } else if (values.weightScrapReceivedH && values.weightNetDryReceivedH.length > 10) {
    errors.weightNetDryReceivedH = setCorrectLabel(
      formValues?.assayOption?.value,
      ENTER_ASSAY.WEIGHT_NET_DRY_RECEIVED_INVALID_H,
      ENTER_ASSAY.WEIGHT_NET_DRY_RECEIVED_INVALID_B,
    );
  } else if (safeParseFloat(values.weightNetDryReceivedH) <= 0) {
    errors.weightNetDryReceivedH = setCorrectLabel(
      formValues?.assayOption?.value,
      ENTER_ASSAY.WEIGHT_NET_DRY_RECEIVED_NEGATIVE_H,
      ENTER_ASSAY.WEIGHT_NET_DRY_RECEIVED_NEGATIVE_B,
    );
  } else if (
    safeParseFloat(values.weightNetDryReceivedH) > safeParseFloat(formValues.weightNetWetReceivedH)
  ) {
    errors.weightNetDryReceivedH = setCorrectLabel(
      formValues?.assayOption?.value,
      ENTER_ASSAY.WEIGHT_NET_DRY_RECEIVED_GT_NET_WET_H,
      ENTER_ASSAY.WEIGHT_NET_DRY_RECEIVED_GT_NET_WET_B,
    );
  }

  if (!replaceEmpty(values.percentMoistureH, '').length) {
    errors.percentMoistureH = setCorrectLabel(
      formValues?.assayOption?.value,
      ENTER_ASSAY.PERCENT_MOISTURE_EMPTY_H,
      ENTER_ASSAY.PERCENT_MOISTURE_EMPTY_B,
    );
  } else if (
    safeParseFloat(values.percentMoistureH) < 0 ||
    safeParseFloat(values.percentMoistureH) > 10
  ) {
    errors.percentMoistureH = setCorrectLabel(
      formValues?.assayOption?.value,
      ENTER_ASSAY.PERCENT_MOISTURE_RANGE_H,
      ENTER_ASSAY.PERCENT_MOISTURE_RANGE_B,
    );
  } else if (
    !THREE_DECIMAL_REGEX.test(values.percentMoistureH) ||
    values.percentMoistureH.length > 6
  ) {
    errors.percentMoistureH = setCorrectLabel(
      formValues?.assayOption?.value,
      ENTER_ASSAY.PERCENT_MOISTURE_INVALID_H,
      ENTER_ASSAY.PERCENT_MOISTURE_INVALID_B,
    );
  }

  if (!replaceEmpty(values.carbonPercentageH, '').length) {
    errors.carbonPercentageH = setCorrectLabel(
      formValues?.assayOption?.value,
      ENTER_ASSAY.CARBON_PERCENTAGE_EMPTY_H,
      ENTER_ASSAY.CARBON_PERCENTAGE_EMPTY_B,
    );
  } else if (
    safeParseFloat(values.carbonPercentageH) < 0 ||
    safeParseFloat(values.carbonPercentageH) > 10
  ) {
    errors.carbonPercentageH = setCorrectLabel(
      formValues?.assayOption?.value,
      ENTER_ASSAY.CARBON_PERCENTAGE_RANGE_H,
      ENTER_ASSAY.CARBON_PERCENTAGE_RANGE_B,
    );
  } else if (
    !THREE_DECIMAL_REGEX.test(values.carbonPercentageH) ||
    values.carbonPercentageH.length > 6
  ) {
    errors.carbonPercentageH = setCorrectLabel(
      formValues?.assayOption?.value,
      ENTER_ASSAY.CARBON_PERCENTAGE_INVALID_H,
      ENTER_ASSAY.CARBON_PERCENTAGE_INVALID_B,
    );
  }

  if (!replaceEmpty(values.platinumAssayH, '').length) {
    errors.platinumAssayH = ENTER_ASSAY.PLATINUM_ASSAY_EMPTY_H;
  } else if (!ONE_DECIMAL_REGEX.test(values.platinumAssayH)) {
    errors.platinumAssayH = ENTER_ASSAY.PLATINUM_ASSAY_INVALID_H;
  } else if (values.platinumAssayH && values.platinumAssayH.length > 10) {
    errors.platinumAssayH = ENTER_ASSAY.PLATINUM_ASSAY_INVALID_H;
  } else if (safeParseFloat(values.platinumAssayH) < 0) {
    errors.platinumAssayH = ENTER_ASSAY.PLATINUM_ASSAY_NEGATIVE_H;
  }

  if (!replaceEmpty(values.palladiumAssayH, '').length) {
    errors.palladiumAssayH = ENTER_ASSAY.PALLADIUM_ASSAY_EMPTY_H;
  } else if (!ONE_DECIMAL_REGEX.test(values.palladiumAssayH)) {
    errors.palladiumAssayH = ENTER_ASSAY.PALLADIUM_ASSAY_INVALID_H;
  } else if (values.palladiumAssayH && values.palladiumAssayH.length > 10) {
    errors.palladiumAssayH = ENTER_ASSAY.PALLADIUM_ASSAY_INVALID_H;
  } else if (safeParseFloat(values.palladiumAssayH) < 0) {
    errors.palladiumAssayH = ENTER_ASSAY.PALLADIUM_ASSAY_INVALID_H;
  }

  if (!replaceEmpty(values.rhodiumAssayH, '').length) {
    errors.rhodiumAssayH = ENTER_ASSAY.RHODIUM_ASSAY_EMPTY_H;
  } else if (!ONE_DECIMAL_REGEX.test(values.rhodiumAssayH)) {
    errors.rhodiumAssayH = ENTER_ASSAY.RHODIUM_ASSAY_INVALID_H;
  } else if (values.rhodiumAssayH && values.rhodiumAssayH.length > 10) {
    errors.rhodiumAssayH = ENTER_ASSAY.RHODIUM_ASSAY_INVALID_H;
  } else if (safeParseFloat(values.rhodiumAssayH) < 0) {
    errors.rhodiumAssayH = ENTER_ASSAY.RHODIUM_ASSAY_NEGATIVE_H;
  }

  if (!replaceEmpty(values.platinumContainedOzH, '').length) {
    errors.platinumContainedOzH = ENTER_ASSAY.PLATINUM_CONTAINED_OZ_EMPTY_H;
  } else if (values.platinumContainedOzH && values.platinumContainedOzH.length > 10) {
    errors.platinumContainedOzH = ENTER_ASSAY.PLATINUM_CONTAINED_OZ_INVALID_H;
  } else if (safeParseFloat(values.platinumContainedOzH) < 0) {
    errors.platinumContainedOzH = ENTER_ASSAY.PLATINUM_CONTAINED_OZ_NEGATIVE_H;
  } else if (!THREE_DECIMAL_REGEX.test(values.platinumContainedOzH)) {
    errors.platinumContainedOzH = ENTER_ASSAY.PLATINUM_CONTAINED_OZ_INVALID_H;
  }

  if (!replaceEmpty(values.palladiumContainedOzH, '').length) {
    errors.palladiumContainedOzH = ENTER_ASSAY.PALLADIUM_CONTAINED_OZ_EMPTY_H;
  } else if (!THREE_DECIMAL_REGEX.test(values.palladiumContainedOzH)) {
    errors.palladiumContainedOzH = ENTER_ASSAY.PALLADIUM_CONTAINED_OZ_INVALID_H;
  } else if (values.palladiumContainedOzH && values.palladiumContainedOzH.length > 10) {
    errors.palladiumContainedOzH = ENTER_ASSAY.PALLADIUM_CONTAINED_OZ_INVALID_H;
  } else if (safeParseFloat(values.palladiumContainedOzH) < 0) {
    errors.palladiumContainedOzH = ENTER_ASSAY.PALLADIUM_CONTAINED_OZ_INVALID_H;
  }

  if (!replaceEmpty(values.rhodiumContainedOzH, '').length) {
    errors.rhodiumContainedOzH = ENTER_ASSAY.RHODIUM_CONTAINED_OZ_EMPTY_H;
  } else if (!THREE_DECIMAL_REGEX.test(values.rhodiumContainedOzH)) {
    errors.rhodiumContainedOzH = ENTER_ASSAY.RHODIUM_CONTAINED_OZ_INVALID_H;
  } else if (values.rhodiumContainedOzH && values.rhodiumContainedOzH.length > 10) {
    errors.rhodiumContainedOzH = ENTER_ASSAY.RHODIUM_CONTAINED_OZ_INVALID_H;
  } else if (safeParseFloat(values.rhodiumContainedOzH) < 0) {
    errors.rhodiumContainedOzH = ENTER_ASSAY.RHODIUM_CONTAINED_OZ_NEGATIVE_H;
  }

  if (!replaceEmpty(values.platinumReturnableOzH, '').length) {
    errors.platinumReturnableOzH = ENTER_ASSAY.PLATINUM_RETURNABLE_OZ_EMPTY_H;
  } else if (!THREE_DECIMAL_REGEX.test(values.platinumReturnableOzH)) {
    errors.platinumReturnableOzH = ENTER_ASSAY.PLATINUM_RETURNABLE_OZ_INVALID_H;
  } else if (values.platinumReturnableOzH && values.platinumReturnableOzH.length > 10) {
    errors.platinumReturnableOzH = ENTER_ASSAY.PLATINUM_RETURNABLE_OZ_INVALID_H;
  } else if (safeParseFloat(values.platinumReturnableOzH) < 0) {
    errors.platinumReturnableOzH = ENTER_ASSAY.PLATINUM_RETURNABLE_OZ_NEGATIVE_H;
  }

  if (!replaceEmpty(values.palladiumReturnableOzH, '').length) {
    errors.palladiumReturnableOzH = ENTER_ASSAY.PALLADIUM_RETURNABLE_OZ_EMPTY_H;
  } else if (!THREE_DECIMAL_REGEX.test(values.palladiumReturnableOzH)) {
    errors.palladiumReturnableOzH = ENTER_ASSAY.PALLADIUM_RETURNABLE_OZ_INVALID_H;
  } else if (values.palladiumReturnableOzH && values.palladiumReturnableOzH.length > 10) {
    errors.palladiumReturnableOzH = ENTER_ASSAY.PALLADIUM_RETURNABLE_OZ_INVALID_H;
  } else if (safeParseFloat(values.palladiumReturnableOzH) < 0) {
    errors.palladiumReturnableOzH = ENTER_ASSAY.PALLADIUM_RETURNABLE_OZ_INVALID_H;
  }

  if (!replaceEmpty(values.rhodiumReturnableOzH, '').length) {
    errors.rhodiumReturnableOzH = ENTER_ASSAY.RHODIUM_RETURNABLE_OZ_EMPTY_H;
  } else if (!THREE_DECIMAL_REGEX.test(values.rhodiumReturnableOzH)) {
    errors.rhodiumReturnableOzH = ENTER_ASSAY.RHODIUM_RETURNABLE_OZ_INVALID_H;
  } else if (values.rhodiumReturnableOzH && values.rhodiumReturnableOzH.length > 10) {
    errors.rhodiumReturnableOzH = ENTER_ASSAY.RHODIUM_RETURNABLE_OZ_INVALID_H;
  } else if (safeParseFloat(values.rhodiumReturnableOzH) < 0) {
    errors.rhodiumReturnableOzH = ENTER_ASSAY.RHODIUM_RETURNABLE_OZ_NEGATIVE_H;
  }

  if (!replaceEmpty(values.weightGrossReceivedF, '').length) {
    errors.weightGrossReceivedF = setCorrectLabel(
      formValues?.assayOption?.value,
      ENTER_ASSAY.WEIGHT_GROSS_RECEIVED_EMPTY_F,
      ENTER_ASSAY.WEIGHT_GROSS_RECEIVED_EMPTY_A,
    );
  } else if (!FLOAT_REGEX.test(values.weightGrossReceivedF)) {
    errors.weightGrossReceivedF = setCorrectLabel(
      formValues?.assayOption?.value,
      ENTER_ASSAY.WEIGHT_GROSS_RECEIVED_INVALID_F,
      ENTER_ASSAY.WEIGHT_GROSS_RECEIVED_INVALID_A,
    );
  } else if (values.weightGrossReceivedF && values.weightGrossReceivedF.length > 10) {
    errors.weightGrossReceivedF = setCorrectLabel(
      formValues?.assayOption?.value,
      ENTER_ASSAY.WEIGHT_GROSS_RECEIVED_INVALID_F,
      ENTER_ASSAY.WEIGHT_GROSS_RECEIVED_INVALID_A,
    );
  } else if (safeParseFloat(values.weightGrossReceivedF) <= 0) {
    errors.weightGrossReceivedF = setCorrectLabel(
      formValues?.assayOption?.value,
      ENTER_ASSAY.WEIGHT_GROSS_RECEIVED_NEGATIVE_F,
      ENTER_ASSAY.WEIGHT_GROSS_RECEIVED_NEGATIVE_A,
    );
  }

  if (!replaceEmpty(values.weightTareReceivedF, '').length) {
    errors.weightTareReceivedF = setCorrectLabel(
      formValues?.assayOption?.value,
      ENTER_ASSAY.WEIGHT_TARE_RECEIVED_EMPTY_F,
      ENTER_ASSAY.WEIGHT_TARE_RECEIVED_EMPTY_A,
    );
  } else if (!FLOAT_REGEX.test(values.weightTareReceivedF)) {
    errors.weightTareReceivedF = setCorrectLabel(
      formValues?.assayOption?.value,
      ENTER_ASSAY.WEIGHT_TARE_RECEIVED_INVALID_F,
      ENTER_ASSAY.WEIGHT_TARE_RECEIVED_INVALID_A,
    );
  } else if (values.weightTareReceivedF && values.weightTareReceivedF.length > 10) {
    errors.weightTareReceivedF = setCorrectLabel(
      formValues?.assayOption?.value,
      ENTER_ASSAY.WEIGHT_TARE_RECEIVED_INVALID_F,
      ENTER_ASSAY.WEIGHT_TARE_RECEIVED_INVALID_A,
    );
  } else if (safeParseFloat(values.weightTareReceivedF) < 0) {
    errors.weightTareReceivedF = setCorrectLabel(
      formValues?.assayOption?.value,
      ENTER_ASSAY.WEIGHT_TARE_RECEIVED_NEGATIVE_F,
      ENTER_ASSAY.WEIGHT_TARE_RECEIVED_INVALID_A,
    );
  } else if (
    safeParseFloat(values.weightTareReceivedF) >= safeParseFloat(formValues.weightGrossReceivedF)
  ) {
    errors.weightTareReceivedF = setCorrectLabel(
      formValues?.assayOption?.value,
      ENTER_ASSAY.WEIGHT_TARE_RECEIVED_GTE_GROSS_F,
      ENTER_ASSAY.WEIGHT_TARE_RECEIVED_GTE_GROSS_A,
    );
  }

  if (!replaceEmpty(values.weightNetWetReceivedF, '').length) {
    errors.weightNetWetReceivedF = setCorrectLabel(
      formValues?.assayOption?.value,
      ENTER_ASSAY.WEIGHT_NET_WET_RECEIVED_EMPTY_F,
      ENTER_ASSAY.WEIGHT_NET_WET_RECEIVED_EMPTY_A,
    );
  } else if (!FLOAT_REGEX.test(values.weightNetWetReceivedF)) {
    errors.weightNetWetReceivedF = setCorrectLabel(
      formValues?.assayOption?.value,
      ENTER_ASSAY.WEIGHT_NET_WET_RECEIVED_INVALID_F,
      ENTER_ASSAY.WEIGHT_NET_WET_RECEIVED_INVALID_A,
    );
  } else if (values.weightNetWetReceivedF && values.weightNetWetReceivedF.length > 10) {
    errors.weightNetWetReceivedF = setCorrectLabel(
      formValues?.assayOption?.value,
      ENTER_ASSAY.WEIGHT_NET_WET_RECEIVED_INVALID_F,
      ENTER_ASSAY.WEIGHT_NET_WET_RECEIVED_INVALID_A,
    );
  } else if (safeParseFloat(values.weightNetWetReceivedF) <= 0) {
    errors.weightNetWetReceivedF = setCorrectLabel(
      formValues?.assayOption?.value,
      ENTER_ASSAY.WEIGHT_NET_WET_RECEIVED_NEGATIVE_F,
      ENTER_ASSAY.WEIGHT_NET_WET_RECEIVED_NEGATIVE_A,
    );
  } else if (
    safeParseFloat(values.weightNetWetReceivedF) > safeParseFloat(formValues.weightGrossReceivedF)
  ) {
    errors.weightNetWetReceivedF = setCorrectLabel(
      formValues?.assayOption?.value,
      ENTER_ASSAY.WEIGHT_NET_WET_RECEIVED_GTE_GROSS_F,
      ENTER_ASSAY.WEIGHT_NET_WET_RECEIVED_GTE_GROSS_A,
    );
  }

  if (!replaceEmpty(values.weightScrapReceivedF, '').length) {
    errors.weightScrapReceivedF = setCorrectLabel(
      formValues?.assayOption?.value,
      ENTER_ASSAY.WEIGHT_SCRAP_RECEIVED_EMPTY_F,
      ENTER_ASSAY.WEIGHT_SCRAP_RECEIVED_EMPTY_A,
    );
  } else if (!FLOAT_REGEX.test(values.weightScrapReceivedF)) {
    errors.weightScrapReceivedF = setCorrectLabel(
      formValues?.assayOption?.value,
      ENTER_ASSAY.WEIGHT_SCRAP_RECEIVED_INVALID_F,
      ENTER_ASSAY.WEIGHT_SCRAP_RECEIVED_INVALID_A,
    );
  } else if (values.weightScrapReceivedF && values.weightScrapReceivedF.length > 10) {
    errors.weightScrapReceivedF = setCorrectLabel(
      formValues?.assayOption?.value,
      ENTER_ASSAY.WEIGHT_SCRAP_RECEIVED_INVALID_F,
      ENTER_ASSAY.WEIGHT_SCRAP_RECEIVED_INVALID_A,
    );
  } else if (safeParseFloat(values.weightScrapReceivedF) < 0) {
    errors.weightScrapReceivedF = setCorrectLabel(
      formValues?.assayOption?.value,
      ENTER_ASSAY.WEIGHT_SCRAP_RECEIVED_NEGATIVE_F,
      ENTER_ASSAY.WEIGHT_SCRAP_RECEIVED_NEGATIVE_A,
    );
  } else if (
    safeParseFloat(values.weightScrapReceivedF) >= safeParseFloat(formValues.weightNetWetReceivedF)
  ) {
    errors.weightScrapReceivedF = setCorrectLabel(
      formValues?.assayOption?.value,
      ENTER_ASSAY.WEIGHT_SCRAP_RECEIVED_GTE_NET_WET_F,
      ENTER_ASSAY.WEIGHT_SCRAP_RECEIVED_GTE_NET_WET_A,
    );
  }

  if (!replaceEmpty(values.weightAdjustedNetWetReceivedF, '').length) {
    errors.weightAdjustedNetWetReceivedF = setCorrectLabel(
      formValues?.assayOption?.value,
      ENTER_ASSAY.WEIGHT_ADJUSTED_NET_WET_RECEIVED_EMPTY_F,
      ENTER_ASSAY.WEIGHT_ADJUSTED_NET_WET_RECEIVED_EMPTY_A,
    );
  } else if (!FLOAT_REGEX.test(values.weightAdjustedNetWetReceivedF)) {
    errors.weightAdjustedNetWetReceivedF = setCorrectLabel(
      formValues?.assayOption?.value,
      ENTER_ASSAY.WEIGHT_ADJUSTED_NET_WET_RECEIVED_INVALID_F,
      ENTER_ASSAY.WEIGHT_ADJUSTED_NET_WET_RECEIVED_INVALID_A,
    );
  } else if (
    values.weightAdjustedNetWetReceivedF &&
    values.weightAdjustedNetWetReceivedF.length > 10
  ) {
    errors.weightAdjustedNetWetReceivedF = setCorrectLabel(
      formValues?.assayOption?.value,
      ENTER_ASSAY.WEIGHT_ADJUSTED_NET_WET_RECEIVED_INVALID_F,
      ENTER_ASSAY.WEIGHT_ADJUSTED_NET_WET_RECEIVED_INVALID_A,
    );
  } else if (safeParseFloat(values.weightAdjustedNetWetReceivedF) <= 0) {
    errors.weightAdjustedNetWetReceivedF = setCorrectLabel(
      formValues?.assayOption?.value,
      ENTER_ASSAY.WEIGHT_ADJUSTED_NET_WET_RECEIVED_NEGATIVE_F,
      ENTER_ASSAY.WEIGHT_ADJUSTED_NET_WET_RECEIVED_NEGATIVE_A,
    );
  } else if (
    safeParseFloat(values.weightAdjustedNetWetReceivedF) >
    safeParseFloat(formValues.weightNetWetReceivedF)
  ) {
    errors.weightAdjustedNetWetReceivedF = setCorrectLabel(
      formValues?.assayOption?.value,
      ENTER_ASSAY.WEIGHT_ADJUSTED_NET_WET_RECEIVED_GTE_NET_WET_F,
      ENTER_ASSAY.WEIGHT_ADJUSTED_NET_WET_RECEIVED_GTE_NET_WET_A,
    );
  }

  if (!replaceEmpty(values.weightNetDryReceivedF, '').length) {
    errors.weightNetDryReceivedF = setCorrectLabel(
      formValues?.assayOption?.value,
      ENTER_ASSAY.WEIGHT_NET_DRY_RECEIVED_EMPTY_F,
      ENTER_ASSAY.WEIGHT_NET_DRY_RECEIVED_EMPTY_A,
    );
  } else if (!FLOAT_REGEX.test(values.weightNetDryReceivedF)) {
    errors.weightNetDryReceivedF = setCorrectLabel(
      formValues?.assayOption?.value,
      ENTER_ASSAY.WEIGHT_NET_DRY_RECEIVED_INVALID_F,
      ENTER_ASSAY.WEIGHT_NET_DRY_RECEIVED_INVALID_A,
    );
  } else if (values.weightScrapReceivedF && values.weightNetDryReceivedF.length > 10) {
    errors.weightNetDryReceivedF = setCorrectLabel(
      formValues?.assayOption?.value,
      ENTER_ASSAY.WEIGHT_NET_DRY_RECEIVED_INVALID_F,
      ENTER_ASSAY.WEIGHT_NET_DRY_RECEIVED_INVALID_A,
    );
  } else if (safeParseFloat(values.weightNetDryReceivedF) <= 0) {
    errors.weightNetDryReceivedF = setCorrectLabel(
      formValues?.assayOption?.value,
      ENTER_ASSAY.WEIGHT_NET_DRY_RECEIVED_NEGATIVE_F,
      ENTER_ASSAY.WEIGHT_NET_DRY_RECEIVED_NEGATIVE_A,
    );
  } else if (
    safeParseFloat(values.weightNetDryReceivedF) > safeParseFloat(formValues.weightNetWetReceivedF)
  ) {
    errors.weightNetDryReceivedF = setCorrectLabel(
      formValues?.assayOption?.value,
      ENTER_ASSAY.WEIGHT_NET_DRY_RECEIVED_GT_NET_WET_F,
      ENTER_ASSAY.WEIGHT_NET_DRY_RECEIVED_GT_NET_WET_A,
    );
  }

  if (!replaceEmpty(values.percentMoistureF, '').length) {
    errors.percentMoistureF = setCorrectLabel(
      formValues?.assayOption?.value,
      ENTER_ASSAY.PERCENT_MOISTURE_EMPTY_F,
      ENTER_ASSAY.PERCENT_MOISTURE_EMPTY_A,
    );
  } else if (
    safeParseFloat(values.percentMoistureF) < 0 ||
    safeParseFloat(values.percentMoistureF) > 10
  ) {
    errors.percentMoistureF = setCorrectLabel(
      formValues?.assayOption?.value,
      ENTER_ASSAY.PERCENT_MOISTURE_RANGE_F,
      ENTER_ASSAY.PERCENT_MOISTURE_RANGE_A,
    );
  } else if (
    !THREE_DECIMAL_REGEX.test(values.percentMoistureF) ||
    values.percentMoistureF.length > 6
  ) {
    errors.percentMoistureF = setCorrectLabel(
      formValues?.assayOption?.value,
      ENTER_ASSAY.PERCENT_MOISTURE_INVALID_F,
      ENTER_ASSAY.PERCENT_MOISTURE_INVALID_A,
    );
  }

  if (!replaceEmpty(values.carbonPercentageF, '').length) {
    errors.carbonPercentageF = setCorrectLabel(
      formValues?.assayOption?.value,
      ENTER_ASSAY.CARBON_PERCENTAGE_EMPTY_F,
      ENTER_ASSAY.CARBON_PERCENTAGE_EMPTY_A,
    );
  } else if (
    safeParseFloat(values.carbonPercentageF) < 0 ||
    safeParseFloat(values.carbonPercentageF) > 10
  ) {
    errors.carbonPercentageF = setCorrectLabel(
      formValues?.assayOption?.value,
      ENTER_ASSAY.CARBON_PERCENTAGE_RANGE_F,
      ENTER_ASSAY.CARBON_PERCENTAGE_RANGE_A,
    );
  } else if (
    !THREE_DECIMAL_REGEX.test(values.carbonPercentageF) ||
    values.carbonPercentageF.length > 6
  ) {
    errors.carbonPercentageF = setCorrectLabel(
      formValues?.assayOption?.value,
      ENTER_ASSAY.CARBON_PERCENTAGE_INVALID_F,
      ENTER_ASSAY.CARBON_PERCENTAGE_INVALID_A,
    );
  }

  if (!replaceEmpty(values.platinumAssayF, '').length) {
    errors.platinumAssayF = ENTER_ASSAY.PLATINUM_ASSAY_EMPTY_F;
  } else if (!ONE_DECIMAL_REGEX.test(values.platinumAssayF)) {
    errors.platinumAssayF = ENTER_ASSAY.PLATINUM_ASSAY_INVALID_F;
  } else if (values.platinumAssayF && values.platinumAssayF.length > 10) {
    errors.platinumAssayF = ENTER_ASSAY.PLATINUM_ASSAY_INVALID_F;
  } else if (safeParseFloat(values.platinumAssayF) < 0) {
    errors.platinumAssayF = ENTER_ASSAY.PLATINUM_ASSAY_NEGATIVE_F;
  }

  if (!replaceEmpty(values.palladiumAssayF, '').length) {
    errors.palladiumAssayF = ENTER_ASSAY.PALLADIUM_ASSAY_EMPTY_F;
  } else if (!ONE_DECIMAL_REGEX.test(values.palladiumAssayF)) {
    errors.palladiumAssayF = ENTER_ASSAY.PALLADIUM_ASSAY_INVALID_F;
  } else if (values.palladiumAssayF && values.palladiumAssayF.length > 10) {
    errors.palladiumAssayF = ENTER_ASSAY.PALLADIUM_ASSAY_INVALID_F;
  } else if (safeParseFloat(values.palladiumAssayF) < 0) {
    errors.palladiumAssayF = ENTER_ASSAY.PALLADIUM_ASSAY_INVALID_F;
  }

  if (!replaceEmpty(values.rhodiumAssayF, '').length) {
    errors.rhodiumAssayF = ENTER_ASSAY.RHODIUM_ASSAY_EMPTY_F;
  } else if (!ONE_DECIMAL_REGEX.test(values.rhodiumAssayF)) {
    errors.rhodiumAssayF = ENTER_ASSAY.RHODIUM_ASSAY_INVALID_F;
  } else if (values.rhodiumAssayF && values.rhodiumAssayF.length > 10) {
    errors.rhodiumAssayF = ENTER_ASSAY.RHODIUM_ASSAY_INVALID_F;
  } else if (safeParseFloat(values.rhodiumAssayF) < 0) {
    errors.rhodiumAssayF = ENTER_ASSAY.RHODIUM_ASSAY_NEGATIVE_F;
  }

  if (!replaceEmpty(values.platinumContainedOzF, '').length) {
    errors.platinumContainedOzF = ENTER_ASSAY.PLATINUM_CONTAINED_OZ_EMPTY_F;
  } else if (values.platinumContainedOzF && values.platinumContainedOzF.length > 10) {
    errors.platinumContainedOzF = ENTER_ASSAY.PLATINUM_CONTAINED_OZ_INVALID_F;
  } else if (safeParseFloat(values.platinumContainedOzF) < 0) {
    errors.platinumContainedOzF = ENTER_ASSAY.PLATINUM_CONTAINED_OZ_NEGATIVE_F;
  } else if (!THREE_DECIMAL_REGEX.test(values.platinumContainedOzF)) {
    errors.platinumContainedOzF = ENTER_ASSAY.PLATINUM_CONTAINED_OZ_INVALID_F;
  }

  if (!replaceEmpty(values.palladiumContainedOzF, '').length) {
    errors.palladiumContainedOzF = ENTER_ASSAY.PALLADIUM_CONTAINED_OZ_EMPTY_F;
  } else if (!THREE_DECIMAL_REGEX.test(values.palladiumContainedOzF)) {
    errors.palladiumContainedOzF = ENTER_ASSAY.PALLADIUM_CONTAINED_OZ_INVALID_F;
  } else if (values.palladiumContainedOzF && values.palladiumContainedOzF.length > 10) {
    errors.palladiumContainedOzF = ENTER_ASSAY.PALLADIUM_CONTAINED_OZ_INVALID_F;
  } else if (safeParseFloat(values.palladiumContainedOzF) < 0) {
    errors.palladiumContainedOzF = ENTER_ASSAY.PALLADIUM_CONTAINED_OZ_INVALID_F;
  }

  if (!replaceEmpty(values.rhodiumContainedOzF, '').length) {
    errors.rhodiumContainedOzF = ENTER_ASSAY.RHODIUM_CONTAINED_OZ_EMPTY_F;
  } else if (!THREE_DECIMAL_REGEX.test(values.rhodiumContainedOzF)) {
    errors.rhodiumContainedOzF = ENTER_ASSAY.RHODIUM_CONTAINED_OZ_INVALID_F;
  } else if (values.rhodiumContainedOzF && values.rhodiumContainedOzF.length > 10) {
    errors.rhodiumContainedOzF = ENTER_ASSAY.RHODIUM_CONTAINED_OZ_INVALID_F;
  } else if (safeParseFloat(values.rhodiumContainedOzF) < 0) {
    errors.rhodiumContainedOzF = ENTER_ASSAY.RHODIUM_CONTAINED_OZ_NEGATIVE_F;
  }

  if (!replaceEmpty(values.platinumReturnableOzF, '').length) {
    errors.platinumReturnableOzF = ENTER_ASSAY.PLATINUM_RETURNABLE_OZ_EMPTY_F;
  } else if (!THREE_DECIMAL_REGEX.test(values.platinumReturnableOzF)) {
    errors.platinumReturnableOzF = ENTER_ASSAY.PLATINUM_RETURNABLE_OZ_INVALID_F;
  } else if (values.platinumReturnableOzF && values.platinumReturnableOzF.length > 10) {
    errors.platinumReturnableOzF = ENTER_ASSAY.PLATINUM_RETURNABLE_OZ_INVALID_F;
  } else if (safeParseFloat(values.platinumReturnableOzF) < 0) {
    errors.platinumReturnableOzF = ENTER_ASSAY.PLATINUM_RETURNABLE_OZ_NEGATIVE_F;
  }

  if (!replaceEmpty(values.palladiumReturnableOzF, '').length) {
    errors.palladiumReturnableOzF = ENTER_ASSAY.PALLADIUM_RETURNABLE_OZ_EMPTY_F;
  } else if (!THREE_DECIMAL_REGEX.test(values.palladiumReturnableOzF)) {
    errors.palladiumReturnableOzF = ENTER_ASSAY.PALLADIUM_RETURNABLE_OZ_INVALID_F;
  } else if (values.palladiumReturnableOzF && values.palladiumReturnableOzF.length > 10) {
    errors.palladiumReturnableOzF = ENTER_ASSAY.PALLADIUM_RETURNABLE_OZ_INVALID_F;
  } else if (safeParseFloat(values.palladiumReturnableOzF) < 0) {
    errors.palladiumReturnableOzF = ENTER_ASSAY.PALLADIUM_RETURNABLE_OZ_INVALID_F;
  }

  if (!replaceEmpty(values.rhodiumReturnableOzF, '').length) {
    errors.rhodiumReturnableOzF = ENTER_ASSAY.RHODIUM_RETURNABLE_OZ_EMPTY_F;
  } else if (!THREE_DECIMAL_REGEX.test(values.rhodiumReturnableOzF)) {
    errors.rhodiumReturnableOzF = ENTER_ASSAY.RHODIUM_RETURNABLE_OZ_INVALID_F;
  } else if (values.rhodiumReturnableOzF && values.rhodiumReturnableOzF.length > 10) {
    errors.rhodiumReturnableOzF = ENTER_ASSAY.RHODIUM_RETURNABLE_OZ_INVALID_F;
  } else if (safeParseFloat(values.rhodiumReturnableOzF) < 0) {
    errors.rhodiumReturnableOzF = ENTER_ASSAY.RHODIUM_RETURNABLE_OZ_NEGATIVE_F;
  }

  commaValidator(
    {
      weightGrossReceived: values.weightGrossReceived,
      weightTareReceived: values.weightTareReceived,
      weightNetWetReceived: values.weightNetWetReceived,
      weightScrapReceived: values.weightScrapReceived,
      weightAdjustedNetWetReceived: values.weightAdjustedNetWetReceived,
      percentMoisture: values.percentMoisture,
      weightNetDryReceived: values.weightNetDryReceived,
      weightGrossReceivedH: values.weightGrossReceivedH,
      weightTareReceivedH: values.weightTareReceivedH,
      weightNetWetReceivedH: values.weightNetWetReceivedH,
      weightScrapReceivedH: values.weightScrapReceivedH,
      weightAdjustedNetWetReceivedH: values.weightAdjustedNetWetReceivedH,
      percentMoistureH: values.percentMoistureH,
      weightNetDryReceivedH: values.weightNetDryReceivedH,
      weightGrossReceivedF: values.weightGrossReceivedF,
      weightTareReceivedF: values.weightTareReceivedF,
      weightNetWetReceivedF: values.weightNetWetReceivedF,
      weightScrapReceivedF: values.weightScrapReceivedF,
      weightAdjustedNetWetReceivedF: values.weightAdjustedNetWetReceivedF,
      percentMoistureF: values.percentMoistureF,
      weightNetDryReceivedF: values.weightNetDryReceivedF,
      platinumAssay: values.platinumAssay,
      palladiumAssay: values.palladiumAssay,
      rhodiumAssay: values.rhodiumAssay,
      platinumAssayH: values.platinumAssayH,
      palladiumAssayH: values.palladiumAssayH,
      rhodiumAssayH: values.rhodiumAssayH,
      platinumAssayF: values.platinumAssayF,
      palladiumAssayF: values.palladiumAssayF,
      rhodiumAssayF: values.rhodiumAssayF,
      platinumContainedOz: values.platinumContainedOz,
      palladiumContainedOz: values.palladiumContainedOz,
      rhodiumContainedOz: values.rhodiumContainedOz,
      platinumContainedOzH: values.platinumContainedOzH,
      palladiumContainedOzH: values.palladiumContainedOzH,
      rhodiumContainedOzH: values.rhodiumContainedOzH,
      platinumContainedOzF: values.platinumContainedOzF,
      palladiumContainedOzF: values.palladiumContainedOzF,
      rhodiumContainedOzF: values.rhodiumContainedOzF,
      platinumReturnableOz: values.platinumReturnableOz,
      palladiumReturnableOz: values.palladiumReturnableOz,
      rhodiumReturnableOz: values.rhodiumReturnableOz,
      platinumReturnableOzH: values.platinumReturnableOzH,
      palladiumReturnableOzH: values.palladiumReturnableOzH,
      rhodiumReturnableOzH: values.rhodiumReturnableOzH,
      platinumReturnableOzF: values.platinumReturnableOzF,
      palladiumReturnableOzF: values.palladiumReturnableOzF,
      rhodiumReturnableOzF: values.rhodiumReturnableOzF,
      carbonPercentage: values.carbonPercentage,
      carbonPercentageH: values.carbonPercentageH,
      carbonPercentageF: values.carbonPercentageF,
    },
    errors,
  );

  return errors;
}

export { validate };
