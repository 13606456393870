const termStatusFilterActionType = 'TERM_STATUS_ACTION';
function termStatusFilterAction(termStatus) {
  return {
    type: termStatusFilterActionType,
    payload: {
      termStatus,
    },
  };
}

const materialTypeFilterActionType = 'MATERIAL_TYPE_FILTER_ACTION';
function materialTypeFilterAction(materialType) {
  return {
    type: materialTypeFilterActionType,
    payload: {
      materialType,
    },
  };
}

const termFiltersClearActionType = 'TERM_FILTERS_CLEAR_ACTION';
function termFiltersClearAction() {
  return {
    type: termFiltersClearActionType,
  };
}

function termStatusFilter(termStatus) {
  return dispatch => {
    dispatch(termStatusFilterAction(termStatus));
  };
}

function materialTypeFilter(materialType) {
  return dispatch => {
    dispatch(materialTypeFilterAction(materialType));
  };
}

function termFiltersClear() {
  return dispatch => {
    dispatch(termFiltersClearAction());
  };
}

export {
  termFiltersClearActionType,
  termStatusFilterActionType,
  materialTypeFilterActionType,
  materialTypeFilterAction,
  termStatusFilter,
  materialTypeFilter,
  termFiltersClear,
};
