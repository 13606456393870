import { SHARED } from 'shared/helpers/constants/sharedConstants';

const SHIPMENTS = {
  SHIPMENTS: 'Shipments',
  SHIPMENT: 'Shipment',
  STATUS: 'Status',
  GENERAL_DETAILS: 'General details',
  SENT_FROM_COMPANY_LOCATION: 'From location',
  SENT_FROM_COMPANY: 'From company',
  SENT_FROM_COMPANY_COLON: 'From company: ',
  EST_PICKUP_DATE: 'Est. pickup date',
  SHIP_DATE: 'Ship date',
  EST_DELIVERY_DATE: 'Est. delivery date',
  ASSIGNED_LOT: 'Assigned lot',
  ASSIGNED_LOTS: 'Assigned lots',
  ASSIGNED_LOTS_COLON: 'Assigned lots:',
  GROSS_DECLARED: 'Gross (lbs)',
  SHIPMENT_DIRECTION: 'Shipment direction',
  PACKAGES: 'Packages',
  SELECT_ALL_PACKAGES: 'Select all packages',
  ACTIVITY: 'Activity',
  WEIGHT: 'Weight',
  DOCUMENTS: 'Documents',
  CREATED_BY: 'Created by',
  UPDATED_BY: 'Updated by',
  SHIPMENT_BILL_OF_LADING: 'Shipment bill of lading',
  SHIPMENT_PACKING_LIST: 'Shipment packing list',
  SHIPMENT_PACKING_LIST_EXCEL: 'Shipment excel packing list',
  ACTIONS: 'Actions',
  CREATE_A_SHIPMENT: `Create a${SHARED.NON_BREAKING_SPACE}shipment`,
  CREATE_INCOMING_SHIPMENT: 'Create an incoming shipment',
  CREATE_OUTGOING_SHIPMENT: 'Create an outgoing shipment',
  CREATE_SELF_DELIVERY_REQUEST: 'Create a self-delivery',
  UPDATE_OUTGOING_SHIPMENT: 'Update outgoing shipment',
  OUTGOING_SHIPMENTS: 'Outgoing shipments',
  INCOMING_SHIPMENTS: 'Incoming shipments',
  SELF_DELIVERY_REQUESTS: 'Self-delivery requests',
  SELF_DELIVERY_REQUEST_SHORTENED: 'Request',
  RELATED_COMPANY: 'Related company',
  OVERVIEW: 'Overview',
  CHOOSE_TYPE: 'Choose type:',
  SHIPMENT_TYPES: {
    ALL: 'list',
    OUTGOING: 'outgoing',
    INCOMING: 'incoming',
  },
  SWITCH_TO_LIST_VIEW: 'Switch to list view',
  SWITCH_TO_INCOMING_SHIPMENTS_VIEW: 'Switch to incoming shipments list',
  SWITCH_TO_SELF_DELIVERY_REQUEST_VIEW: 'Switch to incoming delivery list',
  EDIT_SHIPMENT: 'Edit shipment',
  EDIT_PACKING_LIST: 'Edit packing list',
  EDIT_INCOMING_SHIPMENT: 'Edit incoming shipment',
  DELETE_SHIPMENT: 'Delete shipment',
  SHIP_AND_PRINT_SHIPMENT: 'Ship and print',
  GENERATE_DOCUMENTS: 'Generate documents',
  GENERATE_REPORT: 'Generate report',
  SHIPMENT_COMPANY: 'Shipment company',
  SHIPMENT_SEAL: 'Shipment seal',
  LOAD_NUMBER: 'Load #',
  UNIT_COUNT_COLON: 'Unit count: ',
  WEIGHT_GROSS_DECLARED: 'Gross declared',
  WEIGHT_GROSS_DECLARED_COLON: 'Weight gross declared: ',
  WEIGHT_TARE_DECLARED: 'Tare declared',
  WEIGHT_NET_DECLARED: 'Net declared',
  EST_PICKUP_DATE_FROM: 'Est. pickup date from',
  EST_PICKUP_DATE_TO: 'Est. pickup date to',
  SHIP_DATE_FROM: 'Ship date from',
  SHIP_DATE_TO: 'Ship date to',
  EST_DELIVERY_DATE_FROM: 'Est. delivery date from',
  EST_DELIVERY_DATE_TO: 'Est. delivery date to',
  STILLWATER_SHIPMENT_ID: 'Stillwater shipment ID',
  MATERIAL_TYPE: 'Material type',
  ENTER_LOTS_INFORMATION: 'Enter lots information',
  LOTS_INFORMATION: 'Lots information',
  LBS: 'lbs',
  // Long dashes - not to mistake with regular dash
  EMPTY_FIELD: '— —',
  FILTERS: {
    SELECT_EST_DELIVERY_DATE: '-- select est delivery date --',
    SELECT_DATE: '-- select date --',
    SELECT_STATUS: '-- select status --',
  },
  SUCCESS: {
    GENERATE_REPORT: 'Shipment report has been generated and is being downloaded.',
  },
  ERROR: {
    CREATE_SHIPMENT_ERROR: 'Shipment has not been created.',
    UPDATE_SHIPMENT_ERROR: 'Shipment has not been updated.',
    DELETE_SHIPMENT_ERROR: 'Shipment has not been deleted.',
    MARK_AS_SENT_ERROR: 'Shipment has not been marked as sent.',
    GENERATE_SHIPMENT_DOCUMENTS_ERROR: 'Shipment documents have not been generated.',
    GENERATE_REPORT: 'Shipment report has not been generated.',
    MUST_HAVE_PACKAGE: 'Must have at least one assigned package.',
  },
  NO_OUTGOING_PACKAGES: 'The shipment cannot be marked as sent if there are no packages assigned.',
  GENERATE_PACKING_LIST_HEADER: 'Download and print',
  PRINT_PACKING_LIST: 'Print packing list',
  EXPORT_TO_EXCEL: 'Export to Excel',
  PRINT_PACKAGE_LABELS: 'Print package labels',
  MARK_SHIPMENT_AS_SENT: 'Mark shipment as sent',
  PURCHASE_ORDER: 'PO #',
  BILL_OF_LADING: 'BOL',
  TRUCKING_COMPANY: 'Trucking company',
  TRAILER: 'Trailer',
  CONTACT_NAME: 'Contact name',
  CONTACT_NUMBER: 'Contact number',
  SENSOR: 'Sensor #',
  MARK_AS_SENT: 'Mark as sent',
  REPRINT_ALL_PACKAGES_LABELS:
    'You need to reprint all package labels because lot assignment has been changed',
  RECEIVED: 'Received',
};

export { SHIPMENTS };
