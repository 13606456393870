export const PERMISSIONS = {
  SPECIAL_PERMISSIONS: 'Special permissions',
  USERS_ASSIGNED: 'User(s) assigned',
  HEDGES: 'hedges',
  LOTS: 'lots',
  PAYMENTS: 'payments',
  PROCESSING: 'processing',
  SHIPMENTS: 'shipments',
  ASSIGN_HEDGE_OUNCES: 'Assign hedge ounces to lots w/out notifications',
  MANUAL_LOT_CHANGE_NOTIFICATIONS: 'Manual lot change notifications',
  DELETE_HEDGE_DELIVERY: 'Delete a hedge delivery (ounce assignment)',
  CHANGE_HEDGE_OUNCES: 'Change hedge ounces w/out notifications',
  CHANGE_HEDGE_PRICES: 'Change hedge prices w/out notifications',
  HEDGE_REQUEST_NOTIFICATION: 'Hedge request notifications',
  UPDATE_SCHEDULED_OR_FUTURE_HEDGE: 'Update scheduled/future hedge',
  TRANSFER_HEDGE_TO_DIFFERENT_LOT: 'Transfer hedge to a different lot',
  GENERATE_OR_APPROVE_PROFIT_LOSS_DOCS: 'Generate/Approve a P&L document',
  REASSIGN_PAYMENT: 'Re-assign payment to a different lot',
  UPDATE_COMPANY_OUTSTANDING_BALANCE: 'Update company outstanding balance',
  MCR_ADVANCE_PAYMENT_NOTIFICATIONS: `'Material count required' advance payment notifications`,
  UPDATE_PACKAGE_WEIGHT: 'Update any package weight (until lot shipped)',
  LABS_USER: 'Lab users',
  CREATE_OUTGOING_SHIPMENT: 'Create an outgoing shipment',
  FINALIZE_LOT: 'Finalize lot w/out documents',
  APPROVE_CLIENT_FACING_UPLOADS: 'Approve client-facing uploads',
  VIEW_LOG: 'View log',
  SPECIAL_PERMISSIONS_LOG: 'Special permissions log',
  ACTION: 'Action',
  DETAILS: 'Details',
  ACTION_BEFORE: 'Before',
  ACTION_AFTER: 'After',
  ACTION_DETAILS: 'Action details',
  MARKET_INDICATOR_NOTIFICATIONS: 'Market indicator notifications',
  CANCEL_HEDGE: 'Cancel an approved hedge',
  TRANSFER_BLANKET_HEDGE_OUNCES: 'Transfer blanket hedge ounces',
};

export const PERMISSIONS_LOGS_MAPPER = {
  hedges: {
    deleteApprovedHedge: ({ hedgeName }) => `Hedge ${hedgeName} deleted`,
    changeHedgeOunces: ({ hedgeName }) => `Hedge ${hedgeName} ounces updated`,
    changeHedgePrices: ({ hedgeName }) => `Hedge ${hedgeName} prices updated`,
    transferHedgeToDifferentLot: ({ hedgeName }) => `Hedge ${hedgeName} transferred`,
    cancelHedge: ({ hedgeName }) => `Hedge ${hedgeName} cancelled`,
    transferBlanketHedgeOunces: ({ hedgeName }) => `Blanket hedge ${hedgeName} ounces transferred`,
  },
  payments: {
    reassignPayment: ({ grgLotNumberOld, grgLotNumberNew }) =>
      `Payment re-assigned from lot ${grgLotNumberOld} to ${grgLotNumberNew}`,
  },
  companies: {
    updateCompanyOutstandingBalance: ({ companyName }) =>
      `Company balance updated for ${companyName}`,
  },
  lots: {
    setFinalizedLot: ({ grgLotNumber }) => `Lot ${grgLotNumber} finalized manually`,
  },
};
