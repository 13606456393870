import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const hedgesChangeTypeActionType = 'HEDGES_CHANGE_TYPE';
function hedgesChangeTypeAction() {
  return { type: hedgesChangeTypeActionType };
}

const hedgesChangeTypeSuccessActionType = `${hedgesChangeTypeActionType}_SUCCESS`;
function hedgesChangeTypeSuccessAction(hedge) {
  return {
    type: hedgesChangeTypeSuccessActionType,
    payload: { hedge },
  };
}

const hedgesChangeTypeFailureActionType = `${hedgesChangeTypeActionType}_FAILURE`;
function hedgesChangeTypeFailureAction() {
  return { type: hedgesChangeTypeFailureActionType };
}

function hedgesChangeType(hedge, hedgeType) {
  return dispatch => {
    dispatch(hedgesChangeTypeAction());

    return axios
      .patch(`${API_HOST_AUTH}/hedges/${hedge._id}/type/${hedgeType}`)
      .then(({ data }) => {
        dispatch(hedgesChangeTypeSuccessAction(data.hedge));

        return data;
      })
      .catch(error => {
        dispatch(hedgesChangeTypeFailureAction());

        return Promise.reject(error);
      });
  };
}

export {
  hedgesChangeType,
  hedgesChangeTypeActionType,
  hedgesChangeTypeSuccessActionType,
  hedgesChangeTypeFailureActionType,
};
