import axios from 'axios';

import { FINAL_INVOICE } from 'shared/helpers/constants/lots/finalInvoiceConstants';
import { messageTypes } from 'shared/helpers/constants/snackbar/snackbarMessageTypes';
import {
  parseGenerateFinalInvoiceData,
  parseGenerateFinalInvoiceMockupData,
} from 'shared/helpers/dataParsers/lot/parseGenerateFinalInvoiceData';

const API_HOST_AUTH = process.env.REACT_APP_API;

const generateFinalInvoiceActionType = 'GENERATE_FINAL_INVOICE';
const generateFinalInvoiceAction = () => ({
  type: generateFinalInvoiceActionType,
});

const generateFinalInvoiceSuccessActionType = `${generateFinalInvoiceActionType}_SUCCESS`;
const generateFinalInvoiceSuccessAction = () => ({
  type: generateFinalInvoiceSuccessActionType,
});

const generateFinalInvoiceFailureActionType = `${generateFinalInvoiceActionType}_FAILURE`;
const generateFinalInvoiceFailureAction = () => ({
  type: generateFinalInvoiceFailureActionType,
});

const generateFinalInvoice = (data, id, removingOuncesDisabled) => dispatch => {
  dispatch(generateFinalInvoiceAction());

  return axios
    .post(
      `${API_HOST_AUTH}/lots/generate/final-invoice`,
      parseGenerateFinalInvoiceData(data, id, removingOuncesDisabled),
    )
    .then(res => {
      dispatch(generateFinalInvoiceSuccessAction());

      return {
        id: res.data.id,
        message: res.data.message,
      };
    })
    .catch(error => {
      dispatch(generateFinalInvoiceFailureAction());

      return Promise.reject({
        message: `${FINAL_INVOICE.ERROR.GENERATE_FINAL_INVOICE} ${error.data.message}`,
      });
    });
};

const generateFinalInvoiceMockup = (data, id) => dispatch => {
  dispatch(generateFinalInvoiceAction());

  return axios
    .post(
      `${API_HOST_AUTH}/lots/generate/final-invoice-mockup`,
      parseGenerateFinalInvoiceMockupData(data, id),
      { responseType: 'arraybuffer' },
    )
    .then(res => {
      dispatch(generateFinalInvoiceSuccessAction());

      return res.data;
    })
    .catch(error => {
      dispatch(generateFinalInvoiceFailureAction());

      const decodedError = JSON.parse(String.fromCharCode.apply(null, new Uint8Array(error.data)));

      return Promise.reject({
        message: `${FINAL_INVOICE.ERROR.GENERATE_FINAL_INVOICE} ${decodedError.message}`,
        messageType: messageTypes.error,
      });
    });
};

export {
  generateFinalInvoiceActionType,
  generateFinalInvoiceAction,
  generateFinalInvoiceSuccessActionType,
  generateFinalInvoiceSuccessAction,
  generateFinalInvoiceFailureActionType,
  generateFinalInvoiceFailureAction,
  generateFinalInvoice,
  generateFinalInvoiceMockup,
};
