import { css } from 'styled-components';

export const FieldDateStyles = css`
  .FieldDate__calendar {
    border: none;
    background-color: transparent;

    & > div:nth-child(4):not(:last-child) {
      border-bottom-right-radius: 0;

      .react-datepicker__month {
        height: 265px;
      }
    }

    .react-datepicker {
      &__triangle {
        display: none;
      }

      &__navigation {
        margin: 10px 5px 0;

        &:active,
        &:focus {
          outline: none;
        }
      }

      &__month-container {
        background-color: var(--shuttleGray);
        border-radius: 5px;
        box-shadow: 0 11px 24px 0 rgba(0, 0, 0, 0.29);

        .react-datepicker__header {
          border-bottom: 1px solid rgba(var(--dustGray), 0.53);
          font-weight: 300;

          .react-datepicker__current-month {
            margin: 5px 0 10px;
            font-weight: 300;
          }

          .react-datepicker__day-names {
            margin-bottom: 5px;
          }

          .react-datepicker__year-dropdown,
          .react-datepicker__month-dropdown {
            border: 1px solid rgba(var(--dustGray), 0.3);
          }

          .react-datepicker__year-option:hover,
          .react-datepicker__month-option:hover {
            background-color: var(--shuttleGray);
          }
        }

        .react-datepicker__month {
          $height: 44px;
          margin: 0;

          .react-datepicker__week {
            display: flex;
            align-items: center;
            height: $height;

            &:not(:last-child) {
              border-bottom: 1px solid rgba(var(--dustGray), 0.2);
            }

            .react-datepicker__day {
              height: 100%;
              flex-grow: 1;
              margin: 0;
              line-height: $height;

              &:hover {
                background-color: var(--nevada);
                border-radius: 0;
              }

              &--disabled {
                color: var(--dustGray);
              }

              &--today {
                border-radius: 0;
                background-color: transparent;
              }

              &--selected {
                border-radius: 0;
                background-color: var(--slateGrayGRG);
              }

              &--keyboard-selected {
                background-color: transparent;
                outline: 1px solid var(--slateGrayGRG);
                color: var(--alabaster);
              }
            }
          }
        }
      }
    }
  }
`;
