const marketHoursModal = ({ marketHours, marketDays, timezone }) => ({
  message: 'Settlement of metal pool',
  messageText: `Market hours are ${marketHours} ${timezone}, ${marketDays}. If you submit a settlement request
    outside of market hours, it will be processed at the start of the next business day.
   Submit settlement request?`,
  acceptButton: 'Submit',
  cancelButton: 'Cancel',
});

const marketHolidayModal = isSettlementForLot => ({
  message: isSettlementForLot ? 'Settlement for lots' : 'Settlement of metal pool',
  messageText: `Today is a market holiday. If you submit your settlement request today, it will be processed
                at the start of the next business day. Submit settlement request?`,
  acceptButton: 'Submit',
  cancelButton: 'Cancel',
});

export { marketHoursModal, marketHolidayModal };
