import {
  getSelfDeliveryRequestsListActionType,
  getSelfDeliveryRequestsListFailureActionType,
  getSelfDeliveryRequestsListSuccessActionType,
  clearSelfDeliveryRequestsListActionType,
} from 'actions/SelfDeliveryRequests/selfDeliveryRequestsList';

const INITIAL_STATE = {
  isPending: false,
  selfDeliveryRequests: [],
  limit: 50,
  page: 1,
  total: 0,
  pages: null,
};

const SelfDeliveryRequestsListReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getSelfDeliveryRequestsListActionType:
      return {
        ...state,
        isPending: true,
      };
    case getSelfDeliveryRequestsListSuccessActionType:
      return {
        ...state,
        isPending: false,
        selfDeliveryRequests: action.payload.selfDeliveryRequests,
        pages: action.payload.pages,
        limit: action.payload.limit,
        page: action.payload.page,
        total: action.payload.total,
      };
    case getSelfDeliveryRequestsListFailureActionType:
      return {
        ...state,
        isPending: false,
        selfDeliveryRequests: [],
        pages: null,
        total: 0,
      };
    case clearSelfDeliveryRequestsListActionType:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};

export { SelfDeliveryRequestsListReducer };
