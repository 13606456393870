import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const addGradingReceiptType = 'ADD_GRADING_RECEIPT';
const addGradingReceipt = gradingReceipt => ({
  type: addGradingReceiptType,
  payload: { gradingReceipt },
});

const clearGradingReceiptType = 'CLEAR_GRADING_RECEIPT';
const clearGradingReceipt = () => ({
  type: clearGradingReceiptType,
});

const uploadGradingReceiptType = 'UPLOAD_GRADING_RECEIPT';
const uploadGradingReceiptAction = () => ({
  type: uploadGradingReceiptType,
});

const uploadGradingReceiptSuccessType = `${uploadGradingReceiptType}_SUCCESS`;
const uploadGradingReceiptSuccessAction = () => ({
  type: uploadGradingReceiptSuccessType,
});

const uploadGradingReceiptFailureType = `${uploadGradingReceiptType}_FAILURE`;
const uploadGradingReceiptFailureAction = () => ({
  type: uploadGradingReceiptFailureType,
});

const uploadGradingReceipt =
  (lotId, { gradingReceipt }) =>
  dispatch => {
    const data = new FormData();

    data.append('gradingReceipt', gradingReceipt);
    dispatch(uploadGradingReceiptAction());

    return axios
      .patch(`${API_HOST_AUTH}/lots/upload-grading-receipt/${lotId}`, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then(res => {
        dispatch(uploadGradingReceiptSuccessAction());

        return res.data;
      })
      .catch(err => {
        dispatch(uploadGradingReceiptFailureAction());

        return Promise.reject(err.data);
      });
  };

export {
  addGradingReceiptType,
  clearGradingReceiptType,
  uploadGradingReceiptType,
  uploadGradingReceiptSuccessType,
  uploadGradingReceiptFailureType,
  addGradingReceipt,
  clearGradingReceipt,
  uploadGradingReceipt,
};
