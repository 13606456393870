import axios from 'axios';

import { INSPECTIONS } from 'shared/helpers/constants/inspections/inspectionConstants';
import { messageTypes } from 'shared/helpers/constants/snackbar/snackbarMessageTypes';
import { parseLotInspectionData } from 'shared/helpers/dataParsers/lot/parseLotInspectionData';

const API_HOST_AUTH = process.env.REACT_APP_API;

const lotInspectionActionType = 'LOT_INSPECTION';
const lotInspectionAction = () => ({
  type: lotInspectionActionType,
});

const lotInspectionSuccessActionType = `${lotInspectionActionType}_SUCCESS`;
const lotInspectionSuccessAction = () => ({
  type: lotInspectionSuccessActionType,
});

const lotInspectionFailureActionType = `${lotInspectionActionType}_FAILURE`;
const lotInspectionFailureAction = () => ({
  type: lotInspectionFailureActionType,
});

const lotInspectionFactory = (url, lotId, errorMessage, data) => dispatch => {
  dispatch(lotInspectionAction());

  return axios
    .post(`${API_HOST_AUTH}/lots/${url}/${lotId}`, data)
    .then(res => {
      dispatch(lotInspectionSuccessAction());

      return {
        message: res.data.message,
        messageType: messageTypes.success,
      };
    })
    .catch(error => {
      dispatch(lotInspectionFailureAction());

      return Promise.reject({
        message: `${errorMessage} ${error.data.message}`,
        messageType: messageTypes.error,
      });
    });
};

const requestLotInspection = inspectionObj =>
  lotInspectionFactory(
    'request-inspection',
    inspectionObj.relatedLot.value,
    INSPECTIONS.ERROR.REQUEST_LOT_INSPECTION_ERROR,
  );

const scheduleLotInspection = inspectionObj =>
  lotInspectionFactory(
    'schedule-inspection',
    inspectionObj.relatedLot.value,
    INSPECTIONS.ERROR.SCHEDULE_LOT_INSPECTION_ERROR,
    JSON.stringify(parseLotInspectionData(inspectionObj)),
  );

export {
  lotInspectionActionType,
  lotInspectionAction,
  lotInspectionSuccessActionType,
  lotInspectionSuccessAction,
  lotInspectionFailureActionType,
  lotInspectionFailureAction,
  lotInspectionFactory,
  requestLotInspection,
  scheduleLotInspection,
};
