const USER_TYPES = {
  OWNER: {
    LABEL: 'Owner',
    VALUE: 'owner',
  },
  SUPPORT: {
    LABEL: 'Support',
    VALUE: 'support',
  },
  WAREHOUSE: {
    LABEL: 'Warehouse',
    VALUE: 'warehouse',
  },
  CUSTOMER: {
    LABEL: 'Customer',
    VALUE: 'customer',
  },
  GRADING: {
    LABEL: 'Grading',
    VALUE: 'grading',
  },
  EXTERNAL: {
    LABEL: 'External',
    VALUE: 'external',
  },
};

export { USER_TYPES };
