import { shipmentDirections } from 'shared/helpers/constants/shipments/shipmentDirections';

function isOutgoingShipment(value) {
  return value === shipmentDirections.outgoing;
}

function isIncomingShipment(value) {
  return value === shipmentDirections.incoming;
}

export { isOutgoingShipment, isIncomingShipment };
