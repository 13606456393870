import PropTypes from 'prop-types';
import React, { Fragment, useEffect, memo, useState, useContext } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import { openModal } from 'actions/shared/modal';

import { wizardContext } from 'shared/components/Wizard/Wizard';
import { deletePackageModal } from 'shared/helpers/constants/modalConstants';
import { PACKAGES } from 'shared/helpers/constants/packages/packageConstants';
import { packageStatuses } from 'shared/helpers/constants/packages/packageType';
import {
  extendMachineNameMixing,
  extendMachineNameProcessing,
} from 'shared/helpers/dataParsers/lot/lotOverviewData';

import { IncomingPackageListTemplate } from 'pages/Packages/components/PackagesManagement/components/MultiPackagesCreate/components/IncomingPackagesFormList/components/IncomingPackageListTemplate/IncomingPackageListTemplate';
import { configNames } from 'pages/Packages/components/PackagesManagement/components/PackageForm/components/PackageForm/fieldsConfig';

import { AddPackageAction } from './components/AddPackageAction/AddPackageAction';

const areEqual = (prevProps, nextProps) => prevProps.fields === nextProps.fields;

const IncomingPackagesFormListBase = props => {
  const { fields, data, pkgQuantity, config, forLot, change, packagesData } = props;

  const dispatch = useDispatch();

  const [focusedRow, setFocusedRow] = useState(0);
  const [focusedCol, setFocusedCol] = useState(null);
  const wizard = useContext(wizardContext);
  const isPackingList = wizard.config === configNames.packingList;
  const isEditMultiplePackages = wizard.config === configNames.editMultiplePackages;
  const isWizardEditMode = wizard.editMode;
  const editedPackage = useSelector(
    ({ packagesOverviewList }) => packagesOverviewList.temporaryData.editedPackage,
  );
  const {
    data: { defaultMaterialType },
  } = wizard;

  useEffect(() => {
    const { oneOrMultiLots, packagesQuantity, oldValues, ...initialData } = data;
    const weightFields = [
      'unitCount',
      'weightGrossDeclared',
      'weightTareDeclared',
      'weightGrossActual',
      'weightTareActual',
    ];

    if (wizard.editMode) {
      return;
    }

    if (config && config !== configNames.incoming) {
      forLot &&
        forLot.processVia &&
        (initialData.processVia = extendMachineNameProcessing(forLot.processVia));
      forLot && forLot.mixVia && (initialData.mixVia = extendMachineNameMixing(forLot.mixVia));
    }

    if (fields.length <= pkgQuantity) {
      batch(() =>
        Array.from({ length: pkgQuantity }).forEach((field, i) => {
          const currentField = fields.get(i);

          if (!currentField) {
            return fields.push({ ...initialData });
          }

          let changed = Object.entries(oldValues || {}).some(([key, value]) => {
            if (!currentField[key]) {
              // eslint-disable-next-line
              return;
            }
            if (currentField[key].value) {
              return currentField[key].value !== value.value;
            }
            return currentField[key] !== value;
          });

          changed = changed || weightFields.some(w => Object.keys(currentField).includes(w));

          if (!changed) {
            if (isPackingList) {
              initialData.packageNumber = i + 1;
            }

            fields.splice(i, 1, { ...initialData });
          }
        }),
      );
    } else {
      batch(() => fields.splice(pkgQuantity, fields.length - pkgQuantity));
    }
  }, []);

  const getNewPackage = index => {
    const { packagesValues } = props;
    return packagesValues && packagesValues.packages[index];
  };

  const getNewPackageName = index => {
    const pkg = getNewPackage(index) || {};
    const { relatedLot, relatedCompany } = pkg;
    if (relatedLot) return relatedLot.grgLotNumber;
    if (relatedCompany) return relatedCompany.lotPrefix;
    return '-';
  };

  const removePkg = index => () =>
    dispatch(
      openModal(deletePackageModal(), () => {
        change('defaultPackagesValues.packagesQuantity', +data.packagesQuantity - 1);
        fields.get(index)._id
          ? change(`packages[${index}].status`, packageStatuses.deleted)
          : fields.remove(index);
      }),
    );

  const handleSelectPackage = pkgId => {
    return editedPackage ? pkgId === editedPackage?.id : false;
  };

  const addPkg = () => {
    const { oneOrMultiLots, oldValues, packagesQuantity, ...initialData } = data;

    batch(() => {
      change('defaultPackagesValues.packagesQuantity', +data.packagesQuantity + 1);
      fields.push({ ...initialData });
    });
  };

  const populateWeightTareDeclared =
    index =>
    ({ target: { value } }) =>
      (!isPackingList || !index) &&
      fields.get(index).weightTareDeclared &&
      fields.forEach(
        (field, fieldIndex) =>
          !fields.get(fieldIndex).weightTareDeclared &&
          change(`${field}.weightTareDeclared`, value),
      );

  const isPlaceholder = index => props.packagesData?.[index].status === packageStatuses.deleted;
  const shouldDisabledAddPkgBtn = () =>
    !isEditMultiplePackages && !isWizardEditMode && fields.length >= 99;

  return (
    <>
      {props.fields.map((pkg, index) => (
        <Fragment key={pkg}>
          <IncomingPackageListTemplate
            {...props}
            header={`${PACKAGES.PACKAGE} ${getNewPackageName(index)}`}
            index={index}
            value={getNewPackage(index) || {}}
            onRemove={removePkg(index)}
            oneOrMultiLots={props.data.oneOrMultiLots.value}
            packagesQuantity={props.data.packagesQuantity}
            package={pkg}
            packageCountIncoming={data.packageCountIncoming || wizard.packageCountIncoming}
            shipmentPackagesLength={wizard.shipmentPackagesLength}
            edited={handleSelectPackage(packagesData[index]._id)}
            touch={props.touch}
            focusedRow={focusedRow}
            setFocusedRow={setFocusedRow}
            focusedCol={focusedCol}
            setFocusedCol={setFocusedCol}
            populateWeightTareDeclared={populateWeightTareDeclared}
            isPlaceholder={isPlaceholder(index)}
            packagesData={props.packagesData}
            isPackingList={isPackingList}
            isWizardEditMode={isWizardEditMode}
            isEditMultiplePackages={isEditMultiplePackages}
            defaultMaterialType={defaultMaterialType}
          />
        </Fragment>
      ))}
      {(isPackingList || isEditMultiplePackages) && (
        <AddPackageAction disabled={shouldDisabledAddPkgBtn()} onClick={addPkg} />
      )}
    </>
  );
};

IncomingPackagesFormListBase.propTypes = {
  forLot: PropTypes.object,
  assignedLots: PropTypes.object,
  auth: PropTypes.object,
  change: PropTypes.func,
  focus: PropTypes.func,
  config: PropTypes.string,
  data: PropTypes.object,
  location: PropTypes.shape({
    state: PropTypes.object,
    search: PropTypes.string,
    pathname: PropTypes.string,
    key: PropTypes.string,
    hash: PropTypes.string,
  }),
  meta: PropTypes.object,
  packagesData: PropTypes.array,
};

const IncomingPackagesFormList = memo(
  props => <IncomingPackagesFormListBase {...props} />,
  areEqual,
);

export { IncomingPackagesFormList, IncomingPackagesFormListBase };
