import { goBackOrTo, history as historyUtility } from './history';

const relativeNavigator =
  (navigation, fallbackRoute = '/') =>
  (history = historyUtility) =>
  (pathName, data = {}) => {
    const fallback = { from: /.*/, to: fallbackRoute };

    return (fallbackRoute ? [...navigation, fallback] : navigation).some(
      ({ from, to, tryHistory }) => {
        const isActive = from.test(pathName);

        if (isActive) {
          const path = typeof to === 'function' ? to({ ...data, pathName }) : to;

          tryHistory ? goBackOrTo(path) : history.push(path);
        }

        return isActive;
      },
    );
  };
export { relativeNavigator };
