import axios from 'axios';

import { messageTypes } from 'shared/helpers/constants/snackbar/snackbarMessageTypes';

const API_HOST_AUTH = process.env.REACT_APP_API;

const deletePackageActionType = 'DELETE_PACKAGE';
function deletePackageAction() {
  return {
    type: deletePackageActionType,
  };
}

const deletePackageSuccessActionType = `${deletePackageActionType}_SUCCESS`;
function deletePackageSuccessAction(pkg) {
  return {
    type: deletePackageSuccessActionType,
    payload: {
      pkg,
    },
  };
}

const deletePackageFailureActionType = `${deletePackageActionType}_FAILURE`;
function deletePackageFailureAction() {
  return {
    type: deletePackageFailureActionType,
  };
}

const deletePackageFactory = url => id => dispatch => {
  dispatch(deletePackageAction());

  return axios
    .delete(`${API_HOST_AUTH}/${url}/${id}`)
    .then(response => {
      dispatch(deletePackageSuccessAction(response.data));
      return {
        message: response.data.message,
        messageType: messageTypes.success,
      };
    })
    .catch(error => {
      dispatch(deletePackageFailureAction());
      return {
        message: `Package has not been deleted. ${error.data.message}`,
        messageType: messageTypes.error,
      };
    });
};

const deletePackage = deletePackageFactory('packages');
const deleteIncomingPackage = deletePackageFactory('packages/incoming');
const deleteProcessingPackage = deletePackageFactory('packages/processing');

export {
  deletePackageActionType,
  deletePackageAction,
  deletePackageSuccessActionType,
  deletePackageSuccessAction,
  deletePackageFailureActionType,
  deletePackageFailureAction,
  deletePackage,
  deleteIncomingPackage,
  deleteProcessingPackage,
  deletePackageFactory,
};
