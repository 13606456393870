import { DetailsActivityField } from 'shared/components/Details/DetailsActivityField';
import { DetailsDownloadField } from 'shared/components/Details/DetailsDownloadFieldRedesign';
import { DetailsNotes } from 'shared/components/Details/DetailsNote';
import { COMPANY } from 'shared/helpers/constants/companies/companyConstants';
import { DILIGENCE_VALID_FILE_EXTENSIONS } from 'shared/helpers/constants/companies/diligenceValidFileExtensions';
import { NOTE } from 'shared/helpers/constants/noteConstants';

const diligenceConfig = ({ files }, match, downloadAttachment) => {
  const { locationVerification, dueDiligenceQuestionnaire } = files;

  return [
    {
      header: COMPANY.LOCATION_VERIFICATION,
      config: {
        noMargin: true,
      },
      template: [
        'locationVerification locationVerification locationVerificationCreatedBy locationVerificationCreatedBy',
        'locationVerificationNotes locationVerificationNotes locationVerificationNotes locationVerificationNotes',
      ],
      fields: [
        {
          id: 'locationVerification',
          label: COMPANY.LOCATION_VERIFICATION,
          value: locationVerification,
          onClick: index => downloadAttachment(locationVerification[index].relatedFile.path),
          FieldType: DetailsDownloadField,
          highlightLast: true,
        },
        {
          id: 'locationVerificationCreatedBy',
          label: COMPANY.UPLOADED_BY,
          user: locationVerification[locationVerification.length - 1]?.createdBy,
          date: locationVerification[locationVerification.length - 1]?.createdAt,
          FieldType: DetailsActivityField,
        },
        {
          id: 'locationVerificationNotes',
          label: NOTE.NOTE,
          relatedState: {
            type: NOTE.NOTES_TYPE.LOCATION_NOTE,
            title: COMPANY.LOCATION_VERIFICATION_NOTES_TITLE,
            validExtensions: DILIGENCE_VALID_FILE_EXTENSIONS,
          },
          relatedType: NOTE.NOTES_TYPE.DILIGENCE,
          FieldType: DetailsNotes,
          match,
        },
      ],
    },
    {
      header: COMPANY.DUE_DILIGENCE_QUESTIONNAIRE,
      template: [
        'dueDiligenceQuestionnaire dueDiligenceQuestionnaire dueDiligenceQuestionnaireCreatedBy dueDiligenceQuestionnaireCreatedBy',
        'dueDiligenceQuestionnaireNotes dueDiligenceQuestionnaireNotes dueDiligenceQuestionnaireNotes dueDiligenceQuestionnaireNotes',
      ],
      config: {
        noMargin: true,
      },
      fields: [
        {
          label: COMPANY.DUE_DILIGENCE_QUESTIONNAIRE,
          value: dueDiligenceQuestionnaire,
          id: 'dueDiligenceQuestionnaire',
          onClick: index => downloadAttachment(dueDiligenceQuestionnaire[index].relatedFile.path),
          FieldType: DetailsDownloadField,
          highlightLast: true,
        },
        {
          label: COMPANY.UPLOADED_BY,
          user: dueDiligenceQuestionnaire[dueDiligenceQuestionnaire.length - 1]?.createdBy,
          date: dueDiligenceQuestionnaire[dueDiligenceQuestionnaire.length - 1]?.createdAt,
          id: 'dueDiligenceQuestionnaireCreatedBy',
          FieldType: DetailsActivityField,
        },
        {
          id: 'dueDiligenceQuestionnaireNotes',
          label: NOTE.NOTE,
          relatedState: {
            type: NOTE.NOTES_TYPE.QUESTIONNAIRE_NOTE,
            title: COMPANY.DUE_DILIGENCE_QUESTIONNAIRE_NOTES_TITLE,
            validExtensions: DILIGENCE_VALID_FILE_EXTENSIONS,
          },
          relatedType: NOTE.NOTES_TYPE.DILIGENCE,
          FieldType: DetailsNotes,
          match,
        },
      ],
    },
    {
      template: ['verificationNotes verificationNotes verificationNotes verificationNotes'],
      fields: [
        {
          id: 'verificationNotes',
          label: COMPANY.VERIFICATION_NOTES,
          relatedState: {
            title: COMPANY.VERIFICATION_NOTES_TITLE,
            validExtensions: DILIGENCE_VALID_FILE_EXTENSIONS,
          },
          relatedType: NOTE.NOTES_TYPE.DILIGENCE,
          FieldType: DetailsNotes,
          match,
        },
      ],
    },
  ];
};
export { diligenceConfig };
