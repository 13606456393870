import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getAvailableLotsActionType = 'GET_AVAILABLE_LOTS';
const getAvailableLotsAction = () => ({
  type: getAvailableLotsActionType,
});

const getAvailableLotsSuccessActionType = `${getAvailableLotsActionType}_SUCCESS`;
const getAvailableLotsSuccessAction = lots => ({
  type: getAvailableLotsSuccessActionType,
  payload: {
    lots,
  },
});

const getAvailableLotsFailureActionType = `${getAvailableLotsActionType}_FAILURE`;
const getAvailableLotsFailureAction = () => ({
  type: getAvailableLotsFailureActionType,
});

const getAvailableLots = () => dispatch => {
  dispatch(getAvailableLotsAction());

  return axios
    .get(`${API_HOST_AUTH}/deliveries/lots`)
    .then(res => {
      dispatch(getAvailableLotsSuccessAction(res.data));

      return res.data;
    })
    .catch(err => {
      dispatch(getAvailableLotsFailureAction());

      return Promise.reject(err.data);
    });
};

export {
  getAvailableLotsActionType,
  getAvailableLotsSuccessActionType,
  getAvailableLotsFailureActionType,
  getAvailableLots,
};
