import React from 'react';
import styled from 'styled-components';

import { DatepickerDropdown } from 'shared/components/DatepickerDropdown/DatepickerDropdown';
import { FiltersDropdown } from 'shared/components/FiltersDropdown/FiltersDropdown';
import { LocationsDropdown } from 'shared/components/LocationsDropdown/LocationsDropdown';
import { JOURNAL } from 'shared/helpers/constants/poolAccounts/journalConstants';
import { allJournalMetals } from 'shared/helpers/constants/poolAccounts/journalMetals';
import { allJournalTypes } from 'shared/helpers/constants/poolAccounts/journalTypes';
import { media } from 'shared/helpers/styling/styling';

import dateAdapter from 'utils/date/dateAdapter';
import { MomentAdapter } from 'utils/date/momentAdapter';

const JournalFiltersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${media.tablet`
    justify-content: center;
  `}

  ${media.largeTablet`
    justify-content: center;
  `}

  ${media.phone`
    flex-direction: column;
    align-items: center;
  `}
`;

const FilterItem = styled.div`
  width: 540px;

  ${media.phone`
     width: 250px;
  `}
`;

const JournalFilters = ({ filters, ...props }) => {
  const { type, metal, relatedCompany, createdAtFrom, createdAtTo } = filters;

  const filterFunction = name => value => {
    props.onFiltersChanged({
      ...filters,
      [name]: value instanceof MomentAdapter ? value.valueOf() : value,
    });
  };

  const renderDateFilter = (name, label, minDate, maxDate) => (
    <FilterItem>
      <DatepickerDropdown
        label={label}
        selected={filters[name] && dateAdapter(filters[name])}
        filterFunction={filterFunction(name)}
        minDate={dateAdapter(minDate || 0)}
        maxDate={dateAdapter(maxDate)}
      />
    </FilterItem>
  );

  return (
    <JournalFiltersContainer>
      <FilterItem>
        <LocationsDropdown
          label={JOURNAL.RELATED_COMPANY}
          options={props.companies.data}
          selected={relatedCompany || ''}
          filterFunction={() => {}}
          filterOptionsSelected={filterFunction('relatedCompany')}
        />
      </FilterItem>
      <FilterItem>
        <FiltersDropdown
          label={JOURNAL.TYPE}
          options={allJournalTypes}
          selected={type || ''}
          filterFunction={filterFunction('type')}
        />
      </FilterItem>
      <FilterItem>
        <FiltersDropdown
          label={JOURNAL.METAL}
          options={allJournalMetals}
          selected={metal || ''}
          filterFunction={filterFunction('metal')}
        />
      </FilterItem>
      {renderDateFilter('createdAtFrom', JOURNAL.CREATED_AT_FROM, 0, createdAtTo || Infinity)}
      {renderDateFilter('createdAtTo', JOURNAL.CREATED_AT_TO, createdAtFrom, Infinity)}
    </JournalFiltersContainer>
  );
};

export { JournalFilters };
