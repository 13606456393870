import { UPDATE_DEFAULT_PPMS } from 'shared/helpers/constants/hedges/updateDefaultPPMsConstants';
import { safeParseFloat } from 'shared/helpers/parsers/number';
import { replaceEmpty } from 'shared/helpers/parsers/text';
import { commaValidator } from 'shared/helpers/validations/commaValidator';
import { ONE_DECIMAL_REGEX } from 'shared/helpers/validations/regularExpressions';

function validate(defaultPPMs, { fieldsRequired = true }) {
  const errors = {};
  const {
    monoPlatinumAssay,
    monoPalladiumAssay,
    monoRhodiumAssay,
    foilCannedPlatinumAssay,
    foilCannedPalladiumAssay,
    foilCannedRhodiumAssay,
    sensorsPlatinumAssay,
    sensorsPalladiumAssay,
    sensorsRhodiumAssay,
  } = defaultPPMs;

  if (fieldsRequired || (!fieldsRequired && monoPlatinumAssay)) {
    if (fieldsRequired && !replaceEmpty(monoPlatinumAssay, '').length) {
      errors.monoPlatinumAssay = UPDATE_DEFAULT_PPMS.MONO_PLATINUM_ASSAY_EMPTY;
    } else if (
      !ONE_DECIMAL_REGEX.test(monoPlatinumAssay) ||
      (monoPlatinumAssay && monoPlatinumAssay.length > 10)
    ) {
      errors.monoPlatinumAssay = UPDATE_DEFAULT_PPMS.MONO_PLATINUM_ASSAY_INVALID;
    } else if (safeParseFloat(monoPlatinumAssay) < 0) {
      errors.monoPlatinumAssay = UPDATE_DEFAULT_PPMS.MONO_PLATINUM_ASSAY_NEGATIVE;
    }
  }

  if (fieldsRequired || (!fieldsRequired && monoPalladiumAssay)) {
    if (fieldsRequired && !replaceEmpty(monoPalladiumAssay, '').length) {
      errors.monoPalladiumAssay = UPDATE_DEFAULT_PPMS.MONO_PALLADIUM_ASSAY_EMPTY;
    } else if (
      !ONE_DECIMAL_REGEX.test(monoPalladiumAssay) ||
      (monoPalladiumAssay && monoPalladiumAssay.length > 10)
    ) {
      errors.monoPalladiumAssay = UPDATE_DEFAULT_PPMS.MONO_PALLADIUM_ASSAY_INVALID;
    } else if (safeParseFloat(monoPalladiumAssay) < 0) {
      errors.monoPalladiumAssay = UPDATE_DEFAULT_PPMS.MONO_PALLADIUM_ASSAY_NEGATIVE;
    }
  }

  if (fieldsRequired || (!fieldsRequired && monoRhodiumAssay)) {
    if (fieldsRequired && !replaceEmpty(monoRhodiumAssay, '').length) {
      errors.monoRhodiumAssay = UPDATE_DEFAULT_PPMS.MONO_RHODIUM_ASSAY_EMPTY;
    } else if (
      !ONE_DECIMAL_REGEX.test(monoRhodiumAssay) ||
      (monoRhodiumAssay && monoRhodiumAssay.length > 10)
    ) {
      errors.monoRhodiumAssay = UPDATE_DEFAULT_PPMS.MONO_RHODIUM_ASSAY_INVALID;
    } else if (safeParseFloat(monoRhodiumAssay) < 0) {
      errors.monoRhodiumAssay = UPDATE_DEFAULT_PPMS.MONO_RHODIUM_ASSAY_NEGATIVE;
    }
  }

  if (fieldsRequired || (!fieldsRequired && foilCannedPlatinumAssay)) {
    if (fieldsRequired && !replaceEmpty(foilCannedPlatinumAssay, '').length) {
      errors.foilCannedPlatinumAssay = UPDATE_DEFAULT_PPMS.FOIL_PLATINUM_ASSAY_EMPTY;
    } else if (
      !ONE_DECIMAL_REGEX.test(foilCannedPlatinumAssay) ||
      (foilCannedPlatinumAssay && foilCannedPlatinumAssay.length > 10)
    ) {
      errors.foilCannedPlatinumAssay = UPDATE_DEFAULT_PPMS.FOIL_PLATINUM_ASSAY_INVALID;
    } else if (safeParseFloat(foilCannedPlatinumAssay) < 0) {
      errors.foilCannedPlatinumAssay = UPDATE_DEFAULT_PPMS.FOIL_PLATINUM_ASSAY_NEGATIVE;
    }
  }

  if (fieldsRequired || (!fieldsRequired && foilCannedPalladiumAssay)) {
    if (fieldsRequired && !replaceEmpty(foilCannedPalladiumAssay, '').length) {
      errors.foilCannedPalladiumAssay = UPDATE_DEFAULT_PPMS.FOIL_PALLADIUM_ASSAY_EMPTY;
    } else if (
      !ONE_DECIMAL_REGEX.test(foilCannedPalladiumAssay) ||
      (foilCannedPalladiumAssay && foilCannedPalladiumAssay.length > 10)
    ) {
      errors.foilCannedPalladiumAssay = UPDATE_DEFAULT_PPMS.FOIL_PALLADIUM_ASSAY_INVALID;
    } else if (safeParseFloat(foilCannedPalladiumAssay) < 0) {
      errors.foilCannedPalladiumAssay = UPDATE_DEFAULT_PPMS.FOIL_PALLADIUM_ASSAY_NEGATIVE;
    }
  }

  if (fieldsRequired || (!fieldsRequired && foilCannedRhodiumAssay)) {
    if (fieldsRequired && !replaceEmpty(foilCannedRhodiumAssay, '').length) {
      errors.foilCannedRhodiumAssay = UPDATE_DEFAULT_PPMS.FOIL_RHODIUM_ASSAY_EMPTY;
    } else if (
      !ONE_DECIMAL_REGEX.test(foilCannedRhodiumAssay) ||
      (foilCannedRhodiumAssay && foilCannedRhodiumAssay.length > 10)
    ) {
      errors.foilCannedRhodiumAssay = UPDATE_DEFAULT_PPMS.FOIL_RHODIUM_ASSAY_INVALID;
    } else if (safeParseFloat(foilCannedRhodiumAssay) < 0) {
      errors.foilCannedRhodiumAssay = UPDATE_DEFAULT_PPMS.FOIL_RHODIUM_ASSAY_NEGATIVE;
    }
  }

  if (fieldsRequired || (!fieldsRequired && sensorsPlatinumAssay)) {
    if (fieldsRequired && !replaceEmpty(sensorsPlatinumAssay, '').length) {
      errors.sensorsPlatinumAssay = UPDATE_DEFAULT_PPMS.SENSORS_PLATINUM_ASSAY_EMPTY;
    } else if (
      !ONE_DECIMAL_REGEX.test(sensorsPlatinumAssay) ||
      (sensorsPlatinumAssay && sensorsPlatinumAssay.length > 10)
    ) {
      errors.sensorsPlatinumAssay = UPDATE_DEFAULT_PPMS.SENSORS_PLATINUM_ASSAY_INVALID;
    } else if (safeParseFloat(sensorsPlatinumAssay) < 0) {
      errors.sensorsPlatinumAssay = UPDATE_DEFAULT_PPMS.SENSORS_PLATINUM_ASSAY_NEGATIVE;
    }
  }

  if (fieldsRequired || (!fieldsRequired && sensorsPalladiumAssay)) {
    if (fieldsRequired && !replaceEmpty(sensorsPalladiumAssay, '').length) {
      errors.sensorsPalladiumAssay = UPDATE_DEFAULT_PPMS.SENSORS_PALLADIUM_ASSAY_EMPTY;
    } else if (
      !ONE_DECIMAL_REGEX.test(sensorsPalladiumAssay) ||
      (sensorsPalladiumAssay && sensorsPalladiumAssay.length > 10)
    ) {
      errors.sensorsPalladiumAssay = UPDATE_DEFAULT_PPMS.SENSORS_PALLADIUM_ASSAY_INVALID;
    } else if (safeParseFloat(sensorsPalladiumAssay) < 0) {
      errors.sensorsPalladiumAssay = UPDATE_DEFAULT_PPMS.SENSORS_PALLADIUM_ASSAY_NEGATIVE;
    }
  }

  if (fieldsRequired || (!fieldsRequired && sensorsRhodiumAssay)) {
    if (fieldsRequired && !replaceEmpty(sensorsRhodiumAssay, '').length) {
      errors.sensorsRhodiumAssay = UPDATE_DEFAULT_PPMS.SENSORS_RHODIUM_ASSAY_EMPTY;
    } else if (
      !ONE_DECIMAL_REGEX.test(sensorsRhodiumAssay) ||
      (sensorsRhodiumAssay && sensorsRhodiumAssay.length > 10)
    ) {
      errors.sensorsRhodiumAssay = UPDATE_DEFAULT_PPMS.SENSORS_RHODIUM_ASSAY_INVALID;
    } else if (safeParseFloat(sensorsRhodiumAssay) < 0) {
      errors.sensorsRhodiumAssay = UPDATE_DEFAULT_PPMS.SENSORS_RHODIUM_ASSAY_NEGATIVE;
    }
  }

  if (!fieldsRequired && (monoPlatinumAssay || monoPalladiumAssay || monoRhodiumAssay)) {
    if (!monoPlatinumAssay) {
      errors.monoPlatinumAssay = UPDATE_DEFAULT_PPMS.MONO_PLATINUM_ASSAY_EMPTY;
    }
    if (!monoPalladiumAssay) {
      errors.monoPalladiumAssay = UPDATE_DEFAULT_PPMS.MONO_PALLADIUM_ASSAY_EMPTY;
    }
    if (!monoRhodiumAssay) {
      errors.monoRhodiumAssay = UPDATE_DEFAULT_PPMS.MONO_RHODIUM_ASSAY_EMPTY;
    }
  }

  if (
    !fieldsRequired &&
    (foilCannedPlatinumAssay || foilCannedPalladiumAssay || foilCannedRhodiumAssay)
  ) {
    if (!foilCannedPlatinumAssay) {
      errors.foilCannedPlatinumAssay = UPDATE_DEFAULT_PPMS.FOIL_PLATINUM_ASSAY_EMPTY;
    }
    if (!foilCannedPalladiumAssay) {
      errors.foilCannedPalladiumAssay = UPDATE_DEFAULT_PPMS.FOIL_PALLADIUM_ASSAY_EMPTY;
    }
    if (!foilCannedRhodiumAssay) {
      errors.foilCannedRhodiumAssay = UPDATE_DEFAULT_PPMS.FOIL_RHODIUM_ASSAY_EMPTY;
    }
  }

  if (!fieldsRequired && (sensorsPlatinumAssay || sensorsPalladiumAssay || sensorsRhodiumAssay)) {
    if (!sensorsPlatinumAssay) {
      errors.sensorsPlatinumAssay = UPDATE_DEFAULT_PPMS.SENSORS_PLATINUM_ASSAY_EMPTY;
    }
    if (!sensorsPalladiumAssay) {
      errors.sensorsPalladiumAssay = UPDATE_DEFAULT_PPMS.SENSORS_PALLADIUM_ASSAY_EMPTY;
    }
    if (!sensorsRhodiumAssay) {
      errors.sensorsRhodiumAssay = UPDATE_DEFAULT_PPMS.SENSORS_RHODIUM_ASSAY_EMPTY;
    }
  }

  commaValidator(
    {
      monoPlatinumAssay,
      monoPalladiumAssay,
      monoRhodiumAssay,
      foilCannedPlatinumAssay,
      foilCannedPalladiumAssay,
      foilCannedRhodiumAssay,
      sensorsPlatinumAssay,
      sensorsPalladiumAssay,
      sensorsRhodiumAssay,
    },
    errors,
  );

  return errors;
}

export { validate };
