import PropTypes from 'prop-types';
import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector, batch } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { Field, reduxForm, formValueSelector, getFormSyncErrors } from 'redux-form';
import styled from 'styled-components';

import { openModal } from 'actions/shared/modal';

import { finalInvoiceCalculation } from 'components/FinalInvoice/helpers/finalInvoiceCalculation';

import { Disclaimer } from 'shared/components/Disclaimer/Disclaimer';
import { FieldInput, FieldHint } from 'shared/components/Fields';
import { FieldConfirmation } from 'shared/components/Fields/FieldConfirmation/FieldConfirmation';
import { FieldDate } from 'shared/components/Fields/FieldDate/FieldDate';
import { FieldUpload } from 'shared/components/Fields/FiledUpload/FieldUpload';
import { FieldUploadList } from 'shared/components/Fields/FiledUpload/FieldUploadList';
import { Form, Section } from 'shared/components/Form';
import { MultipleFieldsSection } from 'shared/components/Form/MultipleFieldsSection/MultipleFieldsSection';
import { FINAL_INVOICE } from 'shared/helpers/constants/lots/finalInvoiceConstants';
import { cancelModal } from 'shared/helpers/constants/modalConstants';
import { payoutRulesTypes } from 'shared/helpers/constants/payments/payoutRulesTypes';
import { formatToDollar } from 'shared/helpers/parsers/formaters';
import { safeReplace } from 'shared/helpers/parsers/text';
import { cssVar } from 'shared/helpers/styling/styling';
import { validate } from 'shared/helpers/validations/lot/finalInvoiceValidation';

import dateAdapter from 'utils/date/dateAdapter';

const formName = 'FinalInvoiceForm';
const selector = formValueSelector(formName);
const disableInput = value => value <= 0;
const syncError = getFormSyncErrors(formName);

const MarketIndicators = styled.div`
  display: flex;
  align-items: center;
  min-height: 35px;
  padding: 0 22px;
  color: ${cssVar('alto')};
  background-color: ${cssVar('outerSpaceBitLighter')};
  border-bottom: 1px solid ${cssVar('outerSpaceBitLighter')};
  font-size: 0.75rem;
  width: 100%;
`;

const CurrentMarketIndicator = styled.span`
  display: block;
  width: 33.3333333%;
  padding: 0 15px;
  text-align: center;
`;

const FinalInvoiceFormLayout = ({
  handleSubmit,
  invalid,
  pristine,
  finalInvoiceMaximumOunces: {
    data: {
      maximumPlatinumAccount,
      maximumPalladiumAccount,
      maximumRhodiumAccount,
      receivedAt,
      removingOuncesDisabled,
      balanceRemaining,
    },
  },
  change,
  match,
  history,
  uploadFinalInvoice,
  finalInvoiceFile,
  addCustomFinalInvoice,
  clearCustomFinalInvoice,
  blur,
  mockup,
  latestMarketIndicators,
  marketIndicatorsPending,
  submitButtonLabel,
}) => {
  const dispatch = useDispatch();
  const totalFinalPayment = useSelector(state => selector(state, 'totalFinalPayment'));
  const platinumOuncesRemovedVal = useSelector(state => selector(state, 'platinumOuncesRemoved'));
  const palladiumOuncesRemovedVal = useSelector(state => selector(state, 'palladiumOuncesRemoved'));
  const rhodiumOuncesRemovedVal = useSelector(state => selector(state, 'rhodiumOuncesRemoved'));
  const { lot } = useSelector(({ lotDetails }) => lotDetails);
  const [priceFieldsFilled, setPriceFieldsFilled] = useState(false);

  const form = {
    values: useSelector(state => state.form.FinalInvoiceForm.values),
    errors: useSelector(state => syncError(state)),
  };

  const setDisableConfirmation = fieldName => {
    return Boolean(!form.values[fieldName] || form.errors[fieldName]);
  };

  useEffect(() => {
    change(
      'invoiceGeneratedAt',
      lot?.sentFromCompany.payoutRule?.rule === payoutRulesTypes.net30
        ? dateAdapter(lot?.suggestedFinalPaymentDate)
        : mockup
        ? dateAdapter()
        : dateAdapter().add(1, 'd'),
    );
  }, [lot]);

  useEffect(() => {
    disableInput(maximumPlatinumAccount) && change('platinumOuncesRemoved', 0);
    disableInput(maximumPalladiumAccount) && change('palladiumOuncesRemoved', 0);
    disableInput(maximumRhodiumAccount) && change('rhodiumOuncesRemoved', 0);
  }, [change, maximumPalladiumAccount, maximumPlatinumAccount, maximumRhodiumAccount]);

  const modalOpen = () => {
    dispatch(openModal(cancelModal, () => history.push(`/lots/list/${match.params.id}`)));
  };

  const invoiceCalculation = finalInvoiceCalculation({
    maximumPlatinumAccount,
    maximumPalladiumAccount,
    maximumRhodiumAccount,
    platinumOuncesRemoved: platinumOuncesRemovedVal,
    palladiumOuncesRemoved: palladiumOuncesRemovedVal,
    rhodiumOuncesRemoved: rhodiumOuncesRemovedVal,
  });

  const clearFinalInvoicePrice = fieldName => {
    change(fieldName, null);
    change(`${fieldName}Confirm`, null);
  };

  useEffect(() => {
    if (platinumOuncesRemovedVal === maximumPlatinumAccount)
      clearFinalInvoicePrice('finalPlatinumPrice');

    if (palladiumOuncesRemovedVal === maximumPalladiumAccount)
      clearFinalInvoicePrice('finalPalladiumPrice');

    if (rhodiumOuncesRemovedVal === maximumRhodiumAccount)
      clearFinalInvoicePrice('finalRhodiumPrice');
  }, [platinumOuncesRemovedVal, palladiumOuncesRemovedVal, rhodiumOuncesRemovedVal]);

  useEffect(() => {
    !invoiceCalculation.finalRhodiumPriceCalculation &&
      form.values.finalPalladiumPrice &&
      setPriceFieldsFilled(true);

    invoiceCalculation.finalRhodiumPriceCalculation &&
      form.values.finalRhodiumPrice &&
      setPriceFieldsFilled(true);
  }, [change, invoiceCalculation]);

  const ouncesFields = [
    {
      component: FieldHint,
      name: 'platinumOuncesRemoved',
      label: FINAL_INVOICE.PLATINUM_OUNCES_REMOVED,
      field: 'required',
      hint: FINAL_INVOICE.MAXIMUM,
      suggestion: maximumPlatinumAccount,
      disabled: disableInput(maximumPlatinumAccount) || removingOuncesDisabled,
      forceHint: true,
    },
    {
      component: FieldHint,
      name: 'palladiumOuncesRemoved',
      label: FINAL_INVOICE.PALLADIUM_OUNCES_REMOVED,
      field: 'required',
      hint: FINAL_INVOICE.MAXIMUM,
      suggestion: maximumPalladiumAccount,
      disabled: disableInput(maximumPalladiumAccount) || removingOuncesDisabled,
      forceHint: true,
    },
    {
      component: FieldHint,
      name: 'rhodiumOuncesRemoved',
      label: FINAL_INVOICE.RHODIUM_OUNCES_REMOVED,
      field: 'required',
      hint: FINAL_INVOICE.MAXIMUM,
      suggestion: maximumRhodiumAccount,
      disabled: disableInput(maximumRhodiumAccount) || removingOuncesDisabled,
      forceHint: true,
    },
  ];

  const finalInvoiceChange = e => {
    addCustomFinalInvoice(e.target.files[0]);
  };

  const totalFinalPaymentChange = useCallback((event, newValue, previousValue) => {
    if (previousValue > 0) return;

    batch(() => {
      change('balanceRemaining', balanceRemaining);
      change('outstandingBalanceApplied', Math.abs(balanceRemaining));
    });
  }, []);

  const fieldUploadList = () => {
    return [
      {
        file: finalInvoiceFile.grgFinalInvoice,
        onDelete: () => {
          clearCustomFinalInvoice();
          blur('grgFinalInvoice', null);
        },
      },
    ];
  };

  const disabledPalladium =
    invoiceCalculation.finalPlatinumPriceCalculation &&
    setDisableConfirmation('finalPlatinumPriceConfirm');

  const disabledRhodium = () => {
    if (invoiceCalculation.finalPalladiumPriceCalculation) {
      return (
        invoiceCalculation.finalPalladiumPriceCalculation &&
        setDisableConfirmation('finalPalladiumPriceConfirm')
      );
    }

    if (invoiceCalculation.finalPlatinumPriceCalculation) {
      return disabledPalladium;
    }

    return;
  };

  return (
    <Form
      onSubmit={handleSubmit}
      onCancel={modalOpen}
      header={FINAL_INVOICE.FINAL_INVOICE}
      submitButtonLabel={submitButtonLabel || FINAL_INVOICE.GENERATE}
      submitDisabled={invalid || pristine}
    >
      <Section template={['invoiceGeneratedAt grgFinalInvoice']}>
        <Field
          name="invoiceGeneratedAt"
          component={FieldDate}
          label={FINAL_INVOICE.FINAL_PAYMENT_DATE}
          field="required"
          minDate={dateAdapter(receivedAt)}
          disableFlip
        />
        {uploadFinalInvoice && (
          <div name="grgFinalInvoice">
            <Field
              name="grgFinalInvoice"
              type="file"
              validExtensions=".pdf"
              field="required"
              component={FieldUpload}
              label={FINAL_INVOICE.FINAL_INVOICE}
              onChange={finalInvoiceChange}
            />
            {finalInvoiceFile.grgFinalInvoice && <FieldUploadList files={fieldUploadList()} />}
          </div>
        )}
      </Section>
      {!mockup && (
        <Section
          header={FINAL_INVOICE.PRICES}
          template={[
            'finalPlatinumPrice finalPlatinumPrice',
            'finalPalladiumPrice finalPalladiumPrice',
            'finalRhodiumPrice finalRhodiumPrice',
          ]}
          noPadding
        >
          {invoiceCalculation.finalPlatinumPriceCalculation && (
            <FieldConfirmation
              name="finalPlatinumPrice"
              component={FieldInput}
              type="text"
              label={FINAL_INVOICE.FINAL_PLATINUM_PRICE}
              errorMessage={FINAL_INVOICE.FINAL_PLATINUM_PRICE_DO_NOT_MATCH}
              change={change}
              field="required"
              prefix="$"
              disableConfirmation={setDisableConfirmation(`finalPlatinumPrice`)}
            />
          )}
          {invoiceCalculation.finalPalladiumPriceCalculation && (
            <FieldConfirmation
              name="finalPalladiumPrice"
              component={FieldInput}
              type="text"
              label={FINAL_INVOICE.FINAL_PALLADIUM_PRICE}
              errorMessage={FINAL_INVOICE.FINAL_PALLADIUM_PRICE_DO_NOT_MATCH}
              change={change}
              field="required"
              prefix="$"
              disableConfirmation={setDisableConfirmation(`finalPalladiumPrice`)}
              disabled={!priceFieldsFilled && !form.values.finalPalladiumPrice && disabledPalladium}
            />
          )}
          {invoiceCalculation.finalRhodiumPriceCalculation && (
            <FieldConfirmation
              name="finalRhodiumPrice"
              component={FieldInput}
              type="text"
              label={FINAL_INVOICE.FINAL_RHODIUM_PRICE}
              errorMessage={FINAL_INVOICE.FINAL_RHODIUM_PRICE_DO_NOT_MATCH}
              change={change}
              field="required"
              prefix="$"
              disableConfirmation={setDisableConfirmation(`finalRhodiumPrice`)}
              disabled={!priceFieldsFilled && !form.values.finalRhodiumPrice && disabledRhodium()}
            />
          )}
        </Section>
      )}
      {uploadFinalInvoice && (
        <Section
          header={FINAL_INVOICE.TOTAL_FINAL_PAYMENT_HEADER}
          template={['totalFinalPayment .']}
        >
          <Field
            name="totalFinalPayment"
            field="required"
            component={FieldInput}
            label={FINAL_INVOICE.TOTAL_FINAL_PAYMENT}
            onChange={totalFinalPaymentChange}
            prefix="$"
          />
        </Section>
      )}
      {balanceRemaining !== 0 &&
        (!uploadFinalInvoice || safeReplace(totalFinalPayment, ',', '') > 0) &&
        !mockup && (
          <Section
            header={FINAL_INVOICE.OUTSTANDING_BALANCE_APPLIED_HEADER}
            template={['balanceRemaining outstandingBalanceApplied']}
          >
            <Field
              name="balanceRemaining"
              field="required"
              component={FieldInput}
              label={FINAL_INVOICE.BALANCE_REMAINING}
              color={cssVar('solidRed')}
              prefix="$"
              format={formatToDollar}
              disabled
            />
            <Field
              name="outstandingBalanceApplied"
              field="required"
              component={FieldInput}
              label={FINAL_INVOICE.OUTSTANDING_BALANCE_APPLIED}
              prefix="$"
            />
          </Section>
        )}
      <Section
        template={
          removingOuncesDisabled
            ? ['ouncesFields ouncesFields', 'removingDisabled removingDisabled']
            : ['ouncesFields ouncesFields']
        }
        header={
          mockup
            ? FINAL_INVOICE.POOL_ACCOUNT_MOCKUP_OUNCES_WARNING
            : FINAL_INVOICE.POOL_ACCOUNT_OUNCES_WARNING
        }
        noPadding
      >
        <MultipleFieldsSection name="ouncesFields" fields={ouncesFields} />
        {removingOuncesDisabled && (
          <Disclaimer name="removingDisabled">{FINAL_INVOICE.REMOVING_OUNCES_DISABLED}</Disclaimer>
        )}
      </Section>
      {!marketIndicatorsPending && mockup && (
        <MarketIndicators>
          <CurrentMarketIndicator>
            {FINAL_INVOICE.FINAL_PLATINUM_PRICE}: <b>{latestMarketIndicators?.platinum || '-'} $</b>
          </CurrentMarketIndicator>
          <CurrentMarketIndicator>
            {FINAL_INVOICE.FINAL_PALLADIUM_PRICE}:{' '}
            <b>{latestMarketIndicators?.palladium || '-'} $</b>
          </CurrentMarketIndicator>
          <CurrentMarketIndicator>
            {FINAL_INVOICE.FINAL_RHODIUM_PRICE}: <b>{latestMarketIndicators?.rhodium || '-'} $</b>
          </CurrentMarketIndicator>
        </MarketIndicators>
      )}
    </Form>
  );
};

const FinalInvoiceForm = compose(
  withRouter,
  reduxForm({
    form: formName,
    validate,
  }),
)(FinalInvoiceFormLayout);

FinalInvoiceFormLayout.defaultProps = {
  latestMarketIndicators: {
    platinum: 0,
    palladium: 0,
    rhodium: 0,
  },
};

FinalInvoiceFormLayout.propTypes = {
  blur: PropTypes.func,
  change: PropTypes.func,
  finalInvoiceMaximumOunces: PropTypes.shape({
    data: PropTypes.shape({
      balanceRemaining: PropTypes.number,
      maximumPalladiumAccount: PropTypes.number,
      maximumPlatinumAccount: PropTypes.number,
      maximumRhodiumAccount: PropTypes.number,
      receivedAt: PropTypes.number,
      removingOuncesDisabled: PropTypes.bool,
      platinumOuncesRemoved: PropTypes.number,
      palladiumOuncesRemoved: PropTypes.number,
      rhodiumOuncesRemoved: PropTypes.number,
    }).isRequired,
  }),
  handleSubmit: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  latestMarketIndicators: PropTypes.shape({
    palladium: PropTypes.number,
    platinum: PropTypes.number,
    rhodium: PropTypes.number,
    updateOlderThanOneHour: PropTypes.bool,
  }),
  invalid: PropTypes.bool,
  marketIndicatorsPending: PropTypes.bool,
  match: PropTypes.shape({
    isExact: PropTypes.bool,
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
    path: PropTypes.string,
    url: PropTypes.string,
  }),
  mockup: PropTypes.bool,
  pristine: PropTypes.bool,
};

export { FinalInvoiceFormLayout, FinalInvoiceForm };
