import AgreementsDetailsReducer from './agreementsDetails';
import AgreementsFiltersReducer from './agreementsFilters';
import AgreementsListReducer from './agreementsList';
import CreateAgreementReducer from './createAgreement';
import AgreementsUploadDocumentReducer from './uploadAgreementDocument';

const agreementsRootReducer = {
  createAgreements: CreateAgreementReducer,
  agreementsList: AgreementsListReducer,
  agreementsFilters: AgreementsFiltersReducer,
  agreementsDetails: AgreementsDetailsReducer,
  agreementsUploadDocument: AgreementsUploadDocumentReducer,
};

export { agreementsRootReducer };
