import { processViaOptions } from 'shared/helpers/constants/lots/processingConstants';
import { statusesForGlobal } from 'shared/helpers/constants/lots/statusesForGlobal';
import { CREATE_PACKAGE } from 'shared/helpers/constants/packages/createPackageConstants';
import { materialTypeForAssay } from 'shared/helpers/constants/packages/materialType';
import { packageStatuses, packageType } from 'shared/helpers/constants/packages/packageType';
import { isWarehouseUser, isOwnerUser } from 'shared/helpers/matchers/checkUserType';
import { isGlobalRefiningGroup } from 'shared/helpers/matchers/isGlobalRefiningGroup';
import { safeParseFloat } from 'shared/helpers/parsers/number';
import { replaceEmpty } from 'shared/helpers/parsers/text';
import { commaValidator } from 'shared/helpers/validations/commaValidator';
import {
  INTEGER_REGEX,
  FLOAT_REGEX,
  CAPITAL_ALPHANUM_REGEX,
  CAPITAL_ALPHANUM_WHITE_SPACES_REGEX,
} from 'shared/helpers/validations/regularExpressions';

function validatePackage(pkg, props) {
  const error = {};
  const { auth, forLot } = props;
  const materialTypeValue = pkg.materialTypeDeclared && pkg.materialTypeDeclared.value;

  if (!pkg.assignedLot) {
    error.assignedLot = CREATE_PACKAGE.INVALID_ASSIGNED_LOT_EMPTY;
  }

  if (
    materialTypeValue === materialTypeForAssay.other &&
    (!pkg.customerInstructions || !pkg.customerInstructions.trim())
  ) {
    error.customerInstructions = isOwnerUser(auth.user.userType)
      ? CREATE_PACKAGE.INVALID_CUSTOMER_INSTRUCTIONS_REQUIRED_OWNER
      : CREATE_PACKAGE.INVALID_CUSTOMER_INSTRUCTIONS_REQUIRED_CUSTOMER;
  } else if (pkg.customerInstructions && pkg.customerInstructions.length > 100) {
    error.customerInstructions = CREATE_PACKAGE.INVALID_CUSTOMER_INSTRUCTIONS_LENGTH;
  }

  if (!pkg.packageType && pkg.status !== packageStatuses.deleted) {
    error.packageType = CREATE_PACKAGE.INVALID_PACKAGE_TYPE_EMPTY;
  }

  if (
    forLot &&
    forLot.processVia &&
    forLot.processVia !== processViaOptions.shears.Shears &&
    forLot.statusForGlobal !== statusesForGlobal.ready_for_processing &&
    !pkg.materialClass
  ) {
    error.materialClass = CREATE_PACKAGE.INVALID_MATERIAL_CLASS;
  }

  if (!pkg.materialTypeDeclared && pkg.status !== packageStatuses.deleted) {
    error.materialTypeDeclared = CREATE_PACKAGE.INVALID_MATERIAL_TYPE_DECLARED_EMPTY;
  }

  if (pkg.unitCount && pkg.unitCount <= 0) {
    error.unitCount = CREATE_PACKAGE.INVALID_UNIT_COUNT_LESS;
  } else if (
    (pkg.unitCount && !INTEGER_REGEX.test(pkg.unitCount)) ||
    replaceEmpty(pkg.unitCount, '').length > 10
  ) {
    error.unitCount = CREATE_PACKAGE.INVALID_UNIT_COUNT;
  } else if (pkg.packageType?.value === packageType.sample) {
    if (pkg.unitCount > 140) {
      error.unitCount = CREATE_PACKAGE.INVALID_UNIT_COUNT;
    }
    if (!pkg.unitCount) {
      error.unitCount = CREATE_PACKAGE.INVALID_UNIT_COUNT_EMPTY;
    }
  }

  if (pkg.status !== packageStatuses.deleted) {
    if (
      !pkg.weightGrossDeclared &&
      pkg.weightGrossDeclared !== 0 &&
      !isWarehouseUser(auth.user.userType) &&
      !isGlobalRefiningGroup(forLot && forLot.sentFromCompany.companyName) &&
      !(forLot && (forLot.processVia || forLot.mixVia))
    ) {
      error.weightGrossDeclared = CREATE_PACKAGE.INVALID_WEIGHT_GROSS_DECLARED_EMPTY;
    } else if (pkg.weightGrossDeclared && pkg.weightGrossDeclared <= 0) {
      error.weightGrossDeclared = CREATE_PACKAGE.INVALID_WEIGHT_GROSS_DECLARED_LESS;
    } else if (
      (pkg.weightGrossDeclared && !FLOAT_REGEX.test(pkg.weightGrossDeclared)) ||
      replaceEmpty(pkg.weightGrossDeclared, '').length > 10
    ) {
      error.weightGrossDeclared = CREATE_PACKAGE.INVALID_WEIGHT_GROSS_DECLARED;
    }

    if (
      !pkg.weightTareDeclared &&
      pkg.weightTareDeclared !== 0 &&
      !isWarehouseUser(auth.user.userType) &&
      !isGlobalRefiningGroup(forLot && forLot.sentFromCompany.companyName) &&
      !(forLot && (forLot.processVia || forLot.mixVia))
    ) {
      error.weightTareDeclared = CREATE_PACKAGE.INVALID_WEIGHT_TARE_DECLARED_EMPTY;
    } else if (pkg.weightTareDeclared < 0) {
      error.weightTareDeclared = CREATE_PACKAGE.INVALID_WEIGHT_TARE_DECLARED_LESS;
    } else if (
      (pkg.weightTareDeclared && !FLOAT_REGEX.test(pkg.weightTareDeclared)) ||
      replaceEmpty(pkg.weightTareDeclared, '').length > 10
    ) {
      error.weightTareDeclared = CREATE_PACKAGE.INVALID_WEIGHT_TARE_DECLARED;
    } else if (
      (!pkg.weightGrossDeclared && pkg.weightTareDeclared) ||
      (pkg.weightGrossDeclared &&
        pkg.weightGrossDeclared &&
        safeParseFloat(pkg.weightGrossDeclared) <= safeParseFloat(pkg.weightTareDeclared))
    ) {
      error.weightTareDeclared = CREATE_PACKAGE.INVALID_WEIGHT_TARE_DECLARED_GREATER;
    }
  }

  if (pkg.weightGrossActual && pkg.weightGrossActual <= 0) {
    error.weightGrossActual = CREATE_PACKAGE.INVALID_WEIGHT_GROSS_ACTUAL_LESS;
  } else if (
    (pkg.weightGrossActual && !FLOAT_REGEX.test(pkg.weightGrossActual)) ||
    replaceEmpty(pkg.weightGrossActual, '').length > 10
  ) {
    error.weightGrossActual = CREATE_PACKAGE.INVALID_WEIGHT_GROSS_ACTUAL;
  }

  if (pkg.weightTareActual && pkg.weightTareActual < 0) {
    error.weightTareActual = CREATE_PACKAGE.INVALID_WEIGHT_TARE_ACTUAL_LESS;
  } else if (
    (pkg.weightTareActual && !FLOAT_REGEX.test(pkg.weightTareActual)) ||
    replaceEmpty(pkg.weightTareActual, '').length > 10
  ) {
    error.weightTareActual = CREATE_PACKAGE.INVALID_WEIGHT_TARE_ACTUAL;
  } else if (
    (!pkg.weightGrossActual && pkg.weightTareActual) ||
    (pkg.weightGrossActual &&
      safeParseFloat(pkg.weightGrossActual) <= safeParseFloat(pkg.weightTareActual))
  ) {
    error.weightTareActual = CREATE_PACKAGE.INVALID_WEIGHT_TARE_ACTUAL_GREATER;
  }

  if (
    pkg.labelId &&
    (!CAPITAL_ALPHANUM_REGEX.test(pkg.labelId) || replaceEmpty(pkg.labelId, '').length > 50)
  ) {
    error.labelId = CREATE_PACKAGE.INVALID_LABEL_ID_FORMAT;
  }

  if (
    pkg.plant &&
    (!CAPITAL_ALPHANUM_WHITE_SPACES_REGEX.test(pkg.plant) ||
      replaceEmpty(pkg.plant, '').length > 50)
  ) {
    error.plant = CREATE_PACKAGE.INVALID_PLANT_FORMAT;
  }

  commaValidator(
    {
      weightGrossDeclared: pkg.weightGrossDeclared,
      weightTareDeclared: pkg.weightTareDeclared,
      weightGrossActual: pkg.weightGrossActual,
      weightTareActual: pkg.weightTareActual,
    },
    error,
  );

  return error;
}

function validate(values, props) {
  return {
    packages: (values.packages || []).map((pkg, index) => validatePackage(pkg, props, index)),
  };
}

export { validate };
