import {
  getPriceFeedsType,
  getPriceFeedsSuccessType,
  getPriceFeedsFailureType,
  clearPriceFeedsType,
} from 'actions/PriceFeeds/getPriceFeeds';

const INITIAL_STATE = {
  isPending: false,
  priceFeeds: ['platinum', 'palladium', 'rhodium'].map(metal => ({
    adjustment: null,
    currentPrice: 0,
    manualPrice: null,
    metal,
    source: 'Loading...',
    updatedAt: null,
    updatedBy: null,
  })),
};

const getPriceFeedsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getPriceFeedsType:
      return {
        ...INITIAL_STATE,
        isPending: true,
      };
    case getPriceFeedsSuccessType:
      return {
        isPending: false,
        priceFeeds: action.payload.priceFeeds,
      };
    case clearPriceFeedsType:
    case getPriceFeedsFailureType:
      return {
        priceFeeds: null,
        isPending: false,
      };
    default:
      return state;
  }
};

export { getPriceFeedsReducer };
