import { processViaOptions } from 'shared/helpers/constants/lots/processingConstants';
import { statusesForGlobal } from 'shared/helpers/constants/lots/statusesForGlobal';

const createProcessingPackageAccess = (auth, lot) =>
  auth.access.packages.createProcessing &&
  [
    statusesForGlobal.ready_for_mixing,
    statusesForGlobal.accepted_for_processing,
    statusesForGlobal.accepted_for_mixing,
    statusesForGlobal.ready_to_ship,
  ].includes(lot.statusForGlobal) &&
  processViaOptions.processing.includes(lot.processVia);

export { createProcessingPackageAccess };
