import axios from 'axios';

import { showSnackbar } from 'actions/shared/snackbar';

import {
  dangerOptions,
  successOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

import { history } from 'utils/history';

import { virtualLotParseData } from 'pages/Lots/helpers/virtualLotParseData';

const API_HOST_AUTH = process.env.REACT_APP_API;

const createVirtualLotActionType = 'CREATE_VIRTUAL_LOT';
const createVirtualLotAction = () => ({
  type: createVirtualLotActionType,
});

const createVirtualLotSuccessActionType = `${createVirtualLotActionType}_SUCCESS`;
const createVirtualLotSuccessAction = () => ({
  type: createVirtualLotSuccessActionType,
});

const createVirtualLotFailureActionType = `${createVirtualLotActionType}_FAILURE`;
const createVirtualLotFailureAction = () => ({
  type: createVirtualLotFailureActionType,
});

const createVirtualLot = virtualLot => dispatch => {
  dispatch(createVirtualLotAction());

  return axios({
    method: 'post',
    url: `${API_HOST_AUTH}/virtual-lots`,
    data: JSON.stringify(virtualLotParseData(virtualLot)),
  })
    .then(({ data }) => {
      dispatch(createVirtualLotSuccessAction());

      dispatch(showSnackbar(successOptions, data.message));
      history.push('/lots');
    })
    .catch(({ data }) => {
      dispatch(createVirtualLotFailureAction());

      dispatch(showSnackbar(dangerOptions, data.message));
    });
};

export {
  createVirtualLotActionType,
  createVirtualLotAction,
  createVirtualLot,
  createVirtualLotSuccessActionType,
  createVirtualLotSuccessAction,
  createVirtualLotFailureActionType,
  createVirtualLotFailureAction,
};
