const hedgeTypes = {
  standard: 'standard',
  blanket: 'blanket',
  custom: 'custom',
  internal: 'internal',
};

const trustedCompanyTypes = {
  standard: 'standard',
  blanket: 'blanket',
};

const untrustedCompanyTypes = {
  blanket: 'blanket',
};

export { trustedCompanyTypes, untrustedCompanyTypes, hedgeTypes };
