const filtersCompanyMapper = filters => ({
  ...filters,
  country: [filters?.country?.value].filter(Boolean),
  state: [filters?.state?.value].filter(Boolean),
});

function parseCompanyFilters(filters, limit, page, searchFilter) {
  return {
    ...filtersCompanyMapper(filters),
    searchFilter,
    limit,
    page,
  };
}

export { parseCompanyFilters, filtersCompanyMapper };
