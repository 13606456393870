import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getGeneralInfo } from 'actions/GeneralInfo/generalInfoList';

import { OverviewHeader } from 'shared/components/OverviewHeader';
import { PreloaderInline } from 'shared/components/PreloaderInline/PreloaderInline';

import { GENERAL_INFO } from '../../../../shared/helpers/constants/generalInfoConstants';
import { StyledGeneralInfoWrapper } from './StyledGeneralInfoPage';
import { DetailsInfo } from './components/DetailsInfo';
import { fieldsConfig } from './config/fieldsConfig';

const GeneralInfoPage = () => {
  const { generalInfo, isPending } = useSelector(state => state.generalInfo);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getGeneralInfo());
  }, []);

  return isPending ? (
    <PreloaderInline loading />
  ) : (
    <>
      <OverviewHeader header={GENERAL_INFO.GENERAL_INFO} marginBottom="15px" />
      <StyledGeneralInfoWrapper>
        {fieldsConfig(generalInfo).map((e, index) => (
          <DetailsInfo title={e.title} value={e.value} fields={e.fields} key={index} />
        ))}
      </StyledGeneralInfoWrapper>
    </>
  );
};
export { GeneralInfoPage };
