import {
  printLotLabelActionType,
  printLotLabelSuccessActionType,
  printLotLabelFailureActionType,
} from 'actions/Lots/printLotLabel';

const INITIAL_STATE = {
  isPending: false,
};

const PrintLotLabelReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case printLotLabelActionType:
      return {
        isPending: true,
      };
    case printLotLabelSuccessActionType:
    case printLotLabelFailureActionType:
      return {
        isPending: false,
      };
    default:
      return state;
  }
};

export { PrintLotLabelReducer };
