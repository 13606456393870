import PropTypes from 'prop-types';
import React, { memo } from 'react';
import styled from 'styled-components';

import { Button } from 'shared/components/Buttons/Button';
import { CancelButton } from 'shared/components/Buttons/CancelButton/CancelButton';
import { Icon } from 'shared/components/Icon/Icon';
import { SETTLEMENT_FOR_LOT_WIZARD } from 'shared/helpers/constants/poolAccounts/settlementForLotWizard';
import { media, cssVar } from 'shared/helpers/styling/styling';

export const SummaryActionsStyled = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin: 65px 0 25px 0;

  ${media.phone`
      flex-wrap: wrap;
    `}

  span {
    display: flex;
    flex-direction: row-reverse;

    button:first-child {
      margin-left: 30px;
    }

    ${media.phone`
      flex-direction: column-reverse;
      width: 100%;

      button:first-child {
        margin: 0 0 10px;
      }
 
      button:nth-child(2) {
        margin: 0 0 10px;
      }
    `}
  }
`;

const SummaryActions = memo(({ onCancel, onSubmit, onReset, isDisabled, isResetDisabled }) => {
  return (
    <SummaryActionsStyled>
      <span>
        <Button id="submit" onClick={onSubmit} disabled={isDisabled} padding="0 10px 0 23px">
          Submit
          <Icon icon="icon-arrow-right" />
        </Button>
      </span>
      <span>
        <Button
          id="reset"
          onClick={onReset}
          padding="0 10px 0 23px"
          outlineColor={cssVar('pelorous')}
          disabled={isResetDisabled}
        >
          {SETTLEMENT_FOR_LOT_WIZARD.REQUEST_SETTLEMENT}
          <Icon icon="icon-plus" />
        </Button>
        <CancelButton onClick={onCancel} />
      </span>
    </SummaryActionsStyled>
  );
});

SummaryActions.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  isResetDisabled: PropTypes.bool.isRequired,
};

export { SummaryActions };
