const message = {
  INVALID_VIRTUAL_LOT_NAME_EMPTY: 'Virtual lot name cannot be empty.',
  INVALID_VIRTUAL_LOT_NAME_LENGTH: 'Virtual lot name can be up to 50 characters long.',
  INVALID_VIRTUAL_LOT_SECTION_EMPTY: 'Virtual lot section cannot be empty.',
  INVALID_VIRTUAL_LOT_SUBSECTION_EMPTY: 'Virtual lot subsection cannot be empty.',
  INVALID_VIRTUAL_LOT_WEIGHT_EMPTY: 'Virtual lot weight cannot be empty.',
  INVALID_VIRTUAL_LOT_WEIGHT_NEGATIVE: 'Weight cannot be equal to or less than 0.',
  INVALID_VIRTUAL_LOT_WEIGHT_RECEIVED_INVALID: 'Weight must have a valid format.',
};

export { message };
