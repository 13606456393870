import React, { Component, Fragment } from 'react';

import { UIAuthorization } from 'components/Authorization/UIAuthorization';

import { ContentLoader } from 'shared/components/ContentLoader/ContentLoader';
import { renderDetailsContainer } from 'shared/components/Details/helpers/renderDetailsContainer';
import { selfDeliveryRequestStatuses } from 'shared/helpers/constants/selfDeliveryRequests/selfDeliveryRequestStatuses';
import { dangerOptions } from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { isGlobalUserType } from 'shared/helpers/matchers/isGlobalUserType';

import { extractErrorMessage } from 'utils/extractErrorMessage';

import { ShipmentsCalendar } from 'pages/Shipments/components/ShipmentsCalendar/ConnectedShipmentsCalendar';

import { SelfDeliveryRequestMainDetails } from './SelfDeliveryRequestMainDetails/SelfDeliveryRequestMainDetails';
import {
  selfDeliveryRequestDetailsContainerConfig,
  selfDeliveryRequestDetailsFieldsConfig,
  activityContainerConfig,
  activityFieldsConfig,
} from './config/fieldsConfig';

class SelfDeliveryRequestDetails extends Component {
  componentDidMount() {
    this.props
      .getSelfDeliveryRequest(this.props.match.params.id)
      .then(sdRequest => sdRequest || Promise.reject(null))
      .catch(error => {
        this.props.showSnackbar(dangerOptions, extractErrorMessage(error));
        return this.props.history.push('/shipments');
      });
  }

  componentWillUnmount() {
    this.props.clearSelfDeliveryRequestDetails();
    this.props.clearShipmentsCalendar();
  }

  render() {
    const { selfDeliveryRequestDetails, auth } = this.props;
    const { selfDeliveryRequest, isPending } = selfDeliveryRequestDetails;

    return (
      <ContentLoader loaded={selfDeliveryRequest && !isPending}>
        {selfDeliveryRequest && (
          <>
            <SelfDeliveryRequestMainDetails auth={auth} selfDeliveryRequest={selfDeliveryRequest} />
            {renderDetailsContainer(
              selfDeliveryRequestDetailsContainerConfig,
              selfDeliveryRequestDetailsFieldsConfig,
              selfDeliveryRequest,
            )}
            {isGlobalUserType(auth.user.userType) &&
              renderDetailsContainer(
                activityContainerConfig,
                activityFieldsConfig,
                selfDeliveryRequest,
              )}
            <UIAuthorization access={auth.access.selfDeliveryRequests.details.calendar}>
              <ShipmentsCalendar
                markedDay={
                  selfDeliveryRequest.status === selfDeliveryRequestStatuses.approved &&
                  selfDeliveryRequest.deliveryDate
                }
              />
            </UIAuthorization>
          </>
        )}
      </ContentLoader>
    );
  }
}

export { SelfDeliveryRequestDetails };
