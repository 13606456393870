import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { clearOpenFinalLots, getOpenFinalLots } from 'actions/Lots/getOpenFinalLots';

import { FullPageList } from 'shared/components/FullPageList/FullPageList';
import {
  itemsTemplate,
  actionsTemplate,
  itemsDetailsTemplate,
  itemsTemplateMobile,
} from 'shared/helpers/constants/reports/openFinalsConfig';
import { REPORTS } from 'shared/helpers/constants/reports/reportsConstants';

import { SettlementNotesModal } from './components/SettlementNotesModal';

export const OpenFinals = () => {
  const dispatch = useDispatch();
  const [displaySettlementNotesModal, setDisplaySettlementNotesModal] = useState(false);
  const [relatedLotId, setRelatedLotId] = useState(null);
  const { openFinalLots, isPending, limit, page, pages } = useSelector(
    ({ openFinalLots }) => openFinalLots,
  );

  useEffect(() => () => dispatch(clearOpenFinalLots()), [dispatch]);

  const handleFetchList = useCallback(
    (page = 1, limit = 50) => dispatch(getOpenFinalLots(limit, page)),
    [limit, page],
  );

  const handleToggleModal = useCallback(
    async lot => {
      lot?._id && setRelatedLotId(lot?._id);
      return setDisplaySettlementNotesModal(current => !current);
    },
    [relatedLotId, displaySettlementNotesModal],
  );

  return (
    <>
      <FullPageList
        title={REPORTS.OPEN_FINALS}
        itemsTemplate={itemsTemplate}
        itemsDetailsTemplate={itemsDetailsTemplate}
        itemsTemplateMobile={itemsTemplateMobile}
        actionsTemplate={actionsTemplate(handleToggleModal)}
        pagination={{ limit, page, pages }}
        fetchList={handleFetchList}
        items={openFinalLots || []}
        isPending={isPending}
      />
      <SettlementNotesModal
        isOpen={displaySettlementNotesModal}
        modalClose={handleToggleModal}
        relatedLotId={relatedLotId}
      />
    </>
  );
};
