const materialTypes = {
  mono_canned: 'mono_canned',
  mono_decanned: 'mono_decanned',
  foil_canned: 'foil_canned',
  foil_decanned: 'foil_decanned',
  DPF_canned: 'DPF_canned',
  DPF_decanned: 'DPF_decanned',
  sensors: 'sensors',
  sweeps: 'sweeps',
  beads_canned: 'beads_canned',
  beads_decanned: 'beads_decanned',
  industrial_foil: 'industrial_foil',
  industrial_mono: 'industrial_mono',
  high_grade_dust: 'high_grade_dust',
};

const allowedMaterialTypesForCustomer = {
  foil_canned: 'foil_canned',
  mono_canned: 'mono_canned',
  mono_decanned: 'mono_decanned',
  sensors: 'sensors',
};

const allowedMaterialTypesForBuyer = {
  foil_canned: 'foil_canned',
  mono_canned: 'mono_canned',
  mono_decanned: 'mono_decanned',
  sensors: 'sensors',
};

const materialTypesForSuggestions = [
  materialTypes.mono_canned,
  materialTypes.mono_decanned,
  materialTypes.foil_canned,
  materialTypes.sensors,
];

export {
  allowedMaterialTypesForCustomer,
  allowedMaterialTypesForBuyer,
  materialTypes,
  materialTypesForSuggestions,
};
