import { isSupportUser } from '../matchers/checkUserType';
import { companyTypesForUserRole } from './config/companyTypesForUserRole';

function companyDetailsListAccess(authAccess, companyType, userType) {
  const supportCheck = isSupportUser(userType)
    ? companyTypesForUserRole[userType].includes(companyType)
    : true;

  return authAccess && supportCheck;
}

export { companyDetailsListAccess };
