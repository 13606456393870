import { connect } from 'react-redux';

import { retryImport } from 'actions/Lots/retryImport';
import { showSnackbar } from 'actions/shared/snackbar';

import { snackbarOptions } from 'shared/helpers/matchers/snackbar/snackbarOptions';

function RetryImportLot(props) {
  const onClick = () => {
    props
      .retryImport(props.lot._id)
      .then(res => props.showSnackbar(snackbarOptions(res.messageType), res.message));
  };

  return props.children({ onClick });
}

const mapDispatchToProps = {
  showSnackbar,
  retryImport,
};

const ConnectedRetryImportLot = connect(null, mapDispatchToProps)(RetryImportLot);

export { ConnectedRetryImportLot as RetryImportLot, RetryImportLot as RetryImportLotTests };
