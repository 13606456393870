import {
  getHedgesSuggestedValuesActionType,
  getHedgesSuggestedValuesSuccessActionType,
  getHedgesSuggestedValuesFailedActionType,
} from 'actions/Hedges/hedgesSuggestedValues';

const INITIAL_STATE = {
  isPending: false,
};

const hedgesSuggestedValuesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getHedgesSuggestedValuesActionType:
      return {
        isPending: true,
      };
    case getHedgesSuggestedValuesSuccessActionType:
      return {
        isPending: false,
      };
    case getHedgesSuggestedValuesFailedActionType:
      return {
        isPending: false,
      };
    default:
      return state;
  }
};

export { hedgesSuggestedValuesReducer };
