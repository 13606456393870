import axios from 'axios';

import { parseUploadFinalInvoiceData } from 'shared/helpers/dataParsers/lot/parseUploadFinalInvoiceData';

const API_HOST_AUTH = process.env.REACT_APP_API;

const addCustomFinalInvoiceType = 'ADD_CUSTOM_FINAL_INVOICE';
const addCustomFinalInvoice = grgFinalInvoice => ({
  type: addCustomFinalInvoiceType,
  payload: {
    grgFinalInvoice,
  },
});

const clearCustomFinalInvoiceType = 'CLEAR_CUSTOM_FINAL_INVOICE';
const clearCustomFinalInvoice = () => ({
  type: clearCustomFinalInvoiceType,
});

const uploadCustomFinalInvoiceType = 'UPLOAD_CUSTOM_FINAL_INVOICE';
const uploadCustomFinalInvoiceAction = () => ({
  type: uploadCustomFinalInvoiceType,
});

const uploadCustomFinalInvoiceSuccessType = `${uploadCustomFinalInvoiceType}_SUCCESS`;
const uploadCustomFinalInvoiceSuccessAction = () => ({
  type: uploadCustomFinalInvoiceSuccessType,
});

const uploadCustomFinalInvoiceFailureType = `${uploadCustomFinalInvoiceType}_FAILURE`;
const uploadCustomFinalInvoiceFailureAction = () => ({
  type: uploadCustomFinalInvoiceFailureType,
});

const uploadCustomFinalInvoice =
  (formValues, { grgFinalInvoice }, lotId, { removingOuncesDisabled, balanceRemaining }) =>
  dispatch => {
    dispatch(uploadCustomFinalInvoiceAction());

    return axios
      .patch(
        `${API_HOST_AUTH}/lots/upload/final-invoice`,
        parseUploadFinalInvoiceData(
          formValues,
          grgFinalInvoice,
          lotId,
          removingOuncesDisabled,
          balanceRemaining,
        ),
        { headers: { 'Content-Type': 'multipart/form-data' } },
      )
      .then(res => {
        dispatch(uploadCustomFinalInvoiceSuccessAction());

        return res.data;
      })
      .catch(err => {
        dispatch(uploadCustomFinalInvoiceFailureAction());

        return Promise.reject(err.data);
      });
  };

export {
  addCustomFinalInvoiceType,
  clearCustomFinalInvoiceType,
  uploadCustomFinalInvoiceType,
  uploadCustomFinalInvoiceSuccessType,
  uploadCustomFinalInvoiceFailureType,
  addCustomFinalInvoice,
  clearCustomFinalInvoice,
  uploadCustomFinalInvoice,
};
