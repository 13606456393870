const message = {
  INVALID_COMPANY_NAME: 'Company name must have a valid format.',
  INVALID_COMPANY_NAME_EMPTY: 'Company name cannot be empty.',
  INVALID_LOT_PREFIX: 'Lot prefix must have a valid format.',
  INVALID_LOT_PREFIX_EMPTY: 'Lot prefix cannot be empty.',
  INVALID_LOT_PREFIX_UNIQUE: 'Lot prefix must be unique.',
  INVALID_VOLUME: 'Volume must have a valid format.',
  INVALID_REFERRAL_METHOD: 'Referral method must have a valid format.',
  INVALID_MATERIAL_COUNT_REQUIRED_EMPTY: 'Material count required must be selected.',
  INVALID_COMPANY_TYPE: 'Company type cannot be empty.',
  INVALID_LOCATION_EMPTY: 'Default shipping location cannot be empty.',
  INVALID_PURCHASING_APP_URL: 'URL must have a valid format.',
  INVALID_PURCHASING_APP_URL_REGEX: 'URL must be a valid link.',
  INVALID_PURCHASING_APP_URL_EMPTY: 'URL cannot be empty.',
  INVALID_ONLY_INTERNAL_HEDGING_SETTLEMENT_EMPTY:
    'Only internal hedging/settlement must be selected.',
  INVALID_PHOTO_ID_EXPIRES: 'Photo id expires at cannot be empty',
  INVALID_AML_EXPIRES: 'AML expires at cannot be empty',
  INVALID_BUSINESS_LICENSE_EXPIRES: 'Business license expires at cannot be empty',
  INVALID_SAMPLES_ALLOWED_EMPTY: 'Samples allowed must be selected',
};

export { message };
