import PropTypes from 'prop-types';
import * as React from 'react';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { generateShipmentSummary } from 'actions/Lots/generateShipmentSummary';
import { showSnackbar } from 'actions/shared/snackbar';

import { ModalCustom } from 'components/ModalCustom/ModalCustom';

import { Button } from 'shared/components/Buttons';
import { CancelButton } from 'shared/components/Buttons/CancelButton/CancelButton';
import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';
import { SHARED } from 'shared/helpers/constants/sharedConstants';
import {
  successOptions,
  dangerOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { cssVar } from 'shared/helpers/styling/styling';

const StyledContent = styled.div`
  color: ${cssVar('whiteGRG')};
`;

const StyledOptions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  button {
    min-width: 50%;
  }
`;

export const GenerateShipmentSummary = ({ modalClose, open, lot }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isWest = process.env.REACT_APP_VERSION === 'WEST';

  const generateStandardShipmentSummary = useCallback(() => {
    dispatch(generateShipmentSummary(lot._id, 'standard'))
      .then(res => {
        dispatch(showSnackbar(successOptions, res.message));
        return history.push(`/lots/list/${lot._id}/manage/shipment-summary`);
      })
      .catch(err => dispatch(showSnackbar(dangerOptions, err.message)));
  }, [lot]);

  const generateScrapShipmentSummary = useCallback(() => {
    dispatch(generateShipmentSummary(lot._id, 'scrap'))
      .then(res => {
        dispatch(showSnackbar(successOptions, res.message));
        return history.push(`/lots/list/${lot._id}/manage/shipment-summary`);
      })
      .catch(err => dispatch(showSnackbar(dangerOptions, err.message)));
  }, [lot]);

  const manualEntryRedirect = useCallback(() => {
    history.push(`/lots/list/${lot._id}/enter-assay-results`);
  }, [lot]);

  return (
    <ModalCustom isOpen={open} modalClose={modalClose}>
      <StyledContent>
        <h4>{LOTS.GENERATE_SHIPMENT_SUMMARY}</h4>
        <p>Please select template</p>
        <StyledOptions>
          {isWest ? (
            <>
              <Button onClick={generateScrapShipmentSummary}>
                {LOTS.GENERATE_SHIPMENT_SUMMARY_MODAL_SCRAPS}
              </Button>
              <Button onClick={generateStandardShipmentSummary} outlineColor={cssVar('grayGRG')}>
                {LOTS.GENERATE_SHIPMENT_SUMMARY_MODAL_STANDARD}
              </Button>
            </>
          ) : (
            <>
              <Button onClick={generateStandardShipmentSummary}>
                {LOTS.GENERATE_SHIPMENT_SUMMARY_MODAL_STANDARD}
              </Button>
              <Button onClick={generateScrapShipmentSummary} outlineColor={cssVar('grayGRG')}>
                {LOTS.GENERATE_SHIPMENT_SUMMARY_MODAL_SCRAPS}
              </Button>
            </>
          )}
          <Button onClick={manualEntryRedirect} outlineColor={cssVar('grayGRG')}>
            {LOTS.GENERATE_SHIPMENT_SUMMARY_MODAL_MANUAL}
          </Button>
          <CancelButton onClick={modalClose} id="cancelButton" label={SHARED.CANCEL_BUTTON} />
        </StyledOptions>
      </StyledContent>
    </ModalCustom>
  );
};

GenerateShipmentSummary.propTypes = {
  lot: PropTypes.shape({
    _id: PropTypes.string,
  }).isRequired,
  open: PropTypes.bool,
  modalClose: PropTypes.func,
};
