import { assayOptions } from 'shared/helpers/constants/lots/assayOptions';
import { safeReplace } from 'shared/helpers/parsers/text';

const parseEnterAssayResultsData = (data, relatedLot) => ({
  relatedLot,
  option: data.assayOption.value,
  ...(data.assayOption.value === assayOptions.regular_assay && {
    materialType: data.materialType.value,
    weightGrossReceived: +safeReplace(data.weightGrossReceived, ',', '.'),
    weightTareReceived: +safeReplace(data.weightTareReceived, ',', '.'),
    weightNetDryReceived: +safeReplace(data.weightNetDryReceived, ',', '.'),
    weightScrapReceived: +safeReplace(data.weightScrapReceived, ',', '.'),
    percentMoisture: +safeReplace(data.percentMoisture, ',', '.'),
    platinumAssay: +safeReplace(data.platinumAssay, ',', '.'),
    palladiumAssay: +safeReplace(data.palladiumAssay, ',', '.'),
    rhodiumAssay: +safeReplace(data.rhodiumAssay, ',', '.'),
    platinumContainedOz: +safeReplace(data.platinumContainedOz, ',', '.'),
    palladiumContainedOz: +safeReplace(data.palladiumContainedOz, ',', '.'),
    rhodiumContainedOz: +safeReplace(data.rhodiumContainedOz, ',', '.'),
    platinumReturnableOz: +safeReplace(data.platinumReturnableOz, ',', '.'),
    palladiumReturnableOz: +safeReplace(data.palladiumReturnableOz, ',', '.'),
    rhodiumReturnableOz: +safeReplace(data.rhodiumReturnableOz, ',', '.'),
    carbonPercentage: +safeReplace(data.carbonPercentage, ',', '.'),
    weightNetWetReceived: +safeReplace(data.weightNetWetReceived, ',', '.'),
    weightAdjustedNetWetReceived: +safeReplace(data.weightAdjustedNetWetReceived, ',', '.'),
  }),
  ...([assayOptions.HF_assay, assayOptions.AB_assay].includes(data.assayOption.value) && {
    materialTypeH: data.materialTypeH.value,
    weightGrossReceivedH: +safeReplace(data.weightGrossReceivedH, ',', '.'),
    weightTareReceivedH: +safeReplace(data.weightTareReceivedH, ',', '.'),
    weightNetDryReceivedH: +safeReplace(data.weightNetDryReceivedH, ',', '.'),
    weightScrapReceivedH: +safeReplace(data.weightScrapReceivedH, ',', '.'),
    percentMoistureH: +safeReplace(data.percentMoistureH, ',', '.'),
    platinumAssayH: +safeReplace(data.platinumAssayH, ',', '.'),
    palladiumAssayH: +safeReplace(data.palladiumAssayH, ',', '.'),
    rhodiumAssayH: +safeReplace(data.rhodiumAssayH, ',', '.'),
    platinumContainedOzH: +safeReplace(data.platinumContainedOzH, ',', '.'),
    palladiumContainedOzH: +safeReplace(data.palladiumContainedOzH, ',', '.'),
    rhodiumContainedOzH: +safeReplace(data.rhodiumContainedOzH, ',', '.'),
    platinumReturnableOzH: +safeReplace(data.platinumReturnableOzH, ',', '.'),
    palladiumReturnableOzH: +safeReplace(data.palladiumReturnableOzH, ',', '.'),
    rhodiumReturnableOzH: +safeReplace(data.rhodiumReturnableOzH, ',', '.'),
    weightNetWetReceivedH: +safeReplace(data.weightNetWetReceivedH, ',', '.'),
    weightAdjustedNetWetReceivedH: +safeReplace(data.weightAdjustedNetWetReceivedH, ',', '.'),
    weightGrossReceivedF: +safeReplace(data.weightGrossReceivedF, ',', '.'),
    weightTareReceivedF: +safeReplace(data.weightTareReceivedF, ',', '.'),
    weightNetDryReceivedF: +safeReplace(data.weightNetDryReceivedF, ',', '.'),
    weightScrapReceivedF: +safeReplace(data.weightScrapReceivedF, ',', '.'),
    percentMoistureF: +safeReplace(data.percentMoistureF, ',', '.'),
    platinumAssayF: +safeReplace(data.platinumAssayF, ',', '.'),
    palladiumAssayF: +safeReplace(data.palladiumAssayF, ',', '.'),
    rhodiumAssayF: +safeReplace(data.rhodiumAssayF, ',', '.'),
    platinumContainedOzF: +safeReplace(data.platinumContainedOzF, ',', '.'),
    palladiumContainedOzF: +safeReplace(data.palladiumContainedOzF, ',', '.'),
    rhodiumContainedOzF: +safeReplace(data.rhodiumContainedOzF, ',', '.'),
    platinumReturnableOzF: +safeReplace(data.platinumReturnableOzF, ',', '.'),
    palladiumReturnableOzF: +safeReplace(data.palladiumReturnableOzF, ',', '.'),
    rhodiumReturnableOzF: +safeReplace(data.rhodiumReturnableOzF, ',', '.'),
    carbonPercentageH: +safeReplace(data.carbonPercentageH, ',', '.'),
    carbonPercentageF: +safeReplace(data.carbonPercentageF, ',', '.'),
    weightNetWetReceivedF: +safeReplace(data.weightNetWetReceivedF, ',', '.'),
    weightAdjustedNetWetReceivedF: +safeReplace(data.weightAdjustedNetWetReceivedF, ',', '.'),
  }),
});

export { parseEnterAssayResultsData };
