import styled from 'styled-components';

export const StyledPagesMobile = styled.div``;

export const StyledPagesMobileWrapper = styled.ul`
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
`;

export const StyledPagesMobileArrow = styled.li`
  display: flex;
  align-items: center;

  .disabled {
    background: var(--gullGray);
  }
`;

export const StyledPagesMobilePages = styled.li`
  position: relative;
  padding: 7px 15px;
  border: 2px solid var(--lynch);
  border-radius: 20px;
  background: transparent;
  color: var(--geyser);
  font-size: 0.9em;
  font-weight: 600;
  height: 40px;
  margin: 0 5px;
`;
