import PropTypes from 'prop-types';
import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { generateFinalInvoiceMockup } from 'actions/Lots/generateFinalInvoice';
import {
  getFinalInvoiceMaximumOunces,
  clearFinalInvoiceMaximumOunces,
} from 'actions/Lots/getFinalInvoiceMaximumOunces';
import { getLot } from 'actions/Lots/lotDetails';
import {
  clearLatestMarketIndicators,
  getLatestMarketIndicators,
} from 'actions/MarketIndicators/getLatestMarketIndicators';
import { getMarketIndicators } from 'actions/MarketIndicators/getMarketIndicators';
import { requestUpdateMarketIndicators } from 'actions/MarketIndicators/requestUpdateMarketIndicators';
import { showSnackbar } from 'actions/shared/snackbar';

import { ModalCustom } from 'components/ModalCustom/ModalCustom';

import { Button } from 'shared/components/Buttons/Button/Button';
import { FormContainer } from 'shared/components/Form';
import { Icon } from 'shared/components/Icon/Icon';
import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';
import { requestUpdatedMarketIndicatorsModal } from 'shared/helpers/constants/modalConstants';
import { SHARED } from 'shared/helpers/constants/sharedConstants';
import {
  dangerOptions,
  successOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

import { FinalInvoiceForm } from '../FinalInvoiceForm/FinalInvoiceForm';

const ModalContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 40px 16px 20px;
`;

const Header = styled.h4`
  font-size: 1rem;
  font-weight: bold;
`;

const Paragraph = styled.p`
  font-size: 0.875rem;
  color: var(--gullGray);
  font-weight: 400;
  text-align: center;
  padding-top: 0.5rem;
`;

const ModalButtons = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 10px;
`;

const GenerateFinalInvoiceMockup = ({
  match: {
    params: { id },
  },
  history,
}) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const finalInvoiceMaximumOunces = useSelector(state => state.finalInvoiceMaximumOunces);
  const { lot } = useSelector(state => state.lotDetails);
  const marketIndicatorsPending = useSelector(state => state.getMarketIndicators.isPending);
  const { isPending } = useSelector(state => state.finalInvoiceDetails);
  const latestMarketIndicators = useSelector(
    state => state.getMarketIndicators.latestMarketIndicators,
  );

  useEffect(() => {
    dispatch(getMarketIndicators())
      .then(marketIndicators => dispatch(getLatestMarketIndicators(marketIndicators)))
      .then(
        latestMarketIndicators => latestMarketIndicators?.updateOlderThanOneHour && setIsOpen(true),
      );
    dispatch(getFinalInvoiceMaximumOunces(id));
    dispatch(getLot(id));

    return () => {
      dispatch(clearFinalInvoiceMaximumOunces());
      dispatch(clearLatestMarketIndicators());
    };
  }, [dispatch, id]);

  const handleClickClose = () => {
    setIsOpen(false);
  };

  const handleClickRequest = () => {
    dispatch(requestUpdateMarketIndicators(lot)).then(({ message }) => {
      dispatch(showSnackbar(successOptions, message));
    });
    history.goBack();
  };

  const submit = useCallback(
    async values => {
      try {
        const { message } = await dispatch(generateFinalInvoiceMockup(values, id));
        dispatch(showSnackbar(successOptions, message));
        history.push(`/lots/list/${id}/manage/final-invoice-mockup`);
      } catch ({ message }) {
        dispatch(showSnackbar(dangerOptions, message));
      }
    },
    [dispatch, history, id],
  );

  const { platinumOuncesRemoved, palladiumOuncesRemoved, rhodiumOuncesRemoved } =
    finalInvoiceMaximumOunces.data || {};

  return (
    <>
      <FormContainer
        header={LOTS.GENERATE_FINAL_INVOICE_MOCKUP}
        loaded={
          !isPending && !finalInvoiceMaximumOunces.isPending && finalInvoiceMaximumOunces.data
        }
        focusLockDisabled
      >
        <FinalInvoiceForm
          finalInvoiceMaximumOunces={finalInvoiceMaximumOunces}
          onSubmit={submit}
          mockup
          marketIndicatorsPending={marketIndicatorsPending}
          latestMarketIndicators={latestMarketIndicators}
          initialValues={{
            platinumOuncesRemoved: platinumOuncesRemoved || 0,
            palladiumOuncesRemoved: palladiumOuncesRemoved || 0,
            rhodiumOuncesRemoved: rhodiumOuncesRemoved || 0,
          }}
        />
      </FormContainer>
      <ModalCustom isOpen={isOpen} modalClose={handleClickClose}>
        <ModalContent>
          <Icon icon="warning icon-exclamation" height={65} width={65} />
          <Header>{requestUpdatedMarketIndicatorsModal.message}</Header>
          <Paragraph>{requestUpdatedMarketIndicatorsModal.messageText}</Paragraph>
          <ModalButtons>
            <Button outlineColor="var(--pelorous)" onClick={handleClickRequest}>
              {SHARED.REQUEST_UPDATE_MARKET_INDICATORS}
              <Icon icon="icon-upload" width={11} height={11} size="contain" />
            </Button>
            <Button onClick={handleClickClose}>
              {SHARED.GENERATE_ANYWAY}
              <Icon icon="icon-tick" width={20} height={20} size="contain" />
            </Button>
          </ModalButtons>
        </ModalContent>
      </ModalCustom>
    </>
  );
};

GenerateFinalInvoiceMockup.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  history: PropTypes.object,
};

export { GenerateFinalInvoiceMockup };
