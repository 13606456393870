import { connect } from 'react-redux';

import { getLot } from 'actions/Lots/lotDetails';
import { markGradingComplete } from 'actions/Lots/markGradingComplete';
import { openModal } from 'actions/shared/modal';
import { showSnackbar } from 'actions/shared/snackbar';

import { markGradingCompleteModal } from 'shared/helpers/constants/modalConstants';
import {
  dangerOptions,
  successOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

function MarkGradingCompleteBase(props) {
  const markGradingCompleteAction = () => {
    props.openModal(markGradingCompleteModal, () => {
      props
        .markGradingComplete(props.lot._id)
        .then(res => {
          props.showSnackbar(successOptions, res.message);
          props.onSuccess && props.onSuccess();
        })
        .catch(err => {
          props.getLot(props.lot._id);
          props.showSnackbar(dangerOptions, err.message);
        });
    });
  };

  return props.children({ markGradingComplete: markGradingCompleteAction });
}

const mapDispatchToProps = {
  openModal,
  showSnackbar,
  markGradingComplete,
  getLot,
};

const MarkGradingComplete = connect(null, mapDispatchToProps)(MarkGradingCompleteBase);

export { MarkGradingComplete, MarkGradingCompleteBase };
