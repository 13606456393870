import React, { useCallback } from 'react';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { destroy } from 'redux-form';

import { enterPlacedHedges } from 'actions/Hedges/hedgesCreate';
import { filterCompanies, getLotsToEnterPlacedHedges } from 'actions/Hedges/hedgesManagement';
import {
  getSuggestedDeliveryDate,
  clearSuggestedDeliveryDate,
} from 'actions/Hedges/suggestedDeliveryDate';
import { openModal } from 'actions/shared/modal';
import { showSnackbar } from 'actions/shared/snackbar';

import { FormContainer } from 'shared/components/Form';
import { HEDGES } from 'shared/helpers/constants/hedges/hedgesConstants';
import {
  dangerOptions,
  successOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

import { ConnectedHedgesForm } from '../HedgesForm/HedgesForm';

const EnterPlacedHedgesLayout = props => {
  const { history } = props;
  const dispatch = useDispatch();

  const submit = useCallback(
    ({ hedges }) => {
      const filteredValues = hedges.map(({ materialUnits, materialWeight, ...baseValues }) =>
        materialUnits ? { ...baseValues, materialUnits } : { ...baseValues, materialWeight },
      );

      return dispatch(enterPlacedHedges(filteredValues))
        .then(({ message }) => {
          dispatch(showSnackbar(successOptions, message));
          dispatch(destroy('HedgesForm'));
          history.push('/hedges');
        })
        .catch(({ message }) => dispatch(showSnackbar(dangerOptions, message)));
    },
    [history, dispatch],
  );

  return (
    <FormContainer header={HEDGES.ADD_EXISTING_HEDGE}>
      <ConnectedHedgesForm {...props} enterPlacedHedges onSubmit={submit} />
    </FormContainer>
  );
};

const mapStateToProps = state => {
  const form = state.form[HEDGES.HEDGES_FORM];
  const mappedValues =
    form && form.values && form.values.hedges
      ? form.values.hedges.map(hedge => ({
          ...hedge,
          hedgeType: hedge.hedgeType && hedge.hedgeType.value,
          materialType: hedge.materialType && hedge.materialType.value,
        }))
      : [];

  return {
    auth: state.auth,
    modal: state.modal,
    hedgesManagement: state.hedgesManagement,
    hedgesValues: { hedges: mappedValues },
    lots: state.hedgesManagement && state.hedgesManagement.lots,
    suggestedDeliveryDate: state.suggestedDeliveryDate,
    createHedges: state.createHedges,
  };
};

const mapDispatchToProps = {
  clearSuggestedDeliveryDate,
  getSuggestedDeliveryDate,
  getLotsToEnterPlacedHedges,
  filterCompanies,
  openModal,
};

const EnterPlacedHedges = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(EnterPlacedHedgesLayout);

export { EnterPlacedHedges, EnterPlacedHedgesLayout };
