import { statusesForGlobal } from 'shared/helpers/constants/lots/statusesForGlobal';
import { packageClasses } from 'shared/helpers/constants/packages/packageClasses';

const releaseMillPackagesAccess = (
  {
    access: {
      packages: { releasePackage },
    },
  },
  lot,
) =>
  releasePackage &&
  lot.packages &&
  lot.statusForGlobal === statusesForGlobal.accepted_for_mixing &&
  lot.packages.filter(p => p.packageClass === packageClasses.post_mill && !p.releasedAt).length;

export { releaseMillPackagesAccess };
