import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { memo, useContext } from 'react';
import styled from 'styled-components';

import { Button } from 'shared/components/Buttons/Button/Button.jsx';
import { CancelButton } from 'shared/components/Buttons/CancelButton/CancelButton';
import { SubmitButton } from 'shared/components/Buttons/SubmitButton/SubmitButton';
import { WizardContext } from 'shared/components/Formik/Wizard/Wizard.jsx';
import { Icon } from 'shared/components/Icon/Icon.jsx';
import { WIZARD } from 'shared/components/Wizard/constants.js';
import { SHARED } from 'shared/helpers/constants/sharedConstants';
import { media } from 'shared/helpers/styling/styling';

const CustomActions = memo(({ className, onCancel, submitDisabled, stepErrors }) => {
  const { next, prev, isFirst, isLast, currentStep } = useContext(WizardContext);
  const { dirty } = useFormikContext();

  const isNextDisabled = !dirty || !!stepErrors[currentStep - 1].filter(Boolean).length;

  return (
    <div className={className}>
      <span>
        {isLast ? (
          <SubmitButton
            label={isLast ? SHARED.SUBMIT : WIZARD.NEXT_STEP}
            disabled={submitDisabled}
          />
        ) : (
          <Button onClick={next} disabled={isNextDisabled}>
            {WIZARD.NEXT_STEP}
            <Icon icon="icon-arrow-right" />
          </Button>
        )}

        {!isFirst && (
          <Button onClick={prev}>
            <Icon icon="icon-arrow-left" />
            {WIZARD.PREVIOUS_STEP}
          </Button>
        )}
      </span>
      <span>
        <CancelButton onClick={onCancel} />
      </span>
    </div>
  );
});

export const CustomActionsStyled = styled(CustomActions)`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin: 65px 0 25px 0;

  span {
    display: flex;
    flex-direction: row-reverse;

    button:first-child {
      margin-left: 30px;
    }

    &:nth-child(2) {
      button:only-child {
        margin-left: 0;
      }
    }

    ${media.phone`
      flex-direction: column-reverse;

      button:first-child {
        margin: 0;
      }

      button:nth-child(2) {
        margin: 10px 0;
      }
    `}
  }

  ${media.phone`
    margin: 65px 15px 25px 15px
  `}
`;

CustomActions.propTypes = {
  className: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  submitDisabled: PropTypes.bool.isRequired,
  wizardContext: PropTypes.object.isRequired,
  stepErrors: PropTypes.array.isRequired,
};

export { CustomActionsStyled as CustomActions };
