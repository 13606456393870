import axios from 'axios';
import { saveAs } from 'file-saver';

import { showSnackbar } from 'actions/shared/snackbar';

import { PACKAGES } from 'shared/helpers/constants/packages/packageConstants';
import { messageTypes } from 'shared/helpers/constants/snackbar/snackbarMessageTypes';
import {
  dangerOptions,
  successOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

const API_HOST_AUTH = process.env.REACT_APP_API;

const printLabPolicyActionType = 'PRINT_LAB_POLICY';
const printLabPolicyAction = () => ({
  type: printLabPolicyActionType,
});

const printLabPolicySuccessActionType = `${printLabPolicyActionType}_SUCCESS`;
const printLabPolicySuccess = () => ({
  type: printLabPolicySuccessActionType,
});

const printLabPolicyFailureActionType = `${printLabPolicyActionType}_FAILURE`;
const printLabPolicyFailure = () => ({
  type: printLabPolicyFailureActionType,
});

const printLabPolicy = () => dispatch => {
  dispatch(printLabPolicyAction());

  return axios
    .get(`${API_HOST_AUTH}/packages/print/lab-policy`, {
      responseType: 'arraybuffer',
    })
    .then(res => {
      dispatch(printLabPolicySuccess());
      saveAs(new Blob([res.data], { type: res.headers['content-type'] }), 'LaboratoryPolicy.pdf');
      dispatch(showSnackbar(successOptions, PACKAGES.PRINT_LAB_REQUEST_FORMS_SUCCESS));

      return {
        message: PACKAGES.PRINT_LAB_REQUEST_FORMS_SUCCESS,
        messageType: messageTypes.success,
      };
    })
    .catch(() => {
      dispatch(printLabPolicyFailure());
      dispatch(showSnackbar(dangerOptions, PACKAGES.PRINT_LAB_REQUEST_FORMS_ERROR));

      return Promise.reject({
        message: PACKAGES.PRINT_LAB_REQUEST_FORMS_ERROR,
        messageType: messageTypes.error,
      });
    });
};

export {
  printLabPolicy,
  printLabPolicyAction,
  printLabPolicySuccess,
  printLabPolicyFailure,
  printLabPolicyActionType,
  printLabPolicySuccessActionType,
  printLabPolicyFailureActionType,
};
