import React from 'react';
import { connect } from 'react-redux';

import { changeOwnPassword } from 'actions/Users/changeOwnPassword';
import { showSnackbar } from 'actions/shared/snackbar';

import { CHANGE_PASSWORD } from 'shared/helpers/constants/changePasswordConstants';
import {
  dangerOptions,
  successOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { USER } from 'shared/helpers/constants/users/userConstants';

import { goBackOrTo } from 'utils/history';

import { ResetPasswordForm } from 'pages/ResetPassword/components/ResetPasswordFormPortal/ResetPasswordForm';

const ChangeOwnPasswordBase = props => {
  const submit = ({ oldPassword, newPassword }) =>
    props
      .changeOwnPassword(oldPassword, newPassword)
      .then(res => {
        props.showSnackbar(successOptions, res.message);
        props.history.push('/my-profile');
      })
      .catch(error => {
        props.showSnackbar(dangerOptions, `${USER.PASSWORD_NOT_CHANGED} ${error.message}`);
      });

  const onCancel = () => goBackOrTo('/my-profile');

  return (
    <ResetPasswordForm
      header={CHANGE_PASSWORD.CHANGE_YOUR_PASSWORD}
      onSubmit={submit}
      onCancel={onCancel}
      own
    />
  );
};

const mapDispatchToProps = {
  changeOwnPassword,
  showSnackbar,
};

const ChangeOwnPassword = connect(null, mapDispatchToProps)(ChangeOwnPasswordBase);

export { ChangeOwnPasswordBase, ChangeOwnPassword };
