import { statusesForGlobal } from 'shared/helpers/constants/lots/statusesForGlobal';
import { createMultipleFilesFormData } from 'shared/helpers/dataParsers/files/createMultipleFilesFormData';
import { dateToTimestamp } from 'shared/helpers/parsers/date';
import { removeCommasAndParse } from 'shared/helpers/parsers/text';

import { parseLotUpdateFormData } from './parseLotUpdateFormData';

function parseLotUpdateData(lot, files, lotStatus, existingLotFiles) {
  const {
    countSheet,
    gradingReceipt,
    stillwaterShipmentSummary,
    stillwaterFinalInvoice,
    ...lotFields
  } = lot;
  const parsedLot = parseLotUpdateFormData(lotFields);
  const data = createMultipleFilesFormData({ files, existingLotFiles });

  data.append(
    'stillwaterFinalPayment',
    String(lot.stillwaterFinalPayment) && lot.stillwaterFinalPayment !== null
      ? removeCommasAndParse(lot.stillwaterFinalPayment)
      : '',
  );
  data.append('stillwaterFinalPaymentDate', dateToTimestamp(lot.stillwaterFinalPaymentDate) || '');

  if (lotStatus !== statusesForGlobal.finalized) {
    data.append(
      'stillwaterInitialPayment',
      String(lot.stillwaterInitialPayment) && lot.stillwaterInitialPayment !== null
        ? removeCommasAndParse(lot.stillwaterInitialPayment)
        : '',
    );

    data.append(
      'stillwaterInitialPaymentDate',
      dateToTimestamp(lot.stillwaterInitialPaymentDate) || '',
    );

    Object.keys(parsedLot).forEach(field => {
      if (Array.isArray(parsedLot[field])) {
        return data.append(field, JSON.stringify(parsedLot[field]));
      } else if (parsedLot[field] !== undefined && parsedLot[field] !== null) {
        return data.append(field, parsedLot[field]);
      }
    });
  }

  return data;
}

export { parseLotUpdateData };
