import PropTypes from 'prop-types';
import * as React from 'react';
import styled from 'styled-components';

import { ModalCustom } from 'components/ModalCustom/ModalCustom';

import { Button } from 'shared/components/Buttons/Button/Button';
import { Icon } from 'shared/components/Icon/Icon';
import { editMorePackagesModal } from 'shared/helpers/constants/modalConstants';
import { SHARED } from 'shared/helpers/constants/sharedConstants';

const ModalContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 6px 16px 20px;
`;

const Header = styled.h4`
  font-size: 1rem;
  font-weight: bold;
`;

const Paragraph = styled.p`
  font-size: 0.875rem;
  color: var(--gullGray);
  font-weight: 400;
  text-align: center;
  padding-top: 0.5rem;
`;

const ModalButtons = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px 15px 0;
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

const ModalEditMorePackages = ({
  handleToggleModal,
  handleClickEditMorePackages,
  handleClickUpdateOnePackage,
  isModalOpen,
  isPending,
}) => {
  return (
    <ModalCustom isOpen={isModalOpen} modalClose={handleToggleModal}>
      <ModalContent>
        <Icon icon="warning icon-exclamation" height={65} width={65} />
        <Header>{editMorePackagesModal.message}</Header>
        <Paragraph>{editMorePackagesModal.messageText}</Paragraph>
        <ModalButtons>
          <Button
            outlineColor="var(--regentGrayLighter)"
            disabled={isPending}
            onClick={handleClickEditMorePackages}
          >
            {editMorePackagesModal.editMoreButton}
            <Icon icon="icon-edit" width={13} height={13} size="contain" />
          </Button>
          <Button onClick={handleClickUpdateOnePackage} disabled={isPending}>
            {editMorePackagesModal.saveButton}
            <Icon icon="icon-tick" width={20} height={20} size="contain" />
          </Button>
        </ModalButtons>
        <ModalButtons>
          <StyledButton outlineColor="var(--roman)" onClick={handleToggleModal}>
            {SHARED.CLOSE}
            <Icon icon="icon-cancel" width={13} height={13} size="contain" />
          </StyledButton>
        </ModalButtons>
      </ModalContent>
    </ModalCustom>
  );
};

ModalEditMorePackages.propTypes = {
  handleToggleModal: PropTypes.func,
  handleClickEditMorePackages: PropTypes.func,
  handleClickUpdateOnePackage: PropTypes.func,
  isModalOpen: PropTypes.bool,
  isPending: PropTypes.bool,
};

export { ModalEditMorePackages };
