import {
  getCompaniesActionType,
  getCompaniesSuccessActionType,
  getCompaniesFailureActionType,
  clearCompaniesListActionType,
} from 'actions/Companies/companiesList';

const INITIAL_STATE = {
  isPending: false,
  errorMessage: null,
  companies: [],
  limit: 50,
  page: 1,
  pages: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getCompaniesActionType:
      return {
        ...state,
        isPending: true,
      };
    case getCompaniesSuccessActionType:
      return {
        ...state,
        isPending: false,
        companies: action.payload.companies,
        pages: action.payload.pages,
        limit: action.payload.limit,
        page: action.payload.page,
      };
    case getCompaniesFailureActionType:
      return {
        ...state,
        isPending: false,
        errorMessage: action.payload.errorMessage,
        companies: [],
        pages: null,
      };
    case clearCompaniesListActionType:
      return {
        isPending: false,
        errorMessage: null,
        companies: [],
        limit: 50,
        page: 1,
        pages: null,
      };
    default:
      return state;
  }
};
