import {
  getPriceFeedType,
  getPriceFeedSuccessType,
  getPriceFeedFailureType,
  clearPriceFeedType,
} from 'actions/PriceFeeds/getPriceFeed';

const INITIAL_STATE = {
  isPending: false,
  priceFeed: null,
};

const getPriceFeedReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getPriceFeedType:
      return {
        ...state,
        isPending: true,
      };
    case getPriceFeedSuccessType:
      return {
        isPending: false,
        priceFeed: action.payload.priceFeed,
      };
    case clearPriceFeedType:
    case getPriceFeedFailureType:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};

export { getPriceFeedReducer };
