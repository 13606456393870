import {
  createCalendarNoteActionType,
  createCalendarNoteSuccessActionType,
  createCalendarNoteFailureActionType,
  clearCreatedCalendarNoteActionType,
} from 'actions/Shipments/createCalendarNote';

const INITIAL_STATE = {
  isPending: false,
  createdNote: null,
};

const createCalendarNoteReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case createCalendarNoteActionType:
      return {
        ...state,
        isPending: true,
      };
    case createCalendarNoteSuccessActionType:
      return {
        createdNote: action.payload.data,
        isPending: false,
      };
    case createCalendarNoteFailureActionType:
      return {
        ...state,
        isPending: false,
      };
    case clearCreatedCalendarNoteActionType:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};

export { createCalendarNoteReducer };
