import {
  createShipmentActionType,
  createShipmentSuccessActionType,
  createShipmentFailureActionType,
} from 'actions/Shipments/createShipment';
import {
  clearTemporaryDataSuccessActionType,
  saveTemporaryDataSuccessActionType,
} from 'actions/Shipments/saveTemporaryData';

const INITIAL_STATE = {
  isPending: false,
  temporaryData: {},
};

const CreateShipmentReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case createShipmentActionType:
      return {
        ...state,
        isPending: true,
      };
    case saveTemporaryDataSuccessActionType:
      return {
        ...state,
        temporaryData: {
          ...state.temporaryData,
          ...action?.payload,
        },
      };
    case clearTemporaryDataSuccessActionType: {
      return {
        ...state,
        temporaryData: {
          ...state.temporaryData,
          [action?.payload]: null,
        },
      };
    }
    case createShipmentFailureActionType:
      return {
        ...state,
        isPending: false,
      };
    case createShipmentSuccessActionType:
      return {
        isPending: false,
      };
    default:
      return state;
  }
};

export { CreateShipmentReducer };
