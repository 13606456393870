import {
  getCountWarehouseDataActionType,
  getCountWarehouseDataSuccessActionType,
  getCountWarehouseDataFailureActionType,
} from 'actions/Lots/getWarehouseDashboardCountData';

const INITIAL_STATE = {
  isPending: false,
  countWarehouseLots: null,
};

const warehouseDashboardCountDataReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getCountWarehouseDataActionType:
      return {
        ...state,
        isPending: true,
      };
    case getCountWarehouseDataSuccessActionType:
      return {
        countWarehouseLots: action.payload.data,
        isPending: false,
      };
    case getCountWarehouseDataFailureActionType:
      return {
        ...state,
        isPending: false,
      };
    default:
      return state;
  }
};

export { warehouseDashboardCountDataReducer };
