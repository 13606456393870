import {
  getCompanyLocationsActionType,
  getCompanyLocationsFailureActionType,
  getCompanyLocationsSuccessActionType,
  clearCompanyLocationsActionType,
} from 'actions/Locations/getCompanyLocations';

const INITIAL_STATE = {
  isPending: false,
  data: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getCompanyLocationsActionType:
      return {
        ...state,
        isPending: true,
      };
    case getCompanyLocationsFailureActionType:
      return {
        data: [],
        isPending: false,
      };
    case getCompanyLocationsSuccessActionType:
      return {
        data: action.payload.response,
        isPending: false,
      };
    case clearCompanyLocationsActionType:
      return {
        ...state,
        data: [],
      };
    default:
      return state;
  }
};
