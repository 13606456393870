import { DetailsDateField } from 'shared/components/Details/DetailsDateField';
import { DetailsDownloadField } from 'shared/components/Details/DetailsDownloadFieldRedesign';
import { DetailsField } from 'shared/components/Details/DetailsField';
import { DetailsHumanizedField } from 'shared/components/Details/DetailsHumanizedField';
import { DetailsRadioField } from 'shared/components/Details/DetailsRadioFieldRedesign';
import {
  globalFilesAccess,
  stillwaterFilesAccess,
  hedgeOrderDocumentsAccess,
  poolSettlementDocumentAccess,
} from 'shared/helpers/accesses/lots/customerFilesAccess';
import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';
import { checkSentFromCompany } from 'shared/helpers/matchers/lots/checkSentFromCompany';
import { formatToUS } from 'shared/helpers/parsers/formaters';

const lotDetailsFieldsConfig = (lot, downloadAttachment) => [
  {
    label: LOTS.SHORTENED_SENT_FROM_COMPANY_LOCATION,
    value: lot.sentFromCompanyLocation.name,
    icon: 'icon-home',
    id: 'sentFromCompanyLocation',
    FieldType: DetailsField,
  },
  {
    label: LOTS.STATUS,
    value: lot.statusForCustomer,
    icon: 'icon-type',
    id: 'statusForCustomer',
    FieldType: DetailsHumanizedField,
  },
  {
    label: LOTS.PROCESS_UPON_DELIVERY,
    value: lot.processUponDelivery,
    icon: 'icon-process',
    id: 'processUponDelivery',
    FieldType: DetailsRadioField,
  },
  {
    label: LOTS.HEDGED_STATUS,
    value: lot.hedgedStatus,
    icon: 'icon-type',
    id: 'hedgedStatus',
    FieldType: DetailsHumanizedField,
  },
  {
    label: LOTS.HEDGED_LOT_DELIVERY_DEADLINE,
    value: lot.hedgedLotDeliveryDeadline,
    id: 'hedgedLotDeliveryDeadline',
    FieldType: DetailsDateField,
  },
  {
    label: LOTS.RECEIVED_ON,
    value: lot.receivedAt,
    id: 'receivedAt',
    FieldType: DetailsDateField,
  },
  {
    label: LOTS.CUSTOMER_INSTRUCTIONS,
    value: lot.customerInstructions,
    icon: 'icon-document',
    id: 'customerInstructions',
    FieldType: DetailsField,
  },
  lot.sentFromCompany.materialCountRequired && {
    label: LOTS.COUNT_SHEET,
    value: lot.countSheet,
    id: 'countSheet',
    onClick: () => downloadAttachment(lot.countSheet.path),
    FieldType: DetailsDownloadField,
  },
  {
    label: LOTS.BILL_OF_LADING,
    value: lot.billOfLading,
    id: 'billOfLading',
    onClick: index => downloadAttachment(lot.billOfLading[index].path),
    FieldType: DetailsDownloadField,
    generateName: index => `${LOTS.BILL_OF_LADING_SHORT}-${++index}`,
  },
  ...(poolSettlementDocumentAccess(lot)
    ? [
        {
          label: LOTS.POOL_SETTLEMENT_DOCUMENT,
          value: lot.poolSettlementDocument,
          id: 'poolSettlementDocument',
          onClick: index => downloadAttachment(lot.poolSettlementDocument[index].path),
          generateName: (index, files) => files[index].originalName,
          FieldType: DetailsDownloadField,
        },
      ]
    : []),
  ...(globalFilesAccess(lot)
    ? [
        {
          label: LOTS.GRG_SHIPMENT_SUMMARY,
          value: lot.grgShipmentSummary,
          id: 'shipmentSummary',
          onClick: () => downloadAttachment(lot.grgShipmentSummary.path),
          FieldType: DetailsDownloadField,
        },
        {
          label: LOTS.GRG_FINAL_INVOICE,
          value: lot.grgFinalInvoice,
          id: 'finalInvoice',
          onClick: () => downloadAttachment(lot.grgFinalInvoice.path),
          FieldType: DetailsDownloadField,
        },
      ]
    : []),
  ...(stillwaterFilesAccess(lot)
    ? [
        {
          label: LOTS.STILLWATER_SHIPMENT_SUMMARY,
          value: lot.stillwaterShipmentSummary,
          id: 'shipmentSummary',
          onClick: () => downloadAttachment(lot.stillwaterShipmentSummary.path),
          FieldType: DetailsDownloadField,
        },
        {
          label: LOTS.STILLWATER_FINAL_INVOICE,
          value: lot.stillwaterFinalInvoice,
          id: 'finalInvoice',
          onClick: () => downloadAttachment(lot.stillwaterFinalInvoice.path),
          FieldType: DetailsDownloadField,
        },
      ]
    : []),
  ...(hedgeOrderDocumentsAccess(lot)
    ? [
        {
          label: LOTS.HEDGE_ORDER_DOCUMENT,
          value: lot.hedgeOrderDocuments,
          id: 'hedgeOrderDocument',
          onClick: index => downloadAttachment(lot.hedgeOrderDocuments[index].path),
          generateName: (index, files) => files[index].hedgeName,
          FieldType: DetailsDownloadField,
        },
      ]
    : []),
];

const lotDetailsInternalCompanyFieldsConfig = lot => [
  {
    label: LOTS.SHORTENED_SENT_FROM_COMPANY_LOCATION,
    value: lot.sentFromCompanyLocation.name,
    icon: 'icon-home',
    id: 'sentFromCompanyLocation',
    FieldType: DetailsField,
  },
  {
    label: LOTS.STATUS,
    value: lot.statusForGlobal,
    icon: 'icon-type',
    id: 'statusForGlobal',
    FieldType: DetailsHumanizedField,
  },
  checkSentFromCompany(lot.sentFromCompany) && {
    label: LOTS.PROCESS_UPON_DELIVERY,
    value: lot.processUponDelivery,
    icon: 'icon-process',
    id: 'processUponDelivery',
    FieldType: DetailsRadioField,
  },
  {
    label: LOTS.MATERIAL_TYPE,
    value: lot.materialType,
    icon: 'icon-cart',
    id: 'materialType',
    FieldType: DetailsHumanizedField,
  },
  {
    label: LOTS.HEDGED_STATUS,
    value: lot.hedgedStatus,
    icon: 'icon-type',
    id: 'hedgedStatus',
    FieldType: DetailsHumanizedField,
  },
  {
    label: LOTS.HEDGED_LOT_DELIVERY_DEADLINE,
    value: lot.hedgedLotDeliveryDeadline,
    id: 'hedgedLotDeliveryDeadline',
    FieldType: DetailsDateField,
  },
  {
    label: LOTS.CUSTOMER_INSTRUCTIONS,
    value: lot.customerInstructions,
    icon: 'icon-document',
    id: 'customerInstructions',
    FieldType: DetailsField,
  },
];

const weightFieldsConfig = lot => [
  {
    label: LOTS.WEIGHT_GROSS_DECLARED,
    value: formatToUS(lot.weightGrossDeclared),
    icon: 'icon-volume',
    id: 'weightGrossDeclared',
    suffix: LOTS.LBS,
    FieldType: DetailsField,
  },
  {
    label: LOTS.WEIGHT_TARE_DECLARED,
    value: formatToUS(lot.weightTareDeclared),
    icon: 'icon-volume',
    id: 'weightTareDeclared',
    suffix: LOTS.LBS,
    FieldType: DetailsField,
  },
  {
    label: LOTS.WEIGHT_NET_DECLARED,
    value: formatToUS(lot.weightNetDeclared),
    icon: 'icon-volume',
    id: 'weightNetDeclared',
    suffix: LOTS.LBS,
    FieldType: DetailsField,
  },
];

const lotDetailsContainerConfig = lot => ({
  header: LOTS.GENERAL_DETAILS,
  template: [
    'sentFromCompanyLocation sentFromCompanyLocation statusForCustomer processUponDelivery',
    lot.sentFromCompany.materialCountRequired
      ? 'receivedAt hedgedStatus hedgedLotDeliveryDeadline countSheet'
      : 'receivedAt hedgedStatus hedgedLotDeliveryDeadline hedgedLotDeliveryDeadline',
    stillwaterFilesAccess(lot) || globalFilesAccess(lot)
      ? 'shipmentSummary finalInvoice customerInstructions customerInstructions'
      : 'customerInstructions customerInstructions customerInstructions customerInstructions',
    hedgeOrderDocumentsAccess(lot)
      ? 'hedgeOrderDocument hedgeOrderDocument hedgeOrderDocument hedgeOrderDocument'
      : '. . . .',
    'billOfLading billOfLading billOfLading billOfLading',
    'poolSettlementDocument poolSettlementDocument poolSettlementDocument poolSettlementDocument',
  ],
});

const lotDetailsInternalCompanyContainerConfig = () => ({
  header: LOTS.GENERAL_DETAILS,
  template: [
    'sentFromCompanyLocation sentFromCompanyLocation statusForGlobal statusForGlobal',
    'materialType processUponDelivery hedgedStatus hedgedLotDeliveryDeadline',
    'customerInstructions customerInstructions customerInstructions customerInstructions',
  ],
});

const weightContainerConfig = () => ({
  header: LOTS.WEIGHT,
  template: [
    'weightGrossDeclared weightGrossDeclared weightTareDeclared weightTareDeclared',
    'weightNetDeclared weightNetDeclared weightNetDeclared weightNetDeclared',
  ],
});

export {
  lotDetailsFieldsConfig,
  weightFieldsConfig,
  lotDetailsContainerConfig,
  weightContainerConfig,
  lotDetailsInternalCompanyFieldsConfig,
  lotDetailsInternalCompanyContainerConfig,
};
