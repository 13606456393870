import store from 'store';

import { showSessionSnackbar, hideSessionSnackbar } from 'actions/shared/sessionSnackbar';

import { getStaleTime } from 'utils/getStaleTime';

let intervalHandle = 0;

const setSessionExpirationInfo = () => {
  const { REACT_APP_SESSION_EXPIRATION_WARNING_TIME } = process.env;

  const { auth } = store.getState();

  const staleTime = getStaleTime(auth?.user) + Date.now();

  const getExpirationWarningTimeInMs = () => +REACT_APP_SESSION_EXPIRATION_WARNING_TIME * 60000;

  const expirationWarningTime = getExpirationWarningTimeInMs() + Date.now();

  const showSessionModal = () => {
    const remainingTime = staleTime - expirationWarningTime;
    store.dispatch(showSessionSnackbar({ remainingTime }));
  };

  clearInterval(intervalHandle);
  store.dispatch(hideSessionSnackbar());

  if (auth && auth.user) {
    intervalHandle = setInterval(() => {
      if (expirationWarningTime < Date.now()) {
        clearInterval(intervalHandle);
        showSessionModal();
      }
    }, 1000);
  }
};

export { setSessionExpirationInfo };
