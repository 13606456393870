import styled from 'styled-components';

export const StyledGhostIcon = styled.i`
  &.green {
    background-color: var(--atlantis);
  }

  &.grey {
    background-color: var(--fiord);
  }

  &.pink {
    background-color: var(--amethyst);
  }

  &.small {
    width: 130px;
    height: 130px;
  }

  &.medium {
    width: 145px;
    height: 145px;
  }

  &.large {
    width: 235px;
    height: 235px;
  }
`;
