import {
  changeDeliveryDateType,
  changeDeliveryDateSuccessType,
  changeDeliveryDateFailureType,
} from 'actions/Lots/changeDeliveryDate';

const INITIAL_STATE = {
  isPending: false,
};

const changeDeliveryDateReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case changeDeliveryDateType:
      return {
        isPending: true,
      };
    case changeDeliveryDateSuccessType:
    case changeDeliveryDateFailureType:
      return {
        isPending: false,
      };
    default:
      return state;
  }
};

export { changeDeliveryDateReducer };
