import { resolveFilterDate, resolveFilterDateEnd } from 'shared/helpers/parsers/dateFilterParser';

function parsePaymentsFilters(filters) {
  return {
    ...filters,
    paidAtStart: resolveFilterDate(filters.paidAtStart),
    paidAtEnd: resolveFilterDateEnd(filters.paidAtEnd),
  };
}

export { parsePaymentsFilters };
