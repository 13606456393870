import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getSpecialPermissionsLogsActionType = 'GET_SPECIAL_PERMISSIONS_LOGS';
const getSpecialPermissionsLogsAction = () => ({
  type: getSpecialPermissionsLogsActionType,
});

const getSpecialPermissionsLogsSuccessActionType = `${getSpecialPermissionsLogsActionType}_SUCCESS`;
const getSpecialPermissionsLogsSuccessAction = specialPermissions => ({
  type: getSpecialPermissionsLogsSuccessActionType,
  payload: specialPermissions,
});

const getSpecialPermissionsLogsFailureActionType = `${getSpecialPermissionsLogsActionType}_FAILURE`;
const getSpecialPermissionsLogsFailureAction = () => ({
  type: getSpecialPermissionsLogsFailureActionType,
});

const getSpecialPermissionsLogs =
  ({ limit, page }) =>
  dispatch => {
    dispatch(getSpecialPermissionsLogsAction());

    return axios
      .get(`${API_HOST_AUTH}/special-permissions-logs`, {
        params: {
          page,
          limit,
        },
      })
      .then(({ data }) => {
        dispatch(getSpecialPermissionsLogsSuccessAction(data));
        return {
          specialPermissionsLogs: data,
        };
      })
      .catch(err => {
        dispatch(getSpecialPermissionsLogsFailureAction());

        return Promise.reject({
          message: err.data.message,
        });
      });
  };

export {
  getSpecialPermissionsLogs,
  getSpecialPermissionsLogsActionType,
  getSpecialPermissionsLogsSuccessActionType,
  getSpecialPermissionsLogsFailureActionType,
};
