import axios from 'axios';

import { showSnackbar } from 'actions/shared/snackbar';

import {
  dangerOptions,
  successOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { USER } from 'shared/helpers/constants/users/userConstants';

const API_HOST_AUTH = process.env.REACT_APP_API;

const inviteUserActionType = 'INVITE_USER';
const inviteUserAction = () => ({
  type: inviteUserActionType,
});

const inviteUserSuccessActionType = `${inviteUserActionType}_SUCCESS`;
const inviteUserSuccessAction = () => ({
  type: inviteUserSuccessActionType,
});

const inviteUserFailureActionType = `${inviteUserActionType}_FAILURE`;
const inviteUserFailureAction = () => ({
  type: inviteUserFailureActionType,
});

const inviteUser = id => dispatch => {
  dispatch(inviteUserAction());

  return axios
    .post(`${API_HOST_AUTH}/users/${id}/invite`)
    .then(res => {
      dispatch(inviteUserSuccessAction());
      dispatch(showSnackbar(successOptions, res.data.message));
    })
    .catch(error => {
      dispatch(inviteUserFailureAction());
      dispatch(showSnackbar(dangerOptions, `${USER.NOT_INVITED} ${error.data.message}`));
    });
};

export {
  inviteUserActionType,
  inviteUserSuccessActionType,
  inviteUserFailureActionType,
  inviteUser,
};
