import { dateToTimestamp } from 'shared/helpers/parsers/date';

function parseOutgoingShipmentData(shipment) {
  return {
    assignedLot: shipment.assignedLot.map(lot => lot.value),
    shipmentCompany: shipment.shipmentCompany || null,
    shipmentSeal: shipment.shipmentSeal || null,
    loadNumber: shipment.loadNumber || null,
    estPickupDate: dateToTimestamp(shipment.estPickupDate),
    estDeliveryDate: dateToTimestamp(shipment.estDeliveryDate),
  };
}

export { parseOutgoingShipmentData };
