import axios from 'axios';

import { SHIPMENTS } from 'shared/helpers/constants/shipments/shipmentsConstants';
import { messageTypes } from 'shared/helpers/constants/snackbar/snackbarMessageTypes';

const API_HOST_AUTH = process.env.REACT_APP_API;

const deleteShipmentActionType = 'DELETE_SHIPMENT';
function deleteShipmentAction() {
  return {
    type: deleteShipmentActionType,
  };
}

const deleteShipmentSuccessActionType = `${deleteShipmentActionType}_SUCCESS`;
function deleteShipmentSuccessAction(pkg) {
  return {
    type: deleteShipmentSuccessActionType,
    payload: {
      pkg,
    },
  };
}

const deleteShipmentFailureActionType = `${deleteShipmentActionType}_FAILURE`;
function deleteShipmentFailureAction() {
  return {
    type: deleteShipmentFailureActionType,
  };
}

function deleteShipment(id) {
  return dispatch => {
    dispatch(deleteShipmentAction());

    return axios
      .delete(`${API_HOST_AUTH}/shipments/${id}`)
      .then(response => {
        dispatch(deleteShipmentSuccessAction(response.data));
        return {
          message: response.data.message,
          messageType: messageTypes.success,
        };
      })
      .catch(error => {
        dispatch(deleteShipmentFailureAction());
        return {
          message: `${SHIPMENTS.ERROR.DELETE_SHIPMENT_ERROR} ${error.data.message}`,
          messageType: messageTypes.error,
        };
      });
  };
}

export {
  deleteShipmentActionType,
  deleteShipmentAction,
  deleteShipmentSuccessActionType,
  deleteShipmentSuccessAction,
  deleteShipmentFailureActionType,
  deleteShipmentFailureAction,
  deleteShipment,
};
