import React from 'react';

import { ConnectedHedgesList } from '../HedgesList/ConnectedHedgesList';
import { MarketHolidayAlert } from '../MarketHolidayAlert/MarketHolidayAlert';

const HedgesOverview = () => (
  <>
    <MarketHolidayAlert />
    <ConnectedHedgesList />
  </>
);

export { HedgesOverview };
