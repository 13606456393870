import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { downloadAttachment } from 'actions/Attachments/downloadAttachment';

import { renderDetailsContainer } from 'shared/components/Details/helpers/renderDetailsContainer';

import {
  lotDetailsContainerConfig,
  lotDetailsFieldsConfig,
  weightContainerConfig,
  weightFieldsConfig,
} from '../config/fieldsConfig';

export const CustomerView = ({ lot, auth }) => {
  const dispatch = useDispatch();

  const handleDownloadAttachment = path => {
    dispatch(downloadAttachment(path));
  };

  return (
    <>
      {renderDetailsContainer(
        lotDetailsContainerConfig,
        lotDetailsFieldsConfig,
        lot,
        handleDownloadAttachment,
        auth,
      )}
      {renderDetailsContainer(weightContainerConfig, weightFieldsConfig, lot)}
    </>
  );
};

CustomerView.propTypes = {
  auth: PropTypes.object,
  lot: PropTypes.object,
};
