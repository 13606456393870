import { filterCompaniesActionType } from 'actions/Companies/filterCompanies';

const INITIAL_STATE = {
  filteredCompanies: ['active'],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case filterCompaniesActionType:
      return {
        filteredCompanies: action.payload.filteredCompanies,
      };
    default:
      return state;
  }
};
