import PropTypes from 'prop-types';
import { useRef, useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import {
  printSingleLabRequestForm,
  printLabRequestFormsAction,
  printLabRequestFormSuccess,
  printLabRequestFormFailure,
} from 'actions/Packages/printLabRequestForms';

import { StyledIframe } from './StyledPrintLabRequestForm';

const API_HOST_AUTH = process.env.REACT_APP_API;

const PrintLabRequestForm = ({ children, packageId }) => {
  const iframeRef = useRef(null);
  const path = `/packages/print/lab-request-form/${packageId}`;
  const [print, setPrint] = useState(false);
  const dispatch = useDispatch();

  const printLabRequestForm = useCallback(() => {
    if (iframeRef.current.contentDocument && print) {
      setTimeout(() => iframeRef.current.contentWindow.print());
      dispatch(printLabRequestFormSuccess());
    } else if (!iframeRef.current.contentDocument && print) {
      dispatch(printLabRequestFormFailure());
      dispatch(printSingleLabRequestForm(packageId));
    }
  }, [dispatch, print, printLabRequestFormSuccess, printLabRequestFormFailure]);

  useEffect(() => {
    const iframe = iframeRef.current;
    iframe.addEventListener('load', printLabRequestForm);

    return () => {
      iframe.removeEventListener('load', printLabRequestForm);
    };
  }, [printLabRequestForm]);

  const onClick = useCallback(() => {
    if (print) {
      iframeRef.current.contentWindow.location.reload(true);
    }
    setPrint(true);
    dispatch(printLabRequestFormsAction());
  }, [dispatch, print, printLabRequestFormsAction]);

  return (
    <>
      <StyledIframe
        title="printLabRequestForm"
        ref={iframeRef}
        src={print ? `${API_HOST_AUTH}${path}` : undefined}
      />
      {children({ onClick })}
    </>
  );
};

PrintLabRequestForm.propTypes = {
  children: PropTypes.func,
  packageId: PropTypes.string,
};

export { PrintLabRequestForm };
