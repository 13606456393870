import { TERMS } from 'shared/helpers/constants/terms/termsConstants';

function parseErrorData(data) {
  if (data && data.err && data.err.isJoi) {
    const { err } = data;
    switch (err.details[0].path[0]) {
      case 'startDate':
      case 'endDate':
        return TERMS.SUBMIT_ERROR_INVALID_DATA;
      case 'primaryContact':
        return TERMS.SUBMIT_ERROR_CONTACT;
      case 'advanceRate':
        return TERMS.SUBMIT_ERROR_ADVANCE_RATE;
      case 'status':
        return TERMS.SUBMIT_ERROR_STATUS;
      default:
        return data.message;
    }
  }

  return TERMS.SUBMIT_ERROR;
}

export { parseErrorData };
