import React, { Component } from 'react';

import { UIAuthorization } from 'components/Authorization/UIAuthorization';
import { NotificationActionsPortal } from 'components/NotificationActionsPortal/NotificationActionsPortal';

import { RouterButton } from 'shared/components/Buttons';
import { Button } from 'shared/components/Buttons/Button';
import { ExtendableButton } from 'shared/components/Buttons/ExtendableButton/ExtendableButton';
import { DetailsHeader } from 'shared/components/Details/DetailsHeader';
import {
  deletePaymentAccess,
  changePaymentStatusAccess,
  markPaymentAsPaidAccess,
  createSettlementDocumentAccess,
  showExistingPaymentsAccess,
  reassignPaymentAccess,
  updateDraftPaymentAccess,
} from 'shared/helpers/accesses/payments/paymentAccesses';
import { notificationActionTypes } from 'shared/helpers/constants/notifications/notificationActionTypes';
import { paymentStatuses } from 'shared/helpers/constants/payments/paymentStatuses';
import { paymentTypes } from 'shared/helpers/constants/payments/paymentTypes';
import { PAYMENTS } from 'shared/helpers/constants/payments/paymentsConstants';

import { history } from 'utils/history';

import { CreateSettlementDocument } from 'pages/Payments/components/CreateSettlementDocument/CreateSettlementDocument';
import { DeletePayment } from 'pages/Payments/components/DeletePayment/DeletePayment';
import { PaymentMarkAsPaid } from 'pages/Payments/components/PaymentMarkAsPaid/PaymentMarkAsPaid';
import { PaymentReassign } from 'pages/Payments/components/PaymentReassign/PaymentReassign';
import { PaymentStatusChange } from 'pages/Payments/components/PaymentStatusChange/PaymentStatusChange';

class PaymentMainDetails extends Component {
  state = { displayMarkAsPaid: false, displayPaymentReassign: false };

  toggleMarkAsPaidModal = () =>
    this.setState(({ displayMarkAsPaid }) => ({ displayMarkAsPaid: !displayMarkAsPaid }));

  togglePaymentReassignModal = () => {
    this.setState(({ displayPaymentReassign }) => ({
      displayPaymentReassign: !displayPaymentReassign,
    }));
  };

  detailsHeader = payment => {
    return payment.paymentType !== paymentTypes.pool
      ? `${payment.relatedLot.grgLotNumber}, ${PAYMENTS.PAYMENT_NUMBER_COLON} ${payment.paymentNumber}`
      : PAYMENTS.POOL_PAYMENT;
  };

  handleClick = () => {
    this.props.saveSharedData({ scrollToPayment: true });
    return history.push(`/lots/${this.props.payment?.relatedLot?._id}`);
  };

  render() {
    const { auth, payment } = this.props;
    const { displayMarkAsPaid, displayPaymentReassign } = this.state;
    const notificationActions = [
      notificationActionTypes.advancePaymentRequest,
      notificationActionTypes.paymentRequest,
    ];

    return (
      <DetailsHeader header={this.detailsHeader(payment)} status={payment.status}>
        <ExtendableButton label={PAYMENTS.ACTIONS} closeOnClick={this.toggleMarkAsPaidModal}>
          <UIAuthorization access={createSettlementDocumentAccess(auth, payment)}>
            <CreateSettlementDocument>
              {({ createSettlementDocument }) => (
                <Button id="createSettlementDocument" onClick={createSettlementDocument}>
                  {PAYMENTS.SETTLEMENT_DOCUMENT}
                </Button>
              )}
            </CreateSettlementDocument>
          </UIAuthorization>
          <UIAuthorization access={changePaymentStatusAccess(auth, payment)}>
            <PaymentStatusChange success status={paymentStatuses.approved} id={payment._id}>
              {({ onClick }) => (
                <NotificationActionsPortal actions={notificationActions}>
                  <Button id="approvePayment" onClick={onClick}>
                    {PAYMENTS.APPROVE_PAYMENT}
                  </Button>
                </NotificationActionsPortal>
              )}
            </PaymentStatusChange>
          </UIAuthorization>
          <UIAuthorization access={changePaymentStatusAccess(auth, payment)}>
            <PaymentStatusChange status={paymentStatuses.declined} id={payment._id}>
              {({ onClick }) => (
                <NotificationActionsPortal actions={notificationActions}>
                  <Button
                    id="declinePayment"
                    color="danger"
                    outlineColor="danger"
                    onClick={onClick}
                  >
                    {PAYMENTS.DECLINE_PAYMENT}
                  </Button>
                </NotificationActionsPortal>
              )}
            </PaymentStatusChange>
          </UIAuthorization>
          <UIAuthorization access={showExistingPaymentsAccess(payment)}>
            <NotificationActionsPortal showOnlyInNotification actions={notificationActions}>
              <Button
                id="showExistingPayments"
                color="warning"
                outlineColor="warning"
                onClick={this.handleClick}
              >
                {PAYMENTS.SHOW_EXISTING_PAYMENTS}
              </Button>
            </NotificationActionsPortal>
          </UIAuthorization>
          <UIAuthorization access={markPaymentAsPaidAccess(auth, payment)}>
            <Button id="markAsPaid" onClick={this.toggleMarkAsPaidModal}>
              {PAYMENTS.MARK_AS_PAID}
            </Button>
          </UIAuthorization>
          <UIAuthorization access={reassignPaymentAccess(auth, payment)}>
            <Button id="reassignPayment" color="danger" onClick={this.togglePaymentReassignModal}>
              {PAYMENTS.ADMIN_REASSIGN_PAYMENT}
            </Button>
          </UIAuthorization>
          <UIAuthorization access={updateDraftPaymentAccess(auth, payment)}>
            <RouterButton
              id="updateDraftPayment"
              path={`/payments/${payment._id}/update-draft-${payment.paymentType}`}
              label={PAYMENTS.EDIT_PAYMENT}
            />
          </UIAuthorization>
          <UIAuthorization access={deletePaymentAccess(auth, payment)}>
            <DeletePayment payment={payment} userType={auth.user.userType}>
              {({ deletePayment }) => (
                <Button id="deletePayment" color="danger" onClick={deletePayment}>
                  {PAYMENTS.DELETE_PAYMENT}
                </Button>
              )}
            </DeletePayment>
          </UIAuthorization>
        </ExtendableButton>
        <PaymentMarkAsPaid
          toggle={this.toggleMarkAsPaidModal}
          open={displayMarkAsPaid}
          payment={payment}
        />
        <PaymentReassign
          toggle={this.togglePaymentReassignModal}
          open={displayPaymentReassign}
          paymentId={payment._id}
        />
      </DetailsHeader>
    );
  }
}

export { PaymentMainDetails };
