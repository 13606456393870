import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getForwardRecipientsType = 'GET_FORWARD_RECIPIENTS';
const getForwardRecipientsAction = () => ({
  type: getForwardRecipientsType,
});

const getForwardRecipientsSuccessType = `${getForwardRecipientsType}_SUCCESS`;
const getForwardRecipientsSuccessAction = recipients => ({
  type: getForwardRecipientsSuccessType,
  payload: {
    recipients,
  },
});

const getForwardRecipientsFailureType = `${getForwardRecipientsType}_FAILURE`;
const getForwardRecipientsFailureAction = () => ({
  type: getForwardRecipientsFailureType,
});

const clearForwardRecipientsType = 'CLEAR_FORWARD_RECIPIENTS';
const clearForwardRecipientsTypeAction = () => ({
  type: clearForwardRecipientsType,
});

const getForwardRecipients = fileId => dispatch => {
  dispatch(getForwardRecipientsAction());

  return axios
    .get(`${API_HOST_AUTH}/files/${fileId}/recipients`)
    .then(res => {
      dispatch(getForwardRecipientsSuccessAction(res.data));

      return res.data;
    })
    .catch(err => {
      dispatch(getForwardRecipientsFailureAction());

      return Promise.reject(err.data);
    });
};

export {
  getForwardRecipientsType,
  getForwardRecipientsSuccessType,
  getForwardRecipientsFailureType,
  clearForwardRecipientsType,
  getForwardRecipients,
  clearForwardRecipientsTypeAction as clearForwardRecipients,
};
