import React, { Component } from 'react';

import { FormContainer } from 'shared/components/Form';
import { normalizeShipment } from 'shared/helpers/constants/shipments/normalizeShipment';
import { SHIPMENTS } from 'shared/helpers/constants/shipments/shipmentsConstants';
import { snackbarOptions } from 'shared/helpers/matchers/snackbar/snackbarOptions';

import { ShipmentForm } from '../ShipmentForm/ShipmentForm';

class UpdateShipment extends Component {
  componentDidMount() {
    this.props
      .getShipment(this.props.match.params.id)
      .then(shipment => shipment || Promise.reject(null))
      .catch(() => this.props.history.push('/shipments'));
  }

  submit = values => {
    const updateObj = {
      ...values,
      packages: this.props.shipmentPackagesListData.packages,
      id: this.props.shipmentDetails.shipment._id,
    };

    return this.props.updateShipment(updateObj).then(this.resolveAction);
  };

  resolveAction = result => {
    this.props.showSnackbar(snackbarOptions(result.messageType), result.message);
    this.props.history.push(`/shipments/${result.id}`);
  };

  render() {
    const {
      shipmentDetails: { shipment, isPending },
      shipmentPackagesListData,
      location,
    } = this.props;
    const initialValues = shipment
      ? {
          ...normalizeShipment(shipment, [
            ...((location.state && location.state.packages) || []),
            ...((shipmentPackagesListData && shipmentPackagesListData.packages) || []),
          ]),
          relatedCompany: {
            label: this.props.auth.user.relatedCompany.companyName,
          },
        }
      : null;

    return (
      <FormContainer header={SHIPMENTS.EDIT_SHIPMENT} loaded={shipment && !isPending}>
        {() => (
          <ShipmentForm
            {...this.props}
            onSubmit={this.submit}
            sentFromCompany={this.props.auth.user.relatedCompany}
            initialValues={initialValues}
            editMode
          />
        )}
      </FormContainer>
    );
  }
}

export { UpdateShipment };
