import {
  createVirtualLotActionType,
  createVirtualLotSuccessActionType,
  createVirtualLotFailureActionType,
} from 'actions/Lots/createVirtualLot';

const INITIAL_STATE = {
  isPending: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case createVirtualLotActionType:
      return {
        isPending: true,
      };
    case createVirtualLotSuccessActionType:
    case createVirtualLotFailureActionType:
      return {
        isPending: false,
      };
    default:
      return state;
  }
};
