import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getMarketIndicatorsLogsType = 'GET_MARKET_INDICATORS_LOGS';
const getMarketIndicatorsLogsAction = () => ({
  type: getMarketIndicatorsLogsType,
});

const getMarketIndicatorsLogsSuccessType = `${getMarketIndicatorsLogsType}_SUCCESS`;
const getMarketIndicatorsLogsSuccessAction = logs => ({
  type: getMarketIndicatorsLogsSuccessType,
  payload: {
    logs,
  },
});

const getMarketIndicatorsLogsFailureType = `${getMarketIndicatorsLogsType}_FAILURE`;
const getMarketIndicatorsLogsFailureAction = () => ({
  type: getMarketIndicatorsLogsFailureType,
});

const clearMarketIndicatorsLogsActionType = 'CLEAR_MARKET_INDICATORS_LOGS';
const clearMarketIndicatorsLogsAction = () => ({
  type: clearMarketIndicatorsLogsActionType,
});

const getMarketIndicatorsLogs = () => async dispatch => {
  dispatch(getMarketIndicatorsLogsAction());

  try {
    const res = await axios.get(`${API_HOST_AUTH}/market-indicators/logs`);
    dispatch(getMarketIndicatorsLogsSuccessAction(res.data));

    return res.data;
  } catch (error) {
    dispatch(getMarketIndicatorsLogsFailureAction());

    return Promise.reject(error.data);
  }
};

export {
  getMarketIndicatorsLogsType,
  getMarketIndicatorsLogsSuccessType,
  getMarketIndicatorsLogsFailureType,
  clearMarketIndicatorsLogsActionType,
  getMarketIndicatorsLogs,
  clearMarketIndicatorsLogsAction as clearMarketIndicatorsLogs,
};
