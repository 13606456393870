import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  clearSelfDeliveryRequestsOverview,
  getSelfDeliveryRequestsOverviewList,
} from 'actions/SelfDeliveryRequests/selfDeliveryRequestsOverview';
import {
  getIncomingShipmentsOverview,
  getOutgoingShipmentsOverview,
  clearIncomingShipmentsOverview,
  clearOutgoingShipmentsOverview,
} from 'actions/Shipments/shipmentsOverview';

import { GroupButtons } from 'shared/components/Buttons';
import { Button } from 'shared/components/Buttons/Button';
import { GroupButton } from 'shared/components/Buttons/GroupButton/components/GroupButton';
import { Icon } from 'shared/components/Icon/Icon';
import { OverviewHeader } from 'shared/components/OverviewHeader';
import { OverviewListWrapper } from 'shared/components/OverviewListWrapper/OverviewListWrapper';
import { SHARED } from 'shared/helpers/constants/sharedConstants';
import { overviewListsConfig } from 'shared/helpers/constants/shipments/overviewListsConfig';
import { SHIPMENTS } from 'shared/helpers/constants/shipments/shipmentsConstants';
import { cssVar } from 'shared/helpers/styling/styling';
import { useWindowWidth } from 'shared/hooks/useWindowWidth';

import { ShipmentsCalendar } from 'pages/Shipments/components/ShipmentsCalendar/ConnectedShipmentsCalendar';

const renderActionButtons = (auth, history) => (
  <GroupButtons>
    <GroupButton
      access={auth.access.shipments.createIncoming}
      label={SHIPMENTS.CREATE_INCOMING_SHIPMENT}
      icon="create-incoming-shipment"
      onClick={() => history.push('/shipments/create-incoming')}
    />
    <GroupButton
      access={
        auth.access.shipments.createOutgoing ||
        auth.access.specialPermissions.shipments.createOutgoingShipment
      }
      label={SHIPMENTS.CREATE_OUTGOING_SHIPMENT}
      icon="create-outgoing-shipment"
      onClick={() => history.push('/shipments/create-outgoing')}
    />
    <GroupButton
      access={auth.access.selfDeliveryRequests.create && auth.access.companies.gradingEnabled}
      label={SHIPMENTS.CREATE_SELF_DELIVERY_REQUEST}
      icon="create-self-delivery"
      onClick={() => history.push('/shipments/self-delivery-requests/create')}
    />
  </GroupButtons>
);

const listsTemplate = props => [
  {
    clearList: props.clearIncomingShipmentsOverview,
    getList: (page, limit) => props.getIncomingShipmentsOverview(limit),
    label: SHIPMENTS.INCOMING_SHIPMENTS,
    buttonRoute: '/shipments/incoming',
    itemsTemplate: overviewListsConfig.incomingShipmentsItemsTemplate,
    itemsTemplateMobile: overviewListsConfig.incomingShipmentsTemplateMobile,
    itemDetailsTemplate: overviewListsConfig.incomingShipmentsItemsDetailsTemplate,
    actionsTemplate: overviewListsConfig.shipmentActionsTemplate(SHIPMENTS.SHIPMENT_TYPES.INCOMING),
    list: props.incomingShipments,
  },
  {
    clearList: props.clearOutgoingShipmentsOverview,
    getList: (page, limit) => props.getOutgoingShipmentsOverview(limit),
    label: SHIPMENTS.OUTGOING_SHIPMENTS,
    buttonRoute: '/shipments/outgoing',
    itemsTemplate: overviewListsConfig.outgoingShipmentsItemsTemplate,
    itemsTemplateMobile: overviewListsConfig.outgoingShipmentsTemplateMobile,
    itemDetailsTemplate: overviewListsConfig.outgoingShipmentsItemsDetailsTemplate,
    actionsTemplate: overviewListsConfig.shipmentActionsTemplate(SHIPMENTS.SHIPMENT_TYPES.OUTGOING),
    list: props.outgoingShipments,
  },
  props.auth.access.companies.gradingEnabled && {
    clearList: props.clearSelfDeliveryRequestsOverview,
    getList: (page, limit) => props.getSelfDeliveryRequestsOverviewList(limit),
    label: SHIPMENTS.SELF_DELIVERY_REQUESTS,
    buttonRoute: '/shipments/self-delivery-requests',
    itemsTemplate: overviewListsConfig.sdRequestsItemsTemplate,
    itemsTemplateMobile: overviewListsConfig.sdRequestsTemplateMobile,
    itemDetailsTemplate: overviewListsConfig.sdRequestsItemsDetailsTemplate,
    actionsTemplate: overviewListsConfig.sdRequestsActionsTemplate(props.auth),
    list: props.selfDeliveryRequests,
  },
].filter(Boolean);

const GlobalShipmentsOverviewLayout = props => {
  const { auth, history } = props;
  const windowWidth = useWindowWidth();

  return (
    <>
      <OverviewHeader header={SHIPMENTS.SHIPMENTS} actions={renderActionButtons(auth, history)} />
      <ShipmentsCalendar />
      {listsTemplate(props).map(el => (
        <OverviewListWrapper
          {...props}
          {...el}
          key={el.label}
          windowWidth={windowWidth}
          button={
            <Button
              id="listsButton"
              onClick={() => props.history.push(el.buttonRoute)}
              borderRadius={18}
              outlineColor={cssVar('pelorous')}
              margin={false}
              bgColor={cssVar('outerSpaceTabs')}
              padding="0px 36px"
            >
              {SHARED.DETAILS}
              <Icon icon="icon-tiny-arrow-right" color={cssVar('alabaster')} margin="0 0 0 16px" />
            </Button>
          }
        />
      ))}
    </>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
  selfDeliveryRequests: state.selfDeliveryRequestsOverview,
  incomingShipments: state.incomingShipmentsOverview,
  outgoingShipments: state.outgoingShipmentsOverview,
});

const mapDispatchToProps = {
  getSelfDeliveryRequestsOverviewList,
  clearSelfDeliveryRequestsOverview,
  getIncomingShipmentsOverview,
  getOutgoingShipmentsOverview,
  clearIncomingShipmentsOverview,
  clearOutgoingShipmentsOverview,
};

const GlobalShipmentsOverview = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GlobalShipmentsOverviewLayout),
);

export { GlobalShipmentsOverview };
