import { hedgeStatus } from 'shared/helpers/constants/hedges/hedgeStatus';
import { hedgeTypes } from 'shared/helpers/constants/hedges/hedgeType';
import { statusesForGlobal } from 'shared/helpers/constants/lots/statusesForGlobal';

const availableHedgeStatuses = [hedgeStatus.placed, hedgeStatus.filled, hedgeStatus.reassigned];
const allowedHedgeTypes = [hedgeTypes.custom, hedgeTypes.standard, hedgeTypes.internal];

export const updatePricesAccess = (auth, { status, relatedLot, hedgeType }) => {
  const { changeHedgePrices } = auth.access.specialPermissions.hedges;

  return (
    changeHedgePrices &&
    [...availableHedgeStatuses, hedgeStatus.partly_filled].includes(status) &&
    !(
      allowedHedgeTypes.includes(hedgeType) &&
      relatedLot.statusForGlobal === statusesForGlobal.finalized
    )
  );
};

export const updateCustomPricesAccess = (
  auth,
  { status, hedgeType, platinumPriceCust, palladiumPriceCust, rhodiumPriceCust, relatedLot },
) => {
  const { changeHedgePrices } = auth.access.specialPermissions.hedges;

  return (
    changeHedgePrices &&
    hedgeType === hedgeTypes.custom &&
    availableHedgeStatuses.includes(status) &&
    (platinumPriceCust || palladiumPriceCust || rhodiumPriceCust) &&
    !(
      allowedHedgeTypes.includes(hedgeType) &&
      relatedLot.statusForGlobal === statusesForGlobal.finalized
    )
  );
};
