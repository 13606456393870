import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getSpecialPermissionsLogs } from 'actions/SpecialPermissionsLogs/getSpecialPermissionsLogs';

import { FullPageList } from 'shared/components/FullPageList/FullPageList';
import { PERMISSIONS } from 'shared/helpers/constants/systemSettings/permissions/constants';

import {
  itemsTemplate,
  itemsTemplateMobile,
  itemsDetailsTemplate,
} from './config/overviewTableConfig';

const SpecialPermissionsLogs = () => {
  const dispatch = useDispatch();

  const { items, page, limit, pages, isPending } = useSelector(
    state => state.specialPermissionsLogs,
  );

  const fetchList = (page = 1, limit = 50) => {
    dispatch(getSpecialPermissionsLogs({ limit, page }));
  };

  return (
    <FullPageList
      title={PERMISSIONS.SPECIAL_PERMISSIONS_LOG}
      itemsTemplate={itemsTemplate}
      itemsDetailsTemplate={itemsDetailsTemplate}
      itemsTemplateMobile={itemsTemplateMobile}
      actionsTemplate={[]}
      items={items || []}
      fetchList={fetchList}
      detailsList
      pagination={{ page, limit, pages }}
      isPending={isPending}
      itemsNumber={itemsTemplate.length}
      stripedItems
      equalColumns
    />
  );
};

export { SpecialPermissionsLogs };
