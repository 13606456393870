import axios from 'axios';

const { REACT_APP_API } = process.env;

const incomingLotsOverviewActionType = 'INCOMING_LOTS';
const incomingLotsOverviewAction = section => ({
  type: incomingLotsOverviewActionType,
  payload: { section },
});

const incomingLotsOverviewSuccessActionType = `${incomingLotsOverviewActionType}_SUCCESS`;
const incomingLotsOverviewSuccessAction = (section, data) => ({
  type: incomingLotsOverviewSuccessActionType,
  payload: { section, data },
});

const incomingLotsOverviewFailureActionType = `${incomingLotsOverviewActionType}_FAILURE`;
const incomingLotsOverviewFailureAction = section => ({
  type: incomingLotsOverviewFailureActionType,
  payload: { section },
});

const incomingLotsOverviewClearActionType = `${incomingLotsOverviewActionType}_CLEAR`;
const incomingLotsOverviewClearAction = () => ({
  type: incomingLotsOverviewClearActionType,
});

const getIncomingLotsOverview =
  (section, page = 1, limit = 0) =>
  dispatch => {
    dispatch(incomingLotsOverviewAction(section));
    return axios({
      method: 'GET',
      url: `${REACT_APP_API}/lots`,
      params: {
        ...section.query,
        page,
        limit,
      },
    })
      .then(res => {
        dispatch(incomingLotsOverviewSuccessAction(section, res.data));
      })
      .catch(() => {
        dispatch(incomingLotsOverviewFailureAction(section));
      });
  };

const setPriorityActionType = `${incomingLotsOverviewActionType}_SET_PRIORITY`;
const setPriorityAction = (section, lotIds, reset) => ({
  type: setPriorityActionType,
  payload: { section, lotIds, reset },
});

const setPrioritySuccessActionType = `${incomingLotsOverviewActionType}_SET_PRIORITY_SUCCESS`;
const setPrioritySuccessAction = (section, lots) => ({
  type: setPrioritySuccessActionType,
  payload: { section, lots },
});

const setPriorityFailedActionType = `${incomingLotsOverviewActionType}_SET_PRIORITY_FAILED`;
const setPriorityFailedAction = section => ({
  type: setPriorityFailedActionType,
  payload: { section },
});

const setPriority = (section, lotIds, reset) => dispatch => {
  dispatch(setPriorityAction(section, lotIds, reset));

  return axios({
    method: 'post',
    url: `${REACT_APP_API}/lots/set-priority`,
    data: JSON.stringify({
      lotIds,
      reset,
      priorityField: section.priorityField,
    }),
  })
    .then(({ data: { lots } }) => dispatch(setPrioritySuccessAction(section, lots)))
    .catch(() => dispatch(setPriorityFailedAction(section)));
};

export {
  getIncomingLotsOverview,
  incomingLotsOverviewClearAction,
  incomingLotsOverviewActionType,
  incomingLotsOverviewSuccessActionType,
  incomingLotsOverviewFailureActionType,
  incomingLotsOverviewClearActionType,
  setPriority,
  setPriorityActionType,
  setPrioritySuccessActionType,
  setPriorityFailedActionType,
};
