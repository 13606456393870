import styled from 'styled-components';

import { cssVar } from 'shared/helpers/styling/styling';

const Line = styled.div`
  grid-area: line;
  width: 2px;
  align-self: stretch;
  justify-self: center;
  background: repeating-linear-gradient(
    0deg,
    transparent,
    transparent 5px,
    ${cssVar('shuttleGray')} 5px,
    ${cssVar('shuttleGray')} 10px
  );
`;

export { Line };
