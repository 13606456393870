import React, { Component } from 'react';

import { DatepickerDropdown } from 'shared/components/DatepickerDropdown/DatepickerDropdown';
import { FieldRadioButton } from 'shared/components/Fields/FieldRadioButton/FieldRadioButton';
import { FiltersDropdown } from 'shared/components/FiltersDropdown/FiltersDropdown';
import { fundsTypes } from 'shared/helpers/constants/payments/fundsTypes';
import { paymentStatuses } from 'shared/helpers/constants/payments/paymentStatuses';
import { paymentTypes } from 'shared/helpers/constants/payments/paymentTypes';
import { PAYMENTS } from 'shared/helpers/constants/payments/paymentsConstants';

import dateAdapter from 'utils/date/dateAdapter';
import { MomentAdapter } from 'utils/date/momentAdapter';

import { StyledPaymentsFilters, StyledPaymentsFiltersItem } from './StyledPaymentsFilters';

class PaymentsFilters extends Component {
  filterFunction = name => value => {
    this.props.onFiltersChanged({
      ...this.props.filters,
      [name]: value instanceof MomentAdapter ? value.valueOf() : value,
    });
  };

  renderDateFilter = (name, label, minDate, maxDate) => {
    const { filters } = this.props;

    return (
      <StyledPaymentsFiltersItem>
        <DatepickerDropdown
          label={label}
          disabled={filters.isPaid === false}
          selected={filters[name] && dateAdapter(filters[name])}
          filterFunction={this.filterFunction(name)}
          minDate={dateAdapter(minDate || 0)}
          maxDate={dateAdapter(maxDate)}
          fixedHeight
        />
      </StyledPaymentsFiltersItem>
    );
  };

  render() {
    const {
      filters: { status, paymentType, fundsType, paidAtStart, paidAtEnd, isPaid },
    } = this.props;

    return (
      <StyledPaymentsFilters>
        <StyledPaymentsFiltersItem>
          <FiltersDropdown
            label={PAYMENTS.STATUS}
            options={Object.values(paymentStatuses)}
            selected={status || ''}
            filterFunction={this.filterFunction('status')}
          />
        </StyledPaymentsFiltersItem>
        <StyledPaymentsFiltersItem>
          <FiltersDropdown
            label={PAYMENTS.PAYMENT_TYPE}
            options={Object.values(paymentTypes)}
            selected={paymentType || ''}
            filterFunction={this.filterFunction('paymentType')}
          />
        </StyledPaymentsFiltersItem>
        <StyledPaymentsFiltersItem>
          <FiltersDropdown
            label={PAYMENTS.FUNDS_TYPE}
            options={Object.values(fundsTypes)}
            selected={fundsType || ''}
            filterFunction={this.filterFunction('fundsType')}
          />
        </StyledPaymentsFiltersItem>
        {this.renderDateFilter('paidAtStart', PAYMENTS.PAID_ON_FROM, 0, paidAtEnd)}
        {this.renderDateFilter('paidAtEnd', PAYMENTS.PAID_ON_TO, paidAtStart)}
        <StyledPaymentsFiltersItem>
          <FieldRadioButton
            clearable
            label={PAYMENTS.PAID}
            disabled={!!(paidAtStart || paidAtEnd)}
            input={{
              value: paidAtStart || paidAtEnd ? true : isPaid,
              onChange: value =>
                this.filterFunction('isPaid')(typeof value === 'string' ? value === 'true' : null),
            }}
          />
        </StyledPaymentsFiltersItem>
      </StyledPaymentsFilters>
    );
  }
}

export { PaymentsFilters };
