import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useLocation } from 'react-router-dom';

import { getWarehouseData } from 'actions/Lots/getWarehouseDashboardData';
import { createNote as createNoteAction } from 'actions/Notes/createNote';
import { clearNotesList, getNotes } from 'actions/Notes/notesList';
import { openModal } from 'actions/shared/modal';
import { showSnackbar } from 'actions/shared/snackbar';

import { AddButton } from 'shared/components/Buttons/AddButton/AddButton';
import { ContentLoader } from 'shared/components/ContentLoader/ContentLoader';
import { Separator } from 'shared/components/Form';
import { NoteFormBase } from 'shared/components/Notes/CreateNotes/NoteFormBase/NoteFormBase';
import { NotesItem } from 'shared/components/Notes/NotesPanel/NotesItem/NotesItem';
import { cancelModal } from 'shared/helpers/constants/modalConstants';
import { NOTE } from 'shared/helpers/constants/noteConstants';
import {
  dangerOptions,
  successOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

import { relativeNavigator } from 'utils/relativeNavigator';

import { ProcessingNoteSchema } from './ProcessingNoteYup';
import { StyledNoteHeader } from './StyledCreateProcessingNote';

export const CreateProcessingNote = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { isPending } = useSelector(({ createNoteStorage }) => createNoteStorage);
  const { notes, isPending: isNotesListPending } = useSelector(({ notesList }) => notesList);
  const [isCreateFormOpen, setIsCreateFormOpen] = useState(false);

  const navigation = [{ from: /\/create-processing-note\//, to: '/lots' }];
  const navigator = relativeNavigator(navigation)();

  useEffect(() => {
    dispatch(
      getNotes({
        relatedType: NOTE.NOTES_TYPE.PROCESSING,
        relatedId: id,
        listLimit: 0,
        listPage: 1,
      }),
    );
  }, []);

  useEffect(() => {
    return () => {
      dispatch(clearNotesList());
    };
  }, []);

  useEffect(() => {
    setIsCreateFormOpen(!notes[NOTE.NOTES_TYPE.PROCESSING]?.length);
  }, [notes]);

  const navigateBack = () => {
    dispatch(clearNotesList());
    navigator(pathname);
  };

  const modalClose = useCallback(() => {
    dispatch(openModal(cancelModal, navigateBack));
  }, [navigator]);

  const createNote = useCallback(
    async value => {
      try {
        const {
          data: { message },
        } = await dispatch(createNoteAction(value, [], id, NOTE.NOTES_TYPE.PROCESSING));

        dispatch(showSnackbar(successOptions, message));
        dispatch(getWarehouseData());
        navigator(pathname);
      } catch (error) {
        dispatch(showSnackbar(dangerOptions, error?.message));
      }
    },
    [modalClose],
  );

  const openCreateForm = useCallback(() => setIsCreateFormOpen(true), [isCreateFormOpen]);

  return (
    <ContentLoader loaded={!isNotesListPending && !isPending}>
      {!isCreateFormOpen ? (
        <AddButton data-testid="addProcessingNoteButton" onClick={openCreateForm}>
          {NOTE.ADD_PROCESSING_NOTE}
        </AddButton>
      ) : (
        <>
          <StyledNoteHeader>{NOTE.ADD_PROCESSING_NOTE}</StyledNoteHeader>
          <NoteFormBase
            handleSubmitForm={createNote}
            closeModal={modalClose}
            isPending={isPending}
            validationSchema={ProcessingNoteSchema}
          />
        </>
      )}
      {notes[NOTE.NOTES_TYPE.PROCESSING]?.length > 0 && (
        <>
          <Separator />
          <StyledNoteHeader>{NOTE.PROCESSING_NOTES_LIST}</StyledNoteHeader>
        </>
      )}
      {notes[NOTE.NOTES_TYPE.PROCESSING]?.map(note => (
        <NotesItem note={note} key={note._id} lotId={id} />
      ))}
    </ContentLoader>
  );
};
