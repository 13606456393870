import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import { FieldCapitalized } from 'shared/components/Fields/FieldCapitalized/FieldCapitalized';
import { FieldInput } from 'shared/components/Fields/FieldInput/FieldInput';
import { FieldRadioButton } from 'shared/components/Fields/FieldRadioButton/FieldRadioButton';
import { Form } from 'shared/components/Form/Form/Form';
import { Section } from 'shared/components/Form/Section/Section';
import { LocationsSelect } from 'shared/components/LocationsSelect/LocationsSelect';
import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';
import { cancelModal } from 'shared/helpers/constants/modalConstants';
import { isCustomerUser } from 'shared/helpers/matchers/checkUserType';
import { isGlobalRefiningGroup } from 'shared/helpers/matchers/isGlobalRefiningGroup';
import { asyncValidate } from 'shared/helpers/validations/lot/customLotNumberAsyncValidation';
import { validate } from 'shared/helpers/validations/lot/customerLotValidations';

import { history } from 'utils/history';

class CustomerLotForm extends Component {
  modalOpen = () => {
    this.props.openModal(cancelModal, () => {
      if (this.props.location.state?.fromRequestHedgeView) {
        history.push('/hedges/create');
      } else if (this.props.location.state?.fromCreatePackagesView) {
        history.push('/shipments/packages/create', this.props.location.state);
      } else if (this.props.location.state?.fromPackingListView) {
        this.props.history.push('/shipments/create-incoming-wizard', this.props.location.state);
      } else {
        history.push('/lots');
      }
    });
  };

  render() {
    const {
      handleSubmit,
      auth: {
        user: {
          userType,
          relatedCompany: { companyName },
        },
      },
      sentFromCompany,
    } = this.props;

    return (
      <Form
        onSubmit={handleSubmit}
        onCancel={this.modalOpen}
        submitDisabled={this.props.invalid}
        header={companyName}
      >
        <Section
          template={
            !isGlobalRefiningGroup(sentFromCompany.companyName)
              ? [
                  'sentFromCompanyLocation customLotNumber',
                  'processUponDelivery customerInstructions',
                ]
              : ['sentFromCompanyLocation customerInstructions', 'processUponDelivery .']
          }
        >
          <LocationsSelect {...this.props} selectedForm="LotForm" name="sentFromCompanyLocation" />
          {!isGlobalRefiningGroup(sentFromCompany.companyName) && (
            <FieldCapitalized
              name="customLotNumber"
              type="text"
              component={FieldInput}
              label={LOTS.CUSTOM_LOT_NUMBER}
              icon="icon-referral"
            />
          )}
          {isCustomerUser(userType) && (
            <Field
              name="processUponDelivery"
              component={FieldRadioButton}
              label={LOTS.PROCESS_UPON_DELIVERY}
              checked
              icon="icon-cart"
              field="required"
            />
          )}
          <Field
            name="customerInstructions"
            component={FieldInput}
            label={LOTS.CUSTOMER_INSTRUCTIONS}
          />
        </Section>
      </Form>
    );
  }
}

function mapStateToProps({ form, auth }, { companyLocations }) {
  const defaultCompanyLocation =
    companyLocations?.data.length === 1
      ? { value: companyLocations.data[0]._id, label: companyLocations.data[0].name }
      : null;

  return {
    LotForm: form.LotForm,
    initialValues: {
      sentFromCompanyLocation: defaultCompanyLocation,
      ...(isCustomerUser(auth.user.userType) && { processUponDelivery: true }),
    },
  };
}

const LotForm = reduxForm({
  form: 'LotForm',
  validate,
  asyncValidate,
  enableReinitialize: true,
  asyncBlurFields: ['customLotNumber'],
})(CustomerLotForm);

export default connect(mapStateToProps)(LotForm);
