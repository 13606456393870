import CreateNoteReducer from './createNote';
import NotesListReducer from './notesList';
import UpdateNoteReducer from './updateNote';

const noteRootReducer = {
  createNoteStorage: CreateNoteReducer,
  updateNoteStorage: UpdateNoteReducer,
  notesList: NotesListReducer,
};

export { noteRootReducer };
