import { PAYMENTS } from 'shared/helpers/constants/payments/paymentsConstants';

export const validate = values => {
  const errors = {};

  if (!values.newLot) {
    errors.newLot = PAYMENTS.REASSIGN_MESSAGES.NEW_LOT_EMPTY;
  }

  return errors;
};
