import React from 'react';

import { LinkButton } from 'shared/components/Buttons';
import { DetailsActivityField } from 'shared/components/Details/DetailsActivityField';
import { DetailsDateField } from 'shared/components/Details/DetailsDateField';
import { DetailsDownloadField } from 'shared/components/Details/DetailsDownloadFieldRedesign';
import { DetailsField } from 'shared/components/Details/DetailsField';
import { DetailsHumanizedField } from 'shared/components/Details/DetailsHumanizedField';
import { paymentTypes } from 'shared/helpers/constants/payments/paymentTypes';
import { PAYMENTS } from 'shared/helpers/constants/payments/paymentsConstants';
import { isOwnerOrSupport } from 'shared/helpers/matchers/checkUserType';
import { formatToDollar } from 'shared/helpers/parsers/formaters';
import { REQUESTED_PAYMENT_DATE_REGEX } from 'shared/helpers/validations/regularExpressions';

import { history } from 'utils/history';

const paymentDetailsFieldsConfig = (payment, downloadAttachment, auth) => [
  {
    label: PAYMENTS.RELATED_COMPANY,
    customContent: payment.relatedCompany && isOwnerOrSupport(auth?.user.userType) && (
      <LinkButton
        label={payment.relatedCompany.companyName}
        onClick={() => history.push(`/companies/${payment.relatedCompany._id}`)}
      />
    ),
    value: payment.relatedCompany.companyName,
    icon: 'icon-home',
    id: 'relatedCompany',
    FieldType: DetailsField,
  },
  {
    label: PAYMENTS.RELATED_LOT,
    customContent: payment.relatedLot && (
      <LinkButton
        label={payment.relatedLot.grgLotNumber}
        onClick={() => history.push(`/lots/list/${payment.relatedLot._id}`)}
      />
    ),
    icon: 'icon-home',
    id: 'relatedLot',
    FieldType: DetailsField,
  },
  {
    label: PAYMENTS.PAYMENT_TYPE,
    icon: 'icon-dollar',
    value: payment.paymentType,
    id: 'paymentType',
    FieldType: DetailsHumanizedField,
  },
  {
    label: PAYMENTS.PAYMENT_AMOUNT,
    value: formatToDollar(payment.paymentAmount),
    prefix: '$',
    icon: 'icon-dollar',
    id: 'paymentAmount',
    FieldType: DetailsField,
  },
  {
    label: PAYMENTS.FUNDS_TYPE,
    value: payment.fundsType,
    icon: 'icon-type',
    id: 'fundsType',
    FieldType: DetailsHumanizedField,
  },
  {
    label: PAYMENTS.PAID_ON,
    value: payment.paidAt,
    id: 'paidAt',
    FieldType: DetailsDateField,
  },
  {
    label: PAYMENTS.ADDITIONAL_INFORMATION,
    value: payment.additionalInfo?.replace(REQUESTED_PAYMENT_DATE_REGEX, ''),
    icon: 'icon-referral',
    id: 'additionalInfo',
    FieldType: DetailsField,
  },
  {
    label: PAYMENTS.REQUESTED_FINAL_PAYMENT_DATE,
    value:
      payment.requestedFinalPaymentDate && new Date(payment.requestedFinalPaymentDate).valueOf(),
    icon: 'icon-calendar',
    id: 'requestedFinalPaymentDate',
    FieldType: DetailsDateField,
  },
  {
    label: PAYMENTS.OUTSTANDING_BALANCE_APPLIED,
    icon: 'icon-dollar',
    value: payment.outstandingBalanceApplied && formatToDollar(payment.outstandingBalanceApplied),
    id: 'outstandingBalanceApplied',
    FieldType: DetailsHumanizedField,
  },
  ...(payment.paymentType === paymentTypes.pool
    ? [
        {
          label: PAYMENTS.POOL_ACCOUNT_SETTLEMENT_AND_BALANCE_DOCUMENT,
          value: payment.poolAccountSettlementAndBalanceDocument,
          id: 'poolAccountSettlementAndBalanceDocument',
          onClick: () => downloadAttachment(payment.poolAccountSettlementAndBalanceDocument.path),
          FieldType: DetailsDownloadField,
          forwardable: true,
        },
      ]
    : []),
];

const activityFieldsConfig = payment => [
  {
    label: PAYMENTS.CREATED_BY,
    user: payment.createdBy,
    date: payment.createdAt,
    id: 'createdBy',
    FieldType: DetailsActivityField,
  },
  {
    label: PAYMENTS.UPDATED_BY,
    user: payment.updatedBy,
    date: payment.reassignedAt > payment.updatedAt ? payment.reassignedAt : payment.updatedAt,
    id: 'updatedBy',
    FieldType: DetailsActivityField,
  },
  {
    label: PAYMENTS.GLOBAL_DECISION_BY,
    user: payment.globalDecisionBy,
    date: payment.globalDecisionAt,
    id: 'globalDecisionBy',
    FieldType: DetailsActivityField,
  },
];

const settlementFieldsConfig = settlement => [
  {
    label: PAYMENTS.PLATINUM_OUNCES,
    value: settlement.ouncesPt && settlement.ouncesPt.toFixed(3),
    icon: 'icon-volume',
    id: 'ouncesPt',
    suffix: 'oz.',
    FieldType: DetailsField,
  },
  {
    label: PAYMENTS.PLATINUM_PRICE_PER_OUNCE,
    value: formatToDollar(settlement.pricePerOuncePt),
    prefix: '$',
    icon: 'icon-dollar',
    id: 'pricePerOuncePt',
    FieldType: DetailsField,
  },
  {
    label: PAYMENTS.PALLADIUM_OUNCES,
    value: settlement.ouncesPd && settlement.ouncesPd.toFixed(3),
    icon: 'icon-volume',
    id: 'ouncesPd',
    suffix: 'oz.',
    FieldType: DetailsField,
  },
  {
    label: PAYMENTS.PALLADIUM_PRICE_PER_OUNCE,
    value: formatToDollar(settlement.pricePerOuncePd),
    prefix: '$',
    icon: 'icon-dollar',
    id: 'pricePerOuncePd',
    FieldType: DetailsField,
  },
  {
    label: PAYMENTS.RHODIUM_OUNCES,
    value: settlement.ouncesRh && settlement.ouncesRh.toFixed(3),
    icon: 'icon-volume',
    id: 'ouncesRh',
    suffix: 'oz.',
    FieldType: DetailsField,
  },
  {
    label: PAYMENTS.RHODIUM_PRICE_PER_OUNCE,
    value: formatToDollar(settlement.pricePerOunceRh),
    prefix: '$',
    icon: 'icon-dollar',
    id: 'pricePerOunceRh',
    FieldType: DetailsField,
  },
];

const paymentDetailsContainerConfig = () => ({
  header: PAYMENTS.GENERAL_DETAILS,
  template: [
    'relatedCompany relatedCompany relatedLot relatedLot',
    'paymentType paymentAmount fundsType paidAt',
    'additionalInfo additionalInfo outstandingBalanceApplied requestedFinalPaymentDate',
    'poolAccountSettlementAndBalanceDocument poolAccountSettlementAndBalanceDocument poolAccountSettlementAndBalanceDocument poolAccountSettlementAndBalanceDocument',
  ],
});

const activityContainerConfig = () => ({
  header: PAYMENTS.ACTIVITY,
  template: [
    'createdBy createdBy updatedBy updatedBy',
    'globalDecisionBy globalDecisionBy globalDecisionBy globalDecisionBy',
  ],
});

const settlementContainerConfig = () => ({
  header: PAYMENTS.SETTLEMENT,
  template: [
    'ouncesPt ouncesPt pricePerOuncePt pricePerOuncePt',
    'ouncesPd ouncesPd pricePerOuncePd pricePerOuncePd',
    'ouncesRh ouncesRh pricePerOunceRh pricePerOunceRh',
    'balanceRemaining balanceRemaining outstandingBalanceApplied outstandingBalanceApplied',
  ],
});

export {
  paymentDetailsFieldsConfig,
  activityFieldsConfig,
  settlementFieldsConfig,
  paymentDetailsContainerConfig,
  activityContainerConfig,
  settlementContainerConfig,
};
