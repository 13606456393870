import {
  clearShipmentPackagesListActionType,
  shipmentPackagesListActionType,
} from 'actions/Shipments/shipmentPackagesList';

const INITIAL_STATE = {
  packages: [],
};

const ShipmentPackagesListReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case clearShipmentPackagesListActionType:
      return {
        packages: [],
      };
    case shipmentPackagesListActionType:
      return {
        packages: [...new Set((action.payload.packages || []).filter(p => p).map(p => p._id || p))],
      };
    default:
      return state;
  }
};

export { ShipmentPackagesListReducer };
