import {
  payoutRulesActionType,
  payoutRulesSuccessActionType,
  payoutRulesFailureActionType,
  clearPayoutRulesActionType,
} from 'actions/Payments/payoutRulesList';

const INITIAL_STATE = {
  isPending: false,
  payoutRules: [],
};

const PayoutRulesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case payoutRulesActionType:
      return {
        ...state,
        isPending: true,
      };
    case payoutRulesSuccessActionType:
      return {
        isPending: false,
        payoutRules: action.payload.data,
      };
    case payoutRulesFailureActionType:
      return {
        ...state,
        isPending: false,
      };
    case clearPayoutRulesActionType:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};

export { PayoutRulesReducer };
