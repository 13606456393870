const agreementsStatusFilterActionType = 'AGREEMENT_STATUS_ACTION';
function agreementsStatusFilterAction(status) {
  return {
    type: agreementsStatusFilterActionType,
    payload: { status },
  };
}

const agreementsFiltersClearActionType = 'AGREEMENT_FILTERS_CLEAR_ACTION';
function agreementsFiltersClearAction() {
  return {
    type: agreementsFiltersClearActionType,
  };
}

function agreementsStatusFilter(status) {
  return dispatch => {
    dispatch(agreementsStatusFilterAction(status));
  };
}

function agreementsFiltersClear() {
  return dispatch => {
    dispatch(agreementsFiltersClearAction());
  };
}

export {
  agreementsFiltersClearActionType,
  agreementsFiltersClearAction,
  agreementsStatusFilterActionType,
  agreementsStatusFilterAction,
  agreementsStatusFilter,
  agreementsFiltersClear,
};
