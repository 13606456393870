import React from 'react';
import { connect } from 'react-redux';

import { isGlobalUserType } from 'shared/helpers/matchers/isGlobalUserType';

import { CustomerShipmentsOverview } from './components/CustomerShipmentsList/ConnectedCustomerShipmentsOverview';
import { GlobalShipmentsOverview } from './components/GlobalShipmentsList/GlobalShipmentsOverview';

const ShipmentsOverviewLayout = ({ auth }) =>
  isGlobalUserType(auth.user.userType) ? (
    <GlobalShipmentsOverview />
  ) : (
    <CustomerShipmentsOverview />
  );

const mapStateToProps = state => ({
  auth: state.auth,
});

const ShipmentsOverview = connect(mapStateToProps)(ShipmentsOverviewLayout);

export { ShipmentsOverview, ShipmentsOverviewLayout };
