import axios from 'axios';
import { saveAs } from 'file-saver';

import { PACKAGES } from 'shared/helpers/constants/packages/packageConstants';
import { messageTypes } from 'shared/helpers/constants/snackbar/snackbarMessageTypes';

const API_HOST_AUTH = process.env.REACT_APP_API;

const printPackageLabelsActionType = 'PRINT_PACKAGE_LABELS';
const printPackageLabels = () => ({
  type: printPackageLabelsActionType,
});

const printPackageLabelsSuccessActionType = `${printPackageLabelsActionType}_SUCCESS`;
const printPackageLabelsSuccess = () => ({
  type: printPackageLabelsSuccessActionType,
});

const printPackageLabelsFailureActionType = `${printPackageLabelsActionType}_FAILURE`;
const printPackageLabelsFailure = () => ({
  type: printPackageLabelsFailureActionType,
});

const assignedViewPrintPackageLabels =
  (type, packageClass, relatedId, packages = []) =>
  dispatch => {
    dispatch(printPackageLabels());

    return axios
      .get(
        `${API_HOST_AUTH}/packages/print-labels/${type}/${packageClass}/${relatedId}/${packages.join()}`,
        { responseType: 'arraybuffer' },
      )
      .then(res => {
        dispatch(printPackageLabelsSuccess());
        saveAs(new Blob([res.data], { type: res.headers['content-type'] }), 'packageLabels.pdf');

        return {
          message: PACKAGES.PRINT_PACKAGE_LABELS_SUCCESS,
          messageType: messageTypes.success,
        };
      })
      .catch(() => {
        dispatch(printPackageLabelsFailure());

        return Promise.reject({
          message: PACKAGES.PRINT_PACKAGE_LABELS_ERROR,
          messageType: messageTypes.error,
        });
      });
  };

export {
  printPackageLabels,
  printPackageLabelsSuccess,
  printPackageLabelsFailure,
  assignedViewPrintPackageLabels,
  printPackageLabelsActionType,
  printPackageLabelsSuccessActionType,
  printPackageLabelsFailureActionType,
};
