import {
  getPrivilegedCompaniesActionType,
  getExceptionCompaniesSuccessActionType,
  getTrustedCompaniesSuccessActionType,
  getPrivilegedCompaniesFailureActionType,
  clearPrivilegedCompaniesActionType,
} from 'actions/Companies/privilegedCompaniesList';

const INITIAL_STATE = {
  isPending: false,
  privilegedCompanies: {
    exceptions: {
      hedge_not_required: [],
      hedge_required: [],
    },
    trusted: [],
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getPrivilegedCompaniesActionType:
      return {
        ...state,
        isPending: true,
      };
    case getExceptionCompaniesSuccessActionType:
      return {
        isPending: false,
        privilegedCompanies: {
          exceptions: {
            ...state.privilegedCompanies.exceptions,
            ...action.payload.data,
          },
        },
      };
    case getTrustedCompaniesSuccessActionType:
      return {
        ...state,
        isPending: false,
        privilegedCompanies: {
          trusted: [...action.payload.data],
        },
      };
    case getPrivilegedCompaniesFailureActionType:
      return {
        ...state,
        isPending: false,
      };
    case clearPrivilegedCompaniesActionType:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};
