import { css } from 'styled-components';
import { media } from 'shared/helpers/styling/styling';

export const ButtonStyles = css`
  .btn {
    width: 160px;
    height: 40px;
    line-height: 40px;
    padding: 0 10px;
    border-radius: 20px;
    color: var(--whiteGRG);
    font-size: 0.9em;
    cursor: pointer;
    @media print {
      display: none;
    }

    ${media.phone`
    width: auto;
    padding: 0 22px;
    `}

    &.btn-search {
      background: var(--outerSpace);
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      width: 95px;

      &:active,
      &:focus {
        box-shadow: none;
      }

      &:hover {
        background: var(--regentGray);
      }
    }

    &.btn-primary {
      background: var(--atlantis);
      border: none;

      &.disabled,
      &:disabled,
      &.disabled:active,
      &.disabled:focus,
      &.disabled:hover {
        background: var(--fiord);
        border: none;
        cursor: not-allowed;
      }

      &:active,
      &:focus {
        box-shadow: none;
        background: var(--sushi);
      }

      &:not([disabled]):not(.disabled):active {
        box-shadow: none;
        background: var(--sushi);
      }

      &:hover {
        background: var(--conifer);
      }
    }

    &.btn-secondary:not([disabled]):not(.disabled):active {
      box-shadow: none;
    }

    &.btn-outline-danger {
      border: none;
      color: var(--red);
      box-shadow: 0 0 0 2px var(--red);

      &.disabled,
      &:disabled,
      &.disabled:active,
      &.disabled:focus,
      &.disabled:hover {
        color: var(--fiord);
        box-shadow: 0 0 0 2px var(--fiord);
        cursor: not-allowed;
      }

      &:active,
      &:focus {
        background: transparent;
        box-shadow: 0 0 0 2px var(--solidRed);
        color: var(--solidRed);
      }

      &:not([disabled]):not(.disabled):active {
        background: transparent;
        box-shadow: 0 0 0 2px var(--solidRed);
        color: var(--solidRed);
      }

      &:hover {
        background: transparent;
        box-shadow: 0 0 0 2px var(--solidRed);
        color: var(--solidRed);
      }
    }

    &.btn-outline-secondary {
      border: none;
      color: var(--atlantis);
      box-shadow: 0 0 0 2px var(--atlantis);

      &.disabled,
      &:disabled,
      &.disabled:active,
      &.disabled:focus,
      &.disabled:hover {
        color: var(--fiord);
        box-shadow: 0 0 0 2px var(--fiord);
        cursor: not-allowed;
      }

      &:active,
      &:focus {
        background: transparent;
        box-shadow: 0 0 0 2px var(--sushi);
        color: var(--sushi);
      }

      &:not([disabled]):not(.disabled):active {
        background: transparent;
        box-shadow: 0 0 0 2px var(--sushi);
        color: var(--sushi);
      }

      &:hover {
        background: transparent;
        box-shadow: 0 0 0 2px var(--conifer);
        color: var(--conifer);
      }
    }

    &.btn-menuDropdown {
      width: 100%;
      background: transparent;
      box-shadow: none;
      cursor: pointer;
      border: none;
      height: 50px;
      color: var(--atlantis);

      &:not([disabled]):not(.disabled):active {
        background: transparent;
        box-shadow: none;
        color: var(--atlantis);
        border: none;
      }
    }

    &.btn-selectDropdown {
      background: var(--limedSpruce);
      box-shadow: none;
      cursor: pointer;
      border: 1px solid var(--lynch);

      &[aria-expanded='true'] {
        z-index: 1030;
      }

      &:not([disabled]):not(.disabled):active {
        background: var(--limedSpruce);
        box-shadow: none;
        border: 1px solid var(--lynch);
        z-index: 1030;
      }
    }

    &.btn-cancel,
    &.btn-link {
      color: var(--tangerine);
      font-size: 14px;
      cursor: pointer;
      background: transparent;
      box-shadow: none;
      border: none;

      &:not([disabled]):not(.disabled):active {
        background: transparent;
        box-shadow: none;
        border: none;
      }

      &:disabled {
        cursor: not-allowed;
      }

      &:not([disabled]):not(.disabled):hover {
        color: var(--atlantis);
        text-decoration: none;
      }
    }

    &.btn-liteRouter {
      @extend .btn-cancel;
      font-size: 1.2em;
      padding-left: 0;
      width: auto;
    }
  }
`;
