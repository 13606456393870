import React, { Fragment } from 'react';
import { useDispatch } from 'react-redux';

import { openModal } from 'actions/shared/modal';

import { AddButton } from 'shared/components/Buttons/AddButton/AddButton';
import { Separator } from 'shared/components/Form';
import { removeSettlementRequestModal } from 'shared/helpers/constants/modalConstants';
import { POOL_ACCOUNTS } from 'shared/helpers/constants/poolAccounts/poolAccountsConstants';

import { OneSettlementForm } from '../RequestSettlementForm/OneSettlementForm/OneSettlementForm';

const RequestSettlementFormList = ({ fields, location, invalid, ...props }) => {
  const dispatch = useDispatch();

  const addField = () => fields.push({ requestPayment: false, formId: Date.now() });

  const removeField = index => {
    dispatch(openModal(removeSettlementRequestModal, () => fields.remove(index)));
  };

  return (
    <>
      {fields.map((settlement, index) => (
        <Fragment key={settlement}>
          <OneSettlementForm
            {...props}
            settlement={settlement}
            index={index}
            header={`${POOL_ACCOUNTS.SETTLEMENT} ${index + 1}`}
            onRemove={fields.length > 1 && (() => removeField(index))}
            location={location}
            invalid={invalid}
            fieldsNumber={fields.length}
          />
          <Separator />
        </Fragment>
      ))}
      {fields.length < 10 && !(location.state && location.state.relatedLot) && (
        <AddButton
          onClick={addField}
          disabled={invalid || props.summaryAvailableLots.length <= fields.length}
        >
          {POOL_ACCOUNTS.ADD}
        </AddButton>
      )}
    </>
  );
};

export { RequestSettlementFormList };
