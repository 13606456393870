import { statusesForCustomer } from 'shared/helpers/constants/lots/statusesForCustomer';
import { statusesForGlobal } from 'shared/helpers/constants/lots/statusesForGlobal';
import { statusesForGrading } from 'shared/helpers/constants/lots/statusesForGrading';
import { isGlobalRefiningGroup } from 'shared/helpers/matchers/isGlobalRefiningGroup';
import { dateToTimestamp } from 'shared/helpers/parsers/date';
import { dateWithTimeToTimestamp } from 'shared/helpers/parsers/date';
import { safeReplace } from 'shared/helpers/parsers/text';

function parseHedge(hedge) {
  const {
    relatedLot,
    relatedCompany,
    hedgeType,
    materialType,
    deliveryDate,
    deliveryDateGRG,
    lotStatuses,
    requestedHedgeDate,
    requestedHedgeTime,
    hedgeOptions,
    ...hedgeRest
  } = hedge;

  const requestNow = hedgeOptions?.value === 'requestNow';

  const isGlobal = isGlobalRefiningGroup(relatedCompany.label);

  const parsedHedge = {
    ...hedgeRest,
    materialUnits: safeReplace(hedge.materialUnits, ',', '.'),
    materialWeight: safeReplace(hedge.materialWeight, ',', '.'),
    platinumOz: safeReplace(hedge.platinumOz, ',', '.'),
    palladiumOz: safeReplace(hedge.palladiumOz, ',', '.'),
    rhodiumOz: safeReplace(hedge.rhodiumOz, ',', '.'),
    relatedCompany: relatedCompany && relatedCompany.value,
    relatedLot: relatedLot && relatedLot.value,
    hedgeType: hedgeType && hedgeType.value,
    materialType: materialType && materialType.value,
    ...(relatedCompany.materialCountRequired && {
      requestNow: !!requestNow,
      ...(!requestNow && {
        requestedHedgeDate: dateWithTimeToTimestamp(requestedHedgeDate, requestedHedgeTime),
      }),
    }),
  };

  if (
    (!isGlobal &&
      [
        statusesForCustomer.received,
        statusesForCustomer.summary_available,
        statusesForGrading.receipt_ready,
        statusesForGrading.receipt_accepted,
      ].includes(lotStatuses.statusForCustomer)) ||
    (isGlobal &&
      [
        statusesForGlobal.shipped_from_global,
        statusesForGlobal.assay_results_received,
        statusesForGlobal.ship_summary_accepted,
      ].includes(lotStatuses.statusForGlobal))
  ) {
    return parsedHedge;
  }

  return {
    ...parsedHedge,
    deliveryDate: deliveryDate && dateToTimestamp(deliveryDate),
    deliveryDateGRG: deliveryDateGRG && dateToTimestamp(deliveryDateGRG),
  };
}

export { parseHedge };
