import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getTimelineActionType = 'GET_TIMELINE';
const getTimelineAction = () => ({
  type: getTimelineActionType,
});

const getTimelineSuccessActionType = `${getTimelineActionType}_SUCCESS`;
const getTimelineSuccessAction = data => ({
  type: getTimelineSuccessActionType,
  payload: {
    data,
  },
});

const getTimelineFailureActionType = `${getTimelineActionType}_FAILURE`;
const getTimelineFailureAction = () => ({
  type: getTimelineFailureActionType,
});

const clearTimelineActionType = 'CLEAR_TIMELINE';
const clearTimeline = () => ({
  type: clearTimelineActionType,
});

const getTimeline = lotId => async dispatch => {
  dispatch(getTimelineAction());

  try {
    const { data } = await axios.get(`${API_HOST_AUTH}/lots/${lotId}/timeline`);
    dispatch(getTimelineSuccessAction(data));
  } catch (error) {
    dispatch(getTimelineFailureAction());

    return Promise.reject(error.data);
  }
};

export {
  getTimelineActionType,
  getTimelineAction,
  getTimelineSuccessActionType,
  getTimelineSuccessAction,
  getTimelineFailureActionType,
  getTimelineFailureAction,
  clearTimelineActionType,
  clearTimeline,
  getTimeline,
};
