import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  clearShipmentsOverview,
  getShipmentsOverview,
} from 'actions/Shipments/lotShipmentsOverviewList';

import { OverviewList } from 'shared/components/OverviewList/OverviewList';
import { isTablet } from 'shared/helpers/constants/resolutionsConstants';
import { SHIPMENTS } from 'shared/helpers/constants/shipments/shipmentsConstants';
import {
  getItemsTemplateBuyer,
  itemsDetailsTemplate,
  itemsTemplate,
  itemsTemplateMobile,
} from 'shared/helpers/constants/shipments/shipmentsOverviewTableConfig';
import { isBuyerUser } from 'shared/helpers/matchers/checkUserType';
import { useWindowWidth } from 'shared/hooks/useWindowWidth';

import { history } from 'utils/history';

export const OverviewShipmentsList = () => {
  const details = useSelector(state => state.auth.access.shipments.details);
  const lotId = useSelector(state => state.lotDetails.lot._id);
  const { shipments, pages, isPending } = useSelector(state => state.shipmentsOverviewList);
  const userType = useSelector(state => state.auth.user.userType);

  const dispatch = useDispatch();

  const windowWidth = useWindowWidth();
  const isMobile = windowWidth < isTablet;

  const { id } = useParams();
  const tableActions = () => {
    const actions = [];

    if (details) {
      actions.push({
        icon: 'arrow-right',
        action: 'Details',
        onClick: thisShipment => () =>
          history.push(`/lots/list/${lotId}/shipments/${thisShipment._id}`),
      });
    }
    return actions;
  };

  const getShipments = (_, limit) => {
    dispatch(getShipmentsOverview(id, limit));
  };

  const handleClearShipmentsOverview = () => {
    dispatch(clearShipmentsOverview);
  };

  const overviewListInputProps = {
    items: { docs: shipments, pages },
    isPending,
  };

  return (
    <div>
      <OverviewList
        getList={getShipments}
        clearData={handleClearShipmentsOverview}
        input={overviewListInputProps}
        itemsTemplate={isBuyerUser(userType) ? getItemsTemplateBuyer : itemsTemplate}
        actionsTemplate={isBuyerUser(userType) ? [] : tableActions()}
        itemsTemplateMobile={itemsTemplateMobile}
        itemDetailsTemplate={itemsDetailsTemplate}
        isMobile={isMobile}
        label={SHIPMENTS.SHIPMENTS}
      />
    </div>
  );
};
