import { denominatedIn } from '../materials/denominationTable';

const materialTypeForAssay = {
  mono_canned: 'mono_canned',
  mono_decanned: 'mono_decanned',
  foil_canned: 'foil_canned',
  foil_decanned: 'foil_decanned',
  DPF_canned: 'DPF_canned',
  DPF_decanned: 'DPF_decanned',
  sensors: 'sensors',
  sweeps: 'sweeps',
  other: 'other',
  beads_canned: 'beads_canned',
  beads_decanned: 'beads_decanned',
  industrial_foil: 'industrial_foil',
  industrial_mono: 'industrial_mono',
  high_grade_dust: 'high_grade_dust',
};

const materialTypeWithConverters = {
  ...materialTypeForAssay,
  catalytic_converters: 'catalytic_converters',
};

const materialTypeForGrading = {
  mono_canned: 'mono_canned',
  foil_canned: 'foil_canned',
  DPF_canned: 'DPF_canned',
  sensors: 'sensors',
  beads_canned: 'beads_canned',
  industrial_foil: 'industrial_foil',
  industrial_mono: 'industrial_mono',
};

const materialTypeCommodities = {
  mono_canned: 'mono_canned',
  aftermarkets: 'aftermarkets',
  foil_canned: 'foil_canned',
  DPF_canned: 'DPF_canned',
  beads_canned: 'beads_canned',
  sensors: 'sensors',
  mono_decanned: 'mono_decanned',
  foil_decanned: 'foil_decanned',
  DPF_decanned: 'DPF_decanned',
  beads_decanned: 'beads_decanned',
  industrial_foil: 'industrial_foil',
  industrial_mono: 'industrial_mono',
  high_grade_dust: 'high_grade_dust',
  sweeps: 'sweeps',
};

const materialTypeOutbound = {
  mono_decanned: 'mono_decanned',
  DPF_decanned: 'DPF_decanned',
  sweeps: 'sweeps',
  foil_decanned: 'foil_decanned',
  beads_decanned: 'beads_decanned',
  sensors: 'sensors',
  high_grade_dust: 'high_grade_dust',
};

const materialTypesUnitCountChange = {
  mono_canned: 'mono_canned',
  foil_canned: 'foil_canned',
  DPF_canned: 'DPF_canned',
  beads_canned: 'beads_canned',
};

const materialTypesPackingList = {
  catalytic_converters: 'catalytic_converters',
  sensors: 'sensors',
  mono_canned: 'mono_canned',
  mono_decanned: 'mono_decanned',
  foil_canned: 'foil_canned',
  foil_decanned: 'foil_decanned',
  DPF_canned: 'DPF_canned',
  DPF_decanned: 'DPF_decanned',
  sweeps: 'sweeps',
  beads_canned: 'beads_canned',
  beads_decanned: 'beads_decanned',
  industrial_foil: 'industrial_foil',
  industrial_mono: 'industrial_mono',
  high_grade_dust: 'high_grade_dust',
};

const materialTypesSamplePackage = {
  mono_canned: 'mono_canned',
  foil_canned: 'foil_canned',
  DPF_canned: 'DPF_canned',
  beads_canned: 'beads_canned',
  other: 'other',
};

const availableMaterialTypeForAssay = {
  all: Object.values(materialTypeForAssay),
};

const availableMaterialTypeForGrading = {
  all: Object.values(materialTypeForGrading),
};

const availableMaterialTypeWithConverters = {
  all: Object.values(materialTypeWithConverters),
};

const availableMaterialTypeCommodities = {
  all: Object.values(materialTypeCommodities),
};

const availableMaterialTypeOutbound = {
  all: Object.values(materialTypeOutbound),
};

const availableMaterialTypesPackingList = {
  all: Object.values(materialTypesPackingList),
};
const availableMaterialTypesSamplePackage = {
  all: Object.values(materialTypesSamplePackage),
};

const availableMaterialTypeEntity = {
  all: Object.values(denominatedIn),
};

const materialTypesForUnitCountChange = {
  all: Object.values(materialTypesUnitCountChange),
};

const allMaterials = Object.values({
  ...materialTypeForAssay,
  ...materialTypeForGrading,
  ...materialTypeCommodities,
  ...materialTypeOutbound,
});

export {
  availableMaterialTypeForAssay,
  availableMaterialTypeForGrading,
  availableMaterialTypeCommodities,
  availableMaterialTypeEntity,
  availableMaterialTypeOutbound,
  availableMaterialTypesPackingList,
  materialTypeForAssay,
  materialTypeOutbound,
  materialTypesPackingList,
  materialTypesForUnitCountChange,
  allMaterials,
  availableMaterialTypesSamplePackage,
  materialTypeWithConverters,
  availableMaterialTypeWithConverters,
};
