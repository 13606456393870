import { formatDate, formatToUS } from 'shared/helpers/parsers/formaters';
import { humanize, replaceEmpty } from 'shared/helpers/parsers/text';

import { deleteCommodityModal } from '../modalConstants';
import { dangerOptions, successOptions } from '../snackbar/snackbarOptionTypes';
import { COMMODITIES } from './commoditiesConstants';

const itemTemplate = [
  {
    label: COMMODITIES.FROM_LOT,
    render: ({ fromLot }) => fromLot.grgLotNumber,
  },
  {
    label: COMMODITIES.TO_LOT,
    render: ({ toLot }) => toLot.grgLotNumber,
  },
  {
    label: COMMODITIES.MATERIAL_UNITS,
    render: ({ materialUnits }) => replaceEmpty(formatToUS(materialUnits, 0)),
  },
  {
    label: COMMODITIES.MATERIAL_WEIGHT,
    render: ({ materialWeight }) => replaceEmpty(formatToUS(materialWeight)),
  },
  {
    label: COMMODITIES.MATERIAL_TYPE,
    render: ({ materialType }) => humanize(materialType),
  },
  {
    label: COMMODITIES.CREATED_BY,
    render: ({ createdBy }) => `${createdBy.firstName} ${createdBy.lastName}`,
  },
  {
    label: COMMODITIES.CREATED_ON,
    render: ({ createdAt }) => formatDate(createdAt),
  },
];

const itemsTemplateMobile = [
  {
    label: COMMODITIES.FROM_LOT,
    render: ({ fromLot }) => fromLot.grgLotNumber,
  },
  {
    label: COMMODITIES.TO_LOT,
    render: ({ toLot }) => toLot.grgLotNumber,
  },
];

const actionsTemplate = [
  {
    icon: 'bin',
    action: 'Delete',
    onClick(commodity) {
      return () => {
        this.props.openModal(deleteCommodityModal, () => {
          this.props.deleteCommodity(commodity._id).then(
            res => {
              this.props.showSnackbar(successOptions, res.message);
              this.props.getLot(this.props.match.params.id);
              this.props.getCommoditiesOverviewList(
                this.props.match.params.id,
                this.props.commoditiesOverviewList.items.limit,
              );
            },
            error => {
              this.props.showSnackbar(dangerOptions, error.message);
            },
          );
        });
      };
    },
  },
];


const actionsTemplateCommodities = (auth, deleteCommodity) => {
  const actionsTemplate = [
    {
      icon: 'bin',
      action: 'Delete',
      onClick: commodity => () => deleteCommodity(commodity),
    },
  ];
  actionsTemplate.access = function access() {
    return auth.access.commodities.delete;
  }

  return actionsTemplate
}


const [, , ...itemsDetailsTemplate] = itemTemplate;

export { itemTemplate, actionsTemplate,actionsTemplateCommodities, itemsTemplateMobile, itemsDetailsTemplate };
