import logoMobileWest from 'assets/images/grg-logo-mobile-west.svg';
import logoAlpha from 'assets/images/alpha-logo.png';
import logoMobileAlpha from 'assets/images/alpha-logo-mobile.png';
import logoMobile from 'assets/images/grg-logo-mobile.svg';
import logoWest from 'assets/images/grg-logo-west.svg';
import logo from 'assets/images/grg-logo.svg';

import { resolveVersion, versions } from 'utils/versioning';

const layout = resolveVersion({
  [versions.alpha]: {
    logo: logoAlpha,
    logoMobile: logoMobileAlpha,
  },
  [versions.global]: {
    logo,
    logoMobile,
  },
  [versions.west]: {
    logo: logoWest,
    logoMobile: logoMobileWest,
  },
});

export { layout };
