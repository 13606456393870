const materialClass = {
  heavies: 'heavies',
  fines: 'fines',
  na: 'na',
};

const materialClassSymbol = {
  heavies: 'H',
  fines: 'F',
  na: 'NA',
};

const availableMaterialClass = {
  all: Object.values(materialClass),
};

export { availableMaterialClass, materialClassSymbol };
