const customerLotsSections = {
  materialDeliveryRequired: {
    name: 'materialDeliveryRequired',
    title: 'Material delivery required',
  },
  open: {
    name: 'open',
    title: 'Open',
  },
  receivedByGlobal: {
    name: 'received',
    title: 'Received by Global',
  },
  summaryAvailable: {
    name: 'summaryAvailable',
    title: 'Summary available',
  },
  settled: {
    name: 'settled',
    title: 'Settled',
  },
  receiptReady: {
    name: 'receiptReady',
    title: 'Receipt ready',
  },
  receiptAccepted: {
    name: 'receiptAccepted',
    title: 'Receipt accepted',
  },
  finalized: {
    name: 'finalized',
    title: 'Finalized',
  },
};

const customerLotsSectionsArray = Object.keys(customerLotsSections);

export { customerLotsSections, customerLotsSectionsArray };
