import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';
import styled from 'styled-components';

import { cssVar, media } from 'shared/helpers/styling/styling';

const MultipleFieldsSectionLayout = ({ fields, className }) => (
  <div className={className}>
    {fields.map(({ name, component, type, label, suffix, field, ...rest }) => (
      <Field
        key={name}
        name={name}
        component={component}
        type={type}
        label={label}
        suffix={suffix}
        field={field}
        pretendMobile
        {...rest}
      />
    ))}
  </div>
);

const MultipleFieldsSection = styled(MultipleFieldsSectionLayout)`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  padding: 0 22px;

  & > * {
    padding: 0 15px;

    &:not(:last-child) {
      border-right: 1px solid ${cssVar('riverBedDarker')};
    }
  }

  ${media.tablet`
    padding: 0;
  `}

  ${media.mobile`
    flex-direction: column;

    & > * {
      padding: 0 90px;

      &:not(:last-child) {
        border-right: none;
      }
    }
  `}

  ${media.phone`
    & > * {
      padding: 0 15px;
    }
  `}
`;

MultipleFieldsSection.propTypes = {
  fields: PropTypes.array.isRequired,
};

export { MultipleFieldsSection };
