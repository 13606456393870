import { formatDate, formatToDollarWithPrefix, formatToUS } from 'shared/helpers/parsers/formaters';
import { replaceEmpty, humanize } from 'shared/helpers/parsers/text';

import { JOURNAL } from './journalConstants';

const itemsTemplate = [
  {
    label: JOURNAL.RELATED_LOT,
    render: ({ relatedLot }) => replaceEmpty(relatedLot?.grgLotNumber),
    highlight: true,
  },
  {
    label: JOURNAL.RELATED_COMPANY,
    render: ({ relatedCompany }) => replaceEmpty(relatedCompany && relatedCompany.companyName),
  },
  {
    label: JOURNAL.TYPE,
    render: ({ type }) => humanize(type),
  },
  {
    label: JOURNAL.METAL,
    render: ({ metal }) => humanize(metal),
  },
  {
    label: JOURNAL.OUNCES,
    render: ({ ounces }) => formatToUS(ounces, 3),
  },
  {
    label: JOURNAL.PRICE_PER_OUNCE,
    render: ({ pricePerOunce }) => replaceEmpty(formatToDollarWithPrefix(pricePerOunce)),
  },
  {
    label: JOURNAL.CREATED_AT,
    render: ({ createdAt }) => formatDate(createdAt),
  },
  {
    label: JOURNAL.CREATED_BY,
    render: ({ createdBy }) => `${createdBy.firstName} ${createdBy.lastName}`,
  },
];

const itemsTemplateMobile = [
  {
    label: JOURNAL.RELATED_LOT,
    render: ({ relatedLot }) =>
      replaceEmpty(relatedLot && (relatedLot.customLotNumber || relatedLot.grgLotNumber)),
    highlight: true,
  },
  {
    label: JOURNAL.RELATED_COMPANY,
    render: ({ relatedCompany }) => replaceEmpty(relatedCompany && relatedCompany.companyName),
  },
];

const [, , ...itemsDetailsTemplate] = itemsTemplate;

export { itemsTemplate, itemsTemplateMobile, itemsDetailsTemplate };
