import { useState } from 'react';
import { useSelector } from 'react-redux';

import { ListCollapse } from 'shared/components/ListCollapse/ListCollapse';
import {
  OverviewListSection,
  OverviewListSectionWrapper,
} from 'shared/components/OverviewList/OverviewListSection/OverviewListSection';
import { SHIPMENTS } from 'shared/helpers/constants/shipments/shipmentsConstants';

import { OverviewShipmentsList } from 'pages/Shipments/components/OverviewList/OverviewShipmentsList';

export const ShipmentsOverview = () => {
  const [displayContent, setDisplayContent] = useState(true);

  const shipmentsOverviewListTotal = useSelector(state => state.shipmentsOverviewList.total);

  const toggleContent = () => {
    shipmentsOverviewListTotal && setDisplayContent(prev => !prev);
  };

  return (
    <OverviewListSectionWrapper isOpen={displayContent} total={shipmentsOverviewListTotal}>
      <OverviewListSection isOpen={displayContent} total={shipmentsOverviewListTotal}>
        <div>
          <ListCollapse
            collapse={displayContent}
            toggleList={toggleContent}
            label={SHIPMENTS.SHIPMENTS}
          />
        </div>
      </OverviewListSection>
      <OverviewShipmentsList />
    </OverviewListSectionWrapper>
  );
};
