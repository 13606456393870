import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getIncomingLotsOverview,
  incomingLotsOverviewClearAction,
  setPriority,
} from 'actions/Lots/incomingLotsOverview';
import { clearLotDetails } from 'actions/Lots/lotDetails';
import { moveToProcessing as moveToProcessingAction } from 'actions/Lots/moveToProcessing';
import { clearAllPackagesOverview } from 'actions/Packages/packagesOverviewList';

import { ModalCustom } from 'components/ModalCustom/ModalCustom';

import { Button } from 'shared/components/Buttons/Button';
import { DraggableSection } from 'shared/components/DraggableSection/DraggableSection';
import { Icon } from 'shared/components/Icon/Icon';
import { OverviewList } from 'shared/components/OverviewList/OverviewList';
import {
  getDraggableListSections,
  getExpandedProcessingActionsTemplate,
  getSectionTemplates,
  incomingSections,
} from 'shared/helpers/constants/lots/incomingSections';
import {
  actionsTemplate,
  draggableActionsTemplate,
  itemTemplate,
} from 'shared/helpers/constants/lots/incomingTableConfig';
import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';
import { statusesForGlobal } from 'shared/helpers/constants/lots/statusesForGlobal';
import { moveToProcessing } from 'shared/helpers/constants/modalConstants';
import { isTablet } from 'shared/helpers/constants/resolutionsConstants';
import { SHARED } from 'shared/helpers/constants/sharedConstants';

import { getBrowserWidth } from 'utils/responsive';

import { InactiveSensorPackages } from '../LotsManagement/Update/UpdateLot/components/InactiveSensorPackages/InactiveSensorPackages';
import { OverviewLotsSection } from '../OverviewLotsSection/OverviewLotsSection';
import { UploadCountSheet } from '../UploadCountSheet/UploadCountSheet';
import {
  Header,
  ModalButtons,
  ModalContent,
  Paragraph,
  StyledLotsIncoming,
  StyledLotsIncomingTitle,
} from './StyledLotsIncoming';

export const LotsIncoming = () => {
  const [isCountSheetModalDisplayed, setIsCountSheetModalDisplayed] = useState(false);
  const [isInactiveSensorPackagesModalDisplayed, setIsInactiveSensorPackagesModalDisplayed] =
    useState(false);
  const [isMoveToProcessingModalDisplayed, setIsMoveToProcessingModalDisplayed] = useState(false);
  const [isMovingToProcessingBtnDisabled, setIsMovingToProcessingBtnDisabled] = useState(false);
  const [isUploadingCountSheetBtnDisabled, setIsUploadingCountSheetBtnDisabled] = useState(false);
  const [selectedLot, setSelectedLot] = useState(null);

  const incomingLotsOverview = useSelector(state => state.incomingLotsOverview);
  const acceptForGrading = useSelector(state => state.acceptForGrading);
  const markGradingComplete = useSelector(state => state.markGradingComplete);
  const auth = useSelector(state => state.auth);
  const intervalId = useRef(null);
  const isDragging = useRef(false);
  const dispatch = useDispatch();

  const windowWidth = getBrowserWidth();

  const reloadDraggableSections = () => {
    dispatch(getIncomingLotsOverview(incomingSections.receivedGradingRequired));
    dispatch(getIncomingLotsOverview(incomingSections.receivedMaterialCount));
  };

  const onDragStart = () => {
    isDragging.current = true;
  };

  const onSelectedChange = (newValues, section) => {
    const { items, selected } = newValues;

    isDragging.current = false;

    if (items?.length) {
      dispatch(
        setPriority(
          section,
          items.map(p => p._id),
          true,
        ),
      );
    }

    if (selected?.length) {
      dispatch(
        setPriority(
          section,
          selected.map(p => p._id),
        ),
      );
    }
  };

  const onSelectedChangeFactory = section => values => onSelectedChange(values, section);

  const getTotal = resource => resource.items?.total;

  const getListFactory = section => (page, limit) =>
    dispatch(getIncomingLotsOverview(section, page, limit));

  const itemOrdersFnGrading = (lot1, lot2) => {
    if (lot1.statusForGlobal === lot2.statusForGlobal) {
      return 2 * (lot1.gradingPriority > lot2.gradingPriority) - 1;
    }
    if (lot1.statusForGlobal === statusesForGlobal.accepted_for_grading) return -1;
    if (lot2.statusForGlobal === statusesForGlobal.accepted_for_grading) return 1;
  };

  const itemsOrderFn = field => (lot1, lot2) =>
    field === incomingSections.receivedGradingRequired.priorityField
      ? itemOrdersFnGrading(lot1, lot2)
      : 2 * (lot1[field] > lot2[field]) - 1;

  const toggleCountSheetModal = lot => () => {
    setIsCountSheetModalDisplayed(prev => !prev);
    setSelectedLot(lot);
  };
  const toggleMoveToProcessingModal = lot => {
    setIsMoveToProcessingModalDisplayed(prev => !prev);
    setSelectedLot(lot);
  };

  const handleToggleInactiveSensorPackagesModal = lot => {
    setIsInactiveSensorPackagesModalDisplayed(prev => !prev);
    setSelectedLot(lot);
  };

  const handleUploadCountSheetSuccess = () => {
    handleUpdateCountSheetBtnState();

    if (selectedLot?.canInactiveIncomingSensorPackages) {
      return handleToggleInactiveSensorPackagesModal(selectedLot);
    }
  };

  const handleMoveToProcessing = async () => {
    setIsMovingToProcessingBtnDisabled(true);

    await dispatch(moveToProcessingAction(selectedLot._id)).then(() => {
      setIsMoveToProcessingModalDisplayed(false);
      dispatch(getIncomingLotsOverview(incomingSections.receivedMaterialCount)).then(() => {
        setIsMovingToProcessingBtnDisabled(false);
      });
    });
  };

  const handleUpdateCountSheetBtnState = () => {
    setIsUploadingCountSheetBtnDisabled(true);

    dispatch(getIncomingLotsOverview(incomingSections.receivedMaterialCount)).then(() => {
      setIsUploadingCountSheetBtnDisabled(false);
    });
  };

  const renderOverviewList = (list, getList) => {
    return (
      <OverviewList
        getList={getList}
        input={list}
        itemsTemplate={itemTemplate}
        actionsTemplate={actionsTemplate}
        isMobile={windowWidth < isTablet}
        label={LOTS.LOTS}
        listLimit={5}
      />
    );
  };

  const renderDraggableList = (list, access, section) => {
    const docs = list.items?.docs || [];

    const sections = getDraggableListSections({ section, docs, itemsOrderFn });
    const topLotPriority = sections.selected.data.filter(l => !l.gradedAt)[0];

    const expandedProcessingActionsTemplate = getExpandedProcessingActionsTemplate({
      isMovingToProcessingBtnDisabled,
      auth,
      isUploadingCountSheetBtnDisabled,
      toggleMoveToProcessingModal,
      reloadDraggableSections,
      toggleCountSheetModal,
      topLotPriority,
      acceptForGrading,
      incomingLotsOverview,
      markGradingComplete,
    });

    const props = {
      auth,
      incomingLotsOverview,
    };

    return (
      !!docs.length && (
        <>
          <DraggableSection
            sections={sections}
            itemTemplate={itemTemplate}
            actionsTemplate={draggableActionsTemplate}
            expandedActionsTemplate={expandedProcessingActionsTemplate}
            getDraggableProps={section.getDraggableProps?.(props, sections, auth.access)}
            disabled={section.isDragDisabled?.(props, auth.access) || !access}
            onSelectedChange={onSelectedChangeFactory(section)}
            onDragStart={onDragStart}
            highlightedColor="beaver"
          />
          <UploadCountSheet
            toggle={toggleCountSheetModal(selectedLot)}
            open={isCountSheetModalDisplayed}
            onSuccess={handleUploadCountSheetSuccess}
            lot={selectedLot}
          />
          <ModalCustom
            isOpen={isMoveToProcessingModalDisplayed}
            modalClose={() => toggleMoveToProcessingModal()}
          >
            <ModalContent>
              <Icon icon="warning icon-exclamation" height={65} width={65} />
              <Header>{moveToProcessing.message}</Header>
              <Paragraph>{moveToProcessing.messageText}</Paragraph>
              <ModalButtons>
                <Button outlineColor="danger" onClick={() => toggleMoveToProcessingModal()}>
                  {SHARED.CLOSE}
                  <Icon icon="icon-cancel" width={11} height={11} size="contain" />
                </Button>
                <Button outlineColor={'--var(acceptColor)'} onClick={() => handleMoveToProcessing()}>
                  {SHARED.PROCEED}
                  <Icon icon="icon-tick" width={20} height={20} size="contain" />
                </Button>
              </ModalButtons>
            </ModalContent>
          </ModalCustom>
        </>
      )
    );
  };

  useEffect(() => {
    reloadDraggableSections();
    intervalId.current = setInterval(() => {
      const anySectionPending = Object.values(incomingLotsOverview).some(p => p.isPending);

      if (!(anySectionPending || isDragging.current)) {
        reloadDraggableSections();
      }
    }, 5000);
  }, []);

  useEffect(() => {
    return () => {
      clearInterval(intervalId.current);
      dispatch(incomingLotsOverviewClearAction());
      dispatch(clearAllPackagesOverview());
      dispatch(clearLotDetails());
    };
  }, []);

  const sectionTemplates = getSectionTemplates({
    getTotal,
    getListFactory,
    incomingLotsOverview,
    renderDraggableList,
    renderOverviewList,
    auth,
  });

  return (
    <StyledLotsIncoming>
      <UploadCountSheet
        toggle={toggleCountSheetModal(selectedLot)}
        open={isCountSheetModalDisplayed}
        onSuccess={handleUploadCountSheetSuccess}
        lot={selectedLot}
      />
      <ModalCustom
        isOpen={isMoveToProcessingModalDisplayed}
        modalClose={toggleMoveToProcessingModal}
      >
        <ModalContent>
          <Icon icon="warning icon-exclamation" height={65} width={65} />
          <Header>{moveToProcessing.message}</Header>
          <Paragraph>{moveToProcessing.messageText}</Paragraph>
          <ModalButtons>
            <Button outlineColor="danger" onClick={toggleMoveToProcessingModal}>
              {SHARED.CLOSE}
              <Icon icon="icon-cancel" width={11} height={11} size="contain" />
            </Button>
            <Button onClick={handleMoveToProcessing}>
              {SHARED.PROCEED}
              <Icon icon="icon-tick" width={20} height={20} size="contain" />
            </Button>
          </ModalButtons>
        </ModalContent>
      </ModalCustom>
      <StyledLotsIncomingTitle>{LOTS.OVERVIEW_INCOMING}</StyledLotsIncomingTitle>
      {sectionTemplates.map(({ name, title, render, total }) => (
        <OverviewLotsSection
          key={name}
          id={name}
          title={title}
          total={total}
          disableTransform={
            incomingSections.receivedGradingRequired.name === name ||
            incomingSections.receivedMaterialCount.name === name
          }
        >
          {render}
        </OverviewLotsSection>
      ))}
      <InactiveSensorPackages
        isOpen={isInactiveSensorPackagesModalDisplayed}
        incomingSensorPackages={selectedLot?.incomingSensorPackagesIdsToInactive}
        toggle={handleToggleInactiveSensorPackagesModal}
        onFinish={() => handleToggleInactiveSensorPackagesModal(null)}
      />
    </StyledLotsIncoming>
  );
};
