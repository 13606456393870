import NotificationsMarkAsResolvedReducer from './markAsResolved';
import NotificationsCountReducer from './notificationCount';
import NotificationsDetailsReducer from './notificationDetails';
import NotificationsListReducer from './notificationsList';

const notificationsRootReducer = {
  markAsResolved: NotificationsMarkAsResolvedReducer,
  notificationsCount: NotificationsCountReducer,
  notificationsList: NotificationsListReducer,
  notificationDetails: NotificationsDetailsReducer,
};

export { notificationsRootReducer };
