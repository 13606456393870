import React from 'react';

import { DETAILS_CONSTANTS } from 'shared/components/Details/constants/detailsConstants';
import { Icon } from 'shared/components/Icon/Icon';
import { StatusIndicator } from 'shared/components/StatusIndicator/StatusIndicator';
import { formatToUS } from 'shared/helpers/parsers/formaters';
import { humanize, replaceEmpty } from 'shared/helpers/parsers/text';

import { formatWeight, packageNumberLabel } from 'pages/Packages/components/OverviewList/helpers';

import { materialClassSymbol } from './materialClass';
import { PACKAGES } from './packageConstants';

const commonItemTemplate = [
  {
    label: PACKAGES.STATUS,
    render: ({ status }) => <StatusIndicator status={status} />,
  },
  {
    label: PACKAGES.PACKAGE_NUMBER,
    render: pkg => `${PACKAGES.PACKAGE_COLON} ${packageNumberLabel(pkg)}`,
  },
  {
    label: PACKAGES.PACKAGE_TYPE,
    render: ({ packageType }) => humanize(packageType),
  },
  {
    label: PACKAGES.MATERIAL_TYPE_DECLARED,
    render: ({ materialTypeDeclared }) => humanize(materialTypeDeclared),
  },
];

const itemsTemplateProcessing = [
  ...commonItemTemplate,
  {
    label: PACKAGES.CONTENTS,
    render: pkg => {
      if (pkg.weightGrossActual && Number.isFinite(pkg.weightTareActual))
        return `${formatToUS(pkg.weightGrossActual)} / ${formatToUS(pkg.weightTareActual)}`;
      if (pkg.weightGrossActual) return `${formatToUS(pkg.weightGrossActual)} / -`;
      return DETAILS_CONSTANTS.EMPTY_FIELD;
    },
  },
];

const itemsTemplate = [
  ...commonItemTemplate,
  {
    label: PACKAGES.CONTENTS_DECLARED,
    render: pkg => {
      if (pkg.weightGrossDeclared && pkg.weightTareDeclared)
        return `${formatToUS(pkg.weightGrossDeclared)} / ${formatToUS(pkg.weightTareDeclared)}`;
      if (pkg.weightGrossDeclared) return `${formatToUS(pkg.weightGrossDeclared)} / -`;
      if (pkg.unitCount) return `${formatToUS(pkg.unitCount, 0)} ${PACKAGES.UNITS}`;
      return DETAILS_CONSTANTS.EMPTY_FIELD;
    },
  },
];

const incomingPackagesLotItemsTemplate = [
  ...commonItemTemplate,
  {
    label: PACKAGES.GROSS_LBS,
    render: ({ weightGrossActual }) => formatWeight(formatToUS(weightGrossActual)),
  },
  {
    label: humanize(PACKAGES.UNITS),
    render: ({ unitCount }) => replaceEmpty(formatToUS(unitCount, 0)),
  },
];

const packagesLotItemsTemplate = [
  ...commonItemTemplate,
  {
    label: PACKAGES.GROSS_LBS,
    render: ({ weightGrossActual }) => formatWeight(formatToUS(weightGrossActual)),
  },
];

const itemsTemplateHF = [
  {
    label: PACKAGES.H_F,
    render: ({ materialClass }) => materialClassSymbol[materialClass],
  },
];

const templateReleased = [
  {
    label: PACKAGES.RELEASED,
    render: ({ releasedAt }) => releasedAt && <Icon icon="icon-tick" />,
  },
];

const itemsTemplateMobile = [
  {
    label: PACKAGES.STATUS,
    render: ({ status }) => <StatusIndicator status={status} />,
  },
  {
    label: PACKAGES.PACKAGE_NUMBER,
    render: pkg => `${PACKAGES.PACKAGE_COLON} ${packageNumberLabel(pkg)}`,
  },
  {
    label: PACKAGES.PACKAGE_TYPE,
    render: ({ packageType }) => humanize(packageType),
  },
];

const shipmentItemsTemplateMobile = [
  {
    label: PACKAGES.STATUS,
    render: ({ status }) => <StatusIndicator status={status} />,
  },
  {
    label: PACKAGES.PACKAGE_NUMBER,
    render: pkg => `${PACKAGES.PACKAGE_COLON} ${packageNumberLabel(pkg)}`,
  },
  {
    label: PACKAGES.GRG_LOT_NUMBER,
    render: ({ grgLotNumber, customLotNumber }) => customLotNumber || grgLotNumber,
  },
];

const shipmentItemsTemplateBase = [
  {
    label: PACKAGES.STATUS,
    render: ({ status }) => <StatusIndicator status={status} />,
  },
  {
    label: PACKAGES.GRG_LOT_NUMBER,
    render: ({ grgLotNumber }) => grgLotNumber,
  },
  {
    label: PACKAGES.PACKAGE_NUMBER,
    render: pkg => `${PACKAGES.PACKAGE_COLON} ${packageNumberLabel(pkg)}`,
  },
  {
    label: PACKAGES.PACKAGE_TYPE,
    render: ({ packageType }) => humanize(packageType),
  },
  {
    label: PACKAGES.MATERIAL_TYPE_DECLARED,
    render: ({ materialTypeDeclared }) => humanize(materialTypeDeclared),
  },
];

const incomingShipmentPackagesItemsTemplate = [
  ...shipmentItemsTemplateBase,
  {
    label: PACKAGES.GROSS_LBS,
    render: ({ weightGrossDeclared }) => formatWeight(formatToUS(weightGrossDeclared)),
  },
  {
    label: humanize(PACKAGES.UNITS),
    render: ({ unitCount }) => replaceEmpty(formatToUS(unitCount, 0)),
  },
];

const outgoingShipmentPackagesItemsTemplate = [
  ...shipmentItemsTemplateBase,
  {
    label: PACKAGES.GROSS_LBS,
    render: ({ weightGrossActual }) => formatWeight(formatToUS(weightGrossActual)),
  },
];

const customerLotItemsTemplate = [
  {
    label: PACKAGES.STATUS,
    render: ({ status }) => <StatusIndicator status={status} />,
  },
  {
    label: PACKAGES.PACKAGE_NUMBER,
    render: pkg => `${PACKAGES.PACKAGE_COLON} ${packageNumberLabel(pkg)}`,
  },
  {
    label: PACKAGES.PACKAGE_TYPE,
    render: ({ packageType }) => humanize(packageType),
  },
  {
    label: PACKAGES.MATERIAL_TYPE_DECLARED,
    render: ({ materialTypeDeclared }) => humanize(materialTypeDeclared),
  },
  {
    label: PACKAGES.GROSS_LBS,
    render: ({ weightGrossDeclared }) => formatWeight(formatToUS(weightGrossDeclared)),
  },
  {
    label: humanize(PACKAGES.UNITS),
    render: ({ unitCount }) => replaceEmpty(formatToUS(unitCount, 0)),
  },
];

const buyerLotItemsTemplate = [
  {
    label: PACKAGES.STATUS,
    render: ({ status }) => <StatusIndicator status={status} />,
  },
  {
    label: PACKAGES.PACKAGE_NUMBER,
    render: pkg => `${PACKAGES.PACKAGE_COLON} ${packageNumberLabel(pkg)}`,
  },
  {
    label: PACKAGES.PACKAGE_TYPE,
    render: ({ packageType }) => humanize(packageType),
  },
  {
    label: PACKAGES.MATERIAL_TYPE_DECLARED,
    render: ({ materialTypeDeclared }) => humanize(materialTypeDeclared),
  },
  {
    label: PACKAGES.GROSS_LBS,
    render: ({ weightGrossActual }) => formatWeight(formatToUS(weightGrossActual)),
  },
  {
    label: humanize(PACKAGES.UNITS),
    render: ({ unitCount }) => replaceEmpty(formatToUS(unitCount, 0)),
  },
];

const [, , , ...outgoingShipmentPackagesDetailsTemplate] = outgoingShipmentPackagesItemsTemplate;

const [, , , ...incomingShipmentPackagesDetailsTemplate] = incomingShipmentPackagesItemsTemplate;

const [, , , ...customerLotDetailsTemplate] = customerLotItemsTemplate;

const [, , , ...incomingPackagesLotDetailsTemplate] = incomingPackagesLotItemsTemplate;

const [, , , ...packagesLotDetailsTemplate] = packagesLotItemsTemplate;

const [, , , ...itemsDetailsTemplate] = itemsTemplate;

const [, , , ...itemsDetailsTemplateProcessing] = itemsTemplateProcessing;

export {
  commonItemTemplate,
  itemsTemplate,
  itemsTemplateProcessing,
  itemsTemplateHF,
  templateReleased,
  buyerLotItemsTemplate,
  itemsTemplateMobile,
  itemsDetailsTemplate,
  itemsDetailsTemplateProcessing,
  incomingShipmentPackagesItemsTemplate,
  outgoingShipmentPackagesItemsTemplate,
  packagesLotItemsTemplate,
  incomingPackagesLotItemsTemplate,
  customerLotItemsTemplate,
  shipmentItemsTemplateMobile,
  outgoingShipmentPackagesDetailsTemplate,
  incomingShipmentPackagesDetailsTemplate,
  customerLotDetailsTemplate,
  incomingPackagesLotDetailsTemplate,
  packagesLotDetailsTemplate,
};
