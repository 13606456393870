import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const transferBlanketHedgeOuncesActionType = 'GET_DELIVERIES_TO_TRANSFER';
const transferBlanketHedgeOuncesAction = () => ({
  type: transferBlanketHedgeOuncesActionType,
});

const transferBlanketHedgeOuncesSuccessActionType = `${transferBlanketHedgeOuncesActionType}_SUCCESS`;
const transferBlanketHedgeOuncesSuccessAction = data => ({
  type: transferBlanketHedgeOuncesSuccessActionType,
  payload: data,
});

const transferBlanketHedgeOuncesFailureActionType = `${transferBlanketHedgeOuncesActionType}_FAILURE`;
const transferBlanketHedgeOuncesFailureAction = () => ({
  type: transferBlanketHedgeOuncesFailureActionType,
});

const transferBlanketHedgeOunces =
  ({ deliveryId, lotId }) =>
  dispatch => {
    dispatch(transferBlanketHedgeOuncesAction());

    return axios
      .post(`${API_HOST_AUTH}/deliveries/transfer-ounces`, {
        deliveryId,
        lotId,
      })

      .then(response => {
        dispatch(transferBlanketHedgeOuncesSuccessAction(response.data));
        return response.data;
      })
      .catch(error => {
        dispatch(transferBlanketHedgeOuncesFailureAction());
        return Promise.reject(error.data);
      });
  };

export {
  transferBlanketHedgeOuncesActionType,
  transferBlanketHedgeOuncesAction,
  transferBlanketHedgeOuncesSuccessActionType,
  transferBlanketHedgeOuncesSuccessAction,
  transferBlanketHedgeOuncesFailureActionType,
  transferBlanketHedgeOuncesFailureAction,
  transferBlanketHedgeOunces,
};
