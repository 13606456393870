import { connect } from 'react-redux';

import { downloadAttachment } from 'actions/Attachments/downloadAttachment';
import {
  clearAgreementsList,
  companyDetailsGetAgreements,
} from 'actions/Companies/Details/agreementsList';
import {
  clearLocationsList,
  companyDetailsGetLocations,
} from 'actions/Companies/Details/locationsList';
import { clearTermsList, companyDetailsGetTerms } from 'actions/Companies/Details/termsList';
import { clearUsersList, companyDetailsGetUsers } from 'actions/Companies/Details/usersList';
import { getCompany, clearCompanyDetails } from 'actions/Companies/companyDetails';
import { createNote } from 'actions/Notes/createNote';
import {
  clearNotesList as clearNotesListAction,
  getNotes as getNotesAction,
} from 'actions/Notes/notesList';
import { closeModal, openModal } from 'actions/shared/modal';
import { showSnackbar } from 'actions/shared/snackbar';

import { CompanyDetails } from './CompanyDetails';

const mapStateToProps = state => ({
  auth: state.auth,
  router: state.router,
  modal: state.modal,
  companyDetails: state.companyDetails,
  companyDetailsLocationsList: state.companyDetailsLocationsList,
  companyDetailsTermsList: state.companyDetailsTermsList,
  companyDetailsAgreementsList: state.companyDetailsAgreementsList,
  companyDetailsUsersList: state.companyDetailsUsersList,
  inactivateCompany: state.inactivateCompany,
  reactivateCompany: state.reactivateCompany,

  // Notes & Attachment
  downloadAttachmentStorage: state.downloadAttachmentStorage,
  createNoteStorage: state.createNoteStorage,
  getNotes: state.getNotes,
  clearNotesList: state.clearNotesList,
  notesList: state.notesList,
  assignedShipmentNumberData: state.assignedShipmentNumberData,
});

const mapDispatchToProps = {
  openModal,
  closeModal,
  getCompany,
  clearCompanyDetails,
  clearUsersList,
  companyDetailsGetUsers,
  clearLocationsList,
  companyDetailsGetLocations,
  clearTermsList,
  companyDetailsGetTerms,
  clearAgreementsList,
  companyDetailsGetAgreements,
  showSnackbar,

  // Notes & Attachment
  downloadAttachment,
  createNote,
  getNotes: getNotesAction,
  clearNotesList: clearNotesListAction,
};

const ConnectedCompanyDetails = connect(mapStateToProps, mapDispatchToProps)(CompanyDetails);

export { ConnectedCompanyDetails };
