import React from 'react';
import ReactSidebar from 'react-sidebar';
import styled from 'styled-components';

import { Icon } from 'shared/components/Icon/Icon';
import { cssVar } from 'shared/helpers/styling/styling';

import { resolveVersion, versions } from 'utils/versioning';

import { SidebarStyle } from './SidebarStyle';
import { SidebarItems } from './components/SidebarItems';

const StyledSidebar = styled.div`
  flex-grow: 1;
`;

class Sidebar extends React.Component {
  state = {
    sidebarOpen: false,
  };

  sidebarFactory = state => () => this.setState({ sidebarOpen: state });

  closeSidebar = this.sidebarFactory(false);

  openSidebar = this.sidebarFactory(true);

  render() {
    return (
      <StyledSidebar>
        <ReactSidebar
          sidebar={<SidebarItems closeOnClick={this.closeSidebar} {...this.props} />}
          open={this.state.sidebarOpen}
          onSetOpen={this.closeSidebar}
          styles={SidebarStyle}
          touch={false}
        >
          <Icon
            color={resolveVersion({
              [versions.alpha]: cssVar('whiteGRG'),
              [versions.global]: cssVar('whiteGRG'),
              [versions.west]: cssVar('graniteGray'),
            })}
            icon="icon-menu"
            onClick={this.openSidebar}
          />
        </ReactSidebar>
      </StyledSidebar>
    );
  }
}

export { Sidebar };
