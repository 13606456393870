import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const checkLotPrefixActionType = 'CHECK_LOT_PREFIX';
const checkLotPrefixAction = () => ({
  type: checkLotPrefixActionType,
});

const checkLotPrefixSuccessActionType = `${checkLotPrefixActionType}_SUCCESS`;
const checkLotPrefixSuccessAction = result => ({
  type: checkLotPrefixSuccessActionType,
  payload: {
    result,
  },
});

const checkLotPrefixFailureActionType = `${checkLotPrefixActionType}_FAILURE`;
const checkLotPrefixFailureAction = () => ({
  type: checkLotPrefixFailureActionType,
});

const checkLotPrefix = (id, lotPrefix) => dispatch => {
  dispatch(checkLotPrefixAction());

  return axios
    .post(`${API_HOST_AUTH}/companies/validated/lot-prefix`, {
      id,
      lotPrefix,
    })
    .then(res => {
      dispatch(checkLotPrefixSuccessAction(res));

      return res.data;
    })
    .catch(err => {
      dispatch(checkLotPrefixFailureAction());

      return Promise.reject(err.data);
    });
};

export {
  checkLotPrefixActionType,
  checkLotPrefixSuccessActionType,
  checkLotPrefixFailureActionType,
  checkLotPrefix,
};
