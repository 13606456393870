function parseLotFormData(lot, isGrgUser) {
  const lotData = {
    sentFromCompanyLocation: lot.sentFromCompanyLocation.value,
    processUponDelivery: lot.processUponDelivery,
    customerInstructions: lot.customerInstructions,
    customLotNumber: lot.customLotNumber,
  };

  if (isGrgUser) {
    return {
      ...lotData,
      sentFromCompany: lot.sentFromCompany.value,
      statusForGlobal: lot.statusForGlobal && lot.statusForGlobal.value,
      catMono: lot.catMono,
      catFoil: lot.catFoil,
      catEmpty: lot.catEmpty,
      catAft: lot.catAft,
      catBead: lot.catBead,
      catGrgQtyTotal: lot.catGrgQtyTotal,
    };
  }
  return lotData;
}

export { parseLotFormData };
