import {
  getForwardRecipientsType,
  getForwardRecipientsSuccessType,
  getForwardRecipientsFailureType,
  clearForwardRecipientsType,
} from 'actions/Files/getForwardRecipients';

const INITIAL_STATE = {
  isPending: false,
  recipients: null,
};

const forwardRecipientsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getForwardRecipientsType:
      return {
        isPending: true,
      };
    case getForwardRecipientsSuccessType:
      return {
        isPending: false,
        recipients: action.payload.recipients,
      };
    case clearForwardRecipientsType:
    case getForwardRecipientsFailureType:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};

export { forwardRecipientsReducer };
