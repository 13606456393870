import styled from 'styled-components';

import { media } from 'shared/helpers/styling/styling';

export const StyledAuthContent = styled.div`
  padding: 25px 0 30px;

  @media print {
    padding: 15px 0;
  }

  ${media.phone`
  padding-top: 0;
`}

  .container {
    ${media.phone`
    padding: 0;
    `}
  }
`;
