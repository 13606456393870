import React from 'react';

import { UIAuthorization } from 'components/Authorization/UIAuthorization';

import { SHARED } from 'shared/helpers/constants/sharedConstants';

import { OverviewListTableCell } from '../OverviewListTableCell/OverviewListTableCell';
import { OverviewTableItem } from '../OverviewTableItem/OverviewTableItem';
import { OverviewTableRow } from '../OverviewTableRow/OverviewTableRow';
import { StyledOverviewTable } from '../StyledOverviewTable/StyledOverviewTable';

function OverviewTable({
  items,
  auth,
  itemsTemplate,
  actionsTemplate,
  templateProps,
  actionsContext,
  processing,
  logs,
  itemFilter,
  detailsList,
  limitMaximumHeight,
  customColor,
}) {
  return (
    <div className="DetailsTable">
      <StyledOverviewTable>
        <OverviewTableRow
          head
          itemsNumber={itemsTemplate.length - !actionsTemplate.length}
          header
          processing={processing}
          logs={logs}
        >
          {itemsTemplate.map(({ label }, index) => (
            <OverviewListTableCell key={index} head>
              {label}
            </OverviewListTableCell>
          ))}
          <UIAuthorization access={actionsTemplate.length}>
            <OverviewListTableCell head>{SHARED.TABLE_ACTIONS}</OverviewListTableCell>
          </UIAuthorization>
        </OverviewTableRow>
        {items.map(item => (
          <OverviewTableItem
            itemsTemplate={itemsTemplate}
            templateProps={templateProps}
            actionsTemplate={actionsTemplate}
            actionsContext={actionsContext}
            key={item._id}
            auth={auth}
            item={item}
            processing={processing}
            itemFilter={itemFilter}
            detailsList={detailsList}
            logs={logs}
            limitMaximumHeight={limitMaximumHeight}
            customColor={customColor}
            isDisabled={item.isDisabled}
          />
        ))}
      </StyledOverviewTable>
    </div>
  );
}

export { OverviewTable };
