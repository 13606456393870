import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, change, reduxForm } from 'redux-form';

import { FieldDate, FieldInput } from 'shared/components/Fields';
import { FieldRadioButton } from 'shared/components/Fields/FieldRadioButton/FieldRadioButton';
import { Form, Section } from 'shared/components/Form';
import { MultipleFieldsSection } from 'shared/components/Form/MultipleFieldsSection/MultipleFieldsSection';
import { cancelModal } from 'shared/helpers/constants/modalConstants';
import { TERMS } from 'shared/helpers/constants/terms/termsConstants';
import { normalize } from 'shared/helpers/parsers/boolean';
import { validate } from 'shared/helpers/validations/term/termValidations';

import dateAdapter from 'utils/date/dateAdapter';
import { goBackOrTo } from 'utils/history';

class CreateTermForm extends Component {
  componentDidMount() {
    const { defaultValues, termsDetails } = this.props;
    this.populateValues(termsDetails.terms || defaultValues);
  }

  onCancel = () => {
    this.props.openModal(cancelModal, () => {
      goBackOrTo(`/companies/${this.props.match.params.companyId}`);
    });
  };

  setField = (key, value) => {
    const { dispatch } = this.props;
    dispatch(change('TermForm', key, value));
  };

  populateValues = term => {
    const { signedTerms, auth } = this.props;

    const digitalSignatureEnabled = auth?.access?.companies?.details?.terms?.digitalSignature;

    this.setField('treatmentChargePerPound', term.treatmentChargePerPound);
    this.setField('advanceRate', signedTerms.value || term.advanceRate);
    this.setField('startDate', dateAdapter(term.startDate));
    this.setField('endDate', dateAdapter(term.endDate));
    this.setField('smallLotCharge', term.smallLotCharge);
    this.setField('largeLotCharge', term.largeLotCharge);
    this.setField('platinumReturnRate', term.platinumReturnRate);
    this.setField('palladiumReturnRate', term.palladiumReturnRate);
    this.setField('rhodiumReturnRate', term.rhodiumReturnRate);
    this.setField('metalsReturnTermInDays', term.metalsReturnTermInDays);
    this.setField('metalsReturnFinanceRate', term.metalsReturnFinanceRate);
    this.setField('digitalSignatureRequired', digitalSignatureEnabled && term.digitalSignatureRequired);
  };

  render() {
    const { handleSubmit, signedTerms } = this.props;

    const metalsFields = [
      {
        name: 'platinumReturnRate',
        component: FieldInput,
        type: 'text',
        label: TERMS.PLATINUM_RETURN_RATE,
        field: 'required',
        suffix: '%',
      },
      {
        name: 'palladiumReturnRate',
        component: FieldInput,
        type: 'text',
        label: TERMS.PALLADIUM_RETURN_RATE,
        field: 'required',
        suffix: '%',
      },
      {
        name: 'rhodiumReturnRate',
        component: FieldInput,
        type: 'text',
        label: TERMS.RHODIUM_RETURN_RATE,
        field: 'required',
        suffix: '%',
      },
    ];

    return (
      <Form
        header={TERMS.TERM}
        onSubmit={handleSubmit}
        onCancel={this.onCancel}
        submitDisabled={this.props.invalid || this.props.submitting}
      >
        <Section
          template={['treatmentChargePerPound advanceRate', 'smallLotCharge largeLotCharge']}
        >
          <Field
            name="treatmentChargePerPound"
            component={FieldInput}
            type="text"
            label={TERMS.TREATMENT_CHARGE_CERAMIC}
            field="required"
            prefix="$"
          />
          <Field
            name="advanceRate"
            component={FieldInput}
            type="text"
            label={TERMS.ADVANCE_RATE}
            field="required"
            suffix="%"
            disabled={signedTerms.value !== null}
          />
          <Field
            name="smallLotCharge"
            component={FieldInput}
            type="text"
            label={TERMS.SMALL_LOT_CHARGE}
            field="required"
            prefix="$"
          />
          <Field
            name="largeLotCharge"
            component={FieldInput}
            type="text"
            label={TERMS.LARGE_LOT_CHARGE}
            field="required"
            prefix="$"
          />
        </Section>
        <Section header={TERMS.DATES} template={['startDate endDate']}>
          <Field
            name="startDate"
            component={FieldDate}
            label={TERMS.START_DATE}
            field="required"
            minDate={dateAdapter()}
          />
          <Field
            name="endDate"
            component={FieldDate}
            label={TERMS.END_DATE}
            field="required"
            minDate={dateAdapter().add(1, 'd')}
          />
        </Section>
        <Section
          header={TERMS.RETURN_RATES}
          template={['metals metals', 'metalsReturnFinanceRate metalsReturnTermInDays']}
          noPaddingField={1}
        >
          <MultipleFieldsSection name="metals" fields={metalsFields} />
          <Field
            name="metalsReturnFinanceRate"
            component={FieldInput}
            type="text"
            label={TERMS.METALS_RETURN_FINANCE_RATE}
            field="required"
            suffix="%"
          />
          <Field
            name="metalsReturnTermInDays"
            component={FieldInput}
            type="number"
            label={TERMS.METALS_RETURN_TERMIN_DAYS}
            field="required"
          />
        </Section>
        <Section header={TERMS.DIGITAL_SIGNATURE} template={['digitalSignatureRequired .']}>
          <Field
            name="digitalSignatureRequired"
            component={FieldRadioButton}
            label={TERMS.DIGITAL_SIGNATURE_REQUIRED}
            field="required"
            normalize={normalize}
            disabled={!this.props.auth?.access?.companies?.details?.terms?.digitalSignature}
          />
        </Section>
      </Form>
    );
  }
}

const mapStateToProps = state => ({
  companyId: state.companyDetails.company && state.companyDetails.company._id,
});

const ConnectedTermForm = compose(
  connect(mapStateToProps),
  reduxForm({
    form: 'TermForm',
    initialValues: {
      digitalSignatureRequired: false,
    },
    validate,
  }),
)(CreateTermForm);

export { ConnectedTermForm };
