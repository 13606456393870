import React, { Component } from 'react';

import { Button } from 'shared/components/Buttons/Button';
import { Icon } from 'shared/components/Icon/Icon';
import { OverviewListTableCell } from 'shared/components/OverviewList/OverviewListTableCell/OverviewListTableCell';
import { OverviewTableRow } from 'shared/components/OverviewList/OverviewTableRow/OverviewTableRow';
import { renderHighlight } from 'shared/helpers/parsers/highlight';
import { renderItem } from 'shared/helpers/parsers/lists';
import { cssVar } from 'shared/helpers/styling/styling';

import { FullPageListItemDetails } from '../FullPageListItemDetails/FullPageListItemDetails';

class FullPageListTableItemMobile extends Component {
  state = {
    displayDetails: false,
  };

  toggleFullPageDetails = () => {
    this.setState(prevState => ({ displayDetails: !prevState.displayDetails }));
  };

  render() {
    const {
      item,
      searchFilter,
      itemsTemplate,
      itemsTemplateMobile,
      actionsTemplate,
      actionsContext,
    } = this.props;

    const rowClassName =
      itemsTemplateMobile.className && itemsTemplateMobile.className.call(actionsContext, item);

    return (
      <React.Fragment>
        <OverviewTableRow
          additionalClass={rowClassName}
          itemsNumber={itemsTemplateMobile.length}
          isOpen={this.state.displayDetails}
          onClick={this.toggleFullPageDetails}
          header
          mobile
        >
          {itemsTemplateMobile.map(
            ({ highlight, render, includeRegExp, className, dueDiligence }, index) => (
              <OverviewListTableCell key={index} className={className}>
                {highlight
                  ? renderHighlight(render, item, searchFilter, includeRegExp, dueDiligence)
                  : renderItem(render, item)}
              </OverviewListTableCell>
            ),
          )}
          <Button
            borderRadius={100}
            margin={false}
            noOutline
            bgColor={cssVar('limedSpruceList')}
            padding="0"
            height={30}
            width={30}
            bgColorHover={cssVar('limedSpruceList')}
          >
            <Icon icon="icon-plus" height={this.state.displayDetails ? 1 : 14} width={14} />
          </Button>
        </OverviewTableRow>
        {this.state.displayDetails && (
          <FullPageListItemDetails
            searchFilter={searchFilter}
            item={item}
            itemsTemplate={itemsTemplate}
            itemsTemplateMobile={itemsTemplateMobile}
            actionsTemplate={actionsTemplate}
            actionsContext={actionsContext}
            displayDetails={this.state.displayDetails}
          />
        )}
      </React.Fragment>
    );
  }
}

export { FullPageListTableItemMobile };
