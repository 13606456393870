import React, { Component } from 'react';
import { Field, reduxForm, SubmissionError } from 'redux-form';

import { FieldInput } from 'shared/components/Fields';
import { Form, Section } from 'shared/components/Form';
import { HEDGES } from 'shared/helpers/constants/hedges/hedgesConstants';
import { cancelModal } from 'shared/helpers/constants/modalConstants';
import { validate, asyncValidate } from 'shared/helpers/validations/hedges/recipientsValidation';

import { goBackOrTo } from 'utils/history';

class RecipientsBaseForm extends Component {
  onCancel = () => {
    this.props.openModal(cancelModal, () => {
      goBackOrTo('/hedges/recipients');
    });
  };

  handleSubmit = values => {
    try {
      this.props.handleSubmit(values);
    } catch (err) {
      new SubmissionError(err);
    }
  };

  render() {
    return (
      <Form
        header={HEDGES.RECIPIENTS.TITLE}
        onSubmit={this.handleSubmit}
        onCancel={this.onCancel}
        submitDisabled={this.props.invalid || this.props.submitting}
      >
        <Section template={['email .']}>
          <Field
            name="email"
            component={FieldInput}
            label={HEDGES.RECIPIENTS.EMAIL}
            field="required"
            maxLength="100"
            lazyError
          />
        </Section>
      </Form>
    );
  }
}

const RecipientsForm = reduxForm({
  form: 'RecipientsForm',
  validate,
  asyncValidate,
  asyncBlurFields: ['email'],
})(RecipientsBaseForm);

export { RecipientsBaseForm, RecipientsForm };
