import { Field } from 'redux-form';

import { FieldInput } from 'shared/components/Fields/FieldInput/FieldInput';
import { Section } from 'shared/components/Form/Section/Section';
import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';

const lotFields = [
  {
    name: 'deductions',
    title: 'Deductions',
    sectionTitle: 'Deduction',
    buttonLabel: 'Add new deduction',
    commonComponent: () => {
      return (
        <Section template={[`truckingDeduction .`]}>
          <Field
            name={`truckingDeduction`}
            component={FieldInput}
            type="text"
            label={LOTS.TRUCKING}
            prefix="$"
          />
        </Section>
      );
    },
  },
  {
    name: 'credits',
    title: 'Credits',
    sectionTitle: 'Credit',
    buttonLabel: 'Add new credit',
  },
];

export { lotFields };
