import React from 'react';

import { UIAuthorization } from 'components/Authorization/UIAuthorization';
import { NotificationActionsPortal } from 'components/NotificationActionsPortal/NotificationActionsPortal';

import { Button } from 'shared/components/Buttons/Button';
import { ExtendableButton } from 'shared/components/Buttons/ExtendableButton/ExtendableButton';
import { DetailsHeader } from 'shared/components/Details/DetailsHeader';
import { deleteSelfDeliveryRequestAccess } from 'shared/helpers/accesses/selfDeliveryRequests/deleteSelfDeliveryRequestAccess';
import { selfDeliveryRequestStatusChangeAccess } from 'shared/helpers/accesses/selfDeliveryRequests/selfDeliveryRequestStatusChangeAccess';
import { notificationActionTypes } from 'shared/helpers/constants/notifications/notificationActionTypes';
import { selfDeliveryRequestStatuses } from 'shared/helpers/constants/selfDeliveryRequests/selfDeliveryRequestStatuses';
import { SELF_DELIVERY_REQUESTS } from 'shared/helpers/constants/selfDeliveryRequests/selfDeliveryRequestsConstants';

import { DeleteSelfDeliveryRequest } from 'pages/SelfDeliveryRequests/DeleteSelfDeliveryRequest/DeleteSelfDeliveryRequest';
import { SelfDeliveryRequestStatusChange } from 'pages/SelfDeliveryRequests/SelfDeliveryRequestStatusChange/SelfDeliveryRequestStatusChange';

function SelfDeliveryRequestMainDetails({ auth, selfDeliveryRequest }) {
  const actionButton = (label, onClick, color) => (
    <Button outline color={color} outlineColor={color} onClick={onClick}>
      {label}
    </Button>
  );

  return (
    <DetailsHeader
      header={selfDeliveryRequest.relatedCompany.companyName}
      status={selfDeliveryRequest.status}
    >
      <ExtendableButton label={SELF_DELIVERY_REQUESTS.ACTIONS}>
        <UIAuthorization access={selfDeliveryRequestStatusChangeAccess(auth, selfDeliveryRequest)}>
          <SelfDeliveryRequestStatusChange
            status={selfDeliveryRequestStatuses.approved}
            id={selfDeliveryRequest._id}
          >
            {({ onClick }) => (
              <NotificationActionsPortal
                actions={notificationActionTypes.selfDeliveryRequestApproval}
              >
                {actionButton(SELF_DELIVERY_REQUESTS.APPROVE, onClick)}
              </NotificationActionsPortal>
            )}
          </SelfDeliveryRequestStatusChange>
        </UIAuthorization>
        <UIAuthorization access={selfDeliveryRequestStatusChangeAccess(auth, selfDeliveryRequest)}>
          <SelfDeliveryRequestStatusChange
            status={selfDeliveryRequestStatuses.declined}
            id={selfDeliveryRequest._id}
          >
            {({ onClick }) => (
              <NotificationActionsPortal
                actions={notificationActionTypes.selfDeliveryRequestApproval}
              >
                {actionButton(SELF_DELIVERY_REQUESTS.DECLINE, onClick, 'danger')}
              </NotificationActionsPortal>
            )}
          </SelfDeliveryRequestStatusChange>
        </UIAuthorization>
        <UIAuthorization access={deleteSelfDeliveryRequestAccess(auth, selfDeliveryRequest)}>
          <DeleteSelfDeliveryRequest id={selfDeliveryRequest._id}>
            {({ deleteSelfDeliveryRequest }) => (
              <Button outline id="deletePayment" color="danger" onClick={deleteSelfDeliveryRequest}>
                {SELF_DELIVERY_REQUESTS.DELETE_REQUEST}
              </Button>
            )}
          </DeleteSelfDeliveryRequest>
        </UIAuthorization>
      </ExtendableButton>
    </DetailsHeader>
  );
}

export { SelfDeliveryRequestMainDetails };
