import { Col, Row } from 'reactstrap';
import styled from 'styled-components';

import { media } from 'shared/helpers/styling/styling';

export const StyledErrorPage = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  padding: 150px 0;
  overflow-y: auto;
  overflow-x: hidden;
  background: var(--outerSpace);
  ${media.mobile`
padding: 80px 0;
`}
`;

export const StyledErrorPageRow = styled(Row)`
  justify-content: center;
`;
export const StyledErrorPageColumn = styled(Col)`
  position: relative;
  padding: 80px 50px;
  ${media.mobile`
padding: 10px 15px;
`}
  &:after {
    content: '';
    position: absolute;
    top: 35px;
    right: 0;
    height: 100%;
    width: 2px;
    background: var(--limedSpruce);

    ${media.mobile`
  display: none;
  `}
  }
`;

export const StyledErrorPageText = styled.div`
  text-align: right;
  color: var(--limedSpruce);
  line-height: 75px;

  ${media.mobile`
  text-align: center;`}
`;

export const StyledErrorPageTextTop = styled.div`
  font-size: 1.9em;
  font-weight: 600;
  margin-right: 14px;

  ${media.mobile`
    margin-right: 0;
    margin-left: 140px;`}
`;

export const StyledErrorPageTextBottom = styled.div`
  font-size: 9em;
  font-weight: 900;
`;

export const StyledErrorPageIcons = styled(Col)`
  position: relative;
  overflow: hidden;
  .GhostIcon {
    position: absolute;
    &:nth-child(1) {
      top: -25px;
      left: 180px;
    }

    &:nth-child(2) {
      top: 160px;
      left: 120px;
    }

    &:nth-child(3) {
      top: 64px;
      left: -77px;
    }
  }
  ${media.mobile`
  display: none;
`}
`;

export const StyledErrorPageInfo = styled.div`
  margin-top: 80px;
  text-align: center;
  font-size: 2em;
  color: var(--geyser);

  ${media.mobile`
  margin-top: 60px;
  font-size: 1.6em;
  `}
`;

export const StyledErrorPageButton = styled.div`
  text-align: center;
  padding: 30px 0;
  display: flex;
  justify-content: center;
`;
