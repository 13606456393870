import {
  createCompanyActionType,
  createCompanyFailureActionType,
  createCompanySuccessActionType,
} from 'actions/Companies/createCompany';

const INITIAL_STATE = {
  isPending: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case createCompanyActionType:
      return {
        isPending: true,
      };
    case createCompanyFailureActionType:
      return {
        isPending: false,
      };
    case createCompanySuccessActionType:
      return {
        isPending: false,
      };
    default:
      return state;
  }
};
