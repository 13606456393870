import {
  getIncomingShipmentsOverviewActionType,
  getIncomingShipmentsOverviewSuccessActionType,
  getIncomingShipmentsOverviewFailureActionType,
  clearIncomingShipmentsOverviewActionType,
} from 'actions/Shipments/shipmentsOverview';

const INITIAL_STATE = {
  isPending: false,
  items: null,
};

const IncomingShipmentsOverviewReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getIncomingShipmentsOverviewActionType:
      return {
        ...state,
        isPending: true,
      };
    case getIncomingShipmentsOverviewSuccessActionType:
      return {
        isPending: false,
        items: action.payload.data,
      };
    case getIncomingShipmentsOverviewFailureActionType:
      return {
        ...state,
        isPending: false,
      };
    case clearIncomingShipmentsOverviewActionType:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};

export { IncomingShipmentsOverviewReducer };
