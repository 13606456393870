import { isCustomerUser } from '../matchers/checkUserType';

function usersListAccess(user, access) {
  return (
    (isCustomerUser(user.userType) &&
      user.relatedCompany.primaryContact.includes(user.id) &&
      user.relatedCompany.primaryLocation &&
      access.users.list) ||
    (!isCustomerUser(user.userType) && access.users.list)
  );
}

export { usersListAccess };
