import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';

import { getLot } from 'actions/Lots/lotDetails';
import { createProcessingPackage } from 'actions/Packages/createPackage';
import { showSnackbar } from 'actions/shared/snackbar';

import {
  dangerOptions,
  successOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { getPackageProcessingType } from 'shared/helpers/dataParsers/lot/getPackageProcessingType';
import { camelToKebab } from 'shared/helpers/parsers/text';

import { goBackOrTo } from 'utils/history';

import { CreateMultiIncomingPackages } from 'pages/Packages/components/PackagesManagement/Create/CreateMultiIncomingPackages/CreateMultiIncomingPackages';
import { configNames } from 'pages/Packages/components/PackagesManagement/components/PackageForm/components/PackageForm/fieldsConfig';

class CreateProcessingPackageBase extends Component {
  isComponentMounted = false;

  state = {
    config: null,
    isReady: false,
  };

  componentDidMount() {
    this.isComponentMounted = true;

    this.props
      .getLot(this.props.match.params.lotId)
      .then(() => this.isComponentMounted && this.setState({ isReady: true }));
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
  }

  static getDerivedStateFromProps(props, state) {
    const {
      lotDetails: { lot },
    } = props;
    const formType = lot && getPackageProcessingType(lot);

    if (formType && formType !== state.config) {
      return {
        config: formType,
      };
    }

    return null;
  }

  submit = values => {
    const packageType = camelToKebab(this.state.config);

    return this.props
      .createProcessingPackage(values, packageType)
      .then(res => {
        const {
          location: { pathname },
          match: {
            params: { lotId },
          },
        } = this.props;
        const detailsPath = `/lots/list/${lotId}/packages/${res.id}`;

        if (['/lots/processing', '/lots/mixing'].some(p => !pathname.indexOf(p))) {
          goBackOrTo(detailsPath);
        } else {
          this.props.history.push(detailsPath);
        }
        this.props.showSnackbar(successOptions, res.message);
      })
      .catch(res => this.props.showSnackbar(dangerOptions, res.message));
  };

  renderContent() {
    const { config } = this.state;
    const configName = configNames[config];

    return <CreateMultiIncomingPackages config={configName} />;
  }

  render() {
    const {
      match: {
        params: { lotId },
      },
    } = this.props;
    const { isReady, config } = this.state;

    return isReady && !config ? <Redirect to={`/lots/list/${lotId}`} /> : this.renderContent();
  }
}

const mapStateToProps = state => ({
  lotDetails: state.lotDetails,
});

const mapDispatchToProps = {
  createProcessingPackage,
  getLot,
  showSnackbar,
};

const CreateProcessingPackage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateProcessingPackageBase);

export { CreateProcessingPackage, CreateProcessingPackageBase };
