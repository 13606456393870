import PropTypes from 'prop-types';
import React from 'react';
import Highlighter from 'react-highlight-words';

import { escapeRegExp } from 'shared/helpers/parsers/text';

import { StyledDueDiligence, StyledContainer } from './StyledHighlight';

function Highlight({ textStr, searchFilter = '', includeRegExp, dueDiligence }) {
  if (includeRegExp) {
    const searchString = searchFilter.trim();
    const newSearchFilter = new RegExp(escapeRegExp(searchString));
    searchFilter = new RegExp(newSearchFilter.source.replace('\\(', '\\s*\\('), 'i');
  }

  return (
    <StyledContainer>
      <Highlighter
        searchWords={[searchFilter]}
        textToHighlight={textStr?.toString()}
        autoEscape={!includeRegExp}
      />
      {dueDiligence && <StyledDueDiligence />}
    </StyledContainer>
  );
}

Highlight.propTypes = {
  textStr: PropTypes.string,
  searchFilter: PropTypes.string,
  includeRegExp: PropTypes.bool,
  dueDiligence: PropTypes.bool,
};

Highlight.defaultProps = {
  dueDiligence: false,
};

export { Highlight };
