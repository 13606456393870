import axios from 'axios';

import { showSnackbar } from 'actions/shared/snackbar';

import {
  successOptions,
  dangerOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { parseUserUpdateData } from 'shared/helpers/dataParsers/user/parseUserUpdateData';

import { history } from 'utils/history';

const API_HOST_AUTH = process.env.REACT_APP_API;

const updateUserActionType = 'UPDATE_USER';
function updateUserAction() {
  return {
    type: updateUserActionType,
  };
}

const updateUserSuccessActionType = 'UPDATE_USER_SUCCESS';
function updateUserSuccessAction() {
  return {
    type: updateUserSuccessActionType,
  };
}

const updateUserFailureActionType = 'UPDATE_USER_FAILURE';
function updateUserFailureAction() {
  return {
    type: updateUserFailureActionType,
  };
}

function updateUser(user, id, companyId) {
  return dispatch => {
    dispatch(updateUserAction());
    return axios({
      method: 'put',
      url: `${API_HOST_AUTH}/users/${id}`,
      data: JSON.stringify(parseUserUpdateData(user)),
    })
      .then(res => {
        dispatch(updateUserSuccessAction());
        dispatch(showSnackbar(successOptions, res.data.message));
        history.push(companyId ? `/companies/${companyId}/users/${id}` : `/users/${id}`);
      })
      .catch(error => {
        dispatch(updateUserFailureAction());
        dispatch(showSnackbar(dangerOptions, `User has not been updated. ${error.data.message}`));
      });
  };
}

export {
  updateUserActionType,
  updateUserSuccessActionType,
  updateUserFailureActionType,
  updateUser,
};
