import React, { useMemo, useCallback } from 'react';
import styled from 'styled-components';

import { UIAuthorization } from 'components/Authorization/UIAuthorization';
import { ModalCustom } from 'components/ModalCustom/ModalCustom';

import { Button } from 'shared/components/Buttons/Button';
import { RouterButton } from 'shared/components/Buttons/RouterButton/RouterButton';
import { Icon } from 'shared/components/Icon/Icon';
import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';
import { statusesForGlobal } from 'shared/helpers/constants/lots/statusesForGlobal';
import { SHARED } from 'shared/helpers/constants/sharedConstants';
import { SHIPMENTS } from 'shared/helpers/constants/shipments/shipmentsConstants';
import { humanize } from 'shared/helpers/parsers/text';
import { cssVar, media, pxToRem } from 'shared/helpers/styling/styling';

import dateAdapter from 'utils/date/dateAdapter';
import { flatten } from 'utils/flatten';
import { history } from 'utils/history';

import {
  ContentListHeader,
  ModalListContainer,
} from 'pages/Shipments/components/ShipmentsCalendar/ModalContent/ModalContent';

const displayedStatuses = [
  statusesForGlobal.accepted_for_grading,
  statusesForGlobal.accepted_for_processing,
  statusesForGlobal.accepted_for_mixing,
];

const ListItem = styled.div`
  font-size: ${pxToRem(14)};
  color: ${cssVar('whiteGRG')};
  background-color: ${({ bgColor }) => bgColor};
  min-height: 88px;
  padding: 4px 55px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  &:hover {
    opacity: 0.92;
  }

  &:not(:first-of-type) {
    border-top: 2px solid rgba(255, 255, 255, 0.23);
  }

  span {
    margin-right: 15px;
  }

  ${media.phone`
    padding: 8px 12px;
  `}
`;

function SectionSummaryModal(props) {
  const { part, isOpen, toggle, data, actionsAuth } = props;

  const parsedData = useMemo(() => (data ? flatten(data.map(([, value]) => value)) : []), [data]);

  const actions = (
    <UIAuthorization access={actionsAuth}>
      <RouterButton path={part.path} outlineColor={cssVar('pelorous')}>
        {SHARED.DETAILS}
        <Icon icon="icon-tiny-arrow-right" />
      </RouterButton>
    </UIAuthorization>
  );

  const getColumnHeader = useCallback(
    item => (item && item.estDeliveryDate ? SHIPMENTS.EST_DELIVERY_DATE : LOTS.GRG_LOT_NUMBER),
    [],
  );

  return (
    <>
      <ModalCustom isOpen={isOpen} containerPadding={0} modalClose={toggle}>
        <ModalListContainer header={part.header} actions={actions}>
          <ContentListHeader>
            <span>{getColumnHeader(parsedData[0])}</span>
            <span>{SHARED.EXTENDABLE_ACTIONS}</span>
          </ContentListHeader>
          {parsedData.map(item => (
            <ListItem
              key={item._id}
              bgColor={
                displayedStatuses.includes(item.statusForGlobal)
                  ? part.color[0]
                  : cssVar('fiordLighter')
              }
              onClick={() => history.push(part.navigate(item))}
            >
              <span>{item.grgLotNumber || dateAdapter(item.estDeliveryDate).format()}</span>
              {displayedStatuses.includes(item.statusForGlobal) && (
                <>
                  <Icon icon={part.icon[0]} />
                  <span>{humanize(item.statusForGlobal)}</span>
                </>
              )}
              {
                <Button
                  bgColor={cssVar('limedSpruceList')}
                  bgColorHover={cssVar('limedSpruceList')}
                  borderRadius={100}
                  margin={false}
                  noOutline
                  fitToContent
                >
                  <Icon icon="icon-arrow-right" color={cssVar('whiteGRG')} />
                </Button>
              }
            </ListItem>
          ))}
        </ModalListContainer>
      </ModalCustom>
    </>
  );
}

export { SectionSummaryModal };
