import axios from 'axios';
import { saveAs } from 'file-saver';

import { POOL_ACCOUNTS } from 'shared/helpers/constants/poolAccounts/poolAccountsConstants';

const API_HOST_AUTH = process.env.REACT_APP_API;

const poolAccountBalanceStatementType = 'POOL_ACCOUNT_BALANCE_STATEMENT';
const poolAccountBalanceStatementAction = () => ({
  type: poolAccountBalanceStatementType,
});

const poolAccountBalanceStatementSuccessType = `${poolAccountBalanceStatementType}_SUCCESS`;
const poolAccountBalanceStatementSuccessAction = () => ({
  type: poolAccountBalanceStatementSuccessType,
});

const poolAccountBalanceStatementFailureType = `${poolAccountBalanceStatementType}_FAILURE`;
const poolAccountBalanceStatementFailureAction = () => ({
  type: poolAccountBalanceStatementFailureType,
});

const poolAccountBalanceStatement = companyId => dispatch => {
  dispatch(poolAccountBalanceStatementAction());

  return axios
    .post(`${API_HOST_AUTH}/pool-accounts/balance-statement/${companyId}`, null, {
      responseType: 'arraybuffer',
    })
    .then(res => {
      dispatch(poolAccountBalanceStatementSuccessAction());

      saveAs(new Blob([res.data], { type: res.headers['content-type'] }), 'balanceStatement.pdf');

      return {
        message: POOL_ACCOUNTS.GENERATE_BALANCE_STATEMENT_SUCCESS,
      };
    })
    .catch(() => {
      dispatch(poolAccountBalanceStatementFailureAction());

      return Promise.reject({
        message: POOL_ACCOUNTS.GENERATE_BALANCE_STATEMENT_FAILURE,
      });
    });
};

export {
  poolAccountBalanceStatementType,
  poolAccountBalanceStatementSuccessType,
  poolAccountBalanceStatementFailureType,
  poolAccountBalanceStatement,
};
