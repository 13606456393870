import React, { useEffect } from 'react';
import { useSelector, useDispatch, connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { Field, FormSection, formValueSelector, reduxForm } from 'redux-form';

import { getLot } from 'actions/Lots/lotDetails';
import { getShipmentsOverview } from 'actions/Shipments/lotShipmentsOverviewList';

import { FieldHint, FieldInput, FieldDate } from 'shared/components/Fields';
import { Form, Section } from 'shared/components/Form';
import { PROFIT_LOSS } from 'shared/helpers/constants/lots/profitLossConstants';
import { payoutRulesTypes } from 'shared/helpers/constants/payments/payoutRulesTypes';
import { shipmentDirections } from 'shared/helpers/constants/shipments/shipmentDirections';
import { shipmentStatuses } from 'shared/helpers/constants/shipments/shipmentStatuses';
import { safeParseFloat } from 'shared/helpers/parsers/number';
import { lotValidation } from 'shared/helpers/validations/lot/generateProfitLossValidation';
import { multiValidator } from 'shared/helpers/validations/multiValidator';

import dateAdapter from 'utils/date/dateAdapter';
import { evaluate } from 'utils/evaluate';

const LotFormLayout = ({ handleSubmit, invalid, touch, change, match, lot }) => {
  const dispatch = useDispatch();
  const selector = formValueSelector('wizard');
  const { paidByGlobal } = useSelector(({ profitLossData: { data } }) => data);
  const { isPending } = useSelector(({ shipmentsOverviewList }) => shipmentsOverviewList);
  const { isPending: lotIsPending } = useSelector(({ lotDetails }) => lotDetails);
  const receivedFromStillwater = useSelector(state =>
    selector(state, 'lot.receivedFromStillwater'),
  );

  const fetchData = async () => {
    const { id } = match.params;

    await Promise.all([
      dispatch(getShipmentsOverview(id, Number.MAX_SAFE_INTEGER)),
      dispatch(getLot(id)),
    ]).then(([shipments, lot]) => {
      const outgoingShipment = (shipments || []).find(
        ({ shipmentDirection, status }) =>
          shipmentDirection === shipmentDirections.outgoing && status === shipmentStatuses.sent,
      );

      const {
        invoiceGeneratedAt,
        platinumOuncesRemoved,
        palladiumOuncesRemoved,
        rhodiumOuncesRemoved,
        sentFromCompany: { payoutRule },
        suggestedFinalPaymentDate,
        stillwaterInitialPayment,
        stillwaterFinalPayment,
      } = lot;

      if (
        platinumOuncesRemoved === 0 &&
        palladiumOuncesRemoved === 0 &&
        rhodiumOuncesRemoved === 0
      ) {
        change(
          'lot.lotCloseDate',
          Object.prototype.toString.call(outgoingShipment) === '[object Object]' &&
            Object.keys(outgoingShipment).length
            ? dateAdapter(outgoingShipment.estPickupDate)
            : payoutRule?.rule === payoutRulesTypes.net30
            ? dateAdapter(suggestedFinalPaymentDate)
            : invoiceGeneratedAt && dateAdapter(invoiceGeneratedAt),
        );
        touch('lot.lotCloseDate');
      }

      if (
        (stillwaterInitialPayment !== null || stillwaterFinalPayment !== null) &&
        !receivedFromStillwater
      ) {
        const total = +evaluate(
          `${safeParseFloat(stillwaterInitialPayment)} + ${+safeParseFloat(
            stillwaterFinalPayment,
          )}`,
        );

        change('lot.receivedFromStillwater', total);
      }
    });
  };

  useEffect(() => {
    fetchData();
    touch('lot.paidByGlobal');
  }, [dispatch, match.params.id]);

  return (
    <Form
      header={PROFIT_LOSS.LOT_VALUES}
      onSubmit={handleSubmit}
      submitDisabled={invalid}
      loaded={!(lotIsPending && isPending)}
    >
      <FormSection name="lot">
        <Section template={['receivedFromStillwater paidByGlobal', 'lotCloseDate .']}>
          <Field
            name="receivedFromStillwater"
            component={FieldInput}
            label={PROFIT_LOSS.RECEIVED_FROM_STILLWATER}
            field="required"
            type="text"
            prefix="$"
          />
          <Field
            name="paidByGlobal"
            component={FieldHint}
            label={PROFIT_LOSS.PAID_BY_GLOBAL}
            field="required"
            type="text"
            prefix="$"
            hint={PROFIT_LOSS.CALCULATED}
            suggestion={paidByGlobal}
          />
          <Field
            name="lotCloseDate"
            component={FieldDate}
            label={PROFIT_LOSS.LOT_CLOSE_DATE}
            field="required"
            minDate={lot && dateAdapter(lot.receivedAt)}
          />
        </Section>
      </FormSection>
    </Form>
  );
};

const mapStateToProps = ({ lotDetails }) => ({
  lot: lotDetails && lotDetails.lot,
});

const LotForm = compose(
  withRouter,
  connect(mapStateToProps),
  reduxForm({
    validate: multiValidator('lot', lotValidation),
  }),
)(LotFormLayout);

export { LotForm, LotFormLayout };
