import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, FieldArray } from 'redux-form';

import { clearSummaryAvailableLots } from 'actions/PoolAccounts/getSummaryAvailableLots';
import { clearUsersList } from 'actions/Users/usersList';
import { openModal } from 'actions/shared/modal';

import { Form } from 'shared/components/Form';
import { Preloader } from 'shared/components/Preloader/Preloader';
import { cancelModal } from 'shared/helpers/constants/modalConstants';
import { validate } from 'shared/helpers/validations/settlements/enterSettledOuncesValidation';

import { goBackOrTo } from 'utils/history';

import { EnterSettledOuncesFormList } from './EnterSettledOuncesFormList/EnterSettledOuncesFormList';

class EnterSettledOuncesFormBase extends Component {
  componentWillUnmount() {
    this.props.clearUsersList();
    this.props.clearSummaryAvailableLots();
  }

  renderOuncesArray = ({ fields }) => (
    <EnterSettledOuncesFormList {...this.props} fields={fields} />
  );

  onCancel = () => {
    const { destroy } = this.props;

    this.props.openModal(cancelModal, () => {
      destroy();

      goBackOrTo('/hedges');
    });
  };

  submit = values => {
    const { destroy, handleSubmit } = this.props;

    handleSubmit(values);
    destroy();
  };

  render() {
    const { invalid, submitting, enterSettledOunces } = this.props;

    return (
      <>
        <Form
          onSubmit={this.submit}
          onCancel={this.onCancel}
          submitDisabled={invalid || submitting}
        >
          <FieldArray name="settledOunces" component={this.renderOuncesArray} />
        </Form>
        <Preloader loading={submitting || enterSettledOunces.isPending} />
      </>
    );
  }
}

const mapStateToProps = ({ enterSettledOunces }) => ({ enterSettledOunces });

const mapDispatchToProps = {
  openModal,
  clearUsersList,
  clearSummaryAvailableLots,
};

const EnterSettledOuncesForm = compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'enterSettledOunces',
    destroyOnUnmount: false,
    validate,
    initialValues: { settledOunces: [{ formId: Date.now() }] },
  }),
)(EnterSettledOuncesFormBase);

export { EnterSettledOuncesForm, EnterSettledOuncesFormBase };
