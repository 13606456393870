import styled from 'styled-components';

import { media } from 'shared/helpers/styling/styling';

export const StyledSearchInput = styled.input`
  background-color: var(--fiord);
  border: none;
  border-radius: 25px;
  padding-left: 15px;
  color: var(--geyser);
  height: 40px;
  width: 320px;
  outline: none;

  ${media.phone`
max-width: 80%;
`}

  ${media.mobile`
max-width: 100%;
`}
`;
