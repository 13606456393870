import React, { useCallback } from 'react';
import { connect } from 'react-redux';

import { compareBarcodes } from 'actions/Lots/compareBarcodes';
import { getLotByBarcode } from 'actions/Lots/getLotByBarcode';
import {
  getGlobalCreatedPackage,
  clearPackageDetailsAction,
} from 'actions/Packages/packageDetails';

import { BarcodeScanner } from 'shared/components/BarcodeScanner/BarcodeScanner';
import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';

const CompareBarcodesBase = ({
  open,
  onSelfClose,
  getLotByBarcode: getLotByBarcodeAction,
  getGlobalCreatedPackage: getGlobalCreatedPackageAction,
  compareBarcodes: compareBarcodesAction,
}) => {
  const firstBarcodeEntered = useCallback(
    async firstBarcode => {
      try {
        (await getLotByBarcodeAction(firstBarcode)) ||
          (await getGlobalCreatedPackageAction(firstBarcode));
      } catch (e) {
        return Promise.reject(LOTS.PRINT_DUPLICATE_ERROR);
      }
    },
    [getLotByBarcodeAction, getGlobalCreatedPackageAction],
  );

  const secondBarcodeEntered = useCallback(
    async (firstBarcode, secondBarcode) => {
      try {
        const response = await compareBarcodesAction(firstBarcode, secondBarcode);
        return response.message;
      } catch (e) {
        return Promise.reject(e.err ? e.err.message : e.message);
      }
    },
    [compareBarcodesAction],
  );

  const idleStates = [
    {
      icon: 'icon-box',
      name: 'First',
      barcodeFormat: /(^[a-f0-9]{8}$)|(^[a-f0-9]{24}$)|(^[A-Z0-9()\- ]+$)/,
      onChange: firstBarcodeEntered,
    },
    {
      icon: 'icon-box',
      name: 'Second',
      barcodeFormat: /(^[a-f0-9]{8}$)|(^[a-f0-9]{24}$)|(^[A-Z0-9()\- ]+$)/,
    },
  ];

  const messageConfig = {
    success: LOTS.BARCODE_SUCCESSFULLY_SCAN,
    error: LOTS.BARCODE_SCANNING_ERROR,
    idle: LOTS.BARCODE_SCAN,
    manualIdle: LOTS.BARCODE_ENTER,
  };

  return (
    <BarcodeScanner
      title={LOTS.COMPARE_BARCODES}
      open={open}
      onSelfClose={onSelfClose}
      message={messageConfig}
      states={Object.keys(idleStates)}
      idleStates={idleStates}
      onSuccessfulScan={secondBarcodeEntered}
    />
  );
};

const mapDispatchToProps = {
  compareBarcodes,
  getLotByBarcode,
  getGlobalCreatedPackage,
  clearPackageDetailsAction,
};

const CompareBarcodes = connect(null, mapDispatchToProps)(CompareBarcodesBase);

export { CompareBarcodes, CompareBarcodesBase };
