import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import styled from 'styled-components';

import { assignedViewPrintPackageLabels } from 'actions/Packages/printPackageLabels';
import {
  getPrintablePackages,
  clearPrintablePackages,
} from 'actions/Packages/printablePackagesList';
import { clearShipmentPackagesList } from 'actions/Shipments/shipmentPackagesList';
import { showSnackbar } from 'actions/shared/snackbar';

import { Button } from 'shared/components/Buttons/Button';
import { PrintIntent } from 'shared/components/PrintIntent/PrintIntent';
import { PACKAGES } from 'shared/helpers/constants/packages/packageConstants';
import { isInternalCompany } from 'shared/helpers/matchers/checkCompanyType';
import { isCustomerOrGrading } from 'shared/helpers/matchers/checkUserType';
import { snackbarOptions } from 'shared/helpers/matchers/snackbar/snackbarOptions';
import { media } from 'shared/helpers/styling/styling';

import { printLabelsUrl } from 'utils/labelsUrls';
import { sleep } from 'utils/sleep';

const ActionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;

  ${media.phone`
    Button {
      flex-direction: column;
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  `}
`;

const PrintPackageLabelsActionsBase = ({ match, location, packageClass }) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const matchId = match.params.id;
  const dispatch = useDispatch();
  const packagesOverviewList = useSelector(state => state.packagesOverviewList);
  const shipmentPackagesListData = useSelector(state => state.shipmentPackagesListData);
  const companyType = useSelector(state => state.lotDetails?.lot?.sentFromCompany?.companyType);
  const userType = useSelector(state => state.auth.user.userType);
  const relatedType = location.pathname.includes('shipments') ? 'shipments' : 'lots';
  const isAndroid = /Android/i.test(navigator.userAgent);
  const packagesClassForType =
    relatedType === 'shipments' ||
    (isCustomerOrGrading(userType) && !isInternalCompany(companyType))
      ? 'all'
      : packageClass;
  const packages = packagesOverviewList.packagesList[packagesClassForType];
  const selectedPackages = packages?.docs.filter(p =>
    shipmentPackagesListData.packages.includes((p.newPackageNumber || p.packageNumber).toString()),
  );

  useEffect(() => {
    if (packageClass) dispatch(getPrintablePackages(relatedType, matchId, packageClass));
    return () => {
      dispatch(clearPrintablePackages());
      dispatch(clearShipmentPackagesList());
    };
  }, [dispatch, relatedType, matchId, packageClass]);

  const resolveAction = useCallback(
    result => dispatch(showSnackbar(snackbarOptions(result.messageType), result.message)),
    [dispatch],
  );

  const printAllLabels = useCallback(
    () =>
      dispatch(assignedViewPrintPackageLabels(relatedType, packageClass, match.params.id))
        .then(resolveAction)
        .catch(resolveAction),
    [dispatch, relatedType, match, resolveAction, packageClass],
  );

  const printSelectedLabels = useCallback(
    () =>
      dispatch(
        assignedViewPrintPackageLabels(
          relatedType,
          packageClass,
          matchId,
          (selectedPackages || []).map(p => p._id),
        ),
      )
        .then(resolveAction)
        .catch(resolveAction),
    [dispatch, resolveAction, relatedType, matchId, selectedPackages, packageClass],
  );

  const allFallbackUrl = `/packages/print-labels/${relatedType}/${packageClass}/${matchId}`;
  const selectedFallbackUrl = `${allFallbackUrl}/${(selectedPackages || [])
    .map(p => p._id)
    .join()}`;

  const printSelectedLabelsDeepLink = printLabelsUrl(selectedPackages);
  const printAllLabelsDeepLink = printLabelsUrl(packages.docs);

  const blockButton = useCallback(async () => {
    setIsDisabled(true);
    await sleep(3000);
    setIsDisabled(false);
  });

  return (
    <ActionsContainer>
      {isAndroid ? (
        <>
          <Button disabled={!shipmentPackagesListData.packages.length}>
            {shipmentPackagesListData.packages.length > 0 ? (
              <PrintIntent intent={printSelectedLabelsDeepLink} fallback={selectedFallbackUrl}>
                {PACKAGES.PRINT_SELECTED_LABELS}
              </PrintIntent>
            ) : (
              PACKAGES.PRINT_SELECTED_LABELS
            )}
          </Button>
          <Button disabled={isDisabled} onClick={blockButton}>
            <PrintIntent intent={printAllLabelsDeepLink} fallback={allFallbackUrl}>
              {PACKAGES.PRINT_ALL_LABELS}
            </PrintIntent>
          </Button>
        </>
      ) : (
        <>
          <Button
            outline
            id="printSelectedLabels"
            onClick={printSelectedLabels}
            disabled={!shipmentPackagesListData.packages.length}
          >
            {PACKAGES.PRINT_SELECTED_LABELS}
          </Button>
          <Button outline id="printAllLabels" onClick={printAllLabels}>
            {PACKAGES.PRINT_ALL_LABELS}
          </Button>
        </>
      )}
    </ActionsContainer>
  );
};

const PrintPackageLabelsActions = withRouter(PrintPackageLabelsActionsBase);

export { PrintPackageLabelsActions };
