import PropTypes from 'prop-types';
import { memo } from 'react';
import styled, { css } from 'styled-components';

import { IconButton } from 'shared/components/Buttons/IconButton/IconButton';
import { Icon } from 'shared/components/Icon/Icon';
import { cssVar, pxToRem, media, pixelify } from 'shared/helpers/styling/styling';

const Container = styled.div`
  z-index: 1002;
  min-height: 88px;
  width: ${({ width }) => pixelify(width)};
  padding: 0 10px;
  position: fixed;
  bottom: 1px;
  left: ${({ width }) => `calc(50vw - ${width / 2}px)`};
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-size: ${pxToRem(18)};
  color: ${cssVar('alabaster')};
  background-color: ${cssVar('ebonyClaySnackbar')};
  border: 2px solid ${({ borderColor }) => borderColor};
  word-break: break-word;
  animation: linear appear 300ms;

  ${({ top }) =>
    top &&
    css`
      top: 1px;
      bottom: auto;
    `}

  ${media.tablet`
    width: 80vw;
    left: 10vh;
  `};

  ${media.phone`
    width: 100vw;
    left: 0;
  `};

  @keyframes appear {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const Text = styled.span`
  width: 400px;
  margin: 0 10px;

  :hover {
    cursor: normal;
  }
`;

const SnackbarContent = memo(
  ({ options, snackbarMessage, icon, onCancel, top = false, width = 600 }) => (
    <Container {...options} width={width} id="snackbar" top={top}>
      <Icon icon={icon} height={60} width={60} size="cover" />
      <Text>{snackbarMessage}</Text>
      <IconButton
        data-testid="snackbar-cancel"
        id="snackbar-cancel"
        icon="icon-cancel"
        onClick={onCancel}
      />
    </Container>
  ),
);

SnackbarContent.propTypes = {
  options: PropTypes.shape({
    borderColor: PropTypes.string,
    icon: PropTypes.string,
  }).isRequired,
  snackbarMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  icon: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  top: PropTypes.bool,
  width: PropTypes.number,
};

export { SnackbarContent };
