import PropTypes from 'prop-types';
import React from 'react';

import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';
import { statusesForGlobal } from 'shared/helpers/constants/lots/statusesForGlobal';

import { StyledContent } from './StyledWarningMessage';

const selectContent = (lotStatus, destination) => {
  switch (lotStatus) {
    case statusesForGlobal.invoice_accepted:
    case statusesForGlobal.invoice_generated:
      return LOTS.LOT_STATUSES_WARNING.INVOICE_GENERATED_AND_ACCEPTED(destination);
    default:
      return '';
  }
};

const WarningMessage = ({ superAdminMode, selectedLotStatus, destination }) => {
  return (
    (superAdminMode || !!selectedLotStatus) && (
      <StyledContent displayWarning={!!selectedLotStatus}>
        <span>{selectContent(selectedLotStatus, destination)}</span>
      </StyledContent>
    )
  );
};

WarningMessage.propTypes = {
  superAdminMode: PropTypes.bool,
  selectedLotStatus: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export { WarningMessage };
