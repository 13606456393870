import axios from 'axios';

import { showSnackbar } from 'actions/shared/snackbar';

import {
  dangerOptions,
  successOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

const API_HOST_AUTH = process.env.REACT_APP_API;

const changeLocationStatusActionType = 'CHANGE_LOCATION_STATUS';
function changeLocationStatusAction() {
  return {
    type: changeLocationStatusActionType,
  };
}

const changeLocationStatusSuccessActionType = 'CHANGE_LOCATION_STATUS_SUCCESS';
function changeLocationStatusSuccessAction(location) {
  return {
    type: changeLocationStatusSuccessActionType,
    payload: {
      location,
    },
  };
}

const changeLocationStatusFailureActionType = 'CHANGE_LOCATION_STATUS_FAILURE';
function changeLocationStatusFailureAction() {
  return {
    type: changeLocationStatusFailureActionType,
  };
}

function changeLocationStatus(id, status) {
  return dispatch => {
    dispatch(changeLocationStatusAction);

    return axios({
      method: 'put',
      url: `${API_HOST_AUTH}/locations/change-status/${id}`,
      data: JSON.stringify({ status }),
    })
      .then(response => {
        dispatch(changeLocationStatusSuccessAction(response.data.location));
        dispatch(showSnackbar(successOptions, response.data.message));
        return response;
      })
      .catch(error => {
        dispatch(changeLocationStatusFailureAction());
        dispatch(
          showSnackbar(
            dangerOptions,
            `Location status has not been changed. ${error.data.message}`,
          ),
        );
      });
  };
}

export {
  changeLocationStatusActionType,
  changeLocationStatusSuccessActionType,
  changeLocationStatusFailureActionType,
  changeLocationStatus,
};
