import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';

import { getTimeline, clearTimeline } from 'actions/Lots/getTimeline';
import { showSnackbar } from 'actions/shared/snackbar';

import { ModalCustom } from 'components/ModalCustom/ModalCustom';

import { ContentLoader } from 'shared/components/ContentLoader/ContentLoader';
import { Timeline } from 'shared/components/Timeline/Timeline';
import { eventTypes } from 'shared/helpers/constants/lots/eventTypes';
import { dangerOptions } from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { detailsFormatter } from 'shared/helpers/dataParsers/lot/detailsFormatter';
import { cssVar } from 'shared/helpers/styling/styling';

function colorResolver(type) {
  switch (type) {
    case eventTypes.created:
    case eventTypes.received:
    case eventTypes.graded:
    case eventTypes.markedForProcessing:
    case eventTypes.processed:
    case eventTypes.mixed:
    case eventTypes.xmlSummaryReceived:
    case eventTypes.invalidXmlSummaryReceived:
    case eventTypes.assayEnteredManually:
    case eventTypes.noteEntered:
    case eventTypes.inspectionRequested:
    case eventTypes.inspectionScheduledFor:
    case eventTypes.commodityRemovedTo:
    case eventTypes.commodityAddedFrom:
    case eventTypes.gradingReceiptUploaded:
    case eventTypes.countSheetUploaded:
    case eventTypes.stillwaterShipmentSummaryUploaded:
    case eventTypes.stillwaterInitialPayment:
    case eventTypes.stillwaterFinalPayment:
    case eventTypes.stillwaterFinalInvoiceUploaded:
    case eventTypes.globalShipmentSummaryGenerated:
    case eventTypes.globalShipmentSummaryApproved:
    case eventTypes.globalFinalInvoiceMockup:
    case eventTypes.globalFinalInvoiceGenerated:
    case eventTypes.globalFinalInvoiceApproved:
    case eventTypes.gradingReceiptCFU:
    case eventTypes.poolSettlementDocumentCFU:
    case eventTypes.billOfLadingCFU:
    case eventTypes.countSheetCFU:
    case eventTypes.grgFinalInvoiceCFU:
    case eventTypes.globalFinalInvoiceUploaded:
    case eventTypes.profitLossGenerated:
    case eventTypes.profitLossApproved:
    case eventTypes.gradingReceiptAccepted:
      return cssVar('shipCove');
    case eventTypes.shippedToGlobal:
    case eventTypes.shippedToStillwater:
      return cssVar('blueMarguerite');
    case eventTypes.hedgeRequested:
    case eventTypes.hedgeDecision:
    case eventTypes.hedgePlaced:
    case eventTypes.hedgeOuncesAssignedFrom:
      return cssVar('blueBayoux');
    case eventTypes.paymentRequested:
    case eventTypes.paymentDecision:
    case eventTypes.paymentPaidOut:
      return cssVar('seaGreenGRG');
    case eventTypes.poolOuncesAdded:
    case eventTypes.poolOuncesSettled:
      return cssVar('blueLagon');
    case eventTypes.packageUpdated:
      return cssVar('limedSpruceNav');
    case eventTypes.setFinalizedLot:
    case eventTypes.cancelHedge:
    case eventTypes.changeHedgeOunces:
    case eventTypes.changeHedgePrices:
    case eventTypes.transferHedgeToDifferentLot:
    case eventTypes.reassignPayment:
    case eventTypes.changeLotStatus:
    case eventTypes.deleteIncomingPackage:
      return cssVar('bs-danger');
    default:
      return;
  }
}

const pathResolver = lotId => (type, id) => {
  switch (type) {
    case eventTypes.shippedToGlobal:
      return `/shipments/incoming/${id}`;
    case eventTypes.shippedToStillwater:
      return `/shipments/outgoing/${id}`;
    case eventTypes.hedgeRequested:
    case eventTypes.hedgeDecision:
    case eventTypes.hedgePlaced:
    case eventTypes.hedgeOuncesAssignedFrom:
      return `/hedges/${id}`;
    case eventTypes.paymentRequested:
    case eventTypes.paymentDecision:
    case eventTypes.paymentPaidOut:
      return `/payments/${id}`;
    case eventTypes.assayEnteredManually:
      return `/lots/list/${lotId}/assay/${id}`;
    case eventTypes.packageUpdated:
      return `/lots/list/${lotId}/packages/${id}`;
    case eventTypes.setFinalizedLot:
    case eventTypes.changeLotStatus:
      return `/lots/list/${lotId}`;
    case eventTypes.cancelHedge:
    case eventTypes.changeHedgeOunces:
    case eventTypes.changeHedgePrices:
    case eventTypes.transferHedgeToDifferentLot:
    case eventTypes.deleteIncomingPackage:
      return `/hedges/${id}`;
    case eventTypes.reassignPayment:
      return `/payments/${id}`;
    default:
      return;
  }
};

function LotTimelineBase({
  match: {
    params: { id },
  },
  history,
}) {
  const dispatch = useDispatch();

  const modalClose = useCallback(() => {
    history.replace(`/lots/list/${id}`);
  }, [history, id]);

  useEffect(() => {
    dispatch(getTimeline(id)).catch(res => {
      dispatch(showSnackbar(dangerOptions, res?.message));
      modalClose();
    });

    return () => dispatch(clearTimeline());
  }, [modalClose, dispatch, id]);

  const { events, isPending } = useSelector(state => state.getTimeline);

  return (
    <ModalCustom modalClose={modalClose} maxWidth={900} isOpen>
      <ContentLoader loaded={!isPending}>
        {events && (
          <Timeline
            events={events}
            colorResolver={colorResolver}
            pathResolver={pathResolver(id)}
            detailsFormatter={detailsFormatter}
          />
        )}
      </ContentLoader>
    </ModalCustom>
  );
}

const LotTimeline = withRouter(LotTimelineBase);

export { LotTimeline };
