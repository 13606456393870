import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { getLotsForInspection, clearAssignedLotsAction } from 'actions/Lots/getAssignedLots';
import { scheduleLotInspection } from 'actions/Lots/lotInspections';
import { closeModal, openModal } from 'actions/shared/modal';
import { showSnackbar } from 'actions/shared/snackbar';

import { ScheduleLotInspectionBase } from './ScheduleLotInspection';

const mapStateToProps = ({ modal, lotInspections, assignedLots }) => ({
  modal,
  lotInspections,
  assignedLots,
});

const mapDispatchToProps = {
  openModal,
  closeModal,
  showSnackbar,
  scheduleLotInspection,
  getLotsForInspection,
  clearAssignedLotsAction,
};

const ScheduleLotInspection = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ScheduleLotInspectionBase),
);

export { ScheduleLotInspection };
