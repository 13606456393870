import FormData from 'form-data';

function parseNoteData(formValues, attachments, relatedId, type, doNotUpdate, virtual) {
  const noteForm = new FormData();

  noteForm.append('relatedId', relatedId);
  noteForm.append('type', type);
  noteForm.append('note', formValues.note);
  noteForm.append('doNotUpdate', doNotUpdate);
  noteForm.append('virtual', virtual);
  attachments.forEach(val => {
    noteForm.append('attachment', val);
  });

  return noteForm;
}

export { parseNoteData };
