import axios from 'axios';

import { globalRefiningGroupName } from 'shared/helpers/constants/companies/globalRefiningGroup';

const API_HOST_AUTH = process.env.REACT_APP_API;

const basicCompaniesMapper = companies =>
  companies.map(company => ({
    value: company._id,
    label: company.companyName,
    status: company.status,
  }));

const poolAccountCompaniesMapper = companies =>
  companies.map(company => ({
    value: company._id,
    label: company.companyName,
    platinumOz: company.platinumOz,
    palladiumOz: company.palladiumOz,
    rhodiumOz: company.rhodiumOz,
    balanceRemaining: company.balanceRemaining,
    poolPaymentRequestExists: company.poolPaymentRequestExists,
    financeChargesRequired: company.financeChargesRequired,
    relatedLots: company.relatedLots,
    charges: company.charges,
  }));

const filterCompaniesActionType = 'FILTER_COMPANIES';
function filterCompaniesAction(filteredCompanies) {
  return {
    type: filterCompaniesActionType,
    payload: {
      filteredCompanies,
    },
  };
}

function getCompaniesBySearchStrUserType(searchFilter, userType, restrictedStatuses) {
  return dispatch =>
    axios
      .get(
        `${API_HOST_AUTH}/companies/search-filter/${encodeURIComponent(searchFilter)}/${userType}`,
      )
      .then(res => basicCompaniesMapper(res.data))
      .then(res =>
        restrictedStatuses ? res.filter(c => !restrictedStatuses.includes(c.status)) : res,
      )
      .then(res => {
        dispatch(filterCompaniesAction(res));
        return { options: res };
      });
}

function getCompaniesBySearchStr(searchFilter, withoutGlobal) {
  return dispatch =>
    axios
      .get(`${API_HOST_AUTH}/companies/search-filter/${encodeURIComponent(searchFilter)}`)
      .then(res =>
        res.data.map(company => ({
          value: company._id,
          label: company.companyName,
          status: company.status,
          companyType: company.companyType,
          materialCountRequired: company.materialCountRequired,
          paymentExceptionCompany: company.paymentExceptionCompany,
          relatedLocations: company.relatedLocations.sort((a, b) => a.label.localeCompare(b.label)),
          balanceRemaining: company.balanceRemaining,
        })),
      )
      .then(res => (withoutGlobal ? res.filter(c => c.label !== globalRefiningGroupName) : res))
      .then(res => {
        dispatch(filterCompaniesAction(res));
        return { options: res };
      });
}

const getCompaniesFactory =
  (url, mapper = basicCompaniesMapper) =>
  dispatch =>
    axios
      .get(`${API_HOST_AUTH}/${url}`)
      .then(res => mapper(res.data))
      .then(res => {
        dispatch(filterCompaniesAction(res));
        return { options: res };
      });

const getCompaniesForEnterPoolAccountOunces = filter =>
  getCompaniesFactory(`companies/pool-account/enter-ounces/${encodeURIComponent(filter)}`);

const getCompaniesForSettleOunces = filter =>
  getCompaniesFactory(
    `companies/pool-account/settle-ounces/${encodeURIComponent(filter)}`,
    poolAccountCompaniesMapper,
  );

const getCompaniesForPoolPayments = filter =>
  getCompaniesFactory(
    `companies/payments/pool/${encodeURIComponent(filter)}`,
    poolAccountCompaniesMapper,
  );

export {
  filterCompaniesActionType,
  getCompaniesBySearchStr,
  getCompaniesBySearchStrUserType,
  getCompaniesForEnterPoolAccountOunces,
  getCompaniesForSettleOunces,
  getCompaniesForPoolPayments,
};
