import { PROFIT_LOSS } from 'shared/helpers/constants/lots/profitLossConstants';
import { dateToTimestamp } from 'shared/helpers/parsers/date';
import { safeParseFloat } from 'shared/helpers/parsers/number';
import { replaceEmpty } from 'shared/helpers/parsers/text';
import { commaValidator } from 'shared/helpers/validations/commaValidator';
import { PRICE_REGEX } from 'shared/helpers/validations/regularExpressions';

import dateAdapter from 'utils/date/dateAdapter';

const { ERRORS } = PROFIT_LOSS;

const VALUE_LIMIT = 12;

function currentCommoditiesValidation({ commodities }) {
  const errors = {};

  errors.commodities = (commodities || []).map(commodity => {
    const commodityErrors = {};

    if (replaceEmpty(commodity.value, '').length === 0) {
      commodityErrors.value = ERRORS.VALUE_EMPTY;
    } else if (!PRICE_REGEX.test(commodity.value)) {
      commodityErrors.value = ERRORS.VALUE_INVALID;
    } else if (commodity.value && commodity.value.length > VALUE_LIMIT) {
      commodityErrors.value = ERRORS.VALUE_INVALID;
    } else if (safeParseFloat(commodity.value) < 0) {
      commodityErrors.value = ERRORS.COMMODITY_VALUE_NEGATIVE;
    }

    commaValidator({ value: commodity.value }, commodityErrors);

    return commodityErrors;
  });

  return errors;
}

function additionalCommoditiesValidation({ commodities }) {
  const errors = {};

  errors.commodities = (commodities || []).map(commodity => {
    const commodityErrors = {};

    if (!commodity.action) {
      commodityErrors.action = ERRORS.ACTION_EMPTY;
    }

    if (!commodity.description) {
      commodityErrors.description = ERRORS.DESCRIPTION_EMPTY;
    }

    if (commodity.details && commodity.details.length > 50) {
      commodityErrors.details = ERRORS.DETAILS_INVALID;
    }

    if (replaceEmpty(commodity.value, '').length === 0) {
      commodityErrors.value = ERRORS.VALUE_EMPTY;
    } else if (!PRICE_REGEX.test(commodity.value)) {
      commodityErrors.value = ERRORS.VALUE_INVALID;
    } else if (commodity.value && commodity.value.length > VALUE_LIMIT) {
      commodityErrors.value = ERRORS.VALUE_INVALID;
    } else if (safeParseFloat(commodity.value) < 0) {
      commodityErrors.value = ERRORS.COMMODITY_VALUE_NEGATIVE;
    }

    commaValidator({ value: commodity.value }, commodityErrors);

    return commodityErrors;
  });

  return errors;
}

function lotValidation(values, current, props) {
  const errors = {};

  if (replaceEmpty(values.receivedFromStillwater, '').length === 0) {
    errors.receivedFromStillwater = ERRORS.RECEIVED_FROM_STILLWATER_EMPTY;
  } else if (!PRICE_REGEX.test(values.receivedFromStillwater)) {
    errors.receivedFromStillwater = ERRORS.RECEIVED_FROM_STILLWATER_INVALID;
  } else if (values.receivedFromStillwater && values.receivedFromStillwater.length > VALUE_LIMIT) {
    errors.receivedFromStillwater = ERRORS.RECEIVED_FROM_STILLWATER_INVALID;
  } else if (safeParseFloat(values.receivedFromStillwater) <= 0) {
    errors.receivedFromStillwater = ERRORS.RECEIVED_FROM_STILLWATER_NEGATIVE;
  }

  if (replaceEmpty(values.paidByGlobal, '').length === 0) {
    errors.paidByGlobal = ERRORS.PAID_BY_GLOBAL_EMPTY;
  } else if (!PRICE_REGEX.test(values.paidByGlobal)) {
    errors.paidByGlobal = ERRORS.PAID_BY_GLOBAL_INVALID;
  } else if (values.paidByGlobal && values.paidByGlobal.toString().length > VALUE_LIMIT) {
    errors.paidByGlobal = ERRORS.PAID_BY_GLOBAL_INVALID;
  } else if (safeParseFloat(values.paidByGlobal) <= 0) {
    errors.paidByGlobal = ERRORS.PAID_BY_GLOBAL_NEGATIVE;
  }

  if (!values.lotCloseDate) {
    errors.lotCloseDate = ERRORS.LOT_CLOSE_DATE_EMPTY;
  } else if (
    values.lotCloseDate &&
    props.lot &&
    dateToTimestamp(values.lotCloseDate) < dateAdapter(props.lot.receivedAt).startOf('d').valueOf()
  ) {
    errors.lotCloseDate = ERRORS.LOT_CLOSE_DATE_TOO_SOON;
  }

  commaValidator(
    {
      receivedFromStillwater: values.receivedFromStillwater,
      paidByGlobal: values.paidByGlobal,
    },
    errors,
  );

  return errors;
}

export { currentCommoditiesValidation, additionalCommoditiesValidation, lotValidation };
