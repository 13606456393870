import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { getLot } from 'actions/Lots/lotDetails';
import {
  getMaximumSettlementOunces,
  clearMaximumSettlementOunces,
} from 'actions/PoolAccounts/getMaximumSettlementOunces';
import { hideSnackbar, showSnackbar } from 'actions/shared/snackbar';

import { UIAuthorization } from 'components/Authorization/UIAuthorization';
import { ModalCustom } from 'components/ModalCustom/ModalCustom';
import { NotificationActionsPortal } from 'components/NotificationActionsPortal/NotificationActionsPortal';

import { Button } from 'shared/components/Buttons/Button';
import { ExtendableButton } from 'shared/components/Buttons/ExtendableButton/ExtendableButton';
import { RouterButton } from 'shared/components/Buttons/RouterButton/RouterButton';
import { DetailsHeader } from 'shared/components/Details/DetailsHeader';
import { PrintIntent } from 'shared/components/PrintIntent/PrintIntent';
import {
  markGradingCompleteAccess,
  markProcessingCompleteAccess,
  acceptLotForGradingAccess,
  acceptLotForProcessingAccess,
  acceptLotForMixingAccess,
  changeProcessUponDeliveryAccess,
  requestSettlementForLotAccess,
} from 'shared/helpers/accesses/lots';
import { deleteLotAccess } from 'shared/helpers/accesses/lots/deleteLotAccess';
import { enterAssayResultsAccess } from 'shared/helpers/accesses/lots/enterAssayResultsAccess';
import {
  finalizeLotAccess,
  specialPermissionSetFinalizedLotAccess,
} from 'shared/helpers/accesses/lots/finalizeLotAccess';
import {
  generateFinalInvoiceMockupAccess,
  finalInvoiceAccess,
  settlementsAccess,
  generateShipmentSummaryAccess,
  generateProfitLossAccess,
  customHedgesPricesAccess,
} from 'shared/helpers/accesses/lots/generateDocumentsAccess';
import { manageGradingReceiptAccess } from 'shared/helpers/accesses/lots/manageGradingReceiptAccess';
import { removeCommodityAccess } from 'shared/helpers/accesses/lots/removeCommodityAccess';
import { settlementMockUpAccess } from 'shared/helpers/accesses/lots/settlementMockUpAccess';
import {
  updateLotLocationAccess,
  changeReceivedDateAccess,
} from 'shared/helpers/accesses/lots/updateLotAccess';
import { updateShipmentInfoAccess } from 'shared/helpers/accesses/lots/updateShipmentInfo';
import { uploadGradingReceiptAccess } from 'shared/helpers/accesses/lots/uploadGradingReceiptAccess';
import { createIncomingPackageAccess } from 'shared/helpers/accesses/packages/createIncomingPackageAccess';
import { createProcessingPackageAccess } from 'shared/helpers/accesses/packages/createProcessingPackageAccess';
import { scanSamplePackagesAccess } from 'shared/helpers/accesses/packages/packageActivationAccess';
import { assignedViewPrintLabRequestFormsAccess } from 'shared/helpers/accesses/packages/printLabRequestFormsAccess';
import { assignedViewPrintPackageLabelsAccess } from 'shared/helpers/accesses/packages/printPackageLabelsAccess';
import { scanReceivedPackagesAccess } from 'shared/helpers/accesses/packages/scanReceivedPackagesAccess';
import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';
import { statusesForGlobal } from 'shared/helpers/constants/lots/statusesForGlobal';
import { notificationActionTypes } from 'shared/helpers/constants/notifications/notificationActionTypes';
import { PACKAGES } from 'shared/helpers/constants/packages/packageConstants';
import { POOL_ACCOUNTS } from 'shared/helpers/constants/poolAccounts/poolAccountsConstants';
import { shipmentStatuses } from 'shared/helpers/constants/shipments/shipmentStatuses';
import { infoOptions } from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { setDesignation } from 'shared/helpers/dataParsers/lot/setDesignation';
import { isInternalCompany } from 'shared/helpers/matchers/checkCompanyType';
import { isViewAndShip } from 'shared/helpers/matchers/checkUserAccessLevel';
import { isCustomerUser, isGradingUser } from 'shared/helpers/matchers/checkUserType';
import { isGlobalRefiningGroup } from 'shared/helpers/matchers/isGlobalRefiningGroup';

import { printLotLabelUrl, printLotLabelEbsUrl } from 'utils/labelsUrls';

import { AcceptForGrading } from 'pages/Lots/components/AcceptForGrading/AcceptForGrading';
import { AcceptForMixing } from 'pages/Lots/components/AcceptForMixing/AcceptForMixing';
import { AcceptForProcessing } from 'pages/Lots/components/AcceptForProcessing/AcceptForProcessing';
import { ConnectedChangeProcessUponDelivery } from 'pages/Lots/components/ChangeProcessUponDelivery/ChangeProcessUponDelivery';
import { DeleteLot } from 'pages/Lots/components/DeleteLot/DeleteLot';
import { FinalizeLot } from 'pages/Lots/components/FinalizeLot/FinalizeLot';
import { GenerateShipmentSummary } from 'pages/Lots/components/GenerateShipmentSummary/GenerateShipmentSummary';
import { InactivatePackageBarcodeScanner } from 'pages/Lots/components/InactivatePackageBarcodeScanner/InactivatePackageBarcodeScanner';
import { PrintLotLabel } from 'pages/Lots/components/LotDetails/components/LotDetailsGlobal/components/PrintLotLabel';
import { ManageGradingReceipt } from 'pages/Lots/components/ManageGradingReceipt/ManageGradingReceipt';
import { MarkGradingComplete } from 'pages/Lots/components/MarkGradingComplete/MarkGradingComplete';
import { ConnectedMarkProcessingComplete } from 'pages/Lots/components/MarkProcessingComplete/ConnectedMarkProcessingComplete';
import { ReceiveBarcodeScanner } from 'pages/Lots/components/ReceiveBarcodeScanner/ReceiveBarcodeScanner';
import { RetryImportLot } from 'pages/Lots/components/RetryImportLot/RetryImportLot';
import { UploadBillOfLading } from 'pages/Lots/components/UploadBillOfLading/UploadBillOfLading';
import { UploadGradingReceipt } from 'pages/Lots/components/UploadGradingReceipt/UploadGradingReceipt';
import { PrintLabRequestForms } from 'pages/Packages/components/PrintLabRequestForms/AssignedView/PrintLabRequestForms';
import { PrintPackageLabels } from 'pages/Packages/components/PrintPackageLabels/AssignedView/PrintPackageLabels';

class MainLotDetailsBase extends Component {
  state = {
    showReceiveBarcodeScanner: false,
    showInactivatePackageBarcodeScanner: false,
    displayPrintLabelsModal: false,
    displayGradingReceiptModal: false,
    displayBillOfLadingModal: false,
    displayGenerateShipmentSummaryModal: false,
    displayPrintLabRequestFormsModal: false,
  };
  timer = null;
  isAndroid = /Android/i.test(navigator.userAgent);

  componentDidMount() {
    const { lot, auth } = this.props;
    isCustomerUser(auth.user.userType) &&
      !isViewAndShip(auth.user.accessLevel) &&
      lot?.canBeSettled &&
      this.props.getMaximumSettlementOunces(lot._id);
  }

  componentWillUnmount() {
    this.props.clearMaximumSettlementOunces();
    clearTimeout(this.timer);
  }

  navigateToLotsOverview = () => this.props.history.push('/lots/list');

  getPackageTarget = () =>
    isGlobalRefiningGroup(
      this.props.lot.sentFromCompany && this.props.lot.sentFromCompany.companyName,
    )
      ? 'global'
      : 'customer';

  toggleReceiveBarcodeScanner = () =>
    this.setState(
      ({ showReceiveBarcodeScanner }) => ({
        showReceiveBarcodeScanner: !showReceiveBarcodeScanner,
      }),
      () => {
        if (!this.state.showReceiveBarcodeScanner) {
          this.updateDataAfterScanning();
        }
      },
    );

  toggleInactivatePackageBarcodeScanner = () =>
    this.setState(
      ({ showInactivatePackageBarcodeScanner }) => ({
        showInactivatePackageBarcodeScanner: !showInactivatePackageBarcodeScanner,
      }),
      () => {
        if (!this.state.showInactivatePackageBarcodeScanner) {
          this.updateDataAfterScanning();
        }
      },
    );

  showMissingShipmentInfoWarning = () =>
    this.props.showSnackbar(infoOptions, LOTS.STILLWATER_SHIPMENT_ID_MISSING);

  showMissingShipmentInfoInternalCompanyWarning = () =>
    this.props.showSnackbar(infoOptions, LOTS.INTERNAL_COMPANY_STILLWATER_SHIPMENT_ID_MISSING);

  showMissingAppliedTermsWarning = () =>
    this.props.showSnackbar(infoOptions, LOTS.APPLIED_TERMS_MISSING);

  showSettledOuncesPricesWarning = () =>
    this.props.showSnackbar(infoOptions, LOTS.SETTLED_OUNCES_PRICES_MISSING);

  showCustomHedgesPricesWarning = () =>
    this.props.showSnackbar(infoOptions, LOTS.CUSTOM_HEDGES_PRICES_MISSING);

  toggleGradingReceiptModal = () => {
    this.setState(state => ({
      displayGradingReceiptModal: !state.displayGradingReceiptModal,
    }));
  };

  showNotesBeforeGenerateDocument = type => () => {
    const {
      lot: { _id: lotId },
      history,
    } = this.props;

    return history.push({
      pathname: `/lots/list/${lotId}/notes`,
      state: { prevLink: type },
    });
  };

  toggleGenerateShipmentSummaryModal = () => {
    this.setState(state => ({
      displayGenerateShipmentSummaryModal: !state.displayGenerateShipmentSummaryModal,
    }));
  };

  togglePrintLabRequestFormsModal = () => {
    this.setState(state => ({
      displayPrintLabRequestFormsModal: !state.displayPrintLabRequestFormsModal,
    }));
  };

  generateShipmentSummaryCheckNotes = hasNotes => () =>
    hasNotes
      ? this.showNotesBeforeGenerateDocument('shipment')()
      : this.toggleGenerateShipmentSummaryModal();

  toggleBillOfLadingModal = () => {
    this.setState(
      state => ({
        displayBillOfLadingModal: !state.displayBillOfLadingModal,
      }),
      () => !this.state.displayBillOfLadingModal && this.getLotOnUploadSuccess(),
    );
  };

  showBillOfLadingModalOnFinish = access => () =>
    access
      ? this.setState(state => ({
          showReceiveBarcodeScanner: false,
          displayBillOfLadingModal: !state.displayBillOfLadingModal,
        }))
      : this.toggleReceiveBarcodeScanner();

  getLotOnUploadSuccess = () => {
    this.props.getLot(this.props.match.params.id);
  };

  uploadPrintLabelsModalToggle = () => {
    this.setState(({ displayPrintLabelsModal }) => ({
      displayPrintLabelsModal: !displayPrintLabelsModal,
    }));
  };

  toggleModal = () => {
    this.setState({
      displayPrintLabelsModal: false,
    });
  };

  lotLabelPrintable = () => {
    const {
      auth: {
        user: { userType },
      },
    } = this.props;

    return !isCustomerUser(userType) && !isGradingUser(userType);
  };

  updateDataAfterScanning() {
    const {
      lot: { _id: lotId },
      history,
    } = this.props;

    this.props.getLot(lotId).catch(() => history.push('/lots'));
  }

  generateLotNumber() {
    const {
      lot: { customLotNumber, grgLotNumber, designation },
    } = this.props;

    const lotName = setDesignation(grgLotNumber, designation);

    return (
      (!customLotNumber && lotName) || (
        <>
          {lotName}
          <br />
          {customLotNumber}
        </>
      )
    );
  }

  generateFinalInvoice = (pathEnd, hasNotes) => () => {
    const {
      lot: { _id: lotId },
      settlements,
      hedges,
    } = this.props;

    if (!settlementsAccess(settlements)) {
      return this.showSettledOuncesPricesWarning();
    }

    if (!customHedgesPricesAccess(hedges)) {
      return this.showCustomHedgesPricesWarning();
    }

    return hasNotes
      ? this.showNotesBeforeGenerateDocument('invoice')()
      : this.props.history.push(`/lots/list/${lotId}/generate/${pathEnd}`);
  };

  createIncomingPackageActionResolver = () => {
    const {
      lot: {
        sentFromCompany: { materialCountRequired, companyName },
        _id,
      },
      history,
      shipments,
    } = this.props;

    if (
      materialCountRequired &&
      !shipments.some(
        ({ status }) => status === shipmentStatuses.sent || status === shipmentStatuses.received,
      )
    ) {
      this.timer = setTimeout(() => this.props.hideSnackbar(), 5000);
      return this.props.showSnackbar(infoOptions, LOTS.MATERIAL_COUNT_WARNING(companyName));
    }

    return history.push(`/lots/list/${_id}/packages/create/${this.getPackageTarget()}`);
  };

  render() {
    const { auth, lot, packagesList, shipments, shipmentsIsPending, lotNotesCount } = this.props;
    const { displayPrintLabelsModal } = this.state;
    const hasNotes = lotNotesCount && lotNotesCount > 0;

    return (
      <>
        <DetailsHeader header={this.generateLotNumber()}>
          <ExtendableButton label="Actions">
            <UIAuthorization access={manageGradingReceiptAccess(auth, lot)}>
              <ManageGradingReceipt lot={lot} approve>
                {({ manageGradingReceipt }) => (
                  <NotificationActionsPortal
                    actions={notificationActionTypes.gradingReceiptAvailable}
                  >
                    <Button id="approveGradingReceipt" onClick={manageGradingReceipt}>
                      {LOTS.APPROVE_RECEIPT}
                    </Button>
                  </NotificationActionsPortal>
                )}
              </ManageGradingReceipt>
            </UIAuthorization>
            <UIAuthorization access={manageGradingReceiptAccess(auth, lot)}>
              <ManageGradingReceipt lot={lot}>
                {({ manageGradingReceipt }) => (
                  <NotificationActionsPortal
                    actions={notificationActionTypes.gradingReceiptAvailable}
                  >
                    <Button
                      id="approveGradingReceipt"
                      onClick={manageGradingReceipt}
                      outlineColor="danger"
                    >
                      {LOTS.REJECT_RECEIPT}
                    </Button>
                  </NotificationActionsPortal>
                )}
              </ManageGradingReceipt>
            </UIAuthorization>
            <UIAuthorization access={finalInvoiceAccess(auth, lot)}>
              <Button
                id="generateFinalInvoice"
                label={LOTS.GENERATE_FINAL_INVOICE}
                onClick={
                  lot.termsApplied
                    ? this.generateFinalInvoice('final-invoice', hasNotes)
                    : this.showMissingAppliedTermsWarning
                }
              >
                {LOTS.GENERATE_FINAL_INVOICE}
              </Button>
            </UIAuthorization>
            <UIAuthorization access={generateFinalInvoiceMockupAccess(auth, lot)}>
              <Button
                id="generateFinalInvoiceMockup"
                label={LOTS.GENERATE_FINAL_INVOICE}
                onClick={
                  lot.termsApplied
                    ? this.generateFinalInvoice('final-invoice-mockup')
                    : this.showMissingAppliedTermsWarning
                }
              >
                {LOTS.GENERATE_FINAL_INVOICE_MOCKUP}
              </Button>
            </UIAuthorization>
            <UIAuthorization access={generateShipmentSummaryAccess(auth, lot)}>
              <Button
                id="generateShipmentSummary"
                onClick={
                  lot.stillwaterShipmentId && lot.materialType
                    ? this.generateShipmentSummaryCheckNotes(hasNotes)
                    : this.showMissingShipmentInfoWarning
                }
              >
                {LOTS.GENERATE_SHIPMENT_SUMMARY}
              </Button>
            </UIAuthorization>
            <UIAuthorization access={generateProfitLossAccess(auth, lot)}>
              <RouterButton
                id="generateProfitLoss"
                path={`/lots/list/${lot._id}/generate/profit-loss`}
                label={LOTS.PROFIT_AND_LOSS}
              />
            </UIAuthorization>
            <UIAuthorization access={finalInvoiceAccess(auth, lot)}>
              <RouterButton
                id="uploadFinalInvoice"
                path={`/lots/list/${lot._id}/upload/final-invoice`}
                label={LOTS.UPLOAD_CUSTOM_INVOICE}
              />
            </UIAuthorization>
            <UIAuthorization
              access={settlementMockUpAccess(auth, lot, this.props.maximumSettlementOunces)}
            >
              <RouterButton
                id="settlementMockUp"
                path={`/pool-accounts/settlement-mock-up/${lot._id}`}
                label={POOL_ACCOUNTS.SETTLEMENT_MOCK_UP}
              />
            </UIAuthorization>
            <UIAuthorization
              access={requestSettlementForLotAccess(auth, lot, this.props.maximumSettlementOunces)}
            >
              <RouterButton
                id="requestSettlementForLot"
                path={`/pool-accounts/settlement-for-lot/${lot._id}`}
                label={LOTS.SETTLE}
              />
            </UIAuthorization>
            <UIAuthorization access={createIncomingPackageAccess(auth, lot, shipments)}>
              <Button
                id="createIncomingPackage"
                label={LOTS.CREATE_INCOMING_PACKAGES}
                onClick={this.createIncomingPackageActionResolver}
                disabled={shipmentsIsPending}
              >
                {LOTS.CREATE_INCOMING_PACKAGES}
              </Button>
            </UIAuthorization>
            <UIAuthorization access={createProcessingPackageAccess(auth, lot)}>
              <RouterButton
                id="createProcessingPackage"
                path={`/lots/list/${lot._id}/packages/create/processing`}
                label={LOTS.CREATE_PACKAGES}
                locationState={{ fromLotDetails: true }}
              />
            </UIAuthorization>
            <UIAuthorization access={acceptLotForGradingAccess(auth, lot)}>
              <AcceptForGrading lot={lot}>
                {({ acceptForGrading }) => (
                  <Button outline id="acceptLotForGrading" onClick={acceptForGrading}>
                    {LOTS.ACCEPT_FOR_GRADING}
                  </Button>
                )}
              </AcceptForGrading>
            </UIAuthorization>
            <UIAuthorization access={markGradingCompleteAccess(auth, lot)}>
              <MarkGradingComplete lot={lot}>
                {({ markGradingComplete }) => (
                  <Button outline id="markGradingComplete" onClick={markGradingComplete}>
                    {LOTS.GRADING_FINISHED}
                  </Button>
                )}
              </MarkGradingComplete>
            </UIAuthorization>
            <UIAuthorization access={uploadGradingReceiptAccess(auth, lot)}>
              <Button onClick={this.toggleGradingReceiptModal}>
                {LOTS.UPLOAD_GRADING_RECEIPT}
              </Button>
            </UIAuthorization>
            <UIAuthorization access={auth.access.billOfLadingUpload}>
              <Button onClick={this.toggleBillOfLadingModal}>{LOTS.UPLOAD_BILL_OF_LADING}</Button>
            </UIAuthorization>
            <UIAuthorization
              access={
                packagesList?.incoming?.docs?.length &&
                acceptLotForProcessingAccess(auth, lot, packagesList)
              }
            >
              <AcceptForProcessing lot={lot}>
                {({ acceptForProcessing }) => (
                  <Button id="acceptForProcessing" onClick={acceptForProcessing}>
                    {LOTS.ACCEPT_FOR_PROCESSING}
                  </Button>
                )}
              </AcceptForProcessing>
            </UIAuthorization>
            <UIAuthorization access={acceptLotForMixingAccess(auth, lot, packagesList)}>
              <AcceptForMixing lot={lot}>
                {({ acceptForMixing }) => (
                  <Button id="acceptForMixing" onClick={acceptForMixing}>
                    {LOTS.ACCEPT_FOR_MIXING}
                  </Button>
                )}
              </AcceptForMixing>
            </UIAuthorization>
            <UIAuthorization access={scanReceivedPackagesAccess(auth, lot)}>
              <Button id="scanReceivedPackages" onClick={this.toggleReceiveBarcodeScanner}>
                {LOTS.SCAN_RECEIVED_PACKAGES}
              </Button>
            </UIAuthorization>
            <UIAuthorization access={scanSamplePackagesAccess(auth, lot, packagesList)}>
              <Button
                id="scanReceivedPackages"
                onClick={this.toggleInactivatePackageBarcodeScanner}
              >
                {LOTS.INACTIVATE_LAB_PACKAGES}
              </Button>
            </UIAuthorization>
            <UIAuthorization access={changeProcessUponDeliveryAccess(auth, lot)}>
              <ConnectedChangeProcessUponDelivery lot={lot}>
                {({ changeProcessUponDelivery }) => (
                  <Button id="changeProcessUponDelivery" onClick={changeProcessUponDelivery}>
                    {lot.processUponDelivery
                      ? LOTS.DO_NOT_PROCESS_UPON_DELIVERY
                      : LOTS.CHANGE_PROCESS_UPON_DELIVERY}
                  </Button>
                )}
              </ConnectedChangeProcessUponDelivery>
            </UIAuthorization>
            <UIAuthorization access={removeCommodityAccess(auth, lot)}>
              <RouterButton
                id="removeCommodity"
                path={`/lots/list/${lot._id}/commodity`}
                label={LOTS.REMOVE_COMMODITY}
                locationState={{
                  grgLotNumber: lot.grgLotNumber,
                }}
              />
            </UIAuthorization>
            <UIAuthorization access={markProcessingCompleteAccess(auth, lot)}>
              <ConnectedMarkProcessingComplete lot={lot}>
                {({ markProcessingComplete }) => (
                  <Button id="markProcessingComplete" onClick={markProcessingComplete}>
                    {lot.statusForGlobal === statusesForGlobal.accepted_for_processing
                      ? LOTS.PROCESSING_COMPLETE
                      : LOTS.MIXING_COMPLETE}
                  </Button>
                )}
              </ConnectedMarkProcessingComplete>
            </UIAuthorization>
            <UIAuthorization access={enterAssayResultsAccess(auth, lot)}>
              <Button
                id="openEnterAssayResults"
                onClick={
                  !lot.stillwaterShipmentId &&
                  !isGlobalRefiningGroup(lot.sentFromCompany?.companyName) &&
                  isInternalCompany(lot.sentFromCompany?.companyType)
                    ? this.showMissingShipmentInfoInternalCompanyWarning
                    : () => this.props.history.push(`/lots/list/${lot._id}/enter-assay-results`)
                }
              >
                {LOTS.ENTER_ASSAY_MANUALLY}
              </Button>
            </UIAuthorization>
            <UIAuthorization access={auth.access.lots.update}>
              <RouterButton
                id="openUpdateLot"
                path={`/lots/list/${lot._id}/update`}
                label={LOTS.EDIT_LOT}
              />
            </UIAuthorization>
            <UIAuthorization access={updateLotLocationAccess(auth, lot)}>
              <RouterButton
                id="openUpdateLocationLot"
                path={`/lots/list/${lot._id}/update/location`}
                label={LOTS.UPDATE_LOCATION}
              />
            </UIAuthorization>
            <UIAuthorization access={changeReceivedDateAccess(auth, lot)}>
              <RouterButton
                id="openUpdateLocationLot"
                path={`/lots/list/${lot._id}/update/received-date`}
                label={LOTS.CHANGE_RECEIVED_DATE}
              />
            </UIAuthorization>
            <UIAuthorization
              access={lot.invalidShipmentSummary && auth.access.lots.retrySummaryImport}
            >
              <RetryImportLot lot={lot}>
                {({ onClick }) => (
                  <Button outline id="retryImport" onClick={onClick}>
                    {LOTS.RETRY_IMPORT}
                  </Button>
                )}
              </RetryImportLot>
            </UIAuthorization>
            <UIAuthorization access={updateShipmentInfoAccess(auth, lot)}>
              <RouterButton
                id="updateShipmentInformation"
                path={`/lots/list/${lot._id}/update/shipment-info`}
                label={LOTS.EDIT_SHIPMENT_INFO}
              />
            </UIAuthorization>
            <UIAuthorization access={!this.isAndroid && this.lotLabelPrintable()}>
              <PrintLotLabel
                path={`/lots/print-label/${lot._id}`}
                errorMessage={LOTS.PRINT_LOT_LABEL_ERROR}
              >
                {({ onClick }) => (
                  <Button outline id="printLotLabel" type="button" onClick={onClick}>
                    {LOTS.PRINT_LOT_LABEL}
                  </Button>
                )}
              </PrintLotLabel>
            </UIAuthorization>
            <UIAuthorization access={this.isAndroid && this.lotLabelPrintable()}>
              <Button outline id="printLotLabelMobile" type="button" margin={false}>
                <PrintIntent
                  intent={printLotLabelUrl(lot)}
                  fallback={`/lots/print-label/${lot._id}`}
                >
                  {LOTS.PRINT_LOT_LABEL_ZEBRA}
                </PrintIntent>
              </Button>
            </UIAuthorization>
            <UIAuthorization access={this.isAndroid && this.lotLabelPrintable()}>
              <Button outline id="printLotLabelMobile" type="button" margin={false}>
                <PrintIntent
                  intent={printLotLabelEbsUrl(lot)}
                  fallback={`/lots/print-label/${lot._id}`}
                  pkg="com.globalrefininggroup.checkinlabels.bluetoothprinterEBS"
                >
                  {LOTS.PRINT_LOT_LABEL_EBS}
                </PrintIntent>
              </Button>
            </UIAuthorization>
            <UIAuthorization access={assignedViewPrintPackageLabelsAccess(auth, lot)}>
              <Button id="printPackageLabels" onClick={this.uploadPrintLabelsModalToggle}>
                {PACKAGES.PRINT_PACKAGE_LABELS}
              </Button>
            </UIAuthorization>
            <UIAuthorization access={assignedViewPrintLabRequestFormsAccess(auth, packagesList)}>
              <Button id="printLabRequestForms" onClick={this.togglePrintLabRequestFormsModal}>
                {PACKAGES.PRINT_LAB_REQUEST_FORMS}
              </Button>
            </UIAuthorization>
            <UIAuthorization access={auth.access.lots.timeline}>
              <RouterButton
                path={`/lots/list/${lot._id}/timeline`}
                label={LOTS.VIEW_TIMELINE}
                replace
              />
            </UIAuthorization>
            <UIAuthorization access={finalizeLotAccess(auth, lot)}>
              <FinalizeLot lot={lot}>
                {({ finalizeLot }) => (
                  <Button id="finalizeLot" onClick={finalizeLot}>
                    {LOTS.SET_TO_FINALIZED}
                  </Button>
                )}
              </FinalizeLot>
            </UIAuthorization>
            <UIAuthorization access={specialPermissionSetFinalizedLotAccess(auth, lot)}>
              <FinalizeLot lot={lot} superAdminMode>
                {({ finalizeLot }) => (
                  <Button id="setFinalizedLotSuperAdminMode" color="danger" onClick={finalizeLot}>
                    {LOTS.ADMIN_SET_TO_FINALIZED}
                  </Button>
                )}
              </FinalizeLot>
            </UIAuthorization>
            <UIAuthorization access={deleteLotAccess(auth.access.lots.delete, lot)}>
              <DeleteLot lot={lot} routeChange={this.navigateToLotsOverview}>
                {({ deleteLot }) => (
                  <Button id="deleteLot" color="danger" onClick={deleteLot}>
                    {LOTS.DELETE_LOT}
                  </Button>
                )}
              </DeleteLot>
            </UIAuthorization>
          </ExtendableButton>
        </DetailsHeader>
        <ReceiveBarcodeScanner
          open={this.state.showReceiveBarcodeScanner}
          onSelfClose={this.toggleReceiveBarcodeScanner}
          lot={this.props.lot}
        />
        <InactivatePackageBarcodeScanner
          open={this.state.showInactivatePackageBarcodeScanner}
          onSelfClose={this.toggleInactivatePackageBarcodeScanner}
          lot={this.props.lot}
          incomingPackages={packagesList?.incoming?.docs}
        />
        <ModalCustom isOpen={displayPrintLabelsModal} modalClose={this.toggleModal}>
          {displayPrintLabelsModal && <PrintPackageLabels />}
        </ModalCustom>
        <UploadGradingReceipt
          toggle={this.toggleGradingReceiptModal}
          open={this.state.displayGradingReceiptModal}
          onSuccess={this.getLotOnUploadSuccess}
          lot={lot}
        />
        <UploadBillOfLading
          toggle={this.toggleBillOfLadingModal}
          open={this.state.displayBillOfLadingModal}
          onSuccess={this.getLotOnUploadSuccess}
          lot={lot}
        />
        <GenerateShipmentSummary
          lot={lot}
          open={this.state.displayGenerateShipmentSummaryModal}
          modalClose={this.toggleGenerateShipmentSummaryModal}
        />
        <PrintLabRequestForms
          open={this.state.displayPrintLabRequestFormsModal}
          modalClose={this.togglePrintLabRequestFormsModal}
        />
      </>
    );
  }
}

const mapStateToProps = ({ maximumSettlementOunces }) => ({
  maximumSettlementOunces,
});

const mapDispatchToProps = {
  getMaximumSettlementOunces,
  clearMaximumSettlementOunces,
  showSnackbar,
  hideSnackbar,
  getLot,
};

MainLotDetailsBase.propTypes = {
  auth: PropTypes.object,
  lot: PropTypes.object,
  packagesList: PropTypes.object,
  globalDetails: PropTypes.bool,
  settlements: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  shipments: PropTypes.array,
  shipmentsIsPending: PropTypes.bool,
  lotNotesCount: PropTypes.number,
  hedges: PropTypes.object,
};

const MainLotDetails = withRouter(connect(mapStateToProps, mapDispatchToProps)(MainLotDetailsBase));

export { MainLotDetailsBase, MainLotDetails };
