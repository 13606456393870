import React, { useEffect, useCallback, useContext } from 'react';

import { ConditionalWrapper } from 'shared/components/ConditionalWrapper/ConditionalWrapper';
import { FormContainer } from 'shared/components/Form/FormContainer/FormContainer';
import { Preloader } from 'shared/components/Preloader/Preloader';
import { wizardContext } from 'shared/components/Wizard/Wizard';
import { SHIPMENTS } from 'shared/helpers/constants/shipments/shipmentsConstants';
import { snackbarOptions } from 'shared/helpers/matchers/snackbar/snackbarOptions';

import { configNames } from 'pages/Packages/components/PackagesManagement/components/PackageForm/components/PackageForm/fieldsConfig';

import { IncomingShipmentForm } from '../IncomingShipmentForm/IncomingShipmentForm';

const CreateIncomingShipment = props => {
  const {
    createIncomingShipment,
    clearAllPackagesOverview,
    shipmentPackagesListData,
    isPending,
    showSnackbar,
    history,
    location,
    destroy,
  } = props;

  const wizard = useContext(wizardContext);
  const isPackingList = wizard?.config === configNames.packingList;

  useEffect(
    () => () => {
      clearAllPackagesOverview();
    },
    [clearAllPackagesOverview],
  );

  const resolveAction = useCallback(
    result => {
      showSnackbar(snackbarOptions(result.messageType), result.message);
      if (result.id) {
        history.push(`/shipments/${result.id}`);
      }
    },
    [history, showSnackbar],
  );

  const submit = useCallback(
    values => {
      const createObj = {
        ...values,
        packages: shipmentPackagesListData.packages,
      };

      return createIncomingShipment(createObj)
        .then(resolveAction)
        .then(destroy('IncomingShipmentForm'));
    },
    [shipmentPackagesListData, createIncomingShipment, resolveAction],
  );

  return (
    <ConditionalWrapper
      condition={!isPackingList}
      wrapper={children => (
        <>
          <FormContainer header={SHIPMENTS.CREATE_INCOMING_SHIPMENT}>{children}</FormContainer>
          <Preloader loading={isPending} />
        </>
      )}
    >
      <IncomingShipmentForm
        {...props}
        isPackingList={isPackingList}
        {...(!isPackingList && {
          onSubmit: submit,
        })}
        initialValues={{
          ...(!isPackingList || wizard?.editMode
            ? {
                initialValues: {
                  packages: [
                    ...(location.state?.packages || []),
                    ...(shipmentPackagesListData?.packages || []),
                  ],
                },
              }
            : {
                ...props.initialValues,
              }),
        }}
      />
    </ConditionalWrapper>
  );
};

export { CreateIncomingShipment };
