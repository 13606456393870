import {
  updateUserActionType,
  updateUserFailureActionType,
  updateUserSuccessActionType,
} from 'actions/Users/updateUser';

const INITIAL_STATE = {
  isPending: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case updateUserActionType:
      return {
        isPending: true,
      };
    case updateUserSuccessActionType:
      return {
        isPending: false,
      };

    case updateUserFailureActionType:
      return {
        isPending: false,
      };
    default:
      return state;
  }
};
