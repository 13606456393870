const termsBreadcrumbsRoutes = {
  '/terms': null,
  '/terms/:companyId': 'Terms',
  '/terms/:companyId/term': null,
  '/terms/:companyId/term/:termId': 'Term details',
  '/terms/:companyId/download': null,
  '/terms/:companyId/download/:termId': null,
  '/terms/:companyId/download/:termId/:fileName': null,
};

export { termsBreadcrumbsRoutes };
