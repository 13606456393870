import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { FormContainer } from 'shared/components/Form';
import { ModalEditMorePackages } from 'shared/components/ModalEditMorePackages';
import { Preloader } from 'shared/components/Preloader/Preloader';
import { normalizePackageObject } from 'shared/helpers/constants/packages/normalizePackage';
import { PACKAGES } from 'shared/helpers/constants/packages/packageConstants';
import { shipmentStatuses } from 'shared/helpers/constants/shipments/shipmentStatuses';
import { parsePkgToCompare } from 'shared/helpers/dataParsers/packages/parsePkgToCompare';
import { isPkgEdited } from 'shared/helpers/matchers/packages/getExistingPackages';
import { snackbarOptions } from 'shared/helpers/matchers/snackbar/snackbarOptions';

import { relativeNavigator } from 'utils/relativeNavigator';

import { CustomerCreatePackage } from 'pages/Packages/components/PackagesManagement/components/PackageForm/CustomerCreatePackage';

class UpdatePackage extends Component {
  state = {
    isModalOpen: false,
    valuesToSend: null,
  };

  handleClickEsc = event => {
    const { isModalOpen } = this.state;

    if (event.keyCode === 27 && isModalOpen) {
      event.stopPropagation();
      this.setState({
        isModalOpen: false,
      });
    }
  };

  componentDidMount() {
    const { relatedCompany } = this.props.auth.user;
    const { getPackage, getCompanyLocations, saveTemporaryPackageData } = this.props;

    saveTemporaryPackageData({ editPackageMode: true });

    getPackage(this.props.match.params.id).catch(() => this.props.history.push('/shipments'));
    getCompanyLocations(relatedCompany._id);

    document.addEventListener('keydown', this.handleClickEsc, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleClickEsc, false);
  }

  handleClickEditMorePackages = () => {
    const { shipmentId, id: packageId } = this.props.match.params;
    const { saveTemporaryPackageData } = this.props;
    const {
      packageDetails: { package: pkg },
    } = this.props;
    const { valuesToSend } = this.state;
    const isEdited = !isPkgEdited(parsePkgToCompare(pkg), parsePkgToCompare(valuesToSend));

    this.props.history.push(
      `/shipments/${shipmentId || pkg.assignedShipment._id}/multipackages/${packageId}/update`,
    );
    saveTemporaryPackageData({
      editedPackage: {
        ...valuesToSend,
        edited: isEdited,
      },
      editMultipackagesMode: true,
      editPackageMode: false,
    });
  };

  handleToggleModal = () => {
    this.setState(prevState => ({
      isModalOpen: !prevState.isModalOpen,
    }));
  };

  handleSendDataToAPI = valuesToSend => {
    const {
      updatePackageAction,
      updatePackageMaterialCount,
      packageDetails: {
        package: {
          assignedLot: { sentFromCompany },
        },
      },
    } = this.props;

    const pkgAction = sentFromCompany.materialCountRequired
      ? updatePackageMaterialCount
      : updatePackageAction;

    return pkgAction(valuesToSend).then(this.resolveAction, this.resolveAction);
  };

  handleClickUpdateOnePackage = () => {
    const { valuesToSend } = this.state;
    const { id } = this.props.packageDetails.package.assignedLot;

    this.handleSendDataToAPI({ ...valuesToSend, assignedLot: { _id: id } });
  };

  navigation = [
    {
      from: /^\/lots\/list\/\w+\/packages\/\w+\/update\/?$/,
      to: ({ lotId, packageId }) => `/lots/list/${lotId}/packages/${packageId}`,
      tryHistory: true,
    },
    {
      from: /^\/shipments\/\w+\/packages\/\w+\/update\/?$/,
      to: ({ shipmentId, packageId }) => `/shipments/${shipmentId}/packages/${packageId}`,
      tryHistory: true,
    },
  ];

  navigator = relativeNavigator(
    this.navigation,
    ({ packageId }) => `/shipments/packages/${packageId}`,
  )();

  navigate = packageId =>
    this.navigator(this.props.location.pathname, { ...this.props.match.params, packageId });

  submit = values => {
    const {
      package: pkg,
      package: { assignedShipment },
    } = this.props.packageDetails;
    const { materialCountRequired } = this.props.auth.user.relatedCompany;
    const { multiple: updateMultipleIncoming } = this.props.auth.access.packages.updateIncoming;
    const { weightGrossActual, weightTareActual, packageClass, ...valuesToSend } = values;
    valuesToSend.materialCountRequired = materialCountRequired;

    if (
      pkg.openIncomingPkgCount > 1 &&
      assignedShipment.status === shipmentStatuses.open &&
      materialCountRequired &&
      updateMultipleIncoming
    ) {
      this.setState({
        valuesToSend: {
          ...valuesToSend,
          plant: valuesToSend?.plant?.toUpperCase(),
          labelId: valuesToSend?.labelId?.toUpperCase(),
        },
      });

      return this.handleToggleModal();
    }

    return this.handleSendDataToAPI({ ...valuesToSend, assignedLot: { _id: pkg.assignedLot.id } });
  };

  resolveAction = result => {
    this.props.showSnackbar(snackbarOptions(result.messageType), result.message);
    this.navigate(result.id);
  };

  render() {
    const { package: pkg, isPending } = this.props.packageDetails;
    const { isPending: updatePackageIsPending } = this.props.updatePackage;

    return (
      <>
        <FormContainer header={PACKAGES.UPDATE_PACKAGE} loaded={pkg && !isPending}>
          {() => (
            <CustomerCreatePackage
              {...this.props}
              pkg={pkg}
              onSubmit={this.submit}
              initialValues={normalizePackageObject(pkg)}
              editMode
            />
          )}
        </FormContainer>
        <ModalEditMorePackages
          handleToggleModal={this.handleToggleModal}
          handleClickEditMorePackages={this.handleClickEditMorePackages}
          handleClickUpdateOnePackage={this.handleClickUpdateOnePackage}
          isModalOpen={this.state.isModalOpen}
          isPending={updatePackageIsPending}
        />
        <Preloader loading={updatePackageIsPending} />
      </>
    );
  }
}

UpdatePackage.propTypes = {
  assignedData: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.object),
    isPending: PropTypes.bool,
  }),
  auth: PropTypes.shape({
    attempts: PropTypes.number,
    user: PropTypes.object,
    access: PropTypes.object,
    isPending: PropTypes.bool,
  }),
  closeModal: PropTypes.func,
  companyLocations: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.object),
    isPending: PropTypes.bool,
  }),
  getAssignedLots: PropTypes.func,
  getCompanyLocations: PropTypes.func,
  getPackage: PropTypes.func,
  history: PropTypes.object,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
    state: PropTypes.object,
    key: PropTypes.string,
  }),
  match: PropTypes.shape({
    path: PropTypes.string,
    url: PropTypes.string,
    isExact: PropTypes.bool,
    params: PropTypes.object,
  }),
  modal: PropTypes.shape({
    modalOpened: PropTypes.bool,
    modalCallback: PropTypes.oneOf([PropTypes.func, null]),
    modalOptions: PropTypes.object,
  }),
  openModal: PropTypes.func,
  packageDetails: PropTypes.shape({
    isPending: PropTypes.bool,
    errorMessage: PropTypes.oneOf([PropTypes.object, null]),
    package: PropTypes.object,
    recentlyCreated: PropTypes.bool,
  }),
  saveTemporaryPackageData: PropTypes.func,
  showSnackbar: PropTypes.func,
  updatePackage: PropTypes.shape({
    isPending: PropTypes.bool,
  }),
  updatePackageAction: PropTypes.func,
  updatePackageMaterialCount: PropTypes.func,
};

export { UpdatePackage };
