import React, { Component } from 'react';

import { Button } from 'shared/components/Buttons/Button';
import { DetailsActivityField } from 'shared/components/Details/DetailsActivityField';
import { Notification } from 'shared/components/Notification';
import { NOTIFICATION } from 'shared/helpers/constants/notifications/notificationConstants';
import {
  dangerOptions,
  successOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { isCustomerOrGrading } from 'shared/helpers/matchers/checkUserType';
import { dateWithTimeLuxonFormat } from 'shared/helpers/parsers/date';

class NotificationDialogBase extends Component {
  state = { pendingDetails: false };

  componentDidMount() {
    const notificationId = new URLSearchParams(window.location.search).get('notification');

    if (notificationId) {
      this.props.getNotification(notificationId).then(this.markNotificationAsRead);
    }

    this.pendingDetails();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.lotDetails.isPending !== this.props.lotDetails.isPending ||
      prevProps.paymentDetails.isPending !== this.props.paymentDetails.isPending ||
      prevProps.termsDetails.isPending !== this.props.termsDetails.isPending ||
      prevProps.hedgesDetails.isPending !== this.props.hedgesDetails.isPending ||
      prevProps.selfDeliveryRequestDetails.isPending !==
        this.props.selfDeliveryRequestDetails.isPending
    ) {
      this.pendingDetails();
    }
  }

  markNotificationAsRead = notification => {
    !notification.alreadyRead && this.props.markAsRead(notification._id);
  };

  onCancel = async () => {
    await this.props.clearNotificationDetails();
    window.scrollTo(0, 0);
  };

  markAsResolved = async () => {
    const {
      notificationDetails: { notification, redirectedFrom },
      history,
    } = this.props;

    try {
      const message = await this.props.markAsResolved(notification._id);
      await this.props.clearNotificationDetails();
      this.props.showSnackbar(successOptions, message);
      history.push(redirectedFrom ? `/${redirectedFrom}` : '/');
    } catch (error) {
      this.props.showSnackbar(dangerOptions, error.message);
    }
  };

  pendingDetails = () => {
    const { lotDetails, paymentDetails, termsDetails, hedgesDetails, selfDeliveryRequestDetails } =
      this.props;

    return this.setState({
      pendingDetails:
        lotDetails.isPending ||
        paymentDetails.isPending ||
        termsDetails.isPending ||
        hedgesDetails.isPending ||
        selfDeliveryRequestDetails.isPending,
    });
  };

  actions = isResolving => {
    const {
      auth,
      notificationDetails: { notification },
    } = this.props;

    if (
      !notification.resolvedBy &&
      !isCustomerOrGrading(auth.user.userType) &&
      !(notification.actionType && this.state.pendingDetails)
    ) {
      return (
        <Button id="markAsResolved" onClick={this.markAsResolved} disabled={isResolving}>
          {NOTIFICATION.MARK_AS_RESOLVED}
        </Button>
      );
    }

    if (notification.resolvedBy && !isCustomerOrGrading(auth.user.userType)) {
      return (
        <DetailsActivityField
          id="resolvedBy"
          label={NOTIFICATION.RESOLVED_BY}
          user={notification.resolvedBy}
          date={notification.resolvedAt}
          dateFormat={dateWithTimeLuxonFormat}
          backgroundColor="transparent"
        />
      );
    }
  };

  render() {
    const {
      notificationDetails: { notification },
      markAsResolvedState: { isPending },
    } = this.props;

    return (
      notification && (
        <Notification
          notification={notification}
          actions={!this.state.pendingDetails && this.actions}
          onCancel={this.onCancel}
          isResolving={isPending}
        />
      )
    );
  }
}

export { NotificationDialogBase };
