import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const finalizeLotType = 'FINALIZE_LOT';
const finalizeLotAction = () => ({
  type: finalizeLotType,
});

const finalizeLotSuccessType = `${finalizeLotType}_SUCCESS`;
const finalizeLotSuccessAction = lot => ({
  type: finalizeLotSuccessType,
  payload: {
    ...lot,
  },
});

const finalizeLotFailureType = `${finalizeLotType}_FAILURE`;
const finalizeLotFailureAction = () => ({
  type: finalizeLotFailureType,
});

const finalizeLot =
  (lotId, superAdminMode = false) =>
  dispatch => {
    dispatch(finalizeLotAction());

    return axios
      .patch(`${API_HOST_AUTH}/lots/${lotId}/finalize`, { superAdminMode })
      .then(res => {
        dispatch(finalizeLotSuccessAction(res.data.lot));

        return res.data;
      })
      .catch(err => {
        dispatch(finalizeLotFailureAction());

        return Promise.reject(err.data);
      });
  };

export { finalizeLotType, finalizeLotSuccessType, finalizeLotFailureType, finalizeLot };
