import styled from 'styled-components';

const Dot = styled.div`
  grid-area: dot;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background-color: ${({ color = 'gray' }) => color};
`;

export { Dot };
