import axios from 'axios';

import { COMPANY } from 'shared/helpers/constants/companies/companyConstants';

const API_HOST_AUTH = process.env.REACT_APP_API;

const inactivateCompanyActionType = 'INACTIVATE_COMPANY';
const inactivateCompanyAction = () => ({
  type: inactivateCompanyActionType,
});

const inactivateCompanySuccessActionType = `${inactivateCompanyActionType}_SUCCESS`;
const inactivateCompanySuccessAction = ({ company }) => ({
  type: inactivateCompanySuccessActionType,
  payload: {
    company,
  },
});

const inactivateCompanyFailureActionType = `${inactivateCompanyActionType}_FAILURE`;
const inactivateCompanyFailureAction = () => ({
  type: inactivateCompanyFailureActionType,
});

const inactivateCompany = id => dispatch => {
  dispatch(inactivateCompanyAction());

  return axios
    .patch(`${API_HOST_AUTH}/companies/inactivate/${id}`)
    .then(response => {
      dispatch(inactivateCompanySuccessAction(response.data));

      return { message: response.data.message };
    })
    .catch(() => {
      dispatch(inactivateCompanyFailureAction());

      return Promise.reject({ message: COMPANY.INACTIVATE_COMPANY_ERROR });
    });
};

export {
  inactivateCompanyActionType,
  inactivateCompanyAction,
  inactivateCompanySuccessActionType,
  inactivateCompanySuccessAction,
  inactivateCompanyFailureActionType,
  inactivateCompanyFailureAction,
  inactivateCompany,
};
