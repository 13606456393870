import React, { Fragment, useState, useMemo } from 'react';
import styled from 'styled-components';

import { StatusButton } from 'shared/components/Buttons/StatusButton/StatusButton';
import { Icon } from 'shared/components/Icon/Icon';
import { cssVar, pxToRem, media } from 'shared/helpers/styling/styling';

import { flatten } from 'utils/flatten';

import { SectionSummaryModal } from '../SectionSummaryModal/SectionSummaryModal';

const Header = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  color: ${cssVar('whiteGRG')};
  min-height: 50px;
  margin: 0 2px 20px;
  font-size: ${pxToRem(14)};
`;

const Separator = styled.div`
  height: 2px;
  width: 100%;
  background-color: ${cssVar('silverSand')};
  opacity: 0.8;
  margin: 21px 0;
`;

function Part(props) {
  const { part, data, className, actionsAuth } = props;
  const [modalOpened, setModalOpened] = useState(false);

  const color = Array.prototype.concat(part.color);
  const plural = Array.prototype.concat(part.plural);
  const empty = Array.prototype.concat(part.empty);
  const icon = Array.prototype.concat(part.icon);
  const parsedPart = {
    ...part,
    color,
    icon,
  };
  const count = useMemo(() => (data ? flatten(data.map(([, key]) => key)).length : []), [data]);

  const toggleModal = () => setModalOpened(open => !open);

  return (
    <div className={className}>
      <Header>
        {!!part.headerIcon && <Icon icon={part.headerIcon} width={50} height={50} />}
        {part.header}
        {!!count && ` (${count})`}
      </Header>
      {data &&
        data.map(([key, row], index) => (
          <Fragment key={key}>
            {!!index && <Separator />}
            <StatusButton
              color={color[index] || color[0]}
              plural={plural[index] || plural[0]}
              empty={empty[index] || empty[0]}
              icon={icon[index] || icon[0]}
              data={row.map(part.map)}
              onClick={toggleModal}
            />
          </Fragment>
        ))}
      <SectionSummaryModal
        part={parsedPart}
        isOpen={modalOpened}
        toggle={toggleModal}
        data={data}
        actionsAuth={actionsAuth}
      />
    </div>
  );
}

const PartStyled = styled(Part)`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 285px;
  background-color: ${cssVar('limedSpruceCalendar')};
  padding: 20px 40px;

  ${media.smallDesktop`
    padding: 20px;
  `};

  ${media.mobile`
    min-height: auto;
  `}
`;

export { PartStyled as Part };
