import axios from 'axios';

import { SHIPMENTS } from 'shared/helpers/constants/shipments/shipmentsConstants';
import { messageTypes } from 'shared/helpers/constants/snackbar/snackbarMessageTypes';
import {
  parseShipmentData,
  parseIncomingShipmentData,
  parseOutgoingShipmentData,
  parseShipmentPackingListData,
  parsePackingListResponseData,
} from 'shared/helpers/dataParsers/shipments';

const API_HOST_AUTH = process.env.REACT_APP_API;

const createShipmentActionType = 'CREATE_SHIPMENT';
const createShipmentAction = () => ({
  type: createShipmentActionType,
});

const createShipmentSuccessActionType = `${createShipmentActionType}_SUCCESS`;
const createShipmentSuccessAction = () => ({
  type: createShipmentSuccessActionType,
});

const createShipmentFailureActionType = `${createShipmentActionType}_FAILURE`;
const createShipmentFailureAction = () => ({
  type: createShipmentFailureActionType,
});

const createShipmentFactory = (url, parseResponseData) => parsedShipmentData => dispatch => {
  dispatch(createShipmentAction());

  return axios({
    method: 'post',
    url,
    data: JSON.stringify(parsedShipmentData),
  })
    .then(res => {
      dispatch(createShipmentSuccessAction());
      return {
        id: res.data.id,
        message: res.data.message,
        messageType: messageTypes.success,
        shipmentPackages: res.data.shipmentPackages,
        ...((parseResponseData && parseResponseData(res.data)) || {}),
      };
    })
    .catch(error => {
      dispatch(createShipmentFailureAction());

      return {
        message: `${SHIPMENTS.ERROR.CREATE_SHIPMENT_ERROR} ${error.data.message}`,
        messageType: messageTypes.error,
      };
    });
};

const createShipment = shipment =>
  createShipmentFactory(`${API_HOST_AUTH}/shipments`)(parseShipmentData(shipment));
const createIncomingShipment = shipment =>
  createShipmentFactory(`${API_HOST_AUTH}/shipments/incoming`)(parseIncomingShipmentData(shipment));
const createOutgoingShipment = shipment =>
  createShipmentFactory(`${API_HOST_AUTH}/shipments/outgoing`)(parseOutgoingShipmentData(shipment));
const createShipmentPackingList = (shipment, userType, chosenPackages) =>
  createShipmentFactory(
    `${API_HOST_AUTH}/shipments/incoming-packing-list`,
    parsePackingListResponseData,
  )(parseShipmentPackingListData(shipment, userType, chosenPackages));

export {
  createShipmentActionType,
  createShipmentAction,
  createShipmentSuccessActionType,
  createShipmentSuccessAction,
  createShipmentFailureActionType,
  createShipmentFailureAction,
  createShipment,
  createIncomingShipment,
  createOutgoingShipment,
  createShipmentPackingList,
};
