import { roles } from 'shared/helpers/constants/filters/userTypes';
import { userHierarchy } from 'shared/helpers/accesses/config/createAccessLevels';
import { messages as locationMessages } from 'shared/helpers/constants/locations/createLocationConstants';
import { message as userMessages } from 'shared/helpers/constants/users/createUserConstants';

function validate(values, index, props) {
  const { locations, users } = props.values;

  const primaryContactsErrors = values.primaryContacts.map(primaryContact => {
    const locationValue = locations.find(({ lotSuffix }) => lotSuffix === primaryContact.lotSuffix);
    const shipmentEligible = locationValue && locationValue.shipmentEligible;

    if (shipmentEligible && !(primaryContact.email && primaryContact.email.length)) {
      return {
        email: locationMessages.INVALID_PRIMARY_CONTACT,
      };
    }

    const emails = (primaryContact.email || []).map(({ value }) => value);
    const selectedUsers = users.filter(({ email }) => emails.includes(email));

    const getDefaultAccessLevel = user =>
      user?.accessLevel?.value ?? userHierarchy.accessLevel[user.userType.value][0].value;

    if (
      selectedUsers &&
      selectedUsers.some(({ userType: { value } }) => value === roles.customer.name) &&
      selectedUsers.some(
        user =>
          ![roles.customer.full_hedging, roles.customer.limited_hedging].includes(
            getDefaultAccessLevel(user),
          ),
      )
    ) {
      return {
        email: locationMessages.INVALID_PRIMARY_CONTACT_NOT_ALLOWED,
      };
    }

    return null;
  });

  const locationsErrors = values.locations.map(location =>
    !location.lotSuffix || !location.lotSuffix.length
      ? {
          lotSuffix: userMessages.AT_LEAST_ONE_LOCATION,
        }
      : null,
  );

  return {
    primaryContacts: primaryContactsErrors,
    locations: locationsErrors,
  };
}

export { validate };
