import React, { Component } from 'react';

import { ListCollapse } from 'shared/components/ListCollapse/ListCollapse';
import {
  OverviewListSectionWrapper,
  OverviewListSection,
} from 'shared/components/OverviewList/OverviewListSection/OverviewListSection';
import { parseSectionName } from 'shared/helpers/matchers/lots/checkLotsSection';

class CustomerLotsSection extends Component {
  state = {
    displaySection: true,
  };

  sectionToggle = () => {
    const { section, lotsSections } = this.props;
    lotsSections.lotsCount &&
      lotsSections.lotsCount[section][section].length !== 0 &&
      this.setState(prevState => ({ displaySection: !prevState.displaySection }));
  };

  render() {
    const { children, section, lotsSections } = this.props;
    const title = parseSectionName(section);
    const total =
      lotsSections.lotsCount[section] && lotsSections.lotsCount[section][section].length;
    const label = Number.isFinite(total) ? `${title} (${total})` : title;

    return (
      <OverviewListSectionWrapper isOpen={this.state.displaySection} total={total}>
        <OverviewListSection isOpen={this.state.displaySection} total={total}>
          <div>
            <ListCollapse
              collapse={this.state.displaySection}
              toggleList={this.sectionToggle}
              label={label}
            />
          </div>
        </OverviewListSection>
        <div>{children}</div>
      </OverviewListSectionWrapper>
    );
  }
}

export { CustomerLotsSection };
