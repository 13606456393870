const modalOpened = 'MODAL_OPENED';

function modalOpenedAction(modalOptions, modalCallback, closeCallback, customActionCallback) {
  return {
    type: modalOpened,
    modalOptions,
    modalCallback,
    closeCallback,
    customActionCallback,
  };
}

const modalClosed = 'MODAL_CLOSED';

function modalClosedAction() {
  return {
    type: modalClosed,
  };
}

function openModal(modalOptions, modalCallback, closeCallback, customActionCallback) {
  return dispatch =>
    new Promise(resolve =>
      dispatch(
        modalOpenedAction(
          modalOptions,
          (...args) => {
            modalCallback && modalCallback(...args);
            resolve(true);
          },
          (...args) => {
            closeCallback && closeCallback(...args);
            resolve(false);
          },
          (...args) => {
            customActionCallback && customActionCallback(...args);
            resolve(false);
          },
        ),
      ),
    );
}

function closeModal() {
  return dispatch => {
    dispatch(modalClosedAction());
  };
}

export { modalOpened, modalClosed, openModal, closeModal };
