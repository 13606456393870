import {
  getRelatedSamplePackagesActionType,
  getRelatedSamplePackagesSuccessActionType,
  getRelatedSamplePackagesFailureActionType,
  clearRelatedSamplePackagesActionType,
} from 'actions/Packages/getRelatedSamplePackages';

const INITIAL_STATE = {
  isPending: false,
  packages: [],
};

const RelatedSamplePackagesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getRelatedSamplePackagesActionType:
      return {
        ...state,
        isPending: true,
      };
    case getRelatedSamplePackagesSuccessActionType:
      return {
        packages: action.payload.packages,
        isPending: false,
      };
    case getRelatedSamplePackagesFailureActionType:
      return {
        ...state,
        isPending: false,
      };
    case clearRelatedSamplePackagesActionType:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};

export { RelatedSamplePackagesReducer };
