import { message } from 'shared/helpers/constants/hedges/validateHedgesConstants';
import { replaceEmpty } from 'shared/helpers/parsers/text';
import { FLOAT_REGEX_GEN } from 'shared/helpers/validations/regularExpressions';

function validate(values) {
  const error = {};

  const palladiumOz = replaceEmpty(values.palladiumOz, '');
  if (replaceEmpty(palladiumOz, '').length === 0) {
    error.palladiumOz = message.PALLADIUM_OZ_EMPTY;
  } else if (palladiumOz.length > 10) {
    error.palladiumOz = message.PALLADIUM_OZ_FORMAT;
  } else if (+palladiumOz.replace(',', '.') < 1 && +palladiumOz.replace(',', '.') !== 0) {
    error.palladiumOz = message.PALLADIUM_OZ_ZERO;
  } else if (!FLOAT_REGEX_GEN(3).test(palladiumOz)) {
    error.palladiumOz = message.PALLADIUM_OZ_FORMAT;
  }

  const platinumOz = replaceEmpty(values.platinumOz, '');
  if (replaceEmpty(platinumOz, '').length === 0) {
    error.platinumOz = message.PLATINUM_OZ_EMPTY;
  } else if (platinumOz.length > 10) {
    error.platinumOz = message.PLATINUM_OZ_FORMAT;
  } else if (+platinumOz.replace(',', '.') < 1 && +platinumOz.replace(',', '.') !== 0) {
    error.platinumOz = message.PLATINUM_OZ_ZERO;
  } else if (!FLOAT_REGEX_GEN(3).test(platinumOz)) {
    error.platinumOz = message.PLATINUM_OZ_FORMAT;
  }

  const rhodiumOz = replaceEmpty(values.rhodiumOz, '');
  if (replaceEmpty(rhodiumOz, '').length === 0) {
    error.rhodiumOz = message.RHODIUM_OZ_EMPTY;
  } else if (rhodiumOz.length > 10) {
    error.rhodiumOz = message.RHODIUM_OZ_FORMAT;
  } else if (+rhodiumOz.replace(',', '.') < 1 && +rhodiumOz.replace(',', '.') !== 0) {
    error.rhodiumOz = message.RHODIUM_OZ_ZERO;
  } else if (!FLOAT_REGEX_GEN(3).test(rhodiumOz)) {
    error.rhodiumOz = message.RHODIUM_OZ_FORMAT;
  }

  if (
    platinumOz &&
    palladiumOz &&
    rhodiumOz &&
    +platinumOz === 0 &&
    +palladiumOz === 0 &&
    +rhodiumOz === 0
  ) {
    error.platinumOz = message.ALL_OUNCES_ZERO;
    error.palladiumOz = message.ALL_OUNCES_ZERO;
    error.rhodiumOz = message.ALL_OUNCES_ZERO;
  }

  return error;
}

export { validate };
