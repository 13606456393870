import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { reduxForm, formValueSelector } from 'redux-form';

import { getBankHolidays, clearBankHolidays } from 'actions/Payments/bankHolidaysList';
import {
  checkSubmittedPoolPayments,
  clearSubmittedPoolPayments,
} from 'actions/PoolAccounts/checkSubmittedPoolPayments';
import {
  getMaximumSettlementOunces,
  clearMaximumSettlementOunces,
} from 'actions/PoolAccounts/getMaximumSettlementOunces';
import { closeModal, openModal } from 'actions/shared/modal';
import { showSnackbar, hideSnackbar } from 'actions/shared/snackbar';

import { validate } from 'shared/helpers/validations/poolAccounts/requestSettlementValidation';

import { RequestSettlementFormLayout } from './RequestSettlementForm';

const selector = formValueSelector('RequestSettlementForm');

const mapStateToProps = state => ({
  auth: state.auth,
  maximumSettlementOunces: state.maximumSettlementOunces,
  relatedCompany: selector(state, 'relatedCompany'),
  requestPayment: selector(state, 'requestPayment'),
  relatedLot: selector(state, 'relatedLot'),
  bankHolidaysList: state.bankHolidaysList,
});

const mapDispatchToProps = {
  closeModal,
  openModal,
  getMaximumSettlementOunces,
  clearMaximumSettlementOunces,
  checkSubmittedPoolPayments,
  clearSubmittedPoolPayments,
  showSnackbar,
  hideSnackbar,
  getBankHolidays,
  clearBankHolidays,
};

const RequestSettlementForm = reduxForm({
  form: 'RequestSettlementForm',
  initialValues: {
    requestPayment: false,
  },
  validate,
})(RequestSettlementFormLayout);

const ConnectedRequestSettlementForm = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RequestSettlementForm),
);

export { ConnectedRequestSettlementForm };
