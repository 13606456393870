import { globalRefiningGroupName } from './companies/globalRefiningGroup';
import { companyInformation } from './versioning/companyInformation';

export const defaultModal = {
  message: '',
  acceptButton: 'Yes',
  acceptIcon: 'icon-tick',
  acceptColor: 'acceptColor',
  cancelButton: 'Cancel',
  cancelIcon: 'icon-cancel',
  cancelColor: 'roman',
  customActionColor: 'pelorous',
  cancelButtonIsAvailable: true,
};

export const logoutModal = {
  message: 'Logout',
  messageText: 'Would you like to logout?',
  acceptButton: 'Logout',
  cancelButton: 'Cancel',
};

export const cancelModal = {
  message: 'Cancel',
  messageText:
    'Are you sure you would like to cancel? If you cancel any changes you made will be lost.',
};

export const requestUpdatedMarketIndicatorsModal = {
  message: 'Market indicators',
  messageText:
    'Market indicators have not been updated in the last hour. Are you sure you want to generate invoice mock-up?',
};

export const inactivateLocationModal = {
  message: 'Inactivate',
  messageText:
    'Inactivate this location? After inactivation you will not be able to create new lots, shipments, or users for this location.',
};

export const reactivateLocationModal = {
  message: 'Reactivate',
  messageText: 'Reactivate this location?',
};

export const deleteUserModal = {
  message: 'Delete',
  messageText:
    'Are you sure you want to delete the specified user? This will irreversibly remove the user from the system.',
};

export const inactivateUserModal = {
  message: 'Inactivate',
  messageText: 'Are you sure you want to inactivate the specified user?',
};

export const inactivateCompanyModal = {
  message: 'Inactivate',
  messageText: `Are you sure that you want to inactivate this company? This will
                also inactivate all terms/agreements, and users from this company
                will no longer have access to the ${companyInformation.representativeName} Portal.`,
};

export const reactivateCompanyModal = {
  message: 'Reactivate',
  messageText: `Reactivating the company will result in all associated users also being reactivated.
                Do you wish to proceed?`,
};

export const deleteLotModal = {
  message: 'Delete',
  messageText: 'Are you sure you want to permanently delete this lot?',
};

export const deletePackageModal = (materialCountRequired = false, hasAccess = false) => ({
  message: 'Delete',
  messageText: `Are you sure you want to permanently delete this package?
  ${
    materialCountRequired && hasAccess
      ? ` Please remember that you need to print new packing list after deleting a package.`
      : ``
  }`,
  acceptButton: `${materialCountRequired && hasAccess ? 'Confirm' : 'Yes'}`,
});

export const deleteShipmentModal = {
  message: 'Delete',
  messageText: 'Delete this shipment?',
};

export const markAsSentShipmentModal = {
  message: 'Mark as sent',
  messageText: 'Are you ready to send this shipment?',
};

export const materialCountMarkAsSentShipmentModal = {
  message: 'Are you ready to send this shipment?',
  messageText:
    "Once your shipment is marked as sent, it's not possible to manage the details of your shipment or packages. To manage your shipment, you'll need to contact a Global representative. Make sure your truck is loaded.",
};

export const changeProcessUponDeliveryModal = {
  processUponDelivery: {
    message: 'CHANGE TO "PROCESS UPON DELIVERY"',
    messageText: 'Are you sure you want to change the lot status?',
  },
  doNotProcess: {
    message: 'CHANGE TO "DO NOT PROCESS"',
    messageText: 'Are you sure you want to change the lot status?',
  },
};

export const shipmentSealReminderModal = {
  message: 'Mark as sent',
  messageText: `Securing your shipment with a security seal can help ensure the safe delivery of your recyclable
  catalytic converters to us. By clicking “Accept” below, you acknowledge that you understand and accept the risks
  of sending your shipment without a security seal and wish to proceed with your shipment.`,
  acceptButton: 'Accept',
  customActionButton: 'Edit shipment seal',
  customActionColor: 'geyser',
  customActionIcon: 'icon-arrow-right',
  cancelButtonIsAvailable: false,
};

export const inviteUserModal = {
  message: 'Invite user',
  messageText: 'Are you sure you want to invite this user?',
  cancelButton: 'Cancel',
};

export const deleteMarketHolidaysModal = {
  message: 'Delete',
  messageText: 'Do you want to delete this market holiday?',
};

export const deleteBankHolidaysModal = {
  message: 'Delete',
  messageText: 'Do you want to delete this bank holiday?',
};

export const addMarketHolidaysModal = dayWithScheduledHedges => ({
  message: 'Add holiday',
  messageText: dayWithScheduledHedges
    ? 'One or more hedges are scheduled for the specified date. Would you still like to add a new holiday?'
    : 'Add new holiday?',
});

export const deleteHedgeModal = {
  message: 'Delete',
  messageText: 'Delete this hedge?',
};

export const addBankHolidaysModal = isPaymentDateExists => ({
  message: 'Add holiday',
  messageText: isPaymentDateExists
    ? 'One or more payments have been requested on the specified date. Would you still like to add a new holiday?'
    : 'Add new holiday?',
});

export const cancelHedgeModal = {
  message: 'Cancel',
  messageText: 'Cancel this hedge?',
};

export const acceptLotForGradingModal = {
  message: 'Accept lot for grading',
  messageText: 'Accept this lot for grading?',
};

export const markGradingCompleteModal = {
  message: 'Mark grading complete',
  messageText: 'Is grading completed?',
};

export const acceptForProcessingModal = {
  message: 'Accept lot for processing',
  messageText: 'Accept this lot for processing?',
};

export const acceptForMixingModal = {
  message: 'Accept lot for mixing',
  messageText: 'Accept this lot for mixing?',
};

export const requestLotInspection = {
  message: 'Lot inspection',
  messageText: 'Request an inspection for this lot?',
};

export const scheduleLotInspection = {
  message: 'Lot inspection',
  messageText: 'Schedule an inspection for this lot?',
};

export const deleteTrustedCompaniesModal = {
  message: 'Delete trusted company',
  messageText: 'Do you want to delete this trusted company?',
};

export const deletePaymentExceptionCompaniesModal = {
  message: 'Delete payment exception company',
  messageText: 'Do you want to delete this payment exception company?',
};

export const deletePayoutRuleModal = {
  message: 'Delete payout rule',
  messageText: 'Do you want to delete this payout rule?',
};

export const deletePaymentModal = {
  message: 'Delete payment',
  messageText: 'Delete this payment?',
};

export const deletePaymentRequestModal = {
  message: 'Delete payment request',
  messageText: 'Delete this payment request?',
};

export const requestAdvancedPaymentModal = companyName => ({
  message: 'Request advanced payment',
  messageText: `Would you like to request an advance payment${
    companyName ? ` for ${companyName}` : ''
  }?`,
});

export const createHedgesModal = {
  className: 'HedgesCreate-modal',
  message: 'Request hedge(s)',
  messageText: `
    By pressing 'Acknowledge' you acknowledge your consent for
    ${globalRefiningGroupName}, Inc. to place a hedge on your behalf
    using estimated ounces and that such hedge will be filled with
    your material intended for this lot(s) or by way of a buy back
    on your final invoice. It is your responsibility to notify a
    ${globalRefiningGroupName}, Inc. representative if you think this
    hedge is not representative of your material.
    Once you press 'Acknowledge' there is no way to cancel or delete this request.`,
  acceptButton: 'Acknowledge',
  cancelButton: 'Cancel',
};

export const removeHedgeRequestModal = {
  message: 'Remove hedge',
  messageText: 'Delete this hedge request?',
};

export const markProcessingCompleteModal = {
  message: 'Mark processing complete',
  messageText: 'Is processing completed?',
};

export const markMixingCompleteModal = {
  message: 'Mark mixing complete',
  messageText: 'Is mixing completed?',
};

export const deleteCommodityModal = {
  message: 'Delete commodity',
  messageText: 'Delete this commodity?',
};

export const deleteRecipientModal = {
  message: 'Delete hedges recipient',
  messageText: 'Remove this email?',
};

export const generateInvoiceModal = {
  message: 'Generate final invoice',
  messageText: `The Stillwater shipment summary and/or final invoice has
            not been uploaded for this lot. These documents cannot be uploaded
            once the ${companyInformation.representativeName} final invoice is generated. Are you sure you wish to proceed?`,
  acceptButton: 'Yes',
  cancelButton: 'No',
};

export const uploadFinalInvoiceModal = {
  message: 'Upload custom final invoice',
  messageText: `Are you sure you want to upload this file? This will replace
                the existing ${companyInformation.representativeName} final invoice for this lot.`,
  acceptButton: 'Yes',
  cancelButton: 'No',
};

export const finalizeLotModal = {
  message: 'Set to finalized',
  messageText: 'Set lot status to finalized?',
};

export const deleteSelfDeliveryRequestModal = {
  message: 'Delete self-delivery request',
  messageText: 'Delete this self-delivery request?',
};

export const removeLocationModal = {
  message: 'Remove location',
  messageText: 'Delete this location?',
};

export const removeUserModal = {
  message: 'Remove user',
  messageText: 'Delete this user?',
};

export const manageGradingReceiptModal = approve => ({
  message: approve ? 'Approve grading receipt' : 'Reject grading receipt',
  messageText: `Are you sure you want to ${approve ? 'approve' : 'reject'} the grading receipt?`,
});

export const markAllReadAsResolvedModal = {
  message: 'Mark all resolved notifications as read',
  messageText: 'Are you sure you want to mark all resolved notifications as read?',
};

export const removeSettlementRequestModal = {
  message: 'Remove settlement',
  messageText: 'Delete this settlement request?',
};

export const requestSettlementWizardModal = {
  message: 'Request settlement',
  messageText: 'Do you want to request another settlement?',
  acceptButton: 'Go to review',
  customActionColor: 'pelorous',
  customActionButton: 'Request another settlement',
};

export const generateGrgShipmentSummaryModal = {
  message: 'Generate shipment summary',
  messageText: 'Please choose which type of shipment summary you want to generate?',
  acceptButton: 'Standard',
  customActionColor: 'pelorous',
  customActionButton: 'Scrap',
};

export const moveToProcessing = {
  message: 'WARNING',
  messageText:
    'You will now be able to process by this lot and this count has not been uploaded. You will receive a reminder after processing this lot to submit your count sheet document.',
};

export const editMorePackagesModal = {
  message: 'Edit more packages',
  messageText:
    'Would you like to edit more packages from the shipment? Please remember that you need to print new packing list after updating a package.',
  editMoreButton: 'Edit more packages',
  saveButton: 'Save this package only',
};

export const generateProfitLossModal = {
  message: 'Profit and Loss document',
  messageText:
    'A Profit and Loss document can now be generated for this lot. Would you like to generate one now?',
};
export const kitcoUnavailableModal = {
  message: 'Kitco market prices are currently unavailable.',
  messageText:
    'Would you like to generate the document using the most recent settlement indicator pricing?',
};

export const downloadReport = {
  message: 'Download report',
  messageText: `Your analysis report has been generated. Would you like to download it now?`,
  acceptButton: 'Yes',
  cancelButton: 'No',
};

export const inactivatePackageModal = {
  message: 'Inactivate package',
  messageText: 'Inactivate this package?',
  acceptButton: 'Yes',
  cancelButton: 'No',
};

export const reactivatePackageModal = {
  message: 'Reactivate package',
  messageText: 'Reactivate this package?',
  acceptButton: 'Yes',
  cancelButton: 'No',
};

export const removeSettlementNote = {
  message: 'Delete note',
  messageText: 'Are you sure you want to delete this note?',
  acceptButton: 'Yes',
  cancelButton: 'No',
};
