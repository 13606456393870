const FINAL_INVOICE = {
  FINAL_INVOICE: 'Final invoice',
  GENERATE: 'Generate',
  CUSTOM_INVOICE: 'Custom invoice',
  PRICES: 'Prices',
  FINAL_PAYMENT_DATE: 'Final payment date',
  FINAL_PLATINUM_PRICE: 'Final platinum price',
  FINAL_PALLADIUM_PRICE: 'Final palladium price',
  FINAL_RHODIUM_PRICE: 'Final rhodium price',
  FINAL_PLATINUM_PRICE_DO_NOT_MATCH: 'Final platinum prices do not match.',
  FINAL_PALLADIUM_PRICE_DO_NOT_MATCH: 'Final palladium prices do not match.',
  FINAL_RHODIUM_PRICE_DO_NOT_MATCH: 'Final rhodium prices do not match.',
  PLATINUM_OUNCES_REMOVED: 'Platinum ounces removed',
  PALLADIUM_OUNCES_REMOVED: 'Palladium ounces removed',
  RHODIUM_OUNCES_REMOVED: 'Rhodium ounces removed',
  TOTAL_FINAL_PAYMENT: 'Total final payment',
  TOTAL_FINAL_PAYMENT_HEADER:
    "Please enter the 'Total Final Payment' amount from the custom invoice. If the amount is negative, include a minus (-) sign",
  BALANCE_REMAINING: 'Company balance',
  OUTSTANDING_BALANCE_APPLIED: 'Outstanding balance applied',
  OUTSTANDING_BALANCE_APPLIED_HEADER:
    'Enter the outstanding amount you would like to apply to this lot (this amount will be subtracted from the invoice amount)',
  POOL_ACCOUNT_OUNCES_WARNING: "Were any ounces moved to the customer's pool account?",
  POOL_ACCOUNT_MOCKUP_OUNCES_WARNING: "Will any ounces be moved to the customer's pool account?",
  MAXIMUM_COLON: 'Maximum:',
  MAXIMUM: 'Maximum',
  GENERATE_FINAL_INVOICE_MOCKUP_SUCCESS: 'Final invoice mockup has been generated successfully.',
  REMOVING_OUNCES_DISABLED:
    'Pool ounces have been paid out for this lot, so "ounces removed" cannot be changed.',
  ERROR: {
    GENERATE_FINAL_INVOICE: 'Final invoice has not been generated.',
    GENERATE_FINAL_INVOICE_MOCKUP: 'Final invoice mockup has not been generated.',
  },
};

export { FINAL_INVOICE };
