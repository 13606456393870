import {
  updateLotActionType,
  updateLotSuccessActionType,
  updateLotFailureActionType,
} from 'actions/Lots/updateLot';

const INITIAL_STATE = {
  isPending: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case updateLotActionType:
      return {
        isPending: true,
      };
    case updateLotSuccessActionType:
      return {
        isPending: false,
      };
    case updateLotFailureActionType:
      return {
        isPending: false,
      };
    default:
      return state;
  }
};
