import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef, useMemo, useCallback, Children } from 'react';
import styled, { css, keyframes, ThemeProvider } from 'styled-components';

import { ButtonLayout, Label } from 'shared/components/Buttons/GroupButton/components/GroupButton';
import { Icon } from 'shared/components/Icon/Icon';
import { SHARED } from 'shared/helpers/constants/sharedConstants';
import { cssVar, media } from 'shared/helpers/styling/styling';

const show = keyframes`
  from {
    opacity: 0;
    transform:  ${({ isOnly }) =>
      isOnly
        ? 'translateY(120px) translateX(calc(-100% + 90px))'
        : 'translateY(120px) translateX(calc(-50% + 90px))'};
  } to {
    opacity: 1;
    transform: ${({ isOnly }) =>
      isOnly
        ? 'translateY(120px) translateX(calc(-100% + 90px))'
        : 'translateY(120px) translateX(calc(-50% + 90px))'};
  }
`;

const ActionButtonLayout = styled(ButtonLayout)`
  :last-child {
    border-radius: 17px;
  }

  :hover {
    border-radius: ${({ isOnly }) => (isOnly ? '17px' : '0 17px 17px 0')};
  }

  && {
    ${({ isOpen }) =>
      isOpen &&
      css`
        background-color: ${cssVar('limedSpruceLighter')};
        border-radius: ${({ isOnly }) => (isOnly ? '17px' : '0 17px 17px 0')};

        :first-child {
          border-radius: 17px;
        }
      `}
  }

  ${({ smallGroup }) =>
    smallGroup &&
    css`
      width: 25px;
      height: 28px;
      padding: 0;
      border-radius: 0 !important;
    `}
`;

const ActionButtonMenu = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  border: 2px solid gray;
  min-width: 180px;
  background-color: ${cssVar('outerSpace')};
  border-radius: 17px;
  transform: ${({ isOnly }) =>
    isOnly
      ? 'translateY(120px) translateX(calc(-100% + 90px))'
      : 'translateY(120px) translateX(calc(-50% + 90px))'};
  z-index: 10;
  box-shadow: 0 11px 24px 0 rgba(0, 0, 0, 0.29);

  ::after {
    content: '';
    width: 1rem;
    height: 1rem;
    position: absolute;
    background-color: ${cssVar('outerSpace')};
    right: 45px;
    transform: rotateZ(45deg) translateY(-13px);
    border-top: 2px solid gray;
    border-left: 2px solid gray;
  }

  ${media.phone`
    transform: ${({ isOnly }) =>
      isOnly
        ? 'translateY(120px) translateX(calc(-50% + 45px))'
        : 'translateY(120px) translateX(calc(-25% + 90px))'};
    animation: none;

    ::after {
      transform: rotateZ(45deg) translateY(calc(100% -13px));
      right: 50%
    }
  `}

  ${({ isOpen }) =>
    isOpen &&
    css`
      animation: ${show} 0.2s ease-out;
    `}

  ${({ smallGroup, itemsLength, standardLot }) =>
    smallGroup &&
    css`
      min-width: 125px;
      max-width: 125px;
      transform: translateY(calc(${itemsLength} * ${standardLot ? '52px' : '38px'}))
        translateX(calc(${standardLot ? '-50% + 80px' : '-5px'}));
      ${!standardLot && `right: -8px;`}
      ::after {
        right: 30px;
      }

      ${media.smallDesktop`
      transform: translateY(calc(${itemsLength} * ${standardLot ? '52px' : '38px'}))
        translateX(0);
        right: -8px;
      `}

      ${media.mobile`
        transform: translateY(calc(${itemsLength} * ${
        standardLot ? '52px' : '38px'
      })) translateX(0);
        right: -8px;
      `}
    `}
`;

const RowButton = styled.div`
  background-color: transparent;
  text-align: center;

  :first-child > * {
    border-radius: 17px 17px 0 0;

    ::after {
      content: '';
      width: 15px;
      height: 15px;
      background-color: ${cssVar('outerSpace')};
      position: absolute;
      right: 59px;
      transform: rotateZ(45deg) translateY(-35px);
      z-index: 12;
      transition: 0.1s ease-in;
    }

    :hover::after {
      background-color: ${cssVar('outerSpaceLighter')};
    }

    ${media.phone`
      ::after {
        right: calc(50% + 16px);
      }
    `}
  }

  :last-child > * {
    border-radius: ${({ isOneAction }) => (isOneAction ? '17px' : '0 0 17px 17px')};
  }

  ${({ smallGroup }) =>
    smallGroup &&
    css`
      :first-child > * {
        ::after {
          right: 44px;
        }
      }
    `}
`;

const theme = {
  noOutline: true,
  noBoxShadow: true,
  borderRadius: 0,
  width: '100%',
  height: '50px',
  bgColorHover: cssVar('outerSpaceLighter'),
  outlineColorHover: cssVar('outerSpaceLighter'),
  textColor: 'rgba(255, 255, 255, 0.70)',
  justifyContent: 'flex-start',
};

const GroupActionButton = ({ children, only, smallGroup, standardLot }) => {
  const [isOpen, setIsOpen] = useState(false);
  const menu = useRef();
  const toggle = useRef();

  const items = useMemo(
    () =>
      Children.toArray(children).filter(child =>
        'access' in child.props ? child.props.access : true,
      ),
    [children],
  );

  const handleClick = useCallback(
    event => {
      if (
        items &&
        items.length &&
        toggle.current &&
        toggle.current.contains(event.target) &&
        !menu.current
      ) {
        setIsOpen(true);
      } else if (menu.current && !menu.current.contains(event.target)) {
        event.stopPropagation();
        setIsOpen(false);
      }
    },
    [items],
  );

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [handleClick]);

  return (
    <>
      <ActionButtonLayout
        ref={toggle}
        isOpen={isOpen}
        isOnly={only}
        disabled={items && !items.length}
        smallGroup={smallGroup}
      >
        {smallGroup && <Icon icon="icon-more" width={18} height={4} size={'contain'} />}
        {!smallGroup && (
          <>
            <Icon icon="icon-more" width={42} height={60} />
            <Label>{SHARED.EXTENDABLE_ACTIONS}</Label>
          </>
        )}
      </ActionButtonLayout>
      {isOpen && (
        <ActionButtonMenu
          ref={menu}
          isOpen={isOpen}
          itemsNumber={items.length}
          isOnly={only}
          smallGroup={smallGroup}
          itemsLength={items.length}
          standardLot={standardLot}
        >
          {items.map(item => (
            <RowButton
              key={item.key}
              isOneAction={items && items.length === 1}
              smallGroup={smallGroup}
            >
              <ThemeProvider theme={theme}>{item}</ThemeProvider>
            </RowButton>
          ))}
        </ActionButtonMenu>
      )}
    </>
  );
};

GroupActionButton.propTypes = {
  standardLot: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  only: PropTypes.bool,
  smallGroup: PropTypes.bool,
};

GroupActionButton.defaultProps = {
  smallGroup: false,
};

export { GroupActionButton };
