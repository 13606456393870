import PropTypes from 'prop-types';
import React from 'react';

import {
  StyledPreloaderComponent,
  StyledPreloaderContent,
  StyledPreloaderDesc,
  StyledPreloaderTextarea,
  StyledPreloaderTitle,
  StyledPreloaderWrapper,
  StyledPreloaderSpinner,
} from './StyledPreloader';

export function Preloader({ loading, label }) {
  return (
    loading && (
      <StyledPreloaderWrapper>
        <StyledPreloaderComponent>
          <StyledPreloaderContent>
            <StyledPreloaderSpinner>
              <span className="slice" />
              <span className="slice" />
              <span className="slice" />
              <span className="slice" />
              <span className="slice" />
              <span className="slice" />
            </StyledPreloaderSpinner>
            <StyledPreloaderTextarea>
              <StyledPreloaderTitle>Please wait...</StyledPreloaderTitle>
              <StyledPreloaderDesc>{label}</StyledPreloaderDesc>
            </StyledPreloaderTextarea>
          </StyledPreloaderContent>
        </StyledPreloaderComponent>
      </StyledPreloaderWrapper>
    )
  );
}

Preloader.propTypes = {
  loading: PropTypes.bool,
  label: PropTypes.string,
};
