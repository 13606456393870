import {
  createAgreementActionType,
  createAgreementFailureActionType,
  createAgreementSuccessActionType,
} from 'actions/Agreements/createAgreement';

const INITIAL_STATE = { isPending: false };

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case createAgreementActionType:
      return {
        isPending: true,
      };
    case createAgreementFailureActionType:
      return {
        isPending: false,
      };
    case createAgreementSuccessActionType:
      return {
        isPending: false,
      };
    default:
      return state;
  }
};
