import axios from 'axios';

import { showSnackbar } from 'actions/shared/snackbar';

import {
  dangerOptions,
  successOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

const API_HOST_AUTH = process.env.REACT_APP_API;

const moveToProcessingType = 'MOVE_TO_PROCESSING';
const moveToProcessingAction = () => ({
  type: moveToProcessingType,
});

const moveToProcessingSuccessType = `${moveToProcessingType}_SUCCESS`;
const moveToProcessingSuccessAction = () => ({
  type: moveToProcessingSuccessType,
});

const moveToProcessingFailureType = `${moveToProcessingType}_FAILURE`;
const moveToProcessingFailureAction = () => ({
  type: moveToProcessingFailureType,
});

const moveToProcessing = lotId => dispatch => {
  dispatch(moveToProcessingAction());

  return axios
    .put(`${API_HOST_AUTH}/lots/available-for-processing/${lotId}`)
    .then(() => {
      dispatch(moveToProcessingSuccessAction());
      return dispatch(showSnackbar(successOptions, `Lot has been moved to processing.`));
    })
    .catch(err => {
      dispatch(moveToProcessingFailureAction());
      return dispatch(
        showSnackbar(dangerOptions, `Lot has not been moved to processing. ${err.data.message}`),
      );
    });
};

export {
  moveToProcessingType,
  moveToProcessingSuccessType,
  moveToProcessingFailureType,
  moveToProcessing,
};
