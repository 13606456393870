import {
  enterSettledOuncesActionType,
  enterSettledOuncesFailureActionType,
  enterSettledOuncesSuccessActionType,
} from 'actions/Settlements/enterSettledOunces';

const INITIAL_STATE = {
  isPending: false,
};

const EnterSettledOuncesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case enterSettledOuncesActionType:
      return {
        isPending: true,
      };
    case enterSettledOuncesSuccessActionType:
    case enterSettledOuncesFailureActionType:
      return {
        isPending: false,
      };
    default:
      return state;
  }
};

export { EnterSettledOuncesReducer };
