import PropTypes from 'prop-types';
import * as React from 'react';

import { FieldUploadList } from 'shared/components/Fields/FiledUpload/FieldUploadList';
import {
  validateMultipleDocuments,
  validateMultipleDocumentsLength,
} from 'shared/helpers/validations/lot/validateMultipleDocuments';

const FieldUploadMultipleFilesList = ({
  files,
  type,
  validExtensions,
  onDelete,
  checkMultipleFilesError,
  limit,
  allowDeleteExistingFiles,
  revertPosition,
  validationError,
}) => {
  const multipleFiles = files.multipleFiles[type];

  if (!(files && multipleFiles)) {
    return null;
  }

  let hasError = false;

  const filesLength = multipleFiles.length;

  const parsedFiles = multipleFiles.map(({ existingFile: file, fileToUpload }, index, arr) => {
    const error =
      filesLength === index + 1 && validationError
        ? validationError
        : validateMultipleDocuments(file, validExtensions);

    hasError = filesLength === index + 1 && validationError ? true : hasError || error;

    return {
      file,
      fileToUpload,
      meta: { error, dirty: true },
      onDelete: onDelete && onDelete({ type, fileIndex: index }),
    };
  });
  hasError = hasError || validateMultipleDocumentsLength(multipleFiles, limit);

  if (checkMultipleFilesError) {
    checkMultipleFilesError({ type, hasError });
  }

  return (
    <FieldUploadList
      revertPosition={revertPosition}
      allowDeleteExistingFiles={allowDeleteExistingFiles}
      files={parsedFiles}
    />
  );
};

FieldUploadMultipleFilesList.propTypes = {
  files: PropTypes.object,
  type: PropTypes.string,
  validExtensions: PropTypes.arrayOf(PropTypes.string),
  onDelete: PropTypes.func,
  checkMultipleFilesError: PropTypes.func,
  limit: PropTypes.number,
  allowDeleteExistingFiles: PropTypes.bool,
  revertPosition: PropTypes.bool,
};

FieldUploadMultipleFilesList.defaultProps = {
  limit: 10,
  allowDeleteExistingFiles: false,
  revertPosition: false,
};

export { FieldUploadMultipleFilesList };
