import {
  getCompaniesForFiltersActionType,
  getCompaniesForFiltersSuccessActionType,
  getCompaniesForFiltersFailureActionType,
} from 'actions/Companies/getCompaniesForFilters';

const INITIAL_STATE = {
  isPending: false,
  data: [],
};

const GetCompaniesForFiltersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getCompaniesForFiltersActionType:
      return {
        ...state,
        isPending: true,
      };
    case getCompaniesForFiltersFailureActionType:
      return {
        data: [],
        isPending: false,
      };
    case getCompaniesForFiltersSuccessActionType:
      return {
        data: action.payload.response,
        isPending: false,
      };
    default:
      return state;
  }
};

export { GetCompaniesForFiltersReducer };
