import {
  companyFiltersClearActionType,
  companyStatusFilterActionType,
  companyTypeFilterActionType,
  companyCountryFilterActionType,
  companyStateFilterActionType,
  companyDueDiligenceFilterActionType,
} from 'actions/Companies/companyFilters';

import { initialDueDiligence } from 'shared/helpers/constants/filters/initialDueDiligence';
import { initialStatuses } from 'shared/helpers/constants/filters/initialStatuses';

const INITIAL_STATE = {
  status: Object.assign([], initialStatuses),
  companyTypes: [],
  country: null,
  state: null,
  dueDiligence: Object.assign([], initialDueDiligence),
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case companyStatusFilterActionType:
      return {
        ...state,
        status: action.payload.companyStatus,
      };
    case companyTypeFilterActionType:
      return {
        ...state,
        companyTypes: action.payload.companyTypes,
      };
    case companyDueDiligenceFilterActionType:
      return {
        ...state,
        dueDiligence: action.payload.dueDiligence,
      };
    case companyStateFilterActionType:
      return {
        ...state,
        state: action.payload.state,
      };
    case companyCountryFilterActionType:
      return {
        ...state,
        country: action.payload.country,
      };
    case companyFiltersClearActionType:
      return {
        status: [],
        companyTypes: [],
        country: null,
        state: null,
        dueDiligence: [],
      };
    default:
      return state;
  }
};
