import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef } from 'react';
import Select, { components } from 'react-select';
import styled from 'styled-components';

import { styles } from 'shared/components/Fields/FieldSelect/FieldSelect';
import { FIELD_SELECT } from 'shared/components/Fields/FieldSelect/constants';
import { withFieldWrapper } from 'shared/components/Formik/Fields/FieldWrapper/FieldWrapper';
import { isTablet } from 'shared/helpers/constants/resolutionsConstants';
import { humanize } from 'shared/helpers/parsers/text';
import { themed, isTouchScreenDevice, scrollbar } from 'shared/helpers/styling/styling';
import { useWindowWidth } from 'shared/hooks/useWindowWidth';

const MenuList = styled(components.MenuList)`
  ${scrollbar}
`;

const FieldSelect = withFieldWrapper(
  ({
    options,
    disabled,
    multi,
    value,
    name,
    onChange,
    onBlur,
    placeholder,
    clearable,
    clearAfterOptionsChanged = false,
    allowEmptyArray,
    wrap,
  }) => {
    const windowWidth = useWindowWidth();
    const selectRef = useRef();

    const customFilterOption = useCallback(
      (option, rawInput) =>
        option.label.toLowerCase().includes(rawInput.toLowerCase()) ||
        option.data?.grgLotNumber?.toLowerCase().includes(rawInput.toLowerCase()),
      [],
    );

    const setDefaultValue = options?.length === 1 && !multi && !value && !disabled;
    const noOptionsMessage = useCallback(() => FIELD_SELECT.NO_OPTION_MESSAGE, []);

    useEffect(() => {
      if (disabled) {
        selectRef.current.setState({ menuIsOpen: false });
      }

      if (!value && clearAfterOptionsChanged) {
        selectRef.current?.clearValue();
      }

      if (setDefaultValue) {
        onChange(options[0]);
      }
    }, [disabled, value]);

    const normalizeValue = value => {
      if (!value) return null;

      return typeof value === 'string' && value ? { label: humanize(value), value } : value;
    };

    return (
      <Select
        name={name}
        options={options}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
        components={{
          MenuList,
        }}
        styles={styles}
        ref={selectRef}
        wrap={wrap}
        isDisabled={disabled}
        isSearchable={!isTouchScreenDevice()}
        filterOption={customFilterOption}
        isClearable={clearable === undefined || clearable}
        isMulti={multi}
        noOptionsMessage={noOptionsMessage}
        isMobile={windowWidth < isTablet}
        defaultValue={setDefaultValue && { label: options[0].label, value: options[0].value }}
        value={normalizeValue(value)}
        allowEmptyArray={allowEmptyArray}
        valueLength={value ? value?.length : 0}
      />
    );
  },
);

FieldSelect.propTypes = {
  clearable: PropTypes.bool,
  disabled: PropTypes.bool,
  multi: PropTypes.bool,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object),
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  clearAfterOptionsChanged: PropTypes.bool,
  allowEmptyArray: PropTypes.bool,
  wrap: PropTypes.bool,
};

const FieldSelectStyled = styled(FieldSelect).attrs(themed(({ disabled }) => ({ disabled })))``;

export { FieldSelectStyled as FieldSelect };
