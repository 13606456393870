import { resolveVersion, versions } from 'utils/versioning';

import { contactData } from '../versioning/contactData';

const message = {
  RELATED_COMPANY_EMPTY: 'Related company is required.',
  INVALID_COMPANY_FOR_FUTURE_HEDGE:
    'You cannot schedule a hedge for a future date and time for this company',
  HEDGE_TYPE_EMPTY: 'Hedge type is required.',
  MATERIAL_TYPE_EMPTY: 'Material type is required',
  MATERIAL_TYPE_CUSTOMER: `To place a hedge on this material, please contact a ${contactData.company} representative at ${contactData.phoneNumber}.`,
  MATERIAL_TYPE_BUYER: `To place a hedge on this material, please contact a
                           ${resolveVersion({
                             [versions.alpha]: 'Alpha',
                             [versions.global]: 'Global',
                             [versions.west]: 'Global',
                           })} representative.`,
  HEDGE_OUNCES_OVER_MAXIMUM: `To hedge more than two times the number of suggested ounces,
                              please contact a ${contactData.company} representative at ${contactData.phoneNumber}.`,
  HEDGE_OUNCES_OVER_LOT_MAXIMUM: `You cannot hedge more ounces than are contained in the lot.`,
  MATERIAL_UNITS_EMPTY: 'Canned units are required.',
  MATERIAL_UNITS_FORMAT: 'Canned units have invalid format.',
  MATERIAL_UNITS_LT_1: 'Canned units cannot be less than 1.',
  MATERIAL_WEIGHT_EMPTY: 'Net weight is required.',
  MATERIAL_WEIGHT_FORMAT: 'Net weight has invalid format.',
  MATERIAL_WEIGHT_ZERO: 'Net weight cannot be less than 0.',
  MATERIAL_BOTH_FILLED: 'You cannot type weight and units.',
  RELATED_LOT_EMPTY: 'Related lot is required.',
  PLATINUM_OZ_EMPTY: 'Platinum is required.',
  PLATINUM_OZ_ZERO: 'Platinum value must be at least 1 or equal 0.',
  PLATINUM_OZ_FORMAT: 'Platinum has invalid format.',
  PLATINUM_OZ_LESS: value => `Platinum value must be less or equal ${value}.`,
  PLATINUM_OZ_EQUAL_SUGGESTED: `Platinum value must be equal to suggested value.`,
  PALLADIUM_OZ_EMPTY: 'Palladium is required.',
  PALLADIUM_OZ_ZERO: 'Palladium value must be at least 1 or equal 0.',
  PALLADIUM_OZ_FORMAT: 'Palladium has invalid format.',
  PALLADIUM_OZ_LESS: value => `Palladium value must be less or equal ${value}.`,
  PALLADIUM_OZ_EQUAL_SUGGESTED: `Palladium value must be equal to suggested value.`,
  RHODIUM_OZ_EMPTY: 'Rhodium is required.',
  RHODIUM_OZ_ZERO: 'Rhodium value must be at least 1 or equal 0.',
  RHODIUM_OZ_FORMAT: 'Rhodium has invalid format.',
  RHODIUM_OZ_LESS: value => `Rhodium value must be less or equal ${value}.`,
  RHODIUM_OZ_EQUAL_SUGGESTED: `Rhodium value must be equal to suggested value.`,
  DELIVERY_DATE_EMPTY: 'Delivery date is required.',
  REQUEST_HEDGE_DATE_EMPTY: 'Request hedge date is required.',
  REQUEST_HEDGE_TIME_EMPTY: 'Request hedge time is required.',
  DELIVERY_DATE_GRG_EMPTY: `Delivery date ${resolveVersion({
    [versions.alpha]: 'ALP',
    [versions.global]: 'GRG',
    [versions.west]: 'GRG',
  })} is required.`,
  CUSTOMER_INSTRUCTIONS_FORMAT: 'Customer instructions must have a valid format.',
  PLACED_ON_EMPTY: 'Placed on is required.',
  HEDGES_CREATE_ERROR: 'There was an error during hedges creation.',
  HEDGES_UPDATE_ERROR: 'There was an error during hedge update.',
  ALL_OUNCES_ZERO: 'All ounces cannot be equal to 0.',
  DIFFERENT_DELIVERY_DATES: 'Delivery dates must be identical for the same lot.',
  RELATED_HEDGE_EMPTY: 'Related hedge is required.',
};

export { message };
