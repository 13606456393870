import { GENERAL_INFO } from 'shared/helpers/constants/generalInfoConstants';

import { isAlpha } from 'utils/versioning/Guards';

const fieldsConfig = values => [
  { title: GENERAL_INFO.FACILITY_ADDRESS, value: values?.facilityAddress },
  {
    title: isAlpha ? GENERAL_INFO.PHONE : GENERAL_INFO.TOLL_FREE,
    value: values?.tollFree,
  },
  { title: GENERAL_INFO.HOURS_OF_OPERATION, value: values?.hoursOfOperation },
  { title: GENERAL_INFO.HEDGING_HOURS, value: values?.hedgingHours },
  {
    title: GENERAL_INFO.SHIPPING_AND_RECEIVING_HOURS,
    value: [values?.shippingAndReceivingHours, values?.isAppointmentNeeded],
  },
  { title: GENERAL_INFO.ACCOUNTING_DEPARTMENT, value: values?.accountingDepartment },
  {
    title: GENERAL_INFO.CONTACT_YOUR_REPRESENTATIVE,
    fields: [
      {
        title: GENERAL_INFO.MOBILE,
        value: values?.representative?.mobile,
        icons: [
          {
            type: 'call',
            icon: 'phone',
            href: `${values?.representative?.mobile}`,
          },
          {
            type: 'sms',
            icon: 'referral',
            href: `${values?.representative?.mobile}`,
          },
        ],
      },
      {
        title: GENERAL_INFO.OFFICE,
        value: values?.representative?.office,
        icons: [
          {
            type: 'call',
            icon: 'phone',
            href: `${values?.representative?.office}`,
          },
        ],
      },
      {
        title: GENERAL_INFO.EMAIL,
        value: values?.representative?.email,
        icons: [
          {
            type: 'email',
            icon: 'email',
            href: `${values?.representative?.email}`,
          },
        ],
      },
    ],
  },
];

export { fieldsConfig };
