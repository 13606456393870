import React, { Component } from 'react';

import { UIAuthorization } from 'components/Authorization/UIAuthorization';

import { Button } from 'shared/components/Buttons/Button';
import { FullPageList } from 'shared/components/FullPageList/FullPageList';
import { NOTIFICATION } from 'shared/helpers/constants/notifications/notificationConstants';
import {
  itemsTemplate,
  itemsDetailsTemplate,
  itemsTemplateMobile,
  customerItemsTemplate,
  customerItemsTemplateMobile,
} from 'shared/helpers/constants/notifications/notificationsListTableConfig';
import { isCustomerOrGrading } from 'shared/helpers/matchers/checkUserType';
import { isGlobalUserType } from 'shared/helpers/matchers/isGlobalUserType';

import { MarkAllResolvedAsRead } from '../MarkAllResolvedAsRead';

class NotificationsList extends Component {
  componentWillUnmount() {
    this.props.clearNotificationsList();
  }

  get tableActions() {
    return [
      {
        icon: 'arrow-right',
        action: 'Notification',
        onClick:
          ({ _id }) =>
          () =>
            !this.props.notificationDetails.isPending &&
            this.props
              .getNotification(_id, 'notifications')
              .then(notification => this.goToNotification(notification)),
      },
    ];
  }

  goToNotification = notification => {
    const { history, markAsRead } = this.props;

    window.scrollTo(0, 0);
    !notification.alreadyRead && markAsRead(notification._id);
    return (
      history.location.pathname.includes('notifications') &&
      notification.path &&
      history.push(notification.path)
    );
  };

  fetchList = (page = 1, limit = 50, query = '') => {
    this.props.getNotifications(limit, page, query);
  };

  actionButtons = () => {
    const {
      auth,
      notificationsList: { resolvedNotReadCount },
    } = this.props;

    return (
      <UIAuthorization access={isGlobalUserType(auth.user.userType)}>
        <MarkAllResolvedAsRead fetchList={this.fetchList}>
          {({ markAllResolvedAsRead }) => (
            <Button onClick={markAllResolvedAsRead} disabled={!resolvedNotReadCount}>
              {NOTIFICATION.MARK_ALL_RESOLVED_AS_READ}
            </Button>
          )}
        </MarkAllResolvedAsRead>
      </UIAuthorization>
    );
  };

  render() {
    const {
      auth: { user },
      notificationsList: { notifications, page, pages, limit, isPending },
      searchFilter,
    } = this.props;

    return (
      <FullPageList
        title={NOTIFICATION.NOTIFICATIONS}
        actionButtons={this.actionButtons}
        itemsTemplate={isCustomerOrGrading(user.userType) ? customerItemsTemplate : itemsTemplate}
        itemsDetailsTemplate={itemsDetailsTemplate}
        itemsTemplateMobile={
          isCustomerOrGrading(user.userType) ? customerItemsTemplateMobile : itemsTemplateMobile
        }
        actionsTemplate={this.tableActions}
        items={notifications || []}
        fetchList={this.fetchList}
        isPending={isPending}
        pagination={{ limit, page, pages }}
        actionsContext={this}
        disableInitialFetch={searchFilter}
        enableSearch
      />
    );
  }
}

export { NotificationsList };
