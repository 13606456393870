const ENTER_POOL_PAYMENT = {
  PLATINUM_OUNCES_EMPTY: 'Platinum ounces cannot be empty.',
  PALLADIUM_OUNCES_EMPTY: 'Palladium ounces cannot be empty.',
  RHODIUM_OUNCES_EMPTY: 'Rhodium ounces cannot be empty.',
  PLATINUM_OUNCES_INVALID: 'Platinum ounces must have a valid format.',
  PALLADIUM_OUNCES_INVALID: 'Palladium ounces must have a valid format.',
  RHODIUM_OUNCES_INVALID: 'Rhodium ounces must have a valid format.',
  PLATINUM_OUNCES_NEGATIVE: 'Platinum ounces cannot be less than 0.',
  PALLADIUM_OUNCES_NEGATIVE: 'Palladium ounces cannot be less than 0.',
  RHODIUM_OUNCES_NEGATIVE: 'Rhodium ounces cannot be less or than 0.',
  PLATINUM_OUNCES_RANGE: value => `Platinum ounces cannot be greater than ${value}.`,
  PALLADIUM_OUNCES_RANGE: value => `Palladium ounces cannot be greater than ${value}.`,
  RHODIUM_OUNCES_RANGE: value => `Rhodium ounces cannot be greater than ${value}.`,
  PLATINUM_PRICE_PER_OUNCE_INVALID: 'Platinum price per ounce must have a valid format.',
  PLATINUM_PRICE_PER_OUNCE_EMPTY: 'Platinum price per ounce cannot be empty.',
  PLATINUM_PRICE_PER_OUNCE_NEGATIVE: 'Platinum price per ounce cannot be less or equal to 0.',
  PALLADIUM_PRICE_PER_OUNCE_INVALID: 'Palladium price per ounce must have a valid format.',
  PALLADIUM_PRICE_PER_OUNCE_EMPTY: 'Palladium price per ounce cannot be empty.',
  PALLADIUM_PRICE_PER_OUNCE_NEGATIVE: 'Palladium price per ounce cannot be less or equal to 0.',
  RHODIUM_PRICE_PER_OUNCE_INVALID: 'Rhodium price per ounce must have a valid format.',
  RHODIUM_PRICE_PER_OUNCE_EMPTY: 'Rhodium price per ounce cannot be empty.',
  RHODIUM_PRICE_PER_OUNCE_NEGATIVE: 'Rhodium price per ounce cannot be less or equal to 0.',
  OUTSTANDING_BALANCE_APPLIED_EMPTY: 'Outstanding balance applied cannot be empty.',
  OUTSTANDING_BALANCE_APPLIED_ZERO: 'Outstanding balance applied has to be greater than zero.',
  OUTSTANDING_BALANCE_APPLIED_INVALID: 'Outstanding balance applied must have a valid format.',
  OUTSTANDING_BALANCE_APPLIED_NEGATIVE: 'Outstanding balance applied cannot be less than 0.',
  OUTSTANDING_BALANCE_APPLIED_TOO_HIGH:
    'Outstanding balance applied cannot be more than the company balance.',
  OUTSTANDING_BALANCE_APPLIED_RANGE:
    'The balance applied cannot be greater than the payment amount.',
};

export { ENTER_POOL_PAYMENT };
