import axios from 'axios';

import { showSnackbar } from 'actions/shared/snackbar';

import {
  dangerOptions,
  successOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

const API_HOST_AUTH = process.env.REACT_APP_API;

const updateOutstandingBalanceActionType = 'UPDATE_OUTSTANDING_BALANCE';
const updateOutstandingBalanceAction = () => ({
  type: updateOutstandingBalanceActionType,
});

const updateOutstandingBalanceSuccessActionType = `${updateOutstandingBalanceActionType}_SUCCESS`;
const updateOutstandingBalanceSuccessAction = () => ({
  type: updateOutstandingBalanceSuccessActionType,
});

const updateOutstandingBalanceFailureActionType = `${updateOutstandingBalanceActionType}_FAILURE`;
const updateOutstandingBalanceFailureAction = () => ({
  type: updateOutstandingBalanceFailureActionType,
});

const updateOutstandingBalance = (companyId, data) => dispatch => {
  dispatch(updateOutstandingBalanceAction());

  return axios
    .patch(`${API_HOST_AUTH}/companies/${companyId}/outstanding-balance`, data)
    .then(({ data: { message } }) => {
      dispatch(updateOutstandingBalanceSuccessAction());
      dispatch(showSnackbar(successOptions, message));
    })
    .catch(({ data }) => {
      dispatch(updateOutstandingBalanceFailureAction());
      dispatch(showSnackbar(dangerOptions, data.message));

      return Promise.reject(data);
    });
};

export {
  updateOutstandingBalanceActionType,
  updateOutstandingBalanceSuccessActionType,
  updateOutstandingBalanceFailureActionType,
  updateOutstandingBalance,
};
