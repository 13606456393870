import React from 'react';

import { Section } from 'shared/components/Form';
import { MultipleFieldsSection } from 'shared/components/Form/MultipleFieldsSection/MultipleFieldsSection';
import { ASSAY } from 'shared/helpers/constants/lots/assayConstants';

import { renderFields } from '../helpers/renderFields';
import { Weights } from './components/Weights/Weights';
import {
  containedAssaysFieldsConfig,
  returnableOuncesFieldsConfig,
  containedMetalsFieldsConfig,
} from './configs/fieldsConfig';

const RegularAssay = ({ formValues, change, resetSection }) => (
  <>
    <Weights formValues={formValues} change={change} resetSection={resetSection} />
    <Section
      header={ASSAY.CONTAINED_ASSAYS}
      template={['containedAssays containedAssays']}
      noPadding
    >
      <MultipleFieldsSection
        name="containedAssays"
        fields={renderFields(containedAssaysFieldsConfig)}
      />
    </Section>
    <Section
      header={ASSAY.CONTAINED_METALS}
      template={['containedMetals containedMetals']}
      noPadding
    >
      <MultipleFieldsSection
        name="containedMetals"
        fields={renderFields(containedMetalsFieldsConfig)}
      />
    </Section>
    <Section
      header={ASSAY.RETURNABLE_OUNCES}
      template={['returnableOunces returnableOunces']}
      noPadding
    >
      <MultipleFieldsSection
        name="returnableOunces"
        fields={renderFields(returnableOuncesFieldsConfig)}
      />
    </Section>
  </>
);

export { RegularAssay };
