import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getPackageDetailsActionType = 'GET_PACKAGE_DETAILS';
const getPackageDetailsAction = () => ({
  type: getPackageDetailsActionType,
});

const getPackageDetailsSuccessActionType = `${getPackageDetailsActionType}_SUCCESS`;
const getPackageDetailsSuccessAction = pkg => ({
  type: getPackageDetailsSuccessActionType,
  payload: {
    package: pkg,
  },
});

const getPackageDetailsFailureActionType = `${getPackageDetailsActionType}_FAILURE`;
const getPackageDetailsFailureAction = errorMessage => ({
  type: getPackageDetailsFailureActionType,
  payload: {
    errorMessage,
  },
});

const clearPackageDetailsActionType = 'CLEAR_PACKAGE_DETAILS_ACTION';
const clearPackageDetailsAction = () => ({
  type: clearPackageDetailsActionType,
});

const getPackageFactory = url => packageId => dispatch => {
  dispatch(getPackageDetailsAction());

  return axios
    .get(`${API_HOST_AUTH}/${url}/${packageId}`)
    .then(res => {
      dispatch(getPackageDetailsSuccessAction(res.data));
      return res.data;
    })
    .catch(err => {
      dispatch(getPackageDetailsFailureAction(err));
      return Promise.reject(err.data);
    });
};

const getPackage = getPackageFactory('packages');
const getGlobalCreatedPackage = getPackageFactory('packages/global');

export {
  getPackageDetailsActionType,
  getPackageDetailsAction,
  getPackageDetailsSuccessActionType,
  getPackageDetailsSuccessAction,
  getPackageDetailsFailureActionType,
  getPackageDetailsFailureAction,
  clearPackageDetailsActionType,
  clearPackageDetailsAction,
  getPackage,
  getGlobalCreatedPackage,
};
