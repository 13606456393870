import React from 'react';
import Select from 'react-select';

import { styles, MenuList } from '../Fields/FieldSelect/FieldSelect';
import { withFieldWrapper } from '../Fields/FieldWrapper/FieldWrapper';

const FiltersSelectBase = props => (
  <Select
    components={{
      MenuList,
    }}
    styles={styles}
    {...props}
  />
);

const FiltersSelect = withFieldWrapper(FiltersSelectBase);

export { FiltersSelect };
