import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getAgreementsActionType = 'GET_AGREEMENTS';
function getAgreementsAction() {
  return {
    type: getAgreementsActionType,
  };
}

const getAgreementsSuccessActionType = 'GET_AGREEMENTS_SUCCESS';
function getAgreementsSuccessAction(agreements, pages, total) {
  return {
    type: getAgreementsSuccessActionType,
    payload: {
      agreements,
      pages,
      total,
    },
  };
}

const getAgreementsFailureActionType = 'GET_AGREEMENTS_FAILURE';
function getAgreementsFailureAction(errorMessage) {
  return {
    type: getAgreementsFailureActionType,
    payload: {
      errorMessage,
    },
  };
}

const clearAgreementsListActionType = 'CLEAR_AGREEMENTS_LIST_ACTION';
function clearAgreementsList() {
  return {
    type: clearAgreementsListActionType,
  };
}

function companyDetailsGetAgreements(companyId, limit, page) {
  return dispatch => {
    dispatch(getAgreementsAction());

    return axios
      .get(`${API_HOST_AUTH}/agreements/companies/${companyId}/${page}/${limit}`)
      .then(res => {
        dispatch(getAgreementsSuccessAction(res.data.docs, res.data.pages, res.data.total));
      })
      .catch(() => {
        dispatch(getAgreementsFailureAction(''));
      });
  };
}

export {
  clearAgreementsListActionType,
  getAgreementsActionType,
  getAgreementsFailureActionType,
  getAgreementsSuccessActionType,
  companyDetailsGetAgreements,
  clearAgreementsList,
};
