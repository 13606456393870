import { PACKAGES } from 'shared/helpers/constants/packages/packageConstants';

const packageContents = pkg => {
  const { unitCount, weightGrossDeclared, weightGrossActual } = pkg;

  if (!unitCount && !weightGrossDeclared && !weightGrossActual) {
    return '-';
  }
  return pkg.unitCount
    ? `${unitCount} ${PACKAGES.UNITS}`
    : `${weightGrossDeclared || weightGrossActual} ${PACKAGES.LBS}`;
};

export { packageContents };
