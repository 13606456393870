import React, { Component } from 'react';

import { ListCollapse } from 'shared/components/ListCollapse/ListCollapse';
import { OverviewList } from 'shared/components/OverviewList/OverviewList';
import {
  OverviewListSection,
  OverviewListSectionWrapper,
} from 'shared/components/OverviewList/OverviewListSection/OverviewListSection';
import { isTablet } from 'shared/helpers/constants/resolutionsConstants';
import {
  itemsTemplate,
  itemsTemplateMobile,
  itemsDetailsTemplate,
} from 'shared/helpers/constants/selfDeliveryRequests/selfDeliveriesRequestsListDasboardConfig';
import { SELF_DELIVERY_REQUESTS } from 'shared/helpers/constants/selfDeliveryRequests/selfDeliveryRequestsConstants';

import dateAdapter from 'utils/date/dateAdapter';
import { getBrowserWidth } from 'utils/responsive';

class SelfDeliveryRequestsList extends Component {
  state = {
    order: {
      deliveryDate: 1,
      unitCount: -1,
    },
    windowWidth: getBrowserWidth(),
    displayContent: true,
  };

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
    this.props.clearSelfDeliveryRequestsList();
  }

  handleWindowSizeChange = () => {
    this.setState({
      windowWidth: getBrowserWidth(),
    });
  };

  toggleContent = () => {
    this.setState(prevState => ({ displayContent: !prevState.displayContent }));
  };

  get tableActions() {
    const {
      history,
      auth: {
        access: { selfDeliveryRequests },
      },
    } = this.props;

    const actions = [];

    if (selfDeliveryRequests.details.details) {
      actions.push({
        icon: 'arrow-right',
        action: 'Details',
        onClick: thisSelfDeliveryRequest => () => {
          history.push(`/shipments/self-delivery-requests/${thisSelfDeliveryRequest._id}`);
        },
      });
    }
    return actions;
  }

  getSelfDeliveryRequests = (page, limit) => {
    const {
      getSelfDeliveryRequestsList,
      selfDeliveryRequestsFilters,
      auth: { user },
    } = this.props;

    getSelfDeliveryRequestsList(
      limit,
      page,
      {
        ...selfDeliveryRequestsFilters,
        deliveryDate: {
          from: dateAdapter().startOf('d').valueOf(),
          to: 0,
        },
      },
      user,
      this.state.order,
    );
  };

  render() {
    const { selfDeliveryRequestsList, clearSelfDeliveryRequestsList } = this.props;
    const isMobile = this.state.windowWidth < isTablet;

    return (
      <OverviewListSectionWrapper
        isOpen={this.state.displayContent}
        total={selfDeliveryRequestsList.total}
      >
        <OverviewListSection
          isOpen={this.state.displayContent}
          total={selfDeliveryRequestsList.total}
        >
          <div>
            <ListCollapse
              collapse={this.state.displayContent}
              clickable={!!selfDeliveryRequestsList.total}
              toggleList={this.toggleContent}
              label={SELF_DELIVERY_REQUESTS.UPCOMING_DELIVERIES}
            />
          </div>
        </OverviewListSection>
        <div>
          <OverviewList
            getList={this.getSelfDeliveryRequests}
            cleatData={clearSelfDeliveryRequestsList}
            input={{
              items: { docs: selfDeliveryRequestsList.selfDeliveryRequests },
              isPending: selfDeliveryRequestsList.isPending,
              pages: selfDeliveryRequestsList.pages,
            }}
            itemsTemplate={itemsTemplate}
            actionsTemplate={this.tableActions}
            itemsTemplateMobile={itemsTemplateMobile}
            itemDetailsTemplate={itemsDetailsTemplate}
            isMobile={isMobile}
            actionContext={this}
            label={SELF_DELIVERY_REQUESTS.UPCOMING_DELIVERIES}
          />
        </div>
      </OverviewListSectionWrapper>
    );
  }
}

export { SelfDeliveryRequestsList };
