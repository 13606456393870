const statusesForGlobal = {
  not_received: 'not_received',
  partly_received: 'partly_received',
  received: 'received',
  accepted_for_grading: 'accepted_for_grading',
  grading_completed: 'grading_completed',
  ready_for_processing: 'ready_for_processing',
  accepted_for_processing: 'accepted_for_processing',
  ready_for_mixing: 'ready_for_mixing',
  accepted_for_mixing: 'accepted_for_mixing',
  ready_to_ship: 'ready_to_ship',
  shipped_from_global: 'shipped_from_global',
  assay_results_received: 'assay_results_received',
  ship_summary_generated: 'ship_summary_generated',
  ship_summary_accepted: 'ship_summary_accepted',
  invoice_generated: 'invoice_generated',
  invoice_accepted: 'invoice_accepted',
  finalized: 'finalized',
};

const allowedStatusesForGlobal = [
  statusesForGlobal.not_received,
  statusesForGlobal.partly_received,
  statusesForGlobal.received,
];

const availableStatusesForGlobal = {
  all: Object.values(statusesForGlobal),
  lotCreate: [
    statusesForGlobal.received,
    statusesForGlobal.ready_for_mixing,
    statusesForGlobal.ready_to_ship,
  ],
};

const allowedStatusesForCreateGlobalLot = [
  statusesForGlobal.not_received,
  statusesForGlobal.received,
];

const lotsAwaitingResults = [
  statusesForGlobal.ship_summary_generated,
  statusesForGlobal.assay_results_received,
  statusesForGlobal.shipped_from_global,
  statusesForGlobal.ready_to_ship,
  statusesForGlobal.accepted_for_mixing,
  statusesForGlobal.ready_for_mixing,
  statusesForGlobal.accepted_for_processing,
  statusesForGlobal.ready_for_processing,
  statusesForGlobal.grading_completed,
  statusesForGlobal.accepted_for_grading,
  statusesForGlobal.received,
];

export {
  statusesForGlobal,
  allowedStatusesForGlobal,
  availableStatusesForGlobal,
  allowedStatusesForCreateGlobalLot,
  lotsAwaitingResults,
};
