import {
  inviteUserActionType,
  inviteUserFailureActionType,
  inviteUserSuccessActionType,
} from 'actions/Users/inviteUser';

const INITIAL_STATE = {
  isPending: false,
};

const inviteUserReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case inviteUserActionType:
      return {
        isPending: true,
      };
    case inviteUserSuccessActionType:
    case inviteUserFailureActionType:
      return {
        isPending: false,
      };
    default:
      return state;
  }
};

export { inviteUserReducer };
