import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  getMarketIndicatorsLogs,
  clearMarketIndicatorsLogs,
} from 'actions/MarketIndicators/getMarketIndicatorsLogs';

import { LinkButton } from 'shared/components/Buttons/LinkButton/LinkButton';
import { OverviewListWrapper } from 'shared/components/OverviewListWrapper/OverviewListWrapper';
import { roles } from 'shared/helpers/constants/filters/userTypes';
import { MARKET_INDICATORS } from 'shared/helpers/constants/systemSettings/marketIndicators/constants';
import {
  MARKET_INDICATORS_LOGS,
  eventTypes,
} from 'shared/helpers/constants/systemSettings/marketIndicators/marketIndicatorsLogs';
import { dateWithShortTimeFormat } from 'shared/helpers/parsers/date';
import { formatToDollarWithPrefix } from 'shared/helpers/parsers/formaters';
import { humanize, replaceEmpty } from 'shared/helpers/parsers/text';
import { cssVar } from 'shared/helpers/styling/styling';

import dateAdapter from 'utils/date/dateAdapter';
import { history } from 'utils/history';

const templates = {
  eventType: {
    label: MARKET_INDICATORS_LOGS.EVENT,
    render: ({ eventType }) => humanize(eventType),
  },
  indicatorType: {
    label: MARKET_INDICATORS_LOGS.TYPE,
    render: ({ indicatorType }) => indicatorType.map(type => <div>{humanize(type)}</div>),
  },
  createdAt: {
    label: MARKET_INDICATORS_LOGS.DATE_TIME,
    render: ({ createdAt }) => dateAdapter(createdAt).format(dateWithShortTimeFormat),
  },
  createdBy: {
    label: MARKET_INDICATORS_LOGS.USER,
    render: ({ eventType, createdBy: { _id, firstName, lastName } }, index, { userType }) => {
      const userName = `${firstName} ${lastName}`;

      return userType !== roles.support.name || eventType === eventTypes.request ? (
        <LinkButton
          label={userName}
          onClick={() => history.push(`/users/${_id}`)}
          bgColor={cssVar('riverBedLegend')}
          outlineColor={cssVar('riverBedLegend')}
          margin="0 10px 0 0"
        />
      ) : (
        userName
      );
    },
  },
  prices: {
    label: MARKET_INDICATORS_LOGS.PRICES,
    render: ({ platinumPrice, palladiumPrice, rhodiumPrice }) => {
      const platinum = replaceEmpty(platinumPrice && formatToDollarWithPrefix(platinumPrice));
      const palladium = replaceEmpty(palladiumPrice && formatToDollarWithPrefix(palladiumPrice));
      const rhodium = replaceEmpty(rhodiumPrice && formatToDollarWithPrefix(rhodiumPrice));

      return (
        <>
          <div>
            {MARKET_INDICATORS_LOGS.PT} {platinum}
          </div>
          <div>
            {MARKET_INDICATORS_LOGS.PD} {palladium}
          </div>
          <div>
            {MARKET_INDICATORS_LOGS.RH} {rhodium}
          </div>
        </>
      );
    },
  },
};

const itemsTemplate = [
  templates.eventType,
  templates.indicatorType,
  templates.createdAt,
  templates.createdBy,
  templates.prices,
];

const itemsTemplateMobile = [templates.eventType, templates.indicatorType, templates.createdBy];

const itemDetailsTemplate = [templates.createdAt, templates.prices];

const actionsTemplate = [];

function MarketIndicatorsLogs() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getMarketIndicatorsLogs());

    return () => dispatch(clearMarketIndicatorsLogs());
  }, [dispatch]);

  const user = useSelector(({ auth }) => auth.user);
  const { logs, isPending } = useSelector(state => state.getMarketIndicatorsLogs);

  const logsList = useMemo(
    () => ({
      items: {
        docs: logs,
        isPending,
        total: logs && logs.length,
      },
    }),
    [logs, isPending],
  );

  return (
    <>
      <h1>{MARKET_INDICATORS.MARKET_INDICATORS_LOG}</h1>
      <OverviewListWrapper
        label={MARKET_INDICATORS_LOGS.LOG}
        list={logsList}
        itemsTemplate={itemsTemplate}
        actionsTemplate={actionsTemplate}
        itemsTemplateMobile={itemsTemplateMobile}
        itemDetailsTemplate={itemDetailsTemplate}
        templateProps={user}
        logs
      />
    </>
  );
}

export { MarketIndicatorsLogs };
