import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { getPackagesUnsentForShipments } from 'actions/Packages/packagesOverviewList';
import { shipmentPackagesList } from 'actions/Shipments/shipmentPackagesList';

import { Checkbox, CheckboxWrapper } from 'shared/components/Fields/FieldCheckbox/FieldCheckbox';

function PackagesSelectActionsLayout({ className, pkg, ...props }) {
  const onClick = useCallback(e => {
    e.stopPropagation();
  }, []);

  const addPackageToList = packageId => {
    const packages = props.packagesList;

    props.shipmentPackagesList([...packages, packageId]);
  };

  const removePackageFromList = packageId => {
    const packages = props.packagesList.filter(id => id !== packageId);

    props.shipmentPackagesList(packages);
  };

  const checkElement = ({ currentTarget }) => {
    currentTarget.checked
      ? addPackageToList(currentTarget.id)
      : removePackageFromList(currentTarget.id);
  };

  const checkIsSelected = () => props.packagesList.includes((pkg?.newPackageNumber || pkg.packageNumber)) || props.packagesList.includes((pkg._id)) ? 'checked' : '';

  return (
    <Checkbox
      id={pkg._id}
      input={{ checked: checkIsSelected() }}
      checked={checkIsSelected()}
      onChange={checkElement}
      onClick={onClick}
      className={className}
    />
  );
}

const PackagesSelectActionsBase = styled(PackagesSelectActionsLayout)`
  display: flex;
  align-items: center;
  cursor: pointer;

  ${CheckboxWrapper} {
    padding-left: 10px;
  }
`;

const mapStateToProps = state => ({
  packagesList: state.shipmentPackagesListData.packages,
  shipment: state.shipmentDetails.shipment,
});

const mapDispatchToProps = {
  getPackagesUnsentForShipments,
  shipmentPackagesList,
};

const PackagesSelectActions = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PackagesSelectActionsBase);

export { PackagesSelectActionsBase, PackagesSelectActions };
