import React from 'react';

import { StatusIndicator } from 'shared/components/StatusIndicator/StatusIndicator';
import { formatDate, formatToDollarWithPrefix } from 'shared/helpers/parsers/formaters';
import { replaceEmpty, humanize } from 'shared/helpers/parsers/text';

import { PAYMENTS } from './paymentsConstants';

const itemsTemplateCustomer = [
  {
    label: PAYMENTS.STATUS,
    render: ({ status }) => <StatusIndicator status={status} />,
  },
  {
    label: PAYMENTS.RELATED_LOT,
    render: ({ relatedLot }) => replaceEmpty(relatedLot && relatedLot.grgLotNumber),
    highlight: true,
  },
  {
    label: PAYMENTS.PAYMENT_NUMBER,
    render: ({ paymentNumber }) => replaceEmpty(paymentNumber),
  },
  {
    label: PAYMENTS.PAID_ON,
    render: ({ paidAt }) => replaceEmpty(formatDate(paidAt)),
  },

  {
    label: PAYMENTS.PAYMENT_AMOUNT,
    render: ({ paymentAmount }) => formatToDollarWithPrefix(paymentAmount),
  },
  {
    label: PAYMENTS.FUNDS_TYPE,
    render: ({ fundsType }) => humanize(fundsType),
  },
  {
    label: PAYMENTS.PAYMENT_TYPE,
    render: ({ paymentType }) => humanize(paymentType),
  },
];

const itemsTemplate = [
  {
    label: PAYMENTS.STATUS,
    render: ({ status }) => <StatusIndicator status={status} />,
  },
  {
    label: PAYMENTS.RELATED_COMPANY,
    render: ({ relatedCompany }) => relatedCompany.companyName,
    highlight: true,
  },
  {
    label: PAYMENTS.RELATED_LOT,
    render: ({ relatedLot }) => replaceEmpty(relatedLot && relatedLot.grgLotNumber),
    highlight: true,
  },
  {
    label: PAYMENTS.PAYMENT_NUMBER,
    render: ({ paymentNumber }) => replaceEmpty(paymentNumber),
  },
  {
    label: PAYMENTS.PAID_ON,
    render: ({ paidAt }) => replaceEmpty(formatDate(paidAt)),
  },
  {
    label: PAYMENTS.PAYMENT_AMOUNT,
    render: ({ paymentAmount }) => formatToDollarWithPrefix(paymentAmount),
  },
  {
    label: PAYMENTS.FUNDS_TYPE,
    render: ({ fundsType }) => humanize(fundsType),
  },
  {
    label: PAYMENTS.PAYMENT_TYPE,
    render: ({ paymentType }) => humanize(paymentType),
  },
];

const itemsTemplateMobile = [
  {
    label: PAYMENTS.STATUS,
    render: ({ status }) => <StatusIndicator status={status} />,
  },
  {
    label: PAYMENTS.RELATED_LOT,
    render: ({ relatedLot }) =>
      replaceEmpty(relatedLot && (relatedLot.customLotNumber || relatedLot.grgLotNumber)),
    highlight: true,
  },
];

const itemsDetailsTemplate = [
  {
    label: PAYMENTS.RELATED_COMPANY,
    render: ({ relatedCompany }) => relatedCompany.companyName,
    highlight: true,
  },
  {
    label: PAYMENTS.PAYMENT_NUMBER,
    render: ({ paymentNumber }) => replaceEmpty(paymentNumber),
  },
  {
    label: PAYMENTS.PAID_ON,
    render: ({ paidAt }) => replaceEmpty(formatDate(paidAt)),
  },
  {
    label: PAYMENTS.PAYMENT_AMOUNT,
    render: ({ paymentAmount }) => formatToDollarWithPrefix(paymentAmount),
  },
  {
    label: PAYMENTS.FUNDS_TYPE,
    render: ({ fundsType }) => humanize(fundsType),
  },
  {
    label: PAYMENTS.PAYMENT_TYPE,
    render: ({ paymentType }) => humanize(paymentType),
  },
];

const [, , ...itemsDetailsTemplateCustomer] = itemsTemplateCustomer;

export {
  itemsTemplate,
  itemsTemplateCustomer,
  itemsTemplateMobile,
  itemsDetailsTemplate,
  itemsDetailsTemplateCustomer,
};
