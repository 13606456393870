import { packageStatuses } from 'shared/helpers/constants/packages/packageType';
import { isCustomerUser } from 'shared/helpers/matchers/checkUserType';
import { dateToTimestamp } from 'shared/helpers/parsers/date';
import { normalizeFormValues } from 'shared/helpers/parsers/formNormalizer';
import { safeReplace } from 'shared/helpers/parsers/text';

const parsePackingListPackages = chosenPackages => pkg => {
  const data = {
    assignedLot: pkg.assignedLot,
    packageType: pkg.packageType,
    customerInstructions: pkg.customerInstructions,
    materialTypeDeclared: pkg.materialTypeDeclared,
    packageNumber: pkg.packageNumber,
  };

  if (pkg.unitCount) {
    data.unitCount = parseInt(pkg.unitCount, 10);
  }

  if (pkg.weightGrossDeclared === 0 || pkg.weightGrossDeclared) {
    data.weightGrossDeclared = parseFloat(safeReplace(pkg.weightGrossDeclared, ',', '.'));
  }

  if (pkg.weightTareDeclared === 0 || pkg.weightTareDeclared) {
    data.weightTareDeclared = parseFloat(safeReplace(pkg.weightTareDeclared, ',', '.'));
  }

  if (pkg.weightGrossActual) {
    data.weightGrossActual = parseFloat(safeReplace(pkg.weightGrossActual, ',', '.'));
  }

  if (pkg.weightTareActual) {
    data.weightTareActual = parseFloat(safeReplace(pkg.weightTareActual, ',', '.'));
  }

  if (pkg.materialClass) {
    data.materialClass = pkg.materialClass;
  }

  if (pkg.customLotNumber) {
    data.customLotNumber = pkg.customLotNumber;
  }

  if (pkg.labelId) {
    data.labelId = pkg.labelId;
  }

  if (pkg.packageCountIncoming) {
    data.packageCountIncoming = pkg.packageCountIncoming;
  }

  if (pkg.placeholder) {
    data.placeholder = pkg.placeholder;
  }

  if (pkg.plant) {
    data.plant = pkg.plant;
  }

  if (pkg.status) {
    data.status = pkg.status;
  }

  if (pkg._id) {
    data._id = pkg._id;
  }

  return normalizeFormValues({
    ...data,
    packageType: data.packageType?.value,
    selected: chosenPackages.includes(data.packageNumber?.toString()),
  });
};

const removeTemporaryFields = ({
  status,
  packageNumber,
  placeholder,
  edited,
  newPackageNumber,
  plant,
  labelId,
  customerInstructions,
  ...pkgValues
}) => {
  return {
    ...pkgValues,
    plant: plant || null,
    labelId: labelId || null,
    customerInstructions: customerInstructions || null,
  };
};

const selectPackages = (packages, chosenPackages) =>
  packages.map(parsePackingListPackages(chosenPackages)).filter(pkg => !pkg.placeholder);

const parseCreateShipment = (createShipment, userType, { materialTypeDeclared }) => {
  const companySource =
    createShipment[isCustomerUser(userType) ? 'relatedCompany' : 'sentFromCompany'];

  return {
    sentFromCompany: companySource?.value || companySource,
    sentFromCompanyLocation:
      createShipment.sentFromCompanyLocation.value || createShipment.sentFromCompanyLocation,
    estPickupDate: dateToTimestamp(createShipment.estPickupDate),
    estDeliveryDate: dateToTimestamp(createShipment.estDeliveryDate),
    billOfLading: createShipment.billOfLading || null,
    purchaseOrder: createShipment.purchaseOrder || null,
    truckingCompany: createShipment.truckingCompany || null,
    trailer: createShipment.trailer || null,
    shipmentSeal: createShipment.shipmentSeal || null,
    contactNumber: createShipment.contactNumber,
    contactName: createShipment.contactName.value || createShipment.contactName,
    materialTypeDeclared: materialTypeDeclared?.value,
  };
};

const parseShipmentPackingListData = (
  { defaultPackagesValues, packages, createShipment, generatePackingListValues },
  userType,
  chosenPackages = [],
) => ({
  defaultPackagesValues: defaultPackagesValues,
  packages: packages.map(parsePackingListPackages(chosenPackages)).map(removeTemporaryFields),
  createShipment: parseCreateShipment(createShipment, userType, defaultPackagesValues),
  generatePackingListValues,
});

const parseShipmentUpdatePackingListData = (
  { defaultPackagesValues, packages, createShipment, generatePackingListValues },
  userType,
  chosenPackages = [],
) => {
  const selectedPackages = selectPackages(packages, chosenPackages);

  const packagesToUpdate = [];
  const packagesToCreate = [];
  const packagesToDelete = [];

  selectedPackages.forEach(({ packageCountIncoming, ...pkg }) => {
    const { status, _id } = pkg;
    if (status === packageStatuses.deleted && _id) {
      return packagesToDelete.push(pkg);
    }
    if (status !== packageStatuses.deleted && _id) {
      return packagesToUpdate.push(pkg);
    }

    return packagesToCreate.push(pkg);
  });

  return {
    packagesToUpdate: packagesToUpdate.map(removeTemporaryFields),
    packagesToCreate: packagesToCreate.map(removeTemporaryFields),
    packagesToDelete,
    createShipment:
      createShipment && parseCreateShipment(createShipment, userType, defaultPackagesValues),
    generatePackingListValues,
  };
};

const parsePackingListResponseData = ({ files }) => ({
  files,
});

export {
  parseShipmentPackingListData,
  parsePackingListResponseData,
  parseShipmentUpdatePackingListData,
  parsePackingListPackages,
};
