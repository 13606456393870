import { PropTypes } from 'prop-types';
import styled from 'styled-components';

import { cssVar, pixelify } from 'shared/helpers/styling/styling';

const DoughnutProgress = styled.div`
  position: relative;
  width: ${({ size }) => pixelify(size)};
  height: ${({ size }) => pixelify(size)};
  border-radius: 50%;
  background: ${({ progress, empty, foreground }) => cssVar(progress < 0.5 ? empty : foreground)};
  background-image: linear-gradient(
    to ${({ progress }) => (progress < 0.5 ? 'right' : 'left')},
    transparent 50%,
    ${({ progress, empty, foreground }) => cssVar(progress < 0.5 ? foreground : empty)} 0
  );

  &::before {
    content: '';
    display: block;
    margin-left: 50%;
    height: 100%;
    border-radius: 0 100% 100% 0 / 50%;
    background-color: inherit;
    transform-origin: left;
    transform: rotate(${({ progress }) => (progress < 0.5 ? progress : progress - 0.5)}turn);
  }

  &::after {
    display: flex;
    content: '${({ progress }) => Math.ceil(progress * 100)}\\0025';
    position: absolute;
    top: 11px;
    left: 11px;
    background: ${({ background }) => cssVar(background)};
    color: ${({ color }) => cssVar(color)};
    width: calc(100% - 22px);
    height: calc(100% - 22px);
    border-radius: 50%;
    font-size: 1.4rem;
    align-items: center;
    justify-content: space-around;
  }
`;

DoughnutProgress.propTypes = {
  progress: PropTypes.number.isRequired,
  size: PropTypes.number,
  background: PropTypes.string,
  foreground: PropTypes.string,
  color: PropTypes.string,
};

DoughnutProgress.defaultProps = {
  background: 'outerSpaceTabs',
  foreground: 'atlantis',
  empty: 'fiord',
  color: 'regentGrayLighter',
  size: 170,
};

export { DoughnutProgress };
