import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Field, formValueSelector } from 'redux-form';

import { getBankHolidays } from 'actions/Payments/bankHolidaysList';

import { IconButton } from 'shared/components/Buttons/IconButton/IconButton';
import { FieldHint, FieldSearch, FieldDate, FieldInput } from 'shared/components/Fields';
import { FieldButtonSelect } from 'shared/components/Fields/FieldButtonSelect/FieldButtonSelect';
import { FieldCheckbox } from 'shared/components/Fields/FieldCheckbox/FieldCheckbox';
import { FieldSelect } from 'shared/components/Fields/FieldSelect/FieldSelect';
import { Section } from 'shared/components/Form';
import { MultipleFieldsSection } from 'shared/components/Form/MultipleFieldsSection/MultipleFieldsSection';
import { availableFundsTypes } from 'shared/helpers/constants/payments/fundsTypes';
import { PAYMENTS } from 'shared/helpers/constants/payments/paymentsConstants';
import { POOL_ACCOUNTS } from 'shared/helpers/constants/poolAccounts/poolAccountsConstants';
import { SHARED } from 'shared/helpers/constants/sharedConstants';
import { calculateMinimalFinalPaymentDelay } from 'shared/helpers/dataParsers/payments/calculateMinimalFinalPaymentDelay';
import { getBankHolidaysDates } from 'shared/helpers/dataParsers/payments/parseBankHolidaysData';
import { isAssayCompany } from 'shared/helpers/matchers/checkCompanyType';
import { isCustomerUser } from 'shared/helpers/matchers/checkUserType';
import { isGlobalUserType } from 'shared/helpers/matchers/isGlobalUserType';
import { mapArray } from 'shared/helpers/parsers/select';
import { cssVar } from 'shared/helpers/styling/styling';

const selector = formValueSelector('RequestSettlementForm');

const OneSettlementForm = ({ settlement, fieldsNumber, index, ...props }) => {
  const relatedLot = useSelector(state => selector(state, `${settlement}.relatedLot`));
  const dispatch = useDispatch();

  const lotsSelector = () => {
    const lots = [];
    for (let i = 0; i < fieldsNumber; i++) {
      lots.push(`settlements[${i}].relatedLot`);
    }

    return lots;
  };

  const chosenLots = useSelector(state => selector(state, ...lotsSelector()));

  const wasFinalPaymentRequested =
    props.maximumSettlementOunces.settlementOunces?.[relatedLot?.value]?.wasFinalPaymentRequested;
  const requestPayment = useSelector(state => selector(state, `${settlement}.requestPayment`));
  const relatedCompany = useSelector(state => selector(state, `${settlement}.relatedCompany`));
  const { filters, bankHolidays } = useSelector(state => state.bankHolidaysList);

  useEffect(() => {
    const {
      match,
      summaryAvailableLots,
      location: { state: locationState },
      change,
    } = props;

    if (summaryAvailableLots && summaryAvailableLots.length) {
      const settleSingleLot = index === 0 && !relatedLot && match.params.id;
      const settleMultiple = summaryAvailableLots.length === 1;
      const lotToSettle = match.params.id || summaryAvailableLots[0].value;

      if (settleSingleLot) setLotName(lotToSettle) && maximumSettlementOunces(lotToSettle);
      else if (settleMultiple) setLotName(lotToSettle);
    }

    if (locationState && locationState.requestPayment) {
      change(`${settlement}.requestPayment`, locationState.requestPayment);
      change(`${settlement}.relatedLot`, {
        label: locationState.relatedLot.label,
        value: locationState.relatedLot.value,
      });
      change(`${settlement}.fundsType`, {
        label: locationState.fundsType.label,
        value: locationState.fundsType.value,
      });
      change(`${settlement}.requestedFinalPaymentDate`, locationState.requestedFinalPaymentDate);
      change(`${settlement}.additionalInfo`, locationState.additionalInfo);
      maximumSettlementOunces(locationState.relatedLot.value);
    }
  }, [props.summaryAvailableLots]);

  useEffect(() => {
    getSuggested();
    dispatch(getBankHolidays(filters));
  }, [relatedLot, dispatch]);

  const clearInputs = () => {
    const { change } = props;

    change(`${settlement}.platinumOz`, '');
    change(`${settlement}.palladiumOz`, '');
    change(`${settlement}.rhodiumOz`, '');
    change(`${settlement}.requestedBy`, null);
    change(`${settlement}.requestPayment`, false);
    props.resetSection(`${settlement}.fundsType`);
    props.resetSection(`${settlement}.requestedFinalPaymentDate`);
    props.resetSection(`${settlement}.additionalInfo`);
  };

  const setLotName = lotId => {
    const lotToSelect = props.summaryAvailableLots.find(lot => lot.value === lotId);
    return props.change(`${settlement}.relatedLot`, lotToSelect);
  };

  const maximumSettlementOunces = value => {
    value &&
      props
        .getMaximumSettlementOunces(value)
        .then(({ maximumPlatinumOz, maximumPalladiumOz, maximumRhodiumOz }) => {
          props.change(`${settlement}.platinumOz`, maximumPlatinumOz);
          props.change(`${settlement}.palladiumOz`, maximumPalladiumOz);
          props.change(`${settlement}.rhodiumOz`, maximumRhodiumOz);
        })
        .catch(() => {
          clearInputs();
        });
  };

  const getSuggestedValues = useCallback((ref, newOption, prevOption) => {
    !ref && prevOption && props.clearMaximumSettlementOuncesForLot(prevOption.value);

    ref && maximumSettlementOunces(ref.value);

    clearInputs();
  }, []);

  const getSuggested = () => {
    const {
      auth: {
        user: { userType },
      },
      metalPool,
      poolAccountsList,
      maximumSettlementOunces: { settlementOunces },
    } = props;

    if (isGlobalUserType(userType) && relatedCompany) {
      return {
        platinum: relatedCompany.platinumOz,
        palladium: relatedCompany.palladiumOz,
        rhodium: relatedCompany.rhodiumOz,
      };
    }

    if (metalPool && poolAccountsList && poolAccountsList.poolAccounts.docs) {
      return {
        platinum: poolAccountsList.poolAccounts.docs[0].platinumOz,
        palladium: poolAccountsList.poolAccounts.docs[0].palladiumOz,
        rhodium: poolAccountsList.poolAccounts.docs[0].rhodiumOz,
      };
    }

    const { maximumPlatinumOz, maximumPalladiumOz, maximumRhodiumOz } =
      (settlementOunces && settlementOunces[relatedLot && relatedLot.value]) || false;

    return {
      platinum: maximumPlatinumOz,
      palladium: maximumPalladiumOz,
      rhodium: maximumRhodiumOz,
    };
  };

  const changeRequestPayment = () => {
    const {
      change,
      maximumSettlementOunces: { settlementOunces },
    } = props;

    const { maximumPlatinumOz, maximumPalladiumOz, maximumRhodiumOz } =
      (settlementOunces && settlementOunces[relatedLot && relatedLot.value]) || false;

    change(`${settlement}.requestPayment`, !requestPayment);
    change(`${settlement}.platinumOz`, maximumPlatinumOz);
    change(`${settlement}.palladiumOz`, maximumPalladiumOz);
    change(`${settlement}.rhodiumOz`, maximumRhodiumOz);
  };

  const actions = props.onRemove && (
    <IconButton onClick={props.onRemove} icon="icon-cancel" color={cssVar('whiteGRG')} />
  );

  const filterLots = () => {
    if ((index === 0 && fieldsNumber === 1) || !chosenLots.settlements)
      return props.summaryAvailableLots;

    const forbiddenLots = chosenLots.settlements.map(
      ({ relatedLot }) => relatedLot && relatedLot.value,
    );

    return props.summaryAvailableLots.filter(({ value }) => !forbiddenLots.includes(value));
  };

  const suggestions = getSuggested();
  const ouncesFields = [
    {
      component: FieldHint,
      name: `${settlement}.platinumOz`,
      field: 'required',
      suggestion: suggestions.platinum,
      disabled: requestPayment || suggestions.platinum <= 0,
      label: POOL_ACCOUNTS.PLATINUM_OZ_TO_SETTLE,
      hint: SHARED.MAXIMUM,
    },
    {
      component: FieldHint,
      name: `${settlement}.palladiumOz`,
      field: 'required',
      suggestion: suggestions.palladium,
      disabled: requestPayment || suggestions.palladium <= 0,
      label: POOL_ACCOUNTS.PALLADIUM_OZ_TO_SETTLE,
      hint: SHARED.MAXIMUM,
    },
    {
      component: FieldHint,
      name: `${settlement}.rhodiumOz`,
      field: 'required',
      suggestion: suggestions.rhodium,
      disabled: requestPayment || suggestions.rhodium <= 0,
      label: POOL_ACCOUNTS.RHODIUM_OZ_TO_SETTLE,
      hint: SHARED.MAXIMUM,
    },
  ];

  return (
    <>
      <Section
        header={props.header}
        actions={actions}
        template={[
          `${settlement}.relatedLot ${settlement}.requestPayment`,
          `${settlement}.relatedCompany .`,
        ]}
      >
        {!props.metalPool && (
          <Field
            name={`${settlement}.relatedLot`}
            component={FieldSelect}
            options={filterLots()}
            label={POOL_ACCOUNTS.RELATED_LOT}
            onChange={getSuggestedValues}
            clearable={props.summaryAvailableLots.length > 1}
            field="required"
          />
        )}
        {isCustomerUser(props.auth.user.userType) &&
          isAssayCompany(props.auth.user.relatedCompany.companyType) &&
          props.customerRequestSettlement &&
          !wasFinalPaymentRequested && (
            <Field
              name={`${settlement}.requestPayment`}
              component={FieldCheckbox}
              label={POOL_ACCOUNTS.REQUEST_PAYMENT}
              checked={requestPayment}
              onClick={changeRequestPayment}
            />
          )}
        {props.metalPool && !isCustomerUser(props.auth.user.userType) && (
          <Field
            name={`${settlement}.relatedCompany`}
            component={FieldSearch}
            label={POOL_ACCOUNTS.RELATED_COMPANY}
            field="required"
            getOptions={props.listSentFromCompanies}
          />
        )}
      </Section>
      <Section
        header={POOL_ACCOUNTS.OUNCES}
        template={[`${settlement}.ouncesFields ${settlement}.ouncesFields`]}
        noPadding
      >
        <MultipleFieldsSection name={`${settlement}.ouncesFields`} fields={ouncesFields} />
      </Section>
      {requestPayment && (
        <Section
          header={POOL_ACCOUNTS.REQUEST_PAYMENT}
          template={[
            `${settlement}.fundsType ${settlement}.requestedFinalPaymentDate`,
            `${settlement}.additionalInfo .`,
          ]}
        >
          <Field
            name={`${settlement}.fundsType`}
            component={FieldButtonSelect}
            options={mapArray(availableFundsTypes.assayInternal)}
            label={PAYMENTS.FUNDS_TYPE}
            field="required"
          />
          <Field
            name={`${settlement}.requestedFinalPaymentDate`}
            component={FieldDate}
            label={PAYMENTS.REQUESTED_FINAL_PAYMENT_DATE}
            minDate={calculateMinimalFinalPaymentDelay(isCustomerUser(props.auth.user.userType))}
            field="required"
            excludeDates={getBankHolidaysDates(bankHolidays)}
            onlyWeekdays
          />
          <Field
            name={`${settlement}.additionalInfo`}
            component={FieldInput}
            label={PAYMENTS.ADDITIONAL_INFORMATION}
            field="optional"
          />
        </Section>
      )}
    </>
  );
};

export { OneSettlementForm };
