import {
  getAvailableHedgesActionType,
  getAvailableHedgesSuccessActionType,
  getAvailableHedgesFailureActionType,
} from 'actions/Deliveries/getAvailableHedges';

const INITIAL_STATE = {
  isPending: false,
  hedges: null,
};

const getAvailableHedgesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getAvailableHedgesActionType:
      return {
        isPending: true,
      };
    case getAvailableHedgesSuccessActionType:
      return {
        isPending: false,
        hedges: action.payload.hedges,
      };
    case getAvailableHedgesFailureActionType:
      return {
        isPending: false,
        hedges: null,
      };
    default:
      return state;
  }
};

export { getAvailableHedgesReducer };
