import React, { useMemo, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import styled from 'styled-components';

import {
  getMarketIndicators as getMarketIndicatorsAction,
  clearMarketIndicators as clearMarketIndicatorsAction,
} from 'actions/MarketIndicators/getMarketIndicators';
import { lockMarketIndicators as lockMarketIndicatorsAction } from 'actions/MarketIndicators/lockMarketIndicators';
import { showSnackbar as showSnackbarAction } from 'actions/shared/snackbar';

import { UIAuthorization } from 'components/Authorization/UIAuthorization';

import { RouterButton } from 'shared/components/Buttons/RouterButton/RouterButton';
import { Icon } from 'shared/components/Icon/Icon';
import { OverviewListWrapper } from 'shared/components/OverviewListWrapper/OverviewListWrapper';
import { SHARED } from 'shared/helpers/constants/sharedConstants';
import { dangerOptions } from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { SYSTEM_SETTINGS } from 'shared/helpers/constants/systemSettings/constants';
import { MARKET_INDICATORS } from 'shared/helpers/constants/systemSettings/marketIndicators/constants';
import { dateWithShortTimeFormat } from 'shared/helpers/parsers/date';
import { formatToDollarWithPrefix } from 'shared/helpers/parsers/formaters';
import { humanize, replaceEmpty } from 'shared/helpers/parsers/text';
import { media } from 'shared/helpers/styling/styling';

import dateAdapter from 'utils/date/dateAdapter';

const H4 = styled.h4`
  ${media.phone`
    padding: 8px 22px;
    margin-bottom: 0;
  `}
`;

const formatPrices = (values, key) => {
  const value = values[key];

  return replaceEmpty(formatToDollarWithPrefix(value));
};

const templates = {
  type: {
    label: MARKET_INDICATORS.TYPE,
    render: ({ type }) => humanize(type),
  },
  platinum: {
    label: MARKET_INDICATORS.PLATINUM,
    render: ({ type, values }) => formatPrices(values, `${type}PlatinumPrice`),
  },
  palladium: {
    label: MARKET_INDICATORS.PALLADIUM,
    render: ({ type, values }) => formatPrices(values, `${type}PalladiumPrice`),
  },
  rhodium: {
    label: MARKET_INDICATORS.RHODIUM,
    render: ({ type, values }) => formatPrices(values, `${type}RhodiumPrice`),
  },
  updatedBy: {
    label: MARKET_INDICATORS.UPDATED_BY,
    render: values => {
      const updatedBy = values[`${values.type}UpdatedBy`];

      return replaceEmpty(updatedBy && `${updatedBy.firstName} ${updatedBy.lastName}`);
    },
  },
  lastUpdated: {
    label: MARKET_INDICATORS.LAST_UPDATED,
    render: values => {
      const updatedAt = values[`${values.type}UpdatedAt`];

      return replaceEmpty(updatedAt && dateAdapter(updatedAt).format(dateWithShortTimeFormat));
    },
  },
};

const itemsTemplate = [
  templates.type,
  templates.platinum,
  templates.palladium,
  templates.rhodium,
  templates.updatedBy,
  templates.lastUpdated,
];

const itemsTemplateMobile = [
  templates.type,
  templates.platinum,
  templates.palladium,
  templates.rhodium,
];

const itemDetailsTemplate = [templates.updatedBy, templates.lastUpdated];

function MarketIndicatorsBase({
  auth,
  showSnackbar,
  marketIndicators,
  getMarketIndicators,
  clearMarketIndicators,
  lockMarketIndicators,
  isPending,
  history,
}) {
  useEffect(() => () => clearMarketIndicators(), [clearMarketIndicators]);

  const normalizedMarketIndicators = useMemo(() => {
    const indicators = (marketIndicators && marketIndicators.indicators) || {};

    return {
      items: {
        docs: Object.entries(indicators).map(([key, values]) => ({
          type: key,
          values,
          hedgingUpdatedAt: marketIndicators.hedgingUpdatedAt,
          settlementUpdatedAt: marketIndicators.settlementUpdatedAt,
          hedgingUpdatedBy: marketIndicators.hedgingUpdatedBy,
          settlementUpdatedBy: marketIndicators.settlementUpdatedBy,
        })),
        total: Object.keys(indicators).length,
      },
      isPending,
    };
  }, [isPending, marketIndicators]);

  const onClick = useCallback(
    ({ type }) =>
      () => {
        lockMarketIndicators()
          .then(() => history.push(`/settings/market-indicators/update/${type}`))
          .catch(({ message }) => showSnackbar(dangerOptions, message));
      },
    [history, lockMarketIndicators, showSnackbar],
  );

  const actionButton = (
    <UIAuthorization access={auth.access.systemSettings.marketIndicators.viewLogs}>
      <RouterButton path="/settings/market-indicators/logs">
        {MARKET_INDICATORS.VIEW_LOG}
        <Icon icon="icon-tiny-arrow-right" />
      </RouterButton>
    </UIAuthorization>
  );

  const actionsTemplate = [
    {
      icon: 'edit',
      action: SHARED.EDIT,
      onClick,
    },
  ];

  return (
    <>
      <OverviewListWrapper
        label={MARKET_INDICATORS.CURRENT_INDICATORS_PRICES}
        list={normalizedMarketIndicators}
        getList={getMarketIndicators}
        itemsTemplate={itemsTemplate}
        actionsTemplate={actionsTemplate}
        itemsTemplateMobile={itemsTemplateMobile}
        itemDetailsTemplate={itemDetailsTemplate}
        actionsContext={auth}
        button={actionButton}
      />
      {marketIndicators && (
        <>
          <H4>
            {SYSTEM_SETTINGS.INDICATOR_REQUESTS_HEDGING}
            {': '}
            {marketIndicators.outstandingHedgingRequestsCount}
          </H4>
          <H4>
            {SYSTEM_SETTINGS.INDICATOR_REQUESTS_SETTLEMENT}
            {': '}
            {marketIndicators.outstandingSettlementRequestsCount}
          </H4>
        </>
      )}
    </>
  );
}

const mapStateToProps = ({ auth, getMarketIndicators, getPriceFeeds }) => ({
  auth,
  marketIndicators: getMarketIndicators.marketIndicators,
  isPending: getMarketIndicators.isPending && getPriceFeeds.isPending,
});

const mapDispatchToProps = {
  showSnackbar: showSnackbarAction,
  getMarketIndicators: getMarketIndicatorsAction,
  clearMarketIndicators: clearMarketIndicatorsAction,
  lockMarketIndicators: lockMarketIndicatorsAction,
};

const MarketIndicators = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
)(MarketIndicatorsBase);

export { MarketIndicatorsBase, MarketIndicators };
