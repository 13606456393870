import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getHedgeDetailsActionType = 'HEDGE_DETAILS';
const getHedgeDetailsAction = () => ({
  type: getHedgeDetailsActionType,
});

const getHedgeDetailsSuccessActionType = `${getHedgeDetailsActionType}_SUCCESS`;
const getHedgeDetailsSuccessAction = hedge => ({
  type: getHedgeDetailsSuccessActionType,
  payload: { hedge },
});

const getHedgeDetailsFailureActionType = `${getHedgeDetailsActionType}_FAILURE`;
const getHedgeDetailsFailureAction = () => ({
  type: getHedgeDetailsFailureActionType,
});

const clearHedgeDetailsActionType = `${getHedgeDetailsActionType}_CLEAR`;
const clearHedgeDetailsAction = () => ({
  type: clearHedgeDetailsActionType,
});

const getHedgeDetails = id => dispatch => {
  dispatch(getHedgeDetailsAction());

  return axios
    .get(`${API_HOST_AUTH}/hedges/${id}`)
    .then(({ data }) => {
      dispatch(getHedgeDetailsSuccessAction(data));
      return data;
    })
    .catch(error => {
      dispatch(getHedgeDetailsFailureAction());
      return Promise.reject(error.data);
    });
};

export {
  clearHedgeDetailsAction,
  clearHedgeDetailsActionType,
  getHedgeDetailsActionType,
  getHedgeDetailsSuccessActionType,
  getHedgeDetailsFailureActionType,
  getHedgeDetails,
};
