import PropTypes from 'prop-types';
import React, { useState, useCallback } from 'react';
import styled from 'styled-components';

import { themed } from 'shared/helpers/styling/styling';

import { Container } from './components/Container';
import { ErrorMessage } from './components/ErrorMessage';
import { FieldDescription } from './components/FieldDescription';
import { Hint } from './components/Hint';
import { Label } from './components/Label';
import { StyledHintContainer } from './components/StyledHintContainer';

const Content = styled.div`
  grid-area: input;
`;

const fieldTypes = {
  required: 'required',
  unique: 'unique',
};

const parseOptions = fieldOptions =>
  Object.entries(fieldTypes).reduce(
    (options, [key, value]) => ({
      ...options,
      [key]: fieldOptions.includes(value),
    }),
    {},
  );

const checkHintValue = hint => {
  return Array.isArray(hint)
    ? hint.some(hint => hint.value !== undefined && hint.value !== null && hint.value !== false)
    : hint.value !== undefined && hint.value !== null && hint.value !== false;
};

const FieldWrapper = ({
  className,
  label,
  id,
  field,
  disabled,
  labelWidth,
  hint = null,
  hintClickable = false,
  meta,
  input,
  slim,
  pretendMobile,
  lazyError = false,
  children,
  forceHint,
  withoutLabel = false,
  description = '',
  forceShowError = false,
}) => {
  const parsedField = typeof field === 'string' ? field : '';
  const fieldOptions = parsedField.split(' ');
  const parsedOptions = parseOptions(fieldOptions);
  const [blurred, setBlurred] = useState(false);
  const onBlur = useCallback(() => {
    setBlurred(true);
  }, []);

  const { error, touched, dirty, active } = meta || {};
  return (
    <Container
      className={className}
      labelWidth={labelWidth}
      slim={slim}
      pretendMobile={pretendMobile}
      withoutLabel={withoutLabel}
      hintLength={
        Array.isArray(hint) &&
        hint.reduce((acc, hint) => (checkHintValue(hint) ? [...acc, hint] : acc), []).length
      }
    >
      {!withoutLabel && (
        <>
          <Label
            id={id}
            {...parsedOptions}
            disabled={disabled}
            pretendMobile={pretendMobile}
            tooltipExists={!!description}
          >
            {!!description && <FieldDescription description={description} name={input?.name} />}
            {label}
          </Label>
        </>
      )}
      <Content onBlur={onBlur}>{children}</Content>
      <ErrorMessage
        {...parsedOptions}
        show={
          (touched || dirty) && (!active || blurred || !lazyError) && (!disabled || forceShowError)
        }
        label={label}
        labelWidth={labelWidth}
        pretendMobile={pretendMobile}
      >
        {error}
      </ErrorMessage>
      {((!disabled && hint && checkHintValue(hint)) || forceHint) && (
        <StyledHintContainer>
          {Array.isArray(hint) ? (
            hint.map(
              (h, index) =>
                checkHintValue(h) && (
                  <Hint
                    key={index}
                    input={input}
                    hint={{ label: h.label, value: h.value }}
                    isClickable={!disabled && hintClickable}
                    dataTestId={`${input?.name}-hint-${index}`}
                  />
                ),
            )
          ) : (
            <Hint
              input={input}
              hint={{ label: hint.label, value: hint.value }}
              isClickable={!disabled && hintClickable}
              dataTestId={`${input?.name}-hint`}
            />
          )}
        </StyledHintContainer>
      )}
    </Container>
  );
};

const FieldWrapperStyled = styled(FieldWrapper).attrs(themed(({ disabled }) => ({ disabled })))``;

FieldWrapperStyled.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  hint: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.any,
  }),
  hintClickable: PropTypes.bool,
  pretendMobile: PropTypes.bool,
  lazyError: PropTypes.bool,
  withoutLabel: PropTypes.bool,
};

const withFieldWrapper =
  (C, wrapperProps) =>
  ({ as: asComponent, ...props }) =>
    (
      <FieldWrapperStyled {...props} {...wrapperProps}>
        {asComponent ? <C {...props} as={asComponent} /> : <C {...props} />}
      </FieldWrapperStyled>
    );
export { FieldWrapperStyled as FieldWrapper, withFieldWrapper };
