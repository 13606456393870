import { hedgesChangeTypeSuccessActionType } from 'actions/Hedges/hedgesChangeType';
import {
  clearHedgeDetailsActionType,
  getHedgeDetailsActionType,
  getHedgeDetailsFailureActionType,
  getHedgeDetailsSuccessActionType,
} from 'actions/Hedges/hedgesDetails';
import { hedgeReassignSuccessActionType } from 'actions/Hedges/hedgesReassign';

const INITIAL_STATE = {
  isPending: false,
  hedge: null,
};

const hedgesDetailsReducer = (state = INITIAL_STATE, { payload, type }) => {
  switch (type) {
    case getHedgeDetailsActionType:
      return {
        ...state,
        isPending: true,
      };
    case getHedgeDetailsSuccessActionType:
      return {
        ...state,
        isPending: false,
        hedge: payload.hedge,
      };
    case getHedgeDetailsFailureActionType:
      return {
        ...state,
        isPending: false,
      };
    case hedgesChangeTypeSuccessActionType:
    case hedgeReassignSuccessActionType:
      return {
        ...state,
        hedge: payload.hedge,
      };
    case clearHedgeDetailsActionType:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};

export { hedgesDetailsReducer };
