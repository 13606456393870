import axios from 'axios';

import { parseNoteUpdateData } from 'shared/helpers/dataParsers/notes/parseNoteUpdateData';

const API_HOST_AUTH = process.env.REACT_APP_API;

const updateNoteActionType = 'UPDATE_NOTE';
const updateNoteAction = () => {
  return {
    type: updateNoteActionType,
  };
};

const updateNoteSuccessActionType = 'UPDATE_NOTE_SUCCESS';
const updateNoteSuccessAction = () => {
  return {
    type: updateNoteSuccessActionType,
  };
};

const updateNoteFailureActionType = 'UPDATE_NOTE_FAILURE';
const updateNoteFailureAction = () => {
  return {
    type: updateNoteFailureActionType,
  };
};

const updateNote =
  ({ noteId, note }, files) =>
  dispatch => {
    dispatch(updateNoteAction());

    return axios({
      method: 'put',
      url: `${API_HOST_AUTH}/notes/${noteId}`,
      headers: { 'Content-Type': 'multipart/form-data' },
      data: parseNoteUpdateData(note, files),
    })
      .then(res => {
        dispatch(updateNoteSuccessAction());

        return res.data;
      })
      .catch(err => {
        dispatch(updateNoteFailureAction());

        return Promise.reject(err.data);
      });
  };

export {
  updateNoteActionType,
  updateNoteAction,
  updateNoteSuccessActionType,
  updateNoteSuccessAction,
  updateNoteFailureActionType,
  updateNoteFailureAction,
  updateNote,
};
