import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { downloadAttachment } from 'actions/Attachments/downloadAttachment';
import { getHedgeDetails, clearHedgeDetailsAction } from 'actions/Hedges/hedgesDetails';
import { clearNotesList, getNotes } from 'actions/Notes/notesList';
import { showSnackbar } from 'actions/shared/snackbar';

import { HedgesDetails } from './HedgesDetails';

function mapStateToProps(state) {
  return {
    auth: state.auth,
    hedgesDetails: state.hedgesDetails,
    downloadAttachmentStorage: state.downloadAttachmentStorage,
    getNotes: state.getNotes,
    clearNotesList: state.clearNotesList,
    notesList: state.notesList,
  };
}

const mapDispatchToProps = {
  getHedgeDetails,
  clearHedgeDetailsAction,
  getNotes,
  clearNotesList,
  downloadAttachment,
  showSnackbar,
};

const ConnectedHedgesDetails = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HedgesDetails),
);

export { ConnectedHedgesDetails };
