const CREATE_SELF_DELIVERY_REQUEST = {
  INVALID_DELIVERY_DATE_EMPTY: 'Delivery date cannot be empty.',
  INVALID_DELIVERY_DATE_PAST: 'Delivery date cannot be in the past.',
  APPROX_DELIVERY_TIME_EMPTY: 'Approx. delivery time cannot be empty.',
  APPROX_DELIVERY_TIME_BUSY: 'All hours on this day are already taken.',
  INVALID_CUSTOMER_INSTRUCTIONS: 'Customer instructions must have a valid format.',
  INVALID_UNIT_COUNT_NEGATIVE: 'Unit count cannot be less or equal to 0',
  INVALID_UNIT_COUNT_EMPTY: 'Unit count cannot be empty.',
  INVALID_UNIT_COUNT: 'Unit count must have a valid format.',
  INVALID_RELATED_COMPANY_EMPTY: 'Related company cannot be empty.',
  INVALID_FUNDS_TYPE_EMPTY: 'Funds type cannot be empty.',
  INVALID_ESTIMATED_FUNDS: 'Estimated funds must have a valid format.',
  INVALID_ESTIMATED_FUNDS_EMPTY: 'Estimated funds cannot be empty.',
  INVALID_ESTIMATED_FUNDS_NEGATIVE: 'Estimated funds cannot be less or equal to 0',
};

export { CREATE_SELF_DELIVERY_REQUEST };
