import propTypes from 'prop-types';
import React from 'react';

const Toggle = ({ visible, children, ...props } = { visible: true }) => (
  <div
    {...props}
    style={visible ? {} : { display: 'none' }}
  >
    {children}
  </div>
);

Toggle.propTypes = {
  visible: propTypes.bool,
};

export { Toggle };
