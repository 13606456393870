const createUserTypes = {
  owner: {
    standard: ['customer', 'grading', 'external', 'owner', 'support', 'warehouse', 'buyer'],
    assist: ['customer', 'grading', 'external', 'support', 'warehouse', 'buyer'],
  },
  support: {
    standard: ['customer', 'grading'],
    assist: ['customer', 'grading'],
  },
};

export { createUserTypes };
