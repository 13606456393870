import { connect } from 'react-redux';

import { logout } from 'actions/shared/auth';

import { Authentication } from './Authentication';

const mapStateToProps = ({ auth }) => ({ auth });

const mapDispatchToProps = {
  logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(Authentication);
