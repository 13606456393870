import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { clearUserDetails, getUser } from 'actions/Users/Details/userDetails';
import { showSnackbar } from 'actions/shared/snackbar';

import { UIAuthorization } from 'components/Authorization/UIAuthorization';

import { ContentLoader } from 'shared/components/ContentLoader/ContentLoader';
import { renderDetailsContainer } from 'shared/components/Details/helpers/renderDetailsContainer';
import { NotesPanel } from 'shared/components/Notes/NotesPanel/NotesPanel';
import { userTypeAccessHandler } from 'shared/helpers/accesses/userTypeAccessHandler';
import { NOTE } from 'shared/helpers/constants/noteConstants';
import { dangerOptions } from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

import { extractErrorMessage } from 'utils/extractErrorMessage';

import { UserMainDetails } from './components/MainDetails/MainDetails';
import {
  userDetailsContainerConfig,
  userDetailsFieldsConfig,
  activityFieldsConfig,
  activityContainerConfig,
} from './config/fieldsConfig';

const UserDetails = ({ match, history }) => {
  const { user, isPending } = useSelector(state => state.userDetails);
  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();

  const { id, companyId } = match.params;

  useEffect(() => {
    dispatch(getUser(id)).catch(err =>
      dispatch(showSnackbar(dangerOptions, extractErrorMessage(err))),
    );

    return () => dispatch(clearUserDetails());
  }, [id]);

  return (
    <ContentLoader loaded={user && !isPending}>
      {user && (
        <>
          <UserMainDetails user={user} auth={auth} companyId={companyId} history={history} />
          {renderDetailsContainer(userDetailsContainerConfig, userDetailsFieldsConfig, user)}
          {renderDetailsContainer(activityContainerConfig, activityFieldsConfig, user)}
          <UIAuthorization
            access={userTypeAccessHandler(user.userType, auth.user.userType, auth.user.accessLevel)}
          >
            <NotesPanel match={match} relatedType={NOTE.NOTES_TYPE.USERS} />
          </UIAuthorization>
        </>
      )}
    </ContentLoader>
  );
};

export { UserDetails };
