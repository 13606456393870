import React, { Component } from 'react';

import { FormContainer } from 'shared/components/Form';

import { history } from 'utils/history';

class CreateTerm extends Component {
  state = {
    relatedCompany: {},
  };

  componentDidMount() {
    const { getCompany, getSignedTerms, match } = this.props;

    getCompany(match.params.id).then(company => this.checkCompanyDetails(company));

    getSignedTerms(match.params.id);
  }

  componentWillUnmount() {
    this.props.clearCompanyDetails();
  }

  setRelatedCompany(company) {
    this.setState({ relatedCompany: { ...company } });
  }

  /**
   * Submit user object with user data, with actual logged user main data
   * @param values
   */
  submit = values =>
    this.props.createTerms(
      { ...values, materialType: this.props.type },
      this.state.relatedCompany._id,
    );

  checkCompanyDetails(company) {
    if (company) {
      this.setRelatedCompany(company);
    } else {
      history.push('/companies');
    }
  }

  render() {
    const { title, children, signedTerms } = this.props;

    return (
      <FormContainer
        header={title}
        subHeader={this.state.relatedCompany.companyName}
        loaded={!signedTerms.isPending}
      >
        {() =>
          children({
            onSubmit: this.submit,
            relatedCompany: this.state.relatedCompany,
            ...this.props,
          })
        }
      </FormContainer>
    );
  }
}

export { CreateTerm };
