import React from 'react';
import styled from 'styled-components';

import { toCamelCase } from 'shared/helpers/parsers/text';
import { cssVar, pxToRem, media } from 'shared/helpers/styling/styling';

import { Icon } from '../Icon/Icon';
import SearchForm from './components/SearchForm/SearchForm';

function SearchComponentLayout({ handleChange, className, focusSearch, label, nonPersistent }) {
  const listName = toCamelCase(label || '');

  return (
    <div className={className}>
      <Icon icon="icon-search" width={36} />
      <SearchForm
        onChange={handleChange}
        onSubmit={handleChange}
        focusSearch={focusSearch}
        list={listName}
        nonPersistent={nonPersistent}
      />
    </div>
  );
}

const SearchComponent = styled(SearchComponentLayout)`
  display: flex;
  align-items: center;

  ${media.mobile`
  max-width: ${({ size }) => (size === 'long' ? '80%' : '50%')};  
  margin-left: 0;
  `};

  ${media.phone`
  max-width: ${({ size }) => (size === 'long' ? '82%' : '50%')};  
  `};

  i {
    margin-left: ${pxToRem(23)};
    margin-right: ${pxToRem(15)};

    ${media.mobile`
      margin-left: ${pxToRem(10)};
      margin-bottom: 5px;
    `}
  }
  form {
    width: 100%;

    ${media.phone`
        max-width: 100%;
      `}

    input {
      background-color: ${cssVar('alto')};
      width: ${pxToRem(218)};
      height: ${pxToRem(33)};
      border-radius: 10px;
      color: ${cssVar('outerSpaceFormBg')};
      font-size: ${pxToRem(14)};
      font-weight: 500;
      padding-right: 30px;

      ${media.phone`
        height: ${pxToRem(41)};
        width: 8rem;
        
      `}
      ${media.mobile`
        height: ${pxToRem(41)};
        width: 100%;
        
      `}
    }

    ${Icon} {
      position: absolute;
      cursor: pointer;
      transform: translateX(175px) translateY(11px);

      ${media.phone`
        transform: translateX(185px) translateY(11px);
      `}
    }
  }
`;

export { SearchComponent };
