const ENTER_ASSAY = {
  ASSAY_OPTION_EMPTY: 'Assay option must be selected in order to fill the form.',
  MATERIAL_TYPE_EMPTY: 'Material type cannot be empty.',
  MATERIAL_TYPE_EMPTY_H: 'Material type - Heavies cannot be empty.',

  WEIGHT_GROSS_RECEIVED_EMPTY: 'Weight gross received cannot be empty.',
  WEIGHT_GROSS_RECEIVED_INVALID: 'Weight gross received must have a valid format.',
  WEIGHT_GROSS_RECEIVED_NEGATIVE: 'Weight gross received cannot be less or equal to 0.',

  WEIGHT_TARE_RECEIVED_EMPTY: 'Weight tare received cannot be empty.',
  WEIGHT_TARE_RECEIVED_INVALID: 'Weight tare received must have a valid format.',
  WEIGHT_TARE_RECEIVED_NEGATIVE: 'Weight tare received cannot be less than 0.',
  WEIGHT_TARE_RECEIVED_GTE_GROSS: 'Weight tare received must be less than weight gross received.',

  WEIGHT_NET_WET_RECEIVED_EMPTY: 'Weight net wet received cannot be empty.',
  WEIGHT_NET_WET_RECEIVED_INVALID: 'Weight net wet received must have a valid format.',
  WEIGHT_NET_WET_RECEIVED_NEGATIVE: 'Weight net wet received cannot be less or equal to 0.',
  WEIGHT_NET_WET_RECEIVED_GTE_GROSS:
    'Weight net wet received must be less than weight gross received.',

  WEIGHT_ADJUSTED_NET_WET_RECEIVED_EMPTY: 'Weight adjusted net wet received cannot be empty.',
  WEIGHT_ADJUSTED_NET_WET_RECEIVED_INVALID:
    'Weight adjusted net wet received must have a valid format.',
  WEIGHT_ADJUSTED_NET_WET_RECEIVED_NEGATIVE:
    'Weight adjusted net wet received cannot be less or equal to 0.',
  WEIGHT_ADJUSTED_NET_WET_RECEIVED_GTE_NET_WET:
    'Weight adjusted net wet received must be less than weight net wet received.',

  WEIGHT_SCRAP_RECEIVED_EMPTY: 'Weight scrap received cannot be empty.',
  WEIGHT_SCRAP_RECEIVED_INVALID: 'Weight scrap received must have a valid format.',
  WEIGHT_SCRAP_RECEIVED_NEGATIVE: 'Weight scrap received cannot be less than 0.',
  WEIGHT_SCRAP_RECEIVED_GTE_NET_WET:
    'Weight scrap received must be less than weight net wet received.',

  WEIGHT_NET_DRY_RECEIVED_EMPTY: 'Weight net dry received cannot be empty.',
  WEIGHT_NET_DRY_RECEIVED_INVALID: 'Weight net dry received must have a valid format.',
  WEIGHT_NET_DRY_RECEIVED_NEGATIVE: 'Weight net dry received cannot be less or equal to 0.',
  WEIGHT_NET_DRY_RECEIVED_GT_NET_WET:
    'Weight net dry received must be less or equal to weight net wet received.',

  PERCENT_MOISTURE_EMPTY: 'Percent moisture cannot be empty.',
  PERCENT_MOISTURE_INVALID: 'Percent moisture must have a valid format.',
  PERCENT_MOISTURE_RANGE: 'Percent moisture should be number between 0 and 10',

  CARBON_PERCENTAGE_EMPTY: 'Carbon percentage cannot be empty.',
  CARBON_PERCENTAGE_INVALID: 'Carbon percentage must have a valid format.',
  CARBON_PERCENTAGE_RANGE: 'Carbon percentage should be number between 0 and 10',

  CARBON_PERCENTAGE_EMPTY_H: 'Carbon percentage - Heavies cannot be empty.',
  CARBON_PERCENTAGE_INVALID_H: 'Carbon percentage - Heavies must have a valid format.',
  CARBON_PERCENTAGE_RANGE_H: 'Carbon percentage - Heavies should be number between 0 and 10',

  CARBON_PERCENTAGE_EMPTY_A: 'Carbon percentage - A cannot be empty.',
  CARBON_PERCENTAGE_INVALID_A: 'Carbon percentage - A must have a valid format.',
  CARBON_PERCENTAGE_RANGE_A: 'Carbon percentage - A should be number between 0 and 10',

  CARBON_PERCENTAGE_EMPTY_F: 'Carbon percentage - Fines cannot be empty.',
  CARBON_PERCENTAGE_INVALID_F: 'Carbon percentage - Fines must have a valid format.',
  CARBON_PERCENTAGE_RANGE_F: 'Carbon percentage - Fines should be number between 0 and 10',

  CARBON_PERCENTAGE_EMPTY_B: 'Carbon percentage - B cannot be empty.',
  CARBON_PERCENTAGE_INVALID_B: 'Carbon percentage - B must have a valid format.',
  CARBON_PERCENTAGE_RANGE_B: 'Carbon percentage - B should be number between 0 and 10',

  PLATINUM_ASSAY_EMPTY: 'Platinum assay cannot be empty.',
  PLATINUM_ASSAY_INVALID: 'Platinum assay must have a valid format.',
  PLATINUM_ASSAY_NEGATIVE: 'Platinum assay cannot be less than 0.',

  PALLADIUM_ASSAY_EMPTY: 'Palladium assay cannot be empty.',
  PALLADIUM_ASSAY_INVALID: 'Palladium assay must have a valid format.',
  PALLADIUM_ASSAY_NEGATIVE: 'Palladium assay cannot be less than 0.',

  RHODIUM_ASSAY_EMPTY: 'Rhodium assay cannot be empty.',
  RHODIUM_ASSAY_INVALID: 'Rhodium assay must have a valid format.',
  RHODIUM_ASSAY_NEGATIVE: 'Rhodium assay cannot be less than 0.',

  PLATINUM_CONTAINED_OZ_EMPTY: 'Platinum contained oz cannot be empty.',
  PLATINUM_CONTAINED_OZ_INVALID: 'Platinum contained oz must have a valid format.',
  PLATINUM_CONTAINED_OZ_NEGATIVE: 'Platinum contained oz cannot be less than 0.',

  PALLADIUM_CONTAINED_OZ_EMPTY: 'Palladium contained oz cannot be empty.',
  PALLADIUM_CONTAINED_OZ_INVALID: 'Palladium contained oz must have a valid format.',
  PALLADIUM_CONTAINED_OZ_NEGATIVE: 'Palladium contained oz cannot be less than 0.',

  RHODIUM_CONTAINED_OZ_EMPTY: 'Rhodium contained oz cannot be empty.',
  RHODIUM_CONTAINED_OZ_INVALID: 'Rhodium contained oz must have a valid format.',
  RHODIUM_CONTAINED_OZ_NEGATIVE: 'Rhodium contained oz cannot be less than 0.',

  PLATINUM_RETURNABLE_OZ_EMPTY: 'Platinum returnable oz cannot be empty.',
  PLATINUM_RETURNABLE_OZ_INVALID: 'Platinum returnable oz must have a valid format.',
  PLATINUM_RETURNABLE_OZ_NEGATIVE: 'Platinum returnable oz cannot be less than 0.',

  PALLADIUM_RETURNABLE_OZ_EMPTY: 'Palladium returnable oz cannot be empty.',
  PALLADIUM_RETURNABLE_OZ_INVALID: 'Palladium returnable oz must have a valid format.',
  PALLADIUM_RETURNABLE_OZ_NEGATIVE: 'Palladium returnable oz cannot be less than 0.',

  RHODIUM_RETURNABLE_OZ_EMPTY: 'Rhodium returnable oz cannot be empty.',
  RHODIUM_RETURNABLE_OZ_INVALID: 'Rhodium returnable oz must have a valid format.',
  RHODIUM_RETURNABLE_OZ_NEGATIVE: 'Rhodium returnable oz cannot be less than 0.',

  WEIGHT_GROSS_RECEIVED_EMPTY_H: 'Weight gross received - Heavies cannot be empty.',
  WEIGHT_GROSS_RECEIVED_INVALID_H: 'Weight gross received - Heavies must have a valid format.',
  WEIGHT_GROSS_RECEIVED_NEGATIVE_H: 'Weight gross received - Heavies cannot be less or equal to 0.',

  WEIGHT_GROSS_RECEIVED_EMPTY_A: 'Weight gross received - A cannot be empty.',
  WEIGHT_GROSS_RECEIVED_INVALID_A: 'Weight gross received - A must have a valid format.',
  WEIGHT_GROSS_RECEIVED_NEGATIVE_A: 'Weight gross received - A cannot be less or equal to 0.',

  WEIGHT_TARE_RECEIVED_EMPTY_H: 'Weight tare received - Heavies cannot be empty.',
  WEIGHT_TARE_RECEIVED_INVALID_H: 'Weight tare received - Heavies must have a valid format.',
  WEIGHT_TARE_RECEIVED_NEGATIVE_H: 'Weight tare received - Heavies cannot be less than 0.',
  WEIGHT_TARE_RECEIVED_GTE_GROSS_H:
    'Weight tare received - Heavies must be less than weight gross received.',

  WEIGHT_TARE_RECEIVED_EMPTY_A: 'Weight tare received - A cannot be empty.',
  WEIGHT_TARE_RECEIVED_INVALID_A: 'Weight tare received - A must have a valid format.',
  WEIGHT_TARE_RECEIVED_NEGATIVE_A: 'Weight tare received - A cannot be less than 0.',
  WEIGHT_TARE_RECEIVED_GTE_GROSS_A:
    'Weight tare received - A must be less than weight gross received.',

  WEIGHT_NET_WET_RECEIVED_EMPTY_H: 'Weight net wet received - Heavies cannot be empty.',
  WEIGHT_NET_WET_RECEIVED_INVALID_H: 'Weight net wet received - Heavies must have a valid format.',
  WEIGHT_NET_WET_RECEIVED_NEGATIVE_H:
    'Weight net wet received - Heavies cannot be less or equal to 0.',
  WEIGHT_NET_WET_RECEIVED_GTE_GROSS_H:
    'Weight net wet received - Heavies must be less than weight gross received.',

  WEIGHT_NET_WET_RECEIVED_EMPTY_A: 'Weight net wet received - A cannot be empty.',
  WEIGHT_NET_WET_RECEIVED_INVALID_A: 'Weight net wet received - A must have a valid format.',
  WEIGHT_NET_WET_RECEIVED_NEGATIVE_A: 'Weight net wet received - A cannot be less or equal to 0.',
  WEIGHT_NET_WET_RECEIVED_GTE_GROSS_A:
    'Weight net wet received - A must be less than weight gross received.',

  WEIGHT_SCRAP_RECEIVED_EMPTY_H: 'Weight scrap received - Heavies cannot be empty.',
  WEIGHT_SCRAP_RECEIVED_INVALID_H: 'Weight scrap received - Heavies must have a valid format.',
  WEIGHT_SCRAP_RECEIVED_NEGATIVE_H: 'Weight scrap received - Heavies cannot be less than 0.',
  WEIGHT_SCRAP_RECEIVED_GTE_NET_WET_H:
    'Weight scrap received - Heavies must be less than weight net wet received.',

  WEIGHT_SCRAP_RECEIVED_EMPTY_A: 'Weight scrap received - A cannot be empty.',
  WEIGHT_SCRAP_RECEIVED_INVALID_A: 'Weight scrap received - A must have a valid format.',
  WEIGHT_SCRAP_RECEIVED_NEGATIVE_A: 'Weight scrap received - A cannot be less than 0.',
  WEIGHT_SCRAP_RECEIVED_GTE_NET_WET_A:
    'Weight scrap received - A must be less than weight net wet received.',

  WEIGHT_ADJUSTED_NET_WET_RECEIVED_EMPTY_H:
    'Weight adjusted net wet received - Heavies cannot be empty.',
  WEIGHT_ADJUSTED_NET_WET_RECEIVED_INVALID_H:
    'Weight adjusted net wet received - Heavies must have a valid format.',
  WEIGHT_ADJUSTED_NET_WET_RECEIVED_NEGATIVE_H:
    'Weight adjusted net wet received - Heavies cannot be less or equal to 0.',
  WEIGHT_ADJUSTED_NET_WET_RECEIVED_GTE_NET_WET_H:
    'Weight adjusted net wet received - Heavies must be less than weight net wet received.',

  WEIGHT_ADJUSTED_NET_WET_RECEIVED_EMPTY_A: 'Weight adjusted net wet received - A cannot be empty.',
  WEIGHT_ADJUSTED_NET_WET_RECEIVED_INVALID_A:
    'Weight adjusted net wet received - A must have a valid format.',
  WEIGHT_ADJUSTED_NET_WET_RECEIVED_NEGATIVE_A:
    'Weight adjusted net wet received - A cannot be less or equal to 0.',
  WEIGHT_ADJUSTED_NET_WET_RECEIVED_GTE_NET_WET_A:
    'Weight adjusted net wet received - A must be less than weight net wet received.',

  WEIGHT_NET_DRY_RECEIVED_EMPTY_H: 'Weight net dry received - Heavies cannot be empty.',
  WEIGHT_NET_DRY_RECEIVED_INVALID_H: 'Weight net dry received - Heavies must have a valid format.',
  WEIGHT_NET_DRY_RECEIVED_NEGATIVE_H:
    'Weight net dry received - Heavies cannot be less or equal to 0.',
  WEIGHT_NET_DRY_RECEIVED_GT_NET_WET_H:
    'Weight net dry received - Heavies must be less or equal to weight net wet received.',

  WEIGHT_NET_DRY_RECEIVED_EMPTY_A: 'Weight net dry received - A cannot be empty.',
  WEIGHT_NET_DRY_RECEIVED_INVALID_A: 'Weight net dry received - A must have a valid format.',
  WEIGHT_NET_DRY_RECEIVED_NEGATIVE_A: 'Weight net dry received - A cannot be less or equal to 0.',
  WEIGHT_NET_DRY_RECEIVED_GT_NET_WET_A:
    'Weight net dry received - A must be less or equal to weight net wet received.',

  PERCENT_MOISTURE_EMPTY_H: 'Percent moisture - Heavies cannot be empty.',
  PERCENT_MOISTURE_INVALID_H: 'Percent moisture - Heavies must have a valid format.',
  PERCENT_MOISTURE_RANGE_H: 'Percent moisture - Heavies should be number between 0 and 10',

  PERCENT_MOISTURE_EMPTY_A: 'Percent moisture - A cannot be empty.',
  PERCENT_MOISTURE_INVALID_A: 'Percent moisture - A must have a valid format.',
  PERCENT_MOISTURE_RANGE_A: 'Percent moisture - A should be number between 0 and 10',

  PLATINUM_ASSAY_EMPTY_H: 'Platinum assay - Heavies cannot be empty.',
  PLATINUM_ASSAY_INVALID_H: 'Platinum assay must have a valid format.',
  PLATINUM_ASSAY_NEGATIVE_H: 'Platinum assay - Heavies cannot be less than 0.',

  PALLADIUM_ASSAY_EMPTY_H: 'Palladium assay - Heavies cannot be empty.',
  PALLADIUM_ASSAY_INVALID_H: 'Palladium assay must have a valid format.',
  PALLADIUM_ASSAY_NEGATIVE_H: 'Palladium assay - Heavies cannot be less than 0.',

  RHODIUM_ASSAY_EMPTY_H: 'Rhodium assay - Heavies cannot be empty.',
  RHODIUM_ASSAY_INVALID_H: 'Rhodium assay must have a valid format.',
  RHODIUM_ASSAY_NEGATIVE_H: 'Rhodium assay - Heavies cannot be less than 0.',

  PLATINUM_CONTAINED_OZ_EMPTY_H: 'Platinum contained oz - Heavies cannot be empty.',
  PLATINUM_CONTAINED_OZ_INVALID_H: 'Platinum contained oz must have a valid format.',
  PLATINUM_CONTAINED_OZ_NEGATIVE_H: 'Platinum contained oz - Heavies cannot be less than 0.',

  PALLADIUM_CONTAINED_OZ_EMPTY_H: 'Palladium contained oz - Heavies cannot be empty.',
  PALLADIUM_CONTAINED_OZ_INVALID_H: 'Palladium contained oz must have a valid format.',
  PALLADIUM_CONTAINED_OZ_NEGATIVE_H: 'Palladium contained oz - Heavies cannot be less than 0.',

  RHODIUM_CONTAINED_OZ_EMPTY_H: 'Rhodium contained oz - Heavies cannot be empty.',
  RHODIUM_CONTAINED_OZ_INVALID_H: 'Rhodium contained oz must have a valid format.',
  RHODIUM_CONTAINED_OZ_NEGATIVE_H: 'Rhodium contained oz - Heavies cannot be less than 0.',

  PLATINUM_RETURNABLE_OZ_EMPTY_H: 'Platinum returnable oz - Heavies cannot be empty.',
  PLATINUM_RETURNABLE_OZ_INVALID_H: 'Platinum returnable oz must have a valid format.',
  PLATINUM_RETURNABLE_OZ_NEGATIVE_H: 'Platinum returnable oz - Heavies cannot be less than 0.',

  PALLADIUM_RETURNABLE_OZ_EMPTY_H: 'Palladium returnable oz - Heavies cannot be empty.',
  PALLADIUM_RETURNABLE_OZ_INVALID_H: 'Palladium returnable oz must have a valid format.',
  PALLADIUM_RETURNABLE_OZ_NEGATIVE_H: 'Palladium returnable oz - Heavies cannot be less than 0.',

  RHODIUM_RETURNABLE_OZ_EMPTY_H: 'Rhodium returnable oz - Heavies cannot be empty.',
  RHODIUM_RETURNABLE_OZ_INVALID_H: 'Rhodium returnable oz must have a valid format.',
  RHODIUM_RETURNABLE_OZ_NEGATIVE_H: 'Rhodium returnable oz - Heavies cannot be less than 0.',

  WEIGHT_GROSS_RECEIVED_EMPTY_F: 'Weight gross received - Fines cannot be empty.',
  WEIGHT_GROSS_RECEIVED_INVALID_F: 'Weight gross received - Fines must have a valid format.',
  WEIGHT_GROSS_RECEIVED_NEGATIVE_F: 'Weight gross received - Fines cannot be less or equal to 0.',

  WEIGHT_GROSS_RECEIVED_EMPTY_B: 'Weight gross received - B cannot be empty.',
  WEIGHT_GROSS_RECEIVED_INVALID_B: 'Weight gross received - B must have a valid format.',
  WEIGHT_GROSS_RECEIVED_NEGATIVE_B: 'Weight gross received - B cannot be less or equal to 0.',

  WEIGHT_TARE_RECEIVED_EMPTY_F: 'Weight tare received - Fines cannot be empty.',
  WEIGHT_TARE_RECEIVED_INVALID_F: 'Weight tare received - Fines must have a valid format.',
  WEIGHT_TARE_RECEIVED_NEGATIVE_F: 'Weight tare received - Fines cannot be less than 0.',
  WEIGHT_TARE_RECEIVED_GTE_GROSS_F:
    'Weight tare received - Fines must be less than weight gross received.',

  WEIGHT_TARE_RECEIVED_EMPTY_B: 'Weight tare received - B cannot be empty.',
  WEIGHT_TARE_RECEIVED_INVALID_B: 'Weight tare received - B must have a valid format.',
  WEIGHT_TARE_RECEIVED_NEGATIVE_B: 'Weight tare received - B cannot be less than 0.',
  WEIGHT_TARE_RECEIVED_GTE_GROSS_B:
    'Weight tare received - B must be less than weight gross received.',

  WEIGHT_NET_WET_RECEIVED_EMPTY_F: 'Weight net wet received - Fines cannot be empty.',
  WEIGHT_NET_WET_RECEIVED_INVALID_F: 'Weight net wet received - Fines must have a valid format.',
  WEIGHT_NET_WET_RECEIVED_NEGATIVE_F:
    'Weight net wet received - Fines cannot be less or equal to 0.',
  WEIGHT_NET_WET_RECEIVED_GTE_GROSS_F:
    'Weight net wet received - Fines must be less than weight gross received.',

  WEIGHT_NET_WET_RECEIVED_EMPTY_B: 'Weight net wet received - B cannot be empty.',
  WEIGHT_NET_WET_RECEIVED_INVALID_B: 'Weight net wet received - B must have a valid format.',
  WEIGHT_NET_WET_RECEIVED_NEGATIVE_B: 'Weight net wet received - B cannot be less or equal to 0.',
  WEIGHT_NET_WET_RECEIVED_GTE_GROSS_B:
    'Weight net wet received - B must be less than weight gross received.',

  WEIGHT_SCRAP_RECEIVED_EMPTY_F: 'Weight scrap received - Fines cannot be empty.',
  WEIGHT_SCRAP_RECEIVED_INVALID_F: 'Weight scrap received - Fines must have a valid format.',
  WEIGHT_SCRAP_RECEIVED_NEGATIVE_F: 'Weight scrap received - Fines cannot be less than 0.',
  WEIGHT_SCRAP_RECEIVED_GTE_NET_WET_F:
    'Weight scrap received - Fines must be less than weight net wet received.',

  WEIGHT_SCRAP_RECEIVED_EMPTY_B: 'Weight scrap received - B cannot be empty.',
  WEIGHT_SCRAP_RECEIVED_INVALID_B: 'Weight scrap received - B must have a valid format.',
  WEIGHT_SCRAP_RECEIVED_NEGATIVE_B: 'Weight scrap received - B cannot be less than 0.',
  WEIGHT_SCRAP_RECEIVED_GTE_NET_WET_B:
    'Weight scrap received - B must be less than weight net wet received.',

  WEIGHT_ADJUSTED_NET_WET_RECEIVED_EMPTY_F:
    'Weight adjusted net wet received - Fines cannot be empty.',
  WEIGHT_ADJUSTED_NET_WET_RECEIVED_INVALID_F:
    'Weight adjusted net wet received - Fines must have a valid format.',
  WEIGHT_ADJUSTED_NET_WET_RECEIVED_NEGATIVE_F:
    'Weight adjusted net wet received - Fines cannot be less or equal to 0.',
  WEIGHT_ADJUSTED_NET_WET_RECEIVED_GTE_NET_WET_F:
    'Weight adjusted net wet received - Fines must be less than weight net wet received.',

  WEIGHT_ADJUSTED_NET_WET_RECEIVED_EMPTY_B: 'Weight adjusted net wet received - B cannot be empty.',
  WEIGHT_ADJUSTED_NET_WET_RECEIVED_INVALID_B:
    'Weight adjusted net wet received - B must have a valid format.',
  WEIGHT_ADJUSTED_NET_WET_RECEIVED_NEGATIVE_B:
    'Weight adjusted net wet received - B cannot be less or equal to 0.',
  WEIGHT_ADJUSTED_NET_WET_RECEIVED_GTE_NET_WET_B:
    'Weight adjusted net wet received - B must be less than weight net wet received.',

  WEIGHT_NET_DRY_RECEIVED_EMPTY_F: 'Weight net dry received - Fines cannot be empty.',
  WEIGHT_NET_DRY_RECEIVED_INVALID_F: 'Weight net dry received - Fines must have a valid format.',
  WEIGHT_NET_DRY_RECEIVED_NEGATIVE_F:
    'Weight net dry received - Fines cannot be less or equal to 0.',
  WEIGHT_NET_DRY_RECEIVED_GT_NET_WET_F:
    'Weight net dry received - Fines must be less or equal to weight net wet received.',

  WEIGHT_NET_DRY_RECEIVED_EMPTY_B: 'Weight net dry received - B cannot be empty.',
  WEIGHT_NET_DRY_RECEIVED_INVALID_B: 'Weight net dry received - B must have a valid format.',
  WEIGHT_NET_DRY_RECEIVED_NEGATIVE_B: 'Weight net dry received - B cannot be less or equal to 0.',
  WEIGHT_NET_DRY_RECEIVED_GT_NET_WET_B:
    'Weight net dry received - B must be less or equal to weight net wet received.',

  PERCENT_MOISTURE_EMPTY_F: 'Percent moisture - Fines cannot be empty.',
  PERCENT_MOISTURE_INVALID_F: 'Percent moisture - Fines must have a valid format.',
  PERCENT_MOISTURE_RANGE_F: 'Percent moisture - Fines should be number between 0 and 10',

  PERCENT_MOISTURE_EMPTY_B: 'Percent moisture - B cannot be empty.',
  PERCENT_MOISTURE_INVALID_B: 'Percent moisture - B must have a valid format.',
  PERCENT_MOISTURE_RANGE_B: 'Percent moisture - B should be number between 0 and 10',

  PLATINUM_ASSAY_EMPTY_F: 'Platinum assay - Fines cannot be empty.',
  PLATINUM_ASSAY_INVALID_F: 'Platinum assay must have a valid format.',
  PLATINUM_ASSAY_NEGATIVE_F: 'Platinum assay - Fines cannot be less than 0.',

  PALLADIUM_ASSAY_EMPTY_F: 'Palladium assay - Fines cannot be empty.',
  PALLADIUM_ASSAY_INVALID_F: 'Palladium assay must have a valid format.',
  PALLADIUM_ASSAY_NEGATIVE_F: 'Palladium assay - Fines cannot be less than 0.',

  RHODIUM_ASSAY_EMPTY_F: 'Rhodium assay - Fines cannot be empty.',
  RHODIUM_ASSAY_INVALID_F: 'Rhodium assay must have a valid format.',
  RHODIUM_ASSAY_NEGATIVE_F: 'Rhodium assay - Fines cannot be less than 0.',

  PLATINUM_CONTAINED_OZ_EMPTY_F: 'Platinum contained oz - Fines cannot be empty.',
  PLATINUM_CONTAINED_OZ_INVALID_F: 'Platinum contained oz must have a valid format.',
  PLATINUM_CONTAINED_OZ_NEGATIVE_F: 'Platinum contained oz - Fines cannot be less than 0.',

  PALLADIUM_CONTAINED_OZ_EMPTY_F: 'Palladium contained oz - Fines cannot be empty.',
  PALLADIUM_CONTAINED_OZ_INVALID_F: 'Palladium contained oz must have a valid format.',
  PALLADIUM_CONTAINED_OZ_NEGATIVE_F: 'Palladium contained oz - Fines cannot be less than 0.',

  RHODIUM_CONTAINED_OZ_EMPTY_F: 'Rhodium contained oz - Fines cannot be empty.',
  RHODIUM_CONTAINED_OZ_INVALID_F: 'Rhodium contained oz must have a valid format.',
  RHODIUM_CONTAINED_OZ_NEGATIVE_F: 'Rhodium contained oz - Fines cannot be less than 0.',

  PLATINUM_RETURNABLE_OZ_EMPTY_F: 'Platinum returnable oz - Fines cannot be empty.',
  PLATINUM_RETURNABLE_OZ_INVALID_F: 'Platinum returnable oz must have a valid format.',
  PLATINUM_RETURNABLE_OZ_NEGATIVE_F: 'Platinum returnable oz - Fines cannot be less than 0.',

  PALLADIUM_RETURNABLE_OZ_EMPTY_F: 'Palladium returnable oz - Fines cannot be empty.',
  PALLADIUM_RETURNABLE_OZ_INVALID_F: 'Palladium returnable oz must have a valid format.',
  PALLADIUM_RETURNABLE_OZ_NEGATIVE_F: 'Palladium returnable oz - Fines cannot be less than 0.',

  RHODIUM_RETURNABLE_OZ_EMPTY_F: 'Rhodium returnable oz - Fines cannot be empty.',
  RHODIUM_RETURNABLE_OZ_INVALID_F: 'Rhodium returnable oz must have a valid format.',
  RHODIUM_RETURNABLE_OZ_NEGATIVE_F: 'Rhodium returnable oz - Fines cannot be less than 0.',
};

export { ENTER_ASSAY };
