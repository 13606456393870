import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector, SubmissionError } from 'redux-form';

import {
  FieldAttachment,
  FieldInput,
  FieldRadioButton,
  FieldSelect,
  FieldTextarea,
} from 'shared/components/Fields';
import { FieldCapitalized } from 'shared/components/Fields/FieldCapitalized/FieldCapitalized';
import { Form, Section } from 'shared/components/Form';
import { materialCountRequiredAvailable } from 'shared/helpers/accesses/companies/materialCountRequiredAvailable';
import { onlyInternalHedgingAndSettlementAvailable } from 'shared/helpers/accesses/companies/onlyInternalHedgingAndSettlementAvailable';
import { samplesAllowedAvailable } from 'shared/helpers/accesses/companies/samplesAllowedAvailable';
import { COMPANY } from 'shared/helpers/constants/companies/companyConstants';
import { uniqueCompanyNames } from 'shared/helpers/constants/companies/uniqueCompanyNames';
import { FILES_CONSTANTS } from 'shared/helpers/constants/filesConstants';
import { cancelModal } from 'shared/helpers/constants/modalConstants';
import { NOTE } from 'shared/helpers/constants/noteConstants';
import {
  isAssayOrGradingCompany,
  isGradingCompany,
} from 'shared/helpers/matchers/checkCompanyType';
import { normalize } from 'shared/helpers/parsers/boolean';
import { checkTrim } from 'shared/helpers/parsers/text';
import { asyncValidate } from 'shared/helpers/validations/company/asyncValidate';
import { validate } from 'shared/helpers/validations/company/companyValidations';

import { goBackOrTo } from 'utils/history';

import { DiligenceSection } from './components/DiligenceSection';
import { SelectCompanyType } from './components/SelectCompanyType';

function CompanyForm({
  change,
  untouch,
  handleSubmit,
  companyDetails,
  companyType,
  submitting,
  editMode,
  checkNotes,
  ...props
}) {
  const [companyNameDisabled, setCompanyNameDisabled] = useState(false);
  const [notes, setNotes] = useState(null);

  useEffect(() => {
    setCompanyNameDisabled(
      editMode && uniqueCompanyNames.includes(companyDetails.company.companyName),
    );
  }, [editMode, companyDetails.company]);

  const modalOpen = () => {
    props.openModal(cancelModal, () => {
      goBackOrTo('/companies');
    });
  };

  const onChange = useCallback(
    ({ currentTarget: { value } }) => {
      setNotes({ notes: value });
      checkNotes(value);
    },
    [checkNotes],
  );

  const companyTypeChange = useCallback(() => {
    change('materialCountRequired', null);
    change('samplesAllowed', null);

    untouch('materialCountRequired');
    untouch('samplesAllowed');
  }, [change, untouch]);

  const selectedCompany = companyDetails
    ? companyDetails.company.companyType
    : companyType && companyType.value;

  const onHandleSubmit = values => handleSubmit(values).catch(err => new SubmissionError(err));

  return (
    <Form
      header={COMPANY.COMPANY}
      onSubmit={onHandleSubmit}
      onCancel={modalOpen}
      loaded={!submitting}
      submitDisabled={props.invalid || props.submitting || props.blockSubmit}
    >
      <Section template={['companyName .']}>
        <Field
          name="companyName"
          type="text"
          component={FieldInput}
          disabled={companyNameDisabled}
          label={COMPANY.COMPANY_NAME}
          field="required"
        />
      </Section>
      <Section
        header={COMPANY.COMPANY_INFO}
        template={[
          'companyType companyType',
          'lotPrefix referralMethod',
          'volume purchasingAppURL',
          `samplesAllowed ${
            companyType && !isGradingCompany(companyType.value)
              ? 'onlyInternalHedgingAndSettlement'
              : 'samplesAllowed'
          }`,
          'materialCountRequired materialCountRequired',
        ]}
        unwrap
      >
        <SelectCompanyType
          name="companyType"
          disabled={editMode}
          auth={props.auth}
          companyTypeChange={companyTypeChange}
        />
        <FieldCapitalized
          name="lotPrefix"
          component={FieldInput}
          prefix={
            isAssayOrGradingCompany(companyDetails.company.companyType)
              ? COMPANY.LOT_PREFIX_PREFIX
              : ''
          }
          label={COMPANY.LOT_PREFIX}
          prefixSize={34}
          disabled={editMode && companyDetails.company.hasLotsOrHedges}
          field="unique required"
        />
        <Field
          name="referralMethod"
          component={FieldInput}
          label={COMPANY.REFERRAL_METHOD}
          disabled={editMode}
        />
        <Field name="volume" component={FieldInput} label={COMPANY.VOLUME} />
        <Field name="purchasingAppURL" component={FieldInput} label={COMPANY.PURCHASING_APP_URL} />
        {materialCountRequiredAvailable(selectedCompany) && (
          <Field
            name="materialCountRequired"
            component={FieldRadioButton}
            label={COMPANY.MATERIAL_COUNT_REQUIRED}
            icon="icon-count"
            field="required"
            normalize={normalize}
          />
        )}
        {onlyInternalHedgingAndSettlementAvailable(companyType?.value) && (
          <Field
            name="onlyInternalHedgingAndSettlement"
            component={FieldRadioButton}
            label={COMPANY.ONLY_INTERNAL_HEDGING_SETTLEMENT}
            icon="icon-count"
            field="required"
            normalize={normalize}
          />
        )}
        {samplesAllowedAvailable(selectedCompany) && (
          <Field
            name="samplesAllowed"
            component={FieldRadioButton}
            label={COMPANY.INDIVIDUAL_ASSAYS_ALLOWED}
            icon="icon-count"
            field="required"
            normalize={normalize}
          />
        )}
      </Section>
      <Section header={COMPANY.NOTE} template={['note attachment']} render={!editMode}>
        <Field name="note" component={FieldTextarea} label={NOTE.NOTE} onChange={onChange} />
        {notes && checkTrim(notes) && (
          <Field
            multiple
            name="attachment"
            component={FieldAttachment}
            label={FILES_CONSTANTS.ATTACHMENTS}
            value={null}
            notes={notes}
            props={props}
          />
        )}
      </Section>
      <Section header={COMPANY.SHIPPING_LOCATION} template={['grgDefaultShippingLocation .']}>
        <Field
          name="grgDefaultShippingLocation"
          component={FieldSelect}
          disabled
          label={COMPANY.GRG_DEFAULT_SHIPPING_LOCATION}
        />
      </Section>
      {companyType && isAssayOrGradingCompany(companyDetails.company.companyType) && (
        <DiligenceSection {...props} change={change} companyDetails={companyDetails.company} />
      )}
    </Form>
  );
}

const selector = formValueSelector('CompanyForm');

const mapStateToProps = state => ({
  companyType: selector(state, 'companyType'),
  companyFiles: state.companyFiles,
});

const connectedForm = connect(mapStateToProps)(
  reduxForm({
    form: 'CompanyForm',
    validate,
    asyncValidate,
    asyncBlurFields: ['lotPrefix'],
  })(CompanyForm),
);

export { connectedForm as ConnectedCompanyForm, CompanyForm };
