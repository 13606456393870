import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

import { createLot } from 'actions/Lots/createLot';
import { openModal } from 'actions/shared/modal';
import { showSnackbar } from 'actions/shared/snackbar';

import { ContentLoader } from 'shared/components/ContentLoader/ContentLoader';
import { Section } from 'shared/components/Form/Section/Section';
import { FieldInput } from 'shared/components/Formik/Fields';
import { FieldSelect } from 'shared/components/Formik/Fields';
import { Form } from 'shared/components/Formik/Form';
import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';
import { cancelModal } from 'shared/helpers/constants/modalConstants';
import { filterBuyerAvailableLocations } from 'shared/helpers/dataParsers/location/filterBuyerAvailableLocations';
import { parseLocationsSelectData } from 'shared/helpers/dataParsers/location/parseLocationsSelectData';
import { mapLotToHedgeRedirect } from 'shared/helpers/dataParsers/lot/mapLotToHedgeRedirect';
import { snackbarOptions } from 'shared/helpers/matchers/snackbar/snackbarOptions';

import { BuyerLotFormSchema } from './BuyerLotFormYup';

const BuyerLotForm = ({
  auth: {
    user: { relatedCompany, location: userLocations },
  },
  companyLocations,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const onSubmit = async values => {
    const res = await dispatch(createLot(values));
    dispatch(showSnackbar(snackbarOptions(res.messageType), res.message));

    if (location.state?.fromRequestHedgeView) {
      return history.push(
        '/hedges/create',
        mapLotToHedgeRedirect({ ...res, hedge: location.state.relatedHedge }),
      );
    } else if (res.id) {
      history.push(`/lots/list/${res.id}`);
    }
  };

  const {
    values,
    isValid,
    touched,
    handleBlur,
    getFieldMeta,
    handleSubmit,
    setFieldValue,
    handleChange,
    setFieldTouched,
    dirty,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      sentFromCompanyLocation: null,
      customerInstructions: '',
    },
    validationSchema: BuyerLotFormSchema,
    onSubmit,
  });

  const getErrors = name => Object.keys(touched).includes(name) && getFieldMeta(name).error;

  const filteredLocations = useMemo(
    () => filterBuyerAvailableLocations(userLocations, companyLocations.data),
    [userLocations, companyLocations.data],
  );

  useEffect(() => {
    setSingleLocation(filteredLocations);
  }, [filteredLocations]);

  const setSingleLocation = companyLocations => {
    if (companyLocations?.length !== 1) return;
    setFieldValue('sentFromCompanyLocation', parseLocationsSelectData(companyLocations)[0]);
  };

  const modalOpen = () => {
    dispatch(
      openModal(cancelModal, () => {
        if (location.state?.fromRequestHedgeView) return history.push('/hedges/create');
        history.push('/lots/list');
      }),
    );
  };

  const handleSelectChange = (option = '', data) => {
    if (data?.action === 'clear') {
      setFieldTouched('sentFromCompanyLocation');
    }

    setFieldValue('sentFromCompanyLocation', option);
  };

  return (
    <ContentLoader loaded={!companyLocations.isPending}>
      <Form
        onSubmit={handleSubmit}
        onCancel={modalOpen}
        submitDisabled={!isValid || !dirty}
        header={relatedCompany.companyName}
      >
        <Section template={['sentFromCompanyLocation customerInstructions']}>
          <FieldSelect
            name="sentFromCompanyLocation"
            options={parseLocationsSelectData(filteredLocations)}
            label={LOTS.SENT_FROM_COMPANY_LOCATION}
            field="required"
            disabled={!companyLocations.isPending && filteredLocations.length === 1}
            onChange={handleSelectChange}
            error={getErrors('sentFromCompanyLocation')}
            value={values.sentFromCompanyLocation}
          />

          <FieldInput
            name="customerInstructions"
            type="text"
            label={LOTS.CUSTOMER_INSTRUCTIONS}
            value={values.customerInstructions}
            onChange={handleChange}
            onBlur={handleBlur}
            error={getErrors('customerInstructions')}
            onInput={() => setFieldTouched('customerInstructions')}
          />
        </Section>
      </Form>
    </ContentLoader>
  );
};

BuyerLotForm.propTypes = {
  auth: PropTypes.shape({
    attempts: PropTypes.number,
    user: PropTypes.object,
    access: PropTypes.object,
    isPending: PropTypes.bool,
  }).isRequired,
  companyLocations: PropTypes.shape({
    isPending: PropTypes.bool,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string,
        relatedCompany: PropTypes.string,
        name: PropTypes.string,
      }),
    ),
  }).isRequired,
};

export { BuyerLotForm };
