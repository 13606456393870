import {
  clearAgreementsListActionType,
  getAgreementsActionType,
  getAgreementsFailureActionType,
  getAgreementsSuccessActionType,
} from 'actions/Companies/Details/agreementsList';

const INITIAL_STATE = {
  isPending: false,
  errorMessage: null,
  agreements: [],
  limit: 3,
  page: 1,
  pages: null,
  total: null,
  items: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getAgreementsActionType:
      return {
        ...state,
        isPending: true,
      };
    case getAgreementsSuccessActionType:
      return {
        ...state,
        isPending: false,
        agreements: action.payload.agreements,
        pages: action.payload.pages,
        total: action.payload.total,
        items: { docs: action.payload.agreements },
      };
    case getAgreementsFailureActionType:
      return {
        ...state,
        isPending: false,
        errorMessage: action.payload.errorMessage,
      };
    case clearAgreementsListActionType:
      return {
        isPending: false,
        errorMessage: null,
        agreements: [],
        limit: 3,
        page: 1,
        pages: null,
        total: null,
        items: [],
      };
    default:
      return state;
  }
};
