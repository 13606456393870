import { StyledDetailsNotes } from 'shared/components/Details/DetailsNote/StyledDetailsNote';
import { NotesPanel } from 'shared/components/Notes/NotesPanel/NotesPanel';

const DetailsNotes = ({ id, ...props }) => {
  return (
    <StyledDetailsNotes id={id}>
      <NotesPanel {...props} />
    </StyledDetailsNotes>
  );
};

export { DetailsNotes };
