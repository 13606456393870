import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { getCompanyLocations } from 'actions/Locations/getCompanyLocations';
import {
  clearSelfDeliveryRequestsOverview,
  getSelfDeliveryRequestsOverviewList,
} from 'actions/SelfDeliveryRequests/selfDeliveryRequestsOverview';
import {
  estDeliveryDateFromFilterAction,
  estDeliveryDateToFilterAction,
  estPickupDateFromFilterAction,
  estPickupDateToFilterAction,
  sentFromCompanyFilterAction,
  sentFromCompanyLocationFilterAction,
  shipmentsFiltersClearAction,
  statusFilterAction,
} from 'actions/Shipments/shipmentsFilters';
import { clearShipmentsListAction, getShipmentsList } from 'actions/Shipments/shipmentsList';
import {
  clearIncomingShipmentsOverview,
  getIncomingShipmentsOverview,
} from 'actions/Shipments/shipmentsOverview';

import { CustomerShipmentsOverviewLayout } from './CustomerShipmentsOverview';

const mapStateToProps = state => ({
  auth: state.auth,
  companyLocations: state.companyLocations,
  shipmentsFilters: state.shipmentsFilters,
  shipmentsList: state.shipmentsList,
  selfDeliveryRequests: state.selfDeliveryRequestsOverview,
  incomingShipments: state.incomingShipmentsOverview,
});

const mapDispatchToProps = {
  clearShipmentsListAction,
  estDeliveryDateFromFilterAction,
  estDeliveryDateToFilterAction,
  estPickupDateFromFilterAction,
  estPickupDateToFilterAction,
  getCompanyLocations,
  getShipmentsList,
  sentFromCompanyFilterAction,
  sentFromCompanyLocationFilterAction,
  shipmentsFiltersClearAction,
  statusFilterAction,
  getSelfDeliveryRequestsOverviewList,
  clearSelfDeliveryRequestsOverview,
  getIncomingShipmentsOverview,
  clearIncomingShipmentsOverview,
};

const CustomerShipmentsOverview = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CustomerShipmentsOverviewLayout),
);

export { CustomerShipmentsOverview };
