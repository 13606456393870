import React from 'react';
import styled from 'styled-components';

import { OverviewTable } from 'shared/components/OverviewList/OverviewTable/OverviewTable';
import { OverviewTableMobile } from 'shared/components/OverviewList/OverviewTableMobile/OverviewTableMobile';
import { isTablet } from 'shared/helpers/constants/resolutionsConstants';
import { useWindowWidth } from 'shared/hooks/useWindowWidth';

import {
  tableDetailsTemplate,
  itemsTemplate,
  actionsTemplate,
  itemsTemplateMobile,
} from './itemsConfig';

const ListItem = styled.div`
  grid-area: ${({ id }) => id};
`;

const HedgeOuncesList = ({ id, assignedLots, _id, marketAvailabilityDetails, showSnackbar }) => {
  const windowWidth = useWindowWidth();

  return (
    <ListItem id={id}>
      {windowWidth < isTablet && !!itemsTemplateMobile ? (
        <OverviewTableMobile
          itemsTemplate={itemsTemplate}
          actionsTemplate={actionsTemplate(_id, marketAvailabilityDetails, showSnackbar)}
          itemsTemplateMobile={itemsTemplateMobile}
          itemDetailsTemplate={tableDetailsTemplate}
          items={assignedLots}
          fillContent
        />
      ) : (
        <OverviewTable
          itemsTemplate={itemsTemplate}
          actionsTemplate={actionsTemplate(_id, marketAvailabilityDetails, showSnackbar)}
          items={assignedLots}
          detailsList
        />
      )}
    </ListItem>
  );
};

export { HedgeOuncesList };
