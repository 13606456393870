import axios from 'axios';

import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';
import { messageTypes } from 'shared/helpers/constants/snackbar/snackbarMessageTypes';

const API_HOST_AUTH = process.env.REACT_APP_API;

const deleteLotActionType = 'DELETE_LOT';
function deleteLotAction() {
  return {
    type: deleteLotActionType,
  };
}

const deleteLotSuccessActionType = 'DELETE_LOT_SUCCESS';
function deleteLotSuccessAction(lot) {
  return {
    type: deleteLotSuccessActionType,
    payload: {
      lot,
    },
  };
}

const deleteLotFailureActionType = 'DELETE_LOT_FAILURE';
function deleteLotFailureAction() {
  return {
    type: deleteLotFailureActionType,
  };
}

function deleteLot(id) {
  return dispatch => {
    dispatch(deleteLotAction());

    return axios
      .delete(`${API_HOST_AUTH}/lots/${id}`)
      .then(response => {
        dispatch(deleteLotSuccessAction(response.data));
        return {
          message: response.data.message,
          messageType: messageTypes.success,
        };
      })
      .catch(error => {
        dispatch(deleteLotFailureAction());
        return {
          message: `${LOTS.ERRORS.DELETE_LOT_ERROR} ${error.data.message}`,
          messageType: messageTypes.error,
        };
      });
  };
}

export {
  deleteLotActionType,
  deleteLotAction,
  deleteLotSuccessActionType,
  deleteLotSuccessAction,
  deleteLotFailureActionType,
  deleteLotFailureAction,
  deleteLot,
};
