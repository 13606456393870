import React, { Component } from 'react';

import { UIAuthorization } from 'components/Authorization/UIAuthorization';

import { RouterButton } from 'shared/components/Buttons';
import { ContentLoader } from 'shared/components/ContentLoader/ContentLoader';
import { DetailsHeader } from 'shared/components/Details/DetailsHeader';
import { renderDetailsContainer } from 'shared/components/Details/helpers/renderDetailsContainer';
import { USER_TYPES } from 'shared/helpers/constants/filters/userTypesConstants';
import { USER } from 'shared/helpers/constants/users/userConstants';

import { DashboardPreferences } from './components/Preferences/DashboardPreferences';
import { NotificationPreferences } from './components/Preferences/NotificationPreferences/NotificationPreferences';
import {
  userProfileDetailsContainerConfig,
  userProfileDetailsFieldsConfig,
} from './config/fieldsConfig';

class UserProfileLayout extends Component {
  componentDidMount = () => {
    this.props.getUser(this.props.auth.user.id);
  };

  render() {
    const {
      userDetails: { user, isPending },
      auth,
    } = this.props;

    return (
      <ContentLoader loaded={user && user.notificationPreferences && !isPending}>
        {() => (
          <>
            <DetailsHeader header={USER.MY_PROFILE}>
              <RouterButton
                outline
                id="changeUsersPassword"
                path="/my-profile/change-password"
                label={USER.CHANGE_PASSWORD}
              />
            </DetailsHeader>
            {renderDetailsContainer(
              userProfileDetailsContainerConfig,
              userProfileDetailsFieldsConfig,
              user,
            )}
            {user.notificationPreferences && (
              <UIAuthorization access={user.userType !== USER_TYPES.EXTERNAL.VALUE}>
                <ContentLoader loaded={!this.props.updatingNotificationPreferences.isPending}>
                  <NotificationPreferences
                    updateNotificationPreferences={this.props.updateNotificationPreferences}
                    user={user}
                    showSnackbar={this.props.showSnackbar}
                    getUser={this.props.getUser}
                    openModal={this.props.openModal}
                    initialValues={user.notificationPreferences}
                  />
                </ContentLoader>
              </UIAuthorization>
            )}
            {user.dashboardPreferences && (
              <UIAuthorization access={auth.access.users.dashboardPreferences}>
                <DashboardPreferences
                  user={user}
                  showSnackbar={this.props.showSnackbar}
                  getUser={this.props.getUser}
                  openModal={this.props.openModal}
                  updateDashboardPreferences={this.props.updateDashboardPreferences}
                  initialValues={user.dashboardPreferences}
                />
              </UIAuthorization>
            )}
          </>
        )}
      </ContentLoader>
    );
  }
}

export { UserProfileLayout };
