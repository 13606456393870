const message = {
  INVALID_TREATMENT_CHARGE_PER_POUND: 'Treatment charge per pound must have a valid format.',
  INVALID_SMALL_LOT_CHARGE: 'Small lot charge must have a valid format.',
  INVALID_LARGE_LOT_CHARGE: 'Large lot charge must have a valid format.',
  INVALID_PLATINUM_RETURN_RATE: 'Platinum return rate must have a valid format.',
  INVALID_PLATINUM_REFINING_CHARGE: 'Platinum refining charge must have a valid format.',
  INVALID_PALLADIUM_RETURN_RATE: 'Palladium return rate must have a valid format.',
  INVALID_PALLADIUM_REFINING_CHARGE: 'Palladium refining charge must have a valid format.',
  INVALID_RHODIUM_RETURN_RATE: 'Rhodium return rate must have a valid format.',
  INVALID_RHODIUM_REFINING_CHARGE: 'Rhodium refining charge must have a valid format.',
  INVALID_ADVANCE_RATE: 'Advance rate must have a valid format.',
  INVALID_METALS_RETURN_TERMIN_DAYS: 'Metals return term in days must have a valid format.',
  INVALID_METALS_RETURN_FINANCE_RATE: 'Metals return finance rate must have a valid format.',
  INVALID_START_DATE: 'Start date must have a valid format.',
  INVALID_END_DATE: 'End date must have a valid format.',

  INVALID_START_DATE_VALUE: 'Start date is later than end date.',
  INVALID_END_DATE_VALUE: 'End date is earlier than start date.',
  INVALID_START_DATE_VALUE_SAME: 'Start date must be earlier than end date.',
  INVALID_END_DATE_VALUE_SAME: 'End date must be later than start date.',

  INVALID_START_DATE_PAST: 'Start date cannot be in the past.',
  INVALID_END_DATE_PAST: 'End date cannot be in the past.',

  INVALID_TREATMENT_CHARGE_PER_POUND_EMPTY: 'Treatment charge per pound cannot be empty.',
  INVALID_SMALL_LOT_CHARGE_EMPTY: 'Small lot charge cannot be empty.',
  INVALID_LARGE_LOT_CHARGE_EMPTY: 'Large lot charge cannot be empty.',
  INVALID_PLATINUM_RETURN_RATE_EMPTY: 'Platinum return rate cannot be empty.',
  INVALID_PLATINUM_REFINING_CHARGE_EMPTY: 'Platinum refining charge cannot be empty.',
  INVALID_PALLADIUM_RETURN_RATE_EMPTY: 'Palladium return rate cannot be empty.',
  INVALID_PALLADIUM_REFINING_CHARGE_EMPTY: 'Palladium refining charge cannot be empty.',
  INVALID_RHODIUM_RETURN_RATE_EMPTY: 'Rhodium return rate cannot be empty.',
  INVALID_RHODIUM_REFINING_CHARGE_EMPTY: 'Rhodium refining charge cannot be empty.',
  INVALID_ADVANCE_RATE_EMPTY: 'Advance rate cannot be empty.',
  INVALID_METALS_RETURN_TERMIN_DAYS_EMPTY: 'Metals return term in days cannot be empty.',
  INVALID_METALS_RETURN_FINANCE_RATE_EMPTY: 'Metals return finance rate cannot be empty.',
  INVALID_START_DATE_EMPTY: 'Start date cannot be empty.',
  INVALID_END_DATE_EMPTY: 'End date cannot be empty.',
  DIGITAL_SIGNATURE_REQUIRED_EMPTY: 'Digital signature required cannot be empty.',

  INVALID_TREATMENT_CHARGE_PER_POUND_RANGE: 'Treatment charge per pound cannot be negative.',
  INVALID_SMALL_LOT_CHARGE_RANGE: 'Small lot charge cannot be negative.',
  INVALID_LARGE_LOT_CHARGE_RANGE: 'Large lot charge cannot be negative.',
  INVALID_PLATINUM_RETURN_RATE_RANGE: 'Platinum return rate should be number between 0 and 100.',
  INVALID_PLATINUM_REFINING_CHARGE_RANGE: 'Platinum refining charge cannot be more than 0.',
  INVALID_PALLADIUM_RETURN_RATE_RANGE: 'Palladium return rate should be number between 0 and 100.',
  INVALID_PALLADIUM_REFINING_CHARGE_RANGE: 'Palladium refining charge cannot be more than 0.',
  INVALID_RHODIUM_RETURN_RATE_RANGE: 'Rhodium return rate should be number between 0 and 100.',
  INVALID_RHODIUM_REFINING_CHARGE_RANGE: 'Rhodium refining charge cannot be more than 0.',
  INVALID_ADVANCE_RATE_RANGE: 'Advance rate value should be number between 0 and 100.',
  INVALID_METALS_RETURN_TERMIN_DAYS_RANGE:
    'Metals return term in days should be number between 0 and 150.',
  INVALID_METALS_RETURN_FINANCE_RATE_RANGE:
    'Metals return finance rate should be number between 0 and 100.',
};

export { message };
