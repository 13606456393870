import { flatten } from 'utils/flatten';

function capitalize(value) {
  if (!value) return value;
  return `${value.charAt(0).toUpperCase() + value.slice(1)}`;
}

function capitalizeGlobal(label) {
  return label ? label.replace('global', 'Alpha') : null;
}

function humanize(value, postParse = p => p) {
  if (!value) return value;
  const humanizedValue = capitalize(value.replace(/[_]+/g, ' '));
  return postParse(humanizedValue);
}

function humanizeCamelCase(value) {
  if (!value) return value;

  return capitalizeGlobal(
    value.match(/^[a-z]+|[A-Z][a-z]+/g).reduce((acc, str, index) => {
      if (!index) {
        return `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
      }

      return `${acc} ${str.toLowerCase()}`;
    }, ''),
  );
}

function toKebabCase(value) {
  return value ? humanize(value).replace(/ +/g, '-') : value;
}

function parseUserType(type) {
  return `${capitalize(type)} user`;
}

function replaceEmpty(value, filler = '-') {
  if (typeof value === 'undefined') {
    return filler;
  }
  if (typeof value === 'number') {
    return value.toString();
  }
  return value || filler;
}

function toSnakeCase(value) {
  return value.toLowerCase().replace(/ /g, '_');
}

function toCamelCase(value, separators = [' '], forceLowerCase = true) {
  return separators
    .reduce((str, separator) => flatten(str.map(part => part.split(separator))), [value])
    .map(str => (forceLowerCase ? str.toLowerCase() : str))
    .reduce((str, part) => str + part.replace(/^[a-zA-Z]/, char => char.toUpperCase()));
}

function safeReplace(value, pattern, to) {
  if (typeof value === 'string') {
    return value.replace(new RegExp(pattern, 'g'), to);
  }
  return value;
}

function checkTrim(value) {
  return value !== null && value.trim();
}

function camelToSentence(camelCase) {
  return camelCase
    .replace(/([A-Z])/g, match => ` ${match}`)
    .replace(/[A-Z]/, match => match.toLowerCase())
    .replace(/^./, match => match.toUpperCase());
}

function capitalizeNotAvailable(label) {
  return label === 'na' ? label.toUpperCase() : label;
}

function camelToKebab(value) {
  return toKebabCase(camelToSentence(value)).toLowerCase();
}

function spliceString(value, length = 25) {
  return value.length >= length ? `${value.substring(0, length)}...` : value;
}

function removeCommasAndParse(value) {
  if (typeof value === 'string') {
    return +value.replace(/,/g, '');
  }

  return value;
}

function insertCommas(value) {
  if (typeof value === 'string') {
    const [integer, fractions] = value.split('.');

    let integerWithCommas = Math.abs(integer)
      .toString()
      .split('')
      .reverse()
      .join('')
      .match(/.{1,3}/g)
      .map((v, i) => (i === 0 ? v : ',' + v))
      .join('')
      .split('')
      .reverse()
      .join('');

    if (value < 0) {
      integerWithCommas = `-${integerWithCommas}`;
    }

    return fractions ? [integerWithCommas, fractions].join('.') : integerWithCommas;
  }

  return value;
}

function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

const replaceNameElements = element => {
  switch (element) {
    case 'Ounces pt':
      return (element = 'Ounces Pt');
    case 'Ounces pd':
      return (element = 'Ounces Pd');
    case 'Ounces rh':
      return (element = 'Ounces Rh');
    case 'Price per ounce pt':
      return (element = 'Price per ounce Pt');
    case 'Price per ounce pd':
      return (element = 'Price per ounce Pd');
    case 'Price per ounce rh':
      return (element = 'Price per ounce Rh');
    default:
      return element;
  }
};

export {
  capitalize,
  humanize,
  humanizeCamelCase,
  replaceEmpty,
  parseUserType,
  toSnakeCase,
  toCamelCase,
  camelToKebab,
  safeReplace,
  checkTrim,
  camelToSentence,
  toKebabCase,
  capitalizeGlobal,
  capitalizeNotAvailable,
  spliceString,
  removeCommasAndParse,
  insertCommas,
  escapeRegExp,
  replaceNameElements,
};
