import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getTermsListActionType = 'GET_TERMS_LIST';
function getTermsListAction() {
  return {
    type: getTermsListActionType,
  };
}

const getTermsListSuccessActionType = 'GET_TERMS_LIST_SUCCESS';
function getTermsListSuccessAction({ docs, pages, limit, page, company }) {
  return {
    type: getTermsListSuccessActionType,
    payload: {
      terms: docs,
      pages,
      limit,
      page,
      company,
    },
  };
}

const getTermsListFailureActionType = 'GET_TERMS_LIST_FAILURE';
function getTermsListFailureAction(errorMessage) {
  return {
    type: getTermsListFailureActionType,
    payload: {
      errorMessage,
    },
  };
}

const clearTermsListActionType = 'CLEAR_TERMS_LIST_ACTION';
function clearTermsList() {
  return {
    type: clearTermsListActionType,
  };
}

function parseData(companyId, filters, limit, page) {
  return {
    companyId,
    ...filters,
    limit,
    page,
  };
}

function getTermsList(companyId, limit, page, termsFilters) {
  return dispatch => {
    dispatch(getTermsListAction());

    return axios({
      method: 'GET',
      url: `${API_HOST_AUTH}/terms`,
      params: parseData(companyId, termsFilters, limit, page),
    })
      .then(({ data }) => dispatch(getTermsListSuccessAction(data)))
      .catch(() => dispatch(getTermsListFailureAction('')));
  };
}

export {
  getTermsListActionType,
  getTermsListSuccessActionType,
  getTermsListFailureActionType,
  clearTermsListActionType,
  getTermsList,
  clearTermsList,
};
