import {
  clearPoolAccountsActionType,
  getPoolAccountsActionType,
  getPoolAccountsFailureActionType,
  getPoolAccountsSuccessActionType,
} from 'actions/PoolAccounts/poolAccountsList';

const INITIAL_STATE = {
  isPending: false,
  poolAccounts: {},
  pagination: { limit: 50, page: 1 },
  requestSent: false,
};

const poolAccountsListReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getPoolAccountsActionType:
      return {
        ...state,
        pagination: action.payload.pagination,
        isPending: true,
        requestSent: true,
      };
    case getPoolAccountsSuccessActionType:
      return {
        ...state,
        isPending: false,
        poolAccounts: action.payload.poolAccounts,
      };
    case getPoolAccountsFailureActionType:
      return {
        ...state,
        isPending: false,
      };
    case clearPoolAccountsActionType:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};

export { poolAccountsListReducer };
