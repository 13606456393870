import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { marketAvailability } from 'actions/Hedges/marketAvailability';
import {
  getSummaryAvailableLots,
  clearSummaryAvailableLots,
} from 'actions/PoolAccounts/getSummaryAvailableLots';
import { settlementForLotRequest } from 'actions/PoolAccounts/requestSettlement';
import { closeModal, openModal } from 'actions/shared/modal';
import { showSnackbar } from 'actions/shared/snackbar';

import { SettlementForLot } from './SettlementForLot';

const mapStateToProps = state => ({
  auth: state.auth,
  modal: state.modal,
  summaryAvailableLots: state.summaryAvailableLots,
});

const mapDispatchToProps = {
  clearSummaryAvailableLots,
  closeModal,
  getSummaryAvailableLots,
  marketAvailability,
  openModal,
  settlementForLotRequest,
  showSnackbar,
};

const ConnectedSettlementForLot = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SettlementForLot),
);

export { ConnectedSettlementForLot };
