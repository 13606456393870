import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { UIAuthorization } from 'components/Authorization/UIAuthorization';
import { DatepickerDropdown } from 'shared/components/DatepickerDropdown/DatepickerDropdown';
import { FiltersDropdown } from 'shared/components/FiltersDropdown/FiltersDropdown';
import { LocationsDropdown } from 'shared/components/LocationsDropdown/LocationsDropdown';
import { FILTERS } from 'shared/helpers/constants/filters/filtersConstants';
import { hedgedStatus as hedgedStatuses } from 'shared/helpers/constants/filters/hedgedStatus';
import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';
import { statusesForCustomer } from 'shared/helpers/constants/lots/statusesForCustomer';
import { statusesForGlobal } from 'shared/helpers/constants/lots/statusesForGlobal';
import { statusesForGrading } from 'shared/helpers/constants/lots/statusesForGrading';
import { isInternalCompany } from 'shared/helpers/matchers/checkCompanyType';
import { isGradingUser } from 'shared/helpers/matchers/checkUserType';
import { capitalizeGlobal } from 'shared/helpers/parsers/text';

import dateAdapter from 'utils/date/dateAdapter';
import { MomentAdapter } from 'utils/date/momentAdapter';

import { StyledLotsFilters, StyledLotsFiltersItem } from '../StyledLotFilters';
import { sentFromCompanyLocationFilter } from "actions/Lots/lotsFilters";

export const CustomerLotsFilters = ({ filters, onFiltersChanged, companyLocations }) => {
  const {
    userType,
    relatedCompany: { companyType },
  } = useSelector(state => state.auth.user);
  const dispatch = useDispatch();

  const {
    hedgedStatus,
    statusForCustomer,
    receivedAtStart,
    receivedAtEnd,
    statusForGlobal,
    sentFromCompanyLocation,
  } = filters;
  const capitalizedStatusForGlobal = statusForGlobal[0] && [capitalizeGlobal(statusForGlobal[0])];

  const filterFunction = name => value => {
    onFiltersChanged({
      ...filters,
      [name]: value instanceof MomentAdapter ? value.valueOf() : value,
    });
  };

  const renderDateFilter = (name, label, minDate, maxDate, selected) => (
    <StyledLotsFiltersItem>
      <DatepickerDropdown
        label={label}
        selected={dateAdapter(selected)}
        filterFunction={filterFunction(name)}
        minDate={dateAdapter(minDate || 0)}
        maxDate={dateAdapter(maxDate)}
      />
    </StyledLotsFiltersItem>
  );

  return (
    <StyledLotsFilters>
      <StyledLotsFiltersItem>
        {isInternalCompany(companyType) ? (
          <FiltersDropdown
            label={LOTS.STATUS_FOR_GLOBAL}
            options={Object.values(statusesForGlobal)}
            selected={capitalizedStatusForGlobal || []}
            filterFunction={filterFunction('statusForGlobal')}
          />
        ) : (
          <FiltersDropdown
            label={LOTS.STATUS_FOR_CUSTOMER}
            options={
              isGradingUser(userType)
                ? Object.values(statusesForGrading)
                : Object.values(statusesForCustomer)
            }
            selected={statusForCustomer || []}
            filterFunction={filterFunction('statusForCustomer')}
          />
        )}
      </StyledLotsFiltersItem>
      <UIAuthorization access={!isGradingUser(userType)}>
        <StyledLotsFiltersItem>
          <FiltersDropdown
            label={LOTS.HEDGED_STATUS}
            options={Object.values(hedgedStatuses)}
            selected={hedgedStatus || []}
            filterFunction={filterFunction('hedgedStatus')}
          />
        </StyledLotsFiltersItem>
      </UIAuthorization>
      <StyledLotsFiltersItem>
        <LocationsDropdown
          label={LOTS.SHORTENED_SENT_FROM_COMPANY_LOCATION}
          options={companyLocations.data}
          selected={sentFromCompanyLocation || []}
          filterFunction={filter => dispatch(sentFromCompanyLocationFilter(filter))}
          filterOptionsSelected={filterFunction('sentFromCompanyLocation')}
        />
      </StyledLotsFiltersItem>
      {renderDateFilter(
        'receivedAtStart',
        FILTERS.LABELS.RECEIVED_ON_FROM,
        0,
        Infinity,
        receivedAtStart,
      )}
      {renderDateFilter(
        'receivedAtEnd',
        FILTERS.LABELS.RECEIVED_ON_TO,
        receivedAtStart,
        Infinity,
        receivedAtEnd,
      )}
    </StyledLotsFilters>
  );
};

CustomerLotsFilters.propTypes = {
  filters: PropTypes.shape({
    hedgedStatus: PropTypes.arrayOf(PropTypes.string),
    materialType: PropTypes.arrayOf(PropTypes.string),
    receivedAtEnd: PropTypes.number,
    receivedAtStart: PropTypes.number,
    sentFromCompanyLocation: PropTypes.shape({
      _id: PropTypes.string,
      relatedCompany: PropTypes.string,
      name: PropTypes.string,
    }),
    statusForCustomer: PropTypes.arrayOf(PropTypes.string),
    statusForGlobal: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  onFiltersChanged: PropTypes.func.isRequired,
  companyLocations: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      isPending: PropTypes.bool,
      data: PropTypes.arrayOf(
        PropTypes.shape({
          _id: PropTypes.string,
          relatedCompany: PropTypes.string,
          name: PropTypes.string,
        }),
      ),
    }),
  ).isRequired,
};
