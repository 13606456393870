import { dateToTimestamp } from 'shared/helpers/parsers/date';
import { safeReplace } from 'shared/helpers/parsers/text';

const commonParse = ({ platinumOz, palladiumOz, rhodiumOz }) => ({
  platinumOz: +safeReplace(platinumOz, ',', '.'),
  palladiumOz: +safeReplace(palladiumOz, ',', '.'),
  rhodiumOz: +safeReplace(rhodiumOz, ',', '.'),
});

const parsePoolAccount = data => ({
  ...commonParse(data),
  relatedCompany: data.relatedCompany?.value,
  fundsType: data.fundsType?.value,
  requestedFinalPaymentDate:
    data.requestedFinalPaymentDate && dateToTimestamp(data.requestedFinalPaymentDate),
  additionalInfo: data.additionalInfo,
});

const settlementMockUpAndForLot = poolAccount => ({
  ...commonParse(poolAccount),
  relatedLot: poolAccount.relatedLot.value || poolAccount.relatedLot,
});

const parseOneSettlementForLot = ({
  requestPayment,
  fundsType,
  requestedFinalPaymentDate,
  additionalInfo,
  ...poolAccount
}) => ({
  ...commonParse(poolAccount),
  relatedLot: poolAccount.relatedLot.value || poolAccount.relatedLot,
  requestPayment,
  fundsType: fundsType && fundsType.value,
  requestedFinalPaymentDate:
    requestedFinalPaymentDate && dateToTimestamp(requestedFinalPaymentDate),
  additionalInfo,
});

const parseDelivery = ({ relatedLot, relatedHedge, ...poolAccount }) => ({
  ...commonParse(poolAccount),
  relatedLot,
  relatedHedge: relatedHedge.value,
});

const settlementForLot = ({ settlements }) => ({
  settlements: settlements.map(parseOneSettlementForLot),
});

const settlementForLotWizard = ({ requestSettlementWizardData: data, userType }) =>
  Object.entries(data).reduce(
    (acc, [lotId, { settlement, deliveries }]) => ({
      ...acc,
      settlements: [...acc.settlements, parseOneSettlementForLot(settlement)],
      deliveries: [...acc.deliveries, ...deliveries.map(parseDelivery)],
    }),
    {
      settlements: [],
      deliveries: [],
    },
  );

export {
  parsePoolAccount,
  commonParse,
  settlementMockUpAndForLot,
  settlementForLot,
  settlementForLotWizard,
};
