import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { Field, reduxForm } from 'redux-form';

import { Button } from 'shared/components/Buttons';
import { FieldInput, FieldDate, FieldSelect } from 'shared/components/Fields/index';
import { Form, Section } from 'shared/components/Form';
import { Icon } from 'shared/components/Icon/Icon';
import { LocationsSelect } from 'shared/components/LocationsSelect/LocationsSelect';
import { cancelModal } from 'shared/helpers/constants/modalConstants';
import { PACKAGES } from 'shared/helpers/constants/packages/packageConstants';
import { SHIPMENTS } from 'shared/helpers/constants/shipments/shipmentsConstants';
import { validate } from 'shared/helpers/validations/shipments/customerShipmentValidations';

import dateAdapter from 'utils/date/dateAdapter';

import { SelectAllPackages } from 'pages/Shipments/components/SelectAllPackages/SelectAllPackages';
import { PackingListSection } from 'pages/Shipments/components/ShipmentsManagement/CreateShipmentWizard/components/PackingListSection/PackingListSection';

import { CustomerPackagesOverview } from '../components/CustomerPackagesOverview/CustomerPackagesOverview';

const formName = 'ShipmentForm';

const ShipmentFormBase = props => {
  const {
    clearShipmentPackagesList,
    editMode,
    initialValues,
    shipmentPackagesList,
    touch,
    openModal,
    handleSubmit,
    change,
    invalid,
    destroy,
    auth: {
      user: { relatedCompany },
    },
    history,
    location,
    match,
    shipmentPackagesListData: { packages },
    valid,
    getCompaniesContact,
    wizard,
    isPackingList,
  } = props;

  const [pickUpMax, setPickUpMax] = useState();
  const [deliveryMin, setDeliveryMin] = useState(dateAdapter());
  const fieldName = isPackingList ? 'createShipment.' : '';

  const goToNextStep = () =>
    isPackingList &&
    location.state?.fromPackingListView &&
    (history.replace('/shipments/create-incoming-wizard', {
      ...props.location.state,
      fromPackingListView: false,
    }),
    wizard.next());

  useEffect(() => {
    if (editMode) {
      Object.keys(initialValues).map(f => touch(f));
      shipmentPackagesList(initialValues.packages);
    }
    if (!editMode && initialValues.relatedCompany) {
      shipmentPackagesList(initialValues.relatedCompany.packages);
    }

    goToNextStep();

    return () => {
      if (history.location.pathname === '/shipments') destroy('wizard');
    };
  }, [shipmentPackagesList, editMode]);

  const submitForm = useCallback(
    async e => {
      await handleSubmit(e);

      if (!isPackingList) {
        destroy(formName);
        clearShipmentPackagesList();
      }
    },
    [handleSubmit, destroy, clearShipmentPackagesList],
  );

  const modalOpen = useCallback(() => {
    openModal(cancelModal, () => {
      destroy(formName);
      clearShipmentPackagesList();
      history.push(editMode ? `/shipments/${match.params.id}` : '/shipments', {});
    });
  }, [openModal, destroy, clearShipmentPackagesList, editMode, match, history]);

  const createPackagesAction = useMemo(
    () => (
      <Button
        alignToLeft
        onClick={() =>
          history.push('/shipments/packages/create', {
            fromShipmentForm: true,
            referenceUrl: location.pathname,
          })
        }
      >
        {PACKAGES.CREATE_PACKAGES}
        <Icon icon="icon-plus" />
      </Button>
    ),
    [history, location],
  );

  return (
    <Form
      header={SHIPMENTS.CREATE_A_SHIPMENT}
      onSubmit={submitForm}
      onCancel={modalOpen}
      submitDisabled={invalid || props.submitting || (!isPackingList && !packages.length)}
    >
      <Section
        template={[
          `${fieldName}relatedCompany ${fieldName}sentFromCompanyLocation`,
          `${fieldName}estPickupDate ${fieldName}estDeliveryDate`,
          `${fieldName}shipmentCompany ${fieldName}shipmentSeal`,
        ]}
      >
        <Field
          name={`${fieldName}relatedCompany`}
          component={FieldSelect}
          label={SHIPMENTS.RELATED_COMPANY}
          field="required"
          disabled
        />
        <LocationsSelect
          {...props}
          name={`${fieldName}sentFromCompanyLocation`}
          selectedForm={isPackingList ? 'wizard' : 'ShipmentForm'}
          fieldName={fieldName}
        />
        <Field
          name={`${fieldName}estPickupDate`}
          component={FieldDate}
          label={SHIPMENTS.EST_PICKUP_DATE}
          field="required"
          minDate={dateAdapter()}
          maxDate={pickUpMax}
          onChange={setDeliveryMin}
        />
        <Field
          name={`${fieldName}estDeliveryDate`}
          component={FieldDate}
          label={SHIPMENTS.EST_DELIVERY_DATE}
          field="required"
          minDate={deliveryMin}
          onChange={setPickUpMax}
        />
        {editMode && (
          <Field
            name={`${fieldName}shipmentCompany`}
            component={FieldInput}
            label={SHIPMENTS.SHIPMENT_COMPANY}
          />
        )}
        {editMode && (
          <Field
            name={`${fieldName}shipmentSeal`}
            component={FieldInput}
            label={SHIPMENTS.SHIPMENT_SEAL}
          />
        )}
      </Section>
      {!isPackingList && (
        <Section
          header={<SelectAllPackages hasError={!packages.length && valid} />}
          actions={createPackagesAction}
          headerPadding="20px"
          template={['packages packages']}
          noPadding
        >
          <div name="packages">
            <CustomerPackagesOverview
              packageForCompany
              companyId={relatedCompany._id}
              editMode={editMode}
              {...props}
            />
          </div>
        </Section>
      )}
      {isPackingList && (
        <PackingListSection
          fieldName={fieldName}
          relatedCompany={relatedCompany}
          change={change}
          getCompaniesContact={getCompaniesContact}
        />
      )}
    </Form>
  );
};

const ShipmentForm = reduxForm({
  form: formName,
  validate: (values, props) =>
    props.form === 'wizard'
      ? {
          createShipment: validate({ ...values.createShipment }, props),
        }
      : validate(values, props),
  destroyOnUnmount: false,
})(ShipmentFormBase);

export { ShipmentForm, ShipmentFormBase };
