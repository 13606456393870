import PropTypes from 'prop-types';
import React, { useMemo, Children } from 'react';
import styled from 'styled-components';

import { cssVar, media } from 'shared/helpers/styling/styling';

const Container = styled.div`
  display: grid;
  grid-template-columns: ${({ itemsNumber }) => `repeat(${itemsNumber}, 1fr)`};
  grid-template-rows: 1fr;
  width: fit-content;
  border: ${({ borderColor }) => `2px solid ${cssVar(borderColor)}`};
  border-radius: 17px;
  margin: 0 10px;
  padding: 0;

  ${media.mobile`
    grid-template-columns: ${({ itemsNumber }) => (itemsNumber > 1 ? 'repeat(2, 90px)' : '90px')};
  `}

  @media print {
    display: none;
  }
`;

function GroupButtons({ children, borderColor = 'atlantis' }) {
  const items = useMemo(() => {
    const elements = Children.toArray(children).filter(child =>
      'access' in child.props ? child.props.access : true,
    );

    if (elements.length === 1) {
      return [React.cloneElement(elements[0], { only: true })];
    }
    return elements;
  }, [children]);

  return (
    !!items.length && (
      <Container itemsNumber={items.length} borderColor={borderColor}>
        {items}
      </Container>
    )
  );
}

GroupButtons.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  borderColor: PropTypes.string,
};

export { GroupButtons };
