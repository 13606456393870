import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { enterPrices as enterPricesAction } from 'actions/Hedges/enterPrices';
import { getHedgeDetails, clearHedgeDetailsAction } from 'actions/Hedges/hedgesDetails';
import { showSnackbar } from 'actions/shared/snackbar';

import { AccessPermission } from 'components/Authorization/AccessPermission';

import { FormContainer } from 'shared/components/Form';
import { Preloader } from 'shared/components/Preloader/Preloader';
import { enterPricesAccess } from 'shared/helpers/accesses/hedges';
import { updatePricesAccess } from 'shared/helpers/accesses/hedges/specialPermissions/updatePricesAccess';
import { HEDGES } from 'shared/helpers/constants/hedges/hedgesConstants';
import {
  dangerOptions,
  successOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { removeCommasAndParse } from 'shared/helpers/parsers/text';

import { EnterPricesForm, priceType } from './EnterPricesForm/EnterPricesForm';

const EnterPricesBase = ({ match, history, superAdminMode }) => {
  const { isPending } = useSelector(({ enterPrices }) => enterPrices);
  const hedgeDetails = useSelector(({ hedgesDetails }) => hedgesDetails);
  const auth = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getHedgeDetails(match.params.id)).catch(() => history.push('/hedges'));

    return () => dispatch(clearHedgeDetailsAction());
  }, [dispatch, history, match]);

  const onSubmit = ({ platinumPriceActual, palladiumPriceActual, rhodiumPriceActual }) => {
    const hedgeId = match.params.id;

    const parsedValues = {
      platinumPriceActual: removeCommasAndParse(platinumPriceActual),
      palladiumPriceActual: removeCommasAndParse(palladiumPriceActual),
      rhodiumPriceActual: removeCommasAndParse(rhodiumPriceActual),
      superAdminMode,
    };

    return dispatch(enterPricesAction(hedgeId, parsedValues))
      .then(res => {
        dispatch(showSnackbar(successOptions, res.message));
        navigateBack();
      })
      .catch(res => dispatch(showSnackbar(dangerOptions, res.message)));
  };

  const navigateBack = () => {
    history.replace(`/hedges/${match.params.id}`);
  };

  return (
    <>
      <FormContainer
        header={
          superAdminMode
            ? HEDGES.CHANGE_HEDGE_PRICES(hedgeDetails?.hedge?.hedgeName)
            : HEDGES.ENTER_HEDGE_PRICES(hedgeDetails?.hedge?.hedgeName)
        }
        loaded={hedgeDetails.hedge && !hedgeDetails.isPending}
      >
        {() => (
          <AccessPermission
            access={
              superAdminMode
                ? updatePricesAccess(auth, hedgeDetails.hedge)
                : enterPricesAccess(auth, hedgeDetails.hedge)
            }
          >
            <EnterPricesForm
              type={priceType.actual}
              hedge={hedgeDetails.hedge}
              onSubmit={onSubmit}
            />
          </AccessPermission>
        )}
      </FormContainer>
      <Preloader loading={isPending} />
    </>
  );
};

EnterPricesBase.propTypes = {
  match: PropTypes.shape({
    isExact: PropTypes.bool,
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
    path: PropTypes.string,
    url: PropTypes.string,
  }),
  superAdminMode: PropTypes.bool,
  history: PropTypes.object,
};

EnterPricesBase.defaultProps = {
  superAdminMode: false,
};

const EnterPrices = withRouter(EnterPricesBase);

export { EnterPrices, EnterPricesBase };
