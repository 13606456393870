import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { change, Field } from 'redux-form';

import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';
import { parseLocationsSelectData } from 'shared/helpers/dataParsers/location/parseLocationsSelectData';

import { FieldSelect } from '../Fields';

class LocationsSelectBase extends Component {
  componentDidMount() {
    const { sentFromCompany } = this.props;

    this.props
      .getCompanyLocations(sentFromCompany._id)
      .then(companyLocations => this.parseLocations(companyLocations));
  }

  parseLocations(companyLocations) {
    if (companyLocations?.length === 1) {
      this.props.dispatch(
        change(
          this.props.selectedForm,
          `${this.props.fieldName}sentFromCompanyLocation`,
          parseLocationsSelectData(companyLocations)[0],
        ),
      );
    }
  }

  render() {
    const { companyLocations, isPending } = this.props;

    return (
      <Field
        name={`${this.props.fieldName}sentFromCompanyLocation`}
        component={FieldSelect}
        options={parseLocationsSelectData(companyLocations)}
        disabled={!isPending && companyLocations.length === 1}
        searchable={false}
        label={LOTS.SENT_FROM_COMPANY_LOCATION}
        field="required"
      />
    );
  }
}

const mapStateToProps = state => ({
  companyLocations: state.companyLocations.data,
  isPending: state.companyLocations.isPending,
});

const LocationsSelect = connect(mapStateToProps)(LocationsSelectBase);

LocationsSelect.propTypes = {
  fieldName: PropTypes.string.isRequired,
  form: PropTypes.string.isRequired,
  sentFromCompany: PropTypes.shape({
    _id: PropTypes.string,
    companyName: PropTypes.string,
    companyType: PropTypes.string,
    converterDatabaseURL: PropTypes.string,
    lotPrefix: PropTypes.string,
    materialCountRequired: PropTypes.bool,
    rustedCompany: PropTypes.bool,
    primaryContact: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  syncBlurFields: PropTypes.arrayOf(PropTypes.string),
  auth: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  selectedForm: PropTypes.string,
  getCompaniesBySearchStr: PropTypes.func,
  getCompanyLocations: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

LocationsSelect.defaultProps = { fieldName: '' };

export { LocationsSelect };
