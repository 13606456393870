import React, { Component } from 'react';

import { FormContainer } from 'shared/components/Form';
import { message } from 'shared/helpers/constants/hedges/createMarketHolidaysConstants';
import { HEDGES } from 'shared/helpers/constants/hedges/hedgesConstants';
import { addMarketHolidaysModal } from 'shared/helpers/constants/modalConstants';
import {
  successOptions,
  dangerOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { dateFormat } from 'shared/helpers/parsers/date';

import dateAdapter from 'utils/date/dateAdapter';
import { MomentAdapter } from 'utils/date/momentAdapter';

import { MarketHolidaysForm } from '../MarketHolidaysForm/MarketHolidaysForm';

class MarketHolidaysCreate extends Component {
  componentDidMount() {
    const { getFutureHedges } = this.props;
    getFutureHedges();
  }

  modalCallback = values => {
    const { showSnackbar, createMarketHolidays, history, marketAvailability } = this.props;

    return createMarketHolidays(values)
      .then(() => {
        showSnackbar(successOptions, message.MARKET_HOLIDAY_CREATE_SUCCESS);

        marketAvailability();
        history.push('/hedges/market-holidays');
      })
      .catch(() => showSnackbar(dangerOptions, message.MARKET_HOLIDAY_CREATE_ERROR));
  };

  isDayWithScheduledHedges = ({ futureHedges, date }) =>
    futureHedges.some(({ requestedHedgeDate }) => {
      const hedgeDate = dateAdapter(requestedHedgeDate).localizeTime();

      return (
        dateAdapter(hedgeDate).format(dateFormat) ===
        dateAdapter(MomentAdapter.normalize(date)).format(dateFormat)
      );
    });

  submit = values => {
    const { futureHedges } = this.props.futureHedges;
    const dayWithScheduledHedges = this.isDayWithScheduledHedges({
      futureHedges,
      date: values.date,
    });

    return this.props.openModal(addMarketHolidaysModal(dayWithScheduledHedges), () =>
      this.modalCallback(values),
    );
  };

  render() {
    return (
      <FormContainer header={HEDGES.MARKET_HOLIDAYS.CREATE}>
        <MarketHolidaysForm {...this.props} onSubmit={this.submit} />
      </FormContainer>
    );
  }
}

export { MarketHolidaysCreate };
