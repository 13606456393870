import { statusesForGlobal } from 'shared/helpers/constants/lots/statusesForGlobal';
import { packageClasses } from 'shared/helpers/constants/packages/packageClasses';
import { packageStatuses } from 'shared/helpers/constants/packages/packageType';
import { shipmentStatuses } from 'shared/helpers/constants/shipments/shipmentStatuses';
import { isCustomerOrGrading } from 'shared/helpers/matchers/checkUserType';

const isPackageDeletable = ({ user: { userType } }, pkg) => {
  switch (pkg.packageClass) {
    case packageClasses.post_mill:
      return pkg.packageNumber === pkg.packageCountWarehouse;
    case packageClasses.post_mix:
    case packageClasses.post_shears:
      return pkg.packageNumber === pkg.packageCountOutgoing;
    case packageClasses.incoming: {
      const isLotStatusAllowed =
        isCustomerOrGrading(userType) ||
        ![
          statusesForGlobal.ready_for_processing,
          statusesForGlobal.accepted_for_processing,
          statusesForGlobal.ready_for_mixing,
          statusesForGlobal.accepted_for_mixing,
          statusesForGlobal.ready_to_ship,
          statusesForGlobal.shipped_from_global,
          statusesForGlobal.assay_results_received,
          statusesForGlobal.ship_summary_generated,
          statusesForGlobal.ship_summary_accepted,
          statusesForGlobal.invoice_generated,
          statusesForGlobal.invoice_accepted,
          statusesForGlobal.finalized,
        ].includes(pkg.assignedLot.statusForGlobal) ||
        pkg.assignedLot.realPackageCountIncoming > 1;

      const isPackageStatusAllowed =
        !isCustomerOrGrading(userType) || pkg.status === packageStatuses.open;

      const isShipmentStatusAllowed =
        !isCustomerOrGrading(userType) || pkg.assignedShipment?.status === shipmentStatuses.open;

      if (pkg.assignedShipment) {
        return isLotStatusAllowed && isPackageStatusAllowed && isShipmentStatusAllowed;
      } else {
        return isLotStatusAllowed && isPackageStatusAllowed;
      }
    }
    default:
      return;
  }
};

export { isPackageDeletable };
