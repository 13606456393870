import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { Icon } from 'shared/components/Icon/Icon';
import { getColor, pixelify, themed, cssVar } from 'shared/helpers/styling/styling';

const colors = {
  primary: 'primary',
  secondary: 'secondary',
  danger: 'danger',
  warning: 'warning',
};

const colorMap = {
  [colors.primary]: cssVar('atlantis'),
  [colors.secondary]: cssVar('fiord'),
  [colors.danger]: cssVar('roman'),
  [colors.warning]: cssVar('solidYellow'),
};

const Button = styled.button.attrs(
  themed(({ type, disabled }) => ({ type: type || 'button', disabled })),
)`
  position: relative;
  ${({ fitToContent }) =>
    !fitToContent &&
    css`
      ${({ height, statusButton }) =>
        !statusButton
          ? css`
              height: ${height ? pixelify(height) : '43px'};
            `
          : css`
              min-height: ${height ? pixelify(height) : '43px'};
            `}
    `};
  padding: ${({ fitToContent, padding }) => (fitToContent ? '0' : padding)};
  justify-content: ${({ justifyContent }) => justifyContent};
  display: flex;
  @media print {
    display: none;
  }
  align-items: center;
  font-size: 0.875rem;
  line-height: 1;

  ${({ statusButton }) => (statusButton ? 'word-break: break-word' : 'white-space: nowrap')};
  border-radius: ${({ borderRadius }) => pixelify(borderRadius)};
  border: ${({ outlineColor }) => `2px solid ${getColor(outlineColor, colorMap)}`};
  ${({ noOutline }) =>
    noOutline &&
    css`
      border-color: transparent;
    `};
  ${({ noBoxShadow }) =>
    noBoxShadow &&
    css`
      box-shadow: none;
    `};
  ${({ width }) =>
    width &&
    css`
      width: ${pixelify(width)};
    `};
  ${({ alignToLeft }) =>
    alignToLeft &&
    css`
      margin-left: auto;
    `};

  color: ${({ textColor, color }) => getColor(color || textColor, colorMap)};
  background-color: ${({ bgColor }) => bgColor};
  cursor: pointer;
  transition: 100ms ease-in;

  :active,
  :focus {
    outline: none;
    ${({ noBoxShadow }) =>
      !noBoxShadow &&
      css`
        box-shadow: 0 0 3px 3px rgba(255, 255, 255, 0.05);
      `};
  }

  :hover {
    color: ${({ textColorHover, color }) => getColor(color || textColorHover, colorMap)};
    background-color: ${({ bgColorHover }) => bgColorHover};
    border-color: ${({ outlineColorHover }) => outlineColorHover};

    ${({ tooltip }) =>
      tooltip &&
      css`
        ${Icon} {
          background-color: ${tooltip.bgColorHover};
        }
      `}
  }

  :disabled {
    cursor: not-allowed;
    opacity: ${({ alwaysVisible }) => (alwaysVisible ? 1 : 0.4)};
    border-color: ${({ outlineColorDisabled }) => outlineColorDisabled};

    :hover {
      background-color: ${({ bgColorDisabled }) => bgColorDisabled};
    }
  }

  ${({ margin }) =>
    margin &&
    css`
      & > * {
        margin-left: 10px;
      }
    `}

  a {
    margin-left: 0;
    line-height: 40px;
    width: 100%;
    text-align: left;
  }

  ${({ badge }) =>
    badge !== undefined &&
    badge !== null &&
    css`
      :after {
        position: absolute;
        content: '${badge}';
        display: flex;
        justify-content: center;
        align-items: center;
        right: -10px;
        top: -10px;
        min-width: 28px;
        min-height: 28px;
        padding: 5px;
        color: ${cssVar('whiteGRG')};
        background-color: ${cssVar('shipCove')};
        border-radius: 50%;
        font-weight: bold;
      }
    `}
`;

Button.propTypes = {
  textColor: PropTypes.string,
  color: PropTypes.string,
  textColorHover: PropTypes.string,
  outlineColor: PropTypes.string,
  outlineColorHover: PropTypes.string,
  outlineColorDisabled: PropTypes.string,
  bgColor: PropTypes.string,
  bgColorHover: PropTypes.string,
  bgColorDisabled: PropTypes.string,
  padding: PropTypes.string,
  borderRadius: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  noOutline: PropTypes.bool,
  noBoxShadow: PropTypes.bool,
  fitToContent: PropTypes.bool,
  margin: PropTypes.bool,
  alignToLeft: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  justifyContent: PropTypes.string,
  badge: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  alwaysVisible: PropTypes.bool,
};

Button.defaultProps = {
  textColor: cssVar('whiteGRG'),
  textColorHover: cssVar('whiteGRG'),
  outlineColor: colors.primary,
  outlineColorHover: colors.primary,
  outlineColorDisabled: 'rgba(204,204,204, 0.45)',
  bgColor: 'transparent',
  bgColorHover: cssVar('outerSpace'),
  bgColorDisabled: 'transparent',
  justifyContent: 'center',
  padding: '0 23px',
  borderRadius: 17.5,
  noOutline: false,
  noBoxShadow: false,
  fitToContent: false,
  margin: true,
  alwaysVisible: false,
};

export { Button };
