import styled from 'styled-components';

export const StyledContent = styled.div`
  text-align: center;
  color: white;
`;

export const StyledOptions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin: 20px 0;

  button {
    min-width: 50%;
  }
`;
