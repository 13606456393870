const ACCESS = {
  SIGN_IN: 'Sign In',
  LOGGED_OUT: 'LOGGED OUT',
  LOGGED_OUT_MESSAGE: "You've been logged out.",
  FORGOT_PASSWORD: 'Forgot password?',
  SEND_PASSWORD_RESET_EMAIL: 'Send email',
  TOKEN_NOT_VALID: 'Link is no longer valid.',
};

export { ACCESS };
