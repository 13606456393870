import {
  getLotsForHedgesActionType,
  getLotsForHedgesSuccessActionType,
  getLotsForHedgesFailureActionType,
  filterLotsForReassignSuccessActionType,
  clearFilterLotsForReassignSuccessActionType,
} from 'actions/Hedges/hedgesManagement';

const INITIAL_STATE = {
  isPending: false,
  lots: {},
  filteredLots: [],
};

const hedgesManagementReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getLotsForHedgesActionType:
      return {
        ...state,
        isPending: true,
      };
    case getLotsForHedgesSuccessActionType:
      return {
        isPending: false,
        lots: {
          ...state.lots,
          [action.payload.relatedCompany]: action.payload.data,
        },
      };
    case filterLotsForReassignSuccessActionType:
      return {
        ...state,
        filteredLots: action.payload.filteredLots,
      };
    case clearFilterLotsForReassignSuccessActionType:
      return {
        ...state,
        filteredLots: [],
      };
    case getLotsForHedgesFailureActionType:
      return {
        isPending: false,
        lots: {
          ...state.lots,
          [action.payload.relatedCompany]: [],
        },
      };
    default:
      return state;
  }
};

export { hedgesManagementReducer };
