import React from 'react';
import { Field } from 'redux-form';

import { AddButton } from 'shared/components/Buttons/AddButton/AddButton';
import { IconButton } from 'shared/components/Buttons/IconButton/IconButton';
import { FieldInput, FieldSelect } from 'shared/components/Fields';
import { Section, Separator } from 'shared/components/Form';
import {
  actionTypes,
  PROFIT_LOSS,
  profitAndLossDescriptionTypes,
} from 'shared/helpers/constants/lots/profitLossConstants';
import { mapArray } from 'shared/helpers/parsers/select';
import { cssVar } from 'shared/helpers/styling/styling';

const deleteAction = (fields, index) => (
  <IconButton onClick={() => fields.remove(index)} icon="icon-cancel" color={cssVar('whiteGRG')} />
);

const mapSelectData = data => mapArray(Object.values(data));

const AdditionalCommodities = ({ fields }) => {
  const addSection = () => fields.push({});

  return (
    <>
      {fields.map((field, index) => (
        <Section
          header={`${PROFIT_LOSS.COMMODITY} ${index + 1}`}
          template={[`${field}.action ${field}.description`, `${field}.details ${field}.value`]}
          key={field}
          actions={deleteAction(fields, index)}
        >
          <Field
            name={`${field}.action`}
            component={FieldSelect}
            options={mapSelectData(actionTypes)}
            field="required"
            label={PROFIT_LOSS.ACTION}
          />
          <Field
            name={`${field}.description`}
            component={FieldSelect}
            options={mapSelectData(profitAndLossDescriptionTypes)}
            field="required"
            label={PROFIT_LOSS.DESCRIPTION}
          />
          <Field name={`${field}.details`} component={FieldInput} label={PROFIT_LOSS.DETAILS} />
          <Field
            name={`${field}.value`}
            component={FieldInput}
            label={PROFIT_LOSS.VALUE}
            field="required"
            type="text"
            prefix="$"
          />
        </Section>
      ))}
      {fields.length > 0 && <Separator />}
      {fields.length < 20 && (
        <AddButton onClick={addSection}>{PROFIT_LOSS.ADD_COMMODITY}</AddButton>
      )}
    </>
  );
};

export { AdditionalCommodities };
