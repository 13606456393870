import React from 'react';
import { useSelector } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import styled from 'styled-components';

import { SubmitButton, CancelButton } from 'shared/components/Buttons';
import { FieldDate, FieldTextarea } from 'shared/components/Fields';
import { Section } from 'shared/components/Form/Section/Section';
import { CALENDAR_NOTE } from 'shared/helpers/constants/calendarConstants';
import { SHARED } from 'shared/helpers/constants/sharedConstants';
import { media } from 'shared/helpers/styling/styling';
import { validate } from 'shared/helpers/validations/shipments/createCalendarNoteValidation.js';

import dateAdapter from 'utils/date/dateAdapter';

const SubmitContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const SectionStyled = styled(Section)`
  height: 380px;

  ${media.tablet`
    height: auto;
  `}

  ${media.phone`
    height: auto;
  `}
`;

const formName = 'CreateCalendarNote';
const selector = formValueSelector(formName);

const CalendarNoteFormBase = ({ closeModal, handleSubmit, invalid }) => {
  const startDateValue = useSelector(state => selector(state, 'startDate'));
  const endDateValue = useSelector(state => selector(state, 'endDate'));
  const isCreating = useSelector(({ createdCalendarNote }) => createdCalendarNote.isPending);

  return (
    <form noValidate onSubmit={handleSubmit}>
      <SectionStyled template={['startDate endDate', 'noteContents noteContents']}>
        <Field
          name="startDate"
          component={FieldDate}
          label={CALENDAR_NOTE.START_DATE}
          maxDate={endDateValue}
          field="required"
        />
        <Field
          name="endDate"
          component={FieldDate}
          label={CALENDAR_NOTE.END_DATE}
          minDate={startDateValue || dateAdapter()}
          field="required"
        />
        <Field
          name="noteContents"
          component={FieldTextarea}
          label={CALENDAR_NOTE.NOTE}
          field="required"
        />
      </SectionStyled>
      <SubmitContainer>
        <CancelButton onClick={closeModal} />
        <SubmitButton label={SHARED.SAVE_BUTTON} disabled={invalid || isCreating} />
      </SubmitContainer>
    </form>
  );
};

const CalendarNoteForm = reduxForm({
  form: formName,
  validate,
})(CalendarNoteFormBase);

export { CalendarNoteForm, CalendarNoteFormBase };
