const message = {
  INVALID_DATE_EMPTY: 'Date is required.',
  INVALID_DATE_DUPLICATE: 'Holiday exists at that day.',
  INVALID_HOLIDAY_NAME_EMPTY: 'Holiday name is required.',
  INVALID_HOLIDAY_NAME_FORMAT: 'Holiday name cannot be longer than 50 characters.',

  MARKET_HOLIDAY_DELETE_SUCCESS: 'Market holiday has been deleted successfully',
  MARKET_HOLIDAY_DELETE_ERROR: 'There was an error during delete.',

  MARKET_HOLIDAY_CREATE_SUCCESS: 'Market holiday has been added successfully.',
  MARKET_HOLIDAY_CREATE_ERROR: 'There was an error during market holiday creation.',

  HEDGES_DELETE_SUCCESS: 'Hedge has been deleted successfully',
  HEDGES_DELETE_ERROR: 'There was an error during delete hedge.',
};

export { message };
