import axios from 'axios';

import { parseUserData } from 'shared/helpers/dataParsers/user/parseUserData';

const API_HOST_AUTH = process.env.REACT_APP_API;

const createUserActionType = 'CREATE_USER';
function createUserAction() {
  return {
    type: createUserActionType,
  };
}

const createUserSuccessActionType = 'CREATE_USER_SUCCESS';
function createUserSuccessAction() {
  return {
    type: createUserSuccessActionType,
  };
}

const createUserFailureActionType = 'CREATE_USER_FAILURE';
function createUserFailureAction() {
  return {
    type: createUserFailureActionType,
  };
}

function createUser(user) {
  return dispatch => {
    dispatch(createUserAction());
    return axios({
      method: 'post',
      url: `${API_HOST_AUTH}/users`,
      data: JSON.stringify(parseUserData(user)),
    })
      .then(res => {
        dispatch(createUserSuccessAction());

        return res;
      })
      .catch(error => {
        dispatch(createUserFailureAction());

        return Promise.reject(error);
      });
  };
}

export {
  createUserActionType,
  createUserSuccessActionType,
  createUserFailureActionType,
  createUser,
};
