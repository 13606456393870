import { getLotAvailableOuncesReducer } from 'reducers/Deliveries/getLotAvailableOunces';
import { getFutureHedgesReducer } from 'reducers/Hedges/getFutureHedges';

import { createHedgesReducer } from './createHedges';
import { defaultPPMsReducer } from './defaultPPMs';
import { enterCustomPricesReducer } from './enterCustomPrices';
import { enterPricesReducer } from './enterPrices';
import { companyHedgesReducer } from './getCompanyHedges';
import { getHedgeOuncesReducer } from './getHedgeOunces';
import { getHedgeTotalValueReducer } from './getHedgeTotalValue';
import { hedgesDetailsReducer } from './hedgesDetails';
import { hedgesListReducer } from './hedgesList';
import { hedgesManagementReducer } from './hedgesManagement';
import { HedgesOverviewListReducer } from './hedgesOverviewList';
import { hedgeReassignReducer } from './hedgesReassign';
import { hedgesSuggestedValuesReducer } from './hedgesSuggestedValues';
import { marketAvailabilityDetailsReducer } from './marketAvailability';
import { marketHolidaysListReducer } from './marketHolidaysList';
import { recipientsListReducer } from './recipientsList';
import { suggestedDeliveryDateReducer } from './suggestedDeliveryDate';
import { updateOuncesReducer } from './updateOunces';

const hedgesRootReducer = {
  hedgesManagement: hedgesManagementReducer,
  marketHolidaysList: marketHolidaysListReducer,
  marketAvailabilityDetails: marketAvailabilityDetailsReducer,
  hedgesOverviewList: HedgesOverviewListReducer,
  companyHedgesReducer: companyHedgesReducer,
  hedgesList: hedgesListReducer,
  hedgesDetails: hedgesDetailsReducer,
  recipientsList: recipientsListReducer,
  enterPrices: enterPricesReducer,
  enterCustomPrices: enterCustomPricesReducer,
  hedgesSuggestedValues: hedgesSuggestedValuesReducer,
  suggestedDeliveryDate: suggestedDeliveryDateReducer,
  defaultPPMs: defaultPPMsReducer,
  hedgeTotalValue: getHedgeTotalValueReducer,
  hedgeOunces: getHedgeOuncesReducer,
  createHedges: createHedgesReducer,
  hedgeReassign: hedgeReassignReducer,
  lotAvailableOunces: getLotAvailableOuncesReducer,
  futureHedges: getFutureHedgesReducer,
  updateOunces: updateOuncesReducer,
};

export { hedgesRootReducer };
