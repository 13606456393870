import { Dropdown } from 'reactstrap';
import styled from 'styled-components';

import { media } from 'shared/helpers/styling/styling';

export const ExtendableButtonStyled = styled(Dropdown)`
  --width: 240px;
  --beforeHeight: 44px;

  ${media.phone`width: 100%;`}

  .ExtendableButton.show > .dropdown-toggle {
    background-color: var(--atlantis) !important;
  }

  .ExtendableButton-Menu.dropdown-menu,
  .ExtendableButton-Menu {
    overflow: hidden;
    min-width: var(--width);
    padding: 3px 0 0;
    background-color: var(--outerSpace);
    border: 2px solid var(--atlantis);
    border-radius: 17.5px;
    transform: translate(0, 0) !important;

    &[x-placement='bottom-end'] {
      top: -45px !important;
    }

    ${media.phone`width: 100%;`}

    &.show {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &-topSpacer {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-bottom: 8px;

      i {
        cursor: pointer;
        margin-right: 9px;

        ${media.phone`margin-right: 21px;`}
      }
    }

    &-bottomSpacer {
      height: 12px;
    }
  }

  .ExtendableButton-Toggle.btn-secondary,
  .ExtendableButton-Toggle {
    position: relative;
    border-radius: 17.5px;
    background-color: var(--outerSpaceBrighter);
    color: var(--atlantis);
    border: 2px solid var(--atlantis);
    font-size: 1em;
    width: 170px;
    box-shadow: none !important;
    height: 43px;

    ${media.phone`width: 100%;`}

    &:not([disabled]) {
      &:hover,
      &:active {
        border-color: var(--atlantis) !important;
        color: var(--conifer) !important;
        background-color: var(--outerSpace) !important;
      }
    }

    &:disabled {
      background: var(--outerSpaceBrighter);
      border: 2px solid rgba(204, 204, 204, 0.18);
      cursor: not-allowed;
    }

    &-Label {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: var(--whiteGRG);
      font-size: 14px;

      span {
        height: 36px;
        line-height: 36px;
        margin-left: 15px;
      }
    }
  }

  .ExtendableButton-Divider.dropdown-divider,
  .ExtendableButton-Divider {
    width: 100%;
    margin: 0;
    border-color: var(--outerSpaceLighter);
  }
`;
