import PropTypes from 'prop-types';
import * as React from 'react';
import { useMemo } from 'react';
import { Field } from 'redux-form';

import { FieldButtonSelect } from 'shared/components/Fields/FieldButtonSelect/FieldButtonSelect';
import { companyTypesForUserRole } from 'shared/helpers/accesses/config/companyTypesForUserRole';
import { COMPANY } from 'shared/helpers/constants/companies/companyConstants';
import { mapArray } from 'shared/helpers/parsers/select';

export const SelectCompanyType = props => {
  const mappedTypes = useMemo(() => {
    let types = companyTypesForUserRole[props.auth.user.userType];

    if (!props.auth.access.companies.gradingEnabled) {
      types = types.filter(type => type !== 'grading');
    }

    return mapArray(types);
  }, [props.auth.user.userType]);

  return (
    <Field
      name="companyType"
      component={FieldButtonSelect}
      disabled={props.disabled}
      label={COMPANY.COMPANY_TYPE}
      options={mappedTypes}
      onChange={props.companyTypeChange}
      field="required"
    />
  );
};

SelectCompanyType.propTypes = {
  auth: PropTypes.shape({
    user: PropTypes.shape({
      userType: PropTypes.string,
    }),
    access: PropTypes.shape({
      companies: PropTypes.shape({
        gradingEnabled: PropTypes.bool,
      }),
    }),
  }),
  companyTypeChange: PropTypes.any,
  disabled: PropTypes.any,
};
