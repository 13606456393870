import axios from 'axios';

import { history } from 'utils/history';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getCompanyActionType = 'GET_COMPANY';
function getCompanyAction() {
  return {
    type: getCompanyActionType,
  };
}

const getCompanySuccessActionType = 'GET_COMPANY_SUCCESS';
function getCompanySuccessAction(company) {
  return {
    type: getCompanySuccessActionType,
    payload: {
      company,
    },
  };
}

const getCompanyFailureActionType = 'GET_COMPANY_FAILURE';
function getCompanyFailureAction(errorMessage) {
  return {
    type: getCompanyFailureActionType,
    payload: {
      errorMessage,
    },
  };
}

const clearCompanyDetailsActionType = 'CLEAR_COMPANY_DETAILS_ACTION';
function clearCompanyDetails() {
  return {
    type: clearCompanyDetailsActionType,
  };
}

function getCompany(companyId) {
  return dispatch => {
    dispatch(getCompanyAction());

    return axios
      .get(`${API_HOST_AUTH}/companies/${companyId}`)
      .then(company => {
        dispatch(getCompanySuccessAction(company.data));
        return company.data;
      })
      .catch(error => {
        history.push('/companies');
        dispatch(getCompanyFailureAction(error));

        return Promise.reject(error.data);
      });
  };
}

export {
  getCompanyActionType,
  getCompanySuccessActionType,
  getCompanyFailureActionType,
  clearCompanyDetailsActionType,
  getCompany,
  clearCompanyDetails,
};
