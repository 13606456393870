import React, { useEffect } from 'react';
import { connect, batch, useDispatch } from 'react-redux';

import {
  getFinalInvoiceMaximumOunces as getFinalInvoiceMaximumOuncesAction,
  clearFinalInvoiceMaximumOunces as clearFinalInvoiceMaximumOuncesAction,
} from 'actions/Lots/getFinalInvoiceMaximumOunces';
import { getLot } from 'actions/Lots/lotDetails';
import {
  addCustomFinalInvoice,
  clearCustomFinalInvoice as clearCustomFinalInvoiceAction,
  uploadCustomFinalInvoice,
} from 'actions/Lots/uploadCustomFinalInvoice';
import { openModal } from 'actions/shared/modal';
import { showSnackbar } from 'actions/shared/snackbar';

import { FormContainer } from 'shared/components/Form';
import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';
import { uploadFinalInvoiceModal } from 'shared/helpers/constants/modalConstants';
import {
  dangerOptions,
  successOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

import { FinalInvoiceForm } from '../FinalInvoiceForm/FinalInvoiceForm';

const UploadFinalInvoiceLayout = props => {
  const dispatch = useDispatch();

  const {
    getFinalInvoiceMaximumOunces,
    clearFinalInvoiceMaximumOunces,
    clearCustomFinalInvoice,
    finalInvoiceMaximumOunces,
    match: { params },
  } = props;

  useEffect(() => {
    getFinalInvoiceMaximumOunces(params.id);
    dispatch(getLot(params.id));

    return () => {
      batch(() => {
        clearFinalInvoiceMaximumOunces();
        clearCustomFinalInvoice();
      });
    };
  }, [
    getFinalInvoiceMaximumOunces,
    clearFinalInvoiceMaximumOunces,
    clearCustomFinalInvoice,
    params.id,
  ]);

  const uploadFinalInvoice = values => {
    props
      .uploadCustomFinalInvoice(
        values,
        props.finalInvoiceFile,
        params.id,
        finalInvoiceMaximumOunces.data,
      )
      .then(res => {
        props.showSnackbar(successOptions, res.message);
        props.history.push(`/lots/list/${params.id}`);
      })
      .catch(res => {
        props.showSnackbar(dangerOptions, res.message);
      });
  };

  const submit = values => {
    props.openModal(uploadFinalInvoiceModal, () => uploadFinalInvoice(values));
  };

  const { platinumOuncesRemoved, palladiumOuncesRemoved, rhodiumOuncesRemoved, balanceRemaining } =
    finalInvoiceMaximumOunces.data || {};

  return (
    <FormContainer
      header={LOTS.UPLOAD_CUSTOM_INVOICE}
      loaded={!props.finalInvoiceDetails.isPending && props.finalInvoiceMaximumOunces.data}
    >
      {() => (
        <FinalInvoiceForm
          {...props}
          onSubmit={submit}
          uploadFinalInvoice
          submitButtonLabel={LOTS.UPLOAD}
          initialValues={{
            platinumOuncesRemoved: platinumOuncesRemoved || 0,
            palladiumOuncesRemoved: palladiumOuncesRemoved || 0,
            rhodiumOuncesRemoved: rhodiumOuncesRemoved || 0,
            balanceRemaining,
          }}
        />
      )}
    </FormContainer>
  );
};

const mapStateToProps = state => ({
  finalInvoiceDetails: state.finalInvoiceDetails,
  lotDetails: state.lotDetails,
  finalInvoiceMaximumOunces: state.finalInvoiceMaximumOunces,
  finalInvoiceFile: state.uploadCustomFinalInvoiceFile,
});

const mapDispatchToProps = {
  openModal,
  showSnackbar,
  getLot,
  uploadCustomFinalInvoice,
  getFinalInvoiceMaximumOunces: getFinalInvoiceMaximumOuncesAction,
  clearFinalInvoiceMaximumOunces: clearFinalInvoiceMaximumOuncesAction,
  addCustomFinalInvoice,
  clearCustomFinalInvoice: clearCustomFinalInvoiceAction,
};

const UploadFinalInvoice = connect(mapStateToProps, mapDispatchToProps)(UploadFinalInvoiceLayout);

export { UploadFinalInvoiceLayout, UploadFinalInvoice };
