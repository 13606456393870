import axios from 'axios';

import { PACKAGES } from 'shared/helpers/constants/packages/packageConstants';
import { messageTypes } from 'shared/helpers/constants/snackbar/snackbarMessageTypes';
import { parseShipmentUpdatePackingListData } from 'shared/helpers/dataParsers/shipments';

const API_HOST_AUTH = process.env.REACT_APP_API;

const updateMultiplePackagesType = 'UPDATE_MULTIPLE_PACKAGES';
const updateMultiplePackagesAction = () => ({
  type: updateMultiplePackagesType,
});

const updateMultiplePackagesSuccessType = `${updateMultiplePackagesType}_SUCCESS`;
const updateMultiplePackagesSuccessAction = () => ({
  type: updateMultiplePackagesSuccessType,
});

const updateMultiplePackagesFailureType = `${updateMultiplePackagesType}_FAILURE`;
const updateMultiplePackagesFailureAction = () => ({
  type: updateMultiplePackagesFailureType,
});

const updateMultiplePackages = updatedPackages => dispatch => {
  dispatch(updateMultiplePackagesAction());

  return axios
    .put(`${API_HOST_AUTH}/packages/multiple`, parseShipmentUpdatePackingListData(updatedPackages))
    .then(res => {
      dispatch(updateMultiplePackagesSuccessAction());

      return {
        id: res.data.id,
        message: res.data.message,
        shipmentPackages: res.data.shipmentPackages,
        messageType: messageTypes.success,
        files: res.data.files,
      };
    })
    .catch(err => {
      dispatch(updateMultiplePackagesFailureAction());

      return Promise.reject({
        message: `${PACKAGES.UPDATE_PACKAGE_ERROR} ${err.data.message}`,
        messageType: messageTypes.error,
      });
    });
};

export {
  updateMultiplePackagesType,
  updateMultiplePackagesSuccessType,
  updateMultiplePackagesFailureType,
  updateMultiplePackages,
};
