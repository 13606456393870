import axios from 'axios';

import { parseBankHolidaysData } from 'shared/helpers/dataParsers/payments/parseBankHolidaysData';

const API_HOST_AUTH = process.env.REACT_APP_API;

const createBankHolidaysActionType = 'CREATE_BANK_HOLIDAYS';
const createBankHolidaysAction = () => ({ type: createBankHolidaysActionType });

const createBankHolidaysSuccessActionType = `${createBankHolidaysActionType}_SUCCESS`;
const createBankHolidaysSuccessAction = bankHoliday => ({
  type: createBankHolidaysSuccessActionType,
  payload: { bankHoliday },
});

const createBankHolidaysFailureActionType = `${createBankHolidaysActionType}_FAILURE`;
const createBankHolidaysFailureAction = () => ({
  type: createBankHolidaysFailureActionType,
});

const createBankHolidays = bankHoliday => dispatch => {
  dispatch(createBankHolidaysAction());

  return axios({
    method: 'post',
    url: `${API_HOST_AUTH}/bank-holidays`,
    data: parseBankHolidaysData(bankHoliday),
  })
    .then(({ bankHoliday }) => dispatch(createBankHolidaysSuccessAction(bankHoliday)))
    .catch(error => {
      dispatch(createBankHolidaysFailureAction());

      return Promise.reject(error);
    });
};

export {
  createBankHolidays,
  createBankHolidaysActionType,
  createBankHolidaysSuccessActionType,
  createBankHolidaysFailureActionType,
};
