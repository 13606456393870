import {
  deleteSelfDeliveryRequestType,
  deleteSelfDeliveryRequestSuccessType,
  deleteSelfDeliveryRequestFailureType,
} from 'actions/SelfDeliveryRequests/deleteSelfDeliveryRequest';
import {
  clearSelfDeliveryRequestDetailsActionType,
  getSelfDeliveryRequestDetailsActionType,
  getSelfDeliveryRequestDetailsFailureActionType,
  getSelfDeliveryRequestDetailsSuccessActionType,
} from 'actions/SelfDeliveryRequests/selfDeliveryRequestDetails';
import {
  selfDeliveryRequestStatusChangeActionType,
  selfDeliveryRequestStatusChangeFailureActionType,
  selfDeliveryRequestStatusChangeSuccessActionType,
} from 'actions/SelfDeliveryRequests/selfDeliveryRequestStatusChange';

const INITIAL_STATE = {
  isPending: false,
  errorMessage: null,
  selfDeliveryRequest: null,
};

const SelfDeliveryRequestDetailsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getSelfDeliveryRequestDetailsActionType:
      return {
        selfDeliveryRequest: null,
        isPending: true,
      };
    case getSelfDeliveryRequestDetailsSuccessActionType:
      return {
        ...state,
        isPending: false,
        selfDeliveryRequest: action.payload.sdRequest,
      };
    case getSelfDeliveryRequestDetailsFailureActionType:
      return {
        ...state,
        isPending: false,
        errorMessage: action.payload.errorMessage,
      };
    case clearSelfDeliveryRequestDetailsActionType:
      return {
        ...INITIAL_STATE,
      };
    case selfDeliveryRequestStatusChangeActionType:
    case deleteSelfDeliveryRequestType:
      return {
        ...state,
        isPending: true,
      };
    case selfDeliveryRequestStatusChangeSuccessActionType:
      return {
        ...state,
        isPending: false,
        selfDeliveryRequest: {
          ...state.selfDeliveryRequest,
          ...action.payload.selfDeliveryRequest,
        },
      };
    case selfDeliveryRequestStatusChangeFailureActionType:
    case deleteSelfDeliveryRequestSuccessType:
    case deleteSelfDeliveryRequestFailureType:
      return {
        ...state,
        isPending: false,
      };
    default:
      return state;
  }
};

export { SelfDeliveryRequestDetailsReducer };
