import axios from 'axios';

import { messageTypes } from 'shared/helpers/constants/snackbar/snackbarMessageTypes';
import { parseSettledOuncesData } from 'shared/helpers/dataParsers/settlements/parseSettledOuncesData.js';

const API_HOST_AUTH = process.env.REACT_APP_API;

const enterSettledOuncesActionType = 'ENTER_SETTLED_OUNCES';
const enterSettledOuncesAction = () => ({
  type: enterSettledOuncesActionType,
});

const enterSettledOuncesSuccessActionType = `${enterSettledOuncesActionType}_SUCCESS`;
const enterSettledOuncesSuccessAction = () => ({
  type: enterSettledOuncesSuccessActionType,
});

const enterSettledOuncesFailureActionType = `${enterSettledOuncesActionType}_FAILURE`;
const enterSettledOuncesFailureAction = () => ({
  type: enterSettledOuncesFailureActionType,
});

const enterSettledOunces =
  ({ settledOunces }) =>
  dispatch => {
    dispatch(enterSettledOuncesAction());
    return axios
      .post(`${API_HOST_AUTH}/settlements/enter-settled-ounces`, {
        settledOunces: parseSettledOuncesData(settledOunces),
      })
      .then(res => {
        dispatch(enterSettledOuncesSuccessAction());

        return {
          id: res.data.id,
          message: res.data.message,
          messageType: messageTypes.success,
        };
      })
      .catch(error => {
        dispatch(enterSettledOuncesFailureAction());

        return error.data;
      });
  };

export {
  enterSettledOuncesActionType,
  enterSettledOuncesAction,
  enterSettledOuncesSuccessActionType,
  enterSettledOuncesSuccessAction,
  enterSettledOuncesFailureActionType,
  enterSettledOuncesFailureAction,
  enterSettledOunces,
};
