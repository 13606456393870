import { safeParseFloat, tryParseNumber } from 'shared/helpers/parsers/number';

export function calculateWeights(
  change,
  resetSection,
  name,
  values,
  weightGrossReceived,
  weightTareReceived,
  weightNetWetReceived,
  weightScrapReceived,
  weightAdjustedNetWetReceived,
) {
  switch (name) {
    case weightNetWetReceived:
    case weightScrapReceived:
      resetSection(weightAdjustedNetWetReceived);
      change(
        weightAdjustedNetWetReceived,
        tryParseNumber(
          safeParseFloat(values[weightNetWetReceived]) -
            safeParseFloat(values[weightScrapReceived]),
        ),
      );
      break;
    default:
      resetSection(weightAdjustedNetWetReceived);
      resetSection(weightNetWetReceived);
      change(
        weightNetWetReceived,
        tryParseNumber(
          safeParseFloat(values[weightGrossReceived]) - safeParseFloat(values[weightTareReceived]),
        ),
      );
      change(
        weightAdjustedNetWetReceived,
        tryParseNumber(
          safeParseFloat(values[weightGrossReceived]) -
            safeParseFloat(values[weightTareReceived]) -
            safeParseFloat(values[weightScrapReceived]),
        ),
      );
      break;
  }
}
