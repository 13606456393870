import styled from 'styled-components';

import { Button } from 'shared/components/Buttons/Button';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${Button} {
    margin: 3px 6px;
  }
`;
