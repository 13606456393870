import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getHedgesSuggestedValuesActionType = 'GET_HEDGES_SUGGESTED_VALUES';
const getHedgesSuggestedValuesAction = () => ({
  type: getHedgesSuggestedValuesActionType,
});

const getHedgesSuggestedValuesSuccessActionType = `${getHedgesSuggestedValuesActionType}_SUCCESS`;
const getHedgesSuggestedValuesSuccessAction = () => ({
  type: getHedgesSuggestedValuesSuccessActionType,
});

const getHedgesSuggestedValuesFailedActionType = `${getHedgesSuggestedValuesActionType}_FAILED`;
const getHedgesSuggestedValuesFailedAction = () => ({
  type: getHedgesSuggestedValuesFailedActionType,
});

const getHedgesSuggestedValues =
  ({ relatedCompany, materialType, materialWeight, materialUnits, lotId, hedgeType }) =>
  dispatch => {
    dispatch(getHedgesSuggestedValuesAction());

    return axios
      .post(`${API_HOST_AUTH}/hedges/suggested-values/${relatedCompany.value || relatedCompany}`, {
        relatedCompany: {
          companyType: relatedCompany.companyType,
          materialCountRequired: relatedCompany.materialCountRequired,
        },
        materialType,
        materialWeight,
        materialUnits,
        hedgeType,
        ...(lotId && { lotId }),
      })
      .then(({ data }) => {
        dispatch(getHedgesSuggestedValuesSuccessAction());

        return data;
      })
      .catch(() => dispatch(getHedgesSuggestedValuesFailedAction()));
  };

export {
  getHedgesSuggestedValuesActionType,
  getHedgesSuggestedValuesSuccessActionType,
  getHedgesSuggestedValuesFailedActionType,
  getHedgesSuggestedValuesAction,
  getHedgesSuggestedValues,
};
