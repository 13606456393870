import { statusesForGlobal } from 'shared/helpers/constants/lots/statusesForGlobal';
import { materialTypeForAssay } from 'shared/helpers/constants/packages/materialType';
import { packageClasses } from 'shared/helpers/constants/packages/packageClasses';
import {
  packageStatuses,
  packageType as packageTypes,
} from 'shared/helpers/constants/packages/packageType';
import { userStatuses } from 'shared/helpers/constants/users/userFilters';
import { isOwnerUser } from 'shared/helpers/matchers/checkUserType';

export const packageInactivateAccess = ({ user, access }, pkg) => {
  const { lab } = access.specialPermissions.processing;
  const { packageClass, materialTypeDeclared, status, packageType } = pkg;

  const isUserActive = user.status === userStatuses.active;
  const userHasAccess = access.packages.inactivate;
  const validPkgStatus = [packageStatuses.closed, packageStatuses.received].includes(status);
  const validPkgClass = packageClass === packageClasses.incoming;

  switch (packageType) {
    case packageTypes.sample:
      return (isOwnerUser(user.userType) || lab) && isUserActive && validPkgStatus && validPkgClass;
    default:
      const validPkgMaterialType = materialTypeDeclared === materialTypeForAssay.sensors;

      return (
        isUserActive && userHasAccess && validPkgStatus && validPkgClass && validPkgMaterialType
      );
  }
};

export const packageReactivateAccess = ({ user, access }, pkg) => {
  const { packageClass, materialTypeDeclared, status } = pkg;

  const isUserActive = user.status === userStatuses.active;
  const userHasAccess = access.packages.reactivate;
  const validPkgStatus = status === packageStatuses.inactive;
  const validPkgClass = packageClass === packageClasses.incoming;
  const validPkgMaterialType = materialTypeDeclared === materialTypeForAssay.sensors;

  return isUserActive && userHasAccess && validPkgStatus && validPkgClass && validPkgMaterialType;
};

export const scanSamplePackagesAccess = (auth, lot, packagesList) => {
  const { lab } = auth.access.specialPermissions.processing;
  const packages = packagesList?.incoming?.docs;
  const userHasAccess = lab || auth.access.packages.inactivate;
  const isUserActive = auth.user.status === userStatuses.active;
  const samplePackagesToInactivateAvailable = packages?.some(
    pkg =>
      pkg.packageType === packageTypes.sample &&
      [packageStatuses.closed, packageStatuses.received].includes(pkg.status),
  );
  const validLotStatus = [
    statusesForGlobal.partly_received,
    statusesForGlobal.received,
    statusesForGlobal.ready_for_processing,
  ].includes(lot.statusForGlobal);

  return (
    (isOwnerUser(auth.user.userType) || lab) &&
    isUserActive &&
    userHasAccess &&
    samplePackagesToInactivateAvailable &&
    validLotStatus
  );
};
