const ENTER_PRICES = {
  PLATINUM_PRICE_ACTUAL_EMPTY: 'Actual platinum price cannot be empty.',
  PALLADIUM_PRICE_ACTUAL_EMPTY: 'Actual palladium price cannot be empty.',
  RHODIUM_PRICE_ACTUAL_EMPTY: 'Actual rhodium price cannot be empty.',
  PLATINUM_PRICE_ACTUAL_INVALID: 'Actual platinum price must have a valid format.',
  PALLADIUM_PRICE_ACTUAL_INVALID: 'Actual palladium price must have a valid format.',
  RHODIUM_PRICE_ACTUAL_INVALID: 'Actual rhodium price must have a valid format.',
  PLATINUM_PRICE_ACTUAL_NEGATIVE: 'Actual platinum price cannot be less than 0.',
  PALLADIUM_PRICE_ACTUAL_NEGATIVE: 'Actual palladium price cannot be less than 0.',
  RHODIUM_PRICE_ACTUAL_NEGATIVE: 'Actual rhodium price cannot be less than 0.',
  PLATINUM_PRICE_ACTUAL_DO_NOT_MATCH: 'Actual platinum prices do not match.',
  PALLADIUM_PRICE_ACTUAL_DO_NOT_MATCH: 'Actual palladium prices do not match.',
  RHODIUM_PRICE_ACTUAL_DO_NOT_MATCH: 'Actual rhodium prices do not match.',

  PLATINUM_PRICE_CUST_EMPTY: 'Customer platinum price cannot be empty.',
  PALLADIUM_PRICE_CUST_EMPTY: 'Customer palladium price cannot be empty.',
  RHODIUM_PRICE_CUST_EMPTY: 'Customer rhodium price cannot be empty.',
  PLATINUM_PRICE_CUST_INVALID: 'Customer platinum price must have a valid format.',
  PALLADIUM_PRICE_CUST_INVALID: 'Customer palladium price must have a valid format.',
  RHODIUM_PRICE_CUST_INVALID: 'Customer rhodium price must have a valid format.',
  PLATINUM_PRICE_CUST_NEGATIVE: 'Customer platinum price cannot be less than 0.',
  PALLADIUM_PRICE_CUST_NEGATIVE: 'Customer palladium price cannot be less than 0.',
  RHODIUM_PRICE_CUST_NEGATIVE: 'Customer rhodium price cannot be less than 0.',
  PLATINUM_PRICE_CUST_DO_NOT_MATCH: 'Customer platinum prices do not match.',
  PALLADIUM_PRICE_CUST_DO_NOT_MATCH: 'Customer palladium prices do not match.',
  RHODIUM_PRICE_CUST_DO_NOT_MATCH: 'Customer rhodium prices do not match.',
};

export { ENTER_PRICES };
