import { ENTER_PRICES } from 'shared/helpers/constants/settlements/enterPricesConstants';
import { safeParseFloat } from 'shared/helpers/parsers/number';
import { replaceEmpty } from 'shared/helpers/parsers/text';
import { commaValidator } from 'shared/helpers/validations/commaValidator';
import { PRICE_REGEX } from 'shared/helpers/validations/regularExpressions';

const validate = ({ platinumPrice, palladiumPrice, rhodiumPrice }, { settlement }) => {
  let platinumPriceError = null;
  let palladiumPriceError = null;
  let rhodiumPriceError = null;

  if (settlement.ouncesPt !== 0) {
    if (!replaceEmpty(platinumPrice, '').length) {
      platinumPriceError = ENTER_PRICES.PLATINUM_PRICE_EMPTY;
    } else if (!PRICE_REGEX.test(platinumPrice) || (platinumPrice && platinumPrice.length > 10)) {
      platinumPriceError = ENTER_PRICES.PLATINUM_PRICE_INVALID;
    } else if (safeParseFloat(platinumPrice) <= 0) {
      platinumPriceError = ENTER_PRICES.PLATINUM_PRICE_NEGATIVE;
    }
  }

  if (settlement.ouncesPd !== 0) {
    if (!replaceEmpty(palladiumPrice, '').length) {
      palladiumPriceError = ENTER_PRICES.PALLADIUM_PRICE_EMPTY;
    } else if (
      !PRICE_REGEX.test(palladiumPrice) ||
      (palladiumPrice && palladiumPrice.length > 10)
    ) {
      palladiumPriceError = ENTER_PRICES.PALLADIUM_PRICE_INVALID;
    } else if (safeParseFloat(palladiumPrice) <= 0) {
      palladiumPriceError = ENTER_PRICES.PALLADIUM_PRICE_NEGATIVE;
    }
  }

  if (settlement.ouncesRh !== 0) {
    if (!replaceEmpty(rhodiumPrice, '').length) {
      rhodiumPriceError = ENTER_PRICES.RHODIUM_PRICE_EMPTY;
    } else if (!PRICE_REGEX.test(rhodiumPrice) || (rhodiumPrice && rhodiumPrice.length > 10)) {
      rhodiumPriceError = ENTER_PRICES.RHODIUM_PRICE_INVALID;
    } else if (safeParseFloat(rhodiumPrice) <= 0) {
      rhodiumPriceError = ENTER_PRICES.RHODIUM_PRICE_NEGATIVE;
    }
  }
  const errors = {};
  commaValidator({ platinumPrice, palladiumPrice, rhodiumPrice }, errors);

  return {
    platinumPrice: errors.platinumPrice || platinumPriceError,
    palladiumPrice: errors.palladiumPrice || palladiumPriceError,
    rhodiumPrice: errors.rhodiumPrice || rhodiumPriceError,
  };
};

export { validate };
