import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { hideSessionSnackbar } from 'actions/shared/sessionSnackbar';

import { refreshToken } from 'utils/refreshToken';

import { SessionSnackbarContent } from './components/SessionSnackbarContent/SessionSnackbarContent';

const SessionSnackbar = () => {
  const dispatch = useDispatch();
  const sessionSnackbar = useSelector(({ sessionSnackbar }) => sessionSnackbar);

  const handleSubmit = useCallback(() => {
    refreshToken();
  }, []);

  const handleCancel = useCallback(() => {
    dispatch(hideSessionSnackbar());
  }, [dispatch]);

  return (
    !!sessionSnackbar?.snackbarOpened && (
      <SessionSnackbarContent
        remainingTime={sessionSnackbar.remainingTime}
        onCancel={handleCancel}
        onSubmit={handleSubmit}
      />
    )
  );
};

SessionSnackbar.propTypes = {
  remainingTime: PropTypes.number,
  onCancel: PropTypes.func,
};

export { SessionSnackbar };
