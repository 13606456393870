import {
  inactivateCompanyActionType,
  inactivateCompanySuccessActionType,
  inactivateCompanyFailureActionType,
} from 'actions/Companies/inactivateCompany';

const INITIAL_STATE = {
  isPending: false,
};

const InactivateCompanyReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case inactivateCompanyActionType:
      return {
        isPending: true,
      };
    case inactivateCompanySuccessActionType:
    case inactivateCompanyFailureActionType:
      return {
        isPending: false,
      };
    default:
      return state;
  }
};

export { InactivateCompanyReducer };
