import { cssVar } from 'shared/helpers/styling/styling';

import { resolveVersion, versions } from 'utils/versioning';

const SidebarStyle = {
  sidebar: {
    zIndex: 1049,
    position: 'fixed',
    width: 200,
    backgroundColor: resolveVersion({
      [versions.alpha]: cssVar('limedSpruceDetails'),
      [versions.global]: '#263238',
      [versions.west]: cssVar('lightGrayGRG'),
    }),
  },
  content: {
    display: 'flex',
    alignItems: 'center',
  },
};

export { SidebarStyle };
