import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { isCustomerOrGrading, isOwnerUser } from 'shared/helpers/matchers/checkUserType';
import { dateWithTimeLuxonFormat } from 'shared/helpers/parsers/date';
import { formatDate } from 'shared/helpers/parsers/formaters';
import { cssVar, pxToRem } from 'shared/helpers/styling/styling';

const Container = styled.div`
  grid-area: info;
  display: flex;
  flex-wrap: wrap;
  color: ${cssVar('alto')};
  font-size: ${pxToRem(14)};
  & > span:first-child {
    margin-right: 15px;
  }
`;

function Info({ date, user }) {
  const loggedInUser = useSelector(state => state.auth.user);

  const { _id, firstName, lastName, userType } = user || {};
  const link = _id && `/users/${_id}`;
  const name = `${firstName} ${lastName}`;
  const access = isOwnerUser(loggedInUser.userType) || isCustomerOrGrading(userType);

  return (
    <Container>
      <span>{formatDate(date, dateWithTimeLuxonFormat)}</span>
      {user && (link && access ? <Link to={link}>{name}</Link> : <span>{name}</span>)}
    </Container>
  );
}

export { Info };
