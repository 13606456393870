import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const createAgreementDocumentActionType = 'CREATE_AGREEMENT_DOCUMENT';
function createAgreementDocumentAction() {
  return {
    type: createAgreementDocumentActionType,
  };
}

const createAgreementDocumentSuccessActionType = 'CREATE_AGREEMENT_DOCUMENT_SUCCESS';
function createAgreementDocumentSuccessAction(agreement) {
  return {
    type: createAgreementDocumentSuccessActionType,
    payload: { agreement },
  };
}

const createAgreementDocumentFailureActionType = 'CREATE_AGREEMENT_DOCUMENT_FAILURE';
function createAgreementDocumentFailureAction() {
  return {
    type: createAgreementDocumentFailureActionType,
  };
}

function createAgreementDocument(agreement) {
  return dispatch => {
    dispatch(createAgreementDocumentAction());
    return axios({
      method: 'post',
      url: `${API_HOST_AUTH}/agreements/${agreement._id}/createDocument`,
    })
      .then(res => {
        const { agreement: resAgreement } = res.data;
        dispatch(createAgreementDocumentSuccessAction(resAgreement));
        return resAgreement;
      })
      .catch(() => {
        dispatch(createAgreementDocumentFailureAction());
      });
  };
}

export {
  createAgreementDocumentActionType,
  createAgreementDocumentSuccessActionType,
  createAgreementDocumentFailureActionType,
  createAgreementDocument,
};
