import React from 'react';
import { Field } from 'redux-form';

const normalize = value => value.toUpperCase();

const handleChange = e => {
  const caret = e.target.selectionStart;
  const element = e.target;

  window.requestAnimationFrame(() => {
    element.selectionStart = caret;
    element.selectionEnd = caret;
  });
};

const FieldCapitalized = props => (
  <Field {...props} normalize={normalize} onChange={handleChange} />
);

export { FieldCapitalized };
