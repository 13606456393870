import React, { Component } from 'react';

import { UIAuthorization } from 'components/Authorization/UIAuthorization';

import { ContentLoader } from 'shared/components/ContentLoader/ContentLoader';
import { renderDetailsContainer } from 'shared/components/Details/helpers/renderDetailsContainer';
import { NotesPanel } from 'shared/components/Notes/NotesPanel/NotesPanel';
import { hedgeTypes } from 'shared/helpers/constants/hedges/hedgeType';
import { NOTE } from 'shared/helpers/constants/noteConstants';
import { dangerOptions } from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { formatToDollarWithPrefix, formatToUS } from 'shared/helpers/parsers/formaters';

import { extractErrorMessage } from 'utils/extractErrorMessage';

import { DeliveriesList } from './components/DeliveriesOverview/DeliveriesList';
import { HedgesMainDetails } from './components/HedgesMainDetails/HedgesMainDetails';
import {
  activityContainerConfig,
  activityFieldsConfig,
  hedgeDetailsContainerConfig,
  hedgeDetailsFieldsConfig,
} from './config/fieldsConfig';

class HedgesDetails extends Component {
  componentDidMount() {
    const { match, getHedgeDetails, history, showSnackbar } = this.props;
    getHedgeDetails(match.params.id)
      .then(hedge => hedge || Promise.reject(null))
      .catch(error => {
        history.push('/hedges');
        showSnackbar(dangerOptions, extractErrorMessage(error));
      });
  }

  componentWillUnmount() {
    this.props.clearHedgeDetailsAction();
  }

  get troyOunces() {
    const {
      hedgesDetails: { hedge },
    } = this.props;
    return `Pt ${formatToUS(hedge.platinumOz, 3)} - Pd ${formatToUS(
      hedge.palladiumOz,
      3,
    )} - Rh ${formatToUS(hedge.rhodiumOz, 3)}`;
  }

  get prices() {
    const {
      hedgesDetails: {
        hedge: { platinumPriceActual, palladiumPriceActual, rhodiumPriceActual },
      },
    } = this.props;

    return platinumPriceActual !== null ||
      palladiumPriceActual !== null ||
      rhodiumPriceActual !== null
      ? `${formatToDollarWithPrefix(platinumPriceActual)}
      - ${formatToDollarWithPrefix(palladiumPriceActual)}
      - ${formatToDollarWithPrefix(rhodiumPriceActual)}`
      : null;
  }

  get pricesCustomer() {
    const {
      hedgesDetails: {
        hedge: { platinumPriceCust, palladiumPriceCust, rhodiumPriceCust },
      },
    } = this.props;

    return platinumPriceCust !== null && palladiumPriceCust !== null && rhodiumPriceCust !== null
      ? `${formatToDollarWithPrefix(platinumPriceCust)}
      - ${formatToDollarWithPrefix(palladiumPriceCust)}
      - ${formatToDollarWithPrefix(rhodiumPriceCust)}`
      : null;
  }

  render() {
    const {
      hedgesDetails: { hedge, isPending },
      auth,
      downloadAttachment,
    } = this.props;

    return (
      <ContentLoader loaded={hedge && !isPending}>
        {hedge && (
          <>
            <HedgesMainDetails />
            {renderDetailsContainer(
              hedgeDetailsContainerConfig,
              hedgeDetailsFieldsConfig,
              hedge,
              this.troyOunces,
              this.prices,
              this.pricesCustomer,
              downloadAttachment,
              auth,
            )}
            {renderDetailsContainer(activityContainerConfig, activityFieldsConfig, hedge, auth)}
            <UIAuthorization access={auth.access.hedges.notesPanel}>
              <NotesPanel match={this.props.match} relatedType={NOTE.NOTES_TYPE.HEDGES} />
            </UIAuthorization>
            {hedge.hedgeType !== hedgeTypes.standard && <DeliveriesList />}
          </>
        )}
      </ContentLoader>
    );
  }
}

export { HedgesDetails };
