import { SECTIONS } from 'shared/helpers/constants/warehouseDashboard/labels';

const virtualLotParseData = ({ date, section, subsection, name, weight }) => ({
  date: date ? new Date(date).getTime() : null,
  section: section.value || section,
  subsection: subsection?.value || subsection || null,
  weightGrossActual: weight || null,
  name,
});

const updateVirtualLotParseData = ({ _id, date, section, subsection, name, weight }) => ({
  date: date ? new Date(date).getTime() : null,
  subsection: section === SECTIONS.COMPLETE.id ? null : subsection,
  weightGrossActual: weight || null,
  section,
  name,
  _id,
});

export { virtualLotParseData, updateVirtualLotParseData };
