import React, { Component } from 'react';

import { FiltersDropdown } from 'shared/components/FiltersDropdown/FiltersDropdown';
import { locationStatuses } from 'shared/helpers/constants/filters/locationStatuses';
import { LOCATION } from 'shared/helpers/constants/locations/locationConstants';

import { StyledLocationFilters, StyledLocationFiltersItem } from './StyledLocationFilters';

class LocationsFilters extends Component {
  filterFunction = name => value => {
    this.props.onFiltersChanged({
      ...this.props.filters,
      [name]: value,
    });
  };

  render() {
    const {
      filters: { status },
    } = this.props;

    return (
      <StyledLocationFilters>
        <StyledLocationFiltersItem>
          <FiltersDropdown
            label={LOCATION.STATUS}
            options={Object.values(locationStatuses)}
            selected={status || ''}
            filterFunction={this.filterFunction('status')}
          />
        </StyledLocationFiltersItem>
      </StyledLocationFilters>
    );
  }
}

export { LocationsFilters };
