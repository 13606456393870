import {
  getGrandTotalValueActionType,
  getGrandTotalValueFailureActionType,
  getGrandTotalValueSuccessActionType,
  clearGrandTotalValueActionType,
} from 'actions/PoolAccounts/getGrandTotalValue';

const INITIAL_STATE = {
  isPending: true,
  grandTotal: null,
  totalOverall: null,
};

const getGrandTotalValueReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getGrandTotalValueActionType:
      return {
        ...state,
        isPending: true,
      };
    case getGrandTotalValueSuccessActionType:
      return {
        isPending: false,
        grandTotal: action.payload.grandTotal,
        totalOverall: action.payload.totalOverall,
      };
    case getGrandTotalValueFailureActionType:
      return {
        isPending: false,
        grandTotal: null,
        totalOverall: null,
      };
    case clearGrandTotalValueActionType:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};

export { getGrandTotalValueReducer };
