import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const markGradingCompleteType = 'MARK_GRADING_COMPLETE';
const markGradingCompleteAction = () => ({
  type: markGradingCompleteType,
});

const markGradingCompleteSuccessType = `${markGradingCompleteType}_SUCCESS`;
const markGradingCompleteSuccessAction = lot => ({
  type: markGradingCompleteSuccessType,
  payload: {
    lot,
  },
});

const markGradingCompleteFailureType = `${markGradingCompleteType}_FAILURE`;
const markGradingCompleteFailureAction = () => ({
  type: markGradingCompleteFailureType,
});

const markGradingComplete = lotId => dispatch => {
  dispatch(markGradingCompleteAction());

  return axios
    .put(`${API_HOST_AUTH}/lots/mark-grading-complete/${lotId}`)
    .then(res => {
      dispatch(markGradingCompleteSuccessAction(res.data.lot));

      return res.data;
    })
    .catch(err => {
      dispatch(markGradingCompleteFailureAction());

      return Promise.reject(err.data);
    });
};

export {
  markGradingCompleteType,
  markGradingCompleteSuccessType,
  markGradingCompleteFailureType,
  markGradingComplete,
};
