import React, { Component, Fragment } from 'react';

import { AddButton } from 'shared/components/Buttons/AddButton/AddButton';
import { Separator } from 'shared/components/Form';
import { Preloader } from 'shared/components/Preloader/Preloader';
import { hedgeTypes } from 'shared/helpers/constants/hedges/hedgeType';
import { HEDGES } from 'shared/helpers/constants/hedges/hedgesConstants';
import { initialValues } from 'shared/helpers/constants/hedges/initialValues';
import { removeHedgeRequestModal } from 'shared/helpers/constants/modalConstants';

import { HedgeForm } from '../HedgeForm/HedgeForm';

class HedgesFormList extends Component {
  getNewHedge = index => {
    const { hedgesValues } = this.props;
    return hedgesValues && hedgesValues.hedges[index];
  };

  getNewHedgeName = index => {
    const hedge = this.getNewHedge(index) || {};
    const { relatedLot, relatedCompany, hedgeType } = hedge;
    if (relatedLot && hedgeType !== hedgeTypes.blanket)
      return relatedLot.label || relatedLot.grgLotNumber;
    if (relatedCompany && hedgeType === hedgeTypes.blanket) {
      const {
        hedgesValues: { hedges },
      } = this.props;

      const previousHedges = hedges
        .slice(0, index)
        .filter(({ relatedCompany: { value } }) => value === hedge.relatedCompany.value)
        .filter(({ hedgeType }) => hedgeType === hedgeTypes.blanket);

      const counter =
        (relatedCompany.blanketHedgeCount || this.props.blanketHedgeCount) +
        previousHedges.length +
        1;

      return `${relatedCompany.lotPrefix}${HEDGES.BLANKET_HEDGE}${
        Number.isFinite(counter) ? counter : ''
      }`;
    }
    if (relatedCompany) return relatedCompany.lotPrefix;
    return '-';
  };

  addHedge = () => this.props.fields.push({ ...initialValues, formId: Date.now() });

  removeHedge = index => {
    const { openModal, fields } = this.props;

    openModal(removeHedgeRequestModal, () => fields.remove(index));
  };

  render() {
    const { fields, invalid, location, enterPlacedHedges, createHedges } = this.props;
    const maximumHedges = enterPlacedHedges ? 5 : 20;

    return (
      <>
        {fields.map((hedge, index) => (
          <Fragment key={fields.get(index).formId}>
            <HedgeForm
              {...this.props}
              hedge={hedge}
              index={index}
              header={`${HEDGES.HEDGE} ${this.getNewHedgeName(index)}`}
              value={this.getNewHedge(index) || {}}
              onRemove={fields.length > 1 && (() => this.removeHedge(index))}
            />
            <Separator />
          </Fragment>
        ))}
        {fields.length < maximumHedges && !(location.state && location.state.relatedLot) && (
          <AddButton onClick={this.addHedge} disabled={invalid}>
            {HEDGES.ADD}
          </AddButton>
        )}
        <Preloader loading={createHedges.isPending} />
      </>
    );
  }
}

export { HedgesFormList };
