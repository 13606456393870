const UPDATE_DEFAULT_PPMS = {
  MONO_PLATINUM_ASSAY_EMPTY: 'Mono platinum assay is required.',
  MONO_PLATINUM_ASSAY_INVALID: 'Mono platinum assay must have valid format.',
  MONO_PLATINUM_ASSAY_NEGATIVE: 'Mono platinum assay cannot be less than 0.',
  MONO_PALLADIUM_ASSAY_EMPTY: 'Mono palladium assay is required.',
  MONO_PALLADIUM_ASSAY_INVALID: 'Mono palladium assay must have valid format.',
  MONO_PALLADIUM_ASSAY_NEGATIVE: 'Mono palladium assay cannot be less than 0.',
  MONO_RHODIUM_ASSAY_EMPTY: 'Mono rhodium assay is required.',
  MONO_RHODIUM_ASSAY_INVALID: 'Mono rhodium assay must have valid format.',
  MONO_RHODIUM_ASSAY_NEGATIVE: 'Mono rhodium assay cannot be less than 0.',
  FOIL_PLATINUM_ASSAY_EMPTY: 'Foil platinum assay is required.',
  FOIL_PLATINUM_ASSAY_INVALID: 'Foil platinum assay must have valid format.',
  FOIL_PLATINUM_ASSAY_NEGATIVE: 'Foil platinum assay cannot be less than 0.',
  FOIL_PALLADIUM_ASSAY_EMPTY: 'Foil palladium assay is required.',
  FOIL_PALLADIUM_ASSAY_INVALID: 'Foil palladium assay must have valid format.',
  FOIL_PALLADIUM_ASSAY_NEGATIVE: 'Foil palladium assay cannot be less than 0.',
  FOIL_RHODIUM_ASSAY_EMPTY: 'Foil rhodium assay is required.',
  FOIL_RHODIUM_ASSAY_INVALID: 'Foil rhodium assay must have valid format.',
  FOIL_RHODIUM_ASSAY_NEGATIVE: 'Foil rhodium assay cannot be less than 0.',
  SENSORS_PLATINUM_ASSAY_EMPTY: 'Sensors platinum assay is required.',
  SENSORS_PLATINUM_ASSAY_INVALID: 'Sensors platinum assay must have valid format.',
  SENSORS_PLATINUM_ASSAY_NEGATIVE: 'Sensors platinum assay cannot be less than 0.',
  SENSORS_PALLADIUM_ASSAY_EMPTY: 'Sensors palladium assay is required.',
  SENSORS_PALLADIUM_ASSAY_INVALID: 'Sensors palladium assay must have valid format.',
  SENSORS_PALLADIUM_ASSAY_NEGATIVE: 'Sensors palladium assay cannot be less than 0.',
  SENSORS_RHODIUM_ASSAY_EMPTY: 'Sensors rhodium assay is required.',
  SENSORS_RHODIUM_ASSAY_INVALID: 'Sensors rhodium assay must have valid format.',
  SENSORS_RHODIUM_ASSAY_NEGATIVE: 'Sensors rhodium assay cannot be less than 0.',
};

export { UPDATE_DEFAULT_PPMS };
