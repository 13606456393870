import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';
import styled from 'styled-components';

import { SubmitButton, CancelButton } from 'shared/components/Buttons';
import { FieldUpload } from 'shared/components/Fields/FiledUpload/FieldUpload';
import { FieldUploadList } from 'shared/components/Fields/FiledUpload/FieldUploadList';
import { Preloader } from 'shared/components/Preloader/Preloader';
import { TERMS } from 'shared/helpers/constants/terms/termsConstants';
import { validate } from 'shared/helpers/validations/term/documentValidations';
import { TermsUploadSignedStyledForm } from './TermsUploadSignedStyledForm';

const SubmitContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 35px;
`;

class TermsUploadSignedForm extends Component {
  componentWillUnmount() {
    this.props.termsDocumentClearAction();
  }

  inputFieldChanged = e => {
    const filesArray = [].slice.call(e.target.files);
    this.props.termsDocumentAddedAction(filesArray);
  };

  render() {
    const { termsUploadDocument, closeModal } = this.props;

    return (
      <React.Fragment>
        <Preloader loading={termsUploadDocument.isPending} label={TERMS.UPLOAD_SIGNED_PROGRESS} />
        <TermsUploadSignedStyledForm noValidate onSubmit={this.props.handleSubmit}>
          <Field
            name="signedDocument"
            type="file"
            component={FieldUpload}
            label={TERMS.UPLOAD_SIGNED}
            validExtensions=".pdf"
            onChange={this.inputFieldChanged}
          />
          {termsUploadDocument.files && (
            <FieldUploadList files={[{ file: termsUploadDocument.files[0] }]} previewIcoInside />
          )}
          <SubmitContainer>
            <CancelButton onClick={closeModal} />
            <SubmitButton
              label={TERMS.UPLOAD}
              disabled={!termsUploadDocument.files || !this.props.valid}
            />
          </SubmitContainer>
        </TermsUploadSignedStyledForm>
      </React.Fragment>
    );
  }
}

const TermsUploadSigned = reduxForm({
  form: 'UploadSignedTerms',
  validate,
})(TermsUploadSignedForm);

export { TermsUploadSigned, TermsUploadSignedForm };
