import React from 'react';
import { Redirect } from 'react-router';

import { Unauthorized } from 'components/ErrorPage/ErrorPage';

function Authorization(WrappedComponent, access, redirectUrl) {
  const alternative = redirectUrl ? () => <Redirect to={redirectUrl} /> : Unauthorized;

  return access ? WrappedComponent : alternative;
}

export { Authorization };
