import styled from 'styled-components';

import { media } from 'shared/helpers/styling/styling';

export const StyledPreloaderInlineComponent = styled.div`
  margin: 2rem auto;
  border: none;
  outline: none;
  border-radius: 0;
  padding: 20px;
  max-width: 540px;
  max-height: 400px;
  right: 0;
  bottom: 0;

  .lite {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  ${media.phone`
max-width: 290px;
`}
`;

export const StyledPreloaderInlineContent = styled.div`
  padding: 0 60px;
  position: relative;
  text-align: center;
  ${media.phone`
padding: 20px 10px 0 10px;
text-align: center;
`}
`;

export const StyledPreloaderInlineTextarea = styled.div`
  padding: 95px 0 35px 0;
`;

export const StyledPreloaderInlineTitle = styled.h2`
  color: var(--whiteGRG);
  font-size: 3.2em;

  .lite & {
    font-size: 1.5em;
  }
`;

export const StyledPreloaderInlineDesc = styled.div`
  color: var(--whiteGRG);
`;
