import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getLocationsActionType = 'GET_LOCATIONS';
function getLocationsAction() {
  return {
    type: getLocationsActionType,
  };
}

const getLocationsSuccessActionType = 'GET_LOCATIONS_SUCCESS';
function getLocationsSuccessAction(locations, pages, total) {
  return {
    type: getLocationsSuccessActionType,
    payload: {
      locations,
      pages,
      total,
    },
  };
}

const getLocationsFailureActionType = 'GET_LOCATIONS_FAILURE';
function getLocationsFailureAction(errorMessage) {
  return {
    type: getLocationsFailureActionType,
    payload: {
      errorMessage,
    },
  };
}

const clearLocationsListActionType = 'CLEAR_LOCATIONS_LIST_ACTION';
function clearLocationsList() {
  return {
    type: clearLocationsListActionType,
  };
}

function companyDetailsGetLocations(companyId, limit, page) {
  return dispatch => {
    dispatch(getLocationsAction());

    return axios
      .get(`${API_HOST_AUTH}/locations/companies/${companyId}/${page}/${limit}`)
      .then(res => {
        dispatch(getLocationsSuccessAction(res.data.docs, res.data.pages, res.data.total));
      })
      .catch(() => {
        dispatch(getLocationsFailureAction(''));
      });
  };
}

export {
  clearLocationsListActionType,
  getLocationsActionType,
  getLocationsFailureActionType,
  getLocationsSuccessActionType,
  companyDetailsGetLocations,
  clearLocationsList,
};
