import React from 'react';
import styled from 'styled-components';

import { DoughnutProgress } from 'shared/components/DoughnutProgress';
import { cssVar, pxToRem } from 'shared/helpers/styling/styling';

const SummaryWrapper = styled.div`
  color: ${cssVar('alabaster')};
  font-size: ${pxToRem(14)};
  margin-top: 17px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: center;

  ul {
    list-style: none;
    margin: 10px 0 0 0;
  }

  li {
    position: relative;
    height: 25px;

    &::before {
      display: block;
      left: -20px;
      top: 3px;
      content: '';
      position: absolute;
      width: 16px;
      height: 16px;
      border-radius: 50%;
    }

    &:nth-child(2n + 1)::before {
      background: ${cssVar('atlantis')};
    }
    &:nth-child(2n)::before {
      background: ${cssVar('fiord')};
    }
  }
`;

const ScanningSummary = ({ done, total, doneLabel, totalLabel }) => (
  <>
    {(done || done === 0) && total && (
      <SummaryWrapper>
        <DoughnutProgress progress={done / total} size={150} />
        <ul>
          <li>{`${doneLabel} (${done})`}</li>
          <li>{`${totalLabel} (${total - done})`}</li>
        </ul>
      </SummaryWrapper>
    )}
  </>
);

export { ScanningSummary };
