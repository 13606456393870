import PropTypes from 'prop-types';
import { Component } from 'react';

import { FormContainer } from 'shared/components/Form';
import { SHIPMENTS } from 'shared/helpers/constants/shipments/shipmentsConstants';
import { snackbarOptions } from 'shared/helpers/matchers/snackbar/snackbarOptions';

import { OutgoingShipmentForm } from '../OutgoingShipmentForm/OutgoingShipmentForm';

class CreateOutgoingShipment extends Component {
  submit = values => this.props.createOutgoingShipment(values).then(this.resolveAction);

  resolveAction = result => {
    this.props.showSnackbar(snackbarOptions(result.messageType), result.message);

    if (result.id) {
      this.props.history.push(`/shipments/${result.id}`);
      this.props.outgoingLotsOverviewClearAction();
    }
  };

  componentWillUnmount() {
    this.props.clearAssignedLotsAction();
  }

  render() {
    const initialValues = {
      relatedCompany: {
        label: this.props.auth.user.relatedCompany.companyName,
      },
    };

    return (
      <FormContainer header={SHIPMENTS.CREATE_OUTGOING_SHIPMENT}>
        <OutgoingShipmentForm
          {...this.props}
          onSubmit={this.submit}
          initialValues={initialValues}
        />
      </FormContainer>
    );
  }
}

CreateOutgoingShipment.propTypes = {
  assignedLots: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        customLotNumber: PropTypes.string,
        grgLotNumber: PropTypes.string,
        _id: PropTypes.string,
      }),
    ),
  }),
  auth: PropTypes.shape({
    attempts: PropTypes.number,
    user: PropTypes.object,
    access: PropTypes.object,
    isPending: PropTypes.bool,
  }),
  outgoingLotsOverview: PropTypes.shape({
    readyToShip: PropTypes.object,
    shippedFromGlobal: PropTypes.object,
    unsentShipments: PropTypes.object,
  }),
  closeModal: PropTypes.func,
  createOutgoingShipment: PropTypes.func,
  clearAssignedLotsAction: PropTypes.func,
  openModal: PropTypes.func,
  showSnackbar: PropTypes.func,
  outgoingLotsOverviewClearAction: PropTypes.func,
  getAssignedLotsForOutgoingShipment: PropTypes.func,
  isPending: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
    state: PropTypes.object,
    key: PropTypes.string,
  }),
  match: PropTypes.shape({
    path: PropTypes.string,
    url: PropTypes.string,
    isExact: PropTypes.bool,
    params: PropTypes.object,
  }),
  modal: PropTypes.object,
};

export { CreateOutgoingShipment };
