import { markAsResolvedSuccessActionType } from 'actions/Notifications/markAsResolved';
import {
  getNotificationActionType,
  getNotificationFailureActionType,
  getNotificationSuccessActionType,
  clearNotificationDetailsActionType,
} from 'actions/Notifications/notificationDetails';

const INITIAL_STATE = {
  isPending: false,
  errorMessage: null,
  notification: null,
  redirectedFrom: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getNotificationActionType:
      return {
        ...state,
        isPending: true,
      };
    case getNotificationSuccessActionType:
      return {
        ...state,
        isPending: false,
        notification: action.payload.notification,
        redirectedFrom: action.payload.redirectedFrom,
      };
    case getNotificationFailureActionType:
      return {
        ...state,
        isPending: false,
        errorMessage: action.payload.errorMessage,
      };
    case clearNotificationDetailsActionType:
      return {
        ...state,
        isPending: false,
        errorMessage: null,
        notification: null,
        redirectedFrom: null,
      };
    case markAsResolvedSuccessActionType:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};
