import React, { useContext, useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { FieldArray, reduxForm, getFormSyncErrors, getFormValues, change } from 'redux-form';

import { getAssignedLots as getAssignedLotsAction } from 'actions/Lots/getAssignedLots';
import { openModal } from 'actions/shared/modal';

import { Form } from 'shared/components/Form';
import { wizardContext, formName } from 'shared/components/Wizard/Wizard';
import { cancelModal } from 'shared/helpers/constants/modalConstants';
import { PACKAGES } from 'shared/helpers/constants/packages/packageConstants';
import { packageType } from 'shared/helpers/constants/packages/packageType';
import { isCustomerOrGrading } from 'shared/helpers/matchers/checkUserType';
import { validate } from 'shared/helpers/validations/package/incomingPackageListValidation';

import { goBackOrTo } from 'utils/history';

import { IncomingPackagesFormList } from 'pages/Packages/components/PackagesManagement/components/MultiPackagesCreate/components/IncomingPackagesFormList/IncomingPackagesFormList';
import { configNames } from 'pages/Packages/components/PackagesManagement/components/PackageForm/components/PackageForm/fieldsConfig';

const MultiPackagesCreate = props => {
  const {
    auth: { user },
    getAssignedLots,
  } = props;

  const [header, setHeader] = useState('');
  const dispatch = useDispatch();
  const wizardData = useSelector(state => getFormValues('wizard')(state));
  const wizard = useContext(wizardContext);
  const isEditMultiplePackages = wizard.config === configNames.editMultiplePackages;

  useEffect(() => {
    if (!wizard.hiddenStepsCount) {
      return;
    }

    const containsSample = (wizardData.packages || []).some(
      pkg => pkg.packageType?.value === packageType.sample,
    );

    wizard.setHiddenSteps(Number(!containsSample));
  }, [wizardData.packages]);

  useEffect(() => {
    if (isCustomerOrGrading(user.userType)) {
      getAssignedLots(user.relatedCompany._id);
    }

    if (isEditMultiplePackages && !wizard.incomingPkgIsPending) {
      wizard.assignedPackages.map((pkg, index) => {
        dispatch(change('wizard', `packages[${index}]`, pkg));
      });
    }

    if (isEditMultiplePackages && wizardData.packages?.length > 1) {
      wizardData.packages.map((pkg, index) => {
        dispatch(change('wizard', `packages[${index}]`, pkg));
      });
    }

    setHeader(renderHeader());
  }, [getAssignedLots, user.userType, user.relatedCompany._id, wizard.assignedPackages]);

  const onCancel = () => {
    props.openModal(cancelModal, () => {
      goBackOrTo('/');
    });
  };

  const hasErrors =
    props.formErrors.packages &&
    props.formErrors.packages.some(err => err && Object.values(err).length);

  const renderHeader = () => {
    if (wizard.config) {
      switch (wizard.config) {
        case configNames.postMill:
          return PACKAGES.CREATE_POST_MILL_PACKAGES;
        case configNames.postMix:
          return PACKAGES.CREATE_POST_MIX_PACKAGES;
        case configNames.postShears:
          return PACKAGES.CREATE_POST_SHEARS_PACKAGES;
        case configNames.packingList:
          return PACKAGES.CREATE_PACKING_LIST_PACKAGES;
        default:
          return;
      }
    }
    return PACKAGES.CREATE_INCOMING_PACKAGES;
  };

  const packingListConfig =
    wizard.config ||
    (props.forLot?.sentFromCompany.materialCountRequired && configNames.packingList);

  return (
    <Form
      header={header}
      onCancel={onCancel}
      onSubmit={props.handleSubmit}
      submitDisabled={props.invalid || props.submitting || hasErrors}
      loaded={!props.createMultiplePackages.isPending}
      wide
    >
      <FieldArray
        auth={props.auth}
        assignedLots={props.assignedLots}
        forLot={props.forLot}
        change={props.change}
        focus={props.focus}
        touch={props.touch}
        name="packages"
        location={props.location}
        component={IncomingPackagesFormList}
        config={packingListConfig || configNames.incoming}
        pkgQuantity={wizardData.defaultPackagesValues?.packagesQuantity || 1}
        data={{
          ...wizardData.defaultPackagesValues,
          ...(!wizard.editMode && { oldValues: wizard?.data?.defaultPackagesValues?.oldValues }),
        }}
        packagesData={wizardData.packages}
      />
    </Form>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
  lotDetails: state.lotDetails,
  assignedLots: state.assignedLots,
  forLot: state.lotDetails.lot,
  packageDetails: state.packageDetails,
  formErrors: getFormSyncErrors(formName)(state),
  createMultiplePackages: state.createMultiplePackages,
});

const mapDispatchToProps = {
  openModal,
  getAssignedLots: getAssignedLotsAction,
};

const ConnectedMultiPackagesForm = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'IncomingPackagesForm',
    shouldError: () => true,
    validate,
  }),
)(MultiPackagesCreate);

export { ConnectedMultiPackagesForm };
