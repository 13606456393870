import PropTypes from 'prop-types';
import { useState } from 'react';
import { touch } from 'redux-form';

import { TinyFieldInput } from 'shared/components/Fields';
import { FieldCapitalized } from 'shared/components/Fields/FieldCapitalized/FieldCapitalized';
import { FieldsFilter } from 'shared/components/FieldsFilter/FieldsFilter';
import { navigateTable } from 'shared/helpers/forms/navigateTable';

import { refreshToken } from 'utils/refreshToken';

import { FormRowCell } from 'pages/Packages/components/PackagesManagement/components/MultiPackagesCreate/components/IncomingPackagesFormList/components/IncomingPackageListTemplate/components/FormRowCell';
import {
  configNames,
  configs,
} from 'pages/Packages/components/PackagesManagement/components/PackageForm/components/PackageForm/fieldsConfig';

import {
  StyledPackageSection,
  StyledMultiPackagesFormHeader,
  StyledFormRowCell,
} from './StyledSamplePackagesListTemplate';

const SamplePackagesListTemplate = ({
  samplePkgData,
  fieldName,
  formErrors,
  assignedCatsQuantity,
  index,
  subIndex,
  focusedRow,
  setFocusedRow,
  focusedCol,
  setFocusedCol,
  withoutRequiredFields,
}) => {
  const [wasFocused, setWasFocused] = useState(false);

  const focusCol = (e, name) => {
    refreshToken();
    const colName = (e?.target.name || name).replace(`${fieldName}.`, '');
    setFocusedCol(colName);
  };

  const getFieldError = () => formErrors?.packages?.[index]?.samplePackages?.[subIndex];

  const formatName = name => name?.split('].')[2];

  const navigate = e =>
    navigateTable(
      e,
      {
        current: subIndex,
        max: assignedCatsQuantity - 1,
        fields: ['primaryId', 'secondaryId'],
      },
      {
        row: setFocusedRow,
        column: setFocusedCol,
        triggerErrors: setWasFocused,
      },
      refreshToken,
      formatName,
    );

  const setNextRow = (e, subIndex) => {
    if (e.keyCode === 9 && !e.shiftKey) {
      e.preventDefault();
      setFocusedRow(subIndex + 1);

      focusCol(null, 'primaryId');
    }
  };

  const setPrevRow = (e, subIndex) => {
    if (e.keyCode === 9 && e.shiftKey) {
      e.preventDefault();
      setFocusedRow(subIndex - 1);
      focusCol(null, 'secondaryId');
    }
  };

  const focusRow = () => {
    const toTouch = [];

    getFieldError() &&
      Object.keys(getFieldError()).forEach(key => toTouch.push(`${fieldName}.${key}`));

    touch(...toTouch);
    setFocusedRow(subIndex);
    setWasFocused(true);
  };

  const filter = ({ props: fieldsProps }) => {
    const name = fieldsProps.name.replace(`${fieldName}.`, '');

    return configs[configNames[configNames.samplePackages]].includes(name);
  };

  const renderPkgNumber = (index, packagesQuantity) => `${index + 1} / ${packagesQuantity}`;

  const getSectionWithError = () => getFieldError() && Object.values(getFieldError()).length;

  return (
    <FieldsFilter filterFunc={filter}>
      {subIndex === 0 && (
        <StyledMultiPackagesFormHeader
          requiredFields={{ primaryId: !withoutRequiredFields }}
          package={fieldName}
          config={configNames.samplePackages}
        />
      )}
      {focusedRow === subIndex ? (
        <>
          {subIndex !== 0 && (
            <StyledMultiPackagesFormHeader
              package={fieldName}
              config={configNames.samplePackages}
              requiredFields={{ primaryId: !withoutRequiredFields }}
            />
          )}
          <StyledPackageSection onClick={focusRow} focused config={configNames.samplePackages}>
            <FormRowCell name={`${fieldName}.number`} noBorder>
              {renderPkgNumber(subIndex, assignedCatsQuantity)}
            </FormRowCell>
            <FieldCapitalized
              name={`${fieldName}.primaryId`}
              type="text"
              forwardRef
              component={TinyFieldInput}
              field={withoutRequiredFields ? '' : 'required'}
              onClick={focusCol}
              focused={focusedCol === 'primaryId'}
              onKeyDown={e => setPrevRow(e, subIndex) || navigate(e)}
              height={45}
              data-testid={`${fieldName}.primaryId`}
            />

            <FieldCapitalized
              name={`${fieldName}.secondaryId`}
              component={TinyFieldInput}
              forwardRef
              type="text"
              onClick={focusCol}
              focused={focusedCol === 'secondaryId'}
              onKeyDown={e => setNextRow(e, subIndex) || navigate(e)}
              height={45}
              data-testid={`${fieldName}.secondaryId`}
            />
          </StyledPackageSection>
        </>
      ) : (
        <StyledPackageSection
          hasError={getSectionWithError() && wasFocused}
          onClick={focusRow}
          config={configNames.samplePackages}
        >
          <FormRowCell name={`${fieldName}.number`} noBorder>
            {renderPkgNumber(subIndex, assignedCatsQuantity)}
          </FormRowCell>
          <StyledFormRowCell name={`${fieldName}.primaryId`} onClick={focusCol}>
            {samplePkgData?.primaryId || ''}
          </StyledFormRowCell>
          <StyledFormRowCell name={`${fieldName}.secondaryId`} onClick={focusCol}>
            {samplePkgData?.secondaryId || ''}
          </StyledFormRowCell>
        </StyledPackageSection>
      )}
    </FieldsFilter>
  );
};
SamplePackagesListTemplate.propTypes = {
  samplePkgData: PropTypes.shape({
    primaryId: PropTypes.string,
    secondaryId: PropTypes.string,
  }),
  fieldName: PropTypes.string,
  formErrors: PropTypes.object,
  assignedCatsQuantity: PropTypes.number,
  index: PropTypes.number,
  subIndex: PropTypes.number,
  focusedRow: PropTypes.number,
  setFocusedRow: PropTypes.func,
  focusedCol: PropTypes.string,
  setFocusedCol: PropTypes.func,
  withoutRequiredFields: PropTypes.bool,
};

export { SamplePackagesListTemplate };
