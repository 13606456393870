import React from 'react';

import { OverviewListTableCell } from 'shared/components/OverviewList/OverviewListTableCell/OverviewListTableCell';
import { OverviewTableRow } from 'shared/components/OverviewList/OverviewTableRow/OverviewTableRow';

function ListPlaceholder() {
  return (
    <OverviewTableRow itemsNumber={0} header>
      <OverviewListTableCell header>List is empty</OverviewListTableCell>
    </OverviewTableRow>
  );
}

export { ListPlaceholder };
