const PRICE_FEEDS = {
  PRICE_FEEDS: 'Price feeds',
  PRICE_FEED: 'Price feed',
  UPDATE_PRICE_FEED: 'Change feed source',
  METAL: 'Metal',
  SOURCE: 'Source',
  ADJUSTMENT: 'Adjustment',
  MANUAL_PRICE: 'Manual price',
  SYSTEM_PRICE: 'System price',
  UPDATED_BY: 'Updated by',
  UPDATED_AT: 'Updated on',
};

const priceFeed = {
  source: {
    marketFeed: 'market_feed',
    marketFeedAdjusted: 'market_feed_adjusted',
    manualEntry: 'manual_entry',
  },
};

export { PRICE_FEEDS, priceFeed };
