import { Button } from 'shared/components/Buttons';

import { history } from 'utils/history';

import { StyledLogoutButtonsPanel } from './StyledLogoutButtons';

function changeLocation() {
  history.push('/');
}

export const LogoutButton = () => {
  return (
    <StyledLogoutButtonsPanel>
      <Button id="signInOpen" onClick={changeLocation}>
        Sign In
      </Button>
    </StyledLogoutButtonsPanel>
  );
};
