import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { getCompanyLocations } from 'actions/Locations/getCompanyLocations';
import { clearAllPackagesOverview } from 'actions/Packages/packagesOverviewList';

import { Shipments } from './Shipments';

function mapStateToProps({ auth, router, companyLocations }) {
  return {
    auth,
    router,
    companyLocations,
  };
}

const mapDispatchToProps = {
  getCompanyLocations,
  clearAllPackagesOverview,
};

const ConnectedShipments = withRouter(connect(mapStateToProps, mapDispatchToProps)(Shipments));

export { ConnectedShipments };
