import {
  getPoolAccountJournalActionType,
  getPoolAccountJournalFailureActionType,
  getPoolAccountJournalSuccessActionType,
  clearPoolAccountJournalActionType,
} from 'actions/PoolAccounts/poolAccountJournal';

const INITIAL_STATE = {
  isPending: false,
  journal: {
    docs: [],
    limit: null,
    page: null,
    pages: null,
    total: null,
  },
  filters: { query: '' },
  pagination: { limit: 50, page: 1 },
};

export const poolAccountJournalReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getPoolAccountJournalActionType:
      return {
        ...state,
        filters: action.payload.filters,
        pagination: action.payload.pagination,
        isPending: true,
      };
    case getPoolAccountJournalSuccessActionType:
      return {
        ...state,
        isPending: false,
        journal: {
          ...action.payload.journalEntries,
        },
      };
    case getPoolAccountJournalFailureActionType:
      return {
        ...state,
        isPending: false,
      };
    case clearPoolAccountJournalActionType:
      return {
        ...INITIAL_STATE,
        filters: state.filters,
      };
    default:
      return state;
  }
};
