import {
  inspectCfuActionType,
  inspectCfuAproveActionType,
  inspectCfuFailureActionType,
  inspectCfuRejectActionType,
  inspectCfuSuccessActionType,
} from '../../actions/Cfu/inspectCfu';

const INITIAL_STATE = {
  isPending: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case inspectCfuActionType:
      return {
        isPending: true,
      };
    case inspectCfuAproveActionType:
    case inspectCfuFailureActionType:
    case inspectCfuRejectActionType:
    case inspectCfuSuccessActionType:
      return {
        isPending: false,
      };
    default:
      return state;
  }
};
