import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';

import { FieldSelect } from 'shared/components/Fields/FieldSelect/FieldSelect';
import { FieldSearch } from 'shared/components/Fields/index';
import { Form, Section } from 'shared/components/Form';
import { exceptionTypes } from 'shared/helpers/constants/companies/exceptionTypes';
import { cancelModal } from 'shared/helpers/constants/modalConstants';
import { PAYMENTS } from 'shared/helpers/constants/payments/paymentsConstants';
import { mapArray } from 'shared/helpers/parsers/select';
import { validate } from 'shared/helpers/validations/company/privilegedCompaniesValidation';

import { goBackOrTo } from 'utils/history';

import { ExceptionTypeInformation } from '../ExceptionTypeInformation/ExceptionTypeInformation.jsx';

class PaymentExceptionCompaniesFormLayout extends Component {
  state = {
    submitDisabled: true,
  };

  onCancel = () => {
    this.props.openModal(cancelModal, () => {
      goBackOrTo('/payments/payment-exception-companies');
    });
  };

  checkSelectedExceptionType = value => {
    const { exceptionTypes } = this.props.selectedCompany || {};

    const companyExceptionTypes = exceptionTypes
      ?.map(({ value }) => value)
      .sort((a, b) => a.localeCompare(b));
    const selectedExceptionTypes = value
      ?.map(({ value }) => value)
      .sort((a, b) => a.localeCompare(b));

    this.setState({
      submitDisabled:
        JSON.stringify(companyExceptionTypes) === JSON.stringify(selectedExceptionTypes),
    });
  };

  handleChangeCompany = value => {
    this.checkSelectedExceptionType();
    return this.props.change('exceptionTypes', value?.exceptionTypes);
  };

  listCompanies = input =>
    !input
      ? Promise.resolve({ options: [] })
      : this.props.filterPaymentExceptionCompaniesToAdd(input);

  render() {
    const submitDisabled = this.props.invalid || this.props.submitting || this.state.submitDisabled;

    return (
      <Form
        header={PAYMENTS.EXCEPTION_COMPANY}
        onSubmit={this.props.handleSubmit}
        onCancel={this.onCancel}
        submitDisabled={submitDisabled}
      >
        <Section template={['relatedCompany exceptionTypes']}>
          <Field
            name="relatedCompany"
            component={FieldSearch}
            label={PAYMENTS.PAYMENT_EXCEPTION_COMPANIES.RELATED_COMPANY}
            field="required"
            getOptions={this.listCompanies}
            onChange={this.handleChangeCompany}
          />
          <Field
            name="exceptionTypes"
            component={FieldSelect}
            label={PAYMENTS.PAYMENT_EXCEPTION_COMPANIES.EXCEPTION_TYPE}
            field="required"
            onChange={this.checkSelectedExceptionType}
            options={mapArray(Object.values(exceptionTypes))}
            multi
            wrap
          />
        </Section>
        <ExceptionTypeInformation />
      </Form>
    );
  }
}

const PaymentExceptionCompaniesForm = reduxForm({
  form: 'PaymentExceptionCompaniesForm',
  validate,
})(PaymentExceptionCompaniesFormLayout);

export { PaymentExceptionCompaniesForm, PaymentExceptionCompaniesFormLayout };
