import React from 'react';

import { UIAuthorization } from 'components/Authorization/UIAuthorization';

import { Button } from 'shared/components/Buttons/Button';
import { cssVar } from 'shared/helpers/styling/styling';

import {
  OverviewItemsDetailsTitle,
  OverviewListMobileActions,
  OverviewListMobileDetails,
} from '../OverviewListMobileCollapsed/OverviewListMobileCollapsed';
import { OverviewListTableCell } from '../OverviewListTableCell/OverviewListTableCell';
import { OverviewTableRow } from '../OverviewTableRow/OverviewTableRow';

function OverviewItemDetails({
  item,
  displayDetails,
  itemsTemplate,
  templateProps,
  actionsTemplate,
  actionsContext,
  itemDetailsTemplate,
  displayAllDetails,
  fillContent,
  logs,
  customColor,
  isDisabled,
}) {
  const detailsTemplate = itemDetailsTemplate || itemsTemplate;
  const filteredActionsTemplate = actionsTemplate.filter(
    ({ access }) => !access || access.call(actionsContext, item),
  );

  return (
    <OverviewTableRow
      mobile
      isOpen={displayAllDetails || displayDetails}
      mobileDetails
      customColor={customColor}
      isDisabled={isDisabled}
    >
      <OverviewListTableCell header margin={displayAllDetails ? '0' : '10px 0'}>
        <OverviewListMobileDetails fillContent={fillContent} logs={logs}>
          {detailsTemplate.map(({ label, render, className }, index) => (
            <div key={index} className={className || ''}>
              <OverviewItemsDetailsTitle>{`${label}:`}</OverviewItemsDetailsTitle>
              <div>{render(item, index, templateProps)}</div>
            </div>
          ))}
          <UIAuthorization access={filteredActionsTemplate.length}>
            <OverviewListMobileActions>
              {filteredActionsTemplate.map(({ icon, action, onClick }, index) => (
                <Button
                  onClick={onClick.call(actionsContext, item)}
                  bgColor={cssVar('outerSpaceBrighter')}
                  noOutline
                  borderRadius={10}
                  key={index}
                >
                  {action}
                  <span className={`icon icon-${icon}`} />
                </Button>
              ))}
            </OverviewListMobileActions>
          </UIAuthorization>
        </OverviewListMobileDetails>
      </OverviewListTableCell>
    </OverviewTableRow>
  );
}

OverviewItemDetails.defaultProps = {
  actionsContext: null,
};

export { OverviewItemDetails };
