import React, { Component } from 'react';

import { isPhone } from 'shared/helpers/constants/resolutionsConstants';
import { capitalizeGlobal, humanize } from 'shared/helpers/parsers/text';

import { getBrowserWidth } from 'utils/responsive';

import { withFieldWrapper } from '../Fields/FieldWrapper/FieldWrapper';
import { Icon } from '../Icon/Icon';
import { DropdownIcon } from '../ListFilters/DropdownIcon/DropdownIcon';
import { MenuDropdown } from '../ListFilters/DropdownMenu/DropdownMenu';
import { DropdownMenuItemText } from '../ListFilters/styles/SelectDropdown.styles';
import {
  StyledFiltersDropdown,
  StyledFiltersDropdownToggle,
  StyledFiltersDropdownItem,
  StyledFiltersDropdownInput,
} from './StyledFiltersDropdown';

class FiltersDropdownBase extends Component {
  state = {
    open: false,
    multiSelect: false,
    selected: [],
    windowWidth: getBrowserWidth(),
  };

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
    this.setFilters();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  static getDerivedStateFromProps(nextProps) {
    return {
      selected: nextProps.selected,
      multiSelect: nextProps.multiSelect,
    };
  }

  handleWindowSizeChange = () => this.setState({ windowWidth: getBrowserWidth() });

  setFilters = () => {
    this.setState({
      selected: [...this.props.selected],
      multiSelect: this.props.multiSelect,
    });
  };

  dropdownToggle = () => {
    this.setState(prevState => ({ open: !prevState.open }));
  };

  selectOptions = option => {
    const { selected, multiSelect } = this.state;
    const optionsSelected = [...selected];

    if (optionsSelected.includes(option)) {
      const index = optionsSelected.indexOf(option);

      if (index > -1) {
        optionsSelected.splice(index, 1);
      }
    } else if (multiSelect) {
      optionsSelected.push(option);
    } else {
      optionsSelected[0] = option;
    }

    return optionsSelected;
  };

  optionSelected = option => {
    const { filterOptionsSelected, filterFunction } = this.props;
    const selected = this.selectOptions(option);
    this.setState({ selected });
    filterOptionsSelected && filterOptionsSelected(selected);
    filterFunction && filterFunction(selected);
  };

  renderItem = item =>
    this.props.children ? this.props.children(item) : humanize(item, this.props.itemPostParser);

  clearCurrentInput = e => {
    const { filterOptionsSelected, filterFunction } = this.props;
    filterOptionsSelected && filterOptionsSelected([]);
    filterFunction && filterFunction([]);
    e.stopPropagation();
  };

  renderStatusLabel = selected => {
    const isOnPhone = this.state.windowWidth < isPhone;
    return (
      <React.Fragment>
        {this.renderItem(
          selected[0].length > 18 && isOnPhone ? `${selected[0].slice(0, 18)}...` : selected[0],
        )}
        {selected.length > 1 ? `, and ${selected.length - 1} more` : ''}
        {!this.props.notClearable && (
          <Icon
            icon="icon-cross"
            width={11}
            height={11}
            size="contain"
            onClick={e => this.clearCurrentInput(e)}
          />
        )}
      </React.Fragment>
    );
  };

  handleClick = item => {
    this.optionSelected(item);
    this.dropdownToggle();
  };

  renderDropdownItems = (options, selected) =>
    options &&
    options.map(item => (
      <React.Fragment key={item}>
        <StyledFiltersDropdownItem
          toggle={false}
          id={`${item}-SelectItem`}
          selected={selected?.includes(item) ?? false}
          onClick={() => this.handleClick(item)}
        >
          <DropdownMenuItemText>{this.renderItem(capitalizeGlobal(item))}</DropdownMenuItemText>
        </StyledFiltersDropdownItem>
        <div className="SelectDropdown-menu-options-divider" />
      </React.Fragment>
    ));

  render() {
    const { options, placeholder, disabled, id } = this.props;
    const { open, selected } = this.state;

    return (
      <StyledFiltersDropdown isOpen={open} toggle={this.dropdownToggle} id={id}>
        <StyledFiltersDropdownToggle disabled={disabled}>
          <StyledFiltersDropdownInput isSelected={selected.length}>
            {selected.length ? this.renderStatusLabel(selected) : placeholder}
          </StyledFiltersDropdownInput>
          <DropdownIcon dropdownOpen={open} />
        </StyledFiltersDropdownToggle>
        <MenuDropdown
          id="DropdownMenu"
          renderDropdownItems={this.renderDropdownItems(options, selected)}
        />
      </StyledFiltersDropdown>
    );
  }
}

const FiltersDropdown = withFieldWrapper(FiltersDropdownBase);

export { FiltersDropdown };
