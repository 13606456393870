const statusesColors = {
  inactive: 'doveGray',
  active: 'apple',
  no_terms: 'highland',
  draft: 'grayGRG',
  awaiting: 'dingley',
  approved: 'forestGreenGRG',
  download: 'lochinvar',
  pending: 'astral',
  signed: 'sycamore',
  signed_upcoming: 'sycamore',
  declined: 'matrix',
  cancelled: 'oldRose',
  exception: 'scorpion',
  expired: 'tundora',
  incoming: 'fountainBlue',
  received: 'horizon',
  sent: 'smaltBlue',
  open: 'seaGreenGRG',
  scheduled: 'dingley',
  closed: 'darkGrayGRG',
  submitted: 'oxley',
  placed: 'butterflyBash',
  partly_filled: 'gumbo',
  filled: 'cuttySarkStatus',
  reassigned: 'gurkha',
  paid: 'greenHouse',
  resolved: 'blueLagon',
  deleted: 'matrix',
};

export { statusesColors };
