import AlphaLogo from 'assets/images/alpha-logo.png';

import { ACCESS } from 'shared/helpers/constants/access/accessConstants';

import {
  StyledLogoutContentInfo,
  StyledLogoutContentInfoText,
  StyledLogoutContentLogo,
  StyledLogoutContentLogoText,
  StyledLogoutContentSpacer,
} from './StyledLogoutContent';

export const LogoutContent = () => {
  return (
    <div>
      <StyledLogoutContentLogo>
        <img src={AlphaLogo} alt="Global Refining Group" />
        <StyledLogoutContentSpacer />
        <StyledLogoutContentLogoText>{ACCESS.LOGGED_OUT}</StyledLogoutContentLogoText>
      </StyledLogoutContentLogo>
      <StyledLogoutContentInfo>
        <i className="icon warning icon-keyhole" />
        <StyledLogoutContentInfoText>{ACCESS.LOGGED_OUT_MESSAGE}</StyledLogoutContentInfoText>
      </StyledLogoutContentInfo>
    </div>
  );
};
