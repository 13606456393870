const CHANGE_PASSWORD = {
  CHANGE_YOUR_PASSWORD: 'Change your password',
  CHANGE_PASSWORD: 'Change password',
  OLD_PASSWORD: 'Old password',
  NEW_PASSWORD: 'New password',
  PASSWORD: 'Password',
  REPEAT_NEW_PASSWORD: 'Repeat new password',
};

export { CHANGE_PASSWORD };
