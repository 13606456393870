import {
  updateLocationActionType,
  updateLocationFailureActionType,
  updateLocationSuccessActionType,
} from 'actions/Locations/updateLocation';

const INITIAL_STATE = {
  isPending: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case updateLocationActionType:
      return {
        isPending: true,
      };
    case updateLocationFailureActionType:
      return {
        isPending: false,
      };
    case updateLocationSuccessActionType:
      return {
        isPending: false,
      };
    default:
      return state;
  }
};
