const MARKET_INDICATORS = {
  CURRENT_INDICATORS_PRICES: 'Current indicator prices',
  TYPE: 'Type',
  PRICES: 'Prices',
  UPDATED_BY: 'Updated by',
  LAST_UPDATED: 'Last updated',
  PLATINUM: 'Platinum',
  PALLADIUM: 'Palladium',
  RHODIUM: 'Rhodium',
  VIEW_LOG: 'View log',
  MARKET_INDICATORS_LOG: 'Market indicators log',
};

export { MARKET_INDICATORS };
