import styled from 'styled-components';

import iconTick from 'assets/icons/ico_tick.svg';

import { pxToRem } from 'shared/helpers/styling/styling';

export const StyledDueDiligence = styled.div`
  height: ${pxToRem(22)};
  min-width: ${pxToRem(22)};
  width: ${pxToRem(22)};
  border-radius: 50%;
  margin: 0 ${pxToRem(7)};
  background: url(${iconTick}) center no-repeat var(--apple);
  background-size: ${pxToRem(16)};
`;

export const StyledContainer = styled.div`
  display: flex;
  align-items: center;
`;
