const companyStatusFilterActionType = 'COMPANY_STATUS_ACTION';
function companyStatusFilterAction(companyStatus) {
  return {
    type: companyStatusFilterActionType,
    payload: {
      companyStatus,
    },
  };
}

const companyCountryFilterActionType = 'COMPANY_COUNTRY_ACTION';
function companyCountryFilterAction(country) {
  return {
    type: companyCountryFilterActionType,
    payload: {
      country,
    },
  };
}

const companyStateFilterActionType = 'COMPANY_STATE_ACTION';
function companyStateFilterAction(state) {
  return {
    type: companyStateFilterActionType,
    payload: {
      state,
    },
  };
}

const companyTypeFilterActionType = 'COMPANY_TYPE_ACTION';
function companyTypeFilterAction(companyTypes) {
  return {
    type: companyTypeFilterActionType,
    payload: {
      companyTypes,
    },
  };
}

const companyDueDiligenceFilterActionType = 'COMPANY_DUE_DILIGENCE_ACTION';
function companyDueDiligenceFilterAction(dueDiligence) {
  return {
    type: companyDueDiligenceFilterActionType,
    payload: {
      dueDiligence,
    },
  };
}

const companyFiltersClearActionType = 'COMPANY_FILTERS_CLEAR_ACTION';
function companyFiltersClearAction() {
  return {
    type: companyFiltersClearActionType,
  };
}

function companyStatusFilter(companyStatus) {
  return dispatch => {
    dispatch(companyStatusFilterAction(companyStatus));
  };
}

function companyCountryFilter(country) {
  return dispatch => {
    dispatch(companyCountryFilterAction(country));
  };
}

function companyStateFilter(state) {
  return dispatch => {
    dispatch(companyStateFilterAction(state));
  };
}

function companyTypeFilter(companyTypes) {
  return dispatch => {
    dispatch(companyTypeFilterAction(companyTypes));
  };
}

function companyDueDiligenceFilter(dueDiligence) {
  return dispatch => {
    dispatch(companyDueDiligenceFilterAction(dueDiligence));
  };
}

function companyFiltersClear() {
  return dispatch => {
    dispatch(companyFiltersClearAction());
  };
}

export {
  companyStatusFilterActionType,
  companyFiltersClearActionType,
  companyTypeFilterActionType,
  companyDueDiligenceFilterActionType,
  companyCountryFilterActionType,
  companyStateFilterActionType,
  companyStatusFilter,
  companyTypeFilter,
  companyDueDiligenceFilter,
  companyFiltersClear,
  companyCountryFilter,
  companyStateFilter,
};
