import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getMaximumSettlementOuncesActionType = 'GET_MAXIMUM_SETTLEMENT_OUNCES';
const getMaximumSettlementOuncesAction = () => ({
  type: getMaximumSettlementOuncesActionType,
});

const getMaximumSettlementOuncesSuccessActionType = `${getMaximumSettlementOuncesActionType}_SUCCESS`;
const getMaximumSettlementOuncesSuccessAction = settlementOunces => ({
  type: getMaximumSettlementOuncesSuccessActionType,
  payload: {
    settlementOunces,
  },
});

const getMaximumSettlementOuncesFailureActionType = `${getMaximumSettlementOuncesActionType}_FAILURE`;
const getMaximumSettlementOuncesFailureAction = errorMessage => ({
  type: getMaximumSettlementOuncesFailureActionType,
  payload: {
    errorMessage,
  },
});

const clearMaximumSettlementOuncesActionType = 'CLEAR_MAXIMUM_SETTLEMENT_OUNCES';
const clearMaximumSettlementOunces = () => ({
  type: clearMaximumSettlementOuncesActionType,
});

const clearMaximumSettlementOuncesActionForLotType = 'CLEAR_MAXIMUM_SETTLEMENT_OUNCES_FOR_LOT';
const clearMaximumSettlementOuncesForLot = lot => ({
  type: clearMaximumSettlementOuncesActionForLotType,
  payload: { lot },
});

const getMaximumSettlementOunces = id => dispatch => {
  dispatch(getMaximumSettlementOuncesAction());

  return axios
    .get(`${API_HOST_AUTH}/pool-accounts/maximum-settlement-ounces/${id}`)
    .then(res => {
      dispatch(getMaximumSettlementOuncesSuccessAction(res.data));

      return res.data;
    })
    .catch(err => Promise.reject(dispatch(getMaximumSettlementOuncesFailureAction(err))));
};

export {
  getMaximumSettlementOuncesActionType,
  getMaximumSettlementOuncesAction,
  getMaximumSettlementOuncesSuccessActionType,
  getMaximumSettlementOuncesSuccessAction,
  getMaximumSettlementOuncesFailureActionType,
  getMaximumSettlementOuncesFailureAction,
  getMaximumSettlementOunces,
  clearMaximumSettlementOuncesActionType,
  clearMaximumSettlementOunces,
  clearMaximumSettlementOuncesActionForLotType,
  clearMaximumSettlementOuncesForLot,
};
