import axios from 'axios';

import { showSnackbar } from 'actions/shared/snackbar';
import { dangerOptions } from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

const API_HOST_AUTH = process.env.REACT_APP_API;

export const getOpenFinalLotsActionType = 'GET_OPEN_FINAL_LOTS';
const getOpenFinalLotsAction = () => ({
  type: getOpenFinalLotsActionType,
});

export const getOpenFinalLotsSuccessActionType = `${getOpenFinalLotsActionType}_SUCCESS`;
const getOpenFinalLotsSuccessAction = data => ({
  type: getOpenFinalLotsSuccessActionType,
  payload: {
    docs: data?.docs,
    limit: data.limit,
    page: data.page,
    pages: data.pages,
  },
});

export const getOpenFinalLotsFailureActionType = `${getOpenFinalLotsActionType}_FAILURE`;
const getOpenFinalLotsFailureAction = () => ({
  type: getOpenFinalLotsFailureActionType,
});

export const clearOpenFinalLotsActionType = `CLEAR_OPEN_FINAL_LOTS`;
const clearOpenFinalLotsAction = () => ({
  type: clearOpenFinalLotsActionType,
});

export const clearOpenFinalLots = () => clearOpenFinalLotsAction();
export const getOpenFinalLots = (limit, page) => dispatch => {
  dispatch(getOpenFinalLotsAction());

  return axios
    .get(`${API_HOST_AUTH}/lots/open-finals/${limit}/${page}`)
    .then(({ data }) => dispatch(getOpenFinalLotsSuccessAction(data)))
    .catch(({ data }) => {
      dispatch(getOpenFinalLotsFailureAction());
      dispatch(showSnackbar(dangerOptions, data.message));
    });
};
