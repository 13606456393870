import { CREATE_SHIPMENT } from 'shared/helpers/constants/shipments/createShipmentConstants';
import { dateToTimestamp } from 'shared/helpers/parsers/date';
import {
  CAPITAL_ALPHANUM_REGEX,
  CAPITAL_ALPHANUM_WHITE_SPACES_REGEX,
  MOBILE_PHONE_REGEX,
} from 'shared/helpers/validations/regularExpressions';

import dateAdapter from 'utils/date/dateAdapter';

function validate(values, props) {
  const errors = {};
  const today = dateAdapter().startOf('d').valueOf();

  if (!values.sentFromCompanyLocation) {
    errors.sentFromCompanyLocation = CREATE_SHIPMENT.INVALID_SENT_FROM_COMPANY_LOCATION_EMPTY;
  }

  if (!values.estPickupDate) {
    errors.estPickupDate = CREATE_SHIPMENT.INVALID_EST_PICKUP_DATE_EMPTY;
  } else if (!props.wizard?.editMode && dateToTimestamp(values.estPickupDate) < today) {
    errors.estPickupDate = CREATE_SHIPMENT.INVALID_EST_PICKUP_DATE_PAST;
  } else if (
    values.estDeliveryDate &&
    dateToTimestamp(values.estPickupDate) > dateToTimestamp(values.estDeliveryDate)
  ) {
    errors.estPickupDate = CREATE_SHIPMENT.INVALID_EST_PICKUP_DATE_VALUE;
  }

  if (!values.estDeliveryDate) {
    errors.estDeliveryDate = CREATE_SHIPMENT.INVALID_EST_DELIVERY_DATE_EMPTY;
  } else if (!props.wizard?.editMode && dateToTimestamp(values.estDeliveryDate) < today) {
    errors.estDeliveryDate = CREATE_SHIPMENT.INVALID_EST_DELIVERY_DATE_PAST;
  } else if (
    values.estPickupDate &&
    dateToTimestamp(values.estPickupDate) > dateToTimestamp(values.estDeliveryDate)
  ) {
    errors.estDeliveryDate = CREATE_SHIPMENT.INVALID_EST_DELIVERY_DATE_VALUE;
  }

  if (!values.shipmentCompany) {
    errors.shipmentCompany = '';
  } else if (values.shipmentCompany.length > 50) {
    errors.shipmentCompany = CREATE_SHIPMENT.INVALID_SHIPMENT_COMPANY;
  }

  if (!values.contactName) {
    errors.contactName = CREATE_SHIPMENT.INVALID_CONTACT_NAME_EMPTY;
  }

  if (!values.shipmentSeal) {
    errors.shipmentSeal = '';
  } else if (values.shipmentSeal.length > 50) {
    errors.shipmentSeal = CREATE_SHIPMENT.INVALID_SHIPMENT_SEAL_LENGTH;
  }

  if (!values.trailer) {
    errors.trailer = '';
  } else if (!CAPITAL_ALPHANUM_WHITE_SPACES_REGEX.test(values.trailer)) {
    errors.trailer = CREATE_SHIPMENT.INVALID_TRAILER;
  } else if (values.trailer.length > 50) {
    errors.trailer = CREATE_SHIPMENT.INVALID_TRAILER_LENGTH;
  }

  if (!values.purchaseOrder) {
    errors.purchaseOrder = '';
  } else if (!CAPITAL_ALPHANUM_REGEX.test(values.purchaseOrder)) {
    errors.purchaseOrder = CREATE_SHIPMENT.INVALID_PURCHASE_ORDER;
  } else if (values.purchaseOrder.length > 50) {
    errors.purchaseOrder = CREATE_SHIPMENT.INVALID_PURCHASE_ORDER_LENGTH;
  }

  if (!values.billOfLading) {
    errors.billOfLading = '';
  } else if (!CAPITAL_ALPHANUM_REGEX.test(values.billOfLading)) {
    errors.billOfLading = CREATE_SHIPMENT.INVALID_BILL_OF_LADING;
  } else if (values.billOfLading.length > 50) {
    errors.billOfLading = CREATE_SHIPMENT.INVALID_BILL_OF_LADING_LENGTH;
  }

  if (!values.truckingCompany) {
    errors.truckingCompany = '';
  } else if (!CAPITAL_ALPHANUM_WHITE_SPACES_REGEX.test(values.truckingCompany)) {
    errors.truckingCompany = CREATE_SHIPMENT.INVALID_TRUCKING_COMPANY;
  } else if (values.truckingCompany.length > 50) {
    errors.truckingCompany = CREATE_SHIPMENT.INVALID_TRUCKING_COMPANY_LENGTH;
  }

  if (!values.contactNumber || !values.contactNumber.trim()) {
    errors.contactNumber = CREATE_SHIPMENT.INVALID_CONTACT_NUMBER_EMPTY;
  } else if (!MOBILE_PHONE_REGEX.test(values.contactNumber)) {
    errors.contactNumber = CREATE_SHIPMENT.INVALID_CONTACT_NUMBER;
  } else if (values.contactNumber.length > 16) {
    errors.contactNumber = CREATE_SHIPMENT.INVALID_CONTACT_NUMBER;
  }

  return errors;
}

export { validate };
