import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { deletePayment } from 'actions/Payments/deletePayment';
import {
  getAccountsReceivable,
  clearAccountsReceivable,
} from 'actions/Payments/getAccountsReceivable';
import { getPayments, clearPaymentsAction } from 'actions/Payments/paymentsList';
import { openModal } from 'actions/shared/modal';
import { showSnackbar } from 'actions/shared/snackbar';

import { PaymentsList } from './PaymentsList';

const mapStateToProps = ({ auth, paymentsList, accountsReceivable }) => ({
  auth,
  paymentsList,
  accountsReceivable,
});

const mapDispatchToProps = {
  getPayments,
  clearPaymentsAction,
  getAccountsReceivable,
  clearAccountsReceivable,
  openModal,
  showSnackbar,
  deletePayment,
};

const ConnectedPaymentsList = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PaymentsList),
);

export { ConnectedPaymentsList };
