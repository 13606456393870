import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const deleteHedgeActionType = 'DELETE_HEDGES';
function deleteHedgeAction() {
  return { type: deleteHedgeActionType };
}

const deleteHedgeSuccessActionType = `${deleteHedgeActionType}_SUCCESS`;
function deleteHedgeSuccessAction() {
  return { type: deleteHedgeSuccessActionType };
}

const deleteHedgeFailureActionType = `${deleteHedgeActionType}_FAILURE`;
function deleteHedgeFailureAction() {
  return { type: deleteHedgeFailureActionType };
}

function deleteHedge(hedge) {
  return dispatch => {
    dispatch(deleteHedgeAction());

    return axios({
      method: 'delete',
      url: `${API_HOST_AUTH}/hedges/${hedge._id}`,
    })
      .then(({ data }) => {
        dispatch(deleteHedgeSuccessAction());
        return data;
      })
      .catch(error => {
        dispatch(deleteHedgeFailureAction());

        return Promise.reject(error);
      });
  };
}

export {
  deleteHedge,
  deleteHedgeActionType,
  deleteHedgeSuccessActionType,
  deleteHedgeFailureActionType,
};
