import PropTypes from 'prop-types';
import { Component } from 'react';

import { UIAuthorization } from 'components/Authorization/UIAuthorization';

import { ClearFilters } from 'shared/components/ListFilters/ClearFilters/ClearFilters';
import { FilterCollapse } from 'shared/components/ListFilters/FilterCollapse/FilterCollapse';

import { StyledFullPageListFilters } from './StyledFullPageListFilters';
import { FiltersMenu } from './components/FiltersMenu';

class FullPageListFilters extends Component {
  state = { displayFilters: false };

  filtersToggle = () => {
    this.setState(prevState => ({ displayFilters: !prevState.displayFilters }));
  };

  clearFilters = () => {
    const { searchFilter, filtersClear } = this.props;
    filtersClear(searchFilter);
  };

  render() {
    const {
      filtersSelected,
      filterComponent,
      filterAccess,
      filters,
      searchFilter,
      excludedFilters,
    } = this.props;
    const { displayFilters } = this.state;

    return (
      <UIAuthorization access={filterAccess}>
        <StyledFullPageListFilters>
          <FilterCollapse
            excludedFilters={excludedFilters}
            collapse={displayFilters}
            toggleFilters={this.filtersToggle}
            filters={filters}
          />
        </StyledFullPageListFilters>
        <FiltersMenu isOpen={displayFilters}>
          {displayFilters && filterComponent(searchFilter)}
          {!!filtersSelected && <ClearFilters clearFilters={this.clearFilters} />}
        </FiltersMenu>
      </UIAuthorization>
    );
  }
}

FullPageListFilters.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  searchFilter: PropTypes.string,
  pagination: PropTypes.object,
  items: PropTypes.array,
  isPending: PropTypes.bool,
  filtersSelected: PropTypes.bool,
  filterAccess: PropTypes.bool,
  enableSearch: PropTypes.bool,
  filtersClear: PropTypes.func,
  fetchList: PropTypes.func,
  dispatch: PropTypes.func,
  filterComponent: PropTypes.func,
  actionsTemplate: PropTypes.array,
  filters: PropTypes.shape({
    fundsType: PropTypes.arrayOf(PropTypes.string),
    isPaid: PropTypes.bool,
    paidAtEnd: PropTypes.number,
    paidAtStart: PropTypes.number,
    paymentType: PropTypes.arrayOf(PropTypes.string),
    query: PropTypes.string,
    status: PropTypes.arrayOf(PropTypes.string),
  }),
  excludedFilters: PropTypes.arrayOf(PropTypes.string),
  itemsTemplate: PropTypes.array,
  itemsTemplateMobile: PropTypes.array,
  itemDetailsTemplate: PropTypes.array,
};

export { FullPageListFilters };
