import React from 'react';

import { shipmentDirections } from 'shared/helpers/constants/shipments/shipmentDirections';

import { ShipmentsList } from '../ShipmentsList';

function IncomingShipmentsList(props) {
  return (
    <ShipmentsList
      getShipmentsList={props.getShipmentsList}
      shipmentDirection={shipmentDirections.incoming}
      {...props}
    />
  );
}

export { IncomingShipmentsList };
