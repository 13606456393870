import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const updateDashboardPreferencesActionType = 'UPDATE_DASHBOARD_PREFERENCES';
const updateDashboardPreferencesAction = () => ({
  type: updateDashboardPreferencesActionType,
});

const updateDashboardPreferencesSuccessActionType = `${updateDashboardPreferencesActionType}_SUCCESS`;
const updateDashboardPreferencesSuccessAction = dashboardPreferences => ({
  type: updateDashboardPreferencesSuccessActionType,
  payload: {
    dashboardPreferences,
  },
});

const updateDashboardPreferencesFailureActionType = `${updateDashboardPreferencesActionType}_FAILURE`;
const updateDashboardPreferencesFailureAction = () => ({
  type: updateDashboardPreferencesFailureActionType,
});

const updateDashboardPreferences = (id, preferences) => dispatch => {
  dispatch(updateDashboardPreferencesAction());

  return axios
    .put(`${API_HOST_AUTH}/users/${id}/dashboard-preferences`, JSON.stringify(preferences))
    .then(res => {
      dispatch(updateDashboardPreferencesSuccessAction(preferences));

      return {
        message: res.data.message,
      };
    })
    .catch(error => {
      dispatch(updateDashboardPreferencesFailureAction());

      return Promise.reject({
        message: error.data.message,
      });
    });
};

export {
  updateDashboardPreferences,
  updateDashboardPreferencesActionType,
  updateDashboardPreferencesSuccessActionType,
  updateDashboardPreferencesFailureActionType,
};
