import { dateToTimestamp } from 'shared/helpers/parsers/date';

function parseAgreementData(agreemnent) {
  return {
    ...agreemnent,
    startDate: dateToTimestamp(agreemnent.startDate),
  };
}

export { parseAgreementData };
