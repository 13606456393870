import { FILES_CONSTANTS } from 'shared/helpers/constants/filesConstants';
import { messages } from 'shared/helpers/constants/terms/uploadSignedDocument';

function validate(values, props) {
  const { files } = props.termsUploadDocument;
  const errors = {};

  if (!files) {
    errors.signedDocument = messages.INVALID_FILE;
  }

  if (files && files.length !== 1) {
    errors.signedDocument = messages.INVALID_FILE_NUMBER;
  }

  if (files && files.length > 0 && files[0].size > FILES_CONSTANTS.MAX_FILE_SIZE) {
    errors.signedDocument = messages.INVALID_FILE_SIZE;
  }

  if (files && files.length > 0 && !/\.pdf$/i.test(files[0].name)) {
    errors.signedDocument = messages.INVALID_FILE_TYPE;
  }

  return errors;
}

export { validate };
