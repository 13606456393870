import { dateToTimestamp } from 'shared/helpers/parsers/date';
import { safeReplace, removeCommasAndParse } from 'shared/helpers/parsers/text';

function parseTermData(term) {
  return {
    ...term,

    treatmentChargePerPound: removeCommasAndParse(term.treatmentChargePerPound, ',', '.'),
    smallLotCharge: removeCommasAndParse(term.smallLotCharge, ',', '.'),
    largeLotCharge: removeCommasAndParse(term.largeLotCharge, ',', '.'),
    platinumReturnRate: safeReplace(term.platinumReturnRate, ',', '.'),
    platinumReturnTermInDays: safeReplace(term.platinumReturnTermInDays, ',', '.'),
    platinumReturnFinanceRate: safeReplace(term.platinumReturnFinanceRate, ',', '.'),
    platinumRefiningCharge: removeCommasAndParse(term.platinumRefiningCharge, ',', '.'),
    palladiumReturnRate: safeReplace(term.palladiumReturnRate, ',', '.'),
    palladiumReturnTermInDays: safeReplace(term.palladiumReturnTermInDays, ',', '.'),
    palladiumReturnFinanceRate: safeReplace(term.palladiumReturnFinanceRate, ',', '.'),
    palladiumRefiningCharge: removeCommasAndParse(term.palladiumRefiningCharge, ',', '.'),
    rhodiumReturnRate: safeReplace(term.rhodiumReturnRate, ',', '.'),
    rhodiumReturnTermInDays: safeReplace(term.rhodiumReturnTermInDays, ',', '.'),
    rhodiumReturnFinanceRate: safeReplace(term.rhodiumReturnFinanceRate, ',', '.'),
    rhodiumRefiningCharge: removeCommasAndParse(term.rhodiumRefiningCharge, ',', '.'),
    metalsReturnTermInDays: safeReplace(term.metalsReturnTermInDays, ',', '.'),
    metalsReturnFinanceRate: safeReplace(term.metalsReturnFinanceRate, ',', '.'),
    advanceRate: safeReplace(term.advanceRate, ',', '.'),

    startDate: dateToTimestamp(term.startDate),
    endDate: dateToTimestamp(term.endDate),
  };
}

export { parseTermData };
