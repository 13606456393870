import {
  deletePaymentActionType,
  deletePaymentSuccessActionType,
  deletePaymentFailureActionType,
} from 'actions/Payments/deletePayment';

const INITIAL_STATE = {
  isPending: false,
};

const DeletePaymentReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case deletePaymentActionType:
      return {
        isPending: true,
      };
    case deletePaymentSuccessActionType:
    case deletePaymentFailureActionType:
      return {
        isPending: false,
      };
    default:
      return state;
  }
};

export { DeletePaymentReducer };
