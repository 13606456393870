import {
  getUsersActionType,
  getUsersSuccessActionType,
  getUsersFailureActionType,
  clearUsersListActionType,
  getUsersForSettleOuncesSuccessActionType,
} from 'actions/Users/usersList';

const INITIAL_STATE = {
  isPending: false,
  errorMessage: null,
  users: [],
  limit: 50,
  page: 1,
  pages: null,
  items: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getUsersActionType:
      return {
        ...state,
        isPending: true,
      };
    case getUsersSuccessActionType:
      return {
        ...state,
        isPending: false,
        users: action.payload.users,
        pages: action.payload.pages,
        limit: action.payload.limit,
        page: action.payload.page,
        items: {
          docs: action.payload.users,
        },
      };
    case getUsersForSettleOuncesSuccessActionType:
      return {
        isPending: false,
        users: {
          ...state.users,
          ...action.payload,
        },
      };
    case getUsersFailureActionType:
      return {
        ...state,
        isPending: false,
        errorMessage: action.payload.errorMessage,
        users: [],
        pages: null,
      };
    case clearUsersListActionType:
      return {
        isPending: false,
        errorMessage: null,
        users: [],
        limit: 50,
        page: 1,
        pages: null,
        items: [],
      };
    default:
      return state;
  }
};
