import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import { clearLotDetails, getLot } from 'actions/Lots/lotDetails';
import { showSnackbar } from 'actions/shared/snackbar';

import { ContentLoader } from 'shared/components/ContentLoader/ContentLoader';
import { Preloader } from 'shared/components/Preloader/Preloader';
import { missingBillofLadingWarningAccess } from 'shared/helpers/accesses/lots/missingBillofLadingWarningAccess';
import { FILES_CONSTANTS } from 'shared/helpers/constants/filesConstants';
import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';
import { dangerOptions } from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { isBuyerUser, isCustomerUser, isGradingUser } from 'shared/helpers/matchers/checkUserType';
import { isGlobalUserType } from 'shared/helpers/matchers/isGlobalUserType';

import { extractErrorMessage } from 'utils/extractErrorMessage';
import { history } from 'utils/history';

import { UIAuthorization } from '../../../../components/Authorization/UIAuthorization';
import { InspectCfu } from '../InspectCfu/InspectCfu';
import { LotDetailsBuyer } from 'pages/Lots/components/LotDetails/components/LotDetailsBuyer/LotDetailsBuyer';

import { LotDetailsCustomer } from './components/LotDetailsCustomer/LotDetailsCustomer';
import { LotDetailsGlobal } from './components/LotDetailsGlobal/LotDetailsGlobal';
import { LotDetailsGrading } from './components/LotDetailsGrading/LotDetailsGrading';

export const LotDetails = () => {
  const user = useSelector(state => state.auth.user);
  const auth = useSelector(state => state.auth);
  const { lot, isPending } = useSelector(state => state.lotDetails);
  const { isPending: filesPending } = useSelector(state => state.manageGlobalFiles);
  const downloadAttachmentStorage = useSelector(state => state.downloadAttachmentStorage);
  const packagesOverviewList = useSelector(state => state.packagesOverviewList);
  const hedgesOverviewList = useSelector(state => state.hedgesOverviewList);
  const { pathname: path } = useLocation();

  const { id } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getLot(id))
      .then(lot => {
        if (missingBillofLadingWarningAccess(lot, user)) {
          dispatch(showSnackbar(dangerOptions, LOTS.MISSING_BILL_OF_LADING));
        }
      })
      .catch(error => {
        showSnackbar(dangerOptions, extractErrorMessage(error));
        history.push('/lots');
      });
  }, []);

  useEffect(() => {
    return () => {
      dispatch(clearLotDetails());
    };
  }, []);

  return (
    <ContentLoader loaded={lot && !isPending && !filesPending}>
      <>
        <UIAuthorization access={path.endsWith('-cfu')}>
          <InspectCfu isInspect={path.endsWith('inspect-cfu')} />
        </UIAuthorization>
        {isCustomerUser(user.userType) && (
          <LotDetailsCustomer
            auth={auth}
            packagesOverviewList={packagesOverviewList}
            lot={lot}
            hedgesOverviewList={hedgesOverviewList}
          />
        )}
        {isGradingUser(user.userType) && (
          <LotDetailsGrading auth={auth} lot={lot} packagesOverviewList={packagesOverviewList} />
        )}
        {isBuyerUser(user.userType) && (
          <LotDetailsBuyer
            auth={auth}
            lot={lot}
            packagesOverviewList={packagesOverviewList}
            hedgesOverviewList={hedgesOverviewList}
          />
        )}
        {isGlobalUserType(user.userType) && (
          <LotDetailsGlobal
            auth={auth}
            packagesOverviewList={packagesOverviewList}
            lot={lot}
            hedgesOverviewList={hedgesOverviewList}
          />
        )}
      </>
      <Preloader
        loading={downloadAttachmentStorage?.isPending}
        label={FILES_CONSTANTS.DOWNLOADING_FILES}
      />
    </ContentLoader>
  );
};
