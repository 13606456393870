import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getNotification } from 'actions/Notifications/notificationDetails';
import { login } from 'actions/shared/auth';
import { clearSavedUrlAction } from 'actions/shared/redirect';

import { LoginForm } from './components/LoginForm/LoginForm';

const LoginPage = () => {
  const [isCaptchaFilled, setIsCaptchaFilled] = useState(false);
  const [gRecaptchaResponse, setGRecaptchaResponse] = useState(false);

  const dispatch = useDispatch();
  const redirect = useSelector(state => state.redirect);

  const history = useHistory();
  const onExpiredRecaptcha = () => {
    setIsCaptchaFilled(false);
  };

  const recaptchaCallback = value => {
    setIsCaptchaFilled(true);
    setGRecaptchaResponse(value);
  };

  const submit = values => {
    setIsCaptchaFilled(false);

    return dispatch(login(values, gRecaptchaResponse)).then(response => {
      const { url, userId, notification } = redirect;
      const { id } = response.data.payload.details;

      notification && dispatch(getNotification(notification));
      history.push(url && (!userId || userId === id) ? url : '/dashboard');

      dispatch(clearSavedUrlAction());
    });
  };

  return (
    <LoginForm
      filledCaptcha={isCaptchaFilled}
      verifyRecaptchaCallback={recaptchaCallback}
      onExpiredRecaptcha={onExpiredRecaptcha}
      onSubmit={submit}
    />
  );
};

export { LoginPage };
