import React, { Component } from 'react';

import { ContentLoader } from 'shared/components/ContentLoader/ContentLoader';
import { renderDetailsContainer } from 'shared/components/Details/helpers/renderDetailsContainer';
import { Preloader } from 'shared/components/Preloader/Preloader';
import { FILES_CONSTANTS } from 'shared/helpers/constants/filesConstants';
import { dangerOptions } from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

import { extractErrorMessage } from 'utils/extractErrorMessage';

import { TermsMainDetails } from './components/MainDetails/MainDetails';
import {
  termsDetailsContainerConfig,
  termsDetailsFieldsConfig,
  activityContainerConfig,
  activityFieldsConfig,
} from './config/fieldsConfig';

class TermsDetails extends Component {
  componentDidMount() {
    this.props.getTerms(this.props.match.params.id).catch(error => {
      this.props.showSnackbar(dangerOptions, extractErrorMessage(error));
      return this.props.history.push(`/companies/${this.props.match.params.companyId}`);
    });
  }

  componentWillUnmount() {
    this.props.clearTermsDetails();
  }

  render() {
    const { terms, isPending } = this.props.termsDetails;
    const { companyId } = this.props.match.params;
    const { auth, downloadApiAttachment, downloadAttachmentStorage } = this.props;

    return (
      <ContentLoader loaded={terms && !isPending}>
        {terms && (
          <React.Fragment>
            <TermsMainDetails terms={terms} auth={auth} companyId={companyId} />
            {renderDetailsContainer(
              termsDetailsContainerConfig,
              termsDetailsFieldsConfig,
              terms,
              downloadApiAttachment,
            )}
            {renderDetailsContainer(activityContainerConfig, activityFieldsConfig, terms)}
          </React.Fragment>
        )}
        <Preloader
          loading={downloadAttachmentStorage.isPending}
          label={FILES_CONSTANTS.DOWNLOADING_FILES}
        />
      </ContentLoader>
    );
  }
}

export { TermsDetails };
