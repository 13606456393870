import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getCompaniesBySearchStr } from 'actions/Companies/filterCompanies';
import { getCompanyLocations } from 'actions/Locations/getCompanyLocations';
import { createLot } from 'actions/Lots/createLot';
import { lotsAddFile, lotsRemoveFile, lotsClearAllFiles } from 'actions/Lots/lotsAddFiles';
import { openModal, closeModal } from 'actions/shared/modal';
import { showSnackbar } from 'actions/shared/snackbar';

import { CreateLot } from './CreateLot';

function ConnectedCreateLot(props) {
  return <CreateLot {...props} />;
}

const mapStateToProps = state => ({
  auth: state.auth,
  router: state.router,
  modal: state.modal,
  lotsFiles: state.lotsFiles,
  lotCreate: state.lotCreate,
  companyLocations: state.companyLocations,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createLot,
      lotsAddFile,
      lotsRemoveFile,
      showSnackbar,
      lotsClearAllFiles,
      getCompanyLocations,
      getCompaniesBySearchStr,
      openModal,
      closeModal,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ConnectedCreateLot);
