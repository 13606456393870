import React, { useCallback } from 'react';

import { validateDate } from 'utils/date/dateAdapter';

import { FieldDate } from '../Fields/FieldDate/FieldDate';

const DatepickerDropdown = ({ selected, filterOptionsSelected, filterFunction, ...props }) => {
  const input = validateDate(selected) ? { value: selected } : {};

  const handleChange = useCallback(
    date => {
      filterOptionsSelected && filterOptionsSelected();
      filterFunction(date && date.valueOf());
    },
    [filterFunction, filterOptionsSelected],
  );

  return <FieldDate {...props} input={input} onDateChange={handleChange} />;
};

export { DatepickerDropdown };
