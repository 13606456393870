import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';

import { createDeliveries } from 'actions/Deliveries/createDeliveries';
import { getAvailableHedges } from 'actions/Deliveries/getAvailableHedges';
import { getAvailableLots } from 'actions/Deliveries/getAvailableLots';
import { clearAvailableOunces } from 'actions/Deliveries/getLotAvailableOunces';
import { showSnackbar } from 'actions/shared/snackbar';

import { FormContainer } from 'shared/components/Form/FormContainer/FormContainer';
import { DELIVERIES } from 'shared/helpers/constants/deliveries/deliveriesConstants';
import {
  successOptions,
  dangerOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { safeParseFloat } from 'shared/helpers/parsers/number';

import { DeliveriesForm } from './components/DeliveriesForm/DeliveriesForm';

class CreateDeliveriesBase extends Component {
  componentDidMount() {
    Promise.all([this.props.getAvailableHedges(), this.props.getAvailableLots()]).catch(() =>
      this.props.history.push('/hedges'),
    );
  }

  componentWillUnmount() {
    this.props.clearAvailableOunces();
  }

  submit = ({ deliveries }) => {
    const { history, location } = this.props;
    const deliveriesToSend = deliveries.map(delivery => ({
      relatedHedge: delivery.relatedHedge && delivery.relatedHedge.value,
      relatedLot: delivery.relatedLot && delivery.relatedLot.value,
      platinumOz: safeParseFloat(delivery.platinumOz),
      palladiumOz: safeParseFloat(delivery.palladiumOz),
      rhodiumOz: safeParseFloat(delivery.rhodiumOz),
    }));

    return this.props
      .createDeliveriesAction(deliveriesToSend)
      .then(data => {
        this.props.showSnackbar(successOptions, data.message);

        if (location.state && location.state.relatedLot) {
          return history.push('/hedges/hedge-ounces', {
            shipmentId: location.state.relatedLot.shipmentId,
          });
        }
        history.push('/hedges');
      })
      .catch(error => this.props.showSnackbar(dangerOptions, error.message));
  };

  render() {
    const { availableHedges, availableLots, createDeliveries } = this.props;

    return (
      <FormContainer
        header={DELIVERIES.ASSIGN_HEDGE_OUNCES}
        loaded={
          !(availableHedges.isPending || availableLots.isPending || createDeliveries.isPending) &&
          !!availableHedges.hedges &&
          !!availableLots.lots
        }
      >
        <DeliveriesForm onSubmit={this.submit} />
      </FormContainer>
    );
  }
}

const mapStateToProps = ({ availableHedges, availableLots, createDeliveries }) => ({
  availableHedges,
  availableLots,
  createDeliveries,
});

const mapDispatchToProps = {
  getAvailableHedges,
  getAvailableLots,
  createDeliveriesAction: createDeliveries,
  showSnackbar,
  clearAvailableOunces,
};

const CreateDeliveries = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
)(CreateDeliveriesBase);

export { CreateDeliveriesBase, CreateDeliveries };
