import React, { useState, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { hidePDFPreview } from 'actions/shared/PDFPreview';

import { Preloader } from 'shared/components/Preloader/Preloader';

import { getBrowserWidth } from 'utils/responsive';

pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/static/pdf.worker.js`;

const StyledContainer = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  overflow: auto;
  overflow-x: hidden;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1002;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledPageControls = styled.div`
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  background: #ffffff;
  transition: opacity ease-in-out 0.2s;
  box-shadow: 0 30px 40px 0 rgba(16, 36, 94, 0.2);
  border-radius: 4px;
  opacity: 0;
`;

const StyledDocument = styled(Document)`
  position: relative;

  &:hover {
    ${StyledPageControls} {
      opacity: 1;
    }
  }
`;
const StyledPage = styled(Page)`
  padding: 5px 5px 0 5px;
  background-color: #ffffff;
  border-radius: 10px;
`;

const StyledButtons = styled.div`
  display: flex;
  align-items: center;
`;

const StyledButton = styled.button`
  width: 44px;
  height: 44px;
  background-color: #ffffff;
  border: 0;
  font: inherit;
  font-size: 0.8em;
  border-radius: 4px;

  &:hover:not(:disabled) {
    background-color: #e0dfdf;
  }
`;

const StyledCurrentPage = styled.span`
  padding: 0 5px;
`;

const StyledExit = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  z-index: 2001;
`;

const StyledButtonExit = styled.button`
  padding: 10px 25px;
  background-color: transparent;
  border: none;
  color: #ffffff;
  font-size: 40px;
  font-weight: bold;
`;

const PDFPreview = () => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [documentWidth, setDocumentWidth] = useState(null);
  const isOpen = useSelector(({ PDFPreview }) => PDFPreview.isOpen);
  const isPending = useSelector(({ PDFPreview }) => PDFPreview.isPending);
  const filePath = useSelector(({ PDFPreview }) => PDFPreview.filePath);
  const dispatch = useDispatch();

  const changeDocumentWidth = () => {
    if (getBrowserWidth() <= 700 && getBrowserWidth() > 500) {
      setDocumentWidth(450);
    } else if (getBrowserWidth() <= 500) {
      setDocumentWidth(320);
    } else {
      setDocumentWidth(null);
    }
  };

  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'auto';
    changeDocumentWidth();

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPageNumber(1);
  };

  const changePage = offset => {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  };

  const previousPage = () => {
    changePage(-1);
  };

  const nextPage = () => {
    changePage(1);
  };

  const handleExit = () => {
    dispatch(hidePDFPreview());
  };

  return isPending ? (
    <StyledContainer>
      <StyledExit>
        <StyledButtonExit onClick={handleExit}>×</StyledButtonExit>
      </StyledExit>
      <Preloader loading={isPending} label="Opening a PDF document..." />
    </StyledContainer>
  ) : (
    isOpen && (
      <StyledContainer>
        <StyledExit>
          <StyledButtonExit onClick={handleExit}>×</StyledButtonExit>
        </StyledExit>
        <StyledDocument file={filePath} onLoadSuccess={onDocumentLoadSuccess}>
          <StyledPage
            height={820}
            width={documentWidth}
            loading={<Preloader loading label="Opening a page..." />}
            pageNumber={pageNumber}
          />
          <StyledPageControls>
            <StyledButtons>
              <StyledButton type="button" disabled={pageNumber <= 1} onClick={previousPage}>
                ‹
              </StyledButton>
              <StyledCurrentPage>
                {pageNumber} of {numPages}
              </StyledCurrentPage>
              <StyledButton
                type="button"
                disabled={pageNumber >= numPages}
                onClick={nextPage}
                right
              >
                ›
              </StyledButton>
            </StyledButtons>
          </StyledPageControls>
        </StyledDocument>
      </StyledContainer>
    )
  );
};

export { PDFPreview };
