import React from 'react';

import { StatusIndicator } from 'shared/components/StatusIndicator/StatusIndicator';
import { TermsAbbrDetails } from 'shared/components/TermsAbbrDetails/TermsAbbrDetails';
import { formatDate } from 'shared/helpers/parsers/formaters';
import { humanize } from 'shared/helpers/parsers/text';

import { TERMS } from './termsConstants';

const itemsTemplate = [
  {
    label: TERMS.STATUS,
    render: ({ status }) => <StatusIndicator status={status} />,
  },
  {
    label: TERMS.MATERIAL_TYPE,
    render: ({ materialType }) => humanize(materialType),
  },
  {
    label: TERMS.START_DATE,
    render: ({ startDate }) => formatDate(startDate),
  },
  {
    label: TERMS.END_DATE,
    render: ({ endDate }) => formatDate(endDate),
  },
  {
    label: TERMS.DETAILS,
    render: term => <TermsAbbrDetails term={term} />,
  },
];

const itemsTemplateMobile = [
  {
    label: TERMS.STATUS,
    render: ({ status }) => <StatusIndicator status={status} />,
  },
  {
    label: TERMS.MATERIAL_TYPE,
    render: ({ materialType }) => humanize(materialType),
  },
];

const actionsTemplate = (history, company) => {
  const actionTemplate = [
    {
      icon: 'arrow-right',
      action: 'Details',
      access: term => term,
      onClick: term => () => {
        history.push(`/companies/${company._id}/terms/${term._id}`);
      },
    },
  ];

  actionsTemplate.access = function access() {
    return (
      this.props.auth.access.companies.details.terms.update || this.props.auth.access.terms.details
    );
  };

  return actionTemplate;
};

const actionsTemplateCustomer = downloadApiAttachment => {
  const actionTemplateCustomer = [
    {
      icon: 'clip',
      action: 'Download',
      access: term => !!(term.termsDocument || term.docuSignDocument),
      onClick: term => () => downloadApiAttachment(`terms/${term._id}/document`),
    },
  ];

  return actionTemplateCustomer;
};

const [, , ...itemsDetailsTemplate] = itemsTemplate;

export {
  itemsTemplate,
  itemsTemplateMobile,
  itemsDetailsTemplate,
  actionsTemplate,
  actionsTemplateCustomer,
};
