import {
  getLotsToTransferActionType,
  getLotsToTransferSuccessActionType,
  getLotsToTransferFailureActionType,
} from 'actions/Deliveries/getLotsToTransfer';

const INITIAL_STATE = {
  isPending: false,
  lots: [],
};

const getLotsToTransferReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getLotsToTransferActionType:
      return {
        ...state,
        isPending: true,
      };
    case getLotsToTransferFailureActionType:
      return INITIAL_STATE;
    case getLotsToTransferSuccessActionType:
      return {
        ...state,
        isPending: false,
        lots: action.payload.lots,
      };
    default:
      return state;
  }
};

export { getLotsToTransferReducer };
