import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

import { Icon } from 'shared/components/Icon/Icon';
import { cssVar } from 'shared/helpers/styling/styling';

import { withFieldWrapper } from '../FieldWrapper/FieldWrapper';

const CheckboxWrapper = styled.div`
  display: flex;
`;

const CheckboxControl = styled.input`
  cursor: pointer;
  display: none;
  visibility: hidden;
`;

const CheckboxFakeLabel = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 33px;
  height: 33px;
  background-color: transparent;
  border-radius: 5px;
  transition: all 0.1s ease-in-out;
  margin: 0;
  border: 2px solid
    ${({ meta, hasError }) =>
      cssVar((meta && meta.touched && meta.error) || hasError ? 'copperRust' : 'nevada')};

  && ${Icon} {
    visibility: hidden;
    position: relative;
    mask-position: calc(50% - 1px) 50%;
  }

  ${CheckboxControl}:disabled ~ & {
    opacity: 0.6;
  }

  ${CheckboxControl}:checked ~ & {
    background-color: ${cssVar('nevada')};
    ${Icon} {
      visibility: visible;
    }
  }

  ${({ meta, hasError }) =>
    !((meta && meta.touched && meta.error) || hasError) &&
    css`
      ${CheckboxControl}:hover:not(:disabled) ~ &,
      ${CheckboxControl}:focus:not(:disabled) ~ & {
        border-color: ${cssVar('slateGrayDarker')};
      }
    `}
`;

const CheckboxLabel = styled.span`
  display: flex;
  align-items: center;
  font-size: 16px;
  margin-left: 12px;
`;

const Checkbox = ({ label, labelColor, className, dataTestId, ...props }) => {
  return (
    <CheckboxWrapper className={className}>
      <CheckboxControl
        {...props}
        {...props.input}
        type="checkbox"
        data-testid={`${dataTestId}-input`}
      />
      <CheckboxFakeLabel {...props} htmlFor={props.id} data-testid={dataTestId}>
        <Icon icon="icon-tick" width={33} height={33} />
      </CheckboxFakeLabel>
      {label && <CheckboxLabel>{label}</CheckboxLabel>}
    </CheckboxWrapper>
  );
};

const FieldCheckbox = withFieldWrapper(({ label, ...props }) => Checkbox(props));

FieldCheckbox.propTypes = {
  height: PropTypes.number,
  padding: PropTypes.number,
};

FieldCheckbox.defaultProps = {
  height: 50,
  padding: 0,
};

export { Checkbox, CheckboxLabel, CheckboxWrapper, FieldCheckbox };
