import { DetailsActivityField } from 'shared/components/Details/DetailsActivityField';
import { DetailsDateField } from 'shared/components/Details/DetailsDateField';
import { DetailsDownloadField } from 'shared/components/Details/DetailsDownloadFieldRedesign';
import { DetailsField } from 'shared/components/Details/DetailsField';
import { DetailsRadioField } from 'shared/components/Details/DetailsRadioFieldRedesign';
import { termTypes } from 'shared/helpers/constants/filters/termTypes';
import { TERMS } from 'shared/helpers/constants/terms/termsConstants';
import { formatToDollar } from 'shared/helpers/parsers/formaters';

const getTreatmentChargeLabel = materialType => {
  switch (materialType) {
    case termTypes.ceramic:
      return TERMS.TREATMENT_CHARGE_CERAMIC;
    case termTypes.foil:
      return TERMS.TREATMENT_CHARGE_FOIL;
    default:
      return TERMS.TREATMENT_CHARGE_O2SENSOR;
  }
};

const termsDetailsFieldsConfig = (values, downloadApiAttachment) => [
  {
    label: TERMS.RELATED_COMPANY,
    value: values.relatedCompany.companyName,
    icon: 'icon-home',
    id: 'relatedCompany',
    FieldType: DetailsField,
  },
  {
    label: TERMS.START_DATE,
    value: values.startDate,
    id: 'startDate',
    FieldType: DetailsDateField,
  },
  {
    label: TERMS.END_DATE,
    value: values.endDate,
    id: 'endDate',
    FieldType: DetailsDateField,
  },
  {
    label: TERMS.ACTUAL_EXPIRATION_DATE,
    value: values.actualExpirationDate,
    id: 'actualExpirationDate',
    FieldType: DetailsDateField,
  },
  {
    label: TERMS.DIGITAL_SIGNATURE_REQUIRED,
    value: values.digitalSignatureRequired,
    icon: 'icon-document',
    id: 'digitalSignatureRequired',
    FieldType: DetailsRadioField,
  },
  {
    label: getTreatmentChargeLabel(values.materialType),
    value: formatToDollar(values.treatmentChargePerPound),
    icon: 'icon-dollar',
    prefix: '$',
    id: 'treatmentChargePerPound',
    FieldType: DetailsField,
  },
  {
    label: TERMS.SMALL_LOT_CHARGE,
    value: formatToDollar(values.smallLotCharge),
    icon: 'icon-dollar',
    prefix: '$',
    id: 'smallLotCharge',
    FieldType: DetailsField,
  },
  {
    label: TERMS.LARGE_LOT_CHARGE,
    value: formatToDollar(values.largeLotCharge),
    icon: 'icon-dollar',
    prefix: '$',
    id: 'largeLotCharge',
    FieldType: DetailsField,
  },
  {
    label: TERMS.ADVANCE_RATE,
    value: values.advanceRate,
    icon: 'icon-rate',
    suffix: '%',
    id: 'advanceRate',
    FieldType: DetailsField,
  },
  {
    label: TERMS.TERMS_DOCUMENT,
    value: values.docuSignDocument || values.termsDocument,
    id: 'termsDocument',
    onClick: () => downloadApiAttachment(`terms/${values._id}/document`),
    FieldType: DetailsDownloadField,
    forwardable: true,
    previewIsAvailable: false,
  },
  {
    label: TERMS.METALS_RETURN_TERMIN_DAYS,
    value: values.metalsReturnTermInDays,
    icon: 'icon-calendar',
    id: 'metalsReturnTermInDays',
    FieldType: DetailsField,
  },
  {
    label: TERMS.METALS_RETURN_FINANCE_RATE,
    value: values.metalsReturnFinanceRate,
    icon: 'icon-rate',
    suffix: '%',
    id: 'metalsReturnFinanceRate',
    FieldType: DetailsField,
  },
  {
    label: TERMS.PLATINUM_RETURN_RATE,
    value: values.platinumReturnRate,
    icon: 'icon-rate',
    suffix: '%',
    id: 'platinumReturnDate',
    FieldType: DetailsField,
  },
  {
    label: TERMS.PALLADIUM_RETURN_RATE,
    value: values.palladiumReturnRate,
    icon: 'icon-rate',
    suffix: '%',
    id: 'palladiumReturnRate',
    FieldType: DetailsField,
  },
  {
    label: TERMS.RHODIUM_RETURN_RATE,
    value: values.rhodiumReturnRate,
    icon: 'icon-rate',
    suffix: '%',
    id: 'rhodiumReturnRate',
    FieldType: DetailsField,
  },
  {
    label: TERMS.PLATINUM_REFINING_CHARGE,
    value: formatToDollar(values.platinumRefiningCharge),
    icon: 'icon-dollar',
    prefix: '$',
    id: 'platinumRefiningCharge',
    FieldType: DetailsField,
  },
  {
    label: TERMS.PALLADIUM_REFINING_CHARGE,
    value: formatToDollar(values.palladiumRefiningCharge),
    icon: 'icon-dollar',
    prefix: '$',
    id: 'palladiumRefiningCharge',
    FieldType: DetailsField,
  },
  {
    label: TERMS.RHODIUM_REFINING_CHARGE,
    value: formatToDollar(values.rhodiumRefiningCharge),
    icon: 'icon-dollar',
    prefix: '$',
    id: 'rhodiumRefiningCharge',
    FieldType: DetailsField,
  },
];

const activityFieldsConfig = values => [
  {
    label: TERMS.GLOBAL_SIGNED_ON,
    value: values.globalSignedAt,
    id: 'globalSignedAt',
    FieldType: DetailsDateField,
  },
  {
    label: TERMS.CREATED_BY,
    user: values.createdBy,
    date: values.createdAt,
    id: 'createdBy',
    FieldType: DetailsActivityField,
  },
  {
    label: TERMS.UPDATED_BY,
    user: values.updatedBy,
    date: values.updatedAt,
    id: 'updatedBy',
    FieldType: DetailsActivityField,
  },
  {
    label: TERMS.SUBMITTED_BY,
    user: values.submittedBy,
    date: values.submittedAt,
    id: 'submittedBy',
    FieldType: DetailsActivityField,
  },
  {
    label: TERMS.GLOBAL_DECISION_BY,
    user: values.globalDecisionBy,
    date: values.globalDecisionAt,
    id: 'globalDecisionBy',
    FieldType: DetailsActivityField,
  },
  {
    label: TERMS.CUSTOMER_DECISION_BY,
    date: values.customerDecisionAt,
    user: values.customerDecisionBy,
    id: 'customerDecisionBy',
    FieldType: DetailsActivityField,
  },
];

const termsDetailsContainerConfig = ({ digitalSignatureRequired }) => ({
  header: TERMS.GENERAL_DETAILS,
  template: [
    'relatedCompany relatedCompany startDate endDate',
    'actualExpirationDate digitalSignatureRequired treatmentChargePerPound treatmentChargePerPound',
    'smallLotCharge largeLotCharge advanceRate termsDocument',
    'metalsReturnTermInDays metalsReturnTermInDays metalsReturnFinanceRate metalsReturnFinanceRate',
    'platinumReturnDate platinumReturnDate platinumRefiningCharge platinumRefiningCharge',
    'palladiumReturnRate palladiumReturnRate palladiumRefiningCharge palladiumRefiningCharge',
    'rhodiumReturnRate rhodiumReturnRate rhodiumRefiningCharge rhodiumRefiningCharge',
  ],
});

const activityContainerConfig = () => ({
  header: TERMS.ACTIVITY,
  template: [
    'createdBy createdBy updatedBy updatedBy',
    'globalSignedAt globalSignedAt submittedBy submittedBy',
    'globalDecisionBy globalDecisionBy customerDecisionBy customerDecisionBy',
  ],
});

export {
  termsDetailsFieldsConfig,
  activityFieldsConfig,
  termsDetailsContainerConfig,
  activityContainerConfig,
};
