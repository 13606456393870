import axios from 'axios';

import { showSnackbar } from 'actions/shared/snackbar';

import { dangerOptions } from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { downloadFile } from 'shared/helpers/dataParsers/files/downloadFile';

const API_HOST_AUTH = process.env.REACT_APP_API;

const generateReportActionType = 'GENERATE_REPORT';
const generateReportAction = () => ({
  type: generateReportActionType,
});

const generateReportSuccessActionType = `${generateReportActionType}_SUCCESS`;
const generateReportSuccessAction = () => ({
  type: generateReportSuccessActionType,
});

const generateReportFailureActionType = `${generateReportActionType}_FAILURE`;
const generateReportFailureAction = () => ({
  type: generateReportFailureActionType,
});

const generateReport = () => async dispatch => {
  dispatch(generateReportAction());
  return axios({
    method: 'GET',
    url: `${API_HOST_AUTH}/companies/generate-report`,
    responseType: 'arraybuffer',
  })
    .then(res => {
      downloadFile(res);
      dispatch(generateReportSuccessAction());
    })
    .catch(err => {
      dispatch(generateReportFailureAction());
      dispatch(showSnackbar(dangerOptions, err.data.message));
    });
};

export {
  generateReportActionType,
  generateReportSuccessActionType,
  generateReportFailureActionType,
  generateReport,
  generateReportAction,
  generateReportSuccessAction,
  generateReportFailureAction,
};
