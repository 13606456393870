import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { getPackagesUnsentForShipments } from 'actions/Packages/packagesOverviewList';
import { shipmentPackagesList } from 'actions/Shipments/shipmentPackagesList';

import { Checkbox } from 'shared/components/Fields/FieldCheckbox/FieldCheckbox';
import { ErrorMessage } from 'shared/components/Fields/FieldWrapper/components/ErrorMessage';
import { SHIPMENTS } from 'shared/helpers/constants/shipments/shipmentsConstants';
import { media } from 'shared/helpers/styling/styling';

const PackagesErrorMessage = styled(ErrorMessage)`
  transform: translate(-235px, -30px);
  margin-bottom: 12px;
  ${media.mobile`
    transform: none;
    position: absolute;
    top: -45px;
    min-width: 140px;
  `};

  &:before {
    transform: translate(-90px, 6px);
    ${media.mobile`
      transform: none;
      bottom: -6px;
    `};
  }
`;

const PackagesLayoutWrapper = styled.div`
  display: flex;
  ${media.mobile`
    order: -1;
    flex-direction: row-reverse;
  `};
`;

const SelectAllPackagesLayout = props => {
  const packages =
    props.packagesOverviewList.packagesList.items &&
    props.packagesOverviewList.packagesList.items.docs;

  const addAllPackages = () => {
    props.shipmentPackagesList(packages.map(p => p._id));
  };

  const removeAllPackages = () => {
    props.shipmentPackagesList([]);
  };

  const onCheckboxChange = ({ currentTarget }) => {
    currentTarget.checked ? addAllPackages() : removeAllPackages();
  };

  const disabled = !packages || !packages.length;

  const checkSelectedPackages = () =>
    packages && !disabled && props.packagesList.length === packages.length;

  return (
    !!(packages && packages.length) && (
      <PackagesLayoutWrapper>
        <Checkbox
          id="selectAllPackages"
          checked={checkSelectedPackages()}
          onChange={onCheckboxChange}
          label={SHIPMENTS.SELECT_ALL_PACKAGES}
          hasError={props.hasError}
          disabled={disabled}
        />
        <PackagesErrorMessage show={props.hasError}>
          {SHIPMENTS.ERROR.MUST_HAVE_PACKAGE}
        </PackagesErrorMessage>
      </PackagesLayoutWrapper>
    )
  );
};

const mapStateToProps = state => ({
  packagesList: state.shipmentPackagesListData.packages,
  shipment: state.shipmentDetails.shipment,
  packagesOverviewList: state.packagesOverviewList,
});

const mapDispatchToProps = {
  getPackagesUnsentForShipments,
  shipmentPackagesList,
};

const SelectAllPackages = connect(mapStateToProps, mapDispatchToProps)(SelectAllPackagesLayout);

export { SelectAllPackages, SelectAllPackagesLayout };
