import { connect } from 'react-redux';

import { deleteRecipient } from 'actions/Hedges/recipientsDelete';
import { getRecipients, clearRecipients } from 'actions/Hedges/recipientsList';
import { openModal } from 'actions/shared/modal';
import { showSnackbar } from 'actions/shared/snackbar';

import { Recipients } from './Recipients';

const mapStateToProps = ({ recipientsList, auth }) => ({ recipientsList, auth });

const dispatchMapToProps = {
  getRecipients,
  clearRecipients,
  deleteRecipient,
  showSnackbar,
  openModal,
};

const ConnectedRecipients = connect(mapStateToProps, dispatchMapToProps)(Recipients);

export { ConnectedRecipients };
