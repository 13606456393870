import {
  getShipmentsCalendarFailureActionType,
  getShipmentsCalendarSuccessActionType,
  getShipmentsCalendarActionType,
  clearShipmentsCalendarActionType,
} from 'actions/Shipments/shipmentsCalendar';

const INITIAL_STATE = {
  isPending: false,
  shipments: null,
};

const ShipmentsCalendarReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getShipmentsCalendarActionType:
      return {
        ...state,
        isPending: true,
      };
    case getShipmentsCalendarSuccessActionType:
      return {
        isPending: false,
        shipments: action.payload.shipments,
      };
    case getShipmentsCalendarFailureActionType:
    case clearShipmentsCalendarActionType:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};

export { ShipmentsCalendarReducer };
