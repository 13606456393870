import { CREATE_SHIPMENT } from 'shared/helpers/constants/shipments/createShipmentConstants';
import { dateToTimestamp } from 'shared/helpers/parsers/date';

function validate(values) {
  const errors = {};

  if (!values.assignedLot || (values.assignedLot && !values.assignedLot.length)) {
    errors.assignedLot = CREATE_SHIPMENT.INVALID_ASSIGNED_LOT_EMPTY;
  }

  if (!values.estPickupDate) {
    errors.estPickupDate = CREATE_SHIPMENT.INVALID_SHIP_DATE_EMPTY;
  } else if (
    values.estDeliveryDate &&
    dateToTimestamp(values.estPickupDate) > dateToTimestamp(values.estDeliveryDate)
  ) {
    errors.estPickupDate = CREATE_SHIPMENT.INVALID_SHIP_DATE_VALUE;
  }

  if (!values.estDeliveryDate) {
    errors.estDeliveryDate = CREATE_SHIPMENT.INVALID_EST_DELIVERY_DATE_EMPTY;
  } else if (
    values.estPickupDate &&
    dateToTimestamp(values.estPickupDate) > dateToTimestamp(values.estDeliveryDate)
  ) {
    errors.estDeliveryDate = CREATE_SHIPMENT.INVALID_EST_DELIVERY_DATE_VALUE;
  }

  if (!values.shipmentCompany) {
    errors.shipmentCompany = '';
  } else if (values.shipmentCompany.length > 50) {
    errors.shipmentCompany = CREATE_SHIPMENT.INVALID_SHIPMENT_COMPANY_LENGTH;
  }

  if (!values.shipmentSeal) {
    errors.shipmentSeal = '';
  } else if (values.shipmentSeal.length > 50) {
    errors.shipmentSeal = CREATE_SHIPMENT.INVALID_SHIPMENT_SEAL_LENGTH;
  }

  if (!values.loadNumber) {
    errors.loadNumber = '';
  } else if (values.loadNumber.length > 50) {
    errors.loadNumber = CREATE_SHIPMENT.INVALID_LOAD_NUMBER_LENGTH;
  }

  return errors;
}

export { validate };
