import { string, object } from 'yup';

import { message } from 'shared/helpers/constants/lots/createLotConstants';

export const BuyerLotFormSchema = object().shape({
  customerInstructions: string().max(100, message.INVALID_CUSTOMER_INSTRUCTIONS),
  sentFromCompanyLocation: object()
    .required(message.INVALID_SENT_FROM_COMPANY_LOCATION_EMPTY)
    .nullable(),
});
