import {
  holdLotActionType,
  holdLotSuccessActionType,
  holdLotFailureActionType,
} from 'actions/Lots/holdLot';

const INITIAL_STATE = {
  isPending: false,
};

const holdLotReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case holdLotActionType:
      return {
        isPending: true,
      };
    case holdLotSuccessActionType:
    case holdLotFailureActionType:
      return {
        isPending: false,
      };
    default:
      return state;
  }
};

export { holdLotReducer };
