import styled from 'styled-components';

import { media } from 'shared/helpers/styling/styling';

export const StyledAgreementFilters = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${media.largeTablet`
        justify-content: center;
    `}

  ${media.tablet`
        justify-content: center;
    `}

    ${media.phone`
        flex-direction: column;
        align-items: center;
    `}
`;

export const StyledAgreementFiltersItem = styled.div`
  width: 360px;

  ${media.phone`
        width: 250px;
    `}
`;
