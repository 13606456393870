import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { cssVar, isMobile } from 'shared/helpers/styling/styling';

import { Container } from './Container';

const fontRatio = 0.5;

const calculateBoxMargin = ({ children: value, labelWidth, pretendMobile, withoutMargin }) => {
  if (isMobile() || pretendMobile || withoutMargin) return 0;

  const positionFix = 46;
  const fontSize = 10;

  if (!value) return labelWidth - positionFix;

  const padding = (value.length / 2) * fontSize * fontRatio;
  const normalizedPadding = padding || 0;
  const margin = labelWidth - positionFix - normalizedPadding;

  return margin < 0 ? 0 : margin;
};

const calculateOriginMargin = ({ labelWidth, pretendMobile }) =>
  isMobile() || pretendMobile ? 20 : labelWidth - 40;

const ErrorMessageWrapper = styled.div`
  grid-area: error;
  position: relative;
  display: flex;
  min-height: 26px;
  opacity: ${({ show }) => (show ? 1 : 0)};
`;

const ErrorMessageContent = styled.div`
  display: inline-block;
  justify-self: left;
  min-width: 30px;
  padding: 2px 15px 3px;
  margin-left: ${calculateBoxMargin}px;
  font-size: 0.625rem;
  color: ${cssVar('whiteGRG')};
  margin-bottom: 6px;
  background-color: ${cssVar('copperRust')};
  border-radius: 7px;
  transition: opacity 50ms ease-out;

  ::before {
    content: '';
    display: inline-block;
    box-sizing: border-box;
    position: absolute;
    bottom: 0;
    left: ${calculateOriginMargin}px;
    height: 6px;
    width: 20px;
    border-style: solid;
    border-color: ${cssVar('copperRust')} transparent transparent transparent;
    border-width: 6px 10px 0 10px;
  }
`;

const ErrorMessage = props => (
  <ErrorMessageWrapper show={props.show && props.children}>
    <ErrorMessageContent {...props} />
  </ErrorMessageWrapper>
);

ErrorMessage.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  label: PropTypes.string,
  labelWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  show: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  unique: PropTypes.bool,
  withoutMargin: PropTypes.bool,
};

ErrorMessage.defaultProps = {
  children: '',
  label: '',
  labelWidth: Container.defaultProps.labelWidth,
  unique: false,
  withoutMargin: false,
};

export { ErrorMessage };
