import {
  getBusyHoursForSelfDeliveryRequestActionType,
  getBusyHoursForSelfDeliveryRequestSuccessActionType,
  clearBusyHoursForSelfDeliveryRequestSuccessActionType,
  getBusyHoursForSelfDeliveryRequestFailureActionType,
} from 'actions/SelfDeliveryRequests/selfDeliveryRequestBusyHours';
import {
  getSelfDeliveryRequestsOverviewFailureActionType,
  getSelfDeliveryRequestsOverviewSuccessActionType,
  clearSelfDeliveryRequestsOverviewActionType,
  getSelfDeliveryRequestsOverviewActionType,
} from 'actions/SelfDeliveryRequests/selfDeliveryRequestsOverview';

import dateAdapter from 'utils/date/dateAdapter';

const INITIAL_STATE = {
  isPending: false,
  items: null,
  excludedHours: [],
};

const SelfDeliveryRequestsOverviewReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getBusyHoursForSelfDeliveryRequestActionType:
    case getSelfDeliveryRequestsOverviewActionType:
      return {
        ...state,
        isPending: true,
      };
    case getSelfDeliveryRequestsOverviewSuccessActionType:
      return {
        isPending: false,
        items: action.payload.data,
      };
    case getBusyHoursForSelfDeliveryRequestSuccessActionType:
      const excludedHours = action.payload.map(excludedHours =>
        dateAdapter(excludedHours).localizeTime().valueOf(),
      );

      return {
        ...state,
        isPending: false,
        excludedHours,
      };
    case getBusyHoursForSelfDeliveryRequestFailureActionType:
    case getSelfDeliveryRequestsOverviewFailureActionType:
      return {
        ...state,
        isPending: false,
      };
    case clearBusyHoursForSelfDeliveryRequestSuccessActionType:
    case clearSelfDeliveryRequestsOverviewActionType:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};

export { SelfDeliveryRequestsOverviewReducer };
