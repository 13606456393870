import React from 'react';
import { css } from 'styled-components';

import { LinkButton } from 'shared/components/Buttons';
import { CollapsableDetailsFields } from 'shared/components/CollapsableDetailsFields/CollapsableDetailsFields';
import { DetailsActivityField } from 'shared/components/Details/DetailsActivityField';
import { DetailsActivityFieldList } from 'shared/components/Details/DetailsActivityFieldList/DetailsActivityFieldList';
import { DetailsDateField } from 'shared/components/Details/DetailsDateField';
import { DetailsDownloadField } from 'shared/components/Details/DetailsDownloadFieldRedesign';
import { DetailsField } from 'shared/components/Details/DetailsField';
import { DetailsRadioField } from 'shared/components/Details/DetailsRadioFieldRedesign';
import { converterCountsAccess } from 'shared/helpers/accesses/lots/converterCountsAccess';
import { ASSAY } from 'shared/helpers/constants/lots/assayConstants';
import { FINAL_INVOICE } from 'shared/helpers/constants/lots/finalInvoiceConstants';
import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';
import { denominatedIn } from 'shared/helpers/constants/materials/denominationTable';
import { SHIPMENTS } from 'shared/helpers/constants/shipments/shipmentsConstants';
import { isGradingCompany } from 'shared/helpers/matchers/checkCompanyType';
import { isWarehouseUser, isOwnerUser, isSupportUser } from 'shared/helpers/matchers/checkUserType';
import {
  formatDate,
  formatToDollar,
  formatToUS,
  formatToDollarWithPrefix,
} from 'shared/helpers/parsers/formaters';
import { isMobile, media } from 'shared/helpers/styling/styling';

import { setCorrectLabel } from 'utils/setCorrectLabel';

const locationStored = lot =>
  lot.locationStored &&
  lot.locationStored
    .map(({ building, section, lane }) =>
      lane ? `${building}-${section}-${lane}` : `${building}-${section}`,
    )
    .join(', ');

const assayResultsDetailsFields = {
  general: lot => [
    {
      label: LOTS.GLOBAL_MOISTURE_TEST,
      value: lot.globalMoistureTest,
      icon: 'icon-volume',
      suffix: '%',
      id: 'globalMoistureTest',
      Component: DetailsField,
    },
    {
      label: LOTS.WEIGHT_GROSS_RECEIVED,
      value: formatToUS(lot.weightGrossReceived),
      icon: 'icon-volume',
      suffix: LOTS.LBS,
      id: 'weightGrossReceived',
      Component: DetailsField,
    },
    {
      label: LOTS.WEIGHT_TARE_RECEIVED,
      value: formatToUS(lot.weightTareReceived),
      icon: 'icon-volume',
      suffix: LOTS.LBS,
      id: 'weightTareReceived',
      Component: DetailsField,
    },
    {
      label: LOTS.WEIGHT_NET_WET_RECEIVED,
      value: formatToUS(lot.weightNetWetReceived),
      icon: 'icon-volume',
      suffix: LOTS.LBS,
      id: 'weightNetWetReceived',
      Component: DetailsField,
    },
    {
      label: LOTS.WEIGHT_SCRAP_RECEIVED,
      value: formatToUS(lot.weightScrapReceived),
      icon: 'icon-volume',
      suffix: LOTS.LBS,
      id: 'weightScrapReceived',
      Component: DetailsField,
    },
    {
      label: LOTS.WEIGHT_ADJUSTED_NET_WET_RECEIVED,
      value: formatToUS(lot.weightAdjustedNetWetReceived),
      icon: 'icon-volume',
      suffix: LOTS.LBS,
      id: 'weightAdjustedNetWetReceived',
      Component: DetailsField,
    },
    {
      label: LOTS.PERCENT_MOISTURE,
      value: lot.percentMoisture,
      icon: 'icon-volume',
      suffix: '%',
      id: 'percentMoisture',
      Component: DetailsField,
    },
    {
      label: LOTS.WEIGHT_NET_DRY_RECEIVED,
      value: formatToUS(lot.weightNetDryReceived),
      icon: 'icon-volume',
      suffix: LOTS.LBS,
      id: 'weightNetDryReceived',
      Component: DetailsField,
    },
    {
      label: LOTS.PLATINUM_ASSAY,
      value: formatToUS(lot.platinumAssay, 1),
      icon: 'icon-volume',
      suffix: LOTS.PPM,
      id: 'platinumAssay',
      Component: DetailsField,
    },
    {
      label: LOTS.PALLADIUM_ASSAY,
      value: formatToUS(lot.palladiumAssay, 1),
      icon: 'icon-volume',
      suffix: LOTS.PPM,
      id: 'palladiumAssay',
      Component: DetailsField,
    },
    {
      label: LOTS.RHODIUM_ASSAY,
      value: formatToUS(lot.rhodiumAssay, 1),
      icon: 'icon-volume',
      suffix: LOTS.PPM,
      id: 'rhodiumAssay',
      Component: DetailsField,
    },
    {
      label: LOTS.PLATINUM_CONTAINED_OZ,
      value: formatToUS(lot.platinumContainedOz, 3),
      icon: 'icon-volume',
      suffix: LOTS.TROY_OZ,
      id: 'platinumContainedOz',
      Component: DetailsField,
    },
    {
      label: LOTS.PALLADIUM_CONTAINED_OZ,
      value: formatToUS(lot.palladiumContainedOz, 3),
      icon: 'icon-volume',
      suffix: LOTS.TROY_OZ,
      id: 'palladiumContainedOz',
      Component: DetailsField,
    },
    {
      label: LOTS.RHODIUM_CONTAINED_OZ,
      value: formatToUS(lot.rhodiumContainedOz, 3),
      icon: 'icon-volume',
      suffix: LOTS.TROY_OZ,
      id: 'rhodiumContainedOz',
      Component: DetailsField,
    },
    {
      label: LOTS.PLATINUM_RETURNABLE_OZ,
      value: formatToUS(lot.platinumReturnableOz, 3),
      icon: 'icon-volume',
      suffix: LOTS.TROY_OZ,
      id: 'platinumReturnableOz',
      Component: DetailsField,
    },
    {
      label: LOTS.PALLADIUM_RETURNABLE_OZ,
      value: formatToUS(lot.palladiumReturnableOz, 3),
      icon: 'icon-volume',
      suffix: LOTS.TROY_OZ,
      id: 'palladiumReturnableOz',
      Component: DetailsField,
    },
    {
      label: LOTS.RHODIUM_RETURNABLE_OZ,
      value: formatToUS(lot.rhodiumReturnableOz, 3),
      icon: 'icon-volume',
      suffix: LOTS.TROY_OZ,
      id: 'rhodiumReturnableOz',
      Component: DetailsField,
    },
    {
      label: ASSAY.CARBON_PERCENTAGE,
      value: formatToUS(lot.carbonPercentage, 3),
      icon: 'icon-volume',
      suffix: '%',
      id: 'carbonPercentage',
      Component: DetailsField,
    },
  ],
  heavies: lot => [
    {
      label: setCorrectLabel(
        lot.breakdownOption,
        LOTS.WEIGHT_GROSS_RECEIVED_HEAVIES,
        LOTS.WEIGHT_GROSS_RECEIVED_B,
      ),
      value: formatToUS(lot.weightGrossReceivedH),
      icon: 'icon-volume',
      suffix: LOTS.LBS,
      id: 'weightGrossReceivedH',
      Component: DetailsField,
    },
    {
      label: setCorrectLabel(
        lot.breakdownOption,
        LOTS.WEIGHT_TARE_RECEIVED_HEAVIES,
        LOTS.WEIGHT_TARE_RECEIVED_B,
      ),
      value: formatToUS(lot.weightTareReceivedH),
      icon: 'icon-volume',
      suffix: LOTS.LBS,
      id: 'weightTareReceivedH',
      Component: DetailsField,
    },
    {
      label: setCorrectLabel(
        lot.breakdownOption,
        LOTS.WEIGHT_NET_WET_RECEIVED_HEAVIES,
        LOTS.WEIGHT_NET_WET_RECEIVED_B,
      ),
      value: formatToUS(lot.weightNetWetReceivedH),
      icon: 'icon-volume',
      suffix: LOTS.LBS,
      id: 'weightNetWetReceivedH',
      Component: DetailsField,
    },
    {
      label: setCorrectLabel(
        lot.breakdownOption,
        LOTS.WEIGHT_SCRAP_RECEIVED_HEAVIES,
        LOTS.WEIGHT_SCRAP_RECEIVED_B,
      ),
      value: formatToUS(lot.weightScrapReceivedH),
      icon: 'icon-volume',
      suffix: LOTS.LBS,
      id: 'weightScrapReceivedH',
      Component: DetailsField,
    },
    {
      label: setCorrectLabel(
        lot.breakdownOption,
        LOTS.WEIGHT_ADJUSTED_NET_WET_RECEIVED_HEAVIES,
        LOTS.WEIGHT_ADJUSTED_NET_WET_RECEIVED_B,
      ),
      value: formatToUS(lot.weightAdjustedNetWetReceivedH),
      icon: 'icon-volume',
      suffix: LOTS.LBS,
      id: 'weightAdjustedNetWetReceivedH',
      Component: DetailsField,
    },
    {
      label: setCorrectLabel(
        lot.breakdownOption,
        LOTS.PERCENT_MOISTURE_HEAVIES,
        LOTS.PERCENT_MOISTURE_B,
      ),
      value: lot.percentMoistureH,
      icon: 'icon-volume',
      suffix: '%',
      id: 'percentMoistureH',
      Component: DetailsField,
    },
    {
      label: setCorrectLabel(
        lot.breakdownOption,
        LOTS.WEIGHT_NET_DRY_RECEIVED_HEAVIES,
        LOTS.WEIGHT_NET_DRY_RECEIVED_B,
      ),
      value: formatToUS(lot.weightNetDryReceivedH),
      icon: 'icon-volume',
      suffix: LOTS.LBS,
      id: 'weightNetDryReceivedH',
      Component: DetailsField,
    },
    {
      label: setCorrectLabel(
        lot.breakdownOption,
        LOTS.PLATINUM_ASSAY_HEAVIES,
        LOTS.PLATINUM_ASSAY_B,
      ),
      value: formatToUS(lot.platinumAssayH, 1),
      icon: 'icon-volume',
      suffix: LOTS.PPM,
      id: 'platinumAssayH',
      Component: DetailsField,
    },
    {
      label: setCorrectLabel(
        lot.breakdownOption,
        LOTS.PALLADIUM_ASSAY_HEAVIES,
        LOTS.PALLADIUM_ASSAY_B,
      ),
      value: formatToUS(lot.palladiumAssayH, 1),
      icon: 'icon-volume',
      suffix: LOTS.PPM,
      id: 'palladiumAssayH',
      Component: DetailsField,
    },
    {
      label: setCorrectLabel(lot.breakdownOption, LOTS.RHODIUM_ASSAY_HEAVIES, LOTS.RHODIUM_ASSAY_B),
      value: formatToUS(lot.rhodiumAssayH, 1),
      icon: 'icon-volume',
      suffix: LOTS.PPM,
      id: 'rhodiumAssayH',
      Component: DetailsField,
    },
    {
      label: setCorrectLabel(
        lot.breakdownOption,
        LOTS.PLATINUM_CONTAINED_OZ_HEAVIES,
        LOTS.PLATINUM_CONTAINED_OZ_B,
      ),
      value: formatToUS(lot.platinumContainedOzH, 3),
      icon: 'icon-volume',
      suffix: LOTS.TROY_OZ,
      id: 'platinumContainedOzH',
      Component: DetailsField,
    },
    {
      label: setCorrectLabel(
        lot.breakdownOption,
        LOTS.PALLADIUM_CONTAINED_OZ_HEAVIES,
        LOTS.PALLADIUM_CONTAINED_OZ_B,
      ),
      value: formatToUS(lot.palladiumContainedOzH, 3),
      icon: 'icon-volume',
      suffix: LOTS.TROY_OZ,
      id: 'palladiumContainedOzH',
      Component: DetailsField,
    },
    {
      label: setCorrectLabel(
        lot.breakdownOption,
        LOTS.RHODIUM_CONTAINED_OZ_HEAVIES,
        LOTS.RHODIUM_CONTAINED_OZ_B,
      ),
      value: formatToUS(lot.rhodiumContainedOzH, 3),
      icon: 'icon-volume',
      suffix: LOTS.TROY_OZ,
      id: 'rhodiumContainedOzH',
      Component: DetailsField,
    },
    {
      label: setCorrectLabel(
        lot.breakdownOption,
        LOTS.PLATINUM_RETURNABLE_OZ_HEAVIES,
        LOTS.PLATINUM_RETURNABLE_OZ_B,
      ),
      value: formatToUS(lot.platinumReturnableOzH, 3),
      icon: 'icon-volume',
      suffix: LOTS.TROY_OZ,
      id: 'platinumReturnableOzH',
      Component: DetailsField,
    },
    {
      label: setCorrectLabel(
        lot.breakdownOption,
        LOTS.PALLADIUM_RETURNABLE_OZ_HEAVIES,
        LOTS.PALLADIUM_RETURNABLE_OZ_B,
      ),
      value: formatToUS(lot.palladiumReturnableOzH, 3),
      icon: 'icon-volume',
      suffix: LOTS.TROY_OZ,
      id: 'palladiumReturnableOzH',
      Component: DetailsField,
    },
    {
      label: setCorrectLabel(
        lot.breakdownOption,
        LOTS.RHODIUM_RETURNABLE_OZ_HEAVIES,
        LOTS.RHODIUM_RETURNABLE_OZ_B,
      ),
      value: formatToUS(lot.rhodiumReturnableOzH, 3),
      icon: 'icon-volume',
      suffix: LOTS.TROY_OZ,
      id: 'rhodiumReturnableOzH',
      Component: DetailsField,
    },
    {
      label: setCorrectLabel(
        lot.breakdownOption,
        ASSAY.CARBON_PERCENTAGE_H,
        ASSAY.CARBON_PERCENTAGE_B,
      ),
      value: formatToUS(lot.carbonPercentageH, 3),
      icon: 'icon-volume',
      suffix: '%',
      id: 'carbonPercentageH',
      Component: DetailsField,
    },
  ],
  fines: lot => [
    {
      label: setCorrectLabel(
        lot.breakdownOption,
        LOTS.WEIGHT_GROSS_RECEIVED_FINES,
        LOTS.WEIGHT_GROSS_RECEIVED_A,
      ),
      value: formatToUS(lot.weightGrossReceivedF),
      icon: 'icon-volume',
      suffix: LOTS.LBS,
      id: 'weightGrossReceivedF',
      Component: DetailsField,
    },
    {
      label: setCorrectLabel(
        lot.breakdownOption,
        LOTS.WEIGHT_TARE_RECEIVED_FINES,
        LOTS.WEIGHT_TARE_RECEIVED_A,
      ),
      value: formatToUS(lot.weightTareReceivedF),
      icon: 'icon-volume',
      suffix: LOTS.LBS,
      id: 'weightTareReceivedF',
      Component: DetailsField,
    },
    {
      label: setCorrectLabel(
        lot.breakdownOption,
        LOTS.WEIGHT_NET_WET_RECEIVED_FINES,
        LOTS.WEIGHT_NET_WET_RECEIVED_A,
      ),
      value: formatToUS(lot.weightNetWetReceivedF),
      icon: 'icon-volume',
      suffix: LOTS.LBS,
      id: 'weightNetWetReceivedF',
      Component: DetailsField,
    },
    {
      label: setCorrectLabel(
        lot.breakdownOption,
        LOTS.WEIGHT_SCRAP_RECEIVED_FINES,
        LOTS.WEIGHT_SCRAP_RECEIVED_A,
      ),
      value: formatToUS(lot.weightScrapReceivedF),
      icon: 'icon-volume',
      suffix: LOTS.LBS,
      id: 'weightScrapReceivedF',
      Component: DetailsField,
    },
    {
      label: setCorrectLabel(
        lot.breakdownOption,
        LOTS.WEIGHT_ADJUSTED_NET_WET_RECEIVED_FINES,
        LOTS.WEIGHT_ADJUSTED_NET_WET_RECEIVED_A,
      ),
      value: formatToUS(lot.weightAdjustedNetWetReceivedF),
      icon: 'icon-volume',
      suffix: LOTS.LBS,
      id: 'weightAdjustedNetWetReceivedF',
      Component: DetailsField,
    },
    {
      label: setCorrectLabel(
        lot.breakdownOption,
        LOTS.PERCENT_MOISTURE_FINES,
        LOTS.PERCENT_MOISTURE_A,
      ),
      value: lot.percentMoistureF,
      icon: 'icon-volume',
      suffix: '%',
      id: 'percentMoistureF',
      Component: DetailsField,
    },
    {
      label: setCorrectLabel(
        lot.breakdownOption,
        LOTS.WEIGHT_NET_DRY_RECEIVED_FINES,
        LOTS.WEIGHT_NET_DRY_RECEIVED_A,
      ),
      value: formatToUS(lot.weightNetDryReceivedF),
      icon: 'icon-volume',
      suffix: LOTS.LBS,
      id: 'weightNetDryReceivedF',
      Component: DetailsField,
    },
    {
      label: setCorrectLabel(lot.breakdownOption, LOTS.PLATINUM_ASSAY_FINES, LOTS.PLATINUM_ASSAY_A),
      value: formatToUS(lot.platinumAssayF, 1),
      icon: 'icon-volume',
      suffix: LOTS.PPM,
      id: 'platinumAssayF',
      Component: DetailsField,
    },
    {
      label: setCorrectLabel(
        lot.breakdownOption,
        LOTS.PALLADIUM_ASSAY_FINES,
        LOTS.PALLADIUM_ASSAY_A,
      ),
      value: formatToUS(lot.palladiumAssayF, 1),
      icon: 'icon-volume',
      suffix: LOTS.PPM,
      id: 'palladiumAssayF',
      Component: DetailsField,
    },
    {
      label: setCorrectLabel(lot.breakdownOption, LOTS.RHODIUM_ASSAY_FINES, LOTS.RHODIUM_ASSAY_A),
      value: formatToUS(lot.rhodiumAssayF, 1),
      icon: 'icon-volume',
      suffix: LOTS.PPM,
      id: 'rhodiumAssayF',
      Component: DetailsField,
    },
    {
      label: setCorrectLabel(
        lot.breakdownOption,
        LOTS.PLATINUM_CONTAINED_OZ_FINES,
        LOTS.PLATINUM_CONTAINED_OZ_A,
      ),
      value: formatToUS(lot.platinumContainedOzF, 3),
      icon: 'icon-volume',
      suffix: LOTS.TROY_OZ,
      id: 'platinumContainedOzF',
      Component: DetailsField,
    },
    {
      label: setCorrectLabel(
        lot.breakdownOption,
        LOTS.PALLADIUM_CONTAINED_OZ_FINES,
        LOTS.PALLADIUM_CONTAINED_OZ_A,
      ),
      value: formatToUS(lot.palladiumContainedOzF, 3),
      icon: 'icon-volume',
      suffix: LOTS.TROY_OZ,
      id: 'palladiumContainedOzF',
      Component: DetailsField,
    },
    {
      label: setCorrectLabel(
        lot.breakdownOption,
        LOTS.RHODIUM_CONTAINED_OZ_FINES,
        LOTS.RHODIUM_CONTAINED_OZ_A,
      ),
      value: formatToUS(lot.rhodiumContainedOzF, 3),
      icon: 'icon-volume',
      suffix: LOTS.TROY_OZ,
      id: 'rhodiumContainedOzF',
      Component: DetailsField,
    },
    {
      label: setCorrectLabel(
        lot.breakdownOption,
        LOTS.PLATINUM_RETURNABLE_OZ_FINES,
        LOTS.PLATINUM_RETURNABLE_OZ_A,
      ),
      value: formatToUS(lot.platinumReturnableOzF, 3),
      icon: 'icon-volume',
      suffix: LOTS.TROY_OZ,
      id: 'platinumReturnableOzF',
      Component: DetailsField,
    },
    {
      label: setCorrectLabel(
        lot.breakdownOption,
        LOTS.PALLADIUM_RETURNABLE_OZ_FINES,
        LOTS.PALLADIUM_RETURNABLE_OZ_A,
      ),
      value: formatToUS(lot.palladiumReturnableOzF, 3),
      icon: 'icon-volume',
      suffix: LOTS.TROY_OZ,
      id: 'palladiumReturnableOzF',
      Component: DetailsField,
    },
    {
      label: setCorrectLabel(
        lot.breakdownOption,
        LOTS.RHODIUM_RETURNABLE_OZ_FINES,
        LOTS.RHODIUM_RETURNABLE_OZ_A,
      ),
      value: formatToUS(lot.rhodiumReturnableOzF, 3),
      icon: 'icon-volume',
      suffix: LOTS.TROY_OZ,
      id: 'rhodiumReturnableOzF',
      Component: DetailsField,
    },
    {
      label: setCorrectLabel(
        lot.breakdownOption,
        ASSAY.CARBON_PERCENTAGE_F,
        ASSAY.CARBON_PERCENTAGE_A,
      ),
      value: formatToUS(lot.carbonPercentageF, 3),
      icon: 'icon-volume',
      suffix: '%',
      id: 'carbonPercentageF',
      Component: DetailsField,
    },
  ],
};

const deductionsDetailsFields = lot => {
  const deductionsFields = [];

  lot.truckingDeduction &&
    deductionsFields.push(
      {
        value: `${formatToDollar(lot.truckingDeduction)}`,
        prefix: '$',
        icon: 'icon-dollar',
        id: `truckingDeductionValue`,
        Component: DetailsField,
        oneRow: true,
      },
      {
        value: 'Trucking',
        icon: 'icon-referral',
        id: `truckingDeductionDescription`,
        Component: DetailsField,
        oneRow: true,
      },
    );

  lot.deductions.map((deduction, index) =>
    deductionsFields.push(
      {
        label: `${LOTS.DEDUCTION} ${index + 1}`,
        value: formatToDollar(deduction.value),
        prefix: '$',
        icon: 'icon-dollar',
        id: `deduction${index}`,
        Component: DetailsField,
      },
      {
        label: `${LOTS.DEDUCTION} ${index + 1} description`,
        value: deduction.description,
        icon: 'icon-referral',
        id: `deduction${index}Description`,
        Component: DetailsField,
      },
    ),
  );

  return deductionsFields;
};

const creditsDetailsFields = lot => {
  const creditsDetails = [];

  lot.credits.map((credit, index) =>
    creditsDetails.push(
      {
        label: `${LOTS.CREDIT} ${index + 1}`,
        value: formatToDollar(credit.value),
        prefix: '$',
        icon: 'icon-dollar',
        id: `credit${index}`,
        Component: DetailsField,
      },
      {
        label: `${LOTS.CREDIT} ${index + 1} description`,
        value: credit.description,
        icon: 'icon-referral',
        id: `credit${index}Description`,
        Component: DetailsField,
      },
    ),
  );

  return creditsDetails;
};

const tabsConfig = (lot, auth, history, params, downloadAttachment, shipments = []) => [
  {
    tabName: LOTS.WEIGHT,
    tabContent: {
      template: isMobile()
        ? [
            'weightGrossDeclared weightGrossDeclared weightTareDeclared weightTareDeclared',
            'weightNetDeclared weightNetDeclared weightGrossActual weightGrossActual',
            'weightTareActual weightTareActual weightNetActual weightNetActual',
          ]
        : [
            'weightGrossDeclared weightGrossDeclared weightGrossActual weightGrossActual',
            'weightTareDeclared weightTareDeclared weightTareActual weightTareActual',
            'weightNetDeclared weightNetDeclared weightNetActual weightNetActual',
          ],
      fieldsConfig: [
        {
          label: LOTS.WEIGHT_GROSS_DECLARED,
          value: formatToUS(lot.weightGrossDeclared),
          icon: 'icon-volume',
          id: 'weightGrossDeclared',
          suffix: LOTS.LBS,
          Component: DetailsField,
        },
        {
          label: LOTS.WEIGHT_TARE_DECLARED,
          value: formatToUS(lot.weightTareDeclared),
          icon: 'icon-volume',
          id: 'weightTareDeclared',
          suffix: LOTS.LBS,
          Component: DetailsField,
        },
        {
          label: LOTS.WEIGHT_NET_DECLARED,
          value: formatToUS(lot.weightNetDeclared),
          icon: 'icon-volume',
          id: 'weightNetDeclared',
          suffix: LOTS.LBS,
          Component: DetailsField,
        },
        {
          label: LOTS.WEIGHT_GROSS_ACTUAL,
          value: formatToUS(lot.weightGrossActual),
          icon: 'icon-volume',
          id: 'weightGrossActual',
          suffix: LOTS.LBS,
          Component: DetailsField,
        },
        {
          label: LOTS.WEIGHT_TARE_ACTUAL,
          value: formatToUS(lot.weightTareActual),
          icon: 'icon-volume',
          id: 'weightTareActual',
          suffix: LOTS.LBS,
          Component: DetailsField,
        },
        {
          label: LOTS.WEIGHT_NET_ACTUAL,
          value: formatToUS(lot.weightNetActual),
          icon: 'icon-volume',
          id: 'weightNetActual',
          suffix: LOTS.LBS,
          Component: DetailsField,
        },
      ],
    },
  },
  {
    tabName: LOTS.PROCESSING,
    tabContent: {
      template: [
        'processVia processVia mixVia mixVia',
        'locationStored locationStored loadNumber loadNumber',
        'inspectionRequested inspectionScheduled inspectionScheduledFor inspectionScheduledFor',
        'inspectionRequestedBy inspectionRequestedBy inspectionScheduledBy inspectionScheduledBy',
      ],
      fieldsConfig: [
        {
          label: LOTS.LOCATION_STORED,
          value: lot.locationStored && locationStored(lot),
          icon: 'icon-home',
          id: 'locationStored',
          Component: DetailsField,
        },
        {
          label: SHIPMENTS.LOAD_NUMBER,
          icon: 'icon-car',
          id: 'loadNumber',
          customContent:
            shipments.some(({ loadNumber }) => loadNumber) &&
            shipments.map(({ loadNumber }, index) => (
              <div key={`${loadNumber}-${index}`}>{loadNumber}</div>
            )),
          Component: DetailsField,
        },
        {
          label: LOTS.PROCESS_VIA,
          value: lot.processVia,
          icon: 'icon-process',
          id: 'processVia',
          Component: DetailsField,
        },
        {
          label: LOTS.MIX_VIA,
          value: lot.mixVia,
          icon: 'icon-process',
          id: 'mixVia',
          Component: DetailsField,
        },
        {
          label: LOTS.INSPECTION_REQUESTED,
          value: lot.inspectionRequested,
          icon: 'icon-request-inspection',
          id: 'inspectionRequested',
          Component: DetailsRadioField,
        },
        {
          label: LOTS.INSPECTION_SCHEDULED,
          value: lot.inspectionScheduled,
          icon: 'icon-schedule-inspection',
          id: 'inspectionScheduled',
          Component: DetailsRadioField,
        },
        {
          label: LOTS.INSPECTION_SCHEDULED_FOR,
          value: lot.relatedInspection && lot.relatedInspection.inspectionScheduledFor,
          id: 'inspectionScheduledFor',
          Component: DetailsDateField,
        },
        {
          label: LOTS.INSPECTION_REQUESTED_BY,
          date: lot.relatedInspection && lot.relatedInspection.inspectionRequestedAt,
          user: lot.relatedInspection && lot.relatedInspection.inspectionRequestedBy,
          id: 'inspectionRequestedBy',
          Component: DetailsActivityField,
        },
        {
          label: LOTS.INSPECTION_SCHEDULED_BY,
          date: lot.relatedInspection && lot.relatedInspection.inspectionScheduledAt,
          user: lot.relatedInspection && lot.relatedInspection.inspectionScheduledBy,
          id: 'inspectionScheduledBy',
          Component: DetailsActivityField,
        },
      ],
    },
  },
  !isWarehouseUser(auth.user.userType) && {
    tabName: LOTS.ASSAY_RESULTS,
    tabContent: {
      template: [
        'relatedAssayResults relatedAssayResults stillwaterShipmentId stillwaterShipmentId',
        'assayResultsGeneral assayResultsGeneral assayResultsGeneral assayResultsGeneral',
        'assayResultsFines assayResultsFines assayResultsFines assayResultsFines',
        'assayResultsHeavies assayResultsHeavies assayResultsHeavies assayResultsHeavies',
      ],
      fieldsConfig: [
        {
          label: LOTS.ASSAY_RESULTS_ENTERED_MANUALLY,
          icon: 'icon-document',
          id: 'relatedAssayResults',
          customContent:
            !!lot.relatedAssayResults.length &&
            lot.relatedAssayResults.map((assay, index) => (
              <LinkButton
                label={`${LOTS.ASSAY} ${index + 1} (${formatDate(assay.createdAt)}) `}
                onClick={() => history.push(`/lots/list/${params.id}/assay/${assay._id}`)}
                key={assay._id}
              />
            )),
          Component: DetailsField,
        },
        {
          label: LOTS.STILLWATER_SHIPMENT_ID,
          value: lot.stillwaterShipmentId,
          icon: 'icon-referral',
          id: 'stillwaterShipmentId',
          Component: DetailsField,
        },
        {
          label: LOTS.ASSAY_RESULTS,
          insideFields: assayResultsDetailsFields.general(lot),
          id: 'assayResultsGeneral',
          Component: CollapsableDetailsFields,
        },
        {
          label: setCorrectLabel(
            lot.breakdownOption,
            LOTS.ASSAY_RESULTS_FINES,
            LOTS.ASSAY_RESULTS_A,
          ),
          insideFields: assayResultsDetailsFields.fines(lot),
          id: 'assayResultsFines',
          Component: CollapsableDetailsFields,
        },
        {
          label: setCorrectLabel(
            lot.breakdownOption,
            LOTS.ASSAY_RESULTS_HEAVIES,
            LOTS.ASSAY_RESULTS_B,
          ),
          insideFields: assayResultsDetailsFields.heavies(lot),
          id: 'assayResultsHeavies',
          Component: CollapsableDetailsFields,
        },
      ],
    },
  },
  {
    tabName: LOTS.DOCUMENTS,
    tabContent: {
      template: !isWarehouseUser(auth.user.userType)
        ? [
            'stillwaterShipmentSummary stillwaterShipmentSummary stillwaterFinalInvoice stillwaterFinalInvoice',
            'stillwaterInitialPayment stillwaterInitialPayment stillwaterInitialPaymentDate stillwaterInitialPaymentDate',
            'stillwaterFinalPayment stillwaterFinalPayment stillwaterFinalPaymentDate stillwaterFinalPaymentDate',
            'grgShipmentSummary grgShipmentSummary grgFinalInvoice grgFinalInvoice',
            `grgProfitAndLoss grgProfitAndLoss ${
              isGradingCompany(lot.sentFromCompany.companyType)
                ? 'gradingReceipt gradingReceipt'
                : lot.sentFromCompany.materialCountRequired
                ? [`countSheet countSheet`]
                : 'grgProfitAndLoss grgProfitAndLoss'
            }`,
            'hedgeOrderDocument hedgeOrderDocument billOfLading billOfLading',
            'poolSettlementDocument poolSettlementDocument poolSettlementDocument poolSettlementDocument',
            'labRequestForm labRequestForm labRequestForm labRequestForm',
            'converterFields converterFields converterFields converterFields',
          ]
        : [
            `${
              isGradingCompany(lot.sentFromCompany.companyType)
                ? 'gradingReceipt gradingReceipt gradingReceipt gradingReceipt'
                : lot.sentFromCompany.materialCountRequired
                ? 'countSheet countSheet countSheet countSheet'
                : '. . . .'
            }`,
            'billOfLading billOfLading billOfLading billOfLading',
            'labRequestForm labRequestForm labRequestForm labRequestForm',
          ],
      fieldsConfig: [
        ...(isGradingCompany(lot.sentFromCompany.companyType)
          ? [
              {
                label: LOTS.GRADING_RECEIPT,
                value: lot.gradingReceipt,
                id: 'gradingReceipt',
                onClick: () => downloadAttachment(lot.gradingReceipt.path),
                Component: DetailsDownloadField,
                forwardable: true,
              },
            ]
          : []),
        lot.sentFromCompany.materialCountRequired && {
          label: LOTS.COUNT_SHEET,
          value: lot.countSheet,
          id: 'countSheet',
          onClick: () => downloadAttachment(lot.countSheet.path),
          Component: DetailsDownloadField,
          forwardable: true,
        },
        {
          label: LOTS.BILL_OF_LADING,
          value: lot.billOfLading,
          id: 'billOfLading',
          onClick: index => downloadAttachment(lot.billOfLading[index].path),
          Component: DetailsDownloadField,
          generateName: index => `${LOTS.BILL_OF_LADING_SHORT}-${++index}`,
        },
        {
          label: LOTS.LAB_REQUEST_FORM,
          value: lot.labRequestForm,
          id: 'labRequestForm',
          onClick: index => downloadAttachment(lot.labRequestForm[index].path),
          generateName: (index, files) => files[index].originalName,
          Component: DetailsDownloadField,
        },
        ...(!isWarehouseUser(auth.user.userType)
          ? [
              {
                label: LOTS.STILLWATER_SHIPMENT_SUMMARY,
                value: lot.stillwaterShipmentSummary,
                id: 'stillwaterShipmentSummary',
                onClick: () => downloadAttachment(lot.stillwaterShipmentSummary.path),
                Component: DetailsDownloadField,
              },
              {
                label: LOTS.STILLWATER_FINAL_INVOICE,
                value: lot.stillwaterFinalInvoice,
                id: 'stillwaterFinalInvoice',
                onClick: () => downloadAttachment(lot.stillwaterFinalInvoice.path),
                Component: DetailsDownloadField,
              },
              ...((isOwnerUser(auth.user.userType) || isSupportUser(auth.user.userType)) && [
                {
                  label: LOTS.STILLWATER_INITIAL_PAYMENT,
                  value: formatToDollarWithPrefix(lot.stillwaterInitialPayment),
                  id: 'stillwaterInitialPayment',
                  icon: 'icon-dollar',
                  Component: DetailsField,
                },
                {
                  label: LOTS.STILLWATER_INITIAL_PAYMENT_DATE,
                  value: lot.stillwaterInitialPaymentDate,
                  id: 'stillwaterInitialPaymentDate',
                  Component: DetailsDateField,
                },
                {
                  label: LOTS.STILLWATER_FINAL_PAYMENT,
                  value: formatToDollarWithPrefix(lot.stillwaterFinalPayment),
                  id: 'stillwaterFinalPayment',
                  icon: 'icon-dollar',
                  containerStyles: css`
                    ${media.desktop`border-bottom: 3px solid var(--riverBedLighter);`}
                  `,
                  Component: DetailsField,
                },
                {
                  label: LOTS.STILLWATER_FINAL_PAYMENT_DATE,
                  value: lot.stillwaterFinalPaymentDate,
                  id: 'stillwaterFinalPaymentDate',
                  containerStyles: css`
                    border-bottom: 3px solid var(--riverBedLighter);
                  `,
                  Component: DetailsDateField,
                },
              ]),
              {
                label: LOTS.GRG_SHIPMENT_SUMMARY,
                value: lot.grgShipmentSummary,
                id: 'grgShipmentSummary',
                onClick: () => downloadAttachment(lot.grgShipmentSummary.path),
                Component: DetailsDownloadField,
                forwardable: true,
                manualCreationWarning:
                  lot.assayEnteredManually && LOTS.ASSAY_RESULTS_ENTERED_MANUALLY.toUpperCase(),
              },
              {
                label: LOTS.GRG_FINAL_INVOICE,
                value: lot.grgFinalInvoice,
                id: 'grgFinalInvoice',
                onClick: () => downloadAttachment(lot.grgFinalInvoice.path),
                Component: DetailsDownloadField,
                forwardable: true,
                manualCreationWarning: lot.customInvoiceUploadedManually && LOTS.UPLOADED_MANUALLY,
              },
              {
                label: LOTS.GRG_PROFIT_AND_LOSS,
                value: lot.grgProfitAndLoss,
                id: 'grgProfitAndLoss',
                onClick: index => downloadAttachment(lot.grgProfitAndLoss[index].path),
                generateName: (index, files) =>
                  `${LOTS.PROFIT_AND_LOSS_SHORT} ${files.length - index}`,
                Component: DetailsDownloadField,
                forwardable: true,
              },
              {
                label: LOTS.HEDGE_ORDER_DOCUMENT,
                value: lot.hedgeOrderDocuments,
                id: 'hedgeOrderDocument',
                onClick: index => downloadAttachment(lot.hedgeOrderDocuments[index].path),
                generateName: (index, files) => files[index].hedgeName,
                Component: DetailsDownloadField,
              },
              {
                label: LOTS.POOL_SETTLEMENT_DOCUMENT,
                value: lot.poolSettlementDocument,
                id: 'poolSettlementDocument',
                onClick: index => downloadAttachment(lot.poolSettlementDocument[index].path),
                generateName: (index, files) => files[index].originalName,
                Component: DetailsDownloadField,
              },
            ]
          : []),
        converterCountsAccess(auth.user.userType, lot.sentFromCompany.materialCountRequired) && {
          label: LOTS.CONVERTER_COUNTS,
          insideFields: [
            {
              label: LOTS.MONO.label,
              value: lot?.relatedReport?.catMono,
              icon: 'icon-volume',
              suffix: denominatedIn.units,
              id: 'catMono',
              Component: DetailsField,
            },
            {
              label: LOTS.FOIL.label,
              value: lot?.relatedReport?.catFoil,
              icon: 'icon-volume',
              suffix: denominatedIn.units,
              id: 'catFoil',
              Component: DetailsField,
            },
            {
              label: LOTS.EMPTY.label,
              value: lot?.relatedReport?.catEmpty,
              icon: 'icon-volume',
              suffix: denominatedIn.units,
              id: 'catEmpty',
              Component: DetailsField,
            },
            {
              label: LOTS.AFT.label,
              value: lot?.relatedReport?.catAft,
              icon: 'icon-volume',
              suffix: denominatedIn.units,
              id: 'catAft',
              Component: DetailsField,
            },
            {
              label: LOTS.BEAD.label,
              value: lot?.relatedReport?.catBead,
              icon: 'icon-volume',
              suffix: denominatedIn.units,
              id: 'catBead',
              Component: DetailsField,
            },
            {
              label: LOTS.GRG_QTY_TOTAL.label,
              value: lot?.relatedReport?.catGrgQtyTotal,
              icon: 'icon-volume',
              suffix: denominatedIn.units,
              id: 'catGrgQtyTotal',
              Component: DetailsField,
            },
          ],
          id: 'converterFields',
          Component: CollapsableDetailsFields,
          isOpenAtStart: true,
          cellWidth: 33.333333,
        },
      ],
    },
  },
  {
    tabName: LOTS.INVOICING,
    tabContent: {
      template: [
        'deductionsGeneral deductionsGeneral deductionsGeneral deductionsGeneral',
        'creditsGeneral creditsGeneral creditsGeneral creditsGeneral',
        'finalPlatinumPrice finalPlatinumPrice finalPalladiumPrice finalRhodiumPrice',
        'platinumOuncesRemoved platinumOuncesRemoved palladiumOuncesRemoved rhodiumOuncesRemoved',
        'totalFinalPayment totalFinalPayment outstandingBalanceApplied outstandingBalanceApplied',
      ],
      fieldsConfig: [
        {
          label: LOTS.DEDUCTIONS,
          insideFields: deductionsDetailsFields(lot),
          id: 'deductionsGeneral',
          disabled: !(lot.deductions.length || lot.truckingDeduction),
          Component: CollapsableDetailsFields,
          cellWidth: 50,
        },
        {
          label: LOTS.CREDITS,
          insideFields: creditsDetailsFields(lot),
          id: 'creditsGeneral',
          disabled: !lot.credits.length,
          Component: CollapsableDetailsFields,
          cellWidth: 50,
        },
        {
          label: FINAL_INVOICE.FINAL_PLATINUM_PRICE,
          value: formatToDollar(lot.finalPlatinumPrice),
          prefix: '$',
          icon: 'icon-dollar',
          id: 'finalPlatinumPrice',
          Component: DetailsField,
        },
        {
          label: FINAL_INVOICE.FINAL_PALLADIUM_PRICE,
          value: formatToDollar(lot.finalPalladiumPrice),
          prefix: '$',
          icon: 'icon-dollar',
          id: 'finalPalladiumPrice',
          Component: DetailsField,
        },
        {
          label: FINAL_INVOICE.FINAL_RHODIUM_PRICE,
          value: formatToDollar(lot.finalRhodiumPrice),
          prefix: '$',
          icon: 'icon-dollar',
          id: 'finalRhodiumPrice',
          Component: DetailsField,
        },
        {
          label: FINAL_INVOICE.PLATINUM_OUNCES_REMOVED,
          value: formatToUS(lot.platinumOuncesRemoved),
          icon: 'icon-volume',
          id: 'platinumOuncesRemoved',
          Component: DetailsField,
        },
        {
          label: FINAL_INVOICE.PALLADIUM_OUNCES_REMOVED,
          value: formatToUS(lot.palladiumOuncesRemoved),
          icon: 'icon-volume',
          id: 'palladiumOuncesRemoved',
          Component: DetailsField,
        },
        {
          label: FINAL_INVOICE.RHODIUM_OUNCES_REMOVED,
          value: formatToUS(lot.rhodiumOuncesRemoved),
          icon: 'icon-volume',
          id: 'rhodiumOuncesRemoved',
          Component: DetailsField,
        },
        {
          label: FINAL_INVOICE.TOTAL_FINAL_PAYMENT,
          value: formatToDollarWithPrefix(lot.totalFinalPayment),
          icon: 'icon-dollar',
          id: 'totalFinalPayment',
          Component: DetailsField,
        },
        {
          label: FINAL_INVOICE.OUTSTANDING_BALANCE_APPLIED,
          value: formatToDollarWithPrefix(lot.outstandingBalanceApplied),
          icon: 'icon-dollar',
          id: 'outstandingBalanceApplied',
          Component: DetailsField,
        },
      ],
    },
  },
  {
    tabName: LOTS.ACTIVITY,
    tabContent: {
      template: [
        'createdBy updatedBy receivedBy gradedBy',
        'markedForProcessingBy processedBy mixedBy shippedFromGlobalBy',
        'invoiceGeneratedBy invoiceGeneratedBy invoiceApprovedBy invoiceApprovedBy',
      ],
      fieldsConfig: [
        {
          label: LOTS.CREATED_BY,
          user: lot.createdBy,
          date: lot.createdAt,
          id: 'createdBy',
          Component: DetailsActivityField,
        },
        {
          label: LOTS.UPDATED_BY,
          user: lot.updatedBy,
          date: lot.updatedAt,
          id: 'updatedBy',
          Component: DetailsActivityField,
        },
        {
          label: LOTS.RECEIVED_BY,
          values: lot.deliveryDates,
          id: 'receivedBy',
          Component: DetailsActivityFieldList,
        },
        {
          label: LOTS.GRADED_BY,
          user: lot.gradedBy,
          date: lot.gradedAt,
          id: 'gradedBy',
          Component: DetailsActivityField,
        },
        {
          label: LOTS.MARKED_FOR_PROCESSING_BY,
          user: lot.markedForProcessingBy,
          date: lot.markedForProcessingAt,
          id: 'markedForProcessingBy',
          Component: DetailsActivityField,
        },
        {
          label: LOTS.PROCESSED_BY,
          user: lot.processedBy,
          date: lot.processedAt,
          id: 'processedBy',
          Component: DetailsActivityField,
        },
        {
          label: LOTS.MIXED_BY,
          user: lot.mixedBy,
          date: lot.mixedAt,
          id: 'mixedBy',
          Component: DetailsActivityField,
        },
        {
          label: LOTS.SHIPPED_FROM_GLOBAL_BY,
          user: lot.shippedFromGlobalBy,
          date: lot.shippedFromGlobalAt,
          id: 'shippedFromGlobalBy',
          Component: DetailsActivityField,
        },
        {
          label: LOTS.INVOICE_GENERATED_BY,
          user: lot.invoiceGeneratedBy,
          date: lot.invoiceGeneratedAt,
          id: 'invoiceGeneratedBy',
          Component: DetailsActivityField,
        },
        {
          label: LOTS.INVOICE_APPROVED_BY,
          user: lot.invoiceApprovedBy,
          id: 'invoiceApprovedBy',
          Component: DetailsActivityField,
        },
      ],
    },
  },
];

export { tabsConfig };
