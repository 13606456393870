import React from 'react';
import styled from 'styled-components';

import { RequirementMatcher } from 'components/RequirementMatcher/RequirementMatcher';

import { PASSWORD_VALIDATION } from 'shared/helpers/constants/users/passwordValidationConstants';
import { pixelify } from 'shared/helpers/styling/styling';

const requirements = [
  {
    name: PASSWORD_VALIDATION.TOO_SHORT,
    test: v => v.length >= 8,
  },
  {
    name: PASSWORD_VALIDATION.TOO_LONG,
    test: v => v.length <= 15,
  },
  {
    name: PASSWORD_VALIDATION.MUST_CONTAIN_UPPER_CASE,
    test: /[A-Z]/,
  },
  {
    name: PASSWORD_VALIDATION.MUST_CONTAIN_NUMBER,
    test: /\d/,
  },
  {
    name: PASSWORD_VALIDATION.MUST_CONTAIN_SPECIAL_CHARACTER,
    test: /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/,
  },
];

const PasswordRequirementsLayout = ({ className, ...props }) => (
  <div className={className}>
    <RequirementMatcher
      value={props.password}
      requirements={requirements}
      show={props.dirty || false}
      hideMet
    />
  </div>
);

const PasswordRequirements = styled(PasswordRequirementsLayout)`
  margin-bottom: ${({ marginBottom }) => pixelify(marginBottom)};
`;

PasswordRequirements.defaultProps = {
  marginBottom: '15px',
};

export { PasswordRequirements };
