import { BankHolidayAvailabilityReducer } from './bankHolidayAvailability';
import { bankHolidaysListReducer } from './bankHolidaysList';
import { ChangePackagesStateReducer } from './changePackagesState';
import { DeletePaymentReducer } from './deletePayment';
import { EnterPaymentReducer } from './enterPayment';
import { AccountsReceivableReducer } from './getAccountsReceivable';
import { AvailablePaymentTypesReducer } from './getAvailablePaymentTypes';
import { getFinalPaymentDateValidationReducer } from './getFinalPaymentDateValidation';
import { GetLotPaymentsReducer } from './getLotPayments';
import { LotsForSettlementReducer } from './getLotsForSettlement';
import { PaymentAmountReducer } from './getPaymentAmount';
import { PaymentDetailsReducer } from './paymentDetails';
import { PaymentsListReducer } from './paymentsList';
import { PayoutRulesReducer } from './payoutRulesList';
import { ReportsTotalPaymentsListReducer } from './reportsTotalPaymentsList';
import { RequestLotsSettlementReducer } from './requestLotsSettlement';
import { RequestPaymentReducer } from './requestPayment';

const paymentsRootReducer = {
  requestPayment: RequestPaymentReducer,
  enterPaymentDetails: EnterPaymentReducer,
  paymentAmount: PaymentAmountReducer,
  availablePaymentTypes: AvailablePaymentTypesReducer,
  paymentDetails: PaymentDetailsReducer,
  deletePayment: DeletePaymentReducer,
  paymentsList: PaymentsListReducer,
  reportsTotalPaymentsList: ReportsTotalPaymentsListReducer,
  lotsForSettlement: LotsForSettlementReducer,
  requestLotsSettlement: RequestLotsSettlementReducer,
  lotPayments: GetLotPaymentsReducer,
  payoutRulesList: PayoutRulesReducer,
  accountsReceivable: AccountsReceivableReducer,
  bankHolidaysList: bankHolidaysListReducer,
  bankHolidayAvailability: BankHolidayAvailabilityReducer,
  getFinalPaymentDateValidation: getFinalPaymentDateValidationReducer,
  changePackagesState: ChangePackagesStateReducer,
};

export { paymentsRootReducer };
