import {
  clearAgreementsListActionType,
  getAgreementsListActionType,
  getAgreementsListFailureActionType,
  getAgreementsListSuccessActionType,
} from 'actions/Agreements/agreementsList';

const INITIAL_STATE = {
  isPending: false,
  errorMessage: null,
  agreements: [],
  limit: 50,
  page: 1,
  pages: null,
  company: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getAgreementsListActionType:
      return {
        ...state,
        isPending: true,
      };
    case getAgreementsListSuccessActionType:
      return {
        ...state,
        isPending: false,
        agreements: action.payload.agreements,
        pages: action.payload.pages,
        limit: action.payload.limit,
        page: action.payload.page,
        company: action.payload.company,
      };
    case getAgreementsListFailureActionType:
      return {
        ...state,
        isPending: false,
        errorMessage: action.payload.errorMessage,
        agreements: [],
        pages: null,
      };
    case clearAgreementsListActionType:
      return {
        isPending: false,
        errorMessage: null,
        agreements: [],
        limit: 50,
        page: 1,
        pages: null,
        company: null,
      };
    default:
      return state;
  }
};
