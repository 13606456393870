import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { requestPasswordReset } from 'actions/Users/requestPasswordReset';
import { showSnackbar } from 'actions/shared/snackbar';

import { successOptions } from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { USER } from 'shared/helpers/constants/users/userConstants';

import { FormWrapper } from '../components/FormWrapper/FormWrapper';
import { ResetPasswordRequestForm } from '../components/ResetPasswordRequestForm/ResetPasswordRequestForm';

class ResetPasswordRequestBase extends Component {
  submit = ({ email }) =>
    this.props
      .requestPasswordReset(email)
      .then(() => this.props.history.push('/'))
      .then(() => this.props.showSnackbar(successOptions, USER.PASSWORD_RESET_REQUEST_SUCCESS));

  render() {
    return (
      <FormWrapper>
        <ResetPasswordRequestForm onSubmit={this.submit} />
      </FormWrapper>
    );
  }
}

const mapDispatchToProps = {
  requestPasswordReset,
  showSnackbar,
};

const ResetPasswordRequest = withRouter(
  connect(null, mapDispatchToProps)(ResetPasswordRequestBase),
);

export { ResetPasswordRequest, ResetPasswordRequestBase };
