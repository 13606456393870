import { connect } from 'react-redux';

import { markAsRead } from 'actions/Notifications/markAsRead';
import { getNotification } from 'actions/Notifications/notificationDetails';
import { getNotifications, clearNotificationsList } from 'actions/Notifications/notificationsList';

import { selector } from 'shared/components/Search/components/SearchForm/SearchForm';
import { NOTIFICATION } from 'shared/helpers/constants/notifications/notificationConstants';
import { toCamelCase } from 'shared/helpers/parsers/text';

import { NotificationsList } from './NotificationsList';

const mapStateToProps = ({ auth, router, notificationsList, notificationDetails, ...state }) => ({
  auth,
  router,
  notificationsList,
  notificationDetails,
  searchFilter: selector(state, `${toCamelCase(NOTIFICATION.NOTIFICATIONS)}.searchInput`),
});

const mapDispatchToProps = {
  getNotifications,
  clearNotificationsList,
  getNotification,
  markAsRead,
};

export const ConnectedNotificationsList = connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotificationsList);
