const createMultipleFilesFormData = ({ files, existingLotFiles }) => {
  const data = new FormData();

  Object.keys(files).forEach(file => {
    if (files[file] !== null) {
      files[file].forEach(item => {
        data.append(file, item);
      });
    }
  });

  if (existingLotFiles) {
    Object.keys(existingLotFiles).forEach(file => {
      if (existingLotFiles[file] !== null) {
        existingLotFiles[file].forEach(item => {
          data.append(`${file}Ids`, item);
        });
      }
    });
  }

  return data;
};

export { createMultipleFilesFormData };
