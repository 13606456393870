import styled from 'styled-components';

import { LinkButton } from 'shared/components/Buttons';
import { StatusIndicator } from 'shared/components/StatusIndicator/StatusIndicator';
import { media } from 'shared/helpers/styling/styling';

export const FilesWrapper = styled.div`
  display: grid;
  grid-template-columns: 40px 1fr;

  ${media.mobile`margin-top: 8px;`}
`;

export const FileWrapper = styled.div`
  margin-top: 7px;

  & > * {
    margin-top: 7px;
  }

  ${media.mobile`width: calc(100% - 42px);`};
`;

export const FileActions = styled.div`
  display: flex;
  align-items: center;
  margin-top: -8px !important;
`;

export const StyledLinkButton = styled(LinkButton)`
  margin-right: 10px;
  height: 100%;
  margin-top: 8px;
`;

export const NoteLabel = styled(StatusIndicator)`
  margin: 0px 0 10px;
`;

export const CloseButton = styled.div`
  display: flex;
  align-items: flex-start;
  cursor: pointer;
`;
