import {
  clearReportsTotalPaymentsActionType,
  getReportsTotalPaymentsActionType,
  getReportsTotalPaymentsSuccessActionType,
  getReportsTotalPaymentsFailureActionType,
} from 'actions/Payments/reportsTotalPaymentsList';

import dateAdapter from 'utils/date/dateAdapter';

const INITIAL_STATE = {
  isPending: false,
  payments: {},
  filters: {
    status: [],
    paymentType: [],
    fundsType: [],
    query: '',
    isPaid: true,
    paidAtStart: dateAdapter(),
    paidAtEnd: dateAdapter(),
  },
  pagination: { limit: 50, page: 1 },
};

const ReportsTotalPaymentsListReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getReportsTotalPaymentsActionType:
      return {
        ...state,
        filters: action.payload.filters,
        pagination: action.payload.pagination,
        isPending: true,
      };
    case getReportsTotalPaymentsSuccessActionType:
      return {
        ...state,
        isPending: false,
        payments: action.payload.payments,
      };
    case getReportsTotalPaymentsFailureActionType:
      return {
        ...state,
        isPending: false,
      };
    case clearReportsTotalPaymentsActionType:
      return {
        ...INITIAL_STATE,
        filters: state.filters,
      };
    default:
      return state;
  }
};

export { ReportsTotalPaymentsListReducer };
