import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { Field, getFormValues, reduxForm } from 'redux-form';

import { clearCompanyDetails, getCompany } from 'actions/Companies/companyDetails';
import { getCompaniesBySearchStrUserType } from 'actions/Companies/filterCompanies';
import { openModal } from 'actions/shared/modal';

import {
  FieldAttachment,
  FieldInput,
  FieldRadioButton,
  FieldSearch,
  FieldTextarea,
} from 'shared/components/Fields';
import { Form, Section } from 'shared/components/Form';
import { globalRefiningGroupUserTypes } from 'shared/helpers/constants/companies/globalRefiningGroup';
import { FILES_CONSTANTS } from 'shared/helpers/constants/filesConstants';
import { cancelModal } from 'shared/helpers/constants/modalConstants';
import { NOTE } from 'shared/helpers/constants/noteConstants';
import { USER } from 'shared/helpers/constants/users/userConstants';
import { isCustomerUser, isBuyerUser } from 'shared/helpers/matchers/checkUserType';
import { normalize } from 'shared/helpers/parsers/boolean';
import { checkTrim } from 'shared/helpers/parsers/text';
import { asyncValidate } from 'shared/helpers/validations/user/asyncValidate';
import { validate } from 'shared/helpers/validations/user/userValidations';

import { goBackOrTo } from 'utils/history';

import { SelectLocation } from './components/SelectLocation/SelectLocation';
import { SelectUserRole } from './components/SelectUserRole/SelectUserRole';

const formName = 'UserForm';

function UserForm({
  userDetails,
  handleSubmit,
  isPending,
  submitting,
  editMode,
  form,
  invalid,
  blockSubmit,
  change,
  checkNotes,
  match: {
    params: { companyId, userId },
  },
  ...props
}) {
  const [notes, setNotes] = useState(null);
  const dispatch = useDispatch();
  const { companyDetails, auth } = useSelector(({ companyDetails, auth }) => ({
    companyDetails,
    auth,
  }));
  const values = useSelector(state => getFormValues(formName)(state));
  const isCheckEmailPending = useSelector(state => state.checkEmail.isPending);
  const userType = values?.userType?.value;
  const relatedCompany = values?.relatedCompany?.value;
  const isGlobalUser = globalRefiningGroupUserTypes.includes(userType);
  const isBuyer = isBuyerUser(userType);

  const {
    user: { relatedCompany: userRelatedCompany },
  } = auth;

  const onCancel = useCallback(() => {
    dispatch(
      openModal(cancelModal, () => {
        goBackOrTo('/users');
      }),
    );
  }, [dispatch, openModal]);

  const onChange = useCallback(
    ({ currentTarget: { value } }) => {
      setNotes(value);
      checkNotes(value);
    },
    [checkNotes],
  );

  const getRelatedCompanies = useCallback(
    async input => {
      if (!input) {
        return {
          options: [],
        };
      }

      return dispatch(getCompaniesBySearchStrUserType(input, userType)).then(companies => ({
        options: companies.options,
      }));
    },
    [dispatch, userType],
  );

  useEffect(() => {
    if (!companyId) return;

    dispatch(getCompany(companyId)).then(({ _id, companyName }) => {
      change('relatedCompany', {
        value: _id,
        label: companyName,
      });
    });

    return () => dispatch(clearCompanyDetails);
  }, [dispatch, companyId]);

  useEffect(() => {
    if ((!isGlobalUser && !isBuyer) || userId) return;

    change('relatedCompany', {
      label: userRelatedCompany.companyName,
      value: userRelatedCompany._id,
    });
  }, [
    change,
    isGlobalUser,
    userId,
    userRelatedCompany._id,
    userRelatedCompany.companyName,
    userType,
  ]);

  const onRelatedCompanyChange = useCallback(() => {
    change('location', null);
  }, [change]);

  const renderCustomerUserOptions = () =>
    isCustomerUser(userType) && (
      <>
        <Field
          name="databaseAccess"
          component={FieldRadioButton}
          label={USER.DATABASE_ACCESS}
          field="required"
          normalize={normalize}
        />
        <Field
          name="purchasingAppAccess"
          component={FieldRadioButton}
          label={USER.PURCHASING_APP_ACCESS}
          field="required"
          normalize={normalize}
        />
      </>
    );

  return (
    <Form
      header={USER.USER_INFO}
      onSubmit={handleSubmit}
      onCancel={onCancel}
      loaded={!isPending && !submitting}
      submitDisabled={invalid || blockSubmit || submitting || isCheckEmailPending}
    >
      <Section template={['firstName lastName', 'email title']}>
        <Field name="firstName" component={FieldInput} label={USER.FIRST_NAME} field="required" />
        <Field name="lastName" component={FieldInput} label={USER.LAST_NAME} field="required" />
        <Field name="email" component={FieldInput} label={USER.EMAIL} field="required" lazyError />
        <Field name="title" component={FieldInput} label={USER.TITLE} />
      </Section>
      <SelectUserRole
        userTypeSelectDisabled={!userDetails}
        userType={userType}
        form={form}
        editMode={editMode}
        companyDetails={companyDetails}
        change={change}
      >
        {renderCustomerUserOptions()}
      </SelectUserRole>
      <Section header={USER.RELATED_COMPANY} template={['relatedCompany location']} unwrap>
        <Field
          name="relatedCompany"
          component={FieldSearch}
          disabled={!userType || isGlobalUser || isBuyer || companyId || editMode}
          label={USER.RELATED_COMPANY}
          field="required"
          getOptions={getRelatedCompanies}
          onChange={onRelatedCompanyChange}
        />
        <SelectLocation name="location" change={change} relatedCompany={relatedCompany} />
      </Section>
      <Section header={USER.CONTACT} template={['officePhone mobilePhone', 'phoneExtension .']}>
        <Field name="officePhone" component={FieldInput} label={USER.OFFICE_PHONE} />
        <Field
          name="mobilePhone"
          component={FieldInput}
          label={USER.MOBILE_PHONE}
          field="required"
        />
        <Field name="phoneExtension" component={FieldInput} label={USER.PHONE_EXTENSION} />
      </Section>
      <Section header={USER.NOTE} template={['note attachment']} render={!editMode}>
        <Field name="note" component={FieldTextarea} label={NOTE.NOTE} onChange={onChange} />
        {notes && checkTrim(notes) && (
          <Field
            multiple
            id="file"
            name="attachment"
            component={FieldAttachment}
            label={FILES_CONSTANTS.ATTACHMENTS}
            value={null}
            notes={notes}
            form={form}
            props={props}
          />
        )}
      </Section>
    </Form>
  );
}

const ConnectedUserForm = compose(
  withRouter,
  reduxForm({
    form: formName,
    validate,
    asyncValidate,
    asyncBlurFields: ['email'],
  }),
)(UserForm);

export { ConnectedUserForm };
