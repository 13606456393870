import {
  reactivateCompanyActionType,
  reactivateCompanySuccessActionType,
  reactivateCompanyFailureActionType,
} from 'actions/Companies/reactivateCompany';

const INITIAL_STATE = {
  isPending: false,
};

const reactivateCompanyReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case reactivateCompanyActionType:
      return { isPending: true };
    case reactivateCompanySuccessActionType:
    case reactivateCompanyFailureActionType:
      return { isPending: false };
    default:
      return state;
  }
};

export { reactivateCompanyReducer };
