import propTypes from 'prop-types';
import React from 'react';

const PrintIntent = ({
  intent,
  fallback,
  label,
  children,
  pkg = 'com.grgportal.zebraprinter.prod',
}) => {
  const [scheme, target] = intent.split('://');
  const fullFallback = fallback.includes('://')
    ? fallback
    : `${process.env.REACT_APP_API}${fallback}`;
  const appUrl = process.env.REACT_APP_HOST;

  return (
    <a
      href={`
        intent://${target}
        #Intent;
          scheme=${scheme};
          package=${pkg};
          S.browser_fallback_url=${appUrl}/manual-download?link=${encodeURIComponent(fullFallback)};
        end;
        `.replace(/\s/g, '')}
    >
      {children || label}
    </a>
  );
};

PrintIntent.propTypes = {
  intent: propTypes.string.isRequired,
  fallback: propTypes.string.isRequired,
  label: propTypes.string,
};

export { PrintIntent };
