import {
  deleteCalendarNoteActionType,
  deleteCalendarNoteFailureActionType,
  deleteCalendarNoteSuccessActionType,
} from 'actions/Shipments/deleteCalendarNote';

const INITIAL_STATE = {
  isPending: false,
};

const DeleteCalendarNoteReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case deleteCalendarNoteActionType:
      return { isPending: true };
    case deleteCalendarNoteSuccessActionType:
    case deleteCalendarNoteFailureActionType:
      return { isPending: false };
    default:
      return state;
  }
};

export { DeleteCalendarNoteReducer };
