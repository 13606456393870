import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getLotsSectionActionType = 'GET_LOTS_SECTION';
function getLotsSectionAction() {
  return {
    type: getLotsSectionActionType,
  };
}

const getLotsSectionSuccessActionType = 'GET_LOTS_SECTION_SUCCESS';
function getLotsSectionSuccessAction(lots, sectionType) {
  return {
    type: getLotsSectionSuccessActionType,
    payload: {
      lots,
      sectionType,
      items: lots,
    },
  };
}

const getLotsSectionFailureActionType = 'GET_LOTS_SECTION_FAILURE';
function getLotsSectionFailureAction(errorMessage) {
  return {
    type: getLotsSectionFailureActionType,
    payload: {
      errorMessage,
    },
  };
}

const clearLotsSectionListActionType = 'CLEAR_LOTS_SECTION_ACTION';
function clearLotsSectionListAction(sectionType) {
  return {
    type: clearLotsSectionListActionType,
    payload: {
      sectionType,
    },
  };
}

const appendLotsSectionSuccessActionType = 'APPEND_LOTS_SECTION_SUCCESS';
function appendLotsSectionSuccessAction(lots, sectionType) {
  return {
    type: appendLotsSectionSuccessActionType,
    payload: {
      lots,
      sectionType,
    },
  };
}

const countLotsActionType = 'COUNT_LOTS_SECTION';
function countLotsAction() {
  return {
    type: countLotsActionType,
  };
}

const countLotsSuccessActionType = 'COUNT_LOTS_SUCCESS';
function countLotsSuccessAction(count) {
  return {
    type: countLotsSuccessActionType,
    payload: {
      count,
    },
  };
}

const countLotsFailureActionType = 'COUNT_LOTS_FAILURE';
function countLotsFailureAction(errorMessage) {
  return {
    type: countLotsFailureActionType,
    payload: {
      errorMessage,
    },
  };
}

const clearLotsCountActionType = 'CLEAR_LOTS_COUNT_ACTION';
function clearLotsCountAction() {
  return {
    type: clearLotsCountActionType,
  };
}

function getLotsSection(companyId, sectionType, limit, page) {
  return dispatch => {
    dispatch(getLotsSectionAction());

    return axios
      .get(`${API_HOST_AUTH}/lots/${companyId}/${sectionType}/${limit}/${page}`)
      .then(res => {
        dispatch(getLotsSectionSuccessAction(res.data, sectionType));
      })
      .catch(() => {
        dispatch(getLotsSectionFailureAction(''));
      });
  };
}

function appendLotsSection(companyId, sectionType, limit, page) {
  return dispatch => {
    dispatch(getLotsSectionAction());

    return axios
      .get(`${API_HOST_AUTH}/lots/${companyId}/${sectionType}/${limit}/${page}`)
      .then(res => {
        dispatch(appendLotsSectionSuccessAction(res.data, sectionType));
      })
      .catch(() => {
        dispatch(getLotsSectionFailureAction(''));
      });
  };
}

function countLots() {
  return dispatch => {
    dispatch(countLotsAction());

    return axios
      .get(`${API_HOST_AUTH}/lots/count/sections`)
      .then(res => {
        dispatch(countLotsSuccessAction(res));
      })
      .catch(() => {
        dispatch(countLotsFailureAction(''));
      });
  };
}

function clearLotsSectionList(sectionType) {
  return dispatch => {
    dispatch(clearLotsSectionListAction(sectionType));
  };
}

function clearLotsCount() {
  return dispatch => {
    dispatch(clearLotsCountAction());
  };
}

export {
  clearLotsSectionListActionType,
  getLotsSectionActionType,
  getLotsSectionSuccessActionType,
  getLotsSectionFailureActionType,
  appendLotsSectionSuccessActionType,
  countLotsActionType,
  countLotsSuccessActionType,
  countLotsFailureActionType,
  clearLotsCountActionType,
  getLotsSection,
  appendLotsSection,
  countLots,
  clearLotsSectionList,
  clearLotsCount,
};
