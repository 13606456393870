import PropTypes from 'prop-types';
import { useState } from 'react';

import { ProgressCounter } from 'shared/components/ProgressCounter/ProgressCounter';
import { packageType } from 'shared/helpers/constants/packages/packageType';
import { countObjectsWithFilledFields } from 'shared/helpers/parsers/countObjectsWithFilledFields';

import { SamplePackagesListTemplate } from '../SamplePackagesListTemplate/SamplePackagesListTemplate';
import { SamplePackagesListCollapse, SamplePackagesTitle } from './StyledSamplePackagesList';

const SamplePackagesList = ({ packages, formErrors, withoutRequiredFields }) => {
  const [focusedItem, setFocusedItem] = useState('0');
  const [focusedRow, setFocusedRow] = useState(0);
  const [focusedCol, setFocusedCol] = useState(null);

  const handleToggle = index =>
    focusedItem === index ? setFocusedItem('0') : setFocusedItem(index);

  const getFieldName = (index, subIndex) => `packages[${index}].samplePackages[${subIndex}]`;

  return packages.map((pkg, index) => {
    if (pkg.packageType?.value !== packageType.sample) {
      return;
    }

    return (
      <div key={index}>
        <SamplePackagesListCollapse
          onClick={() => handleToggle(index)}
          isOpen={focusedItem === index}
          data-testid="packagesListCollapse"
        >
          <SamplePackagesTitle>Package {index + 1}</SamplePackagesTitle>
          {!withoutRequiredFields && (
            <ProgressCounter
              currentNumber={countObjectsWithFilledFields(pkg?.samplePackages || [], ['primaryId'])}
              quantity={pkg.assignedCats?.length}
            />
          )}
        </SamplePackagesListCollapse>
        {focusedItem === index &&
          (pkg.assignedCats || []).map((_, subIndex) => (
            <SamplePackagesListTemplate
              key={subIndex}
              samplePkgData={pkg?.samplePackages?.[subIndex]}
              fieldName={getFieldName(index, subIndex)}
              formErrors={formErrors}
              index={index}
              subIndex={subIndex}
              assignedCatsQuantity={pkg.assignedCats.length}
              focusedRow={focusedRow}
              setFocusedRow={setFocusedRow}
              focusedCol={focusedCol}
              setFocusedCol={setFocusedCol}
              packages={packages}
              withoutRequiredFields={withoutRequiredFields}
            />
          ))}
      </div>
    );
  });
};

SamplePackagesList.propTypes = {
  packages: PropTypes.arrayOf(PropTypes.object),
  formErrors: PropTypes.object,
  withoutRequiredFields: PropTypes.bool,
};

export { SamplePackagesList };
