import React from 'react';
import { withRouter } from 'react-router';
import { Field, reduxForm } from 'redux-form';

import { CancelButton } from 'shared/components/Buttons';
import { Button } from 'shared/components/Buttons/Button';
import { FieldInput } from 'shared/components/Fields';
import { ACCESS } from 'shared/helpers/constants/access/accessConstants';
import { validate } from 'shared/helpers/validations/user/emailValidation';

import { StyledResetPasswordButtons } from './StyledResetPasswordRequestForm';

const ResetPasswordRequestFormBase = props => {
  const navigateToLoginPage = () => props.history.push('/');

  return (
    <form onSubmit={props.handleSubmit}>
      <Field
        name="email"
        type="email"
        component={FieldInput}
        label="Email"
        labelWidth={100}
        lazyError
      />
      <StyledResetPasswordButtons>
        <CancelButton onClick={navigateToLoginPage} id="cancelPasswordReset" />
        <Button id="submitButton" type="submit" padding="0 25px" disabled={props.invalid}>
          {ACCESS.SEND_PASSWORD_RESET_EMAIL}
        </Button>
      </StyledResetPasswordButtons>
    </form>
  );
};

const ResetPasswordRequestForm = withRouter(
  reduxForm({
    form: 'ResetPasswordRequestForm',
    validate,
  })(ResetPasswordRequestFormBase),
);

export { ResetPasswordRequestForm, ResetPasswordRequestFormBase };
