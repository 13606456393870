import PropTypes from 'prop-types';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getDeliveriesToTransfer } from 'actions/Deliveries/getDeliveriesToTransfer';
import { showSnackbar } from 'actions/shared/snackbar';

import { Form } from 'shared/components/Formik/Form';
import { useWizardContext } from 'shared/components/Formik/Wizard/WizardContext';
import { FormWrapper } from 'shared/components/Formik/Wizard/components/FormWrapper/FormWrapper';
import { DELIVERIES } from 'shared/helpers/constants/deliveries/deliveriesConstants';
import { dangerOptions } from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

import { goBackOrTo } from 'utils/history';

import { TransferDeliveriesFormContent } from './components/TransferDeliveriesFormContent/TransferDeliveriesFormContent';
import { TransferLotsFormContent } from './components/TransferLotsFormContent/TransferLotsFormContent';

const TransferOuncesForm = ({ formikContext, stepErrors }) => {
  const dispatch = useDispatch();
  const { hedgeId } = useParams();
  const { currentStep, CustomActions, onCancel } = useWizardContext();
  const { isValid, isSubmitting, handleSubmit, setFieldValue, values } = formikContext;

  const { isPending, deliveries } = useSelector(({ deliveriesToTransfer }) => deliveriesToTransfer);
  const lotsToTransfer = useSelector(({ lotsToTransfer }) => lotsToTransfer);

  useEffect(() => {
    try {
      dispatch(getDeliveriesToTransfer({ hedgeId }));
    } catch (err) {
      goBackOrTo(`/hedges/${hedgeId}`);
      dispatch(showSnackbar(dangerOptions, err.message));
    }
  }, []);

  useEffect(() => {
    values.relatedLot && setFieldValue('relatedLot', '');
  }, [values.delivery]);

  const FormCustomActions = useCallback(
    p => <CustomActions {...p} stepErrors={stepErrors} />,
    [stepErrors],
  );

  return (
    <Form
      onSubmit={handleSubmit}
      onCancel={onCancel}
      submitDisabled={!isValid || isSubmitting}
      header={[DELIVERIES.SELECT_DELIVERY, DELIVERIES.SELECT_LOT][currentStep - 1]}
      CustomActions={FormCustomActions}
      context={formikContext}
      loaded={!isPending}
    >
      <FormWrapper pageIndex={1}>
        <TransferDeliveriesFormContent deliveries={deliveries.docs} />
      </FormWrapper>
      <FormWrapper pageIndex={2}>
        <TransferLotsFormContent deliveries={deliveries.docs} lots={lotsToTransfer.lots} />
      </FormWrapper>
    </Form>
  );
};

TransferOuncesForm.propTypes = {
  formikContext: PropTypes.object,
  stepErrors: PropTypes.array,
};

export { TransferOuncesForm };
