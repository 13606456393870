import {
  enterPaymentActionType,
  enterPaymentSuccessActionType,
  enterPaymentFailureActionType,
} from 'actions/Payments/enterPayment';

const INITIAL_STATE = {
  isPending: false,
};

const EnterPaymentReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case enterPaymentActionType:
      return {
        isPending: true,
      };
    case enterPaymentSuccessActionType:
    case enterPaymentFailureActionType:
      return {
        isPending: false,
      };
    default:
      return state;
  }
};

export { EnterPaymentReducer };
