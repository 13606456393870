import { messages } from 'shared/helpers/constants/locations/createLocationConstants';

const validateLotSuffix = (values, current, props) => {
  const formValues = props.values.locations;
  const lotSuffixes = formValues
    .map(({ lotSuffix }, index) => (current === index ? null : lotSuffix))
    .filter(lotSuffix => !!lotSuffix);

  if (lotSuffixes.includes(values.lotSuffix)) {
    return {
      lotSuffix: messages.INVALID_LOT_SUFFIX_UNIQUE,
    };
  }

  return {};
};

export { validateLotSuffix };
