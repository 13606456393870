import { ASSAY } from 'shared/helpers/constants/lots/assayConstants';

const containedMetalsFieldsConfig = [
  {
    name: 'platinumContainedOz',
    type: 'number',
    label: ASSAY.PLATINUM_CONTAINED_OZ,
    suffix: ASSAY.TROY_OZ,
  },
  {
    name: 'palladiumContainedOz',
    type: 'number',
    label: ASSAY.PALLADIUM_CONTAINED_OZ,
    suffix: ASSAY.TROY_OZ,
  },
  {
    name: 'rhodiumContainedOz',
    type: 'number',
    label: ASSAY.RHODIUM_CONTAINED_OZ,
    suffix: ASSAY.TROY_OZ,
  },
];

const containedAssaysFieldsConfig = [
  {
    name: 'platinumAssay',
    type: 'number',
    label: ASSAY.PLATINUM_ASSAY,
    suffix: ASSAY.PPM,
  },
  {
    name: 'palladiumAssay',
    type: 'number',
    label: ASSAY.PALLADIUM_ASSAY,
    suffix: ASSAY.PPM,
  },
  {
    name: 'rhodiumAssay',
    type: 'number',
    label: ASSAY.RHODIUM_ASSAY,
    suffix: ASSAY.PPM,
  },
];

const returnableOuncesFieldsConfig = [
  {
    name: 'platinumReturnableOz',
    type: 'number',
    label: ASSAY.PLATINUM_RETURNABLE_OZ,
    suffix: ASSAY.TROY_OZ,
  },
  {
    name: 'palladiumReturnableOz',
    type: 'number',
    label: ASSAY.PALLADIUM_RETURNABLE_OZ,
    suffix: ASSAY.TROY_OZ,
  },
  {
    name: 'rhodiumReturnableOz',
    type: 'number',
    label: ASSAY.RHODIUM_RETURNABLE_OZ,
    suffix: ASSAY.TROY_OZ,
  },
];

export { containedAssaysFieldsConfig, containedMetalsFieldsConfig, returnableOuncesFieldsConfig };
