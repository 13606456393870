import PropTypes from 'prop-types';
import * as React from 'react';
import { Field } from 'redux-form';
import styled from 'styled-components';

import { AddButton } from 'shared/components/Buttons/AddButton/AddButton';
import { IconButton } from 'shared/components/Buttons/IconButton/IconButton';
import { FieldInput } from 'shared/components/Fields/FieldInput/FieldInput';
import { Section } from 'shared/components/Form/Section/Section';
import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';

const StyledHeader = styled.div`
  padding: 0 37px;
  background-color: var(--shark);
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  height: 60px;
  color: var(--alto);
`;

const StyledDiv = styled(AddButton)`
  div {
    padding: 20px 0;
    margin: 0;
  }
`;

const RemoveAction = (fields, index) => {
  const handleRemove = () => fields.remove(index);
  return <IconButton onClick={handleRemove} icon="icon-cancel" />;
};

const LotsGroupFields = ({ title, fields, buttonLabel, sectionTitle, commonComponent }) => {
  const handleAdd = () => fields.push();

  const handleSetRequired = fieldValue =>
    fieldValue !== undefined && fieldValue !== null && 'required';

  return (
    <>
      <StyledHeader>{title}</StyledHeader>
      {commonComponent && commonComponent()}
      {fields.map((field, index) => (
        <Section
          template={[`${field}.value ${field}.description`]}
          actions={RemoveAction(fields, index)}
          key={field}
          header={`${sectionTitle} ${index + 1}`}
        >
          <Field
            name={`${field}.value`}
            component={FieldInput}
            type="text"
            label={LOTS.VALUE}
            prefix="$"
          />
          <Field
            name={`${field}.description`}
            component={FieldInput}
            label={LOTS.DESCRIPTION}
            field={handleSetRequired(fields.get(index)?.value)}
          />
        </Section>
      ))}
      {fields.length < 10 && (
        <StyledDiv name="addButton" onClick={handleAdd}>
          {buttonLabel}
        </StyledDiv>
      )}
    </>
  );
};

LotsGroupFields.propTypes = {
  buttonLabel: PropTypes.string,
  filed: PropTypes.string,
  fields: PropTypes.object,
  label: PropTypes.string,
  sectionTitle: PropTypes.string,
  meta: PropTypes.object,
  title: PropTypes.string,
  commonComponent: PropTypes.element,
};

export { LotsGroupFields };
