import { REPORTS } from './reportsConstants';

const itemsTemplate = [
  {
    label: REPORTS.LAST_5_REPORTS,
    render: ({ name }) => name.replace(/\.[^/.]+$/, ''),
  },
  {
    label: REPORTS.GENERATED_BY,
    render: ({ uploadBy }) => uploadBy,
  },
];

export { itemsTemplate };
