import React, { Component } from 'react';
import { connect } from 'react-redux';

import { showSnackbar as showSnackbarAction } from 'actions/shared/snackbar';

import { UIAuthorization } from 'components/Authorization/UIAuthorization';
import { ModalCustom } from 'components/ModalCustom/ModalCustom';

import { Button } from 'shared/components/Buttons/Button';
import { ExtendableButton } from 'shared/components/Buttons/ExtendableButton/ExtendableButton';
import { RouterButton } from 'shared/components/Buttons/RouterButton/RouterButton';
import { DetailsHeader } from 'shared/components/Details/DetailsHeader';
import { assignedViewPrintLabRequestFormsAccess } from 'shared/helpers/accesses/packages/printLabRequestFormsAccess';
import { assignedViewPrintPackageLabelsAccess } from 'shared/helpers/accesses/packages/printPackageLabelsAccess';
import { generateShipmentDocuments } from 'shared/helpers/accesses/shipments/generateShipmentDocuments';
import { generateShipmentReportAccess } from 'shared/helpers/accesses/shipments/generateShipmentReportAccess';
import {
  updateIncomingShipmentAccess,
  updateOutgoingShipmentAccess,
  markAsSentAccess,
  deleteShipmentAccess,
  updatePackingListAccess,
} from 'shared/helpers/accesses/shipments/shipmentAccesses';
import { PACKAGES } from 'shared/helpers/constants/packages/packageConstants';
import {
  markAsSentConfigEnum,
  markAsSentConfig,
} from 'shared/helpers/constants/shipments/markAsSentConfig';
import { SHIPMENTS } from 'shared/helpers/constants/shipments/shipmentsConstants';
import { infoOptions } from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { isCustomerOrGrading } from 'shared/helpers/matchers/checkUserType';
import {
  isIncomingShipment,
  isOutgoingShipment,
} from 'shared/helpers/matchers/shipments/checkShipmentDirection';

import { relativeNavigator } from 'utils/relativeNavigator';

import { PrintLabRequestForms } from 'pages/Packages/components/PrintLabRequestForms/AssignedView/PrintLabRequestForms';
import { PrintPackageLabels } from 'pages/Packages/components/PrintPackageLabels/AssignedView/PrintPackageLabels';
import { DeleteShipment } from 'pages/Shipments/components/DeleteShipment/DeleteShipment';
import { GenerateShipmentDocuments } from 'pages/Shipments/components/GenerateShipmentDocuments/GenerateShipmentDocuments';
import { GenerateShipmentReport } from 'pages/Shipments/components/GenerateShipmentReport/GenerateShipmentReport';
import { MarkAsSent } from 'pages/Shipments/components/MarkAsSent/MarkAsSent';

class ShipmentMainDetailsLayout extends Component {
  constructor(props) {
    super(props);

    this.navigator = relativeNavigator(this.navigation)(props.history);
  }

  state = {
    displayModal: false,
    displayPrintLabRequestFormsModal: false,
  };

  navigation = [
    { from: /^\/shipments\/outgoing\/calendar\/.+$/, to: '/shipments/outgoing/calendar' },
    { from: /^\/shipments\/outgoing\/.+$/, to: '/shipments/outgoing' },
    { from: /^\/shipments\/.+$/, to: ({ shipment }) => `/shipments/${shipment.shipmentDirection}` },
    { from: /^\/lots\/list\/.+$/, to: ({ match }) => `/lots/list/${match.params.lotId}` },
  ];

  navigate = () =>
    this.navigator(this.props.location.pathname, {
      match: this.props.match,
      shipment: this.props.shipment,
    });

  modalToggle = () => {
    this.setState(prevState => ({
      displayModal: !prevState.displayModal,
    }));
  };

  togglePrintLabRequestFormsModal = () => {
    this.setState(state => ({
      displayPrintLabRequestFormsModal: !state.displayPrintLabRequestFormsModal,
    }));
  };

  get pathToRedirect() {
    const {
      shipment,
      auth: {
        user: { userType },
      },
    } = this.props;

    return isCustomerOrGrading(userType)
      ? `/shipments/${shipment._id}/update`
      : `/shipments/incoming/${shipment._id}/update`;
  }

  get markAsSentConfig() {
    const {
      shipment: {
        sentFromCompany: { materialCountRequired },
      },
      auth: {
        user: { userType },
      },
    } = this.props;

    if (materialCountRequired) {
      return markAsSentConfig(userType)[markAsSentConfigEnum.materialCountRequired];
    }

    return markAsSentConfig(userType)[markAsSentConfigEnum.default];
  }

  render() {
    const { auth, shipment, packagesList, showSnackbar, isSending } = this.props;
    const { displayModal } = this.state;

    return (
      <>
        <DetailsHeader status={shipment.status} header={SHIPMENTS.SHIPMENT}>
          <ExtendableButton label={SHIPMENTS.ACTIONS} disabled={isSending}>
            <UIAuthorization access={markAsSentAccess(auth, shipment, packagesList)}>
              <UIAuthorization access={isIncomingShipment(shipment.shipmentDirection)}>
                <MarkAsSent shipment={shipment} config={this.markAsSentConfig}>
                  {({ markAsSentShipment, label }) => (
                    <Button outline id="markAsSentShipment" onClick={markAsSentShipment}>
                      {label}
                    </Button>
                  )}
                </MarkAsSent>
              </UIAuthorization>
              <UIAuthorization access={isOutgoingShipment(shipment.shipmentDirection)}>
                {packagesList.all && packagesList.all.total !== 0 ? (
                  <RouterButton
                    id="markAsSentShipment"
                    path={`/shipments/outgoing/${shipment._id}/ship-and-print`}
                    label={SHIPMENTS.SHIP_AND_PRINT_SHIPMENT}
                  />
                ) : (
                  <Button
                    id="markAsSentShipment"
                    onClick={() => showSnackbar(infoOptions, SHIPMENTS.NO_OUTGOING_PACKAGES)}
                  >
                    {SHIPMENTS.SHIP_AND_PRINT_SHIPMENT}
                  </Button>
                )}
              </UIAuthorization>
            </UIAuthorization>
            <UIAuthorization access={generateShipmentReportAccess(auth, shipment)}>
              <GenerateShipmentReport shipment={shipment}>
                {({ onClick }) => (
                  <Button outline id="generateShipmentReport" onClick={onClick}>
                    {SHIPMENTS.GENERATE_REPORT}
                  </Button>
                )}
              </GenerateShipmentReport>
            </UIAuthorization>
            <UIAuthorization access={generateShipmentDocuments(auth, shipment)}>
              <GenerateShipmentDocuments shipment={shipment}>
                {({ onClick }) => (
                  <Button outline id="generateShipmentDocuments" onClick={onClick}>
                    {SHIPMENTS.GENERATE_DOCUMENTS}
                  </Button>
                )}
              </GenerateShipmentDocuments>
            </UIAuthorization>
            <UIAuthorization access={assignedViewPrintPackageLabelsAccess(auth, shipment)}>
              <Button outline id="printPackageLabels" onClick={this.modalToggle}>
                {PACKAGES.PRINT_PACKAGE_LABELS}
              </Button>
            </UIAuthorization>
            <UIAuthorization access={assignedViewPrintLabRequestFormsAccess(auth, packagesList)}>
              <Button id="printLabRequestForms" onClick={this.togglePrintLabRequestFormsModal}>
                {PACKAGES.PRINT_LAB_REQUEST_FORMS}
              </Button>
            </UIAuthorization>
            <UIAuthorization access={updatePackingListAccess(auth, shipment)}>
              <RouterButton
                id="openUpdateIncomingShipment"
                path={`/shipments/edit-incoming-wizard/${this.props.shipment._id}`}
                label={SHIPMENTS.EDIT_PACKING_LIST}
              />
            </UIAuthorization>

            <UIAuthorization access={updateIncomingShipmentAccess(auth, shipment)}>
              <RouterButton
                id="openUpdateIncomingShipment"
                path={this.pathToRedirect}
                label={SHIPMENTS.EDIT_SHIPMENT}
              />
            </UIAuthorization>
            {isOutgoingShipment(shipment.shipmentDirection) && (
              <UIAuthorization access={updateOutgoingShipmentAccess(auth, shipment)}>
                <RouterButton
                  id="openUpdateOutgoingShipment"
                  path={`/shipments/${shipment.shipmentDirection}/${shipment._id}/update`}
                  label={SHIPMENTS.EDIT_SHIPMENT}
                />
              </UIAuthorization>
            )}
            <UIAuthorization access={deleteShipmentAccess(auth, shipment)}>
              <DeleteShipment shipment={shipment} onDelete={this.navigate}>
                {({ deleteShipment }) => (
                  <Button outline id="deleteShipment" color="danger" onClick={deleteShipment}>
                    {SHIPMENTS.DELETE_SHIPMENT}
                  </Button>
                )}
              </DeleteShipment>
            </UIAuthorization>
          </ExtendableButton>
        </DetailsHeader>
        <ModalCustom isOpen={displayModal} modalClose={this.modalToggle}>
          <PrintPackageLabels />
        </ModalCustom>
        <PrintLabRequestForms
          open={this.state.displayPrintLabRequestFormsModal}
          modalClose={this.togglePrintLabRequestFormsModal}
        />
      </>
    );
  }
}

const mapDispatchToProps = {
  showSnackbar: showSnackbarAction,
};

const ShipmentMainDetails = connect(null, mapDispatchToProps)(ShipmentMainDetailsLayout);

export { ShipmentMainDetails, ShipmentMainDetailsLayout };
