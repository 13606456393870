import styled from 'styled-components';

import { Checkbox, CheckboxWrapper } from 'shared/components/Fields/FieldCheckbox/FieldCheckbox';
import { cssVar, pxToRem, media } from 'shared/helpers/styling/styling';

export const StyledPrintLabRequestFormsActions = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 ${pxToRem(5)};

  ${media.phone`
    Button {
      flex-direction: column;
      margin-bottom: ${pxToRem(10)};
      &:last-child {
        margin-bottom: 0;
      }
    }
  `}
`;

export const LabPolicy = styled.div`
  padding: ${pxToRem(20)};
  display: flex;
  align-items: center;

  ${media.phone`
    padding-left: ${pxToRem(5)};
  `}
`;

export const LabPolicyInfo = styled.div`
  display: block;
  color: ${cssVar('alto')};
  font-size: ${pxToRem(14)};
  margin: 0;
  padding-left: ${pxToRem(20)};
`;

export const StyledCheckbox = styled(Checkbox)`
  display: flex;
  align-items: center;
  cursor: pointer;

  ${CheckboxWrapper} {
    padding-left: ${pxToRem(10)};
  }
`;
