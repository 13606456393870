import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getHedgeTotalValueActionType = 'GET_HEDGE_TOTAL_VALUE';
const getHedgeTotalValueAction = () => ({
  type: getHedgeTotalValueActionType,
});

const getHedgeTotalValueSuccessActionType = `${getHedgeTotalValueActionType}_SUCCESS`;
const getHedgeTotalValueSuccessAction = hedgeTotal => ({
  type: getHedgeTotalValueSuccessActionType,
  payload: {
    hedgeTotal,
  },
});

const getHedgeTotalValueFailureActionType = `${getHedgeTotalValueActionType}_FAILURE`;
const getHedgeTotalValueFailureAction = () => ({
  type: getHedgeTotalValueFailureActionType,
});

const clearHedgeTotalValueActionType = 'CLEAR_HEDGE_TOTAL_VALUE';
const clearHedgeTotalValue = () => ({
  type: clearHedgeTotalValueActionType,
});

const getHedgeTotalValue = () => dispatch => {
  dispatch(getHedgeTotalValueAction());

  return axios
    .get(`${API_HOST_AUTH}/hedges/hedge-total`)
    .then(({ data }) => {
      dispatch(getHedgeTotalValueSuccessAction(data.hedgeTotal));
      return data;
    })
    .catch(err => Promise.reject(dispatch(getHedgeTotalValueFailureAction(err))));
};

export {
  getHedgeTotalValueActionType,
  getHedgeTotalValueAction,
  getHedgeTotalValueSuccessActionType,
  getHedgeTotalValueSuccessAction,
  getHedgeTotalValueFailureActionType,
  getHedgeTotalValueFailureAction,
  getHedgeTotalValue,
  clearHedgeTotalValueActionType,
  clearHedgeTotalValue,
};
