import { UPDATE_MARKET_INDICATORS } from 'shared/helpers/constants/systemSettings/marketIndicators/updateMarketIndicators';
import { replaceEmpty } from 'shared/helpers/parsers/text';
import { commaValidator } from 'shared/helpers/validations/commaValidator';
import { PRICE_REGEX } from 'shared/helpers/validations/regularExpressions';

function validate({
  hedgingPlatinumPrice,
  hedgingPalladiumPrice,
  hedgingRhodiumPrice,
  settlementPlatinumPrice,
  settlementPalladiumPrice,
  settlementRhodiumPrice,
}) {
  const errors = {};

  if (!hedgingPlatinumPrice) {
    errors.hedgingPlatinumPrice = UPDATE_MARKET_INDICATORS.HEDGING_PLATINUM_PRICE_EMPTY;
  } else if (hedgingPlatinumPrice <= 0) {
    errors.hedgingPlatinumPrice = UPDATE_MARKET_INDICATORS.HEDGING_PLATINUM_PRICE_NOT_POSITIVE;
  } else if (
    (hedgingPlatinumPrice && !PRICE_REGEX.test(hedgingPlatinumPrice)) ||
    replaceEmpty(hedgingPlatinumPrice, '').length > 10
  ) {
    errors.hedgingPlatinumPrice = UPDATE_MARKET_INDICATORS.HEDGING_PLATINUM_PRICE_FORMAT;
  }

  if (!hedgingPalladiumPrice) {
    errors.hedgingPalladiumPrice = UPDATE_MARKET_INDICATORS.HEDGING_PALLADIUM_PRICE_EMPTY;
  } else if (hedgingPalladiumPrice <= 0) {
    errors.hedgingPalladiumPrice = UPDATE_MARKET_INDICATORS.HEDGING_PALLADIUM_PRICE_NOT_POSITIVE;
  } else if (
    (hedgingPalladiumPrice && !PRICE_REGEX.test(hedgingPalladiumPrice)) ||
    replaceEmpty(hedgingPalladiumPrice, '').length > 10
  ) {
    errors.hedgingPalladiumPrice = UPDATE_MARKET_INDICATORS.HEDGING_PALLADIUM_PRICE_FORMAT;
  }

  if (!hedgingRhodiumPrice) {
    errors.hedgingRhodiumPrice = UPDATE_MARKET_INDICATORS.HEDGING_RHODIUM_PRICE_EMPTY;
  } else if (hedgingRhodiumPrice <= 0) {
    errors.hedgingRhodiumPrice = UPDATE_MARKET_INDICATORS.HEDGING_RHODIUM_PRICE_NOT_POSITIVE;
  } else if (
    (hedgingRhodiumPrice && !PRICE_REGEX.test(hedgingRhodiumPrice)) ||
    replaceEmpty(hedgingRhodiumPrice, '').length > 10
  ) {
    errors.hedgingRhodiumPrice = UPDATE_MARKET_INDICATORS.HEDGING_RHODIUM_PRICE_FORMAT;
  }

  if (!settlementPlatinumPrice) {
    errors.settlementPlatinumPrice = UPDATE_MARKET_INDICATORS.SETTLEMENT_PLATINUM_PRICE_EMPTY;
  } else if (settlementPlatinumPrice <= 0) {
    errors.settlementPlatinumPrice =
      UPDATE_MARKET_INDICATORS.SETTLEMENT_PLATINUM_PRICE_NOT_POSITIVE;
  } else if (
    (settlementPlatinumPrice && !PRICE_REGEX.test(settlementPlatinumPrice)) ||
    replaceEmpty(settlementPlatinumPrice, '').length > 10
  ) {
    errors.settlementPlatinumPrice = UPDATE_MARKET_INDICATORS.SETTLEMENT_PLATINUM_PRICE_FORMAT;
  }

  if (!settlementPalladiumPrice) {
    errors.settlementPalladiumPrice = UPDATE_MARKET_INDICATORS.SETTLEMENT_PALLADIUM_PRICE_EMPTY;
  } else if (settlementPalladiumPrice <= 0) {
    errors.settlementPalladiumPrice =
      UPDATE_MARKET_INDICATORS.SETTLEMENT_PALLADIUM_PRICE_NOT_POSITIVE;
  } else if (
    (settlementPalladiumPrice && !PRICE_REGEX.test(settlementPalladiumPrice)) ||
    replaceEmpty(settlementPalladiumPrice, '').length > 10
  ) {
    errors.settlementPalladiumPrice = UPDATE_MARKET_INDICATORS.SETTLEMENT_PALLADIUM_PRICE_FORMAT;
  }

  if (!settlementRhodiumPrice) {
    errors.settlementRhodiumPrice = UPDATE_MARKET_INDICATORS.SETTLEMENT_RHODIUM_PRICE_EMPTY;
  } else if (settlementRhodiumPrice <= 0) {
    errors.settlementRhodiumPrice = UPDATE_MARKET_INDICATORS.SETTLEMENT_RHODIUM_PRICE_NOT_POSITIVE;
  } else if (
    (settlementRhodiumPrice && !PRICE_REGEX.test(settlementRhodiumPrice)) ||
    replaceEmpty(settlementRhodiumPrice, '').length > 10
  ) {
    errors.settlementRhodiumPrice = UPDATE_MARKET_INDICATORS.SETTLEMENT_RHODIUM_PRICE_FORMAT;
  }

  commaValidator(
    {
      hedgingPlatinumPrice,
      hedgingPalladiumPrice,
      hedgingRhodiumPrice,
      settlementPlatinumPrice,
      settlementPalladiumPrice,
      settlementRhodiumPrice,
    },
    errors,
  );

  return errors;
}

export { validate };
