import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const removeCommodityFromLotType = 'REMOVE_COMMODITY_FROM_LOT';
const removeCommodityFromLotAction = () => ({
  type: removeCommodityFromLotType,
});

const removeCommodityFromLotSuccessType = `${removeCommodityFromLotType}_SUCCESS`;
const removeCommodityFromLotSuccessAction = () => ({
  type: removeCommodityFromLotSuccessType,
});

const removeCommodityFromLotFailureType = `${removeCommodityFromLotType}_FAILURE`;
const removeCommodityFromLotFailureAction = () => ({
  type: removeCommodityFromLotFailureType,
});

const removeCommodityFromLot =
  (fromLot, toLot, materialType, materialUnits, materialWeight) => dispatch => {
    dispatch(removeCommodityFromLotAction());

    let values = {
      fromLot,
      toLot,
      materialType,
    };

    if (materialUnits) {
      values = {
        ...values,
        materialUnits,
      };
    } else if (materialWeight) {
      values = {
        ...values,
        materialWeight,
      };
    }

    return axios
      .post(`${API_HOST_AUTH}/commodity`, values)
      .then(res => {
        dispatch(removeCommodityFromLotSuccessAction());

        return res.data;
      })
      .catch(err => {
        dispatch(removeCommodityFromLotFailureAction());

        return Promise.reject(err.data);
      });
  };

export {
  removeCommodityFromLotType,
  removeCommodityFromLotSuccessType,
  removeCommodityFromLotFailureType,
  removeCommodityFromLot,
};
