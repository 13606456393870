import React, { Component } from 'react';

import { OverviewList } from 'shared/components/OverviewList/OverviewList';
import {
  overviewPackageClasses,
  packageClasses,
} from 'shared/helpers/constants/packages/packageClasses';
import { PACKAGES } from 'shared/helpers/constants/packages/packageConstants';
import { packageStatuses } from 'shared/helpers/constants/packages/packageType';
import {
  buyerLotItemsTemplate,
  customerLotDetailsTemplate,
  customerLotItemsTemplate,
  incomingPackagesLotDetailsTemplate,
  incomingPackagesLotItemsTemplate,
  incomingShipmentPackagesDetailsTemplate,
  incomingShipmentPackagesItemsTemplate,
  itemsTemplateHF,
  itemsTemplateMobile,
  outgoingShipmentPackagesDetailsTemplate,
  outgoingShipmentPackagesItemsTemplate,
  packagesLotDetailsTemplate,
  packagesLotItemsTemplate,
  shipmentItemsTemplateMobile,
  templateReleased,
} from 'shared/helpers/constants/packages/packagesListTableConfig';
import { isTablet } from 'shared/helpers/constants/resolutionsConstants';
import { isInternalCompany } from 'shared/helpers/matchers/checkCompanyType';
import {
  isBuyerUser,
  isCustomerOrGrading,
  isCustomerUser,
} from 'shared/helpers/matchers/checkUserType';
import { isGlobalUserType } from 'shared/helpers/matchers/isGlobalUserType';
import {
  isIncomingShipment,
  isOutgoingShipment,
} from 'shared/helpers/matchers/shipments/checkShipmentDirection';

import { history } from 'utils/history';
import { getBrowserWidth } from 'utils/responsive';

class OverviewPackagesList extends Component {
  state = {
    windowWidth: getBrowserWidth(),
  };

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    this.props.clearPackagesOverview(this.props.sectionType);
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  get checkIsIncoming() {
    return this.props.sectionType === overviewPackageClasses.incoming;
  }

  getPackagesOverview = (page, listLimit = 2) => {
    const {
      editMode,
      packageForCompany,
      companyId,
      sectionType,
      auth: { user },
    } = this.props;

    if (editMode && packageForCompany && this.checkIsIncoming) {
      this.props.getPackagesOverview(
        companyId || user.relatedCompany._id,
        this.props.match.params.id,
        listLimit,
        sectionType,
      );
    } else if (packageForCompany && this.checkIsIncoming) {
      this.props.getPackagesOverview(companyId || user.relatedCompany._id, listLimit, sectionType);
    } else {
      this.props.getPackagesOverview(this.props.match.params.id, listLimit, sectionType);
    }
  };

  get tableActions() {
    const {
      shipmentId,
      lotId,
      label,
      auth: {
        user: {
          userType,
          relatedCompany: { companyType },
        },
      },
    } = this.props;

    const actions = [];
    if (shipmentId) {
      actions.push({
        icon: 'arrow-right',
        action: 'Details',
        onClick: thisPackage => () =>
          history.push(`/shipments/${shipmentId}/packages/${thisPackage._id}`),
      });
    }
    if (lotId) {
      actions.push({
        icon: 'arrow-right',
        action: 'Details',
        onClick: thisPackage => () =>
          history.push(`/lots/list/${lotId}/packages/${thisPackage._id}`),
        access: ({ status }) => status !== packageStatuses.deleted,
      });

      if (isInternalCompany(companyType) && isCustomerUser(userType) && label !== 'incoming')
        actions.pop();
    }

    return actions;
  }

  get tableTemplate() {
    const { sectionType, shipmentDetails, auth } = this.props;

    if (isBuyerUser(auth.user.userType)) {
      return buyerLotItemsTemplate;
    }
    const defaultItemsTemplate = (() => {
      if (shipmentDetails && isOutgoingShipment(shipmentDetails.shipment.shipmentDirection)) {
        return outgoingShipmentPackagesItemsTemplate;
      }
      if (shipmentDetails && isIncomingShipment(shipmentDetails.shipment.shipmentDirection)) {
        return incomingShipmentPackagesItemsTemplate;
      }
      if (isCustomerOrGrading(auth.user.userType) && sectionType === overviewPackageClasses.all) {
        return customerLotItemsTemplate;
      }
      if (isGlobalUserType(auth.user.userType) && sectionType === overviewPackageClasses.incoming) {
        return incomingPackagesLotItemsTemplate;
      }
      return packagesLotItemsTemplate;
    })();

    const itemsTemplateHeaviesFines =
      [packageClasses.post_mill, packageClasses.post_mix, packageClasses.post_shears].includes(
        sectionType,
      ) || isOutgoingShipment(shipmentDetails && shipmentDetails.shipment.shipmentDirection)
        ? [...defaultItemsTemplate, ...itemsTemplateHF]
        : defaultItemsTemplate;

    return [packageClasses.incoming, packageClasses.post_mill].includes(sectionType)
      ? [...itemsTemplateHeaviesFines, ...templateReleased]
      : itemsTemplateHeaviesFines;
  }

  get itemsTemplateMobile() {
    const { shipmentDetails } = this.props;

    return shipmentDetails ? shipmentItemsTemplateMobile : itemsTemplateMobile;
  }

  get tableDetailsTemplate() {
    const { sectionType, shipmentDetails, auth } = this.props;

    if (isBuyerUser(auth.user.userType)) {
      return customerLotItemsTemplate;
    }
    const defaultItemsTemplate = (() => {
      if (shipmentDetails && isOutgoingShipment(shipmentDetails.shipment.shipmentDirection)) {
        return outgoingShipmentPackagesDetailsTemplate;
      }
      if (shipmentDetails && isIncomingShipment(shipmentDetails.shipment.shipmentDirection)) {
        return incomingShipmentPackagesDetailsTemplate;
      }
      if (isCustomerOrGrading(auth.user.userType) && sectionType === overviewPackageClasses.all) {
        return customerLotDetailsTemplate;
      }
      if (isGlobalUserType(auth.user.userType) && sectionType === overviewPackageClasses.incoming) {
        return incomingPackagesLotDetailsTemplate;
      }
      return packagesLotDetailsTemplate;
    })();

    const itemsTemplateHeaviesFines =
      [packageClasses.post_mill, packageClasses.post_mix, packageClasses.post_shears].includes(
        sectionType,
      ) || isOutgoingShipment(shipmentDetails && shipmentDetails.shipment.shipmentDirection)
        ? [...defaultItemsTemplate, ...itemsTemplateHF]
        : defaultItemsTemplate;

    return [packageClasses.incoming, packageClasses.post_mill].includes(sectionType)
      ? [...itemsTemplateHeaviesFines, ...templateReleased]
      : itemsTemplateHeaviesFines;
  }

  handleWindowSizeChange = () => {
    this.setState({ windowWidth: getBrowserWidth() });
  };

  sortItems = () => {
    const { packagesOverviewList, sectionType } = this.props;

    return {
      ...packagesOverviewList.packagesList[sectionType],
      incoming: {
        ...packagesOverviewList.packagesList.incoming,
        docs: (packagesOverviewList.packagesList?.incoming?.docs || []).sort(
          (a, b) => !a.receivedAt - !b.receivedAt || b.receivedAt - a.receivedAt,
        ),
      },
    };
  };

  render() {
    const isMobile = this.state.windowWidth < isTablet;
    const { packagesOverviewList, clearPackagesOverview, processing, itemFilter } = this.props;

    return (
      <OverviewList
        getList={this.getPackagesOverview}
        cleatData={clearPackagesOverview}
        input={{
          items: this.sortItems(),
          isPending: packagesOverviewList.isPending,
        }}
        itemsTemplate={this.props.itemsTemplate || this.tableTemplate}
        actionsTemplate={this.props.tableActions || this.tableActions}
        itemsTemplateMobile={this.props.itemsTemplateMobile || this.itemsTemplateMobile}
        itemDetailsTemplate={this.props.itemsDetailsTemplate || this.tableDetailsTemplate}
        isMobile={isMobile}
        actionContext={this}
        label={PACKAGES.PACKAGES}
        listLimit="0"
        itemFilter={itemFilter}
        processing={processing}
      />
    );
  }
}

export { OverviewPackagesList };
