import {
  getCompanyHedgesActionType,
  getCompanyHedgesSuccessActionType,
  getCompanyHedgesFailureActionType,
  getCompanyHedgesTableSuccessActionType,
} from 'actions/Hedges/getCompanyHedges';

const INITIAL_STATE = {
  isPending: false,
  items: {
    docs: null,
  },
  existingHedges: { items: { docs: null } },
};

const companyHedgesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getCompanyHedgesActionType:
      return {
        ...state,
        isPending: true,
      };
    case getCompanyHedgesSuccessActionType:
      return {
        ...state,
        isPending: false,
        items: { docs: action.payload.data },
      };
    case getCompanyHedgesTableSuccessActionType:
      return {
        ...state,
        existingHedges: {
          ...state.existingHedges,
          [action.payload.hedgeName]: { items: { docs: action.payload.data } },
        },
      };
    case getCompanyHedgesFailureActionType:
      return {
        ...state,
        isPending: false,
      };
    default:
      return state;
  }
};

export { companyHedgesReducer };
