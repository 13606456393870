import { UPDATE_LOT_INFO } from 'shared/helpers/constants/lots/updateLotInfoConstants';
import { CAPITAL_ALPHANUM_REGEX } from 'shared/helpers/validations/regularExpressions';

const validate = ({ materialType, stillwaterShipmentId }) => {
  let materialTypeError = null;
  let stillwaterShipmentIdError = null;

  if (!materialType) {
    materialTypeError = UPDATE_LOT_INFO.INVALID_MATERIAL_TYPE_EMPTY;
  }

  if (!stillwaterShipmentId) {
    stillwaterShipmentIdError = UPDATE_LOT_INFO.INVALID_STILLWTER_SHIPMENT_ID_EMPTY;
  } else if (!CAPITAL_ALPHANUM_REGEX.test(stillwaterShipmentId)) {
    stillwaterShipmentIdError = UPDATE_LOT_INFO.INVALID_STILLWTER_SHIPMENT_ID_INVALID;
  } else if (stillwaterShipmentId.length > 15) {
    stillwaterShipmentIdError = UPDATE_LOT_INFO.INVALID_STILLWTER_SHIPMENT_ID_LENGTH;
  }

  return {
    materialType: materialTypeError,
    stillwaterShipmentId: stillwaterShipmentIdError,
  };
};

export { validate };
