import React, { Component } from 'react';

import { FormContainer } from 'shared/components/Form';
import { message } from 'shared/helpers/constants/hedges/createTrustedCompaniesConstants';
import { HEDGES } from 'shared/helpers/constants/hedges/hedgesConstants';
import {
  successOptions,
  dangerOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

import { TrustedCompaniesForm } from '../TrustedCompaniesForm/TrustedCompaniesForm';

class TrustedCompaniesCreate extends Component {
  submit = values => {
    const { showSnackbar, createTrustedCompany, history } = this.props;

    return createTrustedCompany(values.relatedCompany.value)
      .then(() => {
        showSnackbar(successOptions, message.TRUSTED_COMPANIES_CREATE_SUCCESS);
        history.push('/hedges/trusted-companies');
      })
      .catch(() => showSnackbar(dangerOptions, message.MARKET_HOLIDAY_CREATE_ERROR));
  };

  render() {
    return (
      <FormContainer header={HEDGES.TRUSTED_COMPANIES.CREATE}>
        <TrustedCompaniesForm {...this.props} onSubmit={this.submit} />
      </FormContainer>
    );
  }
}

export { TrustedCompaniesCreate };
