const REMOVE_COMMODITY = {
  INVALID_TO_LOT_EMPTY: 'To lot cannot be empty.',
  INVALID_MATERIAL_TYPE_EMPTY: 'Material type cannot be empty.',
  INVALID_MATERIAL_UNITS_EMPTY: 'Material units cannot be empty.',
  INVALID_MATERIAL_UNITS_LENGTH: 'Material units cannot be longer than 10 characters.',
  INVALID_MATERIAL_UNITS_INTEGER: 'Material units must be an integer.',
  INVALID_MATERIAL_UNITS_LESS: 'Material units cannot be less than 1.',
  INVALID_MATERIAL_WEIGHT_EMPTY: 'Material weight cannot be empty.',
  INVALID_MATERIAL_WEIGHT_LENGTH: 'Material weight cannot be longer than 10 characters.',
  INVALID_MATERIAL_WEIGHT_FORMAT: 'Material weight must have a valid format.',
  INVALID_MATERIAL_WEIGHT_LESS: 'Material weight cannot be less or equal to 0.',
  INVALID_MATERIAL_WEIGHT_XOR:
    'Please enter either the number of material units or the material weight.',
};

export { REMOVE_COMMODITY };
