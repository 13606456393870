import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { FiltersSelect } from 'shared/components/FiltersSelect/FiltersSelect';
import { LOCATION } from 'shared/helpers/constants/locations/locationConstants';

const FilterCountry = ({ regionList, filters: { country, state }, filterFunction }) => {
  const [requiredState, setRequiredState] = useState(false);

  useEffect(() => {
    const specialCountries = Object.keys(regionList?.data.state || {});
    setRequiredState(specialCountries.includes(country?.value));
  }, [country]);

  return (
    <>
      <div className="CompaniesFilters__item">
        <FiltersSelect
          value={country}
          label={LOCATION.COUNTRY}
          options={regionList?.data?.country || []}
          onChange={filterFunction('country')}
          isClearable
          placeholder={false}
        />
      </div>
      <div className="CompaniesFilters__item">
        <FiltersSelect
          value={state}
          label={LOCATION.STATE}
          options={regionList?.data.state[country?.value] || []}
          onChange={filterFunction('state')}
          isClearable
          isDisabled={!requiredState}
          placeholder={false}
        />
      </div>
    </>
  );
};

FilterCountry.propTypes = {
  filterFunction: PropTypes.func.isRequired,
  filters: PropTypes.shape({
    country: PropTypes.object,
    state: PropTypes.object,
  }).isRequired,
  regionList: PropTypes.object.isRequired,
};

export { FilterCountry };
