const normalizeLotLocationObject = lot => ({
  sentFromCompany: {
    value: lot.sentFromCompany._id,
    label: lot.sentFromCompany.companyName,
  },
  sentFromCompanyLocation: {
    value: lot.sentFromCompanyLocation._id,
    label: lot.sentFromCompanyLocation.name,
  },
  building: lot.locationStored && lot.locationStored.length ? lot.locationStored[0].building : null,
  locationStored: lot.locationStored && lot.locationStored.length ? lot.locationStored : [{}],
});

export { normalizeLotLocationObject };
