import { dateToTimestamp } from 'shared/helpers/parsers/date';
import { safeReplace, removeCommasAndParse } from 'shared/helpers/parsers/text';

const parseGenerateFinalInvoiceData = (data, lotId, removingOuncesDisabled) => ({
  lotId,
  invoiceGeneratedAt: dateToTimestamp(data.invoiceGeneratedAt),
  finalPlatinumPrice: data.finalPlatinumPrice
    ? removeCommasAndParse(data.finalPlatinumPrice, ',', '.')
    : 0,
  finalPalladiumPrice: data.finalPalladiumPrice
    ? removeCommasAndParse(data.finalPalladiumPrice, ',', '.')
    : 0,
  finalRhodiumPrice: data.finalRhodiumPrice
    ? removeCommasAndParse(data.finalRhodiumPrice, ',', '.')
    : 0,
  ...(data.outstandingBalanceApplied && {
    outstandingBalanceApplied: removeCommasAndParse(data.outstandingBalanceApplied),
  }),
  ...(!removingOuncesDisabled && {
    platinumOuncesRemoved: +safeReplace(data.platinumOuncesRemoved, ',', '.'),
    palladiumOuncesRemoved: +safeReplace(data.palladiumOuncesRemoved, ',', '.'),
    rhodiumOuncesRemoved: +safeReplace(data.rhodiumOuncesRemoved, ',', '.'),
  }),
});

const parseGenerateFinalInvoiceMockupData = (data, lotId) => ({
  lotId,
  invoiceGeneratedAt: dateToTimestamp(data.invoiceGeneratedAt),
  platinumOuncesRemoved: +safeReplace(data.platinumOuncesRemoved, ',', '.'),
  palladiumOuncesRemoved: +safeReplace(data.palladiumOuncesRemoved, ',', '.'),
  rhodiumOuncesRemoved: +safeReplace(data.rhodiumOuncesRemoved, ',', '.'),
});

export { parseGenerateFinalInvoiceData, parseGenerateFinalInvoiceMockupData };
