import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import { filterCompaniesToAdd } from 'actions/Payments/payoutRulesCreate';
import { openModal } from 'actions/shared/modal';

import { FieldSelect } from 'shared/components/Fields/FieldSelect/FieldSelect';
import { FieldSearch } from 'shared/components/Fields/index';
import { Form, Section } from 'shared/components/Form';
import { cancelModal } from 'shared/helpers/constants/modalConstants';
import { PAYMENTS } from 'shared/helpers/constants/payments/paymentsConstants';
import {
  payoutRulesTypes,
  parsePayoutRule,
} from 'shared/helpers/constants/payments/payoutRulesTypes';
import { mapArray } from 'shared/helpers/parsers/select';
import { validate } from 'shared/helpers/validations/payments/payoutRulesValidation';

import { goBackOrTo } from 'utils/history';

const PayoutRulesFormLayout = ({ handleSubmit, invalid, submitting }) => {
  const dispatch = useDispatch();
  const onCancel = useCallback(() => {
    dispatch(openModal(cancelModal, () => goBackOrTo('/payments/payout-rules')));
  }, [dispatch]);

  const listCompanies = useCallback(
    input => (!input ? Promise.resolve({ options: [] }) : dispatch(filterCompaniesToAdd(input))),
    [dispatch],
  );

  return (
    <Form
      header={PAYMENTS.PAYOUT_RULES.PAYOUT_RULE}
      onSubmit={handleSubmit}
      onCancel={onCancel}
      submitDisabled={invalid || submitting}
    >
      <Section template={['relatedCompany payoutRule']}>
        <Field
          name="relatedCompany"
          component={FieldSearch}
          label={PAYMENTS.PAYOUT_RULES.RELATED_COMPANY}
          field="required"
          getOptions={listCompanies}
        />
        <Field
          name="payoutRule"
          component={FieldSelect}
          label={PAYMENTS.PAYOUT_RULES.PAYOUT_RULE}
          field="required"
          options={mapArray(Object.values(payoutRulesTypes), parsePayoutRule)}
        />
      </Section>
    </Form>
  );
};

const PayoutRulesForm = reduxForm({
  form: 'PayoutRulesForm',
  validate,
})(PayoutRulesFormLayout);

export { PayoutRulesForm, PayoutRulesFormLayout };
