import { statusesForGlobal } from 'shared/helpers/constants/lots/statusesForGlobal';
import { packageClasses } from 'shared/helpers/constants/packages/packageClasses';
import { packageStatuses } from 'shared/helpers/constants/packages/packageType';

const releaseIncomingPackagesAccess = (
  {
    access: {
      packages: { releasePackage },
    },
  },
  lot,
) =>
  releasePackage &&
  lot.packages &&
  lot.statusForGlobal === statusesForGlobal.accepted_for_processing &&
  lot.packages.filter(
    p =>
      p.packageClass === packageClasses.incoming &&
      !p.releasedAt &&
      p.status !== packageStatuses.inactive,
  ).length;

export { releaseIncomingPackagesAccess };
