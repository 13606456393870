import axios from 'axios';

import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';
import { messageTypes } from 'shared/helpers/constants/snackbar/snackbarMessageTypes';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getVirtualLotActionType = 'GET_VIRTUAL_LOT';
const getVirtualLotAction = () => ({
  type: getVirtualLotActionType,
});

const getVirtualLotSuccessActionType = `${getVirtualLotActionType}_SUCCESS`;
const getVirtualLotSuccessAction = data => ({
  type: getVirtualLotSuccessActionType,
  payload: data,
});

const getVirtualLotFailureActionType = `${getVirtualLotActionType}_FAILURE`;
const getVirtualLotFailureAction = () => ({
  type: getVirtualLotFailureActionType,
});

const getVirtualLot = id => dispatch => {
  dispatch(getVirtualLotAction());

  return axios({
    method: 'get',
    url: `${API_HOST_AUTH}/virtual-lots/${id}`,
    headers: { 'Content-Type': 'application/json' },
  })
    .then(response => {
      dispatch(getVirtualLotSuccessAction(response.data));
      return response.data;
    })
    .catch(error => {
      dispatch(getVirtualLotFailureAction());
      return Promise.reject({
        message: `${LOTS.ERRORS.UPDATE_LOT_ERROR} ${error.data.message}`,
        messageType: messageTypes.error,
      });
    });
};

export {
  getVirtualLotActionType,
  getVirtualLotAction,
  getVirtualLotSuccessActionType,
  getVirtualLotSuccessAction,
  getVirtualLotFailureActionType,
  getVirtualLotFailureAction,
  getVirtualLot,
};
