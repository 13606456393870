import axios from 'axios';

import { parseSelfDeliveryRequestsFilters } from 'shared/helpers/dataParsers/selfDeliveryRequests/parseSelfDeliveryRequestsFilters';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getSelfDeliveryRequestsListActionType = 'GET_SELF_DELIVERY_REQUESTS_LIST';
const getSelfDeliveryRequestsListAction = () => ({
  type: getSelfDeliveryRequestsListActionType,
});

const getSelfDeliveryRequestsListSuccessActionType = `${getSelfDeliveryRequestsListActionType}_SUCCESS`;
const getSelfDeliveryRequestsListSuccessAction = ({ docs, pages, limit, page, total }) => ({
  type: getSelfDeliveryRequestsListSuccessActionType,
  payload: {
    selfDeliveryRequests: docs,
    pages,
    limit,
    page,
    total,
  },
});

const getSelfDeliveryRequestsListFailureActionType = `${getSelfDeliveryRequestsListActionType}_FAILURE`;
const getSelfDeliveryRequestsListFailureAction = () => ({
  type: getSelfDeliveryRequestsListFailureActionType,
});

const clearSelfDeliveryRequestsListActionType = 'CLEAR_SELF_DELIVERY_REQUESTS_LIST';
function clearSelfDeliveryRequestsList() {
  return {
    type: clearSelfDeliveryRequestsListActionType,
  };
}

const getSelfDeliveryRequestsListFactory = parsedFilters => dispatch => {
  dispatch(getSelfDeliveryRequestsListAction());

  return axios
    .get(`${API_HOST_AUTH}/self-delivery-requests`, {
      params: parsedFilters,
    })
    .then(({ data }) => dispatch(getSelfDeliveryRequestsListSuccessAction(data)))
    .catch(err => Promise.reject(dispatch(getSelfDeliveryRequestsListFailureAction(err))));
};

const getSelfDeliveryRequestsList = (limit, page, filters, user, order) =>
  getSelfDeliveryRequestsListFactory(
    parseSelfDeliveryRequestsFilters(limit, page, filters, user, order),
  );

export {
  getSelfDeliveryRequestsListActionType,
  getSelfDeliveryRequestsListSuccessActionType,
  getSelfDeliveryRequestsListFailureActionType,
  clearSelfDeliveryRequestsListActionType,
  clearSelfDeliveryRequestsList,
  getSelfDeliveryRequestsList,
};
