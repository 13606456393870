import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { change, getFormValues } from 'redux-form';

import {
  hedgedStatusFilter,
  lotsFiltersClear,
  materialTypeFilter,
  receivedAtEndFilter,
  receivedAtStartFilter,
  statusForCustomerFilter,
  statusForGlobalFilter,
} from 'actions/Lots/lotsFilters';
import { clearLotsList, getLotsList } from 'actions/Lots/lotsList';

import { GroupButtons } from 'shared/components/Buttons';
import { GroupButton } from 'shared/components/Buttons/GroupButton/components/GroupButton';
import { FullPageList } from 'shared/components/FullPageList/FullPageList';
import {
  itemsDetailsTemplateCustomer,
  itemsDetailsTemplateGlobal,
  itemsDetailsTemplateGrading,
  itemsTemplateCustomer,
  itemsTemplateGlobal,
  itemsTemplateGrading,
  itemsTemplateMobileCustomer,
  itemsTemplateMobileGlobal,
} from 'shared/helpers/constants/lots/lotListTableConfig';
import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';
import {
  isBuyerUser,
  isCustomerOrGrading,
  isCustomerUser,
  isGradingUser,
} from 'shared/helpers/matchers/checkUserType';
import { toCamelCase } from 'shared/helpers/parsers/text';

import { LotsFilters } from './components/LotsFilters/LotsFilters';

export const LotsList = () => {
  const { lots, page, pages, limit, isPending } = useSelector(state => state.lotsList);
  const searchForm = useSelector(state => getFormValues('SearchForm')(state));
  const auth = useSelector(state => state.auth);
  const {
    user: {
      userType,
      relatedCompany: { companyType },
    },
    access,
  } = useSelector(state => state.auth);
  const lotsFilters = useSelector(state => state.lotsFilters);
  const companyLocations = useSelector(state => state.companyLocations);
  const dispatch = useDispatch();

  const location = useLocation();
  const history = useHistory();

  const filterAccess = true;

  const filtersSelected =
    lotsFilters.hedgedStatus.length ||
    lotsFilters.materialType.length ||
    lotsFilters.receivedAtEnd !== 0 ||
    lotsFilters.receivedAtStart !== 0 ||
    lotsFilters.sentFromCompanyLocation._id ||
    lotsFilters.statusForGlobal.length ||
    lotsFilters.statusForCustomer.length;

  const onFiltersChanged = () => filters => {
    dispatch(receivedAtStartFilter(filters.receivedAtStart));
    dispatch(receivedAtEndFilter(filters.receivedAtEnd));
    dispatch(materialTypeFilter(filters.materialType));
    dispatch(hedgedStatusFilter(filters.hedgedStatus));
    dispatch(statusForGlobalFilter(filters.statusForGlobal));
    dispatch(statusForCustomerFilter(filters.statusForCustomer));
  };

  const tableActions = () => {
    const { details: lotDetails } = access.lots.details;
    const actions = [];

    if (lotDetails) {
      actions.push({
        icon: 'arrow-right',
        action: 'Details',
        onClick: thisLot => () => {
          history.push(`/lots/list/${thisLot._id}`);
        },
      });
    }

    return actions;
  };

  const renderFilters = searchFilter => {
    return (
      <LotsFilters
        filters={lotsFilters}
        pagination={{ page, limit }}
        onFiltersChanged={onFiltersChanged(searchFilter)}
        companyLocations={companyLocations}
        auth={auth}
      />
    );
  };

  const filtersClear = () => {
    dispatch(lotsFiltersClear());
  };

  const fetchList = (
    page = 1,
    limit = 50,
    query = searchForm?.lots?.searchInput || '',
    filters = lotsFilters,
  ) => {
    dispatch(getLotsList(limit, page, query, filters));
  };

  useEffect(() => {
    dispatch(
      change('SearchForm', `${toCamelCase(LOTS.LOTS)}.searchInput`, location.state?.searchLots),
    );
  }, []);

  useEffect(() => {
    fetchList();
  }, [lotsFilters]);

  useEffect(() => {
    return () => {
      dispatch(clearLotsList());
    };
  }, []);

  const createLot = () => history.push('/lots/create');

  const renderActionButtons = () => (
    <GroupButtons>
      <GroupButton
        access={access.lots.create}
        onClick={createLot}
        label={LOTS.CREATE_A_LOT}
        icon="new-lot"
      />
    </GroupButtons>
  );

  return (
    <FullPageList
      actionButtons={renderActionButtons}
      title={LOTS.LOTS}
      itemsTemplate={
        (isCustomerUser(userType) && itemsTemplateCustomer(companyType)) ||
        (isGradingUser(userType) && itemsTemplateGrading) ||
        itemsTemplateGlobal(isBuyerUser(userType))
      }
      itemsDetailsTemplate={
        (isCustomerUser(userType) && itemsDetailsTemplateCustomer) ||
        (isGradingUser(userType) && itemsDetailsTemplateGrading) ||
        itemsDetailsTemplateGlobal(isBuyerUser(userType))
      }
      itemsTemplateMobile={
        isCustomerOrGrading(userType) ? itemsTemplateMobileCustomer : itemsTemplateMobileGlobal
      }
      actionsTemplate={tableActions()}
      items={lots || []}
      fetchList={fetchList}
      filterComponent={renderFilters}
      filterAccess={filterAccess}
      pagination={{ page, limit, pages }}
      enableSearch
      focusSearch
      filtersClear={filtersClear}
      filtersSelected={!!filtersSelected}
      isPending={isPending}
      disableInitialFetch={location.state && location.state.searchLots}
      filters={lotsFilters}
    />
  );
};
