import styled from 'styled-components';

import { media } from 'shared/helpers/styling/styling';

export const StyledMarketHolidayFilters = styled.div`
  display: flex;

  ${media.mobile`
  flex-direction: column;
  align-items: center;
  `}

  .SelectDropdown-menu {
    width: 130px;

    ${media.mobile`
    width: 300px;
    `}

    ${media.phone`
    width: 250px;
    `}
  }

  .dropdown-menu {
    min-width: 6rem;
  }
`;

export const StyledMarketHolidayFiltersYear = styled.div`
  width: 330px;

  ${media.phone`
  width: 250px;
`}
`;
