import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { createNote } from 'actions/Notes/createNote';
import { clearNotesList, getNotes } from 'actions/Notes/notesList';
import { showSnackbar } from 'actions/shared/snackbar';

import { ModalCustom } from 'components/ModalCustom/ModalCustom';

import { NoteFormBase } from 'shared/components/Notes/CreateNotes/NoteFormBase/NoteFormBase';
import { NotesItem } from 'shared/components/Notes/NotesPanel';
import { Preloader } from 'shared/components/Preloader/Preloader';
import { NOTE } from 'shared/helpers/constants/noteConstants';
import {
  dangerOptions,
  successOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

import { CreateSettlementNoteYup } from './SettlementNoteYup';
import { StyledH2 } from './StyledSettlementNotesModal';

export const SettlementNotesModal = ({ isOpen, modalClose, relatedLotId }) => {
  const dispatch = useDispatch();
  const { notes, isPending } = useSelector(({ notesList }) => notesList);

  useEffect(() => {
    if (isOpen) {
      dispatch(
        getNotes({
          relatedType: NOTE.NOTES_TYPE.SETTLEMENTS,
          relatedId: relatedLotId,
          listLimit: 0,
        }),
      );
    } else dispatch(clearNotesList());
  }, [dispatch, isOpen, relatedLotId]);

  const handleSubmitForm = value =>
    dispatch(createNote(value, [], relatedLotId, NOTE.NOTES_TYPE.SETTLEMENTS))
      .then(({ data }) => dispatch(showSnackbar(successOptions, data?.message)))
      .catch(({ message }) => dispatch(showSnackbar(dangerOptions, message)))
      .finally(modalClose);

  return isPending ? (
    <Preloader loading={isPending} />
  ) : (
    <ModalCustom isOpen={isOpen} modalClose={modalClose} maxWidth={1260} zIndex={1}>
      <StyledH2>{NOTE.SETTLEMENT_NOTES_LIST}</StyledH2>
      {notes[NOTE.NOTES_TYPE.SETTLEMENTS]?.reverse().map(note => (
        <NotesItem note={note} key={note._id} closeModal={modalClose} />
      ))}
      <NoteFormBase
        handleSubmitForm={handleSubmitForm}
        validationSchema={CreateSettlementNoteYup}
        closeModal={modalClose}
        isPending={isPending}
        maxLength={1000}
      />
    </ModalCustom>
  );
};

SettlementNotesModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  modalClose: PropTypes.func.isRequired,
  relatedLotId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
