import styled from 'styled-components';

import { media } from 'shared/helpers/styling/styling';

export const StyledInactivateConfirmation = styled.div`
  padding: 40px 30px 35px;

  ${media.phone`
    padding: 40px 15px 35px;
  `}
`;

export const StyledButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 30px;
`;
