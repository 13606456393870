import styled from 'styled-components';

import { cssVar, media, pxToRem } from 'shared/helpers/styling/styling';

export const StyledPermissionsFooter = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${cssVar('shark')};
  padding: ${pxToRem(29)} ${pxToRem(26)};
  ${media.mobile`padding: 10px;`};
`;
