import axios from 'axios';

import {
  settlementMockUpAndForLot,
  parsePoolAccount,
  settlementForLot,
  settlementForLotWizard,
} from 'shared/helpers/dataParsers/poolAccounts/poolAccountData';

const API_HOST_AUTH = process.env.REACT_APP_API;

const requestSettlementActionType = 'REQUEST_SETTLEMENT';
const requestSettlementAction = () => ({
  type: requestSettlementActionType,
});

const requestSettlementSuccessActionType = `${requestSettlementActionType}_SUCCESS`;
const requestSettlementSuccessAction = () => ({
  type: requestSettlementSuccessActionType,
});

const requestSettlementFailureActionType = `${requestSettlementActionType}_FAILURE`;
const requestSettlementFailureAction = () => ({
  type: requestSettlementFailureActionType,
});

const requestSettlementFactory = url => parsedData => dispatch => {
  dispatch(requestSettlementAction());

  return axios
    .post(`${API_HOST_AUTH}/${url}`, parsedData)
    .then(({ data }) => {
      dispatch(requestSettlementSuccessAction());
      return data;
    })
    .catch(error => {
      dispatch(requestSettlementFailureAction());
      return Promise.reject(error.data);
    });
};

const settlementForLotRequest = data =>
  requestSettlementFactory('pool-accounts/request-settlement/settle-lot')(settlementForLot(data));

const settlementMockUpRequest = data =>
  requestSettlementFactory('pool-accounts/request-settlement/mock-up')(
    settlementMockUpAndForLot(data),
  );
const settlementOfMetalPoolRequest = data =>
  requestSettlementFactory('pool-accounts/request-settlement/settle-ounces')(
    parsePoolAccount(data),
  );

const settlementForLotWizardRequest = data =>
  requestSettlementFactory('pool-accounts/request-settlement/settle-lot-wizard')(
    settlementForLotWizard(data),
  );

export {
  requestSettlementActionType,
  requestSettlementSuccessActionType,
  requestSettlementFailureActionType,
  settlementForLotRequest,
  settlementMockUpRequest,
  settlementOfMetalPoolRequest,
  settlementForLotWizardRequest,
};
