import { resolveFilterDate, resolveFilterDateEnd } from 'shared/helpers/parsers/dateFilterParser';

const parseShipmentsFilters = (shipmentDirection, limit, page, shipmentFilters, searchFilter) => {
  const { estPickupDate, estDeliveryDate } = shipmentFilters;
  const filters = {
    status: shipmentFilters.status,
    estPickupDateFrom: resolveFilterDate(estPickupDate.from),
    estPickupDateTo: resolveFilterDateEnd(estPickupDate.to),
    estDeliveryDateFrom: resolveFilterDate(estDeliveryDate.from),
    estDeliveryDateTo: resolveFilterDateEnd(estDeliveryDate.to),
  };
  if (shipmentDirection === 'incoming') {
    filters.sentFromCompany = shipmentFilters.sentFromCompany._id;
    filters.sentFromCompanyLocation = shipmentFilters.sentFromCompanyLocation._id;
  }

  return {
    shipmentDirection,
    ...filters,
    limit,
    page,
    searchFilter,
  };
};

export { parseShipmentsFilters };
