import { connect } from 'react-redux';

import { generateShipmentReport } from 'actions/Shipments/generateShipmentReport';
import { showSnackbar } from 'actions/shared/snackbar';

import { SHIPMENTS } from 'shared/helpers/constants/shipments/shipmentsConstants';
import {
  successOptions,
  dangerOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

function GenerateShipmentReportLayout(props) {
  const onClick = () => {
    props
      .generateShipmentReport(props.shipment._id)
      .then(() => props.showSnackbar(successOptions, SHIPMENTS.SUCCESS.GENERATE_REPORT))
      .catch(() => props.showSnackbar(dangerOptions, SHIPMENTS.ERROR.GENERATE_REPORT));
  };

  return props.children({ onClick });
}

const mapDispatchToProps = {
  generateShipmentReport,
  showSnackbar,
};

const GenerateShipmentReport = connect(null, mapDispatchToProps)(GenerateShipmentReportLayout);

export { GenerateShipmentReportLayout, GenerateShipmentReport };
