import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { getPDFDocument } from 'actions/Files/getPDFDocument';
import { deleteNote } from 'actions/Notes/deleteNote';
import { openModal } from 'actions/shared/modal';

import { RouterButton } from 'shared/components/Buttons';
import { PreviewButton } from 'shared/components/Buttons/PreviewButton/PreviewButton';
import { Icon } from 'shared/components/Icon/Icon';
import { Section } from 'shared/components/Notes/NotesPanel/Sections/Section';
import { isNoteRemovable } from 'shared/helpers/accesses/notes/isNoteRemovable';
import { removeSettlementNote } from 'shared/helpers/constants/modalConstants';
import { NOTE } from 'shared/helpers/constants/noteConstants';
import { SHARED } from 'shared/helpers/constants/sharedConstants';
import { dateFormat } from 'shared/helpers/parsers/date';
import { cssVar, media } from 'shared/helpers/styling/styling';
import { PDF_REGEX, FILE_EXTENSION } from 'shared/helpers/validations/regularExpressions';

import dateAdapter from 'utils/date/dateAdapter';

import {
  CloseButton,
  FileActions,
  FilesWrapper,
  FileWrapper,
  NoteLabel,
  StyledLinkButton,
} from './StyledNotesItem';

const download = (downloadAttachment, note, file) => downloadAttachment(file.path);

const renderFiles = (note, onClick, handleShow) =>
  !!note.relatedFiles.length && (
    <FilesWrapper>
      <Icon
        icon="icon-clip"
        margin="0 0 -15px -4px"
        width={42}
        height={42}
        size="contain"
        color={cssVar('slateGrayGRG')}
      />
      <FileWrapper>
        {note.relatedFiles.map(file => (
          <Fragment key={file._id}>
            <FileActions>
              <StyledLinkButton
                key={file._id}
                label={file.originalName}
                onClick={() => download(onClick, note, file)}
                download
              />
              {PDF_REGEX.test(file.originalName) || !FILE_EXTENSION.test(file.originalName) ? (
                <PreviewButton sectionDocuments handleClick={() => handleShow(file._id)} />
              ) : null}
            </FileActions>
          </Fragment>
        ))}
      </FileWrapper>
    </FilesWrapper>
  );

const NotesItemLayout = ({
  className,
  note,
  onClick,
  showLabel,
  lotId,
  closeModal,
  listAppend,
  listAppendAvailable,
}) => {
  const dispatch = useDispatch();
  const { access } = useSelector(({ auth }) => auth);

  const handleShow = id => {
    dispatch(getPDFDocument(id));
  };

  const renderUserSection = () => (
    <>
      {showLabel && <NoteLabel status={note.type} backgroundColor={cssVar('blueBayoux')} />}
      <div>
        {note.createdBy.firstName} {note.createdBy.lastName}
      </div>
    </>
  );

  const handleDeleteNote = async ({ _id, type }) => {
    if (type === NOTE.NOTES_TYPE.SETTLEMENTS) {
      return dispatch(
        openModal(removeSettlementNote, () => dispatch(deleteNote(_id, type)).then(closeModal)),
      );
    }

    return dispatch(deleteNote(_id, type)).then(() => {
      if (listAppendAvailable) {
        listAppend(0);
      }
    });
  };

  return (
    <div className={className}>
      <Section text={renderUserSection()} />
      <Section text={dateAdapter(note.createdAt).format(dateFormat)} date />
      <Section text={note.note}>{renderFiles(note, onClick, handleShow)}</Section>
      {isNoteRemovable(access, note) && (
        <CloseButton data-testid="deleteNote" onClick={() => handleDeleteNote(note)}>
          <Icon icon="icon-cancel" />
        </CloseButton>
      )}
      {note.type === NOTE.NOTES_TYPE.PROCESSING && (
        <Section>
          <RouterButton
            id="editNote"
            path={`/lots/${lotId}/update-processing-note/${note._id}`}
            label={SHARED.EDIT}
            outlineColor={cssVar('blueBayoux')}
          />
        </Section>
      )}
    </div>
  );
};

const NotesItem = styled(NotesItemLayout)`
  width: 100%;
  display: grid;
  grid-template-columns: 130px 110px 1fr 75px;
  padding: 37px 35px;
  background-color: ${cssVar('limedSpruceCalendar')};
  border-bottom: 2px solid ${cssVar('sanJuan')};
  border-left: 1px solid ${cssVar('riverBed')};
  border-right: 1px solid ${cssVar('riverBed')};

  @media print {
    border-bottom: none;
    border-left: none;
    border-right: none;
  }
  ${media.mobile`
    grid-template-columns: 100%;
  `}
`;

NotesItem.propTypes = {
  note: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  showLabel: PropTypes.bool,
  lotId: PropTypes.string,
  closeModal: PropTypes.func,
  listAppendAvailable: PropTypes.bool,
  listAppend: PropTypes.func,
};

export { NotesItem };
