import {
  getVirtualLotActionType,
  getVirtualLotSuccessActionType,
  getVirtualLotFailureActionType,
} from 'actions/Lots/getVirtualLot';

const INITIAL_STATE = {
  isPending: false,
  virtualLot: null,
};

const getVirtualLotReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getVirtualLotActionType:
      return {
        ...state,
        isPending: true,
      };
    case getVirtualLotFailureActionType:
      return INITIAL_STATE;
    case getVirtualLotSuccessActionType:
      return {
        ...state,
        isPending: false,
        virtualLot: action.payload,
      };
    default:
      return state;
  }
};

export { getVirtualLotReducer };
