import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { changePasswordUsingToken } from 'actions/Users/changePasswordUsingToken';

import { FormWrapper } from '../components/FormWrapper/FormWrapper';
import { ResetPasswordForm } from '../components/ResetPasswordForm/ResetPasswordForm';
import { TokenAuth } from '../components/TokenAuth/TokenAuth';

class ResetPasswordBase extends Component {
  static defaultProps = {
    password: '',
  };

  onSubmit = ({ newPassword }) => {
    this.props
      .changePasswordUsingToken(this.props.match.params.token, newPassword)
      .then(() => this.props.history.push('/'));
  };

  render() {
    return (
      <FormWrapper wider>
        <TokenAuth purpose={this.props.purpose}>
          <ResetPasswordForm onSubmit={this.onSubmit} buttonLabel={this.props.buttonLabel} />
        </TokenAuth>
      </FormWrapper>
    );
  }
}

const mapDispatchToProps = {
  changePasswordUsingToken,
};

const ResetPassword = withRouter(connect(null, mapDispatchToProps)(ResetPasswordBase));

export { ResetPassword, ResetPasswordBase };
