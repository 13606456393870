import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getCompanyLocationsActionType = 'GET_COMPANY_LOCATIONS';
function getCompanyLocationsAction() {
  return {
    type: getCompanyLocationsActionType,
  };
}

const getCompanyLocationsSuccessActionType = 'GET_COMPANY_LOCATIONS_SUCCESS';
function getCompanyLocationsSuccessAction(response) {
  return {
    type: getCompanyLocationsSuccessActionType,
    payload: {
      response,
    },
  };
}

const getCompanyLocationsFailureActionType = 'GET_COMPANY_LOCATIONS_FAILURE';
function getCompanyLocationsFailureAction() {
  return {
    type: getCompanyLocationsFailureActionType,
  };
}

const clearCompanyLocationsActionType = 'CLEAR_COMPANY_LOCATIONS_ACTION';
function clearCompanyLocationsAction() {
  return {
    type: clearCompanyLocationsActionType,
  };
}

function getCompanyLocations(relatedCompany) {
  return dispatch => {
    dispatch(getCompanyLocationsAction());

    return axios({
      method: 'get',
      url: `${API_HOST_AUTH}/locations/companies/${relatedCompany}`,
    })
      .then(response => {
        dispatch(getCompanyLocationsSuccessAction(response.data));

        return response.data;
      })
      .catch(() => {
        dispatch(getCompanyLocationsFailureAction());
      });
  };
}

function clearCompanyLocations() {
  return dispatch => {
    dispatch(clearCompanyLocationsAction());
  };
}

export {
  getCompanyLocationsActionType,
  getCompanyLocationsSuccessActionType,
  getCompanyLocationsFailureActionType,
  clearCompanyLocationsActionType,
  getCompanyLocations,
  clearCompanyLocations,
};
