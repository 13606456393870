const message = {
  INVALID_DATE_EMPTY: 'Date is required.',
  INVALID_DATE_DUPLICATE: 'Holiday exists at that day.',
  INVALID_HOLIDAY_NAME_EMPTY: 'Holiday name is required.',
  INVALID_HOLIDAY_NAME_FORMAT: 'Holiday name cannot be longer than 50 characters.',
  BANK_HOLIDAY_DELETE_SUCCESS: 'Bank holiday has been deleted successfully',
  BANK_HOLIDAY_DELETE_ERROR: 'There was an error during delete.',
  BANK_HOLIDAY_CREATE_SUCCESS: 'Bank holiday has been added successfully.',
  BANK_HOLIDAY_CREATE_ERROR: 'There was an error during bank holiday creation.',
};

export { message };
