import React, { useCallback, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { destroy, getFormValues } from 'redux-form';

import { downloadAttachment } from 'actions/Attachments/downloadAttachment';
import { clearAssignedLotsAction } from 'actions/Lots/getAssignedLots';
import { clearLotDetailsAction } from 'actions/Lots/lotDetails';
import { assignedViewPrintPackageLabels } from 'actions/Packages/printPackageLabels';
import { createShipmentPackingList } from 'actions/Shipments/createShipment';
import { saveTemporaryData } from 'actions/Shipments/saveTemporaryData';
import { clearShipmentPackagesList } from 'actions/Shipments/shipmentPackagesList';
import { openModal } from 'actions/shared/modal';
import { saveSharedData } from 'actions/shared/saveSharedData';
import { showSnackbar } from 'actions/shared/snackbar';

import { Preloader } from 'shared/components/Preloader/Preloader';
import { Wizard } from 'shared/components/Wizard/Wizard';
import { cancelModal } from 'shared/helpers/constants/modalConstants';
import { requestAdvancedPaymentModal } from 'shared/helpers/constants/modalConstants';
import { packageClasses } from 'shared/helpers/constants/packages/packageClasses';
import { PACKAGES } from 'shared/helpers/constants/packages/packageConstants';
import { paymentTypes } from 'shared/helpers/constants/payments/paymentTypes';
import { SHIPMENTS } from 'shared/helpers/constants/shipments/shipmentsConstants';
import { dangerOptions } from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { getPackagesToPrint } from 'shared/helpers/dataParsers/shipments';
import { isFullHedging, isLimitedHedging } from 'shared/helpers/matchers/checkUserAccessLevel';
import { isCustomerUser, isOwnerOrSupport } from 'shared/helpers/matchers/checkUserType';
import { isStatusActive } from 'shared/helpers/matchers/isStatusActive';
import { snackbarOptions } from 'shared/helpers/matchers/snackbar/snackbarOptions';
import { humanize } from 'shared/helpers/parsers/text';

import { refreshToken } from 'utils/refreshToken';

import { ConnectedMultiPackagesForm } from 'pages/Packages/components/PackagesManagement/components/MultiPackagesCreate/MultiPackagesCreate';
import { IncomingPackageForm } from 'pages/Packages/components/PackagesManagement/components/MultiPackagesCreate/components/IncommingPackageForm/IncomingPackageForm';
import { configNames } from 'pages/Packages/components/PackagesManagement/components/PackageForm/components/PackageForm/fieldsConfig';
import { ConnectedCreateIncomingShipment } from 'pages/Shipments/components/GlobalShipmentsManagement/IncomingShipments/CreateIncomingShipment/ConnectedCreateIncomingShipment';
import { ConnectedCreateShipment } from 'pages/Shipments/components/ShipmentsManagement/CreateShipment/ConnectedCreateShipment';

import { GeneratePackingListForm } from './components/GeneratePackingListForm/GeneratePackingListForm';

const INTERVAL_20_MINUTES = 1200000;

const steps = userType => ({
  createShipment: isCustomerUser(userType)
    ? ConnectedCreateShipment
    : ConnectedCreateIncomingShipment,

  defaultPackagesValues: IncomingPackageForm,
  packages: ConnectedMultiPackagesForm,
  generatePackingListValues: GeneratePackingListForm,
});

const initialValues = {
  createShipment: {},
  defaultPackagesValues: {
    oneOrMultiLots: {
      label: humanize(PACKAGES.LOTS_FOR_PACKAGES.SAME_LOT),
      value: PACKAGES.LOTS_FOR_PACKAGES.SAME_LOT,
    },
    packagesQuantity: 1,
  },

  generatePackingListValues: {
    printPackingList: true,
    exportToExcel: false,
    printPackageLabels: false,
    markShipmentAsSent: false,
  },
};

const CreateShipmentWizard = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isPending, setIsPending] = useState(false);
  const [sendShipmentModalOpen, setSendShipmentModalOpen] = useState(false);
  const selectedPackages = useSelector(({ shipmentPackagesListData }) => shipmentPackagesListData);
  const [inputHighlight, setInputHighlight] = useState(false);
  const wizardData = useSelector(state => getFormValues('wizard')(state));
  const createShipment = useSelector(state => state.createShipment.temporaryData?.createShipment);
  const chosenPackages = useSelector(state => state.shipmentPackagesListData.packages);
  const markShipmentAsSent = wizardData?.generatePackingListValues?.markShipmentAsSent;

  const { userType, accessLevel, relatedCompany, status } = useSelector(
    ({
      auth: {
        user: { userType, accessLevel, relatedCompany, status },
      },
    }) => ({
      userType,
      relatedCompany,
      accessLevel,
      status,
    }),
  );

  useEffect(() => {
    const intervalId = setInterval(() => refreshToken(), INTERVAL_20_MINUTES);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const handleClickModalOpenOrSubmitData = useCallback(
    values =>
      wizardData?.generatePackingListValues?.markShipmentAsSent
        ? setSendShipmentModalOpen(true)
        : onSubmit(values),
    [wizardData?.generatePackingListValues?.markShipmentAsSent, chosenPackages, createShipment],
  );

  const resetData = result => {
    resetWizardData();
    dispatch(showSnackbar(snackbarOptions(result.messageType), result.message));
  };

  const requestAdvancePayment = () =>
    isCustomerUser(userType) &&
    relatedCompany.paymentExceptionCompany &&
    isStatusActive(status) &&
    (isFullHedging(accessLevel) || isLimitedHedging(accessLevel));

  const requestAdvancePaymentAsGlobal = () =>
    isOwnerOrSupport(userType) && createShipment.sentFromCompany.paymentExceptionCompany;

  const onSubmit = async values => {
    try {
      const formData = Object.assign({}, values, { createShipment });
      setSendShipmentModalOpen(false);
      setIsPending(true);
      const result = await dispatch(createShipmentPackingList(formData, userType, chosenPackages));

      await Promise.all(result.files.map(async filePath => dispatch(downloadAttachment(filePath))));

      if (
        values.generatePackingListValues.printPackageLabels &&
        selectedPackages.packages.length > 0
      ) {
        const packagesToPrint = getPackagesToPrint(
          result.shipmentPackages,
          selectedPackages.packages,
        );

        dispatch(
          assignedViewPrintPackageLabels(
            'shipments',
            packageClasses.incoming,
            result.id,
            packagesToPrint,
          ),
        );
      }

      setIsPending(false);
      if (markShipmentAsSent && (requestAdvancePayment() || requestAdvancePaymentAsGlobal())) {
        const requestAdvancedPaymentResult = await dispatch(
          openModal(
            requestAdvancedPaymentModal(
              isOwnerOrSupport(userType) && createShipment.sentFromCompany.label,
            ),
          ),
        );

        if (requestAdvancePaymentAsGlobal()) {
          const { label, value, companyType, balanceRemaining } = createShipment.sentFromCompany;

          dispatch(
            saveSharedData({
              payments: {
                relatedCompany: {
                  label,
                  value,
                  companyType,
                  balanceRemaining,
                },
                paymentType: {
                  value: paymentTypes.advance,
                  label: 'Advance',
                },
              },
            }),
          );
        }

        if (requestAdvancedPaymentResult) {
          const path = isOwnerOrSupport(userType) ? '/payments/enter' : '/payments/request/advance';
          history.push(path);
          return resetData(result);
        }
      }

      if (result.id) history.push(`/shipments/${result.id}`);
      resetData(result);
    } catch (err) {
      setIsPending(false);
      setSendShipmentModalOpen(false);
      dispatch(showSnackbar(dangerOptions, err.message));
    }
  };

  const resetWizardData = () => {
    dispatch(saveTemporaryData({ sentFromCompany: null }));
    dispatch(destroy('wizard'));
    dispatch(clearAssignedLotsAction());
    dispatch(clearLotDetailsAction());
    dispatch(clearShipmentPackagesList());
  };

  const modalOpen = useCallback(() => {
    dispatch(
      openModal(cancelModal, () => {
        resetWizardData();
        history.push('/shipments');
      }),
    );
  }, [openModal, destroy, history]);

  return (
    <>
      <Wizard
        steps={steps(userType)}
        header={SHIPMENTS.CREATE_INCOMING_SHIPMENT}
        onSubmit={handleClickModalOpenOrSubmitData}
        onCancel={modalOpen}
        initialValues={initialValues}
        contextProps={{
          config: configNames.packingList,
          inputHighlight: inputHighlight,
          setInputHighlight: setInputHighlight,
          sendShipmentModalOpen: sendShipmentModalOpen,
          setSendShipmentModalOpen: setSendShipmentModalOpen,
          handleSubmit: onSubmit,
          userType: userType,
        }}
      />
      <Preloader loading={isPending} />
    </>
  );
};

export { CreateShipmentWizard };
