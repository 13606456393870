import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const hedgeReassignActionType = 'HEDGES_REASSIGN';
function hedgeReassignAction() {
  return { type: hedgeReassignActionType };
}

const hedgeReassignSuccessActionType = `${hedgeReassignActionType}_SUCCESS`;
function hedgeReassignSuccessAction(hedge) {
  return {
    type: hedgeReassignSuccessActionType,
    payload: { hedge },
  };
}

const hedgeReassignFailureActionType = `${hedgeReassignActionType}_FAILURE`;
function hedgeReassignFailureAction() {
  return { type: hedgeReassignFailureActionType };
}

function hedgeReassign(hedge, relatedLotId, superAdminMode = false) {
  return dispatch => {
    dispatch(hedgeReassignAction());

    return axios
      .patch(`${API_HOST_AUTH}/hedges/${hedge._id}/reassign/${relatedLotId}`, { superAdminMode })
      .then(({ data }) => {
        dispatch(hedgeReassignSuccessAction(data.hedge));

        return data;
      })
      .catch(error => {
        dispatch(hedgeReassignFailureAction());

        return Promise.reject(error);
      });
  };
}

export {
  hedgeReassign,
  hedgeReassignActionType,
  hedgeReassignSuccessActionType,
  hedgeReassignFailureActionType,
};
