import axios from 'axios';

import { showSnackbar } from 'actions/shared/snackbar';

import { NOTE } from 'shared/helpers/constants/noteConstants';
import { messageTypes } from 'shared/helpers/constants/snackbar/snackbarMessageTypes';
import {
  dangerOptions,
  successOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

const API_HOST_AUTH = process.env.REACT_APP_API;

const deleteNoteActionType = 'DELETE_NOTE';
const deleteNoteAction = () => {
  return {
    type: deleteNoteActionType,
  };
};

const deleteNoteSuccessActionType = 'DELETE_NOTE_SUCCESS';
const deleteNoteSuccessAction = (noteId, noteType) => {
  return {
    type: deleteNoteSuccessActionType,
    payload: { noteId, noteType },
  };
};

const deleteNoteFailureActionType = 'DELETE_NOTE_FAILURE';
const deleteNoteFailureAction = () => {
  return {
    type: deleteNoteFailureActionType,
  };
};

const deleteNote = (noteId, noteType) => dispatch => {
  dispatch(deleteNoteAction());

  return axios
    .delete(`${API_HOST_AUTH}/notes/${noteId}`)
    .then(response => {
      dispatch(deleteNoteSuccessAction(noteId, noteType));
      dispatch(showSnackbar(successOptions, response.data.message));

      return {
        message: response.data.message,
        messageType: messageTypes.success,
      };
    })
    .catch(error => {
      dispatch(deleteNoteFailureAction());
      dispatch(showSnackbar(dangerOptions, `Note has not been deleted. ${error.data.message}`));

      return {
        message: `${NOTE.ERRORS.DELETE_NOTE_ERROR} ${error.data.message}`,
        messageType: messageTypes.error,
      };
    });
};

export {
  deleteNote,
  deleteNoteActionType,
  deleteNoteSuccessActionType,
  deleteNoteFailureActionType,
};
