import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';

export const DAYS = {
  MONDAY: { name: 'Monday', id: 'monday', dayNumber: 1 },
  TUESDAY: { name: 'Tuesday', id: 'tuesday', dayNumber: 2 },
  WEDNESDAY: { name: 'Wednesday', id: 'wednesday', dayNumber: 3 },
  THURSDAY: { name: 'Thursday', id: 'thursday', dayNumber: 4 },
  FRIDAY: { name: 'Friday', id: 'friday', dayNumber: 5 },
  SATURDAY: { name: 'Saturday', id: 'saturday', dayNumber: 6 },
  SUNDAY: { name: 'Sunday', id: 'sunday', dayNumber: 0 },
};

export const PROCESSING = {
  SHEARS: { name: 'Shears', id: 'processingShears' },
  M1: { name: 'Mill 1', id: 'processingM1' },
  M2: { name: 'Mill 2', id: 'processingM2' },
};

export const MIXING = {
  M1: { name: 'Mixer 1', id: 'mixingM1' },
  M2: { name: 'Mixer 2', id: 'mixingM2' },
};

export const GRADING_STATUSES = {
  RECEIVED: { name: 'Received', id: 'received' },
  ACCEPTED_FOR_GRADING: { name: 'Accepted for grading', id: 'acceptedForGrading' },
  GRADING_COMPLETED: { name: 'Grading completed', id: 'gradingCompleted' },
};

export const COUNTING_REQUIRED_SECTIONS = {
  COUNT: { name: LOTS.COUNT, id: 'count' },
  HOLD: { name: LOTS.HOLD, id: 'hold' },
};

export const SECTIONS = {
  INCOMING: { name: LOTS.INCOMING, id: 'incoming' },
  RECEIVED: { name: LOTS.RECEIVED_PACKAGES, id: 'received' },
  GRADING: { name: LOTS.GRADING, id: 'grading' },
  COUNTING_REQUIRED: { name: LOTS.COUNTING_REQUIRED, id: 'countingRequired' },
  CURRENTLY_PROCESSING: { name: LOTS.CURRENTLY_PROCESSING, id: 'currentlyProcessing' },
  COMPLETE: { name: LOTS.COMPLETE, id: 'complete' },
};

export const COLORS = {
  GRAY: { name: 'gray', color: '#191919' },
  RED: { name: 'red', color: '#A75859' },
  ORANGE: { name: 'orange', color: '#B2855F' },
  GREEN: { name: 'green', color: '#5DA259' },
};
