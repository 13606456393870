import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { reduxForm } from 'redux-form';

import { openModal } from 'actions/shared/modal';

import { FieldConfirmation } from 'shared/components/Fields';
import { Form, Section } from 'shared/components/Form';
import { cancelModal } from 'shared/helpers/constants/modalConstants';
import { ENTER_PRICES } from 'shared/helpers/constants/settlements/enterPricesConstants';
import { SETTLEMENTS } from 'shared/helpers/constants/settlements/settlementsConstants';
import { formatToUS } from 'shared/helpers/parsers/formaters';
import { validate } from 'shared/helpers/validations/settlements/enterPrices';

import { history } from 'utils/history';

const EnterPricesFormBase = ({ change, settlement, invalid, submitting, handleSubmit }) => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const openModalOnCancel = () => {
    dispatch(openModal(cancelModal, () => history.push(`/lots/list/${id}`)));
  };

  const getLabel = key => SETTLEMENTS[key];

  const getErrorMessage = key => ENTER_PRICES[`${key}_DO_NOT_MATCH`];

  return (
    <Form
      onSubmit={handleSubmit}
      onCancel={openModalOnCancel}
      submitDisabled={invalid || submitting}
      header={SETTLEMENTS.ENTER_PRICES}
    >
      <Section
        header={`${SETTLEMENTS.OUNCES_PT}: ${formatToUS(settlement.ouncesPt, 3)}`}
        template={[`platinumPrice platinumPrice`]}
        noPadding
      >
        <FieldConfirmation
          name="platinumPrice"
          type="number"
          label={getLabel('PLATINUM_PRICE')}
          field="required"
          prefix="$"
          change={change}
          errorMessage={getErrorMessage('PLATINUM_PRICE')}
          disabled={settlement.ouncesPt === 0}
        />
      </Section>
      <Section
        header={`${SETTLEMENTS.OUNCES_PD}: ${formatToUS(settlement.ouncesPd, 3)}`}
        template={[`palladiumPrice palladiumPrice`]}
        noPadding
      >
        <FieldConfirmation
          name="palladiumPrice"
          type="number"
          label={getLabel('PALLADIUM_PRICE')}
          field="required"
          prefix="$"
          change={change}
          errorMessage={getErrorMessage('PALLADIUM_PRICE')}
          disabled={settlement.ouncesPd === 0}
        />
      </Section>
      <Section
        header={`${SETTLEMENTS.OUNCES_RH}: ${formatToUS(settlement.ouncesRh, 3)}`}
        template={[`rhodiumPrice rhodiumPrice`]}
        noPadding
      >
        <FieldConfirmation
          name="rhodiumPrice"
          type="number"
          label={getLabel('RHODIUM_PRICE')}
          field="required"
          prefix="$"
          change={change}
          errorMessage={getErrorMessage('RHODIUM_PRICE')}
          disabled={settlement.ouncesRh === 0}
        />
      </Section>
    </Form>
  );
};

const EnterPricesForm = reduxForm({ form: 'enterPrices', validate })(EnterPricesFormBase);

export { EnterPricesForm, EnterPricesFormBase };
