import PropTypes from 'prop-types';
import * as React from 'react';
import { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';

import { openModal } from 'actions/shared/modal';

import { FieldConfirmation } from 'shared/components/Fields/FieldConfirmation/FieldConfirmation';
import { Form } from 'shared/components/Form';
import { HEDGES } from 'shared/helpers/constants/hedges/hedgesConstants';
import { cancelModal } from 'shared/helpers/constants/modalConstants';
import { ENTER_SETTLED_OUNCES } from 'shared/helpers/constants/settlements/settlementsConstants';
import { roundUp } from 'shared/helpers/parsers/number';
import { validate } from 'shared/helpers/validations/hedges/updateOuncesValidation';

const UpdateHedgeOuncesFormBase = ({
  handleSubmit,
  history,
  match,
  invalid,
  submitting,
  change,
  deliveries,
  initialValues,
  touch,
  showHint,
}) => {
  const dispatch = useDispatch();
  const getErrorMessage = key => ENTER_SETTLED_OUNCES[`${key}_DO_NOT_MATCH`];

  useEffect(() => {
    Object.keys(initialValues).map(f => touch(f));
  }, [deliveries]);

  const openModalOnCancel = () =>
    dispatch(openModal(cancelModal, () => history.push(`/hedges/${match.params.id}`)));

  const formatSuggestion = ounces =>
    deliveries &&
    showHint && {
      value: +roundUp(deliveries[ounces], 3),
      label: HEDGES.MINIMUM,
    };

  return (
    <Form
      onSubmit={handleSubmit}
      onCancel={openModalOnCancel}
      submitDisabled={invalid || submitting}
      header={HEDGES.HEDGE_OUNCES}
    >
      <FieldConfirmation
        name="platinumOz"
        type="text"
        label={ENTER_SETTLED_OUNCES.OUNCES_PT}
        field="required"
        suffix={ENTER_SETTLED_OUNCES.OZ}
        change={change}
        errorMessage={getErrorMessage('OUNCES_PT')}
        hint={formatSuggestion('platinumOz')}
      />
      <FieldConfirmation
        name="palladiumOz"
        type="text"
        label={ENTER_SETTLED_OUNCES.OUNCES_PD}
        field="required"
        suffix={ENTER_SETTLED_OUNCES.OZ}
        change={change}
        topBorder
        errorMessage={getErrorMessage('OUNCES_PD')}
        hint={formatSuggestion('palladiumOz')}
      />
      <FieldConfirmation
        name="rhodiumOz"
        type="text"
        label={ENTER_SETTLED_OUNCES.OUNCES_RH}
        field="required"
        suffix={ENTER_SETTLED_OUNCES.OZ}
        change={change}
        topBorder
        errorMessage={getErrorMessage('OUNCES_RH')}
        hint={formatSuggestion('rhodiumOz')}
      />
    </Form>
  );
};

UpdateHedgeOuncesFormBase.propTypes = {
  change: PropTypes.func,
  handleSubmit: PropTypes.func,
  history: PropTypes.object,
  invalid: PropTypes.bool,
  match: PropTypes.shape({
    isExact: PropTypes.bool,
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
    path: PropTypes.string,
    url: PropTypes.string,
  }),
  submitting: PropTypes.bool,
  showHint: PropTypes.bool,
  deliveries: PropTypes.array,
};

let UpdateHedgeOuncesForm = compose(
  reduxForm({
    form: 'updateHedgeOunces',
    validate,
    enableReinitialize: true,
  }),
  withRouter,
)(UpdateHedgeOuncesFormBase);

UpdateHedgeOuncesForm = connect(({ hedgesDetails: { hedge } }) => ({
  initialValues: {
    platinumOz: hedge?.platinumOz.toString(),
    palladiumOz: hedge?.palladiumOz.toString(),
    rhodiumOz: hedge?.rhodiumOz.toString(),
    platinumOzConfirm: hedge?.platinumOz.toString(),
    palladiumOzConfirm: hedge?.palladiumOz.toString(),
    rhodiumOzConfirm: hedge?.rhodiumOz.toString(),
  },
}))(UpdateHedgeOuncesForm);

export { UpdateHedgeOuncesForm };
