import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import { Authorization } from 'components/Authorization/Authorization';
import { NotFound } from 'components/ErrorPage/ErrorPage';

import { ConnectedCreateLotsNote } from 'shared/components/Notes/CreateNotes/ConnectedCreateNote';
import { createLotsAccessRouteHandler } from 'shared/helpers/accesses/createLotsAccessHandler';
import { globalFileTypes } from 'shared/helpers/constants/lots/globalFileTypes';
import { isInternalCompany } from 'shared/helpers/matchers/checkCompanyType';
import { isShipOnly } from 'shared/helpers/matchers/checkUserAccessLevel';
import { isBuyerUser } from 'shared/helpers/matchers/checkUserType';
import { isGlobalUserType } from 'shared/helpers/matchers/isGlobalUserType';
import { isStatusActive } from 'shared/helpers/matchers/isStatusActive';

import { isGlobal } from 'utils/versioning/Guards';

import { RequestLotInspection } from 'pages/Inspections/RequestLotInspection/ConnectedRequestLotInspection';
import { ScheduleLotInspection } from 'pages/Inspections/ScheduleLotInspection/ConnectedScheduleLotInspection';
import { AssayResultsDetails } from 'pages/Lots/components/AssayResultsDetails/AssayResultsDetails';
import { ChangeReceivedDate } from 'pages/Lots/components/ChangeReceivedDate/ChangeReceivedDate';
import { EnterAssayResults } from 'pages/Lots/components/EnterAssayResults/EnterAssayResults';
import { ConnectedFillProcessingPackages } from 'pages/Lots/components/FillProcessingPackages/ConnectedFillProcessingPackages';
import { GenerateFinalInvoice } from 'pages/Lots/components/FinalInvoice/GenerateFinalInvoice/GenerateFinalInvoice';
import { GenerateFinalInvoiceMockup } from 'pages/Lots/components/FinalInvoice/GenerateFinalInvoice/GenerateFinalInvoiceMockup';
import { UploadFinalInvoice } from 'pages/Lots/components/FinalInvoice/UploadFinalInvoice/UploadFinalInvoice';
import { GenerateProfitLoss } from 'pages/Lots/components/GenerateProfitLoss/GenerateProfitLoss';
import { LotDetails } from 'pages/Lots/components/LotDetails/LotDetails';
import { LotNotes } from 'pages/Lots/components/LotNotes/LotNotes';
import { LotsIncoming } from 'pages/Lots/components/LotsIncoming/LotsIncoming';
import ConnectedLotCreate from 'pages/Lots/components/LotsManagement/CreateLot/ConnectedCreateLot';
import ConnectedLotUpdate from 'pages/Lots/components/LotsManagement/Update/UpdateLot/ConnectedUpdateLot';
import { ConnectedUpdateLotLocation } from 'pages/Lots/components/LotsManagement/Update/UpdateLotLocation/ConnectedUpdateLotLocation';
import { LotsOutgoing } from 'pages/Lots/components/LotsOutgoing/LotsOutgoing';
import ConnectedLotsSections from 'pages/Lots/components/LotsSections/ConnectedLotsSections';
import { CreateProcessingNote } from 'pages/Lots/components/LotsSections/components/LotsSection/components/WarehouseDashboardParts/CreateProcessingNote/CreateProcessingNote';
import { UpdateProcessingNote } from 'pages/Lots/components/LotsSections/components/LotsSection/components/WarehouseDashboardParts/UpdateProcessingNote/UpdateProcessingNote';
import { MixingOverview } from 'pages/Lots/components/MixingOverview/MixingOverview';
import { ProcessingOverview } from 'pages/Lots/components/ProcessingOverview/ProcessingOverview';
import { RemoveCommodity } from 'pages/Lots/components/RemoveCommodity/RemoveCommodity';
import { SettlementEnterPrices } from 'pages/Lots/components/SettlementEnterPrices/SettlementEnterPrices';
import { UpdateShipmentInfo } from 'pages/Lots/components/UpdateShipmentInfo/UpdateShipmentInfo';
import { CreateVirtualLotForm } from 'pages/Lots/components/VirtualLotManagement/CreateVirtualLot/CreateVirtualLot';
import { UpdateVirtualLotForm } from 'pages/Lots/components/VirtualLotManagement/UpdateVirtualLot/UpdateVirtualLot';
import { PackageDetails } from 'pages/Packages/components/PackageDetails/PackageDetails';
import { CreateMultiIncomingPackages } from 'pages/Packages/components/PackagesManagement/Create/CreateMultiIncomingPackages/CreateMultiIncomingPackages';
import { CreateProcessingPackage } from 'pages/Packages/components/PackagesManagement/Create/CreateProcessingPackage/CreateProcessingPackage';
import { UpdateIncomingPackageForCustomer } from 'pages/Packages/components/PackagesManagement/Update/UpdateIncomingPackageForCustomer/UpdateIncomingPackageForCustomer';
import { UpdateIncomingPackageForGlobal } from 'pages/Packages/components/PackagesManagement/Update/UpdateIncomingPackageForGlobal/UpdateIncomingPackageForGlobal';
import { ConnectedUpdatePackage } from 'pages/Packages/components/PackagesManagement/Update/UpdatePackage/ConnectedUpdatePackage';
import { ConnectedUpdatePackageWeight } from 'pages/Packages/components/PackagesManagement/Update/UpdatePackageWeight/ConnectedUpdatePackageWeight';
import { ConnectedPaymentDetails } from 'pages/Payments/components/PaymentDetails/ConnectedPaymentDetails';
import { ConnectedShipmentDetails } from 'pages/Shipments/components/ShipmentDetails/ConnectedShipmentDetails';

import { LotsList } from './components/LotsList/LotsList';

class Lots extends Component {
  componentDidMount() {
    const { relatedCompany, userType } = this.props.auth.user;

    if (!isGlobalUserType(userType)) {
      this.props.getCompanyLocations(relatedCompany._id);
    }
  }

  componentWillUnmount() {
    const { editPackageMode } = this.props.packagesOverviewList.temporaryData;

    if (!editPackageMode) {
      this.props.clearAllPackagesOverview();
    }
  }

  createLotsAccess = (access, user) => {
    if (isGlobalUserType(user.userType) && isStatusActive(user.status)) {
      return access.lots.create;
    }

    return createLotsAccessRouteHandler(access.lots.create, user.relatedCompany.status);
  };

  getRelatedAssayResultAccess = (access, user) =>
    (isInternalCompany(user.relatedCompany.companyType) && !isShipOnly(user.accessLevel)) ||
    access.lots.assayResultsDetails;

  render() {
    const { access, user } = this.props.auth;

    const AuthLotsSections = Authorization(
      ConnectedLotsSections,
      access.lots.list && !isBuyerUser(user.userType),
    );
    const AuthLotsList = Authorization(LotsList, access.lots.list);
    const AuthLotsIncoming = Authorization(LotsIncoming, access.lots.incomingOverview);
    const AuthLotsOutgoing = Authorization(LotsOutgoing, access.lots.outgoingOverview);
    const AuthLotDetails = Authorization(LotDetails, access.lots.details.details);
    const AuthLotUpdate = Authorization(ConnectedLotUpdate, access.lots.update);
    const AuthLotNotes = Authorization(LotNotes, access.lots.generateGlobalDocuments);
    const AuthUpdateLotLocation = Authorization(
      ConnectedUpdateLotLocation,
      access.lots.updateLocationStored,
    );
    const AuthLotCreate = Authorization(ConnectedLotCreate, this.createLotsAccess(access, user));
    const AuthUpdatePackage = Authorization(ConnectedUpdatePackage, access.packages.update);
    const AuthPackageDetails = Authorization(PackageDetails, access.packages.details);
    const AuthShipmentDetails = Authorization(ConnectedShipmentDetails, access.shipments.details);
    const AuthPaymentDetails = Authorization(ConnectedPaymentDetails, access.payments.details);
    const AuthCreateIncomingPackageForCustomer = Authorization(
      CreateMultiIncomingPackages,
      access.packages.createIncoming.forCustomer,
    );
    const AuthCreateIncomingPackageForGlobal = Authorization(
      CreateMultiIncomingPackages,
      access.packages.createIncoming.forGlobal,
    );
    const AuthCreateProcessingPackage = Authorization(
      CreateProcessingPackage,
      access.packages.createProcessing,
    );
    const AuthUpdateIncomingPackageForCustomer = Authorization(
      UpdateIncomingPackageForCustomer,
      access.packages.updateIncoming.forCustomer,
    );
    const AuthUpdateIncomingPackageForGlobal = Authorization(
      UpdateIncomingPackageForGlobal,
      access.packages.updateIncoming.forGlobal,
    );
    const AuthUpdatePackageWeight = Authorization(
      ConnectedUpdatePackageWeight,
      access.packages.updateWeight || access.specialPermissions.processing.updatePackageWeight,
    );
    const AuthProcessingOverview = Authorization(
      ProcessingOverview,
      access.lots.processingOverview.view,
    );
    const AuthMixingOverview = Authorization(MixingOverview, access.lots.mixingOverview.view);
    const AuthRequestLotInspection = Authorization(
      RequestLotInspection,
      access.lots.requestInspection,
    );
    const AuthScheduleLotInspection = Authorization(
      ScheduleLotInspection,
      access.lots.scheduleInspection,
    );
    const AuthRemoveCommodity = Authorization(RemoveCommodity, access.commodities.remove);
    const AuthEnterAssayResults = Authorization(EnterAssayResults, access.lots.enterAssayResults);
    const AuthAssayDetails = Authorization(
      AssayResultsDetails,
      this.getRelatedAssayResultAccess(access, user),
    );
    const AuthUpdateShipmentInfo = Authorization(
      UpdateShipmentInfo,
      access.lots.updateShipmentInfo,
    );
    const AuthUpdateReceivedDate = Authorization(
      ChangeReceivedDate,
      access.lots.changeReceivedDate,
    );
    const accessToProfitLoss = isGlobal
      ? access.specialPermissions.payments.generateOrApproveProfitLossDocs
      : access.lots.profitLossManagement;
    const AuthGenerateProfitLoss = Authorization(GenerateProfitLoss, accessToProfitLoss);
    const AuthGenerateFinalInvoice = Authorization(
      GenerateFinalInvoice,
      access.lots.generateGlobalDocuments,
    );
    const AuthGenerateFinalInvoiceMockup = Authorization(
      GenerateFinalInvoiceMockup,
      access.lots.generateGlobalDocuments,
    );
    const AuthUploadFinalInvoice = Authorization(
      UploadFinalInvoice,
      access.lots.generateGlobalDocuments,
    );
    const AuthManageFinalInvoice = Authorization(LotDetails, access.lots.manageGlobalDocuments);
    const AuthInspectCFU = Authorization(
      LotDetails,
      access.specialPermissions.lots.approveClientFacingUploads,
    );
    const AuthManageProfitLoss = Authorization(
      LotDetails,
      access.lots.profitLossNotification ||
        access.specialPermissions.payments.generateOrApproveProfitLossDocs,
    );
    const AuthCreateLotsNote = Authorization(ConnectedCreateLotsNote, access.lots.details.addNotes);
    const AuthFillProcessingPackages = Authorization(
      ConnectedFillProcessingPackages,
      access.lots.markProcessingComplete,
    );
    const AuthLotTimeline = Authorization(LotDetails, access.lots.timeline);
    const AuthSettlementEnterPrices = Authorization(
      SettlementEnterPrices,
      access.settlements.enterPrices,
    );
    const AuthCreateProcessingNote = Authorization(
      CreateProcessingNote,
      access.lots.warehouseDashboard,
    );
    const AuthUpdateProcessingNote = Authorization(
      UpdateProcessingNote,
      access.lots.warehouseDashboard,
    );
    const AuthVirtualLotCreate = Authorization(
      CreateVirtualLotForm,
      access.lots.warehouseDashboard,
    );
    const AuthVirtualLotWeightUpdate = Authorization(
      UpdateVirtualLotForm,
      access.lots.warehouseDashboard,
    );

    return (
      <Switch>
        <Route exact path="/lots" component={AuthLotsSections} />
        <Route exact path="/lots/create" component={AuthLotCreate} />
        <Route exact path="/lots/create-virtual-lot" component={AuthVirtualLotCreate} />
        <Route exact path="/lots/update-virtual-lot/:id" component={AuthVirtualLotWeightUpdate} />
        <Route exact path="/lots/incoming" component={AuthLotsIncoming} />
        <Route exact path="/lots/incoming/:id" component={AuthLotDetails} />
        <Route exact path="/lots/list" component={AuthLotsList} />
        <Route exact path="/lots/list/:id" component={AuthLotDetails} />
        <Route exact path="/lots/list/:id/notes" component={AuthLotNotes} />
        <Route exact path="/lots/list/:id/update" component={AuthLotUpdate} />
        <Route exact path="/lots/list/:id/commodity" component={AuthRemoveCommodity} />
        <Route exact path="/lots/list/:id/assay/:assayId" component={AuthAssayDetails} />
        <Route exact path="/lots/list/:id/update/location" component={AuthUpdateLotLocation} />
        <Route
          exact
          path="/lots/list/:id/update/shipment-info"
          component={AuthUpdateShipmentInfo}
        />
        <Route
          exact
          path="/lots/list/:id/update/received-date"
          component={AuthUpdateReceivedDate}
        />
        <Route exact path="/lots/list/:id/enter-assay-results" component={AuthEnterAssayResults} />
        <Route
          exact
          path="/lots/list/:id/generate/profit-loss"
          component={AuthGenerateProfitLoss}
        />
        <Route
          exact
          path="/lots/list/:id/generate/final-invoice"
          component={AuthGenerateFinalInvoice}
        />
        <Route
          exact
          path="/lots/list/:id/generate/final-invoice-mockup"
          component={AuthGenerateFinalInvoiceMockup}
        />
        <Route
          exact
          path="/lots/list/:id/upload/final-invoice"
          component={AuthUploadFinalInvoice}
        />
        <Route exact path="/lots/outgoing" component={AuthLotsOutgoing} />
        <Route
          exact
          path="/lots/outgoing/request-inspection"
          component={AuthRequestLotInspection}
        />
        <Route
          exact
          path="/lots/outgoing/schedule-inspection"
          component={AuthScheduleLotInspection}
        />
        <Route exact path="/lots/outgoing/lots/:id" component={AuthLotDetails} />
        <Route exact path="/lots/outgoing/shipments/:id" component={AuthShipmentDetails} />
        <Route
          exact
          path="/lots/list/:lotId/packages/create/customer"
          component={AuthCreateIncomingPackageForCustomer}
        />
        <Route
          exact
          path="/lots/list/:lotId/packages/create/global"
          component={AuthCreateIncomingPackageForGlobal}
        />
        <Route
          exact
          path="/lots/list/:lotId/packages/create/processing"
          component={AuthCreateProcessingPackage}
        />
        <Route
          exact
          path="/lots/list/:lotId/packages/:id/update/customer"
          component={AuthUpdateIncomingPackageForCustomer}
        />
        <Route
          exact
          path="/lots/list/:lotId/packages/:id/update/global"
          component={AuthUpdateIncomingPackageForGlobal}
        />
        <Route
          exact
          path="/lots/list/:lotId/packages/:id/update/weight"
          component={AuthUpdatePackageWeight}
        />
        <Route
          exact
          path="/lots/list/:lotId/packages/:id/update/weight/admin"
          component={props => <AuthUpdatePackageWeight {...props} superAdminMode />}
        />
        <Route exact path="/lots/list/:lotId/packages/:id" component={AuthPackageDetails} />
        <Route exact path="/lots/list/:lotId/packages/:id/update" component={AuthUpdatePackage} />
        <Route exact path="/lots/list/:lotId/shipments/:id" component={AuthShipmentDetails} />
        <Route exact path="/lots/list/:lotId/payments/:id" component={AuthPaymentDetails} />
        <Route exact path="/lots/list/:id/timeline" component={AuthLotTimeline} />
        <Route exact path="/lots/processing" component={AuthProcessingOverview} />
        <Route exact path="/lots/processing/accept/:id" component={AuthFillProcessingPackages} />
        <Route
          exact
          path="/lots/processing/accept/:lotId/create-package"
          component={AuthCreateIncomingPackageForGlobal}
        />
        <Route
          exact
          path="/lots/processing/accept/:lotId/packages/:id"
          component={AuthPackageDetails}
        />
        <Route
          exact
          path="/lots/processing/accept/:lotId/packages/:id/update-weight"
          component={AuthUpdatePackageWeight}
        />
        <Route
          exact
          path="/lots/processing/accept/:lotId/packages/:id/update/weight"
          component={AuthUpdatePackageWeight}
        />
        <Route
          exact
          path="/lots/processing/accept/:lotId/packages/:id/update/global"
          component={AuthUpdateIncomingPackageForGlobal}
        />
        <Route exact path="/lots/processing" component={AuthProcessingOverview} />
        <Route exact path="/lots/processing/complete/:id" component={AuthFillProcessingPackages} />
        <Route
          exact
          path="/lots/processing/complete/:lotId/create-package"
          component={AuthCreateProcessingPackage}
        />
        <Route
          exact
          path="/lots/processing/complete/:lotId/packages/:id"
          component={AuthPackageDetails}
        />
        <Route
          exact
          path="/lots/processing/complete/:lotId/packages/:id/update-weight"
          component={AuthUpdatePackageWeight}
        />
        <Route
          exact
          path="/lots/processing/complete/:lotId/packages/:id/update/weight"
          component={AuthUpdatePackageWeight}
        />
        ...
        {isGlobal && [
          <Route exact path="/lots/mixing" component={AuthMixingOverview} />,
          <Route exact path="/lots/mixing/complete/:id" component={AuthFillProcessingPackages} />,
          <Route
            exact
            path="/lots/mixing/complete/:lotId/create-package"
            component={AuthCreateProcessingPackage}
          />,
          <Route
            exact
            path="/lots/mixing/complete/:lotId/packages/:id"
            component={AuthPackageDetails}
          />,
          <Route
            exact
            path="/lots/mixing/complete/:lotId/packages/:id/update-weight"
            component={AuthUpdatePackageWeight}
          />,
          <Route
            exact
            path="/lots/mixing/complete/:lotId/packages/:id/update/weight"
            component={AuthUpdatePackageWeight}
          />,
          <Route exact path="/lots/mixing/:id" component={AuthLotDetails} />,
        ]}
        <Route exact path="/lots/processing/:id" component={AuthLotDetails} />
        <Route
          exact
          path={`/lots/list/:id/manage-profit-and-loss/:fileType(${globalFileTypes.profitAndLoss})`}
          component={AuthManageProfitLoss}
        />
        <Route exact path="/lots/list/:id/inspect-cfu" component={AuthInspectCFU} />
        <Route exact path="/lots/list/:id/rejected-cfu" component={LotDetails} />
        <Route
          exact
          path={`/lots/list/:id/:manage/:fileType(${Object.values(globalFileTypes).join('|')})`}
          component={AuthManageFinalInvoice}
        />
        <Route exact path="/lots/:id" component={AuthLotDetails} />
        <Route exact path="/lots/:id/create-note" component={AuthCreateLotsNote} />
        <Route
          exact
          path="/lots/list/:id/settlements/:settlementId/enter-prices"
          component={AuthSettlementEnterPrices}
        />
        <Route exact path="/lots/create-processing-note/:id" component={AuthCreateProcessingNote} />
        <Route
          exact
          path="/lots/:id/update-processing-note/:noteId"
          component={AuthUpdateProcessingNote}
        />
        <Route component={NotFound} />
      </Switch>
    );
  }
}

export { Lots };
