import { companyInformation } from "../versioning/companyInformation";

const PROFIT_LOSS = {
  LOT_VALUES: 'Lot values',
  EXISTING_COMMODITIES: 'Existing commodities',
  ADDITIONAL_COMMODITIES: 'Additional commodities',
  RECEIVED_FROM_STILLWATER: 'Received from Stillwater',
  PAID_BY_GLOBAL: `Paid by ${companyInformation.representativeName}`,
  COMMODITY: 'Commodity',
  ADD_COMMODITY: 'Add commodity',
  ADD_LOT_ADJUSTMENT: 'Add lot adjustment',
  CALCULATED: 'Calculated',
  ACTION: 'Action',
  DESCRIPTION: 'Description',
  DETAILS: 'Details',
  VALUE: 'Value',
  MATERIAL: 'Material',
  MATERIAL_WEIGHT: 'Material weight',
  MATERIAL_TYPE: 'Material type',
  FROM_LOT: 'From lot',
  TO_LOT: 'To lot',
  LBS: 'lbs',
  UNITS: 'units',
  ERRORS: {
    RECEIVED_FROM_STILLWATER_INVALID: 'Received from Stillwater must have a valid format.',
    RECEIVED_FROM_STILLWATER_EMPTY: 'Received from Stillwater cannot be empty.',
    RECEIVED_FROM_STILLWATER_NEGATIVE:
      'Received from Stillwater cannot be less than or equal to 0.',
    PAID_BY_GLOBAL_INVALID: `Paid by ${companyInformation.representativeName} must have a valid format.`,
    PAID_BY_GLOBAL_EMPTY: `Paid by ${companyInformation.representativeName} cannot be empty.`,
    PAID_BY_GLOBAL_NEGATIVE: `Paid by ${companyInformation.representativeName} cannot be less than or equal to 0.`,
    ACTION_EMPTY: 'Action cannot be empty',
    DESCRIPTION_EMPTY: 'Description cannot be empty',
    DETAILS_INVALID: 'Details must have a valid format.',
    VALUE_INVALID: 'Value must have a valid format.',
    VALUE_EMPTY: 'Value cannot be empty.',
    VALUE_NEGATIVE: 'Value cannot be less than or equal to 0.',
    COMMODITY_VALUE_NEGATIVE: 'Value cannot be less than 0.',
    LOT_CLOSE_DATE_EMPTY: 'Lot close date cannot be empty.',
    LOT_CLOSE_DATE_TOO_SOON: 'Lot close date cannot be before the lot receive date.',
    LOT_DESCRIPTION_INVALID: 'Description must have a valid format.',
  },
  LOT_CLOSE_DATE: 'Lot close date',
  LOT_ADJUSTMENT: 'Lot adjustment',
  LOT_ADJUSTMENTS: 'Lot adjustments',
};

const actionTypes = {
  add: 'add',
  remove: 'remove',
};

const profitAndLossDescriptionTypes = {
  mono_canned: 'mono_canned',
  aftermarkets: 'aftermarkets',
  foil_canned: 'foil_canned',
  DPF_canned: 'DPF_canned',
  beads_canned: 'beads_canned',
  sensors: 'sensors',
  mono_decanned: 'mono_decanned',
  foil_decanned: 'foil_decanned',
  DPF_decanned: 'DPF_decanned',
  beads_decanned: 'beads_decanned',
  industrial_foil: 'industrial_foil',
  industrial_mono: 'industrial_mono',
  high_grade_dust: 'high_grade_dust',
  sweeps: 'sweeps',
  d_p: 'D.P.',
  jumbo: 'jumbo',
};

const lotAdjustmentActionTypes = {
  credit: 'credit',
  deduction: 'deduction',
};

export { PROFIT_LOSS, actionTypes, profitAndLossDescriptionTypes, lotAdjustmentActionTypes };
