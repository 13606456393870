import { dateFormat } from 'shared/helpers/parsers/date';

import dateAdapter from 'utils/date/dateAdapter';

import { PAYMENTS } from './paymentsConstants';

const itemsTemplate = [
  {
    label: PAYMENTS.BANK_HOLIDAYS.MONTH,
    render: ({ date }) => dateAdapter(date).format('MMMM'),
  },
  {
    label: PAYMENTS.BANK_HOLIDAYS.DAY,
    render: ({ date }) => dateAdapter(date).format('DD'),
  },
  {
    label: PAYMENTS.BANK_HOLIDAYS.YEAR,
    render: ({ date }) => dateAdapter(date).format('YYYY'),
  },
  {
    label: PAYMENTS.BANK_HOLIDAYS.HOLIDAY_NAME,
    render: ({ holidayName }) => holidayName,
    className: 'holidayName',
  },
  {
    label: PAYMENTS.BANK_HOLIDAYS.DATE_ADDED,
    render: ({ createdAt }) => dateAdapter(createdAt).format(dateFormat),
  },
  {
    label: PAYMENTS.BANK_HOLIDAYS.ADDED_BY,
    render: ({ createdBy: { firstName, lastName } }) => `${firstName} ${lastName}`,
    className: 'createdBy',
  },
];

const itemsTemplateMobile = [
  {
    label: PAYMENTS.BANK_HOLIDAYS.DATE,
    render: ({ date }) => dateAdapter(date).format(dateFormat),
  },
  {
    label: PAYMENTS.BANK_HOLIDAYS.HOLIDAY_NAME,
    render: ({ holidayName }) => holidayName,
    className: 'holidayName',
  },
];

const itemsDetailsTemplate = [
  {
    label: PAYMENTS.BANK_HOLIDAYS.DATE_ADDED,
    render: ({ createdAt }) => dateAdapter(createdAt).format(dateFormat),
  },
  {
    label: PAYMENTS.BANK_HOLIDAYS.ADDED_BY,
    render: ({ createdBy: { firstName, lastName } }) => `${firstName} ${lastName}`,
    className: 'createdBy',
  },
];

export { itemsTemplate, itemsTemplateMobile, itemsDetailsTemplate };
