import React from 'react';

import { StatusIndicator } from 'shared/components/StatusIndicator/StatusIndicator';
import { dateWithTimeLuxonFormat } from 'shared/helpers/parsers/date';

import dateAdapter from 'utils/date/dateAdapter';

import { NOTIFICATION } from './notificationConstants';

const itemsTemplate = [
  {
    label: NOTIFICATION.STATUS,
    render: ({ status }) => <StatusIndicator status={status} />,
  },
  {
    label: NOTIFICATION.MESSAGE_SUBJECT,
    render: ({ messageSubject }) => messageSubject,
    highlight: true,
  },
  {
    label: NOTIFICATION.CREATED_ON,
    render: ({ createdAt }) => dateAdapter(createdAt).format(dateWithTimeLuxonFormat),
  },
];

const itemsTemplateMobile = [
  {
    label: NOTIFICATION.STATUS,
    render: ({ status }) => <StatusIndicator status={status} />,
  },
  {
    label: NOTIFICATION.MESSAGE_SUBJECT,
    render: ({ messageSubject }) => messageSubject,
    highlight: true,
  },
];

const [, ...customerItemsTemplate] = itemsTemplate;
const [, ...customerItemsTemplateMobile] = itemsTemplateMobile;
const [, , ...itemsDetailsTemplate] = itemsTemplate;

const alreadyReadFunction = ({ alreadyRead }) => (alreadyRead ? '' : ' New');

itemsTemplate.className = alreadyReadFunction;
itemsTemplateMobile.className = alreadyReadFunction;
customerItemsTemplate.className = alreadyReadFunction;
customerItemsTemplateMobile.className = alreadyReadFunction;

export {
  itemsTemplateMobile,
  itemsTemplate,
  itemsDetailsTemplate,
  customerItemsTemplate,
  customerItemsTemplateMobile,
};
