import React from 'react';
import { connect } from 'react-redux';

import { filterEnterPaymentCompanies } from 'actions/Companies/privilegedCompaniesCreate';
import { getLotsForEnterPayment, clearAssignedLotsAction } from 'actions/Lots/getAssignedLots';
import { getBankHolidays, clearBankHolidays } from 'actions/Payments/bankHolidaysList';
import { enterPayment } from 'actions/Payments/enterPayment';
import {
  getAvailablePaymentTypes,
  clearAvailablePaymentTypes,
} from 'actions/Payments/getAvailablePaymentTypes';
import { clearPaymentAmount, getPaymentAmount } from 'actions/Payments/getPaymentAmount';
import { openModal } from 'actions/shared/modal';
import { clearSharedData } from 'actions/shared/saveSharedData';
import { showSnackbar } from 'actions/shared/snackbar';

import { FormContainer } from 'shared/components/Form';
import { PAYMENTS } from 'shared/helpers/constants/payments/paymentsConstants';
import {
  successOptions,
  dangerOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

import { EnterPaymentForm } from './EnterPaymentForm/EnterPaymentForm';

function EnterPaymentLayout(props) {
  const submit = values =>
    props
      .enterPayment(values)
      .then(res => {
        props.showSnackbar(successOptions, res.message);
        props.history.push(`/payments/${res.id}`);
      })
      .catch(res => {
        props.showSnackbar(dangerOptions, res.message);
      });

  return (
    <FormContainer header={PAYMENTS.REQUEST_PAYMENT} loaded={!props.enterPaymentDetails.isPending}>
      {() => <EnterPaymentForm {...props} onSubmit={submit} />}
    </FormContainer>
  );
}

const mapStateToProps = state => ({
  auth: state.auth,
  enterPaymentDetails: state.enterPaymentDetails,
  assignedLots: state.assignedLots,
  availablePaymentTypes: state.availablePaymentTypes,
  paymentAmount: state.paymentAmount,
  bankHolidaysList: state.bankHolidaysList,
});

const mapDispatchToProps = {
  openModal,
  showSnackbar,
  enterPayment,
  filterEnterPaymentCompanies,
  getLotsForEnterPayment,
  getAvailablePaymentTypes,
  clearAvailablePaymentTypes,
  clearSharedData,
  getPaymentAmount,
  clearPaymentAmount,
  clearAssignedLotsAction,
  getBankHolidays,
  clearBankHolidays,
};

const EnterPayment = connect(mapStateToProps, mapDispatchToProps)(EnterPaymentLayout);

export { EnterPaymentLayout, EnterPayment };
