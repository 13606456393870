import React from 'react';
import { compose } from 'redux';
import { reduxForm, FormSection, FieldArray } from 'redux-form';

import { Form } from 'shared/components/Form';
import { COMPANY } from 'shared/helpers/constants/companies/companyConstants';
import { validate } from 'shared/helpers/validations/company/associationsValidation';
import { multiValidator } from 'shared/helpers/validations/multiValidator';

import { Locations } from './components/Locations/Locations';
import { PrimaryContacts } from './components/PrimaryContacts/PrimaryContacts';

function AddAssociationsBase(props) {
  const { CustomActions, handleSubmit, invalid, submitting } = props;

  return (
    <Form
      header={COMPANY.ASSOCIATIONS}
      onSubmit={handleSubmit}
      CustomActions={CustomActions}
      submitDisabled={invalid || submitting}
    >
      <FormSection name="associations">
        <FieldArray name="primaryContacts" component={PrimaryContacts} />
        <FieldArray name="locations" component={Locations} />
      </FormSection>
    </Form>
  );
}

const AddAssociations = compose(
  reduxForm({
    validate: multiValidator('associations', validate),
  }),
)(AddAssociationsBase);

export { AddAssociations };
