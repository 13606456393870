import axios from 'axios';

import { showSnackbar } from 'actions/shared/snackbar';

import { FILES_CONSTANTS } from 'shared/helpers/constants/filesConstants';
import { dangerOptions } from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { downloadFile } from 'shared/helpers/dataParsers/files/downloadFile';

const API_HOST_AUTH = process.env.REACT_APP_API;

const downloadAttachmentActionType = 'DOWNLOAD_ATTACHMENT';
function downloadAttachmentAction() {
  return {
    type: downloadAttachmentActionType,
  };
}

const downloadAttachmentSuccessActionType = `${downloadAttachmentActionType}_SUCCESS`;
function downloadAttachmentSuccessAction() {
  return {
    type: downloadAttachmentSuccessActionType,
  };
}

const downloadAttachmentFailureActionType = `${downloadAttachmentActionType}_FAILURE`;
function downloadAttachmentFailureAction() {
  return {
    type: downloadAttachmentFailureActionType,
  };
}

function downloadAttachment(path) {
  return dispatch => {
    dispatch(downloadAttachmentAction());

    return axios({
      method: 'post',
      url: `${API_HOST_AUTH}/files/single`,
      data: JSON.stringify({ path }),
      responseType: 'arraybuffer',
    })
      .then(res => {
        dispatch(downloadAttachmentSuccessAction());
        downloadFile(res);
      })
      .catch(() => {
        dispatch(downloadAttachmentFailureAction());
        dispatch(showSnackbar(dangerOptions, FILES_CONSTANTS.ATTACHMENT_DOWNLOAD_ERROR));
      });
  };
}

function downloadApiAttachment(path) {
  return dispatch => {
    dispatch(downloadAttachmentAction());

    return axios({
      method: 'get',
      url: `${API_HOST_AUTH}/${path}`,
      responseType: 'arraybuffer',
    })
      .then(res => {
        dispatch(downloadAttachmentSuccessAction());
        downloadFile(res);
      })
      .catch(() => {
        dispatch(downloadAttachmentFailureAction());
        dispatch(showSnackbar(dangerOptions, FILES_CONSTANTS.ATTACHMENT_DOWNLOAD_ERROR));
      });
  };
}

export {
  downloadAttachmentActionType,
  downloadAttachmentFailureActionType,
  downloadAttachmentSuccessActionType,
  downloadAttachment,
  downloadApiAttachment,
};
