import { statusesForGlobal } from 'shared/helpers/constants/lots/statusesForGlobal';

const markGradingCompleteAccess = (
  {
    access: {
      lots: { markGradingComplete },
    },
  },
  lot,
) => markGradingComplete && lot.statusForGlobal === statusesForGlobal.accepted_for_grading;

export { markGradingCompleteAccess };
