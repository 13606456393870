import PropTypes from 'prop-types';
import styled from 'styled-components';

import { pixelify } from 'shared/helpers/styling/styling';

const Separator = styled.div`
  height: ${({ size }) => pixelify(size)};
  background-color: transparent;
`;

Separator.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

Separator.defaultProps = {
  size: 50,
};

export { Separator };
