const companyStatuses = {
  active: 'active',
  inactive: 'inactive',
  no_terms: 'no_terms',
};

const companyTypesTable = gradingEnabled => ({
  assay: 'assay',
  ...(gradingEnabled && { grading: 'grading' }),
  internal: 'internal',
  external: 'external',
});

const companyDueDiligenceStatus = {
  verified: 'verified',
  unverified: 'unverified',
};

export { companyStatuses, companyTypesTable, companyDueDiligenceStatus };
