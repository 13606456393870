import { Route, Switch } from 'react-router-dom';

import { Authorization } from 'components/Authorization/Authorization';
import { NotFound } from 'components/ErrorPage/ErrorPage';

import { ConnectedCreateUsersNote } from 'shared/components/Notes/CreateNotes/ConnectedCreateNote';
import { usersListAccess } from 'shared/helpers/accesses/usersListAccess';

import { UserDetails } from 'pages/Users/components/UserDetails/UserDetails';
import { CreateUser } from 'pages/Users/components/UsersManagement/components/CreateUser/CreateUser';

import { ChangeUsersPassword } from '../UserProfile/components/ChangeUsersPassword/ChangeUsersPassword';
import { ConnectedUsersList } from './components/UsersList/ConnectedUsersList';
import ConnectedUpdateUser from './components/UsersManagement/components/UpdateUser/ConnectedUpdateUser';

function Users(props) {
  const { users } = props.auth.access;
  const { user } = props.auth;

  const AuthUsersList = Authorization(ConnectedUsersList, usersListAccess(user, props.auth.access));
  const AuthUserDetails = Authorization(UserDetails, users.details.details);
  const AuthCreateUser = Authorization(CreateUser, users.create);
  const AuthUpdateUser = Authorization(ConnectedUpdateUser, users.update);
  const AuthUChangeUsersPassword = Authorization(ChangeUsersPassword, users.changePassword);
  const AuthCreateNoteUsers = Authorization(ConnectedCreateUsersNote, users.details.notesPanel);

  return (
    <Switch>
      <Route exact path="/users" component={AuthUsersList} />
      <Route path="/users/create" component={AuthCreateUser} />
      <Route path="/users/:userId/update" component={AuthUpdateUser} />
      <Route path="/users/:userId/change-password" component={AuthUChangeUsersPassword} />
      <Route path="/users/:id/create-note" component={AuthCreateNoteUsers} />
      <Route path="/users/:id" component={AuthUserDetails} />
      <Route component={NotFound} />
    </Switch>
  );
}

export { Users };
