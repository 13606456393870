import store from 'store';

import { saveUrlAction } from 'actions/shared/redirect';
import { showSnackbar } from 'actions/shared/snackbar';

import { MESSAGES } from 'shared/helpers/constants/messagesConstants';
import { dangerOptions } from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

import { history } from 'utils/history';

const sessionExpiredHandler = error => {
  if (error.response.data.JWTStale) {
    store.dispatch(showSnackbar(dangerOptions, MESSAGES.SESSION_TIMED_OUT));
    store.dispatch(saveUrlAction(history.location.pathname, store.getState().auth.user.id));
    history.push('/logout');
  }
  return Promise.reject(error.response);
};

export { sessionExpiredHandler };
