import React from 'react';

import { UIAuthorization } from 'components/Authorization/UIAuthorization';

import { Button } from 'shared/components/Buttons/Button';
import { Icon } from 'shared/components/Icon/Icon';
import {
  OverviewItemsDetailsTitle,
  OverviewListMobileDetails,
  OverviewListMobileActions,
} from 'shared/components/OverviewList/OverviewListMobileCollapsed/OverviewListMobileCollapsed';
import { OverviewListTableCell } from 'shared/components/OverviewList/OverviewListTableCell/OverviewListTableCell';
import { OverviewTableRow } from 'shared/components/OverviewList/OverviewTableRow/OverviewTableRow';
import { renderHighlight } from 'shared/helpers/parsers/highlight';
import { renderItem } from 'shared/helpers/parsers/lists';
import { cssVar } from 'shared/helpers/styling/styling';

const FullPageListItemDetails = ({
  item,
  itemsTemplate,
  displayDetails,
  searchFilter,
  actionsTemplate,
  actionsContext,
}) => {
  const filteredActionsTemplate = actionsTemplate.filter(
    ({ access }) => !access || access.call(actionsContext, item),
  );

  const renderButton = ({ icon, action, onClick }, index) => (
    <Button
      bgColor={cssVar('outerSpaceBrighter')}
      noOutline
      margin={false}
      borderRadius={10}
      key={index}
      onClick={e => onClick && onClick(item)(e)}
    >
      {action}
      <Icon icon={`icon-${icon} cursor`} color={cssVar('regentGrayBrighter')} />
    </Button>
  );

  return (
    <OverviewTableRow isOpen={displayDetails} mobile mobileDetails>
      <OverviewListTableCell header margin="10px 0">
        <OverviewListMobileDetails>
          {itemsTemplate.map(({ highlight, render, label }, index) => (
            <div key={index}>
              <OverviewItemsDetailsTitle>{label}</OverviewItemsDetailsTitle>
              <div>
                {highlight ? renderHighlight(render, item, searchFilter) : renderItem(render, item)}
              </div>
            </div>
          ))}
          <UIAuthorization access={filteredActionsTemplate.length}>
            <OverviewListMobileActions>
              {filteredActionsTemplate.map(({ location, ...action }, index) =>
                location ? (
                  <a href={location(item)} target="_blank" rel="noopener noreferrer">
                    {renderButton(action, index)}
                  </a>
                ) : (
                  renderButton(action, index)
                ),
              )}
            </OverviewListMobileActions>
          </UIAuthorization>
        </OverviewListMobileDetails>
      </OverviewListTableCell>
    </OverviewTableRow>
  );
};

FullPageListItemDetails.defaultProps = { actionsTemplate: [] };

export { FullPageListItemDetails };
