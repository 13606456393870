import { connect } from 'react-redux';

import { getCompaniesContact } from 'actions/Locations/getCompaniesContact';
import { getRegionList } from 'actions/Locations/getRegionList';
import { getLocation, clearLocationDetails } from 'actions/Locations/locationDetails';
import { updateDefaultPPMs } from 'actions/Locations/updateDefaultPPMs';
import { updateLocation as updateLocationAction } from 'actions/Locations/updateLocation';
import { openModal, closeModal } from 'actions/shared/modal';
import { hideSnackbar, showSnackbar } from 'actions/shared/snackbar';

import { UpdateLocationDefaultPPMs } from './UpdateLocationDefaultPPMs';

const mapStateToProps = ({ auth, modal, updateLocation, locationDetails, defaultPPMs }) => ({
  auth,
  modal,
  updateLocation,
  locationDetails,
  defaultPPMs,
});

const mapDispatchToProps = {
  showSnackbar,
  hideSnackbar,
  updateLocation: updateLocationAction,
  getLocation,
  clearLocationDetails,
  getRegionList,
  getCompaniesContact,
  openModal,
  closeModal,
  updateDefaultPPMs,
};

const ConnectedUpdateLocationDefaultPPMs = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UpdateLocationDefaultPPMs);

export { ConnectedUpdateLocationDefaultPPMs };
