import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { destroy as destroyAction } from 'redux-form';

import { enterPoolPayment } from 'actions/Payments/enterPayment';
import { openModal } from 'actions/shared/modal';
import { showSnackbar } from 'actions/shared/snackbar';

import { Wizard, formName } from 'shared/components/Wizard/Wizard';
import { cancelModal } from 'shared/helpers/constants/modalConstants';
import { PAYMENTS } from 'shared/helpers/constants/payments/paymentsConstants';
import {
  dangerOptions,
  successOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

import { PaymentForm, SettlementForm, FinanceChargesForm } from './components';

const steps = {
  payment: PaymentForm,
  settlement: SettlementForm,
  financeCharges: FinanceChargesForm,
};

const initialValues = {
  payment: {},
  settlement: {},
  financeCharges: {},
};

const EnterPoolPaymentLayout = props => {
  useEffect(() => () => props.destroy(formName), []);

  const onSubmit = useCallback(
    values =>
      props
        .enterPoolPayment(values)
        .then(({ message, id }) => {
          props.showSnackbar(successOptions, message);
          props.history.push(`/payments/${id}`);
        })
        .catch(({ message }) => props.showSnackbar(dangerOptions, message)),
    [],
  );

  const onCancel = useCallback(() => {
    props.openModal(cancelModal, () => {
      props.history.push('/payments');
    });
  }, []);

  return (
    <Wizard
      steps={steps}
      header={PAYMENTS.REQUEST_POOL_PAYMENT}
      onSubmit={onSubmit}
      initialValues={initialValues}
      onCancel={onCancel}
    />
  );
};

const mapDispatchToProps = {
  showSnackbar,
  openModal,
  enterPoolPayment,
  destroy: destroyAction,
};

const EnterPoolPayment = compose(
  withRouter,
  connect(null, mapDispatchToProps),
)(EnterPoolPaymentLayout);

export { EnterPoolPaymentLayout, EnterPoolPayment };
