import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import { FieldDate } from 'shared/components/Fields';
import { FieldRadioButton } from 'shared/components/Fields/FieldRadioButton/FieldRadioButton';
import { Form, Section } from 'shared/components/Form';
import { AGREEMENTS } from 'shared/helpers/constants/agreements/agreementsConstants';
import { cancelModal } from 'shared/helpers/constants/modalConstants';
import { normalize } from 'shared/helpers/parsers/boolean';
import { validate } from 'shared/helpers/validations/agreement/agreementValidations';

import dateAdapter from 'utils/date/dateAdapter';
import { goBackOrTo } from 'utils/history';

class CreateAgreementForm extends Component {
  componentDidMount() {
    const { defaultValues, auth } = this.props;
    this.populateValues(defaultValues, auth);
  }

  onCancel = () => {
    this.props.openModal(cancelModal, () => {
      goBackOrTo(`/companies/${this.props.companyId}`);
    });
  };

  setField = (key, value) => {
    this.props.change(key, value);
  };

  populateValues = (agreement, auth) => {
    const digitalSignatureEnabled = auth?.access?.companies?.details?.agreements?.digitalSignature;

    this.setField('startDate', dateAdapter(agreement.startDate).toDate());
    this.setField(
      'digitalSignatureRequired',
      digitalSignatureEnabled && agreement.digitalSignatureRequired,
    );
  };

  render() {
    const { handleSubmit, invalid, submitting } = this.props;

    return (
      <Form
        header={AGREEMENTS.AGREEMENT}
        onSubmit={handleSubmit}
        onCancel={this.onCancel}
        submitDisabled={invalid || submitting}
      >
        <Section template={['startDate digitalSignatureRequired']}>
          <Field
            name="startDate"
            component={FieldDate}
            label={AGREEMENTS.START_DATE}
            field="required"
            minDate={dateAdapter()}
            disableFlip
          />
          <Field
            name="digitalSignatureRequired"
            component={FieldRadioButton}
            label={AGREEMENTS.DIGITAL_SIGNATURE_REQUIRED}
            field="required"
            normalize={normalize}
            disabled={!this.props.auth?.access?.companies?.details?.agreements?.digitalSignature}
          />
        </Section>
      </Form>
    );
  }
}

const mapStateToProps = state => ({
  companyId: state.companyDetails.company && state.companyDetails.company._id,
});

const ConnectedAgreementForm = reduxForm({
  form: 'AgreementForm',
  initialValues: {
    digitalSignatureRequired: false,
  },
  validate,
})(connect(mapStateToProps)(CreateAgreementForm));

export { ConnectedAgreementForm };
