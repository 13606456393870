import { useDispatch } from 'react-redux';

import { changePackagesState } from 'actions/Payments/changePackagesState';
import { openModal } from 'actions/shared/modal';

import {
  inactivatePackageModal,
  reactivatePackageModal,
} from 'shared/helpers/constants/modalConstants';

export const PackageStateToggle = ({ children }) => {
  const dispatch = useDispatch();

  const inactivatePackage = pkg => {
    dispatch(
      openModal(inactivatePackageModal, () => dispatch(changePackagesState('inactivate', pkg))),
    );
  };

  const reactivatePackage = pkg => {
    dispatch(
      openModal(reactivatePackageModal, () => dispatch(changePackagesState('reactivate', pkg))),
    );
  };

  return children({ inactivatePackage, reactivatePackage });
};
