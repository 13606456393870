const fundsTypes = {
  check: 'check',
  wire: 'wire',
  cash: 'cash',
};

const availableFundsTypes = {
  all: Object.values(fundsTypes),
};

export { fundsTypes, availableFundsTypes };
