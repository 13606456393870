import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const createCompanyActionType = 'CREATE_COMPANY';
function createCompanyAction() {
  return {
    type: createCompanyActionType,
  };
}

const createCompanySuccessActionType = 'CREATE_COMPANY_SUCCESS';
function createCompanySuccessAction() {
  return {
    type: createCompanySuccessActionType,
  };
}

const createCompanyFailureActionType = 'CREATE_COMPANY_FAILURE';
function createCompanyFailureAction() {
  return {
    type: createCompanyFailureActionType,
  };
}

function createCompany(company) {
  return dispatch => {
    dispatch(createCompanyAction());

    return axios
      .post(`${API_HOST_AUTH}/companies/wizard`, company)
      .then(res => {
        dispatch(createCompanySuccessAction());

        return res.data;
      })
      .catch(error => {
        dispatch(createCompanyFailureAction());

        return Promise.reject(error.data);
      });
  };
}

export {
  createCompanyActionType,
  createCompanySuccessActionType,
  createCompanyFailureActionType,
  createCompany,
};
