import React from 'react';

import { PackageForm } from './components/PackageForm/PackageForm';
import { configNames } from './components/PackageForm/fieldsConfig';

function CustomerCreatePackage(props) {
  return (
    <PackageForm
      {...props}
      config={configNames.editPackage}
      sentFromCompany={props.auth.user.relatedCompany}
    />
  );
}

export { CustomerCreatePackage };
