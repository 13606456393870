import axios from 'axios';

import { messageTypes } from 'shared/helpers/constants/snackbar/snackbarMessageTypes';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getFutureHedgesActionType = 'GET_FUTURE_HEDGES';
const getFutureHedgesAction = () => ({ type: getFutureHedgesActionType });

const getFutureHedgesSuccessActionType = `${getFutureHedgesActionType}_SUCCESS`;
const getFutureHedgesSuccessAction = futureHedges => ({
  type: getFutureHedgesSuccessActionType,
  payload: { futureHedges },
});

const getFutureHedgesFailureActionType = `${getFutureHedgesActionType}_FAILURE`;
const getFutureHedgesFailureAction = () => ({
  type: getFutureHedgesFailureActionType,
});

const getFutureHedges = () => {
  return dispatch => {
    dispatch(getFutureHedgesAction());

    return axios({
      method: 'get',
      url: `${API_HOST_AUTH}/hedges/future-hedges`,
    })
      .then(({ data }) => dispatch(getFutureHedgesSuccessAction(data.futureHedges)))
      .catch(e => {
        dispatch(getFutureHedgesFailureAction());
        return Promise.reject({
          message: e.message,
          messageType: messageTypes.error,
        });
      });
  };
};

export {
  getFutureHedgesActionType,
  getFutureHedgesSuccessActionType,
  getFutureHedgesFailureActionType,
  getFutureHedges,
};
