import { isNumber } from 'shared/helpers/parsers/formaters';
import { roundUp } from 'shared/helpers/parsers/number';
import { removeCommasAndParse } from 'shared/helpers/parsers/text';

const calculatePoolPaymentAmount = (
  {
    ouncesPt,
    pricePerOuncePt,
    ouncesPd,
    pricePerOuncePd,
    ouncesRh,
    pricePerOunceRh,
    outstandingBalanceApplied,
  },
  financeCharges = [],
) => {
  const platinumValue =
    isNumber(ouncesPt) && isNumber(pricePerOuncePt)
      ? removeCommasAndParse(ouncesPt) * removeCommasAndParse(pricePerOuncePt)
      : 0;
  const palladiumValue =
    isNumber(ouncesPd) && isNumber(pricePerOuncePd)
      ? removeCommasAndParse(ouncesPd) * removeCommasAndParse(pricePerOuncePd)
      : 0;
  const rhodiumValue =
    isNumber(ouncesRh) && isNumber(pricePerOunceRh)
      ? removeCommasAndParse(ouncesRh) * removeCommasAndParse(pricePerOunceRh)
      : 0;
  const parsedOutstandingBalanceValue = removeCommasAndParse(outstandingBalanceApplied);
  const outstandingBalanceAppliedValue =
    isNumber(parsedOutstandingBalanceValue) && parsedOutstandingBalanceValue > 0
      ? parsedOutstandingBalanceValue
      : 0;

  const sumOfFinanceCharges = financeCharges.reduce(
    (acc, { financeCharge }) =>
      removeCommasAndParse(acc) +
      (isNumber(financeCharge) ? removeCommasAndParse(financeCharge) : 0),
    0,
  );

  return +roundUp(
    platinumValue +
      palladiumValue +
      rhodiumValue -
      outstandingBalanceAppliedValue -
      sumOfFinanceCharges,
  );
};

export { calculatePoolPaymentAmount };
