import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getNotificationsActionType = 'GET_NOTIFICATIONS';
function getNotificationsAction(limit, page, query) {
  return {
    type: getNotificationsActionType,
    payload: { limit, page, query },
  };
}

const getNotificationsSuccessActionType = 'GET_NOTIFICATIONS_SUCCESS';
function getNotificationsSuccessAction({ docs, pages, limit, page, resolvedNotReadCount }) {
  return {
    type: getNotificationsSuccessActionType,
    payload: {
      notifications: docs,
      pages,
      limit,
      page,
      resolvedNotReadCount,
    },
  };
}

const getNotificationsFailureActionType = 'GET_NOTIFICATIONS_FAILURE';
function getNotificationsFailureAction(errorMessage) {
  return {
    type: getNotificationsFailureActionType,
    payload: {
      errorMessage,
    },
  };
}

const clearNotificationsListActionType = 'CLEAR_NOTIFICATIONS_LIST_ACTION';
function clearNotificationsList() {
  return {
    type: clearNotificationsListActionType,
  };
}

function getNotifications(limit, page, query) {
  return dispatch => {
    dispatch(getNotificationsAction(limit, page, query));

    return axios
      .post(`${API_HOST_AUTH}/notifications`, JSON.stringify({ limit, page, query }))
      .then(({ data }) => dispatch(getNotificationsSuccessAction(data)))
      .catch(() => dispatch(getNotificationsFailureAction('')));
  };
}

export {
  getNotificationsActionType,
  getNotificationsSuccessActionType,
  getNotificationsFailureActionType,
  clearNotificationsListActionType,
  clearNotificationsList,
  getNotifications,
};
