import styled, { css } from 'styled-components';

import { DetailsContainer } from 'shared/components/Details/DetailsContainer';

const StyledContainer = styled(DetailsContainer)`
  ${({ config }) => {
    return (
      !config?.noMargin &&
      css`
        margin: 12px 0;
      `
    );
  }}}
`;

const StyledSection = styled.div`
  margin-bottom: 30px;
`;

export { StyledSection, StyledContainer };
