import { connect } from 'react-redux';

import { getCompany, clearCompanyDetails } from 'actions/Companies/companyDetails';
import { createTerms } from 'actions/Terms/createTerms';
import { getSignedTerms } from 'actions/Terms/getSignedTerms';
import { openModal, closeModal } from 'actions/shared/modal';

import { CreateTerm } from './CreateTerm';

function mapStateToProps({ auth, router, modal, companyDetails, termsDetails, signedTerms }) {
  return {
    auth,
    router,
    modal,
    companyDetails,
    termsDetails,
    signedTerms,
  };
}

const mapDispatchToProps = {
  getCompany,
  clearCompanyDetails,
  createTerms,
  openModal,
  closeModal,
  getSignedTerms,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateTerm);
