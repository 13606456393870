import { changeLocationStatusSuccessActionType } from 'actions/Locations/changeLocationStatus';
import {
  getLocationActionType,
  getLocationFailureActionType,
  getLocationSuccessActionType,
  clearLocationDetailsActionType,
} from 'actions/Locations/locationDetails';

const INITIAL_STATE = {
  isPending: false,
  errorMessage: null,
  location: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getLocationActionType:
      return {
        location: null,
        isPending: true,
      };
    case getLocationSuccessActionType:
      return {
        ...state,
        isPending: false,
        location: action.payload.location,
      };
    case getLocationFailureActionType:
      return {
        ...state,
        isPending: false,
        errorMessage: action.payload.errorMessage,
      };
    case clearLocationDetailsActionType:
      return {
        isPending: false,
        errorMessage: null,
        location: null,
      };
    case changeLocationStatusSuccessActionType:
      return {
        ...state,
        location: state.location
          ? {
              ...state.location,
              status: action.payload.location.status,
              shipmentEligible: action.payload.location.shipmentEligible,
              updatedBy: action.payload.location.updatedBy,
              updatedAt: action.payload.location.updatedAt,
            }
          : null,
      };
    default:
      return state;
  }
};
