import { COMMA_VALIDATION } from '../constants/sharedConstants';

const disallowComa = (name, value) => {
  if (value && String(value).includes(','))
    return { [name]: COMMA_VALIDATION.USE_PERIOD_FOR_FRACTIONS };
};

const allowComma = (name, value) => {
  if (value) {
    const splittedOnCommas = String(value).split(',');
    const splittedOnDots = String(value).split('.');

    if (splittedOnDots.length > 2) return { [name]: COMMA_VALIDATION.USE_COMMA_TO_SEPARATE };

    const lessThanThree = splittedOnCommas.some((val, i) => i !== 0 && val.length < 3);

    const leadingZeros = splittedOnCommas.length > 1 && /^0/.test(splittedOnCommas[0]);

    if (leadingZeros) return { [name]: COMMA_VALIDATION.REMOVE_LEADING_ZEROS };
    if (lessThanThree) return { [name]: COMMA_VALIDATION.COMMA_MUST_BE_FALLOWED_BY_THREE_DIGITS };
  }
};

const commaValidator = (fields, error) => {
  const fieldsNames = Object.keys(fields);

  const errors = fieldsNames.map(name => {
    switch (name) {
      case 'advanceRate':
      case 'foilCannedPlatinumAssay':
      case 'foilCannedPalladiumAssay':
      case 'foilCannedRhodiumAssay':
      case 'globalMoistureTest':
      case 'materialUnits':
      case 'materialWeight':
      case 'monoPlatinumAssay':
      case 'monoPalladiumAssay':
      case 'monoRhodiumAssay':
      case 'metalsReturnFinanceRate':
      case 'ouncesPt':
      case 'ouncesPd':
      case 'ouncesRh':
      case 'platinumOz':
      case 'palladiumOz':
      case 'rhodiumOz':
      case 'platinumReturnRate':
      case 'palladiumReturnRate':
      case 'rhodiumReturnRate':
      case 'sensorsPlatinumAssay':
      case 'sensorsPalladiumAssay':
      case 'sensorsRhodiumAssay':
      case 'weightGrossReceived':
      case 'weightTareReceived':
      case 'weightNetWetReceived':
      case 'weightScrapReceived':
      case 'weightAdjustedNetWetReceived':
      case 'percentMoisture':
      case 'weightNetDryReceived':
      case 'weightGrossReceivedH':
      case 'weightTareReceivedH':
      case 'weightNetWetReceivedH':
      case 'weightScrapReceivedH':
      case 'weightAdjustedNetWetReceivedH':
      case 'percentMoistureH':
      case 'weightNetDryReceivedH':
      case 'weightGrossReceivedF':
      case 'weightTareReceivedF':
      case 'weightNetWetReceivedF':
      case 'weightScrapReceivedF':
      case 'weightAdjustedNetWetReceivedF':
      case 'percentMoistureF':
      case 'weightNetDryReceivedF':
      case 'platinumAssay':
      case 'palladiumAssay':
      case 'rhodiumAssay':
      case 'platinumAssayH':
      case 'palladiumAssayH':
      case 'rhodiumAssayH':
      case 'platinumAssayF':
      case 'palladiumAssayF':
      case 'rhodiumAssayF':
      case 'platinumContainedOz':
      case 'palladiumContainedOz':
      case 'rhodiumContainedOz':
      case 'platinumContainedOzH':
      case 'palladiumContainedOzH':
      case 'rhodiumContainedOzH':
      case 'platinumContainedOzF':
      case 'palladiumContainedOzF':
      case 'rhodiumContainedOzF':
      case 'platinumReturnableOz':
      case 'palladiumReturnableOz':
      case 'rhodiumReturnableOz':
      case 'platinumReturnableOzH':
      case 'palladiumReturnableOzH':
      case 'rhodiumReturnableOzH':
      case 'platinumReturnableOzF':
      case 'palladiumReturnableOzF':
      case 'rhodiumReturnableOzF':
      case 'platinumOuncesRemoved':
      case 'palladiumOuncesRemoved':
      case 'rhodiumOuncesRemoved':
      case 'carbonPercentage':
      case 'carbonPercentageH':
      case 'carbonPercentageF':
      case 'weightGrossDeclared':
      case 'weightTareDeclared':
      case 'weightGrossActual':
      case 'weightTareActual':
      case 'financeRate':
      case 'updateWeightTareActual':
      case 'updateWeightGrossActual':
      case 'weight':
        return disallowComa(name, fields[name]);
      case 'adjustment':
      case 'credits':
      case 'deductions':
      case 'estimatedFunds':
      case 'finalPlatinumPrice':
      case 'finalPalladiumPrice':
      case 'finalRhodiumPrice':
      case 'hedgingPlatinumPrice':
      case 'hedgingPalladiumPrice':
      case 'hedgingRhodiumPrice':
      case 'largeLotCharge':
      case 'manualPrice':
      case 'outstandingBalanceApplied':
      case 'paymentAmount':
      case 'pricePerOuncePt':
      case 'pricePerOuncePd':
      case 'pricePerOunceRh':
      case 'platinumPrice':
      case 'palladiumPrice':
      case 'rhodiumPrice':
      case 'platinumPriceActual':
      case 'palladiumPriceActual':
      case 'rhodiumPriceActual':
      case 'platinumPriceCust':
      case 'palladiumPriceCust':
      case 'rhodiumPriceCust':
      case 'platinumRefiningCharge':
      case 'palladiumRefiningCharge':
      case 'rhodiumRefiningCharge':
      case 'receivedFromStillwater':
      case 'paidByGlobal':
      case 'settlementPlatinumPrice':
      case 'settlementPalladiumPrice':
      case 'settlementRhodiumPrice':
      case 'smallLotCharge':
      case 'totalFinalPayment':
      case 'treatmentChargePerPound':
      case 'value':
      case 'financeCharge':
      case 'lotAdjustmentValue':
        return allowComma(name, fields[name]);
      default:
        return null;
    }
  });

  return Object.assign(error, ...errors);
};

export { commaValidator };
