import { hedgeStatusOverlay, hedgeStatus } from 'shared/helpers/constants/hedges/hedgeStatus';

import { MomentAdapter } from 'utils/date/momentAdapter';

const parseOpenStatus = ({ status, requestedHedgeDate }) => {
  if (!requestedHedgeDate) {
    return status;
  }
  const currentTime = new MomentAdapter().getTime();

  const requestedHedgeDateWithTimezone = MomentAdapter.formatLocal(requestedHedgeDate, 'x');

  return [hedgeStatus.open, hedgeStatus.approved].includes(status) &&
    +requestedHedgeDateWithTimezone > currentTime
    ? hedgeStatusOverlay.scheduled
    : status;
};

export { parseOpenStatus };
