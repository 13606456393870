import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const checkLotSuffixActionType = 'CHECK_LOT_SUFFIX';
const checkLotSuffixAction = () => ({
  type: checkLotSuffixActionType,
});

const checkLotSuffixSuccessActionType = `${checkLotSuffixActionType}_SUCCESS`;
const checkLotSuffixSuccessAction = result => ({
  type: checkLotSuffixSuccessActionType,
  payload: {
    result,
  },
});

const checkLotSuffixFailureActionType = `${checkLotSuffixActionType}_FAILURE`;
const checkLotSuffixFailureAction = () => ({
  type: checkLotSuffixFailureActionType,
});

const checkLotSuffix = (lotSuffix, companyId) => dispatch => {
  dispatch(checkLotSuffixAction());

  return axios
    .post(`${API_HOST_AUTH}/locations/validated/lot-suffix`, {
      lotSuffix,
      companyId,
    })
    .then(res => {
      dispatch(checkLotSuffixSuccessAction(res));

      return res.data;
    })
    .catch(err => {
      dispatch(checkLotSuffixFailureAction());

      return Promise.reject(err.data);
    });
};

export {
  checkLotSuffixActionType,
  checkLotSuffixSuccessActionType,
  checkLotSuffixFailureActionType,
  checkLotSuffix,
};
