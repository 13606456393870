import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { getAssignedLotsForOutgoingShipment } from 'actions/Lots/getAssignedLots';
import { clearShipmentDetailsAction, getShipment } from 'actions/Shipments/shipmentDetails';
import { updateOutgoingShipment } from 'actions/Shipments/updateShipment';
import { openModal, closeModal } from 'actions/shared/modal';
import { showSnackbar } from 'actions/shared/snackbar';

import { UpdateOutgoingShipment } from './UpdateOutgoingShipment';

const mapStateToProps = state => ({
  auth: state.auth,
  router: state.router,
  modal: state.modal,
  assignedLots: state.assignedLots,
  shipmentDetails: state.shipmentDetails,
  outgoingLotsOverview: state.outgoingLotsOverview,
});

const mapDispatchToProps = {
  updateOutgoingShipment,
  getShipment,
  clearShipmentDetailsAction,
  getAssignedLotsForOutgoingShipment,
  openModal,
  closeModal,
  showSnackbar,
};

const ConnectedUpdateOutgoingShipment = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UpdateOutgoingShipment),
);

export { ConnectedUpdateOutgoingShipment };
