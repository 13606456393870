import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { downloadAttachment } from 'actions/Attachments/downloadAttachment';

import { renderDetailsContainer } from 'shared/components/Details/helpers/renderDetailsContainer';

import {
  CustomerPackagesOverview,
  ShipmentsOverview,
} from 'pages/Lots/components/LotDetails/components/components';

import { MainLotDetails } from '../MainDetails/MainDetails';
import {
  lotDetailsContainerConfig,
  lotDetailsFieldsConfig,
  weightContainerConfig,
  weightFieldsConfig,
} from './config/fieldsConfig';

export const LotDetailsGrading = ({ auth, lot, packagesOverviewList }) => {
  const dispatch = useDispatch();

  const handleDownloadAttachment = path => {
    dispatch(downloadAttachment(path));
  };

  return (
    <>
      <MainLotDetails lot={lot} auth={auth} packagesList={packagesOverviewList.packagesList} />
      {renderDetailsContainer(
        lotDetailsContainerConfig,
        lotDetailsFieldsConfig,
        lot,
        handleDownloadAttachment,
      )}
      {renderDetailsContainer(weightContainerConfig, weightFieldsConfig, lot)}
      <ShipmentsOverview />
      <CustomerPackagesOverview packagesOverviewList={packagesOverviewList} auth={auth} />
    </>
  );
};

LotDetailsGrading.propTypes = {
  auth: PropTypes.object,
  packagesOverviewList: PropTypes.object,
  lot: PropTypes.object,
};
