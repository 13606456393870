import {
  enterPricesActionType,
  enterPricesFailureActionType,
  enterPricesSuccessActionType,
} from 'actions/Hedges/enterPrices';

const INITIAL_STATE = {
  isPending: false,
};

const enterPricesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case enterPricesActionType:
      return {
        isPending: true,
      };

    case enterPricesSuccessActionType:
    case enterPricesFailureActionType:
      return {
        isPending: false,
      };

    default:
      return state;
  }
};

export { enterPricesReducer };
