import {
  getOutgoingShipmentsOverviewActionType,
  getOutgoingShipmentsOverviewSuccessActionType,
  getOutgoingShipmentsOverviewFailureActionType,
  clearOutgoingShipmentsOverviewActionType,
} from 'actions/Shipments/shipmentsOverview';

const INITIAL_STATE = {
  isPending: false,
  items: null,
};

const OutgoingShipmentsOverviewReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getOutgoingShipmentsOverviewActionType:
      return {
        ...state,
        isPending: true,
      };
    case getOutgoingShipmentsOverviewSuccessActionType:
      return {
        isPending: false,
        items: action.payload.data,
      };
    case getOutgoingShipmentsOverviewFailureActionType:
      return {
        ...state,
        isPending: false,
      };
    case clearOutgoingShipmentsOverviewActionType:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};

export { OutgoingShipmentsOverviewReducer };
