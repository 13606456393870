import {
  createPackageActionType,
  createPackageSuccessActionType,
  createPackageFailureActionType,
} from 'actions/Packages/createPackage';

const INITIAL_STATE = {
  isPending: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case createPackageActionType:
      return {
        isPending: true,
      };
    case createPackageFailureActionType:
      return {
        isPending: false,
      };
    case createPackageSuccessActionType:
      return {
        isPending: false,
      };
    default:
      return state;
  }
};
