import { ENTER_SETTLED_OUNCES } from 'shared/helpers/constants/settlements/settlementsConstants';
import { dateToTimestamp } from 'shared/helpers/parsers/date';
import { commaValidator } from 'shared/helpers/validations/commaValidator';
import { FLOAT_REGEX_GEN, PRICE_REGEX } from 'shared/helpers/validations/regularExpressions';

import dateAdapter from 'utils/date/dateAdapter';

function validateSettledOunce(settledOunce, props) {
  const error = {};
  const maxDate = dateAdapter().endOf('d').valueOf();

  if (!settledOunce.relatedCompany) {
    error.relatedCompany = ENTER_SETTLED_OUNCES.VALIDATION.RELATED_COMPANY_EMPTY;
  }

  if (!settledOunce.relatedLot) {
    error.relatedLot = ENTER_SETTLED_OUNCES.VALIDATION.RELATED_LOT_EMPTY;
  }

  if (!settledOunce.ouncesPt) {
    error.ouncesPt = ENTER_SETTLED_OUNCES.VALIDATION.OUNCES_PT_EMPTY;
  } else if (settledOunce.ouncesPt.length > 10) {
    error.ouncesPt = ENTER_SETTLED_OUNCES.VALIDATION.OUNCES_PT_TOO_LONG;
  } else if (!FLOAT_REGEX_GEN(3).test(settledOunce.ouncesPt)) {
    error.ouncesPt = ENTER_SETTLED_OUNCES.VALIDATION.OUNCES_PT_FORMAT;
  } else if (+settledOunce.ouncesPt.replace(',', '.') < 0) {
    error.ouncesPt = ENTER_SETTLED_OUNCES.VALIDATION.OUNCES_PT_ZERO;
  }

  if (!settledOunce.ouncesPd) {
    error.ouncesPd = ENTER_SETTLED_OUNCES.VALIDATION.OUNCES_PD_EMPTY;
  } else if (settledOunce.ouncesPd.length > 10) {
    error.ouncesPd = ENTER_SETTLED_OUNCES.VALIDATION.OUNCES_PD_TOO_LONG;
  } else if (!FLOAT_REGEX_GEN(3).test(settledOunce.ouncesPd)) {
    error.ouncesPd = ENTER_SETTLED_OUNCES.VALIDATION.OUNCES_PD_FORMAT;
  } else if (+settledOunce.ouncesPd.replace(',', '.') < 0) {
    error.ouncesPd = ENTER_SETTLED_OUNCES.VALIDATION.OUNCES_PD_ZERO;
  }

  if (!settledOunce.ouncesRh) {
    error.ouncesRh = ENTER_SETTLED_OUNCES.VALIDATION.OUNCES_RH_EMPTY;
  } else if (settledOunce.ouncesRh.length > 10) {
    error.ouncesRh = ENTER_SETTLED_OUNCES.VALIDATION.OUNCES_RH_TOO_LONG;
  } else if (!FLOAT_REGEX_GEN(3).test(settledOunce.ouncesRh)) {
    error.ouncesRh = ENTER_SETTLED_OUNCES.VALIDATION.OUNCES_RH_FORMAT;
  } else if (+settledOunce.ouncesRh.replace(',', '.') < 0) {
    error.ouncesRh = ENTER_SETTLED_OUNCES.VALIDATION.OUNCES_RH_ZERO;
  }

  if (
    +settledOunce.ouncesPt === 0 &&
    +settledOunce.ouncesPd === 0 &&
    +settledOunce.ouncesRh === 0
  ) {
    error.ouncesPt = ENTER_SETTLED_OUNCES.VALIDATION.ALL_OUNCES_ZERO;
    error.ouncesPd = ENTER_SETTLED_OUNCES.VALIDATION.ALL_OUNCES_ZERO;
    error.ouncesRh = ENTER_SETTLED_OUNCES.VALIDATION.ALL_OUNCES_ZERO;
  }

  if (settledOunce.pricePerOuncePt) {
    if (!PRICE_REGEX.test(settledOunce.pricePerOuncePt)) {
      error.pricePerOuncePt = ENTER_SETTLED_OUNCES.VALIDATION.PRICE_PER_OUNCE_PT_FORMAT;
    } else if (settledOunce.pricePerOuncePt.length > 10) {
      error.pricePerOuncePt = ENTER_SETTLED_OUNCES.VALIDATION.PRICE_PER_OUNCE_PT_TOO_LONG;
    } else if (+settledOunce.pricePerOuncePt.replace(',', '.') <= 0) {
      error.pricePerOuncePt = ENTER_SETTLED_OUNCES.VALIDATION.PRICE_PER_OUNCE_PT_ZERO;
    } else if (settledOunce.pricePerOuncePt !== settledOunce.pricePerOuncePtConfirm) {
      error.pricePerOuncePtConfirm = ENTER_SETTLED_OUNCES.VALIDATION.PRICE_PER_OUNCE_PT_NOT_MATCH;
    }
  }

  if (settledOunce.pricePerOuncePd) {
    if (!PRICE_REGEX.test(settledOunce.pricePerOuncePd)) {
      error.pricePerOuncePd = ENTER_SETTLED_OUNCES.VALIDATION.PRICE_PER_OUNCE_PD_FORMAT;
    } else if (settledOunce.pricePerOuncePd.length > 10) {
      error.pricePerOuncePd = ENTER_SETTLED_OUNCES.VALIDATION.PRICE_PER_OUNCE_PD_TOO_LONG;
    } else if (+settledOunce.pricePerOuncePd.replace(',', '.') <= 0) {
      error.pricePerOuncePd = ENTER_SETTLED_OUNCES.VALIDATION.PRICE_PER_OUNCE_PD_ZERO;
    } else if (settledOunce.pricePerOuncePd !== settledOunce.pricePerOuncePdConfirm) {
      error.pricePerOuncePdConfirm = ENTER_SETTLED_OUNCES.VALIDATION.PRICE_PER_OUNCE_PD_NOT_MATCH;
    }
  }

  if (settledOunce.pricePerOunceRh) {
    if (!PRICE_REGEX.test(settledOunce.pricePerOunceRh)) {
      error.pricePerOunceRh = ENTER_SETTLED_OUNCES.VALIDATION.PRICE_PER_OUNCE_RH_FORMAT;
    } else if (settledOunce.pricePerOunceRh.length > 10) {
      error.pricePerOunceRh = ENTER_SETTLED_OUNCES.VALIDATION.PRICE_PER_OUNCE_RH_TOO_LONG;
    } else if (+settledOunce.pricePerOunceRh.replace(',', '.') <= 0) {
      error.pricePerOunceRh = ENTER_SETTLED_OUNCES.VALIDATION.PRICE_PER_OUNCE_RH_ZERO;
    } else if (settledOunce.pricePerOunceRh !== settledOunce.pricePerOunceRhConfirm) {
      error.pricePerOunceRhConfirm = ENTER_SETTLED_OUNCES.VALIDATION.PRICE_PER_OUNCE_RH_NOT_MATCH;
    }
  }

  if (!settledOunce.requestedOnDate) {
    error.requestedOnDate = ENTER_SETTLED_OUNCES.VALIDATION.REQUESTED_ON_DATE_EMPTY;
  } else if (dateToTimestamp(settledOunce.requestedOnDate) > maxDate) {
    error.requestedOnDate = ENTER_SETTLED_OUNCES.VALIDATION.REQUESTED_ON_DATE_FUTURE;
  }

  if (!settledOunce.requestedOnTime) {
    error.requestedOnTime = ENTER_SETTLED_OUNCES.VALIDATION.REQUESTED_ON_TIME_EMPTY;
  }

  if (!settledOunce.requestedBy) {
    error.requestedBy = ENTER_SETTLED_OUNCES.VALIDATION.REQUESTED_BY_EMPTY;
  }

  commaValidator(
    {
      ouncesPt: settledOunce.ouncesPt,
      ouncesPd: settledOunce.ouncesPd,
      ouncesRh: settledOunce.ouncesRh,
      pricePerOuncePt: settledOunce.pricePerOuncePt,
      pricePerOuncePd: settledOunce.pricePerOuncePd,
      pricePerOunceRh: settledOunce.pricePerOunceRh,
    },
    error,
  );

  return error;
}

function validate(values, props) {
  return {
    settledOunces: (values.settledOunces || []).map(settledOunce =>
      validateSettledOunce(settledOunce, props),
    ),
  };
}

export { validate };
