import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getGrandTotalValueActionType = 'GET_GRAND_TOTAL_VALUE';
const getGrandTotalValueAction = () => ({
  type: getGrandTotalValueActionType,
});

const getGrandTotalValueSuccessActionType = `${getGrandTotalValueActionType}_SUCCESS`;
const getGrandTotalValueSuccessAction = ({ grandTotal, totalOverall }) => ({
  type: getGrandTotalValueSuccessActionType,
  payload: {
    grandTotal,
    totalOverall,
  },
});

const getGrandTotalValueFailureActionType = `${getGrandTotalValueActionType}_FAILURE`;
const getGrandTotalValueFailureAction = () => ({
  type: getGrandTotalValueFailureActionType,
});

const clearGrandTotalValueActionType = 'CLEAR_GRAND_TOTAL_VALUE';
const clearGrandTotalValue = () => ({
  type: clearGrandTotalValueActionType,
});

const getGrandTotalValue = () => dispatch => {
  dispatch(getGrandTotalValueAction());

  return axios
    .get(`${API_HOST_AUTH}/pool-accounts/grand-total`)
    .then(({ data }) => {
      dispatch(getGrandTotalValueSuccessAction(data));
      return data;
    })
    .catch(err => Promise.reject(dispatch(getGrandTotalValueFailureAction(err))));
};

export {
  getGrandTotalValueActionType,
  getGrandTotalValueAction,
  getGrandTotalValueSuccessActionType,
  getGrandTotalValueSuccessAction,
  getGrandTotalValueFailureActionType,
  getGrandTotalValueFailureAction,
  getGrandTotalValue,
  clearGrandTotalValueActionType,
  clearGrandTotalValue,
};
