import { connect } from 'react-redux';

import { getUser } from 'actions/Users/Details/userDetails';
import { updateDashboardPreferences } from 'actions/Users/dashboardPreferences';
import { updateNotificationPreferences } from 'actions/Users/notificationPreferences';
import { closeModal, openModal } from 'actions/shared/modal';
import { showSnackbar } from 'actions/shared/snackbar';

import { UserProfileLayout } from './UserProfile';

const mapStateToProps = ({
  auth,
  router,
  modal,
  userDetails,
  updatingNotificationPreferences,
}) => ({
  auth,
  router,
  modal,
  userDetails,
  updatingNotificationPreferences,
});

const mapDispatchToProps = {
  openModal,
  closeModal,
  updateNotificationPreferences,
  updateDashboardPreferences,
  showSnackbar,
  getUser,
};

const UserProfile = connect(mapStateToProps, mapDispatchToProps)(UserProfileLayout);

export { UserProfile };
