const userHierarchy = {
  accessLevel: {
    customer: [
      {
        value: 'full_hedging',
        label: 'Full hedging',
      },
      {
        value: 'limited_hedging',
        label: 'Limited hedging',
      },
      {
        value: 'view_and_ship',
        label: 'View and ship',
      },
      {
        value: 'ship_only',
        label: 'Ship only',
      },
    ],
    grading: [
      {
        value: 'standard',
        label: 'Standard',
      },
    ],
    external: [
      {
        value: 'standard',
        label: 'Standard',
      },
    ],
    owner: [
      {
        value: 'standard',
        label: 'Standard',
      },
      {
        value: 'assist',
        label: 'Assist',
      },
    ],
    buyer: [
      {
        value: 'standard',
        label: 'Standard',
      },
    ],
    support: [
      {
        value: 'standard',
        label: 'Standard',
      },
      {
        value: 'assist',
        label: 'Assist',
      },
    ],
    warehouse: [
      {
        value: 'standard',
        label: 'Standard',
      },
      {
        value: 'assist',
        label: 'Assist',
      },
      {
        value: 'limited',
        label: 'Limited',
      },
    ],
  },
};

export { userHierarchy };
