import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { cssVar, pxToRem, media } from 'shared/helpers/styling/styling';

const TypeContainer = styled.div`
  font-size: ${pxToRem(12)};
  display: flex;
  padding: 5px 25px;
  align-items: center;
`;

const TypeColor = styled.div`
  background-color: ${({ color }) => cssVar(color)};
  height: 20px;
  width: 20px;
  border-radius: 10px;
  margin-right: 15px;
`;

const LegendLayout = ({ className, legendConfig }) => (
  <div className={className}>
    {legendConfig.map(el => (
      <TypeContainer key={el.label}>
        <TypeColor color={el.color} />
        {el.label}
      </TypeContainer>
    ))}
  </div>
);

const Legend = styled(LegendLayout)`
  background-color: ${({ color }) => color};
  border: 1px solid ${cssVar('riverBedCalendar')};
  color: ${cssVar('whiteGRG')};
  height: 65px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  ${media.smallMobile`
    flex-direction: column;
    align-items: flex-start;
    height: fit-content;
    justify-content: space-around;
  `}
`;

Legend.propTypes = {
  color: PropTypes.string,
};

Legend.defaultProps = {
  color: cssVar('calendarLegend'),
};

export { Legend };
