import axios from 'axios';

import {
  showPDFPreview,
  fetchPDFPreviewAction,
  fetchPDFPreviewFailureAction,
} from 'actions/shared/PDFPreview';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getPDFDocument = fileId => dispatch => {
  dispatch(fetchPDFPreviewAction());

  return axios
    .get(`${API_HOST_AUTH}/files/single/${fileId}`, { responseType: 'arraybuffer' })
    .then(({ data, headers }) => {
      const blob = new Blob([data], { type: headers['content-type'] });
      const blobURL = window.URL.createObjectURL(blob);

      return dispatch(showPDFPreview(blobURL));
    })
    .catch(error => {
      dispatch(fetchPDFPreviewFailureAction());
      return Promise.reject(error.data);
    });
};

export { getPDFDocument };
