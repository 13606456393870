import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { pxToRem, cssVar, media } from 'shared/helpers/styling/styling';

const SectionLayout = ({ className, text, children }) => (
  <div className={className}>
    {text}
    {children}
  </div>
);

const Section = styled(SectionLayout)`
  color: ${({ date }) => cssVar(date ? 'gullGray' : 'alabaster')};
  font-size: ${pxToRem(14)};
  font-weight: 300;
  word-break: break-word;
  overflow: hidden;

  ${media.mobile`
    margin-bottom: 15px;
    &:first-child { margin-bottom: 5px }
    &:last-child { margin-bottom: 0 }
  `}
`;

Section.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  date: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export { Section };
