import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getLotDetailsActionType = 'GET_LOT_DETAILS';
function getLotDetailsAction() {
  return {
    type: getLotDetailsActionType,
  };
}

const getLotDetailsSuccessActionType = 'GET_LOT_DETAILS_SUCCESS';
function getLotDetailsSuccessAction(lot) {
  return {
    type: getLotDetailsSuccessActionType,
    payload: {
      lot,
    },
  };
}

const getLotDetailsFailureActionType = 'GET_LOT_DETAILS_FAILURE';
function getLotDetailsFailureAction(errorMessage) {
  return {
    type: getLotDetailsFailureActionType,
    payload: {
      errorMessage,
    },
  };
}

const clearLotDetailsActionType = 'CLEAR_LOT_DETAILS_ACTION';
function clearLotDetailsAction() {
  return {
    type: clearLotDetailsActionType,
  };
}

function getLot(lotId) {
  return dispatch => {
    dispatch(getLotDetailsAction());

    return axios
      .get(`${API_HOST_AUTH}/lots/${lotId}`)
      .then(lot => {
        dispatch(getLotDetailsSuccessAction(lot.data));
        return lot.data;
      })
      .catch(err => {
        dispatch(getLotDetailsFailureAction(err));
        return Promise.reject(err.data);
      });
  };
}

function clearLotDetails() {
  return dispatch => {
    dispatch(clearLotDetailsAction());
  };
}

export {
  getLotDetailsActionType,
  getLotDetailsAction,
  getLotDetailsSuccessActionType,
  getLotDetailsSuccessAction,
  getLotDetailsFailureActionType,
  getLotDetailsFailureAction,
  clearLotDetailsActionType,
  clearLotDetailsAction,
  clearLotDetails,
  getLot,
};
