import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { bankHolidayAvailability } from 'actions/Payments/bankHolidayAvailability';

import { PAYMENTS } from 'shared/helpers/constants/payments/paymentsConstants';

import { Alert } from './StyledBankHolidayAlert';

const BankHolidayAlert = () => {
  const dispatch = useDispatch();

  const { isPending, isBankHoliday } = useSelector(
    ({ bankHolidayAvailability }) => bankHolidayAvailability,
  );

  useEffect(() => {
    dispatch(bankHolidayAvailability());
  }, []);

  return (
    !isPending && isBankHoliday && <Alert>{PAYMENTS.BANK_HOLIDAYS.TODAY_IS_A_BANK_HOLIDAY}</Alert>
  );
};

export { BankHolidayAlert };
