import axios from 'axios';

import { PAYMENTS } from 'shared/helpers/constants/payments/paymentsConstants';
import { messageTypes } from 'shared/helpers/constants/snackbar/snackbarMessageTypes';
import { parseMarkAsPaidData } from 'shared/helpers/dataParsers/payments/parseMarkAsPaidData';

const API_HOST_AUTH = process.env.REACT_APP_API;

const paymentMarkAsPaidActionType = 'PAYMENT_MARK_AS_PAID';
const paymentMarkAsPaidAction = () => ({
  type: paymentMarkAsPaidActionType,
});

const paymentMarkAsPaidSuccessActionType = `${paymentMarkAsPaidActionType}_SUCCESS`;
const paymentMarkAsPaidSuccessAction = payment => ({
  type: paymentMarkAsPaidSuccessActionType,
  payload: { payment },
});

const paymentMarkAsPaidFailureActionType = `${paymentMarkAsPaidActionType}_FAILURE`;
const paymentMarkAsPaidFailureAction = () => ({
  type: paymentMarkAsPaidFailureActionType,
});

const paymentMarkAsPaid = (id, paidAt) => dispatch => {
  dispatch(paymentMarkAsPaidAction());

  return axios
    .patch(`${API_HOST_AUTH}/payments/mark-as-paid/${id}`, parseMarkAsPaidData(paidAt))
    .then(res => {
      dispatch(paymentMarkAsPaidSuccessAction(res.data.payment));

      return {
        message: res.data.message,
        messageType: messageTypes.success,
      };
    })
    .catch(error => {
      dispatch(paymentMarkAsPaidFailureAction());

      return Promise.reject({
        message: `${PAYMENTS.ERROR.MARK_AS_PAID_ERROR} ${error.data.message}`,
        messageType: messageTypes.error,
      });
    });
};

export {
  paymentMarkAsPaid,
  paymentMarkAsPaidActionType,
  paymentMarkAsPaidSuccessActionType,
  paymentMarkAsPaidFailureActionType,
};
