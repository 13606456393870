import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getCompanyHedges } from 'actions/Hedges/getCompanyHedges';
import { getHedgesOverviewList } from 'actions/Hedges/hedgesOverviewList';

import { OverviewList } from 'shared/components/OverviewList/OverviewList';
import { HEDGES } from 'shared/helpers/constants/hedges/hedgesConstants';
import {
  itemsTemplateMobile,
  itemsCustomerExistingHedgesTemplate,
  itemsCustomerExistingBlanketHedgesTemplate,
  itemsOwnerExistingHedgesTemplate,
  itemsOwnerExistingBlanketHedgesTemplate,
} from 'shared/helpers/constants/hedges/hedgesListTableConfig';
import { isTablet } from 'shared/helpers/constants/resolutionsConstants';
import { isBuyerUser, isCustomerUser } from 'shared/helpers/matchers/checkUserType';
import { isGlobalRefiningGroup } from 'shared/helpers/matchers/isGlobalRefiningGroup';
import { isGlobalUserType } from 'shared/helpers/matchers/isGlobalUserType';

import { getBrowserWidth } from 'utils/responsive';

import { TablePadding, TableTitle } from './StyledExistingHedges';

const ExistingHedges = ({
  hedgeType,
  companyId,
  lotId,
  relatedLotEnabled,
  auth,
  relatedCompany,
  hedge,
}) => {
  const dispatch = useDispatch();
  const windowWidth = getBrowserWidth();
  const lotHedges = useSelector(({ hedgesOverviewList }) => hedgesOverviewList);
  const companyHedges = useSelector(({ companyHedgesReducer }) => companyHedgesReducer);

  const hasItems =
    (!relatedLotEnabled && companyHedges.existingHedges[hedge]?.items?.docs.length) ||
    (relatedLotEnabled && lotHedges.existingHedges[hedge]?.items?.docs.length);
  const getHedgesData = () => {
    relatedLotEnabled
      ? dispatch(getHedgesOverviewList(lotId, 0, hedge))
      : dispatch(getCompanyHedges(companyId, hedgeType, hedge));
  };

  useEffect(() => {
    ((!relatedLotEnabled && companyId) || lotId) && getHedgesData();
  }, [lotId, relatedLotEnabled]);

  const itemsTemplate = () => {
    const {
      user: { userType },
    } = auth;

    if (isCustomerUser(userType))
      return relatedLotEnabled
        ? itemsCustomerExistingHedgesTemplate(isGlobalRefiningGroup(relatedCompany.label))
        : itemsCustomerExistingBlanketHedgesTemplate(isGlobalRefiningGroup(relatedCompany.label));

    if (isGlobalUserType(userType) || isBuyerUser(userType))
      return relatedLotEnabled
        ? itemsOwnerExistingHedgesTemplate(isGlobalRefiningGroup(relatedCompany.label))
        : itemsOwnerExistingBlanketHedgesTemplate(isGlobalRefiningGroup(relatedCompany.label));
  };

  return (
    <>
      {((!relatedLotEnabled && companyId) || lotId) && hasItems > 0 && (
        <TablePadding>
          <TableTitle>
            {relatedLotEnabled ? HEDGES.EXISTING_HEDGES : HEDGES.EXISTING_BLANKET_HEDGES}
          </TableTitle>
          <OverviewList
            getList={getHedgesData}
            input={
              relatedLotEnabled
                ? lotHedges.existingHedges[hedge]
                : companyHedges.existingHedges[hedge]
            }
            itemsTemplate={itemsTemplate()}
            itemsTemplateMobile={itemsTemplateMobile}
            itemDetailsTemplate={itemsTemplate()}
            actionsTemplate={[]}
            isMobile={windowWidth < isTablet}
            auth={auth}
            label={null}
            actionsContext={null}
            limitMaximumHeight
          />
        </TablePadding>
      )}
    </>
  );
};

ExistingHedges.propTypes = {
  hedgeType: PropTypes.string,
  companyId: PropTypes.string,
  lotId: PropTypes.string,
  relatedLotEnabled: PropTypes.bool.isRequired,
};

export { ExistingHedges };
