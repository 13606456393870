import { humanize } from './text';

const mapArray = (
  array,
  parseFunction = item => (item !== 'na' ? humanize(item) : item.toUpperCase()),
) =>
  array.map(item => {
    const isString = typeof item === 'string';
    return {
      value: isString ? item : item.value,
      label: parseFunction(isString ? item : item.label),
    };
  });

export { mapArray };
