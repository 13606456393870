import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { validateToken } from 'actions/TokenValidation/tokenValidation';

import { PreloaderInline } from 'shared/components/PreloaderInline/PreloaderInline';
import { ACCESS } from 'shared/helpers/constants/access/accessConstants';

import { StyledTokenAuthNotValid } from './StyledTokenAuth';

class TokenAuthBase extends Component {
  static propTypes = {
    purpose: PropTypes.string.isRequired,
  };

  componentDidMount() {
    this.props.validateToken(this.props.purpose, this.props.match.params.token).catch(() => {});
  }

  renderContent() {
    return this.props.isValid ? (
      this.props.children
    ) : (
      <StyledTokenAuthNotValid className="TokenAuth-notValid">
        {ACCESS.TOKEN_NOT_VALID}
      </StyledTokenAuthNotValid>
    );
  }

  render() {
    return <>{this.props.isPending ? <PreloaderInline loading /> : this.renderContent()}</>;
  }
}

const mapStateToProps = state => ({
  isValid: state.tokenValidation.isValid,
  isPending: state.tokenValidation.isPending,
});

const mapDispatchToProps = {
  validateToken,
};

const TokenAuth = withRouter(connect(mapStateToProps, mapDispatchToProps)(TokenAuthBase));

export { TokenAuth, TokenAuthBase };
