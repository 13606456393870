import styled from 'styled-components';

import { media } from 'shared/helpers/styling/styling';

export const StyledLogoutContentLogo = styled.div`
  align-items: center;
  vertical-align: middle;
  justify-content: center;
  display: flex;
  padding-right: 46px;
  width: 100%;

  img {
    height: 48px;
    width: 160px;
  }

  ${media.phone`
display: flex;
padding-right: 0;

img {
    width: 90px;
    height: 27px;
}
`}

  ${media.smallTablet`
padding-right: 0;

img {
  width: 100px;
  height: 30px;
}
`}
`;

export const StyledLogoutContentSpacer = styled.div`
  background-color: var(--cuttySark);
  margin: 0 30px;
  height: 70px;
  width: 1px;

  ${media.phone`
height: 60px;
margin: 0 20px;
`}

  ${media.smallTablet`
height: 60px;
margin: 0 20px;
`}
`;

export const StyledLogoutContentLogoText = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: var(--porcelain);

  ${media.phone`font-size: 14px;`}

  ${media.smallTablet`font-size: 14px;`}
`;

export const StyledLogoutContentInfo = styled.div`
  flex-direction: column;
  text-align: center;
  align-items: center;
  display: flex;
  font-size: 14px;
  margin-top: 62px;
  color: var(--gullGray);

  ${media.phone`
margin-top: 77px;
`}

  ${media.smallTablet`
margin-top: 77px;
font-size: 14px;
`}
`;

export const StyledLogoutContentInfoText = styled.span`
  margin-top: 5px;
`;
