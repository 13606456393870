import styled from 'styled-components';

import { cssVar, pxToRem, media } from 'shared/helpers/styling/styling';

export const LaboratoryPolicy = styled.div`
  padding: ${pxToRem(20)} ${pxToRem(25)};
  display: flex;
`;

export const LaboratoryInfo = styled.div`
  display: block;
  color: ${cssVar('alto')};
  font-size: ${pxToRem(13)};
  margin: 0;
  padding-left: ${pxToRem(20)};

  ${media.mobile`
    padding-left: ${pxToRem(20)};
  `}
`;
