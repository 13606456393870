import PropTypes from 'prop-types';
import React, { useMemo, useRef, useState } from 'react';

import { withFieldWrapper } from 'shared/components/Fields/FieldWrapper/FieldWrapper';
import { timePickerFormat } from 'shared/helpers/parsers/date';

import { MomentAdapter } from 'utils/date/momentAdapter';

import { Input, DatePickerStyled } from '../FieldDate';
import { StyledDatePickerWrapper } from './StyledFieldTime';

const FieldTimeBase = ({
  input,
  clearable,
  icon,
  disabled,
  disableFlip,
  excludeTimes,
  timeRange,
  showTimeZone,
  ...props
}) => {
  const fieldRef = useRef();
  const [timeLength, setTimeLength] = useState(0);

  const onBlur = () => {
    input.onBlur && input.onBlur(input.value);
  };

  const roundDate = date => {
    if (!date) return null;

    const reminder = date.getMinutes() % 30;
    return new MomentAdapter(date).subtract(reminder, 'm').toDate();
  };

  const onChange = (value, event) => {
    const date = roundDate(value);
    const normalizedValue = date && MomentAdapter.normalize(date).toJSDate();

    if (event && event.keyCode === 13) return event.preventDefault();

    props.onTimeChange && props.onTimeChange(normalizedValue);
    input.onChange && input.onChange(date);
    setTimeLength(MomentAdapter.formatLocal(date, timePickerFormat).length);
    fieldRef.current && fieldRef.current.setState({ open: false });
  };

  const inputValue = useMemo(() => {
    const { value } = input;
    const date = value instanceof Date ? value : new Date(value);

    const roundedDate = roundDate(date);

    return Number.isNaN(roundedDate.getTime()) ? null : roundedDate;
  }, [input]);

  return (
    <StyledDatePickerWrapper
      showTimeZoneInInput={showTimeZone && !!inputValue}
      showTimeZoneInDropDown={showTimeZone}
      timeLength={timeLength}
    >
      <DatePickerStyled
        {...input}
        onBlur={onBlur}
        onChange={onChange}
        selected={inputValue}
        disabled={disabled}
        ref={fieldRef}
        customInput={<Input hideIcon={disabled} icon={icon} />}
        isClearable={(clearable === undefined || clearable) && !disabled}
        calendarClassName="FieldTime__calendar"
        dateFormat={timePickerFormat}
        showTimeSelect
        excludeTimes={excludeTimes}
        minTime={timeRange.minTime}
        maxTime={timeRange.maxTime}
        showTimeSelectOnly
        popperModifiers={{
          flip: {
            enabled: !disableFlip,
          },
        }}
      />
    </StyledDatePickerWrapper>
  );
};

const FieldTime = withFieldWrapper(FieldTimeBase);

FieldTimeBase.defaultProps = {
  showTimeZone: false,
};

FieldTimeBase.propTypes = {
  disableFlip: PropTypes.bool,
  disabled: PropTypes.bool,
  showTimeZone: PropTypes.bool,
  excludeTimes: PropTypes.array,
  icon: PropTypes.string,
  input: PropTypes.shape({
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onDragStart: PropTypes.func,
    onDrop: PropTypes.func,
    onFocus: PropTypes.func,
  }),
  timeRange: PropTypes.shape({
    maxTime: PropTypes.object,
    minTime: PropTypes.object,
  }),
  field: PropTypes.string,
  lable: PropTypes.string,
  meta: PropTypes.object,
};

export { FieldTime };
