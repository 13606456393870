import { css } from 'styled-components';

export const ScrollbarStyles = (width = '17px', trackWidth = '11px', thumbBorder = '4px') => css`
  &::-webkit-scrollbar {
    width: ${width};
    background-color: transparent;

    &-track {
      width: ${trackWidth};
      background-color: var(--outerSpaceBrighter);
      background-clip: padding-box;
      border-right: 6px solid transparent;
      border-top: 4px solid transparent;
      border-bottom: 4px solid transparent;
    }

    &-thumb {
      width: 9px;
      border-right: ${thumbBorder} solid transparent;
      border-left: ${thumbBorder} solid transparent;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      background-clip: padding-box;
      background-color: var(--regentGrayLight);
    }

    &-corner {
      background-color: var(--outerSpaceFormBg);
    }
  }
`;
