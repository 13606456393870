import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const deleteSettlementActionType = 'DELETE_SETTLEMENT';
const deleteSettlementAction = () => ({
  type: deleteSettlementActionType,
});

const deleteSettlementSuccessActionType = `${deleteSettlementActionType}_SUCCESS`;
const deleteSettlementSuccessAction = () => ({
  type: deleteSettlementSuccessActionType,
});

const deleteSettlementFailureActionType = `${deleteSettlementActionType}_FAILURE`;
const deleteSettlementFailureAction = () => ({
  type: deleteSettlementFailureActionType,
});

const deleteSettlement = settlementId => dispatch => {
  dispatch(deleteSettlementAction());
  return axios
    .delete(`${API_HOST_AUTH}/settlements/${settlementId}`)
    .then(res => {
      dispatch(deleteSettlementSuccessAction(res.data));

      return res.data;
    })
    .catch(err => {
      dispatch(deleteSettlementFailureAction());
      return Promise.reject(err.data);
    });
};

export {
  deleteSettlementActionType,
  deleteSettlementSuccessActionType,
  deleteSettlementFailureActionType,
  deleteSettlement,
};
