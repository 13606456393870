import styled from 'styled-components';

import { FullPageList } from 'shared/components/FullPageList/FullPageList';

export const StyledPoolAccountsList = styled(FullPageList)`
  .PoolAccountsList {
    &--customer {
      .TableItem:hover {
        background-color: var(--fiord);
        color: var(--gullGray);
      }
    }

    .Table-wrapper thead th.reset-width {
      width: auto;
      min-width: 100px;
    }

    .TableItem {
      word-break: break-word;
    }

    .Table-wrapper thead th.PoolAccountsList-grandTotal {
      width: auto;
    }
  }
`;

export const StyledPoolAccountsDisclaimer = styled.h2`
  font-size: 0.85em;
  margin-top: 20px;
  color: var(--gullGray);
  font-weight: 400;
`
