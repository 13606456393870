import { checkEmail } from 'actions/Users/checkEmail';

import { message } from 'shared/helpers/constants/users/createUserConstants';

const asyncValidate = (values, dispatch, { match }) =>
  dispatch(checkEmail(values.email, match)).then(async res =>
    res ? Promise.reject({ email: message.UNIQUE_EMAIL }) : null,
  );

export { asyncValidate };
