import axios from 'axios';

import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';
import { parseLotUpdateData } from 'shared/helpers/dataParsers/lot/parseLotUpdateData';

const API_HOST_AUTH = process.env.REACT_APP_API;

const updateLotActionType = 'UPDATE_LOT';
function updateLotAction() {
  return {
    type: updateLotActionType,
  };
}

const updateLotSuccessActionType = 'UPDATE_LOT_SUCCESS';
function updateLotSuccessAction() {
  return {
    type: updateLotSuccessActionType,
  };
}

const updateLotFailureActionType = 'UPDATE_LOT_FAILURE';
function updateLotFailureAction() {
  return {
    type: updateLotFailureActionType,
  };
}

function updateLot(lot, files, { id, statusForGlobal, existingLotFiles }) {
  return dispatch => {
    dispatch(updateLotAction());
    return axios({
      method: 'put',
      url: `${API_HOST_AUTH}/lots/${id}`,
      headers: { 'Content-Type': 'multipart/form-data' },
      data: parseLotUpdateData(lot, files, statusForGlobal, existingLotFiles),
    })
      .then(response => {
        dispatch(updateLotSuccessAction());
        return {
          lot: response.data,
          message: response.data.message,
        };
      })
      .catch(error => {
        dispatch(updateLotFailureAction());
        return Promise.reject({
          message: `${LOTS.ERRORS.UPDATE_LOT_ERROR} ${error.data.message}`,
        });
      });
  };
}

export {
  updateLotActionType,
  updateLotAction,
  updateLotSuccessActionType,
  updateLotSuccessAction,
  updateLotFailureActionType,
  updateLotFailureAction,
  updateLot,
};
