import styled from 'styled-components';

import { cssVar } from 'shared/helpers/styling/styling';

const StyledWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  text-align: center;
  color: ${cssVar('geyser')};
  gap: 24px;
`;

const StyledActions = styled('div')`
  display: flex;
  justify-content: space-between;
`;

export { StyledActions, StyledWrapper };
