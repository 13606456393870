import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getLotsToTransfer } from 'actions/Deliveries/getLotsToTransfer';
import { showSnackbar } from 'actions/shared/snackbar';

import { renderDetailsContainer } from 'shared/components/Details/helpers/renderDetailsContainer';
import { Section } from 'shared/components/Form/Section/Section';
import { FieldSelect } from 'shared/components/Formik/Fields';
import { DELIVERIES } from 'shared/helpers/constants/deliveries/deliveriesConstants';
import { dangerOptions } from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { parseLotsData } from 'shared/helpers/dataParsers/lot/parseLotsSelectData';
import { getFormErrors } from 'shared/helpers/forms/getFormErrors';

import { goBackOrTo } from 'utils/history';

import {
  deliveriesDetailsContainerConfig,
  deliveriesDetailsFieldsConfig,
} from 'pages/Deliveries/TransferOuncesWizard/TransferOuncesForm/components/TransferLotsFormContent/config/fieldsConfig';

const TransferLotsFormContent = ({ deliveries }) => {
  const lotsToTransfer = useSelector(({ lotsToTransfer }) => lotsToTransfer);
  const dispatch = useDispatch();
  const { touched, setFieldValue, setFieldTouched, errors, values } = useFormikContext();
  const deliveryData = deliveries.find(({ _id }) => _id === values.delivery);
  const relatedLotId = deliveryData.relatedLot._id;
  const hedgeId = deliveryData.relatedHedge._id;

  useEffect(() => {
    try {
      dispatch(getLotsToTransfer(relatedLotId));
    } catch (err) {
      goBackOrTo(`/hedges/${hedgeId}`);
      dispatch(showSnackbar(dangerOptions, err.message));
    }
  }, []);

  const handleSelectChange = (option = '', data) => {
    if (data?.action === 'clear') {
      setFieldTouched('relatedLot');
    }

    setFieldValue('relatedLot', option);
  };

  return (
    <>
      <Section template={['selectedDelivery relatedLot']}>
        <div name="selectedDelivery">
          {renderDetailsContainer(
            deliveriesDetailsContainerConfig,
            deliveriesDetailsFieldsConfig,
            deliveryData,
          )}
        </div>
        <FieldSelect
          options={parseLotsData(lotsToTransfer.lots)}
          name="relatedLot"
          label={DELIVERIES.RELATED_LOT}
          field="required"
          onChange={handleSelectChange}
          error={getFormErrors({ name: 'relatedLot', touched, errors })}
          value={values.relatedLot}
        />
      </Section>
    </>
  );
};

TransferLotsFormContent.propTypes = {
  deliveries: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      createdAt: PropTypes.number,
      createdBy: PropTypes.object,
      palladiumOz: PropTypes.number,
      platinumOz: PropTypes.number,
      rhodiumOz: PropTypes.number,
      relatedHedge: PropTypes.object,
      relatedLot: PropTypes.object,
    }),
  ).isRequired,
  lots: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      grgLotNumber: PropTypes.string,
      statusForGlobal: PropTypes.string,
    }),
  ).isRequired,
};

export { TransferLotsFormContent };
