import {
  updatePackageActionType,
  updatePackageSuccessActionType,
  updatePackageFailureActionType,
} from 'actions/Packages/updatePackage';

const INITIAL_STATE = {
  isPending: false,
};

const updatePackageReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case updatePackageActionType:
      return {
        isPending: true,
      };
    case updatePackageSuccessActionType:
    case updatePackageFailureActionType:
      return {
        isPending: false,
      };
    default:
      return state;
  }
};

export { updatePackageReducer };
