const agreementsStatuses = {
  draft: 'draft',
  download: 'download',
  pending: 'pending',
  signed: 'signed',
  declined: 'declined',
  cancelled: 'cancelled',
  exception: 'exception',
  expired: 'expired',
};

const availableAgreementsStatuses = {
  all: Object.values(agreementsStatuses),
  grading: [agreementsStatuses.signed, agreementsStatuses.expired],
};

export { agreementsStatuses, availableAgreementsStatuses };
