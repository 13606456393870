import {
  validateTokenActionType,
  validateTokenFailureActionType,
  validateTokenSuccessActionType,
} from 'actions/TokenValidation/tokenValidation';

const INITIAL_STATE = {
  isValid: false,
  isPending: false,
  errorMessage: null,
  info: null,
};

const tokenValidationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case validateTokenActionType:
      return {
        ...INITIAL_STATE,
        isPending: true,
      };
    case validateTokenSuccessActionType:
      return {
        ...INITIAL_STATE,
        isValid: true,
        info: action.payload.info,
      };
    case validateTokenFailureActionType:
      return {
        ...INITIAL_STATE,
        errorMessage: action.payload.errorMessage,
      };
    default:
      return state;
  }
};

export { tokenValidationReducer };
