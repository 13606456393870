import { parseLotFormData } from './parseLotFormData';

function parseLotData(lot, files, isGrgUser) {
  const { countSheet, gradingReceipt, ...lotFields } = lot;
  const parsedLot = parseLotFormData(lotFields, isGrgUser);
  const data = new FormData();

  Object.keys(parsedLot).forEach(
    field =>
      // eslint-disable-next-line eqeqeq
      parsedLot[field] != undefined && data.append(field, parsedLot[field]),
  );

  if (files) {
    Object.keys(files).forEach(file => {
      if (files[file] !== null) {
        data.append(file, files[file][0]);
      }
    });
  }

  return data;
}

export { parseLotData };
