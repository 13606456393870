import { PERMISSIONS } from 'shared/helpers/constants/systemSettings/permissions/constants';

import { isGlobal, isAlpha } from 'utils/versioning/Guards';

export const templates = [
  {
    label: PERMISSIONS.LOTS,
    children: [
      {
        name: 'manualLotChangeNotifications',
        label: PERMISSIONS.MANUAL_LOT_CHANGE_NOTIFICATIONS,
      },
      {
        name: 'setFinalizedLot',
        label: PERMISSIONS.FINALIZE_LOT,
      },
      {
        name: 'approveClientFacingUploads',
        label: PERMISSIONS.APPROVE_CLIENT_FACING_UPLOADS,
        allowEmptyArray: false,
      },
    ],
  },
  {
    label: PERMISSIONS.HEDGES,
    children: [
      {
        name: 'changeHedgeOunces',
        label: PERMISSIONS.CHANGE_HEDGE_OUNCES,
      },
      {
        name: 'changeHedgePrices',
        label: PERMISSIONS.CHANGE_HEDGE_PRICES,
      },
      {
        name: 'cancelHedge',
        label: PERMISSIONS.CANCEL_HEDGE,
      },
      {
        name: 'hedgeRequestNotification',
        label: PERMISSIONS.HEDGE_REQUEST_NOTIFICATION,
        allowEmptyArray: false,
      },
      {
        name: 'transferHedgeToDifferentLot',
        label: PERMISSIONS.TRANSFER_HEDGE_TO_DIFFERENT_LOT,
      },
      {
        name: 'marketIndicatorNotifications',
        label: PERMISSIONS.MARKET_INDICATOR_NOTIFICATIONS,
        allowEmptyArray: false,
      },
      {
        name: 'transferBlanketHedgeOunces',
        label: PERMISSIONS.TRANSFER_BLANKET_HEDGE_OUNCES,
      },
    ],
  },
  {
    label: PERMISSIONS.PAYMENTS,
    children: [
      (isGlobal || isAlpha) && {
        name: 'generateOrApproveProfitLossDocs',
        label: PERMISSIONS.GENERATE_OR_APPROVE_PROFIT_LOSS_DOCS,
      },
      {
        name: 'reassignPayment',
        label: PERMISSIONS.REASSIGN_PAYMENT,
      },
      {
        name: 'updateCompanyOutstandingBalance',
        label: PERMISSIONS.UPDATE_COMPANY_OUTSTANDING_BALANCE,
      },
      {
        name: 'mcrAdvancePaymentNotifications',
        label: PERMISSIONS.MCR_ADVANCE_PAYMENT_NOTIFICATIONS,
      },
    ].filter(Boolean),
  },
  {
    label: PERMISSIONS.PROCESSING,
    children: [
      {
        name: 'updatePackageWeight',
        label: PERMISSIONS.UPDATE_PACKAGE_WEIGHT,
      },
      {
        name: 'lab',
        label: PERMISSIONS.LABS_USER,
      },
    ],
  },
  {
    label: PERMISSIONS.SHIPMENTS,
    children: [
      {
        name: 'createOutgoingShipment',
        label: PERMISSIONS.CREATE_OUTGOING_SHIPMENT,
      },
    ],
  },
];
