import { statusesForGlobal } from 'shared/helpers/constants/lots/statusesForGlobal';
import { isInternalCompany, isGradingCompany } from 'shared/helpers/matchers/checkCompanyType';

const enterPricesForSettledOuncesAccess = (lotStatusForGlobal, settlements, companyType) =>
  lotStatusForGlobal &&
  [
    ...(isInternalCompany(companyType) || isGradingCompany(companyType)
      ? [statusesForGlobal.assay_results_received]
      : []),
    statusesForGlobal.ship_summary_accepted,
  ].includes(lotStatusForGlobal) &&
  settlements.enterPrices;

export { enterPricesForSettledOuncesAccess };
