import {
  clearLatestMarketIndicatorsActionType,
  getLatestMarketIndicatorsFailureType,
  getLatestMarketIndicatorsSuccessType,
  getLatestMarketIndicatorsType,
} from 'actions/MarketIndicators/getLatestMarketIndicators';
import {
  getMarketIndicatorsType,
  getMarketIndicatorsSuccessType,
  getMarketIndicatorsFailureType,
  clearMarketIndicatorsActionType,
} from 'actions/MarketIndicators/getMarketIndicators';

const INITIAL_STATE = {
  isPending: false,
  marketIndicators: null,
  latestMarketIndicators: null,
};

const getMarketIndicatorsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getMarketIndicatorsType:
      return {
        isPending: true,
        marketIndicators: null,
      };
    case getLatestMarketIndicatorsType:
      return {
        isPending: true,
        latestMarketIndicators: null,
      };
    case getMarketIndicatorsSuccessType:
      return {
        isPending: false,
        marketIndicators: action.payload.marketIndicators,
      };
    case getLatestMarketIndicatorsSuccessType:
      return {
        isPending: false,
        latestMarketIndicators: action.payload.latestMarketIndicators,
      };
    case clearLatestMarketIndicatorsActionType:
    case getLatestMarketIndicatorsFailureType:
    case clearMarketIndicatorsActionType:
    case getMarketIndicatorsFailureType:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};

export { getMarketIndicatorsReducer };
