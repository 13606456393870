import { message } from 'shared/helpers/constants/lots/createLotConstants';
import { CUSTOM_LOT_NUMBER_REGEX } from 'shared/helpers/validations/regularExpressions';

function validate(values) {
  const errors = {};

  if (!values.customerInstructions) {
    errors.customerInstructions = '';
  } else if (values.customerInstructions.length > 100) {
    errors.customerInstructions = message.INVALID_CUSTOMER_INSTRUCTIONS;
  }

  if (!values.sentFromCompanyLocation) {
    errors.sentFromCompanyLocation = message.INVALID_SENT_FROM_COMPANY_LOCATION_EMPTY;
  }

  if (
    values.customLotNumber &&
    (!CUSTOM_LOT_NUMBER_REGEX.test(values.customLotNumber) || values.customLotNumber.length > 15)
  ) {
    errors.customLotNumber = message.INVALID_CUSTOM_LOT_NUMBER_FORMAT;
  }

  if (!values.processUponDelivery) {
    errors.processUponDelivery = message.INVALID_PROCESS_UPON_DELIVERY_EMPTY;
  }

  return errors;
}

export { validate };
