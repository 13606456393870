import {
  getSignedTermsPendingActionType,
  getSignedTermsActionType,
} from 'actions/Terms/getSignedTerms';

const INITIAL_STATE = {
  isPending: false,
  value: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getSignedTermsPendingActionType:
      return {
        isPending: true,
        value: null,
      };
    case getSignedTermsActionType:
      return {
        isPending: false,
        value: action.payload.advanceRate,
      };
    default:
      return state;
  }
};
