import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Field, reduxForm, formValueSelector } from 'redux-form';

import { getLotsForCommodity } from 'actions/Commodities/getLotsForCommodity';
import { removeCommodityFromLot } from 'actions/Commodities/removeCommodityFromLot';
import { openModal } from 'actions/shared/modal';
import { showSnackbar } from 'actions/shared/snackbar';

import { FieldInput, FieldSelect } from 'shared/components/Fields';
import { Form, Section, FormContainer } from 'shared/components/Form';
import { COMMODITIES } from 'shared/helpers/constants/commodities/commoditiesConstants';
import {
  denominationTable,
  denominatedIn,
} from 'shared/helpers/constants/materials/denominationTable';
import { cancelModal } from 'shared/helpers/constants/modalConstants';
import { availableMaterialTypeCommodities } from 'shared/helpers/constants/packages/materialType';
import { PLACEHOLDERS } from 'shared/helpers/constants/placeholders';
import {
  dangerOptions,
  successOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { parseAllLotsSelectData } from 'shared/helpers/dataParsers/lot/parseLotsSelectData';
import { safeParseFloat } from 'shared/helpers/parsers/number';
import { mapArray } from 'shared/helpers/parsers/select';
import { validate } from 'shared/helpers/validations/commodities/removeCommodityValidation';

class RemoveCommodityBase extends Component {
  componentDidMount() {
    const { location, getLotsForCommodity } = this.props;
    const { grgLotNumber } = location.state;

    getLotsForCommodity()
      .then(() => {
        if (grgLotNumber) {
          this.props.change('fromLot', grgLotNumber);
        } else {
          return Promise.reject();
        }
      })
      .catch(this.goBack);
  }

  onSubmit = values => {
    const { toLot, materialType, materialUnits, materialWeight } = values;

    const { id: fromLot } = this.props.match.params;

    this.props
      .removeCommodityFromLot(
        fromLot,
        toLot.value,
        materialType.value,
        +materialUnits,
        safeParseFloat(materialWeight),
      )
      .then(({ message }) => {
        this.props.showSnackbar(successOptions, message);
        this.goBack();
      })
      .catch(({ message }) => {
        this.props.showSnackbar(dangerOptions, message);
      });
  };

  onMaterialTypeChange = materialType => {
    const value = materialType && materialType.value;
    const { change } = this.props;
    const newDenomination = denominationTable[value];

    if (newDenomination === denominatedIn.weight) {
      change('materialUnits', '');
    } else if (newDenomination === denominatedIn.units) {
      change('materialWeight', '');
    }
  };

  onCancel = () => {
    this.props.openModal(cancelModal, () => {
      this.goBack();
    });
  };

  get toLotOptions() {
    const lotId = this.props.match.params.id;
    const filteredLots = this.props.commodityLots.lots.filter(({ _id }) => _id !== lotId);

    return parseAllLotsSelectData(filteredLots);
  }

  get denominatedIn() {
    const materialType = this.props.materialType && this.props.materialType.value;

    return denominationTable[materialType];
  }

  goBack = () => {
    const lotId = this.props.match.params.id;

    this.props.history.push(`/lots/list/${lotId}`);
  };

  materialOnChange = () => {
    const { change } = this.props;

    change('materialUnits', null);
    change('materialWeight', null);
  };

  shouldDisplayUnits() {
    return this.denominatedIn && this.denominatedIn !== denominatedIn.weight;
  }

  shouldDisplayWeight() {
    return this.denominatedIn && this.denominatedIn !== denominatedIn.units;
  }

  render() {
    const {
      invalid,
      submitting,
      handleSubmit,
      commodityLots: { isPending },
      materialUnits,
      materialWeight,
      isRemoveCommodityFromLotPending,
    } = this.props;

    return (
      <FormContainer header={COMMODITIES.REMOVE_COMMODITY} loaded={!isPending}>
        <Form
          onSubmit={handleSubmit(this.onSubmit)}
          onCancel={this.onCancel}
          submitDisabled={invalid || submitting || isRemoveCommodityFromLotPending}
          header={COMMODITIES.COMMODITIES}
        >
          <Section template={['fromLot toLot', 'materialType .']}>
            <Field name="fromLot" component={FieldInput} label={COMMODITIES.FROM_LOT} disabled />
            <Field
              name="toLot"
              component={FieldSelect}
              label={COMMODITIES.TO_LOT}
              field="required"
              placeholder={PLACEHOLDERS.SEARCH}
              options={this.toLotOptions}
            />
            <Field
              name="materialType"
              component={FieldSelect}
              label={COMMODITIES.MATERIAL_TYPE}
              field="required"
              options={mapArray(availableMaterialTypeCommodities.all)}
              onChange={this.onMaterialTypeChange}
            />
          </Section>
          <Section
            header={COMMODITIES.WEIGHTS_UNITS}
            template={['materialUnits .', 'materialWeight .']}
          >
            {this.shouldDisplayUnits() && (
              <Field
                name="materialUnits"
                suffix="units"
                component={FieldInput}
                type="number"
                label={COMMODITIES.MATERIAL_UNITS}
                onChange={this.materialOnChange}
                field={(materialWeight === undefined || materialWeight === null) && 'required'}
              />
            )}
            {this.shouldDisplayWeight() && (
              <Field
                name="materialWeight"
                component={FieldInput}
                type="text"
                onChange={this.materialOnChange}
                label={COMMODITIES.MATERIAL_WEIGHT}
                field={(materialUnits === undefined || materialUnits === null) && 'required'}
                suffix="lbs"
              />
            )}
          </Section>
        </Form>
      </FormContainer>
    );
  }
}

const selector = formValueSelector('removeCommodity');

const mapStateToProps = state => ({
  commodityLots: state.getLotsForCommodity,
  isRemoveCommodityFromLotPending: state.removeCommodityFromLot.isPending,
  materialType: selector(state, 'materialType'),
  materialWeight: selector(state, 'materialWeight'),
  materialUnits: selector(state, 'materialUnits'),
});

const mapDispatchToProps = {
  getLotsForCommodity,
  removeCommodityFromLot,
  showSnackbar,
  openModal,
};

const RemoveCommodity = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    reduxForm({
      form: 'removeCommodity',
      validate,
    })(RemoveCommodityBase),
  ),
);

export { RemoveCommodity, RemoveCommodityBase };
