import React, { Component } from 'react';

import { FormContainer } from 'shared/components/Form/FormContainer/FormContainer';
import { NOTE } from 'shared/helpers/constants/noteConstants';
import {
  successOptions,
  dangerOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

import { history } from 'utils/history';

import { ConnectedNoteForm } from './NoteForm';

class CreateNote extends Component {
  state = {
    files: [],
    invalidFiles: false,
  };

  addFilesToList = fileList => {
    this.setState(prevState => ({
      files: prevState.files.concat(fileList),
    }));
  };

  checkNotes = notes => {
    !notes && this.setState({ files: [] });
  };

  removeFilesFromList = fileToRemove => {
    this.setState(({ files }) => ({ files: files.filter(file => file !== fileToRemove) }));
  };

  checkFilesError = invalidFiles => this.setState({ invalidFiles });

  submit = async values => {
    const { files } = this.state;
    const {
      relatedType,
      match,
      createNote,
      showSnackbar,
      location: { state },
    } = this.props;
    const relatedId = match.params.id;
    await createNote(values, files, relatedId, state?.type ?? relatedType)
      .then(({ data }) => {
        showSnackbar(successOptions, data.message);
        switch (relatedType) {
          case 'diligence':
            return history.push(`/companies/${relatedId}`);
          default:
            return history.push(`/${relatedType}/${relatedId}`);
        }
      })
      .catch(() => showSnackbar(dangerOptions, NOTE.NOTE_CREATED_ERROR));
  };

  render() {
    const { location } = this.props;
    return (
      <FormContainer header={location?.state?.title ?? NOTE.CREATE_A_NOTE}>
        <ConnectedNoteForm
          {...this.props}
          onSubmit={this.submit}
          submitLabel={NOTE.CREATE_A_NOTE}
          addFile={this.addFilesToList}
          removeFile={this.removeFilesFromList}
          attachment={this.state}
          blockSubmit={this.state.invalidFiles || this.state.files.length > 10}
          filesError={this.checkFilesError}
          checkNotes={this.checkNotes}
        />
      </FormContainer>
    );
  }
}

export { CreateNote };
