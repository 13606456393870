import {
  clearTermsListActionType,
  getTermsListActionType,
  getTermsListFailureActionType,
  getTermsListSuccessActionType,
} from 'actions/Terms/termList';

const INITIAL_STATE = {
  isPending: false,
  errorMessage: null,
  terms: [],
  limit: 50,
  page: 1,
  pages: null,
  company: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getTermsListActionType:
      return {
        ...state,
        isPending: true,
      };
    case getTermsListSuccessActionType:
      return {
        ...state,
        isPending: false,
        terms: action.payload.terms,
        pages: action.payload.pages,
        limit: action.payload.limit,
        page: action.payload.page,
        company: action.payload.company,
      };
    case getTermsListFailureActionType:
      return {
        ...state,
        isPending: false,
        errorMessage: action.payload.errorMessage,
        terms: [],
        pages: null,
      };
    case clearTermsListActionType:
      return {
        isPending: false,
        errorMessage: null,
        terms: [],
        limit: 50,
        page: 1,
        pages: null,
        company: null,
      };
    default:
      return state;
  }
};
