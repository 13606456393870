import React from 'react';

import { StyledPreloaderSpinner } from '../Preloader/StyledPreloader';
import {
  StyledPreloaderInlineComponent,
  StyledPreloaderInlineContent,
  StyledPreloaderInlineTextarea,
  StyledPreloaderInlineTitle,
} from './StyledPreloaderInline';

function PreloaderInline({ loading, lite }) {
  return (
    loading && (
      <StyledPreloaderInlineComponent className={`${lite ? 'lite' : ''}`}>
        <StyledPreloaderInlineContent>
          <StyledPreloaderSpinner>
            <span className="slice" />
            <span className="slice" />
            <span className="slice" />
            <span className="slice" />
            <span className="slice" />
            <span className="slice" />
          </StyledPreloaderSpinner>
          <StyledPreloaderInlineTextarea>
            <StyledPreloaderInlineTitle>Please wait...</StyledPreloaderInlineTitle>
          </StyledPreloaderInlineTextarea>
        </StyledPreloaderInlineContent>
      </StyledPreloaderInlineComponent>
    )
  );
}

export { PreloaderInline };
