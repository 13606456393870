import React from 'react';

import { FieldButtonSelect } from '../FieldButtonSelect/FieldButtonSelect';

const options = [
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' },
];

const normalizeValue = value => (typeof value === 'boolean' ? value.toString() : value);

const FieldRadioButton = props => (
  <FieldButtonSelect
    {...props}
    input={{
      ...props.input,
      value: normalizeValue(props.input.value),
    }}
    options={options}
    normalize={option => option && option.value}
  />
);

export { FieldRadioButton };
