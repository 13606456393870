import {
  deleteLotActionType,
  deleteLotSuccessActionType,
  deleteLotFailureActionType,
} from 'actions/Lots/deleteLot';

const INITIAL_STATE = {
  isPending: false,
};

const deleteLotReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case deleteLotActionType:
      return {
        isPending: true,
      };
    case deleteLotSuccessActionType:
    case deleteLotFailureActionType:
      return {
        isPending: false,
      };
    default:
      return state;
  }
};

export { deleteLotReducer };
