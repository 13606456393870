import React, { Component } from 'react';

import { FullPageList } from 'shared/components/FullPageList/FullPageList';
import { Preloader } from 'shared/components/Preloader/Preloader';
import { AGREEMENTS } from 'shared/helpers/constants/agreements/agreementsConstants';
import {
  itemsTemplate,
  itemsDetailsTemplate,
  itemsTemplateMobile,
  actionsTemplate,
  actionsTemplateGrading,
} from 'shared/helpers/constants/agreements/agreementsListTableConfig';
import { FILES_CONSTANTS } from 'shared/helpers/constants/filesConstants';
import { isGradingUser } from 'shared/helpers/matchers/checkUserType';

import { AgreementsFilters } from './components/AgreementsFilters/AgreementsFilters';

class AgreementsList extends Component {
  componentDidMount() {
    const { id } = this.props.match.params;
    const companyId = this.props.auth.user.relatedCompany._id;

    if (id) {
      this.props
        .downloadApiAttachment(`agreements/${id}/document`)
        .then(() => this.props.history.push(`/agreements/${companyId}`));
    }
  }

  componentWillUnmount() {
    this.props.clearAgreementsList();
  }

  onFiltersChanged = async filters => {
    const {
      agreementsList: { limit },
      agreementsStatusFilter,
    } = this.props;

    await agreementsStatusFilter(filters.status);

    await this.fetchList(1, limit);
  };

  renderFilters = () => {
    const {
      agreementsList: { limit, page, pages },
      agreementsFilters,
      auth,
    } = this.props;

    return (
      <AgreementsFilters
        filters={agreementsFilters}
        pagination={{ page, limit, pages }}
        onFiltersChanged={this.onFiltersChanged}
        auth={auth}
      />
    );
  };

  filtersClear = () => {
    const {
      getAgreementsList,
      match: {
        params: { companyId },
      },
      agreementsFiltersClear,
      agreementsList: { limit },
    } = this.props;

    getAgreementsList(companyId, limit, 1, { status: [] });
    agreementsFiltersClear();
  };

  fetchList = (page = 1, limit = 50) => {
    const {
      getAgreementsList,
      agreementsFilters,
      match: {
        params: { companyId },
      },
    } = this.props;

    getAgreementsList(companyId, limit, page, agreementsFilters);
  };

  render() {
    const {
      auth: {
        user: { userType },
      },
      agreementsList: { agreements, limit, page, pages, company, isPending },
      agreementsFilters,
      downloadApiAttachment,
      downloadAttachmentStorage,
    } = this.props;

    const filtersSelected = agreementsFilters.status.length;

    return (
      <>
        <FullPageList
          title={AGREEMENTS.AGREEMENTS}
          itemsTemplate={itemsTemplate}
          itemsDetailsTemplate={itemsDetailsTemplate}
          itemsTemplateMobile={itemsTemplateMobile}
          actionsTemplate={
            isGradingUser(userType)
              ? actionsTemplateGrading(downloadApiAttachment)
              : actionsTemplate(this.props.history, company)
          }
          items={agreements || []}
          fetchList={this.fetchList}
          filterComponent={this.renderFilters}
          filterAccess
          pagination={{ limit, page, pages }}
          filtersClear={this.filtersClear}
          filtersSelected={filtersSelected}
          actionsContext={this}
          isPending={isPending}
          filters={agreementsFilters}
        />
        <Preloader
          loading={downloadAttachmentStorage.isPending}
          label={FILES_CONSTANTS.DOWNLOADING_FILES}
        />
      </>
    );
  }
}

export { AgreementsList };
