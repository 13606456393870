import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getProfitLossDataActionType = 'GET_PROFIT_LOSS_DATA';
const getProfitLossDataAction = () => ({
  type: getProfitLossDataActionType,
});

const getProfitLossDataSuccessActionType = `${getProfitLossDataActionType}_SUCCESS`;
const getProfitLossDataSuccessAction = data => ({
  type: getProfitLossDataSuccessActionType,
  payload: {
    data,
  },
});

const getProfitLossDataFailureActionType = `${getProfitLossDataActionType}_FAILURE`;
const getProfitLossDataFailureAction = () => ({
  type: getProfitLossDataFailureActionType,
});

const clearProfitLossDataActionType = 'CLEAR_PROFIT_LOSS_DATA';
const clearProfitLossData = () => ({
  type: clearProfitLossDataActionType,
});

const getProfitLossData = lotId => dispatch => {
  dispatch(getProfitLossDataAction());

  return axios
    .get(`${API_HOST_AUTH}/lots/generate/profit-loss/${lotId}`)
    .then(res => {
      dispatch(getProfitLossDataSuccessAction(res.data));

      return res.data;
    })
    .catch(() => Promise.reject(dispatch(getProfitLossDataFailureAction())));
};

export {
  getProfitLossDataActionType,
  getProfitLossDataAction,
  getProfitLossDataSuccessActionType,
  getProfitLossDataSuccessAction,
  getProfitLossDataFailureActionType,
  getProfitLossDataFailureAction,
  clearProfitLossDataActionType,
  clearProfitLossData,
  getProfitLossData,
};
