const lotsAddFileActionType = 'LOTS_ADD_FILE_ACTION_TYPE';
function lotsAddFileAction(file) {
  return {
    type: lotsAddFileActionType,
    payload: { file },
  };
}

const lotsAddMultipleDocumentsActionType = 'LOTS_ADD_MULTIPLE_FILES_ACTION_TYPE';
function lotsAddMultipleDocumentsAction(payload) {
  return {
    type: lotsAddMultipleDocumentsActionType,
    payload,
  };
}

const lotsRemoveFileActionType = 'LOTS_REMOVE_FILE_ACTION_TYPE';
function lotsRemoveFileAction(fileType) {
  return {
    type: lotsRemoveFileActionType,
    payload: { fileType },
  };
}

const lotsRemoveMultipleFilesActionType = 'LOTS_REMOVE_MULTIPLE_FILES_ACTION_TYPE';
function lotsRemoveMultipleFilesAction(payload) {
  return {
    type: lotsRemoveMultipleFilesActionType,
    payload,
  };
}

const lotsClearAllFilesActionType = 'LOTS_CLEAR_ALL_FILES_ACTION_TYPE';
function lotsClearAllFilesAction() {
  return {
    type: lotsClearAllFilesActionType,
  };
}

const lotsParseUploadedMultipleFilesActionType = 'LOTS_UPDATE_MULTIPLE_FILES_ACTION_TYPE';
function lotsParseUploadedMultipleFilesAction(payload) {
  return {
    type: lotsParseUploadedMultipleFilesActionType,
    payload,
  };
}

function lotsAddFile(target) {
  return dispatch => {
    dispatch(lotsAddFileAction({ [target.name]: [].slice.call(target.files) }));
  };
}

function lotsParseUploadedMultipleFiles(payload) {
  return dispatch => {
    dispatch(lotsParseUploadedMultipleFilesAction(payload));
  };
}

function lotsAddMultipleDocuments(payload) {
  return dispatch => {
    dispatch(lotsAddMultipleDocumentsAction(payload));
  };
}

function lotsRemoveFile(fileType) {
  return dispatch => {
    dispatch(lotsRemoveFileAction(fileType));
  };
}

function lotsRemoveMultipleFiles(payload) {
  return dispatch => {
    dispatch(lotsRemoveMultipleFilesAction(payload));
  };
}

function lotsClearAllFiles() {
  return dispatch => {
    dispatch(lotsClearAllFilesAction());
  };
}

export {
  lotsAddFileActionType,
  lotsAddFileAction,
  lotsRemoveFileActionType,
  lotsRemoveFileAction,
  lotsClearAllFilesActionType,
  lotsClearAllFilesAction,
  lotsParseUploadedMultipleFilesActionType,
  lotsParseUploadedMultipleFilesAction,
  lotsRemoveMultipleFilesActionType,
  lotsRemoveMultipleFilesAction,
  lotsAddFile,
  lotsRemoveFile,
  lotsClearAllFiles,
  lotsAddMultipleDocumentsActionType,
  lotsAddMultipleDocumentsAction,
  lotsAddMultipleDocuments,
  lotsRemoveMultipleFiles,
  lotsParseUploadedMultipleFiles,
};
