import styled from 'styled-components';

import { DetailsField } from 'shared/components/Details/DetailsField';
import { cssVar } from 'shared/helpers/styling/styling';

import { DeliveryDetails } from 'pages/Deliveries/TransferOuncesWizard/TransferOuncesForm/components/TransferLotsFormContent/DeliveryDetails/DeliveryDetails';

const deliveriesDetailsContainerConfig = () => ({
  template: ['deliveries deliveries deliveries deliveries'],
  config: {
    noMargin: true,
  },
});

const StyledDetailsField = styled(DetailsField)`
  background-color: ${cssVar('limedSpruceForm')};
`;

const deliveriesDetailsFieldsConfig = delivery => [
  {
    icon: 'icon-volume',
    id: 'deliveries',
    customContent: delivery && <DeliveryDetails delivery={delivery} />,
    FieldType: StyledDetailsField,
  },
];

export { deliveriesDetailsContainerConfig, deliveriesDetailsFieldsConfig };
