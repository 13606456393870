import axios from 'axios';

import { showSnackbar } from 'actions/shared/snackbar';

import { dangerOptions } from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

const API_HOST_AUTH = process.env.REACT_APP_API;

const updateDefaultPPMsActionType = 'UPDATE_LOCATION_DEFAULT_PPMS';
function updateDefaultPPMsAction() {
  return {
    type: updateDefaultPPMsActionType,
  };
}

const updateDefaultPPMsSuccessActionType = 'UPDATE_LOCATION_DEFAULT_PPMS_SUCCESS';
function updateDefaultPPMsSuccessAction() {
  return {
    type: updateDefaultPPMsSuccessActionType,
  };
}

const updateDefaultPPMsFailureActionType = 'UPDATE_LOCATION_DEFAULT_PPMS_FAILURE';
function updateDefaultPPMsFailureAction() {
  return {
    type: updateDefaultPPMsFailureActionType,
  };
}

function updateDefaultPPMs(id, defaultPPMs) {
  return async dispatch => {
    dispatch(updateDefaultPPMsAction);

    return axios({
      method: 'put',
      url: `${API_HOST_AUTH}/locations/${id}/default-ppms`,
      data: JSON.stringify({ defaultPPMs }),
    })
      .then(response => {
        dispatch(updateDefaultPPMsSuccessAction());
        return response;
      })
      .catch(error => {
        dispatch(updateDefaultPPMsFailureAction());
        dispatch(
          showSnackbar(
            dangerOptions,
            `Location default PPMs has not been changed. ${error.data.message}`,
          ),
        );
      });
  };
}

export {
  updateDefaultPPMsActionType,
  updateDefaultPPMsSuccessActionType,
  updateDefaultPPMsFailureActionType,
  updateDefaultPPMs,
};
