import PropTypes from 'prop-types';
import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  Field,
  FormSection,
  reduxForm,
  getFormSyncErrors,
  getFormValues,
  change,
} from 'redux-form';
import styled from 'styled-components';

import { getCompaniesContact } from 'actions/Locations/getCompaniesContact';

import { FieldInput, FieldSelect } from 'shared/components/Fields';
import { Checkbox, CheckboxWrapper } from 'shared/components/Fields/FieldCheckbox/FieldCheckbox';
import { Form, Section } from 'shared/components/Form';
import { formName } from 'shared/components/Wizard/Wizard';
import { samplePackageEmptyFieldsAccess } from 'shared/helpers/accesses/packages/samplePackageAccess';
import { globalRefiningGroupName } from 'shared/helpers/constants/companies/globalRefiningGroup';
import { PACKAGES } from 'shared/helpers/constants/packages/packageConstants';
import { packageType } from 'shared/helpers/constants/packages/packageType';
import { calculateAssignedCats } from 'shared/helpers/dataParsers/packages/calculateAssignedCats';
import { parseSamplePackages } from 'shared/helpers/dataParsers/packages/parseSamplePackages';
import { capitalize } from 'shared/helpers/parsers/text';
import { validate } from 'shared/helpers/validations/package/samplePackageListValidation';

import SelectPrimaryContact from 'pages/Locations/components/LocationsManagement/LocationForm/components/SelectPrimaryContact/SelectPrimaryContact';
import {
  LaboratoryPolicy,
  LaboratoryInfo,
} from 'pages/Packages/components/PackagesManagement/components/SamplePackagesForm/StyledSamplePackagesForm';

import { SamplePackagesList } from './components/SamplePackagesList/SamplePackagesList';

const StyledCheckbox = styled(Checkbox)`
  display: flex;
  align-items: center;
  cursor: pointer;

  ${CheckboxWrapper} {
    padding-left: 10px;
  }
`;

const SamplePackagesFormLayout = ({
  handleSubmit,
  invalid,
  submitting,
  onSubmit: handleCustomSubmit,
}) => {
  const dispatch = useDispatch();
  const {
    user: { userType, relatedCompany: userRelatedCompany },
  } = useSelector(state => state.auth);
  const { state } = useLocation();
  const wizardData = useSelector(state => getFormValues('wizard')(state));
  const sentFromCompany = useSelector(state => state?.lotDetails?.lot?.sentFromCompany);
  const formErrors = useSelector(state => getFormSyncErrors(formName)(state, 'packages'));
  const { isPending } = useSelector(state => state.createMultiplePackages);

  const hasErrors = formErrors.packages
    ?.map(p => p?.samplePackages?.some(err => err && Object.values(err).length))
    .some(err => !!err);

  const removeUnnecessaryPackages = () =>
    wizardData.packages.forEach((pkg, index) => {
      if ((pkg?.samplePackages || [])?.length <= pkg.unitCount) {
        return;
      }

      change(
        `packages[${index}].samplePackages`,
        pkg?.samplePackages?.splice(pkg.unitCount, pkg?.samplePackages?.length - pkg.unitCount),
      );
    });

  useEffect(() => {
    removeUnnecessaryPackages();
  }, []);

  const changeLaboratoryPolicy = () => {
    dispatch(
      change(
        'wizard',
        'samplePackages.laboratoryPolicy',
        !wizardData.samplePackages.laboratoryPolicy,
      ),
    );
  };

  const getCompaniesContactAction = useCallback(
    ({ name, value }) => dispatch(getCompaniesContact({ name, value })),
    [],
  );

  const parseContactPersonOptions = () =>
    [PACKAGES.PORTAL_USER_VALUE, PACKAGES.EXTERNAL_USER_VALUE].map(type => ({
      value: type,
      label: `${capitalize(type)} user`,
    }));

  const isPortalUserSelected = () =>
    wizardData.samplePackages?.contactPerson?.value === PACKAGES.PORTAL_USER_VALUE;

  const onSubmit = useCallback(
    values =>
      handleCustomSubmit(
        parseSamplePackages({
          samplePackages: values.samplePackages,
          assignedCats: wizardData.packages,
        }),
      ),
    [wizardData.packages],
  );

  const hasIncompleteSamplePackages = useCallback(
    () =>
      !!wizardData.packages.filter(
        p =>
          p.packageType?.value === packageType.sample &&
          (p.samplePackages || []).filter(Boolean).length !== Number(p.unitCount),
      ).length,
    [wizardData.packages],
  );

  const getSamplePackageEmptyFieldsAccess = useCallback(
    userType => samplePackageEmptyFieldsAccess(userType),
    [userType],
  );

  return (
    <Form
      header={PACKAGES.CREATE_INCOMING_PACKAGES}
      onSubmit={handleSubmit(onSubmit)}
      submitDisabled={
        invalid ||
        submitting ||
        hasErrors ||
        !wizardData.samplePackages?.laboratoryPolicy ||
        (!getSamplePackageEmptyFieldsAccess(userType) && hasIncompleteSamplePackages())
      }
      loaded={!isPending}
    >
      <FormSection name="samplePackages">
        <Section template={[`contactPerson userName`]} header={PACKAGES.CONTACT_NAME}>
          <Field
            name={'contactPerson'}
            component={FieldSelect}
            options={parseContactPersonOptions()}
            label={PACKAGES.CONTACT_TYPE}
            field="required"
            searchable={false}
          />
          <div name="userName">
            {isPortalUserSelected() ? (
              <SelectPrimaryContact
                multi={false}
                name="portalUser"
                label={PACKAGES.CONTACT_USER_NAME}
                relatedCompany={
                  getSamplePackageEmptyFieldsAccess(userType)
                    ? sentFromCompany || {
                        _id: state.sentFromCompany._id || state.sentFromCompany.value,
                        companyName:
                          state.sentFromCompany.companyName || state.sentFromCompany.label,
                      }
                    : userRelatedCompany
                }
                fieldRequired
                getCompaniesContact={getCompaniesContactAction}
                data-testid="portalUser"
                field="required"
              />
            ) : (
              <Field
                name="externalUser"
                type="text"
                component={FieldInput}
                label={PACKAGES.CONTACT_USER_NAME}
                field="required"
                data-testid="externalUser"
              />
            )}
          </div>
        </Section>
      </FormSection>
      <Section template={['samplePackages samplePackages']} noPadding topBorder>
        <SamplePackagesList
          name="samplePackages"
          formErrors={formErrors}
          packages={calculateAssignedCats(wizardData.packages)}
          withoutRequiredFields={getSamplePackageEmptyFieldsAccess(userType)}
        />
      </Section>
      <Section
        header={PACKAGES.LABORATORY_POLICY}
        template={['laboratoryPolicySection laboratoryPolicySection .']}
        noPadding
      >
        <FormSection name="samplePackages">
          <LaboratoryPolicy name="laboratoryPolicySection" data-testid="laboratoryPolicySection">
            <Field
              field="required"
              name="laboratoryPolicy"
              component={StyledCheckbox}
              checked={wizardData.samplePackages.laboratoryPolicy}
              onClick={changeLaboratoryPolicy}
            />
            <LaboratoryInfo
              dangerouslySetInnerHTML={{
                __html: PACKAGES.LABORATORY_INFO(globalRefiningGroupName),
              }}
            ></LaboratoryInfo>
          </LaboratoryPolicy>
        </FormSection>
      </Section>
    </Form>
  );
};

SamplePackagesFormLayout.propTypes = {
  handleSubmit: PropTypes.func,
  onSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  invalid: PropTypes.bool,
};

const SamplePackagesForm = reduxForm({
  form: 'SamplePackagesForm',
  shouldError: () => true,
  validate,
})(SamplePackagesFormLayout);

export { SamplePackagesForm };
