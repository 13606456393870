import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, formValueSelector, isDirty, reduxForm } from 'redux-form';

import { CancelButton } from 'shared/components/Buttons';
import { Button } from 'shared/components/Buttons/Button';
import { FieldInput } from 'shared/components/Fields';
import { validate } from 'shared/helpers/validations/user/passwordValidation';

import { PasswordRequirements } from '../PasswordRequirements/PasswordRequirements';
import {
  StyledResetPasswordForm,
  StyledResetPasswordFormActionBtns,
  StyledResetPasswordFormEmail,
} from './StyledResetPasswordForm';

class ResetPasswordFormBase extends Component {
  onSubmit = values => {
    this.props.reset();
    this.props.onSubmit(values);
  };

  render() {
    const { userEmail, own, newPassword, dirty, invalid, buttonLabel, onCancel } = this.props;

    return (
      <StyledResetPasswordForm onSubmit={this.props.handleSubmit(this.onSubmit)} noValidate>
        {userEmail && !own && (
          <StyledResetPasswordFormEmail>{userEmail}</StyledResetPasswordFormEmail>
        )}
        {own && (
          <Field
            name="oldPassword"
            type="password"
            component={FieldInput}
            label="Old password"
            labelWidth={130}
          />
        )}
        <Field
          name="newPassword"
          type="password"
          component={FieldInput}
          label="New password"
          labelWidth={130}
        />
        <Field
          name="newPasswordRepeat"
          type="password"
          component={FieldInput}
          label="Repeat new password"
          labelWidth={130}
        />
        <PasswordRequirements password={newPassword} dirty={dirty && !!newPassword} />
        <StyledResetPasswordFormActionBtns>
          <Button id="submitButton" disabled={invalid} type="submit">
            {buttonLabel}
          </Button>
          {onCancel && <CancelButton onClick={onCancel} id="cancelButton" />}
        </StyledResetPasswordFormActionBtns>
      </StyledResetPasswordForm>
    );
  }
}

const selector = formValueSelector('ResetPasswordForm');

const mapStateToProps = state => ({
  userEmail: state.tokenValidation.info && state.tokenValidation.info.email,
  newPassword: selector(state, 'newPassword'),
  dirty: isDirty('ResetPasswordForm')(state),
});

const ResetPasswordForm = connect(mapStateToProps)(
  reduxForm({
    form: 'ResetPasswordForm',
    validate,
  })(ResetPasswordFormBase),
);

export { ResetPasswordFormBase, ResetPasswordForm };
