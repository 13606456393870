import React from 'react';

import { GroupButtons } from 'shared/components/Buttons';
import { GroupButton } from 'shared/components/Buttons/GroupButton/components/GroupButton';
import { DASHBOARD } from 'shared/helpers/constants/dashboardConstants';
import { isCustomerUser } from 'shared/helpers/matchers/checkUserType';

import dateAdapter from 'utils/date/dateAdapter';

import {
  StyledMainInfo,
  StyledMainInfoButtons,
  StyledMainInfoSubtitle,
  StyledMainInfoTitle,
} from './StyledMainInfo';

function MainInfo({ user, marketHoliday, ...props }) {
  const { relatedCompany, userType, databaseAccess, purchasingAppAccess } = user;

  return (
    <StyledMainInfo>
      <div className="MainInfo-info">
        <StyledMainInfoTitle>{DASHBOARD.DASHBOARD}</StyledMainInfoTitle>
        <StyledMainInfoSubtitle>{dateAdapter().format('MMMM DD, YYYY')}</StyledMainInfoSubtitle>
        {isCustomerUser(userType) && marketHoliday && (
          <StyledMainInfoSubtitle>{DASHBOARD.MARKET_HOLIDAY}</StyledMainInfoSubtitle>
        )}
      </div>
      {isCustomerUser(userType) && (
        <StyledMainInfoButtons>
          <GroupButtons borderColor="pelorous">
            <GroupButton
              access={databaseAccess}
              label={DASHBOARD.CONVERTER_DATABASE}
              icon="converter-database"
              onClick={() => window.open(relatedCompany.converterDatabaseURL, '_blank')}
            />
            <GroupButton
              access={purchasingAppAccess && relatedCompany.purchasingAppURL}
              label={DASHBOARD.PURCHASING_TOOL}
              icon="purchasing-tool"
              onClick={() => window.open(relatedCompany.purchasingAppURL, '_blank')}
            />
          </GroupButtons>
        </StyledMainInfoButtons>
      )}
    </StyledMainInfo>
  );
}

export { MainInfo };
