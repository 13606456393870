const SHARED = {
  DAY: 'day',
  DAYS: 'days',
  LIST_APPEND: 'View more',
  SAVE_BUTTON: 'Save',
  GENERATE_BUTTON: 'Generate',
  SUBMIT: 'Submit',
  CANCEL_BUTTON: 'Cancel',
  NOT_FOUND_MESSAGE: "It's dark and spooky here",
  RESET: 'Reset',
  SMS: 'SMS',
  EMAIL: 'Email',
  PORTAL: 'Portal',
  MODULES: 'Modules',
  MY_PROFILE: 'Profile',
  SYSTEM_SETTINGS: 'Settings',
  LOGOUT: 'Logout',
  REFRESH: 'Refresh',
  MENU: 'MENU',
  EXTENDABLE_ACTIONS: 'Actions',
  TABLE_ACTIONS: 'Actions',
  YES: 'Yes',
  NO: 'No',
  SUGGESTED: 'Suggested',
  MAXIMUM: 'Maximum',
  ERROR: 'App broke down 😞 Developers will be notified.',
  CLOSE: 'Close',
  DETAILS: 'Details',
  HOME: 'Home',
  DISPLAY: 'Display',
  WIDGETS: 'Widgets',
  SELECT_ALL: 'Select all',
  NON_BREAKING_SPACE: '\u00A0',
  NON_BREAKING_HYPHEN: '\u2011',
  DROPPABLE_PLACEHOLDER: name => `${name} ${/s$/.test(name) ? 'are' : 'is'} empty`,
  NA: 'n/a',
  EDIT: 'Edit',
  UPDATE: 'Update',
  CONFIRMATION: 'Are you sure?',
  SKIP: 'Skip',
  GENERATE_ANYWAY: 'Generate anyway',
  NEXT: 'Next',
  REQUEST_UPDATE_MARKET_INDICATORS: 'Request to update indicators',
  PROCEED: 'Proceed',
  CONFIRM: 'Confirm',
  AWAITING_STATUS: 'Awaiting status',
};

const COMMA_VALIDATION = {
  USE_PERIOD_FOR_FRACTIONS: 'Please use a period (not a comma) when entering fractional values.',
  USE_COMMA_TO_SEPARATE: 'A period cannot be used for the thousands separator.',
  COMMA_MUST_BE_FALLOWED_BY_THREE_DIGITS: 'Commas must be followed by three digits.',
  REMOVE_LEADING_ZEROS: 'Please remove leading zeros.',
};

export { SHARED, COMMA_VALIDATION };
