import {
  getDeliveriesListActionType,
  getDeliveriesListSuccessActionType,
  getDeliveriesListFailureActionType,
  clearDeliveriesListActionType,
} from 'actions/Deliveries/deliveriesList';

const INITIAL_STATE = {
  isPending: false,
  items: null,
};

const deliveriesListReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getDeliveriesListActionType:
      return {
        ...state,
        isPending: true,
      };
    case getDeliveriesListSuccessActionType:
      return {
        isPending: false,
        items: action.payload.data,
      };
    case getDeliveriesListFailureActionType:
      return {
        ...state,
        isPending: false,
      };
    case clearDeliveriesListActionType:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};

export { deliveriesListReducer };
