import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  clearPackagesOverview,
  getPackagesOverviewForLots,
} from 'actions/Packages/packagesOverviewList';

import { ListCollapse } from 'shared/components/ListCollapse/ListCollapse';
import {
  OverviewListSection,
  OverviewListSectionWrapper,
} from 'shared/components/OverviewList/OverviewListSection/OverviewListSection';
import { overviewPackageClasses } from 'shared/helpers/constants/packages/packageClasses';
import { PACKAGES } from 'shared/helpers/constants/packages/packageConstants';

import { OverviewPackagesList } from 'pages/Packages/components/OverviewList/OverviewPackagesList';
import { relatedModules } from 'pages/Packages/components/OverviewList/config/relatedModules';

export const CustomerPackagesOverview = ({ packagesOverviewList, auth }) => {
  const [isContentDisplayed, setIsContentDisplayed] = useState(true);

  const totalPackagesList = packagesOverviewList.packagesList.all?.total;
  const dispatch = useDispatch();

  const { id } = useParams();

  const sectionType = overviewPackageClasses.all;

  const toggleContent = () => {
    totalPackagesList && setIsContentDisplayed(prev => !prev);
  };

  const handleGetPackagesOverviewForLots = (id, limit, sectionType) => {
    dispatch(getPackagesOverviewForLots(id, limit, sectionType));
  };

  const matchProps = {
    params: {
      id,
    },
  };

  const handleClearPackagesOverview = sectionType => {
    dispatch(clearPackagesOverview(sectionType));
  };

  return (
    <OverviewListSectionWrapper isOpen={isContentDisplayed} total={totalPackagesList}>
      <OverviewListSection isOpen={isContentDisplayed} total={totalPackagesList}>
        <div>
          <ListCollapse
            collapse={isContentDisplayed}
            toggleList={toggleContent}
            label={PACKAGES.PACKAGES}
          />
        </div>
      </OverviewListSection>
      <div>
        <OverviewPackagesList
          relatedModule={relatedModules.lots}
          getPackagesOverview={handleGetPackagesOverviewForLots}
          sectionType={sectionType}
          auth={auth}
          lotId={id}
          clearPackagesOverview={handleClearPackagesOverview}
          match={matchProps}
          packagesOverviewList={packagesOverviewList}
          packagesList={packagesOverviewList[sectionType]}
        />
      </div>
    </OverviewListSectionWrapper>
  );
};

CustomerPackagesOverview.propTypes = {
  auth: PropTypes.object,
  packagesOverviewList: PropTypes.object,
};
