import {
  inactivateUserActionType,
  inactivateUserSuccessActionType,
  inactivateUserFailureActionType,
} from 'actions/Users/inactivateUser';

const INITIAL_STATE = {
  isPending: false,
};

const inactivateUserReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case inactivateUserActionType:
      return {
        isPending: true,
      };
    case inactivateUserSuccessActionType:
    case inactivateUserFailureActionType:
      return {
        isPending: false,
      };
    default:
      return state;
  }
};

export { inactivateUserReducer };
