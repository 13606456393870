import React from 'react';
import { connect } from 'react-redux';

import {
  uploadGradingReceipt,
  addGradingReceipt,
  clearGradingReceipt,
} from 'actions/Lots/uploadGradingReceipt';
import { showSnackbar } from 'actions/shared/snackbar';

import { ModalCustom } from 'components/ModalCustom/ModalCustom';

import { Preloader } from 'shared/components/Preloader/Preloader';
import { FILES_CONSTANTS } from 'shared/helpers/constants/filesConstants';
import {
  successOptions,
  dangerOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

import { UploadGradingReceiptContent } from './components/UploadGradingReceiptContent';

function UploadGradingReceiptLayout(props) {
  const uploadFile = () => {
    props
      .uploadGradingReceipt(props.lot._id, props.uploadGradingReceiptFile)
      .then(res => {
        props.showSnackbar(successOptions, res.message);
        props.onSuccess();
        props.toggle();
      })
      .catch(err => {
        props.showSnackbar(dangerOptions, err.message);
        props.toggle();
      });
  };

  return (
    <ModalCustom isOpen={props.open} modalClose={props.toggle}>
      <UploadGradingReceiptContent
        onSubmit={uploadFile}
        addGradingReceipt={props.addGradingReceipt}
        clearGradingReceipt={props.clearGradingReceipt}
        uploadGradingReceiptFile={props.uploadGradingReceiptFile}
        grgLotNumber={props.lot.grgLotNumber}
      />
      <Preloader
        loading={props.uploadGradingReceiptFile.isPending}
        label={FILES_CONSTANTS.UPLOADING_FILES}
      />
    </ModalCustom>
  );
}

const mapStateToProps = ({ uploadGradingReceiptFile }) => ({
  uploadGradingReceiptFile,
});

const mapDispatchToProps = {
  showSnackbar,
  uploadGradingReceipt,
  addGradingReceipt,
  clearGradingReceipt,
};

const UploadGradingReceipt = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UploadGradingReceiptLayout);

export { UploadGradingReceipt, UploadGradingReceiptLayout };
