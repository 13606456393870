const hedgeStatus = {
  open: 'open',
  approved: 'approved',
  placed: 'placed',
  partly_filled: 'partly_filled',
  filled: 'filled',
  cancelled: 'cancelled',
  reassigned: 'reassigned',
};

const hedgeStatusOverlay = {
  scheduled: 'scheduled',
};

const { cancelled, reassigned, ...hedgeStatusForCustomer } = hedgeStatus;

export { hedgeStatus, hedgeStatusForCustomer, hedgeStatusOverlay };
