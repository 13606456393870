import PropTypes from 'prop-types';
import React, { memo } from 'react';
import styled from 'styled-components';

import { Button } from 'shared/components/Buttons/Button';
import { SubmitButton } from 'shared/components/Buttons/SubmitButton/SubmitButton';
import { SHARED } from 'shared/helpers/constants/sharedConstants';
import { cssVar, media } from 'shared/helpers/styling/styling';

const Actions = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 40px 100px;
  border-top: 1px solid ${cssVar('dustGray')};

  ${media.phone`
    padding: 15px 15px;
  `}
`;

const CustomActions = memo(({ onSubmit, disabled, lastStep }) => {
  const handleSubmit = () => onSubmit({ skip: true });

  return (
    <Actions>
      <SubmitButton
        label={lastStep ? SHARED.SUBMIT : SHARED.NEXT}
        disabled={disabled}
        onClick={onSubmit}
      />
      <Button outline onClick={handleSubmit}>
        {SHARED.SKIP}
      </Button>
    </Actions>
  );
});

CustomActions.defaultProps = {
  lastStep: true,
};

CustomActions.propType = {
  onSubmit: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  lastStep: PropTypes.bool,
};

export { CustomActions };
