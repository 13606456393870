import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';

import { getCompaniesForEnterPoolAccountOunces } from 'actions/Companies/filterCompanies';
import { getLotsForEnterPoolAccountOunces } from 'actions/Lots/getAssignedLots';
import { openModal } from 'actions/shared/modal';

import { FieldInput, FieldSearch } from 'shared/components/Fields';
import { FieldSelect } from 'shared/components/Fields/FieldSelect/FieldSelect';
import { Form, Section } from 'shared/components/Form';
import { MultipleFieldsSection } from 'shared/components/Form/MultipleFieldsSection/MultipleFieldsSection';
import { cancelModal } from 'shared/helpers/constants/modalConstants';
import { POOL_ACCOUNTS } from 'shared/helpers/constants/poolAccounts/poolAccountsConstants';
import { parseAllLotsSelectData } from 'shared/helpers/dataParsers/lot/parseLotsSelectData';
import { validate } from 'shared/helpers/validations/poolAccounts/enterPoolAccountOuncesValidation';

const EnterOuncesFormLayout = props => {
  const { handleSubmit, invalid, pristine, relatedCompany, relatedLot } = props;

  const modalOpen = () => {
    props.openModal(cancelModal, () => props.history.push('/pool-accounts'));
  };

  const listCompanies = input =>
    !input ? Promise.resolve({ options: [] }) : props.getCompaniesForEnterPoolAccountOunces(input);

  const companyOnChange = company => {
    props.resetSection('relatedLot');
    company && props.getLotsForEnterPoolAccountOunces(company.value);
  };

  const lotOnChange = lot => {
    props.resetSection('platinumOz');
    props.resetSection('palladiumOz');
    props.resetSection('rhodiumOz');

    if (lot) {
      const { platinumOz, palladiumOz, rhodiumOz } = lot;

      platinumOz && props.change('platinumOz', platinumOz);
      palladiumOz && props.change('palladiumOz', palladiumOz);
      rhodiumOz && props.change('rhodiumOz', rhodiumOz);
    }
  };

  return (
    <Form
      onSubmit={handleSubmit}
      onCancel={modalOpen}
      submitDisabled={invalid || pristine}
      header={POOL_ACCOUNTS.POOL_ACCOUNTS}
    >
      <Section template={['relatedCompany relatedLot']}>
        <Field
          name="relatedCompany"
          component={FieldSearch}
          label={POOL_ACCOUNTS.RELATED_COMPANY}
          field="required"
          getOptions={listCompanies}
          onChange={companyOnChange}
        />
        <Field
          name="relatedLot"
          component={FieldSelect}
          options={parseAllLotsSelectData(props.assignedLots.data)}
          onChange={lotOnChange}
          disabled={!relatedCompany}
          label={POOL_ACCOUNTS.RELATED_LOT}
          field="required"
        />
      </Section>
      <Section template={['ounces ounces']} header={POOL_ACCOUNTS.OUNCES} noPadding>
        <MultipleFieldsSection
          name="ounces"
          fields={[
            {
              name: 'platinumOz',
              type: 'number',
              component: FieldInput,
              disabled: relatedLot && !!relatedLot.platinumOz,
              label: POOL_ACCOUNTS.PLATINUM,
              field: 'required',
              suffix: 'oz',
            },
            {
              name: 'palladiumOz',
              type: 'number',
              component: FieldInput,
              disabled: relatedLot && !!relatedLot.palladiumOz,
              label: POOL_ACCOUNTS.PALLADIUM,
              field: 'required',
              suffix: 'oz',
            },
            {
              name: 'rhodiumOz',
              type: 'number',
              component: FieldInput,
              disabled: relatedLot && !!relatedLot.rhodiumOz,
              label: POOL_ACCOUNTS.RHODIUM,
              field: 'required',
              suffix: 'oz',
            },
          ]}
        />
      </Section>
    </Form>
  );
};

const selector = formValueSelector('EnterOuncesForm');

const mapStateToProps = state => ({
  relatedCompany: selector(state, 'relatedCompany'),
  relatedLot: selector(state, 'relatedLot'),
  assignedLots: state.assignedLots,
});

const mapDispatchToProps = {
  getCompaniesForEnterPoolAccountOunces,
  getLotsForEnterPoolAccountOunces,
  openModal,
};

const EnterOuncesForm = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'EnterOuncesForm',
    validate,
  }),
)(EnterOuncesFormLayout);

export { EnterOuncesFormLayout, EnterOuncesForm };
