import PropTypes from 'prop-types';

import { DatepickerDropdown } from 'shared/components/DatepickerDropdown/DatepickerDropdown';
import { FiltersDropdown } from 'shared/components/FiltersDropdown/FiltersDropdown';
import { FILTERS } from 'shared/helpers/constants/filters/filtersConstants';
import { hedgedStatus as hedgedStatuses } from 'shared/helpers/constants/filters/hedgedStatus';
import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';
import { materialTypes } from 'shared/helpers/constants/lots/materialTypes';
import { statusesForGlobal } from 'shared/helpers/constants/lots/statusesForGlobal';
import { capitalizeGlobal } from 'shared/helpers/parsers/text';

import dateAdapter from 'utils/date/dateAdapter';
import { MomentAdapter } from 'utils/date/momentAdapter';

import { StyledLotsFilters, StyledLotsFiltersItem } from '../StyledLotFilters';

export const GlobalLotsFilters = ({ filters, onFiltersChanged }) => {
  const { hedgedStatus, materialType, receivedAtStart, receivedAtEnd, statusForGlobal } = filters;
  const capitalizedStatusForGlobal = statusForGlobal[0] && [capitalizeGlobal(statusForGlobal[0])];

  const filterFunction = name => value => {
    onFiltersChanged({
      ...filters,
      [name]: value instanceof MomentAdapter ? value.valueOf() : value,
    });
  };

  const renderDateFilter = (name, label, minDate, maxDate, selected) => (
    <StyledLotsFiltersItem>
      <DatepickerDropdown
        label={label}
        selected={dateAdapter(selected)}
        filterFunction={filterFunction(name)}
        minDate={dateAdapter(minDate || 0)}
        maxDate={dateAdapter(maxDate)}
      />
    </StyledLotsFiltersItem>
  );

  return (
    <StyledLotsFilters>
      <StyledLotsFiltersItem>
        <FiltersDropdown
          label={LOTS.STATUS_FOR_GLOBAL}
          options={Object.values(statusesForGlobal)}
          selected={capitalizedStatusForGlobal || []}
          filterFunction={filterFunction('statusForGlobal')}
        />
      </StyledLotsFiltersItem>
      <StyledLotsFiltersItem>
        <FiltersDropdown
          label={LOTS.MATERIAL_TYPE}
          options={Object.values(materialTypes)}
          selected={materialType || []}
          filterFunction={filterFunction('materialType')}
        />
      </StyledLotsFiltersItem>
      <StyledLotsFiltersItem>
        <FiltersDropdown
          label={LOTS.HEDGED_STATUS}
          options={Object.values(hedgedStatuses)}
          selected={hedgedStatus || []}
          filterFunction={filterFunction('hedgedStatus')}
        />
      </StyledLotsFiltersItem>
      {renderDateFilter(
        'receivedAtStart',
        FILTERS.LABELS.RECEIVED_ON_FROM,
        0,
        new Date(),
        receivedAtStart,
      )}
      {renderDateFilter(
        'receivedAtEnd',
        FILTERS.LABELS.RECEIVED_ON_TO,
        receivedAtStart,
        Infinity,
        receivedAtEnd,
      )}
    </StyledLotsFilters>
  );
};

GlobalLotsFilters.propTypes = {
  filters: PropTypes.shape({
    hedgedStatus: PropTypes.arrayOf(PropTypes.string),
    materialType: PropTypes.arrayOf(PropTypes.string),
    receivedAtEnd: PropTypes.number,
    receivedAtStart: PropTypes.number,
    sentFromCompanyLocation: PropTypes.shape({
      _id: PropTypes.string,
      relatedCompany: PropTypes.string,
      name: PropTypes.string,
    }),
    statusForCustomer: PropTypes.arrayOf(PropTypes.string),
    statusForGlobal: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  onFiltersChanged: PropTypes.func.isRequired,
};
