import PropTypes from 'prop-types';
import { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { clearLotPayments, getLotPayments } from 'actions/Payments/getLotPayments';
import { clearSharedData } from 'actions/shared/saveSharedData';

import { OverviewListWrapper } from 'shared/components/OverviewListWrapper/OverviewListWrapper';
import { StatusIndicator } from 'shared/components/StatusIndicator/StatusIndicator';
import { PAYMENTS } from 'shared/helpers/constants/payments/paymentsConstants';
import { SHARED } from 'shared/helpers/constants/sharedConstants';
import { formatDate, formatToDollarWithPrefix } from 'shared/helpers/parsers/formaters';
import { humanize, replaceEmpty } from 'shared/helpers/parsers/text';

import { history } from 'utils/history';

const templates = {
  status: {
    label: PAYMENTS.STATUS,
    render: ({ status }) => <StatusIndicator status={status} />,
  },
  paymentNumber: {
    label: PAYMENTS.PAYMENT_NUMBER,
    render: ({ paymentNumber }) => replaceEmpty(paymentNumber),
  },
  paidOn: {
    label: PAYMENTS.PAID_ON,
    render: ({ paidAt }) => replaceEmpty(formatDate(paidAt)),
  },
  paymentAmount: {
    label: PAYMENTS.PAYMENT_AMOUNT,
    render: ({ paymentAmount }) => formatToDollarWithPrefix(paymentAmount),
  },
  fundsType: {
    label: PAYMENTS.FUNDS_TYPE,
    render: ({ fundsType }) => humanize(fundsType),
  },
  paymentType: {
    label: PAYMENTS.PAYMENT_TYPE,
    render: ({ paymentType }) => humanize(paymentType),
  },
};

const itemsTemplate = [
  templates.status,
  templates.paymentNumber,
  templates.paidOn,
  templates.paymentAmount,
  templates.fundsType,
  templates.paymentType,
];

const itemsTemplateMobile = [templates.status, templates.paymentNumber];

const itemDetailsTemplate = [
  templates.paidOn,
  templates.paymentAmount,
  templates.fundsType,
  templates.paymentType,
];

const actionsItemTemplate = lotId => [
  {
    icon: 'arrow-right',
    action: SHARED.DETAILS,
    onClick:
      ({ _id }) =>
      () =>
        history.push(`/lots/list/${lotId}/payments/${_id}`),
  },
];

const PaymentsOverviewBase = ({
  match: {
    params: { id },
  },
}) => {
  const { payments, isPending } = useSelector(state => state.lotPayments);
  const { scrollToPayment } = useSelector(state => state.sharedData);
  const dispatch = useDispatch();

  const paymentsSectionRef = useRef(null);

  const scrollIntoView = () => {
    paymentsSectionRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    dispatch(getLotPayments(id, 3)).finally(() => {
      if (scrollToPayment) {
        scrollIntoView();
        dispatch(clearSharedData('scrollToPayment'));
      }
    });

    return () => clearLotPayments();
  }, [dispatch, id]);

  const normalizedPayments = useMemo(
    () => ({
      items: {
        docs: payments?.docs,
        total: payments?.total,
        pages: payments?.pages,
      },
      isPending,
    }),
    [payments, isPending],
  );

  return (
    <OverviewListWrapper
      list={normalizedPayments}
      getList={(_listPage, listLimit) => dispatch(getLotPayments(id, listLimit))}
      itemsTemplate={itemsTemplate}
      itemsTemplateMobile={itemsTemplateMobile}
      itemDetailsTemplate={itemDetailsTemplate}
      actionsTemplate={actionsItemTemplate(id)}
      label={PAYMENTS.PAYMENTS}
      ref={paymentsSectionRef}
    />
  );
};

export const PaymentsOverview = withRouter(PaymentsOverviewBase);

PaymentsOverviewBase.propTypes = {
  match: PropTypes.object,
};
