import React, { Component, Fragment } from 'react';

import { ContentLoader } from 'shared/components/ContentLoader/ContentLoader';
import { DetailsTabs } from 'shared/components/Details/DetailsTabs';
import { renderDetailsContainer } from 'shared/components/Details/helpers/renderDetailsContainer';
import { Preloader } from 'shared/components/Preloader/Preloader';
import { FILES_CONSTANTS } from 'shared/helpers/constants/filesConstants';
import { SHIPMENTS } from 'shared/helpers/constants/shipments/shipmentsConstants';
import { dangerOptions } from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

import { extractErrorMessage } from 'utils/extractErrorMessage';

import { PackagesOverview } from './components/PackagesOverview/PackagesOverview';
import { ShipmentMainDetails } from './components/ShipmentMainDetails/ShipmentMainDetails';
import { shipmentDetailsContainerConfig, shipmentDetailsFieldsConfig, tabsConfig } from './config';

class ShipmentDetails extends Component {
  componentDidMount() {
    this.props
      .getShipment(this.props.match.params.id)
      .then(shipment => shipment || Promise.reject(null))
      .catch(error => {
        this.props.showSnackbar(dangerOptions, extractErrorMessage(error));
        return this.props.history.push('/shipments');
      });
  }

  componentWillUnmount() {
    this.props.clearShipmentDetailsAction();
    this.props.clearAllPackagesOverview();
  }

  render() {
    const {
      auth,
      shipmentDetails,
      history,
      location,
      match,
      downloadAttachmentStorage,
      downloadAttachment,
      packagesOverviewList: { packagesList },
      markAsSentState: { isPending: isSending },
    } = this.props;
    const { shipment, isPending } = shipmentDetails;

    return (
      <>
        <ContentLoader loaded={shipment && !isPending}>
          {shipment && (
            <>
              <ShipmentMainDetails
                shipment={shipment}
                auth={auth}
                history={history}
                location={location}
                match={match}
                packagesList={packagesList}
                isSending={isSending}
              />
              {renderDetailsContainer(
                shipmentDetailsContainerConfig,
                shipmentDetailsFieldsConfig,
                auth,
                shipment,
              )}
              <DetailsTabs tabsConfig={tabsConfig(shipment, downloadAttachment, auth)} />
              <PackagesOverview
                {...this.props}
                getPackagesOverview={this.props.getPackagesOverviewForShipments}
                label={SHIPMENTS.PACKAGES}
              />
            </>
          )}
        </ContentLoader>
        <Preloader
          loading={downloadAttachmentStorage.isPending}
          label={FILES_CONSTANTS.DOWNLOADING_FILES}
        />
      </>
    );
  }
}

export { ShipmentDetails };
