import React, { Component } from 'react';
import { connect } from 'react-redux';

import { generateFinalInvoice } from 'actions/Lots/generateFinalInvoice';
import {
  getFinalInvoiceMaximumOunces,
  clearFinalInvoiceMaximumOunces,
} from 'actions/Lots/getFinalInvoiceMaximumOunces';
import { getLot } from 'actions/Lots/lotDetails';
import { openModal } from 'actions/shared/modal';
import { showSnackbar } from 'actions/shared/snackbar';

import { FormContainer } from 'shared/components/Form';
import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';
import { generateInvoiceModal } from 'shared/helpers/constants/modalConstants';
import {
  dangerOptions,
  successOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { formatToDollar } from 'shared/helpers/parsers/formaters';

import { FinalInvoiceForm } from '../FinalInvoiceForm/FinalInvoiceForm';

class GenerateFinalInvoiceLayout extends Component {
  componentDidMount() {
    this.props.getFinalInvoiceMaximumOunces(this.props.match.params.id);
    this.props.getLot(this.props.match.params.id);
  }

  componentWillUnmount() {
    this.props.clearFinalInvoiceMaximumOunces();
  }

  generateInvoice = values => {
    this.props
      .generateFinalInvoice(
        values,
        this.props.match.params.id,
        this.props.finalInvoiceMaximumOunces.data.removingOuncesDisabled,
      )
      .then(res => {
        this.props.showSnackbar(successOptions, res.message);
        this.props.history.push(`/lots/list/${this.props.match.params.id}`);
      })
      .catch(res => {
        this.props.showSnackbar(dangerOptions, res.message);
      });
  };

  submit = values => {
    const { stillwaterFinalInvoice, stillwaterShipmentSummary } =
      this.props.finalInvoiceMaximumOunces.data;

    !stillwaterFinalInvoice || !stillwaterShipmentSummary
      ? this.props.openModal(generateInvoiceModal, () => this.generateInvoice(values))
      : this.generateInvoice(values);
  };

  render() {
    const { finalInvoiceDetails, finalInvoiceMaximumOunces } = this.props;
    const {
      platinumOuncesRemoved,
      palladiumOuncesRemoved,
      rhodiumOuncesRemoved,
      balanceRemaining,
    } = finalInvoiceMaximumOunces.data || {};

    return (
      <FormContainer
        header={LOTS.GENERATE_FINAL_INVOICE}
        loaded={!finalInvoiceDetails.isPending && finalInvoiceMaximumOunces.data}
      >
        {() => (
          <FinalInvoiceForm
            {...this.props}
            onSubmit={this.submit}
            initialValues={{
              platinumOuncesRemoved: platinumOuncesRemoved || 0,
              palladiumOuncesRemoved: palladiumOuncesRemoved || 0,
              rhodiumOuncesRemoved: rhodiumOuncesRemoved || 0,
              balanceRemaining,
              outstandingBalanceApplied: !!balanceRemaining
                ? formatToDollar(Math.abs(balanceRemaining))
                : Math.abs(balanceRemaining),
            }}
          />
        )}
      </FormContainer>
    );
  }
}

const mapStateToProps = state => ({
  finalInvoiceDetails: state.finalInvoiceDetails,
  lotDetails: state.lotDetails,
  finalInvoiceMaximumOunces: state.finalInvoiceMaximumOunces,
});

const mapDispatchToProps = {
  openModal,
  generateFinalInvoice,
  getLot,
  showSnackbar,
  getFinalInvoiceMaximumOunces,
  clearFinalInvoiceMaximumOunces,
};

const GenerateFinalInvoice = connect(
  mapStateToProps,
  mapDispatchToProps,
)(GenerateFinalInvoiceLayout);

export { GenerateFinalInvoiceLayout, GenerateFinalInvoice };
