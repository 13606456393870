import { BUILDING, SECTION, LANE } from 'shared/helpers/constants/lots/lotLocation';
import { lotConstants } from 'shared/helpers/constants/lots/updateLotLocationConstants';
import { INTEGER_WITHOUT_LEADING_ZEROES } from 'shared/helpers/validations/regularExpressions';

function validate(values) {
  const errors = {};

  if (!values.building || !values.building.trim()) {
    errors.building = lotConstants.INVALID_BUILDING_EMPTY;
  } else if (!BUILDING.test(values.building) || values.building.length > 1) {
    errors.building = lotConstants.INVALID_BUILDING;
  }

  errors.locationStored = (values.locationStored || []).map(location => {
    const locationErrors = {};

    if (!location.section || !location.section.trim()) {
      locationErrors.section = lotConstants.INVALID_SECTION_EMPTY;
    } else if (!INTEGER_WITHOUT_LEADING_ZEROES.test(location.section)) {
      locationErrors.section = lotConstants.INVALID_SECTION;
    } else if (parseFloat(location.section) < 1 || parseFloat(location.section) > SECTION) {
      locationErrors.section = lotConstants.INVALID_SECTION_RANGE(SECTION);
    }

    if (!location.lane) {
      locationErrors.lane = '';
    } else if (!LANE.test(location.lane) || location.lane.length > 1) {
      locationErrors.lane = lotConstants.INVALID_LANE;
    }

    return locationErrors;
  });

  return errors;
}

export { validate };
