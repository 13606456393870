import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { NotFound } from 'components/ErrorPage/ErrorPage';

import { ConnectedNotificationsList } from './components/NotificationsList/ConnectedNotificationsList';

function Notifications() {
  return (
    <Switch>
      <Route exact path="/notifications" component={ConnectedNotificationsList} />
      <Route component={NotFound} />
    </Switch>
  );
}

export { Notifications };
