import axios from 'axios';

import { setPackageAsClosedAction } from 'actions/Packages/packagesOverviewList';
import { getShipmentDetailsSuccessAction } from 'actions/Shipments/shipmentDetails';

import { overviewPackageClasses } from 'shared/helpers/constants/packages/packageClasses';
import { SHIPMENTS } from 'shared/helpers/constants/shipments/shipmentsConstants';

const API_HOST_AUTH = process.env.REACT_APP_API;

const markAsSentActionType = 'MARK_AS_SENT';
const markAsSentAction = () => ({
  type: markAsSentActionType,
});

const markAsSentSuccessActionType = `${markAsSentActionType}_SUCCESS`;
const markAsSentSuccessAction = () => ({
  type: markAsSentSuccessActionType,
});

const markAsSentFailureActionType = `${markAsSentActionType}_FAILURE`;
const markAsSentFailureAction = () => ({
  type: markAsSentFailureActionType,
});

const markAsSent =
  (id, lotsInformation = {}, refreshData = true) =>
  dispatch => {
    dispatch(markAsSentAction());

    return axios
      .put(`${API_HOST_AUTH}/shipments/mark-as-sent/${id}`, lotsInformation)
      .then(res => {
        dispatch(markAsSentSuccessAction());
        if (refreshData) {
          dispatch(setPackageAsClosedAction(overviewPackageClasses.all));
          dispatch(getShipmentDetailsSuccessAction(res.data.shipment));
        }

        return {
          id: res.data.id,
          message: res.data.message,
        };
      })
      .catch(error => {
        dispatch(markAsSentFailureAction());

        return Promise.reject({
          message: `${SHIPMENTS.ERROR.MARK_AS_SENT_ERROR} ${error.data.message}`,
        });
      });
  };

export {
  markAsSentActionType,
  markAsSentAction,
  markAsSentSuccessActionType,
  markAsSentSuccessAction,
  markAsSentFailureActionType,
  markAsSentFailureAction,
  markAsSent,
};
