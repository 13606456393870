import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { clearLotDetailsAction } from 'actions/Lots/lotDetails';
import { getGlobalCreatedPackage } from 'actions/Packages/packageDetails';
import { updatePackageWeight } from 'actions/Packages/updatePackage';
import { showSnackbar } from 'actions/shared/snackbar';

import { UpdatePackageWeight } from './UpdatePackageWeight';

const mapStateToProps = state => ({
  lotDetails: state.lotDetails,
  packageDetails: state.packageDetails,
});

const mapDispatchToProps = {
  getGlobalCreatedPackage,
  showSnackbar,
  updatePackageWeight,
  clearLotDetailsAction,
};

const ConnectedUpdatePackageWeight = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UpdatePackageWeight),
);

export { ConnectedUpdatePackageWeight };
