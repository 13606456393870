import {
  getWarehouseDataActionType,
  getWarehouseDataSuccessActionType,
  getWarehouseDataFailureActionType,
} from 'actions/Lots/getWarehouseDashboardData';
import { updateWarehouseDataActionType } from 'actions/Lots/updateWarehouseDashboardData';

const INITIAL_STATE = {
  isPending: false,
  warehouseData: null,
};

const warehouseDashboardDataReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getWarehouseDataActionType:
      return {
        ...state,
        isPending: true,
      };
    case getWarehouseDataSuccessActionType:
      return {
        warehouseData: action.payload.data,
        isPending: false,
      };
    case updateWarehouseDataActionType:
      const oldSection = action.payload.oldSection;
      const newSection = action.payload.updatedVirtualLotData.section;
      const lotIndex = state.warehouseData[oldSection].findIndex(
        ({ _id }) => _id === action.payload.updatedVirtualLotData._id,
      );

      const updatedLot = {
        ...state.warehouseData[oldSection][lotIndex],
        ...action.payload.updatedVirtualLotData,
      };

      const newState =
        oldSection !== newSection
          ? {
              [oldSection]: state.warehouseData[oldSection].filter(
                (lot, index) => index !== lotIndex,
              ),
              [newSection]: [...state.warehouseData[newSection], updatedLot],
            }
          : {
              [newSection]: state.warehouseData[newSection].map((lot, index) =>
                index !== lotIndex ? lot : updatedLot,
              ),
            };

      return {
        warehouseData: {
          ...state.warehouseData,
          ...newState,
        },
      };
    case getWarehouseDataFailureActionType:
      return {
        ...state,
        isPending: false,
      };
    default:
      return state;
  }
};

export { warehouseDashboardDataReducer };
