import * as FileSaver from 'file-saver';

import { getFilename } from 'shared/helpers/dataParsers/attachments/getFilename';

function downloadFile(res) {
  const blob = new Blob([res.data], { type: res.headers['content-type'] });

  FileSaver.saveAs(blob, getFilename(res));
}

export { downloadFile };
