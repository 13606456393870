import {
  clearPaymentsActionType,
  getPaymentsActionType,
  getPaymentsFailureActionType,
  getPaymentsSuccessActionType,
} from 'actions/Payments/paymentsList';

const INITIAL_STATE = {
  isPending: false,
  payments: {},
  filters: {
    status: [],
    paymentType: [],
    fundsType: [],
    query: '',
    isPaid: null,
  },
  pagination: { limit: 50, page: 1 },
};

const PaymentsListReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getPaymentsActionType:
      return {
        ...state,
        filters: action.payload.filters,
        pagination: action.payload.pagination,
        isPending: true,
      };
    case getPaymentsSuccessActionType:
      return {
        ...state,
        isPending: false,
        payments: action.payload.payments,
      };
    case getPaymentsFailureActionType:
      return {
        ...state,
        isPending: false,
      };
    case clearPaymentsActionType:
      return {
        ...INITIAL_STATE,
        filters: state.filters,
      };
    default:
      return state;
  }
};

export { PaymentsListReducer };
