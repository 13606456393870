import axios from 'axios';

import { message } from 'shared/helpers/constants/hedges/createRecipientsConstants';
import { EMAIL_REGEX } from 'shared/helpers/validations/regularExpressions';

const API_HOST_AUTH = process.env.REACT_APP_API;

function validate({ email }) {
  const error = {};

  if (!email) {
    error.email = message.EMAIL_EMPTY;
  } else if (!EMAIL_REGEX.test(email)) {
    error.email = message.EMAIL_FORMAT;
  }

  return error;
}

function asyncValidate({ email }) {
  return axios
    .get(`${API_HOST_AUTH}/hedges-recipients/${encodeURIComponent(email)}`)
    .then(({ data }) => {
      if (data) {
        const dateError = { email: message.EMAIL_UNIQUE };
        throw dateError;
      }
    });
}

export { validate, asyncValidate };
