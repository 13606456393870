import { denominationTable } from 'shared/helpers/constants/materials/denominationTable';
import { CREATE_PACKAGE } from 'shared/helpers/constants/packages/createPackageConstants';
import { isWarehouseUser } from 'shared/helpers/matchers/checkUserType';
import { safeParseFloat } from 'shared/helpers/parsers/number';
import { replaceEmpty } from 'shared/helpers/parsers/text';
import { commaValidator } from 'shared/helpers/validations/commaValidator';
import { FLOAT_REGEX, INTEGER_REGEX } from 'shared/helpers/validations/regularExpressions';
import {
  CAPITAL_ALPHANUM_REGEX,
  CAPITAL_ALPHANUM_WHITE_SPACES_REGEX,
} from 'shared/helpers/validations/regularExpressions';

import {
  configs,
  isGlobalCreatedPackage,
  isProcessingPackage,
} from 'pages/Packages/components/PackagesManagement/components/PackageForm/components/PackageForm/fieldsConfig';

const validationRules = {
  customerInstructions(values) {
    if (
      values.materialTypeDeclared === 'other' &&
      (!values.customerInstructions || !values.customerInstructions.trim())
    ) {
      return CREATE_PACKAGE.INVALID_CUSTOMER_INSTRUCTIONS_EMPTY;
    }

    if (values.customerInstructions && values.customerInstructions.length > 100) {
      return CREATE_PACKAGE.INVALID_CUSTOMER_INSTRUCTIONS;
    }
  },
  assignedLot(values) {
    if (!values.assignedLot) {
      return CREATE_PACKAGE.INVALID_ASSIGNED_LOT_EMPTY;
    }
  },
  labelId(values) {
    if (
      values.labelId &&
      (!CAPITAL_ALPHANUM_REGEX.test(values.labelId) || replaceEmpty(values.labelId, '').length > 50)
    ) {
      return CREATE_PACKAGE.INVALID_LABEL_ID_FORMAT;
    }
  },
  plant(values) {
    if (
      values.plant &&
      (!CAPITAL_ALPHANUM_WHITE_SPACES_REGEX.test(values.plant) ||
        replaceEmpty(values.plant, '').length > 50)
    ) {
      return CREATE_PACKAGE.INVALID_PLANT_FORMAT;
    }
  },
  packageType(values) {
    if (!values.packageType) {
      return CREATE_PACKAGE.INVALID_PACKAGE_TYPE_EMPTY;
    }
  },
  materialClass(values) {
    if (!values.materialClass) {
      return CREATE_PACKAGE.INVALID_MATERIAL_CLASS;
    }
  },
  materialTypeDeclared(values) {
    if (!values.materialTypeDeclared) {
      return CREATE_PACKAGE.INVALID_MATERIAL_TYPE_DECLARED_EMPTY;
    }
  },
  denominatedIn(values, { createIncoming }) {
    if (
      !createIncoming &&
      denominationTable[values.materialTypeDeclared] === 'units_weight' &&
      !values.denominatedIn
    ) {
      return CREATE_PACKAGE.INVALID_DENOMINATED_IN_EMPTY;
    }
  },
  unitCount(values) {
    if (values.unitCount && values.unitCount <= 0) {
      return CREATE_PACKAGE.INVALID_UNIT_COUNT_LESS;
    }
    if (values.unitCount && !INTEGER_REGEX.test(values.unitCount)) {
      return CREATE_PACKAGE.INVALID_UNIT_COUNT;
    }
    if (replaceEmpty(values.unitCount, '').length > 10) {
      return CREATE_PACKAGE.INVALID_UNIT_COUNT;
    }
  },
  weightGrossDeclared(values, { auth }) {
    if (!values.weightGrossDeclared && !isWarehouseUser(auth.user.userType)) {
      return CREATE_PACKAGE.INVALID_WEIGHT_GROSS_DECLARED_EMPTY;
    }
    if (values.weightGrossDeclared <= 0) {
      return CREATE_PACKAGE.INVALID_WEIGHT_GROSS_DECLARED_LESS;
    }
    if (
      (values.weightGrossDeclared && !FLOAT_REGEX.test(values.weightGrossDeclared)) ||
      replaceEmpty(values.weightGrossDeclared, '').length > 10
    ) {
      return CREATE_PACKAGE.INVALID_WEIGHT_GROSS_DECLARED;
    }
  },
  weightTareDeclared(values, { auth }) {
    if (!values.weightTareDeclared && !isWarehouseUser(auth.user.userType)) {
      return CREATE_PACKAGE.INVALID_WEIGHT_TARE_DECLARED_EMPTY;
    }
    if (values.weightTareDeclared < 0) {
      return CREATE_PACKAGE.INVALID_WEIGHT_TARE_DECLARED_LESS;
    }
    if (
      (values.weightTareDeclared && !FLOAT_REGEX.test(values.weightTareDeclared)) ||
      replaceEmpty(values.weightTareDeclared, '').length > 10
    ) {
      return CREATE_PACKAGE.INVALID_WEIGHT_TARE_DECLARED;
    }
    if (
      (!values.weightGrossDeclared && values.weightTareDeclared) ||
      safeParseFloat(values.weightGrossDeclared) <= safeParseFloat(values.weightTareDeclared)
    ) {
      return CREATE_PACKAGE.INVALID_WEIGHT_TARE_DECLARED_GREATER;
    }
  },
  weightGrossActual(values, { editMode }) {
    if (!values.weightGrossActual && editMode) {
      return CREATE_PACKAGE.INVALID_WEIGHT_GROSS_ACTUAL_EMPTY;
    }
    if (values.weightGrossActual <= 0) {
      return CREATE_PACKAGE.INVALID_WEIGHT_GROSS_ACTUAL_LESS;
    }
    if (
      (values.weightGrossActual && !FLOAT_REGEX.test(values.weightGrossActual)) ||
      replaceEmpty(values.weightGrossActual, '').length > 10
    ) {
      return CREATE_PACKAGE.INVALID_WEIGHT_GROSS_ACTUAL;
    }
    if (safeParseFloat(values.weightGrossActual) <= safeParseFloat(values.weightTareActual)) {
      return CREATE_PACKAGE.INVALID_WEIGHT_GROS_ACTUAL_GREATER;
    }
  },
  weightTareActual(values, { editMode }) {
    if (!values.weightTareActual && values.weightTareActual !== 0 && editMode) {
      return CREATE_PACKAGE.INVALID_WEIGHT_TARE_ACTUAL_EMPTY;
    }
    if (values.weightTareActual < 0) {
      return CREATE_PACKAGE.INVALID_WEIGHT_TARE_ACTUAL_LESS;
    }
    if (
      (values.weightTareActual && !FLOAT_REGEX.test(values.weightTareActual)) ||
      replaceEmpty(values.weightTareActual, '').length > 10
    ) {
      return CREATE_PACKAGE.INVALID_WEIGHT_TARE_ACTUAL;
    }
    if (
      (!values.weightGrossActual && values.weightTareActual) ||
      safeParseFloat(values.weightGrossActual) <= safeParseFloat(values.weightTareActual)
    ) {
      return CREATE_PACKAGE.INVALID_WEIGHT_TARE_ACTUAL_GREATER;
    }
  },
};

const parseValues = values => ({
  ...values,
  materialTypeDeclared: values.materialTypeDeclared && values.materialTypeDeclared.value,
  denominatedIn: values.denominatedIn && values.denominatedIn.value,
});

const validate = (values, { config, editMode, auth }) => {
  const error = Object.entries(validationRules)
    .filter(([rule]) => configs[config].includes(rule))
    .reduce(
      (errors, [field, validator]) => ({
        ...errors,
        [field]:
          validator(parseValues(values), {
            createIncoming: isGlobalCreatedPackage(config) && !editMode,
            createProcessing: isProcessingPackage(config) && !editMode,
            editMode,
            auth,
          }) || null,
      }),
      {},
    );

  commaValidator(
    {
      weightGrossDeclared: values.weightGrossDeclared,
      weightTareDeclared: values.weightTareDeclared,
      weightGrossActual: values.weightGrossActual,
      weightTareActual: values.weightTareActual,
    },
    error,
  );

  return error;
};

export { validate };
