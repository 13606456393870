import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, getFormSyncErrors, getFormValues, reduxForm } from 'redux-form';

import { FieldConfirmation, FieldDate } from 'shared/components/Fields';
import { FieldCapitalized } from 'shared/components/Fields/FieldCapitalized/FieldCapitalized';
import { FieldInput } from 'shared/components/Fields/FieldInput/FieldInput';
import { FieldRadioButton } from 'shared/components/Fields/FieldRadioButton/FieldRadioButton';
import { FieldSearch } from 'shared/components/Fields/FieldSearch/FieldSearch';
import { FieldSelect } from 'shared/components/Fields/FieldSelect/FieldSelect';
import { FieldUpload } from 'shared/components/Fields/FiledUpload/FieldUpload';
import { FieldUploadList } from 'shared/components/Fields/FiledUpload/FieldUploadList';
import { FieldUploadMultipleFilesList } from 'shared/components/Fields/FiledUpload/FieldUploadMultipleFilesList';
import { Form } from 'shared/components/Form/Form/Form';
import { Section } from 'shared/components/Form/Section/Section';
import { FILES_CONSTANTS } from 'shared/helpers/constants/filesConstants';
import { message } from 'shared/helpers/constants/lots/createLotConstants';
import { lotFields } from 'shared/helpers/constants/lots/createLotFields';
import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';
import {
  allowedStatusesForCreateGlobalLot,
  statusesForGlobal,
} from 'shared/helpers/constants/lots/statusesForGlobal';
import { cancelModal } from 'shared/helpers/constants/modalConstants';
import { availableMaterialTypeOutbound } from 'shared/helpers/constants/packages/materialType';
import { parseMultipleFilesInput } from 'shared/helpers/dataParsers/files/parseMultipleFiles';
import { isGradingCompany } from 'shared/helpers/matchers/checkCompanyType';
import { isGlobalRefiningGroup } from 'shared/helpers/matchers/isGlobalRefiningGroup';
import { checkSentFromCompany } from 'shared/helpers/matchers/lots/checkSentFromCompany';
import { mapArray } from 'shared/helpers/parsers/select';
import { humanize } from 'shared/helpers/parsers/text';
import { asyncValidate } from 'shared/helpers/validations/lot/customLotNumberAsyncValidation';
import { validate } from 'shared/helpers/validations/lot/globalLotValidations';
import { validateMultipleDocumentsLength } from 'shared/helpers/validations/lot/validateMultipleDocuments';

import { goBackOrTo } from 'utils/history';

import { LotsGroupForm } from './components/LotsGroupForm/LotsGroupForm';

const getErrorMessage = key => message[`${key}_DO_NOT_MATCH`];

class GlobalLotForm extends Component {
  state = {
    sentFromCompany: {},
  };

  componentDidMount() {
    const {
      history: {
        location: { state },
      },
      editMode,
      lotDetails,
      sentFromCompany,
    } = this.props;
    this._isMounted = true;

    if (editMode) {
      this.fillData(lotDetails.lot);
    }

    if (state && (state.fromRequestHedgeView || state.fromEnterPlacedHedgeView)) {
      this.reloadCompany(state.relatedCompany);
    }

    if (sentFromCompany) {
      this.setSentFromCompany(sentFromCompany);
      this.reloadCompany(sentFromCompany);
    }

    if (state && state.sentFromCompany) {
      if (state.sentFromCompany.relatedLocations) {
        this.setSentFromCompany(state.sentFromCompany);
      } else {
        this.reloadCompany(state.sentFromCompany);
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  setDisableConfirmation = fieldName => {
    const { formValue, formErrors } = this.props;
    return (!formValue?.[fieldName] && formValue?.[fieldName] !== 0) || formErrors?.[fieldName];
  };

  reloadCompany = async company => {
    const { getCompaniesBySearchStr } = this.props;

    if (company) {
      const companies = await getCompaniesBySearchStr(company.label);
      const [chosenCompany] = companies.options.filter(
        filtered => filtered.value === company.value,
      );

      if (this._isMounted && chosenCompany) {
        this.setSentFromCompany(chosenCompany);
      }
    }
  };

  onFileInputChange = async e => {
    const { lotsAddFile, editMode, lotDetails } = this.props;

    await lotsAddFile(e.target);

    editMode && (await this.setFieldsAfterSWCFiles(lotDetails.lot));
  };

  onMultipleFilesInputChange = async ({ target }) => {
    const { lotsAddMultipleDocuments } = this.props;
    const payload = parseMultipleFilesInput(target);

    await lotsAddMultipleDocuments(payload);
  };

  setLocationsDisabled = sentFromCompany =>
    !Object.keys(sentFromCompany).length ||
    (sentFromCompany.relatedLocations && sentFromCompany.relatedLocations.length === 1) ||
    this.props.editMode;

  setSentFromCompany = company => {
    this.props.change('sentFromCompanyLocation', '');

    if (company) {
      if (isGradingCompany(company.companyType) || isGlobalRefiningGroup(company.label)) {
        this.props.change('processUponDelivery', '');
      }

      if (company.relatedLocations && company.relatedLocations.length === 1) {
        this.props.change('sentFromCompanyLocation', company.relatedLocations[0]);
      }

      if (checkSentFromCompany(company) && !isGlobalRefiningGroup(company.label)) {
        this.props.change('processUponDelivery', 'true');
      }

      if (isGlobalRefiningGroup(company.label)) {
        this.props.change('customLotNumber', '');
      }

      this.props.change('sentFromCompany.value', company.value);
      this.props.change('sentFromCompany.label', company.label);
      this.props.change('sentFromCompany.status', company.status);
      this.props.change('statusForGlobal', '');
      this.props.lotsRemoveFile('countSheet');
      this.props.lotsRemoveFile('gradingReceipt');

      this.setState({
        sentFromCompany: {
          _id: company.value,
          companyName: company.label,
          companyType: company.companyType,
          materialCountRequired: company.materialCountRequired,
          relatedLocations: company.relatedLocations,
        },
      });
    } else {
      this.setState({
        sentFromCompany: {},
      });
    }
  };

  fillData = lot => {
    const fields = {
      deductions: 'deductions',
      credits: 'credits',
    };

    this.props.change('globalMoistureTest', lot.globalMoistureTest);
    this.props.change('sentFromCompany', {
      value: lot.sentFromCompany._id,
      label: lot.sentFromCompany.companyName,
    });
    this.props.change('sentFromCompanyLocation', {
      value: lot.sentFromCompanyLocation._id,
      label: lot.sentFromCompanyLocation.name,
    });

    Object.values(fields).forEach(field => {
      const fieldDescription = `${field}Description`;

      if (lot[field] !== null) {
        this.props.change(field, lot[field]);
      }

      if (lot[fieldDescription]) {
        this.props.change(fieldDescription, lot[fieldDescription]);
      }
    });
  };

  setFieldsAfterSWCFiles = lot => {
    const {
      lotsFiles: { files },
    } = this.props;

    if (files && (files.stillwaterFinalInvoice || files.stillwaterShipmentSummary)) {
      lot.stillwaterShipmentId &&
        this.props.change('stillwaterShipmentId', lot.stillwaterShipmentId);

      lot.materialType &&
        this.props.change('materialType', {
          label: humanize(lot.materialType),
          value: lot.materialType,
        });
    }
  };

  listSentFromCompanies = input => {
    if (!input) {
      return Promise.resolve({ options: [] });
    }

    return this.props.getCompaniesBySearchStr(input).then(companies => ({
      options: companies.options,
    }));
  };

  modalOpen = () => {
    this.props.openModal(cancelModal, () => {
      if (this.props.location.state?.fromPackingListView) {
        this.props.history.push('/shipments/create-incoming-wizard', this.props.location.state);
      } else {
        goBackOrTo('/lots');
      }
    });
  };

  uploadGradingReceiptAccess = (stateCompany, detailsCompany) =>
    isGradingCompany(
      stateCompany.companyType ||
        (detailsCompany && detailsCompany.lot.sentFromCompany.companyType),
    );

  converterCountsAccess = (stateCompany, detailsCompany) =>
    stateCompany.materialCountRequired ||
    (detailsCompany && detailsCompany.lot.sentFromCompany.materialCountRequired);

  uploadPoolSettlementAccess = (stateCompany, detailsCompany) =>
    isGradingCompany(stateCompany?.companyType ?? detailsCompany?.lot.sentFromCompany.companyType);

  removeFile = async type => {
    const {
      lotsFiles: { files },
      resetSection,
    } = this.props;

    this.props.change(type, '');
    await this.props.lotsRemoveFile(type);

    if (!files.stillwaterFinalInvoice && !files.stillwaterFinalInvoice) {
      resetSection('stillwaterShipmentId');
      resetSection('materialType');
    }
  };

  isEditMode = type => {
    const { lotsFiles, editMode } = this.props;

    return !editMode || (lotsFiles.files && lotsFiles.files[type]);
  };

  renderFile = type => {
    const { lotsFiles, editMode, lotDetails } = this.props;

    if (
      !(
        (lotsFiles && lotsFiles.files && lotsFiles.files[type]) ||
        (editMode && lotsFiles && lotDetails.lot[type])
      )
    ) {
      return null;
    }

    const isEditMode = this.isEditMode(type);
    const files = isEditMode
      ? (lotsFiles.files[type] || []).map(file => ({
          file,
          onDelete: isEditMode && (() => this.removeFile(type)),
        }))
      : [
          {
            file: { name: lotDetails.lot[type].originalName, _id: lotDetails.lot[type]._id },
            onDelete: isEditMode && (() => this.removeFile(type)),
          },
        ];
    return <FieldUploadList files={files} />;
  };

  onDelete =
    ({ type, fileIndex }) =>
    () => {
      this.props.change(type);
      this.props.lotsRemoveMultipleFiles({ type, fileIndex });
    };

  render() {
    const {
      handleSubmit,
      lotDetails,
      editMode,
      history: {
        location: { state },
      },
      blockSubmit,
      lotsFiles,
      formValue,
      change,
    } = this.props;
    const { files, multipleFiles } = lotsFiles;

    const { sentFromCompany } = this.state;

    const { VALID_EXTENSIONS } = FILES_CONSTANTS;

    const uploadGradingReceipt = this.uploadGradingReceiptAccess(sentFromCompany, lotDetails);
    const lotFinalized =
      lotDetails && lotDetails.lot.statusForGlobal === statusesForGlobal.finalized;

    const areConverterCountsAvailable =
      this.converterCountsAccess(sentFromCompany, lotDetails) && !lotFinalized;

    if (isGlobalRefiningGroup(sentFromCompany.companyName) && state?.fromShipmentForm) {
      this.props.change('statusForGlobal', statusesForGlobal.not_received);
    }

    return (
      <Form
        onSubmit={handleSubmit}
        onCancel={this.modalOpen}
        submitDisabled={this.props.invalid || blockSubmit}
        header={LOTS.LOT}
      >
        <Section
          template={
            !isGlobalRefiningGroup(sentFromCompany.companyName)
              ? [
                  'sentFromCompany sentFromCompanyLocation',
                  `customLotNumber processUponDelivery`,
                  `${editMode ? 'globalMoistureTest' : 'customerInstructions'} .`,
                ]
              : [
                  'sentFromCompany sentFromCompanyLocation',
                  `${state?.fromShipmentForm ? '' : 'statusForGlobal .'}`,
                  `${editMode ? 'globalMoistureTest' : 'customerInstructions'} processUponDelivery`,
                  `customLotNumber .`,
                ]
          }
        >
          <Field
            name="sentFromCompany"
            component={FieldSearch}
            label={LOTS.SENT_FROM_COMPANY}
            field="required"
            getOptions={this.listSentFromCompanies}
            onChange={this.setSentFromCompany}
            disabled={
              editMode ||
              (state &&
                (state.fromRequestHedgeView ||
                  state.fromEnterPlacedHedgeView ||
                  this.props.sentFromCompany ||
                  state.sentFromCompany))
            }
          />
          <Field
            name="sentFromCompanyLocation"
            component={FieldSelect}
            options={sentFromCompany.relatedLocations}
            disabled={this.setLocationsDisabled(sentFromCompany)}
            searchable={false}
            label={LOTS.SENT_FROM_COMPANY_LOCATION}
            field="required"
          />
          {!editMode && !isGlobalRefiningGroup(sentFromCompany.companyName) && (
            <FieldCapitalized
              name="customLotNumber"
              type="text"
              component={FieldInput}
              label={LOTS.CUSTOM_LOT_NUMBER}
              icon="icon-referral"
            />
          )}
          {checkSentFromCompany(sentFromCompany) && (
            <Field
              name="processUponDelivery"
              component={FieldRadioButton}
              label={LOTS.PROCESS_UPON_DELIVERY}
              field="required"
              disabled={lotFinalized}
            />
          )}
          {editMode && !lotFinalized && (
            <Field
              name="globalMoistureTest"
              component={FieldInput}
              type="text"
              label={LOTS.GLOBAL_MOISTURE_TEST}
              icon="icon-scale"
              suffix="%"
            />
          )}
          {!editMode && (
            <Field
              name="customerInstructions"
              type="text"
              component={FieldInput}
              label={LOTS.CUSTOMER_INSTRUCTIONS}
              icon="icon-referral"
            />
          )}
          {!editMode &&
            isGlobalRefiningGroup(sentFromCompany.companyName) &&
            !state?.fromShipmentForm && (
              <Field
                name="statusForGlobal"
                label={LOTS.STATUS_FOR_GLOBAL}
                component={FieldSelect}
                options={mapArray(allowedStatusesForCreateGlobalLot)}
                field="required"
              />
            )}
        </Section>
        <Section
          header={LOTS.FILES}
          template={['billOfLading poolSettlementDocument', 'gradingReceipt .']}
        >
          {uploadGradingReceipt && !(lotFinalized && editMode) && (
            <div name="gradingReceipt">
              <Field
                name="gradingReceipt"
                component={FieldUpload}
                label={LOTS.UPLOAD_GRADING_RECEIPT}
                validExtensions=".pdf"
                onChange={this.onFileInputChange}
              />
              {this.renderFile('gradingReceipt')}
            </div>
          )}
          {editMode && (
            <div name="billOfLading">
              <Field
                multiple
                name="billOfLading"
                component={FieldUpload}
                label={LOTS.UPLOAD_BILL_OF_LADING}
                validExtensions={[
                  VALID_EXTENSIONS.jpg,
                  VALID_EXTENSIONS.jpeg,
                  VALID_EXTENSIONS.pdf,
                  VALID_EXTENSIONS.png,
                ].join(', ')}
                onChange={this.onMultipleFilesInputChange}
              />
              <FieldUploadMultipleFilesList
                files={lotsFiles}
                type="billOfLading"
                validExtensions={[
                  VALID_EXTENSIONS.jpg,
                  VALID_EXTENSIONS.jpeg,
                  VALID_EXTENSIONS.pdf,
                  VALID_EXTENSIONS.png,
                ]}
                onDelete={this.onDelete}
                checkMultipleFilesError={this.props.checkMultipleFilesError}
                validationError={validateMultipleDocumentsLength(
                  lotsFiles.multipleFiles.billOfLading,
                  LOTS.BILL_OF_LADING_LIMIT,
                )}
                limit={LOTS.BILL_OF_LADING_LIMIT}
              />
            </div>
          )}
          {editMode && (
            <div name="poolSettlementDocument">
              <Field
                multiple
                name="poolSettlementDocument"
                component={FieldUpload}
                label={LOTS.UPLOAD_POOL_SETTLEMENT_DOCUMENT}
                validExtensions={[VALID_EXTENSIONS.pdf].join(', ')}
                onChange={this.onMultipleFilesInputChange}
                meta={{
                  error: validateMultipleDocumentsLength(multipleFiles.poolSettlementDocument),
                  dirty: true,
                }}
              />
              <FieldUploadMultipleFilesList
                files={lotsFiles}
                type="poolSettlementDocument"
                validExtensions={[VALID_EXTENSIONS.pdf]}
                onDelete={this.onDelete}
                checkMultipleFilesError={this.props.checkMultipleFilesError}
                allowDeleteExistingFiles
              />
            </div>
          )}
        </Section>
        <Section
          header={LOTS.SWC_FILES}
          template={
            !lotFinalized
              ? ['stillwaterShipmentSummary stillwaterFinalInvoice']
              : ['stillwaterFinalInvoice stillwaterShipmentSummary']
          }
          unwrap
        >
          {editMode && (
            <>
              <div name="stillwaterShipmentSummary">
                {!lotFinalized && (
                  <>
                    <Field
                      name="stillwaterShipmentSummary"
                      component={FieldUpload}
                      label={LOTS.UPLOAD_STILLWATER_SHIPMENT_SUMMARY}
                      validExtensions=".pdf"
                      onChange={this.onFileInputChange}
                    />
                    {this.renderFile('stillwaterShipmentSummary')}
                  </>
                )}
              </div>
              <div name="stillwaterFinalInvoice">
                <Field
                  name="stillwaterFinalInvoice"
                  component={FieldUpload}
                  label={LOTS.UPLOAD_STILLWATER_FINAL_INVOICE}
                  validExtensions=".pdf"
                  onChange={this.onFileInputChange}
                />
                {this.renderFile('stillwaterFinalInvoice')}
              </div>
            </>
          )}
        </Section>
        {editMode && (
          <>
            {!lotFinalized && (
              <Section
                template={['stillwaterInitialPayment stillwaterInitialPayment']}
                topBorder
                noPadding
                unwrap
              >
                <div name="stillwaterInitialPayment">
                  <FieldConfirmation
                    name="stillwaterInitialPayment"
                    type="text"
                    label={LOTS.STILLWATER_INITIAL_PAYMENT}
                    errorMessage={message.INVALID_STILLWATER_INITIAL_PAYMENT_CONFIRM}
                    disableConfirmation={this.setDisableConfirmation('stillwaterInitialPayment')}
                    change={change}
                    prefix="$"
                    icon="icon-dollar"
                  />
                </div>
              </Section>
            )}
            <Section
              template={[
                `${
                  lotFinalized
                    ? 'stillwaterInitialPayment stillwaterInitialPaymentDate'
                    : 'stillwaterInitialPaymentDate .'
                }`,
              ]}
              topBorder
              unwrap
            >
              {lotFinalized && (
                <Field
                  name="stillwaterInitialPayment"
                  type="text"
                  component={FieldInput}
                  disabled
                  label={LOTS.STILLWATER_INITIAL_PAYMENT}
                />
              )}
              <div name="stillwaterInitialPaymentDate">
                <Field
                  name="stillwaterInitialPaymentDate"
                  component={FieldDate}
                  label={LOTS.STILLWATER_INITIAL_PAYMENT_DATE}
                  disabled={lotFinalized}
                  field={
                    String(formValue?.stillwaterInitialPayment) &&
                    formValue?.stillwaterInitialPayment !== null &&
                    'required'
                  }
                  maxDate={
                    formValue?.stillwaterFinalPaymentDate &&
                    new Date(formValue.stillwaterFinalPaymentDate)
                  }
                />
              </div>
            </Section>
            <Section
              template={['stillwaterFinalPayment stillwaterFinalPayment']}
              topBorder
              noPadding
              unwrap
            >
              <div name="stillwaterFinalPayment">
                <FieldConfirmation
                  name="stillwaterFinalPayment"
                  type="text"
                  errorMessage={message.INVALID_STILLWATER_FINAL_PAYMENT_CONFIRM}
                  label={LOTS.STILLWATER_FINAL_PAYMENT}
                  disableConfirmation={this.setDisableConfirmation('stillwaterFinalPayment')}
                  change={change}
                  prefix="$"
                  icon={'icon-dollar'}
                />
              </div>
            </Section>
            <Section template={['stillwaterFinalPaymentDate .']} topBorder unwrap>
              <Field
                name="stillwaterFinalPaymentDate"
                component={FieldDate}
                label={LOTS.STILLWATER_FINAL_PAYMENT_DATE}
                field={
                  String(formValue?.stillwaterFinalPayment) &&
                  formValue?.stillwaterFinalPayment !== null &&
                  'required'
                }
                minDate={formValue?.stillwaterInitialPaymentDate}
              />
            </Section>
          </>
        )}
        <Section
          header={LOTS.EDIT_SHIPMENT_INFO}
          template={['stillwaterShipmentId materialType']}
          unwrap
        >
          {editMode &&
            !lotFinalized &&
            files &&
            (files.stillwaterFinalInvoice || files.stillwaterShipmentSummary) && (
              <>
                {!lotDetails.lot.stillwaterShipmentId && (
                  <FieldCapitalized
                    name="stillwaterShipmentId"
                    component={FieldInput}
                    label={LOTS.STILLWATER_SHIPMENT_ID}
                    field="required"
                  />
                )}
                {!lotDetails.lot.materialType && (
                  <Field
                    name="materialType"
                    label={LOTS.MATERIAL_TYPE}
                    component={FieldSelect}
                    options={mapArray(availableMaterialTypeOutbound.all)}
                    field="required"
                  />
                )}
              </>
            )}
        </Section>
        {areConverterCountsAvailable && (
          <Section
            header={LOTS.CONVERTER_COUNTS}
            template={[
              'countSheet .',
              'catMono catMono',
              'catFoil catFoil',
              'catEmpty catEmpty',
              'catAft catAft',
              'catBead catBead',
              'catGrgQtyTotal catGrgQtyTotal',
            ]}
          >
            <div name="countSheet">
              <Field
                name="countSheet"
                component={FieldUpload}
                label={LOTS.UPLOAD_COUNT_SHEET}
                validExtensions=".pdf, .xls, .xlsx"
                onChange={this.onFileInputChange}
              />
              {this.renderFile('countSheet')}
            </div>
            <FieldConfirmation
              name="catMono"
              type="number"
              label={LOTS.MONO.label}
              field="optional"
              suffix="units"
              change={change}
              maxLength="6"
              description={LOTS.MONO.description}
              errorMessage={getErrorMessage('MONO')}
              disableConfirmation={this.setDisableConfirmation('catMono')}
              withoutGridGap
            />
            <FieldConfirmation
              name="catFoil"
              type="number"
              label={LOTS.FOIL.label}
              field="optional"
              suffix="units"
              change={change}
              maxLength="6"
              description={LOTS.FOIL.description}
              errorMessage={getErrorMessage('FOIL')}
              disableConfirmation={this.setDisableConfirmation('catFoil')}
              withoutGridGap
            />
            <FieldConfirmation
              name="catEmpty"
              type="number"
              label={LOTS.EMPTY.label}
              field="optional"
              suffix="units"
              change={change}
              maxLength="6"
              description={LOTS.EMPTY.description}
              errorMessage={getErrorMessage('EMPTY')}
              disableConfirmation={this.setDisableConfirmation('catEmpty')}
              withoutGridGap
            />
            <FieldConfirmation
              name="catAft"
              type="number"
              label={LOTS.AFT.label}
              field="optional"
              suffix="units"
              change={change}
              maxLength="6"
              description={LOTS.AFT.description}
              errorMessage={getErrorMessage('AFT')}
              disableConfirmation={this.setDisableConfirmation('catAft')}
              withoutGridGap
              keepOriginalLabel
            />
            <FieldConfirmation
              name="catBead"
              type="number"
              label={LOTS.BEAD.label}
              field="optional"
              suffix="units"
              change={change}
              maxLength="6"
              description={LOTS.BEAD.description}
              errorMessage={getErrorMessage('GRG_QTY_TOTAL')}
              disableConfirmation={this.setDisableConfirmation('catBead')}
              withoutGridGap
              keepOriginalLabel
            />
            <FieldConfirmation
              name="catGrgQtyTotal"
              type="number"
              label={LOTS.GRG_QTY_TOTAL.label}
              field="optional"
              suffix="units"
              change={change}
              maxLength="6"
              description={LOTS.GRG_QTY_TOTAL.description}
              errorMessage={getErrorMessage('GRG_QTY_TOTAL')}
              disableConfirmation={this.setDisableConfirmation('catGrgQtyTotal')}
              withoutGridGap
              keepOriginalLabel
            />
          </Section>
        )}
        {editMode &&
          !lotFinalized &&
          lotFields.map(section => (
            <LotsGroupForm
              key={section.name}
              namePrefix={section.name}
              title={section.title}
              sectionTitle={section.sectionTitle}
              buttonLabel={section.buttonLabel}
              commonComponent={section.commonComponent}
            />
          ))}
      </Form>
    );
  }
}

const formName = 'LotForm';

function mapStateToProps({ createShipment, lotsFiles, lotDetails: { lot }, ...restState }) {
  const syncError = getFormSyncErrors(formName);

  return {
    formValue: getFormValues(formName)(restState),
    formErrors: syncError(restState),
    sentFromCompany: createShipment?.temporaryData?.sentFromCompany,
    lotsFiles,
    initialValues: {
      catMono: lot?.relatedReport?.catMono,
      catMonoConfirm: lot?.relatedReport?.catMono,
      catFoil: lot?.relatedReport?.catFoil,
      catFoilConfirm: lot?.relatedReport?.catFoil,
      catEmpty: lot?.relatedReport?.catEmpty,
      catEmptyConfirm: lot?.relatedReport?.catEmpty,
      catAft: lot?.relatedReport?.catAft,
      catAftConfirm: lot?.relatedReport?.catAft,
      catBead: lot?.relatedReport?.catBead,
      catBeadConfirm: lot?.relatedReport?.catBead,
      catGrgQtyTotal: lot?.relatedReport?.catGrgQtyTotal,
      catGrgQtyTotalConfirm: lot?.relatedReport?.catGrgQtyTotal,
      stillwaterInitialPayment: lot?.stillwaterInitialPayment,
      stillwaterInitialPaymentConfirm: lot?.stillwaterInitialPayment,
      stillwaterInitialPaymentDate: lot?.stillwaterInitialPaymentDate,
      stillwaterFinalPayment: lot?.stillwaterFinalPayment,
      stillwaterFinalPaymentConfirm: lot?.stillwaterFinalPayment,
      stillwaterFinalPaymentDate: lot?.stillwaterFinalPaymentDate,
      truckingDeduction: lot?.truckingDeduction,
    },
  };
}

const LotForm = reduxForm({
  form: formName,
  validate,
  asyncValidate,
  asyncBlurFields: ['customLotNumber'],
})(GlobalLotForm);

export default connect(mapStateToProps)(LotForm);
