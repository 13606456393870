import {
  isAssayCompany,
  isGradingCompany,
  isInternalCompany,
} from 'shared/helpers/matchers/checkCompanyType';
import { isOwnerUser, isSupportUser } from 'shared/helpers/matchers/checkUserType';
import { isGlobalRefiningGroup } from 'shared/helpers/matchers/isGlobalRefiningGroup';

const createUserFromLocationAccess = (userListAccess, userType, companyType, companyName) =>
  userListAccess &&
  ((isSupportUser(userType) &&
    (isAssayCompany(companyType) ||
      isGradingCompany(companyType) ||
      (!isGlobalRefiningGroup(companyName) && isInternalCompany(companyType)))) ||
    isOwnerUser(userType));

export { createUserFromLocationAccess };
