import { message } from 'shared/helpers/constants/users/createUserConstants';
import {
  EMAIL_REGEX,
  PHONE_EXTENSION_REGEX,
  PHONE_REGEX,
  MOBILE_PHONE_REGEX,
} from 'shared/helpers/validations/regularExpressions';

import { validate as notesValidate } from '../notes/notesValidations';

function validate(values, props) {
  const errors = {};
  if (!values.email || !values.email.trim()) {
    errors.email = message.INVALID_EMAIL_EMPTY;
  } else if (!EMAIL_REGEX.test(values.email)) {
    errors.email = message.INVALID_EMAIL;
  } else if (values.email.length > 100) {
    errors.email = message.INVALID_EMAIL;
  }

  if (!values.password || !values.password.trim()) {
    errors.password = message.INVALID_PASSWORD_EMPTY;
  } else if (values.password.length > 100) {
    errors.password = message.INVALID_PASSWORD;
  }

  const userTypeValue = values.userType && values.userType.value;
  if (!userTypeValue && !props.editMode) {
    errors.userType = message.INVALID_USER_TYPE_EMPTY;
  }

  if (!values.accessLevel) {
    errors.accessLevel = message.INVALID_ACCESS_LEVEL_EMPTY;
  }

  if (!values.firstName || !values.firstName.trim()) {
    errors.firstName = message.INVALID_FIRST_NAME_EMPTY;
  } else if (values.firstName.length > 100) {
    errors.firstName = message.INVALID_FIRST_NAME;
  }

  if (!values.lastName || !values.lastName.trim()) {
    errors.lastName = message.INVALID_LAST_NAME_EMPTY;
  } else if (values.lastName.length > 100) {
    errors.lastName = message.INVALID_LAST_NAME;
  }

  if (!values.title) {
    errors.title = '';
  } else if (values.title.length > 50) {
    errors.title = message.INVALID_TITLE;
  }

  if (!values.officePhone) {
    errors.officePhone = '';
  } else if (!PHONE_REGEX.test(values.officePhone)) {
    errors.officePhone = message.INVALID_OFFICE_PHONE;
  } else if (/([^\d])\1/.test(values.officePhone)) {
    errors.officePhone = message.INVALID_OFFICE_PHONE;
  } else if (values.officePhone.length > 14) {
    errors.officePhone = message.INVALID_OFFICE_PHONE;
  }

  if (!values.phoneExtension) {
    errors.phoneExtension = '';
  } else if (!PHONE_EXTENSION_REGEX.test(values.phoneExtension)) {
    errors.phoneExtension = message.INVALID_PHONE_EXTENSION;
  } else if (values.phoneExtension.length > 10) {
    errors.phoneExtension = message.INVALID_PHONE_EXTENSION;
  }

  if (!values.mobilePhone || !values.mobilePhone.trim()) {
    errors.mobilePhone = message.INVALID_MOBILE_PHONE_EMPTY;
  } else if (!MOBILE_PHONE_REGEX.test(values.mobilePhone)) {
    errors.mobilePhone = message.INVALID_MOBILE_PHONE;
  } else if (values.mobilePhone.length > 16) {
    errors.mobilePhone = message.INVALID_MOBILE_PHONE;
  }

  if (!values.relatedCompany) {
    errors.relatedCompany = message.INVALID_RELATED_COMPANY_EMPTY;
  }

  if (!(values.location && values.location.length)) {
    errors.location = message.INVALID_LOCATION_REQUIRED_EMPTY;
  }

  if (
    values.purchasingAppAccess === null ||
    values.purchasingAppAccess === undefined ||
    values.purchasingAppAccess === ''
  ) {
    errors.purchasingAppAccess = message.INVALID_PURCHASING_APP_ACCESS_EMPTY;
  }

  if (
    values.databaseAccess === null ||
    values.databaseAccess === undefined ||
    values.databaseAccess === ''
  ) {
    errors.databaseAccess = message.INVALID_DATABASE_ACCESS_EMPTY;
  }

  return {
    ...errors,
    ...notesValidate(values),
  };
}

export { validate };
