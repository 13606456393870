import { connect } from 'react-redux';

import {
  termsDocumentClearAction,
  termsDocumentAddedAction,
  uploadTermsDocument,
} from 'actions/Terms/uploadTermsDocument';
import { showSnackbar } from 'actions/shared/snackbar';

import { TermsUploadSigned } from './TermsUploadSigned';

const mapStateToProps = state => ({
  termsDetails: state.termsDetails,
  termsUploadDocument: state.termsUploadDocument,
});

const mapActionsToProps = {
  uploadTermsDocument,
  termsDocumentAddedAction,
  termsDocumentClearAction,
  showSnackbar,
};

const connectedComponent = connect(mapStateToProps, mapActionsToProps)(TermsUploadSigned);
export { connectedComponent as ConnectedTermsUploadSigned };
