import React from 'react';
import { useSelector } from 'react-redux';
import { FieldArray, FormSection, reduxForm, formValueSelector } from 'redux-form';

import { Form } from 'shared/components/Form';
import { PROFIT_LOSS } from 'shared/helpers/constants/lots/profitLossConstants';
import { validate } from 'shared/helpers/validations/lot/lotAdjustmentsValidation';
import { multiValidator } from 'shared/helpers/validations/multiValidator';

import { LotAdjustments } from './LotAdjustments/LotAdjustments';

const LotAdjustmentsFormLayout = ({ handleSubmit, invalid, form }) => {
  const adjustments = useSelector(state =>
    formValueSelector(form)(state, 'lotAdjustments.adjustments'),
  );

  return (
    <Form header={PROFIT_LOSS.LOT_ADJUSTMENTS} onSubmit={handleSubmit} submitDisabled={invalid}>
      <FormSection name="lotAdjustments">
        <FieldArray name="adjustments" component={LotAdjustments} charges={adjustments} />
      </FormSection>
    </Form>
  );
};

const LotAdjustmentsForm = reduxForm({
  validate: multiValidator('lotAdjustments', validate),
})(LotAdjustmentsFormLayout);

export { LotAdjustmentsForm, LotAdjustmentsFormLayout };
