import styled from 'styled-components';

import { cssVar } from 'shared/helpers/styling/styling';

export const Alert = styled.div`
  background: ${cssVar('GRGlightYellow')};
  color: ${cssVar('outerSpace')};
  padding: 1rem 2rem;
  margin-bottom: 1rem;
`;
