import Cookies from 'js-cookie';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { ErrorCaught } from 'components/ErrorPage/ErrorPage';

import { history } from 'utils/history';

const { REACT_APP_API: API_URL } = process.env;

class ErrorBoundaryBase extends Component {
  prevErrorMessage = null;

  state = {
    hasError: false,
  };

  static getDerivedStateFromError() {
    return {
      hasError: true,
    };
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.userId !== nextProps.userId) {
      return true;
    }
    return this.props.actionsLog.actions === nextProps.actionsLog.actions;
  }

  onGoHomeClick = () => {
    this.setState({ hasError: false });
    this.prevErrorMessage = null;
    history.push('/');
  };

  componentDidCatch(error, info) {
    if (error.message === this.prevErrorMessage) return;

    this.sendLog(error, info.componentStack).then(this.recoverFromError, this.recoverFromError);

    this.prevErrorMessage = error.message;
  }

  recoverFromError() {
    Cookies.set('grgUserSession', 'invalidUser');
    window.localStorage.clear();
    window.sessionStorage.clear();
  }

  sendLog({ name, message, stack }, componentStack) {
    const log = {
      error: {
        name,
        message,
        stack,
        componentStack,
      },
      localTime: Date(),
      url: window.location.href,
      actions: this.props.actionsLog.actions,
    };

    return fetch(`${API_URL}/logs`, {
      method: 'POST',
      body: JSON.stringify(log),
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  render() {
    return this.state.hasError ? <ErrorCaught onClick={this.onGoHomeClick} /> : this.props.children;
  }
}

const mapStateToProps = state => ({
  userId: state.auth && state.auth.user && state.auth.user.id,
  actionsLog: state.actionsLog,
});

const ErrorBoundary = connect(mapStateToProps)(ErrorBoundaryBase);

export { ErrorBoundary, ErrorBoundaryBase };
