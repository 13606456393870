import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import styled from 'styled-components';

import { SubmitButton } from 'shared/components/Buttons/SubmitButton/SubmitButton';
import { FieldSelect } from 'shared/components/Fields/FieldSelect/FieldSelect';
import { SHARED } from 'shared/helpers/constants/sharedConstants';
import { SYSTEM_SETTINGS } from 'shared/helpers/constants/systemSettings/constants';
import {
  type,
  buyerIndicatorTypes,
} from 'shared/helpers/constants/systemSettings/marketIndicators/requestMarketIndicators';
import { isBuyerUser } from 'shared/helpers/matchers/checkUserType';
import { mapArray } from 'shared/helpers/parsers/select';
import { validate } from 'shared/helpers/validations/systemSettings/marketIndicators/requestMarketIndicators';

const Header = styled.h1`
  text-align: center;
`;

const ActionsContainer = styled.div`
  margin-top: 50px;
  display: flex;
  justify-content: flex-end;
`;

function RequestMarketIndicatorsFormBase({ handleSubmit, invalid }) {
  const { userType } = useSelector(({ auth }) => auth.user);
  const options = useMemo(
    () =>
      isBuyerUser(userType)
        ? mapArray(Object.values(buyerIndicatorTypes))
        : mapArray(Object.values(type)),
    [],
  );

  return (
    <form onSubmit={handleSubmit}>
      <Header>{SYSTEM_SETTINGS.REQUEST_MARKET_INDICATORS}</Header>
      <Field
        name="type"
        label={SYSTEM_SETTINGS.INDICATORS_TYPE}
        component={FieldSelect}
        options={options}
        field="required"
        multi
        pretendMobile
      />
      <ActionsContainer>
        <SubmitButton label={SHARED.SUBMIT} disabled={invalid} />
      </ActionsContainer>
    </form>
  );
}

const RequestMarketIndicatorsForm = reduxForm({
  form: 'RequestMarketIndicators',
  validate,
})(RequestMarketIndicatorsFormBase);

export { RequestMarketIndicatorsFormBase, RequestMarketIndicatorsForm };
