const PASSWORD_VALIDATION = {
  IS_INVALID: 'Password does not meet requirements.',
  CANNON_BE_EMPTY: 'Password cannot be empty.',
  DO_NOT_MATCH: 'Passwords do not match.',
  TOO_SHORT: 'Password must be at least 8 characters long.',
  TOO_LONG: 'Password cannot be longer that 15 characters.',
  MUST_CONTAIN_UPPER_CASE: 'Password must contain at least 1 upper case letter.',
  MUST_CONTAIN_NUMBER: 'Password must contain at least 1 number.',
  MUST_CONTAIN_SPECIAL_CHARACTER: 'Password must contain at least 1 special character.',
};

export { PASSWORD_VALIDATION };
