import styled from 'styled-components';

import { FullPageList } from 'shared/components/FullPageList/FullPageList';

export const StyledUsersList = styled(FullPageList)`
  .UsersList {
    &__item--no-break {
      white-space: nowrap;
    }
  }
`;
