const finalInvoiceCalculation = ({
  maximumPlatinumAccount,
  maximumPalladiumAccount,
  maximumRhodiumAccount,
  platinumOuncesRemoved,
  palladiumOuncesRemoved,
  rhodiumOuncesRemoved,
}) => ({
  finalPlatinumPriceCalculation: maximumPlatinumAccount - platinumOuncesRemoved,
  finalPalladiumPriceCalculation: maximumPalladiumAccount - palladiumOuncesRemoved,
  finalRhodiumPriceCalculation: maximumRhodiumAccount - rhodiumOuncesRemoved,
});

export { finalInvoiceCalculation };
