import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { Authorization } from 'components/Authorization/Authorization';
import { NotFound } from 'components/ErrorPage/ErrorPage';

import { BankHolidays } from './components/BankHolidays/BankHolidays';
import { BankHolidaysCreate } from './components/BankHolidays/components/BankHolidaysCreate/BankHolidaysCreate';
import { EnterPayment } from './components/EnterPayment/EnterPayment';
import { EnterPoolPayment } from './components/EnterPoolPayment/EnterPoolPayment';
import { ConnectedPaymentExceptionCompanies } from './components/ExceptionCompanies/ConnectedExceptionCompanies';
import { ConnectedPaymentExceptionCompaniesCreate } from './components/ExceptionCompanies/components/ExceptionCompaniesCreate/ConnectecExceptionCompaniesCreate';
import { ConnectedPaymentDetails } from './components/PaymentDetails/ConnectedPaymentDetails';
import { ConnectedPaymentsList } from './components/PaymentsList/ConnectedPaymentsList';
import { PayoutRules } from './components/PayoutRules/PayoutRules';
import { PayoutRulesCreate } from './components/PayoutRules/components/PayoutRulesCreate/PayoutRulesCreate';
import { RequestAdvancePayment } from './components/RequestPayment/RequestAdvancePayment/RequestAdvancePayment';
import { RequestFinalPayment } from './components/RequestPayment/RequestFinalPayment/RequestFinalPayment';
import { UpdateDraftFinalPayment } from './components/UpdateDraftFinalPayment/UpdateDraftFinalPayment';
import { UpdateDraftPoolPayment } from './components/UpdateDraftPoolPayment/UpdateDraftPoolPayment';

function Payments(props) {
  const {
    access: { payments },
  } = props.auth;

  const AuthPaymentsOverview = Authorization(ConnectedPaymentsList, payments.overview);
  const AuthEnterPayment = Authorization(EnterPayment, payments.enter);
  const AuthEnterPoolPayment = Authorization(EnterPoolPayment, payments.enter);
  const AuthRequestFinalPayment = Authorization(RequestFinalPayment, payments.request);
  const AuthRequestAdvancePayment = Authorization(RequestAdvancePayment, payments.request);
  const AuthBankHolidays = Authorization(BankHolidays, payments.bankHolidays.list);
  const AuthBankHolidaysCreate = Authorization(BankHolidaysCreate, payments.bankHolidays.manage);
  const AuthPaymentDetails = Authorization(ConnectedPaymentDetails, payments.details);
  const AuthPaymentExceptionCompanies = Authorization(
    ConnectedPaymentExceptionCompanies,
    payments.paymentExceptionCompanies.list,
  );
  const AuthPaymentExceptionCompaniesCreate = Authorization(
    ConnectedPaymentExceptionCompaniesCreate,
    payments.paymentExceptionCompanies.manage,
  );
  const AuthPayoutRules = Authorization(PayoutRules, payments.payoutRules.list);
  const AuthPayoutRulesCreate = Authorization(PayoutRulesCreate, payments.payoutRules.manage);
  const AuthUpdateDraftFinalPayment = Authorization(UpdateDraftFinalPayment, payments.update);
  const AuthUpdateDraftPoolPayment = Authorization(UpdateDraftPoolPayment, payments.update);

  return (
    <Switch>
      <Route
        exact
        path="/payments/payment-exception-companies"
        component={AuthPaymentExceptionCompanies}
      />
      <Route
        exact
        path="/payments/payment-exception-companies/create"
        component={AuthPaymentExceptionCompaniesCreate}
      />
      <Route exact path="/payments/payout-rules" component={AuthPayoutRules} />
      <Route exact path="/payments/bank-holidays" component={AuthBankHolidays} />
      <Route exact path="/payments/bank-holidays/create" component={AuthBankHolidaysCreate} />
      <Route exact path="/payments/payout-rules/create" component={AuthPayoutRulesCreate} />
      <Route exact path="/payments" component={AuthPaymentsOverview} />
      <Route exact path="/payments/outstanding" component={AuthPaymentsOverview} />
      <Route exact path="/payments/enter" component={AuthEnterPayment} />
      <Route exact path="/payments/enter-pool" component={AuthEnterPoolPayment} />
      <Route exact path="/payments/:id" component={AuthPaymentDetails} />
      <Route exact path="/payments/request/final" component={AuthRequestFinalPayment} />
      <Route exact path="/payments/request/advance" component={AuthRequestAdvancePayment} />
      <Route
        exact
        path="/payments/:id/update-draft-final"
        component={AuthUpdateDraftFinalPayment}
      />
      <Route exact path="/payments/:id/update-draft-pool" component={AuthUpdateDraftPoolPayment} />
      <Route component={NotFound} />
    </Switch>
  );
}

export { Payments };
