import React, { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Field, formValueSelector } from 'redux-form';

import { IconButton } from 'shared/components/Buttons/IconButton/IconButton';
import { FieldInput } from 'shared/components/Fields/FieldInput/FieldInput';
import { FieldRadioButton } from 'shared/components/Fields/FieldRadioButton/FieldRadioButton';
import { Section } from 'shared/components/Form';
import { formName, wizardContext } from 'shared/components/Wizard/Wizard';
import { USER } from 'shared/helpers/constants/users/userConstants';
import { isCustomerUser } from 'shared/helpers/matchers/checkUserType';
import { normalize } from 'shared/helpers/parsers/boolean';
import { cssVar } from 'shared/helpers/styling/styling';

import { SelectUserRole } from 'pages/Users/components/UsersManagement/components/UserForm/components/SelectUserRole/SelectUserRole';

function AddUser(props) {
  const { user, onRemove, change } = props;
  const selector = formValueSelector(formName);
  const userValues = useSelector(state => selector(state, 'users')[props.index]);
  const wizard = useContext(wizardContext);
  const company = wizard.data.company;
  const userType = userValues.userType && userValues.userType.value;
  const parsedCompany = useMemo(
    () => ({
      company: {
        ...company,
        companyType: company.companyType.value,
      },
    }),
    [company],
  );

  const actions = onRemove && (
    <IconButton onClick={onRemove} icon="icon-cancel" color={cssVar('whiteGRG')} />
  );

  const renderCustomerUserOptions = () =>
    isCustomerUser(userType) && (
      <>
        <Field
          name={`${user}.databaseAccess`}
          component={FieldRadioButton}
          label={USER.DATABASE_ACCESS}
          field="required"
          normalize={normalize}
        />
        <Field
          name={`${user}.purchasingAppAccess`}
          component={FieldRadioButton}
          label={USER.PURCHASING_APP_ACCESS}
          field="required"
          normalize={normalize}
        />
      </>
    );

  return (
    <>
      <Section
        header={USER.USER_INFO}
        template={[`${user}.firstName ${user}.lastName`, `${user}.email ${user}.title`]}
        actions={actions}
      >
        <Field
          name={`${user}.firstName`}
          component={FieldInput}
          label={USER.FIRST_NAME}
          field="required"
        />
        <Field
          name={`${user}.lastName`}
          component={FieldInput}
          label={USER.LAST_NAME}
          field="required"
        />
        <Field name={`${user}.email`} component={FieldInput} label={USER.EMAIL} field="required" />
        <Field name={`${user}.title`} component={FieldInput} label={USER.TITLE} />
      </Section>
      <SelectUserRole
        companyDetails={parsedCompany}
        userType={userType}
        change={change}
        formName={user}
      >
        {renderCustomerUserOptions()}
      </SelectUserRole>
      <Section
        header={USER.CONTACT}
        template={[`${user}.officePhone ${user}.mobilePhone`, `${user}.phoneExtension .`]}
      >
        <Field name={`${user}.officePhone`} component={FieldInput} label={USER.OFFICE_PHONE} />
        <Field
          name={`${user}.mobilePhone`}
          component={FieldInput}
          label={USER.MOBILE_PHONE}
          field="required"
        />
        <Field
          name={`${user}.phoneExtension`}
          component={FieldInput}
          label={USER.PHONE_EXTENSION}
        />
      </Section>
    </>
  );
}

export { AddUser };
