import React from 'react';

import { LinkButton } from 'shared/components/Buttons';
import { DetailsDateField } from 'shared/components/Details/DetailsDateField';
import { DetailsField } from 'shared/components/Details/DetailsField';
import { DetailsHumanizedField } from 'shared/components/Details/DetailsHumanizedField';
import { SHIPMENTS } from 'shared/helpers/constants/shipments/shipmentsConstants';
import { isOwnerOrSupport } from 'shared/helpers/matchers/checkUserType';
import { isGlobalUserType } from 'shared/helpers/matchers/isGlobalUserType';
import { isOutgoingShipment } from 'shared/helpers/matchers/shipments/checkShipmentDirection';

import { history } from 'utils/history';

const shipmentDetailsFieldsConfig = (auth, shipment) => [
  {
    ...(isOwnerOrSupport(auth.user.userType) && {
      customContent: shipment.sentFromCompany && (
        <LinkButton
          label={shipment.sentFromCompany.companyName}
          onClick={() => history.push(`/companies/${shipment.sentFromCompany._id}`)}
        />
      ),
    }),
    label: SHIPMENTS.SENT_FROM_COMPANY,
    value: shipment.sentFromCompany.companyName,
    icon: 'icon-home',
    id: 'fromCompany',
    FieldType: DetailsField,
  },
  {
    label: SHIPMENTS.SENT_FROM_COMPANY_LOCATION,
    value: shipment.sentFromCompanyLocation.name,
    icon: 'icon-home',
    id: 'fromLocation',
    FieldType: DetailsField,
  },
  {
    label: isOutgoingShipment(shipment.shipmentDirection)
      ? SHIPMENTS.SHIP_DATE
      : SHIPMENTS.EST_PICKUP_DATE,
    value: shipment.estPickupDate,
    id: 'estPickupDate',
    FieldType: DetailsDateField,
  },
  {
    label: SHIPMENTS.EST_DELIVERY_DATE,
    value: shipment.estDeliveryDate,
    id: 'estDeliveryDate',
    FieldType: DetailsDateField,
  },
  isGlobalUserType(auth.user.userType) && {
    label: SHIPMENTS.SHIPMENT_DIRECTION,
    icon: 'icon-car',
    value: shipment.shipmentDirection,
    id: 'shipmentDirection',
    FieldType: DetailsHumanizedField,
  },
  {
    label: SHIPMENTS.SHIPMENT_COMPANY,
    value: shipment.shipmentCompany || shipment.shipmentTruckingCompany,
    icon: 'icon-company',
    id: 'shipmentCompany',
    FieldType: DetailsField,
  },
  {
    label: SHIPMENTS.SHIPMENT_SEAL,
    value: shipment.shipmentSeal,
    icon: 'icon-class',
    id: 'shipmentSeal',
    FieldType: DetailsField,
  },
  {
    label: SHIPMENTS.ASSIGNED_LOTS,
    customContent:
      !!shipment.assignedLot.length &&
      shipment.populatedLots.map(lot => (
        <LinkButton
          key={lot._id}
          label={lot.grgLotNumber}
          onClick={() => history.push(`/lots/list/${lot._id}`)}
        />
      )),
    icon: 'icon-box',
    id: 'assignedLots',
    FieldType: DetailsField,
  },
  isGlobalUserType(auth.user.userType) && {
    label: SHIPMENTS.LOAD_NUMBER,
    icon: 'icon-car',
    value: shipment.loadNumber,
    id: 'loadNumber',
    FieldType: DetailsField,
  },
];

const shipmentDetailsContainerConfig = auth => ({
  header: SHIPMENTS.GENERAL_DETAILS,
  template: isGlobalUserType(auth.user.userType)
    ? [
        'fromCompany fromCompany fromLocation fromLocation',
        'shipmentDirection shipmentDirection estPickupDate estDeliveryDate',
        'shipmentCompany shipmentCompany shipmentSeal shipmentSeal',
        'assignedLots assignedLots loadNumber loadNumber',
      ]
    : [
        'fromCompany fromCompany fromLocation fromLocation',
        'estPickupDate estPickupDate estDeliveryDate estDeliveryDate',
        'shipmentCompany shipmentCompany shipmentSeal shipmentSeal',
        'assignedLots assignedLots assignedLots assignedLots',
      ],
});

export { shipmentDetailsFieldsConfig, shipmentDetailsContainerConfig };
