import {
  enterCustomPricesActionType,
  enterCustomPricesFailureActionType,
  enterCustomPricesSuccessActionType,
} from 'actions/Hedges/enterCustomPrices';

const INITIAL_STATE = {
  isPending: false,
};

const enterCustomPricesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case enterCustomPricesActionType:
      return {
        isPending: true,
      };

    case enterCustomPricesSuccessActionType:
    case enterCustomPricesFailureActionType:
      return {
        isPending: false,
      };

    default:
      return state;
  }
};

export { enterCustomPricesReducer };
