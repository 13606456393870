import PropTypes from 'prop-types';

import { Icon } from 'shared/components/Icon/Icon';
import { SpecialLink } from 'shared/components/SpecialLink/SpecialLink';

import {
  StyledSingleLine,
  StyledValue,
  StyledLineWrapper,
  StyledFieldsWrapper,
} from './StyledDetailsInfo';

const DetailsInfo = ({ title, value, fields }) => (
  <StyledLineWrapper>
    {(value || fields) && (
      <>
        <StyledSingleLine>{title}: </StyledSingleLine>
        {(Array.isArray(value) ? value : [value]).map((e, index) => (
          <StyledValue key={index}> {e}</StyledValue>
        ))}
      </>
    )}

    {fields?.map(
      (field, index) =>
        field.value && (
          <StyledFieldsWrapper key={index}>
            <StyledValue href={field.href}>
              {field.title}: {field.value}
            </StyledValue>
            {field.icons?.map(({ icon, href, type }, index) => (
              <SpecialLink type={type} href={href} key={index}>
                <Icon icon={`icon-${icon}`} />
              </SpecialLink>
            ))}
          </StyledFieldsWrapper>
        ),
    )}
  </StyledLineWrapper>
);

DetailsInfo.propTypes = {
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  fields: PropTypes.arrayOf(PropTypes.object),
};

export { DetailsInfo };
