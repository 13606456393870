import PropTypes from 'prop-types';
import * as React from 'react';

import { PERMISSIONS } from 'shared/helpers/constants/systemSettings/permissions/constants';

import { LogsActionList } from '../LogsActionList/LogsActionList';
import { LogsItem } from '../LogsItem/LogsItem';
import * as S from '../SpecialPermissionsLogsStyles';

const LogsDetails = ({ logInfo }) => {
  return (
    <S.LogsActionWrapper>
      <S.LogsActionHeader>{PERMISSIONS.ACTION_DETAILS}</S.LogsActionHeader>
      <S.LogsActionContent>
        <LogsItem>
          <LogsActionList data={logInfo} />
        </LogsItem>
      </S.LogsActionContent>
    </S.LogsActionWrapper>
  );
};

LogsDetails.propTypes = {
  logInfo: PropTypes.shape({
    updatedBy: PropTypes.string,
    updatedAt: PropTypes.string,
  }),
};

export { LogsDetails };
