import PropTypes from 'prop-types';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';

import { cssVar } from 'shared/helpers/styling/styling';

import { Button } from '../Button';

const Tooltip = styled(ReactTooltip)`
  background-color: ${cssVar('baliHai')} !important;
  color: ${cssVar('alabaster')} !important;
  border-radius: 10px !important;

  &.place-top {
    &:after {
      border-top-color: ${cssVar('baliHai')} !important;
      border-top-style: solid !important;
      border-top-width: 6px !important;
    }
  }
`;

const TooltipButton = ({ tooltip, ...props }) => (
  <>
    <Button
      data-tip={tooltip.label}
      bgColorHover={tooltip && cssVar('baliHai')}
      tooltip={{
        ...tooltip,
        bgColorHover: cssVar('alabaster'),
      }}
      {...props}
    />
    <Tooltip {...tooltip} />
  </>
);

TooltipButton.propTypes = {
  tooltip: PropTypes.object.isRequired,
};

export { TooltipButton };
