import PropTypes from 'prop-types';
import { useRef, useEffect, useState, useCallback } from 'react';

import { SnackbarContent } from 'components/SnackbarContent/SnackbarContent';

import { Button } from 'shared/components/Buttons/Button';
import { infoOptions } from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

import { Container, MessageContent } from './StyledSessionSnackbarContent';

const SessionSnackbarContent = ({ remainingTime, onCancel, onSubmit }) => {
  const timerId = useRef(null);
  const [seconds, setSeconds] = useState(remainingTime / 1000);

  const updateRemainingTime = () => setSeconds(seconds => seconds - 1);

  useEffect(() => {
    if (seconds > 0) {
      timerId.current = setTimeout(updateRemainingTime, 1000);
    }

    return () => clearTimeout(timerId.current);
  }, [seconds]);

  const getRemainingTime = (seconds = 0) => {
    const displayMinutes = Math.floor(seconds / 60);
    const displaySeconds = `0${seconds % 60}`.slice(-2);
    return `${displayMinutes}:${displaySeconds} s.`;
  };

  const renderSnackbarMessage = useCallback(
    () => (
      <Container>
        <MessageContent>Your session will expire in {getRemainingTime(seconds)}</MessageContent>
        <Button outlineColor="warning" onClick={onSubmit} data-testid="session-button">
          Extend session
        </Button>
      </Container>
    ),
    [seconds],
  );

  return (
    <SnackbarContent
      options={infoOptions}
      icon="icon-logout"
      onCancel={onCancel}
      top
      snackbarMessage={renderSnackbarMessage()}
    />
  );
};

SessionSnackbarContent.propTypes = {
  remainingTime: PropTypes.number,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
};

export { SessionSnackbarContent };
