import { css } from 'styled-components';

export const TypographyStyles = css`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--alabaster);
    font-weight: 300;
  }

  h1 {
    font-size: 2.2em;
  }

  h2 {
    font-weight: 500;
    color: var(--whiteGRG);
    font-size: 1.875rem;
  }

  h3 {
    color: var(--whiteGRG);
  }
`;
