const SELF_DELIVERY_REQUESTS = {
  ACTIONS: 'Actions',
  GENERAL_DETAILS: 'General details',
  ACTIVITY: 'Activity',
  ALL_SELF_DELIVERY_REQUESTS: 'All self-delivery requests',
  APPROVE: 'Approve',
  CREATE_SELF_DELIVERY_REQUEST: 'Create a self-delivery request',
  CREATED_BY: 'Created by',
  CUSTOMER_INSTRUCTIONS: 'Customer instructions',
  DECLINE: 'Decline',
  DELETE_REQUEST: 'Delete request',
  DELIVERY_DATE_PICKER: 'Delivery date',
  DELIVERY_DATE: 'Delivery date and approx. time',
  APPROX_DELIVERY_TIME: 'Approx. delivery time',
  DELIVERY_DATE_FROM: 'Delivery date from',
  DELIVERY_DATE_TO: 'Delivery date to',
  ESTIMATED_FUNDS: 'Estimated funds',
  FROM_COMPANY: 'From company:',
  FUNDS_TYPE: 'Funds type',
  GLOBAL_DECISION_BY: 'Global decision by',
  RELATED_COMPANY: 'Related company',
  SELF_DELIVERY_REQUEST: 'Self-delivery request',
  SELF_DELIVERY_REQUESTS: 'Self-delivery requests',
  STATUS: 'Status',
  SWITCH_TO_CALENDAR_VIEW: 'Switch to calendar view',
  UNIT_COUNT: 'Unit count',
  UNIT_COUNT_UNITS: 'Unit count (units)',
  UPCOMING_DELIVERIES: 'Upcoming deliveries',
  PLACEHOLDERS: {
    SEARCH: 'Search...',
    SELECT_FUNDS_TYPE: '-- select funds type --',
    SELECT_RELATED_COMPANY: 'Select related company',
  },
  FILTERS: {
    SELECT_STATUS: '-- select status --',
  },
  ERROR: {
    CREATE_SELF_DELIVERY_REQUEST_ERROR: 'Self delivery request has not been created.',
    CHANGE_STATUS_ERROR: 'Self delivery request has not been approved or declined.',
  },
};

export { SELF_DELIVERY_REQUESTS };
