import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import styled from 'styled-components';

import {
  getPoolAccountDetails,
  clearPoolAccountDetails,
} from 'actions/PoolAccounts/poolAccountDetails';
import { showSnackbar } from 'actions/shared/snackbar';

import { Button } from 'shared/components/Buttons/Button';
import { ContentLoader } from 'shared/components/ContentLoader/ContentLoader';
import { DetailsHeader } from 'shared/components/Details/DetailsHeader';
import { POOL_ACCOUNTS } from 'shared/helpers/constants/poolAccounts/poolAccountsConstants';
import { dangerOptions } from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { isGlobalUserType } from 'shared/helpers/matchers/isGlobalUserType';
import { pxToRem, cssVar, media } from 'shared/helpers/styling/styling';

import { extractErrorMessage } from 'utils/extractErrorMessage';

import { PoolAccountBalanceStatement } from '../PoolAccountBalanceStatement/PoolAccountBalanceStatement';
import { PoolAccountDetailsList } from './components';

const HeaderContainer = styled.div`
  margin-bottom: 20px;

  div:first-of-type {
    margin-bottom: 0;
  }
`;

const CompanyContainer = styled.div`
  font-size: ${pxToRem(24)};
  font-weight: 500;
  color: ${cssVar('whiteGRG')};
  word-break: break-word;

  ${media.phone`
    font-size: ${pxToRem(16)};
    margin-top: 8px;
    padding-left: 16px;
  `}
`;

const PoolAccountDetailsLayout = ({
  auth: {
    user: { userType },
  },
  match,
  poolAccountDetails: { poolAccount, isPending },
  ...props
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    props.getPoolAccountDetails(match.params.companyId).catch(error => {
      dispatch(showSnackbar(dangerOptions, extractErrorMessage(error)));
      return props.history.push('/pool-accounts');
    });

    return () => props.clearPoolAccountDetails();
  }, []);

  return (
    <ContentLoader loaded={poolAccount && !isPending}>
      {poolAccount && (
        <>
          <HeaderContainer>
            <DetailsHeader header={POOL_ACCOUNTS.POOL_ACCOUNT_BALANCE}>
              <PoolAccountBalanceStatement companyId={match.params.companyId}>
                {({ generatePoolAccountBalanceStatement }) => (
                  <Button
                    outline
                    width="100%"
                    id="poolAccountBalanceStatement"
                    onClick={generatePoolAccountBalanceStatement}
                  >
                    {POOL_ACCOUNTS.CREATE_STATEMENT}
                  </Button>
                )}
              </PoolAccountBalanceStatement>
            </DetailsHeader>
            {isGlobalUserType(userType) && (
              <CompanyContainer>{poolAccount.companyName}</CompanyContainer>
            )}
          </HeaderContainer>
          <PoolAccountDetailsList poolAccount={poolAccount} />
        </>
      )}
    </ContentLoader>
  );
};

const mapStateToProps = ({ auth, poolAccountDetails }) => ({
  auth,
  poolAccountDetails,
});

const mapDispatchToProps = {
  getPoolAccountDetails,
  clearPoolAccountDetails,
};

const PoolAccountDetails = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
)(PoolAccountDetailsLayout);

export { PoolAccountDetails, PoolAccountDetailsLayout };
