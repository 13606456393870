import React from 'react';
import { connect } from 'react-redux';

import { getLotsForAdvancePayment, clearAssignedLotsAction } from 'actions/Lots/getAssignedLots';
import { clearPaymentAmount, getPaymentAmount } from 'actions/Payments/getPaymentAmount';
import { requestAdvancePayment } from 'actions/Payments/requestPayment';
import { openModal } from 'actions/shared/modal';
import { showSnackbar } from 'actions/shared/snackbar';

import { FormContainer } from 'shared/components/Form';
import { PAYMENTS } from 'shared/helpers/constants/payments/paymentsConstants';
import { snackbarOptions } from 'shared/helpers/matchers/snackbar/snackbarOptions';

import { RequestAdvancePaymentForm } from './RequestAdvancePaymentForm/RequestAdvancePaymentForm';

function RequestAdvancePaymentLayout(props) {
  const resolveAction = res => {
    props.showSnackbar(snackbarOptions(res.messageType), res.message);
    props.history.push(res.id ? `/payments/${res.id}` : '/payments');
  };

  const submit = values =>
    props.requestAdvancePayment(values).then(resolveAction).catch(resolveAction);

  return (
    <FormContainer
      header={PAYMENTS.REQUEST_ADVANCE_PAYMENT}
      subHeader={props.auth.user.relatedCompany.companyName}
      loaded={!props.requestPayment.isPending}
    >
      {() => <RequestAdvancePaymentForm {...props} onSubmit={submit} />}
    </FormContainer>
  );
}

const mapStateToProps = ({ auth, assignedLots, requestPayment, paymentAmount }) => ({
  auth,
  requestPayment,
  assignedLots,
  paymentAmount,
});

const mapDispatchToProps = {
  openModal,
  showSnackbar,
  requestAdvancePayment,
  getLotsForAdvancePayment,
  getPaymentAmount,
  clearPaymentAmount,
  clearAssignedLotsAction,
};

const RequestAdvancePayment = connect(
  mapStateToProps,
  mapDispatchToProps,
)(RequestAdvancePaymentLayout);

export { RequestAdvancePaymentLayout, RequestAdvancePayment };
