import styled from 'styled-components';

export const StyledAgreementUploadSigned = styled.form`
  &-button.btn {
    width: 100%;
    min-width: none;

    .FieldUpload-error {
      height: 0;
    }
  }
`;
