import React from 'react';

import { Button } from 'shared/components/Buttons/Button';
import { Icon } from 'shared/components/Icon/Icon';
import { FILTERS } from 'shared/helpers/constants/filters/filtersConstants';
import { cssVar } from 'shared/helpers/styling/styling';

function ClearFilters({ clearFilters }) {
  const filtersClear = () => {
    clearFilters();
  };

  return (
    <Button
      id="clearFilters"
      className="ClearFilters"
      onClick={() => filtersClear()}
      outlineColor={cssVar('roman')}
    >
      {FILTERS.CLEAR_FILTERS}
      <Icon icon="icon-cross" height={15} width={15} />
    </Button>
  );
}

export { ClearFilters };
