import { useHistory, useLocation, useParams } from 'react-router-dom';

import { UIAuthorization } from 'components/Authorization/UIAuthorization';

import { RouterButton } from 'shared/components/Buttons';
import { Button } from 'shared/components/Buttons/Button';
import { ExtendableButton } from 'shared/components/Buttons/ExtendableButton/ExtendableButton';
import { DetailsHeader } from 'shared/components/Details/DetailsHeader';
import { PrintIntent } from 'shared/components/PrintIntent/PrintIntent';
import {
  packageInactivateAccess,
  packageReactivateAccess,
} from 'shared/helpers/accesses/packages/packageActivationAccess';
import { packageViewPrintLabRequestFormAccess } from 'shared/helpers/accesses/packages/printLabRequestFormsAccess';
import { packageViewPrintPackageLabelsAccess } from 'shared/helpers/accesses/packages/printPackageLabelsAccess';
import { adminUpdatePackageWeightAccess } from 'shared/helpers/accesses/packages/specialPermissions/adminUpdatePackageWeightAccess';
import {
  deletePackageAccess,
  updateGlobalCreatedIncomingPackageAccess,
  updatePackageAccess,
  updatePackageWeightAccess,
} from 'shared/helpers/accesses/packages/updatePackageAccess';
import { PACKAGES } from 'shared/helpers/constants/packages/packageConstants';
import { packageType as packageTypes } from 'shared/helpers/constants/packages/packageType';
import { SHIPMENTS } from 'shared/helpers/constants/shipments/shipmentsConstants';
import { isInternalCompany } from 'shared/helpers/matchers/checkCompanyType';
import { isCustomerUser, isGradingUser } from 'shared/helpers/matchers/checkUserType';
import { isGlobalRefiningGroup } from 'shared/helpers/matchers/isGlobalRefiningGroup';
import { isGlobalCreatedPackage } from 'shared/helpers/matchers/packages/isGlobalCreatedPackage';
import { isPackageDeletable } from 'shared/helpers/validations/package/isPackageDeletable';

import { printCustomerInstructionsUrl, printLabelEbsUrl, printLabelsUrl } from 'utils/labelsUrls';
import { relativeNavigator } from 'utils/relativeNavigator';
import { urlExtractor } from 'utils/urlExtractor';

import { DeletePackage } from 'pages/Packages/components/DeletePackage/DeletePackage';
import { PackageStateToggle } from 'pages/Packages/components/PackageStateToggle/PackageStateToggle';
import { PrintLabRequestForm } from 'pages/Packages/components/PrintLabRequestForms/PackageView/PrintLabRequestForm';
import { PrintPackageLabel } from 'pages/Packages/components/PrintPackageLabels/PackageView/PrintPackageLabel';

export const PackageMainDetails = ({ pkg, auth }) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { shipmentId } = useParams();

  const {
    id,
    companyName,
    packageType,
    packageClass,
    assignedShipment,
    assignedLot: {
      customLotNumber,
      grgLotNumber,
      statusForGlobal,
      sentFromCompany: { materialCountRequired },
    },
  } = pkg;

  const navigation = [
    {
      from: /^\/shipments\/packages\/\w+?$/,
      to: () => '/shipments/packages/create',
    },
    {
      from: /^\/shipments\/\w+\/packages\/\w+\/?$/,
      to: ({ shipmentId }) => `/shipments/${shipmentId}`,
    },
    {
      from: /^\/shipments\/packages\/\w+\/?$/,
      to: ({ pkg }) => `/lots/list/${pkg.assignedLot.id}`,
      tryHistory: true,
    },
    {
      from: /^\/lots\/list\/\w+\/packages\/\w+\/?$/,
      to: ({ pathName }) => urlExtractor(pathName, -2),
    },
    {
      from: /^\/lots\/(mixing|processing)\/(accept|complete)\/\w+\/packages\/\w+\/?$/,
      to: ({ pathName }) => urlExtractor(pathName, -2),
      tryHistory: true,
    },
    {
      from: /^\/lots\/list\/\w+\/?$/,
      to: ({ pathName }) => pathName,
    },
  ];

  const relativeNavigate = relativeNavigator(navigation)(history);
  const isAndroid = /Android/i.test(navigator.userAgent);

  const globalCreatedUpdateLink = () => {
    const forLotType = isGlobalRefiningGroup(companyName) ? 'global' : 'customer';

    return `./${id}/update/${forLotType}`;
  };

  const packageLabelPrintable = () => {
    const { userType } = auth.user;

    return !isCustomerUser(userType) && !isGradingUser(userType) && isAndroid;
  };

  const customerInstructionsPrintable = () => {
    return (
      (pkg.assignedLot.customerInstructions || pkg.customerInstructions) && packageLabelPrintable()
    );
  };

  const navigate = () => relativeNavigate(pathname, { pkg, shipmentId });

  const generateLotNumber = () =>
    (!customLotNumber && grgLotNumber) || (
      <>
        {grgLotNumber}
        <br />
        {customLotNumber}
      </>
    );

  return (
    <DetailsHeader status={pkg.status} header={generateLotNumber()}>
      <ExtendableButton label={SHIPMENTS.ACTIONS}>
        <UIAuthorization
          access={updatePackageAccess(auth, pkg) && packageType !== packageTypes.sample}
        >
          <RouterButton id="updatePackage" path={`./${id}/update`} label={PACKAGES.EDIT_PACKAGE} />
        </UIAuthorization>
        <UIAuthorization access={packageInactivateAccess(auth, pkg)}>
          <PackageStateToggle>
            {({ inactivatePackage }) => (
              <Button id="inactivatePackage" onClick={() => inactivatePackage(pkg)}>
                {PACKAGES.INACTIVATE}
              </Button>
            )}
          </PackageStateToggle>
        </UIAuthorization>
        <UIAuthorization access={packageReactivateAccess(auth, pkg)}>
          <PackageStateToggle>
            {({ reactivatePackage }) => (
              <Button id="reactivatePackage" onClick={() => reactivatePackage(pkg)}>
                {PACKAGES.REACTIVATE}
              </Button>
            )}
          </PackageStateToggle>
        </UIAuthorization>
        <UIAuthorization access={updatePackageWeightAccess(auth, pkg)}>
          <RouterButton
            id="updatePackageWeight"
            path={`./${id}/update/weight`}
            label={PACKAGES.UPDATE_WEIGHT}
          />
        </UIAuthorization>
        <UIAuthorization
          access={
            updateGlobalCreatedIncomingPackageAccess(auth, pkg) &&
            !isGlobalCreatedPackage(pkg) &&
            packageType !== packageTypes.sample
          }
        >
          <RouterButton
            id="updateGlobalCreatedPackage"
            path={globalCreatedUpdateLink()}
            label={PACKAGES.EDIT_PACKAGE}
          />
        </UIAuthorization>
        <UIAuthorization access={!isAndroid && packageViewPrintPackageLabelsAccess(auth)}>
          <PrintPackageLabel
            path={`/packages/print-labels/${id}`}
            errorMessage={PACKAGES.PRINT_PACKAGE_LABEL_ERROR}
          >
            {({ onClick }) => (
              <Button outline id="printPackageLabel" type="button" onClick={onClick}>
                {PACKAGES.PRINT_LABEL}
              </Button>
            )}
          </PrintPackageLabel>
        </UIAuthorization>
        <UIAuthorization access={packageViewPrintLabRequestFormAccess(auth, pkg)}>
          <PrintLabRequestForm packageId={id}>
            {({ onClick }) => (
              <Button outline id="printLabRequestForm" type="button" onClick={onClick}>
                {PACKAGES.PRINT_LAB_REQUEST_FORMS}
              </Button>
            )}
          </PrintLabRequestForm>
        </UIAuthorization>
        <UIAuthorization access={packageLabelPrintable()}>
          <Button outline id="printPackageLabelMobile" type="button" margin={false}>
            <PrintIntent intent={printLabelsUrl([pkg])} fallback={`/packages/print-labels/${id}`}>
              {PACKAGES.PRINT_LABEL_ZEBRA}
            </PrintIntent>
          </Button>
        </UIAuthorization>
        <UIAuthorization access={packageLabelPrintable()}>
          <Button outline id="printPackageLabelMobile" type="button" margin={false}>
            <PrintIntent
              intent={printLabelEbsUrl(pkg)}
              pkg="com.globalrefininggroup.checkinlabels.bluetoothprinterEBS"
              fallback={`/packages/print-labels/${id}`}
            >
              {PACKAGES.PRINT_LABEL_EBS}
            </PrintIntent>
          </Button>
        </UIAuthorization>
        {!materialCountRequired && (
          <UIAuthorization access={customerInstructionsPrintable()}>
            <Button outline id="printCustomerInstructionsMobile" type="button">
              <PrintIntent
                intent={printCustomerInstructionsUrl(pkg)}
                fallback={`/packages/print-labels/${id}`}
              >
                {PACKAGES.PRINT_CUSTOMER_INSTRUCTIONS}
              </PrintIntent>
            </Button>
          </UIAuthorization>
        )}
        {(!materialCountRequired ||
          assignedShipment?.packages?.length > 1 ||
          !assignedShipment) && (
          <UIAuthorization
            access={
              deletePackageAccess(auth, pkg) &&
              isPackageDeletable(auth, pkg) &&
              (!isInternalCompany(auth.user.relatedCompany.companyType) ||
                isGlobalRefiningGroup(auth.user.relatedCompany.companyName))
            }
          >
            <DeletePackage pkg={pkg} onDelete={navigate}>
              {({ deletePackage }) => (
                <Button outline id="deletePackage" color="danger" onClick={deletePackage}>
                  {PACKAGES.DELETE_PACKAGE}
                </Button>
              )}
            </DeletePackage>
          </UIAuthorization>
        )}
        <UIAuthorization
          access={adminUpdatePackageWeightAccess({ auth, statusForGlobal, packageClass })}
        >
          <RouterButton
            id="adminUpdatePackageWeight"
            color="danger"
            path={`/lots/list/${pkg.assignedLot._id}/packages/${pkg.id}/update/weight/admin`}
            label={PACKAGES.ADMIN_UPDATE_WEIGHT}
          />
        </UIAuthorization>
      </ExtendableButton>
    </DetailsHeader>
  );
};
