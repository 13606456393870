import React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { reduxForm, getFormSyncErrors } from 'redux-form';

import { openModal } from 'actions/shared/modal';

import { FieldConfirmation } from 'shared/components/Fields';
import { Form } from 'shared/components/Form';
import { ENTER_PRICES } from 'shared/helpers/constants/hedges/enterPricesConstants';
import { HEDGES } from 'shared/helpers/constants/hedges/hedgesConstants';
import { cancelModal } from 'shared/helpers/constants/modalConstants';
import { validate } from 'shared/helpers/validations/hedges/enterPrices';

const priceType = {
  actual: 'Actual',
  custom: 'Cust',
};

const formName = 'enterPrices';
const syncError = getFormSyncErrors(formName);

const EnterPricesFormBase = props => {
  const { type, change, match, history, hedge, invalid, submitting, handleSubmit } = props;
  const dispatch = useDispatch();

  const form = {
    values: useSelector(state => state.form.enterPrices.values),
    errors: useSelector(state => syncError(state)),
  };

  const navigateBack = () => history.push(`/hedges/${match.params.id}`);

  const setDisableConfirmation = fieldName => {
    return Boolean(!form.values[fieldName] || form.errors[fieldName]);
  };

  const openModalOnCancel = () => {
    dispatch(openModal(cancelModal, navigateBack));
  };

  const getLabel = key => HEDGES[`${key}_${type.toUpperCase()}`];

  const getErrorMessage = key => ENTER_PRICES[`${key}_${type.toUpperCase()}_DO_NOT_MATCH`];

  return (
    <Form
      onSubmit={handleSubmit}
      onCancel={openModalOnCancel}
      submitDisabled={invalid || submitting}
      header={HEDGES.HEDGE_PRICES}
    >
      <FieldConfirmation
        name={`platinumPrice${type}`}
        type="text"
        label={getLabel('PLATINUM_PRICE')}
        field="required"
        prefix="$"
        change={change}
        errorMessage={getErrorMessage('PLATINUM_PRICE')}
        disabled={hedge.platinumOz === 0}
        disableConfirmation={setDisableConfirmation(`platinumPrice${type}`)}
      />
      <FieldConfirmation
        name={`palladiumPrice${type}`}
        type="text"
        label={getLabel('PALLADIUM_PRICE')}
        field="required"
        prefix="$"
        change={change}
        errorMessage={getErrorMessage('PALLADIUM_PRICE')}
        topBorder
        disabled={hedge.palladiumOz === 0}
        disableConfirmation={setDisableConfirmation(`palladiumPrice${type}`)}
      />
      <FieldConfirmation
        name={`rhodiumPrice${type}`}
        type="text"
        label={getLabel('RHODIUM_PRICE')}
        field="required"
        prefix="$"
        change={change}
        errorMessage={getErrorMessage('RHODIUM_PRICE')}
        topBorder
        disabled={hedge.rhodiumOz === 0}
        disableConfirmation={setDisableConfirmation(`rhodiumPrice${type}`)}
      />
    </Form>
  );
};

let EnterPricesForm = compose(
  reduxForm({
    form: formName,
    validate,
    enableReinitialize: true,
  }),
  withRouter,
)(EnterPricesFormBase);

EnterPricesForm = connect(({ hedgesDetails: { hedge } }) => ({
  initialValues: {
    palladiumPriceActual: hedge?.palladiumPriceActual?.toString(),
    platinumPriceActual: hedge?.platinumPriceActual?.toString(),
    rhodiumPriceActual: hedge?.rhodiumPriceActual?.toString(),
    palladiumPriceCust: hedge?.palladiumPriceCust?.toString(),
    platinumPriceCust: hedge?.platinumPriceCust?.toString(),
    rhodiumPriceCust: hedge?.rhodiumPriceCust?.toString(),
    palladiumPriceActualConfirm: hedge?.palladiumPriceActual?.toString(),
    platinumPriceActualConfirm: hedge?.platinumPriceActual?.toString(),
    rhodiumPriceActualConfirm: hedge?.rhodiumPriceActual?.toString(),
    palladiumPriceCustConfirm: hedge?.palladiumPriceCust?.toString(),
    platinumPriceCustConfirm: hedge?.platinumPriceCust?.toString(),
    rhodiumPriceCustConfirm: hedge?.rhodiumPriceCust?.toString(),
  },
}))(EnterPricesForm);

export { EnterPricesForm, EnterPricesFormBase, priceType };
