import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Navbar } from 'components/Navbar/Navbar';
import { NavbarElement } from 'components/Navbar/components/NavbarElement';

import { globalRelatedAppName } from 'shared/helpers/constants/companies/globalRelatedAppName';
import { logoutModal } from 'shared/helpers/constants/modalConstants';
import { getNavbarElements } from 'shared/helpers/constants/navbarElements';
import { SHARED } from 'shared/helpers/constants/sharedConstants';
import { cssVar } from 'shared/helpers/styling/styling';

import { history } from 'utils/history';
import { resolveVersion, versions } from 'utils/versioning';

const MenuElements = styled.div`
  border-bottom: 1px solid
    ${resolveVersion({
      [versions.alpha]: cssVar('boulderLegend'),
      [versions.global]: cssVar('boulderLegend'),
      [versions.alpha]: cssVar('boulderLegend'),
      [versions.west]: cssVar('graniteGray'),
    })};

  && a:last-of-type {
    background-color: transparent;
    color: ${resolveVersion({
      [versions.alpha]: cssVar('geyser'),
      [versions.global]: cssVar('geyser'),
      [versions.alpha]: cssVar('geyser'),
      [versions.west]: cssVar('graniteGray'),
    })};
  }
`;

function SidebarItemsLayout(props) {
  const {
    auth: {
      access,
      user: { userType },
    },
  } = props;

  const logout = () => {
    props.closeOnClick();

    props.openModal(logoutModal, () => {
      history.push('/logout');
    });
  };

  const settingsAuth = useCallback(() => access.systemSettings.view, [access.systemSettings.view]);
  const relatedPortalAuth = useCallback(
    () => access.relatedPortal.view,
    [access.relatedPortal.view],
  );
  const relatedApp = process.env.REACT_APP_RELATED_UNIT_URL;

  return (
    <>
      <MenuElements>
        <NavbarElement
          id="navbarMyProfile"
          path="/my-profile"
          access={() => true}
          closeOnClick={props.closeOnClick}
          label={SHARED.MY_PROFILE}
        />
        <NavbarElement
          id="navbarSystemSettings"
          path="/settings"
          access={settingsAuth}
          closeOnClick={props.closeOnClick}
          label={SHARED.SYSTEM_SETTINGS}
        />
        {relatedApp && (
          <NavbarElement
            id="navbarRelatedApp"
            path={relatedApp}
            access={relatedPortalAuth}
            closeOnClick={props.closeOnClick}
            label={globalRelatedAppName}
            target="_blank"
            external
          />
        )}
        <NavbarElement
          id="navbarLogout"
          path={history.location.pathname}
          access={() => true}
          closeOnClick={logout}
          label={SHARED.LOGOUT}
        />
      </MenuElements>
      <Navbar {...props} navbarElements={getNavbarElements(userType)} />
    </>
  );
}

const mapStateToProps = ({ auth, poolAccountsList }) => ({
  auth,
  poolAccountsList,
});

const SidebarItems = connect(mapStateToProps)(SidebarItemsLayout);

export { SidebarItems, SidebarItemsLayout };
