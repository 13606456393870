import axios from 'axios';

import { POOL_ACCOUNTS } from 'shared/helpers/constants/poolAccounts/poolAccountsConstants';
import { parseEnterPoolAccountOuncesData } from 'shared/helpers/dataParsers/poolAccounts/parseEnterPoolAccountOuncesData';

const API_HOST_AUTH = process.env.REACT_APP_API;

const enterPoolAccountOuncesActionType = 'ENTER_POOL_ACCOUNT_OUNCES';
const enterPoolAccountOuncesAction = () => ({
  type: enterPoolAccountOuncesActionType,
});

const enterPoolAccountOuncesSuccessActionType = `${enterPoolAccountOuncesActionType}_SUCCESS`;
const enterPoolAccountOuncesSuccessAction = () => ({
  type: enterPoolAccountOuncesSuccessActionType,
});

const enterPoolAccountOuncesFailureActionType = `${enterPoolAccountOuncesActionType}_FAILURE`;
const enterPoolAccountOuncesFailureAction = () => ({
  type: enterPoolAccountOuncesFailureActionType,
});

const enterPoolAccountOunces = data => dispatch => {
  dispatch(enterPoolAccountOuncesAction());

  return axios
    .post(`${API_HOST_AUTH}/pool-accounts/enter-ounces`, parseEnterPoolAccountOuncesData(data))
    .then(res => {
      dispatch(enterPoolAccountOuncesSuccessAction());

      return {
        message: res.data.message,
      };
    })
    .catch(error => {
      dispatch(enterPoolAccountOuncesFailureAction());

      return Promise.reject({
        message: `${POOL_ACCOUNTS.ERROR.ENTER_OUNCES} ${error.data.message}`,
      });
    });
};

export {
  enterPoolAccountOuncesActionType,
  enterPoolAccountOuncesAction,
  enterPoolAccountOuncesSuccessActionType,
  enterPoolAccountOuncesSuccessAction,
  enterPoolAccountOuncesFailureActionType,
  enterPoolAccountOuncesFailureAction,
  enterPoolAccountOunces,
};
