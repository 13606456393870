import React, { Component } from 'react';

import { FormContainer } from 'shared/components/Form';
import { normalizeIncomingShipment } from 'shared/helpers/constants/shipments/normalizeShipment';
import { SHIPMENTS } from 'shared/helpers/constants/shipments/shipmentsConstants';
import { snackbarOptions } from 'shared/helpers/matchers/snackbar/snackbarOptions';

import { IncomingShipmentForm } from '../IncomingShipmentForm/IncomingShipmentForm';

class UpdateIncomingShipment extends Component {
  componentDidMount() {
    this.props
      .getShipment(this.props.match.params.id)
      .then(shipment => shipment || Promise.reject(null))
      .catch(() => this.props.history.push('/shipments'));
  }

  componentWillUnmount() {
    this.props.clearShipmentPackagesList();
  }

  submit = values => {
    const updateObj = {
      ...values,
      packages: this.props.shipmentPackagesListData.packages,
      id: this.props.shipmentDetails.shipment._id,
    };

    return this.props.updateIncomingShipment(updateObj).then(this.resolveAction);
  };

  resolveAction = result => {
    this.props.showSnackbar(snackbarOptions(result.messageType), result.message);
    this.props.history.push(`/shipments/incoming/${result.id}`);
  };

  render() {
    const {
      shipmentDetails: { shipment, isPending },
      shipmentPackagesListData,
      location,
    } = this.props;

    const initialValues =
      shipment &&
      normalizeIncomingShipment(shipment, [
        ...((location.state && location.state.packages) || []),
        ...((shipmentPackagesListData && shipmentPackagesListData.packages) || []),
      ]);

    return (
      <FormContainer header={SHIPMENTS.EDIT_INCOMING_SHIPMENT} loaded={shipment && !isPending}>
        {() => (
          <IncomingShipmentForm
            {...this.props}
            onSubmit={this.submit}
            initialValues={initialValues}
            editMode
          />
        )}
      </FormContainer>
    );
  }
}

export { UpdateIncomingShipment };
