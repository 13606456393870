import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getVirtualLot } from 'actions/Lots/getVirtualLot';
import { updateVirtualLot, clearUpdateVirtualLotAction } from 'actions/Lots/updateVirtualLot';
import { openModal } from 'actions/shared/modal';
import { showSnackbar } from 'actions/shared/snackbar';

import { FormContainer } from 'shared/components/Form/FormContainer/FormContainer';
import { Section } from 'shared/components/Form/Section/Section';
import { FieldInput, FieldDate } from 'shared/components/Formik/Fields';
import { Form } from 'shared/components/Formik/Form';
import { Preloader } from 'shared/components/Preloader/Preloader';
import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';
import { cancelModal } from 'shared/helpers/constants/modalConstants';
import { dangerOptions } from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { SECTIONS } from 'shared/helpers/constants/warehouseDashboard/labels';

import { goBackOrTo } from 'utils/history';

import { CreateVirtualLotSchema } from 'pages/Lots/components/VirtualLotManagement/CreateVirtualLot/CreateVirtualLotYup';
import { updateVirtualLotParseData } from 'pages/Lots/helpers/virtualLotParseData';

const UpdateVirtualLot = ({
  match: {
    params: { id },
  },
}) => {
  const dispatch = useDispatch();
  const { updateVirtualLotData, isPending: updateIsPending } = useSelector(
    ({ updateVirtualLot }) => updateVirtualLot,
  );
  const { isPending, virtualLot } = useSelector(({ getVirtualLot }) => getVirtualLot);

  useEffect(() => {
    dispatch(getVirtualLot(id))
      .then(() => validateForm())
      .catch(err => {
        goBackOrTo('/lots');
        return dispatch(showSnackbar(dangerOptions, err.message));
      });

    return () => {
      dispatch(clearUpdateVirtualLotAction());
    };
  }, [dispatch]);

  const modalOpen = () => {
    dispatch(
      openModal(cancelModal, () => {
        goBackOrTo('/lots');
      }),
    );
  };

  const setRequiredWeight = () => {
    const values = [virtualLot?.section, updateVirtualLotData?.section];
    return values.includes(SECTIONS.COMPLETE.id) ? 'required' : null;
  };

  const {
    validateForm,
    values,
    touched,
    isValid,
    isSubmitting,
    setFieldTouched,
    setFieldValue,
    getFieldMeta,
    handleSubmit,
    handleChange,
    handleBlur,
    setSubmitting,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...virtualLot,
      weight: virtualLot?.weightGrossActual || '',
      section: updateVirtualLotData?.section || virtualLot?.section,
    },
    validationSchema: CreateVirtualLotSchema,
    onSubmit: updatedVirtualLot =>
      dispatch(updateVirtualLot(updateVirtualLotParseData(updatedVirtualLot))).finally(() =>
        setSubmitting(false),
      ),
  });

  const getErrors = name => Object.keys(touched).includes(name) && getFieldMeta(name).error;

  return (
    <>
      <FormContainer header={LOTS.UPDATE_VIRTUAL_LOT}>
        <Form
          onSubmit={handleSubmit}
          onCancel={modalOpen}
          submitDisabled={!isValid || isSubmitting}
          header={LOTS.VIRTUAL_LOT}
        >
          <Section
            template={['name date', `${updateVirtualLotData?.section ? 'section' : '.'} weight`]}
          >
            <FieldInput
              name="name"
              type="text"
              label={LOTS.NAME}
              icon="icon-referral"
              field="required"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              error={getErrors('name')}
              onInput={() => setFieldTouched('name')}
            />
            <FieldDate
              name="date"
              label={LOTS.DATE}
              value={values.date}
              onBlur={handleBlur}
              onChange={setFieldValue}
            />
            {updateVirtualLotData?.section && (
              <FieldInput
                name="section"
                label={LOTS.SECTION}
                field="required"
                style={{ textTransform: 'capitalize' }}
                value={values.section}
                disabled
              />
            )}
            <FieldInput
              name="weight"
              type="number"
              label={LOTS.WEIGHT}
              icon="icon-referral"
              field={setRequiredWeight()}
              value={values.weight}
              onBlur={handleBlur}
              onChange={handleChange}
              onInput={() => setFieldTouched('weight')}
              error={getErrors('weight')}
            />
          </Section>
        </Form>
      </FormContainer>
      <Preloader loading={isPending || updateIsPending} />
    </>
  );
};

UpdateVirtualLot.propTypes = {
  history: PropTypes.object,
  location: PropTypes.shape({
    hash: PropTypes.string,
    key: PropTypes.string,
    pathname: PropTypes.string,
    search: PropTypes.string,
  }),
  match: PropTypes.shape({
    isExact: PropTypes.bool,
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
    path: PropTypes.string,
    url: PropTypes.string,
  }),
};

export { UpdateVirtualLot as UpdateVirtualLotForm };
