import React, { Component } from 'react';

import { FormContainer } from 'shared/components/Form';
import { HEDGES } from 'shared/helpers/constants/hedges/hedgesConstants';
import { createHedgesModal } from 'shared/helpers/constants/modalConstants';
import {
  successOptions,
  dangerOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { filterActiveField } from 'shared/helpers/dataParsers/hedges/filterActiveFields';
import { isCustomerUser } from 'shared/helpers/matchers/checkUserType';

import { ConnectedHedgesForm } from '../HedgesForm/HedgesForm';

class HedgesCreate extends Component {
  modalCallback = values => {
    const {
      showSnackbar,
      createHedgesRequests,
      history,
      location,
      hedgesActiveFields,
      lots,
      clearSharedData,
    } = this.props;

    const filteredValues = values.hedges.map(({ materialUnits, materialWeight, ...baseValues }) => {
      const { statusForCustomer, statusForGlobal } =
        lots[baseValues.relatedCompany.value].find(
          lot => lot._id === (baseValues.relatedLot && baseValues.relatedLot.value),
        ) || {};
      const lotStatuses = {
        statusForCustomer,
        statusForGlobal,
      };

      return materialUnits
        ? { ...baseValues, materialUnits, lotStatuses }
        : { ...baseValues, materialWeight, lotStatuses };
    });

    const valuesToSubmit = filteredValues.map((hedge, index) => ({
      ...filterActiveField(hedge, 'hedges', index, hedgesActiveFields),
      lotStatuses: hedge.lotStatuses,
    }));

    return createHedgesRequests(valuesToSubmit)
      .then(data => {
        showSnackbar(successOptions, data.message);
        clearSharedData('defaultMaterialType');
        this.props.destroy('HedgesForm');

        if (location.state && location.state.relatedLot) {
          return history.push('/hedges/hedge-ounces', {
            shipmentId: location.state.relatedLot.shipmentId,
          });
        }
        history.push('/hedges');
      })
      .catch(error => showSnackbar(dangerOptions, error.message));
  };

  submit = values => {
    this.props.openModal(createHedgesModal, () => this.modalCallback(values));
  };

  render() {
    const {
      auth: {
        user: { userType },
      },
    } = this.props;

    return (
      <FormContainer header={HEDGES.CREATE} focusLockDisabled>
        <ConnectedHedgesForm
          {...this.props}
          onSubmit={isCustomerUser(userType) ? this.submit : this.modalCallback}
        />
      </FormContainer>
    );
  }
}

export { HedgesCreate };
