import React, { useMemo } from 'react';
import { Switch, Route } from 'react-router';

import { Authorization } from 'components/Authorization/Authorization';
import { NotFound } from 'components/ErrorPage/ErrorPage';

import { SettingsDashboard } from './components/SettingsDashboard/SettingsDashboard';
import { MarketIndicatorsLogs } from './components/SettingsDashboard/components/MarketIndicators/components/MarketIndicatorsLogs/MarketIndicatorsLogs';
import { UpdateMarketIndicators } from './components/SettingsDashboard/components/MarketIndicators/components/UpdateMarketIndicators/UpdateMarketIndicators';
import { SpecialPermissionsLogs } from './components/SettingsDashboard/components/Permissions/components/SpecialPermissionsLogs/SpecialPermissionsLogs';
import { UpdatePriceFeed } from './components/SettingsDashboard/components/PriceFeeds/components/UpdatePriceFeed/UpdatePriceFeed';

function Settings({
  auth: {
    access: { systemSettings },
  },
}) {
  const AuthSettingsDashboard = useMemo(
    () => Authorization(SettingsDashboard, systemSettings.view),
    [systemSettings.view],
  );

  const AuthUpdatePriceFeed = useMemo(
    () => Authorization(UpdatePriceFeed, systemSettings.priceFeeds.update),
    [systemSettings.priceFeeds.update],
  );

  const AuthUpdateMarketIndicators = useMemo(
    () => Authorization(UpdateMarketIndicators, systemSettings.marketIndicators.update),
    [systemSettings.marketIndicators.update],
  );

  const AuthMarketIndicatorsLogs = useMemo(
    () => Authorization(MarketIndicatorsLogs, systemSettings.marketIndicators.viewLogs),
    [systemSettings.marketIndicators.viewLogs],
  );

  const AuthSpecialPermissionsLogs = useMemo(
    () => Authorization(SpecialPermissionsLogs, systemSettings.specialPermissions.viewLogs),
    [systemSettings.specialPermissions.viewLogs],
  );

  return (
    <Switch>
      <Route exact path="/settings" component={AuthSettingsDashboard} />
      <Route exact path="/settings/price-feeds/:id/update" component={AuthUpdatePriceFeed} />
      <Route
        exact
        path="/settings/market-indicators/update/:type(hedging|settlement)"
        component={AuthUpdateMarketIndicators}
      />
      <Route exact path="/settings/market-indicators/logs" component={AuthMarketIndicatorsLogs} />
      <Route
        exact
        path="/settings/special-permissions/logs"
        component={AuthSpecialPermissionsLogs}
      />
      <Route component={NotFound} />
    </Switch>
  );
}

export { Settings };
