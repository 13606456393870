import React from 'react';
import styled from 'styled-components';

import { scrollbar } from 'shared/helpers/styling/styling';

import { FieldInput } from '../FieldInput/FieldInput';

const FieldTextareaLayout = props => (
  <FieldInput
    {...props}
    height={100}
    prefixSize={20}
    suffixSize={20}
    maxLength={255}
    padding={10}
    as="textarea"
  />
);

const FieldTextarea = styled(FieldTextareaLayout)`
  ${scrollbar};
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
`;

export { FieldTextarea };
