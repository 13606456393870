import React from 'react';
import styled from 'styled-components';

import { media } from 'shared/helpers/styling/styling';

import { ModalCustom } from '../ModalCustom/ModalCustom';
import { ModalButtons } from './components/ModalButtons/ModalButtons';
import { ModalContent } from './components/ModalContent/ModalContent';

const ModalContainer = styled.div`
  padding: 40px 16px 20px;

  ${media.phone`
    padding: 40px 0 20px;
  `}
`;

const Modal = props => {
  const closeCallback = () => {
    const { closeModal, modal } = props;
    closeModal && closeModal();
    modal && modal.closeCallback && modal.closeCallback();
  };

  return (
    <ModalCustom
      isOpen={props.modal.modalOpened}
      modalClose={closeCallback}
      holdOnOutsideClick={props.holdOnOutsideClick}
    >
      <ModalContainer>
        <ModalContent {...props} />
        <ModalButtons {...props} />
      </ModalContainer>
    </ModalCustom>
  );
};

export { Modal };
