import {
  deleteCommodityType,
  deleteCommoditySuccessType,
  deleteCommodityFailureType,
} from 'actions/Commodities/deleteCommodity';

const INITIAL_STATE = {
  isPending: false,
};

const deleteCommodityReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case deleteCommodityType:
      return {
        isPending: true,
      };
    case deleteCommoditySuccessType:
    case deleteCommodityFailureType:
      return {
        isPending: false,
      };
    default:
      return state;
  }
};

export { deleteCommodityReducer };
