import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Container } from 'reactstrap';

import { saveUrlAction } from 'actions/shared/redirect';

import { NotFound } from 'components/ErrorPage/ErrorPage';
import { Header } from 'components/Header/Header';
import { NotificationDialog } from 'components/NotificationDialog/NotificationDialog';

import { isTouchScreenDevice } from 'shared/helpers/styling/styling';

import { Agreements } from 'pages/Agreements/Agreements';
import { Companies } from 'pages/Companies/Companies';
import { Dashboard } from 'pages/Dashboard/Dashboard';
import { Diligence } from 'pages/Diligence/Diligence';
import { GeneralInfo } from 'pages/GeneralInfo/GeneralInfo';
import { Hedges } from 'pages/Hedges/Hedges';
import { Locations } from 'pages/Locations/Locations';
import { ConnectedLots } from 'pages/Lots/ConnectedLots';
import { Notifications } from 'pages/Notifications/Notifications';
import { Payments } from 'pages/Payments/Payments';
import { PoolAccounts } from 'pages/PoolAccounts/PoolAccounts';
import { Reports } from 'pages/Reports/Reports';
import { Settings } from 'pages/Settings/Settings';
import { ConnectedShipments } from 'pages/Shipments/ConnectedShipments';
import { Terms } from 'pages/Terms/Terms';
import { UserProfile } from 'pages/UserProfile/ConnectedUserProfile';
import { ChangeOwnPassword } from 'pages/UserProfile/components/ChangeOwnPassword/ChangeOwnPassword';
import { Users } from 'pages/Users/Users';

import { StyledAuthContent } from './StyledAuthentication';

const Authentication = ({ auth }) => {
  const { user } = auth;
  const dispatch = useDispatch();

  useEffect(() => {
    if (!user) {
      const notification = new URLSearchParams(window.location.search).get('notification');
      dispatch(saveUrlAction(window.location.pathname, null, notification));
    }
  }, []);

  return user ? (
    <div className={`AppWrapper ${isTouchScreenDevice() ? 'AppWrapper--touch' : ''}`}>
      <NotificationDialog />
      <Header />
      <Container>
        <StyledAuthContent>
          <Switch>
            <Route exact path="/my-profile" component={UserProfile} key="User profile route" />
            <Route
              exact
              path="/my-profile/change-password"
              component={ChangeOwnPassword}
              key="Change password"
            />
            <Route path="/dashboard" render={() => <Dashboard />} />
            <Route path="/users" render={() => <Users auth={auth} />} key="Users route" />
            <Route
              path="/companies"
              render={() => <Companies auth={auth} />}
              key="Companies route"
            />
            <Route
              path="/locations"
              render={() => <Locations auth={auth} />}
              key="Locations route"
            />
            <Route path="/terms" render={() => <Terms auth={auth} />} key="Terms route" />
            <Route
              path="/agreements"
              render={() => <Agreements auth={auth} />}
              key="Agreements route"
            />
            <Route path="/lots" render={() => <ConnectedLots />} key="Lots route" />
            <Route path="/shipments" render={() => <ConnectedShipments />} key="Shipments route" />
            <Route
              path="/notifications"
              render={() => <Notifications />}
              key="Notifications route"
            />
            <Route path="/payments" render={() => <Payments auth={auth} />} key="Payments route" />
            <Route path="/hedges" render={() => <Hedges auth={auth} />} key="Hedges route" />
            <Route
              path="/pool-accounts"
              render={() => <PoolAccounts auth={auth} />}
              key="Pool accounts route"
            />
            <Route
              path="/settings"
              render={() => <Settings auth={auth} />}
              key="System settings route"
            />
            <Route
              path="/diligence"
              render={() => <Diligence auth={auth} />}
              key="Due diligence route"
            />
            <Route
              path="/reports"
              render={() => <Reports auth={auth} />}
              key="Analysis reports route"
            />
            <Route
              path="/general-info"
              render={() => <GeneralInfo auth={auth} />}
              key="General info"
            />
            <Route component={NotFound} />
          </Switch>
        </StyledAuthContent>
      </Container>
    </div>
  ) : (
    <Redirect to="/" />
  );
};

export { Authentication };
