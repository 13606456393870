import { StyledContainer, StyledSection } from './StyledDiligenceDetails';

const renderDiligenceDetails = config => {
  return (
    <StyledSection>
      {config.map(({ fields, ...template }) => (
        <StyledContainer {...template}>
          {fields.map(
            ({ FieldType, ...rest }) => FieldType && <FieldType {...rest} key={rest.id} />,
          )}
        </StyledContainer>
      ))}
    </StyledSection>
  );
};

export { renderDiligenceDetails };
