import {
  updateLotLocationType,
  updateLotLocationSuccessType,
  updateLotLocationFailureType,
} from 'actions/Lots/updateLotLocation';

const INITIAL_STATE = {
  isPending: false,
};

const updateLotLocationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case updateLotLocationType:
      return {
        isPending: true,
      };
    case updateLotLocationSuccessType:
    case updateLotLocationFailureType:
      return {
        isPending: false,
      };
    default:
      return state;
  }
};

export { updateLotLocationReducer };
