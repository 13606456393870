import React, { Component } from 'react';
import { Field, reduxForm, SubmissionError } from 'redux-form';

import { FieldDate, FieldInput } from 'shared/components/Fields';
import { Form, Section } from 'shared/components/Form';
import { HEDGES } from 'shared/helpers/constants/hedges/hedgesConstants';
import { cancelModal } from 'shared/helpers/constants/modalConstants';
import { validate, asyncValidate } from 'shared/helpers/validations/marketHolidays';

import dateAdapter from 'utils/date/dateAdapter';
import { goBackOrTo } from 'utils/history';

class MarketHolidaysFormLayout extends Component {
  onCancel = () => {
    this.props.openModal(cancelModal, () => {
      goBackOrTo('/hedges/market-holidays');
    });
  };

  handleSubmit = values => {
    try {
      this.props.handleSubmit(values);
    } catch (err) {
      new SubmissionError(err);
    }
  };

  render() {
    return (
      <Form
        header={HEDGES.MARKET_HOLIDAYS.TITLE}
        onSubmit={this.handleSubmit}
        onCancel={this.onCancel}
        submitDisabled={this.props.invalid || this.props.submitting}
      >
        <Section template={['holidayName date']}>
          <Field
            name="holidayName"
            component={FieldInput}
            label={HEDGES.MARKET_HOLIDAYS.HOLIDAY_NAME}
            field="required"
          />
          <Field
            name="date"
            component={FieldDate}
            label={HEDGES.MARKET_HOLIDAYS.DATE}
            field="required"
            minDate={dateAdapter()}
            maxDate={dateAdapter().add(1, 'y').endOf('y')}
            disableFlip
          />
        </Section>
      </Form>
    );
  }
}

const MarketHolidaysForm = reduxForm({
  form: 'MarketHolidaysForm',
  validate,
  asyncValidate,
  asyncBlurFields: ['date'],
})(MarketHolidaysFormLayout);

export { MarketHolidaysForm, MarketHolidaysFormLayout };
