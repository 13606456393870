import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getFinalInvoiceMaximumOuncesActionType = 'GET_FINAL_INVOICE_MAXIMUM_OUNCES';
const getFinalInvoiceMaximumOuncesAction = () => ({
  type: getFinalInvoiceMaximumOuncesActionType,
});

const getFinalInvoiceMaximumOuncesSuccessActionType = `${getFinalInvoiceMaximumOuncesActionType}_SUCCESS`;
const getFinalInvoiceMaximumOuncesSuccessAction = data => ({
  type: getFinalInvoiceMaximumOuncesSuccessActionType,
  payload: {
    data,
  },
});

const getFinalInvoiceMaximumOuncesFailureActionType = `${getFinalInvoiceMaximumOuncesActionType}_FAILURE`;
const getFinalInvoiceMaximumOuncesFailureAction = () => ({
  type: getFinalInvoiceMaximumOuncesFailureActionType,
});

const clearFinalInvoiceMaximumOuncesActionType = 'CLEAR_FINAL_INVOICE_MAXIMUM_OUNCES';
const clearFinalInvoiceMaximumOunces = () => ({
  type: clearFinalInvoiceMaximumOuncesActionType,
});

const getFinalInvoiceMaximumOunces = lotId => dispatch => {
  dispatch(getFinalInvoiceMaximumOuncesAction());

  return axios
    .get(`${API_HOST_AUTH}/lots/calculate-ounces/final-invoice/${lotId}`)
    .then(res => {
      dispatch(getFinalInvoiceMaximumOuncesSuccessAction(res.data));

      return res.data;
    })
    .catch(() => Promise.reject(dispatch(getFinalInvoiceMaximumOuncesFailureAction())));
};

export {
  getFinalInvoiceMaximumOuncesActionType,
  getFinalInvoiceMaximumOuncesAction,
  getFinalInvoiceMaximumOuncesSuccessActionType,
  getFinalInvoiceMaximumOuncesSuccessAction,
  getFinalInvoiceMaximumOuncesFailureActionType,
  getFinalInvoiceMaximumOuncesFailureAction,
  clearFinalInvoiceMaximumOuncesActionType,
  clearFinalInvoiceMaximumOunces,
  getFinalInvoiceMaximumOunces,
};
