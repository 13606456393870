import React, { Component } from 'react';

import { isPhone } from 'shared/helpers/constants/resolutionsConstants';

import { getBrowserWidth } from 'utils/responsive';

import { Limit } from './Limit/Limit';
import { Pages } from './Pages/Pages';
import { PagesMobile } from './PagesMobile/PagesMobile';
import { StyledPagination, StyledPaginationPages } from './StyledPagination';

class Pagination extends Component {
  state = {
    windowWidth: getBrowserWidth(),
  };

  componentDidMount() {
    window.addEventListener('resize', this.onWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onWindowSizeChange);
  }

  onWindowSizeChange = () => {
    this.setState({ windowWidth: getBrowserWidth() });
  };

  setPage = page => {
    this.props.setPage(page);
  };

  setLimit = limit => {
    this.props.setLimit(limit);
  };

  render() {
    const { windowWidth } = this.state;
    const isMobile = windowWidth > isPhone;
    const { pages, limits, page, selectedLimit } = this.props;

    return (
      <StyledPagination>
        <StyledPaginationPages>
          {isMobile
            ? pages > 1 && <Pages pages={pages} page={page} setPage={this.setPage} />
            : pages > 1 && <PagesMobile pages={pages} page={page} setPage={this.setPage} />}
        </StyledPaginationPages>
        <div className="Pagination-limit">
          <Limit limits={limits} setLimit={this.setLimit} selectedLimit={selectedLimit} />
        </div>
      </StyledPagination>
    );
  }
}

export { Pagination };
