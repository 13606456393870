import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getAvailableHedgesActionType = 'GET_AVAILABLE_HEDGES';
const getAvailableHedgesAction = () => ({
  type: getAvailableHedgesActionType,
});

const getAvailableHedgesSuccessActionType = `${getAvailableHedgesActionType}_SUCCESS`;
const getAvailableHedgesSuccessAction = hedges => ({
  type: getAvailableHedgesSuccessActionType,
  payload: {
    hedges,
  },
});

const getAvailableHedgesFailureActionType = `${getAvailableHedgesActionType}_FAILURE`;
const getAvailableHedgesFailureAction = () => ({
  type: getAvailableHedgesFailureActionType,
});

const getAvailableHedges =
  (sortedBy = '') =>
  dispatch => {
    dispatch(getAvailableHedgesAction());

    return axios
      .get(`${API_HOST_AUTH}/deliveries/hedges/${sortedBy}`)
      .then(res => {
        dispatch(getAvailableHedgesSuccessAction(res.data));

        return res.data;
      })
      .catch(err => {
        dispatch(getAvailableHedgesFailureAction());

        return Promise.reject(err.data);
      });
  };

export {
  getAvailableHedgesActionType,
  getAvailableHedgesSuccessActionType,
  getAvailableHedgesFailureActionType,
  getAvailableHedges,
};
