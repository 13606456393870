import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { getGlobalCreatedPackage } from 'actions/Packages/packageDetails';
import { updateIncomingPackageForGlobal } from 'actions/Packages/updatePackage';
import { showSnackbar } from 'actions/shared/snackbar';

import { FormContainer } from 'shared/components/Form';
import { normalizePackageObject } from 'shared/helpers/constants/packages/normalizePackage';
import { PACKAGES } from 'shared/helpers/constants/packages/packageConstants';
import {
  dangerOptions,
  successOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

import { goBackOrTo } from 'utils/history';
import { urlExtractor } from 'utils/urlExtractor';

import { ManagePackage } from 'pages/Packages/components/PackagesManagement/components/ManagePackage/ManagePackage';
import { configNames } from 'pages/Packages/components/PackagesManagement/components/PackageForm/components/PackageForm/fieldsConfig';

class UpdateIncomingPackageForGlobalBase extends Component {
  componentDidMount() {
    const {
      match: {
        params: { id, lotId },
      },
    } = this.props;

    this.props.getGlobalCreatedPackage(id).catch(() => goBackOrTo(`/lots/list/${lotId}`));
  }

  submit = values => {
    const { packageClass, assignedLot, ...valuesToSend } = values;

    return this.props
      .updateIncomingPackageForGlobal({ ...valuesToSend, assignedLot: { _id: assignedLot } })
      .then(res => {
        this.props.history.push(urlExtractor(this.props.location.pathname, -2));
        this.props.showSnackbar(successOptions, res.message);
      })
      .catch(res => this.props.showSnackbar(dangerOptions, res.message));
  };

  render() {
    const companyName =
      this.props.lotDetails.lot && this.props.lotDetails.lot.sentFromCompany.companyName;

    const {
      packageDetails: { isPending, package: pkg },
    } = this.props;

    return (
      <FormContainer
        companyName={companyName}
        formLoaded={pkg && !isPending}
        header={PACKAGES.UPDATE_AN_INCOMING_PACKAGE}
        subHeader={companyName}
      >
        {() => (
          <ManagePackage
            companyName={companyName}
            config={configNames.grgForGlobal}
            onSubmit={this.submit}
            editMode
            initialValues={pkg && normalizePackageObject(pkg)}
          />
        )}
      </FormContainer>
    );
  }
}

const mapStateToProps = state => ({
  lotDetails: state.lotDetails,
  packageDetails: state.packageDetails,
});

const mapDispatchToProps = {
  getGlobalCreatedPackage,
  showSnackbar,
  updateIncomingPackageForGlobal,
};

const UpdateIncomingPackageForGlobal = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UpdateIncomingPackageForGlobalBase),
);

export { UpdateIncomingPackageForGlobal, UpdateIncomingPackageForGlobalBase };
