import {
  getHedgeOuncesActionType,
  getHedgeOuncesFailureActionType,
  getHedgeOuncesSuccessActionType,
  clearHedgeOuncesActionType,
} from 'actions/Hedges/getHedgeOunces';

const INITIAL_STATE = {
  isPending: true,
  data: null,
};

const getHedgeOuncesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getHedgeOuncesActionType:
      return {
        ...state,
        isPending: true,
      };
    case getHedgeOuncesSuccessActionType:
      return {
        isPending: false,
        data: action.payload.hedgeOunces,
      };
    case getHedgeOuncesFailureActionType:
      return {
        isPending: false,
        data: null,
      };
    case clearHedgeOuncesActionType:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};

export { getHedgeOuncesReducer };
