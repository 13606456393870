import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm, SubmissionError } from 'redux-form';

import { FieldInput, FieldRadioButton } from 'shared/components/Fields';
import { FieldCapitalized } from 'shared/components/Fields/FieldCapitalized/FieldCapitalized';
import { Form, Section } from 'shared/components/Form';
import { LOCATION } from 'shared/helpers/constants/locations/locationConstants';
import { cancelModal } from 'shared/helpers/constants/modalConstants';
import { snackbarMessageConstants } from 'shared/helpers/constants/snackbar/snackbarMessageConstants';
import { messageTypes } from 'shared/helpers/constants/snackbar/snackbarMessageTypes';
import { snackbarOptions } from 'shared/helpers/matchers/snackbar/snackbarOptions';
import { normalize } from 'shared/helpers/parsers/boolean';
import { asyncValidate } from 'shared/helpers/validations/location/asyncValidate';
import { validate } from 'shared/helpers/validations/location/locationValidations';

import { goBackOrTo } from 'utils/history';

import SelectPrimaryContact from './components/SelectPrimaryContact/SelectPrimaryContact';
import SelectCountry from './components/SelectRegion/SelectCountry';

function LocationFormLayout({
  handleSubmit,
  relatedCompany,
  invalid,
  submitting,
  touch,
  LocationForm,
  locationDetails,
  ...props
}) {
  const [zipCodeRequirement, setZipCodeRequirement] = useState(false);

  useEffect(() => {
    const specialCountries = ['US', 'CA'];

    function checkZipCodeRequirement(country) {
      return country ? 'required' : '';
    }

    if (LocationForm && LocationForm.values) {
      const country = LocationForm.values.country;
      setZipCodeRequirement(
        checkZipCodeRequirement(specialCountries.includes(country && country.value)),
      );
    }
  }, [LocationForm]);

  const modalOpen = () => {
    props.openModal(cancelModal, () => {
      goBackOrTo(`/companies/${props.companyId}`);
    });
  };

  const shipmentEligibleChange = value => {
    touch('primaryContact');

    if (normalize(value)) {
      props.showSnackbar(
        snackbarOptions(messageTypes.info),
        snackbarMessageConstants.shipment_eligibility_change,
      );
    } else {
      props.hideSnackbar();
    }
  };

  const onSubmit = useCallback(
    values => {
      try {
        return handleSubmit(values);
      } catch (err) {
        return new SubmissionError(err);
      }
    },
    [handleSubmit],
  );

  return (
    <Form
      onSubmit={onSubmit}
      onCancel={modalOpen}
      submitDisabled={invalid || submitting}
      header={LOCATION.LOCATION}
    >
      <Section template={['name .']}>
        <Field name="name" component={FieldInput} label={LOCATION.NAME} field="required" />
      </Section>
      <SelectCountry {...props} />
      <Section
        header={LOCATION.ADDRESS}
        unwrap
        template={['city .', 'street suite', 'zipCode poBox']}
      >
        <Field name="city" component={FieldInput} label={LOCATION.CITY} field="required" />
        <Field name="street" component={FieldInput} label={LOCATION.STREET} field="required" />
        <Field name="suite" component={FieldInput} label={LOCATION.SUITE} />
        <Field
          name="zipCode"
          component={FieldInput}
          label={LOCATION.ZIP_CODE}
          field={zipCodeRequirement}
        />
        <Field name="poBox" component={FieldInput} label={LOCATION.PO_BOX} />
      </Section>
      <Section
        header={LOCATION.ATTRIBUTES}
        unwrap
        template={['shipmentEligible primaryLocation', 'lotSuffix primaryContact']}
      >
        <Field
          name="shipmentEligible"
          component={FieldRadioButton}
          label={LOCATION.SHIPMENT_ELIGIBLE}
          field="required"
          normalize={normalize}
          onChange={shipmentEligibleChange}
        />
        <Field
          name="primaryLocation"
          component={FieldRadioButton}
          label={LOCATION.PRIMARY_LOCATION}
          field="required"
          normalize={normalize}
          disabled={locationDetails?.location?.primaryLocation && props.editMode}
        />
        <FieldCapitalized
          name="lotSuffix"
          component={FieldInput}
          label={LOCATION.LOT_SUFFIX}
          disabled={
            props.editMode && locationDetails.location && locationDetails.location.hasLotsOrHedges
          }
          field="unique required"
        />
        <SelectPrimaryContact name="primaryContact" {...props} relatedCompany={relatedCompany} />
      </Section>
    </Form>
  );
}

const mapStateToProps = ({ form, companyDetails }) => ({
  LocationForm: form.LocationForm,
  companyId: companyDetails.company && companyDetails.company._id,
});

const LocationForm = compose(
  connect(mapStateToProps),
  reduxForm({
    form: 'LocationForm',
    validate,
    asyncValidate,
    asyncBlurFields: ['lotSuffix'],
  }),
)(LocationFormLayout);

export { LocationForm };
