/* eslint-disable no-useless-escape */
/* eslint-disable no-control-regex */

const FLOAT_REGEX_GEN = decimals => new RegExp(`^-?\\d+((\\.|,)\\d{1,${decimals}})?$`);
const FLOAT_REGEX = FLOAT_REGEX_GEN(2);
const ONE_DECIMAL_REGEX = FLOAT_REGEX_GEN(1);
const THREE_DECIMAL_REGEX = FLOAT_REGEX_GEN(3);
const URL_REGEX =
  /^(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*))/i;
const EMAIL_REGEX =
  /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]){2,}|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])){2,})\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]){2,}|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])){2,})\.?$/i;
const PHONE_REGEX = /^([0-9()+ -]*)$/;
const MOBILE_PHONE_REGEX = /^\+[1-9]\d{6,15}$/;
const PHONE_EXTENSION_REGEX = /^[0-9]*$/i;
const ZIPCODE_CA_REGEX =
  /^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ]( )?\d[ABCEGHJKLMNPRSTVWXYZ]\d$/i;
const ZIPCODE_US_REGEX = /^\d{5}(?:[-\s]\d{4})?$/i;
const GLOBAL_MOISTURE_TEST_REGEX = /^-?\d+((\.|,)\d*)?$/;
const INTEGER_REGEX = /^-?\d+$/;
const POSITIVE_INTEGER_REGEX = /^\d+$/;
const INTEGER_WITHOUT_LEADING_ZEROES = /^([1-9]\d*|0)$/;
const VALIDATE_UTF_8 = () => /^((?!,)[\x20-\x7E])+$/g;
const LOWERCASE_CAPITAL_ALPHANUM_REGEX = /^[A-Za-z0-9]+$/;
const CAPITAL_ALPHANUM_REGEX = /^[A-Z0-9]+$/;
const CAPITAL_ALPHANUM_WHITE_SPACES_REGEX = /^[A-Z0-9]+( +[A-Z0-9]+)*$/;
const USER_PASSWORD =
  /^(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,15}/;
const PDF_REGEX = /\.pdf$/i;
const CUSTOM_LOT_NUMBER_REGEX = /^[A-Z0-9#-]+$/;
const PRICE_REGEX = /^-?(\d+|\d{1,3}(,\d{3})*)(\.\d{1,2})?$/;
const REQUESTED_PAYMENT_DATE_REGEX = /Requested payment date: (\d{2})\/(\d{2})\/(\d{4})$/i;
const FILE_EXTENSION = /\.[0-9A-Za-z]{2,4}$/;

export {
  URL_REGEX,
  EMAIL_REGEX,
  PHONE_REGEX,
  MOBILE_PHONE_REGEX,
  PHONE_EXTENSION_REGEX,
  ZIPCODE_CA_REGEX,
  ZIPCODE_US_REGEX,
  FLOAT_REGEX,
  FLOAT_REGEX_GEN,
  INTEGER_REGEX,
  INTEGER_WITHOUT_LEADING_ZEROES,
  VALIDATE_UTF_8,
  LOWERCASE_CAPITAL_ALPHANUM_REGEX,
  CAPITAL_ALPHANUM_REGEX,
  GLOBAL_MOISTURE_TEST_REGEX,
  THREE_DECIMAL_REGEX,
  ONE_DECIMAL_REGEX,
  USER_PASSWORD,
  PDF_REGEX,
  PRICE_REGEX,
  CUSTOM_LOT_NUMBER_REGEX,
  REQUESTED_PAYMENT_DATE_REGEX,
  FILE_EXTENSION,
  CAPITAL_ALPHANUM_WHITE_SPACES_REGEX,
  POSITIVE_INTEGER_REGEX,
};
