import {
  addCountSheetType,
  clearCountSheetType,
  uploadCountSheetType,
  uploadCountSheetSuccessType,
  uploadCountSheetFailureType,
} from 'actions/Lots/uploadCountSheet';

const INITIAL_STATE = {
  countSheet: null,
  isPending: false,
};

const uploadCountSheetReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case uploadCountSheetType:
      return {
        ...state,
        isPending: true,
      };
    case uploadCountSheetSuccessType:
    case uploadCountSheetFailureType:
      return {
        ...state,
        isPending: false,
      };
    case addCountSheetType:
      return {
        ...state,
        countSheet: action.payload.countSheet,
      };
    case clearCountSheetType:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};

export { uploadCountSheetReducer };
