import { statusesForGlobal } from 'shared/helpers/constants/lots/statusesForGlobal';
import { isGradingCompany } from 'shared/helpers/matchers/checkCompanyType';

const acceptLotForGradingAccess = (
  {
    access: {
      lots: { acceptForGrading },
    },
  },
  lot,
  topLotPriority,
) =>
  acceptForGrading &&
  lot.statusForGlobal === statusesForGlobal.received &&
  isGradingCompany(lot.sentFromCompany && lot.sentFromCompany.companyType) &&
  lot.gradedAt === null &&
  (topLotPriority ? topLotPriority.grgLotNumber === lot.grgLotNumber : lot.isTopGradingPriority);

export { acceptLotForGradingAccess };
