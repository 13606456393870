import PropTypes from 'prop-types';
import React, { useEffect, useCallback, useMemo, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Field, reduxForm, formValueSelector, reset, getFormValues } from 'redux-form';

import { clearTemporaryData, saveTemporaryData } from 'actions/Shipments/saveTemporaryData';

import { Button } from 'shared/components/Buttons';
import { FieldDate } from 'shared/components/Fields/FieldDate/FieldDate';
import { FieldInput } from 'shared/components/Fields/FieldInput/FieldInput';
import { FieldSearch } from 'shared/components/Fields/FieldSearch/FieldSearch';
import { FieldSelect } from 'shared/components/Fields/FieldSelect/FieldSelect';
import { Form } from 'shared/components/Form/Form/Form';
import { Section } from 'shared/components/Form/Section/Section';
import { Icon } from 'shared/components/Icon/Icon';
import { wizardContext } from 'shared/components/Wizard/Wizard';
import { cancelModal } from 'shared/helpers/constants/modalConstants';
import { PACKAGES } from 'shared/helpers/constants/packages/packageConstants';
import { PLACEHOLDERS } from 'shared/helpers/constants/placeholders';
import { SHIPMENTS } from 'shared/helpers/constants/shipments/shipmentsConstants';
import { validate } from 'shared/helpers/validations/shipments/createIncomingShipmentValidation';

import { refreshToken } from 'utils/refreshToken';

import { SelectAllPackages } from 'pages/Shipments/components/SelectAllPackages/SelectAllPackages';
import { PackingListSection } from 'pages/Shipments/components/ShipmentsManagement/CreateShipmentWizard/components/PackingListSection/PackingListSection';
import { CustomerPackagesOverview } from 'pages/Shipments/components/ShipmentsManagement/components/CustomerPackagesOverview/CustomerPackagesOverview';

const formName = 'IncomingShipmentForm';

const IncomingShipmentFormBase = props => {
  const {
    clearShipmentPackagesList,
    shipmentPackagesList,
    getCompaniesBySearchStr,
    handleSubmit,
    openModal,
    editMode,
    initialValues,
    touch,
    change,
    history,
    destroy,
    location,
    match,
    shipmentDetails,
    shipmentPackagesListData: { packages },
    valid,
    isPackingList,
    getCompaniesContact,
    resetSection,
  } = props;

  const selector = formValueSelector(formName);
  const dispatch = useDispatch();
  const wizard = useContext(wizardContext);
  const fieldName = isPackingList ? 'createShipment.' : '';

  const wizardData = useSelector(state => getFormValues('wizard')(state));

  const sentFromCompany = useSelector(state => state.createShipment.temporaryData?.sentFromCompany);
  const estPickupDate =
    useSelector(state => selector(state, 'estPickupDate')) ||
    wizardData?.createShipment?.estPickupDate;
  const estDeliveryDate =
    useSelector(state => selector(state, 'estDeliveryDate')) ||
    wizardData?.createShipment?.estDeliveryDate;
  const filteredCompany = useSelector(state => state.filteredCompanies.filteredCompanies[0]);
  const packagesOverviewAvailable =
    !shipmentDetails?.shipment?.sentFromCompany?.materialCountRequired ||
    (sentFromCompany?.value && !sentFromCompany?.materialCountRequired);

  const goToNextStep = () => {
    if (isPackingList) {
      if (location.state?.fromPackingListView) {
        history.replace('/shipments/create-incoming-wizard', {
          ...props.location.state,
          fromPackingListView: false,
        });
        wizard.next();
      }

      if (location.state?.fromEditPackingListView) {
        history.replace(
          `/shipments/edit-incoming-wizard/${props.location.state.assignedShipmentId}`,
          {
            ...props.location.state,
            fromPackingListView: false,
          },
        );
        wizard.next();
      }
    }
  };

  useEffect(() => () => !isPackingList && editMode && destroy(formName), []);

  useEffect(() => {
    if (isPackingList) {
      change(`${fieldName}sentFromCompany`, sentFromCompany);
      goToNextStep();
    } else {
      change(
        `${fieldName}sentFromCompany`,
        wizardData?.createShipment?.sentFromCompany ||
          sentFromCompany ||
          initialValues.sentFromCompany,
      );
      dispatch(reset('wizard'));
    }
  }, [location]);

  const handleOnFocus = () => {
    isPackingList && refreshToken();
  };

  const listSentFromCompanies = useCallback(
    input => {
      if (!input) return Promise.resolve({ options: [] });
      return getCompaniesBySearchStr(input, false);
    },
    [getCompaniesBySearchStr],
  );

  useEffect(() => {
    if (editMode) {
      Object.keys(initialValues).map(f => touch(f));
      listSentFromCompanies(initialValues.sentFromCompany.label);
    }

    if (initialValues.packages) {
      shipmentPackagesList(initialValues.packages);
    }
  }, [listSentFromCompanies, shipmentPackagesList, editMode, location]);

  const modalOpen = useCallback(() => {
    openModal(cancelModal, () => {
      destroy();
      clearShipmentPackagesList();
      dispatch(clearTemporaryData('sentFromCompany'));
      history.push(editMode ? `/shipments/incoming/${match.params.id}` : '/shipments');
    });
  }, [openModal, destroy, clearShipmentPackagesList, history, editMode, match]);

  const submitForm = useCallback(
    async e => {
      await handleSubmit(e);
    },
    [handleSubmit, destroy, clearShipmentPackagesList],
  );

  const onSentFromCompanyChange = useCallback(
    (company, value) => {
      clearShipmentPackagesList();
      dispatch(reset(formName));

      if (sentFromCompany?.value !== company?.value) {
        history.replace('/shipments/create-incoming');
      }

      if (value?.materialCountRequired) {
        history.push('/shipments/create-incoming-wizard');
      }

      dispatch(saveTemporaryData({ sentFromCompany: value }));
      resetSection(`${fieldName}sentFromCompanyLocation`);
    },
    [clearShipmentPackagesList, change, sentFromCompany],
  );

  const createPackagesAction = useMemo(
    () => (
      <Button
        alignToLeft
        onClick={() =>
          history.push('/shipments/packages/create', {
            fromShipmentForm: true,
            sentFromCompany: sentFromCompany || shipmentDetails?.shipment?.sentFromCompany,
            referenceUrl: location.pathname,
          })
        }
      >
        {PACKAGES.CREATE_PACKAGES}
        <Icon icon="icon-plus" />
      </Button>
    ),
    [sentFromCompany, history, location],
  );

  const companyLocations = useMemo(() => {
    if (sentFromCompany?.relatedLocations) {
      return sentFromCompany.relatedLocations;
    }
    return filteredCompany && editMode ? filteredCompany.relatedLocations : [];
  }, [filteredCompany, editMode, sentFromCompany]);

  const relatedCompany = useMemo(
    () => ({
      _id: sentFromCompany?.value,
      companyName: sentFromCompany?.label,
    }),
    [sentFromCompany],
  );

  const companyId = sentFromCompany?.value || shipmentDetails?.shipment?.sentFromCompany._id;

  return (
    <Form
      onSubmit={submitForm}
      onCancel={modalOpen}
      submitDisabled={props.invalid || props.submitting || (!isPackingList && !packages.length)}
      header={SHIPMENTS.SHIPMENT}
    >
      <Section
        template={[
          `${fieldName}sentFromCompany ${fieldName}sentFromCompanyLocation`,
          `${fieldName}estPickupDate ${fieldName}estDeliveryDate`,
          `${fieldName}shipmentCompany ${fieldName}shipmentSeal`,
        ]}
      >
        <Field
          name={`${fieldName}sentFromCompany`}
          component={FieldSearch}
          label={SHIPMENTS.SENT_FROM_COMPANY}
          field="required"
          getOptions={listSentFromCompanies}
          onChange={onSentFromCompanyChange}
          disabled={props.editMode || wizard?.editMode}
          onFocus={handleOnFocus}
        />
        <Field
          name={`${fieldName}sentFromCompanyLocation`}
          component={FieldSelect}
          options={wizard?.companyLocations || companyLocations}
          label={SHIPMENTS.SENT_FROM_COMPANY_LOCATION}
          placeholder={PLACEHOLDERS.SELECT}
          field="required"
          onFocus={handleOnFocus}
        />
        <Field
          name={`${fieldName}estPickupDate`}
          component={FieldDate}
          label={SHIPMENTS.EST_PICKUP_DATE}
          field="required"
          maxDate={estDeliveryDate}
          onFocus={handleOnFocus}
        />
        <Field
          name={`${fieldName}estDeliveryDate`}
          component={FieldDate}
          label={SHIPMENTS.EST_DELIVERY_DATE}
          field="required"
          minDate={estPickupDate}
          onFocus={handleOnFocus}
        />
        {editMode && (
          <Field
            name={`${fieldName}shipmentCompany`}
            component={FieldInput}
            label={SHIPMENTS.SHIPMENT_COMPANY}
            data-testid="shipmentCompany"
          />
        )}
        {editMode && (
          <Field
            name={`${fieldName}shipmentSeal`}
            component={FieldInput}
            label={SHIPMENTS.SHIPMENT_SEAL}
            data-testid="shipmentSeal"
          />
        )}
      </Section>
      {!isPackingList && (
        <Section
          header={<SelectAllPackages hasError={!packages.length && valid} />}
          actions={createPackagesAction}
          headerPadding="20px"
          template={['packages packages']}
          noPadding
        >
          {packagesOverviewAvailable && companyId && (
            <div name="packages">
              <CustomerPackagesOverview
                packageForCompany
                companyId={companyId}
                editMode={editMode}
                estPickupDate={estPickupDate}
                estDeliveryDate={estDeliveryDate}
                {...props}
              />
            </div>
          )}
        </Section>
      )}
      {isPackingList && (
        <PackingListSection
          fieldName={fieldName}
          relatedCompany={relatedCompany}
          change={change}
          getCompaniesContact={getCompaniesContact}
          handleOnFocus={handleOnFocus}
          setDefaultValues={wizard?.editMode}
        />
      )}
    </Form>
  );
};

const IncomingShipmentForm = reduxForm({
  form: formName,
  validate: (values, props) =>
    props.form === 'wizard'
      ? {
          createShipment: validate({ ...values.createShipment }, props),
        }
      : validate(values, props),

  destroyOnUnmount: false,
})(IncomingShipmentFormBase);

IncomingShipmentFormBase.propTypes = {
  CustomActions: PropTypes.func,
  anyTouched: PropTypes.bool,
  array: PropTypes.object,
  asyncValidate: PropTypes.func,
  asyncValidating: PropTypes.bool,
  auth: PropTypes.shape({
    access: PropTypes.object,
    attempts: PropTypes.number,
    isPending: PropTypes.bool,
    user: PropTypes.object,
  }),
  autofill: PropTypes.func,
  blur: PropTypes.func,
  change: PropTypes.func,
  clearAllPackagesOverview: PropTypes.func,
  clearAsyncError: PropTypes.func,
  clearFields: PropTypes.func,
  clearPackagesOverview: PropTypes.func,
  clearShipmentPackagesList: PropTypes.func,
  clearSubmit: PropTypes.func,
  clearSubmitErrors: PropTypes.func,
  closeModal: PropTypes.func,
  companyLocations: PropTypes.shape({
    data: PropTypes.array,
    isPending: PropTypes.bool,
  }),
  createIncomingShipment: PropTypes.func,
  destroy: PropTypes.func,
  dirty: PropTypes.bool,
  dispatch: PropTypes.func,
  error: PropTypes.oneOfType([PropTypes.object]),
  form: PropTypes.string,
  getAssignedLotsForOutgoingShipment: PropTypes.func,
  getCompaniesBySearchStr: PropTypes.func,
  getCompaniesContact: PropTypes.func,
  getCompanyLocations: PropTypes.func,
  getPackagesUnsentForShipments: PropTypes.func,
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.shape({
    createShipment: PropTypes.object,
    defaultPackagesValues: PropTypes.shape({
      oneOrMultiLots: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
      packagesQuantity: PropTypes.number,
    }),
    generatePackingListValues: PropTypes.shape({
      exportToExcel: PropTypes.bool,
      printPackageLabels: PropTypes.bool,
      printPackingList: PropTypes.bool,
    }),
  }),
  initialize: PropTypes.func,
  initialized: PropTypes.bool,
  invalid: PropTypes.bool,
  isPackingList: PropTypes.bool,
  location: PropTypes.object,
  match: PropTypes.object,
  modal: PropTypes.shape({
    modalCallback: PropTypes.oneOfType([PropTypes.func]),
    modalOpened: PropTypes.bool,
    modalOptions: PropTypes.shape({
      acceptButton: PropTypes.string,
      acceptColor: PropTypes.string,
      acceptIcon: PropTypes.string,
      cancelButton: PropTypes.string,
      cancelColor: PropTypes.string,
      cancelIcon: PropTypes.string,
      customActionColor: PropTypes.string,
      message: PropTypes.string,
    }),
  }),
  onSubmit: PropTypes.func,
  openModal: PropTypes.func,
  packagesOverviewList: PropTypes.shape({
    errorMessage: PropTypes.oneOfType([PropTypes.object]),
    isPending: PropTypes.bool,
    packagesList: PropTypes.object,
  }),
  pristine: PropTypes.bool,
  pure: PropTypes.bool,
  reset: PropTypes.func,
  resetSection: PropTypes.func,
  shipmentPackagesList: PropTypes.func,
  shipmentPackagesListData: PropTypes.shape({
    packages: PropTypes.array,
  }),
  showSnackbar: PropTypes.func,
  staticConext: PropTypes.oneOfType([PropTypes.object]),
  submit: PropTypes.func,
  submitAsSideEffect: PropTypes.bool,
  submitFailed: PropTypes.bool,
  submitSucceeded: PropTypes.bool,
  submitting: PropTypes.bool,
  touch: PropTypes.func,
  triggerSubmit: PropTypes.oneOfType([PropTypes.func]),
  untouch: PropTypes.func,
  valid: PropTypes.bool,
  validate: PropTypes.func,
  warning: PropTypes.oneOfType([PropTypes.object]),
};

export { IncomingShipmentForm, IncomingShipmentFormBase };
