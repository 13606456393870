import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';
import styled from 'styled-components';

import { CancelButton, SubmitButton } from 'shared/components/Buttons';
import { FieldUpload } from 'shared/components/Fields/FiledUpload/FieldUpload';
import { FieldUploadList } from 'shared/components/Fields/FiledUpload/FieldUploadList';
import { Preloader } from 'shared/components/Preloader/Preloader';
import { AGREEMENTS } from 'shared/helpers/constants/agreements/agreementsConstants';
import { validate } from 'shared/helpers/validations/agreement/documentValidations';

import { StyledAgreementUploadSigned } from './StyledAgreementUploadSigned';

const SubmitContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 35px;
`;

class AgreementUploadSigned extends Component {
  componentWillUnmount() {
    this.props.agreementDocumentClearAction();
  }

  onFileInputChange = e => {
    const filesArray = [].slice.call(e.target.files);
    this.props.agreementDocumentAddedAction(filesArray);
  };

  render() {
    const { agreementsUploadDocument, closeModal } = this.props;

    return (
      <React.Fragment>
        <Preloader
          loading={agreementsUploadDocument.isPending}
          label={AGREEMENTS.UPLOAD_SIGNED_PROGRESS}
        />
        <StyledAgreementUploadSigned noValidate onSubmit={this.props.handleSubmit}>
          <Field
            name="signedDocument"
            type="file"
            component={FieldUpload}
            label={AGREEMENTS.UPLOAD_SIGNED}
            validExtensions=".pdf"
            onChange={this.onFileInputChange}
          />
          {agreementsUploadDocument.files && (
            <FieldUploadList
              previewIcoInside
              files={[
                {
                  file: agreementsUploadDocument.files[0],
                },
              ]}
            />
          )}
          <SubmitContainer>
            <CancelButton onClick={closeModal} />
            <SubmitButton
              label={AGREEMENTS.UPLOAD}
              disabled={!agreementsUploadDocument.files || !this.props.valid}
            />
          </SubmitContainer>
        </StyledAgreementUploadSigned>
      </React.Fragment>
    );
  }
}

const uploadForm = reduxForm({
  form: 'UploadSignedAgreement',
  validate,
})(AgreementUploadSigned);

export { uploadForm as AgreementUploadSigned, AgreementUploadSigned as TestAgreementUploadSigned };
