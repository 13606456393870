import React, { Component } from 'react';

import { UIAuthorization } from 'components/Authorization/UIAuthorization';

import { GroupButtons } from 'shared/components/Buttons';
import { GroupButton } from 'shared/components/Buttons/GroupButton/components/GroupButton';
import { RouterButton } from 'shared/components/Buttons/RouterButton/RouterButton';
import { Icon } from 'shared/components/Icon/Icon';
import { OverviewHeader } from 'shared/components/OverviewHeader/OverviewHeader';
import { OverviewList } from 'shared/components/OverviewList/OverviewList';
import { OverviewListWrapper } from 'shared/components/OverviewListWrapper/OverviewListWrapper';
import { SearchComponent } from 'shared/components/Search/Search';
import { createLotsAccessButtonHandler } from 'shared/helpers/accesses/createLotsAccessHandler';
import {
  materialDeliveryRequiredActionsTemplate,
  materialDeliveryRequiredMobileTemplate,
  materialDeliveryRequiredTableTemplate,
  materialDeliveryRequiredDetailsTableTemplate,
  lotItemActionsTemplate,
  lotItemMobileTemplate,
  customerLotItemTableTemplate,
  customerLotItemDetailsTableTemplate,
  gradingLotItemTableTemplate,
} from 'shared/helpers/constants/lots/customerLotsListTableConfig';
import {
  customerLotsSections,
  customerLotsSectionsArray,
} from 'shared/helpers/constants/lots/customerLotsSections';
import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';
import { customerSectionsEnum, gradingSectionsEnum } from 'shared/helpers/constants/lots/sections';
import { POOL_ACCOUNTS } from 'shared/helpers/constants/poolAccounts/poolAccountsConstants';
import { isTablet } from 'shared/helpers/constants/resolutionsConstants';
import { SHARED } from 'shared/helpers/constants/sharedConstants';
import { isAssayCompany } from 'shared/helpers/matchers/checkCompanyType';
import { isCustomerUser, isGradingUser } from 'shared/helpers/matchers/checkUserType';
import { lotsSections } from 'shared/helpers/matchers/lots/checkLotsSection';
import { humanizeCamelCase } from 'shared/helpers/parsers/text';
import { cssVar } from 'shared/helpers/styling/styling';

import { debounce } from 'utils/debounce';
import { flatten } from 'utils/flatten';
import { getBrowserWidth } from 'utils/responsive';

import { CustomerLotsSection } from './LotsSection/CustomerLotsSection/CustomerLotsSection';

const customerItemsTemplate = [
  {
    label: humanizeCamelCase(customerSectionsEnum.open),
    render: ({ open }) => open,
  },
  {
    label: humanizeCamelCase(customerSectionsEnum.materialDeliveryRequired),
    render: ({ materialDeliveryRequired }) => materialDeliveryRequired,
  },
  {
    label: humanizeCamelCase(customerSectionsEnum.received),
    render: ({ received }) => received,
  },
  {
    label: humanizeCamelCase(customerSectionsEnum.summaryAvailable),
    render: ({ summaryAvailable }) => summaryAvailable,
  },
  {
    label: humanizeCamelCase(customerSectionsEnum.settled),
    render: ({ settled }) => settled,
  },
  {
    label: humanizeCamelCase(customerSectionsEnum.finalized),
    render: ({ finalized }) => finalized,
  },
];

const gradingItemsTemplate = [
  {
    label: humanizeCamelCase(gradingSectionsEnum.open),
    render: ({ open }) => open,
  },
  {
    label: humanizeCamelCase(gradingSectionsEnum.received),
    render: ({ received }) => received,
  },
  {
    label: humanizeCamelCase(gradingSectionsEnum.receiptReady),
    render: ({ receiptReady }) => receiptReady,
  },
  {
    label: humanizeCamelCase(gradingSectionsEnum.receiptAccepted),
    render: ({ receiptAccepted }) => receiptAccepted,
  },
  {
    label: humanizeCamelCase(gradingSectionsEnum.finalized),
    render: ({ finalized }) => finalized,
  },
];

const itemsTemplateMobileAllLots = [
  {
    label: LOTS.ALL_LOTS,
    render: () => null,
  },
];

const allLotsActionsTemplate = [];

class CustomerLotsSections extends Component {
  state = {
    windowWidth: getBrowserWidth(),
  };

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
    this.props.countLots();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
    this.props.clearLotsCount();
    customerLotsSectionsArray.map(section => this.props.clearLotsSectionList(section));
  }

  handleWindowSizeChange = () => {
    this.setState({
      windowWidth: getBrowserWidth(),
    });
  };

  renderOverviewList(
    input,
    getList,
    label = 'ooo',
    itemsTemplate,
    itemsTemplateMobile,
    actionsItemTemplate,
    itemDetailsTemplate,
  ) {
    const { windowWidth } = this.state;
    const { auth } = this.props;
    return (
      <OverviewList
        getList={getList}
        input={input}
        itemsTemplate={itemsTemplate}
        itemsTemplateMobile={itemsTemplateMobile}
        itemDetailsTemplate={itemDetailsTemplate}
        actionsTemplate={actionsItemTemplate}
        isMobile={windowWidth < isTablet}
        auth={auth}
        label={label}
      />
    );
  }

  userAccess = (userType, section) => lotsSections(userType).includes(section);

  returnTemplate = userType => {
    if (isCustomerUser(userType)) return customerLotItemTableTemplate;
    if (isGradingUser(userType)) return gradingLotItemTableTemplate;
  };

  render() {
    const { access, user } = this.props.auth;
    const { getLotsSection } = this.props;
    const lotsSectionsItems = this.props.lotsSections.items;

    const sectionTemplates = [
      {
        ...customerLotsSections.open,
        render: this.renderOverviewList(
          {
            items: lotsSectionsItems.open.items,
            isPending: this.props.lotsSections.isPending,
          },
          (page, limit) =>
            getLotsSection(user.relatedCompany._id, customerLotsSections.open.name, limit, page),
          customerLotsSections.open.title,
          this.returnTemplate(user.userType),
          lotItemMobileTemplate,
          lotItemActionsTemplate,
          customerLotItemDetailsTableTemplate,
        ),
        tableAccess: this.userAccess(user.userType, customerLotsSections.open.name),
      },
      {
        ...customerLotsSections.materialDeliveryRequired,
        render: this.renderOverviewList(
          {
            items: lotsSectionsItems.materialDeliveryRequired.items,
            isPending: this.props.lotsSections.isPending,
          },
          (page, limit) =>
            getLotsSection(
              user.relatedCompany._id,
              customerLotsSections.materialDeliveryRequired.name,
              limit,
              page,
            ),
          customerLotsSections.materialDeliveryRequired.title,
          materialDeliveryRequiredTableTemplate,
          materialDeliveryRequiredMobileTemplate,
          materialDeliveryRequiredActionsTemplate,
          materialDeliveryRequiredDetailsTableTemplate,
        ),
        tableAccess: this.userAccess(
          user.userType,
          customerLotsSections.materialDeliveryRequired.name,
        ),
      },
      {
        ...customerLotsSections.receivedByGlobal,
        render: this.renderOverviewList(
          {
            items: lotsSectionsItems.received.items,
            isPending: this.props.lotsSections.isPending,
          },
          (page, limit) =>
            getLotsSection(
              user.relatedCompany._id,
              customerLotsSections.receivedByGlobal.name,
              limit,
              page,
            ),
          customerLotsSections.receivedByGlobal.title,
          this.returnTemplate(user.userType),
          lotItemMobileTemplate,
          lotItemActionsTemplate,
          customerLotItemDetailsTableTemplate,
        ),
        tableAccess: this.userAccess(user.userType, customerLotsSections.receivedByGlobal.name),
      },
      {
        ...customerLotsSections.summaryAvailable,
        render: this.renderOverviewList(
          {
            items: lotsSectionsItems.summaryAvailable.items,
            isPending: this.props.lotsSections.isPending,
          },
          (page, limit) =>
            getLotsSection(
              user.relatedCompany._id,
              customerLotsSections.summaryAvailable.name,
              limit,
              page,
            ),
          customerLotsSections.summaryAvailable.title,
          customerLotItemTableTemplate,
          lotItemMobileTemplate,
          lotItemActionsTemplate,
          customerLotItemDetailsTableTemplate,
        ),
        tableAccess: this.userAccess(user.userType, customerLotsSections.summaryAvailable.name),
      },
      {
        ...customerLotsSections.settled,
        render: this.renderOverviewList(
          {
            items: lotsSectionsItems.settled.items,
            isPending: this.props.lotsSections.isPending,
          },
          (page, limit) =>
            getLotsSection(user.relatedCompany._id, customerLotsSections.settled.name, limit, page),
          customerLotsSections.settled.title,
          customerLotItemTableTemplate,
          lotItemMobileTemplate,
          lotItemActionsTemplate,
          customerLotItemDetailsTableTemplate,
        ),
        tableAccess: this.userAccess(user.userType, customerLotsSections.settled.name),
      },
      {
        ...customerLotsSections.receiptReady,
        render: this.renderOverviewList(
          {
            items: lotsSectionsItems.receiptReady.items,
            isPending: this.props.lotsSections.isPending,
          },
          (page, limit) =>
            getLotsSection(
              user.relatedCompany._id,
              customerLotsSections.receiptReady.name,
              limit,
              page,
            ),
          customerLotsSections.receiptReady.title,
          gradingLotItemTableTemplate,
          lotItemMobileTemplate,
          lotItemActionsTemplate,
          customerLotItemDetailsTableTemplate,
        ),
        tableAccess: this.userAccess(user.userType, customerLotsSections.receiptReady.name),
      },
      {
        ...customerLotsSections.receiptAccepted,
        render: this.renderOverviewList(
          {
            items: lotsSectionsItems.receiptAccepted.items,
            isPending: this.props.lotsSections.isPending,
          },
          (page, limit) =>
            getLotsSection(
              user.relatedCompany._id,
              customerLotsSections.receiptAccepted.name,
              limit,
              page,
            ),
          customerLotsSections.receiptAccepted.title,
          gradingLotItemTableTemplate,
          lotItemMobileTemplate,
          lotItemActionsTemplate,
          customerLotItemDetailsTableTemplate,
        ),
        tableAccess: this.userAccess(user.userType, customerLotsSections.receiptAccepted.name),
      },
      {
        ...customerLotsSections.finalized,
        render: this.renderOverviewList(
          {
            items: lotsSectionsItems.finalized.items,
            isPending: this.props.lotsSections.isPending,
          },
          (page, limit) =>
            getLotsSection(
              user.relatedCompany._id,
              customerLotsSections.finalized.name,
              limit,
              page,
            ),
          customerLotsSections.finalized.title,
          this.returnTemplate(user.userType),
          lotItemMobileTemplate,
          lotItemActionsTemplate,
          customerLotItemDetailsTableTemplate,
        ),
        tableAccess: this.userAccess(user.userType, customerLotsSections.finalized.name),
      },
    ];

    const searchLots = debounce(value => {
      const { history } = this.props;
      value.overview.searchInput &&
        history.push('lots/list', { searchLots: value.overview.searchInput });
    }, 600);

    const sectionActionButtons = (
      <GroupButtons>
        <GroupButton
          id="openCreateLots"
          access={createLotsAccessButtonHandler(
            access.lots.create,
            this.props.companyLocations.data,
            user.relatedCompany.status,
          )}
          onClick={() => this.props.history.push('/lots/create')}
          icon="new-lot"
          label={LOTS.CREATE_A_LOT}
        />
        <GroupButton
          id="requestSettlementForLot"
          access={access.poolAccounts.requestSettlement.forLot}
          onClick={() => this.props.history.push('pool-accounts/settlement-for-lot')}
          label={POOL_ACCOUNTS.SETTLE_LOTS}
          icon="close-lot"
        />
        <GroupButton
          id="requestSettlementMockUp"
          access={
            isCustomerUser(this.props.auth.user.userType) &&
            isAssayCompany(this.props.auth.user.relatedCompany.companyType) &&
            access.poolAccounts.requestSettlement.mockUp
          }
          onClick={() => this.props.history.push('pool-accounts/settlement-mock-up')}
          label={POOL_ACCOUNTS.SETTLEMENT_MOCK_UP}
          icon="settlement-mock-up"
        />
      </GroupButtons>
    );

    const listActionButtons = (
      <>
        <UIAuthorization access={access.lots.list}>
          <SearchComponent handleChange={searchLots} nonPersistent label="overview" />
        </UIAuthorization>
        <UIAuthorization access={access.lots.list}>
          <RouterButton path="/lots/list" outlineColor={cssVar('pelorous')}>
            {SHARED.DETAILS}
            <Icon icon="icon-tiny-arrow-right" />
          </RouterButton>
        </UIAuthorization>
      </>
    );

    const count = Object.entries(this.props.lotsSections.lotsCount).reduce(
      (acc, [name, section]) => ({
        ...acc,
        [name]: flatten(Object.values(section)).length,
      }),
      { _id: 0 },
    );

    const total = Object.values(count).reduce((acc, length) => acc + length, 0);

    const list = {
      items: {
        docs: [count],
        total: total || 1,
      },
    };

    const template = isCustomerUser(user.userType) ? customerItemsTemplate : gradingItemsTemplate;

    return (
      <>
        <OverviewHeader header={LOTS.LOTS} actions={sectionActionButtons} />
        <OverviewListWrapper
          label={LOTS.ALL_LOTS}
          list={list}
          itemsTemplate={template}
          itemsTemplateMobile={itemsTemplateMobileAllLots}
          actionsTemplate={allLotsActionsTemplate}
          button={listActionButtons}
          windowWidth={this.state.windowWidth}
          displayAllDetails
        />
        {sectionTemplates.map(({ name, render, tableAccess }) => (
          <UIAuthorization access={tableAccess} key={name}>
            <CustomerLotsSection section={name} {...this.props}>
              {render}
            </CustomerLotsSection>
          </UIAuthorization>
        ))}
      </>
    );
  }
}

export { CustomerLotsSections };
