import {
  getProfitLossDataActionType,
  getProfitLossDataFailureActionType,
  getProfitLossDataSuccessActionType,
  clearProfitLossDataActionType,
} from 'actions/Lots/getProfitLossData';

const INITIAL_STATE = {
  isPending: false,
  data: null,
};

const profitLossDataReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getProfitLossDataActionType:
      return {
        ...state,
        isPending: true,
      };
    case getProfitLossDataSuccessActionType:
      return {
        data: action.payload.data,
        isPending: false,
      };
    case getProfitLossDataFailureActionType:
      return {
        ...state,
        isPending: false,
      };
    case clearProfitLossDataActionType:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};

export { profitLossDataReducer };
