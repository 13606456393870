import { resolveVersion, versions } from 'utils/versioning';

const BUILDING = resolveVersion({
  [versions.alpha]: /[A-C]/,
  [versions.global]: /[A-C]/,
  [versions.alpha]: /[A-C]/,
  [versions.west]: /[A]/,
});
const SECTION = resolveVersion({
  [versions.alpha]: 30,
  [versions.global]: 30,
  [versions.alpha]: 30,
  [versions.west]: 10,
});
const LANE = resolveVersion({
  [versions.alpha]: /[A-Z]/,
  [versions.global]: /[A-Z]/,
  [versions.alpha]: /[A-Z]/,
  [versions.west]: /[A-J]/,
});

const lotLocationSuffix = resolveVersion({
  [versions.alpha]: {
    building: 'A-C',
    section: '1-30',
    lane: 'A-Z',
  },
  [versions.global]: {
    building: 'A-C',
    section: '1-30',
    lane: 'A-Z',
  },
  [versions.alpha]: {
    building: 'A-C',
    section: '1-30',
    lane: 'A-Z',
  },
  [versions.west]: {
    building: 'A',
    section: '1-10',
    lane: 'A-J',
  },
});

export { BUILDING, SECTION, LANE, lotLocationSuffix };
