import { useSelector } from 'react-redux';

import { renderDetailsContainer } from 'shared/components/Details/helpers/renderDetailsContainer';
import { isInternalCompany } from 'shared/helpers/matchers/checkCompanyType';
import { isGlobalUserType } from 'shared/helpers/matchers/isGlobalUserType';

import {
  activityContainerConfig,
  activityFieldsConfig,
  packageDetailsContainerConfig,
  packageDetailsFieldsConfig,
  weightsContainerConfig,
  weightsFieldsConfig,
} from 'pages/Packages/components/PackageDetails/fieldsConfig';

import { PackageMainDetails } from '../PackageMainDetails/PackageMainDetails';

export const PackageDetailsForm = () => {
  const { package: pkg } = useSelector(state => state.packageDetails);
  const auth = useSelector(state => state.auth);

  const userFromInternalCompany = isInternalCompany(auth.user.relatedCompany.companyType);

  return (
    <div className="DetailsComponent">
      <PackageMainDetails pkg={pkg} auth={auth} />
      {renderDetailsContainer(packageDetailsContainerConfig, packageDetailsFieldsConfig, pkg, auth)}
      {(pkg.weightGrossDeclared ||
        isGlobalUserType(auth.user.userType) ||
        userFromInternalCompany) &&
        renderDetailsContainer(weightsContainerConfig, weightsFieldsConfig, pkg, auth)}
      {(isGlobalUserType(auth.user.userType) || userFromInternalCompany) &&
        renderDetailsContainer(activityContainerConfig, activityFieldsConfig, pkg)}
    </div>
  );
};
