import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const releasePackageType = 'RELEASE_PACKAGE';
const releasePackageAction = () => ({
  type: releasePackageType,
});

const releasePackageSuccessType = `${releasePackageType}_SUCCESS`;
const releasePackageSuccessAction = () => ({
  type: releasePackageSuccessType,
});

const releasePackageFailureType = `${releasePackageType}_FAILURE`;
const releasePackageFailureAction = () => ({
  type: releasePackageFailureType,
});

const releasePackage = (lotId, packageId, machineId) => dispatch => {
  dispatch(releasePackageAction());

  return axios
    .patch(`${API_HOST_AUTH}/packages/release/${lotId}/${packageId}`, {
      machineId,
    })
    .then(res => {
      dispatch(releasePackageSuccessAction());

      return res.data;
    })
    .catch(err => {
      dispatch(releasePackageFailureAction());

      return Promise.reject(err.data);
    });
};

export { releasePackageType, releasePackageSuccessType, releasePackageFailureType, releasePackage };
