import {
  getMarketIndicatorsLogsType,
  getMarketIndicatorsLogsSuccessType,
  getMarketIndicatorsLogsFailureType,
  clearMarketIndicatorsLogsActionType,
} from 'actions/MarketIndicators/getMarketIndicatorsLogs';

const INITIAL_STATE = {
  isPending: false,
  logs: null,
};

const getMarketIndicatorsLogsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getMarketIndicatorsLogsType:
      return {
        isPending: true,
        logs: null,
      };
    case getMarketIndicatorsLogsSuccessType:
      return {
        isPending: false,
        logs: action.payload.logs,
      };
    case clearMarketIndicatorsLogsActionType:
    case getMarketIndicatorsLogsFailureType:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};

export { getMarketIndicatorsLogsReducer };
