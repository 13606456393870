import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { getPDFDocument } from 'actions/Files/getPDFDocument';

import { LinkButton } from 'shared/components/Buttons';
import { PreviewButton } from 'shared/components/Buttons/PreviewButton';
import { Icon } from 'shared/components/Icon/Icon';
import { isNumberOrString } from 'shared/helpers/parsers/formaters';
import { cssVar, media } from 'shared/helpers/styling/styling';
import { PDF_REGEX, FILE_EXTENSION } from 'shared/helpers/validations/regularExpressions';

import { DETAILS_CONSTANTS } from '../constants/detailsConstants';
import {
  DetailsContent,
  FileActions,
  IconWrapper,
  StyledWarningMessage,
} from './StyledDetailsField';

const parseFieldValue = (value, prefix, suffix) =>
  isNumberOrString(value)
    ? `${prefix || ''} ${value} ${suffix || ''}`
    : DETAILS_CONSTANTS.EMPTY_FIELD;

const DetailsFieldLayout = ({
  label,
  value,
  prefix,
  suffix,
  icon,
  iconColor,
  iconOpacity,
  className,
  onClick,
  id,
  customContent,
  download,
  forwardable,
  fileId,
  previewIsAvailable,
  manualCreationWarning,
  containerStyles,
  ...props
}) => {
  const dispatch = useDispatch();
  const handleShow = id => {
    dispatch(getPDFDocument(id || fileId));
  };

  return (
    <div className={className} id={id}>
      <IconWrapper>
        <Icon icon={icon} color={iconColor} opacity={iconOpacity} />
      </IconWrapper>
      <DetailsContent {...props} value={value} customContent={customContent}>
        {label && <div>{label}</div>}
        <div>
          {customContent ||
            (download && value && onClick ? (
              <FileActions>
                <LinkButton
                  label={value}
                  onClick={onClick}
                  download={download}
                  forwardable={forwardable}
                  margin="0 8px 0 0"
                  data-testid={`${id}File`}
                />
                {(PDF_REGEX.test(download.originalName) ||
                  !FILE_EXTENSION.test(download.originalName)) &&
                previewIsAvailable ? (
                  <PreviewButton
                    sectionDocuments
                    handleClick={() => handleShow(download._id)}
                    dataTestId={`${id}Preview`}
                  />
                ) : null}
                {manualCreationWarning && (
                  <StyledWarningMessage>{manualCreationWarning}</StyledWarningMessage>
                )}
              </FileActions>
            ) : (
              parseFieldValue(value, prefix, suffix)
            ))}
        </div>
      </DetailsContent>
    </div>
  );
};

const DetailsField = styled(DetailsFieldLayout)`
  grid-area: ${({ id }) => id};
  display: flex;
  align-items: center;
  padding: 34px 10px 30px 34px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  word-break: break-word;
  ${media.mobile`
    padding: 16px 4px 15px 17px;
`}

  @media print {
    padding: 0px;
    border: none;
  }

  ${({ containerStyles }) => containerStyles}
`;

DetailsField.propTypes = {
  manualCreationWarning: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.any,
  iconOpacity: PropTypes.number,
  emptyOpacity: PropTypes.number,
  iconColor: PropTypes.string,
  previewIsAvailable: PropTypes.bool,
  fileId: PropTypes.string,
  labelColor: PropTypes.string,
  valueColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  downloadColor: PropTypes.string,
  downloadHoverColor: PropTypes.string,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  download: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        orginalName: PropTypes.string,
        path: PropTypes.string,
        _id: PropTypes.string.isRequired,
        type: PropTypes.string,
        uploadAt: PropTypes.number,
      }),
    ),
    PropTypes.shape({
      orginalName: PropTypes.string,
      path: PropTypes.string,
      _id: PropTypes.string.isRequired,
      type: PropTypes.string,
      uploadAt: PropTypes.number,
    }),
  ]),
  forwardable: PropTypes.bool,
  onClick: PropTypes.func,
  customContent: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  containerStyles: PropTypes.object,
};

DetailsField.defaultProps = {
  iconOpacity: 0.25,
  emptyOpacity: 0.38,
  iconColor: cssVar('alto'),
  labelColor: cssVar('alto'),
  valueColor: cssVar('alto'),
  backgroundColor: cssVar('limedSpruceDetails'),
  downloadColor: cssVar('tangerine'),
  downloadHoverColor: cssVar('atlantis'),
  manualCreationWarning: false,
};

export { DetailsField };
