const setInitialUserFiltersActionType = 'USER_FILTERS_SET_INITIAL';
function setInitialUserFiltersAction() {
  return {
    type: setInitialUserFiltersActionType,
  };
}

const userStatusFilterActionType = 'USER_STATUS_ACTION';
function userStatusFilterAction(userStatus) {
  return {
    type: userStatusFilterActionType,
    payload: {
      userStatus,
    },
  };
}

const userTypeFilterActionType = 'USER_TYPE_ACTION';
function userTypeFilterAction(userType) {
  return {
    type: userTypeFilterActionType,
    payload: {
      userType,
    },
  };
}

const accessLevelFilterActionType = 'ACCESS_LEVEL_ACTION';
function accessLevelFilterAction(accessLevel) {
  return {
    type: accessLevelFilterActionType,
    payload: {
      accessLevel,
    },
  };
}

const userFiltersClearActionType = 'USER_FILTERS_CLEAR_ACTION';
function userFiltersClearAction() {
  return {
    type: userFiltersClearActionType,
  };
}

function setInitialUserFilters() {
  return dispatch => {
    dispatch(setInitialUserFiltersAction());
  };
}

function userStatusFilter(userStatus) {
  return dispatch => {
    dispatch(userStatusFilterAction(userStatus));
  };
}

function userTypeFilter(userType) {
  return dispatch => {
    dispatch(userTypeFilterAction(userType));
  };
}

function accessLevelFilter(accessLevel) {
  return dispatch => {
    dispatch(accessLevelFilterAction(accessLevel));
  };
}

function userFiltersClear() {
  return dispatch => {
    dispatch(userFiltersClearAction());
  };
}

export {
  userStatusFilterActionType,
  userTypeFilterActionType,
  accessLevelFilterActionType,
  userFiltersClearActionType,
  setInitialUserFiltersActionType,
  userStatusFilter,
  userTypeFilter,
  accessLevelFilter,
  userFiltersClear,
  setInitialUserFilters,
};
