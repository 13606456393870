function addConfirmation(values, parser = v => v) {
  return Object.entries(values).reduce((acc, [key, value]) => {
    const parsedValue = parser(value);

    return {
      ...acc,
      [key]: parsedValue,
      [`${key}Confirm`]: parsedValue,
    };
  }, {});
}

function removeConfirmation(values) {
  return Object.entries(values)
    .filter(([key]) => !key.match(/\w+Confirm$/))
    .reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: value,
      }),
      {},
    );
}

export { addConfirmation, removeConfirmation };
