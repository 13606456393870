import React, { Component } from 'react';

import { GroupButtons } from 'shared/components/Buttons';
import { GroupButton } from 'shared/components/Buttons/GroupButton/components/GroupButton';
import { FullPageList } from 'shared/components/FullPageList/FullPageList';
import { message } from 'shared/helpers/constants/hedges/createRecipientsConstants';
import { HEDGES } from 'shared/helpers/constants/hedges/hedgesConstants';
import {
  itemsTemplate,
  itemsTemplateMobile,
  itemsDetailsTemplate,
} from 'shared/helpers/constants/hedges/recipientsTableConfig';
import { deleteRecipientModal } from 'shared/helpers/constants/modalConstants';
import {
  successOptions,
  dangerOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

class Recipients extends Component {
  componentWillUnmount() {
    this.props.clearRecipients();
  }

  deleteRecipient(recipient) {
    const { getRecipients, deleteRecipient, showSnackbar } = this.props;

    deleteRecipient(recipient)
      .then(() => {
        getRecipients();
        showSnackbar(successOptions, message.RECIPIENTS_DELETE_SUCCESS);
      })
      .catch(() => showSnackbar(dangerOptions, message.RECIPIENTS_DELETE_ERROR));
  }

  renderActionButtons = () => (
    <GroupButtons>
      <GroupButton
        className="Recipients-create"
        access={this.props.auth.access.hedges.recipients.list}
        onClick={() => this.props.history.push('/hedges/recipients/create')}
        label={HEDGES.RECIPIENTS.CHANGE}
        icon="add-hedge-recipient"
      />
    </GroupButtons>
  );

  render() {
    const { recipientsList, openModal } = this.props;
    const actions =
      recipientsList.recipients.length > 1
        ? [
            {
              icon: 'bin',
              action: 'Delete',
              onClick: recipient => () =>
                openModal(deleteRecipientModal, () => this.deleteRecipient(recipient)),
            },
          ]
        : [];

    return (
      <FullPageList
        title={HEDGES.RECIPIENTS.TITLE}
        actionButtons={this.renderActionButtons}
        itemsTemplate={itemsTemplate}
        itemsTemplateMobile={itemsTemplateMobile}
        itemsDetailsTemplate={itemsDetailsTemplate}
        actionsContext={this}
        actionsTemplate={actions}
        items={recipientsList.recipients}
        fetchList={this.props.getRecipients}
        isPending={recipientsList.isPending}
      />
    );
  }
}

export { Recipients };
