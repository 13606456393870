import {
  getReportsType,
  getReportsSuccessType,
  getReportsFailureType,
} from 'actions/Reports/getReports';

const INITIAL_STATE = {
  isPending: false,
  reports: [],
};

const reportsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getReportsType:
      return {
        isPending: true,
      };
    case getReportsSuccessType:
      return {
        isPending: false,
        reports: [...action.payload.reports],
      };
    case getReportsFailureType:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};

export { reportsReducer };
