import {
  generateReportActionType,
  generateReportSuccessActionType,
  generateReportFailureActionType,
} from 'actions/Companies/generateReport';

const INITIAL_STATE = {
  isPending: false,
};

const generateReportReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case generateReportActionType:
      return { isPending: true };
    case generateReportSuccessActionType:
    case generateReportFailureActionType:
      return { isPending: false };
    default:
      return state;
  }
};

export { generateReportReducer };
