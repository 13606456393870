import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const markAsReadActionType = 'MARK_AS_READ';
function markAsReadAction() {
  return {
    type: markAsReadActionType,
  };
}

const markAsReadSuccessActionType = 'MARK_AS_READ_SUCCESS';
function markAsReadSuccessAction(notification) {
  return {
    type: markAsReadSuccessActionType,
    payload: {
      notification,
    },
  };
}

const markAsReadFailureActionType = 'MARK_AS_READ_FAILURE';
function markAsReadFailureAction(errorMessage) {
  return {
    type: markAsReadFailureActionType,
    payload: {
      errorMessage,
    },
  };
}

function markAsRead(notificationId) {
  return dispatch => {
    dispatch(markAsReadAction());

    return axios
      .put(`${API_HOST_AUTH}/notifications/${notificationId}/mark-as-read`)
      .then(res => {
        dispatch(markAsReadSuccessAction());
        return res.data;
      })
      .catch(error => dispatch(markAsReadFailureAction(error)));
  };
}

export {
  markAsReadActionType,
  markAsReadSuccessActionType,
  markAsReadFailureActionType,
  markAsRead,
};
