const CLIENT_FACING_UPLOADS_CONSTANTS = {
  DOCUMENTS_ALREADY_INSPECTED: ({ isMultiple }) =>
    `Document${isMultiple ? 's' : ''} already approved/declined.`,
  SUCCESS_APPROVE: ({ isMultiple }) => `Document${isMultiple ? 's' : ''} approved.`,
  SUCCESS_REJECTED: ({ isMultiple }) => `Document${isMultiple ? 's' : ''} rejected.`,
  PENDING: 'PENDING',
  REJECTED: 'REJECTED',
  ACCEPTED: 'ACCEPTED',
};

export { CLIENT_FACING_UPLOADS_CONSTANTS };
