import styled from 'styled-components';

import { Button } from 'shared/components/Buttons/Button';
import { cssVar, media } from 'shared/helpers/styling/styling';

const PreferencesFooter = styled.div`
  display: flex;
  justify-content: space-between;
  background: ${cssVar('outerSpaceBrighter')};
  padding: 32px 47px;
  ${media.mobile`padding: 10px;`}

  ${Button}:only-of-type {
    margin-left: auto;
  }
`;

export { PreferencesFooter };
