import styled from 'styled-components';

import { media } from 'shared/helpers/styling/styling';

const BtnGroup = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  height: 150px;

  ${media.mobile`
    padding: 0 25px;
  `};
`;

const Paragraph = styled.p`
  font-size: 0.875rem;
  color: var(--gullGray);
  font-weight: 400;
  text-align: center;
  padding-top: 0.5rem;
`;

export { BtnGroup, Paragraph };
