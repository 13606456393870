import React, { useEffect, useCallback } from 'react';

import { InlineError } from 'shared/components/InlineError/InlineError';
import { NoResults } from 'shared/components/NoResults/NoResults';
import { OverviewList } from 'shared/components/OverviewList/OverviewList';
import { overviewPackageClasses } from 'shared/helpers/constants/packages/packageClasses';
import { PACKAGES } from 'shared/helpers/constants/packages/packageConstants';
import { isTablet } from 'shared/helpers/constants/resolutionsConstants';
import {
  itemsTemplate,
  itemsTemplateMobile,
  itemsDetailsTemplate,
  actionsTemplate,
} from 'shared/helpers/constants/shipments/customerPackagesOverviewTableConfig';
import { SHIPMENTS } from 'shared/helpers/constants/shipments/shipmentsConstants';
import { isGlobalUserType } from 'shared/helpers/matchers/isGlobalUserType';
import { useWindowWidth } from 'shared/hooks/useWindowWidth';

const CustomerPackagesOverview = props => {
  const {
    packagesOverviewList: { packagesList },
    clearPackagesOverview,
    shipmentDetails,
    getPackagesUnsentForShipments,
    getPackagesUnsentWithShipments,
    editMode,
    packageForCompany,
    companyId,
    auth: { user },
    match,
  } = props;
  const windowWidth = useWindowWidth();

  const getPackagesOverview = useCallback(
    (page, listLimit) => {
      if (editMode && packageForCompany) {
        getPackagesUnsentWithShipments(
          companyId || user.relatedCompany._id,
          shipmentDetails.shipment._id,
          listLimit,
          overviewPackageClasses.incoming,
        );
      } else if (packageForCompany) {
        getPackagesUnsentForShipments(
          companyId || user.relatedCompany._id,
          listLimit,
          overviewPackageClasses.incoming,
        );
      } else {
        getPackagesUnsentForShipments(match.params.id, listLimit, overviewPackageClasses.incoming);
      }
    },
    [
      companyId,
      user,
      match,
      editMode,
      packageForCompany,
      shipmentDetails,
      getPackagesUnsentForShipments,
      getPackagesUnsentWithShipments,
    ],
  );

  useEffect(() => {
    clearPackagesOverview(overviewPackageClasses.incoming);
  }, [clearPackagesOverview, getPackagesOverview]);

  useEffect(() => {
    isGlobalUserType(user.userType) && getPackagesOverview(1, 0);
  }, [companyId, getPackagesOverview]);

  return (
    <>
      <OverviewList
        input={packagesList}
        getList={getPackagesOverview}
        itemsTemplate={itemsTemplate}
        itemsTemplateMobile={itemsTemplateMobile}
        itemDetailsTemplate={itemsDetailsTemplate}
        actionsTemplate={actionsTemplate}
        isMobile={windowWidth < isTablet}
        label={PACKAGES.PACKAGES}
        listLimit="0"
        actionsContext={{ props }}
        dashboard={isGlobalUserType(props.auth.user.userType)}
      />
      {packagesList.items && !packagesList.items.total && (
        <>
          <NoResults label={PACKAGES.PACKAGES} />
          <InlineError error={SHIPMENTS.ERROR.MUST_HAVE_PACKAGE} />
        </>
      )}
    </>
  );
};

export { CustomerPackagesOverview };
