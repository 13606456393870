import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { batch } from 'react-redux';
import { css } from 'styled-components';

import { Toggle } from 'components/Toggle/Toggle';

import { DetailsTabs } from 'shared/components/Details/DetailsTabs';
import { ListCollapse } from 'shared/components/ListCollapse/ListCollapse';
import {
  OverviewListSection,
  OverviewListSectionWrapper,
} from 'shared/components/OverviewList/OverviewListSection/OverviewListSection';
import { packageClasses } from 'shared/helpers/constants/packages/packageClasses';
import { PACKAGES } from 'shared/helpers/constants/packages/packageConstants';
import { humanize } from 'shared/helpers/parsers/text';
import { cssVar } from 'shared/helpers/styling/styling';

import { StyledGlobalPackagesOverviewItem } from './GlobalPackagesOverviewItem/GlobalPackagesOverviewItem';

export const GlobalPackagesOverview = props => {
  const {
    lotId,
    clearPackagesOverview,
    packagesOverviewList: { packagesList },
  } = props;

  useEffect(
    () => () => {
      batch(() => {
        clearPackagesOverview(packageClasses.post_mix);
        clearPackagesOverview(packageClasses.post_shears);
        clearPackagesOverview(packageClasses.post_mill);
        clearPackagesOverview(packageClasses.incoming);
      });
    },
    [lotId, clearPackagesOverview],
  );

  const totalCount = useMemo(() => {
    if (
      !(
        packagesList.incoming &&
        packagesList.incoming.total !== undefined &&
        packagesList['post-mill'] &&
        packagesList['post-mill'].total !== undefined &&
        packagesList['post-shears'] &&
        packagesList['post-shears'].total !== undefined &&
        packagesList['post-mix'] &&
        packagesList['post-mix'].total !== undefined
      )
    ) {
      return {};
    }

    return {
      total:
        packagesList.incoming.total +
        packagesList['post-mill'].total +
        packagesList['post-shears'].total +
        packagesList['post-mix'].total,
      totalIncoming: packagesList.incoming.total,
      totalPostMill: packagesList['post-mill'].total,
      totalPostShears: packagesList['post-shears'].total,
      totalPostMix: packagesList['post-mix'].total,
    };
  }, [packagesList]);

  const [display, setDisplay] = useState(true);
  const toggleDisplay = useCallback(() => {
    if (totalCount.total) {
      setDisplay(state => !state);
    }
  }, [totalCount]);

  const filterItems = item => {
    const { docs } = packagesList.incoming;

    const sortedArr = [...new Set(docs.map(p => p.receivedAt))];

    return (
      (sortedArr.indexOf(item.receivedAt) + 1) % 2 === 0 &&
      css`
        background-color: ${cssVar('nevadaDarker')};
      `
    );
  };

  const tabsConfig = [
    {
      tabName: humanize(packageClasses.incoming),
      tabContent: {
        template: [
          'incomingPackagesList incomingPackagesList incomingPackagesList incomingPackagesList',
        ],
        fieldsConfig: [
          {
            ...props,
            itemFilter: filterItems,
            sectionType: packageClasses.incoming,
            packagesList: packagesList.incoming,
            label: packageClasses.incoming,
            id: 'incomingPackagesList',
            Component: StyledGlobalPackagesOverviewItem,
          },
        ],
      },
      total: totalCount.totalIncoming,
    },
    {
      tabName: humanize(packageClasses.post_mill),
      tabContent: {
        template: [
          'postMillPackagesList postMillPackagesList postMillPackagesList postMillPackagesList',
        ],
        fieldsConfig: [
          {
            ...props,
            sectionType: packageClasses.post_mill,
            packagesList: packagesList[packageClasses.post_mill],
            label: packageClasses.post_mill,
            id: 'postMillPackagesList',
            Component: StyledGlobalPackagesOverviewItem,
          },
        ],
      },
      total: totalCount.totalPostMill,
    },
    {
      tabName: humanize(packageClasses.post_shears),
      tabContent: {
        template: [
          'postShearsPackagesList postShearsPackagesList postShearsPackagesList postShearsPackagesList',
        ],
        fieldsConfig: [
          {
            ...props,
            sectionType: packageClasses.post_shears,
            packagesList: packagesList[packageClasses.post_shears],
            label: packageClasses.post_shears,
            id: 'postShearsPackagesList',
            suffix: PACKAGES.SELECT,
            Component: StyledGlobalPackagesOverviewItem,
          },
        ],
      },
      total: totalCount.totalPostShears,
    },
    {
      tabName: humanize(packageClasses.post_mix),
      tabContent: {
        template: [
          'postMixPackagesList postMixPackagesList postMixPackagesList postMixPackagesList',
        ],
        fieldsConfig: [
          {
            ...props,
            sectionType: packageClasses.post_mix,
            packagesList: packagesList[packageClasses.post_mix],
            label: packageClasses.post_mix,
            id: 'postMixPackagesList',
            suffix: PACKAGES.SELECT,
            Component: StyledGlobalPackagesOverviewItem,
          },
        ],
      },
      total: totalCount.totalPostMix,
    },
  ];
  const total = totalCount.total === undefined ? 0 : totalCount.total;

  return (
    <OverviewListSectionWrapper isOpen={display} total={total}>
      <OverviewListSection isOpen={display} total={total}>
        <div>
          <ListCollapse collapse={display} toggleList={toggleDisplay} label={PACKAGES.PACKAGES} />
        </div>
      </OverviewListSection>
      <Toggle visible={!!total}>
        <DetailsTabs listView tabsConfig={tabsConfig} />
      </Toggle>
    </OverviewListSectionWrapper>
  );
};

GlobalPackagesOverview.propTypes = {
  packagesOverviewList: PropTypes.object,
  lotId: PropTypes.string,
  getPackagesOverviewForLots: PropTypes.func,
  clearPackagesOverview: PropTypes.func,
  auth: PropTypes.object,
  match: PropTypes.object,
};
