import {
  getAvailablePaymentTypesActionType,
  getAvailablePaymentTypesSuccessActionType,
  getAvailablePaymentTypesFailureActionType,
  clearAvailablePaymentTypesActionType,
} from 'actions/Payments/getAvailablePaymentTypes';

const INITIAL_STATE = {
  isPending: false,
  data: [],
};

const AvailablePaymentTypesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getAvailablePaymentTypesActionType:
      return {
        ...state,
        isPending: true,
      };
    case getAvailablePaymentTypesSuccessActionType:
      return {
        data: action.payload.data,
        isPending: false,
      };
    case getAvailablePaymentTypesFailureActionType:
      return {
        ...state,
        isPending: false,
      };
    case clearAvailablePaymentTypesActionType:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};

export { AvailablePaymentTypesReducer };
