import React from 'react';
import styled, { keyframes, css } from 'styled-components';

import { SHARED } from 'shared/helpers/constants/sharedConstants';
import { cssVar, pxToRem } from 'shared/helpers/styling/styling';

function ListAppendLayout({ onClick, className }) {
  return (
    <div className={className} onClick={onClick}>
      <div>{SHARED.LIST_APPEND}</div>
    </div>
  );
}

const rotate = keyframes`
  from {
   transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(360deg);
  }
`;

const rotateAfter = keyframes`
  from {
   transform: translateX(-${pxToRem(51)}) rotateZ(0deg);
  }
  to {
    transform: translateX(-${pxToRem(51)}) rotateZ(360deg);
  }
`;

const ListAppend = styled(ListAppendLayout)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${pxToRem(81)};
  background-color: ${cssVar('outerSpaceListAppend')};
  color: ${cssVar('whiteGRG')};
  font-size: ${pxToRem(14)};
  font-weight: 500;
  cursor: pointer;
  border: 1px solid ${cssVar('riverBedDarker')};

  @media print {
    border: none;
  }

  & > div {
    margin-left: ${pxToRem(32)};
  }

  &:after {
    content: '';
    position: absolute;
    transform: translateX(-${pxToRem(51)});
    width: ${pxToRem(16)};
    height: 2px;
    background-color: ${cssVar('whiteGRG')};
    ${({ loading }) =>
      loading &&
      css`
        animation: ${rotateAfter} 0.6s linear infinite;
      `};
  }

  &:before {
    content: '';
    width: 2px;
    height: ${pxToRem(16)};
    background-color: ${cssVar('whiteGRG')};
    ${({ loading }) =>
      loading &&
      css`
        animation: ${rotate} 0.6s linear infinite;
      `};
  }
`;

export { ListAppend };
