import { useFormik } from 'formik';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { transferBlanketHedgeOunces } from 'actions/Deliveries/transferBlanketHedgeOunces';
import { openModal } from 'actions/shared/modal';
import { showSnackbar } from 'actions/shared/snackbar';

import { Wizard } from 'shared/components/Formik/Wizard/Wizard';
import { HEDGES } from 'shared/helpers/constants/hedges/hedgesConstants';
import { cancelModal } from 'shared/helpers/constants/modalConstants';
import {
  dangerOptions,
  successOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

import { TransferOuncesForm } from './TransferOuncesForm/TransferOuncesForm';
import { TransferOuncesWizardSchema } from './TransferOuncesWizardSchemaYup';

const TransferOuncesWizard = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { hedgeId } = useParams();

  const backToHedgeDetails = () => history.push(`/hedges/${hedgeId}`);

  const handleSubmit = async values => {
    try {
      const result = await dispatch(
        transferBlanketHedgeOunces({
          deliveryId: values.delivery,
          lotId: values.relatedLot._id,
        }),
      );

      dispatch(showSnackbar(successOptions, result.message));
    } catch (err) {
      dispatch(showSnackbar(dangerOptions, err.message));
    } finally {
      backToHedgeDetails();
    }
  };

  const formikContext = useFormik({
    initialValues: {
      delivery: '',
      relatedLot: '',
    },
    validationSchema: TransferOuncesWizardSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  });

  const stepErrors = [
    [formikContext.errors.delivery, formikContext.errors.holidayName],
    [formikContext.errors.relatedLot],
  ];

  const handleCancel = useCallback(() => {
    dispatch(
      openModal(cancelModal, () => {
        history.push(`/hedges/${hedgeId}`);
      }),
    );
  }, [dispatch]);

  return (
    <Wizard header={HEDGES.TRANSFER_BLANKET_HEDGE_OUNCES} onCancel={handleCancel}>
      <TransferOuncesForm formikContext={formikContext} stepErrors={stepErrors} />
    </Wizard>
  );
};

export { TransferOuncesWizard };
