import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Container } from 'reactstrap';
import styled from 'styled-components';

import { getPoolAccounts } from 'actions/PoolAccounts/poolAccountsList';
import { showSnackbar } from 'actions/shared/snackbar';

import { cssVar } from 'shared/helpers/styling/styling';

import { Navbar } from './Navbar';

const NavbarContainer = styled.div`
  background-color: ${({ backgroundColor }) => cssVar(backgroundColor)};
  padding-left: calc(100vw - 100%);
  @media print {
    display: none;
  }
`;

const ConnectedNavbar = ({ backgroundColor, ...props }) => (
  <NavbarContainer backgroundColor={backgroundColor}>
    <Container>
      <Navbar {...props} />
    </Container>
  </NavbarContainer>
);

ConnectedNavbar.defaultProps = {
  backgroundColor: 'fiordLighter',
};

const mapStateToProps = ({ auth, poolAccountsList }) => ({
  auth,
  poolAccountsList,
});

const mapDispatchToProps = {
  getPoolAccounts,
  showSnackbar,
};

const connectedNavbar = withRouter(connect(mapStateToProps, mapDispatchToProps)(ConnectedNavbar));

export { connectedNavbar as Navbar };
