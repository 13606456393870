import React from 'react';

import { StatusIndicator } from 'shared/components/StatusIndicator/StatusIndicator';
import { troyOuncesRemaining } from 'shared/helpers/dataParsers/deliveries/troyOuncesRemaining';
import { parseOpenStatus } from 'shared/helpers/dataParsers/hedges/parseOpenStatus';
import { isTooltipVisible } from 'shared/helpers/matchers/isTooltipVisible';
import { formatDate, formatToDollarWithPrefix, formatToUS } from 'shared/helpers/parsers/formaters';
import { replaceEmpty, humanize } from 'shared/helpers/parsers/text';
import { cssVar } from 'shared/helpers/styling/styling';

import { HEDGES } from './hedgesConstants';

const troyOuncesTemplate = {
  label: HEDGES.TROY_OUNCES_HEDGED,
  render: ({ platinumOz, palladiumOz, rhodiumOz }) => (
    <>
      <span data-testid="troyOuncesHedged">
        {`${HEDGES.PT} ${formatToUS(platinumOz, 3)}`}
        <br />
        {`${HEDGES.PD} ${formatToUS(palladiumOz, 3)}`}
        <br />
        {`${HEDGES.RH} ${formatToUS(rhodiumOz, 3)}`}
      </span>
    </>
  ),
};

const troyOuncesRemainingTemplate = {
  label: HEDGES.TROY_OUNCES_REMAINING,
  render: hedge => {
    const { platinumOz, palladiumOz, rhodiumOz } = troyOuncesRemaining(hedge);

    return (
      <>
        <span data-testid="troyOuncesRemaining">
          {`${HEDGES.PT} ${formatToUS(platinumOz, 3)}`}
          <br />
          {`${HEDGES.PD} ${formatToUS(palladiumOz, 3)}`}
          <br />
          {`${HEDGES.RH} ${formatToUS(rhodiumOz, 3)}`}
        </span>
      </>
    );
  },
  bgColor: cssVar('cuttySarkTable'),
};

const hedgePricesTemplate = {
  label: HEDGES.HEDGE_PRICES,
  render: ({ platinumPriceCust, palladiumPriceCust, rhodiumPriceCust }) =>
    platinumPriceCust !== null || palladiumPriceCust !== null || rhodiumPriceCust !== null ? (
      <>
        <span data-testid="troyOuncesHedgedPrices">
          {formatToDollarWithPrefix(platinumPriceCust)}
          <br />
          {formatToDollarWithPrefix(palladiumPriceCust)}
          <br />
          {formatToDollarWithPrefix(rhodiumPriceCust)}
        </span>
      </>
    ) : (
      '-'
    ),
};

const itemsTemplateCustomer = [
  {
    label: HEDGES.STATUS,
    render: ({ status, requestedHedgeDate, _id }) => {
      const currentStatus = parseOpenStatus({ status, requestedHedgeDate });

      return (
        <StatusIndicator
          status={currentStatus}
          tooltipContent={isTooltipVisible(requestedHedgeDate, currentStatus)}
          id={_id}
        />
      );
    },
  },
  {
    label: HEDGES.HEDGE_NAME,
    render: ({ hedgeName }) => hedgeName,
    highlight: true,
  },
  {
    label: HEDGES.RELATED_LOT,
    render: ({ relatedLot }) => {
      const customLotNumber = relatedLot?.customLotNumber;
      const grgLotNumber = relatedLot?.grgLotNumber;
      return (!customLotNumber && replaceEmpty(grgLotNumber)) || [grgLotNumber, customLotNumber];
    },
    highlight: true,
  },
  troyOuncesTemplate,
  hedgePricesTemplate,
  {
    label: HEDGES.EST_UNITS_WEIGHT,
    render: ({ materialUnits, materialWeight }) =>
      materialUnits
        ? `${formatToUS(materialUnits, 0)} ${HEDGES.UNITS}`
        : `${replaceEmpty(formatToUS(materialWeight))} ${HEDGES.LBS}`,
  },
  {
    label: HEDGES.DELIVERY_DATE,
    render: ({ deliveryDate }) => replaceEmpty(formatDate(deliveryDate)),
  },
];

const itemsCustomerExistingHedgesTemplate = isGlobal => {
  return [
    {
      label: HEDGES.STATUS,
      render: ({ status, requestedHedgeDate, _id }) => {
        const currentStatus = parseOpenStatus({ status, requestedHedgeDate });

        return (
          <StatusIndicator
            status={currentStatus}
            tooltipContent={isTooltipVisible(requestedHedgeDate, currentStatus)}
            id={_id}
          />
        );
      },
    },
    {
      label: HEDGES.HEDGE_NAME,
      render: ({ hedgeName }) => hedgeName,
      highlight: true,
    },
    troyOuncesTemplate,
    hedgePricesTemplate,
    {
      label: HEDGES.EST_UNITS_WEIGHT,
      render: ({ materialUnits, materialWeight }) =>
        materialUnits
          ? `${formatToUS(materialUnits, 0)} ${HEDGES.UNITS}`
          : `${replaceEmpty(formatToUS(materialWeight))} ${HEDGES.LBS}`,
    },
    {
      label: isGlobal ? HEDGES.DELIVERY_DATE_GRG : HEDGES.DELIVERY_DATE_CUSTOMER,
      render: ({ deliveryDate, deliveryDateGRG }) =>
        replaceEmpty(formatDate(isGlobal ? deliveryDateGRG : deliveryDate)),
    },
  ];
};

const itemsCustomerExistingBlanketHedgesTemplate = isGlobal => {
  return [
    {
      label: HEDGES.STATUS,
      render: ({ status, requestedHedgeDate, _id }) => {
        const currentStatus = parseOpenStatus({ status, requestedHedgeDate });

        return (
          <StatusIndicator
            status={currentStatus}
            tooltipContent={isTooltipVisible(requestedHedgeDate, currentStatus)}
            id={_id}
          />
        );
      },
    },
    {
      label: HEDGES.HEDGE_NAME,
      render: ({ hedgeName }) => hedgeName,
      highlight: true,
    },
    troyOuncesTemplate,
    troyOuncesRemainingTemplate,
    hedgePricesTemplate,
    {
      label: HEDGES.EST_UNITS_WEIGHT,
      render: ({ materialUnits, materialWeight }) =>
        materialUnits
          ? `${formatToUS(materialUnits, 0)} ${HEDGES.UNITS}`
          : `${replaceEmpty(formatToUS(materialWeight))} ${HEDGES.LBS}`,
    },
    {
      label: isGlobal ? HEDGES.DELIVERY_DATE_GRG : HEDGES.DELIVERY_DATE_CUSTOMER,
      render: ({ deliveryDate, deliveryDateGRG }) =>
        replaceEmpty(formatDate(isGlobal ? deliveryDateGRG : deliveryDate)),
    },
  ];
};

const itemsOwnerExistingHedgesTemplate = isGlobal => {
  return [
    ...itemsCustomerExistingHedgesTemplate(isGlobal),
    {
      label: HEDGES.PLACED_ON,
      render: ({ placedAt }) => replaceEmpty(formatDate(placedAt)),
    },
  ];
};

const itemsOwnerExistingBlanketHedgesTemplate = isGlobal => {
  return [
    ...itemsCustomerExistingBlanketHedgesTemplate(isGlobal),
    {
      label: HEDGES.PLACED_ON,
      render: ({ placedAt }) => replaceEmpty(formatDate(placedAt)),
    },
  ];
};

const itemsTemplate = [
  {
    label: HEDGES.STATUS,
    render: ({ status, requestedHedgeDate, _id }) => {
      const currentStatus = parseOpenStatus({ status, requestedHedgeDate });

      return (
        <StatusIndicator
          status={currentStatus}
          tooltipContent={isTooltipVisible(requestedHedgeDate, currentStatus)}
          id={_id}
        />
      );
    },
  },
  {
    label: HEDGES.HEDGE_TYPE,
    render: ({ hedgeType }) => humanize(hedgeType),
  },
  {
    label: HEDGES.HEDGE_NAME,
    render: ({ hedgeName }) => hedgeName,
    className: 'HedgesList__item--no-break',
    highlight: true,
  },
  {
    label: HEDGES.RELATED_LOT,
    render: ({ relatedLot }) => {
      const customLotNumber = relatedLot?.customLotNumber;
      const grgLotNumber = relatedLot?.grgLotNumber;
      return (!customLotNumber && replaceEmpty(grgLotNumber)) || [grgLotNumber, customLotNumber];
    },
    className: 'HedgesList__item--no-break',
    highlight: true,
  },
  troyOuncesTemplate,
  hedgePricesTemplate,
  {
    label: HEDGES.DELIVERY_DATE_CUSTOMER,
    render: ({ deliveryDate }) => formatDate(deliveryDate),
  },
  {
    label: HEDGES.PLACED_ON,
    render: ({ placedAt }) => replaceEmpty(formatDate(placedAt)),
  },
];

const itemsTemplateMobile = [
  {
    label: HEDGES.STATUS,
    render: ({ status, requestedHedgeDate, _id }) => {
      const currentStatus = parseOpenStatus({ status, requestedHedgeDate });

      return (
        <StatusIndicator
          status={currentStatus}
          tooltipContent={isTooltipVisible(requestedHedgeDate, currentStatus)}
          id={_id}
        />
      );
    },
  },
  {
    label: HEDGES.HEDGE_NAME,
    render: ({ hedgeName }) => hedgeName,
    highlight: true,
  },
];

const itemsDetailsTemplate = [
  {
    label: HEDGES.HEDGE_TYPE,
    render: ({ hedgeType }) => humanize(hedgeType),
  },
  {
    label: HEDGES.RELATED_LOT,
    render: ({ relatedLot }) => {
      const customLotNumber = relatedLot?.customLotNumber;
      const grgLotNumber = relatedLot?.grgLotNumber;
      return (!customLotNumber && replaceEmpty(grgLotNumber)) || [grgLotNumber, customLotNumber];
    },
    className: 'HedgesList__item--no-break',
    highlight: true,
  },
  troyOuncesTemplate,
  hedgePricesTemplate,
  {
    label: HEDGES.DELIVERY_DATE_CUSTOMER,
    render: ({ deliveryDate }) => replaceEmpty(formatDate(deliveryDate)),
  },
  {
    label: HEDGES.PLACED_ON,
    render: ({ placedAt }) => replaceEmpty(formatDate(placedAt)),
  },
];

const [, , ...itemsDetailsTemplateCustomer] = itemsTemplateCustomer;

const actionsTemplateCustomer = downloadAttachment => {
  const actionsTemplate = [
    {
      icon: 'clip icon-clip',
      action: 'Download',
      access: hedge => hedge.hedgeOrderDocument,
      onClick: hedge => () => downloadAttachment(hedge.hedgeOrderDocument.path),
    },
  ];

  actionsTemplate.access = function access() {
    return (
      this.props.hedgesList.hedges.docs.length &&
      this.props.hedgesList.hedges.docs.some(e => e.hedgeOrderDocument)
    );
  };

  return actionsTemplate;
};

export {
  itemsTemplate,
  itemsTemplateCustomer,
  itemsTemplateMobile,
  itemsDetailsTemplate,
  itemsDetailsTemplateCustomer,
  actionsTemplateCustomer,
  hedgePricesTemplate,
  troyOuncesTemplate,
  itemsCustomerExistingHedgesTemplate,
  itemsCustomerExistingBlanketHedgesTemplate,
  itemsOwnerExistingHedgesTemplate,
  itemsOwnerExistingBlanketHedgesTemplate,
};
