import PropTypes from 'prop-types';
import * as React from 'react';
import { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import { getCompany, clearCompanyDetails } from 'actions/Companies/companyDetails';
import { updateOutstandingBalance } from 'actions/Companies/updateOutstandingBalance';

import { FormContainer } from 'shared/components/Form';
import { COMPANY } from 'shared/helpers/constants/companies/companyConstants';
import { removeCommasAndParse } from 'shared/helpers/parsers/text';

import { UpdateOutstandingBalanceForm } from './UpdateOutstandingBalanceForm/UpdateOutstandingBalanceForm';

const UpdateOutstandingBalanceLayout = ({ match, history }) => {
  const dispatch = useDispatch();

  const { isPending } = useSelector(({ companyDetails }) => companyDetails);

  const goToPreviousPath = match => `/companies/${match.params.id}`;

  useEffect(() => {
    dispatch(getCompany(match.params.id)).catch(() => history.push(goToPreviousPath(match)));

    return () => dispatch(clearCompanyDetails());
  }, [match.params.id]);

  const onSubmit = ({ outstandingBalance }) =>
    dispatch(
      updateOutstandingBalance(match.params.id, {
        outstandingBalance: removeCommasAndParse(outstandingBalance),
      }),
    ).finally(() => history.push(goToPreviousPath(match)));

  return (
    <FormContainer header={COMPANY.UPDATE_OUTSTANDING_BALANCE_TITLE} loaded={!isPending}>
      <UpdateOutstandingBalanceForm onSubmit={onSubmit} />
    </FormContainer>
  );
};
const mapStateToProps = state => ({
  companyDetails: state.companyDetails,
});

const mapDispatchToProps = {
  getCompany,
};

UpdateOutstandingBalanceLayout.propTypes = {
  history: PropTypes.object,
  match: PropTypes.shape({
    isExact: PropTypes.bool,
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
    path: PropTypes.string,
    url: PropTypes.string,
  }),
};

const UpdateOutstandingBalance = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UpdateOutstandingBalanceLayout);

export { UpdateOutstandingBalance };
