import {
  getLotsForCommodityType,
  getLotsForCommoditySuccessType,
  getLotsForCommodityFailureType,
} from 'actions/Commodities/getLotsForCommodity';

const INITIAL_STATE = {
  isPending: false,
  lots: [],
};

const getLotsForCommodityReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getLotsForCommodityType:
      return {
        isPending: true,
        lots: [],
      };
    case getLotsForCommoditySuccessType:
      return {
        isPending: false,
        lots: action.payload.lots,
      };
    case getLotsForCommodityFailureType:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};

export { getLotsForCommodityReducer };
