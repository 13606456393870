const companyTypesEnum = {
  assay: 'assay',
  grading: 'grading',
  internal: 'internal',
  external: 'external',
};

const companyTypes = Object.values(companyTypesEnum);

export { companyTypesEnum, companyTypes };
