import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const markProcessingCompleteType = 'MARK_PROCESSING_COMPLETE';
const markProcessingCompleteAction = () => ({
  type: markProcessingCompleteType,
});

const markProcessingCompleteSuccessType = `${markProcessingCompleteType}_SUCCESS`;
const markProcessingCompleteSuccessAction = lot => ({
  type: markProcessingCompleteSuccessType,
  payload: {
    lot,
  },
});

const markProcessingCompleteFailureType = `${markProcessingCompleteType}_FAILURE`;
const markProcessingCompleteFailureAction = () => ({
  type: markProcessingCompleteFailureType,
});

const markProcessingComplete = lotId => dispatch => {
  dispatch(markProcessingCompleteAction());

  return axios
    .put(`${API_HOST_AUTH}/lots/mark-processing-complete/${lotId}`)
    .then(res => {
      dispatch(markProcessingCompleteSuccessAction(res.data.lot));

      return res.data;
    })
    .catch(err => {
      dispatch(markProcessingCompleteFailureAction());

      return Promise.reject(err.data);
    });
};

export {
  markProcessingCompleteType,
  markProcessingCompleteSuccessType,
  markProcessingCompleteFailureType,
  markProcessingComplete,
};
