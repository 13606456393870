import styled from 'styled-components';

import { Button } from 'shared/components/Buttons/Button';
import { media } from 'shared/helpers/styling/styling';

const ActionsContainer = styled.div`
  display: flex;

  ${Button}:not(:last-child) {
    margin-right: 10px;
  }

  ${media.phone`
     flex-direction: column;
     width: 100%;

      ${Button}:not(:last-child) {
        margin: 0 0 14px 0;
        justify-content: flex-start;
        padding-left: 40px;
      }
  `}
`;

export { ActionsContainer };
