import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';

import { getWarehouseData } from 'actions/Lots/getWarehouseDashboardData';
import { updateLotColor, updateVirtualLotColor } from 'actions/Lots/updateColor';
import { showSnackbar } from 'actions/shared/snackbar';

import {
  successOptions,
  dangerOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { COLORS } from 'shared/helpers/constants/warehouseDashboard/labels';
import { cssVar, media } from 'shared/helpers/styling/styling';
import { useClickAway } from 'shared/hooks/useClickAway';

const ColorMenuWrapper = styled.div`
  position: relative;
`;

const Btn = styled.button`
  padding: 6px;
  border: none;
  background: ${({ isOpen }) => (isOpen ? cssVar('outerSpaceBrighter') : 'transparent')};
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border: 1px solid transparent;
  z-index: 2;
  position: relative;

  ${({ isOpen }) =>
    isOpen &&
    css`
      border: 1px solid ${cssVar('lotColorHoverBg')};
      border-top-color: transparent;
    `}
`;

const ColorMenu = styled.div`
  display: none;
  position: absolute;
  bottom: calc(100% - 1px);
  right: -30px;
  background: ${cssVar('outerSpaceBrighter')};
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 3px 4px;
  border: 1px solid ${cssVar('lotColorHoverBg')};

  ${({ isOpen }) =>
    isOpen &&
    css`
      display: flex;
    `}
  ${media.smallDesktop`
        right: -20px
    `}
`;

const ColorPos = styled.div`
  width: 24px;
  min-width: 24px;
  height: 24px;
  display: block;
  border: 2px solid ${cssVar('whiteGRG')};
  border-radius: 50%;
  background-color: ${({ bgColor }) => bgColor && `${COLORS[bgColor].color}`};
`;

const ChangeBtn = styled.button`
  padding: 2px;
  margin: 2px 3px;
  border: none;
  background: transparent;

  &:hover {
    background: ${cssVar('lotColorHoverBg')};
  }
`;

const colorsArr = [COLORS.GRAY.name, COLORS.RED.name, COLORS.ORANGE.name, COLORS.GREEN.name];

const LotColor = ({ lotId, currentColor, isVirtual }) => {
  const dispatch = useDispatch();
  const [isOpen, toggleMenu] = useState(false);
  const ref = useRef(null);

  useClickAway(ref, () => toggleMenu(false));

  const updateColor = (color, id, isVirtual) => {
    const lotAction = isVirtual ? updateVirtualLotColor : updateLotColor;

    return dispatch(lotAction(color, id))
      .then(result => {
        dispatch(getWarehouseData());
        dispatch(showSnackbar(successOptions, result.message));
        toggleMenu(false);
      })
      .catch(err => dispatch(showSnackbar(dangerOptions, err.message)));
  };

  const handleClickInside = () => toggleMenu(!isOpen);

  return (
    <ColorMenuWrapper ref={ref}>
      <Btn isOpen={isOpen} onClick={() => handleClickInside()} data-testid="colorBtn">
        <ColorPos bgColor={currentColor && currentColor.toUpperCase()}></ColorPos>
      </Btn>
      <ColorMenu isOpen={isOpen} data-testid="colorMenu">
        {colorsArr.map(color => (
          <ChangeBtn
            key={color}
            onClick={() => updateColor(color, lotId, isVirtual)}
            data-testid={color}
          >
            <ColorPos bgColor={color.toUpperCase()}></ColorPos>
          </ChangeBtn>
        ))}
      </ColorMenu>
    </ColorMenuWrapper>
  );
};

LotColor.propTypes = {
  lotId: PropTypes.string,
  currentColor: PropTypes.string,
  isVirtual: PropTypes.bool,
};

LotColor.defaultProps = {
  currentColor: 'gray',
  isVirtual: false,
};

export { LotColor };
