import { hedgeStatus } from 'shared/helpers/constants/hedges/hedgeStatus';
import { hedgeTypes } from 'shared/helpers/constants/hedges/hedgeType';
import { isOwnerUser } from 'shared/helpers/matchers/checkUserType';

const enterPricesAccess = (
  {
    access: {
      hedges: { enterPrices },
    },
    user: { userType },
  },
  hedge,
) =>
  enterPrices &&
  (hedge.hedgeType !== hedgeTypes.internal || isOwnerUser(userType)) &&
  hedge.status === hedgeStatus.approved;

export { enterPricesAccess };
