import React, { Component } from 'react';
import { connect } from 'react-redux';

import { listLimits } from 'shared/helpers/constants/listLimits';
import { isTablet } from 'shared/helpers/constants/resolutionsConstants';
import { toCamelCase } from 'shared/helpers/parsers/text';

import { debounce } from 'utils/debounce';
import { getBrowserWidth } from 'utils/responsive';

import { OverviewHeader } from '../OverviewHeader';
import { Pagination } from '../Pagination/Pagination';
import { SearchComponent } from '../Search/Search';
import { selector } from '../Search/components/SearchForm/SearchForm';
import { FullPageListFilters } from './components/FullPageListFilters/FullPageListFilters';
import { FullPageListTable } from './components/FullPageListTable/FullPageListTable';
import { FullPageListTableMobile } from './components/FullPageListTableMobile/FullPageListTableMobile';
import { StyledFullPageListFilters } from './components/StyledFullPageListFilters/StyledFullPageListFilters';

class FullPageListBase extends Component {
  listMounted = false;
  state = {
    windowWidth: getBrowserWidth(),
  };

  componentDidMount() {
    const { searchFilter } = this.props;
    window.addEventListener('resize', this.handleWindowSizeChange);

    this.listMounted = true;
    !this.props.disableInitialFetch && !searchFilter && this.props.fetchList();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);

    this.listMounted = false;
  }

  phraseChanged = debounce(() => {
    const {
      pagination: { limit },
      searchFilter,
    } = this.props;

    this.listMounted && searchFilter !== undefined
      ? this.props.fetchList(1, limit, searchFilter)
      : this.props.fetchList(1, limit);
  }, 300);

  setPage = page =>
    this.props.fetchList(page, this.props.pagination.limit, this.props.searchFilter);

  setLimit = limit => this.props.fetchList(1, limit, this.props.searchFilter);

  handleWindowSizeChange = () => this.setState({ windowWidth: getBrowserWidth() });

  render() {
    const {
      items,
      pagination,
      itemsTemplateMobile,
      filterComponent,
      enableSearch,
      title,
      actionsTemplate,
      actionsContext,
      actionButtons,
      subheader,
      focusSearch,
      searchFilter,
    } = this.props;
    const { windowWidth } = this.state;
    const isMobile = windowWidth < isTablet;
    const actionsTemplateWithAccess =
      !actionsTemplate.access || actionsTemplate.access.call(actionsContext) ? actionsTemplate : [];

    return (
      <>
        <OverviewHeader
          header={title}
          actions={actionButtons && actionButtons()}
          subheader={subheader}
        />
        <StyledFullPageListFilters searchEnabled={enableSearch} filtersEnabled={filterComponent}>
          {enableSearch && (
            <SearchComponent
              label={title}
              handleChange={this.phraseChanged}
              focusSearch={focusSearch}
              searchFilter={searchFilter}
            />
          )}
          {filterComponent && <FullPageListFilters {...this.props} />}
        </StyledFullPageListFilters>
        {isMobile && itemsTemplateMobile ? (
          <FullPageListTableMobile
            {...this.props}
            actionsTemplate={actionsTemplateWithAccess}
            searchFilter={searchFilter}
          />
        ) : (
          <FullPageListTable
            {...this.props}
            actionsTemplate={actionsTemplateWithAccess}
            searchFilter={searchFilter}
          />
        )}
        {pagination && items.length ? (
          <Pagination
            page={pagination.page}
            pages={pagination.pages}
            setPage={this.setPage}
            limits={this.props.listLimits || listLimits}
            setLimit={this.setLimit}
            selectedLimit={pagination.limit}
          />
        ) : null}
      </>
    );
  }
}

const mapStateToProps = (state, { title }) => ({
  searchFilter: title ? selector(state, `${toCamelCase(title)}.searchInput`) : null,
});

const FullPageList = connect(mapStateToProps)(FullPageListBase);

export { FullPageList };
