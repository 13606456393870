import React from 'react';
import styled from 'styled-components';

import { cssVar, media } from 'shared/helpers/styling/styling';

const CalendarWeekHeaderLayout = ({ className, label }) => {
  const [dayString, dayMonth] = label.split(' ');

  return (
    <div className={className}>
      <span>{dayString.concat(' ')}</span>
      <span>{dayMonth}</span>
    </div>
  );
};

const CalendarWeekHeader = styled(CalendarWeekHeaderLayout)`
  span {
    color: ${cssVar('alabaster')};
  }

  span:last-of-type {
    opacity: 0.5;
  }

  ${media.smallMobile`
    display: flex;
    flex-direction: column;
    
    span:last-of-type {
      display: none;  
    }
  `}
`;

export { CalendarWeekHeader };
