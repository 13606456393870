import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const updatePriceFeedType = 'GET_PRICE_FEED';
const updatePriceFeedAction = () => ({
  type: updatePriceFeedType,
});

const updatePriceFeedSuccessType = `${updatePriceFeedType}_SUCCESS`;
const updatePriceFeedSuccessAction = priceFeeds => ({
  type: updatePriceFeedSuccessType,
  payload: {
    priceFeeds,
  },
});

const updatePriceFeedFailureType = `${updatePriceFeedType}_FAILURE`;
const updatePriceFeedFailureAction = () => ({
  type: updatePriceFeedFailureType,
});

const updatePriceFeed = (priceFeedId, priceFeed) => dispatch => {
  dispatch(updatePriceFeedAction());

  return axios
    .put(`${API_HOST_AUTH}/price-feeds/${priceFeedId}`, priceFeed)
    .then(res => {
      dispatch(updatePriceFeedSuccessAction(res.data));

      return res.data;
    })
    .catch(err => {
      dispatch(updatePriceFeedFailureAction());

      return Promise.reject(err.data);
    });
};

export {
  updatePriceFeedType,
  updatePriceFeedSuccessType,
  updatePriceFeedFailureType,
  updatePriceFeed,
};
