import {
  lockMarketIndicatorsType,
  lockMarketIndicatorsSuccessType,
  lockMarketIndicatorsFailureType,
} from 'actions/MarketIndicators/lockMarketIndicators';

const INITIAL_STATE = {
  isPending: false,
};

const lockMarketIndicatorsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case lockMarketIndicatorsType:
      return {
        isPending: true,
      };
    case lockMarketIndicatorsSuccessType:
    case lockMarketIndicatorsFailureType:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};

export { lockMarketIndicatorsReducer };
