import {
  locationFiltersClearActionType,
  locationStatusFilterActionType,
  setInitialLocationFiltersActionType,
} from 'actions/Locations/locationFilters';

import { initialStatuses } from 'shared/helpers/constants/filters/initialStatuses';

const INITIAL_STATE = {
  status: Object.assign([], initialStatuses),
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case locationStatusFilterActionType:
      return {
        status: action.payload.locationStatus,
      };
    case locationFiltersClearActionType:
      return {
        status: [],
      };
    case setInitialLocationFiltersActionType:
      return {
        status: Object.assign([], initialStatuses),
      };
    default:
      return state;
  }
};
