import PropTypes from 'prop-types';
import * as React from 'react';
import { memo } from 'react';

import { Button } from 'shared/components/Buttons/Button';
import { Icon } from 'shared/components/Icon/Icon';
import { SHARED } from 'shared/helpers/constants/sharedConstants';
import { cssVar } from 'shared/helpers/styling/styling';

import { StyledPermissionsFooter } from './StyledPermissionsFooter';

const PermissionsFooter = memo(({ handleReset, dirty, submitting }) => {
  return (
    <StyledPermissionsFooter>
      <Button onClick={handleReset} outlineColor={cssVar('roman')} disabled={!dirty || submitting}>
        {SHARED.RESET}
        <Icon icon="icon-cancel" />
      </Button>
      <Button type="submit" outline disabled={!dirty || submitting}>
        {SHARED.SAVE_BUTTON}
        <Icon icon="icon-tick" />
      </Button>
    </StyledPermissionsFooter>
  );
});

PermissionsFooter.propTypes = {
  handleReset: PropTypes.func,
  dirty: PropTypes.bool,
};

export { PermissionsFooter };
