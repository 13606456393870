export const message = {
  COMPANIES_EMPTY: 'At least one company must be specified.',
  COMPANIES_REQUIRED: 'Companies are required when include all companies is not selected.',
  COMPANIES_NOT_ALLOWED: 'Companies are not allowed when include all companies is selected.',
  LOCATIONS_EMPTY: 'At least one location must be specified.',
  LOCATIONS_REQUIRED: 'Locations are required when include all locations is not selected.',
  LOCATIONS_NOT_ALLOWED: 'Locations are not allowed when include all locations is selected.',
  DATE_FROM_REQUIRED: 'The date from is required.',
  DATE_FROM_LATER_THAN_TODAY: 'The date from cannot be later than today.',
  DATE_TO_REQUIRED: 'The date to is required.',
  DATE_TO_LATER_THAN_TODAY: 'The date to cannot be later than today.',
  DATE_RANGE_ERROR: 'The date to cannot be earlier than the date from.',
};
