import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const enterPricesActionType = 'SETTLEMENT_ENTER_PRICES';
const enterPricesAction = () => ({ type: enterPricesActionType });

const enterPricesSuccessActionType = `${enterPricesActionType}_SUCCESS`;
const enterPricesSuccessAction = () => ({ type: enterPricesSuccessActionType });

const enterPricesFailureActionType = `${enterPricesActionType}_FAILURE`;
const enterPricesFailureAction = () => ({ type: enterPricesFailureActionType });

const enterPrices = (settlementId, prices) => dispatch => {
  dispatch(enterPricesAction());

  return axios
    .patch(`${API_HOST_AUTH}/settlements/${settlementId}/enter-prices`, prices)
    .then(({ data }) => {
      dispatch(enterPricesSuccessAction());

      return data;
    })
    .catch(error => {
      dispatch(enterPricesFailureAction());

      return Promise.reject(error.data);
    });
};

export {
  enterPricesActionType,
  enterPricesSuccessActionType,
  enterPricesFailureActionType,
  enterPrices,
};
