import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { parseFieldValue } from 'shared/components/Details/helpers/parseFieldValue';

import { DetailsField } from '../DetailsField';

const FieldsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const DetailsActivityFieldList = ({ values = [], dateFormat, icon = 'icon-user', ...props }) => {
  const filesList = values.length && (
    <FieldsContainer>
      {values.map(({ receivedBy, receivedAt }) => (
        <span key={receivedBy._id}>{parseFieldValue(receivedBy, receivedAt, dateFormat)}</span>
      ))}
    </FieldsContainer>
  );

  return <DetailsField {...props} icon={icon} customContent={filesList} />;
};

DetailsActivityFieldList.propTypes = {
  values: PropTypes.arrayOf(
    PropTypes.shape({
      receivedBy: PropTypes.shape({
        _id: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
      }),
      receivedAt: PropTypes.number,
    }),
  ),
  label: PropTypes.string.isRequired,
  icon: PropTypes.string,
  dateFormat: PropTypes.string,
};

export { DetailsActivityFieldList };
