import axios from 'axios';

import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';
import { messageTypes } from 'shared/helpers/constants/snackbar/snackbarMessageTypes';

const API_HOST_AUTH = process.env.REACT_APP_API;

const updateColorType = 'UPDATE_COLOR';
const updateColorAction = () => ({
  type: updateColorType,
});

const updateColorSuccessType = `${updateColorType}_SUCCESS`;
const updateColorSuccessAction = () => ({
  type: updateColorSuccessType,
});

const updateColorFailureType = `${updateColorType}_FAILURE`;
const updateColorFailureAction = () => ({
  type: updateColorFailureType,
});

const updateColorFactory = url => data => dispatch => {
  dispatch(updateColorAction());

  return axios
    .patch(url, data)
    .then(res => {
      dispatch(updateColorSuccessAction());

      return {
        id: res.data.id,
        message: res.data.message,
        messageType: messageTypes.success,
      };
    })
    .catch(error => {
      dispatch(updateColorFailureAction());

      return Promise.reject({
        message: `${LOTS.ERRORS.UPDATE_LOT_ERROR} ${error.message}`,
        messageType: messageTypes.error,
      });
    });
};

const updateLotColor = (color, id) =>
  updateColorFactory(`${API_HOST_AUTH}/lots/${id}/color`)({ color });
const updateVirtualLotColor = (color, id) =>
  updateColorFactory(`${API_HOST_AUTH}/virtual-lots`)({ color, _id: id });

export {
  updateColorType,
  updateColorSuccessType,
  updateColorFailureType,
  updateLotColor,
  updateVirtualLotColor,
};
