import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getAvailablePaymentTypesActionType = 'GET_AVAILABLE_PAYMENT_TYPES';
const getAvailablePaymentTypesAction = () => ({
  type: getAvailablePaymentTypesActionType,
});

const getAvailablePaymentTypesSuccessActionType = `${getAvailablePaymentTypesActionType}_SUCCESS`;
const getAvailablePaymentTypesSuccessAction = data => ({
  type: getAvailablePaymentTypesSuccessActionType,
  payload: {
    data,
  },
});

const getAvailablePaymentTypesFailureActionType = `${getAvailablePaymentTypesActionType}_FAILURE`;
const getAvailablePaymentTypesFailureAction = () => ({
  type: getAvailablePaymentTypesFailureActionType,
});

const clearAvailablePaymentTypesActionType = 'CLEAR_AVAILABLE_PAYMENT_TYPES';
const clearAvailablePaymentTypes = () => ({
  type: clearAvailablePaymentTypesActionType,
});

const getAvailablePaymentTypes = lotId => dispatch => {
  dispatch(getAvailablePaymentTypesAction());

  return axios
    .get(`${API_HOST_AUTH}/payments/payment-types/${lotId}`)
    .then(res => {
      dispatch(getAvailablePaymentTypesSuccessAction(res.data));

      return res.data;
    })
    .catch(() => Promise.reject(dispatch(getAvailablePaymentTypesFailureAction())));
};

export {
  getAvailablePaymentTypesActionType,
  getAvailablePaymentTypesAction,
  getAvailablePaymentTypesSuccessActionType,
  getAvailablePaymentTypesSuccessAction,
  getAvailablePaymentTypesFailureActionType,
  getAvailablePaymentTypesFailureAction,
  clearAvailablePaymentTypesActionType,
  clearAvailablePaymentTypes,
  getAvailablePaymentTypes,
};
