import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
  Tab as TabLayout,
  TabList as TabListLayout,
  TabPanel as TabPanelLayout,
  Tabs,
} from 'react-tabs';
import styled, { css } from 'styled-components';

import { DetailsContainer } from 'shared/components/Details/DetailsContainer';
import { cssVar, media, pxToRem, scrollbar } from 'shared/helpers/styling/styling';

const TabList = styled(TabListLayout)`
  display: flex;
  justify-content: ${({ listView }) => (listView ? 'flex-start' : 'space-between')};
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  background-color: ${({ listView }) =>
    listView ? cssVar('limedSpruceDetails') : cssVar('outerSpaceTabs')};
  border-bottom: 15px solid ${cssVar('shark')};
  overflow: hidden;
  @media print {
    display: none;
  }
  ${({ itemsScroll }) =>
    itemsScroll &&
    css`
      ${scrollbar};

      ::-webkit-scrollbar {
        &-track {
          border-left: 6px solid transparent;
        }

        &-thumb {
          border-left: 10px solid transparent;
          border-top: 6px solid transparent;
          border-bottom: 6px solid transparent;
        }
      }

      overflow-x: auto;
      flex-wrap: nowrap;
    `};
`;

const Tab = styled(TabLayout).attrs({
  selectedClassName: 'selected',
})`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: ${({ listView }) => (listView ? 0 : 1)};
  text-align: center;
  cursor: pointer;
  height: 60px;
  font-size: ${pxToRem(12)};
  font-weight: bold;
  min-width: ${({ itemsScroll }) => (itemsScroll ? '146px' : '100px')};
  color: ${cssVar('alto')};
  background-color: ${({ listView }) =>
    listView ? cssVar('limedSpruceDetails') : cssVar('outerSpaceTabs')};
  box-shadow: -1px -1px 0 ${cssVar('outerSpaceBrighter')};

  ${({ listView }) =>
    !!listView &&
    css`
      padding: 0 35px;

      ${({ total }) =>
        total === 0 &&
        css`
          opacity: 0.4;
          cursor: auto;
        `}

      ${media.mobile`
    flex-grow: 1;
    min-width: 160px;
    padding: 0;
  `}
    `}
  &:focus {
    outline: none;
  }

  &.selected {
    background-color: ${cssVar('shark')};
  }
`;

const TabPanel = styled(TabPanelLayout).attrs({
  selectedClassName: 'selected',
})`
  break-inside: avoid;
  margin-bottom: 30px;
  display: none;

  @media print {
    display: block;
  }

  &.selected {
    display: block;
  }
`;

export const DetailsTabs = ({ tabsConfig, listView, itemsScroll, selectedIndex }) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(selectedIndex || 0);

  return (
    <Tabs
      onSelect={index => setSelectedTabIndex(index)}
      selectedIndex={selectedTabIndex}
      forceRenderTabPanel
    >
      <TabList listView={listView} itemsScroll={itemsScroll}>
        {tabsConfig.map(
          tab =>
            tab && (
              <Tab
                key={tab.tabName}
                listView={listView}
                total={tab.total}
                disabled={listView && !tab.total}
                itemsScroll={itemsScroll}
              >
                {tab.tabName}
              </Tab>
            ),
        )}
      </TabList>
      {tabsConfig.map(
        tab =>
          tab && (
            <TabPanel key={tab.tabName}>
              <DetailsContainer template={tab.tabContent.template} printHeader={tab.tabName}>
                {tab.tabContent.fieldsConfig
                  .filter(data => data?.Component)
                  .map(
                    ({ Component, ...rest }) => Component && <Component {...rest} key={rest.id} />,
                  )}
              </DetailsContainer>
            </TabPanel>
          ),
      )}
    </Tabs>
  );
};

DetailsTabs.propTypes = {
  tabsConfig: PropTypes.arrayOf(
    PropTypes.shape({
      tabName: PropTypes.string.isRequired,
      tabContent: PropTypes.shape({
        template: PropTypes.arrayOf(PropTypes.string).isRequired,
        fieldsConfig: PropTypes.arrayOf(PropTypes.object).isRequired,
      }).isRequired,
    }),
  ).isRequired,
  listView: PropTypes.bool,
  itemsScroll: PropTypes.bool,
};
