import axios from 'axios';

import { parseCompanyFilters } from 'shared/helpers/dataParsers/company/parseCompanyFilters';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getCompaniesActionType = 'GET_COMPANIES';
function getCompaniesAction() {
  return {
    type: getCompaniesActionType,
  };
}

const getCompaniesSuccessActionType = 'GET_COMPANIES_SUCCESS';
function getCompaniesSuccessAction({ docs, pages, limit, page }) {
  return {
    type: getCompaniesSuccessActionType,
    payload: {
      companies: docs,
      pages,
      limit,
      page,
    },
  };
}

const getCompaniesFailureActionType = 'GET_COMPANIES_FAILURE';
function getCompaniesFailureAction(errorMessage) {
  return {
    type: getCompaniesFailureActionType,
    payload: {
      errorMessage,
    },
  };
}

const clearCompaniesListActionType = 'CLEAR_COMPANIES_LIST_ACTION';
function clearCompaniesList() {
  return {
    type: clearCompaniesListActionType,
  };
}

function getCompanies(limit, page, searchFilter, companyFilters) {
  return dispatch => {
    dispatch(getCompaniesAction());

    return axios({
      method: 'GET',
      url: `${API_HOST_AUTH}/companies`,
      params: parseCompanyFilters(companyFilters, limit, page, searchFilter || ''),
    })
      .then(({ data }) => dispatch(getCompaniesSuccessAction(data)))
      .catch(() => dispatch(getCompaniesFailureAction('')));
  };
}

export {
  getCompaniesActionType,
  getCompaniesSuccessActionType,
  getCompaniesFailureActionType,
  clearCompaniesListActionType,
  getCompanies,
  clearCompaniesList,
};
