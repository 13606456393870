import {
  statusForCustomerFilterActionType,
  statusForGlobalFilterActionType,
  materialTypeFilterActionType,
  sentFromCompanyLocationFilterActionType,
  hedgedStatusFilterActionType,
  receivedAtStartFilterActionType,
  receivedAtEndFilterActionType,
  lotsFiltersClearActionType,
} from 'actions/Lots/lotsFilters';

const INITIAL_STATE = {
  statusForCustomer: [],
  statusForGlobal: [],
  materialType: [],
  sentFromCompanyLocation: {},
  hedgedStatus: [],
  receivedAtStart: 0,
  receivedAtEnd: 0,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case statusForCustomerFilterActionType:
      return {
        ...state,
        statusForCustomer: action.payload.statusForCustomer,
      };
    case statusForGlobalFilterActionType:
      return {
        ...state,
        statusForGlobal: action.payload.statusForGlobal,
      };
    case materialTypeFilterActionType:
      return {
        ...state,
        materialType: action.payload.materialType,
      };
    case sentFromCompanyLocationFilterActionType:
      return {
        ...state,
        sentFromCompanyLocation: action.payload.sentFromCompanyLocation,
      };
    case hedgedStatusFilterActionType:
      return {
        ...state,
        hedgedStatus: action.payload.hedgedStatus,
      };
    case receivedAtStartFilterActionType:
      return {
        ...state,
        receivedAtStart: action.payload.receivedAtStart,
      };
    case receivedAtEndFilterActionType:
      return {
        ...state,
        receivedAtEnd: action.payload.receivedAtEnd,
      };
    case lotsFiltersClearActionType:
      return {
        statusForCustomer: [],
        statusForGlobal: [],
        materialType: [],
        sentFromCompanyLocation: {},
        hedgedStatus: [],
        receivedAtStart: 0,
        receivedAtEnd: 0,
      };
    default:
      return state;
  }
};
