import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getLotSettlementsType = 'GET_LOT_SETTLEMENTS';
const getLotSettlementsAction = limit => ({
  type: getLotSettlementsType,
  payload: { limit },
});

const getLotSettlementsSuccessType = `${getLotSettlementsType}_SUCCESS`;
const getLotSettlementsSuccessAction = settlements => ({
  type: getLotSettlementsSuccessType,
  payload: { settlements },
});

const getLotSettlementsFailureType = `${getLotSettlementsType}_FAILURE`;
const getLotSettlementsFailureAction = () => ({
  type: getLotSettlementsFailureType,
});

const clearLotSettlementsType = 'CLEAR_LOT_SETTLEMENTS';
const clearLotSettlements = () => ({
  type: clearLotSettlementsType,
});

const getLotSettlements =
  (lotId, limit, page = 1) =>
  dispatch => {
    dispatch(getLotSettlementsAction(limit));

    return axios
      .get(`${API_HOST_AUTH}/settlements/list/${lotId}/${limit}/${page}`)
      .then(res => {
        dispatch(getLotSettlementsSuccessAction(res.data));

        return res.data;
      })
      .catch(err => {
        dispatch(getLotSettlementsFailureAction());

        return Promise.reject(err.data);
      });
  };

export {
  getLotSettlementsType,
  getLotSettlementsSuccessType,
  getLotSettlementsFailureType,
  clearLotSettlementsType,
  getLotSettlements,
  clearLotSettlements,
};
