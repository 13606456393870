import PropTypes from 'prop-types';
import styled, { css, keyframes } from 'styled-components';

import { cssVar, media } from 'shared/helpers/styling/styling';

const rotate = keyframes`
  from {
    transform: rotateZ(0deg);
    opacity: 1;
  }
  to {
    transform: rotateZ(90deg);
    opacity: 0;
  }
`;

const StyledListCollapse = styled.div`
  display: flex;
  align-items: center;
  ${({ disabled }) =>
    !disabled &&
    css`
      cursor: pointer;
    `};
  padding-left: 1rem;

  ${({ processing }) =>
    !processing &&
    css`
      ${media.phone`
      width: 260px;
    `}
    `};

  h3 {
    color: ${cssVar('whiteGRG')};
    font-size: 1.5rem;
    margin-bottom: 0;
  }

  ${({ isOpen }) =>
    isOpen
      ? css`
          &:after {
            ${({ disabled }) =>
              !disabled &&
              css`
                content: '';
              `};
            position: absolute;
            transform: translateX(-1rem);
            width: 2rem;
            height: 2px;
            background-color: ${cssVar('whiteGRG')};
            @media printer {
              display: none;
            }
          }

          &:before {
            ${({ disabled }) =>
              !disabled &&
              css`
                content: '';
              `};
            width: 2px;
            height: 2rem;
            opacity: 0;
            background-color: ${cssVar('whiteGRG')};
            margin-right: 6rem;
            animation: ${rotate} 0.2s linear;
            @media printer {
              display: none;
            }
          }
        `
      : css`
          &:before {
            ${({ disabled }) =>
              !disabled &&
              css`
                content: '';
              `};
            transform: translateX(-1rem);
            width: 2rem;
            height: 2px;
            background-color: ${cssVar('whiteGRG')};
            margin-right: 4.12rem;
            padding-right: 2rem;
          }
          &:after {
            ${({ disabled }) =>
              !disabled &&
              css`
                content: '';
              `};
            position: absolute;
            width: 2px;
            height: 2rem;
            background-color: ${cssVar('whiteGRG')};
            animation: ${rotate} 0.2s linear reverse;
          }
        `}
`;

StyledListCollapse.propTypes = {
  isOpen: PropTypes.bool,
  disabled: PropTypes.bool,
};

export { StyledListCollapse };
