import React from 'react';
import { connect } from 'react-redux';

import { inviteUser as inviteUserAction } from 'actions/Users/inviteUser';
import { openModal } from 'actions/shared/modal';
import { showSnackbar } from 'actions/shared/snackbar';

import { inviteUserModal } from 'shared/helpers/constants/modalConstants';

function InviteUser(props) {
  const inviteUser = () => {
    props.openModal(inviteUserModal, () => {
      props.inviteUser(props.userId);
    });
  };

  return <React.Fragment>{props.children({ inviteUser })}</React.Fragment>;
}

const mapDispatchToProps = {
  openModal,
  showSnackbar,
  inviteUser: inviteUserAction,
};

const ConnectedInviteUser = connect(null, mapDispatchToProps)(InviteUser);

export { ConnectedInviteUser as InviteUser, InviteUser as InviteUserModal };
