import store from 'store';

import dateAdapter from 'utils/date/dateAdapter';

const isBeforeOrDuringMarketHours = () => {
  const { marketAvailabilityDetails } = store.getState();
  return (
    marketAvailabilityDetails.isBusinessDay &&
    dateAdapter().valueOf() < marketAvailabilityDetails.marketTimeTo
  );
};

export { isBeforeOrDuringMarketHours };
