import { DropdownItem, DropdownMenu as BTSDropdownMenu } from 'reactstrap';
import styled, { css } from 'styled-components';

import { media, scrollbar } from 'shared/helpers/styling/styling';

export const DropdownMenuItem = styled(DropdownItem)`
  color: var(--gullGray);
  align-items: center;
  display: flex;
  position: relative;
  justify-content: space-between;
  text-align: left;
  cursor: pointer;
  font-size: 0.9em;
  padding: 2px 10px 2px 20px;
  height: 42px;
  background-color: var(--shuttleGray);

  &:hover {
    background-color: var(--nevada);
    opacity: 1;
    color: var(--whiteGRG);
  }

  &:focus {
    background-color: var(--nevada);
    outline: none;
    color: var(--whiteGRG);
  }

  ${({ selected }) =>
    selected
      ? css`
          background-color: var(--slateGrayGRG);
          color: var(--whiteGRG);
        `
      : ''}

  ${media.phone`padding-right: 10px;`}
`;

export const DropdownMenuItemText = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 36px;
`;

export const DropdownMenuOptionsDivider = styled.div`
  background-color: var(--shuttleGray);
  width: 90%;
  margin: auto;
  height: 1px;
`;

export const DropdownMenu = styled(BTSDropdownMenu)`
  background-color: transparent;
  border-radius: 5px;
  padding: 0;
  width: 100%;
  margin: 0;
  border: 0;
  z-index: 1011;
  max-height: 225px;
  overflow-y: auto;
  box-shadow: 0 11px 24px 0 rgba(0, 0, 0, 0.29);

  button:first-of-type {
    border-radius: 5px 5px 0 0;
  }

  ${scrollbar}
`;

export const DropdownToggleIcon = styled.i`
  position: absolute;
  right: 10px;
  top: 3px;

  ${media.phone`top: 8px;`}
`;

