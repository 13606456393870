import { isArray } from 'utils/array';

const multiValidator =
  (field, ...validators) =>
  (values, ...args) => {
    const fieldValues = values[field];

    if (!fieldValues) return;

    const validationErrors = Array.prototype.concat(fieldValues).reduce((errors, value, index) => {
      const error = validators.reduce(
        (acc, validator) => ({
          ...acc,
          ...validator(value, index, ...args),
        }),
        {},
      );

      return [...errors, error];
    }, []);

    return {
      [field]: isArray(values[field]) ? validationErrors : validationErrors[0],
    };
  };

const multiAsyncValidator =
  (field, validator) =>
  async (values, ...args) => {
    const validatedValues = values[field];
    let hasError = false;

    const errors = await Array.prototype.concat(validatedValues).reduce(async (acc, value) => {
      let error = null;
      try {
        await validator(value, ...args);
      } catch (e) {
        error = e;
        hasError = true;
      }

      return [...(await acc), error];
    }, []);

    const errorObject = {
      [field]: isArray(values[field]) ? errors : errors[0],
    };

    if (hasError) return Promise.reject(errorObject);
    return errorObject;
  };

export { multiValidator, multiAsyncValidator };
