import axios from 'axios';

import { history } from 'utils/history';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getNotificationActionType = 'GET_NOTIFICATION';
function getNotificationAction() {
  return {
    type: getNotificationActionType,
  };
}

const getNotificationSuccessActionType = 'GET_NOTIFICATION_SUCCESS';
function getNotificationSuccessAction(notification, redirectedFrom) {
  return {
    type: getNotificationSuccessActionType,
    payload: {
      notification,
      redirectedFrom,
    },
  };
}

const getNotificationFailureActionType = 'GET_NOTIFICATION_FAILURE';
function getNotificationFailureAction(errorMessage) {
  return {
    type: getNotificationFailureActionType,
    payload: {
      errorMessage,
    },
  };
}

const clearNotificationDetailsActionType = 'CLEAR_NOTIFICATION_DETAILS_ACTION';
function clearNotificationDetailsAction() {
  return {
    type: clearNotificationDetailsActionType,
  };
}

function getNotification(notificationId, redirectedFrom) {
  return dispatch => {
    dispatch(getNotificationAction());

    return axios
      .get(`${API_HOST_AUTH}/notifications/${notificationId}`)
      .then(res => {
        dispatch(getNotificationSuccessAction(res.data, redirectedFrom));
        return res.data;
      })
      .catch(error => {
        history.push('/notifications');
        dispatch(getNotificationFailureAction(error));

        return Promise.reject(error);
      });
  };
}

function refreshNotification() {
  return (dispatch, getState) => {
    const state = getState();
    const notificationId =
      state.notificationDetails.notification && state.notificationDetails.notification._id;

    if (notificationId) {
      getNotification(getState().notificationDetails.notification._id)(dispatch);
    }
  };
}

function clearNotificationDetails() {
  return dispatch => {
    dispatch(clearNotificationDetailsAction());
  };
}

export {
  getNotificationActionType,
  getNotificationSuccessActionType,
  getNotificationFailureActionType,
  clearNotificationDetailsActionType,
  getNotification,
  refreshNotification,
  clearNotificationDetails,
};
