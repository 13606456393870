import axios from 'axios';

import { showSnackbar } from 'actions/shared/snackbar';

import { dangerOptions } from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

const API_HOST_AUTH = process.env.REACT_APP_API;

const checkSubmittedPoolPaymentsActionType = 'CHECK_SUBMITTED_POOL_PAYMENTS';
const checkSubmittedPoolPaymentsAction = () => ({
  type: checkSubmittedPoolPaymentsActionType,
});

const checkSubmittedPoolPaymentsSuccessActionType = `${checkSubmittedPoolPaymentsActionType}_SUCCESS`;
const checkSubmittedPoolPaymentsSuccessAction = wasPoolPaymentsSubmitted => ({
  type: checkSubmittedPoolPaymentsSuccessActionType,
  payload: {
    wasPoolPaymentsSubmitted,
  },
});

const checkSubmittedPoolPaymentsFailureActionType = `${checkSubmittedPoolPaymentsActionType}_FAILURE`;
const checkSubmittedPoolPaymentsFailureAction = () => ({
  type: checkSubmittedPoolPaymentsFailureActionType,
});

const clearSubmittedPoolPaymentsActionType = 'CLEAR_SUBMITTED_POOL_PAYMENTS';
const clearSubmittedPoolPayments = () => ({
  type: clearSubmittedPoolPaymentsActionType,
});

const checkSubmittedPoolPayments = id => dispatch => {
  dispatch(checkSubmittedPoolPaymentsAction());

  return axios
    .get(`${API_HOST_AUTH}/pool-accounts/check-submitted-pool-payments/${id}`)
    .then(res => {
      dispatch(checkSubmittedPoolPaymentsSuccessAction(res.data));

      return res.data;
    })
    .catch(error => {
      dispatch(showSnackbar(dangerOptions, error.data?.message));
      dispatch(checkSubmittedPoolPaymentsFailureAction(error));
      return Promise.reject(error.data);
    });
};

export {
  checkSubmittedPoolPaymentsActionType,
  checkSubmittedPoolPaymentsAction,
  checkSubmittedPoolPaymentsSuccessActionType,
  checkSubmittedPoolPaymentsSuccessAction,
  checkSubmittedPoolPaymentsFailureActionType,
  checkSubmittedPoolPaymentsFailureAction,
  checkSubmittedPoolPayments,
  clearSubmittedPoolPaymentsActionType,
  clearSubmittedPoolPayments,
};
