import { css } from 'styled-components';
import { theme } from './theme';

export const ColorVariables = css`
  :root {
    ${Object.entries(theme.colors)
    .map(([key, value]) => `--${key}: ${value}`)
    .join(';')}
  }
`;
