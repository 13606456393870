import { messages } from 'shared/helpers/constants/locations/createLocationConstants';
import { doesItHaveValue } from 'shared/helpers/matchers/doesItHaveValue';
import {
  ZIPCODE_CA_REGEX,
  ZIPCODE_US_REGEX,
  CAPITAL_ALPHANUM_REGEX,
} from 'shared/helpers/validations/regularExpressions';

function validate(values) {
  const errors = {};

  if (!values.name || !values.name.trim()) {
    errors.name = messages.INVALID_LOCATION_NAME_EMPTY;
  } else if (values.name.length > 100) {
    errors.name = messages.INVALID_LOCATION_NAME;
  }

  if (!values.suite) {
    errors.suite = '';
  } else if (values.suite.length > 100) {
    errors.suite = messages.INVALID_SUITE;
  }

  if (!values.poBox) {
    errors.poBox = '';
  } else if (values.poBox.length > 20) {
    errors.poBox = messages.INVALID_PO_BOX;
  }

  if (!values.country) {
    errors.country = messages.INVALID_COUNTRY_EMPTY;
  }

  if (!values.state) {
    errors.state = '';
  } else if (values.state.length > 100) {
    errors.state = messages.INVALID_STATE;
  }

  if (!values.zipCode) {
    errors.zipCode = '';
  } else if (values.zipCode.length > 20) {
    errors.zipCode = messages.INVALID_ZIP_CODE;
  }

  if (doesItHaveValue(values.country, 'value') === 'US') {
    if (!values.zipCode || !values.zipCode.trim()) {
      errors.zipCode = messages.INVALID_ZIP_CODE_EMPTY;
    }
    if (!ZIPCODE_US_REGEX.test(values.zipCode) && doesItHaveValue(values.zipCode, 'length')) {
      errors.zipCode = messages.INVALID_ZIP_CODE;
    }

    if (!values.state) {
      errors.state = messages.INVALID_STATE_EMPTY;
    }
  } else if (doesItHaveValue(values.country, 'value') === 'CA') {
    if (!values.zipCode || !values.zipCode.trim()) {
      errors.zipCode = messages.INVALID_ZIP_CODE_EMPTY;
    }
    if (!ZIPCODE_CA_REGEX.test(values.zipCode) && doesItHaveValue(values.zipCode, 'length')) {
      errors.zipCode = messages.INVALID_ZIP_CODE;
    }

    if (!values.state) {
      errors.state = messages.INVALID_STATE_EMPTY;
    }
  }

  if (!values.city || !values.city.trim()) {
    errors.city = messages.INVALID_CITY_EMPTY;
  } else if (values.city.length > 100) {
    errors.city = messages.INVALID_CITY;
  }

  if (!values.street || !values.street.trim()) {
    errors.street = messages.INVALID_STREET_EMPTY;
  } else if (values.street.length > 100) {
    errors.street = messages.INVALID_STREET;
  }

  if (!values.lotSuffix || !values.lotSuffix.trim()) {
    errors.lotSuffix = messages.INVALID_LOT_SUFFIX_EMPTY;
  } else if (values.lotSuffix.length > 6) {
    errors.lotSuffix = messages.INVALID_LOT_SUFFIX;
  } else if (!CAPITAL_ALPHANUM_REGEX.test(values.lotSuffix)) {
    errors.lotSuffix = messages.INVALID_LOT_SUFFIX;
  }

  if (values.shipmentEligible === undefined) {
    errors.shipmentEligible = messages.INVALID_SHIPMENT_ELIGIBLE_EMPTY;
  }

  if (values.primaryLocation === undefined) {
    errors.primaryLocation = messages.INVALID_PRIMARY_LOCATION_EMPTY;
  }

  if (values.shipmentEligible && !(values.primaryContact && values.primaryContact.length)) {
    errors.primaryContact = messages.INVALID_PRIMARY_CONTACT;
  }

  return errors;
}

export { validate };
