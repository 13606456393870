import PropTypes from 'prop-types';
import React, { useEffect, useCallback, useMemo } from 'react';

import { FormContainer } from 'shared/components/Form';
import { LOCATION } from 'shared/helpers/constants/locations/locationConstants';

import { LocationForm } from '../LocationForm/LocationForm';

function UpdateLocation({
  match: { params },
  locationDetails,
  getLocation,
  clearLocationDetails,
  updateLocation,
  ...props
}) {
  const specialCountries = ['US', 'CA'];
  const companyId = locationDetails.location?.relatedCompany._id;
  const location = locationDetails.location;

  useEffect(() => {
    getLocation(params.id);

    return () => clearLocationDetails();
  }, [params.id, getLocation, clearLocationDetails]);

  const submit = useCallback(
    values =>
      updateLocation(values, params.id, companyId, !locationDetails.location.hasLotsOrHedges),
    [updateLocation, params.id, companyId, locationDetails],
  );

  const initialValues = useMemo(
    () =>
      location && {
        name: location.name,
        country: {
          label: location.country.label,
          value: location.country.value,
        },
        state: specialCountries.includes(location.country.value)
          ? {
              label: location.state,
              value: location.state,
            }
          : location.state,
        city: location.city,
        street: location.street,
        suite: location.suite,
        zipCode: location.zipCode,
        poBox: location.poBox,
        shipmentEligible: location.shipmentEligible,
        primaryLocation: location.primaryLocation,
        lotSuffix: location.lotSuffix,
        primaryContact:
          location.primaryContact &&
          location.primaryContact.map(({ _id, firstName, lastName }) => ({
            label: `${firstName} ${lastName}`,
            value: _id,
          })),
      },
    [location, specialCountries],
  );

  return (
    <FormContainer
      header={LOCATION.EDIT_LOCATION}
      subHeader={locationDetails.relatedCompany && locationDetails.relatedCompany.companyName}
      loaded={
        !locationDetails.isPending &&
        locationDetails.relatedCompany &&
        !!Object.keys(locationDetails.relatedCompany).length
      }
    >
      {() => (
        <LocationForm
          {...props}
          initialValues={initialValues}
          locationDetails={locationDetails}
          editMode
          relatedCompany={locationDetails.location && locationDetails.location.relatedCompany}
          onSubmit={submit}
        />
      )}
    </FormContainer>
  );
}

UpdateLocation.propTypes = {
  auth: PropTypes.shape({
    access: PropTypes.object,
    attempts: PropTypes.number,
    isPending: PropTypes.bool,
    user: PropTypes.object,
  }),
  clearLocationDetails: PropTypes.func,
  closeModal: PropTypes.func,
  getCompaniesContract: PropTypes.func,
  getLocation: PropTypes.func,
  getRegionList: PropTypes.func,
  hideSnackbar: PropTypes.func,
  history: PropTypes.object,
  location: PropTypes.object,
  locationDetails: PropTypes.shape({
    location: PropTypes.shape({
      city: PropTypes.string,
      country: PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      }),
      createdAt: PropTypes.number,
      createdBy: PropTypes.shape({
        _id: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
      }),
      hasLotsOrHedges: PropTypes.bool,
      lotSuffix: PropTypes.string,
      name: PropTypes.string,
      poBox: PropTypes.string,
      primaryContact: PropTypes.array,
      primaryLocation: PropTypes.bool,
      relatedCompany: PropTypes.shape({
        _id: PropTypes.string,
        companyType: PropTypes.string,
        companyName: PropTypes.string,
      }),
      shipmentEligible: PropTypes.bool,
      state: PropTypes.string,
      status: PropTypes.string,
      street: PropTypes.string,
      suite: PropTypes.string,
      updatedAt: PropTypes.number,
      updatedBy: PropTypes.shape({
        _id: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
      }),
      zipCode: PropTypes.string,
      _id: PropTypes.string,
    }),
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      companyId: PropTypes.string,
      id: PropTypes.string,
    }),
  }),
  modal: PropTypes.object,
  openModal: PropTypes.func,
  showSnackbar: PropTypes.func,
  updateLocation: PropTypes.func,
};

export { UpdateLocation };
