import React from 'react';
import styled from 'styled-components';

import { Button } from 'shared/components/Buttons/Button';
import { PrintIntent } from 'shared/components/PrintIntent/PrintIntent';
import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';
import { PACKAGES } from 'shared/helpers/constants/packages/packageConstants';

import {
  printLabelUrl,
  printLotLabelUrl,
  printLabelEbsUrl,
  printLotLabelEbsUrl,
} from 'utils/labelsUrls';

import { PrintPackageLabel } from 'pages/Packages/components/PrintPackageLabels/PackageView/PrintPackageLabel';

import { PrintLotLabel } from '../LotDetails/components/LotDetailsGlobal/components/PrintLotLabel';

const isAndroid = /Android/i.test(navigator.userAgent);

const DuplicateSummaryStyled = styled.div`
  min-width: 200px;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  text-align: center;
  align-items: center;

  h2 {
    margin-bottom: 1rem;
  }

  button {
    max-width: 180px;
    a {
      height: 43px;
      line-height: 40px;
    }
  }
`;

const DuplicateSummaryPackage = ({ pkg, isPending }) => (
  <>
    <h2>{`${PACKAGES.PACKAGE}: ${pkg.shortenedId}`}</h2>
    {isAndroid ? (
      <>
        <Button outline id="printPackageLabelMobile" type="button" disabled={isPending}>
          <PrintIntent intent={printLabelUrl(pkg)} fallback={`/packages/print-labels/${pkg.id}`}>
            {PACKAGES.PRINT_LABEL_ZEBRA}
          </PrintIntent>
        </Button>
        <Button outline id="printPackageLabelMobileEbs" type="button" disabled={isPending}>
          <PrintIntent
            intent={printLabelEbsUrl(pkg)}
            fallback={`/packages/print-labels/${pkg.id}`}
            pkg="com.globalrefininggroup.checkinlabels.bluetoothprinterEBS"
          >
            {PACKAGES.PRINT_LABEL_EBS}
          </PrintIntent>
        </Button>
      </>
    ) : (
      <PrintPackageLabel
        path={`/packages/print-labels/${pkg.id}`}
        errorMessage={PACKAGES.PRINT_PACKAGE_LABEL_ERROR}
      >
        {({ onClick }) => (
          <Button
            outline
            id="printPackageLabel"
            type="button"
            onClick={onClick}
            disabled={isPending}
          >
            {PACKAGES.PRINT_LABEL}
          </Button>
        )}
      </PrintPackageLabel>
    )}
  </>
);

const DuplicateSummaryLot = ({ lot, isPending }) => (
  <>
    <h2>{`${LOTS.LOT}: ${lot.customLotNumber || lot.grgLotNumber}`}</h2>
    {isAndroid ? (
      <>
        <Button outline id="printLotLabelMobile" type="button" disabled={isPending}>
          <PrintIntent intent={printLotLabelUrl(lot)} fallback={`/lots/print-label/${lot._id}`}>
            {LOTS.PRINT_LOT_LABEL_ZEBRA}
          </PrintIntent>
        </Button>
        <Button outline id="printLotLabelMobileEbs" type="button" disabled={isPending}>
          <PrintIntent
            intent={printLotLabelEbsUrl(lot)}
            fallback={`/lots/print-label/${lot._id}`}
            pkg="com.globalrefininggroup.checkinlabels.bluetoothprinterEBS"
          >
            {LOTS.PRINT_LOT_LABEL_EBS}
          </PrintIntent>
        </Button>
      </>
    ) : (
      <PrintLotLabel
        path={`/lots/print-label/${lot._id}`}
        errorMessage={LOTS.PRINT_LOT_LABEL_ERROR}
      >
        {({ onClick }) => (
          <Button outline id="printLotLabel" type="button" onClick={onClick} disabled={isPending}>
            {LOTS.PRINT_LOT_LABEL}
          </Button>
        )}
      </PrintLotLabel>
    )}
  </>
);

const DuplicateSummary = ({ lot, pkg, isPending }) => (
  <DuplicateSummaryStyled>
    {pkg && <DuplicateSummaryPackage pkg={pkg} isPending={isPending} />}
    {lot && <DuplicateSummaryLot lot={lot} isPending={isPending} />}
  </DuplicateSummaryStyled>
);

export { DuplicateSummary };
