import axios from 'axios';

import { createMultipleFilesFormData } from 'shared/helpers/dataParsers/files/createMultipleFilesFormData';

const API_HOST_AUTH = process.env.REACT_APP_API;

const addBillOfLadingType = 'ADD_BILL_OF_LADING';
const addBillOfLading = billOfLading => {
  return {
    type: addBillOfLadingType,
    payload: { billOfLading },
  };
};

const clearBillOfLadingType = 'CLEAR_BILL_OF_LADING';
const clearBillOfLading = () => {
  return {
    type: clearBillOfLadingType,
  };
};

const uploadBillOfLadingType = 'UPLOAD_BILL_OF_LADING';
const uploadBillOfLadingAction = () => ({
  type: uploadBillOfLadingType,
});

const uploadBillOfLadingSuccessType = `${uploadBillOfLadingType}_SUCCESS`;
const uploadBillOfLadingSuccessAction = () => ({
  type: uploadBillOfLadingSuccessType,
});

const uploadBillOfLadingFailureType = `${uploadBillOfLadingType}_FAILURE`;
const uploadBillOfLadingFailureAction = () => ({
  type: uploadBillOfLadingFailureType,
});

const uploadBillOfLading =
  (lotId, { files, existingLotFiles }) =>
  dispatch => {
    const data = createMultipleFilesFormData({ files, existingLotFiles });

    dispatch(uploadBillOfLadingAction());

    return axios
      .patch(`${API_HOST_AUTH}/lots/upload-bill-of-lading/${lotId}`, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then(res => {
        dispatch(uploadBillOfLadingSuccessAction());

        return res.data;
      })
      .catch(err => {
        dispatch(uploadBillOfLadingFailureAction());

        return Promise.reject(err.data);
      });
  };

export {
  addBillOfLadingType,
  addBillOfLading,
  clearBillOfLading,
  clearBillOfLadingType,
  uploadBillOfLadingType,
  uploadBillOfLadingSuccessType,
  uploadBillOfLadingFailureType,
  uploadBillOfLading,
};
