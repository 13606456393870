import PropTypes from 'prop-types';
import { useEffect, useContext } from 'react';

import { WizardContext } from 'shared/components/Formik/Wizard/Wizard';

const FormWrapper = ({ children, pageIndex }) => {
  const { currentStep, updatePageIndexes } = useContext(WizardContext);

  useEffect(() => {
    updatePageIndexes(pageIndex);
  }, []);

  return currentStep === pageIndex ? children : null;
};

PropTypes.propTypes = {
  pageIndex: PropTypes.number.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export { FormWrapper };
