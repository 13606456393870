import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getLotByBarcodeType = 'GET_LOT_BY_BARCODE';
const getLotByBarcodeAction = () => ({
  type: getLotByBarcodeType,
});

const getLotByBarcodeSuccessType = `${getLotByBarcodeType}_SUCCESS`;
const getLotByBarcodeSuccessAction = lot => ({
  type: getLotByBarcodeSuccessType,
  payload: { lot },
});

const getLotByBarcodeFailureType = `${getLotByBarcodeType}_FAILURE`;
const getLotByBarcodeFailureAction = () => ({
  type: getLotByBarcodeFailureType,
});

const getLotByBarcode = grgLotNumber => dispatch => {
  dispatch(getLotByBarcodeAction());

  return axios
    .get(`${API_HOST_AUTH}/lots/get-by-barcode/${encodeURIComponent(grgLotNumber)}`)
    .then(res => {
      dispatch(getLotByBarcodeSuccessAction(res.data));

      return res.data;
    })
    .catch(err => {
      dispatch(getLotByBarcodeFailureAction());

      return Promise.reject(err.data);
    });
};

export {
  getLotByBarcodeType,
  getLotByBarcodeSuccessType,
  getLotByBarcodeFailureType,
  getLotByBarcode,
};
