import React, { Component } from 'react';

import { DatepickerDropdown } from 'shared/components/DatepickerDropdown/DatepickerDropdown';
import { FiltersDropdown } from 'shared/components/FiltersDropdown/FiltersDropdown';
import { LocationsDropdown } from 'shared/components/LocationsDropdown/LocationsDropdown';
import {
  incomingShipmentStatuses,
  outgoingShipmentStatuses,
} from 'shared/helpers/constants/shipments/shipmentFilters';
import { SHIPMENTS } from 'shared/helpers/constants/shipments/shipmentsConstants';
import { isCustomerOrGrading } from 'shared/helpers/matchers/checkUserType';

import dateAdapter from 'utils/date/dateAdapter';
import { MomentAdapter } from 'utils/date/momentAdapter';

import { StyledShipmentFilters, StyledShipmentFiltersItem } from './StyledShipmetFilters';

class ShipmentsFilters extends Component {
  componentDidUpdate(prevProps) {
    const { sentFromCompany } = this.props.filters;
    if (sentFromCompany._id !== prevProps.filters.sentFromCompany._id) {
      this.getCompaniesLocations(sentFromCompany._id);
    }
  }

  filterFunction = name => value => {
    this.props.onFiltersChanged({
      ...this.props.filters,
      [name]: value instanceof MomentAdapter ? value.valueOf() : value,
    });
  };

  renderDateFilter = (name, label, minDate, maxDate, selected) => (
    <div className="ShipmentsFilters-item">
      <DatepickerDropdown
        label={label}
        selected={dateAdapter(selected)}
        filterFunction={this.filterFunction(name)}
        minDate={dateAdapter(minDate || 0)}
        maxDate={dateAdapter(maxDate)}
      />
    </div>
  );

  getCompaniesLocations = relatedCompanyId => {
    if (relatedCompanyId) {
      this.props.getCompanyLocations(relatedCompanyId);
    }
  };

  render() {
    const {
      shipmentDirection,
      filters: { estDeliveryDate, estPickupDate, sentFromCompany, sentFromCompanyLocation, status },
      auth: {
        user: { userType },
      },
      companyLocations,
      companyIncomingShipment,
      sentFromCompanyFilterAction,
      sentFromCompanyLocationFilterAction,
    } = this.props;
    const isCompanyLocationDisabled = isCustomerOrGrading(userType) ? false : !sentFromCompany._id;

    return (
      <StyledShipmentFilters>
        <StyledShipmentFiltersItem>
          <FiltersDropdown
            label={SHIPMENTS.STATUS}
            options={Object.values(
              shipmentDirection === SHIPMENTS.SHIPMENT_TYPES.INCOMING
                ? incomingShipmentStatuses
                : outgoingShipmentStatuses,
            )}
            selected={status || ''}
            filterFunction={this.filterFunction('status')}
          />
        </StyledShipmentFiltersItem>
        {shipmentDirection === SHIPMENTS.SHIPMENT_TYPES.INCOMING &&
          !isCustomerOrGrading(userType) && (
            <StyledShipmentFiltersItem>
              <LocationsDropdown
                label={SHIPMENTS.SENT_FROM_COMPANY}
                options={companyIncomingShipment.data}
                selected={sentFromCompany || ''}
                filterFunction={sentFromCompanyFilterAction}
                filterOptionsSelected={this.filterFunction('sentFromCompany')}
                isSearchable
              />
            </StyledShipmentFiltersItem>
          )}
        {shipmentDirection === SHIPMENTS.SHIPMENT_TYPES.INCOMING && (
          <StyledShipmentFiltersItem>
            <LocationsDropdown
              label={SHIPMENTS.SENT_FROM_COMPANY_LOCATION}
              options={companyLocations.data}
              selected={sentFromCompany ? sentFromCompanyLocation || '' : ''}
              filterFunction={sentFromCompanyLocationFilterAction}
              filterOptionsSelected={this.filterFunction('sentFromCompanyLocation')}
              disabled={isCompanyLocationDisabled}
            />
          </StyledShipmentFiltersItem>
        )}
        {shipmentDirection === SHIPMENTS.SHIPMENT_TYPES.INCOMING && (
          <>
            {this.renderDateFilter(
              'estPickupDateStart',
              SHIPMENTS.EST_PICKUP_DATE_FROM,
              0,
              estPickupDate.to,
              estPickupDate.from,
            )}
            {this.renderDateFilter(
              'estPickupDateEnd',
              SHIPMENTS.EST_PICKUP_DATE_TO,
              estPickupDate.from,
              Infinity,
              estPickupDate.to,
            )}
          </>
        )}
        {shipmentDirection === SHIPMENTS.SHIPMENT_TYPES.OUTGOING && (
          <>
            {this.renderDateFilter(
              'estPickupDateStart',
              SHIPMENTS.SHIP_DATE_FROM,
              0,
              estPickupDate.to,
              estPickupDate.from,
            )}
            {this.renderDateFilter(
              'estPickupDateEnd',
              SHIPMENTS.SHIP_DATE_TO,
              estPickupDate.from,
              Infinity,
              estPickupDate.to,
            )}
          </>
        )}
        {this.renderDateFilter(
          'estDeliveryDateStart',
          SHIPMENTS.EST_DELIVERY_DATE_FROM,
          0,
          estDeliveryDate.to,
          estDeliveryDate.from,
        )}
        {this.renderDateFilter(
          'estDeliveryDateEnd',
          SHIPMENTS.EST_DELIVERY_DATE_TO,
          estDeliveryDate.from,
          Infinity,
          estDeliveryDate.to,
        )}
      </StyledShipmentFilters>
    );
  }
}

export { ShipmentsFilters };
