import React, { useCallback, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';

import {
  getMarketIndicators as getMarketIndicatorsAction,
  clearMarketIndicators as clearMarketIndicatorsAction,
} from 'actions/MarketIndicators/getMarketIndicators';
import { updateMarketIndicators as updateMarketIndicatorsAction } from 'actions/MarketIndicators/updateMarketIndicators';
import { getNotificationsCount as getNotificationsCountAction } from 'actions/Notifications/notificationCount';
import { refreshNotification as refreshNotificationAction } from 'actions/Notifications/notificationDetails';
import { showSnackbar as showSnackbarAction } from 'actions/shared/snackbar';

import { FormContainer } from 'shared/components/Form';
import {
  dangerOptions,
  successOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { type as marketIndicatorsType } from 'shared/helpers/constants/systemSettings/marketIndicators/requestMarketIndicators';
import { UPDATE_MARKET_INDICATORS } from 'shared/helpers/constants/systemSettings/marketIndicators/updateMarketIndicators';
import { addConfirmation, removeConfirmation } from 'shared/helpers/parsers/confirmation';
import { normalizeObject } from 'shared/helpers/parsers/normalizeObject';
import { removeCommasAndParse } from 'shared/helpers/parsers/text';

import { UpdateMarketIndicatorsForm } from './components/UpdateMarketIndicatorsForm';

function UpdateMarketIndicatorsBase({
  marketIndicators,
  refreshNotification,
  getMarketIndicators,
  updateMarketIndicators,
  getNotificationsCount,
  showSnackbar,
  history,
  match: { params },
}) {
  const indicators = marketIndicators && marketIndicators.indicators;
  const { type } = params;

  const onSubmit = useCallback(
    values => {
      const normalizer = val => removeCommasAndParse(val);
      const parsedValues = removeConfirmation(normalizeObject(values, normalizer));

      return updateMarketIndicators({
        ...(type === marketIndicatorsType.hedging && {
          hedgingPlatinumPrice: parsedValues.hedgingPlatinumPrice,
          hedgingPalladiumPrice: parsedValues.hedgingPalladiumPrice,
          hedgingRhodiumPrice: parsedValues.hedgingRhodiumPrice,
        }),
        ...(type === marketIndicatorsType.settlement && {
          settlementPlatinumPrice: parsedValues.settlementPlatinumPrice,
          settlementPalladiumPrice: parsedValues.settlementPalladiumPrice,
          settlementRhodiumPrice: parsedValues.settlementRhodiumPrice,
        }),
        type,
      })
        .then(({ message }) => {
          refreshNotification();
          getNotificationsCount();
          showSnackbar(successOptions, message);
          history.push('/settings');
        })
        .catch(({ message }) => showSnackbar(dangerOptions, message));
    },
    [type, refreshNotification, updateMarketIndicators, showSnackbar, history],
  );

  useEffect(() => {
    getMarketIndicators().catch(({ message }) => {
      showSnackbar(dangerOptions, message);
      history.push('/settings');
    });
  }, [getMarketIndicators, showSnackbar, history]);

  const initialValues = useMemo(
    () =>
      indicators &&
      addConfirmation(
        {
          ...indicators.hedging,
          ...indicators.settlement,
        },
        value => {
          if (typeof value === 'number') return String(value);
          return value;
        },
      ),
    [indicators],
  );

  return (
    <FormContainer header={UPDATE_MARKET_INDICATORS.UPDATE_MARKET_INDICATORS}>
      <UpdateMarketIndicatorsForm onSubmit={onSubmit} initialValues={initialValues} />
    </FormContainer>
  );
}

const mapStateToProps = ({ getMarketIndicators: { marketIndicators, isPending } }) => ({
  marketIndicators,
  isPending,
});

const mapDispatchToProps = {
  getMarketIndicators: getMarketIndicatorsAction,
  clearMarketIndicators: clearMarketIndicatorsAction,
  updateMarketIndicators: updateMarketIndicatorsAction,
  showSnackbar: showSnackbarAction,
  refreshNotification: refreshNotificationAction,
  getNotificationsCount: getNotificationsCountAction,
};

const UpdateMarketIndicators = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
)(UpdateMarketIndicatorsBase);

export { UpdateMarketIndicatorsBase, UpdateMarketIndicators };
