import React from 'react';

import { PLACEHOLDERS } from 'shared/helpers/constants/placeholders';

import { StyledSearchInput } from './StyledSearchInput';

function SearchInput({ input, searchRef }) {
  return (
    <StyledSearchInput
      {...input}
      ref={searchRef}
      id={input.name}
      data-testid={input.name}
      autoComplete="off"
      className="SearchInput"
      placeholder={PLACEHOLDERS.SEARCH}
    />
  );
}

export { SearchInput };
