import React from 'react';

import { StyledGhostIcon } from './StyledGhostIcon';

function GhostIcon({ color, size }) {
  return (
    <div className="GhostIcon">
      <StyledGhostIcon className={`icon icon-ghost ${color} ${size}`} />
    </div>
  );
}

export { GhostIcon };
