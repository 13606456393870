import React from 'react';

import { StyledPages, StyledWrapper } from './StyledPages';

function Pages(props) {
  return (
    <StyledWrapper>
      <p>PAGES</p>
      <StyledPages
        pageCount={props.pages}
        pageRangeDisplayed={3}
        marginPagesDisplayed={1}
        forcePage={props.page - 1}
        containerClassName="Pages"
        pageClassName="Pages-page"
        pageLinkClassName="Pages-link"
        activeClassName="Pages-active"
        previousClassName="Pages-previous"
        nextClassName="Pages-next"
        previousLinkClassName="icon icon-tiny-arrow-left cursor"
        nextLinkClassName="icon icon-tiny-arrow-right cursor"
        previousLabel=""
        nextLabel=""
        onPageChange={page => props.setPage(page.selected + 1)}
      />
    </StyledWrapper>
  );
}

export { Pages };
