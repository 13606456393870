import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { downloadAttachment } from 'actions/Attachments/downloadAttachment';
import { clearNotesList, getNotes } from 'actions/Notes/notesList';

import { ListAppend } from 'shared/components/ListAppend/ListAppend';
import { NotesItem } from 'shared/components/Notes/NotesPanel/NotesItem/NotesItem';
import { NOTE } from 'shared/helpers/constants/noteConstants';

const NotesList = ({ relatedType, subType, match }) => {
  const dispatch = useDispatch();
  const [listLimit, setListLimit] = useState(3);
  const [listPage] = useState(1);
  const [listAppendAvailable, setAppendAvailable] = useState(false);
  const notesList = useSelector(state => state.notesList);
  const subTypeNotes = subType ? notesList.notes[subType] : [];
  const elements = [...notesList.notes[relatedType], ...subTypeNotes].sort(
    (a, b) => b.createdAt - a.createdAt,
  );

  const handleChangeListAppend = (total = {}) => {
    setAppendAvailable(() => Math.ceil(total[relatedType] / listLimit) > listPage);
  };

  const listAppend = (limit = 3) => {
    const updatedListLimit = listLimit + limit;
    setListLimit(updatedListLimit);

    dispatch(
      getNotes({
        relatedType,
        subType,
        listPage,
        relatedId: match.params.id,
        listLimit: updatedListLimit,
      }),
    ).then(({ total }) => handleChangeListAppend(total));
  };

  useEffect(() => {
    dispatch(
      getNotes({
        relatedType,
        subType,
        listLimit,
        listPage,
        relatedId: match.params.id,
      }),
    ).then(notes => handleChangeListAppend(notes?.total));

    return () => dispatch(clearNotesList());
  }, []);

  return (
    !!elements?.length && (
      <div>
        {elements.map(note => (
          <NotesItem
            onClick={path => dispatch(downloadAttachment(path))}
            listAppendAvailable={listAppendAvailable}
            listAppend={listAppend}
            note={note}
            key={note._id}
            showLabel={note.type === NOTE.NOTES_TYPE.PROCESSING}
            lotId={match.params.id}
            className="noteElement"
          />
        ))}
        {listAppendAvailable && (
          <ListAppend onClick={() => listAppend()} loading={notesList.isPending} />
        )}
      </div>
    )
  );
};

NotesList.propTypes = {
  relatedType: PropTypes.oneOf(Object.values(NOTE.NOTES_TYPE)),
  subType: PropTypes.oneOf(Object.values(NOTE.NOTES_TYPE)),
  match: PropTypes.shape({
    isExact: PropTypes.bool,
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
    path: PropTypes.string,
    url: PropTypes.string,
  }),
};

export { NotesList };
