import axios from 'axios';

import { parseHedgesFilters } from 'shared/helpers/dataParsers/hedges/parseHedgesFilters';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getHedgesActionType = 'GET_HEDGES_LIST';
const getHedgesAction = (filters, pagination) => ({
  type: getHedgesActionType,
  payload: { filters, pagination },
});

const getHedgesSuccessActionType = `${getHedgesActionType}_SUCCESS`;
const getHedgesSuccessAction = hedges => ({
  type: getHedgesSuccessActionType,
  payload: { hedges },
});

const getHedgesFailureActionType = `${getHedgesActionType}_FAILURE`;
const getHedgesFailureAction = () => ({
  type: getHedgesFailureActionType,
});

const clearHedgesActionType = `${getHedgesActionType}_CLEAR`;
const clearHedgesAction = () => ({
  type: clearHedgesActionType,
});

const getHedges = (filters, pagination) => dispatch => {
  const parsedFilters = parseHedgesFilters(filters);

  dispatch(getHedgesAction(parsedFilters, pagination));

  return axios
    .get(`${API_HOST_AUTH}/hedges`, {
      params: {
        ...parsedFilters,
        ...pagination,
      },
    })
    .then(({ data }) => {
      dispatch(getHedgesSuccessAction(data));
      return data;
    })
    .catch(error => {
      dispatch(getHedgesFailureAction());
      return Promise.reject(error.data);
    });
};

export {
  clearHedgesAction,
  clearHedgesActionType,
  getHedgesActionType,
  getHedgesSuccessActionType,
  getHedgesFailureActionType,
  getHedges,
};
