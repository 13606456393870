import { string, object, array, boolean, date } from 'yup';

import { message } from 'shared/helpers/constants/reports/validationConstraints';

export const ClientLotReportFormSchema = object()
  .shape({
    includeAllCompanies: boolean().required(),
    companies: array()
      .of(object().shape({ value: string(), label: string() }))
      .when('includeAllCompanies', {
        is: false,
        then: array().min(1, message.COMPANIES_EMPTY).required(message.COMPANIES_REQUIRED),
        otherwise: array().max(0, message.COMPANIES_NOT_ALLOWED),
      }),
    includeAllLocations: boolean().required(),
    companyLocations: array()
      .of(object().shape({ value: string(), label: string() }))
      .when('includeAllLocations', {
        is: false,
        then: array().min(1, message.LOCATIONS_EMPTY).required(message.LOCATIONS_REQUIRED),
        otherwise: array().max(0, message.LOCATIONS_NOT_ALLOWED),
      }),
    dateFrom: date()
      .required(message.DATE_FROM_REQUIRED)
      .max(new Date(), message.DATE_FROM_LATER_THAN_TODAY),
    dateTo: date()
      .required(message.DATE_TO_REQUIRED)
      .max(new Date(), message.DATE_TO_LATER_THAN_TODAY),
  })
  .test('date-range', message.DATE_RANGE_ERROR, function (values) {
    const { dateFrom, dateTo } = values;
    return !dateFrom || !dateTo || dateFrom <= dateTo;
  });
