import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { destroy } from 'redux-form';

import { createHedgesRequests } from 'actions/Hedges/hedgesCreate';
import { filterCompanies, getLotsForHedges } from 'actions/Hedges/hedgesManagement';
import {
  getHedgesSuggestedValues,
  getHedgesSuggestedValuesAction,
} from 'actions/Hedges/hedgesSuggestedValues';
import {
  getSuggestedDeliveryDate,
  clearSuggestedDeliveryDate,
} from 'actions/Hedges/suggestedDeliveryDate';
import { getPackagesOverviewForLots } from 'actions/Packages/packagesOverviewList';
import { openModal } from 'actions/shared/modal';
import { saveSharedData, clearSharedData } from 'actions/shared/saveSharedData';
import { showSnackbar } from 'actions/shared/snackbar';

import { HEDGES } from 'shared/helpers/constants/hedges/hedgesConstants';

import { HedgesCreate } from './HedgesCreate';

function mapStateToProps(state) {
  const form = state.form[HEDGES.HEDGES_FORM];
  const mappedValues =
    form && form.values && form.values.hedges
      ? form.values.hedges.map(hedge => ({
          ...hedge,
          hedgeType: hedge.hedgeType && hedge.hedgeType.value,
          materialType: hedge.materialType && hedge.materialType.value,
        }))
      : [];

  return {
    auth: state.auth,
    router: state.router,
    modal: state.modal,
    hedgesManagement: state.hedgesManagement,
    hedgesValues: { hedges: mappedValues },
    hedgesActiveFields: form && form.registeredFields,
    lots: state.hedgesManagement && state.hedgesManagement.lots,
    suggestedDeliveryDate: state.suggestedDeliveryDate,
    hedgesSuggestedValues: state.hedgesSuggestedValues,
    createHedges: state.createHedges,
    packagesOverviewList: state.packagesOverviewList,
    sharedData: state.sharedData,
  };
}

const mapDispatchToProps = {
  getPackagesOverviewForLots,
  filterCompanies,
  getLotsForHedges,
  createHedgesRequests,
  getHedgesSuggestedValues,
  getSuggestedDeliveryDate,
  clearSuggestedDeliveryDate,
  openModal,
  showSnackbar,
  destroy,
  getHedgesSuggestedValuesAction,
  saveSharedData,
  clearSharedData,
};

const ConnectedHedgesCreate = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HedgesCreate),
);

export { ConnectedHedgesCreate };
