import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getUsersActionType = 'GET_USERS';
function getUsersAction() {
  return {
    type: getUsersActionType,
  };
}

const getUsersSuccessActionType = 'GET_USERS_SUCCESS';
function getUsersSuccessAction(users, pages, total) {
  return {
    type: getUsersSuccessActionType,
    payload: {
      users,
      pages,
      total,
      items: users,
    },
  };
}

const getUsersFailureActionType = 'GET_USERS_FAILURE';
function getUsersFailureAction(errorMessage) {
  return {
    type: getUsersFailureActionType,
    payload: {
      errorMessage,
    },
  };
}

const clearUsersListActionType = 'CLEAR_USERS_LIST_ACTION';
function clearUsersList() {
  return {
    type: clearUsersListActionType,
  };
}

function companyDetailsGetUsers(companyId, limit, page) {
  return dispatch => {
    dispatch(getUsersAction());

    return axios
      .get(`${API_HOST_AUTH}/users/companies/${companyId}/${page}/${limit}`)
      .then(res => {
        dispatch(getUsersSuccessAction(res.data.docs, res.data.pages, res.data.total));
      })
      .catch(() => {
        dispatch(getUsersFailureAction(''));
      });
  };
}

export {
  getUsersActionType,
  getUsersSuccessActionType,
  getUsersFailureActionType,
  clearUsersListActionType,
  companyDetailsGetUsers,
  clearUsersList,
};
