import {
  checkCustomLotNumberActionType,
  checkCustomLotNumberSuccessActionType,
  checkCustomLotNumberFailureActionType,
} from 'actions/Lots/checkCustomLotNumber';

const INITIAL_STATE = { isPending: false };

const checkCustomLotNumberReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case checkCustomLotNumberActionType:
      return { isPending: true };
    case checkCustomLotNumberSuccessActionType:
      return { isPending: false };
    case checkCustomLotNumberFailureActionType:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};

export { checkCustomLotNumberReducer };
