import { SHARED } from 'shared/helpers/constants/sharedConstants';

import { resolveVersion, versions } from 'utils/versioning';

const COMPANY = {
  GENERAL_DETAILS: 'General details',
  DUE_DILIGENCE: 'Due diligence',
  CONTACT_PERSON: 'Contact person',
  ACTIVITY: 'Activity',
  EMAIL: 'Email',
  OFFICE_PHONE: 'Office phone',
  CREATE_A_COMPANY: `Create a${SHARED.NON_BREAKING_SPACE}company`,
  GENERATE_REPORT: 'Generate report',
  CREATING_COMPANY: 'Creating a company',
  COMPANY_CREATED: 'Company has been created.',
  COMPANIES: 'Companies',
  COMPANY_CREATED_WITHOUT_NOTES:
    'Company has been created, but there was a problem with notes creation.',
  COMPANY_CREATE_ERROR: 'Company has not been created.',
  EDIT_COMPANY: 'Edit company',
  COMPANY_TYPE: 'Type',
  COMPANY_NAME: 'Company name',
  COMPANY_NUMBER: 'Number',
  LOT_PREFIX: 'Lot prefix',
  LOT_PREFIX_PREFIX: resolveVersion({
    [versions.alpha]: 'AG',
    [versions.global]: 'GG',
    [versions.west]: 'GW',
  }),
  VOLUME: 'Volume',
  REFERRAL_METHOD: 'Referral method',
  MATERIAL_COUNT_REQUIRED: 'Material count required?',
  SAMPLES_ALLOWED: 'Samples allowed',
  INDIVIDUAL_ASSAYS_ALLOWED: 'Individual assays allowed',
  ONLY_INTERNAL_HEDGING_AND_SETTLEMENT: 'Only internal hedging and settlement',
  SHIPPING_LOCATION: 'Shipping location',
  ONLY_INTERNAL_HEDGING_SETTLEMENT: 'Only internal hedging/settlement',
  GRG_DEFAULT_SHIPPING_LOCATION: 'Default shipping location',
  PURCHASING_APP_URL: 'Purchasing app URL',
  NOTE: 'Note',
  LOCATION: 'Location',
  PRIMARY_LOCATION: 'Primary location',
  PRIMARY_CONTACT: 'Primary contact',
  PRIMARY_CONTACTS: 'Primary contacts',
  STATUS: 'Status',
  ACTIONS: 'Actions',
  CREATED_BY: 'Created by',
  UPDATED_BY: 'Last updated by',
  UPLOADED_BY: 'Uploaded by',
  INACTIVATE_COMPANY: 'Inactivate company',
  INACTIVATE_COMPANY_ERROR: 'Company has not been inactivated.',
  REACTIVATE_COMPANY: 'Reactivate company',
  REACTIVATE_COMPANY_ERROR: 'Company has not been reactivated.',
  COMPANY_INFO: 'Company info',
  COMPANY: 'Company',
  ASSOCIATIONS: 'Associations',
  USERS_LOCATIONS: 'Users locations',
  USER: 'User',
  PAYOUT_RULE: 'Payout rule',
  BALANCE_REMAINING: 'Company balance',
  PAYOUT_RULE_ADDED: 'Payout rule added',
  PHOTO_ID: 'Photo ID',
  EXPIRES: 'Expires',
  AML_CHECK: 'AML Check',
  BUSINESS_LICENSE: 'Business License',
  MATERIAL_AGREEMENT: 'Responsible Material Sourcing Agreement',
  MATERIAL_AGREEMENT_WEST: 'Signed Client Intake Form',
  LOCATION_VERIFICATION: 'Location verification',
  DUE_DILIGENCE_QUESTIONNAIRE: 'Questionnaire',
  VERIFICATION_NOTES: 'Verification Notes',
  LOCATION_VERIFICATION_NOTES_TITLE: 'Add location verification note',
  DUE_DILIGENCE_QUESTIONNAIRE_NOTES_TITLE: 'Add questionnaire note',
  VERIFICATION_NOTES_TITLE: 'Add verification note',
  OUTSTANDING_BALANCE_EMPTY: 'Outstanding balance cannot be empty.',
  OUTSTANDING_BALANCE_INVALID: 'Outstanding balance must have a valid format.',
  OUTSTANDING_BALANCE_RANGE: 'Outstanding balance cannot be greater than zero.',
  UPDATE_OUTSTANDING_BALANCE: 'Update balance (admin only)',
  UPDATE_OUTSTANDING_BALANCE_TITLE: 'Update company outstanding balance',
};

export { COMPANY };
