import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { change, Field } from 'redux-form';

import { AddButton } from 'shared/components/Buttons/AddButton/AddButton';
import { IconButton } from 'shared/components/Buttons/IconButton/IconButton';
import { FieldInput, FieldSelect } from 'shared/components/Fields';
import { Section, Separator } from 'shared/components/Form';
import { PAYMENTS } from 'shared/helpers/constants/payments/paymentsConstants';
import { parseLotsSelectData } from 'shared/helpers/dataParsers/lot/parseLotsSelectData';
import { safeParseFloat } from 'shared/helpers/parsers/number';
import { cssVar } from 'shared/helpers/styling/styling';

const deleteAction = (fields, index) => (
  <IconButton onClick={() => fields.remove(index)} icon="icon-cancel" color={cssVar('whiteGRG')} />
);

const FinanceCharges = ({ fields, isRequired, relatedLots, charges, form }) => {
  const dispatch = useDispatch();
  const addSection = () => fields.push({});

  useEffect(() => {
    isRequired && !fields.length && fields.push({});
  }, []);

  const handleClearChargesFields = (field, value = 0) => {
    if (safeParseFloat(value) === 0) {
      dispatch(change(form, `financeCharges.${field}.relatedLot`, null));
      dispatch(change(form, `financeCharges.${field}.financeRate`, null));
    }
  };

  return (
    <>
      {fields.map((field, index) => {
        const financeChargeValue = safeParseFloat(charges[index].financeCharge);
        const chargesFieldsAvaible = financeChargeValue && financeChargeValue !== 0;

        return (
          <Section
            header={`${PAYMENTS.FINANCE_CHARGE} ${index + 1}`}
            template={[
              `${field}.financeCharge ${chargesFieldsAvaible ? `${field}.relatedLot` : '.'}`,
              `${field}.financeRate .`,
            ]}
            key={field}
            actions={deleteAction(fields, index)}
          >
            <Field
              name={`${field}.financeCharge`}
              component={FieldInput}
              label={PAYMENTS.FINANCE_CHARGE}
              type="text"
              field="required"
              prefix="$"
              maxLength={15}
              onChange={({ target: { value } }) => handleClearChargesFields(field, value)}
            />
            {chargesFieldsAvaible && (
              <Field
                name={`${field}.relatedLot`}
                component={FieldSelect}
                options={parseLotsSelectData(relatedLots)}
                disabled={
                  !charges[index].financeCharge ||
                  safeParseFloat(charges[index].financeCharge) === 0
                }
                label={PAYMENTS.RELATED_LOT}
                field="required"
              />
            )}
            {chargesFieldsAvaible && (
              <Field
                name={`${field}.financeRate`}
                component={FieldInput}
                label={PAYMENTS.FINANCE_RATE}
                type="text"
                field="required"
                suffix="%"
                disabled={
                  !charges[index].financeCharge ||
                  safeParseFloat(charges[index].financeCharge) === 0
                }
                maxLength={15}
              />
            )}
          </Section>
        );
      })}
      {fields.length > 0 && <Separator />}
      {fields.length < 10 && (
        <AddButton onClick={addSection}>{PAYMENTS.ADD_NEW_FINANCE_CHARGE}</AddButton>
      )}
    </>
  );
};

export { FinanceCharges };
