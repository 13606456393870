import PropTypes from 'prop-types';
import React, { createContext } from 'react';

const filterContext = createContext();

const FieldsFilter = ({ children, filterFunc }) => (
  <filterContext.Provider value={filterFunc}>{children}</filterContext.Provider>
);

FieldsFilter.propTypes = {
  filterFunc: PropTypes.func.isRequired,
};

export { FieldsFilter, filterContext };
