import React from 'react';
import { Field } from 'redux-form';

import { AddButton } from 'shared/components/Buttons/AddButton/AddButton';
import { IconButton } from 'shared/components/Buttons/IconButton/IconButton';
import { FieldCapitalized } from 'shared/components/Fields/FieldCapitalized/FieldCapitalized';
import { FieldInput } from 'shared/components/Fields/FieldInput/FieldInput';
import { Section } from 'shared/components/Form';
import { Separator } from 'shared/components/Form/Separator/Separator';
import { lotLocationSuffix } from 'shared/helpers/constants/lots/lotLocation';
import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';
import { cssVar } from 'shared/helpers/styling/styling';

const LotLocationSectionsList = ({ fields }) => {
  const addSection = () => fields.push({});

  return (
    <>
      {fields.map((field, index) => {
        const actions = (
          <IconButton
            onClick={() => fields.remove(index)}
            icon="icon-cancel"
            color={cssVar('whiteGRG')}
          />
        );
        return (
          <Section
            header={`${LOTS.LOCATION_STORED_PLACE} ${index + 1}`}
            template={[`${field}.section ${field}.lane`]}
            key={index}
            actions={fields.length > 1 && actions}
          >
            <Field
              name={`${field}.section`}
              component={FieldInput}
              type="number"
              label={LOTS.SECTION}
              field="required"
              suffix={lotLocationSuffix.section}
            />
            <FieldCapitalized
              name={`${field}.lane`}
              component={FieldInput}
              label={LOTS.LANE}
              suffix={lotLocationSuffix.lane}
            />
          </Section>
        );
      })}
      <Separator />
      <AddButton onClick={addSection}>{LOTS.LOCATION_STORED_ADD}</AddButton>
    </>
  );
};

export { LotLocationSectionsList };
