import dateAdapter from 'utils/date/dateAdapter';

import { SHARED } from '../constants/sharedConstants';
import { dateFormat } from './date';

const isNumber = value => (Number.isFinite(parseFloat(value)) ? value.toString() : false);

const isNumberOrString = value => isNumber(value) || (typeof value === 'string' && value.trim());

const formatDate = (date, format = dateFormat) => (date ? dateAdapter(date).format(format) : null);

const formatToString = value => (isNumber(value) ? value.toString() : value);

const formatToDollar = value => {
  const parsedValue =
    value !== null &&
    value !== undefined &&
    Number(value).toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

  return parsedValue || null;
};

const formatToDollarWithPrefix = value => {
  const dollarBase = formatToDollar(value);

  return dollarBase ? `$${SHARED.NON_BREAKING_SPACE}${dollarBase}` : null;
};

const formatToUS = (value, fractionDigits = 2) =>
  Number.isFinite(value)
    ? new Intl.NumberFormat('en-US', {
        maximumFractionDigits: fractionDigits,
        minimumFractionDigits: fractionDigits,
      }).format(value)
    : null;

export {
  isNumber,
  isNumberOrString,
  formatDate,
  formatToDollar,
  formatToDollarWithPrefix,
  formatToString,
  formatToUS,
};
