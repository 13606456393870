import PropTypes from 'prop-types';
import React, { useState, useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { FieldInput } from '..';

const StyledFieldInput = styled(FieldInput)`
  font-variant-numeric: tabular-nums;
`;

function SecretFieldInput(props) {
  const { input, replacement, alwaysVisible, type, disabled } = props;

  const [focused, setFocused] = useState(false);

  const onFocus = useCallback(
    (...args) => {
      setFocused(true);
      input.onFocus(...args);
    },
    [input],
  );

  const onBlur = useCallback(
    (...args) => {
      setFocused(false);
      input.onBlur(...args);
    },
    [input],
  );

  const value = useMemo(() => {
    if (alwaysVisible) return input.value;

    const parsedValue = input.value === 0 ? `${input.value}` : input.value;

    return focused ? parsedValue : parsedValue && replacement.repeat(parsedValue.toString().length);
  }, [alwaysVisible, focused, input.value, replacement]);

  const inputType = useMemo(() => {
    const existingValue = value === 0 ? value : value || '';
    if (alwaysVisible || existingValue.toString() === '') return type;

    return focused ? type : 'text';
  }, [alwaysVisible, focused, type, value]);

  const preventDefault = useCallback(e => e.preventDefault(), []);

  return (
    <StyledFieldInput
      {...props}
      type={inputType}
      onCopy={preventDefault}
      onCut={preventDefault}
      onPaste={preventDefault}
      disabled={disabled}
      input={{
        ...props.input,
        value,
        onFocus,
        onBlur,
      }}
    />
  );
}

SecretFieldInput.propTypes = {
  replacement: PropTypes.string,
  alwaysVisible: PropTypes.bool,
};

SecretFieldInput.defaultProps = {
  replacement: '●',
  alwaysVisible: false,
};

export { SecretFieldInput };
