import React, { Component, createRef } from 'react';
import SmoothCollapse from 'react-smooth-collapse';
import styled, { css } from 'styled-components';

import { Button } from 'shared/components/Buttons/Button/Button';
import { Input } from 'shared/components/Fields/FieldInput/FieldInput';
import { Icon } from 'shared/components/Icon/Icon';
import { BARCODE_SCANNER } from 'shared/helpers/constants/barcodeScanner/barcodeScannerConstants';
import { functionify } from 'shared/helpers/functions';
import { cssVar, media } from 'shared/helpers/styling/styling';

const arrarify = maybeArray => [].concat(maybeArray);

const lengthOrValue = v => (typeof v === 'string' ? v.length : v);

const states = {
  success: 'success',
  idle: 'idle',
  manualIdle: 'manualIdle',
  waiting: 'waiting',
  error: 'error',
};

const BarcodeScannerWrapper = styled.div`
  color: ${cssVar('alto')};
  display: flex;
  flex-direction: column;
`;

const EnteredInputWrapper = styled.div`
  background-color: ${cssVar('outerSpaceListAppend')};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 50px;
`;

const CurrentScanWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const CurrentScanContent = styled.div`
  height: 100%;
  min-height: 250px;
  display: flex;
  align-items: center;

  ${media.phone`
    min-height: 160px;
  `}
`;

const StyledNextButton = styled(Button)`
  width: 112px;
`;

const CurrentScanIconWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 150px;
  box-sizing: border-box;

  ${({ internal }) =>
    !internal &&
    css`
      border: 4px solid ${cssVar('regentGray')};

      &::before,
      &::after {
        content: '';
        display: block;
        position: absolute;
        width: calc(100% + 8px);
        height: calc(100% - 50px);
        left: -4px;
        top: 25px;
        background: ${cssVar('outerSpaceTabs')};
        z-index: 1;
      }

      &::after {
        height: calc(100% + 8px);
        width: calc(100% - 50px);
        left: 25px;
        top: -4px;
      }
    `}

  ${Icon} {
    position: relative;
    z-index: 2;
  }
`;

const InputManuallyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border-top: 1px solid ${cssVar('dustGray')};
  padding: 50px 0 45px;

  ${media.phone`
    padding: 15px 0;
  `}

  ${Input} {
    min-width: 300px;
    height: 50px;
    padding: 0 38px;
    margin-bottom: 25px;

    ${media.phone`
      margin-bottom: 15px;
  `}
  }

  div div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    & > * {
      flex-direction: row;
      justify-content: space-between;
    }
  }
`;

const ScanningMessage = styled.span`
  color: ${({ type }) => {
    switch (type) {
      case states.success:
        return cssVar('atlantis');
      case states.error:
        return cssVar('japonica');
      default:
        return cssVar('alabaster');
    }
  }};
`;

class BarcodeScannerCore extends Component {
  buffer = [];

  inputRef = createRef();

  temporaryMessage = null;

  clearTimeoutId = null;

  messageTimeoutId = null;

  isComponentMounted = false;

  state = {
    manualInput: false,
    scanningState: states.idle,
    index: 0,
    values: [],
  };

  componentDidMount() {
    this.isComponentMounted = true;
    this.attachKeyboardListener();
  }

  componentWillUnmount() {
    this.removeKeyboardListener();
    clearTimeout(this.messageTimeoutId);
    clearTimeout(this.clearTimeoutId);

    this.isComponentMounted = false;
  }

  onKeyPressed = e => {
    if (e.srcElement.tagName === 'DIV') {
      e.preventDefault();
    }

    if (this.state.manualInput || this.currentState.render) return;

    this.buffer.push(e.key);
    this.scheduleBufferClear();
  };

  onFail(error) {
    const { onFailedScan } = this.props;

    onFailedScan && onFailedScan(this.barcode);

    this.changeScanningState(states.error, error);
  }

  async onSuccess(value) {
    const { onSuccessfulScan } = this.props;
    const { values } = this.state;

    try {
      if (onSuccessfulScan) {
        const message = await onSuccessfulScan(value, ...values);
        this.changeScanningState(states.success, message);
      }
      this.resetForm();
    } catch (error) {
      this.changeScanningState(states.error, error);
    }
  }

  get barcode() {
    return this.buffer.join('');
  }

  get barcodeLengths() {
    return (
      this.currentState.barcodeLength &&
      arrarify(this.currentState.barcodeLength).map(lengthOrValue)
    );
  }

  get currentState() {
    return this.props.idleStates[this.state.index] || {};
  }

  get modalContentElement() {
    return document.querySelector('.ReactModal__Content');
  }

  get icon() {
    const { scanningState } = this.state;

    switch (scanningState) {
      case states.success:
        return {
          icon: 'icon-tick',
          color: cssVar('atlantis'),
          width: 150,
          height: 150,
        };
      case states.error:
        return {
          icon: 'icon-cancel',
          color: cssVar('japonica'),
          width: 150,
          height: 150,
        };
      case states.waiting:
        return {
          icon: 'icon-waiting',
          color: cssVar('fiord'),
          width: 150,
          height: 150,
        };
      default:
        return {
          icon: this.currentState.icon,
          width: 75,
          height: 75,
        };
    }
  }

  getMessage() {
    const { scanningState, manualInput } = this.state;
    const { message } = this.props;

    const { name } = this.currentState;

    const messageFromState = functionify(
      manualInput && scanningState === states.idle
        ? message[states.manualIdle]
        : message[scanningState],
    )(name);
    const messageToDisplay = this.temporaryMessage || messageFromState;

    return messageToDisplay;
  }

  changeScanningState = (newState, temporaryMessage) => {
    this.temporaryMessage = temporaryMessage;
    this.setState({ scanningState: newState });

    clearTimeout(this.messageTimeoutId);
    this.messageTimeoutId = setTimeout(() => {
      this.messageTimeoutId = null;
      this.temporaryMessage = null;

      if (this.isComponentMounted) {
        this.setState({ scanningState: states.idle });
      }
    }, this.props.stateResetTime);
  };

  preventEventDefault = e => e.preventDefault();

  toggleManualInput = () => {
    this.setState(
      ({ manualInput, scanningState }) => ({
        manualInput: !manualInput,
        scanningState: manualInput ? scanningState : states.idle,
      }),
      () => {
        if (this.state.manualInput) {
          this.clearInput();
          this.inputRef.current.focus();
        } else {
          this.modalContentElement.focus();
        }
      },
    );
  };

  submitManualInput = () => {
    const barcode = this.inputRef.current.value;
    this.buffer = barcode.split('');

    this.analyzeInput();
  };

  goToIndex = index => {
    this.setState(state => ({
      index,
      values: state.values.slice(0, index),
    }));
    this.idleStateChanged(index);
    this.clearInput();
  };

  attachKeyboardListener() {
    document.body.addEventListener('keypress', this.onKeyPressed);
  }

  removeKeyboardListener() {
    document.body.removeEventListener('keypress', this.onKeyPressed);
  }

  clearInput() {
    if (this.inputRef.current) {
      this.inputRef.current.value = '';
    }
  }

  analyzeInput() {
    if (this.validateBarcodeFormat()) {
      this.nextState(this.barcode);
      this.clearInput();
    } else {
      this.onFail();
      this.changeScanningState(states.error);
    }
    this.buffer = [];
  }

  idleStateChanged(index) {
    const { idleStateChanged } = this.props;
    idleStateChanged && idleStateChanged(index);
  }

  async nextState(value) {
    const { idleStates } = this.props;
    const { index, values } = this.state;
    const idleStatesLength = idleStates.length;
    const { onChange } = idleStates[index];

    try {
      this.changeScanningState(states.waiting, BARCODE_SCANNER.WAITING);
      const message = await functionify(onChange)(value, ...values);

      if (index < idleStatesLength - 1) {
        const newValues = [...values];
        newValues[index] = value;

        this.setState({ index: index + 1, values: newValues });
        this.idleStateChanged(index + 1);
        this.changeScanningState(states.idle, message);
      } else {
        this.onSuccess(value);
      }
    } catch (error) {
      this.onFail(error);
    }
  }

  cancelBufferClear() {
    clearTimeout(this.clearTimeoutId);
  }

  scheduleBufferClear() {
    this.cancelBufferClear();
    this.clearTimeoutId = setTimeout(() => this.analyzeInput(), this.props.scanningTimeSlice);
  }

  resetForm = () => {
    const { idleStates } = this.props;
    const { values } = this.state;

    const firstNonPersistentStep = idleStates.findIndex(({ persistent }) => !persistent);
    const previousValues = values.slice(0, firstNonPersistentStep);

    this.setState({ index: firstNonPersistentStep, values: previousValues });
    this.idleStateChanged(firstNonPersistentStep);
  };

  validateBarcodeFormat() {
    const defaultFormat = /^[a-z0-9]+$/i;
    const barcodeFormat = this.currentState.barcodeFormat || defaultFormat;
    const lengthValid = !this.barcodeLengths || this.barcodeLengths.includes(this.barcode.length);

    return this.barcode.match(barcodeFormat) && lengthValid;
  }

  submitOnEnter = e => {
    if (e.keyCode === 13) return this.submitManualInput();
  };

  renderManualInput() {
    const { manualInput } = this.state;
    return (
      <>
        {!manualInput && (
          <Button
            outline
            id="manuallyEnterBarcode"
            onClick={this.toggleManualInput}
            onKeyDown={this.preventEventDefault}
          >
            {BARCODE_SCANNER.ENTER_MANUALLY}
          </Button>
        )}
        <SmoothCollapse expanded={manualInput}>
          <Input
            ref={this.inputRef}
            type="text"
            data-testid="packageId"
            onKeyDown={this.submitOnEnter}
          />
          <div>
            <Button
              outline
              onClick={this.toggleManualInput}
              onKeyDown={this.preventEventDefault}
              id="backToScanningMode"
            >
              {BARCODE_SCANNER.SCAN}
            </Button>
            <StyledNextButton outline onClick={this.submitManualInput} id="submitManualInput">
              {BARCODE_SCANNER.NEXT}
              <Icon icon="icon-arrow-right" color={cssVar('alabaster')} />
            </StyledNextButton>
          </div>
        </SmoothCollapse>
      </>
    );
  }

  renderInputValues() {
    const { idleStates } = this.props;
    return this.state.values.map((value, index) => (
      <EnteredInputWrapper key={index}>
        <span>{`${idleStates[index].name}: ${value}`}</span>
        <Button
          outline
          outlineColor={cssVar('roman')}
          onClick={() => this.goToIndex(index)}
          onKeyDown={this.preventEventDefault}
        >
          {BARCODE_SCANNER.CLEAR}
        </Button>
      </EnteredInputWrapper>
    ));
  }

  renderStageInputs = () => (
    <>
      {this.renderInputValues()}
      <CurrentScanWrapper>
        <ScanningMessage type={this.state.scanningState}>{this.getMessage()}</ScanningMessage>
        <CurrentScanContent>
          {typeof this.icon.icon === 'function' ? (
            this.icon.icon()
          ) : (
            <CurrentScanIconWrapper
              internal={![states.idle, states.manualIdle].includes(this.state.scanningState)}
            >
              <Icon size="contain" {...this.icon} />
            </CurrentScanIconWrapper>
          )}
        </CurrentScanContent>
      </CurrentScanWrapper>
      <InputManuallyWrapper>{this.renderManualInput()}</InputManuallyWrapper>
    </>
  );

  renderCustom = () =>
    this.currentState.render({ onReset: this.resetForm, goToIndex: this.goToIndex });

  render = () => (
    <BarcodeScannerWrapper>
      {this.currentState.render ? this.renderCustom() : this.renderStageInputs()}
    </BarcodeScannerWrapper>
  );
}

export { BarcodeScannerCore, states as scannerStates };
