import axios from 'axios';

import { showSnackbar } from 'actions/shared/snackbar';

import {
  dangerOptions,
  successOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { parseSpecialPermission } from 'shared/helpers/dataParsers/specialPermissions/parseSpecialPermission';

import { getSpecialPermissionsSuccessAction } from './getSpecialPermissions';

const API_HOST_AUTH = process.env.REACT_APP_API;

const updateSpecialPermissionsActionType = 'UPDATE_SPECIAL_PERMISSIONS';
const updateSpecialPermissionsAction = () => ({
  type: updateSpecialPermissionsActionType,
});

const updateSpecialPermissionsSuccessActionType = `${updateSpecialPermissionsActionType}_SUCCESS`;
const updateSpecialPermissionsSuccessAction = () => ({
  type: updateSpecialPermissionsSuccessActionType,
});

const updateSpecialPermissionsFailureActionType = `${updateSpecialPermissionsActionType}_FAILURE`;
const updateSpecialPermissionsFailureAction = () => ({
  type: updateSpecialPermissionsFailureActionType,
});

const updateSpecialPermissions = specialPermissions => dispatch => {
  dispatch(updateSpecialPermissionsAction());
  return axios
    .put(
      `${API_HOST_AUTH}/special-permissions`,
      specialPermissions && parseSpecialPermission(specialPermissions),
    )
    .then(res => {
      dispatch(updateSpecialPermissionsSuccessAction());
      dispatch(showSnackbar(successOptions, res.data.message));
      dispatch(getSpecialPermissionsSuccessAction(specialPermissions));

      return {
        message: res.data.message,
      };
    })
    .catch(err => {
      dispatch(updateSpecialPermissionsFailureAction());
      dispatch(showSnackbar(dangerOptions, err.data.message));

      return Promise.reject({
        message: err.data.message,
      });
    });
};

export {
  updateSpecialPermissions,
  updateSpecialPermissionsActionType,
  updateSpecialPermissionsSuccessActionType,
  updateSpecialPermissionsFailureActionType,
};
