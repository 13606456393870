import { changeLocationStatusSuccessActionType } from 'actions/Locations/changeLocationStatus';
import {
  clearLocationsListActionType,
  getLocationsListActionType,
  getLocationsListFailureActionType,
  getLocationsListSuccessActionType,
} from 'actions/Locations/locationsList';

const INITIAL_STATE = {
  isPending: false,
  errorMessage: null,
  locations: [],
  limit: 50,
  page: 1,
  pages: null,
  company: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getLocationsListActionType:
      return {
        ...state,
        isPending: true,
      };
    case getLocationsListSuccessActionType:
      return {
        ...state,
        isPending: false,
        locations: action.payload.locations,
        limit: action.payload.limit,
        page: action.payload.page,
        pages: action.payload.pages,
        company: action.payload.locations[0].relatedCompany,
      };
    case getLocationsListFailureActionType:
      return {
        ...state,
        isPending: false,
        errorMessage: action.payload.errorMessage,
        locations: [],
        pages: null,
      };
    case clearLocationsListActionType:
      return {
        isPending: false,
        errorMessage: null,
        locations: [],
        limit: 50,
        page: 1,
        pages: null,
        company: null,
      };
    case changeLocationStatusSuccessActionType:
      return {
        ...state,
        locations: state.locations.map(location =>
          location._id === action.payload.location._id ? action.payload.location : location,
        ),
      };
    default:
      return state;
  }
};
