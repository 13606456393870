import React from 'react';
import { Field } from 'redux-form';

import { FieldInput } from 'shared/components/Fields';
import { FieldSelect } from 'shared/components/Fields/FieldSelect/FieldSelect';
import { Section } from 'shared/components/Form';
import { ASSAY } from 'shared/helpers/constants/lots/assayConstants';
import { availableMaterialTypes } from 'shared/helpers/constants/lots/materialTypes';
import { mapArray } from 'shared/helpers/parsers/select';

import { setCorrectLabel } from 'utils/setCorrectLabel';

import { renderFields } from 'pages/Lots/components/EnterAssayResults/EnterAssayResultsForm/components/helpers/renderFields';
import { calculateWeights } from 'pages/Lots/helpers/calculateWeights';

const Weights = ({ formValues, change, resetSection, assayOption }) => {
  const changeMaterialType = (e, val) => {
    change('materialTypeH', val);
  };

  const calculateWeightsH = (e, val, prev, name) => {
    const values = { ...formValues, [name]: val };
    return calculateWeights(
      change,
      resetSection,
      name,
      values,
      'weightGrossReceivedH',
      'weightTareReceivedH',
      'weightNetWetReceivedH',
      'weightScrapReceivedH',
      'weightAdjustedNetWetReceivedH',
    );
  };

  const calculateWeightsF = (e, val, prev, name) => {
    const values = { ...formValues, [name]: val };
    return calculateWeights(
      change,
      resetSection,
      name,
      values,
      'weightGrossReceivedF',
      'weightTareReceivedF',
      'weightNetWetReceivedF',
      'weightScrapReceivedF',
      'weightAdjustedNetWetReceivedF',
    );
  };

  const weightsConfig = [
    {
      component: FieldSelect,
      name: 'materialTypeF',
      type: 'number',
      label: setCorrectLabel(assayOption, ASSAY.MATERIAL_TYPE_F, ASSAY.MATERIAL_TYPE_A),
      options: mapArray(availableMaterialTypes.all),
      searchable: false,
      onChange: changeMaterialType,
    },
    {
      name: 'percentMoistureF',
      type: 'number',
      label: setCorrectLabel(assayOption, ASSAY.PERCENT_MOISTURE_F, ASSAY.PERCENT_MOISTURE_A),
      suffix: '%',
    },
    {
      name: 'weightGrossReceivedF',
      type: 'number',
      label: setCorrectLabel(
        assayOption,
        ASSAY.WEIGHT_GROSS_RECEIVED_F,
        ASSAY.WEIGHT_GROSS_RECEIVED_A,
      ),
      onChange: calculateWeightsF,
      suffix: 'lbs',
    },
    {
      name: 'weightTareReceivedF',
      type: 'number',
      label: setCorrectLabel(
        assayOption,
        ASSAY.WEIGHT_TARE_RECEIVED_F,
        ASSAY.WEIGHT_TARE_RECEIVED_A,
      ),
      onChange: calculateWeightsF,
      suffix: 'lbs',
    },
    {
      name: 'weightNetWetReceivedF',
      label: setCorrectLabel(
        assayOption,
        ASSAY.WEIGHT_NET_WET_RECEIVED_F,
        ASSAY.WEIGHT_NET_WET_RECEIVED_A,
      ),
      onChange: calculateWeightsF,
      suffix: 'lbs',
    },
    {
      name: 'weightScrapReceivedF',
      type: 'number',
      label: setCorrectLabel(
        assayOption,
        ASSAY.WEIGHT_SCRAP_RECEIVED_F,
        ASSAY.WEIGHT_SCRAP_RECEIVED_A,
      ),
      onChange: calculateWeightsF,
      suffix: 'lbs',
    },
    {
      name: 'weightAdjustedNetWetReceivedF',
      label: setCorrectLabel(
        assayOption,
        ASSAY.WEIGHT_ADJUSTED_NET_WET_RECEIVED_F,
        ASSAY.WEIGHT_ADJUSTED_NET_WET_RECEIVED_A,
      ),
      suffix: 'lbs',
    },
    {
      name: 'weightNetDryReceivedF',
      type: 'number',
      label: setCorrectLabel(
        assayOption,
        ASSAY.WEIGHT_NET_DRY_RECEIVED_F,
        ASSAY.WEIGHT_NET_DRY_RECEIVED_A,
      ),
      suffix: 'lbs',
    },
    {
      name: 'carbonPercentageF',
      type: 'number',
      label: setCorrectLabel(assayOption, ASSAY.CARBON_PERCENTAGE_F, ASSAY.CARBON_PERCENTAGE_A),
      suffix: '%',
    },
    {
      component: FieldSelect,
      name: 'materialTypeH',
      label: setCorrectLabel(assayOption, ASSAY.MATERIAL_TYPE_H, ASSAY.MATERIAL_TYPE_B),
      placeholder: setCorrectLabel(
        assayOption,
        ASSAY.SELECT_MATERIAL_TYPE_F_FIRST,
        ASSAY.SELECT_MATERIAL_TYPE_A_FIRST,
      ),
      disabled: true,
      field: null,
    },
    {
      name: 'percentMoistureH',
      type: 'number',
      label: setCorrectLabel(assayOption, ASSAY.PERCENT_MOISTURE_H, ASSAY.PERCENT_MOISTURE_B),
      suffix: '%',
    },
    {
      name: 'weightGrossReceivedH',
      type: 'number',
      label: setCorrectLabel(
        assayOption,
        ASSAY.WEIGHT_GROSS_RECEIVED_H,
        ASSAY.WEIGHT_GROSS_RECEIVED_B,
      ),
      onChange: calculateWeightsH,
      suffix: 'lbs',
    },
    {
      name: 'weightTareReceivedH',
      type: 'number',
      label: setCorrectLabel(
        assayOption,
        ASSAY.WEIGHT_TARE_RECEIVED_H,
        ASSAY.WEIGHT_TARE_RECEIVED_B,
      ),
      onChange: calculateWeightsH,
      suffix: 'lbs',
    },
    {
      name: 'weightNetWetReceivedH',
      label: setCorrectLabel(
        assayOption,
        ASSAY.WEIGHT_NET_WET_RECEIVED_H,
        ASSAY.WEIGHT_NET_WET_RECEIVED_B,
      ),
      onChange: calculateWeightsH,
      suffix: 'lbs',
    },
    {
      name: 'weightScrapReceivedH',
      type: 'number',
      label: setCorrectLabel(
        assayOption,
        ASSAY.WEIGHT_SCRAP_RECEIVED_H,
        ASSAY.WEIGHT_SCRAP_RECEIVED_B,
      ),
      onChange: calculateWeightsH,
      suffix: 'lbs',
    },
    {
      name: 'weightAdjustedNetWetReceivedH',
      label: setCorrectLabel(
        assayOption,
        ASSAY.WEIGHT_ADJUSTED_NET_WET_RECEIVED_H,
        ASSAY.WEIGHT_ADJUSTED_NET_WET_RECEIVED_B,
      ),
      suffix: 'lbs',
    },
    {
      name: 'weightNetDryReceivedH',
      type: 'number',
      label: setCorrectLabel(
        assayOption,
        ASSAY.WEIGHT_NET_DRY_RECEIVED_H,
        ASSAY.WEIGHT_NET_DRY_RECEIVED_B,
      ),
      suffix: 'lbs',
    },
    {
      name: 'carbonPercentageH',
      type: 'number',
      label: setCorrectLabel(assayOption, ASSAY.CARBON_PERCENTAGE_H, ASSAY.CARBON_PERCENTAGE_B),
      suffix: '%',
    },
  ];

  const fields = renderFields(weightsConfig);

  return (
    <Section
      header={ASSAY.WEIGHTS}
      template={[
        'materialTypeF percentMoistureF',
        'weightGrossReceivedF weightTareReceivedF',
        'weightNetWetReceivedF weightScrapReceivedF',
        'weightAdjustedNetWetReceivedF weightNetDryReceivedF',
        'carbonPercentageF .',
        'materialTypeH percentMoistureH',
        'weightGrossReceivedH weightTareReceivedH',
        'weightNetWetReceivedH weightScrapReceivedH',
        'weightAdjustedNetWetReceivedH weightNetDryReceivedH',
        'carbonPercentageH .',
      ]}
    >
      {fields.map(field => (
        <Field component={FieldInput} field="required" key={field.name} {...field} />
      ))}
    </Section>
  );
};

export { Weights };
