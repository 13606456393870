import {
  getCompaniesContactActionType,
  getCompaniesContactFailureActionType,
  getCompaniesContactSuccessActionType,
} from 'actions/Locations/getCompaniesContact';

const INITIAL_STATE = {
  isPending: false,
  data: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getCompaniesContactActionType:
      return {
        data: null,
        isPending: true,
      };
    case getCompaniesContactFailureActionType:
      return {
        data: null,
        isPending: false,
      };
    case getCompaniesContactSuccessActionType:
      return {
        data: action.payload.response,
        isPending: false,
      };
    default:
      return state;
  }
};
