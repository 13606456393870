import { FILES_CONSTANTS } from 'shared/helpers/constants/filesConstants';

function isFileSizeValid(file) {
  return file.size ? file.size <= FILES_CONSTANTS.MAX_FILE_SIZE : true;
}

function isFileTypeValid(file, validExtensions) {
  const mimetype = FILES_CONSTANTS.VALID_TYPES.some(item => file.type === item);
  const extension = validExtensions.some(p => new RegExp(`\\${p}$`, 'i').test(file.name));

  return (mimetype && extension) || /\.msg$/.test(file.name);
}

const validateAttachment = (file, validExtensions) =>
  isFileTypeValid(file, validExtensions) && isFileSizeValid(file);

export { validateAttachment };
