import { DetailsField } from 'shared/components/Details/DetailsField';
import { DetailsHumanizedField } from 'shared/components/Details/DetailsHumanizedField';
import { HEDGES } from 'shared/helpers/constants/hedges/hedgesConstants';
import { SETTLEMENT_FOR_LOT_WIZARD } from 'shared/helpers/constants/poolAccounts/settlementForLotWizard';
import { isCustomerUser } from 'shared/helpers/matchers/checkUserType';
import { formatToUS } from 'shared/helpers/parsers/formaters';
import { cssVar } from 'shared/helpers/styling/styling';

const { OUNCES_MOVED_TO_POOL_ACCOUNT_HEADER, REQUEST_PAYMENT, TROY_OUNCES_SETTLED, SETTLEMENT } =
  SETTLEMENT_FOR_LOT_WIZARD;

const renderTroyOunces = ({ platinumOz, palladiumOz, rhodiumOz }) => `
    ${HEDGES.PT} ${formatToUS(+platinumOz, 3)} -
    ${HEDGES.PD} ${formatToUS(+palladiumOz, 3)} -
    ${HEDGES.RH} ${formatToUS(+rhodiumOz, 3)}
`;

const config = {
  noMargin: true,
  headerBgColor: cssVar('outerSpaceBitLighter'),
};

const settlementDetailsContainerConfig = (settlement, userType) => ({
  header: SETTLEMENT,
  template: [
    isCustomerUser(userType)
      ? 'settlement settlement requestPayment requestPayment'
      : 'settlement settlement settlement settlement',
  ],
  config,
});

const ouncesRemovedDetailsContainerConfig = () => ({
  header: OUNCES_MOVED_TO_POOL_ACCOUNT_HEADER,
  template: ['ouncesRemoved ouncesRemoved ouncesRemoved ouncesRemoved'],
  config,
});

const deliveriesDetailsContainerConfig = () => ({
  template: ['deliveries deliveries deliveries deliveries'],
  config,
});

const deliveriesDetailsFieldsConfig = delivery => [
  {
    label: `Hedge: ${delivery.relatedHedge.label}`,
    value: renderTroyOunces(delivery),
    icon: 'icon-volume',
    id: 'deliveries',
    FieldType: DetailsField,
    'data-testid': delivery.relatedHedge.label,
  },
];

const settlementDetailsFieldsConfig = (settlement, userType) => [
  {
    label: TROY_OUNCES_SETTLED,
    value: renderTroyOunces(settlement),
    icon: 'icon-volume',
    id: 'settlement',
    FieldType: DetailsField,
    'data-testid': 'settlementItem',
  },
  isCustomerUser(userType) && {
    label: REQUEST_PAYMENT,
    value: settlement.requestPayment ? 'Yes' : 'No',
    icon: 'icon-dollar',
    id: 'requestPayment',
    FieldType: DetailsHumanizedField,
    'data-testid': 'requestPayment',
  },
];

const ouncesRemovedDetailsFieldsConfig = ouncesRemoved => [
  {
    label: OUNCES_MOVED_TO_POOL_ACCOUNT_HEADER,
    value: renderTroyOunces(ouncesRemoved),
    icon: 'icon-volume',
    id: 'ouncesRemoved',
    FieldType: DetailsField,
  },
];

export {
  ouncesRemovedDetailsContainerConfig,
  settlementDetailsContainerConfig,
  deliveriesDetailsContainerConfig,
  deliveriesDetailsFieldsConfig,
  settlementDetailsFieldsConfig,
  ouncesRemovedDetailsFieldsConfig,
};
