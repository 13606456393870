import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { clearHedgesOverviewList, getHedgesOverviewList } from 'actions/Hedges/hedgesOverviewList';

import { ListCollapse } from 'shared/components/ListCollapse/ListCollapse';
import { OverviewList } from 'shared/components/OverviewList/OverviewList';
import {
  OverviewListSection,
  OverviewListSectionWrapper,
} from 'shared/components/OverviewList/OverviewListSection/OverviewListSection';
import { HEDGES } from 'shared/helpers/constants/hedges/hedgesConstants';
import { isTablet } from 'shared/helpers/constants/resolutionsConstants';
import { useWindowWidth } from 'shared/hooks/useWindowWidth';

export const HedgesOverview = ({
  itemsTemplate,
  actionsTemplate,
  itemsTemplateMobile,
  itemDetailsTemplate,
  hedgesOverviewList,
}) => {
  const [isContentDisplayed, setIsContentDisplayed] = useState(true);

  const totalHedgesOverviewListItems = useSelector(state => state.hedgesOverviewList.items?.total);

  const dispatch = useDispatch();

  const { id } = useParams();
  const windowWidth = useWindowWidth();

  const isMobile = windowWidth < isTablet;

  const toggleContent = () => {
    totalHedgesOverviewListItems && setIsContentDisplayed(prev => !prev);
  };

  const handleGetHedgesOverviewList = (_, limit) => {
    dispatch(getHedgesOverviewList(id, limit));
  };

  const handleClearHedgesOverviewList = () => {
    dispatch(clearHedgesOverviewList());
  };

  return (
    <OverviewListSectionWrapper isOpen={isContentDisplayed} total={totalHedgesOverviewListItems}>
      <OverviewListSection isOpen={isContentDisplayed} total={totalHedgesOverviewListItems}>
        <div>
          <ListCollapse
            collapse={isContentDisplayed}
            toggleList={toggleContent}
            label={HEDGES.HEDGES}
          />
        </div>
      </OverviewListSection>
      <div>
        <OverviewList
          getList={handleGetHedgesOverviewList}
          clearData={handleClearHedgesOverviewList}
          input={hedgesOverviewList}
          itemsTemplate={itemsTemplate}
          actionsTemplate={actionsTemplate}
          itemsTemplateMobile={itemsTemplateMobile}
          itemDetailsTemplate={itemDetailsTemplate}
          isMobile={isMobile}
          actionsContext={this}
          label={HEDGES.HEDGES}
        />
      </div>
    </OverviewListSectionWrapper>
  );
};

HedgesOverview.propTypes = {
  itemsTemplate: PropTypes.object,
  actionsTemplate: PropTypes.object,
  itemsTemplateMobile: PropTypes.object,
  itemDetailsTemplate: PropTypes.object,
  hedgesOverviewList: PropTypes.object,
};
