import { DetailsActivityField } from 'shared/components/Details/DetailsActivityField';
import { DetailsDateField } from 'shared/components/Details/DetailsDateField';
import { DetailsDownloadField } from 'shared/components/Details/DetailsDownloadFieldRedesign';
import { DetailsRadioField } from 'shared/components/Details/DetailsRadioFieldRedesign';
import { AGREEMENTS } from 'shared/helpers/constants/agreements/agreementsConstants';

const agreementDetailsFieldsConfig = (values, downloadApiAttachment) => [
  {
    label: AGREEMENTS.START_DATE,
    value: values.startDate,
    id: 'startDate',
    FieldType: DetailsDateField,
  },
  {
    label: AGREEMENTS.ACTUAL_EXPIRATION_DATE,
    value: values.actualExpirationDate,
    id: 'actualExpirationDate',
    FieldType: DetailsDateField,
  },
  {
    label: AGREEMENTS.DIGITAL_SIGNATURE_REQUIRED,
    value: values.digitalSignatureRequired,
    icon: 'icon-document',
    id: 'digitalSignatureRequired',
    FieldType: DetailsRadioField,
  },
  {
    label: AGREEMENTS.GRADING_DOCUMENT,
    value: values.docuSignDocument || values.gradingDocument,
    id: 'gradingDocument',
    onClick: () => downloadApiAttachment(`agreements/${values._id}/document`),
    FieldType: DetailsDownloadField,
    forwardable: true,
    previewIsAvailable: false,
  },
];

const activityFieldsConfig = values => [
  {
    label: AGREEMENTS.CREATED_BY,
    user: values.createdBy,
    date: values.createdAt,
    id: 'createdBy',
    FieldType: DetailsActivityField,
  },
  {
    label: AGREEMENTS.UPDATED_BY,
    user: values.updatedBy,
    date: values.updatedAt,
    id: 'updatedBy',
    FieldType: DetailsActivityField,
  },
  {
    label: AGREEMENTS.GRADING_DECISION_BY,
    user: values.gradingDecisionBy,
    date: values.gradingDecisionAt,
    id: 'gradingDecisionBy',
    FieldType: DetailsActivityField,
  },
  {
    label: AGREEMENTS.GLOBAL_SIGNED_ON,
    value: values.globalSignedAt,
    id: 'globalSignedAt',
    FieldType: DetailsDateField,
  },
];

const agreementDetailsContainerConfig = ({ digitalSignatureRequired }) => ({
  header: AGREEMENTS.GENERAL_DETAILS,
  template: ['startDate actualExpirationDate digitalSignatureRequired gradingDocument'],
});

const activityContainerConfig = () => ({
  header: AGREEMENTS.ACTIVITY,
  template: [
    'createdBy createdBy updatedBy updatedBy',
    'gradingDecisionBy gradingDecisionBy globalSignedAt globalSignedAt',
  ],
});

export {
  agreementDetailsFieldsConfig,
  activityFieldsConfig,
  agreementDetailsContainerConfig,
  activityContainerConfig,
};
