const NAVBAR = {
  DASHBOARD: 'Dashboard',
  COMPANIES: 'Companies',
  USERS: 'Users',
  LOCATIONS: 'Locations',
  TERMS: 'Terms',
  AGREEMENTS: 'Agreements',
  LOTS: 'Lots',
  SHIPMENTS: 'Shipments',
  PAYMENTS: 'Payments',
  HEDGES: 'Hedges',
  POOL_ACCOUNTS: 'Pool accounts',
  REPORTS: 'Reports',
  GENERAL_INFO: 'General info',
  ANALYSIS: 'Analysis',
  ANALYSIS_REPORTS: 'Analysis reports',
  LOTS_AWAITING_RESULTS: 'Lots awaiting results',
};

export { NAVBAR };
