import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, formValueSelector } from 'redux-form';

import { Icon } from 'shared/components/Icon/Icon';
import { cssVar } from 'shared/helpers/styling/styling';

import { SearchInput } from './SearchInput/SearchInput';
import { StyledSearchForm, StyledSearchFormInput } from './StyledSearchForm';

function SearchForm({
  handleSubmit,
  change,
  searchValue,
  focusSearch,
  list,
  submit,
  nonPersistent,
}) {
  const searchRef = useRef(null);
  const [initial, setInitial] = useState(true);

  useEffect(
    () => () => {
      if (nonPersistent) {
        change(list, '');
      }
    },
    [nonPersistent, list],
  );

  useEffect(() => {
    if (initial && searchValue) {
      submit();
    }

    setInitial(false);
  }, [initial, searchValue, submit]);

  useEffect(() => {
    if (searchValue && focusSearch) {
      searchRef.current.focus();
    }
  }, [searchValue, focusSearch]);

  const onSubmit = event => {
    handleSubmit(event);
    searchRef.current.blur();
  };

  const clearInput = () => {
    change(list, '');
  };

  return (
    <form onSubmit={onSubmit}>
      <StyledSearchForm name={list}>
        <StyledSearchFormInput component={SearchInput} name="searchInput" searchRef={searchRef} />
        {searchValue && searchValue.searchInput && (
          <Icon
            icon="icon-cross"
            width={12}
            height={12}
            size="contain"
            onClick={() => clearInput()}
            color={cssVar('ebonyClay')}
          />
        )}
      </StyledSearchForm>
    </form>
  );
}

const searchForm = 'SearchForm';
export const selector = formValueSelector(searchForm);
const mapStateToProps = (state, { list }) => ({
  searchValue: selector(state, list),
});

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: searchForm,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  }),
)(SearchForm);
