import {
  getOpenFinalLotsActionType,
  getOpenFinalLotsSuccessActionType,
  getOpenFinalLotsFailureActionType,
  clearOpenFinalLotsActionType,
} from 'actions/Lots/getOpenFinalLots';

const INITIAL_STATE = {
  isPending: false,
  openFinalLots: null,
  limit: 50,
  page: 1,
  pages: null,
};

export const openFinalLotsReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case getOpenFinalLotsActionType:
      return {
        ...state,
        isPending: true,
      };
    case getOpenFinalLotsSuccessActionType:
      return {
        ...state,
        isPending: false,
        openFinalLots: payload?.docs,
        limit: payload.limit,
        page: payload.page,
        pages: payload.pages,
      };
    case getOpenFinalLotsFailureActionType:
      return {
        ...state,
        isPending: false,
      };
    case clearOpenFinalLotsActionType:
      return INITIAL_STATE;
    default: {
      return state;
    }
  }
};
