import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { submitForApproval } from 'actions/Terms/submitForApproval';
import { showSnackbar } from 'actions/shared/snackbar';

import { snackbarOptions } from 'shared/helpers/matchers/snackbar/snackbarOptions';

class TermsSubmit extends Component {
  onClick = () => {
    const { terms } = this.props;
    this.props.submitForApproval(terms).then(this.resolveAction);
  };

  resolveAction = result => {
    this.props.showSnackbar(snackbarOptions(result.messageType), result.message);
  };

  render() {
    return <React.Fragment>{this.props.children({ onClick: this.onClick })}</React.Fragment>;
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      submitForApproval,
      showSnackbar,
    },
    dispatch,
  );
}

const ConnectedAgreementTermsSubmit = connect(null, mapDispatchToProps)(TermsSubmit);

export { ConnectedAgreementTermsSubmit as TermsSubmit, TermsSubmit as TermsSubmitTests };
