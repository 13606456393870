import { SHARED } from 'shared/helpers/constants/sharedConstants';

const USER = {
  GENERAL_DETAILS: 'General details',
  ACTIVITY: 'Activity',
  CREATE_A_USER: `Create a${SHARED.NON_BREAKING_SPACE}user`,
  USER_CREATED: 'User has been created.',
  USER_CREATED_WITHOUT_NOTES: 'User has been created, but there was a problem with notes creation.',
  USER_WITHOUT_PASSWORD: 'User has not set password.',
  USER_CREATE_ERROR: 'User has not been created.',
  EDIT_A_USER: 'Edit user',
  STATUS: 'Status',
  USER_TYPE: 'User type',
  ACCESS_LEVEL: 'Access level',
  PERSON: 'Person',
  TITLE: 'Title',
  RELATED_COMPANY: 'Related company',
  EMAIL: 'Email',
  FIRST_NAME: 'First name',
  LAST_NAME: 'Last name',
  OFFICE_PHONE: 'Office phone',
  PHONE_EXTENSION: 'Phone extension',
  MOBILE_PHONE: 'Mobile phone',
  LOCATIONS: 'Locations',
  LOCATION: 'Location',
  DATABASE_ACCESS: 'Database access',
  PURCHASING_APP_ACCESS: 'Purchasing app access',
  CREATED_BY: 'Created by',
  UPDATED_BY: 'Last updated by',
  NOTE: 'Note',
  USER: 'User',
  USER_INFO: 'User info',
  CONTACT: 'Contact',
  USERS: 'Users',
  ACTIONS: 'Actions',
  MY_PROFILE: 'My profile',
  DETAILS: 'Details',
  NOTIFICATION_PREFERENCES: 'Notification preferences',
  DASHBOARD_PREFERENCES: 'Dashboard preferences',
  INVITE_USER: 'Invite user',
  NOT_INVITED: 'User has not been invited.',
  CHANGE_PASSWORD: 'Change password',
  PASSWORD_CHANGED: 'Password has been successfully changed for:',
  PASSWORD_NOT_CHANGED: 'Password has not been changed.',
  PASSWORD_RESET_REQUEST_SUCCESS:
    'An email with instructions on how to change your password has been sent to the specified email address.',
  DELETE_USER: 'Delete user',
  INACTIVE: 'inactive',
  INACTIVATE_USER: 'Inactivate user',
  DELETE_USER_ERROR: 'User has not been deleted.',
  INACTIVATE_USER_ERROR: 'User has not been inactivated.',
  ACCESSES: 'Accesses',
  INFORMATION: 'Information',
  ADD_NEW_USER: 'Add new user',
  CHANGE_YOUR_PASSWORD: 'Change your password',
  MATERIAL_COUNT_REQUIRED: '(material count required)',
  GENERATE_REPORT: 'Generate report',
  GENERATE_REPORT_SUCCESS: 'Users report has been generated',
  GENERATE_REPORT_ERROR: "Generate users report couldn't be generated. Please try again later",
};

export { USER };
