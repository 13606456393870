import { DELIVERIES } from 'shared/helpers/constants/deliveries/deliveriesConstants';
import { formatDate, formatToUS } from 'shared/helpers/parsers/formaters';
import { replaceEmpty } from 'shared/helpers/parsers/text';

import { DeliverySelectAction } from 'pages/Deliveries/TransferOuncesWizard/TransferOuncesForm/components/TransferDeliveriesFormContent/DeliverySelectAction/DeliverySelectAction';

const itemsTemplate = [
  {
    label: DELIVERIES.SELECT,
    render: delivery => !delivery.isDisabled && <DeliverySelectAction deliveryId={delivery._id} />,
  },
  {
    label: DELIVERIES.HEDGE_NAME,
    render: ({ relatedHedge: { hedgeName } }) => hedgeName,
  },

  {
    label: DELIVERIES.RELATED_LOT,
    render: ({ relatedLot: { grgLotNumber } }) => grgLotNumber,
  },
  {
    label: DELIVERIES.PLATINUM_OZ,
    render: ({ platinumOz }) => replaceEmpty(formatToUS(platinumOz, 3)),
  },
  {
    label: DELIVERIES.PALLADIUM_OZ,
    render: ({ palladiumOz }) => replaceEmpty(formatToUS(palladiumOz, 3)),
  },
  {
    label: DELIVERIES.RHODIUM_OZ,
    render: ({ rhodiumOz }) => replaceEmpty(formatToUS(rhodiumOz, 3)),
  },
  {
    label: DELIVERIES.CREATED_BY,
    render: ({ createdBy }) => `${createdBy.firstName} ${createdBy.lastName}`,
  },
  {
    label: DELIVERIES.CREATED_ON,
    render: ({ createdAt }) => formatDate(createdAt),
  },
];

const itemsTemplateMobile = [
  {
    label: DELIVERIES.SELECT,
    render: delivery => !delivery.isDisabled && <DeliverySelectAction deliveryId={delivery._id} />,
  },
  {
    label: DELIVERIES.RELATED_LOT,
    render: ({ relatedLot: { grgLotNumber } }) => grgLotNumber,
  },
  {
    label: DELIVERIES.CREATED_BY,
    render: ({ createdBy }) => `${createdBy.firstName} ${createdBy.lastName}`,
  },
];

const [, ...itemsDetailsTemplate] = itemsTemplate;

export { itemsTemplate, itemsTemplateMobile, itemsDetailsTemplate };
