import PropTypes from 'prop-types';
import * as React from 'react';
import { useDispatch } from 'react-redux';

import { downloadApiAttachment } from 'actions/Attachments/downloadAttachment';
import { generateReport } from 'actions/Reports/generateReport';
import { getReports } from 'actions/Reports/getReports';
import { openModal } from 'actions/shared/modal';

import { ModalCustom } from 'components/ModalCustom/ModalCustom';

import { CancelButton, Button } from 'shared/components/Buttons';
import { downloadReport } from 'shared/helpers/constants/modalConstants';
import { REPORTS } from 'shared/helpers/constants/reports/reportsConstants';
import { SHARED } from 'shared/helpers/constants/sharedConstants';

import { StyledContent, StyledOptions } from './StyledGenerateReports';

export const GenerateReportsModal = ({ modalClose, open, companyId }) => {
  const dispatch = useDispatch();

  const handleGenerateReport = async limitFullyReports => {
    return dispatch(generateReport(limitFullyReports, companyId))
      .then(modalClose())
      .then(({ fileId }) =>
        dispatch(
          openModal(downloadReport, () =>
            dispatch(downloadApiAttachment(`files/single/${fileId}`)),
          ),
        ).then(dispatch(getReports())),
      );
  };

  return (
    <ModalCustom isOpen={open} modalClose={modalClose}>
      <StyledContent>
        <h4>{REPORTS.MODAL_HEADER}</h4>
        <p>{REPORTS.MODAL_DESCRIPTION}</p>
        <StyledOptions>
          <Button id="last10Lots" data-testid="last10Lots" onClick={() => handleGenerateReport(10)}>
            {REPORTS.LAST_10_LOTS}
          </Button>
          <Button id="last20Lots" data-testid="last20Lots" onClick={() => handleGenerateReport(20)}>
            {REPORTS.LAST_20_LOTS}
          </Button>
          <Button id="allLots" data-testid="allLots" onClick={() => handleGenerateReport()}>
            {REPORTS.ALL_LOTS}
          </Button>
          <CancelButton onClick={modalClose} id="cancelButton" label={SHARED.CANCEL_BUTTON} />
        </StyledOptions>
      </StyledContent>
    </ModalCustom>
  );
};

GenerateReportsModal.propTypes = {
  modalClose: PropTypes.func,
  open: PropTypes.bool,
};
