import { message } from 'shared/helpers/constants/agreements/createAgreementConstants';
import { dateToTimestamp } from 'shared/helpers/parsers/date';

import dateAdapter from 'utils/date/dateAdapter';

function validate(values) {
  const errors = {};
  const today = dateAdapter().startOf('d').valueOf();

  if (!values.startDate) {
    errors.startDate = message.INVALID_START_DATE_EMPTY;
  } else if (dateToTimestamp(values.startDate) < today) {
    errors.startDate = message.INVALID_START_DATE_PAST;
  }

  if (values.digitalSignatureRequired === undefined || values.digitalSignatureRequired === '') {
    errors.digitalSignatureRequired = message.DIGITAL_SIGNATURE_REQUIRED_EMPTY;
  }

  return errors;
}

export { validate };
