import { connect } from 'react-redux';

import { deletePaymentExceptionCompany } from 'actions/Companies/privilegedCompaniesDelete';
import {
  getPaymentExceptionCompanies,
  clearPrivilegedCompanies,
} from 'actions/Companies/privilegedCompaniesList';
import { openModal } from 'actions/shared/modal';
import { showSnackbar } from 'actions/shared/snackbar';

import { PaymentExceptionCompanies } from './ExceptionCompanies';

const mapStateToProps = ({ privilegedCompaniesList, auth }) => ({
  privilegedCompaniesList,
  auth,
});

const dispatchMapToProps = {
  getPaymentExceptionCompanies,
  clearPrivilegedCompanies,
  deletePaymentExceptionCompany,
  showSnackbar,
  openModal,
};

const ConnectedPaymentExceptionCompanies = connect(
  mapStateToProps,
  dispatchMapToProps,
)(PaymentExceptionCompanies);

export { ConnectedPaymentExceptionCompanies };
