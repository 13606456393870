import { dateToTimestamp } from 'shared/helpers/parsers/date';
import { removeCommasAndParse } from 'shared/helpers/parsers/text';

const parseGenerateProfitLossData = ({
  values: { lot, additionalCommodities, currentCommodities, lotAdjustments },
  data,
  lotId,
  fromKitcoSource,
}) => ({
  receivedFromStillwater: removeCommasAndParse(lot.receivedFromStillwater),
  paidByGlobal: removeCommasAndParse(lot.paidByGlobal),
  lotCloseDate: dateToTimestamp(lot.lotCloseDate),
  additionalCommodities: additionalCommodities.commodities
    ? additionalCommodities.commodities.map(commodity => ({
        action: commodity.action.value,
        description: commodity.description.value,
        details: commodity.details,
        value: removeCommasAndParse(commodity.value),
      }))
    : [],
  currentCommodities:
    currentCommodities && currentCommodities.commodities
      ? currentCommodities.commodities.map((commodity, index) => ({
          _id: data.commodities[index]._id,
          value: removeCommasAndParse(commodity.value),
        }))
      : [],
  lotId,
  lotAdjustments:
    lotAdjustments?.adjustments.map(adjustment => ({
      action: adjustment.lotAdjustmentAction.value,
      description: adjustment.lotAdjustmentDescription,
      value: +adjustment.lotAdjustmentValue,
    })) || [],
  fromKitcoSource,
});

export { parseGenerateProfitLossData };
