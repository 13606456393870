import { message } from 'shared/helpers/constants/poolAccounts/createPoolAccountsConstants';
import { replaceEmpty } from 'shared/helpers/parsers/text';
import { commaValidator } from 'shared/helpers/validations/commaValidator';
import { FLOAT_REGEX_GEN } from 'shared/helpers/validations/regularExpressions';

function validate(values) {
  const errors = {};

  if (!values.relatedCompany) {
    errors.relatedCompany = message.RELATED_COMPANY_EMPTY;
  }

  if (!values.relatedLot) {
    errors.relatedLot = message.RELATED_LOT_EMPTY;
  }

  if (replaceEmpty(values.platinumOz, '').length === 0) {
    errors.platinumOz = message.PLATINUM_OZ_EMPTY;
  } else if (values.platinumOz.length > 10) {
    errors.platinumOz = message.PLATINUM_OZ_FORMAT;
  } else if (!FLOAT_REGEX_GEN(3).test(values.platinumOz)) {
    errors.platinumOz = message.PLATINUM_OZ_FORMAT;
  } else if (values.platinumOz < 0) {
    errors.platinumOz = message.PLATINUM_OZ_NEGATIVE;
  }

  if (replaceEmpty(values.palladiumOz, '').length === 0) {
    errors.palladiumOz = message.PALLADIUM_OZ_EMPTY;
  } else if (values.palladiumOz.length > 10) {
    errors.palladiumOz = message.PALLADIUM_OZ_FORMAT;
  } else if (!FLOAT_REGEX_GEN(3).test(values.palladiumOz)) {
    errors.palladiumOz = message.PALLADIUM_OZ_FORMAT;
  } else if (values.palladiumOz < 0) {
    errors.palladiumOz = message.PALLADIUM_OZ_NEGATIVE;
  }

  if (replaceEmpty(values.rhodiumOz, '').length === 0) {
    errors.rhodiumOz = message.RHODIUM_OZ_EMPTY;
  } else if (values.rhodiumOz.length > 10) {
    errors.rhodiumOz = message.RHODIUM_OZ_FORMAT;
  } else if (!FLOAT_REGEX_GEN(3).test(values.rhodiumOz)) {
    errors.rhodiumOz = message.RHODIUM_OZ_FORMAT;
  } else if (values.rhodiumOz < 0) {
    errors.rhodiumOz = message.RHODIUM_OZ_NEGATIVE;
  }

  commaValidator(
    { platinumOz: values.platinumOz, palladiumOz: values.palladiumOz, rhodiumOz: values.rhodiumOz },
    errors,
  );

  return errors;
}

export { validate };
