import styled, { css } from 'styled-components';

import { cssVar, pxToRem, media } from 'shared/helpers/styling/styling';

const FiltersMenu = styled.div`
  ${({ isOpen }) =>
    !isOpen &&
    css`
      display: none;
    `};

  width: 100%;
  margin-top: ${pxToRem(23)};
  border-top: 1px solid ${cssVar('limedSpruce')};

  :before {
    content: '';
    width: ${pxToRem(16)};
    height: ${pxToRem(16)};
    transform: translateX(-${pxToRem(48)}) translateY(-${pxToRem(9)}) rotateZ(45deg);
    float: right;
    border-top: 1px solid ${cssVar('limedSpruce')};
    border-left: 1px solid ${cssVar('limedSpruce')};
    background-color: ${cssVar('ebonyClay')};
  }

  .ClearFilters {
    float: right;
    margin-right: 1.8rem;
  }

  ${media.phone`
      .ClearFilters {
        width: 90%;
        margin-right: 5%;
      }
    `}
`;

export { FiltersMenu };
