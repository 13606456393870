import React from 'react';

import { UIAuthorization } from 'components/Authorization/UIAuthorization';

import { ContentLoader } from 'shared/components/ContentLoader/ContentLoader';
import { FullPageListTableItem } from 'shared/components/FullPageList/components/FullPageListTableItem/FullPageListTableItem';
import { OverviewListTableCell } from 'shared/components/OverviewList/OverviewListTableCell/OverviewListTableCell';
import { OverviewTableRow } from 'shared/components/OverviewList/OverviewTableRow/OverviewTableRow';
import { StyledOverviewTable } from 'shared/components/OverviewList/StyledOverviewTable/StyledOverviewTable';
import { ListPlaceholder } from 'shared/components/Table';
import { SHARED } from 'shared/helpers/constants/sharedConstants';

const FullPageListTable = ({
  items,
  searchFilter,
  headerGroups,
  itemsTemplate,
  actionsTemplate,
  actionsContext,
  isPending,
  itemsNumber,
  stripedItems,
  equalColumns,
  detailsList,
  tableHeader = false,
  dataTestId,
}) => (
  <StyledOverviewTable>
    {tableHeader && (
      <OverviewTableRow head header>
        <OverviewListTableCell head>{tableHeader}</OverviewListTableCell>
      </OverviewTableRow>
    )}
    {headerGroups && (
      <OverviewTableRow
        head
        itemsNumber={itemsNumber || itemsTemplate.length - !actionsTemplate.length}
        gridTemplate={headerGroups.gridTemplate}
        header
      >
        {headerGroups.map(({ gridArea, label }, index) => (
          <OverviewListTableCell key={index} head gridArea={gridArea} header>
            {label}
          </OverviewListTableCell>
        ))}
      </OverviewTableRow>
    )}
    <OverviewTableRow
      head
      itemsNumber={itemsNumber || itemsTemplate.length - !actionsTemplate.length}
      header
      equalColumns={equalColumns}
    >
      {itemsTemplate.map(({ label, className }, index) => (
        <OverviewListTableCell key={index} className={className} head>
          {label}
        </OverviewListTableCell>
      ))}
      <UIAuthorization access={actionsTemplate.length}>
        <OverviewListTableCell>{SHARED.TABLE_ACTIONS}</OverviewListTableCell>
      </UIAuthorization>
    </OverviewTableRow>
    <ContentLoader loaded={!isPending}>
      {items.length ? (
        items.map((item, index) => (
          <FullPageListTableItem
            key={item._id}
            searchFilter={searchFilter}
            actionsContext={actionsContext}
            actionsTemplate={actionsTemplate}
            itemsTemplate={itemsTemplate}
            item={item}
            itemsNumber={itemsNumber}
            stripedItems={stripedItems}
            equalColumns={equalColumns}
            detailsList={detailsList}
            dataTestId={dataTestId || item?.type || `${index}`}
          />
        ))
      ) : (
        <ListPlaceholder />
      )}
    </ContentLoader>
  </StyledOverviewTable>
);

export { FullPageListTable };
