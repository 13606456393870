import axios from 'axios';

import { SELF_DELIVERY_REQUESTS } from 'shared/helpers/constants/selfDeliveryRequests/selfDeliveryRequestsConstants';
import { messageTypes } from 'shared/helpers/constants/snackbar/snackbarMessageTypes';
import { parseSelfDeliveryRequestData } from 'shared/helpers/dataParsers/selfDeliveryRequests/parseSelfDeliveryRequestData';

const API_HOST_AUTH = process.env.REACT_APP_API;

const createSelfDeliveryRequestActionType = 'CREATE_SELF_DELIVERY_REQUEST';
const createSelfDeliveryRequestAction = () => ({
  type: createSelfDeliveryRequestActionType,
});

const createSelfDeliveryRequestSuccessActionType = `${createSelfDeliveryRequestActionType}_SUCCESS`;
const createSelfDeliveryRequestSuccessAction = () => ({
  type: createSelfDeliveryRequestSuccessActionType,
});

const createSelfDeliveryRequestFailureActionType = `${createSelfDeliveryRequestActionType}_FAILURE`;
const createSelfDeliveryRequestFailureAction = () => ({
  type: createSelfDeliveryRequestFailureActionType,
});

const createSelfDeliveryRequest = (formValues, userObj) => dispatch => {
  dispatch(createSelfDeliveryRequestAction());

  return axios
    .post(
      `${API_HOST_AUTH}/self-delivery-requests`,
      JSON.stringify(parseSelfDeliveryRequestData(formValues, userObj)),
    )
    .then(res => {
      dispatch(createSelfDeliveryRequestSuccessAction());

      return {
        id: res.data.id,
        message: res.data.message,
        messageType: messageTypes.success,
      };
    })
    .catch(error => {
      dispatch(createSelfDeliveryRequestFailureAction());

      return {
        message: `${SELF_DELIVERY_REQUESTS.ERROR.CREATE_SELF_DELIVERY_REQUEST_ERROR} ${error.data.message}`,
        messageType: messageTypes.error,
      };
    });
};

export {
  createSelfDeliveryRequestActionType,
  createSelfDeliveryRequestAction,
  createSelfDeliveryRequestSuccessActionType,
  createSelfDeliveryRequestSuccessAction,
  createSelfDeliveryRequestFailureActionType,
  createSelfDeliveryRequestFailureAction,
  createSelfDeliveryRequest,
};
