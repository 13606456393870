import PropTypes from 'prop-types';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';

import { cssVar, pixelify } from 'shared/helpers/styling/styling';

const Link = styled(({ to, className, children, externalLink }) =>
  externalLink ? (
    <a href={to} target="_blank" rel="noopener noreferrer" className={className}>
      {children}
    </a>
  ) : (
    <RouterLink to={to} className={className}>
      {children}
    </RouterLink>
  ),
).attrs(({ href, path }) => ({
  to: href || path,
}))`
  color: ${({ color }) => cssVar(color)};
  display: ${({ display }) => display};
  text-decoration: none;
  font-size: ${({ fontSize }) => pixelify(fontSize)};

  :hover {
    color: ${({ hoverColor }) => cssVar(hoverColor)};
  }

  :focus {
    color: ${({ hoverColor }) => cssVar(hoverColor)};
    outline: none;
  }
`;

Link.propTypes = {
  href: PropTypes.string,
  path: PropTypes.string,
  color: PropTypes.string,
  display: PropTypes.string,
  hoverColor: PropTypes.string,
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Link.defaultProps = {
  color: 'tangerine',
  hoverColor: 'atlantis',
  display: 'initial',
  fontSize: '16px',
  externalLink: false,
};

export { Link };
