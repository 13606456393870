import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getPrintablePackagesActionType = 'GET_PRINTABLE_PACKAGES_LIST';
const getPrintablePackagesAction = () => ({
  type: getPrintablePackagesActionType,
});

const getPrintablePackagesSuccessActionType = `${getPrintablePackagesActionType}_SUCCESS`;
const getPrintablePackagesSuccessAction = (packages, pages) => ({
  type: getPrintablePackagesSuccessActionType,
  payload: {
    packages,
    pages,
  },
});

const getPrintablePackagesFailureActionType = `${getPrintablePackagesActionType}_FAILURE`;
const getPrintablePackagesFailureAction = () => ({
  type: getPrintablePackagesFailureActionType,
});

const clearPrintablePackagesActionType = 'CLEAR_PRINTABLE_PACKAGES_LIST';
const clearPrintablePackages = () => ({
  type: clearPrintablePackagesActionType,
});

const getPrintablePackages =
  (relatedType, relatedId, packageClass, limit = 0) =>
  dispatch => {
    dispatch(getPrintablePackagesAction());

    return axios
      .get(
        `${API_HOST_AUTH}/packages/printable/${relatedType}/${packageClass}/${relatedId}/${limit}`,
      )
      .then(res => dispatch(getPrintablePackagesSuccessAction(res.data.docs, res.data.pages)))
      .catch(() => dispatch(getPrintablePackagesFailureAction()));
  };

export {
  getPrintablePackagesActionType,
  getPrintablePackagesSuccessActionType,
  getPrintablePackagesFailureActionType,
  clearPrintablePackagesActionType,
  getPrintablePackages,
  clearPrintablePackages,
};
