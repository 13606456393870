const filterActiveField = (hedge, groupName, index, activeFields) => {
  const prefix = `${groupName}[${index}].`;
  return activeFields
    ? Object.values(activeFields)
        .filter(field => field.name.includes(prefix))
        .map(field => field.name.replace(prefix, ''))
        .reduce((acc, name) => ({ ...acc, [name]: hedge[name] }), {})
    : hedge;
};

export { filterActiveField };
