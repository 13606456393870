import {
  changeLotsInMixingOverviewFailureType,
  changeLotsInMixingOverviewSuccessType,
  changeLotsInMixingOverviewType,
  getLotsForMixingOverviewType,
  getLotsForMixingOverviewFailureType,
  getLotsForMixingOverviewSuccessType,
} from 'actions/Lots/mixingOverview';

const INITIAL_STATE = {
  isPending: false,
  lots: [],
};

const mixingOverviewReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getLotsForMixingOverviewType:
      return {
        ...state,
        isPending: true,
      };
    case getLotsForMixingOverviewFailureType:
      return {
        isPending: false,
        lots: [],
      };
    case getLotsForMixingOverviewSuccessType:
      return {
        isPending: false,
        lots: action.payload.lots,
      };
    case changeLotsInMixingOverviewSuccessType:
    case changeLotsInMixingOverviewFailureType:
      return {
        ...state,
        isPending: false,
      };
    case changeLotsInMixingOverviewType:
      return {
        isPending: true,
        lots: {
          ...Object.entries(state.lots).reduce(
            (acc, [section, value]) => ({
              ...acc,
              [section]: action.payload.difference[section] || value,
            }),
            {},
          ),
        },
      };
    default:
      return state;
  }
};

export { mixingOverviewReducer };
