import { FINANCE_CHARGES } from 'shared/helpers/constants/payments/financeChargesConstants';
import { calculatePoolPaymentAmount } from 'shared/helpers/dataParsers/payments/calculatePoolPaymentAmount';
import { safeParseFloat } from 'shared/helpers/parsers/number';
import { replaceEmpty } from 'shared/helpers/parsers/text';
import { commaValidator } from 'shared/helpers/validations/commaValidator';
import { PRICE_REGEX } from 'shared/helpers/validations/regularExpressions';

function validate({ charges }, index, { values: { settlement } }) {
  const errors = {};

  errors.charges = (charges || []).map(charge => {
    const chargesErrors = {};

    if (!charge.financeCharge || charge.length) {
      chargesErrors.financeCharge = FINANCE_CHARGES.FINANCE_CHARGE_EMPTY;
    } else if (safeParseFloat(charge.financeCharge) < 0) {
      chargesErrors.financeCharge = FINANCE_CHARGES.FINANCE_CHARGE_NEGATIVE;
    } else if (!PRICE_REGEX.test(charge.financeCharge) || charge.financeCharge?.length > 15) {
      chargesErrors.financeCharge = FINANCE_CHARGES.FINANCE_CHARGE_INVALID;
    } else if (
      calculatePoolPaymentAmount(settlement) < charge.financeCharge ||
      calculatePoolPaymentAmount(settlement, charges) < 0
    ) {
      chargesErrors.financeCharge = FINANCE_CHARGES.FINANCE_CHARGE_RANGE;
    }

    if (safeParseFloat(charge.financeCharge) !== 0) {
      if (!replaceEmpty(charge.financeRate, '').length) {
        chargesErrors.financeRate = FINANCE_CHARGES.FINANCE_RATE_EMPTY;
      } else if (!PRICE_REGEX.test(charge.financeRate)) {
        chargesErrors.financeRate = FINANCE_CHARGES.FINANCE_RATE_INVALID;
      } else if (parseFloat(charge.financeRate) < 0 || parseFloat(charge.financeRate) > 100) {
        chargesErrors.financeRate = FINANCE_CHARGES.FINANCE_RATE_RANGE;
      }

      if (!charge.relatedLot) {
        chargesErrors.relatedLot = FINANCE_CHARGES.RELATED_LOT_EMPTY;
      }
    }

    commaValidator(
      { financeCharge: charge.financeCharge, financeRate: charge.financeRate },
      chargesErrors,
    );
    return chargesErrors;
  });

  return errors;
}

export { validate };
