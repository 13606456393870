import { DetailsActivityField } from 'shared/components/Details/DetailsActivityField';
import { DetailsDownloadField } from 'shared/components/Details/DetailsDownloadFieldRedesign';
import { DetailsField } from 'shared/components/Details/DetailsField';
import { SHIPMENTS } from 'shared/helpers/constants/shipments/shipmentsConstants';
import { isGlobalUserType } from 'shared/helpers/matchers/isGlobalUserType';
import { formatToUS } from 'shared/helpers/parsers/formaters';

const tabsConfig = (shipment, downloadAttachment, auth) => [
  {
    tabName: SHIPMENTS.WEIGHT,
    tabContent: {
      template: [
        'weightGrossDeclared weightGrossDeclared weightTareDeclared weightTareDeclared',
        'weightNetDeclared weightNetDeclared weightNetDeclared weightNetDeclared',
      ],
      fieldsConfig: [
        {
          label: SHIPMENTS.WEIGHT_GROSS_DECLARED,
          value: formatToUS(shipment.weightGrossDeclared),
          icon: 'icon-volume',
          id: 'weightGrossDeclared',
          suffix: SHIPMENTS.LBS,
          Component: DetailsField,
        },
        {
          label: SHIPMENTS.WEIGHT_TARE_DECLARED,
          value: formatToUS(shipment.weightTareDeclared),
          icon: 'icon-volume',
          id: 'weightTareDeclared',
          suffix: SHIPMENTS.LBS,
          Component: DetailsField,
        },
        {
          label: SHIPMENTS.WEIGHT_NET_DECLARED,
          value: formatToUS(shipment.weightNetDeclared),
          icon: 'icon-volume',
          id: 'weightNetDeclared',
          suffix: SHIPMENTS.LBS,
          Component: DetailsField,
        },
      ],
    },
  },
  {
    tabName: SHIPMENTS.DOCUMENTS,
    tabContent: {
      template: [
        'shipmentBillOfLading shipmentBillOfLading shipmentPackingList shipmentPackingList',
        shipment.sentFromCompany.materialCountRequired
          ? 'shipmentPackingListExcel shipmentPackingListExcel shipmentPackingListExcel shipmentPackingListExcel'
          : '. . . .',
      ],
      fieldsConfig: [
        {
          label: SHIPMENTS.SHIPMENT_BILL_OF_LADING,
          value: shipment.shipmentBillOfLading,
          id: 'shipmentBillOfLading',
          onClick: () => downloadAttachment(shipment.shipmentBillOfLading.path),
          Component: DetailsDownloadField,
          forwardable: true,
        },
        {
          label: SHIPMENTS.SHIPMENT_PACKING_LIST,
          value: shipment.shipmentPackingList,
          id: 'shipmentPackingList',
          onClick: () => downloadAttachment(shipment.shipmentPackingList.path),
          Component: DetailsDownloadField,
          forwardable: true,
        },
        ...(shipment.sentFromCompany.materialCountRequired
          ? [
              {
                label: SHIPMENTS.SHIPMENT_PACKING_LIST_EXCEL,
                value: shipment.shipmentPackingListExcel,
                id: 'shipmentPackingListExcel',
                onClick: () => downloadAttachment(shipment.shipmentPackingListExcel.path),
                Component: DetailsDownloadField,
                forwardable: true,
              },
            ]
          : []),
      ],
    },
  },
  isGlobalUserType(auth.user.userType) && {
    tabName: SHIPMENTS.ACTIVITY,
    tabContent: {
      template: ['createdBy createdBy updatedBy updatedBy'],
      fieldsConfig: [
        {
          label: SHIPMENTS.CREATED_BY,
          user: shipment.createdBy,
          date: shipment.createdAt,
          id: 'createdBy',
          Component: DetailsActivityField,
        },
        {
          label: SHIPMENTS.UPDATED_BY,
          user: shipment.updatedBy,
          date: shipment.updatedAt,
          id: 'updatedBy',
          Component: DetailsActivityField,
        },
      ],
    },
  },
];

export { tabsConfig };
