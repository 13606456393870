import React, { useEffect, useCallback, useContext, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container } from 'reactstrap';
import { reset } from 'redux-form';

import { marketAvailability } from 'actions/Hedges/marketAvailability';
import { settlementForLotWizardRequest } from 'actions/PoolAccounts/requestSettlement';
import { deleteSettlementWizardItemAction } from 'actions/PoolAccounts/settlementForLotWizard';
import { openModal } from 'actions/shared/modal';
import { showSnackbar } from 'actions/shared/snackbar';

import { OverviewHeader } from 'shared/components/OverviewHeader/OverviewHeader';
import { Preloader } from 'shared/components/Preloader/Preloader';
import { wizardContext, formName } from 'shared/components/Wizard/Wizard';
import { cancelModal, deleteLotModal } from 'shared/helpers/constants/modalConstants';
import {
  marketHolidayModal,
  marketHoursModal,
} from 'shared/helpers/constants/poolAccounts/poolAccountsModalConstants';
import { SETTLEMENT_FOR_LOT_WIZARD } from 'shared/helpers/constants/poolAccounts/settlementForLotWizard';
import {
  successOptions,
  dangerOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

import dateAdapter from 'utils/date/dateAdapter';

import { SummaryActions } from './components/SummaryActions/SummaryActions';
import { SummaryItem } from './components/SummaryItem/SummaryItem';

const SettlementWizardSummary = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const wizard = useContext(wizardContext);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const requestSettlementWizardData = useSelector(
    ({ requestSettlementWizard }) => requestSettlementWizard.data,
  );

  const { isPending } = useSelector(({ requestSettlement }) => requestSettlement);

  const userType = useSelector(
    ({
      auth: {
        user: { userType },
      },
    }) => userType,
  );

  const availableLots = useSelector(({ summaryAvailableLots: { lots } }) => lots);

  useEffect(() => {
    if (!Object.keys(requestSettlementWizardData || {}).length) {
      wizard.first();
    }
  }, [requestSettlementWizardData]);

  const isAllLotsSelected = () =>
    availableLots.length <= Object.keys(requestSettlementWizardData || {}).length;

  const onCancel = useCallback(() => {
    dispatch(
      openModal(cancelModal, () => {
        history.push('/lots');
      }),
    );
  }, [cancelModal]);

  const informationModal = ({
    marketHoliday,
    marketTimeFrom,
    marketTimeTo,
    marketHours,
    marketDays,
    timezone,
    isBusinessDay,
  }) => {
    const isAvailable = dateAdapter().isBetween(marketTimeFrom, marketTimeTo) && isBusinessDay;

    const modalSettings = marketHoursModal({ marketHours, marketDays, timezone });
    const isSettlementForLot = history.location.pathname.endsWith('settlement-for-lot');

    if (marketHoliday) {
      return dispatch(openModal(marketHolidayModal(isSettlementForLot), () => sentRequest()));
    }
    if (!isAvailable) {
      return dispatch(openModal(modalSettings, () => sentRequest()));
    }
    return sentRequest();
  };

  const sentRequest = () =>
    dispatch(settlementForLotWizardRequest({ requestSettlementWizardData, userType }))
      .then(res => {
        dispatch(showSnackbar(successOptions, res.message));
        history.push('/lots');
      })
      .catch(res => dispatch(showSnackbar(dangerOptions, res.message)));

  const onSubmit = useCallback(
    () =>
      (setIsSubmitting(true), dispatch(marketAvailability()))
        .then(res => informationModal(res))
        .then(() => setIsSubmitting(false)),
    [dispatch, marketAvailability, requestSettlementWizardData],
  );

  const onReset = useCallback(() => {
    dispatch(reset(formName));
    wizard.first();
  }, [dispatch, wizard]);

  const onDeleteItem = useCallback(
    lotId => {
      dispatch(openModal(deleteLotModal, () => dispatch(deleteSettlementWizardItemAction(lotId))));
    },
    [dispatch, openModal],
  );

  return (
    <Container>
      <OverviewHeader header={SETTLEMENT_FOR_LOT_WIZARD.SUMMARY_HEADER} />

      {Object.entries(requestSettlementWizardData).map(([lotId, summaryData]) => (
        <SummaryItem key={lotId} {...summaryData} onDelete={onDeleteItem} userType={userType} />
      ))}

      <SummaryActions
        onReset={onReset}
        onCancel={onCancel}
        onSubmit={onSubmit}
        isResetDisabled={isAllLotsSelected()}
        isDisabled={isSubmitting}
      />
      <Preloader loading={isPending} />
    </Container>
  );
};

export { SettlementWizardSummary };
