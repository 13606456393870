import React from 'react';

import { LinkButton } from 'shared/components/Buttons';
import { DetailsActivityField } from 'shared/components/Details/DetailsActivityField';
import { DetailsDateField } from 'shared/components/Details/DetailsDateField';
import { DetailsDownloadField } from 'shared/components/Details/DetailsDownloadFieldRedesign';
import { DetailsField } from 'shared/components/Details/DetailsField';
import { DetailsHumanizedField } from 'shared/components/Details/DetailsHumanizedField';
import { hedgeTypes } from 'shared/helpers/constants/hedges/hedgeType';
import { HEDGES } from 'shared/helpers/constants/hedges/hedgesConstants';
import { isBuyerUser } from 'shared/helpers/matchers/checkUserType';
import { dateWithShortTimeFormat } from 'shared/helpers/parsers/date';
import { formatToDollar } from 'shared/helpers/parsers/formaters';

import { history } from 'utils/history';

const hedgeDetailsFieldsConfig = (
  hedge,
  troyOunces,
  prices,
  pricesCustomer,
  downloadAttachment,
  auth,
) => [
  {
    label: HEDGES.RELATED_COMPANY,
    customContent: hedge.relatedCompany && !isBuyerUser(auth?.user.userType) && (
      <LinkButton
        label={hedge.relatedCompany.companyName}
        onClick={() => history.push(`/companies/${hedge.relatedCompany._id}`)}
      />
    ),
    value: hedge.relatedCompany.companyName,
    icon: 'icon-home',
    id: 'relatedCompany',
    FieldType: DetailsField,
  },
  hedge.hedgeType !== hedgeTypes.blanket && {
    label: HEDGES.RELATED_LOT,
    customContent: hedge.relatedLot && (
      <>
        <LinkButton
          label={hedge.relatedLot.grgLotNumber}
          onClick={() => history.push(`/lots/${hedge.relatedLot._id}`)}
        />
        {hedge.relatedLot.customLotNumber && <div>{hedge.relatedLot.customLotNumber}</div>}
      </>
    ),
    icon: 'icon-home',
    id: 'relatedLot',
    FieldType: DetailsField,
  },
  {
    label: HEDGES.HEDGE_TYPE,
    icon: 'icon-type',
    value: hedge.hedgeType,
    id: 'hedgeType',
    FieldType: DetailsHumanizedField,
  },
  {
    label: HEDGES.DELIVERY_DATE,
    value: hedge.deliveryDate,
    id: 'deliveryDate',
    FieldType: DetailsDateField,
  },
  {
    label: HEDGES.DELIVERY_DATE_GRG,
    value: hedge.deliveryDateGRG,
    id: 'deliveryDateGRG',
    FieldType: DetailsDateField,
  },
  {
    label: HEDGES.HEDGE_TOTAL,
    value: formatToDollar(hedge.hedgeTotal),
    prefix: '$',
    icon: 'icon-dollar',
    id: 'hedgeTotal',
    FieldType: DetailsField,
  },
  {
    label: HEDGES.MATERIAL_TYPE,
    icon: 'icon-material',
    value: hedge.materialType,
    id: 'materialType',
    FieldType: DetailsHumanizedField,
  },
  {
    label: HEDGES.MATERIAL_UNITS,
    value: hedge.materialUnits,
    suffix: HEDGES.UNITS,
    icon: 'icon-cart',
    id: 'materialUnits',
    FieldType: DetailsField,
  },
  {
    label: HEDGES.MATERIAL_WEIGHT,
    value: hedge.materialWeight,
    suffix: HEDGES.LBS,
    icon: 'icon-volume',
    id: 'materialWeight',
    FieldType: DetailsField,
  },
  {
    label: HEDGES.CUSTOMER_INSTRUCTIONS,
    value: hedge.customerInstructions,
    icon: 'icon-document',
    id: 'customerInstructions',
    FieldType: DetailsField,
  },
  {
    label: HEDGES.HEDGE_ORDER_DOCUMENT,
    value: hedge.hedgeOrderDocument,
    id: 'hedgeOrderDocument',
    onClick: () => downloadAttachment(hedge.hedgeOrderDocument.path),
    FieldType: DetailsDownloadField,
    forwardable: true,
  },
  {
    label: HEDGES.TROY_OUNCES_HEDGED,
    value: troyOunces,
    icon: 'icon-dollar',
    id: 'troyOunces',
    FieldType: DetailsField,
  },
  {
    label: HEDGES.HEDGE_PRICES,
    value: prices,
    icon: 'icon-dollar',
    id: 'prices',
    FieldType: DetailsField,
  },
  hedge.hedgeType === hedgeTypes.custom && {
    label: HEDGES.HEDGE_PRICES_CUSTOMER,
    value: pricesCustomer,
    icon: 'icon-dollar',
    id: 'pricesCustomer',
    FieldType: DetailsField,
  },
  {
    label: HEDGES.REQUEST_HEDGE_DATE,
    value: hedge.requestedHedgeDate,
    id: 'requestedHedgeDate',
    FieldType: DetailsDateField,
    dateFormat: dateWithShortTimeFormat,
    displayTimeZone: true,
  },
];

const activityFieldsConfig = hedge => [
  {
    label: HEDGES.CREATED_BY,
    user: hedge.createdBy,
    date: hedge.createdAt,
    id: 'createdBy',
    FieldType: DetailsActivityField,
  },
  {
    label: HEDGES.UPDATED_BY,
    user: hedge.updatedBy,
    date: hedge.updatedAt,
    id: 'updatedBy',
    FieldType: DetailsActivityField,
  },
  {
    label: HEDGES.GLOBAL_DECISION_BY,
    user: hedge.globalDecisionBy,
    date: hedge.globalDecisionAt,
    id: 'globalDecisionBy',
    FieldType: DetailsActivityField,
  },
  {
    label: HEDGES.PLACED_ON,
    value: hedge.placedAt,
    id: 'placedAt',
    FieldType: DetailsDateField,
  },
];

const hedgeDetailsContainerConfig = hedge => ({
  header: HEDGES.GENERAL_DETAILS,
  template: [
    `relatedCompany relatedCompany ${
      hedge.hedgeType === hedgeTypes.blanket
        ? 'relatedCompany relatedCompany'
        : 'relatedLot relatedLot'
    }`,
    'hedgeType materialType deliveryDate deliveryDateGRG',
    'hedgeOrderDocument hedgeTotal materialUnits materialWeight',
    'troyOunces troyOunces prices requestedHedgeDate',
    hedge.hedgeType === hedgeTypes.custom
      ? 'pricesCustomer pricesCustomer customerInstructions customerInstructions'
      : 'customerInstructions customerInstructions customerInstructions customerInstructions',
  ],
});

const activityContainerConfig = () => ({
  header: HEDGES.ACTIVITY,
  template: [
    'createdBy createdBy updatedBy updatedBy',
    'globalDecisionBy globalDecisionBy placedAt placedAt',
  ],
});

export {
  hedgeDetailsFieldsConfig,
  activityFieldsConfig,
  hedgeDetailsContainerConfig,
  activityContainerConfig,
};
