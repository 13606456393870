import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getBusyHoursForSelfDeliveryRequestActionType = 'GET_BUSY_HOURS_FOR_SELF_DELIVERY_REQUEST';
const getBusyHoursForSelfDeliveryRequestAction = () => ({
  type: getBusyHoursForSelfDeliveryRequestActionType,
});

const getBusyHoursForSelfDeliveryRequestSuccessActionType = `${getBusyHoursForSelfDeliveryRequestActionType}_SUCCESS`;
const getBusyHoursForSelfDeliveryRequestSuccessAction = busyHoursForSelfDeliveryRequest => ({
  type: getBusyHoursForSelfDeliveryRequestSuccessActionType,
  payload: busyHoursForSelfDeliveryRequest,
});

const getBusyHoursForSelfDeliveryRequestFailureActionType = `${getBusyHoursForSelfDeliveryRequestActionType}_FAILURE`;
const getBusyHoursForSelfDeliveryRequestFailureAction = error => ({
  type: getBusyHoursForSelfDeliveryRequestFailureActionType,
  payload: {
    error,
  },
});

const clearBusyHoursForSelfDeliveryRequestSuccessActionType =
  'CLEAR_BUSY_HOURS_FOR_SELF_DELIVERY_REQUEST_SUCCESS';
const clearBusyHoursForSelfDeliveryRequest = () => ({
  type: clearBusyHoursForSelfDeliveryRequestSuccessActionType,
});

const getBusyHoursForSelfDeliveryRequest = timestamp => async dispatch => {
  dispatch(getBusyHoursForSelfDeliveryRequestAction());

  return axios
    .get(`${API_HOST_AUTH}/self-delivery-requests/busy-hours/${timestamp}`)
    .then(({ data: { busyHoursForSelfDeliveryRequest } }) => {
      dispatch(getBusyHoursForSelfDeliveryRequestSuccessAction(busyHoursForSelfDeliveryRequest));

      return busyHoursForSelfDeliveryRequest;
    })
    .catch(err => {
      dispatch(getBusyHoursForSelfDeliveryRequestFailureAction(err));
      return Promise.reject(err.data);
    });
};

export {
  getBusyHoursForSelfDeliveryRequestActionType,
  getBusyHoursForSelfDeliveryRequestSuccessActionType,
  getBusyHoursForSelfDeliveryRequestFailureActionType,
  clearBusyHoursForSelfDeliveryRequestSuccessActionType,
  getBusyHoursForSelfDeliveryRequest,
  clearBusyHoursForSelfDeliveryRequest,
};
