import { resolveVersion, versions } from 'utils/versioning';

const contactData = resolveVersion({
  [versions.alpha]: {
    phoneNumber: '+1 (609) 396-2250',
    company: 'Alpha Shredding Group',
  },
  [versions.global]: {
    phoneNumber: '+1 (877) 257-1404',
    company: 'Global',
  },
  [versions.west]: {
    phoneNumber: '+1 (702) 844-8842',
    company: 'Global West',
  },
});

export { contactData };
