import axios from 'axios';

import { parseLocationFilters } from 'shared/helpers/dataParsers/location/parseLocationFilters';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getLocationsListActionType = 'GET_LOCATIONS_LIST';
function getLocationsListAction() {
  return {
    type: getLocationsListActionType,
  };
}

const getLocationsListSuccessActionType = 'GET_LOCATIONS_LIST_SUCCESS';
function getLocationsListSuccessAction({ docs, pages, limit, page }) {
  return {
    type: getLocationsListSuccessActionType,
    payload: {
      locations: docs,
      limit,
      page,
      pages,
    },
  };
}

const getLocationsListFailureActionType = 'GET_LOCATIONS_LIST_FAILURE';
function getLocationsListFailureAction(errorMessage) {
  return {
    type: getLocationsListFailureActionType,
    payload: {
      errorMessage,
    },
  };
}

const clearLocationsListActionType = 'CLEAR_LOCATIONS_LIST_ACTION';
function clearLocationsList() {
  return {
    type: clearLocationsListActionType,
  };
}

function getLocationList(companyId, limit, page, searchFilter, locationFilters) {
  return dispatch => {
    dispatch(getLocationsListAction());

    return axios({
      method: 'GET',
      url: `${API_HOST_AUTH}/locations`,
      params: parseLocationFilters(companyId, locationFilters, limit, page, searchFilter || ''),
    })
      .then(({ data }) => dispatch(getLocationsListSuccessAction(data)))
      .catch(() => dispatch(getLocationsListFailureAction('')));
  };
}

export {
  getLocationsListActionType,
  getLocationsListSuccessActionType,
  getLocationsListFailureActionType,
  clearLocationsListActionType,
  getLocationList,
  clearLocationsList,
};
