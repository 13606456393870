import PropTypes from 'prop-types';
import React, { useContext, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { reduxForm, getFormValues, Field } from 'redux-form';

import { shipmentPackagesList } from 'actions/Shipments/shipmentPackagesList';

import { FieldCheckbox } from 'shared/components/Fields/FieldCheckbox/FieldCheckbox';
import { Form, Section } from 'shared/components/Form';
import { OverviewTable } from 'shared/components/OverviewList/OverviewTable/OverviewTable';
import { formName } from 'shared/components/Wizard/Wizard';
import { wizardContext } from 'shared/components/Wizard/Wizard';
import { itemsTemplate } from 'shared/helpers/constants/packages/printPackagesLabelsTableConfig';
import { SHIPMENTS } from 'shared/helpers/constants/shipments/shipmentsConstants';
import { getExistingPackages } from 'shared/helpers/matchers/packages/getExistingPackages';

import { configNames } from 'pages/Packages/components/PackagesManagement/components/PackageForm/components/PackageForm/fieldsConfig';

import { MarkShipmentAsSentModal } from '../MarkShipmentAsSentModal/MarkShipmentAsSentModal';
import { SelectAllPackagesLabels } from './SelectAllPackagesLabels/SelectAllPackagesLabels';

const GeneratePackingListFormLayout = ({ handleSubmit, invalid, change }) => {
  const dispatch = useDispatch();
  const [showSecondMessage, setShowSecondMessage] = useState(false);
  const chosenPackages = useSelector(state => state.shipmentPackagesListData.packages);
  const { shipment } = useSelector(state => state.shipmentDetails);
  const wizard = useContext(wizardContext);

  const { generatePackingListValues, createShipment, packages } = useSelector(state =>
    getFormValues(formName)(state),
  );

  const checkElement = id =>
    chosenPackages.includes(id) ? removePackageFromList(id) : addPackageToList(id);

  const existingPackages = getExistingPackages(
    packages,
    wizard.editMode || wizard.config === configNames.editMultiplePackages,
    wizard.assignedPackages || shipment?.packages,
  );

  useEffect(() => {
    if (wizard.changedShipmentDestination) {
      change(`generatePackingListValues.printPackageLabels`, true);
    }
  }, [wizard.changedShipmentDestination]);

  useEffect(() => {
    if (wizard.editMode && (!chosenPackages.length || chosenPackages !== packages)) {
      const editedPackages = existingPackages
        .filter(({ edited }) => edited)
        .map(
          ({ newPackageNumber, packageNumber }) =>
            newPackageNumber?.toString() || packageNumber?.toString(),
        );

      change(`generatePackingListValues.printPackageLabels`, !!editedPackages.length);
      dispatch(shipmentPackagesList(editedPackages));
    }
  }, [packages]);

  const changeCheckBoxValue = field => () =>
    change(`generatePackingListValues[${field}]`, !generatePackingListValues?.[field]);

  const addPackageToList = packageId =>
    dispatch(shipmentPackagesList([...chosenPackages, packageId]));

  const removePackageFromList = packageId => {
    const packages = chosenPackages.filter(id => id !== packageId);
    dispatch(shipmentPackagesList(packages));
  };

  const handleClickModalClose = () => {
    setShowSecondMessage(false);
    wizard.setSendShipmentModalOpen(false);
  };

  const handleSubmitWizard = () => {
    wizard.handleSubmit(wizard.data);
    handleClickModalClose();
  };

  const handleClickEditShipment = async () => {
    wizard.first();
    wizard.setInputHighlight(true);
    handleClickModalClose();
  };

  const handleClickConfirm = () => {
    if (!createShipment.shipmentSeal) {
      setShowSecondMessage(true);
    } else {
      handleSubmitWizard();
    }
  };

  const markShipmentAsSentIsAvailable =
    !wizard.editMode && wizard.config === configNames.packingList;

  return (
    <>
      <Form
        header={SHIPMENTS.GENERATE_PACKING_LIST_HEADER}
        onSubmit={handleSubmit}
        submitDisabled={invalid}
      >
        <Section
          template={[
            'printPackingList exportToExcel',
            'printPackageLabels markShipmentAsSent',
            'packageLabelsList packageLabelsList',
          ]}
        >
          <Field
            name="printPackingList"
            component={FieldCheckbox}
            label={SHIPMENTS.PRINT_PACKING_LIST}
            checked={generatePackingListValues?.printPackingList}
            onClick={changeCheckBoxValue('printPackingList')}
          />
          <Field
            name="exportToExcel"
            component={FieldCheckbox}
            label={SHIPMENTS.EXPORT_TO_EXCEL}
            checked={generatePackingListValues?.exportToExcel}
            onClick={changeCheckBoxValue('exportToExcel')}
          />
          <Field
            name="printPackageLabels"
            component={FieldCheckbox}
            label={SHIPMENTS.PRINT_PACKAGE_LABELS}
            checked={generatePackingListValues?.printPackageLabels}
            onClick={changeCheckBoxValue('printPackageLabels')}
          />
          {markShipmentAsSentIsAvailable && (
            <Field
              name="markShipmentAsSent"
              component={FieldCheckbox}
              label={SHIPMENTS.MARK_SHIPMENT_AS_SENT}
              checked={generatePackingListValues?.markShipmentAsSent}
              onClick={changeCheckBoxValue('markShipmentAsSent')}
            />
          )}
        </Section>

        {generatePackingListValues?.printPackageLabels && (
          <Section
            template={['packageLabelsList packageLabelsList']}
            noPadding
            header={
              <SelectAllPackagesLabels
                changedShipmentDestination={wizard.changedShipmentDestination}
                chosenPackages={chosenPackages}
                packages={existingPackages}
              />
            }
            headerPadding="25px"
          >
            <div name="packageLabelsList">
              {existingPackages && (
                <OverviewTable
                  itemsTemplate={itemsTemplate}
                  templateProps={{
                    listSize: existingPackages.length,
                    isPackingList:
                      wizard.config === configNames.packingList ||
                      wizard.config === configNames.editMultiplePackages,
                  }}
                  actionsTemplate={[]}
                  actionsContext={{ checkElement }}
                  items={existingPackages}
                />
              )}
            </div>
          </Section>
        )}
      </Form>
      <MarkShipmentAsSentModal
        handleClickEditShipment={handleClickEditShipment}
        handleClickModalClose={handleClickModalClose}
        handleClickConfirm={handleClickConfirm}
        handleSubmitWizard={handleSubmitWizard}
        sendShipmentModalOpen={wizard.sendShipmentModalOpen}
        userType={wizard.userType}
        showSecondMessage={showSecondMessage}
      />
    </>
  );
};

GeneratePackingListFormLayout.propTypes = {
  change: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
};

const GeneratePackingListForm = reduxForm({})(GeneratePackingListFormLayout);

export { GeneratePackingListForm, GeneratePackingListFormLayout };
