import React from 'react';

import { UIAuthorization } from 'components/Authorization/UIAuthorization';

import { Button } from 'shared/components/Buttons/Button';
import { Icon } from 'shared/components/Icon/Icon';
import { OverviewActionsCell } from 'shared/components/OverviewList/OverviewActionsCell/OverviewActionsCell';
import { OverviewListTableCell } from 'shared/components/OverviewList/OverviewListTableCell/OverviewListTableCell';
import { OverviewTableRow } from 'shared/components/OverviewList/OverviewTableRow/OverviewTableRow';
import { isPrimaryRowAction } from 'shared/helpers/matchers/isPrimaryRowAction';
import { renderHighlight } from 'shared/helpers/parsers/highlight';
import { renderItem } from 'shared/helpers/parsers/lists';
import { cssVar } from 'shared/helpers/styling/styling';

const FullPageListTableItem = ({
  item,
  searchFilter,
  itemsTemplate,
  actionsTemplate,
  actionsContext,
  itemsNumber,
  equalColumns,
  stripedItems,
  detailsList,
  dataTestId = '',
}) => {
  const filteredActionsTemplate = actionsTemplate.filter(
    ({ access }) => !access || access.call(actionsContext, item),
  );
  const rowClassName =
    itemsTemplate.className && itemsTemplate.className.call(actionsContext, item);
  const isRowClickable = filteredActionsTemplate.filter(({ action }) => isPrimaryRowAction(action));

  function clickableRowAction() {
    if (isRowClickable.length) isRowClickable[0].onClick(item)();
  }

  const callAction = (e, onClick) => {
    onClick(item)();
    e.stopPropagation();
  };

  return (
    <OverviewTableRow
      additionalClass={rowClassName}
      itemsNumber={itemsNumber || itemsTemplate.length - !actionsTemplate.length}
      header
      isClickable={isRowClickable.length}
      onClick={clickableRowAction}
      stripedItems={stripedItems}
      equalColumns={equalColumns}
      detailsList={detailsList}
      data-testId={dataTestId}
    >
      {itemsTemplate.map(({ highlight, render, includeRegExp, dueDiligence }, index) => (
        <OverviewListTableCell key={index}>
          {highlight
            ? renderHighlight(render, item, searchFilter, includeRegExp, dueDiligence)
            : renderItem(render, item)}
        </OverviewListTableCell>
      ))}
      <UIAuthorization access={actionsTemplate.length}>
        <OverviewActionsCell>
          {filteredActionsTemplate.map(({ icon, onClick, location, access }, index) => (
            <UIAuthorization key={index} access={!access || access(item)}>
              <Button
                height={42}
                key={index}
                borderRadius={100}
                onClick={e => onClick && callAction(e, onClick)}
                margin={false}
                noOutline
                bgColor={cssVar('limedSpruceList')}
                fitToContent
                data-testId={`${dataTestId || index}_action`}
              >
                {location ? (
                  <a href={location(item)} target="_blank" rel="noopener noreferrer">
                    <Icon
                      icon={`icon-${icon}`}
                      color={cssVar('regentGrayBrighter')}
                      height={42}
                      width={42}
                    />
                  </a>
                ) : (
                  <Icon
                    icon={`icon-${icon}`}
                    color={cssVar('regentGrayBrighter')}
                    height={42}
                    width={42}
                  />
                )}
              </Button>
            </UIAuthorization>
          ))}
        </OverviewActionsCell>
      </UIAuthorization>
    </OverviewTableRow>
  );
};

export { FullPageListTableItem };
