import { acceptForGradingSuccessType } from 'actions/Lots/acceptForGrading';
import { acceptForMixingSuccessType } from 'actions/Lots/acceptForMixing';
import { acceptForProcessingSuccessType } from 'actions/Lots/acceptForProcessing';
import { changeProcessUponDeliverySuccessActionType } from 'actions/Lots/changeProcessUponDelivery';
import {
  finalizeLotType,
  finalizeLotSuccessType,
  finalizeLotFailureType,
} from 'actions/Lots/finalizeLot';
import {
  generateShipmentSummaryType,
  generateShipmentSummarySuccessType,
  generateShipmentSummaryFailureType,
} from 'actions/Lots/generateShipmentSummary';
import {
  getLotByBarcodeType,
  getLotByBarcodeSuccessType,
  getLotByBarcodeFailureType,
} from 'actions/Lots/getLotByBarcode';
import {
  getLotDetailsActionType,
  getLotDetailsSuccessActionType,
  getLotDetailsFailureActionType,
  clearLotDetailsActionType,
} from 'actions/Lots/lotDetails';
import {
  manageGlobalFilesSuccessType,
  manageGlobalFilesFailureType,
} from 'actions/Lots/manageGlobalFiles';
import {
  manageGradingReceiptFailureType,
  manageGradingReceiptSuccessType,
  manageGradingReceiptType,
} from 'actions/Lots/manageGradingReceipt';
import { markGradingCompleteSuccessType } from 'actions/Lots/markGradingComplete';
import { markProcessingCompleteSuccessType } from 'actions/Lots/markProcessingComplete';
import {
  retryImportActionType,
  retryImportFailureActionType,
  retryImportSuccessActionType,
} from 'actions/Lots/retryImport';
import {
  printPackageLabelsActionType,
  printPackageLabelsFailureActionType,
  printPackageLabelsSuccessActionType,
} from 'actions/Packages/printPackageLabels';

const INITIAL_STATE = {
  isPending: false,
  errorMessage: null,
  lot: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getLotDetailsActionType:
      return {
        lot: null,
        isPending: true,
      };
    case getLotDetailsSuccessActionType:
    case acceptForGradingSuccessType:
    case markGradingCompleteSuccessType:
    case markProcessingCompleteSuccessType:
    case acceptForProcessingSuccessType:
    case acceptForMixingSuccessType:
    case getLotByBarcodeSuccessType:
      return {
        ...state,
        isPending: false,
        lot: action.payload.lot,
      };
    case getLotDetailsFailureActionType:
      return {
        ...state,
        isPending: false,
        errorMessage: action.payload.errorMessage,
      };
    case clearLotDetailsActionType:
      return {
        isPending: false,
        errorMessage: null,
        lot: null,
      };
    case changeProcessUponDeliverySuccessActionType:
      return {
        ...state,
        lot: state.lot
          ? {
              ...state.lot,
              processUponDelivery: action.payload.lot.processUponDelivery,
              updatedBy: action.payload.lot.updatedBy,
              updatedAt: action.payload.lot.updatedAt,
            }
          : null,
      };
    case retryImportSuccessActionType:
      return {
        ...state,
        isPending: false,
        lot: state.lot
          ? {
              ...state.lot,
              ...action.payload.lot,
            }
          : null,
      };
    case retryImportActionType:
    case printPackageLabelsActionType:
    case generateShipmentSummaryType:
    case manageGradingReceiptType:
    case finalizeLotType:
    case getLotByBarcodeType:
      return {
        ...state,
        isPending: true,
      };
    case retryImportFailureActionType:
    case printPackageLabelsSuccessActionType:
    case printPackageLabelsFailureActionType:
    case manageGradingReceiptFailureType:
    case finalizeLotFailureType:
    case generateShipmentSummaryFailureType:
    case getLotByBarcodeFailureType:
      return {
        ...state,
        isPending: false,
      };
    case manageGlobalFilesSuccessType:
      return {
        ...state,
        lot: {
          ...state.lot,
          ...action.payload,
          grgFinalInvoiceDraft: null,
          grgShipmentSummaryDraft: null,
        },
      };
    case manageGlobalFilesFailureType:
      return {
        ...state,
        lot: {
          ...state.lot,
          grgFinalInvoiceDraft: null,
          grgShipmentSummaryDraft: null,
        },
      };
    case generateShipmentSummarySuccessType:
    case manageGradingReceiptSuccessType:
    case finalizeLotSuccessType:
      return {
        ...state,
        isPending: false,
        lot: {
          ...state.lot,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};
