import React from 'react';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form';

import { FieldInput } from 'shared/components/Fields';
import { Section } from 'shared/components/Form';
import { PROFIT_LOSS } from 'shared/helpers/constants/lots/profitLossConstants';

const CurrentCommodities = ({ fields }) => {
  const { commodities } = useSelector(({ profitLossData: { data } }) => data);

  return (
    <>
      {fields.map((field, index) => (
        <Section
          header={`${PROFIT_LOSS.COMMODITY} ${index + 1}`}
          key={field}
          template={[
            `${field}.material ${field}.materialType`,
            `${field}.fromLot ${field}.toLot`,
            `${field}.value .`,
          ]}
        >
          <Field
            name={`${field}.material`}
            component={FieldInput}
            label={PROFIT_LOSS.MATERIAL}
            disabled
            suffix={commodities[index].materialWeight ? PROFIT_LOSS.LBS : PROFIT_LOSS.UNITS}
          />
          <Field
            name={`${field}.materialType`}
            component={FieldInput}
            label={PROFIT_LOSS.MATERIAL_TYPE}
            disabled
          />
          <Field
            name={`${field}.fromLot`}
            component={FieldInput}
            label={PROFIT_LOSS.FROM_LOT}
            disabled
          />
          <Field
            name={`${field}.toLot`}
            component={FieldInput}
            label={PROFIT_LOSS.TO_LOT}
            disabled
          />
          <Field
            name={`${field}.value`}
            component={FieldInput}
            label={PROFIT_LOSS.VALUE}
            field="required"
            type="text"
            prefix="$"
          />
        </Section>
      ))}
    </>
  );
};

export { CurrentCommodities };
