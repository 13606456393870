import { Component } from 'react';

import { history } from 'utils/history';

import { Socket } from '../SocketContext/socketContext';
import {
  StyledNotifications,
  StyledNotificationsBell,
  StyledNotificationsCounter,
} from './StyledNotificationCounter';

class NotificationsCounter extends Component {
  componentDidMount = () => {
    const {
      auth,
      getNotificationsCount,
      setNotificationsCount,
      getNotifications,
      getNotificationsOnDashboard,
    } = this.props;

    getNotificationsCount(auth.user.id);

    Socket.instance.on('pushNotifications', notificationCount => {
      const {
        notificationsList: { limit = 3, page = 1, query = '' },
      } = this.props;

      setNotificationsCount(notificationCount);
      if (window.location.pathname.includes('dashboard')) getNotificationsOnDashboard(3);
      if (window.location.pathname.includes('notifications')) getNotifications(limit, page, query);
    });
  };

  componentWillUnmount = () => this.props.clearNotificationsCount();

  normalizeNotificationCount = notificationsCount => {
    if (notificationsCount > 99) {
      return '99+';
    }

    return notificationsCount;
  };

  goToNotificationsList = () => history.push('/notifications');

  render() {
    const { notificationsCount } = this.props.notificationsCount;

    return (
      <StyledNotifications onClick={this.goToNotificationsList}>
        <StyledNotificationsBell />
        {notificationsCount > 0 && (
          <StyledNotificationsCounter>
            {this.normalizeNotificationCount(notificationsCount)}
          </StyledNotificationsCounter>
        )}
      </StyledNotifications>
    );
  }
}

export { NotificationsCounter };
