import { resolveVersion, versions } from "utils/versioning";

export const companyInformation = resolveVersion({
  [versions.alpha]: {
    name: 'Alpha Shredding Group, Inc.',
    shortName: 'Alpha Shredding Group',
    address1: '301 Enterprise Ave.',
    address2: 'Trenton, NJ 08638',
    representativeName: 'Alpha',
    articleShortName: 'an Alpha',
  },
  [versions.global]: {
    name: 'Global Refining Group, Inc.',
    shortName: 'Global Refining Group',
    address1: '618 Main St.',
    address2: 'Kenbridge, VA 23944',
    representativeName: 'Global',
    articleShortName: 'a Global',
  },
  [versions.west]: {
    name: 'Global Refining Group West, Inc.',
    shortName: 'Global Refining Group West',
    address1: '3890 E. Craig Rd.',
    address2: 'North Las Vegas, NV 89030',
    representativeName: 'Global',
    articleShortName: 'a Global',
  },
});
