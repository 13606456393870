import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getShipmentsCalendarActionType = 'GET_OUTGOING_SHIPMENTS_CALENDAR';
const getShipmentsCalendarAction = () => ({
  type: getShipmentsCalendarActionType,
});

const getShipmentsCalendarSuccessActionType = `${getShipmentsCalendarActionType}_SUCCESS`;
const getShipmentsCalendarSuccessAction = shipments => ({
  type: getShipmentsCalendarSuccessActionType,
  payload: {
    shipments,
  },
});

const getShipmentsCalendarFailureActionType = `${getShipmentsCalendarActionType}_FAILURE`;
const getShipmentsCalendarFailureAction = () => ({
  type: getShipmentsCalendarFailureActionType,
});

const clearShipmentsCalendarActionType = 'CLEAR_SHIPMENTS_CALENDAR';
const clearShipmentsCalendar = () => ({
  type: clearShipmentsCalendarActionType,
});

const getShipmentsCalendar = timestamp => dispatch => {
  dispatch(getShipmentsCalendarAction());

  return axios
    .get(`${API_HOST_AUTH}/shipments/filtered/${timestamp}`)
    .then(res => dispatch(getShipmentsCalendarSuccessAction(res.data)))
    .catch(() => dispatch(getShipmentsCalendarFailureAction()));
};

export {
  getShipmentsCalendarActionType,
  getShipmentsCalendarSuccessActionType,
  getShipmentsCalendarFailureActionType,
  clearShipmentsCalendarActionType,
  getShipmentsCalendar,
  clearShipmentsCalendar,
};
