import {
  getSpecialPermissionsLogsActionType,
  getSpecialPermissionsLogsSuccessActionType,
  getSpecialPermissionsLogsFailureActionType,
} from 'actions/SpecialPermissionsLogs/getSpecialPermissionsLogs';

const INITIAL_STATE = {
  isPending: false,
  errorMessage: null,
  items: [],
  limit: 50,
  page: 1,
  pages: null,
};

export const SpecialPermissionsLogs = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getSpecialPermissionsLogsActionType:
      return {
        ...state,
        isPending: true,
      };
    case getSpecialPermissionsLogsSuccessActionType:
      return {
        ...state,
        isPending: false,
        items: action.payload.docs,
        page: action.payload.page,
        limit: action.payload.limit,
        pages: action.payload.pages,
      };
    case getSpecialPermissionsLogsFailureActionType:
      return {
        ...state,
        isPending: false,
        errorMessage: action.payload?.errorMessage,
        items: [],
        pages: null,
      };
    default:
      return state;
  }
};
