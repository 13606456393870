import { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import { useHistory, useParams } from 'react-router-dom';
import { compose } from 'redux';
import { destroy } from 'redux-form';

import { clearPaymentDetails, getPayment } from 'actions/Payments/paymentDetails';
import { updateDraftPoolPayment } from 'actions/Payments/updateDraftPayment';
import { openModal } from 'actions/shared/modal';

import { Forbidden } from 'components/ErrorPage/ErrorPage';

import { FormContainer } from 'shared/components/Form';
import { Wizard, formName } from 'shared/components/Wizard/Wizard';
import { updateDraftPoolPaymentAccess } from 'shared/helpers/accesses/payments/paymentAccesses';
import { cancelModal } from 'shared/helpers/constants/modalConstants';
import { PAYMENTS } from 'shared/helpers/constants/payments/paymentsConstants';
import { parseUpdateDraftPoolPaymentData } from 'shared/helpers/dataParsers/payments/parseUpdateDraftPoolPayment';
import { humanizeCamelCase } from 'shared/helpers/parsers/text';

import { PaymentForm, SettlementForm, FinanceChargesForm } from '../EnterPoolPayment/components';

const steps = {
  payment: PaymentForm,
  settlement: SettlementForm,
  financeCharges: FinanceChargesForm,
};

const initialFormValues = payment => ({
  payment: {
    requestedFinalPaymentDate: new Date(payment?.requestedFinalPaymentDate),
    relatedCompany: {
      ...payment?.relatedCompany,
      value: payment?.relatedCompany?._id,
      label: payment?.relatedCompany?.companyName,
    },
    additionalInfo: payment?.additionalInfo,
    fundsType: {
      value: payment?.fundsType,
      label: humanizeCamelCase(payment?.fundsType),
    },
    isDraftPoolPayment: true,
  },
  settlement: {
    ouncesRh: payment?.settlement.ouncesRh,
    ouncesPt: payment?.settlement.ouncesPt,
    ouncesPd: payment?.settlement.ouncesPd,
  },
  financeCharges: {},
});

const UpdateDraftPoolPaymentLayout = () => {
  const { id } = useParams();
  const [isPending, setIsPending] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { isPending: isPaymentDetailsPending, payment } = useSelector(
    ({ paymentDetails }) => paymentDetails,
  );
  const auth = useSelector(({ auth }) => auth);

  useEffect(() => () => dispatch(destroy(formName)), []);
  useEffect(() => {
    dispatch(getPayment(id));

    return () => {
      dispatch(clearPaymentDetails());
    };
  }, [id]);

  const onSubmit = values => {
    setIsPending(true);

    dispatch(updateDraftPoolPayment(id, parseUpdateDraftPoolPaymentData(values))).finally(() => {
      history.push('/payments');
      setIsPending(false);
    });
  };

  const onCancel = useCallback(() => {
    dispatch(
      openModal(cancelModal, () => {
        history.push('/payments');
      }),
    );
  }, []);

  return (
    <FormContainer
      header={PAYMENTS.UPDATE_DRAFT_POOL_PAYMENT(payment?.relatedCompany.companyName)}
      loaded={!isPaymentDetailsPending && !isPending}
    >
      {updateDraftPoolPaymentAccess(auth, payment) ? (
        <Wizard
          steps={steps}
          header={PAYMENTS.UPDATE_DRAFT_POOL_PAYMENT(payment?.relatedCompany.companyName)}
          onSubmit={onSubmit}
          initialValues={initialFormValues(payment)}
          onCancel={onCancel}
          contextProps={{
            editMode: true,
          }}
        />
      ) : (
        <Forbidden />
      )}
    </FormContainer>
  );
};

const UpdateDraftPoolPayment = compose(withRouter)(UpdateDraftPoolPaymentLayout);
export { UpdateDraftPoolPaymentLayout, UpdateDraftPoolPayment };
