const lotConstants = {
  INVALID_BUILDING_EMPTY: 'Building value cannot be empty.',
  INVALID_BUILDING: 'Building value must have a valid format.',
  INVALID_SECTION_EMPTY: 'Section value cannot be empty.',
  INVALID_SECTION_RANGE: max => `Section should be a number between 1 and ${max} inclusive.`,
  INVALID_SECTION: 'Section value must have a valid format.',
  INVALID_LANE: 'Lane value must have a valid format.',
};

export { lotConstants };
