import { commoditiesOverviewListReducer } from './commoditiesOverviewList';
import { deleteCommodityReducer } from './deleteCommodity';
import { getLotsForCommodityReducer } from './getLotsForCommodity';
import { removeCommodityFromLotReducer } from './removeCommodityFromLot';

const commoditiesReducer = {
  getLotsForCommodity: getLotsForCommodityReducer,
  removeCommodityFromLot: removeCommodityFromLotReducer,
  commoditiesOverviewList: commoditiesOverviewListReducer,
  deleteCommodity: deleteCommodityReducer,
};

export { commoditiesReducer };
