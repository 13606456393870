import PropTypes from 'prop-types';
import React from 'react';
import { Container } from 'reactstrap';
import styled from 'styled-components';

import { Button } from 'shared/components/Buttons/Button';
import { DetailsField } from 'shared/components/Details/DetailsField';
import { Icon } from 'shared/components/Icon/Icon';
import { pxToRem, cssVar, media } from 'shared/helpers/styling/styling';

const SectionContainer = styled.div`
  position: relative;
  display: flex;
  margin: auto;
  justify-content: space-between;

  ${DetailsField} {
    padding: 0;

    ${media.mobile`
      margin-top: 30px;
      margin-left: -20px;
    `};
  }

  ${Icon} {
    cursor: pointer;

    ${media.mobile`
      position: absolute;
      right: 0;
    `};
  }

  ${Button} {
    ${media.mobile`
      margin-top: 30px;
    `};
  }

  ${media.mobile`
     padding-right: 35px;
  `};
  ${media.phone`
    flex-direction: column;
  `}
`;

const MainSection = styled.span`
  font-size: ${({ description }) => (description ? pxToRem(14) : pxToRem(24))};
  font-weight: ${({ description }) => (description ? 300 : 500)};
  color: ${({ description }) => (description ? cssVar('heather') : cssVar('alabaster'))};
  max-width: ${({ description }) => description && '555px'};
`;

const SectionLayout = ({ className, label, description, children }) => (
  <div className={className}>
    <Container>
      <SectionContainer>
        <MainSection description={description}>{label}</MainSection>
        {children}
      </SectionContainer>
    </Container>
  </div>
);

const Section = styled(SectionLayout)`
  width: 100%;
  padding: 28px 10px 30px;
  border-bottom: ${({ description }) => !description && `1px solid ${cssVar('nevadaDarker')}`};
`;

Section.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.arrayOf(PropTypes.node).isRequired,
    PropTypes.node.isRequired,
  ]),
  description: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export { Section };
