import React, { Component } from 'react';

import { FormContainer } from 'shared/components/Form';
import { AGREEMENTS } from 'shared/helpers/constants/agreements/agreementsConstants';
import { createAgreementConfig } from 'shared/helpers/constants/agreements/createAgreementConfig';

import { history } from 'utils/history';

import { ConnectedAgreementForm } from '../AgreementForm/AgreementForm';

class CreateAgreement extends Component {
  state = {
    relatedCompany: {},
  };

  componentDidMount() {
    this.props
      .getCompany(this.props.match.params.id)
      .then(company => this.checkCompanyDetails(company));
  }

  componentWillUnmount() {
    this.props.clearCompanyDetails();
  }

  setRelatedCompany(company) {
    this.setState({ relatedCompany: { ...company } });
  }

  /**
   * Submit user object with user data, with actual logged user main data
   * @param values
   */
  submit = values => this.props.createAgreement(values, this.state.relatedCompany._id);

  checkCompanyDetails(company) {
    if (company) {
      this.setRelatedCompany(company);
    } else {
      history.push('/companies');
    }
  }

  render() {
    const { isPending } = this.props.companyDetails;

    return (
      <FormContainer
        header={AGREEMENTS.CREATE_AN_AGREEMENT}
        subHeader={this.state.relatedCompany.companyName}
        loaded={!isPending}
      >
        {() => (
          <ConnectedAgreementForm
            onSubmit={this.submit}
            relatedCompany={this.state.relatedCompany}
            defaultValues={createAgreementConfig.defaultValues}
            {...this.props}
          />
        )}
      </FormContainer>
    );
  }
}

export { CreateAgreement };
