import React from 'react';
import styled, { css } from 'styled-components';

import { Button } from 'shared/components/Buttons/Button';
import { Icon } from 'shared/components/Icon/Icon';
import { ModalListContainer } from 'shared/components/ModalListContainer';
import { eventTypes, CALENDAR } from 'shared/helpers/constants/calendarConstants';
import { setDesignation } from 'shared/helpers/dataParsers/lot/setDesignation';
import { pxToRem, cssVar, media } from 'shared/helpers/styling/styling';

import dateAdapter from 'utils/date/dateAdapter';

const resolveHeader = event => {
  const label = (() => {
    switch (event.type) {
      case eventTypes.incomingShipments:
        return CALENDAR.INCOMING_SHIPMENTS;
      case eventTypes.outgoingShipments:
        return CALENDAR.OUTGOING_SHIPMENTS;
      case eventTypes.selfDeliveryRequests:
      default:
        return CALENDAR.SELF_DELIVERY_REQUESTS;
    }
  })();

  return `${dateAdapter(event.timestamp).format('MM/DD')} - ${label}`;
};

const resolvePath = (type, id, history) => {
  switch (type) {
    case eventTypes.incomingShipments:
      return history.push(`/shipments/incoming/${id}`);
    case eventTypes.outgoingShipments:
      return history.push(`/shipments/outgoing/${id}`);
    case eventTypes.selfDeliveryRequests:
    default:
      return history.push(`/shipments/self-delivery-requests/${id}`);
  }
};

const renderAssignedLots = assignedLots =>
  assignedLots.length
    ? assignedLots.map(lot => <div>{setDesignation(lot.grgLotNumber, lot.designation)}</div>)
    : CALENDAR.EMPTY;

const isSelfDeliveryRequest = type => type === eventTypes.selfDeliveryRequests;

const disableAction = (eventType, allowActions, paramId, itemId) =>
  (!isSelfDeliveryRequest(eventType) && !allowActions) || (allowActions && paramId !== itemId);

const ContentListHeader = styled.header`
  min-height: 55px;
  padding: 4px 55px;
  font-size: ${pxToRem(12)};
  color: ${cssVar('alto')};
  background-color: ${cssVar('shark')};
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${media.phone`
    padding: 4px 12px;
  `}
`;

const ListItem = styled.div`
  font-size: ${pxToRem(14)};
  color: ${cssVar('whiteGRG')};
  background-color: ${cssVar('fiordLighter')};
  min-height: 88px;
  padding: 4px 55px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${({ match, item, eventType, allowActions }) =>
    disableAction(eventType, allowActions, match.params.id, item.id) &&
    css`
      cursor: pointer;

      &:hover {
        opacity: 0.92;
      }
    `};

  &:not(:first-of-type) {
    border-top: 2px solid rgba(255, 255, 255, 0.23);
  }

  span {
    margin-right: 15px;
  }

  ${media.phone`
    padding: 8px 12px;
  `}
`;

const ModalContent = ({ event, history, match, allowActions }) => (
  <ModalListContainer header={resolveHeader(event)}>
    <div>
      <ContentListHeader>
        <span>
          {!isSelfDeliveryRequest(event.type) ? CALENDAR.ASSIGNED_LOTS : CALENDAR.FROM_COMPANY}
        </span>
        {((!isSelfDeliveryRequest(event.type) && !allowActions) || allowActions) && (
          <span>{CALENDAR.ACTIONS}</span>
        )}
      </ContentListHeader>
      {event.data.map(item => (
        <ListItem
          allowActions={allowActions}
          eventType={event.type}
          key={item.id}
          item={item}
          match={match}
          onClick={() =>
            disableAction(event.type, allowActions, match.params.id, item.id) &&
            resolvePath(event.type, item.id, history)
          }
        >
          <span>
            {!isSelfDeliveryRequest(event.type)
              ? renderAssignedLots(item.assignedLots)
              : item.relatedCompany[0].companyName}
          </span>
          {disableAction(event.type, allowActions, match.params.id, item.id) && (
            <Button
              bgColor={cssVar('limedSpruceList')}
              bgColorHover={cssVar('limedSpruceList')}
              borderRadius={100}
              margin={false}
              noOutline
              fitToContent
            >
              <Icon icon="icon-arrow-right" color={cssVar('whiteGRG')} />
            </Button>
          )}
        </ListItem>
      ))}
    </div>
  </ModalListContainer>
);

export { ModalContent, ContentListHeader, ModalListContainer, ListItem };
