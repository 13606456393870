import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

import { cssVar, media } from 'shared/helpers/styling/styling';

const Header = ({ actions, className, children }) => (
  <div className={className}>
    {children}
    {actions}
  </div>
);

export const HeaderStyled = styled(Header)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 35px;
  padding: 0 37px;
  color: ${cssVar('alto')};
  background-color: ${cssVar('outerSpaceBitLighter')};
  border-bottom: 1px solid ${cssVar('outerSpaceBitLighter')};
  font-size: 0.75rem;

  ${media.mobile`
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    `}

  ${({ headerPadding }) =>
    css`
      padding: ${headerPadding};
    `};
`;

Header.propTypes = {
  actions: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};
