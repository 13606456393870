import { connect } from 'react-redux';

import {
  getLocationDetailsUsers,
  clearLocationDetailsUsersList,
} from 'actions/Locations/Details/usersList';
import { getLocation } from 'actions/Locations/locationDetails';
import { showSnackbar } from 'actions/shared/snackbar';

import { LocationDetails } from './LocationDetails';

const mapStateToProps = ({ auth, locationDetails, locationDetailsUsersList }) => ({
  auth,
  locationDetails,
  locationDetailsUsersList,
});

const mapDispatchToProps = {
  getLocation,
  getLocationDetailsUsers,
  clearLocationDetailsUsersList,
  showSnackbar,
};

export const ConnectedLocationDetails = connect(
  mapStateToProps,
  mapDispatchToProps,
)(LocationDetails);
