import axios from 'axios';

import { showSnackbar } from 'actions/shared/snackbar';

import { REPORTS } from 'shared/helpers/constants/reports/reportsConstants';
import {
  dangerOptions,
  infoOptions,
  successOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

const API_HOST_AUTH = process.env.REACT_APP_API;

export const generateReportActionType = `GENERATE_REPORT`;
const generateReportAction = () => ({ type: generateReportActionType });

export const generateReportSuccessActionType = `${generateReportActionType}_SUCCESS`;
const generateReportSuccessAction = () => ({ type: generateReportSuccessActionType });

export const generateReportFailureActionType = `${generateReportActionType}_FAILURE`;
const generateReportFailureAction = () => ({ type: generateReportFailureActionType });

export const generateReport =
  (limitFullyReports = '', companyId) =>
  async dispatch => {
    dispatch(generateReportAction());
    dispatch(showSnackbar(infoOptions, REPORTS.GENERATION_PROCESS));

    const URL = `${API_HOST_AUTH}/report/generate/${companyId}${
      limitFullyReports && `?limitFullyReports=${encodeURIComponent(limitFullyReports)}`
    }`;

    return axios
      .get(URL)
      .then(({ data }) => {
        dispatch(generateReportSuccessAction());
        dispatch(showSnackbar(successOptions, data.message));

        return data;
      })
      .catch(err => {
        dispatch(generateReportFailureAction());
        dispatch(showSnackbar(dangerOptions, err.data.message));

        return Promise.reject(err.data);
      });
  };
