import {
  createHedgesActionType,
  createHedgesSuccessActionType,
  createHedgesFailureActionType,
} from 'actions/Hedges/hedgesCreate';

const INITIAL_STATE = {
  isPending: false,
};

const createHedgesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case createHedgesActionType:
      return {
        isPending: true,
      };

    case createHedgesSuccessActionType:
    case createHedgesFailureActionType:
      return {
        isPending: false,
      };

    default:
      return state;
  }
};

export { createHedgesReducer };
