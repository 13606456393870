import PropTypes from 'prop-types';
import React, { Children } from 'react';
import styled, { css } from 'styled-components';

import { cssVar, media } from 'shared/helpers/styling/styling';

import { FIELD } from '../constants';

const Required = styled.span.attrs({ children: '*' })`
  visibility: ${({ required }) => (required ? 'visible' : 'hidden')};
  color: ${cssVar('japonica')};
  font-size: 1rem;
  padding: 0 1px;
`;

const Label = ({ required, unique, children, id, disabled, className }) => (
  <div className={className}>
    <label htmlFor={id}>
      {children}
      {unique && ` ${FIELD.UNIQUE}`}
      {!!Children.toArray(children).length && ':'}
    </label>
    <Required required={required && !disabled} />
  </div>
);

const LabelStyled = styled(Label)`
  grid-area: label;
  display: flex;
  text-align: right;
  align-items: flex-start;
  justify-content: flex-end;
  margin-right: 25px;
  font-size: 0.75rem;
  color: ${cssVar('alto')};
  label {
    margin: 4px 2px 0 0;
    ${({ tooltipExists }) =>
      tooltipExists &&
      css`
        display: flex;
        align-items: center;
      `}
  }

  ${({ pretendMobile }) =>
    pretendMobile &&
    css`
      justify-content: flex-start;
      margin-bottom: 16px;
    `}

  ${media.mobile`
    justify-content: flex-start;
    margin-bottom: 16px;
  `}
`;

LabelStyled.propTypes = {
  id: PropTypes.string,
  required: PropTypes.bool,
  unique: PropTypes.bool,
  disabled: PropTypes.bool,
  tooltipExists: PropTypes.bool,
};

LabelStyled.defaultProps = {
  required: false,
  unique: false,
  disabled: false,
  tooltipExists: false,
};

export { LabelStyled as Label, Required };
