const showSnackbarActionType = 'SHOW_SNACKBAR';
function showSnackbarAction(options, message) {
  return {
    type: showSnackbarActionType,
    payload: {
      options,
      message,
    },
  };
}

const hideSnackbarActionType = 'HIDE_SNACKBAR';
function hideSnackbarAction() {
  return {
    type: hideSnackbarActionType,
  };
}

function showSnackbar(options, message) {
  return dispatch => {
    dispatch(showSnackbarAction(options, message));
  };
}

function hideSnackbar() {
  return dispatch => {
    dispatch(hideSnackbarAction());
  };
}

export { showSnackbarActionType, hideSnackbarActionType, showSnackbar, hideSnackbar };
