import React, { Component } from 'react';
import Select from 'react-select';

import { isPhone } from 'shared/helpers/constants/resolutionsConstants';
import { humanize } from 'shared/helpers/parsers/text';

import { getBrowserWidth } from 'utils/responsive';

import { DropdownIndicator } from '../Fields/FieldSearch/FieldSearch';
import { styles, MenuList } from '../Fields/FieldSelect/FieldSelect';
import { FIELD_SELECT } from '../Fields/FieldSelect/constants';
import { withFieldWrapper } from '../Fields/FieldWrapper/FieldWrapper';
import {
  StyledFiltersDropdown,
  StyledFiltersDropdownInput,
  StyledFiltersDropdownItem,
  StyledFiltersDropdownToggle,
} from '../FiltersDropdown/StyledFiltersDropdown';
import { Icon } from '../Icon/Icon';
import { DropdownIcon } from '../ListFilters/DropdownIcon/DropdownIcon';
import { MenuDropdown } from '../ListFilters/DropdownMenu/DropdownMenu';
import {
  DropdownMenuItemText,
  DropdownMenuOptionsDivider,
} from '../ListFilters/styles/SelectDropdown.styles';

class LocationsDropdownBase extends Component {
  state = {
    open: false,
    selected: {},
    searchableOptions: [],
  };

  static getDerivedStateFromProps(nextProps) {
    return {
      selected: nextProps.selected,
    };
  }

  componentDidMount() {
    const { options } = this.props;
    this.setFilters();
    if (options.length)
      this.setState({
        searchableOptions: options.map(({ name, _id }) => ({ value: _id, label: name })),
      });
  }

  setFilters = () => {
    this.setState({
      selected: this.props.selected,
    });
  };

  dropdownToggle = () => {
    this.setState(prevState => ({ open: !prevState.open }));
  };

  selectOptions = option => {
    const { selected } = this.state;
    const { isSearchable } = this.props;
    let optionsSelected = selected;

    if (isSearchable) {
      if (option) {
        optionsSelected = { name: option.label, _id: option.value };
      } else {
        optionsSelected = {};
      }
    } else {
      if (option._id === selected._id) {
        optionsSelected = {};
      } else {
        optionsSelected = option;
      }
    }
    return optionsSelected;
  };

  optionSelected = option => {
    const selected = this.selectOptions(option);

    this.setState({ selected });
    this.props.filterOptionsSelected(selected);
    this.props.filterFunction(selected);
  };

  renderItem = item => (this.props.children ? this.props.children(item) : humanize(item));

  clearCurrentInput = e => {
    const { filterOptionsSelected, filterFunction } = this.props;
    const selected = this.selectOptions({});
    this.setState({ selected });
    filterFunction && filterFunction(selected);
    filterOptionsSelected && filterOptionsSelected(selected);
    e.stopPropagation();
  };

  prepareLabel = selected => {
    const isOnPhone = getBrowserWidth() < isPhone;

    if (selected.length > 27 && isOnPhone) {
      return `${selected.slice(0, 24)}...`;
    } else if (selected.length > 37) {
      return `${selected.slice(0, 34)}...`;
    }

    return selected;
  };

  renderSelectedLabel = selected => (
    <>
      {this.renderItem(this.prepareLabel(selected.name))}
      {selected.length > 1 ? `, and ${selected.length - 1} more` : ''}
      <Icon
        icon="icon-cross"
        width={10}
        height={10}
        onClick={e => this.clearCurrentInput(e)}
        size="contain"
      />
    </>
  );

  handleClick = item => {
    this.optionSelected(item);
    this.dropdownToggle();
  };

  renderDropdownItems = (options, selected) =>
    options &&
    options.map(item => (
      <React.Fragment key={`${item._id}`}>
        <StyledFiltersDropdownItem
          toggle={false}
          id={`${item}-SelectItem-$`}
          selected={item._id === selected._id}
          onClick={() => this.handleClick(item)}
        >
          <DropdownMenuItemText>{this.renderItem(item.name)}</DropdownMenuItemText>
        </StyledFiltersDropdownItem>
        <DropdownMenuOptionsDivider />
      </React.Fragment>
    ));

  render() {
    const { options, placeholder, disabled, isSearchable } = this.props;
    const { open, selected, searchableOptions } = this.state;
    const selectValue = selected._id ? { label: selected.name, value: selected._id } : null;

    return isSearchable ? (
      <Select
        onChange={this.optionSelected}
        components={{ MenuList, DropdownIndicator }}
        styles={styles}
        isClearable
        isDisabled={disabled}
        options={searchableOptions}
        value={selectValue}
        noOptionsMessage={() => FIELD_SELECT.NO_OPTION_MESSAGE}
      />
    ) : (
      <StyledFiltersDropdown
        isOpen={open}
        toggle={this.dropdownToggle}
        className="SelectDropdown"
        id="locationSelect"
      >
        <StyledFiltersDropdownToggle id="dropdownSelect" disabled={disabled}>
          <StyledFiltersDropdownInput isSelected={Object.keys(selected).length}>
            {Object.keys(selected).length ? this.renderSelectedLabel(selected) : placeholder}
          </StyledFiltersDropdownInput>
          <DropdownIcon dropdownOpen={open} />
        </StyledFiltersDropdownToggle>
        <MenuDropdown
          id="dropdownMenu"
          renderDropdownItems={this.renderDropdownItems(options, selected)}
        />
      </StyledFiltersDropdown>
    );
  }
}

const LocationsDropdown = withFieldWrapper(LocationsDropdownBase);

export { LocationsDropdown };
