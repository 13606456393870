import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { createAgreementDocument } from 'actions/Agreements/createDocument';
import { showSnackbar } from 'actions/shared/snackbar';

import { AGREEMENTS } from 'shared/helpers/constants/agreements/agreementsConstants';
import {
  successOptions,
  dangerOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

function AgreementCreateDocument(props) {
  const resolveAction = agreement => {
    if (agreement) {
      props.showSnackbar(successOptions, AGREEMENTS.CREATE_DOCUMENT_SUCCESS);
    } else {
      props.showSnackbar(dangerOptions, AGREEMENTS.CREATE_DOCUMENT_FAILURE);
    }
  };

  const onClick = () => {
    props.createAgreementDocument(props.agreement).then(resolveAction);
  };

  return <React.Fragment>{props.children({ onClick })}</React.Fragment>;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createAgreementDocument,
      showSnackbar,
    },
    dispatch,
  );
}

const ConnectedAgreementCreateDocument = connect(null, mapDispatchToProps)(AgreementCreateDocument);

export { ConnectedAgreementCreateDocument as AgreementCreateDocument };
