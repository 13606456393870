import React, { Component } from 'react';
import Breadcrumbs from 'react-router-dynamic-breadcrumbs';

import { BreadcrumbItem, BreadcrumbWrapper } from 'shared/components/Breadcrumbs';
import { isBuyerUser } from 'shared/helpers/matchers/checkUserType';

import { agreementsBreadcrumbsRoutes } from 'pages/Agreements/config/breadcrumbsRoutes';
import { companiesBreadcrumbsRoutes } from 'pages/Companies/config/breadcrumbsRoutes';
import { hedgesBreadcrumbsRoutes } from 'pages/Hedges/config/breadcrumbsRoutes';
import { locationsBreadcrumbsRoutes } from 'pages/Locations/config/breadcrumbsRoutes';
import { lotsBreadcrumbsRoutes } from 'pages/Lots/config/breadcrumbsRoutes';
import { notificationsBreadcrumbsRoutes } from 'pages/Notifications/config/breadcrumbsRoutes';
import { paymentsBreadcrumbsRoutes } from 'pages/Payments/config/breadcrumbsRoutes';
import { poolAccountsBreadcrumbsRoutes } from 'pages/PoolAccounts/config/breadcrumbsRoutes';
import { reportsBreadcrumbsRoutes } from 'pages/Reports/config/breadcrumbsRoutes';
import { systemSettingsBreadcrumbsRoutes } from 'pages/Settings/config/breadcrumbsRoutes';
import { shipmentsBreadcrumbsRoutes } from 'pages/Shipments/config/breadcrumbsRoutes';
import { termsBreadcrumbsRoutes } from 'pages/Terms/config/breadcrumbsRoutes';
import { usersBreadcrumbsRoutes } from 'pages/Users/config/breadcrumbsRoutes';

const routes = {
  ...usersBreadcrumbsRoutes,
  ...lotsBreadcrumbsRoutes,
  ...companiesBreadcrumbsRoutes,
  ...paymentsBreadcrumbsRoutes,
  ...shipmentsBreadcrumbsRoutes,
  ...termsBreadcrumbsRoutes,
  ...agreementsBreadcrumbsRoutes,
  ...locationsBreadcrumbsRoutes,
  ...notificationsBreadcrumbsRoutes,
  ...hedgesBreadcrumbsRoutes,
  ...poolAccountsBreadcrumbsRoutes,
  ...systemSettingsBreadcrumbsRoutes,
  ...reportsBreadcrumbsRoutes,
};

class BreadcrumbsNav extends Component {
  breadcrumbsRef = React.createRef();

  componentDidMount() {
    this.scrollToCurrentRoute();
  }

  componentDidUpdate() {
    this.scrollToCurrentRoute();
  }

  scrollToCurrentRoute() {
    this.breadcrumbsRef.current &&
      this.breadcrumbsRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  render() {
    const {
      notificationDetails: { notification },
      auth: { user },
    } = this.props;

    return (
      <>
        {!notification && (
          <Breadcrumbs
            mappedRoutes={{ ...routes, ...(isBuyerUser(user.userType) && { '/lots': null }) }}
            routeMatcherRegex="([\w-\.]+)"
            WrapperComponent={({ children }) => (
              <BreadcrumbWrapper content={children} reference={this.breadcrumbsRef} />
            )}
            LinkComponent={({ children }) => <BreadcrumbItem content={children} />}
            ActiveLinkComponent={({ children }) => <BreadcrumbItem content={children} />}
          />
        )}
      </>
    );
  }
}

export { BreadcrumbsNav as Breadcrumbs };
