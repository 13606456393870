import React from 'react';
import { connect } from 'react-redux';

import { getSignedTerms } from 'actions/Terms/getSignedTerms';
import { getTerms, clearTermsDetails } from 'actions/Terms/termsDetails';
import { updateTerms } from 'actions/Terms/updateTerms';
import { openModal, closeModal } from 'actions/shared/modal';

import { termTypes } from 'shared/helpers/constants/filters/termTypes';
import { createTermConfig } from 'shared/helpers/constants/terms/createTermConfig';

import { ConnectedTermChargedForm } from '../TermChargedForm/TermChargedForm';
import { ConnectedTermForm } from '../TermForm/TermForm';
import { UpdateTerm } from './UpdateTerm';

function ConnectedUpdateTerm(props) {
  return (
    <UpdateTerm {...props}>
      {(materialType, formProps) =>
        [termTypes.ceramic, termTypes.diesel].includes(materialType) ? (
          <ConnectedTermForm {...formProps} />
        ) : (
          <ConnectedTermChargedForm
            resources={createTermConfig[materialType].resources}
            {...formProps}
          />
        )
      }
    </UpdateTerm>
  );
}

function mapStateToProps({ auth, router, modal, termsDetails, signedTerms }) {
  return {
    auth,
    router,
    modal,
    termsDetails,
    signedTerms,
  };
}

const mapDispatchToProps = {
  getTerms,
  clearTermsDetails,
  updateTerms,
  openModal,
  closeModal,
  getSignedTerms,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConnectedUpdateTerm);
