import {
  requestMarketIndicatorsType,
  requestMarketIndicatorsSuccessType,
  requestMarketIndicatorsFailureType,
} from 'actions/MarketIndicators/requestMarketIndicators';

const INITIAL_STATE = {
  isPending: false,
};

const requestMarketIndicatorsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case requestMarketIndicatorsType:
      return {
        isPending: true,
      };
    case requestMarketIndicatorsSuccessType:
    case requestMarketIndicatorsFailureType:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};

export { requestMarketIndicatorsReducer };
