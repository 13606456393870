import { getPriceFeedReducer } from './getPriceFeed';
import { getPriceFeedsReducer } from './getPriceFeeds';
import { updatePriceFeedReducer } from './updatePriceFeed';

const priceFeedsRootReducer = {
  getPriceFeed: getPriceFeedReducer,
  getPriceFeeds: getPriceFeedsReducer,
  updatePriceFeed: updatePriceFeedReducer,
};

export { priceFeedsRootReducer };
