import {
  clearHedgesActionType,
  getHedgesActionType,
  getHedgesFailureActionType,
  getHedgesSuccessActionType,
} from 'actions/Hedges/hedgesList';

const INITIAL_STATE = {
  isPending: false,
  hedges: {
    docs: [],
    limit: null,
    page: null,
    pages: null,
    total: null,
  },
  filters: { status: [], query: '' },
  pagination: { limit: 50, page: 1 },
};

const hedgesListReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getHedgesActionType:
      return {
        ...state,
        filters: action.payload.filters,
        pagination: action.payload.pagination,
        isPending: true,
      };
    case getHedgesSuccessActionType:
      return {
        ...state,
        isPending: false,
        hedges: {
          ...action.payload.hedges,
        },
      };
    case getHedgesFailureActionType:
      return {
        ...state,
        isPending: false,
      };
    case clearHedgesActionType:
      return {
        ...INITIAL_STATE,
        filters: state.filters,
      };
    default:
      return state;
  }
};

export { hedgesListReducer };
