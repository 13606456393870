import React from 'react';

import { StatusIndicator } from 'shared/components/StatusIndicator/StatusIndicator';
import { parseOpenStatus } from 'shared/helpers/dataParsers/hedges/parseOpenStatus';
import { isTooltipVisible } from 'shared/helpers/matchers/isTooltipVisible';
import { formatDate, formatToDollarWithPrefix, formatToUS } from 'shared/helpers/parsers/formaters';
import { humanize, replaceEmpty } from 'shared/helpers/parsers/text';

import { history } from 'utils/history';

import { HEDGES } from './hedgesConstants';
import { hedgePricesTemplate, troyOuncesTemplate } from './hedgesListTableConfig';

const itemsTemplateBase = [
  {
    label: HEDGES.STATUS,
    render: ({ status, requestedHedgeDate, _id }) => {
      const currentStatus = parseOpenStatus({ status, requestedHedgeDate });

      return (
        <StatusIndicator
          status={currentStatus}
          tooltipContent={isTooltipVisible(requestedHedgeDate, currentStatus)}
          id={_id}
        />
      );
    },
  },
  {
    label: HEDGES.HEDGE_NAME,
    render: ({ hedgeName }) => hedgeName,
  },
  {
    label: HEDGES.HEDGE_TYPE,
    render: ({ hedgeType }) => humanize(hedgeType),
  },
  troyOuncesTemplate,
  hedgePricesTemplate,
  {
    label: HEDGES.DELIVERY_DATE,
    render: ({ deliveryDate }) => formatDate(deliveryDate),
  },
];

const itemsTemplateMobile = [
  {
    label: HEDGES.STATUS,
    render: ({ status, requestedHedgeDate, _id }) => {
      const currentStatus = parseOpenStatus({ status, requestedHedgeDate });

      return (
        <StatusIndicator
          status={currentStatus}
          tooltipContent={isTooltipVisible(requestedHedgeDate, currentStatus)}
          id={_id}
        />
      );
    },
  },
  {
    label: HEDGES.HEDGE_NAME,
    render: ({ hedgeName }) => hedgeName,
  },
];

const itemsTemplateInternalCustomer = [
  ...itemsTemplateMobile,
  troyOuncesTemplate,
  {
    label: HEDGES.HEDGE_PRICES_CUSTOMER,
    render: ({ platinumPriceCust, palladiumPriceCust, rhodiumPriceCust }) =>
      platinumPriceCust !== null || palladiumPriceCust !== null || rhodiumPriceCust !== null ? (
        <>
          <span data-testid="troyOuncesHedgedPrices">
            {formatToDollarWithPrefix(platinumPriceCust)}
            <br />
            {formatToDollarWithPrefix(palladiumPriceCust)}
            <br />
            {formatToDollarWithPrefix(rhodiumPriceCust)}
          </span>
        </>
      ) : (
        '-'
      ),
  },
  {
    label: HEDGES.DELIVERY_DATE,
    render: ({ deliveryDate }) => formatDate(deliveryDate),
  },
];

const itemsTemplateCustomer = [
  ...itemsTemplateMobile,
  troyOuncesTemplate,
  hedgePricesTemplate,
  {
    label: HEDGES.EST_UNITS_WEIGHT,
    render: ({ materialUnits, materialWeight }) =>
      materialUnits
        ? `${formatToUS(materialUnits, 0)} ${HEDGES.UNITS}`
        : `${replaceEmpty(formatToUS(materialWeight))} ${HEDGES.LBS}`,
  },
  {
    label: HEDGES.DELIVERY_DATE,
    render: ({ deliveryDate }) => formatDate(deliveryDate),
  },
];

const itemsTemplateBuyer = [
  ...itemsTemplateMobile,
  {
    label: HEDGES.HEDGE_TYPE,
    render: ({ hedgeType }) => humanize(hedgeType),
  },
  troyOuncesTemplate,
  hedgePricesTemplate,
  {
    label: HEDGES.DELIVERY_DATE,
    render: ({ deliveryDate }) => formatDate(deliveryDate),
  },
  {
    label: HEDGES.PLACED_ON,
    render: ({ placedAt }) => replaceEmpty(formatDate(placedAt)),
  },
];

const itemsTemplateGlobal = [
  ...itemsTemplateBase,
  {
    label: HEDGES.PLACED_ON,
    render: ({ placedAt }) => replaceEmpty(formatDate(placedAt)),
  },
];

const itemsDetailsTemplateBase = [
  {
    label: HEDGES.HEDGE_TYPE,
    render: ({ hedgeType }) => humanize(hedgeType),
  },
  troyOuncesTemplate,
  hedgePricesTemplate,
  {
    label: HEDGES.DELIVERY_DATE_CUSTOMER,
    render: ({ deliveryDate }) => formatDate(deliveryDate),
  },
];

const itemsDetailsTemplateGlobal = [
  ...itemsDetailsTemplateBase,
  {
    label: HEDGES.PLACED_ON,
    render: ({ placedAt }) => replaceEmpty(formatDate(placedAt)),
  },
];

const actionsTemplateGlobal = [
  {
    icon: 'arrow-right',
    action: 'Details',
    onClick: hedge => () => history.push(`/hedges/${hedge._id}`),
  },
];

const actionsTemplateCustomer = (downloadAttachment, hedgesOverviewList) => {
  const actionsTemplate = [
    {
      icon: 'clip icon-clip',
      action: 'Download',
      access: hedge => hedge.hedgeOrderDocument,
      onClick: hedge => () => downloadAttachment(hedge.hedgeOrderDocument.path),
    },
  ];

  actionsTemplate.access = function access() {
    return hedgesOverviewList.items.docs.some(e => e.hedgeOrderDocument);
  };

  return actionsTemplate;
};

export {
  itemsTemplateCustomer,
  itemsTemplateGlobal,
  itemsTemplateBase,
  itemsTemplateMobile,
  itemsDetailsTemplateGlobal,
  itemsDetailsTemplateBase,
  actionsTemplateGlobal,
  actionsTemplateCustomer,
  itemsTemplateInternalCustomer,
  itemsTemplateBuyer,
};
