import styled from 'styled-components';

import { FullPageList } from 'shared/components/FullPageList/FullPageList';
import { media } from 'shared/helpers/styling/styling';

export const StyledPaymentList = styled(FullPageList)`
  .PaymentsList {
    &__item--no-break {
      white-space: nowrap;
    }

    &-actionButtons {
      display: flex;

      ${media.mobile`
        width: 100%;
        flex-direction: column;
  
        & > * {
          margin: 10px 0 0 0;
        }
      }
  
      & > * {
        margin-right: 10px;
      `}
    }
  }
`;
