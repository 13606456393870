import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Field } from 'redux-form';

import { IconButton } from 'shared/components/Buttons/IconButton/IconButton';
import {
  FieldInput,
  FieldSelect,
  FieldHint,
  FieldDate,
  FieldSearch,
  FieldConfirmation,
} from 'shared/components/Fields';
import { FieldButtonSelect } from 'shared/components/Fields/FieldButtonSelect/FieldButtonSelect';
import { FieldTime } from 'shared/components/Fields/FieldTime/FieldTime';
import { Section } from 'shared/components/Form';
import { FieldWithButton } from 'shared/components/Form/FieldWithButton/FieldWithButton';
import { MultipleFieldsSection } from 'shared/components/Form/MultipleFieldsSection/MultipleFieldsSection';
import { requestHedgeDateAccess } from 'shared/helpers/accesses/hedges';
import { companyTypesEnum } from 'shared/helpers/constants/filters/companyTypes';
import { ENTER_PRICES } from 'shared/helpers/constants/hedges/enterPricesConstants';
import {
  hedgeTypes,
  trustedCompanyTypes,
  untrustedCompanyTypes,
} from 'shared/helpers/constants/hedges/hedgeType';
import { HEDGES } from 'shared/helpers/constants/hedges/hedgesConstants';
import {
  materialTypes,
  materialTypesForSuggestions,
  allowedMaterialTypesForCustomer,
} from 'shared/helpers/constants/hedges/materialType';
import { statusesForCustomer } from 'shared/helpers/constants/lots/statusesForCustomer';
import { statusesForGlobal } from 'shared/helpers/constants/lots/statusesForGlobal';
import { statusesForGrading } from 'shared/helpers/constants/lots/statusesForGrading';
import {
  denominationTable,
  denominatedIn,
} from 'shared/helpers/constants/materials/denominationTable';
import { materialTypeForAssay } from 'shared/helpers/constants/packages/materialType';
import { PACKAGES } from 'shared/helpers/constants/packages/packageConstants';
import { SHARED } from 'shared/helpers/constants/sharedConstants';
import { SHIPMENTS } from 'shared/helpers/constants/shipments/shipmentsConstants';
import { infoOptions } from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { isGradingCompany } from 'shared/helpers/matchers/checkCompanyType';
import {
  isBuyerUser,
  isCustomerUser,
  isOwnerOrSupport,
} from 'shared/helpers/matchers/checkUserType';
import { isGlobalRefiningGroup } from 'shared/helpers/matchers/isGlobalRefiningGroup';
import { isGlobalUserType } from 'shared/helpers/matchers/isGlobalUserType';
import { isNumber } from 'shared/helpers/parsers/formaters';
import { safeParseFloat } from 'shared/helpers/parsers/number';
import { mapArray } from 'shared/helpers/parsers/select';
import { safeReplace, humanize } from 'shared/helpers/parsers/text';
import { cssVar } from 'shared/helpers/styling/styling';

import dateAdapter from 'utils/date/dateAdapter';
import { MomentAdapter } from 'utils/date/momentAdapter';
import { debounce } from 'utils/debounce';

import { priceType } from 'pages/Hedges/components/EnterPrices/EnterPricesForm/EnterPricesForm';

import { ExistingHedges } from '../ExistingHedges/ExistingHedges';
import { createHedgeDefaultMaterialType } from './createHedgeDefaultMaterialType/createHedgeDefaultMaterialType';

const HEDGE_DATE_NOTIFICATIONS_OFFSET =
  process.env.REACT_APP_REQUESTED_HEDGE_DATE_NOTIFICATIONS_OFFSET;

class HedgeForm extends Component {
  globalReceivedStatuses = [
    statusesForGlobal.received,
    statusesForGlobal.ready_for_processing,
    statusesForGlobal.accepted_for_processing,
    statusesForGlobal.ready_for_mixing,
    statusesForGlobal.accepted_for_mixing,
    statusesForGlobal.ready_to_ship,
  ];

  globalShippedStatuses = [
    statusesForGlobal.shipped_from_global,
    statusesForGlobal.assay_results_received,
    statusesForGlobal.ship_summary_accepted,
  ];

  globalDeliveryDateStatuses = [
    statusesForGlobal.not_received,
    statusesForGlobal.partly_received,
    ...this.globalReceivedStatuses,
    statusesForGlobal.ship_summary_generated,
    ...this.globalShippedStatuses,
  ];

  customerReceivedStatuses = [
    statusesForCustomer.received,
    statusesForCustomer.summary_available,
    statusesForGrading.receipt_ready,
    statusesForGrading.receipt_accepted,
  ];

  customerDeliveryDateStatuses = [statusesForCustomer.open, ...this.customerReceivedStatuses];

  state = {
    disableDeliveryDate: false,
    disableDeliveryDateGRG: false,
    isTyping: '',
  };

  componentWillUnmount() {
    const { change, hedge } = this.props;

    change(`${hedge}.hedgeOptions`);
  }

  selectHedgeOption() {
    const { change, value, marketAvailabilityDetails, hedge, sharedData } = this.props;

    if (marketAvailabilityDetails?.inMarketHours !== undefined && !value?.hedgeOptions) {
      if (
        marketAvailabilityDetails?.isBusinessDay &&
        marketAvailabilityDetails?.inMarketHours &&
        !marketAvailabilityDetails?.marketHoliday &&
        sharedData.hedgeOptions?.[hedge] !== 'schedule'
      ) {
        change(`${hedge}.hedgeOptions`, {
          label: HEDGES.REQUEST_NOW,
          value: 'requestNow',
        });
      } else {
        change(`${hedge}.hedgeOptions`, {
          label: HEDGES.SCHEDULE,
          value: 'schedule',
        });
      }
    }
  }

  async componentDidMount() {
    const {
      auth: {
        user: { relatedCompany, userType },
      },
      change,
      hedge,
      getLotsForHedges,
      getLotsToEnterPlacedHedges,
      value,
      getSuggestedDeliveryDate,
      location,
      enterPlacedHedges,
    } = this.props;

    if ((!enterPlacedHedges && isCustomerUser(userType)) || isBuyerUser(userType)) {
      getLotsForHedges(relatedCompany._id);
      change(`${hedge}.relatedCompany`, {
        value: relatedCompany._id,
        label: relatedCompany.companyName,
        lotPrefix: relatedCompany.lotPrefix,
        materialCountRequired: relatedCompany.materialCountRequired,
        companyType: relatedCompany.companyType,
      });
    }

    this.selectHedgeOption();

    if (location.state?.relatedLot) {
      const {
        value: lotId,
        relatedCompany: lotCompany,
        label: lotLabel,
        receivedAt,
        statusForCustomer,
        statusForGlobal,
      } = location.state.relatedLot;

      const deliveryDate = (() => {
        if (
          this.customerReceivedStatuses.includes(statusForCustomer) &&
          !isGlobalRefiningGroup(lotCompany.companyName)
        ) {
          return receivedAt;
        }
        return null;
      })();

      const deliveryDateGRG = (() => {
        if (this.globalShippedStatuses.includes(statusForGlobal)) {
          return receivedAt;
        }
        return null;
      })();

      change(`${hedge}.relatedLot`, {
        value: lotId,
        label: lotLabel,
        grgLotNumber: lotLabel,
        statusForCustomer,
        statusForGlobal,
        receivedAt,
      });

      change(`${hedge}.relatedCompany`, {
        value: lotCompany._id,
        label: lotCompany.companyName,
        lotPrefix: relatedCompany.lotPrefix,
        materialCountRequired: lotCompany.materialCountRequired,
        companyType: lotCompany.companyType,
      });

      this.props.getLotsForHedges(lotCompany._id);
      this.props
        .getSuggestedDeliveryDate(lotCompany._id, lotId)
        .then(({ suggestedDeliveryDate, suggestedDeliveryDateGRG }) => {
          if (deliveryDate || deliveryDateGRG) {
            this.setDeliveryDate(deliveryDate, deliveryDateGRG);
          } else {
            this.setDeliveryDate(suggestedDeliveryDate, suggestedDeliveryDateGRG);
          }
          this.setDeliveryDateDisabled(suggestedDeliveryDate, suggestedDeliveryDateGRG);
        });

      await this.setDefaultMaterialType(lotId);
    }

    if (location.state?.hedge && value.relatedCompany) {
      getSuggestedDeliveryDate(value.relatedCompany.value, location.state.id);

      if (!enterPlacedHedges) {
        getLotsForHedges(value.relatedCompany.value);
      } else {
        getLotsToEnterPlacedHedges(value.relatedCompany.value);
      }
    }
  }

  componentDidUpdate() {
    const {
      value: { platinumOz, palladiumOz, rhodiumOz },
      enterPlacedHedges,
      resetSection,
      hedge,
    } = this.props;

    if (!this.suggestedValuesAllowed()) {
      this.resetSuggestions();
    }

    if (enterPlacedHedges) {
      if (+platinumOz === 0) {
        resetSection(`${hedge}.platinumPrice${priceType.actual}`);
        resetSection(`${hedge}.platinumPrice${priceType.custom}`);
        resetSection(`${hedge}.platinumPrice${priceType.actual}Confirm`);
        resetSection(`${hedge}.platinumPrice${priceType.custom}Confirm`);
      }

      if (+palladiumOz === 0) {
        resetSection(`${hedge}.palladiumPrice${priceType.actual}`);
        resetSection(`${hedge}.palladiumPrice${priceType.custom}`);
        resetSection(`${hedge}.palladiumPrice${priceType.actual}Confirm`);
        resetSection(`${hedge}.palladiumPrice${priceType.custom}Confirm`);
      }

      if (+rhodiumOz === 0) {
        resetSection(`${hedge}.rhodiumPrice${priceType.actual}`);
        resetSection(`${hedge}.rhodiumPrice${priceType.custom}`);
        resetSection(`${hedge}.rhodiumPrice${priceType.actual}Confirm`);
        resetSection(`${hedge}.rhodiumPrice${priceType.custom}Confirm`);
      }
    }
  }

  get isTrustedCompany() {
    const {
      auth: {
        user: {
          relatedCompany: { trustedCompany },
          userType,
        },
      },
    } = this.props;

    return trustedCompany || !isCustomerUser(userType);
  }

  get hedgeTypes() {
    if (!this.isTrustedCompany) return untrustedCompanyTypes;

    return Object.values(this.requestForOwnCompany ? trustedCompanyTypes : hedgeTypes);
  }

  get materialTypes() {
    return Object.values(materialTypes);
  }

  get availableLots() {
    const {
      value: { relatedCompany },
      hedgesManagement,
    } = this.props;

    return (hedgesManagement.lots[relatedCompany && relatedCompany.value] || []).map(lot => ({
      value: lot._id,
      label: lot.grgLotNumber,
      statusForCustomer: lot.statusForCustomer,
      statusForGlobal: lot.statusForGlobal,
      grgLotNumber: lot.grgLotNumber,
      receivedAt: lot.receivedAt,
    }));
  }

  get requestForOwnCompany() {
    const {
      auth,
      value: { relatedCompany },
    } = this.props;

    return relatedCompany && relatedCompany.value === auth.user.relatedCompany._id;
  }

  setDeliveryDateDisabled = (deliveryDate, deliveryDateGRG) => {
    const {
      value: { relatedLot, hedgeType },
    } = this.props;

    const checkDeliveryDate = deliveryDate && deliveryDateGRG;

    if (!!checkDeliveryDate && hedgeType !== hedgeTypes.blanket) {
      this.setState({
        disableDeliveryDate: true,
        disableDeliveryDateGRG: true,
      });
    } else if (relatedLot) {
      this.setState({
        disableDeliveryDate:
          relatedLot.receivedAt !== null &&
          this.customerReceivedStatuses.includes(relatedLot.statusForCustomer),
        disableDeliveryDateGRG:
          relatedLot.receivedAt !== null &&
          this.globalShippedStatuses.includes(relatedLot.statusForGlobal),
      });
    } else {
      this.setState({
        disableDeliveryDate: false,
        disableDeliveryDateGRG: false,
      });
    }
  };

  getFormatNumber(value) {
    const digits = this.isTrustedCompany ? 3 : 1;

    return value && Number(value).toFixed(digits);
  }

  changeMaterialTypeWarning = material => {
    const { hedge, sharedData } = this.props;

    if (
      sharedData?.defaultMaterialType?.hasOwnProperty(hedge) &&
      sharedData.defaultMaterialType[hedge] &&
      sharedData.defaultMaterialType[hedge] !== material
    ) {
      const message = PACKAGES.DOUBLE_CHECK_MATERIAL_TYPE(sharedData.defaultMaterialType[hedge]);

      this.props.showSnackbar(infoOptions, message);
    }
  };

  hedgeChange = material => {
    const { hedge, resetSection } = this.props;

    resetSection(`${hedge}.materialUnits`);
    resetSection(`${hedge}.materialWeight`);
    resetSection(`${hedge}.platinumOz`);
    resetSection(`${hedge}.palladiumOz`);
    resetSection(`${hedge}.rhodiumOz`);
    this.resetSuggestions();

    this.setState({
      isTyping: '',
    });

    material && this.changeMaterialTypeWarning(material?.value);
  };

  resetCustomPrices = () => {
    const { hedge, resetSection } = this.props;

    resetSection(`${hedge}.platinumPrice${priceType.custom}`);
    resetSection(`${hedge}.palladiumPrice${priceType.custom}`);
    resetSection(`${hedge}.rhodiumPrice${priceType.custom}`);
    resetSection(`${hedge}.platinumPrice${priceType.custom}Confirm`);
    resetSection(`${hedge}.palladiumPrice${priceType.custom}Confirm`);
    resetSection(`${hedge}.rhodiumPrice${priceType.custom}Confirm`);
  };

  suggestedValuesAllowed = () => {
    const {
      value: { relatedLot, hedgeType },
      enterPlacedHedges,
    } = this.props;

    return !enterPlacedHedges && (hedgeType === hedgeTypes.blanket || !!relatedLot);
  };

  resetSuggestions = () => {
    const { hedge, resetSection } = this.props;
    resetSection(`${hedge}.suggestedPlatinumOz`);
    resetSection(`${hedge}.suggestedPalladiumOz`);
    resetSection(`${hedge}.suggestedRhodiumOz`);
    resetSection(`${hedge}.maximumPlatinumOz`);
    resetSection(`${hedge}.maximumPalladiumOz`);
    resetSection(`${hedge}.maximumRhodiumOz`);
  };

  getHedgesSuggestions = hedgeType => {
    const {
      auth: {
        user: { userType },
      },
      getHedgesSuggestedValues,
      hedge,
      change,
      value: { relatedLot, relatedCompany, materialType, materialWeight, materialUnits },
    } = this.props;
    if (
      relatedCompany?.value &&
      (isOwnerOrSupport(userType) || materialTypesForSuggestions.includes(materialType)) &&
      (materialWeight || materialUnits)
    ) {
      getHedgesSuggestedValues({
        relatedCompany,
        materialType,
        materialWeight: +safeReplace(materialWeight, ',', '.'),
        materialUnits: +safeReplace(materialUnits, ',', '.'),
        lotId: relatedLot?.value,
        hedgeType: hedgeType || this.props.value.hedgeType,
      }).then(s => {
        change(`${hedge}.suggestedPlatinumOz`, this.getFormatNumber(s.avgSuggestedPlatinumOz));
        change(`${hedge}.suggestedPalladiumOz`, this.getFormatNumber(s.avgSuggestedPalladiumOz));
        change(`${hedge}.suggestedRhodiumOz`, this.getFormatNumber(s.avgSuggestedRhodiumOz));
        change(`${hedge}.maximumPlatinumOz`, this.getFormatNumber(s.maximumPlatinumOz));
        change(`${hedge}.maximumPalladiumOz`, this.getFormatNumber(s.maximumPalladiumOz));
        change(`${hedge}.maximumRhodiumOz`, this.getFormatNumber(s.maximumRhodiumOz));

        if (isBuyerUser(userType)) {
          change(
            `${hedge}.platinumOz`,
            this.calculateOuncesForBuyerUser(s.avgSuggestedPlatinumOz, s.maximumPlatinumOz),
          );
          change(
            `${hedge}.palladiumOz`,
            this.calculateOuncesForBuyerUser(s.avgSuggestedPalladiumOz, s.maximumPalladiumOz),
          );
          change(
            `${hedge}.rhodiumOz`,
            this.calculateOuncesForBuyerUser(s.avgSuggestedRhodiumOz, s.maximumRhodiumOz),
          );
        }
      });
    } else {
      this.resetSuggestions();
    }
  };

  calculateOuncesForBuyerUser = (suggested, maximum) => {
    const suggestions = this.getFormatNumber(suggested || maximum || 0);
    return suggestions < 1 ? 0 : +suggestions;
  };

  hedgeChangeUnits = ({ target: { value, name } }) => {
    const {
      auth: {
        user: { userType },
      },
      value: { materialType },
    } = this.props;
    const inputName = name.split('.')[1];

    if (materialType === materialTypes.foil_canned) {
      this.setState({
        isTyping: !value.length ? '' : inputName,
      });
    }

    if (
      (isOwnerOrSupport(userType) || materialTypesForSuggestions.includes(materialType)) &&
      this.suggestedValuesAllowed() &&
      value
    ) {
      this.props.getHedgesSuggestedValuesAction();
      this.hedgeChangeUnitsAction();
    } else {
      this.resetSuggestions();
    }
  };

  hedgeChangeUnitsAction = debounce(() => this.getHedgesSuggestions(), 200);

  relatedCompanyChange = (company, val, prev, name) => {
    const {
      getLotsToEnterPlacedHedges,
      getLotsForHedges,
      resetSection,
      hedge,
      enterPlacedHedges,
      change,
    } = this.props;

    resetSection(`${hedge}.relatedLot`);
    resetSection(`${hedge}.deliveryDate`);
    resetSection(`${hedge}.deliveryDateGRG`);
    change(`${hedge}.hedgeType`, {
      label: humanize(hedgeTypes.standard),
      value: hedgeTypes.standard,
    });

    this.resetCustomPrices();

    if (val) {
      this.hedgeChange(null, val, prev, name);

      if (enterPlacedHedges) {
        getLotsToEnterPlacedHedges(val.value);
      } else {
        getLotsForHedges(val.value);
      }

      this.selectHedgeOption();
    }
  };

  hedgeTypeChange = hedgeType => {
    const { hedge, resetSection, enterPlacedHedges, saveSharedData, sharedData } = this.props;

    if (enterPlacedHedges) {
      this.resetCustomPrices();
    }

    this.getHedgesSuggestions(hedgeType?.value);

    if (hedgeType && hedgeType.value === hedgeTypes.blanket) {
      resetSection(`${hedge}.relatedLot`);
      resetSection(`${hedge}.deliveryDate`);
      resetSection(`${hedge}.deliveryDateGRG`);

      this.setState({
        disableDeliveryDate: false,
        disableDeliveryDateGRG: false,
      });

      saveSharedData({
        defaultMaterialType: {
          ...sharedData.defaultMaterialType,
          [hedge]: false,
        },
      });
    }
  };

  setDefaultMaterialType = async lotId => {
    const { getPackagesOverviewForLots, change, hedge, saveSharedData, sharedData } = this.props;

    await getPackagesOverviewForLots(lotId, 0, SHIPMENTS.SHIPMENT_TYPES.INCOMING).then(
      ({ payload: { packages } }) => {
        const defaultMaterialType = createHedgeDefaultMaterialType({
          packages: packages.docs || [],
        });

        defaultMaterialType
          ? change(`${hedge}.materialType`, ...mapArray([defaultMaterialType]))
          : change(`${hedge}.materialType`, ...mapArray([materialTypeForAssay.mono_canned]));

        saveSharedData({
          defaultMaterialType: {
            ...sharedData.defaultMaterialType,
            [hedge]: defaultMaterialType,
          },
        });
      },
    );
  };

  relatedLotChange = async (lot, val, prev, name) => {
    const { getSuggestedDeliveryDate, clearSuggestedDeliveryDate, hedge, resetSection } =
      this.props;
    const { relatedCompany } = {
      ...this.props.value,
      [name.replace(`${hedge}.`, '')]: val,
    };

    const deliveryDate = (() => {
      if (
        lot &&
        this.customerReceivedStatuses.includes(lot.statusForCustomer) &&
        !isGlobalRefiningGroup(relatedCompany.label)
      ) {
        return lot.receivedAt;
      }
      return null;
    })();

    const deliveryDateGRG = (() => {
      if (lot && this.globalShippedStatuses.includes(lot.statusForGlobal)) {
        return lot.receivedAt;
      }
      return null;
    })();

    if (lot) {
      getSuggestedDeliveryDate(relatedCompany.value, lot.value).then(
        ({ suggestedDeliveryDate, suggestedDeliveryDateGRG }) => {
          if (deliveryDate || deliveryDateGRG) {
            this.setDeliveryDate(deliveryDate, deliveryDateGRG);
          } else {
            this.setDeliveryDate(suggestedDeliveryDate, suggestedDeliveryDateGRG);
          }

          this.setDeliveryDateDisabled(suggestedDeliveryDate, suggestedDeliveryDateGRG);
        },
      );
      await this.setDefaultMaterialType(lot.value);
      this.hedgeChange();
    } else {
      resetSection(`${hedge}.deliveryDate`);
      resetSection(`${hedge}.deliveryDateGRG`);
      clearSuggestedDeliveryDate();
    }
  };

  setDeliveryDate = (deliveryDate, deliveryDateGRG) => {
    const { hedge, change, resetSection, value } = this.props;

    change(`${hedge}.deliveryDate`, deliveryDate && dateAdapter(deliveryDate));
    change(`${hedge}.deliveryDateGRG`, deliveryDateGRG && dateAdapter(deliveryDateGRG));

    resetSection(
      `${hedge}.${
        isGlobalRefiningGroup(value.relatedCompany?.label) ? 'deliveryDate' : 'deliveryDateGRG'
      }`,
    );
  };

  resetDeliveryDate = () => {
    const { resetSection, hedge } = this.props;
    const { disableDeliveryDate, disableDeliveryDateGRG } = this.state;

    if (!disableDeliveryDate) {
      resetSection(`${hedge}.deliveryDate`);
    }

    if (!disableDeliveryDateGRG) {
      resetSection(`${hedge}.deliveryDateGRG`);
    }
  };

  get isCustomer() {
    const {
      auth: {
        user: { userType },
      },
    } = this.props;

    return isCustomerUser(userType);
  }

  get isGlobalRefiningGroupUser() {
    const {
      auth: {
        user: { userType },
      },
    } = this.props;

    return isGlobalUserType(userType) || isBuyerUser(userType);
  }

  get allowRequestHedgeDate() {
    const {
      auth: {
        user: { userType },
      },
      value: { relatedCompany },
      enterPlacedHedges,
    } = this.props;

    return !enterPlacedHedges && requestHedgeDateAccess({ userType, relatedCompany });
  }

  get enableHedgeRequestNow() {
    const { marketAvailabilityDetails } = this.props;

    return (
      marketAvailabilityDetails?.isBusinessDay &&
      marketAvailabilityDetails?.marketDays &&
      marketAvailabilityDetails?.inMarketHours &&
      !marketAvailabilityDetails?.marketHoliday
    );
  }

  setDisableConfirmation = fieldName => {
    const { value } = this.props;

    return Boolean(!value[fieldName]);
  };

  render() {
    const {
      value: {
        relatedCompany,
        relatedLot,
        materialType,
        hedgeType,
        materialUnits,
        materialWeight,
        suggestedPlatinumOz,
        suggestedPalladiumOz,
        suggestedRhodiumOz,
        maximumPlatinumOz,
        maximumPalladiumOz,
        maximumRhodiumOz,
        placedAt,
        platinumOz,
        palladiumOz,
        rhodiumOz,
        requestedHedgeDate,
        hedgeOptions,
      },
      hedge,
      header,
      filterCompanies,
      onRemove,
      enterPlacedHedges,
      change,
      index,
      auth: {
        user: {
          relatedCompany: { trustedCompany, onlyInternalHedgingAndSettlement },
          userType,
        },
      },
    } = this.props;
    const { isTyping } = this.state;
    const requestNow = hedgeOptions?.value !== 'schedule';
    const marketHolidaysDates = this.props.marketHolidaysList.marketHolidays.map(
      ({ date }) => new Date(date),
    );

    const restrictedMaterial =
      !isCustomerUser(userType) ||
      Object.values(allowedMaterialTypesForCustomer).includes(materialType);
    const denomination = denominationTable[materialType] || '';
    const weightEnabled = denomination.includes(denominatedIn.weight) && restrictedMaterial;
    const unitsEnabled = denomination.includes(denominatedIn.units) && restrictedMaterial;

    const dateEnabledBase = hedgeType === hedgeTypes.blanket || !relatedLot;
    const dateGRGEnabled =
      dateEnabledBase || this.globalDeliveryDateStatuses.includes(relatedLot.statusForGlobal);
    const dateEnabled =
      dateEnabledBase || this.customerDeliveryDateStatuses.includes(relatedLot.statusForCustomer);
    const dateGRGRequired =
      !relatedLot || relatedLot.statusForGlobal !== statusesForGlobal.shipped_from_global;

    const actions = onRemove && (
      <IconButton onClick={onRemove} icon="icon-cancel" color={cssVar('whiteGRG')} />
    );

    const ouncesFields = [
      {
        component: FieldHint,
        name: `${hedge}.platinumOz`,
        suffix: HEDGES.OZ,
        label: HEDGES.PLATINUM_OZ,
        hint: [
          {
            label: SHARED.SUGGESTED,
            value:
              isNumber(maximumPlatinumOz) &&
              safeParseFloat(suggestedPlatinumOz) >= safeParseFloat(maximumPlatinumOz)
                ? null
                : suggestedPlatinumOz,
          },
          {
            label: SHARED.MAXIMUM,
            value: maximumPlatinumOz,
          },
        ],
        maxLength: '10',
        field: 'required',
        type: 'text',
        disabled: isBuyerUser(userType),
        forceShowError: true,
      },
      {
        component: FieldHint,
        name: `${hedge}.palladiumOz`,
        suffix: HEDGES.OZ,
        label: HEDGES.PALLADIUM_OZ,
        hint: [
          {
            label: SHARED.SUGGESTED,
            value:
              isNumber(maximumPalladiumOz) &&
              safeParseFloat(suggestedPalladiumOz) >= safeParseFloat(maximumPalladiumOz)
                ? null
                : suggestedPalladiumOz,
          },
          {
            label: SHARED.MAXIMUM,
            value: maximumPalladiumOz,
          },
        ],
        maxLength: '10',
        field: 'required',
        type: 'text',
        disabled: isBuyerUser(userType),
        forceShowError: true,
      },
      {
        component: FieldHint,
        name: `${hedge}.rhodiumOz`,
        suffix: HEDGES.OZ,
        label: HEDGES.RHODIUM_OZ,
        hint: [
          {
            label: SHARED.SUGGESTED,
            value:
              isNumber(maximumRhodiumOz) &&
              safeParseFloat(suggestedRhodiumOz) >= safeParseFloat(maximumRhodiumOz)
                ? null
                : suggestedRhodiumOz,
          },
          {
            label: SHARED.MAXIMUM,
            value: maximumRhodiumOz,
          },
        ],
        maxLength: '10',
        field: 'required',
        type: 'text',
        disabled: isBuyerUser(userType),
        forceShowError: true,
      },
    ];

    const relatedLotEnabled = hedgeType !== hedgeTypes.blanket;

    const getLabel = (key, type) => HEDGES[`${key}_${type.toUpperCase()}`];

    const getErrorMessage = (key, type) =>
      ENTER_PRICES[`${key}_${type.toUpperCase()}_DO_NOT_MATCH`];

    const pricesValueExtractor = ({ hedges }, fieldName) =>
      hedges && hedges[index] && hedges[index][fieldName];

    const disablePriceInput = metalOunces => +metalOunces === 0;

    const changeRequestNow = val => {
      change(`${hedge}.requestNow`, val.value === 'requestNow');
    };

    const isToday = () =>
      requestedHedgeDate &&
      MomentAdapter.normalize(requestedHedgeDate).valueOf() ===
        dateAdapter().startOf('d').valueOf();

    const resolveRequestedHedgeTime = () => {
      const minTime = dateAdapter(this.props.marketAvailabilityDetails.marketTimeFrom)
        .localizeTime()
        .getTime();
      const maxTime = dateAdapter(this.props.marketAvailabilityDetails.marketTimeTo)
        .localizeTime()
        .getTime();

      const marketDayAlreadyStarted =
        dateAdapter().add(HEDGE_DATE_NOTIFICATIONS_OFFSET, 'm').localizeTime().valueOf() > minTime;

      return {
        minTime:
          isToday() && marketDayAlreadyStarted
            ? dateAdapter().add(HEDGE_DATE_NOTIFICATIONS_OFFSET, 'm').localizeTime().valueOf()
            : minTime,
        maxTime,
      };
    };

    const filterMinMaxDate = () => {
      const maxTime = dateAdapter(this.props.marketAvailabilityDetails.marketTimeTo).localizeTime();

      return dateAdapter().localizeTime() > maxTime ? dateAdapter().add(1, 'd') : dateAdapter();
    };

    const isRequestedHedgeTimeDisabled = () => {
      const maxTime = dateAdapter(this.props.marketAvailabilityDetails.marketTimeTo).getTime();
      const isAfterMin = dateAdapter().localizeTime().valueOf() > dateAdapter(maxTime).getTime();

      return (isToday() && isAfterMin) || !requestedHedgeDate;
    };

    const getDeliveryMinDateForCustomer = () => {
      if (enterPlacedHedges) {
        return 0;
      }
      return !requestedHedgeDate ? dateAdapter() : MomentAdapter.normalize(requestedHedgeDate);
    };

    const getDeliveryMaxDateForCustomer = maxDeliveryDateSource =>
      maxDeliveryDateSource
        ? MomentAdapter.normalize(maxDeliveryDateSource).plus({ days: 25 })
        : dateAdapter().add(25, 'd');

    const hedgeSelectOptions = [
      { label: HEDGES.REQUEST_NOW, value: 'requestNow' },
      { label: HEDGES.SCHEDULE, value: 'schedule', alwaysVisible: true },
    ];

    const handleGetOptions = input =>
      filterCompanies(input).then(companies => ({
        options: companies?.options.map(opt => ({
          ...opt,
          label:
            opt.companyType === companyTypesEnum.grading ? `${opt.label} (grading)` : opt.label,
        })),
      }));

    return (
      <>
        <Section
          header={header}
          template={[
            `${hedge}.relatedCompany .`,
            `${hedge}.hedgeType ${hedge}.hedgeType`,
            `${relatedLotEnabled && `${hedge}.relatedLot .`}`,
          ]}
          actions={actions}
          smallPadding
        >
          <Field
            name={`${hedge}.relatedCompany`}
            component={FieldSearch}
            label={HEDGES.RELATED_COMPANY}
            field={this.isCustomer ? '' : 'required'}
            getOptions={handleGetOptions}
            onChange={this.relatedCompanyChange}
            disabled={this.isCustomer || isBuyerUser(userType)}
          />
          {(!isCustomerUser(userType) || (trustedCompany && !onlyInternalHedgingAndSettlement)) && (
            <Field
              name={`${hedge}.hedgeType`}
              component={FieldButtonSelect}
              onChange={this.hedgeTypeChange}
              options={
                isBuyerUser(userType) ? mapArray([hedgeTypes.standard]) : mapArray(this.hedgeTypes)
              }
              label={HEDGES.HEDGE_TYPE}
              field="required"
              disabled={isBuyerUser(userType)}
            />
          )}
          {relatedLotEnabled && (
            <FieldWithButton name={`${hedge}.relatedLot`} noPadding>
              <Field
                name={`${hedge}.relatedLot`}
                component={FieldSelect}
                onChange={this.relatedLotChange}
                options={this.availableLots}
                label={HEDGES.RELATED_LOT}
                disabled={!relatedCompany}
                field="required"
              />
              {relatedCompany && !isGradingCompany(relatedCompany.companyType) && (
                <IconButton
                  icon="icon-plus"
                  disabled={!relatedCompany}
                  onClick={() => {
                    this.props.history.push('/lots/create', {
                      fromRequestHedgeView: !enterPlacedHedges,
                      fromEnterPlacedHedgeView: enterPlacedHedges,
                      relatedHedge: hedge,
                      relatedCompany: {
                        label: relatedCompany?.label,
                        value: relatedCompany?.value,
                      },
                    });

                    this.props.saveSharedData({
                      hedgeOptions: { [hedge]: hedgeOptions?.value },
                    });
                  }}
                />
              )}
            </FieldWithButton>
          )}
        </Section>
        {(this.isCustomer || this.isGlobalRefiningGroupUser) && (
          <ExistingHedges
            hedgeType={hedgeType}
            hedgeTypes={hedgeTypes}
            hedge={hedge}
            relatedCompany={relatedCompany}
            enterPlacedHedges={enterPlacedHedges}
            relatedLotChange={this.relatedLotChange}
            availableLots={this.availableLots}
            companyId={relatedCompany?.value}
            lotId={relatedLot?.value}
            relatedLotEnabled={relatedLotEnabled}
            auth={this.props.auth}
          />
        )}
        <Section
          template={[`${hedge}.materialType .`, `${hedge}.materialUnits ${hedge}.materialWeight`]}
          topBorder
        >
          <Field
            name={`${hedge}.materialType`}
            component={FieldSelect}
            options={mapArray(this.materialTypes)}
            label={HEDGES.MATERIAL_TYPE}
            field={!enterPlacedHedges ? 'required' : ''}
            onChange={this.hedgeChange}
          />
          {(!enterPlacedHedges || materialType) && [
            <Field
              key={`${hedge}.materialUnits`}
              name={`${hedge}.materialUnits`}
              component={FieldInput}
              type="text"
              label={HEDGES.MATERIAL_UNITS}
              maxLength="10"
              field={(materialWeight === undefined || materialWeight === null) && 'required'}
              suffix="units"
              onChange={this.hedgeChangeUnits}
              disabled={!unitsEnabled || isTyping === 'materialWeight'}
            />,
            <Field
              key={`${hedge}.materialWeight`}
              name={`${hedge}.materialWeight`}
              component={FieldInput}
              type="text"
              label={HEDGES.MATERIAL_WEIGHT}
              field={(materialUnits === undefined || materialUnits === null) && 'required'}
              suffix="lbs"
              onChange={this.hedgeChangeUnits}
              disabled={!weightEnabled || isTyping === 'materialUnits'}
            />,
          ]}
        </Section>
        <Section header={HEDGES.OUNCES} template={['ouncesFields ouncesFields']} noPadding>
          <MultipleFieldsSection name="ouncesFields" fields={ouncesFields} />
        </Section>
        {enterPlacedHedges && (
          <>
            <Section
              header={HEDGES.HEDGE_PRICES}
              noPadding
              template={[
                `${hedge}.platinumPrice${priceType.actual} ${hedge}.platinumPrice${priceType.actual}`,
                `${hedge}.palladiumPrice${priceType.actual} ${hedge}.palladiumPrice${priceType.actual}`,
                `${hedge}.rhodiumPrice${priceType.actual} ${hedge}.rhodiumPrice${priceType.actual}`,
              ]}
            >
              <FieldConfirmation
                name={`${hedge}.platinumPrice${priceType.actual}`}
                label={getLabel('PLATINUM_PRICE', priceType.actual)}
                errorMessage={getErrorMessage('PLATINUM_PRICE', priceType.actual)}
                valueExtractor={values =>
                  pricesValueExtractor(values, `platinumPrice${priceType.actual}`)
                }
                disabled={disablePriceInput(platinumOz)}
                change={change}
                type="text"
                field="required"
                prefix="$"
                disableConfirmation={this.setDisableConfirmation(
                  `platinumPrice${priceType.actual}`,
                )}
              />
              <FieldConfirmation
                name={`${hedge}.palladiumPrice${priceType.actual}`}
                label={getLabel('PALLADIUM_PRICE', priceType.actual)}
                errorMessage={getErrorMessage('PALLADIUM_PRICE', priceType.actual)}
                valueExtractor={values =>
                  pricesValueExtractor(values, `palladiumPrice${priceType.actual}`)
                }
                disabled={disablePriceInput(palladiumOz)}
                change={change}
                type="text"
                field="required"
                prefix="$"
                disableConfirmation={this.setDisableConfirmation(
                  `palladiumPrice${priceType.actual}`,
                )}
              />
              <FieldConfirmation
                name={`${hedge}.rhodiumPrice${priceType.actual}`}
                label={getLabel('RHODIUM_PRICE', priceType.actual)}
                errorMessage={getErrorMessage('RHODIUM_PRICE', priceType.actual)}
                valueExtractor={values =>
                  pricesValueExtractor(values, `rhodiumPrice${priceType.actual}`)
                }
                disabled={disablePriceInput(rhodiumOz)}
                change={change}
                type="text"
                field="required"
                prefix="$"
                disableConfirmation={this.setDisableConfirmation(`rhodiumPrice${priceType.actual}`)}
              />
            </Section>
            {hedgeType === hedgeTypes.custom && (
              <Section
                header={HEDGES.HEDGE_PRICES_CUSTOMER}
                noPadding
                template={[
                  `${hedge}.platinumPrice${priceType.custom} ${hedge}.platinumPrice${priceType.custom}`,
                  `${hedge}.palladiumPrice${priceType.custom} ${hedge}.palladiumPrice${priceType.custom}`,
                  `${hedge}.rhodiumPrice${priceType.custom} ${hedge}.rhodiumPrice${priceType.custom}`,
                ]}
              >
                <FieldConfirmation
                  name={`${hedge}.platinumPrice${priceType.custom}`}
                  label={getLabel('PLATINUM_PRICE', priceType.custom)}
                  errorMessage={getErrorMessage('PLATINUM_PRICE', priceType.custom)}
                  valueExtractor={values =>
                    pricesValueExtractor(values, `platinumPrice${priceType.custom}`)
                  }
                  disabled={disablePriceInput(platinumOz)}
                  change={change}
                  type="text"
                  field="required"
                  prefix="$"
                  disableConfirmation={this.setDisableConfirmation(
                    `platinumPrice${priceType.custom}`,
                  )}
                />
                <FieldConfirmation
                  name={`${hedge}.palladiumPrice${priceType.custom}`}
                  label={getLabel('PALLADIUM_PRICE', priceType.custom)}
                  errorMessage={getErrorMessage('PALLADIUM_PRICE', priceType.custom)}
                  valueExtractor={values =>
                    pricesValueExtractor(values, `palladiumPrice${priceType.custom}`)
                  }
                  disabled={disablePriceInput(palladiumOz)}
                  change={change}
                  type="text"
                  field="required"
                  prefix="$"
                  disableConfirmation={this.setDisableConfirmation(
                    `palladiumPrice${priceType.custom}`,
                  )}
                />
                <FieldConfirmation
                  name={`${hedge}.rhodiumPrice${priceType.custom}`}
                  errorMessage={getErrorMessage('RHODIUM_PRICE', priceType.custom)}
                  label={getLabel('RHODIUM_PRICE', priceType.custom)}
                  valueExtractor={values =>
                    pricesValueExtractor(values, `rhodiumPrice${priceType.custom}`)
                  }
                  disabled={disablePriceInput(rhodiumOz)}
                  change={change}
                  type="text"
                  field="required"
                  prefix="$"
                  disableConfirmation={this.setDisableConfirmation(
                    `rhodiumPrice${priceType.custom}`,
                  )}
                />
              </Section>
            )}
            <Section header={HEDGES.PLACED_ON} template={[`${hedge}.placedAt .`]}>
              <Field
                name={`${hedge}.placedAt`}
                component={FieldDate}
                label={HEDGES.PLACED_ON}
                field="required"
                onDateChange={this.resetDeliveryDate}
                maxDate={dateAdapter()}
              />
            </Section>
          </>
        )}

        {this.allowRequestHedgeDate && (
          <Section header={HEDGES.HEDGE_DATE_AND_TIME} template={[`${hedge}.hedgeOptions .`]}>
            <Field
              name={`${hedge}.hedgeOptions`}
              component={FieldButtonSelect}
              options={hedgeSelectOptions}
              onChange={val => changeRequestNow(val)}
              disabled={!this.enableHedgeRequestNow}
            />
          </Section>
        )}

        {this.allowRequestHedgeDate && !requestNow && (
          <Section template={[`${hedge}.requestedHedgeDate ${hedge}.requestedHedgeTime`]} topBorder>
            <Field
              name={`${hedge}.requestedHedgeDate`}
              component={FieldDate}
              label={HEDGES.HEDGE_DATE}
              field="required"
              minDate={filterMinMaxDate()}
              maxDate={dateAdapter().add(25, 'd')}
              disabled={requestNow}
              excludeDates={marketHolidaysDates}
              onlyWeekdays
              onChange={this.resetDeliveryDate}
            />

            <Field
              name={`${hedge}.requestedHedgeTime`}
              component={FieldTime}
              label={HEDGES.HEDGE_TIME}
              field="required"
              icon="icon-clock"
              disabled={isRequestedHedgeTimeDisabled()}
              timeRange={resolveRequestedHedgeTime()}
              disableFlip
              showTimeZone
            />
          </Section>
        )}
        <Section
          header={HEDGES.DELIVERY_DATE}
          template={[`deliveryDate ${hedge}.customerInstructions`]}
        >
          <div name="deliveryDate">
            {isGlobalRefiningGroup(relatedCompany?.label) && dateGRGEnabled && (
              <Field
                name={`${hedge}.deliveryDateGRG`}
                component={FieldDate}
                label={HEDGES.DELIVERY_DATE_GRG}
                field={dateGRGRequired ? 'required' : ''}
                minDate={enterPlacedHedges ? 0 : dateAdapter()}
                maxDate={
                  placedAt
                    ? MomentAdapter.normalize(placedAt).plus({ days: 30 })
                    : dateAdapter().add(30, 'd')
                }
                disabled={this.state.disableDeliveryDateGRG}
              />
            )}
            {!isGlobalRefiningGroup(relatedCompany?.label) && dateEnabled && (
              <Field
                name={`${hedge}.deliveryDate`}
                component={FieldDate}
                label={HEDGES.DELIVERY_DATE}
                field="required"
                minDate={getDeliveryMinDateForCustomer()}
                maxDate={getDeliveryMaxDateForCustomer(requestedHedgeDate || placedAt)}
                disabled={this.state.disableDeliveryDate}
              />
            )}
          </div>
          <Field
            name={`${hedge}.customerInstructions`}
            component={FieldInput}
            label={HEDGES.CUSTOMER_INSTRUCTIONS}
          />
        </Section>
      </>
    );
  }
}

HedgeForm.propTypes = {
  anyTouched: PropTypes.bool,
  array: PropTypes.object,
  asyncValidate: PropTypes.func,
  asyncValidating: PropTypes.bool,
  auth: PropTypes.shape({
    access: PropTypes.object,
    attempts: PropTypes.number,
    isPending: PropTypes.bool,
    user: PropTypes.object,
  }),
  autofill: PropTypes.func,
  blur: PropTypes.func,
  change: PropTypes.func,
  blanketHedgeCount: PropTypes.number,
  clearAsyncError: PropTypes.func,
  clearBlanketHedgeCount: PropTypes.func,
  clearFields: PropTypes.func,
  clearSubmit: PropTypes.func,
  clearSubmitErrors: PropTypes.func,
  createHedgesRequests: PropTypes.func,
  clearSuggestedDeliveryDate: PropTypes.func,
  createHedges: PropTypes.shape({
    isPending: PropTypes.bool,
  }),
  destroy: PropTypes.func,
  dirty: PropTypes.bool,
  dispatch: PropTypes.func,
  filterCompanies: PropTypes.func,
  error: PropTypes.oneOfType([PropTypes.object]),
  form: PropTypes.string,
  getBlanketHedgeCount: PropTypes.func,
  getHedgesSuggestedValues: PropTypes.func,
  getHedgesSuggestedValuesAction: PropTypes.func,
  getLotsForHedges: PropTypes.func,
  getSuggestedDeliveryDate: PropTypes.func,
  handleSubmit: PropTypes.func,
  header: PropTypes.string,
  hedge: PropTypes.string,
  hedgesManagement: PropTypes.shape({
    isPending: PropTypes.bool,
    lots: PropTypes.array,
  }),
  hedgesSuggestedValues: PropTypes.shape({
    isPending: PropTypes.bool,
  }),
  hedgesValues: PropTypes.shape({
    hedges: PropTypes.array,
  }),
  history: PropTypes.object,
  index: PropTypes.number,
  initialValues: PropTypes.shape({
    hedges: PropTypes.array,
  }),
  initialize: PropTypes.func,
  initialized: PropTypes.bool,
  invalid: PropTypes.bool,
  isPackingList: PropTypes.bool,
  location: PropTypes.object,
  lotDetails: PropTypes.shape({
    isPending: PropTypes.bool,
    errorMessage: PropTypes.object,
    lot: PropTypes.object,
  }),
  match: PropTypes.object,
  openModal: PropTypes.func,
  onRemove: PropTypes.func,
  onSubmit: PropTypes.func,
  pristine: PropTypes.bool,
  pure: PropTypes.bool,
  reset: PropTypes.func,
  resetSection: PropTypes.func,
  showSnackbar: PropTypes.func,
  submit: PropTypes.func,
  submitAsSideEffect: PropTypes.bool,
  submitFailed: PropTypes.bool,
  submitSucceeded: PropTypes.bool,
  submitting: PropTypes.bool,
  suggestedDeliveryDate: PropTypes.shape({
    isPending: PropTypes.bool,
    deliveryDate: PropTypes.object,
  }),
  touch: PropTypes.func,
  triggerSubmit: PropTypes.oneOfType([PropTypes.func]),
  untouch: PropTypes.func,
  validate: PropTypes.func,
  warning: PropTypes.oneOfType([PropTypes.object]),
  marketAvailabilityDetails: PropTypes.shape({
    isPending: PropTypes.bool,
    isBusinessDay: PropTypes.bool,
    inMarketHours: PropTypes.bool,
    marketHoliday: PropTypes.bool,
    marketDays: PropTypes.string,
    marketDaysNumbers: PropTypes.array,
    marketHours: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    marketTimeFrom: PropTypes.number,
    marketTimeTo: PropTypes.number,
    validUntil: PropTypes.number,
    timezone: PropTypes.string,
  }),
};

export { HedgeForm };
