import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { reduxForm, Field } from 'redux-form';

import { openModal } from 'actions/shared/modal';

import { FieldInput, FieldSelect } from 'shared/components/Fields';
import { FieldCapitalized } from 'shared/components/Fields/FieldCapitalized/FieldCapitalized';
import { Form, Section } from 'shared/components/Form';
import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';
import { cancelModal } from 'shared/helpers/constants/modalConstants';
import { availableMaterialTypeOutbound } from 'shared/helpers/constants/packages/materialType';
import { mapArray } from 'shared/helpers/parsers/select';
import { validate } from 'shared/helpers/validations/lot/updateShipmentInfo';

const UpdateShipmentInfoFormBase = props => {
  const lotId = props.match.params.id;

  const navigateBack = () => props.history.push(`/lots/list/${lotId}`);

  const openModalOnCancel = () => {
    props.openModal(cancelModal, navigateBack);
  };

  return (
    <Form
      onSubmit={props.handleSubmit}
      onCancel={openModalOnCancel}
      header={LOTS.SHIPMENT_INFO}
      submitDisabled={props.invalid || props.submitting}
    >
      <Section template={['stillwaterShipmentId materialType']}>
        <FieldCapitalized
          name="stillwaterShipmentId"
          component={FieldInput}
          label={LOTS.STILLWATER_SHIPMENT_ID}
          field="required"
        />
        <Field
          name="materialType"
          label={LOTS.MATERIAL_TYPE}
          component={FieldSelect}
          options={mapArray(availableMaterialTypeOutbound.all)}
          field="required"
        />
      </Section>
    </Form>
  );
};

const mapDispatchToProps = {
  openModal,
};

const UpdateShipmentInfoForm = withRouter(
  connect(
    null,
    mapDispatchToProps,
  )(
    reduxForm({
      form: 'updateShipmentInfo',
      validate,
    })(UpdateShipmentInfoFormBase),
  ),
);

export { UpdateShipmentInfoForm, UpdateShipmentInfoFormBase };
