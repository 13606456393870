import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getAssignedLotsActionType = 'GET_ASSIGNED_LOTS';
function getAssignedLotsAction() {
  return {
    type: getAssignedLotsActionType,
  };
}

const getAssignedLotsSuccessActionType = 'GET_ASSIGNED_LOTS_SUCCESS';
function getAssignedLotsSuccessAction(response) {
  return {
    type: getAssignedLotsSuccessActionType,
    payload: {
      response,
    },
  };
}

const getAssignedLotsFailureActionType = 'GET_ASSIGNED_LOTS_FAILURE';
function getAssignedLotsFailureAction() {
  return {
    type: getAssignedLotsFailureActionType,
  };
}

const clearAssignedLotsActionType = 'CLEAR_ASSIGNED_LOTS';
function clearAssignedLotsAction() {
  return {
    type: clearAssignedLotsActionType,
  };
}

const getAssignedLotsFactory = url => dispatch => {
  dispatch(getAssignedLotsAction());

  return axios
    .get(`${API_HOST_AUTH}/${url}`)
    .then(response => dispatch(getAssignedLotsSuccessAction(response.data)))
    .catch(() => dispatch(getAssignedLotsFailureAction()));
};

const getAssignedLots = relatedCompany => getAssignedLotsFactory(`lots/package/${relatedCompany}`);

const getAssignedLotsForOutgoingShipment = () => getAssignedLotsFactory('lots/shipment/outgoing');

const getLotsForInspection = inspectionType =>
  getAssignedLotsFactory(`lots/inspections/${inspectionType}`);

const getLotsForAdvancePayment = () => getAssignedLotsFactory('lots/payments/advance');

const getLotsForEnterPayment = companyId =>
  getAssignedLotsFactory(`lots/payments/enter/${companyId}`);

const getLotsForRequestFinalPayment = companyId =>
  getAssignedLotsFactory(`lots/payments/request-final/${companyId}/`);

const getLotsForEnterPoolAccountOunces = companyId =>
  getAssignedLotsFactory(`lots/pool-accounts/enter-ounces/${companyId}`);

const getLotsAvailableForReassignment = companyId =>
  getAssignedLotsFactory(`lots/available-for-reassignment/${companyId}`);

export {
  getAssignedLotsActionType,
  getAssignedLotsAction,
  getAssignedLotsSuccessActionType,
  getAssignedLotsSuccessAction,
  getAssignedLotsFailureActionType,
  getAssignedLotsFailureAction,
  getAssignedLots,
  getAssignedLotsForOutgoingShipment,
  getLotsAvailableForReassignment,
  getLotsForInspection,
  getLotsForAdvancePayment,
  getLotsForEnterPayment,
  getLotsForRequestFinalPayment,
  getLotsForEnterPoolAccountOunces,
  clearAssignedLotsActionType,
  clearAssignedLotsAction,
};
