const message = {
  ALL_OUNCES_ZERO: 'At least one of the ounce values has to be greater than zero.',
  RELATED_COMPANY_EMPTY: 'Related company is required.',
  RELATED_LOT_EMPTY: 'Related lot is required.',
  PLATINUM_OZ_EMPTY: 'Platinum (oz.) is required.',
  PLATINUM_OZ_FORMAT: 'Platinum (oz.) has invalid format.',
  PLATINUM_OZ_NEGATIVE: 'Platinum (oz.) cannot be a negative value.',
  PLATINUM_OZ_LESS: value => `Platinum (oz.) must be less or equal ${value}.`,
  PLATINUM_OZ_EQUAL: value => `Platinum (oz.) must equal to ${value}.`,
  PALLADIUM_OZ_EMPTY: 'Palladium (oz.) is required.',
  PALLADIUM_OZ_FORMAT: 'Palladium (oz.) has invalid format.',
  PALLADIUM_OZ_NEGATIVE: 'Palladium (oz.) cannot be a negative value.',
  PALLADIUM_OZ_LESS: value => `Palladium (oz.) must be less or equal ${value}.`,
  PALLADIUM_OZ_EQUAL: value => `Palladium (oz.) must equal to ${value}.`,
  RHODIUM_OZ_EMPTY: 'Rhodium (oz.) is required.',
  RHODIUM_OZ_FORMAT: 'Rhodium (oz.) has invalid format.',
  RHODIUM_OZ_NEGATIVE: 'Rhodium (oz.) cannot be a negative value.',
  RHODIUM_OZ_LESS: value => `Rhodium (oz.) must be less or equal ${value}.`,
  RHODIUM_OZ_EQUAL: value => `Rhodium (oz.) must equal to ${value}.`,
};

export { message };
