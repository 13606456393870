import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { Permissions } from './Permissions';

const mapStateToProps = state => ({
  specialPermissions: state.specialPermissions.value,
});

const ConnectedPermissions = withRouter(connect(mapStateToProps, null)(Permissions));

export { ConnectedPermissions };
