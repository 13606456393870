import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { getSelfDeliveryRequestsCompanies } from 'actions/Companies/getCompaniesForFilters';
import {
  selfDeliveryRequestsFiltersClear,
  deliveryDateFromFilter,
  deliveryDateToFilter,
  relatedCompanyFilter,
  statusFilter,
} from 'actions/SelfDeliveryRequests/selfDeliveryRequestsFilters';
import {
  getSelfDeliveryRequestsList,
  clearSelfDeliveryRequestsList,
} from 'actions/SelfDeliveryRequests/selfDeliveryRequestsList';

import { SelfDeliveryRequestsList } from './SelfDeliveryRequestsList';

const mapStateToProps = ({
  auth,
  selfDeliveryRequestsList,
  selfDeliveryRequestsFilters,
  companiesForFilters,
}) => ({
  auth,
  selfDeliveryRequestsList,
  selfDeliveryRequestsFilters,
  companiesForFilters,
});

const mapDispatchToProps = {
  getSelfDeliveryRequestsList,
  clearSelfDeliveryRequestsList,
  statusFilter,
  relatedCompanyFilter,
  deliveryDateToFilter,
  deliveryDateFromFilter,
  selfDeliveryRequestsFiltersClear,
  getSelfDeliveryRequestsCompanies,
};

const ConnectedSelfDeliveryRequestsList = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SelfDeliveryRequestsList),
);

export { ConnectedSelfDeliveryRequestsList };
