import { connect } from 'react-redux';

import { getNotificationsOnDashboard } from 'actions/Dashboard/dashboardNotificationsLists';
import {
  getNotificationsCount,
  setNotificationsCount,
  clearNotificationsCount,
} from 'actions/Notifications/notificationCount';
import { getNotifications } from 'actions/Notifications/notificationsList';

import { NotificationsCounter } from './NotificationsCounter';

const mapStateToProps = ({ auth, notificationsCount, notificationsList }) => ({
  auth,
  notificationsCount,
  notificationsList,
});

const mapDispatchToProps = {
  getNotificationsCount,
  setNotificationsCount,
  clearNotificationsCount,
  getNotifications,
  getNotificationsOnDashboard,
};

const connectedNotificationsCounter = connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotificationsCounter);

export { connectedNotificationsCounter as NotificationsCounter };
