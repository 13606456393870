import React, { useEffect, useCallback, useMemo } from 'react';

import { FormContainer } from 'shared/components/Form';
import { materialCountRequiredAvailable } from 'shared/helpers/accesses/companies/materialCountRequiredAvailable';
import { samplesAllowedAvailable } from 'shared/helpers/accesses/companies/samplesAllowedAvailable';
import { COMPANY } from 'shared/helpers/constants/companies/companyConstants';
import { parseExistingCompanyFile } from 'shared/helpers/dataParsers/company/parseExistingCompanyFile';
import {
  isAssayOrGradingCompany,
  isGradingCompany,
} from 'shared/helpers/matchers/checkCompanyType';
import { dateToTimestamp } from 'shared/helpers/parsers/date';
import { humanize } from 'shared/helpers/parsers/text';

import { ConnectedCompanyForm } from '../CompanyForm/CompanyForm';

function UpdateCompany({
  match: { params },
  companyDetails,
  getCompany,
  updateCompany,
  clearCompanyDetails,
  ...props
}) {
  const company = companyDetails && companyDetails.company;

  const submit = useCallback(
    values => {
      const changedFields = Object.keys(values).reduce((diff, key) => {
        if (values[key] === initialValues[key]) return diff;
        return {
          ...diff,
          [key]: values[key],
        };
      }, {});

      return updateCompany(
        {
          companyName: values.companyName,
          companyType: values.companyType,
          materialCountRequired: values.materialCountRequired,
          lotPrefix: values.lotPrefix,
          ...changedFields,
        },
        params.id,
        !company.hasLotsOrHedges,
        props.companyFiles,
      );
    },
    [params.id, updateCompany, company, props.companyFiles.files, props.companyFiles.existingFiles],
  );

  useEffect(() => {
    getCompany(params.id).then(company => {
      return props.companyAddExistingFiles(parseExistingCompanyFile(company.files));
    });

    return () => {
      clearCompanyDetails();
      props.companyClearAllFiles();
    };
  }, [params.id, getCompany, clearCompanyDetails]);

  const initialValues = useMemo(() => {
    return (
      company && {
        companyName: company.companyName,
        companyType: {
          label: humanize(company.companyType),
          value: company.companyType,
        },
        lotPrefix: isAssayOrGradingCompany(company.companyType)
          ? company.lotPrefix.replace(COMPANY.LOT_PREFIX_PREFIX, '')
          : company.lotPrefix,
        referralMethod: company.referralMethod,
        ...(company.volume && { volume: company.volume }),
        ...(company.purchasingAppURL && { purchasingAppURL: company.purchasingAppURL }),
        ...(materialCountRequiredAvailable(company.companyType) && {
          materialCountRequired: company.materialCountRequired,
        }),
        ...(!isGradingCompany(company.companyType) && {
          onlyInternalHedgingAndSettlement: company.onlyInternalHedgingAndSettlement,
        }),
        ...(samplesAllowedAvailable(company.companyType) && {
          samplesAllowed: company.samplesAllowed,
        }),
        grgDefaultShippingLocation: {
          label: company.grgDefaultShippingLocation.name,
          value: company.grgDefaultShippingLocation._id,
        },
        photoIdExpiresAt: dateToTimestamp(
          company?.files?.photoId[company?.files?.photoId.length - 1]?.fileExpiresAt,
        ),
        documentForAMLExpiresAt: dateToTimestamp(
          company?.files?.documentForAML[company?.files?.documentForAML.length - 1]?.fileExpiresAt,
        ),
        businessLicenseExpiresAt: dateToTimestamp(
          company?.files?.businessLicense[company?.files?.businessLicense.length - 1]
            ?.fileExpiresAt,
        ),
      }
    );
  }, [company]);

  return (
    <FormContainer
      header={COMPANY.EDIT_COMPANY}
      loaded={companyDetails.company && companyDetails.company.grgDefaultShippingLocation}
    >
      {() => (
        <ConnectedCompanyForm
          {...props}
          initialValues={initialValues}
          companyDetails={companyDetails}
          editMode
          onSubmit={submit}
        />
      )}
    </FormContainer>
  );
}

export { UpdateCompany };
