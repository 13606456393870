import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import styled from 'styled-components';

import {
  getPrintablePackages,
  clearPrintablePackages,
} from 'actions/Packages/printablePackagesList';
import {
  clearShipmentPackagesList,
  shipmentPackagesList,
} from 'actions/Shipments/shipmentPackagesList';

import { GroupButtons } from 'shared/components/Buttons/GroupButton/GroupButtons';
import { GroupButton } from 'shared/components/Buttons/GroupButton/components/GroupButton';
import { OverviewList } from 'shared/components/OverviewList/OverviewList';
import { PreloaderInline } from 'shared/components/PreloaderInline/PreloaderInline';
import { packageClasses } from 'shared/helpers/constants/packages/packageClasses';
import { PACKAGES } from 'shared/helpers/constants/packages/packageConstants';
import {
  itemsTemplate,
  actionsTemplate,
} from 'shared/helpers/constants/packages/printPackagesLabelsTableConfig';
import { isCustomerOrGrading } from 'shared/helpers/matchers/checkUserType';
import { humanize } from 'shared/helpers/parsers/text';

import { PrintPackageLabelsActions } from './PrintPackageLabelsActions';

const PackageTypeChoose = styled.div`
  div {
    margin: 40px auto 20px;
  }
`;

const PrintPackageLabelsBase = ({ match, location }) => {
  const relatedType = location.pathname.includes('shipments') ? 'shipments' : 'lots';
  const matchId = match.params.id;
  const packagesOverviewList = useSelector(state => state.packagesOverviewList);
  const packagesList = useSelector(state => state.printablePackagesList);
  const userType = useSelector(state => state.auth.user.userType);
  const chosenPackages = useSelector(state => state.shipmentPackagesListData.packages);
  const dispatch = useDispatch();

  const availableClasses = Object.values(packageClasses).filter(
    pkgClass =>
      packagesOverviewList.packagesList[pkgClass] &&
      packagesOverviewList.packagesList[pkgClass].total > 0,
  );

  const [packageClass, setPackageClass] = useState(
    (relatedType === 'shipments' ||
      availableClasses.length === 1 ||
      isCustomerOrGrading(userType)) &&
      packageClasses.incoming,
  );

  useEffect(
    () => () => {
      dispatch(clearPrintablePackages());
      dispatch(clearShipmentPackagesList());
    },
    [dispatch],
  );

  const getPackages = useCallback(
    () => dispatch(getPrintablePackages(relatedType, matchId, packageClass)),
    [dispatch, relatedType, matchId, packageClass],
  );

  const addPackageToList = packageId => {
    dispatch(shipmentPackagesList([...chosenPackages, packageId]));
  };

  const removePackageFromList = packageId => {
    const packages = chosenPackages.filter(id => id !== packageId);
    dispatch(shipmentPackagesList(packages));
  };

  const checkElement = id => {
    chosenPackages.includes(id) ? removePackageFromList(id) : addPackageToList(id);
  };

  return (
    <>
      {!packageClass ? (
        <PackageTypeChoose>
          <h1>Choose type</h1>
          <GroupButtons>
            {Object.values(packageClasses).map(pkgClass => (
              <GroupButton
                access={packagesOverviewList.packagesList[pkgClass]?.total > 0}
                label={humanize(pkgClass)}
                onClick={() => setPackageClass(pkgClass)}
              />
            ))}
          </GroupButtons>
        </PackageTypeChoose>
      ) : (
        <>
          <PrintPackageLabelsActions packageClass={packageClass} />
          <PreloaderInline loading={packagesList.isPending && !packagesList.pages} />
          <OverviewList
            input={{
              items: packagesList.items,
              isPending: packagesList.isPending,
              pages: packagesList.pages,
            }}
            getList={getPackages}
            label={PACKAGES.PACKAGES}
            itemsTemplate={itemsTemplate}
            actionsTemplate={actionsTemplate}
            templateProps={{
              listSize: packagesList.length,
              isPackingList: true,
            }}
            actionsContext={{ checkElement }}
          />
        </>
      )}
    </>
  );
};

const PrintPackageLabels = withRouter(PrintPackageLabelsBase);

export { PrintPackageLabels };
