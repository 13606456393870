import PropTypes from 'prop-types';
import { useEffect, useMemo, useState, useCallback, useContext } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useLocation, withRouter } from 'react-router';
import { compose } from 'redux';
import { Field, FormSection, formValueSelector, reduxForm, getFormValues } from 'redux-form';

import { getAssignedLots, getLotsAvailableForReassignment } from 'actions/Lots/getAssignedLots';
import { getLot } from 'actions/Lots/lotDetails';
import { getPackagesOverviewForLots } from 'actions/Packages/packagesOverviewList';
import { clearTemporaryData, saveTemporaryData } from 'actions/Shipments/saveTemporaryData';
import { openModal } from 'actions/shared/modal';
import { showSnackbar } from 'actions/shared/snackbar';

import { IconButton } from 'shared/components/Buttons/IconButton/IconButton';
import { FieldInput, FieldSelect } from 'shared/components/Fields';
import { FieldButtonSelect } from 'shared/components/Fields/FieldButtonSelect/FieldButtonSelect';
import { FieldCapitalized } from 'shared/components/Fields/FieldCapitalized/FieldCapitalized';
import { Form, Section } from 'shared/components/Form';
import { FieldWithButton } from 'shared/components/Form/FieldWithButton/FieldWithButton';
import { formName, wizardContext } from 'shared/components/Wizard/Wizard';
import { samplePackageAccess } from 'shared/helpers/accesses/packages/samplePackageAccess';
import { processingTypes } from 'shared/helpers/constants/lots/processingConstants';
import { statusesForCustomer } from 'shared/helpers/constants/lots/statusesForCustomer';
import { denominationTable } from 'shared/helpers/constants/materials/denominationTable';
import { cancelModal } from 'shared/helpers/constants/modalConstants';
import { availableMaterialClass } from 'shared/helpers/constants/packages/materialClass';
import {
  allMaterials,
  availableMaterialTypeCommodities,
  availableMaterialTypeForAssay,
  availableMaterialTypeForGrading,
  availableMaterialTypeOutbound,
  availableMaterialTypesPackingList,
  materialTypeForAssay,
  availableMaterialTypesSamplePackage,
} from 'shared/helpers/constants/packages/materialType';
import { PACKAGES } from 'shared/helpers/constants/packages/packageConstants';
import { availablePackageType, packageType } from 'shared/helpers/constants/packages/packageType';
import { isPhone } from 'shared/helpers/constants/resolutionsConstants';
import { SHIPMENTS } from 'shared/helpers/constants/shipments/shipmentsConstants';
import { infoOptions } from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { parseLotsSelectData } from 'shared/helpers/dataParsers/lot/parseLotsSelectData';
import {
  isAssayCompany,
  isGradingCompany,
  isInternalCompany,
} from 'shared/helpers/matchers/checkCompanyType';
import {
  isCustomerUser,
  isGradingUser,
  isWarehouseUser,
  isOwnerOrSupport,
} from 'shared/helpers/matchers/checkUserType';
import { isGlobalRefiningGroup } from 'shared/helpers/matchers/isGlobalRefiningGroup';
import { isGlobalUserType } from 'shared/helpers/matchers/isGlobalUserType';
import { mapArray } from 'shared/helpers/parsers/select';
import { asyncValidate } from 'shared/helpers/validations/package/customLotNumberAsyncValidation';
import { validate } from 'shared/helpers/validations/package/incomingPackagesCreateValidations';
import { useWindowWidth } from 'shared/hooks/useWindowWidth';

import { goBackOrTo } from 'utils/history';
import { refreshToken } from 'utils/refreshToken';

import { configNames } from 'pages/Packages/components/PackagesManagement/components/PackageForm/components/PackageForm/fieldsConfig';

import { setProcessingDefaultMaterialType } from './setProcessingDefaultMaterialType/setProcessingDefaultMaterialType';

const IncomingPackageFormBase = ({
  getLot: getLotAction,
  getPackagesOverviewForLots: getPackagesOverviewForLotsAction,
  getAssignedLots: getAssignedLotsAction,
  getLotsAvailableForReassignment: getLotsAvailableForReassignmentAction,
  forLot,
  change,
  touch,
  resetSection,
  assignedLot,
  auth: {
    user: { userType, relatedCompany },
  },
  lotDetails,
  packagesOverviewList: {
    packagesList: { incoming },
    isPending: packageListPending,
  },
  ...props
}) => {
  const [oldDefault, setOldDefaults] = useState({});
  const dispatch = useDispatch();
  const [header, setHeader] = useState('');
  const wizard = useContext(wizardContext);
  const windowWidth = useWindowWidth();
  const location = useLocation();
  const wizardData = useSelector(state => getFormValues('wizard')(state));
  const isPackingList = wizard.config === configNames.packingList;
  const isPostProcessing = Object.keys(processingTypes).includes(wizard.config);
  const sentFromCompany = useSelector(state => state.createShipment.temporaryData?.sentFromCompany);
  const createdLot = useSelector(state => state.createShipment.temporaryData?.createdLot);

  const isOwnerEditPackingList = wizard.editMode && isOwnerOrSupport(userType);
  const defaultMatetrialType =
    !packageListPending && setProcessingDefaultMaterialType({ packages: incoming?.docs || [] });

  useEffect(() => {
    if (isPackingList) {
      change(
        'defaultPackagesValues.materialTypeDeclared',
        ...mapArray(availableMaterialTypesPackingList.all),
      );
    }

    if (isPostProcessing && defaultMatetrialType && !props.materialTypeDeclared?.value)
      change('defaultPackagesValues.materialTypeDeclared', ...mapArray([defaultMatetrialType]));

    wizard.data?.setDefaultMaterialType &&
      wizard.data.setDefaultMaterialType(isPostProcessing && defaultMatetrialType);
  }, [wizard.config, defaultMatetrialType]);

  useEffect(() => {
    if (isPackingList) {
      const {
        defaultPackagesValues: { customLotNumber },
      } = wizardData;

      change('defaultPackagesValues.customLotNumber', customLotNumber);
    }
  }, [wizardData?.defaultPackagesValues?.assignedLot]);

  const forLotValue = forLot && forLot._id;

  useEffect(() => {
    if (createdLot) {
      change('defaultPackagesValues.customLotNumber', createdLot?.customLotNumber);
      change('defaultPackagesValues.assignedLot', {
        label: createdLot?.grgLotNumber,
        value: createdLot?.id,
        customLotNumber: createdLot?.customLotNumber,
      });

      dispatch(clearTemporaryData('createdLot'));
    }

    if (forLotValue && !wizard?.editMode) {
      const lots = parseLotsSelectData([forLot]);
      change('defaultPackagesValues.assignedLot', lots[0]);
    }
  }, [forLotValue]);

  useEffect(() => {
    const {
      history: { location },
    } = props;

    if (location.state?.assignLot && isPackingList) {
      change('defaultPackagesValues.assignedLot', {
        label: createdLot?.grgLotNumber,
        value: createdLot?.id,
        customLotNumber: createdLot?.customLotNumber,
      });

      change('defaultPackagesValues.customLotNumber', createdLot?.customLotNumber);

      if (wizard.editMode) {
        props.location.state = {
          assignLot: false,
        };
        wizard.setChangedShipmentDestination(true);
        wizardData.packages.map(pkg => (pkg.edited = true));
        change('defaultPackagesValues.packageCountIncoming', 0);
      } else {
        props.history.replace('/shipments/create-incoming-wizard', {
          ...props.location.state,
          assignLot: false,
        });
      }
    }
  }, [props.location.state?.id, createdLot]);

  useEffect(() => {
    const { oneOrMultiLots, packagesQuantity, ...oldValues } = props.formValues || {};
    setOldDefaults(oldValues);
    if (props.match.params.lotId) {
      if (!assignedLot) {
        getLotAction(props.match.params.lotId);
      }
      getPackagesOverviewForLotsAction(
        props.match.params.lotId,
        0,
        SHIPMENTS.SHIPMENT_TYPES.INCOMING,
      );
    } else {
      if (wizard.editMode && isOwnerEditPackingList) {
        getLotsAvailableForReassignmentAction(sentFromCompany?.value || relatedCompany._id);
      } else {
        getAssignedLotsAction(
          sentFromCompany?.value || location.state?.sentFromCompany?._id || relatedCompany._id,
          statusesForCustomer.open,
        );
      }
    }

    setHeader(renderHeader());
  }, [getLotAction, getPackagesOverviewForLotsAction, getAssignedLotsAction, sentFromCompany]);

  const getLotIdForRequest = () => {
    if (isPackingList) {
      return props.createdLot?.id === forLot?._id ? forLot?._id : props.createdLot?.id;
    }
    return assignedLot?.value;
  };

  useEffect(() => {
    const lotSource = getLotIdForRequest();

    if (!props.match.params.lotId && forLot?._id && lotSource) {
      getLotAction(lotSource);
    }
  }, [sentFromCompany, assignedLot?.value, props.history.location.state]);

  useEffect(() => {
    wizard.hiddenStepsCount && wizard.setHiddenSteps(1);
  }, [wizardData?.defaultPackagesValues?.packageType]);

  const onCancel = () => {
    props.openModal(cancelModal, () => {
      goBackOrTo('/');
    });
  };

  const resetUnitFields = () => {
    change('unitCount', null);
  };

  const resetWeightFields = () => {
    change('weightGrossDeclared', null);
    change('weightTareDeclared', null);
    change('weightGrossActual', null);
    change('weightTareActual', null);
  };

  const getLotsNumber = () => mapArray(Object.values(PACKAGES.LOTS_FOR_PACKAGES));

  const areInstructionsRequired =
    props.materialTypeDeclared && props.materialTypeDeclared.value === materialTypeForAssay.other;

  const checkMaterialTypeDeclared = areInstructionsRequired && 'required';

  const getPackageTypeOptions = () => {
    if (wizard.config && !isPackingList) return availablePackageType.processing;

    if (
      isPackingList ||
      forLot?.sentFromCompany?.materialCountRequired ||
      !samplePackageAccess({
        userType,
        relatedCompany,
      })
    ) {
      return availablePackageType.all.filter(pkg => pkg !== packageType.sample);
    }

    return availablePackageType.allWithLabels;
  };

  const companyType =
    sentFromCompany?.companyType ||
    location.state?.sentFromCompany?.companyType ||
    forLot?.sentFromCompany?.companyType;

  const companyName =
    sentFromCompany?.companyName ||
    location.state?.sentFromCompany?.companyName ||
    forLot?.sentFromCompany?.companyName;

  const getMaterialTypeDeclaredOptions = useMemo(() => {
    const { config } = props;

    if (isGlobalUserType(userType) && !companyType) return [];

    if (wizardData?.defaultPackagesValues?.packageType?.value === packageType.sample) {
      return availableMaterialTypesSamplePackage.all;
    }

    if (wizard.config) {
      if (isPackingList) {
        return availableMaterialTypesPackingList.all;
      }
      return availableMaterialTypeOutbound.all;
    }

    if (
      isCustomerUser(userType) ||
      isAssayCompany(companyType) ||
      (isInternalCompany(companyType) && !isGlobalRefiningGroup(companyName))
    ) {
      return availableMaterialTypeForAssay.all;
    }

    if (isGradingUser(userType) || isGradingCompany(companyType)) {
      return availableMaterialTypeForGrading.all;
    }

    if (isGlobalRefiningGroup(companyName) || isWarehouseUser(userType)) {
      return availableMaterialTypeCommodities.all;
    }

    if (config === configNames.incoming) {
      return allMaterials;
    }

    return [];
  }, [props, wizard.config, companyType, companyName]);

  const handlePackageChange = ({ value }) => {
    const materialTypeDeclared = wizardData?.defaultPackagesValues?.materialTypeDeclared?.value;

    if (!materialTypeDeclared) return;

    if (
      value === packageType.sample &&
      !availableMaterialTypesSamplePackage.all.includes(materialTypeDeclared)
    ) {
      change('defaultPackagesValues.materialTypeDeclared', null);
    }
  };

  const setMaterialType = materialTypeDeclared => {
    const materialType = materialTypeDeclared && materialTypeDeclared.value;

    if (materialType === materialTypeForAssay.other) {
      touch('defaultPackagesValues.customerInstructions');
    }

    if (
      isPostProcessing &&
      defaultMatetrialType &&
      materialTypeDeclared?.value !== defaultMatetrialType
    ) {
      const message = PACKAGES.DOUBLE_CHECK_MATERIAL_TYPE(defaultMatetrialType);

      dispatch(showSnackbar(infoOptions, message));
    }

    denominationTable[materialType] === 'units' ? resetWeightFields() : resetUnitFields();
  };

  const onSubmit = values => {
    props.saveTemporaryData({ createShipment: wizardData.createShipment });

    return props.onSubmit({
      defaultPackagesValues: {
        ...values.defaultPackagesValues,
        oldValues: oldDefault,
      },
    });
  };

  const onLotChanged = useCallback(
    value => {
      if (isPackingList) {
        if (wizard.editMode && value) setReassignedLot(value);
        return change('defaultPackagesValues.customLotNumber', value?.customLotNumber);
      }

      change('defaultPackagesValues.assignedLot', value);
      change('defaultPackagesValues.customLotNumber', value?.customLotNumber);
      return resetSection('defaultPackagesValues.materialTypeDeclared');
    },
    [resetSection, isPackingList],
  );

  const setReassignedLot = reassignedLot => {
    if (wizard.assignedShipment.packingListAssignedLot._id !== reassignedLot?.value) {
      getLotAction(reassignedLot.value).then(lot => {
        change('defaultPackagesValues.packageCountIncoming', +lot.packageCountIncoming);
        wizard.setChangedShipmentDestination(true);
      });

      wizardData.packages.map(pkg => (pkg.edited = true));
      change('defaultPackagesValues.assignedLot', reassignedLot);
    } else {
      wizardData.packages.map(pkg => delete pkg.edited);
      wizard.setChangedShipmentDestination(false);
      change(
        'defaultPackagesValues.packageCountIncoming',
        +wizard.assignedShipment.packingListAssignedLot.packageCountIncoming,
      );
    }
  };

  const renderHeader = () => {
    if (wizard.config) {
      switch (wizard.config) {
        case configNames.postMill:
          return PACKAGES.CREATE_POST_MILL_PACKAGES;
        case configNames.postMix:
          return PACKAGES.CREATE_POST_MIX_PACKAGES;
        case configNames.postShears:
          return PACKAGES.CREATE_POST_SHEARS_PACKAGES;
        case configNames.packingList:
          return PACKAGES.CREATE_PACKING_LIST_PACKAGES;
        default:
          return;
      }
    }
    return PACKAGES.CREATE_INCOMING_PACKAGES;
  };

  const clearAssignedLot = e => {
    if (e.value === PACKAGES.LOTS_FOR_PACKAGES.MULTIPLE_LOTS) {
      resetSection(formName, 'defaultPackagesValues.assignedLot');
      resetSection(formName, 'defaultPackagesValues.customLotNumber');
    } else {
      change('assignedLot', e);
    }
  };

  const handleOnFocus = () => {
    isPackingList && refreshToken();
  };

  const moveToCreateLot = () => {
    props.history.push('/lots/create', {
      ...props.location.state,
      ...(isPackingList && wizard.editMode
        ? {
            fromEditPackingListView: true,
            assignedShipmentId: wizard.assignedShipment._id,
          }
        : isPackingList
        ? { fromPackingListView: true }
        : { fromCreatePackagesView: true }),
    });
  };

  const showAssignedLot =
    isPackingList || props.oneOrMultiLots?.value === PACKAGES.LOTS_FOR_PACKAGES.SAME_LOT;

  const loaded = props.match.params.lotId ? { loaded: !!forLot } : {};

  return (
    <Form
      header={header}
      onCancel={onCancel}
      onSubmit={props.handleSubmit(onSubmit)}
      submitDisabled={props.invalid || props.submitting}
      {...loaded}
    >
      <FormSection name="defaultPackagesValues">
        <Section
          template={
            !props.match.params.lotId && !isPackingList
              ? ['oneOrMultiLots packagesQuantity', 'assignedLot .']
              : ['assignedLot packagesQuantity', ...(isPackingList ? ['customLotNumber .'] : [])]
          }
          noPaddingField={
            isCustomerUser(userType) &&
            props.oneOrMultiLots &&
            !isPackingList &&
            props.oneOrMultiLots.value === PACKAGES.LOTS_FOR_PACKAGES.SAME_LOT &&
            2
          }
        >
          {!props.match.params.lotId && !isPackingList && (
            <Field
              name="oneOrMultiLots"
              component={FieldButtonSelect}
              options={getLotsNumber()}
              label={PACKAGES.LOT_FOR_PACKAGES}
              disabled={props.match.params.lotId}
              onChange={clearAssignedLot}
              field="required"
            />
          )}
          {showAssignedLot && (
            <FieldWithButton name="assignedLot" noPadding={windowWidth < isPhone}>
              <Field
                name="assignedLot"
                component={FieldSelect}
                options={parseLotsSelectData(props.assignedLots.data)}
                disabled={
                  props.match.params.lotId || (wizard.editMode && wizard.disabledReassignLot)
                }
                label={PACKAGES.ASSIGNED_LOT}
                onChange={onLotChanged}
                field="required"
                onFocus={handleOnFocus}
              />
              <IconButton
                icon="icon-plus"
                disabled={
                  props.match.params.lotId || (wizard.editMode && wizard.disabledReassignLot)
                }
                onClick={moveToCreateLot}
              />
            </FieldWithButton>
          )}
          {!isOwnerEditPackingList && (
            <Field
              name="packagesQuantity"
              component={FieldInput}
              type="number"
              label={PACKAGES.PACKAGES_QUANTITY}
              field="required"
              onFocus={handleOnFocus}
            />
          )}
          {isPackingList && (
            <FieldCapitalized
              name="customLotNumber"
              type="text"
              component={FieldInput}
              label={PACKAGES.CUSTOM_LOT_NUMBER}
              icon="icon-referral"
              disabled={
                !assignedLot?.value ||
                isOwnerEditPackingList ||
                !!assignedLot?.assignedShipmentsCount
              }
              onFocus={handleOnFocus}
            />
          )}
        </Section>
        <Section
          header={PACKAGES.DEFAULT_VALUES}
          template={[
            'packageType packageType',
            'materialClass materialClass',
            'materialTypeDeclared customerInstructions',
          ]}
        >
          <Field
            name="customerInstructions"
            component={FieldInput}
            label={PACKAGES.CUSTOMER_INSTRUCTIONS}
            field={checkMaterialTypeDeclared}
            onFocus={handleOnFocus}
          />
          <Field
            name="packageType"
            component={FieldButtonSelect}
            options={mapArray(getPackageTypeOptions())}
            label={PACKAGES.PACKAGE_TYPE}
            onFocus={handleOnFocus}
            onChange={handlePackageChange}
          />
          {wizard.config && (wizard.config === 'postMill' || wizard.config === 'postMix') && (
            <Field
              name="materialClass"
              component={FieldButtonSelect}
              options={mapArray(availableMaterialClass.all)}
              label={PACKAGES.MATERIAL_CLASS}
            />
          )}
          <Field
            name="materialTypeDeclared"
            component={FieldSelect}
            options={mapArray(getMaterialTypeDeclaredOptions)}
            label={PACKAGES.DECLARED_MATERIAL_TYPE}
            onChange={setMaterialType}
            maxMenuHeight={235}
          />
        </Section>
      </FormSection>
    </Form>
  );
};

const selector = formValueSelector(formName);

const mapStateToProps = state => ({
  auth: state.auth,
  lotDetails: state.lotDetails,
  createdLot: state.createShipment.temporaryData?.createdLot,
  assignedLots: state.assignedLots,
  forLot: state.lotDetails.lot,
  lotsList: state.lotsList,
  packageDetails: state.packageDetails,
  packagesOverviewList: state.packagesOverviewList,
  config: 'incoming',
  formValues: selector(state, 'defaultPackagesValues'),
  oneOrMultiLots: selector(state, 'defaultPackagesValues.oneOrMultiLots'),
  materialTypeDeclared: selector(state, 'defaultPackagesValues.materialTypeDeclared'),
  assignedLot: selector(state, 'defaultPackagesValues.assignedLot'),
});

const mapDispatchToProps = {
  getLot,
  getPackagesOverviewForLots,
  getAssignedLots,
  getLotsAvailableForReassignment,
  openModal,
  saveTemporaryData,
};

const IncomingPackageForm = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    validate,
    asyncValidate,
    asyncBlurFields: ['defaultPackagesValues.customLotNumber'],
  }),
)(IncomingPackageFormBase);

IncomingPackageFormBase.propTypes = {
  CustomActions: PropTypes.func,
  anyTouched: PropTypes.bool,
  array: PropTypes.object,
  assignedLots: PropTypes.shape({
    isPending: PropTypes.bool,
    data: PropTypes.array,
  }),
  asyncBlurFields: PropTypes.array,
  asyncValidate: PropTypes.func,
  asyncValidating: PropTypes.bool,
  auth: PropTypes.shape({
    access: PropTypes.object,
    attempts: PropTypes.number,
    isPending: PropTypes.bool,
    user: PropTypes.object,
  }),
  autofill: PropTypes.func,
  blur: PropTypes.func,
  clearAsyncError: PropTypes.func,
  clearFields: PropTypes.func,
  clearSubmit: PropTypes.func,
  clearSubmitErrors: PropTypes.func,
  config: PropTypes.string,
  createdLot: PropTypes.object,
  destroy: PropTypes.func,
  dirty: PropTypes.bool,
  dispatch: PropTypes.func,
  error: PropTypes.oneOfType([PropTypes.object]),
  form: PropTypes.string,
  formValues: PropTypes.shape({
    materialTypeDeclared: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
    oneOrMultiLots: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
    packagesQuantity: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  handleSubmit: PropTypes.func,
  history: PropTypes.object,
  initialValues: PropTypes.shape({
    createShipment: PropTypes.object,
    defaultPackagesValues: PropTypes.shape({
      oneOrMultiLots: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
      packagesQuantity: PropTypes.number,
    }),
    generatePackingListValues: PropTypes.shape({
      exportToExcel: PropTypes.bool,
      printPackageLabels: PropTypes.bool,
      printPackingList: PropTypes.bool,
    }),
  }),
  initialize: PropTypes.func,
  initialized: PropTypes.bool,
  invalid: PropTypes.bool,
  isPackingList: PropTypes.bool,
  location: PropTypes.object,
  lotDetails: PropTypes.shape({
    isPending: PropTypes.bool,
    errorMessage: PropTypes.object,
    lot: PropTypes.object,
  }),
  lotList: PropTypes.shape({
    errorMessage: PropTypes.oneOfType([PropTypes.object]),
    isPending: PropTypes.bool,
    limit: PropTypes.number,
    lots: PropTypes.array,
    page: PropTypes.number,
    pages: PropTypes.number,
  }),
  match: PropTypes.object,
  materialTypeDeclard: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
  onSubmit: PropTypes.func,
  oneOrMultiLots: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  getLot: PropTypes.func,
  getAssignedLots: PropTypes.func,
  getLotsAvailableForReassignment: PropTypes.func,
  openModal: PropTypes.func,
  packageDetals: PropTypes.shape({
    errorMessage: PropTypes.oneOfType([PropTypes.object]),
    isPending: PropTypes.bool,
    package: PropTypes.object,
    recentlyCreated: PropTypes.bool,
  }),
  pristine: PropTypes.bool,
  pure: PropTypes.bool,
  reset: PropTypes.func,
  saveTemporaryData: PropTypes.func,
  staticConext: PropTypes.oneOfType([PropTypes.object]),
  submit: PropTypes.func,
  submitAsSideEffect: PropTypes.bool,
  submitFailed: PropTypes.bool,
  submitSucceeded: PropTypes.bool,
  submitting: PropTypes.bool,
  touch: PropTypes.func,
  triggerSubmit: PropTypes.oneOfType([PropTypes.func]),
  untouch: PropTypes.func,
  valid: PropTypes.bool,
  validate: PropTypes.func,
  warning: PropTypes.oneOfType([PropTypes.object]),
};

export { IncomingPackageForm };
