import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const forwardDocumentType = 'FORWARD_DOCUMENT';
const forwardDocumentAction = () => ({
  type: forwardDocumentType,
});

const forwardDocumentSuccessType = `${forwardDocumentType}_SUCCESS`;
const forwardDocumentSuccessAction = () => ({
  type: forwardDocumentSuccessType,
});

const forwardDocumentFailureType = `${forwardDocumentType}_FAILURE`;
const forwardDocumentFailureAction = () => ({
  type: forwardDocumentFailureType,
});

const forwardDocument = (fileId, recipients) => dispatch => {
  dispatch(forwardDocumentAction());

  return axios
    .post(`${API_HOST_AUTH}/files/${fileId}/forward`, recipients)
    .then(res => {
      dispatch(forwardDocumentSuccessAction(res.data));

      return res.data;
    })
    .catch(err => {
      dispatch(forwardDocumentFailureAction());

      return Promise.reject(err.data);
    });
};

export {
  forwardDocumentType,
  forwardDocumentSuccessType,
  forwardDocumentFailureType,
  forwardDocument,
};
