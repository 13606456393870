import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { getBankHolidays, clearBankHolidays } from 'actions/Payments/bankHolidaysList';
import { clearPaymentDetails, getPayment } from 'actions/Payments/paymentDetails';
import { updateDraftFinalPayment } from 'actions/Payments/updateDraftPayment';
import { openModal } from 'actions/shared/modal';
import { showSnackbar } from 'actions/shared/snackbar';

import { Unauthorized } from 'components/ErrorPage/ErrorPage';

import { FormContainer } from 'shared/components/Form';
import { updateDraftFinalPaymentAccess } from 'shared/helpers/accesses/payments/paymentAccesses';
import { PAYMENTS } from 'shared/helpers/constants/payments/paymentsConstants';
import { parseUpdateDraftFinalPayment } from 'shared/helpers/dataParsers/payments/parseUpdateDraftFinalPayment';
import { humanizeCamelCase } from 'shared/helpers/parsers/text';

import { EnterPaymentForm } from '../EnterPayment/EnterPaymentForm/EnterPaymentForm';

const UpdateDraftFinalPaymentLayout = props => {
  const [isPending, setIsPending] = useState(false);

  const { id } = props.match?.params;
  const { isPending: isPaymentDetailsPending, payment } = props.paymentDetails;

  useEffect(() => {
    props.getPayment(id);
    props.getBankHolidays({ year: '' });
  }, [id]);

  const submit = values => {
    setIsPending(true);

    props
      .updateDraftFinalPayment(id, parseUpdateDraftFinalPayment(values))
      .then(() => props.history.push('/payments'))
      .finally(() => setIsPending(false));
  };

  return (
    <FormContainer
      header={PAYMENTS.UPDATE_DRAFT_FINAL_PAYMENT(payment?.relatedLot?.grgLotNumber)}
      loaded={!isPending && !isPaymentDetailsPending}
    >
      {updateDraftFinalPaymentAccess(props.auth, payment) ? (
        <EnterPaymentForm {...props} onSubmit={submit} config={{ editing: true }} />
      ) : (
        <Unauthorized />
      )}
    </FormContainer>
  );
};

const mapStateToProps = state => {
  const { payment } = state.paymentDetails;

  return {
    auth: state.auth,
    enterPaymentDetails: state.enterPaymentDetails,
    assignedLots: state.assignedLots,
    availablePaymentTypes: state.availablePaymentTypes,
    paymentAmount: state.paymentAmount,
    bankHolidaysList: state.bankHolidaysList,
    paymentDetails: state.paymentDetails,
    initialValues: {
      paymentAmount: payment?.paymentAmount,
      requestedFinalPaymentDate: new Date(payment?.requestedFinalPaymentDate),
      relatedCompany: {
        value: payment?.relatedCompany?._id,
        label: payment?.relatedCompany?.companyName,
      },
      paymentType: {
        value: payment?.paymentType,
        label: humanizeCamelCase(payment?.paymentType),
      },
      relatedLot: {
        value: payment?.relatedLot?._id,
        label: payment?.relatedLot?.grgLotNumber,
      },
      additionalInfo: payment?.additionalInfo,
      fundsType: {
        value: payment?.fundsType,
        label: humanizeCamelCase(payment?.fundsType),
      },
    },
  };
};

const mapDispatchToProps = {
  openModal,
  getPayment,
  showSnackbar,
  updateDraftFinalPayment,
  getBankHolidays,
  clearBankHolidays,
  clearPaymentDetails,
};

const UpdateDraftFinalPayment = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UpdateDraftFinalPaymentLayout);

export { UpdateDraftFinalPaymentLayout, UpdateDraftFinalPayment };
