import styled, { css } from 'styled-components';

import { Button } from 'shared/components/Buttons/Button';
import { cssVar, media } from 'shared/helpers/styling/styling';

const OverviewListMobileDetails = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;

  & > div {
    border: 1px solid rgba(255, 255, 255, 0.1);
    flex-basis: ${({ fillContent }) => (fillContent ? '100%' : '50%')};
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px;
    color: ${cssVar('alabaster')};

    & > div {
      width: 50%;
    }

    ${({ logs }) =>
      logs &&
      css`
        ${media.phone`
        flex-direction: column;
        flex-basis: 100%;
        flex-grow: 0;
        align-items: flex-start;
      `}
      `}
  }
`;

const OverviewListMobileActions = styled.section`
  width: 60%;
  display: flex;
  justify-content: space-evenly;
  color: ${cssVar('alabaster')};
  margin-top: 1.5rem;

  ${media.smallTablet`
    width: 80%;
  `};

  ${media.phone`
    flex-direction: column;

    ${Button}{
      margin-bottom: 8px;
    }
  `}
`;

const OverviewItemsDetailsTitle = styled.div`
  opacity: 0.5;
  padding-right: 5px;
`;

export { OverviewListMobileActions, OverviewListMobileDetails, OverviewItemsDetailsTitle };
