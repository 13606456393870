import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const payoutRulesActionType = 'GET_PAYOUT_RULES';
const payoutRulesAction = () => ({
  type: payoutRulesActionType,
});

const payoutRulesSuccessActionType = `${payoutRulesActionType}_SUCCESS`;
const payoutRulesSuccessAction = data => ({
  type: payoutRulesSuccessActionType,
  payload: { data },
});

const payoutRulesFailureActionType = `${payoutRulesActionType}_FAILURE`;
const payoutRulesFailureAction = errorMessage => ({
  type: payoutRulesFailureActionType,
  payload: { errorMessage },
});

const clearPayoutRulesActionType = 'CLEAR_PAYOUT_RULES_ACTION';
const clearPayoutRules = () => ({ type: clearPayoutRulesActionType });

const getPayoutRules = () => dispatch => {
  dispatch(payoutRulesAction());

  return axios
    .get(`${API_HOST_AUTH}/payout-rules`)
    .then(({ data }) => dispatch(payoutRulesSuccessAction(data)))
    .catch(({ message }) => dispatch(payoutRulesFailureAction(message)));
};

export {
  getPayoutRules,
  payoutRulesActionType,
  payoutRulesSuccessActionType,
  payoutRulesFailureActionType,
  clearPayoutRulesActionType,
  clearPayoutRules,
};
