import { safeParseFloat, roundUp } from 'shared/helpers/parsers/number';

const initialRemainingOunces = {
  platinumOz: 0,
  palladiumOz: 0,
  rhodiumOz: 0,
};

const parseRemainingOunces = (remainingOunces, currentRelatedHedge) => {
  if (!currentRelatedHedge || !remainingOunces.length) {
    return initialRemainingOunces;
  }

  const { platinumOz, palladiumOz, rhodiumOz } = remainingOunces.reduce(
    (remaining, field) => ({
      platinumOz: remaining.platinumOz + +safeParseFloat(field.platinumOz),
      palladiumOz: remaining.palladiumOz + +safeParseFloat(field.palladiumOz),
      rhodiumOz: remaining.rhodiumOz + +safeParseFloat(field.rhodiumOz),
    }),
    { ...initialRemainingOunces },
  );

  return {
    platinumOz: +roundUp(platinumOz, 3),
    palladiumOz: +roundUp(palladiumOz, 3),
    rhodiumOz: +roundUp(rhodiumOz, 3),
  };
};

export { parseRemainingOunces };
