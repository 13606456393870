import styled from 'styled-components';

import { media } from 'shared/helpers/styling/styling';

export const StyledFormWrapperContainer = styled.div`
  text-align: center;
  height: 100%;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;

  &:before {
    content: ' ';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
`;

export const StyledFormWrapperContainerWrapper = styled.div`
  display: inline-block;
  vertical-align: middle;
  min-width: 540px;
  padding: 10px 15px;

  ${media.phone`
font-size: 0.7em;
      min-width: 100%;
`}

  ${media.tablet`
width: 540px;
`}
`;
export const StyledFormWrapperContainerContent = styled.div`
  background: var(--outerSpace);
  padding: 50px;

  ${media.phone`
padding: 40px 16px 80px;
`}
`;

export const StyledFormWrapperContainerLogo = styled.div`
  width: 100%;
  text-align: center;

  img {
    height: 48px;
  }

  ${media.phone`
img {
            height: 27px;
          }
`}
`;

export const StyledFormWrapperContainerChildren = styled.div`
  margin-top: 30px;
`;

export const StyledFormWrapperContainerButton = styled.div`
  float: right;
`;
