import { getMarketIndicatorsReducer } from './getMarketIndicators';
import { getMarketIndicatorsLogsReducer } from './getMarketIndicatorsLogs';
import { lockMarketIndicatorsReducer } from './lockMarketIndicators';
import { requestMarketIndicatorsReducer } from './requestMarketIndicators';
import { updateMarketIndicatorsReducer } from './updateMarketIndicators';

const marketIndicatorsRootReducer = {
  getMarketIndicators: getMarketIndicatorsReducer,
  requestMarketIndicators: requestMarketIndicatorsReducer,
  lockMarketIndicators: lockMarketIndicatorsReducer,
  updateMarketIndicators: updateMarketIndicatorsReducer,
  getMarketIndicatorsLogs: getMarketIndicatorsLogsReducer,
};

export { marketIndicatorsRootReducer };
