const ENTER_PRICES = {
  PLATINUM_PRICE_EMPTY: 'Platinum price cannot be empty.',
  PALLADIUM_PRICE_EMPTY: 'Palladium price cannot be empty.',
  RHODIUM_PRICE_EMPTY: 'Rhodium price cannot be empty.',
  PLATINUM_PRICE_INVALID: 'Platinum price must have a valid format.',
  PALLADIUM_PRICE_INVALID: 'Palladium price must have a valid format.',
  RHODIUM_PRICE_INVALID: 'Rhodium price must have a valid format.',
  PLATINUM_PRICE_NEGATIVE: 'Platinum price cannot be less or equal to 0.',
  PALLADIUM_PRICE_NEGATIVE: 'Palladium price cannot be less or equal to 0.',
  RHODIUM_PRICE_NEGATIVE: 'Rhodium price cannot be less or equal to 0.',
  PLATINUM_PRICE_DO_NOT_MATCH: 'Platinum prices do not match.',
  PALLADIUM_PRICE_DO_NOT_MATCH: 'Palladium prices do not match.',
  RHODIUM_PRICE_DO_NOT_MATCH: 'Rhodium prices do not match.',
};

export { ENTER_PRICES };
