import styled from 'styled-components';

import { media } from 'shared/helpers/styling/styling';

export const StyledPackagesOverviewWrapper = styled.div`
  .OverviewList {
    margin-top: 20px;

    &-mainPanel {
      display: flex;
      justify-content: space-between;

      h3 {
        color: var(--gullGray);
      }
    }
    ${media.phone`
  &-list {
      margin-top: 15px;
    }

    &-mainPanel {
      h3 {
        font-size: 1.25em;
      }
    }
  `}

    ${media.tablet`
  &-list {
      margin-top: 15px;
    }

    &-mainPanel {
      h3 {
        font-size: 1.25em;
      }
    }
  `}
  }
`;
