import styled from 'styled-components';

import { cssVar } from 'shared/helpers/styling/styling';

const StyledOverviewTable = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${cssVar('fiord')};

  /* Safari print hack for flex */
  @media print {
    display: inline-block;
    width: 100%;
  }
`;

export { StyledOverviewTable };
