import {
  getHedgeTotalValueActionType,
  getHedgeTotalValueFailureActionType,
  getHedgeTotalValueSuccessActionType,
  clearHedgeTotalValueActionType,
} from 'actions/Hedges/getHedgeTotalValue';

const INITIAL_STATE = {
  isPending: true,
  hedgeTotal: null,
};

const getHedgeTotalValueReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getHedgeTotalValueActionType:
      return {
        ...state,
        isPending: true,
      };
    case getHedgeTotalValueSuccessActionType:
      return {
        isPending: false,
        hedgeTotal: action.payload.hedgeTotal,
      };
    case getHedgeTotalValueFailureActionType:
      return {
        isPending: false,
        hedgeTotal: null,
      };
    case clearHedgeTotalValueActionType:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};

export { getHedgeTotalValueReducer };
