import axios from 'axios';

import { showSnackbar } from 'actions/shared/snackbar';

import {
  successOptions,
  dangerOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { parseTermData } from 'shared/helpers/dataParsers/terms/parseTermData';

import { history } from 'utils/history';

const API_HOST_AUTH = process.env.REACT_APP_API;

const updateTermsActionType = 'UPDATE_TERM';
function updateTermsAction() {
  return {
    type: updateTermsActionType,
  };
}

const updateTermsSuccessActionType = 'UPDATE_TERM_SUCCESS';
function updateTermsSuccessAction() {
  return {
    type: updateTermsSuccessActionType,
  };
}

const updateTermsFailureActionType = 'UPDATE_TERM_FAILURE';
function updateTermsFailureAction() {
  return {
    type: updateTermsFailureActionType,
  };
}

function updateTerms(id, term, companyId) {
  return dispatch => {
    dispatch(updateTermsAction());

    return axios({
      method: 'put',
      url: `${API_HOST_AUTH}/terms/${id}`,
      data: JSON.stringify(parseTermData(term)),
    })
      .then(res => {
        dispatch(updateTermsSuccessAction());
        dispatch(showSnackbar(successOptions, res.data.message));
        history.push(`/companies/${companyId}/terms/${id}`);
      })
      .catch(error => {
        dispatch(updateTermsFailureAction());
        dispatch(showSnackbar(dangerOptions, `Term has not been updated. ${error.data.message}`));
      });
  };
}

export {
  updateTermsActionType,
  updateTermsSuccessActionType,
  updateTermsFailureActionType,
  updateTerms,
};
