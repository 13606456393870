import styled from 'styled-components';

import { pxToRem } from 'shared/helpers/styling/styling';

export const LogsActionWrapper = styled.div`
  width: 100%;
`;

export const LogsActionHeader = styled.h3`
  font-size: ${pxToRem(16)};
  padding: 15px;
  margin: 0;
  font-weight: bold;
`;

export const LogsActionContent = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const LogsActionItemWrapper = styled.div`
  flex: 1;
  border-right: 1px dashed rgba(255, 255, 255, 0.1);
  border-left: ${({ withoutBorderLeft }) =>
    !withoutBorderLeft && '1px dashed rgba(255, 255, 255, 0.1)'};
  }
`;

export const LogsActionItem = styled.div`
  display: flex;
  padding: ${pxToRem(6)} ${pxToRem(15)};
  border-top: 1px dashed rgba(255, 255, 255, 0.1);
  &:last-child {
    border-bottom: 1px dashed rgba(255, 255, 255, 0.1);
  }
`;
