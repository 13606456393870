function normalizeObject(object, normalizer = v => v) {
  return Object.entries(object).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: normalizer(value),
    }),
    {},
  );
}

export { normalizeObject };
