import React from 'react';
import styled from 'styled-components';

import { CancelButton } from 'shared/components/Buttons/CancelButton/CancelButton';
import { SubmitButton } from 'shared/components/Buttons/SubmitButton/SubmitButton';
import { cssVar, media } from 'shared/helpers/styling/styling';

const Actions = ({ className, CustomActions, ...props }) =>
  CustomActions ? (
    <CustomActions {...props} />
  ) : (
    <div className={className}>
      <SubmitButton label={props.label} disabled={props.submitDisabled} />
      <CancelButton onClick={props.onCancel} />
    </div>
  );

const ActionsStyled = styled(Actions)`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  height: 150px;
  border-top: 1px solid ${cssVar('outerSpaceFormBg')};

  ${media.mobile`
    padding: 0 25px;
  `};

  @media print {
    display: none;
  }
`;

export { ActionsStyled };
