import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  lotsClearAllFiles,
  lotsAddMultipleDocuments,
  lotsRemoveMultipleFiles,
} from 'actions/Lots/lotsAddFiles';
import { uploadBillOfLading } from 'actions/Lots/uploadBillOfLading';
import { showSnackbar } from 'actions/shared/snackbar';

import { ModalCustom } from 'components/ModalCustom/ModalCustom';

import { Preloader } from 'shared/components/Preloader/Preloader';
import { FILES_CONSTANTS } from 'shared/helpers/constants/filesConstants';
import {
  successOptions,
  dangerOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import {
  parseLotsFilesToSend,
  parseExistingFile,
} from 'shared/helpers/dataParsers/files/parseMultipleFiles';

import { UploadBillOfLadingContent } from './components/UploadBillOfLadingContent/UploadBillOfLadingContent';

const UploadBillOfLading = ({ open, lot, onSuccess, toggle }) => {
  const dispatch = useDispatch();

  const [blockSubmit, setBlockSubmit] = useState(false);
  const { isPending } = useSelector(({ uploadBillOfLadingFile }) => uploadBillOfLadingFile);
  const lotsFiles = useSelector(({ lotsFiles }) => lotsFiles);
  const lotDetails = useSelector(({ lotDetails }) => lotDetails);

  useEffect(() => {
    lotDetails?.lot && addMultipleDocuments({ billOfLading: lotDetails.lot.billOfLading });
    return () => dispatch(lotsClearAllFiles());
  }, [lotDetails]);

  const addMultipleDocuments = files => {
    Object.entries(files).forEach(
      ([name, file]) =>
        file?.length &&
        dispatch(
          lotsAddMultipleDocuments({
            name,
            files: file.map(file => ({
              existingFile: parseExistingFile(file),
            })),
          }),
        ),
    );
  };

  const uploadFiles = async () => {
    const filesToSend = parseLotsFilesToSend(lotsFiles);

    try {
      const res = await dispatch(uploadBillOfLading(lot._id, filesToSend));
      dispatch(showSnackbar(successOptions, res.message));
      toggle();
      onSuccess();
    } catch (err) {
      dispatch(showSnackbar(dangerOptions, err.message));
      toggle();
    }
  };

  const checkMultipleFilesError = ({ hasError }) => setBlockSubmit(hasError);

  const onDelete =
    ({ type, fileIndex }) =>
    () =>
      dispatch(lotsRemoveMultipleFiles({ type, fileIndex }));

  return (
    <ModalCustom isOpen={open} modalClose={toggle}>
      <UploadBillOfLadingContent
        onSubmit={uploadFiles}
        grgLotNumber={lot.customLotNumber || lot.grgLotNumber}
        lotsFiles={lotsFiles}
        checkMultipleFilesError={checkMultipleFilesError}
        blockSubmit={blockSubmit}
        onDelete={onDelete}
      />
      <Preloader loading={isPending} label={FILES_CONSTANTS.UPLOADING_FILES} />
    </ModalCustom>
  );
};

UploadBillOfLading.propTypes = {
  open: PropTypes.bool,
  lot: PropTypes.object,
  onSuccess: PropTypes.func,
  toggle: PropTypes.func,
};

export { UploadBillOfLading };
