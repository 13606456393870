import styled from 'styled-components';

import { cssVar } from 'shared/helpers/styling/styling';

const TablePadding = styled.div`
  padding: ${({ padding }) => padding};
  background-color: ${cssVar('riverBedDarker')};
`;
const TableTitle = styled.div`
  background-color: ${cssVar('outerSpaceListAppend')};
  padding: 10px 20px;
  font-size: 0.8rem;
  color: ${cssVar('gullGray')};
`;

export { TablePadding, TableTitle };
