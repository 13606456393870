const messages = {
  INVALID_LOCATION_NAME: 'Location name must have a valid format.',
  INVALID_LOCATION_NAME_EMPTY: 'Location name cannot be empty.',
  INVALID_SUITE: 'Suite must have a valid format.',
  INVALID_PO_BOX: 'PO Box must have a valid format.',
  INVALID_COUNTRY_EMPTY: 'Country cannot be empty.',
  INVALID_STATE_EMPTY: 'State cannot be empty.',
  INVALID_STATE: 'State must have a valid format.',
  INVALID_ZIP_CODE: 'Zip code must have a valid format.',
  INVALID_ZIP_CODE_EMPTY: 'Zip code cannot be empty.',
  INVALID_CITY_EMPTY: 'City cannot be empty.',
  INVALID_CITY: 'City must have a valid format.',
  INVALID_STREET_EMPTY: 'Street cannot be empty.',
  INVALID_STREET: 'Street must have a valid format.',
  INVALID_LOT_SUFFIX_EMPTY: 'Lot suffix cannot be empty.',
  INVALID_LOT_SUFFIX: 'Lot suffix must have a valid format.',
  INVALID_LOT_SUFFIX_UNIQUE: 'Lot suffix must be unique.',
  INVALID_SHIPMENT_ELIGIBLE_EMPTY: 'Shipment eligible cannot be empty.',
  INVALID_PRIMARY_LOCATION_EMPTY: 'Primary location cannot be empty.',
  INVALID_PRIMARY_CONTACT: 'Primary contact cannot be empty.',
  INVALID_PRIMARY_CONTACT_NOT_ALLOWED: 'Selected user cannot be assigned as a primary contact.',
};

export { messages };
