import axios from 'axios';
import { saveAs } from 'file-saver';

import { PAYMENTS } from 'shared/helpers/constants/payments/paymentsConstants';

const API_HOST_AUTH = process.env.REACT_APP_API;

const createSettlementDocumentType = 'CREATE_SETTLEMENT_DOCUMENT';
const createSettlementDocumentAction = () => ({
  type: createSettlementDocumentType,
});

const createSettlementDocumentSuccessType = `${createSettlementDocumentType}_SUCCESS`;
const createSettlementDocumentSuccessAction = () => ({
  type: createSettlementDocumentSuccessType,
});

const createSettlementDocumentFailureType = `${createSettlementDocumentType}_FAILURE`;
const createSettlementDocumentFailureAction = () => ({
  type: createSettlementDocumentFailureType,
});

const createSettlementDocument = paymentId => dispatch => {
  dispatch(createSettlementDocumentAction());

  return axios
    .post(`${API_HOST_AUTH}/payments/create-settlement/${paymentId}`, null, {
      responseType: 'arraybuffer',
    })
    .then(res => {
      dispatch(createSettlementDocumentSuccessAction());

      saveAs(
        new Blob([res.data], { type: res.headers['content-type'] }),
        'poolAccountSettlement.pdf',
      );

      return { message: PAYMENTS.CREATE_SETTLEMENT_SUCCESS };
    })
    .catch(() => {
      dispatch(createSettlementDocumentFailureAction());

      return Promise.reject({ message: PAYMENTS.CREATE_SETTLEMENT_FAILURE });
    });
};

export {
  createSettlementDocumentType,
  createSettlementDocumentSuccessType,
  createSettlementDocumentFailureType,
  createSettlementDocument,
};
