import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { FieldUploadList } from 'shared/components/Fields/FiledUpload/FieldUploadList';
import { FieldUpload } from 'shared/components/Formik/Fields';
import { FILES_CONSTANTS } from 'shared/helpers/constants/filesConstants';
import { validateAttachment } from 'shared/helpers/validations/attachment/validateAttachment';

const { rtf, ...selectedExtensions } = FILES_CONSTANTS;

const FieldAttachment = ({
  id,
  attachment,
  addFile,
  label,
  name,
  removeFile,
  multiple,
  filesError,
  location,
}) => {
  let hasError = null;

  const handleUploadFile = ({ target }) => addFile([].slice.call(target.files));
  const isLengthValid = () => attachment.files.length <= FILES_CONSTANTS.LIMIT;

  const files = (attachment?.files || []).map((file, id) => {
    const error = validateAttachment(file, Object.values(FILES_CONSTANTS.VALID_EXTENSIONS))
      ? null
      : FILES_CONSTANTS.ERROR_SIZE;

    hasError = hasError || error;

    return {
      file: {
        name: file.name,
        meta: { error, dirty: true },
        keyName: `${file.originalName || file.name}-${id}`,
      },
      fileToUpload: file,
      onDelete: () => removeFile(file),
    };
  });

  const error = isLengthValid() ? hasError : FILES_CONSTANTS.ERROR_LENGTH(FILES_CONSTANTS.LIMIT);

  useEffect(() => {
    filesError(hasError);

    return () => {
      hasError = null;
    };
  }, [hasError]);

  return (
    <>
      <FieldUpload
        id={id}
        label={label}
        onChange={handleUploadFile}
        meta={{ error, dirty: true }}
        name={name}
        multiple={multiple}
        validExtensions={
          location.state?.validExtensions || Object.values(selectedExtensions).join(', ')
        }
      />
      <FieldUploadList files={files} />
    </>
  );
};

FieldAttachment.propTypes = {
  id: PropTypes.string,
  addFile: PropTypes.func,
  filesError: PropTypes.func,
  attachment: PropTypes.shape({
    files: PropTypes.array,
    invalidFiles: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  }),
  label: PropTypes.string,
  name: PropTypes.string,
};

export { FieldAttachment };
