import { FormikProvider } from 'formik';
import PropTypes from 'prop-types';
import { useContext } from 'react';

import { SessionSnackbar } from 'components/SessionSnackbar/SessionSnackbar';

import {
  FormContext,
  ContentLoaderStyled,
} from 'shared/components/Form/FormContainer/FormContainer';
import { FORM } from 'shared/components/Form/constants';
import { WizardContext } from 'shared/components/Formik/Wizard/Wizard';
import { useEventListener } from 'shared/hooks/useEventListener';
import { useScrollTop } from 'shared/hooks/useScrollTop';

import { ActionsStyled } from '../index';
import { Header, StyledForm } from './StyledForm';

const Form = ({
  className,
  loaded,
  submitButtonLabel,
  submitDisabled,
  onCancel,
  children,
  CustomActions,
  wide,
  context,
  onSubmit,
  ...props
}) => {
  useScrollTop();

  const wizardContextForm = useContext(WizardContext);
  const formLoaded = useContext(FormContext);

  const cancelOnEsc = event => {
    if (event.key === 'Escape') {
      return onCancel?.(event);
    }
  };
  useEventListener('keydown', cancelOnEsc);

  return (
    <>
      <SessionSnackbar />
      <StyledForm wide={wide}>
        <ContentLoaderStyled loaded={!!loaded || formLoaded}>
          <FormikProvider value={context}>
            <form className={className} onSubmit={onSubmit} noValidate>
              <Header id="formHeader">{props.header}</Header>
              <div className="form-content">{children}</div>
              <ActionsStyled
                label={submitButtonLabel}
                submitDisabled={submitDisabled}
                onCancel={onCancel}
                CustomActions={
                  CustomActions || (wizardContextForm && wizardContextForm.CustomActions)
                }
              />
            </form>
          </FormikProvider>
        </ContentLoaderStyled>
      </StyledForm>
    </>
  );
};

Form.propTypes = {
  header: PropTypes.string,
  submitButtonLabel: PropTypes.string,
  onCancel: PropTypes.func,
  CustomActions: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  submitDisabled: PropTypes.bool,
  loaded: PropTypes.any,
  wide: PropTypes.bool,
  context: PropTypes.shape({
    values: PropTypes.object,
    isValid: PropTypes.bool,
    isDirty: PropTypes.bool,
  }),
};

Form.defaultProps = {
  submitButtonLabel: FORM.SUBMIT,
  submitDisabled: false,
  loaded: true,
  wide: false,
};

export { Form };
