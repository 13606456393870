import LocationDetailsUsersListReducer from './Details/usersList';
import checkLotSuffixReducer from './checkLotSuffix';
import CreateLocationReducer from './createLocation';
import getCompaniesContactReducer from './getCompaniesContact';
import getCompanyLocationsReducer from './getCompanyLocations';
import getRegionListReducer from './getRegionList';
import LocationDetailsReducer from './locationDetails';
import LocationFiltersReducer from './locationFilters';
import LocationsListReducer from './locationsList';
import UpdateLocationReducer from './updateLocation';

const locationsRootReducer = {
  createLocation: CreateLocationReducer,
  updateLocation: UpdateLocationReducer,
  companyLocations: getCompanyLocationsReducer,
  regionList: getRegionListReducer,
  companiesContact: getCompaniesContactReducer,
  locationLotSuffix: checkLotSuffixReducer,
  locationDetails: LocationDetailsReducer,
  locationsList: LocationsListReducer,
  locationFilters: LocationFiltersReducer,
  locationDetailsUsersList: LocationDetailsUsersListReducer,
};

export { locationsRootReducer };
