import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

import { Icon } from 'shared/components/Icon/Icon';
import { cssVar, media } from 'shared/helpers/styling/styling';

const ButtonLayout = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: ${({ hasIcon }) => (hasIcon ? 'space-between' : 'center')};
  align-items: center;
  width: 90px;
  height: 100px;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  border: none;
  padding: 10px 5px;

  &:first-child {
    border-radius: 17px 0 0 17px;
  }

  &:last-child {
    border-radius: 0 17px 17px 0;
  }

  &:not(:last-of-type):after {
    content: '';
    position: absolute;
    transform: translateX(45px);
    width: 1px;
    height: 80px;
    background-color: ${cssVar('limedSpruceLightest')};
  }

  ${media.mobile`
    &:nth-child(even):not(:last-of-type):after {
      transform: rotate(90deg) translateX(15px) translateY(45px);
      height: 150px;
    }

  ${({ isOnly }) =>
    !isOnly &&
    css`
      &:first-child {
        border-radius: 17px 0 0 0;
      }
      &:nth-child(2) {
        border-radius: 0 17px 0 0;
      }
      &:nth-last-child(2):nth-child(odd) {
        border-radius: 0 0 0 17px;
      }
      &:last-child:nth-child(even) {
        border-radius: 0 0 17px 0;
      }
      &:last-child:nth-child(odd) {
        border-radius: 0 0 0 17px;
      }

      &:first-child:nth-last-child(2) {
        border-radius: 17px 0 0 17px;
      }

      &:last-child:nth-child(2) {
        border-radius: 0 17px 17px 0;
      }
    `}
  `}

  &:hover {
    background-color: ${cssVar('limedSpruceLighter')};

    ${({ isOnly }) =>
      isOnly &&
      css`
        border-radius: 17px;
      `}
  }

  &:focus {
    outline: none;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.5;

      &:hover {
        background-color: transparent;
      }
    `}
`;

const Label = styled.p`
  display: flex;
  align-items: center;
  height: ${({ icon }) => (icon ? '45px' : '100%')};
  margin: 0;
  font-size: 12px;
  color: ${cssVar('edward')};
`;

const GroupButton = ({ access, onClick, icon, label, disabled, only }) =>
  access && (
    <ButtonLayout onClick={onClick} disabled={disabled} isOnly={only} hasIcon={!!icon}>
      {icon && <Icon icon={`icon-${icon}`} width={42} height={35} />}
      <Label icon={icon}>{label}</Label>
    </ButtonLayout>
  );

GroupButton.propTypes = {
  access: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  onClick: PropTypes.func,
  icon: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  only: PropTypes.bool,
};

export { GroupButton, ButtonLayout, Label };
