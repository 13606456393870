import React, { forwardRef, useRef, useCallback, useImperativeHandle } from 'react';
import styled from 'styled-components';

import { Icon } from 'shared/components/Icon/Icon';

const InputWrapper = styled.span`
  position: relative;

  ${Icon} {
    position: absolute;
    right: 10px;
    top: 10px;
    height: 30px;
  }
`;

const Input = forwardRef(({ hideIcon, icon, ...props }, ref) => {
  const inputRef = useRef(null);

  const onIconClick = useCallback(() => inputRef.current.focus(), []);
  useImperativeHandle(ref, () => inputRef);

  return (
    <InputWrapper>
      <input {...props} ref={inputRef} id={props.name} readOnly />
      {!hideIcon && <Icon icon={icon} height={50} as="label" onClick={onIconClick} />}
    </InputWrapper>
  );
});

Input.defaultProps = {
  icon: 'icon-calendar',
};

export { Input };
