const userStatuses = {
  active: 'active',
  inactive: 'inactive',
  no_terms: 'no_terms',
};

const userTypesOwner = {
  owner: 'owner',
  support: 'support',
  warehouse: 'warehouse',
  customer: 'customer',
  grading: 'grading',
  external: 'external',
  buyer: 'buyer',
};

const userTypesSupport = {
  customer: 'customer',
  grading: 'grading',
};

const accessLevels = {
  owner: {
    standard: 'standard',
    assist: 'assist',
  },
  support: {
    standard: 'standard',
    assist: 'assist',
  },
  warehouse: {
    standard: 'standard',
    assist: 'assist',
    limited: 'limited',
  },
  customer: {
    full_hedging: 'full_hedging',
    limited_hedging: 'limited_hedging',
    view_and_ship: 'view_and_ship',
    ship_only: 'ship_only',
  },
  grading: {
    standard: 'standard',
  },
  external: {
    standard: 'standard',
  },
  buyer: {
    standard: 'standard',
  },
};

export { userStatuses, userTypesOwner, userTypesSupport, accessLevels };
