import {
  acceptForMixingType,
  acceptForMixingSuccessType,
  acceptForMixingFailureType,
} from 'actions/Lots/acceptForMixing';

const INITIAL_STATE = {
  isPending: false,
};

const acceptForMixingReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case acceptForMixingType:
      return {
        isPending: true,
      };
    case acceptForMixingSuccessType:
    case acceptForMixingFailureType:
      return {
        isPending: false,
      };
    default:
      return state;
  }
};

export { acceptForMixingReducer };
