import axios from 'axios';

import { setLogoutTimeout } from './authentication/logoutTimeout';
import { setSessionExpirationInfo } from './authentication/setSessionExpirationInfo';
import { validateSignedUser } from './authentication/validateSignedUser';

const requestInterceptor = axios.interceptors.request.use(
  config => {
    config.headers['Content-Type'] = 'application/json; charset=utf-8';
    config.withCredentials = true;
    setSessionExpirationInfo();
    setLogoutTimeout();
    validateSignedUser();

    return config;
  },
  error => Promise.reject(error),
);

export { requestInterceptor };
