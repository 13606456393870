import React, { useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { Field } from 'redux-form';

import { FieldSelect } from 'shared/components/Fields';
import { Section } from 'shared/components/Form/Section/Section';
import { companyDetailsCreateUserTypes } from 'shared/helpers/accesses/config/companyDetailsCreateUserTypes';
import { userHierarchy } from 'shared/helpers/accesses/config/createAccessLevels';
import { createUserTypes } from 'shared/helpers/accesses/config/createUserTypes';
import { globalRefiningGroupName } from 'shared/helpers/constants/companies/globalRefiningGroup';
import { USER } from 'shared/helpers/constants/users/userConstants';
import { generateName } from 'shared/helpers/forms/generateName';
import { isInternalCompany } from 'shared/helpers/matchers/checkCompanyType';
import { mapArray } from 'shared/helpers/parsers/select';

import { resolveVersion, versions } from 'utils/versioning';

import { AccessLevel } from './components/AccessLevel';

function SelectUserRoleBase({
  formName,
  auth: { user, access },
  match: { params },
  userType,
  companyDetails,
  editMode,
  change,
  children,
}) {
  const userTypes = useMemo(() => {
    const { userType: currentUserType, accessLevel } = user;
    const company = companyDetails.company;

    let detailsUserTypes = createUserTypes[currentUserType][accessLevel];

    if (company) {
      const { companyName, companyType } = company;
      detailsUserTypes = companyDetailsCreateUserTypes[currentUserType][accessLevel][companyType];

      if (companyName === globalRefiningGroupName && isInternalCompany(companyType)) {
        return mapArray(detailsUserTypes[companyName.replace(/ /g, '')]);
      } else if (companyName !== globalRefiningGroupName && isInternalCompany(companyType)) {
        return mapArray(detailsUserTypes.internalCompanies);
      }
    }

    if (!access.companies.gradingEnabled) {
      detailsUserTypes = detailsUserTypes.filter(type => type !== 'grading');
    }

    return mapArray(detailsUserTypes);
  }, [user, companyDetails]);

  const accessLevels = useMemo(() => userType && userHierarchy.accessLevel[userType], [userType]);

  const displayAccessLevel = resolveVersion({
    [versions.alpha]: true,
    [versions.global]: true,
    [versions.west]: true,
  });

  const onUserTypeChange = useCallback(() => {
    const companyId = params.companyId;

    change('databaseAccess', null);
    change('purchasingAppAccess', null);
    change('accessLevel', null);
    companyId || change('relatedCompany', null);
    change('location', null);
  }, [change, params]);

  return (
    <Section
      header={USER.ACCESS_LEVEL}
      template={[
        `${generateName(formName, 'userType')} .`,
        `${generateName(formName, 'accessLevel')} ${generateName(formName, 'accessLevel')}`,
        `${generateName(formName, 'databaseAccess')} ${generateName(
          formName,
          'purchasingAppAccess',
        )}`,
      ]}
      unwrap
    >
      <Field
        name={generateName(formName, 'userType')}
        component={FieldSelect}
        disabled={editMode}
        label={USER.USER_TYPE}
        options={userTypes}
        field="required"
        onChange={onUserTypeChange}
      />
      {displayAccessLevel && userType && (
        <AccessLevel
          formName={formName}
          name={generateName(formName, 'accessLevel')}
          options={accessLevels}
        />
      )}
      {children}
    </Section>
  );
}

const mapStateToProps = state => ({
  auth: state.auth,
});

const SelectUserRole = compose(connect(mapStateToProps), withRouter)(SelectUserRoleBase);

export { SelectUserRole };
