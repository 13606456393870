import {
  extendMachineNameProcessing,
  extendMachineNameMixing,
} from 'shared/helpers/dataParsers/lot/lotOverviewData';
import { cssVar } from 'shared/helpers/styling/styling';

import { isGlobal } from 'utils/versioning/Guards';

import { processViaOptions } from './processingConstants';

const customerSectionsEnum = {
  materialDeliveryRequired: 'materialDeliveryRequired',
  open: 'open',
  received: 'received',
  summaryAvailable: 'summaryAvailable',
  settled: 'settled',
  finalized: 'finalized',
};

const gradingSectionsEnum = {
  open: 'open',
  received: 'received',
  receiptReady: 'receiptReady',
  receiptAccepted: 'receiptAccepted',
  finalized: 'finalized',
};

const globalSectionsEnum = {
  incomingLots: 'incomingLots',
  processingOverview: 'processingOverview',
  mixingOverview: 'mixingOverview',
  outgoingLots: 'outgoingLots',
};

const warehouseDashboardLabels = {
  allAssayLotsInPossession: 'All assay lots in possession',
  todaysGradingTransactions: "Today's grading transactions",
  lotsPartlyReceivedOrOnHold: 'Lots partly received or on hold',
};

const customerSections = Object.values(customerSectionsEnum);
const gradingSections = Object.values(gradingSectionsEnum);

const globalSections = (gradingEnabled = true) => [
  {
    name: globalSectionsEnum.incomingLots,
    route: 'incoming lot overview',
    path: '/lots/incoming',
    legend: [
      { label: 'Partly received', color: 'riverBedLegend' },
      { label: 'Received - on hold', color: 'trout' },
      { label: 'Available', color: 'lynchLegend' },
      gradingEnabled ?? { label: 'Grading', color: 'beaver' },
    ].filter(Boolean),
    parts: [
      {
        part: 'partlyReceived',
        header: 'Partly received',
        map: ({ grgLotNumber }) => grgLotNumber,
        color: cssVar('riverBedLegend'),
        plural: 'Lots',
        empty: 'No lots',
        icon: 'icon-eye',
        path: '/lots/incoming#partlyReceived',
        navigate: ({ _id }) => `/lots/${_id}`,
      },
      {
        part: 'onHold',
        header: 'Received - on hold',
        map: ({ grgLotNumber }) => grgLotNumber,
        color: cssVar('trout'),
        plural: 'Lots',
        empty: 'No lots',
        icon: 'icon-eye',
        path: '/lots/incoming#receivedOnHold',
        navigate: ({ _id }) => `/lots/${_id}`,
      },
      gradingEnabled && {
        part: 'gradingRequired',
        header: 'Received - grading required',
        map: ({ grgLotNumber }) => grgLotNumber,
        color: [cssVar('beaver'), cssVar('blueBayoux')],
        plural: 'Lots',
        empty: 'No lots',
        icon: ['icon-process', 'icon-eye'],
        path: '/lots/incoming#receivedGradingRequired',
        navigate: ({ _id }) => `/lots/${_id}`,
      },
      {
        part: 'materialCount',
        header: 'Received - material count',
        map: ({ grgLotNumber }) => grgLotNumber,
        color: cssVar('blueBayoux'),
        plural: 'Lots',
        empty: 'No lots',
        icon: 'icon-eye',
        path: '/lots/incoming#receivedMaterialCount',
        navigate: ({ _id }) => `/lots/${_id}`,
      },
      {
        part: 'removeSamples',
        header: 'Received - remove samples',
        map: ({ grgLotNumber }) => grgLotNumber,
        color: cssVar('blueBayoux'),
        plural: 'Lots',
        empty: 'No lots',
        icon: 'icon-eye',
        path: '/lots/incoming#receivedRemoveSamples',
        navigate: ({ _id }) => `/lots/${_id}`,
      },
    ].filter(Boolean),
  },
  {
    name: globalSectionsEnum.processingOverview,
    route: 'processing lot overview',
    path: '/lots/processing',
    legend: [
      { label: 'Available', color: 'lynchLegend' },
      { label: 'Processing', color: 'kashmirBlue' },
    ],
    parts: [
      {
        part: 'available',
        header: 'Available',
        map: ({ grgLotNumber }) => grgLotNumber,
        color: cssVar('lynchLegend'),
        plural: 'Lots',
        empty: 'No lots',
        icon: 'icon-eye',
        path: '/lots/processing',
        navigate: ({ _id }) => `/lots/${_id}`,
      },
      ...Object.values(processViaOptions.processing).map(machine => ({
        part: machine,
        header: extendMachineNameProcessing(machine),
        headerIcon: 'icon-cart',
        map: ({ grgLotNumber }) => grgLotNumber,
        color: [cssVar('kashmirBlue'), cssVar('lynchLegend')],
        plural: 'Lots',
        empty: ['Free slot', 'No lots'],
        icon: ['icon-process', 'icon-eye'],
        path: '/lots/processing',
        navigate: ({ _id }) => `/lots/${_id}`,
      })),
    ],
  },
  isGlobal && {
    name: globalSectionsEnum.mixingOverview,
    route: 'mixing lot overview',
    path: '/lots/mixing',
    legend: [
      { label: 'Available', color: 'lynchLegend' },
      { label: 'Mixing', color: 'kashmirBlue' },
    ],
    parts: [
      {
        part: 'available',
        header: 'Available',
        map: ({ grgLotNumber }) => grgLotNumber,
        color: cssVar('riverBedLegend'),
        plural: 'Lots',
        empty: 'No lots',
        icon: 'icon-eye',
        path: '/lots/mixing',
        navigate: ({ _id }) => `/lots/${_id}`,
      },
      ...Object.values(processViaOptions.mills).map(machine => ({
        part: machine,
        header: extendMachineNameMixing(machine),
        headerIcon: 'icon-cart',
        map: ({ grgLotNumber }) => grgLotNumber,
        color: [cssVar('kashmirBlue'), cssVar('blueBayoux')],
        plural: 'Lots',
        empty: ['Free slot', 'No lots'],
        icon: ['icon-process', 'icon-eye'],
        path: '/lots/mixing',
        navigate: ({ _id }) => `/lots/${_id}`,
      })),
    ],
  },
  {
    name: globalSectionsEnum.outgoingLots,
    route: 'outgoing lot overview',
    path: '/lots/outgoing',
    legend: [
      { label: 'Unsent', color: 'boulderLegend' },
      { label: 'Ready to ship', color: 'blueMarguerite' },
      { label: 'Shipped', color: 'amethyst' },
    ],
    parts: [
      {
        part: 'unsentShipments',
        header: 'Unsent shipments',
        map: () => 'Shipment',
        color: cssVar('boulderLegend'),
        plural: 'Shipments',
        empty: 'No shipments',
        icon: 'icon-eye',
        path: '/lots/outgoing#unsentShipments',
        navigate: ({ _id }) => `/shipments/${_id}`,
      },
      {
        part: 'readyToShip',
        header: 'Ready to ship',
        headerIcon: 'icon-car-moving',
        map: ({ grgLotNumber }) => grgLotNumber,
        color: cssVar('blueMarguerite'),
        plural: 'Lots',
        empty: 'No lots',
        icon: 'icon-eye',
        path: '/lots/outgoing#readyToShip',
        navigate: ({ _id }) => `/lots/${_id}`,
      },
      {
        part: 'shippedFromGlobal',
        header: 'Shipped from Global',
        headerIcon: 'icon-car-moving',
        map: ({ grgLotNumber }) => grgLotNumber,
        color: cssVar('amethyst'),
        plural: 'Lots',
        empty: 'No lots',
        icon: 'icon-eye',
        path: '/lots/outgoing#shippedFromGlobal',
        navigate: ({ _id }) => `/lots/${_id}`,
      },
    ],
  },
].filter(Boolean);

export {
  customerSectionsEnum,
  gradingSectionsEnum,
  globalSectionsEnum,
  warehouseDashboardLabels,
  globalSections,
  customerSections,
  gradingSections,
};
