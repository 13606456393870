import PropTypes from 'prop-types';
import { useState, useReducer, useCallback, useMemo, createContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getFormSyncErrors } from 'redux-form';
import styled from 'styled-components';

import { media } from 'shared/helpers/styling/styling';
import { useEventListener } from 'shared/hooks/useEventListener';

import { FormContainer } from '../Form/FormContainer/FormContainer';
import { StepIndicator } from '../StepIndicator/StepIndicator';
import { CustomActions } from './CustomActions';

const wizardContext = createContext(null);
const formName = 'wizard';
const historySteps = new Set([0]);
const actions = {
  NEXT: 'NEXT',
  PREV: 'PREV',
  FIRST: 'FIRST',
  LAST: 'LAST',
  SET_DYNAMIC_STEPS: 'SET_DYNAMIC_STEPS',
};
const reducer = (state, action) => {
  switch (action.type) {
    case actions.NEXT:
      window.scrollTo(0, 0);
      historySteps.add(state + 1);
      return state + 1;
    case actions.PREV:
      window.scrollTo(0, 0);
      return state - 1;
    case actions.FIRST:
      window.scrollTo(0, 0);
      return 0;
    case actions.LAST:
      window.scrollTo(0, 0);
      return action.payload;
    default:
      return state;
  }
};

const StepIndicatorStyled = styled(StepIndicator)`
  margin: 0 auto 0 150px;

  ${media.mobile`
    margin: 30px 0;
  `}
`;

function Wizard({
  steps,
  onSubmit,
  onCancel,
  initialValues,
  header,
  contextProps,
  subHeader,
  withSummary,
  summaryAvailable,
  actionButtons,
  hiddenSteps = [],
}) {
  const [stepsCount, setStepsCount] = useState(Object.values(steps).length - hiddenSteps.length);
  const [step, dispatch] = useReducer(reducer, 0);
  const [data, setData] = useState({});
  const [name, Form] = Object.entries(steps)[step];

  const [formErrors, setFormErrors] = useState({});
  const wizardValidation = useSelector(state => getFormSyncErrors('wizard')(state));

  const isValidForm = errors =>
    !Object.values(errors).some(err =>
      !err
        ? false
        : Array.isArray(err)
        ? err.some(e => Object.values(e).some(d => d?.length))
        : Object.values(err)?.some(er => er?.length),
    );

  useEffect(() => {
    setFormErrors({ ...formErrors, [name]: wizardValidation[name] });
  }, [wizardValidation]);

  useEffect(() => {
    return () => {
      historySteps.clear();
    };
  }, [historySteps]);

  const handleKeyDown = e => {
    e = e || window.event;

    if (e.altKey) {
      switch (e.keyCode) {
        case 37:
          if (!step) {
            e.preventDefault();
            return false;
          }

          if (step) {
            e.preventDefault();
            wizard.prev();
          }
          break;
        case 39:
          if (historySteps.has(step + 1)) {
            wizard.next();
          }
          break;
        default:
      }
    }
  };

  const indicator = () => (
    <>
      <StepIndicatorStyled current={step + 1} steps={stepsCount} />
      {actionButtons?.()}
    </>
  );

  const wizard = useMemo(
    () => ({
      step,
      steps: stepsCount,
      hiddenStepsCount: hiddenSteps.length,
      historySteps: historySteps,
      isFirst: step === 0,
      isLast: step === stepsCount - (withSummary ? 2 : 1),
      prev: () => dispatch({ type: actions.PREV }),
      next: () => dispatch({ type: actions.NEXT }),
      first: () => dispatch({ type: actions.FIRST }),
      last: () => dispatch({ type: actions.LAST, payload: stepsCount - 1 }),
      setHiddenSteps: stepCount => setStepsCount(Object.values(steps).length - stepCount),
      data,
    }),
    [data, step, stepsCount, hiddenSteps.length],
  );

  const FormCustomActions = useCallback(
    p => (
      <CustomActions
        {...p}
        wizard={wizard}
        wizardContext={wizardContextValue}
        summaryAvailable={summaryAvailable}
        withSummary={withSummary}
        onCancel={onCancel}
      />
    ),

    [
      onCancel,
      wizard,
      contextProps?.changedShipmentDestination,
      summaryAvailable,
      isValidForm(formErrors),
    ],
  );

  const onPartialSubmit = useCallback(
    values => {
      const newData = {
        ...data,
        [name]: values[name],
      };
      setData(newData);

      return wizard.isLast
        ? onSubmit(newData, wizard.first, wizard.next)
        : dispatch({ type: actions.NEXT });
    },
    [data, name, onSubmit, wizard.isLast],
  );

  const wizardContextValue = useMemo(
    () => ({
      ...wizard,
      ...contextProps,
      CustomActions: FormCustomActions,
    }),
    [wizard, contextProps, FormCustomActions],
  );

  const cancelOnEsc = event => {
    if (event.key === 'Escape') {
      return onCancel?.(event);
    }
  };

  useEventListener('keydown', cancelOnEsc);
  useEventListener('keydown', handleKeyDown);

  return (
    <FormContainer header={header} subHeader={subHeader} actions={indicator()} wizard>
      <wizardContext.Provider value={wizardContextValue}>
        <Form
          key={name}
          form={formName}
          destroyOnUnmount={false}
          forceUnregisterOnUnmount
          initialValues={initialValues}
          CustomActions={FormCustomActions}
          onSubmit={onPartialSubmit}
        />
      </wizardContext.Provider>
    </FormContainer>
  );
}

Wizard.propTypes = {
  steps: PropTypes.object,
  hiddenSteps: PropTypes.arrayOf(PropTypes.string),
  header: PropTypes.string.isRequired,
  subHeader: PropTypes.string,
  contextProps: PropTypes.object,
};

Wizard.defaultProps = {
  steps: {},
};

export { Wizard, formName, wizardContext };
