import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { getSelfDeliveryRequestsCompanies } from 'actions/Companies/getCompaniesForFilters';
import {
  getSelfDeliveryRequestsList,
  clearSelfDeliveryRequestsList,
} from 'actions/SelfDeliveryRequests/selfDeliveryRequestsList';

import { SelfDeliveryRequestsList } from './SelfDeliveryRequestsList';

const mapStateToProps = state => ({
  auth: state.auth,
  selfDeliveryRequestsFilters: state.selfDeliveryRequestsFilters,
  selfDeliveryRequestsList: state.selfDeliveryRequestsList,
});

const mapDispatchToProps = {
  getSelfDeliveryRequestsList,
  clearSelfDeliveryRequestsList,
  getSelfDeliveryRequestsCompanies,
};

const ConnectedSelfDeliveryRequestsList = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SelfDeliveryRequestsList),
);

export { ConnectedSelfDeliveryRequestsList };
