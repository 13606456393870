import { UPDATE_PRICE_FEED } from 'shared/helpers/constants/systemSettings/priceFeeds/updatePriceFeed';
import { replaceEmpty } from 'shared/helpers/parsers/text';
import { commaValidator } from 'shared/helpers/validations/commaValidator';
import { PRICE_REGEX } from 'shared/helpers/validations/regularExpressions';

function validate({ source, adjustment, manualPrice }) {
  const errors = {};

  if (!source) {
    errors.source = UPDATE_PRICE_FEED.SOURCE_EMPTY;
  }

  if (!adjustment) {
    errors.adjustment = UPDATE_PRICE_FEED.ADJUSTMENT_EMPTY;
  }

  if ((adjustment && !PRICE_REGEX.test(adjustment)) || replaceEmpty(adjustment, '').length > 10) {
    errors.adjustment = UPDATE_PRICE_FEED.ADJUSTMENT_FORMAT;
  }

  if (!manualPrice) {
    errors.manualPrice = UPDATE_PRICE_FEED.MANUAL_PRICE_EMPTY;
  }

  if (manualPrice < 0) {
    errors.manualPrice = UPDATE_PRICE_FEED.MANUAL_PRICE_NEGATIVE;
  }

  if (
    (manualPrice && !PRICE_REGEX.test(manualPrice)) ||
    replaceEmpty(manualPrice, '').length > 10
  ) {
    errors.manualPrice = UPDATE_PRICE_FEED.MANUAL_PRICE_FORMAT;
  }

  commaValidator({ manualPrice, adjustment }, errors);

  return errors;
}

export { validate };
