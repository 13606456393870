import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const checkSamplePackagesActionType = 'CHECK_SAMPLE_PACKAGES';
const checkSamplePackagesAction = () => ({
  type: checkSamplePackagesActionType,
});

const checkSamplePackagesSuccessActionType = `${checkSamplePackagesActionType}_SUCCESS`;
const checkSamplePackagesSuccessAction = () => ({
  type: checkSamplePackagesSuccessActionType,
});

const checkSamplePackagesFailureActionType = `${checkSamplePackagesActionType}_FAILURE`;
const checkSamplePackagesFailureAction = () => ({
  type: checkSamplePackagesFailureActionType,
});

const checkSamplePackages = (lotId, packageId) => dispatch => {
  dispatch(checkSamplePackagesAction());

  return axios
    .post(`${API_HOST_AUTH}/lots/check-sample-packages`, {
      lotId,
      packageId,
    })
    .then(res => {
      dispatch(checkSamplePackagesSuccessAction());
      return res.data;
    })
    .catch(err => {
      dispatch(checkSamplePackagesFailureAction());
      return Promise.reject(err.data);
    });
};

export {
  checkSamplePackagesActionType,
  checkSamplePackagesSuccessActionType,
  checkSamplePackagesFailureActionType,
  checkSamplePackages,
};
