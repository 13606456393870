import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { checkSamplePackages } from 'actions/Lots/checkSamplePackages';
import { changePackagesState } from 'actions/Payments/changePackagesState';

import { BarcodeScanner } from 'shared/components/BarcodeScanner/BarcodeScanner';
import { ScanningSummary } from 'shared/components/BarcodeScanner/components/ScanningSummary';
import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';

import { InactivateConfirmation } from './components/InactivateConfirmation';

const message = {
  success: LOTS.PACKAGE_SUCCESSFULLY_SCAN,
  error: LOTS.PACKAGE_SCANNING_ERROR,
  idle: LOTS.SCAN_LAB_CAT_PACKAGE_BARCODE,
  manualIdle: LOTS.ENTER_LAB_CAT_PACKAGE_BARCODE,
};

const InactivatePackageBarcodeScanner = ({ open, lot: { _id: lotId }, onSelfClose }) => {
  const [summary, setSummary] = useState(null);
  const [scannedPackageId, setScannedPackageId] = useState(null);
  const { isPending } = useSelector(({ changePackagesState }) => changePackagesState);
  const dispatch = useDispatch();

  const onFinish = () => {
    setSummary(null);
    setScannedPackageId(null);
    onSelfClose();
  };

  const checkSamplePackageStatus = async packageId => {
    setScannedPackageId(packageId);
    await dispatch(checkSamplePackages(lotId, packageId))
      .then(data => {
        setSummary(data.summary);
      })
      .catch(error => Promise.reject(error.message));
  };

  const isLastPackage = () => summary?.inactivated + 1 === summary?.total;

  const inactivateSamplePackage = onReset => async () => {
    await dispatch(changePackagesState('inactivate', { id: scannedPackageId })).then(() => {
      if (isLastPackage()) {
        onFinish();
        return;
      }
      setSummary({
        inactivated: summary.inactivated + 1,
        total: summary.total,
      });
      onReset();
    });
  };

  const steps = [
    {
      icon: summary
        ? () => (
            <ScanningSummary
              done={summary.inactivated}
              total={summary.total}
              doneLabel={LOTS.INACTIVATED_PACKAGES}
              totalLabel={LOTS.NOT_INACTIVATED_PACKAGES}
            />
          )
        : 'icon-box',
      name: 'packageId',
      barcodeLength: [8, 24],
      onChange: checkSamplePackageStatus,
    },
    {
      render: ({ onReset }) => {
        return (
          <InactivateConfirmation
            isPending={isPending}
            onSubmit={inactivateSamplePackage(onReset)}
            onCancel={onReset}
          />
        );
      },
    },
  ];
  return <BarcodeScanner open={open} onSelfClose={onFinish} message={message} idleStates={steps} />;
};

InactivatePackageBarcodeScanner.propTypes = {
  open: PropTypes.bool,
  lot: PropTypes.shape({ _id: PropTypes.string }),
  onSelfClose: PropTypes.func,
};

export { InactivatePackageBarcodeScanner };
