import axios from 'axios';

import { showSnackbar } from 'actions/shared/snackbar';

import {
  dangerOptions,
  successOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { USER } from 'shared/helpers/constants/users/userConstants';

const API_HOST_AUTH = process.env.REACT_APP_API;

const changePasswordUsingTokenActionType = 'CHANGE_PASSWORD_USING_TOKEN';
const changePasswordUsingTokenAction = () => ({
  type: changePasswordUsingTokenActionType,
});

const changePasswordUsingTokenSuccessActionType = `${changePasswordUsingTokenActionType}_SUCCESS`;
const changePasswordUsingTokenSuccessAction = () => ({
  type: changePasswordUsingTokenSuccessActionType,
});

const changePasswordUsingTokenFailureActionType = `${changePasswordUsingTokenActionType}_FAILURE`;
const changePasswordUsingTokenFailureAction = () => ({
  type: changePasswordUsingTokenFailureActionType,
});

const changePasswordUsingToken = (token, newPassword) => dispatch => {
  dispatch(changePasswordUsingTokenAction());

  return axios
    .post(`${API_HOST_AUTH}/users/change-password/${token}`, { newPassword })
    .then(res => {
      dispatch(changePasswordUsingTokenSuccessAction());
      dispatch(showSnackbar(successOptions, res.data.message));
    })
    .catch(error => {
      dispatch(changePasswordUsingTokenFailureAction());
      dispatch(showSnackbar(dangerOptions, `${USER.PASSWORD_NOT_CHANGED} ${error.data.message}`));
    });
};

export {
  changePasswordUsingTokenActionType,
  changePasswordUsingTokenSuccessActionType,
  changePasswordUsingTokenFailureActionType,
  changePasswordUsingToken,
};
