import axios from 'axios';

import { showSnackbar } from 'actions/shared/snackbar';

import {
  successOptions,
  dangerOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { parseLocationUpdateData } from 'shared/helpers/dataParsers/location/parseLocationUpdateData';

import { history } from 'utils/history';

const API_HOST_AUTH = process.env.REACT_APP_API;

const updateLocationActionType = 'UPDATE_LOCATION';
function updateLocationAction() {
  return {
    type: updateLocationActionType,
  };
}

const updateLocationSuccessActionType = 'UPDATE_LOCATION_SUCCESS';
function updateLocationSuccessAction() {
  return {
    type: updateLocationSuccessActionType,
  };
}

const updateLocationFailureActionType = 'UPDATE_LOCATION_FAILURE';
function updateLocationFailureAction() {
  return {
    type: updateLocationFailureActionType,
  };
}

function updateLocation(location, id, relatedCompany, canUpdateSuffix) {
  return dispatch => {
    dispatch(updateLocationAction());

    const locationObj = parseLocationUpdateData(location, relatedCompany);

    if (!canUpdateSuffix) {
      delete locationObj.lotSuffix;
    }

    axios({
      method: 'put',
      url: `${API_HOST_AUTH}/locations/${id}`,
      data: locationObj,
    })
      .then(res => {
        dispatch(updateLocationSuccessAction());
        dispatch(showSnackbar(successOptions, res.data.message));
        history.push(`/companies/${relatedCompany}/locations/${res.data.id}`);
      })
      .catch(error => {
        dispatch(updateLocationFailureAction());
        dispatch(
          showSnackbar(dangerOptions, `Location has not been updated. ${error.data.message}`),
        );
      });
  };
}

export {
  updateLocationActionType,
  updateLocationSuccessActionType,
  updateLocationFailureActionType,
  updateLocation,
};
