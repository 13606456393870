import {
  DAYS,
  PROCESSING,
  MIXING,
  GRADING_STATUSES,
  SECTIONS,
  COUNTING_REQUIRED_SECTIONS,
} from 'shared/helpers/constants/warehouseDashboard/labels';

import { MomentAdapter } from 'utils/date/momentAdapter';

let processViaCount;
const isWest = () => process.env.REACT_APP_VERSION === 'GLOBAL';
const processViaCountFn = () => processViaCount;

const processIncomingList = (
  currentDateEnd,
  currentDay,
  incoming,
  selfDelivery,
  currentDateStart,
) =>
  Object.entries(DAYS).map(([key, day]) => {
    return {
      label: day.name,
      listId: `${SECTIONS.INCOMING.id}-${day.id}`,
      elements: selfDelivery
        .filter(item => {
          const { deliveryDate } = item;
          const itemDay = MomentAdapter.weekday(deliveryDate);

          if (itemDay === day.dayNumber) {
            return item;
          }
        })
        .concat(
          incoming
            .filter(item => {
              const estDeliveryDate = item?.estDeliveryDate || null;
              const subsection = item?.subsection || null;

              const itemDay = estDeliveryDate && MomentAdapter.weekday(estDeliveryDate);

              if (subsection) {
                return subsection === day.id && item;
              }

              if (estDeliveryDate < currentDateStart) {
                item.delayed = true;
              }

              if (estDeliveryDate <= currentDateEnd && currentDay === day.dayNumber) {
                return item;
              }

              if (estDeliveryDate > currentDateEnd && itemDay === day.dayNumber) {
                return item;
              }
            })
            .map(item => (item.subsection ? item : dashboardWarehouseLotMapper(item))),
        ),
    };
  });

const dashboardWarehouseLotMapper = shipment => ({
  _id: shipment.assignedLot._id,
  delayed: shipment?.delayed || false,
  grgLotNumber: shipment.assignedLot.grgLotNumber,
  color: shipment.assignedLot.color || null,
  customLotNumber: shipment.assignedLot.customLotNumber || null,
  receivedAt: shipment.assignedLot.receivedAt,
  processingNotes: shipment.assignedLot.processingNotes,
  designation: shipment.assignedLot.designation,
});

const processGradingList = grading =>
  Object.entries(GRADING_STATUSES).map(([key, status]) => {
    return {
      label: status.name,
      listId: `${SECTIONS.GRADING.id}-${status.id}`,
      elements: grading.filter(item => {
        const statusForGlobal = item?.statusForGlobal || null;
        const subsection = item?.subsection || null;

        if (subsection) {
          return subsection === status.id && item;
        }

        if (key.toUpperCase() === statusForGlobal.toUpperCase()) {
          return item;
        }
      }),
    };
  });

const processCountingRequiredList = countingRequired =>
  Object.entries(COUNTING_REQUIRED_SECTIONS).map(([key, section]) => {
    return {
      label: section.name,
      listId: `${SECTIONS.COUNTING_REQUIRED.id}-${section.id}`,
      elements: countingRequired.filter(item => {
        if (item.subsection) {
          return item.subsection === section.id && item;
        }

        return section.id !== COUNTING_REQUIRED_SECTIONS.HOLD.id && item;
      }),
    };
  });

const processProcessingList = currentlyProcessing => {
  processViaCount = isWest() ? [] : 0;

  return isWest()
    ? Object.entries(PROCESSING)
        .map(([key, processVia]) => {
          let count = 0;
          const elements = currentlyProcessing.filter(item => {
            const itemProcessVia = item?.processVia;
            const mixVia = item?.mixVia || null;
            const subsection = item?.subsection || null;

            if (subsection) {
              subsection === processVia.id && count++;
              return subsection === processVia.id && item;
            }

            if (key.toUpperCase() === itemProcessVia.toUpperCase() && !mixVia) {
              count++;
              return item;
            }
          });
          processViaCount.push(count);

          return {
            label: processVia.name,
            listId: `${SECTIONS.CURRENTLY_PROCESSING.id}-${processVia.id}`,
            elements: elements,
          };
        })
        .concat(
          Object.entries(MIXING).map(([key, processVia]) => {
            let count = 0;
            const elements = currentlyProcessing.filter(item => {
              const mixVia = item?.mixVia || null;
              const subsection = item?.subsection || null;

              if (subsection) {
                subsection === processVia.id && count++;
                return subsection === processVia.id && item;
              }

              if (mixVia && key.toUpperCase() === mixVia.toUpperCase()) {
                count++;
                return item;
              }
            });
            processViaCount.push(count);

            return {
              label: processVia.name,
              listId: `${SECTIONS.CURRENTLY_PROCESSING.id}-${processVia.id}`,
              elements: elements,
            };
          }),
        )
    : [
        {
          label: PROCESSING.SHEARS.name,
          listId: `${SECTIONS.CURRENTLY_PROCESSING.id}-${PROCESSING.SHEARS.id}`,
          elements: currentlyProcessing.filter(item => {
            const processVia = item?.processVia;
            const subsection = item?.subsection || null;

            if (subsection) {
              subsection === PROCESSING.SHEARS.id && processViaCount++;
              return subsection === PROCESSING.SHEARS.id && item;
            }

            if (PROCESSING.SHEARS.name.toUpperCase() === processVia.toUpperCase()) {
              processViaCount++;
              return item;
            }
          }),
        },
      ];
};

export {
  isWest,
  processViaCountFn,
  processIncomingList,
  processGradingList,
  processProcessingList,
  dashboardWarehouseLotMapper,
  processCountingRequiredList,
};
