import { validate as validateShipmentInfo } from '../lot/updateShipmentInfo';

const validate = (values, { shipmentDetails: { shipment } }) => {
  const lotNumbers = (shipment && shipment.assignedLot) || [];

  return lotNumbers
    .map((lotNumber, i) => [(values.lots && values.lots[i]) || {}])
    .reduce(
      (errors, [lot]) => ({
        lots: [
          ...errors.lots,
          {
            ...validateShipmentInfo(lot),
          },
        ],
      }),
      { lots: [] },
    );
};

export { validate };
