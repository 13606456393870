import {
  getLotsForSettlementActionType,
  getLotsForSettlementSuccessActionType,
  getLotsForSettlementFailureActionType,
  clearPaymentAmountActionType,
} from 'actions/Payments/getLotsForSettlement';

const INITIAL_STATE = {
  isPending: false,
  lots: [],
};

const LotsForSettlementReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getLotsForSettlementActionType:
      return {
        ...state,
        isPending: true,
      };
    case getLotsForSettlementSuccessActionType:
      return {
        lots: action.payload.lots,
        isPending: false,
      };
    case getLotsForSettlementFailureActionType:
    case clearPaymentAmountActionType:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};

export { LotsForSettlementReducer };
