import PropTypes from 'prop-types';
import { useCallback, useEffect, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, reduxForm, FormSection, formValueSelector } from 'redux-form';

import { getCompaniesForPoolPayments } from 'actions/Companies/filterCompanies';
import { clearBankHolidays, getBankHolidays } from 'actions/Payments/bankHolidaysList';
import { showSnackbar, hideSnackbar } from 'actions/shared/snackbar';

import { FieldSearch, FieldDate } from 'shared/components/Fields';
import { FieldButtonSelect } from 'shared/components/Fields/FieldButtonSelect/FieldButtonSelect';
import { FieldInput } from 'shared/components/Fields/FieldInput/FieldInput';
import { Form, Section } from 'shared/components/Form';
import { wizardContext } from 'shared/components/Wizard/Wizard';
import { fundsTypes } from 'shared/helpers/constants/payments/fundsTypes';
import { PAYMENTS } from 'shared/helpers/constants/payments/paymentsConstants';
import { infoOptions } from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { getBankHolidaysDates } from 'shared/helpers/dataParsers/payments/parseBankHolidaysData';
import { formatToDollar } from 'shared/helpers/parsers/formaters';
import { mapArray } from 'shared/helpers/parsers/select';
import { cssVar } from 'shared/helpers/styling/styling';
import { multiValidator } from 'shared/helpers/validations/multiValidator';
import { validate } from 'shared/helpers/validations/payments/enterPaymentValidation';

const PaymentFormLayout = ({ handleSubmit, invalid, change, form, initialValues }) => {
  const dispatch = useDispatch();
  const relatedCompany = useSelector(state =>
    formValueSelector(form)(state, 'payment.relatedCompany'),
  );
  const { filters, bankHolidays } = useSelector(state => state.bankHolidaysList);
  const wizard = useContext(wizardContext);
  const listCompanies = input =>
    !input ? Promise.resolve({ options: [] }) : dispatch(getCompaniesForPoolPayments(input));
  const [balanceRemaining, setBalanceRemaining] = useState(relatedCompany?.balanceRemaining);

  useEffect(() => {
    dispatch(getBankHolidays(filters));
    if (initialValues?.payment?.relatedCompany?.label) {
      dispatch(getCompaniesForPoolPayments(initialValues?.payment?.relatedCompany?.label)).then(
        ({ options }) => {
          change('payment.relatedCompany', options[0]);
          onRelatedCompanyChange(options[0]);
        },
      );
    }
    return () => {
      dispatch(clearBankHolidays());
    };
  }, [dispatch]);

  const isEditingMode = () => wizard.editMode;

  const onRelatedCompanyChange = useCallback(
    relatedCompany => {
      change('payment.balanceRemaining', relatedCompany?.balanceRemaining);
      setBalanceRemaining(relatedCompany?.balanceRemaining);
      relatedCompany?.poolPaymentRequestExists
        ? dispatch(showSnackbar(infoOptions, PAYMENTS.POOL_PAYMENT_REQUEST_EXISTS))
        : dispatch(hideSnackbar());
    },
    [balanceRemaining],
  );

  return (
    <Form
      header={PAYMENTS.PAYMENT}
      onSubmit={handleSubmit}
      submitDisabled={invalid || relatedCompany?.poolPaymentRequestExists}
    >
      <FormSection name="payment">
        <Section
          template={[
            'relatedCompany balanceRemaining',
            'fundsType fundsType',
            'additionalInfo requestedFinalPaymentDate',
          ]}
        >
          <Field
            name="relatedCompany"
            component={FieldSearch}
            label={PAYMENTS.RELATED_COMPANY}
            getOptions={listCompanies}
            onChange={onRelatedCompanyChange}
            field="required"
            disabled={isEditingMode()}
          />
          {balanceRemaining && balanceRemaining !== 0 && (
            <Field
              name="balanceRemaining"
              component={FieldInput}
              label={PAYMENTS.BALANCE_REMAINING}
              color={cssVar('solidRed')}
              prefix="$"
              format={formatToDollar}
              disabled
            />
          )}
          <Field
            name="additionalInfo"
            component={FieldInput}
            label={PAYMENTS.ADDITIONAL_INFORMATION}
            maxLength={250}
          />
          <Field
            name="fundsType"
            component={FieldButtonSelect}
            options={mapArray(Object.values(fundsTypes))}
            label={PAYMENTS.FUNDS_TYPE}
            field="required"
          />
          {isEditingMode() && (
            <Field
              name="requestedFinalPaymentDate"
              component={FieldDate}
              label={PAYMENTS.REQUESTED_FINAL_PAYMENT_DATE}
              field="required"
              excludeDates={getBankHolidaysDates(bankHolidays)}
              onlyWeekdays
            />
          )}
        </Section>
      </FormSection>
    </Form>
  );
};

PaymentFormLayout.propTypes = {
  handleSubmit: PropTypes.func,
  invalid: PropTypes.bool,
  change: PropTypes.func,
  form: PropTypes.string,
  initialValues: PropTypes.shape({
    payment: PropTypes.shape({
      requestedFinalPaymentDate: PropTypes.object,
      relatedCompany: PropTypes.object,
      additionalInfo: PropTypes.string,
      fundsType: PropTypes.object,
      isDraftPoolPayment: PropTypes.bool,
    }),
  }),
};

const PaymentForm = reduxForm({
  validate: multiValidator('payment', validate),
})(PaymentFormLayout);

export { PaymentForm, PaymentFormLayout };
