import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { StyledLoadingSpinner } from 'assets/styles/common/StyledLoadingSpinner';

import { IconButton } from 'shared/components/Buttons/IconButton/IconButton';
import { Icon } from 'shared/components/Icon/Icon';
import { CALENDAR, views } from 'shared/helpers/constants/calendarConstants';
import { cssVar, pxToRem, media } from 'shared/helpers/styling/styling';

import { relativeNavigator } from 'utils/relativeNavigator';

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: ${pxToRem(25)};
  color: ${cssVar('whiteGRG')};
  margin-left: 15px;
  height: 50px;

  ${media.smallMobile`
    width: fill-content;
    margin-top: 10px;
    margin-left: 0;
  `}
`;

const ActionsContainer = styled.div`
  display: grid;
  grid-template-columns: 45% 40% 15%;
  align-items: center;

  button {
    width: 50px;
    height: 50px;
  }

  ${media.smallMobile`
    grid-template-columns: 80% 20%;
    grid-template-rows: 50px auto;
    grid-column-gap: 10px;

    ${IconButton} {
      grid-area: 2 / 2 / 3 / 3;
    }
  `}
`;

const ViewsContainer = styled.div`
  display: flex;
  color: ${cssVar('alabaster')};
  align-items: center;
  margin-right: 50px;

  span {
    margin-right: 10px;
  }

  ${media.smallMobile`
    grid-area: 1 / 1 / 2 / 3;
    margin: 0;
  `}

  ${media.tablet`
    margin-right: 40px;
  `}
`;

const RangeContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;

  ${media.smallMobile`
    margin: 10px 0;
  `}

  ${media.tablet`
    margin-right: 10px;
  `}
`;

const ShowTodayContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 8px 10px 0 0;

  ${media.smallMobile`
    margin: 10px 0;
  `}

  ${media.tablet`
    margin-right: 10px;
  `}
`;

const DatesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.smallMobile`
    grid-area: 2 / 1 / 3 / 2;
    margin-top: 8px;
  `}
`;

const DataContainer = styled.div`
  border-radius: 8px;
  font-size: ${pxToRem(14)};
  height: 35px;
  color: ${cssVar('alabaster')};
  font-weight: 500;
  cursor: default;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  min-width: ${({ wide }) => (wide ? '128px' : '90px')};
  background-color: ${({ highlight }) => (highlight ? cssVar('riverBedCalendar') : 'transparent')};
  ${({ highlight }) =>
    !highlight &&
    css`
      opacity: 0.56;
    `};

  ${({ selectable }) =>
    selectable &&
    css`
      cursor: pointer;

      &:hover {
        opacity: 0.92;
      }
    `};
`;

const CalendarToolbarLayout = toolbar => {
  const { isPending } = useSelector(({ shipmentsCalendar }) => shipmentsCalendar);
  const { pathname } = useLocation();
  const goToPrev = () => toolbar.onNavigate('PREV');
  const goToNext = () => toolbar.onNavigate('NEXT');
  const goToWeekView = () => toolbar.onView('week');
  const goToMonthView = () => toolbar.onView('month');
  const goToToday = () => toolbar.onNavigate('TODAY');
  const isViewSelected = view => toolbar.view === view;
  const navigation = [
    { from: /^\/shipments/, to: '/shipments/calendar-notes' },
    { from: /^\/dashboard/, to: '/dashboard/calendar-notes' },
  ];

  const { calendarNote } = toolbar.auth.access.shipments;
  const navigator = relativeNavigator(navigation)();

  const createCalendarNote = () => navigator(pathname);

  return (
    <div className={toolbar.className}>
      <HeaderContainer>
        <span>{CALENDAR.SHIPMENTS_CALENDAR}</span> {isPending && <StyledLoadingSpinner />}
      </HeaderContainer>
      <ActionsContainer>
        <ViewsContainer>
          <span>{CALENDAR.VIEW}</span>
          <DataContainer selectable highlight={isViewSelected(views.week)} onClick={goToWeekView}>
            {CALENDAR.WEEKLY}
          </DataContainer>
          <DataContainer selectable highlight={isViewSelected(views.month)} onClick={goToMonthView}>
            {CALENDAR.MONTHLY}
          </DataContainer>
        </ViewsContainer>
        <DatesContainer>
          <RangeContainer>
            <Icon icon="icon-tiny-arrow-left cursor" onClick={goToPrev} />
            <DataContainer highlight wide>
              {toolbar.label}
            </DataContainer>
            <Icon icon="icon-tiny-arrow-right cursor" onClick={goToNext} />
          </RangeContainer>
          <ShowTodayContainer>
            <DataContainer highlight selectable onClick={goToToday}>
              {CALENDAR.SHOW_TODAY}
            </DataContainer>
          </ShowTodayContainer>
        </DatesContainer>
        {calendarNote.create && (
          <IconButton icon="icon-add-calendar-note" onClick={createCalendarNote} />
        )}
      </ActionsContainer>
    </div>
  );
};

const CalendarToolbar = styled(CalendarToolbarLayout)`
  display: flex;
  justify-content: space-between;
  height: 100px;
  align-items: center;
  background-color: ${cssVar('outerSpaceTabs')};

  ${media.smallMobile`
    flex-direction: column;
    align-items: center;
    height: 260px;
  `}
`;

export { CalendarToolbar };
