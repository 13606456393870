import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { getPoolAccounts } from 'actions/PoolAccounts/poolAccountsList';
import { openModal, closeModal } from 'actions/shared/modal';
import { showSnackbar } from 'actions/shared/snackbar';

import { Sidebar } from './Sidebar';

const mapStateToProps = state => ({
  auth: state.auth,
  modal: state.modal,
  poolAccountsList: state.poolAccountsList,
});

const mapDispatchToProps = {
  openModal,
  closeModal,
  getPoolAccounts,
  showSnackbar,
};

const connectedSidebar = withRouter(connect(mapStateToProps, mapDispatchToProps)(Sidebar));

export { connectedSidebar as Sidebar };
