import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getDashboardActionType = 'GET_DASHBOARD';
function getDashboardAction(limit, page) {
  return {
    type: getDashboardActionType,
    payload: { limit, page },
  };
}

const getNotificationsOnDashboardSuccessActionType = 'GET_NOTIFICATIONS_ON_DASHBOARD_SUCCESS';
function getNotificationsOnDashboardSuccessAction(notifications) {
  return {
    type: getNotificationsOnDashboardSuccessActionType,
    payload: {
      notifications,
    },
  };
}

const getNotificationsOnDashboardFailureActionType = 'GET_ON_DASHBOARD_NOTIFICATIONS_FAILURE';
function getNotificationsOnDashboardFailureAction(errorMessage) {
  return {
    type: getNotificationsOnDashboardFailureActionType,
    payload: {
      errorMessage,
    },
  };
}

const clearDashboardActionType = 'CLEAR_DASHBOARD_ACTION';
function clearDashboardAction() {
  return {
    type: clearDashboardActionType,
  };
}

const getNotificationsOnDashboardWithTypeSuccessActionType =
  'GET_NOTIFICATIONS_ON_DASHBOARD_WITH_TYPE_SUCCESS';
function getNotificationsOnDashboardWithTypeSuccessAction(notifications, pages, page, type) {
  return {
    type: getNotificationsOnDashboardWithTypeSuccessActionType,
    payload: {
      notifications,
      pages,
      page,
      type,
    },
  };
}

function getNotificationsOnDashboard(limit) {
  return dispatch => {
    dispatch(getDashboardAction(limit));

    return axios({
      method: 'post',
      url: `${API_HOST_AUTH}/notifications/unread/dashboard`,
      data: JSON.stringify({ limit }),
    })
      .then(res => {
        dispatch(getNotificationsOnDashboardSuccessAction(res.data.docs));
      })
      .catch(() => {
        dispatch(getNotificationsOnDashboardFailureAction(''));
      });
  };
}

function getNotificationsOnDashboardWithType(limit, page, type) {
  return dispatch => {
    dispatch(getDashboardAction(limit, page, type));

    return axios({
      method: 'get',
      url: `${API_HOST_AUTH}/notifications/unread/${page}/${limit}/${type}`,
    })
      .then(res => {
        dispatch(
          getNotificationsOnDashboardWithTypeSuccessAction(
            res.data.docs,
            res.data.pages,
            res.data.page,
            type,
          ),
        );
      })
      .catch(() => {
        dispatch(getNotificationsOnDashboardFailureAction(''));
      });
  };
}

export {
  getDashboardAction,
  getDashboardActionType,
  getNotificationsOnDashboardWithType,
  getNotificationsOnDashboard,
  getNotificationsOnDashboardSuccessActionType,
  getNotificationsOnDashboardWithTypeSuccessActionType,
  getNotificationsOnDashboardWithTypeSuccessAction,
  getNotificationsOnDashboardSuccessAction,
  getNotificationsOnDashboardFailureActionType,
  getNotificationsOnDashboardFailureAction,
  clearDashboardAction,
  clearDashboardActionType,
};
