import { hedgeStatus } from 'shared/helpers/constants/hedges/hedgeStatus';
import { hedgeTypes } from 'shared/helpers/constants/hedges/hedgeType';

const allowedStatuses = [
  hedgeStatus.placed,
  hedgeStatus.partly_filled,
  hedgeStatus.filled,
  hedgeStatus.reassigned,
];

export const transferOuncesAccess = (auth, hedge) => {
  const { transferBlanketHedgeOunces } = auth.access.specialPermissions.hedges;

  return (
    hedge.hedgeType === hedgeTypes.blanket &&
    allowedStatuses.includes(hedge.status) &&
    transferBlanketHedgeOunces
  );
};
