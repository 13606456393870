import PropTypes from 'prop-types';
import * as React from 'react';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form';

import { selectOptionsParser } from 'shared/helpers/dataParsers/user/parseUserData';

import { StyledPermissionsRow, StyledLabel, StyledFieldSelect } from './StyledPermissionsRow';

const PermissionsRow = memo(({ label, name, usersList, disabled, allowEmptyArray }) => {
  const accessToUpdate = useSelector(
    ({ auth }) => auth.access.systemSettings.specialPermissions.update,
  );

  return (
    <StyledPermissionsRow isDisabled={disabled}>
      <StyledLabel>{label}</StyledLabel>
      <Field
        name={name}
        component={StyledFieldSelect}
        options={selectOptionsParser(usersList, true)}
        highlightAvailableFields
        isClearable
        multi
        wrap
        disabled={!accessToUpdate || disabled}
        allowEmptyArray={allowEmptyArray}
      />
    </StyledPermissionsRow>
  );
});

PermissionsRow.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  usersList: PropTypes.array.isRequired,
};

PermissionsRow.defaultProps = {
  usersList: [],
  disabled: false,
  allowEmptyArray: true,
};

export { PermissionsRow };
