import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { getLot, clearLotDetails } from 'actions/Lots/lotDetails';
import {
  clearAllPackagesOverview,
  clearPackagesOverview,
  getPackagesOverviewForLots,
  getPackagesOverviewForShipments,
} from 'actions/Packages/packagesOverviewList';
import { getShipment, clearShipmentDetailsAction } from 'actions/Shipments/shipmentDetails';
import { showSnackbar } from 'actions/shared/snackbar';

import { FillProcessingPackages } from './FillProcessingPackages';

const mapStateToProps = state => ({
  acceptForMixingState: state.acceptForMixing,
  acceptForProcessingState: state.acceptForProcessing,
  auth: state.auth,
  lotDetails: state.lotDetails,
  markProcessingCompleteState: state.markProcessingComplete,
  packagesOverviewList: state.packagesOverviewList,
  shipmentDetails: state.shipmentDetails,
});

const mapDispatchToProps = {
  getLot,
  getShipment,
  clearShipmentDetailsAction,
  clearLotDetails,
  getPackagesOverviewForLots,
  getPackagesOverviewForShipments,
  clearPackagesOverview,
  clearAllPackagesOverview,
  showSnackbar,
};

export const ConnectedFillProcessingPackages = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FillProcessingPackages),
);
