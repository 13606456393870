import { EnterSettledOuncesReducer } from './enterSettledOunces';
import { GetLotSettlementsReducer } from './getLotSettlements';
import { SettlementDetailsReducer } from './settlementDetails';

const settlementsRootReducer = {
  enterSettledOunces: EnterSettledOuncesReducer,
  lotSettlements: GetLotSettlementsReducer,
  settlementDetails: SettlementDetailsReducer,
};

export { settlementsRootReducer };
