import {
  removeCommodityFromLotType,
  removeCommodityFromLotSuccessType,
  removeCommodityFromLotFailureType,
} from 'actions/Commodities/removeCommodityFromLot';

const INITIAL_STATE = {
  isPending: false,
};

const removeCommodityFromLotReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case removeCommodityFromLotType:
      return {
        isPending: true,
      };
    case removeCommodityFromLotSuccessType:
    case removeCommodityFromLotFailureType:
      return {
        isPending: false,
      };
    default:
      return state;
  }
};

export { removeCommodityFromLotReducer };
