import { connect } from 'react-redux';

import { deleteTrustedCompany } from 'actions/Companies/privilegedCompaniesDelete';
import {
  getTrustedCompanies,
  clearPrivilegedCompanies,
} from 'actions/Companies/privilegedCompaniesList';
import { openModal } from 'actions/shared/modal';
import { showSnackbar } from 'actions/shared/snackbar';

import { TrustedCompanies } from './TrustedCompanies';

const mapStateToProps = ({ privilegedCompaniesList, auth }) => ({
  privilegedCompaniesList,
  auth,
});

const dispatchMapToProps = {
  getTrustedCompanies,
  clearPrivilegedCompanies,
  deleteTrustedCompany,
  showSnackbar,
  openModal,
};

const ConnectedTrustedCompanies = connect(mapStateToProps, dispatchMapToProps)(TrustedCompanies);

export { ConnectedTrustedCompanies };
