const {
  materialCountMarkAsSentShipmentModal,
  markAsSentShipmentModal,
  shipmentSealReminderModal,
  defaultModal,
} = require('shared/helpers/constants/modalConstants');
const { SHARED } = require('shared/helpers/constants/sharedConstants');

export const firstStepModal = {
  header: isCustomerUser =>
    isCustomerUser ? materialCountMarkAsSentShipmentModal.message : markAsSentShipmentModal.message,
  paragraph: isCustomerUser =>
    isCustomerUser
      ? materialCountMarkAsSentShipmentModal.messageText
      : markAsSentShipmentModal.messageText,
  topIconColor: 'solidYellow',
  acceptButton: SHARED.CONFIRM,
  cancelButton: SHARED.CANCEL_BUTTON,
  cancelButtonColor: defaultModal.cancelColor,
  cancelButtonIcon: defaultModal.cancelIcon,
};

export const secondStepModal = {
  paragraph: shipmentSealReminderModal.messageText,
  topIconColor: defaultModal.cancelColor,
  acceptButton: shipmentSealReminderModal.acceptButton,
  customActionButton: shipmentSealReminderModal.customActionButton,
  customActionColor: shipmentSealReminderModal.customActionColor,
  customActionIcon: shipmentSealReminderModal.customActionIcon,
};
