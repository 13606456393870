import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { clearCompanyDetails, getCompany } from 'actions/Companies/companyDetails';
import { createNote } from 'actions/Notes/createNote';
import { createUser } from 'actions/Users/createUser';
import { showSnackbar } from 'actions/shared/snackbar';

import { FormContainer } from 'shared/components/Form';
import { NOTE } from 'shared/helpers/constants/noteConstants';
import {
  successOptions,
  infoOptions,
  dangerOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { USER } from 'shared/helpers/constants/users/userConstants';

import { goBackOrTo, history } from 'utils/history';

import { ConnectedUserForm } from '../UserForm/UserForm';

const CreateUser = ({ match }) => {
  const [files, setFiles] = useState([]);
  const [invalidFiles, setInvalidFiles] = useState(false);
  const dispatch = useDispatch();
  const { companyId } = match.params;
  useEffect(() => {
    if (companyId) {
      dispatch(getCompany(companyId));
    }

    return () => companyId && dispatch(clearCompanyDetails());
  }, [match]);

  const addFilesToList = fileList => setFiles(prevState => prevState.concat(fileList));

  const checkNotes = notes => !notes && setFiles([]);

  const removeFilesFromList = fileToRemove =>
    setFiles(prevState => prevState.filter(file => file !== fileToRemove));

  const checkFilesError = invalidFiles => setInvalidFiles(invalidFiles);

  const submit = async values => {
    const noteType = NOTE.NOTES_TYPE.USERS;
    let userRes;

    try {
      userRes = await dispatch(createUser(values, companyId));
      if (values.note) {
        await dispatch(createNote(values, files, userRes.data.id, noteType, true));
      }

      if (companyId) {
        history.push(`/companies/${companyId}/users/${userRes.data.id}`);
      } else {
        history.push(`/users/${userRes.data.id}`);
      }

      dispatch(showSnackbar(successOptions, userRes.data.message));
    } catch (err) {
      if (userRes && userRes.data.id && values.note) {
        dispatch(showSnackbar(infoOptions, USER.USER_CREATED_WITHOUT_NOTES));
        goBackOrTo('/users');
      } else {
        dispatch(showSnackbar(dangerOptions, `${USER.USER_CREATE_ERROR} ${err.data.message}`));
      }
    }
  };

  const blockSubmit = () => invalidFiles || files.length > 10;

  return (
    <FormContainer header={USER.CREATE_A_USER}>
      <ConnectedUserForm
        onSubmit={submit}
        addFile={addFilesToList}
        removeFile={removeFilesFromList}
        attachment={{ files, invalidFiles }}
        blockSubmit={blockSubmit()}
        filesError={checkFilesError}
        checkNotes={checkNotes}
        match={match}
      />
    </FormContainer>
  );
};

export { CreateUser };
