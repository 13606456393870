import PropTypes from 'prop-types';
import { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Field, formValueSelector, getFormSyncErrors, getFormValues } from 'redux-form';

import { showSnackbar } from 'actions/shared/snackbar';

import { IconButton } from 'shared/components/Buttons/IconButton/IconButton';
import { TinyFieldInput, TinyFieldSelect, TinyFieldTextarea } from 'shared/components/Fields';
import { FieldsFilter } from 'shared/components/FieldsFilter/FieldsFilter';
import { PackageSection } from 'shared/components/Form/PackgeSection/PackageSection';
import { StatusIndicator } from 'shared/components/StatusIndicator/StatusIndicator';
import { formName, wizardContext } from 'shared/components/Wizard/Wizard';
import { samplePackageAccess } from 'shared/helpers/accesses/packages/samplePackageAccess';
import {
  denominatedIn,
  denominationTable,
} from 'shared/helpers/constants/materials/denominationTable';
import { availableMaterialClass } from 'shared/helpers/constants/packages/materialClass';
import {
  allMaterials,
  availableMaterialTypeCommodities,
  availableMaterialTypeForAssay,
  availableMaterialTypeWithConverters,
  availableMaterialTypeForGrading,
  availableMaterialTypeOutbound,
  availableMaterialTypesPackingList,
  availableMaterialTypesSamplePackage,
  materialTypeForAssay,
  materialTypesForUnitCountChange,
} from 'shared/helpers/constants/packages/materialType';
import { PACKAGES } from 'shared/helpers/constants/packages/packageConstants';
import {
  availablePackageType,
  packageStatuses,
  packageType,
} from 'shared/helpers/constants/packages/packageType';
import { infoOptions } from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { parseLotsSelectData } from 'shared/helpers/dataParsers/lot/parseLotsSelectData';
import { navigateTable } from 'shared/helpers/forms/navigateTable';
import {
  isAssayCompany,
  isGradingCompany,
  isInternalCompany,
} from 'shared/helpers/matchers/checkCompanyType';
import {
  isCustomerUser,
  isGradingUser,
  isWarehouseUser,
} from 'shared/helpers/matchers/checkUserType';
import { isGlobalRefiningGroup } from 'shared/helpers/matchers/isGlobalRefiningGroup';
import { mapArray } from 'shared/helpers/parsers/select';
import { cssVar } from 'shared/helpers/styling/styling';

import { refreshToken } from 'utils/refreshToken';

import { FormRowCell } from 'pages/Packages/components/PackagesManagement/components/MultiPackagesCreate/components/IncomingPackagesFormList/components/IncomingPackageListTemplate/components/FormRowCell';
import { MultiPackagesFormHeader } from 'pages/Packages/components/PackagesManagement/components/MultiPackagesCreate/components/IncomingPackagesFormList/components/MultiPackagesFormHeader/MultiPackagesFormHeader';
import {
  configNames,
  configs,
  isProcessingPackage,
} from 'pages/Packages/components/PackagesManagement/components/PackageForm/components/PackageForm/fieldsConfig';

const IncomingPackageListTemplateBase = memo(
  ({
    change,
    packagesFieldsValues,
    formErrors,
    index,
    focusedCol,
    focusedRow,
    setFocusedRow,
    setFocusedCol,
    packagesQuantity,
    packageCountIncoming,
    shipmentPackagesLength,
    config,
    onRemove,
    edited,
    auth: {
      user: { userType, relatedCompany },
    },
    populateWeightTareDeclared,
    packagesData,
    fields,
    isPackingList,
    isEditMultiplePackages,
    isWizardEditMode,
    defaultMaterialType,
    ...props
  }) => {
    const [wasFocused, setWasFocused] = useState(false);
    const wizard = useContext(wizardContext);
    const wizardData = useSelector(state => getFormValues('wizard')(state));
    const dispatch = useDispatch();
    const isSamplePkg = packagesData[index]?.packageType?.value === packageType.sample;

    const setPackageNumber = pkg => {
      if (pkg?._id) {
        return (
          packagesData[index].packageNumber || wizard.assignedShipment.packages[index].packageNumber
        );
      } else {
        return packageCountIncoming - shipmentPackagesLength + (index + 1);
      }
    };

    useEffect(() => {
      if (index === 0) setWasFocused(true);
    }, [index]);

    useEffect(() => {
      if (isPackingList && !(isEditMultiplePackages || isWizardEditMode))
        change(`${props.package}.packageNumber`, index + 1);

      if ((isEditMultiplePackages || isWizardEditMode) && wizard.changedShipmentDestination) {
        change(`${props.package}.packageNumber`, setPackageNumber(packagesData[index]));
        change(`${props.package}.newPackageNumber`, renderPkgNumber());
      }

      if (isEditMultiplePackages || isWizardEditMode)
        change(`${props.package}.packageNumber`, setPackageNumber(packagesData[index]));

      change(`${props.package}.assignedLot`, wizardData.defaultPackagesValues.assignedLot);
      change(`${props.package}.customLotNumber`, wizardData.defaultPackagesValues.customLotNumber);
    }, [packagesQuantity, isPackingList, isEditMultiplePackages]);

    const multipliers = {
      mono_canned: 10,
      foil_canned: 6,
      DPF_canned: 45,
      beads_canned: 30,
    };

    const resetUnitFields = () => {
      change(`${props.package}.unitCount`, null);
    };

    const resetWeightFields = () => {
      change(`${props.package}.unitCount`, null);
      change(`${props.package}.weightGrossDeclared`, null);
      !packagesFieldsValues.unitCount && change(`${props.package}.weightTareDeclared`, null);
      change(`${props.package}.weightGrossActual`, null);
      change(`${props.package}.weightTareActual`, null);
    };

    const getPackageTypeOptions = useMemo(() => {
      if (
        [
          configNames.customer,
          configNames.grgForGlobal,
          configNames.grgForCustomer,
          configNames.incoming,
          configNames.warehouseForCustomer,
          configNames.packingList,
          configNames.editMultiplePackages,
        ].includes(config)
      ) {
        if (
          isPackingList ||
          props.lotDetails?.lot?.sentFromCompany.materialCountRequired ||
          !samplePackageAccess({
            userType,
            relatedCompany,
          })
        ) {
          return availablePackageType.all.filter(pkg => pkg !== packageType.sample);
        }

        return availablePackageType.allWithLabels;
      }

      if (isProcessingPackage(config)) {
        return availablePackageType.processing;
      }

      return [];
    }, [config]);

    const fromShipmentCompany =
      props.location && props.location.state && props.location.state.sentFromCompany;

    const companyType =
      (fromShipmentCompany && fromShipmentCompany.companyType) ||
      (props.lotDetails.lot &&
        props.lotDetails.lot.sentFromCompany &&
        props.lotDetails.lot.sentFromCompany.companyType);

    const companyName =
      props.lotDetails.lot &&
      props.lotDetails.lot.sentFromCompany &&
      props.lotDetails.lot.sentFromCompany.companyName;

    const getMaterialTypeDeclaredOptions = useMemo(() => {
      if (
        config === configNames.postMill ||
        config === configNames.postMix ||
        config === configNames.postShears
      ) {
        return availableMaterialTypeOutbound.all;
      }

      if (packagesData[index].packageType?.value === packageType.sample) {
        return availableMaterialTypesSamplePackage.all;
      }

      if (relatedCompany.materialCountRequired) {
        return availableMaterialTypeWithConverters.all;
      }

      if (
        isCustomerUser(userType) ||
        isAssayCompany(companyType) ||
        (isInternalCompany(companyType) && !isGlobalRefiningGroup(companyName))
      ) {
        return availableMaterialTypeForAssay.all;
      }

      if (isGradingUser(userType) || isGradingCompany(companyType)) {
        return availableMaterialTypeForGrading.all;
      }

      if (isGlobalRefiningGroup(companyName) || isWarehouseUser(userType)) {
        return availableMaterialTypeCommodities.all;
      }

      if (config === configNames.incoming) {
        return allMaterials;
      }

      if (config === configNames.packingList) {
        return availableMaterialTypesPackingList.all;
      }

      return [];
    }, [userType, companyType, companyName, config, packagesData[index].packageType]);

    const setMaterialType = materialTypeDeclared => {
      const materialType = materialTypeDeclared && materialTypeDeclared.value;

      denominationTable[materialType] === 'units' ? resetWeightFields() : resetUnitFields();

      if (
        packagesFieldsValues.materialTypeDeclared &&
        materialType &&
        materialTypesForUnitCountChange.all.includes(materialType) &&
        !isWarehouseUser(userType)
      ) {
        const multiplier = multipliers[materialType];
        const weightGrossDeclared = multiplier * packagesFieldsValues.unitCount;

        change(`${props.package}.unitCount`, packagesFieldsValues.unitCount);
        change(`${props.package}.weightGrossDeclared`, weightGrossDeclared || null);
      }

      if (defaultMaterialType && materialTypeDeclared?.value !== defaultMaterialType) {
        const message = PACKAGES.DOUBLE_CHECK_MATERIAL_TYPE(defaultMaterialType);

        dispatch(showSnackbar(infoOptions, message));
      }
    };

    const filter = ({ props: fieldsProps }) => {
      const {
        lotDetails: { lot },
      } = props;
      const fieldName = fieldsProps.name.replace(`${props.package}.`, '');

      if (config && config !== configNames.incoming) {
        return configs[configNames[config]].includes(fieldName);
      }
      if (lot && isGlobalRefiningGroup(lot.sentFromCompany.companyName)) {
        return configs[configNames.grgForGlobal].includes(fieldName);
      }
      if (isWarehouseUser(userType)) {
        return configs[configNames.warehouseForCustomer].includes(fieldName);
      }
      return configs[configNames.customer].includes(fieldName);
    };

    const checkDenominatedIn = value => {
      const materialTypeOfPkg = packagesFieldsValues.materialTypeDeclared;
      let unitType = denominationTable[materialTypeOfPkg && materialTypeOfPkg.value];

      if (unitType === denominatedIn.unitsWeight) {
        unitType = denominatedIn.units;
      }
      return unitType === value;
    };

    const disableUnitCount = () =>
      isWarehouseUser(userType)
        ? !(
            isGlobalRefiningGroup(props.lotDetails?.lot?.sentFromCompany?.companyName) ||
            isSamplePkg
          )
        : checkDenominatedIn('weight');

    const sectionWithError =
      formErrors.packages &&
      formErrors.packages[index] &&
      Object.values(formErrors.packages[index]).length;

    const focusRow = () => {
      const toTouch = [];

      formErrors.packages &&
        formErrors.packages[focusedRow] &&
        Object.keys(formErrors.packages[focusedRow]).forEach(key =>
          toTouch.push(`packages[${focusedRow}].${key}`),
        );

      props.touch(...toTouch);

      setFocusedRow(index);
      setWasFocused(true);
    };

    const focusCol = (e, name) => {
      refreshToken();
      const fieldName = ((e && e.target.name) || name).replace(`${props.package}.`, '');
      setFocusedCol(fieldName);
    };

    const setNextRow = e => {
      const { lot } = props.lotDetails;

      if (e.keyCode === 9 && !e.shiftKey) {
        e.preventDefault();
        setFocusedRow(index + 1);
        props.touch(`packages[${index}].weightTareDeclared`);
        props.touch(`packages[${index}].weightTareActual`);
        setWasFocused(true);

        if (
          !isGlobalRefiningGroup(lot?.sentFromCompany?.companyName) &&
          config === configNames.incoming
        )
          populateWeightTareDeclared(index)(e);

        if (props.oneOrMultiLots === PACKAGES.LOTS_FOR_PACKAGES.SAME_LOT) {
          if (wizard.config === configNames.packingList) {
            focusCol(null, 'labelId');
          } else {
            focusCol(null, 'customerInstructions');
          }
        } else {
          focusCol(null, 'assignedLot');
        }
      }
    };

    const setPrevRow = e => {
      if (e.keyCode === 9 && e.shiftKey) {
        e.preventDefault();
        setFocusedRow(index - 1);
        config === configNames.incoming
          ? focusCol(null, 'weightTareDeclared')
          : focusCol(null, 'weightTareActual');
      }
    };

    const setWeightGrossDeclared = ({ target: { value } }) => {
      if (
        packagesFieldsValues.materialTypeDeclared &&
        packagesFieldsValues.materialTypeDeclared.value &&
        materialTypesForUnitCountChange.all.includes(
          packagesFieldsValues.materialTypeDeclared.value,
        )
      ) {
        const multiplier = multipliers[packagesFieldsValues.materialTypeDeclared.value];
        const weightGrossDeclared = multiplier * value;

        change(`${props.package}.weightGrossDeclared`, weightGrossDeclared || null);
      }
    };

    const areInstructionsRequired =
      packagesFieldsValues.materialTypeDeclared &&
      packagesFieldsValues.materialTypeDeclared.value === materialTypeForAssay.other;

    const truncateStr = (str, num) => (str?.length > num ? `${str.slice(0, num)}...` : str || '');

    const navigate = e =>
      navigateTable(
        e,
        {
          current: index,
          max: fields.length - 1,
          fields: props.fieldNames,
        },
        {
          row: setFocusedRow,
          column: setFocusedCol,
          triggerErrors: setWasFocused,
        },
        refreshToken,
      );

    const allowToRemove = () =>
      fields.length > 1 &&
      packagesData.filter(pkg => pkg.status === packageStatuses.deleted).length < fields.length - 1;

    const renderPkgNumber = () => {
      if (isPackingList || isEditMultiplePackages) {
        if (wizard.changedShipmentDestination) {
          return packageCountIncoming + (index + 1);
        } else if (packagesData[index]._id) {
          return packagesData[index].packageNumber;
        } else {
          return packageCountIncoming - shipmentPackagesLength + (index + 1) || index + 1;
        }
      } else {
        return `${index + 1} / ${packagesQuantity}`;
      }
    };
    const resetMaterialType = value => {
      const materialTypeDeclared = packagesData[index]?.materialTypeDeclared?.value;

      if (!materialTypeDeclared) return;

      if (
        value === packageType.sample &&
        !availableMaterialTypesSamplePackage.all.includes(materialTypeDeclared)
      ) {
        change(`${props.package}.materialTypeDeclared`, null);
      }
    };

    const handleFieldArrayChange = useCallback(
      option => {
        resetMaterialType(option?.value);

        if (isWarehouseUser(userType) && option?.value !== packageType.sample) {
          resetUnitFields();
        }

        if (!wizard.hiddenStepsCount) {
          return;
        }
      },

      [wizard.hiddenStepsCount, focusedRow, wizardData.packages],
    );

    return (
      <FieldsFilter filterFunc={filter}>
        {index === 0 && (
          <MultiPackagesFormHeader
            package={props.package}
            instructionsRequired={areInstructionsRequired}
            config={config}
            requiredFields={{
              unitCount: isSamplePkg,
            }}
          />
        )}

        {props.isPlaceholder ? (
          <PackageSection focused config={config} isBlocked>
            <FormRowCell name={`${props.package}.number`} noBorder>
              {packagesData[index].packageNumber}
            </FormRowCell>
            <FormRowCell name={`${props.package}.labelId`} noBorder>
              <StatusIndicator status={packageStatuses.deleted} />
            </FormRowCell>
          </PackageSection>
        ) : focusedRow === index ? (
          <>
            {index !== 0 && (
              <MultiPackagesFormHeader
                package={props.package}
                instructionsRequired={areInstructionsRequired}
                config={config}
                requiredFields={{
                  unitCount: isSamplePkg,
                }}
              />
            )}
            <PackageSection focused edited={edited} config={config}>
              <FormRowCell name={`${props.package}.number`} noBorder>
                {renderPkgNumber()}
              </FormRowCell>
              <Field
                name={`${props.package}.assignedLot`}
                component={TinyFieldSelect}
                forwardRef
                options={parseLotsSelectData(props.assignedLots.data)}
                disabled={props.oneOrMultiLots === PACKAGES.LOTS_FOR_PACKAGES.SAME_LOT}
                onFocus={focusCol}
                focused={focusedCol === 'assignedLot'}
                onKeyDown={e => setPrevRow(e) || navigate(e)}
              />
              <Field
                name={`${props.package}.labelId`}
                component={TinyFieldInput}
                forwardRef
                type="text"
                onClick={focusCol}
                focused={focusedCol === 'labelId'}
                onKeyDown={navigate}
                normalize={value => value.toUpperCase()}
              />
              <Field
                name={`${props.package}.plant`}
                component={TinyFieldInput}
                forwardRef
                type="text"
                onClick={focusCol}
                focused={focusedCol === 'plant'}
                onKeyDown={navigate}
                normalize={value => value.toUpperCase()}
              />
              <Field
                name={`${props.package}.customerInstructions`}
                component={TinyFieldTextarea}
                forwardRef
                onClick={focusCol}
                focused={focusedCol === 'customerInstructions'}
                onKeyDown={e =>
                  (props.oneOrMultiLots === PACKAGES.LOTS_FOR_PACKAGES.SAME_LOT && setPrevRow(e)) ||
                  navigate(e)
                }
              />
              <Field
                name={`${props.package}.packageType`}
                component={TinyFieldSelect}
                forwardRef
                options={mapArray(getPackageTypeOptions)}
                onFocus={focusCol}
                focused={focusedCol === 'packageType'}
                onKeyDown={navigate}
                onChange={handleFieldArrayChange}
              />
              <Field
                name={`${props.package}.materialClass`}
                component={TinyFieldSelect}
                forwardRef
                options={mapArray(availableMaterialClass.all)}
                onFocus={focusCol}
                focused={focusedCol === 'materialClass'}
                onKeyDown={navigate}
              />
              <Field
                name={`${props.package}.materialTypeDeclared`}
                component={TinyFieldSelect}
                forwardRef
                options={mapArray(getMaterialTypeDeclaredOptions)}
                onChange={setMaterialType}
                onFocus={focusCol}
                focused={focusedCol === 'materialTypeDeclared'}
                onKeyDown={navigate}
              />
              <Field
                name={`${props.package}.unitCount`}
                component={TinyFieldInput}
                forwardRef
                type="text"
                disabled={disableUnitCount()}
                onClick={focusCol}
                onChange={
                  !wizard.isGlobalCompany && !isWarehouseUser(userType) && setWeightGrossDeclared
                }
                focused={focusedCol === 'unitCount'}
                onKeyDown={navigate}
                suffix={PACKAGES.UNITS}
                suffixSize={45}
                padding={10}
              />
              <Field
                name={`${props.package}.weightGrossActual`}
                component={TinyFieldInput}
                forwardRef
                type="text"
                onClick={focusCol}
                focused={focusedCol === 'weightGrossActual'}
                onKeyDown={navigate}
              />
              <Field
                name={`${props.package}.weightTareActual`}
                component={TinyFieldInput}
                forwardRef
                type="text"
                onClick={focusCol}
                focused={focusedCol === 'weightTareActual'}
                onKeyDown={e => setNextRow(e) || navigate(e)}
              />
              <Field
                name={`${props.package}.weightGrossDeclared`}
                component={TinyFieldInput}
                forwardRef
                type="text"
                onClick={focusCol}
                focused={focusedCol === 'weightGrossDeclared'}
                onKeyDown={navigate}
                suffix={PACKAGES.LBS}
                suffixSize={45}
                padding={10}
              />
              <Field
                name={`${props.package}.weightTareDeclared`}
                component={TinyFieldInput}
                forwardRef
                type="text"
                onClick={focusCol}
                focused={focusedCol === 'weightTareDeclared'}
                onBlur={populateWeightTareDeclared(index)}
                onKeyDown={e => {
                  setNextRow(e) || navigate(e);
                }}
                suffix={PACKAGES.LBS}
                suffixSize={45}
                padding={10}
              />
              <FormRowCell name={`${props.package}.removePackage`} noBorder>
                {allowToRemove() && (
                  <IconButton icon="icon-bin" color={cssVar('whiteGRG')} onClick={onRemove} />
                )}
              </FormRowCell>
            </PackageSection>
          </>
        ) : (
          <PackageSection
            hasError={sectionWithError && wasFocused}
            edited={edited}
            onClick={focusRow}
            config={config}
          >
            <FormRowCell name={`${props.package}.number`} noBorder>
              {renderPkgNumber()}
            </FormRowCell>
            <FormRowCell name={`${props.package}.assignedLot`} onClick={focusCol}>
              {(packagesFieldsValues.assignedLot && packagesFieldsValues.assignedLot.label) || ''}
            </FormRowCell>
            <FormRowCell name={`${props.package}.labelId`} onClick={focusCol}>
              {truncateStr(packagesFieldsValues.labelId, 15)}
            </FormRowCell>
            <FormRowCell name={`${props.package}.plant`} onClick={focusCol}>
              {truncateStr(packagesFieldsValues.plant, 15)}
            </FormRowCell>
            <FormRowCell name={`${props.package}.customerInstructions`} onClick={focusCol}>
              {truncateStr(packagesFieldsValues.customerInstructions, 22)}
            </FormRowCell>
            <FormRowCell name={`${props.package}.packageType`} onClick={focusCol}>
              {(packagesFieldsValues.packageType && packagesFieldsValues.packageType.label) || ''}
            </FormRowCell>
            <FormRowCell name={`${props.package}.materialClass`} onClick={focusCol}>
              {(packagesFieldsValues.materialClass && packagesFieldsValues.materialClass.label) ||
                ''}
            </FormRowCell>
            <FormRowCell name={`${props.package}.materialTypeDeclared`} onClick={focusCol}>
              {(packagesFieldsValues.materialTypeDeclared &&
                packagesFieldsValues.materialTypeDeclared.label) ||
                ''}
            </FormRowCell>
            <FormRowCell name={`${props.package}.unitCount`} onClick={focusCol}>
              {packagesFieldsValues.unitCount || ''}
            </FormRowCell>
            <FormRowCell name={`${props.package}.weightGrossActual`} onClick={focusCol}>
              {packagesFieldsValues.weightGrossActual || ''}
            </FormRowCell>
            <FormRowCell name={`${props.package}.weightTareActual`} onClick={focusCol}>
              {packagesFieldsValues.weightTareActual || ''}
            </FormRowCell>
            <FormRowCell name={`${props.package}.weightGrossDeclared`} onClick={focusCol}>
              {packagesFieldsValues.weightGrossDeclared?.toString() || ''}
            </FormRowCell>
            <FormRowCell name={`${props.package}.weightTareDeclared`} onClick={focusCol}>
              {packagesFieldsValues.weightTareDeclared?.toString() || ''}
            </FormRowCell>
            <FormRowCell name={`${props.package}.removePackage`} noBorder>
              {allowToRemove() && (
                <IconButton icon="icon-bin" color={cssVar('whiteGRG')} onClick={onRemove} />
              )}
            </FormRowCell>
          </PackageSection>
        )}
      </FieldsFilter>
    );
  },
);

const forbiddenValues = ['processVia', 'mixVia'];

IncomingPackageListTemplateBase.defaultProps = {
  packageCountIncoming: 0,
};

const selector = formValueSelector(formName);
const getRegisteredNames = store => {
  return Array.from(
    new Set(
      Object.keys(store.form.wizard.registeredFields || [])
        .map(name => name.split('].')[1])
        .filter(value => Boolean(value) && !forbiddenValues.includes(value)),
    ),
  );
};

const mapStateToProps = (state, props) => ({
  packagesFieldsValues: selector(state, 'packages')[props.index],
  formErrors: getFormSyncErrors(formName)(state),
  fieldNames: getRegisteredNames(state),
  lotDetails: state.lotDetails,
});

IncomingPackageListTemplateBase.propTypes = {
  change: PropTypes.func.isRequired,
  edited: PropTypes.bool,
  packagesFieldsValues: PropTypes.object.isRequired,
  formErrors: PropTypes.object,
  index: PropTypes.number.isRequired,
  focusedCol: PropTypes.string,
  focusedRow: PropTypes.number,
  setFocusedRow: PropTypes.func.isRequired,
  setFocusedCol: PropTypes.func.isRequired,
  packagesQuantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  config: PropTypes.string.isRequired,
  onRemove: PropTypes.func,
  auth: PropTypes.object.isRequired,
  package: PropTypes.string.isRequired,
  pkgQuantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  oneOrMultiLots: PropTypes.string.isRequired,
  forLot: PropTypes.object,
  header: PropTypes.string,
  location: PropTypes.object,
  packagesData: PropTypes.array.isRequired,
  isPackingList: PropTypes.bool,
  isPlaceholder: PropTypes.bool,
  isEditMultiplePackages: PropTypes.bool,
  packageCountIncoming: PropTypes.number,
  shipmentPackagesLength: PropTypes.number,
};

const IncomingPackageListTemplate = connect(mapStateToProps)(IncomingPackageListTemplateBase);

export { IncomingPackageListTemplate };
