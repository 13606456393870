import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';

import { FieldDate } from 'shared/components/Fields/FieldDate/FieldDate';
import { FieldInput } from 'shared/components/Fields/FieldInput/FieldInput';
import { FieldSelect } from 'shared/components/Fields/FieldSelect/FieldSelect';
import { Section, Form } from 'shared/components/Form';
import { outgoingSections } from 'shared/helpers/constants/lots/outgoingSections';
import { cancelModal } from 'shared/helpers/constants/modalConstants';
import { shipmentDirections } from 'shared/helpers/constants/shipments/shipmentDirections';
import { SHIPMENTS } from 'shared/helpers/constants/shipments/shipmentsConstants';
import { validate } from 'shared/helpers/validations/shipments/createOutgoingShipmentValidation';

import dateAdapter from 'utils/date/dateAdapter';
import { goBackOrTo } from 'utils/history';

class OutgoingShipmentFormBase extends Component {
  parseLotsSelectData(lots) {
    return lots.map(lot => ({
      value: lot._id,
      label: lot.grgLotNumber,
      grgLotNumber: lot.grgLotNumber,
    }));
  }

  componentDidMount() {
    const { editMode, getAssignedLotsForOutgoingShipment, outgoingLotsOverview } = this.props;
    const selectedOutgoing = outgoingLotsOverview[outgoingSections.readyToShip.name].selected;

    getAssignedLotsForOutgoingShipment();

    if (editMode) {
      this.fillData();
    } else if (selectedOutgoing && selectedOutgoing.length) {
      this.setField('assignedLot', this.parseLotsSelectData(selectedOutgoing));
    }
  }

  setField = (key, value) => {
    this.props.change(key, value);
  };

  fillData = () => {
    const { shipment } = this.props.shipmentDetails;

    this.setField('assignedLot', this.parseLotsSelectData(shipment.populatedLots));
    this.setField('estPickupDate', dateAdapter(shipment.estPickupDate));
    this.setField('estDeliveryDate', dateAdapter(shipment.estDeliveryDate));
    this.setField('shipmentSeal', shipment.shipmentSeal);
    this.setField('shipmentCompany', shipment.shipmentCompany);
    this.setField('loadNumber', shipment.loadNumber);
  };

  pathToRedirect = shipmentId => {
    const { location } = this.props;

    return location.pathname.includes(shipmentDirections.outgoing)
      ? `/shipments/outgoing/${shipmentId}`
      : `/shipments/${shipmentId}`;
  };

  modalOpen = () => {
    const { editMode, shipmentDetails, openModal } = this.props;

    openModal(cancelModal, () => {
      goBackOrTo(editMode ? this.pathToRedirect(shipmentDetails.shipment._id) : '/shipments');
    });
  };

  parseLots = ({ lots, editMode, shipmentDetails }) =>
    editMode ? [...lots.data, ...shipmentDetails.shipment.populatedLots] : lots.data;

  render() {
    const { handleSubmit, assignedLots, editMode, shipmentDetails } = this.props;

    const parsedLots = compose(
      this.parseLotsSelectData,
      this.parseLots,
    )({ lots: assignedLots, editMode, shipmentDetails });

    return (
      <Form
        header={SHIPMENTS.SHIPMENT}
        onSubmit={handleSubmit}
        onCancel={this.modalOpen}
        submitDisabled={this.props.invalid}
      >
        <Section
          template={[
            'relatedCompany assignedLot',
            'estPickupDate estDeliveryDate',
            'shipmentCompany shipmentSeal',
            'loadNumber .',
          ]}
        >
          <Field
            name="relatedCompany"
            component={FieldSelect}
            label={SHIPMENTS.RELATED_COMPANY}
            field="required"
            disabled
          />
          <Field
            name="assignedLot"
            component={FieldSelect}
            options={parsedLots}
            label={SHIPMENTS.ASSIGNED_LOTS}
            field="required"
            multi
            wrap
          />
          <Field
            name="estPickupDate"
            component={FieldDate}
            label={SHIPMENTS.SHIP_DATE}
            field="required"
            maxDate={this.props.estDeliveryDate}
          />
          <Field
            name="estDeliveryDate"
            component={FieldDate}
            label={SHIPMENTS.EST_DELIVERY_DATE}
            field="required"
            minDate={this.props.estPickupDate}
          />
          <Field name="shipmentCompany" component={FieldInput} label={SHIPMENTS.SHIPMENT_COMPANY} />
          <Field name="shipmentSeal" component={FieldInput} label={SHIPMENTS.SHIPMENT_SEAL} />
          <Field name="loadNumber" component={FieldInput} label={SHIPMENTS.LOAD_NUMBER} />
        </Section>
      </Form>
    );
  }
}

const formName = 'OutgoingShipmentForm';
const selector = formValueSelector(formName);
const mapStateToProps = state => ({
  OutgoingShipmentForm: state.OutgoingShipmentForm,
  estPickupDate: selector(state, 'estPickupDate'),
  estDeliveryDate: selector(state, 'estDeliveryDate'),
});

const ShipmentForm = reduxForm({
  form: formName,
  validate,
})(OutgoingShipmentFormBase);

const connectedForm = connect(mapStateToProps)(ShipmentForm);

export { connectedForm as OutgoingShipmentForm, ShipmentForm, OutgoingShipmentFormBase };
