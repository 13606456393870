function parseLocationFilters(companyId, filters, limit, page, searchFilter) {
  return {
    ...filters,
    companyId,
    searchFilter,
    limit,
    page,
  };
}

export { parseLocationFilters };
