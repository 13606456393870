import axios from 'axios';

import { getGlobalCreatedPackage } from 'actions/Packages/packageDetails';
import { showSnackbar } from 'actions/shared/snackbar';

import {
  dangerOptions,
  successOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

const API_HOST_AUTH = process.env.REACT_APP_API;

export const changePackagesStateActionType = 'CHANGE_PACKAGES_STATE';
const changePackagesStateAction = () => ({
  type: changePackagesStateActionType,
});

export const changePackagesStateSuccessActionType = `${changePackagesStateActionType}_SUCCESS`;
const changePackagesStateSuccessAction = () => ({
  type: changePackagesStateSuccessActionType,
});

export const changePackagesStateFailureActionType = `${changePackagesStateActionType}_FAILURE`;
const changePackagesStateFailureAction = () => ({
  type: changePackagesStateFailureActionType,
});

export const changePackagesState = (actionType, object) => dispatch => {
  dispatch(changePackagesStateAction());

  const packages = Array.isArray(object)
    ? object.map(({ id, _id }) => ({ _id: id || _id }))
    : [{ _id: object.id }];

  return axios
    .patch(`${API_HOST_AUTH}/packages/${actionType}`, { packages })
    .then(({ data }) => {
      dispatch(changePackagesStateSuccessAction());
      if (!Array.isArray(object)) dispatch(getGlobalCreatedPackage(object.id));
      dispatch(showSnackbar(successOptions, data.message));

      return {
        message: data.message,
      };
    })
    .catch(({ data }) => {
      dispatch(changePackagesStateFailureAction());
      dispatch(showSnackbar(dangerOptions, data.message));

      return Promise.reject(data);
    });
};
