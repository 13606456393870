import { FILES_CONSTANTS } from 'shared/helpers/constants/filesConstants';
import { message } from 'shared/helpers/constants/lots/createLotConstants';
import { UPDATE_LOT_INFO } from 'shared/helpers/constants/lots/updateLotInfoConstants';
import { replaceEmpty } from 'shared/helpers/parsers/text';
import { commaValidator } from 'shared/helpers/validations/commaValidator';
import {
  CUSTOM_LOT_NUMBER_REGEX,
  CAPITAL_ALPHANUM_REGEX,
  GLOBAL_MOISTURE_TEST_REGEX,
  PRICE_REGEX,
  POSITIVE_INTEGER_REGEX,
} from 'shared/helpers/validations/regularExpressions';

function validate(values, { lotsFiles, initialValues }) {
  const { files } = lotsFiles;
  const errors = {};
  const deductionArrayErrors = [];
  const creditArrayErrors = [];
  const validFileNameRegexp = '[^\\x20-\\x7E]+';
  const multipleFormatsRegexp = '\\.pdf|.xls|.xlsx';
  const pdfFileRegexp = '\\.pdf$';

  if (files) {
    if (files.countSheet) {
      if (files.countSheet.length && files.countSheet[0].size > FILES_CONSTANTS.MAX_FILE_SIZE) {
        errors.countSheet = message.INVALID_FILE_SIZE;
      }

      if (new RegExp(validFileNameRegexp, 'g').test(files.countSheet[0].name)) {
        errors.countSheet = message.INVALID_FILE_NAME;
      }

      if (
        files.countSheet.length &&
        files.countSheet &&
        !new RegExp(multipleFormatsRegexp, 'i').test(files.countSheet[0].name)
      ) {
        errors.countSheet = message.INVALID_COUNT_SHEET_FILE_TYPE;
      }
    }

    if (files.gradingReceipt) {
      if (
        files.gradingReceipt.length &&
        files.gradingReceipt[0].size > FILES_CONSTANTS.MAX_FILE_SIZE
      ) {
        errors.gradingReceipt = message.INVALID_FILE_SIZE;
      }

      if (new RegExp(validFileNameRegexp, 'g').test(files.gradingReceipt[0].name)) {
        errors.gradingReceipt = message.INVALID_FILE_NAME;
      }

      if (
        files.gradingReceipt.length &&
        files.gradingReceipt &&
        !new RegExp(pdfFileRegexp, 'i').test(files.gradingReceipt[0].name)
      ) {
        errors.gradingReceipt = message.INVALID_FILE_TYPE_PDF;
      }
    }

    if (files.stillwaterShipmentSummary) {
      if (
        files.stillwaterShipmentSummary.length &&
        files.stillwaterShipmentSummary[0].size > FILES_CONSTANTS.MAX_FILE_SIZE
      ) {
        errors.stillwaterShipmentSummary = message.INVALID_FILE_SIZE;
      }

      if (new RegExp(validFileNameRegexp, 'g').test(files.stillwaterShipmentSummary[0].name)) {
        errors.stillwaterShipmentSummary = message.INVALID_FILE_NAME;
      }

      if (
        files.stillwaterShipmentSummary.length &&
        files.stillwaterShipmentSummary &&
        !new RegExp(pdfFileRegexp, 'i').test(files.stillwaterShipmentSummary[0].name)
      ) {
        errors.stillwaterShipmentSummary = message.INVALID_FILE_TYPE_PDF;
      }

      if (!values.stillwaterShipmentId) {
        errors.stillwaterShipmentId = UPDATE_LOT_INFO.INVALID_STILLWTER_SHIPMENT_ID_EMPTY;
      } else if (!CAPITAL_ALPHANUM_REGEX.test(values.stillwaterShipmentId)) {
        errors.stillwaterShipmentId = UPDATE_LOT_INFO.INVALID_STILLWTER_SHIPMENT_ID_INVALID;
      } else if (values.stillwaterShipmentId.length > 15) {
        errors.stillwaterShipmentId = UPDATE_LOT_INFO.INVALID_STILLWTER_SHIPMENT_ID_LENGTH;
      }

      if (!values.materialType) {
        errors.materialType = UPDATE_LOT_INFO.INVALID_MATERIAL_TYPE_EMPTY;
      }
    }

    if (files.stillwaterFinalInvoice) {
      if (
        files.stillwaterFinalInvoice.length &&
        files.stillwaterFinalInvoice[0].size > FILES_CONSTANTS.MAX_FILE_SIZE
      ) {
        errors.stillwaterFinalInvoice = message.INVALID_FILE_SIZE;
      }

      if (new RegExp(validFileNameRegexp, 'g').test(files.stillwaterFinalInvoice[0].name)) {
        errors.stillwaterFinalInvoice = message.INVALID_FILE_NAME;
      }

      if (
        files.stillwaterFinalInvoice.length &&
        files.stillwaterFinalInvoice &&
        !new RegExp(pdfFileRegexp, 'i').test(files.stillwaterFinalInvoice[0].name)
      ) {
        errors.stillwaterFinalInvoice = message.INVALID_FILE_TYPE_PDF;
      }

      if (!values.stillwaterShipmentId) {
        errors.stillwaterShipmentId = UPDATE_LOT_INFO.INVALID_STILLWTER_SHIPMENT_ID_EMPTY;
      } else if (!CAPITAL_ALPHANUM_REGEX.test(values.stillwaterShipmentId)) {
        errors.stillwaterShipmentId = UPDATE_LOT_INFO.INVALID_STILLWTER_SHIPMENT_ID_INVALID;
      } else if (values.stillwaterShipmentId.length > 15) {
        errors.stillwaterShipmentId = UPDATE_LOT_INFO.INVALID_STILLWTER_SHIPMENT_ID_LENGTH;
      }

      if (!values.materialType) {
        errors.materialType = UPDATE_LOT_INFO.INVALID_MATERIAL_TYPE_EMPTY;
      }
    }
  }

  if (!values.sentFromCompany) {
    errors.sentFromCompany = message.INVALID_SENT_FROM_COMPANY_EMPTY;
  }

  if (!values.sentFromCompanyLocation) {
    errors.sentFromCompanyLocation = message.INVALID_SENT_FROM_COMPANY_LOCATION_EMPTY;
  }

  if (!values.statusForGlobal) {
    errors.statusForGlobal = message.INVALID_STATUS_FOR_GLOBAL_EMPTY;
  }

  if (values.processUponDelivery === undefined || values.processUponDelivery === '') {
    errors.processUponDelivery = message.INVALID_PROCESS_UPON_DELIVERY_EMPTY;
  }
  if (initialValues.stillwaterInitialPayment || values.stillwaterInitialPayment !== null) {
    let stillwaterInitialPaymentErrors;
    const stillwaterInitialPayment = values.stillwaterInitialPayment;

    if (
      !PRICE_REGEX.test(stillwaterInitialPayment) &&
      stillwaterInitialPayment !== undefined &&
      stillwaterInitialPayment !== ''
    ) {
      stillwaterInitialPaymentErrors = message.INVALID_STILLWATER_INITIAL_PAYMENT;
    } else if (parseFloat(stillwaterInitialPayment) < 0) {
      stillwaterInitialPaymentErrors = message.INVALID_STILLWATER_INITIAL_PAYMENT_NEGATIVE;
    } else if (stillwaterInitialPayment?.length > 16) {
      stillwaterInitialPaymentErrors = message.INVALID_STILLWATER_INITIAL_PAYMENT_TOO_LARGE;
    }

    errors.stillwaterInitialPayment = stillwaterInitialPaymentErrors;
  }

  if (initialValues.stillwaterFinalPayment || values.stillwaterFinalPayment !== null) {
    let stillwaterFinalPaymentErrors;
    const stillwaterFinalPayment = values.stillwaterFinalPayment;

    if (
      !PRICE_REGEX.test(stillwaterFinalPayment) &&
      stillwaterFinalPayment !== undefined &&
      stillwaterFinalPayment !== ''
    ) {
      stillwaterFinalPaymentErrors = message.INVALID_STILLWATER_FINAL_PAYMENT;
    } else if (parseFloat(stillwaterFinalPayment) < 0) {
      stillwaterFinalPaymentErrors = message.INVALID_STILLWATER_FINAL_PAYMENT_NEGATIVE;
    } else if (stillwaterFinalPayment?.length > 16) {
      stillwaterFinalPaymentErrors = message.INVALID_STILLWATER_FINAL_PAYMENT_TOO_LARGE;
    }

    errors.stillwaterFinalPayment = stillwaterFinalPaymentErrors;
  }

  if (
    String(values.stillwaterInitialPayment) &&
    values.stillwaterInitialPayment !== null &&
    !values.stillwaterInitialPaymentDate
  ) {
    errors.stillwaterInitialPaymentDate = message.STILLWATER_INITIAL_PAYMENT_WITHOUT_DATE;
  }

  if (
    String(values.stillwaterFinalPayment) &&
    values.stillwaterFinalPayment !== null &&
    !values.stillwaterFinalPaymentDate
  ) {
    errors.stillwaterFinalPaymentDate = message.STILLWATER_FINAL_PAYMENT_WITHOUT_DATE;
  }

  if (
    initialValues.stillwaterInitialPaymentDate &&
    !values.stillwaterInitialPaymentDate &&
    values.stillwaterInitialPayment
  ) {
    errors.stillwaterInitialPaymentDate = message.INVALID_STILLWATER_INITIAL_PAYMENT_DATE_NULL;
  }

  if (
    initialValues.stillwaterFinalPaymentDate &&
    !values.stillwaterFinalPaymentDate &&
    values.stillwaterFinalPayment
  ) {
    errors.stillwaterFinalPaymentDate = message.INVALID_STILLWATER_FINAL_PAYMENT_DATE_NULL;
  }

  if (values.truckingDeduction) {
    let truckingDeductionErrors;
    const truckingDeduction = values.truckingDeduction;

    if (
      !PRICE_REGEX.test(truckingDeduction) &&
      truckingDeduction !== undefined &&
      truckingDeduction !== ''
    ) {
      truckingDeductionErrors = message.INVALID_TRUCKING;
    } else if (parseFloat(truckingDeduction) < 0) {
      truckingDeductionErrors = message.INVALID_TRUCKING_NEGATIVE;
    } else if (truckingDeduction?.length > 12) {
      truckingDeductionErrors = message.INVALID_TRUCKING_TOO_LARGE;
    }

    errors.truckingDeduction = truckingDeductionErrors;
  }

  values.deductions &&
    values.deductions.forEach((deduction, index) => {
      const deductionErrors = {};

      if (
        !PRICE_REGEX.test(deduction?.value) &&
        deduction?.value !== undefined &&
        deduction?.value !== ''
      ) {
        deductionErrors.value = message.INVALID_DEDUCTION;
      } else if (parseFloat(deduction?.value) < 0) {
        deductionErrors.value = message.INVALID_DEDUCTION_NEGATIVE;
      } else if (deduction?.value?.length > 12) {
        deductionErrors.value = message.INVALID_DEDUCTION_TOO_LARGE;
      }

      if (deduction?.description && deduction?.description.length > 50) {
        deductionErrors.description = message.INVALID_DEDUCTION_DESCRIPTION;
      }

      if (
        deduction?.value !== undefined &&
        parseFloat(deduction?.value) !== null &&
        deduction?.value !== ''
      ) {
        if (!deduction?.description || !deduction?.description.trim()) {
          deductionErrors.description = message.INVALID_DEDUCTION_DESCRIPTION_EMPTY;
        }
      }

      deductionArrayErrors[index] = deductionErrors;
    });

  values.credits &&
    values.credits.forEach((credit, index) => {
      const creditErrors = {};

      if (!PRICE_REGEX.test(credit?.value) && credit?.value !== undefined && credit?.value !== '') {
        creditErrors.value = message.INVALID_CREDIT;
      } else if (parseFloat(credit?.value) < 0) {
        creditErrors.value = message.INVALID_CREDIT_NEGATIVE;
      } else if (credit?.value?.length > 12) {
        creditErrors.value = message.INVALID_CREDIT_TOO_LARGE;
      }

      if (credit?.description && credit?.description.length > 50) {
        creditErrors.description = message.INVALID_CREDIT_DESCRIPTION;
      }

      if (
        credit?.value !== undefined &&
        parseFloat(credit?.value) !== null &&
        credit?.value !== ''
      ) {
        if (!credit.description || !credit.description.trim()) {
          creditErrors.description = message.INVALID_CREDIT_DESCRIPTION_EMPTY;
        }
      }

      creditArrayErrors[index] = creditErrors;
    });

  if (values.customerInstructions && values.customerInstructions.length > 100) {
    errors.customerInstructions = message.INVALID_CUSTOMER_INSTRUCTIONS;
  }

  if (!values.globalMoistureTest) {
    errors.globalMoistureTest = '';
  } else if (
    !GLOBAL_MOISTURE_TEST_REGEX.test(values.globalMoistureTest) ||
    replaceEmpty(values.globalMoistureTest, '').length > 5
  ) {
    errors.globalMoistureTest = message.INVALID_GLOBAL_MOISTURE_TEST;
  } else if (
    parseFloat(values.globalMoistureTest) < 0 ||
    parseFloat(values.globalMoistureTest) > 10
  ) {
    errors.globalMoistureTest = message.INVALID_GLOBAL_MOISTURE_TEST_RANGE;
  }

  if (
    values.customLotNumber &&
    (!CUSTOM_LOT_NUMBER_REGEX.test(values.customLotNumber) || values.customLotNumber.length > 15)
  ) {
    errors.customLotNumber = message.INVALID_CUSTOM_LOT_NUMBER_FORMAT;
  }

  if (!!values.catMono) {
    if (!POSITIVE_INTEGER_REGEX.test(values.catMono)) errors.catMono = message.MONO_FORMAT;
    if (values.catMono.length > 6) errors.catMono = message.MONO_LOO_LONG;
  }

  if (!!values.catFoil) {
    if (!POSITIVE_INTEGER_REGEX.test(values.catFoil)) errors.catFoil = message.FOIL_FORMAT;
    if (values.catFoil.length > 6) errors.catFoil = message.FOIL_LOO_LONG;
  }

  if (!!values.catEmpty) {
    if (!POSITIVE_INTEGER_REGEX.test(values.catEmpty)) errors.catEmpty = message.EMPTY_FORMAT;
    if (values.catEmpty.length > 6) errors.catEmpty = message.EMPTY_LOO_LONG;
  }

  if (!!values.catAft) {
    if (!POSITIVE_INTEGER_REGEX.test(values.catAft)) errors.catAft = message.AFT_FORMAT;
    if (values.catAft.length > 6) errors.catAft = message.AFT_LOO_LONG;
  }

  if (!!values.catGrgQtyTotal) {
    if (!POSITIVE_INTEGER_REGEX.test(values.catGrgQtyTotal))
      errors.catGrgQtyTotal = message.GRG_QTY_TOTAL_FORMAT;
    if (values.catGrgQtyTotal.length > 6) errors.catGrgQtyTotal = message.GRG_QTY_TOTAL_LOO_LONG;
  }

  commaValidator(
    {
      globalMoistureTest: values.globalMoistureTest,
      deductions: values.deductions,
      credits: values.credits,
    },
    errors,
  );

  return {
    ...errors,
    deductions: deductionArrayErrors,
    credits: creditArrayErrors,
  };
}

export { validate };
