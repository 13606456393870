import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const createPayoutRuleActionType = 'CREATE_PAYOUT_RULE';
const createPayoutRuleAction = () => ({
  type: createPayoutRuleActionType,
});

const createPayoutRuleSuccessActionType = `${createPayoutRuleActionType}_SUCCESS`;
const createPayoutRuleSuccessAction = () => ({
  type: createPayoutRuleSuccessActionType,
});

const createPayoutRuleFailureActionType = `${createPayoutRuleActionType}_FAILURE`;
const createPayoutRuleFailureAction = () => ({
  type: createPayoutRuleFailureActionType,
});

const filterCompaniesToAddActionType = `${createPayoutRuleActionType}_FILTER`;
const filterCompaniesToAddAction = () => ({
  type: filterCompaniesToAddActionType,
});

const createPayoutRule = (relatedCompany, payoutRule) => dispatch => {
  dispatch(createPayoutRuleAction());

  return axios
    .post(`${API_HOST_AUTH}/payout-rules`, { relatedCompany, payoutRule })
    .then(() => dispatch(createPayoutRuleSuccessAction()))
    .catch(error => {
      dispatch(createPayoutRuleFailureAction());

      return Promise.reject(error);
    });
};

const filterCompaniesToAdd = filter => dispatch =>
  axios
    .post(`${API_HOST_AUTH}/payout-rules/filter-to-add`, { filter })
    .then(({ data }) =>
      data.map(({ _id, companyName, companyType }) => ({
        value: _id,
        label: companyName,
        companyType,
      })),
    )
    .then(companies => {
      dispatch(filterCompaniesToAddAction());
      return { options: companies };
    });

export {
  createPayoutRuleActionType,
  createPayoutRuleSuccessActionType,
  createPayoutRuleFailureActionType,
  filterCompaniesToAddActionType,
  createPayoutRule,
  filterCompaniesToAdd,
};
