const BARCODE_SCANNER = {
  ENTER_MANUALLY: 'Enter manually',
  SCAN: 'Scan',
  SUBMIT: 'Submit',
  CLEAR: 'Clear',
  WAITING: 'Waiting...',
  NEXT: 'Next',
};

export { BARCODE_SCANNER };
