import React, { Component } from 'react';

import { FormContainer } from 'shared/components/Form/FormContainer/FormContainer';
import { SELF_DELIVERY_REQUESTS } from 'shared/helpers/constants/selfDeliveryRequests/selfDeliveryRequestsConstants';
import { isGradingUser } from 'shared/helpers/matchers/checkUserType';
import { snackbarOptions } from 'shared/helpers/matchers/snackbar/snackbarOptions';

import { CreateSelfDeliveryRequestForm } from './CreateSelfDeliveryRequestForm/CreateSelfDeliveryRequestForm';

class CreateSelfDeliveryRequest extends Component {
  submit = values => {
    const { auth, createSelfDeliveryRequest } = this.props;

    return createSelfDeliveryRequest(values, auth.user).then(this.resolveAction);
  };

  resolveAction = result => {
    this.props.showSnackbar(snackbarOptions(result.messageType), result.message);
    if (result.id) {
      this.props.history.push(`/shipments/self-delivery-requests/${result.id}`);
    }
  };

  render() {
    const { auth, modal, getCompaniesBySearchStrUserType, closeModal, openModal } = this.props;
    const { isPending } = this.props.createSelfDeliveryRequestObject;

    const initialValues = isGradingUser(auth.user.userType)
      ? {
          relatedCompany: { label: auth.user.relatedCompany.companyName },
        }
      : null;

    return (
      <FormContainer
        loaded={!isPending}
        header={SELF_DELIVERY_REQUESTS.CREATE_SELF_DELIVERY_REQUEST}
      >
        <CreateSelfDeliveryRequestForm
          modal={modal}
          auth={auth}
          getCompaniesBySearchStrUserType={getCompaniesBySearchStrUserType}
          openModal={openModal}
          closeModal={closeModal}
          onSubmit={this.submit}
          initialValues={initialValues}
        />
      </FormContainer>
    );
  }
}

export { CreateSelfDeliveryRequest };
