import {
  deleteVirtualLotActionType,
  deleteVirtualLotSuccessActionType,
  deleteVirtualLotFailureActionType,
} from 'actions/Lots/deleteVirtualLot';

const INITIAL_STATE = {
  isPending: false,
};

const deleteVirtualLotReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case deleteVirtualLotActionType:
      return {
        isPending: true,
      };
    case deleteVirtualLotSuccessActionType:
    case deleteVirtualLotFailureActionType:
      return {
        isPending: false,
      };
    default:
      return state;
  }
};

export { deleteVirtualLotReducer };
