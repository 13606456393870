import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import styled, { css } from 'styled-components';

import { SessionSnackbar } from 'components/SessionSnackbar/SessionSnackbar';

import { CancelButton } from 'shared/components/Buttons/CancelButton/CancelButton';
import { SubmitButton } from 'shared/components/Buttons/SubmitButton/SubmitButton';
import { wizardContext } from 'shared/components/Wizard/Wizard';
import { cssVar, media, scrollbar } from 'shared/helpers/styling/styling';
import { useEventListener } from 'shared/hooks/useEventListener';
import { useScrollTop } from 'shared/hooks/useScrollTop';

import { ContentLoaderStyled, FormContext } from '../FormContainer/FormContainer';
import { FORM } from '../constants';

const Header = styled.div`
  height: 60px;
  padding-left: 37px;
  display: flex;
  align-items: center;
  color: ${cssVar('alto')};
  background-color: ${cssVar('shark')};
  font-size: 0.75rem;
`;

const Actions = ({ className, CustomActions, ...props }) =>
  CustomActions ? (
    <CustomActions {...props} />
  ) : (
    <div className={className}>
      <SubmitButton label={props.label} disabled={props.submitDisabled} />
      <CancelButton onClick={props.onCancel} />
    </div>
  );

const ActionsStyled = styled(Actions)`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  height: 150px;
  border-top: 1px solid ${cssVar('outerSpaceFormBg')};

  ${media.mobile`
    padding: 0 25px;
  `};
`;

const Form = ({
  className,
  loaded,
  submitButtonLabel,
  submitDisabled,
  onCancel,
  children,
  CustomActions,
  wide,
  ...props
}) => {
  useScrollTop();

  const wizardContextForm = useContext(wizardContext);
  const formLoaded = useContext(FormContext);

  const cancelOnEsc = event => {
    if (event.key === 'Escape') {
      return onCancel?.(event);
    }
  };
  useEventListener('keydown', cancelOnEsc);

  return (
    <>
      <SessionSnackbar />
      <ContentLoaderStyled loaded={!!loaded || formLoaded}>
        <form className={className} {...props} noValidate>
          <Header id="formHeader">{props.header}</Header>
          <div className="form-content">{children}</div>
          <ActionsStyled
            label={submitButtonLabel}
            submitDisabled={submitDisabled}
            onCancel={onCancel}
            CustomActions={CustomActions || (wizardContextForm && wizardContextForm.CustomActions)}
          />
        </form>
      </ContentLoaderStyled>
    </>
  );
};

const FormStyled = styled(Form)`
  display: flex;
  flex-direction: column;
  max-width: ${({ wide }) => (wide ? '95vw' : '1100px')};

  min-height: calc(100vh - 150px);
  margin: auto;

  ${scrollbar} ::-webkit-scrollbar {
    &-track {
      border-right: 0;
      border-top: 17px solid transparent;
      border-bottom: 17px solid transparent;
    }

    &-thumb {
      border-right: 15px solid transparent;
      border-left: 9px solid transparent;
      border-top: 13px solid transparent;
      border-bottom: 13px solid transparent;
    }
  }

  ${({ wide }) =>
    wide &&
    css`
      overflow-x: auto;

      .form-content {
        background-color: ${cssVar('limedSpruceForm')};

        :before {
          content: '';
          position: absolute;
          background-color: ${cssVar('shark')};
          height: 60px;
          width: 95%;
          transform: translateY(-60px);
          z-index: -1;
        }
      }
    `}
`;

FormStyled.propTypes = {
  header: PropTypes.string,
  submitButtonLabel: PropTypes.string,
  onCancel: PropTypes.func,
  CustomActions: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  submitDisabled: PropTypes.bool,
  loaded: PropTypes.any,
  wide: PropTypes.bool,
};

FormStyled.defaultProps = {
  submitButtonLabel: FORM.SUBMIT,
  submitDisabled: false,
  loaded: true,
  wide: false,
};

export { FormStyled as Form };
