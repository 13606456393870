import PropTypes from 'prop-types';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import styled from 'styled-components';

import { lotsAddMultipleDocuments } from 'actions/Lots/lotsAddFiles';

import { SubmitButton } from 'shared/components/Buttons';
import { FieldUpload } from 'shared/components/Fields/FiledUpload/FieldUpload';
import { FieldUploadMultipleFilesList } from 'shared/components/Fields/FiledUpload/FieldUploadMultipleFilesList';
import { FILES_CONSTANTS } from 'shared/helpers/constants/filesConstants';
import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';
import { parseMultipleFilesInput } from 'shared/helpers/dataParsers/files/parseMultipleFiles';
import { validateMultipleDocumentsLength } from 'shared/helpers/validations/lot/validateMultipleDocuments';

const Header = styled.h3`
  text-align: center;
`;

const SubmitContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 35px;
`;

const UploadBillOfLadingContentLayout = ({
  grgLotNumber,
  handleSubmit,
  invalid,
  lotsFiles,
  checkMultipleFilesError,
  blockSubmit,
  onDelete,
}) => {
  const dispatch = useDispatch();
  const { billOfLading } = useSelector(({ lotsFiles }) => lotsFiles.multipleFiles);
  const hasNewBOLFiles = !!billOfLading.filter(({ existingFile }) => !existingFile._id).length;

  const { VALID_EXTENSIONS } = FILES_CONSTANTS;

  const onMultipleFilesInputChange = async ({ target }) => {
    const payload = parseMultipleFilesInput(target);

    dispatch(lotsAddMultipleDocuments(payload));
  };

  return (
    <div>
      <Header>{LOTS.UPLOAD_BILL_OF_LADING_WARNING(grgLotNumber)}</Header>
      <form onSubmit={handleSubmit} noValidate>
        <Field
          multiple
          name="billOfLading"
          component={FieldUpload}
          label={LOTS.UPLOAD_BILL_OF_LADING}
          validExtensions={[
            VALID_EXTENSIONS.jpg,
            VALID_EXTENSIONS.jpeg,
            VALID_EXTENSIONS.pdf,
            VALID_EXTENSIONS.png,
          ].join(', ')}
          onChange={onMultipleFilesInputChange}
        />
        <FieldUploadMultipleFilesList
          files={lotsFiles}
          type="billOfLading"
          validExtensions={[
            VALID_EXTENSIONS.jpg,
            VALID_EXTENSIONS.jpeg,
            VALID_EXTENSIONS.pdf,
            VALID_EXTENSIONS.png,
          ]}
          onDelete={onDelete}
          checkMultipleFilesError={checkMultipleFilesError}
          limit={LOTS.BILL_OF_LADING_LIMIT}
          revertPosition
          validationError={validateMultipleDocumentsLength(lotsFiles.multipleFiles.billOfLading, 5)}
        />

        <SubmitContainer>
          <SubmitButton
            id="uploadBillOfLading"
            label={LOTS.UPLOAD}
            disabled={!hasNewBOLFiles || invalid || blockSubmit}
          />
        </SubmitContainer>
      </form>
    </div>
  );
};

UploadBillOfLadingContentLayout.propTypes = {
  grgLotNumber: PropTypes.string,
  handleSubmit: PropTypes.func,
  checkMultipleFilesError: PropTypes.func,
  onDelete: PropTypes.func,
  invalid: PropTypes.bool,
  blockSubmit: PropTypes.bool,
  lotsFiles: PropTypes.object,
};

const UploadBillOfLadingContent = reduxForm({
  form: 'UploadBillOfLadingContentForm',
})(UploadBillOfLadingContentLayout);

export { UploadBillOfLadingContent, UploadBillOfLadingContentLayout };
