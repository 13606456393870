import Cookies from 'js-cookie';
import store from 'store';

import { showSnackbar } from 'actions/shared/snackbar';

import { MESSAGES } from 'shared/helpers/constants/messagesConstants';
import { dangerOptions } from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

import { history } from 'utils/history';

const validateSignedUser = () => {
  const { auth } = store.getState();
  const userId = Cookies.get('grgUserSession');

  if (auth && auth.user && userId && userId !== auth.user.id) {
    store.dispatch(showSnackbar(dangerOptions, MESSAGES.SESSION_MISMATCH));
    history.push('/logout');
  }
};

export { validateSignedUser };
