import { SECTIONS } from 'shared/helpers/constants/warehouseDashboard/labels';

import { MomentAdapter } from 'utils/date/momentAdapter';

import {
  processViaCountFn,
  processIncomingList,
  processGradingList,
  processProcessingList,
  processCountingRequiredList,
} from './warehouseDashboardDataHelpers';

const warehouseDashboardData = ({
  incoming,
  complete,
  received,
  grading,
  countingRequired,
  currentlyProcessing,
  selfDelivery,
}) => {
  const momentAdapter = new MomentAdapter();
  const currentDateStart = momentAdapter.startOf('d').getTime();
  const currentDateEnd = momentAdapter.endOf('d').getTime();
  const currentDay = MomentAdapter.weekday(currentDateEnd);

  return [
    {
      name: SECTIONS.INCOMING.name,
      total: incoming.length + selfDelivery.length,
      list: processIncomingList(
        currentDateEnd,
        currentDay,
        incoming,
        selfDelivery,
        currentDateStart,
      ),
    },
    {
      name: SECTIONS.RECEIVED.name,
      total: received.length,
      list: [
        {
          elements: received,
          listId: SECTIONS.RECEIVED.id,
        },
      ],
    },
    {
      name: SECTIONS.GRADING.name,
      total: grading.length,
      list: processGradingList(grading),
    },
    {
      name: SECTIONS.COUNTING_REQUIRED.name,
      total: countingRequired.length,
      list: processCountingRequiredList(countingRequired),
    },
    {
      name: SECTIONS.CURRENTLY_PROCESSING.name,
      list: processProcessingList(currentlyProcessing),
      processViaCount: Array.isArray(processViaCountFn())
        ? processViaCountFn().join(', ')
        : processViaCountFn(),
    },
    {
      name: SECTIONS.COMPLETE.name,
      total: complete.length,
      weight: parseFloat(complete.reduce((acc, lot) => acc + lot.weightGrossActual, 0)).toFixed(3),
      list: [
        {
          elements: complete,
          listId: SECTIONS.COMPLETE.id,
        },
      ],
    },
  ];
};

export { warehouseDashboardData };
