import React from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { enterSettledOunces } from 'actions/Settlements/enterSettledOunces';
import { showSnackbar } from 'actions/shared/snackbar';

import { FormContainer } from 'shared/components/Form';
import { HEDGES } from 'shared/helpers/constants/hedges/hedgesConstants';
import { snackbarOptions } from 'shared/helpers/matchers/snackbar/snackbarOptions';

import { EnterSettledOuncesForm } from './EnterSettledOuncesForm/EnterSettledOuncesForm';

const EnterSettledOuncesBase = ({ history, ...props }) => {
  const dispatch = useDispatch();

  const resolveAction = res => {
    dispatch(showSnackbar(snackbarOptions(res.messageType), res.message));

    history.push('/hedges');
  };

  const onSubmit = values =>
    dispatch(enterSettledOunces(values)).then(resolveAction).catch(resolveAction);

  return (
    <FormContainer header={HEDGES.ENTER_SETTLED_OUNCES}>
      <EnterSettledOuncesForm onSubmit={onSubmit} />
    </FormContainer>
  );
};

const EnterSettledOunces = withRouter(EnterSettledOuncesBase);

export { EnterSettledOunces, EnterSettledOuncesBase };
