import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { createRecipient } from 'actions/Hedges/recipientsCreate';
import { openModal } from 'actions/shared/modal';
import { showSnackbar } from 'actions/shared/snackbar';

import { RecipientsCreate } from './RecipientsCreate';

const mapStateToProps = ({ modal }) => ({
  modal,
});

const mapDispatchToProps = {
  createRecipient,
  openModal,
  showSnackbar,
};

const ConnectedRecipientsCreate = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RecipientsCreate),
);

export { ConnectedRecipientsCreate };
