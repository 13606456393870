import { modalOpened, modalClosed } from 'actions/shared/modal';

import { defaultModal } from 'shared/helpers/constants/modalConstants';

const INITIAL_STATE = {
  modalOpened: false,
  modalCallback: null,
  modalOptions: defaultModal,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case modalOpened:
      return {
        ...state,
        modalOpened: true,
        modalCallback: action.modalCallback,
        closeCallback: action.closeCallback,
        customActionCallback: action.customActionCallback,
        modalOptions: action.modalOptions,
      };
    case modalClosed:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};
