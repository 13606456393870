import {
  outgoingLotsOverviewActionType,
  outgoingLotsOverviewFailureActionType,
  outgoingLotsOverviewSuccessActionType,
  outgoingLotsOverviewSelectedActionType,
  outgoingLotsOverviewClearActionType,
} from 'actions/Lots/outgoingLotsOverview';

import { outgoingSections } from 'shared/helpers/constants/lots/outgoingSections';

const INITIAL_SECTION_STATE = { isPending: false, items: null, selected: [] };

const INITIAL_STATE = {
  [outgoingSections.readyToShip.name]: { ...INITIAL_SECTION_STATE },
  [outgoingSections.shippedFromGlobal.name]: { ...INITIAL_SECTION_STATE },
  [outgoingSections.unsentShipments.name]: { ...INITIAL_SECTION_STATE },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case outgoingLotsOverviewActionType:
      return {
        ...state,
        [action.payload.section.name]: {
          ...state[action.payload.section.name],
          isPending: true,
        },
      };
    case outgoingLotsOverviewSuccessActionType:
      return {
        ...state,
        [action.payload.section.name]: {
          ...state[action.payload.section.name],
          isPending: false,
          items: action.payload.data,
        },
      };
    case outgoingLotsOverviewFailureActionType:
      return {
        ...state,
        [action.payload.section.name]: {
          isPending: false,
          items: [],
          selected: [],
        },
      };
    case outgoingLotsOverviewSelectedActionType:
      return {
        ...state,
        [action.payload.section.name]: {
          ...state[action.payload.section.name],
          selected: action.payload.selected,
        },
      };
    case outgoingLotsOverviewClearActionType:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};
