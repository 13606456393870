import {
  getNotificationsCountActionType,
  getNotificationsCountSuccessActionType,
  getNotificationsCountFailureActionType,
  setNotificationsCountActionType,
  clearNotificationsCountActionType,
} from 'actions/Notifications/notificationCount';

const INITIAL_STATE = {
  notificationsCount: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getNotificationsCountActionType:
    case getNotificationsCountFailureActionType:
      return {
        ...state,
      };
    case getNotificationsCountSuccessActionType:
    case setNotificationsCountActionType:
      return {
        notificationsCount: action.payload.notificationsCount,
      };
    case clearNotificationsCountActionType:
      return {
        notificationsCount: null,
      };
    default:
      return state;
  }
};
