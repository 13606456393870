import PropTypes from 'prop-types';
import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import styled from 'styled-components';

import {
  CollapsableContainerWrapper,
  CollapsableContentWrapper,
  CollapsableContainer,
} from 'shared/components/CollapsableContainer/CollapsableContainer';
import { DraggableItem } from 'shared/components/DraggableSection/DraggableItem/DraggableItem';
import { DraggableItemsList } from 'shared/components/DraggableSection/DraggableItemsList/DraggableItemsList';
import { move, reorder } from 'shared/components/DraggableSection/utils/draggableUtils';
import { media } from 'shared/helpers/styling/styling';

const DraggableSectionWrapper = styled.div`
  display: grid;
  align-items: flex-start;
  gap: 13px;
  grid-gap: 13px;
  grid-template: ${({ sections }) => `auto / repeat(${Object.keys(sections).length}, 1fr)`};
  ${media.tablet`grid-template: auto / repeat(2, 1fr);`}
  ${media.phone`grid-template: auto / 1fr;`}

  ${CollapsableContainerWrapper}:first-child {
    ${CollapsableContentWrapper} {
      background: transparent;
    }

    ${media.tablet`
      grid-area: 1 / 1 / span ${({ sections }) => Object.keys(sections).length - 1} / 2;
    `}

    ${media.desktop`
      margin-right: 1rem;
    `}
  }
`;

const DraggableSection = props => {
  const { sections, onDragStart, disabled, isDropSectionDisabled } = props;

  const orderItems = (data, sort) => (data && sort ? [...data].sort(sort) : data);

  const onMove = ({ source, destination }) => {
    const sourceSection = sections[source.droppableId];
    const designationSectionName = destination.droppableId;
    const designationSection = sections[designationSectionName];
    const newValue = move(sourceSection.data, designationSection.data, source, destination);

    return {
      ...newValue,
      [designationSectionName]: orderItems(
        newValue[designationSectionName],
        designationSection.sort,
      ),
    };
  };

  const onReorder = ({ source, destination }) => {
    const designationSection = props.sections[destination.droppableId];
    const newValue = reorder(designationSection.data, source, destination);

    return {
      [destination.droppableId]: orderItems(newValue, designationSection.sort),
    };
  };

  const setSectionDisabled = sectionName => {
    switch (sectionName) {
      case 'available':
        return isDropSectionDisabled;
      default:
        return false;
    }
  };

  const onSelectedChange = (newValues, destination) =>
    newValues && onSelectedChange && props.onSelectedChange(newValues, destination);

  const onDragEnd = result => {
    const { source, destination } = result;
    if (!destination) return;

    const newValues =
      source.droppableId === destination.droppableId ? onReorder(result) : onMove(result);

    onSelectedChange(newValues, destination.droppableId);
  };

  const calculateDisabled = item => (typeof disabled === 'function' ? disabled(item) : disabled);

  const renderDraggable =
    (section, { isDraggingOver }) =>
    (item, index) =>
      (
        <Draggable
          key={item._id}
          draggableId={item._id}
          index={index}
          isDragDisabled={calculateDisabled(item)}
        >
          {({ innerRef, draggableProps, dragHandleProps }, { isDragging }) => (
            <DraggableItem
              key={item._id}
              id={`${section}[${index}]`}
              dragRef={innerRef}
              isDragging={isDragging}
              isDraggingOver={isDraggingOver}
              item={item}
              itemsTemplate={props.itemTemplate}
              actionsTemplate={props.actionsTemplate}
              expandedActionsTemplate={props.expandedActionsTemplate}
              highlightedColor={props.highlightedColor}
              outlined={props.outlined}
              data-testid={item.grgLotNumber}
              {...(props.getDraggableProps && props.getDraggableProps(item, section, index))}
              {...draggableProps}
              {...dragHandleProps}
            />
          )}
        </Draggable>
      );

  return (
    <DraggableSectionWrapper sections={props.sections}>
      <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
        {Object.entries(sections).map(
          ([name, { header, expandable, icon, placeholder, data, id }]) => (
            <CollapsableContainer key={name} header={header} expandable={expandable} icon={icon}>
              <Droppable droppableId={name} key={name} isDropDisabled={setSectionDisabled(name)}>
                {(provided, draggingProps) => (
                  <DraggableItemsList
                    id={id}
                    isDraggingOver={draggingProps.isDraggingOver}
                    empty={!data.length}
                    providedRef={provided.innerRef}
                    placeholder={placeholder}
                  >
                    {data.map(renderDraggable(name, draggingProps))}
                    {provided.placeholder}
                  </DraggableItemsList>
                )}
              </Droppable>
            </CollapsableContainer>
          ),
        )}
      </DragDropContext>
    </DraggableSectionWrapper>
  );
};

DraggableSection.defaultProps = {
  sections: {},
  actionsTemplate: [],
  expandedActionsTemplate: [],
};

DraggableSection.propTypes = {
  disabled: PropTypes.func,
  expandedActionsTemplate: PropTypes.arrayOf(
    PropTypes.shape({
      access: PropTypes.func,
      render: PropTypes.func,
    }),
  ),
  actionsTemplate: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  getDraggableProps: PropTypes.func,
  isDropSectionDisabled: PropTypes.bool,
  itemTemplate: PropTypes.arrayOf(
    PropTypes.shape({
      render: PropTypes.func,
    }),
  ),
  onDragStart: PropTypes.func,
  onSelectedChange: PropTypes.func,
  sections: PropTypes.shape({
    M1: PropTypes.shape({
      data: PropTypes.array,
      placeholder: PropTypes.string,
      header: PropTypes.string,
      expandable: PropTypes.bool,
      sort: PropTypes.func,
    }),
    M2: PropTypes.shape({
      data: PropTypes.array,
      placeholder: PropTypes.string,
      header: PropTypes.string,
      expandable: PropTypes.bool,
      sort: PropTypes.func,
    }),
    available: PropTypes.shape({
      data: PropTypes.array,
      placeholder: PropTypes.string,
      header: PropTypes.string,
      expandable: PropTypes.bool,
      sort: PropTypes.func,
    }),
    Shears: PropTypes.shape({
      data: PropTypes.array,
      placeholder: PropTypes.string,
      header: PropTypes.string,
      expandable: PropTypes.bool,
      sort: PropTypes.func,
    }),
  }),
  windowWidth: PropTypes.number,
};

export { DraggableSection };
