import React from 'react';

import { UIAuthorization } from 'components/Authorization/UIAuthorization';

import { Button } from 'shared/components/Buttons';
import { Icon } from 'shared/components/Icon/Icon';
import { isPrimaryRowAction } from 'shared/helpers/matchers/isPrimaryRowAction';
import { cssVar } from 'shared/helpers/styling/styling';

import { OverviewActionsCell } from '../OverviewActionsCell/OverviewActionsCell';
import { OverviewListTableCell } from '../OverviewListTableCell/OverviewListTableCell';
import { OverviewTableRow } from '../OverviewTableRow/OverviewTableRow';

const ActionComponent = ({ Component, ...props }) =>
  Component ? <Component {...props} /> : <Button {...props} />;

function OverviewTableItem({
  item,
  itemsTemplate,
  templateProps,
  actionsTemplate,
  actionsContext,
  processing,
  itemFilter,
  detailsList,
  logs,
  limitMaximumHeight = false,
  customColor,
  isDisabled,
}) {
  const filteredActionsTemplate = actionsTemplate.filter(
    ({ access }) => !access || access.call(actionsContext, item),
  );

  const isRowClickable = filteredActionsTemplate.filter(({ action }) => isPrimaryRowAction(action));

  function clickableRowAction() {
    if (isRowClickable.length) isRowClickable[0].onClick(item)();
    if (itemsTemplate.onClickAction) {
      itemsTemplate.onClickAction.call(actionsContext, item);
    }
  }

  const rowClassName =
    itemsTemplate.className && itemsTemplate.className.call(actionsContext, item);

  const callAction = (e, onClick) => {
    onClick.call(actionsContext, item)();
    e.stopPropagation();
  };

  return (
    <OverviewTableRow
      additionalClass={rowClassName}
      itemsNumber={itemsTemplate.length - !actionsTemplate.length}
      header
      isClickable={isRowClickable.length}
      onClick={clickableRowAction}
      overviewList
      processing={processing}
      item={item}
      itemFilter={itemFilter}
      detailsList={detailsList}
      logs={logs}
      limitMaximumHeight={limitMaximumHeight}
      customColor={customColor}
      isDisabled={isDisabled}
    >
      {itemsTemplate.map(({ render, bgColor }, index) => (
        <OverviewListTableCell bgColor={bgColor} key={index}>
          {render(item, index, templateProps)}
        </OverviewListTableCell>
      ))}
      <UIAuthorization access={actionsTemplate.length}>
        <OverviewActionsCell detailsList={detailsList}>
          {filteredActionsTemplate
            .filter(({ access }) => !access || access.call(actionsContext, item))
            .map(({ Component, icon, onClick, tooltip, action }, index) => (
              <ActionComponent
                Component={Component}
                borderRadius={100}
                onClick={e => callAction(e, onClick)}
                margin={false}
                noOutline
                bgColor={cssVar('limedSpruceList')}
                fitToContent
                tooltip={tooltip}
                key={index}
                data-testid={action}
              >
                <Icon
                  icon={`icon-${icon}`}
                  color={cssVar('regentGrayBrighter')}
                  width={42}
                  height={42}
                />
              </ActionComponent>
            ))}
        </OverviewActionsCell>
      </UIAuthorization>
    </OverviewTableRow>
  );
}

OverviewTableItem.defaultProps = {
  actionsContext: null,
};

export { OverviewTableItem };
