import axios from 'axios';

import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';
import { messageTypes } from 'shared/helpers/constants/snackbar/snackbarMessageTypes';

const API_HOST_AUTH = process.env.REACT_APP_API;

const deleteVirtualLotActionType = 'DELETE_VIRTUAL_LOT';
const deleteVirtualLotAction = () => ({
  type: deleteVirtualLotActionType,
});

const deleteVirtualLotSuccessActionType = `${deleteVirtualLotActionType}_SUCCESS`;
const deleteVirtualLotSuccessAction = virtualLot => ({
  type: deleteVirtualLotSuccessActionType,
  payload: {
    virtualLot,
  },
});

const deleteVirtualLotFailureActionType = `${deleteVirtualLotActionType}_FAILURE`;
const deleteVirtualLotFailureAction = () => ({
  type: deleteVirtualLotFailureActionType,
});

const deleteVirtualLot = id => dispatch => {
  dispatch(deleteVirtualLotAction());

  return axios
    .delete(`${API_HOST_AUTH}/virtual-lots/${id}`)
    .then(response => {
      dispatch(deleteVirtualLotSuccessAction(response.data));
      return {
        message: response.data.message,
        messageType: messageTypes.success,
      };
    })
    .catch(error => {
      dispatch(deleteVirtualLotFailureAction());
      return {
        message: `${LOTS.ERRORS.DELETE_LOT_ERROR} ${error.data.message}`,
        messageType: messageTypes.error,
      };
    });
};

export {
  deleteVirtualLotActionType,
  deleteVirtualLotAction,
  deleteVirtualLotSuccessActionType,
  deleteVirtualLotSuccessAction,
  deleteVirtualLotFailureActionType,
  deleteVirtualLotFailureAction,
  deleteVirtualLot,
};
