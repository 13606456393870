import {
  getDefaultPPMsActionType,
  getDefaultPPMsSuccessActionType,
  getDefaultPPMsFailureActionType,
  clearDefaultPPMsActionType,
} from 'actions/Hedges/defaultPPMs';

const INITIAL_STATE = {
  isPending: false,
  values: {},
};

const defaultPPMsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getDefaultPPMsActionType:
      return {
        ...state,
        isPending: true,
      };
    case getDefaultPPMsSuccessActionType:
      return {
        ...state,
        isPending: false,
        values: action.payload.data,
      };
    case getDefaultPPMsFailureActionType:
      return {
        ...state,
        isPending: false,
      };
    case clearDefaultPPMsActionType:
      return {
        isPending: false,
        values: {},
      };
    default:
      return state;
  }
};

export { defaultPPMsReducer };
