import { message } from 'shared/helpers/constants/payments/createPayoutRulesConstants';

function validate(values) {
  const errors = {};

  if (!values.relatedCompany) {
    errors.relatedCompany = message.INVALID_RELATED_COMPANY_EMPTY;
  }

  if (!values.payoutRule) {
    errors.payoutRule = message.INVALID_PAYOUT_RULE_EMPTY;
  }

  return errors;
}

export { validate };
