import axios from 'axios';

import { messageTypes } from 'shared/helpers/constants/snackbar/snackbarMessageTypes';
import { USER } from 'shared/helpers/constants/users/userConstants';

const API_HOST_AUTH = process.env.REACT_APP_API;

const deleteUserActionType = 'DELETE_USER';
const deleteUserAction = () => ({
  type: deleteUserActionType,
});

const deleteUserSuccessActionType = `${deleteUserActionType}_SUCCESS`;
const deleteUserSuccessAction = () => ({
  type: deleteUserSuccessActionType,
});

const deleteUserFailureActionType = `${deleteUserActionType}_FAILURE`;
const deleteUserFailureAction = () => ({
  type: deleteUserFailureActionType,
});

const deleteUser = id => dispatch => {
  dispatch(deleteUserAction());

  return axios
    .delete(`${API_HOST_AUTH}/users/${id}`)
    .then(response => {
      dispatch(deleteUserSuccessAction());

      return {
        message: response.data.message,
        messageType: messageTypes.success,
      };
    })
    .catch(error => {
      dispatch(deleteUserFailureAction());

      return {
        message: `${USER.DELETE_USER_ERROR} ${error.data.message}`,
        messageType: messageTypes.error,
      };
    });
};

export {
  deleteUserActionType,
  deleteUserAction,
  deleteUserSuccessActionType,
  deleteUserSuccessAction,
  deleteUserFailureActionType,
  deleteUserFailureAction,
  deleteUser,
};
