import { CALENDAR_NOTE } from 'shared/helpers/constants/calendarConstants';
import { dateToTimestamp } from 'shared/helpers/parsers/date';

function validate(values) {
  const errors = {};

  if (!values.startDate) {
    errors.startDate = CALENDAR_NOTE.VALIDATION.START_DATE_EMPTY;
  } else if (
    values.startDate &&
    values.endDate &&
    dateToTimestamp(values.startDate) > dateToTimestamp(values.endDate)
  ) {
    errors.startDate = CALENDAR_NOTE.VALIDATION.START_DATE_TOO_HIGH;
  }

  if (!values.endDate) {
    errors.endDate = CALENDAR_NOTE.VALIDATION.END_DATE_EMPTY;
  } else if (
    values.endDate &&
    values.startDate &&
    dateToTimestamp(values.endDate) < dateToTimestamp(values.startDate)
  ) {
    errors.endDate = CALENDAR_NOTE.VALIDATION.END_DATE_TOO_HIGH;
  }

  if (!values.noteContents) {
    errors.noteContents = CALENDAR_NOTE.VALIDATION.NOTE_CONTENTS_EMPTY;
  } else if (values.noteContents && values.noteContents.length > 100) {
    errors.noteContents = CALENDAR_NOTE.VALIDATION.NOTE_CONTENTS_TOO_LONG;
  }

  return errors;
}

export { validate };
