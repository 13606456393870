import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import { NotFound } from 'components/ErrorPage/ErrorPage';

function NoAuthentication({ component }) {
  const auth = useSelector(({ auth }) => auth);
  return !auth.user ? (
    <Switch>
      <Route path="/" component={component} />
      <Route component={NotFound} />
    </Switch>
  ) : (
    <Redirect to="/dashboard" />
  );
}

export { NoAuthentication };
