import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';

import {
  getMaximumSettlementOunces,
  clearMaximumSettlementOunces,
  clearMaximumSettlementOuncesForLot,
} from 'actions/PoolAccounts/getMaximumSettlementOunces';
import { closeModal, openModal } from 'actions/shared/modal';
import { showSnackbar, hideSnackbar } from 'actions/shared/snackbar';

import { validate } from 'shared/helpers/validations/poolAccounts/requestSettlementValidation';

import { RequestSettlementsForm } from '../RequestSettlementsForm/RequestSettlementsForm';

const mapStateToProps = state => ({
  auth: state.auth,
  maximumSettlementOunces: state.maximumSettlementOunces,
});

const mapDispatchToProps = {
  closeModal,
  openModal,
  getMaximumSettlementOunces,
  clearMaximumSettlementOunces,
  clearMaximumSettlementOuncesForLot,
  showSnackbar,
  hideSnackbar,
};

const ConnectedRequestMultipleSettlementsForm = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'RequestSettlementForm',
    destroyOnUnmount: false,
    validate,
    initialValues: {
      settlements: [
        {
          requestPayment: false,
          formId: Date.now(),
        },
      ],
    },
  }),
)(RequestSettlementsForm);

export { ConnectedRequestMultipleSettlementsForm };
