import { formatDate, formatToUS } from 'shared/helpers/parsers/formaters';
import { replaceEmpty } from 'shared/helpers/parsers/text';

import { DELIVERIES } from './deliveriesConstants';

const itemTemplate = [
  {
    label: DELIVERIES.RELATED_LOT,
    render: ({ relatedLot }) => relatedLot.grgLotNumber,
  },
  {
    label: DELIVERIES.PLATINUM_OZ,
    render: ({ platinumOz }) => replaceEmpty(formatToUS(platinumOz, 3)),
  },
  {
    label: DELIVERIES.PALLADIUM_OZ,
    render: ({ palladiumOz }) => replaceEmpty(formatToUS(palladiumOz, 3)),
  },
  {
    label: DELIVERIES.RHODIUM_OZ,
    render: ({ rhodiumOz }) => replaceEmpty(formatToUS(rhodiumOz, 3)),
  },
  {
    label: DELIVERIES.CREATED_BY,
    render: ({ createdBy }) => `${createdBy.firstName} ${createdBy.lastName}`,
  },
  {
    label: DELIVERIES.CREATED_ON,
    render: ({ createdAt }) => formatDate(createdAt),
  },
];

const itemsTemplateMobile = [
  {
    label: DELIVERIES.RELATED_LOT,
    render: ({ relatedLot }) => relatedLot.grgLotNumber,
  },
  {
    label: DELIVERIES.CREATED_BY,
    render: ({ createdBy }) => `${createdBy.firstName} ${createdBy.lastName}`,
  },
];

const actionsTemplate = [];

export { itemTemplate, itemsTemplateMobile, actionsTemplate };
