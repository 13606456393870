import React, { useEffect } from 'react';
import styled from 'styled-components';

import { Button } from 'shared/components/Buttons/Button/Button';
import { CollapsableContainer } from 'shared/components/CollapsableContainer/CollapsableContainer';
import { FormContainer } from 'shared/components/Form/FormContainer/FormContainer';
import { Icon } from 'shared/components/Icon/Icon';
import {
  markProcessingCompleteAccess,
  acceptLotForProcessingAccess,
  acceptLotForMixingAccess,
  acceptInactiveSamplePkgForProcessingAccess,
} from 'shared/helpers/accesses/lots';
import { updatePackageWeightAccess } from 'shared/helpers/accesses/packages/updatePackageAccess';
import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';
import { statusesForGlobal } from 'shared/helpers/constants/lots/statusesForGlobal';
import { packageClasses } from 'shared/helpers/constants/packages/packageClasses';
import { PACKAGES } from 'shared/helpers/constants/packages/packageConstants';
import { packageStatuses } from 'shared/helpers/constants/packages/packageType';
import {
  itemsTemplateProcessing,
  itemsDetailsTemplateProcessing,
  itemsTemplateHF,
} from 'shared/helpers/constants/packages/packagesListTableConfig';
import { SHARED } from 'shared/helpers/constants/sharedConstants';
import { SHIPMENTS } from 'shared/helpers/constants/shipments/shipmentsConstants';
import { infoOptions, successOptions } from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { setDesignation } from 'shared/helpers/dataParsers/lot/setDesignation';
import { cssVar } from 'shared/helpers/styling/styling';

import { goBackOrTo } from 'utils/history';

import { AcceptForMixing } from 'pages/Lots/components/AcceptForMixing/AcceptForMixing';
import { OverviewPackagesList } from 'pages/Packages/components/OverviewList/OverviewPackagesList';
import { relatedModules } from 'pages/Packages/components/OverviewList/config/relatedModules';

import { AcceptForProcessing } from '../AcceptForProcessing/AcceptForProcessing';
import { ConnectedMarkProcessingComplete } from '../MarkProcessingComplete/ConnectedMarkProcessingComplete';

const ListWrapper = styled.div`
  max-width: 1100px;
  margin: 0 auto;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 68px 0;
`;

const FillProcessingPackages = ({
  auth,
  getLot,
  getShipment,
  match,
  clearLotDetails,
  clearShipmentDetailsAction,
  history,
  getPackagesOverviewForLots,
  getPackagesOverviewForShipments,
  clearPackagesOverview,
  packagesOverviewList,
  clearAllPackagesOverview,
  lotDetails: { lot, isPending },
  shipmentDetails,
  location,
  acceptForProcessingState,
  acceptForMixingState,
  markProcessingCompleteState,
  showSnackbar,
}) => {
  useEffect(() => {
    if (location.state && location.state.fillShipmentWeight) {
      getShipment(match.params.id).catch(() => goBackOrTo('/shipments/'));
    } else {
      getLot(match.params.id).catch(() => goBackOrTo('/lots/'));
    }

    return () => {
      clearLotDetails();
      clearShipmentDetailsAction();
      clearAllPackagesOverview();
    };
  }, [
    clearAllPackagesOverview,
    clearLotDetails,
    clearShipmentDetailsAction,
    getLot,
    getShipment,
    location.state,
    match.params.id,
  ]);

  const processingAccess =
    lot && markProcessingCompleteAccess(auth, lot) && !lot.updateWeightsRequired;

  const inactiveSamplePkg = acceptInactiveSamplePkgForProcessingAccess(packagesOverviewList);

  const processingAcceptAccess =
    lot &&
    acceptLotForProcessingAccess(auth, lot, packagesOverviewList.packagesList) &&
    inactiveSamplePkg &&
    !lot.updateWeightsRequired &&
    lot.packagesCount > 0;

  const mixingAcceptAccess =
    lot &&
    acceptLotForMixingAccess(auth, lot, packagesOverviewList.packagesList) &&
    !lot.updateWeightsRequired;

  const isProcessingReady = lot && lot.statusForGlobal === statusesForGlobal.ready_for_processing;
  const isMixingReady = lot && lot.statusForGlobal === statusesForGlobal.ready_for_mixing;
  const isProcessing = lot && lot.statusForGlobal === statusesForGlobal.accepted_for_processing;
  const isReadyToShip = lot && lot.statusForGlobal === statusesForGlobal.ready_to_ship;
  const basePath = '/lots/processing';
  const stage = isProcessingReady ? 'accept' : 'complete';
  const createPath = lot && `${basePath}/${stage}/${lot._id}/create-package`;

  const canCreate =
    lot &&
    (!isProcessingReady || lot.sentFromCompany._id === auth.user.relatedCompany._id) &&
    !isMixingReady;

  const getRequiredPackageClass = () => {
    if (isMixingReady) {
      return packageClasses.post_mill;
    }

    if (isProcessingReady) {
      return packageClasses.incoming;
    }

    if (lot && /^M/.test(lot.processVia)) {
      return isProcessing ? packageClasses.post_mill : packageClasses.post_mix;
    }

    if (shipmentDetails && shipmentDetails.shipment) {
      return packageClasses.post_mix;
    }

    return packageClasses.post_shears;
  };

  useEffect(() => {
    if (!Object.keys(packagesOverviewList.packagesList).length) {
      return;
    }

    if (packagesOverviewList && !inactiveSamplePkg) {
      return showSnackbar(infoOptions, LOTS.ACTIVE_SAMPLE_PKG_INFO);
    }

    if (lot?.updateWeightsRequired) {
      return showSnackbar(infoOptions, LOTS.PROCESSING_LOT_WITHOUT_WEIGHTS);
    }

    if (
      !lot?.processedAt &&
      !isProcessing &&
      packagesOverviewList &&
      processingAcceptAccess === false
    ) {
      return showSnackbar(infoOptions, LOTS.PROCESSING_LOT_NOT_ACCEPT);
    }

    if (
      packagesOverviewList.packagesList['post-mill'] &&
      (packagesOverviewList?.packagesList['post-mill'] === null ||
        packagesOverviewList?.packagesList['post-mill']?.docs?.length === 0)
    ) {
      return showSnackbar(infoOptions, LOTS.PROCESSING_LOT_WITHOUT_POST_MILL_PACKAGE);
    }

    if (
      packagesOverviewList.packagesList['post-shears'] &&
      (packagesOverviewList?.packagesList['post-shears'] === null ||
        packagesOverviewList?.packagesList['post-shears']?.docs?.length === 0)
    ) {
      return showSnackbar(infoOptions, LOTS.PROCESSING_LOT_WITHOUT_POST_SHEARS_PACKAGE);
    }

    if (
      packagesOverviewList.packagesList['post-mix'] &&
      (packagesOverviewList?.packagesList['post-mix'] === null ||
        packagesOverviewList?.packagesList['post-mix']?.docs?.length === 0)
    ) {
      return showSnackbar(infoOptions, LOTS.PROCESSING_LOT_WITHOUT_POST_MIX_PACKAGE);
    }

    if (!location.state?.fromMixing && ((isMixingReady && lot?.processedAt) || isReadyToShip)) {
      returnToOverview();
      return showSnackbar(successOptions, LOTS.LOT_ALREADY_MARKED_AS_PROCESSED);
    }
  }, [lot, packagesOverviewList]);

  const returnToOverview = () => history.push(basePath);

  const getItemsTemplate = () =>
    isProcessingReady ? itemsTemplateProcessing : [...itemsTemplateProcessing, ...itemsTemplateHF];

  const getItemsDetailsTemplate = () =>
    isProcessingReady
      ? itemsDetailsTemplateProcessing
      : [...itemsDetailsTemplateProcessing, ...itemsTemplateHF];

  const renderCreateAction = () =>
    canCreate ? (
      <Button
        outline
        onClick={() => history.push(createPath)}
        disabled={packagesOverviewList.isPending}
      >
        {PACKAGES.CREATE_PACKAGES}
        <Icon icon="icon-plus" />
      </Button>
    ) : null;

  const renderAcceptForProcessing = () => (
    <AcceptForProcessing lot={lot} successCallback={returnToOverview}>
      {({ acceptForProcessing }) => (
        <Button
          onClick={acceptForProcessing}
          disabled={!processingAcceptAccess || acceptForProcessingState.isPending}
        >
          {LOTS.ACCEPT_FOR_PROCESSING}
          <Icon icon="icon-process" width={20} height={20} size="contain" />
        </Button>
      )}
    </AcceptForProcessing>
  );

  const renderAcceptForMixing = () => (
    <AcceptForMixing lot={lot} successCallback={returnToOverview}>
      {({ acceptForMixing }) => (
        <Button
          onClick={acceptForMixing}
          disabled={!mixingAcceptAccess || acceptForMixingState.isPending}
        >
          {LOTS.ACCEPT_FOR_MIXING}
          <Icon icon="icon-process" width={20} height={20} size="contain" />
        </Button>
      )}
    </AcceptForMixing>
  );

  const renderProcessingComplete = () => (
    <ConnectedMarkProcessingComplete lot={lot} successCallback={returnToOverview}>
      {({ markProcessingComplete }) => (
        <Button
          onClick={markProcessingComplete}
          disabled={!processingAccess || markProcessingCompleteState.isPending}
        >
          {isProcessing ? LOTS.PROCESSING_COMPLETE : LOTS.MIXING_COMPLETE}
          <Icon icon="icon-tick" />
        </Button>
      )}
    </ConnectedMarkProcessingComplete>
  );

  const updateShipmentWeights = () => {
    const packages =
      packagesOverviewList.packagesList.items && packagesOverviewList.packagesList.items.docs;
    const weightsRequired =
      packages &&
      packages.some(pkg => pkg.weightGrossActual === null || pkg.weightTareActual === null);

    return (
      <Button
        disabled={weightsRequired}
        onClick={() =>
          history.push(`/shipments/outgoing/${shipmentDetails.shipment._id}/ship-and-print`)
        }
      >
        {SHIPMENTS.SHIP_AND_PRINT_SHIPMENT}
      </Button>
    );
  };

  const tableActions = [
    {
      action: 'Update weight',
      icon: 'scale',
      access: pkg => updatePackageWeightAccess(auth, pkg),
      onClick: pkg => () =>
        history.push(
          lot
            ? `${basePath}/${stage}/${lot._id}/packages/${pkg._id}/update-weight`
            : `/shipments/${shipmentDetails.shipment._id}/packages/${pkg._id}/update/weight`,
        ),
    },
    {
      action: 'Package details',
      icon: 'arrow-right',
      access: pkg => auth.access.packages.details && pkg.status !== packageStatuses.deleted,
      onClick: pkg => () =>
        history.push(
          lot
            ? `${basePath}/${stage}/${lot._id}/packages/${pkg._id}`
            : `/shipments/${shipmentDetails.shipment._id}/packages/${pkg._id}`,
        ),
    },
  ];

  return (
    <FormContainer
      loaded={!isPending || !shipmentDetails.isPending}
      header={lot ? lot.customLotNumber || setDesignation(lot.grgLotNumber, lot.designation) : ''}
    >
      <ListWrapper>
        {(lot || shipmentDetails.shipment) && (
          <>
            <CollapsableContainer
              header={PACKAGES.PACKAGES}
              actions={renderCreateAction}
              processing
            >
              <OverviewPackagesList
                auth={auth}
                match={match}
                relatedModule={relatedModules.lots}
                getPackagesOverview={
                  location.state && location.state.fillShipmentWeight
                    ? getPackagesOverviewForShipments
                    : getPackagesOverviewForLots
                }
                packagesOverviewList={packagesOverviewList}
                sectionType={getRequiredPackageClass()}
                clearPackagesOverview={clearPackagesOverview}
                itemsTemplate={getItemsTemplate()}
                itemsDetailsTemplate={getItemsDetailsTemplate()}
                tableActions={tableActions}
                listLimit="0"
                processing
              />
            </CollapsableContainer>
            <Footer>
              <Button onClick={returnToOverview} outlineColor={cssVar('roman')}>
                {SHARED.CANCEL_BUTTON}
                <Icon icon="icon-cancel" />
              </Button>
              {(shipmentDetails.shipment && updateShipmentWeights()) ||
                (isProcessingReady && renderAcceptForProcessing()) ||
                (isMixingReady ? renderAcceptForMixing() : renderProcessingComplete())}
            </Footer>
          </>
        )}
      </ListWrapper>
    </FormContainer>
  );
};

export { FillProcessingPackages };
