import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Field, formValueSelector, reduxForm } from 'redux-form';

import { getAssignedLots } from 'actions/Lots/getAssignedLots';
import { getLot } from 'actions/Lots/lotDetails';
import { openModal } from 'actions/shared/modal';

import { FieldButtonSelect } from 'shared/components/Fields/FieldButtonSelect/FieldButtonSelect';
import { FieldInput, FieldSelect } from 'shared/components/Fields/index';
import { FieldsFilter } from 'shared/components/FieldsFilter/FieldsFilter';
import { Form, Section } from 'shared/components/Form';
import {
  denominationTable,
  denominatedIn,
} from 'shared/helpers/constants/materials/denominationTable';
import { cancelModal } from 'shared/helpers/constants/modalConstants';
import { availableMaterialClass } from 'shared/helpers/constants/packages/materialClass';
import {
  availableMaterialTypeForAssay,
  availableMaterialTypeCommodities,
  availableMaterialTypeForGrading,
  availableMaterialTypeOutbound,
  allMaterials,
} from 'shared/helpers/constants/packages/materialType';
import { PACKAGES } from 'shared/helpers/constants/packages/packageConstants';
import { availablePackageType, packageType } from 'shared/helpers/constants/packages/packageType';
import {
  extendMachineNameProcessing,
  extendMachineNameMixing,
} from 'shared/helpers/dataParsers/lot/lotOverviewData';
import { parseLotsSelectData } from 'shared/helpers/dataParsers/lot/parseLotsSelectData';
import {
  isAssayCompany,
  isGradingCompany,
  isInternalCompany,
} from 'shared/helpers/matchers/checkCompanyType';
import {
  isCustomerUser,
  isGradingUser,
  isWarehouseUser,
} from 'shared/helpers/matchers/checkUserType';
import { isGlobalRefiningGroup } from 'shared/helpers/matchers/isGlobalRefiningGroup';
import { normalizeFormValues } from 'shared/helpers/parsers/formNormalizer';
import { mapArray } from 'shared/helpers/parsers/select';
import { validate } from 'shared/helpers/validations/package/customerPackageValidations';

import { relativeNavigator } from 'utils/relativeNavigator';
import { urlExtractor } from 'utils/urlExtractor';

import { configNames, configs, isProcessingPackage, isGlobalCreatedPackage } from './fieldsConfig';

class PackageFormBase extends Component {
  isComponentMounted = false;

  static propTypes = {
    config: PropTypes.oneOf(Object.values(configNames)),
  };

  state = {
    recentPackage: this.props.packageDetails.package,
    assignedLots: [],
  };

  constructor(props) {
    super(props);

    this.navigator = relativeNavigator(this.navigation)(props.history);
  }

  navigation = [
    { from: /^\/shipments\/packages\/create\/?$/, to: '/shipments' },
    {
      from: /^\/shipments\/packages\/\w+\/update\/?$/,
      to: ({ pkg }) => `/shipments/packages/${pkg.id}`,
    },
    {
      from: /^\/lots\/list\/\w+\/packages\/create\/(customer|global|processing)\/?$/,
      to: ({ lot }) => `/lots/list/${lot._id}`,
    },
    {
      from: /^\/lots\/list\/\w+\/packages\/\w+\/update\/?$/,
      to: ({ pathName }) => `/${pathName.split('/').slice(1, 4).join('/')}`,
    },
    {
      from: /^\/lots\/processing\/complete\/\w+\/create-package\/?$/,
      to: ({ lot }) => `/lots/processing/complete/${lot._id}`,
    },
    {
      from: /^\/lots\/list\/\w+\/packages\/\w+\/update\/(customer|global|weight)\/?$/,
      to: ({ lot, packageId }) => `/lots/list/${lot._id}/packages/${packageId}`,
    },
    {
      from: /^\/lots\/list\/\w+\/packages\/\w+\/update\/(customer|global|weight)\/admin\/?$/,
      to: ({ lot, packageId }) => `/lots/list/${lot._id}/packages/${packageId}`,
    },
    {
      from: /^\/shipments\/\w+\/packages\/\w+\/update\/(customer|global|weight)\/?$/,
      to: ({ packageId, shipmentId }) => `/shipments/${shipmentId}/packages/${packageId}`,
    },
    {
      from: /^\/shipments\/\w+\/packages\/\w+\/update\/(customer|global|weight)\/admin\/?$/,
      to: ({ packageId, shipmentId }) => `/shipments/${shipmentId}/packages/${packageId}`,
    },
    {
      from: /^\/lots\/(mixing|processing)\/(accept|complete)\/\w+\/packages\/\w+\/update-weight\/?$/,
      to: ({ pathName }) => urlExtractor(pathName, -3),
    },
    {
      from: /^\/lots\/(mixing|processing)\/(accept|complete)\/\w+\/packages\/\w+\/update\/weight\/?$/,
      to: ({ pathName }) => urlExtractor(pathName, -2),
    },
    {
      from: /^\/lots\/(mixing|processing)\/(accept|complete)\/\w+\/packages\/\w+\/update\/global\/?$/,
      to: ({ pathName }) => urlExtractor(pathName, -2),
    },
    {
      from: /^\/shipments\/\w+\/packages\/\w+\/update\/?$/,
      to: ({ packageId, shipmentId }) => `/shipments/${shipmentId}/packages/${packageId}`,
    },
  ];

  componentDidMount() {
    const {
      sentFromCompany,
      forSpecifiedLot,
      match: {
        params: { lotId },
      },
      change,
    } = this.props;
    const { recentPackage } = this.state;

    this.isComponentMounted = true;

    (forSpecifiedLot
      ? this.props.getLot(lotId || recentPackage?.assignedLot?._id)
      : this.props.getAssignedLots(sentFromCompany._id)
    ).then(lot => {
      this.redirectToCompanyTypedLot(lot);

      lot.processVia && change('processVia', extendMachineNameProcessing(lot.processVia));
      lot.mixVia && change('mixVia', extendMachineNameMixing(lot.mixVia));

      if (this.isComponentMounted) {
        this.setState({ assignedLots: this.parseLots() });
      }
    });

    if (recentPackage) {
      change('assignedLot', {
        label: recentPackage.assignedLot.customLotNumber || recentPackage.assignedLot.grgLotNumber,
        value: recentPackage.assignedLot.id || recentPackage.assignedLot._id,
      });
    }
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
  }

  getRedirectUrl(lot) {
    const {
      forSpecifiedLot,
      editMode,
      match: {
        params: { lotId, id: packageId },
      },
    } = this.props;

    if (forSpecifiedLot) {
      const isGRG = isGlobalRefiningGroup(
        lot && lot.sentFromCompany && lot.sentFromCompany.companyName,
      );
      const path = this.props.location.pathname;

      if (isGRG && path.includes('customer')) {
        return editMode
          ? `/lots/list/${lotId}/packages/${packageId}/update/global`
          : `/lots/list/${lotId}/packages/create/global`;
      }
      if (!isGRG && path.includes('global')) {
        return editMode
          ? `/lots/list/${lotId}/packages/${packageId}/update/customer`
          : `/lots/list/${lotId}/packages/create/customer`;
      }
    }
  }

  redirectToCompanyTypedLot(lot) {
    const redirectUrl = this.getRedirectUrl(lot);

    if (redirectUrl) {
      this.props.history.push(redirectUrl);
    }
  }

  get checkMaterialTypeDeclared() {
    return this.areInstructionsRequired() && 'required';
  }

  setMaterialType = materialTypeDeclared => {
    const materialType = materialTypeDeclared && materialTypeDeclared.value;

    denominationTable[materialType] === 'units' ? this.resetWeightFields() : this.resetUnitFields();
  };

  checkDenominatedIn(value) {
    const { materialTypeDeclared } = this.props;
    let unitType = denominationTable[materialTypeDeclared];

    if (unitType === denominatedIn.unitsWeight) {
      unitType = denominatedIn.units;
    }

    return unitType === value;
  }

  resetUnitFields() {
    this.props.change('unitCount', '');
  }

  resetWeightFields() {
    this.props.change('weightGrossDeclared', '');
    this.props.change('weightTareDeclared', '');
    this.props.change('weightGrossActual', '');
    this.props.change('weightTareActual', '');
  }

  parseLots() {
    const { forSpecifiedLot, forLot, assignedLots } = this.props;
    const forLotArr = forLot ? [forLot] : [];
    const lots = parseLotsSelectData(forSpecifiedLot ? forLotArr : assignedLots.data);

    if (lots.length === 1) {
      this.props.change('assignedLot', lots[0]);
    }

    return lots;
  }

  modalOpen = () => {
    this.props.openModal(cancelModal, () => {
      this.navigator(this.props.location.pathname, {
        pkg: this.props.pkg,
        lot: this.props.forLot,
        packageId: this.props.match.params.id,
        shipmentId: this.props.match.params.shipmentId,
      });
    });
  };

  getPackageTypeOptions() {
    const { config } = this.props;

    if (
      [
        configNames.customer,
        configNames.grgForGlobal,
        configNames.grgForCustomer,
        configNames.incoming,
        configNames.editPackage,
        configNames.ownerEditPackage,
        configNames.warehouseForCustomer,
      ].includes(config)
    ) {
      return availablePackageType.allWithLabels.filter(
        pkgType => pkgType.value !== packageType.sample,
      );
    }

    if (isProcessingPackage(config)) {
      return availablePackageType.processing;
    }

    return [];
  }

  getMaterialTypeDeclaredOptions() {
    const {
      forLot,
      auth: {
        user: { userType },
      },
      config,
    } = this.props;
    const companyType = forLot && forLot.sentFromCompany && forLot.sentFromCompany.companyType;

    if (
      [
        configNames.customer,
        configNames.grgForCustomer,
        configNames.warehouseForCustomer,
        configNames.editPackage,
      ].includes(config)
    ) {
      if (
        isCustomerUser(userType) ||
        isAssayCompany(companyType) ||
        isInternalCompany(companyType)
      ) {
        return availableMaterialTypeForAssay.all;
      }
      if (isGradingUser(userType) || isGradingCompany(companyType)) {
        return availableMaterialTypeForGrading.all;
      }
    } else if (config === configNames.grgForGlobal || config === configNames.warehouseForCustomer) {
      return availableMaterialTypeCommodities.all;
    }

    if (isProcessingPackage(config)) {
      return availableMaterialTypeOutbound.all;
    }

    if (config === configNames.incoming) {
      return allMaterials;
    }

    return [];
  }

  areInstructionsRequired() {
    const { materialTypeDeclared } = this.props;

    return materialTypeDeclared === 'other';
  }

  filter = ({ props }) => configs[this.props.config].includes(props.name);

  get isUpdatePackageWeight() {
    const { editMode, updatePackageWeight } = this.props;

    return editMode && updatePackageWeight;
  }

  get grgCreateIncomingPackage() {
    const { config, editMode } = this.props;

    return isGlobalCreatedPackage(config) && !editMode;
  }

  get processingPackage() {
    const { config, editMode } = this.props;

    return isProcessingPackage(config) && !editMode;
  }

  submit = values => this.props.onSubmit(normalizeFormValues(values));

  render() {
    const {
      handleSubmit,
      editMode,
      updatePackageWeight,
      sentFromCompany,
      auth: {
        user: { userType },
      },
      packageDetails: { package: pkg },
    } = this.props;
    const { assignedLots } = this.state;

    const materialCountRequired =
      sentFromCompany?.materialCountRequired ||
      pkg?.assignedLot.sentFromCompany.materialCountRequired;

    return (
      <Form
        onSubmit={handleSubmit(this.submit)}
        onCancel={this.modalOpen}
        submitDisabled={this.props.invalid || this.props.submitting}
        header={PACKAGES.PACKAGE}
      >
        <FieldsFilter filterFunc={this.filter}>
          <Section
            template={[
              'assignedLot customerInstructions',
              'processVia mixVia',
              'packageType packageType',
              'materialClass materialClass',
              'materialTypeDeclared .',
            ]}
          >
            <Field
              name="assignedLot"
              component={FieldSelect}
              options={assignedLots}
              disabled={assignedLots.length === 1 || editMode}
              label={PACKAGES.ASSIGNED_LOT}
              field="required"
            />
            <Field
              name="customerInstructions"
              component={FieldInput}
              label={PACKAGES.CUSTOMER_INSTRUCTIONS}
              field={this.checkMaterialTypeDeclared}
              disabled={this.isUpdatePackageWeight}
            />
            <Field name="processVia" component={FieldInput} label={PACKAGES.PROCESS_VIA} disabled />
            <Field name="mixVia" component={FieldInput} label={PACKAGES.MIX_VIA} disabled />
            <Field
              name="packageType"
              component={FieldButtonSelect}
              options={mapArray(this.getPackageTypeOptions())}
              label={PACKAGES.PACKAGE_TYPE}
              field="required"
              disabled={this.isUpdatePackageWeight}
            />
            <Field
              name="materialClass"
              component={FieldButtonSelect}
              options={mapArray(availableMaterialClass.all)}
              label={PACKAGES.MATERIAL_CLASS}
              field="required"
              disabled={this.isUpdatePackageWeight}
            />
            <Field
              name="materialTypeDeclared"
              component={FieldSelect}
              options={mapArray(this.getMaterialTypeDeclaredOptions())}
              label={PACKAGES.MATERIAL_TYPE_DECLARED}
              onChange={this.setMaterialType}
              field="required"
              disabled={this.isUpdatePackageWeight || materialCountRequired}
            />
          </Section>
          <Section
            header={PACKAGES.WEIGHTS_UNITS}
            template={['weightGrossDeclared weightTareDeclared', 'labelId plant', 'unitCount .']}
            unwrap
          >
            {!this.checkDenominatedIn('weight') && !updatePackageWeight && (
              <Field
                name="unitCount"
                component={FieldInput}
                type="number"
                label={PACKAGES.UNIT_COUNT}
                suffix="units"
                disabled={this.isUpdatePackageWeight}
              />
            )}
            {!updatePackageWeight && !isWarehouseUser(userType) && (
              <>
                <Field
                  name="weightGrossDeclared"
                  component={FieldInput}
                  type="text"
                  label={PACKAGES.WEIGHT_GROSS_DECLARED}
                  field="required"
                  suffix="lbs"
                  disabled={this.isUpdatePackageWeight}
                />
                <Field
                  name="weightTareDeclared"
                  component={FieldInput}
                  type="text"
                  label={PACKAGES.WEIGHT_TARE_DECLARED}
                  field="required"
                  suffix="lbs"
                  disabled={this.isUpdatePackageWeight}
                />
              </>
            )}
            {materialCountRequired && (
              <>
                <Field
                  name="labelId"
                  component={FieldInput}
                  type="text"
                  label={PACKAGES.LABEL_ID}
                  field={!(this.labelId || this.processingPackage)}
                  normalize={value => value.toUpperCase()}
                />
                <Field
                  name="plant"
                  component={FieldInput}
                  type="text"
                  label={PACKAGES.PLANT}
                  field={!(this.plant || this.processingPackage)}
                  normalize={value => value.toUpperCase()}
                />
              </>
            )}
          </Section>
          <Section header={PACKAGES.WEIGHTS} template={['weightGrossActual weightTareActual']}>
            <Field
              name="weightGrossActual"
              component={FieldInput}
              type="text"
              label={PACKAGES.WEIGHT_GROSS_ACTUAL}
              field={!(this.grgCreateIncomingPackage || this.processingPackage) && 'required'}
              suffix="lbs"
            />
            <Field
              name="weightTareActual"
              component={FieldInput}
              type="text"
              label={PACKAGES.WEIGHT_TARE_ACTUAL}
              field={!(this.grgCreateIncomingPackage || this.processingPackage) && 'required'}
              suffix="lbs"
            />
          </Section>
        </FieldsFilter>
      </Form>
    );
  }
}

const selector = formValueSelector('PackageForm');
const mapStateToProps = state => ({
  auth: state.auth,
  CreatePackageForm: state.form.CreatePackageForm,
  assignedLots: state.assignedLots,
  forLot: state.lotDetails.lot,
  packageDetails: state.packageDetails,
  lotDetails: state.lotDetails,
  materialTypeDeclared:
    selector(state, 'materialTypeDeclared') && selector(state, 'materialTypeDeclared').value,
});

const mapDispatchToProps = {
  getLot,
  getAssignedLots,
  openModal,
};

const PackageForm = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    reduxForm({
      form: 'PackageForm',
      validate,
    })(PackageFormBase),
  ),
);
export { PackageForm, PackageFormBase };
