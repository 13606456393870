import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getAgreementsListActionType = 'GET_AGREEMENTS_LIST';
function getAgreementsListAction() {
  return {
    type: getAgreementsListActionType,
  };
}

const getAgreementsListSuccessActionType = 'GET_AGREEMENTS_LIST_SUCCESS';
function getAgreementsListSuccessAction({ docs, pages, limit, page, company }) {
  return {
    type: getAgreementsListSuccessActionType,
    payload: {
      agreements: docs,
      pages,
      limit,
      page,
      company,
    },
  };
}

const getAgreementsListFailureActionType = 'GET_AGREEMENTS_LIST_FAILURE';
function getAgreementsListFailureAction(errorMessage) {
  return {
    type: getAgreementsListFailureActionType,
    payload: { errorMessage },
  };
}

const clearAgreementsListActionType = 'CLEAR_AGREEMENTS_LIST_ACTION';
function clearAgreementsList() {
  return {
    type: clearAgreementsListActionType,
  };
}

function parseData(companyId, filters, limit, page) {
  return {
    companyId,
    ...filters,
    limit,
    page,
  };
}

function getAgreementsList(companyId, limit, page, agreementsFilters) {
  return dispatch => {
    dispatch(getAgreementsListAction());
    return axios({
      method: 'GET',
      url: `${API_HOST_AUTH}/agreements`,
      params: parseData(companyId, agreementsFilters, limit, page),
    })
      .then(({ data }) => dispatch(getAgreementsListSuccessAction(data)))
      .catch(() => dispatch(getAgreementsListFailureAction('')));
  };
}

export {
  getAgreementsListActionType,
  getAgreementsListSuccessActionType,
  getAgreementsListFailureActionType,
  clearAgreementsListActionType,
  getAgreementsList,
  clearAgreementsList,
};
