const formatName = name => name && name.split('].')[1];

const navigateTable = (
  e,
  { current, max, fields },
  setRowFunc,
  onNavigate = () => {},
  customFormatName,
) => {
  const validChild = e.currentTarget.children[3] || e.currentTarget.children[2];

  const nameFormatter = customFormatName || formatName;

  const fieldName = e.target.name
    ? nameFormatter(e.target.name)
    : validChild && nameFormatter(validChild.name);

  const fieldIndex = fields.indexOf(fieldName);

  switch (e.keyCode) {
    case 37:
      if (fieldIndex !== 0 && !e.target.value) {
        setRowFunc.column(fields[fieldIndex - 1]);
      }
      onNavigate();
      break;
    case 38:
      if (current > 0) {
        setRowFunc.row(current - 1);
        setRowFunc.column(fieldName);
        setRowFunc.triggerErrors(true);
      }
      onNavigate();
      break;
    case 39:
      if (fieldIndex < fields.length - 1 && !e.target.value) {
        setRowFunc.column(fields[fieldIndex + 1]);
      }
      onNavigate();
      break;
    case 40:
      if (current < max) {
        setRowFunc.row(current + 1);
        setRowFunc.column(fieldName);
        setRowFunc.triggerErrors(true);
      }
      onNavigate();
      break;
    case 9:
      onNavigate();
      break;
    default:
      return;
  }
};

export { navigateTable };
