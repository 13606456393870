import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { destroy, reset } from 'redux-form';

import {
  getSummaryAvailableLots,
  clearSummaryAvailableLots,
} from 'actions/PoolAccounts/getSummaryAvailableLots';
import {
  addSettlementWizardItemAction,
  clearSettlementWizardAction,
} from 'actions/PoolAccounts/settlementForLotWizard';
import { openModal } from 'actions/shared/modal';

import { Wizard, formName } from 'shared/components/Wizard/Wizard';
import { cancelModal, requestSettlementWizardModal } from 'shared/helpers/constants/modalConstants';
import { POOL_ACCOUNTS } from 'shared/helpers/constants/poolAccounts/poolAccountsConstants';
import { parseSettlementForLotData } from 'shared/helpers/dataParsers/settlements/parseSettlementForLotData';

import { ApplyBlanketHedgesForm } from './components/ApplyBlanketHedgesForm/ApplyBlanketHedgesForm';
import { RequestSettlementForm } from './components/SettlementForLotForm/RequestSettlementForm';
import { SettlementWizardSummary } from './components/SettlementWizardSummary/SettlementWizardSummary';

const steps = {
  applyBlanketHedges: ApplyBlanketHedgesForm,
  settlement: RequestSettlementForm,
  settlementWizardSummary: SettlementWizardSummary,
};

const initialValues = {
  settlement: {
    palladiumOz: null,
    platinumOz: null,
    rhodiumOz: null,
    requestPayment: true,
  },
  applyBlanketHedges: {
    relatedLot: null,
    deliveries: [],
  },
  ouncesRemoved: {
    palladiumOz: null,
    platinumOz: null,
    rhodiumOz: null,
  },
};

const SettlementForLotWizard = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const requestSettlementWizardData = useSelector(
    ({ requestSettlementWizard }) => requestSettlementWizard.data,
  );

  const summaryAvailableLots = useSelector(({ summaryAvailableLots }) => summaryAvailableLots);

  const { relatedCompany } = useSelector(
    ({
      auth: {
        user: { relatedCompany },
      },
    }) => ({
      relatedCompany,
    }),
  );

  useEffect(() => {
    dispatch(getSummaryAvailableLots('lots-to-settle'));
    return () => {
      dispatch(clearSummaryAvailableLots());
      dispatch(destroy(formName));
      dispatch(clearSettlementWizardAction());
    };
  }, []);

  const modalAction = (cb, values) => () =>
    dispatch(reset(formName)) && dispatch(addSettlementWizardItemAction(values)) && cb();

  const onSubmit = useCallback(
    async (values, first, next) => {
      const mappedValues = parseSettlementForLotData(values);

      if (
        Object.keys(requestSettlementWizardData || {}).length >=
        summaryAvailableLots?.lots.length - 1
      ) {
        dispatch(addSettlementWizardItemAction(mappedValues));
        return next();
      }

      await dispatch(
        openModal(
          requestSettlementWizardModal,
          modalAction(next, mappedValues),
          null,
          modalAction(first, mappedValues),
        ),
      );
    },
    [requestSettlementWizardData, summaryAvailableLots],
  );

  const onCancel = useCallback(() => {
    dispatch(
      openModal(cancelModal, () => {
        history.push('/lots');
      }),
    );
  }, [dispatch]);

  return (
    <Wizard
      steps={steps}
      header={POOL_ACCOUNTS.SETTLEMENT_FOR_LOTS}
      subHeader={relatedCompany.companyName}
      onSubmit={onSubmit}
      onCancel={onCancel}
      withSummary
      summaryAvailable={Object.keys(requestSettlementWizardData || {}).length}
      initialValues={initialValues}
    />
  );
};

export { SettlementForLotWizard };
