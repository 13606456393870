import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { approveTerms } from 'actions/Terms/approveTerms';
import { showSnackbar } from 'actions/shared/snackbar';

import { snackbarOptions } from 'shared/helpers/matchers/snackbar/snackbarOptions';

class TermsApprove extends Component {
  onClick = () => {
    const { terms, approve } = this.props;
    this.props.approveTerms(terms, !!approve).then(this.resolveAction);
  };

  resolveAction = result => {
    this.props.showSnackbar(snackbarOptions(result.messageType), result.message);
  };

  render() {
    return <React.Fragment>{this.props.children({ onClick: this.onClick })}</React.Fragment>;
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      approveTerms,
      showSnackbar,
    },
    dispatch,
  );
}

const ConnectedAgreementTermsApprove = connect(null, mapDispatchToProps)(TermsApprove);

export { ConnectedAgreementTermsApprove as TermsApprove, TermsApprove as TermsApproveTests };
