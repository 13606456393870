const parseLotsSelectData = lots =>
  lots.map(lot => ({
    value: lot._id,
    label: lot.grgLotNumber,
    grgLotNumber: lot.grgLotNumber,
    customLotNumber: lot.customLotNumber,
    assignedShipmentsCount: lot.assignedShipmentsCount,
  }));

const parseAllLotsSelectData = lots =>
  lots.map(lot => ({
    ...lot,
    value: lot._id,
    label: lot.grgLotNumber,
    grgLotNumber: lot.grgLotNumber,
  }));

const parseLotsData = lots =>
  lots.map(lot => ({
    ...lot,
    value: lot._id,
    label: lot.grgLotNumber,
  }));

export { parseLotsSelectData, parseAllLotsSelectData, parseLotsData };
