import { companyTypesEnum } from 'shared/helpers/constants/filters/companyTypes';
import { roles } from 'shared/helpers/constants/filters/userTypes';
import { statusesForGlobal } from 'shared/helpers/constants/lots/statusesForGlobal';
import { isStandard } from 'shared/helpers/matchers/checkAccessLevel';

const allowedStatuses = [statusesForGlobal.invoice_accepted];

const allowedCompanyTypes = [companyTypesEnum.internal, companyTypesEnum.assay];

const finalizeLotAccess = (
  {
    access: {
      lots: { finalize },
    },
  },
  lot,
) =>
  finalize &&
  ((lot.sentFromCompany.companyType === companyTypesEnum.grading &&
    lot.statusForGlobal !== statusesForGlobal.finalized) ||
    (allowedStatuses.includes(lot.statusForGlobal) &&
      allowedCompanyTypes.includes(lot.sentFromCompany.companyType)));

const specialPermissionSetFinalizedLotAccess = (
  { access, user: { userType, accessLevel } },
  { sentFromCompany, statusForGlobal },
) => {
  const { setFinalizedLot } = access.specialPermissions.lots;
  let userHasAccess = true;

  switch (userType) {
    case roles.owner.name:
      if (statusForGlobal === statusesForGlobal.invoice_accepted) userHasAccess = false;
      break;
    case roles.support.name:
      if (isStandard(accessLevel) && statusForGlobal === statusesForGlobal.invoice_accepted)
        userHasAccess = false;
      break;
    default:
      userHasAccess = true;
  }

  return (
    setFinalizedLot &&
    statusForGlobal !== statusesForGlobal.finalized &&
    userHasAccess &&
    allowedCompanyTypes.includes(sentFromCompany.companyType)
  );
};

export { finalizeLotAccess, specialPermissionSetFinalizedLotAccess };
