import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getHedgeOuncesActionType = 'GET_HEDGE_OUNCES';
const getHedgeOuncesAction = () => ({
  type: getHedgeOuncesActionType,
});

const getHedgeOuncesSuccessActionType = `${getHedgeOuncesActionType}_SUCCESS`;
const getHedgeOuncesSuccessAction = hedgeOunces => ({
  type: getHedgeOuncesSuccessActionType,
  payload: {
    hedgeOunces,
  },
});

const getHedgeOuncesFailureActionType = `${getHedgeOuncesActionType}_FAILURE`;
const getHedgeOuncesFailureAction = () => ({
  type: getHedgeOuncesFailureActionType,
});

const clearHedgeOuncesActionType = 'CLEAR_HEDGE_OUNCES';
const clearHedgeOunces = () => ({
  type: clearHedgeOuncesActionType,
});

const getHedgeOunces = () => dispatch => {
  dispatch(getHedgeOuncesAction());

  return axios
    .get(`${API_HOST_AUTH}/hedges/hedge-ounces`)
    .then(({ data }) => {
      dispatch(getHedgeOuncesSuccessAction(data));

      return data;
    })
    .catch(err => Promise.reject(dispatch(getHedgeOuncesFailureAction(err))));
};

export {
  getHedgeOuncesActionType,
  getHedgeOuncesAction,
  getHedgeOuncesSuccessActionType,
  getHedgeOuncesSuccessAction,
  getHedgeOuncesFailureActionType,
  getHedgeOuncesFailureAction,
  getHedgeOunces,
  clearHedgeOuncesActionType,
  clearHedgeOunces,
};
