import React, { useEffect, useContext } from 'react';
import { connect, useSelector } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm, FormSection, getFormValues } from 'redux-form';
import styled from 'styled-components';

import { FieldInputHeadlined } from 'shared/components/Fields';
import { FieldInput } from 'shared/components/Fields/FieldInput/FieldInput';
import { Form, Section } from 'shared/components/Form';
import { formName } from 'shared/components/Wizard/Wizard';
import { wizardContext } from 'shared/components/Wizard/Wizard';
import { PAYMENTS } from 'shared/helpers/constants/payments/paymentsConstants';
import { calculatePoolPaymentAmount } from 'shared/helpers/dataParsers/payments/calculatePoolPaymentAmount';
import { formatToDollar } from 'shared/helpers/parsers/formaters';
import { cssVar } from 'shared/helpers/styling/styling';
import { multiValidator } from 'shared/helpers/validations/multiValidator';
import { validate } from 'shared/helpers/validations/payments/settlementValidation';

export const PaymentAmountContainer = styled.div`
  margin-top: 8px;
  font-size: 18px;
  height: 25px;
  color: ${cssVar('whiteGRG')};
`;

export const paymentAmountOutOfRange = (settlement, charges) =>
  calculatePoolPaymentAmount(settlement, charges).toString().length > 12 ||
  Number.isNaN(calculatePoolPaymentAmount(settlement, charges));

const SettlementFormLayout = ({
  handleSubmit,
  invalid,
  submitting,
  change,
  formValues: {
    payment,
    settlement,
    financeCharges: { charges },
  },
}) => {
  const { ouncesPt, ouncesPd, ouncesRh } = settlement;
  const {
    relatedCompany: { balanceRemaining },
  } = payment;
  const { isPending } = useSelector(({ enterPaymentDetails }) => enterPaymentDetails);
  const wizard = useContext(wizardContext);
  useEffect(() => {
    if (settlement) {
      +ouncesPt === 0 && change('settlement.pricePerOuncePt', null);
      +ouncesPd === 0 && change('settlement.pricePerOuncePd', null);
      +ouncesRh === 0 && change('settlement.pricePerOunceRh', null);
    }
  }, [ouncesPt, ouncesPd, ouncesRh]);

  useEffect(() => {
    settlement && change('settlement.balanceRemaining', balanceRemaining);
  }, [balanceRemaining]);

  const isEditingMode = () => wizard.editMode;

  return (
    <Form
      header={PAYMENTS.SETTLEMENT}
      onSubmit={handleSubmit}
      submitDisabled={
        invalid ||
        submitting ||
        isPending ||
        paymentAmountOutOfRange(settlement, charges) ||
        +calculatePoolPaymentAmount(settlement, charges) < 0
      }
    >
      <FormSection name="settlement">
        <Section template={['ouncesPt pricePerOuncePt']} header={PAYMENTS.PLATINUM}>
          <Field
            name="ouncesPt"
            component={FieldInputHeadlined}
            label={PAYMENTS.OUNCES}
            type="text"
            field="required"
            suffix="oz"
            maxLength={10}
            headline={payment.relatedCompany.platinumOz}
            hint={PAYMENTS.MAXIMUM}
            disabled={payment.relatedCompany.platinumOz === 0 || isEditingMode()}
            forceHint
            hintClickable={payment.relatedCompany.platinumOz !== 0}
          />
          <Field
            name="pricePerOuncePt"
            component={FieldInput}
            label={PAYMENTS.PRICE_PER_OUNCE}
            type="text"
            field="required"
            prefix="$"
            maxLength={12}
            disabled={payment.relatedCompany.platinumOz === 0 || +settlement.ouncesPt === 0}
          />
        </Section>
        <Section template={['ouncesPd pricePerOuncePd']} header={PAYMENTS.PALLADIUM}>
          <Field
            name="ouncesPd"
            component={FieldInputHeadlined}
            label={PAYMENTS.OUNCES}
            type="text"
            field="required"
            suffix="oz"
            maxLength={10}
            headline={payment.relatedCompany.palladiumOz}
            hint={PAYMENTS.MAXIMUM}
            disabled={payment.relatedCompany.palladiumOz === 0 || isEditingMode()}
            forceHint
            hintClickable={payment.relatedCompany.palladiumOz !== 0}
          />
          <Field
            name="pricePerOuncePd"
            component={FieldInput}
            label={PAYMENTS.PRICE_PER_OUNCE}
            type="text"
            field="required"
            prefix="$"
            maxLength={12}
            disabled={payment.relatedCompany.palladiumOz === 0 || +settlement.ouncesPd === 0}
          />
        </Section>
        <Section template={['ouncesRh pricePerOunceRh']} header={PAYMENTS.RHODIUM}>
          <Field
            name="ouncesRh"
            component={FieldInputHeadlined}
            label={PAYMENTS.OUNCES}
            type="text"
            field="required"
            suffix="oz"
            maxLength={10}
            headline={payment.relatedCompany.rhodiumOz}
            hint={PAYMENTS.MAXIMUM}
            disabled={payment.relatedCompany.rhodiumOz === 0 || isEditingMode()}
            forceHint
            hintClickable={payment.relatedCompany.rhodiumOz !== 0}
          />
          <Field
            name="pricePerOunceRh"
            component={FieldInput}
            label={PAYMENTS.PRICE_PER_OUNCE}
            type="text"
            field="required"
            prefix="$"
            maxLength={12}
            disabled={payment.relatedCompany.rhodiumOz === 0 || +settlement.ouncesRh === 0}
          />
        </Section>
        {balanceRemaining && balanceRemaining !== 0 && (
          <Section
            template={['balanceRemaining outstandingBalanceApplied']}
            header={PAYMENTS.OUTSTANDING_BALANCE_APPLIED_HEADER}
          >
            <Field
              name="balanceRemaining"
              field="required"
              component={FieldInput}
              label={PAYMENTS.BALANCE_REMAINING}
              color={cssVar('solidRed')}
              prefix="$"
              format={formatToDollar}
              disabled
            />

            <Field
              name="outstandingBalanceApplied"
              component={FieldInput}
              label={PAYMENTS.OUTSTANDING_BALANCE_APPLIED}
              type="text"
              field="required"
              prefix="$"
              maxLength={15}
            />
          </Section>
        )}
      </FormSection>
      <PaymentAmountContainer>
        {paymentAmountOutOfRange(settlement)
          ? PAYMENTS.PAYMENT_AMOUNT_OUT_OF_RANGE
          : PAYMENTS.CALCULATED_PAYMENT_AMOUNT(calculatePoolPaymentAmount(settlement, charges))}
      </PaymentAmountContainer>
    </Form>
  );
};

const mapStateToProps = state => ({
  formValues: getFormValues(formName)(state),
});

const SettlementForm = compose(
  reduxForm({
    validate: multiValidator('settlement', validate),
  }),
  connect(mapStateToProps),
)(SettlementFormLayout);

export { SettlementForm, SettlementFormLayout };
