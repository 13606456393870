import { capitalize } from 'shared/helpers/parsers/text';

const extendMachineNameProcessing = name => {
  switch (name.charAt(0)) {
    case 'M':
      return `Mill ${name.slice(1)}`;
    case 'P':
      return `Shears - ${name}`;
    default:
      return capitalize(name);
  }
};

const extendMachineNameMixing = name => {
  switch (name.charAt(0)) {
    case 'M':
      return `Mixer ${name.slice(1)}`;
    default:
      return capitalize(name);
  }
};

const generateSectionHeaderFactory =
  (extender = v => v) =>
  (name, count) =>
    `${extender(name)} (${count})`;

const generateProcessingSectionHeader = generateSectionHeaderFactory(extendMachineNameProcessing);

const generateMixingSectionHeader = generateSectionHeaderFactory(extendMachineNameMixing);

const generateIncomingSectionHeader = generateSectionHeaderFactory();

export {
  extendMachineNameProcessing,
  extendMachineNameMixing,
  generateProcessingSectionHeader,
  generateMixingSectionHeader,
  generateIncomingSectionHeader,
};
