import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { destroy as destroyAction } from 'redux-form';

import { clearAssignedLotsAction } from 'actions/Lots/getAssignedLots';
import { clearLotDetailsAction } from 'actions/Lots/lotDetails';
import {
  createMultiplePackages as createMultiplePackagesAction,
  createMultiplePackagesForCustomer as createMultiplePackagesForCustomerAction,
  createMultiplePackagesForPackingList as createMultiplePackagesForPackingListAction,
  createMultiplePackagesForGlobal as createMultiplePackagesForGlobalAction,
  createMultipleProcessingPackages as createMultipleProcessingPackagesAction,
} from 'actions/Packages/createMultiplePackages';
import { openModal as openModalAction } from 'actions/shared/modal';
import { showSnackbar as showSnackbarAction } from 'actions/shared/snackbar';

import { Wizard, formName } from 'shared/components/Wizard/Wizard';
import { roles } from 'shared/helpers/constants/filters/userTypes';
import { cancelModal } from 'shared/helpers/constants/modalConstants';
import { PACKAGES } from 'shared/helpers/constants/packages/packageConstants';
import {
  successOptions,
  dangerOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { parseAssignedCats } from 'shared/helpers/dataParsers/packages/parseSamplePackages';
import { isCustomerOrGrading } from 'shared/helpers/matchers/checkUserType';
import { isGlobalRefiningGroup } from 'shared/helpers/matchers/isGlobalRefiningGroup';
import { normalizeFormValues } from 'shared/helpers/parsers/formNormalizer';
import { humanize, camelToKebab } from 'shared/helpers/parsers/text';

import { goBackOrTo } from 'utils/history';

import { ConnectedMultiPackagesForm } from 'pages/Packages/components/PackagesManagement/components/MultiPackagesCreate/MultiPackagesCreate';
import { IncomingPackageForm } from 'pages/Packages/components/PackagesManagement/components/MultiPackagesCreate/components/IncommingPackageForm/IncomingPackageForm';
import { SamplePackagesForm } from 'pages/Packages/components/PackagesManagement/components/SamplePackagesForm/SamplePackagesForm';

const steps = userType => ({
  defaultPackagesValues: IncomingPackageForm,
  packages: ConnectedMultiPackagesForm,
  samplePackages: props => <SamplePackagesForm {...props} userType={userType} />,
});

const initialValues = {
  defaultPackagesValues: {
    oneOrMultiLots: {
      label: humanize(PACKAGES.LOTS_FOR_PACKAGES.SAME_LOT),
      value: PACKAGES.LOTS_FOR_PACKAGES.SAME_LOT,
    },
    packagesQuantity: 1,
  },
  samplePackages: {
    laboratoryPolicy: false,
    contactPerson: { label: PACKAGES.PORTAL_USER_LABEL, value: PACKAGES.PORTAL_USER_VALUE },
  },
};

function CreateMultiIncomingPackagesBase({
  auth,
  lotDetails: { lot },
  config,
  destroy,
  match,
  history,
  showSnackbar,
  openModal,
  createMultiplePackages,
  createMultiplePackagesForGlobal,
  createMultiplePackagesForCustomer,
  createMultipleProcessingPackages,
  createMultiplePackagesForPackingList,
  clearLotDetails,
  clearAssignedLots,
}) {
  const [defaultMaterialType, setDefaultMaterialType] = useState(null);
  const isMaterialCountRequired = lot?.sentFromCompany.materialCountRequired;
  const isGlobalCompany = isGlobalRefiningGroup(lot?.sentFromCompany.companyName);

  const onSubmit = useCallback(
    ({ defaultPackagesValues, packages, samplePackages = {} }) => {
      const { assignedCats, ...parsedSamplePackages } = samplePackages;
      const packagesDataSource = assignedCats || packages;

      const parsedPackages = packagesDataSource.map(parseAssignedCats(auth.user.userType));

      const data = {
        defaultPackagesValues: defaultPackagesValues,
        packages: parsedPackages.map(normalizeFormValues),
        ...(parsedSamplePackages && { samplePackages: parsedSamplePackages }),
      };

      if (config) {
        return createMultipleProcessingPackages(data, camelToKebab(config))
          .then(({ message }) => {
            history.push(
              history.location.state && history.location.state.fromLotDetails
                ? `/lots/list/${lot._id}`
                : `/lots/processing/complete/${lot._id}`,
            );
            showSnackbar(successOptions, message);
          })
          .catch(({ message }) => showSnackbar(dangerOptions, message));
      }
      if (isCustomerOrGrading(auth.user.userType)) {
        return createMultiplePackages(data)
          .then(({ message, packages }) => {
            showSnackbar(successOptions, message);

            history.push(
              (history.location.state && history.location.state.referenceUrl) || '/shipments',
              { packages },
            );
          })
          .catch(({ message }) => showSnackbar(dangerOptions, message));
      }
      if (lot && isGlobalRefiningGroup(lot.sentFromCompany.companyName)) {
        return createMultiplePackagesForGlobal(data)
          .then(({ message }) => {
            showSnackbar(successOptions, message);
            if (match.url.includes('processing')) {
              history.push(`/lots/processing/accept/${lot._id}`);
            } else {
              history.push(`/lots/list/${lot._id}`);
            }
          })
          .catch(({ message }) => {
            showSnackbar(dangerOptions, message);
          });
      }

      const packagesData = {
        ...data,
        ...(isMaterialCountRequired && {
          packages: data.packages.map(pkg => ({
            ...pkg,
            materialCountRequired: isMaterialCountRequired,
          })),
        }),
      };

      if (isMaterialCountRequired) {
        return createMultiplePackagesForPackingList(packagesData)
          .then(({ message }) => {
            history.push(`/lots/list/${lot._id}`);
            showSnackbar(successOptions, message);
          })
          .catch(({ message }) => showSnackbar(dangerOptions, message));
      }

      return createMultiplePackagesForCustomer(packagesData)
        .then(({ message, packages }) => {
          history.push(
            (history.location.state && history.location.state.referenceUrl) ||
              `/lots/list/${lot._id}`,
            { packages },
          );
          showSnackbar(successOptions, message);
        })
        .catch(({ message }) => {
          showSnackbar(dangerOptions, message);
        });
    },
    [
      auth.user.userType,
      config,
      createMultiplePackages,
      createMultiplePackagesForCustomer,
      createMultiplePackagesForGlobal,
      createMultipleProcessingPackages,
      history,
      lot?._id,
      lot?.sentFromCompany.companyName,
      match.url,
      showSnackbar,
    ],
  );

  const onCancel = useCallback(() => {
    openModal(cancelModal, () => {
      const {
        user: { userType },
      } = auth;

      if (history.location.state && history.location.state.referenceUrl) {
        return history.push(history.location.state.referenceUrl);
      }

      if (userType === roles.customer.name || userType === roles.grading.name) {
        return history.push('/shipments');
      }

      goBackOrTo('/');
    });
  }, [auth, history, openModal]);

  useEffect(
    () => () => {
      destroy(formName);
      clearLotDetails();
      clearAssignedLots();
    },
    [destroy, clearLotDetails],
  );

  return (
    <Wizard
      hiddenSteps={['samplePackages']}
      steps={steps(auth.user.userType)}
      onSubmit={onSubmit}
      onCancel={onCancel}
      initialValues={initialValues}
      header={PACKAGES.CREATE_PACKAGES}
      contextProps={{
        config,
        isGlobalCompany,
        data: { defaultMaterialType, setDefaultMaterialType },
      }}
    />
  );
}

const mapStateToProps = state => ({
  auth: state.auth,
  lotDetails: state.lotDetails,
});

const mapDispatchToProps = {
  showSnackbar: showSnackbarAction,
  openModal: openModalAction,
  clearAssignedLots: clearAssignedLotsAction,
  clearLotDetails: clearLotDetailsAction,
  createMultiplePackages: createMultiplePackagesAction,
  createMultiplePackagesForCustomer: createMultiplePackagesForCustomerAction,
  createMultiplePackagesForGlobal: createMultiplePackagesForGlobalAction,
  createMultipleProcessingPackages: createMultipleProcessingPackagesAction,
  destroy: destroyAction,
  createMultiplePackagesForPackingList: createMultiplePackagesForPackingListAction,
};

const CreateMultiIncomingPackages = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(CreateMultiIncomingPackagesBase);

export { CreateMultiIncomingPackages, CreateMultiIncomingPackagesBase };
