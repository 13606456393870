import {
  getFinalPaymentDateValidation,
  getFinalPaymentDateValidationSuccessActionType,
  getFinalPaymentDateValidationFailureActionType,
} from 'actions/Payments/getFinalPaymentDateValidation';

const INITIAL_STATE = {
  isPending: false,
  isPaymentDateExists: false,
};

const getFinalPaymentDateValidationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getFinalPaymentDateValidation:
      return {
        ...state,
        isPending: true,
      };
    case getFinalPaymentDateValidationSuccessActionType:
      return {
        ...state,
        isPending: false,
        isPaymentDateExists: action.payload.paymentExists,
      };
    case getFinalPaymentDateValidationFailureActionType:
      return {
        ...state,
        isPending: false,
      };
    default:
      return state;
  }
};

export { getFinalPaymentDateValidationReducer };
