import { acceptForMixingSuccessType } from 'actions/Lots/acceptForMixing';
import { acceptForProcessingSuccessType } from 'actions/Lots/acceptForProcessing';
import {
  getPackagesOverviewActionType,
  getPackagesOverviewFailureActionType,
  getPackagesOverviewSuccessActionType,
  clearPackagesOverviewActionType,
  setPackageAsClosedActionType,
  clearAllPackagesOverviewType,
} from 'actions/Packages/packagesOverviewList';
import { saveTemporaryPackageDataSuccessActionType } from 'actions/Packages/saveTemporaryPackageData';

import {
  globalPackageClasses,
  packageClasses,
  overviewPackageClasses,
} from 'shared/helpers/constants/packages/packageClasses';
import { packageStatuses } from 'shared/helpers/constants/packages/packageType';
import { toSnakeCase } from 'shared/helpers/parsers/text';

function fillGaps(data, sectionType) {
  const { docs: packages, packageCountIncoming } = data;

  if (
    ![overviewPackageClasses.incoming, overviewPackageClasses.all].includes(sectionType) ||
    packageCountIncoming === undefined
  ) {
    return data;
  }

  const filledPackages = Array.from({ length: packageCountIncoming }).map((gap, index) => {
    const currentPackageNumber = packageCountIncoming - index;
    const existingPackage = packages.find(
      ({ packageNumber }) => packageNumber === currentPackageNumber,
    );

    return (
      existingPackage || {
        status: packageStatuses.deleted,
        packageNumber: currentPackageNumber,
        packageCountIncoming,
        packageType: '-',
        materialTypeDeclared: '-',
      }
    );
  });

  return {
    ...data,
    total: packageCountIncoming,
    docs: filledPackages,
  };
}

const INITIAL_STATE = {
  isPending: false,
  errorMessage: null,
  packagesList: {},
  temporaryData: {
    editMultipackagesMode: false,
    editPackageMode: false,
  },
};

const PackagesOverviewReducer = (state = INITIAL_STATE, action) => {
  const { packagesList } = state;
  const { payload } = action;
  const packagesSection = {};

  switch (action.type) {
    case getPackagesOverviewActionType:
      return {
        ...state,
        isPending: true,
      };
    case saveTemporaryPackageDataSuccessActionType:
      return {
        ...state,
        temporaryData: {
          ...state.temporaryData,
          ...action?.payload,
          editMultipackagesMode: action?.payload?.editMultipackagesMode,
          editPackageMode: action?.payload?.editPackageMode,
        },
      };
    case getPackagesOverviewSuccessActionType: {
      const sectionType = toSnakeCase(payload.sectionType);
      packagesSection[sectionType] = fillGaps(payload.packages, sectionType);

      return {
        ...state,
        isPending: false,
        packagesList: {
          ...packagesList,
          ...packagesSection,
          items: payload.packages,
        },
      };
    }
    case getPackagesOverviewFailureActionType:
      return {
        ...state,
        isPending: false,
        errorMessage: action.payload.errorMessage,
      };
    case clearPackagesOverviewActionType:
      packagesSection[toSnakeCase(payload.sectionType)] = null;

      return {
        ...state,
        isPending: false,
        errorMessage: null,
        packagesList: {
          ...packagesList,
          ...packagesSection,
        },
      };
    case setPackageAsClosedActionType:
      return {
        ...state,
        packagesList: {
          ...state.packagesList,
          [payload.sectionType]: {
            ...state.packagesList[payload.sectionType],
            docs: state.packagesList[payload.sectionType].docs.map(pkg => ({
              ...pkg,
              status: packageStatuses.closed,
            })),
          },
        },
      };
    case acceptForMixingSuccessType: {
      const postMillPackages =
        state.packagesList && state.packagesList[globalPackageClasses.post_mill];

      return postMillPackages
        ? {
            ...state,
            packagesList: {
              ...state.packagesList,
              [globalPackageClasses.post_mill]: {
                ...postMillPackages,
                docs: postMillPackages.docs.map(pkg => ({
                  ...pkg,
                  status: packageStatuses.closed,
                })),
              },
            },
          }
        : state;
    }
    case acceptForProcessingSuccessType: {
      const incomingPackages = state.packagesList && state.packagesList[packageClasses.incoming];

      return incomingPackages
        ? {
            ...state,
            packagesList: {
              ...state.packagesList,
              [packageClasses.incoming]: {
                ...incomingPackages,
                docs: incomingPackages.docs.map(pkg => ({
                  ...pkg,
                  status: packageStatuses.closed,
                })),
              },
            },
          }
        : state;
    }
    case clearAllPackagesOverviewType:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};

export { PackagesOverviewReducer };
