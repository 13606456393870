import axios from 'axios';

import { NOTE } from 'shared/helpers/constants/noteConstants';
import { parseNoteData } from 'shared/helpers/dataParsers/notes/parseNoteData';

const API_HOST_AUTH = process.env.REACT_APP_API;

const createNoteActionType = 'CREATE_NOTE';
function createNoteAction() {
  return { type: createNoteActionType };
}

const createNoteSuccessActionType = 'CREATE_NOTE_SUCCESS';
function createNoteSuccessAction() {
  return { type: createNoteSuccessActionType };
}

const createNoteFailureActionType = 'CREATE_NOTE_FAILURE';
function createNoteFailureAction() {
  return { type: createNoteFailureActionType };
}

function createNote(values, files, relatedId, type, doNotUpdate = false, virtual = false) {
  return dispatch => {
    dispatch(createNoteAction());

    return axios({
      method: 'post',
      url: `${API_HOST_AUTH}/notes`,
      headers: { 'Content-Type': 'multipart/form-data' },
      data: parseNoteData(values, files, relatedId, type, doNotUpdate, virtual),
    })
      .then(data => {
        dispatch(createNoteSuccessAction());

        return data;
      })
      .catch(() => {
        dispatch(createNoteFailureAction());

        return Promise.reject({ message: NOTE.NOTE_CREATED_ERROR });
      });
  };
}

export {
  createNoteActionType,
  createNoteSuccessActionType,
  createNoteFailureActionType,
  createNote,
};
