import PropTypes from 'prop-types';
import React from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { PROFIT_LOSS } from 'shared/helpers/constants/lots/profitLossConstants';
import { POOL_ACCOUNTS } from 'shared/helpers/constants/poolAccounts/poolAccountsConstants';
import { COUNTING_REQUIRED_SECTIONS } from 'shared/helpers/constants/warehouseDashboard/labels';
import { isSupportUser } from 'shared/helpers/matchers/checkUserType';
import { formatToUS } from 'shared/helpers/parsers/formaters';
import { replaceEmpty } from 'shared/helpers/parsers/text';
import { relativeNavigator } from 'utils/relativeNavigator';
import { Lot, VirtualLot } from './LotElement/LotElement';

import {
  Column,
  DroppableWrapper,
  DropZone,
  Group,
  Header,
  Label,
  SelfDelivery,
  SelfDeliveryWrapper,
  Subtitle,
  Title,
} from './StyledDashboardColumn';

const SelfDeliveryPositions = ({ elements }) => (
  <SelfDeliveryWrapper data-testid="self-delivery-wrapper">
    {elements.map(
      ({ _id, unitCount }, index) =>
        unitCount && (
          <SelfDelivery aria-labelledby="" key={`${_id}_${index}`}>
            {unitCount}
          </SelfDelivery>
        ),
    )}
  </SelfDeliveryWrapper>
);

const LotPositions = ({ elements, isPending, columnName, isDragDisabled }) => {
  const { pathname } = useLocation();

  const createProcessingNote = id => () => {
    const navigation = [{ from: /^\/lots/, to: `/lots/create-processing-note/${id}` }];
    relativeNavigator(navigation)()(pathname);
  };

  return elements.map(
    (
      {
        _id,
        unitCount,
        grgLotNumber,
        subsection,
        customLotNumber,
        receivedAt,
        delayed,
        name,
        color,
        weightGrossActual,
        date,
        processingNotes,
        weightDiscrepancyLbs,
        designation,
      },
      index,
    ) =>
      !unitCount && (
        <Draggable
          key={`${_id}_${index}`}
          draggableId={`${_id}_${index}`}
          index={index}
          isDragDisabled={isDragDisabled || !!grgLotNumber}
          disableInteractiveElementBlocking={false}
        >
          {(provided, snapshot) =>
            !grgLotNumber ? (
              <VirtualLot
                _id={_id}
                data-testid={`lot_${index}`}
                name={name}
                date={date}
                index={index}
                currentColor={color}
                weight={weightGrossActual}
                dragProvided={provided}
                dragSnapshot={snapshot}
                isDragDisabled={isDragDisabled || isPending}
                processingNotes={processingNotes}
                createProcessingNote={createProcessingNote}
                subsection={subsection}
              />
            ) : (
              <Lot
                _id={_id}
                data-testid={`lot_${index}`}
                delayed={delayed}
                grgLotNumber={grgLotNumber}
                currentColor={color}
                designation={designation}
                customLotNumber={customLotNumber}
                receivedAt={receivedAt}
                dragProvided={provided}
                dragSnapshot={snapshot}
                index={index}
                processingNotes={processingNotes}
                createProcessingNote={createProcessingNote}
                weightDiscrepancyLbs={weightDiscrepancyLbs}
                subsection={subsection}
                columnName={columnName}
              />
            )
          }
        </Draggable>
      ),
  );
};

const Container = ({ label, elements, listId, isPending, columnName, isDragging }) => {
  const { userType } = useSelector(({ auth: { user } }) => user);

  const isDropDisabled = labelName => {
    return (
      isDragging && labelName === COUNTING_REQUIRED_SECTIONS.HOLD.name && isSupportUser(userType)
    );
  };

  return (
    <Droppable
      droppableId={listId}
      ignoreContainerClipping={true}
      isDropDisabled={isDropDisabled(label)}
    >
      {(provided, snapshot) => (
        <DroppableWrapper isLabeled={label ? true : false} isDraggingOver={snapshot.isDraggingOver}>
          {label && <Label isDropDisabled={isDropDisabled(label)}>{label}</Label>}
          <Group data-testid={`group_${listId}`}>
            {elements[0]?.estimatedFunds && <SelfDeliveryPositions elements={elements} />}
            <DropZone ref={provided.innerRef} data-testid={`dropzone_${listId}`}>
              <LotPositions
                elements={elements}
                isPending={isPending}
                columnName={columnName}
                isDragDisabled={
                  label === COUNTING_REQUIRED_SECTIONS.HOLD.name && isSupportUser(userType)
                }
              />
              {provided.placeholder}
            </DropZone>
          </Group>
        </DroppableWrapper>
      )}
    </Droppable>
  );
};

const DashboardColumn = ({ data, isPending, isDragging }) => (
  <Column>
    <Header>
      <Title>
        {data.name}
        {data.total !== undefined && ` (${data.total})`}
      </Title>
      {data.weight !== undefined && (
        <Subtitle>
          {POOL_ACCOUNTS.TOTAL}: {replaceEmpty(formatToUS(+data.weight))} {PROFIT_LOSS.LBS}
        </Subtitle>
      )}
      {data.processViaCount !== undefined && <Subtitle>{data.processViaCount}</Subtitle>}
    </Header>
    {data.list.map(({ label, elements, listId }, index) => (
      <Container
        key={`${data.name}_${index}`}
        columnName={data.name}
        label={label}
        elements={elements}
        name={data.name}
        listId={listId}
        isPending={isPending}
        isDragging={isDragging}
      />
    ))}
  </Column>
);

DashboardColumn.propTypes = {
  isDragging: PropTypes.bool,
  data: PropTypes.shape({
    name: PropTypes.string,
    total: PropTypes.number,
    weight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    processViaCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    list: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        elements: PropTypes.arrayOf(PropTypes.object),
      }),
    ),
  }).isRequired,
};

export { DashboardColumn };
