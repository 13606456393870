import {
  getMaximumSettlementOuncesActionType,
  getMaximumSettlementOuncesSuccessActionType,
  getMaximumSettlementOuncesFailureActionType,
  clearMaximumSettlementOuncesActionType,
  clearMaximumSettlementOuncesActionForLotType,
} from 'actions/PoolAccounts/getMaximumSettlementOunces';

const INITIAL_STATE = {
  isPending: false,
  errorMessage: null,
  settlementOunces: null,
};

const getMaximumSettlementOuncesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getMaximumSettlementOuncesActionType:
      return {
        isPending: true,
        errorMessage: null,
      };
    case getMaximumSettlementOuncesSuccessActionType:
      return {
        isPending: false,
        errorMessage: null,
        settlementOunces: {
          ...state.settlementOunces,
          [action.payload.settlementOunces.lot]: {
            maximumPlatinumOz: action.payload.settlementOunces.maximumPlatinumOz,
            maximumPalladiumOz: action.payload.settlementOunces.maximumPalladiumOz,
            maximumRhodiumOz: action.payload.settlementOunces.maximumRhodiumOz,
            wasFinalPaymentRequested: action.payload.settlementOunces.wasFinalPaymentRequested,
          },
        },
      };
    case getMaximumSettlementOuncesFailureActionType:
      return {
        isPending: false,
        errorMessage: action.payload.errorMessage,
        settlementOunces: null,
      };
    case clearMaximumSettlementOuncesActionForLotType:
      return {
        ...state,
        settlementOunces:
          state.settlementOunces &&
          Object.entries(state.settlementOunces)
            .filter(([name]) => name !== action.payload.lot)
            .reduce((acc, [name, value]) => ({ ...acc, [name]: value }), {}),
      };
    case clearMaximumSettlementOuncesActionType:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};

export { getMaximumSettlementOuncesReducer };
