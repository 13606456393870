import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const hedgesDecisionActionType = 'HEDGES_DECISION';
function hedgesDecisionAction() {
  return { type: hedgesDecisionActionType };
}

const hedgesDecisionSuccessActionType = `${hedgesDecisionActionType}_SUCCESS`;
function hedgesDecisionSuccessAction() {
  return { type: hedgesDecisionSuccessActionType };
}

const hedgesDecisionFailureActionType = `${hedgesDecisionActionType}_FAILURE`;
function hedgesDecisionFailureAction() {
  return { type: hedgesDecisionFailureActionType };
}

function hedgesDecision(hedge, status) {
  return dispatch => {
    dispatch(hedgesDecisionAction());

    return axios
      .patch(`${API_HOST_AUTH}/hedges/${hedge._id}/decision/${status}`)
      .then(({ data }) => {
        dispatch(hedgesDecisionSuccessAction());

        return data;
      })
      .catch(error => {
        dispatch(hedgesDecisionFailureAction());

        return Promise.reject(error);
      });
  };
}

export {
  hedgesDecision,
  hedgesDecisionActionType,
  hedgesDecisionSuccessActionType,
  hedgesDecisionFailureActionType,
};
