import PropTypes from 'prop-types';
import React, { useCallback, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { destroy, change, getFormValues } from 'redux-form';

import { downloadAttachment } from 'actions/Attachments/downloadAttachment';
import { getHedgesOverviewList } from 'actions/Hedges/hedgesOverviewList.js';
import { getCompanyLocations } from 'actions/Locations/getCompanyLocations';
import { clearAssignedLotsAction } from 'actions/Lots/getAssignedLots';
import { clearLotDetailsAction, getLot } from 'actions/Lots/lotDetails';
import { assignedViewPrintPackageLabels } from 'actions/Packages/printPackageLabels';
import { saveTemporaryData } from 'actions/Shipments/saveTemporaryData';
import { getShipment, clearShipmentDetailsAction } from 'actions/Shipments/shipmentDetails';
import { clearShipmentPackagesList } from 'actions/Shipments/shipmentPackagesList';
import { updateShipmentPackingList } from 'actions/Shipments/updateShipment';
import { openModal } from 'actions/shared/modal';
import { showSnackbar } from 'actions/shared/snackbar';

import { ContentLoader } from 'shared/components/ContentLoader/ContentLoader';
import { Preloader } from 'shared/components/Preloader/Preloader';
import { Wizard } from 'shared/components/Wizard/Wizard';
import { hedgeStatus } from 'shared/helpers/constants/hedges/hedgeStatus';
import { statusesForGlobal } from 'shared/helpers/constants/lots/statusesForGlobal';
import { cancelModal } from 'shared/helpers/constants/modalConstants';
import { materialTypesPackingList } from 'shared/helpers/constants/packages/materialType';
import { packageClasses } from 'shared/helpers/constants/packages/packageClasses';
import { PACKAGES } from 'shared/helpers/constants/packages/packageConstants';
import { SHIPMENTS } from 'shared/helpers/constants/shipments/shipmentsConstants';
import { dangerOptions } from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { parseLocationsSelectData } from 'shared/helpers/dataParsers/location/parseLocationsSelectData';
import { getPackagesToPrint } from 'shared/helpers/dataParsers/shipments';
import { isCustomerUser } from 'shared/helpers/matchers/checkUserType';
import { snackbarOptions } from 'shared/helpers/matchers/snackbar/snackbarOptions';
import { humanize } from 'shared/helpers/parsers/text';

import { ConnectedMultiPackagesForm } from 'pages/Packages/components/PackagesManagement/components/MultiPackagesCreate/MultiPackagesCreate';
import { IncomingPackageForm } from 'pages/Packages/components/PackagesManagement/components/MultiPackagesCreate/components/IncommingPackageForm/IncomingPackageForm';
import { configNames } from 'pages/Packages/components/PackagesManagement/components/PackageForm/components/PackageForm/fieldsConfig';
import { ConnectedCreateIncomingShipment } from 'pages/Shipments/components/GlobalShipmentsManagement/IncomingShipments/CreateIncomingShipment/ConnectedCreateIncomingShipment';
import { ConnectedCreateShipment } from 'pages/Shipments/components/ShipmentsManagement/CreateShipment/ConnectedCreateShipment';
import { GeneratePackingListForm } from 'pages/Shipments/components/ShipmentsManagement/CreateShipmentWizard/components/GeneratePackingListForm/GeneratePackingListForm';

const steps = userType => ({
  createShipment: isCustomerUser(userType)
    ? ConnectedCreateShipment
    : ConnectedCreateIncomingShipment,
  ...(!isCustomerUser(userType) && {
    defaultPackagesValues: IncomingPackageForm,
  }),
  packages: ConnectedMultiPackagesForm,
  generatePackingListValues: GeneratePackingListForm,
});

const EditShipmentWizard = ({
  match: {
    params: { id: paramsId },
  },
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [disabledReassignLot, setDisabledReassignLot] = useState(true);
  const [changedShipmentDestination, setChangedShipmentDestination] = useState(false);
  const selectedPackages = useSelector(({ shipmentPackagesListData }) => shipmentPackagesListData);
  const [isPending, setIsPending] = useState(false);
  const { shipment, isPending: isShipmentDetailsPending } = useSelector(
    state => state.shipmentDetails,
  );
  const wizardData = useSelector(state => getFormValues('wizard')(state));
  const { userType } = useSelector(
    ({
      auth: {
        user: { userType },
      },
    }) => ({
      userType,
    }),
  );

  const { data: companyLocations } = useSelector(state => state.companyLocations);

  const getCompanyDataForOwner = ({ _id: value, companyName: label }) => {
    dispatch(getCompanyLocations(value));
    dispatch(
      saveTemporaryData({
        sentFromCompany: {
          value,
          label,
        },
      }),
    );
  };

  const getCompanyPropName = userType =>
    isCustomerUser(userType) ? 'relatedCompany' : 'sentFromCompany';

  const fillPackingListForm = (
    {
      sensor,
      trailer,
      purchaseOrder,
      billOfLading,
      truckingCompany,
      shipmentSeal,
      estDeliveryDate,
      estPickupDate,
      sentFromCompany,
      sentFromCompanyLocation,
      packingListPackages,
      packingListAssignedLot,
      contactName,
      contactNumber,
    },
    userType,
  ) => {
    const sharedPkgData = {
      assignedLot: {
        value: packingListAssignedLot._id,
        label: packingListAssignedLot.grgLotNumber,
        customLotNumber: packingListAssignedLot.customLotNumber,
      },
      packageCountIncoming: packingListAssignedLot.packageCountIncoming,
      customLotNumber: packingListAssignedLot.customLotNumber,
    };

    dispatch(
      change('wizard', 'createShipment', {
        sensor,
        trailer,
        purchaseOrder,
        billOfLading,
        truckingCompany,
        shipmentSeal,
        estDeliveryDate,
        estPickupDate,
        [getCompanyPropName(userType)]: {
          value: sentFromCompany._id,
          label: sentFromCompany.companyName,
        },
        sentFromCompanyLocation: {
          value: sentFromCompanyLocation._id,
          label: sentFromCompanyLocation.name,
        },
        contactName: {
          value: contactName._id,
          label: `${contactName.firstName} ${contactName.lastName}`,
        },
        contactNumber,
      }),
    );
    dispatch(
      change('wizard', 'defaultPackagesValues', {
        oneOrMultiLots: {
          label: humanize(PACKAGES.LOTS_FOR_PACKAGES.SAME_LOT),
          value: PACKAGES.LOTS_FOR_PACKAGES.SAME_LOT,
        },

        packagesQuantity: packingListPackages.length,
        materialTypeDeclared: {
          label: humanize(materialTypesPackingList.catalytic_converters),
          value: materialTypesPackingList.catalytic_converters,
        },
        ...sharedPkgData,
      }),
    );
    dispatch(
      change('wizard', 'generatePackingListValues', {
        printPackingList: true,
        exportToExcel: false,
        printPackageLabels: false,
        markShipmentAsSent: false,
      }),
    );
    dispatch(
      change(
        'wizard',
        'packages',
        packingListPackages.map(pkg => ({
          _id: pkg._id,
          status: pkg.status,
          placeholder: pkg.placeholder,
          packageNumber: pkg.packageNumber,
          customLotNumber: pkg.customLotNumber,
          weightGrossDeclared: pkg.weightGrossDeclared,
          weightTareDeclared: pkg.weightTareDeclared,
          packageType: {
            value: pkg.packageType,
            label: humanize(pkg.packageType),
          },
          customerInstructions: pkg.customerInstructions || '',
          labelId: pkg.labelId || '',
          plant: pkg.plant || '',
          ...(pkg.unitCount && {
            unitCount: pkg.unitCount,
          }),
          materialTypeDeclared: {
            label: humanize(pkg.materialTypeDeclared),
            value: pkg.materialTypeDeclared,
          },
          ...sharedPkgData,
        })),
      ),
    );
  };

  useEffect(() => {
    dispatch(getShipment(paramsId)).catch(err => {
      history.push('/shipments');
      dispatch(showSnackbar(dangerOptions, err.message));
    });
    return () => dispatch(clearShipmentDetailsAction());
  }, []);

  useEffect(() => {
    if (shipment) {
      const availableStatuses = [
        statusesForGlobal.not_received,
        statusesForGlobal.partly_received,
        statusesForGlobal.received,
      ];
      fillPackingListForm(shipment, userType);

      dispatch(getLot(shipment.packingListAssignedLot._id)).then(assignedLot => {
        dispatch(getHedgesOverviewList(assignedLot._id, 0)).then(({ docs }) => {
          const hedgeHasStatusFilled = docs.some(hedge => hedge.status === hedgeStatus.filled);

          if (availableStatuses.includes(assignedLot.statusForGlobal) && !hedgeHasStatusFilled) {
            setDisabledReassignLot(false);
          }
        });
      });

      !isCustomerUser(userType) && getCompanyDataForOwner(shipment.sentFromCompany);
    }
  }, [shipment, dispatch]);

  useEffect(() => {
    change(
      'wizard.createShipment.sentFromCompanyLocation',
      parseLocationsSelectData(companyLocations),
    );
  }, [companyLocations]);

  const chosenPackages = useSelector(state => state.shipmentPackagesListData.packages);

  const onSubmit = useCallback(
    async values => {
      try {
        setIsPending(true);
        const result = await dispatch(
          updateShipmentPackingList(wizardData, userType, chosenPackages, paramsId),
        );

        if (
          values.generatePackingListValues.printPackageLabels &&
          selectedPackages.packages.length > 0
        ) {
          const packagesToPrint = getPackagesToPrint(
            result.shipmentPackages,
            selectedPackages.packages,
          );

          dispatch(
            assignedViewPrintPackageLabels(
              'shipments',
              packageClasses.incoming,
              result.id,
              result.packagesToPrint?.length > 0 ? result.packagesToPrint :  packagesToPrint,
            ),
          );
        }

        await Promise.all(
          result.files.map(async filePath => dispatch(downloadAttachment(filePath))),
        );

        if (result.id) {
          history.push(`/shipments/${result.id}`);
          resetWizardData();
        }

        setIsPending(false);

        dispatch(showSnackbar(snackbarOptions(result.messageType), result.message));
      } catch (err) {
        setIsPending(false);
        dispatch(showSnackbar(dangerOptions, err.message));
      }
    },
    [chosenPackages, wizardData],
  );

  const resetWizardData = () => {
    dispatch(saveTemporaryData({ sentFromCompany: null }));
    dispatch(destroy('wizard'));
    dispatch(clearAssignedLotsAction());
    dispatch(clearLotDetailsAction());
    dispatch(clearShipmentPackagesList());
  };

  const modalOpen = useCallback(() => {
    dispatch(
      openModal(cancelModal, () => {
        resetWizardData();
        history.push(`/shipments/${paramsId}`);
      }),
    );
  }, [openModal, destroy, history]);

  return (
    <ContentLoader loaded={!isShipmentDetailsPending}>
      <Wizard
        steps={steps(userType)}
        header={SHIPMENTS.EDIT_PACKING_LIST}
        onSubmit={onSubmit}
        onCancel={modalOpen}
        contextProps={{
          shipmentPackagesLength: shipment?.packingListPackages?.length,
          config: configNames.packingList,
          assignedShipment: shipment,
          changedShipmentDestination,
          setChangedShipmentDestination,
          disabledReassignLot,
          userType: userType,
          editMode: true,
          ...(!isCustomerUser(userType) && {
            companyLocations: parseLocationsSelectData(companyLocations),
          }),
        }}
      />
      <Preloader loading={isPending} />
    </ContentLoader>
  );
};

EditShipmentWizard.propTypes = {
  match: PropTypes.object.isRequired,
};

export { EditShipmentWizard };
