import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { enterCustomPrices as enterCustomPricesAction } from 'actions/Hedges/enterCustomPrices';
import { clearHedgeDetailsAction, getHedgeDetails } from 'actions/Hedges/hedgesDetails';
import { showSnackbar } from 'actions/shared/snackbar';

import { FormContainer } from 'shared/components/Form';
import { Preloader } from 'shared/components/Preloader/Preloader';
import { HEDGES } from 'shared/helpers/constants/hedges/hedgesConstants';
import {
  dangerOptions,
  successOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { safeParseFloat } from 'shared/helpers/parsers/number';

import { EnterPricesForm, priceType } from './EnterPricesForm/EnterPricesForm';

const EnterCustomPricesBase = ({ match, history, superAdminMode }) => {
  const { isPending } = useSelector(({ enterCustomPrices }) => enterCustomPrices);
  const hedgeDetails = useSelector(({ hedgesDetails }) => hedgesDetails);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getHedgeDetails(match.params.id)).catch(() => history.push('/hedges'));

    return () => dispatch(clearHedgeDetailsAction());
  }, [dispatch, history, match]);

  const allowPriceValue = price => superAdminMode || safeParseFloat(price);

  const onSubmit = ({ platinumPriceCust, palladiumPriceCust, rhodiumPriceCust }) => {
    const hedgeId = match.params.id;

    const parsedValues = {
      ...(allowPriceValue(platinumPriceCust) && {
        platinumPriceCust: safeParseFloat(platinumPriceCust),
      }),
      ...(allowPriceValue(palladiumPriceCust) && {
        palladiumPriceCust: safeParseFloat(palladiumPriceCust),
      }),
      ...(allowPriceValue(rhodiumPriceCust) && {
        rhodiumPriceCust: safeParseFloat(rhodiumPriceCust),
      }),
      superAdminMode,
    };

    return dispatch(enterCustomPricesAction(hedgeId, parsedValues))
      .then(res => {
        dispatch(showSnackbar(successOptions, res.message));
        navigateBack();
      })
      .catch(res => dispatch(showSnackbar(dangerOptions, res.message)));
  };

  const navigateBack = () => {
    history.push(`/hedges/${match.params.id}`);
  };

  return (
    <>
      <FormContainer
        header={
          superAdminMode
            ? HEDGES.CHANGE_CUSTOM_HEDGE_PRICES(hedgeDetails?.hedge?.hedgeName)
            : HEDGES.ENTER_CUSTOM_HEDGE_PRICES(hedgeDetails?.hedge?.hedgeName)
        }
        loaded={hedgeDetails.hedge && !hedgeDetails.isPending}
      >
        {() => (
          <EnterPricesForm type={priceType.custom} hedge={hedgeDetails.hedge} onSubmit={onSubmit} />
        )}
      </FormContainer>
      <Preloader loading={isPending} />
    </>
  );
};

const EnterCustomPrices = withRouter(EnterCustomPricesBase);

EnterCustomPricesBase.propTypes = {
  match: PropTypes.shape({
    isExact: PropTypes.bool,
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
    path: PropTypes.string,
    url: PropTypes.string,
  }),
  superAdminMode: PropTypes.bool,
  history: PropTypes.object,
};

EnterCustomPricesBase.defaultProps = {
  superAdminMode: false,
};

export { EnterCustomPrices, EnterCustomPricesBase };
