import { isFactory } from './matcherFactory';

const isOwnerUser = isFactory('owner');
const isCustomerUser = isFactory('customer');
const isSupportUser = isFactory('support');
const isGradingUser = isFactory('grading');
const isWarehouseUser = isFactory('warehouse');
const isExternalUser = isFactory('external');
const isOwnerOrSupport = isFactory('owner', 'support');
const isOwnerOrSupportOrBuyer = isFactory('owner', 'support', 'buyer');
const isBuyerUser = isFactory('buyer');

const isCustomerOrGrading = value => isCustomerUser(value) || isGradingUser(value);

export {
  isOwnerUser,
  isCustomerUser,
  isSupportUser,
  isGradingUser,
  isWarehouseUser,
  isCustomerOrGrading,
  isExternalUser,
  isOwnerOrSupport,
  isOwnerOrSupportOrBuyer,
  isBuyerUser,
};
