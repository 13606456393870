import React from 'react';

import { Highlight } from '../Highlight/Highlight';

function IsEmpty(props) {
  return (
    <span>
      {props.value ? (
        <Highlight searchFilter={props.searchWords || ''} textStr={String(props.value) || ''} />
      ) : (
        '-'
      )}
    </span>
  );
}

export { IsEmpty };
