import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import { markAsResolved } from 'actions/Notifications/markAsResolved';
import { clearNotificationDetails } from 'actions/Notifications/notificationDetails';

import { notificationActionTypes } from 'shared/helpers/constants/notifications/notificationActionTypes';
import { NOTIFICATION_STATUSES } from 'shared/helpers/constants/notifications/notificationsStatuses';
import { isCustomerOrGrading } from 'shared/helpers/matchers/checkUserType';

import { goBackOrTo } from 'utils/history';

const NotificationActionsPortalBase = ({
  auth,
  children,
  actions,
  noRedirect,
  notificationDetails: { notification },
  markAsResolvedAction,
  clearNotificationDetailsAction,
  showOnlyInNotification,
}) => {
  const node = document.getElementById('notificationActionsPortal');
  const resolveAction = () => {
    if (
      !isCustomerOrGrading(auth.user.userType) &&
      notification &&
      notification.status !== NOTIFICATION_STATUSES.RESOLVED &&
      !showOnlyInNotification
    ) {
      markAsResolvedAction(notification._id);

      if (notification.actionType === notificationActionTypes.paymentRequest) return;

      !noRedirect && goBackOrTo('/');
    }
    clearNotificationDetailsAction();
  };

  const overriddenChild =
    children && children.props.onClick
      ? {
          ...children,
          props: {
            ...children.props,
            onClick: () => {
              children.props.onClick();
              resolveAction();
            },
          },
        }
      : children;

  const themedButton = <ThemeProvider theme={() => ({})}>{overriddenChild}</ThemeProvider>;

  return (
    <>
      {node &&
        notification &&
        actions.includes(notification.actionType) &&
        ReactDOM.createPortal(themedButton, node)}
      {!showOnlyInNotification && overriddenChild}
    </>
  );
};

const mapStateToProps = ({ auth, notificationDetails }) => ({
  auth,
  notificationDetails,
});
const mapDispatchToProps = {
  markAsResolvedAction: markAsResolved,
  clearNotificationDetailsAction: clearNotificationDetails,
};

const NotificationActionsPortal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotificationActionsPortalBase);

export { NotificationActionsPortal };
