import store from 'store';

import { getStaleTime } from 'utils/getStaleTime';
import { history } from 'utils/history';

let intervalHandle = 0;

const setLogoutTimeout = () => {
  const { auth } = store.getState();
  const staleTime = getStaleTime(auth?.user) + Date.now();

  clearInterval(intervalHandle);

  if (auth && auth.user) {
    intervalHandle = setInterval(() => {
      staleTime < Date.now() && history.push('/logout');
    }, 1000);
  }
};

export { setLogoutTimeout };
