import React, { useCallback, useContext } from 'react';

import { ConditionalWrapper } from 'shared/components/ConditionalWrapper/ConditionalWrapper';
import { FormContainer } from 'shared/components/Form/FormContainer/FormContainer';
import { wizardContext } from 'shared/components/Wizard/Wizard';
import { SHIPMENTS } from 'shared/helpers/constants/shipments/shipmentsConstants';
import { snackbarOptions } from 'shared/helpers/matchers/snackbar/snackbarOptions';

import { configNames } from 'pages/Packages/components/PackagesManagement/components/PackageForm/components/PackageForm/fieldsConfig';

import { ShipmentForm } from '../ShipmentForm/ShipmentForm';

const CreateShipment = props => {
  const { createIncomingShipment, shipmentPackagesListData, showSnackbar, history, location } =
    props;

  const wizard = useContext(wizardContext);
  const isPackingList = wizard?.config === configNames.packingList;

  const resolveAction = useCallback(
    result => {
      showSnackbar(snackbarOptions(result.messageType), result.message);
      if (result.id) {
        history.push(`/shipments/${result.id}`);
      }
    },
    [history, showSnackbar],
  );

  const submit = useCallback(
    values => {
      const createObj = {
        ...values,
        packages: shipmentPackagesListData.packages,
      };

      return props.createShipment(createObj).then(resolveAction);
    },
    [shipmentPackagesListData, createIncomingShipment, resolveAction],
  );

  const initialValues = {
    relatedCompany: {
      label: props.auth.user.relatedCompany.companyName,
      value: props.auth.user.relatedCompany._id,
      packages: [
        ...((location.state && location.state.packages) || []),
        ...((shipmentPackagesListData && shipmentPackagesListData.packages) || []),
      ],
    },
  };

  return (
    <ConditionalWrapper
      condition={!isPackingList}
      wrapper={children => (
        <FormContainer header={SHIPMENTS.CREATE_A_SHIPMENT}>{children}</FormContainer>
      )}
    >
      <ShipmentForm
        {...props}
        sentFromCompany={props.auth.user.relatedCompany}
        wizard={wizard}
        isPackingList={isPackingList}
        {...(!isPackingList && {
          onSubmit: submit,
        })}
        initialValues={{
          ...(!wizard?.editMode && {
            ...initialValues,
            ...props.initialValues,
            ...(isPackingList && {
              createShipment: { relatedCompany: initialValues.relatedCompany },
            }),
          }),
        }}
      />
    </ConditionalWrapper>
  );
};
export { CreateShipment };
