import React, { Component } from 'react';

import { FormContainer } from 'shared/components/Form/FormContainer/FormContainer';
import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';
import { generateProfitLossModal } from 'shared/helpers/constants/modalConstants';
import { successOptions, infoOptions } from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import {
  parseMultipleFiles,
  parseExistingFile,
} from 'shared/helpers/dataParsers/files/parseMultipleFiles';
import { parseLotUpdateFiles } from 'shared/helpers/dataParsers/lot/parseLotUpdateFiles';

import { history } from 'utils/history';

import GlobalLotForm from 'pages/Lots/components/LotsManagement/LotForm/GlobalLotForm';

import { InactiveSensorPackages } from './components/InactiveSensorPackages/InactiveSensorPackages';

class UpdateLot extends Component {
  state = {
    billOfLadingError: false,
    poolSettlementDocumentError: false,
    displayInactiveSensorPackagesModal: false,
  };

  addMultipleDocuments(files) {
    Object.entries(files).forEach(
      ([name, file]) =>
        file?.length &&
        this.props.lotsAddMultipleDocuments({
          name,
          files: file.map(file => ({
            existingFile: parseExistingFile(file),
          })),
        }),
    );
  }

  componentDidMount() {
    this.props
      .getLot(this.props.match.params.id)
      .then(({ poolSettlementDocument, billOfLading }) =>
        this.addMultipleDocuments({ poolSettlementDocument, billOfLading }),
      )
      .catch(() => history.push('/lots'));
  }

  componentWillUnmount() {
    this.props.lotsClearAllFiles();
  }

  get canInactiveIncomingSensorPackages() {
    const { files } = this.props.lotsFiles;
    const { canInactiveIncomingSensorPackages } = this.props.lotDetails?.lot;

    return !!(canInactiveIncomingSensorPackages && files?.countSheet);
  }

  checkMultipleFilesError = ({ type, hasError }) => this.setState({ [`${type}Error`]: hasError });

  redirectToLotDetails = id => history.push(`/lots/list/${id}`);

  handleToggleInactiveSensorPackagesModal = () => {
    this.setState(({ displayInactiveSensorPackagesModal }) => ({
      displayInactiveSensorPackagesModal: !displayInactiveSensorPackagesModal,
    }));
  };

  submit = values =>
    this.props
      .updateLot(values, parseLotUpdateFiles(this.props.lotsFiles), {
        id: this.props.lotDetails.lot._id,
        statusForGlobal: this.props.lotDetails.lot.statusForGlobal,
        existingLotFiles: parseMultipleFiles(
          this.props.lotsFiles.multipleFiles,
          file => file.existingFile._id,
        ),
      })
      .then(({ message, lot }) => {
        if (lot.generateProfitLossDocumentAllowed) {
          if (this.canInactiveIncomingSensorPackages) {
            this.handleToggleInactiveSensorPackagesModal();
          }

          return this.props.openModal(generateProfitLossModal).then(res => {
            this.props.showSnackbar(successOptions, message);

            if (res) {
              return history.push(`/lots/list/${lot.id}/generate/profit-loss`);
            }
            this.redirectToLotDetails(lot.id);
          });
        }

        if (this.canInactiveIncomingSensorPackages) {
          this.handleToggleInactiveSensorPackagesModal();

          return this.props.showSnackbar(successOptions, message);
        }

        this.props.showSnackbar(successOptions, message);
        this.redirectToLotDetails(lot.id);
      })
      .catch(res => this.props.showSnackbar(infoOptions, res.message));

  render() {
    const {
      parseAttachmentStorage: { isPending: isPendingParseAttachment },
      lotUpdate: { isPending },
    } = this.props;
    const { billOfLadingError, poolSettlementDocumentError, displayInactiveSensorPackagesModal } =
      this.state;

    const { lot } = this.props.lotDetails;

    const header = `${LOTS.EDIT_LOT} ${lot && !isPendingParseAttachment ? lot.grgLotNumber : ''}`;

    return (
      <>
        <FormContainer loaded={!isPending && !isPendingParseAttachment && lot} header={header}>
          <GlobalLotForm
            {...this.props}
            editMode
            onSubmit={this.submit}
            blockSubmit={billOfLadingError || poolSettlementDocumentError}
            checkMultipleFilesError={this.checkMultipleFilesError}
          />
        </FormContainer>
        <InactiveSensorPackages
          isOpen={displayInactiveSensorPackagesModal}
          incomingSensorPackages={lot?.incomingSensorPackagesIdsToInactive}
          toggle={this.handleToggleInactiveSensorPackagesModal}
          onFinish={() => this.redirectToLotDetails(lot._id)}
        />
      </>
    );
  }
}

export { UpdateLot };
