import { FILES_CONSTANTS } from 'shared/helpers/constants/filesConstants';
import { GENERATE_FINAL_INVOICE } from 'shared/helpers/constants/lots/generateFinalInvoiceConstants';
import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';
import { safeParseFloat } from 'shared/helpers/parsers/number';
import { replaceEmpty, removeCommasAndParse } from 'shared/helpers/parsers/text';
import { commaValidator } from 'shared/helpers/validations/commaValidator';
import {
  PDF_REGEX,
  THREE_DECIMAL_REGEX,
  VALIDATE_UTF_8,
  PRICE_REGEX,
} from 'shared/helpers/validations/regularExpressions';

function validate(values, props) {
  const {
    finalInvoiceMaximumOunces: { data },
    finalInvoiceFile,
  } = props;
  const errors = {};

  if (!values.invoiceGeneratedAt) {
    errors.invoiceGeneratedAt = GENERATE_FINAL_INVOICE.FINAL_PAYMENT_DATE_EMPTY;
  }

  if (!replaceEmpty(values.finalPlatinumPrice, '').length) {
    errors.finalPlatinumPrice = GENERATE_FINAL_INVOICE.FINAL_PLATINUM_PRICE_EMPTY;
  } else if (!PRICE_REGEX.test(values.finalPlatinumPrice)) {
    errors.finalPlatinumPrice = GENERATE_FINAL_INVOICE.FINAL_PLATINUM_PRICE_INVALID;
  } else if (values.finalPlatinumPrice && values.finalPlatinumPrice.length > 10) {
    errors.finalPlatinumPrice = GENERATE_FINAL_INVOICE.FINAL_PLATINUM_PRICE_INVALID;
  } else if (safeParseFloat(values.finalPlatinumPrice) <= 0) {
    errors.finalPlatinumPrice = GENERATE_FINAL_INVOICE.FINAL_PLATINUM_PRICE_NEGATIVE;
  } else if (safeParseFloat(values.finalPlatinumPrice) === 0) {
    errors.finalPlatinumPrice = GENERATE_FINAL_INVOICE.FINAL_PLATINUM_PRICE_ZERO;
  }

  if (!replaceEmpty(values.finalPalladiumPrice, '').length) {
    errors.finalPalladiumPrice = GENERATE_FINAL_INVOICE.FINAL_PALLADIUM_PRICE_EMPTY;
  } else if (!PRICE_REGEX.test(values.finalPalladiumPrice)) {
    errors.finalPalladiumPrice = GENERATE_FINAL_INVOICE.FINAL_PALLADIUM_PRICE_INVALID;
  } else if (values.finalPalladiumPrice && values.finalPalladiumPrice.length > 10) {
    errors.finalPalladiumPrice = GENERATE_FINAL_INVOICE.FINAL_PALLADIUM_PRICE_INVALID;
  } else if (safeParseFloat(values.finalPalladiumPrice) <= 0) {
    errors.finalPalladiumPrice = GENERATE_FINAL_INVOICE.FINAL_PALLADIUM_PRICE_NEGATIVE;
  }

  if (!replaceEmpty(values.finalRhodiumPrice, '').length) {
    errors.finalRhodiumPrice = GENERATE_FINAL_INVOICE.FINAL_RHODIUM_PRICE_EMPTY;
  } else if (!PRICE_REGEX.test(values.finalRhodiumPrice)) {
    errors.finalRhodiumPrice = GENERATE_FINAL_INVOICE.FINAL_RHODIUM_PRICE_INVALID;
  } else if (values.finalRhodiumPrice && values.finalRhodiumPrice.length > 10) {
    errors.finalRhodiumPrice = GENERATE_FINAL_INVOICE.FINAL_RHODIUM_PRICE_INVALID;
  } else if (safeParseFloat(values.finalRhodiumPrice) <= 0) {
    errors.finalRhodiumPrice = GENERATE_FINAL_INVOICE.FINAL_RHODIUM_PRICE_NEGATIVE;
  }

  if (!replaceEmpty(values.platinumOuncesRemoved, '').length) {
    errors.platinumOuncesRemoved = GENERATE_FINAL_INVOICE.PLATINUM_OUNCES_REMOVED_EMPTY;
  } else if (
    values.platinumOuncesRemoved &&
    !THREE_DECIMAL_REGEX.test(values.platinumOuncesRemoved)
  ) {
    errors.platinumOuncesRemoved = GENERATE_FINAL_INVOICE.PLATINUM_OUNCES_REMOVED_INVALID;
  } else if (values.platinumOuncesRemoved && values.platinumOuncesRemoved.length > 10) {
    errors.platinumOuncesRemoved = GENERATE_FINAL_INVOICE.PLATINUM_OUNCES_REMOVED_INVALID;
  } else if (safeParseFloat(values.platinumOuncesRemoved) < 0 && data.maximumPlatinumAccount >= 0) {
    errors.platinumOuncesRemoved = GENERATE_FINAL_INVOICE.PLATINUM_OUNCES_REMOVED_NEGATIVE;
  } else if (
    safeParseFloat(values.platinumOuncesRemoved) > data.maximumPlatinumAccount &&
    data.maximumPlatinumAccount >= 0
  ) {
    errors.platinumOuncesRemoved = GENERATE_FINAL_INVOICE.PLATINUM_OUNCES_REMOVED_TOO_HIGH;
  }

  if (!replaceEmpty(values.palladiumOuncesRemoved, '').length) {
    errors.palladiumOuncesRemoved = GENERATE_FINAL_INVOICE.PALLADIUM_OUNCES_REMOVED_EMPTY;
  } else if (
    values.palladiumOuncesRemoved &&
    !THREE_DECIMAL_REGEX.test(values.palladiumOuncesRemoved)
  ) {
    errors.palladiumOuncesRemoved = GENERATE_FINAL_INVOICE.PALLADIUM_OUNCES_REMOVED_INVALID;
  } else if (values.palladiumOuncesRemoved && values.palladiumOuncesRemoved.length > 10) {
    errors.palladiumOuncesRemoved = GENERATE_FINAL_INVOICE.PALLADIUM_OUNCES_REMOVED_INVALID;
  } else if (
    safeParseFloat(values.palladiumOuncesRemoved) < 0 &&
    data.maximumPalladiumAccount >= 0
  ) {
    errors.palladiumOuncesRemoved = GENERATE_FINAL_INVOICE.PALLADIUM_OUNCES_REMOVED_NEGATIVE;
  } else if (
    safeParseFloat(values.palladiumOuncesRemoved) > data.maximumPalladiumAccount &&
    data.maximumPalladiumAccount >= 0
  ) {
    errors.palladiumOuncesRemoved = GENERATE_FINAL_INVOICE.PALLADIUM_OUNCES_REMOVED_TOO_HIGH;
  }

  if (!replaceEmpty(values.rhodiumOuncesRemoved, '').length) {
    errors.rhodiumOuncesRemoved = GENERATE_FINAL_INVOICE.RHODIUM_OUNCES_REMOVED_EMPTY;
  } else if (
    values.rhodiumOuncesRemoved &&
    !THREE_DECIMAL_REGEX.test(values.rhodiumOuncesRemoved)
  ) {
    errors.rhodiumOuncesRemoved = GENERATE_FINAL_INVOICE.RHODIUM_OUNCES_REMOVED_INVALID;
  } else if (values.rhodiumOuncesRemoved && values.rhodiumOuncesRemoved.length > 10) {
    errors.rhodiumOuncesRemoved = GENERATE_FINAL_INVOICE.RHODIUM_OUNCES_REMOVED_INVALID;
  } else if (safeParseFloat(values.rhodiumOuncesRemoved) < 0 && data.maximumRhodiumAccount >= 0) {
    errors.rhodiumOuncesRemoved = GENERATE_FINAL_INVOICE.RHODIUM_OUNCES_REMOVED_NEGATIVE;
  } else if (
    safeParseFloat(values.rhodiumOuncesRemoved) > data.maximumRhodiumAccount &&
    data.maximumRhodiumAccount >= 0
  ) {
    errors.rhodiumOuncesRemoved = GENERATE_FINAL_INVOICE.RHODIUM_OUNCES_REMOVED_TOO_HIGH;
  }

  if (!replaceEmpty(values.outstandingBalanceApplied, '').length) {
    errors.outstandingBalanceApplied = GENERATE_FINAL_INVOICE.OUTSTANDING_BALANCE_APPLIED_EMPTY;
  } else if (
    values.outstandingBalanceApplied &&
    !PRICE_REGEX.test(values.outstandingBalanceApplied)
  ) {
    errors.outstandingBalanceApplied = GENERATE_FINAL_INVOICE.OUTSTANDING_BALANCE_APPLIED_INVALID;
  } else if (values.outstandingBalanceApplied && values.outstandingBalanceApplied.length > 15) {
    errors.outstandingBalanceApplied = GENERATE_FINAL_INVOICE.OUTSTANDING_BALANCE_APPLIED_INVALID;
  } else if (removeCommasAndParse(values.outstandingBalanceApplied) < 0) {
    errors.outstandingBalanceApplied = GENERATE_FINAL_INVOICE.OUTSTANDING_BALANCE_APPLIED_NEGATIVE;
  } else if (
    removeCommasAndParse(values.outstandingBalanceApplied) > Math.abs(data.balanceRemaining)
  ) {
    errors.outstandingBalanceApplied = GENERATE_FINAL_INVOICE.OUTSTANDING_BALANCE_APPLIED_TOO_HIGH;
  }

  if (!replaceEmpty(values.totalFinalPayment, '').length) {
    errors.totalFinalPayment = GENERATE_FINAL_INVOICE.TOTAL_FINAL_PAYMENT_EMPTY;
  } else if (values.totalFinalPayment && !PRICE_REGEX.test(values.totalFinalPayment)) {
    errors.totalFinalPayment = GENERATE_FINAL_INVOICE.TOTAL_FINAL_PAYMENT_INVALID;
  } else if (values.totalFinalPayment && values.totalFinalPayment.length > 15) {
    errors.totalFinalPayment = GENERATE_FINAL_INVOICE.TOTAL_FINAL_PAYMENT_INVALID;
  }

  if (finalInvoiceFile) {
    const { grgFinalInvoice } = finalInvoiceFile;

    if (!grgFinalInvoice) {
      errors.grgFinalInvoice = LOTS.ERRORS.INVALID_FILE;
    }

    if (grgFinalInvoice && grgFinalInvoice.size > FILES_CONSTANTS.MAX_FILE_SIZE) {
      errors.grgFinalInvoice = LOTS.ERRORS.INVALID_SIZE;
    }

    if (grgFinalInvoice && !PDF_REGEX.test(grgFinalInvoice.name)) {
      errors.grgFinalInvoice = LOTS.ERRORS.INVALID_FILE_FORMAT;
    }

    if (grgFinalInvoice && !VALIDATE_UTF_8().test(grgFinalInvoice.name)) {
      errors.grgFinalInvoice = LOTS.ERRORS.INVALID_FILE_NAME;
    }
  }

  commaValidator(
    {
      finalPlatinumPrice: values.finalPlatinumPrice,
      finalPalladiumPrice: values.finalPalladiumPrice,
      finalRhodiumPrice: values.finalRhodiumPrice,
      outstandingBalanceApplied: values.outstandingBalanceApplied,
      platinumOuncesRemoved: values.platinumOuncesRemoved,
      palladiumOuncesRemoved: values.palladiumOuncesRemoved,
      rhodiumOuncesRemoved: values.rhodiumOuncesRemoved,
      totalFinalPayment: values.totalFinalPayment,
    },
    errors,
  );

  return errors;
}

export { validate };
