const saveTemporaryDataActionType = 'SAVE_TEMPORARY_DATA';
const saveTemporaryDataAction = () => ({
  type: saveTemporaryDataActionType,
});

const clearTemporaryDataSuccessActionType = 'CLEAR_TEMPORARY_DATA_SUCCESS';
const clearTemporaryDataSuccessAction = temporaryKey => ({
  type: clearTemporaryDataSuccessActionType,
  payload: temporaryKey,
});

const saveTemporaryDataSuccessActionType = `${saveTemporaryDataActionType}_SUCCESS`;
const saveTemporaryDataSuccessAction = temporaryData => ({
  type: saveTemporaryDataSuccessActionType,
  payload: temporaryData,
});

const saveTemporaryData = temporaryData => dispatch => {
  dispatch(saveTemporaryDataAction());
  dispatch(saveTemporaryDataSuccessAction(temporaryData));
};

const clearTemporaryData = temporaryKey => clearTemporaryDataSuccessAction(temporaryKey);

export {
  clearTemporaryDataSuccessActionType,
  saveTemporaryDataActionType,
  saveTemporaryDataAction,
  saveTemporaryDataSuccessActionType,
  saveTemporaryDataSuccessAction,
  saveTemporaryData,
  clearTemporaryData,
};
