import PropTypes from 'prop-types';
import { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, formValueSelector } from 'redux-form';

import { getCompaniesContactFailureAction } from 'actions/Locations/getCompaniesContact';

import { FieldSelect } from 'shared/components/Fields/index';
import { LOCATION } from 'shared/helpers/constants/locations/locationConstants';

function SelectPrimaryContact({
  relatedCompany,
  fieldRequired,
  getCompaniesContact,
  customClearCompaniesContact,
  name,
  label,
  onChange,
  multi,
}) {
  const dispatch = useDispatch();
  const selector = formValueSelector('LocationForm');
  const companiesContact = useSelector(state => state.companiesContact.data);
  const shipmentEligible = useSelector(state => selector(state, 'shipmentEligible'));
  const [reqPrimaryContact, setReqPrimaryContact] = useState('');

  const clearCompaniesContactAction = useCallback(
    () => dispatch(getCompaniesContactFailureAction()),
    [],
  );

  useEffect(() => {
    setReqPrimaryContact(shipmentEligible || fieldRequired ? 'required' : '');
  }, [shipmentEligible]);

  useEffect(() => {
    relatedCompany?._id &&
      getCompaniesContact(
        {
          value: relatedCompany._id,
          name: relatedCompany.companyName,
        },
        true,
      );

    return () =>
      customClearCompaniesContact ? customClearCompaniesContact() : clearCompaniesContactAction();
  }, [relatedCompany, getCompaniesContact]);

  const primaryContacts = (companiesContact || []).map(contact => ({
    value: contact._id,
    label: `${contact.firstName} ${contact.lastName}`,
    mobilePhone: contact.mobilePhone,
  }));

  return (
    relatedCompany && (
      <Field
        name={name}
        component={FieldSelect}
        options={primaryContacts}
        searchable={false}
        label={label}
        field={reqPrimaryContact}
        multi={multi}
        onChange={onChange}
        customFieldWidth={235}
        wrap
      />
    )
  );
}

SelectPrimaryContact.propTypes = {
  fieldRequired: PropTypes.bool,
  multi: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  relatedCompany: PropTypes.shape({
    _id: PropTypes.string,
    companyName: PropTypes.string,
  }),
  onChange: PropTypes.func,
  customClearCompaniesContact: PropTypes.func,
  getCompaniesContact: PropTypes.func,
};

SelectPrimaryContact.defaultProps = {
  multi: true,
  label: LOCATION.PRIMARY_CONTACT,
  name: 'primaryContact',
};

export default SelectPrimaryContact;
