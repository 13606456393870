import axios from 'axios';
import { string, object, date } from 'yup';

import { message } from 'shared/helpers/constants/payments/createBankHolidaysConstants';
import { dateToTimestamp } from 'shared/helpers/parsers/date';

const API_HOST_AUTH = process.env.REACT_APP_API;

let previousValue;
let holidayStatus;

export const BankHolidaysSchema = object().shape({
  holidayName: string()
    .max(50, message.INVALID_HOLIDAY_NAME_FORMAT)
    .trim(message.INVALID_HOLIDAY_NAME_EMPTY)
    .required(message.INVALID_HOLIDAY_NAME_EMPTY),
  date: date()
    .required(message.INVALID_DATE_EMPTY)
    .test('dateUnique', message.INVALID_DATE_DUPLICATE, value => {
      if (value) {
        if (previousValue !== value) {
          previousValue = value;
          holidayStatus = axios
            .get(`${API_HOST_AUTH}/bank-holidays/date-exists/${dateToTimestamp(value)}`)
            .then(res => !res.data.dateExists);
        }
        return holidayStatus;
      }
    }),
});
