import { packageType } from 'shared/helpers/constants/packages/packageType';
import { safeReplace } from 'shared/helpers/parsers/text';

function parsePackageData(pkg, samplePackagesData) {
  const data = {
    assignedLot: pkg.assignedLot,
    packageType: pkg.packageType,
    materialTypeDeclared: pkg.materialTypeDeclared,
    customerInstructions: pkg.customerInstructions,
    packageNumber: pkg.packageNumber,
    _id: pkg.id,
  };

  if (['other', 'foil_canned'].includes(pkg.materialTypeDeclared)) {
    data.denominatedIn = pkg.denominatedIn;
  }

  if (pkg.unitCount) {
    data.unitCount = parseInt(pkg.unitCount, 10);
  }

  if (pkg.weightGrossDeclared) {
    data.weightGrossDeclared = parseFloat(safeReplace(pkg.weightGrossDeclared, ',', '.'));
  }

  if (pkg.weightTareDeclared) {
    data.weightTareDeclared = parseFloat(safeReplace(pkg.weightTareDeclared, ',', '.'));
  }

  if (pkg.weightGrossActual) {
    data.weightGrossActual = parseFloat(safeReplace(pkg.weightGrossActual, ',', '.'));
  }

  if (pkg.weightTareActual) {
    data.weightTareActual = parseFloat(safeReplace(pkg.weightTareActual, ',', '.'));
  }

  if (pkg.materialClass) {
    data.materialClass = pkg.materialClass;
  }

  if (pkg.packageClass) {
    return {
      weightGrossActual: parseFloat(safeReplace(pkg.weightGrossActual, ',', '.')),
      weightTareActual: parseFloat(safeReplace(pkg.weightTareActual, ',', '.')),
      packageClass: pkg.packageClass,
      superAdminMode: pkg.superAdminMode,
    };
  }

  if (pkg.packageType === packageType.sample) {
    if (samplePackagesData) {
      data.assignedCats = pkg.assignedCats || [];
      data.contactPerson = samplePackagesData.contactPerson;
    }
  }

  if (pkg.materialCountRequired) {
    data.labelId = (pkg.labelId || '').toUpperCase();
    data.plant = (pkg.plant || '').toUpperCase();
  }

  return data;
}

export { parsePackageData };
