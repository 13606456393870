export const parseExistingCompanyFile = (files = {}) => {
  const parsedExistingCompanyFile = {};

  Object.entries(files).map(([key, values]) =>
    Object.assign(parsedExistingCompanyFile, {
      [key]: values.map((file, index) => ({
        _id: file._id,
        fileExpiresAt: file.fileExpiresAt,
        name: file.relatedFile.originalName,
        type: file.relatedFile.mimeType || file.relatedFile.type,
        size: file.relatedFile.size,
        keyName: `${file.relatedFile.originalName || file.name}-${new Date().valueOf()}-${index}`,
        relatedFile: file.relatedFile,
        createdBy: file.createdBy,
        createdAt: file.createdAt,
      })),
    }),
  );

  return parsedExistingCompanyFile;
};
