import React from 'react';

import { StatusIndicator } from 'shared/components/StatusIndicator/StatusIndicator';
import { formatDate } from 'shared/helpers/parsers/formaters';
import { replaceEmpty } from 'shared/helpers/parsers/text';

import { AGREEMENTS } from './agreementsConstants';

const itemsTemplate = [
  {
    label: AGREEMENTS.STATUS,
    render: ({ status }) => <StatusIndicator status={status} />,
  },
  {
    label: AGREEMENTS.START_DATE,
    render: ({ startDate }) => formatDate(startDate),
  },
  {
    label: AGREEMENTS.ACTUAL_EXPIRATION_DATE,
    render: ({ actualExpirationDate }) => replaceEmpty(formatDate(actualExpirationDate)),
  },
];

const itemsTemplateMobile = [
  {
    label: AGREEMENTS.STATUS,
    render: ({ status }) => <StatusIndicator status={status} />,
  },
  {
    label: AGREEMENTS.START_DATE,
    render: ({ startDate }) => formatDate(startDate),
  },
];

const actionsTemplate = (history, company) => {
  const actionTemplate = [
    {
      icon: 'arrow-right',
      action: 'Details',
      access: agreement => agreement,
      onClick: agreement => () => {
        history.push(`/companies/${company._id}/agreements/${agreement._id}`);
      },
    },
  ];

  actionsTemplate.access = function access() {
    return this.props.auth.access.companies.details.agreements.details;
  };

  return actionTemplate;
};

const actionsTemplateGrading = downloadApiAttachment => {
  const actionTemplateGrading = [
    {
      icon: 'clip',
      action: 'Download',
      access: agreement => !!(agreement.gradingDocument || agreement.docuSignDocument),
      onClick: agreement => () => downloadApiAttachment(`agreements/${agreement._id}/document`),
    },
  ];

  actionsTemplate.access = function access() {
    return this.props.agreementsList.agreements.length;
  };

  return actionTemplateGrading;
};

const [, , ...itemsDetailsTemplate] = itemsTemplate;

export {
  itemsTemplate,
  itemsTemplateMobile,
  itemsDetailsTemplate,
  actionsTemplate,
  actionsTemplateGrading,
};
