import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getPoolAccountDetailsType = 'GET_POOL_ACCOUNT_DETAILS';
const getPoolAccountDetailsAction = () => ({
  type: getPoolAccountDetailsType,
});

const getPoolAccountDetailsSuccessType = `${getPoolAccountDetailsType}_SUCCESS`;
const getPoolAccountDetailsSuccessAction = poolAccount => ({
  type: getPoolAccountDetailsSuccessType,
  payload: {
    poolAccount,
  },
});

const getPoolAccountDetailsFailureType = `${getPoolAccountDetailsType}_FAILURE`;
const getPoolAccountDetailsFailureAction = () => ({
  type: getPoolAccountDetailsFailureType,
});

const clearPoolAccountDetailsType = 'CLEAR_POOL_ACCOUNT_DETAILS';
const clearPoolAccountDetails = () => ({
  type: clearPoolAccountDetailsType,
});

const getPoolAccountDetails = companyId => dispatch => {
  dispatch(getPoolAccountDetailsAction());

  return axios
    .get(`${API_HOST_AUTH}/pool-accounts/${companyId}`)
    .then(res => {
      dispatch(getPoolAccountDetailsSuccessAction(res.data));

      return res.data;
    })
    .catch(err => {
      dispatch(getPoolAccountDetailsFailureAction());

      return Promise.reject(err.data);
    });
};

export {
  getPoolAccountDetailsType,
  getPoolAccountDetailsSuccessType,
  getPoolAccountDetailsFailureType,
  clearPoolAccountDetailsType,
  clearPoolAccountDetails,
  getPoolAccountDetails,
};
