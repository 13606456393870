import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import ReactTooltip from 'react-tooltip';
import styled, { css } from 'styled-components';

import { statusesColors } from 'shared/helpers/constants/statusesColors';
import { humanize } from 'shared/helpers/parsers/text';
import { pxToRem, cssVar } from 'shared/helpers/styling/styling';

const StatusIndicatorLayout = ({ className, status }) => (
  <div className={className} data-cy="StatusIndicator">
    {humanize(status)}
  </div>
);

const StatusIndicatorBtn = styled(StatusIndicatorLayout)`
  padding: 6px 15px;
  width: fit-content;
  font-weight: bold;
  border-radius: 15px;
  min-width: 100px;
  display: flex;
  justify-content: center;
  font-size: ${pxToRem(10)};
  ${({ status, color, backgroundColor }) => css`
    color: ${cssVar(color)};
    background-color: ${backgroundColor || cssVar(statusesColors[status])};
  `};
`;

const StyledWrapper = styled.div`
  ${({ tooltipNeverHide }) =>
    tooltipNeverHide &&
    css`
      display: flex;
      align-items: center;
      position: relative;
      margin: 0 10px;
      .extendTooltip {
        opacity: 0.9;
        visibility: visible;
        position: absolute;
        left: calc(50% - 104px) !important;
        top: -40px !important;
        width: 208px;
      }
    `}
`;

const StatusIndicator = ({
  status,
  tooltipContent,
  tooltipOptions,
  tooltipInitShow,
  tooltipNeverHide,
  id,
  ...props
}) => {
  const tooltipRef = useRef();

  useEffect(() => {
    if (tooltipRef?.current && tooltipInitShow) ReactTooltip.show(tooltipRef.current);
  }, []);

  return (
    <StyledWrapper
      data-tip={!!tooltipContent}
      data-for={id}
      ref={tooltipRef}
      tooltipNeverHide={tooltipNeverHide}
    >
      <StatusIndicatorBtn status={status} {...props} />
      {tooltipContent && (
        <ReactTooltip id={id} effect="solid" className="extendTooltip" {...tooltipOptions}>
          {tooltipContent}
        </ReactTooltip>
      )}
    </StyledWrapper>
  );
};

StatusIndicator.propTypes = {
  status: PropTypes.string.isRequired,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  tooltipContent: PropTypes.string,
  tooltipNeverHide: PropTypes.bool,
  id: PropTypes.string,
};

StatusIndicator.defaultProps = {
  color: 'whiteGRG',
  tooltipContent: null,
  tooltipNeverHide: false,
};

export { StatusIndicator };
