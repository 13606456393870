import axios from 'axios';

import { shipmentDirections } from 'shared/helpers/constants/shipments/shipmentDirections';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getIncomingShipmentsOverviewActionType = 'GET_INCOMING_SHIPMENTS_OVERVIEW';
const getOutgoingShipmentsOverviewActionType = 'GET_OUTGOING_SHIPMENTS_OVERVIEW';

const getIncomingShipmentsOverviewAction = () => ({
  type: getIncomingShipmentsOverviewActionType,
});

const getOutgoingShipmentsOverviewAction = () => ({
  type: getOutgoingShipmentsOverviewActionType,
});

const getIncomingShipmentsOverviewSuccessActionType = `${getIncomingShipmentsOverviewActionType}_SUCCESS`;
const getOutgoingShipmentsOverviewSuccessActionType = `${getOutgoingShipmentsOverviewActionType}_SUCCESS`;

const getIncomingShipmentsOverviewSuccessAction = data => ({
  type: getIncomingShipmentsOverviewSuccessActionType,
  payload: {
    data,
  },
});

const getOutgoingShipmentsOverviewSuccessAction = data => ({
  type: getOutgoingShipmentsOverviewSuccessActionType,
  payload: {
    data,
  },
});

const getIncomingShipmentsOverviewFailureActionType = `${getIncomingShipmentsOverviewActionType}_FAILURE`;
const getOutgoingShipmentsOverviewFailureActionType = `${getOutgoingShipmentsOverviewActionType}_FAILURE`;

const getIncomingShipmentsOverviewFailureAction = () => ({
  type: getIncomingShipmentsOverviewFailureActionType,
});

const getOutgoingShipmentsOverviewFailureAction = () => ({
  type: getOutgoingShipmentsOverviewFailureActionType,
});

const clearIncomingShipmentsOverviewActionType = 'CLEAR_INCOMING_SHIPMENTS_OVERVIEW';
const clearOutgoingShipmentsOverviewActionType = 'CLEAR_OUTGOING_SHIPMENTS_OVERVIEW';

const clearIncomingShipmentsOverview = () => ({
  type: clearIncomingShipmentsOverviewActionType,
});

const clearOutgoingShipmentsOverview = () => ({
  type: clearOutgoingShipmentsOverviewActionType,
});

const getShipmentsOverview =
  (action, successAction, failureAction, shipmentDirection) => limit => dispatch => {
    dispatch(action());

    return axios
      .get(`${API_HOST_AUTH}/shipments/overview/${shipmentDirection}/${limit}`)
      .then(({ data }) => {
        dispatch(successAction(data));
      })
      .catch(() => dispatch(failureAction()));
  };

const getIncomingShipmentsOverview = getShipmentsOverview(
  getIncomingShipmentsOverviewAction,
  getIncomingShipmentsOverviewSuccessAction,
  getIncomingShipmentsOverviewFailureAction,
  shipmentDirections.incoming,
);

const getOutgoingShipmentsOverview = getShipmentsOverview(
  getOutgoingShipmentsOverviewAction,
  getOutgoingShipmentsOverviewSuccessAction,
  getOutgoingShipmentsOverviewFailureAction,
  shipmentDirections.outgoing,
);

export {
  getIncomingShipmentsOverviewActionType,
  getIncomingShipmentsOverviewSuccessActionType,
  getIncomingShipmentsOverviewFailureActionType,
  getOutgoingShipmentsOverviewActionType,
  getOutgoingShipmentsOverviewSuccessActionType,
  getOutgoingShipmentsOverviewFailureActionType,
  clearIncomingShipmentsOverviewActionType,
  clearOutgoingShipmentsOverviewActionType,
  getIncomingShipmentsOverview,
  getOutgoingShipmentsOverview,
  clearIncomingShipmentsOverview,
  clearOutgoingShipmentsOverview,
};
