import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Field, getFormValues } from 'redux-form';
import styled from 'styled-components';

import { FieldCapitalized } from 'shared/components/Fields/FieldCapitalized/FieldCapitalized';
import { FieldInput } from 'shared/components/Fields/FieldInput/FieldInput';
import { Section } from 'shared/components/Form/Section/Section';
import { wizardContext } from 'shared/components/Wizard/Wizard';
import { SHIPMENTS } from 'shared/helpers/constants/shipments/shipmentsConstants';
import { cssVar } from 'shared/helpers/styling/styling';

import SelectPrimaryContact from 'pages/Locations/components/LocationsManagement/LocationForm/components/SelectPrimaryContact/SelectPrimaryContact';

const FieldCapitalizedStyled = styled(FieldCapitalized)`
  border-color: ${({ inputHighlight }) => (inputHighlight ? cssVar('solidYellow') : 'none')};
`;

const PackingListSection = ({
  fieldName,
  relatedCompany,
  change,
  getCompaniesContact,
  handleOnFocus,
  setDefaultValues,
}) => {
  const { createShipment } = useSelector(state => getFormValues('wizard')(state));
  const companiesContact = useSelector(state => state.companiesContact.data);
  const wizard = useContext(wizardContext);

  useEffect(() => {
    if (!setDefaultValues || createShipment?.contactName || !companiesContact?.length) {
      return;
    }

    const [{ firstName, lastName, _id, mobilePhone }] = companiesContact;

    change(`${fieldName}contactName`, {
      value: _id,
      label: `${firstName} ${lastName}`,
    });
    change(`${fieldName}contactNumber`, mobilePhone);
  }, [companiesContact]);

  const onContactNameChange = useCallback(
    contactName => {
      change(`${fieldName}contactNumber`, contactName?.mobilePhone);
    },
    [change],
  );

  return (
    <Section
      template={[
        `${fieldName}contactName ${fieldName}contactNumber`,
        `${fieldName}purchaseOrder ${fieldName}billOfLading`,
        `${fieldName}truckingCompany ${fieldName}trailer`,
        `${fieldName}shipmentSeal .`,
      ]}
      nameWithDots
    >
      <SelectPrimaryContact
        multi={false}
        name={`${fieldName}contactName`}
        label={SHIPMENTS.CONTACT_NAME}
        relatedCompany={relatedCompany}
        fieldRequired
        onChange={onContactNameChange}
        getCompaniesContact={getCompaniesContact}
        data-testid="contactName"
      />
      <Field
        name={`${fieldName}contactNumber`}
        component={FieldInput}
        label={SHIPMENTS.CONTACT_NUMBER}
        field="required"
        data-testid="contactNumber"
        onFocus={handleOnFocus}
      />
      <FieldCapitalized
        name={`${fieldName}purchaseOrder`}
        component={FieldInput}
        label={SHIPMENTS.PURCHASE_ORDER}
        data-testid="purchaseOrder"
        onFocus={handleOnFocus}
      />
      <FieldCapitalized
        name={`${fieldName}billOfLading`}
        component={FieldInput}
        label={SHIPMENTS.BILL_OF_LADING}
        data-testid="billOfLading"
        onFocus={handleOnFocus}
      />
      <FieldCapitalized
        name={`${fieldName}truckingCompany`}
        component={FieldInput}
        label={SHIPMENTS.TRUCKING_COMPANY}
        data-testid="truckingCompany"
        onFocus={handleOnFocus}
      />
      <FieldCapitalized
        name={`${fieldName}trailer`}
        component={FieldInput}
        label={SHIPMENTS.TRAILER}
        data-testid="trailer"
        onFocus={handleOnFocus}
      />
      <FieldCapitalizedStyled
        name={`${fieldName}shipmentSeal`}
        component={FieldInput}
        label={SHIPMENTS.SHIPMENT_SEAL}
        data-testid="shipmentSeal"
        onFocus={handleOnFocus}
        inputHighlight={wizard?.inputHighlight}
      />
    </Section>
  );
};

PackingListSection.propTypes = {
  fieldName: PropTypes.string.isRequired,
  relatedCompany: PropTypes.shape({
    _id: PropTypes.string,
    companyName: PropTypes.string,
  }).isRequired,
  change: PropTypes.func.isRequired,
  getCompaniesContact: PropTypes.func.isRequired,
  handleOnFocus: PropTypes.func.isRequired,
  setDefaultValues: PropTypes.bool,
};

PackingListSection.defaultProps = {
  setDefaultValues: true,
};

export { PackingListSection };
