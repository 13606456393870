import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { clearAssignedLotsAction } from 'actions/Lots/getAssignedLots';
import { enterPoolAccountOunces } from 'actions/PoolAccounts/enterPoolAccountOunces';
import { showSnackbar } from 'actions/shared/snackbar';

import { FormContainer } from 'shared/components/Form';
import { POOL_ACCOUNTS } from 'shared/helpers/constants/poolAccounts/poolAccountsConstants';
import {
  dangerOptions,
  successOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

import { EnterOuncesForm } from './EnterOuncesForm/EnterOuncesForm';

function EnterOuncesLayout(props) {
  useEffect(() => () => props.clearAssignedLotsAction());

  const submit = values => {
    props
      .enterPoolAccountOunces(values)
      .then(res => {
        props.showSnackbar(successOptions, res.message);
        props.history.push('/pool-accounts');
      })
      .catch(res => props.showSnackbar(dangerOptions, res.message));
  };

  return (
    <FormContainer
      header={POOL_ACCOUNTS.ENTER_OUNCES}
      loaded={!props.enterPoolAccountOuncesDetails.isPending}
    >
      {() => <EnterOuncesForm onSubmit={submit} />}
    </FormContainer>
  );
}

const mapStateToProps = ({ enterPoolAccountOuncesDetails }) => ({
  enterPoolAccountOuncesDetails,
});

const mapDispatchToProps = {
  showSnackbar,
  enterPoolAccountOunces,
  clearAssignedLotsAction,
};

const EnterOunces = connect(mapStateToProps, mapDispatchToProps)(EnterOuncesLayout);

export { EnterOuncesLayout, EnterOunces };
