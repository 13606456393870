import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getDeliveriesToTransferActionType = 'GET_DELIVERIES_TO_TRANSFER';
const getDeliveriesToTransferAction = () => ({
  type: getDeliveriesToTransferActionType,
});

const getDeliveriesToTransferSuccessActionType = `${getDeliveriesToTransferActionType}_SUCCESS`;
const getDeliveriesToTransferSuccessAction = data => ({
  type: getDeliveriesToTransferSuccessActionType,
  payload: data,
});

const getDeliveriesToTransferFailureActionType = `${getDeliveriesToTransferActionType}_FAILURE`;
const getDeliveriesToTransferFailureAction = () => ({
  type: getDeliveriesToTransferFailureActionType,
});

const getDeliveriesToTransfer =
  ({ hedgeId, limit = 0 }) =>
  dispatch => {
    dispatch(getDeliveriesToTransferAction());

    return axios
      .get(`${API_HOST_AUTH}/deliveries/transfer-ounces/list/${hedgeId}/${limit}`)
      .then(response => {
        dispatch(getDeliveriesToTransferSuccessAction(response.data));
        return response.data;
      })
      .catch(error => {
        dispatch(getDeliveriesToTransferFailureAction());
        return Promise.reject(error.data);
      });
  };

export {
  getDeliveriesToTransferActionType,
  getDeliveriesToTransferAction,
  getDeliveriesToTransferSuccessActionType,
  getDeliveriesToTransferSuccessAction,
  getDeliveriesToTransferFailureActionType,
  getDeliveriesToTransferFailureAction,
  getDeliveriesToTransfer,
};
