import {
  getFutureHedgesActionType,
  getFutureHedgesSuccessActionType,
  getFutureHedgesFailureActionType,
} from 'actions/Hedges/getFutureHedges';

const INITIAL_STATE = {
  isPending: false,
  futureHedges: [],
};

const getFutureHedgesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getFutureHedgesActionType:
      return {
        ...state,
        isPending: true,
      };
    case getFutureHedgesSuccessActionType:
      return {
        ...state,
        isPending: false,
        futureHedges: action.payload.futureHedges,
      };
    case getFutureHedgesFailureActionType:
      return {
        ...state,
        isPending: false,
      };
    default:
      return state;
  }
};

export { getFutureHedgesReducer };
