import {
  getLotAvailableOuncesActionType,
  getLotAvailableOuncesSuccessActionType,
  getLotAvailableOuncesFailureActionType,
  clearAvailableOuncesActionType,
  clearLotAvailableOuncesActionType,
} from 'actions/Deliveries/getLotAvailableOunces';

const INITIAL_STATE = {
  isPending: false,
  errorMessage: null,
  available: null,
};

const getLotAvailableOuncesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getLotAvailableOuncesActionType:
      return {
        isPending: true,
        errorMessage: null,
        ...state,
      };
    case getLotAvailableOuncesSuccessActionType:
      return {
        isPending: false,
        errorMessage: null,
        available: {
          ...state.available,
          [action.payload.lotId]: action.payload.available,
        },
      };
    case getLotAvailableOuncesFailureActionType:
      return {
        isPending: false,
        errorMessage: action.payload.errorMessage,
        available: null,
      };
    case clearLotAvailableOuncesActionType:
      return {
        ...state,
        available:
          state.available &&
          Object.entries(state.available)
            .filter(([name]) => name !== action.payload.lotId)
            .reduce((acc, [name, value]) => ({ ...acc, [name]: value }), {}),
      };
    case clearAvailableOuncesActionType:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};

export { getLotAvailableOuncesReducer };
