import { safeReplace, removeCommasAndParse } from 'shared/helpers/parsers/text';

function parseLotUpdateFormData(lot) {
  return {
    stillwaterShipmentId: lot.stillwaterShipmentId,
    materialType: lot.materialType && lot.materialType.value,
    catMono: lot.catMono,
    catFoil: lot.catFoil,
    catEmpty: lot.catEmpty,
    catAft: lot.catAft,
    catBead: lot.catBead,
    catGrgQtyTotal: lot.catGrgQtyTotal,
    globalMoistureTest: safeReplace(lot.globalMoistureTest, ',', '.'),
    truckingDeduction: removeCommasAndParse(lot.truckingDeduction, ',', '.'),
    deductions: lot.deductions
      .map(deduction => {
        const deductionObject = {};

        if (
          deduction?.value !== null &&
          deduction?.value !== undefined &&
          deduction?.description !== null &&
          deduction?.description !== undefined
        ) {
          deductionObject.value = removeCommasAndParse(deduction.value, ',', '.');
          deductionObject.description = deduction.description;
        }

        return deductionObject;
      })
      .filter(deduction => Object.keys(deduction).length !== 0),
    credits: lot.credits
      .map(credit => {
        const creditObject = {};

        if (
          credit?.value !== null &&
          credit?.value !== undefined &&
          credit?.description !== null &&
          credit?.description !== undefined
        ) {
          creditObject.value = removeCommasAndParse(credit.value, ',', '.');
          creditObject.description = credit.description;
        }

        return creditObject;
      })
      .filter(credit => Object.keys(credit).length !== 0),
    existingLotFiles: lot.existingLotFiles,
  };
}

export { parseLotUpdateFormData };
