import { LinkButton } from 'shared/components/Buttons';
import { DetailsActivityField } from 'shared/components/Details/DetailsActivityField';
import { DetailsField } from 'shared/components/Details/DetailsField';
import { DetailsHumanizedField } from 'shared/components/Details/DetailsHumanizedField';
import { DetailsRadioField } from 'shared/components/Details/DetailsRadioFieldRedesign';
import { USER } from 'shared/helpers/constants/users/userConstants';
import { isCustomerUser } from 'shared/helpers/matchers/checkUserType';

import { history } from 'utils/history';
import { resolveVersion, versions } from 'utils/versioning';

const displayAccessLevel = resolveVersion({
  [versions.alpha]: true,
  [versions.global]: true,
  [versions.west]: true,
});

const userDetailsFieldsConfig = values =>
  [
    {
      label: USER.RELATED_COMPANY,
      customContent: values.relatedCompany && (
        <LinkButton
          label={values.relatedCompany.companyName}
          onClick={() => history.push(`/companies/${values.relatedCompany._id}`)}
        />
      ),
      icon: 'icon-home',
      id: 'relatedCompany',
      FieldType: DetailsField,
    },
    {
      label: USER.LOCATIONS,
      value: values.location.map(location => location.name).join(', '),
      icon: 'icon-home',
      id: 'locations',
      FieldType: DetailsField,
    },
    {
      label: USER.USER_TYPE,
      icon: 'icon-type',
      value: values.userType,
      id: 'userType',
      FieldType: DetailsHumanizedField,
    },
    displayAccessLevel && {
      label: USER.ACCESS_LEVEL,
      icon: 'icon-access',
      value: values.accessLevel,
      id: 'accessLevel',
      FieldType: DetailsHumanizedField,
    },
    {
      label: USER.FIRST_NAME,
      value: values.firstName,
      icon: 'icon-user',
      id: 'firstName',
      FieldType: DetailsField,
    },
    {
      label: USER.LAST_NAME,
      value: values.lastName,
      icon: 'icon-user',
      id: 'lastName',
      FieldType: DetailsField,
    },
    {
      label: USER.TITLE,
      value: values.title,
      icon: 'icon-home',
      id: 'title',
      FieldType: DetailsField,
    },
    {
      label: USER.EMAIL,
      value: values.email,
      icon: 'icon-email',
      id: 'email',
      FieldType: DetailsField,
    },
    {
      label: USER.OFFICE_PHONE,
      value: values.officePhone,
      icon: 'icon-phone',
      id: 'officePhone',
      FieldType: DetailsField,
    },
    {
      label: USER.PHONE_EXTENSION,
      value: values.phoneExtension,
      icon: 'icon-phone',
      id: 'phoneExtension',
      FieldType: DetailsField,
    },
    {
      label: USER.MOBILE_PHONE,
      value: values.mobilePhone,
      icon: 'icon-phone',
      id: 'mobilePhone',
      FieldType: DetailsField,
    },
    ...(isCustomerUser(values.userType)
      ? [
          {
            label: USER.DATABASE_ACCESS,
            value: values.databaseAccess,
            icon: 'icon-link',
            id: 'databaseAccess',
            FieldType: DetailsRadioField,
          },
          {
            label: USER.PURCHASING_APP_ACCESS,
            value: values.purchasingAppAccess,
            icon: 'icon-link',
            id: 'purchasingAppAccess',
            FieldType: DetailsRadioField,
          },
        ]
      : []),
  ].filter(Boolean);

const activityFieldsConfig = values => [
  {
    label: USER.CREATED_BY,
    user: values.createdBy,
    date: values.createdAt,
    id: 'createdBy',
    FieldType: DetailsActivityField,
  },
  {
    label: USER.UPDATED_BY,
    user: values.updatedBy,
    date: values.updatedAt,
    id: 'updatedBy',
    FieldType: DetailsActivityField,
  },
];

const userDetailsContainerConfig = user => ({
  header: USER.GENERAL_DETAILS,
  template: [
    'relatedCompany relatedCompany locations locations',
    'firstName firstName lastName lastName',
    displayAccessLevel ? 'title title userType accessLevel' : 'title title userType userType',
    'email email officePhone phoneExtension',
    isCustomerUser(user.userType)
      ? 'mobilePhone mobilePhone databaseAccess purchasingAppAccess'
      : 'mobilePhone mobilePhone mobilePhone mobilePhone',
  ],
});

const activityContainerConfig = () => ({
  header: USER.ACTIVITY,
  template: ['createdBy createdBy updatedBy updatedBy'],
});

export {
  userDetailsFieldsConfig,
  activityFieldsConfig,
  userDetailsContainerConfig,
  activityContainerConfig,
};
