import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { pixelify, media, pxToRem } from 'shared/helpers/styling/styling';

const Container = styled.div`
  position: relative;
  display: grid;
  width: 100%;
  align-items: center;
  grid-template: ${({ labelWidth, slim }) =>
    `minmax(26px, auto) minmax(50px, auto) ${slim ? 0 : 27}px / ${pixelify(labelWidth)} auto`};
  grid-template-areas:
    'error error'
    'label input'
    '. hint';

  ${({ pretendMobile }) =>
    pretendMobile &&
    css`
      grid-template-rows: ${pxToRem(26)} ${pxToRem(25)} ${pxToRem(50)} ${({ hintLength }) =>
          `${hintLength ? pxToRem(hintLength * 27) : pxToRem(27)}`};
      grid-template-areas:
        'error error'
        'label label'
        'input input'
        'hint hint';
    `};

  ${media.mobile`
    grid-template-rows: minmax(26px, auto) auto minmax(50px, auto) ${({ hintLength }) =>
      `${hintLength ? pxToRem(hintLength * 27) : pxToRem(27)}`};
    grid-template-areas:
    "error error"
    "label label"
    "input input"
    "hint hint";
  `}

  ${({ withoutLabel }) =>
    withoutLabel &&
    media.mobile`
      grid-template-rows: minmax(36px, auto) auto ${({ hintLength }) =>
        `${hintLength ? pxToRem(hintLength * 27) : pxToRem(27)}`};
      grid-template-areas:
      "error error"
      "input input"
      "hint hint";
  `}
`;

Container.propTypes = {
  labelWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  slim: PropTypes.bool,
};

Container.defaultProps = {
  labelWidth: 180,
  slim: false,
};

export { Container };
