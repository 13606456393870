import { isExternalUser, isWarehouseUser, isBuyerUser } from '../matchers/checkUserType';
import { isStatusActive } from '../matchers/isStatusActive';

const generalInfoAccess = user =>
  isStatusActive(user.status) &&
  !isWarehouseUser(user.userType) &&
  !isExternalUser(user.userType) &&
  !isBuyerUser(user.userType);

export { generalInfoAccess };
