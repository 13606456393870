import axios from 'axios';

import { showSnackbar } from 'actions/shared/snackbar';

import {
  successOptions,
  dangerOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { parseCompanyUpdateData } from 'shared/helpers/dataParsers/company/parseCompanyUpdateData';

import { history } from 'utils/history';

const API_HOST_AUTH = process.env.REACT_APP_API;

const updateCompanyActionType = 'UPDATE_COMPANY';
function updateCompanyAction() {
  return {
    type: updateCompanyActionType,
  };
}

const updateCompanySuccessActionType = 'UPDATE_COMPANY_SUCCESS';
function updateCompanySuccessAction() {
  return {
    type: updateCompanySuccessActionType,
  };
}

const updateCompanyFailureActionType = 'UPDATE_COMPANY_FAILURE';
function updateCompanyFailureAction() {
  return {
    type: updateCompanyFailureActionType,
  };
}

function updateCompany(company, id, canUpdatePrefix, companyFiles) {
  if (!canUpdatePrefix) {
    delete company.lotPrefix;
  }

  return dispatch => {
    dispatch(updateCompanyAction());

    return axios({
      method: 'put',
      url: `${API_HOST_AUTH}/companies/${id}`,
      headers: { 'Content-Type': 'multipart/form-data' },
      data: parseCompanyUpdateData(company, companyFiles),
    })
      .then(res => {
        dispatch(updateCompanySuccessAction());
        dispatch(showSnackbar(successOptions, res.data.message));
        history.push(`/companies/${id}`);
      })
      .catch(error => {
        dispatch(updateCompanyFailureAction());
        dispatch(
          showSnackbar(dangerOptions, `Company has not been updated. ${error.data.message}`),
        );
      });
  };
}

export {
  updateCompanyActionType,
  updateCompanySuccessActionType,
  updateCompanyFailureActionType,
  updateCompany,
};
