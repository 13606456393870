import React, { useState } from 'react';

import {
  StyledPagesMobile,
  StyledPagesMobileArrow,
  StyledPagesMobilePages,
  StyledPagesMobileWrapper,
} from './StyledPagesMobile';

function PagesMobile(props) {
  const [page, setState] = useState(1);

  const nextPage = () => {
    setState(st => st + 1);
    props.setPage(page + 1);
  };

  const previousPage = () => {
    setState(st => st - 1);
    props.setPage(page - 1);
  };

  return (
    <StyledPagesMobile>
      <p>PAGES</p>
      <StyledPagesMobileWrapper>
        <StyledPagesMobileArrow>
          <span
            onClick={() => page !== 1 && previousPage()}
            className={`icon icon-tiny-arrow-left ${page === 1 ? 'disabled' : null}`}
          />
        </StyledPagesMobileArrow>
        <StyledPagesMobilePages>
          <span>{`${page} OF ${props.pages}`}</span>
        </StyledPagesMobilePages>
        <StyledPagesMobileArrow>
          <span
            onClick={() => page !== props.pages && nextPage()}
            className={`icon icon-tiny-arrow-right ${page === props.pages ? 'disabled' : null}`}
          />
        </StyledPagesMobileArrow>
      </StyledPagesMobileWrapper>
    </StyledPagesMobile>
  );
}

export { PagesMobile };
