import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';

import { FieldSearch } from 'shared/components/Fields';
import { Form, Section } from 'shared/components/Form';
import { HEDGES } from 'shared/helpers/constants/hedges/hedgesConstants';
import { cancelModal } from 'shared/helpers/constants/modalConstants';
import { PLACEHOLDERS } from 'shared/helpers/constants/placeholders';
import { validate } from 'shared/helpers/validations/company/privilegedCompaniesValidation';

import { goBackOrTo } from 'utils/history';

class TrustedCompaniesFormLayout extends Component {
  onCancel = () => {
    this.props.openModal(cancelModal, () => {
      goBackOrTo('/hedges/trusted-companies');
    });
  };

  listUntrustedCompanies = input =>
    !input ? Promise.resolve({ options: [] }) : this.props.filterTrustedCompaniesToAdd(input);

  render() {
    return (
      <Form
        header={HEDGES.TRUSTED_COMPANIES.TITLE}
        onSubmit={this.props.handleSubmit}
        onCancel={this.onCancel}
        submitDisabled={this.props.invalid || this.props.submitting}
      >
        <Section template={['relatedCompany .']}>
          <Field
            name="relatedCompany"
            component={FieldSearch}
            label={HEDGES.TRUSTED_COMPANIES.RELATED_COMPANY}
            field="required"
            placeholder={PLACEHOLDERS.SEARCH}
            getOptions={this.listUntrustedCompanies}
          />
        </Section>
      </Form>
    );
  }
}

const TrustedCompaniesForm = reduxForm({
  form: 'TrustedCompaniesForm',
  validate,
})(TrustedCompaniesFormLayout);

export { TrustedCompaniesForm, TrustedCompaniesFormLayout };
