import {
  manageGlobalFilesType,
  manageGlobalFilesSuccessType,
  manageGlobalFilesFailureType,
} from 'actions/Lots/manageGlobalFiles';

const INITIAL_STATE = {
  isPending: false,
};

const manageGlobalFilesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case manageGlobalFilesType:
      return {
        isPending: true,
      };
    case manageGlobalFilesSuccessType:
    case manageGlobalFilesFailureType:
      return {
        isPending: false,
      };
    default:
      return state;
  }
};

export { manageGlobalFilesReducer };
