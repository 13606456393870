import React from 'react';
import styled from 'styled-components';

import { Icon } from 'shared/components/Icon/Icon';
import { cssVar, pxToRem } from 'shared/helpers/styling/styling';

const ModalContentContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;

  span:first-of-type {
    font-weight: bold;
    color: ${cssVar('geyser')};
  }

  span:last-of-type {
    font-size: ${pxToRem(14)};
    color: ${cssVar('gullGray')};
    padding-top: 20px;
  }
`;

const ModalContent = ({ modal }) => (
  <ModalContentContainer>
    <Icon icon="warning icon-exclamation" height={65} width={65} />
    <span>{modal.modalOptions.message.toUpperCase()}</span>
    <span>{modal.modalOptions.messageText}</span>
  </ModalContentContainer>
);

export { ModalContent };
