import PropTypes from 'prop-types';
import { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { downloadAttachment } from 'actions/Attachments/downloadAttachment';
import { showSnackbar } from 'actions/shared/snackbar';

import { ModalCustom } from 'components/ModalCustom/ModalCustom';
import { NotificationActionsPortal } from 'components/NotificationActionsPortal/NotificationActionsPortal';

import { CancelButton } from 'shared/components/Buttons';
import { Button } from 'shared/components/Buttons/Button';
import { ContentLoader } from 'shared/components/ContentLoader/ContentLoader';
import { DetailsDownloadField } from 'shared/components/Details/DetailsDownloadFieldRedesign';
import { Icon } from 'shared/components/Icon/Icon';
import { globalFileTypes } from 'shared/helpers/constants/lots/globalFileTypes';
import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';
import { notificationActionTypes } from 'shared/helpers/constants/notifications/notificationActionTypes';
import {
  dangerOptions,
  successOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { cssVar } from 'shared/helpers/styling/styling';

import { StyledManageGlobalFiles, Actions } from './StyledManageGlobalFiles';

const ManageGlobalFiles = ({
  modalHeader,
  modalInfo,
  fileLabel,
  draftFile,
  lot,
  manageGlobalFiles,
  confirmationOnly,
  cancelLabel,
  approveFileAction,
  denyFileAction,
  approveLabel,
  clearNotificationDetails,
  shipmentsIsPending,
}) => {
  const dispatch = useDispatch();
  const { fileType, manage, id } = useParams();
  const history = useHistory();

  const [displayModal, setDisplayModal] = useState(
    fileType === globalFileTypes.profitAndLoss || !!manage,
  );

  useEffect(() => {
    !draftFile && !confirmationOnly && history.push(`/lots/list/${id}`);
  }, []);

  const handleManageGlobalFileSuccess = res => {
    dispatch(showSnackbar(successOptions, res.message));
    history.push(`/lots/list/${id}`);
  };

  const handleManageGlobalFileFailure = res => {
    dispatch(showSnackbar(dangerOptions, res.message));
  };

  const approveGlobalFile = useCallback(
    id => () => {
      dispatch(approveFileAction(id))
        .then(handleManageGlobalFileSuccess)
        .catch(handleManageGlobalFileFailure);
    },
    [lot._id],
  );

  const denyGlobalFile = useCallback(
    id => () => {
      dispatch(denyFileAction(id))
        .then(handleManageGlobalFileSuccess)
        .catch(handleManageGlobalFileFailure);
    },
    [lot._id],
  );

  const modalClose = () => {
    setDisplayModal(false);
    clearNotificationDetails();
    history.push(`/lots/list/${id}`);
  };

  const notificationActions = [
    notificationActionTypes.shipmentSummary,
    notificationActionTypes.finalInvoice,
  ];

  return (
    <ContentLoader loaded={!manageGlobalFiles.isPending && !shipmentsIsPending}>
      {(draftFile || confirmationOnly) && (
        <ModalCustom isOpen={displayModal} holdOnOutsideClick modalClose={modalClose}>
          <StyledManageGlobalFiles>
            <h4>{modalHeader}</h4>
            <p>{modalInfo}</p>
            {draftFile && (
              <DetailsDownloadField
                label={fileLabel}
                backgroundColor={cssVar('outerSpace')}
                onClick={() => dispatch(downloadAttachment(draftFile.path))}
                id="draftFile"
                value={draftFile}
              />
            )}
            <Actions>
              <NotificationActionsPortal actions={notificationActions} noRedirect>
                <CancelButton
                  onClick={denyGlobalFile(lot._id)}
                  id="cancelButton"
                  label={cancelLabel || LOTS.DENY}
                />
              </NotificationActionsPortal>
              <NotificationActionsPortal actions={notificationActions} noRedirect>
                <Button padding="0 28px" onClick={approveGlobalFile(lot._id)}>
                  {approveLabel || LOTS.APPROVE}
                  <Icon icon="icon-tick" />
                </Button>
              </NotificationActionsPortal>
            </Actions>
          </StyledManageGlobalFiles>
        </ModalCustom>
      )}
    </ContentLoader>
  );
};

ManageGlobalFiles.propTypes = {
  modalHeader: PropTypes.string,
  modalInfo: PropTypes.string,
  fileLabel: PropTypes.string,
  draftFile: PropTypes.object,
  lot: PropTypes.object,
  manageGlobalFiles: PropTypes.object,
  confirmationOnly: PropTypes.bool,
  shipmentsIsPending: PropTypes.bool,
  cancelLabel: PropTypes.string,
  approveLabel: PropTypes.string,
  approveFileAction: PropTypes.func,
  denyFileAction: PropTypes.func,
  clearNotificationDetails: PropTypes.func,
};

export { ManageGlobalFiles };
