import styled from 'styled-components';

import { cssVar } from 'shared/helpers/styling/styling';

const StyledDetailsNotes = styled.div`
  grid-area: ${({ id }) => id};
  section {
    margin: 0;
    & > div {
      background: ${cssVar('limedSpruceDetails')};
      border: none;
    }
  }
`;

export { StyledDetailsNotes };
