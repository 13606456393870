import { Fragment } from 'react';

import { Button } from 'shared/components/Buttons';
import { Icon } from 'shared/components/Icon/Icon';
import {
  acceptLotForGradingAccess,
  markGradingCompleteAccess,
  moveToProcessingAccess,
  uploadMaterialCountAccess,
} from 'shared/helpers/accesses/lots';
import { companyTypesEnum } from 'shared/helpers/constants/filters/companyTypes';
import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';
import { statusesForGlobal } from 'shared/helpers/constants/lots/statusesForGlobal';
import { generateIncomingSectionHeader } from 'shared/helpers/dataParsers/lot/lotOverviewData';
import { cssVar } from 'shared/helpers/styling/styling';

import { AcceptForGrading } from 'pages/Lots/components/AcceptForGrading/AcceptForGrading';
import { MarkGradingComplete } from 'pages/Lots/components/MarkGradingComplete/MarkGradingComplete';

const incomingSections = {
  partlyReceived: {
    name: 'partlyReceived',
    title: 'Partly received',
    query: {
      statusForGlobal: [statusesForGlobal.partly_received],
      receivedAt: 1,
    },
  },

  removeSamples: {
    name: 'removeSamples',
    title: LOTS.REMOVE_SAMPLES_LIST_TITLE,
    query: {
      statusForGlobal: [statusesForGlobal.received, statusesForGlobal.ready_for_processing],
      receivedAt: 1,
      processUponDelivery: true,
      removeSamples: true,
      samplePackages: { $exists: true, $ne: [] },
    },
  },

  receivedOnHold: {
    name: 'receivedOnHold',
    title: 'Received - on hold',
    query: {
      statusForGlobal: [statusesForGlobal.received],
      processUponDelivery: false,
      receivedAt: 1,
    },
  },
  receivedGradingRequired: {
    name: 'receivedGradingRequired',
    title: 'Received - grading required',
    selectedColumnName: 'Grading priority',
    priorityField: 'gradingPriority',
    query: {
      statusForGlobal: [statusesForGlobal.received, statusesForGlobal.accepted_for_grading],
      companyType: companyTypesEnum.grading,
      gradingPriority: 1,
      receivedAt: 1,
    },
    getDraggableProps(props, sections, access) {
      const [topLotPriority] = sections.selected.data.filter(l => !l.gradedAt);

      return (item, sectionId) => ({
        highlighted: item.statusForGlobal === statusesForGlobal.accepted_for_grading,
        dragDisabled: this.isDragDisabled(props, access)(item),
        icon: 'icon-drag',
        expanded:
          sectionId !== 'items' &&
          (acceptLotForGradingAccess(props.auth, item, topLotPriority) ||
            markGradingCompleteAccess(props.auth, item)),
      });
    },
    isDragDisabled(props, access) {
      return ({ statusForGlobal }) => {
        if (access && !access.lots.gradingPriority) return true;

        const incomingLotsOverview = props && props.incomingLotsOverview;
        const isPending =
          incomingLotsOverview && incomingLotsOverview.receivedGradingRequired.isPending;

        return isPending || statusForGlobal === statusesForGlobal.accepted_for_grading;
      };
    },
  },
  receivedMaterialCount: {
    name: 'receivedMaterialCount',
    title: 'Received - material count',
    selectedColumnName: 'Material count priority',
    priorityField: 'materialCountPriority',
    query: {
      statusForGlobal: [statusesForGlobal.received, statusesForGlobal.ready_for_processing],
      countSheet: encodeURIComponent(null),
      materialCountRequired: true,
      processUponDelivery: true,
      acceptedForProcessingAt: encodeURIComponent(null),
      acceptedForProcessingBy: encodeURIComponent(null),
      materialCountPriority: 1,
      receivedAt: 1,
    },
    getDraggableProps(props, sections, access) {
      return (item, sectionId) => ({
        dragDisabled: this.isDragDisabled(props, access)(item),
        icon: 'icon-drag',
        expanded:
          sectionId !== 'items' &&
          (access.lots.materialCountUpload || access.lots.moveToProcessing),
      });
    },
    isDragDisabled(props, access) {
      return () => {
        if (access && !access.lots.materialCountPriority) return true;

        const incomingLotsOverview = props && props.incomingLotsOverview;
        return incomingLotsOverview && incomingLotsOverview.receivedMaterialCount.isPending;
      };
    },
  },
};

const getSectionTemplates = ({
  getTotal,
  incomingLotsOverview,
  renderOverviewList,
  getListFactory,
  renderDraggableList,
  auth,
}) => [
  {
    ...incomingSections.partlyReceived,
    total: getTotal(incomingLotsOverview.partlyReceived),
    render: renderOverviewList(
      incomingLotsOverview.partlyReceived,
      getListFactory(incomingSections.partlyReceived),
    ),
  },
  {
    ...incomingSections.receivedOnHold,
    total: getTotal(incomingLotsOverview.receivedOnHold),
    render: renderOverviewList(
      incomingLotsOverview.receivedOnHold,
      getListFactory(incomingSections.receivedOnHold),
    ),
  },
  {
    ...incomingSections.receivedGradingRequired,
    total: getTotal(incomingLotsOverview.receivedGradingRequired),
    render: renderDraggableList(
      incomingLotsOverview.receivedGradingRequired,
      auth.access.lots.gradingPriority,
      incomingSections.receivedGradingRequired,
    ),
  },
  {
    ...incomingSections.receivedMaterialCount,
    total: getTotal(incomingLotsOverview.receivedMaterialCount),
    render: renderDraggableList(
      incomingLotsOverview.receivedMaterialCount,
      auth.access.lots.materialCountPriority,
      incomingSections.receivedMaterialCount,
    ),
  },
  {
    ...incomingSections.removeSamples,
    total: getTotal(incomingLotsOverview.removeSamples),
    render: renderOverviewList(
      incomingLotsOverview.removeSamples,
      getListFactory(incomingSections.removeSamples),
    ),
  },
];

const getExpandedProcessingActionsTemplate = ({
  auth,
  topLotPriority,
  reloadDraggableSections,
  toggleCountSheetModal,
  isUploadingCountSheetBtnDisabled,
  toggleMoveToProcessingModal,
  isMovingToProcessingBtnDisabled,
  acceptForGrading,
  incomingLotsOverview,
  markGradingComplete,
}) => [
  {
    access: lot => acceptLotForGradingAccess(auth, lot, topLotPriority),
    render: (lot, key) => (
      <AcceptForGrading lot={lot} key={key} onSuccess={reloadDraggableSections}>
        {({ acceptForGrading: acceptForGradingAction }) => (
          <Button
            id="acceptLotForGrading"
            onClick={acceptForGradingAction}
            disabled={
              acceptForGrading.isPending || incomingLotsOverview.receivedGradingRequired.isPending
            }
          >
            {LOTS.ACCEPT_FOR_GRADING}
            <Icon icon="icon-process" width={20} height={20} size="contain" />
          </Button>
        )}
      </AcceptForGrading>
    ),
  },
  {
    access: lot => markGradingCompleteAccess(auth, lot),
    render: (lot, key) => (
      <MarkGradingComplete lot={lot} key={key} onSuccess={reloadDraggableSections}>
        {({ markGradingComplete: markGradingCompleteAction }) => (
          <Button
            id="markGradingComplete"
            outlineColor={cssVar('beaver')}
            onClick={markGradingCompleteAction}
            disabled={
              markGradingComplete.isPending ||
              incomingLotsOverview.receivedGradingRequired.isPending
            }
          >
            {LOTS.GRADING_FINISHED}
            <Icon icon="icon-tick" width={20} height={20} size="contain" />
          </Button>
        )}
      </MarkGradingComplete>
    ),
  },
  {
    access: lot => uploadMaterialCountAccess(auth, lot),
    render: (lot, key) => (
      <Fragment key={key}>
        <Button
          key={key + 'uploadCountSheet'}
          id="uploadCountSheet"
          outlineColor={cssVar('pelorous')}
          onClick={toggleCountSheetModal(lot)}
          disabled={isUploadingCountSheetBtnDisabled}
        >
          {LOTS.UPLOAD_COUNT_SHEET}
          <Icon icon="icon-arrow-up" width={20} height={20} size="contain" />
        </Button>
      </Fragment>
    ),
  },
  {
    access: lot => moveToProcessingAccess(auth, lot),
    render: (lot, key) => {
      const {
        sentFromCompany: { materialCountRequired },
        acceptedForProcessingAt,
        acceptedForProcessingBy,
      } = lot;
      const canMoveToProcessing =
        materialCountRequired &&
        acceptedForProcessingAt === null &&
        acceptedForProcessingBy === null;

      return (
        canMoveToProcessing && (
          <Fragment key={key}>
            <Button
              key={key + 'moveToProcessing'}
              id="moveToProcessing"
              outlineColor={cssVar('atlantis')}
              onClick={() => toggleMoveToProcessingModal(lot)}
              disabled={isMovingToProcessingBtnDisabled}
            >
              {LOTS.MOVE_TO_PROCESSING}
              <Icon icon="icon-arrow-right" width={20} height={20} size="contain" />
            </Button>
          </Fragment>
        )
      );
    },
  },
];

const getDraggableListSections = ({ section, docs, itemsOrderFn }) => ({
  items: {
    header: generateIncomingSectionHeader(
      section.availableColumnName || LOTS.AVAILABLE,
      docs.filter(p => !p[section.priorityField]).length,
    ),
    placeholder: LOTS.NO_MORE_LOTS,
    data: docs.filter(p => !p[section.priorityField]).sort(itemsOrderFn('receivedAt')),
  },
  selected: {
    header: generateIncomingSectionHeader(
      section.selectedColumnName || LOTS.SELECTED,
      docs.filter(p => p[section.priorityField]).length,
    ),
    placeholder: LOTS.DROP_HERE,
    data: docs.filter(p => p[section.priorityField]).sort(itemsOrderFn(section.priorityField)),
  },
});

export {
  incomingSections,
  getSectionTemplates,
  getExpandedProcessingActionsTemplate,
  getDraggableListSections,
};
