import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const lockMarketIndicatorsType = 'LOCK_MARKET_INDICATORS';
const lockMarketIndicatorsAction = () => ({
  type: lockMarketIndicatorsType,
});

const lockMarketIndicatorsSuccessType = `${lockMarketIndicatorsType}_SUCCESS`;
const lockMarketIndicatorsSuccessAction = () => ({
  type: lockMarketIndicatorsSuccessType,
});

const lockMarketIndicatorsFailureType = `${lockMarketIndicatorsType}_FAILURE`;
const lockMarketIndicatorsFailureAction = () => ({
  type: lockMarketIndicatorsFailureType,
});

const lockMarketIndicators = () => async dispatch => {
  dispatch(lockMarketIndicatorsAction());

  try {
    const response = await axios.patch(`${API_HOST_AUTH}/market-indicators/lock`);
    dispatch(lockMarketIndicatorsSuccessAction(response.data));

    return response.data;
  } catch (error) {
    dispatch(lockMarketIndicatorsFailureAction());

    return Promise.reject(error.data);
  }
};

export {
  lockMarketIndicatorsType,
  lockMarketIndicatorsSuccessType,
  lockMarketIndicatorsFailureType,
  lockMarketIndicators,
};
