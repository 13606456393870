import PropTypes from 'prop-types';
import React, { useState, useEffect, useMemo, useCallback } from 'react';

import { Button } from 'shared/components/Buttons/Button';
import { cssVar } from 'shared/helpers/styling/styling';

import { withFieldWrapper } from '../FieldWrapper/FieldWrapper';
import { Container } from './StyledFieldButtonSelect';

function FieldButtonSelectBase({
  options,
  disabled,
  normalize,
  value,
  name,
  onChange,
  onBlur,
  onFocus,
  meta,
  clearable,
}) {
  const { touched, error } = meta || {};
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    const selectedValueIndex = options.findIndex(
      option => value !== null && option.value === (value.value || value),
    );

    setSelected(selectedValueIndex >= 0 ? selectedValueIndex : null);
  }, [options, value]);

  useEffect(() => {
    const isOnlyOption = options.length === 1;

    if (isOnlyOption && selected === null) {
      setSelected(0);
      onChange && onChange(options[0]);
    }
  }, [onChange, options, selected, value]);

  const fieldValue = useMemo(
    () => (selected === null ? null : normalize(options[selected])),
    [normalize, options, selected],
  );

  const emitEvent = useCallback(
    index => {
      onChange && onChange(index === null ? null : normalize(options[index]));
    },
    [onChange, normalize, options],
  );

  const onSelect = useCallback(
    index => {
      const selectedValue = clearable && index === selected ? null : index;
      setSelected(selectedValue);
      emitEvent(selectedValue);
    },
    [setSelected, emitEvent, clearable, selected],
  );

  const getBgColor = useCallback(
    index => cssVar(index === selected ? 'regentGrayBrighter' : 'shuttleGray'),
    [selected],
  );

  const getOutlineColor = useCallback(
    index => {
      if (touched && error) return cssVar('copperRust');

      return cssVar(index === selected ? 'regentGrayBrighter' : 'shuttleGray');
    },
    [touched, error, selected],
  );

  const getOutlineHoverColor = useCallback(
    index => cssVar(index === selected ? 'regentGrayBrighter' : 'nevada'),
    [selected],
  );

  return (
    <Container id={name}>
      {options.map(({ label, alwaysVisible }, index) => (
        <Button
          key={`${index}-${label}`}
          id={`${name}-${label.replace(/\s+/g, '')}`}
          borderRadius={5}
          bgColor={getBgColor(index)}
          bgColorHover={getOutlineHoverColor(index)}
          bgColorDisabled={getBgColor(index)}
          outlineColor={getOutlineColor(index)}
          outlineColorHover={getOutlineHoverColor(index)}
          outlineColorDisabled={getOutlineColor(index)}
          onClick={() => onSelect(index)}
          onFocus={onFocus}
          onBlur={() => onBlur && onBlur(fieldValue)}
          disabled={disabled}
          alwaysVisible={alwaysVisible}
        >
          {label}
        </Button>
      ))}
    </Container>
  );
}

FieldButtonSelectBase.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
      alwaysVisible: PropTypes.bool,
    }),
  ),
  normalize: PropTypes.func,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
    }),
  ]),
  name: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  meta: PropTypes.object,
  clearable: PropTypes.bool,
};

FieldButtonSelectBase.defaultProps = {
  options: [],
  normalize: option => option,
};

const FieldButtonSelect = withFieldWrapper(FieldButtonSelectBase);

export { FieldButtonSelect };
