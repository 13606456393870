import React, { Component } from 'react';
import { Field } from 'redux-form';

import { FieldHint, FieldSearch, FieldDate, FieldInput } from 'shared/components/Fields';
import { FieldButtonSelect } from 'shared/components/Fields/FieldButtonSelect/FieldButtonSelect';
import { FieldCheckbox } from 'shared/components/Fields/FieldCheckbox/FieldCheckbox';
import { FieldSelect } from 'shared/components/Fields/FieldSelect/FieldSelect';
import { Form, Section } from 'shared/components/Form';
import { MultipleFieldsSection } from 'shared/components/Form/MultipleFieldsSection/MultipleFieldsSection';
import { cancelModal } from 'shared/helpers/constants/modalConstants';
import { availableFundsTypes } from 'shared/helpers/constants/payments/fundsTypes';
import { PAYMENTS } from 'shared/helpers/constants/payments/paymentsConstants';
import { POOL_ACCOUNTS } from 'shared/helpers/constants/poolAccounts/poolAccountsConstants';
import { SHARED } from 'shared/helpers/constants/sharedConstants';
import { dangerOptions, infoOptions } from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { calculateMinimalFinalPaymentDelay } from 'shared/helpers/dataParsers/payments/calculateMinimalFinalPaymentDelay';
import { getBankHolidaysDates } from 'shared/helpers/dataParsers/payments/parseBankHolidaysData';
import { isCustomerUser } from 'shared/helpers/matchers/checkUserType';
import { isGlobalUserType } from 'shared/helpers/matchers/isGlobalUserType';
import { mapArray } from 'shared/helpers/parsers/select';

import { goBackOrTo } from 'utils/history';

class RequestSettlementFormLayout extends Component {
  state = {
    canRequestPayment: true,
    canRequestSettlement: false,
    wasPoolPaymentsSubmitted: false,
  };

  componentDidMount() {
    const {
      auth: {
        user: { userType, relatedCompany },
      },
      initialValues,
      match,
      summaryAvailableLots,
      location: { state: locationState },
      change,
      metalPool,
    } = this.props;
    initialValues &&
      summaryAvailableLots &&
      summaryAvailableLots.length &&
      this.setLotName(match.params.id);

    initialValues && this.maximumSettlementOunces(match.params.id);

    if (metalPool && isCustomerUser(userType)) {
      change('requestPayment', true);
      change('relatedCompany.value', relatedCompany._id);

      this.props
        .checkSubmittedPoolPayments(relatedCompany._id)
        .then(({ wasPoolPaymentsSubmitted }) => {
          this.setState({
            wasPoolPaymentsSubmitted,
          });
          if (wasPoolPaymentsSubmitted) {
            this.props.showSnackbar(infoOptions, PAYMENTS.POOL_PAYMENT_ALREADY_REQUESTED);
          }
        });
    }

    if (locationState && locationState.requestPayment) {
      change('requestPayment', locationState.requestPayment);
      change('relatedLot', {
        label: locationState.relatedLot.label,
        value: locationState.relatedLot.value,
      });
      change('fundsType', {
        label: locationState.fundsType.label,
        value: locationState.fundsType.value,
      });
      change('requestedFinalPaymentDate', locationState.requestedFinalPaymentDate);
      change('additionalInfo', locationState.additionalInfo);
      this.maximumSettlementOunces(locationState.relatedLot.value);
    }
    this.props.getBankHolidays({ year: '' });
  }

  componentDidUpdate(prevProps) {
    const { initialValues, match, summaryAvailableLots } = this.props;
    if (summaryAvailableLots !== prevProps.summaryAvailableLots) {
      initialValues && summaryAvailableLots.length && this.setLotName(match.params.id);
    }
  }

  componentWillUnmount() {
    this.props.clearMaximumSettlementOunces();
    this.props.clearSubmittedPoolPayments();
    this.props.clearBankHolidays();
  }

  modalOpen = () => {
    this.props.openModal(cancelModal, () => {
      goBackOrTo('/payments');
    });
  };

  clearInputs = () => {
    this.props.change('platinumOz', '');
    this.props.change('palladiumOz', '');
    this.props.change('rhodiumOz', '');
  };

  setLotName = lotId => {
    const initialLotName = this.props.summaryAvailableLots.find(lot => lot.value === lotId);
    this.props.change('relatedLot', initialLotName);
  };

  maximumSettlementOunces = value => {
    const {
      location: { pathname },
    } = this.props;

    this.props.hideSnackbar();
    this.setState({ canRequestSettlement: false });

    value &&
      this.props
        .getMaximumSettlementOunces(value)
        .then(
          ({
            maximumPlatinumOz,
            maximumPalladiumOz,
            maximumRhodiumOz,
            wasFinalPaymentRequested,
            wasSettlementRequested,
          }) => {
            this.props.change('platinumOz', maximumPlatinumOz);
            this.props.change('palladiumOz', maximumPalladiumOz);
            this.props.change('rhodiumOz', maximumRhodiumOz);

            this.setState({
              canRequestPayment: !wasFinalPaymentRequested,
              canRequestSettlement: !wasSettlementRequested,
            });

            if (pathname.endsWith('settlement-for-lot') && wasSettlementRequested) {
              this.props.showSnackbar(dangerOptions, PAYMENTS.SETTLEMENT_ALREADY_REQUESTED);
            }
          },
        )
        .catch(() => {
          this.clearInputs();
        });
  };

  getSuggestedValue = ref => {
    ref ? this.maximumSettlementOunces(ref.value) : this.props.clearMaximumSettlementOunces();

    this.clearInputs();
  };

  getSuggested = () => {
    const {
      auth: {
        user: { userType },
      },
      relatedCompany,
      metalPool,
      poolAccountsList,
      maximumSettlementOunces: { settlementOunces },
      relatedLot,
    } = this.props;

    if (isGlobalUserType(userType) && relatedCompany) {
      return {
        platinum: relatedCompany.platinumOz,
        palladium: relatedCompany.palladiumOz,
        rhodium: relatedCompany.rhodiumOz,
      };
    }

    if (metalPool && poolAccountsList && poolAccountsList.poolAccounts.docs) {
      return {
        platinum: poolAccountsList.poolAccounts.docs[0].platinumOz,
        palladium: poolAccountsList.poolAccounts.docs[0].palladiumOz,
        rhodium: poolAccountsList.poolAccounts.docs[0].rhodiumOz,
      };
    }

    const { maximumPlatinumOz, maximumPalladiumOz, maximumRhodiumOz } =
      (settlementOunces && settlementOunces[relatedLot && relatedLot.value]) || false;

    return {
      platinum: maximumPlatinumOz,
      palladium: maximumPalladiumOz,
      rhodium: maximumRhodiumOz,
    };
  };

  changeRequestPayment = () => {
    const { requestPayment, change } = this.props;

    change('requestPayment', !requestPayment);
  };

  render() {
    const { canRequestPayment, canRequestSettlement } = this.state;
    const {
      auth: {
        user: { userType },
      },
      handleSubmit,
      invalid,
      metalPool,
      summaryAvailableLots,
      listSentFromCompanies,
      requestPayment,
      customerRequestSettlement,
      submitting,
      location: { pathname },
      bankHolidaysList: { bankHolidays },
    } = this.props;
    const suggestions = this.getSuggested();

    const ouncesFields = [
      {
        component: FieldHint,
        name: 'platinumOz',
        field: 'required',
        suggestion: suggestions.platinum,
        label: POOL_ACCOUNTS.PLATINUM_OZ_TO_SETTLE,
        hint: SHARED.MAXIMUM,
      },
      {
        component: FieldHint,
        name: 'palladiumOz',
        field: 'required',
        suggestion: suggestions.palladium,
        label: POOL_ACCOUNTS.PALLADIUM_OZ_TO_SETTLE,
        hint: SHARED.MAXIMUM,
      },
      {
        component: FieldHint,
        name: 'rhodiumOz',
        field: 'required',
        suggestion: suggestions.rhodium,
        label: POOL_ACCOUNTS.RHODIUM_OZ_TO_SETTLE,
        hint: SHARED.MAXIMUM,
      },
    ];

    return (
      <Form
        header={POOL_ACCOUNTS.SETTLEMENT}
        onSubmit={handleSubmit}
        onCancel={this.modalOpen}
        submitDisabled={
          invalid ||
          submitting ||
          (isCustomerUser(userType) && this.state.wasPoolPaymentsSubmitted) ||
          (pathname.endsWith('settlement-for-lot') && !canRequestSettlement)
        }
      >
        <Section template={['relatedLot requestPayment', 'relatedCompany .']}>
          {!metalPool && (
            <Field
              name="relatedLot"
              component={FieldSelect}
              options={summaryAvailableLots}
              label={POOL_ACCOUNTS.RELATED_LOT}
              onChange={this.getSuggestedValue}
              field="required"
            />
          )}
          {isCustomerUser(userType) && customerRequestSettlement && canRequestPayment && (
            <Field
              name="requestPayment"
              component={FieldCheckbox}
              label={POOL_ACCOUNTS.REQUEST_PAYMENT}
              checked={requestPayment}
              onClick={this.changeRequestPayment}
            />
          )}
          {metalPool && !isCustomerUser(userType) && (
            <Field
              name="relatedCompany"
              component={FieldSearch}
              label={POOL_ACCOUNTS.RELATED_COMPANY}
              field="required"
              getOptions={listSentFromCompanies}
            />
          )}
        </Section>
        <Section header={POOL_ACCOUNTS.OUNCES} template={['ouncesFields ouncesFields']} noPadding>
          <MultipleFieldsSection name="ouncesFields" fields={ouncesFields} />
        </Section>
        {requestPayment && (
          <Section
            header={POOL_ACCOUNTS.REQUEST_PAYMENT}
            template={['fundsType requestedFinalPaymentDate', 'additionalInfo .']}
          >
            <Field
              name="fundsType"
              component={FieldButtonSelect}
              options={mapArray(availableFundsTypes.assayInternal)}
              label={PAYMENTS.FUNDS_TYPE}
              field="required"
            />
            <Field
              name="requestedFinalPaymentDate"
              component={FieldDate}
              label={PAYMENTS.REQUESTED_FINAL_PAYMENT_DATE}
              minDate={calculateMinimalFinalPaymentDelay(isCustomerUser(userType))}
              field="required"
              excludeDates={getBankHolidaysDates(bankHolidays)}
              onlyWeekdays
            />
            <Field
              name="additionalInfo"
              component={FieldInput}
              label={PAYMENTS.ADDITIONAL_INFORMATION}
              field="optional"
            />
          </Section>
        )}
      </Form>
    );
  }
}

export { RequestSettlementFormLayout };
