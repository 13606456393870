import axios from 'axios';

import { showSnackbar } from 'actions/shared/snackbar';

import {
  successOptions,
  dangerOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { parseLocationData } from 'shared/helpers/dataParsers/location/parseLocationData';

import { history } from 'utils/history';

const API_HOST_AUTH = process.env.REACT_APP_API;

const createLocationActionType = 'CREATE_LOCATION';
function createLocationAction() {
  return {
    type: createLocationActionType,
  };
}

const createLocationSuccessActionType = 'CREATE_LOCATION_SUCCESS';
function createLocationSuccessAction() {
  return {
    type: createLocationSuccessActionType,
  };
}

const createLocationFailureActionType = 'CREATE_LOCATION_FAILURE';
function createLocationFailureAction() {
  return {
    type: createLocationFailureActionType,
  };
}

function createLocation(location, relatedCompany) {
  return dispatch => {
    dispatch(createLocationAction());

    return axios({
      method: 'post',
      url: `${API_HOST_AUTH}/locations`,
      data: parseLocationData(location, relatedCompany),
    })
      .then(res => {
        dispatch(createLocationSuccessAction());
        dispatch(showSnackbar(successOptions, res.data.message));
        history.push(`/companies/${relatedCompany.value}/locations/${res.data.id}`);
      })
      .catch(error => {
        dispatch(createLocationFailureAction());
        dispatch(
          showSnackbar(dangerOptions, `Location has not been created. ${error.data.message}`),
        );
      });
  };
}

export {
  createLocationActionType,
  createLocationSuccessActionType,
  createLocationFailureActionType,
  createLocation,
};
