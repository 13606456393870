import React, { memo, forwardRef, useCallback } from 'react';
import Select, { components } from 'react-select';
import styled from 'styled-components';

import { styles } from 'shared/components/Fields/FieldSelect/FieldSelect';
import { FIELD_SELECT } from 'shared/components/Fields/FieldSelect/constants';
import { withTinyFieldWrapper } from 'shared/components/Fields/TinyFieldWrapper/TinyFieldWrapper';
import { humanize } from 'shared/helpers/parsers/text';
import { themed, isTouchScreenDevice, scrollbar } from 'shared/helpers/styling/styling';

const normalizeValue = value =>
  typeof value === 'string' && value ? { label: humanize(value), value } : value;

const MenuList = styled(components.MenuList)`
  ${scrollbar}
`;

const FieldSelect = memo(
  withTinyFieldWrapper(
    forwardRef(({ input, field, disabled, clearable, multi, meta, ...rest }, ref) => {
      const onChange = value => {
        input.onChange(value);
        isTouchScreenDevice() && input.onBlur(value);
      };
      const onBlur = () => !isTouchScreenDevice() && input.onBlur(input.value);

      const customFilterOption = useCallback(
        (option, rawInput) => option.label.toLowerCase().includes(rawInput.toLowerCase()),
        [],
      );

      return (
        <Select
          {...rest}
          {...input}
          ref={ref}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={e => input.onFocus(e, input.name)}
          components={{ MenuList }}
          value={normalizeValue(input.value)}
          hasError={meta.touched && meta.error}
          styles={styles}
          isDisabled={disabled}
          isSearchable={!isTouchScreenDevice()}
          isClearable={clearable === undefined || clearable}
          isMulti={multi}
          noOptionsMessage={() => FIELD_SELECT.NO_OPTION_MESSAGE}
          filterOption={customFilterOption}
        />
      );
    }),
  ),
);

const TinySelectFieldStyled = styled(FieldSelect).attrs(themed())``;

export { TinySelectFieldStyled as TinyFieldSelect };
