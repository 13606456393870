import PropTypes from 'prop-types';
import React from 'react';

import { formatDate } from 'shared/helpers/parsers/formaters';

import { isWest } from 'utils/versioning/Guards';

import { DetailsField } from '../DetailsField';

const timeZone = isWest ? 'PT' : 'ET';

const DetailsDateField = ({ value, dateFormat, displayTimeZone, ...props }) => (
  <DetailsField
    value={value && `${formatDate(value, dateFormat)} ${displayTimeZone ? timeZone : ''}`}
    {...props}
  />
);

DetailsDateField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.number,
  icon: PropTypes.string,
  dateFormat: PropTypes.string,
  displayTimeZone: PropTypes.bool,
};

DetailsDateField.defaultProps = {
  icon: 'icon-calendar',
  displayTimeZone: false,
};

export { DetailsDateField };
