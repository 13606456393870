import {
  getBlanketHedgeCountActionType,
  getBlanketHedgeCountSuccessActionType,
  getBlanketHedgeCountFailureActionType,
  clearBlanketHedgeCountActionType,
} from 'actions/Companies/getBlanketHedgeCount';

const INITIAL_STATE = {
  isPending: false,
  blanketHedgeCount: null,
};

const getBlanketHedgeCountReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getBlanketHedgeCountActionType:
      return {
        ...state,
        isPending: true,
      };
    case getBlanketHedgeCountSuccessActionType:
      return {
        blanketHedgeCount: action.payload.blanketHedgeCount,
        isPending: false,
      };
    case getBlanketHedgeCountFailureActionType:
    case clearBlanketHedgeCountActionType:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};

export { getBlanketHedgeCountReducer };
