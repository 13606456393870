import axios from 'axios';

import { parseUserFilters } from 'shared/helpers/dataParsers/user/parseUserFilters';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getUsersActionType = 'GET_USERS';
function getUsersAction() {
  return {
    type: getUsersActionType,
  };
}

const getUsersSuccessActionType = 'GET_USERS_SUCCESS';
function getUsersSuccessAction({ docs, pages, limit, page }) {
  return {
    type: getUsersSuccessActionType,
    payload: {
      users: docs,
      pages,
      limit,
      page,
      items: docs,
    },
  };
}

const getUsersFailureActionType = 'GET_USERS_FAILURE';
function getUsersFailureAction(errorMessage) {
  return {
    type: getUsersFailureActionType,
    payload: {
      errorMessage,
    },
  };
}

const clearUsersListActionType = 'CLEAR_USERS_LIST_ACTION';
function clearUsersList() {
  return {
    type: clearUsersListActionType,
  };
}

const getUsersForSettleOuncesSuccessActionType = 'GET_USERS_FOR_SETTLEMENTS_SUCCESS';
function getUsersForSettleOuncesSuccessAction(companyId, users) {
  return {
    type: getUsersForSettleOuncesSuccessActionType,
    payload: {
      [companyId]: [...users],
    },
  };
}

function getUsers(limit, page, searchFilter, userFilters) {
  return dispatch => {
    dispatch(getUsersAction());

    return axios({
      method: 'GET',
      url: `${API_HOST_AUTH}/users`,
      params: parseUserFilters(userFilters, limit, page, searchFilter || ''),
    })
      .then(({ data }) => dispatch(getUsersSuccessAction(data)))
      .catch(() => dispatch(getUsersFailureAction('')));
  };
}

function getUsersForSettleOunces(userFilters) {
  return dispatch => {
    dispatch(getUsersAction());

    return axios({
      method: 'GET',
      url: `${API_HOST_AUTH}/users`,
      params: parseUserFilters(userFilters, 0, 1, ''),
    })
      .then(({ data }) =>
        dispatch(getUsersForSettleOuncesSuccessAction(userFilters.relatedCompany, data.docs)),
      )
      .catch(() => dispatch(getUsersFailureAction('')));
  };
}

export {
  getUsersActionType,
  getUsersSuccessActionType,
  getUsersFailureActionType,
  clearUsersListActionType,
  getUsers,
  clearUsersList,
  getUsersForSettleOunces,
  getUsersForSettleOuncesSuccessActionType,
  getUsersForSettleOuncesSuccessAction,
};
