import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { Button } from 'shared/components/Buttons/Button';
import { Icon } from 'shared/components/Icon/Icon';
import { Legend } from 'shared/components/Legend';
import { StyledListCollapse } from 'shared/components/ListCollapse/StyledListCollapse/StyledListCollapse';
import { SearchComponent } from 'shared/components/Search/Search';
import { cssVar, media, pxToRem } from 'shared/helpers/styling/styling';

const OverviewListSectionWrapper = styled.section`
  margin: 4rem 0;
  @media print {
    margin: 1.5rem 0;
    break-inside: avoid;
    ${({ total }) =>
      total > 0 &&
      css`
        border: 2px solid ${cssVar('blackGRG')};
      `}
  }

  & > *:nth-child(2) {
    ${({ disableTransform, isOpen }) =>
      disableTransform
        ? css`
            ${!isOpen && 'display: none;'}
          `
        : css`
            transform-origin: top;
            transform: scaleY(${isOpen ? '1' : '0'});
            max-height: ${isOpen ? '3000000px' : '0'};
            transition-timing-function: ease;
            transition-duration: 0.3s;
            transition-property: transform, max-height;
          `}
  }

  ${Legend} {
    display: ${({ isOpen }) => !isOpen && 'none'};
  }
`;

const OverviewListSection = styled.div`
  box-sizing: border-box;
  background-color: ${cssVar('outerSpaceTabs')};
  @media print {
    border-bottom: 2px solid ${cssVar('blackGRG')};
  }

  ${({ isOpen }) =>
    !isOpen &&
    css`
      border-bottom: 1px solid ${cssVar('riverBed')};
    `}
  ${({ total }) =>
    total === 0
      ? css`
          border: 2px dashed ${cssVar('riverBed')};
          background-color: transparent;
          @media print {
            display: none;
          }

          ${StyledListCollapse} {
            opacity: 0.5;
            cursor: auto;
          }
        `
      : css`
          border-top: 1px solid ${cssVar('riverBed')};
          border-left: 1px solid ${cssVar('riverBed')};
          border-right: 1px solid ${cssVar('riverBed')};

          @media print {
            border-top: none;
            border-left: none;
            border-right: none;
          }
        }`}
  & > div {
    display: flex;
    justify-content: space-between;
    padding: 2rem 1rem;
    flex-wrap: wrap;
    align-items: center;

    ${media.mobile`
      padding: 0;
      justify-content: center;

      ${Button} {
        margin-bottom: 40px;
      }
    `}
  }

  & > div > div {
    display: flex;

    ${media.mobile`
      flex-direction: column;
      justify-content: center;
      width: 100%;
      height: fit-content;
      min-height: 110px;
      padding-left: 10%;

      ${SearchComponent} {
        margin: 20px 0 20px 0;
      }

      ${Button}, .ExtendableButton {
        width: 80%;
        margin-bottom: 20px;
      }

      &:nth-child(2) {
        border-top: 1px solid rgba(255, 255, 255, .15);
      }
    `}
    ${media.phone`
      ${SearchComponent} {
        margin-left: 0;
      }

      ${Button}, .ExtendableButton {
        margin-left: 10px;
      }
    `}
    ${SearchComponent} {
      margin-right: 10px;
    }

    ${Button} {
      padding-right: 0.4rem;
      margin-right: 0.5rem;

      &:not(:only-child):first-child {
        ${Icon} {
          margin-left: ${pxToRem(25)};
        }
      }

      ${media.mobile`
        margin-bottom: 20px;
      `}
    }
  }
`;

OverviewListSection.propTypes = {
  isOpen: PropTypes.bool,
  total: PropTypes.number,
};

export { OverviewListSection, OverviewListSectionWrapper };
