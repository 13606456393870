import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const validateTokenActionType = 'VALIDATE_TOKEN';
const validateTokenAction = () => ({
  type: validateTokenActionType,
});

const validateTokenSuccessActionType = `${validateTokenActionType}_SUCCESS`;
const validateTokenSuccessAction = info => ({
  type: validateTokenSuccessActionType,
  payload: {
    info,
  },
});

const validateTokenFailureActionType = `${validateTokenActionType}_FAILURE`;
const validateTokenFailureAction = errorMessage => ({
  type: validateTokenFailureActionType,
  payload: {
    errorMessage,
  },
});

const validateToken = (purpose, token) => dispatch => {
  dispatch(validateTokenAction());

  return axios
    .get(`${API_HOST_AUTH}/users/validate-token/${purpose}/${token}`)
    .then(res => {
      dispatch(validateTokenSuccessAction(res.data));
      return res.data;
    })
    .catch(err => Promise.reject(dispatch(validateTokenFailureAction(err))));
};

export {
  validateTokenActionType,
  validateTokenAction,
  validateTokenSuccessActionType,
  validateTokenSuccessAction,
  validateTokenFailureActionType,
  validateTokenFailureAction,
  validateToken,
};
