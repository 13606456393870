import React from 'react';
import { connect } from 'react-redux';

import { paymentMarkAsPaid } from 'actions/Payments/paymentMarkAsPaid';
import { openModal } from 'actions/shared/modal';
import { showSnackbar } from 'actions/shared/snackbar';

import { ModalCustom } from 'components/ModalCustom/ModalCustom';

import { snackbarOptions } from 'shared/helpers/matchers/snackbar/snackbarOptions';

import { PaymentMarkAsPaidContent } from './PaymentMarkAsPaidContent/PaymentMarkAsPaidContent';

function PaymentMarkAsPaidLayout(props) {
  const resolveAction = res => props.showSnackbar(snackbarOptions(res.messageType), res.message);

  const markAsPaid = ({ paidAt }) => {
    props.paymentMarkAsPaid(props.payment._id, paidAt).then(resolveAction).catch(resolveAction);
  };

  return (
    <ModalCustom isOpen={props.open} modalClose={props.toggle}>
      <PaymentMarkAsPaidContent onSubmit={markAsPaid} />
    </ModalCustom>
  );
}

const mapDispatchToProps = {
  openModal,
  showSnackbar,
  paymentMarkAsPaid,
};

const PaymentMarkAsPaid = connect(null, mapDispatchToProps)(PaymentMarkAsPaidLayout);

export { PaymentMarkAsPaid, PaymentMarkAsPaidLayout };
