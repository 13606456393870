import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { Breadcrumbs } from './Breadcrumbs';

const mapStateToProps = ({ notificationDetails, auth }) => ({
  notificationDetails,
  auth,
});

const ConnectedBreadcrumbs = withRouter(connect(mapStateToProps)(Breadcrumbs));

export { ConnectedBreadcrumbs };
