import { acceptForGradingReducer } from './acceptForGrading';
import { acceptForMixingReducer } from './acceptForMixing';
import { acceptForProcessingReducer } from './acceptForProcessing';
import { assayResultsDetailsReducer } from './assayResultsDetails';
import { changeDeliveryDateReducer } from './changeDeliveryDate';
import { checkCustomLotNumberReducer } from './checkCustomLotNumber';
import { compareBarcodesReducer } from './compareBarcodes';
import CreateLotReducer from './createLot';
import CreateVirtualLotReducer from './createVirtualLot';
import { deleteLotReducer } from './deleteLot';
import { deleteVirtualLotReducer } from './deleteVirtualLot';
import { enterAssayResultsReducer } from './enterAssayResults';
import { finalInvoiceReducer } from './finalInvoice';
import { finalInvoiceMaximumOuncesReducer } from './finalInvoiceMaximumOunces';
import { generateProfitLossReducer } from './generateProfitLoss';
import GetAssignedLotsReducer from './getAssignedLots';
import GetLotsSectionReducer from './getLotsSection';
import { profitLossDataReducer } from './getProfitLossData';
import { getTimelineReducer } from './getTimeline';
import { getVirtualLotReducer } from './getVirtualLot';
import { warehouseDashboardCountDataReducer } from './getWarehouseDashboardCountData';
import { warehouseDashboardDataReducer } from './getWarehouseDashboardData';
import { holdLotReducer } from './holdLot';
import incomingLotsOverviewReducer from './incomingLotsOverview';
import LotDetailsReducer from './lotDetails';
import { LotInspectionsReducer } from './lotInspections';
import LotsAddFilesReducer from './lotsAddFiles';
import LotsFiltersReducer from './lotsFilters';
import LotsListReducer from './lotsList';
import { manageGlobalFilesReducer } from './manageGlobalFiles';
import { markGradingCompleteReducer } from './markGradingComplete';
import { markLotAsReceivedReducer } from './markLotAsReceived';
import { markProcessingCompleteReducer } from './markProcessingComplete';
import { mixingOverviewReducer } from './mixingOverview';
import { openFinalLotsReducer } from './openFinalLots';
import outgoingLotsOverviewReducer from './outgoingLotsOverview';
import { PrintLotLabelReducer } from './printLotLabel';
import { processingOverviewReducer } from './processingOverview';
import { releaseLotReducer } from './releaseLot';
import { updateColorReducer } from './updateColor';
import UpdateLotReducer from './updateLot';
import { updateLotLocationReducer } from './updateLotLocation';
import { updateVirtualLotReducer } from './updateVirtualLot';
import { uploadBillOfLadingReducer } from './uploadBillOfLading';
import { uploadCountSheetReducer } from './uploadCountSheet';
import { uploadCustomFinalInvoiceReducer } from './uploadCustomFinalInvoice';
import { uploadGradingReceiptReducer } from './uploadGradingReceipt';

const lotsRootReducer = {
  lotCreate: CreateLotReducer,
  virtualLotCreate: CreateVirtualLotReducer,
  updateLot: UpdateLotReducer,
  getVirtualLot: getVirtualLotReducer,
  updateVirtualLot: updateVirtualLotReducer,
  lotDetails: LotDetailsReducer,
  lotsSections: GetLotsSectionReducer,
  lotsFilters: LotsFiltersReducer,
  lotsList: LotsListReducer,
  lotsFiles: LotsAddFilesReducer,
  assignedLots: GetAssignedLotsReducer,
  incomingLotsOverview: incomingLotsOverviewReducer,
  outgoingLotsOverview: outgoingLotsOverviewReducer,
  deleteLot: deleteLotReducer,
  deleteVirtualLot: deleteVirtualLotReducer,
  processingOverview: processingOverviewReducer,
  mixingOverview: mixingOverviewReducer,
  lotInspections: LotInspectionsReducer,
  markLotAsReceived: markLotAsReceivedReducer,
  acceptForGrading: acceptForGradingReducer,
  markGradingComplete: markGradingCompleteReducer,
  acceptForProcessing: acceptForProcessingReducer,
  updateLotLocation: updateLotLocationReducer,
  updateColor: updateColorReducer,
  acceptForMixing: acceptForMixingReducer,
  markProcessingComplete: markProcessingCompleteReducer,
  enterAssayResultsDetails: enterAssayResultsReducer,
  assayResultsDetails: assayResultsDetailsReducer,
  finalInvoiceDetails: finalInvoiceReducer,
  finalInvoiceMaximumOunces: finalInvoiceMaximumOuncesReducer,
  manageGlobalFiles: manageGlobalFilesReducer,
  uploadCustomFinalInvoiceFile: uploadCustomFinalInvoiceReducer,
  uploadCountSheetFile: uploadCountSheetReducer,
  uploadGradingReceiptFile: uploadGradingReceiptReducer,
  compareBarcodes: compareBarcodesReducer,
  profitLossData: profitLossDataReducer,
  generateProfitLossDetails: generateProfitLossReducer,
  changeDelivery: changeDeliveryDateReducer,
  getTimeline: getTimelineReducer,
  printLotLabel: PrintLotLabelReducer,
  uploadBillOfLadingFile: uploadBillOfLadingReducer,
  checkCustomLotNumber: checkCustomLotNumberReducer,
  warehouseDashboardData: warehouseDashboardDataReducer,
  warehouseDashboardCountData: warehouseDashboardCountDataReducer,
  holdLot: holdLotReducer,
  releaseLot: releaseLotReducer,
  openFinalLots: openFinalLotsReducer,
};

export { lotsRootReducer };
