import { connect } from 'react-redux';

import { acceptForGrading } from 'actions/Lots/acceptForGrading';
import { getLot } from 'actions/Lots/lotDetails';
import { openModal } from 'actions/shared/modal';
import { showSnackbar } from 'actions/shared/snackbar';

import { acceptLotForGradingModal } from 'shared/helpers/constants/modalConstants';
import {
  dangerOptions,
  successOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

function AcceptForGradingBase(props) {
  const acceptForGradingAction = () => {
    props.openModal(acceptLotForGradingModal, () => {
      props
        .acceptForGrading(props.lot._id)
        .then(res => {
          props.showSnackbar(successOptions, res.message);
          props.onSuccess && props.onSuccess();
        })
        .catch(err => {
          props.getLot(props.lot._id);
          props.showSnackbar(dangerOptions, err.message);
        });
    });
  };

  return props.children({ acceptForGrading: acceptForGradingAction });
}

const mapDispatchToProps = {
  openModal,
  showSnackbar,
  acceptForGrading,
  getLot,
};

const AcceptForGrading = connect(null, mapDispatchToProps)(AcceptForGradingBase);

export { AcceptForGrading, AcceptForGradingBase };
