import styled from 'styled-components';

import { media } from 'shared/helpers/styling/styling';

export const StyledLogoutComponent = styled.div`
  display: inline-block;
  vertical-align: middle;
  width: 540px;
  background: var(--outerSpace);
  padding: 46px 15px 70px 15px;

  ${media.phone`
    width: 100%;
    padding-bottom: 66px;
  `}

  ${media.smallTablet`
    width: 332px;
    padding-bottom: 66px;
  `}
`;

export const StyledLogoutWrapper = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
`;

export const StyledLogoutContent = styled.div`
  ${media.phone`
    padding-bottom: 66px;
  `}

  ${media.smallTablet`
    padding-bottom: 66px;
  `}
`;
