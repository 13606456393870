import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { cssVar, pxToRem } from 'shared/helpers/styling/styling';

const Hint = styled.span.attrs(({ hint, isClickable, input, dataTestId }) => ({
  children: hint && `${hint.label ? `${hint.label}: ` : ''}${hint.value}`,
  onClick: isClickable && input ? () => input.onChange(hint.value) : null,
  'data-testid': dataTestId,
}))`
  display: flex;
  align-items: center;
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'default')};
  margin: ${pxToRem(3)} auto;
  padding: ${pxToRem(3)} ${pxToRem(12)};
  color: ${cssVar('whiteGRG')};
  font-size: 0.625rem;
  background-color: ${cssVar('slateGrayDarker')};
  border-radius: 5px;

  ${({ isClickable }) =>
    isClickable &&
    css`
      :hover {
        background-color: ${cssVar('gullGray')};
      }
    `}
`;

Hint.propTypes = {
  hint: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.any,
  }),
  isClickable: PropTypes.bool,
  dataTestId: PropTypes.string,
};

export { Hint };
