import {
  updateTermsActionType,
  updateTermsFailureActionType,
  updateTermsSuccessActionType,
} from 'actions/Terms/updateTerms';

const INITIAL_STATE = {
  isPending: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case updateTermsActionType:
      return {
        isPending: true,
      };
    case updateTermsFailureActionType:
      return {
        isPending: false,
      };
    case updateTermsSuccessActionType:
      return {
        isPending: false,
      };
    default:
      return state;
  }
};
