import axios from 'axios';
import FormData from 'form-data';

import { messageTypes } from 'shared/helpers/constants/snackbar/snackbarMessageTypes';

const API_HOST_AUTH = process.env.REACT_APP_API;

const uploadTermsDocumentSuccessActionType = 'UPLOAD_TERMS_DOCUMENT_SUCCESS';
function uploadTermsDocumentSuccessAction(terms) {
  return {
    type: uploadTermsDocumentSuccessActionType,
    payload: { terms },
  };
}

const uploadTermsDocumentFailureActionType = 'UPLOAD_TERMS_DOCUMENT_FAILED';
function uploadTermsDocumentFailureAction() {
  return { type: uploadTermsDocumentFailureActionType };
}

const termsDocumentAddedActionType = 'UPLOAD_TERMS_DOCUMENT_ADDED';
function termsDocumentAddedAction(files) {
  return {
    type: termsDocumentAddedActionType,
    payload: { files },
  };
}

const termsDocumentClearActionType = 'UPLOAD_TERMS_DOCUMENT_CLEAR';
function termsDocumentClearAction() {
  return { type: termsDocumentClearActionType };
}

const uploadTermsDocumentActionType = 'UPLOAD_TERMS_DOCUMENT';
function uploadTermsDocumentAction() {
  return { type: uploadTermsDocumentActionType };
}

function uploadTermsDocument(id, files) {
  return dispatch => {
    dispatch(uploadTermsDocumentAction());

    const data = new FormData();
    data.append('terms', id);
    files.forEach(val => data.append('document', val));

    return axios({
      method: 'post',
      url: `${API_HOST_AUTH}/terms/${id}/uploadDocument`,
      headers: { 'Content-Type': 'multipart/form-data' },
      data,
    })
      .then(res => {
        const { terms, message } = res.data;
        dispatch(uploadTermsDocumentSuccessAction(terms));
        return {
          terms,
          message,
          messageType: messageTypes.success,
        };
      })
      .catch(error => {
        const { message } = error.data;
        dispatch(uploadTermsDocumentFailureAction());
        return {
          message,
          messageType: messageTypes.error,
        };
      });
  };
}

export {
  uploadTermsDocumentActionType,
  uploadTermsDocument,
  uploadTermsDocumentSuccessActionType,
  uploadTermsDocumentFailureActionType,
  termsDocumentAddedAction,
  termsDocumentAddedActionType,
  termsDocumentClearAction,
  termsDocumentClearActionType,
};
