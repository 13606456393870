import { capitalizeGlobal, replaceEmpty, humanize } from 'shared/helpers/parsers/text';
import { formatDate } from 'shared/helpers/parsers/formaters';
import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';
import { SHARED } from 'shared/helpers/constants/sharedConstants';
import { setDesignation } from 'shared/helpers/dataParsers/lot/setDesignation';

const itemsTemplate = [
  {
    label: LOTS.GRG_LOT_NUMBER,
    render: ({ grgLotNumber, customLotNumber, designation }) => {
      const lotName = setDesignation(grgLotNumber, designation);

      return (!customLotNumber && lotName) || [lotName, customLotNumber];
    },
    highlight: true,
    includeRegExp: true,
  },
  {
    label: LOTS.STATUS_FOR_GLOBAL,
    render: ({ statusForGlobal }) => capitalizeGlobal(humanize(statusForGlobal)),
  },
  {
    label: LOTS.RECEIVED_ON,
    render: ({ receivedAt, packagesNotReceivedSameDay }) =>
      `${replaceEmpty(formatDate(receivedAt))} ${packagesNotReceivedSameDay ? '*' : ''}`,
  },
  {
    label: LOTS.BUSINESS_DAYS_OUT,
    render: ({ businessDaysOut }) =>
      `${businessDaysOut} ${businessDaysOut === 1 ? SHARED.DAY : SHARED.DAYS}`,
  },
];

const itemsTemplateMobile = [
  {
    label: LOTS.GRG_LOT_NUMBER,
    render: ({ grgLotNumber, customLotNumber, designation }) => {
      const lotName = setDesignation(grgLotNumber, designation);

      return (!customLotNumber && lotName) || [lotName, customLotNumber];
    },
    highlight: true,
    includeRegExp: true,
  },
  {
    label: LOTS.BUSINESS_DAYS_OUT,
    render: ({ businessDaysOut }) =>
      `${businessDaysOut} ${businessDaysOut === 1 ? SHARED.DAY : SHARED.DAYS}`,
  },
];

const itemsDetailsTemplate = [
  {
    label: LOTS.STATUS_FOR_GLOBAL,
    render: ({ statusForGlobal }) => capitalizeGlobal(humanize(statusForGlobal)),
  },
  {
    label: LOTS.RECEIVED_ON,
    render: ({ receivedAt, packagesNotReceivedSameDay }) =>
      `${replaceEmpty(formatDate(receivedAt))} ${packagesNotReceivedSameDay ? '*' : ''}`,
  },
];

export { itemsTemplate, itemsTemplateMobile, itemsDetailsTemplate };
