import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { destroy } from 'redux-form';

import { generateProfitLoss } from 'actions/Lots/generateProfitLoss';
import { getProfitLossData, clearProfitLossData } from 'actions/Lots/getProfitLossData';
import { openModal } from 'actions/shared/modal';
import { showSnackbar } from 'actions/shared/snackbar';

import { ContentLoader } from 'shared/components/ContentLoader/ContentLoader';
import { Preloader } from 'shared/components/Preloader/Preloader';
import { Wizard, formName } from 'shared/components/Wizard/Wizard';
import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';
import { cancelModal, kitcoUnavailableModal } from 'shared/helpers/constants/modalConstants';
import {
  dangerOptions,
  successOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { humanize } from 'shared/helpers/parsers/text';

import {
  AdditionalCommoditiesForm,
  CurrentCommoditiesForm,
  LotForm,
  LotAdjustmentsForm,
} from './components';

const transformCurrentCommodities = data => ({
  ...data,
  material: data.materialWeight || data.materialUnits,
  materialType: humanize(data.materialType),
  fromLot: data.fromLot.grgLotNumber,
  toLot: data.toLot.grgLotNumber,
});

const steps = data => ({
  ...(data.commodities &&
    data.commodities.length && {
      currentCommodities: CurrentCommoditiesForm,
    }),
  additionalCommodities: AdditionalCommoditiesForm,
  lot: LotForm,
  lotAdjustments: LotAdjustmentsForm,
});

const initialValues = data => ({
  ...(data.commodities &&
    data.commodities.length && {
      currentCommodities: {
        commodities: data.commodities.map(transformCurrentCommodities),
      },
    }),
  additionalCommodities: {},
  lot: {
    paidByGlobal: data.paidByGlobal,
  },
});

const GenerateProfitLossLayout = ({ match, ...props }) => {
  const { data, isPending } = useSelector(({ profitLossData }) => profitLossData);
  const generateProfitLossDetails = useSelector(state => state.generateProfitLossDetails);
  const dispatch = useDispatch();

  useEffect(() => () => dispatch(destroy(formName)), []);

  useEffect(() => {
    dispatch(getProfitLossData(match.params.id));

    return () => dispatch(clearProfitLossData());
  }, []);

  const onGenerateProfitLossSuccess = message => {
    dispatch(showSnackbar(successOptions, message));
    props.history.push(`/lots/list/${match.params.id}`);
  };

  const onGenerateProfitLossFailure = ({
    message,
    kitcoUnavailableError,
    values,
    fromKitcoSource,
  }) => {
    if (fromKitcoSource && kitcoUnavailableError) {
      return dispatch(
        openModal(kitcoUnavailableModal, () => onSubmit({ fromKitcoSource: false })(values)),
      );
    }

    dispatch(showSnackbar(dangerOptions, message));
  };

  const onSubmit = useCallback(
    ({ fromKitcoSource }) =>
      values => {
        dispatch(generateProfitLoss({ values, data, lotId: match.params.id, fromKitcoSource }))
          .then(({ message }) => onGenerateProfitLossSuccess(message))
          .catch(({ message, kitcoUnavailableError }) =>
            onGenerateProfitLossFailure({
              fromKitcoSource,
              message,
              kitcoUnavailableError,
              values,
            }),
          );
      },
    [match.params.id, props],
  );

  const onCancel = useCallback(() => {
    dispatch(
      openModal(cancelModal, () => {
        props.history.push(`/lots/list/${match.params.id}`);
      }),
    );
  }, []);

  return (
    <ContentLoader loaded={data && !isPending}>
      {data && (
        <Wizard
          steps={steps(data)}
          initialValues={initialValues(data)}
          header={LOTS.GENERATE_PROFIT_LOSS}
          onSubmit={onSubmit({ fromKitcoSource: true })}
          onCancel={onCancel}
        />
      )}
      {<Preloader loading={generateProfitLossDetails.isPending} />}
    </ContentLoader>
  );
};

const GenerateProfitLoss = compose(withRouter)(GenerateProfitLossLayout);

export { GenerateProfitLossLayout, GenerateProfitLoss };
