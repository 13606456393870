import { capitalize, humanize } from 'shared/helpers/parsers/text';

function parseMultiplePackagesData(packages) {
  return packages?.map(pkg => ({
    assignedLot: pkg.assignedLot,
    customLotNumber: pkg.customLotNumber,
    customerInstructions: pkg.customerInstructions,
    materialTypeDeclared: pkg.materialTypeDeclared,
    labelId: pkg.labelId,
    plant: pkg.plant,
    packageType: pkg.packageType.value,
    unitCount: pkg.unitCount,
    weightGrossDeclared: pkg.weightGrossDeclared,
    weightNetDeclared: pkg.weightNetDeclared,
    weightTareDeclared: pkg.weightTareDeclared,
    packageNumber: pkg.packageNumber,
    _id: pkg._id,
  }));
}

function parseEditMultiplePackagesData(packages) {
  return packages?.map(pkg => ({
    assignedLot: pkg.assignedLot,
    customLotNumber: pkg.customLotNumber,
    customerInstructions: pkg.customerInstructions,
    materialTypeDeclared: {
      value: pkg.materialTypeDeclared,
      label: humanize(pkg.materialTypeDeclared),
    },
    labelId: pkg.labelId,
    plant: pkg.plant,
    edited: pkg.edited,
    packageType: {
      value: pkg.packageType,
      label: capitalize(pkg.packageType),
    },
    unitCount: pkg.unitCount,
    weightGrossDeclared: pkg.weightGrossDeclared,
    weightNetDeclared: pkg.weightNetDeclared,
    weightTareDeclared: pkg.weightTareDeclared,
    packageNumber: pkg.packageNumber,
    _id: pkg._id,
  }));
}

export { parseMultiplePackagesData, parseEditMultiplePackagesData };
