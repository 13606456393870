import {
  accessLevelFilterActionType,
  setInitialUserFiltersActionType,
  userFiltersClearActionType,
  userStatusFilterActionType,
  userTypeFilterActionType,
} from 'actions/Users/userFilters';

import { initialStatuses } from 'shared/helpers/constants/filters/initialStatuses';

const INITIAL_STATE = {
  status: Object.assign([], initialStatuses),
  userType: '',
  accessLevel: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case userStatusFilterActionType:
      return {
        ...state,
        status: action.payload.userStatus,
      };
    case userTypeFilterActionType:
      return {
        ...state,
        userType: action.payload.userType,
        accessLevel: [],
      };
    case accessLevelFilterActionType:
      return {
        ...state,
        accessLevel: action.payload.accessLevel,
      };
    case userFiltersClearActionType:
      return {
        status: [],
        userType: '',
        accessLevel: [],
      };
    case setInitialUserFiltersActionType:
      return {
        status: Object.assign([], initialStatuses),
        userType: '',
        accessLevel: [],
      };
    default:
      return state;
  }
};
