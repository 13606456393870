import axios from 'axios';

import { clearLotDetails } from './lotDetails';

const API_HOST_AUTH = process.env.REACT_APP_API;

const generateShipmentSummaryType = 'GENERATE_SHIPMENT_SUMMARY';
const generateShipmentSummaryAction = () => ({
  type: generateShipmentSummaryType,
});

const generateShipmentSummarySuccessType = `${generateShipmentSummaryType}_SUCCESS`;
const generateShipmentSummarySuccessAction = lot => ({
  type: generateShipmentSummarySuccessType,
  payload: {
    ...lot,
  },
});

const generateShipmentSummaryFailureType = `${generateShipmentSummaryType}_FAILURE`;
const generateShipmentSummaryFailureAction = () => ({
  type: generateShipmentSummaryFailureType,
});

const generateShipmentSummary = (lotId, grgShipmentSummaryType) => dispatch => {
  dispatch(generateShipmentSummaryAction());

  return axios
    .post(`${API_HOST_AUTH}/lots/generate/shipment-summary`, { lotId, grgShipmentSummaryType })
    .then(res => {
      dispatch(generateShipmentSummarySuccessAction(res.data.lot));
      dispatch(clearLotDetails());

      return res.data;
    })
    .catch(err => {
      dispatch(generateShipmentSummaryFailureAction());

      return Promise.reject(err.data);
    });
};

export {
  generateShipmentSummaryType,
  generateShipmentSummarySuccessType,
  generateShipmentSummaryFailureType,
  generateShipmentSummary,
};
