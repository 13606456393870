import {
  customerSections,
  gradingSections,
  customerSectionsEnum,
} from 'shared/helpers/constants/lots/sections';
import { humanizeCamelCase } from 'shared/helpers/parsers/text';

import { isCustomerUser, isGradingUser } from '../checkUserType';

function parseSectionName(section) {
  if (section === customerSectionsEnum.received) {
    return 'Received by Global';
  }
  return humanizeCamelCase(section);
}

function isMaterialDeliveryRequired(section) {
  return section === customerSectionsEnum.materialDeliveryRequired;
}

function lotsSections(userType) {
  if (isCustomerUser(userType)) return customerSections;
  if (isGradingUser(userType)) return gradingSections;
  return null;
}

export { parseSectionName, isMaterialDeliveryRequired, lotsSections };
