import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  clearAssignedLotsAction,
  getLotsForRequestFinalPayment,
} from 'actions/Lots/getAssignedLots';
import { requestFinalPayment } from 'actions/Payments/requestPayment';
import {
  getMaximumSettlementOunces,
  clearMaximumSettlementOunces,
} from 'actions/PoolAccounts/getMaximumSettlementOunces';
import { openModal } from 'actions/shared/modal';
import { showSnackbar } from 'actions/shared/snackbar';

import { FormContainer } from 'shared/components/Form';
import { statusesForGlobal } from 'shared/helpers/constants/lots/statusesForGlobal';
import { cancelModal } from 'shared/helpers/constants/modalConstants';
import { PAYMENTS } from 'shared/helpers/constants/payments/paymentsConstants';
import { snackbarOptions } from 'shared/helpers/matchers/snackbar/snackbarOptions';

import { RequestFinalPaymentForm } from './RequestFinalPaymentForm/RequestFinalPaymentForm';

const RequestFinalPayment = ({ history }) => {
  const { _id: companyId, companyName } = useSelector(({ auth }) => auth.user.relatedCompany);
  const { isPending } = useSelector(({ requestPayment }) => requestPayment);
  const { data } = useSelector(({ assignedLots }) => assignedLots);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getLotsForRequestFinalPayment(companyId));
    return () => {
      dispatch(clearAssignedLotsAction());
      dispatch(clearMaximumSettlementOunces());
    };
  }, [dispatch, companyId]);

  const resolveAction = res => {
    dispatch(showSnackbar(snackbarOptions(res.messageType), res.message));

    if (res.id) {
      return history.push(`/payments/${res.id}`);
    }
    history.push('/payments');
  };

  const submit = values => {
    const { relatedLot } = values;

    if (relatedLot.statusForGlobal === statusesForGlobal.ship_summary_accepted) {
      return dispatch(getMaximumSettlementOunces(relatedLot._id)).then(
        ({ maximumPlatinumOz, maximumPalladiumOz, maximumRhodiumOz }) => {
          if (maximumPlatinumOz || maximumPalladiumOz || maximumRhodiumOz) {
            return history.push('/pool-accounts/settlement-for-lot-final-payment', {
              requestPayment: true,
              ...values,
            });
          }
          return dispatch(requestFinalPayment(values)).then(resolveAction).catch(resolveAction);
        },
      );
    }

    return dispatch(requestFinalPayment(values)).then(resolveAction).catch(resolveAction);
  };

  const onCancel = () => dispatch(openModal(cancelModal, () => history.push('/payments')));

  return (
    <FormContainer
      header={PAYMENTS.REQUEST_FINAL_PAYMENT}
      formLoaded={!isPending}
      subHeader={companyName}
    >
      <RequestFinalPaymentForm
        onSubmit={submit}
        onCancel={onCancel}
        data={data}
        history={history}
      />
    </FormContainer>
  );
};

export { RequestFinalPayment };
