const globalPackageClasses = {
  post_mill: 'post-mill',
  post_shears: 'post-shears',
  post_mix: 'post-mix',
};

const packageClasses = {
  incoming: 'incoming',
  ...globalPackageClasses,
};

const overviewPackageClasses = {
  ...packageClasses,
  all: 'all',
};

export { globalPackageClasses, overviewPackageClasses, packageClasses };
