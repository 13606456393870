import { checkSubmittedPoolPaymentsReducer } from './checkSubmittedPoolPayments';
import { enterPoolAccountOuncesReducer } from './enterPoolAccountOunces';
import { getGrandTotalValueReducer } from './getGrandTotalValue';
import { getMaximumSettlementOuncesReducer } from './getMaximumSettlementOunces';
import { getSummaryAvailableLotsReducer } from './getSummaryAvailableLots';
import { poolAccountDetailsReducer } from './poolAccountDetails';
import { poolAccountJournalReducer } from './poolAccountJournal';
import { poolAccountsListReducer } from './poolAccountsList';
import { requestSettlementReducer } from './requestSettlement';
import { requestSettlementWizardReducer } from './settlementForLotWizard';

const poolAccountsRootReducer = {
  poolAccountsList: poolAccountsListReducer,
  requestSettlement: requestSettlementReducer,
  summaryAvailableLots: getSummaryAvailableLotsReducer,
  maximumSettlementOunces: getMaximumSettlementOuncesReducer,
  enterPoolAccountOuncesDetails: enterPoolAccountOuncesReducer,
  grandTotalValue: getGrandTotalValueReducer,
  poolAccountJournal: poolAccountJournalReducer,
  poolAccountDetails: poolAccountDetailsReducer,
  requestSettlementWizard: requestSettlementWizardReducer,
  checkSubmittedPoolPayments: checkSubmittedPoolPaymentsReducer,
};

export { poolAccountsRootReducer };
