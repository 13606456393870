import React, { Component } from 'react';

import { RouterButton } from 'shared/components/Buttons';
import { Icon } from 'shared/components/Icon/Icon';
import { ListCollapse } from 'shared/components/ListCollapse/ListCollapse';
import {
  OverviewListSectionWrapper,
  OverviewListSection,
} from 'shared/components/OverviewList/OverviewListSection/OverviewListSection';
import { LOCATION } from 'shared/helpers/constants/locations/locationConstants';
import { USER } from 'shared/helpers/constants/users/userConstants';

import { UsersList } from './UsersList/UsersList';

class LocationUsers extends Component {
  state = {
    displayUsers: true,
  };

  usersToggle = () => {
    const {
      locationDetailsUsersList: { total },
    } = this.props;
    total !== 0 && this.setState(prevState => ({ displayUsers: !prevState.displayUsers }));
  };

  render() {
    const {
      locationDetailsUsersList,
      locationDetails: { location },
    } = this.props;

    const listCollapseLabel = Number.isFinite(locationDetailsUsersList.total)
      ? `${LOCATION.USERS} (${locationDetailsUsersList.total})`
      : LOCATION.USERS;

    return (
      <OverviewListSectionWrapper
        isOpen={this.state.displayUsers}
        total={locationDetailsUsersList.total}
      >
        <OverviewListSection
          isOpen={this.state.displayUsers}
          total={locationDetailsUsersList.total}
        >
          <div>
            <ListCollapse
              collapse={this.state.displayUsers}
              toggleList={this.usersToggle}
              label={listCollapseLabel}
            />
            <RouterButton
              id="createUser"
              type="button"
              path={`/companies/${this.props.match.params.companyId}/location/${location._id}/users/create`}
              label={USER.CREATE_A_USER}
              padding="0 10px 0 23px"
              icon={<Icon icon="icon-plus" />}
            />
          </div>
        </OverviewListSection>
        <div>
          <UsersList {...this.props} />
        </div>
      </OverviewListSectionWrapper>
    );
  }
}

export { LocationUsers };
