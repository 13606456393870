import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getCountWarehouseDataActionType = 'GET_COUNT_WAREHOUSE_DATA';
const getCountWarehouseDataAction = () => ({
  type: getCountWarehouseDataActionType,
});

const getCountWarehouseDataSuccessActionType = `${getCountWarehouseDataActionType}_SUCCESS`;
const getCountWarehouseDataSuccessAction = data => ({
  type: getCountWarehouseDataSuccessActionType,
  payload: {
    data,
  },
});
const getCountWarehouseDataFailureActionType = `${getCountWarehouseDataActionType}_FAILURE`;
const getCountWarehouseDataFailureAction = () => ({
  type: getCountWarehouseDataFailureActionType,
});

const getWarehouseCountData = () => dispatch => {
  dispatch(getCountWarehouseDataAction());

  return axios
    .get(`${API_HOST_AUTH}/lots/warehouse/count`)
    .then(res => {
      dispatch(getCountWarehouseDataSuccessAction(res.data));
    })
    .catch(() => {
      dispatch(getCountWarehouseDataFailureAction());
    });
};

export {
  getCountWarehouseDataActionType,
  getCountWarehouseDataAction,
  getCountWarehouseDataSuccessActionType,
  getCountWarehouseDataSuccessAction,
  getCountWarehouseDataFailureActionType,
  getCountWarehouseDataFailureAction,
  getWarehouseCountData,
};
