import { FieldInput } from 'shared/components/Fields';

const renderFields = config =>
  config.map(field => ({
    component: FieldInput,
    field: 'required',
    key: field.name,
    ...field,
  }));

export { renderFields };
