import * as React from 'react';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { createBankHolidays } from 'actions/Payments/bankHolidaysCreate';
import { getFinalPaymentDateValidation } from 'actions/Payments/getFinalPaymentDateValidation';
import { openModal } from 'actions/shared/modal';
import { showSnackbar } from 'actions/shared/snackbar';

import { FormContainer } from 'shared/components/Form';
import { addBankHolidaysModal, cancelModal } from 'shared/helpers/constants/modalConstants';
import { message } from 'shared/helpers/constants/payments/createBankHolidaysConstants';
import { PAYMENTS } from 'shared/helpers/constants/payments/paymentsConstants';
import {
  successOptions,
  dangerOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

import { history, goBackOrTo } from 'utils/history';

import { BankHolidaysForm } from '../BankHolidaysForm/BankHolidaysForm';

const BankHolidaysCreate = () => {
  const dispatch = useDispatch();
  const [createBankHolidayIsPending, setCreateBankHolidayIsPending] = useState(false);

  const { isPaymentDateExists, isPending } = useSelector(
    ({ getFinalPaymentDateValidation }) => getFinalPaymentDateValidation,
  );

  const addBankHolidays = async values => {
    try {
      setCreateBankHolidayIsPending(true);
      await dispatch(createBankHolidays(values)).finally(() =>
        setCreateBankHolidayIsPending(false),
      );

      dispatch(showSnackbar(successOptions, message.BANK_HOLIDAY_CREATE_SUCCESS));
      history.push('/payments/bank-holidays');
    } catch (err) {
      dispatch(showSnackbar(dangerOptions, message.BANK_HOLIDAY_CREATE_ERROR));
    }
  };

  const handleCancel = () => {
    dispatch(
      openModal(cancelModal, () => {
        goBackOrTo('/payments/bank-holidays');
      }),
    );
  };

  const handleSubmit = values => {
    dispatch(openModal(addBankHolidaysModal(isPaymentDateExists), () => addBankHolidays(values)));
  };

  const handleDateChange = async date => date && dispatch(getFinalPaymentDateValidation(date));

  return (
    <FormContainer header={PAYMENTS.BANK_HOLIDAYS.CREATE}>
      <BankHolidaysForm
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        onDateChange={handleDateChange}
        dateValidationIsPending={isPending}
        createBankHolidayIsPending={createBankHolidayIsPending}
      />
    </FormContainer>
  );
};

export { BankHolidaysCreate };
