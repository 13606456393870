import { createPackageSuccessActionType } from 'actions/Packages/createPackage';
import {
  getPackageDetailsActionType,
  getPackageDetailsSuccessActionType,
  getPackageDetailsFailureActionType,
  clearPackageDetailsActionType,
} from 'actions/Packages/packageDetails';
import {
  printPackageLabelsActionType,
  printPackageLabelsFailureActionType,
  printPackageLabelsSuccessActionType,
} from 'actions/Packages/printPackageLabels';

const INITIAL_STATE = {
  isPending: false,
  errorMessage: null,
  package: null,
  recentlyCreated: false,
};

const PackageDetailsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getPackageDetailsActionType:
      return {
        ...state,
        package: null,
        isPending: true,
      };
    case getPackageDetailsSuccessActionType:
      return {
        ...state,
        isPending: false,
        package: action.payload.package,
      };
    case getPackageDetailsFailureActionType:
      return {
        ...state,
        isPending: false,
        errorMessage: action.payload.errorMessage,
      };
    case clearPackageDetailsActionType:
      return {
        ...INITIAL_STATE,
      };
    case createPackageSuccessActionType:
      return {
        ...state,
        recentlyCreated: true,
      };
    case printPackageLabelsActionType:
      return {
        ...state,
        isPending: true,
      };
    case printPackageLabelsFailureActionType:
    case printPackageLabelsSuccessActionType:
      return {
        ...state,
        isPending: false,
      };
    default:
      return state;
  }
};

export { PackageDetailsReducer };
