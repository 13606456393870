import { DetailsField } from 'shared/components/Details/DetailsField';
import { USER } from 'shared/helpers/constants/users/userConstants';

const userProfileDetailsFieldsConfig = values => [
  {
    label: USER.RELATED_COMPANY,
    value: values.relatedCompany.companyName,
    icon: 'icon-home',
    id: 'relatedCompany',
    FieldType: DetailsField,
  },
  {
    label: USER.FIRST_NAME,
    value: values.firstName,
    icon: 'icon-person',
    id: 'firstName',
    FieldType: DetailsField,
  },
  {
    label: USER.LAST_NAME,
    value: values.lastName,
    icon: 'icon-person',
    id: 'lastName',
    FieldType: DetailsField,
  },
  {
    label: USER.EMAIL,
    value: values.email,
    icon: 'icon-email',
    id: 'email',
    FieldType: DetailsField,
  },
  {
    label: USER.OFFICE_PHONE,
    value: values.officePhone,
    icon: 'icon-phone',
    id: 'officePhone',
    FieldType: DetailsField,
  },
  {
    label: USER.PHONE_EXTENSION,
    value: values.phoneExtension,
    icon: 'icon-phone',
    id: 'phoneExtension',
    FieldType: DetailsField,
  },
  {
    label: USER.MOBILE_PHONE,
    value: values.mobilePhone,
    icon: 'icon-phone',
    id: 'mobilePhone',
    FieldType: DetailsField,
  },
];

const userProfileDetailsContainerConfig = () => ({
  header: USER.GENERAL_DETAILS,
  template: [
    'relatedCompany relatedCompany firstName lastName',
    'email officePhone phoneExtension mobilePhone',
  ],
});

export { userProfileDetailsFieldsConfig, userProfileDetailsContainerConfig };
