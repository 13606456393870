import { hedgeStatus } from 'shared/helpers/constants/hedges/hedgeStatus';
import { hedgeTypes } from 'shared/helpers/constants/hedges/hedgeType';
import { statusesForGlobal } from 'shared/helpers/constants/lots/statusesForGlobal';

const allowedHedgeStatuses = [hedgeStatus.placed, hedgeStatus.filled, hedgeStatus.reassigned];
const notAllowedHedgeTypes = [hedgeTypes.blanket];

const transferAccess = (auth, { status, hedgeType, relatedLot }) => {
  const { transferHedgeToDifferentLot } = auth.access.specialPermissions.hedges;
  return (
    transferHedgeToDifferentLot &&
    allowedHedgeStatuses.includes(status) &&
    !notAllowedHedgeTypes.includes(hedgeType) &&
    !(
      [hedgeTypes.custom, hedgeTypes.standard, hedgeTypes.internal].includes(hedgeType) &&
      relatedLot.statusForGlobal === statusesForGlobal.finalized
    )
  );
};

export { transferAccess };
