import { Route, Router, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { theme } from 'assets/styles/common/theme';

import { PDFPreview } from 'components/PDFPreview/PDFPreview';

import { RemoveSlash } from 'shared/components/RemoveSlash';

import { history } from 'utils/history';

import { LoginPage } from 'pages/Login/Login';
import { Logout } from 'pages/Logout/Logout';
import { ManualDownload } from 'pages/ManualDownload/ManualDownload';
import { ResetPassword } from 'pages/ResetPassword/ResetPassword/ResetPassword';
import { ResetPasswordRequest } from 'pages/ResetPassword/ResetPasswordRequest/ResetPasswordRequest';

import ConnectedAuthentication from '../Authentication/ConnectedAuthentication';
import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary';
import { Modal } from '../Modal/ConnectedModal';
import { NoAuthentication } from '../NoAuthentication/NoAuthentication';
import { Snackbar } from '../Snackbar/Snackbar';

function Main(props) {
  return (
    <ThemeProvider theme={theme}>
      <ErrorBoundary>
        <Router history={history}>
          <RemoveSlash />
          <Switch>
            <Route exact path="/" render={() => <NoAuthentication component={LoginPage} />} />
            <Route
              exact
              path="/password-reset"
              render={() => <NoAuthentication component={ResetPasswordRequest} />}
            />
            <Route
              exact
              path="/users/invite/:token"
              render={() => <ResetPassword buttonLabel="Set password" purpose="invite" />}
            />
            <Route
              exact
              path="/users/password-reset/:token"
              render={() => <ResetPassword buttonLabel="Reset password" purpose="passwordReset" />}
            />
            <Route path="/logout" render={() => <Logout />} />
            <Route path="/manual-download" exact render={() => <ManualDownload />} />
            <Route path="*" component={ConnectedAuthentication} />
          </Switch>
        </Router>
        <PDFPreview />
        <Modal />
        <Snackbar />
      </ErrorBoundary>
    </ThemeProvider>
  );
}

export default Main;
