import axios from 'axios';

import { showSnackbar } from 'actions/shared/snackbar';

import {
  successOptions,
  dangerOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { parseTermData } from 'shared/helpers/dataParsers/terms/parseTermData';

import { history } from 'utils/history';

const API_HOST_AUTH = process.env.REACT_APP_API;

const createTermsActionType = 'CREATE_TERMS';
function createTermsAction() {
  return {
    type: createTermsActionType,
  };
}

const createTermsSuccessActionType = 'CREATE_TERMS_SUCCESS';
function createTermsSuccessAction() {
  return {
    type: createTermsSuccessActionType,
  };
}

const createTermsFailureActionType = 'CREATE_TERMS_FAILURE';
function createTermsFailureAction() {
  return {
    type: createTermsFailureActionType,
  };
}

function createTerms(term, relatedCompany) {
  return dispatch => {
    dispatch(createTermsAction());

    return axios({
      method: 'post',
      url: `${API_HOST_AUTH}/terms`,
      data: JSON.stringify({ ...parseTermData(term), relatedCompany }),
    })
      .then(res => {
        dispatch(createTermsSuccessAction());
        dispatch(showSnackbar(successOptions, res.data.message));
        history.push(`/companies/${relatedCompany}/terms/${res.data.id}`);
      })
      .catch(error => {
        dispatch(createTermsFailureAction());
        dispatch(showSnackbar(dangerOptions, `Term has not been created. ${error.data.message}`));
      });
  };
}

export {
  createTermsActionType,
  createTermsSuccessActionType,
  createTermsFailureActionType,
  createTerms,
};
