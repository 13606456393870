import axios from 'axios';

import { showSnackbar } from 'actions/shared/snackbar';

import { dangerOptions } from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getPoolAccountsActionType = 'GET_POOL_ACCOUNTS_LIST';
const getPoolAccountsAction = (filters, pagination) => ({
  type: getPoolAccountsActionType,
  payload: { filters, pagination },
});

const getPoolAccountsSuccessActionType = `${getPoolAccountsActionType}_SUCCESS`;
const getPoolAccountsSuccessAction = poolAccounts => ({
  type: getPoolAccountsSuccessActionType,
  payload: { poolAccounts },
});

const getPoolAccountsFailureActionType = `${getPoolAccountsActionType}_FAILURE`;
const getPoolAccountsFailureAction = () => ({
  type: getPoolAccountsFailureActionType,
});

const clearPoolAccountsActionType = `${getPoolAccountsActionType}_CLEAR`;
const clearPoolAccountsAction = () => ({
  type: clearPoolAccountsActionType,
});

const getPoolAccounts = (filters, pagination) => dispatch => {
  dispatch(getPoolAccountsAction(filters, pagination));

  return axios
    .get(`${API_HOST_AUTH}/pool-accounts`, { params: { ...filters, ...pagination } })
    .then(({ data }) => {
      dispatch(getPoolAccountsSuccessAction(data));
      return data;
    })
    .catch(error => {
      dispatch(getPoolAccountsFailureAction());
      dispatch(showSnackbar(dangerOptions, error.data?.message));

      return Promise.reject(error.data);
    });
};

export {
  clearPoolAccountsAction,
  clearPoolAccountsActionType,
  getPoolAccounts,
  getPoolAccountsActionType,
  getPoolAccountsFailureActionType,
  getPoolAccountsSuccessActionType,
};
