import PropTypes from 'prop-types';
import React from 'react';

import { ModalCustom } from 'components/ModalCustom/ModalCustom';

import { PaymentReassignForm } from './PaymentReassignForm';

const PaymentReassign = ({ open, toggle, paymentId }) => {
  return (
    <ModalCustom isOpen={open} modalClose={toggle}>
      <PaymentReassignForm paymentId={paymentId} />
    </ModalCustom>
  );
};

PaymentReassign.propTypes = {
  open: PropTypes.bool,
  toggle: PropTypes.func,
  paymentId: PropTypes.string,
};

export { PaymentReassign };
