const LOCATION = {
  LOCATION: 'Location',
  LOCATIONS: 'Locations',
  ALL_LOCATIONS: 'All locations',
  CREATE_A_LOCATION: 'Create a location',
  EDIT_LOCATION: 'Edit location',
  EDIT_DEFAULT_PPMS: 'Edit default PPMs',
  SELECT_PRIMARY_LOCATION: '-- select primary location --',
  SELECT_LOCATION: '-- select location --',
  ADD_ANOTHER_LOCATION: 'Add another location',
  NAME: 'Location name',
  SUITE: 'Suite',
  PO_BOX: 'PO Box',
  COUNTRY: 'Country',
  SELECT_COUNTRY: '-- select country --',
  STATE: 'State',
  SELECT_STATE: 'State',
  CITY: 'City',
  STREET: 'Street',
  ZIP_CODE: 'Zip code',
  LOT_SUFFIX: 'Lot suffix',
  SHIPMENT_ELIGIBLE: 'Shipment eligible',
  PRIMARY_LOCATION: 'Primary location',
  PRIMARY_CONTACT: 'Primary contact',
  GENERAL_DETAILS: 'General details',
  CONTACT_PERSON: 'Contact person',
  ACTIVITY: 'Activity',
  SELECT_PRIMARY_CONTACT: '-- select primary contact --',
  CREATED_BY: 'Created by',
  UPDATED_BY: 'Last updated by',
  RELATED_COMPANY: 'Related company',
  USERS: 'Users',
  STATUS: 'Status',
  ACTIONS: 'Actions',
  ADDRESS: 'Address',
  ATTRIBUTES: 'Attributes',
  COUNTRY_STATE: 'Country / State',
  ADD_NEW_LOCATION: 'Add new location',
  DEFAULT_PPMS: {
    TITLE: `Location's default PPM values for calculating estimated hedge ounces`,
  },
};

export { LOCATION };
