import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router';

import { deletePayoutRule } from 'actions/Payments/payoutRulesDelete';
import { clearPayoutRules, getPayoutRules } from 'actions/Payments/payoutRulesList';
import { openModal } from 'actions/shared/modal';
import { showSnackbar } from 'actions/shared/snackbar';

import { GroupButtons } from 'shared/components/Buttons';
import { GroupButton } from 'shared/components/Buttons/GroupButton/components/GroupButton';
import { FullPageList } from 'shared/components/FullPageList/FullPageList';
import { deletePayoutRuleModal } from 'shared/helpers/constants/modalConstants';
import { message } from 'shared/helpers/constants/payments/createPayoutRulesConstants';
import { PAYMENTS } from 'shared/helpers/constants/payments/paymentsConstants';
import {
  itemsTemplate,
  itemsTemplateMobile,
  itemsDetailsTemplate,
} from 'shared/helpers/constants/payments/payoutRulesTableConfig';
import {
  successOptions,
  dangerOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

const PayoutRulesBase = ({ history }) => {
  const dispatch = useDispatch();
  const auth = useSelector(({ auth }) => auth);
  const { payoutRules, isPending } = useSelector(({ payoutRulesList }) => payoutRulesList);

  useEffect(() => () => dispatch(clearPayoutRules()), [dispatch]);

  const fetchList = useCallback(() => dispatch(getPayoutRules()), [dispatch]);

  const deletePayoutRulesCompany = useCallback(
    async payoutRuleCompany => {
      try {
        await dispatch(deletePayoutRule(payoutRuleCompany));
        fetchList();
        dispatch(showSnackbar(successOptions, message.PAYOUT_RULE_DELETE_SUCCESS));
      } catch (error) {
        dispatch(showSnackbar(dangerOptions, message.PAYOUT_RULE_DELETE_ERROR));
      }
    },
    [dispatch],
  );

  const renderActionButtons = () => (
    <GroupButtons>
      <GroupButton
        className="PayoutRules-create"
        access={auth.access.payments.payoutRules.manage}
        onClick={() => history.push('/payments/payout-rules/create')}
        label={PAYMENTS.PAYOUT_RULES.CREATE}
        icon="payout-rules-add"
      />
    </GroupButtons>
  );

  const actions = auth.access.payments.paymentExceptionCompanies.manage
    ? [
        {
          icon: 'bin',
          action: 'Delete',
          onClick: payoutRuleCompany => () =>
            dispatch(
              openModal(deletePayoutRuleModal, () => deletePayoutRulesCompany(payoutRuleCompany)),
            ),
        },
      ]
    : [];

  return (
    <FullPageList
      title={PAYMENTS.PAYOUT_RULES.TITLE}
      actionButtons={renderActionButtons}
      itemsTemplate={itemsTemplate}
      itemsTemplateMobile={itemsTemplateMobile}
      itemsDetailsTemplate={itemsDetailsTemplate}
      actionsTemplate={actions}
      items={payoutRules}
      fetchList={fetchList}
      isPending={isPending}
    />
  );
};

const PayoutRules = withRouter(PayoutRulesBase);

export { PayoutRules, PayoutRulesBase };
