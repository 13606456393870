import { connect } from 'react-redux';

import { closeModal, openModal } from 'actions/shared/modal';

import { Modal } from './Modal';

const mapStateToProps = state => ({
  modal: state.modal,
});

const mapDispatchToProps = {
  openModal,
  closeModal,
};

const connectedModal = connect(mapStateToProps, mapDispatchToProps)(Modal);

export { connectedModal as Modal };
