import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getPrivilegedCompaniesActionType = 'GET_PRIVILEGED_COMPANIES';
const getPrivilegedCompaniesAction = () => ({
  type: getPrivilegedCompaniesActionType,
});

const getExceptionCompaniesSuccessActionType = `GET_EXCEPTION_COMPANIES_SUCCESS`;
const getExceptionCompaniesSuccessAction = data => ({
  type: getExceptionCompaniesSuccessActionType,
  payload: { data },
});

const getTrustedCompaniesSuccessActionType = `GET_TRUSTED_COMPANIES_SUCCESS`;
const getTrustedCompaniesSuccessAction = data => {
  return {
    type: getTrustedCompaniesSuccessActionType,
    payload: { data },
  };
};

const getPrivilegedCompaniesFailureActionType = `${getPrivilegedCompaniesActionType}_FAILURE`;
const getPrivilegedCompaniesFailureAction = errorMessage => ({
  type: getPrivilegedCompaniesFailureActionType,
  payload: { errorMessage },
});

const clearPrivilegedCompaniesActionType = 'CLEAR_PRIVILEGED_COMPANIES_ACTION';
const clearPrivilegedCompanies = () => ({
  type: clearPrivilegedCompaniesActionType,
});

const getPrivilegedCompanies = (url, actionType) => dispatch => {
  dispatch(getPrivilegedCompaniesAction());

  return axios
    .get(`${API_HOST_AUTH}/${url}`)
    .then(({ data }) => dispatch(actionType(data)))
    .catch(({ message }) => dispatch(getPrivilegedCompaniesFailureAction(message)));
};

const getTrustedCompanies = () =>
  getPrivilegedCompanies('trusted-companies', getTrustedCompaniesSuccessAction);

const getPaymentExceptionCompanies = () =>
  getPrivilegedCompanies('payment-exception-companies', getExceptionCompaniesSuccessAction);

export {
  getPrivilegedCompaniesActionType,
  getExceptionCompaniesSuccessActionType,
  getTrustedCompaniesSuccessActionType,
  getPrivilegedCompaniesFailureActionType,
  clearPrivilegedCompaniesActionType,
  getTrustedCompanies,
  getPaymentExceptionCompanies,
  clearPrivilegedCompanies,
};
