import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const changeUsersPasswordActionType = 'CHANGE_USERS_PASSWORD';
const changeUsersPasswordAction = () => ({
  type: changeUsersPasswordActionType,
});

const changeUsersPasswordSuccessActionType = `${changeUsersPasswordActionType}_SUCCESS`;
const changeUsersPasswordSuccessAction = () => ({
  type: changeUsersPasswordSuccessActionType,
});

const changeUsersPasswordFailureActionType = `${changeUsersPasswordActionType}_FAILURE`;
const changeUsersPasswordFailureAction = () => ({
  type: changeUsersPasswordFailureActionType,
});

const changeUsersPassword = (userId, newPassword) => dispatch => {
  dispatch(changeUsersPasswordAction());

  return axios
    .post(`${API_HOST_AUTH}/users/${userId}/change-password`, { newPassword })
    .then(res => {
      dispatch(changeUsersPasswordSuccessAction());

      return res.data;
    })
    .catch(error => {
      dispatch(changeUsersPasswordFailureAction());

      return Promise.reject(error.data);
    });
};

export {
  changeUsersPasswordActionType,
  changeUsersPasswordSuccessActionType,
  changeUsersPasswordFailureActionType,
  changeUsersPassword,
};
