import PropTypes from 'prop-types';
import { useCallback } from 'react';

import { StyledProgressCounter, ProgressStatuses } from './StyledProgressCounter';

const ProgressCounter = ({ currentNumber = 0, quantity = 0 }) => {
  const getProgressStatus = useCallback(currentNumber => {
    if (!currentNumber) {
      return ProgressStatuses.EMPTY;
    }

    if (currentNumber === quantity) {
      return ProgressStatuses.FILLED;
    }

    return ProgressStatuses.PARTIALLY_FILLED;
  }, []);

  return (
    <StyledProgressCounter status={getProgressStatus(currentNumber)} data-testid="progressCounter">
      {currentNumber}/{quantity}
    </StyledProgressCounter>
  );
};

ProgressCounter.propTypes = {
  currentNumber: PropTypes.number.isRequired,
  quantity: PropTypes.number.isRequired,
};

export { ProgressCounter };
