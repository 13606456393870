import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { changeProcessUponDeliveryField } from 'actions/Lots/changeProcessUponDelivery';
import { openModal } from 'actions/shared/modal';
import { showSnackbar } from 'actions/shared/snackbar';

import { changeProcessUponDeliveryModalData } from 'shared/helpers/matchers/lots/changeProcessUponDeliveryModalData';
import { snackbarOptions } from 'shared/helpers/matchers/snackbar/snackbarOptions';

function ChangeProcessUponDelivery(props) {
  const changeProcessUponDelivery = () => {
    props.openModal(changeProcessUponDeliveryModalData(props.lot.processUponDelivery), () => {
      props
        .changeProcessUponDeliveryField(props.lot._id, !props.lot.processUponDelivery)
        .then(res => props.showSnackbar(snackbarOptions(res.messageType), res.message));
    });
  };

  return <React.Fragment>{props.children({ changeProcessUponDelivery })}</React.Fragment>;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      openModal,
      showSnackbar,
      changeProcessUponDeliveryField,
    },
    dispatch,
  );
}

const ConnectedChange = connect(null, mapDispatchToProps)(ChangeProcessUponDelivery);

export { ConnectedChange as ConnectedChangeProcessUponDelivery, ChangeProcessUponDelivery };
