import {
  changeLotsInProcessingOverviewFailureType,
  changeLotsInProcessingOverviewSuccessType,
  changeLotsInProcessingOverviewType,
  getLotsForProcessingOverviewType,
  getLotsForProcessingOverviewFailureType,
  getLotsForProcessingOverviewSuccessType,
  clearProcessingOverviewActionType,
} from 'actions/Lots/processingOverview';

const INITIAL_STATE = {
  isPending: false,
  lots: [],
};

const processingOverviewReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getLotsForProcessingOverviewType:
      return {
        ...state,
        isPending: true,
      };
    case clearProcessingOverviewActionType:
    case getLotsForProcessingOverviewFailureType:
      return {
        isPending: false,
        lots: [],
      };
    case getLotsForProcessingOverviewSuccessType:
      return {
        isPending: false,
        lots: action.payload.lots,
      };
    case changeLotsInProcessingOverviewSuccessType:
    case changeLotsInProcessingOverviewFailureType:
      return {
        ...state,
        isPending: false,
      };
    case changeLotsInProcessingOverviewType:
      return {
        isPending: true,
        lots: {
          ...Object.entries(state.lots).reduce(
            (acc, [section, value]) => ({
              ...acc,
              [section]: action.payload.difference[section] || value,
            }),
            {},
          ),
        },
      };
    default:
      return state;
  }
};

export { processingOverviewReducer };
