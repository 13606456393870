import axios from 'axios';

import { parseShipmentsFilters } from 'shared/helpers/dataParsers/shipments/parseShipmentsFilters';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getShipmentsListActionType = 'GET_SHIPMENTS_LIST';
const getShipmentsListAction = () => ({
  type: getShipmentsListActionType,
});

const getShipmentsListSuccessActionType = `${getShipmentsListActionType}_SUCCESS`;
const getShipmentsListSuccessAction = ({ docs, pages, page, limit }) => ({
  type: getShipmentsListSuccessActionType,
  payload: {
    shipments: docs,
    pages,
    limit,
    page,
  },
});

const getShipmentsListFailureActionType = `${getShipmentsListActionType}_FAILURE`;
const getShipmentsListFailureAction = () => ({
  type: getShipmentsListFailureActionType,
});

const clearShipmentsListActionType = 'CLEAR_SHIPMENTS_LIST_ACTION';
function clearShipmentsListAction() {
  return {
    type: clearShipmentsListActionType,
  };
}

const getShipmentsListFactory = parsedFilters => dispatch => {
  dispatch(getShipmentsListAction());

  return axios
    .get(`${API_HOST_AUTH}/shipments`, { params: parsedFilters })
    .then(({ data }) => dispatch(getShipmentsListSuccessAction(data)))
    .catch(() => dispatch(getShipmentsListFailureAction()));
};

const getShipmentsList = (shipmentDirection, limit, page, filters, searchFilter) =>
  getShipmentsListFactory(
    parseShipmentsFilters(shipmentDirection, limit, page, filters, searchFilter),
  );

export {
  getShipmentsListActionType,
  getShipmentsListSuccessActionType,
  getShipmentsListFailureActionType,
  clearShipmentsListActionType,
  clearShipmentsListAction,
  getShipmentsList,
};
