import {
  isWarehouseUser,
  isOwnerOrSupport,
  isCustomerUser,
} from 'shared/helpers/matchers/checkUserType';

const samplePackageAccess = ({ userType, relatedCompany }) =>
  !relatedCompany?.materialCountRequired &&
  (isCustomerUser(userType)
    ? relatedCompany?.samplesAllowed
    : isWarehouseUser(userType) || isOwnerOrSupport(userType));

const samplePackageEmptyFieldsAccess = userType =>
  isOwnerOrSupport(userType) || isWarehouseUser(userType);

export { samplePackageAccess, samplePackageEmptyFieldsAccess };
