import React, { useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import { openModal } from 'actions/shared/modal';
import { showSnackbar } from 'actions/shared/snackbar';

import { FieldInput } from 'shared/components/Fields/FieldInput/FieldInput';
import { FieldSelect } from 'shared/components/Fields/FieldSelect/FieldSelect';
import { Form, Section } from 'shared/components/Form';
import { cancelModal } from 'shared/helpers/constants/modalConstants';
import {
  PRICE_FEEDS,
  priceFeed,
} from 'shared/helpers/constants/systemSettings/priceFeeds/constants';
import { humanizeCamelCase } from 'shared/helpers/parsers/text';
import { validate } from 'shared/helpers/validations/systemSettings/priceFeeds/updatePriceFeed';

function UpdatePriceFeedFormBase({
  source,
  handleSubmit,
  history,
  change,
  invalid,
  pristine,
  ...props
}) {
  const options = useMemo(() =>
    Object.entries(priceFeed.source).map(([key, value]) => ({
      label: humanizeCamelCase(key),
      value,
    })),
  );

  const onSourceChange = useCallback(() => {
    change('adjustment', null);
    change('manualPrice', null);
  }, [change]);

  const onCancel = useCallback(() => {
    props.openModal(cancelModal, () => {
      history.push('/settings');
    });
  }, [props.openModal, history]);

  const sourceValue = source && source.value;
  const isAdjustmentVisible = sourceValue === priceFeed.source.marketFeedAdjusted;
  const isManualPriceVisible = sourceValue === priceFeed.source.manualEntry;
  const additionalField =
    (isAdjustmentVisible && 'adjustment') || (isManualPriceVisible && 'manualPrice') || '.';

  return (
    <Form
      header={PRICE_FEEDS.PRICE_FEED}
      submitDisabled={invalid || pristine}
      onSubmit={handleSubmit}
      onCancel={onCancel}
    >
      <Section template={[`source ${additionalField}`]}>
        <Field
          name="source"
          field="required"
          component={FieldSelect}
          label={PRICE_FEEDS.SOURCE}
          options={options}
          onChange={onSourceChange}
        />
        {isAdjustmentVisible && (
          <Field
            name="adjustment"
            field="required"
            component={FieldInput}
            label={PRICE_FEEDS.ADJUSTMENT}
            prefix="$"
          />
        )}
        {isManualPriceVisible && (
          <Field
            name="manualPrice"
            field="required"
            component={FieldInput}
            label={PRICE_FEEDS.MANUAL_PRICE}
            prefix="$"
          />
        )}
      </Section>
    </Form>
  );
}

const formName = 'updatePriceFeed';
const selector = formValueSelector(formName);

const mapStateToProps = state => ({
  source: selector(state, 'source'),
});

const mapDispatchToProps = {
  openModal,
  showSnackbar,
};

const UpdatePriceFeedForm = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: formName,
    validate,
  }),
)(UpdatePriceFeedFormBase);

export { UpdatePriceFeedFormBase, UpdatePriceFeedForm };
