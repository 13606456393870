import React from 'react';

import { FieldButtonSelect } from '../FieldButtonSelect/FieldButtonSelect';

const options = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

const FieldRadioButton = props => (
  <FieldButtonSelect {...props} options={options} normalize={option => option?.value} />
);

export { FieldRadioButton };
