import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { marketAvailability } from 'actions/Hedges/marketAvailability';

import { datePickerFormat } from 'shared/helpers/parsers/date';

import dateAdapter, { validateDate } from 'utils/date/dateAdapter';
import { MomentAdapter } from 'utils/date/momentAdapter';

import { withFieldWrapper } from '../FieldWrapper/FieldWrapper';
import { Input } from './Input';
import { DatePickerStyled } from './StyledFieldDate';

class FieldDateBase extends Component {
  static defaultProps = {
    excludeDates: [],
    onlyWeekdays: false,
  };

  componentDidMount() {
    this.props.marketAvailability(true);
  }

  stripTime = value =>
    value instanceof Date
      ? new Date(value.getFullYear(), value.getMonth(), value.getDate())
      : value;

  onChange = (value, event) => {
    const { onChange, name, onDateChange } = this.props;
    const normalizedValue = value && MomentAdapter.normalize(value).toJSDate();
    if (event?.keyCode === 13) return event.preventDefault();

    onDateChange && onDateChange(normalizedValue);
    onChange && onChange(name, this.stripTime(value === null ? undefined : value));
  };

  unwrapDates(values) {
    return Object.entries(values)
      .map(([key, value]) => [key, value instanceof MomentAdapter ? value.localize() : value])
      .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
  }

  localizeDate(date) {
    return date instanceof Date ? date : dateAdapter(date).localize();
  }

  isWeekday = date => {
    const { marketDaysNumbers } = this.props.marketAvailabilityDetails;

    const day = new Date(date).getDay();
    return marketDaysNumbers.includes(day);
  };

  render() {
    const { value, clearable, filterRestrictedDays, ...parsedProps } = this.unwrapDates(this.props);
    const { minDate, maxDate, excludeDates, onlyWeekdays } = this.props;
    const minMax = {
      minDate: validateDate(minDate) ? this.localizeDate(minDate) : null,
      maxDate: validateDate(maxDate) ? this.localizeDate(maxDate) : null,
    };
    const { disabled, disableFlip } = parsedProps;

    return (
      <DatePickerStyled
        {...parsedProps}
        {...minMax}
        onBlur={this.props.onBlur}
        onChange={(val, e) => this.onChange(val, e)}
        customInput={<Input hideIcon={disabled} />}
        isClearable={(clearable === undefined || clearable) && !disabled}
        calendarClassName="FieldDate__calendar"
        selected={this.props.value}
        handleDateChangeRaw={this.handleDateChangeRaw}
        dateFormat={datePickerFormat}
        autoComplete="off"
        excludeDates={excludeDates}
        showMonthDropdown
        showYearDropdown
        filterDate={filterRestrictedDays || (onlyWeekdays && this.isWeekday)}
        popperModifiers={{ flip: { enabled: !disableFlip } }}
      />
    );
  }
}

FieldDateBase.propTypes = {
  excludeDates: PropTypes.array,
  label: PropTypes.string,
  marketAvailability: PropTypes.func,
  marketAvailabilityDetails: PropTypes.shape({
    isPending: PropTypes.bool,
    marketDays: PropTypes.string,
    marketHoliday: PropTypes.bool,
    marketHours: PropTypes.string,
    marketTimeFrom: PropTypes.number,
    marketTimeTo: PropTypes.number,
  }),
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onlyWeekdays: PropTypes.bool,
};

const FieldDate = withFieldWrapper(FieldDateBase);

const mapStateToProps = ({ marketAvailabilityDetails }) => ({ marketAvailabilityDetails });

const mapDispatchToProps = {
  marketAvailability,
};

const connected = connect(mapStateToProps, mapDispatchToProps)(FieldDate);

export { connected as FieldDate, DatePickerStyled };
