import axios from 'axios';

import { CLIENT_FACING_UPLOADS_CONSTANTS } from '../../shared/helpers/constants/clientFacingUploadsConstants';
import {
  successOptions,
  dangerOptions,
} from '../../shared/helpers/constants/snackbar/snackbarOptionTypes';
import { showSnackbar } from '../shared/snackbar';

const API_HOST_AUTH = process.env.REACT_APP_API;

const inspectCfuAproveActionType = 'CFU_INSPECT_APPROVE';
function inspectCfuAproveAction() {
  return {
    type: inspectCfuAproveActionType,
  };
}

const inspectCfuRejectActionType = 'CFU_INSPECT_REJECT';
function inspectCfuRejectAction() {
  return {
    type: inspectCfuRejectActionType,
  };
}

const inspectCfuActionType = 'CFU_INSPECT';
function inspectCfuAction() {
  return { type: inspectCfuActionType };
}

const inspectCfuSuccessActionType = 'CFU_INSPECT_SUCCESS';
function inspectCfuSuccessAction() {
  return {
    type: inspectCfuSuccessActionType,
  };
}

const inspectCfuFailureActionType = 'CFU_INSPECT_FAILURE';
function inspectCfuFailureAction() {
  return {
    type: inspectCfuFailureActionType,
  };
}

function inspectCfu(ids, relatedId, approve) {
  return dispatch => {
    dispatch(inspectCfuAction());
    return axios({
      method: 'PUT',
      url: `${API_HOST_AUTH}/cfu/${relatedId}/inspect`,
      data: {
        decision: approve
          ? CLIENT_FACING_UPLOADS_CONSTANTS.ACCEPTED
          : CLIENT_FACING_UPLOADS_CONSTANTS.REJECTED,
        documents: ids,
      },
    })
      .then(() => {
        dispatch(
          showSnackbar(
            successOptions,
            approve
              ? CLIENT_FACING_UPLOADS_CONSTANTS.SUCCESS_APPROVE({ isMultiple: Boolean(ids.length) })
              : CLIENT_FACING_UPLOADS_CONSTANTS.SUCCESS_REJECTED({
                  isMultiple: Boolean(ids.length),
                }),
          ),
        );
        dispatch(inspectCfuSuccessAction());
      })
      .catch(err => {
        dispatch(showSnackbar(dangerOptions, err.data.message));
        dispatch(inspectCfuFailureAction());
      });
  };
}

export {
  inspectCfuAproveActionType,
  inspectCfuAproveAction,
  inspectCfuRejectActionType,
  inspectCfuRejectAction,
  inspectCfuSuccessActionType,
  inspectCfuFailureAction,
  inspectCfuFailureActionType,
  inspectCfuSuccessAction,
  inspectCfuActionType,
  inspectCfuAction,
  inspectCfu,
};
