import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getTermsDetailsActionType = 'GET_TERMS_DETAILS';
function getTermsDetailsAction() {
  return {
    type: getTermsDetailsActionType,
  };
}

const getTermsDetailsSuccessActionType = 'GET_TERMS_DETAILS_SUCCESS';
function getTermsDetailsSuccessAction(terms) {
  return {
    type: getTermsDetailsSuccessActionType,
    payload: {
      terms,
    },
  };
}

const getTermsDetailsFailureActionType = 'GET_TERMS_DETAILS_FAILURE';
function getTermsDetailsFailureAction(errorMessage) {
  return {
    type: getTermsDetailsFailureActionType,
    payload: {
      errorMessage,
    },
  };
}

const clearTermsDetailsActionType = 'CLEAR_TERMS_DETAILS_ACTION';
function clearTermsDetailsAction() {
  return {
    type: clearTermsDetailsActionType,
  };
}

function getTerms(termsId) {
  return dispatch => {
    dispatch(getTermsDetailsAction());

    return axios
      .get(`${API_HOST_AUTH}/terms/${termsId}`)
      .then(terms => {
        dispatch(getTermsDetailsSuccessAction(terms.data));
        return terms.data;
      })
      .catch(err => {
        dispatch(getTermsDetailsFailureAction(err));
        return Promise.reject(err.data);
      });
  };
}

function clearTermsDetails() {
  return dispatch => {
    dispatch(clearTermsDetailsAction());
  };
}

export {
  getTermsDetailsActionType,
  getTermsDetailsSuccessActionType,
  getTermsDetailsFailureActionType,
  clearTermsDetailsActionType,
  getTerms,
  clearTermsDetails,
};
