import { safeParseFloat, roundUp } from 'shared/helpers/parsers/number';

import { evaluate } from 'utils/evaluate';

const troyOuncesRemaining = hedge => {
  const initialRemainingOunces = {
    platinumOz: hedge.platinumOz,
    palladiumOz: hedge.palladiumOz,
    rhodiumOz: hedge.rhodiumOz,
  };

  const { platinumOz, palladiumOz, rhodiumOz } = hedge.deliveries.reduce(
    (delivery, field) => ({
      platinumOz: +evaluate(`${delivery.platinumOz} - ${+safeParseFloat(field.platinumOz)}`),
      palladiumOz: +evaluate(`${delivery.palladiumOz} - ${+safeParseFloat(field.palladiumOz)}`),
      rhodiumOz: +evaluate(`${delivery.rhodiumOz} - ${+safeParseFloat(field.rhodiumOz)}`),
    }),
    { ...initialRemainingOunces },
  );

  return {
    platinumOz: +roundUp(platinumOz, 3),
    palladiumOz: +roundUp(palladiumOz, 3),
    rhodiumOz: +roundUp(rhodiumOz, 3),
  };
};

export { troyOuncesRemaining };
