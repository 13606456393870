import PropTypes from 'prop-types';
import React from 'react';

import { Section } from 'shared/components/Form';
import { MultipleFieldsSection } from 'shared/components/Form/MultipleFieldsSection/MultipleFieldsSection';
import { ASSAY } from 'shared/helpers/constants/lots/assayConstants';
import { assayOptions } from 'shared/helpers/constants/lots/assayOptions';

import { renderFields } from '../helpers/renderFields';
import { Weights } from './components/Weights/Weights';
import {
  containedAssaysFieldsConfig,
  returnableOuncesFieldsConfig,
  containedMetalsFieldsConfig,
} from './configs/fieldsConfig';

const formatFields = (config, row) => renderFields(config).splice((row - 1) * 3, row * 3);

const HFAssay = ({ formValues, change, resetSection, assayOption }) => (
  <>
    <Weights
      formValues={formValues}
      change={change}
      resetSection={resetSection}
      assayOption={assayOption}
    />
    <Section
      header={ASSAY.CONTAINED_ASSAYS}
      template={['containedAssaysF containedAssaysF', 'containedAssaysH containedAssaysH']}
      noPadding
    >
      <MultipleFieldsSection
        name="containedAssaysF"
        fields={formatFields(containedAssaysFieldsConfig(assayOption), 1)}
      />
      <MultipleFieldsSection
        name="containedAssaysH"
        fields={formatFields(containedAssaysFieldsConfig(assayOption), 2)}
      />
    </Section>
    <Section
      header={ASSAY.CONTAINED_METALS}
      template={['containedMetalsF containedMetalsF', 'containedMetalsH containedMetalsH']}
      noPadding
    >
      <MultipleFieldsSection
        name="containedMetalsF"
        fields={formatFields(containedMetalsFieldsConfig(assayOption), 1)}
      />
      <MultipleFieldsSection
        name="containedMetalsH"
        fields={formatFields(containedMetalsFieldsConfig(assayOption), 2)}
      />
    </Section>
    <Section
      header={ASSAY.RETURNABLE_OUNCES}
      template={['returnableOuncesF returnableOuncesF', 'returnableOuncesH returnableOuncesH']}
      noPadding
    >
      <MultipleFieldsSection
        name="returnableOuncesF"
        fields={formatFields(returnableOuncesFieldsConfig(assayOption), 1)}
      />
      <MultipleFieldsSection
        name="returnableOuncesH"
        fields={formatFields(returnableOuncesFieldsConfig(assayOption), 2)}
      />
    </Section>
  </>
);

HFAssay.propTypes = {
  assayOption: PropTypes.oneOf([assayOptions.AB_assay, assayOptions.HF_assay]),
  change: PropTypes.func,
  resetSection: PropTypes.func,
  formValues: PropTypes.shape({
    assayOptions: PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
    materialTypeF: PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
    materialTypeH: PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  }),
};

export { HFAssay };
