import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, getFormValues } from 'redux-form';

import { FieldSelect } from 'shared/components/Fields/FieldSelect/FieldSelect';
import { Section } from 'shared/components/Form';
import { ASSAY } from 'shared/helpers/constants/lots/assayConstants';
import { assayOptions } from 'shared/helpers/constants/lots/assayOptions';
import { cancelModal } from 'shared/helpers/constants/modalConstants';
import { mapArray } from 'shared/helpers/parsers/select';
import { validate } from 'shared/helpers/validations/lot/enterAssayResultsValidation';

import { StyledEnterAssayResultsForm } from './StyledEnterAssayResultsForm';
import { HFAssay } from './components/HFAssay/HFAssay';
import { RegularAssay } from './components/RegularAssay/RegularAssay';

class EnterAssayResultsFormLayout extends Component {
  modalOpen = () => {
    const { match, openModal, history } = this.props;

    openModal(cancelModal, () => history.push(`/lots/list/${match.params.id}`));
  };

  resetPreviousForm = () => {
    this.props.reset('EnterAssayResultsForm');
  };

  render() {
    const { handleSubmit, invalid, pristine, formValues } = this.props;

    const renderSwitch = assayOption => {
      switch (assayOption) {
        case assayOptions.regular_assay:
          return <RegularAssay {...this.props} />;
        case assayOptions.AB_assay:
        case assayOptions.HF_assay:
          return <HFAssay {...this.props} assayOption={assayOption} />;
        default:
      }
    };

    return (
      <StyledEnterAssayResultsForm
        onSubmit={handleSubmit}
        onCancel={this.modalOpen}
        submitDisabled={invalid || pristine}
        header={ASSAY.ASSAY}
      >
        <Section template={['assayOption .']}>
          <Field
            name="assayOption"
            component={FieldSelect}
            options={mapArray(Object.values(assayOptions))}
            label={ASSAY.ASSAY_OPTION}
            field="required"
            onChange={this.resetPreviousForm}
          />
        </Section>
        {formValues && formValues.assayOption && renderSwitch(formValues.assayOption.value)}
      </StyledEnterAssayResultsForm>
    );
  }
}

const mapStateToProps = state => ({
  formValues: getFormValues('EnterAssayResultsForm')(state),
});

const EnterAssayResultsForm = connect(mapStateToProps)(
  reduxForm({
    form: 'EnterAssayResultsForm',
    validate,
  })(EnterAssayResultsFormLayout),
);

export { EnterAssayResultsFormLayout, EnterAssayResultsForm };
