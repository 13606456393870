import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const createDeliveriesActionType = 'CREATE_DELIVERIES';
const createDeliveriesAction = () => ({
  type: createDeliveriesActionType,
});

const createDeliveriesSuccessActionType = `${createDeliveriesActionType}_SUCCESS`;
const createDeliveriesSuccessAction = () => ({
  type: createDeliveriesSuccessActionType,
});

const createDeliveriesFailureActionType = `${createDeliveriesActionType}_FAILURE`;
const createDeliveriesFailureAction = () => ({
  type: createDeliveriesFailureActionType,
});

const createDeliveries = deliveries => dispatch => {
  dispatch(createDeliveriesAction());

  return axios
    .post(`${API_HOST_AUTH}/deliveries`, deliveries)
    .then(res => {
      dispatch(createDeliveriesSuccessAction(res.data));

      return res.data;
    })
    .catch(err => {
      dispatch(createDeliveriesFailureAction());

      return Promise.reject(err.data);
    });
};

export {
  createDeliveriesActionType,
  createDeliveriesSuccessActionType,
  createDeliveriesFailureActionType,
  createDeliveries,
};
