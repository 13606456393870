import { connect } from 'react-redux';

import { paymentStatusChange } from 'actions/Payments/paymentStatusChange';
import { showSnackbar } from 'actions/shared/snackbar';

import { snackbarOptions } from 'shared/helpers/matchers/snackbar/snackbarOptions';

function PaymentStatusChangeLayout(props) {
  const resolveAction = result => {
    props.showSnackbar(snackbarOptions(result.messageType), result.message);
  };

  const onClick = () => {
    const { id, status } = props;

    props.paymentStatusChange(id, status).then(resolveAction).catch(resolveAction);
  };

  return props.children({ onClick });
}

const mapDispatchToProps = {
  paymentStatusChange,
  showSnackbar,
};

const PaymentStatusChange = connect(null, mapDispatchToProps)(PaymentStatusChangeLayout);

export { PaymentStatusChangeLayout, PaymentStatusChange };
