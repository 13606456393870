import { packageType } from 'shared/helpers/constants/packages/packageType';
import { userStatuses, userTypesOwner } from 'shared/helpers/constants/users/userFilters';

export const assignedViewPrintLabRequestFormsAccess = (auth, packagesList) => {
  const packages = packagesList?.incoming?.docs || packagesList?.all?.docs;
  const isUserActive = auth.user.status === userStatuses.active;
  const samplePackagesToPrintFormAvailable = packages?.some(
    pkg => pkg.packageType === packageType.sample,
  );
  const acceptedUserTypes = [
    userTypesOwner.owner,
    userTypesOwner.support,
    userTypesOwner.warehouse,
    userTypesOwner.grading,
    userTypesOwner.customer,
  ];

  return (
    acceptedUserTypes.includes(auth.user.userType) &&
    isUserActive &&
    samplePackagesToPrintFormAvailable
  );
};

export const packageViewPrintLabRequestFormAccess = (auth, pkg) => {
  const isUserActive = auth.user.status === userStatuses.active;
  const isSamplePackage = pkg.packageType === packageType.sample;
  const acceptedUserTypes = [
    userTypesOwner.owner,
    userTypesOwner.support,
    userTypesOwner.warehouse,
  ];

  return acceptedUserTypes.includes(auth.user.userType) && isUserActive && isSamplePackage;
};
