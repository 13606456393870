import {
  getRecipientsActionType,
  getRecipientsSuccessActionType,
  getRecipientsFailureActionType,
  clearRecipientsActionType,
} from 'actions/Hedges/recipientsList';

const INITIAL_STATE = {
  isPending: false,
  recipients: [],
};

const recipientsListReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getRecipientsActionType:
      return {
        ...state,
        isPending: true,
      };
    case getRecipientsSuccessActionType:
      return {
        ...state,
        isPending: false,
        recipients: action.payload.recipients,
      };
    case getRecipientsFailureActionType:
      return {
        ...state,
        isPending: false,
      };
    case clearRecipientsActionType:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};

export { recipientsListReducer };
