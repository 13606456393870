import React, { Component } from 'react';

import { FormContainer } from 'shared/components/Form';
import { INSPECTIONS } from 'shared/helpers/constants/inspections/inspectionConstants';
import { inspectionTypes } from 'shared/helpers/constants/inspections/inspectionTypes';
import { requestLotInspection } from 'shared/helpers/constants/modalConstants';
import { snackbarOptions } from 'shared/helpers/matchers/snackbar/snackbarOptions';

import { LotInspectionsForm } from '../LotInspectionsForm/LotInspectionsForm';

class RequestLotInspectionBase extends Component {
  submit = values => {
    this.props.openModal(requestLotInspection, () => {
      this.props.requestLotInspection(values).then(this.resolveAction).catch(this.resolveAction);
    });
  };

  resolveAction = result => {
    this.props.showSnackbar(snackbarOptions(result.messageType), result.message);
    this.props.history.push('/lots/outgoing');
  };

  render() {
    const {
      modal,
      closeModal,
      openModal,
      getLotsForInspection,
      assignedLots,
      clearAssignedLotsAction,
    } = this.props;
    const { isPending } = this.props.lotInspections;

    return (
      <FormContainer header={INSPECTIONS.REQUEST_LOT_INSPECTION} loaded={!isPending}>
        {() => (
          <LotInspectionsForm
            modal={modal}
            openModal={openModal}
            closeModal={closeModal}
            onSubmit={this.submit}
            assignedLots={assignedLots}
            getLotsForInspection={getLotsForInspection}
            clearAssignedLotsAction={clearAssignedLotsAction}
            inspectionType={inspectionTypes.request}
          />
        )}
      </FormContainer>
    );
  }
}

export { RequestLotInspectionBase };
