import {
  changeUsersPasswordActionType,
  changeUsersPasswordFailureActionType,
  changeUsersPasswordSuccessActionType,
} from 'actions/Users/changeUsersPassword';

const INITIAL_STATE = {
  isPending: false,
};

const changeUsersPasswordReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case changeUsersPasswordActionType:
      return {
        isPending: true,
      };
    case changeUsersPasswordSuccessActionType:
    case changeUsersPasswordFailureActionType:
      return {
        isPending: false,
      };
    default:
      return state;
  }
};

export { changeUsersPasswordReducer };
