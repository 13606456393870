import {
  createSettlementDocumentType,
  createSettlementDocumentSuccessType,
  createSettlementDocumentFailureType,
} from 'actions/Payments/createSettlementDocument';
import {
  getPaymentDetailsActionType,
  getPaymentDetailsSuccessActionType,
  getPaymentDetailsFailureActionType,
  clearPaymentDetailsActionType,
} from 'actions/Payments/paymentDetails';
import {
  paymentMarkAsPaidActionType,
  paymentMarkAsPaidSuccessActionType,
  paymentMarkAsPaidFailureActionType,
} from 'actions/Payments/paymentMarkAsPaid';
import {
  paymentStatusChangeActionType,
  paymentStatusChangeSuccessActionType,
  paymentStatusChangeFailureActionType,
} from 'actions/Payments/paymentStatusChange';
import {
  reassignPaymentActionType,
  reassignPaymentSuccessActionType,
  reassignPaymentFailureActionType,
} from 'actions/Payments/reassignPayment';

const INITIAL_STATE = {
  isPending: false,
  errorMessage: null,
  payment: null,
};

const PaymentDetailsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getPaymentDetailsActionType:
      return {
        payment: null,
        isPending: true,
      };
    case getPaymentDetailsSuccessActionType:
      return {
        ...state,
        isPending: false,
        payment: action.payload.payment,
      };
    case getPaymentDetailsFailureActionType:
      return {
        ...state,
        isPending: false,
        errorMessage: action.payload.errorMessage,
      };
    case clearPaymentDetailsActionType:
      return {
        isPending: false,
        errorMessage: null,
        payment: null,
      };
    case paymentStatusChangeSuccessActionType:
      return {
        ...state,
        isPending: false,
        payment: {
          ...state.payment,
          ...action.payload.payment,
        },
      };
    case paymentMarkAsPaidSuccessActionType:
      return {
        ...state,
        isPending: false,
        payment: {
          ...state.payment,
          status: action.payload.payment.status,
          updatedBy: action.payload.payment.updatedBy,
          updatedAt: action.payload.payment.updatedAt,
          paidAt: action.payload.payment.paidAt,
          poolAccountSettlementAndBalanceDocument:
            action.payload.payment.poolAccountSettlementAndBalanceDocument,
        },
      };
    case reassignPaymentSuccessActionType:
      return {
        ...state,
        isPending: false,
        payment: {
          ...state.payment,
          ...action.payload.payment,
        },
      };
    case paymentMarkAsPaidActionType:
    case paymentStatusChangeActionType:
    case createSettlementDocumentType:
    case reassignPaymentActionType:
      return {
        ...state,
        isPending: true,
      };
    case paymentMarkAsPaidFailureActionType:
    case paymentStatusChangeFailureActionType:
    case createSettlementDocumentSuccessType:
    case createSettlementDocumentFailureType:
    case reassignPaymentFailureActionType:
      return {
        ...state,
        isPending: false,
      };
    default:
      return state;
  }
};

export { PaymentDetailsReducer };
