import {
  getDeliveriesToTransferActionType,
  getDeliveriesToTransferSuccessActionType,
  getDeliveriesToTransferFailureActionType,
} from 'actions/Deliveries/getDeliveriesToTransfer';

const INITIAL_STATE = {
  isPending: false,
  deliveries: [],
};

const getDeliveriesToTransferReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getDeliveriesToTransferActionType:
      return {
        ...state,
        isPending: true,
      };
    case getDeliveriesToTransferFailureActionType:
      return INITIAL_STATE;
    case getDeliveriesToTransferSuccessActionType:
      return {
        ...state,
        isPending: false,
        deliveries: { docs: action.payload.deliveries },
      };
    default:
      return state;
  }
};

export { getDeliveriesToTransferReducer };
