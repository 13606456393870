import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getLotsForCommodityType = 'GET_LOTS_FOR_COMMODITY';
const getLotsForCommodityAction = () => ({
  type: getLotsForCommodityType,
});

const getLotsForCommoditySuccessType = `${getLotsForCommodityType}_SUCCESS`;
const getLotsForCommoditySuccessAction = lots => ({
  type: getLotsForCommoditySuccessType,
  payload: {
    lots,
  },
});

const getLotsForCommodityFailureType = `${getLotsForCommodityType}_FAILURE`;
const getLotsForCommodityFailureAction = () => ({
  type: getLotsForCommodityFailureType,
});

const getLotsForCommodity = () => dispatch => {
  dispatch(getLotsForCommodityAction());

  return axios
    .get(`${API_HOST_AUTH}/commodity/lots`)
    .then(res => {
      dispatch(getLotsForCommoditySuccessAction(res.data));

      return res.data;
    })
    .catch(err => {
      dispatch(getLotsForCommodityFailureAction());

      return Promise.reject(err.data);
    });
};

export {
  getLotsForCommodityType,
  getLotsForCommoditySuccessType,
  getLotsForCommodityFailureType,
  getLotsForCommodity,
};
