import { humanize } from 'shared/helpers/parsers/text';

import { hedgeTypes } from './hedgeType';
import { materialTypes } from './materialType';

const initialValues = {
  hedgeType: {
    label: humanize(hedgeTypes.standard),
    value: hedgeTypes.standard,
  },
  materialType: {
    label: humanize(materialTypes.mono_canned),
    value: materialTypes.mono_canned,
  },
};

export { initialValues };
