import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getPriceFeedsType = 'GET_PRICE_FEEDS';
const getPriceFeedsAction = () => ({
  type: getPriceFeedsType,
});

const getPriceFeedsSuccessType = `${getPriceFeedsType}_SUCCESS`;
const getPriceFeedsSuccessAction = priceFeeds => ({
  type: getPriceFeedsSuccessType,
  payload: {
    priceFeeds,
  },
});

const getPriceFeedsFailureType = `${getPriceFeedsType}_FAILURE`;
const getPriceFeedsFailureAction = () => ({
  type: getPriceFeedsFailureType,
});

const clearPriceFeedsType = 'CLEAR_PRICE_FEEDS';
const clearPriceFeedsTypeAction = () => ({
  type: clearPriceFeedsType,
});

const getPriceFeeds = () => dispatch => {
  dispatch(getPriceFeedsAction());

  return axios
    .get(`${API_HOST_AUTH}/price-feeds`)
    .then(res => {
      dispatch(getPriceFeedsSuccessAction(res.data));

      return res.data;
    })
    .catch(err => {
      dispatch(getPriceFeedsFailureAction());

      return Promise.reject(err.data);
    });
};

export {
  getPriceFeedsType,
  getPriceFeedsSuccessType,
  getPriceFeedsFailureType,
  clearPriceFeedsType,
  getPriceFeeds,
  clearPriceFeedsTypeAction as clearPriceFeeds,
};
