import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { getCompaniesContact } from 'actions/Locations/getCompaniesContact';
import { getCompanyLocations } from 'actions/Locations/getCompanyLocations';
import {
  clearPackagesOverview,
  getPackagesUnsentForShipments,
} from 'actions/Packages/packagesOverviewList';
import { createShipment } from 'actions/Shipments/createShipment';
import {
  clearShipmentPackagesList,
  shipmentPackagesList,
} from 'actions/Shipments/shipmentPackagesList';
import { openModal, closeModal } from 'actions/shared/modal';
import { showSnackbar } from 'actions/shared/snackbar';

import { CreateShipment } from './CreateShipment';

const mapStateToProps = state => ({
  auth: state.auth,
  router: state.router,
  modal: state.modal,
  companyLocations: state.companyLocations,
  packagesOverviewList: state.packagesOverviewList,
  shipmentPackagesListData: state.shipmentPackagesListData,
  shipmentDetails: state.shipmentDetails,
});

const mapDispatchToProps = {
  closeModal,
  createShipment,
  getCompanyLocations,
  openModal,
  showSnackbar,
  getPackagesUnsentForShipments,
  clearPackagesOverview,
  clearShipmentPackagesList,
  shipmentPackagesList,
  getCompaniesContact,
};

const ConnectedCreateShipment = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreateShipment),
);

export { ConnectedCreateShipment };
