import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router';

import { createPayoutRule } from 'actions/Payments/payoutRulesCreate';
import { showSnackbar } from 'actions/shared/snackbar';

import { FormContainer } from 'shared/components/Form';
import { message } from 'shared/helpers/constants/payments/createPayoutRulesConstants';
import { PAYMENTS } from 'shared/helpers/constants/payments/paymentsConstants';
import {
  successOptions,
  dangerOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

import { PayoutRulesForm } from '../PayoutRulesForm/PayoutRulesForm';

const PayoutRulesCreateBase = ({ history }) => {
  const dispatch = useDispatch();

  const submit = useCallback(
    values => {
      return dispatch(createPayoutRule(values.relatedCompany.value, values.payoutRule.value))
        .then(() => {
          dispatch(showSnackbar(successOptions, message.PAYOUT_RULE_CREATE_SUCCESS));
          history.push('/payments/payout-rules');
        })
        .catch(() => showSnackbar(dangerOptions, message.PAYOUT_RULE_CREATE_ERROR));
    },
    [dispatch, history],
  );

  return (
    <FormContainer header={PAYMENTS.PAYOUT_RULES.CREATE}>
      <PayoutRulesForm onSubmit={submit} />
    </FormContainer>
  );
};

const PayoutRulesCreate = withRouter(PayoutRulesCreateBase);

export { PayoutRulesCreate };
