import { dateWithTimeToTimestamp } from 'shared/helpers/parsers/date';
import { safeParseFloat } from 'shared/helpers/parsers/number';
import { removeCommasAndParse } from 'shared/helpers/parsers/text';

const parseSettledOuncesData = values =>
  values.map(
    ({
      ouncesPt,
      ouncesPd,
      ouncesRh,
      pricePerOuncePt,
      pricePerOuncePd,
      pricePerOunceRh,
      requestedOnDate,
      requestedOnTime,
      ...ounce
    }) => ({
      relatedCompany: ounce.relatedCompany.value,
      relatedLot: ounce.relatedLot.value,
      ouncesPt: safeParseFloat(ouncesPt),
      ouncesPd: safeParseFloat(ouncesPd),
      ouncesRh: safeParseFloat(ouncesRh),
      pricePerOuncePt: removeCommasAndParse(pricePerOuncePt),
      pricePerOuncePd: removeCommasAndParse(pricePerOuncePd),
      pricePerOunceRh: removeCommasAndParse(pricePerOunceRh),
      requestedBy: ounce.requestedBy.value,
      requestedOn: dateWithTimeToTimestamp(requestedOnDate, requestedOnTime),
    }),
  );

export { parseSettledOuncesData };
