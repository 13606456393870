import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getLotsForMixingOverviewType = 'GET_LOTS_FOR_MIXING_OVERVIEW';
const getLotsForMixingOverviewAction = () => ({
  type: getLotsForMixingOverviewType,
});

const getLotsForMixingOverviewSuccessType = `${getLotsForMixingOverviewType}_SUCCESS`;
const getLotsForMixingOverviewSuccessAction = lots => ({
  type: getLotsForMixingOverviewSuccessType,
  payload: {
    lots,
  },
});

const getLotsForMixingOverviewFailureType = `${getLotsForMixingOverviewType}_FAILURE`;
const getLotsForMixingOverviewFailureAction = () => ({
  type: getLotsForMixingOverviewFailureType,
});

const changeLotsInMixingOverviewType = 'CHANGE_LOTS_FOR_MIXING_OVERVIEW';
const changeLotsInMixingOverviewAction = difference => ({
  type: changeLotsInMixingOverviewType,
  payload: {
    difference,
  },
});

const changeLotsInMixingOverviewSuccessType = `${changeLotsInMixingOverviewType}_SUCCESS`;
const changeLotsInMixingOverviewSuccessAction = () => ({
  type: changeLotsInMixingOverviewSuccessType,
});

const changeLotsInMixingOverviewFailureType = `${changeLotsInMixingOverviewSuccessType}_FAILURE`;
const changeLotsInMixingOverviewFailureAction = () => ({
  type: changeLotsInMixingOverviewFailureType,
});

const getLotsForMixingOverview = () => dispatch => {
  dispatch(getLotsForMixingOverviewAction());

  return axios
    .get(`${API_HOST_AUTH}/lots/mixing-overview`)
    .then(lots => dispatch(getLotsForMixingOverviewSuccessAction(lots.data)))
    .catch(err => {
      dispatch(getLotsForMixingOverviewFailureAction());

      return Promise.reject(err);
    });
};

const changeLotsInMixingOverview = (difference, destination) => dispatch => {
  dispatch(changeLotsInMixingOverviewAction(difference));

  return axios
    .put(`${API_HOST_AUTH}/lots/mixing-overview`, {
      [destination]: difference[destination].map(lot => lot._id),
    })
    .then(() => dispatch(changeLotsInMixingOverviewSuccessAction()))
    .catch(err => {
      dispatch(changeLotsInMixingOverviewFailureAction());

      return Promise.reject(err);
    });
};

export {
  getLotsForMixingOverviewType,
  getLotsForMixingOverviewSuccessType,
  getLotsForMixingOverviewFailureType,
  getLotsForMixingOverview,
  changeLotsInMixingOverviewType,
  changeLotsInMixingOverviewSuccessType,
  changeLotsInMixingOverviewFailureType,
  changeLotsInMixingOverview,
};
