import { paymentsAccess } from 'shared/helpers/accesses/payments/paymentAccesses';
import { isBuyerUser, isCustomerUser } from 'shared/helpers/matchers/checkUserType';

import { isGlobal } from 'utils/versioning/Guards';

import { generalInfoAccess } from '../accesses/generalInfoAccess';
import { poolAccountAccess } from '../accesses/poolAccounts/poolAccountAccess';
import { reportsAccess } from '../accesses/reportsAccess/reportsAccess';
import { termsAccess } from '../accesses/terms/termsAccess';
import { usersListAccess } from '../accesses/usersListAccess';
import { isGradingUser } from '../matchers/checkUserType';
import { HEDGES } from './hedges/hedgesConstants';
import { LOTS } from './lots/lotsConstants';
import { NAVBAR } from './navbarConstants';
import { PAYMENTS } from './payments/paymentsConstants';
import { REPORTS } from './reports/reportsConstants';
import { SHIPMENTS } from './shipments/shipmentsConstants';

const getNavbarElements = userType => [
  {
    id: 'navbarDashboard',
    path: '/dashboard',
    label: NAVBAR.DASHBOARD,
    access: () => true,
  },
  {
    id: 'navbarCompanies',
    path: '/companies',
    label: NAVBAR.COMPANIES,
    access: ({ access }) => access.companies.list,
  },
  {
    id: 'navbarUsers',
    path: '/users',
    label: NAVBAR.USERS,
    access: ({ user, access }) => usersListAccess(user, access),
  },
  {
    id: 'navbarLocations',
    path: '/locations',
    label: NAVBAR.LOCATIONS,
    access: ({ access }) => access.locations.ownLocations.list,
    withPathId: true,
  },
  {
    id: 'navbarTerms',
    path: '/terms',
    label: NAVBAR.TERMS,
    access: ({ user, access }) => termsAccess(user, access),
    withPathId: true,
  },
  {
    id: 'navbarAgreements',
    path: '/agreements',
    label: NAVBAR.AGREEMENTS,
    access: ({ access }) => access.agreements.list,
    withPathId: true,
  },
  {
    id: 'navbarLots',
    path: isBuyerUser(userType) ? '/lots/list' : '/lots',
    label: NAVBAR.LOTS,
    access: ({ access }) => access.lots.list,
  },
  {
    id: 'navbarShipments',
    path: '/shipments',
    label: NAVBAR.SHIPMENTS,
    access: ({ access }) => access.shipments.list,
  },
  {
    id: 'navbarPayments',
    path: '/payments',
    label: NAVBAR.PAYMENTS,
    access: ({ access, user }) => paymentsAccess(access, user),
  },
  {
    id: 'navbarHedges',
    path: '/hedges',
    label: NAVBAR.HEDGES,
    access: ({ access }) => access.hedges.overview,
  },
  {
    id: 'navbarPoolAccounts',
    path: '/pool-accounts',
    label: NAVBAR.POOL_ACCOUNTS,
    access: ({ user, access, poolAccounts }) => poolAccountAccess(user, access, poolAccounts),
  },
  {
    id: 'navbarReports',
    path: user => (isCustomerUser(user.userType) ? '/reports/analysis' : '/reports'),
    label: user => (isCustomerUser(user.userType) ? NAVBAR.ANALYSIS : NAVBAR.REPORTS),
    access: ({ user, access }) => reportsAccess(user, access),
  },
  {
    id: 'navbarGeneralInfo',
    path: '/general-info',
    label: NAVBAR.GENERAL_INFO,
    access: ({ user }) => generalInfoAccess(user),
  },
];

const lotsNavbarElementsBuyer = [
  {
    id: 'allLots',
    path: '/lots/list',
    label: LOTS.ALL_LOTS,
    access: ({ access }) => access.lots.list,
  },
];

const lotsNavbarElements = [
  {
    id: 'lotsOverview',
    path: '/lots',
    label: LOTS.OVERVIEW,
    access: ({ access }) => access.lots.list,
  },
  {
    id: 'allLots',
    path: '/lots/list',
    label: LOTS.ALL_LOTS,
    access: ({ access }) => access.lots.list,
  },
  {
    id: 'incomingLots',
    path: '/lots/incoming',
    label: LOTS.INCOMING_LOTS,
    access: ({ access }) => access.lots.incomingOverview,
  },
  {
    id: 'processingOverview',
    path: '/lots/processing',
    label: LOTS.PROCESSING_OVERVIEW,
    access: ({ access }) => access.lots.processingOverview.view,
  },
  {
    id: 'mixingOverview',
    path: '/lots/mixing',
    label: LOTS.MIXING_OVERVIEW,
    access: ({ access }) => access.lots.mixingOverview.view && isGlobal,
  },
  {
    id: 'outgoingLots',
    path: '/lots/outgoing',
    label: LOTS.OUTGOING_LOTS,
    access: ({ access }) => access.lots.outgoingOverview,
  },
];

const shipmentsNavbarElements = [
  {
    id: 'shipmentsOverview',
    path: '/shipments',
    label: SHIPMENTS.OVERVIEW,
    access: ({ access }) => access.shipments.list,
  },
  {
    id: 'shipmentsList',
    path: '/shipments/list',
    label: SHIPMENTS.SHIPMENTS,
    access: ({ user }) => isGradingUser(user.userType),
  },
  {
    id: 'incomingShipments',
    path: '/shipments/incoming',
    label: SHIPMENTS.INCOMING_SHIPMENTS,
    access: ({ user }) => !isGradingUser(user.userType),
  },
  {
    id: 'outgoingShipments',
    path: '/shipments/outgoing',
    label: SHIPMENTS.OUTGOING_SHIPMENTS,
    access: ({ user }) => !isGradingUser(user.userType),
  },
  {
    id: 'selfDeliveryRequests',
    path: '/shipments/self-delivery-requests',
    label: SHIPMENTS.SELF_DELIVERY_REQUESTS,
    access: ({ access }) => access.shipments.list && access.companies.gradingEnabled,
  },
];

const hedgesNavbarElements = [
  {
    id: 'allHedges',
    path: '/hedges',
    label: HEDGES.ALL_HEDGES,
    access: ({ access }) => access.hedges.overview,
  },
  {
    id: 'hedgeLots',
    path: '/hedges/hedge-ounces',
    label: HEDGES.HEDGE_LOTS.TITLE,
    access: ({ access }) => access.hedges.hedgeLots,
  },
  {
    id: 'marketHolidays',
    path: '/hedges/market-holidays',
    label: HEDGES.MARKET_HOLIDAYS.TITLE,
    access: ({ access }) => access.hedges.marketHolidays.list,
  },
  {
    id: 'trustedCompanies',
    path: '/hedges/trusted-companies',
    label: HEDGES.TRUSTED_COMPANIES.TITLE,
    access: ({ access }) => access.hedges.trustedCompanies.list,
  },
  {
    id: 'hedgeRecipients',
    path: '/hedges/recipients',
    label: HEDGES.RECIPIENTS.TITLE,
    access: ({ access }) => access.hedges.recipients.list,
  },
];

const paymentsNavbarElements = [
  {
    id: 'allPayments',
    path: '/payments',
    label: PAYMENTS.ALL_PAYMENTS,
    access: ({ access }) => access.payments.overview,
  },
  {
    id: 'bankHolidays',
    path: '/payments/bank-holidays',
    label: PAYMENTS.BANK_HOLIDAYS.TITLE,
    access: ({ access }) => access.payments.overview,
  },
  {
    id: 'paymentsExceptionCompanies',
    path: '/payments/payment-exception-companies',
    label: PAYMENTS.EXCEPTION_COMPANIES,
    access: ({ access }) => access.payments.paymentExceptionCompanies.list,
  },
  {
    id: 'paymentsPayoutRules',
    path: '/payments/payout-rules',
    label: PAYMENTS.PAYOUT_RULES.TITLE,
    access: ({ access }) => access.payments.payoutRules.list,
  },
];
const reportsNavbarElements = [
  {
    id: 'Total payments',
    path: '/reports',
    label: REPORTS.TOTAL_PAYMENTS,
    access: ({ access }) => access.payments.analysis.overview,
  },
  {
    id: 'lotsAwaitingResults',
    path: '/reports/lots-awaiting-results',
    label: REPORTS.LOTS_AWAITING_RESULTS,
    access: ({ access }) => access.reports.lotsAwaitingResults,
  },
  {
    id: 'openFinals',
    path: '/reports/finals',
    label: REPORTS.OPEN_FINALS,
    access: ({ access }) => access.reports.openFinals.view,
  },
  {
    id: 'analysisReports',
    path: '/reports/analysis',
    label: REPORTS.ANALYSIS_REPORTS,
    access: ({ access }) => access.payments.overview,
  },
  {
    id: 'clientLotReport',
    path: '/reports/client-lot-report',
    label: REPORTS.CLIENT_LOT_REPORT,
    access: ({ access }) => access.reports.clientLotReports,
  },
];

export {
  getNavbarElements,
  lotsNavbarElements,
  shipmentsNavbarElements,
  hedgesNavbarElements,
  paymentsNavbarElements,
  reportsNavbarElements,
  lotsNavbarElementsBuyer,
};
