import React, { Component } from 'react';
import { connect } from 'react-redux';

import { marketAvailability } from 'actions/Hedges/marketAvailability';

import { HEDGES } from 'shared/helpers/constants/hedges/hedgesConstants';

import { StyledMarketHolidayAlert } from './StyledMarketHolidayAlert';

class MarketHolidayAlert extends Component {
  componentDidMount() {
    this.props.marketAvailability(true);
  }

  render() {
    return (
      this.props.marketAvailabilityDetails.marketHoliday && (
        <StyledMarketHolidayAlert>{HEDGES.MARKET_HOLIDAYS_WARNING}</StyledMarketHolidayAlert>
      )
    );
  }
}

function mapStateToProps({ marketAvailabilityDetails }) {
  return { marketAvailabilityDetails };
}

const mapDispatchToProps = { marketAvailability };

const ConnectedHedgesList = connect(mapStateToProps, mapDispatchToProps)(MarketHolidayAlert);

export { ConnectedHedgesList as MarketHolidayAlert, MarketHolidayAlert as MarketHolidayAlertTest };
