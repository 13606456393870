import {
  termsDocumentAddedActionType,
  termsDocumentClearActionType,
  uploadTermsDocumentActionType,
  uploadTermsDocumentFailureActionType,
  uploadTermsDocumentSuccessActionType,
} from 'actions/Terms/uploadTermsDocument';

const INITIAL_STATE = {
  isPending: false,
  files: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case termsDocumentAddedActionType:
      return {
        isPending: false,
        files: action.payload.files,
      };
    case termsDocumentClearActionType:
      return {
        isPending: false,
        files: null,
      };
    case uploadTermsDocumentActionType:
      return {
        isPending: true,
      };
    case uploadTermsDocumentFailureActionType:
    case uploadTermsDocumentSuccessActionType:
      return {
        isPending: false,
      };
    default:
      return state;
  }
};
