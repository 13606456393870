import { useFormik } from 'formik';
import React, { useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { createVirtualLot } from 'actions/Lots/createVirtualLot';
import { openModal } from 'actions/shared/modal';

import { FormContainer } from 'shared/components/Form/FormContainer/FormContainer';
import { Section } from 'shared/components/Form/Section/Section';
import { FieldSelect, FieldInput, FieldDate } from 'shared/components/Formik/Fields';
import { Form } from 'shared/components/Formik/Form';
import { Preloader } from 'shared/components/Preloader/Preloader';
import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';
import { cancelModal } from 'shared/helpers/constants/modalConstants';
import { PLACEHOLDERS } from 'shared/helpers/constants/placeholders';
import {
  DAYS,
  PROCESSING,
  MIXING,
  GRADING_STATUSES,
  SECTIONS,
  COUNTING_REQUIRED_SECTIONS,
} from 'shared/helpers/constants/warehouseDashboard/labels';

import { goBackOrTo } from 'utils/history';

import { CreateVirtualLotSchema } from './CreateVirtualLotYup';

const isWest = process.env.REACT_APP_VERSION === 'GLOBAL';

const sections = Object.entries(SECTIONS).map(([key, section]) => ({
  label: section.name,
  value: section.id,
}));

const daySubsections = Object.entries(DAYS).map(([key, day]) => ({
  label: day.name,
  value: day.id,
}));

const gradingSubsections = Object.entries(GRADING_STATUSES).map(([key, section]) => ({
  label: section.name,
  value: section.id,
}));

const countingReqSubsections = Object.entries(COUNTING_REQUIRED_SECTIONS).map(([key, section]) => ({
  label: section.name,
  value: section.id,
}));

const processingSubsections = isWest
  ? Object.entries(PROCESSING)
      .map(([key, section]) => ({ label: section.name, value: section.id }))
      .concat(
        Object.entries(MIXING).map(([key, section]) => ({
          label: section.name,
          value: section.id,
        })),
      )
  : [
      {
        label: PROCESSING.SHEARS.name,
        value: PROCESSING.SHEARS.id,
      },
    ];

const CreateVirtualLot = () => {
  const dispatch = useDispatch();
  const { isPending } = useSelector(({ virtualLotCreate }) => virtualLotCreate);

  const {
    values,
    touched,
    isValid,
    isSubmitting,
    setFieldValue,
    setFieldTouched,
    getFieldMeta,
    handleSubmit,
    handleChange,
    handleBlur,
    setSubmitting,
  } = useFormik({
    initialValues: {
      name: '',
      date: new Date(),
      subsection: '',
      section: '',
      weight: '',
    },
    validationSchema: CreateVirtualLotSchema,
    onSubmit: newVirtualLot =>
      dispatch(createVirtualLot(newVirtualLot)).finally(() => setSubmitting(false)),
  });

  const currentSubsections = useMemo(() => {
    setFieldValue('weight', '');
    setFieldValue('subsection', '');

    switch (values.section) {
      case SECTIONS.INCOMING.id:
        return daySubsections;
      case SECTIONS.GRADING.id:
        return gradingSubsections;
      case SECTIONS.CURRENTLY_PROCESSING.id:
        return processingSubsections;
      case SECTIONS.COUNTING_REQUIRED.id:
        return countingReqSubsections;
      default:
        setFieldValue('subsection', '');
        return null;
    }
  }, [values?.section]);

  const getErrors = name => Object.keys(touched).includes(name) && getFieldMeta(name).error;
  const handleSelect = useCallback((val, name) => setFieldValue(name, val?.value), [setFieldValue]);

  const modalOpen = useCallback(
    () =>
      dispatch(
        openModal(cancelModal, () => {
          goBackOrTo('/lots');
        }),
      ),
    [dispatch],
  );

  return (
    <>
      <FormContainer header={LOTS.CREATE_VIRTUAL_LOT}>
        <Form
          onSubmit={handleSubmit}
          onCancel={modalOpen}
          submitDisabled={!isValid || isSubmitting}
          header={LOTS.VIRTUAL_LOT}
        >
          <Section
            template={[
              'name date',
              `section ${values?.section === SECTIONS.COMPLETE.id ? 'weight' : 'subsection'}`,
            ]}
          >
            <FieldInput
              name="name"
              type="text"
              label={LOTS.NAME}
              icon="icon-referral"
              field="required"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              error={getErrors('name')}
              onInput={() => setFieldTouched('name')}
            />
            <FieldDate
              name="date"
              label={LOTS.DATE}
              value={values.date}
              onBlur={handleBlur}
              onChange={setFieldValue}
            />
            <FieldSelect
              name="section"
              options={sections}
              label={LOTS.SECTION}
              placeholder={PLACEHOLDERS.SELECT}
              field="required"
              value={values.section}
              onBlur={() => setFieldTouched('section')}
              onChange={val => handleSelect(val, 'section')}
              error={getErrors('section')}
              setFieldValue={setFieldValue}
            />
            {currentSubsections && (
              <FieldSelect
                name="subsection"
                options={currentSubsections}
                label={LOTS.SUBSECTION}
                placeholder={PLACEHOLDERS.SELECT}
                field="required"
                value={values.subsection}
                onBlur={() => setFieldTouched('subsection')}
                error={getErrors('subsection')}
                onChange={val => handleSelect(val, 'subsection')}
                cleareAfterOptionsChanged
              />
            )}
            {values?.section === SECTIONS.COMPLETE.id && (
              <FieldInput
                name="weight"
                type="number"
                label={LOTS.WEIGHT}
                icon="icon-referral"
                field="required"
                value={values.weight}
                onBlur={handleBlur}
                onChange={handleChange}
                error={getErrors('weight')}
                onInput={() => setFieldTouched('weight')}
              />
            )}
          </Section>
        </Form>
      </FormContainer>
      <Preloader loading={isPending} />
    </>
  );
};

export { CreateVirtualLot as CreateVirtualLotForm };
