import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import styled from 'styled-components';

import { Checkbox, CheckboxWrapper } from 'shared/components/Fields/FieldCheckbox/FieldCheckbox';

const StyledCheckbox = styled(Checkbox)`
  display: flex;
  align-items: center;
  cursor: pointer;

  ${CheckboxWrapper} {
    padding-left: 10px;
  }
`;

const DeliverySelectAction = ({ deliveryId }) => {
  const { setFieldValue, values } = useFormikContext();

  const onClick = useCallback(e => {
    e.stopPropagation();
  }, []);

  const checkElement = ({ currentTarget: { checked } }) =>
    setFieldValue('delivery', checked ? deliveryId : '');

  const isSelected = () => (values.delivery === deliveryId ? 'checked' : '');

  return (
    <StyledCheckbox
      dataTestId={deliveryId}
      id={deliveryId}
      name="delivery"
      input={{ checked: isSelected() }}
      onChange={checkElement}
      onClick={onClick}
    />
  );
};

DeliverySelectAction.propTypes = {
  deliveryId: PropTypes.string,
};

export { DeliverySelectAction };
