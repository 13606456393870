import { connect } from 'react-redux';

import { createAgreement } from 'actions/Agreements/createAgreement';
import { getCompany, clearCompanyDetails } from 'actions/Companies/companyDetails';
import { openModal, closeModal } from 'actions/shared/modal';

import { CreateAgreement } from './CreateAgreement';

const mapStateToProps = ({ auth, modal, companyDetails, agreementDetails }) => ({
  auth,
  modal,
  companyDetails,
  agreementDetails,
});

const mapDispatchToProps = {
  openModal,
  closeModal,
  createAgreement,
  clearCompanyDetails,
  getCompany,
};

const ConnectedCreateAgreements = connect(mapStateToProps, mapDispatchToProps)(CreateAgreement);

export { ConnectedCreateAgreements };
