import PropTypes from 'prop-types';
import * as React from 'react';
import { useEffect, Fragment, useCallback, useState } from 'react';
import { useSelector, useDispatch, connect } from 'react-redux';
import { FormSection, reduxForm } from 'redux-form';

import { getSpecialPermissions } from 'actions/SpecialPermissions/getSpecialPermissions';
import { updateSpecialPermissions } from 'actions/SpecialPermissions/updateSpecialPermissions';
import { getUsers } from 'actions/Users/usersList';

import { UIAuthorization } from 'components/Authorization/UIAuthorization';

import { RouterButton } from 'shared/components/Buttons/RouterButton/RouterButton';
import { Icon } from 'shared/components/Icon/Icon';
import { OverviewListWrapper } from 'shared/components/OverviewListWrapper/OverviewListWrapper';
import { Preloader } from 'shared/components/Preloader/Preloader';
import { PERMISSIONS } from 'shared/helpers/constants/systemSettings/permissions/constants';
import { isBuyerUser } from 'shared/helpers/matchers/checkUserType';
import { capitalize } from 'shared/helpers/parsers/text';

import { PermissionsHeader, PermissionsH4 } from './StyledPermissions';
import { PermissionsFooter } from './components/PermissionsFooter/PermissionsFooter';
import { PermissionsRow } from './components/PermissionsRow';
import { templates } from './config';

let Permissions = ({ handleSubmit, dirty, reset }) => {
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);
  const userCompanyId = useSelector(state => state.auth.user.relatedCompany._id);
  const updateIsPending = useSelector(state => state.updateSpecialPermissions.isPending);
  const getPermissionsIsPending = useSelector(state => state.specialPermissions.isPending);
  const usersList = useSelector(state => state.usersList.users);
  const { viewLogs: viewLogsAccess, viewUsersList: viewUsersListAccess } = useSelector(
    state => state.auth.access.systemSettings.specialPermissions,
  );
  const filteredUsersList = usersList.filter(user => !isBuyerUser(user.userType));

  useEffect(() => {
    if (viewUsersListAccess) {
      dispatch(
        getUsers(false, false, false, {
          status: ['active'],
          accessLevel: [],
          relatedCompany: userCompanyId,
        }),
      );
      dispatch(getSpecialPermissions());
    }
  }, [userCompanyId, dispatch]);

  const submit = useCallback(values => {
    setSubmitting(true);
    dispatch(updateSpecialPermissions(values)).finally(() => setSubmitting(false));
  });

  const actionButton = (
    <UIAuthorization access={viewLogsAccess}>
      <RouterButton path="/settings/special-permissions/logs">
        {PERMISSIONS.VIEW_LOG}
        <Icon icon="icon-tiny-arrow-right" />
      </RouterButton>
    </UIAuthorization>
  );

  return (
    <>
      <OverviewListWrapper
        label={PERMISSIONS.SPECIAL_PERMISSIONS}
        list={{
          items: {
            total: viewUsersListAccess ? 1 : 0,
          },
        }}
        customList
        pending={updateIsPending}
        button={actionButton}
      >
        {viewUsersListAccess && (
          <form onSubmit={handleSubmit(submit)} noValidate>
            {templates.map(({ label, children }, index) => (
              <Fragment key={index}>
                <PermissionsHeader>
                  <PermissionsH4>{capitalize(label)}</PermissionsH4>
                  <span>{PERMISSIONS.USERS_ASSIGNED}</span>
                </PermissionsHeader>
                <FormSection name={label}>
                  {children.map(({ name, label, disabled, allowEmptyArray }) => (
                    <PermissionsRow
                      key={name}
                      name={name}
                      label={label}
                      usersList={filteredUsersList}
                      isPending={updateIsPending}
                      disabled={disabled}
                      allowEmptyArray={allowEmptyArray}
                    />
                  ))}
                </FormSection>
              </Fragment>
            ))}
            <PermissionsFooter dirty={dirty} handleReset={reset} submitting={submitting} />
          </form>
        )}
      </OverviewListWrapper>
      <Preloader loading={updateIsPending || getPermissionsIsPending} />
    </>
  );
};

Permissions.propTypes = {
  handleSubmit: PropTypes.func,
  dirty: PropTypes.bool,
  reset: PropTypes.func,
};

Permissions = reduxForm({
  form: 'SpecialPermissions',
  enableReinitialize: true,
})(Permissions);

Permissions = connect(state => ({
  initialValues: state.specialPermissions.value,
}))(Permissions);

export { Permissions };
