import {
  transferBlanketHedgeOuncesActionType,
  transferBlanketHedgeOuncesSuccessActionType,
  transferBlanketHedgeOuncesFailureActionType,
} from 'actions/Deliveries/transferBlanketHedgeOunces';

const INITIAL_STATE = {
  isPending: false,
};

const transferBlanketHedgeOuncesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case transferBlanketHedgeOuncesActionType:
      return {
        ...state,
        isPending: true,
      };
    case transferBlanketHedgeOuncesFailureActionType:
      return INITIAL_STATE;
    case transferBlanketHedgeOuncesSuccessActionType:
      return {
        ...state,
        isPending: false,
      };
    default:
      return state;
  }
};

export { transferBlanketHedgeOuncesReducer };
