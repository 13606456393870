import { companyTypesEnum } from 'shared/helpers/constants/filters/companyTypes';
import { statusesForGlobal } from 'shared/helpers/constants/lots/statusesForGlobal';

const uploadGradingReceiptAccess = (
  {
    access: {
      lots: { gradingReceiptUpload },
    },
  },
  lot,
) =>
  gradingReceiptUpload &&
  lot.statusForGlobal !== statusesForGlobal.finalized &&
  lot.sentFromCompany.companyType === companyTypesEnum.grading;

export { uploadGradingReceiptAccess };
