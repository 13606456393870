import React from 'react';
import { connect } from 'react-redux';

import { marketAvailability } from 'actions/Hedges/marketAvailability';
import { getCompanyLocations } from 'actions/Locations/getCompanyLocations';
import {
  getLotsSection,
  appendLotsSection,
  clearLotsSectionList,
  countLots,
  clearLotsCount,
} from 'actions/Lots/getLotsSection';
import { showSnackbar } from 'actions/shared/snackbar';

import { LotsSections } from './LotsSections';

function ConnectedLotsSections(props) {
  return <LotsSections {...props} />;
}

function mapStateToProps({
  auth,
  router,
  companyLocations,
  lotsSections,
  marketAvailabilityDetails,
}) {
  return {
    auth,
    router,
    companyLocations,
    lotsSections,
    marketAvailabilityDetails,
  };
}

const mapDispatchToProps = {
  getCompanyLocations,
  getLotsSection,
  clearLotsSectionList,
  countLots,
  appendLotsSection,
  clearLotsCount,
  marketAvailability,
  showSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConnectedLotsSections);
