import dateAdapter from 'utils/date/dateAdapter';

import { TERMS } from './termsConstants';

const today = () => dateAdapter().startOf('d').valueOf();
const plusYear = () => dateAdapter().startOf('d').add(1, 'y').valueOf();

const createTermConfig = {
  ceramic: {
    type: 'ceramic',
    title: TERMS.CREATE_CERAMIC_TERM,
    defaultValues: {
      treatmentChargePerPound: '0.95',
      smallLotCharge: '500',
      largeLotCharge: '0',
      platinumReturnRate: '95',
      palladiumReturnRate: '95',
      rhodiumReturnRate: '90',
      metalsReturnTermInDays: '115',
      metalsReturnFinanceRate: '8.5',
      advanceRate: '85',
      get startDate() {
        return today();
      },
      get endDate() {
        return plusYear();
      },
    },
  },
  diesel: {
    type: 'diesel',
    title: TERMS.CREATE_DIESEL_TERMS,
    defaultValues: {
      treatmentChargePerPound: '1.45',
      smallLotCharge: '500',
      largeLotCharge: '0',
      platinumReturnRate: '95',
      palladiumReturnRate: '95',
      rhodiumReturnRate: '90',
      metalsReturnTermInDays: '115',
      metalsReturnFinanceRate: '8.5',
      advanceRate: '85',
      get startDate() {
        return today();
      },
      get endDate() {
        return plusYear();
      },
    },
  },
  foil: {
    type: 'foil',
    title: TERMS.CREATE_FOIL_TERM,
    defaultValues: {
      treatmentChargePerPound: '0.75',
      smallLotCharge: '500',
      largeLotCharge: '0',
      platinumReturnRate: '97',
      platinumRefiningCharge: '-10',
      palladiumReturnRate: '97',
      palladiumRefiningCharge: '-10',
      rhodiumReturnRate: '89',
      rhodiumRefiningCharge: '-50',
      metalsReturnTermInDays: '115',
      metalsReturnFinanceRate: '8.5',
      advanceRate: '85',
      get startDate() {
        return today();
      },
      get endDate() {
        return plusYear();
      },
    },
    resources: [
      {
        name: 'platinum',
        title: TERMS.PLATINUM,
      },
      {
        name: 'palladium',
        title: TERMS.PALLADIUM,
      },
      {
        name: 'rhodium',
        title: TERMS.RHODIUM,
      },
    ],
  },
  o2sensor: {
    type: 'o2sensor',
    title: TERMS.CREATE_O2SENSOR_TERM,
    defaultValues: {
      treatmentChargePerPound: '0.85',
      smallLotCharge: '500',
      largeLotCharge: '0',
      platinumReturnRate: '97',
      platinumRefiningCharge: '-10',
      palladiumReturnRate: '97',
      palladiumRefiningCharge: '-10',
      metalsReturnTermInDays: '115',
      metalsReturnFinanceRate: '8.5',
      advanceRate: '85',
      get startDate() {
        return today();
      },
      get endDate() {
        return plusYear();
      },
    },
    resources: [
      {
        name: 'platinum',
        title: TERMS.PLATINUM,
      },
      {
        name: 'palladium',
        title: TERMS.PALLADIUM,
      },
    ],
  },
};

export { createTermConfig };
