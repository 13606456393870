import React, { useState } from 'react';
import Highlighter from 'react-highlight-words';
import { components } from 'react-select';
import Async from 'react-select/async';
import styled from 'styled-components';

import { Icon } from 'shared/components/Icon/Icon';
import { themed, isTouchScreenDevice } from 'shared/helpers/styling/styling';

import { debounce } from 'utils/debounce';

import { styles, MenuList } from '../FieldSelect/FieldSelect';
import { FIELD_SELECT } from '../FieldSelect/constants';
import { withFieldWrapper } from '../FieldWrapper/FieldWrapper';

const DropdownIndicator = props =>
  components.DropdownIndicator && (
    <components.DropdownIndicator {...props}>
      <Icon icon="icon-search" height={30} width={30} />
    </components.DropdownIndicator>
  );

const Option = props => (
  <components.Option {...props}>
    <Highlighter
      searchWords={[props.selectProps.inputValue]}
      textToHighlight={props.label}
      autoEscape
    />
  </components.Option>
);

const FieldSearch = withFieldWrapper(
  ({
    input,
    label,
    field,
    disabled,
    clearable,
    multi,
    meta,
    getOptions,
    getOptionsArgs,
    defaultOptions = false,
    ...rest
  }) => {
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [initialInputValue, setInitialInputValue] = useState('');
    const onChange = value => {
      input.onChange(value);
      isTouchScreenDevice() && input.onBlur(value);
      setMenuIsOpen(false);
      !value && handleOnFocus();
    };

    const onBlur = () => {
      !isTouchScreenDevice() && input.onBlur(input.value);
      setMenuIsOpen(false);
    };

    const handleInputChange = inputValue => {
      setInitialInputValue(inputValue);
    };

    const handleKeyDown = () => {
      setMenuIsOpen(true);
    };

    const handleOnFocus = () => {
      defaultOptions && setMenuIsOpen(true);
    };

    const getAsyncOptions = debounce(async (query, callback) => {
      const getOpt = await getOptions(query, getOptionsArgs);
      callback(getOpt.options);
    }, 300);

    return (
      <Async
        {...rest}
        {...input}
        id={input.name}
        onChange={onChange}
        onBlur={onBlur}
        components={{
          DropdownIndicator,
          Option,
          MenuList,
        }}
        hasError={meta.touched && meta.error}
        defaultOptions={defaultOptions}
        styles={styles}
        isDisabled={disabled}
        isClearable={clearable === undefined || clearable}
        isMulti={multi}
        loadOptions={(...args) => getAsyncOptions(...args, opt => opt)}
        noOptionsMessage={() => FIELD_SELECT.NO_OPTION_MESSAGE}
        inputValue={disabled ? '' : initialInputValue}
        menuIsOpen={menuIsOpen && !disabled}
        onInputChange={handleInputChange}
        onKeyDown={handleKeyDown}
        onFocus={handleOnFocus}
      />
    );
  },
);

const FieldSearchStyled = styled(FieldSearch).attrs(themed(({ disabled }) => ({ disabled })))``;

export { FieldSearchStyled as FieldSearch, DropdownIndicator };
