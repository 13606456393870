import ReactPaginate from 'react-paginate';
import styled from 'styled-components';

import { media } from 'shared/helpers/styling/styling';

export const StyledPages = styled(ReactPaginate)`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;

  .Pages {
    &-page {
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &-link {
      font-size: 0.8em;
      font-family: 'Open Sans', sans-serif;
      cursor: pointer;
      outline: none;
      color: var(--gullGray);

      &:hover {
        color: var(--gullGray);
        text-decoration: none;

        ${media.desktop`
        color: var(--whiteGRG);
        `}
      }
    }

    &-active {
      border: 2px solid var(--atlantis);
      border-radius: 50%;
      color: var(--whiteGRG);
    }

    &-previous,
    &-next {
      display: flex;
      align-items: center;

      &.disabled {
        a {
          background: var(--gullGray);
        }
      }
    }
  }
  .break {
    width: 40px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--gullGray);

    a {
      color: var(--gullGray);
    }
  }

  li a {
    outline: none;
  }
`;

export const StyledWrapper = styled('div')`
  ${media.desktop`
  margin-left: 120px; 
  `}
`;
