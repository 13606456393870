import PropTypes from 'prop-types';
import styled, { css, keyframes } from 'styled-components';

import { Button } from 'shared/components/Buttons/Button';
import { FILTERS } from 'shared/helpers/constants/filters/filtersConstants';
import { cssVar, pxToRem, media } from 'shared/helpers/styling/styling';

function FilterCollapseLayout({
  collapse,
  toggleFilters,
  className,
  filters,
  excludedFilters = [],
}) {
  const filtersToggle = () => {
    toggleFilters(collapse);
  };

  const parseFilters = filters => {
    excludedFilters.forEach(filterName => delete filters[filterName]);

    return {
      ...filters,
      query: null,
    };
  };

  const getFiltersCount = filters =>
    filters &&
    Object.values(parseFilters(filters)).reduce((count, filter) => {
      if (Array.isArray(filter)) {
        return count + +!!filter.length;
      }

      if (typeof filter === 'boolean') {
        return count + 1;
      }

      if (Object(filter) === filter) {
        const keys = Object.keys(filter);
        const includesId = keys.some(key => key.includes('id'));

        return (
          count +
          +Object.entries(filter)
            .map(([key, value]) => {
              if (includesId && !key.includes('id')) {
                return null;
              }

              return [key, value];
            })
            .filter(Boolean)
            .reduce((propsCount, [, value]) => propsCount + +!!value, 0)
        );
      }

      if (!Number.isFinite(filter) && typeof filter === 'number') {
        return count;
      }

      return count + +!!filter;
    }, 0);

  return (
    <Button
      className={className}
      onClick={filtersToggle}
      noOutline
      bgColor={cssVar('fiordLighter')}
      bgColorHover={cssVar('fiordLighter')}
      borderRadius={10}
      width={pxToRem(112)}
      height={pxToRem(33)}
      fitToContent
      margin={false}
      badge={getFiltersCount({ ...filters }) || null}
    >
      <div>{FILTERS.FILTERS}</div>
    </Button>
  );
}

const expand = keyframes`
  from {
   transform: translateX(-${pxToRem(25)}) rotateZ(0deg);
   opacity: 1;
  }
  to {
    transform: translateX(-${pxToRem(25)}) rotateZ(90deg);
    opacity: 0;
  }
`;

const FilterCollapse = styled(FilterCollapseLayout)`
  color: ${cssVar('whiteGRG')};
  font-size: ${pxToRem(14)};
  margin-right: ${pxToRem(10)};

  ${media.phone`
    margin-top: 0px;
  `}

  & > * {
    margin-right: ${pxToRem(13)};
  }

  ${({ collapse }) =>
    collapse
      ? css`
  & > div {
    margin: 12px 18px 12px 52px;
     &:after {
    content: '';
    position: absolute;
    transform: translateX(-4.5rem) translateY(${pxToRem(7)});
    width: 17px;
    height: 2px;
    background-color: ${cssVar('whiteGRG')};
    }
    &:before {
    content: '';
    position: absolute;
    width: 2px;
    height: 17px;
    opacity: 0;
    transform: translateX(-${pxToRem(25)});
    background-color: ${cssVar('whiteGRG')};
    margin-right: 4rem;
  }`
      : css`
  & > div {
    margin: 12px 18px 12px 52px;
     &:after {
    content: '';
    position: absolute;
    transform: translateX(-4.5rem) translateY(${pxToRem(7)});
    width: 17px;
    height: 2px;
    background-color: ${cssVar('whiteGRG')};
    }
    &:before {
    content: '';
    position: absolute;
    width: 2px;
    height: 17px;
    transform: translateX(-${pxToRem(25)});
    background-color: ${cssVar('whiteGRG')};
    margin-right: 4rem;
    animation: ${expand} .2s linear reverse;
  }
`}
`;

FilterCollapse.propTypes = {
  collapse: PropTypes.bool,
  toggleFilters: PropTypes.func,
  className: PropTypes.string,
  filters: PropTypes.shape({
    fundsType: PropTypes.arrayOf(PropTypes.string),
    isPaid: PropTypes.bool,
    paidAtEnd: PropTypes.number,
    paidAtStart: PropTypes.number,
    paymentType: PropTypes.arrayOf(PropTypes.string),
    query: PropTypes.string,
    status: PropTypes.arrayOf(PropTypes.string),
  }),
  excludedFilters: PropTypes.arrayOf(PropTypes.string),
};

export { FilterCollapse };
