import React from 'react';

import { StatusIndicator } from 'shared/components/StatusIndicator/StatusIndicator';
import { setDesignation } from 'shared/helpers/dataParsers/lot/setDesignation';
import { formatDate, formatToUS } from 'shared/helpers/parsers/formaters';
import { replaceEmpty } from 'shared/helpers/parsers/text';

import { SHIPMENTS } from './shipmentsConstants';

const assignedLotsTemplate = {
  label: SHIPMENTS.ASSIGNED_LOTS,
  render: ({ populatedLots }) =>
    populatedLots && populatedLots.length
      ? populatedLots.map(({ grgLotNumber, designation }) =>
          setDesignation(grgLotNumber, designation),
        )
      : SHIPMENTS.EMPTY_FIELD,
  highlight: true,
  includeRegExp: true,
};

const itemsTemplateOutgoing = [
  {
    label: SHIPMENTS.STATUS,
    render: ({ status }) => <StatusIndicator status={status} />,
  },
  {
    label: SHIPMENTS.SHIP_DATE,
    render: ({ estPickupDate }) => formatDate(estPickupDate),
  },
  {
    label: SHIPMENTS.EST_DELIVERY_DATE,
    render: ({ estDeliveryDate }) => formatDate(estDeliveryDate),
  },
  {
    label: SHIPMENTS.LOAD_NUMBER,
    render: ({ loadNumber }) => loadNumber || SHIPMENTS.EMPTY_FIELD,
    highlight: true,
  },
  assignedLotsTemplate,
  {
    label: SHIPMENTS.GROSS_DECLARED,
    render: ({ weightGrossDeclared }) => replaceEmpty(formatToUS(weightGrossDeclared)),
  },
  {
    label: SHIPMENTS.PACKAGES,
    render: ({ assignedPackagesCount }) => replaceEmpty(assignedPackagesCount),
  },
];

const itemsTemplateIncoming = [
  {
    label: SHIPMENTS.STATUS,
    render: ({ status }) => <StatusIndicator status={status} />,
  },
  {
    label: SHIPMENTS.SENT_FROM_COMPANY,
    render: ({ sentFromCompany }) => sentFromCompany && sentFromCompany.companyName,
  },
  {
    label: SHIPMENTS.SENT_FROM_COMPANY_LOCATION,
    render: ({ sentFromCompanyLocation }) =>
      sentFromCompanyLocation && sentFromCompanyLocation.name,
  },
  {
    label: SHIPMENTS.EST_PICKUP_DATE,
    render: ({ estPickupDate }) => formatDate(estPickupDate),
  },
  {
    label: SHIPMENTS.EST_DELIVERY_DATE,
    render: ({ estDeliveryDate }) => formatDate(estDeliveryDate),
  },
  assignedLotsTemplate,
  {
    label: SHIPMENTS.GROSS_DECLARED,
    render: ({ weightGrossDeclared }) => replaceEmpty(formatToUS(weightGrossDeclared)),
  },
  {
    label: SHIPMENTS.PACKAGES,
    render: ({ assignedPackagesCount }) => replaceEmpty(assignedPackagesCount),
  },
];

const itemsTemplateMobile = [
  {
    label: SHIPMENTS.STATUS,
    render: ({ status }) => <StatusIndicator status={status} />,
  },
  {
    label: SHIPMENTS.EST_DELIVERY_DATE,
    render: ({ estDeliveryDate }) => formatDate(estDeliveryDate),
  },
  {
    label: SHIPMENTS.SHIP_DATE,
    render: ({ estPickupDate }) => formatDate(estPickupDate),
  },
];

const itemsTemplateCustomer = [
  {
    label: SHIPMENTS.STATUS,
    render: ({ status }) => <StatusIndicator status={status} />,
  },
  {
    label: SHIPMENTS.SENT_FROM_COMPANY_LOCATION,
    render: ({ sentFromCompanyLocation }) => sentFromCompanyLocation.name,
  },
  {
    label: SHIPMENTS.EST_PICKUP_DATE,
    render: ({ estPickupDate }) => formatDate(estPickupDate),
  },
  {
    label: SHIPMENTS.EST_DELIVERY_DATE,
    render: ({ estDeliveryDate }) => formatDate(estDeliveryDate),
  },
  assignedLotsTemplate,
  {
    label: SHIPMENTS.GROSS_DECLARED,
    render: ({ weightGrossDeclared }) => replaceEmpty(formatToUS(weightGrossDeclared)),
  },
  {
    label: SHIPMENTS.PACKAGES,
    render: ({ assignedPackagesCount }) => replaceEmpty(assignedPackagesCount),
  },
];

const itemsTemplateMobileCustomer = [
  {
    label: SHIPMENTS.STATUS,
    render: ({ status }) => <StatusIndicator status={status} />,
  },
  {
    label: SHIPMENTS.EST_DELIVERY_DATE,
    render: ({ estDeliveryDate }) => formatDate(estDeliveryDate),
  },
  {
    label: SHIPMENTS.EST_PICKUP_DATE,
    render: ({ estPickupDate }) => formatDate(estPickupDate),
  },
];

const itemsDetailsTemplateIncoming = [
  {
    label: SHIPMENTS.SENT_FROM_COMPANY,
    render: ({ sentFromCompany }) => sentFromCompany.companyName,
  },
  {
    label: SHIPMENTS.SENT_FROM_COMPANY_LOCATION,
    render: ({ sentFromCompanyLocation }) => sentFromCompanyLocation.name,
  },
  assignedLotsTemplate,
  {
    label: SHIPMENTS.GROSS_DECLARED,
    render: ({ weightGrossDeclared }) => replaceEmpty(formatToUS(weightGrossDeclared)),
  },
  {
    label: SHIPMENTS.PACKAGES,
    render: ({ assignedPackagesCount }) => replaceEmpty(assignedPackagesCount),
  },
];

const [, , , ...itemsDetailsTemplateOutgoing] = itemsTemplateOutgoing;
const itemsDetailsTemplateCustomer = [
  {
    label: SHIPMENTS.SENT_FROM_COMPANY_LOCATION,
    render: ({ sentFromCompanyLocation }) => sentFromCompanyLocation.name,
  },
  assignedLotsTemplate,
  {
    label: SHIPMENTS.GROSS_DECLARED,
    render: ({ weightGrossDeclared }) => replaceEmpty(formatToUS(weightGrossDeclared)),
  },
  {
    label: SHIPMENTS.PACKAGES,
    render: ({ assignedPackagesCount }) => replaceEmpty(assignedPackagesCount),
  },
];

export {
  itemsTemplateOutgoing,
  itemsTemplateIncoming,
  itemsTemplateCustomer,
  itemsTemplateMobile,
  itemsTemplateMobileCustomer,
  itemsDetailsTemplateIncoming,
  itemsDetailsTemplateOutgoing,
  itemsDetailsTemplateCustomer,
};
