import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { Authorization } from 'components/Authorization/Authorization';
import { NotFound } from 'components/ErrorPage/ErrorPage';

import { ConnectedLocationDetails } from './components/LocationDetails/ConnectedLocationDetails';
import { ConnectedLocationsList } from './components/LocationsList/ConnectedLocationsList';

function Locations(props) {
  const { locations } = props.auth.access;

  const AuthLocationsList = Authorization(ConnectedLocationsList, locations.ownLocations.list);
  const AuthLocationDetails = Authorization(
    ConnectedLocationDetails,
    locations.ownLocations.details,
  );

  return (
    <Switch>
      <Route exact path="/locations/:id" component={AuthLocationsList} />
      <Route path="/locations/:id/location/:locationId" component={AuthLocationDetails} />
      <Route component={NotFound} />
    </Switch>
  );
}

export { Locations };
