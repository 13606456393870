import { connect } from 'react-redux';

import { selfDeliveryRequestStatusChange } from 'actions/SelfDeliveryRequests/selfDeliveryRequestStatusChange';
import { showSnackbar } from 'actions/shared/snackbar';

import { snackbarOptions } from 'shared/helpers/matchers/snackbar/snackbarOptions';

function SelfDeliveryRequestStatusChangeBase(props) {
  const resolveAction = result => {
    props.showSnackbar(snackbarOptions(result.messageType), result.message);
  };

  const onClick = () => {
    const { id, status } = props;

    props.selfDeliveryRequestStatusChange(id, status).then(resolveAction).catch(resolveAction);
  };

  return props.children({ onClick });
}

const mapDispatchToProps = {
  selfDeliveryRequestStatusChange,
  showSnackbar,
};

const ConnectedSelfDeliveryRequestStatusChange = connect(
  null,
  mapDispatchToProps,
)(SelfDeliveryRequestStatusChangeBase);

export {
  ConnectedSelfDeliveryRequestStatusChange as SelfDeliveryRequestStatusChange,
  SelfDeliveryRequestStatusChangeBase,
};
