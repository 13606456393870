import {
  companyAddFileActionType,
  companyRemoveFileActionType,
  companyAddExistingFilesActionType,
  companyRemoveExistingFileActionType,
  companyClearAllFilesActionType,
} from 'actions/Companies/companyAddFile';

const INITIAL_STATE = {
  files: {
    photoId: [],
    documentForAML: [],
    businessLicense: [],
    materialSourcingAgreement: [],
    locationVerification: [],
    dueDiligenceQuestionnaire: [],
  },
  existingFiles: {
    photoId: [],
    documentForAML: [],
    businessLicense: [],
    materialSourcingAgreement: [],
    locationVerification: [],
    dueDiligenceQuestionnaire: [],
  },
};

export const companyFilesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case companyAddFileActionType:
      const { type, file } = action.payload.file;
      const filteredFiles = (state.files[type] || []).filter(file => file._id);

      return {
        ...state,
        files: {
          ...state.files,
          [type]: [...filteredFiles, ...file],
        },
      };

    case companyRemoveFileActionType:
      const { fileType, fileIndex } = action.payload;
      return {
        ...state,
        files: {
          ...state.files,
          [fileType]: state.files[fileType].filter((_, index) => index !== fileIndex),
        },
      };
    case companyAddExistingFilesActionType:
      return {
        ...state,
        existingFiles: {
          ...action.payload,
        },
      };
    case companyRemoveExistingFileActionType:
      const { fileId } = action.payload;
      return {
        ...state,
        existingFiles: {
          ...state.existingFiles,
          [action.payload.fileType]: state.existingFiles[action.payload.fileType].filter(
            ({ _id }) => _id !== fileId,
          ),
        },
      };
    case companyClearAllFilesActionType:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
