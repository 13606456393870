import styled from 'styled-components';

export const StyledActions = styled.div`
  margin-top: ${({ displayWarning }) => (displayWarning ? '97px' : '180px')};
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
`;

export const StyledForm = styled.form`
  margin-top: 2.5rem;
`;
