import {
  showPDFPreviewActionType,
  hidePDFPreviewActionType,
  fetchPDFPreviewActionType,
  fetchPDFPreviewFailureActionType,
} from 'actions/shared/PDFPreview.js';

const INITIAL_STATE = {
  isOpen: false,
  isPending: false,
  filePath: '',
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case fetchPDFPreviewActionType:
      return {
        ...state,
        isPending: true,
      };
    case showPDFPreviewActionType:
      return {
        ...state,
        isOpen: true,
        isPending: false,
        filePath: action.filePath,
      };
    case hidePDFPreviewActionType:
    case fetchPDFPreviewFailureActionType:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};
