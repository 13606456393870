import {
  requestSettlementActionType,
  requestSettlementSuccessActionType,
  requestSettlementFailureActionType,
} from 'actions/PoolAccounts/requestSettlement';

const INITIAL_STATE = {
  isPending: false,
};

const requestSettlementReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case requestSettlementActionType:
      return {
        isPending: true,
      };
    case requestSettlementSuccessActionType:
    case requestSettlementFailureActionType:
      return {
        isPending: false,
      };
    default:
      return state;
  }
};

export { requestSettlementReducer };
