const NOTIFICATION = {
  STATUS: 'Status',
  MESSAGE_SUBJECT: 'Message subject',
  MESSAGE_TEXT: 'Message text',
  CREATED_ON: 'Created on',
  RESOLVED_BY: 'Resolved by',
  SENT_TO: 'Sent to',
  ACTIONS: 'Actions',
  MARK_AS_RESOLVED: 'Mark as resolved',
  NOTIFICATIONS: 'Notifications',
  ALL_NOTIFICATIONS: 'All notifications',
  GENERAL_DETAILS: 'General details',
  MARK_ALL_RESOLVED_AS_READ: 'Mark all resolved as read',
};

export { NOTIFICATION };
