import axios from 'axios';

import { parseLotsFilters } from 'shared/helpers/dataParsers/lot/parseLotsFilters';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getLotsListActionType = 'GET_LOTS_LIST';
function getLotsListAction() {
  return {
    type: getLotsListActionType,
  };
}

const getLotsListSuccessActionType = 'GET_LOTS_LIST_SUCCESS';
function getLotsListSuccessAction({ docs, pages, limit, page }) {
  return {
    type: getLotsListSuccessActionType,
    payload: {
      lots: docs,
      pages,
      limit,
      page,
    },
  };
}

const getLotsListFailureActionType = 'GET_LOTS_LIST_FAILURE';
function getLotsListFailureAction(errorMessage) {
  return {
    type: getLotsListFailureActionType,
    payload: {
      errorMessage,
    },
  };
}

const clearLotsListActionType = 'CLEAR_LOTS_LIST_ACTION';
function clearLotsList() {
  return {
    type: clearLotsListActionType,
  };
}

function getLotsList(limit, page, searchFilter, locationFilters, businessDaysOut) {
  return dispatch => {
    dispatch(getLotsListAction());
    return axios({
      method: 'GET',
      url: `${API_HOST_AUTH}/lots`,
      params: parseLotsFilters(locationFilters, limit, page, searchFilter || '', businessDaysOut),
    })
      .then(({ data }) => dispatch(getLotsListSuccessAction(data)))
      .catch(() => dispatch(getLotsListFailureAction('')));
  };
}

export {
  getLotsListActionType,
  getLotsListSuccessActionType,
  getLotsListFailureActionType,
  clearLotsListActionType,
  getLotsList,
  clearLotsList,
};
