import { INSPECTIONS } from 'shared/helpers/constants/inspections/inspectionConstants';

function validate(values) {
  const errors = {};

  if (!values.relatedLot) {
    errors.relatedLot = INSPECTIONS.INVALID_FORM_VALUES.RELATED_LOT_EMPTY;
  }

  if (!values.inspectionScheduledFor) {
    errors.inspectionScheduledFor = INSPECTIONS.INVALID_FORM_VALUES.INSPECTION_SCHEDULED_FOR_EMPTY;
  }

  return errors;
}

export { validate };
