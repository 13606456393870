import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { getLotsForInspection, clearAssignedLotsAction } from 'actions/Lots/getAssignedLots';
import { requestLotInspection } from 'actions/Lots/lotInspections';
import { closeModal, openModal } from 'actions/shared/modal';
import { showSnackbar } from 'actions/shared/snackbar';

import { RequestLotInspectionBase } from './RequestLotInspection';

const mapStateToProps = ({ modal, lotInspections, assignedLots }) => ({
  modal,
  lotInspections,
  assignedLots,
});

const mapDispatchToProps = {
  openModal,
  closeModal,
  showSnackbar,
  requestLotInspection,
  getLotsForInspection,
  clearAssignedLotsAction,
};

const RequestLotInspection = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RequestLotInspectionBase),
);

export { RequestLotInspection };
