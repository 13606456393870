import { string, object } from 'yup';

import { message } from 'shared/helpers/constants/lots/createVirtualLotConstant';
import { SECTIONS } from 'shared/helpers/constants/warehouseDashboard/labels';
import { safeParseFloat } from 'shared/helpers/parsers/number';
import { FLOAT_REGEX } from 'shared/helpers/validations/regularExpressions';

const sections = [
  SECTIONS.INCOMING.id,
  SECTIONS.GRADING.id,
  SECTIONS.CURRENTLY_PROCESSING.id,
  SECTIONS.COUNTING_REQUIRED.id,
];

export const CreateVirtualLotSchema = object().shape({
  name: string()
    .max(50, message.INVALID_VIRTUAL_LOT_NAME_LENGTH)
    .trim(message.INVALID_VIRTUAL_LOT_NAME_EMPTY)
    .required(message.INVALID_VIRTUAL_LOT_NAME_EMPTY),
  section: string().required(message.INVALID_VIRTUAL_LOT_SECTION_EMPTY),
  subsection: string().when('section', {
    is: section => sections.includes(section),
    then: string().required(message.INVALID_VIRTUAL_LOT_SUBSECTION_EMPTY),
    otherwise: string().nullable(),
  }),
  weight: string().when('section', {
    is: section => section === SECTIONS.COMPLETE.id,
    then: string()
      .max(10, message.INVALID_VIRTUAL_LOT_WEIGHT_RECEIVED_INVALID)
      .matches(FLOAT_REGEX, message.INVALID_VIRTUAL_LOT_WEIGHT_RECEIVED_INVALID)
      .test({
        name: 'greaterThan0',
        message: message.INVALID_VIRTUAL_LOT_WEIGHT_NEGATIVE,
        test: val => {
          if (!val) {
            return true;
          }
          return safeParseFloat(val) > 0;
        },
      })
      .required(message.INVALID_VIRTUAL_LOT_WEIGHT_EMPTY),
    otherwise: string()
      .max(10, message.INVALID_VIRTUAL_LOT_WEIGHT_RECEIVED_INVALID)
      .matches(FLOAT_REGEX, message.INVALID_VIRTUAL_LOT_WEIGHT_RECEIVED_INVALID)
      .test({
        name: 'greaterThan0',
        message: message.INVALID_VIRTUAL_LOT_WEIGHT_NEGATIVE,
        test: val => {
          if (!val) {
            return true;
          }
          return safeParseFloat(val) > 0;
        },
      }),
  }),
});
