import { dateToTimestamp } from 'shared/helpers/parsers/date';

function parseShipmentData(shipment) {
  return {
    sentFromCompanyLocation: shipment.sentFromCompanyLocation.value,
    shipmentCompany: shipment.shipmentCompany,
    shipmentSeal: shipment.shipmentSeal,
    estPickupDate: dateToTimestamp(shipment.estPickupDate),
    estDeliveryDate: dateToTimestamp(shipment.estDeliveryDate),
    packages: shipment.packages,
  };
}

export { parseShipmentData };
