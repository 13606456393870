import styled, { css } from 'styled-components';

import { cssVar, pxToRem } from 'shared/helpers/styling/styling';

export const Container = styled.div`
  grid-area: details;
  align-self: start;
  padding: 11px 22px;
  margin-bottom: 10px;
  color: ${cssVar('silverDarker')};
  background-color: ${cssVar('limedSpruceCalendar')};
  border: 2px dashed ${cssVar('fiordDarker')};
`;

export const Row = styled.div`
  font-size: ${pxToRem(13)};

  ${({ nestedRow }) =>
    nestedRow &&
    css`
      padding-left: ${pxToRem(10)};
    `}

  span:first-child {
    font-weight: bold;
  }
`;
