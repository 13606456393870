import React, { Component } from 'react';
import { change } from 'redux-form';

import { FILES_CONSTANTS } from 'shared/helpers/constants/filesConstants';
import { validateAttachment } from 'shared/helpers/validations/attachment/validateAttachment';

import { FieldUpload } from '../FiledUpload/FieldUpload';
import { FieldUploadList } from '../FiledUpload/FieldUploadList';

class FieldAttachment extends Component {
  componentWillUnmount() {
    this.props.filesError();
  }

  uploadFile = e => {
    const attachmentArray = [].slice.call(e.target.files);

    this.props.dispatch(change(this.props.form, 'attachment', attachmentArray));
    this.props.addFile(attachmentArray);
  };

  isLengthValid() {
    const { attachment } = this.props;

    return attachment.files.length <= FILES_CONSTANTS.LIMIT;
  }

  render() {
    const { input, label, id, removeFile, attachment } = this.props;

    const handlers = {
      ...input,
      onChange: this.uploadFile,
    };

    let hasError = false;

    const files = (attachment.files || []).map((file, id) => {
      const error = validateAttachment(file, Object.values(FILES_CONSTANTS.VALID_EXTENSIONS))
        ? null
        : FILES_CONSTANTS.ERROR_SIZE;

      hasError = hasError || error;

      return {
        file: {
          name: file.name,
          meta: { error, dirty: true },
          keyName: `${file.originalName || file.name}-${id}`,
        },
        fileToUpload: file,
        onDelete: () => removeFile(file),
      };
    });

    const error = this.isLengthValid()
      ? hasError
      : FILES_CONSTANTS.ERROR_LENGTH(FILES_CONSTANTS.LIMIT);

    this.props.filesError(hasError);

    return (
      <>
        <FieldUpload
          id={id}
          input={handlers}
          label={label}
          meta={{ error, dirty: true }}
          multiple
          validExtensions={Object.values(FILES_CONSTANTS.VALID_EXTENSIONS).join(', ')}
        />
        <FieldUploadList files={files} />
      </>
    );
  }
}

export { FieldAttachment };
