import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getReportsType = 'GET_REPORTS';
const getReportsAction = () => ({
  type: getReportsType,
});

const getReportsSuccessType = `${getReportsType}_SUCCESS`;
const getReportsSuccessAction = reports => ({
  type: getReportsSuccessType,
  payload: {
    reports,
  },
});

const getReportsFailureType = `${getReportsType}_FAILURE`;
const getReportsFailureAction = () => ({
  type: getReportsFailureType,
});

const getReports = () => dispatch => {
  dispatch(getReportsAction());

  return axios
    .get(`${API_HOST_AUTH}/reports`)
    .then(res => {
      dispatch(getReportsSuccessAction(res.data));

      return res.data;
    })
    .catch(err => {
      dispatch(getReportsFailureAction());

      return Promise.reject(err.data);
    });
};

export { getReportsType, getReportsSuccessType, getReportsFailureType, getReports };
