import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { StatusIndicator } from 'shared/components/StatusIndicator/StatusIndicator';
import { pxToRem, cssVar, media } from 'shared/helpers/styling/styling';

const DetailsContainer = styled.div`
  display: flex;
  align-items: center;
  ${media.mobile`
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
`}
`;

const MainHeader = styled.span`
  font-size: ${pxToRem(30)};
  font-weight: 500;
  color: ${cssVar('whiteGRG')};
  word-break: break-word;

  ${media.phone`
  font-size: ${pxToRem(20)};
  `}
`;

const StatusContainer = styled.div`
  display: inline-block;
  margin: 0 22px;

  ${media.mobile`
 margin: 0 2px;
 `}
`;

const ChildrenContainer = styled.div`
  ${media.phone`
width: 100%;
`}
`;

const DetailsHeaderLayout = ({
  className,
  header,
  children,
  status,
  statusColor,
  statusBackgroundColor,
  tooltipContent,
  tooltipOptions,
  tooltipInitShow,
  tooltipNeverHide,
  id,
}) => (
  <div className={className}>
    <DetailsContainer>
      <MainHeader>{header}</MainHeader>
      {status && (
        <StatusContainer>
          <StatusIndicator
            status={status}
            color={statusColor}
            backgroundColor={statusBackgroundColor}
            tooltipContent={tooltipContent}
            tooltipOptions={tooltipOptions}
            tooltipInitShow={tooltipInitShow}
            tooltipNeverHide={tooltipNeverHide}
            id={id}
          />
        </StatusContainer>
      )}
    </DetailsContainer>
    <ChildrenContainer>{children}</ChildrenContainer>
  </div>
);
const DetailsHeader = styled(DetailsHeaderLayout)`
  width: 100%;
  margin-bottom: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;

  ${media.phone`
    flex-direction: column;
    align-items: row;
    padding: 22px 22px 22px 22px;
    margin-bottom: 0px;
  `}

  @media print {
    padding: 0;
    margin: 0;
  }
`;

DetailsHeader.propTypes = {
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  status: PropTypes.string,
  statusColor: PropTypes.string,
  statusBackgroundColor: PropTypes.string,
  tooltipContent: PropTypes.string,
  tooltipOptions: PropTypes.object,
  tooltipInitShow: PropTypes.bool,
};

export { DetailsHeader };
