import React, { useCallback } from 'react';
import { messageTypes } from 'shared/helpers/constants/snackbar/snackbarMessageTypes';
import { snackbarOptions } from 'shared/helpers/matchers/snackbar/snackbarOptions';

function PrintLabel({
  children,
  path,
  errorMessage,
  printLabels,
  showSnackbar,
}) {
  const onClick = useCallback(() => {
    printLabels(path).catch(() => {
      showSnackbar(snackbarOptions(messageTypes.error), errorMessage);
    });
  }, [path]);

  return (
    <>
      {children({ onClick })}
    </>
  );
}

export { PrintLabel };
