import axios from 'axios';

import { parsePaymentsFilters } from 'shared/helpers/dataParsers/payments/parsePaymentsFilters';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getReportsTotalPaymentsActionType = 'GET_REPORTS_TOTAL_PAYMENTS_LIST';
const getReportsTotalPaymentsAction = (filters, pagination) => ({
  type: getReportsTotalPaymentsActionType,
  payload: { filters, pagination },
});

const getReportsTotalPaymentsSuccessActionType = `${getReportsTotalPaymentsActionType}_SUCCESS`;
const getReportsTotalPaymentsSuccessAction = payments => ({
  type: getReportsTotalPaymentsSuccessActionType,
  payload: { payments },
});

const getReportsTotalPaymentsFailureActionType = `${getReportsTotalPaymentsActionType}_FAILURE`;
const getReportsTotalPaymentsFailureAction = () => ({
  type: getReportsTotalPaymentsFailureActionType,
});

const clearReportsTotalPaymentsActionType = `${getReportsTotalPaymentsActionType}_CLEAR`;
const clearReportsTotalPaymentsAction = () => ({
  type: clearReportsTotalPaymentsActionType,
});

const getReportsTotalPayments =
  (filters, pagination, sortByPaidAt = true) =>
  dispatch => {
    const parsedFilters = parsePaymentsFilters(filters);

    dispatch(getReportsTotalPaymentsAction(parsedFilters, pagination));

    return axios
      .get(`${API_HOST_AUTH}/payments`, {
        params: {
          ...parsedFilters,
          ...pagination,
          sortByPaidAt,
        },
      })
      .then(({ data }) => {
        dispatch(getReportsTotalPaymentsSuccessAction(data));
        return data;
      })
      .catch(error => {
        dispatch(getReportsTotalPaymentsFailureAction());
        return Promise.reject(error.data);
      });
  };

export {
  clearReportsTotalPaymentsAction,
  clearReportsTotalPaymentsActionType,
  getReportsTotalPaymentsActionType,
  getReportsTotalPaymentsSuccessActionType,
  getReportsTotalPaymentsFailureActionType,
  getReportsTotalPayments,
};
