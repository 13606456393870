import { connect } from 'react-redux';

import { deleteUser as deleteUserAction } from 'actions/Users/deleteUser';
import { openModal } from 'actions/shared/modal';
import { showSnackbar } from 'actions/shared/snackbar';

import { deleteUserModal } from 'shared/helpers/constants/modalConstants';
import { messageTypes } from 'shared/helpers/constants/snackbar/snackbarMessageTypes';
import { snackbarOptions } from 'shared/helpers/matchers/snackbar/snackbarOptions';

function DeleteUser(props) {
  const deleteUser = () => {
    props.openModal(deleteUserModal, () => {
      props.routeChange && props.routeChange();
      props.deleteUser(props.user._id).then(({ message, messageType }) => {
        props.showSnackbar(snackbarOptions(messageType), message);
        props.onDelete && messageType === messageTypes.success && props.onDelete();
      });
    });
  };

  return props.children({ deleteUser });
}

const mapDispatchToProps = {
  openModal,
  showSnackbar,
  deleteUser: deleteUserAction,
};

const ConnectedDeleteUser = connect(null, mapDispatchToProps)(DeleteUser);

export { ConnectedDeleteUser as DeleteUser, DeleteUser as DeleteUserModal };
