import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getSelfDeliveryRequestDetailsActionType = 'GET_SELF_DELIVERY_REQUEST_DETAILS';
const getSelfDeliveryRequestDetailsAction = () => ({
  type: getSelfDeliveryRequestDetailsActionType,
});

const getSelfDeliveryRequestDetailsSuccessActionType = `${getSelfDeliveryRequestDetailsActionType}_SUCCESS`;
const getSelfDeliveryRequestDetailsSuccessAction = sdRequest => ({
  type: getSelfDeliveryRequestDetailsSuccessActionType,
  payload: {
    sdRequest,
  },
});

const getSelfDeliveryRequestDetailsFailureActionType = `${getSelfDeliveryRequestDetailsActionType}_FAILURE`;
const getSelfDeliveryRequestDetailsFailureAction = errorMessage => ({
  type: getSelfDeliveryRequestDetailsFailureActionType,
  payload: {
    errorMessage,
  },
});

const clearSelfDeliveryRequestDetailsActionType = 'CLEAR_SELF_DELIVERY_REQUEST_DETAILS_ACTION';
const clearSelfDeliveryRequestDetails = () => ({
  type: clearSelfDeliveryRequestDetailsActionType,
});

const getSelfDeliveryRequest = id => dispatch => {
  dispatch(getSelfDeliveryRequestDetailsAction());

  return axios
    .get(`${API_HOST_AUTH}/self-delivery-requests/${id}`)
    .then(res => {
      dispatch(getSelfDeliveryRequestDetailsSuccessAction(res.data));
      return res.data;
    })
    .catch(err => {
      dispatch(getSelfDeliveryRequestDetailsFailureAction(err));
      return Promise.reject(err.data);
    });
};

export {
  getSelfDeliveryRequestDetailsActionType,
  getSelfDeliveryRequestDetailsAction,
  getSelfDeliveryRequestDetailsSuccessActionType,
  getSelfDeliveryRequestDetailsSuccessAction,
  getSelfDeliveryRequestDetailsFailureActionType,
  getSelfDeliveryRequestDetailsFailureAction,
  clearSelfDeliveryRequestDetailsActionType,
  clearSelfDeliveryRequestDetails,
  getSelfDeliveryRequest,
};
