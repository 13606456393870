const message = {
  INVALID_RELATED_COMPANY_EMPTY: 'Related company is required.',
  EXCEPTION_TYPE_EMPTY: 'Exception type must be selected in order to fill the form.',

  TRUSTED_COMPANIES_CREATE_SUCCESS: 'Trusted company has been successfully added.',
  TRUSTED_COMPANIES_CREATE_ERROR: 'There was an error during trusted company creation.',
  TRUSTED_COMPANIES_DELETE_SUCCESS: 'Trusted company has been successfully deleted.',
  TRUSTED_COMPANIES_DELETE_ERROR: 'There was an error during delete.',
};

export { message };
