import React from 'react';
import styled, { css } from 'styled-components';

import { Button } from 'shared/components/Buttons/Button';
import { Icon } from 'shared/components/Icon/Icon';
import { defaultModal } from 'shared/helpers/constants/modalConstants';
import { isPhone } from 'shared/helpers/constants/resolutionsConstants';
import { cssVar } from 'shared/helpers/styling/styling';
import { useWindowWidth } from 'shared/hooks/useWindowWidth';

const ModalButtons = ({ closeModal, modal }) => {
  const windowWidth = useWindowWidth();

  const modalClose = () => {
    modal.closeCallback();
    closeModal();
  };

  const onCustomAction = () => {
    modal.customActionCallback();
    closeModal();
  };

  const onAcceptClick = () => {
    modal.modalCallback();
    modalClose();
  };

  const { modalOptions } = modal;
  const acceptIcon = modalOptions.acceptIcon || defaultModal.acceptIcon;
  const acceptColor = modalOptions.acceptColor || defaultModal.acceptColor;
  const cancelIcon = modalOptions.cancelIcon || defaultModal.cancelIcon;
  const cancelColor = modalOptions.cancelColor || defaultModal.cancelColor;
  const cancelButtonIsAvailable =
    modalOptions.cancelButtonIsAvailable === false
      ? modalOptions.cancelButtonIsAvailable
      : defaultModal.cancelButtonIsAvailable;
  const customActionColor = modalOptions.customActionColor || defaultModal.customActionColor;
  const customActionIcon = modalOptions.customActionIcon || defaultModal.customActionIcon;

  const ModalButtonsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 30px;
    ${() =>
      windowWidth < isPhone &&
      !!modalOptions.customActionButton &&
      css`
        flex-direction: column;
      `};
  `;

  const ModalButton = styled(Button)`
    padding: 0 10px 0 ${modalOptions.customActionButton ? '10px' : '23px'};
    ${() =>
      windowWidth < isPhone &&
      !!modalOptions.customActionButton &&
      css`
        margin: 15px 0;
        width: 70%;
      `};
  `;

  return (
    <ModalButtonsContainer>
      {cancelButtonIsAvailable && (
        <ModalButton id="cancelModal" onClick={modalClose} outlineColor={cssVar(cancelColor)}>
          {modalOptions.cancelButton || defaultModal.cancelButton}
          {!modalOptions.customActionButton && <Icon icon={cancelIcon} />}
        </ModalButton>
      )}
      {modalOptions.customActionButton && (
        <ModalButton
          id="customActionModal"
          onClick={onCustomAction}
          outlineColor={cssVar(customActionColor)}
        >
          {modalOptions.customActionButton}
          {modalOptions.customActionIcon && <Icon icon={customActionIcon} />}
        </ModalButton>
      )}
      <ModalButton id="acceptModal" onClick={onAcceptClick} outlineColor={cssVar(acceptColor)}>
        {modalOptions.acceptButton || defaultModal.acceptButton}
        {(!modalOptions.customActionButton || !cancelButtonIsAvailable) && (
          <Icon icon={acceptIcon} />
        )}
      </ModalButton>
    </ModalButtonsContainer>
  );
};

export { ModalButtons };
