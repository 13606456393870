import { shipmentDirections } from 'shared/helpers/constants/shipments/shipmentDirections';
import { shipmentStatuses } from 'shared/helpers/constants/shipments/shipmentStatuses';

const generateShipmentDocuments = (auth, shipment) =>
  auth.access.shipments.generateDocuments &&
  shipment.status === shipmentStatuses.sent &&
  shipment.shipmentDirection === shipmentDirections.incoming &&
  !shipment?.sentFromCompany?.materialCountRequired;

export { generateShipmentDocuments };
