const message = {
  INVALID_RELATED_COMPANY_EMPTY: 'Related company is required.',
  PAYMENT_EXCEPTION_COMPANIES_CREATE_SUCCESS:
    'Payment exception company has been successfully added.',
  PAYMENT_EXCEPTION_COMPANIES_CREATE_ERROR:
    'There was an error during payment exception company creation.',
  PAYMENT_EXCEPTION_COMPANIES_DELETE_SUCCESS:
    'Payment exception company has been successfully deleted.',
  PAYMENT_EXCEPTION_COMPANIES_DELETE_ERROR: 'There was an error during delete.',
};

export { message };
