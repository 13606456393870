const termStatuses = {
  draft: 'draft',
  awaiting: 'awaiting',
  download: 'download',
  pending: 'pending',
  signed: 'signed',
  declined: 'declined',
  cancelled: 'cancelled',
  exception: 'exception',
  expired: 'expired',
};

const availableTermStatuses = {
  all: Object.values(termStatuses),
  customer: [termStatuses.signed, termStatuses.expired],
};

export { termStatuses, availableTermStatuses };
