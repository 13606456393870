import { FILES_CONSTANTS } from 'shared/helpers/constants/filesConstants';
import { EMAIL_REGEX } from 'shared/helpers/validations/regularExpressions';

function validate(values) {
  const { externalEmails } = values;
  const errors = {};

  const parsedEmails =
    externalEmails &&
    externalEmails
      .split(';')
      .map(email => email.trim())
      .filter(Boolean);

  if (parsedEmails && parsedEmails.some(email => !email.match(EMAIL_REGEX))) {
    errors.externalEmails = FILES_CONSTANTS.INVALID_EMAILS;
  }

  return errors;
}

export { validate };
