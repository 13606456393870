import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getAgreementsDetailsActionType = 'GET_AGREEMENTS_DETAILS';
function getAgreementsDetailsAction() {
  return {
    type: getAgreementsDetailsActionType,
  };
}

const getAgreementsDetailsSuccessActionType = 'GET_AGREEMENTS_DETAILS_SUCCESS';
function getAgreementsDetailsSuccessAction(agreement) {
  return {
    type: getAgreementsDetailsSuccessActionType,
    payload: {
      agreement,
    },
  };
}

const getAgreementsDetailsFailureActionType = 'GET_AGREEMENTS_DETAILS_FAILURE';
function getAgreementsDetailsFailureAction(errorMessage) {
  return {
    type: getAgreementsDetailsFailureActionType,
    payload: {
      errorMessage,
    },
  };
}

const clearAgreementsDetailsActionType = 'CLEAR_AGREEMENTS_DETAILS_ACTION';
function clearAgreementsDetailsAction() {
  return {
    type: clearAgreementsDetailsActionType,
  };
}

function getAgreement(agreementId) {
  return dispatch => {
    dispatch(getAgreementsDetailsAction());

    return axios
      .get(`${API_HOST_AUTH}/agreements/${agreementId}`)
      .then(agreement => {
        dispatch(getAgreementsDetailsSuccessAction(agreement.data));
        return agreement.data;
      })
      .catch(err => {
        dispatch(getAgreementsDetailsFailureAction(err));

        return Promise.reject(err.data);
      });
  };
}

function clearAgreementsDetails() {
  return dispatch => {
    dispatch(clearAgreementsDetailsAction());
  };
}

export {
  getAgreementsDetailsActionType,
  getAgreementsDetailsAction,
  getAgreementsDetailsSuccessActionType,
  getAgreementsDetailsSuccessAction,
  getAgreementsDetailsFailureActionType,
  getAgreementsDetailsFailureAction,
  clearAgreementsDetailsActionType,
  clearAgreementsDetailsAction,
  clearAgreementsDetails,
  getAgreement,
};
