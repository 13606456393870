import React from 'react';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { UIAuthorization } from 'components/Authorization/UIAuthorization';

import { cssVar, pxToRem, media } from 'shared/helpers/styling/styling';

import { resolveVersion, versions } from 'utils/versioning';

const isFunction = func => Object.prototype.toString.call(func) === '[object Function]';

const NavbarElementLayout = ({
  closeOnClick,
  id,
  path,
  label,
  subNavbar,
  access,
  auth,
  withPathId,
  className,
  external,
  target,
}) => {
  const closeSidebar = () => {
    closeOnClick && closeOnClick();
  };

  const returnPath = withPathId
    ? `${path}/${auth.user.relatedCompany._id}`
    : isFunction(path)
    ? path(auth.user)
    : path;
  const labelString = isFunction(label) ? label(auth.user) : label;

  return (
    <UIAuthorization access={access(auth)}>
      {external ? (
        <a
          id={id}
          href={returnPath}
          target={target || '_blank'}
          className={className}
          onClick={closeSidebar}
          rel="noopener noreferrer"
        >
          {labelString}
        </a>
      ) : (
        <NavLink
          exact={subNavbar}
          id={id}
          className={className}
          to={returnPath}
          onClick={closeSidebar}
        >
          {labelString}
        </NavLink>
      )}
    </UIAuthorization>
  );
};

const NavbarElement = styled(NavbarElementLayout)`
  padding: 0 26px;
  display: flex;
  height: 65px;
  font-weight: 500;
  font-size: ${pxToRem(14)};
  align-items: center;
  color: ${({ subNavbar }) =>
    resolveVersion({
      [versions.alpha]: cssVar(subNavbar ? 'gullGray' : 'geyser'),
      [versions.global]: cssVar(subNavbar ? 'gullGray' : 'geyser'),
      [versions.west]: cssVar('graniteGray'),
    })};

  :hover {
    text-decoration: none;
    color: ${resolveVersion({
      [versions.alpha]: cssVar('whiteGRG'),
      [versions.global]: cssVar('whiteGRG'),
      [versions.west]: cssVar('blackGRG'),
    })};

    ${({ subNavbar }) =>
      !subNavbar &&
      css`
        background-color: ${resolveVersion({
          [versions.alpha]: cssVar('nevada'),
          [versions.global]: cssVar('nevada'),
          [versions.west]: cssVar('argentBitLighter'),
        })};
      `}
  }

  &.active {
    position: relative;
    background-color: ${resolveVersion({
      [versions.alpha]: cssVar('limedSpruceNav'),
      [versions.global]: cssVar('limedSpruceNav'),
      [versions.west]: cssVar('argent'),
    })};
    color: ${resolveVersion({
      [versions.alpha]: cssVar('whiteGRG'),
      [versions.global]: cssVar('whiteGRG'),
      [versions.west]: cssVar('blackGRG'),
    })};
  }

  ${media.phone`
    height: 56px;
  `}
  ${media.smallDesktop`
  padding: 0 18px;
  `}
`;

export { NavbarElement };
