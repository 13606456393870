import {
  lotsAddFileActionType,
  lotsRemoveFileActionType,
  lotsClearAllFilesActionType,
  lotsAddMultipleDocumentsActionType,
  lotsRemoveMultipleFilesActionType,
} from 'actions/Lots/lotsAddFiles';

const INITIAL_STATE = {
  files: null,
  multipleFiles: { poolSettlementDocument: [], billOfLading: [] },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case lotsAddFileActionType:
      return {
        ...state,
        files: {
          ...state.files,
          ...action.payload.file,
        },
      };
    case lotsRemoveMultipleFilesActionType:
      const { type, fileIndex } = action.payload;
      return {
        ...state,
        multipleFiles: {
          ...state.multipleFiles,
          [type]: state.multipleFiles[type].filter((item, index) => index !== fileIndex),
        },
      };
    case lotsAddMultipleDocumentsActionType:
      const { name, files } = action.payload;
      return {
        ...state,
        multipleFiles: {
          ...state.multipleFiles,
          [name]: [...(state.multipleFiles[name] || []), ...files],
        },
      };
    case lotsRemoveFileActionType:
      return {
        ...state,
        files: {
          ...state.files,
          [action.payload.fileType]: null,
        },
      };
    case lotsClearAllFilesActionType:
      return {
        files: null,
        multipleFiles: { poolSettlementDocument: [], billOfLading: [] },
      };
    default:
      return state;
  }
};
