import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { enterPrices } from 'actions/Settlements/enterPrices';
import {
  getSettlementDetails,
  clearSettlementDetails,
} from 'actions/Settlements/settlementDetails';
import { showSnackbar } from 'actions/shared/snackbar';

import { FormContainer } from 'shared/components/Form';
import { Preloader } from 'shared/components/Preloader/Preloader';
import {
  dangerOptions,
  successOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { removeCommasAndParse } from 'shared/helpers/parsers/text';

import { history } from 'utils/history';

import { EnterPricesForm } from './EnterPricesForm/EnterPricesForm';

const SettlementEnterPrices = () => {
  const { isPending } = useSelector(({ enterPrices }) => enterPrices);
  const settlementDetails = useSelector(({ settlementDetails }) => settlementDetails);
  let { id, settlementId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSettlementDetails(settlementId)).catch(() => history.push(`/lots/list/${id}`));

    return () => dispatch(clearSettlementDetails());
  }, [dispatch]);

  const onSubmit = ({ platinumPrice, palladiumPrice, rhodiumPrice }) => {
    const parsedValues = {
      pricePerOuncePt: removeCommasAndParse(platinumPrice),
      pricePerOuncePd: removeCommasAndParse(palladiumPrice),
      pricePerOunceRh: removeCommasAndParse(rhodiumPrice),
    };

    return dispatch(enterPrices(settlementId, parsedValues))
      .then(res => {
        dispatch(showSnackbar(successOptions, res.message));
        history.push(`/lots/list/${id}`);
      })
      .catch(res => dispatch(showSnackbar(dangerOptions, res.message)));
  };

  return (
    <>
      <FormContainer
        header={
          settlementDetails.settlement && settlementDetails.settlement.relatedLot.grgLotNumber
        }
        loaded={settlementDetails.settlement && !settlementDetails.isPending}
      >
        {() => <EnterPricesForm settlement={settlementDetails.settlement} onSubmit={onSubmit} />}
      </FormContainer>
      <Preloader loading={isPending} />
    </>
  );
};

export { SettlementEnterPrices };
