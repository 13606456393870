import { statusesForCustomer } from 'shared/helpers/constants/lots/statusesForCustomer';
import { statusesForGlobal } from 'shared/helpers/constants/lots/statusesForGlobal';
import { isGlobalRefiningGroup } from 'shared/helpers/matchers/isGlobalRefiningGroup';
import { dateToTimestamp } from 'shared/helpers/parsers/date';
import { safeReplace, removeCommasAndParse } from 'shared/helpers/parsers/text';

const customerStatuses = [statusesForCustomer.received, statusesForCustomer.summary_available];

const globalStatuses = [
  statusesForGlobal.shipped_from_global,
  statusesForGlobal.assay_results_received,
  statusesForGlobal.ship_summary_accepted,
];

function parsePlacedHedge(hedge) {
  const {
    relatedLot,
    relatedCompany,
    hedgeType,
    materialType,
    deliveryDate,
    deliveryDateGRG,
    placedAt,
  } = hedge;
  const isGlobal = isGlobalRefiningGroup(relatedCompany.label);

  const parsedHedge = {
    platinumPriceActual:
      hedge.platinumPriceActual && removeCommasAndParse(hedge.platinumPriceActual, ',', '.'),
    palladiumPriceActual:
      hedge.palladiumPriceActual && removeCommasAndParse(hedge.palladiumPriceActual, ',', '.'),
    rhodiumPriceActual:
      hedge.rhodiumPriceActual && removeCommasAndParse(hedge.rhodiumPriceActual, ',', '.'),
    platinumPriceCust:
      hedge.platinumPriceCust && removeCommasAndParse(hedge.platinumPriceCust, ',', '.'),
    palladiumPriceCust:
      hedge.palladiumPriceCust && removeCommasAndParse(hedge.palladiumPriceCust, ',', '.'),
    rhodiumPriceCust:
      hedge.rhodiumPriceCust && removeCommasAndParse(hedge.rhodiumPriceCust, ',', '.'),
    materialUnits: hedge.materialUnits && +safeReplace(hedge.materialUnits, ',', '.'),
    materialWeight: hedge.materialWeight && +safeReplace(hedge.materialWeight, ',', '.'),
    platinumOz: +safeReplace(hedge.platinumOz, ',', '.'),
    palladiumOz: +safeReplace(hedge.palladiumOz, ',', '.'),
    rhodiumOz: +safeReplace(hedge.rhodiumOz, ',', '.'),
    customerInstructions: hedge.customerInstructions,
    relatedCompany: relatedCompany && relatedCompany.value,
    relatedLot: relatedLot && relatedLot.value,
    hedgeType: hedgeType && hedgeType.value,
    materialType: materialType ? materialType.value : undefined,
    placedAt: placedAt && dateToTimestamp(placedAt),
  };

  if (
    relatedLot &&
    ((!isGlobal && customerStatuses.includes(relatedLot.statusForCustomer)) ||
      (isGlobal && globalStatuses.includes(relatedLot.statusForGlobal)))
  ) {
    return parsedHedge;
  }

  return {
    ...parsedHedge,
    deliveryDate: deliveryDate && dateToTimestamp(deliveryDate),
    deliveryDateGRG: deliveryDateGRG && dateToTimestamp(deliveryDateGRG),
  };
}

export { parsePlacedHedge };
