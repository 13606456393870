import React, { Component } from 'react';

import { GroupButtons } from 'shared/components/Buttons';
import { GroupButton } from 'shared/components/Buttons/GroupButton/components/GroupButton';
import { FullPageList } from 'shared/components/FullPageList/FullPageList';
import { Preloader } from 'shared/components/Preloader/Preloader';
import {
  itemsTemplate,
  itemsTemplateMobile,
  itemsDetailsTemplate,
} from 'shared/helpers/constants/companies/companiesListTableConfig';
import { COMPANY } from 'shared/helpers/constants/companies/companyConstants';
import { filtersCompanyMapper } from 'shared/helpers/dataParsers/company/parseCompanyFilters';

import { CompaniesFilters } from './components/CompaniesFilters/CompaniesFilters';

class CompaniesList extends Component {
  componentDidMount() {
    this.props.getRegionList();
  }

  onFiltersChanged = searchFilter => filters => {
    const {
      getCompanies,
      companiesList: { limit },
      companyStatusFilter,
      companyTypeFilter,
      companyCountryFilter,
      companyStateFilter,
      companyDueDiligenceFilter,
    } = this.props;

    getCompanies(limit, 1, searchFilter, filters);
    companyCountryFilter(filters.country);
    companyStateFilter(filters.state);
    companyStatusFilter(filters.status);
    companyTypeFilter(filters.companyTypes);
    companyDueDiligenceFilter(filters.dueDiligence);
  };

  get tableActions() {
    const {
      auth: {
        access: { companies },
      },
      history,
    } = this.props;

    const actions = [];

    if (companies.details.details) {
      actions.push({
        icon: 'arrow-right',
        action: 'Details',
        onClick: thisCompany => () => {
          history.push(`/companies/${thisCompany._id}`);
        },
      });
    }

    return actions;
  }

  filtersClear = searchFilter => {
    const {
      getCompanies,
      companyFiltersClear,
      companiesList: { limit },
    } = this.props;

    getCompanies(limit, 1, searchFilter, {
      status: [],
      companyTypes: [],
      dueDiligence: [],
      country: {},
      state: {},
    });
    companyFiltersClear();
  };

  fetchList = (page = 1, limit = 50, query) => {
    const { getCompanies, companyFilters } = this.props;

    getCompanies(limit, page, query, companyFilters);
  };

  renderFilters = searchFilter => {
    const {
      companiesList: { page, limit },
      companyFilters,
      regionList,
    } = this.props;

    return (
      <CompaniesFilters
        regionList={regionList}
        filters={companyFilters}
        pagination={{ page, limit }}
        onFiltersChanged={this.onFiltersChanged(searchFilter)}
      />
    );
  };

  renderActionButtons = () => {
    const {
      auth: {
        access: { companies },
      },
      history,
      generateReportAction,
    } = this.props;

    const actions = [
      {
        access: companies.generateReport,
        onClick: () => generateReportAction(),
        label: COMPANY.GENERATE_REPORT,
        icon: 'add',
      },
      {
        access: companies.create,
        onClick: () => history.push('/companies/create'),
        label: COMPANY.CREATE_A_COMPANY,
        icon: 'create-company',
      },
    ];

    return (
      <GroupButtons>
        {actions.map((action, index) => (
          <GroupButton
            access={action.access}
            label={action.label}
            onClick={action.onClick}
            icon={action.icon}
            key={index}
          />
        ))}
      </GroupButtons>
    );
  };

  render() {
    const {
      companiesList: { companies, pages, page, limit, isPending },
      companyFilters,
      generateReport,
    } = this.props;

    const filtersSelected =
      companyFilters.state ||
      companyFilters.country ||
      companyFilters.status.length ||
      companyFilters.companyTypes.length ||
      companyFilters.dueDiligence.length;

    const filtersAccess = true;
    return (
      <>
        <Preloader loading={generateReport.isPending} />
        <FullPageList
          title={COMPANY.COMPANIES}
          itemsTemplate={itemsTemplate}
          itemsDetailsTemplate={itemsDetailsTemplate}
          itemsTemplateMobile={itemsTemplateMobile}
          actionsTemplate={this.tableActions}
          items={companies || []}
          fetchList={this.fetchList}
          filterComponent={this.renderFilters}
          filterAccess={filtersAccess}
          pagination={{ limit, page, pages }}
          enableSearch
          filtersClear={this.filtersClear}
          filtersSelected={filtersSelected}
          actionsContext={this}
          actionButtons={this.renderActionButtons}
          isPending={isPending}
          filters={filtersCompanyMapper(companyFilters)}
        />
      </>
    );
  }
}

export { CompaniesList };
