import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, FieldArray } from 'redux-form';

import { markAsSent } from 'actions/Shipments/markAsSent';
import { getShipment } from 'actions/Shipments/shipmentDetails';
import { openModal } from 'actions/shared/modal';
import { showSnackbar } from 'actions/shared/snackbar';

import { FieldSelect } from 'shared/components/Fields';
import { Section, FormContainer, Form } from 'shared/components/Form';
import { cancelModal, markAsSentShipmentModal } from 'shared/helpers/constants/modalConstants';
import {
  availableMaterialTypeOutbound,
  materialTypeOutbound,
} from 'shared/helpers/constants/packages/materialType';
import { shipmentStatuses } from 'shared/helpers/constants/shipments/shipmentStatuses';
import { SHIPMENTS } from 'shared/helpers/constants/shipments/shipmentsConstants';
import {
  successOptions,
  dangerOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { setDesignation } from 'shared/helpers/dataParsers/lot/setDesignation';
import { mapArray } from 'shared/helpers/parsers/select';
import { humanize } from 'shared/helpers/parsers/text';
import { validate } from 'shared/helpers/validations/shipments/shipAndPrintValidation';

class ShipAndPrintBase extends Component {
  componentDidMount() {
    const shipmentId = this.props.match.params.id;

    this.props
      .getShipment(shipmentId)
      .then(shipment => {
        if (shipment.status !== shipmentStatuses.open) {
          return Promise.reject();
        }
      })
      .catch(this.navigateBack);
  }

  onSubmit = values => {
    const shipmentId = this.props.match.params.id;
    const mappedValues = Object.values(values.lots).reduce(
      (lots, { grgLotNumber, materialType: { value: materialType } }) => [
        ...lots,
        {
          grgLotNumber,
          materialType,
        },
      ],
      [],
    );

    return this.props.openModal(markAsSentShipmentModal, () => {
      this.props
        .markAsSent(shipmentId, mappedValues, false)
        .then(res => {
          this.props.showSnackbar(successOptions, res.message);
          this.navigateBack();
        })
        .catch(({ message }) => this.props.showSnackbar(dangerOptions, message));
    });
  };

  navigateBack = () => this.props.history.push(`/shipments/outgoing/${this.props.match.params.id}`);

  openModalOnCancel = () => {
    this.props.openModal(cancelModal, this.navigateBack);
  };

  renderFields = ({ fields }) => {
    const {
      shipmentDetails: { shipment },
    } = this.props;
    const assignedLot = (shipment && shipment.populatedLots) || [];

    if (!fields.length) {
      fields.push(...assignedLot);
    }

    return fields.map((lot, i) => (
      <Section
        header={setDesignation(assignedLot[i].grgLotNumber, assignedLot[i].designation)}
        template={[`${lot}.materialType .`]}
      >
        <Field
          name={`${lot}.materialType`}
          label={SHIPMENTS.MATERIAL_TYPE}
          component={FieldSelect}
          options={mapArray(availableMaterialTypeOutbound.all)}
          searchable={false}
          field="required"
        />
      </Section>
    ));
  };

  render() {
    const {
      invalid,
      submitting,
      handleSubmit,
      shipmentDetails: { isPending },
      markAsSentState: { isPending: isSending },
    } = this.props;

    return (
      <FormContainer header={SHIPMENTS.ENTER_LOTS_INFORMATION} loaded={!isPending}>
        {() => (
          <Form
            header={SHIPMENTS.LOTS_INFORMATION}
            submitDisabled={invalid || submitting || isSending}
            onSubmit={handleSubmit(this.onSubmit)}
            onCancel={this.openModalOnCancel}
          >
            <FieldArray name="lots" component={this.renderFields} />
          </Form>
        )}
      </FormContainer>
    );
  }
}

const mapStateToProps = state => ({
  shipmentDetails: state.shipmentDetails,
  markAsSentState: state.markAsSent,
  initialValues:
    state.shipmentDetails.shipment &&
    state.shipmentDetails.shipment.populatedLots.reduce(
      (acc, lot) => ({
        lots: [
          ...acc.lots,
          {
            materialType: lot.materialType && {
              label: humanize(lot.materialType),
              value: materialTypeOutbound[lot.materialType],
            },
            grgLotNumber: lot.grgLotNumber,
          },
        ],
      }),
      { lots: [] },
    ),
});

const mapDispatchToProps = {
  getShipment,
  markAsSent,
  openModal,
  showSnackbar,
};

const ShipAndPrint = connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'informationForLots',
    shouldError: () => true,
    validate,
  })(ShipAndPrintBase),
);

export { ShipAndPrintBase, ShipAndPrint };
