import React from 'react';
import { FieldArray, FormSection, reduxForm } from 'redux-form';

import { Form } from 'shared/components/Form';
import { PROFIT_LOSS } from 'shared/helpers/constants/lots/profitLossConstants';
import { currentCommoditiesValidation } from 'shared/helpers/validations/lot/generateProfitLossValidation';
import { multiValidator } from 'shared/helpers/validations/multiValidator';

import { CurrentCommodities } from './CurrentCommodities/CurrentCommodities';

const CurrentCommoditiesFormLayout = ({ handleSubmit, invalid }) => (
  <Form header={PROFIT_LOSS.EXISTING_COMMODITIES} onSubmit={handleSubmit} submitDisabled={invalid}>
    <FormSection name="currentCommodities">
      <FieldArray name="commodities" component={CurrentCommodities} />
    </FormSection>
  </Form>
);

const CurrentCommoditiesForm = reduxForm({
  validate: multiValidator('currentCommodities', currentCommoditiesValidation),
})(CurrentCommoditiesFormLayout);

export { CurrentCommoditiesForm, CurrentCommoditiesFormLayout };
