import { saveUrlActionType, clearSavedUrlActionType } from 'actions/shared/redirect';

const INITIAL_STATE = {
  url: null,
  userId: null,
  notification: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case saveUrlActionType:
      return {
        url: state.url || action.payload.url,
        userId: action.payload.userId,
        notification: action.payload.notification,
      };
    case clearSavedUrlActionType:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
