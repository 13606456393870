import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Field, formValueSelector } from 'redux-form';

import { getRegionList as getRegionListAction } from 'actions/Locations/getRegionList';

import { FieldInput, FieldSelect } from 'shared/components/Fields/index';
import { Section } from 'shared/components/Form';
import { LOCATION } from 'shared/helpers/constants/locations/locationConstants';
import { generateName } from 'shared/helpers/forms/generateName';

function SelectCountry({
  formName,
  editMode,
  selectedState,
  change,
  regionList,
  getRegionList,
  ...props
}) {
  const [requiredState, setRequiredState] = useState('');
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);

  const selectedCountry =
    props.selectedCountry || (props.locationValues && props.locationValues.country);

  useEffect(() => {
    setCountries((regionList && regionList.country) || []);
    setStates((regionList && regionList.state) || []);
  }, [regionList]);

  const setCountry = country => {
    change('state', '');
    if (formName) {
      change(`${formName}.state`, '');
    }

    if (!states || !country) return;

    const value = country && country.value;
    const specialCountries = Object.keys(states);

    if (specialCountries.includes(value)) {
      setRequiredState('required');
    } else {
      setRequiredState('');
    }
  };

  const label = selectedState && selectedState.label;

  const changeStateField = useCallback(() => {
    const country = selectedCountry && selectedCountry.value;
    const specialCountries = Object.keys(states);

    if (specialCountries.includes(country)) {
      const currentState = states[country].filter(s => s.label === label);

      change('state', currentState[0]);
    }
  }, [change, selectedCountry, label, states]);

  useEffect(() => {
    getRegionList();
  }, [getRegionList]);

  useEffect(() => {
    if (!states) return;
    const value = selectedCountry && selectedCountry.value;
    const specialCountries = Object.keys(states);
    if (specialCountries.includes(value)) {
      setRequiredState('required');
      editMode && changeStateField();
    } else {
      setRequiredState('');
    }
  }, [states, selectedCountry, editMode, changeStateField]);

  return (
    <Section
      header={LOCATION.COUNTRY_STATE}
      template={[`${generateName(formName, 'country')} ${generateName(formName, 'state')}`]}
    >
      <Field
        name={generateName(formName, 'country')}
        component={FieldSelect}
        label={LOCATION.COUNTRY}
        disabled={editMode}
        options={Array.from(countries)}
        field="required"
        onChange={setCountry}
      />
      {requiredState ? (
        <Field
          name={generateName(formName, 'state')}
          component={FieldSelect}
          label={LOCATION.STATE}
          options={(states && selectedCountry && states[selectedCountry.value]) || []}
          field="required"
        />
      ) : (
        <Field
          name={generateName(formName, 'state')}
          component={FieldInput}
          label={LOCATION.STATE}
          field={requiredState}
        />
      )}
    </Section>
  );
}

const selector = formValueSelector('LocationForm');

const mapStateToProps = state => ({
  regionList: state.regionList.data,
  selectedCountry: selector(state, 'country'),
  selectedState: selector(state, 'state'),
});

const mapDispatchToProps = {
  getRegionList: getRegionListAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectCountry);
