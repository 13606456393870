import { formatToDollarWithPrefix } from 'shared/helpers/parsers/formaters';

const REPORTS = {
  LOTS_AWAITING_RESULTS: 'Lots awaiting results',
  ANALYSIS_REPORTS: 'Analysis reports',
  CLIENT_LOT_REPORT: 'Client lot report',
  OPEN_FINALS: 'Open finals',
  LAST_10_LOTS: 'Last 10 lots',
  LAST_20_LOTS: 'Last 20 lots',
  MODAL_HEADER: 'Generate reports',
  MODAL_DESCRIPTION: 'For each location, use data from:',
  GENERATE: 'Generate',
  GENERATED_BY: 'Generated by',
  ALL_LOTS: 'All lots',
  REPORT: 'Report',
  REPORTS: 'Reports',
  LAST_5_REPORTS: 'Last 5 reports',
  GENERATION_PROCESS: 'Your report is being generated and will be sent to you once ready.',
  TOTAL_PAYMENTS: 'Total payments',
  TOTAL_PAYMENTS_TITLE: ({ value, filtersSelected }) =>
    `Total payments ${filtersSelected ? 'listed' : 'today'}: ${
      formatToDollarWithPrefix(value) || ''
    }`,
  GENERATE_CLIENT_LOT_REPORT: 'Generate client lot report',
  GENERATE_CLIENT_LOT_REPORT_SUCCESS: 'Client lot report has been generated successfully',
  INCLUDE_ALL_COMPANIES: 'Include all companies',
  INCLUDE_ALL_LOCATIONS: 'Include all locations',
  COMPANIES: 'Companies',
  COMPANY_LOCATIONS: 'Company locations',
  DATE_FROM: 'Received date from',
  DATE_TO: 'Received date to',
  CREATE_CLIENT_LOT_REPORT_FAILURE: 'There was problem with creating client lot report.',
};

export { REPORTS };
