import React from 'react';
import { reduxForm, FieldArray } from 'redux-form';

import { Form } from 'shared/components/Form';
import { LOCATION } from 'shared/helpers/constants/locations/locationConstants';
import { validate } from 'shared/helpers/validations/location/locationValidations';
import { validateLotSuffix } from 'shared/helpers/validations/location/validateLotSuffix';
import { multiValidator } from 'shared/helpers/validations/multiValidator';

import { LocationsList } from './components/LocationsList';

function AddLocationsBase(props) {
  const { CustomActions, handleSubmit, invalid, change } = props;

  return (
    <Form
      header={LOCATION.LOCATIONS}
      onSubmit={handleSubmit}
      CustomActions={CustomActions}
      submitDisabled={invalid}
    >
      <FieldArray name="locations" component={LocationsList} invalid={invalid} change={change} />
    </Form>
  );
}

const AddLocations = reduxForm({
  validate: multiValidator('locations', validate, validateLotSuffix),
})(AddLocationsBase);

export { AddLocations };
