import { humanize } from 'shared/helpers/parsers/text';

const normalizePackageObject = pkg => ({
  ...pkg,
  assignedLot: pkg && {
    label: pkg.assignedLot.grgLotNumber,
    value: pkg.assignedLot._id,
  },
  materialTypeDeclared: pkg && {
    label: humanize(pkg.materialTypeDeclared),
    value: pkg.materialTypeDeclared,
  },
  customerInstructions: pkg.customerInstructions || '',
});

export { normalizePackageObject };
