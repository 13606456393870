import { css } from 'styled-components';

import { media } from 'shared/helpers/styling/styling';

import ico_access from '../../icons/ico_access.svg';
import ico_add_exception_company from '../../icons/ico_add-exception-company.svg';
import ico_add_hedge_recipient from '../../icons/ico_add-hedge-recipient.svg';
import ico_add_market_holiday from '../../icons/ico_add-market-holiday.svg';
import ico_add_trusted_company from '../../icons/ico_add-trusted-company.svg';
import icoAdd from '../../icons/ico_add.svg';
import ico_add_calendar_note from '../../icons/ico_add_calendar_note.svg';
import icoAlert from '../../icons/ico_alert.svg';
import ico_arrow_down from '../../icons/ico_arrow-down.svg';
import icoArrow from '../../icons/ico_arrow.svg';
import icoBin from '../../icons/ico_bin.svg';
import icoBlock from '../../icons/ico_block.svg';
import icoBox from '../../icons/ico_box.svg';
import icoCalendar from '../../icons/ico_calendar.svg';
import ico_calendar_note from '../../icons/ico_calendar_note.svg';
import icoCancel from '../../icons/ico_cancel.svg';
import ico_car from '../../icons/ico_car.svg';
import ico_city from '../../icons/ico_city.svg';
import ico_class from '../../icons/ico_class.svg';
import icoClip from '../../icons/ico_clip.svg';
import ico_clock from '../../icons/ico_clock.svg';
import ico_close_lot from '../../icons/ico_close-lot.svg';
import icoClose from '../../icons/ico_close.svg';
import ico_company from '../../icons/ico_company.svg';
import ico_compare from '../../icons/ico_compare.svg';
import ico_converter_database from '../../icons/ico_converter-database.svg';
import icoCount from '../../icons/ico_count.svg';
import ico_create_company from '../../icons/ico_create-company.svg';
import ico_create_package from '../../icons/ico_create-package.svg';
import ico_create_shipment from '../../icons/ico_create-shipment.svg';
import ico_create_user from '../../icons/ico_create-user.svg';
import ico_create_incoming_shipment from '../../icons/ico_create_incoming-shipment.svg';
import ico_create_outgoing_shipment from '../../icons/ico_create_outgoing-shipment.svg';
import ico_create_self_delivery from '../../icons/ico_create_self-delivery.svg';
import ico_cross from '../../icons/ico_cross.svg';
import ico_customize from '../../icons/ico_customize.svg';
import ico_document from '../../icons/ico_document.svg';
import ico_dollar from '../../icons/ico_dollar.svg';
import ico_download from '../../icons/ico_download.svg';
import icoDrag from '../../icons/ico_drag.svg';
import ico_edit from '../../icons/ico_edit.svg';
import ico_enter_payment from '../../icons/ico_enter-payment.svg';
import ico_enter_pool_payment from '../../icons/ico_enter-pool-payment.svg';
import icoError from '../../icons/ico_error.svg';
import ico_exception_companies from '../../icons/ico_exception-companies.svg';
import icoExclamation from '../../icons/ico_exclamation.svg';
import icoEye from '../../icons/ico_eye.svg';
import icoGhost from '../../icons/ico_ghost.svg';
import ico_hedge from '../../icons/ico_hedge.svg';
import ico_home from '../../icons/ico_home.svg';
import icoHouse from '../../icons/ico_house.svg';
import ico_inspection from '../../icons/ico_inspection.svg';
import icoKey from '../../icons/ico_key.svg';
import icoKeyhole from '../../icons/ico_keyhole.svg';
import icoLink from '../../icons/ico_link.svg';
import icoLittleArrow from '../../icons/ico_little-arrow.svg';
import ico_location from '../../icons/ico_location.svg';
import icoLogout from '../../icons/ico_logout.svg';
import icoMail from '../../icons/ico_mail.svg';
import ico_material from '../../icons/ico_material.svg';
import icoMenu from '../../icons/ico_menu.svg';
import icoMineralCart from '../../icons/ico_mineral.cart.svg';
import icoMobile from '../../icons/ico_mobile.svg';
import icoMoneyValue from '../../icons/ico_money.value.svg';
import icoMonth from '../../icons/ico_month.svg';
import ico_more from '../../icons/ico_more.svg';
import ico_new_lot from '../../icons/ico_new-lot.svg';
import icoNew from '../../icons/ico_new.svg';
import ico_package_type from '../../icons/ico_package-type.svg';
import ico_payout_rules_add from '../../icons/ico_payout-rules-add.svg';
import ico_payout_rules from '../../icons/ico_payout-rules.svg';
import icoPen from '../../icons/ico_pen.svg';
import icoPerson from '../../icons/ico_person.svg';
import icoPhone from '../../icons/ico_phone.svg';
import ico_plus from '../../icons/ico_plus.svg';
import ico_po_box from '../../icons/ico_po-box.svg';
import icoPreview from '../../icons/ico_preview.svg';
import ico_printer from '../../icons/ico_printer.svg';
import ico_process from '../../icons/ico_process.svg';
import ico_purchasing_tool from '../../icons/ico_purchasing-tool.svg';
import icoRadioCheck from '../../icons/ico_radio-check.svg';
import icoRadioEmpty from '../../icons/ico_radio-empty.svg';
import icoRate from '../../icons/ico_rate.svg';
import icoReferral from '../../icons/ico_referral.svg';
import ico_request_advance_payment from '../../icons/ico_request-advance-payment.svg';
import ico_request_inspection from '../../icons/ico_request-inspection.svg';
import ico_request_payment from '../../icons/ico_request-payment.svg';
import icoScale from '../../icons/ico_scale.svg';
import ico_schedule_inspection from '../../icons/ico_schedule-inspection.svg';
import icoSearch from '../../icons/ico_search.svg';
import ico_self_delivery from '../../icons/ico_self-delivery.svg';
import ico_settlement_mock_up from '../../icons/ico_settlement-mock-up.svg';
import icoShare from '../../icons/ico_share.svg';
import icoState from '../../icons/ico_state.svg';
import icoStatus from '../../icons/ico_status.svg';
import icoStreet from '../../icons/ico_street.svg';
import icoSuccess from '../../icons/ico_success.svg';
import icoSuite from '../../icons/ico_suite.svg';
import ico_tick from '../../icons/ico_tick.svg';
import ico_trusted_companies from '../../icons/ico_trusted-companies.svg';
import ico_type from '../../icons/ico_type.svg';
import icoUpload from '../../icons/ico_upload.svg';
import icoUser from '../../icons/ico_user.svg';
import ico_view_journal from '../../icons/ico_view-journal.svg';
import ico_volume from '../../icons/ico_volume.svg';
import ico_waiting from '../../icons/ico_waiting.svg';
import icoZip from '../../icons/ico_zip.svg';
import grg_logo_mobile from '../../images/grg-logo-mobile.svg';

export const IconStyles = css`
  .icon {
    --transition: 100ms ease-in;

    width: 35px;
    height: 35px;
    display: inline-block;
    -webkit-mask-size: cover;
    mask-size: cover;
    background-color: var(--whiteGRG);
    transition: var(--transition);

    &.icon-email {
      mask: url(${icoMail}) no-repeat 50% 50%;
    }

    &.icon-bin {
      mask: url(${icoBin}) no-repeat 50% 50%;
    }

    &.icon-box {
      mask: url(${icoBox}) no-repeat 50% 50%;
    }

    &.icon-clip {
      mask: url(${icoClip}) no-repeat 50% 50%;
    }

    &.icon-key {
      mask: url(${icoKey}) no-repeat 50% 50%;
    }

    &.icon-error {
      mask: url(${icoError}) no-repeat 50% 50%;
    }

    &.icon-success {
      mask: url(${icoSuccess}) no-repeat 50% 50%;
    }

    &.icon-alert {
      mask: url(${icoAlert}) no-repeat 50% 50%;
    }

    &.icon-close {
      mask: url(${icoClose}) no-repeat 50% 50%;
    }

    &.icon-mobile {
      mask: url(${icoMobile}) no-repeat 50% 50%;
    }

    &.icon-person {
      mask: url(${icoPerson}) no-repeat 50% 50%;
    }

    &.icon-phone {
      mask: url(${icoPhone}) no-repeat 50% 50%;
    }

    &.icon-ghost {
      mask: url(${icoGhost}) no-repeat 50% 50%;
    }

    &.icon-house {
      mask: url(${icoHouse}) no-repeat 50% 50%;
    }

    &.icon-keyhole {
      mask: url(${icoKeyhole}) no-repeat 50% 50%;
    }

    &.icon-exclamation {
      mask: url(${icoExclamation}) no-repeat 50% 50%;
    }

    &.icon-logout {
      mask: url(${icoLogout}) no-repeat 50% 50%;
    }

    &.icon-eye {
      mask: url(${icoEye}) no-repeat 50% 50%;
    }

    &.icon-radio-uncheck {
      mask: url(${icoBlock}) no-repeat 50% 50%;
    }

    &.icon-radio-check {
      mask: url(${icoRadioCheck}) no-repeat 50% 50%;
    }

    &.icon-radio-empty {
      mask: url(${icoRadioEmpty}) no-repeat 50% 50%;
    }

    &.icon-referral {
      mask: url(${icoReferral}) no-repeat 50% 50%;
    }

    &.icon-scale {
      mask: url(${icoScale}) no-repeat 50% 50%;
    }

    &.icon-link {
      mask: url(${icoLink}) no-repeat 50% 50%;
    }

    &.icon-link-scaled {
      mask: url(${icoLink}) no-repeat 50% 50%;
      mask-size: 70% 70% !important;
    }

    &.icon-little-arrow {
      mask: url(${icoLittleArrow}) no-repeat 50% 50%;
    }

    &.icon-count {
      mask: url(${icoCount}) no-repeat 50% 50%;
    }

    &.icon-rate {
      mask: url(${icoRate}) no-repeat 50% 50%;
    }

    &.icon-money {
      mask: url(${icoMoneyValue}) no-repeat 50% 50%;
    }

    &.icon-month {
      mask: url(${icoMonth}) no-repeat 50% 50%;
    }

    &.icon-cart {
      mask: url(${icoMineralCart}) no-repeat 50% 50%;
    }

    &.icon-pen {
      mask: url(${icoPen}) no-repeat 50% 50%;
    }

    &.icon-calendar {
      mask-image: url(${icoCalendar});
      mask-position: center;
      mask-repeat: no-repeat;
      mask-size: 50% 50%;
    }

    &.icon-upload {
      mask: url(${icoUpload}) no-repeat 50% 50%;
    }

    &.icon-preview {
      mask: url(${icoPreview}) no-repeat 50% 50%;
    }

    &.icon-search {
      mask: url(${icoSearch}) no-repeat 50% 50%;
    }

    &.icon-arrow-right {
      mask: url(${icoArrow}) no-repeat 50% 50%;
    }

    &.icon-arrow-left {
      mask: url(${icoArrow}) no-repeat 50% 50%;
      transform: rotate(-180deg);
    }

    &.icon-arrow-up {
      mask: url(${icoArrow}) no-repeat 50% 50%;
      transform: rotate(-90deg);
    }

    &.icon-arrow-down {
      mask: url(${icoArrow}) no-repeat 50% 50%;
      transform: rotate(90deg);
    }

    &.icon-cancel {
      mask: url(${icoCancel}) no-repeat 50% 50%;
    }

    &.icon-tiny-arrow-left {
      mask: url(${ico_arrow_down}) no-repeat 50% 50%;
      transform: rotate(90deg);
    }

    &.icon-tiny-arrow-right {
      mask: url(${ico_arrow_down}) no-repeat 50% 50%;
      transform: rotate(-90deg);
    }

    &.icon-tiny-arrow-down {
      mask: url(${ico_arrow_down}) no-repeat 50% 50%;
    }

    &.icon-download {
      mask: url(${ico_download}) no-repeat 50% 50%;
    }

    &.icon-cross {
      mask: url(${ico_cross}) no-repeat 50% 50%;
    }

    &.icon-process {
      mask: url(${ico_process}) no-repeat 50% 50%;
    }

    &.icon-user {
      mask: url(${icoUser}) no-repeat 50% 50%;
    }

    &.icon-volume {
      mask: url(${ico_volume}) no-repeat 50% 50%;
    }

    &.icon-location {
      mask: url(${ico_location}) no-repeat 50% 50%;
    }

    &.icon-home {
      mask: url(${ico_home}) no-repeat 50% 50%;
    }

    &.icon-tick {
      mask: url(${ico_tick}) no-repeat 50% 50%;
    }

    &.icon-tick-large {
      mask: url(${ico_tick}) no-repeat 50% 50%;
      transform: scale(1.5);
    }

    &.icon-type {
      mask: url(${ico_type}) no-repeat 50% 50%;
    }

    &.icon-document {
      mask: url(${ico_document}) no-repeat 50% 50%;
    }

    &.icon-car {
      mask: url(${ico_car}) no-repeat 50% 50%;
    }

    &.icon-car-moving {
      mask: url(${ico_car}) no-repeat 50% 50%;
    }

    &.icon-company {
      mask: url(${ico_company}) no-repeat 50% 50%;
    }

    &.icon-dollar {
      mask: url(${ico_dollar}) no-repeat 50% 50%;
    }

    &.icon-edit {
      mask: url(${ico_edit}) no-repeat 50% 50%;
    }

    &.icon-customize {
      mask: url(${ico_customize}) no-repeat 50% 50%;
    }

    &.icon-plus {
      mask: url(${ico_plus}) no-repeat 50% 50%;
    }

    &.icon-access {
      mask: url(${ico_access}) no-repeat 50% 50%;
    }

    &.icon-city {
      mask: url(${ico_city}) no-repeat 50% 50%;
    }

    &.icon-class {
      mask: url(${ico_class}) no-repeat 50% 50%;
    }

    &.icon-material {
      mask: url(${ico_material}) no-repeat 50% 50%;
    }

    &.icon-package-type {
      mask: url(${ico_package_type}) no-repeat 50% 50%;
    }

    &.icon-po-box {
      mask: url(${ico_po_box}) no-repeat 50% 50%;
    }

    &.icon-state {
      mask: url(${icoState}) no-repeat 50% 50%;
    }

    &.icon-street {
      mask: url(${icoStreet}) no-repeat 50% 50%;
    }

    &.icon-suite {
      mask: url(${icoSuite}) no-repeat 50% 50%;
    }

    &.icon-zip {
      mask: url(${icoZip}) no-repeat 50% 50%;
    }

    &.icon-drag {
      mask: url(${icoDrag}) no-repeat 50% 50%;
    }

    &.icon-menu {
      mask: url(${icoMenu}) no-repeat 50% 50%;
    }

    &.icon-status {
      mask: url(${icoStatus}) no-repeat 50% 50%;
    }

    &.icon-share {
      mask: url(${icoShare}) no-repeat 50% 50%;
    }

    &.icon-add {
      mask: url(${icoAdd}) no-repeat 50% 50%;
    }

    &.icon-new {
      mask: url(${icoNew}) no-repeat 50% 50%;
    }

    &.icon-close-lot {
      mask: url(${ico_close_lot}) no-repeat 50% 50%;
    }

    &.icon-compare {
      mask: url(${ico_compare}) no-repeat 50% 50%;
    }

    &.icon-more {
      mask: url(${ico_more}) no-repeat 50% 50%;
    }

    &.icon-new-lot {
      mask: url(${ico_new_lot}) no-repeat 50% 50%;
    }

    &.icon-printer {
      mask: url(${ico_printer}) no-repeat 50% 50%;
    }

    &.icon-add-exception-company {
      mask: url(${ico_add_exception_company}) no-repeat 50% 50%;
    }

    &.icon-add-hedge-recipient {
      mask: url(${ico_add_hedge_recipient}) no-repeat 50% 50%;
    }

    &.icon-add-market-holiday {
      mask: url(${ico_add_market_holiday}) no-repeat 50% 50%;
    }

    &.icon-add-trusted-company {
      mask: url(${ico_add_trusted_company}) no-repeat 50% 50%;
    }

    &.icon-converter-database {
      mask: url(${ico_converter_database}) no-repeat 50% 50%;
    }

    &.icon-create-company {
      mask: url(${ico_create_company}) no-repeat 50% 50%;
    }

    &.icon-create-package {
      mask: url(${ico_create_package}) no-repeat 50% 50%;
    }

    &.icon-create-shipment {
      mask: url(${ico_create_shipment}) no-repeat 50% 50%;
    }

    &.icon-create-user {
      mask: url(${ico_create_user}) no-repeat 50% 50%;
    }

    &.icon-enter-payment {
      mask: url(${ico_enter_payment}) no-repeat 50% 50%;
    }

    &.icon-enter-pool-payment {
      mask: url(${ico_enter_pool_payment}) no-repeat 50% 50%;
    }

    &.icon-exception-companies {
      mask: url(${ico_exception_companies}) no-repeat 50% 50%;
    }

    &.icon-payout-rules {
      mask: url(${ico_payout_rules}) no-repeat 50% 50%;
    }

    &.icon-payout-rules-add {
      mask: url(${ico_payout_rules_add}) no-repeat 50% 50%;
    }

    &.icon-inspection {
      mask: url(${ico_inspection}) no-repeat 50% 50%;
    }

    &.icon-purchasing-tool {
      mask: url(${ico_purchasing_tool}) no-repeat 50% 50%;
    }

    &.icon-request-advance-payment {
      mask: url(${ico_request_advance_payment}) no-repeat 50% 50%;
    }

    &.icon-request-inspection {
      mask: url(${ico_request_inspection}) no-repeat 50% 50%;
    }

    &.icon-request-payment {
      mask: url(${ico_request_payment}) no-repeat 50% 50%;
    }

    &.icon-settlement-mock-up {
      mask: url(${ico_settlement_mock_up}) no-repeat 50% 50%;
    }

    &.icon-schedule-inspection {
      mask: url(${ico_schedule_inspection}) no-repeat 50% 50%;
    }

    &.icon-trusted-companies {
      mask: url(${ico_trusted_companies}) no-repeat 50% 50%;
    }

    &.icon-view-journal {
      mask: url(${ico_view_journal}) no-repeat 50% 50%;
    }

    &.icon-hedge {
      mask: url(${ico_hedge}) no-repeat 50% 50%;
    }

    &.icon-self-delivery {
      mask: url(${ico_self_delivery}) no-repeat 50% 50%;
    }

    &.icon-clock {
      mask: url(${ico_clock}) no-repeat 50% 50%;
    }

    &.icon-create-incoming-shipment {
      mask: url(${ico_create_incoming_shipment}) no-repeat 50% 50%;
    }

    &.icon-create-outgoing-shipment {
      mask: url(${ico_create_outgoing_shipment}) no-repeat 50% 50%;
    }

    &.icon-create-self-delivery {
      mask: url(${ico_create_self_delivery}) no-repeat 50% 50%;
    }

    &.icon-waiting {
      mask: url(${ico_waiting}) no-repeat 50% 50%;
    }

    &.icon-calendar-note {
      mask: url(${ico_calendar_note}) no-repeat 50% 50%;
    }

    &.icon-add-calendar-note {
      mask: url(${ico_add_calendar_note}) no-repeat 50% 50%;
    }

    &.icon-grg {
      mask: url(${grg_logo_mobile}) no-repeat 50% 50%;
    }

    &.icon-green {
      background-color: var(--atlantis);
    }

    &.icon-gray {
      background-color: var(--graniteGray);
    }

    &.error {
      background-color: var(--solidRed);
      transition: var(--transition);
    }

    &.warning {
      background-color: var(--solidYellow);
    }

    &.menu-arrow {
      transform: rotate(0deg) scale(-1, 1);

      &.animate {
        transform: rotate(180deg) scale(-1, 1);
      }
    }

    &.filter-arrow {
      background-color: var(--alabaster);
      transform: rotate(0deg) scale(-1, 1);

      &.animate {
        transform: rotate(180deg) scale(-1, 1);
      }
    }

    &.cursor {
      cursor: pointer;
    }

    ${media.phone`
      width: 30px;
      height: 30px;
    `}
  }
`;
