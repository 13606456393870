import React from 'react';

import { UIAuthorization } from 'components/Authorization/UIAuthorization';

import { RouterButton } from 'shared/components/Buttons';
import { Button } from 'shared/components/Buttons/Button';
import { ExtendableButton } from 'shared/components/Buttons/ExtendableButton/ExtendableButton';
import { DetailsHeader } from 'shared/components/Details/DetailsHeader';
import { companyUpdateOutstandingBalanceAccess } from 'shared/helpers/accesses/companies/companyUpdateOutstandingBalanceAccess';
import {
  companyInactivateAccess,
  companyReactivateAccess,
} from 'shared/helpers/accesses/companyActivationAccess';
import { companyTypeAccessHandler } from 'shared/helpers/accesses/companyTypeAccessHandler';
import { COMPANY } from 'shared/helpers/constants/companies/companyConstants';
import { SHARED } from 'shared/helpers/constants/sharedConstants';
import { isAssayCompany, isGradingCompany } from 'shared/helpers/matchers/checkCompanyType';

import { InactivateCompany } from './components/InactivateCompany/InactivateCompany';
import { ReactivateCompany } from './components/ReactivateCompany/ReactivateCompany';

function MainCompanyDetails({
  company,
  auth,
  companyDetailsGetAgreements,
  companyDetailsGetTerms,
  companyDetailsGetUsers,
}) {
  const defaultState = {
    listLimit: 3,
    listPage: 1,
  };

  const refreshCompanyRelatedEntities = () => {
    if (isAssayCompany(company.companyType)) {
      companyDetailsGetTerms(company._id, defaultState.listPage, defaultState.listLimit);
    }

    if (isGradingCompany(company.companyType)) {
      companyDetailsGetAgreements(company._id, defaultState.listPage, defaultState.listLimit);
    }

    companyDetailsGetUsers(company._id, defaultState.listPage, defaultState.listLimit);
  };

  return (
    <DetailsHeader header={company.companyName} status={company.status}>
      <ExtendableButton label={SHARED.EXTENDABLE_ACTIONS} closeOnClick>
        <UIAuthorization access={companyTypeAccessHandler(company.companyType, auth.user.userType)}>
          <RouterButton
            id="openUpdateCompany"
            type="button"
            path={`/companies/${company._id}/update`}
            label={COMPANY.EDIT_COMPANY}
          />
        </UIAuthorization>
        <UIAuthorization access={companyUpdateOutstandingBalanceAccess(auth)}>
          <RouterButton
            id="updateCompanyOutstandingBalance"
            color="danger"
            type="button"
            path={`/companies/${company._id}/update/outstanding-balance`}
            label={COMPANY.UPDATE_OUTSTANDING_BALANCE}
          />
        </UIAuthorization>
        <UIAuthorization access={companyInactivateAccess(auth, company)}>
          <InactivateCompany company={company} onInactivate={refreshCompanyRelatedEntities}>
            {({ inactivateCompany }) => (
              <Button outline id="inactivateCompany" onClick={inactivateCompany} color="danger">
                {COMPANY.INACTIVATE_COMPANY}
              </Button>
            )}
          </InactivateCompany>
        </UIAuthorization>
        <UIAuthorization access={companyReactivateAccess(auth, company)}>
          <ReactivateCompany company={company} onReactivate={refreshCompanyRelatedEntities}>
            {({ onClick }) => (
              <Button outline id="reactivateCompany" onClick={onClick}>
                {COMPANY.REACTIVATE_COMPANY}
              </Button>
            )}
          </ReactivateCompany>
        </UIAuthorization>
      </ExtendableButton>
    </DetailsHeader>
  );
}

export { MainCompanyDetails };
