import styled from 'styled-components';

import { cssVar } from 'shared/helpers/styling/styling';

export const StyledManageGlobalFiles = styled.div`
  h4,
  p {
    text-align: center;
    color: ${cssVar('geyser')};
    margin-bottom: 25px;
    white-space: pre-line;
  }
`;

export const Actions = styled.div`
  margin-top: 45px;
  display: flex;
  justify-content: space-between;
`;
