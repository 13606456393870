import {
  statusFilterActionType,
  sentFromCompanyFilterActionType,
  sentFromCompanyLocationFilterActionType,
  estPickupDateFromFilterActionType,
  estPickupDateToFilterActionType,
  estDeliveryDateFromFilterActionType,
  estDeliveryDateToFilterActionType,
  shipmentsFiltersClearActionType,
} from 'actions/Shipments/shipmentsFilters';

const INITIAL_SHIPMENT_STATE = {
  status: [],
  sentFromCompany: {},
  sentFromCompanyLocation: {},
  estPickupDate: {
    from: null,
    to: null,
  },
  estDeliveryDate: {
    from: null,
    to: null,
  },
};

const INITIAL_STATE = {
  incoming: { ...INITIAL_SHIPMENT_STATE },
  outgoing: { ...INITIAL_SHIPMENT_STATE },
};

const ShipmentsFiltersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case statusFilterActionType:
      return {
        ...state,

        [action.payload.direction]: {
          ...state[action.payload.direction],
          status: action.payload.status,
        },
      };
    case sentFromCompanyFilterActionType:
      return {
        ...state,
        [action.payload.direction]: {
          ...state[action.payload.direction],
          sentFromCompany: action.payload.sentFromCompany,
          sentFromCompanyLocation: {},
        },
      };
    case sentFromCompanyLocationFilterActionType:
      return {
        ...state,
        [action.payload.direction]: {
          ...state[action.payload.direction],
          sentFromCompanyLocation: action.payload.sentFromCompanyLocation,
        },
      };
    case estDeliveryDateFromFilterActionType:
      return {
        ...state,
        [action.payload.direction]: {
          ...state[action.payload.direction],
          estDeliveryDate: {
            ...state[action.payload.direction].estDeliveryDate,
            from: action.payload.estDeliveryDateFrom,
          },
        },
      };
    case estDeliveryDateToFilterActionType:
      return {
        ...state,
        [action.payload.direction]: {
          ...state[action.payload.direction],
          estDeliveryDate: {
            ...state[action.payload.direction].estDeliveryDate,
            to: action.payload.estDeliveryDateTo,
          },
        },
      };
    case estPickupDateFromFilterActionType:
      return {
        ...state,
        [action.payload.direction]: {
          ...state[action.payload.direction],
          estPickupDate: {
            ...state[action.payload.direction].estPickupDate,
            from: action.payload.estPickupDateFrom,
          },
        },
      };
    case estPickupDateToFilterActionType:
      return {
        ...state,
        [action.payload.direction]: {
          ...state[action.payload.direction],
          estPickupDate: {
            ...state[action.payload.direction].estPickupDate,
            to: action.payload.estPickupDateTo,
          },
        },
      };
    case shipmentsFiltersClearActionType:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export { ShipmentsFiltersReducer };
