import React from 'react';

import { StatusIndicator } from 'shared/components/StatusIndicator/StatusIndicator';
import { humanize, replaceEmpty } from 'shared/helpers/parsers/text';

import { COMPANY } from './companyConstants';

const itemsTemplate = [
  {
    label: COMPANY.STATUS,
    render: ({ status }) => <StatusIndicator status={status} />,
  },
  {
    label: COMPANY.COMPANY_NAME,
    render: ({ companyName }) => companyName,
    highlight: true,
    dueDiligence: true,
  },
  {
    label: COMPANY.COMPANY_TYPE,
    render: ({ companyType }) => humanize(companyType),
  },
  {
    label: COMPANY.LOT_PREFIX,
    render: ({ lotPrefix }) => lotPrefix,
    highlight: true,
  },
  {
    label: COMPANY.PRIMARY_LOCATION,
    render: ({ primaryLocation }) => {
      let stringPrimaryLocation = '';
      if (primaryLocation) {
        stringPrimaryLocation = (
          <>
            {primaryLocation.city || '-'}
            <br />
            {primaryLocation.state && primaryLocation.state.label}
          </>
        );
      }

      return replaceEmpty(stringPrimaryLocation);
    },
  },
];

const itemsTemplateMobile = [
  {
    label: COMPANY.STATUS,
    render: ({ status }) => <StatusIndicator status={status} />,
  },
  {
    label: COMPANY.COMPANY_NAME,
    render: ({ companyName }) => companyName,
    highlight: true,
    dueDiligence: true,
  },
];

const [, , ...itemsDetailsTemplate] = itemsTemplate;

export { itemsTemplate, itemsTemplateMobile, itemsDetailsTemplate };
