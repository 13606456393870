import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { changeLotsInMixingOverview, getLotsForMixingOverview } from 'actions/Lots/mixingOverview';
import { clearAllPackagesOverview } from 'actions/Packages/packagesOverviewList';

import { StyledLoadingSpinner } from 'assets/styles/common/StyledLoadingSpinner';

import { Button } from 'shared/components/Buttons/Button';
import { Icon } from 'shared/components/Icon/Icon';
import { OverviewHeader } from 'shared/components/OverviewHeader/OverviewHeader';
import { ScrollToTop } from 'shared/components/ScrollToTop/ScrollToTop';
import {
  acceptLotForMixingAccess,
  markProcessingCompleteAccess,
  fillPackagesForMixing,
} from 'shared/helpers/accesses/lots';
import { releaseMillPackagesAccess } from 'shared/helpers/accesses/lots/releaseMillPackagesAccess';
import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';
import {
  mixingActionsTemplate,
  overviewItemTemplate,
} from 'shared/helpers/constants/lots/overviewTableConfig';
import { statusesForGlobal } from 'shared/helpers/constants/lots/statusesForGlobal';
import { packageClasses } from 'shared/helpers/constants/packages/packageClasses';
import { packageStatuses } from 'shared/helpers/constants/packages/packageType';
import { isTablet } from 'shared/helpers/constants/resolutionsConstants';
import { SHARED } from 'shared/helpers/constants/sharedConstants';
import { generateMixingSectionHeader } from 'shared/helpers/dataParsers/lot/lotOverviewData';
import { capitalize } from 'shared/helpers/parsers/text';
import { cssVar } from 'shared/helpers/styling/styling';

import { goBackOrTo, history } from 'utils/history';
import { getBrowserWidth } from 'utils/responsive';

import { AcceptForMixing } from '../AcceptForMixing/AcceptForMixing';
import { ReleaseBarcodeScanner } from '../ReleaseBarcodeScanner/ReleaseBarcodeScanner';
import {
  StyledMixingOverviewDraggableSection,
  StyledMixingOverviewWrapper,
} from './StyledMixingOverview';

const isLotMixed = lot => lot.statusForGlobal === statusesForGlobal.accepted_for_mixing;

class MixingOverviewBase extends Component {
  isDragging = false;

  state = {
    showReleaseBarcodeScanner: false,
    isDropSectionDisabled: false,
    windowWidth: getBrowserWidth(),
  };

  componentDidMount() {
    this.getLotsForMixing();
    this.intervalId = setInterval(() => {
      const {
        mixingOverview: { isPending },
      } = this.props;

      if (!(isPending || this.isDragging)) {
        this.getLotsForMixing();
      }
    }, 5000);
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
    this.props.clearAllPackagesOverview();
  }

  onSelectedChange = (difference, destination) => {
    this.isDragging = false;

    this.props
      .changeLotsInMixingOverview(difference, destination)
      .then(this.props.getLotsForMixingOverview)
      .catch(this.getLotsForMixing);
  };

  onDragStart = ({ draggableId, source: { droppableId } }) => {
    const {
      auth,
      mixingOverview: { lots },
    } = this.props;

    const lot = lots[droppableId].find(lot => lot._id === draggableId);

    const markProcessingComplete =
      markProcessingCompleteAccess(auth, lot, true) &&
      lot.packages.filter(p => p.packageClass === packageClasses.post_mill && !p.releasedAt)
        .length === 0;

    releaseMillPackagesAccess(auth, lot) || markProcessingComplete
      ? this.setState({ isDropSectionDisabled: true })
      : this.setState({ isDropSectionDisabled: false });

    this.isDragging = true;
  };

  get sections() {
    const {
      mixingOverview: { lots },
    } = this.props;
    const { windowWidth } = this.state;

    return Object.entries(lots || {}).reduce(
      (acc, [name, data]) => ({
        ...acc,
        [name]: {
          data,
          placeholder: SHARED.DROPPABLE_PLACEHOLDER(capitalize(name)),
          header: generateMixingSectionHeader(name, data.length),
          sort: this.getSortFunction(name),
          expandable: name !== 'available' && windowWidth < isTablet,
          icon: name !== 'available' ? 'icon-cart' : null,
        },
      }),
      {},
    );
  }

  getLotsForMixing = () => {
    const { lots, isPending } = this.props;

    return (
      (isPending || !lots) && this.props.getLotsForMixingOverview().catch(() => goBackOrTo('/lots'))
    );
  };

  getSortFunction(name) {
    switch (name) {
      case 'available':
        return (a, b) => 2 * (a.receivedAt > b.receivedAt) - 1;
      default:
        return lot => -isLotMixed(lot);
    }
  }

  handleWindowSizeChange = () => this.setState({ windowWidth: getBrowserWidth() });

  toggleReleaseBarcodeScanner = lot => () =>
    this.setState(({ showReleaseBarcodeScanner }) => ({
      showReleaseBarcodeScanner: !showReleaseBarcodeScanner,
      releasedLot: lot,
    }));

  getDraggableProps = (lot, sectionId, index) => ({
    highlighted: isLotMixed(lot),
    icon: isLotMixed(lot) ? 'icon-process' : 'icon-drag',
    dragDisabled: this.isDragDisabled(lot),
    expanded: sectionId !== 'available' && index === 0,
  });

  isDragDisabled = lot => {
    const {
      mixingOverview: { isPending },
      auth,
    } = this.props;

    return auth.access.lots.mixingOverview.modifyAccepted
      ? isPending ||
          (markProcessingCompleteAccess(auth, lot, true) &&
            lot.packages.some(
              p =>
                p.packageClass === packageClasses.post_mix && p.status === packageStatuses.closed,
            ))
      : !auth.access.lots.mixingOverview.modify || isPending || isLotMixed(lot);
  };

  goToProcessingCompleteView = lot => () => history.push(`/lots/mixing/complete/${lot._id}`);

  goToFillPackageWeightView = lot => () => {
    return history.push(`/lots/processing/complete/${lot._id}`, {
      ...history.location.state,
      fromMixing: true,
    });
  };

  render() {
    const {
      auth,
      mixingOverview: { isPending },
    } = this.props;

    const { windowWidth, showReleaseBarcodeScanner, releasedLot, isDropSectionDisabled } =
      this.state;

    const postMillClass = packageClasses.post_mill;

    const expandedProcessingActionsTemplate = [
      {
        access: lot =>
          acceptLotForMixingAccess(
            auth,
            { ...lot, canBeMixed: true },
            {
              [postMillClass]: { docs: lot.packages.filter(p => p.packageClass === postMillClass) },
            },
            false,
          ),
        render: (lot, key) => (
          <AcceptForMixing lot={lot} key={key}>
            {({ acceptForMixing }) => (
              <Button onClick={acceptForMixing}>
                {LOTS.ACCEPT_FOR_MIXING}
                <Icon icon="icon-process" width={20} height={20} size="contain" />
              </Button>
            )}
          </AcceptForMixing>
        ),
      },
      {
        access: lot =>
          fillPackagesForMixing(
            auth,
            { ...lot, canBeMixed: true },
            {
              [postMillClass]: { docs: lot.packages.filter(p => p.packageClass === postMillClass) },
            },
          ),
        render: (lot, key) => (
          <Button key={key} onClick={this.goToFillPackageWeightView(lot)}>
            {LOTS.ACCEPT_FOR_MIXING}
            <Icon icon="icon-process" width={20} height={20} size="contain" />
          </Button>
        ),
      },
      {
        access: lot =>
          markProcessingCompleteAccess(auth, lot, true) &&
          lot.packages.filter(p => p.packageClass === packageClasses.post_mill && !p.releasedAt)
            .length === 0,
        render: (lot, key) => (
          <Button
            onClick={this.goToProcessingCompleteView(lot)}
            outlineColor={cssVar('shipCove')}
            key={key}
          >
            {LOTS.MIXING_COMPLETE}
            <Icon icon="icon-tick" width={20} height={20} size="contain" />
          </Button>
        ),
      },
      {
        access: lot => releaseMillPackagesAccess(auth, lot),
        render: (lot, key) => (
          <Button
            key={key}
            outlineColor={cssVar('roti')}
            onClick={this.toggleReleaseBarcodeScanner(lot)}
          >
            {LOTS.RELEASE_PACKAGE}
            <Icon icon="icon-tick" width={20} height={20} size="contain" />
          </Button>
        ),
      },
    ];

    return (
      <StyledMixingOverviewWrapper>
        <ScrollToTop>
          <OverviewHeader>
            {LOTS.MIXING_OVERVIEW}
            {isPending && <StyledLoadingSpinner />}
          </OverviewHeader>
          <StyledMixingOverviewDraggableSection
            sections={this.sections}
            itemTemplate={overviewItemTemplate}
            actionsTemplate={mixingActionsTemplate}
            expandedActionsTemplate={expandedProcessingActionsTemplate}
            getDraggableProps={this.getDraggableProps}
            disabled={this.isDragDisabled}
            onSelectedChange={this.onSelectedChange}
            onDragStart={this.onDragStart}
            windowWidth={windowWidth}
            isDropSectionDisabled={isDropSectionDisabled}
          />
          <ReleaseBarcodeScanner
            open={showReleaseBarcodeScanner}
            onSelfClose={this.toggleReleaseBarcodeScanner()}
            lot={releasedLot}
          />
        </ScrollToTop>
      </StyledMixingOverviewWrapper>
    );
  }
}

MixingOverviewBase.propTypes = {
  auth: PropTypes.shape({
    access: PropTypes.object,
    attempts: PropTypes.number,
    isPending: PropTypes.bool,
    user: PropTypes.object,
  }),
  changeLotsInMixingOverview: PropTypes.func,
  clearAllPackagesOverview: PropTypes.func,
  getLotsForMixingOverview: PropTypes.func,
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
  mixingOverview: PropTypes.shape({
    isPending: PropTypes.bool,
    lots: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.shape({
        M1: PropTypes.array,
        M2: PropTypes.array,
        available: PropTypes.array,
        Shears: PropTypes.array,
      }),
    ]),
  }),
};

const mapStateToProps = state => ({
  mixingOverview: state.mixingOverview,
  auth: state.auth,
});

const mapDispatchToProps = {
  getLotsForMixingOverview,
  changeLotsInMixingOverview,
  clearAllPackagesOverview,
};

const MixingOverview = connect(mapStateToProps, mapDispatchToProps)(MixingOverviewBase);

export { MixingOverviewBase, MixingOverview };
