import React from 'react';

import { IsBoolean } from 'shared/components/IsBoolean/IsBoolean';
import { StatusIndicator } from 'shared/components/StatusIndicator/StatusIndicator';
import { replaceEmpty } from 'shared/helpers/parsers/text';

import { LOCATION } from './locationConstants';

const itemsTemplate = [
  {
    label: LOCATION.STATUS,
    render: ({ status }) => <StatusIndicator status={status} />,
  },
  {
    label: LOCATION.NAME,
    render: ({ name }) => name,
    highlight: true,
  },
  {
    label: LOCATION.COUNTRY,
    render: ({ country }) => country,
  },
  {
    label: LOCATION.STATE,
    render: ({ state }) => replaceEmpty(state),
  },
  {
    label: LOCATION.CITY,
    render: ({ city }) => replaceEmpty(city),
  },
  {
    label: LOCATION.LOT_SUFFIX,
    render: ({ lotSuffix }) => lotSuffix,
    highlight: true,
  },
  {
    label: LOCATION.SHIPMENT_ELIGIBLE,
    render: ({ shipmentEligible }) => <IsBoolean value={shipmentEligible} />,
  },
  {
    label: LOCATION.PRIMARY_LOCATION,
    render: ({ primaryLocation }) => <IsBoolean value={primaryLocation} />,
  },
];

const itemsTemplateMobile = [
  {
    label: LOCATION.STATUS,
    render: ({ status }) => <StatusIndicator status={status} />,
  },
  {
    label: LOCATION.CITY,
    render: ({ city }) => replaceEmpty(city),
  },
];

const itemsDetailsTemplate = [
  {
    label: LOCATION.NAME,
    render: ({ name }) => name,
    highlight: true,
  },
  {
    label: LOCATION.COUNTRY,
    render: ({ country }) => country,
  },
  {
    label: LOCATION.STATE,
    render: ({ state }) => replaceEmpty(state),
  },
  {
    label: LOCATION.LOT_SUFFIX,
    render: ({ lotSuffix }) => lotSuffix,
    highlight: true,
  },
  {
    label: LOCATION.SHIPMENT_ELIGIBLE,
    render: ({ shipmentEligible }) => <IsBoolean value={shipmentEligible} />,
  },
  {
    label: LOCATION.PRIMARY_LOCATION,
    render: ({ primaryLocation }) => <IsBoolean value={primaryLocation} />,
  },
];

export { itemsTemplate, itemsTemplateMobile, itemsDetailsTemplate };
