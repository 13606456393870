import styled from 'styled-components';

import icon_bell from 'assets/icons/ico_bell.svg';

import { media } from 'shared/helpers/styling/styling';

import { isWest } from 'utils/versioning/Guards';

export const StyledNotifications = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  @media screen and (min-width: 1009px) {
    margin-left: auto;
  }
  height: 90px;
  width: 100px;
  border-left: 1px solid rgba(150, 150, 150, 0.2);
  cursor: pointer;

  &:hover {
    background-color: var(--outerSpaceBitLighter);
  }

  ${isWest ? `background-color: var(--argentBitLighter);` : ''}

  ${media.mobile`
    justify-content: flex-end;
    border: none;
    width: 50px;

    :hover {
      background-color: ${isWest ? 'var(--lightGrayGRG)' : 'var(--outerSpace)'};
    }
`}

  ${media.phone`
    justify-content: flex-end;
    border: none;
    height: 35px;
    width: 35px;

    &:hover {
      background-color: ${isWest ? `var(--lightGrayGRG);` : 'var(--outerSpace);'}
    }
`}
`;

export const StyledNotificationsBell = styled.div`
  height: 30px;
  width: 30px;
  mask: url(${icon_bell}) no-repeat;
  background-color: ${isWest ? 'var(--graniteGray)' : 'var(--whiteGRG)'};

  ${media.mobile`
  margin-right: 7px;
  `}

  ${media.phone`
  margin-right: 7px;
      width: 24px;
      height: 24px;
  `}
`;

export const StyledNotificationsCounter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px;
  width: 26px;
  border-radius: 50%;
  font-size: 0.75em;
  font-weight: bold;
  background-color: var(--whiteGRG);
  text-align: center;
  position: absolute;
  right: 28px;
  top: 17px;

  ${isWest
    ? `
    background-color: var(--graniteGray);
    color: var(--lightGrayGRG);
  `
    : ''}

  ${media.mobile`
      width: 20px;
      height: 20px;
      top: 21px;
      right: 4px;
  `}

  ${media.phone`
      width: 20px;
      height: 20px;
      top: 0;
      right: 3px;
  `}
`;
