import { connect } from 'react-redux';

import { finalizeLot } from 'actions/Lots/finalizeLot';
import { openModal } from 'actions/shared/modal';
import { showSnackbar } from 'actions/shared/snackbar';

import { finalizeLotModal } from 'shared/helpers/constants/modalConstants';
import {
  dangerOptions,
  successOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

function FinalizeLotLayout(props) {
  const { openModal, lot, superAdminMode, showSnackbar, finalizeLot, children } = props;

  const finalizeLotAction = () => {
    openModal(finalizeLotModal, () => {
      finalizeLot(lot._id, superAdminMode)
        .then(({ message }) => showSnackbar(successOptions, message))
        .catch(({ message }) => showSnackbar(dangerOptions, message));
    });
  };

  return children({ finalizeLot: finalizeLotAction });
}

const mapDispatchToProps = {
  showSnackbar,
  openModal,
  finalizeLot,
};

const FinalizeLot = connect(null, mapDispatchToProps)(FinalizeLotLayout);

export { FinalizeLot, FinalizeLotLayout };
