const roles = {
  owner: {
    name: 'owner',
    standard: 'standard',
    assist: 'assist',
  },
  support: {
    name: 'support',
    standard: 'standard',
    assist: 'assist',
  },
  warehouse: {
    name: 'warehouse',
    standard: 'standard',
    assist: 'assist',
    limited: 'limited',
  },
  customer: {
    name: 'customer',
    full_hedging: 'full_hedging',
    limited_hedging: 'limited_hedging',
    view_and_ship: 'view_and_ship',
    ship_only: 'ship_only',
  },
  grading: {
    name: 'grading',
    standard: 'standard',
  },
  external: {
    name: 'external',
    standard: 'standard',
  },
  buyer: {
    standard: 'standard',
  },
};

const userTypes = Object.keys(roles);

export { roles, userTypes };
