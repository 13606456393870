const DASHBOARD = {
  TODAY_IS: 'Today is',
  MARKET_HOLIDAY: '(Market Holiday)',
  CONVERTER_DATABASE: 'Converter database',
  PURCHASING_TOOL: 'Purchasing tool',
  DASHBOARD: 'Dashboard',
  YOU_DO_NOT_HAVE_NOTIFICATIONS: 'No unread notifications',
};

export { DASHBOARD };
