import axios from 'axios';

import { showSnackbar } from 'actions/shared/snackbar';

import {
  dangerOptions,
  successOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { USER } from 'shared/helpers/constants/users/userConstants';
import { downloadFile } from 'shared/helpers/dataParsers/files/downloadFile';

const API_HOST_AUTH = process.env.REACT_APP_API;

export const generateUsersReport = () => dispatch => {
  return axios
    .get(`${API_HOST_AUTH}/users/report/generate`, {
      headers: {
        'Content-Disposition': 'attachment; filename=template.xlsx',
        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
      responseType: 'arraybuffer',
    })
    .then(res => {
      downloadFile(res);
      dispatch(showSnackbar(successOptions, USER.GENERATE_REPORT_SUCCESS));
    })
    .catch(() => {
      dispatch(showSnackbar(dangerOptions, USER.GENERATE_REPORT_ERROR));
      return Promise.reject();
    });
};
