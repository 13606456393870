import { isGradingUser } from 'shared/helpers/matchers/checkUserType';
import { dateWithTimeToTimestamp } from 'shared/helpers/parsers/date';
import { removeCommasAndParse } from 'shared/helpers/parsers/text';

function parseSelfDeliveryRequestData(formValues, userObj) {
  const relatedCompany = isGradingUser(userObj.userType)
    ? userObj.relatedCompany._id
    : formValues.relatedCompany.value;

  return {
    relatedCompany,
    fundsType: formValues.fundsType.value,
    estimatedFunds: removeCommasAndParse(formValues.estimatedFunds),
    unitCount: formValues.unitCount,
    deliveryDate: dateWithTimeToTimestamp(formValues.deliveryDate, formValues.deliveryDateTime),
    customerInstructions: formValues.customerInstructions,
  };
}

export { parseSelfDeliveryRequestData };
