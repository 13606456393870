import React, { useEffect } from 'react';

import { FormContainer } from 'shared/components/Form';
import { LOCATION } from 'shared/helpers/constants/locations/locationConstants';

import { LocationForm } from '../LocationForm/LocationForm';

function CreateLocation({
  companyDetails,
  getCompany,
  clearCompanyDetails,
  match: { params },
  ...props
}) {
  useEffect(() => {
    getCompany(params.id);

    return () => clearCompanyDetails();
  }, [params.id, getCompany, clearCompanyDetails]);

  const submit = values =>
    props.createLocation(values, {
      value: companyDetails.company._id,
      companyName: companyDetails.company.companyName,
    });

  return (
    <FormContainer
      header={LOCATION.CREATE_A_LOCATION}
      subHeader={companyDetails.company && companyDetails.company.companyName}
      loaded={!companyDetails.isPending && !!Object.keys(companyDetails.company || {}).length}
    >
      <LocationForm
        {...props}
        editMode={false}
        relatedCompany={companyDetails.company}
        onSubmit={submit}
      />
    </FormContainer>
  );
}

export { CreateLocation };
