import {
  incomingLotsOverviewActionType,
  incomingLotsOverviewFailureActionType,
  incomingLotsOverviewSuccessActionType,
  incomingLotsOverviewClearActionType,
  setPriorityActionType,
  setPrioritySuccessActionType,
} from 'actions/Lots/incomingLotsOverview';

import { incomingSections } from 'shared/helpers/constants/lots/incomingSections';

const INITIAL_SECTION_STATE = { isPending: false, items: null };

const INITIAL_STATE = {
  [incomingSections.partlyReceived.name]: { ...INITIAL_SECTION_STATE },
  [incomingSections.receivedGradingRequired.name]: { ...INITIAL_SECTION_STATE },
  [incomingSections.receivedMaterialCount.name]: { ...INITIAL_SECTION_STATE },
  [incomingSections.receivedOnHold.name]: { ...INITIAL_SECTION_STATE },
  [incomingSections.removeSamples.name]: { ...INITIAL_SECTION_STATE },
};

export default (state = INITIAL_STATE, { type, payload }) => {
  const section = payload && payload.section;
  const getPriority = p => (payload.lotIds.includes(p._id) ? null : p[section.priorityField]);

  switch (type) {
    case incomingLotsOverviewActionType:
      return {
        ...state,
        [section.name]: {
          ...state[section.name],
          isPending: true,
        },
      };
    case incomingLotsOverviewSuccessActionType:
      return {
        ...state,
        [section.name]: {
          isPending: false,
          items: payload.data,
        },
      };
    case incomingLotsOverviewFailureActionType:
      return {
        ...state,
        [section.name]: {
          ...state[section.name],
          isPending: false,
        },
      };
    case setPriorityActionType:
      return {
        ...state,
        [section.name]: {
          ...state[section.name],
          items: {
            ...state[section.name].items,
            docs: state[section.name].items.docs.map(p => ({
              ...p,
              [section.priorityField]: payload.reset
                ? getPriority(p)
                : payload.lotIds.indexOf(p._id) + 1,
            })),
          },
        },
      };
    case setPrioritySuccessActionType:
      return {
        ...state,
        [section.name]: {
          ...state[section.name],
          items: {
            ...state[section.name].items,
            docs: state[section.name].items.docs.map(lot => {
              const newLot = payload.lots.find(({ _id }) => _id === lot._id);

              return newLot || lot;
            }),
          },
        },
      };
    case incomingLotsOverviewClearActionType:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};
