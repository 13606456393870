import React, { Component } from 'react';

import { GroupButtons } from 'shared/components/Buttons';
import { GroupButton } from 'shared/components/Buttons/GroupButton/components/GroupButton';
import { paymentStatuses } from 'shared/helpers/constants/payments/paymentStatuses';
import { PAYMENTS } from 'shared/helpers/constants/payments/paymentsConstants';
import {
  itemsTemplate,
  itemsTemplateCustomer,
  itemsDetailsTemplate,
  itemsDetailsTemplateCustomer,
  itemsTemplateMobile,
} from 'shared/helpers/constants/payments/paymentsListTableConfig';
import { POOL_ACCOUNTS } from 'shared/helpers/constants/poolAccounts/poolAccountsConstants';
import {
  successOptions,
  dangerOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { isCustomerUser, isOwnerOrSupport } from 'shared/helpers/matchers/checkUserType';
import { isGlobalRefiningGroup } from 'shared/helpers/matchers/isGlobalRefiningGroup';
import { isGlobalUserType } from 'shared/helpers/matchers/isGlobalUserType';
import { isMobile } from 'shared/helpers/styling/styling';

import { BankHolidayAlert } from '../BankHolidayAlert/BankHolidayAlert';
import { StyledPaymentList } from './StyledPaymentsList';
import { PaymentsFilters } from './components/PaymentsFilters/PaymentsFilters';

class PaymentsList extends Component {
  componentDidMount() {
    const {
      location: { pathname },
      history,
      getAccountsReceivable,
      auth: {
        user: { userType },
      },
    } = this.props;

    if (pathname.includes('outstanding')) {
      this.onFiltersChanged('')({
        status: [paymentStatuses.approved],
        paymentType: [],
        fundsType: [],
        isPaid: false,
      }).then(() => history.replace('/payments'));
    }

    isGlobalUserType(userType) && getAccountsReceivable();
  }

  componentWillUnmount() {
    const { clearPaymentsAction, clearAccountsReceivable } = this.props;

    clearPaymentsAction();
    clearAccountsReceivable();
  }

  onFiltersChanged = searchFilter => filters => {
    const {
      getPayments,
      paymentsList: {
        pagination: { limit },
      },
    } = this.props;

    return getPayments({ ...filters, query: searchFilter }, { page: 1, limit });
  };

  get tableActions() {
    const { auth, history } = this.props;

    const actions = [];

    if (auth.access.payments.details) {
      actions.push({
        icon: 'arrow-right',
        action: 'Details',
        onClick: payment => () => {
          history.push(`/payments/${payment._id}`);
        },
      });
    }

    return actions;
  }

  deletePayment = payment => {
    const {
      paymentsList: { filters, pagination },
      getPayments,
      deletePayment,
      showSnackbar,
    } = this.props;

    deletePayment(payment)
      .then(res => {
        getPayments(filters, pagination);
        showSnackbar(successOptions, res.message);
      })
      .catch(() => showSnackbar(dangerOptions, PAYMENTS.ERROR.PAYMENT_DELETE_ERROR));
  };

  filtersSelected = filters =>
    filters.status.length ||
    filters.paymentType.length ||
    filters.fundsType.length ||
    filters.paidAtStart ||
    filters.paidAtEnd ||
    typeof filters.isPaid === 'boolean';

  filtersClear = searchFilter => {
    const {
      getPayments,
      paymentsList: { pagination },
    } = this.props;

    getPayments(
      {
        status: [],
        paymentType: [],
        fundsType: [],
        query: searchFilter,
        isPaid: null,
      },
      { limit: pagination.limit, page: 1 },
    );
  };

  fetchList = (page = 1, limit = 50, query) => {
    const {
      getPayments,
      paymentsList: { filters },
      location: { pathname },
    } = this.props;

    if (!pathname.includes('outstanding')) {
      getPayments({ ...filters, query }, { page, limit });
    }
  };

  renderFilters = searchFilter => {
    const {
      paymentsList: { filters, pagination },
      auth,
    } = this.props;

    return (
      <PaymentsFilters
        filters={filters}
        pagination={pagination}
        onFiltersChanged={this.onFiltersChanged(searchFilter)}
        auth={auth}
      />
    );
  };

  renderCustomerButtons = () => {
    const {
      auth: { access, user },
      history,
    } = this.props;

    return (
      <div className="PaymentsList-actionButtons">
        <GroupButtons>
          <GroupButton
            id="requestFinalPayment"
            access={
              access.payments.request && !isGlobalRefiningGroup(user.relatedCompany.companyType)
            }
            onClick={() => history.push('/payments/request/final')}
            label={PAYMENTS.REQUEST_FINAL_PAYMENT}
            icon="request-payment"
          />
          <GroupButton
            id="requestAdvancePayment"
            access={access.payments.request}
            onClick={() => history.push('/payments/request/advance')}
            label={PAYMENTS.REQUEST_ADVANCE_PAYMENT}
            icon="request-advance-payment"
          />
          <GroupButton
            id="requestSettlementForLot"
            access={access.poolAccounts.requestSettlement.forLot}
            onClick={() => history.push('pool-accounts/settlement-for-lot')}
            label={POOL_ACCOUNTS.SETTLE_LOTS}
            icon="close-lot"
          />
        </GroupButtons>
      </div>
    );
  };

  renderActionButtons = () => {
    const { auth, history } = this.props;

    return (
      <div className="PaymentsList-actionButtons">
        <GroupButtons>
          <GroupButton
            id="enterPayment"
            access={auth.access.payments.enter}
            onClick={() => history.push('/payments/enter')}
            label={PAYMENTS.REQUEST_PAYMENT}
            icon="enter-payment"
          />
          <GroupButton
            id="enterPoolPayment"
            access={auth.access.payments.enter}
            onClick={() => history.push('/payments/enter-pool')}
            label={PAYMENTS.REQUEST_POOL_PAYMENT}
            icon="enter-pool-payment"
          />
          <GroupButton
            id="bankHolidays"
            access={auth.access.payments.enter && isMobile()}
            onClick={() => history.push('/payments/bank-holidays')}
            label={PAYMENTS.BANK_HOLIDAYS.TITLE}
            icon="add-market-holiday"
          />
          <GroupButton
            id="viewPaymentExceptionCompanies"
            access={auth.access.payments.paymentExceptionCompanies.list && isMobile()}
            onClick={() => history.push('/payments/payment-exception-companies')}
            label={PAYMENTS.EXCEPTION_COMPANIES}
            icon="exception-companies"
          />
          <GroupButton
            id="viewPayoutRules"
            access={auth.access.payments.payoutRules.list && isMobile()}
            onClick={() => history.push('/payments/payout-rules')}
            label={PAYMENTS.PAYOUT_RULES.TITLE}
            icon="payout-rules"
          />
        </GroupButtons>
      </div>
    );
  };

  render() {
    const {
      auth: {
        user: { userType },
      },
      paymentsList: { pagination, payments, filters, isPending },
      accountsReceivable: { accountsReceivable },
    } = this.props;

    return (
      <>
        {isOwnerOrSupport(userType) && <BankHolidayAlert />}
        <StyledPaymentList
          title={PAYMENTS.PAYMENTS}
          subheader={
            isGlobalUserType(userType) && PAYMENTS.TOTAL_ACCOUNTS_RECEIVABLE(accountsReceivable)
          }
          itemsTemplate={isCustomerUser(userType) ? itemsTemplateCustomer : itemsTemplate}
          itemsDetailsTemplate={
            isCustomerUser(userType) ? itemsDetailsTemplateCustomer : itemsDetailsTemplate
          }
          itemsTemplateMobile={itemsTemplateMobile}
          actionsTemplate={this.tableActions}
          items={payments.docs || []}
          fetchList={this.fetchList}
          filterComponent={this.renderFilters}
          pagination={{ ...pagination, pages: payments.pages }}
          enableSearch
          filtersClear={this.filtersClear}
          filtersSelected={this.filtersSelected(filters)}
          filterAccess
          actionButtons={
            isGlobalUserType(userType) ? this.renderActionButtons : this.renderCustomerButtons
          }
          isPending={isPending}
          filters={filters}
        />
      </>
    );
  }
}

export { PaymentsList };
