import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, formValueSelector } from 'redux-form';

import { getCompanies } from 'actions/Companies/companiesList';
import { getSummaryAvailableLotsForCompany } from 'actions/PoolAccounts/getSummaryAvailableLots';
import { getUsersForSettleOunces } from 'actions/Users/usersList';

import { IconButton } from 'shared/components/Buttons/IconButton/IconButton';
import { FieldConfirmation } from 'shared/components/Fields/FieldConfirmation/FieldConfirmation';
import { FieldDate } from 'shared/components/Fields/FieldDate/FieldDate';
import { FieldInput } from 'shared/components/Fields/FieldInput/FieldInput';
import { FieldSearch } from 'shared/components/Fields/FieldSearch/FieldSearch';
import { FieldSelect } from 'shared/components/Fields/FieldSelect/FieldSelect';
import { FieldTime } from 'shared/components/Fields/FieldTime/FieldTime';
import { Section } from 'shared/components/Form';
import { MultipleFieldsSection } from 'shared/components/Form/MultipleFieldsSection/MultipleFieldsSection';
import { companyStatuses } from 'shared/helpers/constants/companies/companiesFilters';
import { companyTypesEnum } from 'shared/helpers/constants/filters/companyTypes';
import { ENTER_SETTLED_OUNCES } from 'shared/helpers/constants/settlements/settlementsConstants';
import { parseAllLotsSelectData } from 'shared/helpers/dataParsers/lot/parseLotsSelectData';
import { isGlobalRefiningGroup } from 'shared/helpers/matchers/isGlobalRefiningGroup';
import { cssVar } from 'shared/helpers/styling/styling';

import dateAdapter from 'utils/date/dateAdapter';
import { MomentAdapter } from 'utils/date/momentAdapter';

const selector = formValueSelector('enterSettledOunces');

const EnterSettledOunceForm = ({ header, onRemove, settledOunce, resetSection, change }) => {
  const dispatch = useDispatch();
  const availableLots = useSelector(({ summaryAvailableLots }) => summaryAvailableLots);
  const auth = useSelector(({ auth }) => auth);
  const { users } = useSelector(({ usersList }) => usersList);
  const relatedCompany = useSelector(state => selector(state, `${settledOunce}.relatedCompany`));
  const requestedOnDate = useSelector(state => selector(state, `${settledOunce}.requestedOnDate`));
  const ouncesPtValue = useSelector(state => selector(state, `${settledOunce}.ouncesPt`));
  const ouncesPdValue = useSelector(state => selector(state, `${settledOunce}.ouncesPd`));
  const ouncesRhValue = useSelector(state => selector(state, `${settledOunce}.ouncesRh`));
  const actions = onRemove && (
    <IconButton onClick={onRemove} icon="icon-cancel" color={cssVar('whiteGRG')} />
  );

  useEffect(() => {
    (ouncesPtValue && +ouncesPtValue === 0 && change(`${settledOunce}.pricePerOuncePt`, '')) ||
      (ouncesPdValue && +ouncesPdValue === 0 && change(`${settledOunce}.pricePerOuncePd`, '')) ||
      (ouncesRhValue && +ouncesRhValue === 0 && change(`${settledOunce}.pricePerOunceRh`, ''));
  }, [ouncesPtValue, ouncesPdValue, ouncesRhValue]);

  const getFilteredCompanies = useCallback(filter =>
    dispatch(
      getCompanies(0, 1, filter, {
        status: [companyStatuses.active, companyStatuses.no_terms],
        companyTypes: [companyTypesEnum.assay, companyTypesEnum.grading, companyTypesEnum.internal],
      }),
    ).then(({ payload }) => {
      return {
        options: payload.companies.map(({ companyName, _id, companyType }) => ({
          label: companyName,
          value: _id,
          companyType,
        })),
      };
    }),
  );

  const relatedCompanyChange = useCallback(val => {
    resetSection(`${settledOunce}.relatedLot`, `${settledOunce}.requestedBy`);

    if (val) {
      dispatch(getSummaryAvailableLotsForCompany(val.value));
      dispatch(
        getUsersForSettleOunces({
          status: ['active'],
          accessLevel: [],
          userType:
            val.companyType === companyTypesEnum.grading || isGlobalRefiningGroup(val.label)
              ? ['owner', 'support']
              : [],
          relatedCompany:
            val.companyType === companyTypesEnum.grading ? auth.user.relatedCompany._id : val.value,
        }),
      );
    }
  }, []);

  const requestedOnDateChange = useCallback(date => {
    resetSection(`${settledOunce}.requestedOnTime`);

    return dateAdapter(date).localize().valueOf();
  }, []);

  const parsedLots = () => {
    const filteredLots =
      (availableLots.lots && availableLots.lots[relatedCompany && relatedCompany.value]) || [];
    return parseAllLotsSelectData(filteredLots);
  };

  const parsedUsers = () =>
    (
      (users &&
        relatedCompany &&
        users[
          relatedCompany.companyType === companyTypesEnum.grading
            ? auth.user.relatedCompany._id
            : relatedCompany.value
        ]) ||
      []
    ).map(({ firstName, lastName, _id }) => ({
      label: `${firstName} ${lastName}`,
      value: _id,
    }));

  const resolveTime = () => {
    const isToday =
      requestedOnDate &&
      MomentAdapter.normalize(requestedOnDate).valueOf() === dateAdapter().startOf('d').valueOf();

    return {
      minTime: dateAdapter().startOf('d').localizeTime(),
      maxTime: isToday
        ? dateAdapter().startOf('h').localizeTime()
        : dateAdapter().endOf('d').localizeTime(),
    };
  };

  const ouncesFields = [
    {
      component: FieldInput,
      name: `${settledOunce}.ouncesPt`,
      suffix: ENTER_SETTLED_OUNCES.OZ,
      label: ENTER_SETTLED_OUNCES.OUNCES_PT,
      maxLength: '10',
      field: 'required',
      type: 'text',
    },
    {
      component: FieldInput,
      name: `${settledOunce}.ouncesPd`,
      suffix: ENTER_SETTLED_OUNCES.OZ,
      label: ENTER_SETTLED_OUNCES.OUNCES_PD,
      maxLength: '10',
      field: 'required',
      type: 'text',
    },
    {
      component: FieldInput,
      name: `${settledOunce}.ouncesRh`,
      suffix: ENTER_SETTLED_OUNCES.OZ,
      label: ENTER_SETTLED_OUNCES.OUNCES_RH,
      maxLength: '10',
      field: 'required',
      type: 'text',
    },
  ];

  return (
    <>
      <Section
        header={header}
        actions={actions}
        template={[`${settledOunce}.relatedCompany ${settledOunce}.relatedLot`]}
      >
        <Field
          name={`${settledOunce}.relatedCompany`}
          component={FieldSearch}
          label={ENTER_SETTLED_OUNCES.RELATED_COMPANY}
          field="required"
          getOptions={getFilteredCompanies}
          onChange={relatedCompanyChange}
        />
        <Field
          name={`${settledOunce}.relatedLot`}
          component={FieldSelect}
          options={parsedLots()}
          label={ENTER_SETTLED_OUNCES.RELATED_LOT}
          disabled={!relatedCompany}
          field="required"
        />
      </Section>
      <Section
        header={ENTER_SETTLED_OUNCES.OUNCES}
        template={['ouncesFields ouncesFields']}
        noPadding
      >
        <MultipleFieldsSection name="ouncesFields" fields={ouncesFields} />
      </Section>
      <Section
        header={ENTER_SETTLED_OUNCES.PRICES_PER_OUNCE}
        template={[
          `${settledOunce}.pricePerOuncePt ${settledOunce}.pricePerOuncePt`,
          `${settledOunce}.pricePerOuncePd ${settledOunce}.pricePerOuncePd`,
          `${settledOunce}.pricePerOunceRh ${settledOunce}.pricePerOunceRh`,
        ]}
        noPadding
      >
        {ouncesPtValue && +ouncesPtValue !== 0 && (
          <FieldConfirmation
            name={`${settledOunce}.pricePerOuncePt`}
            prefix={ENTER_SETTLED_OUNCES.DOLLAR}
            label={ENTER_SETTLED_OUNCES.PRICE_PER_OUNCE_PT}
            change={change}
            maxLength="10"
            field="optional"
            type="text"
          />
        )}
        {ouncesPdValue && +ouncesPdValue !== 0 && (
          <FieldConfirmation
            name={`${settledOunce}.pricePerOuncePd`}
            prefix={ENTER_SETTLED_OUNCES.DOLLAR}
            label={ENTER_SETTLED_OUNCES.PRICE_PER_OUNCE_PD}
            change={change}
            maxLength="10"
            field="optional"
            type="text"
          />
        )}
        {ouncesRhValue && +ouncesRhValue !== 0 && (
          <FieldConfirmation
            name={`${settledOunce}.pricePerOunceRh`}
            prefix={ENTER_SETTLED_OUNCES.DOLLAR}
            label={ENTER_SETTLED_OUNCES.PRICE_PER_OUNCE_RH}
            change={change}
            maxLength="10"
            field="optional"
            type="text"
          />
        )}
      </Section>
      <Section
        header={ENTER_SETTLED_OUNCES.DETAILS}
        template={[
          `${settledOunce}.requestedOnDate ${settledOunce}.requestedOnTime`,
          `${settledOunce}.requestedBy .`,
        ]}
      >
        <Field
          name={`${settledOunce}.requestedOnDate`}
          component={FieldDate}
          label={ENTER_SETTLED_OUNCES.REQUESTED_ON_DATE}
          field="required"
          maxDate={Date.now()}
          onDateChange={requestedOnDateChange}
          disableFlip
        />
        <Field
          name={`${settledOunce}.requestedOnTime`}
          component={FieldTime}
          label={ENTER_SETTLED_OUNCES.REQUESTED_ON_TIME}
          field="required"
          icon="icon-clock"
          disabled={!requestedOnDate}
          timeRange={resolveTime()}
          disableFlip
        />
        <Field
          name={`${settledOunce}.requestedBy`}
          component={FieldSelect}
          options={parsedUsers()}
          label={ENTER_SETTLED_OUNCES.REQUESTED_BY}
          disabled={!relatedCompany}
          field="required"
        />
      </Section>
    </>
  );
};

export { EnterSettledOunceForm };
