import styled from 'styled-components';

import { media } from 'shared/helpers/styling/styling';

import { resolveVersion, versions } from 'utils/versioning';

export const StyledHeader = styled.div`
  @media print {
    display: none;
  }
`;

export const StyledHeaderWrapper = styled.div`
  padding-left: calc(100vw - 100%);

  ${resolveVersion({
    [versions.global]: 'background: var(--outerSpace);',
    [versions.alpha]: 'background: var(--outerSpace);',
    [versions.west]: 'background: var(--lightGrayGRG); border-bottom: 1px solid var(--argent);',
  })}

  @media print {
    display: none;
  }

  .navbar {
    padding: 0;
  }
`;

export const StyledHeaderContent = styled.div`
  &-user {
    font-size: 1em;
    display: flex;
    justify-content: space-between;

    &-avatar {
      height: 50px;
      font-size: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50px;
      border-radius: 50px;
      color: var(--geyser);
      border: solid 2px var(--fiord);
    }

    &-text {
      margin: 0 20px;
      display: flex;
      flex-direction: column;
      vertical-align: middle;
      line-height: 32px;

      &-type {
        font-weight: 600;
        color: var(--lynch);
        height: 16px;
      }

      &-name {
        color: var(--geyser);
        height: 16px;
      }
    }
  }

  ${media.phone`
  height: 60px;
  padding: 0 15px;

  .navbar {
    height: 60px;
   }
 `}

  ${media.mobile`
    .container-fluid:nth-child(1){
      position: relative;
      > div {
        flex-basis: 33%;
      }

      > div:nth-child(2) {
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
`}
`;

export const StyledHeaderLogo = styled.img`
  cursor: pointer;
  height: 48px;

  ${media.phone`
  height: 35px;
`}
`;
