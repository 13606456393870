const statusesForCustomer = {
  open: 'open',
  received: 'received',
  summary_available: 'summary_available',
  settled: 'settled',
  finalized: 'finalized',
};

const availableStatusesForCustomer = {
  all: Object.values(statusesForCustomer),
};

export { statusesForCustomer, availableStatusesForCustomer };
