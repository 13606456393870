const statuses = {
  submitted: 'submitted',
  approved: 'approved',
  declined: 'declined',
};

const selfDeliveryRequestStatuses = {
  ...statuses,
  all: Object.values(statuses),
};

export { selfDeliveryRequestStatuses };
