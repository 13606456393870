import {
  createLocationActionType,
  createLocationFailureActionType,
  createLocationSuccessActionType,
} from 'actions/Locations/createLocation';

const INITIAL_STATE = {
  isPending: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case createLocationActionType:
      return {
        isPending: true,
      };
    case createLocationFailureActionType:
      return {
        isPending: false,
      };
    case createLocationSuccessActionType:
      return {
        isPending: false,
      };
    default:
      return state;
  }
};
