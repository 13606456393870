import React, { Component } from 'react';

import { FiltersDropdown } from 'shared/components/FiltersDropdown/FiltersDropdown';
import { USER } from 'shared/helpers/constants/users/userConstants';
import {
  userStatuses,
  userTypesOwner,
  userTypesSupport,
  accessLevels,
} from 'shared/helpers/constants/users/userFilters';
import { isOwnerUser, isSupportUser } from 'shared/helpers/matchers/checkUserType';

import { resolveVersion, versions } from 'utils/versioning';

import { StyledUsersFilters, StyledUsersFiltersItem } from './StyledUsersFilters';

const displayAccessLevel = resolveVersion({
  [versions.alpha]: true,
  [versions.global]: true,
  [versions.west]: true,
});

class UsersFilters extends Component {
  filterFunction = name => value => {
    if (name === 'userType') {
      value[0]
        ? this.props.onFiltersChanged({
            ...this.props.filters,
            accessLevel: [],
            [name]: value[0],
          })
        : this.props.onFiltersChanged({
            ...this.props.filters,
            accessLevel: [],
            [name]: '',
          });
    } else {
      this.props.onFiltersChanged({
        ...this.props.filters,
        [name]: value,
      });
    }
  };

  availableAccesses = userType => {
    if (userType) {
      switch (userType) {
        case userTypesOwner.owner:
          return accessLevels.owner;
        case userTypesOwner.support:
          return accessLevels.support;
        case userTypesOwner.warehouse:
          return accessLevels.warehouse;
        case userTypesOwner.customer:
          return accessLevels.customer;
        case userTypesOwner.grading:
          return accessLevels.grading;
        case userTypesOwner.external:
          return accessLevels.external;
        case userTypesOwner.buyer:
          return accessLevels.buyer;
        default:
          return [];
      }
    }
    return [];
  };

  render() {
    const {
      auth: { user, access },
      filters: { status, userType, accessLevel },
    } = this.props;

    let userTypeOptions =
      (isSupportUser(user.userType) && Object.values(userTypesSupport)) ||
      (isOwnerUser(user.userType) && Object.values(userTypesOwner));

    if (!access.companies.gradingEnabled) {
      userTypeOptions = userTypeOptions.filter(type => type !== 'grading');
    }

    return (
      <StyledUsersFilters>
        <StyledUsersFiltersItem>
          <FiltersDropdown
            label={USER.STATUS}
            labelWidth={150}
            options={Object.values(userStatuses)}
            selected={status || ''}
            filterFunction={this.filterFunction('status')}
          />
        </StyledUsersFiltersItem>
        <StyledUsersFiltersItem>
          <FiltersDropdown
            label={USER.USER_TYPE}
            labelWidth={150}
            options={userTypeOptions}
            selected={userType && ([userType] || '')}
            filterFunction={this.filterFunction('userType')}
          />
        </StyledUsersFiltersItem>
        {displayAccessLevel && (
          <StyledUsersFiltersItem>
            <FiltersDropdown
              label={USER.ACCESS_LEVEL}
              labelWidth={150}
              options={Object.values(this.availableAccesses(userType))}
              selected={accessLevel || ''}
              filterFunction={this.filterFunction('accessLevel')}
              disabled={!userType}
            />
          </StyledUsersFiltersItem>
        )}
      </StyledUsersFilters>
    );
  }
}

export { UsersFilters };
