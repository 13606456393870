import { createGlobalStyle } from 'styled-components';

import { ButtonStyles } from './ButtonStyles';
import { ColorVariables } from './ColorCssVariables';
import { FieldDateStyles } from './FieldDateStyles';
import { IconStyles } from './IconStyles';
import { PrintStyles } from './PrintStyles';
import { ScrollbarStyles } from './ScrollbarStyles';
import { TypographyStyles } from './TypographyStyles';

export const GlobalStyle = createGlobalStyle`
  ${ColorVariables}
  ${TypographyStyles}
  ${ScrollbarStyles()}
  ${PrintStyles}
  ${FieldDateStyles}
  ${IconStyles}
  ${ButtonStyles}

  * {
    scroll-behavior: unset !important;
  }

  mark {
    padding: 0.1em;
    background-color: var(--geyser);
  }

  body {
    background: var(--outerSpaceBrighter);
    font-family: 'Open Sans', sans-serif;
    margin: 0;
    min-width: 320px;
  }

  hr {
    border-color: var(--fiord);
  }

  a {
    color: rgba(255, 255, 255, 0.7);
    text-decoration: none;

    &:hover {
      color: var(--whiteGRG);
      text-decoration: none;
    }
  }

  .required-info {
    font-style: italic;
    font-size: 0.9em;
    color: var(--lynch);

    &-icon {
      color: var(--tangerine);
    }
  }

  .hidden {
    display: none;
  }

  *:focus {
    outline: none;
  }

  .balancePrice {
    display: inline-block;
    color: var(--solidRed);
  }

  input::-webkit-contacts-auto-fill-button {
    visibility: hidden;
    display: none;
    pointer-events: none;
    position: absolute;
    right: 0;
  }
`;
