import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getSummaryAvailableLotsActionType = 'GET_SUMMARY_AVAILABLE_LOTS';
const getSummaryAvailableLotsAction = () => ({
  type: getSummaryAvailableLotsActionType,
});

const getSummaryAvailableLotsSuccessActionType = `${getSummaryAvailableLotsActionType}_SUCCESS`;
const getSummaryAvailableLotsSuccessAction = lots => ({
  type: getSummaryAvailableLotsSuccessActionType,
  payload: {
    lots,
  },
});

const getSummaryAvailableLotsForCompanySuccessActionType = `${getSummaryAvailableLotsActionType}_FOR_COMPANY_SUCCESS`;
const getSummaryAvailableLotsForCompanySuccessAction = (lots, companyId) => ({
  type: getSummaryAvailableLotsForCompanySuccessActionType,
  payload: {
    [companyId]: [...lots],
  },
});

const getSummaryAvailableLotsFailureActionType = `${getSummaryAvailableLotsActionType}_FAILURE`;
const getSummaryAvailableLotsFailureAction = errorMessage => ({
  type: getSummaryAvailableLotsFailureActionType,
  payload: {
    errorMessage,
  },
});

const clearSummaryAvailableLotsActionType = 'CLEAR_SUMMARY_AVAILABLE_LOTS_ACTION';
const clearSummaryAvailableLots = () => ({
  type: clearSummaryAvailableLotsActionType,
});

const getSummaryAvailableLots =
  (status = 'summary-available-lots') =>
  dispatch => {
    dispatch(getSummaryAvailableLotsAction());

    return axios
      .get(`${API_HOST_AUTH}/pool-accounts/${status}`)
      .then(res => {
        dispatch(getSummaryAvailableLotsSuccessAction(res.data));

        return res.data;
      })
      .catch(err => Promise.reject(dispatch(getSummaryAvailableLotsFailureAction(err))));
  };

const getSummaryAvailableLotsForCompany = companyId => dispatch => {
  dispatch(getSummaryAvailableLotsAction());

  return axios
    .get(`${API_HOST_AUTH}/pool-accounts/ship-summary-accepted/${companyId}`)
    .then(res => {
      dispatch(getSummaryAvailableLotsForCompanySuccessAction(res.data, companyId));

      return res.data;
    })
    .catch(err => Promise.reject(dispatch(getSummaryAvailableLotsFailureAction(err))));
};

export {
  getSummaryAvailableLotsActionType,
  getSummaryAvailableLotsAction,
  getSummaryAvailableLotsSuccessActionType,
  getSummaryAvailableLotsSuccessAction,
  getSummaryAvailableLotsFailureActionType,
  getSummaryAvailableLotsFailureAction,
  getSummaryAvailableLots,
  clearSummaryAvailableLotsActionType,
  clearSummaryAvailableLots,
  getSummaryAvailableLotsForCompanySuccessAction,
  getSummaryAvailableLotsForCompanySuccessActionType,
  getSummaryAvailableLotsForCompany,
};
