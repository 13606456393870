import styled, { css } from 'styled-components';

import { cssVar } from 'shared/helpers/styling/styling';

export const ProgressStatuses = {
  EMPTY: 'graniteGray',
  PARTIALLY_FILLED: 'sycamore',
  FILLED: 'greenHouse',
};

export const StyledProgressCounter = styled.span`
  user-select: none;
  border-radius: 5px;
  padding: 4px;
  font-size: 0.75rem;
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 60px;
  color: ${cssVar('lightGrayBitLighter')};
  ${({ status }) =>
    status &&
    css`
      background: ${cssVar(status)};
    `}
`;
