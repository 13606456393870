import { setDesignation } from 'shared/helpers/dataParsers/lot/setDesignation';
import { formatDate } from 'shared/helpers/parsers/formaters';

import { history } from 'utils/history';

import { LOTS } from './lotsConstants';

const itemTemplate = [
  {
    label: LOTS.GRG_LOT_NUMBER,
    className: 'LotsIncoming-lotNumber',
    render: ({ grgLotNumber, designation }) => setDesignation(grgLotNumber, designation),
  },
  {
    label: LOTS.RECEIVED_ON,
    render: ({ receivedAt }) => formatDate(receivedAt),
  },
];

const actionsTemplate = [
  {
    icon: 'arrow-right',
    action: 'Details',
    onClick: lot => () => history.push(`/lots/incoming/${lot._id}`),
  },
];

const draggableActionsTemplate = [
  {
    icon: 'icon-eye',
    action: 'Details',
    onClick: lot => () => history.push(`/lots/incoming/${lot._id}`),
  },
];

export { itemTemplate, actionsTemplate, draggableActionsTemplate };
