import React from 'react';
import { Field } from 'redux-form';

import { FieldInput } from 'shared/components/Fields';
import { FieldSelect } from 'shared/components/Fields/FieldSelect/FieldSelect';
import { Section } from 'shared/components/Form';
import { ASSAY } from 'shared/helpers/constants/lots/assayConstants';
import { availableMaterialTypes } from 'shared/helpers/constants/lots/materialTypes';
import { mapArray } from 'shared/helpers/parsers/select';

import { renderFields } from 'pages/Lots/components/EnterAssayResults/EnterAssayResultsForm/components/helpers/renderFields';
import { calculateWeights as calculateAssayWeights } from 'pages/Lots/helpers/calculateWeights';

const Weights = ({ formValues, change, resetSection }) => {
  const calculateWeights = (e, val, prev, name) => {
    const values = { ...formValues, [name]: val };

    calculateAssayWeights(
      change,
      resetSection,
      name,
      values,
      'weightGrossReceived',
      'weightTareReceived',
      'weightNetWetReceived',
      'weightScrapReceived',
      'weightAdjustedNetWetReceived',
    );
  };

  const weightsConfig = [
    {
      component: FieldSelect,
      name: 'materialType',
      label: ASSAY.MATERIAL_TYPE,
      options: mapArray(availableMaterialTypes.all),
    },
    {
      name: 'percentMoisture',
      type: 'number',
      label: ASSAY.PERCENT_MOISTURE,
      suffix: '%',
    },
    {
      name: 'weightGrossReceived',
      type: 'number',
      label: ASSAY.WEIGHT_GROSS_RECEIVED,
      onChange: calculateWeights,
      suffix: 'lbs',
    },
    {
      name: 'weightTareReceived',
      type: 'number',
      label: ASSAY.WEIGHT_TARE_RECEIVED,
      onChange: calculateWeights,
      suffix: 'lbs',
    },
    {
      name: 'weightNetWetReceived',
      label: ASSAY.WEIGHT_NET_WET_RECEIVED,
      onChange: calculateWeights,
      suffix: 'lbs',
    },
    {
      name: 'weightScrapReceived',
      type: 'number',
      label: ASSAY.WEIGHT_SCRAP_RECEIVED,
      onChange: calculateWeights,
      suffix: 'lbs',
    },
    {
      name: 'weightAdjustedNetWetReceived',
      label: ASSAY.WEIGHT_ADJUSTED_NET_WET_RECEIVED,
      suffix: 'lbs',
    },
    {
      name: 'weightNetDryReceived',
      type: 'number',
      label: ASSAY.WEIGHT_NET_DRY_RECEIVED,
      suffix: 'lbs',
    },
    {
      name: 'carbonPercentage',
      type: 'number',
      label: ASSAY.CARBON_PERCENTAGE,
      suffix: '%',
    },
  ];

  const fields = renderFields(weightsConfig);

  return (
    <Section
      header={ASSAY.WEIGHTS}
      template={[
        'materialType percentMoisture',
        'weightGrossReceived weightTareReceived',
        'weightNetWetReceived weightScrapReceived',
        'weightAdjustedNetWetReceived weightNetDryReceived',
        'carbonPercentage .',
      ]}
    >
      {fields.map(field => (
        <Field component={FieldInput} field="required" key={field.name} {...field} />
      ))}
    </Section>
  );
};

export { Weights };
