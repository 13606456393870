const parseSummaryAvailableObject = lot => ({
  value: lot._id,
  label: lot.grgLotNumber,
  sentFromCompany: lot.sentFromCompany,
  grgLotNumber: lot.grgLotNumber,
});

const parseSummaryAvailableSelectData = lots => (lots ? lots.map(parseSummaryAvailableObject) : []);

export { parseSummaryAvailableSelectData };
