import dateAdapter from 'utils/date/dateAdapter';
import { MomentAdapter } from 'utils/date/momentAdapter';

const dateFormat = 'MM/DD/YYYY';
const datePickerFormat = 'MM/dd/yyyy';
const dateWithTimeFormat = 'MM/dd/yyyy hh:mm:ss A';
const dateWithTimeLuxonFormat = 'MM/DD/YYYY hh:mm:ss A';
const dateWithShortTimeFormat = 'MM/DD/YYYY hh:mm A';
const packageDateFormat = 'MMMM DD, YYYY  HH:mm';
const timePickerFormat = 'h:mm aa';

function dateToTimestamp(value) {
  if (value instanceof Date) {
    return MomentAdapter.normalize(value).valueOf();
  }

  if (value) {
    return dateAdapter(value).valueOf();
  }

  return null;
}

function dateWithTimeToTimestamp(date, time) {
  if (date instanceof Date) {
    return MomentAdapter.normalizeWithTime(date)
      .plus({
        hours: time.getHours(),
        minutes: time.getMinutes(),
      })
      .valueOf();
  }

  return null;
}

export {
  dateFormat,
  dateWithTimeFormat,
  dateWithShortTimeFormat,
  dateWithTimeLuxonFormat,
  packageDateFormat,
  dateToTimestamp,
  dateWithTimeToTimestamp,
  datePickerFormat,
  timePickerFormat,
};
