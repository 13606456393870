import PropTypes from 'prop-types';
import React from 'react';

import { parseFieldValue } from 'shared/components/Details/helpers/parseFieldValue';

import { DetailsField } from '../DetailsField';

const DetailsActivityField = ({ user, date, dateFormat, ...props }) => (
  <DetailsField {...props} value={parseFieldValue(user, date, dateFormat)} />
);

DetailsActivityField.propTypes = {
  label: PropTypes.string.isRequired,
  user: PropTypes.shape({
    _id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
  date: PropTypes.number,
  dateFormat: PropTypes.string,
  icon: PropTypes.string,
};

DetailsActivityField.defaultProps = {
  icon: 'icon-user',
};

export { DetailsActivityField };
