import PropTypes from 'prop-types';
import React from 'react';

import { ModalCustom } from 'components/ModalCustom/ModalCustom';

import { cssVar } from 'shared/helpers/styling/styling';

import { PreloaderInline } from '../PreloaderInline/PreloaderInline';
import {
  BarcodeScannerCore,
  scannerStates,
} from './components/BarcodeScannerCore/BarcodeScannerCore';

const BarcodeScanner = ({
  open,
  onSelfClose,
  title,
  availableCloseAction,
  progress,
  isPending = false,
  ...props
}) => (
  <ModalCustom
    title={title}
    isOpen={open}
    modalClose={onSelfClose}
    availableCloseAction={availableCloseAction}
    progress={progress}
    containerPadding={0}
    background={cssVar('outerSpaceTabs')}
  >
    <PreloaderInline loading={isPending} />
    <BarcodeScannerCore {...props} />
  </ModalCustom>
);

BarcodeScanner.propTypes = {
  open: PropTypes.bool.isRequired,
  scanningTimeSlice: PropTypes.number,
  stateResetTime: PropTypes.number,
  onSuccessfulScan: PropTypes.func,
  availableCloseAction: PropTypes.bool,
  onFailedScan: PropTypes.func,
  onSelfClose: PropTypes.func.isRequired,
  message: PropTypes.exact({
    success: PropTypes.string.isRequired,
    idle: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
    manualIdle: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  }).isRequired,
  idleStates: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      onChange: PropTypes.func,
      barcodeFormat: PropTypes.instanceOf(RegExp),
      barcodeLength: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.string)])
        .IsRequired,
      persistent: PropTypes.bool,
    }).isRequired,
  ).isRequired,
  progress: PropTypes.exact({
    current: PropTypes.number,
    steps: PropTypes.number,
  }),
  isPending: PropTypes.bool,
};

BarcodeScanner.defaultProps = {
  scanningTimeSlice: 300,
  stateResetTime: 3000,
};

export { BarcodeScanner, scannerStates };
