import { connect } from 'react-redux';

import { downloadApiAttachment } from 'actions/Attachments/downloadAttachment';
import { getTerms, clearTermsDetails } from 'actions/Terms/termsDetails';
import { showSnackbar } from 'actions/shared/snackbar';

import { TermsDetails } from './TermsDetails';

const mapStateToProps = ({ auth, router, termsDetails, downloadAttachmentStorage }) => ({
  auth,
  router,
  termsDetails,
  downloadAttachmentStorage,
});

const mapDispatchToProps = {
  getTerms,
  clearTermsDetails,
  downloadApiAttachment,
  showSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(TermsDetails);
