import styled from 'styled-components';

import { cssVar } from 'shared/helpers/styling/styling';

const StyledValue = styled('p')`
  color: ${cssVar('geyser')};
  font-size: 1.1rem;
  font-weight: 300;
  margin: 0 0 6px 16px;
`;

const StyledFieldsWrapper = styled('div')`
  display: flex;
  align-items: center;
`;

const StyledLineWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  margin-left: 12px;
`;

const StyledSingleLine = styled('p')`
  color: ${cssVar('geyser')};
  font-weight: 550;
  font-size: 1.2rem;
  margin: 0;
`;

export { StyledValue, StyledFieldsWrapper, StyledLineWrapper, StyledSingleLine };
