const agreementsBreadcrumbsRoutes = {
  '/agreements': null,
  '/agreements/:companyId': 'Agreements',
  '/agreements/:companyId/agreement': null,
  '/agreements/:companyId/agreement/:agreementId': 'Agreement details',
  '/agreements/:companyId/download': null,
  '/agreements/:companyId/download/agreements': null,
  '/agreements/:companyId/download/agreements/:id': null,
  '/agreements/:companyId/download/agreements/:id/:fileName': null,
};

export { agreementsBreadcrumbsRoutes };
