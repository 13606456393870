import { connect } from 'react-redux';

import { PrintLabel } from 'shared/components/PrintLabel/PrintLabel';
import { printLotLabel } from 'actions/Lots/printLotLabel';
import { showSnackbar } from 'actions/shared/snackbar';

const mapDispatchToProps = { printLabels: printLotLabel, showSnackbar };

const ConnectedPrintPackageLabel = connect(null, mapDispatchToProps)(PrintLabel);

export { ConnectedPrintPackageLabel as PrintPackageLabel };
