import {
  requestPaymentActionType,
  requestPaymentSuccessActionType,
  requestPaymentFailureActionType,
} from 'actions/Payments/requestPayment';

const INITIAL_STATE = {
  isPending: false,
};

const RequestPaymentReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case requestPaymentActionType:
      return {
        isPending: true,
      };
    case requestPaymentSuccessActionType:
    case requestPaymentFailureActionType:
      return {
        isPending: false,
      };
    default:
      return state;
  }
};

export { RequestPaymentReducer };
