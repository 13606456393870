import React from 'react';

import { FieldInput } from '../FieldInput/FieldInput';

const FieldHint = ({ hint, suggestion, ...props }) => (
  <FieldInput
    {...props}
    hint={Array.isArray(hint) ? hint : { label: hint, value: suggestion }}
    hintClickable
  />
);

export { FieldHint };
