import React from 'react';

import { DetailsContainer } from '../DetailsContainer';

export const renderDetailsContainer = (containerConfig, fieldsConfig, ...args) => (
  <DetailsContainer {...containerConfig(...args)}>
    {fieldsConfig(...args)
      .filter(data => data?.FieldType)
      .map(({ FieldType, ...rest }) => FieldType && <FieldType {...rest} key={rest.id} />)}
  </DetailsContainer>
);
