import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { deleteLot as deleteLotAction } from 'actions/Lots/deleteLot';
import { openModal } from 'actions/shared/modal';
import { showSnackbar } from 'actions/shared/snackbar';

import { deleteLotModal } from 'shared/helpers/constants/modalConstants';
import { snackbarOptions } from 'shared/helpers/matchers/snackbar/snackbarOptions';

function DeleteLot(props) {
  const deleteLot = () => {
    props.openModal(deleteLotModal, () => {
      props.deleteLot(props.lot._id).then(res => {
        props.showSnackbar(snackbarOptions(res.messageType), res.message);
        props.routeChange && props.routeChange();
      });
    });
  };

  return <React.Fragment>{props.children({ deleteLot })}</React.Fragment>;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      openModal,
      showSnackbar,
      deleteLot: deleteLotAction,
    },
    dispatch,
  );
}

const ConnectedDeleteLot = connect(null, mapDispatchToProps)(DeleteLot);

export { ConnectedDeleteLot as DeleteLot, DeleteLot as DeleteLotModal };
