import { isAssayCompany, isInternalCompany } from '../checkCompanyType';
import { isGlobalRefiningGroup } from '../isGlobalRefiningGroup';

function checkSentFromCompany(company) {
  const { companyType, companyName } = company;

  return (
    (isAssayCompany(companyType) || isInternalCompany(companyType)) &&
    !isGlobalRefiningGroup(companyName)
  );
}

export { checkSentFromCompany };
