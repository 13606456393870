import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getPaymentAmountActionType = 'GET_PAYMENT_AMOUNT';
const getPaymentAmountAction = () => ({
  type: getPaymentAmountActionType,
});

const getPaymentAmountSuccessActionType = `${getPaymentAmountActionType}_SUCCESS`;
const getPaymentAmountSuccessAction = data => ({
  type: getPaymentAmountSuccessActionType,
  payload: {
    data,
  },
});

const getPaymentAmountFailureActionType = `${getPaymentAmountActionType}_FAILURE`;
const getPaymentAmountFailureAction = () => ({
  type: getPaymentAmountFailureActionType,
});

const clearPaymentAmountActionType = 'CLEAR_PAYMENT_AMOUNT';
const clearPaymentAmount = () => ({
  type: clearPaymentAmountActionType,
});

const getPaymentAmount = lotId => dispatch => {
  dispatch(getPaymentAmountAction());

  return axios
    .get(`${API_HOST_AUTH}/payments/payment-amount/${lotId}`)
    .then(res => {
      dispatch(getPaymentAmountSuccessAction(res.data));

      return res.data;
    })
    .catch(() => Promise.reject(dispatch(getPaymentAmountFailureAction())));
};

export {
  getPaymentAmountActionType,
  getPaymentAmountAction,
  getPaymentAmountSuccessActionType,
  getPaymentAmountSuccessAction,
  getPaymentAmountFailureActionType,
  getPaymentAmountFailureAction,
  clearPaymentAmountActionType,
  clearPaymentAmount,
  getPaymentAmount,
};
