import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';

import { Section } from 'shared/components/Form';
import { FieldTextarea } from 'shared/components/Formik/Fields';
import { SubmitButton, CancelButton } from 'shared/components/Buttons';
import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';
import { SHARED } from 'shared/helpers/constants/sharedConstants';
import { StyledSubmitContainer } from './StyledNoteFormBase';

const NoteFormBase = ({
  closeModal,
  handleSubmitForm,
  isPending,
  noteDetails,
  validationSchema,
  maxLength,
}) => {
  useEffect(() => {
    validateForm();
  }, []);

  const {
    validateForm,
    values,
    isValid,
    touched,
    isSubmitting,
    handleSubmit,
    handleChange,
    handleBlur,
    getFieldMeta,
    setFieldTouched,
  } = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      note: noteDetails,
    },
    onSubmit: values => handleSubmitForm(values),
  });

  const getErrors = name => Object.keys(touched).includes(name) && getFieldMeta(name).error;

  return (
    <form onSubmit={handleSubmit}>
      <Section template={['note note']}>
        <FieldTextarea
          name="note"
          type="textarea"
          label={LOTS.NOTE}
          value={values.note}
          field="required"
          onChange={handleChange}
          onBlur={handleBlur}
          error={getErrors('note')}
          onInput={() => setFieldTouched('note')}
          maxLength={maxLength}
        />
      </Section>
      <StyledSubmitContainer>
        <CancelButton onClick={closeModal} data-testid="noteCancelButton" />
        <SubmitButton
          label={SHARED.SAVE_BUTTON}
          disabled={!isValid || isSubmitting || isPending}
          data-testid="noteSubmitButton"
        />
      </StyledSubmitContainer>
    </form>
  );
};

NoteFormBase.propTypes = {
  closeModal: PropTypes.func.isRequired,
  handleSubmitForm: PropTypes.func.isRequired,
  isPending: PropTypes.bool.isRequired,
  noteDetails: PropTypes.string,
  validationSchema: PropTypes.object.isRequired,
  maxLength: PropTypes.number,
};

export { NoteFormBase };
