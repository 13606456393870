import React from 'react';

import { OverviewTable } from 'shared/components/OverviewList/OverviewTable/OverviewTable';
import { OverviewTableMobile } from 'shared/components/OverviewList/OverviewTableMobile/OverviewTableMobile';
import { POOL_ACCOUNTS } from 'shared/helpers/constants/poolAccounts/poolAccountsConstants';
import { isPhone } from 'shared/helpers/constants/resolutionsConstants';
import { formatToUS } from 'shared/helpers/parsers/formaters';
import { humanize } from 'shared/helpers/parsers/text';
import { useWindowWidth } from 'shared/hooks/useWindowWidth';

const itemsTemplate = [
  {
    label: POOL_ACCOUNTS.COMMODITY,
    render: ({ _id }) => humanize(_id),
  },
  {
    label: POOL_ACCOUNTS.LOT,
    render: ({ lots }) => lots.map(lot => <div key={lot.grgLotNumber}>{lot.grgLotNumber}</div>),
  },
  {
    label: POOL_ACCOUNTS.TROY_OUNCES,
    render: ({ lots }) =>
      lots.map(lot => <div key={lot.grgLotNumber}>{formatToUS(lot.ounces, 3)}</div>),
  },
  {
    label: POOL_ACCOUNTS.TOTAL,
    render: ({ total }) => `${formatToUS(total, 3)} ${POOL_ACCOUNTS.OZ}`,
  },
];

const itemsTemplateMobile = [
  {
    label: POOL_ACCOUNTS.COMMODITY,
    render: ({ _id }) => humanize(_id),
  },
  {
    label: POOL_ACCOUNTS.TOTAL,
    render: ({ total }) => `${formatToUS(total, 3)} ${POOL_ACCOUNTS.OZ}`,
  },
];

const itemDetailsTemplate = [
  {
    label: POOL_ACCOUNTS.LOT,
    render: ({ lots }) => lots.map(lot => <div key={lot.grgLotNumber}>{lot.grgLotNumber}</div>),
  },
  {
    label: POOL_ACCOUNTS.TROY_OUNCES,
    render: ({ lots }) =>
      lots.map(lot => <div key={lot.grgLotNumber}>{formatToUS(lot.ounces, 3)}</div>),
  },
];

const PoolAccountDetailsList = ({ poolAccount }) =>
  useWindowWidth() > isPhone ? (
    <OverviewTable
      actionsTemplate={[]}
      itemsTemplate={itemsTemplate}
      items={poolAccount.poolEntries}
      detailsList
    />
  ) : (
    <OverviewTableMobile
      itemsTemplate={[]}
      actionsTemplate={[]}
      itemsTemplateMobile={itemsTemplateMobile}
      itemDetailsTemplate={itemDetailsTemplate}
      items={poolAccount.poolEntries}
    />
  );

export { PoolAccountDetailsList };
