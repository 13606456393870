import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getPaymentDetailsActionType = 'GET_PAYMENT_DETAILS';
const getPaymentDetailsAction = () => ({
  type: getPaymentDetailsActionType,
});

const getPaymentDetailsSuccessActionType = `${getPaymentDetailsActionType}_SUCCESS`;
const getPaymentDetailsSuccessAction = payment => ({
  type: getPaymentDetailsSuccessActionType,
  payload: {
    payment,
  },
});

const getPaymentDetailsFailureActionType = `${getPaymentDetailsActionType}_FAILURE`;
const getPaymentDetailsFailureAction = errorMessage => ({
  type: getPaymentDetailsFailureActionType,
  payload: {
    errorMessage,
  },
});

const clearPaymentDetailsActionType = 'CLEAR_PAYMENT_DETAILS_ACTION';
const clearPaymentDetails = () => ({
  type: clearPaymentDetailsActionType,
});

const getPayment = paymentId => dispatch => {
  dispatch(getPaymentDetailsAction());

  return axios
    .get(`${API_HOST_AUTH}/payments/${paymentId}`)
    .then(res => {
      dispatch(getPaymentDetailsSuccessAction(res.data));

      return res.data;
    })
    .catch(err => {
      dispatch(getPaymentDetailsFailureAction(err));
      return Promise.reject(err.data);
    });
};

export {
  getPaymentDetailsActionType,
  getPaymentDetailsAction,
  getPaymentDetailsSuccessActionType,
  getPaymentDetailsSuccessAction,
  getPaymentDetailsFailureActionType,
  getPaymentDetailsFailureAction,
  clearPaymentDetailsActionType,
  clearPaymentDetails,
  getPayment,
};
