import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { Authorization } from 'components/Authorization/Authorization';
import { NotFound } from 'components/ErrorPage/ErrorPage';

import { ConnectedCreateHedgesNote } from 'shared/components/Notes/CreateNotes/ConnectedCreateNote';

import { ConnectedDefaultPPMs } from 'pages/Hedges/components/DefaultPPMs/ConnectedDefaultPPMs';

import { CreateDeliveries } from '../Deliveries/CreateDeliveries/CreateDeliveries';
import { TransferOuncesWizard } from '../Deliveries/TransferOuncesWizard/TransferOuncesWizard';
import { EnterCustomPrices } from './components/EnterPrices/EnterCustomPrices';
import { EnterPrices } from './components/EnterPrices/EnterPrices';
import { EnterSettledOunces } from './components/EnterSettledOunces/EnterSettledOunces';
import { HedgeOunces } from './components/HedgeOunces';
import { UpdateHedgeOunces } from './components/HedgeOunces/components/SpecialPermissions/UpdateHedgeOunces/UpdateHedgeOunces';
import { ConnectedHedgesDetails } from './components/HedgesDetails/ConnectedHedgesDetails';
import { EnterPlacedHedges } from './components/HedgesManagement/components/EnterPlacedHedges/EnterPlacedHedges';
import { ConnectedHedgesCreate } from './components/HedgesManagement/components/HedgesCreate/ConnectedHedgesCreate';
import { HedgesOverview } from './components/HedgesOverview/HedgesOverview';
import { ConnectedMarketHolidays } from './components/MarketHolidays/ConnectedMarketHolidays';
import { ConnectedMarketHolidaysCreate } from './components/MarketHolidays/components/MarketHolidaysManagement/MarketHolidaysCreate/ConnectedMarketHolidaysCreate';
import { ConnectedRecipients } from './components/Recipients/ConnectedRecipients';
import { ConnectedRecipientsCreate } from './components/Recipients/components/RecipientsManagement/RecipientsCreate/ConnectedRecipientsCreate';
import { ConnectedTrustedCompanies } from './components/TrustedCompanies/ConnectedTrustedCompanies';
import { ConnectedTrustedCompaniesCreate } from './components/TrustedCompanies/components/TrustedCompaniesManagement/TrustedCompaniesCreate/ConnectedTrustedCompaniesCreate';

function Hedges(props) {
  const { hedges, specialPermissions, poolAccounts } = props.auth.access;

  const AuthHedgesOverview = Authorization(HedgesOverview, hedges.overview);
  const AuthHedgesCreateOverview = Authorization(ConnectedHedgesCreate, hedges.create);
  const AuthEnterPlacedHedges = Authorization(EnterPlacedHedges, hedges.enterPlaced);
  const AuthHedgesDetails = Authorization(ConnectedHedgesDetails, hedges.details);
  const AuthMarketHolidays = Authorization(ConnectedMarketHolidays, hedges.marketHolidays.list);
  const AuthRecipients = Authorization(ConnectedRecipients, hedges.recipients.list);
  const AuthRecipientsCreate = Authorization(ConnectedRecipientsCreate, hedges.recipients.list);
  const AuthMarketHolidaysCreate = Authorization(
    ConnectedMarketHolidaysCreate,
    hedges.marketHolidays.manage,
  );
  const AuthTrustedCompanies = Authorization(
    ConnectedTrustedCompanies,
    hedges.trustedCompanies.list,
  );
  const AuthDefaultPPMs = Authorization(ConnectedDefaultPPMs, hedges.defaultPPMs);
  const AuthTrustedCompaniesCreate = Authorization(
    ConnectedTrustedCompaniesCreate,
    hedges.trustedCompanies.manage,
  );
  const AuthEnterPrices = Authorization(EnterPrices, hedges.enterPrices);
  const AuthUpdatePrices = Authorization(EnterPrices, specialPermissions.hedges.changeHedgePrices);
  const AuthEnterCustomPrices = Authorization(EnterCustomPrices, hedges.enterCustomPrices);
  const AuthUpdateCustomPrices = Authorization(
    EnterCustomPrices,
    specialPermissions.hedges.changeHedgePrices,
  );
  const AuthCreateDeliveries = Authorization(CreateDeliveries, hedges.assignHedgeOunces);
  const AuthTransferOuncesWizard = Authorization(
    TransferOuncesWizard,
    specialPermissions.hedges.transferBlanketHedgeOunces,
  );
  const AuthCreateHedgesNote = Authorization(ConnectedCreateHedgesNote, hedges.notesPanel);
  const AuthHedgeOunces = Authorization(HedgeOunces, hedges.hedgeLots);
  const AuthUpdateHedgeOunces = Authorization(
    UpdateHedgeOunces,
    specialPermissions.hedges.changeHedgeOunces,
  );
  const AuthEnterSettledOunces = Authorization(EnterSettledOunces, poolAccounts.enterSettledOunces);

  return (
    <Switch>
      <Route exact path="/hedges" component={AuthHedgesOverview} />
      <Route exact path="/hedges/create" component={AuthHedgesCreateOverview} />
      <Route exact path="/hedges/enter-placed" component={AuthEnterPlacedHedges} />
      <Route exact path="/hedges/enter-settled-ounces" component={AuthEnterSettledOunces} />
      <Route exact path="/hedges/market-holidays" component={AuthMarketHolidays} />
      <Route exact path="/hedges/market-holidays/create" component={AuthMarketHolidaysCreate} />
      <Route exact path="/hedges/trusted-companies" component={AuthTrustedCompanies} />
      <Route exact path="/hedges/trusted-companies/create" component={AuthTrustedCompaniesCreate} />
      <Route exact path="/hedges/recipients" component={AuthRecipients} />
      <Route exact path="/hedges/recipients/create" component={AuthRecipientsCreate} />
      <Route exact path="/hedges/assign-ounces" component={AuthCreateDeliveries} />
      <Route exact path="/hedges/transfer-ounces/:hedgeId" component={AuthTransferOuncesWizard} />
      <Route exact path="/hedges/default-ppms" component={AuthDefaultPPMs} />
      <Route exact path="/hedges/hedge-ounces" component={AuthHedgeOunces} />
      <Route exact path="/hedges/:id" component={AuthHedgesDetails} />
      <Route exact path="/hedges/:id/create-note" component={AuthCreateHedgesNote} />
      <Route exact path="/hedges/:id/prices" component={AuthEnterPrices} />
      <Route exact path="/hedges/:id/ounces/update" component={AuthUpdateHedgeOunces} />
      <Route
        exact
        path="/hedges/:id/prices/update"
        component={props => <AuthUpdatePrices {...props} superAdminMode />}
      />
      <Route exact path="/hedges/:id/custom-prices" component={AuthEnterCustomPrices} />
      <Route
        exact
        path="/hedges/:id/custom-prices/update"
        component={props => <AuthUpdateCustomPrices {...props} superAdminMode />}
      />
      <Route exact path="/hedges/download/:id/:fileName" component={AuthHedgesOverview} />
      <Route component={NotFound} />
    </Switch>
  );
}

export { Hedges };
