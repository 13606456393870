import {
  getPaymentAmountActionType,
  getPaymentAmountSuccessActionType,
  getPaymentAmountFailureActionType,
  clearPaymentAmountActionType,
} from 'actions/Payments/getPaymentAmount';

const INITIAL_STATE = {
  isPending: false,
  data: null,
};

const PaymentAmountReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getPaymentAmountActionType:
      return {
        ...state,
        isPending: true,
      };
    case getPaymentAmountSuccessActionType:
      return {
        data: action.payload.data,
        isPending: false,
      };
    case getPaymentAmountFailureActionType:
      return {
        ...state,
        isPending: false,
      };
    case clearPaymentAmountActionType:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};

export { PaymentAmountReducer };
