import styled from 'styled-components';

import { media } from 'shared/helpers/styling/styling';

export const StyledPagination = styled.div`
  display: flex;
  margin-top: 20px;

  ${media.phone`
  padding-right: 15px;
  margin-left: 0;
`}
  p {
    font-size: 0.9em;
    font-weight: bold;
    font-family: 'Open Sans', sans-serif;
    color: var(--lynch);
    margin-bottom: 5px;
    text-align: center;
  }
`;
export const StyledPaginationPages = styled.div`
  margin: auto;
  color: var(--gullGray);

  @media print {
    display: none;
  }
`;
