const GENERATE_FINAL_INVOICE = {
  FINAL_PAYMENT_DATE_EMPTY: 'Final payment date cannot be empty.',
  FINAL_PLATINUM_PRICE_EMPTY: 'Final platinum price cannot be empty.',
  FINAL_PLATINUM_PRICE_INVALID: 'Final platinum price must have a valid format.',
  FINAL_PLATINUM_PRICE_NEGATIVE: 'Final platinum price cannot be less than or equal 0.',
  FINAL_PALLADIUM_PRICE_EMPTY: 'Final palladium price cannot be empty.',
  FINAL_PALLADIUM_PRICE_INVALID: 'Final palladium price must have a valid format.',
  FINAL_PALLADIUM_PRICE_NEGATIVE: 'Final palladium price cannot be less than or equal 0.',
  FINAL_RHODIUM_PRICE_EMPTY: 'Final rhodium price cannot be empty.',
  FINAL_RHODIUM_PRICE_INVALID: 'Final rhodium price must have a valid format.',
  FINAL_RHODIUM_PRICE_NEGATIVE: 'Final rhodium price cannot be less than or equal 0.',
  PLATINUM_OUNCES_REMOVED_EMPTY: 'Platinum ounces removed cannot be empty.',
  PLATINUM_OUNCES_REMOVED_INVALID: 'Platinum ounces removed must have a valid format.',
  PLATINUM_OUNCES_REMOVED_NEGATIVE: 'Platinum ounces removed cannot be less than 0.',
  PLATINUM_OUNCES_REMOVED_TOO_HIGH: 'Platinum ounces removed cannot be more than maximum.',
  PALLADIUM_OUNCES_REMOVED_EMPTY: 'Palladium ounces removed cannot be empty.',
  PALLADIUM_OUNCES_REMOVED_INVALID: 'Palladium ounces removed must have a valid format.',
  PALLADIUM_OUNCES_REMOVED_NEGATIVE: 'Palladium ounces removed cannot be less than 0.',
  PALLADIUM_OUNCES_REMOVED_TOO_HIGH: 'Palladium ounces removed cannot be more than maximum.',
  RHODIUM_OUNCES_REMOVED_EMPTY: 'Rhodium ounces removed cannot be empty.',
  RHODIUM_OUNCES_REMOVED_INVALID: 'Rhodium ounces removed must have a valid format.',
  RHODIUM_OUNCES_REMOVED_NEGATIVE: 'Rhodium ounces removed cannot be less than 0.',
  RHODIUM_OUNCES_REMOVED_TOO_HIGH: 'Rhodium ounces removed cannot be more than maximum.',
  OUTSTANDING_BALANCE_APPLIED_EMPTY: 'Outstanding balance applied cannot be empty.',
  OUTSTANDING_BALANCE_APPLIED_INVALID: 'Outstanding balance applied must have a valid format.',
  OUTSTANDING_BALANCE_APPLIED_NEGATIVE: 'Outstanding balance applied cannot be less than 0.',
  OUTSTANDING_BALANCE_APPLIED_TOO_HIGH:
    'Outstanding balance applied cannot be more than the company balance.',
  TOTAL_FINAL_PAYMENT_EMPTY: 'Total final payment cannot be empty.',
  TOTAL_FINAL_PAYMENT_INVALID: 'Total final payment must have a valid format.',
};

export { GENERATE_FINAL_INVOICE };
