import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const deleteMarketHolidaysActionType = 'DELETE_MARKET_HOLIDAYS';
function deleteMarketHolidaysAction() {
  return { type: deleteMarketHolidaysActionType };
}

const deleteMarketHolidaysSuccessActionType = `${deleteMarketHolidaysActionType}_SUCCESS`;
function deleteMarketHolidaysSuccessAction() {
  return { type: deleteMarketHolidaysSuccessActionType };
}

const deleteMarketHolidaysFailureActionType = `${deleteMarketHolidaysActionType}_FAILURE`;
function deleteMarketHolidaysFailureAction() {
  return { type: deleteMarketHolidaysFailureActionType };
}

function deleteMarketHolidays(marketHoliday) {
  return dispatch => {
    dispatch(deleteMarketHolidaysAction());

    return axios({
      method: 'delete',
      url: `${API_HOST_AUTH}/market-holidays`,
      data: JSON.stringify({ id: marketHoliday._id }),
    })
      .then(() => dispatch(deleteMarketHolidaysSuccessAction()))
      .catch(error => {
        dispatch(deleteMarketHolidaysFailureAction());

        return Promise.reject(error);
      });
  };
}

export {
  deleteMarketHolidays,
  deleteMarketHolidaysActionType,
  deleteMarketHolidaysSuccessActionType,
  deleteMarketHolidaysFailureActionType,
};
