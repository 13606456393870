import { ENTER_PRICES } from 'shared/helpers/constants/hedges/enterPricesConstants';
import { safeParseFloat } from 'shared/helpers/parsers/number';
import { replaceEmpty } from 'shared/helpers/parsers/text';
import { commaValidator } from 'shared/helpers/validations/commaValidator';
import { PRICE_REGEX } from 'shared/helpers/validations/regularExpressions';

import { priceType } from 'pages/Hedges/components/EnterPrices/EnterPricesForm/EnterPricesForm';

const validate = (
  {
    platinumPriceActual,
    palladiumPriceActual,
    rhodiumPriceActual,
    platinumPriceCust,
    palladiumPriceCust,
    rhodiumPriceCust,
  },
  { type, hedge },
) => {
  let platinumPriceActualError = null;
  let palladiumPriceActualError = null;
  let rhodiumPriceActualError = null;
  let platinumPriceCustError = null;
  let palladiumPriceCustError = null;
  let rhodiumPriceCustError = null;
  let error = {};

  commaValidator(
    {
      platinumPriceActual,
      palladiumPriceActual,
      rhodiumPriceActual,
      platinumPriceCust,
      palladiumPriceCust,
      rhodiumPriceCust,
    },
    error,
  );

  if (hedge.platinumOz !== 0) {
    if (!replaceEmpty(platinumPriceActual, '').length) {
      platinumPriceActualError = ENTER_PRICES.PLATINUM_PRICE_ACTUAL_EMPTY;
    } else if (
      !PRICE_REGEX.test(platinumPriceActual) ||
      (platinumPriceActual && platinumPriceActual.length > 10)
    ) {
      platinumPriceActualError =
        error.platinumPriceActual || ENTER_PRICES.PLATINUM_PRICE_ACTUAL_INVALID;
    } else if (safeParseFloat(platinumPriceActual) < 0) {
      platinumPriceActualError = ENTER_PRICES.PLATINUM_PRICE_ACTUAL_NEGATIVE;
    }

    if (!replaceEmpty(platinumPriceCust, '').length) {
      platinumPriceCustError = ENTER_PRICES.PLATINUM_PRICE_CUST_EMPTY;
    } else if (
      !PRICE_REGEX.test(platinumPriceCust) ||
      (platinumPriceCust && platinumPriceCust.length > 10)
    ) {
      platinumPriceCustError = error.platinumPriceCust || ENTER_PRICES.PLATINUM_PRICE_CUST_INVALID;
    } else if (safeParseFloat(platinumPriceCust) < 0) {
      platinumPriceCustError = ENTER_PRICES.PLATINUM_PRICE_CUST_NEGATIVE;
    }
  }

  if (hedge.palladiumOz !== 0) {
    if (!replaceEmpty(palladiumPriceActual, '').length) {
      palladiumPriceActualError = ENTER_PRICES.PALLADIUM_PRICE_ACTUAL_EMPTY;
    } else if (
      !PRICE_REGEX.test(palladiumPriceActual) ||
      (palladiumPriceActual && palladiumPriceActual.length > 10)
    ) {
      palladiumPriceActualError =
        error.palladiumPriceActual || ENTER_PRICES.PALLADIUM_PRICE_ACTUAL_INVALID;
    } else if (safeParseFloat(palladiumPriceActual) < 0) {
      palladiumPriceActualError = ENTER_PRICES.PALLADIUM_PRICE_ACTUAL_NEGATIVE;
    }

    if (!replaceEmpty(palladiumPriceCust, '').length) {
      palladiumPriceCustError = ENTER_PRICES.PALLADIUM_PRICE_CUST_EMPTY;
    } else if (
      !PRICE_REGEX.test(palladiumPriceCust) ||
      (palladiumPriceCust && palladiumPriceCust.length > 10)
    ) {
      palladiumPriceCustError =
        error.palladiumPriceCust || ENTER_PRICES.PALLADIUM_PRICE_CUST_INVALID;
    } else if (safeParseFloat(palladiumPriceCust) < 0) {
      palladiumPriceCustError = ENTER_PRICES.PALLADIUM_PRICE_CUST_NEGATIVE;
    }
  }

  if (hedge.rhodiumOz !== 0) {
    if (!replaceEmpty(rhodiumPriceActual, '').length) {
      rhodiumPriceActualError = ENTER_PRICES.RHODIUM_PRICE_ACTUAL_EMPTY;
    } else if (
      !PRICE_REGEX.test(rhodiumPriceActual) ||
      (rhodiumPriceActual && rhodiumPriceActual.length > 10)
    ) {
      rhodiumPriceActualError =
        error.rhodiumPriceActual || ENTER_PRICES.RHODIUM_PRICE_ACTUAL_INVALID;
    } else if (safeParseFloat(rhodiumPriceActual) < 0) {
      rhodiumPriceActualError = ENTER_PRICES.RHODIUM_PRICE_ACTUAL_NEGATIVE;
    }

    if (!replaceEmpty(rhodiumPriceCust, '').length) {
      rhodiumPriceCustError = ENTER_PRICES.RHODIUM_PRICE_CUST_EMPTY;
    } else if (
      !PRICE_REGEX.test(rhodiumPriceCust) ||
      (rhodiumPriceCust && rhodiumPriceCust.length > 10)
    ) {
      rhodiumPriceCustError = error.rhodiumPriceCust || ENTER_PRICES.RHODIUM_PRICE_CUST_INVALID;
    } else if (safeParseFloat(rhodiumPriceCust) < 0) {
      rhodiumPriceCustError = ENTER_PRICES.RHODIUM_PRICE_CUST_NEGATIVE;
    }
  }

  return type === priceType.actual
    ? {
        platinumPriceActual: platinumPriceActualError,
        palladiumPriceActual: palladiumPriceActualError,
        rhodiumPriceActual: rhodiumPriceActualError,
      }
    : {
        platinumPriceCust: platinumPriceCustError,
        palladiumPriceCust: palladiumPriceCustError,
        rhodiumPriceCust: rhodiumPriceCustError,
      };
};

export { validate };
