import PropTypes from 'prop-types';
import React, { useLayoutEffect } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';

import { Icon } from 'shared/components/Icon/Icon';
import { StepIndicator } from 'shared/components/StepIndicator/StepIndicator';
import { SHARED } from 'shared/helpers/constants/sharedConstants';
import {
  cssVar,
  pxToRem,
  pixelify,
  scrollbar,
  media,
  isMobile,
} from 'shared/helpers/styling/styling';

const CloseContainer = styled.div`
  color: ${cssVar('whiteGRG')};
  font-size: ${pxToRem(12)};
  display: flex;
  align-items: baseline;
  justify-content: flex-end;

  > div:first-child {
    margin: 0 45px;
  }
  i {
    margin-left: 14px;
  }
`;

const CloseButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 10px 50px 22px 0;
`;

const ModalContainer = styled.div`
  background-color: ${({ background }) => background || cssVar('outerSpace')};
  padding: ${({ containerPadding }) => containerPadding};
  overflow-y: auto;
  ${scrollbar}
`;

const ModalTitleBar = styled.div`
  color: ${cssVar('alabaster')};
  background-color: ${cssVar('limedSpruceCalendar')};
  font-size: ${pxToRem(14)};
  padding: 20px 50px;
`;

function ModalCustomLayout({ progress, isOpen, ...props }) {
  useLayoutEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'auto';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  const modalCloseCallback =
    props.availableCloseAction && !props.holdOnOutsideClick ? props.modalClose : () => {};

  return (
    <Modal
      isOpen={isOpen}
      className={props.modalClassName}
      portalClassName={props.className}
      overlayClassName={props.overlayClassName}
      onRequestClose={modalCloseCallback}
      appElement={document.getElementById('app')}
    >
      {props.availableCloseAction && (
        <CloseContainer>
          {progress && <StepIndicator {...progress} />}
          <CloseButton onClick={props.modalClose}>
            {SHARED.CLOSE}
            <Icon icon="icon-cancel" />
          </CloseButton>
        </CloseContainer>
      )}
      <ModalContainer containerPadding={props.containerPadding} background={props.background}>
        {props.title && <ModalTitleBar>{props.title}</ModalTitleBar>}
        {props.children}
      </ModalContainer>
    </Modal>
  );
}

const ModalCustom = styled(ModalCustomLayout).attrs({
  overlayClassName: 'Overlay',
  modalClassName: 'Modal',
})`
  .Overlay {
    background-color: rgba(0, 0, 0, 0.85);
    position: fixed;
    top: 0;
    height: calc(100% + 20px);
    width: 100vw;
    z-index: ${({ zIndex }) => zIndex};
  }

  .Modal {
    display: flex;
    flex-direction: column;
    position: ${isMobile() ? 'fixed' : 'absolute'};
    height: fit-content;
    margin: auto;
    overflow: hidden;
    max-width: ${({ maxWidth }) => pixelify(maxWidth)};
    max-height: ${isMobile() ? '50%' : 'calc(100% - 50px)'};
    padding-bottom: ${isMobile() ? 50 : 0}px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    ${media.phone`
      padding-bottom: 55px;
    `}

    &:focus {
      outline: none;
    }
  }
`;

ModalCustom.propTypes = {
  title: PropTypes.string,
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  containerPadding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  background: PropTypes.string,
  holdOnOutsideClick: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  modalClose: PropTypes.func.isRequired,
  availableCloseAction: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  zIndex: PropTypes.number,
};

ModalCustom.defaultProps = {
  maxWidth: 540,
  containerPadding: '20px',
  availableCloseAction: true,
  zIndex: 1001,
};

export { ModalCustom };
