import PropTypes from 'prop-types';
import React from 'react';

import { capitalizeGlobal, humanize, capitalizeNotAvailable } from 'shared/helpers/parsers/text';

import { DetailsField } from '../DetailsField';

const formatValue = value => capitalizeGlobal(humanize(capitalizeNotAvailable(value)));

const DetailsHumanizedField = ({ value, ...props }) => (
  <DetailsField value={formatValue(value)} {...props} />
);

DetailsHumanizedField.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  value: PropTypes.string,
};

export { DetailsHumanizedField };
