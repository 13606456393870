import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getAssayResultsDetailsActionType = 'GET_ASSAY_RESULTS_DETAILS';
const getAssayResultsDetailsAction = () => ({
  type: getAssayResultsDetailsActionType,
});

const getAssayResultsDetailsSuccessActionType = `${getAssayResultsDetailsActionType}_SUCCESS`;
const getAssayResultsDetailsSuccessAction = assayResults => ({
  type: getAssayResultsDetailsSuccessActionType,
  payload: {
    assayResults,
  },
});

const getAssayResultsDetailsFailureActionType = `${getAssayResultsDetailsActionType}_FAILURE`;
const getAssayResultsDetailsFailureAction = () => ({
  type: getAssayResultsDetailsFailureActionType,
});

const clearAssayResultsDetailsActionType = 'CLEAR_ASSAY_RESULTS_DETAILS_ACTION';
const clearAssayResultsDetails = () => ({
  type: clearAssayResultsDetailsActionType,
});

const getAssayResultsDetails = assayId => dispatch => {
  dispatch(getAssayResultsDetailsAction());

  return axios
    .get(`${API_HOST_AUTH}/assay/${assayId}`)
    .then(res => {
      dispatch(getAssayResultsDetailsSuccessAction(res.data));

      return res.data;
    })
    .catch(error => {
      dispatch(getAssayResultsDetailsFailureAction());
      return Promise.reject(error.data);
    });
};

export {
  getAssayResultsDetailsActionType,
  getAssayResultsDetailsAction,
  getAssayResultsDetailsSuccessActionType,
  getAssayResultsDetailsSuccessAction,
  getAssayResultsDetailsFailureActionType,
  getAssayResultsDetailsFailureAction,
  clearAssayResultsDetailsActionType,
  clearAssayResultsDetails,
  getAssayResultsDetails,
};
