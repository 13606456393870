const paymentStatuses = {
  submitted: 'submitted',
  approved: 'approved',
  declined: 'declined',
  paid: 'paid',
  draft: 'draft',
};

const deletableStatuses = [
  paymentStatuses.submitted,
  paymentStatuses.approved,
  paymentStatuses.declined,
  paymentStatuses.draft,
];

const customerDeletableStatuses = [paymentStatuses.draft, paymentStatuses.submitted];

export { paymentStatuses, deletableStatuses, customerDeletableStatuses };
