import {
  getLotPaymentsType,
  getLotPaymentsFailureType,
  getLotPaymentsSuccessType,
  clearLotPaymentsType,
} from 'actions/Payments/getLotPayments';

const INITIAL_STATE = {
  isPending: false,
  payments: null,
};

const GetLotPaymentsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getLotPaymentsType:
      return {
        ...state,
        isPending: true,
      };
    case getLotPaymentsSuccessType:
      return {
        isPending: false,
        payments: action.payload.payments,
      };
    case clearLotPaymentsType:
    case getLotPaymentsFailureType:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};

export { GetLotPaymentsReducer };
