const INSPECTIONS = {
  RELATED_LOT: 'Related lot',
  REQUEST_LOT_INSPECTION: 'Request lot inspection',
  SCHEDULE_LOT_INSPECTION: 'Schedule lot inspection',
  INSPECTION_SCHEDULED_FOR: 'Scheduled for',
  PLACEHOLDERS: {
    SELECT_RELATED_LOT: '-- select related lot --',
  },
  ERROR: {
    REQUEST_LOT_INSPECTION_ERROR: 'Lot inspection has not been requested.',
    SCHEDULE_LOT_INSPECTION_ERROR: 'Lot inspection has not been scheduled.',
  },
  INVALID_FORM_VALUES: {
    RELATED_LOT_EMPTY: 'Related lot cannot be empty.',
    INSPECTION_SCHEDULED_FOR_EMPTY: 'Scheduled for cannot be empty.',
  },
};

export { INSPECTIONS };
