import {
  markAsSentShipmentModal,
  materialCountMarkAsSentShipmentModal,
} from 'shared/helpers/constants/modalConstants';
import { SHIPMENTS } from 'shared/helpers/constants/shipments/shipmentsConstants';
import { isCustomerOrGrading } from 'shared/helpers/matchers/checkUserType';

const markAsSentConfigEnum = {
  materialCountRequired: 'materialCountRequired',
  default: 'default',
};

const markAsSentConfig = userType => ({
  materialCountRequired: {
    markAsSentShipmentModal: materialCountMarkAsSentShipmentModal,
    label: SHIPMENTS.MARK_AS_SENT,
    redirectPath: id => `/shipments/edit-incoming-wizard/${id}`,
  },
  default: {
    markAsSentShipmentModal,
    label: SHIPMENTS.SHIP_AND_PRINT_SHIPMENT,
    redirectPath: id =>
      isCustomerOrGrading(userType)
        ? `/shipments/${id}/update`
        : `/shipments/incoming/${id}/update`,
  },
});

export { markAsSentConfigEnum, markAsSentConfig };
