import { companyTypesEnum } from 'shared/helpers/constants/filters/companyTypes';
import { statusesForGlobal } from 'shared/helpers/constants/lots/statusesForGlobal';

const removeCommodityAccess = (
  {
    access: {
      commodities: { remove },
    },
  },
  { statusForGlobal, sentFromCompany },
) => {
  const isGrading = sentFromCompany?.companyType === companyTypesEnum.grading;
  return (
    remove &&
    (isGrading ||
      [
        statusesForGlobal.received,
        statusesForGlobal.accepted_for_grading,
        statusesForGlobal.grading_completed,
        statusesForGlobal.ready_for_processing,
      ].includes(statusForGlobal))
  );
};

export { removeCommodityAccess };
