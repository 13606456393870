import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getGeneralInfoActionType = 'GET_GENERAL_INFO';
function getGeneralInfoAction() {
  return {
    type: getGeneralInfoActionType,
  };
}

const getGeneralInfoSuccessActionType = `${getGeneralInfoActionType}_SUCCESS`;
function getGeneralInfoSuccessAction(generalInfo) {
  return {
    type: getGeneralInfoSuccessActionType,
    payload: {
      generalInfo,
    },
  };
}

const getGeneralInfoFailureActionType = `${getGeneralInfoActionType}_FAILURE`;
function getGeneralInfoFailureAction(errorMessage) {
  return {
    type: getGeneralInfoFailureActionType,
    payload: {
      errorMessage,
    },
  };
}

function getGeneralInfo() {
  return dispatch => {
    dispatch(getGeneralInfoAction());

    return axios
      .get(`${API_HOST_AUTH}/general-info`)
      .then(generalInfo => {
        dispatch(getGeneralInfoSuccessAction(generalInfo.data));
        return generalInfo.data;
      })
      .catch(error => {
        dispatch(getGeneralInfoFailureAction(error));

        return Promise.reject(error.data);
      });
  };
}

export {
  getGeneralInfoActionType,
  getGeneralInfoSuccessActionType,
  getGeneralInfoFailureActionType,
  getGeneralInfo,
};
