const shipmentPackagesListActionType = 'SHIPMENT_PACKAGES_LIST';
const shipmentPackagesListAction = packages => ({
  type: shipmentPackagesListActionType,
  payload: {
    packages,
  },
});

const clearShipmentPackagesListActionType = `CLEAR_${shipmentPackagesListActionType}`;
const clearShipmentPackagesListAction = () => ({
  type: clearShipmentPackagesListActionType,
});

export {
  shipmentPackagesListActionType,
  shipmentPackagesListAction as shipmentPackagesList,
  clearShipmentPackagesListActionType,
  clearShipmentPackagesListAction as clearShipmentPackagesList,
};
