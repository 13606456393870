import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';

import { marketAvailability as getMarketAvailability } from 'actions/Hedges/marketAvailability';
import { getMarketHolidays } from 'actions/Hedges/marketHolidaysList';
import {
  clearBusyHoursForSelfDeliveryRequest,
  getBusyHoursForSelfDeliveryRequest,
} from 'actions/SelfDeliveryRequests/selfDeliveryRequestBusyHours';

import { FieldInput, FieldSearch, FieldDate, FieldTime } from 'shared/components/Fields';
import { FieldButtonSelect } from 'shared/components/Fields/FieldButtonSelect/FieldButtonSelect';
import { Form, Section } from 'shared/components/Form';
import { cancelModal } from 'shared/helpers/constants/modalConstants';
import { isTablet } from 'shared/helpers/constants/resolutionsConstants';
import { availableFundsTypes } from 'shared/helpers/constants/selfDeliveryRequests/fundsTypes';
import { SELF_DELIVERY_REQUESTS } from 'shared/helpers/constants/selfDeliveryRequests/selfDeliveryRequestsConstants';
import { isGradingUser, isOwnerUser, isSupportUser } from 'shared/helpers/matchers/checkUserType';
import { dateToTimestamp } from 'shared/helpers/parsers/date';
import { mapArray } from 'shared/helpers/parsers/select';
import { validate } from 'shared/helpers/validations/selfDeliveryRequests/createSelfDeliveryRequestValidation';
import { useWindowWidth } from 'shared/hooks/useWindowWidth';

import { convertDataToDaysNumbers } from 'utils/date/convertDataToDaysNumbers';
import dateAdapter from 'utils/date/dateAdapter';
import { MomentAdapter } from 'utils/date/momentAdapter';
import { goBackOrTo } from 'utils/history';

const selector = formValueSelector('SelfDeliveryRequestForm');

const minTimeStart = process.env.REACT_APP_SELF_DELIVERY_REQUEST_MARKET_TIME_START;
const minTimeEnd = process.env.REACT_APP_SELF_DELIVERY_REQUEST_MARKET_TIME_END;

const CreateSelfDeliveryRequestFormBase = ({
  auth: {
    user: { userType },
  },
  ...props
}) => {
  const dispatch = useDispatch();
  const deliveryDate = useSelector(state => selector(state, 'deliveryDate'));
  const excludedHours = useSelector(
    ({ selfDeliveryRequestsOverview }) => selfDeliveryRequestsOverview.excludedHours,
  );
  const minTime = dateAdapter().startOf('d').add(minTimeStart, 'h').localizeTime();
  const maxTime = dateAdapter().startOf('d').add(minTimeEnd, 'h').localizeTime();
  const windowWidth = useWindowWidth();

  const { marketHolidays } = useSelector(({ marketHolidaysList }) => marketHolidaysList);
  const marketAvailability = useSelector(
    ({ marketAvailabilityDetails }) => marketAvailabilityDetails,
  );

  useEffect(() => {
    dispatch(getMarketHolidays({ year: new Date().getFullYear() }));
    dispatch(getMarketAvailability());
  }, []);

  useEffect(() => {
    if (deliveryDate) {
      const deliverDateTimestamp = MomentAdapter.normalizeWithTime(deliveryDate).valueOf();
      dispatch(getBusyHoursForSelfDeliveryRequest(deliverDateTimestamp)).then(excludedHours => {
        props.change('excludedHoursCount', excludedHours.length);
      });
    }

    return () => {
      dispatch(clearBusyHoursForSelfDeliveryRequest());
    };
  }, [deliveryDate]);

  const listRelatedCompanies = input => {
    const restrictedStatuses = ['no_terms'];

    if (!input) {
      return Promise.resolve({
        options: [],
      });
    }
    return props
      .getCompaniesBySearchStrUserType(input, 'grading', restrictedStatuses)
      .then(companies => ({
        options: companies.options,
      }));
  };

  const modalOpen = () => {
    props.openModal(cancelModal, () => {
      goBackOrTo('/shipments');
    });
  };

  const resolveDeliveryTime = () => {
    const isToday =
      deliveryDate &&
      MomentAdapter.normalize(deliveryDate).valueOf() === dateAdapter().startOf('d').valueOf();
    const isBeforeMin = dateAdapter().localizeTime() > dateAdapter(minTime).localizeTime();

    return {
      minTime: isGradingUser(userType) && isToday && isBeforeMin ? dateAdapter().toDate() : minTime,
      maxTime,
    };
  };

  const marketHolidaysDates = marketHolidays.map(({ date }) => date);
  const marketDays = convertDataToDaysNumbers(marketAvailability.marketDays);

  const filterRestrictedDays = date => {
    const day = date.getDay();
    if (marketHolidaysDates.includes(dateToTimestamp(date))) {
      return false;
    }

    return marketDays.includes(day);
  };

  const filterMinMaxDate = () =>
    isGradingUser(userType) && dateAdapter().localizeTime() > dateAdapter(maxTime).localizeTime()
      ? dateAdapter().add(1, 'd')
      : dateAdapter();

  const minDate =
    isOwnerUser(userType) || isSupportUser(userType) ? dateAdapter(0) : filterMinMaxDate();

  const deliveryDateOnChange = () => deliveryDate && props.resetSection('deliveryDateTime');

  return (
    <Form
      onSubmit={props.handleSubmit}
      onCancel={modalOpen}
      submitDisabled={props.invalid}
      header={SELF_DELIVERY_REQUESTS.SELF_DELIVERY_REQUEST}
    >
      <Section
        noPaddingField={windowWidth > isTablet && 2}
        template={[
          'relatedCompany fundsType',
          'deliveryDate deliveryDateTime',
          'estimatedFunds unitCount',
          'customerInstructions .',
        ]}
      >
        <Field
          name="relatedCompany"
          component={FieldSearch}
          label={SELF_DELIVERY_REQUESTS.RELATED_COMPANY}
          field="required"
          placeholder={SELF_DELIVERY_REQUESTS.PLACEHOLDERS.SEARCH}
          getOptions={listRelatedCompanies}
          disabled={isGradingUser(userType)}
        />
        <Field
          name="fundsType"
          component={FieldButtonSelect}
          options={mapArray(availableFundsTypes.all)}
          label={SELF_DELIVERY_REQUESTS.FUNDS_TYPE}
          field="required"
        />
        <Field
          name="deliveryDate"
          component={FieldDate}
          label={SELF_DELIVERY_REQUESTS.DELIVERY_DATE_PICKER}
          field="required"
          filterRestrictedDays={filterRestrictedDays}
          minDate={minDate}
          onDateChange={deliveryDateOnChange}
          disableFlip
        />
        <Field
          name="deliveryDateTime"
          component={FieldTime}
          label={SELF_DELIVERY_REQUESTS.APPROX_DELIVERY_TIME}
          field="required"
          icon="icon-clock"
          disabled={!deliveryDate}
          timeRange={resolveDeliveryTime()}
          disableFlip
          excludeTimes={excludedHours}
        />
        <Field
          name="estimatedFunds"
          component={FieldInput}
          type="text"
          label={SELF_DELIVERY_REQUESTS.ESTIMATED_FUNDS}
          field="required"
          prefix="$"
        />
        <Field
          name="unitCount"
          component={FieldInput}
          label={SELF_DELIVERY_REQUESTS.UNIT_COUNT}
          field="required"
          suffix="units"
        />
        <Field
          name="customerInstructions"
          component={FieldInput}
          label={SELF_DELIVERY_REQUESTS.CUSTOMER_INSTRUCTIONS}
        />
      </Section>
    </Form>
  );
};

const SelfDeliveryRequestForm = reduxForm({
  form: 'SelfDeliveryRequestForm',
  validate,
})(CreateSelfDeliveryRequestFormBase);

export {
  SelfDeliveryRequestForm as CreateSelfDeliveryRequestForm,
  CreateSelfDeliveryRequestFormBase,
};
