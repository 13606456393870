import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';

import { lockMarketIndicators } from 'actions/MarketIndicators/lockMarketIndicators';
import { openModal } from 'actions/shared/modal';
import { showSnackbar } from 'actions/shared/snackbar';

import { FieldConfirmation } from 'shared/components/Fields/FieldConfirmation/FieldConfirmation';
import { Form, Section } from 'shared/components/Form';
import { cancelModal } from 'shared/helpers/constants/modalConstants';
import { dangerOptions } from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { type } from 'shared/helpers/constants/systemSettings/marketIndicators/requestMarketIndicators';
import { UPDATE_MARKET_INDICATORS } from 'shared/helpers/constants/systemSettings/marketIndicators/updateMarketIndicators';
import { validate } from 'shared/helpers/validations/systemSettings/marketIndicators/updateMarketIndicators';
import { useInterval } from 'shared/hooks/useInterval';

function UpdateMarketIndicatorsFormBase({
  handleSubmit,
  submitting,
  invalid,
  history,
  match: { params },
  change,
}) {
  const dispatch = useDispatch();
  const onCancel = useCallback(() => {
    dispatch(
      openModal(cancelModal, () => {
        history.push('/settings');
      }),
    );
  }, [dispatch, history]);

  useInterval(
    () => {
      dispatch(lockMarketIndicators()).catch(({ message }) => {
        dispatch(showSnackbar(dangerOptions, message));
        history.push('/settings');
      });
    },
    process.env.REACT_APP_MARKET_INDICATORS_LOCKING_RATE,
    true,
  );

  return (
    <Form
      header={UPDATE_MARKET_INDICATORS.MARKET_INDICATORS}
      onSubmit={handleSubmit}
      onCancel={onCancel}
      submitDisabled={submitting || invalid}
    >
      {params.type === type.hedging && (
        <Section
          header={UPDATE_MARKET_INDICATORS.HEDGING}
          template={[
            'hedgingPlatinumPrice hedgingPlatinumPrice',
            'hedgingPalladiumPrice hedgingPalladiumPrice',
            'hedgingRhodiumPrice hedgingRhodiumPrice',
          ]}
          noPadding
        >
          <FieldConfirmation
            type="text"
            name="hedgingPlatinumPrice"
            label={UPDATE_MARKET_INDICATORS.PLATINUM}
            errorMessage={UPDATE_MARKET_INDICATORS.HEDGING_PLATINUM_PRICE_DO_NOT_MATCH}
            field="required"
            prefix="$"
            change={change}
          />
          <FieldConfirmation
            type="text"
            name="hedgingPalladiumPrice"
            label={UPDATE_MARKET_INDICATORS.PALLADIUM}
            errorMessage={UPDATE_MARKET_INDICATORS.HEDGING_PALLADIUM_PRICE_DO_NOT_MATCH}
            field="required"
            prefix="$"
            change={change}
          />
          <FieldConfirmation
            type="text"
            name="hedgingRhodiumPrice"
            label={UPDATE_MARKET_INDICATORS.RHODIUM}
            errorMessage={UPDATE_MARKET_INDICATORS.HEDGING_RHODIUM_PRICE_DO_NOT_MATCH}
            field="required"
            prefix="$"
            change={change}
          />
        </Section>
      )}
      {params.type === type.settlement && (
        <Section
          header={UPDATE_MARKET_INDICATORS.SETTLEMENT}
          template={[
            'settlementPlatinumPrice settlementPlatinumPrice',
            'settlementPalladiumPrice settlementPalladiumPrice',
            'settlementRhodiumPrice settlementRhodiumPrice',
          ]}
          noPadding
        >
          <FieldConfirmation
            type="text"
            name="settlementPlatinumPrice"
            label={UPDATE_MARKET_INDICATORS.PLATINUM}
            errorMessage={UPDATE_MARKET_INDICATORS.SETTLEMENT_PLATINUM_PRICE_DO_NOT_MATCH}
            field="required"
            prefix="$"
            change={change}
          />
          <FieldConfirmation
            type="text"
            name="settlementPalladiumPrice"
            label={UPDATE_MARKET_INDICATORS.PALLADIUM}
            errorMessage={UPDATE_MARKET_INDICATORS.SETTLEMENT_PALLADIUM_PRICE_DO_NOT_MATCH}
            field="required"
            prefix="$"
            change={change}
          />
          <FieldConfirmation
            type="text"
            name="settlementRhodiumPrice"
            label={UPDATE_MARKET_INDICATORS.RHODIUM}
            errorMessage={UPDATE_MARKET_INDICATORS.SETTLEMENT_RHODIUM_PRICE_DO_NOT_MATCH}
            field="required"
            prefix="$"
            change={change}
          />
        </Section>
      )}
    </Form>
  );
}

const UpdateMarketIndicatorsForm = compose(
  reduxForm({
    form: 'UpdateMarketIndicators',
    validate,
  }),
  withRouter,
)(UpdateMarketIndicatorsFormBase);

export { UpdateMarketIndicatorsForm, UpdateMarketIndicatorsFormBase };
