const ASSAY = {
  ASSAY: 'Assay',
  ASSAY_OPTION: 'Assay option',
  MATERIAL_TYPE: 'Material type',
  MATERIAL_TYPE_H: 'Material type - Heavies',
  MATERIAL_TYPE_F: 'Material type - Fines',

  MATERIAL_TYPE_A: 'Material type - A',
  MATERIAL_TYPE_B: 'Material type - B',

  SELECT_MATERIAL_TYPE_F_FIRST: '-- select Fines first --',

  SELECT_MATERIAL_TYPE_A_FIRST: '-- select A first --',

  WEIGHT_GROSS_RECEIVED: 'Weight gross received',
  WEIGHT_TARE_RECEIVED: 'Weight tare received',
  WEIGHT_NET_WET_RECEIVED: 'Weight net wet received',
  WEIGHT_SCRAP_RECEIVED: 'Weight scrap received',
  WEIGHT_ADJUSTED_NET_WET_RECEIVED: 'Weight adjusted net wet received',
  WEIGHT_NET_DRY_RECEIVED: 'Weight net dry received',
  PERCENT_MOISTURE: 'Percent moisture',
  PLATINUM_ASSAY: 'Platinum assay',
  PALLADIUM_ASSAY: 'Palladium assay',
  RHODIUM_ASSAY: 'Rhodium assay',
  PLATINUM_CONTAINED_OZ: 'Platinum contained oz',
  PALLADIUM_CONTAINED_OZ: 'Palladium contained oz',
  RHODIUM_CONTAINED_OZ: 'Rhodium contained oz',
  PLATINUM_RETURNABLE_OZ: 'Platinum returnable oz',
  PALLADIUM_RETURNABLE_OZ: 'Palladium returnable oz',
  RHODIUM_RETURNABLE_OZ: 'Rhodium returnable oz',

  WEIGHT_GROSS_RECEIVED_H: 'Weight gross received - Heavies',
  WEIGHT_TARE_RECEIVED_H: 'Weight tare received - Heavies',
  WEIGHT_NET_WET_RECEIVED_H: 'Weight net wet received - Heavies',
  WEIGHT_SCRAP_RECEIVED_H: 'Weight scrap received - Heavies',
  WEIGHT_ADJUSTED_NET_WET_RECEIVED_H: 'Weight adjusted net wet received - Heavies',
  WEIGHT_NET_DRY_RECEIVED_H: 'Weight net dry received - Heavies',
  PERCENT_MOISTURE_H: 'Percent moisture - Heavies',
  PLATINUM_ASSAY_H: 'Platinum assay - Heavies',
  PALLADIUM_ASSAY_H: 'Palladium assay - Heavies',
  RHODIUM_ASSAY_H: 'Rhodium assay - Heavies',
  PLATINUM_CONTAINED_OZ_H: 'Platinum contained oz - Heavies',
  PALLADIUM_CONTAINED_OZ_H: 'Palladium contained oz - Heavies',
  RHODIUM_CONTAINED_OZ_H: 'Rhodium contained oz - Heavies',
  PLATINUM_RETURNABLE_OZ_H: 'Platinum returnable oz - Heavies',
  PALLADIUM_RETURNABLE_OZ_H: 'Palladium returnable oz - Heavies',
  RHODIUM_RETURNABLE_OZ_H: 'Rhodium returnable oz - Heavies',

  WEIGHT_GROSS_RECEIVED_A: 'Weight gross received - A',
  WEIGHT_TARE_RECEIVED_A: 'Weight tare received - A',
  WEIGHT_NET_WET_RECEIVED_A: 'Weight net wet received - A',
  WEIGHT_SCRAP_RECEIVED_A: 'Weight scrap received - A',
  WEIGHT_ADJUSTED_NET_WET_RECEIVED_A: 'Weight adjusted net wet received - A',
  WEIGHT_NET_DRY_RECEIVED_A: 'Weight net dry received - A',
  PERCENT_MOISTURE_A: 'Percent moisture - A',
  PLATINUM_ASSAY_A: 'Platinum assay - A',
  PALLADIUM_ASSAY_A: 'Palladium assay - A',
  RHODIUM_ASSAY_A: 'Rhodium assay - A',
  PLATINUM_CONTAINED_OZ_A: 'Platinum contained oz - A',
  PALLADIUM_CONTAINED_OZ_A: 'Palladium contained oz - A',
  RHODIUM_CONTAINED_OZ_A: 'Rhodium contained oz - A',
  PLATINUM_RETURNABLE_OZ_A: 'Platinum returnable oz - A',
  PALLADIUM_RETURNABLE_OZ_A: 'Palladium returnable oz - A',
  RHODIUM_RETURNABLE_OZ_A: 'Rhodium returnable oz - A',

  WEIGHT_GROSS_RECEIVED_F: 'Weight gross received - Fines',
  WEIGHT_TARE_RECEIVED_F: 'Weight tare received - Fines',
  WEIGHT_NET_WET_RECEIVED_F: 'Weight net wet received - Fines',
  WEIGHT_SCRAP_RECEIVED_F: 'Weight scrap received - Fines',
  WEIGHT_ADJUSTED_NET_WET_RECEIVED_F: 'Weight adjusted net wet received - Fines',
  WEIGHT_NET_DRY_RECEIVED_F: 'Weight net dry received - Fines',
  PERCENT_MOISTURE_F: 'Percent moisture - Fines',
  PLATINUM_ASSAY_F: 'Platinum assay - Fines',
  PALLADIUM_ASSAY_F: 'Palladium assay - Fines',
  RHODIUM_ASSAY_F: 'Rhodium assay - Fines',
  PLATINUM_CONTAINED_OZ_F: 'Platinum contained oz - Fines',
  PALLADIUM_CONTAINED_OZ_F: 'Palladium contained oz - Fines',
  RHODIUM_CONTAINED_OZ_F: 'Rhodium contained oz - Fines',
  PLATINUM_RETURNABLE_OZ_F: 'Platinum returnable oz - Fines',
  PALLADIUM_RETURNABLE_OZ_F: 'Palladium returnable oz - Fines',
  RHODIUM_RETURNABLE_OZ_F: 'Rhodium returnable oz - Fines',

  WEIGHT_GROSS_RECEIVED_B: 'Weight gross received - B',
  WEIGHT_TARE_RECEIVED_B: 'Weight tare received - B',
  WEIGHT_NET_WET_RECEIVED_B: 'Weight net wet received - B',
  WEIGHT_SCRAP_RECEIVED_B: 'Weight scrap received - B',
  WEIGHT_ADJUSTED_NET_WET_RECEIVED_B: 'Weight adjusted net wet received - B',
  WEIGHT_NET_DRY_RECEIVED_B: 'Weight net dry received - B',
  PERCENT_MOISTURE_B: 'Percent moisture - B',
  PLATINUM_ASSAY_B: 'Platinum assay - B',
  PALLADIUM_ASSAY_B: 'Palladium assay - B',
  RHODIUM_ASSAY_B: 'Rhodium assay - B',
  PLATINUM_CONTAINED_OZ_B: 'Platinum contained oz - B',
  PALLADIUM_CONTAINED_OZ_B: 'Palladium contained oz - B',
  RHODIUM_CONTAINED_OZ_B: 'Rhodium contained oz - B',
  PLATINUM_RETURNABLE_OZ_B: 'Platinum returnable oz - B',
  PALLADIUM_RETURNABLE_OZ_B: 'Palladium returnable oz - B',
  RHODIUM_RETURNABLE_OZ_B: 'Rhodium returnable oz - B',

  ACTIVITY: 'Activity',
  WEIGHTS: 'Weights',
  RETURNABLE_OUNCES: 'Returnable ounces',
  CONTAINED_METALS: 'Contained metals',
  CONTAINED_ASSAYS: 'Contained assays',
  CARBON_PERCENTAGE: 'Carbon percentage',
  CARBON_PERCENTAGE_H: 'Carbon percentage - Heavies',
  CARBON_PERCENTAGE_F: 'Carbon percentage - Fines',
  CARBON_PERCENTAGE_A: 'Carbon percentage - A',
  CARBON_PERCENTAGE_B: 'Carbon percentage - B',
  LBS: 'lbs',
  PPM: 'ppm',
  TROY_OZ: 'troy oz',
  PERCENT: '%',
  CREATED_BY: 'Created by',
  SELECT_ASSAY_OPTION: '-- select assay option --',
  ERROR: {
    ENTER_ASSAY_RESULTS: 'Assay results have not been entered.',
  },
};

export { ASSAY };
