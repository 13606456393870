import styled from 'styled-components';

import { Icon } from 'shared/components/Icon/Icon';

export const InputWrapper = styled.span`
  position: relative;

  ${Icon} {
    position: absolute;
    right: 10px;
  }
`;
