import { flatten } from 'utils/flatten';

const WEEKDAYS = {
  Sunday: 0,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
};

const convertDataToDaysNumbers = date => {
  const singleDays = date.replace(/ /g, '').split(',');
  const rangeDays = singleDays.filter(date => date.includes('-'));

  const ranges = rangeDays.map(days => {
    const [firstDay, lastDay] = days.split('-');
    const range = [];

    for (let i = WEEKDAYS[firstDay]; i <= WEEKDAYS[lastDay]; i++) {
      range.push(i);
    }

    return range;
  });
  return Array.from(
    new Set(
      flatten(
        singleDays
          .map(day => WEEKDAYS[day])
          .filter(Boolean)
          .concat(ranges),
      ),
    ),
  );
};

export { convertDataToDaysNumbers };
