import PropTypes from 'prop-types';
import React, { createContext, useEffect } from 'react';
import ReactDOM from 'react-dom';
import FocusLock from 'react-focus-lock';
import styled, { css } from 'styled-components';

import { ContentLoader } from 'shared/components/ContentLoader/ContentLoader';
import { cssVar, media, scrollbar } from 'shared/helpers/styling/styling';

const FormContext = createContext(true);

const HeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1100px;
  margin: auto;

  ${media.mobile`
    margin: 0 25px;
  `}

  ${media.phone`
    ${({ wizard }) =>
      wizard &&
      css`
        margin-top: 15px;
        flex-direction: column;
      `}
  `}
`;

const Headers = styled.div`
  display: flex;
  height: 150px;
  justify-content: center;
  flex-direction: column;

  ${media.phone`
    ${({ wizard }) =>
      wizard &&
      css`
        height: initial;
      `}
  `}
`;

const Header = styled.div`
  width: 100%;
  font-size: 1.875rem;
  color: ${cssVar('whiteGRG')};
`;

const SubHeader = styled(Header)`
  font-size: 1rem;
`;

const ContentLoaderStyled = styled(ContentLoader)`
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FormContainer = ({
  className,
  loaded = true,
  formLoaded,
  header,
  subHeader,
  actions,
  wizard,
  focusLockDisabled = false,
  children,
}) => {
  useEffect(() => {
    document.body.style.background = cssVar('outerSpaceFormBg');

    return () => {
      document.body.style.background = '';
    };
  }, []);

  return ReactDOM.createPortal(
    <div className={className} id="formContainer">
      <HeaderSection wizard={wizard}>
        <Headers wizard={wizard}>
          {!!header && <Header>{header}</Header>}
          {!!subHeader && <SubHeader>{subHeader}</SubHeader>}
        </Headers>
        {actions}
      </HeaderSection>
      <FormContext.Provider value={!!formLoaded}>
        <FocusLock disabled={focusLockDisabled}>
          <ContentLoaderStyled loaded={!!loaded}>{children}</ContentLoaderStyled>
        </FocusLock>
      </FormContext.Provider>
    </div>,
    document.getElementById('app'),
  );
};

const FormContainerStyled = styled(FormContainer)`
  width: 100%;
  height: fit-content;
  min-height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: ${cssVar('outerSpaceFormBg')};

  ${media.desktop`
     ${scrollbar}

     ::-webkit-scrollbar-track {
        background-color: ${cssVar('outerSpaceFormBg')};
     }
  `}
`;

FormContainerStyled.propTypes = {
  header: PropTypes.string.isRequired,
  subHeader: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  loaded: PropTypes.any,
  formLoaded: PropTypes.any,
};

export { FormContainerStyled as FormContainer, ContentLoaderStyled, FormContext };
