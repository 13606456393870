import { fieldPrefix } from '../versioning/fieldPrefixes';

const message = {
  INVALID_CUSTOMER_INSTRUCTIONS: 'Customer instructions must have a valid format.',
  INVALID_GLOBAL_MOISTURE_TEST: 'Global moisture test must have a valid format.',
  INVALID_GLOBAL_MOISTURE_TEST_RANGE: 'Global moisture test should be number between 0 and 10.',
  INVALID_GLOBAL_MOISTURE_TEST_TOO_LARGE: 'Global moisture test value is too large.',
  INVALID_FUNDS_TYPE_EMPTY: 'Funds type cannot be empty.',
  INVALID_SENT_FROM_COMPANY_LOCATION_EMPTY: 'Sent from company location cannot be empty.',
  INVALID_SENT_FROM_COMPANY_EMPTY: 'Sent from company cannot be empty.',
  INVALID_MATERIAL_TYPE_EMPTY: 'Material type cannot be empty.',
  INVALID_STATUS_FOR_CUSTOMER_EMPTY: 'Status for customer cannot be empty.',
  INVALID_STATUS_FOR_GLOBAL_EMPTY: 'Status for Global cannot be empty.',
  INVALID_PROCESS_UPON_DELIVERY_EMPTY: 'Process upon delivery cannot be empty.',
  INVALID_STILLWATER_FINAL_PAYMENT: 'Final payment value must have valid format.',
  INVALID_STILLWATER_FINAL_PAYMENT_NEGATIVE: 'Final payment value cannot be negative.',
  INVALID_STILLWATER_FINAL_PAYMENT_TOO_LARGE: 'Final payment value is too large.',
  INVALID_STILLWATER_FINAL_PAYMENT_NULL: 'Final payment value cannot be changed to empty value.',
  INVALID_STILLWATER_FINAL_PAYMENT_CONFIRM: 'Final payment value do not match.',
  STILLWATER_FINAL_PAYMENT_WITHOUT_DATE: 'Final payment date cannot be empty',
  INVALID_STILLWATER_FINAL_PAYMENT_DATE_NULL:
    'Final payment date value cannot be changed to empty value.',
  INVALID_STILLWATER_INITIAL_PAYMENT: 'Initial payment value have invalid format.',
  INVALID_STILLWATER_INITIAL_PAYMENT_NEGATIVE: 'Initial payment value cannot be negative.',
  INVALID_STILLWATER_INITIAL_PAYMENT_TOO_LARGE: 'Initial payment value is too large.',
  INVALID_STILLWATER_INITIAL_PAYMENT_NULL:
    'Initial payment value cannot be changed to empty value.',
  INVALID_STILLWATER_INITIAL_PAYMENT_CONFIRM: 'Initial payment value do not match.',
  STILLWATER_INITIAL_PAYMENT_WITHOUT_DATE: 'Initial payment date cannot be empty',
  INVALID_STILLWATER_INITIAL_PAYMENT_DATE_NULL:
    'Initial payment date value cannot be changed to empty value.',
  INVALID_DEDUCTION: 'Deduction must have a valid format.',
  INVALID_TRUCKING: 'Trucking must have a valid format.',
  INVALID_DEDUCTION_NEGATIVE: 'Deduction cannot be a negative value.',
  INVALID_TRUCKING_NEGATIVE: 'Trucking cannot be a negative value.',
  INVALID_DEDUCTION_TOO_LARGE: 'Deduction value is too large.',
  INVALID_TRUCKING_TOO_LARGE: 'Trucking value is too large.',
  INVALID_DEDUCTION_DESCRIPTION: 'Deduction description must have a valid format.',
  INVALID_DEDUCTION_DESCRIPTION_EMPTY: 'Deduction description cannot be empty',
  INVALID_CREDIT: 'Credit must have a valid format.',
  INVALID_CREDIT_NEGATIVE: 'Credit cannot be a negative value.',
  INVALID_CREDIT_TOO_LARGE: 'Credit value is too large.',
  INVALID_CREDIT_DESCRIPTION: 'Credit description must have a valid format.',
  INVALID_CREDIT_DESCRIPTION_EMPTY: 'Credit description cannot be empty',
  INVALID_COUNT_SHEET_FILE_TYPE: "Expecting '.pdf, .xls, .xlsx' file type.",
  INVALID_FILE_TYPE_PDF: "Expecting '.pdf' file type.",
  INVALID_FILE_SIZE: 'Maximum file size is 20MB.',
  INVALID_FILE_NAME: 'You cannot attach files with invalid name.',
  INVALID_CUSTOM_LOT_NUMBER_FORMAT: 'Custom lot number must have a valid format.',
  INVALID_CUSTOM_LOT_NUMBER_UNIQUE: 'Custom lot number must be unique.',
  MONO_FORMAT: 'Mono have invalid format.',
  MONO_LOO_LONG: 'Mono is too long.',
  MONO_DO_NOT_MATCH: 'Mono do not match.',
  FOIL_FORMAT: 'Foil have invalid format.',
  FOIL_LOO_LONG: 'Foil is too long.',
  FOIL_DO_NOT_MATCH: 'Foil do not match.',
  EMPTY_FORMAT: 'Empty have invalid format.',
  EMPTY_LOO_LONG: 'Empty is too long.',
  EMPTY_DO_NOT_MATCH: 'Empty do not match.',
  AFT_FORMAT: 'AFT have invalid format.',
  AFT_LOO_LONG: 'AFT is too long.',
  AFT_DO_NOT_MATCH: 'AFT do not match.',
  GRG_QTY_TOTAL_FORMAT: `${fieldPrefix} QTY Total have invalid format.`,
  GRG_QTY_TOTAL_LOO_LONG: `${fieldPrefix} QTY Total is too long.`,
  GRG_QTY_TOTAL_DO_NOT_MATCH: `${fieldPrefix} QTY Total do not match.`,
};

export { message };
