import React from 'react';
import { useLocation } from 'react-router-dom';
import dateAdapter from 'utils/date/dateAdapter';
import { MomentAdapter } from 'utils/date/momentAdapter';
import { useSelector } from 'react-redux';

import { Calendar as BigCalendar, momentLocalizer, Views } from 'react-big-calendar';

import { UIAuthorization } from 'components/Authorization/UIAuthorization';

import { Legend } from 'shared/components/Legend/Legend';
import { eventTypes, CALENDAR, colors } from 'shared/helpers/constants/calendarConstants';

import { CreateCalendarNote } from 'pages/Shipments/components/CalendarNotes/CreateCalendarNote/CreateCalendarNote';

import { StyledCalendar } from './StyledCalendar';
import { CalendarToolbar, CalendarEvent, CalendarWeekHeader } from './components';

const calendarLocalizer = momentLocalizer();

const parseEvents = (data, type) =>
  data.map(event => ({
    ...event,
    type,
    start: dateAdapter(event.timestamp).localize(),
    end: dateAdapter(event.timestamp).localize(),
  }));

const Calendar = ({ data, markedDay, initialView, onDateChange, onSelectEvent, auth }) => {
  const { pathname } = useLocation();
  const access = useSelector(state => state.auth.access);

  const legendConfig = [
    {
      label: CALENDAR.INCOMING_SHIPMENTS,
      color: colors.incomingShipments,
    },
    access.companies.gradingEnabled && {
      label: CALENDAR.SELF_DELIVERY_REQUESTS,
      color: colors.selfDeliveryRequests,
    },
    {
      label: CALENDAR.OUTGOING_SHIPMENTS,
      color: colors.outgoingShipments,
    },
  ].filter(Boolean);

  const dates = data && data.marketHolidays.map(day => dateAdapter(day.date).localize().valueOf());
  const localizedEvents = data
    ? [
        ...parseEvents(data.incomingShipments, eventTypes.incomingShipments),
        ...parseEvents(data.outgoingShipments, eventTypes.outgoingShipments),
        ...parseEvents(data.selfDeliveryRequests, eventTypes.selfDeliveryRequests),
        ...parseEvents(data.calendarNotes, eventTypes.calendarNotes),
      ]
    : [];
  const marketHolidayDays = date => {
    const classNames = [];

    if ((dates || []).includes(date.valueOf())) {
      classNames.push('market-holiday');
    }

    if (markedDay && dateAdapter(markedDay).localize().valueOf() === date.valueOf()) {
      classNames.push('marked-day');
    }

    return {
      className: classNames.join(' '),
    };
  };

  return (
    <>
      <StyledCalendar>
        <BigCalendar
          selectable
          events={localizedEvents}
          views={[Views.MONTH, Views.WEEK]}
          defaultDate={new Date(markedDay || Date.now())}
          defaultView={initialView}
          onNavigate={onDateChange}
          getNow={() => new Date()}
          onSelectEvent={onSelectEvent}
          dayPropGetter={marketHolidayDays}
          slotPropGetter={marketHolidayDays}
          localizer={{
            formats: calendarLocalizer.formats,
            startOfWeek: () => 0,
            format: (value, format) =>
              typeof format === 'function'
                ? format(value)
                : MomentAdapter.formatLocal(value, format),
          }}
          components={{
            toolbar: toolbarProps => <CalendarToolbar auth={auth} {...toolbarProps} />,
            event: CalendarEvent,
            week: {
              header: CalendarWeekHeader,
            },
          }}
          formats={{
            dateFormat: 'MM/DD',
            dayFormat: 'ddd (MM/DD)',
            monthHeaderFormat: 'MMMM',
            dayRangeHeaderFormat: ({ start, end }) =>
              `${MomentAdapter.formatLocal(start, 'MM/DD')} - ${MomentAdapter.formatLocal(
                end,
                'MM/DD',
              )}`,
          }}
        />
        <Legend legendConfig={legendConfig} />
      </StyledCalendar>
      <UIAuthorization access={pathname.endsWith('calendar-notes')}>
        <CreateCalendarNote />
      </UIAuthorization>
    </>
  );
};

Calendar.defaultProps = {
  initialView: Views.WEEK,
};

export { Calendar };
