import React, { useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import styled from 'styled-components';

import { SubmitButton } from 'shared/components/Buttons';
import { FieldUpload } from 'shared/components/Fields/FiledUpload/FieldUpload';
import { FieldUploadList } from 'shared/components/Fields/FiledUpload/FieldUploadList';
import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';
import { validate } from 'shared/helpers/validations/lot/uploadGradingReceiptValidation';

const Header = styled.h3`
  text-align: center;
`;

const SubmitContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 35px;
`;

const UploadGradingReceiptContentLayout = props => {
  const { clearGradingReceipt } = props;

  useEffect(() => () => clearGradingReceipt(), [clearGradingReceipt]);

  const gradingReceipt = props.uploadGradingReceiptFile.gradingReceipt;
  const onFileInputChange = e => props.addGradingReceipt(e.target.files[0]);

  return (
    <div>
      <Header>{LOTS.UPLOAD_GRADING_RECEIPT_WARNING(props.grgLotNumber)}</Header>
      <form onSubmit={props.handleSubmit} noValidate>
        <Field
          name="gradingReceipt"
          type="file"
          validExtensions=".pdf"
          component={FieldUpload}
          label={LOTS.ADD_GRADING_RECEIPT}
          onChange={onFileInputChange}
        >
          <span>{LOTS.ADD_GRADING_RECEIPT}</span>
        </Field>
        {gradingReceipt && <FieldUploadList previewIcoInside files={[{ file: gradingReceipt }]} />}
        <SubmitContainer>
          <SubmitButton
            id="uploadGradingReceipt"
            label={LOTS.UPLOAD}
            disabled={props.invalid || props.pristine}
          />
        </SubmitContainer>
      </form>
    </div>
  );
};

const UploadGradingReceiptContent = reduxForm({
  form: 'UploadGradingReceiptContentForm',
  validate,
})(UploadGradingReceiptContentLayout);

export { UploadGradingReceiptContent, UploadGradingReceiptContentLayout };
