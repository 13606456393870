import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { marketAvailability } from 'actions/Hedges/marketAvailability';
import { showSnackbar } from 'actions/shared/snackbar';

import { GroupButton } from 'shared/components/Buttons/GroupButton/components/GroupButton';
import { requestHedgeDateAccess } from 'shared/helpers/accesses/hedges';
import { HEDGES } from 'shared/helpers/constants/hedges/hedgesConstants';
import { PAYMENTS } from 'shared/helpers/constants/payments/paymentsConstants';
import { infoOptions } from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

class MarketHoursRouterButton extends Component {
  componentDidMount() {
    this.props.marketAvailability(true);
  }

  navigate = () => {
    const {
      history,
      showSnackbar: showSnackbarAction,
      marketAvailabilityDetails: {
        isBusinessDay,
        inMarketHours,
        marketHoliday,
        marketDays,
        marketHours,
        timezone,
      },
      module,
      path,
      userType,
      materialCountRequired,
    } = this.props;

    const marketInfo = { marketDays, marketHours, timezone };

    const allowRequestHedgeDate =
      requestHedgeDateAccess({
        userType,
        relatedCompany: { materialCountRequired },
        withoutCompanyValidation: true,
      }) && module === HEDGES.HEDGES;

    if (marketHoliday || !inMarketHours || !isBusinessDay) {
      if (allowRequestHedgeDate) {
        const warningInfo = marketHoliday
          ? HEDGES.MATERIAL_COUNT_MARKET_HOLIDAYS_WARNING
          : HEDGES.MATERIAL_COUNT_MARKET_HOURS_WARNING(marketInfo);

        history.push(path);
        return showSnackbarAction(infoOptions, warningInfo);
      }

      if (marketHoliday) return showSnackbarAction(infoOptions, PAYMENTS.SETTLEMENT_MARKET_HOLIDAY);

      return showSnackbarAction(infoOptions, HEDGES.MARKET_HOURS_WARNING(marketInfo, module));
    }
    history.push(path);
  };

  render() {
    const {
      marketAvailabilityDetails: { isPending },
      icon,
      label,
    } = this.props;

    return (
      <GroupButton
        disabled={isPending}
        access={this.props.access}
        onClick={this.navigate}
        label={label}
        icon={icon}
      />
    );
  }
}

const mapStateToProps = ({ marketAvailabilityDetails }) => ({ marketAvailabilityDetails });

const dispatchMapToProps = {
  marketAvailability,
  showSnackbar,
};

const ConnectedMarketHoursRouterButton = withRouter(
  connect(mapStateToProps, dispatchMapToProps)(MarketHoursRouterButton),
);

export {
  ConnectedMarketHoursRouterButton as MarketHoursRouterButton,
  MarketHoursRouterButton as MarketHoursRouterButtonTest,
};
