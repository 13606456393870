import React from 'react';

import { ContentLoader } from 'shared/components/ContentLoader/ContentLoader';
import { FullPageListTableItemMobile } from 'shared/components/FullPageList/components/FullPageListTableItemMobile/FullPageListTableItemMobile';
import { OverviewListTableCell } from 'shared/components/OverviewList/OverviewListTableCell/OverviewListTableCell';
import { OverviewTableRow } from 'shared/components/OverviewList/OverviewTableRow/OverviewTableRow';
import { StyledOverviewTable } from 'shared/components/OverviewList/StyledOverviewTable/StyledOverviewTable';
import { ListPlaceholder } from 'shared/components/Table';

const FullPageListTableMobile = ({
  items,
  itemsTemplate,
  itemsDetailsTemplate,
  itemsTemplateMobile,
  actionsTemplate,
  searchFilter,
  actionsContext,
  isPending,
  tableHeader = false,
}) => (
  <StyledOverviewTable>
    {tableHeader && (
      <OverviewTableRow mobile head header>
        <OverviewListTableCell head>{tableHeader}</OverviewListTableCell>
      </OverviewTableRow>
    )}
    <OverviewTableRow head mobile itemsNumber={itemsTemplateMobile.length} isOpen>
      {itemsTemplateMobile.map(({ label }, index) => (
        <OverviewListTableCell key={index} head>
          {label}
        </OverviewListTableCell>
      ))}
    </OverviewTableRow>
    <ContentLoader loaded={!isPending}>
      {items.length ? (
        items.map(item => (
          <FullPageListTableItemMobile
            key={item._id}
            item={item}
            searchFilter={searchFilter}
            actionsTemplate={actionsTemplate}
            actionsContext={actionsContext}
            itemsTemplate={itemsDetailsTemplate || itemsTemplate}
            itemsTemplateMobile={itemsTemplateMobile}
          />
        ))
      ) : (
        <ListPlaceholder />
      )}
    </ContentLoader>
  </StyledOverviewTable>
);

export { FullPageListTableMobile };
