import React from 'react';

import { OverviewListTableCell } from '../OverviewListTableCell/OverviewListTableCell';
import { OverviewTableItemMobile } from '../OverviewTableItemMobile/OverviewTableItemMobile';
import { OverviewTableRow } from '../OverviewTableRow/OverviewTableRow';
import { StyledOverviewTable } from '../StyledOverviewTable/StyledOverviewTable';

function OverviewTableMobile({
  items,
  itemsTemplate,
  itemsTemplateMobile,
  actionsTemplate,
  actionsContext,
  itemDetailsTemplate,
  templateProps,
  itemFilter,
  displayAllDetails,
  fillContent,
  logs,
  customColor,
  isDisabled,
}) {
  return (
    <div className="DetailsTable">
      <StyledOverviewTable>
        <OverviewTableRow head mobile itemsNumber={itemsTemplateMobile.length} isOpen>
          {itemsTemplateMobile.map(({ label }, index) => (
            <OverviewListTableCell key={index} head>
              {label}
            </OverviewListTableCell>
          ))}
        </OverviewTableRow>
        {items.map(item => (
          <OverviewTableItemMobile
            itemsTemplate={itemsTemplate}
            itemsTemplateMobile={itemsTemplateMobile}
            itemDetailsTemplate={itemDetailsTemplate}
            templateProps={templateProps}
            actionsTemplate={actionsTemplate}
            actionsContext={actionsContext}
            item={item}
            key={item._id}
            itemFilter={itemFilter}
            displayAllDetails={displayAllDetails}
            fillContent={fillContent}
            logs={logs}
            customColor={customColor}
            isDisabled={item.isDisabled}
          />
        ))}
      </StyledOverviewTable>
    </div>
  );
}

export { OverviewTableMobile };
