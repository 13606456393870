import React, { useEffect, useCallback, useMemo } from 'react';

import { FormContainer } from 'shared/components/Form';
import { USER } from 'shared/helpers/constants/users/userConstants';
import { isCustomerUser } from 'shared/helpers/matchers/checkUserType';
import { humanize } from 'shared/helpers/parsers/text';

import { ConnectedUserForm } from '../UserForm/UserForm';

function UpdateUser({
  match: { params },
  userDetails,
  updateUser,
  getUser,
  clearUserDetails,
  ...props
}) {
  const submit = useCallback(
    values => {
      const { companyId, userId } = params;

      return updateUser(values, userId, companyId);
    },
    [params, updateUser],
  );

  useEffect(() => {
    getUser(params.userId);

    return clearUserDetails;
  }, [getUser, params.userId, clearUserDetails]);

  const user = userDetails && userDetails.user;
  const initialValues = useMemo(
    () =>
      user && {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        title: user.title,
        userType: {
          label: humanize(user.userType),
          value: user.userType,
        },
        accessLevel: {
          label: humanize(user.accessLevel),
          value: user.accessLevel,
        },
        relatedCompany: {
          label: user.relatedCompany.companyName,
          value: user.relatedCompany._id,
        },
        location: user.location.map(({ _id, name }) => ({ label: name, value: _id })),
        officePhone: user.officePhone,
        mobilePhone: user.mobilePhone,
        phoneExtension: user.phoneExtension,
        ...(isCustomerUser(user.userType) && {
          databaseAccess: user.databaseAccess,
          purchasingAppAccess: user.purchasingAppAccess,
        }),
      },
    [user],
  );

  return (
    <FormContainer header={USER.EDIT_A_USER} loaded={!userDetails.isPending}>
      {() => (
        <ConnectedUserForm
          {...props}
          initialValues={initialValues}
          onSubmit={submit}
          blockSubmit={false}
          editMode
        />
      )}
    </FormContainer>
  );
}

export { UpdateUser };
