import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import { Authorization } from 'components/Authorization/Authorization';
import { NotFound } from 'components/ErrorPage/ErrorPage';

import { isGlobalUserType } from 'shared/helpers/matchers/isGlobalUserType';
import { isStatusActive } from 'shared/helpers/matchers/isStatusActive';

import { LotDetails } from 'pages/Lots/components/LotDetails/LotDetails';
import { PackageDetails } from 'pages/Packages/components/PackageDetails/PackageDetails';
import { CreateMultiIncomingPackages } from 'pages/Packages/components/PackagesManagement/Create/CreateMultiIncomingPackages/CreateMultiIncomingPackages';
import { UpdateIncomingPackageForCustomer } from 'pages/Packages/components/PackagesManagement/Update/UpdateIncomingPackageForCustomer/UpdateIncomingPackageForCustomer';
import { UpdateIncomingPackageForGlobal } from 'pages/Packages/components/PackagesManagement/Update/UpdateIncomingPackageForGlobal/UpdateIncomingPackageForGlobal';
import { UpdateMultiplePackages } from 'pages/Packages/components/PackagesManagement/Update/UpdateMultiplePackages/UpdateMultiplePackages';
import { ConnectedUpdatePackage } from 'pages/Packages/components/PackagesManagement/Update/UpdatePackage/ConnectedUpdatePackage';
import { ConnectedUpdatePackageWeight } from 'pages/Packages/components/PackagesManagement/Update/UpdatePackageWeight/ConnectedUpdatePackageWeight';

import ConnectedLotUpdate from '../Lots/components/LotsManagement/Update/UpdateLot/ConnectedUpdateLot';
import { ConnectedCreateSelfDeliveryRequest } from '../SelfDeliveryRequests/CreateSelfDeliveryRequest/ConnectedCreateSelfDeliveryRequest';
import { ConnectedSelfDeliveryRequestDetails } from '../SelfDeliveryRequests/SelfDeliveryRequestDetails/ConnectedSelfDeliveryRequestDetails';
import { ConnectedSelfDeliveryRequestsList } from '../SelfDeliveryRequests/SelfDeliveryRequestsList/ConnectedSelfDeliveryRequestsList';
import { ConnectedCreateIncomingShipment } from './components/GlobalShipmentsManagement/IncomingShipments/CreateIncomingShipment/ConnectedCreateIncomingShipment';
import { ConnectedUpdateIncomingShipment } from './components/GlobalShipmentsManagement/IncomingShipments/UpdateIncomingShipment/ConnectedUpdateIncomingShipment';
import { ConnectedCreateOutgoingShipment } from './components/GlobalShipmentsManagement/OutgoingShipments/CreateOutgoingShipment/ConnectedCreateOutgoingShipment';
import { ConnectedUpdateOutgoingShipment } from './components/GlobalShipmentsManagement/OutgoingShipments/UpdateOutgoingShipment/ConnectedUpdateOutgoingShipment';
import { ShipAndPrint } from './components/ShipAndPrint/ShipAndPrint';
import { ConnectedShipmentDetails } from './components/ShipmentDetails/ConnectedShipmentDetails';
import { ShipmentsCalendar } from './components/ShipmentsCalendar/ConnectedShipmentsCalendar';
import { ConnectedIncomingShipmentsList } from './components/ShipmentsList/IncomingShipmentsList/ConnectedIncomingShipmentsList';
import { ConnectedOutgoingShipmentsList } from './components/ShipmentsList/OutgoingShipmentsList/ConnectedOutgoingShipmentsList';
import { ConnectedCreateShipment } from './components/ShipmentsManagement/CreateShipment/ConnectedCreateShipment';
import { CreateShipmentWizard } from './components/ShipmentsManagement/CreateShipmentWizard/CreateShipmentWizard';
import { EditShipmentWizard } from './components/ShipmentsManagement/EditShipmentWizard/EditShipmentWizard';
import { ConnectedUpdateShipment } from './components/ShipmentsManagement/UpdateShipment/ConnectedUpdateShipment';
import { ShipmentsOverview } from './components/ShipmentsOverview/ShipmentsOverview';

class Shipments extends Component {
  componentDidMount() {
    const { relatedCompany, userType } = this.props.auth.user;

    if (!isGlobalUserType(userType)) {
      this.props.getCompanyLocations(relatedCompany._id);
    }
  }

  componentWillUnmount() {
    this.props.clearAllPackagesOverview();
  }

  createShipmentWizardAccess = (shipments, user) => {
    if (isGlobalUserType(user.userType)) {
      return shipments.createIncoming && isStatusActive(user.status);
    }

    return (
      shipments.create && isStatusActive(user.status) && user.relatedCompany.materialCountRequired
    );
  };

  render() {
    const {
      access: { specialPermissions, packages, shipments, lots, selfDeliveryRequests },
      user,
    } = this.props.auth;
    const AuthLotDetails = Authorization(LotDetails, lots.details.details);
    const AuthLotUpdate = Authorization(ConnectedLotUpdate, lots.update);
    const AuthShipmentsOverview = Authorization(ShipmentsOverview, shipments.list);
    const AuthIncomingShipmentsList = Authorization(ConnectedIncomingShipmentsList, shipments.list);
    const AuthOutgoingShipmentsList = Authorization(ConnectedOutgoingShipmentsList, shipments.list);
    const AuthShipmentDetails = Authorization(ConnectedShipmentDetails, shipments.details);
    const AuthShipAndPrint = Authorization(
      ShipAndPrint,
      shipments.markAsSent || specialPermissions.shipments.createOutgoingShipment,
    );
    const AuthShipmentsCreate = Authorization(
      ConnectedCreateShipment,
      shipments.create && isStatusActive(user.relatedCompany.status),
    );
    const AuthUpdatePackage = Authorization(ConnectedUpdatePackage, packages.update);
    const AuthUpdateMultiPackages = Authorization(
      UpdateMultiplePackages,
      packages.updateIncoming.multiple,
    );
    const AuthUpdatePackageWeight = Authorization(
      ConnectedUpdatePackageWeight,
      packages.updateWeight || specialPermissions.processing.updatePackageWeight,
    );
    const AuthPackageDetails = Authorization(PackageDetails, packages.details);
    const AuthCreateIncomingShipment = Authorization(
      ConnectedCreateIncomingShipment,
      shipments.createIncoming,
    );
    const AuthCreateShipmentWizard = Authorization(
      CreateShipmentWizard,
      this.createShipmentWizardAccess(shipments, user),
    );
    const AuthEditShipmentWizard = Authorization(
      EditShipmentWizard,
      this.createShipmentWizardAccess(shipments, user),
    );
    const AuthCreateOutgoingShipment = Authorization(
      ConnectedCreateOutgoingShipment,
      shipments.createOutgoing || specialPermissions.shipments.createOutgoingShipment,
    );
    const AuthUpdateIncomingShipment = Authorization(
      ConnectedUpdateIncomingShipment,
      shipments.update.incoming,
    );
    const AuthUpdateOutgoingShipment = Authorization(
      ConnectedUpdateOutgoingShipment,
      shipments.update.outgoing || specialPermissions.shipments.createOutgoingShipment,
    );
    const AuthUpdateShipment = Authorization(ConnectedUpdateShipment, shipments.update.incoming);
    const AuthShipmentsCalendar = Authorization(ShipmentsCalendar, shipments.calendars);
    const AuthCreateSelfDeliveryRequest = Authorization(
      ConnectedCreateSelfDeliveryRequest,
      selfDeliveryRequests.create,
    );
    const AuthSelfDeliveryRequestDetails = Authorization(
      ConnectedSelfDeliveryRequestDetails,
      selfDeliveryRequests.details.details,
    );
    const AuthSelfDeliveryRequestsList = Authorization(
      ConnectedSelfDeliveryRequestsList,
      selfDeliveryRequests.list,
    );
    const AuthUpdateIncomingPackageForCustomer = Authorization(
      UpdateIncomingPackageForCustomer,
      packages.updateIncoming.forCustomer,
    );
    const AuthUpdateIncomingPackageForGlobal = Authorization(
      UpdateIncomingPackageForGlobal,
      packages.updateIncoming.forGlobal,
    );
    const AuthCreateIncomingPackages = Authorization(
      CreateMultiIncomingPackages,
      packages.create,
      '/shipments',
    );

    const AuthCreateCalendarNote = Authorization(ShipmentsOverview, shipments.calendarNote.create);

    return (
      <Switch>
        <Route exact path="/shipments" component={AuthShipmentsOverview} />
        <Route path="/shipments/create" component={AuthShipmentsCreate} />
        <Route path="/shipments/create-incoming" component={AuthCreateIncomingShipment} />
        <Route path="/shipments/create-incoming-wizard" component={AuthCreateShipmentWizard} />
        <Route path="/shipments/edit-incoming-wizard/:id" component={AuthEditShipmentWizard} />
        <Route path="/shipments/create-outgoing" component={AuthCreateOutgoingShipment} />
        <Route
          exact
          path="/shipments/self-delivery-requests"
          component={AuthSelfDeliveryRequestsList}
        />
        <Route exact path="/shipments/calendar-notes" component={AuthCreateCalendarNote} />
        <Route exact path="/shipments/list" component={AuthIncomingShipmentsList} />
        <Route exact path="/shipments/incoming" component={AuthIncomingShipmentsList} />
        <Route exact path="/shipments/incoming/calendar" component={AuthShipmentsCalendar} />
        <Route
          exact
          path="/shipments/incoming/calendar/shipment/:id"
          component={AuthShipmentDetails}
        />
        <Route
          exact
          path="/shipments/incoming/calendar/shipment/:id/update"
          component={AuthUpdateOutgoingShipment}
        />
        <Route exact path="/shipments/incoming/calendar/lot/:id" component={AuthLotDetails} />
        <Route exact path="/shipments/incoming/calendar/lot/:id/update" component={AuthLotUpdate} />
        <Route exact path="/shipments/outgoing" component={AuthOutgoingShipmentsList} />
        <Route exact path="/shipments/outgoing/calendar" component={AuthShipmentsCalendar} />
        <Route
          exact
          path="/shipments/outgoing/calendar/shipment/:id"
          component={AuthShipmentDetails}
        />
        <Route
          exact
          path="/shipments/outgoing/calendar/shipment/:id/update"
          component={AuthUpdateOutgoingShipment}
        />
        <Route exact path="/shipments/outgoing/calendar/lot/:id" component={AuthLotDetails} />
        <Route exact path="/shipments/outgoing/calendar/lot/:id/update" component={AuthLotUpdate} />
        <Route exact path="/shipments/list/:id" component={AuthShipmentDetails} />
        <Route exact path="/shipments/incoming/:id" component={AuthShipmentDetails} />
        <Route exact path="/shipments/incoming/:id/update" component={AuthUpdateIncomingShipment} />
        <Route exact path="/shipments/outgoing/:id" component={AuthShipmentDetails} />
        <Route exact path="/shipments/outgoing/:id/ship-and-print" component={AuthShipAndPrint} />
        <Route exact path="/shipments/outgoing/:id/update" component={AuthUpdateOutgoingShipment} />
        <Route exact path="/shipments/:id" component={AuthShipmentDetails} />
        <Route exact path="/shipments/:id/update" component={AuthUpdateShipment} />
        <Route exact path="/shipments/packages/create" component={AuthCreateIncomingPackages} />
        <Route exact path="/shipments/packages/:id/update" component={AuthUpdatePackage} />
        <Route exact path="/shipments/packages/:id" component={AuthPackageDetails} />
        <Route
          exact
          path="/shipments/:shipmentId/packages/:id/update"
          component={AuthUpdatePackage}
        />
        <Route
          exact
          path="/shipments/:shipmentId/multipackages/:id/update"
          component={AuthUpdateMultiPackages}
        />
        <Route
          exact
          path="/shipments/:shipmentId/packages/:id/update/weight"
          component={AuthUpdatePackageWeight}
        />
        <Route exact path="/shipments/:shipmentId/packages/:id" component={AuthPackageDetails} />
        <Route
          exact
          path="/shipments/self-delivery-requests/create"
          component={AuthCreateSelfDeliveryRequest}
        />
        <Route
          exact
          path="/shipments/self-delivery-requests/calendar"
          component={AuthShipmentsCalendar}
        />
        <Route
          exact
          path="/shipments/self-delivery-requests/:id"
          component={AuthSelfDeliveryRequestDetails}
        />
        <Route
          exact
          path="/shipments/self-delivery-requests/calendar/self-delivery-request/:id"
          component={AuthSelfDeliveryRequestDetails}
        />
        <Route
          exact
          path="/shipments/:shipmentId/packages/:id/update/customer"
          component={AuthUpdateIncomingPackageForCustomer}
        />
        <Route
          exact
          path="/shipments/:shipmentId/packages/:id/update/global"
          component={AuthUpdateIncomingPackageForGlobal}
        />
        <Route
          exact
          path="/shipments/:shipmentId/packages/:id/update/weight/admin"
          component={props => <AuthUpdatePackageWeight {...props} superAdminMode />}
        />
        <Route component={NotFound} />
      </Switch>
    );
  }
}

export { Shipments };
