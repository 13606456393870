import {
  getNotificationsActionType,
  getNotificationsSuccessActionType,
  getNotificationsFailureActionType,
  clearNotificationsListActionType,
} from 'actions/Notifications/notificationsList';

const INITIAL_STATE = {
  isPending: false,
  errorMessage: null,
  notifications: [],
  input: {
    items: [],
    isPending: false,
  },
  resolvedNotReadCount: null,
  limit: 50,
  page: 1,
  query: '',
  pages: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getNotificationsActionType:
      return {
        ...state,
        isPending: true,
        limit: action.payload.limit,
        page: action.payload.page,
        query: action.payload.query,
        input: {
          items: state.input.items,
          isPending: true,
        },
      };
    case getNotificationsSuccessActionType:
      return {
        ...state,
        isPending: false,
        notifications: action.payload.notifications,
        pages: action.payload.pages,
        resolvedNotReadCount: action.payload.resolvedNotReadCount,
        input: {
          items: {
            docs: action.payload.notifications,
            pages: action.payload.pages,
          },
          isPending: false,
        },
      };
    case getNotificationsFailureActionType:
      return {
        ...state,
        isPending: false,
        errorMessage: action.payload.errorMessage,
        notifications: [],
        pages: null,
      };
    case clearNotificationsListActionType:
      return {
        isPending: false,
        errorMessage: null,
        notifications: null,
        input: [],
        resolvedNotReadCount: null,
        limit: 50,
        page: 1,
        query: '',
        pages: null,
      };
    default:
      return state;
  }
};
