import { resolveVersion, versions } from 'utils/versioning';

const globalRefiningGroupUserTypes = ['owner', 'support', 'warehouse'];
const globalRefiningGroupName = resolveVersion({
  [versions.alpha]: 'Alpha Shredding Group',
  [versions.global]: 'Global Refining Group',
  [versions.alpha]: 'Alpha Shredding Group',
  [versions.west]: 'Global Refining Group West',
});

export { globalRefiningGroupUserTypes, globalRefiningGroupName };
