import {
  processingTypes,
  processViaOptions,
} from 'shared/helpers/constants/lots/processingConstants';
import { statusesForGlobal } from 'shared/helpers/constants/lots/statusesForGlobal';

const getPackageProcessingType = ({ processVia, mixVia, statusForGlobal }) => {
  const postMillShearsLotStatuses = [
    statusesForGlobal.accepted_for_processing,
    statusesForGlobal.ready_for_mixing,
    statusesForGlobal.ready_to_ship,
  ];

  const postMixLotStatuses = [
    statusesForGlobal.accepted_for_mixing,
    statusesForGlobal.ready_to_ship,
  ];

  if (
    postMixLotStatuses.includes(statusForGlobal) &&
    Object.values(processViaOptions.mills).includes(mixVia)
  ) {
    return processingTypes.postMix;
  }

  if (postMillShearsLotStatuses.includes(statusForGlobal)) {
    if (Object.values(processViaOptions.mills).includes(processVia)) {
      return processingTypes.postMill;
    }
    if (Object.values(processViaOptions.shears).includes(processVia)) {
      return processingTypes.postShears;
    }
  }

  return null;
};

export { getPackageProcessingType };
