import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { downloadAttachment } from 'actions/Attachments/downloadAttachment';
import {
  clearAllPackagesOverview,
  clearPackagesOverview,
  getPackagesOverviewForShipments,
} from 'actions/Packages/packagesOverviewList';
import { getShipment, clearShipmentDetailsAction } from 'actions/Shipments/shipmentDetails';
import { showSnackbar } from 'actions/shared/snackbar';

import { ShipmentDetails } from './ShipmentDetails';

const mapStateToProps = state => ({
  auth: state.auth,
  downloadAttachmentStorage: state.downloadAttachmentStorage,
  packagesOverviewList: state.packagesOverviewList,
  markAsSentState: state.markAsSent,
  shipmentDetails: state.shipmentDetails,
  shipmentId: state.shipmentDetails.shipment && state.shipmentDetails.shipment._id,
});

const mapDispatchToProps = {
  getShipment,
  clearShipmentDetailsAction,
  downloadAttachment,
  getPackagesOverviewForShipments,
  clearPackagesOverview,
  clearAllPackagesOverview,
  showSnackbar,
};

const ConnectedShipmentDetails = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ShipmentDetails),
);

export { ConnectedShipmentDetails };
