import { FILES_CONSTANTS } from 'shared/helpers/constants/filesConstants';

const DILIGENCE_VALID_FILE_EXTENSIONS = [
  FILES_CONSTANTS.VALID_EXTENSIONS.doc,
  FILES_CONSTANTS.VALID_EXTENSIONS.docx,
  FILES_CONSTANTS.VALID_EXTENSIONS.rtf,
  FILES_CONSTANTS.VALID_EXTENSIONS.pdf,
  FILES_CONSTANTS.VALID_EXTENSIONS.jpg,
  FILES_CONSTANTS.VALID_EXTENSIONS.jpeg,
  FILES_CONSTANTS.VALID_EXTENSIONS.png,
].join(', ');

export { DILIGENCE_VALID_FILE_EXTENSIONS };
