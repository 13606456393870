import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { formValueSelector } from 'redux-form';

import {
  createPaymentExceptionCompany,
  filterPaymentExceptionCompaniesToAdd,
} from 'actions/Companies/privilegedCompaniesCreate';
import { openModal } from 'actions/shared/modal';
import { showSnackbar } from 'actions/shared/snackbar';

import { PaymentExceptionCompaniesCreate } from './ExceptionCompaniesCreate';

const selector = formValueSelector('PaymentExceptionCompaniesForm');

const mapStateToProps = ({ modal, ...rest }) => ({
  selectedCompany: selector(rest, 'relatedCompany'),
  modal,
});

const mapDispatchToProps = {
  createPaymentExceptionCompany,
  filterPaymentExceptionCompaniesToAdd,
  openModal,
  showSnackbar,
};

const ConnectedPaymentExceptionCompaniesCreate = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PaymentExceptionCompaniesCreate),
);

export { ConnectedPaymentExceptionCompaniesCreate };
