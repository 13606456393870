const uploadMaterialCountAccess = (
  {
    access: {
      lots: { materialCountUpload },
    },
  },
  lot,
) => materialCountUpload && lot.materialCountPriority;

export { uploadMaterialCountAccess };
