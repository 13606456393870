import {
  parseAttachmentActionType,
  parseAttachmentFailureActionType,
  parseAttachmentSuccessActionType,
} from 'actions/Attachments/parseAttachment';

const INITIAL_STATE = {
  isPending: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case parseAttachmentActionType:
      return {
        isPending: true,
      };
    case parseAttachmentFailureActionType:
    case parseAttachmentSuccessActionType:
      return {
        isPending: false,
      };
    default:
      return state;
  }
};
