const paymentsBreadcrumbsRoutes = {
  '/payments': () => (window.location.pathname.includes('outstanding') ? null : 'Payments'),
  '/payments/outstanding': 'Outstanding payments',
  '/payments/enter': 'Enter a payment',
  '/payments/:paymentId': 'Payment details',
  '/payments/request': null,
  '/payments/request/:type': 'Request payment',
  '/payments/payment-exception-companies': 'Payment exception companies',
  '/payments/payment-exception-companies/create': 'Add payment exception company',
  '/payments/payout-rules': 'Payout rules',
  '/payments/payout-rules/create': 'Add payout rule',
};

export { paymentsBreadcrumbsRoutes };
