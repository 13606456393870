import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { getCompanyLocations } from 'actions/Locations/getCompanyLocations';
import { getAssignedLots } from 'actions/Lots/getAssignedLots';
import { getPackage } from 'actions/Packages/packageDetails';
import { saveTemporaryPackageData } from 'actions/Packages/saveTemporaryPackageData';
import {
  updatePackage as updatePackageAction,
  updatePackageMaterialCount,
} from 'actions/Packages/updatePackage';
import { openModal, closeModal } from 'actions/shared/modal';
import { showSnackbar } from 'actions/shared/snackbar';

import { UpdatePackage } from './UpdatePackage';

function mapStateToProps({
  auth,
  router,
  modal,
  assignedLots,
  companyLocations,
  packageDetails,
  updatePackage,
}) {
  return {
    auth,
    router,
    modal,
    assignedLots,
    companyLocations,
    packageDetails,
    updatePackage,
  };
}

const mapDispatchToProps = {
  closeModal,
  updatePackageAction,
  updatePackageMaterialCount,
  getPackage,
  showSnackbar,
  getAssignedLots,
  getCompanyLocations,
  openModal,
  saveTemporaryPackageData,
};

const ConnectedUpdatePackage = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UpdatePackage),
);

export { ConnectedUpdatePackage };
