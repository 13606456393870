import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import {
  getSummaryAvailableLots,
  clearSummaryAvailableLots,
} from 'actions/PoolAccounts/getSummaryAvailableLots';
import { settlementMockUpRequest } from 'actions/PoolAccounts/requestSettlement';
import { showSnackbar } from 'actions/shared/snackbar';

import { SettlementMockUp } from './SettlementMockUp';

const mapStateToProps = ({ auth, modal, summaryAvailableLots }) => ({
  auth,
  modal,
  summaryAvailableLots,
});

const mapDispatchToProps = {
  clearSummaryAvailableLots,
  getSummaryAvailableLots,
  settlementMockUpRequest,
  showSnackbar,
};

const ConnectedSettlementMockUp = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SettlementMockUp),
);

export { ConnectedSettlementMockUp };
