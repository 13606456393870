import PropTypes from 'prop-types';
import * as React from 'react';

import { humanizeCamelCase } from 'shared/helpers/parsers/text';

import * as S from '../SpecialPermissionsLogsStyles';

const LogsActionList = ({ data }) =>
  Object.entries(data).map(([key, value], i) => (
    <S.LogsActionItem key={`${key}-${i}`}>
      {humanizeCamelCase(key)}: {value}
    </S.LogsActionItem>
  ));

LogsActionList.propTypes = {
  data: PropTypes.object,
};

export { LogsActionList };
