import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import {
  cssVar,
  pxToRem,
  formatTemplate,
  splitTemplate,
  media,
} from 'shared/helpers/styling/styling';

const Header = styled.div`
  height: 60px;
  padding: 0 37px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${cssVar('alto')};
  background-color: ${cssVar('shark')};
  font-size: ${pxToRem(12)};
  font-weight: 700;
  background-color: ${({ bgColor }) => bgColor || cssVar('shark')};
  @media print {
    border-bottom: 2px solid ${cssVar('blackGRG')};
    padding: 0 15px;
  }
  ${({ printHeader }) =>
    printHeader &&
    css`
      display: none;
      @media print {
        display: flex;
      }
    `}
`;

const SubHeader = styled.span`
  font-size: ${pxToRem(10)};

  ${media.tablet`
      display: none;
   `};
`;

const StyledHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const DetailsContent = styled.div`
  display: grid;
  gap: 1px;
  grid-gap: 1px;

  @media print {
    gap: 15px;
    padding: 15px;
  }

  background-color: ${cssVar('riverBedLighter')};
  grid-template-columns: repeat(4, 1fr);
  grid-template-areas: ${({ template }) => formatTemplate(template)};

  ${media.phone`
   grid-template-columns: 1fr;
     grid-template-areas: ${({ template }) =>
       formatTemplate(splitTemplate(splitTemplate(template)))};
   `};

  ${media.tablet`
   grid-template-columns: 1fr 1fr;
     grid-template-areas: ${({ template }) => formatTemplate(splitTemplate(template))};
   `};
`;

const DetailsContainerLayout = ({
  className,
  header,
  template,
  children,
  printHeader,
  config,
  secondHeader,
  subHeader,
}) => (
  <div className={className}>
    {(header || printHeader) && (
      <Header bgColor={config?.headerBgColor} printHeader={printHeader}>
        <StyledHeaderContainer>
          {header || printHeader}
          {subHeader && <SubHeader>{subHeader}</SubHeader>}
        </StyledHeaderContainer>
        {secondHeader}
      </Header>
    )}
    <DetailsContent config={config} template={template}>
      {children}
    </DetailsContent>
  </div>
);

const DetailsContainer = styled(DetailsContainerLayout)`
  @media print {
    border: 2px solid ${cssVar('blackGRG')};
    margin: 20px 0;
    break-inside: avoid;
  }
  ${({ config }) =>
    !config?.noMargin &&
    css`
      margin-bottom: 30px;

      @media print {
        margin-bottom: 0;
      }
    `};
`;

DetailsContainer.propTypes = {
  header: PropTypes.string,
  printHeader: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export { DetailsContainer, Header };
