import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import {
  shipmentsFiltersClearAction,
  estPickupDateToFilterAction,
  estPickupDateFromFilterAction,
  estDeliveryDateToFilterAction,
  estDeliveryDateFromFilterAction,
  statusFilterAction,
} from 'actions/Shipments/shipmentsFilters';
import { getShipmentsList, clearShipmentsListAction } from 'actions/Shipments/shipmentsList';

import { OutgoingShipmentsList } from './OutgoingShipmentsList';

const mapStateToProps = state => ({
  auth: state.auth,
  router: state.router,
  shipmentsFilters: state.shipmentsFilters,
  shipmentsList: state.shipmentsList,
  companyLocations: state.companyLocations,
});

const mapDispatchToProps = {
  getShipmentsList,
  clearShipmentsListAction,
  statusFilterAction,
  shipmentsFiltersClearAction,
  estPickupDateToFilterAction,
  estPickupDateFromFilterAction,
  estDeliveryDateToFilterAction,
  estDeliveryDateFromFilterAction,
};

const ConnectedOutgoingShipmentsList = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OutgoingShipmentsList),
);

export { ConnectedOutgoingShipmentsList };
