import {
  checkLotPrefixActionType,
  checkLotPrefixSuccessActionType,
  checkLotPrefixFailureActionType,
} from 'actions/Companies/checkLotPrefix';

const INITIAL_STATE = {
  isPending: false,
  result: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case checkLotPrefixActionType:
      return {
        isPending: true,
        result: null,
      };
    case checkLotPrefixSuccessActionType:
      return {
        isPending: false,
        result: action.payload.result.data,
      };
    case checkLotPrefixFailureActionType:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};
