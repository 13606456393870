import {
  addLotToSettlementActionType,
  removeLotFromSettlementActionType,
  requestLotsSettlementActionType,
  requestLotsSettlementSuccessActionType,
  requestLotsSettlementFailureActionType,
  removeAllLotsForSettlementActionType,
} from 'actions/Payments/requestLotsSettlement';

const INITIAL_STATE = {
  isPending: false,
  lots: [],
};

const RequestLotsSettlementReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case addLotToSettlementActionType:
      return {
        ...state,
        lots: [...new Set([...state.lots].concat(action.payload.id))],
      };
    case removeLotFromSettlementActionType:
      return {
        ...state,
        lots: state.lots.filter(lotId => lotId !== action.payload.id),
      };
    case requestLotsSettlementActionType:
      return {
        ...state,
        isPending: true,
      };
    case removeAllLotsForSettlementActionType:
    case requestLotsSettlementSuccessActionType:
    case requestLotsSettlementFailureActionType:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};

export { RequestLotsSettlementReducer };
