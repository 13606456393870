import PropTypes from 'prop-types';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { destroy } from 'redux-form';

import { downloadAttachment } from 'actions/Attachments/downloadAttachment';
import { getPackagesOverviewForShipments } from 'actions/Packages/packagesOverviewList';
import { assignedViewPrintPackageLabels } from 'actions/Packages/printPackageLabels';
import { updateMultiplePackages } from 'actions/Packages/updateMultiplePackages';
import { openModal } from 'actions/shared/modal';
import { showSnackbar } from 'actions/shared/snackbar';

import { Preloader } from 'shared/components/Preloader/Preloader';
import { Wizard } from 'shared/components/Wizard/Wizard';
import { cancelModal } from 'shared/helpers/constants/modalConstants';
import { materialTypesPackingList } from 'shared/helpers/constants/packages/materialType';
import {
  overviewPackageClasses,
  packageClasses,
} from 'shared/helpers/constants/packages/packageClasses';
import { PACKAGES } from 'shared/helpers/constants/packages/packageConstants';
import { packageStatuses } from 'shared/helpers/constants/packages/packageType';
import { dangerOptions } from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { parseEditMultiplePackagesData } from 'shared/helpers/dataParsers/packages/parseMultiplePackagesData';
import { setPlaceholder } from 'shared/helpers/dataParsers/packages/setPlaceholder';
import { getPackagesToPrint } from 'shared/helpers/dataParsers/shipments';
import { snackbarOptions } from 'shared/helpers/matchers/snackbar/snackbarOptions';
import { humanize } from 'shared/helpers/parsers/text';

import { ConnectedMultiPackagesForm } from 'pages/Packages/components/PackagesManagement/components/MultiPackagesCreate/MultiPackagesCreate';
import { configNames } from 'pages/Packages/components/PackagesManagement/components/PackageForm/components/PackageForm/fieldsConfig';
import { GeneratePackingListForm } from 'pages/Shipments/components/ShipmentsManagement/CreateShipmentWizard/components/GeneratePackingListForm/GeneratePackingListForm';

const steps = {
  packages: ConnectedMultiPackagesForm,
  generatePackingListValues: GeneratePackingListForm,
};

const UpdateMultiplePackages = props => {
  const dispatch = useDispatch();
  const [incomingPkgIsPending, setIncomingPkgIsPending] = useState(true);
  const history = useHistory();
  const { params } = props.match;

  const isPending = useSelector(({ packagesOverviewList }) => packagesOverviewList.isPending);
  const updatePackages = useSelector(({ updateMultiplePackages }) => updateMultiplePackages);
  const selectedPackages = useSelector(({ shipmentPackagesListData }) => shipmentPackagesListData);
  const incomingPackagesList = useSelector(
    ({ packagesOverviewList }) => packagesOverviewList.packagesList.incoming,
  );
  const { editedPackage, editMultipackagesMode } = useSelector(
    ({ packagesOverviewList }) => packagesOverviewList.temporaryData,
  );

  const selectEditedPackage = incomingPackagesList?.docs.find(pkg => pkg._id === editedPackage?.id);
  const newEditedPackage = Object.assign({}, selectEditedPackage, editedPackage);
  const index = incomingPackagesList?.docs.findIndex(pkg => pkg._id === newEditedPackage._id);

  const updatedPackagesList = incomingPackagesList?.docs;
  updatedPackagesList && updatedPackagesList.splice(index, 1, newEditedPackage);
  const parsedPackagesList =
    updatedPackagesList &&
    setPlaceholder(
      parseEditMultiplePackagesData(updatedPackagesList),
      editedPackage?.assignedLot,
      packageStatuses.deleted,
    );

  const initialValues = {
    oldValues: {},
    defaultPackagesValues: {
      oneOrMultiLots: {
        label: humanize(PACKAGES.LOTS_FOR_PACKAGES.SAME_LOT),
        value: PACKAGES.LOTS_FOR_PACKAGES.SAME_LOT,
      },
      assignedLot: editedPackage?.assignedLot,
      materialTypeDeclared: {
        label: humanize(materialTypesPackingList.catalytic_converters),
        value: materialTypesPackingList.catalytic_converters,
      },
    },
    createShipment: editedPackage?.assignedShipment,
    generatePackingListValues: {
      printPackingList: true,
      exportToExcel: false,
      printPackageLabels: false,
      markShipmentAsSent: false,
    },
  };

  useEffect(() => {
    if (!editMultipackagesMode) {
      history.goBack();
    }

    dispatch(
      getPackagesOverviewForShipments(params.shipmentId, 0, overviewPackageClasses.incoming),
    ).then(() => setIncomingPkgIsPending(false));

    return () => {
      dispatch(destroy('wizard'));
    };
  }, [dispatch, history]);

  const handleSubmit = async values => {
    try {
      const result = await dispatch(
        updateMultiplePackages({
          ...values,
          defaultPackagesValues: initialValues.defaultPackagesValues,
          createShipment: { ...editedPackage?.assignedShipment },
        }),
      );

      if (
        values.generatePackingListValues.printPackageLabels &&
        selectedPackages.packages.length > 0
      ) {
        const packagesToPrint = getPackagesToPrint(
          result.shipmentPackages,
          selectedPackages.packages,
        );

        dispatch(
          assignedViewPrintPackageLabels(
            'shipments',
            packageClasses.incoming,
            editedPackage?.assignedShipment._id,
            packagesToPrint,
          ),
        );
      }

      dispatch(showSnackbar(snackbarOptions(result.messageType), result.message));

      await Promise.all(
        result.files.map(filePath => dispatch(downloadAttachment(filePath))),
      ).finally(history.push(`/shipments/${result.id}`));
    } catch (err) {
      dispatch(showSnackbar(dangerOptions, err.message));
    }
  };

  const modalOpen = useCallback(() => {
    dispatch(
      openModal(cancelModal, () => {
        history.goBack();
      }),
    );
  }, [openModal, history]);

  return (
    <>
      <Wizard
        steps={steps}
        header={PACKAGES.UPDATE_PACKAGE}
        onSubmit={handleSubmit}
        onCancel={modalOpen}
        contextProps={{
          config: configNames.editMultiplePackages,
          shipmentPackagesLength: parsedPackagesList?.length,
          assignedPackages: parsedPackagesList || [],
          packageCountIncoming: editedPackage?.packageCountIncoming,
          incomingPkgIsPending,
        }}
        initialValues={initialValues}
      />
      <Preloader loading={isPending || updatePackages.isPending} />
    </>
  );
};

UpdateMultiplePackages.propTypes = {
  history: PropTypes.object,
  location: PropTypes.shape({
    hash: PropTypes.string,
    key: PropTypes.string,
    pathname: PropTypes.string,
    search: PropTypes.string,
    state: PropTypes.oneOf([PropTypes.object, undefined]),
  }),
  match: PropTypes.shape({
    isExact: PropTypes.bool,
    params: PropTypes.shape({
      shipmentId: PropTypes.string,
      id: PropTypes.string,
    }),
    path: PropTypes.string,
    url: PropTypes.string,
  }),
  staticContext: PropTypes.oneOf([PropTypes.object, undefined]),
};

export { UpdateMultiplePackages };
