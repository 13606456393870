import React, { Component } from 'react';

import { FullPageList } from 'shared/components/FullPageList/FullPageList';
import {
  itemsTemplate,
  itemsTemplateGrading,
  itemsDetailsTemplate,
  itemsDetailsTemplateGrading,
  itemsTemplateMobile,
} from 'shared/helpers/constants/selfDeliveryRequests/seflDeliveryRequestListTableConfig';
import { SELF_DELIVERY_REQUESTS } from 'shared/helpers/constants/selfDeliveryRequests/selfDeliveryRequestsConstants';
import { isGradingUser } from 'shared/helpers/matchers/checkUserType';

import { SelfDeliveryRequestsFilters } from './components/SelfDeliveryRequestsFilters/SelfDeliveryRequestsFilters';

class SelfDeliveryRequestsList extends Component {
  componentDidMount() {
    if (!isGradingUser(this.props.auth.user.userType)) {
      this.props.getSelfDeliveryRequestsCompanies();
    }
  }

  componentWillUnmount() {
    this.props.clearSelfDeliveryRequestsList();
  }

  onFiltersChanged = async filters => {
    const {
      selfDeliveryRequestsList: { limit },
      deliveryDateFromFilter,
      deliveryDateToFilter,
      relatedCompanyFilter,
      statusFilter,
    } = this.props;

    typeof filters.deliveryDateStart !== 'undefined' &&
      (await deliveryDateFromFilter(filters.deliveryDateStart));
    typeof filters.deliveryDateEnd !== 'undefined' &&
      (await deliveryDateToFilter(filters.deliveryDateEnd));
    await relatedCompanyFilter(filters.relatedCompany || '');
    await statusFilter(filters.status);

    await this.fetchList(1, limit);
  };

  get tableActions() {
    const {
      history,
      auth: {
        access: { selfDeliveryRequests },
      },
    } = this.props;

    const actions = [];

    if (selfDeliveryRequests.details.details) {
      actions.push({
        icon: 'arrow-right',
        action: 'Details',
        onClick: thisSelfDeliveryRequest => () => {
          history.push(`/shipments/self-delivery-requests/${thisSelfDeliveryRequest._id}`);
        },
      });
    }

    return actions;
  }

  fetchList = (page = 1, limit = 50, order = '') => {
    const {
      getSelfDeliveryRequestsList,
      selfDeliveryRequestsFilters,
      auth: { user },
    } = this.props;

    getSelfDeliveryRequestsList(limit, page, selfDeliveryRequestsFilters, user, order);
  };

  renderFilters = () => {
    const {
      selfDeliveryRequestsList: { page, limit },
      selfDeliveryRequestsFilters,
      getSelfDeliveryRequestsCompanies,
      companiesForFilters,
      relatedCompanyFilter,
      auth,
    } = this.props;

    return (
      <SelfDeliveryRequestsFilters
        filters={selfDeliveryRequestsFilters}
        pagination={{ page, limit }}
        onFiltersChanged={this.onFiltersChanged}
        auth={auth}
        companiesForFilters={companiesForFilters}
        getSelfDeliveryRequestsCompanies={getSelfDeliveryRequestsCompanies}
        relatedCompanyFilter={relatedCompanyFilter}
      />
    );
  };

  filtersClear = () => {
    const {
      getSelfDeliveryRequestsList,
      selfDeliveryRequestsFiltersClear,
      auth: { user },
      selfDeliveryRequestsList: { limit },
    } = this.props;
    const clearedFilters = {
      deliveryDate: { from: null, to: null },
      relatedCompany: '',
      status: [],
    };
    const order = '';

    getSelfDeliveryRequestsList(limit, 1, clearedFilters, user, order);
    selfDeliveryRequestsFiltersClear();
  };

  render() {
    const {
      selfDeliveryRequestsList: { selfDeliveryRequests, limit, page, pages, isPending },
      selfDeliveryRequestsFilters,
      auth: {
        user: { userType },
      },
    } = this.props;

    const { deliveryDate, relatedCompany, status } = selfDeliveryRequestsFilters;

    const filterAccess = true;
    const filtersSelected = deliveryDate.from || deliveryDate.to || relatedCompany || status.length;

    return (
      <FullPageList
        title={SELF_DELIVERY_REQUESTS.SELF_DELIVERY_REQUESTS}
        itemsTemplate={isGradingUser(userType) ? itemsTemplateGrading : itemsTemplate}
        itemsDetailsTemplate={
          isGradingUser(userType) ? itemsDetailsTemplateGrading : itemsDetailsTemplate
        }
        itemsTemplateMobile={itemsTemplateMobile}
        actionsTemplate={this.tableActions}
        items={selfDeliveryRequests || []}
        fetchList={this.fetchList}
        filterComponent={this.renderFilters}
        filterAccess={filterAccess}
        pagination={{ limit, page, pages }}
        filtersClear={this.filtersClear}
        filtersSelected={filtersSelected}
        isPending={isPending}
        filters={selfDeliveryRequestsFilters}
      />
    );
  }
}

export { SelfDeliveryRequestsList };
