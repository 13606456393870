import { CreateSelfDeliveryRequestReducer } from './createSelfDeliveryRequest';
import { SelfDeliveryRequestDetailsReducer } from './selfDeliveryRequestDetails';
import { SelfDeliveryRequestsFiltersReducer } from './selfDeliveryRequestsFilters';
import { SelfDeliveryRequestsListReducer } from './selfDeliveryRequestsList';
import { SelfDeliveryRequestsOverviewReducer } from './selfDeliveryRequestsOverview';

const selfDeliveryRequestsRootReducer = {
  createSelfDeliveryRequest: CreateSelfDeliveryRequestReducer,
  selfDeliveryRequestDetails: SelfDeliveryRequestDetailsReducer,
  selfDeliveryRequestsList: SelfDeliveryRequestsListReducer,
  selfDeliveryRequestsFilters: SelfDeliveryRequestsFiltersReducer,
  selfDeliveryRequestsOverview: SelfDeliveryRequestsOverviewReducer,
};

export { selfDeliveryRequestsRootReducer };
