import { replaceEmpty } from 'shared/helpers/parsers/text';

const DELIVERIES = {
  DELIVERIES: 'Deliveries',
  DELIVERY: 'Delivery',
  FORM_NAME: 'createDeliveriesForm',
  ASSIGN_HEDGE_OUNCES: 'Assign hedge ounces',
  HEDGE_OUNCES: 'Hedge ounces',
  ASSIGNMENT: 'Assignment',
  ADD: 'Add new assignment',
  REMOVE: 'Remove',
  RELATED_HEDGE: 'Related hedge',
  RELATED_LOT: 'Related lot',
  PLATINUM: 'Platinum',
  PALLADIUM: 'Palladium',
  RHODIUM: 'Rhodium',
  PLATINUM_OZ: 'Platinum (oz.)',
  PALLADIUM_OZ: 'Palladium (oz.)',
  RHODIUM_OZ: 'Rhodium (oz.)',
  OZ: 'oz',
  OUNCES: 'Ounces',
  PLACED_ON: 'Placed on',
  CREATED_ON: 'Created on',
  CREATED_BY: 'Created by',
  LOT: lot => `LOT ${replaceEmpty(lot)}`,
  PLATINUM_LEFT: 'Platinum left',
  PALLADIUM_LEFT: 'Palladium left',
  RHODIUM_LEFT: 'Rhodium left',
  SELECT: 'Select',
  PT: 'Pt',
  PD: 'Pd',
  RH: 'Rh',
  RELATED_LOT_EMPTY: 'Related lot is required.',
  SELECT_DELIVERY: 'Select delivery',
  SELECT_LOT: 'Select related lot',
  SELECTED_HEDGE: 'Selected hedge',
  HEDGE_NAME: 'Hedge name',
  TRANSFER_HEDGE_OUNCES_LOT_WARNING: (grgLotNumber, forOriginalLot = true) =>
    `NOTE: the final invoice for ${grgLotNumber} will need to be generated again in order for the blanket hedge ounces to be ${
      forOriginalLot ? 'removed from' : 'visible on'
    } the document.`,
};

export { DELIVERIES };
