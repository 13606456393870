import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getWarehouseCountData } from 'actions/Lots/getWarehouseDashboardCountData';

import { RouterButton } from 'shared/components/Buttons';
import { Icon } from 'shared/components/Icon/Icon';
import { OverviewListWrapper } from 'shared/components/OverviewListWrapper/OverviewListWrapper';
import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';
import { warehouseDashboardLabels } from 'shared/helpers/constants/lots/sections';

import { WarehouseDashboardContainer } from 'pages/Lots/components/LotsSections/components/LotsSection/components/WarehouseDashboardParts/WarehouseDashboardContainer';

const warehouseDashboardTemplate = [
  {
    label: warehouseDashboardLabels.allAssayLotsInPossession,
    render: ({ assayIncomingLots }) => assayIncomingLots,
  },
  {
    label: warehouseDashboardLabels.todaysGradingTransactions,
    render: ({ todaysGradingTransactions }) => todaysGradingTransactions,
  },
  {
    label: warehouseDashboardLabels.lotsPartlyReceivedOrOnHold,
    render: ({ partlyReceivedAndHoldLots }) => partlyReceivedAndHoldLots,
  },
];

const addVirtualLotBtn = (
  <RouterButton
    type="button"
    path="/lots/create-virtual-lot"
    label={LOTS.ADD}
    icon={<Icon icon="icon-plus" />}
  />
);

const WarehouseDashboard = ({ access, contentHide }) => {
  const { countWarehouseLots } = useSelector(
    ({ warehouseDashboardCountData }) => warehouseDashboardCountData,
  );
  const { isPending } = useSelector(({ updateVirtualLot }) => updateVirtualLot);
  const { isPending: colorPending } = useSelector(({ updateColor }) => updateColor);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getWarehouseCountData());
  }, [dispatch]);

  const list = {
    items: {
      docs: [countWarehouseLots],
      total: countWarehouseLots?.total || 1,
    },
  };

  return (
    <>
      {countWarehouseLots && (
        <OverviewListWrapper
          label={LOTS.DASHBOARD}
          list={list}
          itemsTemplate={warehouseDashboardTemplate}
          button={addVirtualLotBtn}
          access={access}
          pending={isPending || colorPending}
          disableTransform
          contentHide={contentHide}
        >
          <WarehouseDashboardContainer isPending={isPending || colorPending} />
        </OverviewListWrapper>
      )}
    </>
  );
};

WarehouseDashboard.propTypes = {
  access: PropTypes.bool,
  contentHide: PropTypes.bool,
};

WarehouseDashboard.defaultProps = {
  access: false,
  contentHide: false,
};

export { WarehouseDashboard };
