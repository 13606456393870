import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import {
  agreementDocumentAddedAction,
  agreementDocumentClearAction,
  uploadAgreementDocument,
} from 'actions/Agreements/uploadAgreementDocument';
import { showSnackbar } from 'actions/shared/snackbar';

import { ModalCustom } from 'components/ModalCustom/ModalCustom';

import { snackbarOptions } from 'shared/helpers/matchers/snackbar/snackbarOptions';

import { ConnectedAgreementUploadSigned } from 'pages/Agreements/components/AgreementsDetails/components/AgreementUploadSigned/ConnectedUploadSigned';

const Header = styled.h3`
  text-align: center;
`;

const AgreementsReuploadSignedLayout = ({
  open,
  toggle,
  title,
  agreementsDetails,
  agreementsUploadDocument,
  ...props
}) => {
  const resolveAction = result => {
    const messageType = snackbarOptions(result.messageType);
    props.showSnackbar(messageType, result.message);
  };

  const uploadFile = () => {
    if (agreementsUploadDocument.files) {
      props
        .uploadAgreementDocumentAction(
          agreementsDetails.agreement._id,
          agreementsUploadDocument.files,
        )
        .then(resolveAction)
        .then(toggle);
    }
  };

  return (
    <ModalCustom isOpen={open} modalClose={toggle}>
      <Header>{title}</Header>
      <ConnectedAgreementUploadSigned closeModal={toggle} onSubmit={uploadFile} />
    </ModalCustom>
  );
};

const mapStateToProps = state => ({
  agreementsUploadDocument: state.agreementsUploadDocument,
  agreementsDetails: state.agreementsDetails,
});

const mapActionsToProps = {
  uploadAgreementDocumentAction: uploadAgreementDocument,
  agreementDocumentAddedAction,
  agreementDocumentClearAction,
  showSnackbar,
};

const AgreementsReuploadSigned = connect(
  mapStateToProps,
  mapActionsToProps,
)(AgreementsReuploadSignedLayout);

export { AgreementsReuploadSigned };
