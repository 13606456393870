import { connect } from 'react-redux';

import { clearCompaniesList, getCompanies } from 'actions/Companies/companiesList';
import {
  companyStatusFilter,
  companyTypeFilter,
  companyFiltersClear,
  companyCountryFilter,
  companyStateFilter,
  companyDueDiligenceFilter,
} from 'actions/Companies/companyFilters';
import { generateReport as generateReportAction } from 'actions/Companies/generateReport';
import { getRegionList } from 'actions/Locations/getRegionList';

import { CompaniesList } from './CompaniesList';

const mapStateToProps = ({
  auth,
  router,
  companiesList,
  companyFilters,
  regionList,
  generateReport,
}) => ({
  auth,
  router,
  companiesList,
  companyFilters,
  generateReport,
  regionList,
});

const mapDispatchToProps = {
  getCompanies,
  getRegionList,
  generateReportAction,
  clearCompaniesList,
  companyStatusFilter,
  companyTypeFilter,
  companyDueDiligenceFilter,
  companyCountryFilter,
  companyStateFilter,
  companyFiltersClear,
};

export const ConnectedCompaniesList = connect(mapStateToProps, mapDispatchToProps)(CompaniesList);
