import { connect } from 'react-redux';

import { getLot } from 'actions/Lots/lotDetails';
import { markProcessingComplete } from 'actions/Lots/markProcessingComplete';
import { getLotsForProcessingOverview } from 'actions/Lots/processingOverview';
import { openModal } from 'actions/shared/modal';
import { showSnackbar } from 'actions/shared/snackbar';

import { MarkProcessingComplete } from './MarkProcessingComplete';

const mapDispatchToProps = {
  openModal,
  showSnackbar,
  markProcessingComplete,
  getLotsForProcessingOverview,
  getLot,
};

const ConnectedMarkProcessingComplete = connect(null, mapDispatchToProps)(MarkProcessingComplete);

export { ConnectedMarkProcessingComplete };
