import React, { Component } from 'react';

import { Button } from 'shared/components/Buttons/Button';
import { Icon } from 'shared/components/Icon/Icon';
import { cssVar } from 'shared/helpers/styling/styling';

import { OverviewItemDetails } from '../OverviewItemDetails/OverviewItemDetails';
import { OverviewListTableCell } from '../OverviewListTableCell/OverviewListTableCell';
import { OverviewTableRow } from '../OverviewTableRow/OverviewTableRow';

class OverviewTableItemMobile extends Component {
  state = {
    displayDetails: false,
  };

  toggleLotsDetails = () => {
    this.setState(prevState => ({
      displayDetails: !prevState.displayDetails,
    }));
  };

  render() {
    const {
      item,
      itemsTemplate,
      itemsTemplateMobile,
      templateProps,
      actionsTemplate,
      actionsContext,
      itemDetailsTemplate,
      itemFilter,
      displayAllDetails,
      fillContent,
      logs,
      customColor,
      isDisabled,
    } = this.props;

    const rowClassName =
      itemsTemplateMobile.className && itemsTemplateMobile.className.call(actionsContext, item);

    return (
      <React.Fragment>
        {!displayAllDetails && (
          <OverviewTableRow
            additionalClass={rowClassName}
            itemsNumber={itemsTemplateMobile.length}
            isOpen={this.state.displayDetails}
            onClick={this.toggleLotsDetails}
            itemFilter={itemFilter}
            item={item}
            header
            mobile
            overviewList
            customColor={customColor}
            isDisabled={isDisabled}
          >
            {itemsTemplateMobile.map((row, index) => (
              <OverviewListTableCell key={index} className={row.className || null}>
                {row.render(item, index, templateProps)}
              </OverviewListTableCell>
            ))}
            <Button
              borderRadius={100}
              margin={false}
              noOutline
              bgColor={cssVar('limedSpruceList')}
              padding="0"
              height={30}
              width={30}
              bgColorHover={cssVar('limedSpruceList')}
            >
              <Icon icon="icon-plus" height={this.state.displayDetails ? 1 : 14} width={14} />
            </Button>
          </OverviewTableRow>
        )}
        <OverviewItemDetails
          displayDetails={this.state.displayDetails}
          displayAllDetails={displayAllDetails}
          itemsTemplate={itemsTemplate}
          itemsTemplateMobile={itemsTemplateMobile}
          itemDetailsTemplate={itemDetailsTemplate}
          templateProps={templateProps}
          actionsTemplate={actionsTemplate}
          actionsContext={actionsContext}
          item={item}
          fillContent={fillContent}
          logs={logs}
          customColor={customColor}
          isDisabled={isDisabled}
        />
      </React.Fragment>
    );
  }
}

export { OverviewTableItemMobile };
