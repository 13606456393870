import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { clearNotesList, getNotes } from 'actions/Notes/notesList';
import { updateNote as updateNoteAction } from 'actions/Notes/updateNote';
import { openModal } from 'actions/shared/modal';
import { showSnackbar } from 'actions/shared/snackbar';

import { cancelModal } from 'shared/helpers/constants/modalConstants';
import { NOTE } from 'shared/helpers/constants/noteConstants';
import {
  dangerOptions,
  successOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

import { goBackOrTo } from 'utils/history';

import { NoteFormBase } from 'shared/components/Notes/CreateNotes/NoteFormBase/NoteFormBase';

import { StyledNoteHeader } from '../CreateProcessingNote/StyledCreateProcessingNote';
import { ProcessingNoteSchema } from '../CreateProcessingNote/ProcessingNoteYup';

const UpdateProcessingNote = () => {
  const dispatch = useDispatch();
  const { id, noteId } = useParams();

  const { isPending } = useSelector(
    ({ updateNoteStorage }) => updateNoteStorage,
  );
  const { notes } = useSelector(({ notesList }) => notesList);

  useEffect(() => {
    dispatch(
      getNotes({
        relatedType: NOTE.NOTES_TYPE.PROCESSING,
        relatedId: id,
        listLimit: 0,
        listPage: 1,
      }),
    );
  }, []);

  const navigateBack = () => {
    dispatch(clearNotesList());
    goBackOrTo('/lots');
  };

  const modalClose = useCallback(() => {
    dispatch(openModal(cancelModal, navigateBack));
  }, [navigator]);

  const editNote = useCallback(
    async value => {
      try {
        const { message } = await dispatch(
          updateNoteAction({
            noteId,
            note: value.note,
          }),
        );

        dispatch(showSnackbar(successOptions, message));
        navigateBack();
      } catch (error) {
        dispatch(showSnackbar(dangerOptions, error?.message));
      }
    },
    [modalClose],
  );

  return (
    <>
      <StyledNoteHeader>{NOTE.EDIT_PROCESSING_NOTE}</StyledNoteHeader>
      <NoteFormBase
        handleSubmitForm={editNote}
        closeModal={modalClose}
        isPending={isPending}
        noteDetails={
          notes[NOTE.NOTES_TYPE.PROCESSING]?.find(note => note._id === noteId)
            ?.note
        }
        validationSchema={ProcessingNoteSchema}
      />
    </>
  );
};
export { UpdateProcessingNote };
