import styled from 'styled-components';

import { media } from 'shared/helpers/styling/styling';

export const StyledLogoutButtonsPanel = styled.div`
  margin-top: 66px;
  display: flex;
  justify-content: center;

  button {
    ${media.phone`
    width: 90px;
    height: 37px;
    line-height: 0;
    `}

    ${media.tablet`
    width: 100px;
    height: 34px;
    line-height: 0;
    font-size: 14px;
    `}
  }
`;
