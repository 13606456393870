import React, { Component } from 'react';
import { connect } from 'react-redux';
import { formValueSelector, change, reset } from 'redux-form';

import { markLotAsReceived } from 'actions/Lots/markLotAsReceived';
import { uploadBillOfLading } from 'actions/Lots/uploadBillOfLading';
import { checkStatusPackageFactory } from 'actions/Packages/checkStatus';
import {
  updatePackageWeight,
  updatePackageWeighMaterialCount,
} from 'actions/Packages/updatePackage';
import { showSnackbar } from 'actions/shared/snackbar';

import { BarcodeScanner } from 'shared/components/BarcodeScanner/BarcodeScanner';
import { ScanningSummary } from 'shared/components/BarcodeScanner/components/ScanningSummary';
import { LOTS } from 'shared/helpers/constants/lots/lotsConstants';
import { packageClasses } from 'shared/helpers/constants/packages/packageClasses';
import {
  successOptions,
  dangerOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { parseLotsFilesToSend } from 'shared/helpers/dataParsers/files/parseMultipleFiles';

import { UpdatePackageWeightForm } from './components/UpdatePackageWeightForm';
import { UploadBillOfLadingScanner } from './components/UploadBillOfLadingScanner';

class ReceiveBarcodeScannerBase extends Component {
  state = {
    summary: null,
    packageId: null,
    isLastPackage: false,
    billOfLadingPending: false,
    isLastStep: false,
  };

  message = {
    success: LOTS.PACKAGE_SUCCESSFULLY_SCAN,
    error: LOTS.PACKAGE_SCANNING_ERROR,
    idle: LOTS.SCAN_PACKAGE_BARCODE,
    manualIdle: LOTS.ENTER_PACKAGE_BARCODE,
  };

  componentWillUnmount() {
    this.setState({
      billOfLadingPending: false,
    });

    this.props.reset('UpdatePackageWeightForm');
  }

  onFinish = () => {
    const { onSelfClose } = this.props;

    this.setState({ summary: null });

    onSelfClose();
  };

  changePackageStatus = async packageId => {
    try {
      const {
        lot: { _id: lotId },
        markLotAsReceivedState: { isPending },
      } = this.props;

      if (isPending) return;

      this.setState({ packageId });

      this.props.change('UpdatePackageWeightForm', 'lotId', lotId);
      this.props.change('UpdatePackageWeightForm', 'packageId', packageId);

      await this.props
        .checkStatusPackageFactory({ packageId, lotId })
        .then(({ message, isLastPackage }) => {
          this.setState({
            isLastPackage: isLastPackage,
            isLastStep: isLastPackage,
          });
          return message;
        });

      await this.props.markLotAsReceived(lotId, packageId).then(({ message, summary }) => {
        this.setState({ summary });

        this.props.showSnackbar(successOptions, message);
      });
    } catch (error) {
      return Promise.reject(error.message);
    }
  };

  updatePackageWeight =
    (onReset, goToIndex) =>
    async ({ skip }) => {
      try {
        const {
          lot: {
            billOfLading,
            sentFromCompany: { materialCountRequired },
          },
          updatePackageWeight,
          updatePackageWeighMaterialCount,
        } = this.props;
        const { isLastPackage, summary, packageId } = this.state;
        let actionAfterUpdate = summary?.received === summary?.total ? this.onFinish : onReset;

        const data = {
          weightGrossActual: this.props.updateWeightGrossActual,
          weightTareActual: this.props.updateWeightTareActual,
          packageClass: packageClasses.incoming,
          id: packageId,
        };

        if (skip) {
          this.props.reset('UpdatePackageWeightForm');

          return actionAfterUpdate();
        }

        const updatePkgAction = materialCountRequired
          ? updatePackageWeighMaterialCount
          : updatePackageWeight;

        await updatePkgAction(data).then(({ message }) => {
          this.props.showSnackbar(successOptions, message);
          this.props.reset('UpdatePackageWeightForm');
        });

        if (isLastPackage && !billOfLading.length) {
          this.setState({
            isLastPackage: false,
          });

          if (skip) {
            this.props.change('UpdatePackageWeightForm', 'updateWeightGrossActual', null);
            this.props.change('UpdatePackageWeightForm', 'updateWeightTareActual', null);
          }

          return goToIndex(2);
        }

        this.setState({
          summary,
        });

        return actionAfterUpdate();
      } catch (err) {
        this.props.showSnackbar(dangerOptions, err.message);
      }
    };

  handleFinalSubmit =
    () =>
    async ({ skip }) => {
      try {
        const {
          lot: { _id: lotId },
          lotsFiles,
        } = this.props;

        this.setState({
          billOfLadingPending: true,
        });

        if (skip) {
          this.props.showSnackbar(successOptions, LOTS.ALL_PACKAGES_RECEIVED);

          return this.onFinish();
        }

        const filesToSend = parseLotsFilesToSend(lotsFiles);

        await this.props.uploadBillOfLading(lotId, filesToSend).then(({ message }) => {
          this.setState({
            billOfLadingPending: false,
          });

          this.props.showSnackbar(successOptions, message);
        });

        return this.onFinish();
      } catch (err) {
        this.setState({
          billOfLadingPending: false,
        });

        this.props.showSnackbar(dangerOptions, err.message);
      }
    };

  render() {
    const { summary, billOfLadingPending, isLastStep } = this.state;
    const {
      open,
      updatePackageState: { isPending },
      lot: { billOfLading, customLotNumber, grgLotNumber },
      billOfLadingFile,
      lotsFiles,
    } = this.props;

    const steps = [
      {
        icon: summary
          ? () => (
              <ScanningSummary
                done={summary.received}
                total={summary.total}
                doneLabel={LOTS.RECEIVED_PACKAGES}
                totalLabel={LOTS.NOT_RECEIVED_PACKAGES}
              />
            )
          : 'icon-box',
        name: 'packageId',
        barcodeLength: [8, 24],
        onChange: this.changePackageStatus,
      },
      {
        render: ({ onReset, goToIndex }) => {
          return (
            <UpdatePackageWeightForm
              isPending={isPending}
              onSubmit={this.updatePackageWeight(onReset, goToIndex)}
              lastStep={!isLastStep || billOfLading?.length}
            />
          );
        },
      },
      {
        render: ({ onReset, goToIndex }) => {
          return (
            <UploadBillOfLadingScanner
              onSubmit={this.handleFinalSubmit(onReset, goToIndex)}
              isPending={billOfLadingPending}
              lastStep
              grgLotNumber={customLotNumber || grgLotNumber}
              uploadBillOfLadingFile={billOfLadingFile}
              lotsFiles={lotsFiles}
            />
          );
        },
      },
    ];
    return (
      <BarcodeScanner
        open={open}
        onSelfClose={this.onFinish}
        message={this.message}
        idleStates={steps}
        availableCloseAction={!this.state.isLastStep}
      />
    );
  }
}

const selector = formValueSelector('UpdatePackageWeightForm');

const mapStateToProps = state => ({
  markLotAsReceivedState: state.markLotAsReceived,
  updatePackageState: state.updatePackage,
  updateWeightTareActual: selector(state, 'updateWeightTareActual'),
  updateWeightGrossActual: selector(state, 'updateWeightGrossActual'),
  packageId: selector(state, 'packageId'),
  lotId: selector(state, 'lotId'),
  incomingPackagesList: state.packagesOverviewList.packagesList[packageClasses.incoming],
  billOfLadingFile: state.uploadBillOfLadingFile,
  lotsFiles: state.lotsFiles,
});

const mapDispatchToProps = {
  markLotAsReceived,
  showSnackbar,
  updatePackageWeight,
  updatePackageWeighMaterialCount,
  change,
  reset,
  checkStatusPackageFactory,
  uploadBillOfLading,
};

const ReceiveBarcodeScanner = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReceiveBarcodeScannerBase);

export { ReceiveBarcodeScanner, ReceiveBarcodeScannerBase };
