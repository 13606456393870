import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { downloadApiAttachment } from 'actions/Attachments/downloadAttachment';
import { getReports } from 'actions/Reports/getReports';

import { GroupButtons } from 'shared/components/Buttons';
import { GroupButton } from 'shared/components/Buttons/GroupButton/components/GroupButton';
import { FullPageList } from 'shared/components/FullPageList/FullPageList';
import { reportsAccess } from 'shared/helpers/accesses/reportsAccess/reportsAccess';
import { REPORTS } from 'shared/helpers/constants/reports/reportsConstants';
import { itemsTemplate } from 'shared/helpers/constants/reports/reportsTableConfig';

import { GenerateReportsModal } from '../GenerateReports/GenerateReportsModal';

const renderActionButton = ({ user, access }, handleOpenModal) => (
  <GroupButtons>
    <GroupButton
      data-testid="generateReportActionButton"
      access={reportsAccess(user, access)}
      onClick={handleOpenModal}
      label={REPORTS.GENERATE}
      icon="add"
    />
  </GroupButtons>
);

const CustomerList = () => {
  const dispatch = useDispatch();
  const auth = useSelector(({ auth }) => auth);
  const { reports, isPending } = useSelector(({ reports }) => reports);
  const [displayGenerateReportsModal, setDisplayGenerateReportsModal] = useState(false);
  const downloadReportIsPending = useSelector(
    ({ downloadAttachmentStorage }) => downloadAttachmentStorage?.isPending,
  );

  useEffect(() => {
    dispatch(getReports());
  }, [dispatch]);

  const toggleOpenCloseModal = () => setDisplayGenerateReportsModal(!displayGenerateReportsModal);
  const actions = [
    {
      icon: 'download',
      action: 'Download',
      onClick:
        ({ _id }) =>
        () =>
          dispatch(downloadApiAttachment(`files/single/${_id}`)),
    },
  ];

  return (
    <>
      {reports && (
        <FullPageList
          title={REPORTS.ANALYSIS_REPORTS}
          actionButtons={() => renderActionButton(auth, toggleOpenCloseModal)}
          itemsTemplate={itemsTemplate}
          actionsTemplate={actions}
          items={reports[0]?.reports || []}
          dataTestId="report"
          isPending={isPending || downloadReportIsPending}
          disableInitialFetch
        />
      )}
      <GenerateReportsModal
        open={displayGenerateReportsModal}
        modalClose={toggleOpenCloseModal}
        companyId={auth.user.relatedCompany._id}
      />
    </>
  );
};

export { CustomerList };
