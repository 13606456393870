import FormData from 'form-data';

const parseNoteUpdateData = (note, files) => {
  const data = new FormData();

  data.append('note', note);

  if (files) {
    Object.keys(files).forEach(file => {
      if (files[file] !== null) {
        files[file].forEach(item => {
          data.append(file, item);
        });
      }
    });
  }

  return data;
};
export { parseNoteUpdateData };
