import axios from 'axios';

import { dateToTimestamp } from 'shared/helpers/parsers/date';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getFinalPaymentDateValidationActionType = 'GET_FINAL_PAYMENT_DATE_VALIDATION';
const getFinalPaymentDateValidationAction = () => ({
  type: getFinalPaymentDateValidationActionType,
});

const getFinalPaymentDateValidationSuccessActionType = `${getFinalPaymentDateValidationActionType}_SUCCESS`;
const getFinalPaymentDateValidationSuccessAction = paymentExists => ({
  type: getFinalPaymentDateValidationSuccessActionType,
  payload: { paymentExists },
});

const getFinalPaymentDateValidationFailureActionType = `${getFinalPaymentDateValidationActionType}_FAILURE`;
const getFinalPaymentDateValidationFailureAction = () => ({
  type: getFinalPaymentDateValidationFailureActionType,
});

const getFinalPaymentDateValidation = date => async dispatch => {
  dispatch(getFinalPaymentDateValidationAction());
  try {
    const { data } = await axios.get(
      `${API_HOST_AUTH}/payments/final-payment-date-exists/${dateToTimestamp(date)}`,
    );

    return dispatch(getFinalPaymentDateValidationSuccessAction(data.paymentExists));
  } catch (error) {
    dispatch(getFinalPaymentDateValidationFailureAction());

    return Promise.reject(error);
  }
};

export {
  getFinalPaymentDateValidation,
  getFinalPaymentDateValidationActionType,
  getFinalPaymentDateValidationSuccessActionType,
  getFinalPaymentDateValidationFailureActionType,
};
