import { resolveVersion, versions } from 'utils/versioning';

const ENTER_SETTLED_OUNCES = {
  ADD: 'Add another settlement',
  DETAILS: 'Details',
  DOLLAR: '$',
  OUNCES: 'Ounces',
  OUNCES_PD: 'Ounces palladium',
  OUNCES_PT: 'Ounces platinum',
  OUNCES_RH: 'Ounces rhodium',
  OZ: 'oz',
  PRICES_PER_OUNCE: 'Prices per ounces',
  PRICE_PER_OUNCE_PD: 'Price per ounce palladium',
  PRICE_PER_OUNCE_PT: 'Price per ounce platinum',
  PRICE_PER_OUNCE_RH: 'Price per ounce rhodium',
  RELATED_COMPANY: 'Related company',
  RELATED_LOT: 'Related lot',
  REQUESTED_BY: 'Requested by',
  REQUESTED_ON_DATE: 'Requested on date',
  REQUESTED_ON_TIME: 'Requested on time',
  SETTLEMENT: 'Settlement',

  OUNCES_PD_DO_NOT_MATCH: 'Palladium ounces do not match.',
  OUNCES_PT_DO_NOT_MATCH: 'Platinum ounces do not match.',
  OUNCES_RH_DO_NOT_MATCH: 'Rhodium ounces do not match.',

  VALIDATION: {
    ALL_OUNCES_ZERO: 'At least one ounces value has to be more than zero.',
    OUNCES_PD_EMPTY: 'Ounces palladium cannot be empty.',
    OUNCES_PD_FORMAT: 'Ounces palladium has invalid format.',
    OUNCES_PD_TOO_LONG: 'Ounces palladium is too long.',
    OUNCES_PD_ZERO: 'Ounces palladium cannot be less than 0.',
    OUNCES_PT_EMPTY: 'Ounces platinum cannot be empty.',
    OUNCES_PT_FORMAT: 'Ounces platinum has invalid format.',
    OUNCES_PT_TOO_LONG: 'Ounces platinum is too long.',
    OUNCES_PT_ZERO: 'Ounces platinum cannot be less than 0.',
    OUNCES_RH_EMPTY: 'Ounces rhodium cannot be empty.',
    OUNCES_RH_FORMAT: 'Ounces rhodium has invalid format.',
    OUNCES_RH_TOO_LONG: 'Ounces rhodium is too long.',
    OUNCES_RH_ZERO: 'Ounces rhodium cannot be less than 0.',
    PRICE_PER_OUNCE_PD_FORMAT: 'Price per palladium ounce has invalid format.',
    PRICE_PER_OUNCE_PD_NOT_MATCH: 'Prices per palladium ounce do not match.',
    PRICE_PER_OUNCE_PD_TOO_LONG: 'Price per palladium ounce is too long.',
    PRICE_PER_OUNCE_PD_ZERO: 'Price per palladium ounce cannot be less or equal 0.',
    PRICE_PER_OUNCE_PT_FORMAT: 'Price per platinum ounce has invalid format.',
    PRICE_PER_OUNCE_PT_NOT_MATCH: 'Prices per platinum ounce do not match.',
    PRICE_PER_OUNCE_PT_TOO_LONG: 'Price per platinum ounce is too long.',
    PRICE_PER_OUNCE_PT_ZERO: 'Price per platinum ounce cannot be less or equal 0.',
    PRICE_PER_OUNCE_RH_FORMAT: 'Price per rhodium ounce has invalid format.',
    PRICE_PER_OUNCE_RH_NOT_MATCH: 'Prices per rhodium ounce do not match.',
    PRICE_PER_OUNCE_RH_TOO_LONG: 'Price per rhodium ounce is too long.',
    PRICE_PER_OUNCE_RH_ZERO: 'Price per rhodium ounce cannot be less or equal 0.',
    RELATED_COMPANY_EMPTY: 'Related company cannot be empty.',
    RELATED_LOT_EMPTY: 'Related lot cannot be empty.',
    REQUESTED_BY_EMPTY: 'Requested by cannot be empty.',
    REQUESTED_ON_DATE_EMPTY: 'Requested on date cannot be empty.',
    REQUESTED_ON_DATE_FUTURE: 'Requested on date cannot be in the future.',
    REQUESTED_ON_TIME_EMPTY: 'Requested on time cannot be empty.',
  },
};

const SETTLEMENTS = {
  SETTLEMENTS: 'Settlements',
  TROY_OUNCES: 'Troy ounces settled',
  PRICES: 'Prices',
  PALLADIUM_PRICE: 'Price per ounce palladium',
  PLATINUM_PRICE: 'Price per ounce platinum',
  RHODIUM_PRICE: 'Price per ounce rhodium',
  OUNCES_PD: 'Ounces palladium',
  OUNCES_PT: 'Ounces platinum',
  OUNCES_RH: 'Ounces rhodium',
  REQUESTED_BY: 'Requested by',
  REQUESTED_ON: 'Requested on',
  CREATED_BY: 'Created by',
  UPDATED_BY: 'Updated by',
  UPDATED_ON: 'Updated on',
  ENTER_PRICES: 'Enter prices',
  SETTLEMENT_DATE_SWC: 'Settlement date SWC',
  SETTLEMENT_REQUEST_DATE_GRG: `Settlement request date ${resolveVersion({
    [versions.alpha]: 'ALP',
    [versions.global]: 'GRG',
    [versions.west]: 'GRG',
  })}`,
};

export { SETTLEMENTS, ENTER_SETTLED_OUNCES };
