import {
  addSettlementWizardItemActionType,
  deleteSettlementWizardItemActionType,
  clearSettlementWizardActionType,
} from 'actions/PoolAccounts/settlementForLotWizard';

const INITIAL_STATE = {
  data: {},
};

const cloneWizardData = ({ settlement, relatedLot, deliveries, ouncesRemoved }) => ({
  [relatedLot.value]: {
    settlement: { ...settlement, relatedLot: relatedLot.value },
    relatedLot: { ...relatedLot, sentFromCompany: { ...relatedLot.sentFromCompany } },
    deliveries: [
      ...deliveries.map(delivery => ({
        ...delivery,
        relatedHedge: { ...delivery.relatedHedge },
        relatedLot: relatedLot.value,
      })),
    ],
    ouncesRemoved: { ...ouncesRemoved },
  },
});

const requestSettlementWizardReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case addSettlementWizardItemActionType:
      const {
        applyBlanketHedges: { relatedLot, deliveries },
        settlement,
        ouncesRemoved,
      } = action.payload;

      return {
        data: {
          ...state.data,
          ...cloneWizardData({ settlement, relatedLot, deliveries, ouncesRemoved }),
        },
      };
    case deleteSettlementWizardItemActionType:
      const { [action.lotId]: removed, ...newData } = state.data;
      return {
        data: {
          ...Object.entries(newData).reduce(
            (acc, [lotId, items]) => ({
              ...acc,
              ...cloneWizardData(items),
            }),
            {},
          ),
        },
      };
    case clearSettlementWizardActionType:
      return {
        data: null,
      };
    default:
      return state;
  }
};

export { requestSettlementWizardReducer };
