import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { clearLotDetailsAction } from 'actions/Lots/lotDetails';
import {
  clearPackageDetailsAction,
  getGlobalCreatedPackage,
  getPackage,
} from 'actions/Packages/packageDetails';
import { showSnackbar } from 'actions/shared/snackbar';

import { Preloader } from 'shared/components/Preloader/Preloader';
import { PreloaderInline } from 'shared/components/PreloaderInline/PreloaderInline';
import { dangerOptions } from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { isCustomerUser, isGradingUser } from 'shared/helpers/matchers/checkUserType';

import { extractErrorMessage } from 'utils/extractErrorMessage';

import { PackageDetailsForm } from './components/PackageDetailsForm/PackageDetailsForm';

export const PackageDetails = () => {
  const userType = useSelector(state => state.auth.user.userType);
  const { pending: isPackageDetailsPending, package: pkg } = useSelector(
    state => state.packageDetails,
  );
  const isDeletePackagePending = useSelector(state => state.deletePackage.isPending);
  const dispatch = useDispatch();

  const { id, shipmentId, lotId } = useParams();
  const history = useHistory();

  useEffect(() => {
    try {
      if (isCustomerUser(userType) || isGradingUser(userType)) {
        dispatch(getPackage(id));
      } else {
        dispatch(getGlobalCreatedPackage(id));
      }
    } catch (error) {
      let redirectUrl = '/shipments';
      if (lotId) {
        redirectUrl = `/lots/list/${lotId}`;
      } else if (shipmentId) {
        redirectUrl = `/shipments/${shipmentId}`;
      }

      dispatch(showSnackbar(dangerOptions, extractErrorMessage(error)));
      history.push(redirectUrl);
    }
  }, []);

  useEffect(() => {
    return () => {
      dispatch(clearPackageDetailsAction());
      dispatch(clearLotDetailsAction());
    };
  }, []);

  return (
    <>
      {pkg && <PackageDetailsForm />}
      {isPackageDetailsPending && !pkg && <PreloaderInline loading />}
      {(isPackageDetailsPending || isDeletePackagePending) && pkg && <Preloader loading />}
    </>
  );
};
