import React from 'react';

import { UIAuthorization } from 'components/Authorization/UIAuthorization';

import { capitalize } from 'shared/helpers/parsers/text';

import { resolveVersion } from '.';
import { versions } from './config';

const isVersion = version =>
  resolveVersion(
    Object.values(versions)
      .map(currentVersion => [currentVersion, currentVersion === version])
      .reduce(
        (acc, [key, value]) => ({
          ...acc,
          [key]: value,
        }),
        {},
      ),
  );

const Guards = Object.entries(versions)
  .map(([name, version]) => [
    capitalize(name),
    ({ children }) => <UIAuthorization access={isVersion(version)}>{children}</UIAuthorization>,
  ])
  .reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: value,
    }),
    {},
  );

const isGlobal = isVersion(versions.global);
const isWest = isVersion(versions.west);
const isAlpha = isVersion(versions.alpha);

export { Guards, isGlobal, isWest, isAlpha };
