import { DetailsActivityField } from 'shared/components/Details/DetailsActivityField';
import { DetailsField } from 'shared/components/Details/DetailsField';
import { DetailsHumanizedField } from 'shared/components/Details/DetailsHumanizedField';
import { ASSAY } from 'shared/helpers/constants/lots/assayConstants';
import { formatToUS } from 'shared/helpers/parsers/formaters';

import { setCorrectLabel } from 'utils/setCorrectLabel';

const weightsFieldsConfig = assayResults => [
  {
    label: ASSAY.MATERIAL_TYPE,
    value: assayResults.materialType,
    icon: 'icon-cart',
    id: 'materialType',
    FieldType: DetailsHumanizedField,
  },
  {
    label: ASSAY.WEIGHT_GROSS_RECEIVED,
    value: formatToUS(assayResults.weightGrossReceived),
    suffix: ASSAY.LBS,
    icon: 'icon-volume',
    id: 'weightGrossReceived',
    FieldType: DetailsField,
  },
  {
    label: ASSAY.WEIGHT_TARE_RECEIVED,
    value: formatToUS(assayResults.weightTareReceived),
    suffix: ASSAY.LBS,
    icon: 'icon-volume',
    id: 'weightTareReceived',
    FieldType: DetailsField,
  },
  {
    label: ASSAY.WEIGHT_NET_WET_RECEIVED,
    value: formatToUS(assayResults.weightNetWetReceived),
    suffix: ASSAY.LBS,
    icon: 'icon-volume',
    id: 'weightNetWetReceived',
    FieldType: DetailsField,
  },
  {
    label: ASSAY.WEIGHT_SCRAP_RECEIVED,
    value: formatToUS(assayResults.weightScrapReceived),
    suffix: ASSAY.LBS,
    icon: 'icon-volume',
    id: 'weightScrapReceived',
    FieldType: DetailsField,
  },
  {
    label: ASSAY.WEIGHT_ADJUSTED_NET_WET_RECEIVED,
    value: formatToUS(assayResults.weightAdjustedNetWetReceived),
    suffix: ASSAY.LBS,
    icon: 'icon-volume',
    id: 'weightAdjustedNetWetReceived',
    FieldType: DetailsField,
  },
  {
    label: ASSAY.PERCENT_MOISTURE,
    value: assayResults.percentMoisture,
    suffix: ASSAY.PERCENT,
    icon: 'icon-count',
    id: 'percent',
    FieldType: DetailsField,
  },
  {
    label: ASSAY.WEIGHT_NET_DRY_RECEIVED,
    value: formatToUS(assayResults.weightNetDryReceived),
    suffix: ASSAY.LBS,
    icon: 'icon-volume',
    id: 'weightNetDryReceived',
    FieldType: DetailsField,
  },
  {
    label: setCorrectLabel(
      assayResults.breakdownOption,
      ASSAY.MATERIAL_TYPE_F,
      ASSAY.MATERIAL_TYPE_A,
    ),
    value: assayResults.materialTypeF,
    icon: 'icon-cart',
    id: 'materialTypeF',
    FieldType: DetailsHumanizedField,
  },
  {
    label: setCorrectLabel(
      assayResults.breakdownOption,
      ASSAY.WEIGHT_GROSS_RECEIVED_F,
      ASSAY.WEIGHT_GROSS_RECEIVED_A,
    ),
    value: formatToUS(assayResults.weightGrossReceivedF),
    suffix: ASSAY.LBS,
    icon: 'icon-volume',
    id: 'weightGrossReceivedF',
    FieldType: DetailsField,
  },
  {
    label: setCorrectLabel(
      assayResults.breakdownOption,
      ASSAY.WEIGHT_TARE_RECEIVED_F,
      ASSAY.WEIGHT_TARE_RECEIVED_A,
    ),
    value: formatToUS(assayResults.weightTareReceivedF),
    suffix: ASSAY.LBS,
    icon: 'icon-volume',
    id: 'weightTareReceivedF',
    FieldType: DetailsField,
  },
  {
    label: setCorrectLabel(
      assayResults.breakdownOption,
      ASSAY.WEIGHT_NET_WET_RECEIVED_F,
      ASSAY.WEIGHT_NET_WET_RECEIVED_A,
    ),
    value: formatToUS(assayResults.weightNetWetReceivedF),
    suffix: ASSAY.LBS,
    icon: 'icon-volume',
    id: 'weightNetWetReceivedF',
    FieldType: DetailsField,
  },
  {
    label: setCorrectLabel(
      assayResults.breakdownOption,
      ASSAY.WEIGHT_SCRAP_RECEIVED_F,
      ASSAY.WEIGHT_SCRAP_RECEIVED_A,
    ),
    value: formatToUS(assayResults.weightScrapReceivedF),
    suffix: ASSAY.LBS,
    icon: 'icon-volume',
    id: 'weightScrapReceivedF',
    FieldType: DetailsField,
  },
  {
    label: setCorrectLabel(
      assayResults.breakdownOption,
      ASSAY.WEIGHT_ADJUSTED_NET_WET_RECEIVED_F,
      ASSAY.WEIGHT_ADJUSTED_NET_WET_RECEIVED_A,
    ),
    value: formatToUS(assayResults.weightAdjustedNetWetReceivedF),
    suffix: ASSAY.LBS,
    icon: 'icon-volume',
    id: 'weightAdjustedNetWetReceivedF',
    FieldType: DetailsField,
  },
  {
    label: setCorrectLabel(
      assayResults.breakdownOption,
      ASSAY.PERCENT_MOISTURE_F,
      ASSAY.PERCENT_MOISTURE_A,
    ),
    value: assayResults.percentMoistureF,
    suffix: ASSAY.PERCENT,
    icon: 'icon-count',
    id: 'percentF',
    FieldType: DetailsField,
  },
  {
    label: setCorrectLabel(
      assayResults.breakdownOption,
      ASSAY.WEIGHT_NET_DRY_RECEIVED_F,
      ASSAY.WEIGHT_NET_DRY_RECEIVED_A,
    ),
    value: formatToUS(assayResults.weightNetDryReceivedF),
    suffix: ASSAY.LBS,
    icon: 'icon-volume',
    id: 'weightNetDryReceivedF',
    FieldType: DetailsField,
  },
  {
    label: setCorrectLabel(
      assayResults.breakdownOption,
      ASSAY.MATERIAL_TYPE_H,
      ASSAY.MATERIAL_TYPE_B,
    ),
    value: assayResults.materialTypeH,
    icon: 'icon-cart',
    id: 'materialTypeH',
    FieldType: DetailsHumanizedField,
  },
  {
    label: setCorrectLabel(
      assayResults.breakdownOption,
      ASSAY.WEIGHT_GROSS_RECEIVED_H,
      ASSAY.WEIGHT_GROSS_RECEIVED_B,
    ),
    value: formatToUS(assayResults.weightGrossReceivedH),
    suffix: ASSAY.LBS,
    icon: 'icon-volume',
    id: 'weightGrossReceivedH',
    FieldType: DetailsField,
  },
  {
    label: setCorrectLabel(
      assayResults.breakdownOption,
      ASSAY.WEIGHT_TARE_RECEIVED_H,
      ASSAY.WEIGHT_TARE_RECEIVED_B,
    ),
    value: formatToUS(assayResults.weightTareReceivedH),
    suffix: ASSAY.LBS,
    icon: 'icon-volume',
    id: 'weightTareReceivedH',
    FieldType: DetailsField,
  },
  {
    label: setCorrectLabel(
      assayResults.breakdownOption,
      ASSAY.WEIGHT_NET_WET_RECEIVED_H,
      ASSAY.WEIGHT_NET_WET_RECEIVED_B,
    ),
    value: formatToUS(assayResults.weightNetWetReceivedH),
    suffix: ASSAY.LBS,
    icon: 'icon-volume',
    id: 'weightNetWetReceivedH',
    FieldType: DetailsField,
  },
  {
    label: setCorrectLabel(
      assayResults.breakdownOption,
      ASSAY.WEIGHT_SCRAP_RECEIVED_H,
      ASSAY.WEIGHT_SCRAP_RECEIVED_B,
    ),
    value: formatToUS(assayResults.weightScrapReceivedH),
    suffix: ASSAY.LBS,
    icon: 'icon-volume',
    id: 'weightScrapReceivedH',
    FieldType: DetailsField,
  },
  {
    label: setCorrectLabel(
      assayResults.breakdownOption,
      ASSAY.WEIGHT_ADJUSTED_NET_WET_RECEIVED_H,
      ASSAY.WEIGHT_ADJUSTED_NET_WET_RECEIVED_B,
    ),
    value: formatToUS(assayResults.weightAdjustedNetWetReceivedH),
    suffix: ASSAY.LBS,
    icon: 'icon-volume',
    id: 'weightAdjustedNetWetReceivedH',
    FieldType: DetailsField,
  },
  {
    label: setCorrectLabel(
      assayResults.breakdownOption,
      ASSAY.PERCENT_MOISTURE_H,
      ASSAY.PERCENT_MOISTURE_B,
    ),
    value: assayResults.percentMoistureH,
    suffix: ASSAY.PERCENT,
    icon: 'icon-count',
    id: 'percentH',
    FieldType: DetailsField,
  },
  {
    label: setCorrectLabel(
      assayResults.breakdownOption,
      ASSAY.WEIGHT_NET_DRY_RECEIVED_H,
      ASSAY.WEIGHT_NET_DRY_RECEIVED_B,
    ),
    value: formatToUS(assayResults.weightNetDryReceivedH),
    suffix: ASSAY.LBS,
    icon: 'icon-volume',
    id: 'weightNetDryReceivedH',
    FieldType: DetailsField,
  },
];

const containedAssaysFieldsConfig = assayResults => [
  {
    label: ASSAY.PLATINUM_ASSAY,
    value: formatToUS(assayResults.platinumAssay, 1),
    suffix: ASSAY.PPM,
    icon: 'icon-volume',
    id: 'platinumAssay',
    FieldType: DetailsField,
  },
  {
    label: ASSAY.PALLADIUM_ASSAY,
    value: formatToUS(assayResults.palladiumAssay, 1),
    suffix: ASSAY.PPM,
    icon: 'icon-volume',
    id: 'palladiumAssay',
    FieldType: DetailsField,
  },
  {
    label: ASSAY.RHODIUM_ASSAY,
    value: formatToUS(assayResults.rhodiumAssay, 1),
    suffix: ASSAY.PPM,
    icon: 'icon-volume',
    id: 'rhodiumAssay',
    FieldType: DetailsField,
  },
  {
    label: ASSAY.CARBON_PERCENTAGE,
    value: formatToUS(assayResults.carbonPercentage, 3),
    suffix: ASSAY.PERCENT,
    icon: 'icon-volume',
    id: 'carbonPercentage',
    FieldType: DetailsField,
  },
  {
    label: setCorrectLabel(
      assayResults.breakdownOption,
      ASSAY.PLATINUM_ASSAY_F,
      ASSAY.PLATINUM_ASSAY_A,
    ),
    value: formatToUS(assayResults.platinumAssayF, 1),
    suffix: ASSAY.PPM,
    icon: 'icon-volume',
    id: 'platinumAssayF',
    FieldType: DetailsField,
  },
  {
    label: setCorrectLabel(
      assayResults.breakdownOption,
      ASSAY.PALLADIUM_ASSAY_F,
      ASSAY.PALLADIUM_ASSAY_A,
    ),
    value: formatToUS(assayResults.palladiumAssayF, 1),
    suffix: ASSAY.PPM,
    icon: 'icon-volume',
    id: 'palladiumAssayF',
    FieldType: DetailsField,
  },
  {
    label: setCorrectLabel(
      assayResults.breakdownOption,
      ASSAY.RHODIUM_ASSAY_F,
      ASSAY.RHODIUM_ASSAY_A,
    ),
    value: formatToUS(assayResults.rhodiumAssayF, 1),
    suffix: ASSAY.PPM,
    icon: 'icon-volume',
    id: 'rhodiumAssayF',
    FieldType: DetailsField,
  },
  {
    label: setCorrectLabel(
      assayResults.breakdownOption,
      ASSAY.CARBON_PERCENTAGE_F,
      ASSAY.CARBON_PERCENTAGE_A,
    ),
    value: formatToUS(assayResults.carbonPercentageF, 3),
    suffix: ASSAY.PERCENT,
    icon: 'icon-volume',
    id: 'carbonPercentageF',
    FieldType: DetailsField,
  },
  {
    label: setCorrectLabel(
      assayResults.breakdownOption,
      ASSAY.PLATINUM_ASSAY_H,
      ASSAY.PLATINUM_ASSAY_B,
    ),
    value: formatToUS(assayResults.platinumAssayH, 1),
    suffix: ASSAY.PPM,
    icon: 'icon-volume',
    id: 'platinumAssayH',
    FieldType: DetailsField,
  },
  {
    label: setCorrectLabel(
      assayResults.breakdownOption,
      ASSAY.PALLADIUM_ASSAY_H,
      ASSAY.PALLADIUM_ASSAY_B,
    ),
    value: formatToUS(assayResults.palladiumAssayH, 1),
    suffix: ASSAY.PPM,
    icon: 'icon-volume',
    id: 'palladiumAssayH',
    FieldType: DetailsField,
  },
  {
    label: setCorrectLabel(
      assayResults.breakdownOption,
      ASSAY.RHODIUM_ASSAY_H,
      ASSAY.RHODIUM_ASSAY_B,
    ),
    value: formatToUS(assayResults.rhodiumAssayH, 1),
    suffix: ASSAY.PPM,
    icon: 'icon-volume',
    id: 'rhodiumAssayH',
    FieldType: DetailsField,
  },
  {
    label: setCorrectLabel(
      assayResults.breakdownOption,
      ASSAY.CARBON_PERCENTAGE_H,
      ASSAY.CARBON_PERCENTAGE_B,
    ),
    value: formatToUS(assayResults.carbonPercentageH, 3),
    suffix: ASSAY.PERCENT,
    icon: 'icon-volume',
    id: 'carbonPercentageH',
    FieldType: DetailsField,
  },
];

const containedMetalsFieldsConfig = assayResults => [
  {
    label: ASSAY.PLATINUM_CONTAINED_OZ,
    value: formatToUS(assayResults.platinumContainedOz, 3),
    suffix: ASSAY.TROY_OZ,
    icon: 'icon-volume',
    id: 'platinumContainedOz',
    FieldType: DetailsField,
  },
  {
    label: ASSAY.PALLADIUM_CONTAINED_OZ,
    value: formatToUS(assayResults.palladiumContainedOz, 3),
    suffix: ASSAY.TROY_OZ,
    icon: 'icon-volume',
    id: 'palladiumContainedOz',
    FieldType: DetailsField,
  },
  {
    label: ASSAY.RHODIUM_CONTAINED_OZ,
    value: formatToUS(assayResults.rhodiumContainedOz, 3),
    suffix: ASSAY.TROY_OZ,
    icon: 'icon-volume',
    id: 'rhodiumContainedOz',
    FieldType: DetailsField,
  },
  {
    label: setCorrectLabel(
      assayResults.breakdownOption,
      ASSAY.PLATINUM_CONTAINED_OZ_F,
      ASSAY.PLATINUM_CONTAINED_OZ_A,
    ),
    value: formatToUS(assayResults.platinumContainedOzF, 3),
    suffix: ASSAY.TROY_OZ,
    icon: 'icon-volume',
    id: 'platinumContainedOzF',
    FieldType: DetailsField,
  },
  {
    label: setCorrectLabel(
      assayResults.breakdownOption,
      ASSAY.PALLADIUM_CONTAINED_OZ_F,
      ASSAY.PALLADIUM_CONTAINED_OZ_A,
    ),
    value: formatToUS(assayResults.palladiumContainedOzF, 3),
    suffix: ASSAY.TROY_OZ,
    icon: 'icon-volume',
    id: 'palladiumContainedOzF',
    FieldType: DetailsField,
  },
  {
    label: setCorrectLabel(
      assayResults.breakdownOption,
      ASSAY.RHODIUM_CONTAINED_OZ_F,
      ASSAY.RHODIUM_CONTAINED_OZ_A,
    ),
    value: formatToUS(assayResults.rhodiumContainedOzF, 3),
    suffix: ASSAY.TROY_OZ,
    icon: 'icon-volume',
    id: 'rhodiumContainedOzF',
    FieldType: DetailsField,
  },
  {
    label: setCorrectLabel(
      assayResults.breakdownOption,
      ASSAY.PLATINUM_CONTAINED_OZ_H,
      ASSAY.PLATINUM_CONTAINED_OZ_B,
    ),
    value: formatToUS(assayResults.platinumContainedOzH, 3),
    suffix: ASSAY.TROY_OZ,
    icon: 'icon-volume',
    id: 'platinumContainedOzH',
    FieldType: DetailsField,
  },
  {
    label: setCorrectLabel(
      assayResults.breakdownOption,
      ASSAY.PALLADIUM_CONTAINED_OZ_H,
      ASSAY.PALLADIUM_CONTAINED_OZ_B,
    ),
    value: formatToUS(assayResults.palladiumContainedOzH, 3),
    suffix: ASSAY.TROY_OZ,
    icon: 'icon-volume',
    id: 'palladiumContainedOzH',
    FieldType: DetailsField,
  },
  {
    label: setCorrectLabel(
      assayResults.breakdownOption,
      ASSAY.RHODIUM_CONTAINED_OZ_H,
      ASSAY.RHODIUM_CONTAINED_OZ_B,
    ),
    value: formatToUS(assayResults.rhodiumContainedOzH, 3),
    suffix: ASSAY.TROY_OZ,
    icon: 'icon-volume',
    id: 'rhodiumContainedOzH',
    FieldType: DetailsField,
  },
];

const returnableOuncesFieldsConfig = assayResults => [
  {
    label: ASSAY.PLATINUM_RETURNABLE_OZ,
    value: formatToUS(assayResults.platinumReturnableOz, 3),
    suffix: ASSAY.TROY_OZ,
    icon: 'icon-volume',
    id: 'platinumReturnableOz',
    FieldType: DetailsField,
  },
  {
    label: ASSAY.PALLADIUM_RETURNABLE_OZ,
    value: formatToUS(assayResults.palladiumReturnableOz, 3),
    suffix: ASSAY.TROY_OZ,
    icon: 'icon-volume',
    id: 'palladiumReturnableOz',
    FieldType: DetailsField,
  },
  {
    label: ASSAY.RHODIUM_RETURNABLE_OZ,
    value: formatToUS(assayResults.rhodiumReturnableOz, 3),
    suffix: ASSAY.TROY_OZ,
    icon: 'icon-volume',
    id: 'rhodiumReturnableOz',
    FieldType: DetailsField,
  },
  {
    label: setCorrectLabel(
      assayResults.breakdownOption,
      ASSAY.PLATINUM_RETURNABLE_OZ_F,
      ASSAY.PLATINUM_RETURNABLE_OZ_A,
    ),
    value: formatToUS(assayResults.platinumReturnableOzF, 3),
    suffix: ASSAY.TROY_OZ,
    icon: 'icon-volume',
    id: 'platinumReturnableOzF',
    FieldType: DetailsField,
  },
  {
    label: setCorrectLabel(
      assayResults.breakdownOption,
      ASSAY.PALLADIUM_RETURNABLE_OZ_F,
      ASSAY.PALLADIUM_RETURNABLE_OZ_A,
    ),
    value: formatToUS(assayResults.palladiumReturnableOzF, 3),
    suffix: ASSAY.TROY_OZ,
    icon: 'icon-volume',
    id: 'palladiumReturnableOzF',
    FieldType: DetailsField,
  },
  {
    label: setCorrectLabel(
      assayResults.breakdownOption,
      ASSAY.RHODIUM_RETURNABLE_OZ_F,
      ASSAY.RHODIUM_RETURNABLE_OZ_A,
    ),
    value: formatToUS(assayResults.rhodiumReturnableOzF, 3),
    suffix: ASSAY.TROY_OZ,
    icon: 'icon-volume',
    id: 'rhodiumReturnableOzF',
    FieldType: DetailsField,
  },
  {
    label: setCorrectLabel(
      assayResults.breakdownOption,
      ASSAY.PLATINUM_RETURNABLE_OZ_H,
      ASSAY.PLATINUM_RETURNABLE_OZ_B,
    ),
    value: formatToUS(assayResults.platinumReturnableOzH, 3),
    suffix: ASSAY.TROY_OZ,
    icon: 'icon-volume',
    id: 'platinumReturnableOzH',
    FieldType: DetailsField,
  },
  {
    label: setCorrectLabel(
      assayResults.breakdownOption,
      ASSAY.PALLADIUM_RETURNABLE_OZ_H,
      ASSAY.PALLADIUM_RETURNABLE_OZ_B,
    ),
    value: formatToUS(assayResults.palladiumReturnableOzH, 3),
    suffix: ASSAY.TROY_OZ,
    icon: 'icon-volume',
    id: 'palladiumReturnableOzH',
    FieldType: DetailsField,
  },
  {
    label: setCorrectLabel(
      assayResults.breakdownOption,
      ASSAY.RHODIUM_RETURNABLE_OZ_H,
      ASSAY.RHODIUM_RETURNABLE_OZ_B,
    ),
    value: formatToUS(assayResults.rhodiumReturnableOzH, 3),
    suffix: ASSAY.TROY_OZ,
    icon: 'icon-volume',
    id: 'rhodiumReturnableOzH',
    FieldType: DetailsField,
  },
];

const activityFieldsConfig = assayResults => [
  {
    label: ASSAY.CREATED_BY,
    user: assayResults.createdBy,
    date: assayResults.createdAt,
    id: 'createdBy',
    FieldType: DetailsActivityField,
  },
];

const weightsContainerConfig = () => ({
  header: ASSAY.WEIGHTS,
  template: [
    'materialType weightGrossReceived weightTareReceived weightNetWetReceived',
    'weightScrapReceived weightAdjustedNetWetReceived percent weightNetDryReceived',
    'materialTypeF weightGrossReceivedF weightTareReceivedF weightNetWetReceivedF',
    'weightScrapReceivedF weightAdjustedNetWetReceivedF percentF weightNetDryReceivedF',
    'materialTypeH weightGrossReceivedH weightTareReceivedH weightNetWetReceivedH',
    'weightScrapReceivedH weightAdjustedNetWetReceivedH percentH weightNetDryReceivedH',
  ],
});

const containedAssaysContainerConfig = () => ({
  header: ASSAY.CONTAINED_ASSAYS,
  template: [
    'platinumAssay platinumAssay palladiumAssay rhodiumAssay',
    'carbonPercentage carbonPercentage carbonPercentage carbonPercentage',
    'platinumAssayF platinumAssayF palladiumAssayF rhodiumAssayF',
    'carbonPercentageF carbonPercentageF carbonPercentageF carbonPercentageF',
    'platinumAssayH platinumAssayH palladiumAssayH rhodiumAssayH',
    'carbonPercentageH carbonPercentageH carbonPercentageH carbonPercentageH',
  ],
});

const containedMetalsContainerConfig = () => ({
  header: ASSAY.CONTAINED_METALS,
  template: [
    'platinumContainedOz platinumContainedOz palladiumContainedOz rhodiumContainedOz',
    'platinumContainedOzF platinumContainedOzF palladiumContainedOzF rhodiumContainedOzF',
    'platinumContainedOzH platinumContainedOzH palladiumContainedOzH rhodiumContainedOzH',
  ],
});

const returnableOuncesContainerConfig = () => ({
  header: ASSAY.RETURNABLE_OUNCES,
  template: [
    'platinumReturnableOz platinumReturnableOz palladiumReturnableOz rhodiumReturnableOz',
    'platinumReturnableOzF platinumReturnableOzF palladiumReturnableOzF rhodiumReturnableOzF',
    'platinumReturnableOzH platinumReturnableOzH palladiumReturnableOzH rhodiumReturnableOzH',
  ],
});

const activityContainerConfig = () => ({
  header: ASSAY.ACTIVITY,
  template: ['createdBy createdBy createdBy createdBy'],
});

export {
  weightsFieldsConfig,
  weightsContainerConfig,
  activityFieldsConfig,
  containedMetalsFieldsConfig,
  returnableOuncesFieldsConfig,
  containedAssaysFieldsConfig,
  containedAssaysContainerConfig,
  containedMetalsContainerConfig,
  returnableOuncesContainerConfig,
  activityContainerConfig,
};
