import axios from 'axios';

import { PACKAGES } from 'shared/helpers/constants/packages/packageConstants';
import { messageTypes } from 'shared/helpers/constants/snackbar/snackbarMessageTypes';
import { parsePackageData } from 'shared/helpers/dataParsers/packages/parsePackageData';

const API_HOST_AUTH = process.env.REACT_APP_API;

const createPackageActionType = 'CREATE_PACKAGE';
function createPackageAction() {
  return {
    type: createPackageActionType,
  };
}

const createPackageSuccessActionType = 'CREATE_PACKAGE_SUCCESS';
function createPackageSuccessAction() {
  return {
    type: createPackageSuccessActionType,
  };
}

const createPackageFailureActionType = 'CREATE_PACKAGE_FAILURE';
function createPackageFailureAction() {
  return {
    type: createPackageFailureActionType,
  };
}

const createPackageFactory = url => packages => dispatch => {
  dispatch(createPackageAction());

  return axios
    .post(`${API_HOST_AUTH}${url}`, JSON.stringify(parsePackageData(packages)))
    .then(res => {
      dispatch(createPackageSuccessAction());

      return {
        id: res.data.id,
        message: res.data.message,
        messageType: messageTypes.success,
      };
    })
    .catch(error => {
      dispatch(createPackageFailureAction());

      return Promise.reject({
        message: `${PACKAGES.CREATE_PACKAGE_ERROR} ${error.data.message}`,
        messageType: messageTypes.error,
      });
    });
};

const createPackage = createPackageFactory('/packages');
const createProcessingPackage = (values, packageType) =>
  createPackageFactory(`/packages/processing/${packageType}`)(values);

export {
  createPackageActionType,
  createPackageAction,
  createPackageSuccessActionType,
  createPackageSuccessAction,
  createPackageFailureActionType,
  createPackageFailureAction,
  createPackage,
  createProcessingPackage,
};
