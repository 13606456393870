import {
  deletePackageActionType,
  deletePackageSuccessActionType,
  deletePackageFailureActionType,
} from 'actions/Packages/deletePackage';

const INITIAL_STATE = {
  isPending: false,
};

const deletePackageReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case deletePackageActionType:
      return {
        isPending: true,
      };
    case deletePackageSuccessActionType:
    case deletePackageFailureActionType:
      return {
        isPending: false,
      };
    default:
      return state;
  }
};

export { deletePackageReducer };
