import styled from 'styled-components';

import { cssVar, pxToRem } from 'shared/helpers/styling/styling';

export const PermissionsHeader = styled.header`
  display: grid;
  grid-template-columns: 1fr 0.5fr;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  margin: 0;
  background-color: ${cssVar('shark')};
  padding: ${pxToRem(18)};
  color: ${cssVar('alto')};
  border-bottom: none;
`;

export const PermissionsH4 = styled.h4`
  font-size: 12px;
  font-weight: 500;
  margin: 0;
  color: ${cssVar('alto')};
`;
