import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getCompaniesContactActionType = 'GET_COMPANIES_CONTACT';
function getCompaniesContactAction() {
  return {
    type: getCompaniesContactActionType,
  };
}

const getCompaniesContactSuccessActionType = 'GET_COMPANIES_CONTACT_SUCCESS';
function getCompaniesContactSuccessAction(response) {
  return {
    type: getCompaniesContactSuccessActionType,
    payload: {
      response,
    },
  };
}

const getCompaniesContactFailureActionType = 'GET_COMPANIES_CONTACT_FAILURE';
function getCompaniesContactFailureAction() {
  return {
    type: getCompaniesContactFailureActionType,
  };
}

function getCompaniesContact(relatedCompany, potentialPrimaryContact = false) {
  return dispatch => {
    dispatch(getCompaniesContactAction());

    return axios({
      method: 'GET',
      url: `${API_HOST_AUTH}/users/contacts`,
      params: { value: relatedCompany.value, potentialPrimaryContact },
    })
      .then(response => {
        dispatch(getCompaniesContactSuccessAction(response.data));
      })
      .catch(() => {
        dispatch(getCompaniesContactFailureAction());
      });
  };
}

export {
  getCompaniesContactActionType,
  getCompaniesContactSuccessActionType,
  getCompaniesContactFailureActionType,
  getCompaniesContact,
  getCompaniesContactFailureAction,
};
