import { Switch, Route } from 'react-router';

import { Authorization } from 'components/Authorization/Authorization';

import { generalInfoAccess } from 'shared/helpers/accesses/generalInfoAccess';

import { GeneralInfoPage } from './components/GeneralInfoPage/GeneralInfoPage';

const GeneralInfo = ({ auth }) => {
  const AuthAccess = Authorization(GeneralInfoPage, generalInfoAccess(auth.user));

  return (
    <Switch>
      <Route path="/" component={AuthAccess} />
    </Switch>
  );
};

export { GeneralInfo };
