import { cssVar } from 'shared/helpers/styling/styling';

const dangerOptions = {
  borderColor: cssVar('wellRead'),
  icon: 'icon-error',
};

const successOptions = {
  borderColor: cssVar('acceptColor'),
  icon: 'icon-success',
};

const infoOptions = {
  borderColor: cssVar('alpine'),
  icon: 'icon-alert',
};

export { dangerOptions, successOptions, infoOptions };
