import { COMPANY } from 'shared/helpers/constants/companies/companyConstants';
import { replaceEmpty, removeCommasAndParse } from 'shared/helpers/parsers/text';
import { commaValidator } from 'shared/helpers/validations/commaValidator';
import { PRICE_REGEX } from 'shared/helpers/validations/regularExpressions';

const validate = ({ outstandingBalance }) => {
  const errors = {};

  if (replaceEmpty(outstandingBalance, '').length === 0) {
    errors.outstandingBalance = COMPANY.OUTSTANDING_BALANCE_EMPTY;
  } else if (!PRICE_REGEX.test(outstandingBalance)) {
    errors.outstandingBalance = COMPANY.OUTSTANDING_BALANCE_INVALID;
  } else if (outstandingBalance?.toString().length > 15) {
    errors.outstandingBalance = COMPANY.OUTSTANDING_BALANCE_INVALID;
  } else if (removeCommasAndParse(outstandingBalance) > 0) {
    errors.outstandingBalance = COMPANY.OUTSTANDING_BALANCE_RANGE;
  }

  commaValidator({ outstandingBalance: outstandingBalance }, errors);

  return errors;
};

export { validate };
