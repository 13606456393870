const companyDetailsCreateUserTypes = {
  owner: {
    standard: {
      assay: ['customer'],
      grading: ['grading'],
      external: ['external'],
      internal: {
        AlphaShreddingGroup: ['owner', 'support', 'warehouse', 'buyer'],
        GlobalRefiningGroup: ['owner', 'support', 'warehouse', 'buyer'],
        GlobalRefiningGroupWest: ['owner', 'support', 'warehouse', 'buyer'],
        internalCompanies: ['customer'],
      },
    },
    assist: {
      assay: ['customer'],
      grading: ['grading'],
      external: ['external'],
      internal: {
        AlphaShreddingGroup: ['support', 'warehouse'],
        GlobalRefiningGroup: ['support', 'warehouse'],
        GlobalRefiningGroupWest: ['support', 'warehouse'],
        internalCompanies: ['customer'],
      },
    },
  },
  support: {
    standard: {
      assay: ['customer'],
      grading: ['grading'],
      internal: {
        internalCompanies: ['customer'],
      },
    },
    assist: {
      assay: ['customer'],
      grading: ['grading'],
      internal: {
        internalCompanies: ['customer'],
      },
    },
  },
};

export { companyDetailsCreateUserTypes };
