import React, { Component } from 'react';

import { ContentLoader } from 'shared/components/ContentLoader/ContentLoader';
import { renderDetailsContainer } from 'shared/components/Details/helpers/renderDetailsContainer';
import { Preloader } from 'shared/components/Preloader/Preloader';
import { FILES_CONSTANTS } from 'shared/helpers/constants/filesConstants';
import { paymentTypes } from 'shared/helpers/constants/payments/paymentTypes';
import { dangerOptions } from 'shared/helpers/constants/snackbar/snackbarOptionTypes';
import { isGlobalUserType } from 'shared/helpers/matchers/isGlobalUserType';

import { extractErrorMessage } from 'utils/extractErrorMessage';

import { PaymentMainDetails } from './PaymentMainDetails/PaymentMainDetails';
import {
  activityContainerConfig,
  activityFieldsConfig,
  settlementFieldsConfig,
  paymentDetailsContainerConfig,
  paymentDetailsFieldsConfig,
  settlementContainerConfig,
} from './config/fieldsConfig';

class PaymentDetails extends Component {
  componentDidMount() {
    this.props
      .getPayment(this.props.match.params.id)
      .then(payment => payment || Promise.reject(null))
      .catch(error => {
        this.props.showSnackbar(dangerOptions, extractErrorMessage(error));
        return this.props.history.push('/payments');
      });
  }

  componentWillUnmount() {
    this.props.clearPaymentDetails();
  }

  render() {
    const {
      paymentDetails,
      auth,
      saveSharedData,
      downloadAttachment,
      deletePayment: { isPending: isDeleting },
      downloadAttachmentStorage,
    } = this.props;
    const { payment, isPending } = paymentDetails;

    return (
      <>
        <ContentLoader loaded={payment && !isPending && !isDeleting}>
          {payment && (
            <>
              <PaymentMainDetails payment={payment} auth={auth} saveSharedData={saveSharedData} />
              {renderDetailsContainer(
                paymentDetailsContainerConfig,
                paymentDetailsFieldsConfig,
                payment,
                downloadAttachment,
                auth,
              )}
              {isGlobalUserType(auth.user.userType) &&
                renderDetailsContainer(activityContainerConfig, activityFieldsConfig, payment)}
              {payment.paymentType === paymentTypes.pool &&
                renderDetailsContainer(
                  settlementContainerConfig,
                  settlementFieldsConfig,
                  payment.settlement,
                )}
            </>
          )}
        </ContentLoader>
        <Preloader
          loading={downloadAttachmentStorage.isPending}
          label={FILES_CONSTANTS.DOWNLOADING_FILES}
        />
      </>
    );
  }
}

export { PaymentDetails };
