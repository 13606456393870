import React from 'react';
import styled from 'styled-components';

import { Icon } from 'shared/components/Icon/Icon';
import { colors, eventTypes, CALENDAR_NOTE } from 'shared/helpers/constants/calendarConstants';
import { cssVar, pxToRem, media, isSmartphone } from 'shared/helpers/styling/styling';
import { useWindowWidth } from 'shared/hooks/useWindowWidth';

const resolveColor = type => {
  switch (type) {
    case eventTypes.incomingShipments:
      return colors.incomingShipments;
    case eventTypes.outgoingShipments:
      return colors.outgoingShipments;
    case eventTypes.selfDeliveryRequests:
    default:
      return colors.selfDeliveryRequests;
  }
};

const resolveEventType = type => {
  switch (type) {
    case eventTypes.incomingShipments:
    case eventTypes.selfDeliveryRequests:
      return (
        <>
          <Icon icon="icon-cart" />
          <Icon icon="icon-arrow-right" width={32} height={32} />
        </>
      );
    case eventTypes.outgoingShipments:
    default:
      return (
        <>
          <Icon icon="icon-arrow-left" width={32} height={32} />
          <Icon icon="icon-cart" />
        </>
      );
  }
};

const CalendarNoteEventLayout = ({ className, event }) => (
  <div className={`${className} noteEvent`}>
    <Icon icon="icon-calendar-note" />
    {!isSmartphone() && (
      <span>
        {event.count === 1 ? CALENDAR_NOTE.NOTE : `${CALENDAR_NOTE.NOTES} (${event.count})`}
      </span>
    )}
  </div>
);

const CalendarEventLayout = ({ className, event }) => {
  const browserWidth = useWindowWidth();

  return event.type === eventTypes.calendarNotes ? (
    <CalendarNoteEvent event={event} browserWidth={browserWidth} />
  ) : (
    <div className={className}>
      {resolveEventType(event.type)}
      <span>{event.count}</span>
    </div>
  );
};

const CalendarNoteEvent = styled(CalendarNoteEventLayout)`
  position: absolute;
  top: 150px;
  transform: translateX(-79px);
  width: 158px;
  height: 50px;
  border-top: 2px solid rgba(255, 255, 255, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  span {
    font-size: ${pxToRem(12)};
    color: ${cssVar('alabaster')};
  }

  ${media.smallDesktop`
    width: 132px;
    transform: translateX(-66px);
  `}

  ${media.mobile`
    flex-direction: column;
    width: 98px;
    padding: 10px 0;
    transform: translateX(-49px);
  `}

  ${media.smallMobile`
    width: 72px;
    transform: translateX(-36px);
  `}

  ${media.phone`
    ${({ browserWidth }) => `
      width: ${browserWidth * 0.143}px;
      transform: translateX(-${browserWidth * 0.07}px);
    `}
  `}
`;

const CalendarEvent = styled(CalendarEventLayout)`
  background-color: ${({ event }) => cssVar(resolveColor(event.type))};
  width: 105px;
  height: 36px;
  border-radius: 8px;
  color: ${cssVar('alabaster')};
  font-size: ${pxToRem(16)};
  font-weight: bold;
  display: flex;
  align-items: center;
  padding-left: 8px;
  cursor: pointer;

  :hover {
    opacity: 0.9;
  }

  span {
    margin-left: 4px;
  }

  ${media.mobile`
      width: 36px;
      justify-content: center;
      padding-left: 0;

      span {
        margin-left: 0;
      }

      i {
        display: none;
      }
    `}

  ${media.largeTablet`
      width: 80px;
    `}
`;

export { CalendarEvent };
