import PropTypes from 'prop-types';
import { useState, useEffect, useMemo } from 'react';

import { FormContainer } from 'shared/components/Form/FormContainer/FormContainer';
import { WizardContext } from 'shared/components/Formik/Wizard/WizardContext';
import { CustomActions } from 'shared/components/Formik/Wizard/components/CustomActions/CustomActions';
import { WizardIndicator } from 'shared/components/Formik/Wizard/components/WizardIndicator/WizardIndicator';
import { useEventListener } from 'shared/hooks/useEventListener';

const Wizard = ({ children, header, subHeader, onCancel }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [pageIndexes, setPageIndexes] = useState([]);

  const historySteps = useMemo(() => new Set([0]), []);

  useEffect(() => () => historySteps.clear(), [historySteps]);

  const stepsCount = pageIndexes.length;

  const changePage = pageIndex => {
    setCurrentStep(pageIndex);
    window.scrollTo(0, 0);
  };

  const updatePageIndexes = pageIndex => {
    if (pageIndexes.includes(pageIndex)) {
      return;
    }

    setPageIndexes(state => [...state, pageIndex]);
  };

  const wizardContextValue = useMemo(
    () => ({
      steps: stepsCount,
      isFirst: currentStep === 1,
      isLast: currentStep === stepsCount,
      currentStep,
      changePage,
      CustomActions,
      updatePageIndexes,
      prev: () => changePage(currentStep - 1),
      next: () => {
        changePage(currentStep + 1);
        historySteps.add(currentStep + 1);
      },
      onCancel,
    }),
    [currentStep, changePage, stepsCount, CustomActions, updatePageIndexes, onCancel],
  );

  const handleKeyDown = e => {
    e = e || window.event;

    if (e.altKey) {
      switch (e.keyCode) {
        case 37:
          if (!(currentStep - 1)) {
            e.preventDefault();
            return false;
          }

          if (currentStep - 1) {
            e.preventDefault();
            wizardContextValue.prev();
          }
          break;
        case 39:
          if (historySteps.has(currentStep + 1)) {
            wizardContextValue.next();
          }
          break;
        default:
      }
    }
  };

  const cancelOnEsc = event => {
    if (event.key === 'Escape') {
      return onCancel?.(event);
    }
  };

  useEventListener('keydown', cancelOnEsc);
  useEventListener('keydown', handleKeyDown);

  return (
    <WizardContext.Provider value={wizardContextValue}>
      <FormContainer
        header={header}
        subHeader={subHeader}
        actions={<WizardIndicator current={currentStep} steps={stepsCount} />}
        wizard
      >
        {children}
      </FormContainer>
    </WizardContext.Provider>
  );
};

Wizard.propTypes = {
  header: PropTypes.string,
  subHeader: PropTypes.string,
  children: PropTypes.element.isRequired,
  onCancel: PropTypes.func,
};

export { Wizard, WizardContext };
