import { deleteLotSuccessActionType } from 'actions/Lots/deleteLot';
import {
  appendLotsSectionSuccessActionType,
  clearLotsCountActionType,
  clearLotsSectionListActionType,
  countLotsActionType,
  countLotsFailureActionType,
  countLotsSuccessActionType,
  getLotsSectionActionType,
  getLotsSectionFailureActionType,
  getLotsSectionSuccessActionType,
} from 'actions/Lots/getLotsSection';

const INITIAL_STATE = {
  isPending: false,
  errorMessage: null,
  lots: {},
  lotsCount: {},
  items: {
    materialDeliveryRequired: { items: [] },
    open: { items: [] },
    received: { items: [] },
    summaryAvailable: { items: [] },
    settled: { items: [] },
    receiptReady: { items: [] },
    receiptAccepted: { items: [] },
    finalized: { items: [] },
  },
};

export default (state = INITIAL_STATE, action) => {
  const { lots } = state;
  const { lotsCount } = state;
  const { payload } = action;
  const lotsSection = {};
  let toSnakeCased;

  switch (action.type) {
    case getLotsSectionActionType:
      return {
        ...state,
        isPending: true,
      };
    case getLotsSectionSuccessActionType:
      lotsSection[payload.sectionType] = payload;

      return {
        ...state,
        isPending: false,
        lots: Object.assign(lots, lotsSection),
        items: {
          materialDeliveryRequired: lots.materialDeliveryRequired || [],
          open: lots.open || [],
          received: lots.received || [],
          summaryAvailable: lots.summaryAvailable || [],
          settled: lots.settled || [],
          receiptReady: lots.receiptReady || [],
          receiptAccepted: lots.receiptAccepted || [],
          finalized: lots.finalized || [],
        },
      };
    case getLotsSectionFailureActionType:
      return {
        ...state,
        isPending: false,
        errorMessage: action.payload.errorMessage,
      };
    case appendLotsSectionSuccessActionType:
      toSnakeCased = payload.sectionType;
      lots[toSnakeCased].lots = payload.lots;

      return {
        ...state,
        isPending: false,
        lots,
      };
    case countLotsActionType:
      return {
        ...state,
        isPending: true,
      };
    case countLotsSuccessActionType:
      return {
        ...state,
        isPending: false,
        lotsCount: payload.count.data,
      };
    case countLotsFailureActionType:
      return {
        ...state,
        isPending: false,
      };
    case clearLotsCountActionType:
      return {
        ...state,
        isPending: false,
        lotsCount: {},
      };
    case clearLotsSectionListActionType:
      lots[payload.sectionType] = null;

      return {
        ...state,
        isPending: false,
        errorMessage: null,
        lots,
        items: {
          materialDeliveryRequired: lots.materialDeliveryRequired || [],
          open: lots.open || [],
          received: lots.received || [],
          summaryAvailable: lots.summaryAvailable || [],
          settled: lots.settled || [],
          receiptReady: lots.receiptReady || [],
          receiptAccepted: lots.receiptAccepted || [],
          finalized: lots.finalized || [],
        },
      };
    case deleteLotSuccessActionType:
      if (lots.open && Object.keys(lots.open).length) {
        lots.open.lots.docs = lots.open.lots.docs.filter(lot => lot._id !== action.payload.lot.id);
        lotsCount.open--;
      }

      if (lots.materialDeliveryRequired && Object.keys(lots.materialDeliveryRequired).length) {
        lots.materialDeliveryRequired.lots.docs = lots.materialDeliveryRequired.lots.docs.filter(
          lot => lot._id !== action.payload.lot.id,
        );
        lotsCount.materialDeliveryRequired--;
      }

      return {
        ...state,
        lots,
      };
    default:
      return state;
  }
};
