import React from 'react';

import { setDesignation } from 'shared/helpers/dataParsers/lot/setDesignation';
import { formatDate, formatToUS } from 'shared/helpers/parsers/formaters';
import { replaceEmpty, humanize } from 'shared/helpers/parsers/text';

import { history } from 'utils/history';

import { SHIPMENTS } from '../shipments/shipmentsConstants';
import { LOTS } from './lotsConstants';

const lotItemTemplate = [
  {
    label: LOTS.GRG_LOT_NUMBER,
    className: 'item-grgLotNumber',
    render: ({ grgLotNumber, designation }) => setDesignation(grgLotNumber, designation),
  },
  {
    label: LOTS.MATERIAL_TYPE,
    render: ({ materialType }) => humanize(replaceEmpty(materialType)),
  },
  {
    label: SHIPMENTS.PACKAGES,
    render: ({ packageCountOutgoing }) => packageCountOutgoing,
  },
  {
    label: SHIPMENTS.GROSS_DECLARED,
    render: ({ weightGrossActual }) => replaceEmpty(formatToUS(weightGrossActual)),
  },
];

const outgoingLotItemTemplate = [
  {
    render: ({ grgLotNumber, designation, materialType }) =>
      `${setDesignation(grgLotNumber, designation)}, ${LOTS.MATERIAL_TYPE}: ${humanize(
        replaceEmpty(materialType),
      )}`,
  },
  {
    render: ({ packageCountOutgoing, weightGrossActual }) =>
      `${SHIPMENTS.PACKAGES}: ${packageCountOutgoing}
       ${SHIPMENTS.GROSS_DECLARED}: ${replaceEmpty(formatToUS(weightGrossActual))}`,
  },
  {
    render: ({ receivedAt, packagesNotReceivedSameDay }) =>
      `${SHIPMENTS.RECEIVED}: ${replaceEmpty(formatDate(receivedAt))} ${
        packagesNotReceivedSameDay ? '*' : ''
      }`,
  },
];

const lotItemMobileTemplate = [
  {
    label: LOTS.GRG_LOT_NUMBER,
    render: ({ grgLotNumber }) => grgLotNumber,
  },
  {
    label: LOTS.MATERIAL_TYPE,
    render: ({ materialType }) => humanize(replaceEmpty(materialType)),
  },
];

const shipmentItemTemplate = [
  {
    label: SHIPMENTS.SHIP_DATE,
    render: ({ estPickupDate }) => formatDate(estPickupDate),
  },
  {
    label: SHIPMENTS.EST_DELIVERY_DATE,
    render: ({ estDeliveryDate }) => formatDate(estDeliveryDate),
  },
  {
    label: SHIPMENTS.ASSIGNED_LOTS,
    render: ({ assignedLot }) =>
      assignedLot.map(lotNumber => <div key={lotNumber}>{lotNumber}</div>),
  },
  {
    label: SHIPMENTS.PACKAGES,
    render: ({ assignedPackagesCount }) => replaceEmpty(assignedPackagesCount),
  },
  {
    label: SHIPMENTS.WEIGHT_GROSS_DECLARED,
    render: ({ weightGrossDeclared }) => replaceEmpty(formatToUS(weightGrossDeclared)),
  },
];

const shipmentItemMobileTemplate = [
  {
    label: SHIPMENTS.SHIP_DATE,
    render: ({ estPickupDate }) => formatDate(estPickupDate),
  },
  {
    label: SHIPMENTS.EST_DELIVERY_DATE,
    render: ({ estDeliveryDate }) => formatDate(estDeliveryDate),
  },
];

const lotActionsTemplate = [
  {
    icon: 'arrow-right',
    action: 'Details',
    onClick:
      ({ _id }) =>
      () =>
        history.push(`/lots/outgoing/lots/${_id}`),
  },
];

const outgoingLotsActionsTemplate = [
  {
    icon: 'icon-eye',
    onClick:
      ({ _id }) =>
      () =>
        history.push(`/lots/outgoing/lots/${_id}`),
  },
];

const shipmentActionsTemplate = [
  {
    icon: 'arrow-right',
    action: 'Details',
    onClick:
      ({ _id }) =>
      () =>
        history.push(`/lots/outgoing/shipments/${_id}`),
  },
];

export {
  lotItemTemplate,
  lotItemMobileTemplate,
  lotActionsTemplate,
  shipmentItemTemplate,
  shipmentItemMobileTemplate,
  shipmentActionsTemplate,
  outgoingLotsActionsTemplate,
  outgoingLotItemTemplate,
};
