import PropTypes from 'prop-types';
import React, { Children, useContext, useMemo } from 'react';
import { compose } from 'redux';
import styled, { css, ThemeProvider } from 'styled-components';

import { filterContext } from 'shared/components/FieldsFilter/FieldsFilter';
import {
  cssVar,
  splitTemplate,
  formatTemplate,
  media,
  fillGaps,
  isMobile,
  sanitizeTemplate,
} from 'shared/helpers/styling/styling';
import { useWindowWidth } from 'shared/hooks/useWindowWidth';

import { flatten } from 'utils/flatten';

import { HeaderStyled } from './Header/Header';
import { Subheader, SectionCell } from './StyledSection';

const Section = ({
  className,
  header,
  subheader,
  disabled,
  childrenNames,
  template,
  render,
  actions,
  children,
  noPadding,
  smallPadding,
  headerPadding,
  noPaddingField,
  rowSection,
  withoutGridGap,
}) => {
  const mobile = isMobile();

  const holes = useMemo(
    () =>
      flatten(
        (mobile ? splitTemplate(template) : template)
          .filter(row => row.split(' ').some(name => childrenNames.includes(name)))
          .map(row => row.split(' ')),
      )
        .filter(name => !childrenNames.includes(name))
        .map(name => <div name={name} />),
    [mobile, template, childrenNames],
  );

  const filteredChildren = useMemo(() => children.filter(child => !!child), [children]);
  const childrenPresent = !!filteredChildren.length;

  useWindowWidth();

  return childrenPresent && render ? (
    <ThemeProvider theme={{ disabled }}>
      <>
        {childrenPresent && (header || actions) && (
          <HeaderStyled actions={actions} headerPadding={headerPadding}>
            {header}
            {subheader && <Subheader>{subheader}</Subheader>}
          </HeaderStyled>
        )}
        <div className={className}>
          {childrenPresent &&
            [...filteredChildren, ...holes].map(child => (
              <SectionCell
                key={child.props.name}
                area={child.props.name}
                disabled={disabled}
                noPadding={noPadding}
                smallPadding={smallPadding}
                noPaddingField={noPaddingField}
                rowSection={rowSection}
                withoutGridGap={withoutGridGap}
              >
                {child}
              </SectionCell>
            ))}
        </div>
      </>
    </ThemeProvider>
  ) : null;
};

const SectionStyled = styled(Section)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1px;
  grid-gap: ${({ withoutGridGap }) => (withoutGridGap ? 0 : 1)}px;
  background-color: ${cssVar('riverBedDarker')};
  ${({ topBorder }) =>
    topBorder &&
    css`
      border-top: 1px solid ${cssVar('riverBedDarker')};
    `};
  grid-template-areas: ${({ template }) => compose(formatTemplate)(template)};

  ${media.mobile`
    grid-template-columns: 1fr;
    grid-template-areas: ${({ template }) => compose(formatTemplate, splitTemplate)(template)};
  `}
`;

function WrappedSection(props) {
  const { template, unwrap, children, nameWithDots } = props;
  const filterFunc = useContext(filterContext);

  const unwrapChild = child => (child && child.props && child.props.children) || child;

  const parsedChildren = useMemo(
    () =>
      flatten(
        Children.toArray(children).map(child => (unwrap ? unwrapChild(child) : child)),
      ).filter(child => !!child && (!filterFunc || filterFunc(child))),
    [children, unwrap, filterFunc],
  );

  const childrenNames = useMemo(
    () => parsedChildren.filter(child => !!child).map(({ props: { name } }) => name),
    [parsedChildren],
  );

  const parsedTemplate = useMemo(
    () => template.filter(row => row.split(' ').some(t => childrenNames.includes(t))),
    [template, childrenNames],
  );

  const adjustTemplate = () =>
    nameWithDots
      ? compose(sanitizeTemplate)(parsedTemplate)
      : compose(fillGaps, sanitizeTemplate)(parsedTemplate);

  return (
    <SectionStyled
      {...props}
      childrenNames={sanitizeTemplate(childrenNames)}
      template={adjustTemplate()}
    >
      {parsedChildren}
    </SectionStyled>
  );
}

WrappedSection.propTypes = {
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  subheader: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  template: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
  unwrap: PropTypes.bool,
  render: PropTypes.bool,
  noPadding: PropTypes.bool,
  smallPadding: PropTypes.bool,
  topBorder: PropTypes.bool,
  headerPadding: PropTypes.string,
  noPaddingField: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  rowSection: PropTypes.bool,
  nameWithDots: PropTypes.bool,
};

WrappedSection.defaultProps = {
  template: [],
  disabled: false,
  unwrap: false,
  render: true,
  rowSection: false,
  nameWithDots: false,
};

export { WrappedSection as Section };
