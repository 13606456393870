import axios from 'axios';

const API_HOST_AUTH = process.env.REACT_APP_API;

const getBankHolidaysActionType = 'GET_BANK_HOLIDAYS';
function getBankHolidaysAction(filters) {
  return {
    type: getBankHolidaysActionType,
    payload: { filters },
  };
}

const getBankHolidaysSuccessActionType = `${getBankHolidaysActionType}_SUCCESS`;
function getBankHolidaysSuccessAction(data) {
  return {
    type: getBankHolidaysSuccessActionType,
    payload: { data },
  };
}

const getBankHolidaysFailureActionType = `${getBankHolidaysActionType}_FAILURE`;
function getBankHolidaysFailureAction(errorMessage) {
  return {
    type: getBankHolidaysFailureActionType,
    payload: { errorMessage },
  };
}

const clearBankHolidaysActionType = 'CLEAR_BANK_HOLIDAYS_ACTION';
function clearBankHolidays() {
  return { type: clearBankHolidaysActionType };
}

function getBankHolidays(filters) {
  return dispatch => {
    dispatch(getBankHolidaysAction(filters));

    return axios({
      method: 'get',
      url: `${API_HOST_AUTH}/bank-holidays/${filters.year}`,
    })
      .then(res => {
        dispatch(getBankHolidaysSuccessAction(res.data));
      })
      .catch(({ message }) => {
        dispatch(getBankHolidaysFailureAction(message));
      });
  };
}

export {
  getBankHolidaysActionType,
  getBankHolidaysSuccessActionType,
  getBankHolidaysFailureActionType,
  clearBankHolidaysActionType,
  getBankHolidays,
  clearBankHolidays,
};
