import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { getFormValues } from 'redux-form';
import styled from 'styled-components';

import { goToSummaryButtonAccess } from 'shared/helpers/accesses/shipments/goToSummaryButtonAccess';
import { SHARED } from 'shared/helpers/constants/sharedConstants';
import { media, cssVar } from 'shared/helpers/styling/styling';

import { Button } from '../Buttons/Button';
import { CancelButton } from '../Buttons/CancelButton/CancelButton';
import { SubmitButton } from '../Buttons/SubmitButton/SubmitButton';
import { Icon } from '../Icon/Icon';
import { WIZARD } from './constants';

const SummaryButtonStyled = styled(Button)`
  margin-left: 30px;
`;

const CustomActions = memo(
  ({
    className,
    wizard,
    onCancel,
    withSummary,
    summaryAvailable,
    wizardContext,
    isValidForm,
    ...props
  }) => {
    const { isFirst, isLast, prev, last, step } = wizard;
    const { defaultPackagesValues } = useSelector(state => getFormValues('wizard')(state) || {});
    const { editMode, userType, config, assignedShipment } = wizardContext;
    const changedShipmentDestination =
      defaultPackagesValues?.assignedLot?.value !== assignedShipment?.packingListAssignedLot?._id;

    return (
      <div className={className}>
        <span>
          <SubmitButton
            label={isLast && !withSummary ? SHARED.SUBMIT : WIZARD.NEXT_STEP}
            disabled={props.submitDisabled}
          />
          {goToSummaryButtonAccess(
            userType,
            editMode,
            step,
            config,
            changedShipmentDestination,
            isValidForm,
          ) && (
            <SummaryButtonStyled onClick={last}>
              {WIZARD.SUMMARY}
              <div>
                <Icon width={13} height={20} icon="icon-tiny-arrow-right" />
                <Icon width={13} height={20} icon="icon-tiny-arrow-right" />
              </div>
            </SummaryButtonStyled>
          )}
          {!isFirst && (
            <Button onClick={prev}>
              <Icon icon="icon-arrow-left" />
              {WIZARD.PREVIOUS_STEP}
            </Button>
          )}
        </span>
        <span>
          {withSummary && (
            <Button
              id="summary"
              onClick={last}
              padding="0 10px 0 23px"
              outlineColor={cssVar('pelorous')}
              disabled={!summaryAvailable}
            >
              {WIZARD.REVIEW}
              <Icon icon="icon-arrow-right" />
            </Button>
          )}
          <CancelButton onClick={onCancel} />
        </span>
      </div>
    );
  },
);

export const CustomActionsStyled = styled(CustomActions)`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin: 65px 0 25px 0;

  span {
    display: flex;
    flex-direction: row-reverse;

    button:first-child {
      margin-left: 30px;
    }

    &:nth-child(2) {
      button:only-child {
        margin-left: 0;
      }
    }

    ${media.phone`
      flex-direction: column-reverse;

      button:first-child {
        margin: 0;
      }

      button:nth-child(2) {
        margin: 10px 0;
      }
    `}
  }

  ${media.phone`
    margin: 65px 15px 25px 15px
  `}

  ${media.mobile`
    margin: 65px 90px 25px 90px
  `}
`;

CustomActions.propTypes = {
  className: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  submitDisabled: PropTypes.bool.isRequired,
  summaryAvailable: PropTypes.number,
  withSummary: PropTypes.bool,
  wizard: PropTypes.object.isRequired,
  wizardContext: PropTypes.object,
};

export { CustomActionsStyled as CustomActions };
