import { message } from 'shared/helpers/constants/hedges/createMarketHolidaysConstants';

function validate(values) {
  const errors = {};

  if (!values.date) {
    errors.date = message.INVALID_DATE_EMPTY;
  }

  if (!values.holidayName) {
    errors.holidayName = message.INVALID_HOLIDAY_NAME_EMPTY;
  } else if (values.holidayName.length > 50) {
    errors.holidayName = message.INVALID_HOLIDAY_NAME_FORMAT;
  }

  return errors;
}

export { validate };
