import React, { Component } from 'react';

import { FormContainer } from 'shared/components/Form';
import { message } from 'shared/helpers/constants/payments/createPaymentExceptionCompaniesConstants';
import { PAYMENTS } from 'shared/helpers/constants/payments/paymentsConstants';
import {
  successOptions,
  dangerOptions,
} from 'shared/helpers/constants/snackbar/snackbarOptionTypes';

import { PaymentExceptionCompaniesForm } from '../ExceptionCompaniesForm/ExceptionCompaniesForm';

class PaymentExceptionCompaniesCreate extends Component {
  submit = values => {
    const { showSnackbar, createPaymentExceptionCompany, history } = this.props;

    return createPaymentExceptionCompany(values)
      .then(() => {
        showSnackbar(successOptions, message.PAYMENT_EXCEPTION_COMPANIES_CREATE_SUCCESS);
        history.push('/payments/payment-exception-companies');
      })
      .catch(() => showSnackbar(dangerOptions, message.PAYMENT_EXCEPTION_COMPANIES_CREATE_ERROR));
  };

  render() {
    return (
      <FormContainer header={PAYMENTS.PAYMENT_EXCEPTION_COMPANIES.CREATE}>
        <PaymentExceptionCompaniesForm {...this.props} onSubmit={this.submit} />
      </FormContainer>
    );
  }
}

export { PaymentExceptionCompaniesCreate };
