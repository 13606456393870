import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { reduxForm, FieldArray, getFormValues } from 'redux-form';

import { openModal } from 'actions/shared/modal';

import { Form } from 'shared/components/Form';
import { DELIVERIES } from 'shared/helpers/constants/deliveries/deliveriesConstants';
import { cancelModal } from 'shared/helpers/constants/modalConstants';
import { validate } from 'shared/helpers/validations/deliveries/deliveriesValidation';

import { DeliveriesFormList } from '../DeliveriesFormList/DeliveriesFormList';

const initialDeliveryValues = {
  relatedHedge: null,
  relatedLot: null,
  platinumOz: null,
  palladiumOz: null,
  rhodiumOz: null,
};

class DeliveriesFormBase extends Component {
  onCancel = () => {
    const { openModalAction, history, location } = this.props;

    openModalAction(cancelModal, () => {
      if (location.state && location.state.relatedLot) {
        return history.push('/hedges/hedge-ounces', {
          shipmentId: location.state.relatedLot.shipmentId,
        });
      }
      history.push('/hedges');
    });
  };

  renderDeliveries = ({ fields }) => <DeliveriesFormList {...this.props} fields={fields} />;

  render() {
    return (
      <Form
        header={DELIVERIES.HEDGE_OUNCES}
        onSubmit={this.props.handleSubmit}
        onCancel={this.onCancel}
        submitDisabled={this.props.invalid}
      >
        <FieldArray name="deliveries" component={this.renderDeliveries} />
      </Form>
    );
  }
}

const mapStateToProps = state => ({
  hedges: state.availableHedges.hedges,
  lots: state.availableLots.lots,
  formValues: getFormValues(DELIVERIES.FORM_NAME)(state),
});

const mapDispatchToProps = {
  openModalAction: openModal,
};

const DeliveriesForm = compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: DELIVERIES.FORM_NAME,
    validate,
    initialValues: { deliveries: [{ ...initialDeliveryValues }] },
  }),
  withRouter,
)(DeliveriesFormBase);

export { DeliveriesFormBase, DeliveriesForm, initialDeliveryValues };
