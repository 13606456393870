import axios from 'axios';

import { clearPrivilegedCompanies } from './privilegedCompaniesList';

const API_HOST_AUTH = process.env.REACT_APP_API;

const deletePrivilegedCompanyActionType = 'DELETE_PRIVILEGED_COMPANY';
const deletePrivilegedCompanyAction = () => ({
  type: deletePrivilegedCompanyActionType,
});

const deletePrivilegedCompanySuccessActionType = `${deletePrivilegedCompanyActionType}_SUCCESS`;
const deletePrivilegedCompanySuccessAction = () => ({
  type: deletePrivilegedCompanySuccessActionType,
});

const deletePrivilegedCompanyFailureActionType = `${deletePrivilegedCompanyActionType}_FAILURE`;
const deletePrivilegedCompanyFailureAction = () => ({
  type: deletePrivilegedCompanyFailureActionType,
});

const deletePrivilegedCompany =
  ({ _id, exceptionType = '' }, url) =>
  dispatch => {
    dispatch(deletePrivilegedCompanyAction());
    dispatch(clearPrivilegedCompanies());

    return axios
      .delete(`${API_HOST_AUTH}/${url}/${_id}${exceptionType && `/${exceptionType}`}`)
      .then(() => dispatch(deletePrivilegedCompanySuccessAction()))
      .catch(error => {
        dispatch(deletePrivilegedCompanyFailureAction());

        return Promise.reject(error);
      });
  };

const deleteTrustedCompany = trustedCompany =>
  deletePrivilegedCompany(trustedCompany, 'trusted-companies');

const deletePaymentExceptionCompany = trustedCompany =>
  deletePrivilegedCompany(trustedCompany, 'payment-exception-companies');

export {
  deletePrivilegedCompanyActionType,
  deletePrivilegedCompanySuccessActionType,
  deletePrivilegedCompanyFailureActionType,
  deletePrivilegedCompany,
  deleteTrustedCompany,
  deletePaymentExceptionCompany,
};
