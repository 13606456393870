import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { getCompaniesBySearchStrUserType } from 'actions/Companies/filterCompanies';
import { createSelfDeliveryRequest } from 'actions/SelfDeliveryRequests/createSelfDeliveryRequest';
import { closeModal, openModal } from 'actions/shared/modal';
import { showSnackbar } from 'actions/shared/snackbar';

import { CreateSelfDeliveryRequest } from './CreateSelfDeliveryRequest';

const mapStateToProps = state => ({
  auth: state.auth,
  modal: state.modal,
  createSelfDeliveryRequestObject: state.createSelfDeliveryRequest,
});

const mapDispatchToProps = {
  createSelfDeliveryRequest,
  getCompaniesBySearchStrUserType,
  openModal,
  closeModal,
  showSnackbar,
};

const ConnectedCreateSelfDeliveryRequest = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreateSelfDeliveryRequest),
);

export { ConnectedCreateSelfDeliveryRequest };
