import PropTypes from 'prop-types';
import React, { memo } from 'react';
import styled from 'styled-components';

import { cssVar, pxToRem } from 'shared/helpers/styling/styling';

const Label = styled.span.attrs(({ steps, current }) => ({
  children: `Step ${current}/${steps}`,
}))`
  font-size: ${pxToRem(14)};
  color: ${cssVar('whiteGRG')};
`;

const Indicator = styled.div`
  height: 7px;
  width: 27px;
  background-color: ${({ active }) => cssVar(active ? 'conifer' : 'paleSkyLighter')};
  border-radius: 5px;

  &:not(:last-child) {
    margin-right: 5px;
  }
`;

const Indicators = styled.div`
  display: flex;
  margin-left: 17px;
`;

const StepIndicator = ({ className, steps, current }) => (
  <div className={className}>
    <Label steps={steps} current={current} />
    {!!steps && (
      <Indicators>
        {Array.from({ length: steps }).map((_, index) => (
          <Indicator key={index} active={index < current} data-testid={"stepIndicator"}/>
        ))}
      </Indicators>
    )}
  </div>
);

const StepIndicatorStyled = memo(styled(StepIndicator)`
  display: flex;
  align-items: center;
  width: fit-content;
  height: 43px;
  background-color: ${cssVar('limedSpruceForm')};
  border-radius: 17.5px;
  padding: 0 30px;
`);

StepIndicator.propTypes = {
  steps: PropTypes.number.isRequired,
  current: PropTypes.number.isRequired,
};

export { StepIndicatorStyled as StepIndicator };
