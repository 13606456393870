import styled from 'styled-components';

import { cssVar, media } from 'shared/helpers/styling/styling';

const ColumnGrid = styled.div`
  display: grid;
  gap: 1px;
  grid-gap: 1px;
  grid-template-columns: repeat(${({ size }) => size}, 1fr);
  background-color: ${cssVar('riverBedDarker')};
  border: 1px solid ${cssVar('riverBedDarker')};

  ${media.mobile`
    grid-template-columns: 1fr;
  `}
`;

export { ColumnGrid };
