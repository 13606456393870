import axios from 'axios';

import { ASSAY } from 'shared/helpers/constants/lots/assayConstants';
import { parseEnterAssayResultsData } from 'shared/helpers/dataParsers/lot/parseEnterAssayResultsData';

const API_HOST_AUTH = process.env.REACT_APP_API;

const enterAssayResultsActionType = 'ENTER_ASSAY_RESULTS';
const enterAssayResultsAction = () => ({
  type: enterAssayResultsActionType,
});

const enterAssayResultsSuccessActionType = `${enterAssayResultsActionType}_SUCCESS`;
const enterAssayResultsSuccessAction = () => ({
  type: enterAssayResultsSuccessActionType,
});

const enterAssayResultsFailureActionType = `${enterAssayResultsActionType}_FAILURE`;
const enterAssayResultsFailureAction = () => ({
  type: enterAssayResultsFailureActionType,
});

const enterAssayResults = (data, id) => dispatch => {
  dispatch(enterAssayResultsAction());

  return axios
    .post(`${API_HOST_AUTH}/assay`, parseEnterAssayResultsData(data, id))
    .then(res => {
      dispatch(enterAssayResultsSuccessAction());

      return {
        id: res.data.id,
        message: res.data.message, 
      };
    })
    .catch(error => {
      dispatch(enterAssayResultsFailureAction());

      return Promise.reject({
        message: `${ASSAY.ERROR.ENTER_ASSAY_RESULTS} ${error.data.message}`,
      });
    });
};

export {
  enterAssayResultsActionType,
  enterAssayResultsAction,
  enterAssayResultsSuccessActionType,
  enterAssayResultsSuccessAction,
  enterAssayResultsFailureActionType,
  enterAssayResultsFailureAction,
  enterAssayResults,
};
