import React, { Component } from 'react';

import { GroupButtons } from 'shared/components/Buttons';
import { GroupButton } from 'shared/components/Buttons/GroupButton/components/GroupButton';
import { FullPageList } from 'shared/components/FullPageList/FullPageList';
import { createPackagesAccess } from 'shared/helpers/accesses/shipments/createPackagesAccess';
import { createShipmentsAccessButtonHandler } from 'shared/helpers/accesses/shipments/createShipmentsAccessButtonHandler';
import { PACKAGES } from 'shared/helpers/constants/packages/packageConstants';
import { shipmentDirections } from 'shared/helpers/constants/shipments/shipmentDirections';
import { SHIPMENTS } from 'shared/helpers/constants/shipments/shipmentsConstants';
import {
  itemsTemplateIncoming,
  itemsTemplateOutgoing,
  itemsTemplateCustomer,
  itemsTemplateMobile,
  itemsTemplateMobileCustomer,
  itemsDetailsTemplateIncoming,
  itemsDetailsTemplateOutgoing,
  itemsDetailsTemplateCustomer,
} from 'shared/helpers/constants/shipments/shipmentsListTableConfig';
import {
  isCustomerOrGrading,
  isGradingUser,
  isCustomerUser,
} from 'shared/helpers/matchers/checkUserType';
import { isGlobalUserType } from 'shared/helpers/matchers/isGlobalUserType';

import { ShipmentsFilters } from './components/ShipmentsFilters/ShipmentsFilters';

class ShipmentsList extends Component {
  componentDidMount() {
    const { getIncomingShipmentsCompanies, shipmentDirection } = this.props;
    const { userType } = this.props.auth.user;
    if (shipmentDirection === SHIPMENTS.SHIPMENT_TYPES.INCOMING && !isCustomerOrGrading(userType)) {
      getIncomingShipmentsCompanies();
    }
  }

  componentWillUnmount() {
    this.props.clearShipmentsListAction();
  }

  onFiltersChanged = searchFilter => async filters => {
    const {
      auth: {
        user: { userType },
      },
      shipmentDirection,
      shipmentsList: { limit },
      estDeliveryDateFromFilterAction,
      estDeliveryDateToFilterAction,
      estPickupDateFromFilterAction,
      estPickupDateToFilterAction,
      sentFromCompanyFilterAction,
      sentFromCompanyLocationFilterAction,
      statusFilterAction,
    } = this.props;

    if (shipmentDirection === SHIPMENTS.SHIPMENT_TYPES.INCOMING) {
      await sentFromCompanyFilterAction(filters.sentFromCompany, shipmentDirection);
      await sentFromCompanyLocationFilterAction(filters.sentFromCompanyLocation, shipmentDirection);

      if (
        !isCustomerOrGrading(userType) &&
        (!filters.sentFromCompany._id ||
          filters.sentFromCompany._id !== filters.sentFromCompanyLocation.relatedCompany)
      ) {
        await sentFromCompanyLocationFilterAction('', shipmentDirection);
      }
    }

    await statusFilterAction(filters.status, shipmentDirection);
    await estPickupDateFromFilterAction(
      this.resolveStartDate(filters, 'estPickupDate'),
      shipmentDirection,
    );
    await estPickupDateToFilterAction(
      this.resolveEndDate(filters, 'estPickupDate'),
      shipmentDirection,
    );
    await estDeliveryDateFromFilterAction(
      this.resolveStartDate(filters, 'estDeliveryDate'),
      shipmentDirection,
    );
    await estDeliveryDateToFilterAction(
      this.resolveEndDate(filters, 'estDeliveryDate'),
      shipmentDirection,
    );

    await this.fetchList(1, limit, searchFilter);
  };

  get tableActions() {
    const {
      auth: {
        user,
        access: { shipments },
      },
      history,
      shipmentDirection,
    } = this.props;

    const actions = [];

    if (shipments.details) {
      actions.push({
        icon: 'arrow-right',
        action: 'Details',
        onClick: thisShipment => () => {
          history.push(this.resolveListPath(user.userType, shipmentDirection, thisShipment));
        },
      });
    }

    return actions;
  }

  resolveListPath = (userType, shipmentDirection, thisShipment) => {
    if (isGlobalUserType(userType)) {
      return `/shipments/${shipmentDirection}/${thisShipment._id}`;
    }
    if (isGradingUser(userType)) {
      return `/shipments/list/${thisShipment._id}`;
    }
    if (isCustomerUser(userType)) {
      return `/shipments/${thisShipment._id}`;
    }
  };

  resolveStartDate = (filters, name) =>
    filters[`${name}Start`] === null ? null : filters[`${name}Start`] || filters[name].from;

  resolveEndDate = (filters, name) =>
    filters[`${name}End`] === null ? null : filters[`${name}End`] || filters[name].to;

  filtersClear = () => {
    const {
      getShipmentsList,
      shipmentsFiltersClearAction,
      shipmentDirection,
      shipmentsList: { limit },
    } = this.props;
    const clearedFilters = {
      estDeliveryDate: { from: null, to: null },
      estPickupDate: { from: null, to: null },
      sentFromCompany: {},
      sentFromCompanyLocation: {},
      status: [],
    };
    const searchFilter = '';

    getShipmentsList(shipmentDirection, limit, 1, clearedFilters, searchFilter);
    shipmentsFiltersClearAction(shipmentDirection);
  };

  fetchList = (page = 1, limit = 50, searchFilter = '') => {
    const { getShipmentsList, shipmentsFilters, shipmentDirection } = this.props;
    getShipmentsList(
      shipmentDirection,
      limit,
      page,
      shipmentsFilters[shipmentDirection],
      searchFilter,
    );
  };

  renderActionButtons = () => {
    const { auth, companyLocations, history } = this.props;

    return (
      <GroupButtons>
        <GroupButton
          id="newCustomerShipment"
          access={createShipmentsAccessButtonHandler(
            auth.access.shipments.create,
            companyLocations.data,
            auth.user.relatedCompany.status,
          )}
          onClick={() =>
            history.push(
              auth.user.relatedCompany.materialCountRequired
                ? '/shipments/create-incoming-wizard'
                : '/shipments/create',
            )
          }
          label={SHIPMENTS.CREATE_A_SHIPMENT}
          icon="create-shipment"
        />
        <GroupButton
          id="newCustomerPackage"
          access={createPackagesAccess(auth.user)}
          onClick={() => history.push('/shipments/packages/create')}
          label={PACKAGES.CREATE_PACKAGES}
          icon="create-package"
        />
        <GroupButton
          id="createSelfDeliveryRequest"
          access={auth.access.selfDeliveryRequests.create}
          onClick={() => history.push('/shipments/self-delivery-requests/create')}
          label={SHIPMENTS.CREATE_SELF_DELIVERY_REQUEST}
          icon="car"
        />
      </GroupButtons>
    );
  };

  renderFilters = searchFilter => {
    const {
      shipmentsList: { page, limit },
      shipmentsFilters,
      shipmentDirection,
      companyIncomingShipment,
      sentFromCompanyFilterAction,
      getCompanyLocations,
      companyLocations,
      sentFromCompanyLocationFilterAction,
      auth,
    } = this.props;

    return (
      <ShipmentsFilters
        filters={shipmentsFilters[shipmentDirection]}
        pagination={{ page, limit }}
        shipmentDirection={shipmentDirection}
        onFiltersChanged={this.onFiltersChanged(searchFilter)}
        auth={auth}
        companyIncomingShipment={companyIncomingShipment}
        sentFromCompanyFilterAction={sentFromCompanyFilterAction}
        getCompanyLocations={getCompanyLocations}
        companyLocations={companyLocations}
        sentFromCompanyLocationFilterAction={sentFromCompanyLocationFilterAction}
      />
    );
  };

  render() {
    const {
      auth: {
        user: { userType },
      },
      shipmentDirection,
      shipmentsList: { shipments, limit, page, pages, isPending },
      shipmentsFilters,
    } = this.props;

    const currentFilters = shipmentsFilters[shipmentDirection];
    const { estDeliveryDate, estPickupDate, sentFromCompany, sentFromCompanyLocation, status } =
      currentFilters;

    const filtersAccess = true;
    const filtersSelected =
      estDeliveryDate.from ||
      estDeliveryDate.to ||
      estPickupDate.from ||
      estPickupDate.to ||
      sentFromCompany._id ||
      sentFromCompanyLocation._id ||
      status.length;

    return (
      <FullPageList
        title={SHIPMENTS.SHIPMENTS}
        enableSearch={isGlobalUserType(userType)}
        actionButtons={isCustomerUser(userType) && this.renderActionButtons}
        itemsTemplate={
          isCustomerOrGrading(userType)
            ? itemsTemplateCustomer
            : (shipmentDirection === shipmentDirections.incoming && itemsTemplateIncoming) ||
              (shipmentDirection === shipmentDirections.outgoing && itemsTemplateOutgoing)
        }
        itemsDetailsTemplate={
          isCustomerOrGrading(userType)
            ? itemsDetailsTemplateCustomer
            : (shipmentDirection === shipmentDirections.incoming && itemsDetailsTemplateIncoming) ||
              (shipmentDirection === shipmentDirections.outgoing && itemsDetailsTemplateOutgoing)
        }
        itemsTemplateMobile={
          isCustomerOrGrading(userType) ? itemsTemplateMobileCustomer : itemsTemplateMobile
        }
        actionsTemplate={this.tableActions}
        items={shipments || []}
        fetchList={this.fetchList}
        filterComponent={this.renderFilters}
        filterAccess={filtersAccess}
        pagination={{ limit, page, pages }}
        filtersClear={this.filtersClear}
        filtersSelected={filtersSelected}
        isPending={isPending}
        filters={currentFilters}
      />
    );
  }
}

export { ShipmentsList };
