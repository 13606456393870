import { REMOVE_COMMODITY } from 'shared/helpers/constants/commodities/removeCommodityConstants';
import { commaValidator } from 'shared/helpers/validations/commaValidator';
import { INTEGER_REGEX, FLOAT_REGEX } from 'shared/helpers/validations/regularExpressions';

const validate = values => {
  const { materialUnits, materialWeight } = values;
  const trimMaterialUnits = (materialUnits && materialUnits.trim()) || '';
  const trimMaterialWeight = (materialWeight && materialWeight.trim()) || '';
  const errors = {};

  if (!values.toLot) {
    errors.toLot = REMOVE_COMMODITY.INVALID_TO_LOT_EMPTY;
  }

  if (!values.materialType) {
    errors.materialType = REMOVE_COMMODITY.INVALID_MATERIAL_TYPE_EMPTY;
  }

  if (!trimMaterialWeight) {
    if (!trimMaterialUnits) {
      errors.materialUnits = REMOVE_COMMODITY.INVALID_MATERIAL_UNITS_EMPTY;
    } else if (trimMaterialUnits.length > 10) {
      errors.materialUnits = REMOVE_COMMODITY.INVALID_MATERIAL_UNITS_LENGTH;
    } else if (!INTEGER_REGEX.test(trimMaterialUnits)) {
      errors.materialUnits = REMOVE_COMMODITY.INVALID_MATERIAL_UNITS_INTEGER;
    } else if (trimMaterialUnits < 1) {
      errors.materialUnits = REMOVE_COMMODITY.INVALID_MATERIAL_UNITS_LESS;
    }
  }

  if (!trimMaterialUnits) {
    if (!trimMaterialWeight) {
      errors.materialWeight = REMOVE_COMMODITY.INVALID_MATERIAL_WEIGHT_EMPTY;
    } else if (trimMaterialWeight.length > 10) {
      errors.materialWeight = REMOVE_COMMODITY.INVALID_MATERIAL_WEIGHT_LENGTH;
    } else if (!FLOAT_REGEX.test(trimMaterialWeight)) {
      errors.materialWeight = REMOVE_COMMODITY.INVALID_MATERIAL_WEIGHT_FORMAT;
    } else if (trimMaterialWeight <= 0) {
      errors.materialWeight = REMOVE_COMMODITY.INVALID_MATERIAL_WEIGHT_LESS;
    }
  }

  if (trimMaterialWeight && trimMaterialUnits) {
    errors.materialWeight = REMOVE_COMMODITY.INVALID_MATERIAL_WEIGHT_XOR;
  }

  commaValidator({ materialWeight: trimMaterialWeight }, errors);

  return errors;
};

export { validate };
