import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { UIAuthorization } from 'components/Authorization/UIAuthorization';

import { SYSTEM_SETTINGS } from 'shared/helpers/constants/systemSettings/constants';
import { media } from 'shared/helpers/styling/styling';

import { MarketIndicators } from './components/MarketIndicators/MarketIndicators';
import { ConnectedPermissions } from './components/Permissions/ContectedPermissions';
import { PriceFeeds } from './components/PriceFeeds/PriceFeeds';

const Header = styled.h1`
  ${media.phone`
    padding: 22px 22px 0;
    margin-bottom: 0px;
  `}
`;

function SettingsDashboardBase({ auth: { access } }) {
  return (
    <>
      <Header>{SYSTEM_SETTINGS.SYSTEM_SETTINGS}</Header>
      <UIAuthorization access={access.systemSettings.priceFeeds.view}>
        <PriceFeeds />
      </UIAuthorization>
      <UIAuthorization access={access.systemSettings.marketIndicators.view}>
        <MarketIndicators />
      </UIAuthorization>
      <UIAuthorization access={access.systemSettings.specialPermissions.view}>
        <ConnectedPermissions />
      </UIAuthorization>
    </>
  );
}

const mapStateToProps = ({ auth }) => ({ auth });
const SettingsDashboard = connect(mapStateToProps)(SettingsDashboardBase);

export { SettingsDashboardBase, SettingsDashboard };
