const statusFilterActionType = 'STATUS_ACTION';
const statusFilterAction = (status, direction) => ({
  type: statusFilterActionType,
  payload: {
    status,
    direction,
  },
});

const sentFromCompanyFilterActionType = 'SHIPMENTS_SENT_FROM_COMPANY_ACTION';
const sentFromCompanyFilterAction = (sentFromCompany, direction) => ({
  type: sentFromCompanyFilterActionType,
  payload: {
    sentFromCompany,
    direction,
  },
});

const sentFromCompanyLocationFilterActionType = 'SENT_FROM_COMPANY_LOCATION_ACTION';
const sentFromCompanyLocationFilterAction = (sentFromCompanyLocation, direction) => ({
  type: sentFromCompanyLocationFilterActionType,
  payload: {
    sentFromCompanyLocation,
    direction,
  },
});

const estPickupDateFromFilterActionType = 'EST_PICKUP_DATE_FROM_FILTER_ACTION';
const estPickupDateFromFilterAction = (estPickupDateFrom, direction) => ({
  type: estPickupDateFromFilterActionType,
  payload: {
    estPickupDateFrom,
    direction,
  },
});

const estPickupDateToFilterActionType = 'EST_PICKUP_DATE_TO_FILTER_ACTION';
const estPickupDateToFilterAction = (estPickupDateTo, direction) => ({
  type: estPickupDateToFilterActionType,
  payload: {
    estPickupDateTo,
    direction,
  },
});

const estDeliveryDateFromFilterActionType = 'EST_DELIVERY_DATE_FROM_FILTER_ACTION';
const estDeliveryDateFromFilterAction = (estDeliveryDateFrom, direction) => ({
  type: estDeliveryDateFromFilterActionType,
  payload: {
    estDeliveryDateFrom,
    direction,
  },
});

const estDeliveryDateToFilterActionType = 'EST_DELIVERY_DATE_TO_FILTER_ACTION';
const estDeliveryDateToFilterAction = (estDeliveryDateTo, direction) => ({
  type: estDeliveryDateToFilterActionType,
  payload: {
    estDeliveryDateTo,
    direction,
  },
});

const shipmentsFiltersClearActionType = 'SHIPMENTS_FILTERS_CLEAR_ACTION';
const shipmentsFiltersClearAction = direction => ({
  type: shipmentsFiltersClearActionType,
  direction,
});

export {
  statusFilterActionType,
  sentFromCompanyFilterActionType,
  sentFromCompanyLocationFilterActionType,
  estPickupDateFromFilterActionType,
  estPickupDateToFilterActionType,
  estDeliveryDateFromFilterActionType,
  estDeliveryDateToFilterActionType,
  shipmentsFiltersClearActionType,
  statusFilterAction,
  sentFromCompanyFilterAction,
  sentFromCompanyLocationFilterAction,
  estPickupDateFromFilterAction,
  estPickupDateToFilterAction,
  estDeliveryDateFromFilterAction,
  estDeliveryDateToFilterAction,
  shipmentsFiltersClearAction,
};
