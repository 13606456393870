import PropTypes from 'prop-types';
import * as React from 'react';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';

import { openModal } from 'actions/shared/modal';

import { FieldInput } from 'shared/components/Fields';
import { Form, Section } from 'shared/components/Form';
import { COMPANY } from 'shared/helpers/constants/companies/companyConstants';
import { cancelModal } from 'shared/helpers/constants/modalConstants';
import { insertCommas } from 'shared/helpers/parsers/text';
import { validate } from 'shared/helpers/validations/company/updateOutstandingBalanceValidation';

const UpdateOutstandingBalanceFormBase = props => {
  const { match, history, submitting, invalid, handleSubmit } = props;

  const dispatch = useDispatch();

  const openModalOnCancel = () =>
    dispatch(openModal(cancelModal, () => history.push(`/companies/${match.params.id}`)));

  return (
    <Form
      header={COMPANY.UPDATE_OUTSTANDING_BALANCE_TITLE}
      onSubmit={handleSubmit}
      onCancel={openModalOnCancel}
      submitDisabled={invalid || submitting}
    >
      <Section template={['companyName outstandingBalance']}>
        <Field
          name="companyName"
          data-testid="companyName"
          type="text"
          component={FieldInput}
          disabled
          label={COMPANY.COMPANY_NAME}
          field="required"
        />

        <Field
          name="outstandingBalance"
          data-testid="outstandingBalance"
          component={FieldInput}
          label={COMPANY.BALANCE_REMAINING}
          field="required"
          type="text"
          prefix="$"
        />
      </Section>
    </Form>
  );
};

UpdateOutstandingBalanceFormBase.propTypes = {
  handleSubmit: PropTypes.func,
  history: PropTypes.object,
  invalid: PropTypes.bool,
  match: PropTypes.shape({
    isExact: PropTypes.bool,
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
    path: PropTypes.string,
    url: PropTypes.string,
  }),
  submitting: PropTypes.bool,
};

let UpdateOutstandingBalanceForm = compose(
  reduxForm({
    form: 'updateOutstandingBalance',
    validate,
  }),
  withRouter,
)(UpdateOutstandingBalanceFormBase);

UpdateOutstandingBalanceForm = connect(({ companyDetails: { company } }) => ({
  initialValues: {
    companyName: company?.companyName,
    outstandingBalance: insertCommas(company?.balanceRemaining?.toString() || 0),
  },
}))(UpdateOutstandingBalanceForm);

export { UpdateOutstandingBalanceForm };
