import PropTypes from 'prop-types';
import styled from 'styled-components';

import { PackageSection } from 'shared/components/Form/PackgeSection/PackageSection';
import { PACKAGES } from 'shared/helpers/constants/packages/packageConstants';

const OneHeader = ({ actions, className, children }) => (
  <div className={className}>
    {children}
    {actions}
  </div>
);

const OneHeaderStyled = styled(OneHeader)`
  grid-area: header;
`;

const MultiPackagesFormHeaderLayout = ({
  className,
  package: pkg,
  instructionsRequired,
  config,
  requiredFields = {},
}) => (
  <PackageSection className={className} header config={config}>
    <OneHeaderStyled name={`${pkg}.number`} label={PACKAGES.NUMBER} />
    <OneHeaderStyled name={`${pkg}.status`} label={PACKAGES.STATUS} />
    <OneHeaderStyled name={`${pkg}.assignedLot`} label={PACKAGES.ASSIGNED_LOT} required />
    <OneHeaderStyled name={`${pkg}.labelId`} label={PACKAGES.LABEL_ID} />
    <OneHeaderStyled name={`${pkg}.plant`} label={PACKAGES.PLANT} />
    <OneHeaderStyled
      name={`${pkg}.primaryId`}
      label={PACKAGES.PRIMARY_ID}
      required={!!requiredFields.primaryId}
    />
    <OneHeaderStyled name={`${pkg}.secondaryId`} label={PACKAGES.SECONDARY_ID} />

    <OneHeaderStyled
      name={`${pkg}.customerInstructions`}
      label={PACKAGES.CUSTOMER_INSTRUCTIONS}
      required={instructionsRequired}
    />
    <OneHeaderStyled name={`${pkg}.packageType`} label={PACKAGES.PACKAGE_TYPE} required />
    <OneHeaderStyled name={`${pkg}.materialClass`} label={PACKAGES.MATERIAL_CLASS} required />
    <OneHeaderStyled
      name={`${pkg}.materialTypeDeclared`}
      label={PACKAGES.DECLARED_MATERIAL_TYPE}
      required
    />
    <OneHeaderStyled
      name={`${pkg}.unitCount`}
      label={PACKAGES.UNIT_COUNT}
      required={!!requiredFields.unitCount}
    />
    <OneHeaderStyled name={`${pkg}.weightGrossActual`} label={PACKAGES.WEIGHT_GROSS_ACTUAL} />
    <OneHeaderStyled name={`${pkg}.weightTareActual`} label={PACKAGES.WEIGHT_TARE_ACTUAL} />
    <OneHeaderStyled
      name={`${pkg}.weightGrossDeclared`}
      label={PACKAGES.DECLARED_GROSS_WEIGHT}
      required
    />
    <OneHeaderStyled
      name={`${pkg}.weightTareDeclared`}
      label={PACKAGES.DECLARED_TARE_WEIGHT}
      required
    />
    <OneHeaderStyled name={`${pkg}.removePackage`} label={PACKAGES.REMOVE_PACKAGE} />
  </PackageSection>
);

MultiPackagesFormHeaderLayout.propTypes = {
  className: PropTypes.string,
  package: PropTypes.string,
  instructionsRequired: PropTypes.bool,
  config: PropTypes.string,
  requiredFields: PropTypes.object,
};

const MultiPackagesFormHeader = styled(MultiPackagesFormHeaderLayout)`
  width: 100%;
`;

export { MultiPackagesFormHeader };
