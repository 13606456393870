import React, { Component } from 'react';

import { FormContainer } from 'shared/components/Form';
import { TERMS } from 'shared/helpers/constants/terms/termsConstants';

class UpdateTerm extends Component {
  componentDidMount() {
    const { getTerms, getSignedTerms, match } = this.props;

    getTerms(match.params.id).then(terms => getSignedTerms(terms.relatedCompany._id));
  }

  componentWillUnmount() {
    this.props.clearTermsDetails();
  }

  submit = values => {
    const { id, companyId } = this.props.match.params;

    return this.props.updateTerms(id, values, companyId);
  };

  render() {
    const { children, termsDetails, signedTerms } = this.props;
    const { terms } = termsDetails;
    const title = terms
      ? TERMS[`EDIT_${terms.materialType.toUpperCase()}_TERMS`]
      : TERMS.EDIT_TERMS;

    return (
      <FormContainer
        header={title}
        subHeader={terms && terms.relatedCompany.companyName}
        loaded={terms && !signedTerms.isPending}
      >
        {() =>
          children(terms.materialType, {
            onSubmit: this.submit,
            relatedCompany: terms.relatedCompany,
            ...this.props,
          })
        }
      </FormContainer>
    );
  }
}

export { UpdateTerm };
