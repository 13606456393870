import ReCAPTCHA from 'react-google-recaptcha';
import styled from 'styled-components';

import { media } from 'shared/helpers/styling/styling';

export const StyledResetPasswordForm = styled.form`
  margin-top: 42px;
  ${media.phone`
margin-top: 30px;
`}
`;
export const StyledResetPasswordFormReCAPTCHA = styled(ReCAPTCHA)`
  display: flex;
  align-items: center;
  justify-content: center;
  ${media.phone`transform: scale(0.85);`}
`;
export const StyledResetPasswordFormEmail = styled.h5`
  color: var(--alabaster);
  margin-bottom: 25px;
`;

export const StyledResetPasswordFormActionBtns = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
`;
