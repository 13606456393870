import { companyStatuses } from 'shared/helpers/constants/companies/companiesFilters';
import { statusesForGlobal } from 'shared/helpers/constants/lots/statusesForGlobal';
import { userTypesOwner } from 'shared/helpers/constants/users/userFilters';
import { isGlobalRefiningGroup } from 'shared/helpers/matchers/isGlobalRefiningGroup';

const createIncomingPackageAccess = (auth, lot) => {
  const isGlobal = isGlobalRefiningGroup(lot.sentFromCompany && lot.sentFromCompany.companyName);

  const access = isGlobal
    ? auth.access.packages.createIncoming.forGlobal
    : auth.access.packages.createIncoming.forCustomer;

  const isCompanyActive = lot.sentFromCompany.status === companyStatuses.active;
  const gradingCompletedAllowedStatuses = [
    userTypesOwner.owner,
    userTypesOwner.support,
    userTypesOwner.warehouse,
  ];

  return (
    access &&
    isCompanyActive &&
    [
      statusesForGlobal.not_received,
      statusesForGlobal.partly_received,
      statusesForGlobal.received,
      isGlobal ? statusesForGlobal.ready_for_processing : null,
      gradingCompletedAllowedStatuses.includes(auth.user.userType)
        ? statusesForGlobal.grading_completed
        : null,
    ].includes(lot.statusForGlobal)
  );
};

export { createIncomingPackageAccess };
