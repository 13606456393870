import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import { AddButton } from 'shared/components/Buttons/AddButton/AddButton';
import { Separator } from 'shared/components/Form';
import { DELIVERIES } from 'shared/helpers/constants/deliveries/deliveriesConstants';
import { safeParseFloat, roundUp } from 'shared/helpers/parsers/number';

import { initialDeliveryValues } from '../DeliveriesForm/DeliveriesForm';
import { DeliveryForm } from '../DeliveryForm/DeliveryForm';

const DeliveriesFormList = ({ fields, invalid, location, change, blur, formValues }) => {
  const addDelivery = () => fields.push({ ...initialDeliveryValues });
  const removeDelivery = index => fields.remove(index);

  const initialRemainingOunces = {
    platinumOz: 0,
    palladiumOz: 0,
    rhodiumOz: 0,
  };

  const calculatePreviousRemainingOunces = index => {
    const currentField = fields.get(index);
    const currentRelatedHedge = currentField?.relatedHedge?.value;

    if (!currentRelatedHedge) {
      return initialRemainingOunces;
    }

    const remainingOunces = fields
      .getAll()
      .slice(0, index)
      .filter(field => field?.relatedHedge?.value === currentRelatedHedge)
      .reduce(
        (remaining, field) => ({
          platinumOz: remaining.platinumOz + +safeParseFloat(field.platinumOz),
          palladiumOz: remaining.palladiumOz + +safeParseFloat(field.palladiumOz),
          rhodiumOz: remaining.rhodiumOz + +safeParseFloat(field.rhodiumOz),
        }),
        { ...initialRemainingOunces },
      );

    return {
      platinumOz: +roundUp(remainingOunces.platinumOz, 3),
      palladiumOz: +roundUp(remainingOunces.palladiumOz, 3),
      rhodiumOz: +roundUp(remainingOunces.rhodiumOz, 3),
    };
  };

  const calculatePreviousRemainingLotOunces = index => {
    const currentField = fields.get(index);
    const currentRelatedLot = currentField?.relatedLot?.value;

    if (!currentRelatedLot) {
      return initialRemainingOunces;
    }

    const remainingLotOunces = fields
      .getAll()
      .slice(0, index)
      .filter(field => field?.relatedLot?.value === currentRelatedLot)
      .reduce(
        (remaining, field) => ({
          platinumOz: remaining.platinumOz + +safeParseFloat(field.platinumOz),
          palladiumOz: remaining.palladiumOz + +safeParseFloat(field.palladiumOz),
          rhodiumOz: remaining.rhodiumOz + +safeParseFloat(field.rhodiumOz),
        }),
        { ...initialRemainingOunces },
      );

    return {
      platinumOz: +roundUp(remainingLotOunces.platinumOz, 3),
      palladiumOz: +roundUp(remainingLotOunces.palladiumOz, 3),
      rhodiumOz: +roundUp(remainingLotOunces.rhodiumOz, 3),
    };
  };

  return (
    <>
      {fields.map((delivery, index) => (
        <Fragment key={index}>
          <DeliveryForm
            delivery={delivery}
            values={fields.get(index)}
            previousRemainingOunces={calculatePreviousRemainingOunces(index)}
            previousRemainingLotOunces={calculatePreviousRemainingLotOunces(index)}
            onRemove={fields.length > 1 && (() => removeDelivery(index))}
            location={location}
            change={change}
            blur={blur}
            formValues={formValues && formValues.deliveries && formValues.deliveries[index]}
          />
          <Separator />
        </Fragment>
      ))}
      {!(location.state && location.state.relatedLot) && (
        <AddButton onClick={addDelivery} disabled={invalid}>
          {DELIVERIES.ADD}
        </AddButton>
      )}
    </>
  );
};

DeliveriesFormList.propTypes = {
  fields: PropTypes.object,
  blur: PropTypes.func,
  change: PropTypes.func,
  formValues: PropTypes.object,
  invalid: PropTypes.bool,
  location: PropTypes.object,
};

export { DeliveriesFormList };
