import {
  changeOwnPasswordActionType,
  changeOwnPasswordFailureActionType,
  changeOwnPasswordSuccessActionType,
} from 'actions/Users/changeOwnPassword';

const INITIAL_STATE = {
  isPending: false,
};

const changeOwnPasswordReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case changeOwnPasswordActionType:
      return {
        isPending: true,
      };
    case changeOwnPasswordSuccessActionType:
    case changeOwnPasswordFailureActionType:
      return {
        isPending: false,
      };
    default:
      return state;
  }
};

export { changeOwnPasswordReducer };
