import React, { Component, Fragment } from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { ThemeProvider } from 'styled-components';

import { Icon } from 'shared/components/Icon/Icon';
import { cssVar, isSmartphone } from 'shared/helpers/styling/styling';

import { ExtendableButtonStyled } from './ExtendableButtonStyled';

const prepareChildren = children =>
  React.Children.toArray(children)
    .filter(child => ('access' in child.props ? child.props.access : true))
    .map((child, i, all) => (
      <Fragment key={child.key}>
        {child}
        {i < all.length - 1 && <DropdownItem className="ExtendableButton-Divider" divider />}
      </Fragment>
    ));

class ExtendableButton extends Component {
  state = {
    open: false,
    disabled: false,
    children: null,
  };

  static getDerivedStateFromProps(props) {
    const children = prepareChildren(props.children);

    return {
      children,
      disabled: props.disabled,
    };
  }

  toggle = () => this.setState(prevState => ({ open: !prevState.open }));

  close = () => this.setState({ open: false });

  theme = {
    noOutline: true,
    noBoxShadow: true,
    borderRadius: 0,
    width: '100%',
    height: '50px',
    bgColorHover: cssVar('outerSpaceLighter'),
    outlineColorHover: cssVar('outerSpaceLighter'),
    textColor: 'rgba(255, 255, 255, 0.70)',
    justifyContent: 'flex-start',
  };

  render() {
    const { open, disabled, children } = this.state;
    const { label, closeOnClick, persist } = this.props;

    if (!children.length) return null;

    return (
      <ExtendableButtonStyled isOpen={open} toggle={this.toggle}>
        <DropdownToggle className="ExtendableButton-Toggle" disabled={disabled}>
          <div className="ExtendableButton-Toggle-Label">
            <span>{label}</span>
            <Icon icon="icon-tiny-arrow-down" />
          </div>
        </DropdownToggle>
        <DropdownMenu
          className="ExtendableButton-Menu"
          right
          onClick={closeOnClick && this.close}
          persist={persist}
          style={{ transform: `translate3d(${isSmartphone() ? '0, 43px, 0' : '-70px, 43px, 0'})` }}
        >
          <ThemeProvider theme={this.theme}>
            <>
              <div className="ExtendableButton-Menu-topSpacer">
                <Icon icon="icon-cancel" onClick={this.toggle} />
              </div>
              {children}
              <div className="ExtendableButton-Menu-bottomSpacer" />
            </>
          </ThemeProvider>
        </DropdownMenu>
      </ExtendableButtonStyled>
    );
  }
}

ExtendableButton.defaultProps = {
  disabled: false,
};

export { ExtendableButton };
