import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import * as React from 'react';

import { Section } from 'shared/components/Form';
import { FieldDate, FieldInput } from 'shared/components/Formik/Fields';
import { Form } from 'shared/components/Formik/Form';
import { PAYMENTS } from 'shared/helpers/constants/payments/paymentsConstants';

import { BankHolidaysSchema } from './BankHolidaysFormYup';

const BankHolidaysForm = ({
  onDateChange,
  onCancel,
  dateValidationIsPending,
  onSubmit,
  createBankHolidayIsPending,
}) => {
  const {
    values,
    isValid,
    touched,
    handleBlur,
    getFieldMeta,
    handleSubmit,
    setFieldValue,
    handleChange,
    setFieldTouched,
    dirty,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      holidayName: '',
    },
    validationSchema: BankHolidaysSchema,
    onSubmit: values => onSubmit(values),
  });

  const getErrors = name => Object.keys(touched).includes(name) && getFieldMeta(name).error;

  return (
    <Form
      header={PAYMENTS.BANK_HOLIDAYS.TITLE}
      onSubmit={handleSubmit}
      onCancel={onCancel}
      submitDisabled={!isValid || createBankHolidayIsPending || dateValidationIsPending || !dirty}
    >
      <Section template={['holidayName date']}>
        <FieldInput
          name="holidayName"
          type="text"
          label={PAYMENTS.BANK_HOLIDAYS.HOLIDAY_NAME}
          icon="icon-referral"
          field="required"
          value={values.holidayName}
          onChange={handleChange}
          onBlur={handleBlur}
          error={getErrors('holidayName')}
          onInput={() => setFieldTouched('holidayName')}
        />
        <FieldDate
          name="date"
          label={PAYMENTS.BANK_HOLIDAYS.DATE}
          value={values.date}
          onBlur={handleBlur}
          error={getFieldMeta('date').error}
          onChange={setFieldValue}
          onDateChange={onDateChange}
        />
      </Section>
    </Form>
  );
};

BankHolidaysForm.propTypes = {
  handleSubmit: PropTypes.func,
  invalid: PropTypes.bool,
  submitting: PropTypes.bool,
  dateValidationIsPending: PropTypes.bool,
  createBankHolidayIsPending: PropTypes.bool,
  onDateChange: PropTypes.func,
  onCancel: PropTypes.func,
};

export { BankHolidaysForm };
