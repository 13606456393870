import { companyStatuses } from 'shared/helpers/constants/companies/companiesFilters';
import { roles } from 'shared/helpers/constants/filters/userTypes';
import { statusesForGlobal } from 'shared/helpers/constants/lots/statusesForGlobal';
import { globalPackageClasses } from 'shared/helpers/constants/packages/packageClasses';
import { shipmentDirections } from 'shared/helpers/constants/shipments/shipmentDirections';
import { shipmentStatuses } from 'shared/helpers/constants/shipments/shipmentStatuses';
import { isAssayCompany } from 'shared/helpers/matchers/checkCompanyType';
import { isSupportUser } from 'shared/helpers/matchers/checkUserType';
import { isIncomingShipment } from 'shared/helpers/matchers/shipments/checkShipmentDirection';

const baseUpdatePackingListAccess = ({ user }, shipment) => {
  let canUpdatePackingList = false;

  switch (user.userType) {
    case roles.support.name:
    case roles.owner.name:
      canUpdatePackingList = true;
      break;
    case roles.customer.name:
      canUpdatePackingList = shipment.status === shipmentStatuses.open;
      break;
    default:
      canUpdatePackingList = false;
  }

  return (
    isIncomingShipment(shipment.shipmentDirection) &&
    isAssayCompany(shipment.sentFromCompany.companyType) &&
    canUpdatePackingList
  );
};

const updatePackingListAccess = (auth, shipment) =>
  [
    statusesForGlobal.not_received,
    statusesForGlobal.partly_received,
    statusesForGlobal.received,
  ].includes(shipment.packingListAssignedLot?.statusForGlobal) &&
  baseUpdatePackingListAccess(auth, shipment) &&
  auth.access.shipments.updatePackingList &&
  shipment.shipmentPackingList &&
  shipment.sentFromCompany.materialCountRequired;

const updateIncomingShipmentAccess = (auth, shipment) =>
  auth.access.shipments.update.incoming &&
  isIncomingShipment(shipment.shipmentDirection) &&
  shipment.sentFromCompany.status === companyStatuses.active &&
  shipment.status === shipmentStatuses.open &&
  !shipment.sentFromCompany.materialCountRequired;

const updateOutgoingShipmentAccess = (auth, shipment) =>
  (auth.access.shipments.update.outgoing ||
    auth.access.specialPermissions.shipments.createOutgoingShipment) &&
  shipment.status === shipmentStatuses.open &&
  shipment.sentFromCompany.status === companyStatuses.active;

const markAsSentAccess = (auth, shipment, packages) => {
  const { createOutgoingShipment } = auth.access.specialPermissions.shipments;

  const checkWeight = packageClass =>
    packages.all &&
    packages.all.docs
      .filter(p => p.packageClass === packageClass)
      .some(p => p.weightGrossActual === null || p.weightTareActual === null);

  const weightShears = checkWeight(globalPackageClasses.post_shears);
  const weightMix = checkWeight(globalPackageClasses.post_mix);

  if (
    shipment.shipmentDirection === shipmentDirections.outgoing &&
    !createOutgoingShipment &&
    isSupportUser(auth.user.userType)
  )
    return false;

  return (
    shipment.status === shipmentStatuses.open &&
    ((shipment.shipmentDirection === shipmentDirections.outgoing && createOutgoingShipment) ||
      (auth.access.shipments.markAsSent &&
        shipment.sentFromCompany.status === companyStatuses.active &&
        !(weightShears || weightMix)))
  );
};

const updateWeightAccess = (auth, shipment, packages) => {
  const checkWeight = packageClass =>
    packages.all &&
    packages.all.docs
      .filter(p => p.packageClass === packageClass)
      .some(p => p.weightGrossActual === null || p.weightTareActual === null);

  const weightShears = checkWeight(globalPackageClasses.post_shears);
  const weightMix = checkWeight(globalPackageClasses.post_mix);

  if (
    shipment.shipmentDirection === shipmentDirections.outgoing &&
    isSupportUser(auth.user.userType)
  )
    return false;

  return (
    auth.access.shipments.markAsSent &&
    shipment.status === shipmentStatuses.open &&
    shipment.sentFromCompany.status === companyStatuses.active &&
    (weightShears || weightMix)
  );
};

const deleteShipmentAccess = ({ access: { shipments } }, shipment) =>
  shipment.status === shipmentStatuses.open &&
  (shipment.shipmentDirection === shipmentDirections.outgoing
    ? shipments.deleteOutgoing
    : shipments.deleteIncoming);

export {
  updateIncomingShipmentAccess,
  updateOutgoingShipmentAccess,
  markAsSentAccess,
  deleteShipmentAccess,
  updateWeightAccess,
  updatePackingListAccess,
};
