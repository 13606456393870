import React from 'react';
import { connect } from 'react-redux';

import { UIAuthorization } from 'components/Authorization/UIAuthorization';

import { RouterButton } from 'shared/components/Buttons/RouterButton/RouterButton';
import { ColumnGrid } from 'shared/components/ColumnGrid/ColumnGrid';
import { Icon } from 'shared/components/Icon/Icon';
import { Legend } from 'shared/components/Legend/Legend';
import { OverviewListWrapper } from 'shared/components/OverviewListWrapper/OverviewListWrapper';
import { SHARED } from 'shared/helpers/constants/sharedConstants';
import { humanizeCamelCase } from 'shared/helpers/parsers/text';
import { cssVar } from 'shared/helpers/styling/styling';

import { Part } from '../components/Parts/Parts';

function GlobalLotsSectionBase(props) {
  const {
    section,
    lotsSections,
    contentHide,
    auth: {
      access: { lots },
    },
  } = props;
  const currentSection = lotsSections.lotsCount[section.name];
  const sectionLength =
    currentSection && Object.values(currentSection).reduce((acc, value) => acc + value.length, 0);
  const actionsAuth =
    (lots.outgoingOverview && section.name === 'outgoingLots') ||
    (lots.mixingOverview.view && section.name === 'mixingOverview') ||
    (lots.processingOverview.view && section.name === 'processingOverview') ||
    (lots.incomingOverview && section.name === 'incomingLots');

  const detailsButton = (
    <UIAuthorization access={actionsAuth}>
      <RouterButton outlineColor={cssVar('pelorous')} path={section.path}>
        {SHARED.DETAILS}
        <Icon icon="icon-tiny-arrow-right" />
      </RouterButton>
    </UIAuthorization>
  );

  const list = {
    items: {
      total: sectionLength,
    },
    isPending: lotsSections.isPending,
  };

  const label = Number.isFinite(sectionLength)
    ? `${humanizeCamelCase(section.name)} (${sectionLength})`
    : humanizeCamelCase(section.name);

  return (
    <OverviewListWrapper
      label={label}
      list={list}
      button={detailsButton}
      customList
      contentHide={contentHide}
    >
      <ColumnGrid size={section.parts.length}>
        {section.parts.map(part => {
          const data =
            currentSection &&
            Object.entries(currentSection).filter(([key]) => key.startsWith(part.part));

          return <Part part={part} key={part.part} data={data} actionsAuth={actionsAuth} />;
        })}
      </ColumnGrid>
      <Legend legendConfig={section.legend} color={cssVar('outerSpaceTabs')} />
    </OverviewListWrapper>
  );
}

const mapStateToProps = ({ auth, lotsSections }) => ({
  auth,
  lotsSections,
});

const GlobalLotsSection = connect(mapStateToProps)(GlobalLotsSectionBase);

export { GlobalLotsSection };
